<div style="position:fixed; border: 0.2cm solid #b7860f; top: 1.5cm; right: 1.5cm; bottom: 4cm;left: 1.5cm; "></div>

<div fxLayout="column" style="height: 100%; padding: 2cm 2cm 9cm 2cm; background-color: #ffffff" class="font-montserrat-900">
  @if (data.subType === 'day-session') {
    <div fxLayout="row" fxLayoutAlign="center" style="position:relative; top: 1cm; width: 100%;">
      @if (data.value > 600) {
        <img src="../../../../assets/images/voucher/gift-card-day-session-2x.svg" style="width: 100%;"/>
      } @else {
        <img src="../../../../assets/images/voucher/gift-card-day-session.svg" style="width: 100%;"/>
      }
    </div>
  } @else {
    <div fxLayout="row" fxLayoutAlign="center" style="position:relative; top: 1cm; width: 120%;">
      <img src="../../../../assets/images/voucher/title.svg" style="width: 100%;"/>
    </div>
  }
  <div fxLayout="column" fxLayoutAlign="space-around center" style="border: 0px solid red; height: 100%; margin-top: 1cm">
    <div fxLayout="row" fxLayoutAlign="center">
      <div *ngIf="!data.subType" style="font-size: 32px; font-weight: bold; color:#3c3b3f">WERT: {{ valueString }}</div>
    </div>

    <div *ngIf="!data.subType" style="width:100%">
      <div fxLayout="row" fxLayoutAlign="space-around">
        <div style="font-size: 24px; font-weight: bold; color:#3c3b3f">VON</div>
        <div style="font-size: 24px; font-weight: bold; color:#3c3b3f">FÜR</div>
      </div>
      <br/><br/><br/>
      <div fxLayout="row" fxLayoutAlign="space-around" style="width:100%">
        <div style="border-top: 2px solid #b7860f; width: 30vw">&nbsp;</div>
        <div style="border-top: 2px solid #b7860f; width: 30vw">&nbsp;</div>
      </div>
    </div>
  </div>


  <div class="flex flex-col w-fill justify-center items-center w-full" style="position:fixed; bottom: 1cm; left:0; right:0">
    <div class="flex flex-col pb-2" style="text-align: center">
      @if (data.subType === 'day-session') {
        <div *ngIf="data.value > 600" style="font-size: 14px; font-weight: bold; color:#818588">GÜLTIG FÜR 2x DAY-SESSIONS</div>
      }
      <div *ngIf="!data.validFrom" style="font-size: 14px; font-weight: bold; color:#818588">{{ data.code }} · GÜLTIG
        BIS: {{ data.validTill | nxtDate: 'dd.MM.yyyy' }}
      </div>
      <div *ngIf="data.validFrom" style="font-size: 14px; font-weight: bold; color:#818588">{{ data.code }}<br/>GÜLTIG
        AB: {{ data.validFrom | nxtDate: 'dd.MM.yyyy' }} ·
        GÜLTIG BIS: {{ data.validTill | nxtDate: 'dd.MM.yyyy' }}
      </div>
      <div *ngIf="data.disableDiscountPromo && !data.subType" style="font-size: 14px; font-weight: bold; color:#818588">Der Gutschein ist nicht auf Tattoos mit
        einem Aktionspreise
        einzulösen
      </div>
      <div *ngIf="data.subType === 'day-session'" style="font-size: 14px; font-weight: bold; color:#818588">Eine Tagessitzung beginnt um 11 Uhr und endet um 18
        Uhr
      </div>
    </div>
    <div fxLayout="column"
         class="p-relative flex flex-column items-center"
         style="border-radius: 0.3cm; border: 2px solid #b7860f; background-color: white; padding: 8px">
      <div fxLayout="row" fxLayoutAlign="center" style="padding: 0.5cm;">
        <!--<img src="../../../../assets/logo/logo_black.svg" style="width: 6cm"/>-->
        <img [src]="'../../../../assets/logo/studio/logo_' + configService.config.value.studioRegion.toLowerCase() + '_dark.svg'" style="width: 6cm"/>

      </div>

      <div fxLayout="row" fxLayoutAlign="center" class="no-wrap"
           style="font-size: 14px; letter-spacing: 2px; font-weight: 900; color: #3c3b3f; text-align: center"
           [innerHTML]="configService.config.value.voucher.address"></div>
    </div>
  </div>
</div>
