import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, effect, inject, OnInit, Optional, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {CalendarEvent, CalendarWeekModule} from 'angular-calendar';
import {NxtEmployee, NxtEmployeeEvent, NxtEmployeeWeekCheck, NxtWorkplace} from '../../../common-interfaces/nxt.employee.interface';
import {SocketService} from '../../../services/socket/socket.service';
import {CalendarHourSegmentComponent} from '../../calendar/calendar-hour-segment/calendar-hour-segment.component';
import {DialogService} from '../../../services/dialog.service';
import {EmployeeEventEditComponent} from '../employee-event-edit/employee-event-edit.component';
import {EmployeeCalendarEventComponent} from './employee-calendar-event/employee-calendar-event.component';
import {CacheService} from '../../../services/cache/cache.service';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {FormsModule} from '@angular/forms';
import {NxtPageComponent} from '../../nxt-page/nxt-page.component';
import {NxtPageHeaderTitleComponent} from '../../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageHeaderComponent} from '../../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageContentComponent} from '../../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {DatePicker2Component} from '../../form-controls/date-picker-2/date-picker-2.component';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {EmployeeEventTimetrackEditComponent} from '../employee-event-timetrack-edit/employee-event-timetrack-edit.component';
import {PermissionService} from '../../../services/permission.service';
import {NxtPermissionId} from '../../../common-interfaces/nxt.user.interface';
import {EmployeeCalenderHeaderComponent} from './employee-calendar-header/employee-calendar-header.component';
import {ConfigService} from '../../../services/config.service';
import {LoginService} from '../../../services/login.service';
import {DayOfWeek} from '../../../common-interfaces/date.interface';
import {InputComponent} from '../../form-controls/input/input.component';
import {EmployeeEventTypePipe} from '../../../pipes/employee-event-type.pipe';
import {WorkplaceTools} from '../../../common-browser/helpers/workplace.tools';
import {NxtStudioPipe} from '../../../pipes/nxt-studio-pipe';
import {GlobalStudioShortName} from '../../../common-interfaces/global-studio.interface';
import {WorkplaceService} from '../../../services/workplace.service';
import {EmployeeEventService} from '../employee-event.service';

export interface EmployeeCalendarEventMeta {
  employeeEvent: NxtEmployeeEvent;
  employee: NxtEmployee;
  searchText: string;
}

@Component({
  selector: 'nxt-employee-calendar',
  templateUrl: './employee-calendar.component.html',
  styleUrls: ['./employee-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CalendarWeekModule, CalendarHourSegmentComponent, EmployeeCalendarEventComponent, MatButtonToggle, MatButtonToggleGroup, FormsModule, NxtPageComponent, NxtPageHeaderTitleComponent, NxtPageHeaderComponent, NxtPageHeaderComponent, NxtPageHeaderTitleComponent, NxtPageContentComponent, NxtPageFooterComponent, DatePicker2Component, SlideToggleComponent, EmployeeCalenderHeaderComponent, InputComponent],
})

export class EmployeeCalendarComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor(@Optional() public dialogRef: MatDialogRef<any>) {
    super();
    effect(() => {
      this.dateString();
      this.load();
    });

    effect(() => {
      this.eventTypeFilter();
      this.workplaceFilter();
      this.studioFilter();
      this.refreshView();
    });
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  quickFilterText = signal('');
  workplaceFilter = signal<NxtWorkplace[]>([]);
  studioFilter = signal<string[]>([]);
  eventTypeFilter = signal<string[]>(['work', 'vacation', 'sick', 'notHere']);
  showTimeTracks = signal(false);
  employeeChecks = signal<{ checks: NxtEmployeeWeekCheck[], errors: string[] }>(null);
  dateString = signal(DateTools.getMondayBefore(Date.now(), 0).dateFormatDate());
  employeesFreeDays = signal<{ employeeId: string, giveName: string, familyName: string, freeDays: DayOfWeek[] }[]>([]);

  studioOptions = signal<{ value: string, text: string }[]>([]);

  date = computed(() => new Date(this.dateString().dateFormatDate()));

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  loginService = inject(LoginService);
  socketService = inject(SocketService);
  dialogService = inject(DialogService);
  cacheService = inject(CacheService);
  permissionService = inject(PermissionService);
  configService = inject(ConfigService);
  workplaceService = inject(WorkplaceService);
  employeeEventService = inject(EmployeeEventService);


  events: CalendarEvent<EmployeeCalendarEventMeta>[];

  eventsFiltered = signal<CalendarEvent<EmployeeCalendarEventMeta>[]>([]);

  employeeEvents: NxtEmployeeEvent[] = [];


  private employeeEventTypePipe = new EmployeeEventTypePipe();
  private studioPipe = new NxtStudioPipe();

  startHour = signal(9);
  endHour = signal(24);

  ngOnInit() {
    this.startHour.set(this.employeeEventService.getCalendarHours().start);
    this.endHour.set(this.employeeEventService.getCalendarHours().end);

    this.workplaceFilter.set(this.workplaceService.employeeWorkplaceOptions.map(w => w.value));
    if (this.configService.config.value.studioRegion === 'AC') {
      this.studioOptions.set([{text: 'AC', value: 'ac'}, {text: 'DU', value: 'du'}, {text: 'DO', value: 'do'}, {text: 'MA', value: 'ma'}]);
    } else if (this.configService.config.value.studioRegion === 'MA') {
      this.studioOptions.set([{text: 'Mallorca', value: 'ma'}]);
    } else if (this.configService.config.value.studioRegion === 'BR') {
      this.studioOptions.set([{text: 'Brudis', value: 'br'}]);
    }
    this.pushSocketSubscription = this.socketService.subscribeNew('eventEmployeeEventChanged', () => this.load(), {emitAfterReconnect: true});
    // this.pushSubscription = this.socketService.onAuthenticated.subscribe(() => this.load());
  }

  async load() {
    await this.cacheService.waitForEmployees();
    const start = DateTools.getMondayBefore(this.dateString().dateParse(), 0);
    const end = start.dateAddDays(6);
    const data = await this.socketService.getEmployeeEvents(start.dateFormatDate(), end.dateFormatDate());
    this.employeeEvents = data.employeeEvents;
    this.employeesFreeDays.set(data.employeesFreeDays);
    this.employeeChecks.set(await this.socketService.checkEmployeeEvents(start.dateFormatDate()));
    this.refreshView();
  }

  refreshView() {
    const employeeEvents = this.employeeEvents.filter(e => {
      const workplaceOk = this.workplaceFilter().some(w => e.workplaces.includes(w)) || e.type !== 'work';
      const studioOk = this.studioFilter().includes(e.studio) || this.studioFilter().length === 0 || e.type !== 'work';
      const eventTypeOk = this.eventTypeFilter().includes(e.type);
      return workplaceOk && studioOk && eventTypeOk;
    });
    this.events = employeeEvents.map((employeeEvent) => {
      const employee = this.cacheService.employees.value.find(e => e.id === employeeEvent.employeeId);
      return {
        start: new Date(employeeEvent.start),
        end: employeeEvent.end ? new Date(employeeEvent.end) : new Date(),
        title: employeeEvent.employeeId,
        meta: {employeeEvent, employee, searchText: this.getSearchText(employee, employeeEvent)},
      };
    });
    this.events.sort((e1, e2) => {
      if (e1.start < e2.start) {
        return -1;
      } else if (e1.start > e2.start) {
        return 1;
      } else {
        return e1.meta.employee.givenName.localeCompare(e2.meta.employee.givenName);
      }
    });
    this.setEventsFiltered();
    // this.cdRef.detectChanges();
  }

  nxtOnDestroy() {
  }

  async eventClicked(event: CalendarEvent<EmployeeCalendarEventMeta>) {
    if (!this.loginService.isJulian()) {
      const marcelWorking = await this.socketService.employee.isWorkingByName('Marcel', Date.now().dateFormatDate());
      if (marcelWorking && !this.loginService.isMarcel()) {
        const niklasWorking = await this.socketService.employee.isWorkingByName('Niklas', Date.now().dateFormatDate());
        if (niklasWorking && !this.loginService.isNiklas()) {
          this.dialogService.showOk('Marcel macht den Arbeitsplan');
          return;
        }
      }
    }
    if (event.meta.employeeEvent.type === 'timeTrack') {
      if (this.permissionService.hasPermission(NxtPermissionId.Employees_EditTimeTracks)) {
        const dialog = this.dialogService.showComponentDialog(EmployeeEventTimetrackEditComponent);
        dialog.componentInstance.load(event.meta.employeeEvent.id).then();
      }
    } else {
      if (this.permissionService.hasPermission(NxtPermissionId.Employees_EditEvents)) {
        if (!this.loginService.isJulian()) {
          if (event.meta.employeeEvent.start < Date.now().dateAddDays(-2)) {
            this.dialogService.showOk('Die Vergangenheit lässt sich nicht mehr bearbeiten.').then();
            return;
          }
        }
        const dialog = this.dialogService.showComponentDialog(EmployeeEventEditComponent);
        dialog.componentInstance.load(event.meta.employeeEvent.id).then();
      }
    }
  }

  hourSegmentClicked(data: { date: Date; sourceEvent: MouseEvent }) {
    if (this.showTimeTracks()) {
      if (this.permissionService.hasPermission(NxtPermissionId.Employees_EditTimeTracks)) {
        const dialog = this.dialogService.showComponentDialog(EmployeeEventTimetrackEditComponent);
        dialog.componentInstance.new(data.date.dateFormatDate(), data.date.dateFormat('HH:mm'));
      }
    } else {
      if (this.permissionService.hasPermission(NxtPermissionId.Employees_EditEvents)) {
        const dialog = this.dialogService.showComponentDialog(EmployeeEventEditComponent);
        dialog.componentInstance.new(data.date.dateFormatDate(), data.date.dateFormat('HH:mm'));
      }
    }
  }

  datePickerChanged(dateString: string) {
    this.dateString.set(DateTools.getMondayBefore(dateString, 0).dateFormatDate());
  }

  showTimeTracksChanged() {
    if (this.showTimeTracks()) {
      this.eventTypeFilter.set(['timeTrack']);
    } else {
      this.eventTypeFilter.set(['work', 'vacation', 'sick', 'notHere']);
    }
  }

  quickFilterTextChanged() {
    this.setEventsFiltered();
  }

  setEventsFiltered() {
    if (this.quickFilterText()) {
      this.eventsFiltered.set(this.events.filter(e => {
        let result = true;
        for (const part of this.quickFilterText().split(' ')) {
          if (!e.meta.searchText.includesIgnoreCase(part)) {
            result = false;
          }
        }
        return result;
      }));
    } else {
      this.eventsFiltered.set(this.events);
    }
  }

  private getSearchText(employee: NxtEmployee, employeeEvent: NxtEmployeeEvent) {
    const texts: string[] = [];
    texts.push(employee.givenName);
    texts.push(this.employeeEventTypePipe.transform(employeeEvent.type));
    for (const workplace of employeeEvent.workplaces) {
      texts.push(WorkplaceTools.getText(workplace));
    }
    if (employeeEvent.studio) {
      texts.push(this.studioPipe.transform(employeeEvent.studio as GlobalStudioShortName));
    }
    return texts.join(' ');
  }
}
