import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtArtistConfirm} from '../../common-interfaces/nxt.artist-confirm.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../services/dialog.service';
import {SocketService} from '../../services/socket/socket.service';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';

@Component({
    selector: 'nxt-artist-confirm-waiting',
    templateUrl: './artist-confirm-waiting.component.html',
    styleUrls: ['./artist-confirm-waiting.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NxtButtonComponent
    ]
})

export class ArtistConfirmWaitingComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  artistConfirm = signal<NxtArtistConfirm | null>(null);

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  socketService = inject(SocketService);
  dialogService = inject(DialogService);
  dialogRef = inject(MatDialogRef<any>);

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }


  start(artistConfirm: NxtArtistConfirm) {
    this.artistConfirm.set(artistConfirm);
    this.pushSocketSubscription = this.socketService.subscribeNew('eventArtistConfirmChanged', (data) => {
      if (data.record.state === 'confirmed' && data.record.id === artistConfirm.id) {
        this.dialogRef.close(true);
        this.dialogService.showOk('"' + artistConfirm.artist + '" hat bestätigt.');
      }
    });
  }


  canceleClicked() {
    this.dialogRef.close(false);
  }
}
