import {DurationTools} from './duration.tools';
import {DecimalTools} from './decimal.tools';

export class EventCalcTools {
    public static timeCheckText(eventDuration: number, priceEstimatedFrom: number) {
        const calcResult = EventCalcTools.timeCheck(eventDuration, priceEstimatedFrom);
        if (Math.abs(calcResult.calculatedPriceDiff) > 50) {
            return DurationTools.format(eventDuration, 'HH:mm') + ' <> ' + DecimalTools.toMoneyString(priceEstimatedFrom);
        }
        return '';
    }

    public static timeCheck(eventDuration: number, priceEstimatedFrom: number): { calculatedPrice: number, calculatedPriceDiff: number} {
        if (typeof priceEstimatedFrom === 'string') {
            priceEstimatedFrom = parseFloat(priceEstimatedFrom);
        }
        let calculatedPrice = 0;
        let calculatedPriceDiff = 0;
        if (priceEstimatedFrom > 0) {
            const eventDurationHours = eventDuration / DurationTools.DURATION_1HOUR;
            const costPerHour = 100;
            calculatedPrice = eventDurationHours * costPerHour;
            calculatedPriceDiff = priceEstimatedFrom - calculatedPrice;
        }
        calculatedPriceDiff = Math.round(calculatedPriceDiff / 10) * 10;
        return {calculatedPrice, calculatedPriceDiff};
    }
}
