<button mat-icon-button [matTooltip]="tooltip" [ngStyle]="{backgroundColor: backgroundColor, width: buttonSize + 'px', height: buttonSize + 'px'}"
        [ngClass]="{'small-height': smallHeight, 'normal-height': !smallHeight}">
  @if (svg) {
    <div [innerHTML]="svg | safeHtml"></div>
  } @else {
    @if (!fa) {
      <mat-icon
        fontSet="material-symbols-outlined"
        tabindex="-1"
        aria-hidden="false"
        [style.font-size]="size + 'px'"
        [style.width]="size + 'px'"
        [ngStyle]="{fontSize: size + 'px', width: size + 'px', height: size + 'px', color: color}">
        <ng-content></ng-content>
      </mat-icon>
    }
    <ng-container *ngIf="faIcon && fa">
      <fa-icon class="flex" [icon]="faIcon" [style.font-size]="size + 'px'" [style.width]="size + 'px'" [style.height]="size + 'px'"></fa-icon>
    </ng-container>
  }
</button>

