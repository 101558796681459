import {Pipe, PipeTransform} from '@angular/core';
import {DateTools} from '../common-browser/helpers/date.tools';

@Pipe({
  name: 'nxtTime',
  standalone: true
})
export class NxtTimePipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, short = false): any {
    try {
      let result = DateTools.format(value, 'HH:mm');
      if (short) {
        result = result.replace(':00', '');
      }
      return result;
    } catch (err) {
      return '?';
    }
  }
}
