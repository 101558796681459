import {EventEmitter, Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {StringTools} from '../common-browser/helpers/string.tools';
import {JsonTools} from '../common-browser/helpers/json.tools';

/*export interface NewRoute {
  snapshot: ActivatedRouteSnapshot;
  component: any;
};*/

@Injectable({
  providedIn: 'root',
})
export class RouteService {

  public static instance: RouteService;
  public onNewComponent = new EventEmitter<any>();
  private _currentRoute = '';
  private _currentComponent;

  public setCurrentComponent(comp) {
    this._currentComponent = comp;
    this.onNewComponent.emit(comp);
  }

  public getCurrentRoute() {
    return this._currentRoute;
  }

  public getCurrentComponent() {
    return this._currentComponent;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    RouteService.instance = this;

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (window.location.hostname === 'localhost') {
          if (!event.url.startsWith('/login') && event.url !== '/main') {
            let currentLastUrl: string[] = [];
            if (localStorage.getItem('localLastUrls')) {
              currentLastUrl = JsonTools.parse(localStorage.getItem('localLastUrls'));
            }
            currentLastUrl = currentLastUrl.filter(url => url !== event.url);
            currentLastUrl.unshift(event.url);
            if (currentLastUrl.length > 5) {
              currentLastUrl.pop();
            }
            localStorage.setItem('localLastUrls', JsonTools.stringify(currentLastUrl));
          }
        }
      }
    });
  }

  /*emitNewRouteEvent(event: ActivationStart) {
    this.onNewComponent.emit({snapshot: event.snapshot, component: this._currentComponent});
  }*/

  isCurrentRoute(route: string) {
    route = StringTools.trimStart(route, '/');
    route = StringTools.trimEnd(route, '/');
    return route.toLowerCase() === this.getCurrentRoute().toLowerCase();
  }

  isFirstRoutePart(routePart: string) {
    routePart = StringTools.trimStart(routePart, '/');
    routePart = StringTools.trimEnd(routePart, '/');
    return routePart === this.getCurrentRoute().split('/')[0];
  }

  goTo(route: string) {
    this.router.navigateByUrl(route);
  }
}
