<div class="wrapper">
  <div
    *ngIf="data.title"
    class="title-text flex flex-row justify-center"
    [innerHTML]="data.title | safeHtml"
  >
  </div>
  <div style="margin-bottom:24px; margin-top:10px;" [ngStyle]="{textAlign: data.textAlign, fontSize: data.fontSize}" [innerHTML]="data.message | safeHtml">
  </div>
  <div class="flex items-center justify-around gap-3" [fxLayout]="buttonDirection" [ngStyle]="{fontSize: data.fontSize}"
       [ngClass]="{'more-button-padding': data.fontSize !== '100%'}">
    <nxt-button *ngIf="data.showCancelButton && buttonDirection === 'row'" (click)="cancel()">Abbrechen</nxt-button>
    @for (button of data.buttons; track button.value) {
      <nxt-button (click)="buttonClick(button)">
        <div [innerHTML]="button.text | safeHtml"></div>
      </nxt-button>
    }
    <nxt-button *ngIf="data.showCancelButton && buttonDirection === 'column'" (click)="cancel()">Abbrechen</nxt-button>
  </div>
</div>
