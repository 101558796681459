import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtPageComponent} from '../nxt-page/nxt-page.component';
import {NxtPageContentComponent} from '../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {NxtPageHeaderComponent} from '../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageHeaderTitleComponent} from '../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtMoneyAccount, NxtMoneyTransactionCategory, NxtMoneyTransactionLabel, NxtMoneyTransactionRegion} from '../../common-interfaces/money-account.interface';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {SocketService} from '../../services/socket/socket.service';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {DialogService} from '../../services/dialog.service';
import {MoneyAccountEditComponent} from './money-account-edit/money-account-edit.component';
import {MoneyAccountTools} from '../../common-browser/helpers/money-account.tools';
import {MoneyTransactionCategoryEditComponent} from './money-transaction-category-edit/money-transaction-category-edit.component';
import {firstValueFrom} from 'rxjs';
import {MoneyTransactionLabelEditComponent} from './money-transaction-label-edit/money-transaction-label-edit.component';
import {MoneyTransactionRegionEditComponent} from './money-transaction-region-edit/money-transaction-region-edit.component';
import {IconTools} from '../../common-browser/helpers/icon.tools';
import {MoneyTransactionsComponent} from './money-transactions/money-transactions.component';
import {SocketInterfaceResponse} from '../../common-interfaces/socket/socket-interface';
import {NxtPermissionId, NxtUser} from 'src/app/common-interfaces/nxt.user.interface';
import {PermissionDirective} from '../../directives/permission.directive';
import {PermissionService} from '../../services/permission.service';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'nxt-money-accounts',
  templateUrl: './money-accounts.component.html',
  styleUrls: ['./money-accounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NxtPageComponent,
    NxtPageContentComponent,
    NxtPageFooterComponent,
    NxtPageHeaderComponent,
    NxtPageHeaderTitleComponent,
    NxtDatagridComponent,
    NxtButtonComponent,
    PermissionDirective,
  ],
  standalone: true,
})

export class MoneyAccountsComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  moneyAccounts = signal<NxtMoneyAccount[]>([]);
  regions = signal<NxtMoneyTransactionRegion[]>([]);
  labels = signal<NxtMoneyTransactionLabel[]>([]);
  categories = signal<NxtMoneyTransactionCategory[]>([]);

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  private permissionService = inject(PermissionService);
  private configService = inject(ConfigService);

  dialogRef = inject(MatDialogRef<MoneyAccountsComponent>, {optional: true});


  accountColumnDefs: NxtColDef<NxtMoneyAccount>[] = [
    {
      headerName: '', valueFormatter: () => IconTools.Material.Edit, nxtFieldType: NxtFieldType.Icon,
      nxtOnCellClicked: (data) => this.upsertAccount(data.data),
      hide: !this.permissionService.hasPermission(NxtPermissionId.MoneyAccount_Admin),
    },
    {
      headerName: '', valueFormatter: () => IconTools.Material.Eye, nxtFieldType: NxtFieldType.Icon,
      nxtOnCellClicked: (data) => this.accountClicked(data.data),
    },
    {headerName: 'Name', field: 'name', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Typ', field: 'type', nxtFieldType: NxtFieldType.Text, valueFormatter: (params) => MoneyAccountTools.getMoneyAccountTypeText(params.value)},
    {headerName: 'Letzter Eintrag', field: 'balanceTimestamp', nxtFieldType: NxtFieldType.Date_germanDateTime},
    {headerName: 'Stand', field: 'balance', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Ladenkasse', field: 'cashRegisterName', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Tresor aus', field: 'receiveCashRegisterSafeStudio', nxtFieldType: NxtFieldType.Text, valueFormatter: params => this.configService.getStudioName(params.value)},
    {
      headerName: 'Zugriff', field: 'userIds', nxtFieldType: NxtFieldType.Text, valueGetter: (params) => {
        if (params.data.userIds) {
          return params.data.userIds.map(userId => this.users.find(u => u.id === userId)?.username).sort().join(', ');
        }
      },
      hide: !this.permissionService.hasPermission(NxtPermissionId.MoneyAccount_Admin),
    },
  ];

  regionColumnDefs: NxtColDef<NxtMoneyTransactionRegion>[] = [
    {headerName: '', valueFormatter: () => IconTools.Material.Edit, nxtFieldType: NxtFieldType.Icon, nxtOnCellClicked: (data) => this.upsertRegion(data.data)},
    {headerName: 'Name', field: 'name', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Beschreibung', field: 'description', nxtFieldType: NxtFieldType.Text},
  ];

  labelColumnDefs: NxtColDef<NxtMoneyTransactionLabel>[] = [
    {headerName: '', valueFormatter: () => IconTools.Material.Edit, nxtFieldType: NxtFieldType.Icon, nxtOnCellClicked: (data) => this.upsertLabel(data.data)},
    {headerName: 'Name', field: 'name', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Beschreibung', field: 'description', nxtFieldType: NxtFieldType.Text},
  ];

  categoryColumnDefs: NxtColDef<NxtMoneyTransactionCategory>[] = [
    {headerName: '', valueFormatter: () => IconTools.Material.Edit, nxtFieldType: NxtFieldType.Icon, nxtOnCellClicked: (data) => this.upsertCategory(data.data)},
    {headerName: 'Name', field: 'name', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Beschreibung', field: 'description', nxtFieldType: NxtFieldType.Text},
  ];
  private data: SocketInterfaceResponse.GetMoneyAccounts;
  private users: NxtUser[];


  constructor() {
    super();
  }

  ngOnInit() {
    this.load();
  }

  nxtOnDestroy() {
  }

  async load() {
    this.users = await this.socketService.getUsers();
    const data = await this.socketService.getMoneyAccounts();
    this.moneyAccounts.set(data.accounts.sortString('name'));
    this.regions.set(data.regions);
    this.categories.set(data.categories);
    this.labels.set(data.labels);
  }


  async upsertLabel(label?: NxtMoneyTransactionLabel) {
    const dialog = this.dialogService.showComponentDialog(MoneyTransactionLabelEditComponent);
    if (label) {
      dialog.componentInstance.load(label);
    }
    await firstValueFrom(dialog.afterClosed());
    this.load();
  }

  async upsertCategory(category?: NxtMoneyTransactionCategory) {
    const dialog = this.dialogService.showComponentDialog(MoneyTransactionCategoryEditComponent);
    if (category) {
      dialog.componentInstance.load(category);
    } else {
      dialog.componentInstance.new();
    }
    await firstValueFrom(dialog.afterClosed());
    this.load();
  }

  async upsertAccount(data?: NxtMoneyAccount) {
    const dialog = this.dialogService.showComponentDialog(MoneyAccountEditComponent);
    if (data) {
      dialog.componentInstance.load(data);
    } else {
      dialog.componentInstance.new();
    }
    await firstValueFrom(dialog.afterClosed());
    this.load();
  }

  upsertRegion(data?: NxtMoneyTransactionRegion) {
    const dialog = this.dialogService.showComponentDialog(MoneyTransactionRegionEditComponent);
    if (data) {
      dialog.componentInstance.load(data);
    } else {
      dialog.componentInstance.new();
    }
    dialog.afterClosed().subscribe(() => this.load());
  }

  accountClicked(account: NxtMoneyAccount) {
    const dialog = this.dialogService.showComponentFull(MoneyTransactionsComponent);
    dialog.componentInstance.load(account.id);
  }
}
