<div class="flex flex-col">
  <div>
    <div>
      <nxt-input [readonly]="true" (click)="descriptionClicked()" [nxtFormControl]="form.controls.description"></nxt-input>
    </div>
    <div>
      <nxt-input [isMoney]="true" [nxtFormControl]="form.controls.value"></nxt-input>
    </div>
  </div>
  <div class="flex flex-row justify-between p-2">
    <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
    <nxt-button (click)="saveClicked()">Speichern</nxt-button>
  </div>
</div>
