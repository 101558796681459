<div fxLayout="column" style="border:0px solid green; height:100%">
  <!--<div fxLayout="row" fxLayoutAlign="end">
    <nxt-button-icon class="close-button" fxFlexAlign="start" *ngIf="dialogRef" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>-->
  <div fxLayout="column" *ngIf="availableArtists" style="border:0px solid yellow; overflow: hidden">

    <div fxLayout="row" fxLayoutAlign="center" class="text-120 p-3" style="border:0px solid blue;">
      <div>{{title}}</div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" style="border:0px solid red; overflow-y: auto;">
      <div class="h-100" fxFlex fxLayout="column">
        <table class="artists-table h-100">
          <tr>
            <th>Artist</th>
            <th>Verfügbar</th>
            <th>Auswählen</th>
          </tr>
          <tr *ngFor="let artist of availableArtists">
            <td>
              <div fxLayout="column">
                <div>{{artist.name}}</div>
                <nxt-rate-icons *ngIf="showArtistSkill" icon="star" [value]="artist.skillFiltered.skill / 2"></nxt-rate-icons>
              </div>
            </td>
            <td>
              <div *ngFor="let timeWindow of artist.freeTimeWindows" [ngStyle]="{color: timeWindowColors[timeWindow.id]}">
                {{timeWindow.startTime | duration: 'HH:mm'}}&nbsp;-&nbsp;{{timeWindow.endTime | duration: 'HH:mm'}}
              </div>
            </td>
            <td>
              <div fxLayout="row" fxLayoutAlign="center">
                <nxt-button-icon (click)="useArtist(artist)">done</nxt-button-icon>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div fxLayout="row" *ngIf="loading">
    <div>Artist werden geladen...</div>
  </div>
  <div fxLayout="row" *ngIf="availableArtists && availableArtists.length === 0">
    <div>kein Artist verfügbar</div>
  </div>
  <div *ngIf="false" fxLayout="row" fxLayoutAlign="center" class="pt-3" nxtPermission="Admin">
    <nxt-button (click)="infoToJulian()">das kann nicht sein, info an Julian</nxt-button>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" class="pt-3">
    <nxt-button (click)="dialogRef.close()">schließen</nxt-button>
  </div>
</div>
