<div class="flex flex-col">
  @if (title()) {
    <div class="text-[130%] center w-full">{{ title() }}</div>
  }
  <div class="flex flex-row p-3">
    @for (color of colors; track color) {
      <div class="flex flex-col">
        @for (colorStep of colorSteps; track colorStep) {
          <div
            class="flex text-60 color-item o-hidden items-center justify-center text-center cursor-pointer"
            [style.color]="ColorTools.getLightOrDarkFontColorByBackground(MaterialColors.colors[color][colorStep])"
            [class.current]="currentIndex() === (color + colorStep)"
            (click)="setCurrentColor(color, colorStep)"
            [style.background-color]="MaterialColors.colors[color][colorStep]"
          >{{ colorInfos()[MaterialColors.colors[color][colorStep]] }}
          </div>
        }
      </div>
    }
  </div>
</div>
