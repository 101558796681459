import {ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit, inject, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {InputComponent} from '../../form-controls/input/input.component';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'nxt-node-instance-edit',
  templateUrl: './node-instance-edit.component.html',
  styleUrls: ['./node-instance-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    SlideToggleComponent,
    NxtButtonComponent
  ],
  standalone: true
})

export class NodeInstanceEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  hostname = signal('');
  description = signal('');
  id = signal('');
  monitor = signal(false);

  /*** Injections ***/
  private socketService = inject(SocketService);
  private dialogRef = inject(MatDialogRef);

  /*** Injections ***/

  /*** Variable ***/

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  public cancelClicked() {
    this.dialogRef.close();
  }

  public async saveClicked() {
    await this.socketService.setClientServerDescription(this.id(), this.description(), this.monitor());
    this.dialogRef.close();
  }
}
