import {AfterViewInit, Directive, ElementRef, HostListener, OnInit} from '@angular/core';

@Directive({
    selector: '[nxtTextareaAutoSize]',
    exportAs: 'TextareaAutoSizeDirective',
    standalone: true
})
export class TextareaAutoSizeDirective implements OnInit, AfterViewInit {
  timeout: any;
  observer = new ResizeObserver(() => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => this.resize());
  });

  constructor(public elementRef: ElementRef) {
    this.observer.observe(elementRef.nativeElement);
  }

  ngAfterViewInit(): void {
    this.resize();
  }

  @HostListener(':input')
  onInput() {
    this.resize();
  }

  @HostListener(':change')
  onChange() {
    this.resize();
  }

  ngOnInit() {
    if (this.elementRef.nativeElement.scrollHeight) {
      setTimeout(() => this.resize());
    }
  }

  resize() {
    this.elementRef.nativeElement.style.height = 'auto';
    if (this.elementRef.nativeElement.style.height !== (this.elementRef.nativeElement.scrollHeight) + 'px') {
      this.elementRef.nativeElement.style.height = '0';
      this.elementRef.nativeElement.style.height = (this.elementRef.nativeElement.scrollHeight) + 'px';
    }
  }
}
