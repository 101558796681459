import {AfterViewInit, Component, computed, EventEmitter, inject, input, Input, model, OnChanges, OnInit, output, Output, signal, SimpleChanges} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Payment} from '../../../classes/payment';
import {DecimalTools} from '../../../common-browser/helpers/decimal.tools';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import moment from 'moment';
import {Log} from '../../../common-browser/log/log.tools';
import {DialogService} from '../../../services/dialog.service';
import {NxtFormControl} from '../../../nxt-form/nxt.form-control';
import {NxtPaymentPossibilityRecord} from '../../../common-interfaces/nxt.payment-possibility-record.interface';
import {LoginService} from '../../../services/login.service';
import {PaymentTools, PaymentTypes} from '../../../common-browser/helpers/payment.tools';
import {NxtPayment, PaymentMethod, PaymentType} from '../../../common-interfaces/nxt.payment.interface';
import {ConfigService} from '../../../services/config.service';
import {PermissionService} from '../../../services/permission.service';
import {NxtPermissionId} from '../../../common-interfaces/nxt.user.interface';
import {SocketService} from '../../../services/socket/socket.service';
import {PaymentService} from '../../../services/payment.service';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {StudioRegionBrowserTools} from '../../../common-browser/helpers/studio-region-browser.tools';
import {SearchComponent} from '../../search/search.component';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {TypeTools} from '../../../common-browser/helpers/type.tools';
import {Clipboard} from '@angular/cdk/clipboard';
import {KlarnaOrderRawComponent} from '../../../components/klarna-order-raw/klarna-order.raw.component';
import {ShopOrderViewComponent} from '../../../components/shop-orders/shop-order-view.component';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {RowComponent} from '../../../controls/nxt-grid/row/row.component';
import {CheckboxComponent} from '../../../components/form-controls/checkbox/checkbox.component';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {PermissionDirective} from '../../../directives/permission.directive';
import {AutocompleteComponent} from '../../../components/form-controls/autocomplete/autocomplete.component';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon} from '@angular/material/icon';
import {InputComponent} from '../../../components/form-controls/input/input.component';
import {DatePickerComponent} from '../../../components/form-controls/date-picker/date-picker.component';
import {SelectComponent} from '../../../components/form-controls/select/select.component';
import {ColComponent} from '../../../controls/nxt-grid/col/col.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {SmoothHeightComponent} from '../../../components/smooth-height.component';
import {MultiClickDirective} from '../../../directives/multi-click.directive';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {MatCard} from '@angular/material/card';
import {NgFor, NgIf, NgStyle} from '@angular/common';
import {NxtKlarnaOrder} from '../../../common-interfaces/nxt.klarna-order.interface';
import {NxtPaypalTransaction} from '../../../common-interfaces/nxt-paypal-transaction';
import {TattooTicketService} from '../../../services/tattoo-ticket/tattoo-ticket.service';
import {TimeTools} from '../../../common-browser/helpers/time.tools';
import {FormTools} from '../../../services/form.tools';
import {JsonTools} from '../../../common-browser/helpers/json.tools';
import {TattooTicketConfirmService} from '../../../services/tattoo-ticket/tattoo-ticket-confirm.service';
import {NxtContact} from '../../../common-interfaces/nxt.contact.interface';
import {CashReportIncomingOutgoingFormComponent} from '../../../components/cash-report-incoming-outgoing-form/cash-report-incoming-outgoing-form.component';
import {NxtCalendarEventToPayOnEventDate} from '../../../common-interfaces/nxt.calendar-event.interface';
import {MathTools} from '../../../common-browser/helpers/math.tools';
import {NxtDiscountPromotion} from '../../../common-interfaces/discount-promotion.interface';

@Component({
  selector: 'nxt-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  imports: [NgIf, MatCard, NgStyle, ExtendedModule, MultiClickDirective, SmoothHeightComponent, NgFor, FlexModule, ColComponent, SelectComponent, DatePickerComponent, InputComponent, MatIcon, MatTooltip, AutocompleteComponent, PermissionDirective, NxtButtonIconComponent, CheckboxComponent, RowComponent, NxtButtonComponent, NxtDatePipe, MoneyPipe],
})
export class PaymentsComponent implements OnInit, AfterViewInit, OnChanges {

  constructor(
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    public loginService: LoginService,
    private configService: ConfigService,
    private permissionService: PermissionService,
    private socketService: SocketService,
    public paymentService: PaymentService,
    private clipboard: Clipboard,
    private tattooTicketConfirmService: TattooTicketConfirmService,
  ) {
    setTimeout(() => this.inInitTime = false, 500);
    Log.info(this.formPayments);
    this.valueChange.subscribe(() => {
      this.calcPaymentValueSum();
      this.checkHasPaymentTypes();
    });
  }

  /*** Injections ***/
  tattooTicketService = inject(TattooTicketService);

  private inInitTime = true;

  public DateTools = DateTools;
  studios = this.configService.config.value.studios.map(s => ({text: s.name, value: s.name}));
  studiosWithoutOnlyCash = this.configService.config.value.studios.filter(s => !s.onlyCash).map(s => ({text: s.name, value: s.name}));
  workplaces = [{text: 'Backoffice', value: 'backoffice'}, {text: 'Empfang', value: 'reception'}];
  /*@Input()
  public set formPayments(value: FormArray) {
    this._formPayments = value;
    setTimeout(() => {
      this.valueChange.emit(this._formPayments);
    }, 0);
  }*/

  @Input() formPayments: UntypedFormArray;

  /*public get formPayments(): FormArray {
    return this._formPayments;
  }*/

  forceSaveHack = input.required<boolean>();
  toPayOnEventDate = model.required<NxtCalendarEventToPayOnEventDate[]>();

  toPayOnEventDatePayed = computed(() => this.toPayOnEventDate().filter(toPay => toPay.payed));
  toPayOnEventDateNotPayed = computed(() => this.toPayOnEventDate().filter(toPay => !toPay.payed));

  priceEstimatedFrom = input.required<number>();

  customerToPay = computed(() => {
    return this.priceEstimatedFrom() - this.paymentValueSum() - this.paymentValueCheaper();
  });

  customerToPayAndToPayOnEventDate = computed(() => {
    return this.customerToPay() + this.toPayOnEventDateNotPayed().reduce((a, b) => a + b.value, 0);
  });

  customerPayedAndToPayOnEventDatePayed = computed(() => {
    return this.paymentValueSum() + this.toPayOnEventDatePayed().reduce((a, b) => a + b.value, 0);
  });


  @Input() public artistPercentage: number;
  @Input() public eventDate: any;
  // @Input() public eventIsPrivate: boolean;
  @Input() public enableAddPayment = true;
  @Input() public eventCreateAt?: number;
  @Input() public customer?: NxtContact;
  @Input() public discountPromotion?: NxtDiscountPromotion;
  @Input() eventId: string;
  @Input() public isNewEvent: boolean;
  @Input() public eventIsClosed = false;
  @Input() public paymentTypes: { text: string, value: PaymentType }[];
  @Input() public notAssignedPaypalTransactionsIn: NxtPaypalTransaction[];
  @Input() public notAssignedPaypalTransactionsOut: NxtPaypalTransaction[];
  @Input() public notAssignedKlarnaOrdersIn: NxtKlarnaOrder[];
  @Input() public notAssignedKlarnaOrdersOut: NxtKlarnaOrder[];
  @Input() public notAssignedBankTransactionsIn: NxtPaymentPossibilityRecord[];
  @Input() public notAssignedBankTransactionsOut: NxtPaymentPossibilityRecord[];
  @Input() titleText: string;
  @Input() paymentMethods: { text: string, value: PaymentMethod }[];
  @Input() possiblePaymentMethods: { text: string, value: PaymentMethod }[];
  @Input() possiblePaymentMethodsDepositBack: { text: string, value: PaymentMethod }[];
  @Input() controlGap: string;
  @Input() beforeAddPayment: () => boolean;
  @Output() valueChange = new EventEmitter<UntypedFormArray>();
  private loadTime = Date.now();
  public hasPaymentType: { [paymentType: string]: boolean } = {};
  removePaymentsEnabled = false;
  paymentValueSum = signal(-1);
  paymentValueRealSum = signal(-1);
  paymentValueCheaper = signal(0);
  paymentValueArtist = -1;
  showRest = false;

  public round = Math.round;
  private disableAddPaymentsBecauseRemovedOldPayment = false;

  @Output() printCustomerReceipt = new EventEmitter<string>();


  /*public calcPayout() {
    let result = 0;
    const paymentsInSum = this.calcPaymentSumByPaymentTypes(['deposit', 'rest', 'complete', 'gift-card-sold']);
    if (paymentsInSum === 500 && this.priceEstimatedFrom === 500) {
      // Alte 500 Euro Aktion
      result = 300;
      const sumAdditionalPayments = this.calcPaymentSumByPaymentTypes(['additional']);
      if (sumAdditionalPayments > 0) {
        result += sumAdditionalPayments * 0.5;
      }
      return result;
    } else {
      let sumPayments = this.calcPaymentSumByPaymentTypes(['deposit', 'rest', 'complete', 'additional', 'gift-card-sold']);
      sumPayments -= this.calcPaymentSumByPaymentTypes(['deposit-back']);
      result = sumPayments / 100 * this.artistPercentage;
    }

    result -= this.calcPaymentSumByPaymentTypes(['payout']);
    return result;
  }*/
  @Output() bookToPayOnEventDateClicked = new EventEmitter<{ text: string; value: number }>();

  refundToPayOnEventDatePayedClicked = output<NxtCalendarEventToPayOnEventDate>();
  searchGiftCard = async (options: NxtPaymentPossibilityRecord[], filterValue: string) => {
    filterValue = filterValue.toLowerCase().replace(/-/g, '').trim();
    if (filterValue.length < 8) {
      return [{
        name: 'Gebe bitte mind. 8 Zeichen des Gutscheins ein',
      }];
    }
    return await this.socketService.findGiftCard(filterValue);
  };

  ngOnChanges(changes: SimpleChanges): void {
    this.calcPaymentValueSum();
    this.checkHasPaymentTypes();
    this.addPaymentTypeIfNotExists();
  }

  public getPayments(): UntypedFormGroup[] {
    return this.formPayments.controls as UntypedFormGroup[];
  }

  public getPaymentsAsNxtPayments(): NxtPayment[] {
    const forms = this.formPayments.controls as UntypedFormGroup[];
    return forms.map(f => f.getRawValue() as NxtPayment);
  }

  private calcPaymentValueSum() {
    let paymentValueSum = this.calcPaymentSumByPaymentTypes(['additional', 'complete', 'deposit', 'rest', 'improve', 'gift-card-sold']);
    let paymentValueSumReal = this.calcPaymentSumByPaymentTypesReal(['additional', 'complete', 'deposit', 'rest', 'improve', 'gift-card-sold']);
    paymentValueSum -= this.calcPaymentSumByPaymentTypes(['deposit-back']);
    paymentValueSumReal -= this.calcPaymentSumByPaymentTypes(['deposit-back']);
    this.paymentValueSum.set(MathTools.roundMoney(paymentValueSum));
    this.paymentValueRealSum.set(MathTools.roundMoney(paymentValueSumReal));


    this.paymentValueArtist = this.calcPaymentSumByPaymentTypes(['payout']);
    this.paymentValueCheaper.set(this.calcPaymentSumByPaymentTypes(['cheaper']));
    this.showRest = this.priceEstimatedFrom() - this.paymentValueSum() - this.paymentValueCheaper() > 0 && this.paymentValueSum() > 0;
  }


  /*public getGesamtBetrag() {
    let value = 0;
    for (const payment of this.formPayments.controls) {
      const toAdd = parseInt(payment.get('paymentValue').value, 10);
      if (Number.isInteger(toAdd)) {
        value += parseInt(payment.get('paymentValue').value, 10);
      }
    }
    return value;
  }*/


  ngOnInit() {
    this.calcPaymentValueSum();
    this.checkHasPaymentTypes();
    /*this.formPayments.valueChanges.subscribe(() => {
      this.valueChange.emit(this.formPayments);
    });*/
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.formPayments.valueChanges.subscribe(() => {
        this.emitValueChange();

      });
    }, 500);
  }


  displayKlarnaOrderOptionFn(klarnaOrder: NxtKlarnaOrder | string, highlight: (string) => string): string | undefined {
    if (typeof klarnaOrder === 'string') {
      return klarnaOrder;
    } else if (klarnaOrder) {
      let text = '';
      if (klarnaOrder) {
        text = `<div>`;
        text += `<span style="font-size: larger">${highlight(klarnaOrder.fullName)}</span>`;
        text += `<br/>${highlight(DateTools.format(klarnaOrder.createdAt, 'dd.MM.yyyy HH:mm'))} - ${highlight(moment(DateTools.parse(klarnaOrder.createdAt)).fromNow())}`;
        text += `<br/>${highlight(DecimalTools.roundToString(klarnaOrder.value, 2))} EUR`;
        text += '</div>';
      }
      return text;
    }
  }

  displayPaypalTransactionFn = (paypalTransaction?: NxtPaypalTransaction): string | undefined => {
    let result = '';
    if (paypalTransaction) {
      result = paypalTransaction.value.toMoneyString();
      const name = this.getNameFromPaypalTransaction(paypalTransaction);
      result += ' - ' + name;
      result += ' - ' + this.getTextPaypalTransaction(paypalTransaction);
    }
    return result;
  };

  private getTextPaypalTransaction(paypalTransaction: NxtPaypalTransaction) {
    const isTattooTicket = paypalTransaction?.message?.includes('Tattoo-Ticket');
    if (isTattooTicket) {
      if (paypalTransaction.shopOrderId) {
        return 'Tattoo-Ticket ' + paypalTransaction.shopOrderId;
      }
      return 'Tattoo-Ticket';
    } else {
      if (paypalTransaction.message) {
        return paypalTransaction.message;
      }
      return paypalTransaction.transactionNote;
      /*if (paypalTransaction.transactionNote && !paypalTransaction.transactionNote.startsWith('Zahlung von')) {
        return paypalTransaction.transactionNote;
      }*/
    }
    return '';
  }

  private getNameFromPaypalTransaction(paypalTransaction: NxtPaypalTransaction) {
    let name = paypalTransaction.payerName;
    if (paypalTransaction.message?.includes('Tattoo-Ticket')) {
      name = paypalTransaction.message.replace('Tattoo-Ticket ', '');
    }
    if (!name) {
      name = paypalTransaction.from;
    }
    if (name === 'Tattoo-Ticket') {
      name = paypalTransaction.from;
    }
    if (name.includes('@')) {
      name = name.split('(')[0].trim();
    }
    return name;
  }

  displayPaypalTransactionOptionFn = (paypalTransaction: NxtPaypalTransaction | string, highlight: (string) => string): string | undefined => {
    if (typeof paypalTransaction === 'string') {
      return paypalTransaction;
    }
    if (paypalTransaction && paypalTransaction.from) {
      // const {from, transactionTime, value, originalValue, message, payerEmail, payerName} = paypalTransaction;

      let html = '<div class="flex h-full w-full flex-col justify-center no-wrap p-relative" style="border-bottom: 1px solid #757575">';
      html += '<div style="position: absolute; top: 2px; right:2px; text-align: right;">';
      if (paypalTransaction.requestBy) {
        html += '<div class="flex flex-row items-end justify-end"><div class="text-60" style="line-height: 2">angefragt von&nbsp;</div><div>' + paypalTransaction.requestBy + '</div></div>';
      }
      html += DateTools.dateDiffToNowText(paypalTransaction.transactionTime);
      html += '</div>';

      if (paypalTransaction) {
        const name = this.getNameFromPaypalTransaction(paypalTransaction);
        html += '<div class="text-120">' + paypalTransaction.value.toMoneyString() + ' ' + highlight(name) + '</div>';
        html += '<div>' + highlight(DateTools.format(paypalTransaction.transactionTime, 'dd.MM.yyyy HH:mm')) + '</div>';
        html += '<div>' + highlight(this.getTextPaypalTransaction(paypalTransaction)) + '</div>';
        const payerEmail = paypalTransaction.payerEmail || name;
        html += '<div class="text-90">' + highlight(payerEmail + ' ➞ ' + paypalTransaction.receiverEmail) + '</div>';
      }
      return html;
    }
  };

  displayKlarnaOrderFn(klarnaOrder?: any): string | undefined {
    return klarnaOrder ? DecimalTools.roundToString(klarnaOrder.value, 2) + ' € - ' + klarnaOrder.fullName : undefined;
  }

  displayGiftCardFn(giftCard?: NxtPaymentPossibilityRecord): string | undefined {
    let text = giftCard ? DecimalTools.toMoneyString(giftCard.value) + ' - ' + giftCard.name : undefined;
    if (giftCard?.discountPercentage && giftCard.discountPercentage > 0) {
      text += '  -  ' + giftCard.discountPercentage + '% Rabatt';
    }
    return text;
  }

  displayBankTransactionFn(bankTransaction?: NxtPaymentPossibilityRecord): string | undefined {
    if (bankTransaction?.fullValue) {
      return bankTransaction ? DecimalTools.toMoneyString(bankTransaction.value) + ' von ' + DecimalTools.toMoneyString(bankTransaction.fullValue) + ' - ' + bankTransaction.name : undefined;
    } else {
      return bankTransaction ? DecimalTools.toMoneyString(bankTransaction.value) + ' - ' + bankTransaction.name : undefined;
    }
  }

  displayGiftCardOptionFn(payment: UntypedFormGroup, giftCard: NxtPaymentPossibilityRecord, highlight: (string) => string): string | undefined {
    let text = '?';
    if (giftCard) {


      // const currentString = payment.get('paymentGiftCard').value;
      if (giftCard.validTill && DateTools.parse(giftCard.validTill) < Date.now()) {
        text = `<div style="color: ${ColorTools.Red};">${giftCard.name} ist nicht mehr gültig<br/>Gutschein ist am ${DateTools.format(giftCard.validTill, 'dd.MM.yyyy')} abgelaufen<br/>${giftCard.additionalInfo}</div>`;
      } else {
        text = '';
        if (giftCard.createdAt) {
          text = DecimalTools.toMoneyString(giftCard.value) + ' - ' + DateTools.format(giftCard.createdAt, 'dd.MM.yyyy') + '\n';
        }
        text += giftCard.name;
        if (giftCard.additionalInfo) {
          text += ' - ' + giftCard.additionalInfo;
        }
      }
    }
    return highlight(text);
  }

  displayBankTransactionOptionFn(bankTransaction: NxtPaymentPossibilityRecord, highlight: (string) => string): string | undefined {
    if (bankTransaction) {
      const nameSplit = bankTransaction.name.split('|');
      if (nameSplit.length > 1) {
        return highlight(DecimalTools.toMoneyString(bankTransaction.value) + ' - ' + DateTools.format(bankTransaction.createdAt, 'dd.MM.yyyy') +
          '\n' + nameSplit[1].trim() +
          '\n' + nameSplit[0].trim());
      } else {
        return highlight(DecimalTools.toMoneyString(bankTransaction.value) + ' - ' + DateTools.format(bankTransaction.createdAt, 'dd.MM.yyyy') +
          '\n' + nameSplit[0]);
      }
    }
    return '';
  }


  async removeLastPayment() {
    if (this.removePaymentsEnabled || this.formPayments.controls[this.formPayments.controls.length - 1].get('isNewPayment').value) {
      this.formPayments.controls.pop();
      this.emitValueChange();
    } else {
      this.dialogService.showOk('bereits gespeicherte Zahlungen können nicht gelöscht werden');
    }
    this.calcPaymentValueSum();

    if (this.removePaymentsEnabled) {
      this.disableAddPaymentsBecauseRemovedOldPayment = true;
    }

    /*if (this.formPayments.controls[this.formPayments.controls.length - 1].get('paymentDate').value === DateTools.now.toString()) {
    } else {
      this.dialogService.showOk('Du bist nicht berechtigt buchungen zu löschen, die nicht von heute sind. Bitte Julian fragen');
    }*/
  }

  addPayment(paymentType?: PaymentType, paymentMethod?: PaymentMethod) {
    if (this.disableAddPaymentsBecauseRemovedOldPayment) {
      this.dialogService.showOk('Du hast eine bereits gespeicherte Zahlung gelöscht, bitte speicher erst den Termin, bevor du neue Zahlungen hinzufügst!');
      return;
    }

    if (typeof this.beforeAddPayment === 'function') {
      if (!this.beforeAddPayment()) {
        return;
      }
    }

    const newPaymentRow = Payment.createEmptyPayment(this.loginService.getUsername(), this.loginService.getStudio(), this.loginService.getStudioReal(), this.loginService.getWorkplace(), paymentType, paymentMethod).getFormGroup(this.fb);
    this.formPayments.controls.push(newPaymentRow);
    this.emitValueChange();

    this.formPayments.controls[this.formPayments.controls.length - 1].get('studio').disable();
    this.formPayments.controls[this.formPayments.controls.length - 1].get('studioReal').disable();
    this.formPayments.controls[this.formPayments.controls.length - 1].get('workplace').disable();
    this.formPayments.controls[this.formPayments.controls.length - 1].get('createdBy').disable();
    this.formPayments.controls[this.formPayments.controls.length - 1].get('paymentDate').disable();


    this.paymentMethodChange(this.formPayments.controls[this.formPayments.controls.length - 1] as UntypedFormGroup);

    if (!paymentType && !paymentMethod) {
      this.clickLastPaymentControl('paymentType');
    } else {
      // Button wurde geklickt
      if (!paymentMethod) {
        // nur paymentType ist gesetzt
        this.clickLastPaymentControl('paymentMethod');
      } else {
        if (paymentMethod === 'bank') {
          this.clickLastPaymentControl('paymentBankTransaction');
        } else if (paymentMethod === 'paypal') {
          this.clickLastPaymentControl('paymentPaypalTransaction');
        } else if (paymentMethod === 'klarna') {
          this.clickLastPaymentControl('paymentKlarnaOrder');
        } else if (paymentMethod === 'gift-card') {
          this.clickLastPaymentControl('paymentGiftCard');
        } else if (paymentMethod === 'cash') {
          this.clickLastPaymentControl('paymentValue', true);
        }
      }
    }
    newPaymentRow.get('paymentGiftCard').valueChanges.subscribe((value) => {
      if (typeof value === 'string') {
        if (value.length === 4) {
          const formControl = (newPaymentRow.get('paymentGiftCard') as NxtFormControl);
          if (!value.includes('-')) {
            formControl.formControlWrapper.setInputValue(value + '-');
          }
          // newPaymentRow.get('paymentGiftCard').setValue();
        }
      } else {
        if (value && !value?.id) {
          newPaymentRow.get('paymentGiftCard').setValue(null);
        }
      }
    });
  }

  private getLastPaymentControl(name: 'paymentType' | 'paymentMethod' | 'paymentValue' | 'paymentGiftCard' | 'paymentKlarnaOrder' | 'paymentPaypalTransaction' | 'paymentBankTransaction' | 'paymentComment') {
    return (this.formPayments.controls[this.formPayments.controls.length - 1].get(name) as NxtFormControl).element;
  }

  private getLastPaymentNxtFormControl(name: 'paymentType' | 'paymentMethod' | 'paymentValue' | 'paymentGiftCard' | 'paymentKlarnaOrder' | 'paymentPaypalTransaction' | 'paymentBankTransaction'): NxtFormControl {
    return (this.formPayments.controls[this.formPayments.controls.length - 1].get(name) as NxtFormControl);
  }

  bankTransactionChange(formGroup: AbstractControl) {
    if (formGroup.get('paymentBankTransaction').value && formGroup.get('paymentBankTransaction').value.createdAt) {


      const id = formGroup.get('paymentBankTransaction').value.id;
      const otherPayments = this.getPayments().filter(f => f !== formGroup);
      if (otherPayments.map(f => f.getRawValue() as NxtPayment).find(p => {
        return p.paymentBankTransaction && p.paymentBankTransaction.id === id;
      })) {
        this.dialogService.showOk('Diese Bankzahlung ist bereits diesem Termin zugewiesen');
        formGroup.get('paymentBankTransaction').setValue(null);
        return;
      }


      const date = DateTools.format(formGroup.get('paymentBankTransaction').value.createdAt, 'yyyy-MM-dd');
      formGroup.get('paymentDate').setValue(date);

      // if (!params.onInit) {
      formGroup.get('paymentValue').setValue(formGroup.get('paymentBankTransaction').value.value);
      /*} else {
        formGroup.get('paymentValue').setValue(formGroup.get('paymentValue').value);
      }*/
    }
    this.clickLastPaymentControl('paymentValue', true);
  }

  paypalTransactionChange(formGroup: AbstractControl) {
    if (formGroup.get('paymentPaypalTransaction').value && formGroup.get('paymentPaypalTransaction').value.transactionTime) {

      const transactionId = formGroup.get('paymentPaypalTransaction').value.transactionId;
      const otherPayments = this.getPayments().filter(f => f !== formGroup);
      if (otherPayments.map(f => f.getRawValue() as NxtPayment).find(p => {
        return p.paymentPaypalTransaction && p.paymentPaypalTransaction.transactionId === transactionId;
      })) {
        this.dialogService.showOk('Diese Zahlung ist bereits in diesem Termin zugewiesen');
        formGroup.get('paymentPaypalTransaction').setValue(null);
        return;
      }


      const date = DateTools.format(formGroup.get('paymentPaypalTransaction').value.transactionTime, 'yyyy-MM-dd');
      formGroup.get('paymentDate').setValue(date);
      // if (!params.onInit) {
      formGroup.get('paymentValue').setValue(formGroup.get('paymentPaypalTransaction').value.value);
      /*  } else {
          formGroup.get('paymentValue').setValue(formGroup.get('paymentValue').value);
        }*/
    }
    this.clickLastPaymentControl('paymentValue', true);
  }

  klarnaOrderChange(formGroup: AbstractControl) {
    if (formGroup.get('paymentKlarnaOrder').value && formGroup.get('paymentKlarnaOrder').value.createdAt) {

      const orderId = formGroup.get('paymentKlarnaOrder').value.orderId;
      const otherPayments = this.getPayments().filter(f => f !== formGroup);
      if (otherPayments.map(f => f.getRawValue() as NxtPayment).find(p => {
        return p.paymentKlarnaOrder && p.paymentKlarnaOrder.orderId === orderId;
      })) {
        this.dialogService.showOk('Diese Zahlung ist bereits in diesem Termin zugewiesen');
        formGroup.get('paymentKlarnaOrder').setValue(null);
        return;
      }


      const date = DateTools.format(formGroup.get('paymentKlarnaOrder').value.createdAt, 'yyyy-MM-dd');
      formGroup.get('paymentDate').setValue(date);
      // if (!params.onInit) {
      formGroup.get('paymentValue').setValue(formGroup.get('paymentKlarnaOrder').value.value);
      // } else {
//        formGroup.get('paymentValue').setValue(formGroup.get('paymentValue').value);
      //    }
    }
    this.clickLastPaymentControl('paymentValue', true);
  }

  async canForceGiftCard(text: string, paymentGiftCard: NxtPaymentPossibilityRecord) {
    if (paymentGiftCard.additionalInfo) {
      text += '\nGutschein-Info: ' + paymentGiftCard.additionalInfo;
    }
    if (this.permissionService.hasPermission(NxtPermissionId.GiftCard_ForceAllGiftCards)) {
      if (await this.dialogService.showYesNo(text, {noText: 'OK', yesText: 'Ausnahme machen und verwenden'})) {
        this.socketService.sendBackofficeHeads(this.loginService.getUsername() + ' macht eine Gutscheinausnahme\n' + text + '\n' + StudioRegionBrowserTools.getLinkForEvent(this.eventId));
        return true;
      }
    } else {
      await this.dialogService.showOk(text);
    }
    return false;
  }

  async giftCardChange(formGroup: AbstractControl) {
    await TimeTools.sleep(50);
    if (formGroup.get('paymentGiftCard').value && formGroup.get('paymentGiftCard').value.createdAt) {
      const paymentGiftCard: NxtPaymentPossibilityRecord = formGroup.get('paymentGiftCard').value;
      if (this.discountPromotion?.disableDiscountedGiftCards) {
        if (paymentGiftCard.discountPercentage > 0 && paymentGiftCard.discountPercentage < 100) {
          const text = 'Dies Termin hat einen Aktions-Rabatt, bei dem rabattierte Gutscheine nicht zugelassen sind.';
          if (!await this.canForceGiftCard(text, paymentGiftCard)) {
            formGroup.get('paymentGiftCard').setValue(null);
            return;
          }
        }
      }


      if (paymentGiftCard.additionalInfo.toLowerCase().includes('geburtstag')) {
        if (formGroup.get('paymentType').value === 'deposit') {
          if (!await this.canForceGiftCard('Geburtstags-Gutscheine können nicht als Kaution hinterlegt werden', paymentGiftCard)) {
            formGroup.get('paymentGiftCard').setValue(null);
            return;
          }
        }
      }

      if (paymentGiftCard.validTill && DateTools.parse(paymentGiftCard.validTill) < Date.now().dateAddDays(-1)) {
        const text = 'Gutschein war bis zum ' + DateTools.format(paymentGiftCard.validTill, 'dd.MM.yyyy') + ' gültig';
        if (!await this.canForceGiftCard(text, paymentGiftCard)) {
          formGroup.get('paymentGiftCard').setValue(null);
          return;
        }
      }

      if (!this.isNewEvent && paymentGiftCard.onlyNewAppointments && this.eventCreateAt) {
        if (this.eventCreateAt < DateTools.parse(paymentGiftCard.createdAt).dateAddDays(-1)) {
          const text = 'Dieser Termin wurde am ' + this.eventCreateAt.dateFormat('dd.MM.yyyy') + ' erstellt\n\nDieser Gutschein kann nur für Termine genutzt werden, die nach dem ' + DateTools.format(paymentGiftCard.createdAt, 'dd.MM.yyyy') + ' erstellt wurden';
          if (!await this.canForceGiftCard(text, paymentGiftCard)) {
            formGroup.get('paymentGiftCard').setValue(null);
            return;
          }
        }
      }

      if (paymentGiftCard.customRestrictionValue) {
        if (paymentGiftCard.customRestrictionValue > this.priceEstimatedFrom()) {
          const text = 'Mindestpreis nicht erreicht!\nEinzulösen bei einem Mindestpreis von ' + DecimalTools.toMoneyString(paymentGiftCard.customRestrictionValue);
          if (!await this.canForceGiftCard(text, paymentGiftCard)) {
            formGroup.get('paymentGiftCard').setValue(null);
            return;
          }
        }
      }

      const id = paymentGiftCard.id;
      const otherPayments = this.getPayments().filter(f => f !== formGroup);
      if (otherPayments.map(f => f.getRawValue() as NxtPayment).find(p => {
        return p.paymentGiftCard && p.paymentGiftCard.id === id;
      })) {
        this.dialogService.showOk('Dieser Gutschein ist bereits diesem Termin zugewiesen');
        formGroup.get('paymentGiftCard').setValue(null);
        return;
      }

      if (paymentGiftCard.validFrom) {
        const validFrom = paymentGiftCard.validFrom.dateAddDays(-1);
        if (validFrom && validFrom > DateTools.parse(this.eventDate)) {
          const text = 'Dieser Gutschein ist erst ab dem ' + DateTools.format(paymentGiftCard.validFrom, 'dd.MM.yyyy') + ' gültig';
          const useItForce = await this.dialogService.showYesNo(text, {noText: 'OK', yesText: 'Ausnahmsweise akzeptieren'});
          if (!useItForce) {
            formGroup.get('paymentGiftCard').setValue(null);
            return;
          }
        }
      }

      const hasOther50PercentGiftCards = this.formPayments.getRawValue().some(f => {
        return f.paymentGiftCard && f.paymentGiftCard.discountPercentage === 50 && paymentGiftCard.name !== f.paymentGiftCard.name;
      });
      if (paymentGiftCard.discountPercentage === 50 && hasOther50PercentGiftCards && false) {
        const text = 'Ein 50% Gutschein kann nicht mit einem weiteren 50% Gutschein kombiniert werden';
        await this.dialogService.showOk(text);
        formGroup.get('paymentGiftCard').setValue(null);
        return;
      }


      if (paymentGiftCard.bookingInfo) {
        await this.dialogService.showOk(paymentGiftCard.bookingInfo);
      }
      const date = DateTools.format(paymentGiftCard.createdAt, 'yyyy-MM-dd');
      formGroup.get('paymentDate').setValue(date);
      formGroup.get('paymentValue').setValue(paymentGiftCard.value);
      this.clickLastPaymentControl('paymentValue', true);
    }
  }


  async paymentTypeChange(formGroup: UntypedFormGroup, from: string) {
    await TimeTools.sleep(50);
    console.log(from);
    const paymentType = formGroup.get('paymentType') as NxtFormControl;

    /*if (paymentType.value === 'deposit-back' && paymentType.getOldValue() !== 'deposit-back') {
      this.dialogService.showOk('Versuch dem Kunden ein Gutschein zu geben ;)');
      focusNext = false;
    }*/
    // if ((formGroup.get('paymentType') as NxtFormControl).valueChanged() && formGroup.get('paymentType').value === 'payout') {
    if (formGroup.get('paymentType').value === 'payout') {
      if (paymentType.getOldValue() !== 'payout' && !formGroup.get('earlyPayout').value) {
        const earlyPayout = await this.dialogService.showYesNo('Vorzeitige Artistauszahlung?');
        formGroup.get('earlyPayout').setValue(earlyPayout);
      }
      formGroup.get('paymentMethod').setValue('cash');
      formGroup.get('paymentMethod').disable();
      const payments = this.formPayments.getRawValue();
      const toPayout = PaymentTools.calcPayout(payments, this.artistPercentage);
      formGroup.get('paymentValue').setValue(toPayout);
    } else {
      formGroup.get('paymentMethod').enable();
      if (formGroup.get('paymentType').value === 'free') {
        formGroup.get('paymentMethod').setValue('none');
        formGroup.get('paymentValue').setValue(0);
        formGroup.get('paymentMethod').disable();
      } else {
        formGroup.get('paymentMethod').enable();
      }
    }
    this.emitValueChange();

    if (formGroup.get('paymentType').value !== 'payout') {
      this.clickLastPaymentControl('paymentMethod');
    }
    this.checkIfPaymentCommentRequired(formGroup);
  }

  checkIfPaymentCommentRequired(formGroup: UntypedFormGroup) {
    formGroup.get('paymentComment').clearValidators();
    if (formGroup.get('paymentMethod').value === 'cash' && formGroup.get('paymentType').value === 'deposit-back') {
      // FormTools.setValidators(formGroup.get('paymentComment'), [ValidatorTools.requiredAndNotNaN]);
      this.clickLastPaymentControl('paymentComment');
    }
  }


  async paymentMethodChange(formGroup: UntypedFormGroup) {
    await TimeTools.sleep(50);
    formGroup.get('paymentValue').setValue(formGroup.get('paymentValue').value);
    formGroup.get('paymentPaypalTransaction').clearValidators();
    formGroup.get('paymentKlarnaOrder').clearValidators();
    formGroup.get('paymentBankTransaction').clearValidators();
    formGroup.get('paymentGiftCard').clearValidators();

    const paymentMethod = formGroup.get('paymentMethod') as NxtFormControl;
    const paymentMethodValue = paymentMethod.value as PaymentMethod;

    if (paymentMethodValue === 'gift-card') {
      formGroup.get('paymentKlarnaOrder').setValue(null);
      formGroup.get('paymentPaypalTransaction').setValue(null);
      formGroup.get('paymentBankTransaction').setValue(null);
      FormTools.setValidators(formGroup.get('paymentGiftCard'), [ValidatorTools.hasProperty('id')]);
    }

    if (paymentMethodValue === 'paypal') {
      formGroup.get('paymentKlarnaOrder').setValue(null);
      formGroup.get('paymentGiftCard').setValue(null);
      formGroup.get('paymentBankTransaction').setValue(null);
      FormTools.setValidators(formGroup.get('paymentPaypalTransaction'), [ValidatorTools.hasProperty('transactionId')]);
    }

    if (paymentMethodValue === 'klarna') {
      formGroup.get('paymentPaypalTransaction').setValue(null);
      formGroup.get('paymentGiftCard').setValue(null);
      formGroup.get('paymentBankTransaction').setValue(null);
      FormTools.setValidators(formGroup.get('paymentKlarnaOrder'), [ValidatorTools.hasProperty('orderId')]);
    }


    if (paymentMethodValue === 'bank') {
      formGroup.get('paymentKlarnaOrder').setValue(null);
      formGroup.get('paymentPaypalTransaction').setValue(null);
      formGroup.get('paymentGiftCard').setValue(null);
      if (formGroup.get('isNewPayment').value) {
        FormTools.setValidators(formGroup.get('paymentBankTransaction'), [ValidatorTools.hasProperty('id')]);
      }
    }
    if (paymentMethod.getOldValue() === 'klarna' || paymentMethod.getOldValue() === 'paypal' || paymentMethod.getOldValue() === 'gift-card' || paymentMethod.getOldValue() === 'bank') {

      if (paymentMethod.getOldValue() !== paymentMethodValue) {
        this.clearPaymentDateAndValue(formGroup);
      }

      if (!paymentMethod.getFirstValueSet()) {
        formGroup.get('paymentKlarnaOrder').setValue(null);
        formGroup.get('paymentGiftCard').setValue(null);
        formGroup.get('paymentPaypalTransaction').setValue(null);
        formGroup.get('paymentBankTransaction').setValue(null);
      }
    }

    if (paymentMethodValue === 'klarna' || paymentMethodValue === 'paypal' || paymentMethodValue === 'gift-card' || paymentMethodValue === 'bank') {
      if (!paymentMethod.getFirstValueSet()) {
        formGroup.get('paymentPaypalTransaction').setValue(null);
        formGroup.get('paymentKlarnaOrder').setValue(null);
        formGroup.get('paymentGiftCard').setValue(null);
        formGroup.get('paymentBankTransaction').setValue(null);
      }
    } else {
      if (paymentMethod.getOldValue() === 'klarna' || paymentMethod.getOldValue() === 'paypal' || paymentMethod.getOldValue() === 'gift-card' || paymentMethod.getOldValue() === 'bank') {
        formGroup.get('paymentValue').setValue(null);
        formGroup.get('paymentDate').setValue(null);
      }
      if (formGroup.get('isNewPayment').value) {
        // formGroup.get('paymentDate').enable();
        formGroup.get('paymentValue').enable();
      }

      if (paymentMethodValue === 'cash' && !this.eventIsClosed) {
        if (formGroup.get('isNewPayment').value) {
          formGroup.get('paymentDate').setValue(DateTools.format(Date.now(), 'yyyy-MM-dd'));
        }
      }

    }

    if (DateTools.dateDiffToNow(this.loadTime) > 2000) {
      setTimeout(() => {
        try {
          if (paymentMethodValue === 'cash') {
            // this.getLastPaymentControl('paymentValue').click();
            requestAnimationFrame(() => {
              this.getLastPaymentNxtFormControl('paymentValue')?.formControlWrapper?.select();
            });
          } else if (paymentMethodValue === 'bank') {
            this.getLastPaymentControl('paymentBankTransaction').click();
          } else if (paymentMethodValue === 'gift-card') {
            this.getLastPaymentControl('paymentGiftCard').click();
          } else if (paymentMethodValue === 'klarna') {
            this.getLastPaymentControl('paymentKlarnaOrder').click();
          } else if (paymentMethodValue === 'paypal') {
            this.getLastPaymentControl('paymentPaypalTransaction').click();
          }
        } catch (err) {
        }
      }, 200);
    }
    this.emitValueChange();
    this.checkIfPaymentCommentRequired(formGroup);
  }

  private clearAndDisablePaymentDateAndValue(formGroup: UntypedFormGroup) {
    formGroup.get('paymentValue').setValue(null);
    formGroup.get('paymentValue').disable();
    formGroup.get('paymentDate').setValue(DateTools.format(Date.now(), 'yyyy-MM-dd'));
    formGroup.get('paymentDate').disable();
  }

  private clearPaymentDateAndValue(formGroup: UntypedFormGroup) {
    formGroup.get('paymentValue').setValue(null);
    formGroup.get('paymentDate').setValue(DateTools.format(Date.now(), 'yyyy-MM-dd'));
    formGroup.get('paymentDate').disable();
  }

  private calcHasPaymentType(paymentTypes: PaymentType) {
    return this.formPayments.controls.filter(formGroup => formGroup.get('paymentType').value === paymentTypes).length > 0;
  }

  private calcPaymentSumByPaymentTypes(paymentTypes: PaymentType[]) {
    return this.formPayments.controls
      .filter(formGroup => paymentTypes.indexOf(formGroup.get('paymentType').value) > -1)
      .reduce((sum, payment) => {
        const value = parseFloat(payment.get('paymentValue').value);
        if (!Number.isNaN(value)) {
          return sum + value;
        }
        return sum;
      }, 0);
  }

  private calcPaymentSumByPaymentTypesReal(paymentTypes: PaymentType[]) {
    return this.formPayments.controls
      .filter(formGroup => paymentTypes.indexOf(formGroup.get('paymentType').value) > -1)
      .reduce((sum, payment) => {
        const p = payment.getRawValue() as NxtPayment;
        if (typeof p.paymentGiftCard?.paymentValue === 'number') {
          const value = p.paymentGiftCard?.paymentValue;
          if (TypeTools.isNumberAndNotNaN(value)) {
            return sum + value;
          }
        } else {
          const value = parseFloat(payment.get('paymentValue').value);
          if (!Number.isNaN(value)) {
            return sum + value;
          }
        }
        return sum;
      }, 0);
  }

  async showInfo(info: string) {
    switch (info) {
      case 'addPaymentWithKeyboard':
        await this.dialogService.showOk('\nSei schneller und benutz die Tastatur\n\n Um eine Zahlung hinzuzufügen,\ndrücke die große + Taste rechts auf der Tastatur', {
          title: 'Tipp',
          buttonText: 'OK, mach ich',
        });
        break;
    }
  }

  paymentValueChange(payment: UntypedFormGroup) {
    this.calcPaymentValueSum();
    this.emitValueChange();
  }


  adminClicked() {
    if (this.permissionService.hasPermission(NxtPermissionId.CalendarEventEdit_EditPayments)) {
      this.removePaymentsEnabled = !this.removePaymentsEnabled;

      this.getPayments().forEach(group => {
        Object.keys(group.controls).map(key => {
          group.get(key).enable();
        });
      });
    }
  }

  private clickLastPaymentControl(nxtControlName: 'paymentType' | 'paymentMethod' | 'paymentValue' | 'paymentGiftCard' | 'paymentKlarnaOrder' | 'paymentPaypalTransaction' | 'paymentBankTransaction' | 'paymentComment', selectAll = false) {
    setTimeout(() => {
      const lastControl = this.getLastPaymentControl(nxtControlName);
      if (lastControl) {
        lastControl.click();
      }
      if (selectAll) {
        setTimeout(() => (this.getLastPaymentControl(nxtControlName) as HTMLInputElement)?.select(), 200);
      }
    }, 100);
  }


  private checkHasPaymentTypes() {
    for (const paymentType of Object.keys(PaymentTypes)) {
      this.hasPaymentType[PaymentTypes[paymentType]] = this.calcHasPaymentType(PaymentTypes[paymentType]);
    }
  }

  possiblePaymentMethodsHas(paymentMethod: string) {
    return !!this.possiblePaymentMethods?.find(p => p.value === paymentMethod);
  }

  typeof(value: any) {
    return typeof value;
  }

  private emitValueChange() {
    if (!this.inInitTime) {
      this.valueChange.emit(this.formPayments);
    }
  }

  public showGiftCard(giftCard: any) {
    const dialog = this.dialogService.showComponentDialog(SearchComponent, {});
    dialog.componentInstance.searchType = dialog.componentInstance.searchTypes[2];
    dialog.componentInstance.searchText = giftCard.name;
    dialog.componentInstance.search();
    dialog.componentInstance.fromEvent = true;
  }

  public async refundPaypal(paymentToRefund: NxtPayment) {
    if (this.getPayments().some(p => p.value.paymentType === 'payout')) {
      this.dialogService.showOk('Nicht möglich Es wurde bereits eine Auszahlung getätigt.');
      return;
    }
    const paypalTransaction = await this.socketService.getPaypalTransactionFirstParent(paymentToRefund.paymentPaypalTransaction.paypalTransactionId);


    let alreadyRefunded = 0;
    if (paypalTransaction.refunds && paypalTransaction.refunds.length > 0) {
      alreadyRefunded += paypalTransaction.refunds.reduce((sum, r) => sum + r.value, 0);
    }
    const maxRefund = paypalTransaction.originalValue - alreadyRefunded;

    let message = '<table class="nxt-table"><tr></tr>';
    message += '<tr><td colspan="2" style="text-align: center;">' + paypalTransaction.receiverEmail + '</td></tr>';
    message += '<tr><td>Gesamt-Betrag der PayPal-Zahlung</td><td>' + paypalTransaction.originalValue.toMoneyString() + '</td></tr>';
    message += '<tr><td>Bereits erstattet</td><td>' + alreadyRefunded.toMoneyString() + '</td></tr>';
    message += '<tr><td>Maximaler Erstattungsbetrag</td><td>' + maxRefund.toMoneyString() + '</td></tr>';
    message += '</table>';

    // let message = 'Gesamt-Betrag der PayPal-Zahlung: ' + paypalTransaction.valueFull.toMoneyString();

    if (alreadyRefunded > 0) {
      message += '<br/><table class="w-full nxt-table"><tr><td colspan="3" class="center">Bereits getätigte Rückzahlungen</td></tr>';
      message += paypalTransaction.refunds
        .map(r => '<tr><td class="center">' + r.createdAt.dateFormat('dd.MM.yyyy HH:mm') + '</td><td class="right">' + r.value.toMoneyString() + '</td><td>' + r.createdBy + '</td></td>')
        .join('');
      message += '</table>';
    }
    const title = 'Paypal-Rückerstattung';
    const prompt = paymentToRefund.paymentValue < maxRefund ? paymentToRefund.paymentValue : maxRefund;

    if (maxRefund === 0) {
      this.dialogService.showOk(message, {title});
    } else {
      const valueToRefund = await this.dialogService.showInput(message, {

        title,
        isMoney: true,
        prompt,
        placeholder: 'Wie viel soll erstattet werden?',
      });
      if (TypeTools.isNumber(valueToRefund)) {
        if (valueToRefund > maxRefund) {
          this.dialogService.showOk('Du kannst nur maximal ' + maxRefund.toMoneyString() + ' zurück zurückerstatten');
          return;
        }
        const refundReason = await this.dialogService.showInput('Kurzen Grund');
        if (refundReason) {
          const refundResult = await this.socketService.paypalRefund(
            {
              captureId: paypalTransaction.paypalTransactionId,
              value: valueToRefund,
              receiverEmail: paypalTransaction.receiverEmail,
              reason: refundReason,
              assignedNxtPayPalTransactionId: paymentToRefund.paymentPaypalTransaction.paypalTransactionId,
            },
          );
          if (refundResult.success) {
            this.dialogService.showOk('Rückerstattung erfolgreich');
          } else {
            this.dialogService.showOk('Fehler bei der Rückerstattung\n' + refundResult.message);
          }
        }
      }
    }
  }

  showKlarnaDetails(klarnaOrder: NxtKlarnaOrder) {
    const dialog = this.dialogService.showComponentDialog(KlarnaOrderRawComponent,
      {
        klarnaOrderId: klarnaOrder.klarnaOrderId,
      });
    dialog.componentInstance.load();
    dialog.componentInstance.showCloseButton = true;
    dialog.componentInstance.showShopOrder = true;
    dialog.componentInstance.onShowEvent.subscribe((eventId) => {
      window.open(StudioRegionBrowserTools.getLinkForEvent(eventId), '_blank');
    });
    dialog.componentInstance.onShowShopOrder.subscribe((shopOrderId) => {
      this.showShopOrder(shopOrderId);
      dialog.close();
    });
  }

  private showShopOrder(shopOrderId: string) {
    const dialog = this.dialogService.showComponentDialog(ShopOrderViewComponent, {shopOrderId});
    dialog.componentInstance.showCloseButton = true;
    dialog.componentInstance.load();
  }

  showTattooTicket(shopOrderLineItemId: string, type: 'klarna_payments' | 'paypal') {
    this.tattooTicketService.showTattooTicket(shopOrderLineItemId, type, this.eventId);
  }

  showPaypalDirectInfo() {
    this.dialogService.showOk('Die Zahlung wurde direkt geschickt, Rückzahlung ist nur direkt über PayPal möglich');
  }

  showBankPaymentInfoClicked(bankPayment: any) {
    if (this.loginService.isJulian()) {
      this.dialogService.showOk(JsonTools.stringifyFormat(bankPayment));
    }
  }

  private addPaymentTypeIfNotExists() {
    if (this.paymentTypes) {
      const paymentsTypesFromPayments = this.getPaymentsAsNxtPayments().map(p => p.paymentType);
      const allPaymentTypes = this.paymentTypes.map(p3 => p3.value);
      const missingTypes = paymentsTypesFromPayments.filter(p2 => !allPaymentTypes.includes(p2)).unique();
      for (const missingType of missingTypes) {
        const toAdd = PaymentTools.paymentTypes.find(p => p.value === missingType);
        if (toAdd) {
          this.paymentTypes.push(toAdd);
        }
      }
    }
  }

  public async confirmClicked(payment: any) {
    if (!this.customer?.fullName) {
      this.dialogService.showOk('Bitte wähle erst einen Kunden aus');
      return false;
    }
    let result = false;
    const value = payment.getRawValue().paymentValue;
    const mobile = this.customer.mobileFormatted;
    const customerName = this.customer.fullName;
    const eventStartDateString = this.eventDate;
    const paymentMethod = payment.getRawValue().paymentMethod;
    if (paymentMethod === 'klarna') {
      const klarnaOrder = payment.getRawValue().paymentKlarnaOrder as NxtKlarnaOrder;
      const shopOrderId = klarnaOrder.shopOrderId;
      const paymentMethodId = klarnaOrder.id;
      result = await this.tattooTicketConfirmService.confirm({value, customerName, eventStartDateString, mobile, paymentMethod, paymentMethodId, shopOrderId});
    }
    if (paymentMethod === 'paypal') {
      const paypalTransaction = payment.getRawValue().paymentPaypalTransaction as NxtPaypalTransaction;
      const paymentMethodId = paypalTransaction.id;
      const shopOrderId = paypalTransaction.shopOrderId;
      result = await this.tattooTicketConfirmService.confirm({value, customerName, eventStartDateString, mobile, paymentMethod, paymentMethodId, shopOrderId});
    }
    if (!result) {

    }
  }

  public toPayOnEventDatePayedClicked(payed: NxtCalendarEventToPayOnEventDate) {
    if (payed.method === 'cash') {
      if (this.permissionService.hasPermission(NxtPermissionId.StudioCashReport_CanEditIncoming)) {
        const dialog = this.dialogService.showComponentDialog(CashReportIncomingOutgoingFormComponent);
        dialog.componentInstance.loadFormId('incoming', payed.methodId).then();
      }
    }
  }
}

