import {Component, OnDestroy, OnInit, Optional, signal, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {SocketService} from '../../services/socket/socket.service';
import {DialogService} from '../../services/dialog.service';
import {LoginService} from '../../services/login.service';
import {NxtColDef, NxtFieldTypeParams} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {CacheService} from '../../services/cache/cache.service';
import {NxtSubscriptionClass} from '../../classes/nxt-subscription-class';
import {IconTools} from '../../common-browser/helpers/icon.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {VersionCompareResult, VersionTools} from '../../common-browser/helpers/version.tools';
import {ConfigService} from '../../services/config.service';
import {NewValueParams} from 'ag-grid-community';
import {IntervalTools} from '../../common-browser/helpers/interval.tools';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {ColorTools} from '../../common-browser/helpers/color.tools';
import {LocalStorageService} from '../../services/local-storage.service';
import {MatTab, MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {DownloadService} from '../../services/download.service';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {SocketInterfaceResponse} from '../../common-interfaces/socket/socket-interface';
import _ from 'lodash';
import {SettingsComponent} from './settings/settings.component';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {SelectComponent} from '../form-controls/select/select.component';
import {DatePickerComponent} from '../form-controls/date-picker/date-picker.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {InputComponent} from '../form-controls/input/input.component';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {DocumentService} from '../../services/document.service';
import {LanguageTools} from '../../common-browser/helpers/language.tools';
import {WhatsappScreenshotComponent} from '../whatsapp-screenshot/whatsapp-screenshot.component';
import {PhotoIphoneInitQrCodeComponent} from './photo-iphone-init-qr-code/photo-iphone-init-qr-code.component';
import {NxtEventPhotoEvent} from '../../common-interfaces/nxt.event-photo-event.interface';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {BoxComponent} from '../box/box.component';
import {clone} from '../../common-browser/helpers/object.tools';
import {IcloudComponent} from '../icloud/icloud.component';
import {GlobalStudioShortName} from '../../common-interfaces/global-studio.interface';
import {NodeInstanceEditComponent} from './node-instance-edit/node-instance-edit.component';


@Component({
  selector: 'nxt-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  imports: [FlexModule, NgIf, NxtButtonIconComponent, MatTabGroup, MatTab, NxtDatagridComponent, InputComponent, NxtButtonComponent, DatePickerComponent, SelectComponent, SlideToggleComponent, SettingsComponent, NxtDatePipe, BoxComponent],
  standalone: true,
})
export class AdminComponent extends NxtSubscriptionClass implements OnInit, OnDestroy {

  constructor(
    private fb: UntypedFormBuilder,
    private socketService: SocketService,
    private dialogService: DialogService,
    public loginService: LoginService,
    public cacheService: CacheService,
    private configService: ConfigService,
    @Optional() public dialogRef: MatDialogRef<AdminComponent>,
    private localStorageService: LocalStorageService,
    private downloadService: DownloadService,
    private documentService: DocumentService,
  ) {
    super();
    this.pushSocketSubscription = this.socketService.subscribeNew('getClientConnections', (data) => {
      this.clientConnections = data;
      console.log('clientConnections', this.clientConnections);
    }, {emitInitial: true});

    this.pushSocketSubscription = this.socketService.subscribeNew('getClientServerConnections', (data) => {
      const newData = _.sortBy(data, ['data.description']);
      // .filter(c => !c.data.hostname.toLowerCase().includes('julian'));
      this.serverClientConnections = newData;
    }, {emitInitial: true, emitAfterReconnect: true});
  }

  lastPhotoDevice = signal<NxtEventPhotoEvent | null>(null);

  private refreshInterval: any;

  @ViewChild('devicesDatagridComponent') datagridComponent: NxtDatagridComponent;

  clientConnections: any;
  serverClientConnections: SocketInterfaceResponse.GetClientServerConnection[];
  quickFilterText = '';
  columnDefsConnections: NxtColDef[] = [
    {headerName: 'selber', field: 'self', cellRenderer: (params: any) => params.value ? IconTools.Material.Face : '', nxtCellStyle: {textAlign: 'center'}},
    {headerName: 'Benutzer', field: 'username'},
    {
      headerName: '', cellRenderer: () => IconTools.Material.Chat, nxtOnCellClicked: async client => {
        const message = await this.dialogService.showTextarea({message: 'Nachricht an' + client.data.socketId});
        if (message) {
          const result = await this.socketService.sendMessageToClient(client.data.socketId, message);
          this.dialogService.showOk(result);
        }
      },
    },
    {headerName: 'Studio', field: 'studio'},
    {headerName: 'Arbeitsplatz', field: 'workplace', nxtFieldType: NxtFieldType.Workplace},
    {headerName: 'IP', field: 'ip', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Verbunden seit', field: 'connectedTimestamp', nxtFieldType: NxtFieldType.Date_germanDateWithToNowText},
    {headerName: 'Aktiv', field: 'lastActive', nxtFieldType: NxtFieldType.Date_germanDateWithToNowText},
    // {headerName: 'isWhatsAppViewer', field: 'isWhatsAppViewer', nxtFieldType: NxtFieldType.Boolean},
    // {headerName: 'isCalendarViewer', field: 'isCalendarViewer', nxtFieldType: NxtFieldType.Boolean},
    // {headerName: 'studioCashReportDate', field: 'studioCashReportDate', nxtFieldType: NxtFieldType.Text},
    {headerName: 'socketId', field: 'socketId'},
    {headerName: 'URL', field: 'currentUrl'},
  ];
  columnDefsConnectionsServer: NxtColDef<SocketInterfaceResponse.GetClientServerConnection>[] = [
    {headerName: '', nxtFieldType: NxtFieldType.Icon, nxtIcon: IconTools.Material.Edit, nxtOnCellClicked: (params) => this.editClicked(params.data)},
    {
      headerName: 'ID', field: 'data.machineId',
      nxtFieldType: NxtFieldType.Text,
      maxWidth: 150,
      hide: true,
    }, {
      headerName: 'Info', field: 'data.description',
      nxtFieldType: NxtFieldType.Text,
      // editable: true,
      // onCellValueChanged: (params) => this.setServerDescription(params),
      cellStyle: (params) => params.data.live?.uptime ? {} : ({color: ColorTools.Red}),
    }, {
      headerName: 'Hostname', field: 'data.hostname', nxtFieldType: NxtFieldType.Text,
      cellStyle: (params) => params.data.live?.uptime ? {} : ({color: ColorTools.Red}),
    }, {
      headerName: 'npm i',
      cellRenderer: () => IconTools.Material.InputCircle,
      nxtOnCellClicked: (params) => this.serverNpmI(params.data),
      cellClass: 'nxt-cell-flex-center',
      sortable: false,
      maxWidth: 80,
      hide: true,
    }, {
      headerName: 'Update',
      cellRenderer: () => IconTools.Material.Update,
      nxtOnCellClicked: (params) => this.serverUpdate(params.data),
      cellClass: 'nxt-cell-flex-center',
      sortable: false,
      maxWidth: 75,
    }, {
      headerName: 'Neustart',
      cellRenderer: () => IconTools.Material.Reset, nxtOnCellClicked: (params) => this.serverRestart(params.data),
      cellClass: 'nxt-cell-flex-center',
      sortable: false,
      maxWidth: 80,
    },
    {
      headerName: 'Version',
      field: 'data.version',
      nxtFieldType: NxtFieldType.Text, maxWidth: 90,
    },
    {
      headerName: 'Überwachen',
      field: 'data.monitor',
      nxtFieldType: NxtFieldType.Boolean,
    },
    {
      headerName: 'Node',
      field: 'data.nodeVersion',
      nxtFieldType: NxtFieldType.Text, maxWidth: 90,
    },
    {
      headerName: 'letztes Lebenszeichen',
      field: 'data.serverTimestamp',
      nxtFieldType: NxtFieldType.DurationStringToNow,
      nxtFieldTypeParams: NxtFieldTypeParams<NxtFieldType.DurationStringToNow>({pastPrefix: 'vor'}),
    },
    {
      headerName: 'Server start',
      field: 'data.serverUptime',
      nxtFieldType: NxtFieldType.DurationStringToNow,
      nxtFieldTypeParams: NxtFieldTypeParams<NxtFieldType.DurationStringToNow>({pastPrefix: 'vor'}),
    },
    {
      headerName: 'App start', field: 'live.uptime',
      nxtFieldType: NxtFieldType.DurationStringToNow,
      nxtFieldTypeParams: NxtFieldTypeParams<NxtFieldType.DurationStringToNow>({pastPrefix: 'vor'}),
    },
    {
      headerName: 'Verbunden seit',
      field: 'live.connectedSince',
      nxtFieldType: NxtFieldType.DurationStringToNow,
      nxtFieldTypeParams: NxtFieldTypeParams<NxtFieldType.DurationStringToNow>({pastPrefix: 'seit'}),
    },
    {headerName: 'Öffentliche IP', field: 'live.ip', nxtFieldType: NxtFieldType.Text, maxWidth: 125},
    {
      headerName: 'lokale IP', field: 'data.localIps', nxtFieldType: NxtFieldType.Array,
      valueGetter: (params) => params.data.data.localIps.filter(i => !i.startsWith('172') && !i.startsWith('169')),
    },
    {
      headerName: 'Speicher', field: 'data.diskInfoObj',
      cellRenderer: (params: any) => {
        return params.value?.map(d => d.path + ' ' + d.usedPercentage + ' % belegt').join('<br/>');
      }, autoHeight: true,
    },
  ];
  // readShopId = '';
  deleteShopId = ''; // 6233 wurde gelöscht...
  public reSendShopId = '';


  /*async loadServerConnections() {
    this.serverConnections = await this.socketService.getClientServerConnections();
  }*/
  public version = this.configService.config.value.serverVersion;
  public resetInvoicesDateString = '';
  resetDayFinishString = '';
  selectedTab = this.localStorageService.getByString('adminTab', 0);
  declarationOfConsentOptions: { value: any; text: string }[] = [
    {text: 'Alle Seiten', value: -1},
    {text: '0 - Infoblatt', value: 0},
    {text: '1 - Seite 1', value: 1},
    {text: '2 - Seite 2', value: 2},
    {text: '3 - Zusatzinfos', value: 3},
    {text: '4 - Minderjährig', value: 4},
  ];
  declarationOfConsentPiercingOptions: { value: any; text: string }[] = [
    {text: 'Alle Seiten', value: -1},
    {text: '1 - Seite 1', value: 1},
    {text: '2 - Seite 2', value: 2},
    {text: '3 - Minderjährig', value: 3},
  ];
  afterCareInstructionPiercingOptions: { value: any; text: string }[] = [
    {text: 'Alle Seiten', value: -1},
    {text: '1 - Seite 1', value: 1},
    {text: '2 - Seite 2', value: 2},
    {text: '3 - Seite 3', value: 3},
  ];

  afterCareInstructionTattooOptions: { value: any; text: string }[] = [
    {text: 'Alle Seiten', value: -1},
    {text: '1 - Seite 1', value: 1},
    {text: '2 - Seite 2', value: 2},
  ];

  declarationOfConsentPage = -1;
  declarationOfConsentPiercingPage = -1;
  afterCareInstructionPiercingPage = -1;
  afterCareInstructionTattooPage = -1;
  declarationOfConsentPiercingEmployed = false;

  /*async readShop() {
    const result = await this.socketService.readShopOrderForce(this.readShopId);
    this.dialogService.showOk(result);
    this.readShopId = '';
  }*/
  additionalBankTransactionNoteIncluded = '';

  studioOptions = this.configService.config.value.studios.map(s => ({value: s.name, text: s.name}));
  workplaceOptions = [{value: 'reception', text: 'Empfang'}, {value: 'backoffice', text: 'Backoffice'}];

  studioLogins: string[] = this.localStorageService.get('StudioLogins', []);
  workplaceLogins: string[] = this.localStorageService.get('WorkplaceLogins', []);
  importInventoryStudios: { value: any; text: string }[] = [{text: 'Aachen', value: 'ac'}, {text: 'Düsseldorf', value: 'du'}];

  importInventoryFromStudio = signal<GlobalStudioShortName | ''>('');

  abc = () => {
    return 'declarations-of-consent';
  };

  ngOnDestroy(): void {
    IntervalTools.clear(this.refreshInterval);
    super.onDestroy();
  }

  async ngOnInit() {
    setTimeout(() => {
      this.datagridComponent.gridOptions.suppressRowTransform = true;
    }, 2000);
    this.refreshInterval = setInterval(() => {

      this.serverClientConnections = clone(this.serverClientConnections);

      // this.datagridComponent.api.refreshCells();
    }, 5000);

    this.lastPhotoDevice.set(await this.socketService.getLastPhotoDevice());
  }

  restartServer() {
    this.socketService.restartServer();
  }

  async invalidate(cacheObject: string) {
    if (await this.socketService.invalidate(cacheObject)) {
      this.dialogService.showOk('OK');
    } else {
      this.dialogService.showOk('FEHLER');
    }
  }


  public fakeWhatsAppStatus() {
    this.socketService.fakeWhatsAppStatus();
  }

  private async serverNpmI(data: SocketInterfaceResponse.GetClientServerConnection) {
    await this.socketService.clientServerRunNpmI(data.live.id);
    this.dialogService.showOk('npm i erfolgreich zu dem Gerät gesendet');
  }

  private async serverUpdate(data: SocketInterfaceResponse.GetClientServerConnection) {
    if (data.data.version) {
      if (VersionTools.compare('2.1.0', data.data.version) === VersionCompareResult.Version1Higher) {
        if (!(await this.dialogService.showYesNo('Geht erst ab Version 2.1.0\nTrotzdem ausführen?'))) {
          return;
        }
      }
      if (VersionTools.compare(this.configService.config.value.serverVersion, data.data.version) === VersionCompareResult.Version2Higher) {
        if (!(await this.dialogService.showYesNo('Geräte-Version ist bereits höher als die Server-Version ' + this.configService.config.value.serverVersion + '\nTrotzdem ausführen?'))) {
          return;
        }
      }
      if (VersionTools.compare(this.configService.config.value.serverVersion, data.data.version) === VersionCompareResult.Same) {
        if (!(await this.dialogService.showYesNo('Geräte-Version ist bereits die neuste Version ' + this.configService.config.value.serverVersion + '\nTrotzdem ausführen?'))) {
          return;
        }
      }
    }
    const result = await this.socketService.clientServerRunUpdate(data.live.id);
    if (result) {
      this.dialogService.showOk('Update erfolgreich zu dem Gerät gesendet');
    }
  }

  private serverRestart(data: any) {
    this.socketService.clientServerRestart(data.live.id);
  }


  /*private setServerDescription(params: NewValueParams<SocketInterfaceResponse.GetClientServerConnection>) {
    if (!params.data.data.machineId) {
      alert('geht nicht ohne maschine id');
    } else {
      this.socketService.setClientServerDescription(params.data.data.machineId, params.newValue);
    }
  }*/

  public async resetInvoicesDate2() {
    if (this.resetDayFinishString) {
      const withEarlyPayouts = await this.dialogService.showYesNo('Mit frühzeitigen Auszahlungen?');
      if (withEarlyPayouts) {
        if (!await this.dialogService.showYesNo('WIRKLICH mit frühzeitig???')) {
          return;
        }
      }
      const result = await this.socketService.resetDayFinish2(this.resetDayFinishString, withEarlyPayouts);
      this.resetDayFinishString = null;
      // this.dialogService.showOk(result);
    }
  }

  selectedTabChanged(ev: MatTabChangeEvent) {
    this.localStorageService.setByString('adminTab', ev.index);
  }

  async downloadDeclarationOfConsentTattoo() {
    const lang = await this.dialogService.showSelect('In welche Sprache?', LanguageTools.languageCodes.map(l => ({text: l.name, value: l.code})), {
      focusInputOnInit: true,
      closeOnOptionSelected: true,
      okButtonText: '',
    });
    if (lang) {
      this.documentService.downloadDeclarationOfConsentTattoo(this.declarationOfConsentPage, lang, true);
    }
  }

  async downloadDeclarationOfConsentPiercing() {
    const lang = await this.dialogService.showSelect('In welche Sprache?', LanguageTools.languageCodes.map(l => ({text: l.name, value: l.code})), {
      focusInputOnInit: true,
      closeOnOptionSelected: true,
      okButtonText: '',
    });
    if (lang) {
      this.documentService.downloadDeclarationOfConsentPiercing(this.declarationOfConsentPiercingPage, lang, true, false);
    }
  }

  async downloadAfterCareInstructionPiercing() {
    this.documentService.downloadAfterCareInstructionPiercing(this.afterCareInstructionPiercingPage);
  }

  async downloadAfterCareInstructionTattoo() {
    this.documentService.downloadAfterCareInstructionTattoo(this.afterCareInstructionTattooPage);
  }


  readBankTransaction() {
    this.socketService.importAdditionalBankTransaction(this.additionalBankTransactionNoteIncluded);
  }

  studioLoginChanged() {
    this.localStorageService.set('StudioLogins', this.studioLogins);
  }

  workplaceLoginChanged() {
    this.localStorageService.set('WorkplaceLogins', this.workplaceLogins);
  }

  async whatsAppDeleteClicked() {
    if (await this.dialogService.showYesNo('WhatsApp Daten löschen und QR-Code neu scannen?')) {
      this.dialogService.showLoading('WhatsApp wird zurück gesetzt...');
      const result = await this.socketService.whatsAppDelete();
      this.dialogService.hideLoading();
      this.dialogService.showOk(result);
    }
  }

  async showWhatsAppScreenShotClicked() {
    this.dialogService.showComponentDialog(WhatsappScreenshotComponent);
  }

  async setupPhotoIphoneClicked() {
    const options = [
      {text: 'iPhone 12', value: 'iPhone 12'},
      {text: 'iPhone 12 Pro', value: 'iPhone 12 Pro'},
      {text: 'iPhone 12 Pro Max', value: 'iPhone 12 Pro Max'},
      {text: 'iPhone 13', value: 'iPhone 13'},
      {text: 'iPhone 13 Pro', value: 'iPhone 13 Pro'},
      {text: 'iPhone 13 Pro Max', value: 'iPhone 13 Pro Max'},
      {text: 'iPhone 14', value: 'iPhone 14'},
      {text: 'iPhone 14 Plus', value: 'iPhone 14 Plus'},
      {text: 'iPhone 14 Pro', value: 'iPhone 14 Pro'},
      {text: 'iPhone 14 Pro Max', value: 'iPhone 14 Pro Max'},
      {text: 'iPhone 15', value: 'iPhone 15'},
      {text: 'iPhone 15 Plus', value: 'iPhone 15 Plus'},
      {text: 'iPhone 15 Pro', value: 'iPhone 15 Pro'},
      {text: 'iPhone 15 Pro Max', value: 'iPhone 15 Pro Max'},
    ];
    const model = await this.dialogService.showSelect('Wähle das Modell aus', options);
    if (model) {
      const dialog = this.dialogService.showComponentDialog(PhotoIphoneInitQrCodeComponent);
      dialog.componentInstance.setModel(model);
    }
  }

  showIcloudStateClicked() {
    this.dialogService.showComponentDialog(IcloudComponent);
  }

  async importInventoryClicked() {
    const importInventoryFromStudio = this.importInventoryFromStudio();
    if (importInventoryFromStudio) {
      this.dialogService.showLoading('Inventar wird importiert...');
      await this.socketService.importInventoryFromStudio(importInventoryFromStudio);
      this.dialogService.hideLoading();
    }
  }

  restartWhatsAppClicked() {
    this.socketService.whatsAppRestart().then();
    this.dialogService.showOk('WhatsApp wird neu gestartet...');
  }

  public deleteCachedAftercareClicked() {
    return this.socketService.deleteCachedAftercare();
  }

  private editClicked(data: SocketInterfaceResponse.GetClientServerConnection) {
    const dialog = this.dialogService.showComponentDialog(NodeInstanceEditComponent);
    dialog.componentInstance.id.set(data.data.id);
    dialog.componentInstance.description.set(data.data.description);
    dialog.componentInstance.monitor.set(data.data.monitor);
    dialog.componentInstance.hostname.set(data.data.hostname);
  }
}
