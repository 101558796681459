import {Component, Optional} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtWebFormSubmit} from '../../common-interfaces/nxt.web-form-submit.interface';
import {DialogService} from '../../services/dialog.service';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {ClipboardService} from '../../services/clipboard.service';
import {WebFormSubmitService} from '../../services/web-form-submit.service';
import {NxtFieldType} from 'src/app/common-interfaces/nxt-field.interface';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgIf} from '@angular/common';
import {NxtComponent} from '../nxt.component';

export enum WebFormSubmitStatus {
  new = 'Neu',
  writtenTo = 'Angeschrieben',
  close ='Fertig'
}

@Component({
    selector: 'nxt-web-form-submits',
    templateUrl: './web-form-submits.component.html',
    styleUrls: ['./web-form-submits.component.scss'],
    imports: [NgIf, FlexModule, NxtButtonIconComponent, NxtDatagridComponent]
})
export class WebFormSubmitsComponent extends NxtComponent {


  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
    private socketService: SocketService,
    private dialogService: DialogService,
    private clipboardService: ClipboardService,
    private webFormSubmitService: WebFormSubmitService
  ) {
    super();
    this.load();
  }

  closeIfEmpty = false;

  webFormSubmits: NxtWebFormSubmit[];

  columnDefs: NxtColDef[] = [
    {
      headerName: 'Datum',
      field: 'createdAt',
      nxtFieldType: NxtFieldType.Date_germanDateTime,
      nxtOnCellClicked: (params) => this.showInfo(params)
    },
    {
      headerName: 'Status',
      field: 'statusText',
      nxtFieldType: NxtFieldType.Text,
      nxtOnCellClicked: (params) => this.showInfo(params)
    },
    {
      headerName: 'Name',
      valueGetter: (params) => params.data.givenName + ' ' + params.data.familyName,
      nxtFieldType: NxtFieldType.Text,
      nxtOnCellClicked: (params) => this.showInfo(params)
    },
    {
      headerName: 'Ort',
      valueGetter: (params) => params.data.city,
      nxtFieldType: NxtFieldType.Text,
      nxtOnCellClicked: (params) => this.showInfo(params)
    },
    {
      headerName: 'Handy',
      field: 'mobile',
      nxtFieldType: NxtFieldType.Text,
      nxtOnCellClicked: (params) => this.showInfo(params)
    },
    {
      headerName: 'E-Mail',
      valueGetter: (params) => params.data.email,
      nxtFieldType: NxtFieldType.Text,
      nxtOnCellClicked: (params) => this.showInfo(params)
    },
    {
      headerName: 'via',
      field: 'contactVia',
      nxtFieldType: NxtFieldType.Text,
      nxtOnCellClicked: (params) => this.showInfo(params)
    },
    {
      headerName: 'Bild',
      field: 'imageUrl',
      nxtFieldType: NxtFieldType.ShowImage,
      cellStyle: {textAlign: 'center'}
    },
    {
      headerName: 'Text',
      field: 'text',
      nxtFieldType: NxtFieldType.Text,
      /*maxWidth: 300,*/
      nxtOnCellClicked: (params) => this.showInfo(params)
    }
  ];
  nxtOnDestroy(): void {

  }

  public async load() {
    this.pushSocketSubscription = this.socketService.subscribeNew('getOpenWebFormSubmits', (data) => {
      this.webFormSubmits = data;
      if (this.closeIfEmpty && this.webFormSubmits.length === 0) {
        this.dialogRef.close();
      }
    }, {emitInitial: true});
  }

  private async showInfo(row: any) {
    const webFormSubmit = row.data as NxtWebFormSubmit;
    this.clipboardService.copyToClipboard(webFormSubmit.givenName + ' ' + webFormSubmit.familyName);
    let text = DateTools.format(webFormSubmit.createdAt, 'HH:mm dd.MM.yyyy');
    text += '\n' + webFormSubmit.givenName + ' ' + webFormSubmit.familyName;
    text += '\n' + webFormSubmit.city;
    text += '\n\n' + webFormSubmit.text;

    const emailBody = this.webFormSubmitService.getFirstText(webFormSubmit);
    const subject = this.webFormSubmitService.getFirstTextEmailSubject(webFormSubmit);

    if (webFormSubmit.contactVia === 'E-Mail') {
      text += '\n\n<a href="mailto:' + webFormSubmit.email + '?subject=' + subject + '&body=' + encodeURIComponent(emailBody) + '">E-Mail schreiben</a>';
    }

    if (webFormSubmit.statusText === WebFormSubmitStatus.new) {
      if (await this.dialogService.showYesNo(text, {yesText: 'ich habe angeschreiben', noText: 'ok'})) {
        this.socketService.updateWebFormSubmit({...webFormSubmit, statusText: WebFormSubmitStatus.writtenTo});
      }
    } else if (webFormSubmit.statusText === WebFormSubmitStatus.writtenTo) {
      if (await this.dialogService.showYesNo(text, {yesText: 'ANFRAGE FERTIG', noText: 'ok'})) {
        this.socketService.updateWebFormSubmit({...webFormSubmit, isClosed: true});
      }
    }
  }
}
