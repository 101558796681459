import {Injectable} from '@angular/core';
import {DialogService} from './dialog.service';
import {PermissionService} from './permission.service';
import {CashMoneyCounterComponent} from '../pages/day-finish-view/cash-money-counter/cash-money-counter.component';
import {LoginService} from './login.service';
import {MoneyStackTools} from '../common-browser/helpers/money-stack.tools';
import {firstValueFrom} from 'rxjs';
import {DecimalTools} from '../common-browser/helpers/decimal.tools';
import {SocketService} from './socket/socket.service';
import {StudioCashReport2Service} from '../components/studio-cash-report-2/studio-cash-report-2.service';
import {MathTools} from '../common-browser/helpers/math.tools';
import {NxtMoneyStack} from '../common-interfaces/nxt.money-stack.interface';

@Injectable({providedIn: 'root'})
export class HandoverService {
  private studioCashReport2Service: StudioCashReport2Service;

  constructor(
    private dialogService: DialogService,
    private permissionService: PermissionService,
    private loginService: LoginService,
    private socketService: SocketService
  ) {
  }


  async startHandover(studioCashReport2Service: StudioCashReport2Service, askStart = true) {
    if (studioCashReport2Service.cashRegisterView.currentUser !== this.loginService.getUsername()) {
      await this.dialogService.showOk('Du führst diese Kasse nicht');
      return;
    }
    this.studioCashReport2Service = studioCashReport2Service;
    const shouldValue = this.studioCashReport2Service.cashRegisterView.startMoney
      + this.studioCashReport2Service.cashRegisterViewCalc.incomingPaymentsSum
      + this.studioCashReport2Service.cashRegisterViewCalc.incomingPaymentsSum_
      - this.studioCashReport2Service.cashRegisterViewCalc.outgoingPaymentsSum
      - this.studioCashReport2Service.cashRegisterViewCalc.outgoingPaymentsSum_;

    if (askStart && !await this.dialogService.showYesNo('Du möchtest eine Kassenübergabe starten?')) {
      return;
    }
    this.studioCashReport2Service.log('Kassenübergabe gestartet');
    const dialog = await this.dialogService.showComponentFull(CashMoneyCounterComponent);
    dialog.componentInstance.setData(shouldValue, 'Kasse zählen ' + this.loginService.getUsername(), MoneyStackTools.getEmptyMoneyStack());
    dialog.componentInstance.showShould = false;
    const moneyStackFrom: NxtMoneyStack = await firstValueFrom(dialog.afterClosed());
    if (moneyStackFrom) {
      const moneyStackFromSum = MoneyStackTools.getTotalValueFromMoneyStack(moneyStackFrom);
      const missingValue = MathTools.roundMoney(shouldValue - moneyStackFromSum);
      this.studioCashReport2Service.log('Kassenübergabe: ' + moneyStackFromSum.toMoneyString() + ' gezählt');
      if (missingValue !== 0) {
        const stateText = missingValue > 0 ? 'zu wenig' : 'zu viel';
        if (Math.abs(missingValue) > 40) {
          const text = 'Die Kasse stimmt nicht.\n\nDu hast ' + Math.abs(missingValue).toMoneyString() + ' ' + stateText + ', damit gehts nicht weiter';
          this.studioCashReport2Service.log('Kassenübergabe: ' + text);
          await this.dialogService.showOk(text);
          return;
        } else {
          const doReCalc = await this.dialogService.showYesNo('Die Kasse stimmt nicht.\n\nDu hast ' + Math.abs(missingValue) + ' ' + stateText, {
            noText: 'Weiter',
            yesText: 'Ich zähle nochmal'
          });
          if (doReCalc) {
            return this.startHandover(studioCashReport2Service, false);
          }
        }
      }
      const newUsername = await this.loginService.chooseUsername('An wen wird die Kasse übergeben?');
      if (newUsername) {
        this.studioCashReport2Service.log('Kassenübergabe: Übergabe an ' + newUsername);
        if (newUsername === this.loginService.getUsername()) {
          await this.dialogService.showOk('Du kannst die Kasse nicht an dich selbst übergeben');
          this.studioCashReport2Service.log('Kassenübergabe: Du kannst die Kasse nicht an dich selbst übergeben');
          return;
        }
        let reCalcNewUser = true;
        while (reCalcNewUser) {
          const dialog2 = await this.dialogService.showComponentFull(CashMoneyCounterComponent);
          dialog2.componentInstance.setData(moneyStackFromSum, 'Kasse zählen ' + newUsername, MoneyStackTools.getEmptyMoneyStack());
          dialog2.componentInstance.showShould = false;
          const moneyStackTo: NxtMoneyStack = await firstValueFrom(dialog2.afterClosed());
          if (moneyStackTo) {
            const moneyStackToSum = MoneyStackTools.getTotalValueFromMoneyStack(moneyStackTo);
            this.studioCashReport2Service.log('Kassenübergabe: ' + newUsername + ' hat ' + moneyStackToSum.toMoneyString() + ' gezählt');
            if (!MoneyStackTools.isSame(moneyStackFrom, moneyStackTo)) {
              let text2 = this.loginService.getUsername() + ' hat ' + DecimalTools.toMoneyString(moneyStackFromSum) + ' gezählt';
              if (moneyStackFromSum === moneyStackToSum) {
                text2 = 'Die gezählten Scheine / Münzen stimmen nicht überein';
              }
              reCalcNewUser = await this.dialogService.showYesNo(text2, {
                noText: this.loginService.getUsername() + ' zählt nochmal',
                yesText: newUsername + ' zählt nochmal'
              });
              if (!reCalcNewUser) {
                return this.startHandover(studioCashReport2Service, false);
              }
            } else {
              this.studioCashReport2Service.log('Kassenübergabe fertig');
              await this.studioCashReport2Service.createHandover(newUsername, shouldValue, moneyStackFromSum, moneyStackFrom);
              this.loginService.userChange(newUsername, this.loginService.getStudio());
              this.socketService.logoutUsernameAll();
              reCalcNewUser = false;
            }
          } else {
            return;
          }
        }
      }
    }
  }
}
