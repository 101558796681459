<nxt-tattoo-ticket
  [shopOrder]="shopOrder()"
  [fromEventId]="fromEventId"
  [tattooTicketItems]="mainWithChildrenTransactionsAndOuts()"
  [isDialog]="isDialog"
  (doRefund)="refundClicked()"
  (showEvent)="showEvent.emit($event)"
  (showShopOrder)="showShopOrder.emit($event)"
></nxt-tattoo-ticket>
<div *ngIf="isDialog" class="w-full flex flex-row justify-center mt-3">
  <nxt-button (click)="dialogRef.close()">Schließen</nxt-button>
</div>
