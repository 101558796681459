import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nxtPhotoType',
  standalone: true,
})
export class NxtPhotoTypePipe implements PipeTransform {

  constructor() {
  }

  transform(photoType: string): any {
    switch (photoType) {
      case 'tattoo':
        return 'Tattoo';
      case 'stencil':
        return 'Stencil';
      case 'template':
        return 'Vorlage';
      case 'preTattoo':
        return 'Vorher';
    }
    return '';
  }
}
