import {inject, Injectable} from '@angular/core';
import {SocketService} from '../socket/socket.service';
import {BankSubSocketService} from '../socket/sub-socket-services/bank-sub-socket.service';
import {DialogService} from '../dialog.service';
import {BankCreateTransferComponent, CreateBankTransferDialogData} from '../../components/bank/bank-create-transfer/bank-create-transfer.component';
import {firstValueFrom} from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class BankTransferService {
  /*** Injections ***/
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  private bankSubSocketService = inject(BankSubSocketService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  async createBankTransferDialog(data: CreateBankTransferDialogData) {
    const dialog = this.dialogService.showComponentDialog(BankCreateTransferComponent);
    dialog.componentInstance.setData(data);
    return firstValueFrom(dialog.afterClosed());
  }
}
