import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
  standalone: true
})
export class HighlightPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  transform(text: string, highlight: string): any {
    if (text == null || text.length === 0 || highlight?.length < 2) {
      return text;
    }
    return this.highlight(text, highlight);
  }

  escapeRegExp(regex: string) {
    const specialChars = ['$', '^', '*', '(', ')', '+', '[', ']', '{', '}', '\\', '|', '.', '?', '/'];
    const regexSpecialChars = new RegExp('(\\' + specialChars.join('|\\') + ')', 'g');
    return regex.replace(regexSpecialChars, '\\$1');
  }

  highlight(text: string, highlight: string) {
    if (highlight && text) {
      const newText = text.replace(new RegExp(this.escapeRegExp(highlight), 'gi'), str => `<mark class="highlight">${str}</mark>`);
      console.log(newText);
      return newText;
    }

    return text;
  }

  /*sanitize(str) {
    return this.sanitizer.sanitize(SecurityContext.HTML, str);
  }*/
}
