import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {EventComponent} from '../../event/event.component';
import {SocketService} from '../../services/socket/socket.service';
import {DialogService} from '../../services/dialog.service';
import {NxtContact} from '../../common-interfaces/nxt.contact.interface';
import {LoginService} from '../../services/login.service';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {CacheService} from '../../services/cache/cache.service';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {IconTools} from '../../common-browser/helpers/icon.tools';
import {NxtComponent} from '../nxt.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NgIf} from '@angular/common';
import {InputComponent} from '../form-controls/input/input.component';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-contact',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss'],
    imports: [FlexModule, SlideToggleComponent, InputComponent, NgIf, NxtDatagridComponent, NxtButtonComponent]
})
export class ContactsComponent extends NxtComponent implements OnInit, OnDestroy {

  columnDefs: NxtColDef[] = [
    {
      nxtOnCellClicked: (params) => this.dialogService.showContactForm(params.data),
      nxtIcon: IconTools.Material.Edit,
      getQuickFilterText: () => ''
    },
    {
      nxtFieldType: NxtFieldType.ShowContactHistory,
      headerName: '',
      field: 'id',
      getQuickFilterText: () => ''
    },
    {headerName: '_id', field: '_id', nxtFieldType: NxtFieldType.Text, hide: true, getQuickFilterText: () => ''},

    {headerName: 'Vorname', field: 'givenName', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Nachname', field: 'familyName', nxtFieldType: NxtFieldType.Text},
    {
      headerName: 'Geburtstag', field: 'birthday', nxtFieldType: NxtFieldType.Date_germanDate, valueFormatter: (params) => {
        if (params.value) {
          return DateTools.format(params.value, 'dd.MM.yyyy') + ' (' + DurationTools.getAge(params.value) + ')';
        }
      }
    }, {
      headerName: 'geschl. Termindauer', valueGetter: (params) => {
        const durations = params.data?.closedEvents?.map(e => e.end - e.start);
        const totalDuration = durations?.reduce((sum, duration) => sum + duration, 0);
        return totalDuration;
      },
      valueFormatter: (params) => {
        if (params.value) {
          return DurationTools.getTotalHoursAndMinutes(params.value);
        }
      },
      nxtFieldType: NxtFieldType.Text,
      minWidth: 60,
      getQuickFilterText: (params) => '',
    }, {
      headerName: 'abgesagte Termine', field: 'canceledEvents',
      nxtFieldType: NxtFieldType.CalendarEvents, minWidth: 60,
      getQuickFilterText: (params) => ''
    }, {
      headerName: 'fertige Termine', field: 'closedEvents',
      nxtFieldType: NxtFieldType.CalendarEvents,
      minWidth: 60,
      getQuickFilterText: (params) => '',
    }, {
      headerName: 'offene Termine', field: 'events',
      nxtFieldType: NxtFieldType.CalendarEvents, minWidth: 60,
      getQuickFilterText: (params) => ''
    },
    {headerName: 'Handy formatiert', field: 'mobileFormatted', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Handy', field: 'mobile', nxtFieldType: NxtFieldType.Text, getQuickFilterText: (params) => ''},
    {headerName: 'Straße', field: 'streetAddress', nxtFieldType: NxtFieldType.Text, minWidth: 150},
    {headerName: 'PLZ', field: 'postalCode', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Ort', field: 'city', nxtFieldType: NxtFieldType.Text, minWidth: 150},
    {headerName: 'E-Mail', field: 'email', nxtFieldType: NxtFieldType.Text, minWidth: 200},
    {headerName: 'id', field: 'id', nxtFieldType: NxtFieldType.Text, getQuickFilterText: (params) => ''},
  ];
  quickFilterText = '';
  contactsWithEvents: any[];
  showDoubleNumbers = false;
  contactsWithEventsDouble: any[];

  constructor(
    private fb: UntypedFormBuilder,
    @Optional() private dialogRef: MatDialogRef<EventComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: string | NxtContact,
    private socketService: SocketService,
    private dialogService: DialogService,
    public loginService: LoginService,
    public cacheService: CacheService
  ) {
    super();
  }

  ngOnInit(): void {
  }


  close() {
    this.dialogRef.close();
  }

  showDoubleNumbersChanged() {
    if (this.showDoubleNumbers) {
      this.contactsWithEventsDouble = this.contactsWithEvents.filter(c => c.mobileFormatted?.startsWith('+') && this.contactsWithEvents.filter(c2 => c2.mobileFormatted === c.mobileFormatted && c2.id !== c.id).length > 0);
    }
  }

  nxtOnDestroy(): void {
  }
}
