import {inject, Injectable} from '@angular/core';
import {DialogService, LoadingId} from './dialog.service';
import {SocketService} from './socket/socket.service';
import {ConfigService} from './config.service';
import {DownloadService} from './download.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  /*** Injections ***/
  dialogService = inject(DialogService);
  socketService = inject(SocketService);
  configService = inject(ConfigService);
  downloadService = inject(DownloadService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  async downloadDeclarationOfConsentTattoo(pageCount: number, lang: string, withParents: boolean) {
    this.dialogService.showLoading(LoadingId.Download, 'Tattoo-Einwilligungserklärung wird erstellt...');
    const pdfBase64 = await this.socketService.getDeclarationOfConsentTattoo(pageCount, lang, withParents);
    let fileName = 'Einwilligungserklärung_Tattoo_' + lang + '_' + this.configService.config.value.studioName + '.pdf';
    if (pageCount !== -1) {
      fileName = 'Einwilligungserklärung_Tattoo_' + lang + '_' + this.configService.config.value.studioName + '_' + pageCount + '.pdf';
    }
    this.dialogService.showPdf(pdfBase64, fileName, {askPrintCount: true});
    this.dialogService.hideLoading(LoadingId.Download);
  }

  async downloadDeclarationOfConsentPiercing(pageCount: number, lang: string, withParents: boolean, artistEmployed: boolean) {
    this.dialogService.showLoading(LoadingId.Download, 'Piercing-Einwilligungserklärung wird erstellt...');
    const pdfBase64 = await this.socketService.getDeclarationOfConsentPiercing(pageCount, artistEmployed, lang, withParents);
    let fileName = 'Einwilligungserklärung_Piercing_' + this.configService.config.value.studioName + '.pdf';
    if (pageCount !== -1) {
      fileName = 'Einwilligungserklärung_Piercing_' + this.configService.config.value.studioName + '_' + pageCount + '.pdf';
    }
    this.dialogService.showPdf(pdfBase64, fileName, {askPrintCount: true});
    this.dialogService.hideLoading(LoadingId.Download);
  }

  async downloadDeclarationOfConsentToothGem(pageCount: number, lang: string, withParents: boolean, artistEmployed: boolean) {
    this.dialogService.showLoading(LoadingId.Download, 'Zahnschmuck-Einwilligungserklärung wird erstellt...');
    const pdfBase64 = await this.socketService.getDeclarationOfConsentToothGem(pageCount, false, lang, withParents);
    let fileName = 'Einwilligungserklärung_Zahnschmuck_' + this.configService.config.value.studioName + '.pdf';
    if (pageCount !== -1) {
      fileName = 'Einwilligungserklärung_Zahnschmuck_' + this.configService.config.value.studioName + '_' + pageCount + '.pdf';
    }
    this.dialogService.showPdf(pdfBase64, fileName, {askPrintCount: true});
    this.dialogService.hideLoading(LoadingId.Download);
  }

  async downloadAfterCareInstructionTattoo(pageCount = -1) {
    this.dialogService.showLoading(LoadingId.Download, 'Tattoo-Pflegehinweise wird erstellt...');
    const pdfBase64 = await this.socketService.getAfterCareInstructionTattoo(pageCount);
    let fileName = 'Pflegehinweise_Tattoo_' + this.configService.config.value.studioName + '.pdf';
    if (pageCount !== -1) {
      fileName = 'Pflegehinweise_Tattoo_' + this.configService.config.value.studioName + '_' + pageCount + '.pdf';
    }
    this.dialogService.showPdf(pdfBase64, fileName, {askPrintCount: true});
    this.dialogService.hideLoading(LoadingId.Download);
  }

  async downloadAfterCareInstructionPiercing(pageCount = -1) {
    this.dialogService.showLoading(LoadingId.Download, 'Piercing-Pflegehinweise wird erstellt...');
    const pdfBase64 = await this.socketService.getAfterCareInstructionPiercing(pageCount);
    let fileName = 'Pflegehinweise_Piercing_' + this.configService.config.value.studioName + '.pdf';
    if (pageCount !== -1) {
      fileName = 'Pflegehinweise_Piercing_' + this.configService.config.value.studioName + '_' + pageCount + '.pdf';
    }
    this.dialogService.showPdf(pdfBase64, fileName, {askPrintCount: true});
    this.dialogService.hideLoading(LoadingId.Download);
  }
}
