export interface NxtUser {
  id: string;
  username: string;
  permissions: NxtUserPermission[];
  backgroundColor: string;
  color: string;
  realName: string;
  // password?: string;
  workplaces: string[];
  cashReportCreator: boolean;
  disableDailyLogin: boolean;
  startPage: string;
  disabled: boolean;
  hidden: boolean;
  lastLoginManual: number;
  lastLogin: number;
  photoDeviceName: string;
  birthday: string | null;
  mobilePrivate: string;
  mobileBusiness: string;
  noPerson: boolean;
  canSafeMoneyGet: boolean;
}

export interface NxtUserPermission {
  id: NxtPermissionId;
  granted: boolean;
}

export enum NxtPermissionId {
  Empty = '',
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
  CalendarEventEdit_canMoveEventEarlierThan3Days = 'CalendarEventEdit_canMoveEventEarlierThan3Days',
  StudioCashReport_DatePicker = 'StudioCashReport_DatePicker',
  StudioCashReport_ToggleBlockDayFinish = 'StudioCashReport_ToggleBlockDayFinish',
  ContactForm_WithoutBirthday = 'ContactForm_WithoutBirthday',
  ShowDurationPriceCheckColumn = 'ShowDurationPriceCheckColumn',
  StudioCashReport_CanEditOutgoing = 'StudioCashReport_CanEditOutgoing',
  StudioCashReport_CanEditIncoming = 'StudioCashReport_CanEditIncoming',
  StudioCashReport_SwitchStudios = 'StudioCashReport_SwitchStudios',
  StudioCashReport_Switch_ = 'StudioCashReport_Switch_',
  StudioCashReport_SeeMoneyEndOfDay = 'StudioCashReport_SeeMoneyEndOfDay',
  StudioFee_Admin = 'StudioFee_Admin',
  StudioFee = 'StudioFee',
  StudioCashReport_EditIncomingOutgoing_CanEdit_ = 'StudioCashReport_EditIncomingOutgoing_CanEdit_',
  CalendarEventEdit_canEdit_ = 'CalendarEventEdit_canEdit_',
  NotAssignedPayments_View = 'NotAssignedPayments_View',
  NotAssignedPayments_Assign = 'NotAssignedPayments_Assign',
  Artists_Read = 'Artists_Read',
  GiftCards_Download = 'GiftCards_Download',
  CalendarEventEdit_ChangeWorkType = 'CalendarEventEdit_ChangeWorkType',
  CalendarEventEdit_DisableFollowEventInfo = 'CalendarEventEdit_DisableFollowEventInfo',
  DiscountPromotionsEdit = 'DiscountPromotionsEdit',
  CalendarEventEdit_SaveWithoutChecks = 'CalendarEventEdit_SaveWithoutChecks',
  CalendarEventEdit_EditPayments = 'CalendarEventEdit_EditPayments',
  StudioCashReport_CanEditGiftCard = 'StudioCashReport_CanEditGiftCard',
  CalendarEventEdit_ShowBankDepositQrCode = 'CalendarEventEdit_ShowBankDepositQrCode',
  StudioCashReport_ShowAllCashRegisters = 'StudioCashReport_ShowAllCashRegisters',
  StudioCashReport_CanEditEndMoney = 'StudioCashReport_CanEditEndMoney',
  Analysis_DiscountPromotions = 'Analysis_DiscountPromotions',
  NotAssignedPayments_Delete = 'NotAssignedPayments_Delete',
  Analysis_StudioFee = 'Analysis_StudioFee',
  Analysis_Events = 'Analysis_Events',
  DisableGlobal_ = 'DisableGlobal_',
  WhatsAppBroadcast = 'WhatsAppBroadcast',
  PayPalSMSCode = 'PayPalSMSCode',
  GoogleSMSCode = 'GoogleSMSCode',
  IsJulian = 'IsJulian',
  IsAc = 'IsAc',
  IsMa = 'IsMa',
  IsNoFranchise = 'IsNoFranchise',
  IsFranchise = 'IsFranchise',
  IsNotAc = 'IsNotAc',
  Artists_NewSpot = 'Artists_NewSpot',
  CalendarEventEdit_canReOpen = 'CalendarEventEdit_canReOpen',
  WhatsAppFastAnswers_Edit = 'WhatsAppFastAnswers_Edit',
  EditUsers = 'EditUsers',
  ContactsDelete = 'ContactsDelete',
  StudioCashReport_PrePayedOutInvoices = 'StudioCashReport_PrePayedOutInvoices',
  // PromoAppAdmin = 'PromoAppAdmin',
  IsBackoffice = 'IsBackoffice',
  IsReception = 'IsReception',
  Artists_CanEditSkillsOutsideFromAc = 'Artists_CanEditSkillsOutsideFromAc',
  IsNiklas = 'IsNiklas',
  IsCapone = 'IsCapone',
  Promo_PromotersList = 'Promo_PromotersList',
  DayFinishLog = 'DayFinishLog',
  GiftCard_ForceAllGiftCards = 'GiftCard_ForceAllGiftCards',
  NotAssignedBankTransfer_Assign = 'NotAssignedBankTransfer_Assign',
  GiftCard_SeeCodes = 'GiftCard_SeeCodes',
  Inventory_CanDeleteAllOrders = 'Inventory_CanDeleteAllOrders',
  ExcelExport = 'ExcelExport',
  CanRefund = 'CanRefund',
  IsDu = 'IsDu',
  Artists_DeleteNotes = 'Artists_DeleteNotes',
  PiercingManager_AddInfo = 'PiercingManager_AddInfo',
  StudioCashReport_CanSetWalkIn = 'StudioCashReport_CanSetWalkIn',
  Employees_EditEvents = 'Employees_EditEvents',
  Employees_ViewEvents = 'Employees_ViewEvents',
  Employees_Edit = 'Employees_Edit',
  Artists_EditKeySafeCode = 'Artists_EditKeySafeCode',
  Employees_Works = 'Employees_Works',
  Employees_EditTimeTracks = 'Employees_EditTimeTracks',
  DefaultDashboard = 'DefaultDashboard',
  MoneyAccount_Admin = 'MoneyAccount_Admin',
  Employees_Payout = 'Employees_Payout',
  StudioCashReport = 'StudioCashReport',
  Calendar = 'Calendar',
  EventRating_Edit = 'EventRating_Edit',
  MoneyAccount_Transactions = 'MoneyAccount_Transactions',
  EventRating_Delete = 'EventRating_Delete',
  EventPhoto_Delete = 'EventPhoto_Delete',
  Analysis_EventsCreatedOrUpdated = 'Analysis_EventsCreatedOrUpdated',
  SkillCheck_Disable = 'SkillCheck_Disable',
  WhatApp_ChatRecheck = 'WhatApp_ChatRecheck',
  Artist_SetCanEarlyPayout = 'Artist_SetCanEarlyPayout',
  CalendarEventEdit_setImproveOtherArtistCheckState = 'CalendarEventEdit_setImproveOtherArtistCheckState',
  Photos_Gallery = 'Photos_Gallery',
  Bank_Transactions = 'Bank_Transactions',
  Bank_Documents = 'Bank_Documents',
}

export type PermissionId =
  '' |
  'Admin' |
  'SuperAdmin' |
  'CalendarEventEdit_canMoveEventEarlierThan3Days' |
  'StudioCashReport_DatePicker' |
  'StudioCashReport_ToggleBlockDayFinish' |
  'ContactForm_WithoutBirthday' |
  'ShowDurationPriceCheckColumn' |
  'StudioCashReport_CanEditOutgoing' |
  'StudioCashReport_CanEditIncoming' |
  'StudioCashReport_SwitchStudios' |
  'StudioCashReport_Switch_' |
  'StudioCashReport_SeeMoneyEndOfDay' |
  'StudioCashReport_Menu_Artists' |
  'StudioFee_Admin' |
  'StudioCashReport_EditIncomingOutgoing_CanEdit_' |
  'CalendarEventEdit_canEdit_' |
  'NotAssignedPayments_View' |
  'NotAssignedPayments_Assign' |
  'Artists_Read' |
  'CalendarEventEdit_ChangeWorkType' |
  'CalendarEventEdit_DisableFollowEventInfo' |
  'CalendarEventEdit_SaveWithoutChecks' |
  'CalendarEventEdit_EditPayments' |
  'StudioCashReport_CanEditGiftCard' |
  'CalendarEventEdit_ShowBankDepositQrCode' |
  'StudioCashReport_ShowAllCashRegisters' |
  'StudioCashReport_CanEditEndMoney' |
  'NotAssignedPayments_Delete' |
  'Analysis_StudioFee' |
  'Analysis_DiscountPromotions' |
  'Analysis_Events' |
  'DisableGlobal_' |
  'DiscountPromotionsEdit' |
  'WhatsAppBroadcast' |
  'PayPalSMSCode' |
  'IsJulian' |
  'IsAc' |
  'IsDu' |
  'IsNotAc' |
  'Artists_NewSpot' |
  'CalendarEventEdit_canReOpen' |
  'WhatsAppFastAnswers_Edit' |
  'EditUsers' |
  'StudioCashReport_PrePayedOutInvoices' |
  'IsNoFranchise' |
  'IsFranchise' |
  'IsBackoffice' |
  'IsReception' |
  'Artists_CanEditSkillsOutsideFromAc' |
  'IsNiklas' |
  'Promo_PromotersList' |
  'DayFinishLog' |
  'GiftCard_ForceAllGiftCards' |
  'NotAssignedBankTransfer_Assign' |
  'GiftCard_SeeCodes' |
  'CanRefund' |
  'GoogleSMSCode' |
  'Inventory_CanDeleteAllOrders' |
  'ExcelExport' |
  'IsMa' |
  'Artists_DeleteNotes' |
  'PiercingManager_AddInfo' |
  'StudioCashReport_CanSetWalkIn' |
  'Employees_Edit' |
  'Employees_EditEvents' |
  'Artists_EditKeySafeCode' |
  'Employees_Works' |
  'Employees_EditTimeTracks' |
  'Employees_ViewEvents' |
  'DefaultDashboard' |
  'MoneyAccount_Admin' |
  'Employees_Payout' |
  'StudioCashReport' |
  'Calendar' |
  'StudioFee' |
  'EventRating_Edit' |
  'MoneyAccount_Transactions' |
  'EventRating_Delete' |
  'EventPhoto_Delete' |
  'Analysis_EventsCreatedOrUpdated'
  | 'SkillCheck_Disable'
  | 'WhatApp_ChatRecheck'
  | 'IsCapone'
  | 'Artist_SetCanEarlyPayout'
  | 'CalendarEventEdit_setImproveOtherArtistCheckState'
  | 'Photos_Gallery'
  | 'Bank_Transactions'
  | 'Bank_Documents'
  ;


export function getPermissionIdText(permission: NxtPermissionId) {
  switch (permission) {
    case NxtPermissionId.Admin:
      return 'Admin';
    case NxtPermissionId.SuperAdmin:
      return 'Superadmin';
    case NxtPermissionId.ShowDurationPriceCheckColumn:
      return 'Spalte "Dauer - Preis"';
    case NxtPermissionId.StudioCashReport_DatePicker:
      return 'Datum welchseln';
    case NxtPermissionId.StudioCashReport_ToggleBlockDayFinish:
      return 'Abrechnung freigeben';
    case NxtPermissionId.StudioCashReport_CanEditIncoming:
      return 'Einnahmen bearbeiten';
    case NxtPermissionId.StudioCashReport_CanEditOutgoing:
      return 'Ausgaben bearbeiten';
    case NxtPermissionId.StudioCashReport_SwitchStudios:
      return 'Alle Kassen anzeigen';
    case NxtPermissionId.StudioCashReport_Switch_:
      return 'Tresor Wechsler';
    case NxtPermissionId.StudioCashReport_SeeMoneyEndOfDay:
      return 'Wie viel bleibt am Ende des Tages';
    case NxtPermissionId.Artists_Read:
      return 'Artists anlegen / bearbeiten';
    case NxtPermissionId.StudioCashReport_EditIncomingOutgoing_CanEdit_:
      return '_ bei Ein- oder Ausgaben bearbeiten';
    case NxtPermissionId.CalendarEventEdit_canMoveEventEarlierThan3Days:
      return '3.Tage-Verschieb-Regel nicht anwenden';
    case NxtPermissionId.CalendarEventEdit_canEdit_:
      return 'Termin auf Tresor setzen';
    case NxtPermissionId.StudioFee_Admin:
      return 'Studio AbgabenAdmin';
    case NxtPermissionId.ContactForm_WithoutBirthday:
      return 'Geburtstag nicht nötig';
    case NxtPermissionId.NotAssignedPayments_View:
      return 'Offene Zahlungen anzeigen';
    case NxtPermissionId.NotAssignedPayments_Assign:
      return 'Offene Zahlungen zuweisen';
    case NxtPermissionId.GiftCards_Download:
      return 'Gutscheine herunterladen anstatt Drucken';
    case NxtPermissionId.CalendarEventEdit_ChangeWorkType:
      return 'Terminart ändern (Tattoo oder Piercing)';
    case NxtPermissionId.CalendarEventEdit_DisableFollowEventInfo:
      return 'Folgetermin-Info deaktivieren';
    case NxtPermissionId.DiscountPromotionsEdit:
      return 'Rabatte bearbeiten';
    case NxtPermissionId.CalendarEventEdit_SaveWithoutChecks:
      return 'Termin speichern ohne check (3x Klick)';
    case NxtPermissionId.CalendarEventEdit_EditPayments:
      return 'Termin Zahlungen ändern (3x Klick)';
    case NxtPermissionId.StudioCashReport_CanEditGiftCard:
      return 'Gutschein bearbeiten';
    case NxtPermissionId.CalendarEventEdit_ShowBankDepositQrCode:
      return 'Bank-Rückzahlung QR-Code anzeigen';
    case NxtPermissionId.StudioCashReport_ShowAllCashRegisters:
      return 'Alle gleichzeitig Kassen anzeigen';
    case NxtPermissionId.StudioCashReport_CanEditEndMoney:
      return 'Ende-Geld ohne Abrechnung';
    case NxtPermissionId.Analysis_DiscountPromotions:
      return 'Rabatte';
    case NxtPermissionId.NotAssignedPayments_Delete:
      return 'Löschen von offenen Zahlungen';
    case NxtPermissionId.Analysis_StudioFee:
      return 'Studio-Abgaben';
    case NxtPermissionId.DisableGlobal_:
      return '_ global Deaktiviert';
    case NxtPermissionId.WhatsAppBroadcast:
      return 'NXT-Whatsapp-Broadcast versenden';
    case NxtPermissionId.PayPalSMSCode:
      return 'PayPal SMS-Code anzeigen';
    case NxtPermissionId.GoogleSMSCode:
      return 'Google SMS-Code anzeigen';
    case NxtPermissionId.Artists_NewSpot:
      return 'Neuer Artist-Spot anlegen';
    case NxtPermissionId.CalendarEventEdit_canReOpen:
      return 'geschlossenen Termin erneut öffnen';
    case NxtPermissionId.WhatsAppFastAnswers_Edit:
      return 'WhatsApp-Texte bearbeiten';
    case NxtPermissionId.EditUsers:
      return 'Benutzer anlegen / bearbeiten';
    case NxtPermissionId.ContactsDelete:
      return 'Kunde löschen';
    case NxtPermissionId.StudioCashReport_PrePayedOutInvoices:
      return '"Rechnungen-Button" für vorzeitige Auszahlungen';
    case NxtPermissionId.CanRefund:
      return 'PayPal, Klarna, Gutscheine zurückzahlen';
    case NxtPermissionId.Artists_CanEditSkillsOutsideFromAc:
      return 'Artist-Skills außerhalb von Aachen verändern';
    case NxtPermissionId.Promo_PromotersList:
      return 'Promoters';
    case NxtPermissionId.DayFinishLog:
      return 'Abrechnungs-Log';
    case NxtPermissionId.GiftCard_ForceAllGiftCards:
      return 'Gutscheine Ausnahmen';
    case NxtPermissionId.NotAssignedBankTransfer_Assign:
      return 'Fehlende Bankeinzahlungen zuordnen';
    case NxtPermissionId.Analysis_Events:
      return 'Auswertungen basierend auf Terminen';
    case NxtPermissionId.GiftCard_SeeCodes:
      return 'Gutschein-Codes sehen';
    case NxtPermissionId.Inventory_CanDeleteAllOrders:
      return 'Offene Bestellungen löschen (egal von wem und wann)';
    case NxtPermissionId.ExcelExport:
      return 'Excel Export';
    case NxtPermissionId.Artists_DeleteNotes:
      return 'Artist-Notizen löschen';
    case NxtPermissionId.PiercingManager_AddInfo:
      return 'Notizen zum Termin erfassen';
    case NxtPermissionId.StudioCashReport_CanSetWalkIn:
      return 'Walk-In setzen';
    case NxtPermissionId.Employees_Edit:
      return 'Mitarbeiter bearbeiten';
    case NxtPermissionId.Employees_EditEvents:
      return 'Mitarbeiter Kalender Planung';
    case NxtPermissionId.Employees_ViewEvents:
      return 'Mitarbeiter Kalender';
    case NxtPermissionId.Artists_EditKeySafeCode:
      return 'Schlüssel-Safe-Code bearbeiten';
    case NxtPermissionId.Employees_Works:
      return 'Mitarbeiter-Zeiten';
    case NxtPermissionId.Employees_EditTimeTracks:
      return 'Mitarbeiter Kalender Stempeln nachtragen';
    case NxtPermissionId.DefaultDashboard:
      return 'Übersicht nach Login (Dashboard)';
    case NxtPermissionId.MoneyAccount_Admin:
      return 'Kassen-Admin';
    case NxtPermissionId.MoneyAccount_Transactions:
      return 'Kassen buchen (auf die er Berechtigung hat)';
    case NxtPermissionId.Employees_Payout:
      return 'Überstunden ausbezahlen';
    case NxtPermissionId.StudioCashReport:
      return 'Zugriff auf CashReport';
    case NxtPermissionId.Calendar:
      return 'Zugriff auf Kalender';
    case NxtPermissionId.StudioFee:
      return 'Studio-Abgaben';
    case NxtPermissionId.EventRating_Edit:
      return 'Tattoo-Termine bewerten';
    case NxtPermissionId.EventRating_Delete:
      return 'Tattoo-Bewertung löschen';
    case  NxtPermissionId.EventPhoto_Delete:
      return 'Löschen von Termin-Bildern (Vorlage, Stencil, Tattoo)';
    case NxtPermissionId.Analysis_EventsCreatedOrUpdated:
      return 'Termin erstellt oder geändert';
    case NxtPermissionId.SkillCheck_Disable:
      return 'Skill-Check deaktivieren';
    case NxtPermissionId.WhatApp_ChatRecheck:
      return 'WhatsApp-Chat-Kontrolle';
    case NxtPermissionId.Artist_SetCanEarlyPayout:
      return 'Freigabe von frühzeitigem Artist-Auszahlungen';
    case NxtPermissionId.CalendarEventEdit_setImproveOtherArtistCheckState:
      return 'Nachstechen bei anderem Artist OK setzen';
    case NxtPermissionId.Photos_Gallery:
      return 'Foto-Galerie';
    case NxtPermissionId.Bank_Documents:
      return 'Bank-Dokumente';
    case NxtPermissionId.Bank_Transactions:
      return 'Bank-Transaktionen';
  }
  return '? (' + permission + ')';
}

export function getPermissionIdTextDetail(permission: NxtPermissionId): string[] {
  switch (permission) {
    case NxtPermissionId.SuperAdmin:
      return ['Spezielle Rechte vergeben'];
    case NxtPermissionId.Admin:
      return ['Terminerinnerungen WhatsApp (Menü)', 'Terminbestätigungen (Menü)', 'Rechnungen (Menü)'];
    case NxtPermissionId.StudioCashReport_CanEditEndMoney:
      return ['(Klick auf Ende-Geld)'];
    case NxtPermissionId.GiftCard_ForceAllGiftCards:
      return ['Nicht mehr gültige', 'Mindestpreis nicht erreicht', 'Wenn nicht für Aktion zugelassen', 'Geburstags-Gutscheine als Kaution'];
  }
  return [];
}
