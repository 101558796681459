<div class="nxt-page" fxFlex fxLayout="column" *ngIf="webFormSubmits">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-2">
    <div class="ml-2 text-120">Terminanfragen</div>
    <nxt-button-icon *ngIf="dialogRef" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>

  <div fxFlex>
    <nxt-datagrid
      class="h-100"
      [columnDefs]="columnDefs"
      [rowData]="webFormSubmits"
      [showSideBar]="false"
      [suppressCellSelection]="true"
      [enableRangeSelection]="false"
    >
    </nxt-datagrid>
  </div>
</div>
