<div class="wrapper" fxLayout="column" fxLayoutAlign="start center" style="min-width: 500px">
  <nxt-smooth-height fxLayout="column" fxLayoutAlign="start center" class="w-full" [trigger]="form.value">
    <!--<nxt-input [nxtFormControl]="form.controls.id"></nxt-input>-->
    <nxt-radio
      [nxtFormControl]="form.controls.direction"
      direction="row"
      valueKey="value"
      [options]="[{text: 'Einnahme', value: 'in'}, {text: 'Ausgabe', value: 'out'}]"
    >
    </nxt-radio>
    <nxt-input [nxtFormControl]="form.controls.name"></nxt-input>
    <nxt-autocomplete
      [options]="articleGroupOptions"
      [nxtFormControl]="form.controls.articleGroup"
      [displayInOptionWith]="DisplayWithTools.displayWithDescription"
      [displayWith]="DisplayWithTools.displayWithDescription"
    >

    </nxt-autocomplete>
    <!--<nxt-input [isNumber]="true" [nxtFormControl]="form.controls.postingAccountNo"></nxt-input>-->
    <nxt-input [isMoney]="true" [nxtFormControl]="form.controls.proposalValue"></nxt-input>
    <div *ngIf="!form.value.is_" fxLayout="row" fxLayoutAlign="center center" class="pb-3">
      <div>MwSt.</div>
      <nxt-radio
        [nxtFormControl]="form.controls.vat"
        direction="row"
        valueKey="value"
        [options]="[{text: '0 %', value: 0}, {text: '7 %', value: 7}, {text: '19 %', value: 19}, {text: '21 %', value: 21}]"
      >
      </nxt-radio>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-full">
      <div fxLayoutAlign="center" nxtPermission="IsJulian">
        <nxt-slide-toggle [nxtFormControl]="form.controls.is_"></nxt-slide-toggle>
      </div>
      <div fxLayoutAlign="center">
        <nxt-slide-toggle [nxtFormControl]="form.controls.invoiceRequired"></nxt-slide-toggle>
      </div>
      <div fxLayoutAlign="center">
        <nxt-slide-toggle [nxtFormControl]="form.controls.disabled"></nxt-slide-toggle>
      </div>
    </div>
    <div fxLayout="row" class="pt-3">
      <nxt-button (click)="save()">Anlegen</nxt-button>
    </div>
  </nxt-smooth-height>
</div>

