import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {EmployeeTools} from '../../../common-browser/helpers/employee.tools';
import {NxtEmployee} from '../../../common-interfaces/nxt.employee.interface';
import {WithoutNxtDbFields} from '../../../common-interfaces/nxt.db-fields.interface';
import {FormArray, FormGroup} from '@angular/forms';
import {NxtTypedFormControl} from '../../../nxt-form/nxt.typed-form-control';
import {InputComponent} from '../../form-controls/input/input.component';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {FormTools} from '../../../services/form.tools';
import {DialogService} from '../../../services/dialog.service';
import {DatePicker2Component} from '../../form-controls/date-picker-2/date-picker-2.component';
import {DialogRef} from '@angular/cdk/dialog';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {MultiFromTillComponent, NxtMultiFromTillFormGroup} from '../../form-controls/multi-from-till/multi-from-till.component';
import {clone, ObjectTools} from '../../../common-browser/helpers/object.tools';
import {AutocompleteComponent} from '../../form-controls/autocomplete/autocomplete.component';
import {DisplayWithTools} from '../../../common-browser/helpers/display-with.tools';
import {TimePickerComponent} from '../../form-controls/time-picker/time-picker.component';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {BoxComponent} from '../../box/box.component';
import {MatIcon} from '@angular/material/icon';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {DayOfWeek} from '../../../common-interfaces/date.interface';


@Component({
  selector: 'nxt-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InputComponent, NxtButtonComponent, DatePicker2Component, SlideToggleComponent, MultiFromTillComponent, AutocompleteComponent, TimePickerComponent, BoxComponent, MatIcon],
})

export class EmployeeEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor(
    @Optional() public dialogRef: DialogRef<EmployeeEditComponent>,
  ) {
    super();
  }

  employees: NxtEmployee[] = [];

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  socketService = inject(SocketService);
  dialogService = inject(DialogService);


  private originalEmployee: WithoutNxtDbFields<NxtEmployee>;

  form = new FormGroup({
    abbreviation: new NxtTypedFormControl('', [], 'Kürzel'),
    givenName: new NxtTypedFormControl('', [ValidatorTools.requiredAndNotNaN], 'Name'),
    familyName: new NxtTypedFormControl('', [ValidatorTools.requiredAndNotNaN], 'Nachname'),
    birthday: new NxtTypedFormControl(null, [ValidatorTools.requiredAndNotNaN], 'Geburtstag'),
    color: new NxtTypedFormControl('', [ValidatorTools.requiredAndNotNaN], 'Farbe'),
    shouldPlanWork: new NxtTypedFormControl(null, [], 'Dienstplanung'),
    appUserUid: new NxtTypedFormControl<{ name: string, uid: string }>(null, [], 'Name in der App'),
    hoursPerWeek: new FormArray<NxtMultiFromTillFormGroup<number>>([]),
    holidayCount: new FormArray<NxtMultiFromTillFormGroup<number>>([]),
    earliestStartTime: new NxtTypedFormControl('', [], 'Frühstens stempeln'),
    timeTrackEndTimeIfMissing: new NxtTypedFormControl('', [], 'Ausstempeln vergessen, wenn nicht geplant'),
    timeTrackOnlyFromStudio: new NxtTypedFormControl(false, [], 'Stempeln nur im Studio'),
    seeWorkTime: new NxtTypedFormControl(false, [], 'Arbeitsplan in App'),
    doTimeTrack: new NxtTypedFormControl(false, [], 'muss Stempeln'),
    freeDays: new FormArray<NxtMultiFromTillFormGroup<DayOfWeek[]>>([]),
    startDateString: new NxtTypedFormControl('', [ValidatorTools.requiredAndNotNaN], 'Eintrittsdatum'),
    endDateString: new NxtTypedFormControl('', [], 'Austrittsdatum'),
    earliestStartDependOnWorkplace: new NxtTypedFormControl(false, [], 'Frühstens stempeln 30 min vor geplant'),
    // timeTrackEndTimeDependOnWorkplaceIfMissing: new NxtTypedFormControl('', [], 'Ausstempeln vergessen'),
  });
  appUsers: any[];

  protected readonly DisplayWithTools = DisplayWithTools;

  protected readonly DateTools = DateTools;
  protected readonly DayOfWeek = DayOfWeek;

  displayWithAppUser = (user: { name: string, uid: string, loginContext: string }) => {
    if (!user) {
      return '';
    }
    return user.name + ' (' + user.loginContext + ')';
  };

  async ngOnInit() {
    this.appUsers = await this.socketService.getAppUsers();
    this.form.valueChanges.subscribe(() => {
      this.formChanged();
    });

    this.pushSocketSubscription = this.socketService.subscribeNew('eventEmployeeChanged', (data) => {
      if (data.record.id === this.originalEmployee.id) {
        if (this.socketService.connectionClientId.value !== data.userContext.connectionClientId) {
          this.dialogService.showOk('Der Mitarbeiter wurde eben von ' + data.userContext.username + ' geändert.\nBitte neu öffnen');
          this.dialogRef.close();
        }
      }
    });
  }

  calcAbbreviation() {
    if (this.form.controls.givenName.value && this.form.controls.familyName.value) {
      const newValue = (this.form.controls.givenName.value.charAt(0) + this.form.controls.familyName.value).slice(0, 3).toUpperCase();
      if (this.form.controls.abbreviation.value !== newValue) {
        this.form.controls.abbreviation.setValue(newValue);
      }
    }
  }

  nxtOnDestroy() {
  }

  async load(employeeId: string) {
    this.dialogService.showLoading('Lade Mitarbeiter');
    await ObjectTools.waitFor(() => this.appUsers, 'load(employeeId)');
    if (employeeId === 'new') {
      this.originalEmployee = EmployeeTools.getEmpty();
    } else {
      this.originalEmployee = await this.socketService.getEmployee(employeeId);
      this.form.controls.abbreviation.disable();
    }
    this.setFormFromEmployee(this.originalEmployee);
    this.dialogService.hideLoading();
  }

  closeClicked() {
    this.dialogRef.close();
  }

  async saveClicked() {
    const employee = this.getEmployeeFromForm();
    let hoursPerWeek = 0;
    let freeDaysCount = EmployeeTools.getFreeDays(employee, Date.now().dateFormatDate()).length;

    if (employee.hoursPerWeek.length > 0) {
      const hoursPerWeekItem = employee.hoursPerWeek[employee.hoursPerWeek.length - 1];
      hoursPerWeek = hoursPerWeekItem.value;
      freeDaysCount = EmployeeTools.getFreeDays(employee, hoursPerWeekItem.startDateString).length;
    }

    if (((48 - hoursPerWeek) / 8 - freeDaysCount).abs() > 3) {
      this.dialogService.showOk('Stunden pro Woche stimmen nicht mit den freien Tagen überein');
      return;
    }

    if (FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      await this.socketService.upsertEmployee(employee);
      this.dialogRef.close();
    }
  }

  private getEmployeeFromForm(): WithoutNxtDbFields<NxtEmployee> {
    const employee: WithoutNxtDbFields<NxtEmployee> = {...clone(this.originalEmployee), ...this.form.getRawValue() as any};
    employee.appUserUid = this.form.controls.appUserUid?.value?.uid || '';
    return employee;
  }

  private setFormFromEmployee(employee: WithoutNxtDbFields<NxtEmployee>) {
    FormTools.setForm(this.form, employee, {
      hoursPerWeek: MultiFromTillComponent.getFormGroup.bind(this),
      freeDays: MultiFromTillComponent.getFormGroup.bind(this),
      holidayCount: MultiFromTillComponent.getFormGroup.bind(this),
    });
    this.form.controls.appUserUid.setValue(this.appUsers.find(u => u.uid === employee.appUserUid));
    this.cdRef.detectChanges();
  }

  test() {
    this.cdRef.detectChanges();
  }


  formChanged() {
    this.calcAbbreviation();
  }

  async setColorClicked() {
    const colorInfos: { [color: string]: string } = {};
    this.employees.filter(e => e.id !== this.originalEmployee.id).forEach(e => colorInfos[e.color] = e.givenName);
    this.form.controls.color.setValue(await this.dialogService.showColorPicker('Farbe für ' + this.form.controls.givenName.value, this.form.controls.color.value, colorInfos));
    this.cdRef.detectChanges();
  }

  showTimeTrackEndTimeIfMissingInfoClicked() {
    const text = 'Ist der Mitarbeiter geplant und vergisst auszustempeln, wird die Ende-Zeit auf Plan-Ende gesetzt, ist er nicht geplant wird diese Zeit genommen.';
    this.dialogService.showOk(text);
  }
}
