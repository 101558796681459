import {Injectable} from '@angular/core';
import {DialogService} from './dialog.service';
import {CreatePayPalCheckoutComponent} from '../pages/create-paypa-checkout/create-paypal-checkout.component';
import {SocketInterface} from '../common-interfaces/socket/socket-interface';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaypalCheckoutService {


  constructor(
    private dialogService: DialogService
  ) {
  }
  public showCreatePaypalPayment(data: SocketInterface.GeneratePayPalCheckoutLink) {
    const dialog = this.dialogService.showComponentDialog(CreatePayPalCheckoutComponent, {});
    dialog.componentInstance.load(data);
    return firstValueFrom(dialog.afterClosed());
  }
}
