<div class="nxt-page nxt-text" fxLayout="column" style="border: 0px solid red">

  <div fxLayout="row" fxLayoutAlign="center center">
    <!--<nxt-date-picker
      nxtPermission="IsFranchise"
      fxFlex="300px"
      [nxtModel]="monthYearString"
      [monthPicker]="true"
      displayDateFormat="MMMM YYYY"
      [showJumpMonthButtons]="true"
      (nxtModelChange)="monthPickerChanged($event)"
    >
    </nxt-date-picker>-->

    <nxt-date-range-picker
      [(value)]="dateFromTill"
      (valueChange)="dateRangeChanged()"
      placeholder="Von - Bis"
    >
    </nxt-date-range-picker>

    <div>
      <nxt-button-icon *ngIf="showBottom" (click)="showBottom = false">arrow_downward</nxt-button-icon>
      <nxt-button-icon *ngIf="!showBottom" (click)="showBottom = true">arrow_upward</nxt-button-icon>
    </div>

    <div fxLayout="row">
      <nxt-button-icon matTooltip="Rechnung erstellen" (click)="invoiceForm()">receipt_long</nxt-button-icon>
      <nxt-button-icon matTooltip="Klarna Auszahlungen" (click)="showKlarna()">attach_money</nxt-button-icon>
      <nxt-button-icon matTooltip="Klarna Buchungen" (click)="showKlarnaInvoiceClicked()">post_add</nxt-button-icon>
      <nxt-button-icon *ngIf="monthYearString" matTooltip="Belege herunterladen" (click)="downloadOutgoingImages()">download</nxt-button-icon>
    </div>

    <div>
      <nxt-slide-toggle [disabled]="true" [(value)]="useKlarnaApi" (valueChange)="show()">Neue Klarna Berechnung</nxt-slide-toggle>
    </div>

    <div *ngIf="false" nxtPermission="IsJulian">
      <nxt-slide-toggle [(value)]="useFix" (valueChange)="show()">korrektur</nxt-slide-toggle>
      <nxt-slide-toggle [(value)]="useFix_2022_11_19" (valueChange)="show()">fix 2022-11</nxt-slide-toggle>
      <nxt-slide-toggle [(value)]="useFix_2023_03_16" (valueChange)="show()">fix 2023-02</nxt-slide-toggle>
    </div>

    <nxt-button-icon *ngIf="dialogRef" (click)="dialogRef.close()">close</nxt-button-icon>
    <!--<nxt-button (click)="show()">Anzeigen</nxt-button>-->
  </div>

  <div fxFlex fxLayout="row">
    <nxt-datagrid
      *ngIf="data && !showOnlyOutgoings"
      fxFlex
      name="studioFeeOutgoings"
      [columnDefs]="columnDefs.dateMoney"

      [quickFilterText]="quickFilterText"
      [rowData]="data.dates"
      uniqueRowDataKey="dateString"
      [showFooter]="true"
      [showSideBar]="false"
      [columnFilters]="true"
      excelExportName="Studio-Abgaben Zahlen"
    >
    </nxt-datagrid>
    <div>&nbsp;</div>
    <mat-icon (click)="toggleOutgoingsClicked()">{{ showOnlyOutgoings ? 'arrow_right' : 'arrow_left' }}</mat-icon>
    <nxt-datagrid
      *ngIf="data"
      [fxFlex]="!showOnlyOutgoings ? '400px' : ''"
      name="cashOutgoings"
      [columnDefs]="columnDefs.cashOutgoings"
      [disableAllSort]="true"
      [quickFilterText]="quickFilterText"
      [rowData]="data.cashOutgoings"
      [showFooter]="true"
      [showSideBar]="false"
      excelExportName="Studio-Abgaben Ausgaben"
    >
    </nxt-datagrid>
  </div>
  <nxt-smooth-height [trigger]="showBottom">
    <div class="text-80" style="overflow: auto;" *ngIf="showBottom">
      <div fxLayout="row" style="padding-top:1em" fxLayoutGap="1em" *ngIf="data">
        <div *ngIf="data">
          <table>
            <tr>
              <th colspan="2">TAGESSUMMEN</th>
            </tr>
            <tr>
              <td>_Einlagen</td>
              <td>- {{ data.privateToCash_ | money }}</td>
            </tr>
            <tr>
              <td>_Kassen ausgaben</td>
              <td>- {{ data.cashOutgoingsValue_ | money }}</td>
            </tr>
            <tr>
              <td>_Umsatz nach Kassenausgaben</td>
              <td>{{ data._value | money }}</td>
            </tr>
            <tr>
              <td colspan="2"></td>
            </tr>
            <tr>
              <td>Kasse -> Bank</td>
              <td>{{ data.cashToBank | money }}</td>
            </tr>
            <tr>
              <td>Bank Kunden-Zahlungen</td>
              <td>{{ data.bankIncoming | money }}</td>
            </tr>
            <tr>
              <td>Klarna -> Bank</td>
              <td>{{ data.klarnaIncoming | money }}</td>
            </tr>
            <tr>
              <td>Paypal Kunden-Zahlungen</td>
              <td>{{ data.paypalIncoming | money }}</td>
            </tr>
            <tr>
              <td>Paypal Gutscheine</td>
              <td>{{ data.paypalGiftCardIncoming | money }}</td>
            </tr>
            <tr>
              <td>Paypal Kunden-Rückzahlungen</td>
              <td>{{ data.paypalOutgoing | money }}</td>
            </tr>
            <tr>
              <td>Bank -> Kasse</td>
              <td>± {{ data.bankToCash | money }}</td>
            </tr>
            <tr>
              <td>Kassen ausgaben</td>
              <td>- {{ data.cashOutgoingsValue | money }}</td>
            </tr>
            <tr>
              <td>Umsatz nach Kassenausgaben</td>
              <td (click)="showIncomingGrossText()">{{ data.incomingTotalGross - data._value | money }}</td>
              <!--<td>{{data.incomingTotalGross | money}} - {{data._value | money}}</td>-->
            </tr>

            <tr>
              <td></td>
            </tr>
            <tr>
              <td>Umsatz Gesamt</td>
              <td>{{ data.incomingTotalGross | money }}</td>
            </tr>
            <!--<tr>
              <td>Kosten</td>
              <td>{{data.monthData.costsGross | money}}</td>
            </tr>
            <tr>
              <td>_ Kosten</td>
              <td>{{data.monthData.costs_ | money}}</td>
            </tr>-->
          </table>
        </div>
        <div class="flex flex-row items-center justify-center gap-3" *ngIf="monthYearString">
          <div class="flex h-full flex-col items-center justify-center gap-3 p-3 h-100 w-[180px]" style="border: 1px solid gray">
            <div class="nxt-text-110" style="text-align: center; padding:0.5em">Ausgaben</div>
            <nxt-input [readonly]="true" placeholder="Studiokosten Brutto (Miete, Stuff)" [isMoney]="true"
                       [(value)]="data.monthData.costsGross" (click)="costsGrossClicked()"></nxt-input>
            <nxt-input [readonly]="true" placeholder="Safe Kosten nach Kasse" [isMoney]="true" [(value)]="data.monthData.costs_"
                       (click)="costs_Clicked()"></nxt-input>
            <nxt-input [readonly]="data.monthData.dataInputFinished" placeholder="private _ Ausgaben aus Kasse" [isMoney]="true"
                       [(value)]="data.monthData.privateCosts_" (change)="monthDataChanged()"></nxt-input>
            <nxt-input *ngIf="!useKlarnaApi" [readonly]="data.monthData.dataInputFinished" placeholder="Mehr durch Klarna" [isMoney]="true"
                       [(value)]="data.monthData.sideMoney"
                       (change)="monthDataChanged()"></nxt-input>
            <nxt-button *ngIf="!data.monthData.dataInputFinished" (click)="dataInputFinish()">Ausgaben fertig</nxt-button>
            <nxt-button nxtPermission="StudioFee_Admin" *ngIf="data.monthData.dataInputFinished" (click)="dataInputOpen()">Ausgaben öffnen</nxt-button>
          </div>
          <div class="flex h-full flex-col items-center justify-center gap-3 p-3 h-100 w-fit" style="border: 1px solid gray">
            <nxt-input [readonly]="data.monthData.closed" placeholder="Rabatt" [isMoney]="true" [(value)]="data.monthData.discountGross"
                       (change)="monthDataChanged()"></nxt-input>
            @if (data.monthData.invoiceSent) {
              Rechnung ist verschickt ✅
            }
            @if (data.monthData.closed) {
              Rechnung ist bezahlt ✅
            }
            @if (data.monthData.safeClosed) {
              Tresor ist bezahlt ✅
            }
            <nxt-button *ngIf="!data.monthData.invoiceSent" (click)="setInvoiceSentMonth()">Rechnung verschickt markieren</nxt-button>
            <nxt-button *ngIf="!data.monthData.closed" (click)="finishMonth()">Rechnung als bezahlt markieren</nxt-button>
            <nxt-button *ngIf="!data.monthData.safeClosed" (click)="safeClosedClicked()">Tresor als bezahlt markieren</nxt-button>
            <nxt-button (click)="createInvoice()">Rechnung erstellen</nxt-button>
            <nxt-button (click)="export()">Daten exportieren</nxt-button>
          </div>
          <div fxFlex></div>
        </div>
        <div *ngFor="let feeCalcData of data.feeCalcData; let index = index" fxLayoutAlign="top center" fxLayout="column">
          <table>
            <tr>
              <th colspan="2">{{ (index === 0 && data.feeCalcData.length > 1) ? 'AKTUELL' : 'ENDE VOM MONAT' }}</th>
            </tr>
            <tr>
              <td>Gewinn Brutto</td>
              <td>{{ feeCalcData.profitGross | money }}</td>
            </tr>

            <!--<tr>
              <td>Gesamt Einnahmen</td>
              <td>{{feeCalcData.profitTotalGross | money}}</td>
            </tr>-->
            <tr *ngIf="feeCalcData.feePercentage > 0">
              <td>Abgabe %</td>
              <td>{{ feeCalcData.feePercentage }} %</td>
            </tr>

            <tr *ngIf="feeCalcData.feeGross >= 0.01" class="gold">
              <td>NXT Abgabe NXT</td>
              <td>{{ feeCalcData.feeGross | money }}</td>
            </tr>

            <tr *ngIf="feeCalcData.feePercentage > 0">
              <td>Brutto Gewinn nach Abgaben</td>
              <td>{{ feeCalcData.profitAfterFeeGross | money }}</td>
            </tr>
            <tr>
              <td>MwSt.</td>
              <td>{{ feeCalcData.profitAfterFeeGross - feeCalcData.profitAfterFeeNet | money }}</td>
            </tr>
            <tr>
              <td>Netto Gewinn nach Abgaben</td>
              <td>{{ feeCalcData.profitAfterFeeNet | money }}</td>
            </tr>
            <tr *ngIf="feeCalcData.feePercentage > 0">
              <td>E-St</td>
              <td>{{ feeCalcData.incomingTax | money }}</td>
            </tr>
            <tr *ngIf="feeCalcData.feePercentage > 0">
              <td>Tasche</td>
              <td>{{ feeCalcData.privateMoney | money }}</td>
            </tr>
            <tr>
              <td colspan="2">
              </td>
            </tr>
            <tr>
              <td>_Gewinn</td>
              <td>{{ feeCalcData.profit_ | money }}</td>
            </tr>
            <tr>
              <td><strong>Gewinn Gesamt Brutto</strong></td>
              <td><strong>{{ (feeCalcData.profit_ + feeCalcData.profitGross) | money }}</strong></td>
            </tr>
            <!--<tr>
              <td><strong>profitWithoutOutgoings_</strong></td>
              <td><strong>{{(feeCalcData.profitWithoutOutgoings_) | money}}</strong></td>
            </tr>
            <tr>
              <td><strong>profitGrossWithoutOutgoings</strong></td>
              <td><strong>{{(feeCalcData.profitGrossWithoutOutgoings) | money}}</strong></td>
            </tr>-->
            <tr nxtPermission="IsNoFranchise">
              <td><strong>Gewinn Gesamt Brutto ohne Kassenausgaben</strong></td>
              <td><strong>{{ (feeCalcData.profitWithoutOutgoings_ + feeCalcData.profitGrossWithoutOutgoings) | money }}</strong></td>
            </tr>
            <tr *ngIf="feeCalcData.fee_ >= 0.01" class="gold">
              <td>NXT _Abgabe NXT</td>
              <td>{{ feeCalcData.fee_ | money }}</td>
            </tr>
            <tr *ngIf="feeCalcData.profit_ !== feeCalcData.privateMoney_">
              <td>_ Tasche</td>
              <td>{{ feeCalcData.privateMoney_ | money }}</td>
            </tr>
            <tr *ngIf="feeCalcData.fee_ >= 0.01">
              <td colspan="2">
              </td>
            </tr>
            <tr *ngIf="feeCalcData.fee_ >= 0.01">
              <td>Tasche Gesamt</td>
              <td>{{ feeCalcData.privateMoney + feeCalcData.privateMoney_ | money }}</td>
            </tr>
          </table>
        </div>
        <div *ngIf="data.feeCalcData.length > 1" fxLayoutAlign="top center" fxLayout="column">
          <table>
            <tr>
              <th colspan="2">KOMMT NOCH REIN</th>
            </tr>
            <tr>
              <td>Gewinn Brutto</td>
              <td
                (contextmenu)="copyForExcel($event, data.feeCalcData[1].profitGross - data.feeCalcData[0].profitGross)">{{ data.feeCalcData[1].profitGross - data.feeCalcData[0].profitGross | money }}
              </td>
            </tr>
            <tr>
              <td>_Gewinn</td>
              <td
                (contextmenu)="copyForExcel($event, data.feeCalcData[1].profit_ - data.feeCalcData[0].profit_)">{{ data.feeCalcData[1].profit_ - data.feeCalcData[0].profit_ | money }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </nxt-smooth-height>
</div>
