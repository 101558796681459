import {Component, Input, OnInit} from '@angular/core';
import {ElectronService} from '../../../../services/electron.service';
import {NxtConfig} from '../../../../common-interfaces/nxt.config.interface';
import {SocketService} from '../../../../services/socket/socket.service';
import {DialogService} from '../../../../services/dialog.service';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {InputComponent} from '../../../form-controls/input/input.component';
import {NgIf} from '@angular/common';

@Component({
    selector: 'nxt-settings-qonto',
    templateUrl: './settings.qonto.component.html',
    styleUrls: ['./settings.qonto.component.scss'],
  imports: [NgIf, InputComponent, FlexModule],
  standalone: true,
})

export class SettingsQontoComponent implements OnInit {

  @Input() data: { timer: number, config: NxtConfig.Qonto };

  constructor(
    private electronService: ElectronService,
    private socketService: SocketService,
    private dialogService: DialogService
  ) {
  }

  ngOnInit() {

  }


  /*public async testBankImport() {
    this.dialogService.showLoading(LoadingId.ImportTest, 'Bank-Import Test läuft...');
    try {
      const result = await this.socketService.bankImportTest(this.data.config);
      if (result) {
        const currentMoney = result.balances.map(b => b.Amt.toMoneyString()).join(', ');
        this.dialogService.hideLoading(LoadingId.ImportTest);
        this.dialogService.showOk('Bank-Import war erfolgreich\n' + currentMoney);
      }
    } catch (err) {
      this.dialogService.hideLoading(LoadingId.ImportTest);
      // this.dialogService.showOk('Bank-Import Test fehlgeschlagen!\n' + err.message);
    }
  }*/
}
