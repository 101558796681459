<div [style.display]="visible() ? '' : 'none'" class="info-template-content">
  @if (event(); as event) {
    <div class="flex w-full flex-row justify-center center">
      {{ event.studio | nxtStudio }}
      ・ {{ event.start | nxtDate: 'dd.MM.yy HH:mm' }} - {{ event.end | nxtDate: 'HH:mm' }}
      ・ {{ event.artist }}
      ・ {{ event.paymentSum | money2 }}
      ・ <div (click)="showEventClicked(event)">Termin öffnen</div>
    </div>
  }
</div>
