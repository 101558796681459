<nxt-form-field-wrapper
  [ngClass]="{'no-padding': noPadding, 'no-placeholder': noPlaceholder, 'full-width-mat-form-field': fullWidthMatFormField}"
  [nxtFormControl]="nxtFormControl"
  [width]="width"
  alignItems="center"
  fxFlex
  fxLayout="row">
  <mat-icon
    style="margin-right: 9px;"
    (click)="addDay(-1)"
    *ngIf="showJumpDayButtons"
    matTooltip="-1 Tag"
  >arrow_back
  </mat-icon>
  <mat-icon
    style="margin-right: 9px;"
    (click)="addDay(-7)"
    *ngIf="showJumpWeekButtons"
    matTooltip="-1 Woche"
  >arrow_back
  </mat-icon>
  <mat-icon
    style="margin-right: 9px;"
    (click)="addMonth(-1)"
    *ngIf="showJumpMonthButtons"
    matTooltip="-1 Monat"
  >arrow_back
  </mat-icon>
  <mat-form-field class="mat-form-field-full-width">
    <mat-label>{{ placeholder || nxtFormControl.name }}</mat-label>
    <input
      [ngStyle]="{width: inputWidth}"
      style="text-align: center"
      [readonly]="!disableOpenOnClickInput"
      #myInput
      #clickElement
      (click)="inputClicked()"
      matInput
      [matDatepicker]="datePicker"
      [formControl]="nxtFormControl"
      [min]="minDate"
    >
    <mat-datepicker-toggle *ngIf="!nxtFormControl.disabled" #datepickerToggle matSuffix [for]="datePicker"
                           style="position:absolute;top: -500px"></mat-datepicker-toggle>
    <mat-datepicker #datePicker
                    [disabled]="readonly"
                    (yearSelected)="chosenYearHandler($event)"
                    (monthSelected)="chosenMonthHandler($event, datePicker)"
                    [startView]="monthPicker ? 'multi-year' : 'month'"

    ></mat-datepicker>
    <!--<mat-datepicker #datePicker></mat-datepicker>-->

    <mat-error *ngIf="showInvalidError">
      <div *ngIf="nxtFormControl.errors.required">
        bitte eintragen!
      </div>
    </mat-error>

  </mat-form-field>
  <mat-icon
    *ngIf="false"
    (click)="toggle()">
    calendar_month
  </mat-icon>
  <mat-icon
    style="margin-left: 9px; width: 28px;"
    (click)="setToday()"
    *ngIf="showTodayButton"
    matTooltip="heute">
    system_update_alt
  </mat-icon>
  <mat-icon
    style="margin-left: 8px;"
    (click)="setThisWeek()"
    *ngIf="showWeekStart"
    matTooltip="aktuelle Woche">
    system_update_alt
  </mat-icon>
  <mat-icon
    style="margin-left: 8px;"
    (click)="addDay(1)"
    *ngIf="showJumpDayButtons"
    matTooltip="+1 Tag">
    arrow_forward
  </mat-icon>
  <mat-icon
    style="margin-left: 8px;"
    (click)="addDay(7)"
    *ngIf="showJumpWeekButtons"
    matTooltip="+1 Woche">
    arrow_forward
  </mat-icon>
  <mat-icon
    style="margin-left: 8px;"
    (click)="addMonth(1)"
    *ngIf="showJumpMonthButtons"
    matTooltip="+1 Monat">
    arrow_forward
  </mat-icon>
</nxt-form-field-wrapper>
