<div class="nxt-page">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-2">
    <div fxLayout="row" fxLayoutGap="50px" class="items-center">
      <div class="page-header">Rabatte</div>
      <nxt-input [(value)]="quickFilterText" (valueChange)="quickSearchTextChanged()" [clearIcon]="true">Suche</nxt-input>
      <nxt-slide-toggle [(value)]="showDisabled" (valueChange)="load()">Inaktive einblenden</nxt-slide-toggle>
      <nxt-button (click)="addNewClicked()" [noWrap]="true">neuer Rabatt</nxt-button>
    </div>
    <nxt-button-icon *ngIf="dialogRef" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>
  <nxt-datagrid
    [columnDefs]="columnDefs"
    [rowData]="discountPromotions"
    [autoSizeColumnOnResize]="true"
    [quickFilterText]="quickFilterText"
  >
  </nxt-datagrid>
</div>
