<div class="flex flex-col">
  <div class="flex flex-row">
    <div class="flex flex-col">
      <table>
        <tr>
          <td>1.</td>
          <td>auf dem Social-Media-Studio-Handy Telegram installieren und anmelden.</td>
        </tr>
        <tr>
          <td>2.</td>
          <td>Bitte mit dem Social-Media-Studio-Handy den QR-Code scannen.</td>
        </tr>
        <tr>
          <td>3.</td>
          <td>Fertig.</td>
        </tr>

      </table>
    </div>
    <div class="flex flex-col">
      <qrcode
        errorCorrectionLevel="M"
        [qrdata]="qrCode()"
        [width]="300" colorDark="#FFF"
        colorLight="#2A2A2A">
      </qrcode>
    </div>
  </div>
  <div class="flex flex-row justify-center">
    <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
  </div>
</div>
