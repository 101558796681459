import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';

@Component({
  selector: 'nxt-loading-net',
  templateUrl: './loading-net.component.html',
  styleUrls: ['./loading-net.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})

export class LoadingNetComponent extends NxtComponent implements OnInit, NxtOnDestroy, AfterViewInit {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private destroyed = false;

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.start(this);
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
    this.destroyed = true;
  }

  start(loadingNetComponent: LoadingNetComponent) {
    const points = [];
    const velocity2 = 5; // velocity squared
    const canvas = document.getElementById('container') as HTMLCanvasElement;
    const context = canvas.getContext('2d');
    const radius = 5;
    const boundaryX = 200;
    const boundaryY = 200;
    const numberOfPoints = 30;

    init();

    function init() {
      // create points
      for (let i = 0; i < numberOfPoints; i++) {
        createPoint();
      }
      // create connections
      for (let i = 0, l = points.length; i < l; i++) {
        const point = points[i];
        if (i === 0) {
          points[i].buddy = points[points.length - 1];
        } else {
          points[i].buddy = points[i - 1];
        }
      }

      // animate
      animate();
    }

    function createPoint() {
      const point = {
        x: Math.random() * boundaryX,
        y: Math.random() * boundaryY,
        vx: 0,
        vy: 0,
      };
      point.vx = (Math.floor(Math.random()) * 2 - 1) * Math.random();
      const vx2 = Math.pow(point.vx, 2);
      // vy^2 = velocity^2 - vx^2
      const vy2 = velocity2 - vx2;
      point.vy = Math.sqrt(vy2) * (Math.random() * 2 - 1);
      points.push(point);
    }

    function resetVelocity(point, axis, dir) {
      if (axis === 'x') {
        point.vx = dir * Math.random();
        const vx2 = Math.pow(point.vx, 2);
        // vy^2 = velocity^2 - vx^2
        const vy2 = velocity2 - vx2;
        point.vy = Math.sqrt(vy2) * (Math.random() * 2 - 1);
      } else {
        point.vy = dir * Math.random();
        const vy2 = Math.pow(point.vy, 2);
        const vx2 = velocity2 - vy2;
        point.vx = Math.sqrt(vx2) * (Math.random() * 2 - 1);
      }
    }

    function drawCircle(x, y) {
      context.beginPath();
      context.arc(x, y, radius, 0, 2 * Math.PI, false);
      context.fillStyle = '#b7860f'; // '#97badc';
      // context.fillStyle = '#a3a3a3'; // '#97badc';
      context.fill();
    }

    function drawLine(x1, y1, x2, y2) {
      context.beginPath();
      context.moveTo(x1, y1);
      context.lineTo(x2, y2);
      context.strokeStyle = 'rgba(183,134,15,0.5)'; // '#8ab2d8';
      /// context.strokeStyle = '#e4e4e4'; // '#8ab2d8';
      context.stroke();
    }

    function draw() {
      for (let i = 0, l = points.length; i < l; i++) {
        // circles
        const point = points[i];
        point.x += point.vx;
        point.y += point.vy;
        drawCircle(point.x, point.y);
        // lines
        drawLine(point.x, point.y, point.buddy.x, point.buddy.y);
        // check for edge
        if (point.x < 0 + radius) {
          resetVelocity(point, 'x', 1);
        } else if (point.x > boundaryX - radius) {
          resetVelocity(point, 'x', -1);
        } else if (point.y < 0 + radius) {
          resetVelocity(point, 'y', 1);
        } else if (point.y > boundaryY - radius) {
          resetVelocity(point, 'y', -1);
        }
      }
    }

    function animate() {
      context.clearRect(0, 0, 200, 200);
      draw();
      if (!loadingNetComponent.destroyed) {
        requestAnimationFrame(animate);
      }
    }
  }
}
