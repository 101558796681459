import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {MatDialogRef} from '@angular/material/dialog';
import {InputComponent} from '../../form-controls/input/input.component';
import {MoneyAccountTools} from '../../../common-browser/helpers/money-account.tools';
import {NxtMoneyAccount, NxtMoneyAccountType} from '../../../common-interfaces/money-account.interface';
import {WithoutNxtDbFields} from '../../../common-interfaces/nxt.db-fields.interface';
import {SocketService} from '../../../services/socket/socket.service';
import {FormGroup} from '@angular/forms';
import {NxtTypedFormControl} from '../../../nxt-form/nxt.typed-form-control';
import {FormTools} from '../../../services/form.tools';
import {SelectComponent} from '../../form-controls/select/select.component';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {DialogService} from '../../../services/dialog.service';
import {clone} from '../../../common-browser/helpers/object.tools';
import {ConfigService} from '../../../services/config.service';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {TextareaComponent} from '../../form-controls/textarea/textarea.component';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';

@Component({
  selector: 'nxt-money-account-edit',
  templateUrl: './money-account-edit.component.html',
  styleUrls: ['./money-account-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    SelectComponent,
    NxtButtonComponent,
    TextareaComponent,
    SlideToggleComponent,
  ],
})

export class MoneyAccountEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor() {
    super();
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private configService = inject(ConfigService);
  private dialogService = inject(DialogService);
  private dialogRef = inject(MatDialogRef<MoneyAccountEditComponent>, {optional: true});

  moneyAccountTypeOptions: { text: string, value: NxtMoneyAccountType }[] = [
    {text: 'Bar', value: 'cash'},
    {text: 'Bank', value: 'bank'},
  ];

  /*cashRegisters: { text: string, value: string }[] = [
    {text: 'Mallorca', value: 'ma'},
    {text: 'Düsseldorf', value: 'du'},
  ];*/

  private originalMoneyAccount: WithoutNxtDbFields<NxtMoneyAccount>;

  form = new FormGroup({
    name: new NxtTypedFormControl('', [], 'Name'),
    description: new NxtTypedFormControl('', [], 'Beschreibung'),
    type: new NxtTypedFormControl<NxtMoneyAccountType | null>(null, [ValidatorTools.requiredAndNotNaN], 'Typ'),
    userIds: new NxtTypedFormControl<string[]>([], [], 'Zugriff'),
    cashRegisterName: new NxtTypedFormControl<string>(null, [], 'Ladenkasse'),
    receiveCashRegisterSafeStudio: new NxtTypedFormControl<string>(null, [], 'Empfängt Tresor aus Studio'),
    syncToTransactionsHead: new NxtTypedFormControl<string>(null, [], 'Transaktions-Sync'),
  });

  userOptions = signal<{ text: string, value: string }[]>([]);
  regionOptions = signal<{ text: string, value: string }[]>([]);

  cashRegisterOptions = signal<{ text: string, value: string }[]>([
    {text: 'Villa', value: 'Villa'},
    {text: 'Villa 2', value: 'Villa 2'},
    {text: 'Düsseldorf', value: 'Düsseldorf'},
    {text: 'Düsseldorf 2', value: 'Düsseldorf 2'},
    {text: 'Dortmund', value: 'Dortmund'},
    {text: 'Mallorca', value: 'Mallorca'},
  ]);

  studioOptions = signal<{ text: string, value: string }[]>([
    {text: 'Aachen', value: 'ac'},
    {text: 'Düsseldorf', value: 'du'},
    {text: 'Dortmund', value: 'do'},
    {text: 'Mallorca', value: 'ma'},
  ]);


  ngOnInit() {
    this.loadUsers();
  }

  private async loadUsers() {
    const users = (await this.socketService.getUsers()).filter(u => !u.noPerson).sortString('username').map(u => ({text: u.username, value: u.id}));
    this.userOptions.set(users);
  }

  private async loadRegions() {
    const users = (await this.socketService.getUsers()).filter(u => !u.noPerson).sortString('username').map(u => ({text: u.username, value: u.id}));
    this.userOptions.set(users);
  }

  nxtOnDestroy() {
  }

  async new() {
    this.originalMoneyAccount = MoneyAccountTools.getEmpty();
    this.setFormFromMoneyAccount(this.originalMoneyAccount);
  }

  async load(moneyAccount: NxtMoneyAccount) {
    this.originalMoneyAccount = moneyAccount;
    this.setFormFromMoneyAccount(this.originalMoneyAccount);
  }

  private setFormFromMoneyAccount(moneyAccount: WithoutNxtDbFields<NxtMoneyAccount>) {
    FormTools.setForm(this.form, moneyAccount);
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  async saveClicked() {
    if (FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      const moneyAccount = this.getMoneyAccountFromForm();
      await this.socketService.upsertMoneyAccount(moneyAccount);
      this.dialogRef.close();
    }
    this.dialogRef.close();
  }

  private getMoneyAccountFromForm() {
    const moneyAccount: WithoutNxtDbFields<NxtMoneyAccount> = {...clone(this.originalMoneyAccount), ...this.form.getRawValue() as any};
    return moneyAccount;
  }
}
