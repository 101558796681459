import {Subscription} from 'rxjs';

export class NxtSocketSubscription {
  public subscription: Subscription;
  constructor(
    private register: (from: 'initial' | 'reconnect') => Subscription,
    public unsubscribe: () => void
  ) {
    this.subscription = this.register('initial');
  }

  public registerNewOnReconnected() {
    this.subscription?.unsubscribe();
    this.subscription = this.register('reconnect');
  }

  public registerInitial() {
    this.subscription?.unsubscribe();
    this.subscription = this.register('initial');
  }
}
