import {Component, EventEmitter, Input, OnInit, Output, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from '../../nxt.component';
import {SideBarLeftComponent} from '../side-bar-left.component';
import {SocketService} from '../../../services/socket/socket.service';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {NxtReminder, NxtReminderRefType} from '../../../common-interfaces/nxt.reminder.interface';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {IntervalTools} from '../../../common-browser/helpers/interval.tools';
import {DialogService} from '../../../services/dialog.service';
import {ObjectTools} from '../../../common-browser/helpers/object.tools';
import {EmojiTools} from '../../../common-browser/helpers/emoji.tools';
import {StencilService} from '../../../services/stencil.service';
import {TimeoutTools} from '../../../common-browser/helpers/timeout.tools';
import {LoginService} from '../../../services/login.service';
import {PermissionService} from '../../../services/permission.service';
import {NxtPermissionId} from '../../../common-interfaces/nxt.user.interface';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {NxtTimePipe} from '../../../pipes/nxt-time.pipe';
import {ReminderDateTimeClassPipe} from './reminder-date-time-class.pipe';
import {FromNowPipe} from '../../../pipes/from-now.pipe';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {toObservable} from '@angular/core/rxjs-interop';
import {WorkplacePipe} from '../../../pipes/workplace-pipe';
import {ReminderService} from '../../../services/reminder.service';
import {AudioService} from '../../../services/audio-service';
import {ConfigService} from '../../../services/config.service';

interface NxtReminderSideBar extends NxtReminder {
  color?: string;
  sortValue: number;
}

@Component({
  selector: 'nxt-studio-cash-report-side-bar',
  templateUrl: './studio-cash-report-side-bar.component.html',
  styleUrls: ['./studio-cash-report-side-bar.component.scss'],
  imports: [FlexModule, NgIf, NgFor, NgClass, ExtendedModule, NxtDatePipe, SafeHtmlPipe, FromNowPipe, ReminderDateTimeClassPipe, NxtTimePipe, SlideToggleComponent, WorkplacePipe],
  standalone: true,
})
export class StudioCashReportSideBarComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  private audioInterval: number;

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private stencilService: StencilService,
    private loginService: LoginService,
    private permissionService: PermissionService,
    public reminderService: ReminderService,
    public audioService: AudioService,
    public configService: ConfigService,
  ) {
    super();
    this.load(false);
    toObservable(this.filterOnlyMe).subscribe(() => this.load(false));
    this.audioInterval = window.setInterval(() => this.notificationInterval(), DurationTools.DURATION_1MINUTE * 5);
  }

  @Input() sideBar: SideBarLeftComponent;
  @Output() dataChanged = new EventEmitter<void>();
  public reminders: NxtReminderSideBar[];
  public remindersFiltered: NxtReminderSideBar[];
  private autoReloadInterval: any;
  private checkForHighlightInterval: any;

  NxtReminderRefType = NxtReminderRefType;
  goodEmojis = '';
  public todayDateString = DateTools.formatNowDate();
  remindersGrouped: { text: string, count: number, textShort: string, color: string }[] = [];
  currentReinderGroup = '';
  private clearReminderGroupFilterTimeout: any;
  filterOnlyMe = signal(true);


  ngOnInit() {
    this.pushSocketSubscription = this.socketService.subscribeNew('onReminderUpdate', () => {
      this.load(false);
    }, {emitInitial: true});
    this.startAutoReload();
  }

  public nxtOnDestroy(): void {
    this.stopIntervalls();
  }

  stopIntervalls() {
    IntervalTools.clear(this.autoReloadInterval);
    IntervalTools.clear(this.checkForHighlightInterval);
    IntervalTools.clear(this.audioInterval);
  }

  private async load(fromInterval: boolean) {
    this.setReminders(await this.socketService.getRemindersByDate({dateString: DateTools.formatNowDate(), appendRef: true, withOldOpen: true}));
    this.refreshReminders();
    this.goodEmojis = EmojiTools.getNice(2);
    if (fromInterval) {
      this.showImportantRemindersDialog();
    }
  }


  private startAutoReload() {
    this.stopIntervalls();
    if (window.location.hostname !== 'localhost') {
      this.autoReloadInterval = setInterval(() => this.load(true), DurationTools.DURATION_1MINUTE * 4);
      this.checkForHighlightInterval = setInterval(() => this.refreshReminders(), DurationTools.DURATION_1MINUTE);
    }
  }

  private checkForHighlight() {
    let doHighlight = false;
    for (const reminder of this.reminders) {
      if (reminder.reminderDateTime < Date.now()) {
        doHighlight = true;
      }
    }
    if (this.sideBar) {
      this.sideBar?.highlight(doHighlight);
      if (doHighlight) {
        this.sideBar.expand();
      }
    }
  }

  /*private filterReminder() {
    this.reminders = this.reminders.filter(r => true);

  }*/

  refreshReminders() {
    if (this.reminders) {
      this.reminders.forEach(reminder => {
        reminder.color = NxtReminder.GetReminderColor(reminder);
        reminder.sortValue = NxtReminder.GetReminderSortValue(reminder);
      });
      this.setReminders(ObjectTools.clone(this.reminders));
      this.checkForHighlight();
    }
    this.dataChanged.emit();
  }

  public async reminderClicked(reminder: NxtReminder) {
    this.reminderService.reminderClicked(reminder);
    /*if (reminder.clickAction) {
      if (reminder.clickAction === NxtReminderClickAction.OpenWhatsAppConnect) {
        window.open('/#/qr', 'blank');
        return;
      }
    }
    if (reminder.type === 'Artist_ContractIsMissing') {
      const openArtistContract = await this.dialogService.showYesNo('Artist-Vertrag drucken?', {yesText: 'Drucken', noText: 'Zurück'});
      if (openArtistContract) {
        this.printArtistContract(reminder.refId);
        return;
      }
    }
    if (reminder.refType === NxtReminderRefType.Event) {
      this.dialogService.showEvent(reminder.refId);
    }*/
  }


  public async postpone(reminder: NxtReminder, ev: MouseEvent) {
    ev.stopPropagation();
    ev.preventDefault();
    if (reminder.maxPostponesReached) {
      this.dialogService.showOk('Mehr als ' + reminder.maxPostpones + ' mal verschieben ist nicht möglich!\n\nBitte erledige jetzt diese Aufgabe!');
      return;
    }
    /*if (!reminder.postponeDuration) {
      reminder.postponeDuration = DurationTools.DURATION_1MINUTE * 10;
    }
    if (!reminder.postponeDurations && reminder.postponeDuration) {
      reminder.postponeDurations = [reminder.postponeDuration];
    }*/
    if (!reminder.postponeDurations) {
      reminder.postponeDurations = [DurationTools.DURATION_1MINUTE * 10];
    }

    const result = await this.dialogService.showButtons(reminder.description, {
      buttons: reminder.postponeDurations.map(d => ({text: DurationTools.toText(d) + ' verschieben', value: d})),
      textAlign: 'center',
      showCancelButton: true,
    });
    if (result?.value) {
      this.socketService.postponeReminder({reminderId: reminder.id, postponeDuration: result.value}).then();
    }
  }

  private setReminders(reminders: NxtReminderSideBar[]) {
    if (!reminders) {
      return;
    }
    if (this.filterOnlyMe()) {
      reminders = reminders.filter(reminder => {
        if (!this.filterOnlyMe()) {
          return true;
        }
        const workplaceOk = !reminder.toDoByWorkplace || reminder.toDoByWorkplace === this.loginService.getWorkplace();
        const userOk = !reminder.toDoByUser || reminder.toDoByUser === this.loginService.getUsername();
        return workplaceOk && userOk;
      });
    }
    reminders.forEach(r => {
      if (!r.priority) {
        r.priority = 0;
      }
    });
    this.reminders = reminders.filter(r => {
      if ((r.reminderDateTime - DurationTools.DURATION_1MINUTE * 15) > Date.now() && r.hideIfNotOverDue) {
        return false;
      }
      return true;
    }).sort(NxtReminder.sortReminders);
    this.remindersGrouped = [];
    const result: { [text: string]: number } = {};
    const color: { [text: string]: string } = {};
    for (const r of this.reminders) {
      if (!r.groupName) {
        r.groupName = r.description;
      }
      if (!result[r.groupName]) {
        color[r.groupName] = '';
        result[r.groupName] = 0;
      }
      result[r.groupName]++;
      if (r.color === ColorTools.RedDark) {
        color[r.groupName] = ColorTools.RedDark;
      } else if (r.color === ColorTools.OrangeDark && color[r.groupName] !== ColorTools.RedDark) {
        color[r.groupName] = ColorTools.OrangeDark;
      }
    }
    for (const text of Object.keys(result)) {
      const textShort = text.length > 20 ? (text.substring(0, 20) + '...') : text;
      this.remindersGrouped.push({text, count: result[text], textShort, color: color[text]});
    }
    this.setFilteredReminders();
  }


  public sendStencilRequest(reminder: NxtReminder & { isOverDue?: boolean }, ev: MouseEvent) {
    ev.stopPropagation();
    ev.preventDefault();
    this.stencilService.showStencilRequestDialog(reminder.refId).then();
  }


  private setFilteredReminders() {
    if (this.currentReinderGroup) {
      // gibt es die Gruppe überhaupt noch?
      if (!this.remindersGrouped.find(r => r.text === this.currentReinderGroup)) {
        this.currentReinderGroup = null;
      }
    }

    this.remindersFiltered = this.reminders.filter(r => {
      if (this.currentReinderGroup) {
        return r.groupName === this.currentReinderGroup;
      } else {
        if (r.priority < 30 && r.color !== 'red') {
          return false;
        }
      }
      return true;
    });
  }

  showImportantRemindersDialog() {
    if (this.loginService.isReception()) {
      const alarmReminders = this.reminders.filter(r => r.color === 'red');
      if (alarmReminders.length > 0) {
        this.dialogService.showOk('<div class="text-200">Es gibt überfällige rote Aufgaben!\n\nBitte erledige Sie!</div>', {
          title: '<div class="text-250">📣📣📣</div>',
          timeoutSeconds: 40,
          buttonText: 'JA, mache ich jetzt direkt!',
          // showOkButtonTimeoutSeconds: 10
        });
      }
    }
  }

  public reminderGroupClicked(reminderGrouped: { text: string; count: number }) {
    TimeoutTools.clear(this.clearReminderGroupFilterTimeout);
    if (this.currentReinderGroup === reminderGrouped.text) {
      this.currentReinderGroup = '';
    } else {
      this.currentReinderGroup = reminderGrouped.text;
      this.clearReminderGroupFilterTimeout = setTimeout(() => {
        this.currentReinderGroup = '';
        this.setFilteredReminders();
      }, DurationTools.DURATION_1MINUTE * 3);
    }
    this.setFilteredReminders();
  }

  public async reminderGroupContextClicked(reminderGrouped: { text: string; count: number }, ev: MouseEvent) {
    ev.stopPropagation();
    ev.preventDefault();
    if (this.permissionService.hasPermission(NxtPermissionId.SuperAdmin)) {
      const remindersToSetDone = this.reminders.filter(r => r.description === reminderGrouped.text);
      if (await this.dialogService.showYesNo(remindersToSetDone.length + ' Aufgaben auf erledigt setzen?\n\n' + reminderGrouped.text)) {
        for (const reminderToSetDone of remindersToSetDone) {
          this.socketService.setReminderDone(reminderToSetDone.id, true);
        }
      }
    }
  }

  private async notificationInterval() {
    if (this.loginService.isBackoffice() && this.socketService.state.value.authenticated) {
      if (this.remindersFiltered.some(r => (r.reminderDateTime + (DurationTools.DURATION_1MINUTE * 3)) < Date.now())) {
        this.audioService.playReminderNotification();
      }
    }
  }
}
