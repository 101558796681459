<div fxLayout="column" class="nxt-page h-100">

  <div class="flex row justify-between">
    <div class="text-2xl">Abrechnungs-Log</div>
    <nxt-date-picker [(nxtModel)]="dateString" (nxtModelChange)="load()" [showJumpDayButtons]="true"></nxt-date-picker>
    <nxt-button-icon (click)="closeClicked()">close</nxt-button-icon>
  </div>
  <nxt-datagrid
    class="h-100"
    [columnDefs]="columnDefs"
    [rowData]="logs"
  >
  </nxt-datagrid>
</div>
