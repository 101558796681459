import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, signal, ViewChild, ViewChildren} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {LoginService} from '../../services/login.service';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {CellRenderValues, NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {DialogService, LoadingId} from '../../services/dialog.service';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom, Subscription} from 'rxjs';
import {NxtCalendarEvent, NxtCalendarEventPriceChange} from '../../common-interfaces/nxt.calendar-event.interface';
import {NxtFormControl} from '../../nxt-form/nxt.form-control';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {DecimalTools} from '../../common-browser/helpers/decimal.tools';
import {GiftCardFormComponent} from '../giftcard-form/gift-card-form.component';
import {PaymentTools} from '../../common-browser/helpers/payment.tools';
import {ColorTools} from '../../common-browser/helpers/color.tools';
import {ElectronService} from '../../services/electron.service';
import {EventCalcTools} from '../../common-browser/helpers/event-calc.tools';
import {TimeTools} from '../../common-browser/helpers/time.tools';
import {KeyCode, ShortcutService} from '../../services/shortcut.service';
import {CellClickedEvent} from 'ag-grid-community';
import {CashReportIncomingOutgoingFormComponent} from '../cash-report-incoming-outgoing-form/cash-report-incoming-outgoing-form.component';
import {NxtSubscriptionClass} from '../../classes/nxt-subscription-class';
import {BirthdayTools} from '../../common-browser/helpers/birthday.tools';
import {IconTools} from '../../common-browser/helpers/icon.tools';
import {ConfigService} from '../../services/config.service';
import {DayFinishService} from '../../services/day-finish.service';
import {PermissionService} from '../../services/permission.service';
import {CalcComponent} from '../calc/calc.component';
import {NxtPermissionId} from '../../common-interfaces/nxt.user.interface';
import {WhatsAppChatsComponent} from '../whatsapp/whatsapp-chats/whats-app-chats.component';
import {CalendarEventService} from '../../services/calendar-event.service';
import {GiftCardEditComponent} from '../giftcard-edit/gift-card-edit.component';
import {StringTools} from '../../common-browser/helpers/string.tools';
import {RomanNumeralsComponent} from '../roman-numerals/roman-numerals.component';
import {EventTools} from '../../common-browser/helpers/event.tools';
import {SearchComponent} from '../../pages/search/search.component';
import {ArtistSpotEditComponent} from '../artist-spot-edit/artist-spot-edit.component';
import {TimeoutTools} from '../../common-browser/helpers/timeout.tools';
import {EventConsentTools} from '../../common-browser/helpers/event-consent.tools';
import {StencilService} from '../../services/stencil.service';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {StudioCashReport2Service} from './studio-cash-report-2.service';
import {NxtWorkSessionCashRegisterState, NxtWorkSessionState} from '../../common-interfaces/nxt.work-session';
import {DayFinishView2Component} from './day-finish/day-finish-view-2/day-finish-view-2.component';
import {WorkSessionEditComponent} from '../work-session/work-session-edit.component';
import {keys, ObjectTools} from '../../common-browser/helpers/object.tools';
import {WorkSessionService} from '../../services/work-session.service';
import {CashPaymentService} from 'src/app/services/cash-payment.service';
import {ScrArtistContextMenuAction, ScrArtistContextMenuComponent} from './scr-artist-context-menu/scr-artist-context-menu.component';
import {CalcArtistData} from '../../common-browser/helpers/artists.tools';
import {ScrPreStartDayFinishComponent} from './scr-pre-start-day-finish/scr-pre-start-day-finish.component';
import {LocalStorageService} from '../../services/local-storage.service';
import {NewIncomingOutgoingService} from './new-incoming-outgoing.service';
import {SaleService} from '../../services/sale.service';
import {WorkSessionTools} from '../../common-browser/helpers/work-session.tools';
import {HtmlTools} from '../../common-browser/helpers/html.tools';
import {NxtFieldType} from 'src/app/common-interfaces/nxt-field.interface';
import {CalendarEventEdit2Component} from '../../pages/calendar-event-edit/calendar-event-edit-2/calendar-event-edit-2.component';
import {NxtPayment} from '../../common-interfaces/nxt.payment.interface';
import {ContactTools} from '../../common-browser/helpers/contact.tools';
import {WorkplacePipe} from '../../pipes/workplace-pipe';
import {MoneyPipe} from '../../pipes/money.pipe';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {StudioCashReport2MenuComponent} from './menu/studio-cash-report-2-menu.component';
import {MatIcon} from '@angular/material/icon';
import {InputComponent} from '../form-controls/input/input.component';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {RadioComponent} from '../form-controls/radio/radio.component';
import {DatePickerComponent} from '../form-controls/date-picker/date-picker.component';
import {PermissionDirective} from '../../directives/permission.directive';
import {MatTooltip} from '@angular/material/tooltip';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {MultiClickDirective} from '../../directives/multi-click.directive';
import {StudioCashReportSideBarComponent} from '../side-bar-left/studio-cash-report-side-bar/studio-cash-report-side-bar.component';
import {SideBarLeftComponent} from '../side-bar-left/side-bar-left.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {AsyncPipe, NgClass, NgFor, NgIf, NgStyle} from '@angular/common';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {BodyPutService} from '../../services/body-put.service';
import {WalkInService} from '../../services/walk-in.service';
import {ArtistConfirmService} from '../../services/artist-confirm.service';
import {EventsRatingComponent} from '../../pages/events-rating/events-rating.component';
import {TypeTools} from '../../common-browser/helpers/type.tools';
import {PhotosComponent} from '../photos/photos.component';

@Component({
  selector: 'nxt-studio-cash-report-2',
  templateUrl: './studio-cash-report-2.component.html',
  styleUrls: ['./studio-cash-report-2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, FlexModule, ExtendedModule, SideBarLeftComponent, StudioCashReportSideBarComponent, MultiClickDirective, NxtDatagridComponent, NxtButtonComponent, NxtButtonIconComponent, MatTooltip, PermissionDirective, DatePickerComponent, RadioComponent, NgStyle, SlideToggleComponent, InputComponent, NgClass, MatIcon, ScrArtistContextMenuComponent, NgFor, StudioCashReport2MenuComponent, NxtDatePipe, SafeHtmlPipe, MoneyPipe, WorkplacePipe, AsyncPipe],
  standalone: true,
})
export class StudioCashReport2Component extends NxtSubscriptionClass implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private cdr: ChangeDetectorRef,
    public socketService: SocketService,
    public loginService: LoginService,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private electronService: ElectronService,
    private router: Router,
    private shortCutService: ShortcutService,
    public configService: ConfigService,
    private dayFinishService: DayFinishService,
    public permissionService: PermissionService,
    private eventService: CalendarEventService,
    private stencilService: StencilService,
    private workSessionService: WorkSessionService,
    private cashPaymentService: CashPaymentService,
    private localStorageService: LocalStorageService,
    private newIncomingOutgoingService: NewIncomingOutgoingService,
    private saleService: SaleService,
    private bodyPutService: BodyPutService,
    private walkInService: WalkInService,
    private artistConfirmService: ArtistConfirmService,
  ) {
    super();
    this.myService = new StudioCashReport2Service(
      this.socketService,
      this.loginService,
      this.dialogService,
      this.configService,
      this.workSessionService,
      this.permissionService,
      this.dayFinishService,
      this.cashPaymentService,
      this.walkInService,
      this.artistConfirmService,
    );
    this.initShortCuts();
    this.initAuthenticationListener();
    if (this.activatedRoute.snapshot.paramMap.get('studio')) {
      this.setStudioCash(this.activatedRoute.snapshot.paramMap.get('studio'));
    } else {
      if (!this.myService.studioView) {
        this.setStudioCash(this.loginService.getStudio());
      }
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('date')) {
      if (this.permissionService.hasPermission(NxtPermissionId.StudioCashReport_DatePicker)) {
        this.myService.dateString = this.activatedRoute.snapshot.queryParamMap.get('date');
      } else {
        this.router.navigate([], {
          queryParams: {date: null},
          queryParamsHandling: 'merge',
          replaceUrl: true,
        }).then();
      }
    }
    this.registerEventListeners();
    this.myService.showDateInGrid.subscribe(async (show) => {
      await ObjectTools.waitFor(() => this.nxtDataGrids && this.nxtDataGrids.length > 0, 'nxtDataGrids');
      if (this.nxtDataGrids) {
        for (const dataGrid of this.nxtDataGrids) {
          dataGrid.api?.setColumnsVisible(['date'], show);
        }
      }
      this.detectChanges();
    });
    this.showOldVersionButton = this.localStorageService.get('ShowOldNewVersionButton', false);

    this.registerDetectChangesEvents();
  }

  showPostponedEvents = signal(true);

  private initDone = false;

  NxtWorkSessionCashRegisterState = NxtWorkSessionCashRegisterState;
  public myService: StudioCashReport2Service;

  canShowTotalMoney = !this.permissionService.hasPermission(NxtPermissionId.IsAc) || this.loginService.isBackoffice();
  eventRowClickedTimeout: any;

  @ViewChildren(NxtDatagridComponent) nxtDataGrids: QueryList<NxtDatagridComponent>;
  @ViewChild('calendarEventsDataGrid', {static: false}) calendarEventsDataGrid: NxtDatagridComponent;
  @ViewChild('menuNew', {static: false}) menuNew: MatMenu;
  @ViewChild('artistGetMenu') artistGetMenu: MatMenu;
  @ViewChild('artistGetMenuTrigger') artistGetMenuTrigger: MatMenuTrigger;
  @ViewChild('artistGetMenuTriggerWrapper') artistGetMenuTriggerWrapper: ElementRef;
  @ViewChild(ScrArtistContextMenuComponent) scrArtistContextMenuComponent: ScrArtistContextMenuComponent;

  studioReal: string;
  cashReportIncomingSubscription: Subscription;
  cashReportOutgoingSubscription: Subscription;
  incomingColumnDefs: NxtColDef<NxtPayment>[];
  outgoingColumnDefs: NxtColDef<NxtPayment>[];
  calendarEventColDefs: NxtColDef<NxtCalendarEvent>[];
  eventDetails = '';
  cashReportHideArtistGetValues = this.configService.config.value.cashReportHideArtistGetValues && this.loginService.isReception();
  quickFilterFormControl = new NxtFormControl('');
  walkInFilterText = new NxtFormControl('');
  quickFilterText = '';
  autoSizeAllColumnsInterval: number;
  allCashRegisters: string[];
  showFilterToggle: any = {};
  showArtistGetValues = !this.configService.config.value.cashReportHideArtistGetValues || !this.loginService.isReception();
  showOldVersionButton = false;

  protected readonly NxtWorkSessionState = NxtWorkSessionState;

  testIsRunning = false;
  testInterval: number;

  protected readonly NxtPermissionId = NxtPermissionId;

  ngAfterViewInit(): void {
    this.detectChanges();
  }


  private initAuthenticationListener() {
  }

  async ngOnInit() {
    this.initDone = true;
    this.myService.onOpenStudioCashReport();
    setTimeout(() => {
      this.setColumnDefs();
    }, 100);

    this.quickFilterFormControl.valueChanges.subscribe((filterText) => {
      this.quickFilterText = filterText;
      for (const key of Object.keys(this.showFilterToggle)) {
        if (filterText !== key) {
          this.showFilterToggle[key] = false;
        }
      }
    });

    this.walkInFilterText.valueChanges.subscribe((filterText) => {
      if (filterText) {
        const filterOb = {
          tw: {
            filterType: 'text',
            type: 'contains',
            filter: filterText,
          },
        };
        this.calendarEventsDataGrid.api.setFilterModel(filterOb);
      } else {
        this.calendarEventsDataGrid.api.setFilterModel({});
      }
    });
    this.detectChanges();
  }

  private initShortCuts() {
    this.shortCutService.onKeyPress.subscribe((key: KeyCode) => {
      if (key === KeyCode.AltP) {
        this.showCalc();
      }
    });
  }

  ngOnDestroy(): void {
    this.myService.onCloseStudioCashReport();
    this.onDestroy();
    this.unsubscribe([this.cashReportIncomingSubscription, this.cashReportOutgoingSubscription]);
    clearInterval(this.autoSizeAllColumnsInterval);
  }

  logout() {
    this.loginService.reLogin(true).then();
  }

  private checkCloseEventReminder(event: NxtCalendarEvent, moreTimeDuration: number) {
    const minutes = moreTimeDuration / 1000 / 60;
    if (minutes % 30 === 0) {
      this.electronService.show();
      this.dialogService.showOk('Der Termin\n' + event.title + '\nist ' + minutes + ' Minuten überfällig').then();
    }
  }

  private calendarEventHastPayMore(calendarEvent: NxtCalendarEvent) {
    return !calendarEvent.closed && calendarEvent.end < Date.now();
  }

  newGiftCardClick() {
    this.dialogService.showComponentDialog(GiftCardFormComponent);
  }

  private editOutgoingClicked(params: CellClickedEvent) {
    if (params.data.paymentType === 'cash-report-outgoing') {
      if (params.data.paymentType === 'cash-report-outgoing') {
        const dialog = this.dialogService.showComponentDialog(CashReportIncomingOutgoingFormComponent);
        dialog.componentInstance.loadFormId('outgoing', params.data.paymentUuid).then();
      }
    } else if (params.data.eventId) {
      const dialog = this.dialogService.showComponentFull(CalendarEventEdit2Component);
      setTimeout(() => {
        dialog.componentInstance.loadEvent({eventId: params.data.eventId}).then();
      }, 500);
    }
  }

  private async editIncomingClicked(params: CellClickedEvent) {
    if (params.data.paymentType === 'gift-card-sold') {
      if (this.permissionService.hasPermission(NxtPermissionId.StudioCashReport_CanEditGiftCard)) {
        const dialog = this.dialogService.showComponentDialog(GiftCardEditComponent);
        dialog.componentInstance.load(params.data.paymentUuid).then();
      }
    } else if (params.data.paymentType === 'cash-report-incoming') {
      if (params.data.eventId) {
        const dialog = this.dialogService.showComponentFull(CalendarEventEdit2Component);
        setTimeout(() => {
          dialog.componentInstance.loadEvent({eventId: params.data.eventId});
        }, 100);
      } else {
        if (this.permissionService.hasPermission(NxtPermissionId.StudioCashReport_CanEditIncoming)) {
          const dialog = this.dialogService.showComponentDialog(CashReportIncomingOutgoingFormComponent);
          dialog.componentInstance.loadFormId('incoming', params.data.paymentUuid).then();
        }
      }
    } else if (params.data.eventId) {
      const dialog = this.dialogService.showComponentFull(CalendarEventEdit2Component);
      setTimeout(() => {
        dialog.componentInstance.loadEvent({eventId: params.data.eventId});
      }, 100);
    }
  }

  private setColumnDefs() {
    this.incomingColumnDefs = [
      {
        colId: 'date',
        headerName: 'Datum',
        field: 'createdAt',
        headerClass: 'center',
        nxtFieldType: NxtFieldType.Date_germanDateShort, minWidth: 70, maxWidth: 70,
        cellStyle: (params) => {
          return {textAlign: 'center', color: params.data.isPrivate ? ColorTools.GridTextLighter : ''};
        },
        hide: !this.myService.showDateInGrid.value,
      }, {
        headerName: 'Zeit',
        headerClass: 'text-center',
        field: 'createdAt',
        nxtFieldType: NxtFieldType.Date_germanTime, minWidth: 65, maxWidth: 65,
        cellStyle: (params) => {
          return {textAlign: 'center', color: params.data.isPrivate ? ColorTools.GridTextLighter : ''};
        },
      }, {
        colId: 'edit',
        headerName: '',
        nxtFieldType: NxtFieldType.Text,
        maxWidth: 50,
        minWidth: 50,
        nxtOnCellClicked: (params) => this.editIncomingClicked(params),
        cellStyle: {textAlign: 'center'},
        cellRenderer: (params: any) => {
          if (params.data.paymentType === 'gift-card-sold') {
            if (this.permissionService.hasPermission(NxtPermissionId.StudioCashReport_CanEditGiftCard)) {
              return IconTools.Material.Edit;
            }
          } else if (params.data.paymentType === 'cash-report-incoming') {
            if (params.data.eventId) {
              return IconTools.Material.EditCalendar;
            }
            if (this.permissionService.hasPermission(NxtPermissionId.StudioCashReport_CanEditIncoming)) {
              return IconTools.Material.Edit;
            }
          } else {
            return IconTools.Material.EditCalendar;
          }
        },
      },
      {headerName: 'Erfasst', field: 'createdBy', nxtFieldType: NxtFieldType.Text, maxWidth: 90},
      {
        headerName: 'Zahlung',
        field: 'paymentType',
        nxtFieldType: NxtFieldType.PaymentType,
        minWidth: 100,
        maxWidth: 160,
      },
      {
        headerName: 'Betrag',
        field: 'paymentValue',
        nxtFieldType: NxtFieldType.Money,
        maxWidth: 95,
        cellStyle: {textAlign: 'right'},
      },
      {
        headerName: 'Artist', field: 'artist', nxtFieldType: NxtFieldType.Text,
      },
      {
        headerName: 'Termin', field: 'eventId', nxtFieldType: NxtFieldType.ShowCalendarEvent, maxWidth: 70,
        cellStyle: {textAlign: 'center'}, hide: true,
      },
      {
        headerName: '_mögl', field: 'isPrivate', valueGetter: (params) => {
          if (params.data.isPrivate && params.data.isPrivate.toString() === 'loading') {
            return 'loading';
          }
          if (params.data.isPrivate) {
            return 'ist';
          } else {
            if (this.myService.data.workSession) {
              if (params.data.createdAt > this.myService.data.workSession.startAt && params.data.createdAt < this.myService.data.workSession.endAt) {
                return ['complete'].indexOf(params.data.paymentType) > -1 ? 'mögl.' : '';
              }
            }
          }
        },
        minWidth: 70,
        maxWidth: 70,
        cellStyle: {textAlign: 'center'},
        nxtOnCellDoubleClicked: async (params) => {
          if (params.value === 'mögl.') {
            if (this.permissionService.hasPermission(NxtPermissionId.StudioCashReport_Switch_)) {
              setTimeout(async () => {
                params.node.setDataValue('isPrivate', 'loading');
                const r = await this.setEventTo_FromPayment(params.data);
                if (!r.success) {
                  params.node.setDataValue('isPrivate', '');
                }
              });
            }
          } else if (params.value === 'ist') {
            if (this.permissionService.hasPermission(NxtPermissionId.StudioCashReport_Switch_)) {
              params.node.setDataValue('isPrivate', 'loading');
              const r = await this.setEventBackFrom_FromPayment(params.data);
              if (!r.success) {
                params.node.setDataValue('isPrivate', 'private');
              }
            }
          }
        },
        hide: !this.permissionService.hasPermission(NxtPermissionId.StudioCashReport_Switch_),
        cellRenderer: (params: any) => {
          if (params.data.eventInvoiceNumber) {
            return 'R. da';
          }
          if (params.value === 'loading') {
            return CellRenderValues.Loading;
          }
          return params.value;
        },
      },
      {
        headerName: 'Info',
        field: 'eventCustomerName',
        nxtFieldType: NxtFieldType.Text,
        maxWidth: 500,
        valueGetter: params => params.data.eventCustomerName || params.data.cashIncomingOutgoingDescription || params.data.giftCardDescription,
        cellRenderer: (params: any) => (params.data.isPrivate ? '_' : '') + params.valueFormatted,
      },
    ];


    this.outgoingColumnDefs = [
      {
        colId: 'date',
        headerName: 'Datum',
        field: 'createdAt',
        nxtFieldType: NxtFieldType.Date_germanDateShort, minWidth: 68, maxWidth: 68,
        cellStyle: (params) => {
          return {textAlign: 'center', color: params.data.isPrivate ? ColorTools.GridTextLighter : ''};
        },
        hide: !this.myService.showDateInGrid.value,
      }, {
        headerName: 'Zeit',
        field: 'createdAt',
        nxtFieldType: NxtFieldType.Date_germanTime,
        minWidth: 68,
        maxWidth: 68,
        cellStyle: (params) => {
          return {textAlign: 'center', color: params.data.isPrivate ? ColorTools.GridTextLighter : ''};
        },
      },
      {
        headerName: '',
        nxtFieldType: NxtFieldType.Text,
        maxWidth: 50,
        minWidth: 50,

        nxtOnCellClicked: (params) => this.editOutgoingClicked(params),
        cellStyle: {textAlign: 'center'},
        cellRenderer: (params: any) => {
          if (params.data.paymentType === 'cash-report-outgoing') {
            if (this.permissionService.hasPermission(NxtPermissionId.StudioCashReport_CanEditOutgoing)) {
              return IconTools.Material.Edit;
            }
          } else {
            return IconTools.Material.EditCalendar;
          }
        },
      },
      {
        headerName: 'B', field: 'attachment', nxtFieldType: NxtFieldType.Text, width: 45, maxWidth: 45,
        cellStyle: {textAlign: 'center'},
        nxtOnCellClicked: (params) => {
          if (params.data.paymentType === 'cash-report-outgoing' && !params.data.isPrivate) {
            this.editOutgoingClicked(params);
          }
        },
        cellRenderer: (params: any) => {
          if (params.data.paymentType === 'cash-report-outgoing') {
            if (params.value) {
              return IconTools.Material.Check;
            } else {
              if (!params.data.isPrivate && !params.data.articleId.includes('bank') && !params.data.articleId.includes('transfer') && !params.data.articleId.includes('cash_to_private')) {
                return '🔴';
              }
            }
          }
        },
      },
      {headerName: 'Erfasst', field: 'createdBy', nxtFieldType: NxtFieldType.Text, maxWidth: 90},
      {headerName: 'An', field: 'artist', nxtFieldType: NxtFieldType.Text, width: 90},
      {
        headerName: 'Zahlung',
        field: 'paymentType',
        nxtFieldType: NxtFieldType.PaymentType,
        minWidth: 130,
        maxWidth: 170,
      },
      {
        headerName: 'Betrag',
        field: 'paymentValue',
        nxtFieldType: NxtFieldType.Money,
        maxWidth: 95,
        cellStyle: {textAlign: 'right'},
      },
      {
        headerName: 'Termin',
        field: 'eventId',
        nxtFieldType: NxtFieldType.ShowCalendarEvent,
        maxWidth: 70,
        cellStyle: {textAlign: 'center'},
        hide: true,
      },

      {
        headerName: 'Info',
        field: 'eventCustomerName',
        nxtFieldType: NxtFieldType.Text,
        maxWidth: 500,
        valueGetter: params => params.data.eventCustomerName || params.data.cashIncomingOutgoingDescription || params.data.giftCardDescription,
        cellRenderer: (params: any) => {
          let preIcon: string;
          if (params.data.eventId) {
            preIcon = 'person';
          } else {
            preIcon = IconTools.getIconName(params.value);
          }
          if (!preIcon && params.data.paymentType === 'cash-report-outgoing') {
            preIcon = 'logout';
          }
          let result = params.valueFormatted;
          if (params.data.paymentType && params.data.paymentType === 'cash-report-outgoing' && params.data.originalCreatedBy) {
            result += ' (Beleg von ' + params.data.originalCreatedBy + ')';
          }
          if (preIcon) {
            result = '<div style="display:flex; align-items:center;"><span style="font-size:18px; line-height: 24px;" class="material-icons icon-image-preview">' + preIcon + '</span>&nbsp;<span>' + (params.data.isPrivate ? '_' : '') + result + '</span></div>';
          }
          if (params.data.isPrivate) {
            // result = '<div style="display:inline-block">_' + result;
          }

          return result;
        },
      },
    ];

    this.calendarEventColDefs = [
      {
        hide: this.loginService.isReception(),
        headerName: 'RE',
        headerTooltip: 'Rechnung',
        field: 'invoiceNumber',
        nxtOnCellDoubleClicked: async (params) => {
          if (this.loginService.isJulian()) {
            TimeoutTools.clear(this.eventRowClickedTimeout);
            params.data.invoiceNumber = '';
            await this.setInvoiceNumber(params.data, '');
            params.node.setDataValue('invoiceNumber', '');
          }
        },
        nxtOnCellClicked: async params => {
          if (params.data.invoiceNumber) {
            const invoice = await this.socketService.getArtistInvoice(params.data.invoiceNumber);
            this.dialogService.showPdf(invoice.invoiceSignedBase64 || invoice.invoiceBase64, invoice.invoiceNumber + '.pdf');
          }
        },
      },
      {
        headerName: '',
        field: 'id',
        nxtFieldType: NxtFieldType.ShowCalendarEvent,
        minWidth: 50,
        maxWidth: 50,
        getQuickFilterText: (params) => this.getQuickFilterTextForEvent(params.data),
        valueGetter: (params) => {
          return params.data.id;
        }, cellStyle: {textAlign: 'center'},
      },
      {
        headerName: '',
        colId: 'tw',
        field: 'id',
        tooltipValueGetter: (params) => {
          if (params.data.fastWalkInNo) {
            if (params.data.workType === 'piercing') {
              return 'Piercing Walk-In Nr. ' + params.data.fastWalkInNo;
            }
            return 'Tattoo Walk-In Nr. ' + params.data.fastWalkInNo;
          }
          if (params.data.followUp) {
            return 'Folgetermin ' + (params.data.followUp.index + 1) + ' von ' + params.data.followUp.total;
          }
          return '';
        },
        nxtFieldType: NxtFieldType.Text,
        minWidth: 70, // 70 damit w-123 auch passt
        maxWidth: 70,
        valueGetter: (params) => {
          if (params.data.fastWalkInNo) {
            if (params.data.workType === 'piercing') {
              return 'P-' + params.data.fastWalkInNo;
            }
            if (params.data.workType === 'tooth-gem') {
              return 'Z-' + params.data.fastWalkInNo;
            }
            return 'T-' + params.data.fastWalkInNo;
          }
          if (params.data.followUp) {
            return (params.data.followUp.index + 1) + ' / ' + params.data.followUp.total;
          }
          return '';
          // }
        }, nxtOnCellClicked: async (params) => {
          if (params.data.workType === 'piercing' && params.data.fastWalkInNo) {
            const buttons = [
              {text: 'PiercingLink erneut verschicken', value: 'resend'},
              {text: 'WalkIn-Nr löschen (Uhrzeit zählt dann)', value: 'deleteFastWalkIn'},
              {text: 'Abbrechen', value: 'cancel'},
            ];
            const result = await this.dialogService.showButtons('Was willst du machen?', {buttons});
            if (result?.value === 'resend') {
              this.socketService.piercingManagerSendRegisterLink(params.data.id).then();
            } else if (result?.value === 'deleteFastWalkIn') {
              this.socketService.setEventToNoWalkIn(params.data.id).then();
            }
          }
        },
        cellStyle: (params) => {
          if (this.configService.config.value.studioRegion === 'AC' || this.configService.config.value.studioRegion === 'STAGING') {
            if (params.data.workType === 'piercing') {
              if (params.data.fastWalkInRegisteredAt === 0) {
                return {textAlign: 'center', color: ColorTools.Red};
              }
            }
          }
          return {textAlign: 'center'};
        },
        getQuickFilterText: (params) => {
          if (params.data.fastWalkInNo || EventTools.isWalkIn(params.data, this.myService.dateString)) {
            return 'walk-in';
          }
          return '';
        },
      }, {
        headerName: 'V·S·T·V',
        colId: 'photos',
        nxtFieldType: NxtFieldType.Text,
        headerTooltip: 'Vorlage · Stencil · Tattoo · Video',
        minWidth: 85,
        maxWidth: 85,
        valueGetter: (params) => {
          if (params.data.workType === 'piercing' || params.data.workType === 'tooth-gem') {
            return '';
          }
          let text = '';
          if (params.data.files) {
            const templateCount = params.data.files.filter(f => f.subType.startsWith('template')).length;
            const stencilCount = params.data.files.filter(f => f.subType.startsWith('stencil')).length;
            const tattooPhotoCount = params.data.files.filter(f => f.subType === 'tattooPhoto').length;
            const tattooVideoCount = params.data.files.filter(f => f.subType === 'tattooVideo').length;
            text += '<div class="flex flex-row items-center justify-center">' + templateCount + '<div style="padding:2px;">·</div>' +
              stencilCount + '<div style="padding:2px;">·</div>' +
              tattooPhotoCount + '<div style="padding:2px;">·</div>' +
              tattooVideoCount + '</div>';
          } else {
            if (params.data.photoFolderId || params.data.closed) {
              text += '<div class="flex flex-row items-center justify-center">' + (params.data.mediaCount.templatePhoto || 0) + '<div style="padding:2px;">·</div>' + (params.data.mediaCount.stencil || 0) + '<div style="padding:2px;">·</div>' + (params.data.mediaCount.tattooPhoto || 0) + '<div style="padding:2px;">·</div>' + (params.data.mediaCount.tattooVideo || 0) + '</div>';
            }
          }

          return text;
        },
        tooltipValueGetter: (params) => {
          if (params.data.workType === 'piercing') {
            return '';
          }
          if (params.data.files) {
            const templateCount = params.data.files.filter(f => f.subType.startsWith('template')).length;
            const stencilCount = params.data.files.filter(f => f.subType.startsWith('stencil')).length;
            const tattooPhotoCount = params.data.files.filter(f => f.subType === 'tattooPhoto').length;
            const tattooVideoCount = params.data.files.filter(f => f.subType === 'tattooVideo').length;
            return 'Vorlage: ' + templateCount + '\nStencil: ' + stencilCount + '\nTattoo-Fotos: ' + tattooPhotoCount + '\nTattoo-Videos: ' + tattooVideoCount;
          }


          if (params.data.start < '2024-08-15'.dateParse()) {
            if (params.data.photoFolderId && params.data.mediaCount) {
              const textLines: string[] = [];
              const items: string[] = [];
              if (params.data.mediaCount.photo === 1) {
                items.push('1 Foto');
              } else if (params.data.mediaCount.photo > 1) {
                items.push(params.data.mediaCount.photo + ' Fotos');
              }
              if (params.data.mediaCount.video === 1) {
                items.push('1 Video');
              } else if (params.data.mediaCount.video > 1) {
                items.push(params.data.mediaCount.video + ' Videos');
              }
              if (textLines.length > 0) {
                return textLines + '\n' + items.join(' & ');
              }
              return items.join(' & ');
            }
          } else {
            if (params.data.mediaCount) {
              const lines: string[] = [];
              if (params.data.mediaCount.templatePhoto === 0) {
                lines.push('❗Vorlage fehlt❗');
              } else {
                lines.push('Vorlage:  ' + (params.data.mediaCount.templatePhoto || 0));
              }
              if (params.data.mediaCount.stencil === 0) {
                lines.push('❗Stencil-Foto fehlt❗');
              } else {
                lines.push('Stencil: ' + (params.data.mediaCount.stencil || 0));
              }
              lines.push('Tattoo-Fotos: ' + (params.data.mediaCount.tattooPhoto || 0));
              lines.push('Tattoo-Videos: ' + (params.data.mediaCount.tattooVideo || 0));
              return lines.join('\n');
            } else {
              return 'Vorlage fehlt';
            }
          }
          return '';
        },
        cellStyle: (params) => {
          if (params.data.workType === 'piercing' || !params.data.closed) {
            return;
          }
          const style: any = {textAlign: 'center'};

          if (params.data.files) {
            const templateCount = params.data.files.filter(f => f.subType.startsWith('template')).length;
            const stencilCount = params.data.files.filter(f => f.subType.startsWith('stencil')).length;
            const tattooPhotoCount = params.data.files.filter(f => f.subType === 'tattooPhoto').length;
            const tattooVideoCount = params.data.files.filter(f => f.subType === 'tattooVideo').length;
            if (tattooPhotoCount === 0) {
              style.color = ColorTools.Red;
            }
            if (stencilCount === 0) {
              style.color = ColorTools.Orange;
            }
          }
          return style;
        },
        nxtOnCellClicked: (params) => {
          if (params.data.photoFolderId && params.data.mediaCount.photo + params.data.mediaCount.video > 0) {
            window.open('https://drive.google.com/drive/folders/' + params.data.photoFolderId, '_blank');
          }
        },
        getQuickFilterText: (params) => {
          let totalCount = 0;
          if (params.data.workType === 'piercing' || !params.data.mediaCount) {
            return '';
          }
          if (params.data.mediaCount.photo) {
            totalCount += params.data.mediaCount.photo;
          }
          if (params.data.mediaCount.video) {
            totalCount += params.data.mediaCount.video;
          }
          if (totalCount === 0) {
            return 'fehlende fotos';
          }
          return '';
        },
      },
      {
        headerName: 'E',
        // hide: !this.loginService.isBackoffice(),
        headerTooltip: 'Einwilligung',
        colId: 'e',
        nxtFieldType: NxtFieldType.Text,
        minWidth: 45,
        maxWidth: 45,
        valueGetter: (params) => {
          if (!params.data.closed) {
            return '';
          }
          return EventConsentTools.getConsentState(params.data);
        },
        valueFormatter: (params) => {
          if (!params.data.closed) {
            return '';
          }
          switch (EventConsentTools.getConsentState(params.data)) {
            case 'missing':
              return '🟠';
            case 'invalid':
              return '🔴';
            case 'valid':
              return IconTools.Material.CheckNoPointer;
          }
        },
        tooltipValueGetter: (params) => {
          if (!params.data.closed) {
            return '';
          }
          switch (EventConsentTools.getConsentState(params.data)) {
            case 'missing':
              return 'Einwilligung fehlt';
            case 'invalid':
              return 'Einwilligung hat fehler';
            case 'valid':
              return 'Einwilligung korrekt';
          }
        },
        getQuickFilterText: () => {
          return '';
        },
      },
      {
        headerName: 'S',
        headerTooltip: 'Stencil gesehen',
        colId: 'stencilSeen',
        nxtFieldType: NxtFieldType.Text,
        minWidth: 45,
        maxWidth: 45,
        tooltipValueGetter: (params) => {
          if (params.data.workType === 'piercing' || params.data.workType === 'tooth-gem') {
            return '';
          }
          if (params?.data?.stencilSeen?.seen) {
            return params?.data?.stencilSeen?.seenBy + ' ' + DateTools.dateDiffToNowText(params?.data?.stencilSeen?.seenAt);
          } else {
            return 'noch nicht gesehen!';
          }
        },
        valueGetter: (params) => {
          if (params.data.workType === 'piercing' || params.data.workType === 'tooth-gem') {
            return '';
          }
          if (params?.data?.stencilSeen?.seen) {
            return IconTools.Material.Check; // '✅';
          } else {
            return '🔴';
          }
        },
        getQuickFilterText: (params) => {
          if (params.data.stencilSeen.seen) {
            return '';
          }
          return 'Stencil fehlt';
        },
        nxtOnCellClicked: async (params) => {
          if (params.data.workType === 'piercing' || params.data.workType === 'tooth-gem') {
            return '';
          }
          const newState = await this.stencilService.showStencilSeenDialog(params.data.id);
          if (newState) {
            params.data.stencilSeen = newState;
          }
          params.node.setDataValue('stencilSeen', params.data.stencilSeen);
        },
      }, {
        headerName: 'B',
        field: 'ratings',
        headerTooltip: 'Bewertung',
        hide: !this.configService.config.value.mustEventRating,
        colId: 'rating',
        nxtFieldType: NxtFieldType.Text,
        nxtCellStyle: {textAlign: 'center'},
        minWidth: 70,
        maxWidth: 70,
        tooltipValueGetter: (params) => {
          if (params.data.workType === 'piercing' || params.data.workType === 'tooth-gem') {
            return '';
          }
          if (TypeTools.isNumber(params.data.ratingValue) && params.data.ratings && params.data.ratings.length > 0) {
            let text = params.data.ratingValue.round(1) + '\n';
            text += params.data.ratings.map(r => r.username + ': ' + r.value).join('\n');
            return text;
          } else {
            if (params.data.closed) {
              return 'Bewertung fehlt noch';
            }
          }
          return '';
        },
        cellRenderer: (params) => {
          if (params.data.workType === 'piercing' || params.data.workType === 'tooth-gem') {
            return '';
          }
          if (params.data.ratings && params.data.ratings.length > 0) {
            return '<div class="flex flex-row items-center justify-center"><div style="position: absolute; right: 4px; top: 2px" class="leading-none text-[70%]"> ' + params.data.ratings.length + '</div><div>' + MathTools.round(params.data.ratingValue, 1) + '</div></div>';
          } else {
            if (params.data.closed) {
              return '🟠';
            }
          }
        },
        nxtOnCellClicked: async (params) => {
          if (this.permissionService.hasPermission(NxtPermissionId.EventRating_Edit)) {
            this.dialogService.showEventRating(params.data.id);
          }
        },
      }, {
        headerName: 'sortColumn',
        field: 'start',
        valueGetter: (param) => param.data.start + param.data.artist,
        // sort: 'asc',
        hide: true,
      },
      {
        headerName: 'Artist', field: 'artist', nxtFieldType: NxtFieldType.Text, cellRenderer: (params: any) => {
          if (params.data.artistFix) {
            return params.data.artist + ' (fix)';
          } else {
            return params.data.artist;
          }
        },
      },
      {
        headerName: 'Kunde', nxtFieldType: NxtFieldType.Text,
        valueFormatter: (params: any) => {
          if (params.data.customerObj) {
            if (ContactTools.isFirstAppointment(params.data.customerObj, this.myService.dateString.dateParse())) {
              return '<strong>*' + params.value + '</strong>';
            }
          }
          return params.value;
        },
        tooltipValueGetter: (params) => {
          if (params.data.customerObj) {
            if (ContactTools.isFirstAppointment(params.data.customerObj, this.myService.dateString.dateParse())) {
              return 'NEUKUNDE!\n' + params.value;
            }
          }
        },
        valueGetter: (params) => {
          const customer = params?.data?.customerObj;
          if (customer) {
            let text: string;
            if (customer?.birthday) {
              const birthday = DateTools.parse(customer.birthday);
              const birthdayInfo = BirthdayTools.getBirthdayInfo(birthday);
              text = customer.givenName + ' ' + customer.familyName + ' (' + birthdayInfo.age + ')';
              if (birthdayInfo.daysToBirthday > -3 && birthdayInfo.daysToBirthday <= 3) {
                text += ' ' + birthdayInfo.text;
              }
            } else {
              text = customer.givenName + ' ' + customer.familyName;
            }
            if (params.data.importantInfo) {
              text = '❗' + text;
            } else {
              /*if (params.data.durationPriceInfo) {
                text = '⏰ ' + text;
              }*/
            }
            return text;
          }
          // }
          return 'UNBEKANNT?';
        },
      },
      {
        hide: true,
        headerName: 'Start',
        field: 'start',
        nxtFieldType: NxtFieldType.Date_germanTime,
        valueFormatter: (params) => {
          return DateTools.format(params.data.start, 'HH:mm') + ' - ' + DateTools.format(params.data.end, 'HH:mm');
        },
        maxWidth: 65,
        cellStyle: (params) => {
          return {textAlign: 'center', color: params.data.visibility === 'private' ? ColorTools.GridTextLighter : ''};
        },
      },
      {
        colId: 'date',
        headerName: 'Datum',
        field: 'start',
        nxtFieldType: NxtFieldType.Date_germanDateShort,
        minWidth: 70,
        maxWidth: 70,
        cellStyle: (params) => {
          return {textAlign: 'center', color: params.data.visibility === 'private' ? ColorTools.GridTextLighter : ''};
        },
        hide: !this.myService.showDateInGrid.value,
      },
      {
        headerName: 'Termin',
        nxtFieldType: NxtFieldType.Text,
        valueGetter: (params) => {
          const start = DateTools.format(params.data.start, 'HH:mm');
          const end = DateTools.format(params.data.end, 'HH:mm');
          return start + ' - ' + end
            + ' (' + DurationTools.format(params.data.end - params.data.start, 'H:mm') + ')';
        },
        cellRenderer: (params: any) => {
          let percent = 0;
          if (Date.now() > params.data.start) {
            const totalDuration = params.data.end - params.data.start;
            const doneDuration = Date.now() - params.data.start;
            percent = doneDuration / totalDuration * 100;
          }
          if (percent > 100) {
            percent = 100;
          }
          let color = ColorTools.Green;
          if (!params.data.closed && params.data.end < Date.now()) {
            color = ColorTools.Orange2;
          }
          let text = '<div style="position:absolute; z-index: 1; padding-left: 11px">';
          text += params.value;
          text += '</div><div style="width:100%; position:absolute"><div style="width:' + percent + '%; background-color: ' + color + '">&nbsp;</div></div>';
          return text;
        },
        cellStyle: () => ({paddingLeft: 0, paddingRight: 0, border: 'none'}),
        minWidth: 145,
        maxWidth: 145,
      },
      {
        headerName: 'Status', field: 'closed',
        nxtFieldType: NxtFieldType.Text,
        cellRenderer: (params: any) => {
          if (params.data.closed) {
            return 'Fertig';
          } else {
            if (params.data.start < Date.now()) {
              if (params.data.end < Date.now()) {
                return 'Überfällig';
              }
              return 'Läuft';
            }
          }
          return 'Offen';
        },
        cellStyle: (params) => {
          if (this.calendarEventHastPayMore(params.data)) {
            return {backgroundColor: ColorTools.Orange2};
          } else if (params.data.closed) {
            return {backgroundColor: ColorTools.Green};
          }
        },
        minWidth: 65, maxWidth: 65,
        nxtOnCellClicked: async (params) => {
          const result = await this.eventService.closeEventDialog(params.data);
          if (result) {
            params.data.closed = !params.data.closed;
            params.node.setDataValue('closed', params.data.closed);
          }
        },
      },
      {
        headerName: 'W',
        headerTooltip: 'Wertigkeit',
        field: 'valence',
        nxtFieldType: NxtFieldType.Decimal,
        hide: !this.loginService.isBackoffice(),
        maxWidth: 55,
        minWidth: 55,
      },
      {
        headerName: 'aktion', hide: true, getQuickFilterText: (params) => {
          if (params.data.discountPromotion && params.data.discountPromotion.id !== 'none') {
            if (params.data.discountPromotion?.additionalPayAfterEnd) {
              return 'Aktionen nachkassieren';
            }
            return 'Aktionen';
          }
          return '';
        },
      },
      {
        headerName: 'Preis',
        field: 'priceEstimatedFrom',
        tooltipValueGetter: (params) => {
          const parts: string[] = [];
          if (params.data.discountPromotion && params.data.discountPromotion.id !== 'none') {
            parts.push(params.data.discountPromotion.name);
          }
          if (params.data.priceEstimatedFrom < params.data.priceEstimatedTill) {
            parts.push(params.data.priceEstimatedFrom.toMoneyString('', false) + ' bis ' + params.data.priceEstimatedTill.toMoneyString('€', false));
          } else {
            if (typeof params.data.priceEstimatedFrom !== 'number') {
              parts.push('❗Preis fehlt noch❗');
            } else {
              if (params.data.priceFix) {
                parts.push('Fix ' + params.data.priceEstimatedFrom.toMoneyString('€', false));
              } else {
                parts.push('ca. ' + params.data.priceEstimatedFrom.toMoneyString('€', false));
              }
            }
          }
          return parts.join('\n');
        },
        valueFormatter: (params) => {
          if (typeof params.data.priceEstimatedFrom !== 'number') {
            return 'FEHLT NOCH';
          }
          const firstWithSuffix = !params.data.priceEstimatedTill;
          let text = DecimalTools.toMoneyString(params.value, firstWithSuffix ? '€' : '', false);
          const icons: string[] = [];

          if (params.data.discountPromotion && params.data.discountPromotion.id !== 'none') {
            icons.push('⭐');
          } else if (params.data.priceFix) {
            icons.push('fix');
          } else {
            if (!params.data.priceEstimatedTill) {
              icons.push('ca.');
            }
          }
          if (icons.length > 0) {
            text = icons.join('') + ' ' + text;
          }
          if (params.data.priceEstimatedFrom < params.data.priceEstimatedTill) {
            text += ' - ' + DecimalTools.toMoneyString(params.data.priceEstimatedTill, '€', false);
          }
          return text;
        },
        nxtOnCellClicked: (params) => {
          if (params.data.priceChanges.length > 0) {
            const priceChanges = params.data.priceChanges as NxtCalendarEventPriceChange[];

            const lines = priceChanges.map(p => {
              return p.u + ' ' + p.t.dateFormat('dd.MM.yyyy HH:mm') + '\n' + p.from.toMoneyString() + ' ' + StringTools.arrowRight + ' ' + p.to.toMoneyString() + '\n' + p.i;
            });

            if (lines.length > 0) {
              this.dialogService.showOk(lines.join('\n\n'), {title: 'Preisänderungen'}).then();
            }
          }
        },
        // cellStyle: {textAlign: 'right'},
        minWidth: 120,
        maxWidth: 120,
        cellStyle: (params) => {
          const style: any = {textAlign: 'right', color: params.data.visibility === 'private' ? ColorTools.GridTextLighter : ''};
          if (typeof params.data.priceEstimatedFrom !== 'number') {
            style.color = ColorTools.Red;
          }
          return style;
        },
      }, {
        headerName: '',
        valueGetter: (params) => {
          if (params.data.promoOfferCreatedAt) {
            return params.data.createdAt - params.data.promoOfferCreatedAt;
          }
        },
        cellRenderer: params => {
          const minutes = params.value / DurationTools.DURATION_1MINUTE;
          if (minutes < 180) {
            return DurationTools.format(params.value, 'HH:mm');
          }
        }, maxWidth: 70, minWidth: 70, hide: this.configService.config.value.studioRegion !== 'MA',
      },
      {
        headerName: 'cashEndOfDay',
        field: 'cashEndOfDay',
        nxtFieldType: NxtFieldType.Money,
        cellStyle: {textAlign: 'right'},
        minWidth: 90,
        maxWidth: 90,
        hide: true,
      },
      {
        headerName: 'Dauer-Preis', nxtFieldType: NxtFieldType.Text,
        valueGetter: (params) => {
          if (params.data.workType === 'tattoo') {
            const timeCheckResult = EventCalcTools.timeCheck(params.data.end - params.data.start, params.data.priceEstimatedFrom);
            return timeCheckResult.calculatedPriceDiff;
          }
        },
        valueFormatter: (params) => {
          if (params.value) {
            if (params.value > 0) {
              return '+' + DecimalTools.toMoneyString(params.value, '€', false);
            }
            return DecimalTools.toMoneyString(params.value, '€', false);
          }
        },
        minWidth: 85, maxWidth: 85,
        cellStyle: (params) => {
          if (Math.abs(params.value) > 50) {
            if (Math.abs(params.value) >= 100) {
              return {textAlign: 'right', color: ColorTools.Red};
            } else {
              return {textAlign: 'right', color: ColorTools.Orange};
            }
          }
          return {textAlign: 'right'};
        }, hide: !this.permissionService.hasPermission(NxtPermissionId.ShowDurationPriceCheckColumn),
      },
      {
        colId: 'payMore', headerName: 'Nachbezahlen', valueGetter: (params) => {
          if (this.calendarEventHastPayMore(params.data)) {
            const moreTimeDuration = DateTools.dateDiffToNow(params.data.end);
            const moreTimeString = DurationTools.format(moreTimeDuration, 'HH:mm');
            this.checkCloseEventReminder(params.data, moreTimeDuration);
            const moreMoney = PaymentTools.calcAdditionalPaymentValue(DateTools.dateDiffToNow(params.data.end));
            return moreTimeString + ' = ' + DecimalTools.toMoneyString(moreMoney, '€', false);
          }
        },
        minWidth: 0,
        maxWidth: 120,
        nxtFieldType: NxtFieldType.Text,
        suppressAutoSize: true,
        hide: true,
      },
      {
        headerName: 'bezahlt',
        field: 'paymentSum',
        nxtFieldType: NxtFieldType.Money,
        aggFunc: 'sum',
        cellStyle: {textAlign: 'right'},
        minWidth: 75,
        maxWidth: 75,
      },
      {
        headerName: 'offen',
        field: 'toPay',
        valueGetter: (params) => {
          if (params.data.priceEstimatedTill > params.data.priceEstimatedFrom) {
            let firstToPay = (params.data.priceEstimatedFrom - params.data.paymentSum);
            let secondToPay = (params.data.priceEstimatedTill - params.data.paymentSum);
            if (firstToPay < 0) {
              firstToPay = 0;
            }
            if (secondToPay < 0) {
              secondToPay = 0;
            }
            let color = ColorTools.Red;
            if (firstToPay === 0) {
              color = ColorTools.Orange;
            }
            if (secondToPay === 0) {
              color = ColorTools.Green;
            }
            return {text: firstToPay.toMoneyString('', false) + ' - ' + secondToPay.toMoneyString('€', false), color};
          }
          if (typeof params.data.toPay !== 'number') {
            debugger;
          }
          return {
            text: params.data.toPay.toMoneyString('€', false),
            color: params.data.toPay > 0 ? ColorTools.Red : ColorTools.Green,
          };
        },
        cellRenderer: (params: any) => params.value.text,
        nxtFieldType: NxtFieldType.Text,
        aggFunc: 'sum',
        minWidth: 100,
        maxWidth: 100,
        cellStyle: (params) => ({textAlign: 'right', color: params.value.color, fontWeight: 'bold'}),
      },
      // ColDefTools.calendarEvents.morePayed,
      // {headerName: 'Artist hat bek.', field: 'artistPaymentSum', nxtFieldType: NxtFieldType.Money, aggFunc: 'sum'},
      {
        headerName: 'Art. noch', field: 'artistToGet', nxtFieldType: NxtFieldType.MoneyOnlyPositivShort, aggFunc: 'sum',
        cellStyle: {textAlign: 'right'},
        hide: !this.loginService.isBackoffice(), minWidth: 80, maxWidth: 80,
      }, {
        headerName: 'Art. hat', nxtFieldType: NxtFieldType.MoneyOnlyPositivShort, aggFunc: 'sum',
        valueGetter: (params) => {
          return PaymentTools.getPaymentSumByPaymentType(params.data.payments, 'payout');
        },
        cellStyle: {textAlign: 'right'},
        hide: !this.loginService.isBackoffice(), minWidth: 80, maxWidth: 80,
      },
      {
        headerName: 'Kasse+-',
        field: 'cashEndOfDay',
        nxtFieldType: NxtFieldType.Money,
        aggFunc: 'sum',
        minWidth: 85,
        maxWidth: 85,
        hide: !this.loginService.isBackoffice(),
        cellStyle: {textAlign: 'right'},
      },
      {headerName: 'Info', field: 'title', nxtFieldType: NxtFieldType.Text, hide: true},
      {
        headerName: 'Art. %',
        field: 'artistPercentage',
        nxtFieldType: NxtFieldType.Number,
        valueGetter: (params) => Math.round(params.data.artistPercentage),
        cellRenderer: (params: any) => params.value + ' %',
        cellStyle: (params) => {
          const style: any = {textAlign: 'right'};
          if (params.data.paymentSum >= 350 && !params.data.artistPercentageConfirmed) {
            style.color = ColorTools.Red;
            style.fontWeight = 'bold';
          }
          return style;
        },
      },
      {
        headerName: 'Wichtig',
        field: 'importantInfo',
        nxtFieldType: NxtFieldType.Text,
        cellStyle: {color: ColorTools.Red, fontWeight: 'bold'},
      },
      {
        headerName: 'Dauer-Preis Info',
        field: 'durationPriceInfo',
        hide: true,
        nxtFieldType: NxtFieldType.Text,
        cellStyle: {color: ColorTools.Red, fontWeight: 'bold'},
      },
      {
        headerName: 'Erstellt', field: 'createdBy', nxtFieldType: NxtFieldType.Text, valueGetter: (params) => {
          return params.data.createdBy + ' (' + DateTools.dateDiffToNowText(params.data.createdAt) + ')';
        },
      },
      {
        headerName: 'Geändert', field: 'createdBy', nxtFieldType: NxtFieldType.Text, hide: true,
        valueGetter: (params) => {
          return params.data.updatedBy + ' (' + DateTools.dateDiffToNowText(params.data.updatedAt) + ')';
        },
      },
    ];
    this.detectChanges();
  }

  async setInvoiceNumber(event: NxtCalendarEvent, invoiceNumber: string) {
    await this.socketService.updateCalendarEventProperty(event.id, {invoiceNumber});
  }

  private async setEventTo_FromPayment(payment: any) {
    const result = await this.socketService.setEventTo_FromPayment2(payment);
    if (!result.success) {
      this.dialogService.showOk(result.text).then();
    }
    return result;
  }

  private async setEventBackFrom_FromPayment(payment: any) {
    const result = await this.socketService.setEventBackFrom_FromPayment2(payment);
    if (!result.success) {
      this.dialogService.showOk(result.text).then();
    }
    return result;
  }

  private showCalc() {
    this.dialogService.showComponentDialog(CalcComponent, {});
  }

  showLatestChats() {
    this.dialogService.showComponentFull(WhatsAppChatsComponent);
  }

  private setStudioCash(studioCash: string) {
    this.myService.studioView = studioCash;
    const studio = this.configService.config.value.studios.find(s => s.name === this.myService.studioView);
    if (studio?.realStudio) {
      this.studioReal = studio?.realStudio;
    } else {
      this.studioReal = this.myService.studioView;
    }
    this.allCashRegisters = this.configService.config.value.studios.filter(s => s.name.includes(this.studioReal)).map(s => s.name);
  }

  showRoman() {
    this.dialogService.showComponentFull(RomanNumeralsComponent);
  }

  showSearch() {
    this.dialogService.showComponentFull(SearchComponent);
  }

  public eventRowClicked(ev: any) {
    this.eventRowClickedTimeout = setTimeout(() => {
      this.dialogService.showEvent(ev.data.id);
    }, 100);
  }

  public componentClicked() {
    this.artistGetMenuTrigger?.closeMenu();
  }

  public stopPropagation(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  private async loadData() {
    this.myService.reloadData().then();
  }

  private registerEventListeners() {
  }

  dateChanged() {
    this.loadData().then();
  }

  studioChanged() {
    this.myService.refreshView();
  }

  artistClicked(artistName: string) {
    this.myService.setArtistFilter(artistName);
  }

  datePickerChanged() {
    this.myService.debouncedReload.next();
  }

  detectChanges() {
    this.cdr.detectChanges();
  }

  showArtistMoneyClicked() {
    this.showArtistGetValues = true;
    this.detectChanges();
    setTimeout(() => {
      this.showArtistGetValues = false;
      this.detectChanges();
    }, 5000);
  }


  startWorkSessionClicked() {
    this.myService.startWorkSession();
  }

  startCashRegisterClicked() {
    this.myService.startCashRegister().then();
  }

  showAllCashRegistersChanged() {
    this.myService.refreshView();
  }

  async showPreDayFinish() {
    const dialog = this.dialogService.showComponentDialog(ScrPreStartDayFinishComponent);
    dialog.componentInstance.myService = this.myService;
    return await firstValueFrom(dialog.afterClosed());
  }

  async toggleDayFinishClicked() {
    if (this.myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._1_Open) {
      if (this.permissionService.hasPermission(NxtPermissionId.StudioCashReport_ToggleBlockDayFinish)) {
        let doUnlock = true;
        if (this.myService.cashRegisterView.isMainCashRegister) {
          const preDayFinishResult = await this.showPreDayFinish();
          if (!preDayFinishResult) {
            return;
          }
          doUnlock = preDayFinishResult.doUnblock;
          this.myService.log(preDayFinishResult.postponeEventIds.length + ' Termin(e) auf morgen verschoben');
          await this.socketService.workSessionSetPostponeEvents(this.myService.dateString, preDayFinishResult.postponeEventIds);
        }
        if (doUnlock) {
          this.myService.log('Abrechnung freigegeben ' + this.myService.cashRegisterView.studio);
          this.myService.setViewedCashRegisterState(NxtWorkSessionCashRegisterState._3_CalcCashRegister1);
        }
      }
    } else if (this.myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._3_CalcCashRegister1) {
      if (this.permissionService.hasPermission(NxtPermissionId.StudioCashReport_ToggleBlockDayFinish)) {
        this.myService.log('Abrechnung blockiert');
        this.myService.setViewedCashRegisterState(NxtWorkSessionCashRegisterState._1_Open);
      }
    }
  }

  async dayFinishClicked() {
    if (this.loginService.isJulian()) {
      if (await this.dialogService.showYesNo('Voransicht anschauen?')) {
        const result = await this.showPreDayFinish();
        if (result) {
          await this.socketService.workSessionSetPostponeEvents(this.myService.dateString, result.postponeEventIds);
        }
      }
    }

    if (this.myService.studioView !== this.myService.myLoginCashRegister.studio) {
      this.dialogService.showOk('Du bist nicht an dieser Kasse angemeldet!').then();
      return;
    }
    if (this.myService.loginIsMainCashRegister || true) {
      const dialog = this.dialogService.showComponentFull(DayFinishView2Component);
      dialog.componentInstance.myService = this.myService;
    }
  }

  switchFilterCashState() {
    if (this.myService.viewFilter.cashState === 'all') {
      this.myService.viewFilter.cashState = 't';
    } else if (this.myService.viewFilter.cashState === 't') {
      this.myService.viewFilter.cashState = 'n';
    } else if (this.myService.viewFilter.cashState === 'n') {
      this.myService.viewFilter.cashState = 'all';
    }
    this.myService.refreshView();
  }

  workSessionClicked() {
    const dialog = this.dialogService.showComponentDialog(WorkSessionEditComponent);
    dialog.componentInstance.myService = this.myService;
  }


  async artistContextClicked(data: { artist: CalcArtistData; menuItem: ScrArtistContextMenuAction, data?: any }) {
    if (data.menuItem === ScrArtistContextMenuAction.SetTimeWindow) {
      const availableArtist = await this.socketService.getArtistSpotByArtistAndDate(data.artist.name, this.myService.dateString, true);
      const dialog = this.dialogService.showComponentDialog(ArtistSpotEditComponent);
      dialog.componentInstance.load(availableArtist.id);
    }

    if (data.menuItem === ScrArtistContextMenuAction.EarlyPayout) {
      const result = await this.dialogService.showYesNo('Möchtest du&nbsp;<strong>' + data.artist.name + '</strong>&nbsp;frühzeitig Auszahlen?');
      if (result) {
        this.dialogService.showLoading(LoadingId.AutoArtistPayout, 'Artist-Auszahlungen werden gebucht...');
        await this.socketService.earlyArtistPayout2(this.myService.dateString, data.artist.name);
        this.dialogService.hideLoading(LoadingId.AutoArtistPayout);
        const artistConfirm = await this.socketService.getArtistConfirmByArtistAndWorkSessionDateTime(data.artist.name, this.myService.dateString);
        if (!artistConfirm || artistConfirm.state !== 'confirmed') {
          this.artistConfirmService.start(data.artist.name, data.artist.payoutValue, this.myService.dateString, true);
        }
      }
    }
    if (data.menuItem === ScrArtistContextMenuAction.Invoice) {
      const invoice = this.myService.data.workSession.invoices?.find(i => i.invoiceTo.artistName === data.artist.name);
      if (!invoice) {
        this.dialogService.showOk('Keine Rechnung für ' + data.artist.name + ' gefunden');
        return;
      }
      this.dialogService.showLoading(LoadingId.AutoArtistPayout, 'Lade Rechnung für ' + data.artist.name);
      const invoiceData = await this.socketService.getArtistInvoice(invoice.invoiceNumber);
      this.dialogService.hideLoading(LoadingId.AutoArtistPayout);
      const dialog = this.dialogService.showPdf(invoiceData.invoiceSignedBase64 || invoiceData.invoiceBase64, invoiceData.invoiceNumber + '.pdf');
      await firstValueFrom(dialog.afterClosed());
      // Rechnung wurde geschlossen --> ArtistConfirm
    }
    if (data.menuItem === ScrArtistContextMenuAction.ResetEarlyArtistPayout) {
      this.socketService.resetEarlyArtistPayout(this.myService.dateString, data.artist.name);
    }

    if (data.menuItem === ScrArtistContextMenuAction.SetCanEarlyPayout) {
      this.socketService.setArtistCanEarlyPayout(this.myService.dateString, data.artist.name, data.data);
    }
  }


  async showPostponedEventsClicked() {
    this.myService.showPostponedEvents = !this.myService.showPostponedEvents;
    this.myService.refreshView();
  }

  triggerNewOldVersionButton() {
    this.localStorageService.set('ShowOldNewVersionButton', !this.localStorageService.get('ShowOldNewVersionButton', false));
    this.showOldVersionButton = this.localStorageService.get('ShowOldNewVersionButton', false);
    this.detectChanges();
  }

  registerDetectChangesEvents() {
    this.myService.data$.subscribe(() => {
      if (this.initDone) {
        this.detectChanges();
      }
    });
  }

  async newIncomingOutgoingClicked(type: 'incoming' | 'outgoing' | 'incoming-transfer' | 'outgoing-transfer') {
    if (await this.myService.isWorkSessionOpenMyLoginCashRegister(true)) {
      const possibleValue = this.myService.cashRegisterView.startMoney + this.myService.cashRegisterViewCalc.incomingPaymentsSum - this.myService.cashRegisterViewCalc.outgoingPaymentsSum;
      const possibleSafeValue = this.myService.cashRegisterViewCalc.incomingPaymentsSum_ - this.myService.cashRegisterViewCalc.outgoingPaymentsSum_;
      this.newIncomingOutgoingService.newIncomingOutgoingClicked(type, this.myService.studioView, possibleValue, possibleSafeValue).then();
    }
  }

  newPaypalIncomingClicked() {
    this.saleService.newPaypalSale().then();
  }

  sideBarDataChanged() {
    this.detectChanges();
  }

  async setCashRegisterStateClicked(ev: MouseEvent) {
    const studio = HtmlTools.getTextRecursiveParent(ev.target as HTMLElement).split('\n')[0];
    const cashRegister = this.myService.data.workSession.cashRegisters.find(c => c.studio === studio);
    if (cashRegister) {
      const items: { text: string, value: string }[] = [];
      for (const key of keys(NxtWorkSessionCashRegisterState)) {
        items.push({text: WorkSessionTools.getCashRegisterStateText(NxtWorkSessionCashRegisterState[key]), value: NxtWorkSessionCashRegisterState[key]});
      }
      const result = await this.dialogService.showSelect('Status setzen', items, {value: cashRegister.state});
      if (result && result !== cashRegister.state) {
        cashRegister.state = result;
        this.myService.updateWorkSessionCashRegister(cashRegister);
      }
    }
  }

  async acceptTransferClicked() {
    const transfer = this.myService.cashRegisterView.openCashRegisterTransfersToReceived[0];
    if (await this.dialogService.showYesNo('Transfer über ' + (transfer.transferValue + transfer.transferSafeValue).toMoneyString() + ' annehmen?')) {
      this.dialogService.showLoading('Transfer wird angenommen...');
      this.socketService.acceptCashRegisterTransfer(this.myService.dateString, transfer, this.myService.cashRegisterView.studio).then();
      this.dialogService.hideLoading();
    }
  }

  async startTest() {
    if (this.testIsRunning) {
      this.testIsRunning = false;
      clearInterval(this.testInterval);
    } else {
      this.testIsRunning = true;
      this.testInterval = window.setInterval(async () => {
        const event = MathTools.getRandomFromArray(this.myService.calendarEventsView);
        const dialog = this.dialogService.showEvent(event.id);
        await TimeTools.sleep(1000);
        dialog.close();
      }, 2000);
    }
  }

  setWalkIn() {
    this.socketService.setWalkInToday(!this.configService.config.value.isWalkInToday).then();
  }

  private getQuickFilterTextForEvent(event: NxtCalendarEvent) {
    const lines: string[] = [];
    lines.push(...this.bodyPutService.getTextFromEvent(event));

    if (event.payments.some(p => p.paymentMethod === 'gift-card')) {
      lines.push('payment-some-giftcard');
      if (event.payments.some(p => p.paymentMethod === 'gift-card' && p.paymentGiftCard.discountPercentage > 0)) {
        lines.push('payment-some-giftcard');
      }
    }

    if (event.payments.some(p => p.paymentMethod === 'gift-card' && p.paymentGiftCard.discountPercentage > 0)) {
      lines.push('payment-some-giftcard-discounted');
      if (event.payments.some(p => p.paymentMethod === 'gift-card' && p.paymentGiftCard.discountPercentage === 50)) {
        lines.push('payment-some-giftcard-discounted-50');
      }
    }

    const paymentMethods = event.payments.map(p => 'payment-' + p.paymentMethod);
    lines.push(...paymentMethods);

    return lines.join(' ');
  }

  createArtistConfirm() {

    // this.myService.createArtistConfirm('Test-Artist', 100);
    // this.myService.createArtistConfirm('Test-Artist', 100);
  }

  startEventRatingClicked() {
    const dialog = this.dialogService.showComponentFull(EventsRatingComponent);
    dialog.componentInstance.dateString.set(this.myService.dateString);
  }

  showPhotosClicked() {
    const dialog = this.dialogService.showComponentFull(PhotosComponent);
  }
}
