<div class="nxt-page">
  <div>
    <nxt-input [(value)]="quickFilterText"></nxt-input>
  </div>
  <nxt-datagrid
    class="h-100"
    [columnDefs]="columnDefs"
    [quickFilterText]="quickFilterText"
    [rowData]="whatsappInstaResponses"
  >


  </nxt-datagrid>


</div>
