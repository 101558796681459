import {Component, computed, OnInit, Optional, signal, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {InputComponent} from '../../../components/form-controls/input/input.component';
import {MathTools} from '../../../common-browser/helpers/math.tools';
import {TimeoutTools} from '../../../common-browser/helpers/timeout.tools';
import {CacheService} from '../../../services/cache/cache.service';
import {TypeTools} from '../../../common-browser/helpers/type.tools';
import {Log} from '../../../common-browser/log/log.tools';
import {DialogService, LoadingId} from '../../../services/dialog.service';
import {PermissionService} from '../../../services/permission.service';
import {SocketService} from '../../../services/socket/socket.service';
import {LoginService} from '../../../services/login.service';
import {RadioComponent} from '../../../components/form-controls/radio/radio.component';
import {NxtPromoOfferState} from '../../../common-interfaces/nxt.promo.interface';
import {ConfigService} from '../../../services/config.service';
import {NxtArtist} from '../../../common-interfaces/nxt.artist.interface';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {SlideToggleComponent} from '../../../components/form-controls/slide-toggle/slide-toggle.component';
import {SmoothWidthComponent} from '../../../components/smooth-width.component';
import {HideDirective} from '../../../directives/hide.directive';
import {SmoothHeightComponent} from '../../../components/smooth-height.component';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {NgFor, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {FromNowPipe} from '../../../pipes/from-now.pipe';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {MatIcon} from '@angular/material/icon';
import {NxtDiscountPromotion} from '../../../common-interfaces/discount-promotion.interface';
import {PermissionDirective} from '../../../directives/permission.directive';

export interface PriceResult {
  price: number;
  originalPrice: number;
  discountPercentage: number;
  discount: number;
  discountPromotionId: string;
}


@Component({
  selector: 'nxt-price-input',
  templateUrl: './price-input.component.html',
  styleUrls: ['./price-input.component.scss'],
  imports: [FlexModule, NgIf, InputComponent, NxtButtonComponent, NgFor, RadioComponent, SmoothHeightComponent, HideDirective, SmoothWidthComponent, SlideToggleComponent, NxtButtonIconComponent, NxtDatePipe, FromNowPipe, MoneyPipe, MatIcon, PermissionDirective],
})
export class PriceInputComponent implements OnInit {

  @ViewChild('discountPromotionRadio') discountPromotionRadio: RadioComponent;

  @ViewChild('customDiscountInput') customDiscountInput: InputComponent;
  @ViewChild('originalPriceInput') originalPriceInput: InputComponent;
  @ViewChild('priceInput') priceInput: InputComponent;

  priceFixWithoutDiscountPromotion = false;
  currentPriceEstimatedFrom: number;
  isNewEvent: boolean;
  eventCreatedAt: number;
  artist?: NxtArtist;
  // discountPromotions$: BehaviorSubject<NxtDiscountPromotion[]>;
  discountPromotions: NxtDiscountPromotion[];
  discountPromotion: NxtDiscountPromotion;
  priceEstimatedFrom: number;
  smoothHeightTrigger = 0;
  originalPrice = signal<number>(null);
  price = signal<number>(0);
  priceTill: number;
  discount = 10;
  artistPercentage;
  // takeArtistPercentage = true;
  artistGet = signal(0);
  showRoundPrice = false;
  // priceFix = false;
  private calcShowRoundPriceTimeout: any;
  priceRoundValue = 5;
  eventId = '';
  promoOfferId = '';
  inkBackCode = signal('');
  promoOfferPromoterName = '';
  promoOfferCreatedAt: number;
  discountPromotionsStaticCode: NxtDiscountPromotion[];
  private initArtistPercentage: any;

  constructor(
    @Optional() private dialogRef: MatDialogRef<any>,
    private cacheService: CacheService,
    private dialogService: DialogService,
    private permissionService: PermissionService,
    private socketService: SocketService,
    private loginService: LoginService,
    public configService: ConfigService,
  ) {
    /*{id: 'none', name: 'Kein Rabatt', discountPercentage: 0, disabled: false, },
    {id: '5cb43b08-cd5b-4cb4-8e20-608eba65806f', name: '13% Friday Special', discountPercentage: 13},
    {id: 'custom', name: 'Eigener Rabatt', discountPercentage: 0}*/

  }

  noneDiscount = {id: 'none', name: 'Kein Rabatt', discountPercentage: 0} as NxtDiscountPromotion;
  customDiscount = {id: 'custom', name: 'Eigener Rabatt', discountPercentage: 0} as NxtDiscountPromotion;

  // oberarm = {id: '4567456874567', name: '580er', discountPercentage: 0, priceFixValue: 580, priceFix: true} as NxtDiscountPromotion;
  showSlider = false;
  public promoCode = '';

  artistGetButtonsLess = computed(() => {
    const jump = this.price() > 400 ? 10 : 5;
    let buttonValue = this.artistGet() - (this.artistGet() % jump);
    const buttons: number[] = [];
    for (let i = 0; i < 5; i++) {
      buttonValue -= jump;
      if (buttonValue >= 0) {
        buttons.push(buttonValue);
      }
    }
    return buttons.sort(SortTools.arrayNumbers());
  });


  artistGetButtonsMore = computed(() => {
    const jump = this.price() > 400 ? 10 : 5;
    let buttonValue = this.artistGet() - (this.artistGet() % jump);
    const buttons: number[] = [];
    for (let i = 0; i < 5; i++) {
      buttonValue += jump;
      buttons.push(buttonValue);
    }
    return buttons.sort(SortTools.arrayNumbers());
  });


  ngOnInit(): void {
    setTimeout(() => {
      this.showSlider = true;
    }, 1);
    this.discountPromotions = [this.noneDiscount, ...this.discountPromotions, this.customDiscount].sortNumber('createdAt');
    this.discountPromotionsStaticCode = this.discountPromotions.filter(d => d.staticPromoCode);
    if (this.discountPromotion) {
      this.discountPromotions = this.discountPromotions.filter(d => d.id !== this.discountPromotion.id);
      this.discountPromotions.push(this.discountPromotion);
      this.setDiscountPromotion(this.discountPromotion, true, false);
    } else {
      this.setDiscountPromotion(this.discountPromotions.find(d => d.id === 'none'), true, false);
      this.discountPromotion.priceFix = this.priceFixWithoutDiscountPromotion;
    }
    for (const discountPromotion of this.discountPromotions) {
      if (discountPromotion.ibPromotionId && !discountPromotion.name.startsWith('InkBack')) {
        discountPromotion.name = 'InkBack: ' + discountPromotion.name;
      }
    }
    this.discountPromotions = this.discountPromotions.sort((a, b) => {
      if (a.name === 'Kein Rabatt') {
        return -2;
      }
      if (b.name === 'Kein Rabatt') {
        return 2;
      }
      if (a.name === 'Eigener Rabatt') {
        return -1;
      }
      if (b.name === 'Eigener Rabatt') {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });
    this.calcArtist(true);
  }


  save() {
    if (!this.promoCode) {
      this.promoOfferId = '';
      this.promoOfferPromoterName = '';
      this.promoOfferCreatedAt = null;
    }
    this.dialogRef.close({
      price: this.price(),
      discountPromotion: this.discountPromotion,
      artistPercentage: MathTools.round(this.artistPercentage, 5),
      priceTill: this.priceTill,
      promoOfferId: this.promoOfferId,
      promoOfferPromoterName: this.promoOfferPromoterName,
      promoOfferCreatedAt: this.promoOfferCreatedAt,
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  async setDiscountPromotion(discountPromotion: NxtDiscountPromotion, fromInit: boolean, fromRadioButton: boolean) {
    if (discountPromotion.ibPromotionId && fromRadioButton) {
      await this.dialogService.showOk('Dieser Rabatt ist nur mit einem InkBack-Code möglich');
      requestAnimationFrame(() => {
        this.discountPromotionRadio.setValue(this.discountPromotion);
      });
      return;
    }
    if (discountPromotion.onlyWithPromoCode && fromRadioButton) {
      await this.dialogService.showOk('Dieser Rabatt ist nur mit einem Promo-Code möglich');
      requestAnimationFrame(() => {
        this.discountPromotionRadio.setValue(this.discountPromotion);
      });
      return;
    }
    this.discountPromotion = discountPromotion;

    this.smoothHeightTrigger = Math.random();
    this.discount = this.discountPromotion?.discountPercentage;
    if (this.discountPromotion) {
      if (this.discountPromotion.priceFixValue) {
        this.price.set(this.discountPromotion.priceFixValue);
      }
      if (fromInit && this.currentPriceEstimatedFrom) {
        this.price.set(this.currentPriceEstimatedFrom);
      }
    }

    if (discountPromotion.artistGet && !fromInit) {
      setTimeout(() => {
        this.artistGet.set(discountPromotion.artistGet);
        this.artistPercentage = (this.artistGet() / this.price()) * 100;
      }, 200);
      // this.artistGet = discountPromotion.artistGet;
    }
    if (discountPromotion.priceLike) {
      this.discountPromotion.priceFix = false;
    }
    this.priceChanged(fromInit);
    this.setFocus(fromInit);
    if (!fromInit) {
      this.promoOfferId = '';
    } else {
      this.promoCode = this.promoOfferId;
    }
  }

  test() {
    this.priceInput?.setFocus();
    setTimeout(() => this.priceInput?.select(), 200);
  }

  setFocus(fromInit: boolean) {
    setTimeout(() => {
      requestAnimationFrame(() => {
        if (fromInit) {
          this.priceInput?.setFocus();
          // setTimeout(() => this.priceInput?.select(), 200);
        } else if (this.discountPromotion.id === 'custom') {
          this.customDiscountInput?.setFocus();
          this.customDiscountInput?.select();
        } else if (this.discountPromotion.discountPercentage > 0) {
          this.originalPriceInput?.setFocus();
          // this.originalPriceInput?.select();
        } else if (this.discountPromotion.priceFixValue) {
          this.originalPriceInput?.setFocus();
          // this.originalPriceInput?.select();
        } else {
          this.priceInput?.setFocus();
          // setTimeout(() => this.priceInput?.select(), 200);
        }
      });
    }, 200);
  }

  priceChanged(fromInit: boolean) {
    this.originalPrice.set(MathTools.roundMoney(this.price() / (100 - this.discount) * 100));
    Log.info('Der "Preis" hat sich geändert, der "Original-Preis" wird auf ' + this.originalPrice().toMoneyString() + ' geändert');
    this.calcArtist(fromInit);
  }

  originalPriceChanged() {
    if (this.discountPromotion.priceFixValue) {
      this.artistGet.set(this.originalPrice() / 2);
      Log.info('Der "Original-Preis" hat sich geändert, es ist ein Fix-Preis, "Artist bekommt" wird auf  ' + this.artistGet().toMoneyString() + ' geändert');
      this.calcArtist(false);
    } else {
      this.price.set(MathTools.roundMoney(this.originalPrice() / 100 * (100 - this.discount)));
      Log.info('Der "Original-Preis" hat sich geändert, es ist kein FixPreis, der "Preis" wird auf ' + this.price().toMoneyString() + ' geändert');
      this.calcArtist(false);
    }
  }

  customDiscountChanged() {
    if (this.discount > 30) {
      this.discount = 0;
    }
    this.discountPromotion.discountPercentage = this.discount;
    this.price.set(MathTools.roundMoney(this.originalPrice() / 100 * (100 - this.discount)));
    this.calcArtist(false);
  }

  private calcArtist(fromInit: boolean) {
    if (fromInit) {
      this.artistGet.set(MathTools.roundMoney(this.price() / 100 * this.artistPercentage));
      this.initArtistPercentage = this.artistPercentage;
    } else {
      const newCalc = true;
      if (this.originalPrice() === Infinity) {
        this.originalPrice.set(this.price());
      }
      if (TypeTools.isNumber(this.initArtistPercentage)) {
        // this.artistGet.set(this.originalPrice() / 100 * this.initArtistPercentage);
        this.artistGet.set(this.price() / 100 * this.initArtistPercentage);
      } else {
        // this.artistGet.set(this.originalPrice() / 2);
        this.artistGet.set(this.price() / 100 * this.initArtistPercentage);
      }
      this.artistGet.set(Math.round(this.artistGet() / (this.priceRoundValue / 2)) * (this.priceRoundValue / 2));
      if (this.artistGet() > 50) {
        if (this.artistGet() % 5 === 2.5) {
          this.artistGet.set(this.artistGet() - 2.5);
        }
        if (this.artistGet() % 5 === 7.5) {
          this.artistGet.set(this.artistGet() + 2.5);
        }
      }
      let artistPercentageShould = (this.artistGet() / this.price()) * 100;
      if (Number.isNaN(artistPercentageShould)) {
        artistPercentageShould = 0;
      }
      if (artistPercentageShould > 100) {
        this.artistPercentage = 100;
      } else {
        this.artistPercentage = artistPercentageShould;
      }
    }
    this.calcShowRoundPrice();
    if (!fromInit) {
      this.calcArtistDownIfPossible();
    }
  }

  roundPrice() {
    const newPrice = Math.round(this.price() / this.priceRoundValue) * this.priceRoundValue;
    if (newPrice !== this.price()) {
      this.price.set(newPrice);
      this.priceChanged(false);
      this.showRoundPrice = false;
    }
  }


  private calcShowRoundPrice() {
    TimeoutTools.clear(this.calcShowRoundPriceTimeout);
    this.calcShowRoundPriceTimeout = setTimeout(() => {
      this.showRoundPrice = this.price() % this.priceRoundValue > 0;
    }, 500);
  }

  typeof(value: any) {
    return typeof value;
  }

  isOk() {
    return TypeTools.isNumberAndNotNaN(this.price())
      && TypeTools.isNumberAndNotNaN(this.originalPrice())
      && TypeTools.isNumberAndNotNaN(this.artistGet())
      && TypeTools.isNumberAndNotNaN(this.artistPercentage);
  }

  changeArtistPercentage(more: boolean) {
    /*if (this.artistPercentage === 100 && more) {
      return;
    }
    if (this.artistPercentage === 0 && !more) {
      return;
    }*/
    let artistPercentageToSet = this.artistPercentage + (10 * (more ? 1 : -1));

    if (artistPercentageToSet > 100) {
      artistPercentageToSet = 100;
    } else if (artistPercentageToSet < 0) {
      artistPercentageToSet = 0;
    }

    this.artistPercentage = artistPercentageToSet;
    this.artistGet.set(MathTools.roundMoney(this.price() * this.artistPercentage / 100));
    this.initArtistPercentage = undefined;
  }

  changeArtistMoney(more: boolean) {
    let toAddOrRemove = this.priceRoundValue / 2;
    if (this.price() >= 100) {
      toAddOrRemove = this.priceRoundValue;
    }

    if (this.artistGet() % toAddOrRemove > 0) {
      toAddOrRemove = this.artistGet() % toAddOrRemove;
    }

    const artistShouldGet = this.artistGet() + ((toAddOrRemove) * (more ? 1 : -1));
    if (artistShouldGet <= this.price() && artistShouldGet >= 0) {
      this.artistGet.set(artistShouldGet);
      this.artistPercentage = (this.artistGet() / this.price()) * 100;
    }
    this.initArtistPercentage = undefined;
  }

  public async setArtistGet() {
    if (this.artist?.employed) {
      return;
    }
    const result = await this.dialogService.showInput('Artist bekommt', {prompt: this.artistGet(), isMoney: true, selectPrompt: true});
    if (TypeTools.isNumber(result)) {
      this.artistGet.set(result);
      this.artistPercentage = (this.artistGet() / this.price()) * 100;
      this.initArtistPercentage = undefined;
    }
  }

  public async startPromoCode() {
    this.promoCode = this.promoCode.toUpperCase().replaceAll('O', '0').trim();
    const promoResult = await this.socketService.getPromoByOfferId(this.promoCode);
    if (promoResult.offer && promoResult.offer.state === NxtPromoOfferState.Assigned) {
      this.dialogService.showOk('Der Promo-Code "' + this.promoCode.toUpperCase() + '" wurde bereits für einen Termin am ' + promoResult.offer.assignments[0].eventStart.dateFormat('dd.MM.yyyy HH:mm') + ' eingelöst\n');
      return;
    }

    if (promoResult.discountPromotion) {
      const promoToSet = this.discountPromotions.find(p => p.id === promoResult.discountPromotion.id);
      if (promoToSet) {
        if (!this.isNewEvent) {
          if (promoResult.offer.createdAt > this.eventCreatedAt) {
            let text = 'Der Rabatt wurde nach dem erstellen des Termins erstellt!';

            text += '\nTermin erstellt: ' + this.eventCreatedAt.dateFormat('dd.MM.yyyy HH:mm') + ' ' + this.eventCreatedAt.dateDiffToNowText();
            text += '\nCode erstellt: ' + promoResult.offer.createdAt.dateFormat('dd.MM.yyyy HH:mm') + ' ' + promoResult.offer.createdAt.dateDiffToNowText();
            text += '\n\nPromoter: ' + promoResult.offer.promoterName;
            const force = await this.dialogService.showYesNo(text, {yesText: 'Trotzdem anwenden', noText: 'Abbrechen'});
            if (!force) {
              return;
            }
          }
        }
        this.setDiscountPromotion(promoToSet, false, false);
        this.promoOfferId = promoResult.offer.id.toUpperCase();
        this.promoCode = promoResult.offer.id.toUpperCase();
        this.promoOfferPromoterName = promoResult.offer.promoterName;
        this.promoOfferCreatedAt = promoResult.offer.createdAt;
      } else {
        this.dialogService.showOk('Der Rabatt ist nicht aktiviert');
      }
    }
  }

  private calcArtistDownIfPossible() {
    {
      // kann er ArtistAnteil noch was runter?
      if (this.discountPromotion && this.discountPromotion.discountPercentage > 0) {
        // es ist eine Prozente-Aktion
        const shouldArtistPercentageExact = 100 - ((100 - this.discountPromotion.discountPercentage) * 0.5);
        const newArtistGet = this.artistGet() - 2.5;
        const newArtistPercentage = newArtistGet / this.price() * 100;
        if (newArtistPercentage + 1.5 >= shouldArtistPercentageExact) {
          if (newArtistPercentage > 50) {
            this.artistGet.update(value => value - 2.5);
            this.artistPercentage = newArtistPercentage;
            this.calcShowRoundPrice();
            this.calcArtistDownIfPossible();
          }
        }
      }
    }
  }

  public async generateStaticPromoCode(discountPromotionStaticCode: NxtDiscountPromotion) {
    this.dialogService.showLoading(LoadingId.GeneratePromoCode, 'Promo-Code für ' + discountPromotionStaticCode.name + ' wird erstellt...');
    const result = await this.socketService.generateStaticPromoCode(discountPromotionStaticCode);
    this.dialogService.hideLoading(LoadingId.GeneratePromoCode);
    this.promoCode = result.id;
    setTimeout(() => {
      this.startPromoCode();
    });
  }

  promoCodeChanged() {
    if (this.promoCode !== this.promoCode.toUpperCase()) {
      this.promoCode = this.promoCode.toUpperCase();
    }
  }

  setArtistGetClicked(value: number) {
    this.artistGet.set(value);
    this.artistPercentage = (this.artistGet() / this.price()) * 100;
  }

  public inkBackCodeChanged() {

  }
}
