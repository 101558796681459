import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, input, OnChanges, OnDestroy, OnInit, output, signal, SimpleChanges, ViewChild} from '@angular/core';
import {FormControlWrapper} from '../form-control-wrapper';
import {ControlEvent, FormsModule, ReactiveFormsModule, TouchedChangeEvent, Validators} from '@angular/forms';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon} from '@angular/material/icon';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {DateSelectComponent} from '../../calendar/date-select/date-select.component';
import {DialogService} from '../../../services/dialog.service';
import {firstValueFrom} from 'rxjs';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {toObservable} from '@angular/core/rxjs-interop';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {DayOfWeek} from '../../../common-interfaces/date.interface';

@Component({
  selector: 'nxt-date-picker-2',
  templateUrl: './date-picker-2.component.html',
  styleUrls: ['./date-picker-2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FlexModule, ExtendedModule, MatIcon, MatTooltip, FormsModule, ReactiveFormsModule],
  standalone: true,
})
export class DatePicker2Component extends FormControlWrapper implements OnInit, OnDestroy, OnChanges {
  // dummyNxtFormControl: FormControl;

  @ViewChild('element', {static: false}) set myInput(elementRef: any) {
    this.nxtFormControl.element = elementRef.nativeElement;
  }

  constructor() {
    super();
    this.registerFormControlChanges();
    this.pushSubscription = toObservable(this.value).subscribe(() => {
      if (this.value() !== undefined) {
        if (this.nxtFormControl.value !== this.value()) {
          this.nxtFormControl.setValue(this.value());
        }
      }
    });
  }

  /*** Inputs ***/
  placeholder = input<string>();
  jumpWeeks = input<boolean>(false);
  jumpToday = input<boolean>(false);
  jumpDays = input<boolean>(false);
  format = input<string>('EEE dd.MM.yyyy');
  value = input();
  readonly = input(false);
  noPadding = input(false);
  startYear = input(Date.now().dateFormat('yyyy'));
  /*** Outputs ***/
  valueChange = output<string>();

  /*** Signals ***/
  displayValue = signal('');


  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  dialogService = inject(DialogService);
  private dialogIsOpen = false;

  protected readonly Validators = Validators;

  showInvalid = signal(false);

  protected readonly ValidatorTools = ValidatorTools;

  onEvent(event: ControlEvent) {
    if (event instanceof TouchedChangeEvent) {
      // this.nxtFormControl.valid
      // event.touched
      // console.log(event);
    }
    this.calcShowInvalid();
    this.cdRef.detectChanges();
  }

  registerFormControlChanges() {
    this.pushSubscription = this.nxtFormControl.valueChanges.subscribe((value) => {
      this.setDisplayValue();
      if (value !== this.value()) {
        this.valueChange.emit(value);
      }
    });
  }

  setDisplayValue() {
    if (!this.nxtFormControl.value) {
      this.displayValue.set('');
    } else {
      this.displayValue.set(DateTools.format(this.nxtFormControl.value, this.format()));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.nxtFormControl) {
      this.registerFormControlChanges();
      this.setDisplayValue();
    }
  }

  ngOnInit(): void {
  }

  nxtOnDestroy(): void {
  }

  async showDatePickerDialog() {
    if (this.readonly()) {
      return;
    }
    if (this.dialogIsOpen) {
      return;
    }
    this.dialogIsOpen = true;
    const dialog = this.dialogService.showComponentDialog(DateSelectComponent);
    dialog.componentInstance.startYear = this.startYear();
    if (this.nxtFormControl.value) {
      dialog.componentInstance.selectedMonthString = this.nxtFormControl.value.dateFormat('yyyy-MM');
      dialog.componentInstance.markDateRange(this.nxtFormControl.value, this.nxtFormControl.value);
    }
    const result = await firstValueFrom(dialog.afterClosed());
    if (result) {
      if (this.nxtFormControl.value !== result) {
        this.nxtFormControl.setValue(result);
      }
    }
    this.dialogIsOpen = false;
  }

  private calcShowInvalid() {
    this.showInvalid.set(false);
  }

  jumpWeekClicked(weeks: number) {
    this.nxtFormControl.setValue(this.nxtFormControl.value.dateParse().dateAddDays(weeks * 7).dateFormatDate());
  }

  jumpDayClicked(days: number) {
    this.nxtFormControl.setValue(this.nxtFormControl.value.dateParse().dateAddDays(days).dateFormatDate());
  }

  jumpTodayClicked() {
    let jumpTo = DateTools.formatNowDate();
    if (DateTools.getDayOfWeek(Date.now()) !== DayOfWeek.Monday) {
      jumpTo = DateTools.getLastMonday(0).dateFormatDate();
    }
    this.nxtFormControl.setValue(jumpTo);
  }
}
