import {NxtCalendarEvent} from '../../common-interfaces/nxt.calendar-event.interface';
import {NxtStudioCashReportData, NxtWorkSession} from '../../common-interfaces/nxt.work-session';
import {DurationTools} from './duration.tools';
import {TimeWindowTools} from './timewindow.tools';
import {NxtAvailableArtistDayExtended, NxtAvailableArtistWorkTimeWindow} from '../../common-interfaces/nxt.available-artist-day.interface';
import {ArrayTools} from './array.tools';
import {DateTools} from './date.tools';
import {StringTools} from './string.tools';
import {PaymentTools} from './payment.tools';
import _ from 'lodash';
import {NxtArtist} from '../../common-interfaces/nxt.artist.interface';
import {WithoutNxtDbFields} from '../../common-interfaces/nxt.db-fields.interface';

export interface CalcArtistData {
  name: string;
  workTimeText: string;
  isInWorkTime: boolean;
  state: 'in-work' | 'available' | 'overdue' | 'shorten-available' | 'not-in-work' | 'early-paid-out' | 'error' | 'not-in-work-session';
  stateText: string;
  payoutValue: number;
  payoutValuePostponedEvents: number;
  sortValue: number;
  workTimeWindow: NxtAvailableArtistWorkTimeWindow;
  isEarlyPaidOut: boolean;
  isArtistOpen: boolean;
  canEarlyPayout: boolean;
}

export class ArtistsTools {

  public static blackList = ['(x)', 'Heidi'];


  static badPhotoTexts = [
    'Hey {{artist}}, \n' +
    'wir haben uns die Fotos deines Tattoos angeschaut.\n' +
    'Das Motiv ist ja super, aber das Foto ist jetzt nicht so toll geworden. \n' +
    '\n' +
    'Die Fotos sind unsere Hauptwerbung und unser Aushängeschild, die müssen genauso gut sein wie die Tattoos auch sind!\n' +
    '\n' +
    'Brauchst du eine Einweisung wie du die Fotos richtig und besser machen kannst?\n' +
    'Du kannst uns gern einfach ansprechen kein Problem, dann zeigen wir dir das nochmal.\n' +
    '\n' +
    'Also check das die Fotos genauso geil werden wie das Motiv :) ',

    'Hey {{artist}}, \n' +
    'wir haben dir letztens schonmal gesagt, dass die Fotos nicht gut sind. Woran liegt es? \n' +
    '\n' +
    'Wir können dadurch schlechter werben und dich schwerer verkaufen und nicht garantieren das wir dich ausbuchen. \n' +
    '\n' +
    'Komm doch bitte einmal morgen zur Theke damit wir das Problem bequatschen können.',

    'Hey {{artist}}, \n' +
    '\n' +
    'leider klappt es mit den Fotos ja nicht. Schade. \n' +
    '\n' +
    'Diese Fotos sind unsere Werbung, wir werben damit für uns und vor allem auch für dich, damit wir dich auch ausbuchen können. Wenn die Fotos nicht gut sind, haben wir schlechte Werbung, also möglicherweise weniger Kunden und weniger Geld auf beiden Seiten. \n' +
    'Wenn du weiterhin mit uns zusammen arbeiten möchtest, musst du dieses Problem in den Griff kriegen!\n' +
    '\n' +
    'Geh bitte morgen einmal zu Capone, er möchte mit dir darüber sprechen.',
  ];
  public static artistPercentageConfirmedValue = 350;

  public static calcArtists(workSession: WithoutNxtDbFields<NxtWorkSession>, events: NxtCalendarEvent[], postponedEvents: NxtCalendarEvent[], availableArtist: NxtAvailableArtistDayExtended): CalcArtistData[] {
    const result: CalcArtistData[] = [];
    let thisTime = Date.now() - Date.now().dateClearTime();
    if (DateTools.currentHours() < 6) {
      // nach 12 aber vor 6, d.h. artist-time-windows sind vom Vortag.
      thisTime = Date.now() - Date.now().dateAddDays(-1).dateClearTime();
    }
    const workSessionActive = workSession.startAt < Date.now() && workSession.endAt > Date.now();
    for (const artist of availableArtist.artists) {
      const payoutValue = events.filter(e => e.artist === artist.name).reduce((sum, e) => sum + (e.artistTotalGet || 0), 0);
      const payoutValuePostponedEvents = postponedEvents.filter(e => e.artist === artist.name).reduce((sum, e) => sum + (e.artistTotalGet || 0), 0);

      const resultArtist: CalcArtistData = {
        name: artist.name,
        workTimeText: '',
        isInWorkTime: true,
        state: 'in-work',
        payoutValue,
        payoutValuePostponedEvents,
        sortValue: payoutValue,
        workTimeWindow: artist.workTimeWindow,
        isEarlyPaidOut: artist.isEarlyPaidOut,
        stateText: 'Tätowiert',
        isArtistOpen: false,
        canEarlyPayout: workSession.artistsCanEarlyPayout?.includes(artist.name),
      };
      const artistEventsWithMoneyForArtist = events.filter(e => e.artist === artist.name && e.artistTotalGet > 0);
      let allEventsPayedOut = true;
      for (const event of artistEventsWithMoneyForArtist) {
        if (!event.payments.some(p => p.paymentType === 'payout')) {
          allEventsPayedOut = false;
          break;
        }
      }
      // const artistHasEarlyPaidOut = events.some(e => e.artist === artist.name && e.payments.some(p => p.earlyPayout));
      const artistLastEvents = events.filter(e => e.artist === artist.name && e.start < Date.now()).sortNumber('start', true);
      if (artist.workTimeWindow.start > -1 && artist.workTimeWindow.end > -1) {
        resultArtist.workTimeText = DurationTools.format(artist.workTimeWindow.start, 'HH:mm', true) + ' - ' + DurationTools.format(artist.workTimeWindow.end, 'HH:mm', true);
        resultArtist.isInWorkTime = thisTime >= artist.workTimeWindow.start && thisTime <= artist.workTimeWindow.end;
      } else if (artist.workTimeWindow.start > -1) {
        resultArtist.workTimeText = 'ab ' + DurationTools.format(artist.workTimeWindow.start, 'HH:mm');
        resultArtist.isInWorkTime = thisTime >= artist.workTimeWindow.start;
      } else if (artist.workTimeWindow.end > -1) {
        resultArtist.workTimeText = 'bis ' + DurationTools.format(artist.workTimeWindow.end, 'HH:mm');
        resultArtist.isInWorkTime = thisTime <= artist.workTimeWindow.end;
      }
      if (workSession.endAt > Date.now()) {
        if (artist.workTimeWindow.start === -2 && artist.workTimeWindow.end === -2) {
          // Der Artist ist an diesem Tag nicht mehr da
          resultArtist.state = 'not-in-work';
          resultArtist.workTimeText = 'heute nicht da';
          resultArtist.stateText = '';
          resultArtist.isInWorkTime = false;
        } else {
          for (const freeTimeWindow of artist.freeTimeWindows) {
            let timeWindowToday = TimeWindowTools.timeWindowToDateTimeToday(freeTimeWindow);
            if (DateTools.currentHours() < 6) {
              timeWindowToday = TimeWindowTools.timeWindowToDateTimeYesterday(freeTimeWindow);
            }
            const isCorrectTimeWindow = timeWindowToday.start < Date.now() && timeWindowToday.end > Date.now();
            if (isCorrectTimeWindow) {
              if (timeWindowToday.end > (Date.now() + DurationTools.DURATION_1MINUTE * 30)) {
                // das freie Zeitfenster ist frühstens in 30 Minuten zu Ende
                if (timeWindowToday.start < Date.now()) {
                  // das freie Zeitfenster hat begonnen
                  const hasCurrentOpenEvents = artistLastEvents.length > 0 && !artistLastEvents[0].closed;
                  if (!hasCurrentOpenEvents) {
                    resultArtist.state = 'available';
                    resultArtist.stateText = 'Bis ' + timeWindowToday.end.dateFormat('HH:mm') + ' verfügbar';
                  } else {
                    resultArtist.state = 'overdue';
                  }
                } else {
                  if (TimeWindowTools.timeWindowToDateTimeToday(freeTimeWindow).start - DurationTools.DURATION_1MINUTE * 15 < Date.now()) {
                    // ist in 15 Verfügbar
                    if (freeTimeWindow.duration > DurationTools.DURATION_1MINUTE * 30) {
                      // dann für länger als 30 Minuten verfügbar
                      resultArtist.state = 'shorten-available';
                    }
                  }
                }
              } else {
                resultArtist.stateText = 'Ab ' + timeWindowToday.end.dateFormat('HH:mm') + ' nicht mehr verfügbar';
              }
            }
          }
        }
      } else {
        resultArtist.state = 'not-in-work-session';
        resultArtist.stateText = '';
      }
      if (!resultArtist.isInWorkTime) {
        resultArtist.sortValue = artist.workTimeWindow.end;
        if (artist.workTimeWindow.end === -2) {
          // ist heute nicht da
          resultArtist.sortValue = 9999999999999;
        }
        if (workSessionActive) {
          resultArtist.state = 'not-in-work';
          resultArtist.stateText = '';
        }
      }
      if (artist.isEarlyPaidOut && workSessionActive) {
        if (allEventsPayedOut) {
          resultArtist.stateText = 'Bereits ausgezahlt';
          resultArtist.state = 'early-paid-out';
          resultArtist.sortValue = 9999999999999;
        } else {
          resultArtist.stateText = 'FEHLER\n' + artist.name + ' wurde bereits ausgezahlt, hat aber weitere Termine, das geht nicht.';
          resultArtist.state = 'error';
          resultArtist.sortValue = 9999999999999;
        }
      }
      result.push(resultArtist);
    }
    const uniqueArtistOpenCalendarNumbers = ArrayTools.unique(events.map(e => e.calendarNumber)).map(v => parseInt(v, 10)).filter(v => v > 990);
    for (const uniqueArtistOpenCalendarNumber of uniqueArtistOpenCalendarNumbers) {
      const firstEvent = events.find(e => e.calendarNumber === uniqueArtistOpenCalendarNumber.toString());
      // todo: was ist das hier?
      if (firstEvent) { // Artist offen hinzufügen
        result.push({
          name: firstEvent.artist,
          isEarlyPaidOut: false,
          isInWorkTime: true,
          state: 'available',
          payoutValue: 0,
          sortValue: -1,
          stateText: '',
          workTimeText: '',
          canEarlyPayout: workSession.artistsCanEarlyPayout?.includes(firstEvent.artist),
          workTimeWindow: {
            start: -1,
            end: -1,
            dateString: '',
            earlyPaidOut: false,
          },
          isArtistOpen: true,
          payoutValuePostponedEvents: 0,
        });
      }
    }
    return result.sortNumber('sortValue', false);
  }

  public static filterBlacklist(artists: string[]) {
    return artists.filter(artist => {
      return !this.blackList.find(b => artist.indexOf(b) > -1);

    });
  }

  public static filterFixArtists(artists: string[]) {
    return artists.filter(artist => {
      return artist.toLowerCase().indexOf('(fest)') === -1;
    });
  }

  public static getStartEndDateFromCalendarSummary(artistCalendarSummary: string) {
    const indexStart = artistCalendarSummary.indexOf('(');
    const indexMiddle = artistCalendarSummary.indexOf('-');
    const indexEnd = artistCalendarSummary.indexOf(')');
    if (indexStart > -1 && indexMiddle > -1 && indexEnd > -1) {
      const dateStartString = artistCalendarSummary.substring(indexStart + 1, indexMiddle);
      const dateEndString = artistCalendarSummary.substring(indexMiddle + 1, indexEnd);

      const dateStart: Date = new Date();
      dateStart.setDate(parseInt(dateStartString.substr(0, dateStartString.indexOf('.')), 10));
      dateStart.setMonth(parseInt(dateStartString.substr(dateStartString.indexOf('.') + 1), 10) - 1);
      DateTools.clearTime(dateStart);

      const dateEnd: Date = new Date();
      dateEnd.setDate(parseInt(dateEndString.substr(0, dateEndString.indexOf('.')), 10));
      dateEnd.setMonth(parseInt(dateEndString.substr(dateEndString.indexOf('.') + 1), 10) - 1);
      DateTools.clearTime(dateEnd);

      return {start: dateStart.getTime(), end: dateEnd.getTime()};
    }

  }

  public static getArtistNameFromCalendarSummary(calendarName: string) {
    let artistName = calendarName;
    const numberStart = /(^\d{2,3})/g.exec(artistName);
    if (numberStart && numberStart.length > 1) {
      artistName = artistName.substring(numberStart[1].length).trim();
    }

    const studioPrefix = /(^\w\s)/g.exec(artistName);
    if (studioPrefix && studioPrefix.length > 1) {
      artistName = artistName.substring(studioPrefix[1].length).trim();
    }
    return artistName;
  }

  static getCalendarNumberFromCalendarSummary(artist: string) {
    const artistNumber = artist.match(/^\d* /);
    if (artistNumber && artistNumber.length > 0) {
      return artistNumber[0].trim();
    }
    return '';
  }

  static getStudioFromArtist(artist: string) {
    const artistNumber = artist.match(/^\d* /);
    if (artistNumber && artistNumber.length > 0) {
      return artistNumber[0].trim();
    }
  }

  /*static removeArtistNumberFromArtist(artist: string) {
      if (artist === '101 V Julian') {
          console.log('jo');
      }
      const artistNumber = artist.match(/^\d* /);
      if (artistNumber && artistNumber.length > 0) {
          let result = artist.substr(artistNumber[0].length).trim();
          if (result.indexOf(' ') === 1) {
              result = result.substr(2);
          }
          return result;
      }
      return '';
  }*/

  public static calendarNumberToArtistCustomerNumber(calendarNumber?: string) {
    if (calendarNumber) {
      return 'A' + StringTools.fill(calendarNumber, 5, '0', false);
    }
    return '';
  }


  static getArtistPayouts(data: NxtStudioCashReportData, options: { calcType: 'payout-payment' | 'pre-calc', withEarlyPayout: boolean }): {
    payoutSum_: number,
    payoutSum: number,
    artists: {
      artistName: string,
      payoutValue: number,
      payoutValue_: number,
      payoutValueTotal: number
    }[]
  } {
    const artistPayouts: { [artistName: string]: { artistName: string, payoutValue: number, payoutValue_: number, payoutValueTotal: number } } = {};
    for (const event of data.events) {
      if (!artistPayouts[event.artist]) {
        artistPayouts[event.artist] = {artistName: event.artist, payoutValue: 0, payoutValue_: 0, payoutValueTotal: 0};
      }
      if (options.calcType === 'pre-calc') {
        if (event.visibility === 'private') {
          artistPayouts[event.artist].payoutValue_ += event.artistTotalGet;
        } else {
          artistPayouts[event.artist].payoutValue += event.artistTotalGet;
        }
        artistPayouts[event.artist].payoutValueTotal += event.artistTotalGet;
      } else {
        if (event.visibility === 'private') {
          artistPayouts[event.artist].payoutValue_ += PaymentTools.getPaymentSumByPaymentType(event.payments, 'payout');
        } else {
          artistPayouts[event.artist].payoutValue += PaymentTools.getPaymentSumByPaymentType(event.payments, 'payout');
        }
        artistPayouts[event.artist].payoutValueTotal += PaymentTools.getPaymentSumByPaymentType(event.payments, 'payout');
      }
    }
    const result = {
      payoutSum_: 0,
      payoutSum: 0,
      artists: _.keys(artistPayouts).map(key => artistPayouts[key]),
    };
    if (!options.withEarlyPayout) {
      result.artists = result.artists.filter(r => !data.events.some(e => e.artist === r.artistName && e.payments.some(p => p.earlyPayout)));
    }
    result.payoutSum_ = result.artists.map(a => a.payoutValue_).reduce((sum, v) => sum + v, 0);
    result.payoutSum = result.artists.map(a => a.payoutValue).reduce((sum, v) => sum + v, 0);
    return result;
  }

  static replaceColor(color) {
    switch (color.toUpperCase()) {
      case '#CCA6AC':
        return '#AD1457';
      case '#FA573C':
        return '#F4511E';
      case '#FBE983':
        return '#E4C441';
      case '#16A765':
        return '#0B8043';
      case '#4986E7':
        return '#3F51B5';
      case '#CD74E6':
        return '#8E24AA';
      case '#F691B2':
        return '#D81B60';
      case '#FF7537':
        return '#EF6C00';
      case '#B3DC6C':
        return '#C0CA33';
      case '#42D692':
        return '#009688';
      case '#9A9CFF':
        return '#7986CB';
      case '#AC725E':
        return '#795548';
      case '#F83A22':
        return '#D50000';
      case '#FFAD46':
        return '#F09300';
      case '#7BD148':
        return '#7CB342';
      case '#9FE1E7':
        return '#039BE5';
      case '#B99AFF':
        return '#B39DDB';
      case '#C2C2C2':
        return '#616161';
      case '#D06B64':
        return '#E67C73';
      case '#FAD165':
        return '#F6BF26';
      case '#92E1C0':
        return '#33B679';
      case '#9FC6E7':
        return '#4285F4';
      case '#A47AE2':
        return '#9E69AF';
      case '#CABDBF':
        return '#A79B8E';
    }
    return color.toUpperCase();
  }

  static getCancelArtist() {
    const artist = ArtistsTools.getEmptyArtist();
    artist.id = 'canceled';
    artist.name = 'ABGESAGT :(';
    artist.calendarNumber = '';
    return artist;
  }

  static getArtistOpenPiercing() {
    const artist = ArtistsTools.getEmptyArtist();
    artist.id = 'artist-open-piercing';
    artist.name = 'Piercing offen';
    artist.workType = 'piercing';
    artist.calendarNumber = '998';
    return artist;
  }

  static getArtistOpenTattoo() {
    const artist = ArtistsTools.getEmptyArtist();
    artist.id = 'artist-open-tattoo';
    artist.name = 'Artist offen';
    artist.workType = 'tattoo';
    artist.calendarNumber = '999';
    return artist;
  }

  static getEmptyArtist() {
    const artist: NxtArtist = {
      id: '',
      name: '',
      lang: '',
      calendarNumber: '',
      skills: {boolSkillValues: [], skillValues: [], speed: 0},
      color: '',
      invoiceData: {country: '', city: '', name: '', customerNumber: '', email: '', numberRange: '', postalCode: '', salutation: '', street: '', vatId: ''},
      internalNotes: [],
      instagram: '',
      contractSigned: false,
      birthday: '',
      idNumber: '',
      mobile: '',
      workType: 'tattoo',
      googlePhotosAlbumId: '',
      googlePhotosAlbumShareLink: '',
      googleDriveFolderId: '',
      updatedAt: 0,
      createdAt: 0,
      createdBy: '',
      updatedBy: '',
      telegramChatId: 0,
      contracts: [],
    };
    return artist;
  }

  static isArtistOpen(artistName: string) {
    return ArtistsTools.getArtistOpenTattoo().name === artistName || ArtistsTools.getArtistOpenPiercing().name === artistName;
  }
}
