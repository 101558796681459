import {EventEmitter, Injectable} from '@angular/core';
import {DialogService} from './dialog.service';
import {Clipboard} from '@angular/cdk/clipboard';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  public onImagePast = new EventEmitter<string | ArrayBuffer>();

  constructor(
    private clipboard: Clipboard,
    private dialogService: DialogService
  ) {

  }

  public handlePaste(event: ClipboardEvent): void {
    const pastedImage = this.getPastedImage(event);
    if (pastedImage) {
      const reader = new FileReader();
      reader.readAsDataURL(pastedImage);
      reader.onloadend = () => {
        this.onImagePast.next(reader.result);
      };
    }
  }

  private getPastedImage(event: ClipboardEvent): File | null {
    if (event.clipboardData && event.clipboardData.files && event.clipboardData.files.length && this.isImageFile(event.clipboardData.files[0])) {
      return (event.clipboardData.files[0]);
    }
    return (null);
  }

  private isImageFile(file: File): boolean {
    return (file.type.search(/^image\//i) === 0);
  }

  public copyToClipboard(valueToPut: string) {
    this.clipboard.copy(valueToPut);
    /*const el = document.createElement('textarea');
    el.value = valueToPut;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el?.select();
    document.execCommand('copy');
    document.body.removeChild(el);*/
  }
}
