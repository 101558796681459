<script type="module" src="https://cdn.skypack.dev/pdfjs-viewer-element"></script>


@if (!showAll) {
  <div class="flex h-full w-full flex-col items-center justify-center gap-7" style="background-color: #000; color: #fff">
    <img class="w-1/2 md:max-w-[400px]" src="assets/logo/logo_white.svg"/>
    <div class="text-4xl uppercase tracking-[0.25em]" style="font-family: 'Montserrat',serif; font-weight: 700">{{ loginService.getUsername() }}</div>
    <div class="flex flex-col items-center justify-center gap-3 md:flex-row md:gap-2">
      <nxt-button routerLink="/studio-cash-report" nxtPermission="StudioCashReport">CashReport</nxt-button>
      <nxt-button routerLink="/photos" nxtPermission="Photos_Gallery">Fotos</nxt-button>
      <nxt-button routerLink="/calendar" nxtPermission="Calendar">Kalender</nxt-button>
      <nxt-button routerLink="/studio-fee" nxtPermission="StudioFee">Studio-Abgaben</nxt-button>
      <nxt-button routerLink="/events-rating" nxtPermission="EventRating_Edit">Termin-Bewertung</nxt-button>
      <nxt-button routerLink="/bank-transactions" nxtPermission="Bank_Transactions">Transaktionen</nxt-button>
      <nxt-button routerLink="/bank-documents" nxtPermission="Bank_Transactions">Dokumente</nxt-button>
    </div>
    <div class="flex flex-col">
      <div class="flex flex-row items-center" nxtPermission="Bank_Documents">
        <nxt-input class="w-[200px]" [(value)]="bankDocumentId" placeholder="Dokument ID"></nxt-input>
        <nxt-button-icon (click)="openBankDocumentClicked()">chevron_right</nxt-button-icon>
      </div>
      <div class="flex flex-row items-center" nxtPermission="Bank_Transactions">
        <nxt-input class="w-[200px]" [(value)]="bankTransactionId" (change)="bankTransactionChanged($event)"
                   placeholder="Transaktion ID"></nxt-input>
        <nxt-button-icon (click)="openBankTransactionClicked()">chevron_right</nxt-button-icon>
      </div>
    </div>
    <nxt-button (click)="logout()">AUSLOGGEN</nxt-button>
  </div>
} @else {
  <div class="wrapper" fxLayout="row">
    <div class="item" fxLayout="column" style="overflow: auto;">
      @if (localLastUrls()) {
        <div class="flex flex-row gap-4">
          @for (localLastUrl of localLastUrls(); track localLastUrl) {
            @if (localLastUrl.length > 20) {
              <nxt-button [routerLink]="localLastUrl">{{ localLastUrl.substring(0, 20) }}</nxt-button>
            } @else {
              <nxt-button [routerLink]="localLastUrl">{{ localLastUrl }}</nxt-button>
            }
          }
        </div>
      }
      <div style="padding:8px;" class="hidden md:block">
        <div class="font-size-1" style="width: fit-content" (click)="logout()">Hallo {{ loginService.getUserPrefix() }}</div>
        <div>Version: {{ version }}</div>
      </div>

      <nxt-button (click)="showAll = false">dashboard</nxt-button>

      <div class="my-2 flex flex-row gap-2">
        <nxt-button routerLink="/studio-cash-report">CASH-REPORT</nxt-button>
        <nxt-button routerLink="/calendar">KALENDER</nxt-button>
        <nxt-button routerLink="/employees">MITARBEITER</nxt-button>
        <nxt-button routerLink="/employee-calendar">MITARBEITER-KALENDER</nxt-button>
        <nxt-button routerLink="/employee-works">MITARBEITER-ARBEIT</nxt-button>
        <nxt-button routerLink="/money-accounts">KASSEN</nxt-button>
        <nxt-button (click)="this.grammarTest()">GRAMMAR</nxt-button>
      </div>

      <div class="my-2 flex flex-row gap-2">
        <nxt-button nxtPermission="PayPalSMSCode" (click)="showSmsCode('PayPal')">SMS PayPal</nxt-button>
        <nxt-button nxtPermission="PayPalSMSCode" (click)="showSmsCode('Klarna')">SMS Klarna</nxt-button>
        <nxt-button nxtPermission="GoogleSMSCode" (click)="showSmsCode('Google')">SMS Google</nxt-button>
      </div>


      <div class="my-2 flex flex-row gap-2">
        <nxt-button routerLink="/bank-transactions">TRANSAKTIONEN</nxt-button>
        <nxt-button routerLink="/bank-documents">DOKUMENTE</nxt-button>
        <nxt-button (click)="showBroadcast()">BROADCAST</nxt-button>
        <nxt-button (click)="showBackoffice()">BACKOFFICE</nxt-button>
        <nxt-button (click)="showStudioFee()">STUDIO-ABGABEN</nxt-button>
        <nxt-button (click)="testReminder()">REMINDER</nxt-button>
        <nxt-button (click)="showWhatsAppQr()">WHATSAPP-CONNECT</nxt-button>
        <nxt-button (click)="showVideo()">VIDEO</nxt-button>
      </div>
      <div class="my-2 flex flex-row gap-2">
        <table class="my-3">
          <tr>
            <td>EventId</td>
            <td>
              <input style="width:600px; font-size: 18px; background-color: #444444; border-radius: 4px; border: 1px solid #6a6a6a; padding: 4px;"
                     (change)="idChanged($event)"/>
            </td>
          </tr>
          <tr>
            <td>Handy</td>
            <td>
              <input style="width:600px; font-size: 18px; background-color: #444444; border-radius: 4px; border: 1px solid #6a6a6a; padding: 4px;"
                     (change)="mobileChanged($event)"/>
            </td>
          </tr>
          <tr>
            <td>History</td>
            <td>
              <input style="width:600px; font-size: 18px; background-color: #444444; border-radius: 4px; border: 1px solid #6a6a6a; padding: 4px;"
                     (change)="historyChanged($event)"/>
            </td>
          </tr>
          <tr>
            <td>Transaktion</td>
            <td>
              <input style="width:600px; font-size: 18px; background-color: #444444; border-radius: 4px; border: 1px solid #6a6a6a; padding: 4px;"
                     (change)="bankTransactionChanged($event)"/>
            </td>
          </tr>
          <tr>
            <td>Dokument</td>
            <td>
              <input style="width:600px; font-size: 18px; background-color: #444444; border-radius: 4px; border: 1px solid #6a6a6a; padding: 4px;"
                     (change)="bankDocumentChanged($event)"/>
            </td>
          </tr>
        </table>
      </div>
      <div>
        <div class="my-2 flex flex-row gap-2">
          <div>SEITEN</div>
          <nxt-button routerLink="/search">SUCHE</nxt-button>
          <nxt-button routerLink="/users">BENUTZER</nxt-button>
          <nxt-button routerLink="/artists">ARTISTS</nxt-button>
          <nxt-button routerLink="/inventory">INVENTAR</nxt-button>
          <nxt-button routerLink="/discount-gift-cards">GIFT-CARDS</nxt-button>
          <nxt-button (click)="soundTest()">SOUND TEST</nxt-button>
        </div>
      </div>
      <br/>


      <div class="my-2 flex flex-row gap-2">
        <nxt-button (click)="headerDoubleBlockClicked()">Grafik-Header</nxt-button>
        <nxt-button (click)="invoices()">Rechnungen</nxt-button>
      </div>


      <nxt-time-picker *ngIf="false" value="" placeholder="Hallo dies das"></nxt-time-picker>

      <div class="my-2 flex flex-row gap-2">
        <nxt-button (click)="newEvent(true)">neuer Termin</nxt-button>
        <nxt-button (click)="newEvent(false)">neuer Termin<br/>mit Terminfinder</nxt-button>
        <nxt-button (click)="newEventInNewWindow()">neuer Termin neues Fenster</nxt-button>
        <nxt-button *ngIf="false" (click)="newContact()">neuer Kunde</nxt-button>

        <nxt-button (click)="backoffice()">BACKOFFICE</nxt-button>
        <!--<nxt-button (click)="showInventory()">INVENTAR</nxt-button>-->
        <!--<nxt-button (click)="checkListTest()">CHECKLIST</nxt-button>-->
      </div>


      <div class="my-2 flex flex-row gap-2">
        <nxt-button routerLink="/admin">ADMIN</nxt-button>
        <nxt-button (click)="chat()">CHAT mit Julian</nxt-button>
        <nxt-button (click)="sendToJulian()">send to Julian</nxt-button>
        <nxt-button routerLink="/whats-app-latest-messages">whats-app-latest-messages</nxt-button>
      </div>


      <div class="my-2 flex flex-row gap-2">
        <nxt-button *ngIf="true" (click)="showLoading()">LoadingTest</nxt-button>
        <nxt-button (click)="newPassword()">Neues Passwort</nxt-button>
        <nxt-button *ngIf="false" (click)="userIsActive()">User is Active Whats-App</nxt-button>
      </div>


      <div>
        <nxt-button (click)="throwErrorToolsInfo()">ErrorToolsInfo</nxt-button>
        <nxt-button (click)="throwErrorToolsError()">ErrorToolsError</nxt-button>
        <nxt-button (click)="throwError()">ThrowError</nxt-button>
      </div>


      <div class="my-2 flex flex-row gap-2">
        <div>INPUTS / DIALOGS</div>
        <nxt-button (click)="showInputTest()">InputTest</nxt-button>
        <nxt-button (click)="showInputDialog()">InputDialog</nxt-button>
        <nxt-button (click)="showYesNo()">Yes No</nxt-button>
        <nxt-button (click)="showOk()">OK</nxt-button>
        <nxt-button (click)="showSelect()">SELECT</nxt-button>
      </div>


      <div class="my-2 flex flex-row gap-2">
        <nxt-button (click)="wtest()">whatsapp-preis</nxt-button>
        <nxt-button (click)="fastAnswer()">fastAnswer</nxt-button>
        <nxt-button (click)="moneyStackTest()">MoneyStackTest</nxt-button>
        <nxt-button (click)="showCombineContacts()">showCombineContacts</nxt-button>
      </div>


      <div>
        <nxt-button (click)="emitUserChanged()">emit userChanged</nxt-button>
        <nxt-button (click)="errorTest()">ErrorTest</nxt-button>
      </div>
    </div>


    <!--<qrcode
      errorCorrectionLevel="M"
      qrdata="https://nxt-lvl.ink"
      [width]="300" colorDark="#FFF"
      colorLight="#2A2A2A"></qrcode>-->
  </div>

}
