import {inject, Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {BehaviorSubject} from 'rxjs';
import {NxtTattooTemplate} from '../common-interfaces/tattoo-template.interface';
import {DurationTools} from '../common-browser/helpers/duration.tools';

@Injectable({
  providedIn: 'root',
})
export class TattooTemplateService {
  /*** Injections ***/
  private socketService = inject(SocketService);

  currentTattooTemplates = new BehaviorSubject<NxtTattooTemplate[]>([]);

  constructor() {
    setTimeout(() => this.init());
    setInterval(() => {
      this.currentTattooTemplates.next(this.currentTattooTemplates.value.filter((t) => t.createdAt > Date.now() - DurationTools.DURATION_1MINUTE * 5));
    }, 5 * DurationTools.DURATION_1MINUTE);
  }

  private async init() {
  }

  addTemplate(template: NxtTattooTemplate) {
    const current = this.currentTattooTemplates.getValue();
    current.push(template);
    this.currentTattooTemplates.next(current);
  }

  clear(eventId: string) {
    this.currentTattooTemplates.next(this.currentTattooTemplates.value.filter((t) => t.eventId !== eventId));
  }

  deleteTattooTemplate(tattooTemplate: NxtTattooTemplate) {
    this.currentTattooTemplates.next(this.currentTattooTemplates.value.filter((t) => t !== tattooTemplate));
  }
}
