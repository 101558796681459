import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MoneyTools} from '../../common-browser/helpers/money.tools';
import {ColorTools} from '../../common-browser/helpers/color.tools';
import {NxtMoneyStack} from '../../common-interfaces/nxt.money-stack.interface';
import {DialogService} from '../../services/dialog.service';
import {DecimalTools} from '../../common-browser/helpers/decimal.tools';
import {MoneyStackTools} from '../../common-browser/helpers/money-stack.tools';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {TypeTools} from '../../common-browser/helpers/type.tools';
import {MoneyPipe} from '../../pipes/money.pipe';
import {MultiClickDirective} from '../../directives/multi-click.directive';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgFor, NgIf, NgStyle} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-money-counter-2',
    templateUrl: './money-counter-2.component.html',
    styleUrls: ['./money-counter-2.component.scss'],
    imports: [FlexModule, NgFor, NgStyle, ExtendedModule, NxtButtonIconComponent, NgIf, MultiClickDirective, MoneyPipe]
})
export class MoneyCounter2Component implements OnInit, AfterViewInit {

  @Input() showShould = true;
  @Input() moneyStack: NxtMoneyStack;
  @Input() totalMoneyShouldValue = 0;
  @Input() beforeRemoveMoney: (money: number) => Promise<boolean>;
  @Input() beforeAddMoney: (money: number) => Promise<boolean>;
  @Output() moneyStackChange = new EventEmitter<NxtMoneyStack>();

  totalMoneyValue = 0;
  @Output() totalMoneyValueChange = new EventEmitter<number>();

  public getMoneyWidth = MoneyTools.getMoneyWidth;
  public getMoneyHeight = MoneyTools.getMoneyHeight;


  moneys = MoneyTools.moneysReverse;
  totalMoneyValueColor = '';
  totalMoneyShouldValueColor = '';
  totalMoneyDiffValueColor = '';


  totalMoneyDiffValue = 0;

  constructor(
    private dialogService: DialogService
    // private dialogRef: MatDialogRef<MoneyCounterComponent>,
  ) {
  }

  ngOnInit() {
    this.calcTotalMoneyValue();
  }

  getMoneyCount(money: number) {
    return this.moneyStack[money];
  }

  async setMoneyCount(money: number) {
    const prompt = this.moneyStack[money] !== 0 ? this.moneyStack[money].toString() : '0';
    const dialog = await this.dialogService.showInputOld({
      isMoney: false,
      prompt,
      message: 'Anzahl von <span class="text-lg font-bold">' + DecimalTools.toMoneyString(money) + '</span>',
      okButtonText: 'OK',
      selectPrompt: true
    });
    const result = await dialog.afterClosed().toPromise();
    let askPreviousCoin = false;
    if (!Number.isNaN(result) && result >= 0) {
      this.moneyStack[money] = Number.parseInt(result, 10);
      askPreviousCoin = true;
    }
    this.calcTotalMoneyValue();
    this.moneyStackChange.emit(this.moneyStack);
    if (askPreviousCoin) {
      const previousCoin = MoneyTools.getPreviousCoin(money);
      if (TypeTools.isNumber(previousCoin)) {
        requestAnimationFrame(() => {
          this.setMoneyCount(previousCoin);
        });
      }
    }
  }

  async addMoney(money: number) {
    if (!this.beforeAddMoney || await this.beforeAddMoney(money)) {
      this.moneyStack[money]++;
      this.calcTotalMoneyValue();
      this.moneyStackChange.emit(this.moneyStack);
    }
  }

  async removeMoney(money: number) {
    if (this.moneyStack[money] > 0) {
      if (!this.beforeRemoveMoney || await this.beforeRemoveMoney(money)) {
        this.moneyStack[money]--;
        this.calcTotalMoneyValue();
        this.moneyStackChange.emit(this.moneyStack);
      }
    }
  }

  calcTotalMoneyValue() {
    this.totalMoneyValue = MoneyStackTools.getTotalValueFromMoneyStack(this.moneyStack);
    if (MathTools.round(this.totalMoneyShouldValue, 2) !== this.totalMoneyValue) {
      this.totalMoneyDiffValueColor = ColorTools.Red;
    } else {
      this.totalMoneyDiffValueColor = ColorTools.Green;
    }
    this.totalMoneyDiffValue = this.totalMoneyValue - this.totalMoneyShouldValue;
  }

  ngAfterViewInit(): void {

  }
}
