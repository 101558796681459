<div class="w-full h-full flex flex-col border-1-red">
  <div class="flex flex-row w-full justify-center">
    <div>What material did you use?</div>
  </div>
  <div class="flex flex-row grow border-2-blue">
    <table class="nxt-table h-fit">
      <tr>
        <td>Name</td>
        <td>aktuelle Menge</td>
      </tr>
      <tr *ngFor="let item of inventoryItems">
        <td>
          {{ item.name }}
        </td>
        <td>
          {{ item.amount }}
        </td>
      </tr>
    </table>

  </div>
  <div>
    <nxt-button (click)="backClicked()">Back</nxt-button>
    <nxt-button>Finished</nxt-button>
  </div>
</div>
