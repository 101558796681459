import {Component, Inject, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {NxtFormControl} from '../../../nxt-form/nxt.form-control';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {ObjectTools} from '../../../common-browser/helpers/object.tools';
import {TypeTools} from '../../../common-browser/helpers/type.tools';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MathTools} from '../../../common-browser/helpers/math.tools';
import {NxtButtonComponent} from '../../button/nxt-button.component';
import {InputComponent} from '../../../components/form-controls/input/input.component';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {SlideToggleComponent} from '../../../components/form-controls/slide-toggle/slide-toggle.component';
import {TimePickerComponent} from '../../../components/form-controls/time-picker/time-picker.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf} from '@angular/common';

export interface DialogInputOptions {
  prompt?: string | number;
  selectPrompt?: boolean;
  okButtonText?: string;
  placeholder?: string;
  isMoney?: boolean;
  isNumber?: boolean;
  width?: string;
  message?: string;
  stringCanEmpty?: boolean;
  isPassword?: boolean;
  isPercent?: boolean;
  additionalButtons?: { text: string, value: string }[];
  isTime?: boolean;
  isTimeParams?: { minTime?: string, maxTime?: string };
  title?: string;
}


/**
 * @title Dialog Overview
 */
@Component({
  selector: 'nxt-dialog-input',
  templateUrl: './dialog-input.component.html',
  styleUrls: ['./dialog-input.component.scss'],
  imports: [
    NgIf,
    FlexModule,
    TimePickerComponent,
    InputComponent,
    SlideToggleComponent,
    NxtButtonComponent,
    NgFor,
    SafeHtmlPipe,
  ]
})
export class DialogInputComponent {
  @ViewChild('okButton') okButton: NxtButtonComponent;
  @ViewChild(InputComponent) inputComponent: InputComponent;

  inputFormControl: UntypedFormControl;
  private okButtonTextDynamic = true;

  constructor(
    public dialogRef: MatDialogRef<DialogInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public options: DialogInputOptions;

  isPassword = false;
  isTime = false;
  showPassword = false;
  public okButtonText = 'OK';

  setOptions(options: DialogInputOptions) {
    this.options = ObjectTools.combineWithDefaultOptions(options, {okButtonText: 'OK'});
    this.isPassword = options.isPassword;
    const required = this.options.isNumber || this.options.isMoney || !options.stringCanEmpty;
    const validators = [];
    if (required || this.isPassword) {
      validators.push(ValidatorTools.requiredAndNotNaN);
    }
    if (this.isPassword) {
      validators.push(ValidatorTools.password);
    }

    this.inputFormControl = new NxtFormControl(options.prompt ? options.prompt : '', validators);
    if (this.isPassword) {
      this.inputFormControl.valueChanges.subscribe((value) => {
        if (value?.length > 0) {
          this.inputFormControl.markAsTouched();
        }
      });
    }
    this.inputFormControl.valueChanges.subscribe((value) => {
      requestAnimationFrame(() => {
        if (this.okButtonTextDynamic && (this.options.isMoney || this.options.isPercent)) {
          if (TypeTools.isNumberAndNotNaN(value)) {
            this.okButtonText = value.toMoneyString();
          } else {
            this.okButtonText = this.options.okButtonText;
          }
        }
      });
    });

    if (this.options.okButtonText !== 'OK') {
      this.okButtonText = this.options.okButtonText;
      this.okButtonTextDynamic = false;
    }
  }

  close() {
    if (!this.inputFormControl.invalid) {
      this.dialogRef.close(this.inputFormControl.value);
    }
  }

  okClicked() {
    this.okButton.focus();

    requestAnimationFrame(() => {
      if (!this.inputFormControl.invalid) {
        if (this.options.isMoney) {
          this.dialogRef.close(MathTools.roundMoney(this.inputFormControl.value));
        } else {
          this.dialogRef.close(this.inputFormControl.value);
        }
      } else {
        this.inputComponent.focusAndSelect();
      }
    });
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  additionalButtonClick(additionalButton: { text: string; value: string }) {
    this.dialogRef.close(additionalButton.value);
  }
}
