import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DateTools} from '../common-browser/helpers/date.tools';

@Pipe({
    name: 'fromNowText',
    standalone: true
})
export class FromNowPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, onlyDays = false): string {
    if (onlyDays) {
      return DateTools.dateDiffToNowOnlyDaysText(value);

    } else {
      return DateTools.dateDiffToNowText(value);
    }
  }
}
