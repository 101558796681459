import {Injectable} from '@angular/core';
import {ArticleTools, NxtArticleOld} from '../../common-browser/helpers/article.tools';
import {ObjectTools} from '../../common-browser/helpers/object.tools';
import {DecimalTools} from '../../common-browser/helpers/decimal.tools';
import {DialogInputOptions} from '../../controls/dialog/dialog-input/dialog-input.component';
import {NxtCashPayment} from '../../common-interfaces/nxt.cash-payment.interface';
import {DateTools} from '../../common-browser/helpers/date.tools';
import * as QRCode from 'qrcode';
import {ArticleService} from '../../services/article.service';
import {DialogService} from 'src/app/services/dialog.service';
import {SocketService} from '../../services/socket/socket.service';
import {ConfigService} from '../../services/config.service';
import {TelegramService} from '../../services/telegram.service';
import {LoginService} from '../../services/login.service';
import {CashPaymentService} from '../../services/cash-payment.service';
import {PermissionService} from '../../services/permission.service';
import {NxtPermissionId} from '../../common-interfaces/nxt.user.interface';
import {WithoutNxtDbFields} from '../../common-interfaces/nxt.db-fields.interface';

@Injectable({
  providedIn: 'root',
})
export class NewIncomingOutgoingService {

  constructor(
    private articleService: ArticleService,
    private dialogService: DialogService,
    private socketService: SocketService,
    private configService: ConfigService,
    private telegramService: TelegramService,
    private loginService: LoginService,
    private cashPaymentService: CashPaymentService,
    private permissionService: PermissionService,
  ) {
  }

  async newIncomingOutgoingClicked(type: 'incoming' | 'outgoing' | 'incoming-transfer' | 'outgoing-transfer', studioCash: string, possibleValue: number, possibleSafeValue: number) {
    /*if (await this.dayFinishService.isDayFinishStarted(this.dateString, this.studioReal, this.daySection)) {
      await this.dialogService.showOk('Nicht möglich, die Abrechnung wurde bereits gestartet', {buttonText: 'Ok, logisch'});
      if (!this.permissionService.hasPermission(NxtPermissionId.SuperAdmin)) {
        return;
      }
    }*/
    let buttons: { text: string, value: any }[];
    let articles: NxtArticleOld[];
    let title = '';
    if (type === 'incoming-transfer') {
      articles = ObjectTools.clone(this.articleService.getIncomingTransferArticles(studioCash, true));
      title = 'Neuer Eingangs-Transfer erfassen';
    } else if (type === 'incoming') {
      articles = ObjectTools.clone(ArticleTools.articlesIncoming);
      title = 'Neue Einnahme erfassen';
    } else if (type === 'outgoing') {
      articles = ObjectTools.clone(ArticleTools.articlesOutgoing);
      title = 'Neue Ausgabe erfassen';
    } else if (type === 'outgoing-transfer') {
      articles = ObjectTools.clone(this.articleService.getOutgoingTransferArticles(studioCash, false));
      title = 'Neuer Ausgangs-Transfer erfassen';
    }

    if (this.permissionService.hasPermission(NxtPermissionId.DisableGlobal_)) {
      articles = articles.filter(a => !a._);
    }

    buttons = articles.map(article => {
      return {
        value: article,
        text: article.shortDescription + (article.price > -1 ? '<br/>' + DecimalTools.toMoneyString(article.price) : ''),
      };
    });

    const result = await this.dialogService.showButtonChooser<NxtArticleOld>({
      hideBackButton: true,
      buttonRows: [buttons],
      title,
      text: '',
      minWidth: '80%',
      value: '',
    });

    if (result && typeof result !== 'string' && result.value) {
      const article = result.value;

      if (article.id === '') {
        const inputOptions: DialogInputOptions = {
          okButtonText: 'weiter',
          isMoney: false,
          message: 'Gib eine Bezeichnung für die ' + (type.includes('incoming') ? 'Einnahme' : 'Ausgabe') + ' an',
          placeholder: 'Bezeichnung',
          prompt: '',
        };
        const dialog = await this.dialogService.showInputOld(inputOptions);
        const dialogResult2 = await dialog.afterClosed().toPromise();
        if (!dialogResult2) {
          return;
        }
        article.id = dialogResult2;
        article.description = dialogResult2;
        article.shortDescription = dialogResult2;
      }

      if (type === 'outgoing') {
        const items = [{value: 'direct', text: 'direkt von ' + article.description}, ...this.configService.config.value.users
          .filter(u => !u.noPerson)
          .map(u => ({text: u.username, value: u.username})).sort()];
        article.originalCreatedBy = await this.dialogService.showSelect('Wer hat dir den Beleg gegeben?', items, {allowCustomString: true});
      }

      if (!type.includes('transfer')) {
        for (const blackListWord of ['bank', 'kasse']) {
          if (article.description.toLowerCase().includes(blackListWord)) {
            this.dialogService.showOk('Kassen- oder Bank- Buchungen bitte über Transfer buchen!');
            return;
          }
        }
      }
      /*** PRICE **/
      let value = -1;
      if (article.price === -1) {
        const inputOptions: DialogInputOptions = {
          isMoney: true,
          placeholder: 'Betrag in Euro',
        };
        const dialogResult = await this.dialogService.showInput('Preis für ' + article.description, inputOptions);
        if (article.id === 'bank' && dialogResult > 1000) {
          this.dialogService.showOk('Du kannst maximal 1000 € auf einmal buchen!\nBuche genau so, wie du es von der Bank abgehoben hast!');
          return;
        }
        if (!dialogResult) {
          return;
        }
        value = dialogResult;

      } else {
        value = article.price;
      }

      let vatPercentage = -1;

      if (!article._ && type === 'outgoing' && this.configService.config.value.cashOutgoingAskVatPercentage) {
        const dialogResult = await this.dialogService.showButtons('Umsatzsteuersatz', {
          buttons: [{
            text: '0 %',
            value: 0,
          }, {text: '7 %', value: 7}, {text: '19 %', value: 19}],
        });
        if (typeof dialogResult !== 'string') {
          vatPercentage = dialogResult.value;
        }

      }

      // überprüfen, ob genug Geld in der Kasse ist!

      // const cashTotal = DecimalTools.round(this.totalIncoming - this.totalOutgoing + (this.startEndMoney.startMoney > 0 ? this.startEndMoney.startMoney : 0), 2);
      // const cashTotal_ = DecimalTools.round(this.totalIncoming_ - this.totalOutgoing_, 2);

      if (type === 'outgoing') {
        if (article._) {
          if (value > possibleSafeValue) {
            this.telegramService.sendAdmin(' will eine _ Ausgabe buchen, obwohl die _ Kasse das nicht hergibt:\n' + article.description + ': ' + DecimalTools.toMoneyString(value));
            this.dialogService.showOk('Es ist nicht genug Geld in der Kasse!');
            return;
          }
        } else {
          if (value > possibleValue) {
            this.telegramService.sendAdmin(' will eine Ausgabe buchen, obwohl die Kasse das nicht hergibt:\n' + article.description + ': ' + DecimalTools.toMoneyString(value));
            this.dialogService.showOk('Es ist nicht genug Geld in der Kasse!');
            return;
          }
        }
      }


      let cashPayment: WithoutNxtDbFields<NxtCashPayment> = {
        direction: type.includes('incoming') ? 'in' : 'out',
        articleId: article.id,
        createdAtDateString: DateTools.format(Date.now(), 'yyyy-MM-dd'),
        createdAt: Date.now(),
        createdBy: this.loginService.getUsername(),
        studio: this.loginService.getStudio(),
        // studioReal: this.loginService.getStudioReal(),
        description: article.description,
        originalCreatedBy: article.originalCreatedBy,
        bankConfirmed: false,
        value,
        _: article._,
        vatPercentage,
      };
      this.dialogService.showLoading('Einnahme wird erfasst...');
      cashPayment = await this.cashPaymentService.upsert(cashPayment);
      this.dialogService.hideLoading();

      if (type === 'outgoing' && !article._) {
        const url = window.location.origin + '/event-qr/outgoing-scan' + cashPayment.id;
        const src = await QRCode.toDataURL(url, {width: 500, color: {dark: '#ffffff', light: '#2A2A2A'}});
        let useNew = true;
        if (this.configService.config.value.studioRegion === 'MA') {
          useNew = await this.dialogService.showYesNo('Scannen oder Foto?', {yesText: 'Scannen', noText: 'Foto'});
        }
        if (useNew) {
          this.socketService.setNextDocScanWithoutQrCodeData({
            refId: cashPayment.id,
            refType: 'outgoing-scan',
          });
          const text = 'Benutze bitte den Scanner um den Beleg zu scannen, du hast 5 Minuten Zeit dafür.\n\nAuch quer, so wie die Einwilligungserklärungen\n\nBei kleinen Rechnungen benutze das Flachbett.';
          this.dialogService.showOk(text, {title: 'ACHTUNG NEU!'});
        } else {
          this.dialogService.showOk('<div style="width: 100%; text-align: center;"><br/><img src="' + src + '" /></div>', {title: 'Rechnung für Ausgabe fotografieren!'});
        }
      }
    }
  }
}
