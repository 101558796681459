import {AfterContentInit, Component, ElementRef, EventEmitter, input, Input, OnChanges, OnInit, Output, signal, SimpleChanges, ViewChild} from '@angular/core';
import {FormControlWrapper} from '../form-control-wrapper';
import Fuse, {IFuseOptions} from 'fuse.js';
import {Log} from '../../../common-browser/log/log.tools';
import {CacheListName, CacheListService} from '../../../services/cache-list.service';
import {DateControl} from '../../../controls/date-control';
import {TypeTools} from '../../../common-browser/helpers/type.tools';
import {MatInput} from '@angular/material/input';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {DisplayWithTools} from '../../../common-browser/helpers/display-with.tools';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {MatIcon} from '@angular/material/icon';
import {MatOption} from '@angular/material/core';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgIf} from '@angular/common';
import {FormFieldWrapperComponent} from '../../form-field-wrapper/form-field-wrapper.component';
import {JsonTools} from '../../../common-browser/helpers/json.tools';

@Component({
    selector: 'nxt-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
  imports: [FormFieldWrapperComponent, NgClass, ExtendedModule, NgIf, MatFormField, FlexModule, MatLabel, MatInput, FormsModule, MatAutocompleteTrigger, ReactiveFormsModule, NxtButtonIconComponent, MatError, MatAutocomplete, MatOption, MatIcon, SafeHtmlPipe],
  standalone: true,
})
export class AutocompleteComponent extends FormControlWrapper implements OnInit, AfterContentInit, OnChanges {

  @Input() set value(value: any) {
    if (this.valueField) {
      const optionToSet = this.options?.find(o => o[this.valueField] === value);
      if (optionToSet) {
        this.nxtFormControl.setValue(optionToSet);
      }
    } else {
      this.nxtFormControl.setValue(value);
    }
    this._value = value;
  }


  @ViewChild('autoComplete', {static: false}) set myInput(elementRef: any) {
    this.nxtFormControl.element = elementRef._elementRef.nativeElement;
  }

  @ViewChild(MatInput, {static: false}) set inputElement(input: any) {
    this.myInputElement = input._elementRef.nativeElement;
    $(this.myInputElement).off('keydown');
    $(this.myInputElement).on('keydown', (e) => {
      if (e.originalEvent.code === 'Enter') {
        if (this.filteredOptions.length === 0 || !this.panelOpened) {
          if (Date.now() - this.lastOptionSelectedTimestamp > 1000) {
            if (this.noEntryEnter) {
              this.noEntryEnter.emit(this.myInputElement.value);
            }
          }
        }
      }
    });
  }

  get fuse() {
    if (!this._fuse) {
      this.initFuse();
    }
    return this._fuse;
  }

  constructor(
    private cacheListService: CacheListService
  ) {
    super();
    this.displayWith = DisplayWithTools.displayWith('text');
  }

  @Input() bottomNoPadding = false;
  @Input() noPadding = false;


  @Input() showListAfterClear = true;
  @Input() minLength = 0;
  @Input() panelWidth;
  /**
   * 0 = exact
   * 1 = fuse
   * @private
   */

  @Input() dateControlIfFirstCharDigit = false;
  @Input() fuseThreshold = 0.4;
  @Input() customSearchFn: (options: any[], filterValue: string) => any[];
  @Input() customSearchAsyncFn: (options: any[], filterValue: string) => Promise<any[]>;
  @Input() selectOnClick = false;
  // public threshold = -1;
  private lastOptionSelectedTimestamp = 0;

  // @Input() disableFocusOnInit = false;

  @Input() openSelectOnInit = false;

  @Input() valueField?: string;
  @Output() valueChange = new EventEmitter<any>();

  @ViewChild('wrapper', {static: true}) wrapper: ElementRef;

  // currentOptionsLength = 0;
  private controlMouseClickCounter = 0;

  myInputElement;

  private dateControl: DateControl;

  @ViewChild(MatAutocomplete, {static: true}) matAutocomplete: MatAutocomplete;


  @ViewChild(MatAutocompleteTrigger, {static: false}) autocompleteTrigger: MatAutocompleteTrigger;


  @Input() filterService: (value: string) => Promise<any[]>;
  @Input() showClearIcon = false;
  @Input() useFuseSearch = false;
  @Input() placeholder: any;
  @Input() displayWith: ((value: any) => string) | null;
  @Input() options: any[];
  @Input() disabled = false;
  @Input() cachedTextId: CacheListName;
  @Input() minWidth ? = '';
  @Output() optionClick = new EventEmitter<MatAutocompleteSelectedEvent>();
  @Output() clickOnDisabled = new EventEmitter<void>();
  @Output() clearClicked = new EventEmitter<MatAutocompleteSelectedEvent>();
  @Output() cleared = new EventEmitter<any>();
  @Output() noEntryEnter = new EventEmitter<string>();
  @Output() newItem = new EventEmitter<string>();
  @Input() filterFields: string[];
  @Input() displayInOptionWith: ((value: any, highlightFn: (string) => string) => string) | null;
  @Input() nxtMatOptionClass: string;
  @Input() nxtMatOptionContainerClass: string;
  @Input() maxOptionsToShow = 30;

  private _fuse: Fuse<any>;

  // @Input() nxtMatOptionClass?: string;


  filteredOptions = signal<any[]>([]);
  panelOpened = false;
  filterValue = '';

  showAddCacheTextButton = false;
  showRemoveCacheTextButton = false;


  @Input() tabIndex = -1;
  private _value: any;
  optionTrackBy = input<string>();

  displayOptionWithCache = new Map<any, string>();

  onFormControlSet() {

  }


  public openPanel() {
    setTimeout(() => {
      this.autocompleteTrigger.openPanel();
    }, 0);
  }

  initFuse() {
    if (this.customSearchFn || this.customSearchAsyncFn) {
      return;
    }
    if (!this.filterFields || this.filterFields.length === 0) {
      if (this.cachedTextId) {
        this.filterFields = ['text'];
      } else {
        alert('filterFields fehlen!\n' + this.placeholder + '\n' + this.cachedTextId);
      }
    }
    const options: IFuseOptions<any> = {
      shouldSort: true,
      minMatchCharLength: 1,
      isCaseSensitive: false,
      keys: this.filterFields
    };
    if (!this.useFuseSearch) {
      options.threshold = 0.1; // 0.0 ist zu hard, sonst muss das erste wort übereinstimmen
    }
    this._fuse = new Fuse(this.options || [], options);
  }


  async ngOnInit() {
    if (this.cachedTextId) {
      this.options = (await this.cacheListService.getCacheList(this.cachedTextId));
      this.displayWith = this.cacheListService.displayCacheListItemFn;
      this.displayInOptionWith = this.cacheListService.displayCacheListItemInOptionFn;
      this.checkCacheTextButtonShow();
    }


    this.nxtFormControl.valueChanges.subscribe(async (value) => {
      this.clearDisplayOptionWithCache();
      this.search();
      if (this.valueField) {
        if (TypeTools.is(value)) {
          // da valueField ist es ein Objekt und kein String, wenn String, dann ist es manuelle eingabe
          if (TypeTools.isString(value)) {
            this._value = null;
            this.valueChange.emit(this._value);
          } else {
            if (this._value !== value[this.valueField]) {
              this._value = value[this.valueField];
              this.valueChange.emit(this._value);
            }
          }
        } else {
          if (this._value !== null) {
            this._value = null;
            this.valueChange.emit(this._value);
          }
        }
      } else {
        if (this._value !== value) {
          this._value = value;
          this.valueChange.emit(this._value);
        }
      }
    });

    this.nxtFormControl.valueChanges.subscribe((value) => {
      if (this.cachedTextId) {
        this.checkCacheTextButtonShow();
        if (value?.text && value?.lastUsed && value?.id) {
          this.nxtFormControl.setValue(value.text);
        }
      }
      Log.debug('value change autocomplete');
    });
    if (this.nxtFormControl.name) {
      this.placeholder = this.nxtFormControl.name;
    }
  }


  async search() {
    const text = this.nxtFormControl.value ?? '';
    if (typeof text === 'string') {
      this.filteredOptions.set(await this._filter(text));
    }
  }


  private _filter(value: string): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      if (this.filterService) {
        if (!value) {
          return resolve([]);
        }
        resolve(this.filterService(value));
        return;
      } else {
        let newList = [];
        this.filterValue = value.toLowerCase();
        if (this.options && this.options.length > 0 && typeof this.options[0] === 'string') {
          newList = this.options.filter(o => o.toLowerCase().includes(this.filterValue));
        } else {
          newList = await this.filterWithFuseJs(value);
        }
        if (this.maxOptionsToShow > -1 && this.maxOptionsToShow < newList.length) {
          newList.length = this.maxOptionsToShow;
        }
        resolve(newList);
      }
    });
  }

  private async filterWithFuseJs(filterValue: string) {
    return new Promise<any[]>(async (resolve, reject) => {
      if (this.customSearchAsyncFn) {
        resolve(await this.customSearchAsyncFn(this.options, filterValue));
        return;
      }

      if (this.customSearchFn) {
        resolve(this.customSearchFn(this.options, filterValue));
        return;
      }

      if (!filterValue || filterValue.length === 0) {
        if (!this.options) {
          return [];
        }
        resolve(this.options.slice());
        return;
      }

      const result = this.fuse.search(filterValue).map(m => {
        return m.item;
      });
      resolve(result);
    });
    // }
  }

  escapeRegExp(regex: string) {
    const specialChars = ['$', '^', '*', '(', ')', '+', '[', ']', '{', '}', '\\', '|', '.', '?', '/'];
    const regexSpecialChars = new RegExp('(\\' + specialChars.join('|\\') + ')', 'g');
    return regex.replace(regexSpecialChars, '\\$1');
  }

  clearDisplayOptionWithCache() {
    this.displayOptionWithCache.clear();
  }

  displayOptionWith(value?: any) {
    if (value) {
      if (value.addOption) {
        return value.text;
      }
      const cacheValue = this.displayOptionWithCache.get(value);
      if (cacheValue) {
        return cacheValue;
      }
      let tempValue = this.displayInOptionWith(value, (text) => {
        let result = text;
        if (this.filterValue) {
          const filterParts = this.escapeRegExp(this.filterValue).split(' ');
          const regex = new RegExp(filterParts.join('|'), 'gi');
          try {
            result = result.toString().replace(regex, (str) => '<mark class="autocomplete-mark">' + str + '</mark>');
            return result;
          } catch (err) {
            return err.message;
          }
        }
        return text;
      });
      if (!tempValue?.startsWith('<div')) {
        tempValue = '<div>' + tempValue + '</div>';
      }
      const result = tempValue.replace(/\n/g, '<br/>');
      this.displayOptionWithCache.set(value, result);
      return result;
    }
  }

  closed($event: void) {
    this.panelOpened = false;
  }

  opened($event: void) {
    this.panelOpened = true;
  }

  clearInput(ev: MouseEvent) {
    if (!this.showListAfterClear) {
      ev.stopPropagation();
      ev.preventDefault();
    }
    this.nxtFormControl.setValue('', {emitEvent: true, emitModelToViewChange: true, emitViewToModelChange: true});
    this.clearClicked.emit(null);
    setTimeout(() => {
      this.nxtFormControl.element.focus();

      // this.controlElemRef.nativeElement.focus();
    }, 0);
  }

  changeSearchMode() {
    this.useFuseSearch = !this.useFuseSearch;
    this.nxtFormControl.setValue(this.nxtFormControl.value);
  }

  focusout(event: FocusEvent | any) {
    if (event?.relatedTarget?.tagName?.toLowerCase() !== 'mat-option') {

    }
  }

  public closePanel() {
    this.autocompleteTrigger.closePanel();
  }


  ngAfterContentInit(): void {
    this.displayInOptionWith = this.displayInOptionWith ? this.displayInOptionWith : this.defaultDisplayInOptionWith;
  }

  private defaultDisplayInOptionWith(option: any, highlightFn: (string) => string) {
    if (typeof option === 'string') {
      return option;
    }
    if (typeof option?.text === 'string') {
      return option.text;
    }
    return JsonTools.stringify(option);
  }

  async optionSelected($event: MatAutocompleteSelectedEvent) {
    this.lastOptionSelectedTimestamp = Date.now();
    this.optionClick?.emit($event);
    this.filteredOptions.set(await this._filter(''));
  }

  async addCacheText() {
    let text = this.nxtFormControl.value;
    if (text.text) {
      text = text.text;
    }
    await this.cacheListService.addCacheListItem(this.cachedTextId, text);
    this.checkCacheTextButtonShow();
  }

  async removeCacheText() {
    let text = this.nxtFormControl.value;
    if (text.text) {
      text = text.text;
    }
    await this.cacheListService.removeCacheListItem(this.cachedTextId, text);
    this.checkCacheTextButtonShow();
  }

  private checkCacheTextButtonShow() {
    const value = this.nxtFormControl.value;
    const valueText = value?.text ? value.text : value;
    if (valueText) {
      if (this.options.find(m => m.text.toUpperCase() === valueText.toUpperCase())) {
        this.showAddCacheTextButton = false;
        this.showRemoveCacheTextButton = true;
      } else {
        this.showAddCacheTextButton = true;
        this.showRemoveCacheTextButton = false;
      }
    } else {
      this.showAddCacheTextButton = false;
      this.showRemoveCacheTextButton = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.search();
      if (!changes.options.firstChange) {
        this.initFuse();
      }
      if (this.valueField) {
        const optionToSet = this.options?.find(o => o[this.valueField] === this._value);
        if (optionToSet) {
          this.nxtFormControl.setValue(optionToSet);
        }
      }
    }
    if (changes.dateControlIfFirstCharDigit) {
      if (!this.dateControl) {
        if (changes.dateControlIfFirstCharDigit.currentValue) {
          this.dateControl = new DateControl(this.nxtFormControl, true);
        }
      } else {
        this.dateControl.enable = changes.dateControlIfFirstCharDigit.currentValue;
      }
    }
    this.clearDisplayOptionWithCache();
  }

  inputClicked(ev: MouseEvent) {
    if (this.selectOnClick) {
      (ev.target as HTMLInputElement).select();
    }
  }

  public inputWrapperClicked() {
    if (this.nxtFormControl.disabled) {
      this.clickOnDisabled.emit();
    }
  }

  focusin(ev: FocusEvent) {
  }
}

