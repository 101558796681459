export enum NxtFieldType {
  TodoType = 'TodoType',
  Boolean = 'Boolean',
  EditDynamicGrid = 'EditDynamicGrid',
  Number = 'Number',
  DurationStringToNow = 'DurationStringToNow',
  DurationStringToTimeAndToNow = 'DurationStringToTimeAndToNow',
  Taglines = 'Taglines',
  CalendarEvents = 'CalendarEvents',
  WhatsAppChat = 'WhatsAppChat',
  Array = 'Array',
  GiftcardAssigned = 'GiftcardAssigned',
  Date_germanMonthString = 'Date_germanMonthString',
  ShowCalendarEvent = 'ShowCalendarEvent',
  ShowContactHistory = 'ShowContactHistory',
  ShowEventHistory = 'ShowEventHistory',
  ShowImage = 'ShowImage',
  Date_germanMonth = 'Date_germanMonth',
  PaymentSum = 'PaymentSum',
  Money = 'Money',
  MoneyFull = 'MoneyFull',
  MoneyShort = 'MoneyShort',
  Decimal = 'Decimal',
  MoneyOnlyPositiv = 'MoneyOnlyPositiv',
  MoneyOnlyPositivShort = 'MoneyOnlyPositivShort',
  Priority = 'Priority',
  // FireDate = 'FireDate',
  Text = 'Text',
  Date_germanDate = 'Date_germanDate',
  Date_germanDateShort = 'Date_germanDateShort',
  Date_germanDateShortWithDayOfWeek = 'Date_germanDateShortWithDayOfWeek',
  Date_germanDateWithDayOfWeek = 'Date_germanDateWithDayOfWeek',
  Date_germanDateTimeWithDayOfWeek = 'Date_germanDateTimeWithDayOfWeek',
  Date_germanDateWithToNowText = 'Date_germanDateWithToNowText',
  Date_germanDateTime = 'Date_germanDateTime',
  Date_germanDateTimeFull = 'Date_germanDateTimeFull',
  Date_germanTime = 'Date_germanTime',
  Studio = 'Studio',
  Date_germanDateTimeWithSeconds = 'Date_germanDateTimeWithSeconds',
  PaymentType = 'PaymentType',
  Date_germanDayOfWeek = 'Date_germanDayOfWeek',
  PaymentMethod = 'PaymentMethod',
  Loading = 'Loading',
  Percentage = 'Percentage',
  Workplace = 'Workplace',
  Duration = 'Duration',
  Icon = 'Icon'
}

export interface NxtFieldTypeParams {
  TodoType: {};
  Boolean: {};
  EditDynamicGrid: {};
  Number: {};
  DurationStringToNow: { withoutSeconds?: boolean, pastPrefix?: 'seit' | 'vor' | string, futurePrefix?: string };
  DurationStringToTimeAndToNow: {};
  Taglines: {};
  CalendarEvents: {};
  WhatsAppChat: {};
  Array: {};
  GiftcardAssigned: {};
  Date_germanMonthString: {};
  ShowCalendarEvent: {};
  ShowContactHistory: {};
  ShowEventHistory: {};
  ShowImage: {};
  Date_germanMonth: {};
  PaymentSum: {};
  Money: {};
  MoneyShort: {};
  Decimal: {};
  MoneyOnlyPositiv: {};
  MoneyOnlyPositivShort: {};
  Priority: {};
  Text: {};
  Date_germanDate: {};
  Date_germanDateShort: {};
  Date_germanDateShortWithDayOfWeek: {};
  Date_germanDateWithDayOfWeek: {};
  Date_germanDateTimeWithDayOfWeek: {};
  Date_germanDateWithToNowText: { withoutSeconds?: boolean, pastPrefix?: 'seit' | 'vor', futurePrefix?: string };
  Date_germanDateTime: {};
  Date_germanDateTimeFull: {};
  Date_germanTime: {};
  Studio: {};
  Date_germanDateTimeWithSeconds: {};
  PaymentType: {};
  Date_germanDayOfWeek: {};
  PaymentMethod: {};
  Loading: {};
  Percentage: {};
  Workplace: {};
  Duration: {};
  Icon: {};
}


export class NxtField {

  public static getCellValue(data: any, fields: string, params): any {
    try {
      let value;
      for (const field of fields.split('.')) {
        if (value) {
          value = value[field];
        } else {
          value = data[field];
        }
      }
      return value;
    } catch (err) {
      if (params.getValue) {
        return params.getValue();
      }
      return '_?_';
    }
  }

}
