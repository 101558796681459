<div fxLayout="column" fxLayoutAlign="center center" style="width: fit-content;">
  <table class="money-table">
    <tr *ngFor="let money of moneys">
      <td>
        <div fxLayoutAlign="center center">
          <!--<img [ngStyle]="{margin: '4px', width: getMoneyWidth(money, 0.8) + 'px'}" [src]="'assets/money/' + money + '.png'"/>-->
          <img [ngStyle]="{margin: '4px', height: getMoneyHeight(money, 0.04) + 'vh'}" [src]="'assets/money/' + money + '.png'"/>
        </div>
      </td>
      <td style="text-align: center">
        <nxt-button-icon (click)="removeMoney(money)">remove_circle</nxt-button-icon>
      </td>
      <td style="width: 50px; text-align: center; cursor: pointer;" (click)="setMoneyCount(money)">
        &nbsp;&nbsp;{{moneyStack[money]}}&nbsp;&nbsp;
      </td>
      <td style="text-align: center">
        <nxt-button-icon (click)="addMoney(money)">add_circle</nxt-button-icon>
      </td>
      <td class="no-wrap" style="min-width: 120px; text-align: right;">
        <ng-container *ngIf="moneyStack[money] * money > 0">
          = {{moneyStack[money] * money | money}}
        </ng-container>
        <ng-container *ngIf="moneyStack[money] * money === 0">
          -
        </ng-container>
      </td>
    </tr>
  </table>
  <table class="value-table" style="width:100%; text-align: center;">
    <tr>
      <td [nxtMultiClick]="3" (nxtOnMultiClick)="showShould = !showShould">IST</td>
      <td *ngIf="showShould">SOLL</td>
      <td *ngIf="showShould">DIFFERENZ</td>
    </tr>
    <tr>
      <td [ngStyle]="{color: totalMoneyValueColor, textAlign:'center'}">{{totalMoneyValue | money}}</td>
      <td *ngIf="showShould" [ngStyle]="{color: totalMoneyShouldValueColor, textAlign:'right'}">{{totalMoneyShouldValue | money}}</td>
      <td *ngIf="showShould" [ngStyle]="{color: totalMoneyDiffValueColor, textAlign:'right'}">{{totalMoneyDiffValue > 0 ? '+' : ''}}{{totalMoneyDiffValue | money}}</td>
    </tr>
  </table>
</div>
