<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>Messenger</nxt-page-header-title>

  </nxt-page-header>
  <nxt-page-content>
    <nxt-messenger-chat-list/>


  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>
