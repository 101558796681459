<div class="flex h-full w-full flex-col" style="background-color: transparent;">
  <div class="z-10 flex w-full flex-row justify-between p-3 p-absolute">
    <div></div>
    <div class="flex flex-row items-center gap-4 icons">
      <!--<nxt-button-icon (click)="closeClicked()">close</nxt-button-icon>
      <nxt-button-icon (click)="closeClicked()">close</nxt-button-icon>-->
      <mat-icon matTooltip="Teilen" fontSet="material-symbols-outlined" class="cursor-pointer share-icon" (click)="shareClicked()">share</mat-icon>
      <mat-icon matTooltip="Herunterladen" fontSet="material-symbols-outlined" class="cursor-pointer" (click)="downloadClicked()">download</mat-icon>
      <mat-icon matTooltip="Schließen" fontSet="material-symbols-outlined" class="cursor-pointer" (click)="closeClicked()">close</mat-icon>
    </div>
  </div>
  @if (src() && mimeType()) {


    <div class="flex h-full w-full flex-col">
      <div class="flex h-full w-full justify-center items-center" style="background: transparent; overflow: hidden; border:0px solid blue;">
        <video #videoPlayer class="video-js vjs-default-skin" controls preload="auto" playsinline>
          <source [src]="src()" [type]="mimeType()">
        </video>
      </div>
    </div>


    <!--<div class="flex h-full w-full" style="background: yellow; overflow: hidden; border:3px solid blue;">
      <video #videoPlayer class="video-js vjs-default-skin" controls preload="auto" playsinline
             style="max-height: 100%; max-width: 100%; border: 3px solid red;">
        <source [src]="src()" [type]="mimeType()">
      </video>
    </div>-->
  }
</div>
