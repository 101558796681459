<div style="height:100%; width:100%" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="center">
  <div>{{showDoubleNumbers ? this.contactsWithEventsDouble.length : this.contactsWithEvents?.length}} Kunden</div>
  <nxt-slide-toggle [(value)]="showDoubleNumbers" (valueChange)="showDoubleNumbersChanged()"></nxt-slide-toggle>
  </div>
  <nxt-input placeholder="Suche" [(value)]="quickFilterText" width="100%"></nxt-input>

  <nxt-datagrid
    *ngIf="contactsWithEvents && columnDefs"
    fxFlex
    [columnDefs]="columnDefs"
    [rowData]="showDoubleNumbers ? contactsWithEventsDouble : contactsWithEvents"
    [quickFilterText]="quickFilterText"
    [showSideBar]="false"
  >
  </nxt-datagrid>
  <div fxLayout="row" fxLayoutAlign="space-around center" style="margin-top:5px;">
    <nxt-button (click)="close()">schließen</nxt-button>
  </div>

</div>

