import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {FormGroup} from '@angular/forms';
import {NxtTypedFormControl} from '../../../../nxt-form/nxt.typed-form-control';
import {InputComponent} from '../../../form-controls/input/input.component';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtStudioFeeCost} from '../../../../common-interfaces/nxt.studio-fee.interface';
import {UuidTools} from '../../../../common-browser/helpers/uuid.tools';
import {DialogService} from '../../../../services/dialog.service';
import {FormTools} from '../../../../services/form.tools';
import {ValidatorTools} from '../../../../helpers/validator.tools';

@Component({
    selector: 'nxt-studio-fee-cost-edit',
    templateUrl: './studio-fee-cost-edit.component.html',
    styleUrls: ['./studio-fee-cost-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        InputComponent,
        NxtButtonComponent
    ]
})
export class StudioFeeCostEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  itemsSafe = ['Gehalt (angestellt)', 'Reinigung', 'Getränke'];
  itemsNormal = ['Gehalt (angestellt)', 'Miete', 'Reinigung', 'Nebenkosten', 'Werbung', 'Versicherung', 'Gebühren (PP, etc)', 'Stuff', 'Sonstiges'];

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  isNewCost = signal(false);
  isSafe = signal(false);

  /*** Injections ***/
  dialogRef = inject(MatDialogRef<StudioFeeCostEditComponent>);
  dialogService = inject(DialogService);

  form = new FormGroup({
    id: new NxtTypedFormControl('', [], 'ID'),
    description: new NxtTypedFormControl('', [ValidatorTools.requiredAndNotNaN], 'Beschreibung'),
    value: new NxtTypedFormControl<number>(null, [ValidatorTools.requiredAndNotNaN], 'Betrag'),
  });


  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  setData(cost: NxtStudioFeeCost, isSafe: boolean) {
    this.isSafe.set(isSafe);
    this.isNewCost.set(!cost.id);
    if (!cost.id) {
      cost.id = UuidTools.generate();
    }
    this.form.setValue(cost);
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  saveClicked() {
    if (FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      this.dialogRef.close(this.form.getRawValue());
    }
  }

  async descriptionClicked() {
    const buttons = this.isSafe() ? this.itemsSafe : this.itemsNormal;
    if (this.form.controls.description.value) {
      if (!buttons.includes(this.form.controls.description.value)) {
        buttons.push(this.form.controls.description.value);
      }
    }
    const result = await this.dialogService.showButtonChooser({title: 'Beschreibung', buttonRows: [buttons]});
    if (result) {
      if (result === 'Sonstiges') {
        const result = await this.dialogService.showInput('Beschreibung');
        if (result.toLowerCase() === 'gesamt') {
          // this.dialogService.showOk('Beschreibung darf nicht "Gesamt" sein');
          return;
        }
        if (result) {
          this.form.controls.description.setValue(result);
        }
      } else {
        this.form.controls.description.setValue(result.toString());
      }
    }
  }
}
