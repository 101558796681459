<div class="w-full h-100 background-color">
</div>
<div class="w-full h-100 background-image">
</div>
<div fxLayout="column" fxLayoutAlign="center" [ngClass]="{'nxt-page': true, 'wrap2per': true, 'new-style': newStyle}" style="z-index: 2">
  <div class="flex flex-col items-center justify-center gap-3 p-3 p-relative md:flex-row">
    <nxt-slide-toggle fxHide.lt-sm [(value)]="newStyle" (valueChange)="safeStyle()">Whats-App-Style</nxt-slide-toggle>
    <nxt-slide-toggle fxHide.lt-sm [(value)]="onlyFromCustomer" (valueChange)="onlyFromCustomerChanged()">Nur vom Kunden</nxt-slide-toggle>
    <div class="ml-4 flex flex-row items-center gap-5">
      <!--<nxt-button *ngIf="chat && chat.unreadCount > 0" (click)="setRead()">gelesen</nxt-button>-->
      <nxt-button-icon (click)="copyUrl()">content_copy</nxt-button-icon>
      <nxt-button-icon matTooltip="PayPal-Zahlung anfordner" (click)="paypal()">paid</nxt-button-icon>
      <nxt-button-icon matTooltip="Bank-Zahlung anfordner" (click)="bankClicked()">account_balance</nxt-button-icon>
      <nxt-button-icon matTooltip="Artist-Galerie senden" (click)="sendArtistGallery()">photo_library</nxt-button-icon>
      <nxt-button-icon matTooltip="Standort senden" (click)="sendLocation()">location_on</nxt-button-icon>
      <nxt-button-icon matTooltip="Chat neu einlesen" (click)="reloadChatClicked()">refresh</nxt-button-icon>
    </div>
    <div class="flex flex-row gap-4">
      @if (isUnread()) {
        <div class="flex flex-col items-center">
          <div class="red">ist ungelesen</div>
          <nxt-button (click)="setReadClicked()">ALS GELESEN</nxt-button>
        </div>
      } @else {
        <div class="flex flex-col items-center">
          <div>ist gelesen</div>
          <nxt-button (click)="setUnReadClicked()">ALS UNGELESEN</nxt-button>
        </div>
      }
      @if (chat?.doRecheck) {
        <div class="flex flex-col items-center">
          <div class="red">check ist an!!!</div>
          <nxt-button (click)="setCheckClicked(false)">CHECK AUS</nxt-button>
        </div>
      } @else {
        <div class="flex flex-col items-center">
          <div>check ist aus</div>
          <nxt-button (click)="setCheckClicked(true)">CHECK AN</nxt-button>
        </div>
      }
      @if (pinnedChat?.pinned) {
        <div class="flex flex-col items-center">
          <div class="red">ist fixiert</div>
          @if (pinnedChat.remindAgainAt > 0) {
            <div class="pb-1 text-[80%] red">in {{ pinnedChat.remindAgainAt | fromNowText: true }}</div>
          }
          <nxt-button (click)="setPinnedClicked()">LÖSEN</nxt-button>
        </div>
      } @else {
        <div class="flex flex-col items-center">
          <div>ist nicht fixiert</div>
          <nxt-button (click)="setPinnedClicked()">FIX</nxt-button>
        </div>
      }
    </div>
    <div class="flex flex-grow flex-col items-center justify-center">
      <div>{{ title }}</div>
      @if (isUnread()) {
        <div class="red">Ungelesen</div>
      }
    </div>
    <div fxLayout="row" fxLayoutGap="30px">
      <nxt-input placeholder="Suche" class="ml-3" [clearIcon]="true" [(value)]="filterText" (valueChange)="filterTextChanged()"></nxt-input>
      @if (dialogRef) {
        <nxt-button-icon (click)="close()">close</nxt-button-icon>
      }
    </div>
  </div>
  @if (contact && contact.taglines && contact.taglines.length > 0) {
    <div class="flex flex-row gap-3 w-full justify-center">
      @for (tagline of contact.taglines; track tagline) {
        <div [innerHTML]="tagline | safeHtml">
        </div>
      }
    </div>
  }
  <div *ngIf="bodyPutsTattoo">
    <div *ngFor="let bodyPutTattoo of bodyPutsTattoo" fxLayout="row" class="w-full text-120" fxLayoutGap="10px" fxLayoutAlign="center">
      <div>{{ bodyPutTattoo.motive }}</div>
      <div>{{ bodyPutTattoo.bodyPut | bodyPutText: 'de' }}</div>
      <div>{{ bodyPutTattoo.size }}</div>
    </div>
  </div>
  <div *ngIf="contact && contactMobiles && contactMobiles.length > 1" class="flex justify-center pb-2 row w-fill">
    <div *ngFor="let number of contactMobiles">
      <nxt-button [ngClass]="{'mobile-button-active': number.m === currentWhatAppId}"
                  (click)="loadOtherMobile(number.m)">{{ number.t === -1 ? 'Aktuelle Nummer' : ('vor dem ' + (number.t | nxtDate: 'dd.MM.yyyy')) }}
      </nxt-button>
    </div>
  </div>
  <div *ngIf="message" class="w-full text-120" fxLayout="row" fxLayoutAlign="center center" style="height: 200px;">
    <div>{{ message }}</div>
  </div>
  <div fxFlex class="h-100 o-hidden" *ngIf="messages" [ngStyle]="{visibility: visibility}">
    <!--<virtual-scroller #scrollElem class="w-full h-100" #scroll [items]="messages">-->
    <!--<cdk-virtual-scroll-viewport autosize class="w-full h-100">-->
    <!--<div fxLayout="row" (contextmenu)="copyToClipboard(message.id)" *ngFor="let message of scroll.viewPortItems">-->

    <div infiniteScroll
         class="w-full p-3 scrollDiv h-100"
         fxFlex
         style="overflow: auto"
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         (scrolledUp)="scrolledUp()"
         [scrollWindow]="false">
      <div fxLayout="column"

           *ngFor="let message of messagesVisible"
      >

        <div *ngIf="message.hoursToLastMessage !== 0" fxLayoutAlign="center center" class="day-divider" (dblclick)="copyConversationAfter(message)">
          <div class="line"></div>
          <div class="middle" fxLayoutAlign="center">
            <ng-container *ngIf="message.hoursToLastMessage !== -1">
              {{ (message.hoursToLastMessage) }} {{ message.hoursToLastMessage === 1 ? 'Tag' : 'Tage' }}
            </ng-container>
            <ng-container *ngIf="message.hoursToLastMessage === -1">
              Bitte warten...
            </ng-container>
          </div>
        </div>
        <div fxLayout="row">
          <div *ngIf="message.fromMe" fxFlex fxLayout="row" fxLayoutAlign="end center">
            <div class="button-box">
              <div class="checkbox-wrapper">
                <nxt-checkbox matTooltip="Auswählen" matTooltipPosition="left" [(value)]="selectedMessages[message.id]"></nxt-checkbox>
              </div>
              <div class="icon-wrapper mouse-pointer" matTooltip="Weiterleiten" matTooltipPosition="left">
                <mat-icon (click)="forwardToArtist(message)">shortcut</mat-icon>
              </div>
              <div class="icon-wrapper mouse-pointer" matTooltip="Tattoo-Vorlage" matTooltipPosition="left" *ngIf="!!imagesSrc[message.id]?.src">
                <mat-icon (click)="setImageAsTemplateClicked(message, $event);">photo_library</mat-icon>
              </div>
            </div>
          </div>
          <nxt-context-menu #messageContextMenu>
            <button mat-menu-item (click)="translateMessage(message)">
              <mat-icon>translate</mat-icon>
              <span>Übersetzen</span>
            </button>
            <button mat-menu-item (click)="showMessageId(message)">
              <mat-icon>content_copy</mat-icon>
              <span>Message ID</span>
            </button>
          </nxt-context-menu>
          <div
            (contextmenu)="messageContextMenu.show($event)"
            [ngClass]="{message: true, 'message-in': !message.fromMe, 'message-out': message.fromMe, 'message-selected': selectedMessages[message.id]}"
            [id]="'message-' + message.id"
          >
            <div *ngIf="message.quotedStanzaID" class="response-message mouse-pointer" (click)="jumpToMessage(message.quotedStanzaID)">
              <div>{{ message.quotedMsg.timestamp | nxtDate: 'dd.MM.yyyy' }}</div>

              <div *ngIf="message.quotedMsg.type === 'image'" fxLayout="column">
                <img [src]="'data:' + message.quotedMsg.mimetype + ';base64,' + message.quotedMsg.body" style="width: fit-content">
                <div>{{ message.quotedMsg.caption }}</div>
              </div>
              <div class="p-4" *ngIf="message.quotedMsg.type === 'chat'" fxLayout="column">
                <div [innerHTML]="message.quotedMsg.body | safeHtml"></div>
              </div>
              <div *ngIf="message.quotedMsg.type !== 'chat' && message.quotedMsg.type !== 'image'" fxLayout="column">
                <div>{{ message.quotedMsg.type }}</div>
              </div>
              <div *ngIf="false">{{ message.quotedMsg | json }}</div>
            </div>


            <div class="date" [innerHtml]="message.dateHtml | safeHtml">
            </div>

            <div fxLayout="column" class="can-select">
              @if (message.hasMedia && message.type === 'image') {
                <div fxLayout="column"
                >
                  <div fxLayoutAlign="center center" class="chat-image-placeholder" *ngIf="!imagesSrc[message.id]?.src && !imagesSrc[message.id]?.notInDb">
                    Bild
                    wird geladen...
                  </div>
                  <div fxLayoutAlign="center center" class="chat-image-placeholder" *ngIf="imagesSrc[message.id]?.notInDb">
                    Bild ist noch nicht verfügbar
                    <nxt-button-icon [ngClass]="{rotate: reloadRunning[message.id]}" (click)="reloadImage(message)">refresh</nxt-button-icon>
                  </div>
                  <div fxFlex class="image-box" [fxLayout]="message.fromMe ? 'row-reverse' : 'row'" fxLayoutAlign="start center"
                       *ngIf="!!imagesSrc[message.id]?.src">
                    <img (click)="showImage(message)" class="chat-image mouse-pointer" [src]="imagesSrc[message.id]?.src"/>
                  </div>
                  @if (message.body) {
                    <div class="mt-1 text word-break" [innerHTML]="message.body | safeHtml"></div>
                  }
                  <div nxtObserveVisibility (visible)="preloadImage(message)" style="height: 2px"></div>
                </div>
              } @else if (message.hasMedia && message.type !== 'image') {
                <div class="red" fxLayout="column" *ngIf="message.hasMedia && message.type !== 'image'">
                  Die Nachricht vom Typ "{{ message.typeText }}" kann noch nicht angezeigt / abgespielt werden,<br/>kommt aber bald ;)
                </div>
              } @else if (message.body) {
                <div class="text word-break" [innerHTML]="message.body | safeHtml"></div>
              } @else if (message.type === 'pinned_message') {
                <div class="text-[75%]">hat eine Nachricht angepinnt</div>
              } @else {
                <div class="text word-break">unbekannter Typ: {{ message.type }}</div>
              }
            </div>
            <div *ngIf="message.detectedSourceLangName" class="text-70">(original: {{ message.detectedSourceLangName }})</div>
          </div>

          <div *ngIf="!message.fromMe" fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div class="button-box">
              <div class="checkbox-wrapper">
                <nxt-checkbox matTooltip="Auswählen" matTooltipPosition="right" [(value)]="selectedMessages[message.id]"></nxt-checkbox>
              </div>
              <div class="icon-wrapper mouse-pointer">
                <mat-icon (click)="forwardToArtist(message)" matTooltip="Weiterleiten" matTooltipPosition="right">shortcut</mat-icon>
              </div>
              <!--<div nxtPermission="IsBackoffice" class="icon-wrapper mouse-pointer" *ngIf="!!imagesSrc[message.id]?.src">
                <mat-icon (click)="addToSendImages(message)">image</mat-icon>
              </div>-->
              <div class="icon-wrapper mouse-pointer" matTooltip="Tattoo-Vorlage" matTooltipPosition="right" *ngIf="!!imagesSrc[message.id]?.src">
                <mat-icon (click)="setImageAsTemplateClicked(message, $event);">photo_library</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--</virtual-scroller>-->
    <!--</cdk-virtual-scroll-viewport>-->
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" class="text-input-bar">
    <div fxFlex="80%" fxLayout="row">
      <textarea
          #textareaTextInput
          (keyup)="textAreaKeyUp($event)"
          (keydown)="textAreaKeyDown($event)"
          #textareaAutoSize="TextareaAutoSizeDirective"
          nxtTextareaAutoSize
          style="color: #fff; background-color: #464646; overflow: hidden; border-radius: 4px; font-size: 130%; width:100%; resize: none; padding:10px; font-family: 'Open Sans', sans-serif;"
          [(ngModel)]="textToSend">
      </textarea>
    </div>
    <div class="pl-3">
      <nxt-button-icon (click)="send()">send</nxt-button-icon>
    </div>
  </div>
</div>
