import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {InputComponent} from '../../form-controls/input/input.component';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtPageComponent} from '../../nxt-page/nxt-page.component';
import {NxtPageContentComponent} from '../../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {NxtPageHeaderComponent} from '../../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageHeaderTitleComponent} from '../../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtBankBookingAccount} from '../../../common-interfaces/bank/bank-transaction.interface';
import {BankTransactionTools} from '../../../common-browser/helpers/bank-transaction.tools';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {IconTools} from '../../../common-browser/helpers/icon.tools';

@Component({
  selector: 'nxt-bank-booking-accounts',
  templateUrl: './bank-booking-accounts.component.html',
  styleUrls: ['./bank-booking-accounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    InputComponent,
    NxtDatagridComponent,
    NxtPageComponent,
    NxtPageContentComponent,
    NxtPageHeaderComponent,
    NxtPageHeaderTitleComponent,
    NxtPageFooterComponent,
  ],
})

export class BankBookingAccountsComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  quickFilterText = signal('');
  bookingAccounts = signal<NxtBankBookingAccount[]>([]);


  /*** Computes ***/


  /*** Injections ***/
  private socketService = inject(SocketService);
  dialogRef = inject(MatDialogRef, {optional: true});

  /*** Injections ***/
  columnDefs: NxtColDef<NxtBankBookingAccount>[] = [
    {headerName: '', nxtFieldType: NxtFieldType.Icon, nxtIcon: IconTools.Material.Edit, nxtOnCellClicked: params => this.accountEditClicked(params.data)},
    {headerName: 'Name', field: 'name'},
    {headerName: 'Konto', field: 'datevNo'},
    {headerName: 'Typ', field: 'type', valueGetter: params => BankTransactionTools.getTypeText(params.data.type)},
  ];


  /*** Variable ***/

  constructor() {
    super();
  }

  ngOnInit() {
    this.load().then();
  }

  nxtOnDestroy() {
  }

  async load() {
    this.bookingAccounts.set(await this.socketService.bank.getBookingAccounts());
  }

  isLoading() {
    return false;
  }

  private accountEditClicked(data: NxtBankBookingAccount) {

  }
}
