import {Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Directive({
    selector: '[nxtLongPress]',
    standalone: true
})
export class LongPressDirective implements OnInit, OnDestroy {
  @Input() public nxtLongPress = 500;
  @Output() public nxtOnLongPressed: EventEmitter<MouseEvent> = new EventEmitter();

  private mouseDownTimeout: any;

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    clearInterval(this.mouseDownTimeout);
  }

  @HostListener('mouseup', ['$event'])
  public onMouseUp(event: MouseEvent): void {
    clearInterval(this.mouseDownTimeout);
  }

  @HostListener('mouseout', ['$event'])
  public onMouseOut(event: MouseEvent): void {
    clearInterval(this.mouseDownTimeout);
  }

  @HostListener('touchend', ['$event'])
  public onTouchEnd(event: MouseEvent): void {
    clearInterval(this.mouseDownTimeout);
  }

  @HostListener('mousedown', ['$event'])
  public onMouseDown(event: MouseEvent): void {
    this.mouseDownTimeout = setTimeout(() => {
      this.nxtOnLongPressed.emit();
    }, this.nxtLongPress);
  }

  @HostListener('touchstart', ['$event'])
  public onTouchStart(event: MouseEvent): void {
    this.mouseDownTimeout = setTimeout(() => {
      this.nxtOnLongPressed.emit();
    }, this.nxtLongPress);
  }
}
