import {Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';

@Injectable({
  providedIn: 'root'
})
export class UserActiveTrackService {

  private lastActive = 0;

  constructor(
    private socketService: SocketService
  ) {
  }

  setIsActive() {
    if (Date.now() - this.lastActive > 5000) {
      this.lastActive = Date.now();
      // this.socketService.setUserActive();
    }
  }
}
