import {inject, Injectable} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {ConfigService} from '../../services/config.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeEventService {
  /*** Injections ***/
  private socketService = inject(SocketService);
  private configService = inject(ConfigService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  getWorkTimeButtons(): { text: string, fromTime: string, tillTime: string }[] {
    if (this.configService.config.value.studioRegion === 'BR') {
      return [
        {text: '17 - 2', fromTime: '17:00', tillTime: '02:00'},
        {text: '16 - 0', fromTime: '16:00', tillTime: '00:00'},
      ];
    }
    return [
      {text: '11 - 15:30', fromTime: '11:00', tillTime: '15:30'},
      {text: '15:30 - 20', fromTime: '15:30', tillTime: '20:00'},
      {text: '10 - 18', fromTime: '10:00', tillTime: '18:00'},
      {text: '11 - 20', fromTime: '11:00', tillTime: '20:00'},
    ];
  }

  getCalendarHours() {
    if (this.configService.config.value.studioRegion === 'BR') {
      return {start: 11, end: 24};
    }
    return {start: 9, end: 24};
  }
}
