<table *ngIf="tattooTicketItems().length > 0 && shopOrder()" class="nxt-table w-full" style="border-width: 3px">
  <tr>
    <td colspan="2" class="text-center !p-0">
      <div class="relative pt-4 pb-2">
        <div class="absolute left-2 top-1">{{ shopOrder().createdAt |nxtDate: 'dd.MM.yyyy HH:mm' }}</div>
        <div class="absolute right-2 top-1">{{ shopOrder().createdAt | fromNowText }}</div>
        <div class="font-bold text-130">{{ buyerName() }}</div>
        <div class="font-bold text-120">Tattoo-Ticket {{ originalValue() | money }}</div>
        <div class="red" *ngIf="refundValue() > 0">Rückerstattet: {{ refundValue() | money }}</div>
        @if (allAssigned()) {
          <div class="flex flex-row items-center justify-center green">
            <mat-icon style="font-size: 20px; width: 20px; height: 20px">check</mat-icon>
            <div>&nbsp;Alle Zahlungen zugewiesen&nbsp;</div>
            <mat-icon style="font-size: 20px; width: 20px; height: 20px">check</mat-icon>
          </div>
        } @else {
          <div class="flex flex-row items-center justify-center green">
            <div>{{ openValue() | money }} offen</div>
          </div>
        }
        <div class="pt-2" *ngIf="originalValue() > refundValue()">
          <nxt-button [smallButton]="true" (click)="doRefund.emit(tattooTicketItems()[0].shopOrderLineItemId!)">Rückerstatten</nxt-button>
        </div>
        <div class="pt-2">
          <nxt-button [smallButton]="true" (click)="showShopOrderClicked(shopOrder().id)">Shop-Kauf anzeigen</nxt-button>
        </div>
      </div>
    </td>
  </tr>
  @for (item of tattooTicketItems(); track item.shopOrderLineItemId) {
    <tr>
      <td class="!p-0">
        <nxt-tattoo-ticket-item
          class="w-full"
          [buyerName]="buyerName()"
          [item]="item"
          (showEvent)="showEventClicked($event)"
          [fromEventId]="fromEventId()"
        >
        </nxt-tattoo-ticket-item>
      </td>
    </tr>
  }
</table>
