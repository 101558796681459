<div *ngIf="message" class="nxt-dialog-content">
  <div>
    <mat-icon *ngIf="icon" aria-hidden="false">{{ icon }}</mat-icon>
    <div class="titleText" [innerHTML]="titleText | safeHtml"></div>
  </div>
  <div style="margin-bottom:20px; margin-top:10px;" [innerHTML]="message() | safeHtml">
  </div>
  <div style="justify-content: space-around; display:flex; padding: 1px;">
    <nxt-button *ngIf="buttonText()" [disabled]="okButtonDisabled" style="margin: auto;" (click)="close()">
      <div style="min-width: 50px" [innerHTML]="buttonText() | safeHtml"></div>
    </nxt-button>
  </div>
</div>
