<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>Kassen</nxt-page-header-title>
  </nxt-page-header>
  <nxt-page-content>
    <div class="flex flex-row h-full gap-3 justify-center">
      <div class="flex flex-col w-1/2">
        <div class="flex flex-col w-full h-full">
          <div class="flex flex-row justify-between p-2">
            <div>Kassen</div>
            <nxt-button nxtPermission="MoneyAccount_Admin" (click)="upsertAccount()">Neue Kasse</nxt-button>
          </div>
          <nxt-datagrid
            [columnDefs]="accountColumnDefs"
            [rowData]="moneyAccounts()"
            (rowDoubleClickNew)="accountClicked($event.data)"
          />
        </div>
        <div nxtPermission="MoneyAccount_Admin" class="flex flex-col w-full h-full">
          <div class="flex flex-row justify-between p-2">
            <div>Regionen</div>
            <nxt-button (click)="upsertRegion()">Neue Region</nxt-button>
          </div>
          <nxt-datagrid
            [columnDefs]="regionColumnDefs"
            [rowData]="regions()"
          />
        </div>
      </div>
      <div nxtPermission="MoneyAccount_Admin" class="flex flex-col w-1/2">
        <div class="flex flex-col w-full h-full">
          <div class="flex flex-row justify-between p-2">
            <div>Kategorien</div>
            <nxt-button (click)="upsertCategory()">Neue Kategorie</nxt-button>
          </div>
          <nxt-datagrid
            [columnDefs]="categoryColumnDefs"
            [rowData]="categories()"
          />
        </div>
        <div class="flex flex-col w-full h-full">
          <div class="flex flex-row justify-between p-2">
            <div>Label</div>
            <nxt-button (click)="upsertLabel()">Neues Label</nxt-button>
          </div>
          <nxt-datagrid
            [columnDefs]="labelColumnDefs"
            [rowData]="labels()"
          />
        </div>
      </div>
    </div>
  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>
