import {ChangeDetectionStrategy, Component, inject, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtStudioCashReportData} from '../../common-interfaces/nxt.work-session';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {SocketService} from '../../services/socket/socket.service';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {TimePickerComponent} from '../form-controls/time-picker/time-picker.component';
import {DatePickerComponent} from '../form-controls/date-picker/date-picker.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {AsyncPipe, DatePipe, NgFor} from '@angular/common';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {StudioCashReport2Service} from '../studio-cash-report-2/studio-cash-report-2.service';
import {DialogService} from '../../services/dialog.service';

@Component({
  selector: 'nxt-work-session-edit',
  templateUrl: './work-session-edit.component.html',
  styleUrls: ['./work-session-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FlexModule, DatePickerComponent, TimePickerComponent, NgFor, NxtButtonComponent, DatePipe, NxtDatePipe, AsyncPipe]
})

export class WorkSessionEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor(
    @Optional() private dialogRef: MatDialogRef<any>,
    private socketService: SocketService,
  ) {
    super();
  }

  dialogService = inject(DialogService);

  data: NxtStudioCashReportData;
  // workSession: WithoutNxtDbFields<NxtWorkSession>;
  startTime = '';
  endTime = '';

  public myService: StudioCashReport2Service;


  ngOnInit() {
    this.pushSubscription = this.myService.data$.subscribe(data => {
      this.data = data;
      this.startTime = DurationTools.format(this.data.workSession.startAt - this.data.workSession.startAt.clearTime(), 'HH:mm');
      this.endTime = DurationTools.format(this.data.workSession.endAt - this.data.workSession.endAt.clearTime(), 'HH:mm');
      this.calcFullDays();
    });
  }

  nxtOnDestroy() {

  }

  cancelClicked() {
    this.dialogRef.close();
  }

  calcFullDays() {
    this.data.workSession.startAt = (this.data.workSession.startAtDateString + ' ' + this.startTime + ':00').dateParse();
    this.data.workSession.endAt = (this.data.workSession.endAtDateString + ' ' + this.endTime + ':00').dateParse();
    this.data.workSession.additionalDateStrings = [];
    const dayDiff = DateTools.dayDiff(this.data.workSession.endAt, this.data.workSession.startAt);
    for (let i = 1; i < dayDiff; i++) {
      const dateString = this.data.workSession.startAt.dateAddDays(i).dateFormat('yyyy-MM-dd');
      if (!this.data.workSession.additionalDateStrings.includes(dateString)) {
        this.data.workSession.additionalDateStrings.push(dateString);
      }
    }
  }

  async saveClicked() {
    this.data.workSession.startAt = (this.data.workSession.startAtDateString + ' ' + this.startTime + ':00').dateParse();
    this.data.workSession.endAt = (this.data.workSession.endAtDateString + ' ' + this.endTime + ':00').dateParse();
    this.calcFullDays();
    await this.socketService.workSessionUpdateWorkSession(this.data.workSession);
    this.dialogRef.close();
  }

  async additionalEventIdClicked(additionalEventId: string) {
    const eventText = this.getEventText(additionalEventId);
    if (await this.dialogService.showYesNo(eventText + '\n\nTermin aus zusätzlichen Terminen entfernen?')) {
      this.data.workSession.additionalEventIds = this.data.workSession.additionalEventIds.filter(id => id !== additionalEventId);
      await this.socketService.workSessionUpdateWorkSession(this.data.workSession);
    }
  }

  getEventText(eventId: string) {
    const event = this.myService.data.events.find(e => e.id === eventId);
    if (event) {
      const hasPayout = event.payments.some(p => p.paymentType === 'payout');
      return event.startDateString + ' | ' + event.artist + ' | ' + event.invoiceNumber + ' | ' + hasPayout + ' | ' + event.id;
    }
    return '?';
  }

  async newAdditionalEventClicked() {
    const eventId = await this.dialogService.showInput('neue EventID');
    if (eventId) {
      if (!this.data.workSession.additionalEventIds.includes(eventId)) {
        this.data.workSession.additionalEventIds.push(eventId);
        await this.socketService.workSessionUpdateWorkSession(this.data.workSession);
      }
    }
  }
}
