<div *ngIf="!formPayments">formPayments not set !</div>
<mat-card class="!pt-3" *ngIf="formPayments">
  @if (forceSaveHack()) {
    <div
      style="padding-bottom: 5px"
      [ngStyle]="{color: removePaymentsEnabled ? 'Red' : ''}"
      [nxtMultiClick]="3" (nxtOnMultiClick)="adminClicked()"
    >{{ titleText }}
    </div>
  }
  <nxt-smooth-height [trigger]="getPayments().length">
    <div fxLayout="row" *ngFor="let payment of getPayments(); let i = index" [fxLayoutGap]="controlGap">
      <nxt-col fxFlex.gt-sm="145px" *ngIf="paymentTypes && paymentTypes.length > 0">

        <nxt-select
          [nxtFormControl]="payment.get('paymentType')"
          placeholder="Typ"
          [options]="paymentTypes"
          (valueChange)="paymentTypeChange(payment, 'template')"
          optionHeight="40px"
          panelClass="full-panel-height"
        >
        </nxt-select>
      </nxt-col>

      <!-- DATUM -->
      <nxt-col *ngIf="payment.get('paymentType').value">
        <nxt-date-picker
          [nxtFormControl]="payment.get('paymentDate')"
          [showTodayButton]="false"
          placeholder="Datum bezahlt"
        ></nxt-date-picker>
      </nxt-col>


      <nxt-col fxFlex.gt-sm="137px" *ngIf="!removePaymentsEnabled && payment.get('paymentType').value">
        <nxt-input
          placeholder="Zahlung erstellt"
          [disabled]="true"
          [value]="payment.get('createdAt').value | nxtDate: 'dd.MM.yyyy HH:mm'"
        ></nxt-input>
      </nxt-col>

      <!-- CREATED-AT -->
      <nxt-col fxFlex.gt-sm="15" *ngIf="removePaymentsEnabled && payment.get('paymentType').value">
        <nxt-input
          placeholder="createdAt"
          [nxtFormControl]="payment.get('createdAt')"
        ></nxt-input>
        {{ DateTools.format(payment.get('createdAt').value, 'yyyy-MM-dd HH:mm:ss') }}
      </nxt-col>


      <!-- ZAHLART -->
      <nxt-col fxFlex="110px" *ngIf="payment.get('paymentType').value">
        <nxt-select
          [nxtFormControl]="payment.get('paymentMethod')"
          placeholder="Zahlart"
          requiredError="Zahlart bitte angeben!"
          [options]="payment.get('isNewPayment').value ? (payment.get('paymentType').value === 'deposit-back' ? possiblePaymentMethodsDepositBack : possiblePaymentMethods) : paymentMethods"
          (valueChange)="paymentMethodChange(payment)"
          panelClass="full-panel-height"
        >
        </nxt-select>
      </nxt-col>

      <nxt-col fxFlex="10" *ngIf="payment.get('paymentType').value">
        <nxt-input
          placeholder="Betrag"
          [nxtFormControl]="payment.get('paymentValue')"
          [isMoney]="true"
          requiredError="Brudi, ohne Betrag geht nicht"
          (valueChange)="paymentValueChange(payment)"
        ></nxt-input>
      </nxt-col>

      <nxt-col fxFlex *ngIf="!payment.get('paymentMethod').value">
      </nxt-col>

      <nxt-col fxFlex style="min-width: 150px" *ngIf="payment.get('paymentMethod').value === 'paypal'">

        <div class="payment-icon-wrapper" *ngIf="payment.get('paymentPaypalTransaction')?.value?.canRefund" fxLayout="column" fxLayoutAlign="center">
          <mat-icon matTooltip="Rückerstatten" (click)="refundPaypal(payment.getRawValue())">
            undo
          </mat-icon>
        </div>

        <div class="payment-icon-wrapper" *ngIf="payment.get('paymentPaypalTransaction')?.value?.shopOrderLineItemId" fxLayout="column" fxLayoutAlign="center">
          <mat-icon
            matTooltip="Tattoo-Ticket anzeigen"
            (click)="showTattooTicket(payment.get('paymentPaypalTransaction')?.value?.shopOrderLineItemId, 'paypal')">
            payments
          </mat-icon>
        </div>
        <div class="payment-icon-wrapper" *ngIf="payment.get('paymentPaypalTransaction')?.value?.method === 'direct'" fxLayout="column" fxLayoutAlign="center">
          <mat-icon
            matTooltip="Tattoo-Ticket anzeigen"
            (click)="showPaypalDirectInfo()">
            payments
          </mat-icon>
        </div>
        <nxt-autocomplete
          requiredError="Habibi, wähle eine Paypalzahlung aus!"
          minWidth="300px"
          [placeholder]="payment.get('paymentType').value === 'deposit-back' ? 'PayPal-Rückzahlung' : 'Paypal-Zahlung'"
          [displayWith]="displayPaypalTransactionFn"
          [displayInOptionWith]="displayPaypalTransactionOptionFn"
          optionTrackBy="id"
          [options]="payment.get('paymentType').value === 'deposit-back' ? notAssignedPaypalTransactionsOut : notAssignedPaypalTransactionsIn"
          [nxtFormControl]="payment.get('paymentPaypalTransaction')"
          nxtMatOptionClass="paypal-option"
          [filterFields]="['from', 'message', 'value', 'transactionTime', 'payerEmail', 'requestBy']"
          (optionClick)="paypalTransactionChange(payment)"
          [showClearIcon]="false"
          [maxOptionsToShow]="20"
        ></nxt-autocomplete>
      </nxt-col>


      <nxt-col fxFlex *ngIf="payment.get('paymentMethod').value === 'klarna'">
        <div class="flex flex-col justify-center payment-icon-wrapper" *ngIf="payment.get('paymentKlarnaOrder')?.value?.shopOrderLineItemId">
          <mat-icon
            class="mouse-pointer"
            matTooltip="Info zur Klarna Zahlung"
            (click)="showTattooTicket(payment.get('paymentKlarnaOrder').value?.shopOrderLineItemId, 'klarna_payments')">
            payments
          </mat-icon>
        </div>
        <nxt-autocomplete
          requiredError="Abi, Klarna bitte auswählen!"
          minWidth="300px"
          placeholder="Klarna-Zahlung"
          [displayWith]="displayKlarnaOrderFn"
          [displayInOptionWith]="displayKlarnaOrderOptionFn"
          [options]="payment.get('paymentType').value === 'deposit-back' ? notAssignedKlarnaOrdersOut : notAssignedKlarnaOrdersIn"
          [nxtFormControl]="payment.get('paymentKlarnaOrder')"
          nxtMatOptionClass="paypal-option"
          [filterFields]="['fullName', 'value']"
          (optionClick)="klarnaOrderChange(payment)"
          [showClearIcon]="false"
          [maxOptionsToShow]="20"
        ></nxt-autocomplete>
      </nxt-col>

      <!-- GUTSCHEINE -->
      <nxt-col fxFlex *ngIf="payment.get('paymentMethod').value === 'gift-card'">
        <div *ngIf="payment.get('paymentGiftCard').value?.id" fxLayout="column" fxLayoutAlign="center">
          <mat-icon style="position: relative; top: -5px" class="mouse-pointer" matTooltip="Gutschein-Details anzeigen"
                    (click)="showGiftCard(payment.get('paymentGiftCard').value)">info
          </mat-icon>
        </div>
        <nxt-autocomplete
          requiredError="Olum, du musst schon ein Gutschein auswählen!"
          minWidth="300px"
          placeholder="Gutschein"
          [displayWith]="displayGiftCardFn"
          [displayInOptionWith]="displayGiftCardOptionFn.bind(this, payment)"

          [nxtFormControl]="payment.get('paymentGiftCard')"
          nxtMatOptionClass="option-two-lines"
          (optionClick)="giftCardChange(payment)"
          [showClearIcon]="false"
          [customSearchAsyncFn]="searchGiftCard"
        ></nxt-autocomplete>
      </nxt-col>

      <nxt-col fxFlex *ngIf="payment.get('paymentMethod').value === 'bank'">
        <div class="payment-icon-wrapper" *ngIf="payment.get('paymentBankTransaction').value?.id" fxLayout="column" fxLayoutAlign="center">
          <mat-icon
            matTooltip="Bank-Zahlung anzeigen"
            (click)="showBankPaymentInfoClicked(payment.get('paymentBankTransaction').value)">
            account_balance
          </mat-icon>
        </div>
        <nxt-autocomplete
          requiredError="Kollege, du hier wählen Bank machen musst!"
          minWidth="300px"
          placeholder="Banküberweisung"
          [displayWith]="displayBankTransactionFn"
          [displayInOptionWith]="displayBankTransactionOptionFn"
          [options]="payment.get('paymentType').value === 'deposit-back' ? notAssignedBankTransactionsOut : notAssignedBankTransactionsIn"
          [nxtFormControl]="payment.get('paymentBankTransaction')"
          nxtMatOptionClass="option-auto-height"
          [filterFields]="['name', 'value', 'createdAt', 'additionalInfo']"
          (optionClick)="bankTransactionChange(payment)"
          [showClearIcon]="false"
          [maxOptionsToShow]="20"
        ></nxt-autocomplete>
        <div nxtPermission="CalendarEventEdit_ShowBankDepositQrCode"
             *ngIf="payment.get('paymentBankTransaction').value?.id && payment.get('paymentType').value !== 'deposit-back' && payment.get('paymentValue').value > 0">
          <nxt-button-icon (click)="paymentService.showBankQr(payment)">qr_code_2</nxt-button-icon>
        </div>
      </nxt-col>

      <!-- INFO ZUR ZAHLUNG -->
      <nxt-col fxFlex *ngIf="payment.get('paymentMethod').value === 'cash'">
        <nxt-input placeholder="Info zur Zahlung" [nxtFormControl]="payment.get('paymentComment')"></nxt-input>
      </nxt-col>

      <nxt-col [fxHide.lt-md]="true" *ngIf="payment.get('paymentType').value" style="width:110px; min-width:110px">
        <nxt-select
          [nxtFormControl]="payment.get('studio')"
          placeholder="Kasse"
          [options]="studios"
        >
        </nxt-select>
      </nxt-col>

      <nxt-col [fxHide.lt-md]="true" style="width:100px; min-width:100px" *ngIf="removePaymentsEnabled && payment.get('paymentType').value">
        <nxt-select
          [nxtFormControl]="payment.get('studioReal')"
          placeholder="Studio"
          [options]="studiosWithoutOnlyCash"
        >
        </nxt-select>
      </nxt-col>

      <nxt-col [fxHide.lt-md]="true" *ngIf="removePaymentsEnabled" style="width:100px; min-width:100px">
        <nxt-select
          [nxtFormControl]="payment.get('workplace')"
          placeholder="Arbeitsplatz"
          [options]="workplaces"
        >
        </nxt-select>
      </nxt-col>

      <nxt-col [fxHide.lt-md]="true" style="width:100px">
        <nxt-input
          placeholder="Benutzer"
          [nxtFormControl]="payment.get('createdBy')"
        >
        </nxt-input>
      </nxt-col>

      <nxt-col *ngIf="removePaymentsEnabled" style="width:80px">
        <nxt-checkbox
          label="isNew"
          [nxtFormControl]="payment.get('isNewPayment')"
        >
        </nxt-checkbox>
      </nxt-col>
      @if (payment.get('paymentMethod').value === 'cash' && payment.get('paymentType').value !== 'payout') {
        <nxt-button-icon matTooltip="Beleg drucken" (click)="printCustomerReceipt.emit(payment.get('paymentUuid').value)">print
        </nxt-button-icon>
      } @else {
        <div style="width: 38px;">&nbsp;</div>
      }

    </div>
    @if (loginService.getWorkplace() === 'backoffice') {
      <div>
        <div fxFlex="100%" class="short-buttons">
          <nxt-button *ngIf="possiblePaymentMethodsHas('paypal') && !hasPaymentType['complete'] && !hasPaymentType['deposit']"
                      (click)="addPayment('deposit', 'paypal')">Kaution - PayPal
          </nxt-button>
          <nxt-button *ngIf="possiblePaymentMethodsHas('bank') && !hasPaymentType['complete'] && !hasPaymentType['deposit']"
                      (click)="addPayment('deposit', 'bank')">Kaution -
            Bank
          </nxt-button>
          <nxt-button *ngIf="possiblePaymentMethodsHas('klarna') && !hasPaymentType['complete'] && !hasPaymentType['deposit']"
                      (click)="addPayment('deposit', 'klarna')">
            Kaution
            - Klarna
          </nxt-button>
          <nxt-button *ngIf="possiblePaymentMethodsHas('gift-card') && !hasPaymentType['complete'] && !hasPaymentType['deposit']"
                      (click)="addPayment('deposit', 'gift-card')">Kaution - Gutschein
          </nxt-button>
          <nxt-button *ngIf="possiblePaymentMethodsHas('gift-card') && showRest" (click)="addPayment('rest', 'gift-card')">Rest - Gutschein</nxt-button>
          <nxt-button *ngIf="possiblePaymentMethodsHas('paypal') && loginService.getWorkplace() === 'reception' && showRest"
                      (click)="addPayment('rest', 'paypal')">Rest - PayPal
          </nxt-button>
          <nxt-button
            *ngIf="possiblePaymentMethodsHas('klarna') && !hasPaymentType['complete'] && !hasPaymentType['deposit']"
            (click)="addPayment('complete', 'klarna')">
            Gesamt
            - Klarna
          </nxt-button>
          <nxt-button *ngIf="possiblePaymentMethodsHas('gift-card') && !hasPaymentType['deposit'] && !hasPaymentType['rest'] && !hasPaymentType['complete']"
                      (click)="addPayment('complete', 'gift-card')">Gesamt - Gutschein
          </nxt-button>
        </div>
      </div>
    }
    @if (loginService.getWorkplace() === 'reception') {
      <div>
        <div fxFlex="100%" class="short-buttons">
          @if (!hasPaymentType['deposit'] && !hasPaymentType['complete'] && !hasPaymentType['rest']) {
            <!--GESAMT-->
            <nxt-button (click)="addPayment('complete', 'cash')">Gesamt - Kasse</nxt-button>
            <nxt-button *ngIf="possiblePaymentMethodsHas('paypal')"
                        (click)="addPayment('complete', 'paypal')">Gesamt - PayPal
            </nxt-button>
            <nxt-button *ngIf="possiblePaymentMethodsHas('gift-card')"
                        (click)="addPayment('complete', 'gift-card')">Gesamt - Gutschein
            </nxt-button>
            <nxt-button *ngIf="possiblePaymentMethodsHas('klarna')"
                        (click)="addPayment('complete', 'klarna')">Gesamt - Klarna
            </nxt-button>
            <div class="w-[20px]"></div>
          }

          <!--KAUTION-->
          @if (!hasPaymentType['complete'] && !hasPaymentType['deposit']) {
            <nxt-button (click)="addPayment('deposit', 'cash')">Kaution - Kasse</nxt-button>
            <nxt-button *ngIf="possiblePaymentMethodsHas('paypal')"
                        (click)="addPayment('deposit', 'paypal')">Kaution - PayPal
            </nxt-button>
            <nxt-button *ngIf="possiblePaymentMethodsHas('gift-card')"
                        (click)="addPayment('deposit', 'gift-card')">Kaution - Gutschein
            </nxt-button>
            <nxt-button *ngIf="possiblePaymentMethodsHas('klarna')"
                        (click)="addPayment('deposit', 'klarna')">Kaution - Klarna
            </nxt-button>
            <div class="w-[20px]"></div>
          }

          <!--REST-->
          @if (showRest) {
            <nxt-button (click)="addPayment('rest', 'cash')">Rest - Kasse</nxt-button>
            <nxt-button *ngIf="possiblePaymentMethodsHas('gift-card')" (click)="addPayment('rest', 'gift-card')">Rest - Gutschein</nxt-button>
            <nxt-button *ngIf="possiblePaymentMethodsHas('paypal')"
                        (click)="addPayment('rest', 'paypal')">Rest - PayPal
            </nxt-button>
            <nxt-button *ngIf="possiblePaymentMethodsHas('klarna')"
                        (click)="addPayment('rest', 'klarna')">Rest - Klarna
            </nxt-button>
          }
        </div>
      </div>
    }
    <!--<nxt-row>
      <div>

      </div>
    </nxt-row>-->
    <nxt-row>
      <div class="flex flex-row p-2">
        <!-- BUTTONS -->
        <nxt-button-icon *ngIf="this.formPayments.controls.length > 0" (click)="removeLastPayment()" matTooltip="letzte Zahlung entfernen">remove
        </nxt-button-icon>
        &nbsp;&nbsp;
        <nxt-button-icon *ngIf="enableAddPayment" (click)="addPayment()" matTooltip="neue Zahlung eintragen">add</nxt-button-icon>
      </div>

      <div class="flex flex-row justify-around flex-grow" style="border:0 solid red">
        @if (toPayOnEventDatePayed().length > 0) {
          <div class="flex" style="border:0px solid blue">
            <table>
              <tr>
                <td colspan="2" class="text-center">bereits Bezahlt</td>
              </tr>
              <tr>
                <td>hier im Termin:</td>
                <td class="pl-3"><strong>{{ paymentValueSum() | money }}</strong></td>
              </tr>
              @for (payed of toPayOnEventDatePayed(); track payed) {
                <tr>
                  <td (click)="toPayOnEventDatePayedClicked(payed)">{{ payed.text }}:</td>
                  <td (click)="toPayOnEventDatePayedClicked(payed)" class="pl-3"><strong>{{ payed.value | money }}</strong></td>
                  <td class="pl-3">
                    <mat-icon class="cursor-pointer" [matTooltip]="payed.text + ' Rückerstatten'" (click)="refundToPayOnEventDatePayedClicked.emit(payed)">
                      undo
                    </mat-icon>
                  </td>
                </tr>
              }
              <tr>
                <td>GESAMT:</td>
                <td class="pl-3"><strong>{{ customerPayedAndToPayOnEventDatePayed() | money }}</strong></td>
              </tr>
            </table>
          </div>
        } @else {
          <div class="flex" *ngIf="paymentValueSum() > -1" style="border:0 solid red">
            <table>
              <tr>
                <td>von Kunde gezahlt:</td>
                <td><strong>&nbsp;&nbsp;&nbsp;{{ paymentValueSum() | money }}</strong></td>
              </tr>
              @if (paymentValueRealSum() !== paymentValueSum()) {
                <tr class="text-[90%]">
                  <td>nach Rabatt:</td>
                  <td class="text-right"><strong>{{ paymentValueRealSum() | money }}</strong></td>
                </tr>
              }
            </table>
          </div>
        }
        <div class="flex flex-row justify-start" *ngIf="customerToPay() > 0 || toPayOnEventDateNotPayed().length > 0" style="margin: 0 10px">
          <div class="flex flex-col text-right">
            @if (toPayOnEventDateNotPayed().length > 0) {
              <table>
                <tr>
                  <td>noch zu kassieren hier im Termin:</td>
                  <td class="pl-3"><strong [class.red]="customerToPay() > 0" [class.green]="customerToPay() == 0">{{ customerToPay() | money }}</strong></td>
                </tr>
                @for (toPay of toPayOnEventDate(); track toPay) {
                  <tr [matTooltip]="'Speicher den Termin um ' + toPay.text + ' zu buchen'">
                    <td>{{ toPay.text }}:</td>
                    <td class="pl-3"><strong class="red">{{ toPay.value | money }}</strong></td>
                  </tr>
                }
                <tr>
                  <td>GESAMT:</td>
                  <td class="pl-3"><strong class="red">{{ customerToPayAndToPayOnEventDate() | money }}</strong></td>
                </tr>
              </table>

              <!--<div (click)="test123()">noch zu kassieren im Termin: <strong>{{ customerToPay() | money }}</strong></div>
              @for (toPay of toPayOnEventDate(); track toPay) {
                <div>{{ toPay.text }}: <strong>{{ toPay.value | money }}</strong></div>
              }
              <div>GESAMT: <strong>{{ customerToPayAndToPayOnEventDate() | money }}</strong></div>-->
            } @else {
              <div>noch zu kassieren:&nbsp;&nbsp;<strong class="red">{{ customerToPay() | money }}</strong></div>
            }
          </div>
        </div>

        <div class="flex" *ngIf="priceEstimatedFrom() - paymentValueSum() < 0" style="margin: 0 10px;">
          mehr gezahlt: <strong>{{ paymentValueSum() - priceEstimatedFrom() | money }}</strong>
        </div>

        <div class="flex" *ngIf="paymentValueArtist > 0" style="margin: 0 10px;">
          Artist: <strong>{{ round(paymentValueArtist / paymentValueSum() * 100) }} %</strong>
          Studio: <strong>{{ 100 - round(paymentValueArtist / paymentValueSum() * 100) }} %</strong>
        </div>
      </div>
    </nxt-row>
  </nxt-smooth-height>
</mat-card>
