@if (originalPreTransaction(); as originalPreTransaction) {
  <div class="flex flex-col">
    <div>Transaktion annehmen</div>
    <div class="mb-4">{{ originalPreTransaction.info }}</div>
    <div class="flex flex-col">

      <nxt-autocomplete
        [options]="categories()"
        [nxtFormControl]="form.controls.category"
        [filterFields]="['name']"
        [displayWith]="DisplayWithTools.displayWithName"
        [displayInOptionWith]="DisplayWithTools.displayWithName"
      />

      <nxt-autocomplete
        [options]="regions()"
        [nxtFormControl]="form.controls.region"
        [filterFields]="['name']"
        [displayWith]="DisplayWithTools.displayWithName"
        [displayInOptionWith]="DisplayWithTools.displayWithName"
      />
      <div class="flex flex-row justify-around items-center">
        <div>{{ originalPreTransaction.transaction.direction }}</div>
        <div>{{ originalPreTransaction.transaction.value | money }}</div>
      </div>
      <div>
        <nxt-textarea class="w-[350px]" [nxtFormControl]="form.controls.info"></nxt-textarea>
      </div>
    </div>
    <div class="flex flex-row justify-around w-full">
      <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
      <nxt-button (click)="saveClicked()">Speichern</nxt-button>
    </div>
  </div>
}
