import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {JsonTools} from '../common-browser/helpers/json.tools';

@Pipe({
    name: 'jsonFormatted',
    standalone: true
})
export class JsonFormattedPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(obj: any) {
    if (obj) {
      return JsonTools.stringifyFormat(obj, '·').replace(/\n/g, '<br/>').replace(/·/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    }
    return typeof obj;
  }
}
