export enum TelegramGroupsEnum {
  Admin = 'admin',
  StudioInfo = 'studioInfo',
  Error = 'error',
  DayFinish = 'dayFinish',
  Deposit = 'deposit',
  EventArtistInfos = 'eventArtistInfos',
  AvailableArtists = 'availableArtists'
}


export class TelegramTools {
  public static getGroupDescription(key: string) {
    switch (key) {
      case TelegramGroupsEnum.EventArtistInfos:
        return 'TERMINE / ARTISTS';
      case TelegramGroupsEnum.AvailableArtists:
        return 'VERFÜGBARE ARTISTS';
      case TelegramGroupsEnum.StudioInfo:
        return 'STUDIO INFO';
      case TelegramGroupsEnum.DayFinish:
        return 'ABRECHNUNG / BANK';
      case TelegramGroupsEnum.Deposit:
        return 'KAUTIONEN';
      case TelegramGroupsEnum.Admin:
        return 'ADMIN';
      case TelegramGroupsEnum.Error:
        return 'ERROR';
    }
    return 'UNBEKANNT (' + key + ')';
  }

  static parseMobile(mobile: string, withPlus = false) {
    mobile = mobile.replace('+', '');
    mobile = mobile.replace(/\s/g, '');
    if (mobile.indexOf('0') === 0) {
      mobile = '49' + mobile.substring(1);
    }
    if (withPlus) {
      return '+' + mobile;
    }
    return mobile;
  }
}
