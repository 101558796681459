import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit, Optional, ViewChild} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {NxtReminder, NxtReminderRefType} from '../../../common-interfaces/nxt.reminder.interface';
import {FormTools} from '../../../services/form.tools';
import {SocketService} from '../../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {DatePickerComponent} from '../../form-controls/date-picker/date-picker.component';
import moment, {Moment} from 'moment';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {TimePickerComponent} from '../../form-controls/time-picker/time-picker.component';
import {NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtTypedFormControl} from '../../../nxt-form/nxt.typed-form-control';
import {ReminderTools} from '../../../common-browser/helpers/reminder.tools';
import {ConfigService} from '../../../services/config.service';
import {DisplayWithTools} from '../../../common-browser/helpers/display-with.tools';
import {TextareaComponent} from '../../form-controls/textarea/textarea.component';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {PermissionDirective} from '../../../directives/permission.directive';

@Component({
    selector: 'nxt-reminder-edit',
    templateUrl: './reminder-edit.component.html',
    styleUrls: ['./reminder-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FlexModule, NgIf, DatePickerComponent, TimePickerComponent, NxtButtonComponent, TextareaComponent, MatButtonToggleGroup, MatButtonToggle, ReactiveFormsModule, SlideToggleComponent, PermissionDirective]
})

export class ReminderEditComponent implements OnInit, AfterViewInit {

  constructor(
    private socketService: SocketService,
    @Optional() private dialogRef: MatDialogRef<any>,
    private dialogService: DialogService,
    private configService: ConfigService
  ) {

  }

  @ViewChild(DatePickerComponent) datePickerComponent: DatePickerComponent;
  isNewReminder = true;

  form = new FormGroup(
    {
      id: new NxtTypedFormControl('', [], 'ID'),
      reminderDate: new NxtTypedFormControl<Moment>(null, [], 'Datum'),
      reminderTime: new NxtTypedFormControl<string>(null, [], 'Zeit'),
      description: new NxtTypedFormControl('', [ValidatorTools.requiredAndNotNaN], 'Aufgabe'),
      toDoByWorkplace: new NxtTypedFormControl<'backoffice' | 'reception'>(null, [], 'Arbeitsplatz'),
      toDoByUser: new NxtTypedFormControl<string>('', [], 'Benutzer'),
      refType: new NxtTypedFormControl<NxtReminderRefType>(NxtReminderRefType.None, [], 'RefType'),
      done: new NxtTypedFormControl<boolean>(false, [], 'Erledigt'),
      canPostpone: new NxtTypedFormControl<boolean>(false, [], 'Verschiebbar'),
      maxPostpones: new NxtTypedFormControl<number>(0, [], 'Maximale Verschiebungen'),
      postponeDurations: new NxtTypedFormControl<number[]>([], [], 'Verschiebmöglichkeiten'),
      responseExpected: new NxtTypedFormControl<boolean>(false, [], 'Antwort erwartet'),
      priority: new NxtTypedFormControl<number>(null, [], 'Priorität'),
      refId: new NxtTypedFormControl('', []),
    }
  );
  private currentReminder: NxtReminder;

  userOptions: string[] = this.configService.config.value.users.filter(u => !u.noPerson).sortString('username').map(u => u.username);
  workplaceOptions = [{text: 'Backoffice', value: 'backoffice'}, {text: 'Empfang', value: 'reception'}];

  protected readonly DisplayWithTools = DisplayWithTools;
  dateTimeIn = 0;

  studioStartTimeString = DurationTools.format(this.configService.config.value.studioWorkStart, 'HH:mm');
  todoByRef: 'backoffice' | 'user' | 'reception' = 'user';

  postponeDurationOptions = [
    {value: DurationTools.DURATION_1MINUTE * 30, text: '30 Min'},
    {value: DurationTools.DURATION_1HOUR, text: '1 Std'},
    {value: DurationTools.DURATION_1HOUR * 2, text: '2 Std'},
    {value: DurationTools.DURATION_1HOUR * 4, text: '4 Std'},
    {value: DurationTools.DURATION_1DAY, text: '1 Tag'},
  ];

  ngAfterViewInit(): void {

  }

  setValidators() {
    this.form.controls.toDoByWorkplace.setValidators([]);
    this.form.controls.toDoByUser.setValidators([]);
    if (this.todoByRef === 'user') {
      this.form.controls.toDoByUser.setValidators([ValidatorTools.requiredAndNotNaN]);
    }
    this.form.controls.toDoByWorkplace.updateValueAndValidity({emitEvent: false});
    this.form.controls.toDoByUser.updateValueAndValidity({emitEvent: false});
  }

  ngOnInit() {
    this.form.controls.toDoByUser.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.toDoByWorkplace.setValue(null);
      }
    });
    this.form.controls.toDoByWorkplace.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.toDoByUser.setValue('');
      }
    });

  }

  load(nxtReminder: NxtReminder) {
    this.currentReminder = nxtReminder;
    this.isNewReminder = !nxtReminder.id;
    this.fillForm(nxtReminder);
    this.setValidators();
  }

  fillForm(reminder: NxtReminder) {
    FormTools.setForm(this.form, reminder);
  }

  getReminderFromForm() {
    const rawValue = {...this.form.getRawValue(), reminderDate: DateTools.parse(this.form.getRawValue().reminderDate)};
    const reminder = {...ReminderTools.getEmptyReminder(), ...rawValue} as NxtReminder;
    reminder.reminderDateTime = (DateTools.format(reminder.reminderDate, 'yyyy-MM-dd') + ' ' + rawValue.reminderTime + ':00').dateParse();
    return reminder;
  }

  async save() {
    if (!FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      return;
    }
    const reminder = this.getReminderFromForm();

    const reminderToSave = {...this.currentReminder, ...reminder} as NxtReminder;
    if (this.isNewReminder) {
      reminderToSave.priority = 100;
      await this.socketService.createReminder(reminderToSave);
    } else {
      await this.socketService.updateReminder(reminderToSave);
    }
    this.dialogRef.close();
  }

  async done() {
    await this.socketService.setReminderDone(this.currentReminder.id, false);
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  newReminder(options?: { eventId?: string }) {
    this.currentReminder = ReminderTools.getEmptyReminder();
    this.form.controls.reminderDate.setValue(moment(Date.now()));
    this.form.controls.reminderTime.setValue(DateTools.formatNow('HH:mm'));
    this.form.controls.maxPostpones.setValue(10);
    this.form.controls.priority.setValue(100);
    this.form.controls.postponeDurations.setValue([this.postponeDurationOptions[0].value]);
    if (options?.eventId) {
      this.form.controls.refId.setValue(options.eventId);
      this.form.controls.refType.setValue(NxtReminderRefType.Event);
    }
    this.setValidators();
  }

  dateTimeInChanged() {
    let dateTime = Date.now() + DurationTools.DURATION_1MINUTE * this.dateTimeIn;
    if (this.dateTimeIn === -1) {
      dateTime = DateTools.todayAt(this.studioStartTimeString).dateAddDays(1);
    }
    this.form.controls.reminderTime.setValue(dateTime.dateFormat('HH:mm'));
    this.form.controls.reminderDate.setValue(moment(dateTime));
  }

  todoByRefChanged() {
    this.form.controls.toDoByWorkplace.setValue(null);
    this.form.controls.toDoByUser.setValue(null);
    if (this.todoByRef === 'backoffice') {
      this.form.controls.toDoByWorkplace.setValue('backoffice');
    } else if (this.todoByRef === 'reception') {
      this.form.controls.toDoByWorkplace.setValue('reception');
    }
    this.setValidators();
  }
}
