import {Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {DialogService} from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentReminderService {
  constructor(
    private socketService: SocketService,
    private dialogService: DialogService
  ) {
  }

  defaultText = '';

  public async showEditAppointmentReminderText() {
    let prompt = await this.socketService.getCustomerAppointmentReminderMessageTemplate();
    if (!prompt) {
      prompt = this.defaultText;
    }
    const dialog = await this.dialogService.showTextareaOld({
      message: '<strong>TERMINERINNERUNG</strong> - {{kunde_name}} {{uhr_icon}} {{termin_datum}} {{termin_uhrzeit}} {{datum_uhrzeit}} {{studio_adresse}} {{einverständniserklärung_link}}',
      placeholder: '',
      prompt,
      /*charCounterFn: (text) => {
        const divisor = text.length <= 160 ? 160 : 153;
        let rest = text.length % divisor;
        let messageCount = Math.floor(text.length / divisor);
        if (rest === 0) {

        } else {
          messageCount++;
          rest = divisor - rest;
        }
        return 'Zeichen: ' + text.length + ' | ' + rest + ' verbleibende Zeichen | ' + messageCount + ' Nachrichten';
      }*/
    });
    const result = await dialog.afterClosed().toPromise();
    if (result) {
      this.socketService.setCustomerAppointmentReminderMessageTemplate(result);
    }
  }
}
