import {Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {LoginService} from '../../services/login.service';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgIf, NgStyle} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-brudi-tv',
    templateUrl: './brudi-tv.component.html',
    styleUrls: ['./brudi-tv.component.scss'],
    imports: [FlexModule, NgIf, NgStyle, ExtendedModule]
})
export class BrudiTvComponent implements OnInit {

  @ViewChild('videoElement', {static: false}) set videoComponent(videoComponent: ElementRef) {
    if (videoComponent) {
      this.video = videoComponent.nativeElement;
      this.video.addEventListener('ended', () => {
        this.videoEnd.emit();
        this.visibility = 'hidden';
        this.show = false;
      });

      this.video.addEventListener('loadeddata', () => {
        const videoSize = this.video.videoWidth / this.video.videoHeight;
        const windowSize = window.innerWidth / window.innerHeight;
        if (videoSize > windowSize) {
          this.videoWidth = '90%';
          this.videoHeight = 'auto';
        } else {
          this.videoWidth = 'auto';
          this.videoHeight = '90%';
        }
        this.video.play();
        this.visibility = 'visible';
      });
    }
  }


  constructor(
    private socketService: SocketService,
    private loginService: LoginService
  ) {
    this.login();
  }
  video: HTMLVideoElement;

  videoEnd = new EventEmitter<void>();

  visibility = 'hidden';
  videoSrc = '';

  show = false;
  videoWidth = '100px';
  videoTop = '100px';
  videoLeft = '100px';
  videoHeight = '100px';

  async login() {
    /*const result = await this.loginService.login('brudi-tv', 'brudi-tv', true);
    if (result) {
      this.socketService.subscribe('brudiTvAction, (data) => {
        if (data?.action === 'play_video') {
          this.playVideo(data.data);
        }
      });
    }*/
  }

  ngOnInit() {
    // this.video = this.videoComponent.getVideoTag();

    // Use Angular renderer or addEventListener to listen for standard HTML5 video events


  }

  playVideo(clip: string) {
    this.videoSrc = '/assets/brudi-tv/clips/' + clip;
    setTimeout(() => {
      this.show = true;
    }, 10);

  }

  test2() {
    this.videoSrc = '/assets/brudi-tv/clips/capone_2.mp4';
    this.show = true;
  }
}
