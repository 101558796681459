export class TypeTools {

  static isDateOrNumber(value: any) {
    return value && (typeof value === 'number' || typeof (value.date) === 'function' || typeof (value.getTime) === 'function');
  }

  static isDate(value: any) {
    return typeof value?.getTime === 'function';
  }

  static isDateString(value: any) {
    return (typeof (value) === 'string' && value.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/) !== null);
  }

  static isString(value: any): value is string {
    return typeof value === 'string';
  }

  static isNumber(value: any): value is number {
    return typeof value === 'number' && !Number.isNaN(value);
  }

  static isNumberAndNotNaN(value: any): value is number {
    return typeof value === 'number' && !Number.isNaN(value);
  }

  static is(value: any) {
    return typeof value !== 'undefined' && value !== null;
  }

  static isFunction(value: any) {
    return typeof value === 'function';
  }

  static isArray(value: any): value is any[] {
    return this.is(value) && Array.isArray(value);
  }

  public static isNullOrUndefinedOrNAN(newValue: any) {
    return typeof newValue === 'undefined' || newValue === null || Number.isNaN(newValue);
  }
}
