import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {StudioFeeComponent} from './studio-fee.component';
import {StringTools} from '../../common-browser/helpers/string.tools';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';

export class StudioColumnDefs {
  constructor(private studioFeeComponent: StudioFeeComponent) {
  }

  public dateMoney: NxtColDef[] = [
    {headerName: 'Datum', field: 'date', nxtFieldType: NxtFieldType.Date_germanDateWithDayOfWeek, cellStyle: {textAlign: 'right'}, minWidth: 150, maxWidth: 150},
    {headerName: 'Tresor', field: '_value', nxtFieldType: NxtFieldType.Money, cellStyle: {textAlign: 'right'}, minWidth: 100, maxWidth: 100},
    {headerName: 'Kasse ➞ Tresor', field: 'cashTo_', nxtFieldType: NxtFieldType.Money, cellStyle: {textAlign: 'right'}, minWidth: 120, maxWidth: 120},
    {headerName: 'Einl. ➞ Tresor', field: 'privateToCash_', nxtFieldType: NxtFieldType.Money, cellStyle: {textAlign: 'right'}, minWidth: 120, maxWidth: 120},
    {
      headerName: 'Klarna ' + StringTools.arrowRight + ' Bank',
      field: 'klarnaIncoming',
      nxtFieldType: NxtFieldType.Money,
      cellStyle: {textAlign: 'right'},
      minWidth: 120,
      maxWidth: 120
    },
    {headerName: 'Kasse ' + StringTools.arrowRight + ' Bank', field: 'cashToBank', nxtFieldType: NxtFieldType.Money, cellStyle: {textAlign: 'right'}, minWidth: 120, maxWidth: 120},
    {headerName: 'Bank ' + StringTools.arrowRight + ' Kasse', field: 'bankToCash', nxtFieldType: NxtFieldType.Money, cellStyle: {textAlign: 'right'}, minWidth: 120, maxWidth: 120},
    {
      headerName: 'Einlage ' + StringTools.arrowRight + ' Kasse',
      field: 'privateToCash',
      nxtFieldType: NxtFieldType.Money,
      cellStyle: {textAlign: 'right'},
      minWidth: 130,
      maxWidth: 130
    },
    {
      headerName: 'Kasse ' + StringTools.arrowRight + ' Einlage',
      field: 'cashToPrivate',
      nxtFieldType: NxtFieldType.Money,
      cellStyle: {textAlign: 'right'},
      minWidth: 130,
      maxWidth: 130
    },
    {headerName: 'Paypal', field: 'paypalIncoming', nxtFieldType: NxtFieldType.Money, cellStyle: {textAlign: 'right'}, minWidth: 95, maxWidth: 95},
    {headerName: 'Paypal Gutschein', field: 'paypalGiftCardIncoming', nxtFieldType: NxtFieldType.Money, cellStyle: {textAlign: 'right'}, minWidth: 130, maxWidth: 130},
    {headerName: 'Paypal Rück-Zahlungen', field: 'paypalOutgoing', nxtFieldType: NxtFieldType.Money, cellStyle: {textAlign: 'right'}, minWidth: 120, maxWidth: 120},
    {headerName: 'Bank Zahlungen', field: 'bankIncoming', nxtFieldType: NxtFieldType.Money, cellStyle: {textAlign: 'right'}, minWidth: 130, maxWidth: 130},
    {headerName: 'Bank Rück-Zahlungen', field: 'bankOutgoing', nxtFieldType: NxtFieldType.Money, cellStyle: {textAlign: 'right'}, minWidth: 160, maxWidth: 160},
    {headerName: 'Gesamt', field: 'incomingTotal', nxtFieldType: NxtFieldType.Money, cellStyle: {textAlign: 'right', fontWeight: 'bold'}, minWidth: 120, maxWidth: 120},
  ];

  public cashOutgoings: NxtColDef[] = [
    {headerName: 'Datum', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDateTime, minWidth: 140, maxWidth: 140},
    {headerName: 'Betrag', field: 'value', nxtFieldType: NxtFieldType.Money, minWidth: 90, maxWidth: 90},
    {
      headerName: 'Beschreibung', field: 'description', valueGetter: (params) => {
        return (params.data._ ? '_' : '') + params.data.description;
      }, minWidth: 150, maxWidth: 150
    },
    {headerName: 'Steuersatz', field: 'vatPercentage', nxtFieldType: NxtFieldType.Percentage, minWidth: 80, maxWidth: 80},
    {headerName: 'Erfasst von', field: 'createdBy', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Beleg von', field: 'originalCreatedBy', nxtFieldType: NxtFieldType.Text, filter: true},
  ];
}
