@if (data) {
  <div class="fixed left-[0] top-[0] text-[8px] gray-0">{{ data.cacheKey | nxtSubstr: 0: 4 }} | {{ data.durationMs }}</div>
}
<nxt-calendar-toolbar-top
  [(filter)]="currentFilter"
  (jumpDateBack)="jumpDateBackClicked()"
  (jumpDateForward)="jumpDateForwardClicked()"
  (setToday)="setToday()"
  (showDateSelect)="showDateSelectClicked()"
  (newArtistSpot)="newArtistSpotClicked()"
  (reload)="reloadClicked()"
  (eventFinder)="eventFinderClicked()"
  (walkIn)="walkInClicked()"
></nxt-calendar-toolbar-top>
@if (events() && viewParams()) {
  <div class="flex h-full w-full o-hidden">

    <div class="flex h-full w-full more-days" *ngIf="viewMode() === 'week'">
      <mwl-calendar-week-view
          #calendarWeekViewComponent
          [class.only-artist-spots]="this.currentFilter().onlyArtistSpots"
          class="flex h-full w-full o-hidden"
          [refresh]="refreshView"
          [daysInWeek]="viewParams().daysCountToShow"
          [viewDate]="viewParams().dateToShow"
          [headerTemplate]="headerTemplate"
          [dayStartHour]="dayStartHour"
          [hourSegmentTemplate]="hourSegmentTemplate"
          [eventTemplate]="eventTemplate"
          (hourSegmentClicked)="createEventClicked($event)"
          [events]="events()">
      </mwl-calendar-week-view>
    </div>
    <div class="flex h-full w-full flex-col one-day" *ngIf="viewMode() === 'day'">
      <div class="flex flex-row justify-center p-3 one-day-header text-130">
        <div class="flex flex-row items-center">
          <div>{{ currentFilter().dateString | fromNowText: true }}&nbsp;・&nbsp;</div>
          <div>{{ currentFilter().dateString | nxtDate: 'EEEE' }}</div>&nbsp;・&nbsp;
          <div>{{ currentFilter().dateString | nxtDate: 'dd.MM.yyyy' }}</div>&nbsp;・&nbsp;
          <div>{{ artistCounts[currentFilter().dateString] + ' Artists' }}</div>
          <div *ngIf="piercingCounts[currentFilter().dateString]">&nbsp;・&nbsp;{{ piercingCounts[currentFilter().dateString] + ' Piercing' }}</div>
        </div>
      </div>
      <mwl-calendar-day-view
        class="flex h-full w-full o-hidden"
        [refresh]="refreshView"
        [viewDate]="viewParams().dateToShow"
        [dayStartHour]="dayStartHour"
        [hourSegmentTemplate]="hourSegmentTemplate"
        [eventTemplate]="eventTemplate"
        [tooltipAppendToBody]="true"
        (hourSegmentClicked)="createEventClicked($event)"
        [events]="events()">
      </mwl-calendar-day-view>
    </div>


    <!-- TEMPLATES -->

    <ng-template #hourSegmentTemplate let-segment="segment" let-segmentHeight="segmentHeight" let-isTimeLabel="isTimeLabel" let-daysInWeek="daysInWeek">
      <nxt-calendar-hour-segment [cssClass]="segment.cssClass" [date]="segment.date" [isStart]="segment.isStart" [segmentHeight]="segmentHeight"
                                 [isTimeLabel]="isTimeLabel" [dayStartHour]="dayStartHour" [daysInWeek]="daysInWeek" [daysCount]="currentFilter().daysCount">
      </nxt-calendar-hour-segment>
    </ng-template>

    <ng-template #headerTemplate let-days="days" let-locale="locale">
      <nxt-calendar-header
        [artistCounts]="artistCounts"
        [piercingCounts]="piercingCounts"
        [valence]="valence"
        [days]="days"
        [dailyNotes]="dailyNotes"
        [headerWidth]="headerWidth"
        (dateHeaderClicked)="dateHeaderClicked($event)"
        (dailyNoteClicked)="dailyNoteClicked($event)"
      >
      </nxt-calendar-header>
    </ng-template>


    <ng-template #eventTemplate let-weekEvent="weekEvent">
      @if (weekEvent.event.meta.artistSpot) {
        <nxt-calendar-artist-spot (click)="artistSpotClicked(weekEvent.event.meta.artistSpot)" [event]="weekEvent.event"></nxt-calendar-artist-spot>
      } @else {
        <nxt-calendar-event-template (click)="calendarEventClicked(weekEvent.event.meta.event)" [event]="weekEvent.event"></nxt-calendar-event-template>
      }
    </ng-template>
  </div>
}
