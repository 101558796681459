import {DialogService, LoadingId} from '../../services/dialog.service';
import {Router} from '@angular/router';
import {SocketService} from '../../services/socket/socket.service';
import {LoginService} from '../../services/login.service';
import {Component, OnDestroy, OnInit, Optional} from '@angular/core';
import {NxtSubscriptionClass} from '../../classes/nxt-subscription-class';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {NxtStudioFee, NxtStudioFeeCost} from '../../common-interfaces/nxt.studio-fee.interface';
import {StudioColumnDefs} from './studio-fee.column-defs';
import {PdfLoaderComponent} from '../pdf-loader/pdf-loader.component';
import {JsonTools} from '../../common-browser/helpers/json.tools';
import {TelegramService} from '../../services/telegram.service';
import {InvoiceFormComponent} from '../invoice-form/invoice-form.component';
import {ConfigService} from '../../services/config.service';
import {MatDialogRef} from '@angular/material/dialog';
import {Clipboard} from '@angular/cdk/clipboard';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {DownloadService} from '../../services/download.service';
import {SocketInterface} from '../../common-interfaces/socket/socket-interface';
import {SortTools} from '../../common-browser/helpers/sort.tools';
import {StudioFeeKlarnaInvoiceComponent} from './studio-fee-klarna-invoice/studio-fee-klarna-invoice.component';
import {firstValueFrom} from 'rxjs';
import {StudioFeeKlarnaOrdersComponent} from './studio-fee-klarna-orders/studio-fee-klarna-orders.component';
import {NxtShopOrderWithRaw} from '../../common-interfaces/nxt.shop-order.interface';
import {MoneyPipe} from '../../pipes/money.pipe';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {InputComponent} from '../form-controls/input/input.component';
import {SmoothHeightComponent} from '../smooth-height.component';
import {MatIcon} from '@angular/material/icon';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {PermissionDirective} from '../../directives/permission.directive';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {MatTooltip} from '@angular/material/tooltip';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NgFor, NgIf} from '@angular/common';
import {DateRangePickerComponent} from '../form-controls/date-range-picker/date-range-picker.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {StudioFeeCostsComponent} from './studio-fee-costs/studio-fee-costs.component';
import {MobileTools} from '../../common-browser/helpers/mobile.tools';

export interface EventFinderFilter {
  startTime: number;
  studioEndTime: number;
  days: boolean[];
}

@Component({
  selector: 'nxt-studio-fee',
  templateUrl: './studio-fee.component.html',
  styleUrls: ['./studio-fee.component.scss'],
  imports: [FlexModule, DateRangePickerComponent, NgIf, NxtButtonIconComponent, MatTooltip, SlideToggleComponent, PermissionDirective, NxtDatagridComponent, MatIcon, SmoothHeightComponent, InputComponent, NxtButtonComponent, NgFor, MoneyPipe],
})
export class StudioFeeComponent extends NxtSubscriptionClass implements OnInit, OnDestroy {

  constructor(
    private socketService: SocketService,
    public loginService: LoginService,
    private dialogService: DialogService,
    private router: Router,
    private telegramService: TelegramService,
    private configService: ConfigService,
    private clipboard: Clipboard,
    @Optional() public dialogRef: MatDialogRef<any>,
    private downloadService: DownloadService,
  ) {
    super();
  }

  useKlarnaApi = true;

  public data: NxtStudioFee;
  quickFilterText: any;
  columnDefs: StudioColumnDefs = new StudioColumnDefs(this);
  currentMonth;
  // date = moment();
  monthYearString = DateTools.format(Date.now(), 'yyyy-MM');
  costs = 0;
  feePercentage = 0;
  showBottom = true;
  useFix = true;
  useFix_2022_11_19 = true;
  useFix_2023_03_16 = true;


  dateFromTill = {
    from: DateTools.getFirstOfMonth(Date.now()).dateFormat('yyyy-MM-dd'),
    till: DateTools.getLastOfMonth(Date.now()).dateFormat('yyyy-MM-dd'),
  };

  /*async monthDataChanged() {
    await this.socketService.setStudioFeeMonthData({...this.data.monthData, monthYear: this.monthYearString});
    this.show();
  }*/
  showOnlyOutgoings = false;


  async ngOnInit() {
    this.show();
  }

  ngOnDestroy(): void {
  }

  async show() {
    if (!this.loginService.isJulian()) {
      this.useFix_2022_11_19 = true; // this.monthYearString.dateParse('yyyy-MM') >= '2022-11-01'.dateParse();
      this.useFix_2023_03_16 = true; // this.monthYearString.dateParse('yyyy-MM') >= '2023-03-01'.dateParse();
    }
    this.dialogService.showLoading(LoadingId.StudioFeeLoading, 'Lade Daten...');
    const filter: SocketInterface.GetStudioFeeData = {
      useFix: this.useFix,
      useFix_2022_11_19: this.useFix_2022_11_19,
      useFix_2023_03_16: this.useFix_2023_03_16,
      useKlarnaApi: this.useKlarnaApi,
      appendKlarnaData: true,
    };
    filter.fromDateString = this.dateFromTill.from;
    filter.tillDateString = this.dateFromTill.till;
    this.data = await this.socketService.getStudioFeeData(filter);
    this.calcFee();
    this.dialogService.hideLoading(LoadingId.StudioFeeLoading);
  }

  calcFee() {
    const profit = this.data.incomingTotalGross - this.data.monthData.costsGross - this.data.monthData.costs_;
    if (profit < 20000) {
      this.feePercentage = 20;
    } else if (profit < 30000) {
      this.feePercentage = (0.001 * profit) + 10;
    } else {
      this.feePercentage = 40;
    }
  }

  async setInvoiceSentMonth() {
    const doIt = await this.dialogService.showYesNo('Rechnung für ' + DateTools.parseFormat(this.monthYearString, 'yyyy-MM', 'MMMM yyyy') + ' verschickt?');
    if (doIt) {
      this.data.monthData.invoiceSent = true;
      await this.socketService.setStudioFeeMonthData({...this.data.monthData, monthYear: this.monthYearString});
      this.show();
      this.dialogService.showOk('TipTop');
    }
  }

  async finishMonth() {
    const doIt = await this.dialogService.showYesNo('Rechnung für ' + DateTools.parseFormat(this.monthYearString, 'yyyy-MM', 'MMMM yyyy') + ' bezahlt?');
    if (doIt) {
      this.data.monthData.closed = true;
      await this.socketService.setStudioFeeMonthData({...this.data.monthData, monthYear: this.monthYearString});
      this.show();
      this.dialogService.showOk('TipTop');
    }
  }

  calcIncomingTax(value: number) {
    if (value <= 9408) {
      return 0;
    } else if (value <= 14532) {
      return (972.87 * (value - 9408) / 10000 + 1400) * (value - 9408) / 10000;
    } else if (value <= 57051) {
      return (212.02 * (value - 14532) / 10000 + 2397) * (value - 14532) / 10000 + 972.79;
    } else if (value <= 270500) {
      return (0.42 * value - 8963.74);
    } else {
      return 0.45 * value - 17078.74;
    }
  }

  monthPickerChanged(monthString: string) {
    if (monthString !== this.monthYearString) {
      this.monthYearString = monthString;
      this.router.navigateByUrl('/studio-fee/' + this.monthYearString);
      this.show();
    }
  }

  async createInvoice() {
    const invoiceNumber = await (await this.dialogService.showInputOld({
      message: 'Rechnungsnummer',
      prompt: 'RE' + this.configService.config.value.studioRegion + '00XX',
    })).afterClosed().toPromise();
    if (!invoiceNumber) {
      return;
    }
    const invoiceDateString = await (await this.dialogService.showInputOld({
      message: 'Rechnungsdatum',
      prompt: DateTools.format(Date.now(), 'dd.MM.yyyy'),
    })).afterClosed().toPromise();
    if (!invoiceDateString) {
      return;
    }

    const monthYearString = DateTools.parseFormat(this.monthYearString, 'yyyy-MM', 'MM/yyyy');

    const dialog = this.dialogService.showComponentDialog(PdfLoaderComponent);
    dialog.componentInstance.getInvoiceExternStudio({
      invoiceNumber,
      invoiceDateString,
      monthYearString,
      shouldBeValue: this.data.feeCalcData[0].feeGross,
      studio: this.configService.config.value.studioRegion,
      discountGross: this.data.monthData.discountGross,
    }).then();
    this.export();
  }

  export() {
    const element = document.createElement('a');
    const text = JsonTools.stringifyFormat({exportDate: DateTools.todayDateString, ...this.data});
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', 'export_' + this.configService.config.value.studioRegion + '_' + this.monthYearString + '.json');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  async dataInputFinish() {
    const monthText = DateTools.parseFormat(this.monthYearString, 'yyyy-MM', 'MMMM yyyy');
    const doIt = await this.dialogService.showYesNo('Ausgaben für ' + monthText + ' fertig eingetragen?');
    if (doIt) {
      const _value = this.data.feeCalcData[this.data.feeCalcData.length - 1].fee_;
      const value = this.data.feeCalcData[this.data.feeCalcData.length - 1].feeGross;
      const text = 'Ausgaben für ' + monthText + ' wurden bestätigt! -> Rechnung erstellen!\n_: ' + _value.toMoneyString() + '\nnormal: ' + value.toMoneyString();
      this.telegramService.sendBackofficeHeads(text);
      this.socketService.sendWhatsAppMessage(MobileTools.Numbers.Niklas, text);
      this.data.monthData.dataInputFinished = true;
      await this.socketService.setStudioFeeMonthData({...this.data.monthData, monthYear: this.monthYearString});
      this.show();
      this.dialogService.showOk('Viele Dank!');
    }
  }

  async dataInputOpen() {
    const monthText = DateTools.parseFormat(this.monthYearString, 'yyyy-MM', 'MMMM yyyy');
    const doIt = await this.dialogService.showYesNo('Ausgaben für ' + monthText + ' wieder öffnen?');
    if (doIt) {
      this.telegramService.sendBackofficeHeads('Ausgaben für ' + monthText + ' wurden wieder geöffnet');
      this.data.monthData.dataInputFinished = false;
      await this.socketService.setStudioFeeMonthData({...this.data.monthData, monthYear: this.monthYearString});
      this.show();
    }
  }

  invoiceForm() {
    this.dialogService.showComponentFull(InvoiceFormComponent);
  }

  async showKlarnaInvoiceClicked() {
    const dialog = this.dialogService.showComponentDialog(StudioFeeKlarnaInvoiceComponent);
    const dateRange = await firstValueFrom(dialog.afterClosed());
    if (dateRange) {
      const shopOrders: NxtShopOrderWithRaw[] = await this.socketService.getKlarnaShopOrdersBetweenWithRaw(dateRange.from, dateRange.till);
      this.dialogService.showComponentDialog(StudioFeeKlarnaOrdersComponent, {shopOrders, dateRange},
        {
          minWidth: '70vw',
          minHeight: '70vh',
        },
      );
    }
  }


  showKlarna() {
    if (this.useKlarnaApi) {
      const lines: string[] = [];
      lines.push('<table class="nxt-table">');
      lines.push('<tr><th>Studio</th><th>Auszahlung</th><th>Rückzahlungen</th><th>Gebühren<br/>(Anteilig)</th></tr>');

      for (const [index, payout] of this.data.klarnaApiPayouts.sort(SortTools.sortString('dateString')).entries()) {
        let headerLine = '<tr><td colspan="5" style="text-align: center;">';
        headerLine += '<div class="text-120">' + payout.date.dateFormat('dd.MM.yyyy') + '</div>';
        headerLine += 'Auszahlung: ' + payout.totals.settlementAmount.toMoneyString();
        headerLine += ' | ' + 'Bestellungen: ' + payout.totals.saleAmount.toMoneyString();
        headerLine += ' | ' + 'Rückzahlungen: ' + payout.totals.returnAmount.toMoneyString();
        headerLine += ' | ' + 'Kosten: ' + payout.totals.costs.toMoneyString();
        headerLine += '</td></tr>';
        lines.push(headerLine);
        for (const studio of payout.studios) {
          lines.push('<tr>');
          // lines.push('<td>' + payout.date.dateFormat('dd.MM.yyyy') + '</td>');
          lines.push('<td>' + studio.studio + '</td>');
          lines.push('<td class="text-right">' + studio.payoutValue.toMoneyString() + '</td>');
          lines.push('<td class="text-right">' + studio.returnsAmountEuro.toMoneyString() + '</td>');
          lines.push('<td class="text-right">' + studio.costsEuro.toMoneyString() + '</td>');
          lines.push('</tr>');
        }
        if (index < this.data.klarnaApiPayouts.length - 1) {
          lines.push('<tr><td colspan="5">&nbsp;</td></tr>');
        }
      }
      lines.push('</table>');
      this.dialogService.showOk(lines.join(''));
    }/* else {
      this.dialogService.showComponentDialog(StudioFeeKlarnaOrdersComponent, {
          data: this.data.klarnaShopOrders
        },
        {
          minWidth: '70vw',
          minHeight: '70vh'
        }
      );
    }*/
  }

  public showIncomingGrossText() {
    this.dialogService.showOk(this.data.incomingGrossText);
  }

  public copyForExcel(ev: MouseEvent, number: number) {
    ev.stopPropagation();
    ev.preventDefault();
    this.clipboard.copy(MathTools.roundMoney(number).toString().replace('.', ','));
  }

  public async downloadOutgoingImages() {
    this.dialogService.showLoading(LoadingId.Download, 'Belege werden heruntergeladen...');
    const result = await this.socketService.getOutgoingsImagesZip(this.data.cashOutgoings.map(o => o.id));

    if (result.length > 0) {
      this.downloadService.downloadBase64(this.loginService.getStudio() + '_Belege_' + this.monthYearString + '.zip', result.zipBase64);
      setTimeout(() => {
        this.dialogService.hideLoading(LoadingId.Download);
      }, 3000);
      // this.dialogService.showOk(result.length + ' Belege wurden heruntergeladen');
    } else {
      this.dialogService.hideLoading(LoadingId.Download);
      this.dialogService.showOk('Es gibt keine Belege');
    }

  }

  ifFullMonth() {
    return this.dateFromTill.from === DateTools.getFirstOfMonth(this.dateFromTill.from).dateFormat('yyyy-MM-dd')
      && this.dateFromTill.till === DateTools.getLastOfMonth(this.dateFromTill.till).dateFormat('yyyy-MM-dd');
  }

  dateRangeChanged() {
    this.monthYearString = '';
    if (this.ifFullMonth()) {
      this.monthYearString = this.dateFromTill.from.dateParse().dateFormat('yyyy-MM');
    }
    this.show();
  }

  async monthDataChanged() {
    await this.socketService.setStudioFeeMonthData({...this.data.monthData, monthYear: this.monthYearString});
    this.dateRangeChanged();
  }

  toggleOutgoingsClicked() {
    this.showOnlyOutgoings = !this.showOnlyOutgoings;
  }

  async costsGrossClicked() {
    this.editCosts(false);
  }

  private async editCosts(isSafe: boolean) {
    const dialog = this.dialogService.showComponentDialog<NxtStudioFeeCost[]>(StudioFeeCostsComponent);
    const title = isSafe ? 'Aufgeschlüsselte Kosten (Safe)' : 'Aufgeschlüsselte Studiokosten Brutto (Miete, Stuff)';
    const data = isSafe ? this.data.monthData.costs_Detail : this.data.monthData.costsGrossDetail;
    dialog.componentInstance.setData(data, !this.data.monthData.dataInputFinished, title, isSafe);
    const result = await firstValueFrom(dialog.afterClosed());
    if (result) {
      if (isSafe) {
        this.data.monthData.costs_Detail = result;
        this.data.monthData.costs_ = result.reduce((acc, val) => acc + val.value, 0);
      } else {
        this.data.monthData.costsGrossDetail = result;
        this.data.monthData.costsGross = result.reduce((acc, val) => acc + val.value, 0);
      }
      this.monthDataChanged();
    }
  }

  costs_Clicked() {
    this.editCosts(true);
  }

  async safeClosedClicked() {
    const doIt = await this.dialogService.showYesNo('Tresor für ' + DateTools.parseFormat(this.monthYearString, 'yyyy-MM', 'MMMM yyyy') + ' bezahlt?');
    if (doIt) {
      this.data.monthData.safeClosed = true;
      await this.socketService.setStudioFeeMonthData({...this.data.monthData, monthYear: this.monthYearString});
      this.show();
    }
  }
}
