<div class="nxt-page" fxLayout="column" fxLayoutAlign="center center" style="overflow: hidden">
  <div class="p-3 text-140" fxLayout="row">Piercing Wechsel</div>

  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="0.75em" class="scrollable w-full">
    @for (piercing of piercings(); track piercing) {
      <div style="border: 1px solid gray;" class="p-2" fxLayout="column">
        <table class="piercing-table">
          <tr>
            <th colspan="2">{{ ($index + 1) }}. Piercing</th>
          </tr>
          <tr>
            <td>Bei uns gestochen</td>
            <td>
              <nxt-slide-toggle [(value)]="piercing.workFromUs" (valueChange)="calcIncoming()"></nxt-slide-toggle>
            </td>
          </tr>
          <tr>
            <td colspan="2" *ngIf="piercing">
              <mat-button-toggle-group [(value)]="piercing.jewelryState" (valueChange)="JewelryStateChanged()">
                <mat-button-toggle value="fromUs">
                  <div class="leading-snug h-full flex justify-center">Schmuck<br/>von uns</div>
                </mat-button-toggle>
                <mat-button-toggle value="fromUsSterile">
                  <div class="leading-snug h-full flex justify-center">Steriler<br/>Schmuck von uns</div>
                </mat-button-toggle>
                <mat-button-toggle value="own">
                  <div class="leading-snug h-full flex justify-center">Selbst<br/>mitgebracht</div>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </td>
          </tr>
          <tr>
            <td>Bareinnahme</td>
            <td>
              {{ piercing.articlePrice | money }}
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <nxt-input
                [readonly]="true"
                [validators]="required"
                placeholder="Körperstelle"
                [(value)]="piercing.bodyPutPiercing"
                [cellRenderer]="bodyPutPiercingCellRenderer"
                (click)="bodyPutPiercingClick($index)"
              ></nxt-input>
            </td>
          </tr>
          <tr>
            <td>Artist %</td>
            <td>{{ piercing.artistPercentage }}%</td>
          </tr>
          <tr>
            <td>Terminpreis</td>
            <td>{{ piercing.eventPrice | money }}</td>
          </tr>
          <tr>
            <td class="font-bold">{{ ($index + 1) }}. Piercing Gesamtpreis</td>
            <td class="font-bold">{{ (piercing.eventPrice + piercing.articlePrice) | money }}</td>
          </tr>
        </table>
        <div *ngIf="$index > 0" fxLayoutAlign="end end">
          <nxt-button-icon (click)="removePiercing()">remove</nxt-button-icon>
        </div>
      </div>
    }

    <div fxLayoutAlign="center center">
      <nxt-button-icon (click)="addPiercing()">add</nxt-button-icon>
    </div>
  </div>
  @if (piercings().length > 1) {
    <div class="flex flex-row justify-center pt-2 font-bold text-[120%]">GESAMT ZU KASSIEREN: {{ totalIncome | money2 }}</div>
  }

  <div fxLayout="row" fxLayoutAlign="center" class="pt-3 gap-3">
    <nxt-button (click)="dialogRef?.close()">Abbrechen</nxt-button>
    <nxt-button (click)="save()">Anlegen</nxt-button>
  </div>

</div>
