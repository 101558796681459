@if (dataSource()) {
  <mat-tree #tree [dataSource]="dataSource()" [childrenAccessor]="childrenAccessor" [trackBy]="trackByFn" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
    <mat-nested-tree-node class="without-children" *matTreeNodeDef="let node">
      <div class="flex flex-row items-center pl-[50px] py-[6px]">
        {{ node.name }}
        &nbsp;&nbsp;<mat-icon class="cursor-pointer relative top-[-1px] icon-sm" (click)="editChildClicked($event, node)" fontSet="material-symbols-outlined">edit</mat-icon>
        &nbsp;&nbsp;<mat-icon class="cursor-pointer relative top-[-1px]" (click)="addChildClicked($event, node)" fontSet="material-symbols-outlined">add</mat-icon>
      </div>
    </mat-nested-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node
      #treeNode="matNestedTreeNode"
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodeToggle [cdkTreeNodeTypeaheadLabel]="node.name">
      <div class="mat-tree-node cursor-pointer">
        <div class="flex flex-row justify-center items-center p-3 cursor-pointer">
          <mat-icon matTreeNodeToggle fontSet="material-symbols-outlined">
            {{ tree.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </div>
        {{ node.name }}&nbsp;&nbsp;
        <mat-icon class="cursor-pointer relative top-[-1px] icon-sm" (click)="editChildClicked($event, node)" fontSet="material-symbols-outlined">edit</mat-icon>
        @if (tree.isExpanded(node)) {
          &nbsp;&nbsp;
          <mat-icon class="cursor-pointer relative top-[-1px]" fontSet="material-symbols-outlined" (click)="addChildClicked($event, node, treeNode)">add</mat-icon>
        }
      </div>
      <!-- There is inline padding applied to this div using styles.
          This padding value depends on the mat-icon-button width.  -->
      <div [class.example-tree-invisible]="!tree.isExpanded(node)" role="group">
        <div style="border:0px solid green;">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
}
<nxt-button (click)="addChildClicked($event)">neues Hauptlabel</nxt-button>
<!--<nxt-button (click)="deleteAllClicked()">alle löschen</nxt-button>-->
<div [innerHTML]="dataSource() | jsonFormatted" (click)="test123(dataSource())" class="text-[80%] text-color"></div>
