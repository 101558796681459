import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NxtDayFinishReport2ForView} from '../../../../common-interfaces/nxt.day-finish-report.interface';
import {PaymentTools} from '../../../../common-browser/helpers/payment.tools';
import {NxtMoneyStack} from '../../../../common-interfaces/nxt.money-stack.interface';
import {ObjectGroupTools} from '../../../../common-browser/helpers/object-group.tools';
import {DateTools} from '../../../../common-browser/helpers/date.tools';
import {NxtPayment, NxtStudioCashReportPayment} from '../../../../common-interfaces/nxt.payment.interface';
import {MoneyTools} from '../../../../common-browser/helpers/money.tools';
import {MoneyStackTools} from '../../../../common-browser/helpers/money-stack.tools';
import {StringTools} from '../../../../common-browser/helpers/string.tools';
import {MoneyPipe} from '../../../../pipes/money.pipe';
import {SafeHtmlPipe} from '../../../../pipes/safe-html.pipe';
import {NxtDatePipe} from '../../../../pipes/nxt-date-pipe';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf, NgStyle} from '@angular/common';

@Component({
    selector: 'nxt-day-finish-report-2',
    templateUrl: './day-finish-report-2.component.html',
    styleUrls: ['./day-finish-report-2.component.scss'],
    imports: [NgIf, FlexModule, NgStyle, ExtendedModule, NgFor, NxtDatePipe, SafeHtmlPipe, MoneyPipe]
})
export class DayFinishReport2Component implements OnInit {
  getTotalValueFromMoneyStack = MoneyStackTools.getTotalValueFromMoneyStack;
  moneys = MoneyTools.moneys;
  data: NxtDayFinishReport2ForView;
  errorText = '';
  showIncomingSum = true;
  showOutgoingSum = true;
  showOutgoings = true;
  incomingFontSize = '9px';
  outgoingFontSize = '9px';
  isLastPage = false;

  constructor(
    private route: ActivatedRoute,
  ) {
    try {
      this.errorText = '';
      (window as any).setData = (data: any) => {
        try {
          this.data = this.calcData(data);
          this.afterDataSet();
          this.setFontSize();
        } catch (err) {
          this.errorText = err.message;
        }
      };
    } catch (err) {
      this.errorText = err.message;
    }
  }

  async ngOnInit() {

  }

  getPaymentTypeText(payment: NxtPayment) {
    let result = PaymentTools.getPaymentTypeInvoiceText(payment.paymentType, 'tattoo');
    if (payment.earlyPayout) {
      result += ' (Vorzeitig)';
    }
    if (payment.paymentType === 'gift-card-sold') {
      result += ' #' + StringTools.fill(payment.refId.toString(), 4, '0', false);
    }

    if (payment.paymentType === 'cash-report-outgoing' || payment.paymentType === 'cash-report-incoming') {
      if (payment.cashIncomingOutgoingDescription.indexOf('Kasse 2') > -1) {
        result = 'Transfer Kasse 2';
      } else {
        result = payment.cashIncomingOutgoingDescription;
      }
    } else if (payment.paymentType === 'payout') {
      result += ' - ' + payment.artist;
    } else if (payment.paymentType === 'deposit-back' || payment.paymentType === 'cheaper') {
      result += ' - ' + payment.eventCustomerName;
    }
    return result;
  }

  printMoneyStack(endMoneyStack: NxtMoneyStack) {
    const text: string[] = [];
    for (const key of Object.keys(endMoneyStack)) {
      text.push(endMoneyStack[key] + ' x ' + key);
    }
    return text.join(', ');
  }

  private calcData(data: NxtDayFinishReport2ForView): NxtDayFinishReport2ForView {
    // payouts gruppieren
    let payouts = data.cashPaymentsOut.filter(payment => payment.paymentType === 'payout');
    const outgoingsWithoutPayouts = data.cashPaymentsOut.filter(payment => payment.paymentType !== 'payout');
    payouts = this.groupPayouts(payouts);
    // Zahlungen mit 0 raus nehmen
    data.cashPaymentsOut = data.cashPaymentsOut.filter(payment => payment.paymentValue > 0);
    data.cashPaymentsIn = data.cashPaymentsIn.filter(payment => payment.paymentValue > 0);
    data.cashPaymentsOut = [...outgoingsWithoutPayouts, ...payouts].sortNumber('createdAt');
    return data;
  }

  private groupPayouts(payouts: NxtStudioCashReportPayment[]): NxtStudioCashReportPayment[] {
    const result: NxtStudioCashReportPayment[] = [];
    const groupedPayouts = ObjectGroupTools.groupObjectArray(payouts, 'artist');
    for (const artist of Object.keys(groupedPayouts)) {
      const paymentValue = groupedPayouts[artist].reduce((sum, payment) => sum + payment.paymentValue, 0);
      const createdAt = payouts.find(p => p.artist === artist).createdAt;
      result.push({
        artist,
        createdBy: '',
        createdAt,
        paymentUuid: '',
        paymentDate: '',
        paymentValue,
        paymentType: 'payout',
        createdAtDateString: '',
        earlyPayout: groupedPayouts[artist][0].earlyPayout,
        direction: 'out',
      });
    }
    return result;
  }

  getDateString(date: number) {
    return DateTools.format(date, 'EEE dd.MM.yyyy');
  }

  private afterDataSet() {
    if (this.data.totalPageCount > 1) {
      if (this.data.currentPageCount === 1) {
        this.showIncomingSum = false;
        this.showOutgoingSum = true;
      } else {
        this.showIncomingSum = true;
        this.showOutgoingSum = false;
        this.showOutgoings = false;
      }
    }
    this.isLastPage = this.data.totalPageCount === this.data.currentPageCount;
  }

  private setFontSize() {
    this.incomingFontSize = '9px';
    if (this.data.cashPaymentsIn.length > 30 && this.data.currentPageCount === this.data.totalPageCount) {
      this.incomingFontSize = '6px';
    }
  }
}
