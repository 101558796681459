import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[nxtClickAnimation]',
  standalone: true,
})
export class ClickDirective {

  durationMs = 100;

  @HostListener('click') onClick() {
    // Beim Klicken skalieren
    this.renderer.setStyle(this.el.nativeElement, 'transform', 'scale(0.8)');

    // Rücksetzen nach 200ms, um den Klick-Effekt zu beenden
    setTimeout(() => {
      this.renderer.setStyle(this.el.nativeElement, 'transform', 'scale(1)');
    }, this.durationMs);
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.setStyle(this.el.nativeElement, 'transition', 'transform ' + this.durationMs + 'ms ease-in-out');
  }
}
