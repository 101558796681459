import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Optional, signal} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../services/dialog.service';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {CheckboxComponent} from '../form-controls/checkbox/checkbox.component';
import {NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {NxtDriveFileSubTypePipe} from '../../pipes/nxt-drive-file-sub-type-pipe';
import {NxtEventFileClipboard} from '../../common-interfaces/event-file-clipboard.interface';
import {NxtEventFilePipe} from '../../pipes/nxt-event-file.pipe';

@Component({
  selector: 'nxt-event-files-clipboard',
  templateUrl: './event-files-clipboard.component.html',
  styleUrls: ['./event-files-clipboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FlexModule, NgIf, CheckboxComponent, NxtButtonComponent, NxtDatePipe, NxtDriveFileSubTypePipe, NxtEventFilePipe],
})
export class EventFilesClipboardComponent implements OnInit {

  public eventId = '';

  eventFilesClipboard = signal<NxtEventFileClipboard[]>([]);

  public photosSelected = {};
  photosSelectedCount = 0;

  constructor(
    private socketService: SocketService,
    private cdRef: ChangeDetectorRef,
    @Optional() public dialogRef: MatDialogRef<any>,
    private dialogService: DialogService,
  ) {
  }

  async ngOnInit() {
    this.load();
  }

  private async load() {
    const data = await this.socketService.getEventFilesFromClipboard();
    this.eventFilesClipboard.set(data);
  }

  public async save() {
    const ids: string[] = [];
    for (const key of Object.keys(this.photosSelected)) {
      if (this.photosSelected[key]) {
        ids.push(key);
      }
    }
    if (ids.length === 0) {
      this.dialogService.showOk('Du hast keine Bilder ausgewählt');
    } else {
      if (await this.dialogService.showYesNo('Möchtest du ' + ids.length + ' Foto(s) verknüpfen?')) {
        const eventFiles = this.eventFilesClipboard().filter(p => ids.includes(p.id));
        this.socketService.reAssignEventFilesFromClipboard(this.eventId, eventFiles);
        this.dialogRef.close();
      }
    }
  }

  public triggerPhoto(index: string, forceValue?: boolean) {
    if (typeof forceValue === 'boolean') {
      this.photosSelected[index] = forceValue;
    } else {
      this.photosSelected[index] = !this.photosSelected[index];
    }

    for (const key of Object.keys(this.photosSelected)) {
      if (!this.photosSelected[key]) {
        delete this.photosSelected[key];
      }
    }
    this.photosSelectedCount = Object.keys(this.photosSelected).length;
  }

  public async delete() {
    const ids: string[] = [];
    for (const key of Object.keys(this.photosSelected)) {
      if (this.photosSelected[key]) {
        ids.push(key);
      }
    }
    if (await this.dialogService.showYesNo('Möchtest du ' + ids.length + ' Foto(s) wirklich löschen?')) {
      await this.socketService.updatePhotoSyncReimportUnknownDelete(ids);
      this.load();
    }
  }
}
