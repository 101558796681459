import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MoneyTools} from '../../common-browser/helpers/money.tools';
import {ColorTools} from '../../common-browser/helpers/color.tools';
import {NxtMoneyStack} from '../../common-interfaces/nxt.money-stack.interface';
import {DialogService} from '../../services/dialog.service';
import {DecimalTools} from '../../common-browser/helpers/decimal.tools';
import {MoneyStackTools} from '../../common-browser/helpers/money-stack.tools';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {MoneyPipe} from '../../pipes/money.pipe';
import {MultiClickDirective} from '../../directives/multi-click.directive';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgFor, NgIf, NgStyle} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-money-counter',
    templateUrl: './money-counter.component.html',
    styleUrls: ['./money-counter.component.scss'],
    imports: [FlexModule, NgFor, NgStyle, ExtendedModule, NxtButtonIconComponent, NgIf, MultiClickDirective, MoneyPipe]
})
export class MoneyCounterComponent implements OnInit, AfterViewInit {

  @Input() showShould = true;
  @Input() moneyStack: NxtMoneyStack;
  @Input() totalMoneyShouldValue = 0;
  @Output() moneyStackChange = new EventEmitter<NxtMoneyStack>();

  totalMoneyValue = 0;
  @Output() totalMoneyValueChange = new EventEmitter<number>();

  public getMoneyWidth = MoneyTools.getMoneyWidth;
  public getMoneyHeight = MoneyTools.getMoneyHeight;


  moneys = MoneyTools.moneysReverse;
  totalMoneyValueColor = '';
  totalMoneyShouldValueColor = '';
  totalMoneyDiffValueColor = '';


  totalMoneyDiffValue = 0;

  constructor(
    private dialogService: DialogService
    // private dialogRef: MatDialogRef<MoneyCounterComponent>,
  ) {
  }

  ngOnInit() {
    this.calcTotalMoneyValue();
  }

  getMoneyCount(money: number) {
    return this.moneyStack[money];
  }

  async setMoneyCount(money: number) {
    const prompt = this.moneyStack[money] !== 0 ? this.moneyStack[money].toString() : '';
    const dialog = await this.dialogService.showInputOld({
      isMoney: false,
      prompt,
      message: 'Anzahl von ' + DecimalTools.toMoneyString(money),
      okButtonText: 'OK',
    });
    const result = await dialog.afterClosed().toPromise();
    if (!Number.isNaN(result) && result >= 0) {
      this.moneyStack[money] = Number.parseInt(result, 10);
    }
    this.calcTotalMoneyValue();
    this.moneyStackChange.emit(this.moneyStack);
    // alert(result);
  }

  addMoney(money: number) {
    this.moneyStack[money]++;
    this.calcTotalMoneyValue();
    this.moneyStackChange.emit(this.moneyStack);
  }

  removeMoney(money: number) {
    if (this.moneyStack[money] > 0) {
      this.moneyStack[money]--;
      this.calcTotalMoneyValue();
      this.moneyStackChange.emit(this.moneyStack);
    }
  }

  calcTotalMoneyValue() {
    this.totalMoneyValue = MoneyStackTools.getTotalValueFromMoneyStack(this.moneyStack);
    if (MathTools.round(this.totalMoneyShouldValue, 2) !== this.totalMoneyValue) {
      this.totalMoneyDiffValueColor = ColorTools.Red;
    } else {
      this.totalMoneyDiffValueColor = ColorTools.Green;
    }
    this.totalMoneyDiffValue = this.totalMoneyValue - this.totalMoneyShouldValue;
  }

  ngAfterViewInit(): void {

  }
}
