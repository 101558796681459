import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, input, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtChat} from '../../../../common-interfaces/message.interface';

@Component({
  selector: 'nxt-messenger-chat-list-item',
  templateUrl: './messenger-chat-list-item.component.html',
  styleUrls: ['./messenger-chat-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})

export class MessengerChatListItemComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/
  chat = input.required<NxtChat>();

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);


  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }
}
