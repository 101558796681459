import {SortTools} from './sort.tools';
import {ObjectTools} from './object.tools';

export class MoneyTools {
  private static _moneys = [0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 50, 100, 200];

  public static currencyShort(currency: string) {
    switch (currency) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
    }
    return currency;
  }

  public static get moneys() {
    return ObjectTools.clone(MoneyTools._moneys);
  }

  public static get moneysReverse() {
    return ObjectTools.clone(MoneyTools.moneys).sort(SortTools.arrayNumbers(true));
  }

  public static get moneysReverseRandom() {
    return ObjectTools.clone(MoneyTools.moneys).sort(SortTools.random());
  }

  public static getMoneyWidth(money: number, factor: number) {
    return MoneyTools.getMoneyWidthPercent(money) * factor;
  }

  public static getMoneyHeight(money: number, factor: number) {
    return MoneyTools.getMoneyHeightPercent(money) * factor;
  }

  public static getMoneyWidthPercent(money: number) {
    switch (money) {
      case 200:
        return 140;
      case 100:
        return 138;
      case 50:
        return 136;
      case 20:
        return 134;
      case 10:
        return 132;
      case 5:
        return 130;
      case 2:
        return 40;
      case 1:
        return 38;
      case 0.5:
        return 38;
      case 0.2:
        return 36;
      case 0.1:
        return 34;
      case 0.05:
        return 34;
      case 0.02:
        return 32;
      case 0.01:
        return 30;
    }
    return 100;
  }

  public static getMoneyHeightPercent(money: number) {
    switch (money) {
      case 200:
        return 140;
      case 100:
        return 138;
      case 50:
        return 136;
      case 20:
        return 134;
      case 10:
        return 125;
      case 5:
        return 120;
      case 2:
        return 80;
      case 1:
        return 75;
      case 0.5:
        return 76;
      case 0.2:
        return 72;
      case 0.1:
        return 68;
      case 0.05:
        return 64;
      case 0.02:
        return 60;
      case 0.01:
        return 58;
    }
    return 100;
  }


  static getPreviousCoin(money: number): any {
    const index = this.moneys.findIndex(m => m === money);
    if (index > 0) {
      return this.moneys[index - 1];
    }
  }
}
