<div #menuTriggerWrapper
     class="menuTriggerWrapper"
     (contextmenu)="stopPropagation($event)">
  <div
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menu">
  </div>
</div>
<mat-menu #menu [hasBackdrop]="false">
  <ng-content></ng-content>
</mat-menu>
