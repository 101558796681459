import {ChangeDetectionStrategy, Component, EventEmitter, inject, model, OnInit, Output, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {MatTooltip} from '@angular/material/tooltip';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {WorkplacePipe} from '../../../pipes/workplace-pipe';
import {LoginService} from '../../../services/login.service';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {NgIf} from '@angular/common';
import {SocketInterfaceResponse} from '../../../common-interfaces/socket/socket-interface';
import {PermissionDirective} from '../../../directives/permission.directive';
import CalendarFilter = SocketInterfaceResponse.CalendarFilter;

@Component({
    selector: 'nxt-calendar-toolbar-top',
    templateUrl: './calendar-toolbar-top.component.html',
    styleUrls: ['./calendar-toolbar-top.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTooltip, NxtButtonIconComponent, WorkplacePipe, SlideToggleComponent, NxtButtonComponent, NgIf, PermissionDirective]
})

export class CalendarToolbarTopComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  iconSize = 28;


  /*** Inputs ***/
  filter = model.required<CalendarFilter>();


  /*** Outputs ***/
  @Output() showDateSelect = new EventEmitter<void>();
  @Output() jumpDateBack = new EventEmitter<void>();
  @Output() jumpDateForward = new EventEmitter<void>();
  @Output() setToday = new EventEmitter<void>();
  @Output() newArtistSpot = new EventEmitter<void>();
  @Output() eventFinder = new EventEmitter<void>();
  @Output() reload = new EventEmitter<void>();
  @Output() walkIn = new EventEmitter<void>();

  /*** Signals ***/
  showSunday = signal(true);

  /*** Injections ***/
  loginService = inject(LoginService);


  daysCountOptions: { value: number; text: string }[] =
    [
      {value: 1, text: '1 Tag'},
      {value: 3, text: '3 Tage'},
      {value: 7, text: '1 Woche'},
    ];

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  showOnlyEventsFutureChanged(showOnlyEventsFuture: boolean) {
    // setTimeout(() => {
    this.filter.update(f => ({...f, showOnlyEventsFuture, showCanceledEvents: false}));
    // });
  }

  showWithPiercingChanged(showEventsWithPiercing: boolean) {
    this.filter.update(f => ({...f, showEventsWithPiercing}));
  }

  showCanceledEventsChanged(showCanceledEvents: boolean) {
    let showOnlyCanceledEvents = this.filter().showOnlyCanceledEvents;
    if (!showCanceledEvents) {
      showOnlyCanceledEvents = false;
    }
    this.filter.update(f => ({...f, showCanceledEvents, showOnlyEventsFuture: false, showOnlyCanceledEvents}));
  }

  showOnlyCanceledEventsChanged(showOnlyCanceledEvents: boolean) {
    this.filter.update(f => ({...f, showOnlyCanceledEvents, showOnlyEventsFuture: false}));
  }

  setDaysCount(daysCount: number) {
    this.filter.update(f => ({...f, daysCount}));
  }

  logout() {
    this.loginService.reLogin(true);
  }

  onlyArtistSpotsChanged(onlyArtistSpots: any) {
    this.filter.update(f => ({...f, onlyArtistSpots}));
  }

  openGooglePhotos() {
    window.open('https://photos.google.com', '_blank');
  }
}
