export class HtmlTools {


  public static linkify(text, target: '_blank' | '_parent' | '_self' = '_self') {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, (url) => '<a class="a-link" target="' + target + '" href="' + url + '">' + url + '</a>');
  }

  static getTextRecursiveParent(target: HTMLElement) {
    if (target.innerText) {
      return target.innerText;
    } else {
      return HtmlTools.getTextRecursiveParent(target.parentElement as HTMLElement);
    }
  }
}
