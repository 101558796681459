import {AbstractControlOptions, FormControl, UntypedFormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ElementRef} from '@angular/core';
import {FormControlWrapper} from '../components/form-controls/form-control-wrapper';

export class NxtFormControl extends UntypedFormControl implements FormControl {


  get disabled(): boolean {
    return super.disabled;
  }

  readonly errors: ValidationErrors | null;

  public isNumber = false;

  public controlElemRef: ElementRef;
  public element: HTMLElement;

  private _firstValueSetTemp = 0;
  private _oldValue: any;
  private _lastValueChanged: boolean;
  private _firstValueSet: boolean;
  public formControlWrapper: FormControlWrapper;

  constructor(formState?: any, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, public name?: string) {
    super(formState, validatorOrOpts);
  }

  public getOldValue(): any {
    return this._oldValue;
  }

  public valueChanged(): any {
    return this.value !== this._oldValue;
  }

  public getFirstValueSet(): any {
    return this._firstValueSet;
  }

  public getLastValueChanged(): boolean {
    return this._lastValueChanged;
  }

  setValue(value: any, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
    emitModelToViewChange?: boolean;
    emitViewToModelChange?: boolean;
  }) {
    if (this._firstValueSetTemp === 0) {
      this._firstValueSet = true;
      this._firstValueSetTemp = 1;
    } else {
      this._firstValueSet = false;
    }
    this._oldValue = this.value;
    this._lastValueChanged = this._oldValue !== value;
    // Log.log(`setValue: ${value} oldValue: ${this._oldValue} changend: ${this._lastValueChanged}`);
    if (this.isNumber && value !== null && value !== '' && typeof value !== 'undefined') {
      if (value !== '-') {
        const valueToSet: any = parseFloat(typeof value?.replace === 'function' ? value.replace(',', '.') : value);
        super.setValue(valueToSet, options);
      }
    } else {
      super.setValue(value, options);
    }
  }
}
