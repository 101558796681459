import {Pipe, PipeTransform} from '@angular/core';
import {NxtWhatsAppChat} from '../../../common-interfaces/whats-app/nxt-whatsapp-message.interface';

@Pipe({
  name: 'nxtWhatsappChatLastBody',
  standalone: true
})
export class WhatsappChatLastBodyPipe implements PipeTransform {

  constructor() {
  }

  transform(chat: NxtWhatsAppChat): any {
    if (chat.lastBodyCustomerTimestamp > chat.lastBodyMeTimestamp) {
      return chat.lastBodyCustomer;
    } else {
      return chat.lastBodyMe;
    }
  }
}
