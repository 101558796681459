<div fxLayout="column">
  <div *ngIf="message" style="margin-bottom:24px; margin-top:10px;" [innerHTML]="message | safeHtml">
  </div>
  <div *ngIf="inputFormControl">
    <nxt-textarea
      [placeholder]="placeholder"
      [nxtFormControl]="inputFormControl"
      [rows]="15"
      [cols]="100"

    ></nxt-textarea>
  </div>
  <div style="padding-left: 10px;">{{charCounterText}}</div>

  <div fxLayout="row" fxLayoutAlign="space-around">
    <nxt-button (click)="cancelClicked()">ABBRECHEN</nxt-button>
    <nxt-button (click)="okClicked()">{{buttonText}}</nxt-button>
  </div>
</div>
<!--< button [ngStyle]="{backgroundColor: color.backgroundColor, color: color.fontColor}" style="margin: 10px;" mat-raised-button color="primary"
        (click)="close(color)">{{buttonText}}</button>-->



