<div style="position: fixed; left: -500px;">
  <nxt-button-icon>fullscreen</nxt-button-icon>
  <span class="material-symbols-outlined icon-image-preview">shopping_cart</span>
</div>

<div class="wrapper">
  <div class="app-button">
    <nxt-app-menu></nxt-app-menu>
  </div>
  <div *ngIf="false" style="font-size: 100px; color: red">HIER IST DIE APP COMPONENT</div>
  <router-outlet (keydown.ctrl.a)="handleKey($event)"></router-outlet>
</div>



<div fxLayout="row" class="mini-info-bar" *ngIf="showVersionInfo">
  <div class="version-info" *ngIf="showVersionInfo && false">
    {{ version }}
  </div>
  <div (dblclick)="disconnect()" *ngIf="showVersionInfo"
       [class]="((this.socketService.state | async).connected ? 'background-green': 'background-red') + ' info-char no-select'">
    C
  </div>

  <div (dblclick)="disconnect()" *ngIf="showVersionInfo"
       [class]="((this.socketService.state | async).authenticated ? 'background-green': 'background-red') + ' info-char no-select'">
    A
  </div>

  <div (dblclick)="disconnect()" *ngIf="showVersionInfo"
       [class]="((this.firebaseLoginService.isLoggedIn | async) ? 'background-green': 'background-red') + ' info-char no-select'">
    G
  </div>
</div>
