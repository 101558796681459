import {Component, Input, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {TranslateDirective} from '../../../directives/translate.directive';

@Component({
    selector: 'nxt-declarations-of-consent-section',
    template: `
    <!-- DEINE DATEN -->
    @if (lang) {
      <div class="flex flex-col w-full">
        <div class="flex text-xl justify-center section-header">
          <div [nxtTranslate]="lang" class="text-center uppercase" [innerHTML]="titleText"></div>
        </div>
        <div class="flex flex-col p-2">
          <ng-content></ng-content>
        </div>
      </div>
    }
  `,
    styleUrls: ['./declarations-of-consent-section.component.scss'],
    imports: [
        TranslateDirective
    ]
})

export class DeclarationsOfConsentSectionComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  @Input() titleText: string;
  @Input() lang = 'de';

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {

  }

}
