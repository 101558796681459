<div fxLayout="column" class="w-full text-90">
  <div fxFlex fxLayout="row" fxLayoutGap="0.5rem" class="w-full scrollable">
    <div fxFlex="40%" fxLayout="column">

      <div class="w-full py-2" fxLayout="column" *ngIf="ngConfig">
        <div class="w-full" fxLayout="column" style="border:1px solid gray" fxLayoutAlign="start center">
          <div fxLayout="row" fxLayoutAlign="center" class="p-2 text-120">ALLGEMEIN</div>

          <div fxLayout="row" class="w-full px-4" fxLayoutAlign="start center">

            <nxt-time-picker placeholder="Beginn" style="width: 120px" [noPadding]="true" minTime="08:00" maxTime="12:00"
                             [value]="ngConfig.studioWorkStart | duration: 'HH:mm'"
                             (valueChange)="setStudioWorkStart($event)"></nxt-time-picker>

            <nxt-time-picker placeholder="Ende" [noPadding]="true" style="width: 120px" minTime="00:00" maxTime="23:00"
                             [value]="ngConfig.studioWorkEnd | duration: 'HH:mm'"
                             (valueChange)="setStudioWorkEnd($event)"></nxt-time-picker>


          </div>
          <div fxLayout="row" class="w-full px-4 pt-2" fxLayoutAlign="start center">
            <nxt-slide-toggle [(value)]="ngConfig.workOnSunday">Sonntags geöffnet</nxt-slide-toggle>
          </div>

          <div fxLayout="row" class="w-full px-4 pt-2" fxLayoutAlign="start center">
            <nxt-slide-toggle [(value)]="sendArtistWorkTimeInfo">Arbeitszeiten an Artist schicken</nxt-slide-toggle>
          </div>

          <div fxLayout="row" class="w-full px-4 pt-2" fxLayoutAlign="start center">
            <nxt-slide-toggle [(value)]="ngConfig.isWalkInToday">Ist Walk-In</nxt-slide-toggle>
          </div>

          <div fxLayout="row" class="w-full px-4 pt-2" fxLayoutAlign="start center">
            <nxt-slide-toggle [(value)]="ngConfig.printSecondWalkInNoIfArtistOpen">2x Walk-In drucken wenn Artist offen</nxt-slide-toggle>
          </div>
          <div fxLayout="row" class="w-full px-4 pt-2" fxLayoutAlign="start center">
            <nxt-slide-toggle [(value)]="ngConfig.enableArtistConfirm">Artist Empfangsbestätigung</nxt-slide-toggle>
          </div>

          <div fxLayout="row" class="w-full px-4 pt-2" fxLayoutAlign="start center">
            <nxt-slide-toggle [(value)]="ngConfig.sendToArtistViaTelegram">Artists via Telegram</nxt-slide-toggle>
          </div>
          <div fxLayout="row" class="w-full px-4 pt-2" fxLayoutAlign="start center">
            <nxt-slide-toggle [(value)]="printAllCashPaymentReceipts">Belegdrucker</nxt-slide-toggle>
          </div>
          <div fxLayout="row" class="w-full px-4 pt-2" fxLayoutAlign="start center">
            <nxt-slide-toggle [(value)]="ngConfig.cashReportHideArtistGetValues">CashReport: Artists-Geld ausblenden (Empfang)</nxt-slide-toggle>
          </div>
          <div fxLayout="row" class="w-full px-4 pt-2" fxLayoutAlign="start center">
            <nxt-slide-toggle [disabled]="disableChangeMustEventRating()" [(value)]="mustEventRating">Tattoo-Bewertungen</nxt-slide-toggle>
            @if (mustEventRating) {
              <nxt-input [readonly]="disableChangeMustEventRating()" [(value)]="mustEventRatingMin" [isNumber]="true" placeholder="Min Bewertungen"></nxt-input>
            }
          </div>
          <div fxLayout="row" class="w-full px-4 pt-2" fxLayoutAlign="start center">
            <nxt-slide-toggle [disabled]="disableChangeMustEventRating()" [(value)]="mustContactStreet">Kunde mit Straße</nxt-slide-toggle>
          </div>
          <br/>
        </div>
      </div>

      <div class="w-full py-2" fxLayout="column" *ngIf="ngConfig">
        <div class="w-full" fxLayout="column" style="border:1px solid rgb(128,128,128)" fxLayoutAlign="start center">
          <div fxLayout="row" fxLayoutAlign="center" class="p-2 text-120">GUTSCHEINE</div>
          <div class="w-full p-2" fxLayout="row">
            <table class="w-full" *ngIf="ngConfig">
              <tr>
                <td>Text unter Logo</td>
                <td style="width: 100%">
                  <nxt-input [(value)]="ngConfig.voucher.logoTitle" [noPadding]="true"></nxt-input>
                </td>
              </tr>
              <tr>
                <td>Adresstext</td>
                <td style="width: 100%">
                  <nxt-input [(value)]="ngConfig.voucher.address" [noPadding]="true"></nxt-input>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="w-full py-2" fxLayout="column" *ngIf="ngConfig">
        <div class="w-full" fxLayout="column" style="border:1px solid gray" fxLayoutAlign="start center">
          <div fxLayout="row" fxLayoutAlign="center" class="p-2 text-120">WHATSAPP</div>
          <div class="w-full p-2" fxLayout="row">
            <nxt-settings-whatsapp [whatsApp]="whatsApp"></nxt-settings-whatsapp>
          </div>
        </div>
      </div>

      <div class="w-full py-2" fxLayout="column" *ngIf="ngConfig">
        <div class="w-full" fxLayout="column" style="border:1px solid gray" fxLayoutAlign="start center">
          <div fxLayout="row" fxLayoutAlign="center" class="p-2 text-120">RECHNUNGSDATEN</div>
          <div class="w-full p-2" fxLayout="row">
            <nxt-settings-ng-config-invoice-data [ngConfig]="ngConfig"></nxt-settings-ng-config-invoice-data>
          </div>
        </div>
      </div>

      <div class="w-full py-2" fxLayout="column" *ngIf="ngConfig">
        <div class="w-full p-2" fxLayout="column" style="border:1px solid gray" fxLayoutAlign="start center">
          <div fxLayout="row" fxLayoutAlign="center" class="p-2 text-120">STUDIOS</div>

          <div class="w-full" style="border: 1px solid gray" *ngFor="let studio of ngConfig.studios">
            <div class="w-full p-2" fxLayout="row">
              <nxt-settings-ng-config-studio [studio]="studio"></nxt-settings-ng-config-studio>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="20%" fxLayout="column">


      <div class="w-full py-2" fxLayout="column">
        <div class="w-full" fxLayout="column" style="border:1px solid gray" fxLayoutAlign="start center">
          <div fxLayout="row" fxLayoutAlign="center" class="p-2 text-120">TELEGRAM-GRUPPEN</div>
          <div class="w-full p-2" fxLayout="row">
            <table class="w-full">
              <tr *ngFor="let telegramGroup of telegramGroups">
                <td>{{ telegramGroup.description }}</td>
                <td>
                  <nxt-input [isNumber]="true" [(value)]="telegramGroup.groupId" [noPadding]="true"></nxt-input>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="w-full py-2" fxLayout="column" *ngIf="ngConfig">
        <div class="w-full p-2" fxLayout="column" style="border:1px solid gray" fxLayoutAlign="start center">
          <div fxLayout="row" fxLayoutAlign="center" class="p-2 text-120">BANK-IMPORT</div>
          <div class="w-full p-2" fxLayout="row">
            <nxt-settings-bank-import [data]="bankData"></nxt-settings-bank-import>
          </div>
        </div>
      </div>

      <div class="w-full py-2" fxLayout="column" *ngIf="ngConfig">
        <div class="w-full p-2" fxLayout="column" style="border:1px solid gray" fxLayoutAlign="start center">
          <div fxLayout="row" fxLayoutAlign="center" class="p-2 text-120">QONTO</div>
          <div class="w-full p-2" fxLayout="row">
            <nxt-settings-qonto [data]="qontoData"></nxt-settings-qonto>
          </div>
        </div>
      </div>

      <div class="w-full py-2" fxLayout="column" *ngIf="paypalData">
        <div class="w-full p-2" fxLayout="column" style="border:1px solid gray" fxLayoutAlign="start center">
          <div fxLayout="row" fxLayoutAlign="center" class="p-2 text-120">PAYPAL-ACCOUNTS</div>
          <div class="w-full p-2" fxLayout="column">
            <div *ngFor="let paypalConfig of paypalData.configs" style="border: 1px solid gray;" class="mb-3 p-2">
              <nxt-settings-paypal-import [config]="paypalConfig"></nxt-settings-paypal-import>
              <nxt-button-icon (click)="removePaypal(paypalConfig)">remove</nxt-button-icon>
            </div>
            <nxt-button-icon (click)="newPaypal()">add</nxt-button-icon>
            <nxt-input [isNumber]="true" [(value)]="paypalData.timer" [noPadding]="true" placeholder="Timer"></nxt-input>
          </div>
        </div>
      </div>

      <div class="w-full py-2" fxLayout="column" *ngIf="googleCalendar">
        <div class="w-full p-2" fxLayout="column" style="border:1px solid gray" fxLayoutAlign="start center">
          <div fxLayout="row" fxLayoutAlign="center" class="p-2 text-120">GOOGLE KALENDER</div>
          <div class="w-full p-2" fxLayout="row">
            <nxt-input type="password" placeholder="Passwort" [(value)]="googleCalendar.password"></nxt-input>
          </div>
        </div>
      </div>

      <div class="w-full py-2" fxLayout="column" *ngIf="icloudSync">
        <div class="w-full p-2" fxLayout="column" style="border:1px solid gray" fxLayoutAlign="start center">
          <div fxLayout="row" fxLayoutAlign="center" class="p-2 text-120">FOTO ICLOUD</div>
          <div class="flex w-full flex-col p-2">
            <nxt-input placeholder="Apple ID" [(value)]="icloudSync.appleId"></nxt-input>
            <nxt-input type="password" placeholder="Passwort" [(value)]="icloudSync.password"></nxt-input>
          </div>
        </div>
      </div>


    </div>

  </div>


  <div fxLayout="row" fxLayoutAlign="center">
    <div class="p-2">
      <nxt-button (click)="save()">speichern</nxt-button>
    </div>
  </div>
</div>
