<div class="w-full h-100" fxLayout="column" class="nxt-page">
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <nxt-input fxFlex="35" [(value)]="blocks[0].text" placeholder="Text oben"></nxt-input>
    <nxt-input fxFlex="35" [(value)]="blocks[1].text" placeholder="Text unten"></nxt-input>
    <nxt-select
      placeholder="Schriftdicke"
      fxFlex="20"
      [options]="[{value: 700, text: '700'}, {value: 800, text: '800'}, {value:900, text: '900'}]"
      [(value)]="fontWeight"
    ></nxt-select>
    <nxt-select
      placeholder="Farbe 2"
      fxFlex="20"
      [options]="[{value: '#3c3b3f', text: 'grau'}, {value: '#121818', text: 'fast schwarz'}]"
      [(value)]="color2"
    ></nxt-select>
    <nxt-select
      placeholder="Abstand"
      fxFlex="20"
      [options]="[{value: 0.015, text: 'wenig'}, {value: 0.0225, text: 'mittle'}, {value:0.03, text: 'viel'}]"
      [(value)]="multiplier"
    ></nxt-select>
    <nxt-input fxFlex="10" [isNumber]="true" [(value)]="resultMaxWidth" placeholder="Breite (px)"></nxt-input>
  </div>
  <div *ngIf="true" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
    <div #block [ngStyle]="{background: '#b8860b', fontFamily:fontFamily, fontWeight: fontWeight}" class="block" fxLayout="column"
         fxLayoutAlign="center center">
      <div #firstBlock [ngStyle]="getStyle(0)">{{blocks[0].text}}</div>
      <div #secondBlock [ngStyle]="getStyle(1)">{{blocks[1].text}}</div>
    </div>
  </div>

  <!--<div *ngIf="true" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
    <div #block style="background: #b8860b" class="block" fxLayout="column" fxLayoutAlign="center center">
      <div #firstBlock [ngStyle]="getStyle(0)">{{blocks[0].text}}</div>
      <div #secondBlock [ngStyle]="getStyle(1)">{{blocks[1].text}}</div>
    </div>
  </div>-->


  <div class="bottom-bar" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-around center" style="position:fixed; bottom:25px; left: 0; right: 0">
      <nxt-button (click)="autoFormat()">hayat</nxt-button>
      <!--<nxt-button (click)="download(false)">herunterladen</nxt-button>-->
      <nxt-button (click)="download(true)">hayat + herunterladen</nxt-button>
      <nxt-button (click)="close()">schließen</nxt-button>
    </div>

    <!--<table>
      <tr>
        <th></th>
        <th>text</th>
        <th>größe</th>
        <th>abstand oben/unten</th>
        <th>abstand links/rechts</th>
      </tr>
      <tr>
        <td>oben</td>
        <td>
          <nxt-input style="color:black !important; font-size: 20px; text-transform: uppercase" type="text" [(value)]="blocks[0].text"></nxt-input>
        </td>
        <td><input type="number" [(ngModel)]="blocks[0].fontSize"/></td>
        <td><input type="number" [(ngModel)]="blocks[0].paddingY"/></td>
        <td><input type="number" [(ngModel)]="blocks[0].paddingX"/></td>
      </tr>
      <tr>
        <td>unten</td>

        <td><input style="color:black !important; font-size: 20px; text-transform: uppercase" type="text" [(ngModel)]="blocks[1].text"/></td>
        <td><input type="number" [(ngModel)]="blocks[1].fontSize"/></td>
        <td><input type="number" [(ngModel)]="blocks[1].paddingY"/></td>
        <td><input type="number" [(ngModel)]="blocks[1].paddingX"/></td>
      </tr>
    </table>-->
  </div>
</div>
