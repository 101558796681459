import {Component, inject, Injector, Input, OnChanges, OnInit, runInInjectionContext, SimpleChanges} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {firstValueFrom} from 'rxjs';
import {ObjectTools} from '../../common-browser/helpers/object.tools';
import {JsonTools} from '../../common-browser/helpers/json.tools';
import {rdiffResult} from 'recursive-diff';
import {DecimalTools} from '../../common-browser/helpers/decimal.tools';
import {StringTools} from '../../common-browser/helpers/string.tools';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {ColorTools} from '../../common-browser/helpers/color.tools';
import {Clipboard} from '@angular/cdk/clipboard';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {PermissionDirective} from '../../directives/permission.directive';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {NgIf} from '@angular/common';
import {ConfigService} from '../../services/config.service';
import {LoginService} from '../../services/login.service';
import {FirestoreService} from '../../services/firestore.service';
import {keys} from 'lodash';

export interface NxtDiff<T> {
  diff: rdiffResult;
  text: string;
  name: string;
  propDef: NxtHistoryPropDef;
  data: T;
}

export interface NxtHistoryEntryNew {
  diffTextLines: string;
  nextItem?: NxtHistoryEntryNew;
  prevItem?: NxtHistoryEntryNew;
  data: string;
  created: number;
  user: string;
  action: string;
  dataToCompare: any;
  diffsToPrev: rdiffResult[];

}

export interface NxtHistoryPropDef<T = any> {
  hideOldValueOnUpdate?: boolean;
  textGetter?: (params: { data: any, diff: rdiffResult, value: any }) => string;
  hideOnAdd?: (value: any, nxtDiff: NxtDiff<T>, data: T) => boolean;
  hideOnAddOrUpdate?: (value: any, nxtDiff: NxtDiff<T>) => boolean;
  /**
   * aus Compare-Objekt löschen bevor es verglichen wird?
   */
  deleteInCompareData?: (params: { value: any, data: T }) => boolean;
  valueGetter?: (value: any, data: T) => Promise<any> | any;
  field: keyof T;
  fields?: string[];
  name: string;
  type: NxtFieldType;
  hide?: boolean;
  showDebug?: boolean;
  /** default true */
  // showOldValueOnUpdate?: boolean;
}


@Component({
  selector: 'nxt-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  imports: [NgIf, SlideToggleComponent, PermissionDirective, NxtDatagridComponent, FlexModule],
})
export class HistoryComponent extends NxtComponent implements OnInit, NxtOnDestroy, OnChanges {


  constructor(
    private clipboard: Clipboard,
    private configService: ConfigService,
    private loginService: LoginService,
    private firestoreService: FirestoreService,
    private injector: Injector,
  ) {
    super();

  }

  @Input() hiddenProps: (string | RegExp)[] = [];
  @Input() debugProps: string[] = [];
  @Input() propDefs: NxtHistoryPropDef[];
  @Input() firstItem: any = {};
  @Input() prepareDataToCompare: (data) => any;
  @Input() prepareRawData: (data) => any;
  @Input() filterRawItem: (data: NxtHistoryEntryNew) => boolean;
  @Input() filterItem: (data: NxtHistoryEntryNew) => boolean;


  @Input() table: string;
  @Input() id: string;
  @Input() actions: ('update' | 'create')[];
  columnDefs: NxtColDef[] = [
    {
      headerName: 'Datum', field: 'created', nxtFieldType: NxtFieldType.Text, sort: 'desc', cellRenderer: (params: any) => {
        if (this.loginService.isJulian()) {
          return DateTools.format(params.data.created, 'dd.MM.yyyy HH:mm:ss.SSSS');
        }
        return DateTools.format(params.data.created, 'dd.MM.yyyy HH:mm:ss');
      },
    },
    {headerName: 'Benutzer', field: 'user', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Aktion', field: 'action', nxtFieldType: NxtFieldType.Text},
    {
      headerName: 'Änderungen', field: 'diffTextLines', autoHeight: true, nxtFieldType: NxtFieldType.Text, cellRenderer: (params: any) => params.data.diffTextLines,
      suppressKeyboardEvent: params => {
        return params.event.ctrlKey && params.event.key === 'c';
      }, nxtOnCellDoubleClicked: (params) => this.clipboard.copy(params.data.data),
    },
  ];
  public historyData = [];
  showDebugProps = false;

  ngOnInit() {
    // this.firestoreService.test();
  }

  nxtOnDestroy() {

  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.table || changes.id || changes.actions) {
      if (this.table && this.id && this.actions) {
        this.load();
      }
    }
  }

  public async load() {
    runInInjectionContext(this.injector, async () => {
      const historyItems = (await firstValueFrom(
        this.firestoreService.collection<NxtHistoryEntryNew>('/history/' + this.table + '/' + this.id, ref => ref.where('action', 'in', this.actions))
          .get())).docs.map(d => d.data());

      historyItems.forEach(h => h.created = DateTools.parse(h.created));

      let historyItemsSorted = historyItems.sortNumber('created');
      if (this.filterRawItem) {
        historyItemsSorted = historyItemsSorted.filter(this.filterRawItem);
      }
      this.appendPrevAndNext(historyItemsSorted);
      await this.appendDataToCompare(historyItemsSorted);
      this.appendDiffs(historyItemsSorted);
      this.appendFormattedDiffs(historyItemsSorted);
      if (this.filterItem) {
        this.historyData = historyItemsSorted.filter(this.filterItem);
      } else {
        this.historyData = historyItemsSorted;
      }
      this.historyData = this.historyData.filter(h => !!h.diffTextLines);
    });
  }

  private appendDiffs(items: NxtHistoryEntryNew[]) {
    for (const [index, currentItem] of items.entries()) {
      currentItem.diffsToPrev = ObjectTools.getDiff(currentItem.prevItem.dataToCompare, currentItem.dataToCompare, true);
    }
  }

  private async appendDataToCompare(historyItemsSorted: NxtHistoryEntryNew[]) {
    for (const [index, item] of historyItemsSorted.entries()) {
      let data = JsonTools.parse(item.data);
      const originalData = ObjectTools.clone(data);
      for (const hiddenProp of this.hiddenProps) {
        if (typeof (hiddenProp as any).lastIndex === 'number') {
          // regex
          for (const key of keys(data)) {
            if ((hiddenProp as any).test(key)) {
              ObjectTools.delete(data, key);
            }
          }
        } else {
          ObjectTools.delete(data, hiddenProp);
        }
      }
      if (this.prepareRawData) {
        data = this.prepareRawData(data);
      }
      const showAllData = true;
      const newData = showAllData ? ObjectTools.clone(data) : {};
      for (const propDef of this.propDefs) {
        if (!propDef.fields) {
          propDef.fields = [];
        }
        for (const field of [propDef.field, ...propDef.fields]) {
          let value = ObjectTools.get(data, field);
          if (typeof value !== 'undefined') {
            if (propDef.valueGetter) {
              value = await propDef.valueGetter(value, data);
            } else {
              value = this.defaultValueGetterByType(propDef.type, value);
            }
            if (propDef.deleteInCompareData && propDef.deleteInCompareData({data: originalData, value})) {
              ObjectTools.delete(newData, field);
            } else {
              ObjectTools.set(newData, field, value);
            }
          }
        }
      }
      if (this.prepareDataToCompare) {
        item.dataToCompare = this.prepareDataToCompare(newData);
      } else {
        item.dataToCompare = newData;
      }
    }
  }

  private appendPrevAndNext(items: NxtHistoryEntryNew[]) {
    for (const [index, currentItem] of items.entries()) {
      const prevItem: NxtHistoryEntryNew = index > 0 ? items[index - 1] : {
        dataToCompare: this.firstItem,
        data: '',
        created: 0,
        user: '',
        action: 'initial',
        diffsToPrev: [],
        diffTextLines: '',
      };
      const nextItem = index < items.length - 1 ? items[index + 1] : undefined;
      currentItem.prevItem = prevItem;
      currentItem.nextItem = nextItem;
    }
  }

  private appendFormattedDiffs(historyItemsSorted: NxtHistoryEntryNew[]) {
    for (const [index, item] of historyItemsSorted.entries()) {
      let nxtDiffs: NxtDiff<any>[] = [];
      for (const diff of item.diffsToPrev) {
        try {
          let propDef = this.propDefs.find(p => p.field === diff.path.join('.'));
          if (!propDef) {
            propDef = this.propDefs.find(p => p.fields.includes(diff.path.join('.')));
          }
          if (propDef) {
            let text = diff.val;
            if (propDef.textGetter) {
              text = propDef.textGetter({data: item.dataToCompare, diff, value: diff.val});
            } else {
              text = this.defaultTextGetter(propDef, diff);
            }
            nxtDiffs.push({diff, text, propDef, name: propDef.name, data: item});
          } else {
            let text = diff.op === 'delete' ? diff.oldVal : diff.val;
            if (typeof text === 'object') {
              text = JsonTools.stringify(text);
            }
            nxtDiffs.push({diff, text, propDef, name: diff.path.join('.'), data: item});
          }
        } catch (err) {
          debugger;
          throw Error(err);
        }
      }

      const sortObj = {};
      for (const [i, propDef] of this.propDefs.entries()) {
        sortObj[propDef.field] = i;
      }

      nxtDiffs = nxtDiffs.sort((d1, d2) => {
        const index1 = d1.propDef ? sortObj[d1.propDef.field] : 999999;
        const index2 = d2.propDef ? sortObj[d2.propDef.field] : 999999;

        if (index1 > index2) {
          return 1;
        } else if (index1 < index2) {
          return -1;
        }
        return d1.name.localeCompare(d2.name);
      });

      const diffsTextLines: string[] = [];

      for (const nxtDiff of nxtDiffs) {
        if (nxtDiff.diff.op === 'add' && nxtDiff.propDef?.hideOnAdd) {
          if (nxtDiff.propDef.hideOnAdd(nxtDiff.diff.val, nxtDiff, item)) {
            continue;
          }
        }

        if (['add', 'update'].includes(nxtDiff.diff.op) && nxtDiff.propDef?.hideOnAddOrUpdate) {
          if (nxtDiff.propDef.hideOnAddOrUpdate(nxtDiff.diff.val, nxtDiff)) {
            continue;
          }
        }


        diffsTextLines.push(this.buildDiffLine(nxtDiff.diff.op, nxtDiff.name, nxtDiff.text));
      }
      item.diffTextLines = diffsTextLines.filter(line => !!line).join('<br/>');
    }
  }

  private getOperationText(operation: string) {
    switch (operation) {
      case 'update':
        return 'aktualisiert';
      case 'delete':
        return 'gelöscht';
      case 'add':
        return 'neu';
    }
  }

  private buildDiffLine(op: string, name: string, text: string) {
    if (typeof text === 'string' && text.startsWith('"')) {
      text = text.trimChar('"');
    }
    if (op === 'delete') {
      return this.getOperationText(op) + ' <span style="color:#5493ff;user-select: all">' + name + '</span><span style="color:#5493ff;">:</span>&nbsp;<span style="user-select: all; color:' + ColorTools.Red + ';">' + text + '</span>';
    } else {
      return this.getOperationText(op) + ' <span style="color:#5493ff;user-select: all">' + name + '</span><span style="color:#5493ff;">:</span>&nbsp;<span style="user-select: all; color:' + ColorTools.GreenLight + ';">' + text + '</span>';
    }
  }

  private defaultTextGetter(propDef: NxtHistoryPropDef, diff: rdiffResult) {
    if (diff.op === 'delete' && !diff.val) {
      return this.defaultTextGetterWithoutOldVal(propDef, diff.oldVal);
    }
    if (diff.op === 'add' || !diff.oldVal || propDef.hideOldValueOnUpdate) {
      return this.defaultTextGetterWithoutOldVal(propDef, diff.val);
    } else {
      return this.defaultTextGetterWithOldVal(propDef, diff.val, diff.oldVal);
    }
  }


  private defaultTextGetterWithoutOldVal(propDef: NxtHistoryPropDef, value: any) {
    switch (propDef.type) {
      case NxtFieldType.Money:
      case NxtFieldType.MoneyFull:
        return DecimalTools.toMoneyString(parseFloat(value));
      case NxtFieldType.Date_germanDateTime:
        return DateTools.format(value, 'dd.MM.yyyy HH:mm');
      case NxtFieldType.Date_germanDate:
        return DateTools.format(value, 'dd.MM.yyyy');
      case NxtFieldType.Boolean:
        return value ? 'Ja' : 'Nein';
      case NxtFieldType.Percentage:
        return MathTools.round(parseFloat(value), 1) + '%';
      default:
        return JsonTools.stringifyFormat(value);
        return value;
    }
  }

  private defaultTextGetterWithOldVal(propDef: NxtHistoryPropDef, newValue: any, oldValue: any) {
    const arrowWithSpaces = '&nbsp;&nbsp;' + StringTools.arrowRight + '&nbsp;&nbsp;';
    switch (propDef.type) {
      case NxtFieldType.Money:
        return DecimalTools.toMoneyString(parseFloat(oldValue)) + ' ' + StringTools.arrowRight + ' ' + DecimalTools.toMoneyString(parseFloat(newValue));
      case NxtFieldType.Date_germanDateTime:
        if (DateTools.format(oldValue, 'dd.MM.yyyy') === DateTools.format(newValue, 'dd.MM.yyyy')) {
          return DateTools.format(oldValue, 'HH:mm') + arrowWithSpaces + DateTools.format(newValue, 'HH:mm');
        } else {
          return DateTools.format(oldValue, 'dd.MM.yyyy HH:mm') + arrowWithSpaces + DateTools.format(newValue, 'dd.MM.yyyy HH:mm');
        }
      case NxtFieldType.Date_germanDate:
        return DateTools.format(oldValue, 'dd.MM.yyyy') + arrowWithSpaces + DateTools.format(newValue, 'dd.MM.yyyy');
      case NxtFieldType.Percentage:
        return MathTools.round(parseFloat(oldValue), 1) + '%' + arrowWithSpaces + MathTools.round(parseFloat(newValue), 1) + '%';
      case NxtFieldType.Text:
        return '<span style="color:' + ColorTools.Red + ';">' + oldValue + '</span>' + arrowWithSpaces + newValue;
      default:
        return this.defaultTextGetterWithoutOldVal(propDef, newValue);
    }
  }

  private defaultValueGetterByType(type: NxtFieldType, value: any) {
    switch (type) {
      case NxtFieldType.Date_germanDateTime:
        return value ? DateTools.parse(value) : null;
      case NxtFieldType.Date_germanDate:
        return value ? DateTools.parse(value) : null;
      case NxtFieldType.Boolean:
        if (typeof value === 'string') {
          return value === 'true';
        }
        return !!value;
    }
    return value;
  }

  showDebugPropsChanged() {
    this.hiddenProps = this.hiddenProps.filter(p => typeof p === 'string' && !this.debugProps.includes(p));
    if (!this.showDebugProps) {
      this.hiddenProps.push(...this.debugProps);
    }
    this.load();
  }
}
