import {Component, Input, OnInit} from '@angular/core';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgIf, NgStyle} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
  selector: 'nxt-col',
  templateUrl: './col.component.html',
  styleUrls: ['./col.component.scss'],
  imports: [FlexModule, NgStyle, ExtendedModule],
  standalone: true,
})
export class ColComponent implements OnInit {

  @Input() align: 'center' | 'start' | 'end' | 'space-around' | 'space-between' = 'start';
  @Input() show = true;

  borderWidth = 0;

  /*get borderWidth(): string {
    if ((window as any).debug) {
      return '1';
    } else {
      return '0';
    }
  }*/

  constructor() {

  }

  ngOnInit() {
  }

}
