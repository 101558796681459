import {Component, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {SocketService} from '../../services/socket/socket.service';
import {NxtReminder} from '../../common-interfaces/nxt.reminder.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {InputComponent} from '../form-controls/input/input.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {DialogService} from '../../services/dialog.service';
import {EventTools} from '../../common-browser/helpers/event.tools';
import {WorkplaceTools} from '../../common-browser/helpers/workplace.tools';

@Component({
    selector: 'nxt-reminders',
    templateUrl: './reminders.component.html',
    styleUrls: ['./reminders.component.scss'],
    imports: [FlexModule, InputComponent, NxtButtonIconComponent, NxtDatagridComponent]
})

export class RemindersComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  columnDefs: NxtColDef<NxtReminder>[] = [
    {headerName: 'Text', field: 'description', maxWidth: 500},
    {headerName: 'Für', valueGetter: (params) => params.data.toDoByUser ? params.data.toDoByUser : WorkplaceTools.getText(params.data.toDoByWorkplace)},
    {headerName: 'Erstellt', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDateTime},
    {headerName: 'Fällig original', field: 'originalReminderDateTime', nxtFieldType: NxtFieldType.Date_germanDateTime},
    {headerName: 'Fällig', field: 'reminderDateTime', nxtFieldType: NxtFieldType.Date_germanDateTime},
    // {headerName: 'Erstellt', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDateTime},
    {headerName: 'Erledigt von', field: 'doneBy', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Erledigt', field: 'doneAt', nxtFieldType: NxtFieldType.Date_germanDateTime},
    // {headerName: 'Info', field: 'description', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Typ', field: 'type'},
    {
      headerName: 'Info', field: 'refType', cellRenderer: (params: any) => {
        if (params.value === 'event') {
          return params.data?.refObj?.title || 'Termin';
        }
      },
      nxtOnCellClicked: (params) => {
        if (params.data.refType === 'event') {
          this.dialogService.showEvent(params.data.refId);
        }
      }, getQuickFilterText: (params) => {
        if (params.data.refType === 'event') {
          if (EventTools.isEvent(params.data?.refObj)) {
            return params.data?.refObj?.title;
          }
        }
      }
    },
  ];
  reminders: NxtReminder[];
  quickFilterText = '';


  constructor(
    @Optional() public dialogRef: MatDialogRef<RemindersComponent>,
    private socketService: SocketService,
    private dialogService: DialogService,
  ) {
    super();
  }

  async ngOnInit() {
    this.reminders = await this.socketService.getRemindersSince(Date.now().dateAddDays(-5).dateFormat('yyyy-MM-dd'), true);
    this.reminders = this.reminders.sortNumber('originalReminderDateTime', true);
  }

  nxtOnDestroy() {

  }

  close() {
    this.dialogRef.close();
  }
}
