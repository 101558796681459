import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, effect, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../services/socket/socket.service';
import {NxtCalendarEvent, NxtCalendarEventFile, NxtCalendarEventRating, NxtEventRatingLog} from '../../common-interfaces/nxt.calendar-event.interface';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {Duration2Pipe} from '../../pipes/duration-2.pipe';
import {Money2Pipe} from '../../pipes/money-2.pipe';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {SocketInterfaceResponse} from '../../common-interfaces/socket/socket-interface';
import {toObservable} from '@angular/core/rxjs-interop';
import {DialogService, OpenerComponent} from '../../services/dialog.service';
import {NxtPageComponent} from '../../components/nxt-page/nxt-page.component';
import {NxtPageHeaderComponent} from '../../components/nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageHeaderTitleComponent} from '../../components/nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageContentComponent} from '../../components/nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../../components/nxt-page/nxt-page-footer/nxt-page-footer.component';
import {SlideToggleComponent} from '../../components/form-controls/slide-toggle/slide-toggle.component';
import {BodyPutService} from '../../services/body-put.service';
import {TextareaComponent} from '../../components/form-controls/textarea/textarea.component';
import {LoginService} from '../../services/login.service';
import {EventTools} from '../../common-browser/helpers/event.tools';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {DatePicker2Component} from '../../components/form-controls/date-picker-2/date-picker-2.component';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtRoundPipe} from '../../pipes/nxt-round-pipe';
import {Clipboard} from '@angular/cdk/clipboard';
import {ActivatedRoute, Router} from '@angular/router';
import {PhotoSwipeDirective} from '../../directives/photo-swipe/photo-swipe.directive';
import {TypeTools} from '../../common-browser/helpers/type.tools';
import {MultiClickDirective} from '../../directives/multi-click.directive';
import {PermissionDirective} from '../../directives/permission.directive';
import {MatIcon} from '@angular/material/icon';
import {NxtDriveFileSubTypePipe} from '../../pipes/nxt-drive-file-sub-type-pipe';
import {NxtEventFilePipe} from '../../pipes/nxt-event-file.pipe';
import {VideoComponent} from '../../components/video/video.component';
import {firstValueFrom} from 'rxjs';
import {WithoutNxtDbFields} from '../../common-interfaces/nxt.db-fields.interface';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {PermissionService} from '../../services/permission.service';
import {ConfigService} from '../../services/config.service';
import Slide from 'photoswipe/dist/types/slide/slide';
import {PhotoEditorComponent} from '../../components/photos/photo-editor/photo-editor.component';

@Component({
  selector: 'nxt-events-rating',
  templateUrl: './events-rating.component.html',
  styleUrls: ['./events-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtButtonComponent, MatButtonToggle, MatButtonToggleGroup, Duration2Pipe, Money2Pipe, NxtDatePipe, NxtPageComponent, NxtPageHeaderComponent, NxtPageHeaderTitleComponent, NxtPageContentComponent, NxtPageFooterComponent, SlideToggleComponent, TextareaComponent, NxtButtonIconComponent, DatePicker2Component, NxtRoundPipe, PhotoSwipeDirective, MultiClickDirective, PermissionDirective, MatIcon, NxtDriveFileSubTypePipe, NxtEventFilePipe, SafeHtmlPipe]
})
export class EventsRatingComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  eventFileThumbReloadIndicator = computed(() => {
    this.event();
    return Date.now().toString();
  });

  constructor() {
    super();
    this.pushSubscription = toObservable(this.event).subscribe(event => this.loadEvent());

    effect(() => {
      if (this.event()) {
        this.ratingsLog = {};
        this.resetRatingLog();
        if (this.permissionService.isJulian()) {
          this.showRatingLogClicked();
        }
      }
    });


    effect(() => {
      if (this.dateString()) {
        this.singleEventId();
        this.artist();
        this.load();
      }
    });

    if (this.activatedRoute.snapshot.paramMap.get('eventId')) {
      this.singleEventId.set(this.activatedRoute.snapshot.paramMap.get('eventId'));
    }
    if (this.activatedRoute.snapshot.paramMap.get('artist')) {
      this.artist.set(this.activatedRoute.snapshot.paramMap.get('artist'));
    }
  }

  openerComponent: OpenerComponent;


  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  events = signal<NxtCalendarEvent[]>([]);
  event = signal<NxtCalendarEvent | null>(null); // computed(() => this.eventsFiltered()[this.currentIndex()]);
  loading = signal(false);
  photos = signal<SocketInterfaceResponse.EventPhoto[]>([]);
  imagesAndVideos = signal<NxtCalendarEventFile[]>([]);
  myRatingValue = signal<number | null>(null);
  myRatingComment = signal('');
  allRatings = signal<NxtCalendarEventRating[]>([]);
  showEventsOnlyWithPhotosOrVideos = signal(true);
  showEventsOnlyToFewRatings = signal(false);
  showEventsOnlyWithoutMyCheck = signal(false);
  dateString = signal(DateTools.formatNowDate());
  currentIndex = computed(() => {
    return this.eventsFiltered().findIndex(e => e.id === this.event()?.id);
  });
  singleEventId = signal<string | null>(null);
  artist = signal<string | null>(null);

  hasRatingFromMe = computed(() => {
    return this.event()?.ratings && this.event().ratings.some(r => r.username === this.loginService.getUsername());
  });

  forceShowRatingAndArtist = signal(false);

  showRatingAndArtist = computed(() => {
    return (this.hasRatingFromMe() || this.forceShowRatingAndArtist()) || this.artist();
  });

  eventsFiltered = computed(() => {
    let events = this.events();
    if (this.showEventsOnlyWithPhotosOrVideos()) {
      events = events.filter(e => e.closed && e.files && e.files.some(f => f.subType === 'tattooPhoto' || f.subType === 'tattooVideo'));
    }
    if (this.showEventsOnlyToFewRatings()) {
      events = events.filter(e => !e.ratings || e.ratings.length < this.configService.config.value.mustEventRatingMin || this.additionalEventIdAfterLoad === e.id);
    }
    if (this.showEventsOnlyWithoutMyCheck()) {
      events = events.filter(e => !e.ratings || !e.ratings.some(r => r.username === this.loginService.getUsername()) || this.additionalEventIdAfterLoad === e.id);
    }
    return events;
  });

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  socketService = inject(SocketService);
  loginService = inject(LoginService);
  dialogService = inject(DialogService);
  configService = inject(ConfigService);
  bodyPutService = inject(BodyPutService);
  permissionService = inject(PermissionService);
  dialogRef = inject(MatDialogRef, {optional: true});
  clipboard = inject(Clipboard);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);


  private jumpEventIdAfterLoad: string;
  private additionalEventIdAfterLoad: string;

  protected readonly EventTools = EventTools;
  changed = false;
  private photoEventId = '';
  showHeaderButtons = computed(() => {
    return !this.singleEventId() && !this.artist();
  });

  private ratingLog: WithoutNxtDbFields<NxtEventRatingLog> = {
    eventId: '',
    imageCount: 0,
    imageViews: [],
    duration: 0,
    end: 0,
    start: 0,
  };

  ratingsLog: { [username: string]: NxtEventRatingLog[] } = {};

  additionImageText: { [fileId: string]: string } = {};

  ngOnInit() {
    this.pushSocketSubscription = this.socketService.subscribeNew('calendarEventChanged', data => {
      if (this.events().some(e => e.id === data.record.id)) {
        this.load();
      }
    });
  }

  nxtOnDestroy() {
  }

  async loadEvent() {
    if (this.event()) {
      this.loading.set(true);
      // this.myRatingValue.set(null);
      // this.myRatingComment.set('');
      this.allRatings.set([]);
      if (this.event().ratings) {
        /*const myRating = this.event().ratings.find(r => r.username === this.loginService.getUsername());
        if (myRating) {
          this.myRatingValue.set(myRating.value);
          this.myRatingComment.set(myRating.comment);
        }*/
        this.allRatings.set(this.event().ratings);
      }
      if (this.event()) {
        if (this.photoEventId !== this.event().id || true) {
          this.imagesAndVideos.set(this.event().files.filter(f => f.type === 'image' || f.type === 'video'));
          if (this.event()) {
            this.photoEventId = this.event().id;
          } else {
            this.photoEventId = '';
          }
        }
      }
      this.loading.set(false);
    }
  }

  async load() {
    let data: { events: NxtCalendarEvent[] };
    if (this.singleEventId()) {
      data = await this.socketService.getEventRatingEvent(this.singleEventId());
    } else if (this.artist()) {
      data = await this.socketService.getEventRatingArtist(this.artist());
    } else {
      data = await this.socketService.getEventRatingData(this.dateString());
    }
    console.log(data);
    this.events.set(data.events);
    if (this.jumpEventIdAfterLoad) {
      if (this.event()) {
        if (this.event().id === this.jumpEventIdAfterLoad) {
          this.nextClicked();
          /*if (this.currentIndex() < this.events().length) {
            this.currentIndex.set(this.currentIndex() + 1);
          }*/
        }
      }
      this.jumpEventIdAfterLoad = '';
    }
    if (this.additionalEventIdAfterLoad) {
      if (this.event()) {
        if (this.event().id !== this.additionalEventIdAfterLoad) {
          this.event.set(this.eventsFiltered().find(e => e.id === this.additionalEventIdAfterLoad));
          // this.currentIndex.set(this.eventsFiltered().findIndex(e => e.id === this.additionalEventIdAfterLoad));
        }
      }
    }
    this.refreshView();
  }


  async nextClicked() {
    this.additionalEventIdAfterLoad = '';
    /*if (this.changed) {
      if (!await this.dialogService.showYesNo('Deine Bewertung ist noch nicht gespeichert', {yesText: 'Weiter ohne speichern', noText: 'Zurück'})) {
        return;
      }
    }*/
    if (this.currentIndex() < this.eventsFiltered().length) {
      this.event.set(this.eventsFiltered()[this.currentIndex() + 1]);
    }
  }

  showEventClicked() {
    if (this.openerComponent === 'CalendarEventEditComponent') {
      this.dialogRef.close();
    } else {
      this.dialogService.showEvent(this.event().id, 'EventsRatingComponent');
    }
  }

  showArtistClicked() {
    this.singleEventId.set(null);
    this.artist.set(this.event().artist);
  }

  closeClicked() {
    this.dialogRef?.close();
  }

  async saveClicked(next: boolean) {
    if (!TypeTools.isNumber(this.myRatingValue())) {
      await this.dialogService.showOk('Speichern geht nur mit einer Bewertung');
      return;
    }
    this.jumpEventIdAfterLoad = next ? this.event().id : '';
    this.additionalEventIdAfterLoad = !next ? this.event().id : '';
    this.ratingLog.end = Date.now();
    await this.socketService.setEventRating(this.event().id, this.myRatingValue(), this.myRatingComment(), this.ratingLog);
    this.myRatingValue.set(null);
    this.myRatingComment.set('');
  }

  prevClicked() {
    this.additionalEventIdAfterLoad = '';
    if (this.currentIndex() > 0) {
      if (this.currentIndex() < this.eventsFiltered().length) {
        this.event.set(this.eventsFiltered()[this.currentIndex() - 1]);
      }
    }
  }

  setEventId(id: string) {
    this.singleEventId.set(id);
  }

  showEventsOnlyToFewRatingsChanged() {
    this.jumpEventIdAfterLoad = '';
    this.additionalEventIdAfterLoad = '';
    if (this.showEventsOnlyToFewRatings() && this.showEventsOnlyWithoutMyCheck()) {
      this.showEventsOnlyWithoutMyCheck.set(false);
    }
    this.refreshView();
  }

  showEventsOnlyWithoutMyCheckChanged() {
    if (this.showEventsOnlyToFewRatings() && this.showEventsOnlyWithoutMyCheck()) {
      this.showEventsOnlyToFewRatings.set(false);
    }
    this.refreshView();
  }

  copyLinkClicked() {
    this.clipboard.copy(window.origin + '/events-rating/' + this.event().id);
  }

  async deleteRatingClicked(index: number) {
    const username = this.event().ratings[index].username;
    if (await this.dialogService.showYesNo('Bewertung von "' + username + '" löschen?')) {
      this.socketService.deleteEventRating(this.event().id, username);
    }
  }

  private refreshView() {
    const existingEvent = this.event() && this.eventsFiltered().find(e => e.id === this.event().id);
    if (!existingEvent) {
      this.event.set(this.eventsFiltered()[0]);
    } else {
      if (this.myRatingValue()) {

      }
      this.event.set(existingEvent);
    }
  }

  async videoClicked(eventFile: NxtCalendarEventFile) {
    this.startUserWatchPhoto(eventFile.id);
    const dialog = this.dialogService.showComponentFull(VideoComponent);
    dialog.componentInstance.loadDriveVideo(eventFile);
    await firstValueFrom(dialog.afterClosed());
    this.stopUserWatchPhoto(eventFile.id);
  }

  startUserWatchPhoto(id: string) {
    this.ratingLog.imageViews.push({id, start: Date.now(), end: 0});
  }

  stopUserWatchPhoto(id: string) {
    const currentOpen = this.ratingLog.imageViews.find(i => i.id === id && !i.end);
    if (currentOpen) {
      currentOpen.end = Date.now();
    }
  }


  private resetRatingLog() {
    this.additionImageText = {};
    this.ratingLog = {
      eventId: this.event().id,
      imageCount: this.event().files.filter(f => f.type === 'image').length,
      imageViews: [],
      duration: 0,
      end: 0,
      start: Date.now(),
    };
  }

  openImage(data: { slide: Slide, id: string }) {
    this.startUserWatchPhoto(data.id);
  }

  closeImage(data: { slide: Slide, id: string }) {
    this.stopUserWatchPhoto(data.id);
  }

  async showRatingLogClicked() {
    this.ratingsLog = {};
    const ratingLogs = await this.socketService.getEventRatingLog(this.event().id);
    for (const ratingLog of ratingLogs) {
      if (!this.ratingsLog[ratingLog.createdBy]) {
        this.ratingsLog[ratingLog.createdBy] = [];
      }
      this.ratingsLog[ratingLog.createdBy].push(ratingLog);
    }
    this.cdRef.detectChanges();
  }

  showRatingLogDetails(log: NxtEventRatingLog) {
    this.additionImageText = {};
    for (const image of log.imageViews) {
      if (this.additionImageText[image.id]) {
        this.additionImageText[image.id] += '\n' + DurationTools.format2(image.end - image.start, {pastPrefix: '', futurePrefix: ''});
      } else {
        this.additionImageText[image.id] = DurationTools.format2(image.end - image.start, {pastPrefix: '', futurePrefix: ''});
      }

    }
  }

  eventFileEditClicked(data: { slide: Slide; id: string }) {
    requestAnimationFrame(() => {
      const dialog = this.dialogService.showComponentFull(PhotoEditorComponent);
      dialog.componentInstance.fileId = data.id;
    });
  }
}
