import {ChangeDetectionStrategy, Component, computed, input, OnInit, output} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {NgForOf, NgIf, NgStyle} from '@angular/common';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {NxtRoundPipe} from '../../../pipes/nxt-round-pipe';
import {FromNowPipe} from '../../../pipes/from-now.pipe';
import {NxtDailyNote} from '../../../common-interfaces/daily-note.interface';
import {ConfigService} from '../../../services/config.service';
import {PublicHolidaysTools} from '../../../common-browser/helpers/public-holidays.tools';

export interface CalendarHeaderDay {
  isPast: boolean;
  isToday: boolean;
  isFuture: boolean;
  isWeekend: boolean;
  cssClass: string;
  date: Date;
}

@Component({
    selector: 'nxt-calendar-header',
    templateUrl: './calendar-header.component.html',
    styleUrls: ['./calendar-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtDatePipe, NgStyle, NgForOf, NgIf, NxtRoundPipe, FromNowPipe]
})
export class CalendarHeaderComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/
  days = input.required<CalendarHeaderDay[]>();
  dailyNotes = input.required<{ [dateString: string]: NxtDailyNote[] }>();
  headerWidth = input.required<string>();

  artistCounts = input.required<{ [dateString: string]: number }>();
  piercingCounts = input.required<{ [dateString: string]: number }>();
  valence = input.required<{ [dateString: string]: number }>();


  publicHolidays = computed<{ [dateString: string]: string }>(() => {
    const publicHolidays = {};
    for (const day of this.days()) {
      const text = PublicHolidaysTools.getPublicHoliday(day.date.dateFormatDate(), this.configService.config.value.invoiceData.fromRegion);
      if (text) {
        publicHolidays[day.date.dateFormat('yyyy-MM-dd')] = text;
      }
    }
    return publicHolidays;
  });

  /*** Outputs ***/
  dateHeaderClicked = output<string>();
  dailyNoteClicked = output<NxtDailyNote>();

  /*** Signals ***/

  thisYear = DateTools.formatNow('yyyy');

  /*** Injections ***/

  constructor(
    private configService: ConfigService,
  ) {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }
}
