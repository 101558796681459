<div class="flex flex-col">
  <div>Kategorie</div>
  <div class="flex flex-col">
    <nxt-input [nxtFormControl]="form.controls.name"/>
    <nxt-input [nxtFormControl]="form.controls.description"/>
    <nxt-slide-toggle class="mb-4" [nxtFormControl]="form.controls.isTransfer"/>
  </div>
  <div class="flex flex-row justify-around gap-3">
    <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
    @if(originalTransactionCategory?.id){
      <nxt-button (click)="deleteClicked()">Löschen</nxt-button>
    }
    <nxt-button (click)="saveClicked()">Speichern</nxt-button>
  </div>
</div>
