import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {GlobalStudioShortName} from '../../common-interfaces/global-studio.interface';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {FormsModule} from '@angular/forms';
import {MatSlider, MatSliderRangeThumb} from '@angular/material/slider';
import {AutocompleteComponent} from '../form-controls/autocomplete/autocomplete.component';
import {CacheService} from '../../services/cache/cache.service';
import {DisplayWithTools} from '../../common-browser/helpers/display-with.tools';
import {SkillSelectComponent} from '../../pages/calendar-event-edit/skill-select/skill-select.component';
import {clone, keys} from '../../common-browser/helpers/object.tools';
import {firstValueFrom} from 'rxjs';
import {DialogService} from '../../services/dialog.service';
import {DatePicker2Component} from '../form-controls/date-picker-2/date-picker-2.component';
import {ClickDirective} from '../../directives/click.directive';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {NxtEventQuery, NxtEventQueryEventDateType} from '../../common-interfaces/nxt.calendar-event.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {InputComponent} from '../form-controls/input/input.component';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {LocalStorageService} from '../../services/local-storage.service';
import {UpperCasePipe} from '@angular/common';
import {ConfigService} from '../../services/config.service';
import {NxtBoolSkill, NxtSkill} from '../../common-interfaces/nxt.artist.interface';
import {BodyPutService} from '../../services/body-put.service';


@Component({
  selector: 'nxt-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonToggle, MatButtonToggleGroup, FormsModule, MatSlider, MatSliderRangeThumb, AutocompleteComponent, DatePicker2Component, ClickDirective, NxtButtonComponent, InputComponent, SlideToggleComponent, UpperCasePipe],
  standalone: true,
})
export class EventFilterComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor() {
    super();
    this.resetFilter();
    this.load();
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  studioOptions = signal<GlobalStudioShortName[]>([]);
  artistOptions = signal<{ text: string, value: string }[]>([]);

  filterStudio = signal<GlobalStudioShortName[]>([]);
  filterMotive = signal<string | null>(null);
  filterRating = signal<boolean>(true);
  filterRatingMin = signal<number>(1);
  filterRatingMax = signal<number>(10);
  filterArtist = signal<{ text: string, value: string } | null>(null);
  filterSkills = signal<{ [key in NxtSkill]?: boolean }>({});
  filterBoolSkills = signal<{ [key in NxtBoolSkill]?: boolean }>({});
  filterDateMin = signal<string | null>(null);
  filterDateMax = signal<string | null>(null);
  filterDateType = signal<NxtEventQueryEventDateType>('today');
  filterBodyPut = signal<string[]>([]);

  filterSkillsText = computed(() => {
    return [...keys(this.filterBoolSkills()), ...keys(this.filterSkills())].join(', ');
  });

  filterBodyPutText = computed(() => {
    if (this.filterBodyPut() && this.filterBodyPut().length > 0) {
      return this.bodyPutService.getGermanPath(this.filterBodyPut());
    }
    return 'Alle Körperstellen';
  });


  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private cacheService = inject(CacheService);
  private configService = inject(ConfigService);
  private localStorageService = inject(LocalStorageService);
  private dialogService = inject(DialogService);
  private bodyPutService = inject(BodyPutService);
  private matDialogRef = inject(MatDialogRef, {optional: true});

  protected readonly DisplayWithTools = DisplayWithTools;

  getStudioOptions(): GlobalStudioShortName[] {
    switch (this.configService.config.value.studioRegion) {
      case 'DO':
      case 'MA':
      case 'AC':
      case 'DU':
        return ['ac', 'du', 'do', 'ma', 'ffm', 'ab', 'nu'];
      case 'NU':
      case 'AB':
      case 'FFM':
        return ['ac', 'du', 'do', 'ma', 'ffm', 'ab', 'nu'];
    }
    return [];
  }

  load() {
    this.pushSubscription = this.cacheService.artists.subscribe(artists => {
      this.artistOptions.set(artists.map(a => ({text: a.name, value: a.id})).sortString('text'));
    });
  }

  ngOnInit() {
    this.studioOptions.set(this.getStudioOptions());
  }

  nxtOnDestroy() {
  }

  async selectBodyPutClicked() {
    const result = await this.bodyPutService.showBodyPutChooser(true);
    console.log(result);
    this.filterBodyPut.set(result);
  }

  async selectSkillsClicked() {
    const dialog = this.dialogService.showComponentDialog(SkillSelectComponent);
    dialog.componentInstance.noChecks = true;
    dialog.componentInstance.currentSkills = clone(this.filterSkills());
    dialog.componentInstance.currentBoolSkills = clone(this.filterBoolSkills());
    const result = await firstValueFrom(dialog.afterClosed());
    if (result) {
      this.filterSkills.set(result.skills);
      this.filterBoolSkills.set(result.boolSkills);
    }
  }

  cancelClicked() {
    this.matDialogRef.close();
  }

  getFilter() {
    const eventQuery: NxtEventQuery = {};

    if (this.filterArtist()) {
      eventQuery.artist = this.filterArtist().text;
    }
    if (this.filterMotive()) {
      eventQuery.tattooMotive = this.filterMotive();
    }
    if (this.filterRating()) {
      eventQuery.rating = {};
      if (this.filterRatingMin()) {
        eventQuery.rating.min = this.filterRatingMin();
      }
      if (this.filterRatingMax()) {
        eventQuery.rating.max = this.filterRatingMax();
      }
    }
    if (this.filterStudio()) {
      eventQuery.studios = this.filterStudio();
    }
    if (this.filterSkills()) {
      eventQuery.skills = keys(this.filterSkills()) as NxtSkill[];
    }
    if (this.filterBoolSkills()) {
      eventQuery.boolSkills = keys(this.filterBoolSkills()) as NxtBoolSkill[];
    }
    if (this.filterDateMin()) {
      eventQuery.eventDate = eventQuery.eventDate || {};
      eventQuery.eventDate.min = this.filterDateMin();
    }
    if (this.filterDateMax()) {
      eventQuery.eventDate = eventQuery.eventDate || {};
      eventQuery.eventDate.max = this.filterDateMax();
    }
    if (this.filterDateType()) {
      eventQuery.eventDate = eventQuery.eventDate || {};
      eventQuery.eventDate.type = this.filterDateType();
    }

    if (this.filterBodyPut() && this.filterBodyPut().length > 0) {
      eventQuery.bodyPut = this.filterBodyPut();
    }
    return eventQuery;
  }

  okClicked() {
    this.matDialogRef.close(this.getFilter());
  }

  resetFilter() {
    this.filterDateMin.set(null);
    this.filterDateMax.set(null);
    this.filterRating.set(false);
    this.filterRatingMin.set(1);
    this.filterRatingMax.set(10);
    this.filterStudio.set([]);
    this.filterArtist.set(null);
    this.filterSkills.set({});
    this.filterBoolSkills.set({});
  }

  async setFilter(filter: NxtEventQuery) {

    if (filter.eventDate && filter.eventDate.min) {
      this.filterDateMin.set(filter.eventDate.min);
    }
    if (filter.eventDate && filter.eventDate.max) {
      this.filterDateMax.set(filter.eventDate.max);
    }
    if (filter.eventDate.type) {
      this.filterDateType.set(filter.eventDate.type);
    }

    if (filter.bodyPut) {
      this.filterBodyPut.set(filter.bodyPut);
    }

    if (filter.rating && filter.rating.min) {
      this.filterRatingMin.set(filter.rating.min);
      this.filterRating.set(true);
    }
    if (filter.rating && filter.rating.max) {
      this.filterRatingMax.set(filter.rating.max);
      this.filterRating.set(true);
    }
    if (filter.studios) {
      this.filterStudio.set(filter.studios);
    }
    if (filter.skills) {
      for (const skill of filter.skills) {
        this.filterSkills.set({[skill]: true});
      }
    }
    if (filter.boolSkills) {
      for (const skill of filter.boolSkills) {
        this.filterBoolSkills.set({[skill]: true});
      }
    }
    if (filter.tattooMotive) {
      this.filterMotive.set(filter.tattooMotive);
    }

    await this.cacheService.waitForArtists();
    if (filter.artist) {
      const option = this.artistOptions().find(a => a.text === filter.artist);
      this.filterArtist.set(option);
    }
  }
}
