import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SocketService} from '../../../services/socket/socket.service';
import {JsonTools} from '../../../common-browser/helpers/json.tools';
import {ConfigService} from '../../../services/config.service';
import {NxtInvoiceForm} from '../../../common-interfaces/nxt.invoice-form';
import {MathTools} from '../../../common-browser/helpers/math.tools';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {LowerCasePipe, NgFor, NgIf} from '@angular/common';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';


@Component({
  selector: 'nxt-invoice-extern-studio-pdf',
  templateUrl: './invoice-from-invoice-form-pdf.component.html',
  styleUrls: ['./invoice-from-invoice-form-pdf.component.scss'],
  imports: [NgIf, FlexModule, NgFor, LowerCasePipe, SafeHtmlPipe, MoneyPipe, NxtDatePipe]
})
export class InvoiceFromInvoiceFormPdfComponent implements OnInit {

  errorText = '';
  showSigning = true;
  showTotalTaxInfo = true;
  data: NxtInvoiceForm;
  vatPercent = 19;
  totalGross = 0;
  private totalNet = 0;
  vatTax = 0;
  studio = 'Aachen';

  constructor(
    private route: ActivatedRoute,
    private socketService: SocketService,
    public configService: ConfigService
  ) {
    if (this.configService.config.value.studios.length === 1) {
      this.studio = this.configService.config.value.studios[0].name;
    }
    if (this.route.snapshot.queryParams.data) {
      this.data = JsonTools.parse(this.route.snapshot.queryParams.data);
      this.calcTotalGross();
    } else {
      this.errorText = 'Date fehlen!';
      return;
    }
  }

  async ngOnInit() {
  }

  getNet() {
    return this.totalGross / ((this.vatPercent / 100) + 1);
  }

  getTax() {
    // return this.data.invoiceValueTotalForTax - this.getNet();
  }

  getFrom() {
    let text = this.configService.config.value.invoiceData.fromCompany;
    text += ', ' + this.configService.config.value.invoiceData.fromStreet;
    text += ', DE-' + this.configService.config.value.invoiceData.fromPostalCode + ' ' + this.configService.config.value.invoiceData.fromCity;
    return text;
  }

  calcTotalGross() {
    this.totalGross = MathTools.round(this.data?.positions?.reduce((sum, p) => sum + (p.amount * p.unitPrice), 0), 2);
    this.totalNet = MathTools.round(this.totalGross / ((this.vatPercent / 100) + 1), 2);
    this.vatTax = MathTools.round(this.totalGross - this.totalNet, 2);
  }
}
