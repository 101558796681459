import {inject, Injectable} from '@angular/core';
import {NxtBankDocumentStatus} from '../../../common-interfaces/bank/bank-transaction.interface';
import {SocketService} from '../../../services/socket/socket.service';
import {DialogService} from '../../../services/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class BankDocumentService {
  /*** Injections ***/
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  async run(ids: string[]) {
    const result = await this.dialogService.showButtons('Aktion', {
      showCancelButton: true,
      buttons: [
        {text: NxtBankDocumentStatus._00_download, value: NxtBankDocumentStatus._00_download},
        {text: NxtBankDocumentStatus._02_ocr, value: NxtBankDocumentStatus._02_ocr},
        {text: '04-DocType', value: NxtBankDocumentStatus._04_documentType},
        {text: '05-CheckComp', value: NxtBankDocumentStatus._05_checkOwnCompany},
        {text: NxtBankDocumentStatus._06_documentAi, value: NxtBankDocumentStatus._06_documentAi},
        {text: NxtBankDocumentStatus._08_split, value: NxtBankDocumentStatus._08_split},
        {text: NxtBankDocumentStatus._10_nxtAi, value: NxtBankDocumentStatus._10_nxtAi},
        {text: '12-CheckDupl', value: NxtBankDocumentStatus._12_checkDuplicates},
        {text: NxtBankDocumentStatus._14_merge, value: NxtBankDocumentStatus._14_merge},
        {text: '20-sparkasse', value: NxtBankDocumentStatus._20_spk_month_statement},
        {text: '21-Klarna', value: NxtBankDocumentStatus._21_klarna_settlement},
        {text: '22-Paypal', value: NxtBankDocumentStatus._22_paypal_fee},
        {text: '30_transac', value: NxtBankDocumentStatus._30_transaction},
      ],
    });
    if (!result) {
      return false;
    }
    const startFlow = await this.dialogService.showYesNo('Flow starten?');
    this.dialogService.showLoading(result.value + ' läuft...');
    await this.socketService.runBankDocumentFlow(ids, result.value as NxtBankDocumentStatus, startFlow);
    this.dialogService.hideLoading();
    return true;
  }
}
