import {Component, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtPiercingManagerArtistSession, NxtPiercingManagerCustomer, NxtPiercingManagerData} from '../../common-interfaces/nxt.piercing-manager-data.interface';
import {SocketService} from '../../services/socket/socket.service';
import {DialogService} from '../../services/dialog.service';
import {LoginService} from '../../services/login.service';
import {NxtSocketSubscription} from '../../services/socket/socket-subscription';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {IntervalTools} from '../../common-browser/helpers/interval.tools';
import {PiercingManagerTools} from '../../common-browser/helpers/piercing-manager.tools';
import {PiercingManagerInventoryCheckComponent} from './piercing-manager-inventory-check/piercing-manager-inventory-check.component';
import {NxtInventoryStockItem} from '../../common-interfaces/nxt.inventory-item.interface';
import {firstValueFrom} from 'rxjs';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {MatIcon} from '@angular/material/icon';
import {PermissionDirective} from '../../directives/permission.directive';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf, NgStyle} from '@angular/common';
import {WindowTools} from '../../common-browser/window.tools';
import {PermissionService} from '../../services/permission.service';
import {NxtPermissionId} from '../../common-interfaces/nxt.user.interface';


@Component({
  selector: 'nxt-piercing-manager',
  templateUrl: './piercing-manager.component.html',
  styleUrls: ['./piercing-manager.component.scss'],
  imports: [NgIf, FlexModule, NxtButtonComponent, PermissionDirective, MatIcon, NgFor, NgStyle, ExtendedModule, NxtDatePipe],
})

export class PiercingManagerComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  private dataSubscription: NxtSocketSubscription;
  private resortInterval: number;
  private piercingInventoryStockItems: NxtInventoryStockItem[];

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private loginService: LoginService,
    private permissionService: PermissionService,
  ) {
    super();
  }

  public data: NxtPiercingManagerData;


  protected readonly window = window;
  artistSession: NxtPiercingManagerArtistSession;
  showCancel = true;

  async ngOnInit() {
    this.startResortInterval();
    this.startUp();
    this.piercingInventoryStockItems = await this.socketService.getPiercingInventoryStockItems();
  }

  private async startUp() {
    const session = await this.socketService.piercingManagerGetSession();
    if (!session) {
      if (await this.dialogService.showYesNo('No Piercing-Session started', {yesText: 'Start', noText: 'Cancel', fontSize: '200%'})) {
        await this.socketService.piercingManagerStartSession();
      }
    }
    this.artistSession = await this.socketService.piercingManagerGetArtistSession();
    if (!this.artistSession) {
      const availableArtists = await this.socketService.piercingManagerGetAvailableArtists();
      if (availableArtists.length === 0) {
        this.dialogService.showOk('No available artists');
        return;
      } else {
        const result = await this.dialogService.showButtonChooserNew<string>({
          buttonRows: [availableArtists.map(a => ({value: a, text: a}))],
          title: 'Who are you?',
          hideBackButton: true,
        });
        if (result?.value) {
          this.artistSession = await this.socketService.piercingManagerStartArtistSession(result.value);
        }
      }
    }
    this.dataSubscription?.unsubscribe();
    this.dataSubscription = this.socketService.subscribeNew('getPiercingManagerData', async (data: NxtPiercingManagerData) => {
      this.data = data;
      PiercingManagerTools.resort(this.data.nextPossibleCustomers);
      this.artistSession = data.artistSession;
      if (!this.artistSession?.artistName) {
        this.dialogService.showOk('Wrong login');
        this.startUp();
      }
    }, {emitInitial: true, emitAfterReconnect: true});

    if (this.artistSession && this.artistSession.state === 'search-next-customer') {
      this.startNextCustomer();
    }
  }

  nxtOnDestroy() {
    this.dataSubscription?.unsubscribe();
    IntervalTools.clear(this.resortInterval);
  }

  /*public test(nextPossibleCustomer: NxtPiercingManagerCustomer) {
    this.data.currentCustomer = nextPossibleCustomer;
  }*/

  public async nextClicked() {
    let text = '';
    if (this.data.currentCustomer.fastWalkInNo > 0) {
      text += 'Walk-In ' + this.data.currentCustomer.fastWalkInNo;
    } else {
      text += 'Appointment ' + this.data.currentCustomer.start.dateFormat('HH:mm');
    }
    text += '\n<strong>' + this.data.currentCustomer.fullName + '</strong>\nfinished?';
    if (this.data.currentCustomer.fastWalkInNo === 0 || await this.dialogService.showYesNo(text, {
      yesText: 'Yes',
      noText: 'No',
      fontSize: '250%',
      textAlign: 'center',
    })) {
      if (this.data.currentCustomer.fastWalkInNo !== 0) {
        if (!await this.checkInventoryCurrentCustomer()) {
          return;
        }
        // es war ein Kunde, nun fragen was benutzt wurde
      }
      if (this.data.currentCustomer.eventId) {
        await this.socketService.piercingManagerEventFinished(this.data.currentCustomer.eventId);
      }
      this.startNextCustomer();
    }
  }

  private async startNextCustomer() {
    const nextCustomer = await this.socketService.piercingManagerGetNextCustomer();
    if (nextCustomer) {
      if (nextCustomer.fastWalkInNo === -2) {
        this.dialogService.showOk('No more customers');
        return;
      }
      let nextCustomerText = 'No. ' + nextCustomer.fastWalkInNo +
        '\n' + nextCustomer.fullName +
        '\n<small>' + nextCustomer.bodyPuts.join('\n') + '</small>';

      if (nextCustomer.fastWalkInNo === -1) {
        nextCustomerText = 'Start: ' + nextCustomer.start.dateFormat('HH:mm') +
          '\n' + nextCustomer.fullName +
          '\n<small>' + nextCustomer.bodyPuts.join('\n') + '</small>';
      }

      const buttonResult = await this.dialogService.showYesNoCancel(nextCustomerText, {
        yesText: 'START',
        noText: 'NOT HERE',
        fontSize: '250%',
        textAlign: 'center',
        title: 'NEXT',
        cancelText: 'PAUSE',
      });
      if (typeof buttonResult === 'boolean') {
        if (buttonResult) {
          const result = await this.socketService.piercingManagerStartCustomer(nextCustomer.eventId);
          if (!result.canStart) {
            await this.dialogService.showOk(result.text);
            this.startNextCustomer();
          }
        } else {
          // nicht hier
          const toAdd = await this.dialogService.showInput('Um wie viel Plätze verschieben?', {prompt: 1, isNumber: true});
          if (toAdd) {
            await this.socketService.piercingManagerNotHere(this.data.nextPossibleCustomers[0].eventId, toAdd);
          }
          this.startNextCustomer();
        }
      } else {
        await this.socketService.piercingManagerStartPause();
      }
    }
  }

  async clearSession() {
    await this.socketService.piercingManagerClearSession();
    WindowTools.reload('Clear Session');
  }

  async reOpenEvents() {
    await this.socketService.piercingManagerReOpenEvents();
  }

  async clearSortValues() {
    await this.socketService.piercingManagerClearSortValues();
  }

  async setEventsToToday() {
    const result = await this.dialogService.showInput('Termin von', {prompt: '2023-08-07'});
    if (result) {
      await this.socketService.piercingManagerSetEventsToToday(result);
    }
  }


  private startResortInterval() {
    this.resortInterval = window.setInterval(() => {
      if (this.data) {
        PiercingManagerTools.resort(this.data.nextPossibleCustomers);
      }
    }, DurationTools.DURATION_1MINUTE * 3);
  }

  private async checkInventoryCurrentCustomer() {
    return true;
    if (window.location.hostname !== 'localhost') {
      return true;
    }
    const dialog = this.dialogService.showComponentFull(PiercingManagerInventoryCheckComponent);
    this.piercingInventoryStockItems = await this.socketService.getPiercingInventoryStockItems();
    dialog.componentInstance.inventoryItems = this.piercingInventoryStockItems;
    await firstValueFrom(dialog.afterClosed());
    return false;
  }


  async cancelClicked() {
    if (await this.dialogService.showYesNo('Cancel?', {yesText: 'Cancel', noText: 'BACK', fontSize: '200%'})) {
      this.startNextCustomer();
    }
  }

  async currentCustomerClicked() {
    if (!this.permissionService.hasPermission(NxtPermissionId.PiercingManager_AddInfo)) {
      return;
    }
    if (!this.data.currentCustomer.eventId) {
      return;
    }
    const buttonRows1: { value: string, text: string }[] = [];
    buttonRows1.push({value: 'add-info', text: 'Info zum Termin'});
    // buttonRows1.push({value: 'change', text: 'Piercing wechseln'});
    const action = await this.dialogService.showButtonChooserNew({buttonRows: [buttonRows1], title: '', hideBackButton: true});
    if (action) {
      if (action.value === 'add-info') {
        let info = await this.dialogService.showTextarea({message: 'Info zum Termin', prompt: '', buttonText: 'Speichern'});
        if (info) {
          info = this.loginService.getUsername() + ': ' + info;
          this.socketService.piercingManagerAddInfo(this.data.currentCustomer.eventId, info);
        }
      } else if (action.value === 'change') {
        this.dialogService.showOk('Kommt noch ;)');
      }
    }
  }

  async nextPossibleCustomerClicked(customer: NxtPiercingManagerCustomer, index: number) {
    const isPreferred = customer.fastWalkInNo > 0 && customer.fastWalkInNo + customer.fastWakInNoAdd < 0;
    if (isPreferred) {
      const result = await this.dialogService.showYesNo('Cancel preference?', {yesText: 'Yes', noText: 'No', fontSize: '200%'});
      if (result) {
        await this.socketService.piercingManagerNotHere(customer.eventId, customer.fastWakInNoAdd * -1);
        return;
      }
    } else {
      if (customer.fastWalkInNo === -1 || index === 0) {
        return;
      }
      const result = await this.dialogService.showYesNo('Prefer?', {yesText: 'Yes', noText: 'No', fontSize: '200%'});
      if (result) {
        const nextWalkInCustomer = this.data.nextPossibleCustomers.filter(c => c.fastWalkInNo > 0)?.[0];
        if (nextWalkInCustomer) {
          const currentMin = Math.min(...this.data.nextPossibleCustomers.map(n => n.fastWalkInNo + n.fastWakInNoAdd));
          const shouldBe = currentMin - 1;
          const customerToPreferCalculated = customer.fastWalkInNo + customer.fastWakInNoAdd;
          const toAdd = shouldBe - customerToPreferCalculated;
          await this.socketService.piercingManagerNotHere(customer.eventId, toAdd);
        }
      }
    }
  }
}
