<div class="nxt-page w-full h-100" *ngIf="!data" fxLayout="column" fxLayoutAlign="center center">
  <nxt-button (click)="window.location.reload()">reload</nxt-button>
</div>
<div class="nxt-page" *ngIf="data" fxLayout="column">
  <div fxLayout="row" nxtPermission="IsJulian">
    <nxt-button (click)="clearSession()">Session löschen</nxt-button>
    <nxt-button (click)="reOpenEvents()">Alle Termine öffnen</nxt-button>
    <nxt-button (click)="clearSortValues()">Sortierung wiederherstellen</nxt-button>
    <nxt-button (click)="setEventsToToday()">Termine auf heute</nxt-button>
    <!--<div>{{artistSession | json}}</div>-->
  </div>
  <div class="w-full" fxLayout="row" fxLayoutAlign="center">
    <div class="text-180">Hello {{ artistSession?.artistName }}</div>
  </div>
  <div fxFlex fxLayout="row" clasS="w-full" fxLayoutAlign="center center">
    <div *ngIf="data.currentCustomer" class="current-user-box" fxLayout="row">
      <div
        *ngIf="showCancel && data.currentCustomer.fastWalkInNo !== 0"
        fxFlex="15%"
        class="border-left button-cancel background-red mouse-pointer"
        fxLayoutAlign="center center" (click)="cancelClicked()">
        <mat-icon style="font-size: 80px; width: fit-content; height: fit-content">cancel</mat-icon>
      </div>

      <div fxFlex fxLayout="column" class="current-user-text" fxLayoutAlign="center center" (click)="currentCustomerClicked()">
        <div *ngIf="data.currentCustomer.fastWalkInNo !== 0" class="text-70">in progress...</div>
        <div *ngIf="data.currentCustomer.fastWalkInNo === 0" class="text-70">in pause...</div>

        <div *ngIf="data.currentCustomer.fastWalkInNo > 0" class="text-130 w-full pt-1" style="line-height: 0.8">
          <div fxLayoutAlign="center">Walk-In {{ data.currentCustomer.fastWalkInNo }}</div>
        </div>
        <div *ngIf="data.currentCustomer.fastWalkInNo === -1" class="text-130 w-full pt-1" style="line-height: 0.8">
          <div fxLayoutAlign="center" class="text-70">Appointment {{ data.currentCustomer.start | nxtDate: 'HH:mm' }}</div>
        </div>
        <div class="text-140" *ngIf="data.currentCustomer.fastWalkInNo !== 0">{{ data.currentCustomer.fullName }}</div>
        <div class="text-140 py-5" *ngIf="data.currentCustomer.fastWalkInNo === 0">START WORK ➞</div>
        <div [ngStyle]="{fontSize: data.currentCustomer.bodyPuts.length < 3 ? '100%' : '65%'}"
             *ngFor="let bodyPut of data.currentCustomer.bodyPuts">
          {{ bodyPut }}
        </div>
      </div>

      <div fxFlex="15%" class="border-right button-finish background-green mouse-pointer" fxLayoutAlign="center center"
           (click)="nextClicked()">
        <mat-icon style="font-size: 80px; width: fit-content; height: fit-content">task_alt</mat-icon>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" class="scrollable w-full">
    @for (nextPossibleCustomer of data.nextPossibleCustomers; track nextPossibleCustomer.eventId) {
      <div>
        <div class="next-possible-customer" (click)="nextPossibleCustomerClicked(nextPossibleCustomer, $index)">
          <div fxLayoutAlign="center" fxLayout="column">
            @if (nextPossibleCustomer.fastWalkInNo > -1) {
              <div class="text-120 center bold">Walk-In {{ nextPossibleCustomer.fastWalkInNo }}</div>
              @if (nextPossibleCustomer.fastWalkInNo > 0 && nextPossibleCustomer.fastWalkInNo + nextPossibleCustomer.fastWakInNoAdd < 0) {
                <div class="text-center">preferred</div>
              }
            } @else {
              <div class="text-120 center bold">{{ nextPossibleCustomer.start | nxtDate: 'dd.MM HH:mm' }}</div>
            }
          </div>
          <div fxLayout="column">
            <div fxLayoutAlign="center">
              <div class="text-120 pb-2">{{ nextPossibleCustomer.fullName }}</div>
            </div>
            <div fxFlex fxLayout="column" fxLayoutAlign="center center">
              <div style="text-align: center;" *ngFor="let bodyPut of nextPossibleCustomer.bodyPuts">
                <div>{{ bodyPut }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</div>
