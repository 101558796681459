<div class="nxt-page w-full h-100" fxLayout="column">

  <div fxLayout="row" fxLayoutAlign="between">
    <div fxFlex>
      <nxt-input placeholder="Suche" [(value)]="quickFilterText"></nxt-input>
    </div>
    <div>
      <nxt-button-icon (click)="close()">close</nxt-button-icon>
    </div>
  </div>
  <div fxFlex fxLayout="row">
    <nxt-datagrid
      class="h-100 w-full"
      [quickFilterText]="quickFilterText"
      [columnDefs]="columnDefs"
      [rowData]="reminders"
    >
    </nxt-datagrid>
  </div>
</div>
