import {Pipe, PipeTransform} from '@angular/core';
import {DriveTools} from '../common-browser-public/helpers/drive.tools';

@Pipe({
  name: 'nxtEventFile',
  standalone: true,
})
export class NxtEventFilePipe implements PipeTransform {

  constructor() {
  }

  transform(id: string, thumb = false, reloadIndicator = ''): any {
    if (thumb) {
      return DriveTools.getDriveThumbLink(id) + '&reload=' + reloadIndicator;
    }
    return DriveTools.getDriveLink(id) + '&reload=' + reloadIndicator;
  }
}
