import {AfterContentInit, booleanAttribute, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControlWrapper} from '../form-control-wrapper';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';


@Component({
  selector: 'nxt-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  imports: [FlexModule, NgClass, ExtendedModule, NgIf, MatSlideToggle, FormsModule, ReactiveFormsModule, SafeHtmlPipe],
  standalone: true,
})
export class SlideToggleComponent extends FormControlWrapper implements OnInit, AfterContentInit, OnChanges, OnDestroy {

  @Input() disabled: boolean;

  @Input() placeholder: string;

  @Input() direction: 'row' | 'column' = 'row';
  @Input() leftText: string;
  @Input() noColorChange: boolean;

  @Input({transform: booleanAttribute}) set value(checked: boolean) {
    this.nxtFormControl.setValue(!!checked);
  }

  @Output() valueChange = new EventEmitter();

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.placeholder) {
      this.placeholder = this.nxtFormControl.name;
    }
    if (this.disabled) {
      this.nxtFormControl.disable();
    } else {
      this.nxtFormControl.enable();
    }
  }


  ngOnInit() {
    if (this.nxtFormControl.name) {
      // this.leftText = this.nxtFormControl.name;
    }
  }

  ngAfterContentInit(): void {

  }

  ngOnDestroy(): void {

  }

  public textClicked() {
    if (!this.disabled) {
      this.nxtFormControl.setValue(!this.nxtFormControl.value);
    }
  }

  toggleChanged(checked: boolean) {
    this.valueChange.emit(checked);
  }
}
