import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DurationTools} from '../common-browser/helpers/duration.tools';

@Pipe({
  name: 'duration2',
  standalone: true,
})
export class Duration2Pipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, withoutSeconds = true): string {
    return DurationTools.format2(value, {withoutSeconds, futurePrefix: '', pastPrefix: ''});
  }
}
