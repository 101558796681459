import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {PdfTranslateTools} from '../common-browser/helpers/pdf-translate.tools';

@Pipe({
    name: 'trans',
    standalone: true
})
export class TransPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(key: string, lang: string, params?: any): string {
    return PdfTranslateTools.getText(key, lang, params);
  }
}
