import {Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[nxtMultiClick]',
  standalone: true
})
export class MultiClickDirective implements OnInit, OnDestroy {
  @Input() public nxtMultiClick = 2;
  @Input() public nxtMultiTimeout = 1500;
  @Output() public nxtOnMultiClick: EventEmitter<MouseEvent> = new EventEmitter();

  private currentClickCounter = 0;

  constructor(private element: ElementRef) {
  }

  public ngOnInit(): void {
    this.element.nativeElement.classList.add('none-selection');
  }

  public ngOnDestroy(): void {
  }

  @HostListener('mouseup', ['$event'])
  public onMouseUp(event: MouseEvent): void {
  }

  @HostListener('mouseout', ['$event'])
  public onMouseOut(event: MouseEvent): void {
  }

  @HostListener('mousedown', ['$event'])
  public onMouseDown(event: MouseEvent): void {
    this.currentClickCounter++;
    if (this.currentClickCounter === this.nxtMultiClick) {
      this.nxtOnMultiClick.emit(event);
    }
    setTimeout(() => {
      this.currentClickCounter = 0;
    }, this.nxtMultiTimeout);
  }
}
