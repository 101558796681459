import {Component, OnInit} from '@angular/core';
import {DialogService} from '../../services/dialog.service';
import {SocketService} from '../../services/socket/socket.service';

@Component({
    selector: 'nxt-julian-start',
    templateUrl: './julian-start.component.html',
    styleUrls: ['./julian-start.component.scss'],
    standalone: true
})
export class JulianStartComponent implements OnInit {

  constructor(
    private dialogService: DialogService,
    private socketService: SocketService
  ) {
  }

  async ngOnInit() {
  }
}
