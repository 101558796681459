import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, signal, SimpleChanges} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtArtistSpot} from '../../../../common-interfaces/nxt.available-artist-day.interface';
import {NxtDatePipe} from '../../../../pipes/nxt-date-pipe';
import {DateTools} from '../../../../common-browser/helpers/date.tools';
import {NgStyle} from '@angular/common';
import {ColorTools} from '../../../../common-browser/helpers/color.tools';

@Component({
  selector: 'nxt-calendar-artist-spot-tooltip',
  templateUrl: './calendar-artist-spot-tooltip.component.html',
  styleUrls: ['./calendar-artist-spot-tooltip.component.scss'],
  imports: [NxtDatePipe, NgStyle],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class CalendarArtistSpotTooltipComponent extends NxtComponent implements OnInit, NxtOnDestroy, OnChanges {

  @Input() artistSpot: NxtArtistSpot;
  @Input() artistColor = '';
  untilText = signal('');
  color = '';

  constructor(
    private cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.artistSpot) {
      if (this.artistSpot.start < Date.now() && this.artistSpot.end > Date.now()) {
        const days = DateTools.dayDiff(this.artistSpot.end, this.artistSpot.start);
        if (days <= 30) {
          this.untilText.set('noch ' + DateTools.dayDiff(this.artistSpot.end, this.artistSpot.start) + ' Tage da');
        }
      } else if (this.artistSpot.start > Date.now()) {
        this.untilText.set('reist ' + DateTools.dateDiffToNowOnlyDaysText(this.artistSpot.start) + ' an');
      } else if (this.artistSpot.start === this.artistSpot.end) {
        this.untilText.set('nur heute');
      }
    }
    if (changes.artistColor) {
      this.color = ColorTools.getLightOrDarkFontColorByBackground(changes.artistColor.currentValue);
    }
  }
}
