import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, input, OnChanges, OnInit, Optional, Output, signal, SimpleChanges} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {KlarnaOrderRaw} from '../../common-interfaces/klarna-raw.interface';
import {SocketService} from '../../services/socket/socket.service';
import {DialogService} from '../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {TimeTools} from '../../common-browser/helpers/time.tools';
import {FromNowPipe} from '../../pipes/from-now.pipe';
import {JsonFormattedPipe} from '../../pipes/json-formatted-pipe';
import {MoneyPipe} from '../../pipes/money.pipe';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {KlarnaTools} from '../../common-browser/helpers/klarna-tools';
import {NxtKlarnaOrder} from '../../common-interfaces/nxt.klarna-order.interface';

@Component({
    selector: 'nxt-klarna-order-raw',
    templateUrl: './klarna-order-raw.component.html',
    styleUrls: ['./klarna-order-raw.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtButtonIconComponent, NgClass, ExtendedModule, NgIf, NxtButtonComponent, NgFor, NxtDatePipe, MoneyPipe, JsonFormattedPipe, FromNowPipe]
})

export class KlarnaOrderRawComponent extends NxtComponent implements OnInit, NxtOnDestroy, OnChanges {
  @Input() showCloseButton = false;
  originalValue: number;
  openValue = signal(0);
  firstParents: NxtKlarnaOrder[];

  // giftCards: { [paymentPossibilityRecordId: string]: NxtPaymentPossibilityRecord | undefined } = {};


  constructor(
    @Optional() public dialogRef: MatDialogRef<KlarnaOrderRawComponent>,
    private cdRef: ChangeDetectorRef,
    private socketService: SocketService,
    private dialogService: DialogService
  ) {
    super();
  }

  @Output() onShowEvent = new EventEmitter<string>();
  @Output() onShowGiftCard = new EventEmitter<string>();
  @Input() klarnaOrderId: string;
  showDebug = input(false);
  @Input() showShopOrder = false;

  noOuterBorder = input(false);
  klarnaOrderRaw = signal<KlarnaOrderRaw>(null);

  assignedEventIds = signal<string[]>([]);
  @Output() onShowShopOrder = new EventEmitter<string>();

  tattooTickets = signal<{ name: string, orders: NxtKlarnaOrder[] }[]>([]);
  giftCards = signal<{ code: string, order: NxtKlarnaOrder }[]>([]);


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.klarnaOrderId) {
      this.load();
    }
  }

  ngOnInit() {
    this.pushSocketSubscription = this.socketService.subscribeNew('eventKlarnaOrderRawChanged', (klarnaOrderRaw: KlarnaOrderRaw) => {
      if (klarnaOrderRaw.order_id === this.klarnaOrderId) {
        this.klarnaOrderRaw.set(klarnaOrderRaw);
      }
    });
  }

  nxtOnDestroy() {
  }

  load() {
    this.socketService.getKlarnaOrderRaw(this.klarnaOrderId).then((klarnaOrderRaw: KlarnaOrderRaw) => {
      this.klarnaOrderRaw.set(klarnaOrderRaw);
    });

    this.socketService.getKlarnaOrdersByKlarnaOrderId(this.klarnaOrderId).then(async (klarnaOrders) => {
      this.openValue.set(0);
      this.originalValue = 0;
      this.firstParents = klarnaOrders.filter(k => !k.originalOrderId);
      this.originalValue = this.firstParents.reduce((sum, k) => sum + k.originalValue, 0);
      this.openValue.set(this.originalValue);
      const splittetOrders = KlarnaTools.sortBySplitDate(klarnaOrders);
      this.tattooTickets.set([]);
      this.giftCards.set([]);
      for (const orders of splittetOrders) {
        const giftCardAssignment = orders[0].assignments.find(a => a.refType === 'gift-card');
        if (giftCardAssignment) {
          // debugger;
          const a = await this.socketService.getPaymentPossibilityRecord(giftCardAssignment.paymentPossibilityRecordId);
          const giftCard = {
            code: a.name,
            order: orders[0]
          };
          this.giftCards.update(tt => {
            tt.push(giftCard);
            return tt;
          });
        } else {
          this.tattooTickets.update(tt => {
            tt.push({
              name: 'Tattoo-Ticket ' + orders[0].originalValue.toMoneyString() + ' (' + this.getOpenValue(orders).toMoneyString() + ' offen)',
              orders
            });
            return tt;
          });
        }
      }

      this.assignedEventIds.set([]);
      klarnaOrders.forEach(klarnaOrder => {
        for (const assignment of klarnaOrder.assignments) {
          this.openValue.update(value => value - assignment.value);
          if (assignment.eventId) {
            this.assignedEventIds.update((eventIds) => {
              eventIds.push(assignment.eventId);
              return eventIds;
            });
          }
        }
      });
      this.openValue.update(value => MathTools.roundMoney(value));
      console.log('openValue = ' + this.openValue());
      this.cdRef.detectChanges();
    });
  }

  showEvent(eventId: string) {
    this.onShowEvent.emit(eventId);
  }

  showGiftCard(paymentPossibilityRecordId: string) {
    this.onShowGiftCard.emit(paymentPossibilityRecordId);
  }

  /*loadGiftCards() {
    this.tattooTickets().forEach(async tattooTicket => {
      for (const klarnaOrder of tattooTicket.orders) {
        for (const assignment of klarnaOrder.assignments) {
          if (assignment.refType === 'gift-card') {
            this.giftCards[assignment.paymentPossibilityRecordId] = await this.socketService.getPaymentPossibilityRecord(assignment.paymentPossibilityRecordId);
            console.log(this.giftCards[assignment.paymentPossibilityRecordId]);
          }
        }
      }
      this.cdRef.detectChanges();
    });
  }*/

  async deleteAssignment(klarnaOrder: NxtKlarnaOrder, assignmentIndex: number) {
    if (await this.dialogService.showYesNo('Zuweisung wirklich löschen?')) {
      klarnaOrder.assignments.splice(assignmentIndex, 1);
      await this.socketService.updateKlarnaOrder(klarnaOrder);
      await TimeTools.sleep(500);
      this.load();
    }
  }


  private getOpenValue(orders: NxtKlarnaOrder[]) {
    return orders.filter(o => !o.assigned).reduce((sum, o) => sum + o.value, 0);
  }
}
