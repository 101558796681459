import {ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit, inject, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtButtonIconComponent} from '../../../../../controls/button-icon/nxt-button-icon.component';
import {NxtBankDocument, NxtBankDocumentManualChange} from '../../../../../common-interfaces/bank/bank-transaction.interface';
import {NxtDatePipe} from '../../../../../pipes/nxt-date-pipe';
import {PermissionDirective} from '../../../../../directives/permission.directive';
import {NxtButtonComponent} from '../../../../../controls/button/nxt-button.component';
import {SocketService} from '../../../../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'nxt-bank-document-manual-changes',
  templateUrl: './bank-document-manual-changes.component.html',
  styleUrls: ['./bank-document-manual-changes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NxtButtonIconComponent,
    NxtDatePipe,
    PermissionDirective,
    NxtButtonComponent,
  ],
})

export class BankDocumentManualChangesComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  document = signal<NxtBankDocument | null>(null);

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private dialogRef = inject(MatDialogRef, {optional: true});
  private saved = false;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  closeClicked() {
    this.dialogRef.close(this.saved);
  }

  async deleteChange(change: NxtBankDocumentManualChange) {
    await this.socketService.bankDocumentDeleteManualChange(this.document().id, change.at);
    this.dialogRef.close(true);
  }
}
