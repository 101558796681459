import {DateTools} from '../common-browser/helpers/date.tools';
import {DurationTools} from '../common-browser/helpers/duration.tools';
import {DecimalTools} from '../common-browser/helpers/decimal.tools';
import {ArticleTools, NxtArticleOld} from '../common-browser/helpers/article.tools';
import {ObjectTools} from '../common-browser/helpers/object.tools';
import {DialogInputOptions} from '../controls/dialog/dialog-input/dialog-input.component';
import {SocketService} from './socket/socket.service';
import {DialogService} from './dialog.service';
import {Injectable} from '@angular/core';
import {NxtSale} from '../common-interfaces/nxt.sale';
import {UuidTools} from '../common-browser/helpers/uuid.tools';
import {PermissionService} from './permission.service';
import {NxtPermissionId} from '../common-interfaces/nxt.user.interface';
import {NxtPaypalTransaction} from '../common-interfaces/nxt-paypal-transaction';

@Injectable({
  providedIn: 'root',
})
export class SaleService {

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private permissionService: PermissionService,
  ) {
  }


  public async selectPayPalTransaction() {
    let notAssignedPaypalPayments = await this.socketService.getNotAssignedPaypalTransactions();
    let durationBack = DurationTools.DURATION_1HOUR * 4;
    if (this.permissionService.hasPermission(NxtPermissionId.GiftCards_Download)) {
      durationBack = DurationTools.DURATION_1DAY * 7;
    }
    notAssignedPaypalPayments = notAssignedPaypalPayments.filter(p => DateTools.parse(p.transactionTime) > Date.now() - durationBack);
    const buttons = notAssignedPaypalPayments.map(payment => {
      return {
        value: payment,
        text: DateTools.dateDiffToNowText(payment.transactionTime) + '<br/> ' + DecimalTools.toMoneyString(payment.value) + ' | ' + payment.from,
        smallerText: true,
      };
    });

    const result1 = await this.dialogService.showButtonChooser<NxtPaypalTransaction>({
      hideBackButton: true,
      buttonRows: [buttons],
      title: 'Wähle die Paypal Zahlung aus',
      text: '',
      minWidth: '80%',
      value: '',
    });
    if (!result1 || typeof result1 === 'string') {
      return;
    }
    return result1.value;
  }

  public async newPaypalSale() {
    const selectedPaypalTransaction = await this.selectPayPalTransaction();
    if (!selectedPaypalTransaction) {
      return;
    }
    /*let notAssignedPaypalPayments = await this.socketService.getNotAssignedPaypalTransactions();
    let durationBack = DurationTools.DURATION_1HOUR * 4;
    if (this.permissionService.hasPermission(NxtPermissionId.GiftCards_Download)) {
      durationBack = DurationTools.DURATION_1DAY * 7;
    }
    notAssignedPaypalPayments = notAssignedPaypalPayments.filter(p => DateTools.parse(p.transactionTime) > Date.now() - durationBack);
    let buttons = notAssignedPaypalPayments.map(payment => {
      return {
        value: payment,
        text: DateTools.dateDiffToNowText(payment.transactionTime) + '<br/> ' + DecimalTools.toMoneyString(payment.value) + ' | ' + payment.from,
        smallerText: true
      };
    });

    const result1 = await this.dialogService.showButtonChooser<NxtPaypalTransaction>({
      hideBackButton: true,
      buttonRows: [buttons],
      title: 'Wähle die Paypal Zahlung aus',
      text: '',
      minWidth: '80%',
      value: ''
    });
    if (!result1 || typeof result1 === 'string') {
      return;
    }
    const selectedPaypalTransaction = result1.value;*/


    const buttons = ObjectTools.clone<any>(ArticleTools.articlesIncomingPaypal).map(article => {
      return {
        value: article,
        text: article.shortDescription + (article.price > -1 ? '<br/>' + DecimalTools.toMoneyString(article.price) : ''),
      };
    });

    const result = await this.dialogService.showButtonChooser<NxtArticleOld>({
      hideBackButton: true,
      buttonRows: [buttons],
      title: 'Artikel wählen',
      text: '',
      minWidth: '80%',
      value: '',
    });

    if (result && typeof result !== 'string' && result.value) {
      const article = result.value;

      if (article.id === '') {
        const inputOptions: DialogInputOptions = {
          okButtonText: 'weiter',
          isMoney: false,
          message: 'Gib eine Bezeichnung für die Ausgabe an',
          placeholder: 'Bezeichnung',
          prompt: '',
        };
        const dialog = await this.dialogService.showInputOld(inputOptions);
        const dialogResult2 = await dialog.afterClosed().toPromise();
        if (!dialogResult2) {
          return;
        }
        article.id = dialogResult2;
        article.description = dialogResult2;
        article.shortDescription = dialogResult2;
      }
      let text = article.description;
      text += '\n' + DecimalTools.toMoneyString(selectedPaypalTransaction.value);
      text += '\n' + selectedPaypalTransaction.from;
      if (await this.dialogService.showYesNo(text)) {
        const sale: NxtSale = {
          id: UuidTools.generate(),
          value: selectedPaypalTransaction.value,
          createdAt: new Date(),
          createdAtDateString: DateTools.todayDateString,
          paypalTransaction: selectedPaypalTransaction,
          discountPercentage: 0,
          paymentMethod: 'paypal',
          description: article.description,
        };
        this.socketService.addSale(sale);
      }
    }
  }
}
