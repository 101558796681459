import {NxtMoneyStack} from '../../common-interfaces/nxt.money-stack.interface';
import {MoneyTools} from './money.tools';
import {MathTools} from './math.tools';
import {clone, keys, ObjectTools} from './object.tools';
import {ArrayTools} from './array.tools';
import {DateTools} from './date.tools';
import _ from 'lodash';
import {TypeTools} from './type.tools';
import {JsonTools} from './json.tools';

export type ReduceMultiFromMoneyStackResult = {
  values: { id: string; value: number; moneyStack?: NxtMoneyStack; missingMoney?: number }[];
  possible: boolean;
  missingMoneySum: number,
  log: string[];
};

export type MoneyStackReduceItem = {
  value: number;
  id: string;
};


export class MoneyStackTools {

  private static _dummies3: { reduce: NxtMoneyStack, add: NxtMoneyStack }[];
  private static _dummies2: { reduce: NxtMoneyStack, add: NxtMoneyStack }[];
  private static _dummies2Array: { reduce: NxtMoneyStack[], add: NxtMoneyStack[] }[];


  static dummies: { reduce: NxtMoneyStack, add: NxtMoneyStack }[] = [

    {reduce: {200: 1}, add: {100: 2}},
    {reduce: {200: 1}, add: {50: 4}},
    {reduce: {200: 1}, add: {100: 1, 50: 2}},
    {reduce: {200: 1}, add: {100: 1, 50: 1, 20: 2, 10: 1}},
    {reduce: {200: 1}, add: {100: 1, 50: 1, 20: 1, 10: 2, 5: 2}},
    {reduce: {200: 1}, add: {100: 1, 50: 1, 20: 1, 10: 2, 5: 1, 2: 2, 1: 1}},
    {reduce: {200: 1}, add: {100: 1, 50: 1, 10: 3, 5: 3, 2: 2, 1: 1}},

    {reduce: {100: 2}, add: {20: 8, 10: 4}},
    {reduce: {100: 2}, add: {20: 9, 10: 2}},
    {reduce: {100: 1}, add: {50: 2}},
    {reduce: {100: 1}, add: {50: 1, 20: 2, 10: 1}},
    {reduce: {100: 1}, add: {20: 5}},
    {reduce: {100: 1}, add: {20: 4, 10: 2}},
    {reduce: {100: 1}, add: {20: 3, 10: 4}},
    {reduce: {100: 1}, add: {20: 2, 10: 6}},
    {reduce: {100: 1}, add: {20: 1, 10: 8}},
    {reduce: {100: 1}, add: {10: 10}},
    {reduce: {100: 1}, add: {50: 1, 20: 1, 10: 2, 5: 2}},
    {reduce: {100: 1}, add: {50: 1, 20: 1, 10: 2, 5: 1, 2: 2, 1: 1}},
    {reduce: {100: 1}, add: {50: 1, 10: 3, 5: 3, 2: 2, 1: 1}},
    {reduce: {100: 1}, add: {50: 1, 20: 1, 10: 1, 5: 2, 2: 4, 1: 1, 0.5: 1, 0.2: 1, 0.1: 2, 0.05: 2}},

    {reduce: {50: 1}, add: {20: 2, 5: 2}},
    {reduce: {50: 1}, add: {10: 5}},
    {reduce: {50: 1}, add: {10: 4, 5: 2}},
    {reduce: {50: 1}, add: {10: 4, 5: 1, 2: 2, 1: 1}},
    {reduce: {50: 1}, add: {20: 1, 10: 1, 5: 4}},
    {reduce: {50: 1}, add: {10: 3, 5: 3, 2: 2, 1: 1}},
    {reduce: {50: 1}, add: {20: 2, 10: 1}},
    {reduce: {50: 1}, add: {20: 1, 2: 10, 5: 2}},


    {reduce: {20: 1}, add: {10: 1, 5: 2}},
    {reduce: {20: 1}, add: {10: 1, 5: 1, 2: 2, 1: 1}},
    {reduce: {20: 1}, add: {5: 3, 2: 2, 1: 1}},
    {reduce: {20: 1}, add: {2: 10}},

    {reduce: {20: 1}, add: {5: 4}},


    {reduce: {10: 1}, add: {5: 2}},
    {reduce: {10: 1}, add: {5: 1, 2: 2, 1: 1}},
    {reduce: {10: 1}, add: {2: 5}},
    {reduce: {10: 1}, add: {5: 1, 1: 1, 0.5: 7, 0.2: 2, 0.1: 1}},
    {reduce: {10: 1}, add: {5: 1, 1: 1, 0.5: 6, 0.2: 4, 0.1: 2}},
    {reduce: {10: 1}, add: {2: 4, 1: 1, 0.1: 5, 0.05: 10}},

    {reduce: {5: 1}, add: {2: 2, 1: 1}},
    {reduce: {5: 1}, add: {1: 5}},
    {reduce: {5: 1}, add: {2: 2, 0.5: 2}},
    {reduce: {5: 1}, add: {2: 1, 1: 2, 0.5: 2}},
    {reduce: {5: 1}, add: {1: 1, 0.5: 7, 0.2: 2, 0.1: 1}},

    {reduce: {2: 1}, add: {1: 2}},
    {reduce: {1: 1}, add: {0.5: 2}},
    {reduce: {0.5: 1}, add: {0.2: 2, 0.1: 1}},
    {reduce: {0.2: 1}, add: {0.1: 2}},
    {reduce: {0.1: 1}, add: {0.05: 2}},


    {reduce: {2: 1}, add: {1: 1, 0.5: 1, 0.2: 2, 0.1: 1}},
    {reduce: {2: 1}, add: {1: 1, 0.1: 5, 0.05: 10}},
    {reduce: {1: 1}, add: {0.1: 10}},
    {reduce: {1: 1}, add: {0.1: 5, 0.05: 10}},

    {reduce: {0.5: 1}, add: {0.1: 5}},
    {reduce: {0.5: 1}, add: {0.05: 10}},
    {reduce: {0.05: 1}, add: {0.01: 3, 0.02: 1}},
  ];

  static getCombinedDummiesDeep3() {
    if (MoneyStackTools._dummies3) {
      return MoneyStackTools._dummies3;
    }
    MoneyStackTools._dummies3 = [];
    for (const dummy1 of MoneyStackTools.dummies) {
      for (const dummy2 of MoneyStackTools.dummies) {
        for (const dummy3 of MoneyStackTools.dummies) {
          const moneyStack = {
            reduce: MoneyStackTools.combineMoneyStacks([dummy1.reduce, dummy2.reduce, dummy3.reduce]),
            add: MoneyStackTools.combineMoneyStacks([dummy1.add, dummy2.add, dummy3.add])
          };
          for (const key of keys(moneyStack.reduce)) {
            if (moneyStack.reduce[key] === 0) {
              delete moneyStack.reduce[key];
            }
          }
          for (const key of keys(moneyStack.add)) {
            if (moneyStack.add[key] === 0) {
              delete moneyStack.add[key];
            }
          }
          MoneyStackTools._dummies3.push(moneyStack);
        }
      }
    }
    return MoneyStackTools._dummies3;
  }

  static getCombinedDummiesDeep2() {
    if (MoneyStackTools._dummies2) {
      return MoneyStackTools._dummies2;
    }
    MoneyStackTools._dummies2 = [];
    for (const dummy1 of MoneyStackTools.dummies) {
      for (const dummy2 of MoneyStackTools.dummies) {
        const moneyStack = {
          reduce: MoneyStackTools.combineMoneyStacks([dummy1.reduce, dummy2.reduce]),
          add: MoneyStackTools.combineMoneyStacks([dummy1.add, dummy2.add])
        };
        for (const key of keys(moneyStack.reduce)) {
          if (moneyStack.reduce[key] === 0) {
            delete moneyStack.reduce[key];
          }
        }
        for (const key of keys(moneyStack.add)) {
          if (moneyStack.add[key] === 0) {
            delete moneyStack.add[key];
          }
        }
        MoneyStackTools._dummies2.push(moneyStack);
      }
    }
    return MoneyStackTools._dummies2;
  }

  static getCombinedDummiesDeep2Array() {
    if (MoneyStackTools._dummies2Array) {
      return MoneyStackTools._dummies2Array;
    }
    MoneyStackTools._dummies2Array = [];
    for (const dummy1 of MoneyStackTools.dummies) {
      for (const dummy2 of MoneyStackTools.dummies) {
        MoneyStackTools._dummies2Array.push({
          reduce: [dummy1.reduce, dummy2.reduce],
          add: [dummy1.add, dummy2.add]
        });
      }
    }
    return MoneyStackTools._dummies2Array;
  }

  public static getSum(moneyStack: NxtMoneyStack, onlyPaper = false): number {
    return MoneyStackTools.getTotalValueFromMoneyStack(moneyStack, onlyPaper);
  }

  public static getTotalValueFromMoneyStack(moneyStack: NxtMoneyStack, onlyPaper = false): number {
    let totalMoneyValue = 0;
    if (moneyStack) {
      for (const money of MoneyTools.moneys) {
        if (!onlyPaper || money >= 5) {
          if (TypeTools.isNumber(moneyStack[money])) {
            totalMoneyValue += moneyStack[money] * money;
          }
        }
      }
    }
    return MathTools.roundMoney(totalMoneyValue);
  }

  public static getEmptyMoneyStack() {
    const result: NxtMoneyStack = {};
    for (const money of MoneyTools.moneys) {
      result[money] = 0;
    }
    return result;
  }

  public static canReduceMultiFromMoneyStack(moneyStack: NxtMoneyStack, values: { id: string, value: number }[], prioIds: string[]) {
    const logLines: string[] = [];
    const sumMoneyStack = MoneyStackTools.getSum(moneyStack);
    logLines.push('Summe vom Stack: ' + sumMoneyStack.toMoneyString());
    const valueSum = values.reduce((sum, v) => sum + v.value, 0);
    logLines.push('Summe von Values : ' + valueSum.toMoneyString());
    const cloneMoneyStack = clone(moneyStack);
    const result = MoneyStackTools.reduceMultiFromMoneyStack(cloneMoneyStack, values, prioIds);
    result.log = [...logLines, ...result.log];
    return result;
  }

  public static reduceMultiFromMoneyStack(moneyStack: NxtMoneyStack, items: MoneyStackReduceItem[], prioIds: string[]): ReduceMultiFromMoneyStackResult {
    let indicator = DateTools.formatNow('yyyy-MM-dd');
    let result = MoneyStackTools._reduceMultiFromMoneyStack(moneyStack, items, prioIds, indicator);
    if (!result) {
      indicator = Date.now().dateAddDays(1).dateFormat('yyyy-MM-dd');
      result = MoneyStackTools._reduceMultiFromMoneyStack(moneyStack, items, prioIds, indicator);
    }
    if (!result) {
      indicator = Date.now().dateAddDays(2).dateFormat('yyyy-MM-dd');
      result = MoneyStackTools._reduceMultiFromMoneyStack(moneyStack, items, prioIds, indicator);
    }
    return result;
  }

  private static _reduceMultiFromMoneyStack(moneyStack: NxtMoneyStack, items: MoneyStackReduceItem[], prioIds: string[], indicator: string): ReduceMultiFromMoneyStackResult {
    const log: string[] = [];
    if (prioIds) {
      items = MoneyStackTools.setArtistPrio(items, prioIds, indicator);
    }
    let possible = true;
    const result: { id: string, value: number, moneyStack?: NxtMoneyStack, missingMoney?: number }[] = ObjectTools.clone(items);
    for (const value of result) {
      const moneyStackBefore = clone(moneyStack);
      const valueResult = MoneyStackTools.reduceFromMoneyStack(moneyStack, value.value);
      if (!valueResult.possible) {
        const moneyStackBeforeSum = MoneyStackTools.getSum(moneyStackBefore);
        log.push('not possible for ' + value.id + '\n' + value.value.toMoneyString() + '\nmoneyStackBeforeSum: ' + moneyStackBeforeSum.toMoneyString() + '\n' + JsonTools.stringify(MoneyStackTools.onlyValues(moneyStackBefore)));
      }
      value.moneyStack = valueResult.getMoneyStack;
      if (!valueResult.possible) {
        value.missingMoney = valueResult.missingMoney;
        possible = false;
      }
    }
    const missingMoneySum = result.reduce((sum, v) => MathTools.roundMoney(sum + (v.missingMoney ?? 0)), 0);
    return {values: result, possible, missingMoneySum, log};
  }

  public static combineMoneyStacks(moneyStacks: NxtMoneyStack[]) {
    const result = MoneyStackTools.getEmptyMoneyStack();
    for (const moneyStack of moneyStacks) {
      for (const coin of MoneyTools.moneys) {
        if (TypeTools.isNumber(moneyStack[coin])) {
          result[coin] += moneyStack[coin];
        }
      }
    }
    return result;
  }

  public static getMoneyStackText(moneyStack: NxtMoneyStack, separator = ' ') {
    const text: string[] = [];
    for (const coin of MoneyTools.moneysReverse) {
      if (moneyStack[coin] > 0) {
        text.push(moneyStack[coin] + '×' + coin);
      }
    }
    return text.join(separator);
  }

  public static reduceFromMoneyStack(moneyStack: NxtMoneyStack, value: number, getChangeProposal = true, recursiveRetry = 0): {
    possible: boolean,
    getMoneyStack: NxtMoneyStack,
    missingMoney?: number
  } {
    const minCoin = 0.01;
    /*if (value % 1 === 0) {
        minCoin = 1;
    }*/
    const reducedMoneyStack = ObjectTools.clone(moneyStack);
    const startValue = value;
    const getMoneyStack = MoneyStackTools.getEmptyMoneyStack();
    let moneys = MoneyTools.moneysReverse;
    if (recursiveRetry > 0) {
      moneys = MoneyTools.moneysReverseRandom;
    }
    for (const coin of moneys) {
      while (value > 0) {
        try {
          if (value >= coin && coin >= minCoin) {
            if (reducedMoneyStack[coin] > 0) {
              reducedMoneyStack[coin] = Math.round(reducedMoneyStack[coin] - 1);
              getMoneyStack[coin] = Math.round(getMoneyStack[coin] + 1);

              value -= MathTools.roundMoney(coin);
              value = MathTools.roundMoney(value);
            } else {
              break;
            }
          } else {
            break;
          }
        } catch (err: any) {
          throw Error('reduceFromMoneyStack failed\n' + err.message);
        }
      }
    }
    if (value === 0) {
      Object.assign(moneyStack, reducedMoneyStack);
      return {getMoneyStack, possible: true};
    } else {
      if (recursiveRetry < 100) {
        return MoneyStackTools.reduceFromMoneyStack(moneyStack, startValue, getChangeProposal, recursiveRetry + 1);
      }
      Object.assign(moneyStack, reducedMoneyStack);
      return {getMoneyStack, missingMoney: MathTools.roundMoney(value), possible: false};
    }
  }

  /***
   *
   * @param values wer bekommt wie viel
   * @param artistsPrio Gibt es Artists die nur große scheine bekommen?
   * @param indicator Zufalls-String um die sortierung der großen / kleinen Scheine zu beeinflussen
   */
  public static setArtistPrio(values: { id: string, value: number }[], artistsPrio: string[], indicator: string) {
    for (const [index, artistPrio] of artistsPrio.entries()) {
      if (values.find(v => v.id === artistPrio)) {
        (values.find(v => v.id === artistPrio) as any)._sortValue = index;
      }
    }
    return ArrayTools.sortRandomByIndicator(values, 'id', indicator);
  }

  public static getSumFromValues(values: { id: string, value: number }[]) {
    return values.reduce((sum, v) => sum + v.value, 0);
  }

  private static removeEmptyCoins(moneyStack: NxtMoneyStack) {
    for (const key of Object.keys(moneyStack)) {
      if ((moneyStack as any)[key] === 0) {
        delete (moneyStack as any)[key];
      }
    }
  }

  private static getProposalChanges(moneyStack: NxtMoneyStack, value: number): { reduce: NxtMoneyStack, add: NxtMoneyStack } | undefined {
    for (const dummy of MoneyStackTools.dummies) {
      let moneyStackClone = ObjectTools.clone(moneyStack);
      for (let i = 0; i < 10; i++) {
        if (MoneyStackTools.reduceMoneyStackFromMoneyStack(moneyStackClone, dummy.reduce)) {
          moneyStackClone = MoneyStackTools.combineMoneyStacks([moneyStackClone, dummy.add]);
          const tempResult = MoneyStackTools.reduceFromMoneyStack(moneyStackClone, value, false);
          if (tempResult.possible) {
            const dummyClone = ObjectTools.clone(dummy);
            _.keys(dummyClone.reduce).forEach(key => dummyClone.reduce[key] *= i + 1);
            _.keys(dummyClone.add).forEach(key => dummyClone.add[key] *= i + 1);
            return dummyClone;
          }
        }
      }
    }
  }


  static getProposalChangesMulti(moneyStack: NxtMoneyStack, values: number[], deepIndex = 0): { reduce: NxtMoneyStack, add: NxtMoneyStack, sortValue: number }[] {
    const result: { reduce: NxtMoneyStack, add: NxtMoneyStack, sortValue: number }[] = [];
    let dummies = MoneyStackTools.dummies;
    if (deepIndex === 1) {
      dummies = MoneyStackTools.getCombinedDummiesDeep2();
    }
    if (deepIndex === 2) {
      dummies = MoneyStackTools.getCombinedDummiesDeep3();
    }
    for (const dummy of dummies) {
      let moneyStackClone = ObjectTools.clone(moneyStack);
      const i = 0;
      if (MoneyStackTools.reduceMoneyStackFromMoneyStack(moneyStackClone, dummy.reduce)) {
        moneyStackClone = MoneyStackTools.combineMoneyStacks([moneyStackClone, dummy.add]);
        let isPossible = true;
        let lastPossible: any;
        const clone2 = ObjectTools.clone(moneyStackClone);
        for (const value of values) {
          const tempResult = MoneyStackTools.reduceFromMoneyStack(clone2, value, false);
          if (tempResult.possible) {
            const dummyClone = ObjectTools.clone(dummy);
            _.keys(dummyClone.reduce).forEach(key => dummyClone.reduce[key] *= i + 1);
            _.keys(dummyClone.add).forEach(key => dummyClone.add[key] *= i + 1);
            lastPossible = dummyClone;
          } else {
            isPossible = false;
          }
        }
        if (isPossible && lastPossible) {
          if (!result.find(r => MoneyStackTools.isSame(r.reduce, lastPossible.reduce) && MoneyStackTools.isSame(r.add, lastPossible.add))) {
            result.push(lastPossible);
          }
        }
      }
    }
    for (const proposal of result) {
      proposal.sortValue = MoneyStackTools.getSortValueFromChangeProposal(proposal);
    }
    if (result.length === 0 && deepIndex === 0) {
      return MoneyStackTools.getProposalChangesMulti(moneyStack, values, 1);
    }
    if (result.length === 0 && deepIndex === 1) {
      return MoneyStackTools.getProposalChangesMultiViaArray(moneyStack, values);
    }
    return result.sortNumber('sortValue');
  }

  static getProposalChangesMultiViaArray(moneyStack: NxtMoneyStack, values: number[]): { reduce: NxtMoneyStack, add: NxtMoneyStack, sortValue: number }[] {
    const result: { reduce: NxtMoneyStack, add: NxtMoneyStack, sortValue: number }[] = [];
    const dummies = MoneyStackTools.getCombinedDummiesDeep2Array();
    for (const dummy of dummies) {
      let moneyStackClone = ObjectTools.clone(moneyStack);
      const i = 0;
      if (MoneyStackTools.reduceMoneyStackFromMoneyStack(moneyStackClone, dummy.reduce[0])) {
        moneyStackClone = MoneyStackTools.combineMoneyStacks([moneyStackClone, dummy.add[0]]);
        if (MoneyStackTools.reduceMoneyStackFromMoneyStack(moneyStackClone, dummy.reduce[1])) {
          moneyStackClone = MoneyStackTools.combineMoneyStacks([moneyStackClone, dummy.add[1]]);
          let isPossible = true;
          let lastPossible: any;
          const clone2 = ObjectTools.clone(moneyStackClone);
          for (const value of values) {
            const tempResult = MoneyStackTools.reduceFromMoneyStack(clone2, value, false);
            if (tempResult.possible) {
              lastPossible = MoneyStackTools.getMoneyStackDiff(moneyStack, moneyStackClone);
            } else {
              isPossible = false;
            }
          }
          if (isPossible && lastPossible) {
            if (!result.find(r => MoneyStackTools.isSame(r.reduce, lastPossible.reduce) && MoneyStackTools.isSame(r.add, lastPossible.add))) {
              result.push(lastPossible);
            }
            // break;
          }
        }
      }
    }
    for (const proposal of result) {
      proposal.sortValue = MoneyStackTools.getSortValueFromChangeProposal(proposal);
    }
    return result.sortNumber('sortValue');
  }


  static reduceMoneyStackFromMoneyStack(moneyStack: NxtMoneyStack, toReduce: NxtMoneyStack) {
    for (const key of _.keys(toReduce)) {
      if (moneyStack[key] >= toReduce[key]) {
        moneyStack[key] -= toReduce[key];
      } else {
        return false;
      }
    }
    return true;
  }

  static getSortValueFromChangeProposal(changeProposal: { add: NxtMoneyStack, reduce: NxtMoneyStack }) {
    const valueFromKeys = keys(changeProposal.reduce).length + keys(changeProposal.add).length;
    let valuesFromBanknoteCount = 0;
    for (const key of keys(changeProposal.reduce)) {
      valuesFromBanknoteCount += changeProposal.reduce[key];
    }
    for (const key of keys(changeProposal.add)) {
      valuesFromBanknoteCount += changeProposal.add[key];
    }
    return valueFromKeys + valuesFromBanknoteCount + keys(changeProposal.add).length;
  }

  static test3() {
    const data1 = JsonTools.parse('{\n' +
      '  "1": 0,\n' +
      '  "2": 0,\n' +
      '  "5": 0,\n' +
      '  "10": 0,\n' +
      '  "20": 20,\n' +
      '  "50": 3,\n' +
      '  "100": 0,\n' +
      '  "200": 0,\n' +
      '  "0.01": 1,\n' +
      '  "0.02": 1,\n' +
      '  "0.05": 4,\n' +
      '  "0.1": 8,\n' +
      '  "0.2": 6,\n' +
      '  "0.5": 0\n' +
      '}');

    const result1 = MoneyStackTools.reduceFromMoneyStack(clone(data1), 380);
    const result2 = MoneyStackTools.reduceFromMoneyStack(clone(data1), 380);
    const result3 = MoneyStackTools.reduceFromMoneyStack(clone(data1), 380);
    const result4 = MoneyStackTools.reduceFromMoneyStack(clone(data1), 380);
    const result5 = MoneyStackTools.reduceFromMoneyStack(clone(data1), 380);
    const result6 = MoneyStackTools.reduceFromMoneyStack(clone(data1), 380);
    const result7 = MoneyStackTools.reduceFromMoneyStack(clone(data1), 380);
    const result8 = MoneyStackTools.reduceFromMoneyStack(clone(data1), 380);
    console.log(result1);
    console.log(result2);
    console.log(result3);
    console.log(result4);
    console.log(result5);
    console.log(result6);
    console.log(result7);
    console.log(result8);
    // debugger;

  }

  static test2() {
    return;
    const data1 = {
      1: 1,
      2: 2,
      5: 4,
      10: 1,
      20: 41,
      50: 19,
      100: 9,
      200: 0,
      0.01: 0,
      0.02: 2,
      0.05: 2,
      0.1: 2,
      0.2: 1,
      0.5: 0
    };

    const data2 = [
      {
        id: 'Tresor',
        value: 600,
      },
      {
        id: 'Lolo',
        value: 0,
      },
      {
        id: 'Jacpol',
        value: 480,
      },
      {
        id: 'Anna (Piercing)',
        value: 0,
      },
      {
        id: 'Toxa',
        value: 460,
      },
      {
        id: 'Ibra',
        value: 500,
      },
      {
        id: 'Nicole',
        value: 630
      }
    ];
    const result = MoneyStackTools.canReduceMultiFromMoneyStack(clone(data1), data2, ['Tresor']);
    const result2 = MoneyStackTools.canReduceMultiFromMoneyStack(clone(data1), data2, ['Tresor']);
    const result3 = MoneyStackTools.canReduceMultiFromMoneyStack(clone(data1), data2, ['Tresor']);
    const result4 = MoneyStackTools.canReduceMultiFromMoneyStack(clone(data1), data2, ['Tresor']);
    const result5 = MoneyStackTools.canReduceMultiFromMoneyStack(clone(data1), data2, ['Tresor']);
    const result6 = MoneyStackTools.canReduceMultiFromMoneyStack(clone(data1), data2, ['Tresor']);
    const result7 = MoneyStackTools.canReduceMultiFromMoneyStack(clone(data1), data2, ['Tresor']);
    const result8 = MoneyStackTools.canReduceMultiFromMoneyStack(clone(data1), data2, ['Tresor']);

    console.log(result);
    console.log(result2);
    console.log(result3);
    console.log(result4);
    console.log(result5);
    console.log(result6);
    console.log(result7);
    console.log(result8);
    debugger;
  }

  /***
   * hier money-stack reduce texts 0x34456455
   */
  static test() {
    const moneyStack = JsonTools.parse('{"1":0,"2":0,"5":0,"10":0,"20":2,"50":0,"100":0,"200":0,"0.01":0,"0.02":0,"0.05":0,"0.1":0,"0.2":0,"0.5":0}');
    const result2 = MoneyStackTools.canReduceMultiFromMoneyStack(moneyStack, [{id: 'x', value: 630}], []);
    const sum = MoneyStackTools.getTotalValueFromMoneyStack(moneyStack);
    console.log(sum);

    for (const dummy of MoneyStackTools.dummies) {

      for (const key of keys(dummy.reduce)) {
        if (dummy.reduce[key] % 1 !== 0) {
          // es gibt nur ganze münzen
          debugger;
        }
      }

      for (const key of keys(dummy.reduce)) {
        if (dummy.reduce[key] % 1 !== 0) {
          // es gibt nur ganze münzen
          debugger;
        }
      }

      if (MoneyStackTools.getTotalValueFromMoneyStack(dummy.reduce) !== MoneyStackTools.getTotalValueFromMoneyStack(dummy.add)) {
        debugger;
        throw new Error('MoneyStackTools.dummies is not correct\n' + dummy);
      }
      let sameCounter = 0;
      for (const dummy2 of MoneyStackTools.dummies) {
        if (MoneyStackTools.isSame(dummy.reduce, dummy2.reduce)) {
          if (MoneyStackTools.isSame(dummy.add, dummy2.add)) {
            sameCounter++;
          }
        }
      }
      if (sameCounter > 1) {
        /*** ACHUNG ES GIBT DOPPELTE WECHSEL-VORSCHLÄGE ***/
        debugger;
      }
    }
  }

  static isSame(stack1: NxtMoneyStack, stack2: NxtMoneyStack) {
    const value1 = MoneyStackTools.getTotalValueFromMoneyStack(stack1);
    const value2 = MoneyStackTools.getTotalValueFromMoneyStack(stack2);
    if (value1 === value2) {
      for (const key1 of keys(stack1)) {
        if (stack1[key1] !== stack2[key1]) {
          return false;
        }
      }
      for (const key2 of keys(stack2)) {
        if (stack1[key2] !== stack2[key2]) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  private static getMoneyStackDiff(moneyStack: NxtMoneyStack, newMoneyStack: NxtMoneyStack) {
    MoneyStackTools.fillMoneyStackEmptyCoins(moneyStack);
    const result = {
      reduce: {},
      add: {}
    };
    for (const coin of MoneyTools.moneys) {
      if (moneyStack[coin] > newMoneyStack[coin]) {
        result.reduce[coin] = moneyStack[coin] - newMoneyStack[coin];
      }
      if (moneyStack[coin] < newMoneyStack[coin]) {
        result.add[coin] = newMoneyStack[coin] - moneyStack[coin];
      }
    }
    return result;
  }

  private static fillMoneyStackEmptyCoins(moneyStack: NxtMoneyStack) {
    for (const coin of MoneyTools.moneys) {
      if (!moneyStack[coin]) {
        moneyStack[coin] = 0;
      }
    }
  }

  private static onlyValues(moneyStack: NxtMoneyStack) {
    const moneyStackClone = clone(moneyStack);
    for (const key of keys(moneyStackClone)) {
      if (moneyStackClone[key] === 0) {
        delete moneyStackClone[key];
      }
    }
    return moneyStackClone;
  }
}
