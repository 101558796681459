<div class="flex flex-col">
  <div class="flex flex-row justify-between mb-3">
    <div class="flex flex-row justify-center text-3xl flex-grow">{{ timeText() }}</div>
    <nxt-button-icon (click)="closeClicked()">close</nxt-button-icon>
  </div>
  <div class="flex flex-col">
    @if (src()) {
      <img [src]="src()">
    } @else {
      <div>WhatsApp wird geladen</div>
    }
  </div>
  <!--<div class="flex w-full flex-row justify-center">
    <nxt-button>OK</nxt-button>
  </div>-->
</div>
