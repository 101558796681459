import {Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ClipboardService} from '../../services/clipboard.service';
import {DisplayWithTools} from '../../common-browser/helpers/display-with.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {NgIf} from '@angular/common';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {AutocompleteComponent} from '../form-controls/autocomplete/autocomplete.component';

@Component({
    selector: 'nxt-artists-gallery-links',
    templateUrl: './artists-gallery-links.component.html',
    styleUrls: ['./artists-gallery-links.component.scss'],
    imports: [AutocompleteComponent, NxtButtonComponent, NgIf]
})
export class ArtistsGalleryLinksComponent {

  constructor(
    private clipboardService: ClipboardService,
    private httpClient: HttpClient,
    private dialogRef: MatDialogRef<any>
  ) {
    this.load();
  }

  artists: any[];

  protected readonly DisplayWithTools = DisplayWithTools;
  currentArtist: any;

  load() {
    this.httpClient.get('https://artists.nxt-lvl.ink/image-drive/configs/artist-gallery.json').subscribe((data) => {
      this.artists = (data as any).artists;
    });
  }

  artistClicked(artist: any) {
    this.clipboardService.copyToClipboard('https://artists.nxt-lvl.ink/#/artists/' + artist.name);
  }

  closeClicked() {
    this.dialogRef.close();
  }

  sendClicked() {
    this.dialogRef.close(this.currentArtist.name);
  }
}
