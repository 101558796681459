<div class="flex flex-col justify-center items-center" *ngIf="dailyNote()">
  <div class="text-120">{{ dailyNote().dateString | nxtDate: 'EEE dd.MM.yyyy' }}</div>
  <div class="text-120">{{ dailyNote().id ? 'Tages-Notiz bearbeiten' : 'Neue Tages-Notiz' }}</div>
  <div>
    <nxt-input class="w-[400px]" placeholder="Text" [(value)]="text"></nxt-input>
  </div>
  <div class="flex flex-row justify-around w-full">
    <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
    @if (dailyNote().id) {
      <nxt-button (click)="deleteClicked()">Löschen</nxt-button>
    }
    <nxt-button (click)="saveClicked()">{{ dailyNote().id ? 'Speichern' : 'Anlegen' }}</nxt-button>
  </div>
</div>
