import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {EventComponent} from '../../event/event.component';
import {SocketService} from '../../services/socket/socket.service';
import {DialogService} from '../../services/dialog.service';
import {NxtPaymentPossibilityRecord} from '../../common-interfaces/nxt.payment-possibility-record.interface';
import {LoginService} from '../../services/login.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CalendarEventEdit2Component} from '../../pages/calendar-event-edit/calendar-event-edit-2/calendar-event-edit-2.component';
import {MoneyPipe} from '../../pipes/money.pipe';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {InputComponent} from '../form-controls/input/input.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf} from '@angular/common';


@Component({
  selector: 'nxt-gift-card-edit',
  templateUrl: './gift-card-edit.component.html',
  styleUrls: ['./gift-card-edit.component.scss'],
  imports: [NgIf, FlexModule, NgFor, InputComponent, NxtButtonComponent, MoneyPipe],
})
export class GiftCardEditComponent implements OnInit, AfterViewInit, OnDestroy {

  showPdf = this.loginService.getUsername() === 'Niklas' || this.loginService.getUsername() === 'Julian';
  discountPercentage = 0;
  paymentPossibilityRecord: NxtPaymentPossibilityRecord;


  constructor(
    private dialogRef: MatDialogRef<EventComponent>,
    @Inject(MAT_DIALOG_DATA) private data: string | NxtPaymentPossibilityRecord,
    private socketService: SocketService,
    private dialogService: DialogService,
    private loginService: LoginService,
  ) {
    // document.body.style.backgroundColor = 'transparent !important';
  }

  ngOnInit() {
  }


  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }


  async save() {
    if (this.paymentPossibilityRecord.assigned) {
      this.dialogService.showOk('Gutschein ist bereits zugewiesen, dann geht das leider nicht mehr\nWende dich ans Backoffice');
      return;
    }
    if (typeof this.paymentPossibilityRecord.paymentValue !== 'number') {
      await this.dialogService.showOk('Eingabe fehlt!');
      return;
    }
    if (this.paymentPossibilityRecord.paymentValue > this.paymentPossibilityRecord.value) {
      await this.dialogService.showOk('Der Kunde hat nicht mehr bezahlt als der Gutschein wert ist!');
      return;
    }
    this.paymentPossibilityRecord.originalPaymentValue = this.paymentPossibilityRecord.paymentValue;
    await this.socketService.updateGiftCardPaymentValue(this.paymentPossibilityRecord.id, this.paymentPossibilityRecord.paymentValue);
    this.close();
  }


  close() {
    this.dialogRef.close();
  }


  async load(id: string) {
    this.paymentPossibilityRecord = await this.socketService.getPaymentPossibilityRecord(id);
    if (!this.paymentPossibilityRecord) {
      await this.dialogService.showOk('Gutschein nicht gefunden');
      this.close();
    }
    if (this.paymentPossibilityRecord.paymentMethod !== 'cash') {
      await this.dialogService.showOk('Nur Bar bezahlte Gutscheine können bearbeitet werden');
      this.close();
    }
  }

  showEvent(eventId: any) {
    const dialog = this.dialogService.showComponentFull(CalendarEventEdit2Component);
    setTimeout(() => {
      dialog.componentInstance.loadEvent({eventId}).then();
    }, 500);
  }

  async deleteClicked() {
    if (await this.dialogService.showYesNo('Gutschein löschen?')) {
      await this.socketService.deleteGiftCard(this.paymentPossibilityRecord.id);
      this.dialogRef.close();
    }
  }
}
