<div class="nxt-page h-100">
  <div *ngIf="!mobilesFiltered" class="w-100 h-100 flex justify-center items-center">
    <nxt-spinner></nxt-spinner>
  </div>
  <div *ngIf="mobilesFiltered" class="flex justify-between items-center">
    <div class="text-2xl">Kontakte zusammenführen ({{this.mobiles ? this.mobiles.length : ''}})</div>

    <div>Wähle die richtigen Daten aus!</div>
    <!--<nxt-slide-toggle [(value)]="deleteContact">Alte Kontakte löschen</nxt-slide-toggle>-->
    <nxt-button (click)="deleteSelectedContactsClicked()">Markierte Kontakte löschen</nxt-button>
    <nxt-input class="w-64" [(value)]="quickFilterText" (valueChange)="quickFilterTextChanged()" placeholder="Suche"></nxt-input>
    <nxt-button-icon (click)="closeClicked()">close</nxt-button-icon>
  </div>
  <div *ngIf="mobilesFiltered" class="scrollable h-100">
    <table class="nxt-table">
      <tr>
        <th>Mobile</th>
        <th>Kunden</th>
        <th>Aktion</th>
      </tr>
      <tr *ngFor="let mobile of mobilesFiltered">
        <td>
          {{mobile.mobile}}
        </td>
        <td style="padding: 0">
          <table>
            <tr *ngFor="let contact of mobile.contacts">
              <td (click)="editClicked(contact)" [innerHTML]="IconTools.Material.Edit | safeHtml"></td>
              <!--<td>
                <div class="flex justify-center items-center">
                  <div (click)="deleteClicked(contact)" [innerHTML]="IconTools.Material.Delete | safeHtml"></div>
                  <br>
                  <nxt-slide-toggle [(value)]="contactsToDelete[contact.id]"></nxt-slide-toggle>
                </div>
              </td>-->
              <td [ngClass]="{green: $any(contact).sameName}">{{contact.fullName}}</td>
              <td [ngClass]="{green: $any(contact).sameBirthday}">{{contact.birthday | nxtDate: 'dd.MM.yyyy'}}</td>
              <td>
                <div class="text-xs">
                  <div *ngIf="contact.postalCode">{{contact.postalCode}}</div>
                  <div *ngIf="contact.city">{{contact.city}}</div>
                  <div *ngIf="contact.country">{{contact.country}}</div>
                  <div *ngIf="contact.email">{{contact.email}}</div>
                </div>
              </td>
              <td (click)="showChatClicked(contact)" [innerHTML]="IconTools.Material.Chat | safeHtml"></td>
              <td (click)="showHistoryClicked(contact)" [innerHTML]="IconTools.Material.History | safeHtml"></td>
              <td style="padding: 0" [ngClass]="{red: $any(contact).noEvents}">
                <table style="font-size: 75%" class="nxt-table-small-padding">
                  <tr [ngClass]="{'mouse-pointer' : contact.closedEvents?.length > 0}" (click)="showEventClicked('geschlossene', contact.closedEvents)">
                    <td>Fertige</td>
                    <td>{{contact.closedEvents.length}}</td>
                  </tr>
                  <tr [ngClass]="{'mouse-pointer' : contact.canceledEvents?.length > 0}" (click)="showEventClicked('abgesagte', contact.canceledEvents)">
                    <td>Abgesagte</td>
                    <td>{{contact?.canceledEvents?.length}}</td>
                  </tr>
                  <tr [ngClass]="{'mouse-pointer' : contact.events?.length > 0}" (click)="showEventClicked('zukünftige', contact.events)">
                    <td>Zukünftige</td>
                    <td>{{contact?.events?.length}}</td>
                  </tr>
                </table>
              </td>
              <!--<td>
                <nxt-slide-toggle [(value)]="mainContacts[contact.id]">Behalten</nxt-slide-toggle>
              </td>-->
            </tr>
          </table>
        </td>
        <td>
          <nxt-button (click)="combineContactsClicked(mobile.contacts)">Zusammenführen</nxt-button>
        </td>
      </tr>
    </table>
  </div>


</div>
