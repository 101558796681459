import {ChangeDetectionStrategy, Component, computed, effect, inject, OnInit, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtPageComponent} from '../../nxt-page/nxt-page.component';
import {NxtPageHeaderComponent} from '../../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageHeaderTitleComponent} from '../../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageContentComponent} from '../../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtBankConfigAccountLive, NxtBankTransaction, NxtBankTransactionStatus} from '../../../common-interfaces/bank/bank-transaction.interface';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {SocketService} from '../../../services/socket/socket.service';
import {InputComponent} from '../../form-controls/input/input.component';
import {IconTools} from '../../../common-browser/helpers/icon.tools';
import {BankTransactionDetailsComponent} from './bank-transaction-details/bank-transaction-details.component';
import {DialogService} from '../../../services/dialog.service';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {SocketInterface, SocketInterfaceResponse} from '../../../common-interfaces/socket/socket-interface';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {FormsModule} from '@angular/forms';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {DateRangePickerComponent} from '../../form-controls/date-range-picker/date-range-picker.component';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {LoginService} from '../../../services/login.service';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {firstValueFrom} from 'rxjs';
import {DecimalTools} from '../../../common-browser/helpers/decimal.tools';
import {MoneyTools} from '../../../common-browser/helpers/money.tools';
import {BankDocumentDetailsComponent} from '../bank-documents/bank-document-details/bank-document-details.component';
import {BankTransactionTools} from '../../../common-browser/helpers/bank-transaction.tools';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {PermissionDirective} from '../../../directives/permission.directive';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {BankCreateTransferComponent} from '../bank-create-transfer/bank-create-transfer.component';
import {TimeTools} from '../../../common-browser/helpers/time.tools';
import {DurationFromNowOnlyPastPipe} from '../../../pipes/duration-from-now-only-past.pipe';
import {Money2Pipe} from '../../../pipes/money-2.pipe';

@Component({
  selector: 'nxt-bank-transactions',
  templateUrl: './bank-transactions.component.html',
  styleUrls: ['./bank-transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtPageComponent, NxtPageHeaderComponent, NxtPageHeaderTitleComponent, NxtPageContentComponent, NxtPageFooterComponent, NxtDatagridComponent, InputComponent, MatButtonToggleGroup, MatButtonToggle, FormsModule, MatIcon, MatTooltip, DateRangePickerComponent, NxtButtonIconComponent, MoneyPipe, SlideToggleComponent, NxtButtonComponent, PermissionDirective, DurationFromNowOnlyPastPipe, Money2Pipe],
  standalone: true,
})
export class BankTransactionsComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor() {
    super();
    effect(() => {

    });
  }

  @ViewChild(NxtDatagridComponent) datagrid: NxtDatagridComponent;

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  isLoading = signal(false);
  transactions = signal<NxtBankTransaction[]>([]);


  paypalAccounts = signal<NxtBankConfigAccountLive[]>([]);
  paypalAccountsFiltered = computed(() => {
    return this.paypalAccounts().filter(a => this.filterCompany().includes(a.company));
  });

  klarnaAccounts = signal<NxtBankConfigAccountLive[]>([]);
  klarnaAccountsFiltered = computed(() => {
    return this.klarnaAccounts().filter(a => this.filterCompany().includes(a.company));
  });

  availablePaypal = computed(() => {
    return this.paypalAccounts().reduce((sum, a) => sum + a.saldo, 0);
  });

  qontoAccounts = signal<NxtBankConfigAccountLive[]>([]);
  qontoAccountsFiltered = computed(() => {
    return this.qontoAccounts().filter(a => this.filterCompany().includes(a.company));
  });
  availableQonto = computed(() => {
    return this.qontoAccounts().reduce((sum, a) => sum + a.saldo, 0);
  });


  spkAccounts = signal<NxtBankConfigAccountLive[]>([]);
  spkAccountsFiltered = computed(() => {
    return this.spkAccounts().filter(a => this.filterCompany().includes(a.company));
  });

  cashRegisterAccounts = signal<NxtBankConfigAccountLive[]>([]);
  cashRegisterAccountsFiltered = computed(() => {
    return this.cashRegisterAccounts().filter(a => this.filterCompany().includes(a.company));
  });

  depositAccounts = signal<NxtBankConfigAccountLive[]>([]);
  depositAccountsFiltered = computed(() => {
    return this.depositAccounts().filter(a => this.filterCompany().includes(a.company));
  });

  availableSpk = computed(() => {
    const sum = this.spkAccounts().reduce((s, a) => s + a.saldo, 0);
    const limit = this.spkAccounts().reduce((s, a) => s + a.limit, 0);
    return sum - limit;
  });


  creditCardAccounts = signal<NxtBankConfigAccountLive[]>([]);

  creditCardAccountsFiltered = computed(() => {
    return this.creditCardAccounts().filter(a => this.filterCompany().includes(a.company));
  });

  filterCompany = signal<string[]>(['GBX GmbH']);
  filterPaypalAccount = signal<string[]>([]);
  filterKlarnaAccount = signal<string[]>([]);
  filterQontoAccount = signal<string[]>([]);
  filterSpkAccount = signal<string[]>([]);
  filterCreditCardAccount = signal<string[]>([]);
  filterCashRegisterAccount = signal<string[]>([]);
  filterDepositAccount = signal<string[]>([]);
  filterAccount = computed(() => {
    return this.filterPaypalAccount().concat(this.filterQontoAccount()).concat(this.filterSpkAccount()).concat(this.filterCreditCardAccount()).concat(this.filterCashRegisterAccount()).concat(this.filterKlarnaAccount()).concat(this.filterDepositAccount());
  });
  quickFilterText = signal('');
  transactionsFiltered = computed(() => {

    // this.filterOnlyLastMonth();
    let filterValue: number | undefined;
    if (this.filterValue()) {
      if (this.filterValue().includes(',')) {
        filterValue = Math.abs(parseFloat(this.filterValue().replaceAll('€', '').trim().replaceAll('.', '').replaceAll(',', '.')));
      } else {
        filterValue = Math.abs(parseFloat(this.filterValue().replaceAll('€', '').trim().replaceAll(',', '.')));
      }
    }

    // const lastMonthStart = DateTools.getFirstOfMonth(Date.now().dateAddMonths(-1));
    // const lastMonthEnd = DateTools.getFirstOfMonth(Date.now()) - 1000;

    return this.transactions().filter(t => {
      const accountOk = this.filterAccount().includes(t.accountId);
      const filterValueOk = !filterValue || Math.abs(t.value) === filterValue;
      // const onlyLastMonthOk = !this.filterOnlyLastMonth() || t.bookDate >= lastMonthStart && t.bookDate <= lastMonthEnd;
      return accountOk && filterValueOk;
    });
  });

  accountsById: { [id: string]: NxtBankConfigAccountLive } = {};
  accountsByIban: { [id: string]: NxtBankConfigAccountLive } = {};
  data: SocketInterfaceResponse.GetBankTransactions;


  /*** Injections ***/
  private socketService = inject(SocketService);
  private router = inject(Router);
  private loginService = inject(LoginService);
  private dialogService = inject(DialogService);
  private localStorageService = inject(LocalStorageService);
  public dialogRef = inject(MatDialogRef, {optional: true});

  columnDefs: NxtColDef<NxtBankTransaction>[] = [

    {
      headerName: '',
      valueFormatter: () => IconTools.Material.Edit, nxtFieldType: NxtFieldType.Icon,
      nxtOnCellClicked: (data) => this.editClicked(data.data),
      maxWidth: 45,
      minWidth: 45,
      hide: false,
      filter: false,
    },
    {
      headerName: 'ID',
      field: 'id',
      suppressAutoSize: true,
      width: 70,
      nxtFieldType: NxtFieldType.Text,
      hide: false,
      cellStyle: params => (params.data.type === 'internal-transfer') ? {color: ColorTools.BlueLight, textAlign: 'center'} : {textAlign: 'center'},
    },
    {
      headerName: 'Status',
      filter: true,
      valueGetter: params => {
        if (params.data.statusMessage) {
          return '❗ ' + params.data.statusMessage + ' ' + params.data.status;
        }
        return params.data.status;
      },
      nxtFieldType: NxtFieldType.Text,
      cellStyle: params => (params.data.type === 'internal-transfer') ? {color: ColorTools.BlueLight, textAlign: 'center'} : {textAlign: 'center'},
      hide: false,
    },
    {
      headerName: 'Type',
      nxtFieldType: NxtFieldType.Text,
      valueGetter: params => BankTransactionTools.getTypeText(params.data.type),
      minWidth: 130,
      maxWidth: 130,
      cellStyle: params => (params.data?.type === 'internal-transfer') ? {color: ColorTools.BlueLight} : {},
    },
    {
      headerName: 'run',
      cellRenderer: () => IconTools.Material.Refresh, nxtFieldType: NxtFieldType.Icon,
      nxtOnCellClicked: (data) => this.runSingleClicked(data.data.id),
      cellStyle: params => (params.data.type === 'internal-transfer') ? {color: ColorTools.BlueLight, textAlign: 'center'} : {textAlign: 'center'},
      maxWidth: 45,
      minWidth: 45,
      hide: !this.loginService.isJulian(),
      filter: false,
    },
    {
      headerName: 'Erstellt',
      field: 'bankCreatedAt',
      nxtFieldType: NxtFieldType.Date_germanDateTime,
      minWidth: 160,
      maxWidth: 160,
      cellStyle: params => (params.data.type === 'internal-transfer') ? {color: ColorTools.BlueLight} : {},
      hide: true,
    },
    {
      headerName: 'Datum',
      valueFormatter: params => {
        if (!params.data.bankCreatedAt) {
          return '';
        }
        const days = ((Date.now() - params.data.bankCreatedAt) / DurationTools.DURATION_1DAY).abs().round(0);
        const daysText = days === 1 ? '1 Tag' : (days + ' Tage');
        return daysText + ' · ' + params.data.bankCreatedAt.dateFormat('dd.MM.yyyy HH:mm');
      },
      nxtFieldType: NxtFieldType.Date_germanDate,
      cellStyle: params => (params.data?.type === 'internal-transfer') ? {color: ColorTools.BlueLight} : {},

    }, {
      headerName: 'Zuordnung',
      filter: true,
      hide: false,
      valueGetter: params => {
        return BankTransactionTools.getAssignText(params.data);
      },

      valueFormatter: params => {
        return '<div class="flex flex-row justify-end items-center">' + params.value + '</div>';
      },
      cellStyle: params => {
        const style: any = {textAlign: 'right'};
        if (params.data.type === 'internal-transfer') {
          style.color = ColorTools.BlueLight;
        }
        return style;
      },
      nxtOnCellClicked: params => this.showInvoiceClicked(params.data),
    }, {
      headerName: 'Konto', field: 'accountId', valueGetter: params => {
        return (this.accountsById[params.data.accountId]?.datevNo + ' | ' + this.accountsById[params.data.accountId]?.name) || '?';
      },
      nxtFieldType: NxtFieldType.Text,
      width: 250,
      suppressAutoSize: true,
      cellStyle: params => (params.data.type === 'internal-transfer') ? {color: ColorTools.BlueLight} : {},
    }, {
      headerName: 'Gegenüber',
      cellRenderer: (params) => {
        if (params.data.direction === 'in') {
          return '⇦ ' + params.value;
        } else {
          return '⇨ ' + params.value;
        }
      },
      valueGetter: params => {
        if (this.accountsByIban[params.data.other.iban]) {
          return this.accountsByIban[params.data.other.iban].name || '';
        } else {
          return params.data.other.name || '';
        }
      }, nxtFieldType: NxtFieldType.Text,
      minWidth: 180,
      suppressAutoSize: true,
      hide: false,
      cellStyle: params => (params.data.type === 'internal-transfer') ? {color: ColorTools.BlueLight} : {},
    }, {
      headerName: 'Gegenkonto',
      field: 'nxtAi.datevOther.name',
      nxtFieldType: NxtFieldType.Text,
      width: 230,
      suppressAutoSize: true,
      valueGetter: params => {
        if (!params.data.nxtAi) {
          return 'AI fehlt';
        }
        const parts: string[] = [];
        if (params.data.nxtAi?.datevOther?.datevNo) {
          parts.push(params.data.nxtAi.datevOther?.datevNo);
        }
        if (params.data.nxtAi?.datevOther?.name) {
          parts.push(params.data.nxtAi.datevOther.name);
        }
        if (params.data.nxtAi?.datevOther?.name2) {
          parts.push(params.data.nxtAi.datevOther.name2);
        }
        return parts.join(' | ');
      },
      cellStyle: params => (params.data.type === 'internal-transfer') ? {color: ColorTools.BlueLight} : {},
    }, {
      headerName: 'Betrag',
      field: 'value',
      nxtFieldType: NxtFieldType.Text,
      minWidth: 100,
      maxWidth: 100,
      cellStyle: params => {
        if ((params.data.type === 'internal-transfer')) {
          return {color: ColorTools.BlueLight, textAlign: 'right'};
        }
        return {color: params.data.value > 0 ? ColorTools.Green : ColorTools.Red, textAlign: 'right'};
      },
      valueFormatter: params => DecimalTools.toMoneyString(params.data.value, MoneyTools.currencyShort(params.data.currency)),
    },
    {
      headerName: 'Offen',
      field: 'assignValue',
      cellStyle: params => (params.data.type === 'internal-transfer') ? {color: ColorTools.BlueLight, textAlign: 'right'} : {textAlign: 'right'},
      valueFormatter: params => {
        return DecimalTools.toMoneyString(params.value, MoneyTools.currencyShort(params.data.currency));
      },
    },
    {
      headerName: 'Buchungsinfo',
      field: 'transactionText',
      suppressAutoSize: true,
      nxtFieldType: NxtFieldType.Text,
      width: 200,
      cellStyle: params => (params.data.type === 'internal-transfer') ? {color: ColorTools.BlueLight} : {},
    }, {
      headerName: 'Text',
      field: 'text',
      suppressAutoSize: true,
      nxtFieldType: NxtFieldType.Text,
      width: 250,
      cellStyle: params => (params.data.type === 'internal-transfer') ? {color: ColorTools.BlueLight} : {},
    }, {
      headerName: 'Typ',
      field: 'method',
      nxtFieldType: NxtFieldType.Text,
      valueGetter: params => {
        if (['ECHTZEIT-ÜBERWEISUNG', 'Transfer', 'ÜBERTRAG (ÜBERWEISUNG)', 'ONLINE-ÜBERWEISUNG'].includes(params.data.method)) {
          return 'Überweisung';
        } else if (['ECHTZEIT-GUTSCHRIFT', 'GUTSCHRIFT ÜBERWEISUNG', 'Income', 'ÜBERTRAG (GUTSCHRIFT ÜBERWEISUNG)'].includes(params.data.method)) {
          return 'Gutschrift';
        } else if (['BARGELDEINZAHLUNG SB'].includes(params.data.method)) {
          return 'Bargeldeinzahlung';
        } else if (['BARGELDAUSZAHLUNG'].includes(params.data.method)) {
          return 'Bargeldauszahlung';
        } else if (['FOLGELASTSCHRIFT'].includes(params.data.method)) {
          return 'Folgelastschrift';
        } else if (['ERSTLASTSCHRIFT'].includes(params.data.method)) {
          return 'Erstlastschrift';
        } else if (['KARTENZAHLUNG', 'DIGITALE KARTE (ANDROID)'].includes(params.data.method)) {
          return 'Kartenzahlung';
        } else if (['DAUERAUFTRAG'].includes(params.data.method)) {
          return 'Dauerauftrag';
        } else if (['SEPA-ELV-LASTSCHRIFT'].includes(params.data.method)) {
          return 'SEPA-Lastschrift';
        }
        if (params.data.method.startsWith('T') && params.data.method.length === 5) {
          return params.data.method;
        }
        return params.data.method;
      },
      cellStyle: params => (params.data.type === 'internal-transfer') ? {color: ColorTools.BlueLight} : {},
      width: 120,
      suppressAutoSize: true,
    }, {
      headerName: 'Id',
      field: 'id',
      nxtFieldType: NxtFieldType.Text,
      minWidth: 160,
      maxWidth: 160,
      hide: true,
    }, {
      headerName: 'Fehler',
      field: 'nxtAi.error',
      nxtFieldType: NxtFieldType.Text,
    },
  ];

  dateFromTill = signal({
    from: DateTools.getFirstOfMonth(Date.now()).dateFormatDate(),
    till: DateTools.getFirstOfMonth(Date.now()).dateAddMonths(1).dateAddDays(-1).dateFormatDate(),
  });
  unknownCount = signal(0);
  filterValue = signal('');
  // filterOnlyLastMonth = signal(true);
  filterNotFinished = signal(false);
  filterMode = signal<'time' | 'bank-cash' | 'opos'>('time');

  async ngOnInit() {
    this.restoreAllFiltersFromStorage();
    await this.load();
    this.selectAllAccounts(true);
  }

  nxtOnDestroy() {
  }

  async load() {
    this.saveAllFiltersToStorage();
    /*if (this.filterMode() !== 'todo') {
      this.filterOnlyLastMonth.set(false);
    }*/
    this.isLoading.set(true);
    const filter: SocketInterface.GetBankTransactions = {
      companies: this.filterCompany(),
    };
    switch (this.filterMode()) {
      case 'time':
        filter.from = this.dateFromTill().from;
        filter.till = this.dateFromTill().till;
        break;
      /*case 'todo':
        filter.todo = true;
        break;*/
      case 'bank-cash':
        filter.bankCash = true;
        break;
      case 'opos':
        filter.opos = true;
        break;
    }
    if (this.filterNotFinished()) {
      filter.notFinished = true;
    }
    const data = await this.socketService.bank.getBankTransactions(filter);
    for (const account of data.accounts) {
      // this.accountsByNumber[account.number] = account;
      this.accountsById[account.id] = account;
      this.accountsByIban[account.iban] = account;
    }
    this.data = data;
    if (this.filterMode() === 'opos') {
      data.transactions = data.transactions.sortNumber('value', false);
    }
    this.transactions.set(data.transactions);
    this.paypalAccounts.set(data.accounts.filter(a => a.type === 'paypal').sortString('name'));
    this.klarnaAccounts.set(data.accounts.filter(a => a.type === 'klarna').sortString('name'));
    this.qontoAccounts.set(data.accounts.filter(a => a.type === 'qonto').sortString('name'));
    this.spkAccounts.set(data.accounts.filter(a => a.type === 'spk').sortString('name'));
    this.creditCardAccounts.set(data.accounts.filter(a => a.type === 'spk-credit-card' || a.type === 'spk-credit-card-transfer').sortString('name'));
    this.cashRegisterAccounts.set(data.accounts.filter(a => a.type === 'cash-register').sortString('name'));
    this.depositAccounts.set(data.accounts.filter(a => a.type === 'datev-account').sortString('name'));
    this.unknownCount.set(data.transactions.filter(t => t.type === 'unknown').length);

    this.isLoading.set(false);
    this.dialogService.hideLoading();
  }

  private async editClicked(transaction: NxtBankTransaction) {
    const dialog = this.dialogService.showComponentFull(BankTransactionDetailsComponent);
    dialog.componentInstance.load(transaction.id).then();
    if (await firstValueFrom(dialog.afterClosed())) {
      this.load().then();
    }
  }

  selectAllAccounts(selectAll: boolean) {
    if (selectAll) {
      this.filterSpkAccount.set(this.spkAccounts().map(a => a.id));
      this.filterCreditCardAccount.set(this.creditCardAccounts().map(a => a.id));
      this.filterPaypalAccount.set(this.paypalAccounts().map(a => a.id));
      this.filterKlarnaAccount.set(this.klarnaAccounts().map(a => a.id));
      this.filterQontoAccount.set(this.qontoAccounts().map(a => a.id));
      this.filterCashRegisterAccount.set(this.cashRegisterAccounts().map(a => a.id));
      this.filterDepositAccount.set(this.depositAccounts().map(a => a.id));
    } else {
      this.filterSpkAccount.set([]);
      this.filterPaypalAccount.set([]);
      this.filterQontoAccount.set([]);
      this.filterCreditCardAccount.set([]);
      this.filterCashRegisterAccount.set([]);
      this.filterKlarnaAccount.set([]);
      this.filterDepositAccount.set([]);
    }
  }

  reloadCLicked() {
    this.load();
  }

  filterCompanyChanged() {
    this.load();
  }


  private async showInvoiceClicked(data: NxtBankTransaction) {
    if (data.assignedDocuments.length > 0) {
      const documents = await this.socketService.getBankDocumentsByIds(data.assignedDocuments.map(d => d.id));
      const buttons: { text: string, value: string }[] = [];
      for (const d of data.assignedDocuments) {
        const document = documents.find(doc => doc.id === d.id);
        if (document) {
          buttons.push({text: document.documentDate.dateFormat('dd.MM.yyyy') + '\n' + d.value.toMoneyString() + '\n' + document.documentNumber, value: document.id});
        }
      }
      let documentId = '';
      if (buttons.length === 1) {
        documentId = buttons[0].value;
      } else {
        const result = await this.dialogService.showButtons('Rechnungen', {buttons, showCancelButton: true});
        if (result) {
          documentId = result.value;
        }
      }
      if (documentId) {
        const dialog = this.dialogService.showComponentFull(BankDocumentDetailsComponent);
        dialog.componentInstance.load(documentId).then();
        if (await firstValueFrom(dialog.afterClosed())) {
          this.load().then();
        }
      }
    }
  }


  async reImportAllClicked() {
    const buttonResult = await this.dialogService.showButtons('Nur Unbekannte?', {
      buttons: [
        {text: 'ALLE', value: 'ALL'},
        {text: 'FILTER', value: 'FILTER'},
      ],
    });
    this.dialogService.showLoading('NXT Import läuft...');
    let ids: string[] = this.transactions().map(t => t.id);
    if (buttonResult.value === 'FILTER') {
      if (this.quickFilterText().length > 0) {
        ids = [];
        this.datagrid.api.forEachNodeAfterFilter((node) => {
          ids.push(node.data.id);
        });
      } else {
        ids = this.transactionsFiltered().map(t => t.id);
      }
      this.dialogService.showLoading(ids.length + ' werden neu importiert');
      await this.socketService.runBankTransactionImportByIds(ids);
      this.dialogService.hideLoading();
      this.load().then();
    }
  }

  async run(id?: string) {
    const result = await this.dialogService.showButtons('Aktion', {
      showCancelButton: true,
      buttons: [
        {text: NxtBankTransactionStatus._01_nxtAi, value: NxtBankTransactionStatus._01_nxtAi},
        {text: NxtBankTransactionStatus._03_delayed, value: NxtBankTransactionStatus._03_delayed},
        {text: NxtBankTransactionStatus._05_transferMatch, value: NxtBankTransactionStatus._05_transferMatch},
        {text: NxtBankTransactionStatus._07_documentMatch, value: NxtBankTransactionStatus._07_documentMatch},
      ],
    });
    if (!result) {
      return;
    }
    const ids: string[] = [];
    if (id) {
      ids.push(id);
    } else {
      this.datagrid.api.forEachNodeAfterFilter(node => ids.push(node.data.id));
    }
    const startFlow = await this.dialogService.showYesNo('Flow starten?');
    this.dialogService.showLoading(result.value + ' läuft...');
    await this.socketService.runBankTransferFlow(ids, result.value as NxtBankTransactionStatus, startFlow);
    this.dialogService.hideLoading();
    this.load();
  }

  runClicked() {
    this.run();
  }

  private runSingleClicked(id: string) {
    this.run(id);
  }

  public showBankDocumentsClicked(ev: MouseEvent) {
    if (ev.shiftKey || ev.button === 1) {
      window.open('/bank-documents', '_blank');
    } else if (ev.button === 0) {
      this.router.navigate(['/bank-documents']);
    }
  }

  async runDateExportClicked() {
    const buttons = [
      {text: '2024-12', value: '2024-12'},
      {text: '2025-01', value: '2025-01'},
      {text: '2025-02', value: '2025-02'},
    ];
    const result = await this.dialogService.showButtons('Datev-Export', {buttons, showCancelButton: true});
    if (result) {
      const monthString = result.value;
      const buttons2 = [
        {text: 'Export starten', value: 'export'},
        {text: 'Export wurde in DATEV eingelesen', value: 'exported'},
      ];
      const result2 = await this.dialogService.showButtons('Datev-Export', {buttons: buttons2, showCancelButton: true});
      if (result2) {
        const loadingText = result2.value === 'export' ? 'Datev-Export läuft...' : 'Dokumente werden markiert...';
        this.dialogService.showLoading(loadingText);
        if (result2.value === 'export') {
          let onlyTest = false;
          if (this.loginService.isJulian()) {
            onlyTest = await this.dialogService.showYesNo('Nur Test?');
          }
          await this.socketService.bank.startDatevExport(monthString, 'GBX GmbH', onlyTest);
        } else if (result2.value === 'exported') {
          await this.socketService.bank.setExportExported(monthString, 'GBX GmbH');
        }
        this.dialogService.hideLoading();
      }
    }
  }

  private restoreAllFiltersFromStorage() {
    const storageState = this.localStorageService.get('BankTransactionFilter', {
      dateStringFrom: this.dateFromTill().from,
      dateStringTill: this.dateFromTill().till,
      filterCompany: this.filterCompany(),
      // filterOnlyLastMonth: this.filterOnlyLastMonth(),
      filterNotFinished: this.filterNotFinished(),
      mode: this.filterMode(),
    });
    this.dateFromTill.set({from: storageState.dateStringFrom, till: storageState.dateStringTill});
    this.filterCompany.set(storageState.filterCompany);
    this.filterNotFinished.set(storageState.filterNotFinished);
    this.filterMode.set(storageState.mode);
    // this.filterOnlyLastMonth.set(storageState.filterOnlyLastMonth);
  }

  private saveAllFiltersToStorage() {
    this.localStorageService.set('BankTransactionFilter', {
      dateStringFrom: this.dateFromTill().from,
      dateStringTill: this.dateFromTill().till,
      filterCompany: this.filterCompany(),
      filterNotFinished: this.filterNotFinished(),
      // filterOnlyLastMonth: this.filterOnlyLastMonth(),
      mode: this.filterMode(),
    });
  }

  public filterModeChanged() {
    if (this.filterMode() === 'bank-cash') {
      this.filterNotFinished.set(true);
    }
    this.load().then();
  }

  async runBankAndPayPalImportClicked() {
    this.dialogService.showLoading('Bankimport läuft...');
    await this.socketService.bank.runBankAndPayPalImport();
    this.dialogService.hideLoading();
    this.load().then();
  }

  async allToMainClicked(type: 'qonto' | 'spk', company: 'GBX GmbH') {
    if (await this.dialogService.showYesNo('Alles aufs Hauptkonto schieben?')) {
      if (type === 'spk') {
        const username = await this.dialogService.showSelect('Push-Tan-User', [
          {text: 'Julian', value: 'Julian'},
          {text: 'Niklas', value: 'Niklas'},
          {text: 'Marcel', value: 'Marcel'},
        ], {value: this.loginService.getUsername()});
        if (username) {
          this.dialogService.showLoading('Transfer läuft...\nPush-Tan-App!');
          await this.socketService.bank.transferAllToMain(type, company, username);
          this.load().then();
          this.dialogService.hideLoading();
        }
      } else if (type === 'qonto') {
        this.dialogService.showLoading('Transfer läuft...');
        await this.socketService.bank.transferAllToMain(type, company, '');
        await TimeTools.sleep(2000);
        this.load().then();
        this.dialogService.hideLoading();
      }
    }
  }


  async createFinTsTransferClicked(account: NxtBankConfigAccountLive) {
    const dialog = this.dialogService.showComponentDialog(BankCreateTransferComponent);
    dialog.componentInstance.setData({
      ownCompany: account.company,
      accountId: account.id,
    });
    if (await firstValueFrom(dialog.afterClosed())) {
      this.load().then();
    }
  }

  async oposDocumentExportClicked() {
    this.dialogService.showLoading('OPOS-Export läuft...');
    await this.socketService.bank.oposDocumentExport('GBX GmbH');
    this.dialogService.hideLoading();
  }
}
