<button
    #button
    [class.button-small]="smallButton() || (screenService.state | async).isMobile"
    [class.no-wrap]="noWrap"
    [class.no-border]="noBorder"
    [ngStyle]="{fontSize: fontSize, height: height, padding: padding, borderRadius: borderRadius}"
    (click)="clicked($event)"
    mat-raised-button
    [disabled]="disabled()"
    [style]="{opacity: disabled() ? 0.2 : 1}"
>
  @if (iconLeft) {
    <mat-icon class="!mr-1">{{ iconLeft }}</mat-icon>
  }
  <ng-content>
  </ng-content>
  @if (iconRight) {
    <mat-icon style="order: 1" class="!ml-1 !mr-0">{{ iconRight }}</mat-icon>
  }
</button>


