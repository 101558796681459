<div class="flex flex-col">
  <div class="flex flex-row justify-end">
    <nxt-button-icon (click)="cancel()">close</nxt-button-icon>
  </div>
  <div class="flex flex-col outer-container">
    <div fxFlex="100%">
      <div class="title">
        <div #titleDiv>{{ title }}</div>
      </div>
      <div class="text">
        <div [innerHTML]="text | safeHtml"></div>
      </div>
    </div>
    <div
      *ngFor="let buttons of buttonRows"
      fxLayout="row"
      fxLayoutAlign="space-evenly">
      <nxt-time-button
        (buttonClick)="buttonClicked($event)"
        *ngFor="let button of buttons"
        [hourCounter]="hourCounter[$any(button).text ? button : button.toString()]"
        [hour]="$any(button).text ? button : button.toString()"
        [value]="currentValue"
        class="item"
        fxFlex="25%">

      </nxt-time-button>
    </div>
  </div>
</div>
