import {Pipe, PipeTransform} from '@angular/core';
import {DateTools} from '../common-browser/helpers/date.tools';

@Pipe({
    name: 'nxtTime',
    standalone: true
})
export class NxtTimePipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, short: boolean, longFormatIfNeeded = 'HH:mm:ss'): any {
    return DateTools.formatTime(value, short, longFormatIfNeeded);
  }
}
