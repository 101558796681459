import {DateTools} from './date.tools';
import * as _ from 'lodash';

type Many<T> = T | readonly T[];
type List<T> = ArrayLike<T>;
type NotVoid = unknown;
type ListIteratee<T> = ListIterator<T, NotVoid> | IterateeShorthand<T>;
type PropertyName = string | number | symbol;
type IterateeShorthand<T> = PropertyName | [PropertyName, any] | PartialShallow<T>;
type ListIterator<T, TResult> = (value: T, index: number, collection: List<T>) => TResult;
type PartialShallow<T> = {
  [P in keyof T]?: T[P] extends object ? object : T[P]
};

export class SortTools {


  public static arrayNumbers(reverse = false) {
    if (!reverse) {
      return (s1: number, s2: number) => s1 < s2 ? -1 : (s1 > s2 ? 1 : 0);
    }
    return (s1: number, s2: number) => s1 > s2 ? -1 : (s1 < s2 ? 1 : 0);
  }

  public static random() {
    return (a: any, b: any) => 0.5 - Math.random();
  }

  public static arrayParseNumbers(reverse = false) {
    if (!reverse) {
      return (s1: any, s2: any) => parseFloat(s1) < parseFloat(s2) ? -1 : (parseFloat(s1) > parseFloat(s2) ? 1 : 0);
    }
    return (s1: any, s2: any) => parseFloat(s1) > parseFloat(s2) ? -1 : (parseFloat(s1) < parseFloat(s2) ? 1 : 0);
  }

  public static arrayString(reverse = false) {
    if (!reverse) {
      return (s1: string, s2: string) => s1.localeCompare(s2);
    }
    return (s1: string, s2: string) => s2.localeCompare(s1);
  }

  public static sortNumber(key: string, reverse = false) {
    if (reverse) {
      return (o2: any, o1: any) => (o1[key] ? o1[key] : 0) - (o2[key] ? o2[key] : 0);
    } else {
      return (o1: any, o2: any) => (o1[key] ? o1[key] : 0) - (o2[key] ? o2[key] : 0);
    }
  }

  public static nxtSort<T>(array: any[], iteratees?: Many<ListIteratee<T>>, orders?: Many<boolean | 'asc' | 'desc'>) {
    return _.orderBy(array, iteratees, orders);
  }

  public static sortDate(key: string, reverse = false) {
    return (o2: any, o1: any) => {
      const o1Value = DateTools.parse(o1[key]);
      const o2Value = DateTools.parse(o2[key]);
      if (reverse) {
        return o1Value - o2Value;
      } else {
        return o2Value - o1Value;
      }
    };


    if (reverse) {

    } else {
      return (o1: any, o2: any) => (o1[key] ? o1[key] : 0) - (o2[key] ? o2[key] : 0);
    }
  }

  public static sortWithGetter<T>(array: T[], getter: (o: T) => string): T[] {
    return array
      .map(obj => ({obj, sortValue: getter(obj)}))
      .sort(SortTools.sortString('sortValue'))
      .map(({obj}) => obj);
  }

  public static sortString(key: string, reverse = false, options?: { last?: string, first?: string }) {
    return (o1: any, o2: any) => {
      if (typeof o1[key] !== 'string') {
        debugger;
        throw new Error('sortString fehlgeschlagen\ntypeof o1 = ' + typeof o1[key]);
      }
      if (typeof o2[key] !== 'string') {
        debugger;
        throw new Error('sortString fehlgeschlagen\ntypeof o2 = ' + typeof o2[key]);
      }
      if (options && options.last && o1[key] === options.last) {
        return 1;
      }
      if (options && options.last && o2[key] === options.last) {
        return -1;
      }

      if (options && options.first && o1[key] === options.first) {
        return -1;
      }
      if (options && options.first && o2[key] === options.first) {
        return 1;
      }
      if (reverse) {
        return (o2[key] ? o2[key] : '').localeCompare((o1[key] ? o1[key] : ''));
      } else {
        return (o1[key] ? o1[key] : '').localeCompare((o2[key] ? o2[key] : ''));
      }
    };
  }


  public static sort_Seconds(key: string) {
    return (o1: any, o2: any) => (o1[key]._seconds ? o1[key]._seconds : 0) - (o2[key]._seconds ? o2[key]._seconds : 0);
  }

  public static sortFirebaseTimestamp(key: string, reverse = false) {
    if (reverse) {
      return (o2: any, o1: any) => (o1[key].seconds ? o1[key].seconds : 0) - (o2[key].seconds ? o2[key].seconds : 0);
    } else {
      return (o1: any, o2: any) => (o1[key].seconds ? o1[key].seconds : 0) - (o2[key].seconds ? o2[key].seconds : 0);
    }
  }

  public static sort<T>(listToSort: T[], sortBy: string, reverse = false): T[] {
    try {
      return listToSort.sort((c1: any, c2: any) => {
        if (!c1[sortBy] || !c2[sortBy]) {
        }
        try {
          return this._compare(c1[sortBy], c2[sortBy], reverse);
        } catch (err1) {
          throw new Error('SortTools.sort failed\nerr1\n' + err1);
        }
      });
    } catch (err2) {
      throw new Error('SortTools.sort failed\nerr2\n' + err2);
    }
  }

  private static _compare(v1: any, v2: any, reverse: boolean) {
    try {
      if (typeof v1 === 'object') {
        if (reverse) {
          return JSON.stringify(v2).localeCompare(JSON.stringify(v1));
        }
        return JSON.stringify(v1).localeCompare(JSON.stringify(v2));
      } else {
        if (reverse) {
          return v2.toString().localeCompare(v1.toString());
        }
        return v1.toString().localeCompare(v2.toString());
      }
    } catch (err) {
      throw new Error('SortTools._compare failed\nerr\n' + err);
    }
  }

  public static init() {
  }
}

declare global {
  interface Array<T> {

    sortString(key: keyof T, reverse?: boolean): Array<T>;

    sortDate(key: keyof T, reverse?: boolean): Array<T>;

    sortNumber(key: keyof T, reverse?: boolean): Array<T>;

    nxtSort(key: keyof T | (keyof T)[], orders: Many<boolean | 'asc' | 'desc'>): Array<T>;
  }
}

Object.defineProperty(Array.prototype, 'sortString', {
  configurable: true,
  writable: true,
  value(this, key: string, reverse = false) {
    return this.sort(SortTools.sortString(key, reverse));
  },
});

Object.defineProperty(Array.prototype, 'sortDate', {
  configurable: true,
  writable: true,
  value(this, key: string, reverse = false) {
    return this.sort(SortTools.sortDate(key, reverse));
  },
});

Object.defineProperty(Array.prototype, 'sortNumber', {
  configurable: true,
  writable: true,
  value(this, key: string, reverse = false) {
    return this.sort(SortTools.sortNumber(key, reverse));
  },
});

Object.defineProperty(Array.prototype, 'nxtSort', {
  configurable: true,
  writable: true,
  value<T>(this, key: Many<ListIteratee<T>>, orders: Many<boolean | 'asc' | 'desc'>) {
    return _.orderBy(this, key, orders);
  },
});


