import {Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {NxtPayment} from '../common-interfaces/nxt.payment.interface';
import {BankTransactionTools} from '../common-browser/helpers/bank-transaction.tools';
import {DialogService} from './dialog.service';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  public constructor(
    private socketService: SocketService,
    private dialogService: DialogService
  ) {
  }

  /***
   * eventId: 494dfause0f0mu0vqqrgpfbqnk
   * calendarId: nxt-lvl.ink_enmuk98jq4e9abvaj26s3dfumo@group.calendar.google.com
   *
   */

  async showBankQr(payment: any) {
    const rawPayment: NxtPayment = payment.getRawValue();
    if (rawPayment.paymentBankTransaction && rawPayment.paymentValue) {
      let bankTransaction = await this.socketService.getPaymentPossibilityRecord(rawPayment.paymentBankTransaction.id);
      let counter = 0;
      while (bankTransaction.originalId) {
        bankTransaction = await this.socketService.getPaymentPossibilityRecord(bankTransaction.originalId);
        counter++;
        if (counter > 10) {
          throw Error('Original Bank-Überweisung nicht gefunden\nMehr als 10x gesplittet?');
        }
      }
      const name = BankTransactionTools.getSenderName(bankTransaction.rawObject);
      const iban = BankTransactionTools.getSenderIbanFromRaw(bankTransaction.rawObject);
      let text = 'Kautionsrückzahlung: ' + BankTransactionTools.getSenderText(bankTransaction.rawObject);
      if (text.length > 140) {
        text = text.substring(0, 140);
      }
      this.dialogService.showEPCQR(name, iban, rawPayment.paymentValue, text);
    }
  }
}
