<div fxLayout="column" class="h-100 w-full">
  <div fxLayout="row" fxLayoutAlign="center" class="p3">
    <div fxLayout="column">
      <div class="nxt-page-header">WhatsApp Texte</div>
      <div class="nxt-page-header" *ngIf="contact">an {{contact.fullName}}</div>
      <div class="nxt-page-header" *ngIf="mobile">an {{mobile}}</div>
    </div>
  </div>
  <div class="nxt-page-body h-100" fxLayout="column">
    <div fxLayout="row">

      <div fxFlex="300px">
        <nxt-input placeholder="Kürzel" (esc)="dialogRef.close()" [(value)]="quickFilter" (valueChange)="setFilter()"></nxt-input>
      </div>
      <nxt-radio direction="row" [(value)]="currentLang" [options]="langs" valueKey="value"></nxt-radio>
      <!--currentLang: {{currentLang}}-->
    </div>

    <div fxFlex fxLayout="column" class="pt-3 o-hidden h-100">
      <div fxLayout="column" class="o-auto h-100">
        <table class="nxt-table">
          <tr>
            <th>Kürzel</th>
            <th>Text</th>
            <th nxtPermission="WhatsAppFastAnswers_Edit">Bearbeiten</th>
          </tr>
          <tr *ngFor="let whatsappText of whatsappFastAnswersFiltered; let i = index" [ngClass]="{'selected-row': i === selectIndex}" [id]="'item_' + i">
            <td style="width: 30%; text-align: center" [innerHTML]="whatsappText.shortcut | highlight: quickFilter | safeHtml"></td>
            <td style="font-size: 75%">
              <div [innerHTML]="whatsappText.texts[currentLang] | highlight: quickFilter | safeHtml"></div>
            </td>
            <td style="width: 30px;" nxtPermission="WhatsAppFastAnswers_Edit">
              <div fxLayout="row" fxLayoutAlign="center">
                <nxt-button-icon (click)="edit(whatsappText)">edit</nxt-button-icon>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

  </div>
  <div fxLayout="row" fxLayoutAlign="center" class="p-2">
    <nxt-button (click)="dialogRef.close()">Abbrechen</nxt-button>
    <nxt-button (click)="createNew()" nxtPermission="WhatsAppFastAnswers_Edit">Neuer Text anlegen</nxt-button>
  </div>

</div>
