<div fxLayout="column" *ngIf="options">
  <div fxLayout="row" fxLayoutAlign="center" class="text-150 pb-3" *ngIf="options.title">{{options.title}}</div>
  <div *ngIf="options.message" style="margin-bottom:24px; margin-top:10px;" [innerHTML]="options.message | safeHtml">
  </div>

  <div *ngIf="isTime">
    <nxt-time-picker [minTime]="options.isTimeParams.minTime" [maxTime]="options.isTimeParams.maxTime" [nxtFormControl]="inputFormControl"></nxt-time-picker>
  </div>

  <div *ngIf="inputFormControl && !isTime">
    <nxt-input
      (enter)="okClicked()"
      [isMoney]="options.isMoney"
      [isNumber]="options.isNumber"
      [nxtFormControl]="inputFormControl"
      [placeholder]="options.placeholder"
      [isPercent]="options.isPercent"
      [selectOnInit]="options.selectPrompt"
      [type]="isPassword && !showPassword ? 'password' : 'text'"></nxt-input>
  </div>
  <div *ngIf="isPassword" class="pt-2 pb-4">
    <nxt-slide-toggle [(value)]="showPassword">Passwort anzeigen</nxt-slide-toggle>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-around">
    <nxt-button (click)="cancelClicked()">ABBRECHEN</nxt-button>
    <nxt-button *ngFor="let additionalButton of options.additionalButtons" (click)="additionalButtonClick(additionalButton)">{{additionalButton.text}}</nxt-button>
    <nxt-button #okButton (click)="okClicked()">{{okButtonText}}</nxt-button>
  </div>

</div>
<!--< button [ngStyle]="{backgroundColor: color.backgroundColor, color: color.fontColor}" style="margin: 10px;" mat-raised-button color="primary"
        (click)="close(color)">{{buttonText}}</button>-->




