@if (data(); as data) {
  <div id="data" class="flex w-full flex-row min-h-[4cm]" style="border:0px solid red">
    @if (data.logo) {
      <div class="logo-wrapper flex flex-col items-center max-w-[30%]">
        <img class="logo" [src]="data.logo.url">
        @if (data.logo.footer) {
          <div>{{ data.logo.footer }}</div>
        }
      </div>
    }
    <div class="flex flex-row justify-end flex-grow">
      <div class="flex flex-col items-end">
        <div class="text-[200%] pb-4">Rechnung</div>
        <table class="h-fit header-table">
          <tr>
            <td>Rechnung</td>
            <td class="text-right">{{ data.invoiceNumber }}</td>
          </tr>
          <tr>
            <td>Datum</td>
            <td class="text-right">{{ data.invoiceDate | nxtDate: 'dd.MM.yyyy' }}</td>
          </tr>
          <tr>
            <td>Seite</td>
            <td class="text-right">1/1</td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div class="flex flex-col py-4 min-h-[4cm]">
    <div class="text-[60%]" [innerHTML]="data.sender.oneLine | safeHtml"></div>
    <div [innerHTML]="data.receiver.text | safeHtml"></div>
  </div>

  <div class="flex flex-col w-full flex-grow items-end pt-[3cm]" style="border:0px solid blue">
    <table class="pos-table nxt-table-new">
      <tr>
        <td>Bezeichnung</td>
        @if (data.useVat) {
          <td class="text-right">USt %</td>
        }
        <td class="text-right leading-3">
          <div>Zwischensumme</div>
          @if (data.useVat) {
            <div class="text-[70%]">inkl. USt.</div>
          }
        </td>
      </tr>
      @for (pos of data.positions; track pos) {
        <tr>
          <td>
            <div class="flex flex-col">
              <div>{{ pos.description }}</div>
              @if (pos.description2) {
                <div class="text-[80%]" [innerHTML]="pos.description2 | safeHtml"></div>
              }
            </div>
          </td>
          @if (data.useVat) {
            <td class="text-right">{{ pos.vat }}%</td>
          }
          <td class="text-right">{{ pos.valueGross | money }}</td>
        </tr>
      }
    </table>
    <table class="w-1/2 nxt-table-new mt-2 text-[80%]">
      @if (data.useVat) {
        <tr>
          <td class="text-right">USt. %</td>
          <td class="text-right leading-3">
            <div class="text-right">Zwischensumme</div>
            <div class="text-[70%]">ohne USt.</div>
          </td>
          <td class="text-right">USt.</td>
        </tr>
        @for (vatLine of vatLines(); track vatLine) {
          <tr>
            <td class="text-right whitespace-nowrap">{{ vatLine.vat }}%</td>
            <td class="text-right whitespace-nowrap">{{ vatLine.valueNet | money }}</td>
            <!--<td>{{ vatLine.valueGross | money }}</td>-->
            <td class="text-right whitespace-nowrap">{{ vatLine.vatValue | money }}</td>
          </tr>
        }
      }
      <tr>
        <td class="text-[160%]">Gesamtpreis</td>
        <td colspan="2" class="text-end text-[160%]" style="padding-right: 12px">{{ totalGross() | money }}</td>
      </tr>
    </table>
  </div>

  <div class="flex flex-col justify-center text-[80%] text-center" style="border:0px solid red" [innerHTML]="data.footer | safeHtml">
  </div>
  @if (false) {
    <div class="flex flex-col justify-center text-[70%]" style="border:0px solid red">
      <div class="text-center">Die Tätowierung wurde in den Räumlichkeiten von NXT-LVL.INK durchgeführt.</div>
      <div class="text-center">NXT-LVL.INK trat hierbei ausschließlich als Vermittler auf.</div>
      <div class="text-center">der Vertrag über die Tätowierung besteht direkt zwischen dem Kunden und dem selbstständig tätigen Artist.</div>
    </div>
  }
}
