import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, effect, ElementRef, HostListener, inject, OnInit, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../services/socket/socket.service';
import {
  NxtCalendarEvent,
  NxtCalendarEventFile,
  NxtCalendarEventFileWithEventId,
  NxtEventQuery,
  NxtEventQueryProjection,
} from '../../common-interfaces/nxt.calendar-event.interface';
import justifiedLayout from 'justified-layout';
import {PhotoSwipeDirective} from '../../directives/photo-swipe/photo-swipe.directive';
import {NxtEventFilePipe} from '../../pipes/nxt-event-file.pipe';
import {VideoComponent} from '../video/video.component';
import {DialogService} from '../../services/dialog.service';
import {MatIcon} from '@angular/material/icon';
import {NxtPageFooterComponent} from '../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {NxtPageHeaderTitleComponent} from '../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageContentComponent} from '../nxt-page/nxt-page-content/nxt-page-content.component';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtPageHeaderComponent} from '../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageComponent} from '../nxt-page/nxt-page.component';
import {firstValueFrom} from 'rxjs';
import Slide from 'photoswipe/dist/types/slide/slide';
import {PhotoEditorComponent} from './photo-editor/photo-editor.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {EventFilterComponent} from '../event-filter/event-filter.component';
import {SpinnerComponent} from '../spinner/spinner.component';
import {ConfigService} from '../../services/config.service';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {LocalStorageService} from '../../services/local-storage.service';
import {NxtStudioPipe} from '../../pipes/nxt-studio-pipe';
import {ActivatedRoute} from '@angular/router';
import {JsonTools} from '../../common-browser/helpers/json.tools';
import {Clipboard} from '@angular/cdk/clipboard';
import {EventQueryService} from '../../services/event-query.service';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'nxt-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PhotoSwipeDirective, NxtEventFilePipe, MatIcon, NxtPageComponent, NxtPageHeaderComponent, NxtPageHeaderTitleComponent, NxtPageContentComponent, NxtPageFooterComponent, NxtButtonComponent, SpinnerComponent, NxtDatePipe, NxtStudioPipe, MatButtonToggle, MatButtonToggleGroup, FormsModule],
  standalone: true,
})
export class PhotosComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  private files: NxtCalendarEventFileWithEventId[] = [];


  constructor() {
    super();
    if (!this.configService.config.value.mustEventRating) {
      this.filter.set({...this.filter(), rating: undefined});
    }
    effect(() => {
      this.localStorageService.set('PhotosEventQuery', this.filter());
    });
  }

  @ViewChild('container') container: ElementRef;
  @ViewChild('scroller') scroller: ElementRef;


  /*** Signals ***/
  eventFilesWithEventId = signal<NxtCalendarEventFileWithEventId[]>([]);

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private configService = inject(ConfigService);
  private socketService = inject(SocketService);
  private clipboard = inject(Clipboard);
  private eventQueryService = inject(EventQueryService);
  private route = inject(ActivatedRoute);
  private localStorageService = inject(LocalStorageService);
  private dialogService = inject(DialogService);
  dialogRef = inject(MatDialogRef, {optional: true});
  filter = signal<NxtEventQuery>({
    rating: {min: 8, max: 10},
    hasEventFiles: true,
    eventDate: {
      type: 'today',
      min: Date.now().dateAddMonths(-1).dateFormatDate(),
      max: Date.now().dateFormatDate(),
    },
  });
  showSpinner = signal(false);
  events = signal<NxtCalendarEvent[]>([]);
  eventsById = computed<{ [eventId: string]: NxtCalendarEvent }>(() => {
    const result: { [eventId: string]: NxtCalendarEvent } = {};
    for (const event of this.events()) {
      result[event.id] = event;
    }
    return result;
  });

  showStudioInfo = computed(() => {
    return this.filter().studios && this.filter().studios.length > 0;
  });

  layoutItems: {
    file: NxtCalendarEventFileWithEventId,
    width: number,
    height: number,
    left: number,
    top: number,
  }[] = [];
  containerWidth = 0;
  galleryHeight = 0;


  filterText = computed(() => {
    return this.eventQueryService.getText(this.filter()).join(' · ');
  });
  filterMediaType = signal<('image' | 'video' | 'pdf') []>(['image', 'video']);

  async ngOnInit() {
    this.filter.set(this.localStorageService.get('PhotosEventQuery', this.filter()));
    if (this.route.snapshot.queryParams.q) {
      const filter = JsonTools.parse(atob(this.route.snapshot.queryParams.q));
      this.filter.set(filter);
    }
    // Laden der Daten
    this.load();
  }

  copyToClipboard() {
    const text = btoa(JsonTools.stringifyFormat(this.filter()));
    this.clipboard.copy(window.location.origin + window.location.pathname + '?q=' + text);
  }

  async load() {
    this.showSpinner.set(true);
    // let events = await this.socketService.getEventsBestPhoto();
    const projection: NxtEventQueryProjection = {
      id: 1,
      studio: 1,
      files: 1,
      paymentSum: 1,
      start: 1,
      end: 1,
      artist: 1,
    };
    this.filter.set({...this.filter(), projection});
    this.events.set((await this.socketService.eventQuery(this.filter())).sortNumber('start', true));
    let files = this.events().map((e) => this.getPhotosFromEvent(e))
      .filter(f => !!f)
      .flat()
      .sortNumber('fileCreatedAt', true);
    files = files.filter((f) => {
        try {
          const isTattooPhoto = f.type === 'image' && f.subType === 'tattooPhoto';
          const isTattooVideo = f.type === 'video' && f.subType === 'tattooVideo' && f.mimetype === 'video/mp4';
          return isTattooPhoto || isTattooVideo;
        } catch (err) {
          console.log(files);
          console.log(files);
          console.log(files);
          debugger;
        }
      },
    );
    if (files.length > 10000) {
      this.dialogService.showOk('Deine Suche hat ' + files.length + ' Fotos gefunden. Es werden nur die ersten 10.000 Fotos angezeigt.');
      files = files.slice(0, 10000);
    }
    this.files = files;
    this.showFiles();
    // this.eventFilesWithEventId.set(files);
    // this.calculateContainerWidth();
    // this.calculateLayout();
    this.showSpinner.set(false);
  }

  showFiles() {
    const filteredFiles = this.files.filter(f => {
      return this.filterMediaType().includes(f.type);
    });
    this.eventFilesWithEventId.set(filteredFiles);
    this.calculateContainerWidth();
    this.calculateLayout();
  }

  nxtOnDestroy() {
  }

  @HostListener('window:resize')
  onResize() {
    this.calculateContainerWidth();
    this.calculateLayout();
  }

  calculateContainerWidth() {
    this.containerWidth = this.container.nativeElement.clientWidth;
  }

  calculateLayout() {
    const input = this.eventFilesWithEventId().map((img) => {
      const ration = img.width / img.height;
      const width = 220 * ration;
      return {width, height: 220};
    });

    const geometry = justifiedLayout(input, {
      containerWidth: this.containerWidth,
      boxSpacing: 0,
      targetRowHeight: 200,
      containerPadding: 0,
    });

    this.layoutItems = geometry.boxes.map((box, index) => ({
      ...box,
      file: this.eventFilesWithEventId()[index],
    }));

    // this.layoutItems = this.layoutItems.slice(0, 100);

    // Setzen der Gesamthöhe der Galerie
    this.galleryHeight = geometry.containerHeight;
    this.cdRef.detectChanges();
  }

  videoClicked(eventFile: NxtCalendarEventFile) {
    const dialog = this.dialogService.showComponentFull(VideoComponent);
    dialog.componentInstance.loadDriveVideo(eventFile);
  }

  async editClicked(data: { slide: Slide, id: string }) {
    requestAnimationFrame(() => {
      const dialog = this.dialogService.showComponentFull(PhotoEditorComponent);
      dialog.componentInstance.fileId = data.id;
    });
  }

  async filterClicked() {
    const dialog = this.dialogService.showComponentDialog(EventFilterComponent);
    dialog.componentInstance.setFilter(this.filter());
    const filter = await firstValueFrom(dialog.afterClosed());
    if (filter) {
      this.filter.set(filter);
      this.filter.set({...this.filter(), hasEventFiles: true});
      this.load();
    }
  }

  private getPhotosFromEvent(e: NxtCalendarEvent): NxtCalendarEventFileWithEventId[] {
    return e.files.map((f) => ({...f, eventId: e.id}));
    /*for (const file of files) {
      file.tooltip = file.fileCreatedAt.dateFormat('dd.MM.yyyy HH:mm:ss');
    }*/
    // return files;
  }

  shareClicked(data: { slide: Slide; id: string }) {
    this.dialogService.showOk('Kommt die tage');
  }
}
