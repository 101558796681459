<nxt-form-field-wrapper [nxtFormControl]="nxtFormControl" [width]="width" [ngClass]="{'bottom-no-padding': noPadding || bottomNoPadding, 'no-padding': noPadding}">
  <mat-form-field class="mat-form-field-full-width">
    <mat-label>{{placeholder || nxtFormControl.name}}</mat-label>
    <input [readOnly]="readonly" matInput (click)="inputClick()" readonly [formControl]="$any(nxtFormControl)" [ngStyle]="{width: width}">
  </mat-form-field>
  <div *ngIf="showClearButton">
    <mat-icon (click)="clear()">clear</mat-icon>
  </div>
</nxt-form-field-wrapper>

