import {JsonTools} from './json.tools';

export class WhatsAppTools {

  public static toWhatsAppId(mobile: string) {
    return WhatsAppTools.parseWhatsAppId(mobile, true);
  }

  public static parseWhatsAppId(mobile: string, addSuffix = false) {
    if (typeof mobile !== 'string') {
      throw Error('parseWhatsAppId mobile is not a string\n' + JsonTools.stringify(mobile));
      return '';
    }
    mobile = mobile.replace(/\s/g, '');
    if (mobile.indexOf('0') === 0) {
      mobile = '49' + mobile.substr(1);
    }
    mobile = mobile.replace(/\+/g, '');
    if (addSuffix && !mobile.includes('@')) {
      mobile += '@c.us';
    }
    if (!addSuffix && mobile.includes('@')) {
      mobile = mobile.replace('@c.us', '');
    }
    return mobile;
  }

  public static toMobile(mobileOrWhatsAppId: string, withPlus = true) {
    let result = mobileOrWhatsAppId;
    if (result.includes('@')) {
      result = result.split('@')[0];
    }
    if (!result.startsWith('+')) {
      result = '+' + result;
    }
    if (!withPlus) {
      result = result.replace('+', '');
    }
    return result;
  }

  static getMobileFromTemplateParam(templateParams: any) {
    return '+' + WhatsAppTools.parseWhatsAppId(templateParams?.user || templateParams);
  }

  static messageGetFrom(message: any) {
    if (typeof message?._data?.from === 'string') {
      return message?._data?.from;
    }
    if (message?._data?.from?._serialized) {
      return message?._data?.from?._serialized;
    }
  }

  static messageGetTo(message: any) {
    if (typeof message?._data?.to === 'string') {
      return message?._data?.to;
    }
    if (message?._data?.to?._serialized) {
      return message?._data?.to?._serialized;
    }
  }
}
