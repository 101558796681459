import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SocketService} from '../../services/socket/socket.service';
import {NxtWebFormSubmit} from '../../common-interfaces/nxt.web-form-submit.interface';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {DatePipe, NgIf} from '@angular/common';

@Component({
    selector: 'nxt-web-form-submit-print',
    template: `
    <div class="w-full" fxLayout="column" *ngIf="webformSubmit">
      <br/>
      <br/>
      <br/>
      <table>
        <tr>
          <td>Datum</td>
          <td>{{webformSubmit.createdAt | date : 'HH:mm dd.MM.yyyy'}}</td>
        </tr>
        <tr>
          <td>Studio</td>
          <td>{{webformSubmit.studio}}</td>
        </tr>
        <tr>
          <td>Name</td>
          <td>{{webformSubmit.givenName}} {{webformSubmit.familyName}}</td>
        </tr>
        <tr>
          <td>Telefon</td>
          <td>{{webformSubmit.mobile}}</td>
        </tr>
        <tr>
          <td>Text</td>
          <td>{{webformSubmit.text}}</td>
        </tr>
      </table>
      <br/>

      <div fxLayoutAlign="center center" class="w-full" *ngIf="webformSubmit.imageUrl">
        <img style="width: 80%;" [src]="webformSubmit.imageUrl"/>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div fxLayoutAlign="center center">
        <div>Kunde wurde angerufen von: _________________________________</div>
      </div>
    </div>
  `,
    styleUrls: ['./web-form-submit-print.component.scss'],
    imports: [NgIf, FlexModule, DatePipe]
})
export class WebFormSubmitPrintComponent implements OnInit {

  webformSubmit: NxtWebFormSubmit;

  constructor(
    private activatedRoute: ActivatedRoute,
    private socketService: SocketService
  ) {

  }


  async ngOnInit() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id) {
      const webformSubmits = await this.socketService.getOpenWebFormSubmits();
      this.webformSubmit = webformSubmits.find(w => w.id.toString() === id);
      // this.webformSubmit.text = 'declarations-of-consent abcac agbfsdf tzse dröogsyd fglöoisdtj ghlsdifgjh sdkufghl sdlikufghb dk8e5h6bloisrtuvu w45o8ömlzmkvcuwe84o5sltuzvn58eswuzc t09ver556z9bum46059cuz m098456zu4vß5,zu cxe05zub4rß 0569zu mß';
    }
    setTimeout(() => {
      window.print();
    }, 2000);
  }
}
