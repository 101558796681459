@if (true) {
  <div class="flex flex-col gap-4">
    <div class="flex flex-row gap-3">
      <nxt-input [disabled]="true" class="w-[110px]" [nxtFormControl]="form.controls.abbreviation"></nxt-input>
      <nxt-input [nxtFormControl]="form.controls.givenName"></nxt-input>
      <nxt-input [nxtFormControl]="form.controls.familyName"></nxt-input>
    </div>
    <div class="flex flex-row justify-around gap-4">
      <nxt-date-picker-2 startYear="1990" [nxtFormControl]="form.controls.birthday"></nxt-date-picker-2>
      <nxt-date-picker-2 [nxtFormControl]="form.controls.startDateString"></nxt-date-picker-2>
      <nxt-date-picker-2 [nxtFormControl]="form.controls.endDateString"></nxt-date-picker-2>
    </div>
    <div class="flex flex-row">
      <div class="mr-3" (click)="setColorClicked()" style="width: 50px; height: 25px; border-radius: 4px" [style.background-color]="form.controls.color.value">
        @if (!form.controls.color.value) {
          Farbe wählen
        }
      </div>
      <nxt-autocomplete
        [options]="appUsers"
        [showClearIcon]="true"
        [nxtFormControl]="form.controls.appUserUid"
        [displayWith]="displayWithAppUser"
        [displayInOptionWith]="displayWithAppUser"
        [filterFields]="['name']"
      >
      </nxt-autocomplete>
      <!--<nxt-slide-toggle [nxtFormControl]="form.controls.shouldPlanWork"></nxt-slide-toggle>-->

    </div>

    <div class="mb-4 flex flex-row gap-4">
      <nxt-multi-from-till
        style="min-width: 500px"
        headerText="Stunden pro Woche"
        (formChanged)="formChanged()"
        [isNumber]="true"
        placeholder="Stunden pro Woche"
        [nxtFormControl]="form.controls.hoursPerWeek"
      />

      <nxt-multi-from-till
        style="min-width: 500px"
        headerText="Freie Tage"
        (formChanged)="formChanged()"
        [options]="[{text: 'Mo.', value: DayOfWeek.Monday}, {text: 'Di.', value: DayOfWeek.Tuesday}, {text: 'Mi.', value: DayOfWeek.Wednesday}, {text: 'Do.', value: DayOfWeek.Thursday}, {text: 'Fr.', value: DayOfWeek.Friday}, {text: 'Sa.', value: DayOfWeek.Saturday}]"
        [multiSelect]="true"
        [requiredValue]="false"
        placeholder="Freier Tag"
        [nxtFormControl]="form.controls.freeDays"
      />

      <nxt-multi-from-till
        style="min-width: 400px"
        headerText="Urlaubstage pro Jahr"
        (formChanged)="formChanged()"
        [isNumber]="true"
        placeholder="Urlaubstage pro Jahr"
        [nxtFormControl]="form.controls.holidayCount"
      />
    </div>
    <nxt-box class="flex w-full flex-row" headerText="Stempeln">
      <div class="flex w-1/2 flex-col items-center gap-4 pt-2">
        <nxt-slide-toggle [nxtFormControl]="form.controls.doTimeTrack"></nxt-slide-toggle>
        <nxt-slide-toggle [nxtFormControl]="form.controls.timeTrackOnlyFromStudio"></nxt-slide-toggle>
        <nxt-slide-toggle [nxtFormControl]="form.controls.seeWorkTime"></nxt-slide-toggle>
      </div>
      <div class="flex w-1/2 flex-col items-center">
        <nxt-slide-toggle class="mb-3" [nxtFormControl]="form.controls.earliestStartDependOnWorkplace"></nxt-slide-toggle>
        <nxt-time-picker
          width="300px"
          [nxtFormControl]="form.controls.earliestStartTime"
          [placeholder]="form.controls.earliestStartDependOnWorkplace.value ? 'Frühstens stempeln, wenn nicht geplant' : 'Frühstens stempeln'"
        ></nxt-time-picker>
        <div class="flex flex-row items-center">
          <nxt-time-picker width="300px" [nxtFormControl]="form.controls.timeTrackEndTimeIfMissing"></nxt-time-picker>
          <mat-icon class="cursor-pointer" (click)="showTimeTrackEndTimeIfMissingInfoClicked()">info</mat-icon>
        </div>
      </div>
    </nxt-box>


    <div class="mt-5 flex flex-row justify-around gap-3">
      <nxt-button (click)="closeClicked()">Abbrechen</nxt-button>
      <nxt-button (click)="saveClicked()">Speichern</nxt-button>
    </div>
  </div>
}
