import {ChangeDetectionStrategy, Component, EventEmitter, input, OnInit, Output} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {MatIcon} from '@angular/material/icon';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {AssignmentRefTypePipe} from '../../../pipes/assignment-ref-type.pipe';
import {NgForOf, NgIf} from '@angular/common';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {TattooTicketItem} from '../tattoo-ticket.component';


@Component({
    selector: 'nxt-tattoo-ticket-item',
    templateUrl: './tattoo-ticket-item.component.html',
    styleUrls: ['./tattoo-ticket-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIcon,
    MoneyPipe,
    AssignmentRefTypePipe,
    NgForOf,
    NgIf,
    NxtButtonComponent,
    NxtDatePipe
  ]
})
export class TattooTicketItemComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/
  item = input.required<TattooTicketItem>();
  fromEventId = input<string>();
  buyerName = input.required<string>();

  /*** Outputs ***/
  @Output() showEvent = new EventEmitter<string>();

  /*** Injections ***/

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }
}
