import {ChangeDetectionStrategy, Component, computed, input, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {TooltipCalendarEventComponent} from '../tooltips/tooltip-calendar-event/tooltip-calendar-event.component';
import {CalendarEvent} from 'angular-calendar';
import {MtxTooltip} from '@ng-matero/extensions/tooltip';
import {NgStyle} from '@angular/common';
import {EventMeta} from '../calendar.component';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {ArtistsTools} from '../../../common-browser/helpers/artists.tools';

@Component({
    selector: 'nxt-calendar-event-template',
    templateUrl: './calendar-event-template.component.html',
    styleUrls: ['./calendar-event-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TooltipCalendarEventComponent, MtxTooltip, NgStyle]
})

export class CalendarEventTemplateComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/
  event = input.required<CalendarEvent<EventMeta>>();
  artistOpenPiercing = computed(() => this.event().meta?.event?.artist === ArtistsTools.getArtistOpenPiercing().name);
  artistOpenTattoo = computed(() => this.event().meta?.event?.artist === ArtistsTools.getArtistOpenTattoo().name);

  /*** Outputs ***/

  /*** Signals ***/
  textColor = computed(() => {
    if (this.event().color?.primary) {
      return ColorTools.getLightOrDarkFontColorByBackground(this.event().color.primary);
    }
    return '';
  });
  tooltipClass = computed(() => {
    if (this.event().allDay) {
      return 'no-style mat-tooltip-width-content mat-tooltip-offset-right';
    }
    return 'no-style mat-tooltip-width-content';
  });


  /*** Injections ***/

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }
}
