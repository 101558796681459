import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtPageComponent} from '../../nxt-page/nxt-page.component';
import {NxtPageHeaderTitleComponent} from '../../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageHeaderComponent} from '../../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtMoneyPreTransaction, NxtMoneyTransaction} from '../../../common-interfaces/money-account.interface';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {DialogService} from '../../../services/dialog.service';
import {MoneyTransactionEditComponent} from '../money-transaction-edit/money-transaction-edit.component';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtPageFooterComponent} from '../../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {NxtPageContentComponent} from '../../nxt-page/nxt-page-content/nxt-page-content.component';
import {MatDialogRef} from '@angular/material/dialog';
import {SocketInterfaceResponse} from '../../../common-interfaces/socket/socket-interface';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {IconTools} from '../../../common-browser/helpers/icon.tools';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {MoneyPreTransactionAcceptComponent} from '../money-pre-transaction-accept/money-pre-transaction-accept.component';
import {firstValueFrom} from 'rxjs';
import {InputComponent} from '../../form-controls/input/input.component';
import {LoginService} from '../../../services/login.service';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {MobileTools} from '../../../common-browser/helpers/mobile.tools';
import {JsonTools} from '../../../common-browser/helpers/json.tools';

@Component({
  selector: 'nxt-money-transactions',
  templateUrl: './money-transactions.component.html',
  styleUrls: ['./money-transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NxtPageComponent,
    NxtPageHeaderTitleComponent,
    NxtPageHeaderComponent,
    NxtPageFooterComponent,
    NxtDatagridComponent,
    NxtPageContentComponent,
    NxtButtonComponent,
    MoneyPipe,
    InputComponent,
  ],
})

export class MoneyTransactionsComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/


  /*** Outputs ***/

  /*** Signals ***/
  moneyTransactions = signal<NxtMoneyTransaction[]>([]);
  moneyPreTransactions = signal<NxtMoneyPreTransaction[]>([]);
  accountName = signal('');
  accountBalance = signal(0);

  /*** Injections ***/
  private dialogService = inject(DialogService);
  dialogRef = inject(MatDialogRef, {optional: true});
  private socketService = inject(SocketService);
  private loginService = inject(LoginService);


  columnDefs: NxtColDef<NxtMoneyTransaction>[] = [
    {headerName: '', nxtFieldType: NxtFieldType.Icon, cellRenderer: () => IconTools.Material.Update, nxtOnCellClicked: (params) => this.reCalcTransactionClicked(params.data)},
    {
      headerName: '',
      nxtFieldType: NxtFieldType.Icon,
      cellRenderer: () => IconTools.Material.Delete,
      nxtOnCellClicked: (params) => this.deleteTransactionClicked(params.data),
      // hide: !this.loginService.isJulian(),
    },
    {headerName: 'Datum', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDateTimeWithSeconds},
    {headerName: 'Richtung', field: 'direction', nxtFieldType: NxtFieldType.Text, valueFormatter: params => params.value === 'in' ? 'Einnahme' : 'Ausgabe'},
    {headerName: 'Betrag', field: 'value', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Kassenstand', field: 'balanceAfter', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Region', field: 'regionId', valueFormatter: (params) => this.data.regions.find(r => r.id === params.value)?.name},
    {headerName: 'Kategorie', field: 'categoryId', valueFormatter: (params) => this.data.categories.find(r => r.id === params.value)?.name},
    {headerName: 'Erstellt', field: 'createdBy', nxtFieldType: NxtFieldType.Text},
    {
      headerName: 'Gegenseite', nxtFieldType: NxtFieldType.Text,
      valueGetter: (params) => {
        if (params.data.isTransfer) {
          return 'Kasse: ' + this.data.accountNames[params.data.peerId];
        }
        return params.data.peerId;
      },
    },
    {headerName: 'Info', field: 'info', nxtFieldType: NxtFieldType.Text},
  ];

  preTransactionColumnDefs: NxtColDef<NxtMoneyPreTransaction>[] = [
    {headerName: '', nxtFieldType: NxtFieldType.Icon, nxtIcon: IconTools.Material.Check, nxtOnCellClicked: params => this.acceptPreTransactionClicked(params.data)},
    {headerName: 'Datum', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDateTimeWithSeconds},
    {headerName: 'Richtung', field: 'transaction.direction', nxtFieldType: NxtFieldType.Text, valueFormatter: params => params.value === 'in' ? 'Einnahme' : 'Ausgabe'},
    {headerName: 'Betrag', field: 'transaction.value', nxtFieldType: NxtFieldType.Money},
    // {headerName: 'Gegenseite Info', field: 'peerId', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Region', field: 'transaction.regionId', valueFormatter: (params) => this.data.regions.find(r => r.id === params.value)?.name},
    {headerName: 'Kategorie', field: 'transaction.categoryId', valueFormatter: (params) => this.data.categories.find(r => r.id === params.value)?.name},
    {headerName: 'Erstellt', field: 'createdBy', nxtFieldType: NxtFieldType.Text},
    {
      headerName: 'Gegenseite', nxtFieldType: NxtFieldType.Text,
      valueGetter: (params) => {
        if (params.data.transaction.isTransfer) {
          return 'Kasse: ' + this.data.accountNames[params.data.transaction.peerId];
        }
        return params.data.transaction.peerId;
      },
    },
    {headerName: 'Info', field: 'info', nxtFieldType: NxtFieldType.Text},
  ];
  private moneyAccountId = '';
  private data: SocketInterfaceResponse.GetMoneyAccounts;
  quickFilterText = '';


  constructor() {
    super();
  }

  async ngOnInit() {
    this.pushSocketSubscription = this.socketService.subscribeNew('eventMoneyTransactionChanged', (data) => {
      if (data.record.accountId === this.moneyAccountId) {
        this.load(this.moneyAccountId);
      }
    });
    this.pushSocketSubscription = this.socketService.subscribeNew('eventMoneyAccountChanged', (data) => {
      if (data.record.id === this.moneyAccountId) {
        this.load(this.moneyAccountId);
      }
    });
  }

  nxtOnDestroy() {
  }

  newTransactionClicked() {
    const dialog = this.dialogService.showComponentDialog(MoneyTransactionEditComponent);
    dialog.componentInstance.new(this.moneyAccountId);
  }

  async load(moneyAccountId: string) {
    this.data = await this.socketService.getMoneyAccounts();
    this.moneyAccountId = moneyAccountId;
    const account = this.data.accounts.find(a => a.id === moneyAccountId);
    if (account) {
      this.accountName.set(account.name);
      this.accountBalance.set(account.balance);
    }

    const data = await this.socketService.getMoneyTransactionsWithPre(moneyAccountId);
    this.moneyTransactions.set(data.transactions.sortNumber('datetime', true));
    this.moneyPreTransactions.set(data.preTransactions.sortNumber('createdAt'));
  }

  private async deleteTransactionClicked(data: NxtMoneyTransaction) {
    if (!this.loginService.isJulian()) {

      if (this.data.categories.find(r => r.id === data.categoryId)?.isTransfer) {
        this.dialogService.showOk('Transfers kann du nicht löschen').then();
        return;
      }

      if (Date.now() - data.createdAt > DurationTools.DURATION_1MINUTE * 10) {
        this.dialogService.showOk('Du kannst nur Transaktionen löschen, die jünger als 10 Min. sind.').then();
        return;
      }
      if (data.createdBy !== this.loginService.getUsername()) {
        this.dialogService.showOk('Nur "' + data.createdBy + '" kann diese Transaktion löschen').then();
        return;
      }
    }
    if (await this.dialogService.showYesNo('Möchtest du die Transaktion wirklich löschen?')) {
      await this.socketService.deleteMoneyTransaction(data.id);
      this.socketService.sendWhatsAppMessage(MobileTools.Numbers.Julian, 'Transaktion gelöscht von ' + this.loginService.getUsername() + ': ' + data.value + ' ' + data.info + '\n' + JsonTools.stringifyFormat(data)).then();
    }
  }

  private reCalcTransactionClicked(data: NxtMoneyTransaction) {
    return this.socketService.reCalcAccountBalance(data.accountId, data.datetime);
  }

  private async acceptPreTransactionClicked(preTransaction: NxtMoneyPreTransaction) {
    const dialog = this.dialogService.showComponentDialog(MoneyPreTransactionAcceptComponent);
    dialog.componentInstance.load(preTransaction).then();
    await firstValueFrom(dialog.afterClosed());
    this.load(this.moneyAccountId);
  }
}
