import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {CalcArtistData} from '../../../common-browser/helpers/artists.tools';
import {MatIcon} from '@angular/material/icon';
import {NgIf, UpperCasePipe} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {PermissionDirective} from '../../../directives/permission.directive';


export enum ScrArtistContextMenuAction {
  EarlyPayout = 'earlyPayout',
  SetTimeWindow = 'setTimeWindow',
  Invoice = 'invoice',
  ResetEarlyArtistPayout = 'resetEarlyArtistPayout',
  SetCanEarlyPayout = 'setCanEarlyPayout',
}

@Component({
  selector: 'nxt-scr-artist-context-menu',
  templateUrl: './scr-artist-context-menu.component.html',
  styleUrls: ['./scr-artist-context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatMenuTrigger, MatMenu, FlexModule, NgIf, MatMenuItem, MatIcon, UpperCasePipe, PermissionDirective],
  standalone: true,
})

export class ScrArtistContextMenuComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  @ViewChild('artistGetMenuTriggerWrapper') artistGetMenuTriggerWrapper: ElementRef;
  @ViewChild('artistGetMenuTrigger') artistGetMenuTrigger: MatMenuTrigger;

  @Output() onMenuItemClicked = new EventEmitter<{ artist: CalcArtistData, menuItem: ScrArtistContextMenuAction, data?: any }>();
  artist: CalcArtistData;

  constructor() {
    super();
  }

  @HostListener('document:click')
  clickedOut() {
    this.artistGetMenuTrigger.closeMenu();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  public show(event: MouseEvent, artist: CalcArtistData) {
    event.stopPropagation();
    event.preventDefault();
    this.artist = artist;
    this.artistGetMenuTriggerWrapper.nativeElement.style.left = event.x + 'px';
    this.artistGetMenuTriggerWrapper.nativeElement.style.top = event.y + 'px';
    this.artistGetMenuTrigger.closeMenu();
    setTimeout(() => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            this.artistGetMenuTrigger.openMenu();
            requestAnimationFrame(() => {
              this.artistGetMenuTriggerWrapper.nativeElement.style.left = '0px';
              this.artistGetMenuTriggerWrapper.nativeElement.style.top = '0px';
            });
          });
        });
      });
    }, 100);
  }

  stopPropagation(ev: MouseEvent) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  earlyArtistPayout() {
    this.onMenuItemClicked.emit({artist: this.artist, menuItem: ScrArtistContextMenuAction.EarlyPayout});
  }

  setArtistTimeWindow() {
    this.onMenuItemClicked.emit({artist: this.artist, menuItem: ScrArtistContextMenuAction.SetTimeWindow});
  }

  invoice() {
    this.onMenuItemClicked.emit({artist: this.artist, menuItem: ScrArtistContextMenuAction.Invoice});
  }

  resetEarlyArtistPayoutClicked() {
    this.onMenuItemClicked.emit({artist: this.artist, menuItem: ScrArtistContextMenuAction.ResetEarlyArtistPayout});
  }

  setCanEarlyPayoutClicked(value: boolean) {
    this.onMenuItemClicked.emit({artist: this.artist, menuItem: ScrArtistContextMenuAction.SetCanEarlyPayout, data: value});
  }
}
