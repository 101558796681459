import {NxtUser} from '../../common-interfaces/nxt.user.interface';

export class UserTools {


  public static getEmptyUser(): NxtUser {
    return {
      id: '',
      permissions: [],
      username: '',
      cashReportCreator: false,
      // password: '',
      disableDailyLogin: false,
      startPage: '',
      backgroundColor: '',
      color: '',
      realName: '',
      workplaces: [],
      lastLogin: 0,
      lastLoginManual: 0,
      disabled: false,
      hidden: false,
      photoDeviceName: '',
      birthday: null,
      mobileBusiness: '',
      mobilePrivate: '',
      noPerson: false,
      canSafeMoneyGet: false
    };
  }
}
