import {ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../services/socket/socket.service';
import {CacheService} from '../../services/cache/cache.service';
import * as L from 'leaflet';
import {Layer} from 'leaflet';
import 'leaflet.markercluster';
import {SelectComponent} from '../form-controls/select/select.component';
import {SocketInterfaceResponse} from '../../common-interfaces/socket/socket-interface';


L.Icon.Default.mergeOptions({
  iconRetinaUrl: '../assets/map/marker-icon-2x.png',
  iconUrl: '../assets/map/marker-icon.png',
  shadowUrl: '../assets/map/marker-shadow.png',
});

@Component({
    selector: 'nxt-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        SelectComponent,
    ]
})

export class MapComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  private markers: L.MarkerClusterGroup;


  constructor() {

    super();

    effect(() => {
      const contacts = this.contacts().filter(c => this.selectedStudios().includes(c.studio));
      this.addCustomerClusters(contacts);
    });
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  private map!: L.Map;
  selectedStudios = signal(['ac']);
  contacts = signal<SocketInterfaceResponse.MapContact[]>([]);

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private cacheService = inject(CacheService);

  markerClusterData: Layer[];
  markerClusterOptions: L.MarkerClusterGroupOptions;

  // Fügt die Kundenstandorte als Cluster hinzu
  studios = [
    {value: 'ac', text: 'Aachen'},
    {value: 'du', text: 'Düsseldorf'},
    {value: 'ma', text: 'Mallorca'},
    {value: 'ffm', text: 'Frankfurt'},
    {value: 'nu', text: 'Nürnberg'},
    {value: 'ab', text: 'Aschaffenburg'},
  ];

  async ngOnInit() {
    this.initMap();
    this.contacts.set(await this.socketService.getContactsForMap());
  }

  nxtOnDestroy() {
  }

  private initMap(): void {
    this.map = L.map('map').setView([51.1657, 10.4515], 6); // Zentrum: Düsseldorf

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'NXT-LVL.INK',
      // detectRetina: true
    }).addTo(this.map);
  }

  private async addCustomerClusters(mapContacts: SocketInterfaceResponse.MapContact[]): Promise<void> {
    if (this.markers) {
      this.map.removeLayer(this.markers);
    }
    this.markers = window.L.markerClusterGroup();
    for (const contact of mapContacts) {
      // for (const event of ContactTools.getAllEventIds(contact)) {
      const marker = L.marker([contact.latitude, contact.longitude])
        .bindPopup(contact.postalCode + ' ' + contact.city);
      this.markers.addLayer(marker);
      // }
    }


    this.map.addLayer(this.markers);
  }
}
