import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {SocketService} from './socket/socket.service';
import {WorkSessionService} from './work-session.service';
import {DialogService} from './dialog.service';
import {LoginService} from './login.service';
import {NxtCashPayment} from '../common-interfaces/nxt.cash-payment.interface';
import {WithoutNxtDbFields} from '../common-interfaces/nxt.db-fields.interface';

@Injectable({
  providedIn: 'root',
})
export class CashPaymentService {

  constructor(
    private firestoreService: FirestoreService,
    private socketService: SocketService,
    private workSessionService: WorkSessionService,
    private dialogService: DialogService,
    private loginService: LoginService,
  ) {
  }

  async getById(cashPaymentId: string): Promise<NxtCashPayment | undefined> {
    return this.socketService.cashReportCashGetById(cashPaymentId);
    // return (await firstValueFrom(this.firestoreService.doc('cash-report-cash-' + cashType + '/' + id).get())).data() as any;
  }

  async upsert(cashPayment: WithoutNxtDbFields<NxtCashPayment>) {
    return this.socketService.cashReportCashUpsert(cashPayment);
  }

  async deleteById(id: string) {
    return this.socketService.cashReportCashDeleteById(id);
    // await this.firestoreService.doc('cash-report-cash-' + cashType + '/' + id).delete();
  }
}
