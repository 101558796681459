import {Component, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../services/socket/socket.service';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {InputComponent} from '../form-controls/input/input.component';
import {WhatsAppInstaResponses} from '../../common-interfaces/whats-app/nxt-whatsapp-message.interface';

@Component({
    selector: 'nxt-whatsapp-insta-responses',
    templateUrl: './whatsapp-insta-responses.component.html',
    styleUrls: ['./whatsapp-insta-responses.component.scss'],
    imports: [InputComponent, NxtDatagridComponent]
})

export class WhatsappInstaResponsesComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  whatsappInstaResponses: WhatsAppInstaResponses[] = [];
  columnDefs: NxtColDef[] = [
    {headerName: 'Name', field: 'description', maxWidth: 400},
    {headerName: 'Anfragen', field: 'responseCount', nxtFieldType: NxtFieldType.Number},
    {headerName: 'erste Anfrage', field: 'firstResponseAt', nxtFieldType: NxtFieldType.Date_germanDateTime},
    {headerName: 'letzte Anfrage', field: 'lastResponseAt', nxtFieldType: NxtFieldType.Date_germanDateTime},
    {headerName: 'instaUrl', field: 'id', nxtFieldType: NxtFieldType.Text},
    {
      headerName: 'Dauer in Tagen', valueGetter: (params) => {
        return (params.data.lastResponseAt - params.data.firstResponseAt) / DurationTools.DURATION_1DAY;
      }, nxtFieldType: NxtFieldType.Number
    },
  ];
  quickFilterText = '';

  constructor(
    private socketService: SocketService
  ) {
    super();
  }

  async ngOnInit() {
    this.whatsappInstaResponses = await this.socketService.getWhatsAppInstaResponses();
  }

  nxtOnDestroy() {

  }

}
