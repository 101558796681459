import {Injectable} from '@angular/core';
import {SocketService} from '../socket.service';
import {BankTransactionLabel} from '../../../common-interfaces/bank/bank-transaction.interface';
import {WithoutNxtDbFields} from '../../../common-interfaces/nxt.db-fields.interface';
import {IbPromotion} from '../../../common-interfaces/ink-back/ib.interface';

@Injectable({
  providedIn: 'root',
})
export class IbSubSocketService {
  /*** Injections ***/

  constructor(private socketService: SocketService) {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  private async emit(eventName: string, data?: any): Promise<any> {
    return this.socketService.emit('ib.' + eventName, data);
  }

  async upsertTransactionLabels(labels: BankTransactionLabel[]) {
    return this.emit('upsertTransactionLabels', labels);
  }

  public async getPromotions() {
    return this.emit('getPromotions');
  }

  public async createPromotion(promotion: WithoutNxtDbFields<IbPromotion>, arrayBuffer: ArrayBuffer) {
    return this.emit('createPromotion', {promotion, arrayBuffer});
  }

  public async updatePromotion(promotion: IbPromotion, arrayBuffer?: ArrayBuffer) {
    return this.emit('updatePromotion', {promotion, arrayBuffer});
  }

  public async getTestPromotionImage(promotion: WithoutNxtDbFields<IbPromotion>, arrayBuffer: ArrayBuffer): Promise<ArrayBuffer> {
    return this.emit('getTestPromotionImage', {promotion, arrayBuffer});
  }

  async getPromotion(promotionId: string): Promise<IbPromotion | null> {
    return this.emit('getPromotion', promotionId);
  }
}
