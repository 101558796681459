<nxt-page>
  <nxt-page-header>
    <nxt-page-header-title>QR-Code Generator</nxt-page-header-title>
    <div class="flex flex-col gap-2 items-end">
      <div class="flex flex-row items-center gap-2">
        <div>Landing-Page</div>
        <nxt-button (click)="value.set('https://nxt-lvl.ink/ac')">AC</nxt-button>
        <nxt-button (click)="value.set('https://nxt-lvl.ink/ffm')">FFM</nxt-button>
        <nxt-button (click)="value.set('https://nxt-lvl.ink/ab')">AB</nxt-button>
        <nxt-button (click)="value.set('https://nxt-lvl.ink/nu')">NU</nxt-button>
        <nxt-button (click)="value.set('https://nxt-lvl.ink/ma')">MA</nxt-button>
        <nxt-button (click)="value.set('https://nxt-lvl.ink/du')">DU</nxt-button>
        <nxt-button (click)="value.set('https://nxt-lvl.ink/do')">DO</nxt-button>
      </div>
      <div class="flex flex-row items-center gap-2">
        <div>Google bewerten</div>
        <nxt-button (click)="value.set('https://g.page/nxtlvlink/review')">AC</nxt-button>
        <nxt-button (click)="value.set('https://g.page/r/CejTsakHDwCbEBM/review')">FFM</nxt-button>
        <nxt-button (click)="value.set('https://g.page/r/CaDgPsXqtdyQEBM/review')">AB</nxt-button>
        <nxt-button (click)="value.set('https://g.page/r/CcroNdpCPdbREBM/review')">NU</nxt-button>
        <nxt-button (click)="value.set('https://g.page/r/CSJMxI_NffNoEBM/review')">MA</nxt-button>
        <nxt-button (click)="value.set('https://g.page/r/CaeGbL_TydgqEBM/review')">DU</nxt-button>
        <nxt-button (click)="value.set('https://g.page/r/CfElac3b3Io9EBM/review')">DO</nxt-button>
      </div>
      <div class="flex flex-row items-center gap-2">
        <div>WhatsApp</div>
        <nxt-button (click)="value.set('https://wa.me/4917645663664')">AC</nxt-button>
        <nxt-button (click)="value.set('https://wa.me/4915167344319')">FFM</nxt-button>
        <nxt-button (click)="value.set('https://wa.me/491746610045')">AB</nxt-button>
        <nxt-button (click)="value.set('https://wa.me/4917677994412')">NU</nxt-button>
        <nxt-button (click)="value.set('https://wa.me/34676359836')">MA</nxt-button>
        <nxt-button (click)="value.set('https://wa.me/4917643330770')">DU</nxt-button>
        <nxt-button (click)="value.set('https://wa.me/4915560407278')">DO</nxt-button>
      </div>
    </div>
  </nxt-page-header>
  <nxt-page-content>
    <nxt-input [(value)]="value"></nxt-input>
    <div class="flex h-full w-full items-center justify-center">
      <div class="flex w-fit flex-row flex-wrap items-center justify-center">
        <qrcode
          errorCorrectionLevel="M"
          [qrdata]="value()"
          [width]="400" colorDark="#000"
          colorLight="#00000000">
        </qrcode>
        <qrcode
          errorCorrectionLevel="M"
          [qrdata]="value()"
          [width]="400" colorDark="#fff"
          colorLight="#00000000">
        </qrcode>
        <qrcode
          errorCorrectionLevel="M"
          [qrdata]="value()"
          [width]="400" colorDark="#b7860f"
          colorLight="#00000000">
        </qrcode>
        <qrcode
          errorCorrectionLevel="M"
          [qrdata]="value()"
          [width]="400" colorDark="#3c3b3f"
          colorLight="#00000000">
        </qrcode>
      </div>
    </div>
  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>




