import {ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit, inject, signal, computed} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {JsonFormattedPipe} from '../../../../../pipes/json-formatted-pipe';
import {NxtBankTransaction} from '../../../../../common-interfaces/bank/bank-transaction.interface';
import {ObjectTools} from '../../../../../common-browser/helpers/object.tools';
import {NxtButtonIconComponent} from '../../../../../controls/button-icon/nxt-button-icon.component';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'nxt-bank-transaction-debug',
  templateUrl: './bank-transaction-debug.component.html',
  styleUrls: ['./bank-transaction-debug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    JsonFormattedPipe,
    NxtButtonIconComponent
  ],
  standalone: true
})

export class BankTransactionDebugComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  transaction = signal<NxtBankTransaction>(null);
  rawObj = signal<any>(null);
  transactionWithoutRawObj = signal<any>(null);

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  dialogRef = inject(MatDialogRef, {optional: true});


  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  public set(transaction: NxtBankTransaction) {
    this.transaction.set(transaction);
    this.rawObj.set(transaction.rawObj);
    const clone = ObjectTools.clone(transaction);
    delete clone.rawObj;
    this.transactionWithoutRawObj.set(clone);
  }
}
