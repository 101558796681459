import {Component, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {SocketService} from '../../../services/socket/socket.service';
import {DialogService, LoadingId} from '../../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {ConfigService} from '../../../services/config.service';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {ActivatedRoute} from '@angular/router';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {NgIf} from '@angular/common';
import {DateRangePickerComponent} from '../../../components/form-controls/date-range-picker/date-range-picker.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-discount-analysis',
    templateUrl: './discount-analysis.component.html',
    styleUrls: ['./discount-analysis.component.scss'],
    imports: [FlexModule, DateRangePickerComponent, NgIf, NxtButtonIconComponent, NxtDatagridComponent]
})
export class DiscountAnalysisComponent implements OnInit, OnDestroy {

  discountPromotions: any[];

  @ViewChild(NxtDatagridComponent) dataGrid: NxtDatagridComponent;
  columnDefs: NxtColDef[] = [
    {headerName: 'ID', field: 'discountPromotion.id', nxtFieldType: NxtFieldType.Text, hide: true},
    {
      headerName: 'Rabatt Aktion', field: 'discountPromotion.name', nxtFieldType: NxtFieldType.Text, valueFormatter: (params) => {
        if (!params.value) {
          return 'Keine Rabatt Aktion';
        }
        return params.value;
      }
    },
    {headerName: 'Wertigkeit', field: 'valence', nxtFieldType: NxtFieldType.Number, sort: 'desc'},
    {
      headerName: 'Studio Wertigkeit', field: 'valenceStudio', nxtFieldType: NxtFieldType.Number, valueFormatter: (params) => {
        return params.value + ' (' + params.data.valenceStudioPercentage + ' %)';
      }
    },
    {headerName: 'Anzahl Termine', field: 'eventCount', nxtFieldType: NxtFieldType.Number},
    // {headerName: 'Studio Zukunft Wertigkeit', field: 'valenceFutureStudio', nxtFieldType: NxtFieldType.Number},
    // {headerName: 'valenceStudioPercentage', field: 'valenceStudioPercentage', nxtFieldType: NxtFieldType.Number}
  ];
  quickFilterText: any;
  dateFromTill: { from: string, till: string };

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private configService: ConfigService,
    private activatedRoute: ActivatedRoute,
    @Optional() public dialogRef: MatDialogRef<DiscountAnalysisComponent>,
  ) {

  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  async load() {
    if (this.dateFromTill?.from && this.dateFromTill?.till) {
      this.dialogService.showLoading(LoadingId.Default, 'Lade Daten...');
      const result = await this.socketService.getDiscountAnalysis({startDateString: this.dateFromTill.from, endDateString: this.dateFromTill.till});
      this.discountPromotions = result;
      this.dialogService.hideLoading(LoadingId.Default);
    }
  }

  dateChanged() {
    this.load();
  }
}
