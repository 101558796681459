import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ImageViewerConfig} from '../../../components/image-viewer/image-viewer-config.model';
import {ImageViewerComponent} from '../../../components/image-viewer/image-viewer.component';
import {NxtButtonIconComponent} from '../../button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-dialog-image-viewer',
    templateUrl: './dialog-image-viewer.component.html',
    styleUrls: ['./dialog-image-viewer.component.scss'],
    imports: [FlexModule, NxtButtonIconComponent, ImageViewerComponent]
})
export class DialogImageViewerComponent implements OnInit {

  imageIndex: number;
  imagesSrc: string[];


  imageViewerConfig: ImageViewerConfig = {
    btnClass: 'image-viewer-button',
    wheelZoom: true,
    allowFullscreen: false,
    containerBackgroundColor: '#424242'
  };

  constructor(
    public dialogRef: MatDialogRef<DialogImageViewerComponent>,
  ) {
  }

  ngOnInit() {
  }

  cancelButtonClicked() {
    this.dialogRef.close();
  }

  delete() {
    this.dialogRef.close({deleteIndex: this.imageIndex});
  }
}
