<div fxLayout="column" class="w-full">
  <div class="text-120 pb-2">Preiseingabe</div>


  <div
    fxLayout="row"
    fxLayoutAlign="space-around center"
    class="py-2 o-hidden"
  >
    <div nxtPermission="IsJulian">
      <nxt-input
        #promoCodeInput
        [disableFocusOnInit]="true"
        [(value)]="inkBackCode"
        (valueChange)="inkBackCodeChanged()"
        [noPadding]="true"
        fxFlex
        placeholder="InkBack-Code">
      </nxt-input>
    </div>

    <div fxLayout="column"
         *ngIf="configService.config.value.studioRegion === 'MA' || configService.config.value.studioRegion === 'DU' || configService.config.value.studioRegion === 'DO'">
      <div class="flex flex-row items-center gap-3">
        <nxt-input
          #promoCodeInput
          [disableFocusOnInit]="true"
          [(value)]="promoCode"
          (valueChange)="promoCodeChanged()"
          [noPadding]="true"
          fxFlex
          placeholder="Promo-Code">
        </nxt-input>
        <nxt-button [smallButton]="true" (click)="startPromoCode()">Anwenden</nxt-button>
      </div>
      @if (promoOfferPromoterName || promoOfferCreatedAt) {
        <div class="flex flex-col mt-2">
          <div *ngIf="promoOfferPromoterName">Promoter: {{ promoOfferPromoterName }}</div>
          <div *ngIf="promoOfferCreatedAt">Erstellt: {{ promoOfferCreatedAt | nxtDate : 'dd.MM.yyyy HH:mm' }}&nbsp;{{ promoOfferCreatedAt | fromNowText }}</div>
        </div>
      }
      <br/><br/><br/>
      <div fxLayout="column" class="w-full" fxLayoutAlign="start center">
        <div class="pb-2">#Feste Promo-Codes</div>
        <div class="p-2" *ngFor="let discountPromotionStaticCode of discountPromotionsStaticCode">
          <nxt-button (click)="generateStaticPromoCode(discountPromotionStaticCode)" [smallButton]="true">{{ discountPromotionStaticCode.name }}</nxt-button>
        </div>
      </div>
    </div>
    <div class="flex flex-col overflow-scroll h-full">
      <div class="flex h-fit text-80">
        <nxt-radio #discountPromotionRadio tabIndex="-1"
                   [value]="discountPromotion"
                   textKey="name"
                   (valueChange)="setDiscountPromotion($event, false, true)"
                   [options]="discountPromotions">
        </nxt-radio>
      </div>
    </div>
  </div>

  <div class="flex flex-col flex-grow">
    <div fxLayout="row">
      <nxt-smooth-height class="w-full" [trigger]="discountPromotion?.id">
        <nxt-input
          #customDiscountInput
          [disableFocusOnInit]="true"
          [nxtHideHeight]="discountPromotion?.id !== 'custom'"
          [(value)]="discount"
          (keyup)="customDiscountChanged()"
          fxFlex
          [isPercent]="true"
          placeholder="Rabatt %"></nxt-input>
      </nxt-smooth-height>
    </div>

    <div fxLayout="row">
      <nxt-smooth-height class="w-full" [trigger]="discountPromotion?.id">
        <nxt-input
          #originalPriceInput
          (keyup)="originalPriceChanged()"
          [value]="originalPrice()"
          (valueChange)="originalPrice.set($event)"
          [disableFocusOnInit]="true"
          [nxtHideHeight]="discountPromotion?.id === 'none'"
          [isMoney]="true"
          placeholder="Original Preis"
          [selectOnFocus]="true"
        ></nxt-input>
      </nxt-smooth-height>
    </div>

    <div fxLayout="row" class="w-full" fxLayoutGap="15px">
      <nxt-input
        fxFlex
        #priceInput
        (keyup)="priceChanged(false)"
        [value]="price()"
        (valueChange)="price.set($event)"
        [isMoney]="true"
        [placeholder]="discountPromotion.priceFix ? 'Fixpreis' : 'Preis von'"
        [readonly]="!!discountPromotion.priceFixValue && false"
        (enter)="save()"
        [selectOnFocus]="true"

      ></nxt-input>
      <nxt-smooth-width [trigger]="discountPromotion.priceFix">
        <nxt-input
          class="price-till-input"
          *ngIf="!discountPromotion.priceFix"
          #priceTillInput
          [(value)]="priceTill"
          [isMoney]="true"
          placeholder="Preis bis"
          (enter)="save()"

        ></nxt-input>

      </nxt-smooth-width>
      <nxt-slide-toggle *ngIf="showSlider" [(value)]="discountPromotion.priceFix">Fix-Preis</nxt-slide-toggle>

      <nxt-smooth-width [trigger]="showRoundPrice">
        <nxt-button *ngIf="showRoundPrice" (click)="roundPrice()">Preis runden</nxt-button>
      </nxt-smooth-width>
    </div>

    <!--<nxt-slide-toggle>Fixpreis</nxt-slide-toggle>-->


    <div fxLayout="row" class="py-4" fxLayoutGap="15px">
      <nxt-input
        [(value)]="artistPercentage"
        [bottomNoPadding]="true"
        fxFlex
        [isPercent]="true"
        placeholder="Artist %"
        [readonly]="true"
      ></nxt-input>
      <div fxLayout="column" fxLayoutAlign="center center" *ngIf="!artist || !artist.employed">
        <nxt-button-icon [smallHeight]="true" (click)="changeArtistPercentage(true)">add</nxt-button-icon>
        <nxt-button-icon [smallHeight]="true" (click)="changeArtistPercentage(false)">remove</nxt-button-icon>
      </div>

      <div class="flex flex-col">
        <div>
          <nxt-input
            [value]="artistGet()"
            [bottomNoPadding]="true"
            fxFlex
            [isMoney]="true"
            placeholder="Artist bekommt"
            [readonly]="true"
            (click)="setArtistGet()"
          ></nxt-input>
          <div fxLayout="column" fxLayoutAlign="center center" *ngIf="!artist || !artist.employed">
            <nxt-button-icon [smallHeight]="true" (click)="changeArtistMoney(true)">add</nxt-button-icon>
            <nxt-button-icon [smallHeight]="true" (click)="changeArtistMoney(false)">remove</nxt-button-icon>
          </div>
        </div>
        <div class="flex gap-1 flex-col">
          @if (artistGetButtonsLess().length > 0) {
            <div class="flex flex-row w-full justify-start gap-1">
              <mat-icon>arrow_downward</mat-icon>
              @for (artistGetButton of artistGetButtonsLess(); track artistGetButton) {
                <nxt-button class="artist-price-button" (click)="setArtistGetClicked(artistGetButton)" [smallButton]="true">{{ artistGetButton | money: false }}
                </nxt-button>
              }
            </div>
          }
          @if (artistGetButtonsMore().length > 0) {
            <div class="flex flex-row w-full justify-start gap-1">
              <mat-icon>arrow_upward</mat-icon>
              @for (artistGetButton of artistGetButtonsMore(); track artistGetButton) {
                <nxt-button class="artist-price-button" (click)="setArtistGetClicked(artistGetButton)" [smallButton]="true">{{ artistGetButton | money: false }}
                </nxt-button>
              }
            </div>
          }
        </div>
      </div>

    </div>

    <!--<nxt-slide-toggle [(value)]="takeArtistPercentage">Artist % übernehmen</nxt-slide-toggle>-->
  </div>
  <div class="pt-4 w-full" fxLayout="row" fxLayoutAlign="space-around">
    <nxt-button (click)="cancel()">Abbrechen</nxt-button>
    <nxt-button [disabled]="!isOk()" (click)="save()">Übernehmen</nxt-button>
  </div>
</div>
