<div class="nxt-page" fxLayout="column">
  <div nxtPermission="IsAc" *ngIf="showCam">
    <div style="width: 30%; position: absolute; right: 18px; top: 53px; overflow: hidden; border-radius: 8px">
      <div style="position:absolute; right: 0; z-index: 1; background-color: #313131cc; padding: 0 3px">{{ camTimestamp | nxtDate: 'dd.MM.yyyy HH:mm:ss' }}
      </div>
      <img style="transform: scale(1.5); transform-origin: bottom left;" [src]="camImgSrc"/>
      <div class="flex flex-row justify-end pt-1">
        <nxt-button [smallButton]="true" (click)="hideCamClicked()">Kamera ausblenden</nxt-button>
      </div>
    </div>
  </div>
  <div class="p-absolute" nxtPermission="IsJulian" fxLayout="column">
    <table class="nxt-table" style="font-size: 75%">
      <tr>
        <td>Gesamt Soll</td>
        <td class="right">{{ shouldBeforeDayFinishTotalValue | money }}</td>
        <td class="right">{{ shouldBeforeDayFinishTotalValue }}</td>
      </tr>
      <tr>
        <td>Gesamt Soll Normal</td>
        <td class="right">{{ shouldBeforeDayFinishValueWithoutSafe | money }}</td>
        <td class="right">{{ shouldBeforeDayFinishValueWithoutSafe }}</td>
      </tr>
      <tr>
        <td>Gesamt Soll Tresor</td>
        <td class="right">{{ (shouldBeforeDayFinishTotalValue - shouldBeforeDayFinishValueWithoutSafe) | money }}</td>
        <td class="right">{{ shouldBeforeDayFinishTotalValue }} - {{ shouldBeforeDayFinishValueWithoutSafe }}</td>
      </tr>
      <tr>
        <td>shouldBeforeDayFinishValue</td>
        <td class="right">{{ shouldBeforeDayFinishTotalValue | money }}</td>
      </tr>
      <tr>
        <td>shouldBeforeDayFinishValueWithoutSafe</td>
        <td class="right">{{ shouldBeforeDayFinishValueWithoutSafe | money }}</td>
      </tr>
    </table>
    <table class="nxt-table" *ngIf="payouts">
      <tr *ngFor="let payout of payouts">
        <td>{{ payout.id }}</td>
        <td>{{ payout.value | money }}</td>
      </tr>
    </table>

    <table class="nxt-table" *ngIf="!payouts && this.myService.cashRegisterView.payouts">
      <tr *ngFor="let payout of this.myService.cashRegisterView.payouts">
        <td>{{ payout.id }}</td>
        <td>{{ payout.value | money }}</td>
      </tr>
    </table>


    <!--<div (click)="test4()">test4</div>-->
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between" class="p-relative">
    <div fxFlex fxLayoutAlign="center">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="text-150">Abrechnung {{ myService.cashRegisterView.studio }}</div>
        <div>{{ myService.cashRegisterView.state | cashRegisterState }}</div>
      </div>
    </div>
    <nxt-button-icon class="dialog-close-button" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>
  <div *ngIf="showLoadingUntilNextChange" class="h-100" fxLayout="column" fxLayoutAlign="center center">
    <nxt-spinner></nxt-spinner>
    <div *ngIf="loadingText" class="pt-2">{{ loadingText }}</div>
  </div>
  <div fxLayout="column" class="scrollable h-100" *ngIf="!showLoadingUntilNextChange">
    <div fxFlex fxLayout="column" fxLayoutAlign="start center" class="pt-4">
      <div class="h-100" fxLayoutAlign="strech">
        <div fxLayout="column" fxLayoutAlign="center center" class="text-100">
          <div
            *ngIf="myService.cashRegisterView.errorMessage"
            [innerHTML]="myService.cashRegisterView.errorMessage | safeHtml"
            class="red text-150">
          </div>
          @if (showRestartCreateInvoicesButton()) {
            <div>
              <nxt-button (click)="restartCreateInvoicesClicked()">Rechnungen erneut erstellen</nxt-button>
            </div>
          }


          <ng-container
            *ngIf="myService.myLoginCashRegister.state === NxtWorkSessionCashRegisterState._3_CalcCashRegister1">
            <div>
              <nxt-button (click)="countCashRegisterBeforeStart(1)">1. Gesamte Kasse zählen</nxt-button>
            </div>
          </ng-container>

          <ng-container
            *ngIf="myService.myLoginCashRegister.state === NxtWorkSessionCashRegisterState._4_CalcCashRegister2">
            <div fxLayout="column" fxLayoutAlign="center center">
              <nxt-button [disabled]="true">1. Gesamte Kasse wurde gezählt</nxt-button>
              <nxt-button (click)="countCashRegisterBeforeStart(2)">2. Gesamte Kasse erneut zählen</nxt-button>
            </div>
          </ng-container>

          <ng-container
            *ngIf="myService.myLoginCashRegister.state === NxtWorkSessionCashRegisterState._5_CalcCashRegister3">
            <div fxLayout="column" fxLayoutAlign="center center">
              <nxt-button [disabled]="true">1. Gesamte Kasse wurde gezählt</nxt-button>
              <nxt-button [disabled]="true">2. Gesamte Kasse wurde gezählt</nxt-button>
              <nxt-button (click)="countCashRegisterBeforeStart(3)">3. Gesamte Kasse erneut zählen</nxt-button>
            </div>
          </ng-container>

          <ng-container *ngIf="myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._6_ChangeMoney">
            <div>Um fort zu fahren, musst du Geld wechseln</div>
            <div class="p-4">
              <nxt-button (click)="countCashRegisterChange()">Geld wechseln</nxt-button>
            </div>
          </ng-container>

          <ng-container
            *ngIf="myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._7_ArtistPayoutCanBook">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div>Buche die Artist-Auszahlungen</div>
              <div class="p-4">
                <nxt-button (click)="bookArtistPayouts()">Artist-Auszahlungen buchen</nxt-button>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._9_CreateInvoices || myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._10_PreparingPayouts">
            <div fxLayout="column" fxLayoutAlign="center center">
              <ng-container *ngIf="payouts && !myService.cashRegisterView.bankCashPayment">
                <div class="p-3">Benutze die Pfeil-Tasten ↑ ↓ auf der Tastatur</div>
                <table class="nxt-table">
                  <tr *ngFor="let payout of payouts; let i = index" [ngClass]="{'current-row': currentPayoutRow === i}">
                    <td>{{ (i + 1) }}</td>
                    <td>{{ payout.id }}</td>
                    <td class="right">{{ payout.value | money: false }}</td>
                    <td [innerHTML]="payout.moneyStack | moneyStack"></td>
                    <td *ngIf="payout.invoiceNumber" class="mouse-pointer" (click)="showInvoice(payout)">
                      {{ payout.invoiceNumber }}
                    </td>
                    <td *ngIf="!payout.invoiceNumber"></td>
                  </tr>
                </table>
              </ng-container>
              <table class="mt-3 nxt-table" [ngClass]="{'current-table': currentPayoutRow === payouts?.length}">
                <tr>
                  <td>Kasse vor den Umschlägen<br>(hast du gezählt)</td>
                  <td class="right">{{ moneyStackTotalValue | money }}</td>
                </tr>
                <tr>
                  <td>Summe aller Umschläge</td>
                  <td class="right">{{ payoutsTotalSumSafeIncluded | money }}</td>
                </tr>
                <tr *ngIf="myService.cashRegisterView.bankCashPayment">
                  <td>Bank</td>
                  <td class="right">- {{ myService.cashRegisterView.bankCashPayment.value | money }}</td>
                </tr>
                <ng-container *ngIf="!myService.cashRegisterView.bankCashPayment">
                  <tr>
                    <td>Rest</td>
                    <td class="right bold">{{ moneyStackTotalValue - payoutsTotalSumSafeIncluded | money }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">Wenn du jetzt nicht {{ moneyStackTotalValue - payoutsTotalSumSafeIncluded | money }}
                      hast, sind die Umschläge falsch
                      befüllt.
                    </td>
                  </tr>
                </ng-container>
                <tr *ngIf="myService.cashRegisterView.bankCashPayment">
                  <td>Rest</td>
                  <td class="right bold">
                    {{ moneyStackTotalValue - payoutsTotalSumSafeIncluded - myService.cashRegisterView.bankCashPayment.value | money }}
                  </td>
                </tr>
              </table>


              <div *ngIf="!myService.cashRegisterView.bankCashPayment" class="p-3">
                <nxt-button
                  (click)="bookBankClicked()">
                  Bank buchen
                </nxt-button>
              </div>
              <div class="p-4" *ngIf="myService.cashRegisterView.bankCashPayment">
                <nxt-button (click)="bookEndMoney()">Kassen-Endbestand zählen</nxt-button>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._8_ArtistPayoutBooking">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div>Zahle die Artists aus</div>
              <div class="p-4">
                <nxt-button [disabled]="true" (click)="countCashRegisterChange()">Geld wechseln</nxt-button>
              </div>
            </div>
          </ng-container>


          <ng-container
            *ngIf="myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._11_SetSafeMoneyDestination">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div>Wem hast du das Tresor-Geld in höhe von
                <strong>{{ myService.cashRegisterView.safeCashPayment.value | money }}</strong> gegeben?
              </div>
              <nxt-select
                [(value)]="myService.cashRegisterView.safeMoneyGet"
                [options]="users"
              >
              </nxt-select>
              <div fxLayout="row">
                <nxt-button [disabled]="!myService.cashRegisterView.safeMoneyGet" (click)="setSafeMoneyDestination()">
                  Weiter
                </nxt-button>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._12_InvoicesPrinting">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="p-3">Kassenbericht drucken</div>
              <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
                <div *ngIf="false">
                  <nxt-button (click)="printInvoicesClicked()">Alle Rechnungen drucken</nxt-button>
                  oder
                  <nxt-button (click)="showInvoicesClicked(true)">Alle Rechnungen anzeigen</nxt-button>
                </div>
                <div>
                  <nxt-button (click)="printDayFinishReportClicked()">Kassenbericht drucken</nxt-button>
                  oder
                  <nxt-button (click)="showDayFinishReportClicked(true)">Kassenbericht anzeigen</nxt-button>
                </div>
                <nxt-button (click)="printingFinishedClicked()">weiter</nxt-button>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._13_PayoutsRunning">
            <div class="p-3 text-120" nxtPermission="IsAc">❗DIE ABRECHNUNG IST NOCH NICHT FERTIG❗</div>
            <div class="p-3" nxtPermission="IsAc">1. Gehe nun wieder an die Theke und öffne von dort die Abrechnung.
            </div>
            <!--<div class="pb-3"><span nxtPermission="IsAc">2. </span>MACH EIN KÜCHEN-CHECH, ARTISTS BEKOMMEN NUR IHR GELD WENN DIE KÜCHE OK IST!</div>-->
            <div class="pb-3 text-150 red" style="text-align: center" nxtPermission="IsAc"><strong>Neu bei jedem
              Artist</strong><br/>Bevor du das Geld gibts:
              <br/>kontrolliere sein Arbeitsplatz und seine Arbeitsstation<br/>➞ ES MUSS ALLES SAUBER SEIN!
            </div>


            <div class="pb-3"><span nxtPermission="IsAc">4. </span>Übergebe nun die Umschläge</div>
            <div class="pt-2" fxLayout="column" fxLayoutAlign="center center">
              <table class="nxt-table" *ngIf="payouts">
                <tr *ngFor="let payout of payouts; let i = index">
                  <td>{{ payout.id }}</td>
                  <td class="right">{{ payout.value | money: false }}</td>
                  <td *ngIf="payout.invoiceNumber" class="mouse-pointer" (click)="showInvoice(payout)">
                    {{ payout.invoiceNumber }}
                  </td>
                  <td *ngIf="!payout.invoiceNumber"></td>
                  <td>
                    @if (useArtistConfirm()) {
                      <div class="flex flex-col items-center justify-center">
                        @if (payout.confirmState === 'pending') {
                          <div class="flex flex-row pt-3">
                            <nxt-spinner [size]="24"></nxt-spinner>
                            <div>&nbsp;{{ payout.id }} muss den Betrag bestätigen&nbsp;</div>
                            <nxt-spinner [size]="24"></nxt-spinner>
                          </div>
                          <nxt-button class="pt-3" (click)="startArtistConfirm(payout)">📲 Bestätigung erneut anfordern 📲</nxt-button>
                          <nxt-button class="pt-3" (click)="cancelArtistConfirm(payout)">❌ Bestätigung abbrechen ❌</nxt-button>
                        } @else if (payout.confirmState === 'confirmed') {
                          <div>✅ Betrag vom Artist bestätigt ✅</div>
                        } @else {
                          <div>❗Umschlag wurde nicht übergeben❗</div>
                          <nxt-button class="py-2" (click)="startArtistConfirm(payout)">📲 Bestätigung anfordern 📲</nxt-button>
                        }
                      </div>
                    } @else {
                      <div>
                        <nxt-slide-toggle [value]="payout.confirmState === 'confirmed'"
                                          (valueChange)="$event ? payout.confirmState = 'confirmed': payout.confirmState = 'none'">Umschlag übergeben
                        </nxt-slide-toggle>
                      </div>
                    }
                  </td>
                </tr>
              </table>

              <table class="mt-2 nxt-table" *ngIf="myService?.data?.postponedEvents.length > 0">
                <tr>
                  <td colspan="3" class="text-center">Verschobene Termine</td>
                </tr>
                <tr *ngFor="let postponedEvent of myService.data.postponedEvents">
                  <td>{{ postponedEvent.start | nxtDate: 'HH:mm' }}</td>
                  <td>{{ postponedEvent.customerObj.fullName }}</td>
                  <td>{{ postponedEvent.artist }}</td>
                </tr>
              </table>
              <div class="p-3">
                <nxt-button (click)="payoutsFinishedClicked()">WEITER</nxt-button>
              </div>
            </div>
          </ng-container>


          <ng-container
            *ngIf="myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._29_SideCashRegisterBookTransfers">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="p-3">Transfer buchen</div>
              <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
                <nxt-button (click)="bookSideCashRegisterTransfer()">Transfer
                  über {{ this.shouldBeforeDayFinishTotalValue - this.myService.cashRegisterView.missingValue | money }}
                  buchen
                </nxt-button>
              </div>
            </div>
          </ng-container>


          <ng-container
            *ngIf="myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._30_SideCashRegisterPrintDayFinishReport">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="p-3">Kassenbericht drucken</div>
              <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
                <div>
                  <nxt-button (click)="printDayFinishReportClicked()">Kassenbericht drucken</nxt-button>
                  oder
                  <nxt-button (click)="showDayFinishReportClicked(true)">Kassenbericht anzeigen</nxt-button>
                </div>
                <nxt-button (click)="printingFinishedClicked()">fertig</nxt-button>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._14_ClosingWorkSession">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
                <nxt-button *ngIf="!myService.cashRegisterView.checkListDone" (click)="showCheckListClicked()">
                  Checkliste öffnen
                </nxt-button>
                <nxt-button *ngIf="myService.cashRegisterView.checkListDone" (click)="closeDayFinishClicked()">
                  Abrechnung schließen
                </nxt-button>
                <nxt-button nxtPermission="IsJulian" (click)="showDayFinishReportClicked(false)">
                  Kassenbericht anzeigen (J)
                </nxt-button>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._99_Closed">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="p-3">Die Abrechnung ist abgeschlossen</div>
              <div class="p-3">Verbleib des Tresor-Umschlags: {{ myService.cashRegisterView.safeMoneyGet }}</div>
              <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
                <nxt-button (click)="closeClicked()">schließen</nxt-button>
                <nxt-button (click)="showDayFinishReportClicked(false)">Kassenbericht anzeigen</nxt-button>
                <nxt-button (click)="showInvoicesClicked(false)">Rechnungen anzeigen</nxt-button>
              </div>
              <div class="text-80">
                <table class="mt-2 nxt-table" *ngIf="myService?.data?.postponedEvents.length > 0">
                  <tr>
                    <td colspan="3" class="text-center">Termin-Auszahlung verschoben ({{ myService.data.postponedEvents.length }})</td>
                  </tr>
                  <tr *ngFor="let postponedEvent of myService.data.postponedEvents">
                    <td>{{ postponedEvent.start | nxtDate: 'HH:mm' }}</td>
                    <td>{{ postponedEvent.customerObj.fullName }}</td>
                    <td>{{ postponedEvent.artist }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
