import {EventEmitter, Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {NxtPermissionId} from '../common-interfaces/nxt.user.interface';
import {LoginService} from './login.service';
import {ConfigService} from './config.service';
import {DialogService} from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {

  userPermissions: { [permissionId: string]: boolean } = null;
  onPermissionUpdated = new EventEmitter<void>();

  constructor(
    private socketService: SocketService,
    private loginService: LoginService,
    private configService: ConfigService,
    private dialogService: DialogService,
  ) {
    this.registerListener();
  }

  public isAc() {
    return this.hasPermission(NxtPermissionId.IsAc);
  }

  public isJulian() {
    return this.hasPermission(NxtPermissionId.IsJulian);
  }

  public isNiklas() {
    return this.hasPermission(NxtPermissionId.IsNiklas);
  }

  public hasPermissionWithInfo(permission: NxtPermissionId) {
    if (!this.hasPermission(permission)) {
      this.dialogService.showOk('Leider hast du keine Berechtigungen dafür\n\n' + permission);
      return false;
    }
    return true;
  }

  public hasPermission(permission: NxtPermissionId) {
    const overwriteResult = this.overwritePermission(permission);
    if (overwriteResult) {
      return overwriteResult.result;
    }
    if (this.userPermissions === null) {
      return false;
    }
    if (permission === '') {
      return true;
    }
    return !!this.userPermissions[permission];
  }

  private registerListener() {
    setTimeout(() => {
      this.socketService.state.subscribe((params) => {
        if (params.authenticated) {
          this.reloadPermissions();
        }
      });

      this.socketService.currentUser$.subscribe((params) => {
        this.reloadPermissions();
      });
    }, 500);
  }


  private overwritePermission(permission: NxtPermissionId) {
    if (permission === NxtPermissionId.IsFranchise && ['FFM', 'AB', 'NU'].includes(this.configService.config.value.studioRegion)) {
      return {result: true};
    }
    if (permission === NxtPermissionId.IsNoFranchise && !['FFM', 'AB', 'NU'].includes(this.configService.config.value.studioRegion)) {
      return {result: true};
    }
    if (permission === NxtPermissionId.IsAc && ['AC', 'STAGING'].includes(this.configService.config.value.studioRegion)) {
      return {result: true};
    }
    if (permission === NxtPermissionId.IsDu && ['DU'].includes(this.configService.config.value.studioRegion)) {
      return {result: true};
    }
    if (permission === NxtPermissionId.IsMa && ['MA'].includes(this.configService.config.value.studioRegion)) {
      return {result: true};
    }
    if (permission === NxtPermissionId.IsNotAc && this.configService.config.value.studioRegion !== 'AC') {
      return {result: true};
    }
    if (permission === NxtPermissionId.IsJulian && this.loginService.isJulian()) {
      return {result: true};
    }
    if (permission === NxtPermissionId.IsNiklas && this.loginService.isNiklas()) {
      return {result: true};
    }
    if (permission === NxtPermissionId.IsCapone && this.loginService.isCapone()) {
      return {result: true};
    }
    if (permission === NxtPermissionId.IsReception && this.loginService.isReception()) {
      return {result: true};
    }
    if (permission === NxtPermissionId.IsBackoffice && this.loginService.isBackoffice()) {
      return {result: true};
    }
    /*if (permission === NxtPermissionId.SuperAdmin) {
      // return {result: this.loginService.isJulian() || this.loginService.isNiklas()};
      return {result: this.loginService.isNiklas()};
    }*/
    if (this.userPermissions && this.userPermissions[NxtPermissionId.DisableGlobal_]) {
      if ([
        NxtPermissionId.StudioCashReport_EditIncomingOutgoing_CanEdit_,
        NxtPermissionId.CalendarEventEdit_canEdit_,
        NxtPermissionId.StudioCashReport_Switch_,
      ].includes(permission)) {
        return {result: false};
      }
    }
  }

  private reloadPermissions() {
    this.userPermissions = {};
    if (this.socketService.currentUser$.value) {
      for (const userPermission of this.socketService.currentUser$.value.permissions) {
        this.userPermissions[userPermission.id] = userPermission.granted;
      }
      this.onPermissionUpdated.emit();
    }
  }

  async waitForPermissions() {
    return new Promise<void>((resolve, reject) => {
      if (this.userPermissions === null) {
        this.onPermissionUpdated.subscribe(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }
}
