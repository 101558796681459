<div class="tooltip flex flex-col justify-center items-center" [ngStyle]="{borderColor: artistColor}">
  <div class="artist" [ngStyle]="{backgroundColor: artistColor, color: color}">{{ artistSpot.artist }}</div>
  <div class="content">
    <div>{{ artistSpot.start | nxtDate : 'dd.MM.yyyy' }} - {{ artistSpot.end | nxtDate : 'dd.MM.yyyy' }}</div>
    <div class="text-80">{{ untilText() }}</div>
    @if (artistSpot.artistIsInApartment) {
      <div class="text-80">🏠 in der Wohnung</div>
    }
  </div>
</div>
