import {Pipe, PipeTransform} from '@angular/core';
import {NxtEmployeeEventType} from '../common-interfaces/nxt.employee.interface';

@Pipe({
  name: 'nxtEmployeeEventType',
  standalone: true,
})
export class EmployeeEventTypePipe implements PipeTransform {

  constructor() {
  }

  transform(value: NxtEmployeeEventType): any {
    switch (value) {
      case 'work':
        return 'Arbeit';
      case 'vacation':
        return 'Urlaub';
      case 'timeTrack':
        return 'Zeiterfassung';
      case 'notHere':
        return 'Frei';
    }
  }
}
