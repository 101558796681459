@if (eventFilesClipboard(); as eventFileClipboards) {
  <div fxLayout="column" fxLayoutAlign="center center" class="w-full h-100 o-hidden">
    <div class="text-120">{{ eventFileClipboards.length }} Fotos / Videos</div>
    <div fxLayout="row" fxLayoutGap="5px" class="w-full p-3 scrollable">
      @for (eventFileClipboard of eventFileClipboards; track eventFileClipboard.id) {
        <div class="relative flex flex-col items-center justify-center">
          <img style="max-width: none !important; height: 220px" [src]="eventFileClipboard.fileId | nxtEventFile: true"/>
          <nxt-checkbox
            [value]="photosSelected[eventFileClipboard.id]"
            (valueChange)="triggerPhoto(eventFileClipboard.id, $event)"
            style="position: absolute; left: 5px; top:5px; width: 28px; height: 28px; border-radius: 4px; background-color: #0000006b">
          </nxt-checkbox>
          <div class="p-2 no-wrap" fxLayoutAlign="center center" fxLayout="column" (click)="triggerPhoto(eventFileClipboard.id)"
               style="position: absolute;bottom: 0; background: rgba(0,0,0,0.5); border-radius: 4px 4px 0 0;">
            <div class="mouse-pointer">{{ eventFileClipboard.subType | nxtDriveFileSubType }}</div>
            <div class="mouse-pointer">{{ eventFileClipboard.createdAt | nxtDate: 'dd.MM.yyyy HH:mm' }}</div>
          </div>
        </div>
      }
    </div>
    <div class="flex flex-row items-center justify-center gap-1 p-3">
      <nxt-button (click)="dialogRef.close()">Abbrechen</nxt-button>
      <nxt-button *ngIf="photosSelectedCount === 1" (click)="save()">1 Foto mit diesem Termin verknüpfen</nxt-button>
      <nxt-button *ngIf="photosSelectedCount > 1" (click)="save()">{{ photosSelectedCount }} Fotos mit diesem Termin verknüpfen</nxt-button>
    </div>
    <!--<div *ngIf="photosSelectedCount > 0" class="pb-3">
      <nxt-button [smallButton]="true" *ngIf="photosSelectedCount === 1" (click)="delete()">1 Foto Löschen</nxt-button>
      <nxt-button [smallButton]="true" *ngIf="photosSelectedCount > 1" (click)="delete()">{{photosSelectedCount}} Fotos Löschen</nxt-button>
    </div>-->
  </div>
}
