export class PasswordTools {

    static getPasswordStrength(password: string): { ok: boolean, text: string, oneLine: string } {
        const result = {ok: false, text: '', oneLine: ''};
        const specialChars = ['!', '"', '§', '$', '%', '&', '/', '(', ')', '=', '?'];
        const hasUpperCaseRegex = new RegExp('^(?=.*[A-Z]).*$', 'g');
        const hasLowerCaseRegex = new RegExp('^(?=.*[a-z]).*$', 'g');
        const hasNumberRegex = new RegExp('^(?=.*[0-9]).*$', 'g');


        const hastSpecialChars = [...password].some(c => specialChars.includes(c));
        const hasUpperCase = hasUpperCaseRegex.test(password);
        const hasLowerCase = hasLowerCaseRegex.test(password);
        const hasNumber = hasNumberRegex.test(password);
        const hasEnoughLength = password.length >= 5;

        result.ok = hastSpecialChars && hasUpperCase && hasLowerCase && hasEnoughLength && hasNumber;
        if (!result.ok) {
            const missings: string[] = [];
            if (!hasEnoughLength) {
                missings.push('mind. 5 Zeichen');
            }
            if (!hastSpecialChars) {
                missings.push('ein Sonderzeichen');
            }
            if (!hasUpperCase) {
                missings.push('ein Großbuchstabe');
            }
            if (!hasLowerCase) {
                missings.push('ein Kleinbuchstabe');
            }
            if (!hasNumber) {
                missings.push('- eine Zahl');
            }
            result.text = 'Es fehlen:\n-' + missings.join('\n-');
            result.oneLine = missings.join(', ');
        }
        return result;
    }

}
