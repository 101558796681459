import {Component, OnInit} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {LoginService} from '../../services/login.service';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-brudi-tv-dashboard',
    templateUrl: './brudi-tv-dashboard.component.html',
    styleUrls: ['./brudi-tv-dashboard.component.scss'],
    imports: [FlexModule, NxtButtonComponent]
})
export class BrudiTvDashboardComponent implements OnInit {


  constructor(
    private socketService: SocketService,
    private loginService: LoginService
  ) {

  }


  ngOnInit() {
  }

  playClip(clip: string) {
    this.socketService.sendBrudiTvAction({action: 'play_video', data: clip});
  }
}
