import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {InputComponent} from '../form-controls/input/input.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {MatDialogRef} from '@angular/material/dialog';
import {SocketService} from '../../services/socket/socket.service';
import {NxtDailyNote} from '../../common-interfaces/daily-note.interface';
import {LoginService} from '../../services/login.service';
import {NgIf} from '@angular/common';

@Component({
    selector: 'nxt-daily-note-edit',
    templateUrl: './daily-note-edit.component.html',
    styleUrls: ['./daily-note-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NxtDatePipe,
        InputComponent,
        NxtButtonComponent,
        NgIf
    ]
})

export class DailyNoteEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  dailyNote = signal<NxtDailyNote>(null);

  text = '';

  /*** Injections ***/
  loginService = inject(LoginService);
  cdRef = inject(ChangeDetectorRef);
  dialogRef = inject(MatDialogRef<any>);
  socketService = inject(SocketService);

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  new(dateString: string) {
    this.dailyNote.set({
      id: '',
      dateString,
      date: dateString.dateParse(),
      title: '',
      createdBy: this.loginService.getUsername(),
      createdAt: Date.now(),
      updatedAt: Date.now(),
      updatedBy: this.loginService.getUsername()
    });
  }

  async load(id: string) {
    const dailyNote = await this.socketService.getDailyNote(id);
    this.dailyNote.set(dailyNote);
    this.text = dailyNote.title;
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  async saveClicked() {

    const dailyNote = this.dailyNote();
    dailyNote.title = this.text;
    await this.socketService.upsertDailyNote(dailyNote);
    this.dialogRef.close();
  }

  async deleteClicked() {
    await this.socketService.deleteDailyNote(this.dailyNote().id);
    this.dialogRef.close();
  }
}
