import {Component, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketInterfaceResponse} from '../../../../common-interfaces/socket/socket-interface';
import {SocketService} from '../../../../services/socket/socket.service';
import {SafeResourceUrlPipe} from '../../../../pipes/safe-resource-url.pipe';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf} from '@angular/common';

@Component({
    selector: 'nxt-price-builder-photo-viewer',
    templateUrl: './price-builder-photo-viewer.component.html',
    styleUrls: ['./price-builder-photo-viewer.component.scss'],
    imports: [NgIf, FlexModule, NgFor, NxtButtonComponent, SafeResourceUrlPipe]
})

export class PriceBuilderPhotoViewerComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  public photo: SocketInterfaceResponse.EventPhoto;
  public data: SocketInterfaceResponse.GetPriceBuilderRandomPrePhotos;

  fullImage: { base64: string, mimeType: string };
  public widthSteps: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 20, 25, 30];
  public heightSteps: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 20, 25, 30];
  width: number;
  height: number;


  constructor(
    private socketService: SocketService,
  ) {
    super();
  }

  async ngOnInit() {

    this.fullImage = await this.socketService.getGoogleDriveFile(this.photo.id);


    setTimeout(() => {
      // this.magnify('img', 4);
    }, 200);
  }

  nxtOnDestroy() {

  }

  public setWidth(step: number) {
    this.width = step;
  }

  public setHeight(step: number) {
    this.height = step;
  }
}
