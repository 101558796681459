<div class="wrapper">
  <div>
    <img src="assets/logo.svg"/>
  </div>
</div>

<div class="wrapper-text" (dblclick)="setFullScreen()">
  <div style="position:relative; left: 2%">
    <span #codeSpan>3BJHD32</span>
  </div>
</div>



