import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, input, OnInit, Output, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {FileDropDirective} from '../../directives/file-drop.directive';
import {NgStyle} from '@angular/common';

@Component({
    selector: 'nxt-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NxtButtonComponent,
        FileDropDirective,
        NgStyle
    ]
})

export class FileUploadComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  @ViewChild('uploadInput') uploadInput: ElementRef;

  /*** Inputs ***/
  message = input('Datei hier her ziehen');
  width = input(300);
  height = input(300);

  /*** Outputs ***/  @Output() onFileDropped = new EventEmitter<File[]>();

  /*** Signals ***/


  /*** Injections ***/

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  fileDropped(fileList: FileList) {
    const files: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList.item(i));
    }
    this.onFileDropped.emit(files);
    this.uploadInput.nativeElement.value = '';
  }

  selectFileClicked() {
    this.uploadInput.nativeElement.click();
  }

  inputChanged(event: Event) {
    this.fileDropped((event.target as any).files);
  }
}
