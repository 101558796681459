import {Component, computed, OnInit} from '@angular/core';
import {SocketService} from '../../../services/socket/socket.service';
import {TelegramTools} from '../../../common-browser/helpers/telegram.tools';
import {DialogService, LoadingId} from '../../../services/dialog.service';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {NxtNgConfig} from '../../../common-interfaces/nxt.ng-config.interface';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {NxtConfig} from '../../../common-interfaces/nxt.config.interface';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {DurationPipe} from '../../../pipes/duration.pipe';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {SettingsPaypalImportComponent} from './paypal-import/settings.paypal-import.component';
import {SettingsBankImportComponent} from './bank-import/settings.bank-import.component';
import {SettingsNgConfigStudioComponent} from './ng-config-studio/settings-ng-config-studio.component';
import {SettingsNgConfigInvoiceDataComponent} from './ng-config-invoice-data/settings-ng-config-invoice-data.component';
import {SettingsWhatsappComponent} from './config-whatsapp/settings-whatsapp.component';
import {InputComponent} from '../../form-controls/input/input.component';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {TimePickerComponent} from '../../form-controls/time-picker/time-picker.component';
import {NgFor, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {SettingsQontoComponent} from './qonto/settings.qonto.component';
import {LoginService} from '../../../services/login.service';

@Component({
  selector: 'nxt-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  imports: [FlexModule, NgIf, TimePickerComponent, SlideToggleComponent, InputComponent, SettingsWhatsappComponent, SettingsNgConfigInvoiceDataComponent, NgFor, SettingsBankImportComponent, SettingsPaypalImportComponent, NxtButtonIconComponent, NxtButtonComponent, DurationPipe, SettingsQontoComponent, SettingsNgConfigStudioComponent],
  standalone: true,
})
export class SettingsComponent implements OnInit {


  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private loginService: LoginService,
  ) {
  }

  public telegramGroups: { description: string, key: string, groupId: string }[] = [];
  public ngConfig: NxtNgConfig;
  public icloudSync: { appleId: string, password: string };
  public whatsApp: NxtConfig.WhatsApp;
  public printAllCashPaymentReceipts: boolean;
  public mustEventRating: boolean;
  public mustEventRatingMin: number;
  public mustContactStreet: number;
  public paypalData: { timer: number; configs: NxtConfig.PaypalImport[] };
  public bankData: { timer: number; config: NxtConfig.BankImport };
  public qontoData: { timer: number; config: NxtConfig.Qonto };
  public googleCalendar: { password: string };
  public sendArtistWorkTimeInfo: boolean;
  public serviceConfigs: any;

  disableChangeMustEventRating = computed(() => {
    return !this.loginService.isJulian();
  });

  ngOnInit() {
    this.load().then();
  }

  async load() {
    this.loadTelegramGroups().then();
    this.loadNgConfig().then();
    this.loadICloud().then();
    this.loadWhatsApp().then();
    this.loadBankImport().then();
    this.loadQonto().then();
    this.loadPaypalImport().then();
    this.loadGoogleCalendar().then();
    this.loadSendArtistWorkTimeInfo().then();
    this.loadPrintAllCashPaymentReceipts().then();
    this.loadMustEventRating().then();
    this.loadServiceConfigs().then();
  }

  async save() {
    if (!this.isValid()) {
      return;
    }


    this.dialogService.showLoading(LoadingId.SaveSettings, 'Daten werden gespeichert');
    await this.socketService.setGlobalConfig('TelegramGroups', this.getTelegramGroupsFromForm());
    this.ngConfig.invoiceData.dateOfCondition = DateTools.parseFormat(this.ngConfig.invoiceData.dateOfCondition, 'yyyy-MM-dd');
    this.dialogService.updateLoadingText('Daten werden gespeichert\nNgConfig');
    await this.socketService.setGlobalConfig('NgConfig', this.ngConfig);
    this.dialogService.updateLoadingText('Daten werden gespeichert\nBank Import');
    await this.saveBankImport();
    await this.saveQontoImport();
    this.dialogService.updateLoadingText('Daten werden gespeichert\nPayPal Import');
    await this.savePaypalImport();
    this.dialogService.updateLoadingText('Daten werden gespeichert\nGoogle-Kalender');
    await this.saveGoogleCalendar();
    await this.saveWhatsApp();
    await this.saveSendArtistWorkTimeInfo();
    await this.savePrintAllCashPaymentReceipts();
    await this.saveMustEventRating();
    await this.saveIcloudSync();
    await this.saveServiceConfigs();

    this.dialogService.hideLoading(LoadingId.SaveSettings);
    this.dialogService.showOk('Erfolgreich gespeichert').then();
  }

  async loadTelegramGroups() {
    const configGroups = await this.socketService.getGlobalConfig('TelegramGroups');
    this.telegramGroups = [];
    for (const key of Object.keys(configGroups)) {
      this.telegramGroups.push({key, groupId: configGroups[key], description: TelegramTools.getGroupDescription(key)});
    }
    this.telegramGroups = this.telegramGroups.sort(SortTools.sortString('description'));
  }

  getTelegramGroupsFromForm() {
    const configGroups: any = {};
    for (const t of this.telegramGroups) {
      configGroups[t.key] = t.groupId;
    }
    return configGroups;
  }

  private async loadNgConfig() {
    this.ngConfig = await this.socketService.getGlobalConfig('NgConfig');
  }

  private async loadICloud() {
    this.icloudSync = await this.socketService.getGlobalConfig('IcloudSync');
    if (!this.icloudSync) {
      this.icloudSync = {appleId: '', password: ''};
    }
  }

  private async loadWhatsApp() {
    this.whatsApp = await this.socketService.getGlobalConfig('WhatsApp');
  }

  private async loadServiceConfigs() {
    this.serviceConfigs = await this.socketService.getServerConfig('ServiceConfigs');
  }

  private async loadGoogleCalendar() {
    this.googleCalendar = await this.socketService.getGlobalConfig('GoogleCalendar');
    if (!this.googleCalendar) {
      this.googleCalendar = {password: ''};
    }
  }

  private async loadBankImport() {
    this.bankData = {
      timer: await this.socketService.getServerConfig('TimerManager_BankingTimer_intervalSeconds'),
      config: await this.socketService.getGlobalConfig('BankImport'),
    };

    if (!this.bankData.config) {
      this.bankData.config = {
        bankFintsURL: '',
        pin: '',
        BLZ: '',
        loginName: '',
        iban: '',
        bic: '',
        account: '',
        bankName: '',
      };
    }
  }

  private async loadQonto() {
    this.qontoData = {
      timer: await this.socketService.getServerConfig('TimerManager_QontoTimer_intervalSeconds'),
      config: await this.socketService.getGlobalConfig('Qonto'),
    };

    if (!this.qontoData.config) {
      this.qontoData.config = {
        iban: '',
        identifier: '',
        secret: '',
      };
    }
  }

  private async loadPaypalImport() {
    this.paypalData = {
      timer: await this.socketService.getServerConfig('TimerManager_PaypalTimer_intervalSeconds'),
      configs: await this.socketService.getGlobalConfig('PaypalImports'),
    };
    if (!this.paypalData.configs || this.paypalData.configs.length === 0) {
      this.paypalData.configs = [await this.socketService.getGlobalConfig('PaypalImport')];
    }
  }

  private async savePaypalImport() {
    await this.socketService.setGlobalConfig('PaypalImports', this.paypalData.configs);
    await this.socketService.setServerConfig({key: 'TimerManager_PaypalTimer_intervalSeconds', value: this.paypalData.timer});
  }

  private async saveBankImport() {
    await this.socketService.setGlobalConfig('BankImport', this.bankData.config);
    await this.socketService.setServerConfig({key: 'TimerManager_BankingTimer_intervalSeconds', value: this.bankData.timer});
  }

  private async saveQontoImport() {
    await this.socketService.setGlobalConfig('Qonto', this.qontoData.config);
    await this.socketService.setServerConfig({key: 'TimerManager_QontoTimer_intervalSeconds', value: this.qontoData.timer});
  }

  private async saveWhatsApp() {
    await this.socketService.setGlobalConfig('WhatsApp', this.whatsApp);
  }

  private async saveGoogleCalendar() {
    await this.socketService.setGlobalConfig('GoogleCalendar', this.googleCalendar);
  }

  public setStudioWorkStart(time: string) {
    this.ngConfig.studioWorkStart = DurationTools.parse(time);
  }

  public setStudioWorkEnd(time: string) {
    if (time === '0:00') {
      this.ngConfig.studioWorkEnd = DurationTools.DURATION_1DAY;
    } else {
      this.ngConfig.studioWorkEnd = DurationTools.parse(time);
    }
  }

  private async loadSendArtistWorkTimeInfo() {
    this.sendArtistWorkTimeInfo = await this.socketService.getServerConfig('SendArtistWorkTimeInfo');
  }

  private async loadPrintAllCashPaymentReceipts() {
    this.printAllCashPaymentReceipts = !!(await this.socketService.getGlobalConfig('PrintAllCashPaymentReceipts'));
  }

  private async loadMustEventRating() {
    this.mustEventRating = !!(await this.socketService.getGlobalConfig('MustEventRating'));
    this.mustEventRatingMin = await this.socketService.getGlobalConfig('MustEventRatingMin');
    this.mustContactStreet = await this.socketService.getGlobalConfig('MustContactStreet');
  }

  private async saveMustEventRating() {
    await this.socketService.setGlobalConfig('MustEventRating', this.mustEventRating);
    await this.socketService.setGlobalConfig('MustEventRatingMin', this.mustEventRatingMin);
    await this.socketService.setGlobalConfig('MustContactStreet', this.mustContactStreet);
  }

  private async saveIcloudSync() {
    await this.socketService.setGlobalConfig('IcloudSync', this.icloudSync);
  }

  private async savePrintAllCashPaymentReceipts() {
    await this.socketService.setGlobalConfig('PrintAllCashPaymentReceipts', this.printAllCashPaymentReceipts);
  }

  private async saveSendArtistWorkTimeInfo() {
    await this.socketService.setServerConfig({key: 'SendArtistWorkTimeInfo', value: !!this.sendArtistWorkTimeInfo});
  }

  private async saveServiceConfigs() {
    await this.socketService.setServerConfig({key: 'ServiceConfigs', value: this.serviceConfigs});
  }

  newPaypal() {
    this.paypalData.configs.push({
      email: '',
      clientId: '',
      secret: '',
      accountNumber: '',
    });
  }

  removePaypal(paypalConfig: NxtConfig.PaypalImport) {
    this.paypalData.configs = this.paypalData.configs.filter(c => c !== paypalConfig);
  }

  private isValid() {
    if (this.paypalData.configs.length > 0) {
      if (this.paypalData.configs.filter(c => c.use).length !== 1) {
        this.dialogService.showOk('Bitte genau einen PayPal-Account benutzen').then();
        return false;
      }
    }
    return true;
  }
}
