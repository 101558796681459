import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'assignmentRefType',
  standalone: true
})
export class AssignmentRefTypePipe implements PipeTransform {

  constructor() {
  }

  transform(value: string, direction?: 'in' | 'out'): string {
    let text = '';
    switch (value) {
      case 'event':
        text = 'Termin';
        break;
      case 'klarnaOrder':
        text = 'Klarna';
        break;
      default:
        return value;
    }
    if (direction === 'out') {
      text += '-Rückzahlung';
    } else if (direction === 'in') {
      text += '-Zahlung';
    }
    return text;
  }
}
