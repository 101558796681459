import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BodyPutService} from '../services/body-put.service';

@Pipe({
    name: 'bodyPutText',
    standalone: true
})
export class BodyPutTextPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer,
    private bodyPutService: BodyPutService) {
  }

  transform(value: any, lang ?: string): string {
    if (lang === 'en') {
      return this.bodyPutService.getEnPath(value);
    }
    return this.bodyPutService.getGermanPath(value);
  }
}
