import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {TextareaComponent} from '../../components/form-controls/textarea/textarea.component';
import {JsonTools} from '../../common-browser/helpers/json.tools';

@Component({
    selector: 'nxt-font-extractor',
    templateUrl: './font-extractor.component.html',
    styleUrls: ['./font-extractor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NxtButtonComponent,
        TextareaComponent,
    ]
})

export class FontExtractorComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor() {
    super();
  }

  @ViewChild(HTMLIFrameElement) iframe: HTMLIFrameElement;

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);

  text = signal('');

  ngOnInit() {
    this.text.set(`
    [
  "<link rel=\\"preconnect\\" href=\\"https://fonts.googleapis.com\\">\\n<link rel=\\"preconnect\\" href=\\"https://fonts.gstatic.com\\" crossorigin>\\n<link href=\\"https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&family=Macondo&family=Mrs+Saint+Delafield&family=Playwrite+CL:wght@100..400&family=Playwrite+CZ:wght@100..400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap\\" rel=\\"stylesheet\\">\\ncontent_copy\\nCopy code",
  ".mrs-saint-delafield-regular {\\n  font-family: \\"Mrs Saint Delafield\\", cursive;\\n  font-weight: 400;\\n  font-style: normal;\\n}\\n\\ncontent_copy\\nCopy code",
  "// <uniquifier>: Use a unique and descriptive class name\\n// <weight>: Use a value from 100 to 400\\n\\n.playwrite-cl-<uniquifier> {\\n  font-family: \\"Playwrite CL\\", cursive;\\n  font-optical-sizing: auto;\\n  font-weight: <weight>;\\n  font-style: normal;\\n}\\ncontent_copy\\nCopy code",
  "// <uniquifier>: Use a unique and descriptive class name\\n// <weight>: Use a value from 400 to 700\\n\\n.edu-au-vic-wa-nt-hand-<uniquifier> {\\n  font-family: \\"Edu AU VIC WA NT Hand\\", cursive;\\n  font-optical-sizing: auto;\\n  font-weight: <weight>;\\n  font-style: normal;\\n}\\ncontent_copy\\nCopy code",
  "// <uniquifier>: Use a unique and descriptive class name\\n// <weight>: Use a value from 100 to 400\\n\\n.playwrite-cz-<uniquifier> {\\n  font-family: \\"Playwrite CZ\\", cursive;\\n  font-optical-sizing: auto;\\n  font-weight: <weight>;\\n  font-style: normal;\\n}\\ncontent_copy\\nCopy code",
  ".macondo-regular {\\n  font-family: \\"Macondo\\", cursive;\\n  font-weight: 400;\\n  font-style: normal;\\n}\\n\\ncontent_copy\\nCopy code",
  ".covered-by-your-grace-regular {\\n  font-family: \\"Covered By Your Grace\\", cursive;\\n  font-weight: 400;\\n  font-style: normal;\\n}\\n\\ncontent_copy\\nCopy code",
  ".roboto-thin {\\n  font-family: \\"Roboto\\", system-ui;\\n  font-weight: 100;\\n  font-style: normal;\\n}\\n\\n.roboto-light {\\n  font-family: \\"Roboto\\", system-ui;\\n  font-weight: 300;\\n  font-style: normal;\\n}\\n\\n.roboto-regular {\\n  font-family: \\"Roboto\\", system-ui;\\n  font-weight: 400;\\n  font-style: normal;\\n}\\n\\n.roboto-medium {\\n  font-family: \\"Roboto\\", system-ui;\\n  font-weight: 500;\\n  font-style: normal;\\n}\\n\\n.roboto-bold {\\n  font-family: \\"Roboto\\", system-ui;\\n  font-weight: 700;\\n  font-style: normal;\\n}\\n\\n.roboto-black {\\n  font-family: \\"Roboto\\", system-ui;\\n  font-weight: 900;\\n  font-style: normal;\\n}\\n\\n.roboto-thin-italic {\\n  font-family: \\"Roboto\\", system-ui;\\n  font-weight: 100;\\n  font-style: italic;\\n}\\n\\n.roboto-light-italic {\\n  font-family: \\"Roboto\\", system-ui;\\n  font-weight: 300;\\n  font-style: italic;\\n}\\n\\n.roboto-regular-italic {\\n  font-family: \\"Roboto\\", system-ui;\\n  font-weight: 400;\\n  font-style: italic;\\n}\\n\\n.roboto-medium-italic {\\n  font-family: \\"Roboto\\", system-ui;\\n  font-weight: 500;\\n  font-style: italic;\\n}\\n\\n.roboto-bold-italic {\\n  font-family: \\"Roboto\\", system-ui;\\n  font-weight: 700;\\n  font-style: italic;\\n}\\n\\n.roboto-black-italic {\\n  font-family: \\"Roboto\\", system-ui;\\n  font-weight: 900;\\n  font-style: italic;\\n}\\n\\ncontent_copy\\nCopy code"
]`);
    return;
    const hrefs = this.getHrefs('<link rel="preconnect" href="https://fonts.googleapis.com">\n' +
      '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>\n' +
      '<link href="https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&family=Macondo&family=Mrs+Saint+Delafield&family=Playwrite+CL:wght@100..400&family=Playwrite+CZ:wght@100..400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">\n' +
      'content_copy\n' +
      'Copy code');
    for (const href of hrefs) {
      this.splitHrefFonts(href);
    }
  }

  nxtOnDestroy() {
  }

  testClicked() {
    const scripts = this.iframe.contentDocument.querySelectorAll('gf-code-snippet');
    console.log(scripts);
    console.log(scripts);
    console.log(scripts);
  }

  startClicked() {
    const fonts: { name: string, href: string }[] = [];
    let scripts = JsonTools.parse(this.text());
    for (const script of scripts) {
      const hrefs = this.getHrefs(script);
      if (hrefs.length > 0) {
        for (const href of hrefs) {
          const hrefFonts = this.splitHrefFonts(href);
          for (const hrefFont of hrefFonts) {
            fonts.push({name: '', href: hrefFont});
          }
        }
      } else {
        console.log(script);
        console.log(script);
        console.log(script);
      }
    }
  }


  getHrefs(html: string) {
    // const regex = /<link[^>]+rel=["']stylesheet["'][^>]*href=["']([^"']+)["']/g;
    const regex = /<link href="(.*)" rel="stylesheet"/gi;

    let match: RegExpExecArray | null;
    const hrefs: string[] = [];

    while (true) {
      match = regex.exec(html);
      if (match === null) {
        break;
      }
      hrefs.push(match[1]);
    }
    return hrefs;
  }

  splitHrefFonts(href: string) {
    const fonts: string[] = [];
    const families = href.replaceAll('&display=swap', '').split('family=');
    for (const family of families) {
      console.log(family);
      console.log(family);
      console.log(family);
      const fontHrefPart = family.trimChar('&').trimChar('?');
      if (!fontHrefPart.startsWith('https')) {
        fonts.push(fontHrefPart);
      }
    }
    return fonts;
  }
}
