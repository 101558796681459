import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtArtistSpot} from '../../../common-interfaces/nxt.available-artist-day.interface';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {FormsModule} from '@angular/forms';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {MaterialColors} from '../../../common-browser/helpers/material-colors';
import {keys} from 'lodash';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtArtist} from '../../../common-interfaces/nxt.artist.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {ArtistSpotEditComponent} from '../../artist-spot-edit/artist-spot-edit.component';
import {DialogService} from '../../../services/dialog.service';
import {firstValueFrom} from 'rxjs';

@Component({
    selector: 'nxt-calendar-artist-view',
    templateUrl: './calendar-artist-view.component.html',
    styleUrls: ['./calendar-artist-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtButtonComponent, FormsModule, NxtDatePipe]
})

export class CalendarArtistViewComponent extends NxtComponent implements OnInit, NxtOnDestroy {


  constructor() {
    super();
  }

  currentIndex = computed(() => {
    for (const color of keys(MaterialColors.colors)) {
      for (const colorStep of keys(MaterialColors.colors[color])) {
        if (MaterialColors.colors[color][colorStep] === this.color()) {
          return color + colorStep;
        }
      }
    }
    return '';
  });

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  artistSpot = signal<NxtArtistSpot>(null);
  artistColors = signal<{ [artist: string]: string }>({});
  artist = signal<NxtArtist>(null);
  color = signal('#ab47bc');

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  socketService = inject(SocketService);
  dialogRef = inject(MatDialogRef<CalendarArtistViewComponent>);
  dialogService = inject(DialogService);

  colors = keys(MaterialColors.colors);
  colorSteps = ['200', '300', '400', '500', '600', '700', '800', '900'];

  protected readonly MaterialColors = MaterialColors;

  protected readonly ColorTools = ColorTools;

  ngOnInit() {
  }

  nxtOnDestroy() {
  }


  async load(artistSpot: NxtArtistSpot, artistColors: { [color: string]: string }) {
    this.artistColors.set(artistColors);
    this.artistSpot.set(artistSpot);
    this.artist.set(await this.socketService.getArtistByName(artistSpot.artist));
    this.color.set(this.artist().color);
  }

  setCurrentColor(color: string, colorStep: string) {
    this.color.set(MaterialColors.colors[color][colorStep]);
  }

  canceleClicked() {
    this.dialogRef.close();
  }

  saveClicked() {
    const artist = this.artist();
    artist.color = this.color();
    this.socketService.upsertArtist(artist);
    this.dialogRef.close();
  }

  async editArtistSpot() {
    const dialog = this.dialogService.showComponentDialog(ArtistSpotEditComponent);
    dialog.componentInstance.load(this.artistSpot().id);
    await firstValueFrom(dialog.afterClosed());
    this.dialogRef.close();
  }
}
