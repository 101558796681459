import {Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {DialogService} from './dialog.service';
import {NxtCalendarEventStencilSeen} from '../common-interfaces/nxt.calendar-event.interface';
import {BodyPutService} from './body-put.service';
import {LoginService} from './login.service';
import {EventTools} from '../common-browser/helpers/event.tools';

@Injectable({
  providedIn: 'root'
})
export class StencilService {
  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private bodyPutService: BodyPutService,
    private loginService: LoginService,
  ) {
  }

  public async showStencilSeenDialog(eventId: string): Promise<NxtCalendarEventStencilSeen> {
    const event = await this.socketService.getNxtCalendarEvent(eventId);

    if (EventTools.hastMissingBodyPuts(event)) {
      await this.dialogService.showOk('Die Körperstelle oder das Motiv ist noch nicht eingetragen, bitte trage erst die richtige Körperstelle oder das richtige Motiv ein', {buttonText: 'Termin öffnen'});
      this.dialogService.showEvent(event.id);
      return;
    }

    const text = this.bodyPutService.getTextFromEvent(event).join('\n');
    const title = event.customerObj.fullName + ' bei ' + event.artist;
    if (!event.stencilSeen.seen) {
      const result = await this.dialogService.showYesNoCancel(text + '\n\nHast du das Stencil gesehen?',
        {title, yesText: 'Gesehen\nInfo 📲 an Kunden', noText: 'Gesehen\nkeine Info an Kunden', cancelText: 'Zurück'});
      if (typeof result === 'boolean') {
        await this.socketService.updateCalendarEventProperty(event.id, {
          stencilSeen: {
            seen: true,
            seenBy: this.loginService.getUsername(),
            seenAt: Date.now(),
            workplace: this.loginService.getWorkplace(),
            studio: this.loginService.getStudio()
          }
        });
        if (result) {
          this.socketService.sendStencilSeenMessageToCustomer(event.id).then();
        }
        return {seen: true, seenBy: this.loginService.getUsername(), seenAt: Date.now(), studio: this.loginService.getStudio(), workplace: this.loginService.getWorkplace()};
      }
    } else {
      const messageText = text + '\n\n\n' + event.stencilSeen.seenAt.dateFormat('dd.MM.yyyy HH:mm') + ': Stencil von ' + event.stencilSeen.seenBy + ' gesehen';
      if (event.stencilSeen.seenBy === this.loginService.getUsername()) {
        if (!(await this.dialogService.showYesNo(messageText, {
          title,
          noText: 'Stencil doch nicht gesehen',
          yesText: 'OK'
        }))) {
          this.socketService.updateCalendarEventProperty(event.id, {stencilSeen: {seen: false, seenBy: '', seenAt: 0, workplace: '', studio: ''}}).then();
          return {seen: false, seenBy: '', seenAt: 0, workplace: '', studio: ''};
        }
      } else {
        await this.dialogService.showOk(messageText, {title});
      }
    }
  }

  public async showStencilRequestDialog(eventId: string) {
    const sendIt = await this.dialogService.showYesNo('Stencil per WhatsApp beim Artist anfordern', {yesText: 'Anfrage senden', noText: 'Abbrechen'});
    if (sendIt) {
      await this.socketService.sendArtistStencilRequest(eventId);
    }
  }
}
