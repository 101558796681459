import {Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {NanoIdTools} from '../common-browser/helpers/nano-id.tools';
import {LoginService} from './login.service';
import {DateTools} from '../common-browser/helpers/date.tools';

@Injectable({
  providedIn: 'root'
})
export class LabelPrintService {

  constructor(
    private socketService: SocketService,
    private loginService: LoginService
  ) {
  }

  printShisha(eventId: string) {
    this.socketService.printShisha(eventId);
  }

  printFreeWater() {
    this.socketService.printSmallText({
      text: 'Ein kostenloses Wasser im Brudi\'s\n\nDatum: ' + DateTools.formatNow('dd.MM.yyyy HH:mm') + '\n\nCode: ' + NanoIdTools.generateCustom(6, true),
      studio: this.loginService.getStudio(),
      orientation: 'standard',
      margin_top: 20,
      margin_bottom: 20
    });
  }

  printFreeShot(name: string) {
    this.socketService.printSmallText({
      text: 'Ein kostenloser Shot im Brudi\'s\n\nfür ' + name + '\n\nDatum: ' + DateTools.formatNow('dd.MM.yyyy HH:mm') + '\n\nCode: ' + NanoIdTools.generateCustom(6, true),
      studio: this.loginService.getStudio(),
      orientation: 'standard',
      margin_top: 20,
      margin_bottom: 20
    });
  }
}
