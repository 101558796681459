import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, inject, OnDestroy, OnInit, signal, ViewChild} from '@angular/core';
import {fabric} from 'fabric';
import {FormsModule} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {DriveTools} from '../../../common-browser-public/helpers/drive.tools';
import {Group, Object as FabricObject} from 'fabric/fabric-impl';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {MatIcon} from '@angular/material/icon';
import {NxtPageContentComponent} from '../../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {NxtPageComponent} from '../../nxt-page/nxt-page.component';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtRoundPipe} from '../../../pipes/nxt-round-pipe';
import {MatTooltip} from '@angular/material/tooltip';
import {SpinnerComponent} from '../../spinner/spinner.component';
import {ClickDirective} from '../../../directives/click.directive';
import {clone} from '../../../common-browser/helpers/object.tools';
import {DialogService} from '../../../services/dialog.service';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtDriveFile} from '../../../common-interfaces/drive-file.interface';
import {FlexModule} from 'ngx-flexible-layout';
import {MatSlider, MatSliderThumb} from '@angular/material/slider';
import {ConfigService} from '../../../services/config.service';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {MobileTools} from '../../../common-browser/helpers/mobile.tools';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {TimeTools} from '../../../common-browser/helpers/time.tools';

@Component({
  selector: 'nxt-photo-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, NxtButtonComponent, MatIcon, NxtPageComponent, NxtPageContentComponent, NxtPageFooterComponent, NxtRoundPipe, MatTooltip, SpinnerComponent, ClickDirective, FlexModule, MatSlider, MatSliderThumb, MatMenuItem, MatMenu, MatMenuTrigger, NxtButtonIconComponent],
  standalone: true,
})
export class PhotoEditorComponent implements OnInit, AfterViewInit, OnDestroy {


  constructor(private activatedRoute: ActivatedRoute) {
    if (this.activatedRoute.snapshot.paramMap.get('file-id')) {
      // this.imageUrl = DriveTools.getDriveLink(this.activatedRoute.snapshot.paramMap.get('file-id') as string);
      this.fileId = this.activatedRoute.snapshot.paramMap.get('file-id').toString();
    }
    this.loadDriveFile().then();

    if (this.configService.config.value.isFranchise) {
      if (this.configService.config.value.studioRegion === 'AB') {
        this.sendToOptions.set([
          {name: 'Born', mobile: MobileTools.Numbers.Born},
          {name: 'Twin', mobile: MobileTools.Numbers.Sven},
          {name: 'Shop-Nummer', mobile: this.configService.config.value.shopMobile},
          {name: 'Rena', mobile: '+4915111262481'},
        ]);
      } else if (this.configService.config.value.studioRegion === 'FFM') {
        this.sendToOptions.set([
          {name: 'Born', mobile: MobileTools.Numbers.Born},
          {name: 'Twin', mobile: MobileTools.Numbers.Sven},
          {name: 'Shop-Nummer', mobile: this.configService.config.value.shopMobile},
          {name: 'Mitarbeiter-Handy', mobile: '+4915759726871'},
        ]);
      } else {
        this.sendToOptions.set([
          {name: 'Born', mobile: MobileTools.Numbers.Born},
          {name: 'Twin', mobile: MobileTools.Numbers.Sven},
          {name: 'Shop-Nummer', mobile: this.configService.config.value.shopMobile},
        ]);
      }
    } else {
      this.sendToOptions.set([
        {name: 'Aachen Social Media Handy', mobile: MobileTools.Numbers.ACSocialMedia},
        {name: 'Niklas', mobile: MobileTools.Numbers.NiklasNXT},
        {name: 'Capone', mobile: MobileTools.Numbers.CaponeNXT},
        {name: 'Lili', mobile: MobileTools.Numbers.Lili},
        {name: 'Julian', mobile: MobileTools.Numbers.Julian},
      ]);
    }
  }

  currentZoom = signal(1);
  logText = signal('log');

  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasWrapper') canvasWrapper!: ElementRef<HTMLCanvasElement>;


  private canvas!: fabric.Canvas;
  private imageObject!: fabric.Image;
  private logoObject: FabricObject | Group;
  private cropper: fabric.Rect | null = null;
  isCropping = signal(false);

  logoDefaultOpacity = 0.5;

  show = signal(false);
  contentOpacity = signal(0);
  canvasWidth = signal(0);
  canvasHeight = signal(0);

  private isPanning = false;  // Variable für den Panning-Zustand
  private lastPosX = 0;
  private lastPosY = 0;


  /** Injected Services */
  dialogRef = inject(MatDialogRef, {optional: true});
  cdRef = inject(ChangeDetectorRef);
  dialogService = inject(DialogService);
  socketService = inject(SocketService);
  configService = inject(ConfigService);

  settings: {
    brightness: number;
    contrast: number;
    saturation: number;
    blackPoint: number;
    logoOpacity: number;
    logoPosition: 'center' | 'top-right';
  } = {
    brightness: 0,
    contrast: 0,
    saturation: 0,
    blackPoint: 0,
    logoOpacity: 0,
    logoPosition: 'center',
  };


  public fileId = 'c11aa59c-c00f-4b0c-ace1-5f953f987cb3';
  private driveFile = signal<NxtDriveFile | null>(null);

  protected readonly MobileTools = MobileTools;

  firstTouchMoveTwoFingers = true;

  private lastTouchDistance: number | null = null;

  sendToOptions = signal<{ name: string, mobile: string }[]>([]);

  async loadDriveFile() {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.refreshCanvasSize();
  }


  refreshCanvasSize() {
    const relation = this.canvas.width / this.canvas.height;
    const maxWidth = this.canvasWrapper.nativeElement.clientWidth;
    const maxHeight = this.canvasWrapper.nativeElement.clientHeight;

    const oldWidth = this.canvas.getWidth();
    const oldHeight = this.canvas.getHeight();

    let newWidth: number;
    let newHeight: number;

    if (maxWidth / maxHeight > relation) {
      newWidth = maxHeight * relation;
      newHeight = maxHeight;
    } else {
      newWidth = maxWidth;
      newHeight = maxWidth / relation;
    }

    // newHeight -= 50;

    // Verhältnis für das Skalieren der Objekte berechnen
    const scaleX = newWidth / oldWidth;
    const scaleY = newHeight / oldHeight;

    // Neue Canvas-Größe setzen
    this.canvas.setWidth(newWidth);
    this.canvas.setHeight(newHeight);

    // Alle Objekte auf dem Canvas skalieren und repositionieren
    this.canvas.getObjects().forEach((obj: fabric.Object) => {
      obj.scaleX = obj.scaleX * scaleX;
      obj.scaleY = obj.scaleY * scaleY;
      obj.left = obj.left * scaleX;
      obj.top = obj.top * scaleY;
      obj.setCoords(); // Aktualisiert die Begrenzungen und den Rendering-Bereich des Objekts
    });
    this.requestRenderAll();
  }


  ngOnInit(): void {
    this.canvasWidth.set(window.innerWidth);
    this.canvasHeight.set(window.innerHeight - 150);


    fabric.textureSize = 1024 * 6;

    // fabric.textureSize = 1024 * 3.5;

    this.show.set(true);
  }

  async ngAfterViewInit() {
    this.driveFile.set(await this.socketService.getDriveFile(this.fileId));
    await this.initLogo();
    await this.initializeCanvas();
    this.settings.brightness = 0.01;
    this.applyAllSettings('ngAfterViewInit');
    requestAnimationFrame(() => {
      this.settings.brightness = 0;
      this.applyAllSettings('ngAfterViewInit reset');
      this.contentOpacity.set(1);
      this.refreshCanvasSize();
    });
  }


  getFabricImage(url: string): Promise<fabric.Image> {
    return new Promise<fabric.Image>((resolve, reject) => {
      fabric.Image.fromURL(url, (img) => {
        resolve(img);
      }, {crossOrigin: 'anonymous'});
    });
  }

  getFabricSvg(url: string): Promise<FabricObject | Group> {
    return new Promise<FabricObject | Group>((resolve, reject) => {
      fabric.loadSVGFromURL(url, (objects, options) => {
        resolve(fabric.util.groupSVGElements(objects, options));
      });
    });
  }

  public startCropping() {
    if (this.isCropping()) {
      return;
    }
    this.resetZoom().then();
    this.isCropping.set(true);

    const canvasWidth = this.canvas.getWidth();
    const canvasHeight = this.canvas.getHeight();

    this.cropper = new fabric.Rect({
      left: canvasWidth * 0.1,
      top: canvasHeight * 0.1,
      width: canvasWidth * 0.8,
      height: canvasHeight * 0.8,
      fill: 'rgba(0,0,0,0)',
      borderColor: '#b7860f',
      // stroke: '#b7860f',
      // strokeDashArray: [5, 5],
      selectable: true,
      hasBorders: true,
      hasControls: true,
      lockRotation: true,
      lockUniScaling: false,  // Deaktiviert das Beibehalten der Seitenverhältnisse
      objectCaching: false,
      hasRotatingPoint: false,
      cornerStrokeColor: '#b7860f',
      // cornerStyle: 'circle',
      cornerSize: 20,
      cornerColor: '#b7860f',   // Optional: Sichtbare Ecken
    });

    this.cropper.on('moving', (e) => {
      const obj = e.transform.target;
      if (obj.left < 0) {
        obj.left = 0;
      }
      if (obj.top < 0) {
        obj.top = 0;
      }
      if (obj.left + obj.getScaledWidth() > this.canvas.getWidth()) {
        obj.left = this.canvas.getWidth() - obj.getScaledWidth();
      }
      if (obj.top + obj.getScaledHeight() > this.canvas.getHeight()) {
        obj.top = this.canvas.getHeight() - obj.getScaledHeight();
      }
    });


    this.cropper.on('scaling', (e) => {
      const obj = e.transform.target;

      // Begrenzung für die rechte und untere Seite
      if (obj.left + obj.getScaledWidth() > this.canvas.getWidth()) {
        obj.scaleX = (this.canvas.getWidth() - obj.left) / obj.width;
      }
      if (obj.top + obj.getScaledHeight() > this.canvas.getHeight()) {
        obj.scaleY = (this.canvas.getHeight() - obj.top) / obj.height;
      }

      // Begrenzung für die linke Seite
      if (obj.left < 0) {
        const excessWidth = -obj.left;
        obj.left = 0;
        obj.scaleX = (obj.getScaledWidth() - excessWidth) / obj.width;
      }

      // Begrenzung für die obere Seite
      if (obj.top < 0) {
        const excessHeight = -obj.top;
        obj.top = 0;
        obj.scaleY = (obj.getScaledHeight() - excessHeight) / obj.height;
      }
    });


    this.canvas.add(this.cropper);
    this.canvas.setActiveObject(this.cropper);
  }

  public cancelCropping() {
    if (!this.isCropping || !this.cropper) {
      return;
    }

    this.canvas.remove(this.cropper);
    this.cropper = null;
    this.isCropping.set(false);
    this.canvas.discardActiveObject();
    this.requestRenderAll();
  }


  private async initLogo() {
    this.logoObject = await this.getFabricSvg('assets/logo/logo_white.svg');
  }


  private async initializeCanvas() {
    this.canvas = new fabric.Canvas(this.canvasElement.nativeElement, {enableRetinaScaling: true});
    this.initMouse();


    this.imageObject = await this.getFabricImage(DriveTools.getDriveLink(this.driveFile().id));
    const viewportWidth = this.canvasElement.nativeElement.parentElement?.clientWidth || window.innerWidth;
    const viewportHeight = this.canvasElement.nativeElement.parentElement?.clientHeight || window.innerHeight;
    const scale = Math.min(viewportWidth / this.imageObject.width, viewportHeight / this.imageObject.height);
    this.canvas.setWidth(this.imageObject.width * scale);
    this.canvas.setHeight(this.imageObject.height * scale);
    this.imageObject.set({
      selectable: false,
      evented: false,
      scaleX: scale,
      scaleY: scale,
      originX: 'left',
      originY: 'top',
      left: 0,
      top: 0,
      objectCaching: false,
    });
    this.canvas.add(this.imageObject);
    this.canvas.add(this.logoObject);
    this.requestRenderAll();
  }

  applyAllSettings(from: string) {
    requestAnimationFrame(() => {
      this.applyLogoSettings(from + ' -> applyAllSettings');
      this.applyImageSettings(from + ' -> applyAllSettings');
      this.requestRenderAll();
    });
  }

  applyLogoSettings(from: string) {
    if (this.logoObject) {
      if (this.settings.logoPosition === 'top-right') {
        const originalWidth = this.logoObject.getScaledWidth() / this.logoObject.scaleX;
        const originalHeight = this.logoObject.getScaledHeight() / this.logoObject.scaleY;
        const canvasWidth = this.canvas.getWidth();
        const width = this.canvas.getWidth() / 5;
        const scale = Math.min(width / originalWidth, width / originalHeight);
        this.logoObject.set({
          scaleX: scale, // Setze die Skalierung auf 1 (ursprüngliche Größe)
          scaleY: scale,
          left: canvasWidth - (originalWidth * scale) - 10, // Positioniere es 10px vom rechten Rand
          top: 10, // Positioniere es 10px vom oberen Rand
          selectable: false,
          evented: false,
          opacity: this.settings.logoOpacity,
        });
      } else if (this.settings.logoPosition === 'center') {
        const canvasWidth = this.canvas.getWidth();
        const canvasHeight = this.canvas.getHeight();
        let logoWidth = canvasWidth * 0.7;
        if (canvasWidth > canvasHeight) {
          logoWidth = canvasWidth * 0.5;
        }
        const logoHeight = (this.logoObject.height) * (logoWidth / (this.logoObject.width)); // Erhalte die Proportionen

        // Zentriere das Logo auf dem Canvas
        this.logoObject.set({
          scaleX: logoWidth / (this.logoObject.width),
          scaleY: logoHeight / (this.logoObject.height),
          left: (canvasWidth - logoWidth) / 2,
          top: (canvasHeight - logoHeight) / 2,
          selectable: false,
          evented: false,
          opacity: this.settings.logoOpacity,
        });
      }
    }
  }

  public applyImageSettingsOld() {
    this.imageObject.filters = [
      new fabric.Image.filters.Brightness({brightness: this.settings.brightness}),
      new fabric.Image.filters.Contrast({contrast: this.settings.contrast}),
      new fabric.Image.filters.Saturation({saturation: this.settings.saturation}),
      new fabric.Image.filters.Gamma({gamma: [1 + this.settings.blackPoint, 1 + this.settings.blackPoint, 1 + this.settings.blackPoint]}),
    ];
    this.imageObject.applyFilters();
  }

  public applyImageSettings(from: string) {
    const start = Date.now();
    // Überprüfen, ob die Filter bereits existieren
    if (!this.imageObject.filters || this.imageObject.filters.length === 0) {
      // Filter initialisieren, falls sie noch nicht existieren
      this.imageObject.filters = [
        new fabric.Image.filters.Brightness(),
        new fabric.Image.filters.Contrast(),
        new fabric.Image.filters.Saturation(),
        new fabric.Image.filters.Gamma(),
      ];
    }

    // Vorhandene Filter aktualisieren
    const brightnessFilter = this.imageObject.filters[0] as any;
    const contrastFilter = this.imageObject.filters[1] as any;
    const saturationFilter = this.imageObject.filters[2] as any;
    const gammaFilter = this.imageObject.filters[3] as any;

    brightnessFilter.brightness = this.settings.brightness;
    contrastFilter.contrast = this.settings.contrast;
    saturationFilter.saturation = this.settings.saturation;
    gammaFilter.gamma = [
      1 + this.settings.blackPoint,
      1 + this.settings.blackPoint,
      1 + this.settings.blackPoint,
    ];

    // Filter anwenden
    this.imageObject.applyFilters();
    // this.cdRef.detectChanges();
    console.log('duration: ' + (Date.now() - start));
  }


  ngOnDestroy(): void {
    this.canvas.dispose();
  }

  private async getDataUrl(withLogo: boolean) {
    await this.resetZoom();
    const originalWidth = this.imageObject.width;
    const multiplier = originalWidth / this.canvas.getWidth();
    if (!withLogo) {
      this.canvas.remove(this.logoObject);
    }
    const dataURL = this.canvas.toDataURL({
      format: 'jpeg',
      multiplier, // Verwende den Skalierungsfaktor für die Originalgröße
      quality: 0.9, // Qualität des JPEG-Bildes
    });
    if (!withLogo) {
      this.canvas.add(this.logoObject);
    }
    return dataURL;
  }

  public downloadClicked(): void {
    setTimeout(async () => {
      const dataUrl = await this.getDataUrl(true);
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = Date.now().dateFormat('yyyy-MM-dd HH:mm:ss') + '.jpg';
      link.click();
    });
  }


  async saveClicked() {
    if (await this.dialogService.showYesNo('Möchtest du deine Änderungen speichern?', {noText: 'Zurück', yesText: 'Speichern'})) {
      const dataUrl = await this.getDataUrl(false);
      try {
        this.dialogService.showLoading('Bild wird gespeichert...');
        await this.socketService.saveEditPhoto(this.driveFile().id, dataUrl);
        this.dialogService.hideLoading();
        this.dialogRef.close();
      } catch (error) {
        this.dialogService.hideLoading();
        this.dialogService.showOk('Fehler beim Speichern des Bildes!\n' + error.message);
      }
    }
  }


  public rotateClicked(degree: number) {
    // Speichere die aktuellen Dimensionen des Bildes vor der Drehung
    const originalWidth = this.imageObject.getScaledWidth();
    const originalHeight = this.imageObject.getScaledHeight();

    let set = this.imageObject.angle + degree;
    if (set >= 360) {
      set -= 360;
    }
    if (set < 0) {
      set += 360;
    }
    // Drehe das Bild
    this.imageObject.rotate(set);
    const newWidth = this.canvas.getHeight();
    const newHeight = this.canvas.getWidth();
    // Passe die Canvas-Dimensionen an
    // setTimeout(() => {
    this.canvas.setWidth(newWidth);
    this.canvas.setHeight(newHeight);
    if (this.imageObject.angle === 0) {
      this.imageObject.set({
        left: 0,
        top: 0,
      });
    }
    if (this.imageObject.angle === 90) {
      this.imageObject.set({
        left: newWidth,
        top: 0,
      });
    }
    if (this.imageObject.angle === 180) {
      this.imageObject.set({
        left: newWidth,
        top: newHeight,
      });
    }
    if (this.imageObject.angle === 270) {
      this.imageObject.set({
        left: 0,
        top: newHeight,
      });
    }
    this.refreshCanvasSize();
    this.applyAllSettings('rotateClicked');
  }


  public async applyCrop() {
    if (!this.isCropping || !this.cropper) {
      return;
    }

    this.dialogService.showLoading('Bild wird zugeschnitten...');
    await TimeTools.sleep(10);
    // Get cropping rectangle dimensions
    const cropper = this.cropper.getBoundingRect();
    // Calculate the scale factor
    const scaleX = this.imageObject.scaleX || 1;
    const scaleY = this.imageObject.scaleY || 1;

    // Calculate the cropping area in the original image size
    const left = (cropper.left - this.imageObject.left) / scaleX;
    const top = (cropper.top - this.imageObject.top) / scaleY;
    const width = cropper.width / scaleX;
    const height = cropper.height / scaleY;

    // Create a canvas element to get the cropped image data
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = width;
    tempCanvas.height = height;
    const tempCtx = tempCanvas.getContext('2d');

    // Draw the cropped image onto the temp canvas

    // filter zurück setzen
    const currentSettings = clone(this.settings);
    this.resetSettings();
    this.applyImageSettings('applyCrop');


    tempCtx?.drawImage(
      this.imageObject.getElement(),
      left,
      top,
      width,
      height,
      0,
      0,
      width,
      height,
    );

    this.settings = currentSettings;

    // Create a new fabric image from the cropped image data
    const croppedDataUrl = tempCanvas.toDataURL();
    fabric.Image.fromURL(croppedDataUrl, (img) => {
      // Remove the old image and cropping rectangle
      this.canvas.remove(this.logoObject);
      this.canvas.remove(this.imageObject);
      if (this.cropper) {
        this.canvas.remove(this.cropper);
        this.cropper = null;
      }

      this.isCropping.set(false);

      // Add the new cropped image
      this.imageObject = img;
      this.imageObject.set({
        selectable: false,
        evented: false,
        originX: 'left',
        originY: 'top',
        left: 0,
        top: 0,
        objectCaching: true,
      });

      // Resize canvas to the new image dimensions
      this.canvas.setWidth(width * scaleX);
      this.canvas.setHeight(height * scaleY);

      // Apply the same scale factors
      this.imageObject.scaleX = scaleX;
      this.imageObject.scaleY = scaleY;

      this.canvas.add(this.imageObject);
      this.canvas.add(this.logoObject);

      // Re-apply settings and logo
      this.applyAllSettings('applyCrop');
      this.refreshCanvasSize();
      this.dialogService.hideLoading();
    });
  }

  private resetSettings() {
    this.settings.brightness = 0;
    this.settings.contrast = 0;
    this.settings.saturation = 0;
    this.settings.blackPoint = 0;
  }

  async shareToClicked(mobile: string) {
    this.dialogService.showLoading('Bild wird versendet...');
    const dataUrl = await this.getDataUrl(true);
    const base64 = dataUrl.split(',')[1];
    await this.socketService.sendWhatsAppDocument(mobile, this.driveFile().name, base64);
    this.dialogService.hideLoading();
  }

  switchLogoClicked() {
    if (this.settings.logoOpacity === 0) {
      this.centerLogoClicked();
    } else if (this.settings.logoPosition === 'top-right') {
      this.hideLogoClicked();
    } else {
      this.topRightLogoClicked();
    }
  }

  public topRightLogoClicked(): void {
    this.settings.logoOpacity = 1;
    this.settings.logoPosition = 'top-right';
    this.applyAllSettings('topRightLogoClicked');
  }

  public centerLogoClicked(): void {
    this.settings.logoPosition = 'center';
    this.settings.logoOpacity = this.logoDefaultOpacity;
    this.applyAllSettings('centerLogoClicked');
  }

  hideLogoClicked() {
    this.settings.logoOpacity = 0;
    this.applyAllSettings('hideLogoClicked');
  }


  setZoomDelta(delta: number) {
    if (this.isCropping()) {
      return;
    }
    let zoom = this.canvas.getZoom();
    zoom *= 0.999 ** delta;  // Zoom-Level anpassen

    if (zoom > 20) {
      zoom = 20;
    } // Begrenzung auf max. Zoom
    if (zoom < 0.01) {
      zoom = 0.01;
    } // Begrenzung auf min. Zoom

    const centerPoint = this.canvas.getCenter();
    const zoomPoint = new fabric.Point(centerPoint.left, centerPoint.top);
    this.canvas.zoomToPoint(zoomPoint, zoom);
    this.currentZoom.set(zoom);
  }

  initMouse() {
    this.canvas.on('mouse:wheel', (opt) => {
      const delta = opt.e.deltaY;
      this.setZoomDelta(delta);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    // Start des Pannings
    this.canvas.on('mouse:down', (opt) => {
      if (opt.e instanceof TouchEvent) {
        this.touchDown(opt.e as unknown as TouchEvent);
        return;
      }
      if (this.canvas.getZoom() !== 1) {
        this.isPanning = true;  // Panning aktivieren
        const pointer = this.canvas.getPointer(opt.e);
        this.lastPosX = pointer.x;
        this.lastPosY = pointer.y;
      }
    });

    this.canvas.on('mouse:up', () => {
      this.isPanning = false;  // Panning deaktivieren
      this.firstTouchMoveTwoFingers = true;
    });

    // Mausbewegung zum Verschieben (Pan)
    this.canvas.on('mouse:move', (opt) => {
      const e = opt.e;
      if (e.type === 'touchmove') {
        const touchEvent = e as unknown as TouchEvent;
        this.toucheMove(touchEvent);
        return;
      }
      if (this.isPanning) {  // Nur verschieben, wenn Panning aktiv ist
        const vpt = this.canvas.viewportTransform;
        if (vpt) {
          vpt[4] += e.movementX; // X-Achse verschieben
          vpt[5] += e.movementY; // Y-Achse verschieben

          this.logText.set('x: ' + e.type);
          this.requestRenderAll();
        }
      }
    });
  }

  resetZoom() {
    return new Promise<void>((resolve) => {
      this.canvas.setZoom(1);
      this.currentZoom.set(1);
      this.canvas.viewportTransform = [1, 0, 0, 1, 0, 0];  // Identitätsmatrix für Zoom und Pan
      this.canvas.requestRenderAll();
      requestAnimationFrame(() => {
        resolve();
      });
    });
  }

  zoomInClicked() {
    this.setZoomDelta(-100);
  }

  zoomOutClicked() {
    this.setZoomDelta(100);
  }

  private toucheMove(touchEvent: TouchEvent) {
    if (touchEvent.touches.length === 1) {
      this.firstTouchMoveTwoFingers = true;
      this.logText.set('ein finger');
      if (this.isPanning) {
        const touch = touchEvent.touches[0];
        const vpt = this.canvas.viewportTransform;
        if (vpt) {
          vpt[4] += touch.clientX - this.lastPosX;
          vpt[5] += touch.clientY - this.lastPosY;
          this.lastPosX = touch.clientX;
          this.lastPosY = touch.clientY;
          this.canvas.requestRenderAll();
        }
      }
    } else if (touchEvent.touches.length === 2) {
      this.toucheMoveTwoFingers(touchEvent);
    }
  }

  private touchDown(event: TouchEvent) {
    // this.logText.set('touch down ' + event.touches.length);
    if (event.touches.length === 1) {
      if (this.canvas.getZoom() !== 1) {
        this.isPanning = true;  // Panning aktivieren
        this.lastPosX = event.touches[0].clientX;
        this.lastPosY = event.touches[0].clientY;
      }
    } else if (event.touches.length === 2) {
      this.touchDownTwoFingers(event);
    }
  }

  private touchDownTwoFingers(event: TouchEvent) {
    this.logText.set('zwei finger');
    const point1 = event.touches[0];
    const point2 = event.touches[1];
    const dist = Math.sqrt(
      Math.pow(point2.clientX - point1.clientX, 2) +
      Math.pow(point2.clientY - point1.clientY, 2),
    );
    this.lastTouchDistance = dist;
    this.logText.set('set lastTouchDistance' + dist);
  }

  private toucheMoveTwoFingers(event: TouchEvent) {
    // this.logText.set('zwei finger - ' + this.lastTouchDistance);

    if (this.firstTouchMoveTwoFingers) {

      const point1 = event.touches[0];
      const point2 = event.touches[1];

      const dist = Math.sqrt(
        Math.pow(point2.clientX - point1.clientX, 2) +
        Math.pow(point2.clientY - point1.clientY, 2),
      );
      this.lastTouchDistance = dist;
      this.logText.set('zwei finger set lastTouchDistance ' + dist);
    }
    this.firstTouchMoveTwoFingers = false;

    if (event.touches.length === 2 && this.lastTouchDistance) {
      this.logText.set('zwei finger + last');
      const point1 = event.touches[0];
      const point2 = event.touches[1];

      const newDist = Math.sqrt(
        Math.pow(point2.clientX - point1.clientX, 2) +
        Math.pow(point2.clientY - point1.clientY, 2),
      );

      const delta = newDist / this.lastTouchDistance;
      let zoom = this.canvas.getZoom();
      zoom *= delta;

      // Begrenzung des Zooms
      if (zoom > 20) {
        zoom = 20;
      }
      if (zoom < 0.01) {
        zoom = 0.01;
      }

      const centerPoint = this.canvas.getCenter();
      const zoomPoint = new fabric.Point(centerPoint.left, centerPoint.top);

      this.logText.set('zwei finger zoom to: ' + zoom);
      this.canvas.zoomToPoint(zoomPoint, zoom);
      this.currentZoom.set(zoom);
      this.lastTouchDistance = newDist;
    }
  }

  async shareToStudioSocialMediaMobileClicked() {
    this.dialogService.showLoading('Bild wird versendet...');
    const dataUrl = await this.getDataUrl(true);
    const base64 = dataUrl.split(',')[1];
    await this.socketService.sendImageToSocialMedialMobile(base64);
    this.dialogService.hideLoading();
  }

  settingsDown(setting: 'saturation' | 'contrast' | 'brightness' | 'blackPoint') {
    this.settings[setting] -= 0.01;
    if (this.settings[setting] < -1) {
      this.settings[setting] = -1;
    }
    this.applyAllSettings('settingsDown');
  }

  settingsUp(setting: 'saturation' | 'contrast' | 'brightness' | 'blackPoint') {
    this.settings[setting] += 0.01;
    if (this.settings[setting] > 1) {
      this.settings[setting] = 1;
    }
    this.applyAllSettings('settingsUp');
  }

  private requestRenderAll() {
    this.canvas.requestRenderAll();
  }
}
