import {Injectable} from '@angular/core';
import {SocketService} from '../socket.service';
import {GlobalStudioShortName} from '../../../common-interfaces/global-studio.interface';
import {
  NxtBankCompany,
  NxtBankConfigAccount,
  NxtBankDatevExportLine,
  NxtBankTransaction,
  NxtBankTransactionAi,
  NxtBankTransactionType,
} from '../../../common-interfaces/bank/bank-transaction.interface';
import {SocketInterface, SocketInterfaceResponse} from '../../../common-interfaces/socket/socket-interface';

@Injectable({
  providedIn: 'root',
})
export class TattooTicketSubSocketService {
  /*** Injections ***/

  constructor(private socketService: SocketService) {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  private async emit(eventName: string, data?: any): Promise<any> {
    return this.socketService.emit('tattooTicket.' + eventName, data);
  }

  async confirm(data: SocketInterface.TattooTicketConfirm) {
    return this.emit('confirm', data);
  }

  async findByName(name: string) {
    return this.emit('findByName', name);
  }

  public sendToCustomer(shopOrderId: string, shopOrderLineItemId: string, sendVia: string) {
    return this.emit('sendToCustomer', {shopOrderId, shopOrderLineItemId, sendVia});
  }
}
