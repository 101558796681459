<div class="nxt-page nxt-text" style="border: 0px solid red">
  <div *ngIf="dialogRef" class="close-button mouse-pointer" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </div>
  <div fxFlex fxLayout="column" style="border: 0px solid blue; overflow: hidden">
    <div fxLayout="row">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
        <div fxLayout="row" fxLayoutAlign="center center">

          <nxt-date-picker [showJumpWeekButtons]="true" style="width:305px;" [(nxtModel)]="fromDateString" (nxtModelChange)="dateChanged()"
                           [showWeekStart]="true"></nxt-date-picker>
        </div>

        <div fxLayout="column" fxFlex="400px" fxLayoutAlign="center center">
          <div class="w-full" fxLayout="row" fxLayoutAlign="center center">
            <div class="mouse-pointer" (click)="selectDuration()">Dauer</div>
            &nbsp;&nbsp;
            <mat-slider fxFlex min="0.5" max="10" step="0.5">
              <input matSliderThumb [(ngModel)]="duration" (valueChange)="durationChanged()">
            </mat-slider>
            <div>{{ duration * 3600000 | duration: 'HH:mm' }}</div>
          </div>
          <div fxFill fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5em">
            <nxt-button [smallButton]="true" style="min-width: 70px" (click)="setDuration(0.5)">0.5</nxt-button>
            <nxt-button [smallButton]="true" style="min-width: 70px" (click)="setDuration(1)">1</nxt-button>
            <nxt-button [smallButton]="true" style="min-width: 70px" (click)="setDuration(2)">2</nxt-button>
            <nxt-button [smallButton]="true" style="min-width: 70px" (click)="setDuration(3)">3</nxt-button>
            <nxt-button [smallButton]="true" style="min-width: 70px" (click)="setDuration(7)">7</nxt-button>
          </div>
        </div>
        <div>
          <nxt-button-icon (click)="copyText()">content_copy</nxt-button-icon>
        </div>
        <div>
          <nxt-input placeholder="Artist" [(value)]="artistFilterText" (valueChange)="artistFilterTextChanged()"></nxt-input>
        </div>
      </div>
    </div>

    <div class="o-hidden" style="border:0px solid blue" fxLayout="row" fxLayoutAlign="center end">
      <div (click)="addFilter()" style="padding-bottom: 0.5em" class="mouse-pointer">
        <mat-icon>add</mat-icon>
      </div>

      <div fxFlex fxLayout="column">
        <div *ngFor="let filter of filters; let last = last; let index = index" fxLayout="row" fxLayoutAlign="start center" class="nxt-text"
             style="border: 0px solid green; overflow: auto">


          <div fxFlex fxLayout="row" fxLayoutAlign="space-between center" style="padding-left: 1em; padding-right: 1em">
            <div fxLayoutAlign="center center" fxLayout="row">
              <div *ngIf="filters.length > 1" style="padding-right: 1em" class="mouse-pointer" (click)="removeFilter(index)">
                <mat-icon>delete</mat-icon>
              </div>

              <mat-icon matTooltip="Kein Tag" class="mouse-pointer" *ngIf="filter.days.indexOf(false) === -1" (click)="selectAllDays(filter, false)">deselect
              </mat-icon>
              <mat-icon matTooltip="Alle Tag" class="mouse-pointer" *ngIf="filter.days.indexOf(false) > -1" (click)="selectAllDays(filter, true)">select_all
              </mat-icon>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <nxt-checkbox label="Mo." [(value)]="filter.days[0]" (valueChange)="loadData()"></nxt-checkbox>&nbsp;&nbsp;&nbsp;&nbsp;
              <nxt-checkbox label="Di." [(value)]="filter.days[1]" (valueChange)="loadData()"></nxt-checkbox>&nbsp;&nbsp;&nbsp;&nbsp;
              <nxt-checkbox label="Mi." [(value)]="filter.days[2]" (valueChange)="loadData()"></nxt-checkbox>&nbsp;&nbsp;&nbsp;&nbsp;
              <nxt-checkbox label="Do." [(value)]="filter.days[3]" (valueChange)="loadData()"></nxt-checkbox>&nbsp;&nbsp;&nbsp;&nbsp;
              <nxt-checkbox label="Fr." [(value)]="filter.days[4]" (valueChange)="loadData()"></nxt-checkbox>&nbsp;&nbsp;&nbsp;&nbsp;
              <nxt-checkbox label="Sa." [(value)]="filter.days[5]" (valueChange)="loadData()"></nxt-checkbox>
              <nxt-checkbox label="So." [(value)]="filter.days[6]" (valueChange)="loadData()"></nxt-checkbox>
            </div>

            <div fxFlex="25" fxLayoutAlign="center center">
              <div>Ab</div>
              &nbsp;&nbsp;
              <mat-slider fxFlex [min]="normalStudioStart" [max]="normalStudioEnd - 1" step="0.5">
                <input matSliderThumb [(ngModel)]="filter.startTime" (valueChange)="startTimeChanged()">
              </mat-slider>
              <div>{{ filter.startTime * 3600000 | duration: 'HH:mm' }} Uhr</div>
            </div>


            <div fxFlex="25" fxLayoutAlign="center center">
              <div>Studio Ende</div>
              &nbsp;&nbsp;
              <mat-slider fxFlex min="18" [max]="24" step="0.5">
                <input matSliderThumb [(ngModel)]="filter.studioEndTime" (valueChange)="endTimeChanged()">
              </mat-slider>
              <div>{{ filter.studioEndTime * 3600000 | duration: 'HH:mm' }} Uhr</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxFlex class="o-hidden" style="border: 0px solid red;">
      <div fxFlex fxLayout="row" style="border: 0px solid green; overflow: auto" class="nxt-text">
        <table fxFlex class="day-table" *ngIf="data">
          <tr>
            <th *ngFor="let day of data.days">
              <div class="flex flex-col justify-start">
                <div class="date-block" [innerHTML]="day.dateStringLong"></div>
                @if (dailyNotes[day.dateString]) {
                  <div class="daily-notes">
                    @for (dailyNote of dailyNotes[day.dateString]; track dailyNote.id) {
                      <div class="daily-note">{{ dailyNote.title }}</div>
                    }
                  </div>
                }
              </div>
            </th>
          </tr>
          <tr class="h-full">
            <td *ngFor="let day of dataFiltered.days" style="vertical-align: top">
              <table class="artist-table" style="width: 100%">
                <tr *ngFor="let artist of day.artists" style="height: 1px">
                  <td class="mouse-pointer" (dblclick)="editArtist(artist.id)" style="padding:0; height: inherit">
                    <div class="flex flex-row h-full items-center">
                      <div [ngStyle]="{'backgroundColor': artist.color}" style="height: 100%; width: 10px; margin-right: 5px">&nbsp;
                      </div>
                      <div class="flex flex-col w-full">
                        {{ $any(artist).studioShort }} {{ artist.name }}
                        <br/>
                        <nxt-rate-icons *ngIf="artist.skillFiltered.skill > 0" [value]="artist.skillFiltered.skill / 2" icon="star"
                                        [showEmpty]="true"></nxt-rate-icons>
                        <div class="text-80">Geschw. {{ artist.skillFiltered.speed }} / 10</div>
                        <!--<nxt-rate-icons *ngIf="false && artist.skillFiltered.like > 0" [value]="artist.skillFiltered.like" icon="heart" [showEmpty]="true"></nxt-rate-icons>-->
                        <div *ngIf="false" style="border: 0 solid grey; overflow: hidden; position: relative">
                          <div
                            [ngStyle]="{fontSize: '75%', minWidth:'3px', width: (artist.freeTime / (7 * 60 * 60 * 10)) +'%', backgroundColor: (artist.freeTime / (7 * 60 * 60 * 10)) > 30 ? 'red' : ((artist.freeTime / (7 * 60 * 60 * 10)) > 10) ? 'orange' : 'green'}">
                            &nbsp;
                          </div>
                          <div style="position: absolute; left: 0; top: 0; font-size: 75%; color: #808080">Zeit</div>
                        </div>

                        <div *ngIf="showMoneyBar" style="border: 0px solid grey; overflow: hidden; position: relative; margin-top:0px;">
                          <!--<div
                            [ngStyle]="{fontSize: '75%', minWidth:'3px', width: artist.artistMoneyPercentageToFill +'%', backgroundColor: artist.artistMoneyPercentageToFill > 30 ? 'red' : artist.artistMoneyPercentageToFill > 10 ? 'orange' : 'green'}">&nbsp;
                          </div>-->
                          <!--<div
                            [ngStyle]="{fontSize: '75%', minWidth:'3px', maxHeight: '5px', width: artist.artistMoneyPercentageToFill +'%', backgroundColor: 'green'}">&nbsp;
                          </div>-->

                          <nxt-rate-icons [minValue]="1" [value]="artist.artistMoneyPercentageToFill / 20" icon="euro" [showEmpty]="false"
                                          [showHalf]="false"></nxt-rate-icons>

                          <div style="position: absolute; left: 0; top: 0; font-size: 75%; color:gray"></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <table class="time-window-table">
                      <tr *ngFor="let freeTimeWindow of artist.freeTimeWindows">
                        <td>
                          <div (click)="freeTimeWindowClicked(day, artist, freeTimeWindow)">
                            <!--{{(freeTimeWindow.endTime - freeTimeWindow.startTime) / 1000 / 60 / 60}}
                            &nbsp;&nbsp;&nbsp;-->
                            {{ freeTimeWindow.startTime | duration: 'HH:mm' }} - {{ freeTimeWindow.endTime | duration: 'HH:mm' }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>Artist offen</td>
                  <td>
                    <div (click)="freeArtistClicked(day)" style="width: min-content" class="time-window-button mouse-pointer">{{ minTimeFrom }}
                      - {{ freeArtistEndTime }}
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-around" class="h-100" *ngIf="this.data">
        <div fxLayout="row" fxLayoutAlign="center">
          <div class="text-120">Skills</div>
        </div>
        <div class="scrollable h-full">
          <div *ngFor="let skill of this.data.boolSkills">
            <nxt-slide-toggle [(value)]="currentBoolSkillFilterObj[skill.description]"
                              (valueChange)="boolSkillChanged(skill.description)">{{ skill.description }}
            </nxt-slide-toggle>
          </div>
          <div>&nbsp;</div>
          <div *ngFor="let skill of this.data.skills">
            <nxt-slide-toggle [(value)]="currentSkillFilterObj[skill.description]" (valueChange)="skillChanged(skill.description)">{{ skill.description }}
            </nxt-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

