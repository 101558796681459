import {Component, OnInit} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {NxtComponent} from '../nxt.component';
import {NxtEventPhotoEvent} from '../../common-interfaces/nxt.event-photo-event.interface';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {DurationPipe} from '../../pipes/duration.pipe';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgIf} from '@angular/common';

@Component({
    selector: 'nxt-current-artist-photo-component',
    templateUrl: './current-artist-photo.component.html',
    styleUrls: ['./current-artist-photo.component.scss'],
    imports: [NgIf, FlexModule, DurationPipe]
})

export class CurrentArtistPhotoComponent extends NxtComponent implements OnInit {
  result: NxtEventPhotoEvent & { artistPhotoTimeDurationMin: number };
  public isInTime = false;
  public restTime = 0;
  public showTime = false;

  constructor(
    private socketService: SocketService
  ) {
    super();
    this.startInterval();
  }

  ngOnInit() {
    this.load();
    this.socketService.state.subscribe(async (params) => {
      if (params.authenticated) {
        this.result = await this.socketService.getCurrentArtistPhoto('iPhone 12 Pro Max');
        this.calc();
      }
    });
  }

  private async load() {
    this.result = await this.socketService.getCurrentArtistPhoto('iPhone 12 Pro Max');
    this.calc();
    this.pushSocketSubscription = this.socketService.subscribeNew('getCurrentArtistPhoto', (data) => {
      this.result = data;
      this.calc();
    }, {emitInitial: true});
  }

  private calc() {
    if (this.result) {
      this.restTime = this.result.createdAt + (DurationTools.DURATION_1MINUTE * this.result.artistPhotoTimeDurationMin) - Date.now();
      this.isInTime = this.restTime > 0;
      this.showTime = this.restTime < DurationTools.DURATION_1MINUTE * 5;
    }
  }

  private startInterval() {
    setInterval(() => this.calc(), 1000);
  }

  nxtOnDestroy(): void {
  }
}
