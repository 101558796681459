@if (documentFull(); as documentFull) {
  <div class="flex flex-col w-full h-full nxt-page bordersX !p-0">
    <div class="flex flex-row border-2-red flex-grow h-full">
      <as-split direction="horizontal">
        <as-split-area [size]="30">
          <div class="flex flex-col justify-center items-center h-full border-3-yellow">
            @if (pdfUrl()) {
              <nxt-pdfjs class="flex flex-grow self-stretch" [src]="pdfUrl()"></nxt-pdfjs>

              <!--<object class="flex flex-grow self-stretch" *ngIf="pdfObjectUrl()" [data]="pdfObjectUrl() | safeResourceUrl" type="application/pdf"></object>-->
            }
          </div>
        </as-split-area>
        <as-split-area [size]="70" class="pr-2">
          <div class="right-side flex flex-col border-4-pink flex-grow h-full pl-2 pb-1">

            <div class="flex">
              <div class="flex flex-grow flex-col items-center justify-start w-full">
                @if (false && documentFull.document.nxtAi.manualChanges?.length > 0) {
                  <div class="flex text-[85%] flex-col gap-4">
                    @for (change of documentFull.document.nxtAi.manualChanges; track change) {
                      <table class="nxt-table">
                        <tr>
                          <td [colSpan]="2" class="text-center">{{ change.by }} {{ change.at | nxtDate: 'dd.MM.yyyy HH:mm' }}</td>
                        </tr>
                        @for (itemChange of change.changes; track itemChange.key) {
                          <tr>
                            <td>{{ itemChange.key }}</td>
                            <td>{{ itemChange.oldValue }} ➞ {{ itemChange.newValue }}</td>
                          </tr>
                        }
                      </table>
                    }
                  </div>
                }
                <div class="flex flex-col self-stretch flex-grow justify-center">
                  <div class="flex flex-row justify-between px-2">
                    @if (hasChanges()) {
                      <nxt-button-icon matTooltip="Speichern" (click)="saveClicked()">save</nxt-button-icon>
                    }
                    <nxt-button-icon matTooltip="Aktualisieren" (click)="reloadClicked()">refresh</nxt-button-icon>
                    <nxt-button-icon matTooltip="PDF bearbeiten" (click)="editDocumentClicked()">edit_document</nxt-button-icon>
                    <nxt-button-icon matTooltip="RUN" (click)="runClicked()">sprint</nxt-button-icon>
                    <nxt-button-icon (mouseup)="copyUrlClicked($event)">content_copy</nxt-button-icon>
                    <nxt-button-icon matTooltip="Details" (click)="documentDetailsClicked()">info</nxt-button-icon>
                    <nxt-button-icon matTooltip="Dokument herunterladen" (click)="downloadClicked()">download</nxt-button-icon>
                    <nxt-button-icon matTooltip="Dokument löschen" (click)="deleteDocumentClicked()">delete</nxt-button-icon>

                    @if (dialogRef) {
                      <nxt-button-icon matTooltip="Schließen" (click)="closeClicked()">close</nxt-button-icon>
                    }
                  </div>
                  <div class="flex flex-row justify-center py-2 gap-3">
                    <nxt-button [smallButton]="true" (click)="documentTypeClicked()">Dokument-Typ: {{ document().documentType | nxtBankDocumentType }}
                    </nxt-button>
                    <nxt-button [smallButton]="true">Dokument-Status: {{ document().status }}</nxt-button>
                  </div>

                  @if (showLoading()) {
                    <div class="flex flex-row justify-center w-full">
                      <nxt-spinner></nxt-spinner>
                    </div>
                  }

                  @if (showForm()) {
                    <div class="grid grid-cols-3 gap-4 w-full px-8">
                      <nxt-autocomplete
                        [options]="companies()"
                        [displayWith]="displayOtherCompany"
                        [displayInOptionWith]="displayOtherCompany"
                        [maxOptionsToShow]="999"
                        [filterFields]="['name', 'datevNo']"
                        [showClearIcon]="true"
                        [nxtFormControl]="form.controls.otherCompany"
                      />
                      <div class="flex flex-row relative">
                        <nxt-date-picker-2 class="w-full" [nxtFormControl]="form.controls.documentDateString"></nxt-date-picker-2>
                        <nxt-button-icon class="absolute right-0 top-[12px]" [size]="18" (click)="parseDateInputClicked()" fa="sparkles"></nxt-button-icon>
                      </div>
                      <nxt-input class="w-full" [nxtFormControl]="form.controls.valueGross" [isMoney]="true" [currency]="currency()"></nxt-input>
                      @if (!form.controls.noDocumentNumberOk.value) {
                        <div class="flex flex-col justify-center items-center relative">
                          <nxt-input class="w-full" [noPadding]="true" [center]="true" [nxtFormControl]="form.controls.documentNumber"></nxt-input>
                          @if (showDocumentSearchIcons() && form.value.documentNumber) {
                            <nxt-button-icon class="absolute right-0" (click)="searchDocumentNumberClicked(form.controls.documentNumber.value)">search
                            </nxt-button-icon>
                          }
                        </div>
                        <div class="flex flex-col justify-center items-center relative">
                          <nxt-input class="w-full" [noPadding]="true" [center]="true" [nxtFormControl]="form.controls.documentNumber2"></nxt-input>
                          @if (showDocumentSearchIcons() && form.value.documentNumber2) {
                            <nxt-button-icon class="absolute right-0" (click)="searchDocumentNumberClicked(form.controls.documentNumber2.value)">search
                            </nxt-button-icon>
                          }
                        </div>
                        <div class="flex flex-col justify-center items-center relative">
                          <nxt-input class="w-full" [noPadding]="true" [center]="true" [nxtFormControl]="form.controls.documentNumber3"></nxt-input>
                          @if (showDocumentSearchIcons() && form.value.documentNumber3) {
                            <nxt-button-icon class="absolute right-0" (click)="searchDocumentNumberClicked(form.controls.documentNumber3.value)">search
                            </nxt-button-icon>
                          }
                        </div>
                      }
                      @if (!form.controls.documentNumber.value) {
                        <nxt-slide-toggle [nxtFormControl]="form.controls.noDocumentNumberOk"></nxt-slide-toggle>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
            <div style="border:0px solid pink" class="flex flex-col relative pt-2">
              @if (document().nxtAi?.manualChanges?.length > 0) {
                <div class="flex flex-row justify-center">
                  <nxt-button [smallButton]="true" (click)="showManualChangesClicked()">Manuelle Änderungen anzeigen</nxt-button>
                </div>
              }

              <div class="flex flex-row flex-grow items-center justify-center py-3 gap-2">
                @if (document().status === NxtBankDocumentStatus._90_ok && documentRestValue() === 0) {
                  <div class="green-div">✓ Vollständig zugewiesen ✓</div>
                }
                @if (document().documentType === NxtBankDocumentType.DeliveryNote) {
                  <div>Wenn es wirklich ein Lieferschein ist, Dokument bitte löschen</div>
                }

                @if (document().datevExportId) {
                  <div class="green-div">✓ DATEV-Export ✓</div>
                }
                @if (document().status === NxtBankDocumentStatus._30_transaction && documentRestValue() > 0) {
                  <div class="orange-div cursor-pointer"
                       (click)="documentRestValueClicked()">{{ documentRestValue() | money2: document().nxtAi?.invoice?.currency }} offen
                  </div>
                }
                @if (document().status === NxtBankDocumentStatus._30_transaction && documentRestValue() > 0) {
                  <div class="green-div cursor-pointer" (click)="payNowClicked()">jetzt bezahlen</div>
                }
              </div>

              @if (assignedTransactions().length > 0) {
                <div style="border:0px solid white" class="flex flex-row items-center relative">
                  @if (selectedAssignedTransactions().length > 0) {
                    <div class="absolute flex-row flex justify-start items-end h-full pl-2 pb-1">
                      <mat-icon class="mouse-pointer" (click)="removeSelectedAssignedTransactionsClicked()" matTooltip="Zuweisung aufheben">delete</mat-icon>
                    </div>
                  }
                  <nxt-bank-transaction-finder
                      #assignedTransactionsFinder
                      class="pt-3"
                      [headerText]="assignedTransactions().length === 1 ? '1 zugewiesene Transaktion' : (assignedTransactions().length + ' zugewiesene Transaktionen')"
                      [notSelectableTransactionIds]="[]"
                      [otherCompanyName]="otherCompany().name"
                      [initValue]="documentValueForTransaction()"
                      [initDate]="document().documentDateTime || document().documentDate"
                      [initTransactions]="assignedTransactions()"
                      [(selectedPossibleTransactions)]="selectedAssignedTransactions"
                      (onAssignSelectedPossibleTransactionsClicked)="removeSelectedAssignedTransactionsClicked()"
                      [assignIconColor]="ColorTools.Red"
                      [contentHeight]="true"
                      [assignedTransactions]="document().assignedTransactions"
                      assignIcon="delete"
                      assignMode="full"
                      mode="view"
                      assignText="Zuweisung aufheben"
                  />
                </div>
              }
            </div>
            <!--@if (!showPossibleTransactions()) {
              <div class="flex flex-row justify-center">
                <div class="not-load-possible-transaction">{{ showPossibleTransactionsNotText() }}</div>
              </div>
            }-->
            @switch (document().status) {
              @case (NxtBankDocumentStatus._05_checkOwnCompany) {
                <div class="flex flex-row justify-center">
                  <div class="orange-div cursor-pointer" (click)="ownCompanyMissingClicked()">{{ document().ownCompany }} fehlt</div>
                </div>
              }
              @case (NxtBankDocumentStatus._30_transaction) {
                @if (documentRestValue() !== 0) {
                  <nxt-bank-transaction-finder
                    class="pt-3"
                    #possibleTransactionsFinder
                    headerText="Mögliche Transaktionen"
                    [notSelectableTransactionIds]="[]"
                    [otherCompanyName]="otherCompany().name"
                    [initValue]="documentValueForTransaction()"
                    [initDate]="document().documentDateTime || document().documentDate"
                    [(selectedPossibleTransactions)]="selectedPossibleTransactions"
                    (onAssignSelectedPossibleTransactionsClicked)="assignSelectedPossibleTransactionsClicked()"
                    [assignIconColor]="ColorTools.GreenDark"
                    [transactionStatus]="[NxtBankTransactionStatus._07_documentMatch, NxtBankTransactionStatus._03_delayed, NxtBankTransactionStatus._01_nxtAi]"
                    [excludeTransactionIds]="assignedTransactionIds()"
                    [onlyNeedDocumentOrStatusAi]="true"
                    assignMode="rest"
                    mode="search"
                    assignText="Zuweisen"
                  />
                }
              }
            }


          </div>
        </as-split-area>
      </as-split>
    </div>
  </div>
}
