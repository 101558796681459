<div class="w-full" *ngIf="form" [formGroup]="form" fxLayout="column">
  <div nxtPermission="IsJulian" class="text-60">{{ data?.id }}</div>


  <div fxLayout="row" fxLayoutGap="15px">
    <nxt-input *ngIf="piercingAttributes.length === 0" placeholder="Bezeichnung" [nxtFormControl]="form.get('name')"></nxt-input>


    <div class="flex w-1/2 flex-col" *ngIf="piercingAttributes.length > 0">
      <div class="pb-5 text-xl">{{ form.get('name').value }}</div>
      <nxt-input placeholder="Bezeichnung" [(value)]="piercingData.name" (valueChange)="piercingDataChanged()"></nxt-input>
      <div *ngFor="let piercingAttribute of piercingAttributes; let l = index">
        <nxt-input [isNumber]="true" [placeholder]="piercingAttribute.name" [(value)]="piercingData.attributes[l]"
                   (valueChange)="piercingDataChanged()"></nxt-input>
      </div>
    </div>


    <div class="flex w-1/2">
      <nxt-select
        [nxtFormControl]="form.get('location')"
        placeholder="Ort"
        requiredError="Ort bitte angeben!"
        [options]="inventoryService.inventoryLocations"
      >
      </nxt-select>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="15px">
    <nxt-input placeholder="Bezeichnung engl." [nxtFormControl]="form.get('nameEn')"></nxt-input>
    <nxt-input
      [placeholder]="'Bezeichnung bei ' + (form.get('sourceOfSupply').value ? inventoryService.getSourceText(form.get('sourceOfSupply').value) : 'Bezugsquelle')"
      [nxtFormControl]="form.get('nameBySource')"></nxt-input>
  </div>
  <div fxLayout="row" fxLayoutGap="15px">
    <nxt-autocomplete
      placeholder="Zuständig"
      [options]="users"
      [nxtFormControl]="form.get('responsibility')"
      [maxOptionsToShow]="9999"
    >
    </nxt-autocomplete>
    <nxt-autocomplete
      placeholder="Zuständig Vertretung"
      [options]="users"
      [nxtFormControl]="form.get('responsibility2')"
      [maxOptionsToShow]="9999"
    >
    </nxt-autocomplete>
    <nxt-slide-toggle [nxtFormControl]="form.get('orderedByAc')">Aachen bestellt</nxt-slide-toggle>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
    <nxt-select
      fxFlex="30"
      [nxtFormControl]="form.get('sourceOfSupply')"
      placeholder="1. Bezugsquelle"
      requiredError=""
      [options]="inventoryService.inventorySourcesOfSupply"
    >
    </nxt-select>
    <nxt-input
      style="font-size: 80%; margin-top: 8px"
      fxFlex="70"
      [nxtFormControl]="form.get('sourceOfSupplyUrl')"
      placeholder="1. Bezugsquelle Url"
      requiredError=""
    >
    </nxt-input>
  </div>

  <div fxLayout="row" fxLayoutGap="15px">
    <nxt-input
      fxFlex="30"
      [nxtFormControl]="form.get('sourceOfSupply2')"
      placeholder="2. Bezugsquelle"
    >
    </nxt-input>
    <nxt-input
      style="font-size: 80%; margin-top: 8px"
      fxFlex="70"
      [nxtFormControl]="form.get('sourceOfSupply2Url')"
      placeholder="2. Bezugsquelle Url"
      requiredError=""
    >
    </nxt-input>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">

    <nxt-input
      [nxtFormControl]="form.get('unitOfMeasure')"
      placeholder="Mengeneinheit"
    >
    </nxt-input>

    <nxt-input
      [nxtFormControl]="form.get('amountPerPackage')"
      placeholder="Menge pro Packung"
      [isNumber]="true"
      [isNumberMin]="1"
    >
    </nxt-input>
    <div class="no-wrap" *ngIf="form.get('amountPerPackage').value && form.get('unitOfMeasure').value">
      = 1 Packung beinhaltet {{ form.get('amountPerPackage').value }} {{ form.get('unitOfMeasure').value }}
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
    <nxt-slide-toggle
      [nxtFormControl]="form.get('onlyFullOrEmpty')"
    >Nur "Voll" oder "Nachbestellen"
    </nxt-slide-toggle>

    <ng-container *ngIf="form.get('onlyFullOrEmpty').value">
      <nxt-input placeholder="Inventarinfo" [nxtFormControl]="form.get('onlyFullOrEmptyInfo')"></nxt-input>
    </ng-container>

    <ng-container *ngIf="!form.get('onlyFullOrEmpty').value">
      <div fxLayout="row" fxFlex fxLayoutAlign="center center">
        <nxt-input
          [nxtFormControl]="form.get('amountAlarm')"
          placeholder="Alarm ab Menge"
          [isNumber]="true"
          [isNumberMin]="0"
        >
        </nxt-input>
        <div class="no-wrap" *ngIf="form.get('amountAlarm').value && form.get('amountPerPackage').value">
          = Alarm ab {{ (form.get('amountAlarm').value * form.get('amountPerPackage').value) }} {{ form.get('unitOfMeasure').value }}
        </div>
      </div>
      <div style="width:100px;"></div>
      <div fxLayout="row" fxFlex fxLayoutAlign="center center">
        <nxt-input
          [nxtFormControl]="form.get('amountFull')"
          placeholder="Sollbestand"
          [isNumber]="true"
          [isNumberMin]="0"
        >
        </nxt-input>
        <div class="no-wrap" *ngIf="form.get('amountFull').value && form.get('amountPerPackage').value">
          = Voll bei {{ (form.get('amountFull').value * form.get('amountPerPackage').value) }} {{ form.get('unitOfMeasure').value }}
        </div>
      </div>
      <div>
      </div>
    </ng-container>
  </div>
  <div class="flex flex-row justify-center">
    <nxt-date-picker width="220px" [nxtFormControl]="form.get('notOrderBefore')" placeholder="Nicht bestelle vor">
    </nxt-date-picker>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-around" class="pt-3">
    <div>
      <nxt-button (click)="closeClicked()">ABBRECHEN</nxt-button>
    </div>

    <div>
      <nxt-button (click)="showPackagesClicked()">PAKETE</nxt-button>
    </div>
    <div>
      <nxt-button (click)="delete()">LÖSCHEN</nxt-button>
    </div>
    <div>
      <nxt-button (click)="saveAndClose()">SPEICHERN</nxt-button>
    </div>
  </div>
</div>
