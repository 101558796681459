import {ErrorHandler, Injectable} from '@angular/core';
import {Log} from './common-browser/log/log.tools';
import {Router} from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private noErrorList = [
    'FirebaseError: Firebase: Access to this account has been temporarily disabled due to many failed login attempts',
    'FirebaseError: Firebase: The password is invalid or the user does not have a password. (auth/wrong-password)',
    'The provided value is non-finite',
  ];

  constructor(
    private router: Router
  ) {
  }

  handleError(error) {
    if (error.message.includes('NG04002')) {
      this.router.navigate(['/']);
      // window.location.href = window.location.origin;
      return;
    }
    // warum denn die Server-Errors nicht anzeigen???
    /*if (Log.isServerError(error.message)) {
      return;
    }*/
    for (const noError of this.noErrorList) {
      if (error.message && error.message.includes(noError)) {
        return;
      }
    }
    if (error.message !== 'nicht eingeloggt!') {
      if (error.message && error.message.startsWith('Uncaught')) {
        error.uncaught = true;
        error.message = error.message.replace('Uncaught (in promise): ', '');
        if (error.message.startsWith('Object: ')) {
          error.message = error.message.substr(8);
        }
      }
      Log.error('Uncaught', error);
    }
    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    // throw error;
  }
}
