import {inject, Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {DialogService} from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class ThermalPrinterService {
  /*** Injections ***/
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  async showChangeIp(message = '') {
    const currentIp = await this.socketService.getThermalPrinterIp();
    const newIp = await this.dialogService.showInput(message, {title: 'Belegdrucker IP ändern', prompt: currentIp, placeholder: 'IP-Adresse', okButtonText: 'Speichern'});
    if (newIp && newIp !== currentIp) {
      await this.socketService.setThermalPrinterIp(newIp);
    }
  }
}
