import {Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'nxt-smooth-height',
    template: `
    <ng-content></ng-content>
  `,
    styles: [`
    :host {
      display: block;
      overflow: hidden;
    }
  `],
    animations: [
        trigger('grow', [
            transition('void <=> *', []),
            transition('* <=> *', [
                style({ height: '{{startHeight}}px', overflow: 'hidden' }),
                animate('{{seconds}}s ease'),
            ], { params: { startHeight: 0 } })
        ])
    ],
    standalone: true
})
export class SmoothHeightComponent implements OnChanges {
  @Input() trigger: any;
  @Input() fromTop: boolean;
  @Output() HeightChanged = new EventEmitter<void>();
  @Input() animateSeconds = 0.4;
  private firstChange = true;
  startHeight: number;
  startOpacity = 1;

  @HostBinding('@grow') grow: any;
  private doScroll = false;


  @HostListener('@grow.done', ['$event'])
  growDoneEvent(ev) {
    this.stopScroll();
    if (this.element.nativeElement.clientHeight === 0) {
      this.element.nativeElement.classList.add('d-none');
    } else {
      this.element.nativeElement.classList.remove('d-none');
    }
    this.HeightChanged.emit();
  }

  constructor(private element: ElementRef) {
  }


  ngOnChanges() {
    this.element.nativeElement.classList.remove('d-none');
    if (this.firstChange) {
      this.firstChange = false;
      return;
    }
    this.startHeight = this.element.nativeElement.clientHeight;
    requestAnimationFrame(() => {
      this.grow = {
        value: this.trigger,
        params: {startHeight: this.startHeight, startOpacity: this.startOpacity, seconds: this.animateSeconds.toString()}
      };
    });
    if (this.fromTop) {
      this.startScroll();
    }
  }

  private stopScroll() {
    this.doScroll = false;
  }

  private startScroll() {
    this.doScroll = true;
    this.scrollDown();
    setTimeout(() => {
      this.stopScroll();
    }, this.animateSeconds * 1000);
  }

  private scrollDown() {
    requestAnimationFrame(() => {
      console.log('Scroll down');
      this.element.nativeElement.scrollTo({top: 999999999});
      if (this.doScroll) {
        this.scrollDown();
      }
    });


  }
}
