<div class="flex flex-row items-center gap-3">
  <nxt-button-icon
    [size]="iconSize"
    matTooltip="Datum Wählen"
    (click)="showDateSelect.emit()">
    calendar_month
  </nxt-button-icon>
  <nxt-button-icon
    [size]="iconSize"
    [matTooltip]="filter().daysCount + ' Tage zurück'"
    (click)="jumpDateBack.emit()">
    arrow_back_ios_new
  </nxt-button-icon>
  <nxt-button-icon
    [size]="iconSize"
    [matTooltip]="filter().daysCount + ' Tage vor'"
    (click)="jumpDateForward.emit()">
    arrow_forward_ios
  </nxt-button-icon>
  <nxt-button-icon
    [size]="iconSize"
    matTooltip="heute"
    (click)="setToday.emit()">
    system_update_alt
  </nxt-button-icon>
  <nxt-button-icon
    nxtPermission="Artists_NewSpot"
    [size]="iconSize"
    matTooltip="neuer Artist-Spot"
    (click)="newArtistSpot.emit()">
    person_add
  </nxt-button-icon>
  <nxt-button-icon
    [size]="iconSize"
    matTooltip="Terminfinder"
    (click)="eventFinder.emit()">
    event_available
  </nxt-button-icon>
  <nxt-button-icon
    *ngIf="false"
    [size]="iconSize"
    matTooltip="Google-Photos"
    (click)="openGooglePhotos()">
    image
  </nxt-button-icon>
  <nxt-button-icon
    [size]="iconSize"
    matTooltip="WALK-IN anlegen"
    (click)="walkIn.emit()">
    directions_run
  </nxt-button-icon>
  <nxt-button-icon
    [size]="iconSize"
    matTooltip="neu Laden"
    (click)="reload.emit()">
    refresh
  </nxt-button-icon>
</div>
<div class="flex flex-row gap-2 slide-toggle-wrapper">
  <div class="flex flex-col items-start">
    <nxt-slide-toggle [value]="filter().showCanceledEvents" (valueChange)="showCanceledEventsChanged($event)">Abgesagte</nxt-slide-toggle>
    @if (filter().showCanceledEvents) {
      <nxt-slide-toggle [value]="filter().showOnlyCanceledEvents" (valueChange)="showOnlyCanceledEventsChanged($event)">nur Abgesagte</nxt-slide-toggle>
    }
    <nxt-slide-toggle [value]="filter().showOnlyEventsFuture" (valueChange)="showOnlyEventsFutureChanged($event)">nur Offene</nxt-slide-toggle>
  </div>
  <div class="flex flex-col items-start">
    <nxt-slide-toggle [value]="filter().showEventsWithPiercing" (valueChange)="showWithPiercingChanged($event)">mit Piercing</nxt-slide-toggle>
    <nxt-slide-toggle [value]="filter().onlyArtistSpots" (valueChange)="onlyArtistSpotsChanged($event)">Nur Artist-Spots</nxt-slide-toggle>
  </div>
</div>
<div>
  <div class="flex flex-row" (click)="logout()">
    <div>{{ loginService.getUsername() }}</div>
    <div>&nbsp;・&nbsp;</div>
    <div>{{ loginService.getWorkplace() | workplace }}</div>
    <div>&nbsp;・&nbsp;</div>
    <div>{{ loginService.getStudio() }}</div>
  </div>
</div>
<div>
  <div class="flex flex-row gap-1">
    <nxt-button [smallButton]="true" [class.current-button]="filter().daysCount === 1" (click)="setDaysCount(1)">1</nxt-button>
    <nxt-button [smallButton]="true" [class.current-button]="filter().daysCount === 3" (click)="setDaysCount(3)">3</nxt-button>
    <nxt-button [smallButton]="true" [class.current-button]="filter().daysCount === 6" (click)="setDaysCount(6)">6</nxt-button>
    <nxt-button [smallButton]="true" [class.current-button]="filter().daysCount === 7" (click)="setDaysCount(7)">7</nxt-button>
  </div>
</div>

