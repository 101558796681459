<div fxLayout="column" class="nxt-page-modal" style="width:70vw; height: 90vh; border-radius: 4px;">
  <div>{{ filename | json }}</div>
  <div fxFlex fxLayoutAlign="center center" *ngIf="!iframeSrc">
    <div style="font-size: 24px;">lade PDF...</div>
  </div>
  <object fxFlex *ngIf="iframeSrc" [data]="iframeSrc | safeResourceUrl" type="application/pdf">
  </object>
  <div style="height: 16px;"></div>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div class="flex justify-center items-center gap-2">
      <nxt-button (click)="download()">herunterladen</nxt-button>
      <nxt-button (click)="print()">drucken</nxt-button>
      <nxt-button (click)="close()">schließen</nxt-button>
    </div>
  </div>

</div>

