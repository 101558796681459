import {Injectable} from '@angular/core';
import {DialogService} from './dialog.service';
import {SocketService} from './socket/socket.service';
import {NxtContact} from '../common-interfaces/nxt.contact.interface';
import {NxtArtist} from '../common-interfaces/nxt.artist.interface';
import {WhatsAppTools} from '../common-browser/helpers/whats-app.tools';
import {DateTools} from '../common-browser/helpers/date.tools';
import {WhatsappChatComponent} from '../components/whatsapp/whatsapp-chat/whatsapp-chat.component';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtCalendarEventBodyPutTattoo} from '../common-interfaces/nxt.calendar-event.interface';

@Injectable({
  providedIn: 'root',
})
export class ContactService {


  constructor(
    private dialogService: DialogService,
    private socketService: SocketService,
  ) {
  }


  async showAddTaglineDialog(contactId: string): Promise<string | undefined> {
    const result = await this.dialogService.showInputWhatsApp('Info hinzufügen', {showColorPicker: true, prompt: 'info zum Kunden erfassen'});
    let tagline = '';
    if (result) {
      if (typeof result === 'string') {
        tagline = result;
      } else {
        const style = `background-color:${result.color.backgroundColor};color:${result.color.fontColor};margin-left: 4px; padding: 2px 4px; border-radius: 3px; display: inline; position: relative; top: -2px`;
        tagline = `<button style="${style}">${result.text}</button>`;
      }
      await this.socketService.addTaglineToNxtContact({contactId, tagline});
      return tagline;
    }
  }

  async showWhatsAppChat(contactOrContactIdOrMobile: string | NxtContact, currentArtist?: NxtArtist, options?: {
    bodyPutsTattoo?: NxtCalendarEventBodyPutTattoo[],
    skills?: string[],
    eventId: string
  }): Promise<MatDialogRef<WhatsappChatComponent, any>> {
    let dialog: MatDialogRef<WhatsappChatComponent, any> | undefined;
    let contact: NxtContact | undefined;
    let mobile = '';
    if (typeof contactOrContactIdOrMobile === 'string') {
      if (contactOrContactIdOrMobile.startsWith('+')) {
        mobile = contactOrContactIdOrMobile;
      } else {
        contact = await this.socketService.getContactWithEvents(contactOrContactIdOrMobile);
      }
    } else {
      contact = contactOrContactIdOrMobile;
    }
    if (contact) {
      mobile = contact.mobileFormatted;
      if (contact.oldMobilesFormatted?.length > 0) {
        let buttons: any[] = contact.oldMobilesFormatted.sortNumber('t').map(m => ({text: 'vor dem ' + DateTools.format(m.t, 'dd.MM.yyyy'), value: m.m}));
        buttons = [...buttons, {text: 'aktuell Nummer', value: contact.mobileFormatted}];
        const ask = false;
        mobile = contact.mobileFormatted;
        if (ask) {
          const result = await this.dialogService.showButtonChooserNew<string>({buttonRows: [buttons], title: 'Der Kunde hatte einen Rufnummer-Wechsel', hideBackButton: true});
          if (result?.value) {
            mobile = WhatsAppTools.parseWhatsAppId(result.value, true);
          }
        }
      }
    }
    if (mobile) {
      dialog = await this.dialogService.showComponentFull(WhatsappChatComponent);
      dialog.componentInstance.loadChat(mobile, contact?.id);
      if (contact.givenName) {
        dialog.componentInstance.title = contact.givenName + ' ' + contact.familyName + ' +' + WhatsAppTools.parseWhatsAppId(mobile, false);
      } else {
        dialog.componentInstance.title = WhatsAppTools.parseWhatsAppId(mobile, false);
      }
      dialog.componentInstance.currentArtist = currentArtist;
      dialog.componentInstance.bodyPutsTattoo = options?.bodyPutsTattoo;
      dialog.componentInstance.skills = options?.skills;
      dialog.componentInstance.eventId = options?.eventId;
    } else {
      this.dialogService.showOk('Keine Nummer hinterlegt').then();
    }
    return dialog;
  }
}
