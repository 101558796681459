import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DialogOptionsYesNo} from '../../../services/dialog.service';
import {NxtButtonComponent} from '../../button/nxt-button.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgIf, NgStyle} from '@angular/common';

@Component({
    selector: 'nxt-dialog-yesno',
    templateUrl: './dialog-yesno.component.html',
    styleUrls: ['./dialog-yesno.component.scss'],
    imports: [
        NgClass,
        ExtendedModule,
        NgIf,
        NgStyle,
        NxtButtonComponent,
        SafeHtmlPipe,
    ]
})
export class DialogYesNoComponent implements OnInit {
  @ViewChild('yesButton', {static: true}) yesButton: NxtButtonComponent;
  @ViewChild('noButton', {static: true}) noButton: NxtButtonComponent;
  message: string;

  // @Input() fontSize = '100%';
  private buttonPadding = '8px';

  constructor(
    public dialogRef: MatDialogRef<DialogYesNoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptionsYesNo & { message: string }
  ) {
    data.message = data.message.replace(/\n/g, '<br/>');
    if (data.title) {
      data.title = data.title.replace(/\n/g, '<br/>');
    }
    if (!data.textAlign) {
      data.textAlign = 'left';
    }
    if (!data.fontSize) {
      data.fontSize = '100%';
    } else {
      this.buttonPadding = '30px';
    }
  }

  yes() {
    this.dialogRef.close(true);
  }

  no() {
    this.dialogRef.close(false);
  }

  ngOnInit(): void {

  }
}
