import {Component, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../services/socket/socket.service';
import {NxtPaymentPossibilityRecord} from '../../common-interfaces/nxt.payment-possibility-record.interface';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {IconTools} from '../../common-browser/helpers/icon.tools';
import {DialogService} from '../../services/dialog.service';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';

@Component({
    selector: 'nxt-print-gift-cards',
    templateUrl: './print-gift-cards.component.html',
    styleUrls: ['./print-gift-cards.component.scss'],
    imports: [NxtDatagridComponent]
})

export class PrintGiftCardsComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  giftCards: NxtPaymentPossibilityRecord[];
  columnDefs: NxtColDef<NxtPaymentPossibilityRecord>[] = [
    {headerName: 'ID', field: 'internalId'},
    {headerName: 'Code', field: 'name'},
    {headerName: 'Bestellt', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDate},
    {headerName: 'Betrag', field: 'value', nxtFieldType: NxtFieldType.Money},
    {
      headerName: 'Name', valueGetter: (data) => {
        return ''; // data.data.shopOrder._billing_first_name + ' ' + data.data.shopOrder._billing_last_name;
      }
    },
    {
      headerName: 'Drucken', valueGetter: (data) => {
        return IconTools.Material.Print;
      }, cellStyle: {textAlign: 'center'}, nxtOnCellClicked: (data) => this.print(data.data)
    },
  ];

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit() {
    this.load();
  }

  nxtOnDestroy() {

  }

  async load() {
    this.giftCards = await this.socketService.getWhatsApp20();
  }

  private async print(giftCard: NxtPaymentPossibilityRecord) {
    /*this.dialogService.showLoading(LoadingId.Printing, 'Drucke Gutschein');
    const text = 'Gutscheincode\n' + giftCard.name +
      '\n\nWert:           ' + giftCard.value.toMoneyString() +
      '\nDatum:        ' + giftCard.createdAt.dateFormat('dd.MM.yyyy') +
      '\nGültig bis:   ' + giftCard.validTill.dateFormat('dd.MM.yyyy') +
      '\nNr:                ' + giftCard.internalId +
      '\n\nNicht auf Tattoos mit\neinem Aktionspreise einzulösen';
    await this.socketService.printSmallText({text, studio: 'Backoffice'});
    await TimeTools.sleep(500);

    const textSender = 'NXT-LVL.INK - Wallstraße 62 - 52064 Aachen';
    await this.socketService.printSmallText({text: textSender, studio: 'Backoffice', orientation: 'standard', font_size: 32, noCut: true, margin_bottom: 1});

    const textAddress = giftCard.shopOrder._billing_first_name + ' ' + giftCard.shopOrder._billing_last_name +
      '\n' + giftCard.shopOrder._billing_address_1 +
      '\n' + giftCard.shopOrder._billing_postcode + ' ' + giftCard.shopOrder._billing_city + '\n';
    await this.socketService.printSmallText({text: textAddress, studio: 'Backoffice', orientation: 'standard', font_size: 60, noCut: false, margin_top: 1});


    this.dialogService.hideLoading(LoadingId.Printing);*/
  }
}
