@if (employeeEvent(); as employeeEvent) {
  <div class="flex flex-col p-5 text-xl tooltip">
    <div class="flex flex-row justify-center text-center">
      <div>{{ employeeEvent.type | nxtEmployeeEventType }}</div>
      @if (employeeEvent.studio) {
        <div>&nbsp;in&nbsp;{{ employeeEvent.studio | nxtStudio }}</div>
      }
    </div>
    @if (employeeEvent.type === 'work') {
      <div>{{ WorkplaceTools.getText(employeeEvent.workplaces) }}</div>
    }
    <div>{{ employee().abbreviation }} - {{ employee().fullName }}</div>
    @if (employeeEvent.end) {
      <div>{{ employeeEvent.start | nxtTime: true }} - {{ employeeEvent.end | nxtTime: true }} Uhr</div>
    } @else {
      <div>läuft seit {{ employeeEvent.start | nxtDate: 'HH:mm' }}</div>
    }
    <div>Dauer: {{ duration() | duration: 'HH:mm' }}</div>
    @if (employeeEvent.info) {
      <div>Info: {{ employeeEvent.info }}</div>
    }

    <div class="flex flex-col">
      @if (employeeEvent.infos) {
        @for (info of employeeEvent.infos; track info) {
          <div>{{ info }}</div>
        }
      }
    </div>

  </div>
}

