import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {NxtArtistSkills} from '../../../common-interfaces/nxt.artist.interface';
import {NgFor} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';


@Component({
    selector: 'nxt-star-cell-renderer',
    template: `
    <div fxLayout="column">
      <table style="margin-bottom: 10px;">
        <tr *ngFor="let skillValue of skills.skillValues">
          <td>
            {{skillValue.description}}
          </td>
          <td>
            <div [innerHTML]="getStars(skillValue.value)"></div>
          </td>
        </tr>
      </table>
    </div>
  `,
    styles: [`
    table{
      margin-bottom: 10px;
    }
    .wrapper {
      height: 22px;
    }
  `],
    imports: [FlexModule, NgFor]
})
export class ArtistSkillCellRenderer implements ICellRendererAngularComp {

  skills: NxtArtistSkills;

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.skills = params.value as NxtArtistSkills;
  }

  refresh(params: ICellRendererParams): boolean {
    console.log('');
    return false;
  }

  getStars(value: number) {
    let starsHtml = '';
    for (let i = 0; i < 5; i++) {
      if ((value / 2) >= i + 1) {
        starsHtml += '<img src="/assets/images/grid-cell-renderer/star.svg"/>';
      } else if ((value / 2) >= i + 0.5) {
        starsHtml += '<img src="/assets/images/grid-cell-renderer/star_half.svg"/>';
      } else {
        starsHtml += '<img src="/assets/images/grid-cell-renderer/star_border.svg"/>';
      }
    }
    return starsHtml;
  }
}
