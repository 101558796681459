<div fxLayout="column" style="width:100%; height:100%" fxLayoutAlign="fill">

  <div fxLayout="row" fxFlex>
    <div class="button-wrapper">
      <nxt-button (click)="playClip('capone_1.mp4')">Capone<br/>Lacher</nxt-button>
    </div>
    <div class="button-wrapper">
      <nxt-button (click)="playClip('capone_2.mp4')">Capone<br/>Haps</nxt-button>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="button-wrapper">
      <nxt-button (click)="playClip('jule_hitler.mp4')">Jule<br/>Hitler</nxt-button>
    </div>
    <div class="button-wrapper">
      <nxt-button (click)="playClip('jule_deso_dogg.mp4')">Jule<br/>Deso Dogg</nxt-button>
    </div>
    <div class="button-wrapper">
      <nxt-button (click)="playClip('jule_haspler.mp4')">Jule<br/>Haspler</nxt-button>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="button-wrapper">
      <nxt-button (click)="playClip('niklas_knutscher.mp4')">Niklas<br/>Knutscher</nxt-button>
    </div>
    <div class="button-wrapper">
      <nxt-button (click)="playClip('niklas_spritzen.mp4')">Niklas<br/>Spritzen</nxt-button>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="button-wrapper">
      <nxt-button (click)="playClip('timey_brokkoli.mp4')">Timey<br/>Brokkoli</nxt-button>
    </div>
    <div class="button-wrapper">
      <nxt-button (click)="playClip('timey_discusting.mp4')">Timey<br/>Discusting</nxt-button>
    </div>
    <div class="button-wrapper">
      <nxt-button (click)="playClip('timey_lacher.mp4')">Timey<br/>Lacher</nxt-button>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="button-wrapper">
      <nxt-button (click)="playClip('twin_hessen.mp4')">Twin<br/>Hessen</nxt-button>
    </div>
    <div class="button-wrapper">
      <nxt-button (click)="playClip('twin_couch.mp4')">Twin<br/>Couch</nxt-button>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="button-wrapper">
      <nxt-button (click)="playClip('shayan_lacher.mp4')">Shayan<br/>Lacher</nxt-button>
    </div>
  </div>
</div>
