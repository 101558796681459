import {Component, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {ActivatedRoute} from '@angular/router';
import {ConfigService} from '../../../services/config.service';
import {JsonTools} from '../../../common-browser/helpers/json.tools';
import {DeclarationsOfConsentCheckboxComponent} from '../declarations-of-consent-checkbox/declarations-of-consent-checkbox.component';
import {DeclarationsOfConsentSectionComponent} from '../declarations-of-consent-section/declarations-of-consent-section.component';
import {NgIf} from '@angular/common';
import {TranslateDirective} from '../../../directives/translate.directive';

@Component({
  selector: 'nxt-declarations-of-consent-piercing',
  templateUrl: './declarations-of-consent-piercing.component.html',
  styleUrls: ['./declarations-of-consent-piercing.component.scss'],
  imports: [NgIf, DeclarationsOfConsentSectionComponent, DeclarationsOfConsentCheckboxComponent, TranslateDirective],
})

export class DeclarationsOfConsentPiercingComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  pageNumberCurrent = 2;
  pageNumberTotal = 2;
  signatureName = '';
  artistEmployed = false;
  lang = 'de';

  constructor(
    public configService: ConfigService,
    private route: ActivatedRoute,
  ) {
    super();
    if (this.route.snapshot.queryParams.data) {
      const data = JsonTools.parse(this.route.snapshot.queryParams.data);
      this.pageNumberCurrent = data.page;
      this.lang = data.lang;
      if (typeof data.artistEmployed === 'boolean') {
        this.artistEmployed = data.artistEmployed;
      }
    }
    this.calc();
  }

  ngOnInit() {
    window.document.body.style.backgroundColor = 'transparent';
  }

  calc() {
    if ([0, 1, 2].includes(this.pageNumberCurrent)) {
      this.signatureName = 'Deine Unterschrift';
    } else if (this.pageNumberCurrent === 3) {
      this.signatureName = 'Unterschrift Erziehungsberechtigte(r)';
    }
  }

  nxtOnDestroy() {

  }
}
