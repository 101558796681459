<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>Mitarbeiter</nxt-page-header-title>
    <nxt-button (click)="newEmployeeClicked()">Neuer Mitarbeiter</nxt-button>
    <nxt-input [noPadding]="true" [(value)]="quickFilterText" placeholder="Suche" [clearIcon]="true"></nxt-input>
    <nxt-slide-toggle [(value)]="showOldEmployees" class="whitespace-nowrap">Alte Mitarbeiter</nxt-slide-toggle>
  </nxt-page-header>
  <nxt-page-content>
    <nxt-datagrid [columnDefs]="columnDefs" [rowData]="employeesFiltered()" [quickFilterText]="quickFilterText()"></nxt-datagrid>
  </nxt-page-content>
</nxt-page>
