/// <reference types="@types/googlemaps" />
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DialogService} from '../../services/dialog.service';
import {SocketService} from '../../services/socket/socket.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NxtFormControl} from '../../nxt-form/nxt.form-control';
import {GooglePlaceService} from '../../services/google-place.service';
import {CityTools} from '../../common-browser/helpers/city.tools';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {AutocompleteComponent} from '../../components/form-controls/autocomplete/autocomplete.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgStyle} from '@angular/common';
import {NxtCardDividerDirective} from '../../controls/nxt/card-divider/nxt-card-divider.directive';
import {InputComponent} from '../../components/form-controls/input/input.component';
import {NxtCardDirective} from '../../controls/nxt/card-divider/nxt-card.directive';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {WindowTools} from '../../common-browser/window.tools';
import {ValidatorTools} from '../../helpers/validator.tools';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;

@Component({
    selector: 'nxt-new-contact',
    templateUrl: './new-contact.component.html',
    styleUrls: ['./new-contact.component.scss'],
    imports: [FlexModule, NxtCardDirective, InputComponent, NxtCardDividerDirective, NgStyle, ExtendedModule, AutocompleteComponent, NxtButtonComponent]
})
export class NewContactComponent implements OnInit {
  @ViewChild('page', {static: true}) page: ElementRef;
  @ViewChild('placesContainer', {static: true}) placesContainer: ElementRef;
  @ViewChild('houseNumber', {static: true}) houseNumber: ElementRef;
  placeService: any; // google.maps.places.PlacesService;

  form: UntypedFormGroup;
  cities: string[];
  searchText = '';
  searchFormControl = new NxtFormControl('');
  private suggestions: string[];
  private height: number;
  private width: number;
  private posBottom: string;
  private lastPlaceId = '';
  public showPostalCode = false;

  streetDisplayInOptionWith(value, highlight: (string) => string) {
    return highlight(value.description.replace(', Deutschland', ''));
  }

  streetDisplayWith = (value: AutocompletePrediction) => {

    if (value && value.structured_formatting && value.structured_formatting.main_text) {
      this.autoPostalCode(value.place_id);
      return value.structured_formatting.main_text;
    }
    return '??';
  };

  test2() {
    this.googlePlaceService.getPostalCode('EiFKYWtvYnN0cmHDn2UsIEFhY2hlbiwgRGV1dHNjaGxhbmQiLiosChQKEgkBSnx1fpnARxHqWtICxFOQjhIUChIJAUK8it1RqEcRwKtfW0YgIQU').then((result) => {
      console.log(result);
    });
  }


  filterService = async (value: string): Promise<string[]> => {
    const searchText = this.form.get('city').value + ', ' + value;
    const list = await this.googlePlaceService.find(searchText);
    if (list) {
      return list;
    } else {
      return [];
    }
  };

  // formValues: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogService: DialogService,
    private socketService: SocketService,
    private googlePlaceService: GooglePlaceService
  ) {


  }


  tempRawData;

  get cookies() {
    return window.document.cookie;
  }

  async ngOnInit() {
    this.initForm();


    setInterval(() => {
      this.height = window.innerHeight;
      this.width = window.innerWidth;

      if (window.innerWidth > window.innerHeight) {
        // this.page.nativeElement.style.bottom = '0px';
      } else {
        // this.page.nativeElement.style.bottom = '0px';
      }
    }, 1000);

    this.cities = CityTools.getCities();

    this.placeService = new google.maps.places.AutocompleteService();

  }

  initForm() {
    this.form = this.formBuilder.group({
      givenName: new NxtFormControl('', [ValidatorTools.requiredAndNotNaN]),
      familyName: new NxtFormControl('', [ValidatorTools.requiredAndNotNaN]),
      email: new NxtFormControl(''),
      mobile: new NxtFormControl('', [ValidatorTools.requiredAndNotNaN]),
      city: new NxtFormControl('', [ValidatorTools.requiredAndNotNaN]),
      postalCode: new NxtFormControl('', [ValidatorTools.requiredAndNotNaN]),
      streetAddress: new NxtFormControl('', [ValidatorTools.requiredAndNotNaN]),
      houseNumber: new NxtFormControl('', [ValidatorTools.requiredAndNotNaN])
    });

    this.form.valueChanges.subscribe(() => {
      this.tempRawData = this.form.getRawValue();
      const rawValues = this.form.getRawValue();

      if (this.form.get('givenName').value) {
        rawValues.streetAddress = this.streetDisplayWith(rawValues.streetAddress);
        this.socketService.setNewContactData(rawValues);
      }
    });
  }

  public test() {
    this.placeService.getPlacePredictions({
        types: ['address'],
        input: 'Würselen Lindenstraße 3',
        componentRestrictions: {country: 'DE'}
      },
      (results) => {
        this.suggestions = results.map(r => r.description);
      });
  }

  clickInput(name: string) {
    (this.form.get(name) as NxtFormControl).element.click();
  }


  private async autoPostalCode(place_id: string) {

    if (this.lastPlaceId !== place_id) {
      this.lastPlaceId = place_id;
      const result = await this.googlePlaceService.getPostalCode(place_id);
      this.form.get('postalCode').setValue(result);
      this.showPostalCode = true;
    }
  }

  async finished() {
    let text = this.form.get('givenName').value + ' ' + this.form.get('familyName').value;
    text += '\n' + this.streetDisplayWith(this.form.get('streetAddress').value) + ' ' + this.form.get('houseNumber').value;
    text += '\n' + this.form.get('postalCode').value + ' ' + this.form.get('city').value;
    text += '\nHandy: ' + this.form.get('mobile').value;

    const result = await this.dialogService.showYesNo(text, {yesText: 'Alles richtig, fertig', noText: 'korrigieren'});
    if (result) {
      WindowTools.reload('finished');
      // this.form.reset();
      // this.initForm();
      // this.form.get('givenName').di.dirty = false;


      // window.location.reload();
      // this.form.setValue({givenName: '', familyName: '', streetAddress: '', houseNumber: '', postalCode: '', city: '', mobile: '', email: ''});
    }
  }
}
