export class LanguageTools {

  static get nxtUsed(): { name: string, code: string }[] {
    if (LanguageTools._nxtUsed.length === 0) {
      for (const nxtUsedCode of LanguageTools.nxtUsedCodes) {
        const use = LanguageTools.languageCodes.find(c => c.code === nxtUsedCode);
        if (use) {
          LanguageTools._nxtUsed.push(use);
        }
      }
    }
    return LanguageTools._nxtUsed;
  }

  private static nxtUsedCodes: string[] = ['de', 'en', 'nl', 'es', 'fr'];

  private static _nxtUsed: { name: string, code: string }[] = [];

  static countryLangMap: { [countryCode: string]: string } = {
    DE: 'de',
    FR: 'fr',
    IT: 'it',
    ES: 'es',
    PT: 'pt',
    NL: 'nl',
    BE: 'nl',
    GR: 'el',
    RU: 'ru',
    JP: 'ja',
    CN: 'zh',
    KR: 'ko',
    SA: 'ar',
    TR: 'tr',
    IN: 'hi',
    BR: 'pt',
    AR: 'es',
    MX: 'es',
    CA: 'en',
    US: 'en',
    GB: 'en',
    AU: 'en',
    NZ: 'en',
    IE: 'en',
    ZA: 'af',
    EG: 'ar',
    MA: 'ar',
    DZ: 'ar',
    TN: 'ar',
    LY: 'ar',
    JO: 'ar',
    SY: 'ar',
    IQ: 'ar',
    KW: 'ar',
    AE: 'ar',
    QA: 'ar',
    OM: 'ar',
    BH: 'ar',
    YE: 'ar',
    LB: 'ar',
    IL: 'he',
    IR: 'fa',
    AF: 'ps',
    PK: 'ur',
    BD: 'bn',
    MY: 'ms',
    ID: 'id',
    PH: 'tl',
    TH: 'th',
    KH: 'km',
    VN: 'vi',
    MM: 'my',
    SG: 'en',
    HK: 'zh',
    TW: 'zh',
    PL: 'pl',
    HU: 'hu',
    CZ: 'cs',
    SK: 'sk',
    AT: 'de',
    CH: 'de',
    SE: 'sv',
    NO: 'no',
    DK: 'da',
    FI: 'fi',
    IS: 'is',
    MT: 'mt',
    CY: 'el',
    RO: 'ro',
    BG: 'bg',
    HR: 'hr',
    SI: 'sl',
    BA: 'bs',
    ME: 'sr',
    MK: 'mk',
    RS: 'sr',
    UA: 'uk',
    BY: 'be',
    MD: 'ro',
    LT: 'lt',
    LV: 'lv',
    EE: 'et',
  };


  static languageCodes: { name: string, code: string }[] = [
    {name: 'Afrikaans', code: 'af'},
    {name: 'Albanisch', code: 'sq'},
    {name: 'Amharisch', code: 'am'},
    {name: 'Arabisch', code: 'ar'},
    {name: 'Armenisch', code: 'hy'},
    {name: 'Assamesisch', code: 'as'},
    {name: 'Aymara', code: 'ay'},
    {name: 'Aserbaidschanisch', code: 'az'},
    {name: 'Bambara', code: 'bm'},
    {name: 'Baskisch', code: 'eu'},
    {name: 'Belarussisch', code: 'be'},
    {name: 'Bengalisch', code: 'bn'},
    {name: 'Bhojpuri', code: 'bho'},
    {name: 'Bosnisch', code: 'bs'},
    {name: 'Bulgarisch', code: 'bg'},
    {name: 'Katalanisch', code: 'ca'},
    {name: 'Cebuano', code: 'ceb'},
    {name: 'Chinesisch (vereinfacht)', code: 'zh-CN oder zh (BCP-47)'},
    {name: 'Chinesisch (traditionell)', code: 'zh-TW (BCP-47)'},
    {name: 'Korsisch', code: 'co'},
    {name: 'Kroatisch', code: 'hr'},
    {name: 'Tschechisch', code: 'cs'},
    {name: 'Dänisch', code: 'da'},
    {name: 'Dhivehi', code: 'dv'},
    {name: 'Dogri', code: 'doi'},
    {name: 'Niederländisch', code: 'nl'},
    {name: 'Englisch', code: 'en'},
    {name: 'Esperanto', code: 'eo'},
    {name: 'Estnisch', code: 'et'},
    {name: 'Ewe', code: 'ee'},
    {name: 'Philippinisch (Tagalog)', code: 'fil'},
    {name: 'Finnisch', code: 'fi'},
    {name: 'Französisch', code: 'fr'},
    {name: 'Friesisch', code: 'fy'},
    {name: 'Galicisch', code: 'gl'},
    {name: 'Georgisch', code: 'ka'},
    {name: 'Deutsch', code: 'de'},
    {name: 'Griechisch', code: 'el'},
    {name: 'Guarani', code: 'gn'},
    {name: 'Gujarati', code: 'gu'},
    {name: 'Kreolisch (Haiti)', code: 'ht'},
    {name: 'Haussa', code: 'ha'},
    {name: 'Hawaiianisch', code: 'haw'},
    {name: 'Hebräisch', code: 'he oder iw'},
    {name: 'Hindi', code: 'hi'},
    {name: 'Hmong', code: 'hmn'},
    {name: 'Ungarisch', code: 'hu'},
    {name: 'Isländisch', code: 'is'},
    {name: 'Igbo', code: 'ig'},
    {name: 'Ilokano', code: 'ilo'},
    {name: 'Indonesisch', code: 'id'},
    {name: 'Irisch', code: 'ga'},
    {name: 'Italienisch', code: 'it'},
    {name: 'Japanisch', code: 'ja'},
    {name: 'Javanisch', code: 'jv oder jw'},
    {name: 'Kannada', code: 'kn'},
    {name: 'Kasachisch', code: 'kk'},
    {name: 'Khmer', code: 'km'},
    {name: 'Kinyarwanda', code: 'rw'},
    {name: 'Konkani', code: 'gom'},
    {name: 'Koreanisch', code: 'ko'},
    {name: 'Krio', code: 'kri'},
    {name: 'Kurdisch', code: 'ku'},
    {name: 'Kurdisch (Sorani)', code: 'ckb'},
    {name: 'Kirgisisch', code: 'ky'},
    {name: 'Lao', code: 'lo'},
    {name: 'Latein', code: 'la'},
    {name: 'Lettisch', code: 'lv'},
    {name: 'Lingala', code: 'ln'},
    {name: 'Litauisch', code: 'lt'},
    {name: 'Luganda', code: 'lg'},
    {name: 'Luxemburgisch', code: 'lb'},
    {name: 'Mazedonisch', code: 'mk'},
    {name: 'Maithili', code: 'mai'},
    {name: 'Malagasy', code: 'mg'},
    {name: 'Malaiisch', code: 'ms'},
    {name: 'Malayalam', code: 'ml'},
    {name: 'Maltesisch', code: 'mt'},
    {name: 'Maori', code: 'mi'},
    {name: 'Marathi', code: 'mr'},
    {name: 'Meitei (Manipuri)', code: 'mni-Mtei'},
    {name: 'Mizo', code: 'lus'},
    {name: 'Mongolisch', code: 'mn'},
    {name: 'Myanmar (Birmanisch)', code: 'my'},
    {name: 'Nepalesisch', code: 'ne'},
    {name: 'Norwegisch', code: 'no'},
    {name: 'Nyanja (Chichewa)', code: 'ny'},
    {name: 'Odia (Oriya)', code: 'or'},
    {name: 'Oromo', code: 'om'},
    {name: 'Paschtunisch', code: 'ps'},
    {name: 'Persisch', code: 'fa'},
    {name: 'Polnisch', code: 'pl'},
    {name: 'Portugiesisch (Portugal, Brasilien)', code: 'pt'},
    {name: 'Panjabi', code: 'pa'},
    {name: 'Quechua', code: 'qu'},
    {name: 'Rumänisch', code: 'ro'},
    {name: 'Russisch', code: 'ru'},
    {name: 'Samoanisch', code: 'sm'},
    {name: 'Sanskrit', code: 'sa'},
    {name: 'Schottisches Gälisch', code: 'gd'},
    {name: 'Sepedi', code: 'nso'},
    {name: 'Serbisch', code: 'sr'},
    {name: 'Sesotho', code: 'st'},
    {name: 'Shona', code: 'sn'},
    {name: 'Sindhi', code: 'sd'},
    {name: 'Singhalesisch', code: 'si'},
    {name: 'Slowakisch', code: 'sk'},
    {name: 'Slowenisch', code: 'sl'},
    {name: 'Somali', code: 'so'},
    {name: 'Spanisch', code: 'es'},
    {name: 'Sundanesisch', code: 'su'},
    {name: 'Swahili', code: 'sw'},
    {name: 'Schwedisch', code: 'sv'},
    {name: 'Tagalog (Philippinisch)', code: 'tl'},
    {name: 'Tadschikisch', code: 'tg'},
    {name: 'Tamil', code: 'ta'},
    {name: 'Tatarisch', code: 'tt'},
    {name: 'Telugu', code: 'te'},
    {name: 'Thai', code: 'th'},
    {name: 'Tigrinya', code: 'ti'},
    {name: 'Tsonga', code: 'ts'},
    {name: 'Türkisch', code: 'tr'},
    {name: 'Turkmenisch', code: 'tk'},
    {name: 'Twi (Akan)', code: 'ak'},
    {name: 'Ukrainisch', code: 'uk'},
    {name: 'Urdu', code: 'ur'},
    {name: 'Uigurisch', code: 'ug'},
    {name: 'Usbekisch', code: 'uz'},
    {name: 'Vietnamesisch', code: 'vi'},
    {name: 'Walisisch', code: 'cy'},
    {name: 'Xhosa', code: 'xh'},
    {name: 'Jiddisch', code: 'yi'},
    {name: 'Yoruba', code: 'yo'},
    {name: 'Zulu', code: 'zu'}
  ];

  static getTextByCode(code: string) {
    return LanguageTools.languageCodes.find(l => l.code === code)?.name;
  }

  static getCodeByText(text: string) {
    return LanguageTools.languageCodes.find(l => l.name === text)?.code;
  }

  static getLanguageFromCountry(countryCode: string) {
    const result = LanguageTools.countryLangMap[countryCode];
    if (!result) {
      throw Error('Sprache zum Land "' + countryCode + '" nicht gefunden');
    }
    return result;
  }
}
