<div class="w-full wrapper nxt-page" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="center center" fxLayout="row">
  <div fxFlex="20%" fxShow.lt-sm="false" class="text-120"></div>
  <div fxFlex fxLayoutAlign.lt-sm="center center" class="transition-opacity duration-500 opacity-0" [style.opacity]="loginService.showLoginDialog ? 1 : 0">
    <div fxFlex.lt-sm="90" fxFlex.gt-xs="350px" fxLayout="column">
      <div class="logo-wrapper" fxLayoutAlign="center" fxLayout="row">
        <img fxFlex="90" [nxtLongPress]="1000" (nxtOnLongPressed)="logoLongPressed()" *ngIf="logoSrc" fxFlex.lt-sm="80" style=" padding-bottom: 6vh"
             [src]="logoSrc"/>
      </div>
      <div>
        <div fxLayout="column" fxLayoutAlign="center">
          <nxt-input #inputUsername placeholder="Benutzer" [(value)]="username" (valueChange)="usernameChanged()"></nxt-input>
          <nxt-input #inputStudio [readonly]="true" placeholder="Kasse" [value]="studio" (inputClick)="askStudio()"></nxt-input>
          <nxt-input #inputWorkplace [readonly]="true" placeholder="Arbeitsplatz" [value]="workPlaceTools.getText(workplace)"
                     (inputClick)="askWorkplace()"></nxt-input>
          <ng-container *ngIf="!setNewPassword">
            <nxt-input #inputPassword placeholder="Passwort" [(value)]="password" [type]="showPassword ? 'text' : 'password'"
                       (onFocus)="onFocusPassword()"></nxt-input>
            <nxt-slide-toggle [(value)]="showPassword">Passwort anzeigen</nxt-slide-toggle>
          </ng-container>
          <ng-container *ngIf="setNewPassword">
            <nxt-input #inputPassword placeholder="altes Passwort" [(value)]="password" type="password"></nxt-input>
            <nxt-input #inputPassword placeholder="neues Passwort" [validators]="passwordValidator" [(value)]="newPassword1" type="password"
                       (valueChange)="checkCanChangePassword()"></nxt-input>
            <nxt-input #inputPassword placeholder="neues Passwort wiederholen" [validators]="passwordValidator" [(value)]="newPassword2" type="password"
                       (valueChange)="checkCanChangePassword()"></nxt-input>
          </ng-container>

          <br/>
          <div style="max-width: 300px" *ngIf="errorMessage" class="error-message">
            <div [innerHTML]="errorMessage| safeHtml"></div>
          </div>
          <br/>
          <nxt-row>
            <nxt-col align="center" fxFlex="">
              <nxt-button [disabled]="disableLoginButton || loginRunning" #loginButton style="margin: auto;" (click)="login(true)">{{ loginButtonText }}
              </nxt-button>
              <!--<button #loginButton style="margin: auto;" mat-raised-button color="primary" (click)="login()">{{loginButtonText}}</button>-->
            </nxt-col>
          </nxt-row>
        </div>
      </div>
    </div>
  </div>
</div>
