import {NxtDbFields} from '../nxt.db-fields.interface';
import {NxtQontoTransactionRaw} from '../qonto.interface';
import {NxtPaypalTransactionRaw} from '../paypal/paypal-transaction-raw.interface';
import {GlobalStudioShortName} from '../global-studio.interface';
import {CreditCardTransactionRaw} from '../credit-card-CardTransaction-raw.interface';
import {GetMyInvoiceDocument} from '../get-my-invoices.interface';
import {NxtCashPayment} from '../nxt.cash-payment.interface';
import {KlarnaOrderRaw, KlarnaRawRefund} from '../klarna-raw.interface';
import {KlarnaApi} from '../klarna-api-interfaces';
import {NxtPayment} from '../nxt.payment.interface';

export interface NxtBankDatevOpos {
  Konto: string;
  Kontobezeichnung: string;
  RechnungsNr: string;
  Datum: string;
  Faelligkeit: string;
  Beleg: string;
  BelegNr: string;
  Gegenkonto: string;
  KZN: string;
  WKZ: string;
  Eingabebetrag: string;
  OffenePostenSoll: number;
  OffenePostenHaben: number;
  Saldo: string;
  S_H: string;
  Faellig_in_seit_Tagen: string;
  Buchungstext: string;
}


export interface NxtMultiImportRunnerStatus {
  isRunning: boolean;
  progress: number;
  errorMessage: string;
  statusText: string;
  log: string[];
}

export interface NxtBankDocumentNxtAiInvoice {
  numbers: string[];
  noNumberOk?: boolean;
  valueGross: number;
  currency: string;
  // valueNet: number;
}

export interface NxtBankDocumentNxtAiWithInvoice extends NxtBankDocumentNxtAi {
  invoice: NxtBankDocumentNxtAiInvoice;
}

export interface NxtBankDocumentManualChange {
  by: string;
  at: number;
  changes: { key: NxtBankDocumentChangeKey, keyText: string, oldValue: string | number, newValue: string | number }[];
}

export interface NxtBankDocumentNxtAi {
  invoice?: NxtBankDocumentNxtAiInvoice;
  receiverCompany: {
    name: string;
    datevNo: string;
  };
  senderCompany: {
    name: string;
    datevNo: string;
  };
  manualChanges?: NxtBankDocumentManualChange[];
  klarnaSettlement?: { company: string; name: string; dateString: string; merchantId: string };
  spkMonthStatement?: { company: string; name: string; monthString: string; accountIban: string };
  paypalFee?: { company: string; name: string; monthString: string; accountId: string };
}

export type NxtBankDocumentRawType = 'gmi-document' | 'none' | 'mail';

export interface NxtBankDocumentWithAi extends NxtBankDocument {
  nxtAi: NxtBankDocumentNxtAi;
}

export interface NxtBankDocumentWithInvoice extends NxtBankDocument {
  nxtAi: NxtBankDocumentNxtAiWithInvoice;
}


export interface NxtBankDocumentDocumentAiEntity {
  textAnchor?: {
    textSegments: {
      startIndex: string;
      endIndex: string;
    }[];
    content?: string;
  };
  type: string;
  mentionText?: string;
  confidence: number;
  pageAnchor?: {
    pageRefs: {
      page?: string;
      boundingPoly?: {
        normalizedVertices: {
          x: number;
          y: number;
        }[];
      };
    }[];
  };
  id: string;
  normalizedValue?: {
    text: string;
    dateValue?: {
      year?: number;
      month?: number;
      day?: number;
    };
    datetimeValue?: {
      year?: number;
      month?: number;
      day?: number;
      hours?: number;
      minutes?: number;
    };
  };
  properties?: {
    textAnchor?: {
      textSegments: {
        startIndex: string;
        endIndex: string;
      }[];
      content?: string;
    };
    type: string;
    mentionText?: string;
    confidence: number;
    pageAnchor?: {
      pageRefs: {
        page?: string;
        boundingPoly?: {
          normalizedVertices: {
            x: number;
            y: number;
          }[];
        };
      }[];
    };
    id: string;
    normalizedValue?: {
      text: string;
    };
  }[];
}

export enum NxtBankDocumentType {
  Invoice = 'invoice',
  DeliveryNote = 'delivery-note',
  Receipt = 'receipt',
  NoInvoice = 'no-invoice',
  Unknown = 'unknown',
  InvoiceShouldSplit = 'invoice-should-split',
  SpkMonthStatement = 'spk-month-statement',
  PaypalFee = 'paypal-fee',
  Unset = 'unset',
  KlarnaSettlementReport = 'klarna-settlement-report',
}


export enum NxtBankDocumentStatus {
  _00_download = '00_download',
  _02_ocr = '02_ocr',
  _04_documentType = '04_documentType',
  _05_checkOwnCompany = '05_checkOwnCompany',
  _06_documentAi = '06_documentAi',
  _08_split = '08_split',
  _10_nxtAi = '10_nxtAi',
  _12_checkDuplicates = '12_checkDuplicates',
  _14_merge = '14_merge',
  _20_spk_month_statement = '20_spk_month_statement',
  _21_klarna_settlement = '21_klarna_settlement',
  _22_paypal_fee = '22_paypal_fee',
  _30_transaction = '30_transaction',
  _90_ok = '90_ok',
  // _99_error = '99_error',

}

export enum NxtBankDocumentChangeKey {
  DocumentDate = 'documentDate',
  DocumentType = 'documentType',
  SenderCompany = 'senderCompany',
  ValueGross = 'valueGross',
  DocumentNumber = 'documentNumber',
  InvoiceNoNumberOk = 'invoiceNoNumberOk',
  InvoiceCurrency = 'invoiceCurrency',
  ReceiverCompany = 'receiverCompany',
  OwnCompany = 'ownCompany',
  Status = 'status',
  AssignValueOk = 'assignValueOk',
  OwnCompanyNotRequired = 'ownCompanyNotRequired',
}

export interface NxtBankDocument extends NxtDbFields {
  status: NxtBankDocumentStatus;
  statusMessage?: string;
  // errorMessage?: string;
  nxtAi?: NxtBankDocumentNxtAi;
  documentType: NxtBankDocumentType;
  rawType: NxtBankDocumentRawType;
  rawId: string;
  rawObj: GetMyInvoiceDocument | any | null;
  documentAi?: { pages: any[]; entities: NxtBankDocumentDocumentAiEntity[] };
  documentNumber: string;
  documentDate: number;
  documentDateTime: number;
  documentDateString: string;
  filename: string;
  driveFileId: string;
  direction: 'in' | 'out';
  assignedTransactions: { id: string; value: number }[];
  assignValue: number | null;
  shortId: string;
  datevExportedAt?: number;
  datevExportId?: string;
  ownCompany: string;
  nxtManual?: {
    ownCompanyNotRequired?: boolean;
    assignValueOk?: boolean;
  };
  source: string;
}

export interface NxtBankConfigShop {
  studio: GlobalStudioShortName;
  consumerKey: string;
  consumerSecret: string;
  url: string;
}

export type NxtBankConfigAccountType = 'spk-credit-card' | 'spk-credit-card-transfer' | 'klarna' | 'paypal' | 'cash-register' | 'qonto' | 'spk' | 'datev-account';

export interface NxtBankConfigAccount {
  id: string;
  iban: string;
  number: string;
  limit: number;
  name: string;
  company: string;
  bankCode: string;
  country: string;
  datevNo: string;
  creditCardTransferAccountId: string;
  bankTransferAccountId: string;
  type: NxtBankConfigAccountType;
  name2?: string;
  customerPayments?: boolean;
  main?: boolean;
}

export interface NxtBankConfigAccountLive extends NxtBankConfigAccount {
  lastSyncedAt: number;
  saldo: number;
  unbooked: { inDays: number; value: number; dateString: string, ok: boolean }[];
}

export interface NxtBankTransactionOther {
  name: string;
  name2: string;
  number: string;
  iban: string;
  bic: null | string;
}

export interface NxtBankTransactionAi {
  // datevNo: string;
  needInvoice?: boolean;
  needTransaction?: boolean;
  studio?: GlobalStudioShortName | '';
  error?: string;
  datevOther?: NxtBankTransactionDatevOther;
  datevOtherSuggestion?: { company: string, description: string, category: string };
}

export interface NxtBankTransactionDatevOther {
  datevNo: string;
  name: string;
  name2?: string;
  // info?: string;
  accountId?: string;
}


export enum NxtBankTransactionType {
  InternalTransfer = 'internal-transfer',
  PaypalCredit = 'paypal-credit',
  Paypal = 'paypal',
  // Klarna = 'klarna',
  Loan = 'loan',
  Salary = 'salary',
  Tax = 'tax',
  ArtistCustomer = 'artist-customer',
  CustomerAr = 'customer-ar',
  Rent = 'rent',
  Revenue = 'revenue',
  SupplierAp = 'supplier-ap',
  HealthInsurance = 'health-insurance',
  Private = 'private',
  Error = 'error',
  Unknown = 'unknown',
  Unset = 'unset',
  QontoCredit = 'qonto-credit',
}

export type NxtBankTransactionRawType =
  'nxt-bank'
  | 'qonto'
  | 'paypal'
  | 'credit-card'
  | 'cash-payment'
  | 'paypal-fee'
  | 'klarna-order'
  | 'klarna-refund'
  | 'klarna-payout'
  | 'datev-opos'
  | 'event-payment'
  ;

/*export type NxtBankCashTransferType = 'cash-to-bank' | 'bank-to-cash' | 'cash-to-safe';

export interface NxtBankCashTransfer extends NxtDbFields {
  type: NxtBankCashTransferType;
  studio: GlobalStudioShortName;
  value: number;
  direction: 'in' | 'out';
  rawType: 'cash-payment';
  rawObj: NxtCashPayment;
  accountId: string;
  bankTransfer?: {
    transfers: { id: string; value: number; }[];
    missingValue: number;
    datevNo: string;
  }
}*/

export interface NxtBankTransactionWithAi extends NxtBankTransaction {
  nxtAi: NxtBankTransactionAi;
}

export enum NxtBankTransactionStatus {
  _01_nxtAi = '01_nxtAi',
  _03_delayed = '03_delayed',
  _05_transferMatch = '05_transferMatch',
  _07_documentMatch = '07_documentMatch',
  _90_ok = '90_ok',
  _99_error = '99_error',
}

export interface NxtBankTransaction extends NxtDbFields {
  statusMessage?: string;
  accountId: string;
  status: NxtBankTransactionStatus;
  other: NxtBankTransactionOther;
  company: string;
  newSaldo: number | null;
  bookDate: number;
  bookDateString: string;
  valueDate: number;
  valueDateString: string;
  value: number;
  currency: string;
  method: string;
  text: string;
  transactionText: string;
  type: NxtBankTransactionType;
  direction: 'in' | 'out';
  rawObj: NxtBankFintsTransactionRaw | NxtQontoTransactionRaw | NxtPaypalTransactionRaw | CreditCardTransactionRaw | NxtCashPayment | {
    id: string,
    value: number
  }[] | KlarnaOrderRaw | KlarnaRawRefund | KlarnaApi.Payout | NxtBankDatevOpos | NxtPayment;
  rawType: NxtBankTransactionRawType;
  // sortValue: number;
  bankCreatedAt: number;
  // articles: string[];
  /**
   * hier wird z.B. aus Paypal das Paypal-Konto und aus Klarna das Klarna-Konto, hier steht dann noch das originale drinnen
   */
  originalOther?: NxtBankTransactionOther;
  nxtAi?: NxtBankTransactionAi | null;
  nxtManual?: {
    nxtAi?: boolean;
    missingInvoice?: boolean;
    bankTransactionType?: NxtBankTransactionType;
  };
  assignValue: number;

  assignedTransactions: { id: string; value: number; accountId: string }[];
  assignedDocuments: { id: string; value: number; }[];
  delayed?: {
    by: number;
    to: number;
    at: number;
  };
  errorMessage?: string;
  nxtNotes?: { at: number, by: string, text: string }[];
  shortId: string;
}


export interface NxtBankFintsTransactionRaw extends NxtDbFields {
  booked: boolean;
  number: string;
  company: string;
  dateId: number;

  /*** Wertstellung ***/
  valuta: number;
  bdate: number;
  value: {
    curr: string;
    bigDecimalValue: number;
    doubleValue: number;
    longValue: number;
  };
  isStorno: boolean;
  saldo: {
    value: {
      curr: string;
      bigDecimalValue: number;
      doubleValue: number;
      longValue: number;
    };
    timestamp: number;
  };
  customerref: string;
  instref: string;
  orig_value: null | {
    curr: string;
    bigDecimalValue: number;
    doubleValue: number;
    longValue: number;
  };
  charge_value: null | {
    curr: string;
    bigDecimalValue: number;
    doubleValue: number;
    longValue: number;
  };
  gvcode: string;
  additional: null | string;
  text: string;
  primanota: string;
  usage: string[];
  other: {
    country: null | string;
    blz: string;
    number: string;
    subnumber: null | string;
    acctype: null | string;
    type: null | string;
    curr: string;
    customerid: null | string;
    name: string;
    name2: null | string;
    limit: null | number;
    allowedGVs: null | string[];
    bic: null | string;
    iban: string;
    creditorid: null | string;
    sepaaccount: boolean;
  };
  addkey: null | string;
  isSepa: boolean;
  isCamt: boolean;
  endToEndId: null | string;
  purposecode: null | string;
  mandateId: null | string;
}

export interface NxtBankCompany {
  name: string;
  name2?: string;
  datevNo: string;
  type: NxtBankTransactionType;
  ibans?: string[];
}

export interface NxtBankDatevExportLine {
  datum: string;
  konto: string;
  kontoname: string;
  gegenkonto: string;
  gegenkontoname: string;
  s_h: 'S' | 'H';
  betrag: string;
  waehrung: string;
  belegFeld1: string;
  // belegFeld2: string;
  // belegFeld3: string;
  // belegFeld4: string;
  // belegFeld5: string;
  // belegFeld6: string;
  buchungstext1: string;
  buchungstext2: string;
  belegLink: string;
  typ1: string;
  typ2: string;
  bs: string;
  accountId: string;
  shortId: string;
}

export type NxtBankBookingAccountCompany = 'GBX GmbH' | 'Krafft & Welsch GmbH & Co. KG' | 'Krafft & Welsch GmbH & Co KG Sucursal en España';

export interface NxtBankBookingAccount extends NxtDbFields {
  name: string;
  name2?: string;
  datevNo: string;
  info?: string;
  accountId?: string;
  findName?: NxtBankBookingAccountItemFind;
  invoiceLinks?: string[];
  findInTransactionText?: NxtBankBookingAccountItemFind;
  findInDocumentText?: NxtBankBookingAccountItemFind;
  ibans?: string[];
  company?: NxtBankBookingAccountCompany;
  type: NxtBankTransactionType;
}

export interface NxtBankBookingAccountItemFind {
  contains?: string[];
  equals?: string[];
  startsWith?: string[];
}

export interface BankTransactionLabel {
  id: string;
  name: string;
  children: BankTransactionLabel[];
}
