<div class="text-gray-500 text-[9px]" style="position: fixed; left: 0; z-index: 999" *ngIf="myService.data">
  {{ socketService.openSubscriptions | async }} | {{ myService.data.calcId }} | ms:  {{ myService.data.calcDuration }}
</div>
<div *ngIf="!myService.data" class="nxt-page" fxLayoutAlign="center center">
  <div>
    Daten werden geladen...
  </div>
</div>
<div *ngIf="myService.data" class="nxt-page !pl-0 !py-2 !pr-2" fxLayout="row" (click)="componentClicked()" [ngStyle.lt-sm]="{fontSize: '70%'}">
  <nxt-side-bar-left [collapsedWidth]="10" #sideBar>
    <nxt-studio-cash-report-side-bar [sideBar]="sideBar" (dataChanged)="sideBarDataChanged()"></nxt-studio-cash-report-side-bar>
  </nxt-side-bar-left>
  @if (myService.showNotLoggedInView()) {
    <div class="not-logged-in-view">
      <div class="text-[120%]">DU BIST NICHT AN DIESER KASSE ANGEMELDET!</div>
      <div>{{ myService.cashRegisterView.currentUser }} ist angemeldet</div>
      <div>Wenn du weiter die Kassen übernehmen möchtest, muss {{ myService.cashRegisterView.currentUser }} sich einloggen und eine Kassenübergabe starten</div>
      <nxt-button (click)="loginService.reLogin(false)">OK</nxt-button>
      <nxt-button nxtPermission="IsJulian" (click)="myService.showNotLoggedInView.set(false)">Julian...</nxt-button>
    </div>
  }


  <div fxFlex fxLayout="column" fxFlexAlign="stretch" fxLayoutGap="5px"
       [ngStyle.lt-sm]="{maxWidth: 'calc(100vw - 10px)'}">
    <div fxFlex fxLayout="column" class="scroll-view" fxFlexAlign="stretch" fxLayoutGap="5px">

      <div id="oberer-teil" fxLayout="row" fxLayout.lt-sm="column" ngStyle.lt-sm="height: fit-content" fxFlex
           fxLayoutGap="5px">
        <div id="incomings" class="nxt-card grid-min-height" fxLayout="column" ngStyle.lt-sm="min-height: 400px" style="padding-right: 0px;">
          <div class="nxt-card-title" fxLayout="row wrap" fxLayoutAlign="space-between center"
               style="padding-bottom: 6px; padding-right: 10px;">
            <div fxFlex fxShow.lt-sm="false" class="no-wrap" fxLayout="row wrap" fxLayoutAlign="space-between center">
              <div style="padding-right: 1em" [nxtMultiClick]="3" (nxtOnMultiClick)="triggerNewOldVersionButton()">EINNAHMEN {{ this.myService.studioView }}
              </div>
              <div fxLayout="row">
                @if (myService.showPostponedEvents ? myService.data.calculatedData.payments.reception.klarnaPostponed : myService.data.calculatedData.payments.reception.klarna; as klarna) {
                  <div class="text-80" fxLayout="row" fxLayoutAlign="center center">
                    <div>Klarna:&nbsp;</div>
                    <div>{{ klarna | money: false }}</div>
                    <div>&nbsp;&nbsp;</div>
                  </div>
                }
                @if (myService.showPostponedEvents ? myService.data.calculatedData.payments.reception.giftCardPostponed : myService.data.calculatedData.payments.reception.giftCard; as giftCard) {
                  <div class="text-80" fxLayout="row" fxLayoutAlign="center center">
                    <div>Gutscheine:&nbsp;</div>
                    <div>{{ giftCard | money: false }}</div>
                    <div>&nbsp;&nbsp;</div>
                  </div>
                }
                @if (myService.showPostponedEvents ? myService.data.calculatedData.payments.reception.bankPostponed : myService.data.calculatedData.payments.reception.bank; as bank) {
                  <div class="text-80" fxLayout="row" fxLayoutAlign="center center">
                    <div>Bank:&nbsp;</div>
                    <div>{{ bank | money : false }}</div>
                    <div>&nbsp;&nbsp;</div>
                  </div>
                }
                @if (myService.showPostponedEvents ? myService.data.calculatedData.payments.reception.paypalPostponed : myService.data.calculatedData.payments.reception.paypal; as paypal) {
                  <div class="text-80" fxLayout="row" fxLayoutAlign="center center">
                    <div>PayPal:&nbsp;</div>
                    <div>{{ paypal | money : false }}</div>
                    <div>&nbsp;&nbsp;</div>
                  </div>
                }
              </div>
              <div *ngIf="canShowTotalMoney" fxLayout="row" fxLayoutAlign="center center">
                <!--<div *ngIf="incomingData.length === 1">{{incomingData ? incomingData.length : '0'}}&nbsp;Einnahme:&nbsp;&nbsp;</div>
                <div *ngIf="incomingData.length !== 1">{{incomingData ? incomingData.length : '0'}}&nbsp;Einnahmen:&nbsp;&nbsp;</div>-->
                <div
                  *ngIf="myService.data">{{ myService.totalIncomingView | money : false }}
                </div>
              </div>
            </div>
          </div>
          <div class="nxt-card-content" fxFlex fxLayout="row" fxLayoutAlign="center center">
            <div class="flex h-full w-full flex-col">
              <nxt-datagrid
                class="h-100"
                name="incomingPayments"
                uniqueRowDataKey="paymentUuid"
                [columnDefs]="incomingColumnDefs"
                [disableAllSort]="true"
                [quickFilterText]="quickFilterText"
                [rowData]="myService.incomingDataView"
                [showFooter]="true"
                [showSideBar]="false"
                [localTextOverwrite]="{totalAndFilteredRows: 'Einnahmen', totalRows: 'Alle Einnahmen'}"
                fxFlex
              />
              <div class="flex flex-row justify-center pt-1">
                <nxt-button class="blink" (click)="acceptTransferClicked()" *ngIf="myService.cashRegisterView?.openCashRegisterTransfersToReceived?.length > 0">
                  Transfer annehmen
                </nxt-button>
              </div>
            </div>
            <div class="flex flex-col justify-end h-100" *ngIf="myService.cashRegisterView?.state === NxtWorkSessionCashRegisterState._1_Open">
              <!--<nxt-button-icon matTooltip="Transfer" fa="transfer"></nxt-button-icon>-->
              <nxt-button-icon
                (click)="newPaypalIncomingClicked()"
                fa="paypal"
                matTooltip="PayPal Einnahme"
                matTooltipPosition="left">
              </nxt-button-icon>
              <nxt-button-icon
                (click)="newIncomingOutgoingClicked('incoming-transfer')"
                matTooltip="Transfer"
                matTooltipPosition="left">move_down
              </nxt-button-icon>
              <nxt-button-icon
                (click)="newIncomingOutgoingClicked('incoming')"
                matTooltip="neue Einnahme"
                matTooltipPosition="left">
                add
              </nxt-button-icon>
            </div>
          </div>
        </div>
        <div id="outgoings" class="nxt-card grid-min-height" style="padding-left: 0 !important;" fxLayout="column" ngStyle.lt-sm="min-height: 400px">
          <div class="nxt-card-title" fxLayout="row" fxLayoutAlign="space-between center" style="padding-bottom: 6px; padding-left: 10px">
            <div fxFlex>AUSGABEN {{ this.myService.studioView }}</div>
            <div>{{ myService.totalOutgoingView | money: false }}</div>
          </div>
          <div class="flex grow flex-row nxt-card-content">
            <div class="flex flex-col justify-end" *ngIf="myService.cashRegisterView?.state === NxtWorkSessionCashRegisterState._1_Open">
              <nxt-button-icon
                (click)="newIncomingOutgoingClicked('outgoing-transfer')"
                matTooltip="Transfer Ausgabe"
                matTooltipPosition="right"
                style="transform: scaleX(-1)">move_up
              </nxt-button-icon>
              <nxt-button-icon
                (click)="newIncomingOutgoingClicked('outgoing')"
                matTooltip="neue Ausgabe"
                matTooltipPosition="right"
              >add
              </nxt-button-icon>
            </div>
            <nxt-datagrid
              class="h-100"
              name="outgoingPayments"
              uniqueRowDataKey="paymentUuid"
              [columnDefs]="outgoingColumnDefs"
              [disableAllSort]="true"
              [quickFilterText]="quickFilterText"
              [rowData]="myService.outgoingDataView"
              [showFooter]="true"
              [showSideBar]="false"
              [localTextOverwrite]="{totalAndFilteredRows: 'Ausgaben', totalRows: 'Alle Ausgaben'}"
              fxFlex
            />
          </div>
        </div>
      </div>

      <!-- DaTUM - ARTIST-BUTTONS - GESAMT -->
      <div id="mittlerer-teil" fxLayout="row">
        <div style="width: 22px;">
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <div (click)="logout()" style=" padding:0 10px; cursor:pointer;" class="no-wrap" fxLayoutAlign="start end" fxLayout="column">
            <div>{{ this.loginService.getUsername() }}</div>
            <div class="text-80">{{ this.loginService.getStudio() }}</div>
            <div class="text-80">{{ this.loginService.getWorkplace() | workplace }}</div>
          </div>
        </div>
        <div class="nxt-card nxt-text" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="0px" fxLayoutGap.lt-sm="8px"
             fxLayoutAlign="space-between center" style="padding: 0 8px  !important;">

          <div nxtPermission="StudioCashReport_DatePicker" fxLayout="column" class="date-picker"
               fxLayoutAlign="center center" style="border: 0 solid green">
            <nxt-date-picker
              width="200px"
              [noPadding]="true"
              [showJumpDayButtons]="true"
              [(nxtModel)]="myService.dateString"
              (nxtModelChange)="datePickerChanged()"
              [showTodayButton]="true"
              [noPlaceholder]="true"
            >
            </nxt-date-picker>
            <div>
              <div style="position: relative; left: -13px; top: -3px; font-size: 10.5px" (click)="workSessionClicked()" class="mouse-pointer">
                {{ myService.data.workSession.startAt | nxtDate: 'dd.MM HH:mm' }}
                - {{ myService.data.workSession.endAt | nxtDate: 'dd.MM HH:mm' }}
              </div>
            </div>
          </div>
          <div nxtPermission="IsJulian">
            <nxt-button-icon (click)="myService.reloadData(true)">sync</nxt-button-icon>
          </div>
          <div nxtPermission="StudioCashReport_SwitchStudios"
               fxHide.lt-sm
               fxLayout.gt-xs="row"
               fxLayout.lt-sm="column"
               class="pl-2" fxLayoutAlign="start center">
            <nxt-radio
              [nxtMultiClick]="3"
              (nxtOnMultiClick)="setCashRegisterStateClicked($event)"
              [ngStyle]="{visibility: myService.showAllCashRegisters ? 'hidden': ''}"
              direction="row"
              [options]="myService.cashRegisterRadioButtons"
              [(value)]="myService.studioView"
              textKey="text"
              valueKey="value"
              (valueChange)="studioChanged()">
            </nxt-radio>
          </div>
          <div fxLayout="row">
            <div *ngIf="myService.cashRegisterRadioButtons.length > 1" nxtPermission="StudioCashReport_ShowAllCashRegisters" fxLayout="row"
                 fxLayoutAlign="center center">
              <nxt-slide-toggle [(value)]="myService.showAllCashRegisters"
                                (valueChange)="showAllCashRegistersChanged()">
                <div style="font-size: 80%; line-height: 1.2">Alle<br/>Kassen</div>
              </nxt-slide-toggle>
            </div>
            <div>
              <nxt-button nxtPermission permissionNot="DisableGlobal_" [noBorder]="true"
                          (click)="switchFilterCashState()">{{ myService.viewFilter.cashState }}
              </nxt-button>
            </div>
          </div>
          <div fxLayout="row" fxShow.lt-sm="false" fxLayoutAlign="center center" fxLayoutGap="10px">
            <nxt-input placeholder="Suche" [noPadding]="true" [nxtFormControl]="quickFilterFormControl"
                       [clearIcon]="true"></nxt-input>
            <nxt-input [noPadding]="true" style="width: 70px;" placeholder="W-Nr" [nxtFormControl]="walkInFilterText"
                       [clearIcon]="true"></nxt-input>
          </div>
          <div>
            <nxt-slide-toggle nxtPermission="StudioCashReport_CanSetWalkIn" [value]="(configService.config | async)?.isWalkInToday" (valueChange)="setWalkIn()">
              Walk-In
            </nxt-slide-toggle>
            <!--<nxt-checkbox [value]="(configService.config | async)?.isWalkInToday" (valueChange)="setWalkIn()" label="Walk-In<br/>heute"></nxt-checkbox>-->
          </div>
          <div>
            <nxt-button
              *ngIf="myService.loginIsMainCashRegister && myService.data.workSession.state === NxtWorkSessionState.CanStart"
              (click)="startWorkSessionClicked()"
            >
              ARBEITSTAG STARTEN
            </nxt-button>
            <nxt-button
              (click)="startCashRegisterClicked()"
              *ngIf="myService.data.workSession.state === 'open' && !myService.myLoginCashRegister">
              KASSE STARTEN
            </nxt-button>
          </div>

          <div *ngIf="myService.cashRegisterView">
            <div>Start: {{ myService.cashRegisterView.startMoney | money }}</div>
            <div *ngIf="myService.cashRegisterView.moneyStackEnd">Ende: {{ myService.cashRegisterView.endMoney | money }}</div>
          </div>

          <div fxLayout="row">
            <!-- GELD AM ENDE VOM TAG + OB VON BANK -->
            <div
              fxLayout="column"
              fxLayoutAlign="center end"
              *ngIf="myService.data && (!myService.cashRegisterView || myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._1_Open)"
              class="pl-2 text-70"
              style="line-height: 1.2">

              <table nxtPermission="StudioCashReport_Switch_">
                <tr [ngClass]="myService.data.calculatedData.endOfDayCash.value < 0 ? 'red' : 'green-light'">
                  <td class="pr-1">N</td>
                  <td class="right">
                    <div>
                      <span *ngIf="myService.data.calculatedData.endOfDayCash.value > 0">+</span>
                      {{ myService.data.calculatedData.endOfDayCash.value | money }}
                    </div>
                  </td>
                </tr>
                <tr [ngClass]="myService.data.calculatedData.endOfDayCash.value_ < 0 ? 'red' : 'green-light'">
                  <td class="pr-1">T</td>
                  <td class="right">
                    <div>
                      <span *ngIf="myService.data.calculatedData.endOfDayCash.value_ > 0">+</span>
                      {{ myService.data.calculatedData.endOfDayCash.value_ | money }}
                    </div>
                  </td>
                </tr>
              </table>

              <!--<div [ngClass]="myService.data.calculatedData.endOfDayCash.value < 0 ? 'red' : ''">
                {{myService.data.calculatedData.endOfDayCash.value | money}}
              </div>
              <div [ngClass]="myService.data.calculatedData.endOfDayCash.value_ < 0 ? 'red' : ''">
                T {{myService.data.calculatedData.endOfDayCash.value_ | money}}
              </div>-->

              <ng-container *ngIf="myService.data.calculatedData.missingImproveArtistShouldGetMoney">
                <div class="red">Fehlende Nachstech-Gutscheine</div>
              </ng-container>

              <div *ngIf="!myService.data.calculatedData.missingImproveArtistShouldGetMoney">
                <div [ngClass]="myService.data.calculatedData.endOfDayCash.toGetFromBank > 0 ? 'red' : 'green-light'">
                  <div *ngIf="myService.data.calculatedData.endOfDayCash.toGetFromBank <= 0">Bank nicht nötig</div>
                  @if (loginService.isBackoffice() || permissionService.hasPermission(NxtPermissionId.IsFranchise)) {
                    <div fxLayout="column" fxLayoutAlign="center end" class="pt-1" *ngIf="myService.data.calculatedData.endOfDayCash.toGetFromBank > 0">
                      <div class="flex flex-row items-center">von Bank&nbsp;
                        <div style="font-size: 120%">{{ myService.data.calculatedData.endOfDayCash.toGetFromBank | money }}</div>
                      </div>
                    </div>
                  }
                  <div *ngIf="myService.data.calculatedData.missingImproveArtistShouldGetMoney" class="red">Fehlende Nachstech-Gutscheine</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--TERMINE -->
      <div id="unterer-teil" fxLayout="column" fxFlex>
        <div class="nxt-card" fxLayout="column" fxFlex>
          <div class="nxt-card-title">
            <div fxLayout="row" style="padding-bottom: 6px;">
              <div class="pr-2" fxLayout="column" fxLayoutAlign="center start" fxHide.lt-sm>
                @if (!myService.showPostponedEvents) {
                  <div [innerHTML]="(myService.calendarEventsView?.length || 0) + ' Termine'"></div>
                }
                <div class="text-70"
                     *ngIf="myService.data.postponedEvents.length > 0">{{ myService.data.postponedEvents.length + ' verschoben' }}
                </div>
              </div>
              <div class="gap-3 filter-toggles" fxLayout="row" fxLayoutAlign="center center" style="font-size: 14px;">
                <nxt-slide-toggle *ngIf="myService.data.postponedEvents.length > 0" fxHide.lt-md [(value)]="myService.showPostponedEvents"
                                  (valueChange)="myService.refreshView()">
                  <div class="filter-toggle-text">verschobene<br/>Termine</div>
                </nxt-slide-toggle>

                <nxt-slide-toggle fxHide.lt-md [(value)]="myService.viewFilter.hideClosedEvents"
                                  (valueChange)="myService.refreshView()">
                  <div class="filter-toggle-text">offene<br/>Termine</div>
                </nxt-slide-toggle>


                <nxt-slide-toggle fxHide.lt-md [(value)]="showFilterToggle['fehlende Fotos']"
                                  (valueChange)="quickFilterFormControl.setValue($event ? 'fehlende Fotos' : '')">
                  <div class="filter-toggle-text">ohne<br/>fotos</div>
                </nxt-slide-toggle>
                <nxt-slide-toggle fxHide.lt-md [(value)]="showFilterToggle['Walk-In']"
                                  (valueChange)="quickFilterFormControl.setValue($event ? 'Walk-In' : '')">
                  <div class="filter-toggle-text">Walk<br/>In</div>
                </nxt-slide-toggle>
                <nxt-slide-toggle [(value)]="showFilterToggle['payment-some-giftcard-discounted']"
                                  (valueChange)="quickFilterFormControl.setValue($event ? 'payment-some-giftcard-discounted' : '')">
                  <div class="filter-toggle-text">Rabattierte<br/>Gutscheine</div>
                </nxt-slide-toggle>
                <nxt-slide-toggle *ngIf="false" [(value)]="showFilterToggle['payment-some-giftcard-discounted-50']"
                                  (valueChange)="quickFilterFormControl.setValue($event ? 'payment-some-giftcard-discounted-50' : '')">
                  <div class="filter-toggle-text">Gutscheine 50</div>
                </nxt-slide-toggle>
                <nxt-slide-toggle [(value)]="showFilterToggle['payment-some-giftcard']"
                                  (valueChange)="quickFilterFormControl.setValue($event ? 'payment-some-giftcard' : '')">
                  <div class="filter-toggle-text">Gutscheine</div>
                </nxt-slide-toggle>
                <nxt-slide-toggle fxHide.lt-md [(value)]="showFilterToggle['Aktionen']"
                                  (valueChange)="quickFilterFormControl.setValue($event ? 'Aktionen' : '')">
                  <div class="filter-toggle-text">Aktionen</div>
                </nxt-slide-toggle>
                <!--<nxt-slide-toggle [(value)]="showFilterToggle['geht noch was']" (valueChange)="quickFilterFormControl.setValue($event ? 'geht noch was' : '')">
                  <div style="font-size: 80%; line-height: 1.2;">Up</div>
                </nxt-slide-toggle>-->
              </div>
              <div fxFlex fxShow.lt-sm="false" fxLayout="row" fxLayoutAlign="end center" style="position: relative;">

                <div fxLayout="row" fxLayoutAlign="end center" [innerHTML]="eventDetails | safeHtml"></div>

                <div *ngIf="cashReportHideArtistGetValues" fxLayoutAlign="center center"
                     class="px-1">
                  <mat-icon fxFLex class="mouse-pointer" style="font-size: 14px; height: 14px; width: 14px"
                            (click)="showArtistMoneyClicked()">euro
                  </mat-icon>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="end center">
                  <nxt-scr-artist-context-menu (onMenuItemClicked)="artistContextClicked($event)"></nxt-scr-artist-context-menu>
                  <div
                    *ngFor="let artist of myService.artistData"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    (click)="artistClicked(artist.name)"
                    (contextmenu)="scrArtistContextMenuComponent.show($event, artist)"
                    [class]="'artist-get-box no-wrap mouse-pointer ' + artist.state"
                    [ngClass]="{selected: myService.viewFilter.artist === artist.name}"
                    [matTooltip]="artist.stateText"
                  >
                    @if (artist.state === 'error') {
                      ❌❌❌
                    }
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <div>{{ artist.name.replace('(Piercing)', '(P)') }}</div>
                    </div>
                    <div *ngIf="showArtistGetValues" class="flex flex-row items-center justify-center">
                      <div>{{ artist.payoutValue | money: false }}<span style="font-size: 80%;" *ngIf="artist.isEarlyPaidOut">&nbsp;bezahlt</span>
                      </div>
                    </div>
                    <div>{{ artist.workTimeText }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="nxt-card-content grid-min-height" fxFlex fxLayout="column" fxFlexAlign="stretch">
            <nxt-datagrid
              name="calendarEvents"
              #calendarEventsDataGrid
              uniqueRowDataKey="id"
              [columnDefs]="calendarEventColDefs"
              [disableAllSort]="true"
              [quickFilterText]="quickFilterText"
              [rowData]="myService.calendarEventsView"
              [showFooter]="true"
              [showSideBar]="false"
              [autoSizeColumnOnResize]="true"
              [localTextOverwrite]="{totalAndFilteredRows: 'Termine', totalRows: 'Alle Termine'}"
              (rowDoubleClickNew)="eventRowClicked($event)"
              fxFlex
            />
          </div>
        </div>
      </div>

    </div>

    <div id="footer">
      <div
        class="nxt-card nxt-text"
        fxLayout="row"

      >


        <div class="nxt-card-content" style="border: 0 solid red" fxFlex
             fxLayout="row"
             fxLayoutAlign="space-between center">

          <nxt-studio-cash-report-2-menu [studioCashReport2Service]="myService"></nxt-studio-cash-report-2-menu>

          <nxt-button-icon (click)="showLatestChats()" matTooltip="WhatsApp-Chats">chat</nxt-button-icon>

          <nxt-button-icon fxHide.lt-sm (click)="newGiftCardClick()" matTooltip="Gutschein erfassen">receipt_long
          </nxt-button-icon>

          <nxt-button-icon nxtPermission="EventRating_Edit" (click)="startEventRatingClicked()" matTooltip="Termine bewerten">thumbs_up_down
          </nxt-button-icon>

          <nxt-button-icon nxtPermission="IsAc" (click)="showPhotosClicked()" matTooltip="Termin-Fotos">image
          </nxt-button-icon>

          <nxt-button-icon matTooltip="Suche (Kunden, Artists oder Gutscheine)" (click)="showSearch()">search
          </nxt-button-icon>

          <nxt-button-icon matTooltip="Römische Zahlen" [fxHide.lt-sm]="true" (click)="showRoman()">numbers
          </nxt-button-icon>

          <nxt-button-icon matTooltip="Walk-In" (click)="myService.walkInClicked(studioReal)">directions_run
          </nxt-button-icon>

          <ng-container *ngIf="myService.cashRegisterView">
            <ng-container *ngIf="myService.cashRegisterView?.state === NxtWorkSessionCashRegisterState._1_Open">
              <nxt-button-icon matTooltip="Abrechnung freigeben" (click)="toggleDayFinishClicked()">
                block
              </nxt-button-icon>
            </ng-container>
            <ng-container
              *ngIf="myService.cashRegisterView?.state === NxtWorkSessionCashRegisterState._3_CalcCashRegister1 || myService.cashRegisterView?.state === NxtWorkSessionCashRegisterState._3_CalcCashRegister1">
              <nxt-button-icon matTooltip="Abrechnung wieder blockieren" (click)="toggleDayFinishClicked()">
                verified
              </nxt-button-icon>
            </ng-container>

            <ng-container *ngIf="myService.cashRegisterView?.state !== NxtWorkSessionCashRegisterState._1_Open">
              <nxt-button-icon matTooltip="Abrechnung" (click)="dayFinishClicked()">payments
              </nxt-button-icon>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
