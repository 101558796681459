export class SearchTools {

  static highlightHtml(value: string, searchText: string) {
    if (searchText) {
      const filterParts = SearchTools.escapeRegExp(searchText).split(' ');
      const regex = new RegExp(filterParts.join('|'), 'gi');
      return value.toString().replace(regex, (str) => '<mark class="autocomplete-mark">' + str + '</mark>');
    } else {
      return value;
    }
  }

  private static escapeRegExp(regex: string) {
    const specialChars = ['$', '^', '*', '(', ')', '+', '[', ']', '{', '}', '\\', '|', '.', '?', '/'];
    const regexSpecialChars = new RegExp('(\\' + specialChars.join('|\\') + ')', 'g');
    return regex.replace(regexSpecialChars, '\\$1');
  }
}
