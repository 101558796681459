<div class="flex flex-col">
  <div class="flex flex-col min-w-[400px]">
    <nxt-input [nxtFormControl]="form.controls.name"/>
    <nxt-textarea
      [nxtFormControl]="form.controls.description"
    >

    </nxt-textarea>
    <nxt-select [nxtFormControl]="form.controls.type" [options]="moneyAccountTypeOptions"/>
    <nxt-select [multiple]="true" [options]="userOptions()" [nxtFormControl]="form.controls.userIds"></nxt-select>
    <nxt-select [options]="cashRegisterOptions()" [nxtFormControl]="form.controls.cashRegisterName" [showClearIcon]="true"></nxt-select>
    <!--<nxt-slide-toggle class="mb-4" [nxtFormControl]="form.controls.receiveCashRegisterSafe"></nxt-slide-toggle>
    <nxt-select [showClearIcon]="true" [nxtFormControl]="form.controls.receiveCashRegisterSafeFrom" [options]="otherStudios"/>-->
    <nxt-select [showClearIcon]="true" [nxtFormControl]="form.controls.receiveCashRegisterSafeStudio" [options]="studioOptions()"/>
    <nxt-slide-toggle [nxtFormControl]="form.controls.syncToTransactionsHead"></nxt-slide-toggle>
  </div>
  <div class="flex flex-row justify-around">
    <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
    <nxt-button (click)="saveClicked()">Speichern</nxt-button>
  </div>
</div>
