import {Component, Inject} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {NxtFormControl} from '../../../nxt-form/nxt.form-control';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {NxtButtonComponent} from '../../button/nxt-button.component';
import {TextareaComponent} from '../../../components/form-controls/textarea/textarea.component';
import {NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {ValidatorTools} from '../../../helpers/validator.tools';

export interface DialogTextareaOptions {
  message: string;
  prompt?: string;
  buttonText?: string;

  placeholder?: string;
  cols?: number;
  charCounterFn?: (text) => string;
}

/**
 * @title Dialog Overview
 */
@Component({
    selector: 'nxt-dialog-input-multiline',
    templateUrl: './dialog-textarea.component.html',
    styleUrls: ['./dialog-textarea.component.scss'],
    imports: [
        FlexModule,
        NgIf,
        TextareaComponent,
        NxtButtonComponent,
        SafeHtmlPipe,
    ]
})
export class DialogTextareaComponent {

  placeholder: string;
  message: string;
  buttonText = 'OK';
  cols = 50;
  inputFormControl: UntypedFormControl;
  charCounterText = '';

  constructor(
    public dialogRef: MatDialogRef<DialogTextareaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  setOptions(options: DialogTextareaOptions) {
    this.placeholder = options.placeholder;
    this.message = options.message;
    this.buttonText = options.buttonText || 'OK';
    if (options.cols) {
      this.cols = options.cols;
    }

    this.inputFormControl = new NxtFormControl(options.prompt ? options.prompt : '', [ValidatorTools.requiredAndNotNaN]);

    this.inputFormControl.valueChanges.subscribe((value) => {
      if (options.charCounterFn) {
        this.charCounterText = options.charCounterFn(value);
      }
    });
    if (options.charCounterFn) {
      this.charCounterText = options.charCounterFn(options.prompt);
    }
  }

  close() {
    if (!this.inputFormControl.invalid) {
      this.dialogRef.close(this.inputFormControl.value);
    }
  }

  okClicked() {
    if (!this.inputFormControl.invalid) {
      this.dialogRef.close(this.inputFormControl.value);
    }
  }

  cancelClicked() {
    this.dialogRef.close();
  }
}
