import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {InputComponent} from '../form-controls/input/input.component';
import {NxtPageHeaderComponent} from '../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageComponent} from '../nxt-page/nxt-page.component';
import {NxtPageHeaderTitleComponent} from '../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageContentComponent} from '../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {QRCodeComponent} from 'angularx-qrcode';

@Component({
  selector: 'nxt-qr-code-generator',
  templateUrl: './qr-code-generator.component.html',
  styleUrls: ['./qr-code-generator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    NxtPageComponent,
    NxtPageHeaderComponent,
    NxtPageHeaderTitleComponent,
    NxtPageContentComponent,
    NxtPageFooterComponent,
    NxtButtonComponent,
    QRCodeComponent,
  ], standalone: true,
})

export class QrCodeGeneratorComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  value = signal('');
  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }
}
