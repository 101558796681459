import {ChangeDetectorRef, Component, ElementRef, EventEmitter, input, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
  CellClickedEvent,
  CellDoubleClickedEvent,
  ColDef,
  colorSchemeDark,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  iconSetMaterial,
  RowNode,
  RowSelectionOptions,
  SelectionChangedEvent,
  themeBalham,
} from 'ag-grid-community';
import {NxtColDef} from './nxt-col-def';
import {NxtDatagridValueGetters} from '../../../common-browser/helpers/nxt-fields/nxt-datagrid-value-getters';
import {NxtDatagridCellRenderers} from './nxt-datagrid-cell-renderers';
import {DialogService} from '../../../services/dialog.service';
import {AgGridAngular} from 'ag-grid-angular';
import {LocaleText} from './locale-text';
import {NxtDatagridValueFormatters} from '../../../common-browser/helpers/nxt-fields/nxt-datagrid-value-formatters';
import {NxtDatagridExcelCellRenderers} from './nxt-datagrid-excel-cell-renderers';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {StarCellRenderer} from '../cell-render-components/star.cell-renderer';
import {ArtistSkillCellRenderer} from '../cell-render-components/artist-skill.cell-renderer';
import {BarCellRenderer} from '../cell-render-components/bar.cell-renderer';
import {BehaviorSubject, Subject} from 'rxjs';
import {WhatsappChatComponent} from '../../../components/whatsapp/whatsapp-chat/whatsapp-chat.component';
import {ContactService} from '../../../services/contact.service';
import {debounceNotFirst} from '../../../common-browser/helpers/rxjs.tools';
import {SocketService} from '../../../services/socket/socket.service';
import {PermissionService} from '../../../services/permission.service';
import {NxtPermissionId} from '../../../common-interfaces/nxt.user.interface';
import {ResizedEvent} from 'src/app/directives/resized.directive';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {NxtDatagridCellStyler} from './nxt-datagrid-cell-styler';
import {ConfigService} from '../../../services/config.service';
import {CalendarEventEdit2Component} from '../../../pages/calendar-event-edit/calendar-event-edit-2/calendar-event-edit-2.component';
import {ResizedDirective} from '../../../directives/resized.directive';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgStyle} from '@angular/common';
import {LoginService} from '../../../services/login.service';
import _ from 'lodash';
import {SpinnerComponent} from '../../../components/spinner/spinner.component';


@Component({
  selector: 'nxt-datagrid',
  templateUrl: './nxt-datagrid.component.html',
  styleUrls: ['./nxt-datagrid.component.scss'],
  imports: [AgGridAngular, FlexModule, NgStyle, ExtendedModule, ResizedDirective, SpinnerComponent],
  standalone: true,
})
export class NxtDatagridComponent implements OnInit, OnChanges {


  @Input() set localTextOverwrite(value: any) {
    this.localeText = {...this.localeText, ...value};
  }

  @Input() columnFilters = false;

  @Input() set rowData(data: any[]) {
    if (data && data.length > 0) {
      if (!this.uniqueRowDataKey) {
        if (data[0].id) {
          // id wäre richtig :) also setzen
          this.uniqueRowDataKey = 'id';
          this.checkForUniqueRowDataKey();
        } else {
          try {
            this.socketService.sendTelegramAdmin('DataGrid mit uniqueRowDataKey nicht id\n\n' + this.name +
              '\n\nHeaders: ' + this._columnDefs.map(c => c.headerName).join(', ')
              + '\n\n' + JSON.stringify(data[0]));
          } catch (err) {
            this.socketService.sendTelegramAdmin('DataGrid mit uniqueRowDataKey nicht id\n\n' + this.name
              + '\n\nHeaders: ' + this._columnDefs.map(c => c.headerName).join(', '),
            );
          }
        }
      }
    }
    setTimeout(async () => {
      if (data) {
        this._data = data;
        if (!this.gridOptions || !this.api) {
          setTimeout(() => {
            this.rowData = this._data;
          }, 100);
        } else {
          if (this.api) {
            /*if (this.opacity === '100%') {
              await this.hideData();
            }*/
            // this.beforeSetRowData();
            // if (!this._data) {

            this.setRowData(data);

            // } else {
            //
            // }

            // this.afterSetRowData();
            if (data.length > 0) {
              this.autoSizeAllColumns$.next();
            }
            this.showData();
          } else {
            this._data = data;
          }
        }
      }
      this.viewChanged.emit();
    }, 0);
  }

  @Input() set columnDefs(colDefs: ColDef[]) {
    this._columnDefs = colDefs;
    this.colDefChanged();
  }


  constructor(
    private dialogService: DialogService,
    public myElement: ElementRef,
    private contactService: ContactService,
    private socketService: SocketService,
    private permissionService: PermissionService,
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private configService: ConfigService,
    private loginService: LoginService,
  ) {
    this.registerAutoSizeAllColumns();
  }

  private counter = 0;

  @ViewChild(AgGridAngular) agGridAngular: AgGridAngular;
  @Output() viewChanged = new EventEmitter<void>();
  @Input() name: string;
  @Input() showSideBar = false;
  @Input() showFooter?: boolean;
  @Input() disableAllSort = false;
  @Input() uniqueRowDataKey = '';
  @Input() quickFilterText = '';
  @Input() rowHeight = -1;
  @Input() fontSizePercent: 100;
  @Input() fontSize: '14px' | '16px' | '18px' | '20px' = '14px';
  @Input() rowHeightGetter: (params: any, lineHeight: number) => number;
  @Input() sizeColumnsToFit = false;

  @Output() cellEditingStopped = new EventEmitter<any>();
  @Output() cellEditingStarted = new EventEmitter<any>();

  public _data: any[];
  public _columnDefs: NxtColDef[];

  @Output() selectionChanged = new EventEmitter<SelectionChangedEvent<any> | CellClickedEvent>();
  @Output() gridStateChanged = new EventEmitter<any>();
  @Output() rowDragEnd = new EventEmitter<any>();
  // @Output() gridReady = new EventEmitter<any>();

  public gridIsReady = new BehaviorSubject(false);

  @Output() rowDoubleClick = new EventEmitter<any>();
  @Output() rowDoubleClickNew = new EventEmitter<any>();
  @Output() rowClick = new EventEmitter<any>();


  public gridOptions: GridOptions;
  public api: GridApi;
  gridClass = 'ag-theme-dark grid-font-big';
  opacity = '0%';
  lastScroll: any;
  localeText = LocaleText.localeText;

  @Input() pagination = false;
  @Input() suppressRowTransform = false;
  @Input() dragOrderFieldName?: string;
  @Input() pinnedBottomRowData?: any;
  loadingSpinner = input(false);
  frameworkComponents = {
    starCellRenderer: StarCellRenderer,
    artistSkillCellRenderer: ArtistSkillCellRenderer,
    barCellRenderer: BarCellRenderer,
  };
  @Input() enableRangeSelection = true;
  @Input() suppressRowHoverHighlight = false;
  @Input() suppressCellSelection = false;
  @Input() excelExportName = '';
  @Input() autoSizeColumnOnResize = false;
  autoSizeAllColumns$ = new Subject<void>();
  @Input() rowSelection: RowSelectionOptions | 'single' | 'multiple';

  // theme = 'ag-theme-balham-dark';
  dataLength = 0;
  myTheme = themeBalham
    .withPart(iconSetMaterial)
    .withPart(colorSchemeDark);


  getDefaultColDef(): ColDef {
    return {
      suppressHeaderMenuButton: true,
      filter: this.columnFilters,
      filterParams: {
        clearButton: true,
        newRowsAction: 'keep',
      },
      resizable: true,
      valueGetter: NxtDatagridValueGetters.getValueGetter(),
      cellRenderer: NxtDatagridCellRenderers.getCellRenderer(),
      valueFormatter: NxtDatagridValueFormatters.getValueFormatter(),
      cellStyle: NxtDatagridCellStyler.getCellStyler(),
      sortable: !this.disableAllSort,
      enableRowGroup: true,
      onCellDoubleClicked: (ev) => this.ngZone.run(() => this.rowDoubleClickNew.emit(ev)),
    };
  }

  public isRowSelectable() {
    return true;
  }

  public localeTextFunc(params: any) {
    return params.defaultValue;
  }


  stringOrNumber(string1, string2) {
    return (string1 < string2 ? -1 : (string1 > string2 ? 1 : 0));
  }


  private groupedComparator(valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode, isInverted: boolean) {

    if (nodeA.rowGroupIndex === 0) {
      // console.log('453');
    }

    if (nodeA && nodeA.group) {

      if ((nodeA.rowGroupColumn.getColDef() as any).groupedSortMethod === 'value') {
        return this.stringOrNumber(valueA, valueB);
      } else {
        return this.stringOrNumber(nodeA.allChildrenCount, nodeB.allChildrenCount);
      }
      // return nodeA.groupData.groupData.toString().localeCompare(nodeB.groupData.groupData.toString());
    }

    if (valueA && valueB) {
      return this.stringOrNumber(valueA, valueB);
    }
  }

  initGridOptions() {
    this.gridOptions = {
      // headerHeight: this.getRowHeight.bind(this),
      // getRowHeight: this.getRowHeight.bind(this),
      suppressRowClickSelection: true,
      // suppressCellSelection: this.suppressCellSelection,
      suppressMultiRangeSelection: true,

      // floatingFilter: this.showFloatingFilter,
      tooltipMouseTrack: true,
      tooltipTrigger: 'hover',
      tooltipShowDelay: 0,

      defaultColDef: this.getDefaultColDef(),

      getContextMenuItems: this.getContextMenuItems.bind(this),
      /*getContextMenuItems: (params) => {
        params.defaultItems.push('copy');
        return params.defaultItems;
      },*/
      suppressAggFuncInHeader: true,
      animateRows: true,
      enableRangeSelection: this.enableRangeSelection,
      enableCharts: true,

      excelStyles: [
        {
          id: 'ExcelDateTime',

          /*dataType: 'dateTime',*/
          numberFormat: {format: 'yyyy-mm-dd hh:mm:ss;;;'},
        }, {
          id: 'ExcelDate',
          /*dataType: 'dateTime',*/
          numberFormat: {format: 'dd.mm.yyyy'},
        },
      ],
      sideBar: this.showSideBar,
      // rowGroupPanelShow: 'always',
      // groupSuppressAutoColumn: false,
      autoGroupColumnDef: {
        filter: false,
        comparator: this.groupedComparator.bind(this),
        headerValueGetter: (params) => {
          return params.api.getRowGroupColumns().map((col) => col.getColDef().headerName).join(', ');
        },
        /*filterValueGetter: (params) => {
          const colGettingGrouped = params.colDef.showRowGroup.toString();
          const valueForOtherCol = params.api.getValue(colGettingGrouped, params.node);
          return valueForOtherCol;
        }*/

        /*headerValueGetter: (params) => {
          console.log(params);
          return 'Gruppe';
        },*/
      },
    };

    this.gridOptions.getRowHeight = null;

    this.checkForUniqueRowDataKey();

    if (this.showFooter) {
      this.gridOptions.statusBar = {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
          {statusPanel: 'agSelectedRowCountComponent'},
          {statusPanel: 'agAggregationComponent'},
        ],
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quickFilterText) {
      if (this.autoSizeColumnOnResize) {
        this.autoSizeAllColumns$.next();
      }
    }

  }

  ngOnInit() {
    this.initGridOptions();

  }


  async onCellClicked(params: CellClickedEvent<any>) {
    const colDef = params.column.getColDef() as NxtColDef;
    if (typeof colDef.nxtOnCellClicked === 'function') {
      colDef.nxtOnCellClicked(params);
    }

    if (colDef.nxtFieldType === NxtFieldType.ShowCalendarEvent) {
      const dialog = this.dialogService.showComponentFull(CalendarEventEdit2Component);
      dialog.componentInstance.loadEvent({eventId: params.value});
    }

    if (colDef.nxtFieldType === NxtFieldType.ShowContactHistory) {
      this.dialogService.showHistory('contact', params.data.id);
    }

    if (colDef.nxtFieldType === NxtFieldType.ShowEventHistory) {
      this.dialogService.showHistory('calendarEvent', params.data.id);
      // const dialog = this.dialogService.showComponentFull(CalendarEventHistoryComponent);
      // dialog.componentInstance.setData(params.data.id);
    }

    if (colDef.nxtFieldType === NxtFieldType.ShowImage) {
      const url = params.data[params.column.getColDef().field];
      window.open(url, '_blank');
      params.event.preventDefault();
      params.event.stopPropagation();
      return false;
    }

    if (colDef.nxtFieldType === NxtFieldType.WhatsAppChat) {
      if (params.data.birthday) {
        this.contactService.showWhatsAppChat(params.data);
      } else {
        const mobile = params.data[params.column.getColDef().field];
        const dialog = await this.dialogService.showComponentFull(WhatsappChatComponent);
        dialog.componentInstance.loadChat(mobile);
        if (typeof colDef.nxtFieldTypeParams === 'function') {
          dialog.componentInstance.title = colDef.nxtFieldTypeParams(params);
        }
      }
    }


    if (colDef.nxtFieldType === NxtFieldType.CalendarEvents) {
      if (params.value.length > 0) {
        let buttons = params.value.map(e => ({text: DateTools.format(e.start, 'dd.MM.yyyy'), value: e.eventId ?? e.id}));
        if (typeof colDef.nxtFieldTypeParams?.getButtonText === 'function') {
          buttons = params.value.map(e => ({text: colDef.nxtFieldTypeParams.getButtonText(e), value: e.eventId ?? e.id}));
        }
        const result = await this.dialogService.showButtonChooser<string>({
          buttonRows: [buttons],
          title: 'Welchen Termin möchtest du dir ansehen?',
          text: '',
          minWidth: '80%',
          value: '',
        });
        if (typeof result !== 'string' && result?.value) {
          const dialog = this.dialogService.showComponentFull(CalendarEventEdit2Component);
          setTimeout(() => {
            dialog.componentInstance.loadEvent({eventId: result.value});
            this.cdRef.detectChanges();
          }, 500);
        }
      }
    }

    this.selectionChanged.emit(params);
  }


  onGridReady(ev: GridReadyEvent) {
    this.api = ev.api;
    if (this._data) {
      this.api.setGridOption('rowData', this._data); // .applyTransaction({})..setRowData(this._data);
      this.cdRef.detectChanges();
    }
    this.gridIsReady.next(true);
  }


  onRowDragEnd(params: any) {
    let prevRowData = params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1);
    let nextRowData = params.api.getDisplayedRowAtIndex(params.node.rowIndex + 1);

    if (prevRowData) {
      prevRowData = prevRowData.data;
    }

    if (nextRowData) {
      nextRowData = nextRowData.data;
    }

    let newOrderValue = -1;

    if (this.dragOrderFieldName) {
      const prevOrderValue = (prevRowData && prevRowData[this.dragOrderFieldName]) ? prevRowData[this.dragOrderFieldName] : 0;
      const nextOrderValue = (nextRowData && nextRowData[this.dragOrderFieldName]) ? nextRowData[this.dragOrderFieldName] : prevOrderValue + 200000;
      newOrderValue = parseInt(prevOrderValue + ((nextOrderValue - prevOrderValue) / 2), 10);
    }


    this.rowDragEnd.emit({...params, prevRowData, nextRowData, newOrderValue});
  }

  onSelectionChanged(params: SelectionChangedEvent<any>) {
    this.selectionChanged.emit(params);
  }


  private getRowHeight(params: any) {
    let result = 0;
    if (this.rowHeight > -1) {
      result = this.rowHeight;
    } else if (this.fontSize === '14px') {
      result = 25;
    } else if (this.fontSize === '16px') {
      result = 30;
    } else if (this.fontSize === '18px') {
      result = 32;
    } else if (this.fontSize === '20px') {
      result = 35;
    }
    if (typeof this.rowHeightGetter === 'function') {
      return this.rowHeightGetter(params, result);
    }
    return result;
  }

  registerAutoSizeAllColumns(inMs = 1) {
    this.autoSizeAllColumns$.pipe(debounceNotFirst(50)).subscribe(() => {
      requestAnimationFrame(() => {
        if (this.gridOptions && this.api) {
          if (this.sizeColumnsToFit) {
            this.api.sizeColumnsToFit();
          } else {
            let allDisplayedColumns = this.api.getColumns();
            allDisplayedColumns = allDisplayedColumns?.filter(col => !col.getColDef().suppressAutoSize);
            if (allDisplayedColumns) {
              this.api.autoSizeColumns(allDisplayedColumns);
            }
          }
        }
      });
    });
  }

  private showData() {
    this.opacity = '100%';
  }


  redrawRows() {
    if (this.api) {
      this.api.redrawRows();
    }
  }


  private beforeSetRowData() {
    this.lastScroll = this.myElement.nativeElement.querySelector('.ag-body-viewport').scrollTop;
  }

  private afterSetRowData() {
    setTimeout(() => {
      this.myElement.nativeElement.querySelector('.ag-body-viewport').scrollTo(0, this.lastScroll);
    }, 0);
  }

  private setRowData(data: any[]) {
    try {
      this.api.setGridOption('rowData', data); // .setRowData(data);
      this.dataLength = data.length;
      this.cdRef.detectChanges();
    } catch (err) {

    }
    setTimeout(() => {
      try {
        this.dataLength = data.length;
        this.api.redrawRows();
        this.cdRef.detectChanges();
      } catch (err) {
      }
    }, 1);
  }

  getContextMenuItems(params) {
    let result: any[] = [

      'separator',
      'copy',
      'separator',
      'chartRange',
    ];

    const excelExport = {
      // custom item
      name: 'Excel-Datei downloaden',
      action: async () => {
        if (this.permissionService.hasPermission(NxtPermissionId.ExcelExport)) {
          const lang = await (await this.dialogService.showInputOld({message: 'Excel Sprache (en, de)', prompt: 'de', isMoney: false})).afterClosed().toPromise();
          this.api.exportDataAsExcel(this.getExcelExportParams(lang));
          this.socketService.sendTelegramAdmin(this.loginService.getUsername() + ' Exportiert "' + this.excelExportName + '" als Excel');
        }
      },
    };

    if (this.excelExportName && this.permissionService.hasPermission(NxtPermissionId.ExcelExport)) {
      result = [excelExport, ...result];
    }
    return result;
  }

  private getExcelExportParams(lang: string): ExcelExportParams {
    return {
      processCellCallback: (params) => {
        return NxtDatagridExcelCellRenderers.getExcelCellRenderer(params, lang);
      },
      fileName: this.excelExportName + '.xlsx',
    };
  }

  public addTestRow() {

    const testRow = {timestamp: 0, currentIndex: 100, totalContacts: 5, message: 'declarations-of-consent', isRunning: false};
    const newItems = [
      testRow,
      testRow,
      testRow,
    ];
    this.api.applyTransaction({
      add: newItems,
      addIndex: 0,
    });
  }

  public gridResized($event: ResizedEvent) {
    if (this.autoSizeColumnOnResize) {
      this.autoSizeAllColumns$.next();
    }
  }

  private colDefChanged() {
    if (this._columnDefs) {
      for (const colDef of this._columnDefs) {
        if (colDef.nxtIcon) {
          colDef.nxtFieldType = NxtFieldType.Icon;
        }
        if (colDef.nxtIcon || [NxtFieldType.ShowCalendarEvent, NxtFieldType.ShowContactHistory, NxtFieldType.ShowCalendarEvent, NxtFieldType.ShowImage].includes(colDef.nxtFieldType)) {
          colDef.maxWidth = 45;
          colDef.minWidth = 45;
        }
      }
    }
  }

  onCellDoubleClicked(params: CellDoubleClickedEvent<any>) {
    const colDef = params.column.getColDef() as NxtColDef;
    if (typeof colDef.nxtOnCellDoubleClicked === 'function') {
      colDef.nxtOnCellDoubleClicked(params);
    }
  }

  private checkForUniqueRowDataKey() {
    if (this.uniqueRowDataKey && this.gridOptions && !this.gridOptions.getRowId) {
      this.gridOptions.getRowId = (params) => {
        const id = _.get(params.data, this.uniqueRowDataKey);
        if (id) {
          return id;
        }
      };
    }
  }
}
