import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtPageHeaderComponent} from '../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageComponent} from '../nxt-page/nxt-page.component';
import {NxtPageHeaderTitleComponent} from '../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageContentComponent} from '../nxt-page/nxt-page-content/nxt-page-content.component';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtCalendarEvent} from '../../common-interfaces/nxt.calendar-event.interface';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {SocketService} from '../../services/socket/socket.service';
import {NxtPageFooterComponent} from '../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {keys} from 'lodash';
import {DialogService} from '../../services/dialog.service';

@Component({
    selector: 'nxt-consent-check',
    templateUrl: './consent-check.component.html',
    styleUrls: ['./consent-check.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NxtPageComponent,
        NxtPageHeaderComponent,
        NxtPageHeaderTitleComponent,
        NxtPageContentComponent,
        NxtPageFooterComponent,
        NxtDatagridComponent,
    ]
})

export class ConsentCheckComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  events = signal<(NxtCalendarEvent & { firstReceptionPaymentUsername?: string })[]>([]);

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  dialogRef = inject(MatDialogRef<ConsentCheckComponent>, {optional: true});


  columnDefs: NxtColDef<NxtCalendarEvent & { firstReceptionPaymentUsername: string }>[] = [
    {headerName: '', field: 'id', nxtFieldType: NxtFieldType.ShowCalendarEvent,},
    {headerName: 'Datum', field: 'start', nxtFieldType: NxtFieldType.Date_germanDateTime},
    // {headerName: 'Fehler', field: 'consentCheck.message', nxtFieldType: NxtFieldType.Text},
    {
      headerName: 'Kassiert', field: 'firstReceptionPaymentUsername', nxtFieldType: NxtFieldType.Text, valueGetter: (params) => {
        const payment = params.data.payments.find(p => p.createdAtDateString === params.data.startDateString && p.workplace === 'reception');
        return payment ? payment.createdBy : '';
      }
    },
    {
      headerName: 'Stencil gesehen', nxtFieldType: NxtFieldType.Text, valueGetter: (params) => {
        return params.data.stencilSeen?.seenBy || '';
      }
    },
  ];

  users = computed(() => {
    const users = {};
    this.events().forEach(e => {
      if (e.firstReceptionPaymentUsername) {
        if (!users[e.firstReceptionPaymentUsername]) {
          users[e.firstReceptionPaymentUsername] = 0;
        }
        users[e.firstReceptionPaymentUsername]++;
      }
    });
    return keys(users).map(user => ({user, count: users[user]}));
  });

  constructor() {
    super();
  }

  async ngOnInit() {
    this.dialogService.showLoading('Lade...');
    const events: (NxtCalendarEvent & { firstReceptionPaymentUsername?: string })[] = (await this.socketService.getEventsConsentCheckInvalid());
    events.forEach(e => {
      const payment = e.payments.find(p => p.createdAtDateString === e.startDateString && p.workplace === 'reception');
      if (payment) {
        e.firstReceptionPaymentUsername = payment.createdBy;
      }
    });
    this.events.set(events.sortNumber('start'));
    this.dialogService.hideLoading();
  }

  nxtOnDestroy() {
  }
}
