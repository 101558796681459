import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {DialogService} from '../../services/dialog.service';
import {LoginService} from '../../services/login.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {DownloadService} from '../../services/download.service';
import {NxtPromoOfferState} from '../../common-interfaces/nxt.promo.interface';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgIf} from '@angular/common';

@Component({
    selector: 'nxt-event-qr',
    templateUrl: './promo-qr.component.html',
    styleUrls: ['./promo-qr.component.scss'],
    imports: [NgIf, FlexModule, ExtendedModule, NxtButtonComponent]
})

export class PromoQrComponent implements OnInit {
  state: 'wait' | 'ready' | 'not-logged-in' = 'wait';
  result: {
    state: NxtPromoOfferState, code: string, base64: string, error?: string;
  };
  alreadyUsed = false;


  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private dialogService: DialogService,
    private router: Router,
    private loginService: LoginService,
    private storageService: LocalStorageService,
    private downloadService: DownloadService
  ) {
  }

  public id;
  public infoText = 'Dein persönliches Angebot wird erstellt';

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    setTimeout(() => {
      this.loadDiscountQr();
    }, 100);
  }

  private async loadDiscountQr() {
    if (this.id) {
      const promoId = this.id.split('_')[1];
      const alreadyCode = this.storageService.getByString<string>('qr-code-' + promoId);
      let url;
      if (window.location.origin.includes('localhost')) {
        url = 'http://localhost:444/promoLoadDiscountQr';
        // url = 'https://ac.nxt-lvl.ink/promoLoadDiscountQr';
      } else {
        url = window.location.origin + '/promoLoadDiscountQr';
      }

      try {
        const result = await firstValueFrom(this.httpClient.post(url, {id: this.id, alreadyCode})) as { code: string, base64: string, state: NxtPromoOfferState, error?: string; };
        if (result?.error) {
          this.infoText = result?.error;
        } else {
          this.result = result;

          this.alreadyUsed = this.result.state !== NxtPromoOfferState.Open;
          this.storageService.setByString('qr-code-' + promoId, this.result.code);
        }
      } catch (err) {
        this.infoText = 'Es ist ein Fehler aufgetreten\nVersuch es noch einmal';
      }
    }
  }

  public download() {
    this.downloadService.downloadBase64('NXT-LVL.INK Angebot.jpg', this.result.base64);
  }
}
