<div class="nxt-page nxt-text" fxFlex fxLayout="column" fxLayoutAlign="stretch">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-2">
    <div fxLayout="row" fxLayoutGap="50px">
      <div class="page-header">Benutzer</div>
      <nxt-button (click)="addUser()">neuer Benutzer</nxt-button>
      <nxt-input [(value)]="quickFilterText" placeholder="Suche"></nxt-input>
    </div>
    <nxt-button-icon *ngIf="dialogRef" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>
  <nxt-datagrid
    *ngIf="columnDefs"
    fxFlex
    name="newEvents"
    [columnDefs]="columnDefs"
    [disableAllSort]="true"
    [quickFilterText]="quickFilterText"
    [rowData]="users"
    [showFooter]="false"
    [showSideBar]="false"
    [suppressCellSelection]="true"
    (rowDoubleClick)="rowDoubleClicked($event)"
    [enableRangeSelection]="false"
    [suppressRowHoverHighlight]="true"
  >
  </nxt-datagrid>
</div>
