import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtPageComponent} from '../../../components/nxt-page/nxt-page.component';
import {NxtPageHeaderComponent} from '../../../components/nxt-page/nxt-page-header/nxt-page-header.component';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtPageHeaderTitleComponent} from '../../../components/nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageContentComponent} from '../../../components/nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../../../components/nxt-page/nxt-page-footer/nxt-page-footer.component';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {DialogService} from '../../../services/dialog.service';
import {IbPromotionEditComponent} from './ib-offer-edit/ib-promotion-edit.component';
import {IbPromotion} from '../../../common-interfaces/ink-back/ib.interface';
import {IconTools} from '../../../common-browser/helpers/icon.tools';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'nxt-ib-promotions',
  templateUrl: './ib-promotions.component.html',
  styleUrls: ['./ib-promotions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NxtPageComponent, NxtPageHeaderComponent, NxtPageHeaderTitleComponent, NxtPageContentComponent, NxtPageFooterComponent, NxtDatagridComponent, NxtButtonComponent],
})

export class IbPromotionsComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  promotions = signal<IbPromotion[]>([]);
  columnDefs = signal<NxtColDef<IbPromotion>[]>([
    {headerName: '', nxtFieldType: NxtFieldType.Icon, nxtIcon: IconTools.Material.Edit, nxtOnCellClicked: (params) => this.editClicked(params.data)},
    {headerName: 'Name', field: 'name', nxtFieldType: NxtFieldType.Text},
    {
      headerName: 'Rabatt', field: 'discountPromotion.discountPercentage', nxtFieldType: NxtFieldType.Percentage,
      valueFormatter: (params) => {
        if (params.data.discountPromotion.discountType === 'percentage') {
          return params.value + '%';
        }
      }
    },
    {
      headerName: 'Preis',
      nxtFieldType: NxtFieldType.Text,
      valueGetter: (params) => {
        if (params.data.discountPromotion.discountType === 'priceFix' || params.data.discountPromotion.discountType === 'priceLike') {
          return params.data.discountPromotion.priceValue;
        }
      },
      valueFormatter: (params) => {
        if (params.data.discountPromotion.discountType === 'priceFix') {
          try {
            return 'fix ' + params.data.discountPromotion.priceValue.toMoneyString('€', false);
          } catch (err) {
            return '????';
          }

        } else if (params.data.discountPromotion.discountType === 'priceLike') {
          return 'ca. ' + params.data.discountPromotion.priceValue.toMoneyString('€', false);
        }
      },
      cellStyle: {textAlign: 'right'},
      headerClass: 'text-center',
    },
    {
      headerName: 'Artist',
      field: 'discountPromotion.artistGet',
      nxtFieldType: NxtFieldType.Money,
      cellStyle: {textAlign: 'center'},
      headerClass: 'text-center',
      valueFormatter: (params) => {
        if (params.data.discountPromotion.discountType === 'priceLike' || params.data.discountPromotion.discountType === 'priceFix') {
          return params.value.toMoneyString();
        }
      },
    },
    {
      headerName: 'Keine Rabatt-Gutscheine',
      field: 'discountPromotion.disableDiscountedGiftCards',
      nxtFieldType: NxtFieldType.Boolean,
      cellStyle: {textAlign: 'center'},
      headerClass: 'text-center',
    },
    {
      headerName: 'Nachkassieren', field: 'discountPromotion.additionalPayAfterEnd',
      nxtFieldType: NxtFieldType.Boolean,
      cellStyle: {textAlign: 'center'},
      headerClass: 'text-center',
    },
  ]);

  /*** Injections ***/
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  public dialogRef = inject(MatDialogRef, {optional: true});


  /*** Variable ***/

  constructor() {
    super();
  }

  ngOnInit() {
    this.load().then();
  }

  nxtOnDestroy() {
  }

  async load() {
    this.promotions.set(await this.socketService.ib.getPromotions());
  }

  async newOfferClicked() {
    const dialog = this.dialogService.showComponentDialog(IbPromotionEditComponent);
    dialog.componentInstance.load('new').then();
    if (await firstValueFrom(dialog.afterClosed())) {
      this.load().then();
    }
  }

  private async editClicked(promotion: IbPromotion) {
    const dialog = this.dialogService.showComponentDialog(IbPromotionEditComponent);
    dialog.componentInstance.load(promotion.id).then();
    if (await firstValueFrom(dialog.afterClosed())) {
      this.load().then();
    }
  }
}
