import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, ElementRef, inject, OnInit, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../../services/socket/socket.service';
import {SocketInterfaceResponse} from '../../../../common-interfaces/socket/socket-interface';
import {
  NxtBankDocument,
  NxtBankDocumentStatus,
  NxtBankDocumentType,
  NxtBankTransaction,
  NxtBankTransactionStatus,
  NxtBankTransactionType,
} from '../../../../common-interfaces/bank/bank-transaction.interface';
import {SplitAreaComponent, SplitComponent} from 'angular-split';
import {NxtDatePipe} from '../../../../pipes/nxt-date-pipe';
import {DatePicker2Component} from '../../../form-controls/date-picker-2/date-picker-2.component';
import {MatTooltip} from '@angular/material/tooltip';
import {NxtButtonIconComponent} from '../../../../controls/button-icon/nxt-button-icon.component';
import {MatDialogRef} from '@angular/material/dialog';
import {AutocompleteComponent} from '../../../form-controls/autocomplete/autocomplete.component';
import {FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {NxtTypedFormControl} from '../../../../nxt-form/nxt.typed-form-control';
import {ValidatorTools} from '../../../../helpers/validator.tools';
import {InputComponent} from '../../../form-controls/input/input.component';
import {SlideToggleComponent} from '../../../form-controls/slide-toggle/slide-toggle.component';
import {FlexModule} from 'ngx-flexible-layout';
import {CellClickedEvent, SelectionChangedEvent} from 'ag-grid-community';
import {firstValueFrom} from 'rxjs';
import {ObjectTools} from '../../../../common-browser/helpers/object.tools';
import {FormTools} from '../../../../services/form.tools';
import {DialogService} from '../../../../services/dialog.service';
import {MatIcon} from '@angular/material/icon';
import {BankDocumentDebugComponent} from './bank-document-debug/bank-document-debug.component';
import {LoginService} from '../../../../services/login.service';
import {LocalStorageService} from '../../../../services/local-storage.service';
import {AssignFinderService} from './assign-finder.service';
import {BankDocumentTools} from '../../../../common-browser/helpers/bank-document.tools';
import {Clipboard} from '@angular/cdk/clipboard';
import {DriveTools} from '../../../../common-browser-public/helpers/drive.tools';
import {PdfjsComponent} from '../../../pdfjs/pdfjs.component';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {BankDocumentManualChangesComponent} from './bank-document-manual-changes/bank-document-manual-changes.component';
import {DateTools} from '../../../../common-browser/helpers/date.tools';
import {BankDocumentService} from '../bank-document.service';
import {DownloadService} from '../../../../services/download.service';
import {BankTransactionFinderComponent, PossibleNxtBankTransaction} from '../../bank-transactions/bank-transaction-finder/bank-transaction-finder.component';
import {BankDocumentTypePipe} from '../../../../pipes/bank-document-type.pipe';
import {ActivatedRoute} from '@angular/router';
import {ColorTools} from '../../../../common-browser/helpers/color.tools';
import {SpinnerComponent} from '../../../spinner/spinner.component';
import {Money2Pipe} from '../../../../pipes/money-2.pipe';
import {BankTransferService} from '../../../../services/bank/bank-transfer.service';


@Component({
  selector: 'nxt-bank-document-details',
  templateUrl: './bank-document-details.component.html',
  styleUrls: ['./bank-document-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SplitComponent,
    SplitAreaComponent,
    NxtDatePipe,
    DatePicker2Component,
    MatTooltip,
    NxtButtonIconComponent,
    AutocompleteComponent,
    InputComponent,
    SlideToggleComponent,
    FlexModule,
    ReactiveFormsModule,
    FormsModule,
    MatIcon,
    PdfjsComponent,
    NxtButtonComponent,
    BankTransactionFinderComponent,
    BankDocumentTypePipe,
    SpinnerComponent,
    Money2Pipe,

  ],
  standalone: true,
})

export class BankDocumentDetailsComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor() {
    super();
  }

  @ViewChild('pdfCanvas') pdfCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('assignedTransactionsFinder') assignedTransactionFinder!: BankTransactionFinderComponent;
  @ViewChild('possibleTransactionsFinder') possibleTransactionFinder!: BankTransactionFinderComponent;

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private route = inject(ActivatedRoute);
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  private bankTransferService = inject(BankTransferService);
  private downloadService = inject(DownloadService);
  private loginService = inject(LoginService);
  private localStorageService = inject(LocalStorageService);
  private assignFinderService = inject(AssignFinderService);
  private clipboard = inject(Clipboard);
  dialogRef = inject(MatDialogRef, {optional: true});
  bankDocumentService = inject(BankDocumentService);

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  selectedPossibleTransactions = signal<PossibleNxtBankTransaction[]>([]);


  documentNumbers = computed(() => {
    return [this.document().documentNumber, ...this.document().nxtAi?.invoice?.numbers || []];
  });

  documentValue = computed(() => {
    return this.document().nxtAi?.invoice?.valueGross || 0;
  });

  assignedTransactionIds = computed(() => this.assignedTransactions().map(t => t.id));

  documentValueForTransaction = computed(() => {
    if (this.document().direction === 'in') {
      if (this.documentValue() > 0) {
        return this.documentRestValue() * -1;
      } else {
        return this.documentRestValue();
      }
    } else {
      if (this.documentValue() > 0) {
        return this.documentRestValue();
      } else {
        return this.documentRestValue() * -1;
      }
    }
  });

  otherCompany = computed(() => {
    return BankDocumentTools.getOtherCompany(this.document());
  });


  document = computed(() => {
    return this.documentFull().document;
  });

  showDocumentSearchIcons = computed(() => this.isAmazon());

  documentRestValue = computed(() => {
    return this.document().assignValue;
  });
  companies = signal<{ name: string, datevNo: string, type: NxtBankTransactionType, ibans?: string[] }[]>([]);


  isAmazon = computed(() => this.document().nxtAi?.senderCompany?.name === 'Amazon');
  documentFull = signal<SocketInterfaceResponse.GetBankDocumentFull | null>(null);

  assignedTransactions = signal<NxtBankTransaction[]>([]);
  pdfUrl = signal('');


  selectedAssignedTransactions = signal<PossibleNxtBankTransaction[]>([]);
  hasChanges = signal(false);

  currency = signal<'EUR' | 'USD'>('EUR');

  showForm = computed(() => {
    if (this.document()) {
      if (this.document().nxtAi && this.document().documentType.includesOne(['spk-month-statement', 'invoice', 'receipt', 'klarna-settlement-report', 'paypal-fee'])) {
        if ([NxtBankDocumentStatus._10_nxtAi, NxtBankDocumentStatus._90_ok, NxtBankDocumentStatus._30_transaction].includes(this.document().status)) {
          return true;
        }
      }
    }
    return false;
  });

  showLoading = computed(() => {
    if (this.document().status === NxtBankDocumentStatus._06_documentAi) {
      return true;
    }
    return false;
  });


  form = new FormGroup({
    valueGross: new NxtTypedFormControl<number | null>(null, [Validators.required], 'Betrag'),
    documentDateString: new NxtTypedFormControl<string | null>(null, [Validators.required], 'Datum'),
    otherCompany: new NxtTypedFormControl<{ name: string, datevNo: string } | null>(null, [ValidatorTools.hasProperty('name')], 'Firma'),
    documentNumber: new NxtTypedFormControl<string | null>(null, [Validators.required], 'Rechnungsnummer'),
    documentNumber2: new NxtTypedFormControl<string>('', [], 'Rechnungsnummer 2'),
    documentNumber3: new NxtTypedFormControl<string>('', [], 'Rechnungsnummer 3'),
    noDocumentNumberOk: new NxtTypedFormControl(false, [], 'Rechnungsnummer nicht nötig'),
  });


  private originalFormValue: any;


  saved = signal(false);

  protected readonly NxtBankDocumentStatus = NxtBankDocumentStatus;

  protected readonly ColorTools = ColorTools;

  protected readonly NxtBankTransactionStatus = NxtBankTransactionStatus;

  protected readonly NxtBankDocumentType = NxtBankDocumentType;


  ngOnInit() {
    if (!this.dialogRef) {
      if (this.route.snapshot.paramMap.get('shortId')) {
        this.load(this.route.snapshot.paramMap.get('shortId')).then();
      }
      this.load(this.localStorageService.getByString('lastAssingDocumentId', '')).then();
    }
    this.pushSocketSubscription = this.socketService.subscribeNew('eventBankDocumentChangedId', (data) => {
      if (data.op === 'update' && data.id === this.document().id) {
        this.load(this.document().id).then();
      }
    });
    // this.loadDocument('gmi-196874');
    // this.loadDocument('d778f732-20fc-4a09-9f34-bdb1fd24cba9');
  }

  nxtOnDestroy() {
  }

  async load(documentId: string) {
    this.localStorageService.setByString('lastAssingDocumentId', documentId);
    const [/*accounts,*/ documentFull, companies] = await Promise.all([
      // this.socketService.getBankAccounts(),
      this.socketService.getBankDocumentFull(documentId),
      this.socketService.bank.getCompanies(),
    ]);


    this.documentFull.set(documentFull);
    this.companies.set(companies.filter(c =>
      [NxtBankTransactionType.SupplierAp, NxtBankTransactionType.Rent, NxtBankTransactionType.CustomerAr].includes(c.type),
    ));
    this.pdfUrl.set(DriveTools.getDriveLink(documentFull.document.driveFileId));
    this.assignedTransactions.set(this.documentFull().assignedTransactions);
    this.loadFormFromDocument(this.document());
    if (this.possibleTransactionFinder) {
      this.possibleTransactionFinder.loadPossibleTransactions().then();
    }
    this.hasChanges.set(false);
  }


  blobPdfFromBase64String(base64String: string) {
    const byteArray = Uint8Array.from(
      atob(base64String)
        .split('')
        .map(char => char.charCodeAt(0)),
    );
    return new Blob([byteArray], {type: 'application/pdf'});
  }

  loadFormFromDocument(document: NxtBankDocument) {
    if (document.nxtAi && (['spk-month-statement', 'invoice', 'receipt', 'klarna-settlement-report', 'paypal-fee'].includes(document.documentType))) {
      let documentNumber2 = '';
      let documentNumber3 = '';
      if (document.nxtAi?.invoice?.numbers) {
        if (document.nxtAi.invoice.numbers.length > 0) {
          documentNumber2 = document.nxtAi.invoice.numbers[0];
        }
        if (document.nxtAi.invoice.numbers.length > 1) {
          documentNumber3 = document.nxtAi.invoice.numbers[1];
        }
      }
      const otherCompany = this.companies().find(c => c.name === BankDocumentTools.getOtherCompany(document).name);
      this.form.setValue({
        valueGross: document.nxtAi?.invoice?.valueGross || null,
        documentDateString: document.documentDate ? document.documentDate?.dateFormatDate() : null,
        documentNumber: document.documentNumber || null,
        documentNumber2,
        documentNumber3,
        otherCompany: otherCompany || null,
        noDocumentNumberOk: !!document.nxtAi.invoice?.noNumberOk,
      });
      if (document.nxtAi?.invoice?.currency) {
        if (document.nxtAi.invoice.currency === 'USD') {
          this.currency.set('USD');
        }
      }
      this.originalFormValue = this.form.getRawValue();
      this.form.valueChanges.subscribe(() => {
        this.checkChanged();
      });
    }
  }

  async saveClicked() {
    if (this.form.value.documentDateString && this.form.value.documentDateString.dateParse() > Date.now()) {
      this.dialogService.showOk('Eine Dokument mit Datum in der Zukunft?\nDas gibts nicht').then();
      return;
    }
    if (!this.hasChanges()) {
      this.dialogService.showOk('Keine Änderungen').then();
      return;
    }
    if (FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      this.dialogService.showLoading('Daten werden gespeichert...');
      await this.socketService.updateDocumentNxtAiManual({
        documentId: this.document().id,
        valueGross: this.form.value.valueGross,
        documentNumber: this.form.value.documentNumber,
        documentDateString: this.form.value.documentDateString,
        companyName: this.form.value.otherCompany.name,
        noDocumentNumberOk: this.form.value.noDocumentNumberOk,
        documentNumber2: this.form.value.documentNumber2,
        documentNumber3: this.form.value.documentNumber3,
      });
      this.dialogService.hideLoading();
      this.saved.set(true);
    }
  }

  async downloadClicked() {
    this.downloadService.downloadUrl(DriveTools.getDriveLink(this.document().driveFileId), this.document().documentNumber + '.pdf');
    /*const source = this.pdfObjectUrl();
    const downloadLink = document.createElement('a');
    const fileName = this.document().id + '.pdf';

    downloadLink.href = source;
    downloadLink.download = fileName;
    downloadLink.click();*/

  }

  async deleteDocumentClicked() {
    if (await this.dialogService.showYesNo('Dokument wirklich löschen?')) {
      await this.socketService.deleteBankDocument(this.document().id);
      this.dialogRef.close(true);
    }
  }

  private checkChanged() {
    this.hasChanges.set(!ObjectTools.equal(this.form.getRawValue(), this.originalFormValue));
    this.form.controls.documentNumber.setValidators(this.form.value.noDocumentNumberOk ? [] : [Validators.required]);
    this.form.controls.documentNumber.updateValueAndValidity({emitEvent: false});
  }

  public displayOtherCompany = (supplier: { name: string, datevNo: string } | undefined) => {
    if (supplier) {
      return supplier.name + ' - ' + supplier.datevNo;
    }
    return '';
  };


  public assignedTransactionSelectionChanged(ev: SelectionChangedEvent<NxtBankTransaction> | CellClickedEvent) {
    this.selectedAssignedTransactions.set(ev.api.getSelectedRows());
  }


  documentDetailsClicked() {
    const dialog = this.dialogService.showComponentDialog(BankDocumentDebugComponent);
    dialog.componentInstance.load(this.documentFull());
  }

  /*private documentTransactionValueMatch(transaction: PossibleNxtBankTransaction) {
    const directionOk = transaction.direction === 'out' ? this.documentValue() > 0 : this.documentValue() < 0;
    return directionOk
      && (
        (this.documentRestValue().abs() === transaction.rest.abs() && this.documentRestValue().abs() > 0)
        || this.documentRestValue().abs() === transaction.value.abs()
        || this.documentValue().abs() === transaction.value.abs()
        || this.documentValue().abs() === transaction.rest.abs()
      );
  }*/

  async assignSelectedPossibleTransactionsClicked() {
    if (!this.document().documentNumber && !this.document().nxtAi.invoice.noNumberOk) {
      this.dialogService.showOk('Rechnungsnummer fehlt, bitte eintragen.').then();
      return;
    }
    let text = 'Sollen die ausgewählten ' + this.selectedPossibleTransactions().length + ' Buchungen verknüpft werden?';
    if (this.selectedPossibleTransactions().length === 1) {
      text = 'Soll die ausgewählte Buchung verknüpft werden?';
    }
    if (await this.dialogService.showYesNo(text, {yesText: 'Ja, verknüpfen'})) {
      await this.socketService.assignDocumentToTransactions(this.document().id, this.selectedPossibleTransactions().map(t => t.id));
      this.selectedAssignedTransactions.set([]);
      this.selectedPossibleTransactions.set([]);
      this.saved.set(true);
    }
  }

  async removeSelectedAssignedTransactionsClicked() {
    let text = 'Sollen die Verknüpfung mit den ' + this.selectedAssignedTransactions().length + ' Buchungen aufgehoben werden?';
    if (this.selectedPossibleTransactions().length === 1) {
      text = 'Soll die Verknüpfung mit der ausgewählten Buchung aufgehoben werden?';
    }
    if (await this.dialogService.showYesNo(text, {yesText: 'Ja, Verknüpfung aufheben'})) {
      await this.socketService.removeTransactionsFromDocument(this.document().id, this.selectedAssignedTransactions().map(t => t.id));
      this.selectedAssignedTransactions.set([]);
      this.selectedPossibleTransactions.set([]);
      this.saved.set(true);
    }
  }

  reloadClicked() {
    this.load(this.document().id).then();
  }


  async runClicked() {
    if (await this.bankDocumentService.run([this.document().id])) {
      this.load(this.document().id).then();
      this.saved.set(true);
    }
  }

  closeClicked() {
    this.dialogRef?.close(this.saved);
  }

  public searchDocumentNumberClicked(documentNumber: string) {
    if (this.isAmazon()) {
      if (window.location.hostname === 'localhost') {
        this.clipboard.copy('https://www.amazon.de/gp/your-account/order-history?search=' + documentNumber);
      } else {
        window.open('https://www.amazon.de/gp/your-account/order-history?search=' + documentNumber, '_blank');
      }
    }
  }

  async showManualChangesClicked() {
    const dialog = this.dialogService.showComponentDialog(BankDocumentManualChangesComponent);
    dialog.componentInstance.document.set(this.document());
    const saved = await firstValueFrom(dialog.afterClosed());
    if (saved) {
      this.saved.set(true);
    }
  }

  async parseDateInputClicked() {
    const dateString = await this.dialogService.showInput('Datum in einem wilden Format eingeben');
    if (dateString) {
      const date = DateTools.parse(dateString);
      if (date) {
        this.form.controls.documentDateString.setValue(date.dateFormatDate());
        this.checkChanged();
      }
    }
  }

  public async documentTypeClicked() {
    const options = BankDocumentTools.types.map(type => {
      return {
        text: BankDocumentTools.getTypeText(type),
        value: type,
      };
    });
    const result = await this.dialogService.showSelect('Dokumenttyp ändern', options, {value: this.document().documentType});
    if (result) {
      if (result !== this.document().documentType) {
        await this.socketService.setBankDocumentType(this.document().id, result);
      }
    }
  }

  copyUrlClicked(ev: MouseEvent) {
    const url = window.location.origin + '/bd/' + this.document().shortId;
    this.clipboard.copy(url);
    if (ev.shiftKey || ev.button === 1) {
      window.open(url, '_blank');
    }
  }

  public async documentRestValueClicked() {
    if (this.document().status === NxtBankDocumentStatus._30_transaction) {
      let text = 'Offener Betrag: ' + this.documentRestValue().toMoneyString(this.document().nxtAi?.invoice?.currency);
      text += '\nAls erledigt markieren?';
      if (await this.dialogService.showYesNo(text, {yesText: 'Ja Dokument auf OK setzen', noText: 'Zurück'})) {
        await this.socketService.bank.setDocumentAssignValueOk(this.document().id);
      }
    }
  }

  public async ownCompanyMissingClicked() {
    const result = await this.dialogService.showButtons(this.document().ownCompany + ' fehlt', {
      buttons: [
        {text: 'nicht nötig', value: 'notRequired'},
        {text: 'PDF bearbeiten', value: 'edit'},
      ],
      showCancelButton: true,
    });
    if (result) {
      if (result.value === 'notRequired') {
        await this.socketService.bank.setDocumentOwnCompanyNotRequired(this.document().id);
      } else if (result.value === 'edit') {
        this.startEditPdf().then();
      }
    }
  }

  private async startEditPdf() {
    this.clipboard.copy('GBX GmbH\nWallstr. 62\n52064 Aachen');
    this.dialogService.showLoading('Dokument wird vorbereitet...');
    const googleDriveFileId = await this.socketService.bank.startEditBankDocument(this.document().id);
    this.dialogService.hideLoading();
    const url = 'https://acrobat.adobe.com/link/documents/GDrive/9c2ba590-6f48-4bc0-81b0-2e99f01a3d00/?uri=1wF6pFRs61gEGU2uqquPuzCoFYw_KfWjz';
    window.open(url, '_blank');
    this.clipboard.copy(url);
    if (await this.dialogService.showYesNo('Bearbeite jetzt das Dokument', {noText: 'Abbrechen', yesText: 'Fertig, Dokument aktualisieren'})) {
      this.dialogService.showLoading('Dokument wird aktualisiert...');
      await this.socketService.bank.endEditBankDocument(this.document().id, googleDriveFileId);
      this.dialogService.hideLoading();
    }
  }

  public editDocumentClicked() {
    this.startEditPdf().then();
  }

  async payNowClicked() {
    let receiverIban = '';
    const ibans = this.companies().find(c => c.name === this.form.value.otherCompany.name).ibans;
    if (ibans.length > 0) {
      receiverIban = ibans[0];
    }
    const success = await this.bankTransferService.createBankTransferDialog({
      ownCompany: this.document().ownCompany,
      main: true,
      value: this.documentRestValue(),
      receiverName: this.form.value.otherCompany.name,
      receiverIban,
      text: this.form.value.documentNumber,
    });
    if (success) {
      this.load(this.document().id).then();
    }
  }
}

