import {Component, OnInit, ViewChild} from '@angular/core';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtSubscriptionClass} from '../../../classes/nxt-subscription-class';
import {NxtWhatsAppBroadcastStatus} from '../../../common-interfaces/whats-app/nxt.whats-app-broadcast-status.interface';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {MathTools} from '../../../common-browser/helpers/math.tools';
import {SocketInterface} from '../../../common-interfaces/socket/socket-interface';
import {DialogService, LoadingId} from '../../../services/dialog.service';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {Log} from '../../../common-browser/log/log.tools';
import {LanguageTools} from '../../../common-browser/helpers/language.tools';
import {DisplayWithTools} from '../../../common-browser/helpers/display-with.tools';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {DurationPipe} from '../../../pipes/duration.pipe';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {AutocompleteComponent} from '../../form-controls/autocomplete/autocomplete.component';
import {DatePickerComponent} from '../../form-controls/date-picker/date-picker.component';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {NgIf} from '@angular/common';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {InputComponent} from '../../form-controls/input/input.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {IconTools} from '../../../common-browser/helpers/icon.tools';
import {NxtWhatsAppImage} from '../../../common-interfaces/whats-app/nxt-whatsapp-message.interface';
import BroadcastCustomFilter = SocketInterface.BroadcastCustomFilter;


interface OpenContacts {
  name: string;
  id: string;
  eventsCount: number;
  canceledEventsCount: number;
  closedEventsCount: number;
}

@Component({
    selector: 'nxt-whatsapp-broadcast',
    templateUrl: './whatsapp-broadcast.component.html',
    styleUrls: ['./whatsapp-broadcast.component.scss'],
  imports: [FlexModule, InputComponent, NxtButtonComponent, NgIf, SlideToggleComponent, DatePickerComponent, AutocompleteComponent, NxtDatagridComponent, SafeHtmlPipe, DurationPipe]
})
export class WhatsappBroadcastComponent extends NxtSubscriptionClass implements OnInit {
  unknownChats = false;


  constructor(
    private socketService: SocketService,
    private dialogService: DialogService
  ) {
    super();
  }

  @ViewChild('statusGrid') nxtDatagridComponent: NxtDatagridComponent;
  live = true;
  weekly = false;
  broadcastId = '';
  messageId = '';

  statusStack: NxtWhatsAppBroadcastStatus[] = [];
  status: NxtWhatsAppBroadcastStatus;
  columnDefs: NxtColDef[] = [
    {headerName: 'Datum', field: 'timestamp', nxtFieldType: NxtFieldType.Date_germanDateTimeWithSeconds, minWidth: 180},
    {headerName: 'Index', valueGetter: (params) => (params.data.currentIndex + 1) + ' / ' + params.data.totalContacts, nxtFieldType: NxtFieldType.Text, minWidth: 100},
    {headerName: 'Prozent', valueGetter: (params) => (params.data.currentIndex / params.data.totalContacts) * 100, nxtFieldType: NxtFieldType.Percentage, minWidth: 100},
    {
      headerName: 'Chat', cellRenderer: () => IconTools.Material.Chat, nxtOnCellClicked: (params) => {
        window.open('/chat/' + params.data.mobile, '_blank');
      }
    },
    {headerName: 'Text', field: 'message', nxtFieldType: NxtFieldType.Text, minWidth: 800},
  ];
  columnDefsContacts: NxtColDef<OpenContacts>[] = [
    {
      headerName: 'Name', field: 'name', nxtFieldType: NxtFieldType.Text, minWidth: 180, nxtOnCellClicked: (params) => {
        this.dialogService.showEventsByContact(params.data.id);
      }
    },
    {
      headerName: 'Abges.', field: 'canceledEventsCount', nxtFieldType: NxtFieldType.Number
    },
    {
      headerName: 'geschl.', field: 'closedEventsCount', nxtFieldType: NxtFieldType.Number
    },
    {
      headerName: 'zukunft', field: 'eventsCount', nxtFieldType: NxtFieldType.Number
    },
  ];
  alreadySentCount = -1;
  toSendCount = -1;
  sentPercentage = -1;
  message: any;
  imageSrc = '';
  sortMostEvents = true;
  customFilter: BroadcastCustomFilter = {
    use: false,
    eventFrom: '2023-12-24',
    eventTill: '2023-12-26',
    minPriceEstimatedFrom: 10,
    maxPriceEstimatedFrom: 1000,
    noFixArtist: false,
    invertResult: false,
    noEventsInFuture: false,
    customerLanguage: '',
    malleImport: false,
    hadEvents: false,
    artistCalendarNumber: ''
  };
  restDuration = 0; // DurationTools.DURATION_1DAY * 3 + DurationTools.DURATION_1HOUR * 2.5;
  public sleepBetweenMessages = 16;
  public openContacts: OpenContacts[];
  public stopTime = '20:00';
  languages = LanguageTools.nxtUsed;

  protected readonly DisplayWithTools = DisplayWithTools;

  async ngOnInit() {
    const lastBroadcast = await this.socketService.getLastBroadcast();
    if (lastBroadcast) {
      this.broadcastId = lastBroadcast.broadcastId;
      this.messageId = lastBroadcast.messageId;
      if (lastBroadcast.options) {
        if (lastBroadcast.options.customFilter) {
          this.customFilter = lastBroadcast.options.customFilter;
          this.weekly = lastBroadcast?.options?.weekly;
          this.live = lastBroadcast?.options?.live;
          this.sortMostEvents = lastBroadcast?.options?.sortMostEvents;
        }
      }
    }
    this.pushSocketSubscription = this.socketService.subscribeNew('getWhatsAppBroadcastStatus', async (status: NxtWhatsAppBroadcastStatus & {
      messageId: string,
      broadCastId: string,
      options: any
    }) => {
      if (status.isRunning) {
        if (status.options?.sleepBetweenMessages) {
          this.sleepBetweenMessages = (status.options.sleepBetweenMessages / 1000);
        }

        if (status.toSendCount) {
          this.toSendCount = status.toSendCount;
        }
        if (status.alreadySentCount) {
          this.alreadySentCount = status.alreadySentCount;
          this.sentPercentage = MathTools.round(this.alreadySentCount / (this.alreadySentCount + this.toSendCount) * 100, 1);
          this.restDuration = this.toSendCount * (this.sleepBetweenMessages * 1000);
        }

        if (this.statusStack.length === 0) {
          const result = await this.socketService.getWhatsAppBroadcastStatusStack();
          this.statusStack = result.stack;
          this.message = result.message;
          this.imageSrc = '';
          if (this.message.image) {
            this.imageSrc = this.getImageSrc(this.message.image);
          }
        }
        if (status.broadCastId) {
          this.broadcastId = status.broadCastId;
          this.messageId = status.messageId;
        }

        if (this.nxtDatagridComponent) {
          if (this.nxtDatagridComponent.api) {
            this.nxtDatagridComponent.api.applyTransaction({
              add: [status],
              addIndex: 0
            });
          }
          // this.nxtDatagridComponent.gridOptions.api.updateRowData({add: [status], addIndex: 0});
        }

        if (status.contactId && this.openContacts) {
          this.openContacts = this.openContacts.filter(c => c.id !== status.contactId);
        }
      }
      this.status = status;
    }, {emitInitial: true});
  }

  addRow() {
    this.addItems();
    if (this.nxtDatagridComponent) {
      const testRow = {timestamp: 0, currentIndex: 100, totalContacts: 5, message: 'declarations-of-consent', isRunning: false};
      /*this.nxtDatagridComponent.gridOptions.api.applyTransaction({
        add: [testRow],
        addIndex: 0
      });*/
      this.statusStack.push(testRow);
      // this.nxtDatagridComponent.gridOptions.api.updateRowData({add: [status], addIndex: 0});
    }
  }

  addItems(addIndex = 0) {
    const testRow = {timestamp: 0, currentIndex: 100, totalContacts: 5, message: 'declarations-of-consent', isRunning: false};
    const newItems = [
      testRow,
      testRow,
      testRow,
    ];
    this.nxtDatagridComponent.api.applyTransaction({
      add: newItems,
      addIndex,
    });
  }


  async start() {
    if (await this.dialogService.showYesNo('Wir in dem Broadcast eine Aktion beworben?')) {
      if (!(await this.dialogService.showYesNo('Gibt es zu der / den Aktion(en) einen angelegten Rabatt im System?'))) {
        this.dialogService.showOk('Bitte lege einen Rabatt an und starte den Broadcast erneut');
        return;
      }
    }

    if (this.sleepBetweenMessages <= 14) {
      this.dialogService.showOk('Mindestens 14 Sekunden!');
      return;
    }
    this.statusStack = [];
    const stopDateTime = this.stopTime ? (DateTools.parse(DateTools.formatNow('yyyy-MM-dd') + ' ' + this.stopTime + ':00')) : 0;
    this.socketService.startWhatsAppBroadcast(this.broadcastId.toUpperCase(), this.messageId, {
      live: this.live,
      weekly: this.weekly,
      customFilter: this.customFilter,
      sortMostEvents: this.sortMostEvents,
      sleepBetweenMessages: (this.sleepBetweenMessages * 1000),
      stopDateTime,
      unknownChats: this.unknownChats
    }).then();
  }


  abort() {
    this.socketService.abortWhatsAppBroadcast();
  }

  async loadBroadcast(loadContacts = false) {
    this.dialogService.showLoading(LoadingId.BroadcastLoading, 'Broadcast wird geladen');
    try {
      this.imageSrc = '';
      this.message = '';
      this.alreadySentCount = -1;
      const result = await this.socketService.loadWhatsAppBroadcast(this.broadcastId.toUpperCase(), this.messageId, {
        live: this.live,
        weekly: this.weekly,
        customFilter:
        this.customFilter,
        sortMostEvents: this.sortMostEvents,
        sleepBetweenMessages: (this.sleepBetweenMessages * 1000),
        getContacts: loadContacts,
        unknownChats: this.unknownChats
      });
      this.openContacts = result.contacts;
      this.alreadySentCount = result.alreadySentCount;
      this.toSendCount = result.toSendCount;
      this.sentPercentage = MathTools.round(this.alreadySentCount / (this.alreadySentCount + this.toSendCount) * 100, 1);
      this.message = result.message;
      this.imageSrc = '';
      if (this.message.image) {
        this.imageSrc = this.getImageSrc(this.message.image);
      }
      this.restDuration = this.toSendCount * (this.sleepBetweenMessages * 1000);
    } catch (err) {
      Log.error(err);
    }
    this.dialogService.hideLoading(LoadingId.BroadcastLoading);
  }

  getImageSrc(whatsAppImage: NxtWhatsAppImage) {
    if (whatsAppImage.data) {
      return 'data:' + whatsAppImage.mimetype + ';base64,' + whatsAppImage.data;
    }
    return '';
  }

  public sleepBetweenMessagesChanged() {
    this.restDuration = this.toSendCount * (this.sleepBetweenMessages * 1000);
  }

  unknownChatsChanged() {
    this.customFilter.use = false;
    this.sortMostEvents = false;
  }
}
