export enum NxtPromoOfferState {
  Open = 'open',
  Assigned = 'assigned',
  Canceled = 'canceled',
  PayoutReady = 'payout-ready',
  PaidOut = 'paid-out'
}

export type NxtPromoStudioShort = 'AC' | 'FFM' | 'AB' | 'NU' | 'MA' | 'DU' | 'BR' | 'STAGING' | 'ZONA' | 'DO' | 'MG' | 'IB';

/*export enum NxtPromoStudioShort {
    AC = 'AC',
    FFM = 'FFM',
    AB = 'AB',
    NU = 'NU',
    MA = 'MA',
    DU = 'DU'
}*/

export interface NxtPromoOffer {
  payoutSum: number;
  studio: NxtPromoStudioShort;
  id: string;
  promoId: string;
  promoterId: string;
  promoterName: string;
  createdAt: number;
  state: NxtPromoOfferState;
  payoutDateString: string;
  payoutMonthString: string;
  canMultiAssign: boolean;
  assignments: {
    createdAt: number;
    eventStart?: number;
    customerGivenName?: string;
    customerPaymentSum?: number;
    eventId: string;
    price: number;
    commission: NxtPromoCommission;
    artistPercentage: number;
  }[];
  promoTitle: string;
  selfGenerated: boolean;
  createdAtDateString: string;
  createdAtMonthString: string;
  team: string;
  staticCodeInfo: string;
  ip: string;
  mobile: string;
  // commission?: NxtPromoCommission;
}

export interface NxtPromoPromotion {
  id: string;
  title: string;
  description: string;
  discountPromotionId: string;
  enableFrom: number;
  enableTill: number;
  canMultiAssign: boolean;
  active: boolean;
  codePositionX: number;
  codePositionY: number;
  studio: NxtPromoStudioShort;
  canCreateStaticCode: boolean;
  noCode?: boolean;
  onlyExtStaticCode?: boolean;
  disableCron?: string;
  onlyNxtPromoter?: boolean;
  minPersonCount?: number;
  maxPersonCount?: number;
  codeFontSize?: number;
}

export interface NxtPromoPromotionWithImage extends NxtPromoPromotion {
  imgBase64: string;
}

export interface NxtPromoPromotionImage {
  id: string;
  imgBase64: string;
}

export interface NxtPromoUserPercentage {
  startDateString: string;
  endDateString: string;
  percentage: number;
}

export interface NxtPromoUser {
  studio: NxtPromoStudioShort;
  team: string;
  id: string;
  promoterId: string;
  name: string;
  email: string;
  mobile: string;
  photoURL: string;
  domain: string;
  promoterPercentage: number;
  isNxtPromoter: boolean;
  disabled: boolean;
  createdAt: number;
  promoterPercentages?: NxtPromoUserPercentage[];
  infoInternal?: string;
  permissions?: string[];
  isDummy?: boolean;
}


export interface NxtPromoPromoterPayout {
  promoterId: string;
  monthString: string;
  events: { eventId: string, startDateString: string, commission: NxtPromoCommission, artistGet: number, price: number }[];
  payoutSum: number;
  artistSum: number;
  priceSum: number;
  promoterName: string;
  dateString: string;
  studio: NxtPromoStudioShort,
  isPaidDateTime?: number;
}

/*export interface NxtPromoPromoterPayoutDay extends NxtPromoPromoterPayoutMonth {

}*/

export interface NxtPromiAssignmentRankingEvent {
  id: string,
  artistGet?: number,
  price?: number,
  commission: number,
  offerCreatedAt: number;
  eventCreatedAt: number;
  offerAssignedAt: number;
  eventStart: number;
}

export interface NxtPromoAssignmentRanking {
  id: string;
  dateString: string;
  promoterName: string;
  events: NxtPromiAssignmentRankingEvent[];
  lastAssignment: number;
  promoterId: string;
  team: string;
  eventCount: number;
  studio: NxtPromoStudioShort
}

export interface NxtPromoAssignmentRankingAdmin extends NxtPromoAssignmentRanking {
  payoutSum: number;
  artistSum: number;
  priceSum: number;
}

export enum NxtPromoPermission {
  SeeAllOffers = 'SeeAllOffers',  // Alle Scanns sehen
  SeeAllPayoutDays = 'SeeAllPayoutDays',
  IsJulian = 'IsJulian',
  SuperAdmin = 'SuperAdmin',
  SeeRankingMoney = 'SeeRankingMoney',
  PromoterAdmin = 'PromoterAdmin'
}

export type NxtPromoPermissionType =
  'SeeAllOffers' |
  'SeeAllPayoutDays' |
  'IsJulian' |
  'SeeRankingMoney' |
  'PromoterAdmin' |
  'SuperAdmin'

export interface NxtPromoWorkingTimeState {
  start: number;
  end: number;
  dateString: string;
  promoterId: string;
}

export interface NxtPromoCommission {
  refValue: number;
  value: number;
  percentage: number;
  state: NxtPromoCommissionState;
  eventIds: string[];
}

export enum NxtPromoCommissionState {
  NotSet = 'notSet',
  Precalculated = 'precalculated',
  Fix = 'fix'
}
