<div class="nxt-page flex h-full flex-col">
  <div class="flex flex-col">
    <div class="flex flex-row">
      <nxt-date-range-picker [value]="fromTill()" (valueChange)="fromTillChanged($event)"></nxt-date-range-picker>
      <nxt-slide-toggle [(value)]="show20Percentage">20%</nxt-slide-toggle>
      <nxt-slide-toggle [(value)]="show40Percentage">40%</nxt-slide-toggle>
      <nxt-slide-toggle [(value)]="show50Percentage">50%</nxt-slide-toggle>
      <nxt-slide-toggle [(value)]="showDaySessionCards">Day-Session-Cards</nxt-slide-toggle>
    </div>
    <div>
      <table class="nxt-table">
        <tr>
          <td>Gesamtwert</td>
          <td>{{ totalValue() | money }}</td>
        </tr>
        <tr>
          <td>Bezahlt</td>
          <td>{{ paymentValue() | money }}</td>
        </tr>
        <tr>
          <td>nicht zugewiesen</td>
          <td>{{ openValue() | money }}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="flex h-full">
    <nxt-datagrid
      class="h-full"
      [rowData]="discountGiftCardItems()"
      [columnDefs]="columnDefs"
      [showFooter]="true"
    >
    </nxt-datagrid>
  </div>

</div>
