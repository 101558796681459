import {Component, Input, OnInit} from '@angular/core';
import {NxtFormControl} from '../../nxt-form/nxt.form-control';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgIf, NgStyle} from '@angular/common';

@Component({
  selector: 'nxt-form-field-wrapper',
  templateUrl: './form-field-wrapper.component.html',
  styleUrls: ['./form-field-wrapper.component.scss'],
  imports: [NgIf, FlexModule, NgStyle, ExtendedModule],
  standalone: true,
})
export class FormFieldWrapperComponent implements OnInit {


  @Input() alignItems = 'center';

  @Input()
  nxtFormControl: NxtFormControl;

  @Input() width = '100%';
  backgroundRed = false;
  dirty = false;

  constructor() {
    console.log();
  }

  ngOnInit() {
    if (!this.nxtFormControl) {
      throw new Error(this.constructor.name + ' nxtFormControl ist not set :(');
    }
    this.nxtFormControl.valueChanges.subscribe(() => {
      this.backgroundRed = this.nxtFormControl && this.nxtFormControl.invalid && !this.nxtFormControl.touched;
      this.dirty = this.nxtFormControl && this.nxtFormControl.dirty;
    });
  }


}
