import {Injectable} from '@angular/core';

declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class GooglePlaceService {

  private autocompleteService: google.maps.places.AutocompleteService;
  private placesService: google.maps.places.PlacesService;

  constructor() {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
  }


  find(searchText: string): Promise<any[]> {
    if (!searchText || searchText.length === 0) {
      return Promise.resolve([]);
    }
    return new Promise((resolve, reject) => {
        this.autocompleteService.getPlacePredictions({
            types: ['address'],
            input: searchText,
            componentRestrictions: {country: 'de'}
          },
          (result) => {
            resolve(result);
          });
      }
    );
  }

  getPostalCode(placeId: string) {
    return new Promise((resolve, reject) => {
      console.log('start getDetails: ' + placeId);
      this.placesService.getDetails({placeId, fields: ['address_component']},
        (result) => {
          console.log(result);

          if (result && result.address_components && result.address_components.length > 0) {
              const postalCode = result.address_components.find(a => a.types.indexOf('postal_code') > -1);
              if (postalCode && postalCode.long_name) {
                console.log('JAAAAAAAAAAAAA');
                console.log('JAAAAAAAAAAAAA');
                console.log('JAAAAAAAAAAAAA');
                console.log('resolve getDetails', postalCode);
                resolve(postalCode.long_name);
              }
            }
        });
    });
  }
}
