<div class="nxt-page" fxLayout="column">
  <nxt-button-icon *ngIf="dialogRef" style="position: absolute;right: 10px;"
                   (click)="dialogRef.close()"
                   tooltip="schließen"
                   icon="close"
  ></nxt-button-icon>
  <div fxLayout="row" fxLayoutAlign="start center">
    <!--<ng-month-picker
      [(ngModel)]="currentMonth"
      displayFormat="M/YYYY"
      name="declarations-of-consent"
      [showIcon]="false"
    ></ng-month-picker>-->

    <nxt-date-range-picker
      fxFlex="350px"
      [(value)]="dateFromTill"
      (valueChange)="dateChanged()"
      placeholder="Rechnungsdatum"
    ></nxt-date-range-picker>

    <nxt-button (click)="findClicked()">Finden</nxt-button>
  </div>
  <div *ngIf="data && data.length === 0" class="nxt-text">Keine Rechnungen gefunden</div>
  <nxt-datagrid
    *ngIf="data && data.length > 0"
    fxFlex
    [rowData]="data"
    [columnDefs]="columnDefs"
    [pagination]="true"
  >
  </nxt-datagrid>
</div>
