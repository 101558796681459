import {NxtCalendarEvent, NxtWorkType} from '../../common-interfaces/nxt.calendar-event.interface';

export class EventConsentTools {

  static hastCorrectPageCount2(event: NxtCalendarEvent): boolean {
    let correctPageSize: boolean;
    if (event.files?.some(e => e.subType === 'consent' && e.consentData?.correctPageCount)) {
      correctPageSize = true;
    } else {
      correctPageSize = EventConsentTools.hastCorrectPageCount(event.workType, event.pdf1Count);
    }
    return correctPageSize;
  }

  static getConsentState(event: NxtCalendarEvent): 'missing' | 'invalid' | 'valid' {
    const consentFiles = event.files?.filter(e => e.subType === 'consent');
    if (!consentFiles || consentFiles.length === 0) {
      return 'missing';
    }
    if (consentFiles.some(c => c.consentData?.correctPageCount && c.consentData?.hasAllCustomerSigns)) {
      return 'valid';
    }
    return 'invalid';
  }

  static hasConsent(event: NxtCalendarEvent): boolean {
    if (event.files?.some(e => e.subType === 'consent')) {
      return true;
    }
    return (event?.pdf1Count || 0) > 0;
  }

  static hastCorrectPageCount(workType: NxtWorkType, pdf1Count: number | undefined | null): boolean {
    if (typeof pdf1Count !== 'number') {
      return false;
    }
    if (workType === 'tattoo') {
      return [4, 5].includes(pdf1Count ?? 0);
    } else if (workType === 'piercing') {
      return [2, 3].includes(pdf1Count ?? 0);
    }
    return false;
  }

  static getBestConsent(event: NxtCalendarEvent) {
    if (!event.files) {
      return;
    }
    const allConsentFiles = event.files.filter(e => e.subType === 'consent');
    let consentFiles = allConsentFiles.filter(e => {
      return e.consentData?.correctPageCount
        && e.consentData.hasAllCustomerSigns
        && e.consentData.hasArtistSign
        && e.consentData.hasAllDates;
    });
    if (consentFiles.length > 0) {
      return consentFiles[0];
    }
    let consentFile = event.files?.find(e => e.subType === 'consent' && e.consentData?.correctPageCount && e.consentData.hasAllCustomerSigns);
    if (consentFile) {
      return consentFile;
    }
    consentFile = event.files?.find(e => e.subType === 'consent' && e.consentData?.correctPageCount);
    if (consentFile) {
      return consentFile;
    }
    consentFile = event.files?.find(e => e.subType === 'consent');
    if (consentFile) {
      return consentFile;
    }
  }

  static hasTattooPhoto(event: NxtCalendarEvent) {
    if (event.files?.some(e => e.subType === 'tattooPhoto')) {
      return true;
    }
    return (event?.mediaCount.tattooPhoto || 0) > 0;
  }
}
