<div class="flex flex-col w-full h-full" *ngIf="item">
  <div class="text-130 center w-full mb-3">Pakete für "{{ item.name }}"</div>
  <nxt-datagrid
    class="flex w-full h-full min-h-40 min-w-[500px]"
    [columnDefs]="colDefs"
    [rowData]="item.packages"
    [autoSizeColumnOnResize]="true"
  >
  </nxt-datagrid>
  <div class="flex flex-row w-full justify-around mt-3">
    <nxt-button (click)="closeClicked()">Zurück</nxt-button>
    <nxt-button (click)="newPackageClicked()">Neues Paket</nxt-button>
  </div>
</div>
