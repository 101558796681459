import {NxtCalendarEvent} from '../../common-interfaces/nxt.calendar-event.interface';
import {DateTools} from './date.tools';
import {NxtNgConfigStudio} from '../../common-interfaces/nxt.ng-config.interface';
import {EmojiTools} from './emoji.tools';
import {EventTools} from './event.tools';
import {DurationTools} from './duration.tools';

export enum NxtTextTemplateType {
  CustomerStencilSeenMessage = 'CustomerStencilSeenMessage',
  AfterCareInstructionSentMessage = 'AfterCareInstructionSentMessage',
  AppointmentReminder = 'AppointmentReminder',
}

export interface NxtTextTemplate {
  id: string;
  type: NxtTextTemplateType;
  lang: string;
  text: string;
  disabled?: boolean;
}

export interface NxtTextTemplateAvailablePlaceholder {
  placeholder: string;
  refObjField?: string;
  refObj?: 'event' | 'contact' | 'database' | 'ngConfigStudio';
  valueFormat?: (value: any, lang: string) => string;
  valueGetter?: (refObj: any) => Promise<any>;
}

export interface NxtTextTemplateAvailableVariable {
  name: string;
}

export interface NxtTextTemplateAvailableBlockConfig {
  placeholder: string,
  description: string,
  refObj: string,
  fn: (params: any, refObj: any) => Promise<boolean>
}

export interface NxtTextTemplateDef {
  type: NxtTextTemplateType;
  description: string;
  availablePlaceholders: NxtTextTemplateAvailablePlaceholder[];
  availableBlockConfigs: NxtTextTemplateAvailableBlockConfig[];
  availableVariables: NxtTextTemplateAvailableVariable[];
}

export class TextTemplateTools {

  public static variables: { [name: string]: NxtTextTemplateAvailableVariable } = {
    whatsAppMessageId: {name: 'whatsapp-message-id'},
  };

  public static placeholders: { [name: string]: NxtTextTemplateAvailablePlaceholder } = {
    customerGivenNameFromEvent: {placeholder: 'customer-given-name', refObjField: 'customerObj.givenName', refObj: 'event'},
    customerGivenNameFromContact: {placeholder: 'customer-given-name', refObjField: 'givenName', refObj: 'contact'},
    startDateFromEvent: {
      placeholder: 'appointment-start-date', refObjField: 'startDate', refObj: 'event',
      valueFormat: (value: number, lang: string) => {
        return new Date(value).toLocaleDateString(lang);
      },
    },
    startTimeFromEvent: {
      placeholder: 'appointment-start-date', refObjField: 'startTime', refObj: 'event', valueFormat: (value: number, lang: string) => {
        return value.dateFormat('HH:mm');
      },
    },
    studioAddressOneLine: {placeholder: 'studio-address-one-line', refObj: 'ngConfigStudio', refObjField: 'addressOneLine'},
    // whatsAppMessageId: {placeholder: 'whatsapp-message-id', refObjField: 'whatsapp-messages', refObj: 'database'}
  };

  public static blockConfigs: { [name: string]: NxtTextTemplateAvailableBlockConfig } = {
    orSkill: {
      placeholder: 'skills-or',
      description: '',
      refObj: 'event',
      fn: async (orSkills: string, event: NxtCalendarEvent) => {
        const skills = EventTools.getSkillsCombined(event);
        for (const orSkill of orSkills.split(',')) {
          if (skills.includes(orSkill.trim())) {
            return true;
          }
        }
        return false;
      },
    },
    timeBetween: {
      placeholder: 'time-now-between',
      description: '',
      refObj: 'datetimeNow',
      fn: async (param: string, now: number) => {
        let [from, to] = param.split(',');
        if (!from.includes(':')) {
          from += ':00';
        }
        if (!to.includes(':')) {
          to += ':00';
        }
        const fromTime = DurationTools.parse(from.trim());
        const toTime = DurationTools.parse(to.trim());
        const nowTime = now - now.clearTime();
        return nowTime >= fromTime && nowTime < toTime;
      },
    },
  };

  public static TextTemplateDefs: NxtTextTemplateDef[] = [
    {
      type: NxtTextTemplateType.CustomerStencilSeenMessage,
      description: 'Wird an den Kunden verschickt, sobald der Stencil gesehen wurde',
      availablePlaceholders: [TextTemplateTools.placeholders.customerGivenNameFromEvent],
      availableBlockConfigs: [TextTemplateTools.blockConfigs.orSkill],
      availableVariables: [],
    }, {
      type: NxtTextTemplateType.AfterCareInstructionSentMessage,
      description: 'Wird an den Kunden verschickt, nach dem die Pflegehinweise geschickt wurden',
      availablePlaceholders: [TextTemplateTools.placeholders.customerGivenNameFromContact],
      availableVariables: [TextTemplateTools.variables.whatsAppMessageId],
      availableBlockConfigs: [TextTemplateTools.blockConfigs.timeBetween],
    },
    {
      type: NxtTextTemplateType.AppointmentReminder,
      description: 'Wird ein Tag vor dem Termin an den Kunden geschickt',
      availablePlaceholders: [
        TextTemplateTools.placeholders.customerGivenNameFromContact,
        TextTemplateTools.placeholders.startDateFromEvent,
        TextTemplateTools.placeholders.startTimeFromEvent,
        TextTemplateTools.placeholders.studioAddressOneLine,
      ],
      availableVariables: [],
      availableBlockConfigs: [],
    },
  ];

  public static generateReminderText(template: string, event: NxtCalendarEvent, studioConfig: NxtNgConfigStudio, studio: 'ac' | 'du' | string, lang: string) {
    const customerGivenName = event.customerObj?.givenName ?? '';
    const dateString = DateTools.format(event.start, 'dd.MM.yyyy');
    const timeString = DateTools.format(event.start, 'HH:mm') + ' bis ca. ' + DateTools.format(event.end, 'HH:mm');
    const dateTimeString = DateTools.format(event.start, 'EEEE, dd.MM.yyyy HH:mm') + ' bis ca. ' + DateTools.format(event.end, 'HH:mm');

    let studioAddress = studioConfig.addressOneLine;
    if (studioConfig.googleMapsLink) {
      studioAddress += ' ' + studioConfig.googleMapsLink;
    }
    if (event.workType === 'tattoo') {
      // template = template.replace('{{einverständniserklärung_link}}', studioConfig.acceptanceLinkTattoo);
      template = template.replace('{{aftercare_link}}', 'https://aftercare.nxt-lvl.ink/' + studio + '/tattoo-' + lang + '.pdf');
    } else if (event.workType === 'piercing') {
      // template = template.replace('{{einverständniserklärung_link}}', studioConfig.acceptanceLinkPiercing);
      template = template.replace('{{aftercare_link}}', 'https://aftercare.nxt-lvl.ink/' + studio + '/piercing-' + lang + '.pdf');
    } else if (event.workType === 'tooth-gem') {
      // template = template.replace('{{einverständniserklärung_link}}', studioConfig.acceptanceLinkToothGem);
      template = template.replace('{{aftercare_link}}', 'https://aftercare.nxt-lvl.ink/' + studio + '/tooth-gem-' + lang + '.pdf');
    }
    template = template.replace('{{uhr_icon}}', EmojiTools.getClock(event.start));
    template = template.replace(/{{kunde_name}}/g, customerGivenName);
    template = template.replace(/{{termin_datum}}/g, dateString);
    template = template.replace(/{{termin_uhrzeit}}/g, timeString);
    template = template.replace(/{{studio_adresse}}/g, studioAddress);
    template = template.replace(/{{einverständniserklärung_link}}/g, studioAddress);
    template = template.replace(/{{datum_uhrzeit}}/g, dateTimeString);
    return template;
  }
}
