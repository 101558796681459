<div class="flex flex-col w-full h-full text-[10px] page-wrapper p-relative">

  <div class="fixed-header text-xl" [class.center]="pageIndexCurrent === 0">

    <div class="flex flex-row">
      @if (pageIndexCurrent + 1 <= pageIndexTotal) {
        <div [nxtTranslate]="lang">SEITE</div>&nbsp;{{ (pageIndexCurrent + 1) }} / {{ pageIndexTotal }}
      } @else {
        <div [nxtTranslate]="lang">Zusatzseite</div>
      }
    </div>
  </div>

  <div class="flex justify-center p-3" *ngIf="pageIndexCurrent > 0">
    <div class="flex flex-col justify-center items-center">
      <div>
        <img src="../../../../assets/logo/logo_black.svg" style="width: 6cm"/>
      </div>
      <div class="flex flex-row mt-1 logo-text">
        <div *ngIf="false">NXT-LVL.INK {{ configService.config.value.studioName.toUpperCase() }}</div>
        <div class="flex flex-row">
          {{ configService.config.value.invoiceData.fromCompany }}
        </div>
        <div class="flex flex-row" *ngIf="configService.config.value.invoiceData.fromCompany2">
          <div class="gold">・</div>
          {{ configService.config.value.invoiceData.fromCompany2 }}
        </div>
        <div class="flex flex-row">
          <div class="gold">・</div>
          {{ configService.config.value.invoiceData.fromStreet }}
        </div>
        <div class="flex flex-row">
          <div class="gold">・</div>
          {{ configService.config.value.invoiceData.fromPostalCode }} {{ configService.config.value.invoiceData.fromCity }}
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-around flex-row p-3" *ngIf="pageIndexCurrent === 0">
    <div class="flex flex-col justify-center items-center grow w-full" style="border: 0px solid red">
      <div>
        <img src="../../../../assets/logo/logo_black.svg" style="width: 8cm"/>
      </div>
      <div class="flex flex-col mt-1 logo-text-left items-center w-full text-[20px]">
        <div>NXT-LVL.INK {{ configService.config.value.invoiceData.fromCity.toUpperCase() }}</div>
        <div class="flex flex-row">
          {{ configService.config.value.invoiceData.fromCompany }}
        </div>
        <div class="flex flex-row" *ngIf="configService.config.value.invoiceData.fromCompany2">
          {{ configService.config.value.invoiceData.fromCompany2 }}
        </div>
        <div class="flex flex-row">
          {{ configService.config.value.invoiceData.fromStreet }}
        </div>
        <div class="flex flex-row">
          {{ configService.config.value.invoiceData.fromPostalCode }} {{ configService.config.value.invoiceData.fromCity }}
        </div>
      </div>
    </div>

    <div class="flex justify-end">
      <div style="border: 1px dotted black; width: 6cm; height: 8cm" class="flex justify-center items-center">
        <div>QR-CODE</div>
      </div>
    </div>

  </div>

  <div class="flex text-3xl flex-col items-center pb-3 header"
       *ngIf="pageIndexCurrent > 0 && pageIndexCurrent <= pageIndexTotal">
    <div [nxtTranslate]="lang">EINWILLIGUNGSERKLÄRUNG</div>
  </div>

  <div class="flex text-2xl flex-col items-center pb-3 header"
       *ngIf="pageIndexCurrent === 4">
    <div [nxtTranslate]="lang">EINWILLIGUNG ERZIEHUNGSBERECHTIGTE(R)</div>
  </div>

  <div *ngIf="pageIndexCurrent === 0">
    <br/>
    <br/>
    <nxt-declarations-of-consent-section titleText="ALLGEMEINE INFORMATIONEN" [lang]="lang">
      <div class="py-3 px-10">
        <table class="w-full text-[16px] info-table">
          <tr>
            <td>❖️</td>
            <td [nxtTranslate]="lang">Hat dein Tattoo-Termin einen Fixpreis, verändert sich der Preis nicht,<br/>
              wenn es schneller fertig ist oder etwas länger dauert.<br/>
              (Brichst du den Termin ab, werden die Fixkosten erneut fällig)<br/>
            </td>
          </tr>
          <tr>
            <td>❖️</td>
            <td [nxtTranslate]="lang">Hast du eine Tagessession von 11-18 Uhr gebucht,<br/>endet diese um spätestens 18 Uhr.<br/>
              Sollte dein Tattoo nicht fertig sein, kannst du mit unserem Personal einen Folgetermin zu Fertigstellung
              vereinbaren.
            </td>
          </tr>
          <tr>
            <td [nxtTranslate]="lang">❖️</td>
            <td [nxtTranslate]="lang">Bei Änderungen des Motives muss der Preis ggf. angepasst werden</td>
          </tr>
          <tr>
            <td [nxtTranslate]="lang">❖️</td>
            <td [nxtTranslate]="lang">Dein gewünschtes Motiv besprechen wir zusammen mit dem Artist</td>
          </tr>
          <tr>
            <td>❖️</td>
            <td [nxtTranslate]="lang">Je nachdem wie groß dein Tattoo werden soll, dauert die Vorbereitung ca. eine Stunde.</td>
          </tr>
          <tr>
            <td>❖️</td>
            <td class="red" [nxtTranslate]="lang">Achtung!!! Achte bitte darauf, dass die Tattoo-Vorlage richtig ist
              (nicht Spiegelverkehrt), die Daten stimmen und Namen richtig geschrieben sind
            </td>
          </tr>
          <tr>
            <td>❖️</td>
            <td class="red" [nxtTranslate]="lang">Achtung!!! Achte bitte darauf, dass das Stencil vor dem Stechen an der von dir gewünschten Position ist!</td>
          </tr>
          <tr>
            <td>❖️</td>
            <td [nxtTranslate]="lang">Während der Session werden kurze Pausen gemacht, es sei denn du wünschst
              längere
            </td>
          </tr>
          <tr>
            <td colspan="2" [nxtTranslate]="lang">Wir wünschen dir viel Spaß bei deinem Tattoo-Termin!</td>
          </tr>
          <tr>
            <td colspan="2" [nxtTranslate]="lang">Mit deiner Unterschrift bestätigst du, dass du die Information gelesen und verstanden
              hast.
            </td>
          </tr>
        </table>
      </div>
    </nxt-declarations-of-consent-section>


  </div>

  <div *ngIf="pageIndexCurrent === 1">
    <nxt-declarations-of-consent-section titleText="DEINE DATEN" [lang]="lang">
      <div class="flex flex-row w-full gap-3">
        <div class="flex flex-col w-full">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Vor + Nachname</div>
        </div>
        <div class="flex flex-col w-full">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Straße + Hausnummer</div>
        </div>
        <div class="flex flex-col w-full">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">PLZ + Ort</div>
        </div>
      </div>
      <div class="flex flex-row w-full gap-3">
        <div class="flex flex-col w-full">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Geburtsdatum</div>
        </div>
        <div class="flex flex-col w-full">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Handy</div>
        </div>
        <div class="flex flex-col w-full">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Personalausweisnummer</div>
        </div>
      </div>
    </nxt-declarations-of-consent-section>
    <nxt-declarations-of-consent-section [lang]="lang" titleText="DEINE GESUNDHEITSDATEN">
      <div>
        <div [nxtTranslate]="lang">Um mögliche individuelle Risiken rechtzeitig erkennen und einschätzen zu können,
          ist die sorgfältige Beantwortung der Fragen unerlässlich.
        </div>
        <div class="pb-1" [nxtTranslate]="lang">Zutreffendes bitte ankreuzen:</div>
      </div>
      <div class="flex flex-row">
        <div class="flex flex-col w-1/2">
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Es besteht eine Bluterkrankung oder erhöhte Blutungsneigung."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Es besteht eine Hauterkrankung (Neurodermitis, Schuppenflechte etc.)."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich leide an Diabetes."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich leide an Sarkoidose."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Es bestehen Autoimmunerkrankungen."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich bin Asthmatiker."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich nehme Medikamente zur Blutverdünnung (Marcumar, Aspirin, Heparin etc.)."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich habe eine Herzerkrankung, Herzrhythmusstörung bzw. trage ein Herzschrittmacher."/>
        </div>
        <div class="flex flex-col w-1/2">
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich habe einen zu hohen bzw. zu niedrigen Blutdruck."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich habe Kreislaufprobleme und/oder neige zu Ohnmachtsanfällen."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Es besteht eine chronische Infektionskrankheit (z.B. AIDS, Hepatitis, MRSA)."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Es bestehen andere schwerwiegende chronische Leiden (z.B. Epilepsie oder Lähmungen)."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Es bestehen Erkrankungen, die zu einem geschwächten Immunsystem führen."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich leide an Wundheilstörung."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich neige zu starker Narbenbildung."/>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich stehe unter gerichtlicher Betreuung."/>
        </div>
      </div>
    </nxt-declarations-of-consent-section>
    <nxt-declarations-of-consent-section titleText="MÖGLICHE KOMPLIKATIONEN" [lang]="lang">
      <div class="pb-1" [nxtTranslate]="lang">Auch bei sachgemäßer Ausführung des Tattoos
        besteht das Risiko folgender, unerwünschter
        Nebenerscheinungen:
      </div>
      <div class="flex flex-row">
        <div class="flex flex-col w-1/2">
          <div [nxtTranslate]="lang">・Entzündung (Rötung, Schwellung, Lymphknotenschwellung)</div>
          <div [nxtTranslate]="lang">・Schmerzen / Missempfindung</div>
          <div [nxtTranslate]="lang">・Kreislaufprobleme</div>
          <div [nxtTranslate]="lang">・Allergien</div>
          <div [nxtTranslate]="lang">・Wundheilungsstörungen</div>
        </div>
        <div class="flex flex-col w-1/2">
          <div [nxtTranslate]="lang">・Infektionen</div>
          <div [nxtTranslate]="lang">・Gewebenekrose</div>
          <div [nxtTranslate]="lang">・Bildung von Abszessen</div>
          <div [nxtTranslate]="lang">・Blutung / Hämatombildung</div>
          <div [nxtTranslate]="lang">・Dauerhafte Narbenbildung</div>
        </div>
      </div>
      <div class="pt-2" [nxtTranslate]="lang">
        Ich hatte ausreichend Zeit und Gelegenheit meine Entscheidung zu fällen. Eine Pflegeanleitung, sowie die
        benötigten Infos von Pflegemitteln wurden mir
        ausgehändigt. Ich stehe nicht unter dem Einfluss von Drogen, Alkohol oder Medikamenten und bin in vollem Besitz
        meiner geistigen und körperlichen
        Kräfte. Sollten Komplikationen in Folge eines Tattoos ärztlich behandelt werden müssen, so kann es sein, dass
        die Krankenversicherung mich an den Kosten
        beteiligt. Durch meine Unterschrift unter dieser Einwilligungserklärung stimme ich dem Tattoo ausdrücklich zu.
      </div>
    </nxt-declarations-of-consent-section>
    <nxt-declarations-of-consent-section titleText="DATENSCHUTZ" [lang]="lang">
      <div class="pb-1" [nxtTranslate]="lang">Die Daten auf diesem Erfassungsbogen benötigen
        wir zur ordnungsgemäßen Durchführung unserer
        Arbeit. Mit deiner Unterschrift willigst du in unsere
        ausliegende DS-Vereinbarung (Stand 22.01.2019) ein.
      </div>
    </nxt-declarations-of-consent-section>
  </div>

  <div *ngIf="pageIndexCurrent === 2">
    <nxt-declarations-of-consent-section titleText="VERTRAGSPARTNER" [lang]="lang">
      <div class="pb-2" [nxtTranslate]="lang">Die Tätowierer im Tattoo-Studio NXT-LVL.INK sind selbständig tätig, NXT-LVL.INK stellt lediglich
        Räume und Serviceleistungen zur Verfügung. Wir klären Dich daher hiermit ausdrücklich darüber auf, dass das
        Geschäft (Dienstleistungsvertrag für die Tätowierung) nur zwischen Dir und dem Tätowierer abgeschlossen wird.
        Evtl. Haftungsansprüche sind an diesen zu stellen. Unabhängig davon sind wir, NXT-LVL.INK, um zufriedene Kunden
        bemüht und nehmen eure Reklamationen im Auftrag der Tätowierer gerne entgegen. Evtl. Kompensationen seitens
        NXT-LVL.INK zu Reklamationen erfolgen jedoch auf Basis von Kulanz ohne Anerkennung einer dementsprechenden
        Rechtspflicht.
      </div>
    </nxt-declarations-of-consent-section>
    <nxt-declarations-of-consent-section [lang]="lang" titleText="FÜLLT DER TÄTOWIERER AUS">
      <div class="pb-1 font-bold" [nxtTranslate]="lang">ERKLÄRUNG DES TÄTOWIERERS:
      </div>
      <div class="flex flex-row">
        <div class="flex flex-col w-1/2 pb-1 pr-2">
          <div [nxtTranslate]="lang">
            Ich bestätige, dass die Tätowierung unter hygienischen Bedingungen und der Verwendung von geeigneten,
            sterilen Instrumenten und sicheren Verfahren nach EN 17169 durchgeführt wird.
            <br/>
            Für diesen Eingriff wird von mir kein Betäubungsmittel eingesetzt.
            <br/>
            Ich bestätige, dass dem Kunden eine Kopie der unterschriebenen Einverständniserklärung angeboten wurde.
          </div>
          <br/>
          <div [nxtTranslate]="lang">
            I confirm that the tattoo will be performed under hygienic conditions and using suitable, sterile
            instruments and safe
            procedures according to EN 17169.
            <br>
            No anesthetic will be used by me for this procedure.
            <br>
            I confirm that a copy of the signed consent form was offered to the
            customer.
          </div>
        </div>

        <div class="flex flex-col w-1/2 pb-1 pl-2 h-full justify-end items-center">
          <div>
            <div style="height: 3cm"></div>
            <div class="flex flex-col">
              <div style="border-bottom: 1px solid black; width: 7cm">
                <img src="../../../assets/images/sign-here-3.svg" style=" width: 20px;"/>
              </div>
              <div>Signature Artist</div>
            </div>
          </div>
        </div>
      </div>

    </nxt-declarations-of-consent-section>
    <nxt-declarations-of-consent-section titleText="DU BESTÄTIGST" [lang]="lang">
      <div class="flex flex-col">
        <div class="pb-2" [nxtTranslate]="lang">
          Mit meiner Unterschrift bestätige ich, zum Zeitpunkt des Termins:
        </div>
        <div class="flex flex-row">
          <div class="flex w-1/2 pr-2 flex-col">
            <nxt-declarations-of-consent-checkbox
              [lang]="lang"
              text="Ich habe das 18te Lebensjahr vollendet."
              [checked]="true"/>
            <nxt-declarations-of-consent-checkbox
              [lang]="lang"
              text="Es bestehen keine akuten Infektionskrankheiten (grippaler Infekt etc.)."
              [checked]="true"/>
            <nxt-declarations-of-consent-checkbox
              [lang]="lang"
              text="Ich bin nicht schwanger und stille derzeit auch kein Kind."
              [checked]="true"/>
            <nxt-declarations-of-consent-checkbox
              [lang]="lang"
              text="Ich habe in den letzten 24 Stunden kein Alkohol und keine Drogen zu mir genommen."
              [checked]="true"/>
            <nxt-declarations-of-consent-checkbox
              [lang]="lang"
              text="Ich habe auch keine anderen Substanzen zu mir genommen, die meine Wahrnehmung, meinen freien Willen oder mein Urteilsvermögen einschränken könnten."
              [checked]="true"/>
          </div>
          <div class="flex w-1/2 pl-2 flex-col">
            <nxt-declarations-of-consent-checkbox
              [lang]="lang"
              text="Die zu tätowierende Stelle weist kein Narbengewebe (jünger als 12 Monate) oder Keloidnarben auf."
              [checked]="true"></nxt-declarations-of-consent-checkbox>
            <nxt-declarations-of-consent-checkbox
              [lang]="lang"
              text="Ich habe mich in den letzten 12 Monaten keiner Strahlentherapie unterzogen."
              [checked]="true"/>
            <nxt-declarations-of-consent-checkbox
              [lang]="lang"
              text="Ich bin ausgeschlafen und habe ausreichend gegessen und getrunken."
              [checked]="true"/>
            <nxt-declarations-of-consent-checkbox
              [lang]="lang"
              text="Mir ist bewusst, dass das Nachstechen bei Hand-, Fuß- und Fingertattoos nicht inklusive (nicht im Preis inbegriffen) ist."
              [checked]="true"/>
            <nxt-declarations-of-consent-checkbox
              [lang]="lang"
              text="Ich bin auf die <strong>Aufklärung zur Einverständniserklärung, Datenschutzbestimmungen</strong> und <strong>AGB</strong> hingewiesen worden."
              [checked]="true"/>
          </div>
        </div>
        <div class="pt-2 flex flex-col">
          <div class="pb-1" [nxtTranslate]="lang">Ich bestätige, dass die obigen Informationen und Erklärungen korrekt und nach bestem Wissen
            und Gewissen abgegeben wurden.
          </div>
          <div class="pb-1" [nxtTranslate]="lang">Ich bin damit einverstanden, wie vom benannten Tätowierer beschrieben, tätowiert zu
            werden.
          </div>
          <div class="pb-1" [nxtTranslate]="lang">Es gelten darüber hinaus die allgemeinen Geschäftsbedingungen von
            NXT-LVL.INK {{ configService.config.value.studioName }}
            <span
              *ngIf="!configService.config.value.isFranchise"> {{ configService.config.value.invoiceData.fromCompany }}</span>
            ({{ configService.config.value.invoiceData.dateOfCondition | nxtDate: 'dd.MM.yyyy' }}).
          </div>
        </div>
      </div>

    </nxt-declarations-of-consent-section>
  </div>

  <div *ngIf="pageIndexCurrent === 3">
    <br/><br/><br/>
    <nxt-declarations-of-consent-section titleText="WEITERE INFORMATIONEN" [lang]="lang">
      <div class="flex flex-row">
        <div class="text-[8px] w-1/2 flex flex-col p-5">
          <div class="flex flex-col">
            <strong [nxtTranslate]="lang">Aufklärung über den Tätowiervorgang</strong>
            <div [nxtTranslate]="lang">Beim Tätowieren werden mittels Tätowiernadeln Farbpigmente in die 2. Hautschicht gebracht. Dabei handelt es
              sich um einen schmerzhaften Vorgang, bei dem die Haut verletzt wird. Nach § 223 Abs.1 StGB handelt es sich
              damit auch um Körperverletzung, für die wir Deine schriftliche Einwilligung ge-mäß § 228 StGB benötigen. Bei
              einer Tätowierung handelt es sich um ein Handwerk. Daher kann es zwischen der Tätowiervorlage und dem
              Endergebnis immer zu kleinen Abweichungen hinsichtlich Farbe und Form kommen. Außerdem unterliegt auch eine
              Tätowierung in gewissem Umfang der Alterung. So können insbesondere durch starke Sonneneinstrahlung oder
              Solarienbesuche über die Jahre die Farben verblassen. Die Konturen werden über die Jahre ebenfalls
              unschärfer. Tätowierungen sind permanent und können je nach verwendeter Tinte nicht effektiv entfernt
              wer-den. Cover-Ups Soweit es sich bei der Tätowierung um eine Übertätowierung handelt, kann im Vorfeld weder
              vorher-gesagt werden, ob eine Überdeckung der alten Tätowierung überhaupt zu erzielen ist, noch mit welchem
              Zeit- und Arbeitsaufwand. Es kann nicht ausgeschlossen werden, dass es zu Wechselwirkungen mit der Farbe der
              zu überdeckenden Tätowierung kommt. Außerdem kann die alte Tätowierung teil-weise durchscheinen, was sich im
              Laufe der Jahre durch verschiedene Umwelteinflüsse noch verstärken kann. Wurde eine vormals vorhandene
              Tätowierung entfernt oder aufgehellt, besteht bei Überdeckung eine besondere Gefahr, dass die neu zu
              stechende Tätowierung von dem gewünschten Ergebnis ab-weicht. Die Haut kann in ihrer Farbaufnahme
              beeinträchtigt sein oder in besonderem Maße zur Narbenbildung neigen. Dasselbe gilt für das Tätowieren von
              Dehnungsstreifen oder Narben. Hautbeschaffenheit In ungewöhnlichen Fällen kann die Haut aufgrund ihrer
              Beschaffenheit die Pigmente der Tattoofarben nur unzureichend aufnehmen. Dies kann zu unbefriedigenden
              Ergebnissen führen und ist im Vor-feld leider nicht immer absehbar. Mögliche Ursachen hierfür sind
              erhebliche UVExposition sowie Steroidmissbrauch. Wackeln Wir ermahnen Dich als Kunden hiermit ausdrücklich,
              auch wenn der Tätowiervorgang schmerzhaft ist, still zu halten und ruckartige Bewegungen zu vermeiden!
              Solche ruckartigen Bewegungen sind für den Tätowierer unvorhersehbar. Er kann diese trotz leichter Fixierung
              der Hautpartie nicht gänzlich verhindern, sondern lediglich versuchen zu minimieren. Je nach Ausmaß solcher
              ruckartigen Bewegungen durch den Kunden kann es zu mehr oder weniger starken Unregelmäßigkeiten insb. in der
              Linienführung kommen. Mitwirkungspflichten Es ist absolut unerlässlich, dass Du uns vollständig über Deine
              für die Tätowierung relevante Krankengeschichte und Deinen Gesundheitszustand informierst. Dazu geben wir
              Dir auf unserem Kundenerfassungsbogen eine entsprechende Checkliste! Bitte beantworte diese gewissen-haft
              und wahrheitsgemäß. Bei möglichen Risiken bzw. Unklarheiten solltest Du zudem Deinen Arzt konsultieren.
            </div>
          </div>
          <br/>
          <div class="flex flex-col">
            <strong [nxtTranslate]="lang">Vor der Tätowierung</strong>
            <div [nxtTranslate]="lang">Am Tag der Tätowierung darfst Du nicht unter Alkohol-, Drogen- oder Medikamenteneinfluss stehen,
              anderenfalls
              kann die Tätowierung nicht durchgeführt werden. Wir weisen darauf hin, dass es nicht zuletzt aus
              hygienischen
              Gründen erforderlich ist, dass die Kleidungsstücke in dem zu tätowierenden Bereich entfernt werden.
            </div>
          </div>
          <br/>
          <div class="flex flex-col">
            <strong [nxtTranslate]="lang">Nach der Tätowierung</strong>
            Um eine optimale Abheilung und letztendlich ein optimales Ergebnis zu erhalten, ist es unbedingt
            erforderlich, dass Du die Pflegehinweise, die wir Dir übergeben haben, einhältst. Wir weisen darauf hin,
            dass unsere Tätowierer keinerlei Haftung oder Gewährleistung bei Nichteinhaltung der Pflegehinweise
            übernehmen können.
          </div>
        </div>

        <div class="text-[8px] w-1/2 flex flex-col p-5">
          <strong [nxtTranslate]="lang">Risiken und Nebenwirkungen</strong>
          <div [nxtTranslate]="lang">Trotz größter Sorgfalt sowie erprobter Techniken und Arbeitsmaterialien kann es während oder nach dem
            Tätowieren zu Nebenwirkungen oder Komplikationen kommen. Zu nennen sind:
          </div>
          <table class="info-table-small">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Kreislaufprobleme, Übelkeit oder Ohnmacht während des Tätowierens.</td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Nässen der frischen Tätowierung nach dem Tätowieren, was in der Regel nach kurzer Zeit
                abklingt.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Anschwellen der Haut oder anderen Hautirritationen nach dem Tätowieren, welche in der
                Regel nach wenigen Tagen nachlassen.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Juckreiz oder ein leichtes Brennen während des Abheilprozesses, was in der Regel nach
                wenigen Tagen nachlässt.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Ungewollte Farbverläufe (sog. Blow-Outs) aufgrund eines ungünstigen Bindegewebes.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Es kann nach dem Tätowieren zu mehr oder weniger ausgeprägter Schorf- und Krustenbildung kommen.
                Dieser Schorf fällt jedoch nach wenigen Tagen von allein ab.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">In seltenen Fällen: Narbenbildung an der tätowierten Stelle.</td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Photosensitivität der Tätowierung.</td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Auftreten von Keloiden oder Sarkoidosen.</td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Allergische Reaktionen aufgrund der eingebrachten Farbpigmente bzw.
                Bindemittel oder aufgrund der verwendeten Salben.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Trotz größter Sorgfalt hinsichtlich Sauberkeit und Hygiene - vor allem
                in-folge unsachgemäßer Nachbehandlung des Tattoos - Entzündungen, Infektionen und im weiteren Verlauf
                Keimverschleppung. Bei nicht rechtzeitiger ärztlicher Behandlung können dauerhafte und ernste
                Gesundheitsschäden die Folge sein. Bei erheblichen Beeinträchtigungen empfehlen wir, einen Arzt zu
                konsultieren. Aufgrund des § 52 Abs. 2 SGB kann es zu Regressforderungen seitens der Krankenkasse
                kommen.
              </td>
            </tr>
          </table>
          <br/>
          <strong [nxtTranslate]="lang">Einwilligung</strong>
          <div [nxtTranslate]="lang">Mit deiner Unterschrift auf unserer Einwilligungserklärung willigst Du
            in folgende Punkte ein:
          </div>
          <table class="info-table-small">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Ich wurde über Nebenwirkungen, potenzielle Risiken und Komplikationen (z. B. Schmerzen, Schwellungen,
                Übersensibilität und Infektionen) informiert, habe diese Information verstanden und weiß, wie diese
                Risiken minimiert werden können.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Die Nachsorge wurde mir klar und deutlich erklärt und ich verstehe die Handlungen und
                Vorsichtsmaßnahmen, die ich ergreifen muss. Es wurde mir eine eigene, schriftliche Kopie der
                Nachsorgeanweisungen ausgehändigt.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Die Angaben zu meinem Gesundheitszustand sind wahrheitsgemäß und vollständig.</td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Ich wurde darüber aufgeklärt, dass ich bei meinem TattooTermin die Vorlage inkl. Positionierung in
                Augenschein nehmen und jederzeit noch Änderungswünsche äußern kann. Mit der Durchführung des
                Tattoo-Termins bestätige ich, dass die zu stechende Vorlage gestalterisch sowie von der Positionierung
                meinem Wunsch entspricht.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">All dies berücksichtigend willige ich hiermit in die Durchführung der Tätowierung, wie vom be-nannten
                Tätowierer beschrieben, nach § 228 StGB ein.
              </td>
            </tr>
          </table>
        </div>
      </div>

    </nxt-declarations-of-consent-section>
  </div>

  <div *ngIf="pageIndexCurrent === 4">
    <div class="p-6 text-[16px]" [nxtTranslate]="lang">
      Bei Minderjährigen Personen muss diese Einwilligungserklärung von einer erziehungsberechtigten Person
      unterzeichnet und somit bestätigt werden. Mit der Unterschrift wird die ausgeführte Behandlung erlaubt und die
      Richtigkeit der in dieser Erklärung gemachten Daten und Angaben bestätigt.
    </div>
    <nxt-declarations-of-consent-section titleText="ERZIEHUNGSBERECHTIGTE(R) DATEN" [lang]="lang">
      <div class="flex flex-row w-full">
        <div class="flex flex-col w-full">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Vor + Nachname</div>
        </div>
        <div class="flex flex-col pl-5 w-full">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Straße + Hausnummer</div>
        </div>
      </div>
      <div class="flex flex-row w-full">
        <div class="flex flex-col w-full">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">PLZ + Ort</div>
        </div>
        <div class="flex flex-col pl-5 w-full">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Geburtsdatum</div>
        </div>
        <div class="flex flex-col pl-5 w-full">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Personalausweisnummer</div>
        </div>
      </div>
    </nxt-declarations-of-consent-section>
  </div>
  <br/>

  <div class="flex flex-col items-center justify-center footer" *ngIf="signatureName">
    <div class="mb-5 flex flex-row items-end w-full gap-7" style="border: 0px solid blue">
      <div class="flex flex-col w-1/2 items-end" style="border:0px solid red">
        <div class="flex w-fit flex-col">
          <div class="flex flex-row">
            <div style="border-bottom: 1px solid black" class="text-sm">
              {{ configService.config.value.invoiceData.fromCity }}, den
            </div>
            <div style="border-bottom: 1px solid black; width: 4cm" class="text-sm">
            </div>
          </div>
          <div [nxtTranslate]="lang">Ort & Datum</div>
        </div>
      </div>
      <div class="flex flex-col w-1/2" style="border:0px solid green">
        <div style="border-bottom: 1px solid black; width: 7cm">
          <img src="../../../assets/images/sign-here-2.svg" style=" width: 20px"/>
        </div>
        <div [nxtTranslate]="lang" [innerHTML]="signatureName"></div>
      </div>
    </div>
  </div>

  <!--<div class="flex flex-col footer justify-center items-center" *ngIf="signatureName">
    <div class="flex mb-5 flex-row items-end">
      <div class="flex flex-col">
        <div class="flex flex-row">
          <div style="border-bottom: 1px solid black" class="text-sm">
            {{ configService.config.value.invoiceData.fromCity }}, den
          </div>
          <div style="border-bottom: 1px solid black; width: 4cm" class="text-sm">
          </div>
        </div>
        <div [nxtTranslate]="lang">Ort & Datum</div>
      </div>
      <div class="flex flex-col ml-2">
        <div style="border-bottom: 1px solid black; width: 7cm">
          <img src="../../../assets/images/sign-here-2.png" style=" width: 20px"/>
        </div>
        <div [nxtTranslate]="lang" [innerHTML]="signatureName"></div>
      </div>
    </div>
  </div>-->
  <!--<div class="footer-page-counter flex flex-row justify-end w-full">
    @if (pageIndexCurrent + 1 <= pageIndexTotal) {
      <div class="flex flex-row">
        <div [nxtTranslate]="lang">Seite</div>
        {{ pageIndexCurrent + 1 }}/{{ pageIndexTotal }}
      </div>
    } @else {
      <div [nxtTranslate]="lang">Zusatzseite</div>
    }g
  </div>-->
</div>
