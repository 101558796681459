<div class="nxt-page w-full h-100" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="end">
    <nxt-button-icon (click)="close()">close</nxt-button-icon>
  </div>
  <nxt-history
      #historyComponent
      class="w-full h-100"
      table="artist-spot"
      [id]="artistSpotId()"
      [actions]="['update', 'create']"
      [propDefs]="propDefs"
      [prepareRawData]="prepareRawData"
      [hiddenProps]="hiddenProps"
  ></nxt-history>
</div>
