<div *ngIf="nxtFormControl" class="nxt-text" fxFlex fxLayout="column" fxLayoutAlign="stretch">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-around">

    <div *ngFor="let skillValue of boolSkillValues">
      <nxt-slide-toggle [nxtFormControl]="skillValue.get('value')" [placeholder]="skillValue.get('description').value"></nxt-slide-toggle>
    </div>

  </div>

  <table style="width: 100%; border: 0 solid green">
    <tr>
      <td>Geschwindikeit</td>
      <td colspan="2">
        <div fxLayout="row" fxLayoutAlign="center center" style="border: 0px solid red">
          <mat-slider fxFlex min="0" max="10" step="1">
            <input matSliderThumb [formControl]="nxtFormControl.get('speed')">
          </mat-slider>
          <div [ngStyle]="{padding: '1em', color: nxtFormControl.get('speed').value === -1 ? '#ff3737': ''}">
            {{ nxtFormControl.get('speed').value === -1 ? '!' : nxtFormControl.get('speed').value }}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td></td>
      <td style="text-align: center">Fähigkeit</td>
      <!--<td style="text-align: center">Gerne</td>-->
    </tr>
    @for (skillValue of skillValues; track skillValue) {
      <tr>
        <td style="width: 15%">
          {{ skillValue.get('description').value }}
        </td>
        <td>
          <div fxLayout="row" fxLayoutAlign="center center" style="border: 0px solid red">
            <mat-slider fxFlex min="0" max="10" step="1">
              <input matSliderThumb [formControl]="$any(skillValue.get('value'))">
            </mat-slider>
            <div [ngStyle]="{padding: '1em', paddingLeft: '0.5em', color: skillValue.get('value').value === -1 ? '#ff3737': ''}">
              {{ skillValue.get('value').value === -1 ? '!' : skillValue.get('value').value }}
            </div>
          </div>
        </td>
        <!--<td>
          <div fxLayout="row" fxLayoutAlign="center center" style="border: 0px solid red">
            <mat-slide-toggle fxFlex min="0" max="5" step="1" [formControl]="skillValue.get('like')" (input)="skillValue.get('like').setValue($event.value)"></mat-slide-toggle>
            <div [ngStyle]="{padding: '1em', paddingLeft: '0.5em', color: skillValue.get('like').value === -1 ? '#ff3737': ''}">
              {{skillValue.get('like').value === -1 ? '!' : skillValue.get('like').value}}
            </div>
          </div>
        </td>-->
      </tr>
    }
  </table>
</div>
