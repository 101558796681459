import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, inject, input, OnInit, output, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {FormArray, FormGroup} from '@angular/forms';
import {NxtTypedFormControl} from '../../../nxt-form/nxt.typed-form-control';
import {InputComponent} from '../input/input.component';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {DatePicker2Component} from '../date-picker-2/date-picker-2.component';
import {SelectComponent} from '../select/select.component';
import {PermissionDirective} from '../../../directives/permission.directive';
import {DialogService} from '../../../services/dialog.service';
import {ValidatorTools} from '../../../helpers/validator.tools';


export interface NxtMultiFromTill<T> {
  startDateString: string;
  endDateString: string;
  value: T;
}

export type NxtMultiFromTillFormGroup<T> = FormGroup<{
  startDateString: NxtTypedFormControl<string>,
  endDateString: NxtTypedFormControl<string>,
  value: NxtTypedFormControl<T>,
}>;

@Component({
    selector: 'nxt-multi-from-till',
    templateUrl: './multi-from-till.component.html',
    styleUrls: ['./multi-from-till.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    NxtButtonIconComponent,
    DatePicker2Component,
    SelectComponent,
    PermissionDirective,
  ]
})

export class MultiFromTillComponent<T> extends NxtComponent implements OnInit, NxtOnDestroy, DoCheck {


  constructor() {
    super();
  }

  /*** Inputs ***/
  nxtFormControl = input.required<FormArray<NxtMultiFromTillFormGroup<T>>>();
  placeholder = input.required<string>();
  options = input<{ text: string, value: any }[] | null>(null);
  multiSelect = input(false);
  requiredValue = input(true);

  isNumber = input(false);
  headerText = input.required<string>();
  /*** Outputs ***/

  formChanged = output();

  /*** Signals ***/

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  dialogService = inject(DialogService);
  added = signal(false);

  static getFormGroup<T>(data: NxtMultiFromTill<T>, required: boolean) {
    const group = new FormGroup({
      value: new NxtTypedFormControl<T>(data.value, required ? [ValidatorTools.requiredAndNotNaN] : []),
      startDateString: new NxtTypedFormControl<string>(data.startDateString, [ValidatorTools.requiredAndNotNaN], 'Ab'),
      endDateString: new NxtTypedFormControl<string>(data.endDateString, [], 'Bis'),
    });
    return group;
  }

  ngDoCheck(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {

  }

  _formChanged() {
    for (const [index, formGroup] of this.nxtFormControl().controls.entries()) {
      const isLast = index === this.nxtFormControl().controls.length - 1;
      if (isLast && this.nxtFormControl().controls.length > 1) {
        if (formGroup.controls.startDateString.value) {
          const newEnd = formGroup.controls.startDateString.value.dateAddDays(-1).dateFormatDate();
          if (newEnd !== this.nxtFormControl().controls[index - 1].controls.endDateString.value) {
            this.nxtFormControl().controls[index - 1].controls.endDateString.setValue(newEnd);
          }
        }
      }
    }
    for (const [index, formGroup] of this.nxtFormControl().controls.entries()) {
      const isLast = index === this.nxtFormControl().controls.length - 1;
      if (formGroup.controls.startDateString.value && formGroup.controls.endDateString.value && formGroup.controls.startDateString.value >= formGroup.controls.endDateString.value) {
        // this.dialogService.showOk('Zwischen Start und Ende müssen Tage liegen');
        formGroup.controls.startDateString.setErrors({startEndToShort: 'Zwischen Start und Ende müssen Tage liegen'});
        formGroup.controls.endDateString.setErrors({startEndToShort: 'Zwischen Start und Ende müssen Tage liegen'});
      } else {
        if (formGroup.controls.startDateString.value) {
          formGroup.controls.startDateString.setErrors(null);
        }
        if (formGroup.controls.endDateString.value || isLast) {
          formGroup.controls.endDateString.setErrors(null);
        }
      }
    }
    this.formChanged.emit();
    this.cdRef.detectChanges();
  }


  nxtOnDestroy() {
  }

  addClicked() {
    const formGroup = MultiFromTillComponent.getFormGroup({value: null, startDateString: null, endDateString: null}, this.requiredValue());
    formGroup.controls.endDateString.hasValidator(ValidatorTools.requiredAndNotNaN);
    this.pushSubscription = formGroup.valueChanges.subscribe(() => this._formChanged());
    this.nxtFormControl().controls.push(formGroup);
    this.added.set(true);
  }

  removeClicked(index: number) {
    this.nxtFormControl().controls.splice(index, 1);
    if (this.nxtFormControl().controls.length > 0) {
      this.nxtFormControl().controls[0].controls.endDateString.setValue(null);
    }
  }

  test123(startDateString: NxtTypedFormControl<string>) {
    console.log(startDateString);
    const has = startDateString.hasValidator(ValidatorTools.requiredAndNotNaN);
    console.log(has);
  }
}
