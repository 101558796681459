import {DateTools} from './date.tools';
import _ from 'lodash';


// https://www.toptal.com/designers/htmlarrows/

export class StringTools {
  static arrowRight = '➞';
  static arrowLeft = '⇽';
  static check = '✓';
  // static arrowLeft2 = '￩';
  // static arrowLeft2 = '←';

  static dot = '・';
  static dotSmall = '·';
  static multiply = '×';

  static toCamelCase(text: string): string {
    return _.camelCase(text);
    // return text.replace(/-([a-z])/g, (m, w) => w.toUpperCase());
  }

  static indicesOf(text: string, substring: string) {
    const indizes: number[] = [];
    let index = text.indexOf(substring);
    while (index !== -1) {
      indizes.push(index);
      index = text.indexOf(substring, index + 1); // Suche ab dem nächsten Index
    }
    return indizes;
  }

  static keineUmlaute(value: string) {
    return value
      .replaceAll('ä', 'ae')
      .replaceAll('ö', 'oe')
      .replaceAll('ü', 'ue')
      .replaceAll('ß', 'ss')
      .replaceAll('Ä', 'Ae')
      .replaceAll('Ö', 'Oe')
      .replaceAll('Ü', 'Ue');
  }

  static nullOrEmpty(value: any) {
    return typeof value === 'undefined' || (typeof value === 'string' && value.length === 0 || value === null);
  }

  static notNullOrEmpty(value: any) {
    return !StringTools.nullOrEmpty(value);
  }

  static camelCaseToDash(myStr: string) {
    return myStr.substring(0, 1).toLowerCase() + myStr.substring(1).replace(/([A-Z])/g, (g) => {
      return `-${g[0].toLowerCase()}`;
    });
  }


  static escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  static fill(text: string, totalLength: number, fillWith: string, fillOnEnd = true) {
    if (!fillWith) {
      return text;
    }
    const counter = 0;
    while (text.length < totalLength) {
      if (fillOnEnd) {
        text += fillWith;
      } else {
        text = fillWith + text;
      }
      if (counter > 200) {
        throw Error('max fill reached [length: ' + text.length + '] [fillWith: ' + fillWith + '] [fillOnEnd: ' + fillOnEnd + ']');
      }
    }
    return text;
  }

  static toUpperCase(value: string, from: number, length: number) {
    let result = value.substr(0, from);
    result += value.substr(from, length).toUpperCase();
    result += value.substr(from + length);
    return result;
  }

  static trimStart(value: string, trim: string) {
    if (value.startsWith(trim)) {
      return value.substr(1);
    }
    return value;
  }

  static includesIgnoreCase(value: string, search: string) {
    return value.toLowerCase().includes(search.toLowerCase());
  }

  static includesAll(value: string, strings: string[]) {
    return value && strings.filter(s => value.toLowerCase().includes(s.toLowerCase())).length === strings.length;
  }

  static includesOne(value: string, strings: string[]): boolean {
    return !!value && strings.some(s => value.toLowerCase().includes(s.toLowerCase()));
  }

  static equalsOneIgnoreCase(value: string, strings: string[]): boolean {
    return !!value && strings.some(s => value.toLowerCase() === s.toLowerCase());
  }

  static trimEnd(value: string, trim: string) {
    if (value.endsWith(trim)) {
      return value.substring(0, value.length - trim.length);
    }
    return value;
  }

  static firstCharUppercase(value: string) {
    if (value.length > 0) {
      return value.substr(0, 1).toUpperCase() + value.substr(1);
    }
    return value.trim();
  }

  static toSnakeCase(string: string) {
    return (string.charAt(0).toLowerCase() + string.slice(1)).replace(/\s/, '_').replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }

  static toJavaScriptVariable(string: string) {
    return string.charAt(0).toLowerCase() + string.toCamelCase().slice(1);
  }

  static escapeForRegExp(value: string) {
    return value?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  static escapeForRegExReplacement(value: string) {
    return value.replace(/\$/g, '$$$$');
  }

  static decodeHtml(value: string) {
    value = value.replaceAll('&lt;', '<');
    value = value.replaceAll('&gt;', '>');
    value = value.replaceAll('&quot;', '"');
    value = value.replaceAll('&#39;', '\'');
    value = value.replaceAll('&amp;', '&');
    return value;
  }

  static trimChar(str: string, ch: string) {
    str = str.trim();
    let start = 0;
    let end = str.length;
    while (start < end && str[start] === ch) {
      console.log('trimChar start');
      ++start;
    }

    while (end > start && str[end - 1] === ch) {
      console.log('trimChar end');
      --end;
    }
    return (start > 0 || end < str.length) ? str.substring(start, end) : str;
  }

  static test() {
    const abc = StringTools.getNameAttributesByText('Julian van der Krafft\n9.7.84\n52064 Aachen');
    const a = '\n\ntest\ndfd\n'.trimChar('\n');
    console.log(a);
    console.log(a);
    console.log(a);

    const result1 = '/sdfsh$/\.[$]ab/\.[$]c&'.replaceAll('[$]', '___');
    if (result1 !== '/sdfsh$/\.___ab/\.___c&') {
      console.error('Wrong String.replaceAll Test');
    }

    const result2 = StringTools.toSnakeCase('Villa');
    if (result2 !== 'villa') {
      console.error('Wrong String.toSnakeCase Test');
    }
    /*const result3 = StringTools.toSnakeCase('Villa 2');
    if (result3 !== 'villa_2') {
        console.error('Wrong String.toSnakeCase Test');
    }

    const result4 = StringTools.toJavaScriptVariable('Villa 2');
    if (result4 !== 'villa2') {
        console.error('Wrong String.toJavaScriptVariable Test4');
    }*/
  }


  static getNameAttributesByText(text: string): {
    birthday: string,
    postalCode: string,
    givenName: string,
    familyName: string
  } {
    const familyNamePrefixes = ['van', 'von', 'di', 'd\'', 'auf', 'dem', 'den', 'Dr.', 'Prof.'];

    const dateRegex = /(\d{1,2}\.\d{1,2}\.\d{1,4})/g;
    const postalCodeRegex = /\D(\d{5})($|\D)/g;

    let birthday = '';
    let postalCode = '';
    const givenNames: string[] = [];
    const familyNames: string[] = [];

    const matchesDate = dateRegex.exec(text);
    if (matchesDate && matchesDate.length > 1) {
      birthday = matchesDate[1];
      text = text.replace(birthday, '');
      birthday = DateTools.parseTextToBirthday(birthday).dateFormat('yyyy-MM-dd');
    }

    const matchesPostalCode = postalCodeRegex.exec(text);
    if (matchesPostalCode && matchesPostalCode.length > 1) {
      postalCode = matchesPostalCode[1];
      text = text.substring(0, text.indexOf(postalCode));
      // text = text.replace(postalCode, '');
    }
    const parts = text.trimChar('\n').split(' ').map(p => p.trimChar('\n').trim()).filter(p => !!p);

    for (const [index, part] of parts.entries()) {
      if (index + 1 === parts.length) {
        familyNames.push(StringTools.firstCharUppercase(part));
      } else {
        if (familyNamePrefixes.includes(part)) {
          familyNames.push(part);
        } else {
          givenNames.push(part);
        }
      }
    }
    return {birthday, postalCode, givenName: givenNames.join(' '), familyName: familyNames.join(' ')};
  }

  static replaceUtf8IsoWrong(xmlString: string) {
    xmlString = xmlString.replaceAll('Ã¤', 'ä');
    xmlString = xmlString.replaceAll('Ã¼', 'ü');
    xmlString = xmlString.replaceAll('Ã¶', 'ö');
    xmlString = xmlString.replaceAll('Ã„', 'Ä');
    xmlString = xmlString.replaceAll('Ãœ', 'Ü');
    xmlString = xmlString.replaceAll('Ã–', 'Ö');
    xmlString = xmlString.replaceAll('ÃŸ', 'ß');
    xmlString = xmlString.replaceAll('â€“', '€');
    xmlString = xmlString.replaceAll('â‚¬“', '€');
    xmlString = xmlString.replaceAll('Â§“', '§');
    return xmlString;
  }
}

declare global {
  interface Number {
    fill(totalLength: number, fillWith: string, fillOnEnd?: boolean): string;
  }


  interface String {
    replaceAll(searchValue: string, replaceValue: string): string;

    indicesOf(value: string): number[];

    includesIgnoreCase(value: string): boolean;

    decodeHtml(): string;

    keineUmlaute(): string;

    toCamelCase(): string;

    toSnakeCase(): string;

    trimChar(char: string): string;

    trimEnd(value: string): string;

    trimStart(value: string): string;

    includesAll(strings: string[]): boolean;

    includesOne(strings: string[]): boolean;

    equalsOneIgnoreCase(strings: string[]): boolean;

    fill(length: number, fillWith: string, fillOnEnd?: boolean): string;
  }
}

String.prototype.toCamelCase = function(this: string) {
  return StringTools.toCamelCase(this);
};

String.prototype.toSnakeCase = function(this: string) {
  return StringTools.toSnakeCase(this);
};


Object.defineProperty(String.prototype, 'replaceAll', {
  configurable: true,
  writable: true,
  value(this: string, searchValue: string, replaceValue: string) {
    if (!replaceValue) {
      replaceValue = '';
    }
    if (searchValue) {
      const pattern = StringTools.escapeForRegExp(searchValue);
      const result = this.replace(new RegExp(pattern, 'g'), StringTools.escapeForRegExReplacement(replaceValue));
      return result;
    }
    return this;
  },
});

Object.defineProperty(String.prototype, 'trimChar', {
  configurable: true,
  writable: true,
  value(this: string, char: string) {
    return StringTools.trimChar(this, char);
  },
});

Object.defineProperty(String.prototype, 'decodeHtml', {
  configurable: true,
  writable: true,
  value(this: string) {
    return StringTools.decodeHtml(this);
  },
});

Object.defineProperty(String.prototype, 'keineUmlaute', {
  configurable: true,
  writable: true,
  value(this: string) {
    return StringTools.keineUmlaute(this);
  },
});

Object.defineProperty(String.prototype, 'trimEnd', {
  configurable: true,
  writable: true,
  value(this: string, value: string) {
    return StringTools.trimEnd(this, value);
  },
});

Object.defineProperty(String.prototype, 'trimStart', {
  configurable: true,
  writable: true,
  value(this: string, value: string) {
    return StringTools.trimStart(this, value);
  },
});

Object.defineProperty(String.prototype, 'includesAll', {
  configurable: true,
  writable: true,
  value(this: string, strings: string[]) {
    return StringTools.includesAll(this, strings);
  },
});

Object.defineProperty(String.prototype, 'includesOne', {
  configurable: true,
  writable: true,
  value(this: string, strings: string[]) {
    return StringTools.includesOne(this, strings);
  },
});

Object.defineProperty(String.prototype, 'equalsOneIgnoreCase', {
  configurable: true,
  writable: true,
  value(this: string, strings: string[]) {
    return StringTools.equalsOneIgnoreCase(this, strings);
  },
});


Object.defineProperty(String.prototype, 'fill', {
  configurable: true,
  writable: true,
  value(this: string, length: number, fillWith: string, fillOnEnd = true) {
    return StringTools.fill(this, length, fillWith, fillOnEnd);
  },
});

Object.defineProperty(Number.prototype, 'fill', {
  configurable: true,
  writable: true,
  value(this: number, totalLength: number, fillWith: string, fillOnEnd = true) {
    return StringTools.fill(this.toString(), totalLength, fillWith, fillOnEnd);
  },
});

Object.defineProperty(String.prototype, 'indicesOf', {
  configurable: true,
  writable: true,
  value(this: string, value: string) {
    return StringTools.indicesOf(this, value);
  },
});

Object.defineProperty(String.prototype, 'includesIgnoreCase', {
  configurable: true,
  writable: true,
  value(this: string, value: string) {
    return StringTools.includesIgnoreCase(this, value);
  },
});






