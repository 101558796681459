import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {TimeTools} from '../../common-browser/helpers/time.tools';
import {NgIf} from '@angular/common';
import {MultiClickDirective} from '../../directives/multi-click.directive';

@Component({
    selector: 'nxt-gift-card-generator',
    templateUrl: './gift-card-generator.component.html',
    styleUrls: ['./gift-card-generator.component.scss'],
    imports: [MultiClickDirective, NgIf]
})
export class GiftCardGeneratorComponent implements OnInit, OnDestroy {

  @ViewChild('codeSpan', {static: true}) codeSpan: ElementRef;
  private isRunning = false;


  constructor(
  ) {
  }

  fake = ['10.000€', '600€', '100€', '80€', '50€', '40€', '30€', '20€', '10€'];
  real = ['50€', '40€', '30€', '20€', '10€'];
  interval: any;
  currentValue: string = '';
  stopButtonText = 'STOP';

  async ngOnInit() {
    this.start();
  }


  ngOnDestroy(): void {
    this.isRunning = false;
  }

  async stop() {
    if (this.isRunning) {
      this.isRunning = false;
      this.stopButtonText = '&nbsp;&nbsp;&nbsp;&nbsp;3&nbsp;&nbsp;&nbsp;&nbsp;';
      clearInterval(this.interval);
      await TimeTools.sleep(800);
      this.stopButtonText = '&nbsp;&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;&nbsp;';
      this.generate(this.fake);
      await TimeTools.sleep(800);
      this.stopButtonText = '&nbsp;&nbsp;&nbsp;&nbsp;1&nbsp;&nbsp;&nbsp;&nbsp;';
      this.generate(this.real);
      await TimeTools.sleep(1000);
      this.currentValue = 'MAYO';
      this.stopButtonText = '';
      this.codeSpan.nativeElement.innerHTML = '<div style="font-size: 22vw">' + this.currentValue + '</div>';
      await TimeTools.sleep(200);
      this.codeSpan.nativeElement.innerHTML = '';
      await TimeTools.sleep(200);
      this.codeSpan.nativeElement.innerHTML = '<div style="font-size: 22vw">' + this.currentValue + '</div>';
      await TimeTools.sleep(200);
      this.codeSpan.nativeElement.innerHTML = '';
      await TimeTools.sleep(200);
      this.codeSpan.nativeElement.innerHTML = '<div style="font-size: 22vw">' + this.currentValue + '</div>';
      await TimeTools.sleep(200);
      this.codeSpan.nativeElement.innerHTML = '';
      await TimeTools.sleep(200);
      this.codeSpan.nativeElement.innerHTML = '<div style="font-size: 22vw">' + this.currentValue + '</div>';
      // this.codeSpan.nativeElement.innerHTML = '<div style="font-size: 8vw">KAFFEE MACHEN</div>';
    }
  }

  start() {
    if (!this.isRunning) {
      this.stopButtonText = 'STOP';
      this.isRunning = true;
      this.interval = setInterval(() => {
        this.generate(this.fake);
      }, 200);
    }
  }

  generate(array: string[]) {
    this.currentValue = MathTools.getRandomFromArray(array);
    this.codeSpan.nativeElement.innerText = this.currentValue.toUpperCase();
  }


}
