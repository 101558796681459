import {WithoutNxtDbFields} from '../../common-interfaces/nxt.db-fields.interface';
import {NxtMoneyAccount} from '../../common-interfaces/money-account.interface';

export class MoneyAccountTools {
  static getEmpty(): WithoutNxtDbFields<NxtMoneyAccount> {
    return {
      name: '',
      type: 'cash',
      balance: 0,
      balanceTimestamp: 0,
      userIds: [],
      cashRegisterName: '',
      receiveCashRegisterSafeStudio: '',
    };
  }

  static getMoneyAccountTypeText(value: any) {
    switch (value) {
      case 'cash':
        return 'Bar';
      case 'bank':
        return 'Bank';
      case 'credit-card':
        return 'Kreditkarte';
      case 'other':
        return 'Sonstiges';
      default:
        return '';
    }
  }
}
