import {NxtPayment, PaymentMethod, PaymentType} from '../../common-interfaces/nxt.payment.interface';
import {NxtCalendarEvent, NxtWorkType} from '../../common-interfaces/nxt.calendar-event.interface';
import {SortTools} from './sort.tools';
import {DateTools} from './date.tools';
import {ObjectTools} from './object.tools';
import {NxtReceipt} from '../../common-interfaces/nxtReceipt';
import {NxtContact} from '../../common-interfaces/nxt.contact.interface';
import {DecimalTools} from './decimal.tools';
import {NxtPaymentPossibilityRecord} from '../../common-interfaces/nxt.payment-possibility-record.interface';
import {DurationTools} from './duration.tools';
import {MathTools} from './math.tools';
import {NxtLog} from '../log/nxt-log';
import {StringTools} from './string.tools';
import {NxtInvoicePositionPayment} from '../../common-interfaces/nxt.invoice.interface';
import {NxtCashPayment} from '../../common-interfaces/nxt.cash-payment.interface';


export enum PaymentTypes {
  Additional = 'additional',
  Complete = 'complete',
  DepositBack = 'deposit-back',
  Deposit = 'deposit',
  Payout = 'payout',
  Rest = 'rest',
  CheaperStudio = 'cheaper',
  Free = 'free'
}

export class PaymentTools {

  public static customerPayedPaymentTypes: PaymentType[] = ['rest', 'deposit', 'complete', 'additional', 'improve', 'gift-card-sold'];
  public static totalSalesPaymentTypes: PaymentType[] = PaymentTools.customerPayedPaymentTypes;
  public static totalSalesPaymentMethods: PaymentMethod[] = ['bank', 'cash', 'klarna', 'paypal'];
  public static customerPayedPaymentTypesWithoutAdditional: PaymentType[] = ['rest', 'deposit', 'complete', 'improve', 'gift-card-sold'];

  public static getPaymentTypeText(paymentType: string) {
    const result = this.paymentTypes.find(pType => pType.value.toLowerCase() === paymentType);
    if (result) {
      return result.text;
    }
    return paymentType;
  }


  public static getPaymentTypeInvoiceText(paymentType: string, workType: NxtWorkType, lang = 'DE') {
    let texts: { value: string, text: string, eventBookable: boolean }[] = [];
    if (workType === 'tattoo') {
      texts = this.paymentTypesInvoiceDe;
    } else if (workType === 'piercing') {
      texts = this.paymentTypesInvoicePiercingDe;
    }
    if (lang === 'ES') {
      texts = workType === 'piercing' ? this.paymentTypesInvoicePiercingEs : this.paymentTypesInvoiceEs;
    }
    const result = texts.find(pType => pType.value.toLowerCase() === paymentType);
    if (result) {
      return result.text;
    }
    return paymentType;
  }

  public static getPaymentTypeDatevText(paymentType: string, workType: string = 'tattoo') {
    const texts = workType === 'piercing' ? this.paymentTypesDatevPiercing : this.paymentTypesDatev;
    const result = texts.find(pType => pType.value.toLowerCase() === paymentType);
    if (result) {
      return result.text;
    }
    return paymentType;
  }

  public static getPaymentMethodText(paymentMethod?: PaymentMethod | 'klarna_payments') {
    if (!paymentMethod) {
      return '';
    }
    if (paymentMethod === 'klarna_payments') {
      return 'Klarna';
    }
    const result = this.paymentMethods.find(pType => pType.value.toLowerCase() === paymentMethod);
    if (result) {
      return result.text;
    }
    return paymentMethod;
  }


  public static get paymentTypes(): { text: string, value: PaymentType, eventBookable: boolean }[] {
    return [
      {value: 'additional', text: 'Nachzahlung (50/50)', eventBookable: false},
      {value: 'deposit', text: 'Kaution', eventBookable: true},
      {value: 'deposit-back', text: 'Rückzahlung', eventBookable: true},
      {value: 'rest', text: 'Rest', eventBookable: true},
      {value: 'complete', text: 'Gesamt', eventBookable: true},
      {value: 'free', text: 'Kostenlos', eventBookable: false},
      {value: 'improve', text: 'Nachstechen', eventBookable: false},
      {value: 'payout', text: 'Artist-Auszahlung', eventBookable: true},
      {value: 'cheaper', text: 'Preisnachlass (Studio)', eventBookable: false},


      {value: 'gift-card-sold', text: 'Gutschein', eventBookable: false},
      {value: 'cash-report-outgoing', text: 'Barausgabe', eventBookable: false},
      {value: 'cash-report-incoming', text: 'Bareinahme', eventBookable: false},

    ];
  }


  public static get paymentTypesInvoiceDe() {
    return [
      {value: 'additional', text: 'Tattoo Nachzahlung', eventBookable: true},
      {value: 'deposit', text: 'Tattoo Kaution', eventBookable: true},
      {value: 'deposit-back', text: 'Rückzahlung', eventBookable: true},
      {value: 'rest', text: 'Tattoo Restzahlung', eventBookable: true},
      {value: 'complete', text: 'Tattoo Zahlung', eventBookable: true},
      {value: 'free', text: 'Kostenlos', eventBookable: true},
      {value: 'improve', text: 'Tattoo Zahlung', eventBookable: true},
      {value: 'payout', text: 'Artist-Abrechnung', eventBookable: true},
      {value: 'cheaper', text: 'Preisnachlass', eventBookable: true},
      {value: 'gift-card-sold', text: 'Gutschein', eventBookable: false},
      {value: 'cash-report-outgoing', text: 'Barausgabe', eventBookable: false},
      {value: 'cash-report-incoming', text: 'Bareinnahme', eventBookable: false},

    ];
  }

  public static get paymentTypesInvoiceEs() {
    return [
      {value: 'additional', text: 'Tatuaje pago adicional', eventBookable: true},
      {value: 'deposit', text: 'Depósito por tatuaje', eventBookable: true},
      {value: 'deposit-back', text: 'Devolución', eventBookable: true},
      {value: 'rest', text: 'Pago final del tatuaje', eventBookable: true},
      {value: 'complete', text: 'Pago del tatuaje', eventBookable: true},
      {value: 'free', text: 'Gratis', eventBookable: true},
      {value: 'improve', text: 'Pago del tatuaje', eventBookable: true},
      {value: 'payout', text: 'Facturación del artista', eventBookable: true},
      {value: 'cheaper', text: 'Descuento', eventBookable: true},
      {value: 'gift-card-sold', text: 'Vale', eventBookable: false},
      {value: 'cash-report-outgoing', text: 'Retirada de efectivo', eventBookable: false},
      {value: 'cash-report-incoming', text: 'Ingreso en efectivo', eventBookable: false},
    ];
  }

  public static get paymentTypesDatev() {
    return [
      {value: 'additional', text: 'Tattoo Nachzahlung', eventBookable: true},
      {value: 'deposit', text: 'Tattoo Kaution', eventBookable: true},
      {value: 'deposit-back', text: 'Rückzahlung', eventBookable: true},
      {value: 'rest', text: 'Tattoo Restzahlung', eventBookable: true},
      {value: 'complete', text: 'Tattoo Zahlung', eventBookable: true},
      {value: 'free', text: 'Kostenlos', eventBookable: true},
      {value: 'improve', text: 'Tattoo Zahlung', eventBookable: true},
      {value: 'payout', text: 'Artist-Abrechnung', eventBookable: true},
      {value: 'cheaper', text: 'Preisnachlass', eventBookable: true},
      {value: 'gift-card-sold', text: 'Gutscheinverkauf', eventBookable: false},
      {value: 'cash-report-outgoing', text: 'Barausgabe', eventBookable: false},
      {value: 'cash-report-incoming', text: 'Bareinnahme', eventBookable: false},

    ];
  }

  public static get paymentTypesInvoicePiercingDe() {
    return [
      {value: 'additional', text: 'Piercing Nachzahlung', eventBookable: true},
      {value: 'deposit', text: 'Piercing Kaution', eventBookable: true},
      {value: 'deposit-back', text: 'Rückzahlung', eventBookable: true},
      {value: 'rest', text: 'Piercing Restzahlung', eventBookable: true},
      {value: 'complete', text: 'Piercing Zahlung', eventBookable: true},
      {value: 'free', text: 'Kostenlos', eventBookable: true},
      {value: 'improve', text: 'Piercing Zahlung', eventBookable: true},
      {value: 'payout', text: 'Artist-Abrechnung', eventBookable: true},
      {value: 'cheaper', text: 'Preisnachlass', eventBookable: true},
      {value: 'gift-card-sold', text: 'Gutschein', eventBookable: false},
      {value: 'cash-report-outgoing', text: 'Barausgabe', eventBookable: false},
      {value: 'cash-report-incoming', text: 'Bareinnahme', eventBookable: false},
    ];
  }

  public static get paymentTypesInvoicePiercingEs() {
    return [
      {value: 'additional', text: 'Piercing Nachzahlung', eventBookable: true},
      {value: 'deposit', text: 'Piercing Kaution', eventBookable: true},
      {value: 'deposit-back', text: 'Rückzahlung', eventBookable: true},
      {value: 'rest', text: 'Piercing Restzahlung', eventBookable: true},
      {value: 'complete', text: 'Piercing Zahlung', eventBookable: true},
      {value: 'free', text: 'Kostenlos', eventBookable: true},
      {value: 'improve', text: 'Piercing Zahlung', eventBookable: true},
      {value: 'payout', text: 'Artist-Abrechnung', eventBookable: true},
      {value: 'cheaper', text: 'Preisnachlass', eventBookable: true},
      {value: 'gift-card-sold', text: 'Gutschein', eventBookable: false},
      {value: 'cash-report-outgoing', text: 'Barausgabe', eventBookable: false},
      {value: 'cash-report-incoming', text: 'Bareinnahme', eventBookable: false},
    ];
  }

  public static get paymentTypesDatevPiercing() {
    return [
      {value: 'additional', text: 'Piercing Nachzahlung', eventBookable: true},
      {value: 'deposit', text: 'Piercing Kaution', eventBookable: true},
      {value: 'deposit-back', text: 'Rückzahlung', eventBookable: true},
      {value: 'rest', text: 'Piercing Restzahlung', eventBookable: true},
      {value: 'complete', text: 'Piercing Zahlung', eventBookable: true},
      {value: 'free', text: 'Kostenlos', eventBookable: true},
      {value: 'improve', text: 'Piercing Zahlung', eventBookable: true},
      {value: 'payout', text: 'Artist-Abrechnung', eventBookable: true},
      {value: 'cheaper', text: 'Preisnachlass', eventBookable: true},
      {value: 'gift-card-sold', text: 'Gutschein', eventBookable: false},
      {value: 'cash-report-outgoing', text: 'Barausgabe', eventBookable: false},
      {value: 'cash-report-incoming', text: 'Bareinnahme', eventBookable: false},
    ];
  }

  public static get paymentMethods() {
    return [
      {value: 'bank', text: 'Bank'},
      {value: 'cash', text: 'Kasse'},
      {value: 'gift-card', text: 'Gutschein'},
      {value: 'klarna', text: 'Klarna'},
      {value: 'paypal', text: 'PayPal'},
      {value: 'none', text: 'Keine'},
      {value: 'transfer', text: 'Transfer'},
    ];
  }

  public static get paymentMethodsDepositBack() {
    return [
      {value: 'bank', text: 'Bank'},
      {value: 'cash', text: 'Kasse'},
      {value: 'paypal', text: 'PayPal'},
    ];
  }


  public static getCustomerPayedSumFromPayments(nxtPayments: NxtPayment[]): number {
    let sum = this.getPaymentSumByPaymentType(nxtPayments, this.customerPayedPaymentTypes);
    sum -= this.getPaymentSumByPaymentType(nxtPayments, [PaymentTypes.DepositBack]);
    return sum;
  }

  public static getCustomerPayedSumFromPaymentsWithoutAdditional(nxtPayments: NxtPayment[]): number {
    let sum = this.getPaymentSumByPaymentType(nxtPayments, this.customerPayedPaymentTypesWithoutAdditional);
    sum -= this.getPaymentSumByPaymentType(nxtPayments, [PaymentTypes.DepositBack]);
    return sum;
  }


  public static filterPaymentsByMethod(payments: NxtPayment[], methods: PaymentMethod[]) {
    return payments.filter(p => p.paymentMethod && methods.indexOf(p.paymentMethod) > -1);
  }


  public static getPaymentSumByPaymentTypeControl(controls: any, paymentTypes: PaymentType[]) {
    return controls
      .filter(formGroup => paymentTypes.indexOf(formGroup.get('paymentType').value) > -1)
      .reduce((sum, payment) => {
        const value = parseFloat(payment.get('paymentValue').value);
        if (!Number.isNaN(value)) {
          return sum + value;
        }
        return sum;
      }, 0);
  }

  public static getPaymentByPaymentTypeControl(controls: any, paymentTypes: PaymentType[]): any[] {
    return controls.filter(formGroup => paymentTypes.indexOf(formGroup.get('paymentType').value) > -1);
  }

  public static getPaymentSumByPaymentType(nxtPayments: NxtPayment[], paymentTypes: PaymentType[] | PaymentType): number {
    if (!Array.isArray(paymentTypes)) {
      paymentTypes = [paymentTypes];
    }
    const result = nxtPayments.reduce((sum, payment) => {
      if (payment.paymentType && paymentTypes.indexOf(payment.paymentType) > -1) {
        if (payment.paymentValue) {
          return MathTools.roundMoney(sum + payment.paymentValue);
        }
      }
      return MathTools.roundMoney(sum);
    }, 0);
    if (result) {
      return MathTools.roundMoney(result);
    } else {
      return 0;
    }
  }


  public static getPaymentsFromEvents(events: NxtCalendarEvent[], nxtLog?: NxtLog): NxtPayment[] {
    nxtLog?.logAfter('start');
    let payments: NxtPayment[] = [];
    for (const event of events) {
      payments.push(...event.payments);
    }
    payments = payments.sort(SortTools.sortString('paymentDate', true));
    return payments;
  }


  public static calcAdditionalPaymentValue(moreTime: number) {
    const moneyPerHour = 100;
    let moreHours = moreTime / 1000 / 60 / 60;
    if (moreHours < 0.5) {
      moreHours = 0;
    }
    const moreMoney = moreHours * moneyPerHour;
    return Math.round(moreMoney / 10) * 10;
  }

  static calcCashEndOfDayFromEvents(event: NxtCalendarEvent, studios?: string[]) {
    if (event.payments) {
      let paymentsToCalc: NxtPayment[] = ObjectTools.clone(event.payments);
      if (studios) {
        paymentsToCalc = paymentsToCalc.filter(payment => payment.studio && studios.includes(payment.studio));
      }

      let plannedIncoming = 0;
      let plannedOutgoing = 0;
      if (typeof event.priceEstimatedFrom === 'number') {
        plannedIncoming = event.toPay ? event.toPay : 0;
        let plannedTotalPrice = event.priceEstimatedFrom;
        const customerTotalPayed = PaymentTools.getCustomerPayedSumFromPayments(event.payments);
        const customerTotalPayedAdditional = PaymentTools.getPaymentSumByPaymentType(event.payments, ['additional']);
        if (customerTotalPayed > plannedTotalPrice || event.closed) {
          plannedTotalPrice = customerTotalPayed;
        }
        if (event.closed) {
          plannedIncoming = 0;
        }
        plannedIncoming += PaymentTools.getCustomerPayedSumFromPayments(paymentsToCalc.filter(payment => {
          return DateTools.clearTime(payment.createdAt) === DateTools.clearTime(event.start) && payment.paymentMethod === 'cash';
        }));
        plannedOutgoing = (plannedTotalPrice - customerTotalPayedAdditional) * (typeof event.artistPercentage === 'number' ? event.artistPercentage : 50) / 100;
        plannedOutgoing += customerTotalPayedAdditional * 0.5;

        let result = plannedIncoming - plannedOutgoing;
        result -= PaymentTools.getPaymentSumByPaymentType(paymentsToCalc, PaymentTypes.CheaperStudio);
        return MathTools.round(result, 2);
      }
    }
    return 0;
  }

  static parsePaymentToReceipt(payment: NxtPayment, event: NxtCalendarEvent, customer?: NxtContact | null): NxtReceipt | undefined {
    if (payment.paymentMethod === 'cash') {
      let receiptCustomer: any;
      if (customer) {
        receiptCustomer = {
          customerName: customer.givenName + ' ' + customer.familyName,
          customerStreet: customer.streetAddress,
          customerHouseNumber: '',
          customerCity: customer.city,
          customerPostalCode: customer.postalCode,
        };
      }
      const receipt: NxtReceipt = {
        receiptNo: '',
        receiptDateString: DateTools.format(payment.createdAt, 'yyyy-MM-dd'),
        receiptValueTotal: payment.paymentValue ? payment.paymentValue : 0,
        receiptPositions: [
          {
            unitPrice: payment.paymentValue ? payment.paymentValue : 0,
            amount: 1,
            name: PaymentTools.getPaymentTypeText(payment.paymentType ? payment.paymentType : ''),
          },
        ],
        customer: receiptCustomer,
        appointment: {
          startDateTime: event.start ? event.start : 0,
          studio: payment.studio ? payment.studio : '',
          studioAddress: 'fehlt noch...',
          // studioAddress: this.configService.ngConfig.StudioTools.getStudioAddress(event.studio ? event.studio : '')
        },
      };
      return receipt;
    }
  }

  static paymentToTextOneLine(payment: NxtPayment, addPaymentUuid: boolean) {
    if (payment && payment.paymentValue && payment.paymentMethod && payment.paymentType) {
      if (addPaymentUuid) {
        return DecimalTools.toMoneyString(payment.paymentValue) + ' · ' + PaymentTools.getPaymentMethodText(payment.paymentMethod) + ' · ' + PaymentTools.getPaymentTypeText(payment.paymentType) + ' [' + payment.paymentUuid + ']';
      } else {
        let text = DecimalTools.toMoneyString(payment.paymentValue) + ' · ' + PaymentTools.getPaymentMethodText(payment.paymentMethod) + ' · ' + PaymentTools.getPaymentTypeText(payment.paymentType);
        if (payment.receiptNumber) {
          text += ' · ' + payment.receiptNumber;
        }
        return text;
      }
    }
    return '';
  }

  public static calcPayoutPercent(payments: NxtPayment[], payoutValue: number) {
    let result = 0;
    let sumPayments = PaymentTools.getPaymentSumByPaymentType(payments, PaymentTools.customerPayedPaymentTypes);
    sumPayments -= PaymentTools.getPaymentSumByPaymentType(payments, ['deposit-back']);
    result = payoutValue / sumPayments * 100;
    return result;
  }

  public static calcPayout(payments: NxtPayment[], artistPercentage: number | undefined, ignoreAlreadyPayout = false) {
    if (typeof artistPercentage === 'undefined') {
      artistPercentage = 50;
    }
    let result = 0;

    /* alle kundenzahlungen */
    let sumPayments = PaymentTools.getPaymentSumByPaymentType(payments, PaymentTools.customerPayedPaymentTypesWithoutAdditional);
    sumPayments -= PaymentTools.getPaymentSumByPaymentType(payments, ['deposit-back']);
    result = sumPayments / 100 * artistPercentage;

    /* Nachzahlungen immer mit 50 % */
    const additionalPayments = PaymentTools.getPaymentSumByPaymentType(payments, ['additional']);
    result += additionalPayments * 0.5;

    /* bereits gezahlte payouts abziehen */
    if (!ignoreAlreadyPayout) {
      result -= PaymentTools.getPaymentSumByPaymentType(payments, ['payout']);
    }
    return MathTools.roundMoney(result);
  }


  static parseGiftCardsToNxtPayment(giftCards: NxtPaymentPossibilityRecord[]): NxtPayment[] {
    return giftCards.filter(giftCard => !giftCard.originalId).map(giftCard => {
      return PaymentTools.parseGiftCardToNxtPayment(giftCard);
    }).sort(SortTools.sortString('paymentDate', true));
  }

  static parseGiftCardToNxtPayment(giftCard: NxtPaymentPossibilityRecord) {
    const paymentDate = DateTools.parse(giftCard.createdAt);
    const paymentMonth = DateTools.clearDayAndTime(DateTools.parse(giftCard.createdAt));
    const paymentValue = typeof giftCard.paymentValue === 'number' ? parseFloat(giftCard.paymentValue.toString()) : parseFloat(giftCard.value.toString());
    const payment: NxtPayment = {
      paymentUuid: giftCard.id,
      eventId: '',
      // paymentDateNumber: DateTools.parse(giftCard.createdAt),
      paymentDate: DateTools.format(paymentDate, 'yyyy-MM-dd'),
      // paymentMonth,
      // paymentMonthString: DateTools.format(paymentMonth, 'yyyy-MM'),
      createdAt: paymentDate,
      // createdAtString: DateTools.format(paymentDate, 'yyyy-MM-dd HH:mm:ss'),
      paymentMethod: giftCard.paymentMethod ? giftCard.paymentMethod : 'cash',
      paymentType: 'gift-card-sold',
      paymentValue: giftCard.originalPaymentValue,
      studio: giftCard.studio,
      createdBy: giftCard.createdBy,
      giftCardDescription: '#' + StringTools.fill(giftCard.internalId.toString(), 4, '0', false) + ' ' + DecimalTools.toMoneyString(giftCard.value),
      artist: '',
      createdAtDateString: DateTools.format(giftCard.createdAt, 'yyyy-MM-dd'),
      refId: giftCard.internalId,
    };
    return payment;
  }

  static fillAttributes_old(payments: NxtPayment[], event: NxtCalendarEvent) {
    for (const p of payments) {
      p.artist = '';
      if (p.paymentType !== 'deposit-back') {
        p.artist = event.artist;
        p.artistCalendarNumber = event.calendarNumber ?? '';
      }
      p.eventId = event.id;
      p.isPrivate = !!(event.visibility && event.visibility === 'private');
      p.eventDate = event.startDateString ?? '';
      if (event.customerObj) {
        p.eventCustomerName = event.customerObj.givenName + ' ' + event.customerObj.familyName;
      }
      p.eventInvoiceNumber = event.invoiceNumber ?? '';
    }
    return payments;
  }

  static parseNxtCashIncomingToNxtPayment(nxtCashIncomings: NxtCashPayment[]): NxtPayment[] {
    const payments = nxtCashIncomings.map(cashIncoming => {
      const payment: (NxtPayment) = {
        paymentValue: cashIncoming.value,
        isNewPayment: false,
        artist: '',
        isPrivate: cashIncoming._,
        studio: cashIncoming.studio,
        createdAt: DateTools.parse(cashIncoming.createdAt),
        createdBy: cashIncoming.createdBy,
        paymentUuid: cashIncoming.id,
        paymentDate: cashIncoming.createdAtDateString,
        paymentType: cashIncoming.direction === 'in' ? 'cash-report-incoming' : 'cash-report-outgoing',
        paymentMethod: 'cash',
        cashIncomingOutgoingDescription: cashIncoming.description,
        createdAtDateString: DateTools.format(cashIncoming.createdAt, 'yyyy-MM-dd'),
        articleId: cashIncoming.articleId,
        attachment: cashIncoming.googleDriveFolderId,
        workplace: 'reception',
        originalCreatedBy: cashIncoming.originalCreatedBy,
        eventId: cashIncoming.eventId,
      };
      return payment;
    });
    return payments;
  }

  static filterPaymentsDate(payments: NxtPayment[], date: string) {
    const from = DateTools.clearTime(DateTools.parse(date, 'yyyy-MM-dd')); // + DurationTools.DURATION_1HOUR * 6;
    const till = from + DurationTools.DURATION_1DAY;
    const result = payments.filter((payment) => {
      return payment.createdAt > from && payment.createdAt < till;
    });
    return ObjectTools.clone(result);
  }


  static isPossible_(payments: NxtPayment[]) {
    const result = payments.find(p => ['bank', 'klarna', 'paypal', 'gift-card'].includes(p.paymentMethod ?? '') || p.createdAtDateString !== DateTools.formatNow('yyyy-MM-dd'));
    return !result;
  }

  static getLastPaymentReception(payments: NxtPayment[]) {
    const receptionPayments = payments.filter(p => p.workplace === 'reception').sortNumber('createdAt', true);
    if (receptionPayments.length > 0) {
      return receptionPayments[0];
    }
  }

  static getFirstPaymentReceptionOnDateNotDeposit(payments: NxtPayment[], dateString: string) {
    const receptionPayments = payments.filter(p => {
      const isReception = p.workplace === 'reception';
      const isToday = p.createdAtDateString === dateString;
      const isNotDeposit = p.paymentType !== 'deposit';
      return isReception && isToday && isNotDeposit;
    }).sortNumber('createdAt', false);

    if (receptionPayments.length > 0) {
      return receptionPayments[0];
    }
  }

  static getFirstPaymentReceptionOnDateRestOrComplete(payments: NxtPayment[], dateString: string) {
    const receptionPayments = payments.filter(p => {
      const isReception = p.workplace === 'reception';
      const isToday = p.createdAtDateString === dateString;
      const isNotDeposit = ['rest', 'complete'].includes(p.paymentType);
      return isReception && isToday && isNotDeposit;
    }).sortNumber('createdAt', false);

    if (receptionPayments.length > 0) {
      return receptionPayments[0];
    }
  }

  static parseNxtPaymentToNxtInvoicePayment(payment: NxtPayment): NxtInvoicePositionPayment {
    const result: NxtInvoicePositionPayment = {
      paymentUuid: payment.paymentUuid,
      eventId: payment.eventId,
      studio: payment.studio,
      paymentMethod: payment.paymentMethod,
    };
    if (payment.paymentPaypalTransaction) {
      result.paymentPaypalTransaction = {transactionId: payment.paymentPaypalTransaction?.transactionId, receiverEmail: payment.paymentPaypalTransaction?.receiverEmail};
    }
    if (payment.paymentGiftCard) {
      result.paymentGiftCard = {id: payment.paymentGiftCard.id};
    }
    if (payment.paymentKlarnaOrder) {
      result.paymentKlarnaOrder = {orderId: payment?.paymentKlarnaOrder?.orderId, klarnaOrderId: payment?.paymentKlarnaOrder?.klarnaOrderId || ''};
    }
    return result;
  }

  static shrinkPayments(payments: NxtPayment[]) {
    for (const payment of payments) {
      if (payment.paymentBankTransaction) {
        delete (payment as any).paymentBankTransaction.assigned;
        delete (payment as any).paymentBankTransaction.assignments;
        delete (payment as any).paymentBankTransaction.createdAt;
        delete (payment as any).paymentBankTransaction.createSource;
        delete (payment as any).paymentBankTransaction.createStudio;
        delete (payment as any).paymentBankTransaction.foundEventId;
        delete (payment as any).paymentBankTransaction.createdBy;
        delete (payment as any).paymentBankTransaction.insertedAt;
        delete (payment as any).paymentBankTransaction.rawObject;
      }
      if (payment.paymentGiftCard) {
        delete (payment as any).paymentGiftCard.assigned;
        delete (payment as any).paymentGiftCard.assignments;
        delete (payment as any).paymentGiftCard.shopOrder;
        delete (payment as any).paymentGiftCard.additionalInfo;
        delete (payment as any).paymentGiftCard.additionalInfo2;
        delete (payment as any).paymentGiftCard.discountReason;
        delete (payment as any).paymentGiftCard.paymentPaypalTransaction;
        delete (payment as any).paymentGiftCard.additionalPrintText;
        delete (payment as any).paymentGiftCard.monthString;
        delete (payment as any).paymentGiftCard.updatedBy;
        delete (payment as any).paymentGiftCard.bookingInfo;
        delete (payment as any).paymentGiftCard.internalId;
        delete (payment as any).paymentGiftCard.createSource;
        delete (payment as any).paymentGiftCard.validTill;
        delete (payment as any).paymentGiftCard.validFrom;
        delete (payment as any).paymentGiftCard.originalId;
      } else if (payment.paymentKlarnaOrder) {
        delete (payment as any).paymentKlarnaOrder.assigned;
        delete (payment as any).paymentKlarnaOrder.assignments;
      } else if (payment.paymentPaypalTransaction) {
        delete (payment as any).paymentPaypalTransaction.assigned;
        delete (payment as any).paymentPaypalTransaction.assignments;
      }
    }
  }
}
