import {Component, OnInit, Optional} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {SocketInterface} from '../../common-interfaces/socket/socket-interface';
import {Clipboard} from '@angular/cdk/clipboard';
import {DialogService} from '../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {LoginService} from '../../services/login.service';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {TextareaComponent} from '../../components/form-controls/textarea/textarea.component';
import {InputComponent} from '../../components/form-controls/input/input.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgIf} from '@angular/common';
import GeneratePayPalCheckoutLink = SocketInterface.GeneratePayPalCheckoutLink;


@Component({
    selector: 'nxt-create-paypal-checkout',
    templateUrl: './create-paypal-checkout.component.html',
    styleUrls: ['./create-paypal-checkout.component.scss'],
    imports: [NgIf, FlexModule, InputComponent, TextareaComponent, NxtButtonComponent]
})
export class CreatePayPalCheckoutComponent implements OnInit {

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
    private socketService: SocketService,
    private clipboard: Clipboard,
    private dialogService: DialogService,
    private loginService: LoginService,
  ) {
  }

  data: SocketInterface.GeneratePayPalCheckoutLink;
  checkoutLinkData: { id: string, link: string };
  // private getOnlyText = false;
  message = 'Hier kannst du bequem per PayPal bezahlen: {{link}}\n\nDer Termin wird erst nach erfolgter Zahlung geblockt.';
  buttonText = '';

  ngOnInit() {

  }


  public async load(data: GeneratePayPalCheckoutLink) {
    if (data.contactId) {
      const contact = await this.socketService.getContactById(data.contactId);
      if (contact) {
        this.data = {
          contactId: contact.id,
          eventId: data.eventId,
          value: null,
          mobile: contact.mobileFormatted,
          infoForCustomer: data.infoForCustomer,
          name: contact.fullName,
          infoInternal: contact.fullName,
          lang: 'de',
        };
      }
    } else {
      this.data = data;
    }
    if (!this.data.infoForCustomer) {
      this.data.infoForCustomer = 'Terminkaution';
    }
  }

  public copyLink() {
    this.clipboard.copy(this.checkoutLinkData.link);
    this.dialogRef.close();
  }

  public generateMessage() {

  }

  async setWhatsAppChatPinnedState() {
    if (this.loginService.getWorkplace() === 'backoffice') {
      await this.socketService.setWhatsAppChatPinnedState({
        mobile: this.data.mobile,
        createdAt: Date.now(),
        openPayPalCheckout: true,
        pinnedBy: this.loginService.getUsername(),
        info: this.data.value.toMoneyString(),
        name: this.data.name,
        pinned: true,
      });
    }
  }

  async translateTo(lang: string) {
    this.message = (await this.socketService.translate(this.message, lang)).text;
    this.data.infoForCustomer = (await this.socketService.translate(this.data.infoForCustomer, lang)).text;
    this.data.lang = lang;
  }

  async sendMessage() {
    this.dialogService.showLoading('Nachricht wird gesendet');
    this.checkoutLinkData = await this.socketService.generatePayPalCheckoutLink(this.data);
    this.message = this.message.replace('{{link}}', this.checkoutLinkData.link);
    await this.socketService.sendWhatsAppMessage(this.data.mobile, this.message);
    this.setWhatsAppChatPinnedState();
    this.dialogRef.close();
    this.dialogService.hideLoading();
  }

  valueChanged() {
    this.buttonText = '';
    if (this.data.value) {
      this.buttonText = this.data.value.toMoneyString() + ' anfordern';
    }
  }
}
