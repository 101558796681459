<div fxLayout="column" fxLayoutAlign="center center" class="w-full h-100 o-hidden">
  <div *ngIf="!photos" class="pt-4">Fotos werden geladen...</div>
  <div *ngIf="photos" class="text-120">{{photos.length}} Fotos/Videos</div>
  <div fxLayout="row" fxLayoutGap="5px" class="scrollable w-full p-3">
    <div *ngFor="let photo of photos" fxLayoutAlign="center center" style="position:relative; min-width: 200px">
      <img style="height: 300px" class="mouse-pointer" (click)="triggerPhoto(photo.id)" [src]="photo.imgSrc">
      <nxt-checkbox
        [value]="photosSelected[photo.id]"
        (valueChange)="triggerPhoto(photo.id, $event)"
        style="position: absolute; left: 5px; top:5px; width: 28px; height: 28px; border-radius: 4px; background-color: #0000006b">
      </nxt-checkbox>
      <div class="no-wrap" fxLayoutAlign="center center" fxLayout="column" (click)="triggerPhoto(photo.id)" style="position: absolute;bottom: 0; background: rgba(0,0,0,0.5); border-radius: 4px 4px 0 0;" class="p-2">
        <div class="mouse-pointer">{{photo.isImage ? 'Foto' : 'Video'}}</div>
        <div class="mouse-pointer">{{photo.photoCreatedAt | nxtDate: 'dd.MM.yyyy HH:mm'}}</div>
        <div class="mouse-pointer">{{photo.appleDeviceName}}</div>
      </div>
    </div>
  </div>
  <div class="p-3">
    <nxt-button (click)="dialogRef.close()">Abbrechen</nxt-button>
    <nxt-button *ngIf="photosSelectedCount === 1" (click)="save()">1 Foto mit dem Termin verknüpfen</nxt-button>
    <nxt-button *ngIf="photosSelectedCount > 1" (click)="save()">{{photosSelectedCount}} Fotos mit dem Termin verknüpfen</nxt-button>
  </div>
  <div *ngIf="photosSelectedCount > 0" class="pb-3">
    <nxt-button [smallButton]="true" *ngIf="photosSelectedCount === 1" (click)="delete()">1 Foto Löschen</nxt-button>
    <nxt-button [smallButton]="true" *ngIf="photosSelectedCount > 1" (click)="delete()">{{photosSelectedCount}} Fotos Löschen</nxt-button>
  </div>
</div>
