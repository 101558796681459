<div class="nxt-page" fxLayout="column" fxLayoutAlign="start">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-2">
    <div class="ml-2 text-120">Textvorlagen</div>
    <nxt-button-icon *ngIf="dialogRef" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>

  <div fxLayout="row" class="w-full">
    <table class="w-full nxt-table-new">
      <tr>
        <th></th>
        <th nxtPermission="IsJulian">ID</th>
        <th>Beschreibung</th>
      </tr>
      <tr *ngFor="let textTemplate of textTemplateDefs">
        <td style="text-align: center" (click)="edit(textTemplate)">
          <div fxLayoutAlign="center center">
            <nxt-button-icon>edit</nxt-button-icon>
          </div>
        </td>
        <td nxtPermission="IsJulian">{{textTemplate.type}}</td>
        <td>{{textTemplate.description}}</td>
      </tr>
    </table>
  </div>
</div>
