import {NxtReminder, NxtReminderRefType, NxtReminderType} from '../../common-interfaces/nxt.reminder.interface';
import {WorkplaceTools} from './workplace.tools';

export class ReminderTools {

  static getEmptyReminder(): NxtReminder {
    return {
      id: '',
      reminderDate: 0,
      reminderDateTime: 0,
      reminderDateString: '',
      description: '',
      refType: NxtReminderRefType.None,
      createdBy: '',
      createdByStudio: '',
      createdByWorkplace: '',
      createdAt: 0,
      done: false,
      type: NxtReminderType.Manual,
      priority: 0,
      originalReminderDateTime: 0,
      postponeDurations: [],
      updatedBy: '',
      updatedAt: 0
    };
  }

  static getTodoBy(reminder: NxtReminder) {
    if (reminder.toDoByWorkplace) {
      return WorkplaceTools.getText(reminder.toDoByWorkplace);
    } else if (reminder.toDoByUser) {
      return reminder.toDoByUser;
    }
  }
}
