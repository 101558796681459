import {DateTools} from '../date.tools';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import moment from 'moment';
import {PaymentTools} from '../payment.tools';

export class NxtDatagridValueGetters {

  public static getValueGetter() {
    return (params) => {
      let result: any;
      switch (params.colDef.nxtFieldType) {
        case NxtFieldType.DurationStringToNow:
          result = NxtDatagridValueGetters.getValue_DateTime(params);
          break;
        case NxtFieldType.DurationStringToTimeAndToNow:
          const date = NxtDatagridValueGetters.getValue_DateTime(params);
          result = DateTools.format(date, 'HH:mm') + ' (' + moment(date).fromNow() + ')';
          break;
        case NxtFieldType.Array:
          result = NxtDatagridValueGetters.getCellValue(params.data, params.colDef.field, params);
          break;
        case NxtFieldType.GiftcardAssigned:
          result = NxtDatagridValueGetters.getValue_GiftcardAssigned(params);
          break;
        case NxtFieldType.Date_germanDateTime:
        case NxtFieldType.Date_germanTime:
        case NxtFieldType.Date_germanDate:
        case NxtFieldType.Date_germanDateShort:
        case NxtFieldType.Date_germanDateWithDayOfWeek:
        case NxtFieldType.Date_germanDateTimeWithDayOfWeek:
        case NxtFieldType.Date_germanDateWithToNowText:
        case NxtFieldType.Date_germanDateShortWithDayOfWeek:
          result = NxtDatagridValueGetters.getValue_DateTime(params);
          break;
        case NxtFieldType.Date_germanDayOfWeek:
          // return NxtDatagridValueGetters.getValue_DateMonth(params);
          result = DateTools.format(NxtDatagridValueGetters.getValue_DateTime(params), 'EEEE');
          break;
        case NxtFieldType.Date_germanMonth:
          result = DateTools.format(NxtDatagridValueGetters.getValue_DateMonth(params), 'yyyy MM MMMM');
          break;
        case NxtFieldType.Date_germanDateTimeWithSeconds:
          result = NxtDatagridValueGetters.getValue_DateTime(params);
          break;
        case NxtFieldType.PaymentSum:
          result = NxtDatagridValueGetters.getValue_PaymentSum(params);
          break;
        case NxtFieldType.Money:
        case NxtFieldType.MoneyFull:
          result = parseFloat(NxtDatagridValueGetters.getCellValue(params.data, params.colDef.field, params));
          break;
        case NxtFieldType.Decimal:
          result = parseFloat(NxtDatagridValueGetters.getCellValue(params.data, params.colDef.field, params));
          break;
        case NxtFieldType.Number:
          result = parseFloat(NxtDatagridValueGetters.getCellValue(params.data, params.colDef.field, params));
          break;
        case NxtFieldType.Text:
          result = NxtDatagridValueGetters.getCellValue(params.data, params.colDef.field, params);
          if (typeof result === 'object') {
            result = JSON.stringify(result);
          }
          break;

        case NxtFieldType.PaymentType:
          result = PaymentTools.getPaymentTypeText(NxtDatagridValueGetters.getCellValue(params.data, params.colDef.field, params)); // PaymentTypeTools.getText(value, true);
          break;
        case NxtFieldType.PaymentMethod:
          result = PaymentTools.getPaymentMethodText(NxtDatagridValueGetters.getCellValue(params.data, params.colDef.field, params)); // PaymentTypeTools.getText(value, true);
          break;


        default:
          result = NxtDatagridValueGetters.getCellValue(params.data, params.colDef.field, params);
          break;
      }
      if (result === null || result === 'null') {
        result = '';
      }
      return result;
    };
  }


  public static getValue_DateTime(params: any) {
    const value = this.getCellValue(params.data, params.colDef.field, params);
    if (value) {
      return DateTools.parse(value);
    }
  }

  public static getValue_GiftcardAssigned(params: any) {
    const value = this.getCellValue(params.data, params.colDef.field, params);
    if (typeof value === 'boolean') {
      return value ? 'eingelöst' : 'nicht eingelöst';
    }
    return value + '_';
  }

  public static getValue_DateMonth(params: any) {
    const value = this.getValue_DateTime(params);
    if (value) {
      return DateTools.clearDayAndTime(value);
    }
  }


  public static getCellValue(data: any, fields: string, params): any {
    try {
      let value;
      for (const field of fields.split('.')) {
        if (value) {
          value = value[field];
        } else {
          value = data[field];
        }
      }
      return value;
    } catch (err) {
      if (params.getValue) {
        return params.getValue();
      }
      return '_?_';
    }
  }


  public static getValue_Month(params: any) {
    const value = this.getCellValue(params.data, params.colDef.field, params);
    return DateTools.parse(DateTools.format(value, 'yyyy-MM'), 'yyyy-MM');
  }


  public static getValue_PaymentSum(params: any) {
    try {
      const value = this.getCellValue(params.data, params.colDef.field, params);
      let sum = 0;
      for (const payment of value) {
        sum += payment.paymentValue;
      }
      return sum;
    } catch (err) {
      return 0;
    }
  }

}
