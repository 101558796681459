import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, input, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {MtxTooltip} from '@ng-matero/extensions/tooltip';
import {NgStyle} from '@angular/common';
import {CalendarEvent} from 'angular-calendar';
import {EventMeta} from '../calendar.component';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {CalendarArtistSpotTooltipComponent} from './calendar-artist-spot-tooltip/calendar-artist-spot-tooltip.component';

@Component({
    selector: 'nxt-calendar-artist-spot',
    templateUrl: './calendar-artist-spot.component.html',
    styleUrls: ['./calendar-artist-spot.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MtxTooltip, NgStyle, CalendarArtistSpotTooltipComponent]
})

export class CalendarArtistSpotComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/
  event = input.required<CalendarEvent<EventMeta>>();

  /*** Outputs ***/

  /*** Signals ***/
  textColor = computed(() => {
    if (this.event().color?.primary) {
      return ColorTools.getLightOrDarkFontColorByBackground(this.event().color.primary);
    }
    return '';
  });

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }
}
