import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {NxtReminder} from '../../../common-interfaces/nxt.reminder.interface';

@Pipe({
    name: 'reminderDateTimeClass',
    standalone: true
})
export class ReminderDateTimeClassPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: (NxtReminder & { overDueMinutes?: number })): string {
    switch (NxtReminder.GetReminderColor(value)){
      case 'red':
        return 'background-dark-red';
      case 'orange':
        return 'background-orange-dark';
    }
  }
}
