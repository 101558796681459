<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>Artists</nxt-page-header-title>
    <nxt-button (click)="editArtist()">neuer Artist</nxt-button>
    <nxt-button (click)="syncFromAc()">AC SYNC</nxt-button>
    <nxt-input [noPadding]="true" [(value)]="quickFilterText" placeholder="Suche"></nxt-input>
    <nxt-slide-toggle [(value)]="showDisabledArtists" (valueChange)="filterArtists()">Deaktivierte Artists</nxt-slide-toggle>
    <nxt-button nxtPermission="IsJulian" (click)="showArtistSkillFilterClicked()">nach Skill filtern</nxt-button>
  </nxt-page-header>
  <nxt-page-content>
    <nxt-datagrid
      *ngIf="artistsFiltered && columnDefs"
      fxFlex
      name="newEvents"
      [columnDefs]="columnDefs"
      [disableAllSort]="false"
      [quickFilterText]="quickFilterText"
      [rowData]="artistsFiltered"
      [showFooter]="false"
      [showSideBar]="false"
      [suppressCellSelection]="true"
      (rowDoubleClick)="rowDoubleClicked($event)"
      [enableRangeSelection]="false"
      [suppressRowHoverHighlight]="true"
    >
    </nxt-datagrid>

  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>
