import {ChangeDetectionStrategy, Component, computed, inject, model, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {
  NxtBankConfigAccount,
  NxtBankConfigAccountType,
  NxtBankDocument,
  NxtBankTransaction,
  NxtBankTransactionStatus,
  NxtBankTransactionType,
} from '../../../../common-interfaces/bank/bank-transaction.interface';
import {Money2Pipe} from '../../../../pipes/money-2.pipe';
import {NxtDatePipe} from '../../../../pipes/nxt-date-pipe';
import {SocketService} from '../../../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {DialogService} from '../../../../services/dialog.service';
import {NxtButtonIconComponent} from '../../../../controls/button-icon/nxt-button-icon.component';
import {BankTransactionDebugComponent} from './bank-transaction-debug/bank-transaction-debug.component';
import {NxtEventFilePipe} from '../../../../pipes/nxt-event-file.pipe';
import {LocalStorageService} from '../../../../services/local-storage.service';
import {LoginService} from '../../../../services/login.service';
import {documentId} from '@angular/fire/firestore';
import {BankTransactionFinderComponent, PossibleNxtBankTransaction} from '../bank-transaction-finder/bank-transaction-finder.component';
import {MatTooltip} from '@angular/material/tooltip';
import {BankTransactionService} from '../bank-transaction.service';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {Clipboard} from '@angular/cdk/clipboard';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {ColorTools} from '../../../../common-browser/helpers/color.tools';
import {BankDocumentTypePipe} from '../../../../pipes/bank-transaction-status.pipe';
import {BankCompanySelectComponent} from '../../bank-company-select/bank-company-select.component';
import {TimeTools} from '../../../../common-browser/helpers/time.tools';
import {firstValueFrom} from 'rxjs';
import {JsonTools} from '../../../../common-browser/helpers/json.tools';
import {BankDocumentDetailsComponent} from '../../bank-documents/bank-document-details/bank-document-details.component';
import {MultiClickDirective} from '../../../../directives/multi-click.directive';

type NxtBankDocumentWithAssignValue = NxtBankDocument & { assignValue: number };

@Component({
  selector: 'nxt-bank-transaction-details',
  templateUrl: './bank-transaction-details.component.html',
  styleUrls: ['./bank-transaction-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [Money2Pipe, NxtDatePipe, MatIcon, NxtButtonIconComponent, NxtEventFilePipe, BankTransactionFinderComponent, MatTooltip, NxtButtonComponent, RouterLink, BankDocumentTypePipe, MultiClickDirective],
  standalone: true,
})

export class BankTransactionDetailsComponent extends NxtComponent implements OnInit, NxtOnDestroy {


  constructor() {
    super();
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  selectedPossibleTransactions = model<PossibleNxtBankTransaction[]>([]);
  selectedAssignedTransactions = model<PossibleNxtBankTransaction[]>([]);
  transaction = signal<NxtBankTransaction | null>(null);
  possibleTransactions = signal<PossibleNxtBankTransaction[]>([]);
  bankAccounts = signal<NxtBankConfigAccount[]>([]);
  saved = signal(false);
  documentsWithAssignValue = signal<NxtBankDocumentWithAssignValue[]>([]);
  assignedTransactions = signal<NxtBankTransaction[]>([]);
  forceShowSetArtistCustomerButton = signal(false);

  /*** Computeds ***/
  public showNeedInvoiceButton = computed(() => {
    return [
      NxtBankTransactionStatus._90_ok,
      NxtBankTransactionStatus._07_documentMatch,
      NxtBankTransactionStatus._05_transferMatch,
    ].includes(this.transaction().status);
  });
  transferFinderAccountIds = computed<string[]>(() => {
    if (this.transaction().nxtAi.datevOther.accountId) {
      return [this.transaction().nxtAi.datevOther.accountId];
    }
    return [];
  });

  transferFinderBankAccountTypes = computed<NxtBankConfigAccountType[]>(() => {
    if (this.transaction().method === 'bank-to-cash' || this.transaction().method === 'cash-to-bank') {
      if (this.account().type === 'cash-register') {
        return ['spk'];
      }
      if (this.account().type === 'spk') {
        return ['cash-register'];
      }
    }
    return [];
  });

  transferFinderTransactionMethods = computed<string[]>(() => {
    if (this.transaction().method === 'bank-to-cash' || this.transaction().method === 'cash-to-bank') {
      return [this.transaction().method];
    }
    return [];
  });

  bankAccountsById = computed(
    () => Object.fromEntries(this.bankAccounts().map(bankAccount => [bankAccount.id, bankAccount])),
  );

  otherAccount = computed(() => {
    if (this.transaction().nxtAi?.datevOther) {
      return {
        name: this.transaction().nxtAi.datevOther.name,
        name2: this.transaction().nxtAi.datevOther.name2,
        datevNo: this.transaction().nxtAi.datevOther.datevNo,
      };
    }
  });

  otherName = computed(() => {
    let name = this.bankAccounts().find(a => a.iban === this.transaction().other.iban)?.name;
    if (!name) {
      name = this.transaction().other.name;
      if (this.transaction().other.name2) {
        name += '\n' + this.transaction().other.name2;
      }
    }
    return name;
  });
  account = computed(() => {
    return this.bankAccounts().find(a => a.id === this.transaction().accountId);
  });

  /*** Injections ***/
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private clipboard = inject(Clipboard);
  private loginService = inject(LoginService);
  private bankTransactionService = inject(BankTransactionService);
  dialogRef = inject(MatDialogRef, {optional: true});
  dialogService = inject(DialogService);
  private socketService = inject(SocketService);
  private localStorageService = inject(LocalStorageService);

  protected readonly NxtBankTransactionStatus = NxtBankTransactionStatus;

  protected readonly ColorTools = ColorTools;

  protected readonly NxtBankTransactionType = NxtBankTransactionType;
  needInvoiceText = computed(() => {
    if (this.transaction().nxtAi?.needTransaction) {
      return 'Transaktion: benötigt';
    }
    if (this.transaction().nxtManual?.missingInvoice) {
      return 'Rechnung: nicht auffindbar';
    }
    return 'Rechnung: ' + (this.transaction().nxtAi?.needInvoice ? 'benötigt' : 'nicht nötig');
  });
  otherCompanyName = computed(() => {
    if (this.transaction().method === 'cash-to-bank' || this.transaction().method === 'bank-to-cash') {
      return '';
    }
    return this.account().name;
  });

  async ngOnInit() {
    if (this.route.snapshot.paramMap.get('shortId')) {
      this.load(this.route.snapshot.paramMap.get('shortId')).then();
    }

    this.pushSocketSubscription = this.socketService.subscribeNew('eventBankTransactionChangedId', (data) => {
      if (data.op === 'update' && data.id === this.transaction().id) {
        this.saved.set(true);
        this.load(this.transaction().id).then();
      }
    });
  }

  nxtOnDestroy() {
  }

  async setTransactionToCustomerDeposit() {
    const studio = await this.dialogService.showStudioSelect('In welchen Studio?', {onlyHead: true});
    if (studio) {
      await this.socketService.bank.setTransactionToCustomerDeposit(this.transaction().id, studio);
      this.saved.set(true);
    }
  }

  async needInvoiceClicked() {
    const result = await this.dialogService.showButtons('Was braucht die Transaktion',
      {
        buttons: [
          {text: '<div class="flex flex-row items-center"><span class="material-symbols-outlined">sync_alt</span>&nbsp;Transaktion</div>', value: 4},
          {text: '<div class="flex flex-row items-center"><span class="material-symbols-outlined">description</span>&nbsp;Rechnung</div>', value: 1},
          {text: '<div class="flex flex-row items-center"><span class="material-symbols-outlined">not_listed_location</span>&nbsp;Rechnung nicht auffindbar</div>', value: 3},
          {text: '<div class="flex flex-row items-center"><span class="material-symbols-outlined">download_done</span>&nbsp;nichts</div>', value: 2},
        ], showCancelButton: true,
      });
    if (result) {
      if (result.value === 1 || result.value === 2) {
        await this.socketService.bank.setTransactionNeedInvoice(this.transaction().id, result.value === 1);
      } else if (result.value === 3) {
        await this.socketService.bank.setTransactionMissingInvoice(this.transaction().id, true);
      } else if (result.value === 4) {
        await this.socketService.bank.setTransactionNeedTransaction(this.transaction().id, true);
      }

      this.saved.set(true);
    }
  }


  public showDebugClicked() {
    const dialog = this.dialogService.showComponentDialog(BankTransactionDebugComponent);
    dialog.componentInstance.set(this.transaction());
  }

  public async load(transactionId: string) {
    this.localStorageService.setByString('lastBankTransactionId', transactionId);
    // await TimeTools.sleep(1);
    const [transaction, accounts] = await Promise.all([

      this.socketService.getBankTransaction(transactionId),
      this.socketService.bank.getAccounts(),
    ]);
    this.localStorageService.setByString('lastAssingDocumentId', documentId);
    this.bankAccounts.set(accounts);
    this.transaction.set(transaction);
    this.documentsWithAssignValue.set([]);
    this.assignedTransactions.set([]);
    this.possibleTransactions.set([]);
    if (transaction.assignedDocuments.length > 0) {
      const documents = await this.socketService.getBankDocumentsByIds(transaction?.assignedDocuments.map(d => d.id));
      for (const document of documents) {
        document.assignValue = transaction.assignedDocuments.find(d => d.id === document.id)?.value;
      }
      this.documentsWithAssignValue.set(documents);
    }
    if (transaction.nxtAi?.needTransaction) {
      if (transaction.assignedTransactions.length > 0) {
        const transferTransactions = await this.socketService.getBankTransactionsByIds(transaction.assignedTransactions.map(d => d.id));
        this.assignedTransactions.set(transferTransactions);
      }
      //  this.loadPossibleBankTransactions();
    }
  }

  public closeClicked() {
    this.dialogRef.close(this.saved());
  }

  public documentClicked(document: NxtBankDocument) {
    const dialog = this.dialogService.showComponentFull(BankDocumentDetailsComponent);
    dialog.componentInstance.load(document.id).then();

  }

  public async newNoteClicked() {
    const result = await this.dialogService.showInput('Neue Notiz');
    if (result) {
      await this.socketService.addBankTransactionNote(this.transaction().id, result);
    }
  }

  public async runClicked() {
    await this.bankTransactionService.run([this.transaction().id]);
  }

  async assignSelectedTransactions() {
    let text = 'Sollen die ausgewählten ' + this.selectedPossibleTransactions().length + ' Buchungen verknüpft werden?';
    if (this.selectedPossibleTransactions().length === 1) {
      text = 'Soll die ausgewählte Buchung verknüpft werden?';
    }
    if (await this.dialogService.showYesNo(text, {yesText: 'Ja, verknüpfen'})) {
      await this.socketService.assignTransactionToTransactions(this.transaction().id, this.selectedPossibleTransactions().map(t => t.id));
      this.selectedAssignedTransactions.set([]);
      this.selectedPossibleTransactions.set([]);
      this.saved.set(true);
    }
  }

  async removeAssignedTransactionsClicked() {
    let text = 'Sollen die Verknüpfung mit den ' + this.selectedAssignedTransactions().length + ' Buchungen aufgehoben werden?';
    if (this.selectedPossibleTransactions().length === 1) {
      text = 'Soll die Verknüpfung mit der ausgewählten Buchung aufgehoben werden?';
    }
    if (await this.dialogService.showYesNo(text, {yesText: 'Ja, Verknüpfung aufheben'})) {
      await this.socketService.removeTransactionsFromTransaction(this.transaction().id, this.selectedAssignedTransactions().map(t => t.id));
      this.selectedAssignedTransactions.set([]);
      this.selectedPossibleTransactions.set([]);
      this.saved.set(true);
    }
  }

  async setOtherCompanyClicked() {
    const dialog = this.dialogService.showComponentDialog(BankCompanySelectComponent);
    dialog.componentInstance.loadFromTransaction(this.transaction());
    dialog.componentInstance.headerText.set('Gegenkonto auswählen');
  }

  copyUrlClicked(ev: MouseEvent) {
    const url = window.location.origin + '/bt/' + this.transaction().shortId;
    this.clipboard.copy(url);
    if (ev.shiftKey || ev.button === 1) {
      window.open(url, '_blank');
    }
  }

  public async noteClicked(note: { at: number; by: string; text: string }) {
    if (this.loginService.isJulian()) {
      const result = await this.dialogService.showYesNo(note.at.dateFormat('dd.MM.yyyy HH:mm') + '\n' + note.by + ': ' + note.text, {
        noText: 'Löschen',
        yesText: 'Ok',
        textAlign: 'center',
      });
      if (!result) {
        await this.socketService.removeBankTransactionNote(this.transaction().id, note.at);
      }
    }
  }

  public async runDateExportClicked() {
    const datevExportLine = await this.socketService.bank.getDatevExportData(this.transaction());
    const table = JsonTools.toTable(datevExportLine.exportLines);
    this.dialogService.showOk('<div class="text-[80%]">' + table + '</div>');
  }

  showSetArtistCustomerButton() {
    if (this.loginService.isJulian()) {
      this.forceShowSetArtistCustomerButton.set(true);
    }
  }
}
