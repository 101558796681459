import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {NgIf} from '@angular/common';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {DateRangePickerComponent} from '../../form-controls/date-range-picker/date-range-picker.component';

@Component({
    selector: 'nxt-studio-fee-klarna-invoice',
    templateUrl: './studio-fee-klarna-invoice.component.html',
    styleUrls: ['./studio-fee-klarna-invoice.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DateRangePickerComponent, NxtButtonComponent, NgIf]
})
export class StudioFeeKlarnaInvoiceComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  dateRange: { from: string, till: string };

  constructor(
    private dialogRef: MatDialogRef<any>,
    private cdRef: ChangeDetectorRef,
    private socketService: SocketService,
  ) {
    super();
  }

  ngOnInit() {

  }

  nxtOnDestroy() {

  }

  async createClicked() {
    this.dialogRef.close(this.dateRange);
  }

  async closeClicked() {
    this.dialogRef.close();
  }
}
