import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {InventoryService} from '../../../services/inventory.service';
import {NxtInventoryItem, NxtInventoryItemPackage} from '../../../common-interfaces/nxt.inventory-item.interface';
import {IconTools} from '../../../common-browser/helpers/icon.tools';
import {DialogService} from '../../../services/dialog.service';
import {InventoryPackageEditComponent} from './inventory-package-edit/inventory-package-edit.component';
import {firstValueFrom} from 'rxjs';
import {LoginService} from '../../../services/login.service';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NgIf} from '@angular/common';


@Component({
    selector: 'nxt-inventory-packages',
    templateUrl: './inventory-packages.component.html',
    styleUrls: ['./inventory-packages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, NxtDatagridComponent, NxtButtonComponent]
})

export class InventoryPackagesComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor(
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<any>,
    private inventoryService: InventoryService,
    private dialogService: DialogService,
    private loginService: LoginService
  ) {
    super();
  }

  colDefs: NxtColDef<NxtInventoryItemPackage>[] = [
    {
      headerName: '', field: 'name',
      cellRenderer: () => IconTools.Material.Edit,
      nxtOnCellClicked: (params) => this.editItemClicked(params.data),
      minWidth: 45, maxWidth: 45,
      cellStyle: {textAlign: 'center'}
    },
    {headerName: 'Quelle', field: 'sourceOfSupply', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Bezeichnung', field: 'name', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Menge', field: 'amount', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Preis (netto)', field: 'packagePriceNet', nxtFieldType: NxtFieldType.Money},
  ];
  item: NxtInventoryItem;

  private itemId: string;

  ngOnInit() {
    this.pushSubscription = this.inventoryService.inventoryItems.subscribe(() => {
      this.reload();
    });
  }

  nxtOnDestroy() {

  }

  load(itemId: string) {
    this.itemId = itemId;
    this.reload();
  }

  private reload() {
    this.item = this.inventoryService.inventoryItems.getValue().find(i => i.id === this.itemId);
    this.cdRef.detectChanges();
  }

  private async editItemClicked(itemPackage: NxtInventoryItemPackage) {
    this.showInventoryItemPackageEdit(itemPackage);
  }

  async showInventoryItemPackageEdit(packageItem: NxtInventoryItemPackage) {
    const dialog = this.dialogService.showComponentDialog(InventoryPackageEditComponent);
    dialog.componentInstance.load(packageItem, this.getSourceOfSupplies());
    const resultPackage = await firstValueFrom(dialog.afterClosed());
    if (resultPackage) {
      this.item.packages = this.item.packages.filter(p => !!p.id);
      this.item.packages = this.item.packages.filter(p => p.id !== resultPackage.id);
      if (!resultPackage.delete) {
        this.item.packages.push(resultPackage);
      }
      this.item.packages = this.item.packages.sortNumber('amount');
      this.inventoryService.upsertInventoryItem(this.item, this.loginService.getUsername());
      this.cdRef.detectChanges();
    }
  }

  async newPackageClicked() {
    const packageItem: NxtInventoryItemPackage = {
      id: '',
      name: '',
      amount: null,
      sourceOfSupply: ''
    };
    this.showInventoryItemPackageEdit(packageItem);
  }

  getSourceOfSupplies() {
    const sourceOfSupplies: string[] = [];
    if (this.item.sourceOfSupply) {
      sourceOfSupplies.push(this.item.sourceOfSupply);
    }
    if (this.item.sourceOfSupply2) {
      sourceOfSupplies.push(this.item.sourceOfSupply2);
    }
    return sourceOfSupplies;
  }

  closeClicked() {
    this.dialogRef.close();
  }
}
