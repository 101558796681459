<div [formGroup]="form" style="min-width: 35vw;">
  <div fxLayout="column">

    <!--
    <nxt-input placeholder="Gutschein-Anzahl" [value]="giftCardCount" [isNumber]="true" (valueChange)="giftCardCount = $event" width="100%"></nxt-input>
-->
    <div fxLayout="row">
      <nxt-input [disableFocusOnInit]="true" placeholder="Gutschein-Code" width="100%" [nxtFormControl]="form.get('name')"
                 [icon]="{name: 'autorenew', tooltip:'neu generieren', click: refreshInInputClicked}"></nxt-input>
    </div>
    <nxt-input fxFlex #valueInput placeholder="Wert" [isMoney]="true" [nxtFormControl]="form.get('value')"></nxt-input>

    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayoutGap="15px" fxLayoutAlign="center" class="flex  flex-row w-full">
        <nxt-input placeholder="Bezahlt" class="w-1/2" [isMoney]="true" [nxtFormControl]="form.get('paymentValue')"></nxt-input>
        <nxt-input [disabled]="!!discountAction" class="w-1/2" placeholder="% Rabatt" [isPercent]="true" [(value)]="discountPercentage"
                   (valueChange)="discountPercentageChanged()"></nxt-input>
      </div>
      <ng-container *ngIf="discountActions.length > 0">
        <div class="pb-3">
          <nxt-radio direction="column" [(value)]="discountAction" (valueChange)="discountChanged()" [options]="discountActions" textKey="name">
          </nxt-radio>
        </div>
      </ng-container>
    </div>
    <!--<nxt-slide-toggle *ngFor="let discountAction of discountActions" fxFlex="30" [(value)]="xmas15" (valueChange)="xmas15Changed()">XMAS15</nxt-slide-toggle>-->
    <!--<nxt-slide-toggle fxFlex="30" placeholder=XMAS15" [(value)]="xmas15" (valueChange)="discountPercentageChanged()"></nxt-slide-toggle>-->

    <nxt-date-picker *ngIf="false" fxFlex placeholder="Datum" [readonly]="true" [nxtFormControl]="form.get('createdAt')"></nxt-date-picker>
    <nxt-input fxFlex placeholder="Info (intern)" [nxtFormControl]="form.get('additionalInfo')"></nxt-input>
    <nxt-input *ngIf="discountPercentage > 0" fxFlex placeholder="Rabattgrund" [nxtFormControl]="form.get('discountReason')"></nxt-input>

    <nxt-select
      [nxtFormControl]="form.get('paymentMethod')"
      placeholder="Zahlart"
      (valueChange)="paymentMethodChange()"
      [options]="[{ text: 'Kasse', value: 'cash' }, { text: 'Paypal', value: 'paypal' }]"
    >
    </nxt-select>

    <nxt-slide-toggle [disabled]="!!discountAction" [(value)]="disableDiscountPromo">nicht bei Rabatt-Aktionen</nxt-slide-toggle>

    <nxt-autocomplete
      *ngIf="form.get('paymentMethod').value === 'paypal'"
      [nxtFormControl]="form.get('paymentPaypalTransaction')"
      [options]="notAssignedPaypalPayments"
      [displayWith]="displayPaypalTransactionFn"
      [displayInOptionWith]="displayPaypalTransactionOptionFn"
      nxtMatOptionClass="paypal-option"
      placeholder="Paypalzahlung"
      label="Paypalzahlung"
      [maxOptionsToShow]="20"
      [filterFields]="['from', 'message', 'value', 'transactionTime']"
    >
    </nxt-autocomplete>


    <div fxLayout="row" fxLayoutAlign="start end">
      <mat-radio-group
        *ngIf="!disableRestriction && enableRestriction && false"
        style="display: flex;flex-direction: column;margin: 0 10px;"
        [formControl]="$any(form.get('additionalPrintText'))">
        <mat-radio-button style="margin: 10px 0" class="studio-radio-button" value="">normaler Gutschein</mat-radio-button>
        <mat-radio-button style="margin: 10px 0" class="studio-radio-button" value="daySession">nur für Tagessession</mat-radio-button>
        <!--<mat-radio-button style="margin: 10px 0" class="studio-radio-button" value="custom">Mindestpreis festlegen</mat-radio-button>-->
        <!--<mat-radio-button style="margin: 10px 0" class="studio-radio-button" value="thankYou">Dankeschön</mat-radio-button>-->
        <!--<mat-radio-button style="margin: 10px 0" class="studio-radio-button" value="Google Bewertung">Google Bewertung</mat-radio-button>-->
      </mat-radio-group>
      <!--<nxt-input
        *ngIf="showCustomRestriction"
        [isMoney]="true"
        placeholder="Mindestpreis"
        [nxtFormControl]="form.get('customRestrictionValue')"
      ></nxt-input>-->
    </div>
    <nxt-input [isMoney]="true" placeholder="Tattoo-Mindestpreis" [nxtFormControl]="form.get('customRestrictionValue')"></nxt-input>
    <nxt-date-picker-2 [nxtFormControl]="form.get('validFrom')"></nxt-date-picker-2>
    <nxt-date-picker-2 [nxtFormControl]="form.get('validTill')"></nxt-date-picker-2>
    <nxt-row>
      <nxt-col fxFlex>
        <div *ngIf="printerIsAvailable === 0" style="color: red;">DRUCKER IST NICHT AN! --> ANSCHALTEN</div>
        <div *ngIf="printerIsAvailable === 1" style="color: green;">DRUCKER IST AN!</div>
      </nxt-col>
    </nxt-row>
    <br/>
    <nxt-row>
      <nxt-col fxFlex>
        <nxt-button style="margin: auto;" (click)="closeClicked()">ABBRECHEN</nxt-button>
      </nxt-col>
      <nxt-col fxFlex>
        <nxt-button [disabled]="buttonOkDisabled" style="margin: auto;"
                    (click)="buttonOkClicked()">{{ buttonOkDisabled ? 'BITTE WARTEN...' : 'SPEICHERN' }}
        </nxt-button>
      </nxt-col>
    </nxt-row>
  </div>
</div>
