@if (artistConfirm(); as artistConfirm) {
  <div class="flex flex-col">

    <div class="p-3 text-[180%]">Warte auf Bestätigung von "{{ artistConfirm.artist }}"</div>


    @if (!artistConfirm.earlyPayout) {
      <div class="flex flex-row justify-center pt-3">
        <nxt-button (click)="canceleClicked()">Abbrechen</nxt-button>
      </div>
    }

  </div>
}
