import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {MatDialogRef} from '@angular/material/dialog';
import {SocketService} from '../../../services/socket/socket.service';
import {QRCodeComponent} from 'angularx-qrcode';

@Component({
  selector: 'nxt-photo-iphone-init-qr-code',
  templateUrl: './photo-iphone-init-qr-code.component.html',
  styleUrls: ['./photo-iphone-init-qr-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtButtonComponent, QRCodeComponent],
  standalone: true,
})

export class PhotoIphoneInitQrCodeComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  qrCode = signal('');

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private dialogRef = inject(MatDialogRef);
  private socketService = inject(SocketService);

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  async setModel(model: string) {
    const secret = await this.socketService.getEventQrCodeSecret();
    const url = encodeURI(window.location.origin + '/event-qr-2/' + model + '/' + secret);
    this.qrCode.set(url);
  }

  closeClicked() {
    this.dialogRef.close();
  }
}
