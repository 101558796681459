<div *ngIf="errorText">{{ errorText }}</div>
<div *ngIf="!errorText" class="fontsize5 wrapper borders" style="width:100%; height: 100%; border: 3px solid black">
  <div fxFlex fxLayout="column" class="borders" style="width:100%; height: 100%; border: 3px solid red">
    <div [fxFlex]="data.pageCountCurrent === 1 ? 30 : 18" class="borders" fxLayout="row" style="border: 3px solid blue; width:100%">
      <div fxFlex="65" fxLayout="column" class="borders" style="border: 3px solid yellow;">

       <div fxFlex>
          <img src="assets/logo/logo_black.svg" style="max-height:70%; max-width:60%;" alt=""/>
        </div>

        <div *ngIf="data.pageCountCurrent === 1" fxFlex class="address borders" fxLayout="column" fxLayoutAlign="end start" style="border: 3px solid red;">
          <div>
            <div class="address-from fontsize5">{{ getFrom() }}</div>
            <div class="address-to fontsize4">
              {{ data.invoiceTo.salutation }} {{ data.invoiceTo.name }}<br/>
              <div *ngIf="data.invoiceTo.name2">{{ data.invoiceTo.name2 }}</div>
              {{ data.invoiceTo.street }}<br/>
              {{ data.invoiceTo.countryCode }}-{{ data.invoiceTo.postalCode }} {{ data.invoiceTo.city }}<br/>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="35" class="borders" style="border: 3px solid magenta;" fxLayoutAlign="end start" fxLayout="row">
        <div fxFlex class="borders" fxLayout="column" style="border: 3px solid green;" fxLayoutAlign="start end">

          <div fxFlex class="borders" style="border: 3px solid orange; width:100%" fxLayoutAlign="end end" fxLayout="column">
            <div class="fontsize2">{{ data.invoiceTitle }}</div>
            <br/>
            <table class="header-data-table">
              <tr *ngIf="data.invoiceNumber">
                <td>RE-Nr.:</td>
                <td>{{ data.invoiceNumber }}</td>
              </tr>
              <tr>
                <td>Kundennr.:</td>
                <td>{{ data.invoiceTo.customerNumber }}</td>
              </tr>
              <tr>
                <td>Datum:</td>
                <td>{{ data.invoiceDate | nxtDate: 'dd.MM.yyyy' }}</td>
              </tr>
              <tr>
                <td>Seite:</td>
                <td>{{ data.pageCountCurrent }}/{{ data.pageCountTotal }}</td>
              </tr>
            </table>
          </div>
          <br/>
          <br/>
          <table class="header-company-address-table">
            <tr>
              <td>{{ configService.config.value.invoiceData.fromCompany }}</td>
            </tr>
            <tr *ngIf="configService.config.value.invoiceData.fromCompany2">
              <td>{{ configService.config.value.invoiceData.fromCompany2 }}</td>
            </tr>
            <tr>
              <td>{{ configService.config.value.invoiceData.fromStreet }}</td>
            </tr>
            <tr>
              <td>DE-{{ configService.config.value.invoiceData.fromPostalCode }} {{ configService.config.value.invoiceData.fromCity }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div fxFlex="5"></div>
    <div [fxFlex]="data.pageCountCurrent === 1 ? 60 : 72">
      <!--<div>Folgende Lieferungen/Leistungen schreiben wir Ihnen gut.</div>-->
      <br/>
      <table class="pos-table">
        <tr>
          <th>Pos.</th>
          <th>Bezeichnung</th>
          <th>Menge</th>
          <th>Einzel €</th>
          <th>Gesamt €</th>
        </tr>
        <tr *ngFor="let pos of data.invoicePositions">
          <td class="cell-align-right" style="width: 7%">{{ pos.posNumber }}</td>
          <td style="width: 59%" [innerHTML]="pos.name | safeHtml"></td>
          <td class="cell-align-right no-wrap" style="width: 10%">{{ pos.amount }}</td>
          <td class="cell-align-right no-wrap" style="width: 12%">{{ pos.unitPrice | money }}</td>
          <td class="cell-align-right no-wrap" style="width: 12%">{{ pos.amount * pos.unitPrice | money }}</td>
        </tr>
        <tr *ngIf="data.pageCountCurrent === data.pageCountTotal">
          <td colspan="4">
            Gesamtbetrag*
          </td>
          <td class="cell-align-right whitespace-nowrap">{{ data.invoiceValueTotal | money }}</td>
        </tr>

      </table>
      <br/>
      <div *ngIf="data.pageCountCurrent === data.pageCountTotal">
        <div *ngIf="showTotalTaxInfo">
          * Im Gesamtbetrag von {{ data.invoiceValueTotalForTax | money }} (Netto: {{ getNet() | money }}) sind USt {{ vatPercent }} % ({{ getTax() | money }})
          enthalten.
        </div>
        <br/>
        <br/>
        <!--Mit freundlichen Grüßen.-->
        <br/><br/><br/><br/><br/>
      </div>

      <div>
        Zahlungsziel: {{ termOfPayment }}
      </div>
    </div>

    <div fxFlex="10">
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-around">
          <div>
            {{ configService.config.value.invoiceData.fromCompany }}
            <br/>
            {{ configService.config.value.invoiceData.fromStreet }}
            <br/>
            DE-{{ configService.config.value.invoiceData.fromPostalCode }} {{ configService.config.value.invoiceData.fromCity }}
          </div>
          <div>
            USt-IdNr.: {{ configService.config.value.invoiceData.fromVATNumber }}
            <br/>
            Steuernummer: {{ configService.config.value.invoiceData.fromTaxId }}
            <br/>
            {{ configService.config.value.invoiceData.fromBank }}: {{ configService.config.value.invoiceData.fromBankIBAN }}
          </div>
        </div>

        <div fxLayoutAlign="center">
          <br/>
          Seite {{ data.pageCountCurrent }}/{{ data.pageCountTotal }}
        </div>

      </div>
    </div>
  </div>
</div>
