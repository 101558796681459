import {Injectable} from '@angular/core';
import {DateTools} from '../common-browser/helpers/date.tools';
import {EmojiTools} from '../common-browser/helpers/emoji.tools';
import {FirestoreService} from './firestore.service';
import {SocketService} from './socket/socket.service';
import {DialogService} from './dialog.service';
import {ConfigService} from './config.service';
import {DecimalTools} from '../common-browser/helpers/decimal.tools';
import {NxtCalendarEvent, NxtWorkType} from '../common-interfaces/nxt.calendar-event.interface';
import {PaymentTools} from '../common-browser/helpers/payment.tools';
import {NxtDiscountPromotion} from '../common-interfaces/discount-promotion.interface';

@Injectable({
  providedIn: 'root',
})
export class AppointmentConfirmationService {

  constructor(
    private firestoreService: FirestoreService,
    private socketService: SocketService,
    private dialogService: DialogService,
    private configService: ConfigService,
  ) {
  }

  public async getConfirmationTextFromEvent(event: NxtCalendarEvent, lang: 'de' | 'en', canceled: boolean) {
    const depositBack = event.payments.find(p => p.paymentType === 'deposit-back');
    const depositPayed = event.payments.filter(p => p.paymentType === 'deposit' && p.paymentMethod !== 'klarna').reduce((s, p) => s + p.paymentValue, 0);
    return this.getConfirmationText({
      customerName: event.customerObj.fullName,
      start: event.start,
      // studio: event.studio,
      appointmentType: event.workType,
      priceEstimatedFrom: event.priceEstimatedFrom,
      priceEstimatedTill: event.priceEstimatedTill,
      priceFix: event.priceFix,
      lang,
      canceled,
      depositBackValue: depositBack?.paymentValue.toMoneyString(),
      depositBackMethod: PaymentTools.getPaymentMethodText(depositBack?.paymentMethod),
      depositPayed,
    });
  }

  public async getConfirmationText(options: {
    customerName: string,
    start: number,
    // studio: string,
    appointmentType: NxtWorkType,
    priceEstimatedFrom: number,
    priceEstimatedTill: number,
    priceFix: boolean,
    lang: 'de' | 'en',
    canceled: boolean,
    depositBackValue?: string,
    depositBackMethod?: string,
    depositPayed: number,
    discountPromotion?: NxtDiscountPromotion
  }) {

    const textPrice = {
      de: 'Preis',
      en: 'Price',
    };

    const textCe = {
      de: 'ca.',
      en: 'ce.',
    };

    const textTo = {
      de: 'bis',
      en: 'to',
    };

    const textDepositHave = {
      de: 'Deine bereits geleistete Terminkaution beträgt',
      en: 'The deposit you have already paid is',
    };

    const textToPay = {
      de: 'Der Betrag den du am Tag des Termins bezahlen musst, beträgt',
      en: 'The amount you have to pay on the day of the appointment is',
    };


    const studioConfig = this.configService.config.value.studios.find(s => !s.onlyCash);
    let text = await (options.canceled ? this.getAppointmentConfirmationTextCanceled(options.lang) : this.getAppointmentConfirmationText(options.lang));

    if (!options.canceled && !text.includes('discount-promotion-info')) {
      this.socketService.sendBackofficeHeads('{{discount-promotion-info}} fehlt in der Termin-Bestätigung, bitte hinzufügen\n[' + options.lang + ']\naktueller Text:\n\n' + text);
      this.socketService.sendTelegramAdmin('{{discount-promotion-info}} fehlt in der Termin-Bestätigung, bitte hinzufügen\n[' + options.lang + ']\naktueller Text:\n\n' + text);
    }

    const studioText = await this.getAppointmentConfirmationStudioText(studioConfig.name, options.lang);

    let studioAdresse = studioConfig.addressOneLine;
    if (studioConfig.googleMapsLink) {
      studioAdresse += ' ' + studioConfig.googleMapsLink;
    }
    let price = textPrice[options.lang] + ': ';
    if (!options.priceFix) {
      price += textCe[options.lang] + ' ';
    }

    if (options.priceEstimatedTill) {
      price += DecimalTools.toMoneyString(options.priceEstimatedFrom) + ' ' + textTo[options.lang] + ' ' + DecimalTools.toMoneyString(options.priceEstimatedTill);
    } else {
      price += DecimalTools.toMoneyString(options.priceEstimatedFrom);
    }

    if (!options.depositBackValue && options.depositPayed) {
      price += '\n' + textDepositHave[options.lang] + ' ' + options.depositPayed.toMoneyString();
      let restPrice = '';
      if (!options.priceFix) {
        restPrice += textCe[options.lang] + ' ';
      }

      if (options.priceEstimatedTill) {
        restPrice += DecimalTools.toMoneyString(options.priceEstimatedFrom - options.depositPayed) + ' bis ' + DecimalTools.toMoneyString(options.priceEstimatedTill - options.depositPayed);
      } else {
        restPrice += DecimalTools.toMoneyString(options.priceEstimatedFrom - options.depositPayed);
      }
      price += '\n' + textToPay[options.lang] + ' ' + restPrice;
    }

    let discountPromotionInfo = '';
    if (options.discountPromotion && options.discountPromotion.id !== 'none') {
      let hourPrice = '100';
      let hour = '7';
      if (this.configService.config.value.studioRegion === 'MA') {
        hourPrice = '80';
        hour = '6';
      }
      if (options.discountPromotion.additionalPayAfterEnd) {
        discountPromotionInfo = '*Solltest das Tattoo nicht in der vorgeschriebenen Zeit (' + hour + ' Stunden) fertig werden, berechnen wir jede weitere Stunde mit ' + hourPrice + '€.'; // +
        // 'Jeden weiteren "' + options.discountPromotion.name + '" mit ' + options.discountPromotion.priceFixValue.toMoneyString() + '.';
        if (options.lang !== 'de') {
          discountPromotionInfo = (await this.socketService.translate(discountPromotionInfo, options.lang)).text;
        }
      }
      discountPromotionInfo = options.discountPromotion.name + '\n' + discountPromotionInfo;
    }

    text = text.replaceAll('{{kunde_name}}', options.customerName);
    text = text.replaceAll('{{customer-name}}', options.customerName);
    text = text.replaceAll('{{uhr_icon}}', EmojiTools.getClock(options.start));
    text = text.replaceAll('{{clock-icon}}', EmojiTools.getClock(options.start));
    text = text.replaceAll('*{{preis}}*', price);
    text = text.replaceAll('{{preis}}', price);
    text = text.replaceAll('{{datum_uhrzeit}}', DateTools.format(options.start, 'EEEE, dd.MM.yyyy HH:mm', options.lang));
    text = text.replaceAll('{{date-time}}', DateTools.format(options.start, 'EEEE, dd.MM.yyyy HH:mm', options.lang));
    text = text.replaceAll('{{studio_adresse}}', studioAdresse);
    text = text.replaceAll('{{studio_text}}', studioText);
    text = text.replaceAll('{{deposit-back-value}}', options.depositBackValue);
    text = text.replaceAll('{{deposit-back-method}}', options.depositBackMethod);
    text = text.replaceAll('{{discount-promotion-info}}', discountPromotionInfo);


    /*if (options.appointmentType === 'tattoo') {
      text = text.replaceAll('{{einverständniserklärung_link}}', studioConfig.acceptanceLinkTattoo);
    } else if (options.appointmentType === 'piercing') {
      text = text.replaceAll('{{einverständniserklärung_link}}', studioConfig.acceptanceLinkPiercing);
    }*/
    if (!options.depositBackValue) {
      text = text.replace(/<if-deposit-back>([\S\s]*)<\/if-deposit-back>/g, '');
    } else {
      text = text.replace(/<if-deposit-back>([\S\s]*)<\/if-deposit-back>/g, (match, inner) => {
        return inner;
      });
    }
    text = text.replaceAll('\n\n\n\n', '\n\n');
    text = text.replaceAll('\n\n\n', '\n\n');

    if (this.configService.config.value.studioRegion === 'AC' && options.start >= '2023-11-20'.dateParse() && options.start <= '2023-11-27'.dateParse()) {
      text += '\n\nAufgrund von Umbaumaßnahmen können unsere Kundenparkplätze aktuell leider nicht genutzt werden.';
    }
    return text.trim();
  }


  private async getAppointmentConfirmationText(lang: 'de' | 'en') {
    return await this.socketService.getAppointmentConfirmationText(lang);
  }

  private async getAppointmentConfirmationTextCanceled(lang: 'de' | 'en') {
    return await this.socketService.getAppointmentConfirmationTextCanceled(lang);
  }

  private setAppointmentConfirmationText(text: string, lang: 'de' | 'en') {
    this.socketService.setAppointmentConfirmationText(text, lang);
  }

  private async getAppointmentConfirmationStudioText(studio: string, lang: 'de' | 'en') {
    return await this.socketService.getAppointmentConfirmationStudioText(studio, lang);
  }

  public async showEditAppointmentConfirmationText(lang: 'de' | 'en') {
    const prompt = await this.getAppointmentConfirmationText(lang);
    const dialog = await this.dialogService.showTextareaOld({
      message: '<strong>TERMINBESTÄTIGUNG</strong> - {{kunde_name}} {{uhr_icon}} {{datum_uhrzeit}} {{studio_adresse}}',
      placeholder: '',
      prompt,
    });
    const result = await dialog.afterClosed().toPromise();
    if (result) {
      this.socketService.setAppointmentConfirmationText(result, lang);
    }
  }

  public async showEditAppointmentConfirmationTextCanceled(lang: 'de' | 'en') {
    const prompt = await this.getAppointmentConfirmationTextCanceled(lang);
    const dialog = await this.dialogService.showTextareaOld({
      message: '<strong>TERMINBESTÄTIGUNG</strong> - {{kunde_name}} {{uhr_icon}} {{datum_uhrzeit}} {{studio_adresse}}',
      placeholder: '',
      prompt,
    });
    const result = await dialog.afterClosed().toPromise();
    if (result) {
      this.socketService.setAppointmentConfirmationTextCanceled(result, lang);
    }
  }

  public async showEditAppointmentConfirmationStudioText(lang: 'de' | 'en') {

    const result = await this.dialogService.showButtons('Studio', {buttons: this.configService.config.value.studios.map(s => ({text: s.name, value: s.name}))});

    if (typeof result !== 'string' && result.value) {
      const prompt = await this.getAppointmentConfirmationStudioText(result.value, lang);
      const dialog = await this.dialogService.showTextareaOld({
        message: '<strong>TERMINBESTÄTIGUNG ZUSATZTEXT ' + result.value + '</strong>',
        placeholder: '',
        prompt,
      });
      const result2 = await dialog.afterClosed().toPromise();
      if (result2) {
        this.socketService.setAppointmentConfirmationStudioText(result.value, result2, lang);
      }
    }
  }
}
