import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {FlexModule} from 'ngx-flexible-layout/flex';


@Component({
    selector: 'nxt-star-cell-renderer',
    template: `
    <div style="height:22px" fxLayout="row" fxLayoutAlign="start center" [innerHTML]="getStars(value?.value)">
    </div>
    <div style="height:22px" fxLayout="row" fxLayoutAlign="start center" [innerHTML]="getHearts(value?.like)">
    </div>
  `,
    styles: [`
    .wrapper {
      height: 22px;
    }
  `],
    imports: [FlexModule]
})
export class StarCellRenderer implements ICellRendererAngularComp {
  value: { value: number, like: number };
  icon = 'star';

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  getStars(value: number) {
    let starsHtml = '';
    for (let i = 0; i < 5; i++) {
      if ((value / 2) >= i + 1) {
        starsHtml += '<img src="/assets/images/grid-cell-renderer/star.svg"/>';
      } else if ((value / 2) >= i + 0.5) {
        starsHtml += '<img src="/assets/images/grid-cell-renderer/star_half.svg"/>';
      } else {
        starsHtml += '<img src="/assets/images/grid-cell-renderer/star_border.svg"/>';
      }
    }
    return starsHtml;
  }

  getHearts(like: any) {
    let heartHtml = '';
    for (let i = 0; i < 5; i++) {
      if (like >= i + 1) {
        heartHtml += '<img src="/assets/images/grid-cell-renderer/heart.svg"/>';
      } else {
        heartHtml += '<img src="/assets/images/grid-cell-renderer/heart_border.svg"/>';
      }
    }
    return heartHtml;
  }
}
