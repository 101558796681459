import {Injectable} from '@angular/core';
import {NxtNgConfigRuntime} from '../common-interfaces/nxt.ng-config.interface';
import {EventTools} from '../common-browser/helpers/event.tools';
import {BehaviorSubject} from 'rxjs';
import {Log} from '../common-browser/log/log.tools';
import {GlobalStudioShortName} from '../common-interfaces/global-studio.interface';


@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  private _ngConfig = new BehaviorSubject<NxtNgConfigRuntime>(null);

  constructor() {
    this._ngConfig.next((window as any).ngConfig);
    if (this._ngConfig.value?.fireConfig?.apiKey === 'XXX') {
      setTimeout(() => {
        Log.error('fireConfig ist noch nicht gesetzt');
      }, 2000);
    }
  }


  public setConfig(config: NxtNgConfigRuntime) {
    this._ngConfig.next(config);
    EventTools.valenceGuideValue = this._ngConfig.value.valenceGuideValue;
  }


  public get config(): BehaviorSubject<NxtNgConfigRuntime> {
    return this._ngConfig;
  }

  public getStudioName(studioShortName: GlobalStudioShortName) {
    return this.config.value.studioRegions.find(s => s.shortName === studioShortName)?.name || '';
  }

  public getLinkForEvent(eventId: string) {
    return window.location.origin + '/e/' + eventId;
  }
}
