import {Component, OnInit, signal} from '@angular/core';
import {JsonTools} from '../../../common-browser/helpers/json.tools';
import {ActivatedRoute} from '@angular/router';
import {ConfigService} from '../../../services/config.service';
import {FlexModule} from 'ngx-flexible-layout';
import {Money2Pipe} from '../../../pipes/money-2.pipe';

export interface NxtTattooTicketPdfData {
  name: string;
  code: string;
  value: number;
  createdAt: number;
}

@Component({
    selector: 'nxt-tattoo-ticket-pdf',
    templateUrl: './tattoo-ticket-pdf.component.html',
    styleUrls: ['./tattoo-ticket-pdf.component.scss'],
  imports: [FlexModule, Money2Pipe],
})
export class TattooTicketPdfComponent implements OnInit {
  public data = signal<NxtTattooTicketPdfData>({
    code: 'TT-1234-5678-9999',
    value: 100,
    createdAt: Date.now(),
    name: 'Max Mustermann'
  });

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigService
  ) {
    if (this.route.snapshot.queryParams.data) {
      this.data = JsonTools.parse(this.route.snapshot.queryParams.data);
    }
  }

  ngOnInit(): void {
    window.document.body.style.backgroundColor = 'transparent';
  }
}
