import {Component, EventEmitter, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ImageViewerConfig} from '../../../components/image-viewer/image-viewer-config.model';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {NxtButtonComponent} from '../../button/nxt-button.component';
import {ImageViewerComponent} from '../../../components/image-viewer/image-viewer.component';
import {MatIcon} from '@angular/material/icon';
import {SpinnerComponent} from '../../../components/spinner/spinner.component';
import {NgFor, NgIf} from '@angular/common';
import {NxtButtonIconComponent} from '../../button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-dialog-image-viewer-async',
    templateUrl: './dialog-image-viewer-async.component.html',
    styleUrls: ['./dialog-image-viewer-async.component.scss'],
    imports: [FlexModule, NxtButtonIconComponent, NgIf, SpinnerComponent, MatIcon, ImageViewerComponent, NgFor, NxtButtonComponent, SafeHtmlPipe]
})
export class DialogImageViewerAsyncComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogImageViewerAsyncComponent>,
  ) {
  }

  imageViewerConfig: ImageViewerConfig = {
    btnClass: 'image-viewer-button',
    wheelZoom: true,
    allowFullscreen: false,
    containerBackgroundColor: '#424242'
  };
  currentSrc = '';
  imageCount: number;
  getImageSrc: (index: number) => Promise<{ src: string; isLast: boolean; isFirst: boolean, title: string }>;
  isFirst = false;
  isLast = false;
  currentIndex = 0;
  isLoading = false;
  title = '';
  buttons: { text: string; value: string }[];

  buttonClicked = new EventEmitter<{ text: string; value: string }>();

  ngOnInit() {
    this.loadCurrentIndex();
  }

  async loadCurrentIndex() {
    this.isLoading = true;
    const data = await this.getImageSrc(this.currentIndex);
    this.currentSrc = data.src;
    this.title = data.title;
    this.isFirst = data.isFirst;
    this.isLast = data.isLast;
    this.isLoading = false;
  }

  cancelButtonClicked() {
    this.dialogRef.close();
  }

  delete() {
    this.dialogRef.close();
  }

  prevImage() {
    if (!this.isLoading) {
      this.currentIndex--;
      this.loadCurrentIndex();
    }

  }

  nextImage() {
    if (!this.isLoading) {
      this.currentIndex++;
      this.loadCurrentIndex();
    }
  }
}
