<nxt-form-field-wrapper *ngIf="nxtFormControl" [nxtFormControl]="nxtFormControl" fxLayout="row">
  <mat-radio-group [fxLayout]="direction" [ngStyle]="{alignItems: direction === 'column' ? 'start' : ''}" [value]="this.nxtFormControl.value" (change)="this.valueChanged($event)">
    <mat-radio-button
      [disabled]="disabled"
      [ngClass]="{'row-item': direction === 'row', 'radio-disabled': disabled}"
      tabIndex="-1" tabindex="-1"
      style="padding: 3px ; white-space: nowrap;"
      *ngFor="let option of options"
      [value]="valueKey ? option[valueKey] : option"
    >
      <div [innerHTML]="getOptionText(option) | safeHtml">
      </div>
    </mat-radio-button>
  </mat-radio-group>
</nxt-form-field-wrapper>
