<div class="nxt-page" fxLayout="column" *ngIf="data">
  <div class="w-full p-3" fxLayoutAlign="start start" fxLayout="row" style="border: 0px solid red;">
    <div fxFlex fxLayoutAlign="center center" fxLayout="column">
      <div class="text-150">{{ data.contact.fullName }} - {{ totalPayed | money }}</div>
      <div fxLayout="column">
        <table class="header-event-count-table" style="width: fit-content;">
          <tr>
            <td>{{ data.contact.canceledEvents.length }}</td>
            <td>abgesagt</td>
          </tr>
          <tr>
            <td>{{ data.contact.closedEvents.length }}</td>
            <td>geschlossene</td>
          </tr>
          <tr>
            <td>{{ data.contact.events.length }}</td>
            <td>zukünftige</td>
          </tr>
        </table>
        <!--<div class="text-120" *ngIf="data.contact.canceledEvents.length > 0">{{data.contact.canceledEvents.length}} abgesagte Termine</div>
        <div class="text-120" *ngIf="data.contact.closedEvents.length > 0">{{data.contact.closedEvents.length}} geschlossene Termine</div>
        <div class="text-120" *ngIf="data.contact.events.length > 0">{{data.contact.events.length}} zukünftige Termine</div>-->
      </div>
      <div class="pt-2">
        <nxt-button *ngIf="sendAppointmentConfirmationsCount === 1" (click)="sendAppointmentConfirmations()">Eine Terminbestätigung schicken</nxt-button>
        <nxt-button *ngIf="sendAppointmentConfirmationsCount > 1" (click)="sendAppointmentConfirmations()">{{ sendAppointmentConfirmationsCount }}
          Terminbestätigungen schicken
        </nxt-button>
      </div>
    </div>

    <nxt-button-icon *ngIf="dialogRef" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" style="overflow: hidden; border: 0px solid blue;">
    <div fxLayout="column" style="overflow: auto; max-width: 100%">


      <mat-table *ngIf="false" [dataSource]="data.events">
        <ng-container matColumnDef="event">
          <th mat-header-cell *matHeaderCellDef>Termin</th>
          <td mat-cell *matCellDef="let event">
            <div fxLayout="column" fxLayoutAlign="center center">
              <nxt-button-icon>edit_calendar</nxt-button-icon>
              {{ event.workType === 'tattoo' ? 'Tattoo' : 'Piercing' }}
            </div>
          </td>
        </ng-container>


        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let event" class="no-wrap td-center" [class]="event.status" style="font-size: 80%">
            <div *ngIf="event.status === 'canceled'">Abgesagt</div>
            <div *ngIf="event.status === 'closed'">Geschlossen</div>
            <div *ngIf="event.status === 'future'">Offen</div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" sticky style="position: sticky;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </mat-table>


      <table class="nxt-table-new text-90" *ngIf="true">
        <thead>
        <tr>
          <th class="!text-center">Termin</th>
          <th class="!text-center">Nachstechen</th>
          <th class="!text-center">Status</th>
          <th class="!text-center">Datum</th>
          <th class="!text-center">Artist</th>
          <th class="!text-center">Preis</th>
          <th class="!text-center">Bezahlt</th>
          <th style="min-width: 300px">Motiv & Stelle</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tr *ngFor="let event of data.events">
          <!--<td>{{event.status}}</td>
          <td>{{event.calendarId}}</td>-->
          <td (click)="openEvent(event)" style="text-align: center; cursor: pointer;">
            <div fxLayout="column" fxLayoutAlign="center center">
              <nxt-button-icon>edit_calendar</nxt-button-icon>
              {{ event.workType === 'piercing' ? 'Piercing' : 'Tattoo' }}
            </div>
          </td>
          <td (click)="createImproveEvent(event)" style="text-align: center; cursor: pointer;">
            @if (event.status !== 'canceled') {
              <div fxLayout="column" fxLayoutAlign="center center" *ngIf="event.workType === 'tattoo'">
                <nxt-button-icon matTooltip="Nachstech-Termin anlegen">construction</nxt-button-icon>
                <div *ngIf="event.improveStatus.label">{{ event.improveStatus.label }}</div>
                <div *ngIf="event.improveStatus.label2" [innerHTML]="event.improveStatus.label2 | safeHtml"></div>
              </div>
            }
          </td>
          <td class="no-wrap td-center" [class]="event.status" style="font-size: 80%">
            <div *ngIf="event.status === 'canceled'">Abgesagt</div>
            <div *ngIf="event.status === 'closed'">Geschlossen</div>
            <div *ngIf="event.status === 'future'">Offen</div>
          </td>
          <td class="no-wrap td-center">
            <div>
              <div>{{ event.start | nxtDate: 'dd.MM.yyyy' }}<br/>{{ event.start | nxtDate: 'HH:mm' }} - {{ event.end | nxtDate: 'HH:mm' }}</div>
              <div style="font-size: 80%">{{ event.start | fromNowText }}</div>
            </div>
          </td>

          <td class="text-center no-wrap" style="padding: 0">
            <div class="w-full p-relative h-100">
              <div class="w-full h-100" fxLayout="column">
                <div *ngIf="event.artistFix" class="text-70">FIX</div>
                <div>{{ event.artist }}</div>
                <div *ngIf="!event.artistFix && isBackoffice && event.status === 'future'" class="pt-1">
                  <!--<nxt-button class="fix-artist-button" (click)="setArtistFix(event)">FIX MACHEN</nxt-button>-->
                </div>
              </div>
            </div>
          </td>

          <td class="no-wrap td-center">
            {{ event.priceFix ? 'fix' : '' }}
            <br *ngIf="event.priceFix">
            <div>{{ event.priceEstimatedFrom | money }}</div>
            <div *ngIf="event.priceEstimatedTill">{{ event.priceEstimatedTill | money }}</div>
          </td>
          <td>{{ event.paymentSum | money }}</td>
          <td style="padding: 0; max-width: 500px">
            <div class="p-relative h-100">
              <div class="h-100" fxLayout="column">
                <div
                  *ngFor="let motive of motiveTexts[event.id]; let i = index"
                  [ngClass]="{divider: i !== 0}"
                  fxFlex
                  fxLayout="column"
                  fxLayoutAlign="center start">
                  <div class="no-wrapx" style="padding: 10px 20px;">
                    {{ motive }}
                  </div>
                </div>
              </div>
            </div>
          <td *ngIf="isBackoffice" style="padding: 0">
            <nxt-slide-toggle *ngIf="event.status === 'future'" [(value)]="appointmentConfirmations[event.id]"
                              (valueChange)="calcShowAppointmentConfirmations()"></nxt-slide-toggle>
          </td>
          <!--<td *ngIf="isBackoffice">
            <nxt-button-icon (click)="sendEventInfoToCustomer()" matTooltip="Info an Kunden schicken">info</nxt-button-icon>
          </td>-->
          <td *ngIf="!isBackoffice" style="padding: 0">
          </td>
          <td *ngIf="!isBackoffice" style="padding: 0">
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

