<nxt-form-field-wrapper [nxtFormControl]="nxtFormControl" [width]="width" class="flex items-center justify-center">
  <div class="flex items-center justify-center mat-form-field-full-width mouse-pointer">
    <mat-checkbox [class.no-label-padding]="!label" [disabled]="disabled" [formControl]="nxtFormControl">
      <div [innerHTML]="label | safeHtml"></div>
    </mat-checkbox>
  </div>
</nxt-form-field-wrapper>





