<div
  class="upload-card dropzone flex flex-col justify-center items-center"
  [ngStyle]="{width: width() + 'px', height: height() + 'px'}"
  nxtFileDrop
  (change)="inputChanged($event)"
  (nxtFileDropFileDropped)="fileDropped($event)">
  <input #uploadInput style="opacity: 0; position: absolute" type="file"/>
  <div>{{ message() }}</div>
  <div class="py-4">oder</div>
  <nxt-button (click)="selectFileClicked()">ODER DATEI AUSWÄHLEN</nxt-button>
</div>
