<nxt-form-field-wrapper
  [nxtFormControl]="nxtFormControl"
  [width]="width"
  [class.bottom-no-padding]="noPadding || bottomNoPadding"
  [class.no-padding]="noPadding"
  [class.placeholder-center]="isMoney || center">
  <mat-form-field class="mat-form-field-full-width {{readonly ? 'read-only' : ''}}" [ngStyle]="{width: icon ? 'calc('+ width + ' - 28px)' : width}">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      [type]="type"
      (focusin)="focusin('control', $event)"
      (focusout)="focusout('control', $event)"
      (ngModelChange)="callCellRenderer()"
      #controlElement
      #clickElement
      matInput

      [formControl]="$any(nxtFormControl)"
      [name]="controlName"
      (keyup)="keyup($event)"
      (click)="inputClicked($event)"
      autocomplete="off"
    />
    <mat-hint *ngIf="showCapsLockInfo">Achtung! Immer groß Schreiben ist aktiv!</mat-hint>
    <mat-error *ngIf="$any(nxtFormControl).invalid && (nxtFormControl.dirty || $any(nxtFormControl).touched)">
      <div>
        {{ errorText }}
      </div>
    </mat-error>
  </mat-form-field>
  <div class="icons">
    <mat-icon (click)="icon.click()" style="color:#a2a2a2; cursor: pointer;" *ngIf="icon"
              [matTooltip]="icon.tooltip">{{ icon.name }}
    </mat-icon>
    <mat-icon class="clear-icon" *ngIf="clearIcon && showClearIcon" (click)="clear()">close</mat-icon>
  </div>
</nxt-form-field-wrapper>

