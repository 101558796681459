<div class="nxt-page h-100">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-2">
    <div fxLayout="row" fxLayoutGap="50px">
      <div class="page-header">Römische Zahlen / Daten</div>
    </div>
    <nxt-button-icon *ngIf="dialogRef" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>
  <div fxFlex fxLayout="column">
    <div fxFlex fxLayout="row" fxLayoutAlign="center center">
      <div *ngIf="showInputs" fxFlex="50%" class="p-4">
        <nxt-date-picker placeholder="Datum" [disableOpenOnClickInput]="true"
                         (nxtModelChange)="dateChanged($event)"
        ></nxt-date-picker>
      </div>
      <div *ngIf="romanDateGerman" class="text-250" fxLayoutAlign="center center">
        <table class="default-table">
          <tr>
            <td>{{date | nxtDate: 'dd.MM.yyyy'}}</td>
            <td>{{date | nxtDate: 'yyyy-MM-dd'}}</td>
          </tr>
          <tr>
            <td>{{romanDateGerman}}</td>
            <td>{{romanDateUs}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="showInputs" fxFlex="50%" class="p-4">
      <nxt-input placeholder="Römische Zahl" (keyup)="romanNumberChanged($event)"></nxt-input>
    </div>
    <div *ngIf="romanNumberConverted !== 0" class="text-250" fxLayoutAlign="center center">
      <div *ngIf="romanNumberConverted > 0">
        <table class="default-table">
          <tr>
            <td>{{romanNumberConverted}}</td>
          </tr>
          <tr>
            <td>{{romanNumber}}</td>
          </tr>
        </table>
      </div>
      <div *ngIf="romanNumberConverted == -1"><- Keine römische Zahl</div>
    </div>
  </div>

  <div fxFlex fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="showInputs" fxFlex="50%" class="p-4">
      <nxt-input placeholder="Dezimalzahl" [isNumber]="true" [(value)]="decimalNumber" (keyup)="decimalNumberChanged()"></nxt-input>
    </div>
    <div fxFlex *ngIf="decimalNumberConverted" class="text-250" fxLayoutAlign="center center">
      <table class="default-table">
        <tr>
          <td>{{decimalNumber}}</td>
        </tr>
        <tr>
          <td>{{decimalNumberConverted}}</td>
        </tr>
      </table>
    </div>
  </div>
  <div fxLayoutAlign="center" class="pb-4" *ngIf="showInputs && (decimalNumberConverted || romanNumberConverted !== 0 || romanDateGerman)">
    <nxt-button (click)="print()">DRUCKEN</nxt-button>
  </div>
</div>
