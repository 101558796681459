import {Component, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from '../../nxt.component';
import {NxtTextTemplate, NxtTextTemplateDef, NxtTextTemplateType, TextTemplateTools} from '../../../common-browser/helpers/text-template.tools';
import {SocketService} from '../../../services/socket/socket.service';
import {LanguageTools} from '../../../common-browser/helpers/language.tools';
import {DisplayWithTools} from '../../../common-browser/helpers/display-with.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {PermissionDirective} from '../../../directives/permission.directive';
import {TextareaComponent} from '../../form-controls/textarea/textarea.component';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {AutocompleteComponent} from '../../form-controls/autocomplete/autocomplete.component';
import {MatIcon} from '@angular/material/icon';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf} from '@angular/common';

@Component({
    selector: 'nxt-text-template-edit',
    templateUrl: './text-template-edit.component.html',
    styleUrls: ['./text-template-edit.component.scss'],
    imports: [NgIf, FlexModule, NgFor, MatIcon, AutocompleteComponent, SlideToggleComponent, NxtButtonComponent, TextareaComponent, PermissionDirective]
})

export class TextTemplateEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  displayWith = DisplayWithTools.displayWithText;

  textTemplateDef: NxtTextTemplateDef;
  textTemplates: NxtTextTemplate[];
  type: NxtTextTemplateType;
  langs = LanguageTools.languageCodes.map(l => ({text: l.name, value: l.code}));

  constructor(
    @Optional() private dialogRef: MatDialogRef<any>,
    private socketService: SocketService,
    private dialogService: DialogService,
    private clipboard: Clipboard
  ) {
    super();
  }

  ngOnInit() {
    this.textTemplateDef = TextTemplateTools.TextTemplateDefs.find(d => d.type === this.type);
  }

  nxtOnDestroy() {

  }

  async load(type: NxtTextTemplateType) {
    this.type = type;
    this.textTemplates = await this.socketService.getTextTemplatesByType(type);
  }

  public addTextTemplate() {
    this.textTemplates.push({
      type: this.type,
      id: '',
      lang: '',
      text: ''
    });
  }

  public async save() {
    if (this.textTemplates.some(t => !t.lang)) {
      this.dialogService.showOk('Sprache fehlt');
      return;
    }
    if (this.textTemplates.some(t => !t.text)) {
      this.dialogService.showOk('Text fehlt');
      return;
    }
    await this.socketService.updateAllTextTemplateTypes(this.type, this.textTemplates);
    this.dialogRef.close();
    // this.load(this.type);
  }


  public close() {
    this.dialogRef.close();
  }

  public delete(index: number) {
    this.textTemplates.splice(index, 1);
  }

  async importFromAc() {
    const textTemplatesFromAc: NxtTextTemplate[] = await this.socketService.getTextTemplatesFromAc(this.type);
    const alreadyExist: NxtTextTemplate[] = [];
    const toAdds: NxtTextTemplate[] = [];
    for (const textTemplateFromAc of textTemplatesFromAc) {
      if (this.textTemplates.find(t => t.id === textTemplateFromAc.id && t.text)) {
        alreadyExist.push(textTemplateFromAc);
      } else {
        toAdds.push(textTemplateFromAc);
      }
    }
    if (alreadyExist.length > 0) {
      this.dialogService.showOk(alreadyExist.map(a => LanguageTools.getTextByCode(a.lang)).join(', ') + ' wird nicht überschrieben');
    }
    for (const toAdd of toAdds) {
      const exist = this.textTemplates.find(t => t.id === toAdd.id);
      if (exist) {
        exist.text = toAdd.text;
      } else {
        this.textTemplates.push(toAdd);
      }
    }
  }

  toClipboard(text: string) {
    this.clipboard.copy(text);
  }
}
