import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtKlarnaOrder} from '../../../common-interfaces/nxt.klarna-order.interface';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {SocketService} from '../../../services/socket/socket.service';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {DateRangePickerComponent} from '../../form-controls/date-range-picker/date-range-picker.component';
import {NxtShopOrderLineItem} from '../../../common-interfaces/nxt.shop-order.interface';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {KlarnaOrderRaw} from '../../../common-interfaces/klarna-raw.interface';

type KlarnaOrderWithLineItem = (NxtKlarnaOrder & { shopOrderLineItem?: NxtShopOrderLineItem } & { rawOrder?: KlarnaOrderRaw });

@Component({
    selector: 'nxt-klarna-orders',
    templateUrl: './klarna-orders.component.html',
    styleUrls: ['./klarna-orders.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NxtDatagridComponent,
        DateRangePickerComponent,
        MoneyPipe
    ]
})

export class KlarnaOrdersComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  klarnaOrders = signal<KlarnaOrderWithLineItem[]>([]);


  columnDefs = signal<NxtColDef<KlarnaOrderWithLineItem>[]>([
    {headerName: 'Datum', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDate},
    {headerName: 'Betrag', field: 'originalPaymentValue', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Name', field: 'fullName', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Artikel', field: 'shopOrderLineItem.name', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Type', field: 'rawOrder.initial_payment_method.type', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Typename', field: 'rawOrder.initial_payment_method.description', nxtFieldType: NxtFieldType.Text},
  ]);

  /*** Injections ***/
  socketService = inject(SocketService);

  date = {from: DateTools.getFirstOfMonth(Date.now()).dateFormatDate(), till: DateTools.getLastOfMonth(Date.now()).dateFormatDate()};
  totalValue = signal<number>(0);
  totalValueTattooTickets = signal<number>(0);
  totalValueDaySessions = signal<number>(0);
  totalValueGiftCards = signal<number>(0);

  constructor() {
    super();
  }

  ngOnInit() {
    this.find();
  }

  nxtOnDestroy() {
  }

  async find() {
    this.klarnaOrders.set((await this.socketService.getKlarnaOrdersOriginalFromTill(this.date.from, this.date.till)).filter(k => !k.originalOrderId));
    console.log(this.klarnaOrders());
    console.log(this.klarnaOrders());
    this.totalValue.set(this.klarnaOrders().reduce((acc, k) => acc + k.originalPaymentValue, 0));
    this.totalValueTattooTickets.set(this.klarnaOrders().filter(k => k.shopOrderLineItem.name.includes('Ticket')).reduce((acc, k) => acc + k.originalPaymentValue, 0));
    this.totalValueDaySessions.set(this.klarnaOrders().filter(k => k.shopOrderLineItem.name.includes('DAYSESSION')).reduce((acc, k) => acc + k.originalPaymentValue, 0));
    this.totalValueGiftCards.set(this.klarnaOrders().filter(k => k.shopOrderLineItem.name.includes('Gutschein')).reduce((acc, k) => acc + k.originalPaymentValue, 0));
  }
}
