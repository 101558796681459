import {Component, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {keys, ObjectTools} from '../../../common-browser/helpers/object.tools';
import {DialogService} from '../../../services/dialog.service';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {SlideToggleComponent} from '../../../components/form-controls/slide-toggle/slide-toggle.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf} from '@angular/common';
import {ArtistSkillTools} from '../../../common-browser/helpers/artist-skill.tools';
import {NxtBoolSkill, NxtSkill} from '../../../common-interfaces/nxt.artist.interface';

@Component({
  selector: 'nxt-skill-select',
  templateUrl: './skill-select.component.html',
  styleUrls: ['./skill-select.component.scss'],
  imports: [NgIf, FlexModule, NgFor, SlideToggleComponent, NxtButtonComponent],
})

export class SkillSelectComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  public availableSkills = {skills: ArtistSkillTools.skills, boolSkills: ArtistSkillTools.boolSkills};
  public currentSkills: { [key in NxtSkill]?: boolean; } = {};
  public currentBoolSkills: { [key in NxtBoolSkill]?: boolean; } = {};
  private originalSkills: { [key in NxtSkill]?: boolean; } = {};
  private originalBoolSkills: { [key in NxtBoolSkill]?: boolean; } = {};
  noChecks = false;

  constructor(
    @Optional() private dialogRef: MatDialogRef<any>,
    private socketService: SocketService,
    private dialogService: DialogService,
  ) {
    super();

  }

  async ngOnInit() {
    this.originalSkills = ObjectTools.clone(this.currentSkills);
    this.originalBoolSkills = ObjectTools.clone(this.currentBoolSkills);
  }

  nxtOnDestroy() {
  }

  public valueChanged() {

  }

  public async save() {
    this.clearValues();

    const skillsArray = keys(this.currentSkills);
    const boolSkillsArray = keys(this.currentBoolSkills);

    if (!this.noChecks) {
      if (this.noChecks && skillsArray.length === 0 && boolSkillsArray.length > 0) {
        this.dialogService.showOk('"' + boolSkillsArray.join('" & "') + '" reicht nicht aus');
        return;
      }

      if (this.currentSkills['kleine Tattoos'] && keys(this.currentSkills).length === 1) {
        this.dialogService.showOk('Alleine nur Kleines Tattoo reicht nicht aus');
        return;
      }

      if (!this.originalSkills.Portrait && this.currentSkills.Portrait && !this.currentBoolSkills['Portrait 1zu1']) {
        if (await this.dialogService.showYesNo('Ist es ein 1zu1 Portrait (mit Wiedererkennungswert)?\n\nJa: Berühmte Person, Familienmitglied, etc\n\nNein: Einfach nur eine hübsche Frau, etc.', {})) {
          this.currentBoolSkills['Portrait 1zu1'] = true;
        }
      }
      if (!this.originalSkills.Tiere && this.currentSkills.Tiere && !this.currentBoolSkills['Tier-Portrait 1zu1']) {
        if (await this.dialogService.showYesNo('Ist es ein 1zu1 Tier-Portrait (mit Wiedererkennungswert)?\n\nJa: Eigener Hund, eigene Katze, etc\n\nNein: Einfach nur irgendein Hund, etc.', {})) {
          this.currentBoolSkills['Tier-Portrait 1zu1'] = true;
        }
      }
      if (this.currentBoolSkills['Portrait 1zu1']) {
        this.currentSkills.Portrait = true;
      }
    }
    this.dialogRef.close({skills: this.currentSkills, boolSkills: this.currentBoolSkills});
  }

  public cancel() {
    this.dialogRef.close();
  }

  private clearValues() {
    for (const key of Object.keys(this.currentSkills)) {
      if (!this.currentSkills[key]) {
        delete this.currentSkills[key];
      }
    }

    for (const key of Object.keys(this.currentBoolSkills)) {
      if (!this.currentBoolSkills[key]) {
        delete this.currentBoolSkills[key];
      }
    }
  }
}
