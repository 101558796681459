<div>
  <nxt-select
    placeholder="Bezugsquelle"
    [options]="sourceOfSupplies"
    [(value)]="package.sourceOfSupply">
  </nxt-select>
  <nxt-textarea
    placeholder="Beschreibung"
    [(value)]="package.name"
  ></nxt-textarea>
  <nxt-input placeholder="Menge pro Packung" [isNumber]="true" [(value)]="package.amount"></nxt-input>
  <nxt-input placeholder="Preis netto Pro Packung" [isMoney]="true" [(value)]="package.packagePriceNet"></nxt-input>
  <div class="flex flex-row w-full justify-around mt-3">
    <nxt-button (click)="closeClicked()">Abbrechen</nxt-button>
    <nxt-button *ngIf="package.id" (click)="deleteClicked()">Löschen</nxt-button>
    <nxt-button (click)="saveClicked()">Speichern</nxt-button>
  </div>
</div>
