import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {HttpClient} from '@angular/common/http';


@Directive({
  selector: '[nxtTranslate]',
  standalone: true
})
export class TranslateDirective implements OnInit {
  @Input('nxtTranslate') lang: string;

  constructor(
    private element: ElementRef,
    private httpClient: HttpClient
  ) {
  }

  async ngOnInit() {
    if (this.lang && this.lang !== 'de') {
      const text = this.element.nativeElement.innerHTML;
      const newText: any = await firstValueFrom(this.httpClient.post('https://ac.nxt-lvl.ink/translate', {to: this.lang, text, from: 'de'}));
      // const newText = await this.socketService.translate(text, 'en');
      if (!newText) {
        return;
      }
      if (newText.text) {
        this.element.nativeElement.innerHTML = newText.text;
      }
    }
  }
}
