<div class="nxt-page nxt-text" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-2">
    <div fxLayout="row" fxLayoutGap="50px">
      <div class="page-header">Super Admin {{ version }}</div>
    </div>
    <nxt-button-icon *ngIf="dialogRef" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>
  <div fxFlex class="h-100" style="overflow: hidden">
    <mat-tab-group class="h-100" dynamicHeight="" [selectedIndex]="selectedTab"
                   (selectedTabChange)="selectedTabChanged($event)">
      <mat-tab class="h-100" label="User Verbindungen" fxLayout="column">
        <nxt-datagrid
          name="Admin - User Verbindungen"
          class="h-100"
          fxFlex
          uniqueRowDataKey="socketId"
          [columnDefs]="columnDefsConnections"
          [rowData]="clientConnections"
          [autoSizeColumnOnResize]="true"
        >
        </nxt-datagrid>
      </mat-tab>
      <mat-tab class="h-100" label="Geräte" fxLayout="column">
        <nxt-datagrid
          name="Admin - Geräte"
          #devicesDatagridComponent
          class="h-100"
          fxFlex
          [uniqueRowDataKey]="'data.machineId'"
          [columnDefs]="columnDefsConnectionsServer"
          [rowData]="serverClientConnections"
          [autoSizeColumnOnResize]="true"
        >
        </nxt-datagrid>
      </mat-tab>
      <mat-tab class="h-100" label="Aktionen" fxLayout="column">
        <div class="flex h-full flex-col gap-10 overflow-auto p-4">


          <nxt-box class="w-fit" bg="dark" headerText="Abrechnung">
            <div fxLayout="column" fxLayoutAlign="center center" class="pt-3">
              <div class="p-2">Abrechnung zurück setzen</div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <nxt-date-picker [(nxtModel)]="resetDayFinishString"></nxt-date-picker>
                <div class="pl-4">
                  <nxt-button (click)="resetInvoicesDate2()">zurück setzen</nxt-button>
                </div>
              </div>
            </div>
          </nxt-box>
          <nxt-box class="w-fit" bg="dark" headerText="Bank">
            <div fxLayout="column" fxLayoutAlign="center center" class="pt-3">
              <div class="p-2">Bank-Zahlung importieren</div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <nxt-input class="w-[250px]" placeholder="Verwendungszweck enthält" [(value)]="additionalBankTransactionNoteIncluded"/>
                <div class="pl-4">
                  <nxt-button (click)="readBankTransaction()">Einlesen</nxt-button>
                </div>
              </div>
            </div>
          </nxt-box>


          <nxt-box class="w-fit" bg="dark" headerText="Server">
            <nxt-button (click)="restartServer()">Server neu starten</nxt-button>
          </nxt-box>


          <nxt-box class="flex w-fit flex-col gap-3" bg="dark" headerText="WhatsApp">
            <nxt-button (click)="showWhatsAppScreenShotClicked()">WhatsApp anzeigen</nxt-button>
            <nxt-button (click)="restartWhatsAppClicked()">WhatsApp neustarten</nxt-button>
            <nxt-button (click)="whatsAppDeleteClicked()">WhatsApp neu verbinden (Qr-Code scannen)</nxt-button>
          </nxt-box>

          <nxt-box class="flex w-fit flex-col gap-3" bg="dark" headerText="iCloud">
            <nxt-button (click)="showIcloudStateClicked()">iCloud status anzeigen</nxt-button>
          </nxt-box>


          <nxt-box class="flex w-fit flex-col gap-3" bg="dark" headerText="Inventar import">
            <nxt-select [(value)]="importInventoryFromStudio" [options]="importInventoryStudios"></nxt-select>
            <nxt-button [disabled]="!importInventoryFromStudio()" (click)="importInventoryClicked()">Import starten</nxt-button>
          </nxt-box>

          <nxt-box class="flex w-fit flex-col" bg="dark" headerText="Foto-Handy">
            <nxt-button (click)="setupPhotoIphoneClicked()">Foto Handy einrichten</nxt-button>
            @if (lastPhotoDevice()) {
              <div class="pt-2 text-sm">letzter Scan: {{ lastPhotoDevice().device }} ({{ lastPhotoDevice().createdAt | nxtDate: 'dd.MM.yyyy HH:mm' }})</div>
            }
          </nxt-box>

          <!--<div fxLayout="column" class="pt-3">
            <div class="pt-2">WhatsApp</div>
            <div fxLayout="row">
              <nxt-button (click)="fakeWhatsAppStatus()">fake WhatsApp Status</nxt-button>
            </div>
          </div>-->


          <div fxLayout="column" class="pt-3">
            <div class="pt-2">Invalidate</div>
            <div fxLayout="row">
              <nxt-button (click)="invalidate('cachedCalendarEvents')">CalendarEvents</nxt-button>
              <nxt-button (click)="invalidate('cachedNotAssignedPaypalTransactions')">
                NotAssignedPaypalTransactions
              </nxt-button>
            </div>
          </div>
          <nxt-box bg="dark" class="w-fit" headerText="Downloads">
            <div class="flex flex-col">
              <div fxLayout="row">
                <nxt-select style="width: 200px" [options]="declarationOfConsentOptions"
                            [(value)]="declarationOfConsentPage"></nxt-select>
                <nxt-button (click)="downloadDeclarationOfConsentTattoo()">Einwilligungserklärung-Tattoo download</nxt-button>
              </div>
              <div fxLayout="row">
                <nxt-select style="width: 200px" [options]="declarationOfConsentPiercingOptions"
                            [(value)]="declarationOfConsentPiercingPage"></nxt-select>
                <nxt-slide-toggle [(value)]="declarationOfConsentPiercingEmployed">Angestellt</nxt-slide-toggle>
                <nxt-button (click)="downloadDeclarationOfConsentPiercing()">Einwilligungserklärung-Piercing download</nxt-button>
              </div>


              <div fxLayout="row">
                <nxt-select style="width: 200px" [options]="afterCareInstructionPiercingOptions"
                            [(value)]="afterCareInstructionPiercingPage"></nxt-select>
                <nxt-button (click)="downloadAfterCareInstructionPiercing()">Pflegehinweise-Piercing download</nxt-button>
              </div>
              <div fxLayout="row">
                <nxt-select style="width: 200px" [options]="afterCareInstructionTattooOptions"
                            [(value)]="afterCareInstructionTattooPage"></nxt-select>
                <nxt-button (click)="downloadAfterCareInstructionTattoo()">Pflegehinweise-Tattoo download</nxt-button>
              </div>
            </div>
          </nxt-box>

          <nxt-box bg="dark" class="w-fit" headerText="Downloads">
            <div class="flex flex-col">
              <div fxLayout="row">
                <nxt-button (click)="deleteCachedAftercareClicked()">AFTERCARE DOKUMENTEN-CACHE LEEREN</nxt-button>
              </div>
            </div>
          </nxt-box>
        </div>
      </mat-tab>
      <mat-tab class="h-100" label="Einstellungen" fxLayout="column" style="overflow: scroll">
        <nxt-settings class="w-full h-100" style="border: 0px solid yellow; overflow-y: auto"></nxt-settings>
      </mat-tab>
      <mat-tab class="h-100" label="Lokale Einstellungen" fxLayout="column" style="overflow: scroll">
        <div class="mt-3 flex flex-col items-center justify-center gap-3">
          <nxt-select
            class="w-[300px]"
            [multiple]="true"
            [options]="studioOptions"
            [(value)]="studioLogins"
            (valueChange)="studioLoginChanged()"
            placeholder="Anmeldung Kasse">
          </nxt-select>
          <nxt-select
            class="w-[300px]"
            [multiple]="true"
            [options]="workplaceOptions"
            [(value)]="workplaceLogins"
            (valueChange)="workplaceLoginChanged()"
            placeholder="Anmeldung Arbeitsplatz">
          </nxt-select>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
