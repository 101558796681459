import {DecimalTools} from './decimal.tools';
import {clone} from './object.tools';
import {ArrayTools} from './array.tools';
import {NxtKlarnaOrder} from '../../common-interfaces/nxt.klarna-order.interface';
import {DateTools} from './date.tools';
import {DayOfWeek} from '../../common-interfaces/date.interface';

export class KlarnaTools {


  static getNewKlarnaOrderText(klarnaOrder: NxtKlarnaOrder): string {
    let text = 'KLARNA';
    text += '\n' + DateTools.format(klarnaOrder.createdAt, 'dd.MM.yyyy HH:mm');
    text += '\n' + DecimalTools.toMoneyString(klarnaOrder.value);
    text += '\n' + klarnaOrder.fullName;
    return text;
  }

  public static getPayoutDayByCaptureDate(captureDate: number) {
    let payOutDay = DateTools.getNextDayOfWeek(captureDate, DayOfWeek.Thursday);
    payOutDay = DateTools.addDays(payOutDay, 7);
    DateTools.clearTime(payOutDay);
    return new Date(payOutDay);
  }


  public static sortBySplitDate(klarnaOrders: NxtKlarnaOrder[]) {
    let klarnaOrdersClone = clone(klarnaOrders);
    const results: NxtKlarnaOrder[][] = [];
    const firstParents = klarnaOrders.filter(k => !k.originalOrderId);
    for (const firstParent of firstParents) {
      results.push([firstParent]);
    }
    for (const result of results) {
      const firstKlarnaOrder = result[0];
      klarnaOrdersClone = klarnaOrdersClone.filter(k => k.orderId !== firstKlarnaOrder.orderId);
      let counter = 0;
      while (true) {
        counter++;
        if (counter > 100) {
          throw Error('sortBySplitDate fehler, counter > 100');
        }
        const parentId = result[result.length - 1].orderId;
        const child = klarnaOrdersClone.find(k => k.originalOrderId === parentId);
        if (child) {
          klarnaOrdersClone = klarnaOrdersClone.filter(k => k.orderId !== child.orderId);
          result.push(child);
        } else {
          break;
        }
      }
    }
    const allResultIds = results.map(r => r.map(k => k.orderId)).flat();
    if (!ArrayTools.checkSame(allResultIds, klarnaOrders.map(k => k.orderId))) {
      debugger;
      throw Error('sortBySplitDate fehler, result und klarnaOrders nicht gleich');
    }
    return results;
  }

  static getDisputeText(key: 'reason' | 'status' | 'investigation_status' | 'closing_reason' | 'closing_reason_detailed', value: string) {
    if (!value) {
      return '';
    }
    const texts = {
      reason: {
        return: 'Retoure',
        unauthorized_purchase: 'Nicht autorisierte Käufe',
        goods_not_received: 'Artikel nicht erhalten',
        faulty_goods: 'Fehlerhafte Ware',
      },
      status: {
        closed: 'Geschlossen',
        open: 'Offen',
      },
      investigation_status: {
        closed: 'Geschlossen',
      },
      closing_reason: {
        resolved: 'Gelöst',   // Händler hat Bestellung rückerstattet
        chargeback: 'Zurückgebucht', // Händler hat Verlust akzeptiert
      },
      closing_reason_detailed: {
        merchant_accepted_the_loss: 'Verlust akzeptiert',
        customer_cancelled_dispute: 'Kunde hat Beanstandung zurückgezogen',
        merchant_did_not_reply_to_dispute_request: 'Wir haben nicht geantwortet 🤦‍♂️',
        merchant_provided_valid_shipping_details: 'Wir haben ein gültigen Nachweis erbracht',
      },
    };
    if (texts[key][value]) {
      return texts[key][value];
    }
    debugger;
    return value;
  }
}
