import {inject, Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {MathTools} from '../common-browser/helpers/math.tools';
import {LoginService} from './login.service';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  /*** Injections ***/
  private socketService = inject(SocketService);
  private loginService = inject(LoginService);
  private configService = inject(ConfigService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  playJuergen() {
    const audio = new Audio('assets/sounds/juergen.ogg');
    audio.play();
  }

  playBase64(base64: string, mimeType: string) {
    const base64Opus = 'data:' + mimeType + ';base64,' + base64;
    const audio = new Audio(base64Opus);
    audio.play();
  }

  async playReminderNotification() {
    const audio = await this.socketService.tts(this.getReminderNotificationText());
    this.playBase64(audio.base64, audio.mimeType);
  }

  getReminderNotificationText() {
    let start = '';
    let from = '';
    const username = '"' + this.loginService.getUsername() + '"';
    if (window.location.pathname.startsWith('/studio-cash-report')) {
      from += '"' + this.configService.config.value.studioName + ' CashReport"';
    } else if (window.location.pathname.startsWith('/main')) {
      from += '"' + this.configService.config.value.studioName + ' Dashboard"';
    } else {
      from += '"' + window.location.pathname + '"';
    }
    const hellos = [
      from + ' an ' + username + ', bitte kommen.',
      'Hallo ' + this.loginService.getUsername() + ' ' + from + ' meldet sich.',
      'Huhu ' + username + ', ' + from + ' möchte deine Aufmerksamkeit.',
      'Hey ' + username + ', ' + from + ' muss dir auf die Eier gehen.',
    ];
    start += MathTools.getRandomFromArray(hellos);

    start += ' ';
    const texts = [
      start + 'Du hast noch offene Aufgaben. Zack zack erledigen! Aber verliere die Wertigkeit nicht aus den Augen!',
      start + 'Check mal deine Aufgaben! ame nack. over and out!',
      start + 'behalte deine Aufgaben im Blick, check sie ab, erledige Sie, dann bist du krass!',
      start + 'sei kein Frosch, erledige deine Aufgaben! sobald sie erledigt sind, lasse ich dich auch in Ruhe, HA HA HA',
      start + 'Guck mal laan, du hast aufgaben, mach sie auf erledigt, dann ist haiat sii keerrem.',
    ];

    if (this.loginService.getUsername() === 'Marcel') {
      texts.push(start + ' du hast noch offene Aufgaben. Wir sind hier nicht beim Bund, hier wird gearbeitet. HAHA HAHA HAHA');
    }
    return MathTools.getRandomFromArray(texts);
  }
}
