import {Component, Input, OnInit, Optional} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../services/dialog.service';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {CheckboxComponent} from '../form-controls/checkbox/checkbox.component';
import {NgFor, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';

@Component({
    selector: 'nxt-artist-photo-unknown-uploads',
    templateUrl: './artist-photo-unknown-uploads.component.html',
    styleUrls: ['./artist-photo-unknown-uploads.component.scss'],
  imports: [FlexModule, NgIf, NgFor, CheckboxComponent, NxtButtonComponent, NxtDatePipe]
})

export class ArtistPhotoUnknownUploadsComponent implements OnInit {

  @Input() eventId: string;
  @Input() artist: string;
  public photos: {
    name: string;
    id: string,
    imgSrc: string;
    thumbnail: string;
    mimeType: string;
    appleDeviceName: string;
    photoCreatedAt: string;
    isImage: boolean;
  }[];
  public photosSelected = {};
  photosSelectedCount = 0;

  constructor(
    private socketService: SocketService,
    @Optional() public dialogRef: MatDialogRef<any>,
    private dialogService: DialogService
  ) {
  }

  async ngOnInit() {
    this.load();
  }

  private async load() {
    this.photos = await this.socketService.getPhotoSyncUnknownUploadsNotFixed();
    this.photos.forEach(p => p.imgSrc = 'data:image/jpg;base64,' + p.thumbnail);
  }

  public async save() {
    const ids: string[] = [];
    for (const key of Object.keys(this.photosSelected)) {
      if (this.photosSelected[key]) {
        ids.push(key);
      }
    }
    if (ids.length === 0) {
      this.dialogService.showOk('Du hast keine Bilder ausgewählt');
    } else {
      if (await this.dialogService.showYesNo('Möchtest du ' + ids.length + ' Foto(s) verknüpfen?')) {
        await this.socketService.updatePhotoSyncReimportUnknown(ids, this.eventId, this.artist);
        this.dialogRef.close();
        this.dialogService.showOk('Der Verknüpfen kann einen Augenblick dauern...');
      }
    }
  }

  public triggerPhoto(index: string, forceValue?: boolean) {
    if (typeof forceValue === 'boolean') {
      this.photosSelected[index] = forceValue;
    } else {
      this.photosSelected[index] = !this.photosSelected[index];
    }

    for (const key of Object.keys(this.photosSelected)) {
      if (!this.photosSelected[key]) {
        delete this.photosSelected[key];
      }
    }
    this.photosSelectedCount = Object.keys(this.photosSelected).length;
  }

  public async delete() {
    const ids: string[] = [];
    for (const key of Object.keys(this.photosSelected)) {
      if (this.photosSelected[key]) {
        ids.push(key);
      }
    }
    if (await this.dialogService.showYesNo('Möchtest du ' + ids.length + ' Foto(s) wirklich löschen?')) {
      await this.socketService.updatePhotoSyncReimportUnknownDelete(ids);
      this.load();
    }
  }
}
