<div class="flex flex-col nxt-page">

  <div class="flex flex-row justify-between">
    <div>Termine auswerten</div>
    <nxt-button-icon (click)="closeClicked()">close</nxt-button-icon>
  </div>
  <div class="grid grid-cols-4 gap-4">
    <div *ngFor="let filter of filters" class="w-full">
      @switch (filter.controlType) {
        @case ('select') {
          <nxt-select
            [showClearIcon]="true"
            [showListAfterClear]="false"
            [placeholder]="filter.name"
            [options]="filter.options"
            [(value)]="filter.value">
          </nxt-select>
        }
        @case ('search-select') {
          <nxt-autocomplete
            [showClearIcon]="true"
            [showListAfterClear]="false"
            [placeholder]="filter.name"
            [options]="filter.options"
            valueField="value"
            [(value)]="filter.value"
            [filterFields]="['text']"
            [maxOptionsToShow]="999">
          </nxt-autocomplete>
        }
        @case ('date-range') {
          <nxt-date-range-picker
            [showClearIcon]="true"
            [(value)]="filter.value"
            [placeholder]="filter.name"
          >
          </nxt-date-range-picker>
        }
      }
    </div>
  </div>
  <div class="flex flex-row justify-around pb-3">
    <nxt-button (click)="load()">Anzeigen</nxt-button>
    <nxt-button *ngIf="events.length > 0" (click)="showInfos()">Infos</nxt-button>
  </div>

  <nxt-datagrid
    class="flex grow-1 h-full w-fill"
    [columnDefs]="columnDef"
    [rowData]="events"
    [showFooter]="true"
  >
  </nxt-datagrid>

  <div>

  </div>

</div>
