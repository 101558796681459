import {EventEmitter, inject, Injectable} from '@angular/core';
import {SocketService} from '../socket/socket.service';
import {NxtContactForWa} from '../../common-interfaces/nxt.contact.interface';
import {ContactTools} from '../../common-browser/helpers/contact.tools';

@Injectable({
  providedIn: 'root'
})
export class WaContactCacheService {

  private contactsByMobile: { [mobile: string]: NxtContactForWa } = {};
  private contactsByWaIndex: { [waIndex: string]: NxtContactForWa } = {}; // 'givenName', 'familyName', 'suffix'

  /*** Injections ***/
  private socketService = inject(SocketService);

  private started = false;
  contactsChanged = new EventEmitter<void>();

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  public async start() {
    if (this.started) {
      return;
    }
    this.started = true;
    this.loadFull();

    this.socketService.onAuthenticated.subscribe(() => {
      this.loadFull();
    });

    this.socketService.subscribeNew('eventContactChanged', data => {
      if (data.op === 'delete' || data.op === 'update') {
        const waIndex = ContactTools.getWaIndex(data.oldRecord);
        if (data.oldRecord.mobileFormatted) {
          delete this.contactsByMobile[data.oldRecord.mobileFormatted];
        }
        if (waIndex) {
          delete this.contactsByWaIndex[waIndex];
        }
      }
      if (data.op === 'insert' || data.op === 'update') {
        const waIndex = ContactTools.getWaIndex(data.record);
        if (data.record.mobileFormatted) {
          this.contactsByMobile[data.record.mobileFormatted] = ContactTools.contactToContactForWa(data.record);
        }
        if (waIndex) {
          this.contactsByWaIndex[waIndex] = ContactTools.contactToContactForWa(data.record);
        }
      }
      this.contactsChanged.emit();
    });
  }

  public getByWaIndex(index: string) {
    let contact = this.contactsByMobile[index];
    if (!contact) {
      contact = this.contactsByWaIndex[index];
    }
    return contact;
  }

  private async loadFull() {
    const contacts = await this.socketService.getWaContactCache();
    for (const contact of contacts) {
      this.contactsByMobile[contact.mobile] = contact;
      this.contactsByWaIndex[contact.waIndex] = contact;
    }
    this.contactsChanged.emit();
  }
}
