import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class DownloadService {
  static instance: DownloadService;

  constructor() {
    DownloadService.instance = this;
  }

  async fetchImageAsArrayBuffer(url: string): Promise<ArrayBuffer> {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Fehler beim Laden des Bildes: ${response.statusText}`);
    }
    return await response.arrayBuffer();
  }

  public downloadBase64(filename: string, base64String: string) {
    let prefix = '';
    if (base64String.indexOf('data:') === -1) {
      if (filename.toLowerCase().endsWith('.pdf')) {
        prefix = 'data:application/pdf;base64,';
      } else if (filename.toLowerCase().endsWith('.png')) {

      } else if (filename.toLowerCase().endsWith('.zip')) {
        prefix = 'data:application/zip;base64,';
      }
    }
    const source = prefix + base64String;
    const downloadLink = document.createElement('a');
    downloadLink.href = source;
    downloadLink.download = filename;
    downloadLink.click();
  }

  async downloadUrl(url: string, filename: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }
}
