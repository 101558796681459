import {AfterContentInit, Component, computed, EventEmitter, input, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControlWrapper} from '../form-control-wrapper';
import {Subscription} from 'rxjs';
import {DecimalTools} from '../../../common-browser/helpers/decimal.tools';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {TypeTools} from '../../../common-browser/helpers/type.tools';
import {UuidTools} from '../../../common-browser/helpers/uuid.tools';
import {DialogService} from '../../../services/dialog.service';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {FormsModule, ReactiveFormsModule, ValidatorFn} from '@angular/forms';
import {DateControl} from '../../../controls/date-control';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon} from '@angular/material/icon';
import {MatHint, MatInput} from '@angular/material/input';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgIf, NgStyle} from '@angular/common';
import {FormFieldWrapperComponent} from '../../form-field-wrapper/form-field-wrapper.component';
import {PermissionService} from '../../../services/permission.service';

declare const google: any;

@Component({
  selector: 'nxt-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  imports: [FormFieldWrapperComponent, ExtendedModule, MatFormField, NgStyle, MatLabel, MatInput, FormsModule, ReactiveFormsModule, NgIf, MatError, MatIcon, MatTooltip, MatHint],
  standalone: true,
})
export class InputComponent extends FormControlWrapper implements OnInit, AfterContentInit, OnDestroy, OnChanges {

  @Input() set value(value: number | string | null) {
    const currentValue = this.nxtFormControl.value;
    if (value !== currentValue) {
      this.valueChanged(value, '@Input() set value');
      this.setFormControlValue(value, '@Input() set value', false, true);
      if (this.hasFocus) {
        this.removeDisplaySuffix('set value');
      }
    }
  }


  constructor(
    private dialogService: DialogService,
    private permissionService: PermissionService,
  ) {

    super();
  }

  public hasFocus = false;
  private valueChangeSubscription: Subscription;
  public _value: any;

  @Input() disabled = false;
  @Input() icon: { name: string, tooltip: string, click: () => void };
  @Input() color?: string;

  @Input() type = 'text';
  @Input() isDate = false;
  @Input() isTimePicker = false;
  public showCapsLockInfo = false;
  @Output() onFocus = new EventEmitter();
  @Output() inputClick = new EventEmitter<void>();
  private destroyed = false;
  private hasSuffix = false;

  // @Input() value: any;


  @Input() readonly: boolean;
  @Input() cellRenderer?: (value: any) => string | Promise<string>;

  // @Input() displaySuffix?: string;
  @Input() isMoney = false;
  currency = input<'EUR' | 'USD'>('EUR');
  currencySymbol = computed(() => {
    switch (this.currency()) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
    }
  });
  @Input() selectOnInit = false;
  @Input() selectOnFocus = false;
  @Input() isPercent = false;
  @Input() disableFocusOnInit = false;


  @Input() isNumber = false;
  @Input() center = false;
  @Input() isNumberMin: number;
  @Input() placeholder: string;

  @Input() validators: ValidatorFn | ValidatorFn[];
  @Output() valueChange = new EventEmitter<any>();
  @Output() enter = new EventEmitter<void>();
  @Output() up = new EventEmitter<void>();
  @Output() down = new EventEmitter<void>();
  @Output() esc = new EventEmitter<void>();
  @Output() onGoogleClick = new EventEmitter<{
    street: string,
    streetNumber: string,
    postalCode: string,
    city: string,
    country: string,
    countryCode: string,
    text: string,
  }>();
  @Input() google = false;
  @Input() clearGoogleAfterClick = false;
  @Input() clearIcon = false;
  @Input() bottomNoPadding = false;
  @Input() noPadding = false;

  controlName = UuidTools.generate();


  // lastCharDecimalSeparator = false;
  showClearIcon = false;

  addDisplaySuffixRunning = false;


  ngOnInit() {
    if (this.isNumber || this.isMoney || this.isPercent) {
      this.nxtFormControl.isNumber = true;
      // this.type = 'number';
    }
    if (this.selectOnInit) {
      this.select();
    }

    if (this.isDate) {
      this.log('input isDate = true');
      const d = new DateControl(this.nxtFormControl);
    }

    if (!this.placeholder) {
      this.placeholder = this.nxtFormControl.name;
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.setStyle();

    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.nxtFormControl.disable();
      } else {
        this.nxtFormControl.enable();
      }
    }

    if (changes.validators) {
      this.nxtFormControl.setValidators(changes.validators.currentValue);
      this.nxtFormControl.updateValueAndValidity({emitEvent: false});
    }

    if (changes.isMoney) {
      if (!changes.isMoney.currentValue) {
        if (this.nxtFormControl.hasValidator(ValidatorTools.money)) {
          this.nxtFormControl.removeValidators(ValidatorTools.money);
        }
      } else {
        if (!this.nxtFormControl.hasValidator(ValidatorTools.money)) {
          this.nxtFormControl.addValidators(ValidatorTools.money);
        }
      }
    }


  }

  ngAfterContentInit(): void {
    requestAnimationFrame(() => {
      /*if (typeof this.value !== 'undefined') {
        this.setFormControlValue(this.value.toString(), 'ngAfterContentInit');
      }*/
      this.nxtFormControl.element = this.controlElemRef.nativeElement;
      this.setStyle();
      this.valueChangeSubscription = this.nxtFormControl.valueChanges.subscribe((value) => {
        // console.log(Date.now() + ' ' + this.placeholder + ' ' + value);
        // this.validateLive();
        this.valueChanged(this.nxtFormControl.value, 'nxtFormControl.valueChanges');
      });
      this.focusout('ngAfterContentInit requestAnimationFrame');

      if (this.google) {
        this.googleAutocomplete();
      }
    });
    this.focusout('ngAfterContentInit', true);
  }

  focusin(from: string, $event) {
    this.log('focusin from ' + from);
    if (!this.hasFocus && !this.readonly) {
      this.hasFocus = true;
      this.removeDisplaySuffix('focusin');
      this.callCellRenderer();
    }
    this.onFocus.emit();
    setTimeout(() => {
      if (this.selectOnFocus) {
        this.controlElemRef?.nativeElement?.select();
      }
    }, 100);
  }

  focusAndSelect() {
    this.controlElemRef?.nativeElement?.focus();
    this.controlElemRef?.nativeElement?.click();
    this.controlElemRef?.nativeElement?.select();
  }

  async focusout(from: string, $event?) {
    this.log('focusout from ' + from);
    if (!$event && !this.hasFocus || $event) {
      this.hasFocus = false;
      this.validateOnChange();
      this.addDisplaySuffix(true, 'focusout ' + from);
      this.callCellRenderer();
    }
  }

  getStyle() {
    if (this.nxtFormControl.untouched) {
      return {backgroundColor: 'blue'};
    }
  }

  ngOnDestroy(): void {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
    this.destroyed = true;
    console.log('destroyed ' + this.placeholder);
  }

  private async removeDisplaySuffix(from: string) {
    this.log('removeDisplaySuffix ' + from);
    this.validateLive('removeDisplaySuffix');
    if (this.isMoney) {
      if (this.controlElemRef.nativeElement.value.indexOf(',') > -1 && this.controlElemRef.nativeElement.value.indexOf('.') > -1) {
        await this.setNativeElementValue(this.controlElemRef.nativeElement.value.replace('.', ''), 'removeDisplaySuffix-1');
      }
      let valueToSet = this.controlElemRef.nativeElement.value.replace(',00 ' + this.currencySymbol(), '').replace('0 ' + this.currencySymbol(), '').replace(' ' + this.currencySymbol(), '');
      if (this.hasFocus) {
        valueToSet = valueToSet.replace('.', ',');
      }
      if (this.controlElemRef.nativeElement.value !== valueToSet) {
        await this.setNativeElementValue(valueToSet, 'removeDisplaySuffix-1');
      }
    } else {
      if (this.isPercent) {
        this.log('set setNativeElementValue replace "%" from removeDisplaySuffix');
        this.controlElemRef.nativeElement.value = this.controlElemRef.nativeElement.value.replace(' %', '');
      }
      if (this.isNumber) {
        this.log('set setNativeElementValue replace "%" from removeDisplaySuffix');
        this.controlElemRef.nativeElement.value = this.controlElemRef.nativeElement.value.replace('.', ',');
      }
    }
  }

  private async removeDisplaySuffixDirect(from: string) {
    if (this.isMoney) {
      if (this.controlElemRef.nativeElement.value.indexOf(',') > -1 && this.controlElemRef.nativeElement.value.indexOf('.') > -1) {
        await this.setNativeElementValue(this.controlElemRef.nativeElement.value.replace('.', ''), 'removeDisplaySuffixDirect');
      }
      const valueToSet = this.controlElemRef.nativeElement.value.replace(',00 ' + this.currencySymbol(), '').replace('0 ' + this.currencySymbol(), '').replace(' ' + this.currencySymbol(), '');
      if (this.controlElemRef.nativeElement.value !== valueToSet) {
        this.log('Debug-1 set setNativeElementValue ' + valueToSet + ' from removeDisplaySuffixDirect');
        this.controlElemRef.nativeElement.value = valueToSet; // await this.setNativeElementValue(valueToSet);
      }
    } else {
      if (this.isPercent) {
        this.log('Debug-1 set setNativeElementValue remove "%" from removeDisplaySuffixDirect');
        this.controlElemRef.nativeElement.value = this.controlElemRef.nativeElement.value.replace(' %', '');
      }
    }
    this.hasSuffix = false;
  }

  private async addDisplaySuffix(validateLive: boolean, from: string) {
    if (this.addDisplaySuffixRunning) {
      return;
    }
    this.addDisplaySuffixRunning = true;
    this.log('addDisplaySuffix ' + from);
    await this.removeDisplaySuffixDirect('addDisplaySuffix ' + from);
    if (validateLive) {
      this.validateLive('addDisplaySuffix');
    }
    if (this.isMoney && this.controlElemRef.nativeElement.value.length > 0) {
      await this.setNativeElementValue(DecimalTools.toMoneyString(this.controlElemRef.nativeElement.value, this.currencySymbol()), 'addDisplaySuffix');
    } else {
      if (this.isPercent && this.controlElemRef.nativeElement.value.length > 0) {
        await this.setNativeElementValue(this.controlElemRef.nativeElement.value + ' %', 'addDisplaySuffix');
      }
    }
    if (this.isNumber && this.controlElemRef.nativeElement.value) {
      this.controlElemRef.nativeElement.value = this.controlElemRef.nativeElement.value.replace('.', ',');
    }
    if (this.controlElemRef.nativeElement.value.includes('?')) {
      // this.nxtFormControl.invalid;
    }
    this.hasSuffix = true;
    this.addDisplaySuffixRunning = false;
  }

  private setNativeElementValue(value, from): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // const inputElem = (this.controlElemRef.nativeElement as HTMLInputElement);
      let disableAfterSet = false;
      if (this.nxtFormControl.disabled) {
        disableAfterSet = true;
        this.nxtFormControl.enable({emitEvent: false});
      }
      // requestAnimationFrame(() => {
      this.log('set setNativeElementValue: ' + value + ' from: ' + from);
      this.controlElemRef.nativeElement.value = value;
      if (disableAfterSet) {
        this.nxtFormControl.disable({emitEvent: false});
      }
      resolve();
      // });
    });
  }

  async callCellRenderer() {
    if (this.cellRenderer) {
      const valueToSet = await this.cellRenderer(this.nxtFormControl.value);
      this.log('setNativeElementValue ' + valueToSet + ' from callCellRenderer');
      this.controlElemRef.nativeElement.value = valueToSet;
    }
    if (!this.hasFocus && !this.hasSuffix) {
      this.addDisplaySuffix(false, 'callCellRenderer');
    }
  }

  private googleAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.controlElemRef.nativeElement,
      {
        // componentRestrictions: {country: 'DE'},
        types: ['address'],  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      const streetNumber = place.address_components.find(a => a.types.includes('street_number'))?.long_name ?? '';
      const street = place.address_components.find(a => a.types.includes('route'))?.long_name ?? '';
      const postalCode = place.address_components.find(a => a.types.includes('postal_code'))?.long_name ?? '';
      const country = place.address_components.find(a => a.types.includes('country'))?.long_name ?? '';
      const countryCode = place.address_components.find(a => a.types.includes('country'))?.short_name ?? '';
      // political & locality weil sonst "Mitte" kommt bei "Jakobstr 107 aachen"
      let city = place.address_components.find(a => a.types.includes('political') && a.types.includes('locality'))?.long_name ?? '';
      if (!city) {
        city = place.address_components.find(a => a.types.includes('locality'))?.long_name ?? '';
      }
      this.onGoogleClick.emit({street, streetNumber, postalCode, city, country, countryCode, text: place.formatted_address});
      if (this.clearGoogleAfterClick) {
        this.controlElemRef.nativeElement.value = '';
      }
    });
  }

  invokeEvent(place: any) {
    // this.setAddress.emit(place);
  }

  private validateLive(from: string) {
    let valueChangeEmitted = false;
    if (this.isPercent || this.isNumber || this.isMoney) {
      this.log('validateLive from: ' + from);
      if (typeof this.nxtFormControl.value === 'string' && this.nxtFormControl.value === '') {
        return;
      }

      const hasCharDecimalSeparator = typeof this.nxtFormControl.value === 'string' && (this.nxtFormControl.value.indexOf('.') > -1 || this.nxtFormControl.value.indexOf(',') > -1);
      let newValue: any = parseFloat(typeof this.nxtFormControl.value === 'string' ? this.nxtFormControl.value.replace(',', '.') : this.nxtFormControl.value);

      if (this.isPercent) {
        if (newValue < 0) {
          newValue = 0;
        }
        if (newValue > 100) {
          newValue = 100;
        }
      }

      if (!TypeTools.isNullOrUndefinedOrNAN(newValue) || !TypeTools.isNullOrUndefinedOrNAN(this.nxtFormControl.value)) {
        if (newValue !== this.nxtFormControl.value) {
          if (!(Number.isNaN(newValue) && Number.isNaN(this.nxtFormControl.value)) && this.nxtFormControl.value !== null) {
            this.log('validateLive value-fixed: ' + newValue);
            this.setFormControlValue(newValue, 'validateLive');
            valueChangeEmitted = true;
            this.log('validateLive valueChange.emit: ' + newValue);
            // this.valueChange.emit(newValue);
            this.valueChanged(newValue, 'validateLive');
          }
        }
      }

      if (hasCharDecimalSeparator) {
        if (newValue.toString().indexOf('.') > -1) {
          const valueToSet = newValue.toString().replace('.', ',');
          this.log('setNativeElementValue ' + valueToSet + ' from validateLive-1');
          this.controlElemRef.nativeElement.value = valueToSet;
        } else {
          this.log('setNativeElementValue append "," from validateLive-2');
          this.controlElemRef.nativeElement.value += ',';
        }
      }
    }
    return valueChangeEmitted;
  }

  private validateOnChange() {
    if (this.isNumber && typeof this.isNumberMin === 'number') {
      if (this.nxtFormControl.value < this.isNumberMin) {
        this.setFormControlValue(this.isNumberMin.toString(), 'validateOnChange', true);
      }
    }
  }

  setFormControlValue(value: any, from: string, emitEvent = true, onlySelf = false) {
    this.log('nxtFormControl.setValue ' + value + ' from: ' + from);
    this.nxtFormControl.setValue(value, {emitEvent, onlySelf});
  }

  keyup(event: KeyboardEvent) {
    if (this.type === 'password' && event?.getModifierState('CapsLock')) {
      this.showCapsLockInfo = true;
    } else {
      this.showCapsLockInfo = false;
    }
    if (event.key === 'Enter') {
      this.enter.emit();
    }

    if (event.key === 'ArrowUp') {
      this.up.emit();
    }

    if (event.key === 'ArrowDown') {
      this.down.emit();
    }

    if (event.key === 'Esc' || event.key === 'Escape') {
      this.esc.emit();
    }
  }

  setStyle() {
    this.log('setStyle');
    /*if (this.disabled) {
      // wenn disabled geht auch kein klick!
      if (!this.nxtFormControl.disabled) {
        this.nxtFormControl.disable();
      }
    } else {
      if (this.nxtFormControl.disabled) {
        this.nxtFormControl.enable();
      }
    }*/
    if (this.disabled || this.readonly) {
      // this.nxtFormControl.disable();
    }
    let style = '';

    (this.controlElemRef.nativeElement as HTMLInputElement).setAttribute('autocomplete', 'new-password');
    if (this.disabled || this.readonly || this.nxtFormControl.disabled) {
      // (this.controlElemRef.nativeElement as HTMLInputElement).setAttribute('disabled', '');
    } else {
      // (this.controlElemRef.nativeElement as HTMLInputElement).removeAttribute('disabled');
    }
    if (this.readonly || this.disabled || this.isTimePicker) {
      (this.controlElemRef.nativeElement as HTMLInputElement).setAttribute('readonly', 'readonly');
      style += 'cursor: pointer; ';
    } else {
      (this.controlElemRef.nativeElement as HTMLInputElement).removeAttribute('readonly');
      style += 'cursor: text; ';
    }

    if (this.disableFocusOnInit || this.readonly || this.disabled || this.isTimePicker) {
      (this.controlElemRef.nativeElement as HTMLInputElement).setAttribute('tabIndex', '-1');
      (this.controlElemRef.nativeElement as HTMLInputElement).setAttribute('tabindex', '-1');
    }


    if (this.color) {
      if (this.color.toLowerCase() === 'red') {
        this.color = ColorTools.Red;
      }
      style += 'color:' + this.color + ' !important;';
    }
    if (this.isMoney || this.isPercent || this.isNumber || this.center) {
      style += 'text-align: center;';
    }
    (this.controlElemRef.nativeElement as HTMLInputElement).setAttribute('style', style);
  }

  private valueChanged(newValue, from) {
    this.log('valueChanged newValue: ' + newValue + ' from: ' + from);
    if (!this.validateLive('valueChanged')) {
      if (this._value !== newValue) {
        this._value = newValue;
        this.log('valueChange.emit: ' + newValue);
        this.valueChange.emit(newValue);
        if (!this.hasFocus) {
          // this.addDisplaySuffix('valueChanges.subscribe');
          requestAnimationFrame(() => this.addDisplaySuffix(true, 'valueChanges-1'));
        }
      } else {
        if (!this.hasFocus) {
          requestAnimationFrame(() => this.addDisplaySuffix(false, 'valueChanges-2'));
        }
      }
    }
    this.showClearIcon = !!this._value;
  }

  clear() {
    this.log('clear');
    this.valueChanged('', 'clear');
    this.setFormControlValue('', 'clear');
    this.setFocus();
  }

  async inputClicked(event: MouseEvent) {
    this.inputClick.emit();
    this.log('inputClick');

    requestAnimationFrame(() => {
      if (!this.readonly && !this.hasFocus) {
        setTimeout(() => {
          if (!this.destroyed) {
            (this.permissionService.isJulian());
            {
              // this.dialogService.showOk('jetzt: ' + this.placeholder);
            }
          }
        }, 1000);
      }
    });


    if (!this.isTimePicker) {
      return;
    }
    if (this.readonly) {
      return;
    }
    const result = await this.dialogService.showTimeChooser(this.nxtFormControl.value, this.placeholder, '', '00:00', '23:45', false);
    if (result) {
      this.valueChanged(result, 'inputClick');
      this.log('nxtFormControl.setValue ' + result + ' | {emitEvent: true, onlySelf: false}');
      this.setFormControlValue(result, 'inputClick');
    }
  }

  private log(message: string) {
    // Log.log('INPUT-COMPONENT (' + this.placeholder + ') | ' + message);
  }
}
