<div class="flex flex-col h-100">
  <div class="flex flex-row justify-end">
    <nxt-button-icon (click)="dialogRef.close()">close</nxt-button-icon>
  </div>
  @if (transactionWithoutRawObj() && rawObj()) {
    <div class="flex flex-row overflow-auto">
      <div class="flex flex-col">
        <div>Transaction</div>
        <div class="text-[70%]" [innerHTML]="transactionWithoutRawObj() | jsonFormatted"></div>
      </div>
      <div class="flex flex-col">
        <div>Raw {{ transaction().rawType }}</div>
        <div class="text-[70%]" [innerHTML]="rawObj() | jsonFormatted"></div>
      </div>
    </div>
  }
</div>
