import {Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {LoginService} from './login.service';

@Injectable({
  providedIn: 'root'
})
export class TelegramService {
  constructor(
    private socketService: SocketService,
    private loginService: LoginService
  ) {

    this.socketService.onSubscribeError.subscribe((message) => {
      this.sendAdmin('socket-error: ' + message);
    });
  }

  public sendAdmin(message: string) {
    this.socketService.sendTelegramAdmin(this.loginService.getUserPrefix() + '\n' + message);
  }

  public sendBackofficeHeads(message: string) {
    this.socketService.sendBackofficeHeads(this.loginService.getUserPrefix() + '\n' + message);
  }

  public sendTelegramDayFinish(message: string) {
    this.socketService.sendTelegramDayFinishDirect(this.loginService.getUserPrefix() + '\n' + message);
  }

  async sendStudioInfoForAll(text: string) {
    return this.socketService.sendTelegramStudioInfo(text);
  }
}
