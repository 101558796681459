import {DateTools} from './common-browser/helpers/date.tools';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {Moment} from 'moment/moment';

export class NxtDateFormatter extends MomentDateAdapter {
  constructor() {
    super('de');
  }

  format(date: Moment, displayFormat: string) {
    return DateTools.format(date, displayFormat);
  }
}
