import {Injectable} from '@angular/core';
import {DialogService, LoadingId} from './dialog.service';
import {SocketService} from './socket/socket.service';
import {Router} from '@angular/router';
import {Log} from '../common-browser/log/log.tools';
import {FirebaseLoginService} from './firebase-login.service';
import {TimeTools} from '../common-browser/helpers/time.tools';
import {IframeMessageManagerInIframe} from './iframe-message-manager-in-iframe';
import {ConfigService} from './config.service';
import {SortTools} from '../common-browser/helpers/sort.tools';
import {KeyCode, ShortcutService} from './shortcut.service';
import {LocalStorageService} from './local-storage.service';
import {ObjectTools} from '../common-browser/helpers/object.tools';
import {HttpClient} from '@angular/common/http';
import firebase from 'firebase/compat';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {NxtUserInfo} from '../common-interfaces/nxt.user-info';
import {NxtComponent} from '../components/nxt.component';
import {StudioTools} from '../common-browser/helpers/studio.tools';
import {WindowTools} from '../common-browser/window.tools';
import {Version} from '../../version';
import User = firebase.User;
import {NxtWorkplace} from '../common-interfaces/nxt.employee.interface';
import {FirestoreService} from './firestore.service';
import {firstValueFrom} from 'rxjs';

export interface NxtLogin {
  username: string;
  studio: string;
  workplace: NxtWorkplace;
  studioReal: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoginService extends NxtComponent {

  public set showLoginDialog(value: boolean) {
    if (value && this.loginIsRunning) {
      return;
    }
    Log.debug('showLoginDialog <- ' + value);
    this._showLoginDialog = value;
    this.socketService.suppressSocketConnect = value;
  }

  public get showLoginDialog() {
    return this._showLoginDialog;
  }

  constructor(
    private dialogService: DialogService,
    private socketService: SocketService,
    private router: Router,
    private firebaseLoginService: FirebaseLoginService,
    private angularFireAuth: AngularFireAuth,
    private configService: ConfigService,
    private shortCutService: ShortcutService,
    private storageService: LocalStorageService,
    private firestoreService: FirestoreService,
    private httpClient: HttpClient,
  ) {
    super();
    // firestoreService.test();


    LoginService.instance = this;
    this.getMyIp();
    this.registerShortCuts();


    this.firebaseLoginService.onLoginChanged.subscribe((user) => {
      if (!user) {
        this.showLoginDialog = true;
        if (this.socketService.state.getValue().connected) {
          if (!this.socketService.disableSocket) {
            this.reLogin(false);
          }
        }
      }
    });

    this.socketService.state.subscribe(async (params) => {
      if (params.authenticated) {
        this.currentLogin = ObjectTools.clone(this.tryLoginUser);
        this.dialogService.isJulian = this.isJulian();
        this.storageService.set('CurrentLogin', this.currentLogin);
        const newVersionAfterLogin = this.configService.config.value.serverVersion !== Version.version;
        const newVersionSinceLastLogin = LoginService.NODE_SERVER_VERSION_LAST_CONNECTED && LoginService.NODE_SERVER_VERSION_LAST_CONNECTED !== SocketService.NODE_SERVER_VERSION;
        if (newVersionSinceLastLogin || newVersionAfterLogin) {
          if (window.location.hostname === 'localhost') {
            console.log('eig reload');
          } else {
            const counter = newVersionSinceLastLogin ? 5 : 5;
            IframeMessageManagerInIframe.instance.showIframe('UPDATE', 'Update');
            this.dialogService.hideLoading(LoadingId.PreUpdate);
            this.dialogService.showLoading(LoadingId.NewVersion, 'Update abgeschlossen!\nBitte warten...');
            for (let i = counter; i > 0; i--) {
              this.dialogService.updateLoadingText('Update abgeschlossen!\nBitte warten... ' + i);
              if (i === 3) {
                IframeMessageManagerInIframe.instance.send('reloadIframe');
              }
              if (i === 2) {
                WindowTools.reload('Update durch');
              }
              await TimeTools.sleep(1000);
            }
            this.dialogService.updateLoadingText('Lade automatisch neu\nbitte warten...');
          }
        }
        LoginService.NODE_SERVER_VERSION_LAST_CONNECTED = SocketService.NODE_SERVER_VERSION;
      }
    });


    this.angularFireAuth.authState.subscribe((data) => {
      this.firebaseUser = data;
      this.firebaseAuthStateReceived = true;
    });
    this.registerLogoutPush();
  }


  static instance: LoginService;
  private static NODE_SERVER_VERSION_LAST_CONNECTED: string;
  private _showLoginDialog = true;
  private loginIsRunning: boolean;
  private firebaseAuthStateReceived = false;
  private currentLogin: NxtLogin;
  private tryLoginUser: NxtLogin;
  private showBeginningToasts = false;
  public ip = '';
  private firebaseUser: User | null;

  nxtOnDestroy(): void {
  }

  public async reLogin(ask: boolean) {
    if (ask) {
      const text = '<div class="flex flex-col items-center justify-center text-130 center">' + this.getUsername() + '<br>' + this.getStudio() + '<br>' + StudioTools.getWorkplaceText(this.getWorkplace()) + '</div>';
      if (!(await this.dialogService.showYesNo(text, {yesText: 'Abmelden', noText: 'Zurück'}))) {
        return;
      }
    }
    if (this.socketService.state.value.authenticated && this.socketService.state.value.connected) {
      const user = this.configService.config.value.users.find(u => u.username === this.getUsername());
      if (!user?.disableDailyLogin) {
        this.socketService.logoutUsernameAll();
      }
      this.navigateToLogin({reLogin: true});
    }
  }

  public clearSavedLogin(reload: boolean, reason: string) {
    Log.info('clearSavedLogin');
    // this.setSaveLogin(false, 'clearSavedLogin: ' + reason);
    if (reload) {
      WindowTools.reload('clearSavedLogin');
    }
  }

  /*public async setNewPassword() {
    IframeMessageManagerInIframe.instance.showIframe('setNewPassword', 'Login');
    // this.storageService.set(StorageKey.WeakPassword, true);
    const resultNewPassword = await this.dialogService.showInput({
      isPassword: true,
      message: 'Dein Passwort ist zu schwach, bitte lege ein neues Passwort fest!\n<small>\nMindeslänge: 5\neine Zahl\nein Großbuchstabe\nein Kleinbuchstabe\nein Sonderzeichen wie ! " § $ % & / ( ) = ?</small>'
    });
    if (resultNewPassword) {
      const passwordStrength = PasswordTools.getPasswordStrength(resultNewPassword);
      if (!passwordStrength.ok) {
        await this.dialogService.showOk(passwordStrength.text);
        this.setNewPassword();
      } else {
        const resultNewPasswordSecond = await this.dialogService.showInput({
          message: 'Bitte gebe das Passwort erneut ein!',
          isPassword: true
        });
        if (resultNewPasswordSecond !== resultNewPassword) {
          await this.dialogService.showOk('Passwörter stimmen nicht überein!');
          this.setNewPassword();
        } else {
          const result = await this.firebaseUser.updatePassword('0000_' + resultNewPassword);
          // this.storageService.set(StorageKey.WeakPassword, false);
          await this.dialogService.showOk('Passwort erfolgreich gesetzt');
          this.socketService.logoutUsernameAll();
          this.reLogin();
        }
      }
    } else {
      this.setNewPassword();
    }
  }*/

  async setNewPassword(newPassword: string) {
    const result = await this.firebaseUser.updatePassword('0000_' + newPassword);
    // this.storageService.set(StorageKey.WeakPassword, false);
    await this.dialogService.showOk('Passwort erfolgreich gesetzt');
    await this.socketService.logoutUsernameAll();
    // this.reLogin();
  }

  public async userChange(username: string, studio: string) {
    this.clearSavedLogin(false, 'neu Einloggen');
    this.navigateToLogin({reLogin: true, username});
  }

  public async login(username: string, studio: string, studioReal: string, workplace: NxtWorkplace, password: string, manualLogin: boolean) {

    Log.log('login.service | start login');
    this.loginIsRunning = true;

    try {
      // Log.info('LOGIN WITH: ' + username + ' - ' + pw);


      await this.waitForFirebaseAuthState();
      if (!this.firebaseUser || !this.firebaseUser.email || (username + '@organizer.nxt-lvl.ink').toLowerCase() !== this.firebaseUser.email.toLowerCase()) {
        if (password === '') {
          this.loginIsRunning = false;
          return Promise.resolve({success: false});
        }
        this.firebaseUser = await this.firebaseLoginService.loginFromNgNxtlvlink(username, password);
      }
      if (this.firebaseUser) {
        this.tryLoginUser = {
          username,
          studio,
          studioReal,
          workplace,
        };
        this.socketService.login(username, studio, studioReal, workplace, manualLogin);
      }
      this.loginIsRunning = false;
    } catch (err) {
      this.loginIsRunning = false;
      return {success: false, error: err};
    }
  }

  /*public isAdmin() {
    return this.getUsername() === 'Julian' || this.getUsername() === 'Niklas' || this.getUsername() === 'Twin' || this.getUsername() === 'Timey' || this.getUsername() === 'Born';
  }*/

  public isJulian() {
    return this.getUsername() === 'Julian';
  }

  public isMarcel() {
    return this.getUsername() === 'Marcel';
  }

  public isNiklas() {
    return this.getUsername() === 'Niklas';
  }

  public isCapone() {
    return this.getUsername() === 'Capone';
  }


  private async waitForFirebaseAuthState() {
    return new Promise<void>((resolve, reject) => {
      if (this.firebaseAuthStateReceived) {
        resolve();
      } else {
        setTimeout(() => resolve(this.waitForFirebaseAuthState()), 100);
      }
    });
  }

  public navigateToLogin(options: { username?: string, setNewPassword?: boolean, reLogin: boolean }) {
    const returnUrl = window.location.pathname;
    if (!returnUrl.startsWith('/login')) {
      this.router.navigate(['/login'], {queryParams: {returnUrl, ...options}});
    }
  }

  isBackoffice() {
    if (this.currentLogin?.workplace) {
      return this.currentLogin.workplace === 'backoffice';
    }
  }

  isReception() {
    return this.currentLogin.workplace === 'reception';
  }

  public async chooseUsername(text: string) {
    let usernames = this.configService.config.value.users.sort(SortTools.sortString('username'));
    usernames = usernames.filter(u => !u.noPerson);
    const buttonRows = [usernames.map(login => ({text: login.username}))];
    const result = await this.dialogService.showButtonChooser({buttonRows, title: text, text: '', minWidth: '80%', value: '', hideBackButton: true});
    if (!result) {
      return;
    }
    if (typeof result !== 'string' && result.text) {
      return result.text;
    }
  }

  private registerLogoutPush() {
    this.pushSocketSubscription = this.socketService.subscribeNew('reLogin', () => {
      this.navigateToLogin({reLogin: true});
    });
  }

  private toggleForceDailyLogin() {
    const forceDailyLogin = !this.storageService.get('ForceDailyLogin', false);
    if (forceDailyLogin) {
      this.dialogService.showOk('Tägliches Login erforderlich');
    } else {
      this.dialogService.showOk('Tägliches Login wie Benutzereinstellung');
    }
    this.storageService.set('ForceDailyLogin', forceDailyLogin);
  }

  private toggleEnableBackoffice() {
    let disabledWorkplaces = this.storageService.get('DisabledWorkplaces', []).filter(w => !!w);
    if (!disabledWorkplaces) {
      disabledWorkplaces = [];
    }
    if (disabledWorkplaces.includes('backoffice')) {
      disabledWorkplaces = disabledWorkplaces.filter(w => w !== 'backoffice');
      this.dialogService.showOk('Backoffice aktiviert');
    } else {
      disabledWorkplaces.push('backoffice');
      if (this.isBackoffice()) {
        this.reLogin(false);
      } else {
        this.dialogService.showOk('Backoffice deaktiviert');
      }
    }
    this.storageService.set('DisabledWorkplaces', disabledWorkplaces);
  }

  private registerShortCuts() {
    this.shortCutService.onKeyPress.subscribe((key) => {
      if (key === KeyCode.CtrlShiftD) {
        this.toggleForceDailyLogin();
      }
    });

    this.shortCutService.onKeyPress.subscribe((key) => {
      if (key === KeyCode.CtrlShiftB) {
        this.toggleEnableBackoffice();
      }
    });
  }

  public getUsername() {
    return this.currentLogin?.username;
  }

  getWorkplace(): NxtWorkplace {
    return this.currentLogin?.workplace;
  }

  getStudio() {
    return this.currentLogin?.studio;
  }

  getCashRegister() {
    return this.currentLogin?.studio;

    /*if (this.getWorkplace() === 'reception') {
      return this.currentLogin?.studio;
    } else {
      return 'transfer';
    }
    // return this.currentLogin?.studio;*/
  }

  getStudioReal() {
    return this.currentLogin?.studioReal;
  }

  isMainStudio() {
    return this.getStudioReal() === this.getStudio();
  }

  private async getMyIp() {
    try {
      this.ip = await this.httpClient.get('https://api.nxt-lvl.ink/api/getip', {responseType: 'text'}).toPromise();
    } catch (err) {

    }
  }

  public getUserInfo(): NxtUserInfo {
    return {
      username: this.getUsername(),
      workplace: this.getWorkplace(),
      studio: this.getStudio(),
    };

  }

  getUserPrefix() {
    let result = this.getUsername();

    if (this.getWorkplace() === 'backoffice') {
      result += ' im Backoffice';
    } else {
      result += ' am Empfang';
    }

    if (this.getStudio() === 'Villa') {
      result += ' in der Villa';
    } else {
      result += ' in ' + this.getStudio();
    }
    return result;
  }

  newPassword() {
    this.clearSavedLogin(false, 'neu Einloggen');
    this.navigateToLogin({reLogin: true, setNewPassword: true});
  }
}
