<div fxLayout="column" class="w-full p-relative">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="isNewReminder" class="text-130 p-4">Neue Aufgabe</div>
    <div *ngIf="!isNewReminder" class="text-130 p-4">Aufgabe bearbeiten</div>
  </div>
  <div class="flex flex-col items-start gap-3">
    <div class="flex flex-row gap-2 items-center justify-between w-full">
      <div class="flex flex-row items-center">
        <mat-button-toggle-group [(value)]="dateTimeIn" (valueChange)="dateTimeInChanged()">
          <mat-button-toggle [value]="0">JETZT</mat-button-toggle>
          <mat-button-toggle [value]="30">in 30 Min</mat-button-toggle>
          <mat-button-toggle [value]="60">in 1 Std</mat-button-toggle>
          <mat-button-toggle [value]="120">in 2 Std</mat-button-toggle>
          <mat-button-toggle [value]="-1">Morgen {{ studioStartTimeString }} Uhr</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="flex flex-row">
        <nxt-date-picker [noPadding]="true" [nxtFormControl]="form.get('reminderDate')" [showJumpDayButtons]="true"></nxt-date-picker>
        <nxt-time-picker [noPadding]="true" [nxtFormControl]="form.get('reminderTime')" minTime="10:00"></nxt-time-picker>
      </div>
    </div>
    <div class="flex flex-row gap-2 items-center justify-between w-full">
      <div class="flex flex-row items-center">
        <mat-button-toggle-group [(value)]="todoByRef" (valueChange)="todoByRefChanged()">
          <mat-button-toggle value="user">Benutzer</mat-button-toggle>
          <mat-button-toggle value="reception">Empfang</mat-button-toggle>
          <mat-button-toggle value="backoffice">Backoffice</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="flex flex-row">
        <div class="flex flex-row items-center" nxtPermission="IsJulian" style="position:relative; top: -0px;">
          <nxt-slide-toggle [nxtFormControl]="form.controls.responseExpected"></nxt-slide-toggle>
        </div>
      </div>
    </div>

    <div class="flex flex-row items-center">
      @if (todoByRef === 'user') {
        <mat-button-toggle-group [formControl]="form.controls.toDoByUser">
          @for (user of userOptions; track user) {
            <mat-button-toggle [value]="user">{{ user }}</mat-button-toggle>
          }
        </mat-button-toggle-group>
      }
    </div>

    <div class="flex flex-row">
      <nxt-textarea [nxtFormControl]="form.get('description')">
      </nxt-textarea>
    </div>

    <div class="flex flex-row items-center">
      <div class="pr-3">Verschiebbar um</div>
      <mat-button-toggle-group [formControl]="form.controls.postponeDurations" multiple>
        @for (postponeDurationOption of postponeDurationOptions; track postponeDurationOption.value) {
          <mat-button-toggle [value]="postponeDurationOption.value">{{ postponeDurationOption.text }}</mat-button-toggle>
        }
      </mat-button-toggle-group>
    </div>
    <div class="flex flex-row items-center pt-3">
      <div class="pr-3">Verschiebbar anzahl</div>
      <mat-button-toggle-group [formControl]="form.controls.maxPostpones">
        <mat-button-toggle [value]="0">0</mat-button-toggle>
        <mat-button-toggle [value]="3">3</mat-button-toggle>
        <mat-button-toggle [value]="4">4</mat-button-toggle>
        <mat-button-toggle [value]="5">5</mat-button-toggle>
        <mat-button-toggle [value]="10">10</mat-button-toggle>
        <mat-button-toggle [value]="-1">Unbegrenzt</mat-button-toggle>
      </mat-button-toggle-group>
    </div>


  </div>

  <div class="flex flex-row w-full mt-3 justify-around">
    <nxt-button (click)="cancel()">Abbrechen</nxt-button>
    <nxt-button *ngIf="!isNewReminder" (click)="done()">Ist Erledigt</nxt-button>
    <nxt-button (click)="save()">Speichern</nxt-button>
  </div>
</div>
