<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>{{ layoutItems.length }} Fotos / Videos</nxt-page-header-title>

    @if (showSpinner()) {
      <nxt-spinner [size]="28"></nxt-spinner>
    } @else {
      <nxt-button class="text-[80%] md:text-[100%]" (click)="filterClicked()">{{ filterText() }}</nxt-button>
    }
    <mat-icon class="cursor-pointer mat-icon-lg" (click)="copyToClipboard()" fontSet="material-symbols-outlined">content_copy</mat-icon>

    <mat-button-toggle-group class="flex !flex-col md:!flex-row" [(ngModel)]="filterMediaType" (ngModelChange)="showFiles()" [multiple]="true">
      <mat-button-toggle value="image">Bilder</mat-button-toggle>
      <mat-button-toggle value="video">Videos</mat-button-toggle>
    </mat-button-toggle-group>

  </nxt-page-header>
  <nxt-page-content>
    <div #scroller class="flex h-full flex-col overflow-auto md:pr-4" style="border: 0px solid red; overflow: scroll !important;">
      <div #container nxtPhotoSwipe
           [nxtPhotoSwipeEventFiles]="eventFilesWithEventId()"
           [nxtPhotoSwipeEvents]="events()"
           [nxtPhotoSwipeShowEditIcon]="true"
           (nxtPhotoSwipeOnEditClicked)="editClicked($event)"
           [nxtPhotoSwipeShowShareIcon]="false"
           (nxtPhotoSwipeOnShareClicked)="shareClicked($event)"
           class="gallery" [style.height.px]="galleryHeight">

        @for (item of layoutItems; track item.file.id) {
          <div
            class="photo"
            [style.width.px]="item.width"
            [style.height.px]="item.height"
            [style.transform]="'translate(' + item.left + 'px,' + item.top + 'px)'"
          >
            @if (item.file.type === 'image') {
              <a
                [href]="item.file.id | nxtEventFile"
                [attr.data-pswp-width]="item.file.width"
                [id]="item.file.id"
                [attr.data-custom]="'abc'"
                [attr.data-pswp-height]="item.file.height">
                <img
                  [src]="item.file.id | nxtEventFile: true"
                  [attr.loading]="'lazy'"
                  [style.width.%]="100"
                  [style.height.%]="100"
                />
              </a>
            } @else if (item.file.type === 'video') {
              <mat-icon
                (click)="videoClicked(item.file)"
                class="cursor-pointer"
                style="color:#fff; position:absolute; right:4px; top:4px; opacity: 0.5">
                play_circle
              </mat-icon>
              <img
                class="cursor-pointer"
                (click)="videoClicked(item.file)"
                [src]="item.file.id | nxtEventFile: true"
                [attr.loading]="'lazy'"
                [style.width.%]="100"
                [style.height.%]="100"
              />
            }
          </div>
        }
      </div>
    </div>
  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>

<ng-template #tooltip let-i>
  @if (eventsById()[i.eventId]; as event) {
    <div class="flex flex-col text-[80%]">
      @if (showStudioInfo()) {
        <div>{{ event.studio | nxtStudio }}</div>
      }
      @if (event.ratingValue) {
        <div>Bewertung: {{ event.ratingValue }}</div>
      }
      <div>{{ i.fileCreatedAt | nxtDate: 'dd.MM.yyyy HH:mm' }}</div>
    </div>
  }
</ng-template>

