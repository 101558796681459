import {Component, OnInit, Optional} from '@angular/core';
import {DateTools} from '../../../../common-browser/helpers/date.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {InventoryService} from '../../../../services/inventory.service';
import {SocketService} from '../../../../services/socket/socket.service';
import {LoginService} from '../../../../services/login.service';
import {DialogService} from '../../../../services/dialog.service';
import {NxtInventoryItemPackage, NxtInventoryStockItem, NxtInventoryStockItemOrder} from '../../../../common-interfaces/nxt.inventory-item.interface';
import {UuidTools} from '../../../../common-browser/helpers/uuid.tools';
import {MathTools} from '../../../../common-browser/helpers/math.tools';
import {DurationTools} from '../../../../common-browser/helpers/duration.tools';
import {FromNowPipe} from '../../../../pipes/from-now.pipe';
import {MoneyPipe} from '../../../../pipes/money.pipe';
import {SafeHtmlPipe} from '../../../../pipes/safe-html.pipe';
import {NxtDatePipe} from '../../../../pipes/nxt-date-pipe';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {NxtButtonIconComponent} from '../../../../controls/button-icon/nxt-button-icon.component';
import {NgFor, NgIf} from '@angular/common';
import {RadioComponent} from '../../../form-controls/radio/radio.component';
import {InputComponent} from '../../../form-controls/input/input.component';
import {SelectComponent} from '../../../form-controls/select/select.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {DayOfWeek} from '../../../../common-interfaces/date.interface';


@Component({
  selector: 'nxt-inventory-list-order-create',
  templateUrl: './inventory-order-create.component.html',
  styleUrls: ['./inventory-order-create.component.scss'],
  imports: [FlexModule, SelectComponent, InputComponent, RadioComponent, NgIf, NxtButtonIconComponent, NgFor, NxtButtonComponent, NxtDatePipe, SafeHtmlPipe, MoneyPipe, FromNowPipe],
})

export class InventoryOrderCreateComponent implements OnInit {

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
    private inventoryService: InventoryService,
    private socketService: SocketService,
    private loginService: LoginService,
    private dialogService: DialogService,
  ) {
    if (DateTools.getDayOfWeek(this.shouldArrivedAt) === DayOfWeek.Sunday) {
      this.changeShouldArrivedAt(1);
    }
  }

  item: NxtInventoryStockItem;
  public sourceOfSupplyOptions: { value: string, text: string }[];
  public saveButtonText = 'wurde bestellt';
  public vat = 0.19;
  public orderAmount: number;
  public sourceOfSupply: string;
  public dateOfOrder = DateTools.formatNow('yyyy-MM-dd HH:mm');
  unitPrice: number;
  totalPrice: number;
  shouldArrivedAt = Date.now().dateAddDays(3);
  netGrossOptions = [{text: 'Netto', value: 'net'}, {text: 'Brutto', value: 'gross'}];
  currentNetGrossOption: { text: string, value: string } | null = null;
  singleOrTotalPriceOptions = [{text: 'Einzelpreis', value: 'single'}, {text: 'Gesamtpreis', value: 'total'}];
  currentSingleOrTotalPriceOptions: { text: string, value: string } | null = null;
  totalPriceNet = 0;
  private unitPriceNet = 0;

  /*totalPriceChanged(fromForm: boolean) {
    const newUnitPrice = MathTools.roundMoney(this.totalPrice / this.orderAmount);
    if (newUnitPrice !== this.unitPrice) {
      this.unitPrice = newUnitPrice;
    }
  }*/

  /*unitPriceChanged(fromForm: boolean) {
    const newTotalPrice = MathTools.roundMoney(this.unitPrice * this.orderAmount);
    if (newTotalPrice !== this.totalPrice) {
      this.totalPrice = newTotalPrice;
    }
  }*/

  /*async findAutoPackage() {

  }*/
  shouldOrderAmount = -1;

  ngOnInit() {
    this.sourceOfSupplyOptions = [];
    if (this.item.sourceOfSupply) {
      this.sourceOfSupplyOptions.push({
        value: this.item.sourceOfSupply,
        text: this.inventoryService.inventorySourcesOfSupply.find(i => i.value === this.item.sourceOfSupply)?.text,
      });
    }
    if (this.item.sourceOfSupply2) {
      this.sourceOfSupplyOptions.push({
        value: this.item.sourceOfSupply2,
        text: this.inventoryService.inventorySourcesOfSupply.find(i => i.value === this.item.sourceOfSupply2)?.text,
      });
    }
  }

  public createOrder() {
    if (!this.currentSingleOrTotalPriceOptions) {
      this.dialogService.showOk('Preis fehlt');
      return;
    }
    if (!this.currentNetGrossOption) {
      this.dialogService.showOk('Ist der Gesamtpreis\n- Netto (weniger)\n- Brutto (mehr)');
      return;
    }
    if (!this.sourceOfSupply) {
      this.dialogService.showOk('Bezugsquelle fehlt');
      return;
    }
    if (!this.orderAmount) {
      this.dialogService.showOk('Menge fehlt');
      return;
    }
    if (!this.totalPrice) {
      this.dialogService.showOk('Gesamtpreis fehlt');
      return;
    }


    /*let totalPriceNet = MathTools.roundMoney(this.totalPrice);
    let unitPriceNet = this.totalPrice / this.orderAmount;
    if (this.currentNetGrossOption.value === 'gross') {
      totalPriceNet = MathTools.roundMoney(this.totalPrice / (this.vat + 1));
      unitPriceNet = unitPriceNet / (this.vat + 1);
    }*/
    const order: NxtInventoryStockItemOrder = {
      createdBy: this.loginService.getUsername(),
      id: UuidTools.generate(),
      arrived: false,
      itemId: this.item.id,
      studio: this.item.studio,
      createdAt: Date.now(),
      orderAmount: this.orderAmount,
      sourceOfSupply: this.sourceOfSupply,
      arrivedBy: '',
      arrivedAt: 0,
      unitPrice: this.unitPriceNet,
      unitPriceNet: this.unitPriceNet,
      vat: this.vat,
      totalPriceNet: MathTools.roundMoney(this.totalPriceNet),
      shouldArrivedAt: this.shouldArrivedAt,
    };
    this.socketService.upsertInventoryOrder(order);
    this.dialogRef.close();
  }

  changeShouldArrivedAt(daysToAdd: number) {
    this.shouldArrivedAt = DateTools.addDays(this.shouldArrivedAt, daysToAdd);
    if (this.shouldArrivedAt < Date.now()) {
      this.shouldArrivedAt = Date.now() + DurationTools.DURATION_1DAY;
    }
    if (DateTools.getDayOfWeek(this.shouldArrivedAt) === DayOfWeek.Sunday) {
      this.changeShouldArrivedAt(daysToAdd);
    }
  }

  async packageClicked(itemPackage: NxtInventoryItemPackage) {
    let cost = itemPackage.packagePriceNet;
    if (!cost) {
      cost = await this.dialogService.showInput('Was kostet ein Paket (netto)\n' + itemPackage.name, {isMoney: true});
    }
    if (cost) {
      let text = 'Wie viel Pakete bestellt du?\n(Menge im Online-Shop)';
      let packageToOrder = this.shouldOrderAmount / itemPackage.amount;
      if (packageToOrder % 1 > 0.3) {
        packageToOrder++;
      }
      if (packageToOrder === 0) {
        packageToOrder++;
      }
      packageToOrder = Math.floor(packageToOrder);
      if (this.shouldOrderAmount > 0) {
        // text += '\n\nSollmenge = ' + this.shouldOrderAmount;
        if (packageToOrder === 1) {
          // text += '\n' + packageToOrder + ' Paket = ' + (packageToOrder * itemPackage.amount) + ' das ist gut so!';
          text += '\n\nNimm 1 Paket, vertrau mir 😉';
        } else {
          // text += '\n' + packageToOrder + ' Pakete = ' + (packageToOrder * itemPackage.amount) + ' das ist gut so!';
          text += '\n\nNimm ' + packageToOrder + ' Pakete, vertrau mir 😉';
        }

        // text += '\n\nBestelle ' + packageToOrder + ' Pakete das passt gut!';
      }

      const amount = await this.dialogService.showInput(text, {placeholder: 'Anzahl Pakete', isNumber: true, prompt: packageToOrder, selectPrompt: true});
      if (amount) {
        this.currentNetGrossOption = this.netGrossOptions.find(o => o.value === 'net');
        this.orderAmount = amount * itemPackage.amount;
        this.totalPrice = amount * cost;
        this.sourceOfSupply = itemPackage.sourceOfSupply;
      }
    }
  }

  async calcNetGross() {
    if (this.currentSingleOrTotalPriceOptions.value === 'single') {
      if (this.unitPrice) {
        this.totalPriceNet = MathTools.roundMoney(this.unitPrice * this.orderAmount);
        this.unitPriceNet = this.totalPrice / this.orderAmount;
        if (this.currentNetGrossOption && this.currentNetGrossOption.value === 'gross') {
          this.totalPriceNet = MathTools.roundMoney(this.totalPrice / (this.vat + 1));
          this.unitPriceNet = this.unitPriceNet / (this.vat + 1);
        }
        this.totalPrice = this.unitPrice * this.orderAmount;
      }
    } else {
      if (this.totalPrice) {
        this.totalPriceNet = MathTools.roundMoney(this.totalPrice);
        this.unitPriceNet = this.totalPrice / this.orderAmount;
        if (this.currentNetGrossOption && this.currentNetGrossOption.value === 'gross') {
          this.totalPriceNet = MathTools.roundMoney(this.totalPrice / (this.vat + 1));
          this.unitPriceNet = this.unitPriceNet / (this.vat + 1);
        }
        this.unitPrice = MathTools.roundMoney(this.totalPrice / this.orderAmount);
      }
    }
  }
}
