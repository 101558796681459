import {Pipe, PipeTransform} from '@angular/core';
import {NxtMoneyStack} from '../common-interfaces/nxt.money-stack.interface';
import {keys} from '../common-browser/helpers/object.tools';
import {TypeTools} from '../common-browser/helpers/type.tools';
import {SortTools} from '../common-browser/helpers/sort.tools';
import {StringTools} from '../common-browser/helpers/string.tools';
import {NxtBankDocumentType} from '../common-interfaces/bank/bank-transaction.interface';
import {BankDocumentTools} from '../common-browser/helpers/bank-document.tools';

@Pipe({
  name: 'nxtBankDocumentType',
  standalone: true
})
export class BankDocumentTypePipe implements PipeTransform {

  constructor() {
  }

  transform(documentType: NxtBankDocumentType): string {
    return BankDocumentTools.getTypeText(documentType);
  }
}
