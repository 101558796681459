import {AbstractColDef, CellClickedEvent, CellDoubleClickedEvent, CellStyle, ColDef} from 'ag-grid-community';
import {NxtFieldType, NxtFieldTypeParams} from '../../../common-interfaces/nxt-field.interface';

export function NxtFieldTypeParams<T extends keyof NxtFieldTypeParams>(params: NxtFieldTypeParams[T]): NxtFieldTypeParams[T] {
  return params;
}

export interface NxtColDef<TData = any> extends ColDef<TData>, AbstractColDef<TData> {
  nxtFieldTypeParams?: any;
  nxtFieldType?: NxtFieldType;
  nxtCellStyle?: CellStyle;
  /**
   * @deprecated Use nxtOnCellClicked instead
   */
  onCellClicked?: (event: CellClickedEvent<TData, any>) => void;
  nxtOnCellClicked?: (event: CellClickedEvent<TData, any>) => void;
  /**
   * @deprecated Use nxtOnCellDoubleClicked instead
   */
  onCellDoubleClicked?: (event: CellDoubleClickedEvent<TData, any>) => void;
  nxtOnCellDoubleClicked?: (event: CellDoubleClickedEvent<TData, any>) => void;
  nxtIcon?: string;
  groupedSortMethod?: 'value' | 'count';
}

export class CellRenderValues {
  static Loading = '<div style="width: 24px;margin: auto"><svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="12 12 75 75" enable-background="new 0 0 0 0" xml:space="preserve">\n' +
    '    <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">\n' +
    '      <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>\n' +
    '  </path>\n' +
    '</svg></div>';
}
