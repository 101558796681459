import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {LoginService} from './login.service';
import {NxtInventoryService} from '../common-browser/services/nxt.inventory.service';
import {ConfigService} from './config.service';
import {NxtInventoryItem} from '../common-interfaces/nxt.inventory-item.interface';
import {TelegramService} from './telegram.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService extends NxtInventoryService {

  constructor(
    private firestoreService: FirestoreService,
    private loginService: LoginService,
    private configService: ConfigService,
    private telegramService: TelegramService
  ) {
    super(firestoreService.collection.bind(firestoreService), loginService.getUsername.bind(loginService), configService.config.value.studiosWithoutSideCash.find(s => !['Alsdorf', 'Brand'].includes(s.name)).name);
  }

  upsertInventoryItem(nxtInventoryItem: NxtInventoryItem, username: string) {
    return super.upsertInventoryItem(nxtInventoryItem, username);
  }
}
