import {UuidTools} from './uuid.tools';
import {DateTools} from './date.tools';
import {NxtShopOrderLineItem} from '../../common-interfaces/nxt.shop-order.interface';

export class GiftCardTools {

  public static getDateOfExpiry(date: any): number {
    const dateOfExpiry = new Date(DateTools.parse(date));
    dateOfExpiry.setMonth(0);
    dateOfExpiry.setDate(1);
    dateOfExpiry.setFullYear(dateOfExpiry.getFullYear() + 4);
    return dateOfExpiry.getTime();
  }

  static generateCode(prefix: string) {
    let code = (UuidTools.generate().substr(0, 30)).toUpperCase().replace(/-/g, '');
    code = code.substr(0, 4) + '-' + code.substr(16, 4) + '-' + code.substr(20, 4);
    return prefix + '-' + code;
  }

  static getSubTypeFromLineItem(lineItem: NxtShopOrderLineItem) {
    if (lineItem.name.toLowerCase().includes('daysession') || lineItem.name.toLowerCase().includes('doublesession')) {
      return 'day-session';
    }
  }
}
