import {ChangeDetectorRef, Component, computed, EventEmitter, input, Input, OnChanges, OnInit, Optional, Output, signal, SimpleChanges} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {MobileTools} from '../../common-browser/helpers/mobile.tools';
import {DialogService} from '../../services/dialog.service';
import {SocketService} from '../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtShopOrder, NxtShopOrderLineItem} from '../../common-interfaces/nxt.shop-order.interface';
import {FromNowPipe} from '../../pipes/from-now.pipe';
import {JsonFormattedPipe} from '../../pipes/json-formatted-pipe';
import {ShopOrderStatusPipe} from '../../pipes/shop-order-status.pipe';
import {ShopOrderPaymentMethodPipe} from '../../pipes/shop-order-payment-method.pipe';
import {MoneyPipe} from '../../pipes/money.pipe';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {KlarnaOrderRawComponent} from '../klarna-order-raw/klarna-order.raw.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {PermissionDirective} from '../../directives/permission.directive';
import {NgFor, NgIf} from '@angular/common';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NxtKlarnaOrder} from '../../common-interfaces/nxt.klarna-order.interface';
import {NxtRefund} from '../../common-interfaces/refund.interface';
import {NxtPaypalTransaction} from '../../common-interfaces/nxt-paypal-transaction';
import {NxtFindPipe} from '../../pipes/find-pipe';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {JsonTools} from '../../common-browser/helpers/json.tools';

@Component({
  selector: 'nxt-shop-order-view',
  templateUrl: './shop-order-view.component.html',
  styleUrls: ['./shop-order-view.component.scss'],
  imports: [
    NxtButtonIconComponent,
    NgIf,
    NgFor,
    PermissionDirective,
    NxtButtonComponent,
    KlarnaOrderRawComponent,
    NxtDatePipe,
    MoneyPipe,
    ShopOrderPaymentMethodPipe,
    ShopOrderStatusPipe,
    JsonFormattedPipe,
    FromNowPipe,
    NxtFindPipe,
  ]
})

export class ShopOrderViewComponent extends NxtComponent implements OnInit, OnChanges, NxtOnDestroy {
  giftCardCount = 0;
  isDialog = false;

  constructor(
    @Optional() public dialogRef: MatDialogRef<ShopOrderViewComponent>,
    private cdRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private socketService: SocketService
  ) {
    super();
    this.pushSocketSubscription = this.socketService.subscribeNew('eventRefundCreated', (refund) => {
      if (refund.shopOrderId === this.shopOrderId) {
        this.load();
      }
    });
  }

  /*** Signals ***/
  public shopOrder = signal<NxtShopOrder>(null);
  public refunds = signal<NxtRefund[]>([]);
  public refundValue = signal(0);
  public showOrderCreated = computed(() => !this.shopOrder().payedAt || this.shopOrder().payedAt - this.shopOrder().createdAt > DurationTools.DURATION_1MINUTE * 20);

  /*** Inputs ***/
  @Input() shopOrderId: string;
  @Input() showCloseButton = false;
  showDebug = input(false);

  /*** Outputs ***/
  @Output() showEvent = new EventEmitter<string>();
  @Output() showGiftCard = new EventEmitter<string>();
  @Output() showTattooTicketKlarna = new EventEmitter<string>();
  @Output() showTattooTicketPaypal = new EventEmitter<string>();


  paypalTransaction?: NxtPaypalTransaction;
  payPalIpn: any;
  giftCardCodes: { [orderItemId: number]: string } = {};
  tattooTickets: { [orderItemId: number]: NxtKlarnaOrder } = {};

  ngOnInit() {
    this.isDialog = this.dialogRef?.componentInstance === this;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shopOrderId) {
      this.load();
    }
    this.cdRef.detectChanges();
  }

  nxtOnDestroy() {
  }

  async sendGiftCardViaWhatsAppClicked() {
    const mobile = MobileTools.format(this.shopOrder().billingPhone);
    let text = 'Gutschein an ' + mobile + ' senden?';
    if (this.giftCardCount > 1) {
      text = this.giftCardCount + ' Gutscheine an ' + mobile + ' senden?';
    }
    const doIt = await this.dialogService.showYesNo(text);
    if (doIt) {
      this.dialogService.showLoading('Gutschein wird gesendet...');
      const result = await this.socketService.reSendGiftCardsViaWhatsApp(this.shopOrder().id, mobile);
      this.dialogService.hideLoading();
      if (result.error) {
        this.dialogService.showOk('Fehler beim senden\n' + result.error);
      } else {
        this.dialogService.showOk('Erfolgreich gesendet');
      }
    }
  }

  async sendGiftCardViaEmailClicked() {
    let text = 'Gutschein an "' + this.shopOrder().billingEmail + '" senden?';
    if (this.giftCardCount > 1) {
      text = this.giftCardCount + ' Gutscheine an "' + this.shopOrder().billingEmail + '" senden?';
    }
    const doIt = await this.dialogService.showYesNo(text);
    if (doIt) {
      this.dialogService.showLoading('Gutschein wird gesendet...');
      const result = await this.socketService.reSendGiftCardsViaEmail(this.shopOrder().id);
      this.dialogService.hideLoading();
      if (result.error) {
        this.dialogService.showOk('Fehler beim senden\n' + result.error);
      } else {
        this.dialogService.showOk('Erfolgreich gesendet');
      }
    }
  }

  private async getAdditionalInfos() {
    this.giftCardCodes = {};
    this.giftCardCount = 0;
    if (this.shopOrder().paymentMethod === 'paypal') {
      this.paypalTransaction = await this.socketService.getPaypalTransactionFirstParent(this.shopOrder().transactionId);
      this.payPalIpn = await this.socketService.getPayPalIpn(this.shopOrder().transactionId);
    }
    if (this.shopOrder().paymentMethod === 'klarna_payments') {
      for (const lineItem of this.shopOrder().lineItems) {
        if (lineItem.type === 'tattoo-ticket') {
          // this.tattooTickets[lineItem.id] = await this.socketService.getKlarnaOrderByShopOrderLineItemId(lineItem.id);
          // this.tattooTicketsCount++;
        }
      }
    }
    for (const lineItem of this.shopOrder().lineItems) {
      if (lineItem.type === 'gift-card') {
        this.giftCardCodes[lineItem.id] = await this.socketService.getGiftCardCodeByByShopOrderLineItemId(lineItem.id);
        this.giftCardCount++;
      }
    }
  }

  async load() {
    const data = await this.socketService.getShopOrderForView(this.shopOrderId);
    this.shopOrder.set(data.shopOrder);
    this.refunds.set(data.refunds);
    this.refundValue.set(this.refunds().reduce((sum, r) => sum + r.value, 0));
    this.getAdditionalInfos();
  }

  syncClicked() {
    this.socketService.syncShopOrder(this.shopOrderId);
  }


  showLineItemClicked(lineItem: NxtShopOrderLineItem) {
    if (this.giftCardCodes[lineItem.id]) {
      this.showGiftCard.emit(this.giftCardCodes[lineItem.id]);
      return;
    }

    let eventEmitter: EventEmitter<string>;
    if (lineItem.type === 'tattoo-ticket' && this.shopOrder().paymentMethod === 'klarna_payments') {
      eventEmitter = this.showTattooTicketKlarna;
    }
    if (lineItem.type === 'tattoo-ticket' && this.shopOrder().paymentMethod === 'paypal') {
      eventEmitter = this.showTattooTicketPaypal;
    }
    if (this.isDialog) {
      this.dialogService.showTattooTicket.emit(lineItem.id);
      this.dialogRef.close();
    } else {
      eventEmitter.emit(lineItem.id);
    }
  }

  showTattooTicketKlarnaClicked(shopOrderLineItemId: string) {
    if (this.isDialog) {
      this.dialogService.showTattooTicket.emit(shopOrderLineItemId);
      this.dialogRef.close();
    } else {
      this.showTattooTicketKlarna.emit(shopOrderLineItemId);
    }
  }

  showTattooTicketPaypalClicked(shopOrderLineItemId: string) {
    if (this.isDialog) {
      this.dialogService.showTattooTicket.emit(shopOrderLineItemId);
      this.dialogRef.close();
    } else {
      this.showTattooTicketPaypal.emit(shopOrderLineItemId);
    }
  }

  public showDebugClicked() {
    this.dialogService.showOk(JsonTools.stringifyFormat(this.shopOrder())).then();
  }
}



