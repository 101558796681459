import {inject, Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';

export interface Go2Item {
  name: string;
  size: string;
  amount: string;
  id: string;
  ok?: boolean;
  price?: string;
}

@Injectable({
  providedIn: 'root',
})
export class InventorySpecialButtonService {
  /*** Injections ***/
  private socketService = inject(SocketService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  runGo2Inject(items: Go2Item[]) {
    function startGo2Inject(items: Go2Item[]) {
      document.querySelectorAll('input').forEach(input => input.value = '');
      const allTds = Array.from(document.querySelectorAll('td'));
      for (const item of items) {
        const nameTds = allTds.filter(td => {
          return td.innerText === item.name;
        });
        nameTds.forEach(nameTdTd => {
          const nameTr = nameTdTd.parentNode;
          const allBbbTds = Array.from(nameTr.querySelectorAll('td'));
          const rightRowTd = allBbbTds.find(td => {
            return td.innerText === item.size;
          });
          if (rightRowTd) {
            const amountInput = nameTr.querySelector('input') as HTMLInputElement;
            const priceTd = allBbbTds.find(td => td.innerText.includes('€'));
            if (amountInput) {
              amountInput.value = item.amount;
              item.ok = true;
              item.price = priceTd.innerText;
            }
          }
        });
      }

      let hasError = false;
      for (const item of items) {
        if (!item.ok) {
          alert('Achtung: Artikel nicht gefunden:' + item.name + ' | ' + item.size);
          hasError = true;
        }
      }
      if (hasError) {
        alert('Es wurden nicht alle Artikel gefunden, daher passiert hier nix :(');
        document.querySelectorAll('input').forEach(input => input.value = '');
      } else {
        navigator.clipboard.writeText(JSON.stringify(items));
        alert('Alles in den Warenkorb legen, ergebnis ist in der Zwischenablage');
      }
    }

    $('.nxt-button').remove();
    const button = $('<button style="padding: 10px; font-size: 200%; position: absolute; right: 0; top:0">NXT-Start</button>');
    button.click(() => startGo2Inject(items));
    $(document.body).append(button);
  }
}
