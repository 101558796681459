<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>Einwilligungserklärungen mit Fehler</nxt-page-header-title>
  </nxt-page-header>
  <nxt-page-content>
    <nxt-datagrid
      excelExportName="Einwilligungs-Check"
      [columnDefs]="columnDefs"
      [rowData]="events()"
    />

  </nxt-page-content>
  <nxt-page-footer>
    <div class="flex flex-row gap-4">
      @for (user of users(); track user.user) {
        <div>{{ user.user }}: {{ user.count }}</div>
      }
    </div>
  </nxt-page-footer>
</nxt-page>
