import {Routes} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {AuthGuard} from './auth-guard';
import {DebugComponent} from './pages/debug/debug.component';
import {AddContactComponent} from './pages/add-contact/add-contact.component';
import {JulianStartComponent} from './pages/julian-start/julian-start.component';
import {NewContactComponent} from './pages/new-contact/new-contact.component';
import {LogoComponent} from './pages/logo/logo.component';
import {ReceiptComponent} from './pages/receipt/receipt.component';
import {GiftCardGeneratorComponent} from './pages/gift-card-generator/gift-card-generator.component';
import {InvoicesComponent} from './pages/invoices/invoices.component';
import {BrudiTvComponent} from './pages/brudi-tv/brudi-tv.component';
import {BrudiTvDashboardComponent} from './pages/brudi-tv-dashboard/brudi-tv-dashboard.component';
import {VoucherPdfComponent} from './pages/pdf/voucher-pdf/voucher-pdf.component';
import {HeaderDoubleBlockComponent} from './pages/design-helper/header-double-block/header-double-block.component';
import {TattooTicketPdfComponent} from './pages/pdf/tattoo-ticket-pdf/tattoo-ticket-pdf.component';
import {BackofficeComponent} from './components/backoffice/backoffice.component';
import {EventFinderComponent} from './components/event-finder/event-finder.component';
import {StudioFeeComponent} from './components/studio-fee/studio-fee.component';
import {ArtistEditComponent} from './pages/artists/artist-edit/artist-edit.component';
import {ArtistsComponent} from './pages/artists/artists.component';
import {InvoiceBuilderComponent} from './pages/invoice-builder/invoice-builder.component';
import {UsersComponent} from './pages/users/users.component';
import {ArtistsGalleryLinksComponent} from './components/artists-gallery-links/artists-gallery-links.component';
import {YearFinishComponent} from './components/year-finish/year-finish.component';
import {InvoiceExternStudioPdfComponent} from './pages/pdf/invoice-extern-studio-pdf/invoice-extern-studio-pdf.component';
import {InvoiceFromInvoiceFormPdfComponent} from './pages/pdf/invoice-from-invoice-form/invoice-from-invoice-form-pdf.component';
import {InvoiceFormComponent} from './components/invoice-form/invoice-form.component';
import {WebFormSubmitsComponent} from './components/web-form-submits/web-form-submits.component';
import {WhatsAppQrComponent} from './components/whatsapp/whats-app-qr/whats-app-qr.component';
import {WhatsappChatComponent} from './components/whatsapp/whatsapp-chat/whatsapp-chat.component';
import {SubscriptionTestComponent} from './components/subscription-test/subscription-test.component';
import {WebFormSubmitPrintComponent} from './components/web-form-submit-print/web-form-submit-print.component';
import {NotAssignedPaymentsComponent} from './components/not-assigend-payments/not-assigned-payments.component';
import {WhatsAppChatsComponent} from './components/whatsapp/whatsapp-chats/whats-app-chats.component';
import {DiscountPromotionsComponent} from './components/discount-promotions/discount-promotions.component';
import {AdminComponent} from './components/admin/admin.component';
import {QrCodeComponent} from './components/qr-code/qr-code.component';
import {SearchComponent} from './pages/search/search.component';
import {DiscountAnalysisComponent} from './pages/analysis/discount-analysis/discount-analysis.component';
import {RomanNumeralsComponent} from './components/roman-numerals/roman-numerals.component';
import {PrePayoutComponent} from './pages/pre-payout/pre-payout.component';
import {WhatsappBroadcastComponent} from './components/whatsapp/whatsapp-broadcast/whatsapp-broadcast.component';
import {EventQrOkComponent} from './components/event-qr/event-qr-ok/event-qr-ok.component';
import {WhatsappExtensionComponent} from './components/whatsapp/whatsapp-extension/whatsapp-extension.component';
import {EventsViewComponent} from './components/events-view/events-view.component';
import {PaypalCheckoutComponent} from './pages/paypal/paypal-checkout/paypal-checkout.component';
import {ArticlesComponent} from './pages/articles/articles.component';
import {PiercingChangeBookComponent} from './components/studio-cash-report/piercing-change-book/piercing-change-book.component';
import {ArtistPhotoUnknownUploadsComponent} from './components/artist-photo-unknown-uploads/artist-photo-unknown-uploads.component';
import {WhatsappFastAnswersComponent} from './components/whatsapp/whatsapp-fast-answers/whatsapp-fast-answers.component';
import {PromoQrComponent} from './components/promo-qr/promo-qr.component';
import {CurrentArtistPhotoComponent} from './components/current-artist-photo/current-artist-photo.component';
import {DrawComponent} from './pages/draw/draw.component';
import {WhatsappImageSendComponent} from './components/whatsapp-image-send/whatsapp-image-send.component';
import {PriceBuilderPreImageComponent} from './components/price-builder/price-builder-pre-image/price-builder-pre-image.component';
import {ToWhatsappComponent} from './pages/to-whatsapp/to-whatsapp.component';
import {TextTemplatesComponent} from './components/text-templates/text-templates.component';
import {InventoryItemHistoryComponent} from './components/history/inventory-item-history/inventory-item-history.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {PiercingManagerComponent} from './components/piercing-manager/piercing-manager.component';
import {RemindersComponent} from './components/reminders/reminders.component';
import {StudioCashReport2Component} from './components/studio-cash-report-2/studio-cash-report-2.component';
import {DayFinishReport2Component} from './components/studio-cash-report-2/day-finish/day-finish-report-2/day-finish-report-2.component';
import {DayFinishLogComponent} from './components/day-finish-log/day-finish-log.component';
import {PromoUsersComponent} from './components/promo/promo-users/promo-users.component';
import {DiscountPromotions2Component} from './components/discount-promotions-2/discount-promotions-2.component';
import {KlarnaCheckoutComponent} from './pages/klarna/klarna-checkout/klarna-checkout.component';
import {WhatsappInstaResponsesComponent} from './components/whatsapp-insta-responses/whatsapp-insta-responses.component';
import {ContactsSameMobileComponent} from './components/contacts-same-mobile/contacts-same-mobile.component';
import {DeclarationsOfConsentComponent} from './pages/declarations-of-consent/declarations-of-consent.component';
import {PrintGiftCardsComponent} from './components/print-giftcars/print-gift-cards.component';
import {DeclarationsOfConsentPiercingComponent} from './pages/declarations-of-consent/declarations-of-consent-piercing/declarations-of-consent-piercing.component';
import {AfterCareInstructionPiercingComponent} from './pages/after-case-instruction/after-care-instruction-piercing/after-care-instruction-piercing.component';
import {ArtistHistoryComponent} from './components/history/artist-history/artist-history.component';
import {InventoryListComponent} from './components/inventory/inventory-list/inventory-list.component';
import {InventoryPrintComponent} from './components/inventory/inventory-list/inventory-print/inventory-print.component';
import {NotAssignedBankTransactionsComponent} from './components/bank/not-assigned-bank-transactions/not-assigned-bank-transactions.component';
import {EventsInfosComponent} from './components/events/events-infos/events-infos.component';
import {ContactsComponent} from './components/contacts/contacts.component';
import {CalendarEventEdit2Component} from './pages/calendar-event-edit/calendar-event-edit-2/calendar-event-edit-2.component';
import {NxtNgConfigRuntime} from './common-interfaces/nxt.ng-config.interface';
import {Invoice2PdfComponent} from './pages/pdf/invoice-2-pdf/invoice-2-pdf.component';
import {CalendarEventHistoryComponent} from './components/history/calendar-event-history/calendar-event-history.component';
import {CalendarComponent} from './components/calendar/calendar.component';
import {DateSelectComponent} from './components/calendar/date-select/date-select.component';
import {KlarnaOrdersComponent} from './components/klarna/klarna-orders/klarna-orders.component';
import {DiscountGiftCardsComponent} from './components/discount-gift-cards/discount-gift-cards.component';
import {MultiViewComponent} from './components/multi-view/multi-view.component';
import {MultiView2Component} from './components/multi-view-2/multi-view-2.component';
import {TestComponent} from './pages/test/test.component';
import {AfterCareInstructionTattooComponent} from './pages/after-case-instruction/after-care-instruction-tattoo/after-care-instruction-tattoo.component';
import {EmployeesComponent} from './components/employee/employees.component';
import {EmployeeCalendarComponent} from './components/employee/employee-calendar/employee-calendar.component';
import {EventsRatingComponent} from './pages/events-rating/events-rating.component';
import {MessengerComponent} from './pages/messenger/messenger.component';
import {EmployeeWorksComponent} from './components/employee/employee-works/employee-works.component';
import {MoneyAccountsComponent} from './components/money-accounts/money-accounts.component';
import {ConsentCheckComponent} from './components/consent-check/consent-check.component';
import {KlarnaDisputesComponent} from './components/klarna-disputes/klarna-disputes.component';
import {EventQr2Component} from './components/event-qr/event-qr-2/event-qr-2.component';
import {ArtistRatingsComponent} from './components/artist-ratings/artist-ratings.component';
import {VideoComponent} from './components/video/video.component';
import {IcloudComponent} from './components/icloud/icloud.component';
import {PhotosComponent} from './components/photos/photos.component';
import {PhotoEditorComponent} from './components/photos/photo-editor/photo-editor.component';
import {EventFilterComponent} from './components/event-filter/event-filter.component';
import {FontExtractorComponent} from './pages/font-extractor/font-extractor.component';
import {FontsComponent} from './pages/fonts/fonts.component';
import {DriveMigrationComponent} from './components/drive-migration/drive-migration.component';
import {TelegramStudioSocialMediaStartChatComponent} from './components/telegram/telegram-studio-social-media-start-chat/telegram-studio-social-media-start-chat.component';
import {MapComponent} from './components/map/map.component';
import {ChatsToRecheckComponent} from './components/chats-to-recheck/chats-to-recheck.component';
import {QrCodeGeneratorComponent} from './components/qr-code-generator/qr-code-generator.component';
import {MonitorComponent} from './pages/monitor/monitor.component';
import {BankTransactionsComponent} from './components/bank/bank-transactions/bank-transactions.component';
import {BankDocumentsComponent} from './components/bank/bank-documents/bank-documents.component';
import {BankDocumentDetailsComponent} from './components/bank/bank-documents/bank-document-details/bank-document-details.component';
import {BankTransactionDetailsComponent} from './components/bank/bank-transactions/bank-transaction-details/bank-transaction-details.component';
import {TattooTicketSelectForPaymentComponent} from './components/tattoo-ticket/tattoo-ticket-select-for-payment/tattoo-ticket-select-for-payment.component';
import {BankBookingAccountsComponent} from './components/bank/bank-booking-accounts/bank-booking-accounts.component';
import {PdfInvoice3Component} from './pages/pdf-invoice-3/pdf-invoice-3.component';
import {BankTransactionLabelsComponent} from './components/bank/bank-transaction-labels/bank-transaction-labels.component';
import {DeclarationsOfConsentToothGemComponent} from './pages/declarations-of-consent/declarations-of-consent-tooth-gem/declarations-of-consent-tooth-gem.component';
import {AfterCareInstructionToothGemComponent} from './pages/after-case-instruction/after-care-instruction-tooth-gem/after-care-instruction-tooth-gem.component';
import {IbPromotionsComponent} from './pages/ink-back/ib-offers/ib-promotions.component';

export function getRoutes() {
  const ngConfig: NxtNgConfigRuntime = (window as any).ngConfig;
  return [...routes, ...calendarEventEditRoutes2];
}


const calendarEventEditRoutes2: Routes = [
  {path: 'eventedit-new', component: CalendarEventEdit2Component, canActivate: [AuthGuard]},
  {path: 'eventedit', component: CalendarEventEdit2Component, canActivate: [AuthGuard]},
  {path: 'eventedit-eventId/:eventId', redirectTo: 'e/:eventId', pathMatch: 'full'},
  {path: 'e/:eventId', component: CalendarEventEdit2Component, canActivate: [AuthGuard]},
  {path: 'eventedit-jsondata/:jsondata', component: CalendarEventEdit2Component, canActivate: [AuthGuard]},
];


const routes: Routes = [
  // {path: '', redirectTo: 'main', pathMatch: 'full'},
  {path: '', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'new-contact', component: NewContactComponent, canActivate: [AuthGuard], data: {title: 'abc'}},
  {path: 'debug', component: DebugComponent, canActivate: [AuthGuard]},
  {path: 'cash-report', component: StudioCashReport2Component, canActivate: [AuthGuard], data: {permission: 'StudioCashReport', title: 'Cash-Report'}},
  {path: 'studio-cash-report', component: StudioCashReport2Component, canActivate: [AuthGuard], data: {permission: 'StudioCashReport', title: 'Cash-Report'}},
  {path: 'studio-cash-report-2', component: StudioCashReport2Component, canActivate: [AuthGuard], data: {permission: 'StudioCashReport', title: 'Cash-Report'}},
  {path: 'backoffice', component: BackofficeComponent, canActivate: [AuthGuard]},
  {path: 'studio-cash-report/:studio', component: StudioCashReport2Component, canActivate: [AuthGuard], data: {permission: 'StudioCashReport'}},
  {path: 'studio-cash-report-2/:studio', component: StudioCashReport2Component, canActivate: [AuthGuard], data: {permission: 'StudioCashReport'}},
  {path: 'whats-app', component: WhatsappExtensionComponent, canActivate: [AuthGuard]},
  {path: 'add-contact', component: AddContactComponent, canActivate: [AuthGuard]},
  {path: 'inventory', component: InventoryListComponent, canActivate: [AuthGuard]},
  {path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuard]},
  {path: 'logout', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {path: 'event-finder', component: EventFinderComponent, canActivate: [AuthGuard]},
  {path: 'studio-fee', redirectTo: 'studio-fee/', pathMatch: 'full', data: {permission: 'StudioFee'}},
  {path: 'studio-fee/:month', component: StudioFeeComponent, canActivate: [AuthGuard], data: {permission: 'StudioFee'}},
  {path: 'year-finish', component: YearFinishComponent, canActivate: [AuthGuard]},
  {path: 'artist-edit', component: ArtistEditComponent, canActivate: [AuthGuard]},
  {path: 'artists', component: ArtistsComponent, canActivate: [AuthGuard]},
  {path: 'brudi-tv', component: BrudiTvComponent},
  {path: 'brudi-tv-dashboard', component: BrudiTvDashboardComponent, canActivate: [AuthGuard]},
  {path: 'invoice-builder-pdf', component: InvoiceBuilderComponent, canActivate: [AuthGuard]},
  {path: 'users', component: UsersComponent, canActivate: [AuthGuard]},
  {path: 'users/:userId', component: UsersComponent, canActivate: [AuthGuard]},
  {path: 'invoice-form', component: InvoiceFormComponent, canActivate: [AuthGuard]},
  {path: 'web-form-submits', component: WebFormSubmitsComponent, canActivate: [AuthGuard]},
  {path: 'qr', component: WhatsAppQrComponent, canActivate: [AuthGuard]},
  {path: 'subscription-test', component: SubscriptionTestComponent, canActivate: [AuthGuard]},
  {path: 'web-form-submit-print/:id', component: WebFormSubmitPrintComponent, canActivate: [AuthGuard], data: {hideVersionInfo: true}},
  {path: 'not-assigned-payments', component: NotAssignedPaymentsComponent, canActivate: [AuthGuard]},
  {path: 'whats-app-latest-messages', component: WhatsAppChatsComponent, canActivate: [AuthGuard]},
  {path: 'discount-promotions', component: DiscountPromotions2Component, canActivate: [AuthGuard]},
  {path: 'discount-promotions-2', component: DiscountPromotions2Component, canActivate: [AuthGuard]},
  {path: 'admin', component: AdminComponent, canActivate: [AuthGuard], data: {permission: 'Admin'}},
  {path: 'chat/:id', component: WhatsappChatComponent, canActivate: [AuthGuard]},
  {path: 'qr-test', component: QrCodeComponent, canActivate: [AuthGuard]},
  {path: 'search', component: SearchComponent, canActivate: [AuthGuard]},
  {path: 'promo', component: DiscountAnalysisComponent, canActivate: [AuthGuard]},
  {path: 'roman', component: RomanNumeralsComponent, canActivate: [AuthGuard]},
  {path: 'pre-payout', component: PrePayoutComponent, canActivate: [AuthGuard]},
  {path: 'broadcast', component: WhatsappBroadcastComponent, canActivate: [AuthGuard], data: {permission: 'WhatsAppBroadcast'}},
  {path: 'events-view', component: EventsViewComponent, canActivate: [AuthGuard]},
  {path: 'articles', component: ArticlesComponent, canActivate: [AuthGuard]},
  // {path: 'events-sort', component: EventsSortComponent, canActivate: [AuthGuard]},
  // {path: 'av', component: ArtistSpotEditComponent, canActivate: [AuthGuard]},
  {path: 'pb', component: PiercingChangeBookComponent, canActivate: [AuthGuard]},
  {path: 'pu', component: ArtistPhotoUnknownUploadsComponent, canActivate: [AuthGuard]},
  {path: 'wis', component: WhatsappImageSendComponent, canActivate: [AuthGuard]},
  {path: 'wt', component: WhatsappFastAnswersComponent, canActivate: [AuthGuard]},
  {path: 'current-artist-photo', component: CurrentArtistPhotoComponent, canActivate: [AuthGuard]},
  {path: 'win', component: DrawComponent, canActivate: [AuthGuard]},
  {path: 'pbuild', component: PriceBuilderPreImageComponent, canActivate: [AuthGuard]},
  {path: 'text-templates', component: TextTemplatesComponent, canActivate: [AuthGuard]},
  {path: 'e-history', component: CalendarEventHistoryComponent, canActivate: [AuthGuard]},
  {path: 'i-history', component: InventoryItemHistoryComponent, canActivate: [AuthGuard]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'piercing-manager', component: PiercingManagerComponent, canActivate: [AuthGuard]},
  {path: 'reminders', component: RemindersComponent, canActivate: [AuthGuard]},
  {path: 'day-finish-log', component: DayFinishLogComponent, canActivate: [AuthGuard]},
  {path: 'promoters', component: PromoUsersComponent, canActivate: [AuthGuard]},
  {path: 'whatsapp-insta-responses', component: WhatsappInstaResponsesComponent, canActivate: [AuthGuard]},
  {path: 'contacts-same-mobile', component: ContactsSameMobileComponent, canActivate: [AuthGuard]},
  {path: 'print-gift-cards', component: PrintGiftCardsComponent, canActivate: [AuthGuard]},
  {path: 'ah', component: ArtistHistoryComponent, canActivate: [AuthGuard]},
  {path: 'n-a-b-t', component: NotAssignedBankTransactionsComponent, canActivate: [AuthGuard]},
  {path: 'contacts', component: ContactsComponent, canActivate: [AuthGuard]},
  {path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard], data: {permission: 'Calendar', title: 'Kalender'}},
  {path: 'date-select', component: DateSelectComponent, canActivate: [AuthGuard]},
  {path: 'klarna-orders', component: KlarnaOrdersComponent, canActivate: [AuthGuard]},
  {path: 'discount-gift-cards', component: DiscountGiftCardsComponent, canActivate: [AuthGuard]},
  {path: 'test', component: TestComponent},
  {path: 'employees', component: EmployeesComponent, canActivate: [AuthGuard]},
  {path: 'employee-calendar', component: EmployeeCalendarComponent, canActivate: [AuthGuard]},
  {path: 'events-rating', component: EventsRatingComponent, canActivate: [AuthGuard]},
  {path: 'events-rating/artist/:artist', component: EventsRatingComponent, canActivate: [AuthGuard]},
  {path: 'events-rating/:eventId', component: EventsRatingComponent, canActivate: [AuthGuard]},
  {path: 'messenger', component: MessengerComponent, canActivate: [AuthGuard]},
  {path: 'employee-works', component: EmployeeWorksComponent, canActivate: [AuthGuard]},
  {path: 'money-accounts', component: MoneyAccountsComponent, canActivate: [AuthGuard]},
  {path: 'consent-check', component: ConsentCheckComponent, canActivate: [AuthGuard]},
  {path: 'klarna-disputes', component: KlarnaDisputesComponent, canActivate: [AuthGuard]},
  {path: 'artist-ratings', component: ArtistRatingsComponent, canActivate: [AuthGuard]},
  {path: 'video', component: VideoComponent, canActivate: [AuthGuard]},
  {path: 'icloud', component: IcloudComponent, canActivate: [AuthGuard]},
  {path: 'photos', component: PhotosComponent, canActivate: [AuthGuard]},
  {path: 'photo-editor', component: PhotoEditorComponent, canActivate: [AuthGuard]},
  {path: 'photo-editor/:file-id', component: PhotoEditorComponent, canActivate: [AuthGuard]},
  {path: 'event-filter', component: EventFilterComponent, canActivate: [AuthGuard]},
  {path: 'font-extractor', component: FontExtractorComponent, canActivate: [AuthGuard]},
  {path: 'fonts', component: FontsComponent, canActivate: [AuthGuard]},
  {path: 'drive-migration', component: DriveMigrationComponent, canActivate: [AuthGuard]},
  {path: 'telegram-studio-social-media-start', component: TelegramStudioSocialMediaStartChatComponent, canActivate: [AuthGuard]},
  {path: 'map', component: MapComponent, canActivate: [AuthGuard]},
  {path: 'chats-to-recheck', component: ChatsToRecheckComponent, canActivate: [AuthGuard]},
  {path: 'qr-code-generator', component: QrCodeGeneratorComponent, canActivate: [AuthGuard]},
  {path: 'monitor', component: MonitorComponent, canActivate: [AuthGuard]},
  {path: 'transaction-labels', component: BankTransactionLabelsComponent, canActivate: [AuthGuard]},
  {path: 'ib-promotions', component: IbPromotionsComponent, canActivate: [AuthGuard]},

  {path: 'bank-transactions', component: BankTransactionsComponent, canActivate: [AuthGuard], data: {title: 'Transaktionen', permission: 'Bank_Transactions'}},
  {path: 'bank-transaction', component: BankTransactionDetailsComponent, canActivate: [AuthGuard], data: {title: 'Transaktion', permission: 'Bank_Transactions'}},
  {path: 'bank-transaction/:transactionId', component: BankTransactionDetailsComponent, canActivate: [AuthGuard], data: {title: 'Transaktion', permission: 'Bank_Transactions'}},
  {path: 'bank-documents', component: BankDocumentsComponent, canActivate: [AuthGuard], data: {title: 'Dokumente', permission: 'Bank_Documents'}},
  {path: 'bank-document/:documentId', component: BankDocumentDetailsComponent, canActivate: [AuthGuard], data: {title: 'Dokument', permission: 'Bank_Documents'}},
  {path: 'bank-document', component: BankDocumentDetailsComponent, canActivate: [AuthGuard]},
  {path: 'bank-booking-accounts', component: BankBookingAccountsComponent, canActivate: [AuthGuard]},

  {path: 'bt/:shortId', component: BankTransactionDetailsComponent, canActivate: [AuthGuard], data: {title: 'Transaktion', permission: 'Bank_Transactions'}},
  {path: 'bd/:shortId', component: BankDocumentDetailsComponent, canActivate: [AuthGuard], data: {title: 'Dokument', permission: 'Bank_Documents'}},

  {path: 'tt-test', component: TattooTicketSelectForPaymentComponent, canActivate: [AuthGuard]},

  {path: 'multi-view-2', component: MultiView2Component, canActivate: [AuthGuard], data: {hideVersionInfo: true}},
  {path: 'multi-view', component: MultiViewComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'declarations-of-consent', component: DeclarationsOfConsentComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'declarations-of-consent-piercing', component: DeclarationsOfConsentPiercingComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'declarations-of-consent-tooth-gem', component: DeclarationsOfConsentToothGemComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'after-care-instruction-piercing', component: AfterCareInstructionPiercingComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'after-care-instruction-tattoo', component: AfterCareInstructionTattooComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'after-care-instruction-tooth-gem', component: AfterCareInstructionToothGemComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'xx', component: EventsInfosComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'event-qr/:eventId', component: EventQr2Component, data: {disableSocket: false, hideVersionInfo: false}},
  {path: 'event-qr-2/:eventId', component: EventQr2Component, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'event-qr-2/:model/:secret', component: EventQr2Component, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'event-qr-ok', component: EventQrOkComponent, data: {disableSocket: false, hideVersionInfo: false}},
  {path: 'invoice-extern-studio-pdf', component: InvoiceExternStudioPdfComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'invoice-2-pdf', component: Invoice2PdfComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'voucher-pdf', component: VoucherPdfComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'tattoo-ticket-pdf', component: TattooTicketPdfComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'inventory-to-order-print', component: InventoryPrintComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'gift', component: GiftCardGeneratorComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'invoice-from-invoice-form-pdf', component: InvoiceFromInvoiceFormPdfComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'julian', component: JulianStartComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'logo', component: LogoComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'receipt', component: ReceiptComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'day-finish-report-2', component: DayFinishReport2Component, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'header-double-block', component: HeaderDoubleBlockComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'artists-links', component: ArtistsGalleryLinksComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'p/:studio/:id', component: PaypalCheckoutComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'k/:studio/:id', component: KlarnaCheckoutComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'to-whatsapp', component: ToWhatsappComponent, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'p/:id', component: PromoQrComponent, data: {disableSocket: true, hideVersionInfo: true}},


  {path: 'pdf-invoice-3', component: PdfInvoice3Component, data: {disableSocket: true, hideVersionInfo: true}},
  {path: 'pdf-day-finish-report-2', component: DayFinishReport2Component, data: {disableSocket: true, hideVersionInfo: true}},


  {path: 'lazy', loadComponent: () => import('./lazy/lazy.component').then(mod => mod.LazyComponent), data: {disableSocket: true, hideVersionInfo: true}},
];
