import {NxtBankDocument, NxtBankDocumentChangeKey, NxtBankDocumentStatus, NxtBankDocumentType} from '../../common-interfaces/bank/bank-transaction.interface';
import {keys} from './object.tools';
import {MathTools} from './math.tools';

export class BankDocumentTools {

  private static _status: NxtBankDocumentStatus[] = [];
  private static _types: NxtBankDocumentType[] = [];
  private static statusLoaded = false;
  private static typesLoaded = false;

  static get types() {
    if (!BankDocumentTools.typesLoaded) {
      BankDocumentTools.loadTypes();
      BankDocumentTools.typesLoaded = true;
    }
    return BankDocumentTools._types;
  }

  static getNextStatus(status: NxtBankDocumentStatus): NxtBankDocumentStatus {
    if (status === NxtBankDocumentStatus._90_ok) {
      return NxtBankDocumentStatus._90_ok;
    }
    if (!BankDocumentTools.statusLoaded) {
      BankDocumentTools.loadStatus();
    }
    const index = BankDocumentTools._status.indexOf(status);
    return BankDocumentTools._status[index + 1];
  }

  private static loadStatus() {
    for (const status of keys(NxtBankDocumentStatus)) {
      BankDocumentTools._status.push(NxtBankDocumentStatus[status]);
    }
    BankDocumentTools.statusLoaded = true;
  }

  private static loadTypes() {
    for (const type of keys(NxtBankDocumentType)) {
      BankDocumentTools._types.push(NxtBankDocumentType[type]);
    }
    BankDocumentTools.statusLoaded = true;
  }

  public static getDocumentNumbers(document: NxtBankDocument) {
    const documentNumbers: string[] = [];
    if (document.documentNumber) {
      documentNumbers.push(document.documentNumber);
    }
    if (document.nxtAi?.invoice?.numbers) {
      documentNumbers.push(...document.nxtAi.invoice.numbers);
    }
    return documentNumbers;
  }


  public static calcAssignValue(document: NxtBankDocument) {
    if (document.nxtManual?.assignValueOk) {
      document.assignValue = 0;
    } else if (typeof document.nxtAi?.invoice?.valueGross !== 'number') {
      document.assignValue = null;
    } else {
      document.assignValue = 0;
      if (document.nxtAi?.invoice) {
        document.assignValue = document.nxtAi.invoice.valueGross.abs();
      }
      for (const transaction of document.assignedTransactions) {
        document.assignValue -= transaction.value.abs();
      }
      if (document.assignValue < 0.02) {
        document.assignValue = 0; // zwischen abbuchung und rechnung gibts mal ein cent unterschied
      }
      document.assignValue = MathTools.roundMoney(document.assignValue);
    }
  }

  public static hasManualChange(document: NxtBankDocument, key: NxtBankDocumentChangeKey) {
    return document.nxtAi?.manualChanges?.some(c => c.changes.some(ch => ch.key === key));
  }

  public static containsInDocumentNumbers(bankDocument: NxtBankDocument, textParts: string[]) {
    const documentNumbers = BankDocumentTools.getDocumentNumbers(bankDocument).map(d => d.toLowerCase());
    for (const textFromTransaction of textParts) {
      if (documentNumbers.includes(textFromTransaction.trimChar(','))) {
        return true;
      }
    }
    return false;
  }

  static getOtherCompany(bankDocument: NxtBankDocument) {
    if (bankDocument.nxtAi) {
      return bankDocument.direction === 'in' ? bankDocument.nxtAi?.senderCompany : bankDocument.nxtAi?.receiverCompany;
    }
    return {
      name: '',
      datevNo: '',
    };
  }

  static getTypeText(documentType: NxtBankDocumentType) {
    switch (documentType) {
      case NxtBankDocumentType.NoInvoice:
        return 'keine Rechnung';
      case NxtBankDocumentType.Invoice:
        return 'Rechnung';
      case NxtBankDocumentType.Receipt:
        return 'Quittung';
      case NxtBankDocumentType.SpkMonthStatement:
        return 'Spk-Monatsauszug';
      case NxtBankDocumentType.Unknown:
        return 'Unbekannt';
      case NxtBankDocumentType.InvoiceShouldSplit:
        return 'muss aufgeteilt werden';
      case NxtBankDocumentType.DeliveryNote:
        return 'Lieferschein';
      case NxtBankDocumentType.Unset:
        return 'noch nicht gesetzt';
      case NxtBankDocumentType.KlarnaSettlementReport:
        return 'Klarna Abrechnung';
      case NxtBankDocumentType.PaypalFee:
        return 'Paypal Gebühren';
    }
    return documentType;
  }

  static getStatusText(status: NxtBankDocumentStatus) {
    switch (status) {
      case NxtBankDocumentStatus._00_download:
        return 'Download';
      case NxtBankDocumentStatus._02_ocr:
        return 'Texterkennung';
      case NxtBankDocumentStatus._04_documentType:
        return 'Typisierung';
      case NxtBankDocumentStatus._06_documentAi:
        return 'Dokument AI';
      case NxtBankDocumentStatus._08_split:
        return 'Aufteilen';
      case NxtBankDocumentStatus._10_nxtAi:
        return 'NXT AI';
      case NxtBankDocumentStatus._12_checkDuplicates:
        return 'Doppelterkennung';
      case NxtBankDocumentStatus._14_merge:
        return 'Zusammenführen';
      case NxtBankDocumentStatus._20_spk_month_statement:
        return 'Sparkassen-Monatsauszug';
      case NxtBankDocumentStatus._30_transaction:
        return 'Zahlung zuordnen';
      case NxtBankDocumentStatus._90_ok:
        return 'OK';
    }
    return status;
  }

  public static addManualChanges(document: NxtBankDocument, username: string, changes: {
    key: NxtBankDocumentChangeKey,
    keyText: string,
    oldValue: string | number,
    newValue: string | number
  }[]) {
    if (!document.nxtAi) {
      document.nxtAi = {
        receiverCompany: {name: '', datevNo: ''},
        senderCompany: {name: '', datevNo: ''},
      };
    }
    if (!document.nxtAi.manualChanges) {
      document.nxtAi.manualChanges = [];
    }
    document.nxtAi.manualChanges.push({
      by: username,
      at: Date.now(),
      changes,
    });
  }
}
