import {Injectable} from '@angular/core';
import {SocketService} from '../socket.service';
import {GlobalStudioShortName} from '../../../common-interfaces/global-studio.interface';
import {
  BankTransactionLabel,
  NxtBankBookingAccount, NxtBankBookingAccountCompany,
  NxtBankCompany,
  NxtBankConfigAccount,
  NxtBankConfigAccountType,
  NxtBankDatevExportLine,
  NxtBankTransaction,
} from '../../../common-interfaces/bank/bank-transaction.interface';
import {SocketInterface, SocketInterfaceResponse} from '../../../common-interfaces/socket/socket-interface';

@Injectable({
  providedIn: 'root',
})
export class BankSubSocketService {
  /*** Injections ***/

  constructor(private socketService: SocketService) {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  private async emit(eventName: string, data?: any): Promise<any> {
    return this.socketService.emit('bank.' + eventName, data);
  }

  async setTransactionNeedInvoice(transactionId: string, value: boolean) {
    return this.emit('setTransactionNeedInvoice', {transactionId, value});
  }

  async setTransactionMissingInvoice(transactionId: string, value: boolean) {
    return this.emit('setTransactionMissingInvoice', {transactionId, value});
  }

  async setTransactionNeedTransaction(transactionId: string, value: boolean) {
    return this.emit('setTransactionNeedTransaction', {transactionId, value});
  }

  async setTransactionToCustomerDeposit(id: string, studio: GlobalStudioShortName) {
    return this.emit('setTransactionToCustomerDeposit', {id, studio});
  }

  async getAccounts(): Promise<NxtBankConfigAccount[]> {
    return this.emit('getAccounts');
  }

  async getCompanies(): Promise<NxtBankCompany[]> {
    return this.emit('getCompanies');
  }

  public setTransactionAi(ai: SocketInterface.SetTransactionAi) {
    return this.emit('setTransactionAi', ai);
  }

  async getDatevExportData(transaction: NxtBankTransaction): Promise<{ exportLines: NxtBankDatevExportLine[], documentIds: string[] }> {
    return this.emit('getDatevExportData', transaction);
  }

  async startDatevExport(monthString: string, company: string, onlyTest: boolean) {
    return this.emit('startDatevExport', {monthString, company, onlyTest});
  }

  public async setExportExported(monthString: string, company: string) {
    return this.emit('setExportExported', {monthString, company});
  }

  async transactionFinder(filter: SocketInterface.TransactionFinder): Promise<NxtBankTransaction[]> {
    return this.emit('transactionFinder', filter);
  }

  async getBankTransactions(data: SocketInterface.GetBankTransactions): Promise<SocketInterfaceResponse.GetBankTransactions> {
    return this.emit('getBankTransactions', data);
  }

  public setDocumentAssignValueOk(documentId: string) {
    return this.emit('setDocumentAssignValueOk', documentId);
  }

  public setDocumentOwnCompanyNotRequired(documentId: string) {
    return this.emit('setDocumentOwnCompanyNotRequired', documentId);
  }

  public async startEditBankDocument(documentId: string): Promise<string> {
    return this.emit('startEditBankDocument', documentId);
  }

  public endEditBankDocument(documentId: string, googleDriveFileId: string) {
    return this.emit('endEditBankDocument', {documentId, googleDriveFileId});
  }

  async getBookingAccounts(): Promise<NxtBankBookingAccount[]> {
    return this.emit('getBookingAccounts');
  }

  async runBankAndPayPalImport() {
    return this.emit('runBankAndPayPalImport');
  }


  async transferAllToMain(type: 'qonto' | 'spk', company: 'GBX GmbH', username: string) {
    return this.emit('transferAllToMain', {type, company, username});
  }

  createTransfer(accountId: string, iban: string, name: string, text: string, value: number, username: string) {
    return this.emit('createTransfer', {accountId, iban, name, text, value, username});
  }

  async getBankAccount(type: NxtBankConfigAccountType[]) {
    return this.emit('getBankAccount', {type});
  }

  async oposDocumentExport(company: NxtBankBookingAccountCompany) {
    return this.emit('oposDocumentExport', company);
  }

  async upsertTransactionLabels(labels: BankTransactionLabel[]) {
    return this.emit('upsertTransactionLabels', labels);
  }

  async getTransactionLabels() {
    return this.emit('getTransactionLabels');
  }
}
