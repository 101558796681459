import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtCashPayment} from '../../../common-interfaces/nxt.cash-payment.interface';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NgIf} from '@angular/common';

@Component({
    selector: 'nxt-not-assigned-bank-transactions',
    templateUrl: './not-assigned-bank-transactions.component.html',
    styleUrls: ['./not-assigned-bank-transactions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, NxtDatagridComponent]
})

export class NotAssignedBankTransactionsComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  outgoingsColDefs: NxtColDef<NxtCashPayment>[] = [
    {headerName: '', checkboxSelection: true},
    {headerName: 'Datum', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDateTime},
    {headerName: 'Text', field: 'description', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Ersteller', field: 'createdBy', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Betrag', field: 'value', nxtFieldType: NxtFieldType.Money},
  ];
  data: { notAssignedOutgoings: NxtCashPayment[]; notAssignedAtms: any[] };

  constructor(
    private cdRef: ChangeDetectorRef,
    private socketService: SocketService,
  ) {
    super();
  }

  ngOnInit() {
    this.load();
  }

  nxtOnDestroy() {

  }

  private async load() {
    // this.data = await this.socketService.getNotAssignedBankTransfer();

    this.data.notAssignedOutgoings.push(...this.data.notAssignedOutgoings);
    this.cdRef.detectChanges();
  }
}
