<div class="flex h-full flex-col text-sm">
  <div fxLayout="column" fxLayoutAlign="center center">
    <table class="nxt-table">
      <tr [ngClass]="endOfDayValue() < 0 ? 'red' : 'green'">
        <td>N</td>
        <td>{{ endOfDayValue() | money: true: false: true }}</td>
      </tr>
      <tr [ngClass]="endOfDayValue_() < 0 ? 'red' : 'green'">
        <td>T</td>
        <td>{{ endOfDayValue_() | money: true: false: true }}</td>
      </tr>
    </table>
  </div>
  <div class="flex h-full flex-col overflow-auto">
    <div fxLayout="column" *ngIf="eventsToPostpone().length > 0" class="p-3" fxLayoutAlign="center">

      <nxt-slide-toggle [(value)]="showAlreadyPayoutArtists" nxtPermission="IsJulian"></nxt-slide-toggle>

      <table class="nxt-table">
        <tr>
          <th colspan="11" class="uppercase">
            <div class="flex flex-row items-center justify-between">
              @if (showAllEventsToPostpone) {
                <div>ALLE TERMINE</div>
              } @else {
                <div>LAUFENDE & ARTIST SCHON WEG</div>
              }

              <nxt-button [smallButton]="true" iconLeft="brightness_auto" iconRight="brightness_auto" (click)="autoPostponeClicked()">AUTO Verschieben
              </nxt-button>
              <nxt-slide-toggle *ngIf="false" [(value)]="showAllEventsToPostpone" (valueChange)="calcEventsCanPostpone()">Zeige alle Termine
              </nxt-slide-toggle>
            </div>
          </th>
        </tr>
        <tr>
          <td class="center">T</td>
          <td class="center" colspan="2">Artist</td>
          <td class="center">Fotos</td>
          <td class="center">Bew.</td>
          <td class="hidden center md:table-cell">Kunde</td>
          <td class="center">Preis</td>
          <td class="center">Bezahlt</td>
          <td class="center">Zeit</td>
          <td class="center">Info</td>
          <td class="center">Versch</td>
        </tr>
        <tr *ngFor="let event of eventsToPostpone()" [class]="event.cssClass">
          <td>
            <div class="flex h-full w-full items-center justify-center">
              <mat-icon class="cursor-pointer" (click)="openEventClicked(event.id)" style="font-size: 18px; height: 18px; width: 18px">edit</mat-icon>
            </div>
          </td>
          <td>{{ event.artist }}</td>
          <td>{{ event.artistPercentage | nxtRound: 0 }}% ➞ {{ event.artistTotalGet | money2 }}</td>
          <td>
            @if (event.workType === 'tattoo') {
              @if (event.improve) {
                @if (event.preTattooCount === 0) {
                  <div class="red">kein Vorher-Foto</div>
                }
              } @else {
                @if (event.stencilCount === 0) {
                  <div class="red">kein Stencil-Foto</div>
                }
              }

              @if (event.tattooCount === 0) {
                <div class="red">kein Tattoo-Foto</div>
              }
            }
          </td>
          <td [class.red]="event.ratingValue < 5">{{ event.ratingValue }}</td>
          <td class="hidden md:table-cell">{{ event.customerObj?.fullName }}</td>
          <td>{{ event.visibility === 'private' ? 'T' : '' }} {{ EventTools.getPriceText(event) }}</td>
          <td>{{ event.paymentSum | money2 }}</td>
          <td>{{ event.start | nxtDate: 'dd.MM.yyyy HH:mm' }} - {{ event.end | nxtDate: 'HH:mm' }}</td>
          <td [nxtMultiClick]="3" (nxtOnMultiClick)="enablePostPoneHack(event)" [innerHTML]="event.postponeInfo | safeHtml"></td>
          <td>
            <div fxLayout="row" fxLayoutAlign="center">
              @if (event.canPostpone) {
                <nxt-slide-toggle [(value)]="event.postpone" (valueChange)="eventPostponeChanged()"></nxt-slide-toggle>
              } @else {
              }
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div fxLayout="row" *ngIf="artistReduces" class="p-3" fxLayoutAlign="center">
      <table class="nxt-table">
        <tr>
          <th colspan="7" class="uppercase">
            <div>Artist-Auszahlungen kürzen</div>
          </th>
        </tr>
        <tr *ngFor="let artistReduce of artistReduces">
          <td [innerHTML]="artistReduce.artist | safeHtml"></td>
          <td>
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="text-80">{{ artistReduce.eventCount }} Termine</div>
              <div>{{ artistReduce.payoutSum | money }}</div>
            </div>
          </td>
          <td class="text-80">
            @if (artistReduce.toReduce === 0) {
              <div [innerHTML]="artistReduce.text | safeHtml"></div>
            } @else {
              <nxt-button
                [smallButton]="true"
                iconLeft="brightness_auto"
                iconRight="brightness_auto"
                (click)="autoReduceArtistClicked(artistReduce)">{{ artistReduce.text }}
              </nxt-button>
            }
          </td>
          <td *ngFor="let event of artistReduce.events">
            <div
              (click)="showEventClicked(event.eventId)"
              class="mouse-pointer"
              fxLayout="column"
              fxLayoutAlign="center center">
              <div [class]="event.isPrivate ? 'green' : 'orange'">Tresor: {{ event.isPrivate ? 'JA' : 'NEIN' }}</div>
              <div class="text-80">{{ event.customerName }}</div>
              <div>{{ event.text }}</div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div fxLayout="row" fxLayoutAlign="center">
      <table class="nxt-table" *ngIf="canNotFinishReasons().length > 0">
        <tr>
          <th colspan="3" class="uppercase red">
            Noch zu erledigen
          </th>
        </tr>
        <tr *ngFor="let reason of canNotFinishReasons()">
          <td [innerHTML]="reason.text | safeHtml" class="red">
          </td>
          <td *ngIf="reason.refType === 'event'" class="red">
            <div (click)="openEventClicked(reason.refId)" class="mouse-pointer">Termin öffnen</div>
          </td>
          @if (reason.type === 'missing-event-rating') {
            <td class="cursor-pointer" (click)="eventRatingClicked(reason.refId)">
              bewerten
            </td>
          }
        </tr>
      </table>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" class="gap-3 pt-3">
      <nxt-button (click)="cancelClicked()">Zurück</nxt-button>
      <nxt-button (click)="onlyPostPone()">Verschobene Termine speichern</nxt-button>
      <nxt-button *ngIf="canUnblock()" (click)="unblockClicked()">Abrechnung freigeben</nxt-button>
    </div>
  </div>
</div>
