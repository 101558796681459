import {ChangeDetectionStrategy, Component, effect, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {AutocompleteComponent} from '../../form-controls/autocomplete/autocomplete.component';
import {NxtBankCompany, NxtBankTransaction, NxtBankTransactionType} from '../../../common-interfaces/bank/bank-transaction.interface';
import {FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {NxtTypedFormControl} from '../../../nxt-form/nxt.typed-form-control';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {MatDialogRef} from '@angular/material/dialog';
import {BankTransactionTools} from '../../../common-browser/helpers/bank-transaction.tools';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {FormTools} from '../../../services/form.tools';
import {DialogService} from '../../../services/dialog.service';

@Component({
  selector: 'nxt-bank-company-select',
  templateUrl: './bank-company-select.component.html',
  styleUrls: ['./bank-company-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AutocompleteComponent,
    NxtButtonComponent,
    MatButtonToggle,
    MatButtonToggleGroup,
    ReactiveFormsModule,
  ],
  standalone: true,
})

export class BankCompanySelectComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor() {
    super();
    effect(() => {
      // FormTools.setValidators(this.form.controls.company, this.companiesFiltered().length > 0 ? [ValidatorTools.hasProperty('name')] : []);
    });
  }

  public transaction = signal<NxtBankTransaction>(null);

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  companies = signal<NxtBankCompany[]>([]);
  companiesFiltered = signal<NxtBankCompany[]>([]);
  types = signal<{ text: string, value: NxtBankTransactionType }[]>([]);

  /*** Injections ***/
  private socketService = inject(SocketService);
  private dialogRef = inject(MatDialogRef);
  private dialogService = inject(DialogService);

  /*** Injections ***/

  /*** Variables ***/
  form = new FormGroup({
    type: new NxtTypedFormControl<{ text: string, value: NxtBankTransactionType } | null>(null, [ValidatorTools.hasProperty('text')], 'Typ'),
    company: new NxtTypedFormControl<NxtBankCompany>(null, [ValidatorTools.hasProperty('name')], 'Gegenkonto'),
    assignType: new NxtTypedFormControl<'invoice' | 'transaction' | 'none' | null>(null, [Validators.required], 'Benötigt'),
  });
  headerText = signal('');


  async ngOnInit() {
    const companies = await this.socketService.bank.getCompanies();
    this.companies.set(companies);
    this.types.set(BankTransactionTools.types.filter(t => {
      return !['error', 'unknown', 'unset', 'paypal', 'paypal-credit', 'internal-transfer'].includes(t);
    }).map(t => ({
      text: BankTransactionTools.getTypeText(t as NxtBankTransactionType),
      value: t as NxtBankTransactionType,
    })));
    this.form.valueChanges.subscribe(data => {
      if (data.type) {
        this.companiesFiltered.set(this.companies().filter(c => c.type === data.type.value));
      }
      console.log(data);
      console.log(data);
    });
    this.form.controls.type.valueChanges.subscribe(data => {
      if (data?.value && data.value !== this.transaction().type) {
        this.form.controls.assignType.setValue(this.setDefaultAssignTypeByTransactionType(data.value));
        this.form.controls.company.setValue(null);
      }
    });
    this.initDone();
  }

  nxtOnDestroy() {
  }

  async loadFromTransaction(transaction: NxtBankTransaction) {
    this.transaction.set(transaction);
    await this.waitForInit();
    let assignType: 'invoice' | 'transaction' | 'none' | null = null;
    if (transaction.nxtAi) {
      if (transaction.nxtAi.needTransaction) {
        assignType = 'transaction';
      } else if (transaction.nxtAi.needInvoice) {
        assignType = 'invoice';
      } else if (transaction.nxtAi.needInvoice === false && transaction.nxtAi.needTransaction === false) {
        assignType = 'none';
      }
    }

    this.form.setValue({
      type: this.types().find(t => t.value === transaction.type) || null,
      company: this.companies().find(c => c.name === transaction.nxtAi?.datevOther?.name) || null,
      assignType,
    }, {emitEvent: false});
    this.companiesFiltered.set(this.companies().filter(c => c.type === this.form.value.type?.value));
  }

  displayCompany = (company: NxtBankCompany) => {
    if (company) {
      return company.name;
    }
    return '';
  };

  displayType = (type: { text: string, value: NxtBankTransactionType }) => {
    if (type) {
      return BankTransactionTools.getTypeText(type.value);
    }
    return '';
  };

  public cancelClicked() {
    this.dialogRef.close(false);
  }


  public async saveClicked() {
    if (FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      await this.socketService.bank.setTransactionAi({
        transactionId: this.transaction().id,
        type: this.form.value.type.value,
        needTransaction: this.form.value.assignType === 'transaction',
        needInvoice: this.form.value.assignType === 'invoice',
        otherCompany: this.form.value.company,
      });
      this.dialogRef.close(true);
    }
  }

  private setDefaultAssignTypeByTransactionType(transactionType: NxtBankTransactionType): 'invoice' | 'transaction' | 'none' {
    if (transactionType) {
      if (['customer-ar', 'supplier-ap'].includes(transactionType)) {
        return 'invoice';
      }
      if (['internal-transfer'].includes(transactionType)) {
        return 'transaction';
      }
      return 'none';
    }
  }
}
