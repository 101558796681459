import {Component, Optional} from '@angular/core';
import {RomanTools} from '../../common-browser/helpers/roman.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {InputComponent} from '../form-controls/input/input.component';
import {DatePickerComponent} from '../form-controls/date-picker/date-picker.component';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-roman-numerals',
    templateUrl: './roman-numerals.component.html',
    styleUrls: ['./roman-numerals.component.scss'],
    imports: [FlexModule, NgIf, NxtButtonIconComponent, DatePickerComponent, InputComponent, NxtButtonComponent, NxtDatePipe]
})
export class RomanNumeralsComponent {
  date = '';
  romanNumber = '';
  romanNumberConverted = 0;
  romanDateGerman = '';
  romanDateUs = '';
  decimalNumber: number = null;
  decimalNumberConverted = '';
  showInputs = true;

  constructor(
    @Optional() public dialogRef: MatDialogRef<RomanNumeralsComponent>,
  ) {
  }


  romanNumberChanged(ev: any) {
    this.romanNumber = ev.target.value;
    this.romanNumberConverted = RomanTools.parseRomanToInt(this.romanNumber);
  }

  dateChanged(value: string) {
    const dateArray = value.split('-');
    if (dateArray.length > 1) {
      this.date = value;
      const year = RomanTools.parseIntToRoman(dateArray[0]);
      const month = RomanTools.parseIntToRoman(dateArray[1]);
      const day = RomanTools.parseIntToRoman(dateArray[2]);
      this.romanDateGerman = day + '.' + month + '.' + year;
      this.romanDateUs = year + '-' + month + '-' + day;
    } else {
      this.romanDateGerman = '';
      this.romanDateUs = '';
    }
  }

  romanDateChanged() {

  }


  fixRoman() {


  }

  decimalNumberChanged() {
    if (typeof this.decimalNumber === 'number') {
      if (this.decimalNumber > 9999) {
        this.decimalNumber = 9999;
      }
      this.decimalNumberConverted = RomanTools.parseIntToRoman(this.decimalNumber);
    } else {
      this.decimalNumberConverted = '';
    }
  }

  print() {
    this.showInputs = false;
    requestAnimationFrame(() => {
      window.print();
      this.showInputs = true;
    });
    /*setTimeout(() => {
      this.showInputs = true;
    }, 3000);*/
  }
}
