import {StringTools} from './string.tools';

export enum VersionCompareResult {
  Same,
  Version1Higher,
  Version2Higher
}

export class VersionTools {


  static compare(version1: string, version2: string): VersionCompareResult {
    const version1Number = VersionTools.versionToNumber(version1);
    const version2Number = VersionTools.versionToNumber(version2);
    if (version1Number === version2Number) {
      return VersionCompareResult.Same;
    } else if (version1Number > version2Number) {
      return VersionCompareResult.Version1Higher;
    }
    return VersionCompareResult.Version2Higher;
  }

  static versionToNumber(version: string) {
    const versionParts = version.split('.');
    if (versionParts.length !== 3) {
      throw Error(version + ' is not a valid Version');
    }
    const versionString = StringTools.fill(versionParts[0], 3, '0', false)
      + StringTools.fill(versionParts[1], 3, '0', false)
      + StringTools.fill(versionParts[2], 5, '0', false);
    return parseInt(versionString, 10);
  }
}
