import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {NxtNgConfigRuntime} from '../../../common-interfaces/nxt.ng-config.interface';
import {NxtKlarnaCheckoutLinkForCustomer} from '../../../common-interfaces/klarna.interface';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {SlideToggleComponent} from '../../../components/form-controls/slide-toggle/slide-toggle.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

declare const Klarna;

@Component({
    selector: 'nxt-klarna-checkout',
    templateUrl: './klarna-checkout.component.html',
    styleUrls: ['./klarna-checkout.component.scss'],
    imports: [FlexModule, NgClass, ExtendedModule, NgIf, SlideToggleComponent, NxtButtonComponent, MoneyPipe]
})

export class KlarnaCheckoutComponent implements OnInit, AfterViewInit {
  useSandbox = true;
  value: number;
  status: 'selectPaymentMethod' | 'payed' = 'selectPaymentMethod';
  private captureId = '';
  checkoutData: NxtKlarnaCheckoutLinkForCustomer;
  private linkId = '';
  public studio: 'ac' | 'ffm' | 'ab' | 'br';
  public agbOk = false;

  // public message = '';
  public footerText = '';

  private ngConfig: NxtNgConfigRuntime = (window as any).ngConfig;
  public logoSrc = '';
  private klarnaIsReady = new BehaviorSubject(false);


  constructor(
    private route: ActivatedRoute,
    private httpClient: HttpClient,
  ) {
    (window as any).klarnaAsyncCallback = () => {
      // This is where you start calling Klarna's JS SDK functions
      //
      this.klarnaIsReady.next(true);
    };
  }

  async waitForKlarnaReady() {
    return new Promise<void>((resolve, reject) => {
      if (this.klarnaIsReady.value) {
        resolve();
      } else {
        this.klarnaIsReady.subscribe((value) => {
          if (value) {
            resolve();
          }
        });
      }
    });
  }

  async ngOnInit() {
    this.studio = this.route.snapshot.params.studio;
    this.logoSrc = '/assets/logo/studio/logo_' + this.studio + '_white.svg';
    this.linkId = this.route.snapshot.params.id;
    this.checkoutData = await this.getCheckoutData();
    this.loadScript();
    this.waitForKlarnaReady().then(() => {
      Klarna.Payments.init({
        client_token: this.checkoutData.clientToken
      });
      this.loadKlarna();
    });
    this.footerText = this.checkoutData.studioAddress;
  }

  loadKlarna() {
    requestAnimationFrame(() => {
      try {
        const data = {
          container: '#klarnaPaymentsContainer',
          // payment_method_categories: 'pay_now', // this.checkoutData.paymentMethodCategories,
        };
        const result = Klarna.Payments.load(data);
        console.log(result);
      } catch (err) {
        debugger;
      }
    });
  }


  public loadScript() {
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://x.klarnacdn.net/kp/lib/v1/api.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  private async getCheckoutData() {
    return {
      clientToken: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjgyMzA1ZWJjLWI4MTEtMzYzNy1hYTRjLTY2ZWNhMTg3NGYzZCJ9.eyJzZXNzaW9uX2lkIjoiZTNiZTA0NWMtNzJmMS01NDE4LTkxOTQtYjY1Y2EzOGM2Y2ZlIiwiYmFzZV91cmwiOiJodHRwczovL2pzLnBsYXlncm91bmQua2xhcm5hLmNvbS9ldS9rcCIsImRlc2lnbiI6ImtsYXJuYSIsImxhbmd1YWdlIjoiZGUiLCJwdXJjaGFzZV9jb3VudHJ5IjoiREUiLCJlbnZpcm9ubWVudCI6InBsYXlncm91bmQiLCJtZXJjaGFudF9uYW1lIjoiWW91ciBidXNpbmVzcyBuYW1lIiwic2Vzc2lvbl90eXBlIjoiUEFZTUVOVFMiLCJjbGllbnRfZXZlbnRfYmFzZV91cmwiOiJodHRwczovL2V1LnBsYXlncm91bmQua2xhcm5hZXZ0LmNvbSIsInNjaGVtZSI6dHJ1ZSwiZXhwZXJpbWVudHMiOlt7Im5hbWUiOiJrcGMtUFNFTC0zMDk5IiwidmFyaWF0ZSI6InZhcmlhdGUtMSJ9LHsibmFtZSI6ImtwLWNsaWVudC11dG9waWEtcG9wdXAtcmV0cmlhYmxlIiwidmFyaWF0ZSI6InZhcmlhdGUtMSJ9LHsibmFtZSI6ImtwLWNsaWVudC11dG9waWEtc3RhdGljLXdpZGdldCIsInZhcmlhdGUiOiJpbmRleCIsInBhcmFtZXRlcnMiOnsiZHluYW1pYyI6InRydWUifX0seyJuYW1lIjoiaW4tYXBwLXNkay1uZXctaW50ZXJuYWwtYnJvd3NlciIsInBhcmFtZXRlcnMiOnsidmFyaWF0ZV9pZCI6Im5ldy1pbnRlcm5hbC1icm93c2VyLWVuYWJsZSJ9fSx7Im5hbWUiOiJrcC1jbGllbnQtdXRvcGlhLXNkay1mbG93IiwidmFyaWF0ZSI6InZhcmlhdGUtMSJ9LHsibmFtZSI6ImtwLWNsaWVudC11dG9waWEtd2Vidmlldy1mbG93IiwidmFyaWF0ZSI6InZhcmlhdGUtMSJ9LHsibmFtZSI6ImluLWFwcC1zZGstY2FyZC1zY2FubmluZyIsInBhcmFtZXRlcnMiOnsidmFyaWF0ZV9pZCI6ImNhcmQtc2Nhbm5pbmctZW5hYmxlIn19XSwicmVnaW9uIjoiZXUiLCJ1YV9lbmFibGVkX2FuZF9vbmVfcG0iOnRydWUsInZlcnNpb24iOiJ2MS4xMC4wLTE1OTAtZzNlYmMzOTA3In0.Hv3b61Wh9qZLlOagoCQXj6m_2qyVDG1916cihnLTVWBz7Ay1uyC01FFaVM2jwD8vuKrjcYZFt4OrP_0DMojjoT_zkGtNC1w5YnsNOLMwXia3TWKeMMnZrKxQOq5sIz8nU_cxfyP5Q842lOTok5hxHRmD-Bg7uRQrCk-Ht1N_i_buWDvo6ugQvseXkRpICN4KKwC2NdGqiTPGGEMtWP2h2R9kUlmm8yOkp1YRhUnmLfWZHJRr_GLEd3FDDsTwyqblGrNq792vEhuohgueOPXPQKyefLtr7PzagJxvRwQVI-l0ZWXZDjrlyRSdlBlCTJwbBa_mSSJQghR3jGmh9lt9lw',
      lineItemName: 'Termin-Kaution',
      lineItemRef: 'Testprodukt',
      id: '123',
      name: 'Harry Fullsleeve',
      text: 'Tatto dies das',
      value: 5,
      alreadyPayed: false,
      studioAddress: 'dies das bla bla',
    };

    if (window.location.origin.includes('localhost') && false) {
      const result = await firstValueFrom(this.httpClient.get('http://localhost:444/get-paypal-checkout-data?id=' + this.linkId));
      return result as NxtKlarnaCheckoutLinkForCustomer;
    } else {
      const result = await firstValueFrom(this.httpClient.get('https://' + this.studio + '.nxt-lvl.ink/get-paypal-checkout-data?id=' + this.linkId));
      return result as NxtKlarnaCheckoutLinkForCustomer;
    }
  }

  private async sendCheckoutDataToServer(body) {
    debugger;
    if (window.location.origin.includes('localhost')) {
      const result = await firstValueFrom(this.httpClient.post('http://localhost:444/paypal-checkout-done', body));
    } else {
      const result = await firstValueFrom(this.httpClient.post('https://' + this.studio + '.nxt-lvl.ink/paypal-checkout-done', body));
    }
  }

  public async ngAfterViewInit() {

  }

  authorize() {
    const valueCent = Math.round(this.checkoutData.value * 100);
    Klarna.Payments.authorize({
      purchase_country: 'DE',
      purchase_currency: 'EUR',
      locale: 'de-DE',
      order_amount: valueCent,
      order_tax_amount: 0,
      order_lines: [{
        name: this.checkoutData.lineItemName,
        quantity: 1,
        unit_price: valueCent,
        tax_rate: 0,
        total_amount: valueCent,
        total_tax_amount: 0,
        type: 'physical',
        reference: this.checkoutData.lineItemRef,
        quantity_unit: 'pcs',
        total_discount_amount: 0,
      }],
      customer: {
        date_of_birth: '1970-01-01',
      },
    }, (res) => {
      debugger;
      if (res.approved) {
        this.status = 'payed';
        this.sendCheckoutDataToServer(res);
      } else {
        // this.sendCheckoutDataToServer(res);
        // alert('Das hat leider nicht geklappt');
      }
    });
  }


  agbOkChanged() {
    if (this.agbOk) {
      setTimeout(() => {
        document.querySelector('#klarnaPaymentsContainer').scrollIntoView({behavior: 'smooth'});
      }, 200);
    }
  }
}
