import {Injectable} from '@angular/core';
import {AppComponent} from '../app.component';
import {SocketService} from './socket/socket.service';
import {LoginService} from './login.service';
import {DurationTools} from '../common-browser/helpers/duration.tools';
import {DialogService} from './dialog.service';
import {ElectronService} from './electron.service';
import {UuidTools} from '../common-browser/helpers/uuid.tools';
import {WindowTools} from '../common-browser/window.tools';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private lastCheck = 0;
  private isEnabledRestartOnFrozen = false;
  private id: string;

  constructor(
    private socketService: SocketService,
    private loginService: LoginService,
    private dialogService: DialogService,
    private electronService: ElectronService
  ) {
    this.id = UuidTools.generate();
    setInterval(() => {
      this.check();
    }, 5000);
  }

  private idleStart = 0;

  private isEnabledRestartOnIdle = false;

  private lastMouseCounter = 0;

  public enableRestartOnIdle() {
    this.isEnabledRestartOnIdle = true;
  }

  public disableRestartOnIdle() {
    this.isEnabledRestartOnIdle = false;
  }

  public enableRestartOnFrozen() {
    this.isEnabledRestartOnFrozen = true;
  }

  public disableRestartOnFrozen() {
    this.isEnabledRestartOnFrozen = false;
  }

  private check() {
    // console.log('idle check ' + new Date().toTimeString());
    if (this.lastCheck > 0) {
      const diff = Date.now() - this.lastCheck;
      if (diff > DurationTools.DURATION_1MINUTE) {
        this.wasFrozen(diff);
      }
    }
    this.lastCheck = Date.now();


    if (AppComponent.mouseMoveCounter === this.lastMouseCounter) {
      if (this.idleStart === 0) {
        this.idleStart = Date.now();
      }
    } else {
      this.idleStart = 0;
    }

    if (this.isEnabledRestartOnIdle && this.idleStart > 0 && Date.now() - this.idleStart > 30 * DurationTools.DURATION_1MINUTE) {
      this.socketService.sendTelegramAdmin(this.loginService.getUsername() + ' ist seit 30 Minuten idle -> reload\n' + window.location.href);
      setTimeout(() => {
        WindowTools.reload('idle');
      }, 3000);
    }
  }

  private wasFrozen(frozenSince: number) {
    if (this.isEnabledRestartOnFrozen) {
      this.dialogService.showOk('Frozen-Reload');
      this.socketService.sendTelegramAdmin(this.id + '\n' + this.loginService.getUsername() + ' is frozen (' + frozenSince + ') -> reload\n' + window.location.href);
      setTimeout(() => WindowTools.reload('wasFrozen'), 1000);
    }
  }
}
