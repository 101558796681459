import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgFor} from '@angular/common';

@Component({
    selector: 'nxt-rate-icons',
    template: `
      <div class="flex flex-row"><img *ngFor="let icon of icons" [src]="icon"/></div>
    `,
    styles: [`
      img {
        width: 16px
      }
    `],
    imports: [NgFor]
})
export class RateIconsComponent implements OnInit, OnChanges {

  icons: string[] = [];

  @Input() value: number;
  @Input() minValue = 0;
  @Input() icon: 'star' | 'heart' | 'euro';

  @Input() showEmpty = true;
  @Input() showHalf = true;
  @Input() drawFirst = true;

  constructor() {
  }

  ngOnInit(): void {

  }

  draw() {
    this.icons = [];
    const valueToCalc = this.value >= this.minValue ? this.value : this.minValue;
    for (let i = 0; i < 5; i++) {
      let src = '/assets/images/grid-cell-renderer/';
      if (valueToCalc >= i + 1) {
        src += this.icon + '.svg';
      } else if (valueToCalc >= i + 0.5) {
        if (this.showHalf) {
          src += this.icon + '_half.svg';
        } else {
          src += this.icon + '.svg';
        }
      } else {
        src += this.icon + '_border.svg';
        if (!this.showEmpty) {
          if (i === 0 && this.drawFirst) {
          } else {
            continue;
          }
        }
      }
      this.icons.push(src);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.draw();
  }
}
