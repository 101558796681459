import {ChangeDetectionStrategy, Component, inject, OnInit, Optional, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../services/socket/socket.service';
import {NgIf} from '@angular/common';
import {NxtContact, NxtContactPartial, ParsedContactWithMatches} from '../../common-interfaces/nxt.contact.interface';
import {CacheService} from '../../services/cache/cache.service';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {FromNowPipe} from '../../pipes/from-now.pipe';
import {MatDialogRef} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {IntervalTools} from '../../common-browser/helpers/interval.tools';
import {CdkDrag, CdkDragEnd, CdkDragMove} from '@angular/cdk/drag-drop';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {SpinnerComponent} from '../spinner/spinner.component';


export interface ContactParserComponentDialogClose {
  action: 'useContact' | 'createContact';
  contact?: NxtContact;
  parsedContact?: NxtContactPartial;
  id: string;
}

@Component({
  selector: 'nxt-contact-parser',
  templateUrl: './contact-parser.component.html',
  styleUrls: ['./contact-parser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NxtDatePipe, NxtButtonComponent, FromNowPipe, MatIcon, CdkDrag, SafeHtmlPipe, SpinnerComponent],
})
export class ContactParserComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  parsedContacts = signal<ParsedContactWithMatches[]>([]);

  now = signal<number>(Date.now());

  /*** Injections ***/
  socketService = inject(SocketService);
  cacheService = inject(CacheService);
  private refreshInterval: any;
  highlightBoxId: string;


  constructor(
    @Optional() private dialogRef: MatDialogRef<ContactParserComponent, ContactParserComponentDialogClose>,
  ) {
    super();
  }

  ngOnInit() {
    this.pushSubscription = this.cacheService.parsedContacts.subscribe((parsedContacts: ParsedContactWithMatches[]) => {
      if (parsedContacts.length === 0) {
        this.dialogRef.close();
      } else {
        this.parsedContacts.set(parsedContacts.sortNumber('createdAt'));
      }
    });
    this.refreshInterval = setInterval(() => {
      this.parsedContacts.update(parsedContacts => {
        return parsedContacts.filter(parsedContact => !parsedContact.isLoading || parsedContact.createdAt > Date.now() - 10000);
      });
    }, 10000);
  }

  nxtOnDestroy() {
    IntervalTools.clear(this.refreshInterval);
  }

  setData(parsedContacts: ParsedContactWithMatches) {
  }


  cancelClicked() {
    this.dialogRef.close();
  }

  deleteParsedContactClicked(parsedContact: ParsedContactWithMatches) {
    this.socketService.deleteParsedContact(parsedContact.id).then();
  }

  dragEnded(parsedContact: ParsedContactWithMatches, $event: CdkDragEnd, attribute: string) {
    const elements = document.elementsFromPoint($event.dropPoint.x, $event.dropPoint.y);
    let resetDrag = true;
    for (const elem of elements) {
      if (elem.id) {
        if (elem.classList.contains('contact-card')) {
          if (elem.id !== parsedContact.id) {
            console.log(attribute + ' | ' + parsedContact.id + ' -> ' + elem.id);
            this.socketService.swapParsedContactAttribute(parsedContact.id, elem.id, attribute).then();
            resetDrag = false;
          }
        }
      }
    }
    if (resetDrag) {
      $event.source._dragRef.reset();
    }
    this.highlightBoxId = '';
  }

  dragMoved(parsedContact: ParsedContactWithMatches, $event: CdkDragMove) {
    this.highlightBoxId = '';
    const elements = document.elementsFromPoint($event.pointerPosition.x, $event.pointerPosition.y);
    for (const elem of elements) {
      if (elem.id) {
        if (elem.classList.contains('contact-card')) {
          if (elem.id !== parsedContact.id) {
            this.highlightBoxId = elem.id;
          }
        }
      }
    }
  }

  createContactClicked(parsedContactWithMatches: ParsedContactWithMatches) {
    this.dialogRef.close({action: 'createContact', parsedContact: parsedContactWithMatches.parsedContact, id: parsedContactWithMatches.id});
  }

  useMatchedContact(matchingContact: NxtContact, id: string) {
    this.dialogRef.close({action: 'useContact', contact: matchingContact, id});
  }
}
