import {Router} from '@angular/router';
import {DialogService, LoadingId} from '../../services/dialog.service';
import {CalendarEventEdit2Component} from '../../pages/calendar-event-edit/calendar-event-edit-2/calendar-event-edit-2.component';

export class MenuButtons {

  showLoading = false;


  constructor(
    private router: Router,
    private dialogService: DialogService,
  ) {

  }

  winningGame = {
    text: 'Gewinnspiel',
    icon: 'close',
    click: () => this.router.navigateByUrl('/win')
  };


  jsonData = {
    text: 'Event JSON data',
    icon: 'close',
    click: async () => {
      const dialog = this.dialogService.showComponentFull(CalendarEventEdit2Component);
      // dialog.componentInstance.setJsonData('{"calendarId":"nxt-lvl.ink_ebvb968klre8mj9qcdifrq9jg0@group.calendar.google.com","eventId":"0rq2ufc31q0rt6ne7jiv8g11vs","calendarEvent":{"kind":"calendar#event","etag":"\\"3153352874416000\\"","id":"0rq2ufc31q0rt6ne7jiv8g11vs","status":"confirmed","created":"2019-12-17T16:45:40.000Z","updated":"2019-12-18T13:40:37.208Z","summary":"✔️▪Thorsten Peschel, 300 via Klarna(IST DA), 200 rest, frau mit raben etc","description":"\\n🔴 Kaution: 300 €\\n🔵 Rest: 200 €\\n<a href=\\"https://organizer.nxt-lvl.ink/#/eventedit-eventId/0rq2ufc31q0rt6ne7jiv8g11vs\\">bearbeiten</a>","start":{"dateTime":"2019-12-18T11:00:00+0100"},"end":{"dateTime":"2019-12-18T18:00:00+0100"},"sequence":0,"extendedProperties":{"private":{"images":"[]","priceEstimatedFrom":500,"priceConfirmed":500,"bodyPut":"[\\"arm\\",\\"foreArm\\",\\"inner\\"]","customer":"people/c244473833688776251","payment0":"{\\"paymentComment\\":null,\\"paymentDate\\":\\"2019-12-18\\",\\"paymentMethod\\":\\"cash\\",\\"paymentType\\":\\"rest\\",\\"paymentValue\\":\\"200\\",\\"paymentPaypalTransaction\\":null,\\"paymentKlarnaOrder\\":null,\\"paymentGiftCard\\":null,\\"paymentUuid\\":\\"2410ad10-217e-11ea-9bc4-0b4c5f2d333f\\",\\"createdAt\\":\\"2019-12-18T11:07:04+0100\\"}"}},"calendarId":"nxt-lvl.ink_ebvb968klre8mj9qcdifrq9jg0@group.calendar.google.com"},"newCalendarId":"nxt-lvl.ink_ebvb968klre8mj9qcdifrq9jg0@group.calendar.google.com"}');
    }
  };


  newEvent = {
    text: 'neuer Termin',
    click: () => this.router.navigateByUrl('/eventedit-overrides/%5Bnull,null,%2220191023T040000%22,%2220191023T050000%22,%22ZW1wZmFuZ0BueHQtbHZsLmluaw%22,null,null,%5B%5D,null,null,null,null,null,null,null,null,null,null,null,%5B%5D,null,%5B%5D%5D')
  };

  testEvent = {
    text: 'test Termin',
    click: () => {
      // this.router.navigateByUrl('/eventedit-eventId/3ri214kq1n9ue754gluvgijf3q?hash=' + Date.now());
      // window.location.replace('/#/e/3ri214kq1n9ue754gluvgijf3q?hash=' + Date.now());
      // setTimeout(() => window.location.reload(), 200);
    }
  };

  toggleLoading = {
    text: 'Lade...',
    click: () => {
      if (this.showLoading) {
        this.dialogService.showLoading(LoadingId.Test, 'Kalender wird aktualisiert...');
      } else {
        this.dialogService.hideLoading(LoadingId.Test);
      }
      this.showLoading = !this.showLoading;
    }
  };

  /*switchToTest = {
    text: 'switch to test.nxt-lvl.ink:99',
    click: () => {
      IframeMessageManagerInIframe.instance.send('eval', IframeParentCommand.LoadTestNxtLvlInk);
    }
  };*/

  cashToday = {
    text: 'Kasse heute',
    click: () => {
      const fenster = window.open('http://192.168.5.2:82/api/getPayments', 'Kasse heute', 'width=600,height=400,status=yes,scrollbars=yes,resizable=yes');
      fenster.focus();
    }
  };

  markDublicateContacts = {
    text: 'doppelte Kontakte name markieren',
    click: () => {
      const fenster = window.open('http://192.168.5.2:82/api/getDuplicateContacts', 'doppelte Kontakte', 'width=600,height=400,status=yes,scrollbars=yes,resizable=yes');
      fenster.focus();
    }
  };

  showDoubleContactsPhone = {
    text: 'doppelte Kontakte phone',
    click: () => {
      const fenster = window.open('http://192.168.5.2:82/api/getDuplicateContactsPhone', 'doppelte Kontakte Telefonnummer', 'width=600,height=400,status=yes,scrollbars=yes,resizable=yes');
      fenster.focus();
    }
  };

  bodyPuts = {
    text: 'Körperstellen',
    click: () => {
      const fenster = window.open('http://jsoneditoronline.org/?id=efddcc1c8fb94d5ba7d0f011f25db9f2', 'Körperstellen', 'width=600,height=400,status=yes,scrollbars=yes,resizable=yes');
      fenster.focus();
    }
  };

  bodyPutsTranslate = {
    text: 'Körperstellen übers.',
    click: () => {
      const fenster = window.open('http://jsoneditoronline.org/?id=4aa958fd7311465eb42fd7540f65f28b', 'Körperstellen übers.', 'width=600,height=400,status=yes,scrollbars=yes,resizable=yes');
      fenster.focus();
    }
  };

  showDebugPage = {
    text: 'debug',
    click: () => this.router.navigateByUrl('/debug')
  };
}
