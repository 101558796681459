<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>Dokumente</nxt-page-header-title>
    <div class="flex flex-col items-center">
      <mat-button-toggle-group [(ngModel)]="filterMode" (ngModelChange)="load()">
        <mat-button-toggle value="time">Zeitraum</mat-button-toggle>
        <!--<mat-button-toggle value="todo">Handlungsbedarf</mat-button-toggle>-->
        <mat-button-toggle value="newDocuments">Neuste Dokumente</mat-button-toggle>
      </mat-button-toggle-group>

      @switch (filterMode()) {
        @case ('time') {
          <nxt-date-range-picker [bottomNoPadding]="true" [showJumpMonths]="true" [(value)]="filterDateFromTill" (valueChange)="load()"
                                 placeholder="Von - Bis"/>
          <nxt-slide-toggle class="whitespace-nowrap" [(value)]="filterNotFinished" (valueChange)="load()" placeholder="nur Offene"></nxt-slide-toggle>
        }
        @case ('newDocuments') {
          <nxt-slide-toggle class="whitespace-nowrap" [(value)]="filterNotFinished" (valueChange)="load()" placeholder="nur Offene"></nxt-slide-toggle>
        }
      }

      <!--@if (!filterTodos()) {
        <nxt-date-range-picker [bottomNoPadding]="true" [showJumpMonths]="true" [(value)]="dateFromTill" (valueChange)="load()" placeholder="Von - Bis"/>
      }
      @if (filterTodos()) {
        <nxt-slide-toggle class="whitespace-nowrap" [(value)]="filterOnlyLastMonth" (valueChange)="load()" placeholder="nur letzten Monat"></nxt-slide-toggle>
      }-->
      <!---<nxt-slide-toggle [(value)]="filterTodos" (valueChange)="load()" placeholder="Handlungsbedarf"></nxt-slide-toggle>-->
    </div>
    <nxt-input [(value)]="quickFilterText" placeholder="Suche" [clearIcon]="true"></nxt-input>
    <nxt-button-icon matTooltip="neu Laden" (click)="reloadCLicked()">refresh</nxt-button-icon>
    @if (!multiImporterSate().isRunning) {
      <nxt-button-icon matTooltip="GMI, Mail & Google-Drive" (click)="startMultiImportClicked()">download</nxt-button-icon>
      @if (multiImporterSate().errorMessage) {
        <div class="text-[70%]" (click)="showMultiImportErrorClicked()">letzter Fehler: {{ multiImporterSate().errorMessage }}</div>
      }
    } @else {
      <div class="flex flex-col text-[70%] w-fit whitespace-nowrap">
        <div class="flex flex-row justify-center items-center">
          <nxt-spinner [size]="20"></nxt-spinner>
          <div class="flex flex-col ml-3">
            <div>Import läuft... {{ multiImporterSate().progress + ' %' }}</div>
            <div>{{ multiImporterSate().statusText }}</div>
          </div>
        </div>

      </div>
    }
    <nxt-button-icon matTooltip="RUN" (click)="runClicked()">sprint</nxt-button-icon>
    <nxt-button-icon matTooltip="Transaktionen anzeigen" (mouseup)="showBankTransactionsClicked($event)">checklist</nxt-button-icon>
    <nxt-button-icon matTooltip="Downloadlinks" [matMenuTriggerFor]="downloadLinksMenu">assignment_returned</nxt-button-icon>
    <mat-menu #downloadLinksMenu>
      <button mat-menu-item (click)="downloadLinksClicked('meta')">
        <mat-icon fontSet="material-symbols-outlined">assignment_returned</mat-icon>
        <span>Meta</span>
      </button>
      <button mat-menu-item (click)="downloadLinksClicked('paypal')">
        <mat-icon fontSet="material-symbols-outlined">assignment_returned</mat-icon>
        <span>PayPal</span>
      </button>
    </mat-menu>

  </nxt-page-header>
  <nxt-page-content>
    <nxt-datagrid
      [columnDefs]="columnDefs"
      [rowData]="bankDocuments()"
      [quickFilterText]="quickFilterText()"
      [columnFilters]="true"
      [showFooter]="true"
    >
    </nxt-datagrid>
  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>
