import {inject, Injectable, Injector, runInInjectionContext} from '@angular/core';
import {FirebaseLoginService} from './firebase-login.service';
import {Router} from '@angular/router';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, QueryFn} from '@angular/fire/compat/firestore';
import 'firebase/firestore';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  // isLoggedIn: boolean;


  constructor(
    private angularFirestore: AngularFirestore,
    private router: Router,
    private firebaseLoginService: FirebaseLoginService,
    private injector: Injector
  ) {
    // this.angularFirestore.firestore.se
    // firebase.setLogLevel('debug');
  }


  public collection<T>(path: string, queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return this.angularFirestore.collection(path, queryFn);

    /*if (this.checkLogin()) {

    } else {
      throw new Error('nicht eingeloggt!');
    }*/
  }

  public doc<T>(path: string): AngularFirestoreDocument<T> {
    return this.angularFirestore.doc(path);

    /*if (this.checkLogin()) {

    }
    throw Error('das sollte nicht passieren\nFirestore.service.doc(' + path + ')');
    */
  }

  /*private checkLogin() {

    if (this.isLoggedIn) {
      return true;
    } else {
      // this.router.navigateByUrl('login-new');
      return false;
    }
  }*/

  addUser(user: any): Promise<void> {
    try {
      return this.angularFirestore.doc('users/' + user.uid).set(user);
    } catch (err) {
      console.error('addUser failed ' + err.message, err);
    }
  }

  async test() {
    const users = (await this.angularFirestore.collection('users').get().toPromise()).docs.map(d => d.data());
    console.log(users);
    console.log(users);
    console.log(users);

    runInInjectionContext(this.injector, async () => {
      const angularFirestore = inject(AngularFirestore);
      const users = (await angularFirestore.collection('users').get().toPromise()).docs.map(d => d.data());
      console.log(users);
      console.log(users);
      console.log(users);
    });
  }
}
