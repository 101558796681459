<div class="nxt-page w-full flex flex-col">

  <div>
    header
  </div>
  <div class="flex grow">
    <nxt-datagrid
      [columnDefs]="columnDefs"
      [rowData]="giftCards"
    >

    </nxt-datagrid>
  </div>


</div>
