import {AfterViewInit, Component, NgZone, OnInit, Optional, ViewChild} from '@angular/core';
import {CacheService} from '../../services/cache/cache.service';
import {SocketService} from '../../services/socket/socket.service';
import {NxtComponent, NxtOnDestroy} from '../nxt.component';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {NxtPaymentPossibilityRecord} from '../../common-interfaces/nxt.payment-possibility-record.interface';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {PaymentTools} from '../../common-browser/helpers/payment.tools';
import {map} from 'rxjs/operators';
import {IconTools} from '../../common-browser/helpers/icon.tools';
import {DialogService} from '../../services/dialog.service';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtNotAssignedPayment} from 'src/app/common-interfaces/nxt.payment.interface';
import {PermissionService} from '../../services/permission.service';
import {NxtPermissionId} from '../../common-interfaces/nxt.user.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {DecimalTools} from '../../common-browser/helpers/decimal.tools';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {AsyncPipe, NgIf} from '@angular/common';
import {InputComponent} from '../form-controls/input/input.component';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtKlarnaOrder} from '../../common-interfaces/nxt.klarna-order.interface';
import {NxtPaypalTransaction} from '../../common-interfaces/nxt-paypal-transaction';
import {LoginService} from '../../services/login.service';

@Component({
  selector: 'nxt-not-assigned-payments',
  templateUrl: './not-assigned-payments.component.html',
  styleUrls: ['./not-assigned-payments.component.scss'],
  imports: [FlexModule, MatRadioGroup, MatRadioButton, InputComponent, NgIf, NxtButtonComponent, NxtButtonIconComponent, NxtDatagridComponent, AsyncPipe],
})
export class NotAssignedPaymentsComponent extends NxtComponent implements OnInit, NxtOnDestroy, AfterViewInit {
  @ViewChild(NxtDatagridComponent) dataGrid: NxtDatagridComponent;

  notAssignedPaymentsFiltered$: Subject<NxtNotAssignedPayment[]> = new Subject<NxtNotAssignedPayment[]>();
  notAssignedPayments$: Subject<NxtNotAssignedPayment[]> = new Subject<NxtNotAssignedPayment[]>();
  directionFilter$ = new BehaviorSubject<string>('');
  paymentTypeFilter$ = new BehaviorSubject<string>('');
  quickFilterText = '';
  sourcePayment$ = new BehaviorSubject<NxtNotAssignedPayment>(null);

  columnDefs: NxtColDef<NxtNotAssignedPayment>[] = [
    {headerName: 'Datum', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDateTime, minWidth: 150, maxWidth: 150},
    {
      headerName: 'Zahlart',
      field: 'paymentType',
      nxtFieldType: NxtFieldType.Text,
      valueFormatter: (params) => PaymentTools.getPaymentTypeText(params.value),
      minWidth: 100,
      maxWidth: 100,
    },
    {headerName: 'Richtung', field: 'direction', nxtFieldType: NxtFieldType.Text, minWidth: 100, maxWidth: 100},
    {headerName: 'Name', field: 'name', nxtFieldType: NxtFieldType.Text, minWidth: 300, maxWidth: 300},
    {headerName: 'Info', field: 'info', nxtFieldType: NxtFieldType.Text, minWidth: 300, maxWidth: 300},
    {headerName: 'Betrag', field: 'value', nxtFieldType: NxtFieldType.Money, minWidth: 100, maxWidth: 100, cellStyle: {textAlign: 'right'}},
    {
      headerName: 'ID', field: 'value', nxtFieldType: NxtFieldType.Text, valueGetter: (params) => {
        return params.data.id;
      }, hide: !this.loginService.isJulian(),
    },
    {
      colId: 'select-source', headerName: 'Zuweisen', field: 'value', nxtFieldType: NxtFieldType.Text,
      valueGetter: (param) => IconTools.Material.Logout,
      nxtOnCellClicked: (param) => this.startSelectDestination(param.data),
      cellStyle: {textAlign: 'center'},
      minWidth: 110, maxWidth: 110,
      hide: true,
    },
    {
      colId: 'select-destination', headerName: 'Zuweisen', field: 'value', nxtFieldType: NxtFieldType.Text,
      valueGetter: (param) => IconTools.Material.Login,
      nxtOnCellClicked: (param) => this.setDestination(param.data),
      cellStyle: {textAlign: 'center'},
      hide: true,
      minWidth: 110, maxWidth: 110,
    },
    {
      colId: 'delete', headerName: 'Löschen', field: 'value', nxtFieldType: NxtFieldType.Text,
      valueGetter: (param) => {
        return IconTools.Material.Delete;
      },
      nxtOnCellClicked: (param) => {
        this.delete(param.data as NxtNotAssignedPayment);
      },
      cellStyle: {textAlign: 'center'},
      hide: !this.permissionService.hasPermission(NxtPermissionId.NotAssignedPayments_Delete),
      minWidth: 100, maxWidth: 100,
    },
  ];


  constructor(
    private cacheService: CacheService,
    private socketService: SocketService,
    private ngZone: NgZone,
    private dialogService: DialogService,
    private permissionService: PermissionService,
    private loginService: LoginService,
    @Optional() public dialogRef: MatDialogRef<any>,
  ) {
    super();
    this.registerData();
    combineLatest([this.notAssignedPayments$, this.directionFilter$, this.paymentTypeFilter$])
      .pipe(map(this.filter)).subscribe(this.notAssignedPaymentsFiltered$);

  }

  ngAfterViewInit(): void {
    this.dataGrid.gridIsReady.subscribe(() => {
      this.sourcePayment$.next(null);
    });

  }

  ngOnInit(): void {
    this.sourcePayment$.subscribe((value) => {
      if (this.permissionService.hasPermission(NxtPermissionId.NotAssignedPayments_Assign)) {
        this.dataGrid?.api?.setColumnsVisible(['select-source'], !value);
        this.dataGrid?.api?.setColumnsVisible(['select-destination'], !!value);
      }
    });
  }


  nxtOnDestroy(): void {
  }

  private registerData() {
    this.pushSubscription = combineLatest([
      this.socketService.getObservable('getNotAssignedBankTransactions'),
      this.socketService.getObservable('getNotAssignedKlarnaOrders'),
      this.socketService.getObservable('getNotAssignedPaypalTransactions'),
    ]).subscribe(([bank, klarna, paypal]) => {
      const result: NxtNotAssignedPayment[] = [];
      result.push(...bank.map(p => this.parseBankPayment(p)));
      result.push(...klarna.map(p => this.parseKlarnaPayment(p)));
      result.push(...paypal.map(p => this.parsePaypalPayment(p)));
      this.notAssignedPayments$.next(result.sortNumber('createdAt'));
    });
  }

  private parseBankPayment(nxtPaymentPossibility: NxtPaymentPossibilityRecord): NxtNotAssignedPayment {
    return {
      paymentType: 'Bank',
      value: nxtPaymentPossibility.value,
      paymentValue: nxtPaymentPossibility.paymentValue,
      name: nxtPaymentPossibility.additionalInfo,
      direction: nxtPaymentPossibility.direction,
      createdAt: DateTools.parse(nxtPaymentPossibility.createdAt),
      id: nxtPaymentPossibility.id,
      info: nxtPaymentPossibility.additionalInfo2,
    };
  }

  private parseKlarnaPayment(nxtKlarnaOrder: NxtKlarnaOrder): NxtNotAssignedPayment {
    return {
      paymentType: 'Klarna',
      value: nxtKlarnaOrder.value,
      paymentValue: nxtKlarnaOrder.paymentValue,
      name: nxtKlarnaOrder.fullName,
      direction: nxtKlarnaOrder.direction === 'out' ? 'out' : 'in',
      createdAt: DateTools.parse(nxtKlarnaOrder.createdAt),
      id: nxtKlarnaOrder.orderId,
      info: '',
    };
  }

  private parsePaypalPayment(nxtPaypalTransaction: NxtPaypalTransaction): NxtNotAssignedPayment {
    return {
      paymentType: 'PayPal',
      value: nxtPaypalTransaction.value,
      paymentValue: nxtPaypalTransaction.value,
      name: nxtPaypalTransaction.from,
      direction: nxtPaypalTransaction.direction,
      createdAt: DateTools.parse(nxtPaypalTransaction.transactionTime),
      id: nxtPaypalTransaction.transactionId,
      info: nxtPaypalTransaction.transactionNote,
    };
  }

  trigger(s: string) {
    this.socketService.triggerSubscription(s);
  }

  private filter([notAssignedPayments, direction, paymentType]: [NxtNotAssignedPayment[], string, string]) {
    return notAssignedPayments
      .filter(notAssignedPayment => !direction || notAssignedPayment.direction === direction)
      .filter(notAssignedPayment => !paymentType || notAssignedPayment.paymentType === paymentType);
  }

  private async startSelectDestination(data: NxtNotAssignedPayment) {
    const result = await this.dialogService.showYesNoCancel('Wähle nun die Gegenzahlung aus', {
      yesText: 'Gegenzahlung wählen',
      noText: 'Es gibt keine Gegenzahlung',
      cancelText: 'Abbrechen',
    });
    if (typeof result === 'boolean') {
      if (result) {
        if (data.direction === 'in') {
          this.directionFilter$.next('out');
        } else {
          this.directionFilter$.next('in');
        }
        this.dataGrid.api.setColumnsVisible(['assign'], false);
        this.sourcePayment$.next(data);
      } else {
        const buttons: { value: string, text: string }[] = [{value: null, text: 'Abbrechen'}];
        if (data.direction === 'in') {
          buttons.push({value: 'withheld-deposit', text: 'Einbehaltene Kaution'});
        } else {
          buttons.push({value: 'repayment-goodwill', text: 'Rückzahlung aus Kulanz'});
        }
        const reason = await this.dialogService.showButtons('Grund', {buttons});
        if (reason?.value) {
          const info = await this.dialogService.showTextarea({message: 'Bitte schreibe noch eine Info dazu', prompt: reason.text + '\n'});
          if (info) {
            await this.socketService.assignOpenPayment(data, reason.value, info);
            this.directionFilter$.next('');
          }
        }
      }
    }
  }

  public cancelAssignment() {
    this.sourcePayment$.next(null);
    this.directionFilter$.next('');
  }

  private async setDestination(destPayment: NxtNotAssignedPayment) {
    const sourcePayment = this.sourcePayment$.getValue();
    const valueToAssign = await this.dialogService.showInput('Betrag', {
      okButtonText: 'Zuweisen',
      isMoney: true,
      prompt: sourcePayment.value < destPayment.value ? sourcePayment.value : destPayment.value,
    });

    if (valueToAssign !== null) {
      if (valueToAssign > sourcePayment.value || valueToAssign > destPayment.value) {
        this.dialogService.showOk('So viel geht nicht du Otto 🤦🏽‍♂️');
        return;
      }

      const incoming = sourcePayment.direction === 'in' ? sourcePayment : destPayment;
      const outgoing = sourcePayment.direction === 'out' ? sourcePayment : destPayment;

      const lines: string[] = [];

      const incomingText = incoming.paymentType +
        '\n' + DateTools.format(incoming.createdAt, 'dd.MM.yyyy HH:mm') +
        '\n' + incoming.name;

      const outgoingText = outgoing.paymentType +
        '\n' + DateTools.format(outgoing.createdAt, 'dd.MM.yyyy HH:mm') +

        '\n' + outgoing.name;

      const text = '<table><tr><td style="text-align: center; padding-bottom: 20px; font-size: 130%" colspan="3">' + DecimalTools.toMoneyString(valueToAssign) + '</td></tr><tr><td>' + incomingText + '</td><td class="icon-size-3">&nbsp;&nbsp;' + IconTools.Material.ArrowRight + '&nbsp;&nbsp;</td><td>' + outgoingText + '</td></tr>';
      if (await this.dialogService.showYesNo(text, {yesText: 'Ja, zuweisen!', noText: 'Abbrechen'})) {
        if (await this.dialogService.showYesNo('<div style="font-size: 150%">Check es nochmal!</div><br/>' + text, {yesText: 'Ja, zuweisen!', noText: 'Abbrechen'})) {
          let paymentValueToAssign = valueToAssign;
          if (sourcePayment.paymentValue && destPayment.paymentValue) {
            const discountSource = sourcePayment.paymentValue / sourcePayment.value;
            const discountDest = destPayment.paymentValue / destPayment.value;
            if (discountSource !== discountDest) {
              this.dialogService.showOk('Die Zahlungen haben unterschiedlichen Rabatt, das geht nicht\nInfo an Julian');
              return;
            }
            paymentValueToAssign = valueToAssign * discountSource;
          }
          await this.socketService.assignSourceDestPayments(sourcePayment, destPayment, valueToAssign, paymentValueToAssign);
        }
      }
    }
    this.sourcePayment$.next(null);
    this.directionFilter$.next('');
  }

  private async delete(data: NxtNotAssignedPayment) {
    if (await this.dialogService.showYesNo('Willst du die Zahlung wirklich löschen?')) {
      if (await this.dialogService.showYesNo('Wirklich wirklich wirklich löschen?')) {
        if (data.paymentType === 'Bank') {
          // throw Error('geht nicht!');
          await this.socketService.deletePaymentPossibilityRecord(data.id);
        } else if (data.paymentType === 'Klarna') {
          throw Error('geht nicht!');
          // await this.socketService.deleteKlarnaOrder(data.id);
        } else if (data.paymentType === 'PayPal') {
          throw Error('geht nicht!');
          // await this.socketService.deletePaypalTransaction(data.id);
        }
      }
    }
  }
}
