import {inject, Injectable} from '@angular/core';
import {SocketService} from '../socket/socket.service';
import {DialogService} from '../dialog.service';
import {TelegramArtistStartChatComponent} from '../../components/telegram/telegram-artist-start-chat/telegram-artist-start-chat.component';
import {CacheService} from '../cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class TelegramArtistService {
  /*** Injections ***/
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  private cacheService = inject(CacheService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  async startArtistChat(artistId: string) {
    const dialog = this.dialogService.showComponentDialog(TelegramArtistStartChatComponent);
    dialog.componentInstance.setArtist(artistId);
  }
}
