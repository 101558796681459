import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {LoginService} from '../../services/login.service';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {FirestoreService} from '../../services/firestore.service';
import {Subscription} from 'rxjs';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {DialogService} from '../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DateRangePickerComponent} from '../../components/form-controls/date-range-picker/date-range-picker.component';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss'],
    imports: [FlexModule, NgIf, NxtButtonIconComponent, DateRangePickerComponent, NxtButtonComponent, NxtDatagridComponent]
})
export class InvoicesComponent implements OnInit, OnDestroy {
  // data: Observable<any[]>;
  @ViewChild(DateRangePickerComponent) nxtDateRangePicker: DateRangePickerComponent;
  data: any[];
  columnDefs: NxtColDef[] = [];
  private subscription: Subscription;
  dateFromTill: any;
  public dialogRef: MatDialogRef<InvoicesComponent>;

  constructor(
    private socketService: SocketService,
    private loginService: LoginService,
    private firestoreService: FirestoreService,
    private dialogService: DialogService,
  ) {
    this.initColumnDefs();
  }

  async ngOnInit() {
    // this.initData();
    // $('ng-month-picker').find('input').attr('placeholder', 'Monat');

    setTimeout(() => {

      // this.nxtDateRangePicker.setFocus();

      // this.nxtDateRangePicker.select();
      this.nxtDateRangePicker.click();
    }, 1000);


  }

  ngOnDestroy(): void {
  }

  private initColumnDefs() {
    this.columnDefs = [
      {headerName: 'invoiceNumber', field: 'invoiceNumber', nxtFieldType: NxtFieldType.Text},
      {headerName: 'invoiceDate', field: 'invoiceDate', nxtFieldType: NxtFieldType.Date_germanDate, sort: 'asc'},
      {headerName: 'to', field: 'invoiceTo.name', nxtFieldType: NxtFieldType.Text},
      {
        headerName: 'pdf', field: '', nxtFieldType: NxtFieldType.Text, cellRenderer: (params: any) => 'PDF', nxtOnCellClicked: (params) => {
          this.dialogService.showPdf(params.data.invoiceBase64, params.data.invoiceNumber + '.pdf');
        }
      }
    ];
  }

  private async initData() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    const start = new Date(DateTools.parse(this.dateFromTill.from, 'yyyy-MM-dd'));
    const end = new Date(DateTools.parse(this.dateFromTill.till, 'yyyy-MM-dd'));
    /*this.subscription = this.firestoreService.collection('invoice-archive', (ref) => ref.where('invoiceDate', '>=', start).where('invoiceDate', '<=', end)).valueChanges().subscribe((data) => {
      this.data = data;
    });*/
  }

  public findClicked() {
    if (this.checkFilter()) {
      this.initData();
    }
  }

  private checkFilter() {
    if (!this.dateFromTill) {
      this.dialogService.showOk('Bitte wähle einen Monat aus');
      return false;
    }
    return true;
  }

  dateChanged() {

  }
}
