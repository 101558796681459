import {NxtMoneyStack} from '../../../common-interfaces/nxt.money-stack.interface';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {SocketService} from '../../../services/socket/socket.service';
import {DialogService} from '../../../services/dialog.service';
import {LoginService} from '../../../services/login.service';
import {MatDialogRef} from '@angular/material/dialog';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {MoneyStackTools} from '../../../common-browser/helpers/money-stack.tools';
import {ObjectTools} from '../../../common-browser/helpers/object.tools';
import {MathTools} from '../../../common-browser/helpers/math.tools';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {MultiClickDirective} from '../../../directives/multi-click.directive';
import {MoneyCounterComponent} from '../../../components/money-counter/money-counter.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';


@Component({
    selector: 'nxt-cash-money-counter',
    templateUrl: './cash-money-counter.component.html',
    styleUrls: ['./cash-money-counter.component.scss'],
    imports: [FlexModule, NgIf, NgClass, ExtendedModule, MoneyCounterComponent, MultiClickDirective, NxtButtonComponent, MoneyPipe]
})
export class CashMoneyCounterComponent implements OnInit, OnDestroy {
  currentMoneyStack: NxtMoneyStack = {};
  totalMoneyShouldValue = 0;
  colorTools = ColorTools;
  title = '';
  public showShould = true;
  public mustCorrect = false;
  currentMoneyStackValue = 0;
  public suppressValueChange = false;
  public suppressValueChangeErrorMessage = 'Der Wert darf sich nicht ändern';
  startValue = 0;
  public reduceData: { values: { id: string; value: number }[]; otherMoneyStack: NxtMoneyStack };
  moneyStackInfoText = '';
  forceForce = false;
  payoutPrios: string[] = [];

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    public loginService: LoginService,
    private dialogRef: MatDialogRef<CashMoneyCounterComponent>,
  ) {

  }

  async ngOnInit() {

    document.body.style.backgroundColor = 'transparent !important';
    this.initMoneys();
  }

  ngOnDestroy(): void {

  }


  private initMoneys() {
    if (!this.currentMoneyStack || Object.keys(this.currentMoneyStack).length === 0) {
      this.currentMoneyStack = MoneyStackTools.getEmptyMoneyStack();
    }
  }

  getKeys(moneyStack: NxtMoneyStack) {
    return Object.keys(moneyStack).map(key => parseFloat(key)).sort(SortTools.arrayNumbers());
  }

  /*getMoneyClass(money: number) {
    return money < 5 ? 'img-money-small' : 'img-money';
  }*/


  setData(totalMoneyShouldValue: number, title: string, moneyStack?: NxtMoneyStack) {
    this.totalMoneyShouldValue = MathTools.roundMoney(totalMoneyShouldValue);
    if (moneyStack) {
      this.currentMoneyStack = ObjectTools.clone(moneyStack);
      this.startValue = MoneyStackTools.getTotalValueFromMoneyStack(this.currentMoneyStack);
    }
    this.title = title;
    this.currentMoneyStackValue = MoneyStackTools.getTotalValueFromMoneyStack(moneyStack);
    if (this.startValue > 0) {
      this.calcReduceData();
    }
  }

  async save() {
    if (this.reduceData) {
      this.calcReduceData();
      if (this.moneyStackInfoText) {
        return;
      }
    }
    if (this.suppressValueChange && this.startValue !== MoneyStackTools.getTotalValueFromMoneyStack(this.currentMoneyStack)) {
      await this.dialogService.showOk(this.suppressValueChangeErrorMessage + '\n' + this.startValue.toMoneyString());
      return;
    }
    this.dialogRef.close(this.currentMoneyStack);
  }

  close() {
    this.dialogRef.close();
  }

  public moneyStackChanged(moneyStack: NxtMoneyStack) {
    this.currentMoneyStackValue = MoneyStackTools.getTotalValueFromMoneyStack(moneyStack);
    if (this.moneyStackInfoText) {
      this.calcReduceData();
    }
  }

  private calcReduceData() {
    this.moneyStackInfoText = '';
    if (this.reduceData) {
      const combinedMoneyStacks = MoneyStackTools.combineMoneyStacks([this.reduceData.otherMoneyStack, this.currentMoneyStack]);
      const result = MoneyStackTools.reduceMultiFromMoneyStack(combinedMoneyStacks, this.reduceData.values, this.payoutPrios);
      const missingMoneys = [];
      const infoLinesTrs: string[] = [];
      for (const value of result.values) {
        if (value.missingMoney && value.missingMoney > 0) {
          missingMoneys.push(value.missingMoney);
          infoLinesTrs.push('<tr><td class="p-1">' + value.id + '</td><td class="p-1">' + value.missingMoney.toMoneyString() + '</td></tr>');
        }
      }
      if (infoLinesTrs.length > 0 && !this.forceForce) {
        this.moneyStackInfoText = 'Leider kannst du so nicht auszahlen.<br/>Es fehlen dir:<br/><br/><table>' + infoLinesTrs.join('') + '</table>';
      }
    }
  }
}
