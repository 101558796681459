import {v4 as uuidv4} from 'uuid';


export class UuidTools {
    public static generate(): string {
        return uuidv4();
    }

    public static generateNxt(): string {
        return 'nxt' + uuidv4().substring(3);
    }
}
