import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {ConfigService} from '../../../services/config.service';
import {QRCodeComponent} from 'angularx-qrcode';

@Component({
  selector: 'nxt-telegram-studio-social-media-start-chat',
  templateUrl: './telegram-studio-social-media-start-chat.component.html',
  styleUrls: ['./telegram-studio-social-media-start-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtButtonComponent, QRCodeComponent],
  standalone: true,
})

export class TelegramStudioSocialMediaStartChatComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  qrCode = signal<string>('');
  // employee = signal<NxtEmployee | null>(null);


  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private dialogRef = inject(MatDialogRef, {optional: true});
  private configService = inject(ConfigService);


  constructor() {
    super();
  }

  ngOnInit() {
    this.qrCode.set('https://t.me/nxtlvlink_bot?start=' + btoa('StudioSocialMedia:' + this.configService.config.value.studioRegion.toLowerCase()));
  }

  nxtOnDestroy() {
  }


  cancelClicked() {
    this.dialogRef.close();
  }
}
