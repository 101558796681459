import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {interval} from 'rxjs';
import {SocketService} from '../../services/socket/socket.service';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'nxt-whatsapp-screenshot',
    templateUrl: './whatsapp-screenshot.component.html',
    styleUrls: ['./whatsapp-screenshot.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NxtButtonIconComponent,
  ]
})

export class WhatsappScreenshotComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  src = signal('');
  timeText = signal('');

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private dialogRef = inject(MatDialogRef);
  private reloadTimestamp = 0;

  private destroyed = false;

  constructor() {
    super();
    this.pushSubscription = interval(1000).subscribe(() => {
      this.reloadTime();
    });
    this.load();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
    this.destroyed = true;
  }

  async load() {
    try {
      this.src.set(await this.socketService.getWhatsAppScreenshot());
      this.reloadTimestamp = Date.now();
    } catch (err) {
      debugger;
    }
    if (!this.destroyed) {
      setTimeout(() => {
        this.load();
      }, 5000);
    }
  }

  private reloadTime() {
    if (this.src()) {
      this.timeText.set('vor ' + Math.round((Date.now() - this.reloadTimestamp) / 1000) + ' Sekunden');
    }
  }

  closeClicked() {
    this.dialogRef.close();
  }
}
