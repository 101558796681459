import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {DateTools} from '../date.tools';
import {DecimalTools} from '../decimal.tools';
import {DurationTools} from '../duration.tools';
import {TypeTools} from '../type.tools';
import {IconTools} from '../icon.tools';
import {NxtDatagridValueGetters} from './nxt-datagrid-value-getters';

export class NxtDatagridValueFormatters {

  static formatValueByNxtFieldType(value: any, nxtFieldType: NxtFieldType, nxtFieldTypeParams?: any, nxtIcon?: string): string {
    let result = '';
    switch (nxtFieldType) {
      case NxtFieldType.Array:
        result = value.join(nxtFieldTypeParams ? nxtFieldTypeParams : ' | ');
        break;
      case NxtFieldType.Date_germanDateTime:
        result = DateTools.format(value, 'dd.MM.yyyy HH:mm');
        break;
      case NxtFieldType.Date_germanDateTimeFull:
        result = DateTools.format(value, 'dd.MM.yyyy HH:mm:ss.SSSS');
        break;
      case NxtFieldType.Date_germanTime:
        result = DateTools.format(value, 'HH:mm');
        break;
      case NxtFieldType.DurationStringToNow:
        if (!value) {
          result = '';
        } else {
          result = DurationTools.format2(value - Date.now(), nxtFieldTypeParams);
        }
        break;
      case NxtFieldType.Date_germanDateWithToNowText:
        if (!value) {
          result = '';
        } else {
          result = DateTools.format(parseInt(value, 10), 'dd.MM.yy HH:mm') + ' ' + DurationTools.format2(value - Date.now(), nxtFieldTypeParams);
        }
        break;
      case NxtFieldType.Date_germanDate:
        if (value) {
          result = DateTools.format(parseInt(value, 10), 'dd.MM.yyyy');
        } else {
          result = '';
        }
        break;
      case NxtFieldType.Date_germanDateShort:
        result = DateTools.format(parseInt(value, 10), 'dd.MM');
        break;
      case NxtFieldType.Date_germanDateShortWithDayOfWeek:
        result = DateTools.format(parseInt(value, 10), 'E dd.MM');
        break;
      case NxtFieldType.Date_germanDateWithDayOfWeek:
        result = DateTools.format(parseInt(value, 10), 'EE dd.MM.yyyy');
        break;
      case NxtFieldType.Date_germanDateTimeWithDayOfWeek:
        result = DateTools.format(parseInt(value, 10), 'EE dd.MM.yyyy HH:mm');
        break;
      case NxtFieldType.Date_germanDayOfWeek:
        result = value; //  DateTools.format(parseInt(value, 10), 'EEE');
        break;
      case NxtFieldType.Date_germanMonth:
        result = value; // DateTools.format(parseInt(value, 10), 'MMMM yyyy');
        break;
      case NxtFieldType.Date_germanMonthString:
        result = DateTools.format(value, 'MMMM yyyy');
        break;
      case NxtFieldType.Date_germanDateTimeWithSeconds:
        result = DateTools.format(value, 'dd.MM.yyyy HH:mm:ss');
        break;
      case NxtFieldType.Money:
        result = DecimalTools.toMoneyString(value, '€', false);
        break;
      case NxtFieldType.MoneyFull:
        result = DecimalTools.toMoneyString(value, '€', true);
        break;
      case NxtFieldType.Duration:
        result = DurationTools.format(value, 'HH:mm');
        break;
      case NxtFieldType.Percentage:
        if (!TypeTools.isNumber(value)) {
          result = '';
        } else {
          result = Math.round(value) + ' %';
        }
        break;
      case NxtFieldType.Decimal:
        result = DecimalTools.roundToString(value, 2);
        break;

      case NxtFieldType.MoneyOnlyPositiv:
        result = value > 0 ? DecimalTools.toMoneyString(value) : '0,00 €';
        break;
      case NxtFieldType.MoneyOnlyPositivShort:
        result = value > 0 ? DecimalTools.toMoneyString(value, '€', false) : '0 €';
        break;
      case NxtFieldType.Number:
        if (Number.isNaN(value)) {
          result = '';
        } else {
          result = parseFloat(value).toString();
        }
        break;
      case NxtFieldType.PaymentSum:
        result = DecimalTools.toMoneyString(value);
        break;
      case NxtFieldType.ShowCalendarEvent:
        result = value ? IconTools.Material.EditCalendar : '';
        break;
      case NxtFieldType.ShowEventHistory:
        result = value ? IconTools.Material.History : '';
        break;
      case NxtFieldType.ShowContactHistory:
        result = value ? IconTools.Material.History : '';
        break;
      case NxtFieldType.Icon:
        result = nxtIcon || '';
        break;
      case NxtFieldType.ShowImage:
        result = value ? IconTools.Material.Image : '';
        break;
      case NxtFieldType.WhatsAppChat:
        result = value ? IconTools.Material.Chat : '';
        break;
      case NxtFieldType.CalendarEvents:
        if (value?.length > 0) {
          result = value.length.toString();
        }
        break;
      case NxtFieldType.Studio:
        if (value && value.toLowerCase() === 'villa') {
          result = 'Villa';
        } else if (value && value.toLowerCase() === 'brand') {
          result = 'Brand';
        } else {
          result = '';
        }
        break;
      case NxtFieldType.Workplace:
        if (value && value.toLowerCase() === 'backoffice') {
          result = 'Backoffice';
        } else if (value && value.toLowerCase() === 'reception') {
          result = 'Empfang';
        } else {
          result = '';
        }
        break;
      case NxtFieldType.Text:
        result = TypeTools.isNullOrUndefinedOrNAN(value) ? '' : value.toString();
        break;
      case NxtFieldType.Boolean:
        result = value ? 'Ja' : 'Nein';
        break;
      default:
        if (typeof value === 'string') {
          result = value;
        } else if (nxtFieldTypeParams?.getValue) {
          result = nxtFieldTypeParams.getValue();
        } else {
          if (nxtFieldTypeParams && nxtFieldTypeParams.data && nxtFieldTypeParams.colDef.field) {
            result = NxtDatagridValueGetters.getCellValue(nxtFieldTypeParams.data, nxtFieldTypeParams.colDef.field, nxtFieldTypeParams);
          } else {
            result = value;
          }
        }
        break;
    }
    return result;
  }


  public static getValueFormatter() {
    return (params) => {
      let nxtFieldType: NxtFieldType;
      let nxtFieldTypeParams: any;
      let nxtIcon = '';
      if (params.node && params.node.group && !params.colDef.nxtFieldType) {
        nxtFieldType = params.node.rowGroupColumn.colDef.nxtFieldType;
        nxtFieldTypeParams = params.node.rowGroupColumn.colDef.nxtFieldTypeParams;
        nxtIcon = params.node.rowGroupColumn.colDef.nxtIcon;
      } else {
        nxtFieldType = params.colDef.nxtFieldType;
        nxtFieldTypeParams = params.colDef.nxtFieldTypeParams;
        nxtIcon = params.colDef.nxtIcon;
      }
      const value = params.value;
      let result: any;
      result = NxtDatagridValueFormatters.formatValueByNxtFieldType(value, nxtFieldType, nxtFieldTypeParams, nxtIcon);
      return result;
    };
  }


}
