<div class="flex flex-col">
  <div class="flex flex-col items-center">
    <div class="text-130 flex flex-row p-2">{{ artistSpot().artist }}</div>
    <nxt-button (click)="editArtistSpot()">{{ artistSpot().start | nxtDate: 'dd.MM.yyyy' }} - {{ artistSpot().end | nxtDate: 'dd.MM.yyyy' }}</nxt-button>
  </div>
  <div class="flex flex-row p-3">
    @for (color of colors; track color) {
      <div class="flex flex-col">
        @for (colorStep of colorSteps; track colorStep) {
          <div
            class="flex text-60 color-item o-hidden items-center justify-center text-center"
            [style.color]="ColorTools.getLightOrDarkFontColorByBackground(MaterialColors.colors[color][colorStep])"
            [class.current]="currentIndex() === (color + colorStep)"
            (click)="setCurrentColor(color, colorStep)"
            [style.background-color]="MaterialColors.colors[color][colorStep]"
            style="width: 50px; height: 50px; border-radius: 4px; margin: 1px"
          >&nbsp;{{ artistColors()[MaterialColors.colors[color][colorStep]] }}
          </div>
        }
      </div>
    }
  </div>
  <div class="flex flex-row justify-around">
    <nxt-button (click)="canceleClicked()">Abbrechen</nxt-button>
    <nxt-button (click)="saveClicked()">Speichern</nxt-button>
  </div>
</div>
