import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DecimalTools} from '../common-browser/helpers/decimal.tools';
import {MoneyTools} from '../common-browser/helpers/money.tools';

@Pipe({
  name: 'money2',
  standalone: true,
})
export class Money2Pipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, currency?: string): string {
    if (!currency) {
      currency = '€';
    } else {
      currency = MoneyTools.currencyShort(currency);
    }
    return DecimalTools.toMoneyString(value, currency, false);
  }
}
