import * as seedrandom from 'seedrandom';

export class MathTools {
  static test() {
    const result0 = MathTools.roundMoney(19.434);
    console.log(result0);


    const result1 = MathTools.randomFromBasedOn('51.2ax', 3, 4);
    console.log(result1);

    const result2 = MathTools.randomFromBasedOn('1.2', 2, 15);
    console.log(result2);
    const result3 = MathTools.randomFromBasedOn('51.2ax', 1, 2);
    console.log(result3);

    const result4 = MathTools.randomFromBasedOn('51.2abcx', 3, 4);
    console.log(result4);
    const result5 = 2000 * Math.log(1);
    const result6 = 2000 * Math.log(0.5);
    console.log(result5);
    console.log(result5);
    console.log(result6);
    console.log(result6);
  }


  public static randomFromBasedOn(basedOn: string, from: number, till: number) {
    const rangeCount = till - from;
    const value = seedrandom.default(basedOn).double();
    const result = value * rangeCount + from;
    return Math.round(result);
  }

  public static random(from: number, till: number, includeFrom = true, includeTill = true): number {
    if (!includeFrom) {
      from++;
    }
    if (!includeTill) {
      till--;
    }
    const diff = till - from + 1;
    const result = ((Math.random() * diff) + from).toString();
    return parseInt(result, 10);
  }

  public static getRandomFromArray<T>(values: T[]): T {
    return values[MathTools.random(0, values.length - 1)];
  }

  public static getPercentage(part: number, total: number, decimals = 0): number {
    return this.round(part / total * 100);
  }

  public static round(value: number, decimals = 0): number {
    const factor = 10 ** decimals;
    // Umwandlung in eine Zeichenkette mit ausreichender Präzision
    const tempValue = Number((value * factor).toPrecision(15));
    // Rundung und Rückgabe des Ergebnisses
    return Math.round(tempValue) / factor;

    // const multiplier = 10 ** decimals;
    // return Math.round(value *  multiplier) / multiplier;
  }

  public static roundMoney(value: number): number {
    return MathTools.round(value, 2);
  }


  static roundTo(number: number, to: number, roundUp = true): number {
    if (roundUp) {
      return number - (number % to) + 10;
    } else {
      return number - (number % to);
    }
  }
}

declare global {
  interface Number {
    roundMoney(): number;

    round(decimals?: number): number;
  }
}

Object.defineProperty(Number.prototype, 'roundMoney', {
  configurable: true,
  writable: true,
  value(this) {
    return MathTools.roundMoney(this);
  },
});

Object.defineProperty(Number.prototype, 'round', {
  configurable: true,
  writable: true,
  value(this, decimals = 0) {
    return MathTools.round(this, decimals);
  },
});
