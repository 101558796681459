export enum NxtDynamicDataType {
  // DiscountPromotion = 'discountPromotion',
  WhatsAppImageSend = 'WhatsAppImageSend'
}

export interface NxtDynamicDataInsert {
  name: string;
  type: NxtDynamicDataType;
  disabled: boolean;
}

export interface NxtDynamicData {
  id: string;
  name: string;
  type: NxtDynamicDataType;
  disabled: boolean;
  createdAt: number;
  createdBy: string;
  updatedBy: string;
  updatedAt: number;
}


/*export interface NxtDiscountPromotionOldX extends NxtDynamicData {
  discountPercentage: number;
  priceFixValue: number;
  artistGet: number;
  disableDiscountedGiftCards: boolean;
  priceFix: boolean;
  priceLike: boolean;
  additionalPayAfterEnd: boolean;
  maxHours: number;
  onlyWithPromoCode: boolean;
  staticPromoCode: boolean;
  staticPromoCodePromoterId: string;
  staticPromoCodePromoId: string;
}*/

export interface NxtWhatsAppImageSend extends NxtDynamicData {
  whatsAppMessageId: string;
  whatsAppMediaKey: string;
  base64: string;
}

export interface NxtWhatsAppImageSendInsert extends NxtDynamicDataInsert {
  whatsAppMessageId: string;
  whatsAppMediaKey: string;
  base64: string;
}
