import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, CUSTOM_ELEMENTS_SCHEMA, effect, ElementRef, inject, input, OnInit, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {SafeResourceUrlPipe} from '../../pipes/safe-resource-url.pipe';

@Component({
  selector: 'nxt-pdfjs',
  templateUrl: './pdfjs.component.html',
  styleUrls: ['./pdfjs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    NxtButtonIconComponent,
    SafeResourceUrlPipe
  ]
})

export class PdfjsComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  @ViewChild('wrapper') set wrapperElem(elem: ElementRef) {
    this.wrapperDiv = elem?.nativeElement;
    if (elem) {
      this.load();
    }
  }

  constructor() {
    super();
    effect(() => {
      this.src();
      this.load();
    });
  }

  private wrapperDiv: HTMLDivElement;


  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  pdfJsHost = window.location.hostname === 'localhost' ? 'http://localhost:444' : 'https://pdfjs.nxt-lvl.ink';
  iframeSrc = computed(() => {
    return this.pdfJsHost + '/web/viewer.html?file=' + this.src();
  });
  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  src = input.required<string>();
  show = signal(true);


  async load() {
    if (this.wrapperDiv) {
      this.wrapperDiv.innerHTML = '';
      // await TimeTools.sleep(500);
      requestAnimationFrame(async () => {
        const viewer = document.createElement('pdfjs-viewer-element');
        viewer.setAttribute('viewer-path', 'https://pdfjs.nxt-lvl.ink/');
        viewer.setAttribute('src', this.src());
        this.wrapperDiv.appendChild(viewer);
        // await TimeTools.sleep(500);
        requestAnimationFrame(() => {
          this.loadScript().then();
        });
      });
    }
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  async loadScript(): Promise<void> {
    const response = await fetch('/assets/pdfjs-viewer-element.js');
    if (!response.ok) {
      throw new Error(`Script laden fehlgeschlagen: ${response.statusText}`);
    }
    const scriptContent = await response.text();
    const script = document.createElement('script');
    script.type = 'module';
    script.text = scriptContent;
    document.head.appendChild(script);
  }

  public reload() {
    this.show.set(false);
    requestAnimationFrame(() => {
      this.show.set(true);
    });
  }
}
