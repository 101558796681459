@if (klarnaOrderRaw()) {
  @if (showCloseButton) {
    <div class="w-full flex flex-row justify-end pb-3">
      <nxt-button-icon (click)="dialogRef.close()">close</nxt-button-icon>
    </div>
  }

  <table class="nxt-table w-full" [ngClass]="{'nxt-table-no-outer-border': noOuterBorder()}">
    <tr>
      <td>Klarna-ID</td>
      <td>{{ klarnaOrderRaw().order_id }}</td>
    </tr>
    <tr *ngIf="klarnaOrderRaw().status">
      <td>Status</td>
      <td>{{ klarnaOrderRaw().status }}</td>
    </tr>


    <tr *ngIf="klarnaOrderRaw().merchant_reference1 && showShopOrder">
      <td>ShopID</td>
      <td>
        <div>
          <nxt-button (click)="onShowShopOrder.emit(klarnaOrderRaw().merchant_reference1)">Zum Shop-Kauf {{ klarnaOrderRaw().merchant_reference1 }}</nxt-button>
        </div>
      </td>
    </tr>

    <tr *ngIf="klarnaOrderRaw().initial_payment_method">
      <td>Zahlungmethode</td>
      <td>
        <div *ngIf="false">{{ klarnaOrderRaw().initial_payment_method.type }}</div>
        <div>{{ klarnaOrderRaw().initial_payment_method.description }}</div>
      </td>
    </tr>
    <tr>
      <td>Aktueller Wert</td>
      <td>
        <div>{{ openValue() | money }}</div>
        <div class="text-80">von {{ originalValue | money }}</div>
      </td>
    </tr>
    <tr *ngIf="klarnaOrderRaw().refunded_amount">
      <td>Rückerstattet</td>
      <td>
        <div>{{ (klarnaOrderRaw().refunded_amount / 100) | money }}</div>
        <div class="text-80">von {{ originalValue | money }}</div>
    </tr>

    <tr *ngIf="klarnaOrderRaw().captured_amount">
      <td>Bezahlt</td>
      <td>{{ (klarnaOrderRaw().captured_amount / 100) | money }}</td>
    </tr>

    <tr *ngIf="klarnaOrderRaw().customer">
      <td>Geburtstag</td>
      <td>{{ klarnaOrderRaw().customer.date_of_birth | nxtDate: 'dd.MM.yyyy' }}</td>
    </tr>



    <ng-container *ngIf="klarnaOrderRaw().refunds && klarnaOrderRaw().refunds.length > 0">
      <tr *ngFor="let refund of klarnaOrderRaw().refunds; let i = index">
        <td>{{ i + 1 }}. Rückzahlung</td>
        <td class="!p-0">
          <table class="nxt-table nxt-table-no-outer-border w-full">
            <tr *ngIf="showDebug()">
              <td>Rückzahlungs-ID</td>
              <td>{{ refund.refund_id }}</td>
            </tr>
            <tr>
              <td>Betrag</td>
              <td>{{ (refund.refunded_amount / 100) | money }}</td>
            </tr>
            <tr>
              <td>Erstattet am</td>
              <td>
                {{ refund.refunded_at | nxtDate: 'dd.MM.yyyy HH:mm' }}
                ({{ refund.refunded_at | fromNowText }})
              </td>
            </tr>
            <tr>
              <td>Grund</td>
              <td>{{ refund.description }}</td>
            </tr>
          </table>
        </td>
      </tr>
    </ng-container>
  </table>
  <div *ngIf="showDebug() && false" [innerHTML]="klarnaOrderRaw() | jsonFormatted"></div>
}
