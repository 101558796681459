import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FocusService {

  private lastFocusElement: Element;

  public onFocusChanged = new EventEmitter<{ lastFocusElement: Element, focusElement: Element }>();

  public activeElement = new BehaviorSubject(document.activeElement);


  public checkFocusElement(element: Element) {
    if (this.lastFocusElement !== element) {
      this.activeElement.next(element);
      this.onFocusChanged.emit({lastFocusElement: this.lastFocusElement, focusElement: element});
      this.lastFocusElement = element;
    }
  }

}
