import {inject, Injectable} from '@angular/core';
import {NxtEventQuery} from '../common-interfaces/nxt.calendar-event.interface';
import {BodyPutService} from './body-put.service';

@Injectable({
  providedIn: 'root',
})
export class EventQueryService {
  /*** Injections ***/
  private bodyPutService = inject(BodyPutService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  getText(eventQuery: NxtEventQuery): string[] {
    const result: string[] = [];
    if (eventQuery.eventDate) {
      if (eventQuery.eventDate.type === 'today') {
        result.push('Heute');
      } else if (eventQuery.eventDate.type === 'last7Days') {
        result.push('Letzte 7 Tage');
      } else if (eventQuery.eventDate.type === 'last30Days') {
        result.push('Letzte 30 Tage');
      } else if (eventQuery.eventDate.type === 'range') {
        if (eventQuery.eventDate.min && eventQuery.eventDate.max) {
          result.push(eventQuery.eventDate.min.dateFormat('dd.MM.yyyy') + ' - ' + eventQuery.eventDate.max.dateFormat('dd.MM.yyyy'));
        } else if (eventQuery.eventDate.min) {
          result.push('Ab ' + eventQuery.eventDate.min.dateFormat('dd.MM.yyyy'));
        } else if (eventQuery.eventDate.max) {
          result.push('Bis ' + eventQuery.eventDate.max.dateFormat('dd.MM.yyyy'));
        }
      }
    }
    if (eventQuery.rating) {
      if (eventQuery.rating.min && eventQuery.rating.max) {
        result.push('Bewertung: ' + eventQuery.rating.min + '-' + eventQuery.rating.max);
      } else if (eventQuery.rating.min) {
        result.push('Bewertung ab ' + eventQuery.rating.min);
      } else if (eventQuery.rating.max) {
        result.push('Bewertung bis ' + eventQuery.rating.max);
      }
    }
    if (eventQuery.studios && eventQuery.studios.length > 0) {
      result.push(eventQuery.studios.join(' '));
    }

    if (eventQuery.bodyPut && eventQuery.bodyPut.length > 0) {
      result.push(this.bodyPutService.getGermanPath(eventQuery.bodyPut));
    }

    if (eventQuery.artist) {
      result.push(eventQuery.artist);
    }
    const skills = [...eventQuery.skills || [], ...eventQuery.boolSkills || []];
    if (skills.length > 0) {
      result.push(skills.join(' · '));
    }
    if (eventQuery.tattooMotive) {
      result.push(eventQuery.tattooMotive);
    }
    return result;
  }
}
