import {Component, OnInit, signal} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {EventComponent} from '../../event/event.component';
import {SocketService} from '../../services/socket/socket.service';
import {DialogService, LoadingId} from '../../services/dialog.service';
import {Log} from '../../common-browser/log/log.tools';
import {NxtFormControl} from '../../nxt-form/nxt.form-control';
import {NxtContact, NxtContactPartial, ParsedContactWithMatches} from '../../common-interfaces/nxt.contact.interface';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {FormTools} from '../../services/form.tools';
import {LoginService} from '../../services/login.service';
import moment from 'moment';
import {PermissionService} from '../../services/permission.service';
import {StringTools} from '../../common-browser/helpers/string.tools';
import {NxtPermissionId} from '../../common-interfaces/nxt.user.interface';
import {CountryTools} from '../../common-browser/helpers/country.tools';
import {LanguageTools} from '../../common-browser/helpers/language.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtArtistSpotNotification} from '../../common-interfaces/nxt.artist-spot-notification.interface';
import {DisplayWithTools} from '../../common-browser/helpers/display-with.tools';
import {NxtArtist} from '../../common-interfaces/nxt.artist.interface';
import {ValidatorTools} from '../../helpers/validator.tools';
import {MobileTools} from '../../common-browser/helpers/mobile.tools';
import {ContactTools} from '../../common-browser/helpers/contact.tools';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {AutocompleteComponent} from '../form-controls/autocomplete/autocomplete.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {RadioComponent} from '../form-controls/radio/radio.component';
import {DatePickerComponent} from '../form-controls/date-picker/date-picker.component';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {InputComponent} from '../form-controls/input/input.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {ColComponent} from '../../controls/nxt-grid/col/col.component';
import {RowComponent} from '../../controls/nxt-grid/row/row.component';
import {PermissionDirective} from '../../directives/permission.directive';
import {NgFor, NgIf} from '@angular/common';
import {CacheService} from '../../services/cache/cache.service';
import {NxtComponent} from '../nxt.component';
import {TimeTools} from '../../common-browser/helpers/time.tools';
import {SpinnerComponent} from '../spinner/spinner.component';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faWandSparkles} from '@fortawesome/free-solid-svg-icons';
import {MatTooltip} from '@angular/material/tooltip';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'nxt-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  imports: [NgIf, FormsModule, ReactiveFormsModule, PermissionDirective, RowComponent, ColComponent, FlexModule, InputComponent, NxtButtonIconComponent, DatePickerComponent, RadioComponent, NxtButtonComponent, AutocompleteComponent, SlideToggleComponent, NgFor, SpinnerComponent, FaIconComponent, MatTooltip],
})
export class ContactFormComponent extends NxtComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EventComponent>,
    // @Inject(MAT_DIALOG_DATA) private data: string | NxtContact,
    private socketService: SocketService,
    private dialogService: DialogService,
    public permissionService: PermissionService,
    private loginService: LoginService,
    private configService: ConfigService,
    private cacheService: CacheService,
  ) {
    super();
    if (this.permissionService.hasPermission(NxtPermissionId.ContactForm_WithoutBirthday)) {
      this.contactForm_WithoutBirthday = true;
    }

    this.pushSubscription = this.cacheService.parsedContacts.subscribe((parsedContacts) => {
      this.parsedContactsChanged(parsedContacts);
    });
  }

  private data: string | NxtContact;
  loadingParseContacts = signal(false);

  private isNewContact = false;

  artistDisplayWith = DisplayWithTools.displayWithNxtArtist;

  form: UntypedFormGroup;
  getNewContactDataInterval: any;
  getNewContactDataRunning = false;
  contactForm_WithoutBirthday = false;
  showSaveButton = true;
  private originalContact: NxtContact;
  countries = CountryTools.list;
  langs = LanguageTools.nxtUsed;
  artistSpotNotifications: NxtArtistSpotNotification[];
  artists: NxtArtist[];
  clipboardText = '';
  unknownMobile = false;
  unknownStreet = false;

  protected readonly faWandSparkles = faWandSparkles;

  closedEventsCount = signal(0);

  load(data: string | NxtContactPartial) {
    this.data = data as NxtContact;
    this.closedEventsCount.set(this.data?.closedEvents?.length || 0);
    if (this.instanceOfContact(data)) {
      this.loadFormFromObject(data as NxtContact);
    } else {
      this.loadFormFromString(data);
    }
  }

  async reload() {
    const contact = await this.socketService.getContactById(this.form.get('id').value);
    this.load(contact);
  }

  displayInOptionWithCountry = (country, highlight: (string) => string) => {
    if (!country) {
      return '';
    }
    return highlight(country.name);
  };

  displayWithCountry = (country) => {
    console.log(this.form.get('country').value);
    if (!country) {
      return '';
    }
    return country.name ?? country.name;
  };


  public loadFormFromObject(data: NxtContact) {
    this.isNewContact = !data.id;
    this.originalContact = data;
    this.form = this.fb.group({
      givenName: new NxtFormControl(data.givenName, [ValidatorTools.requiredAndNotNaN], 'Vorname'),
      familyName: new NxtFormControl(data.familyName, [ValidatorTools.requiredAndNotNaN], 'Nachname'),
      email: new NxtFormControl(data.email),
      mobile: new NxtFormControl(data.mobile, [ValidatorTools.mobile, ValidatorTools.requiredAndNotNaN], 'Handynummer'),
      city: new NxtFormControl(data.city),
      postalCode: new NxtFormControl(data.postalCode, [], 'PLZ'),
      streetAddress: new NxtFormControl(data.streetAddress, this.configService.config.value.mustContactStreet ? [Validators.required] : []),
      id: new NxtFormControl(data.id),
      birthday: new NxtFormControl(this.parseBirthdayToDate(data.birthday), this.contactForm_WithoutBirthday ? [] : [ValidatorTools.requiredAndNotNaN]),
      disableBroadcast: new NxtFormControl(!!data.disableBroadcast),
      weeklyBroadcast: new NxtFormControl(!!data.weeklyBroadcast),
      testBroadcast: new NxtFormControl(!!data.testBroadcast),
      disableBirthdayGiftCard: new NxtFormControl(!!data.disableBirthdayGiftCard),
      lang: new NxtFormControl(data.lang, [ValidatorTools.requiredAndNotNaN], 'Sprache'),
      country: new NxtFormControl(data.country, [], 'Land'),
    });
    this.form.get('id');
    this.checkUpperCase('givenName');
    this.checkUpperCase('familyName');
    this.form.get('country').setValue(this.countries.find(c => c.code === data.country));

    this.registerFormChangeListeners();
    if (this.isNewContact) {
      FormTools.setValidators(this.form.get('postalCode'), [ValidatorTools.requiredAndNotNaN]);
      FormTools.setValidators(this.form.get('country'), [ValidatorTools.requiredAndNotNaN]);
      FormTools.setValidators(this.form.get('city'), [ValidatorTools.requiredAndNotNaN]);
    }

    setTimeout(() => {
      this.form.get('mobile').setValue(this.form.get('mobile').value);
    }, 500);
  }

  private loadFormFromString(data: string) {
    this.form = this.fb.group({
      givenName: new NxtFormControl('', [ValidatorTools.requiredAndNotNaN], 'Vorname'),
      familyName: new NxtFormControl('', [ValidatorTools.requiredAndNotNaN], 'Nachname'),
      email: new NxtFormControl(''),
      mobile: new NxtFormControl(data, [ValidatorTools.mobile, ValidatorTools.requiredAndNotNaN], 'Handynummer'),
      city: new NxtFormControl(''),
      postalCode: new NxtFormControl('', [ValidatorTools.requiredAndNotNaN], 'PLZ'),
      streetAddress: new NxtFormControl('', this.configService.config.value.mustContactStreet ? [ValidatorTools.requiredAndNotNaN] : []),
      id: new NxtFormControl(''),
      birthday: new NxtFormControl(null),
      disableBroadcast: new NxtFormControl(false),
      weeklyBroadcast: new NxtFormControl(false),
      testBroadcast: new NxtFormControl(false),
      disableBirthdayGiftCard: new NxtFormControl(false),
      lang: new NxtFormControl(null, [ValidatorTools.requiredAndNotNaN], 'Sprache'),
      country: new NxtFormControl('', [ValidatorTools.requiredAndNotNaN], 'Land'),
    });
    this.readClipboard();
    this.registerFormChangeListeners();
  }

  async ngOnInit() {
    document.body.style.backgroundColor = 'transparent !important';
    this.artists = await this.socketService.getArtists();
  }

  nxtOnDestroy(): void {
    if (this.getNewContactDataInterval) {
      clearInterval(this.getNewContactDataInterval);
      this.getNewContactDataRunning = false;
    }
  }

  instanceOfContact(object: any): object is NxtContactPartial {
    return typeof object !== 'string';
  }


  private startGetNewContactData() {
    if (this.getNewContactDataInterval) {
      clearInterval(this.getNewContactDataInterval);
    }
    this.getNewContactDataRunning = true;
    this.getNewContactDataInterval = setInterval(async () => {
      const newContactData = await this.socketService.getNewContactData();
      if (newContactData && newContactData.givenName) {
        this.form.get('givenName').setValue(newContactData.givenName);
        this.form.get('familyName').setValue(newContactData.familyName);
        this.form.get('email').setValue(newContactData.email);
        this.form.get('mobile').setValue(newContactData.mobile);
        this.form.get('streetAddress').setValue(newContactData.streetAddress + ' ' + newContactData.houseNumber);
        this.form.get('postalCode').setValue(newContactData.postalCode);
        this.form.get('city').setValue(newContactData.city);
        this.form.get('birthday').setValue(newContactData.birthday);
      }
    }, 1000);
  }

  private async readClipboard(retry = 5) {
    try {
      const currentClipboard = await navigator.clipboard.readText();
      if (typeof currentClipboard === 'string') {
        const nameArray = currentClipboard.split(' ');
        if (nameArray.length === 2) {
          this.form.get('givenName').setValue(nameArray[0]);
          this.form.get('familyName').setValue(nameArray[1]);
        }
      }
    } catch (err) {
      if (err.message === 'Document is not focused.') {

        if (retry > 0) {
          retry--;
          setTimeout(() => {
            Log.info('readClipboard failed --> retry');
            this.readClipboard(retry);
          }, 100);
        }
      }
      console.warn(this, 'readClipboard failed ' + err.message);
    }
  }

  async saveAndClose(force = false) {

    // this.showSaveButton = false;
    const result = await this.save(force);
    if (result) {
      if (result.sameMobile) {
        const buttons = [{text: 'Zurück', value: 'back'}];
        if (this.originalContact?.id) {
          buttons.push({text: 'Kontakte zusammenführen', value: 'combine'});
        }
        if (this.loginService.isJulian()) {
          buttons.push({text: 'Trotzdem speichern', value: 'force'});
        }
        const dialogResult = await this.dialogService.showButtons(result.fullName + ' hat bereits diese Handynummer!', {buttons});

        switch (dialogResult.value) {
          case 'combine':
            this.dialogService.showCombineContacts([...result.contactIds, this.originalContact.id]);
            this.dialogRef.close();
            break;
          case 'force':
            await this.saveAndClose(true);
            break;
        }
        return;
      } else if (result.alreadyExist) {
        const addressOld = ContactTools.getAddressLine(result.alreadyExistContact as NxtContact, true);
        const newContact = this.form.getRawValue();
        if (newContact.country.code) {
          newContact.country = newContact.country.code;
        }
        const addressNew = ContactTools.getAddressLine(newContact as NxtContact);
        let text = 'Kunde mit gleichem Namen und Geburtstag existiert bereits!';
        text += '\n\nAlte Daten:';
        text += '\n' + result.alreadyExistContact.givenName + ' ' + result.alreadyExistContact.familyName;
        text += '\nGeburtstag: ' + DateTools.format(result.alreadyExistContact.birthday, 'dd.MM.yyyy');
        if (addressOld) {
          text += '\n' + addressOld;
        }
        text += '\nHandy: ' + result.alreadyExistContact.mobileFormatted;

        const newData = this.form.getRawValue();
        text += '\n\nNeue Daten:';
        text += '\n' + newData.givenName + ' ' + newData.familyName;
        text += '\nGeburtstag: ' + DateTools.format(newData.birthday, 'dd.MM.yyyy');
        if (addressNew) {
          text += '\n' + addressNew;
        }
        text += '\nHandy: ' + newData.mobile;
        text += '\n\n Neue Daten verwenden?';
        if (await this.dialogService.showYesNo(text, {yesText: 'Ja, ersetzen'})) {
          result.alreadyExistContact.mobile = this.form.get('mobile').value;
          if (this.form.get('country').value?.code) {
            result.alreadyExistContact.country = this.form.get('country').value.code;
          }
          if (this.form.get('postalCode').value) {
            result.alreadyExistContact.postalCode = this.form.get('postalCode').value;
          }
          if (this.form.get('city').value) {
            result.alreadyExistContact.city = this.form.get('city').value;
          }
          if (this.form.get('streetAddress').value) {
            result.alreadyExistContact.streetAddress = this.form.get('streetAddress').value;
          }
          this.loadFormFromObject(result.alreadyExistContact);
        }
      } else {
        this.close(result);
      }
    }
  }

  private async save(force: boolean) {
    const checkErrors = await this.checkFormErrors();
    if (checkErrors) {
      let updateContactResult;
      try {
        let toSave = this.form.getRawValue();
        if (toSave.birthday) {
          toSave.birthday = DateTools.parseFormat(toSave.birthday, 'yyyy-MM-dd');
        }
        if (this.originalContact && this.originalContact.id) {
          if ((toSave.givenName + ' ' + toSave.familyName).toLowerCase() !== (this.originalContact.givenName + ' ' + this.originalContact.familyName).toLowerCase()) {
            let doIt = true;
            if (!force) {
              doIt = await this.dialogService.showYesNo(
                'Bist du dir sicher, dass du den Namen des Kunden ändern möchtest, der alte Kunde wird überschrieben!\n\nAlte Termine werden mit übernommen!',
                {yesText: 'Ja, es ist weiterhin die gleiche Person', noText: 'zurück'});
            }
            if (!doIt) {
              return;
            }
          }
        }

        if (toSave?.country?.code) {
          toSave.country = toSave.country.code;
        }
        if (typeof this.data !== 'string') {
          toSave = {...this.originalContact, ...toSave};
        }
        if (!this.isNewContact) {
          this.dialogService.showLoading(LoadingId.CreateCustomer, 'Kunde wird aktualisiert...');
          updateContactResult = await this.socketService.updateContact(toSave, force);
        } else {
          this.dialogService.showLoading(LoadingId.CreateCustomer, 'Kunde wird angelegt...');
          updateContactResult = await this.socketService.createContact(toSave);
        }
        const parseContact = this.cacheService.parsedContacts.value.find(c => c.parsedContact?.givenName === toSave?.givenName && c.parsedContact?.familyName === toSave?.familyName);
        if (parseContact) {
          this.socketService.deleteParsedContact(parseContact.id).then();
        }
        Log.info(updateContactResult);
      } catch (err) {
        Log.error(err);
        // alert('save failed! ' + err.message + ' - ' + JSON.stringify(err));
      }
      this.dialogService.hideLoading(LoadingId.CreateCustomer);
      return updateContactResult;
    }
  }


  async closeClicked() {
    /*const result = await this.dialogService.showYesNo('Möchtest du wirklich abbrechen und nicht speichern?', {yesText: 'Ja, abbrechen'});
    if (result) {*/
    this.close(false);
    // }
  }

  async close(result) {
    this.dialogRef.close(result);
  }

  readFromServer() {
    this.startGetNewContactData();
  }

  private parseBirthdayToDate(birthday: any) {
    if (typeof birthday === 'string' && birthday.length === 24) {
      return new Date(DateTools.parse(birthday, 'yyyy-MM-ddTHH:mm:ss.SSSZ'));
    } else if (typeof birthday === 'string' && birthday.length === 10) {
      if (birthday.indexOf('-') === 4) {
        return new Date(DateTools.parse(birthday, 'yyyy-MM-dd'));
      } else {
        return new Date(DateTools.parse(birthday, 'dd.MM.yyyy'));
      }
    }
  }

  private async checkFormErrors() {
    this.form.markAllAsTouched();
    if (!FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      return false;
    }


    if (this.form.get('birthday').value) {
      const birthday = DateTools.parse(this.form.get('birthday').value);
      const years = moment.duration(Date.now() - birthday, 'ms').asYears();
      if (years <= 14) {
        if (!(await this.dialogService.showYesNo('Unter 14 Jahre!\n Bist du dir ganz ganz ganz sicher?'))) {
          return false;
        }
      }
    }

    return true;
  }

  birthdayChanged() {
    if (this.form.get('birthday').value) {
      let birthday = DateTools.parse(this.form.get('birthday').value);
      if (birthday > DateTools.addYears(Date.now(), 1)) {
        birthday = DateTools.addYears(birthday, -100);
        this.form.get('birthday').setValue(new Date(birthday));
      }
    }
  }

  setBirthdayRequired(required: boolean) {
    FormTools.setValidators(this.form.get('birthday'), required ? [ValidatorTools.requiredAndNotNaN] : []);
  }

  private registerFormChangeListeners() {
    // kein plan warum das auskommtiert war...
    this.form.get('givenName').valueChanges.subscribe(() => this.checkUpperCase('givenName'));
    this.form.get('familyName').valueChanges.subscribe(() => this.checkUpperCase('familyName'));

    this.form.get('postalCode').valueChanges.subscribe(() => {
      this.autocompleteCity();
    });
    this.form.get('country').valueChanges.subscribe(() => {
      this.autocompleteCity();
    });

    this.form.get('mobile').valueChanges.subscribe((value) => {
      const newMobile = MobileTools.formatNxt(value);
      if (newMobile && value !== newMobile) {
        this.form.get('mobile').setValue(newMobile);
      }
      if (value) {
        this.unknownMobile = false;
        this.unknownMobileChanged();
      }
    });
  }

  checkUpperCase(field: string) {
    const lowerCases = ['van', 'von', 'di', 'd\'', 'auf', 'dem', 'den', 'der'];
    const oldValue = this.form.get(field).value;
    if (oldValue?.length >= 3) {
      const newValue = oldValue.split(' ').map(part => {
        const index = lowerCases.indexOf(part.toLowerCase());
        if (index > -1) {
          return lowerCases[index];
        }
        return StringTools.toUpperCase(part, 0, 1);

      }).join(' ');
      if (oldValue !== newValue) {
        this.form.get(field).setValue(newValue);
      }
    }
  }

  switchNames() {
    const left = this.form.get('givenName').value;
    const right = this.form.get('familyName').value;
    this.form.get('givenName').setValue(right);
    this.form.get('familyName').setValue(left);
  }

  public setCountry(countryCode: string) {
    this.form.get('country').setValue(this.countries.find(c => c.code === countryCode));
  }

  private async autocompleteCity() {
    const country = this.form.get('country').value;
    const postalCode = this.form.get('postalCode').value ?? '';
    if (country && postalCode.length > 3) {
      const places = await this.socketService.getCityByPostalCode({postalCode: postalCode.toUpperCase(), country: country.code});
      if (places && places.length > 0) {
        if (places.length > 1) {
          const result = await this.dialogService.showButtonChooser({title: 'Welche Stadt', buttonRows: [places]});
          if (result) {
            this.form.get('city').setValue(result);
          }
        } else {
          this.form.get('city').setValue(places[0]);
        }
      } else {
        this.form.get('city').setValue('');
      }
    } else {
      this.form.get('city').setValue('');
    }
  }

  addArtistSpotNotification() {
    this.artistSpotNotifications.push({
      id: '',
      artist: null,
      contactId: this.form.get('id').value,
      createdAt: Date.now(),
      createdBy: this.loginService.getUsername(),
      done: false,
    });
  }

  removeArtistSpotNotification(index: number) {
    this.artistSpotNotifications.splice(index, 1);
  }

  fromClipBoard() {
    const c = StringTools.getNameAttributesByText(this.clipboardText);
    if (c.birthday) {
      this.form.get('birthday').setValue(c.birthday);
    }
    if (c.givenName) {
      this.form.get('givenName').setValue(c.givenName);
    }
    if (c.familyName) {
      this.form.get('familyName').setValue(c.familyName);
    }
    if (c.postalCode) {
      this.form.get('postalCode').setValue(c.postalCode);
    }
    requestAnimationFrame(() => this.clipboardText = '');
  }

  unknownMobileChanged() {
    if (this.unknownMobile) {
      this.form.get('mobile').setValue('');
    }
    this.form.get('mobile').setValidators(this.unknownMobile ? [] : [ValidatorTools.requiredAndNotNaN, ValidatorTools.mobile]);
    this.form.get('mobile').updateValueAndValidity({emitEvent: false});
  }

  unknownStreetChanged() {
    if (this.unknownStreet) {
      this.form.get('streetAddress').setValue('');
    }
    this.form.get('streetAddress').setValidators(this.unknownStreet ? [] : [Validators.required]);
    this.form.get('streetAddress').updateValueAndValidity({emitEvent: false});
  }

  private async parsedContactsChanged(parsedContacts: ParsedContactWithMatches[]) {
    this.loadingParseContacts.set(parsedContacts.some(c => c.isLoading));
    if (!this.loadingParseContacts() && parsedContacts.length === 1) {
      await TimeTools.waitForObj(() => this.form);
      const contact = parsedContacts[0].parsedContact;
      if (!this.form.get('givenName').value && contact.givenName) {
        this.form.get('givenName').setValue(contact.givenName);
      }
      if (!this.form.get('familyName').value && contact.familyName) {
        this.form.get('familyName').setValue(contact.familyName);
      }
      if (!this.form.get('mobile').value && contact.mobile) {
        this.form.get('mobile').setValue(contact.mobile);
      }
      if (!this.form.get('city').value && contact.city) {
        this.form.get('city').setValue(contact.city);
      }
      if (!this.form.get('postalCode').value && contact.postalCode) {
        this.form.get('postalCode').setValue(contact.postalCode);
      }
      if (!this.form.get('birthday').value && contact.birthday) {
        this.form.get('birthday').setValue(contact.birthday);
      }
    }
  }

  async autoStreetClicked() {
    this.dialogService.showLoading('Straße wird aus Einwilligung übernommen...');
    const result = await this.socketService.contactStreetRunByContactId(this.form.get('id').value);
    this.dialogService.hideLoading();
    if (result.foundBy === 'none') {
      await this.dialogService.showOk('<img src="data:image/png;base64,' + result.secondPagePngBase64OnError + '">', {title: 'Straße nicht gefunden!'});
    } else {
      await this.dialogService.showOk('Straße erfolgreich übernommen\n' + result.foundBy);
      this.close(await this.socketService.getContactById(this.form.get('id').value));
    }
  }
}
