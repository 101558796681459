<div class="nxt-page h-100 w-full">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-2">
    <div fxLayout="row" fxLayoutGap="50px">
      <div class="page-header">Artist-Auszahlungen am {{dateString}}</div>
    </div>
    <nxt-button-icon *ngIf="dialogRef" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>
  <div *ngIf="data" fxLayout="column" class="h-100">
    <nxt-datagrid
      #prePayoutGrid
      class="h-100"
      [columnDefs]="columnDefs"
      [rowData]="data.artists"
    >
    </nxt-datagrid>
  </div>
</div>
