<div fxLayout="column">
  <div fxLayout="row" fxLayoutGap="3em" fxLayoutAlign="center">
    <h3>{{title}}</h3>
  </div>
  <div fxFlex fxLayout="row" fxFlex>
    <div fxFlex fxLayout="column" [style.padding.em]="2">
      <table class="w-full">
        <tr *ngFor="let checkItem of checkItems">
          <td fxFlex>
            <label class="mouse-pointer" (click)="checkItem.checked = !checkItem.checked; checkChanged()">
              {{checkItem.name}}
            </label>
          </td>
          <td fxLayoutAlign="end">
            <mat-slide-toggle
              [(ngModel)]="checkItem.checked"
              (ngModelChange)="checkChanged()"
              color="primary"
              labelPosition="before">
            </mat-slide-toggle>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center">
    <nxt-button (click)="close()" [disabled]="!allItemsChecked && allCheckedToFinish">Checklist abgeschlossen</nxt-button>
  </div>
</div>
