import {Component, OnInit} from '@angular/core';
import {NxtNgConfigRuntime} from '../../common-interfaces/nxt.ng-config.interface';
import {FlexModule} from 'ngx-flexible-layout/flex';

declare const paypal;

@Component({
    selector: 'nxt-to-whatsapp',
    templateUrl: './to-whatsapp.component.html',
    styleUrls: ['./to-whatsapp.component.scss'],
    imports: [FlexModule]
})

export class ToWhatsappComponent implements OnInit {
  public logoSrc = '/assets/logo/studio/logo_ac_white.svg';
  public footerText: any;

  private ngConfig: NxtNgConfigRuntime = (window as any).ngConfig;


  constructor(
  ) {
    this.footerText = this.ngConfig.invoiceData.fromCompany + ' - '
      + this.ngConfig.invoiceData.fromStreet + ' - '
      + this.ngConfig.invoiceData.fromPostalCode + ' '
      + this.ngConfig.invoiceData.fromCity + ' ';
  }

  async ngOnInit() {
  }
}
