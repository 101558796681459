import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, OnInit, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {JsonFormattedPipe} from '../../../../../pipes/json-formatted-pipe';
import {SocketService} from '../../../../../services/socket/socket.service';
import {NxtBankDocument} from '../../../../../common-interfaces/bank/bank-transaction.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {SafeHtmlPipe} from '../../../../../pipes/safe-html.pipe';
import {FlexModule} from 'ngx-flexible-layout';
import {DialogService} from '../../../../../services/dialog.service';
import {NxtDatePipe} from '../../../../../pipes/nxt-date-pipe';
import {SocketInterfaceResponse} from '../../../../../common-interfaces/socket/socket-interface';
import {NxtButtonIconComponent} from '../../../../../controls/button-icon/nxt-button-icon.component';
import {ObjectTools} from '../../../../../common-browser/helpers/object.tools';

@Component({
  selector: 'nxt-bank-document-debug',
  templateUrl: './bank-document-debug.component.html',
  styleUrls: ['./bank-document-debug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    JsonFormattedPipe,
    SafeHtmlPipe,
    FlexModule,
    NxtDatePipe,
    NxtButtonIconComponent,
  ],
  standalone: true,
})

export class BankDocumentDebugComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  @ViewChild('pdfCanvas') pdfCanvas: ElementRef<HTMLCanvasElement>;

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  document = signal<NxtBankDocument | null>(null);
  documentText = signal('');
  entities = signal<{ key: string, value: string }[]>([]);

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  dialogRef = inject(MatDialogRef, {optional: true});


  constructor() {
    super();
  }


  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  async load(data: SocketInterfaceResponse.GetBankDocumentFull) {
    const clone = ObjectTools.clone(data);
    const entities = clone.document?.documentAi?.entities;
    const entityLines: { key: string, value: string }[] = [];
    if (entities) {
      for (const entity of entities) {
        if (entity.normalizedValue) {
          entityLines.push({key: entity.type, value: entity.mentionText + '\nNormalized:' + entity.normalizedValue.text});
        } else {
          entityLines.push({key: entity.type, value: entity.mentionText});
        }
      }
      clone.document.documentAi.entities = [];
    }
    this.entities.set(entityLines.sortString('key'));
    this.document.set(clone.document);
    this.documentText.set(clone.contentText);
  }
}
