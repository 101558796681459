import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {NxtTypedFormControl} from '../../../nxt-form/nxt.typed-form-control';
import {SocketService} from '../../../services/socket/socket.service';
import {DialogService} from '../../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtMoneyAccount, NxtMoneyPreTransaction, NxtMoneyTransactionCategory, NxtMoneyTransactionLabel, NxtMoneyTransactionRegion,} from '../../../common-interfaces/money-account.interface';
import {AutocompleteComponent} from '../../form-controls/autocomplete/autocomplete.component';
import {DisplayWithTools} from '../../../common-browser/helpers/display-with.tools';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {FormTools} from '../../../services/form.tools';
import {WithoutNxtDbFields} from '../../../common-interfaces/nxt.db-fields.interface';
import {TextareaComponent} from '../../form-controls/textarea/textarea.component';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {BehaviorSubject, first} from 'rxjs';
import {ValidatorTools} from '../../../helpers/validator.tools';

@Component({
    selector: 'nxt-money-pre-transaction-accept',
    templateUrl: './money-pre-transaction-accept.component.html',
    styleUrls: ['./money-pre-transaction-accept.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AutocompleteComponent,
    NxtButtonComponent,
    ReactiveFormsModule,
    TextareaComponent,
    MoneyPipe,
  ]
})

export class MoneyPreTransactionAcceptComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  originalPreTransaction = signal<WithoutNxtDbFields<NxtMoneyPreTransaction> | null>(null);
  private loaded$ = new BehaviorSubject(false);


  constructor() {
    super();
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  accounts = signal<NxtMoneyAccount[]>([]);
  regions = signal<NxtMoneyTransactionRegion[]>([]);
  categories = signal<NxtMoneyTransactionCategory[]>([]);
  labels = signal<NxtMoneyTransactionLabel[]>([]);

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  private dialogRef = inject(MatDialogRef<MoneyPreTransactionAcceptComponent>, {optional: true});

  form = new FormGroup({
    info: new NxtTypedFormControl<string>('', [], 'Info'),
    region: new NxtTypedFormControl<NxtMoneyTransactionRegion>(null, [ValidatorTools.requiredAndNotNaN], 'Region'),
    category: new NxtTypedFormControl<NxtMoneyTransactionCategory>(null, [ValidatorTools.requiredAndNotNaN], 'Kategorie'),
    labels: new NxtTypedFormControl<NxtMoneyTransactionLabel[]>([], [], 'Labels'),
  });

  protected readonly DisplayWithTools = DisplayWithTools;

  async ngOnInit() {
    const data = await this.socketService.getMoneyAccounts();
    this.accounts.set(data.accounts);
    this.regions.set(data.regions);
    this.categories.set(data.categories);
    this.labels.set(data.labels);
    this.loaded$.next(true);
  }

  nxtOnDestroy() {
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  async saveClicked() {
    if (FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      await this.socketService.acceptMoneyPreTransaction(this.originalPreTransaction().id, this.form.controls.region.value.id, this.form.controls.category.value.id, this.form.controls.info.value);
      this.dialogRef.close();
    }
  }


  async load(preTransaction: NxtMoneyPreTransaction) {
    await this.waitForLoad();
    this.originalPreTransaction.set(preTransaction);
    if (this.originalPreTransaction().transaction.categoryId) {
      this.form.controls.category.setValue(this.categories().find(c => c.id === this.originalPreTransaction().transaction.categoryId));
    }
    if (this.originalPreTransaction().transaction.info) {
      this.form.controls.info.setValue(this.originalPreTransaction().transaction.info);
    }
  }

  private async waitForLoad() {
    return new Promise<void>((resolve, reject) => {
      if (this.loaded$.value) {
        resolve();
      } else {
        this.loaded$.pipe(first(value => value)).subscribe((value) => {
          resolve();
        });
      }
    });
  }
}
