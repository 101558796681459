<div
  [mtxTooltip]="tooltipTemplate"
  m
  mtxTooltipClass="no-style mat-tooltip-width-content"
  class="event"
  [mtxTooltipPosition]="event().allDay ? 'after' : 'above'"
  [class.artist-open-piercing]="artistOpenPiercing()"
  [class.artist-open-tattoo]="artistOpenTattoo()"
  [ngStyle]="{color: textColor(),backgroundColor: event().color?.primary}"
  tabindex="0"
  role="application"
>
  <div
    style="word-wrap: break-word; text-wrap: initial; line-height: 1;">
    @if (event().meta?.event?.artistFix) {
      <div class="w-full text-center font-bold">FIX</div>
    }

    <div [innerHTML]="event().title"></div>

  </div>
</div>

<ng-template #tooltipTemplate>
  <div>
    <nxt-tooltip-calendar-event [event]="event().meta.event" [artistColor]="event().color.primary"></nxt-tooltip-calendar-event>
  </div>
</ng-template>
