import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';

export class NxtDatagridCellRenderers {
  static getCellRenderer() {
    return (params) => {
      if (params.colDef && typeof params.colDef.nxtFieldType === 'string') {
        switch (params.colDef.nxtFieldType) {
          case NxtFieldType.Taglines:
            if (Array.isArray(params.valueFormatted)) {
              return params.valueFormatted.join(' | ');
            }
            return '';
          case NxtFieldType.TodoType:
            switch (params.value) {
              case 'missingDeposit':
                break;
              case 'missingCompletePayed':
                return '<i class="material-icons">euro_symbol</i><i class="material-icons">call_received</i>';
              case 'missingArtistVisiblePrice':
                break;
              case 'missingPayedOut':
                return '<i class="material-icons">euro_symbol</i><i class="material-icons">call_made</i>';
            }
            break;
          default:
            return params.valueFormatted;
        }
      } else {
        return params.valueFormatted;
      }
    };
  }
}
