<div class="wrapper">
  <div>
    <img src="assets/logo.svg"/>
  </div>
</div>

<div [nxtMultiClick]="3" (nxtOnMultiClick)="start()" class="wrapper-text">
  <div style="position:relative; left: 2%">
    <span #codeSpan></span>
  </div>
</div>


<div *ngIf="this.stopButtonText" style="position:fixed; bottom: 20px; left: 0; right: 0; color: white; text-align: center; font-size: 5em">
  <div
    style="background-color: #b7860f; display: inline; padding: 5px 20px; border-radius: 4px; font-weight: bold"
    (click)="stop();"
    [innerHTML]="stopButtonText"
  >
  </div>
</div>

<!--


<div class="footer">
  <nxt-button (click)="start()">START</nxt-button>
</div>
-->
