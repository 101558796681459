import {StringTools} from './string.tools';
import {DecimalTools} from './decimal.tools';


export class DurationLogger {

    private static logFn: (message: string) => void;

    private startTimestamp = 0;

    constructor(private name: string, private message: string) {
        this.start();
    }

    public static setLogFn(logFn: (message: string) => void) {
        DurationLogger.logFn = logFn;
    }

    public static new(tag: string, action = ''): DurationLogger {
        return new DurationLogger(tag, action);
    }

    private start() {
        this.lastStep = Date.now();
        /*if (DurationLogger.logFn) {
            DurationLogger.logFn(StringTools.fill('[DURATION-LOGGER-START] - [' + this.name + '] START', 25, ' ') + ' ' + this.message);
        }*/
        this.startTimestamp = Date.now();
    }

    private steps: { duration: number, message: string }[] = [];

    private lastStep = 0;

    /*public writeStep(message: string) {
        this.steps.push({duration: Date.now() - this.lastStep, message});
        this.lastStep = Date.now();

    }*/

    public log(additionalMessage: string | number = '') {
        const duration = Date.now() - this.startTimestamp;
        additionalMessage = additionalMessage.toString();
        additionalMessage = additionalMessage.length > 0 ? ('\t' + additionalMessage) : '';
        if (typeof DurationLogger.logFn !== 'function') {
            DurationLogger.logFn = console.log;
        }
        let prefix = '';
        if (duration > 1000) {
            prefix = '1';
        }
        if (duration > 2000) {
            prefix += '2';
        }
        if (duration > 3000) {
            prefix += '3';
        }


        let logMessage = StringTools.fill('[DURATION-LOGGER] ' + prefix + ' - [' + DecimalTools.roundToString(duration / 1000, 2) + ' s] - [' + this.name + ']', 25, ' ');
        logMessage += ' ' + this.message + additionalMessage; // + '\n' + this.steps.map(step => step.duration + ' - ' + step.message).join('\n');
        DurationLogger.logFn(logMessage);
    }
}
