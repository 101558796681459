import {DurationTools} from './duration.tools';
import {SortTools} from './sort.tools';
import {NxtPiercingManagerCustomer} from '../../common-interfaces/nxt.piercing-manager-data.interface';

export class PiercingManagerTools {


  public static resort(nextPossibleCustomers: NxtPiercingManagerCustomer[]) {
    if (nextPossibleCustomers) {
      for (const customer of nextPossibleCustomers) {
        if (customer.fastWalkInNo + customer.fastWakInNoAdd < 0) {
          //vorgezogen
          customer.sortValue = '#' + (1000 + (customer.fastWalkInNo + customer.fastWakInNoAdd)).toString()
        }
        if (customer.fastWalkInNo === -1) {
          if (customer.start - (DurationTools.DURATION_1MINUTE * 10) > Date.now()) {
            customer.sortValue = customer.start.toString(); // '9999';
          } else {
            customer.sortValue = '0000';
          }
        }
      }
      nextPossibleCustomers.sort(SortTools.sortString('sortValue'));
    }
  }
}
