import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {FlexModule} from 'ngx-flexible-layout';
import {SafeUrlPipe} from '../../pipes/safe-url.pipe';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';

@Component({
    selector: 'nxt-multi-view',
    templateUrl: './multi-view.component.html',
    styleUrls: ['./multi-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatTabGroup,
    FlexModule,
    MatTab,
    SafeUrlPipe,
    SlideToggleComponent
  ]
})

export class MultiViewComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  studios: { name: string, url1: string, url2: string }[] = [
    {name: 'Aachen', url1: 'https://ac.nxt-lvl.ink/#/calendar', url2: 'https://ac.nxt-lvl.ink/#/studio-cash-report-2'},
    {name: 'Düsseldorf', url1: 'https://du.nxt-lvl.ink/#/calendar', url2: 'https://du.nxt-lvl.ink/#/studio-cash-report-2'},
    {name: 'Mallorca', url1: 'https://ma.nxt-lvl.ink/#/calendar', url2: 'https://ma.nxt-lvl.ink/#/studio-cash-report-2'},
    {name: 'Dortmund', url1: 'https://do.nxt-lvl.ink/#/calendar', url2: 'https://do.nxt-lvl.ink/#/studio-cash-report-2'},
    {name: 'Frankfurt', url1: 'https://ffm.nxt-lvl.ink/#/calendar', url2: 'https://ffm.nxt-lvl.ink/#/studio-cash-report-2'},
    {name: 'Aschaffenburg', url1: 'https://ab.nxt-lvl.ink/#/calendar', url2: 'https://ab.nxt-lvl.ink/#/studio-cash-report-2'},
    {name: 'Nürnberg', url1: 'https://nu.nxt-lvl.ink/#/calendar', url2: 'https://nu.nxt-lvl.ink/#/studio-cash-report-2'},
  ];
  currentStudio = 'Aachen';
  selectedIndex = 0;
  showUrl1 = true;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }
}
