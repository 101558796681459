import {ChangeDetectionStrategy, Component, computed, input, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {CalendarEvent} from 'angular-calendar';
import {MtxTooltip} from '@ng-matero/extensions/tooltip';
import {NgStyle, UpperCasePipe} from '@angular/common';
import {ColorTools} from '../../../../common-browser/helpers/color.tools';
import {EmployeeCalendarEventMeta} from '../employee-calendar.component';
import {NxtTimePipe} from '../../../../pipes/nxt-time-pipe';
import {DurationTools} from '../../../../common-browser/helpers/duration.tools';
import {EmployeeCalendarEventTooltipComponent} from './employee-calendar-event-tooltip/employee-calendar-event-tooltip.component';
import {NxtSubstrPipe} from '../../../../pipes/nxt-subsstring-pipe';

@Component({
    selector: 'nxt-employee-calendar-event',
    templateUrl: './employee-calendar-event.component.html',
    styleUrls: ['./employee-calendar-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MtxTooltip, NgStyle, UpperCasePipe, NxtTimePipe, EmployeeCalendarEventTooltipComponent, NxtSubstrPipe]
})

export class EmployeeCalendarEventComponent extends NxtComponent implements OnInit, NxtOnDestroy {


  /*** Injections ***/

  constructor() {
    super();
  }

  /*** Inputs ***/
  event = input.required<CalendarEvent<EmployeeCalendarEventMeta>>();

  /*** Outputs ***/

  /*** Signals ***/
  textColor = computed(() => {
    if (!this.event().meta.employee) {
      debugger;
    } else {
      return ColorTools.getLightOrDarkFontColorByBackground(this.event().meta.employee.color);
    }
  });

  duration = computed(() => {
    if (this.event().meta.employeeEvent.end) {
      return this.event().meta.employeeEvent.end - this.event().meta.employeeEvent.start;
    }
    return Date.now() - this.event().meta.employeeEvent.start;
  });

  tooltipClass = computed(() => {
    if (this.event().allDay) {
      return 'no-style mat-tooltip-width-content mat-tooltip-offset-right';
    }
    return 'no-style mat-tooltip-width-content';
  });

  protected readonly DurationTools = DurationTools;

  ngOnInit() {
  }

  nxtOnDestroy() {
  }
}
