<div class="flex flex-col h-full no-border-x" style="border:0 solid red">
  <div class="flex flex-row flex-grow gap-4" style="border:0 solid blue">
    <div class="flex w-fit h-full" style="border:0 solid green">
      @if (imgSrc()) {
        <div class="flex flex-col justify-center items-center h-full w-full">
          <img (click)="imgClicked($event)" [src]="imgSrc()" style="max-width: 100%; max-height: 80vh"/>
          <div class="flex pt-3">
            <nxt-button (click)="clearImageClicked()">Bild ersetzen</nxt-button>
          </div>
        </div>
      } @else {
        <nxt-file-upload [width]="300" [height]="220" message="Flyer hier her ziehen" (onFileDropped)="onFileDropped($event)"></nxt-file-upload>
      }
    </div>
    <div class="flex w-fit flex-col gap-4 pt-2">

      <nxt-box class="w-full" bg="normal" headerText="Angebot">
        <div class="flex flex-col justify-center items-center pt-3 w-full" style="border:0 solid pink">
          <nxt-slide-toggle [nxtFormControl]="form.controls.active"></nxt-slide-toggle>
          <div class="flex flex-row gap-4">
            <div class="flex flex-col gap-3 w-1/2">
              <nxt-input [nxtFormControl]="form.controls.name"></nxt-input>
              <nxt-textarea [rows]="2" [nxtFormControl]="form.controls.description"></nxt-textarea>
            </div>

            <div class="flex flex-col w-1/2" style="border:0 solid red">
              <div class="flex flex-row w-full pb-2 justify-center">
                <mat-button-toggle-group class="small-toggle-group" [formControl]="form.controls.discountPromotion.controls.discountType">
                  <mat-button-toggle value="priceFix">Fix Preis</mat-button-toggle>
                  <mat-button-toggle value="priceLike">Ca. Preis</mat-button-toggle>
                  <mat-button-toggle value="percentage">Prozent</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              @if (form.controls.discountPromotion.controls.discountType.value === 'priceFix' || form.controls.discountPromotion.controls.discountType.value === 'priceLike') {
                <div class="flex flex-row gap-3">
                  <nxt-input [nxtFormControl]="form.controls.discountPromotion.controls.priceValue" [isMoney]="true"
                             [placeholder]="form.controls.discountPromotion.controls.discountType.value === 'priceFix' ? 'Fixpreis' : 'Ca. Preis'"></nxt-input>
                  <nxt-input [nxtFormControl]="form.controls.discountPromotion.controls.artistGet" [isMoney]="true"></nxt-input>
                </div>
              }
              @if (form.controls.discountPromotion.controls.discountType.value === 'percentage') {
                <nxt-input [nxtFormControl]="form.controls.discountPromotion.controls.discountPercentage" [isPercent]="true"></nxt-input>
              }

              <div class="flex flex-row">
                <div class="flex w-1/2 flex-col">
                  <nxt-slide-toggle class="py-1" [nxtFormControl]="form.controls.discountPromotion.controls.disableDiscountedGiftCards"></nxt-slide-toggle>
                  <nxt-slide-toggle class="py-1" [nxtFormControl]="form.controls.discountPromotion.controls.additionalPayAfterEnd"></nxt-slide-toggle>
                </div>
                <div class="w-1/2">
                  <nxt-input [nxtFormControl]="form.controls.validDays" [isNumber]="true"></nxt-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nxt-box>

      <nxt-box class="w-full" bg="normal" headerText="Code">
        <div class="flex flex-col justify-center items-center pt-3 w-full gap-4" style="border:0 solid pink">
          <div class="flex flex-row items-center justify-around w-full" style="border: 0 solid blue">
            <div class="grid grid-cols-3 pos-cursor h-fit" style="border: 0 solid orangered">
              <div></div>
              <div>
                <mat-icon (click)="setPosY('code',-1)" fontSet="material-symbols-outlined">keyboard_arrow_up</mat-icon>
              </div>
              <div></div>
              <div>
                <mat-icon (click)="setPosX('code',-1)" fontSet="material-symbols-outlined">keyboard_arrow_left</mat-icon>
              </div>
              <div></div>
              <div>
                <mat-icon (click)="setPosX('code',1)" fontSet="material-symbols-outlined">keyboard_arrow_right</mat-icon>
              </div>
              <div></div>
              <div>
                <mat-icon (click)="setPosY('code',1)" fontSet="material-symbols-outlined">keyboard_arrow_down</mat-icon>
              </div>
              <div></div>
            </div>
            <div class="flex flex-row pl-4 gap-2" style="border: 0 solid #b7860f">
              <nxt-input class="w-[80px]" [noPadding]="true" [nxtFormControl]="form.controls.code.controls.x" [isNumber]="true"></nxt-input>
              <nxt-input class="w-[80px]" [noPadding]="true" [nxtFormControl]="form.controls.code.controls.y" [isNumber]="true"></nxt-input>
            </div>
            <div class="flex flex-col gap-2 items-center">
              <mat-button-toggle-group class="small-toggle-group" [formControl]="form.controls.code.controls.textAlign">
                <mat-button-toggle value="start">Links</mat-button-toggle>
                <mat-button-toggle value="center">Zentriert</mat-button-toggle>
                <mat-button-toggle value="end">Rechts</mat-button-toggle>
              </mat-button-toggle-group>
              <mat-button-toggle-group class="w-fit small-toggle-group" [formControl]="form.controls.code.controls.fontWeight">
                <mat-button-toggle value="normal">Normal</mat-button-toggle>
                <mat-button-toggle value="bold">Fett</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="pl-3" style="border: 0 solid #8716cf">
              <nxt-button (click)="setPosClicked('code')" [smallButton]="true">mit Maus festlegen</nxt-button>
            </div>
          </div>
          <div class="flex flex-row gap-3 w-full">
            <nxt-input [center]="true" [nxtFormControl]="form.controls.code.controls.fontSize" [isNumber]="true"></nxt-input>
            <nxt-input [center]="true" [nxtFormControl]="form.controls.code.controls.fontColor"></nxt-input>
          </div>
        </div>
      </nxt-box>

      <nxt-box class="w-full" bg="normal" headerText="Footer">
        <div class="flex flex-col justify-center items-center pt-3 w-full gap-4" style="border:0 solid pink">
          <div class="flex flex-row items-center justify-around w-full" style="border: 0 solid blue">
            <div class="grid grid-cols-3 pos-cursor h-fit" style="border: 0 solid orangered">
              <div></div>
              <div>
                <mat-icon (click)="setPosY('footer',-1)" fontSet="material-symbols-outlined">keyboard_arrow_up</mat-icon>
              </div>
              <div></div>
              <div>
                <mat-icon (click)="setPosX('footer',-1)" fontSet="material-symbols-outlined">keyboard_arrow_left</mat-icon>
              </div>
              <div></div>
              <div>
                <mat-icon (click)="setPosX('footer',1)" fontSet="material-symbols-outlined">keyboard_arrow_right</mat-icon>
              </div>
              <div></div>
              <div>
                <mat-icon (click)="setPosY('footer',1)" fontSet="material-symbols-outlined">keyboard_arrow_down</mat-icon>
              </div>
              <div></div>
            </div>
            <div class="flex flex-row pl-4 gap-2" style="border: 0 solid #b7860f">
              <nxt-input class="w-[80px]" [noPadding]="true" [nxtFormControl]="form.controls.footer.controls.x" [isNumber]="true"></nxt-input>
              <nxt-input class="w-[80px]" [noPadding]="true" [nxtFormControl]="form.controls.footer.controls.y" [isNumber]="true"></nxt-input>
            </div>
            <div class="flex flex-col gap-2 items-center">
              <mat-button-toggle-group class="small-toggle-group" [formControl]="form.controls.footer.controls.textAlign">
                <mat-button-toggle value="start">Links</mat-button-toggle>
                <mat-button-toggle value="center">Zentriert</mat-button-toggle>
                <mat-button-toggle value="end">Rechts</mat-button-toggle>
              </mat-button-toggle-group>
              <mat-button-toggle-group class="w-fit small-toggle-group" [formControl]="form.controls.footer.controls.fontWeight">
                <mat-button-toggle value="normal">Normal</mat-button-toggle>
                <mat-button-toggle value="bold">Fett</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="pl-3" style="border: 0 solid #8716cf">
              <nxt-button (click)="setPosClicked('footer')" [smallButton]="true">mit Maus festlegen</nxt-button>
            </div>
          </div>
          <div class="flex flex-row gap-3 w-full">
            <nxt-input [center]="true" [nxtFormControl]="form.controls.footer.controls.fontSize" [isNumber]="true"></nxt-input>
            <nxt-input [center]="true" [nxtFormControl]="form.controls.footer.controls.fontColor"></nxt-input>
          </div>
        </div>
      </nxt-box>
    </div>
  </div>
  <div class="flex flex-row justify-around pt-3">
    <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
    <nxt-button (click)="saveClicked()">Speichern</nxt-button>
  </div>
</div>

