<div fxLayout="column">
  <div *ngIf="message" style="margin-bottom:24px; margin-top:10px;" [innerHTML]="message | safeHtml">
  </div>
  <div *ngIf="inputFormControl">
    <nxt-textarea
      [placeholder]="placeholder"
      [nxtFormControl]="inputFormControl"
      (enter)="enterClicked()"
      [rows]="15"
      [cols]="100"

    ></nxt-textarea>
  </div>
  <div style="padding-left: 10px;">{{ charCounterText }}</div>

  <div *ngIf="bodyPutsTattoo">
    <div *ngIf="skillsText" class="text-120 p-2 m-2 mouse-pointer"
         style="border: 1px solid #808080; border-radius: 3px" (click)="addSkill()">Stil: {{ skillsText }}
    </div>
    <div *ngFor="let bodyPutTattoo of bodyPutsTattoo; let i = index"
         fxLayout="row"
         fxLayoutGap="10px"
         class="text-120 p-2 m-2 mouse-pointer"
         style="border: 1px solid #808080; border-radius: 3px"
         (click)="addBodyPutText(bodyPutTattoo)"
    >
      <div *ngIf="bodyPutsTattoo.length > 1">{{ (i + 1)}}.Motiv: </div>
      <div *ngIf="bodyPutsTattoo.length === 1">Motiv: </div>
      <div>{{ bodyPutTattoo.motive }}</div>
      <div>{{ bodyPutTattoo.bodyPut | bodyPutText: 'de' }}</div>
      <div>{{ bodyPutTattoo.size }}</div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-around">
    <nxt-button (click)="cancelClicked()">ABBRECHEN</nxt-button>
    <nxt-button (click)="okClicked()">OK</nxt-button>
  </div>
</div>
<!--< button [ngStyle]="{backgroundColor: color.backgroundColor, color: color.fontColor}" style="margin: 10px;" mat-raised-button color="primary"
        (click)="close(color)">{{buttonText}}</button>-->



