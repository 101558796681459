import {NxtArtist} from '../../common-interfaces/nxt.artist.interface';
import {TypeTools} from './type.tools';

export class DisplayWithTools {

  static displayWithName = (data: any) => {
    if (TypeTools.isString(data)) {
      return data;
    }
    if (data) {
      return data.name;
    } else {
      return '';
    }
  };

  static displayWithTitle = (data: any) => {
    if (TypeTools.isString(data)) {
      return data;
    }
    if (data?.title) {
      return data.title;
    } else {
      return '';
    }
  };

  static displayWithFullName = (data: any) => {
    if (TypeTools.isString(data)) {
      return data;
    }
    if (data) {
      return data.fullName;
    } else {
      return '';
    }
  };

  static displayWithText = (data: any, highlight?: (text: string) => string) => {
    if (TypeTools.isString(data)) {
      return data.toString();
    }
    if (data) {
      if (highlight) {
        return highlight(data.text);
      }
    } else {
      return '';
    }
  };

  static displayWithDescription = (data: any, highlight?: (text: string) => string) => {
    if (TypeTools.isString(data)) {
      return data;
    }
    if (data) {
      if (highlight) {
        return highlight(data.description);
      }
      return data.description;
    } else {
      return '';
    }
  };

  static displayWithUsername = (data: any, highlight: (text: string) => string) => {
    if (TypeTools.isString(data)) {
      return data;
    }
    if (data) {
      return highlight(data.username);
    } else {
      return '';
    }
  };


  static displayWith(field: string) {
    return (data: any) => {
      if (TypeTools.isString(data)) {
        return data;
      }
      if (data) {
        return data[field];
      } else {
        return '';
      }
    };
  }

  static displayWithNxtArtist(nxtArtist: NxtArtist) {
    return nxtArtist.name;
  }
}
