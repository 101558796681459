import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormControlWrapper} from '../form-control-wrapper';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {Subscription} from 'rxjs';
import {MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {Log} from '../../../common-browser/log/log.tools';
import moment, {Moment} from 'moment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {MatError, MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon} from '@angular/material/icon';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgIf, NgStyle} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {FormFieldWrapperComponent} from '../../form-field-wrapper/form-field-wrapper.component';

@Component({
    selector: 'nxt-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    imports: [FormFieldWrapperComponent, FlexModule, NgClass, ExtendedModule, NgIf, MatIcon, MatTooltip, MatFormField, MatLabel, MatInput, MatDatepickerInput, FormsModule, NgStyle, ReactiveFormsModule, MatDatepickerToggle, MatSuffix, MatDatepicker, MatError],
  standalone: true,
})
export class DatePickerComponent extends FormControlWrapper implements OnInit, OnDestroy, OnChanges {


  @Input() monthPicker: boolean;
  @Input() minDate: any;
  @Input() readonly = false;
  @Input() placeholder: string;
  @Input() showTodayButton: boolean;
  @Input() showWeekStart: boolean;
  @Input() showJumpDayButtons: boolean;
  @Input() showJumpWeekButtons: boolean;
  @Input() showJumpMonthButtons: boolean;
  @Input() noPadding = false;
  @Input() width = '';

  // @Input() nxtFormControl: NxtFormControl;
  // @Input() onlyMonth?: boolean;
  @Input() displayDateFormat = 'EEE dd.MM.yyyy';
  @Input() disableOpenOnClickInput = false;
  fullWidthMatFormField = false;

  @Input() set nxtModel(value) {
    this.nxtFormControl.setValue(value, {emitEvent: false});
  }

  @Output() nxtModelChange = new EventEmitter<string>();

  @ViewChild('myInput', {static: false}) set myInput(elementRef: any) {
    this.nxtFormControl.element = elementRef.nativeElement;
  }

  @ViewChild('datePicker') datePicker: MatDatepicker<any>;

  private changeSubscription: Subscription;
  public inputWidth = '130px';
  @Input() noPlaceholder = false;

  constructor(
    @Inject(MAT_DATE_FORMATS) public dateFormats: MatDateFormats,
    @Inject(LOCALE_ID) public localeId: string) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.width) {
      if (this.width) {
        this.inputWidth = '100%';
        this.fullWidthMatFormField = true;
      } else {
        this.inputWidth = '130px';
        this.fullWidthMatFormField = false;
      }
    }
    if (changes.displayDateFormat) {
      this.dateFormats.display.dateInput = this.displayDateFormat;
      if (this.nxtFormControl.element) {
        this.nxtFormControl.setValue(this.nxtFormControl.value);
      }
    }
  }


  setToday() {
    this.nxtFormControl.setValue(DateTools.format(Date.now(), this.format));
    this.nxtFormControl.markAsDirty();
  }

  ngOnInit(): void {
    this.dateFormats.display.dateInput = this.displayDateFormat;
    this.changeSubscription = this.nxtFormControl.valueChanges.subscribe((value) => {
      this.nxtModelChange.emit(DateTools.format(value, this.format));
      if (!this.monthPicker) {
        this.closeDatePickerPopup();
      }
      this.dateFormats.display.dateInput = this.displayDateFormat;
    });
  }

  addDay(days: number) {
    this.nxtFormControl.setValue(DateTools.format(DateTools.addDays(this.nxtFormControl.value, days), this.format));
  }

  addMonth(days: number) {
    this.nxtFormControl.setValue(DateTools.format(DateTools.addMonths(this.nxtFormControl.value, days), this.format));
  }

  ngOnDestroy(): void {
    this.changeSubscription?.unsubscribe();
  }

  closeDatePickerPopup() {
    if (this.datePicker?.opened) {
      this.datePicker.close();
      Log.debug('DATE-PICKER: closeDatePickerPopup');
    }
  }

  setThisWeek() {
    let date = Date.now();
    while (DateTools.getDayOfWeek(date) !== 0) {
      date = DateTools.addDays(date, -1);
    }
    this.nxtFormControl.setValue(DateTools.format(date, this.format));
    this.nxtFormControl.markAsDirty();
  }

  private get format() {
    if (this.monthPicker) {
      return 'yyyy-MM';
    } else {
      return 'yyyy-MM-dd';
    }

  }

  chosenYearHandler(normalizedYear: Moment) {
    if (this.monthPicker) {
      const ctrlValue = moment(DateTools.parse(this.nxtFormControl.value, this.format));
      ctrlValue.year(normalizedYear.year());
      this.nxtFormControl.setValue(DateTools.format(ctrlValue.valueOf(), this.format));
    }
  }

  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    if (this.monthPicker) {
      const ctrlValue = moment(DateTools.parse(this.nxtFormControl.value, this.format));
      ctrlValue.month(normalizedMonth.month());
      this.nxtFormControl.setValue(DateTools.format(ctrlValue.valueOf(), this.format));
      datepicker.close();
    }
  }

  inputClicked() {
    if (!this.disableOpenOnClickInput) {
      this.datePicker.open();
    }
  }

  public toggle() {
    this.datePicker.open();
  }
}
