<div
  [mtxTooltip]="tooltipTemplate"
  mtxTooltipClass="no-style mat-tooltip-width-content mat-tooltip-offset-right"
  class="event-all-day"
  mtxTooltipPosition="after"
  [mtxTooltipPositionAtOrigin]="true"
  [ngStyle]="{color: textColor(),backgroundColor: event().color?.primary}"
  tabindex="0"
  role="application"
>
  <div class="flex flex-row" style="word-wrap: break-word; text-wrap: initial; line-height: 1;">
    <div [innerHTML]="event().title"></div>
    @if (event().meta.artistSpot.artistIsInApartment) {
      🏠
    }
  </div>
</div>

<ng-template #tooltipTemplate>
  <div>
    <nxt-calendar-artist-spot-tooltip [artistSpot]="event().meta.artistSpot" [artistColor]="event().color.primary"></nxt-calendar-artist-spot-tooltip>
  </div>
</ng-template>
