import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[nxtHideHeight]',
    standalone: true
})
export class HideDirective implements OnInit, OnDestroy, OnChanges {
  @Input() nxtHideHeight: boolean;

  constructor(private element: ElementRef) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.nxtHideHeight?.currentValue) {
      this.element.nativeElement.classList.add('nxt-hide-height');
    } else {
      this.element.nativeElement.classList.remove('nxt-hide-height');
    }
  }
}
