import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Optional, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NgForOf, NgIf} from '@angular/common';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {WorkingDayService} from '../../../services/working-day.service';
import {MatTooltip} from '@angular/material/tooltip';
import moment from 'moment';
import {DayOfWeek} from '../../../common-interfaces/date.interface';

@Component({
  selector: 'nxt-date-select',
  templateUrl: './date-select.component.html',
  styleUrls: ['./date-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgForOf, NxtDatePipe, NgIf, NxtButtonIconComponent, MatTooltip, NxtDatePipe],
})

export class DateSelectComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  private currentMarkDateRange: { dateStringFrom: string; dateStringTill: string };
  startYear = Date.now().dateFormat('yyyy');
  selectedMonth = signal('');
  selectedYear = signal('');

  constructor(
    private cdRef: ChangeDetectorRef,
    @Optional() private dialogRef: MatDialogRef<DateSelectComponent>,
    private workingDayService: WorkingDayService,
  ) {
    super();
  }

  todayDateString = Date.now().dateFormat('yyyy-MM-dd');
  weeks: {
    days: {
      outline?: string; text: string, dateString: string, selected: boolean, text2: string
    }[]
  }[] = [];
  years: { selected: boolean, yearString: string }[] = [];
  months: { selected: boolean, monthOfYear: string }[] = [
    {monthOfYear: '01', selected: false},
    {monthOfYear: '02', selected: false},
    {monthOfYear: '03', selected: false},
    {monthOfYear: '04', selected: false},
    {monthOfYear: '05', selected: false},
    {monthOfYear: '06', selected: false},
    {monthOfYear: '07', selected: false},
    {monthOfYear: '08', selected: false},
    {monthOfYear: '09', selected: false},
    {monthOfYear: '10', selected: false},
    {monthOfYear: '11', selected: false},
    {monthOfYear: '12', selected: false},
  ];

  selectedMonthString = Date.now().dateFormat('yyyy-MM');

  ngOnInit() {
    if (this.currentMarkDateRange) {
      this.startYear = this.currentMarkDateRange.dateStringFrom.dateFormat('yyyy');
    }
    this.reDraw();
    this.calcSelectedMonthYearString();
  }

  buildYears() {
    this.years = [];
    for (let i = parseInt(this.startYear, 10) - 2; i <= parseInt(this.startYear, 10) + 2; i++) {
      const selected = i.toString() === this.selectedMonthString.dateFormat('yyyy');
      this.years.push({selected, yearString: i.toString()});
    }
  }

  reDraw() {
    this.calcWeeks();
    this.buildYears();
    this.setSelection();
    this.setMarkDateRange();
    this.cdRef.detectChanges();
  }

  nxtOnDestroy() {
  }

  calcWeeks() {
    const dateStrings = DateTools.getDateStringsFromMonth(this.selectedMonthString);
    this.weeks = [];
    for (const [index, dateString] of dateStrings.entries()) {
      let text2 = this.workingDayService.getPublicHoliday(dateString)?.name || '';
      if (dateString === DateTools.formatNowDate()) {
        if (text2) {
          text2 = 'Heute\n' + text2;
        } else {
          text2 = 'Heute';
        }
      }
      if (index === 0) {
        this.weeks.push({days: []});
        const diffToMoney = DateTools.getDayOfWeek(dateString);
        for (let i = 0; i < diffToMoney; i++) {
          this.weeks[0].days.push({text: '', dateString: '', selected: false, text2: ''});
        }
        this.weeks[0].days.push({text: dateString.dateFormat('d'), dateString, selected: false, text2});
      } else {
        if (DateTools.getDayOfWeek(dateString) === DayOfWeek.Monday) {
          this.weeks.push({days: []});
        }
        this.weeks[this.weeks.length - 1].days.push({text: dateString.dateFormat('d'), dateString, selected: false, text2});
      }
    }
    let lastDateString = DateTools.addDays(dateStrings[dateStrings.length - 1], 1).dateFormatDate();
    while (DateTools.getDayOfWeek(lastDateString) !== DayOfWeek.Monday) {
      this.weeks[this.weeks.length - 1].days.push({text: '', dateString: '', selected: false, text2: ''});
      lastDateString = DateTools.addDays(lastDateString, 1).dateFormatDate();
    }
    this.setMarkDateRange();
  }

  yearClicked(year: string) {
    this.selectYear(year);
    this.calcSelectedMonthYearString();
  }

  monthClicked(monthOfYear: string) {
    this.selectMonth(monthOfYear);
    this.calcSelectedMonthYearString();
  }

  dateClicked(dateString: string) {
    if (dateString) {
      this.selectDate(dateString);
    }
    this.calcSelectedMonthYearString();
  }

  getWeek(week: { days: { outline?: string; text: string, dateString: string, selected: boolean, text2: string }[] }) {
    return moment(week.days.filter(d => !!d.dateString)[0].dateString.dateParse()).week();
  }

  setSelection() {
    this.selectYear(this.selectedMonthString.dateFormat('yyyy'));
    this.selectMonth(this.selectedMonthString.dateFormat('MM'));
  }

  private selectDate(dateString: string) {
    this.dialogRef?.close(dateString);
  }

  private selectMonth(monthOfYear: string) {
    this.months.forEach(y => y.selected = y.monthOfYear === monthOfYear);
  }

  private selectYear(year: string) {
    this.years.forEach(y => y.selected = y.yearString === year);
  }

  private calcSelectedMonthYearString() {
    const selectedMonthString = this.months.find(m => m.selected)?.monthOfYear;
    let selectedYearString = this.years.find(y => y.selected)?.yearString;
    if (!selectedYearString) {
      selectedYearString = this.selectedYear();
    }
    if (selectedMonthString && selectedYearString) {
      this.selectedMonthString = `${selectedYearString}-${selectedMonthString}`;
      this.calcWeeks();
    }
    this.selectedMonth.set(selectedMonthString);
    this.selectedYear.set(selectedYearString);
  }

  markDateRange(dateStringFrom: string, dateStringTill: string) {
    this.currentMarkDateRange = {dateStringFrom, dateStringTill};
    this.reDraw();

  }

  test() {
    this.markDateRange('2024-03-01', '2024-03-16');
  }

  private setMarkDateRange() {
    if (this.currentMarkDateRange) {
      const days = DateTools.getDaysBetween(this.currentMarkDateRange.dateStringFrom, this.currentMarkDateRange.dateStringTill);
      for (const [index, dateString] of days.entries()) {
        for (const week of this.weeks) {
          for (const day of week.days) {
            if (day.dateString === dateString) {
              day.outline = index === 0 ? 'start' : index === days.length - 1 ? 'end' : 'middle';
              if (days.length === 1) {
                day.outline += ' end';
              }
            }
          }
        }
      }
    }
  }

  closeClicked() {
    this.dialogRef.close();
  }

  jumpToThisMonth() {
    this.yearClicked(DateTools.formatNow('yyyy'));
    this.monthClicked(DateTools.formatNow('MM'));
  }

  jumpYears(number: number) {
    this.startYear = (parseInt(this.startYear, 10) + number).toString();
    this.buildYears();
    this.cdRef.detectChanges();
  }
}
