export class TextTools {

    public static splitNameToGivenAndFamilyName(nameTotal: string) {
        const names = nameTotal.trim().split(' ');
        const result = {givenName: '', familyName: ''};
        if (names.length === 2) {
            result.givenName = names[0];
            result.familyName = names[1];
        } else if (names.length === 3) {
            result.givenName = names[0] + ' ' + names[1];
            result.familyName = names[2];
        }
        return result;
    }

}
