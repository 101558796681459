import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import {TypeTools} from './type.tools';
import {StringTools} from './string.tools';
import {ObjectTools} from './object.tools';
import {DurationTools} from './duration.tools';
import {WorkingDayTools} from './working-day.tools';
import {DayOfWeek, Month} from '../../common-interfaces/date.interface';


export enum DateFormatEnum {
  ISO = 'yyyy-MM-ddTHH:mm:ss.SSSZ',
  ISODate = 'yyyy-MM-dd',
  GermanDate = 'dd.MM.yyyy'
}

export type DateFormat =
  'yyyy-MM-dd'
  | 'yyyy-MM-ddTHH:mm:ssZ'
  | 'yyyy-MM-ddTHH:mm'
  | 'HH:mm:ss'
  | 'yyyy-MM-ddTHH:mm:ss.SSSZ'
  | 'yyyy-MM-dd HH:mm:ss.SSS'
  | 'yyyy-MM-dd HH:mm:ss'
  | 'dd.MM.yyyy HH:mm:ss'
  | string
  | DateFormatEnum;

export class Now {

  public value: number;

  constructor() {
    this.value = Date.now();
  }


  public toString(dateFormat: DateFormat = 'yyyy-MM-ddTHH:mm:ssZ') {
    return this.format(dateFormat);
  }

  public format(format: DateFormat) {
    return DateTools.format(this.value, format);
  }

  public addDays(days: number): Now {
    this.value = DateTools.addDays(this.value, days);
    return this;
  }
}

export class TodayDay {
  public value: number;

  constructor() {
    const d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    this.value = d.getTime();
  }

  addDays(days: number) {
    this.value = DateTools.addDays(this.value, days);
    return this;
  }

  format(format: string) {
    return DateTools.format(this.value, format);
  }
}

export class DateTools {

  public static get todayDateString() {
    return DateTools.format(Date.now(), 'yyyy-MM-dd');
  }

  public static get yesterdayDateString() {
    return DateTools.format(DateTools.addDays(Date.now(), -1), 'yyyy-MM-dd');
  }

  static get todayDay() {
    return new TodayDay();
  }

  static get now(): Now {
    return new Now();
  }

  // private static isInit = false;


  public static formatNow(format: string) {
    return DateTools.format(Date.now(), format);
  }

  public static currentHours() {
    return parseInt(DateTools.formatNow('HH'), 10);
  }

  public static currentHoursDecimal() {
    const hours = parseInt(DateTools.formatNow('HH'), 10);
    const minutes = parseInt(DateTools.formatNow('mm'), 10);
    return hours + (minutes / 60);
  }

  public static getHours(date: any) {
    return parseInt(DateTools.parse(date).dateFormat('HH'), 10);
  }

  public static formatNowDate() {
    return DateTools.format(Date.now(), 'yyyy-MM-dd');
  }

  public static formatTomorrowDate() {
    return DateTools.format(Date.now().dateAddDays(1), 'yyyy-MM-dd');
  }

  static init() {
  }

  static parseFormat(value: any, fromFormat: DateFormat, toFormat?: DateFormat): string {
    this.init();
    if (!toFormat) {
      return DateTools.format(DateTools.parse(value), fromFormat);
    } else {
      const dateNumber = DateTools.parse(value, fromFormat);
      return DateTools.format(dateNumber, toFormat);
    }
  }

  static isToday(value: any): boolean {
    return DateTools.format(value, 'yyyy-MM-dd') === DateTools.todayDateString;
  }

  static formatTime(value: any, short: boolean, longFormatIfNeeded = 'HH:mm:ss'): string {
    let result = DateTools.format(value, longFormatIfNeeded);
    if (short && result.endsWith(':00')) {
      result = result.substring(0, result.length - 3);
    }
    if (short && result.endsWith(':00')) {
      result = result.substring(0, result.length - 3);
    }
    return result;
  }

  /**
   * EE = Mo.
   * EEE = Mo.
   * EEEE = Montag
   */
  static format(value: any, format: DateFormat = 'yyyy-MM-dd HH:mm:ss', lang: 'en' | 'de' = 'de'): string {
    try {
      // this.init();
      if (!TypeTools.is(value) || Number.isNaN(value)) {
        return '';
      }
      if (format.startsWith('yyy-')) {
        throw Error('falschen Jahresformat');
      }
      const momentFormat = format
        .replaceAll('yyyy', 'YYYY')
        .replaceAll('yy', 'YY')
        .replaceAll('dd', 'DD')
        .replaceAll('d', 'D')
        .replaceAll('EEEE', 'dddd')
        .replaceAll('EEE', 'ddd')
        .replaceAll('EE', 'ddd');

      let result = '';
      let parsedValue: number;
      if (typeof value === 'string') {
        parsedValue = (new Date(value)).getTime();
      }
      if (typeof value.toDate === 'function') {
        parsedValue = value.toDate();
      }
      if (typeof value._seconds === 'number') {
        parsedValue = value._seconds * 1000;
      }
      if (typeof value.seconds === 'number') {
        parsedValue = value.seconds * 1000;
      } else {
        parsedValue = value;
      }
      if (moment.locale() !== lang) {
        moment.locale(lang);
      }
      result = moment(parsedValue).format(momentFormat);
      if (result === null) {
        return '';
      }
      return result ?? '';
    } catch (err) {
      // debugger;
      return '';
    }
  }

  static parse(value: any, format = '', isUTC = false): number {
    this.init();

    if (typeof value?.getTime === 'function') {
      return value.getTime();
    }

    if (typeof value === 'number') {
      return value;
    }

    if (StringTools.nullOrEmpty(value)) {
      return 0;
    }
    if (typeof value._seconds === 'number') {
      return value._seconds * 1000 + (value._nanoseconds / 1000000);
    }

    if (typeof value.seconds === 'number') {
      return value.seconds * 1000;
    }

    if (typeof value === 'string' && value.indexOf('.') === -1 && value.length === 13) {
      return parseInt(value, 10);
    }

    let lang = 'de';

    if (typeof value === 'string' && value.length !== format.length) {
      value = value.trim().trimChar('\n');
      const formatAndLang = DateTools.getFormatAndLang(value);
      if (!formatAndLang) {
        throw Error('Datum kann nicht geparst werden: |' + value + '|\n\n');
      }
      format = formatAndLang.format;
      lang = formatAndLang.lang;
    }
    format = format.replace('yyyy', 'YYYY').replace('yy', 'YY').replace('ddd', 'DDD').replace('dd', 'DD').replace('d', 'D');

    if (isUTC) {
      return moment.utc(value, format, lang).valueOf();
    }
    // console.log('moment: ' + value + ' | ' + format);
    return moment(value, format, lang).valueOf();
  }

  static dateDiff(date1: number, date2: number, abs = false) {
    if (abs) {
      return Math.abs(date1 - date2);
    } else {
      return date1 - date2;
    }
  }

  static dateDiffToNow(date: any) {
    if (typeof date !== 'number') {
      date = this.parse(date);
    }
    return Date.now() - date;
  }

  static dateDiffToNowAbs(date: any) {
    if (typeof date !== 'number') {
      date = this.parse(date);
    }
    return Math.abs(Date.now() - date);
  }

  /*static fromNow(date: number) {
      return this.dateDiffToNowText(date);
  }*/

  static dateDiffToNowText(date: any, onlyPast = false) {
    this.init();
    if (typeof date !== 'number') {
      date = this.parse(date);
    }
    if (Math.abs(Date.now() - date) < 60000) {
      // return 'Jetzt';
    }
    if (onlyPast && date > Date.now()) {
      return moment(Date.now()).fromNow();
    }
    return moment(date).fromNow();
  }

  static dateDiffText(date1: any, date2: any) {
    this.init();
    const d1 = new Date(DateTools.parse(date1));
    const d2 = new Date(DateTools.parse(date2));
    return moment(d1).from(d2);
  }

  static dateDiffToNowOnlyDaysText(date: any) {
    this.init();
    if (typeof date !== 'number') {
      date = this.parse(date);
    }
    if (date.dateFormat('yyyy-MM-dd') === DateTools.formatNow('yyyy-MM-dd')) {
      return 'Heute';
    } else if (date.dateFormat('yyyy-MM-dd') === Date.now().dateAddDays(1).dateFormat('yyyy-MM-dd')) {
      return 'Morgen';
    } else if (date.dateFormat('yyyy-MM-dd') === Date.now().dateAddDays(-1).dateFormat('yyyy-MM-dd')) {
      return 'Gestern';
    } else {
      const from = DateTools.parse(date.dateFormat('yyyy-MM-dd') + ' ' + DateTools.formatNow('HH:mm:ss'));
      return moment(from).fromNow();
    }
  }

  /*static dateDiffToNowTextDays(date: any) {
      this.init();
      date = DateTools.clearTime(date);
      return moment(date).fromNow();
  }*/

  static dateDiffToTodayTextOld(date: any) {
    this.init();
    date = DateTools.clearTime(date);
    if (typeof date !== 'number') {
      date = this.parse(date);
    }
    const today = DateTools.clearTime(Date.now());
    if (Math.abs(today - date) < 100000) {
      return 'heute';
    }
    return moment(today).to(moment(date));
  }

  static monthDiff(date1: any, date2: any) {
    return moment(DateTools.parse(date1)).diff(moment(DateTools.parse(date2)), 'months', true);
  }


  static addDays(date: any, days: number): number {
    const result = new Date(DateTools.parse(date));
    result.setDate(result.getDate() + days);
    return result.getTime();
  }

  static betweenDays(date: number, from: number, till: number) {
    date = DateTools.clearTime(date);
    from = DateTools.clearTime(from);
    till = DateTools.clearTime(till);
    return date >= from && date <= till;
  }

  static clearTime(datetime: any): number {
    if (typeof datetime !== 'number') {
      datetime = DateTools.parse(datetime);
    }
    const date = new Date(datetime);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
  }

  static clearSeconds(datetime: any): number {
    if (typeof datetime !== 'number') {
      datetime = DateTools.parse(datetime);
    }
    const date = new Date(datetime);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
  }

  static clearDayAndTime(datetime: number): number {
    const date = new Date(datetime);
    date.setDate(1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
  }

  static getLastMonday(weeks: number = 0): number {
    const d = new Date();
    for (let i = 1; i < weeks; i++) {
      d.setDate(d.getDate() - 7);
    }
    d.setDate(d.getDate() + 1 - (d.getDay() || 7));
    return DateTools.clearTime(d.getTime());
  }

  static getDateFromDayOfWeekBeforeOrSame(date: any, dayOfWeek: DayOfWeek): number {
    let d = DateTools.parse(date);
    for (let i = 0; i < 8; i++) {
      if (DateTools.getDayOfWeek(d) === dayOfWeek) {
        return d.clearTime();
      }
      d = d.dateAddDays(-1);
    }
    return 0;
  }

  static getDateFromDayOfWeekAfterOrSame(date: any, dayOfWeek: DayOfWeek): number {
    let d = DateTools.parse(date);
    for (let i = 0; i < 8; i++) {
      if (DateTools.getDayOfWeek(d) === dayOfWeek) {
        return d;
      }
      d = d.dateAddDays(1);
    }
    return 0;
  }

  static getMondayBefore(date: any, weeks: number): number {
    const d = new Date(DateTools.parse(date));
    for (let i = 1; i < weeks; i++) {
      d.setDate(d.getDate() - 7);
    }
    d.setDate(d.getDate() + 1 - (d.getDay() || 7));
    return d.getTime();
  }

  static getNextMonday(weeks: number): number {
    const d = new Date();
    for (let i = 1; i < Math.abs(weeks); i++) {
      d.setDate(d.getDate() + 7);
    }
    d.setDate(d.getDate() + 1 - (d.getDay() || 7));
    return d.getTime();
  }

  static getNextTuesday(from: number, weeks: number): number {
    const d = new Date(from);
    weeks++;
    for (let i = 1; i < Math.abs(weeks); i++) {
      d.setDate(d.getDate() + 7);
    }
    while (d.getDay() !== 2) {
      d.setDate(d.getDate() + 1);
    }
    return d.getTime();
  }


  static getNextDayOfWeek(fromDate: any, dayOfWeek: DayOfWeek): number {
    const d = new Date(DateTools.parse(fromDate));
    d.setDate(d.getDate() + 1);
    while (DateTools.getDayOfWeek(d) !== dayOfWeek) {
      d.setDate(d.getDate() + 1);
    }
    return d.getTime();
  }

  static getDayOfWeekText(dayOfWeek: DayOfWeek): string {
    switch (dayOfWeek) {
      case DayOfWeek.Monday:
        return 'Montag';
      case DayOfWeek.Tuesday:
        return 'Dienstag';
      case DayOfWeek.Wednesday:
        return 'Mittwoch';
      case DayOfWeek.Thursday:
        return 'Donnerstag';
      case DayOfWeek.Friday:
        return 'Freitag';
      case DayOfWeek.Saturday:
        return 'Samstag';
      case DayOfWeek.Sunday:
        return 'Sonntag';
    }
    return '';
  }

  static getDayOfWeekTextShort(dayOfWeek: DayOfWeek): string {
    switch (dayOfWeek) {
      case DayOfWeek.Monday:
        return 'Mo';
      case DayOfWeek.Tuesday:
        return 'Di';
      case DayOfWeek.Wednesday:
        return 'Mi';
      case DayOfWeek.Thursday:
        return 'Do';
      case DayOfWeek.Friday:
        return 'Fr';
      case DayOfWeek.Saturday:
        return 'Sa';
      case DayOfWeek.Sunday:
        return 'So';
    }
    return '';
  }

  static getDayOfWeek(date: any): DayOfWeek {
    let day = new Date(DateTools.parse(date)).getDay() - 1;
    if (day === -1) {
      day = 6;
    }
    return day;
  }

  static getMonth(date: any): Month {
    return new Date(DateTools.parse(date)).getMonth() + 1;
  }

  static addMonths(date: number, monthsToAdd: number) {
    return moment(date).add(monthsToAdd, 'month').valueOf();
  }

  static addYears(date: number, yearsToAdd: number) {
    return moment(date).add(yearsToAdd, 'year').valueOf();
  }

  static parseToDateIfString(date: any): Date {
    if (typeof date === 'string') {
      return new Date(date);
    }
    return date;
  }

  static parseAndClearTime(paymentDate: any): number {
    return this.clearTime(this.parse(paymentDate));
  }

  static parseAndClearDateTime(paymentDate: any): number {
    return DateTools.clearDayAndTime(this.parse(paymentDate));
  }


  /*static getDayNames(de: string) {

      ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
  }*/


  static getEndOfMonthFactorFromNow() {
    const lastDay = DateTools.getLastDayOfThisMonth();
    return lastDay / (new Date()).getDate();
  }

  static getLastDayOfThisMonth() {
    return parseInt(DateTools.format(DateTools.addDays(DateTools.addMonths(DateTools.clearDayAndTime(Date.now()), 1), -1), 'dd'), 10);
  }

  static getLastOfMonth(date: any) {
    const dateWithoutTime = DateTools.clearDayAndTime(DateTools.parse(date));
    const nextMonth = DateTools.addMonths(dateWithoutTime, 1);
    return DateTools.addDays(nextMonth, -1);
  }

  static formatDatesInObj(obj: any) {
    const clone = ObjectTools.clone(obj);
    Object.keys(clone).forEach(key => {
      if (clone[key].seconds) {
        clone[key] = DateTools.format(clone[key], 'yyyy-MM-dd HH:mm:ss');
      }
    });
    return clone;
  }

  static getDateString(date: any) {
    return DateTools.format(date, 'yyyy-MM-dd');
  }

  static test() {

    const date1 = '2025-01-31T10:25:27.333Z'.dateParse();
    console.log(date1.dateFormat('yyyy-MM-dd HH:mm:ss.SSS'));
    console.log(date1.dateFormat('yyyy-MM-dd HH:mm:ss.SSS'));


    const def = DateTools.parse('2024-12-30 09:53:46').dateFormatDate();
    console.log(def);

    const dateString0001 = DateTools.parse('2024-11-13').dateFormatDate();

    const dateString001 = DateTools.parse('7.5.2024').dateFormatDate();
    console.log(dateString001);
    console.log(dateString001);

    const abc = moment('Dec 21, 2024', 'MMM DD, YYYY', 'en').valueOf().dateFormatDate();
    console.log(abc);
    console.log(abc);
    console.log(abc);
    const dateString = DateTools.parse('Dec 21, 2024', 'MMM dd, yyyy').dateFormatDate();
    console.log(dateString);
    console.log(dateString);
    console.log(dateString);
    console.log(dateString);

    const date = DateTools.parse('02 Dezember 2024');
    console.log(date);

    const result1 = DateTools.format('2021-01-01', 'd');
    const result2 = DateTools.format(Date.now(), 'EEEE', 'de');
    const result3 = DateTools.format(Date.now(), 'EEE', 'en');
    const result4 = DateTools.format(Date.now(), 'EEE', 'de');


    const result000 = DateTools.parseFormat('2023-07-29', 'yyyy-MM-dd', 'dd.MM.yyyy');
    console.log(result000);
    console.log(result000);
    console.log(result000);


    console.log('start DateTools Test');
    const localDate = DateTools.utcToLocal('2023-06-29 22:30:00');

    console.log(localDate);
    console.log(localDate);
    console.log(localDate);
    console.log(localDate);

    const nextFriday = DateTools.getNextDayOfWeek(DateTools.parse('2022-07-07'), DayOfWeek.Friday);
    const nextFridayString = DateTools.format(nextFriday, 'yyyy-MM-dd');
    if (nextFridayString === '2022-07-08') {
    } else {
      console.error('DateTools-Test nextFriday OK');
    }

    const nextMonday = DateTools.getNextDayOfWeek(DateTools.parse('2022-07-07'), DayOfWeek.Monday);
    const nextMondayString = DateTools.format(nextMonday, 'yyyy-MM-dd');
    if (nextMondayString === '2022-07-11') {
      // console.log('DateTools-Test nextMonday OK');
    } else {
      console.error('DateTools-Test nextMonday wrong');
      debugger;
    }

    const nextWednesday = DateTools.getNextDayOfWeek(DateTools.parse('2022-07-07'), DayOfWeek.Wednesday);
    const nextWednesdayString = DateTools.format(nextWednesday, 'yyyy-MM-dd');
    if (nextWednesdayString === '2022-07-13') {
      // console.log('DateTools-Test nextWednesday OK');
    } else {
      console.error('DateTools-Test nextWednesday wrong');
      debugger;
    }

    const nextThursday = DateTools.getNextDayOfWeek(DateTools.parse('2022-07-07'), DayOfWeek.Thursday);
    const nextThursdayString = DateTools.format(nextThursday, 'yyyy-MM-dd');
    if (nextThursdayString === '2022-07-14') {
      // console.log('DateTools-Test nextThursday OK');
    } else {
      console.error('DateTools-Test nextWednesday wrong');
      debugger;
    }


    const setEarlyHourTo1 = DateTools.parse('2022-07-25 11:00:00', 'yyyy-MM-dd HH:mm:ss');
    const setEarlyHourTo1Result = DateTools.format(DateTools.setEarlyHourTo(setEarlyHourTo1, 14), 'yyyy-MM-dd HH:mm:ss');
    if (setEarlyHourTo1Result === '2022-07-25 14:00:00') {
      // console.log('DateTools-Test nextThursday OK');
    } else {
      console.error('setEarlyHourTo1 WRONG!');
      debugger;
    }

    const setEarlyHourTo2 = DateTools.parse('2022-07-25 15:00:00', 'yyyy-MM-dd HH:mm:ss');
    const setEarlyHourTo2Result = DateTools.format(DateTools.setEarlyHourTo(setEarlyHourTo2, 14), 'yyyy-MM-dd HH:mm:ss');
    if (setEarlyHourTo2Result === '2022-07-25 15:00:00') {
      // console.log('DateTools-Test nextThursday OK');
    } else {
      console.error('setEarlyHourTo1 WRONG!');
      debugger;
    }

    const roundToNextMinute = DateTools.parse('2022-07-25 15:03:00', 'yyyy-MM-dd HH:mm:ss');
    const roundToNextMinuteResult = DateTools.format(DateTools.roundToNextMinute(roundToNextMinute, 15), 'yyyy-MM-dd HH:mm:ss');
    if (roundToNextMinuteResult === '2022-07-25 15:15:00') {
      // console.log('DateTools-Test nextThursday OK');
    } else {
      console.error('roundToNextMinute WRONG!');
      debugger;
    }

    const roundToNextMinute2 = DateTools.parse('2022-07-25 15:00:00', 'yyyy-MM-dd HH:mm:ss');
    const roundToNextMinute2Result = DateTools.format(DateTools.roundToNextMinute(roundToNextMinute2, 15), 'yyyy-MM-dd HH:mm:ss');
    if (roundToNextMinute2Result === '2022-07-25 15:00:00') {
      // console.log('DateTools-Test nextThursday OK');
    } else {
      console.error('roundToNextMinute WRONG!');
      debugger;
    }

    const roundToNextMinute3 = DateTools.parse('2022-07-25 14:59:00', 'yyyy-MM-dd HH:mm:ss');
    const roundToNextMinute3Result = DateTools.format(DateTools.roundToNextMinute(roundToNextMinute3, 15), 'yyyy-MM-dd HH:mm:ss');
    if (roundToNextMinute3Result === '2022-07-25 15:00:00') {
      // console.log('DateTools-Test nextThursday OK');
    } else {
      console.error('roundToNextMinute WRONG!');
      debugger;
    }


    const resultDateFormat = DateTools.parse('2022-01-01').dateFormat('yyyy-MM-dd');
    if (resultDateFormat !== '2022-01-01') {
      console.error('dateFormat WRONG!');
      debugger;
    }

    const resultDateAddDays = DateTools.format(DateTools.parse('2022-01-01').dateAddDays(2), 'yyyy-MM-dd');
    if (resultDateAddDays !== '2022-01-03') {
      console.error('dateFormat WRONG!');
      debugger;
    }


    const resultParseGermanWild1 = DateTools.format(DateTools.parseGermanWild('01.01.2020'), 'yyyy-MM-dd');
    if (resultParseGermanWild1 !== '2020-01-01') {
      console.error('dateFormat WRONG! ' + resultParseGermanWild1);
      debugger;
    }

    const resultParseGermanWild2 = DateTools.format(DateTools.parseGermanWild('01.01'), 'yyyy-MM-dd');
    if (resultParseGermanWild2 !== '2026-01-01') {
      console.error('dateFormat WRONG! ' + resultParseGermanWild2);
      debugger;
    }

    const resultParseGermanWild3 = DateTools.format(DateTools.parseGermanWild('1.7'), 'yyyy-MM-dd');
    if (resultParseGermanWild3 !== '2025-07-01') {
      console.error('dateFormat WRONG! ' + resultParseGermanWild3);
      debugger;
    }

    const resultParseGermanWild4 = DateTools.format(DateTools.parseGermanWild('1.12'), 'yyyy-MM-dd');
    if (resultParseGermanWild4 !== '2025-12-01') {
      console.error('dateFormat WRONG! ' + resultParseGermanWild4);
      debugger;
    }

    if ('2022-06-30'.dateParse() !== '2022-06-30'.dateParse('yyyy-MM-dd')) {
      debugger;
    }

    const result15 = DateTools.getDateFromDayOfWeekBeforeOrSame('2023-02-02', DayOfWeek.Monday).dateFormat('yyyy-MM-dd');
    if (result15 !== '2023-01-30') {
      debugger;
    }

    const result16 = DateTools.getDateFromDayOfWeekBeforeOrSame('2023-02-17', DayOfWeek.Friday).dateFormat('yyyy-MM-dd');
    if (result16 !== '2023-02-17') {
      debugger;
    }

    const result17 = DateTools.getDateFromDayOfWeekBeforeOrSame('2023-02-17', DayOfWeek.Saturday).dateFormat('yyyy-MM-dd');
    if (result17 !== '2023-02-11') {
      debugger;
    }

    const result18 = DateTools.getDateFromDayOfWeekAfterOrSame('2023-02-10', DayOfWeek.Sunday).dateFormat('yyyy-MM-dd');
    if (result18 !== '2023-02-12') {
      debugger;
    }


    const result19 = DateTools.format(DateTools.parse('20221017T100000', 'yyyyMMddTHHmmss'), 'yyyy-MM-ddTHH:mm:ss.SSSZ');
    console.log(result19);

    const result20 = DateTools.format('2022-01-01', 'EE');
    console.log(result20);

    const result21 = DateTools.format('2022-01-01', 'EEE');
    console.log(result21);

    const result22 = DateTools.format('2022-01-01', 'EEEE');
    console.log(result22);


    const result23 = DateTools.completeDateFull('9784', 'dd.MM.yyyy');
    console.log(result23);

    const result24 = DateTools.completeDateFull('311284', 'dd.MM.yyyy');
    console.log(result24);

    const result25 = DateTools.completeDateFull('111984', 'dd.MM.yyyy');
    console.log(result25);

    const result26 = DateTools.completeDateFull('1111984', 'dd.MM.yyyy');
    console.log(result26);


  }

  public static setEarlyHourTo(date: any, hour: number) {
    const dateObj = new Date(DateTools.parse(date));
    if (dateObj.getHours() < hour) {
      dateObj.setHours(hour);
    }
    return dateObj.getTime();
  }

  public static roundToNextMinute(date: any, minutes: number) {
    const dateObj = new Date(DateTools.parse(date));
    dateObj.setSeconds(0, 0);
    const min = dateObj.getMinutes();
    const roundTo = Math.ceil(min / minutes) * minutes;
    const toAdd = roundTo - min;
    return dateObj.getTime() + (toAdd * 60 * 1000);
  }

  static parseGermanWild(dateWild: string, futureIfNoYearSet = true) {
    const regex = new RegExp('^(\\d{1,2})\\.(\\d{1,2})\\.?(\\d{0,4})$', 'g');
    const matches = regex.exec(dateWild);
    let day = 1;
    let month = 0;
    let year = parseInt(DateTools.formatNow('yyyy'), 10);
    let hasYear = false;
    if (matches && matches.length > 1 && matches[1]) {
      day = parseInt(matches[1], 10);
    }
    if (matches && matches.length > 2 && matches[2]) {
      month = parseInt(matches[2], 10);
    }
    if (matches && matches.length > 3 && matches[3]) {
      hasYear = true;
      year = parseInt(matches[3], 10);
    }
    const date = new Date(year, month - 1, day);
    let result = date.getTime();
    if (!hasYear && futureIfNoYearSet) {
      if (result < Date.now()) {
        result = DateTools.addYears(result, 1);
      }
    }
    return result;
  }


  private static getFormatAndLang(value: string): { format: string, lang: string } | undefined {
    // 2024-12-15
    if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
      return {format: 'yyyy-MM-dd', lang: 'de'};
    }
    // 15.12.2024
    if (value.match(/^\d{2}\.\d{2}\.\d{4}$/)) {
      return {format: 'dd.MM.yyyy', lang: 'de'};
    }
    // 2024-12-31T15:31:13.348Z
    if (value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/)) {
      return {format: 'yyyy-MM-ddTHH:mm:ss.SSSZ', lang: 'de'};
    }
    // 2023-06-29 22:30:00
    if (value.match(/^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/)) {
      return {format: 'yyyy-MM-dd HH:mm:ss', lang: 'de'};
    }

    // 2025-01-04T08:29:59Z
    if (value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/)) {
      return {format: 'yyyy-MM-ddTHH:mm:ssZ', lang: 'de'};
    }

    // 2023-06-29 22:30
    if (value.match(/^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/)) {
      return {format: 'yyyy-MM-dd HH:mm', lang: 'de'};
    }
    // 17.05.2024 10:00
    if (value.match(/^\d{2}\.\d{2}\.\d{4}\s\d{2}:\d{2}$/)) {
      return {format: 'dd.MM.yyyy HH:mm', lang: 'de'};
    }
    // 2024-01-01T00:00:00
    if (value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/)) {
      return {format: 'yyyy-MM-ddTHH:mm:ss', lang: 'de'};
    }
    // 12 Januar 2024
    if (value.match(/^\d{2}\s(?:Januar|Februar|März|April|Mai|Juni|Juli|August|September|Oktober|November|Dezember)\s\d{4}$/)) {
      return {format: 'dd MMMM yyyy', lang: 'de'};
    }
    if (value.match(/^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(0?[1-9]|[12][0-9]|3[01]),\s\d{4}$/)) {
      return {format: 'MMM dd, yyyy', lang: 'en'};
    }
    // 12. Januar 2024
    if (value.match(/^\d{2}\.\s(?:Januar|Februar|März|April|Mai|Juni|Juli|August|September|Oktober|November|Dezember)\s\d{4}$/)) {
      return {format: 'dd. MMMM yyyy', lang: 'de'};
    }
    // 17.5.2024
    if (value.match(/^\d{2}\.\d{1,2}\.\d{4}$/)) {
      return {format: 'dd.M.yyyy', lang: 'de'};
    }
    // 4.7.2024
    if (value.match(/^\d\.\d\.\d{4}$/)) {
      return {format: 'd.M.yyyy', lang: 'de'};
    }
    // 12.12.24
    if (value.match(/^\d{2}\.\d{2}\.\d{2}$/)) {
      return {format: 'dd.MM.yy', lang: 'de'};
    }
    // 1.12.24
    if (value.match(/^\d{1,2}\.\d{2}\.\d{2}$/)) {
      return {format: 'd.MM.yy', lang: 'de'};
    }
    // 1.12.2024
    if (value.match(/^\d{1,2}\.\d{2}\.\d{4}$/)) {
      return {format: 'd.MM.yyyy', lang: 'de'};
    }
    // 2024-12-28T11:44:32+0000
    if (value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{4}$/)) {
      return {format: 'yyyy-MM-ddTHH:mm:ssZ', lang: 'de'};
    }
    // 2024-12
    if (value.match(/^\d{4}-\d{2}$/)) {
      return {format: 'yyyy-MM', lang: 'de'};
    }
    // 30/12/2024
    if (value.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
      return {format: 'dd/MM/yyyy', lang: 'de'};
    }
    // 22.09 2024
    if (value.match(/^\d{2}\.\d{2}\s\d{4}$/)) {
      return {format: 'dd.MM yyyy', lang: 'de'};
    }
    // 27-Dec-2024
    if (value.match(/^\d{2}-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}$/)) {
      return {format: 'dd-MMM-yyyy', lang: 'en'};
    }
    // 12.09.24 20:12
    if (value.match(/^\d{2}\.\d{2}\.\d{2}\s\d{2}:\d{2}$/)) {
      return {format: 'dd.MM.yy HH:mm', lang: 'de'};
    }
    // 13.11.2024, 17:36
    if (value.match(/^\d{2}\.\d{2}\.\d{4},\s\d{2}:\d{2}$/)) {
      return {format: 'dd.MM.yyyy, HH:mm', lang: 'de'};
    }
    // 31. Okt. 2024
    if (value.match(/^\d{2}\.\s(Jan|Feb|Mär|Apr|Mai|Jun|Jul|Aug|Sep|Okt|Nov|Dez)\.\s\d{4}$/)) {
      return {format: 'dd. MMM. yyyy', lang: 'de'};
    }
    // 31. Octubre 2024
    if (value.match(/^\d{2}\.\s(Enero|Febrero|Marzo|Abril|Mayo|Junio|Julio|Agosto|Septiembre|Octubre|Noviembre|Diciembre)\s\d{4}$/)) {
      return {format: 'dd. MMMM yyyy', lang: 'es'};
    }
    // 30-JAN-2024
    if (value.match(/^\d{2}-(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)-\d{4}$/)) {
      return {format: 'dd-MMM-yyyy', lang: 'en'};
    }
    // 30-MAI-2024
    if (value.match(/^\d{2}-(JAN|FEB|MÄR|APR|MAI|JUN|JUL|AUG|SEP|OKT|NOV|DEZ)-\d{4}$/)) {
      return {format: 'dd-MMM-yyyy', lang: 'de'};
    }
    // 4. Dezember 2024
    if (value.match(/^\d{1,2}\.\s(?:Januar|Februar|März|April|Mai|Juni|Juli|August|September|Oktober|November|Dezember)\s\d{4}$/)) {
      return {format: 'd. MMMM yyyy', lang: 'de'};
    }
    // Sonntag 22 Dezember 2024
    if (value.match(/^(Sonntag|Montag|Dienstag|Mittwoch|Donnerstag|Freitag|Samstag)\s\d{1,2}\s(?:Januar|Februar|März|April|Mai|Juni|Juli|August|September|Oktober|November|Dezember)\s\d{4}$/)) {
      return {format: 'EEEE dd MMMM yyyy', lang: 'de'};
    }
    // 2024-01-10 2:00
    if (value.match(/^\d{4}-\d{2}-\d{2}\s\d{1,2}:\d{2}$/)) {
      return {format: 'yyyy-MM-dd H:mm', lang: 'de'};
    }
    // 2024-07-10 9:30:00
    if (value.match(/^\d{4}-\d{2}-\d{2}\s\d{1,2}:\d{2}:\d{2}$/)) {
      return {format: 'yyyy-MM-dd H:mm:ss', lang: 'de'};
    }

    // Samstag, 22. Oktober2022
    if (value.match(/^(Sonntag|Montag|Dienstag|Mittwoch|Donnerstag|Freitag|Samstag),\s\d{1,2}\.\s(?:Januar|Februar|März|April|Mai|Juni|Juli|August|September|Oktober|November|Dezember)\d{4}$/)) {
      return {format: 'EEEE, dd. MMMMyyyy', lang: 'de'};
    }
    // Sonntag, 22. Januar 2023
    if (value.match(/^(Sonntag|Montag|Dienstag|Mittwoch|Donnerstag|Freitag|Samstag),\s\d{1,2}\.\s(?:Januar|Februar|März|April|Mai|Juni|Juli|August|September|Oktober|November|Dezember)\s\d{4}$/)) {
      return {format: 'EEEE, dd. MMMM yyyy', lang: 'de'};
    }
    // 22. Dezember2024
    if (value.match(/^\d{1,2}\.\s(?:Januar|Februar|März|April|Mai|Juni|Juli|August|September|Oktober|November|Dezember)\d{4}$/)) {
      return {format: 'd. MMMMyyyy', lang: 'de'};
    }

    // Donnerstag, 22. August
    if (value.match(/^(Sonntag|Montag|Dienstag|Mittwoch|Donnerstag|Freitag|Samstag),\s\d{1,2}\.\s(?:Januar|Februar|März|April|Mai|Juni|Juli|August|September|Oktober|November|Dezember)$/)) {
      return {format: 'EEEE, dd. MMMM', lang: 'de'};
    }

    // 2024/11/14
    if (value.match(/^\d{4}\/\d{2}\/\d{2}$/)) {
      return {format: 'yyyy/MM/dd', lang: 'de'};
    }

    // D:20241216163924-08'00'
    if (value.match(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})([+-]\d{2})'(\d{2})'$/)) {
      return {format: 'YYYYMMDDHHmmssZ', lang: 'de'};
    }
    // 20250106145451
    if (value.match(/^\d{14}$/) && value.startsWith('202')) {
      return {format: 'yyyyMMddHHmmss', lang: 'de'};
    }

    // 09 Jan 2025
    if (value.match(/^\d{2}\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/)) {
      return {format: 'dd MMM yyyy', lang: 'en'};
    }

    return undefined;

    // HIER
  }

  static getFirstOfMonth(date: any): number {
    return new Date(DateTools.parse(date)).setDate(1).clearTime();
  }

  static getDayCountOfMonth(date: any) {
    const firstOfMonth = DateTools.getFirstOfMonth(date);
    const firstOfNextMonth = DateTools.addMonths(firstOfMonth, 1);
    return DateTools.dayDiff(firstOfNextMonth, firstOfMonth);
  }

  static dayDiff(date1: any, date2: any) {
    date1 = DateTools.parse(date1).dateClearTime();
    date2 = DateTools.parse(date2).dateClearTime();
    return moment(date1).diff(moment(date2), 'days');
  }

  public static timeStringToDuration(till: string) {
    const values = till.split(':');
    return (parseInt(values[0], 10) * DurationTools.DURATION_1HOUR) + (parseInt(values[1], 10) * DurationTools.DURATION_1MINUTE);
  }

  public static parseTextToBirthday(text: string): number {
    const parts = text.split('.');
    if (parts.length === 3) {
      const resultParts: string[] = [];
      let result = '';
      if (parts[0].length === 1) {
        result += '0';
      }
      result += parts[0] + '.';
      if (parts[1].length === 1) {
        result += '0';
      }
      result += parts[1] + '.';
      if (parts[2].length === 2) {
        if (parseInt(parts[2], 10) < 20) {
          result += '20';
        } else {
          result += '19';
        }
      }
      result += parts[2];
      return DateTools.parse(result, 'dd.MM.yyyy');
    }
    return 0;
  }

  public static completeDateFull(data: string, format: string): string {
    let result = 0;
    let day = 0;
    let month = 0;
    let year = 0;

    let dayLength = 0;
    let monthStart = 0;
    let monthEnd = 0;
    let yearStart = 0;
    let yearEnd = 0;

    if (data.includes('.')) {

    } else if (/^\d+$/g.test(data)) {
      // nur zahlen
      if (data.length >= 4) {
        if (data.length === 4) {
          dayLength = 1;
          monthStart = 1;
          monthEnd = 2;
          yearStart = 2;
          yearEnd = 4;
        }


        if (data.length === 6) {
          // ddMMyy oder dMyyyy
          if ([19, 20].includes(parseInt(data.substring(2, 4), 10))) {
            // dMyyyy
            dayLength = 1;
            monthStart = 1;
            monthEnd = 2;
            yearStart = 2;
            yearEnd = 6;
          } else {
            // ddMMyy
            dayLength = 2;
            monthStart = 2;
            monthEnd = 4;
            yearStart = 4;
            yearEnd = 6;
          }
        }
      }
    }
    if (dayLength > 0) {
      day = parseInt(data.substring(0, dayLength), 10);
      month = parseInt(data.substring(monthStart, monthEnd), 10);
      year = parseInt(data.substring(yearStart, yearEnd), 10);
      if (year < 100) {
        if (year > 40) {
          year += 1900;
        } else {
          year += 2000;
        }
      }
      if (year > 1900 && year < 2100) {
        result = DateTools.parse(year + '-' + StringTools.fill(month.toString(), 2, '0', false) + '-' + StringTools.fill(day.toString(), 2, '0', false));
      }
    }
    if (result) {
      return DateTools.format(result, format);
    }
    return '';
  }


  public static completeDate(data: string): string {
    if (data.length === 1) {
      const firstNumber = parseInt(data[0], 10);
      if (firstNumber > 3) {
        return '0' + data + '.';
      }
    } else if (data.length === 2) {
      if (!data.includes('.')) {
        return data + '.';
      } else {
        // bsp: 7.
      }
    } else if (data.length === 3) {
      if (data.includes('.')) {
        const firstNumber = parseInt(data.substring(0, 2), 10);
        if (firstNumber > 31) {
          return '31.';
        }
      } else {
        return data.substring(0, 2) + '.' + data.substring(2, 3);
      }
    } else if (data.length === 4) {
      if (data.includes('.')) {
        const secondNumber = parseInt(data.split('.')[1], 10);
        if (secondNumber > 1) {
          return data.substring(0, 3) + '0' + data.substring(3, 4) + '.';
        }
      } else {
        // return date.substring(0, 2) + '.' + date.substring(2, 3);
      }
    } else if (data.length === 5) {
      if (!data.endsWith('.')) {
        return data + '.';
      }
    } else if (data.length === 8) {
      const lastNumber = parseInt(data.split('.')[2], 10);
      if (lastNumber !== 19 && lastNumber !== 20) {
        if (lastNumber > 50) {
          data = data.substring(0, 6) + '19' + data.substring(6, 8);
        } else {
          data = data.substring(0, 6) + '20' + data.substring(6, 8);
        }
        return data;
      }
    } else if (data.length > 10) {
      return data.substring(0, 10);
    }
    return data;
  }

  static utcToLocal(utcTime: any) {
    let date = DateTools.parse(utcTime);
    const offset = (new Date(date).getTimezoneOffset());
    date += offset * 60000 * -1;
    return date;
  }

  static addWorkingDays(date: any, workingDaysToAdd: number, region: 'nw' | 'he' | 'by' | 'sp' | 'ch') {
    console.log('start addWorkingDays ' + DateTools.format(date, 'dd.MM.yyyy') + ' add ' + workingDaysToAdd);
    let result = date;
    let addedWorkingDays = 0;
    while (addedWorkingDays < workingDaysToAdd) {
      result = DateTools.addDays(result, 1);
      if (WorkingDayTools.isWorkingDay(result, region)) {
        addedWorkingDays++;
        console.log('addWorkingDays addedWorkingDays');
      }
      console.log('addWorkingDays not addedWorkingDays');
    }
    console.log('end addWorkingDays ' + DateTools.format(date, 'dd.MM.yyyy') + ' add ' + workingDaysToAdd + ' --> ' + DateTools.format(result, 'dd.MM.yyyy'));
    return result;
  }

  static todayAt(time: string): number {
    if (time.length === 5) {
      time += ':00';
    }
    return (DateTools.formatNow('yyyy-MM-dd') + ' ' + time).dateParse();
  }

  static getDayOfMonth(number: any) {
    return (new Date(DateTools.parse(number))).getDate();
  }

  static formatDate(date: any) {
    return DateTools.parse(date).dateFormat('yyyy-MM-dd');
  }

  static getDaysBetween(fromDateString: string, tillDateString: string) {
    if (fromDateString === tillDateString) {
      return [fromDateString];
    }
    if (fromDateString.dateParse() >= tillDateString.dateParse()) {
      return [fromDateString, tillDateString];
    }
    const result: string[] = [];
    result.push(fromDateString);
    let date = fromDateString.dateParse();
    while (true) {
      date = date.dateAddDays(1);
      if (date.dateFormat('yyyy-MM-dd') === tillDateString) {
        result.push(date.dateFormat('yyyy-MM-dd'));
        break;
      }
      result.push(date.dateFormat('yyyy-MM-dd'));
    }
    return result;
  }

  static forEachDay(fromDateString: string, tillDateString: string, callback: (dateString: string) => void) {
    const days = DateTools.getDaysBetween(fromDateString, tillDateString);
    days.forEach(day => callback(day));
  }

  static getDateStringsFromMonth(monthString: string) {
    let date = DateTools.getFirstOfMonth(monthString);
    const end = DateTools.getLastOfMonth(monthString);
    const result: string[] = [];
    while (date <= end) {
      result.push(date.dateFormat('yyyy-MM-dd'));
      date = date.dateAddDays(1);
    }
    return result;
  }

  static dayOfYear(createdAt: number) {
    return moment(createdAt).dayOfYear();
  }

  static clearDate(date: any) {
    return DurationTools.parse(DateTools.parse(date).dateFormat('HH:mm'));
  }

  static getDiffToGMT(date: any): number {
    const parsedDate = DateTools.parse(date);
    const localTime = moment(parsedDate);
    const offsetInMinutes = localTime.utcOffset();
    const diffToGMT = offsetInMinutes * 60 * 1000;
    return diffToGMT;
  }
}


declare global {
  interface Number {
    dateFormat(format: string): string;

    dateFormatDate(): string;

    dateClearTime(): number;

    dateAddDays(days: number): number;

    dateAddYears(years: number): number;

    dateAddMonths(months: number): number;

    clearTime(): number;

    getHours(): number;

    clearDate(): number;

    dateDiffToNowText(): string;
  }

  interface String {
    dateParse(format?: DateFormat, isUTC?: boolean): number;

    dateFormat(format?: DateFormat): string;

    dateFormatDate(): string;

    dateAddDays(days: number): number;

    dateAddMonths(days: number): number;
  }

  interface Date {
    dateFormat(format: string): string;
  }

  interface Date {
    dateFormatDate(): string;
  }
}


Number.prototype.dateAddDays = function (this: number, days: number) {
  return DateTools.addDays(this, days);
};

Number.prototype.dateClearTime = function (this: number) {
  return DateTools.clearTime(this);
};

Number.prototype.dateAddMonths = function (this: number, months: number) {
  return DateTools.addMonths(this, months);
};

Number.prototype.dateAddYears = function (this: number, years: number) {
  return DateTools.addYears(this, years);
};

Number.prototype.dateFormat = function (this: number, format: string) {
  return DateTools.format(this, format);
};

Number.prototype.clearTime = function (this: number) {
  return DateTools.clearTime(this);
};

Number.prototype.dateDiffToNowText = function (this: number) {
  return DateTools.dateDiffToNowText(this);
};

String.prototype.dateParse = function (this: string, format?: '', isUTC?: boolean) {
  return DateTools.parse(this, format, isUTC);
};

Date.prototype.dateFormat = function (this: number, format: string) {
  return DateTools.format(this, format);
};

Object.defineProperty(Number.prototype, 'dateFormatDate', {
  configurable: true,
  writable: true,
  value(this: number) {
    return DateTools.formatDate(this);
  },
});

Object.defineProperty(String.prototype, 'dateFormatDate', {
  configurable: true,
  writable: true,
  value(this: string) {
    return DateTools.formatDate(this);
  },
});

Object.defineProperty(String.prototype, 'dateFormat', {
  configurable: true,
  writable: true,
  value(this: string, format: string) {
    return DateTools.format(this, format);
  },
});

Object.defineProperty(Number.prototype, 'clearDate', {
  configurable: true,
  writable: true,
  value(this: number) {
    return DateTools.clearDate(this);
  },
});

Object.defineProperty(Number.prototype, 'getHours', {
  configurable: true,
  writable: true,
  value(this: number) {
    return DateTools.getHours(this);
  },
});


Object.defineProperty(Date.prototype, 'dateFormatDate', {
  configurable: true,
  writable: true,
  value(this: Date) {
    return DateTools.formatDate(this);
  },
});

Object.defineProperty(String.prototype, 'dateAddDays', {
  configurable: true,
  writable: true,
  value(this: string, days: number) {
    return DateTools.addDays(this, days);
  },
});

Object.defineProperty(String.prototype, 'dateAddMonths', {
  configurable: true,
  writable: true,
  value(this: string, days: number) {
    return DateTools.addMonths(this.dateParse(), days);
  },
});





