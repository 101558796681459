import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../services/socket/socket.service';
import {IcloudState} from '../../common-interfaces/icloud.interface';
import {InputComponent} from '../form-controls/input/input.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {FromNowText2Pipe} from '../../pipes/from-now-2.pipe';
import {DialogService} from '../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'nxt-icloud',
    templateUrl: './icloud.component.html',
    styleUrls: ['./icloud.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    NxtButtonComponent,
    FromNowText2Pipe,
  ]
})

export class IcloudComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor() {
    super();
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private dialogService = inject(DialogService);
  private socketService = inject(SocketService);
  private dialogRef = inject(MatDialogRef, {optional: true});

  state = signal<IcloudState | null>(null);

  code = signal('');

  ngOnInit() {
    this.pushSocketSubscription = this.socketService.subscribeNew('getIcloudState', (state) => {
      this.state.set(state);
    }, {emitAfterReconnect: true, emitInitial: true});
  }

  nxtOnDestroy() {
  }

  async setCodeClicked() {
    this.code.set(this.code().trim());
    if (this.code().length !== 6) {
      this.dialogService.showOk('Code muss 6-stellig sein\nDeine Eingaben: "' + this.code() + '"');
      return;
    }
    await this.socketService.icloudAction('code', this.code());
    this.code.set('');
  }

  getNewCodeClicked() {
    this.socketService.icloudAction('startScript');
  }

  closeClicked() {
    this.dialogRef.close();
  }
}
