@if (showCloseButton) {
  <div class="w-full flex flex-row justify-end pb-3">
    <nxt-button-icon (click)="dialogRef.close()">close</nxt-button-icon>
  </div>
}
<div class="flex flex-row items-center">
  <nxt-button [smallButton]="true" (click)="syncClicked()" nxtPermission="IsJulian">SHOP-SYNC</nxt-button>
  <nxt-button-icon (click)="showDebugClicked()">info</nxt-button-icon>
</div>
<div *ngIf="shopOrder()" class="flex flex-row justify-center">
  <table class="nxt-table !border-2">
    <tr>
      <td>Shop-ID</td>
      <td>
        <div>{{ shopOrder().id }}</div>
      </td>
    </tr>
    <tr>
      <td>Shop-Status</td>
      <td>
        <div>{{ shopOrder().status | shopOrderStatus }}</div>
        <div *ngIf="shopOrder().status === 'on-hold'" class="red">Das Geld ist von {{ shopOrder().paymentMethod }} noch nicht freigegeben!</div>
      </td>
    </tr>
    @if (showOrderCreated()) {
      <tr>
        <td>Bestellung erstellt</td>
        <td>
          <div>{{ shopOrder().createdAt | nxtDate: 'dd.MM.yyyy HH:mm' }}</div>
          <div class="text-80">{{ shopOrder().createdAt | fromNowText }}</div>
        </td>
      </tr>
    }
    @if (shopOrder().payedAt) {
      <tr>
        @if (showOrderCreated()) {
          <td>Bestellung bezahlt</td>
        } @else {
          <td>Datum</td>
        }
        <td>
          <div>{{ shopOrder().payedAt | nxtDate: 'dd.MM.yyyy HH:mm' }}</div>
          <div class="text-80">{{ shopOrder().payedAt | fromNowText }}</div>
        </td>
      </tr>
    }
    <tr *ngIf="shopOrder().couponCode">
      <td>Rabatt-Code</td>
      <td>{{ shopOrder().couponCode }}</td>
    </tr>
    <tr *ngFor="let lineItem of shopOrder().lineItems; let i = index">
      <td>{{ i + 1 }}. Artikel</td>
      <td>
        <div>
          <nxt-button [smallButton]="true" (click)="showLineItemClicked(lineItem)">{{ lineItem.quantity }} x {{ lineItem.name }}</nxt-button>
        </div>
        <div *ngIf="false" class="bold">{{ lineItem.quantity }} x {{ lineItem.name }}</div>
        <div *ngIf="false">Bezahlt: {{ lineItem.paymentValue | money }}</div>
        <div *ngIf="false">ArtikelID: {{ lineItem.id }}</div>
        @if (lineItem.type === 'tattoo-ticket' && shopOrder().paymentMethod === 'klarna_payments') {
          <div *ngIf="false">
            <nxt-button [smallButton]="true" (click)="showTattooTicketKlarnaClicked(lineItem.id)">anzeigen</nxt-button>
          </div>
        } @else if (lineItem.type === 'tattoo-ticket' && shopOrder().paymentMethod === 'paypal') {
          <div *ngIf="false">
            <nxt-button [smallButton]="true" (click)="showTattooTicketPaypalClicked(lineItem.id)">anzeigen</nxt-button>
          </div>
        }
      </td>
    </tr>
    <tr>
      <td>Zahlungsmethode</td>
      <td>
        <div>{{ shopOrder().paymentMethod | shopOrderPaymentMethod }}</div>
        <div>{{ shopOrder().transactionId }}</div>
      </td>
    </tr>
    <tr>
      <td>Name</td>
      <td>{{ shopOrder().billingFirstName }} {{ shopOrder().billingLastName }}</td>
    </tr>
    <tr>
      <td>E-Mail</td>
      <td>
        <div class="flex flex-row items-center">
          <div>
            <div>{{ shopOrder().billingEmail }}</div>
            <div class="text-80" *ngIf="giftCardCount === 1">an diese Adresse wurde der Gutschein gesendet</div>
            <div class="text-80" *ngIf="giftCardCount > 1">an diese Adresse wurden der {{ giftCardCount }} Gutscheine gesendet</div>
            <div class="text-80" *ngIf="shopOrder().paymentMethod === 'paypal'">im Shop angegeben, kann von der PayPal-Email-Adresse abweichen</div>
          </div>
          <div class="flex flex-row items-center">
            <div *ngIf="shopOrder().billingPhone && giftCardCount === 1" class="pl-2">
              <nxt-button [smallButton]="true" (click)="sendGiftCardViaEmailClicked()">Gutschein an diese E-Mail senden</nxt-button>
            </div>
            <div *ngIf="shopOrder().billingPhone && giftCardCount > 1" class="pl-2">
              <nxt-button [smallButton]="true" (click)="sendGiftCardViaEmailClicked()">{{ giftCardCount }} Gutscheine an diese E-Mail senden</nxt-button>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>Adresse</td>
      <td>{{ shopOrder().billingAddress1 }}<br/>{{ shopOrder().billingCountry }}
        -{{ shopOrder().billingPostcode }}  {{ shopOrder().billingCity }}
      </td>
    </tr>
    <tr>
      <td>Handy</td>
      <td>
        <div class="flex flex-row items-center py-2">
          <div>{{ shopOrder().billingPhone }}</div>
          <div *ngIf="shopOrder().billingPhone && giftCardCount === 1" class="pl-2">
            <nxt-button [smallButton]="true" (click)="sendGiftCardViaWhatsAppClicked()">Gutschein an diese Nummer senden</nxt-button>
          </div>
          <div *ngIf="shopOrder().billingPhone && giftCardCount > 1" class="pl-2">
            <nxt-button [smallButton]="true" (click)="sendGiftCardViaWhatsAppClicked()">{{ giftCardCount }} Gutscheine an diese Nummer senden</nxt-button>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>Bezahlt</td>
      <td class="green">{{ shopOrder().paymentValue | money }}</td>
    </tr>
    <tr *ngIf="refundValue() > 0">
      <td>Erstattet</td>
      <td class="red">{{ refundValue() | money }}</td>
    </tr>
    <tr *ngIf="refunds().length > 0">
      <td class="!p-0" colspan="2">
        <table class="nxt-table-no-outer-border w-full" style="border-top-width: 10px;">
          <tr *ngFor="let refund of refunds();let i = index">
            <td>
              <div class="flex flex-col justify-center items-center">
                <div>Rückzahlung</div>
                <div *ngIf="refund.refType === 'shop-order-line-item'">
                  {{ (shopOrder().lineItems | nxtFind: 'id' : refund.refId)?.name }}
                </div>
              </div>
            </td>
            <td class="!p-0">
              <table class="nxt-table-no-outer-border w-full">
                <tr>
                  <td>Betrag</td>
                  <td>{{ refund.value | money }}</td>
                </tr>
                <tr>
                  <td>Erstellt</td>
                  <td>{{ refund.createdAt | nxtDate: 'dd.MM.yyyy HH:mm' }}</td>
                </tr>
                <tr>
                  <td>Erstellt von</td>
                  <td>{{ refund.createdBy }}</td>
                </tr>
                <tr>
                  <td>Grund</td>
                  <td>{{ refund.reason }}</td>
                </tr>
              </table>
          </tr>
        </table>
      </td>
    </tr>
    <tr *ngIf="shopOrder().paymentMethod === 'paypal'">
      <!--<td>PayPal</td>
      <td class="!p-0">
        <table class="nxt-table w-full">
          <tr>
            <td>Transaktion</td>
            <td>{{ shopOrder().transactionId }}</td>
          </tr>
          <tr *ngIf="paypalTransaction">
            <td>Zahlungseingang</td>
            <td *ngIf="payPalIpn">{{ payPalIpn.payment_date | nxtDate: 'dd.MM.yyyy HH:mm' }}</td>
            <td *ngIf="!payPalIpn">{{ paypalTransaction.transactionTime | nxtDate: 'dd.MM.yyyy HH:mm' }}</td>
          </tr>
          <tr *ngIf="paypalTransaction">
            <td>Bezahlt von</td>
            <td>{{ paypalTransaction.payerName }}<br/>{{ paypalTransaction.payerEmail }}</td>
          </tr>
          <tr *ngIf="paypalTransaction">
            <td>Bezahlt an</td>
            <td>{{ paypalTransaction.receiverEmail }}</td>
          </tr>
          <tr>
            <td colspan="2">
              <nxt-button (click)="refundPaypalClicked()"></nxt-button>
            </td>
          </tr>
        </table>
      </td>
      <td *ngIf="paypalTransaction && showDebug() && false">
        PaypalTransaction:
        <div *ngIf="paypalTransaction" [innerHTML]="paypalTransaction | jsonFormatted"></div>
      </td>
      <td *ngIf="payPalIpn && showDebug() && false">
        PayPalIpn
        <div [innerHTML]="payPalIpn | jsonFormatted"></div>
      </td>-->
    </tr>
    <tr *ngIf="shopOrder().paymentMethod === 'klarna_payments' && showDebug()">
      <td>Klarna</td>
      <td class="!p-0">
        <nxt-klarna-order-raw
          [showShopOrder]="false"
          [showDebug]="showDebug()"
          [noOuterBorder]="true"
          [klarnaOrderId]="shopOrder().transactionId"
          (onShowEvent)="showEvent.emit($event)"
          (onShowGiftCard)="showGiftCard.emit($event)"
        >
        </nxt-klarna-order-raw>
      </td>
    </tr>
  </table>

  <div *ngIf="false" [innerHTML]="shopOrder | jsonFormatted"></div>
</div>
<div *ngIf="isDialog" class="w-full flex flex-row justify-center mt-3">
  <nxt-button (click)="dialogRef.close()">Schließen</nxt-button>
</div>
