<div class="nxt-page" fxFlex fxLayout="column" fxLayoutAlign="stretch">
  <div style="height: 100%" fxLayout="row">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="space-around center" style="width: 100%">
        <mat-radio-group layout="row" style="margin-bottom:5px;" [(ngModel)]="studio" (ngModelChange)="inventoryFilterChanged()">
          <mat-radio-button style="margin-right: 12px;" *ngFor="let studio of studios" [value]="studio">{{ studio }}</mat-radio-button>
        </mat-radio-group>
        <nxt-input style="width:300px" placeholder="Suche" [(value)]="quickFilterText" [clearIcon]="true"/>
        <mat-radio-group layout="row" style="margin-bottom:5px;" [(ngModel)]="inventoryFilter[studio]" (ngModelChange)="inventoryFilterChanged()">
          <mat-radio-button style="margin-right: 12px;" *ngFor="let filterItem of inventoryFilterItems" [value]="filterItem.value">{{ filterItem.text }}
          </mat-radio-button>
        </mat-radio-group>
        <div>
          <nxt-slide-toggle [value]="showEmptyItems" (valueChange)="showEmptyItemsChanged($event, false)">Bestellen</nxt-slide-toggle>
          <nxt-slide-toggle [value]="showEmptyItemsAc" (valueChange)="showEmptyItemsChanged($event, true)">Bestellen AC</nxt-slide-toggle>
        </div>
      </div>
      <div>
        <div>
          @for (specialButton of specialButtons; track specialButton) {
            <nxt-button (click)="specialButtonClicked(specialButton)">{{ specialButton }}</nxt-button>
          }
        </div>
        <div *ngIf="false">
          <nxt-button [smallButton]="true" *ngIf="showEmptyItems" (click)="print()">Alles DRUCKEN</nxt-button>
          <ng-container *ngFor="let printButton of printButtons">
            <nxt-button *ngIf="printButton === 'Osman'" [smallButton]="true" (click)="sendWhatsApp(printButton)">{{ printButton }}<br/>WhatsApp Capone
            </nxt-button>
            <nxt-button *ngIf="printButton === 'Sorry Mom Mallorca'" [smallButton]="true" (click)="sendWhatsApp(printButton)">{{ printButton }}<br/>WhatsApp
              Mandy
            </nxt-button>
            <nxt-button *ngIf="printButton !== 'Osman'" [smallButton]="true" (click)="print(printButton)">{{ printButton }}<br/>Drucken</nxt-button>
          </ng-container>
        </div>
        <div *ngIf="showEmptyItems">
          <nxt-button *ngFor="let showOrderText of showOrderTexts" [smallButton]="true" (click)="showOrderTextClicked(showOrderText)">{{ showOrderText }}
          </nxt-button>
        </div>
      </div>
      <div *ngIf="false && inventoryDataFiltered[studio]">{{ inventoryDataFiltered[studio].length }}</div>
      <nxt-datagrid fxFlex
                    style="width: 100%;"
                    [columnDefs]="inventoryStockColumnDef"
                    [rowData]="inventoryDataFiltered[studio]"
                    [showSideBar]="false"
                    [disableAllSort]="false"
                    [quickFilterText]="quickFilterText"
                    uniqueRowDataKey="id"
                    (cellEditingStopped)="cellEditingStopped($event)"
                    (cellEditingStarted)="cellEditingStarted($event)"
                    (rowDragEnd)="rowDragEnd($event)"
                    dragOrderFieldName="orderValue"
                    [autoSizeColumnOnResize]="true"
                    excelExportName="Inventar"
      >
      </nxt-datagrid>
    </div>
  </div>
  <div nxtCardDivider></div>
  <div fxLayout="row" fxLayoutAlign="center">
    <nxt-button *ngIf="loginService.isJulian()" (click)="reorder()">REORDER</nxt-button>
    <nxt-button *ngIf="loginService.isJulian()" (click)="redraw()">REDRAW</nxt-button>
    <nxt-button (click)="addItem()">NEUES TEIL</nxt-button>
    <nxt-button (click)="close()">schließen</nxt-button>
  </div>
  <nxt-button-icon *ngIf="dialogRef" style="position: absolute;right: 10px;"
                   (click)="dialogRef.close()"
                   tooltip="schließen"
  >close
  </nxt-button-icon>
</div>
