<div style="height: 100%" fxFlex fxLayout="column" fxLayoutAlign="stretch">
  <div fxFlex style="font-size: 1.4em" fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" class="w-full">
      <div class="headline" fxLayoutAlign="center center">{{title}}</div>
      <div *ngIf="suppressValueChange" fxLayoutAlign="center center" [ngClass]="{red: currentMoneyStackValue !== startValue}" fxFlex class="pl-3 pt-4" style="font-size: 90%">Geldwechseln, du musst mit {{this.startValue | money}} beenden</div>
      <div fxLayout="row">
        <div fxFlex>
        </div>
        <div fxFlex>
        <nxt-money-counter
          style="margin-left:20px;"
          [(moneyStack)]="currentMoneyStack"
          [totalMoneyShouldValue]="totalMoneyShouldValue"
          [showShould]="showShould"
          (moneyStackChange)="moneyStackChanged($event)"
        >
        </nxt-money-counter>
        </div>
        <div [nxtMultiClick]="4" (nxtOnMultiClick)="forceForce = true" fxFlex class="red  pl-3 pt-4" style="font-size: 90%"  [innerHTML]="moneyStackInfoText"></div>
      </div>
    </div>
  </div>
  <div class="flex flex-row justify-center gap-3">
    <nxt-button (click)="close()">Abbrechen</nxt-button>
    <nxt-button *ngIf="!mustCorrect || currentMoneyStackValue === totalMoneyShouldValue" (click)="save()">speichern</nxt-button>
  </div>
</div>
