import {inject, Injectable} from '@angular/core';
import {SocketService} from '../../../../services/socket/socket.service';


export interface IdValuePair {
  id: string;
  value: number;
}

interface TransactionMatch {
  singleId: string;
  combinations: string[][]; // Array aller möglichen Dokument-ID-Kombinationen
}

@Injectable({
  providedIn: 'root'
})
export class AssignFinderService {
  private socketService = inject(SocketService);

  documentsx: IdValuePair[] = [
    {id: '0', value: 1},
    {id: '1', value: 100},
    {id: '2', value: 203},
    {id: '3', value: 400},
    {id: '4', value: 350},
    {id: '5', value: 502},
    {id: '6', value: 601},
    {id: '7', value: 70},
  ];

  transactionsx: IdValuePair[] = [
    {id: '1', value: 100},
    {id: '2', value: 203},
    {id: '3', value: 350},
    {id: '4', value: 400},
    {id: '5', value: 502},
    {id: '6', value: 520},
    {id: '7', value: 70},
  ];

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
    const documents: IdValuePair[] = [
      {
        id: '8a45cae2-6f81-409b-aa4e-614e736aa0b0',
        value: 199.98
      },
      {
        id: '84c83678-24ba-4232-bb0b-0344edcad11d',
        value: 10.99
      }
    ];

    const transactions: IdValuePair[] = [
      {
        id: '5584 **** **** 5587_15.11.24_15.11.24_0,00_-121,05_EUR_AMZN Mktp DE*T922F3444_800-279-6620',
        value: 121.05
      },
      {
        id: '5584 **** **** 5587_15.11.24_15.11.24_0,00_-210,97_EUR_AMZN Mktp DE*T90BM44F4_800-279-6620',
        value: 210.97
      }
    ];
    const result = await this.findAllMatches(transactions, documents);
    console.log(result);
    console.log(result);
    console.log(result);
  }

  findAllMatches(singleIdValuePairs: IdValuePair[], multiIdValuePair: IdValuePair[]): TransactionMatch[] {
    const result: TransactionMatch[] = [];
    for (const singleIdValuePair of singleIdValuePairs) {
      const combos: IdValuePair[][] = [];
      this.findCombinations(singleIdValuePair.value, [], 0, combos, multiIdValuePair);

      result.push({
        singleId: singleIdValuePair.id,
        combinations: combos.map((c) => c.map((d) => d.id))
      });
    }
    return result;
  }

  private findCombinations(
    target: number,
    currentCombo: IdValuePair[],
    startIndex: number,
    allCombos: IdValuePair[][],
    documents: IdValuePair[]
  ) {
    if (target === 0) {
      allCombos.push([...currentCombo]);
      return;
    }
    for (let i = startIndex; i < documents.length; i++) {
      const doc = documents[i];
      if (doc.value <= target) {
        currentCombo.push(doc);
        this.findCombinations(target - doc.value, currentCombo, i + 1, allCombos, documents);
        currentCombo.pop();
      }
    }
  }
}
