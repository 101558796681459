import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NxtComponent} from '../nxt.component';
import {BehaviorSubject} from 'rxjs';
import {LoginService} from '../../services/login.service';
import {SmoothWidthComponent} from '../smooth-width.component';
import {MatIcon} from '@angular/material/icon';
import {AnimateDirective} from '../../directives/animate.directive';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {AsyncPipe, NgIf, NgStyle} from '@angular/common';

@Component({
  selector: 'nxt-side-bar-left',
  templateUrl: './side-bar-left.component.html',
  styleUrls: ['./side-bar-left.component.scss'],
  imports: [NgStyle, ExtendedModule, AnimateDirective, MatIcon, SmoothWidthComponent, NgIf, AsyncPipe],
  standalone: true,
})

export class SideBarLeftComponent extends NxtComponent implements OnInit {


  @ViewChild('toggleBackground') toggleBackground: ElementRef;

  @Input() toggleHighlight = false;
  @Input() expandedWidth = 150;

  protected state = new BehaviorSubject<'collapsed' | 'expanded'>('collapsed');

  @Input() collapsedWidth = 0;
  public currentWidth = 0;
  public toggleExpandedAnimation = 'shakeX';

  constructor(
    private loginService: LoginService,
  ) {
    super();

    this.state.subscribe((value) => {
      this.checkWidth();
    });
  }

  ngOnInit() {
    this.checkWidth();
  }

  nxtOnDestroy(): void {
  }

  public toggle() {
    if (this.state.value === 'collapsed') {
      this.state.next('expanded');
    } else {
      this.state.next('collapsed');
    }
  }

  highlight(doHighlight: boolean) {
    this.toggleHighlight = doHighlight;
  }

  public expand() {
    this.state.next('expanded');
  }

  public collapse() {
    this.state.next('collapsed');
  }

  private checkWidth() {
    if (this.state.value === 'collapsed') {
      this.currentWidth = this.collapsedWidth;
    } else {
      this.currentWidth = this.expandedWidth;
    }
  }
}
