import {Log} from '../../common-browser/log/log.tools';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {BirthdayTools} from '../../common-browser/helpers/birthday.tools';
import {StringTools} from '../../common-browser/helpers/string.tools';
import {NxtArtist} from '../../common-interfaces/nxt.artist.interface';
import {JsonTools} from '../../common-browser/helpers/json.tools';

export class CalendarEventEditDisplayFn {

  static displayCustomerOptionFn(contact: any, highlight: (text: string) => string): string | undefined {
    try {
      if (typeof contact === 'string') {
        return contact;
      }
      if (contact && contact.givenName) {
        const birthday = contact.birthday ? DateTools.format(contact.birthday, 'dd.MM.yyyy') : '';
        let age = 0;
        if (birthday) {
          age = BirthdayTools.getBirthdayInfo(DateTools.parse(contact.birthday)).age;
        }
        return '<div style="width: 100%; display:flex; justify-content: space-between"><div>' + highlight(contact.givenName) + ' ' + highlight(contact.familyName) + '</div>' + (birthday ? ('<div>' + highlight(birthday) + ' ' + StringTools.arrowRight + ' ' + highlight(age.toString()) + '</div>') : '') + '</div>';
      }
      return contact;
    } catch (err) {
      throw Error('displayCustomerOptionFn\nKontakt kann nicht angezeigt werden\n' + JsonTools.stringify(contact));
    }
  }


  static displayFn(contact?: any): string | undefined {
    if (typeof contact === 'string') {
      return contact;
    }
    if (contact) {
      return contact.givenName + ' ' + contact.familyName;
    }
    return '';
  }

  static displayArtistCalendar(artist: NxtArtist): string | undefined {
    if (artist) {
      return artist.calendarNumber + ' ' + artist.name;
    }
  }

  static displayArtistCalendarOption(artist: NxtArtist, highlight: (string) => string): string | undefined {
    let result = '?';
    if (artist) {
      result = artist.calendarNumber + ' ' + artist.name;
    }
    return highlight(result);
  }

  static displayText(text: string): string {
    return text;
  }
}
