import {Component, Input, OnInit} from '@angular/core';
import {NxtNgConfigStudio} from '../../../../common-interfaces/nxt.ng-config.interface';
import {SlideToggleComponent} from '../../../form-controls/slide-toggle/slide-toggle.component';
import {InputComponent} from '../../../form-controls/input/input.component';
import {NgIf} from '@angular/common';

@Component({
    selector: 'nxt-settings-ng-config-studio',
    templateUrl: './settings-ng-config-studio.component.html',
    styleUrls: ['./settings-ng-config-studio.component.scss'],
    imports: [NgIf, InputComponent, SlideToggleComponent],
  standalone: true,
})

export class SettingsNgConfigStudioComponent implements OnInit {

  @Input() studio: NxtNgConfigStudio;

  constructor() {
  }

  ngOnInit() {

  }
}
