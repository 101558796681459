import {Injectable} from '@angular/core';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtWhatsAppImage} from '../../../common-interfaces/whats-app/nxt-whatsapp-message.interface';
import {DialogService} from '../../../services/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class WhatsappChatService {

  imageCache: NxtWhatsAppImage[] = [];

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
  ) {
  }

  async getImage(mediaKey: string): Promise<NxtWhatsAppImage> {


    return this.socketService.getWhatsAppImage(mediaKey);

    /*if (!this.imageCache.find(i => i.id === messageId)) {

      this.imageCache.push(image);
    }
    return this.imageCache.find(i => i.id === messageId);*/
  }

  clearCache() {
    this.imageCache = [];
  }
}
