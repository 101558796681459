import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormControlWrapper} from '../form-control-wrapper';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {debounceTime, Subject, Subscription} from 'rxjs';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import moment, {Moment} from 'moment';
import {Log} from '../../../common-browser/log/log.tools';
import {MatDatepickerActions, MatDatepickerApply, MatDatepickerCancel, MatDatepickerToggle, MatDateRangeInput, MatDateRangePicker, MatEndDate, MatStartDate,} from '@angular/material/datepicker';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {MatIcon} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgIf, NgStyle} from '@angular/common';
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {FormFieldWrapperComponent} from '../../form-field-wrapper/form-field-wrapper.component';
import {MatTooltip} from '@angular/material/tooltip';

const MY_FORMATS = {
  parse: {
    dateInput: 'dd.MM.yyyy',
  },
  display: {
    dateInput: 'dd.MM.yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

@Component({
  selector: 'nxt-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  imports: [FormFieldWrapperComponent, MatFormField, NgStyle, ExtendedModule, MatLabel, MatDateRangeInput, MatStartDate, FormsModule, MatEndDate, NgIf, MatIcon, MatDatepickerToggle, MatSuffix, MatDateRangePicker, MatDatepickerActions, FlexModule, NxtButtonComponent, MatDatepickerCancel, MatDatepickerApply, MatTooltip],
  standalone: true,
})
export class DateRangePickerComponent extends FormControlWrapper implements OnInit, OnDestroy, OnChanges {
  private valueChangedSubscription: Subscription;

  @Input() showJumpMonths = false;

  @Input() set value(value: { from: string, till: string }) {
    this.nxtFormControl.setValue(value, {emitEvent: false});
    if (value?.from) {
      this.valueFrom = moment(value?.from);
    }
    if (value?.till) {
      this.valueTill = moment(value?.till);
    }
  }

  constructor(@Inject(MAT_DATE_FORMATS) private dateFormats) {
    super();
  }

  @Input() placeholder: string;
  @Input() onlyMonth?: boolean;

  @Input() showClearIcon = false;

  @Output() valueChange = new EventEmitter<{ from: string, till: string }>();


  @ViewChild('rangePicker') dateRangePicker: MatDateRangePicker<any>;

  @Input() bottomNoPadding = false;

  formFieldWidth = '100%';
  // startView = '';
  valueFrom: Moment;
  valueTill: Moment;
  thisMonthText = DateTools.formatNow('MMM yy');
  monthBack1 = DateTools.addMonths(Date.now(), -1).dateFormat('MMM yy');
  monthBack2 = DateTools.addMonths(Date.now(), -2).dateFormat('MMM yy');
  monthBack3 = DateTools.addMonths(Date.now(), -3).dateFormat('MMM yy');
  monthBack4 = DateTools.addMonths(Date.now(), -4).dateFormat('MMM yy');
  monthBack5 = DateTools.addMonths(Date.now(), -5).dateFormat('MMM yy');

  yearBack0 = Date.now().dateFormat('yyyy');
  yearBack1 = DateTools.addYears(Date.now(), -1).dateFormat('yyyy');
  yearBack2 = DateTools.addYears(Date.now(), -2).dateFormat('yyyy');
  yearBack3 = DateTools.addYears(Date.now(), -3).dateFormat('yyyy');

  valueChanged$ = new Subject<void>();

  ngOnInit(): void {
    this.valueChangedSubscription = this.valueChanged$.pipe(debounceTime(50)).subscribe(() => {
      this.valueChange.emit(this.getValue());
    });
  }


  ngOnDestroy(): void {
    this.valueChangedSubscription?.unsubscribe();
  }

  thisMonthClicked() {
    this.valueFrom = moment(Date.now()).add(0, 'month').startOf('month');
    this.valueTill = moment(Date.now()).add(1, 'month').startOf('month').add(-1, 'day');
    this.closeDatePickerPopup();
    this.emitChange();
  }


  lastXMonthClicked(monthBack: number) {
    this.valueFrom = moment(Date.now()).add(-1 * monthBack, 'month').startOf('month');
    this.valueTill = moment(Date.now()).add((-1 * monthBack) + 1, 'month').startOf('month').add(-1, 'day');
    this.closeDatePickerPopup();
    this.emitChange();
  }

  lastXYearClicked(yearsBack: number) {
    const year = parseInt(Date.now().dateFormat('yyyy'), 10) - yearsBack;
    this.valueFrom = moment(DateTools.parse(year + '-01-01'));
    this.valueTill = moment(DateTools.parse(year + '-12-31'));
    this.closeDatePickerPopup();
    this.emitChange();
  }

  thisWeekClicked() {
    this.valueFrom = moment(Date.now()).add(0, 'week').startOf('week');
    this.valueTill = moment(Date.now()).add(1, 'week').startOf('week').add(-1, 'day');
    this.closeDatePickerPopup();
    this.emitChange();
  }

  lastWeekClicked() {
    this.valueFrom = moment(Date.now()).add(-1, 'week').startOf('week');
    this.valueTill = moment(Date.now()).add(0, 'week').startOf('week').add(-1, 'day');
    this.closeDatePickerPopup();
    this.emitChange();
  }

  closeDatePickerPopup() {
    if (this.dateRangePicker?.opened) {
      this.dateRangePicker.close();
      Log.debug('DATE-PICKER: closeDatePickerPopup');
    }
  }

  emitChange() {
    this.valueChanged$.next();
  }

  private getValue() {
    return {from: DateTools.format(this.valueFrom, 'yyyy-MM-dd'), till: DateTools.format(this.valueTill, 'yyyy-MM-dd')};
  }

  open(event) {
    this.dateRangePicker.open();
    /*setTimeout(() => {
      event.target?.focus();
      event.target?.select();
    }, 1);*/
  }

  ngOnChanges(changes: SimpleChanges): void {

  }


  clearInput($event: MouseEvent) {
    $event.stopPropagation();
    this.valueFrom = null;
    this.valueTill = null;
    this.emitChange();
  }

  closed($event: Event) {
  }

  changed($event: Event) {
  }

  addMonth(toAdd: number) {
    const firstOfMonth = DateTools.getFirstOfMonth(DateTools.parse(this.valueFrom));
    const from = firstOfMonth.dateAddMonths(toAdd);
    const till = DateTools.getLastOfMonth(from);
    this.valueFrom = moment(from);
    this.valueTill = moment(till);
    this.emitChange();
  }
}
