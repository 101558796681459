import {BackofficeComponent} from './backoffice.component';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {DecimalTools} from '../../common-browser/helpers/decimal.tools';
import {ColorTools} from '../../common-browser/helpers/color.tools';
import {EventCalcTools} from '../../common-browser/helpers/event-calc.tools';
import {NxtPermissionId} from '../../common-interfaces/nxt.user.interface';
import {WorkplaceTools} from '../../common-browser/helpers/workplace.tools';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';

export class BackofficeColumnDefs {

  constructor(private backofficeComponent: BackofficeComponent) {
  }


  public newEventsColumnDefs: NxtColDef[] = [
    {headerName: '', field: 'id', nxtFieldType: NxtFieldType.ShowCalendarEvent, minWidth: 50, maxWidth: 50},
    {headerName: '', field: 'id', nxtFieldType: NxtFieldType.ShowEventHistory, minWidth: 50, maxWidth: 50},
    /*{
      headerName: 'Erstellt', field: 'created', cellRenderer: (params: any) => {
        if (params.data.createdByWorkplace) {
          const workplace = WorkplaceTools.getText(params.data.createdByWorkplace);
          return DateTools.format(params.data.created, 'HH:mm') +
            ' - ' + params.data.createdBy +
            ' - ' + workplace +
            ' - ' + params.data.createdByStudio;
        }
      }, hide: true
    },*/
    {headerName: 'Erstellt', field: 'createdAt', hide: false, nxtFieldType: NxtFieldType.Date_germanTime, sortable: true},
    {headerName: 'Ersteller', field: 'createdBy', hide: false, sortable: true},
    {headerName: 'Aktualisiert von', field: 'updatedBy', hide: false, nxtFieldType: NxtFieldType.Text, sortable: true},
    {
      headerName: 'Arbeitsplatz', field: 'createdByWorkplace', hide: false,
      valueGetter: (params) => {
        return WorkplaceTools.getText(params.data.createdByWorkplace);
      }
      , sortable: true
    },
    {headerName: 'Promo', field: 'promoOfferId', hide: false, sortable: true, nxtFieldType: NxtFieldType.Text},
    {
      headerName: 'Angebot', field: 'discountPromotion', valueGetter: (params) => {
        if (params.data.discountPromotion) {
          return params.data.discountPromotion.name;
        }
        // return JsonTools.stringify(params.data.discountPromotion);
      }, hide: false, sortable: true, nxtFieldType: NxtFieldType.Text
    },
    {headerName: 'Studio Erstellt', field: 'createdByStudio', sortable: true},
    {headerName: 'Studio Termin', field: 'studio', sortable: true},
    {headerName: 'Arbeit', field: 'workType', valueFormatter: (params) => params.value.replace('tattoo', 'Tattoo').replace('piercing', 'Piercing')},
    {
      headerName: 'Termin', field: 'start',
      cellRenderer: (params: any) => DateTools.format(params.data.start, 'dd.MM.yyyy HH:mm') + ' - ' + DateTools.format(params.data.end, 'HH:mm')
    },
    {headerName: 'Tätowierer', field: 'artist', sortable: true},
    {headerName: 'akt. von', field: 'updatedBy', hide: true},
    {headerName: 'aktualisiert', field: 'updatedAt', nxtFieldType: NxtFieldType.Date_germanDateTime, hide: true},
    {headerName: 'Wert', field: 'valence', nxtFieldType: NxtFieldType.Decimal, aggFunc: 'sum', sortable: true},
    {headerName: 'Preis', field: 'priceEstimatedFrom', nxtFieldType: NxtFieldType.Money, aggFunc: 'sum', sortable: true},
    {
      headerName: 'Dauer - Preis', field: '', nxtFieldType: NxtFieldType.Text,
      valueGetter: (params) => {
        if (params.data.workType === 'tattoo') {
          const timeCheckResult = EventCalcTools.timeCheck(params.data.end - params.data.start, params.data.priceEstimatedFrom);
          return timeCheckResult.calculatedPriceDiff;
        }
      },
      valueFormatter: (params) => {
        if (params.value) {
          if (params.value > 0) {
            return '+' + DecimalTools.toMoneyString(params.value);
          }
          return DecimalTools.toMoneyString(params.value);
        }
      },
      minWidth: 110, maxWidth: 110,
      cellStyle: (params) => {
        if (Math.abs(params.value) > 50) {
          if (Math.abs(params.value) >= 100) {
            return {textAlign: 'right', color: ColorTools.Red};
          } else {
            return {textAlign: 'right', color: ColorTools.Orange};
          }
        }
        return {textAlign: 'right'};
      }, hide: !this.backofficeComponent.permissionService.hasPermission(NxtPermissionId.ShowDurationPriceCheckColumn)
    },
    {
      headerName: 'bezahlt',
      field: 'paymentSum',
      nxtFieldType: NxtFieldType.Money,
      aggFunc: 'sum',
      minWidth: 90,
      maxWidth: 90,
    },
    {
      headerName: 'Artist', field: 'artistTotalGet', cellRenderer: (params: any) => {
        if (params.data.artistTotalGet) {
          // return DecimalTools.toMoneyString(params.data.artistTotalGet) + ' (' + Math.round(params.data.artistPercentage) + ' %)';
          return Math.round(params.data.artistPercentage) + ' %';
        }
      }
    },
    {
      headerName: 'Kunde', field: '', valueGetter: (params) => {
        if (params.data.customerObj) {
          return params.data.customerObj.givenName + ' ' + params.data.customerObj.familyName;
        }
      }
    },
    {headerName: 'Motiv', field: 'tattooMotive'},
    {headerName: 'Wichtig', field: 'importantInfo', cellStyle: {color: ColorTools.Red}},
    {headerName: 'Titel', field: 'title', hide: true},
  ];

  _createdEventsColumnDefs: NxtColDef[];
  public get createdEventsColumnDefs() {
    if (!this._createdEventsColumnDefs) {
      this._createdEventsColumnDefs = [
        {headerName: 'Geändert', field: 'updatedAt', hide: false, nxtFieldType: NxtFieldType.Date_germanTime, sortable: true},
        ...this.newEventsColumnDefs.filter(d => d.headerName !== 'Erstellt')
      ];
    }
    return this._createdEventsColumnDefs;
  }
}
