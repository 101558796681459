<div class="flex border-5-blue flex-col flex-grow w-full">
  <div class="flex justify-start text-[120%] items-baseline">
    <div>{{ headerText() }}</div>

    @if (mode() === 'search') {
      @if (filterPossibleTransactionsSameCompanies() && otherCompanyName()) {
        <div class="text-[70%]">&nbsp;Gegenüber {{ otherCompanyName() }}</div>
      }
      @if (filterPossibleTransactionsSameValue() && initValue()) {
        <div class="text-[70%]">&nbsp;mit {{ initValue().toMoneyString() }}</div>
      }
    }
  </div>
  @if (mode() === 'search') {
    <div class="flex flex-row gap-4 border-3-yellow justify-center items-center">
      <nxt-input class="w-[220px]" [noPadding]="false" [center]="true" [(value)]="quickFilterPossibleTransactions" [clearIcon]="true"
                 placeholder="Suche"></nxt-input>
      <div class="flex flex-col items-center w-[300px]">
        <div class="relative top-[6px]" [nxtMultiClick]="3" (nxtOnMultiClick)="minMaxValue.set(minMaxValue() + 100)">{{ filterDaysBack() }}
          Tag(e) {{ filterBookDateFromDateString() | nxtDate: 'dd.MM.yyyy' }}
        </div>
        <div class="flex self-stretch">
          <mat-slider fxFlex [min]="-1 * minMaxValue()" [max]="minMaxValue()" step="1">
            <input matSliderThumb [(ngModel)]="filterDaysBack">
          </mat-slider>
        </div>
      </div>
      <div class="flex flex-col items-center w-[300px]">
        <div class="relative top-[6px]" [nxtMultiClick]="3"
             (nxtOnMultiClick)="minMaxValue.set(minMaxValue() + 100)">{{ filterDaysForward() > 0 ? '+' : '' }}{{ filterDaysForward() }}
          Tag(e) {{ filterBookDateTillDateString() | nxtDate: 'dd.MM.yyyy' }}
        </div>
        <div class="flex self-stretch">
          <mat-slider fxFlex [min]="-1 * minMaxValue()" [max]="minMaxValue()" step="1">
            <input matSliderThumb [(ngModel)]="filterDaysForward">
          </mat-slider>
        </div>
      </div>
      <nxt-slide-toggle [(value)]="filterPossibleTransactionsAlsoFullyAssigned">auch Zugewiesene</nxt-slide-toggle>
      @if (otherCompanyName()) {
        <nxt-slide-toggle [(value)]="filterPossibleTransactionsSameCompanies">gleiche Firmen</nxt-slide-toggle>
      }
      <nxt-slide-toggle [(value)]="filterPossibleTransactionsSameValue">gleicher Betrag</nxt-slide-toggle>
    </div>
  }
  <div class="flex flex-col flex-grow border-3 relative">
    @if (selectedPossibleTransactions().length > 0) {
      <div
        [matTooltip]="assignText()" (click)="assignSelectedPossibleTransactionsClicked()"
        class="assign-icon flex-col flex justify-start items-center h-fit cursor-pointer"
        [style.background-color]="assignIconColor()"
      >
        <mat-icon fontSet="material-symbols-outlined" class="mouse-pointer">{{ assignIcon() }}</mat-icon>
        <div class="text-[70%]">{{ selectedPossibleTransactionsText() }}</div>
      </div>
    }
    <nxt-datagrid
      class="w-full h-full flex"
      [style.min-height]="gridMinHeight() + 'px'"
      [columnDefs]="possibleTransactionsColumnDefs"
      [quickFilterText]="quickFilterPossibleTransactions()"
      [rowData]="possibleTransactionsFiltered()"
      [rowSelection]="possibleTransactionRowSelection"
      (selectionChanged)="possibleTransactionSelectionChanged($event)"
      [showFooter]="true"
      [pinnedBottomRowData]=""
      [loadingSpinner]="possibleTransactionLoading()"
    >
    </nxt-datagrid>
  </div>
</div>
