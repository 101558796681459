import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtContact} from '../../common-interfaces/nxt.contact.interface';
import {SocketService} from '../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../services/dialog.service';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {NgIf} from '@angular/common';
import {ContactPossibleCombineAttributes, ContactTools} from '../../common-browser/helpers/contact.tools';
import {clone} from '../../common-browser/helpers/object.tools';
import {keys} from 'lodash';
import {JsonTools} from '../../common-browser/helpers/json.tools';
import {LoginService} from '../../services/login.service';


@Component({
    selector: 'nxt-combine-contacts-2',
    templateUrl: './combine-contacts-2.component.html',
    styleUrls: ['./combine-contacts-2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NxtButtonComponent, NxtDatePipe]
})

export class CombineContacts2Component extends NxtComponent implements OnInit, NxtOnDestroy {

  possibleAttributes = signal<ContactPossibleCombineAttributes>(null);

  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  private loginService = inject(LoginService);

  newContact = signal<NxtContact>(null);

  contacts = signal<NxtContact[]>([]);
  attributes = signal<string[]>([]);

  canCombine = computed(() => {
    for (const attr of this.attributes()) {
      if (this.newContact()[attr] === null) {
        return false;
      }
    }
    return true;
  });


  constructor(
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<any>,
  ) {
    super();
  }


  // previewContact: NxtContact;

  // contactAttributes = ['givenName', 'familyName', 'birthday', 'mobile', 'email', 'streetAddress', 'postalCode', 'city', 'country', 'lang'];

  ngOnInit() {

  }

  nxtOnDestroy() {

  }

  setContactAttr(attr: string, value: string | null) {
    this.newContact.update(c => {
      c[attr] = value;
      return clone(c);
    });
  }

  async setContacts(contactIds: string[]) {
    const contacts = await Promise.all(contactIds.map(async contactId => this.socketService.getContactById(contactId)));
    this.contacts.set(contacts.filter(c => !!c));
    if (this.contacts().length > 1) {
      this.possibleAttributes.set(ContactTools.getPossibleCombineAttributes(this.contacts()));
      this.newContact.set(clone(this.contacts()[0]));
      for (const attr of keys(this.possibleAttributes())) {
        if (this.possibleAttributes()[attr].length === 0) {
          this.setContactAttr(attr, '');
        } else if (this.possibleAttributes()[attr].length === 1) {
          this.setContactAttr(attr, this.possibleAttributes()[attr][0]);
        } else {
          this.setContactAttr(attr, null);
        }
      }
    } else {
      this.dialogService.showOk('Du musst mehr als ein Kontakt auswählen');
      this.socketService.sendTelegramAdmin('Du musst mehr als ein Kontakt auswählen\n\n' + this.loginService.getUsername() + '\n\n' + contactIds.join('&') + '\n\n' + JsonTools.stringifyFormat(this.contacts()));
      this.dialogRef.close();
    }
    this.attributes.set(keys(this.possibleAttributes()));
  }

  close() {
    this.dialogRef.close();
  }

  setAttribute(attr: string, contact: NxtContact) {
    console.log(attr, contact);
  }

  async combine() {
    this.dialogService.showLoading('Kontakte werden zusammengeführt...');
    this.newContact.set({...this.newContact(), fullName: this.newContact().givenName + ' ' + this.newContact().familyName});
    await this.socketService.combineContacts2(this.newContact(), this.contacts().map(c => c.id));
    this.dialogService.hideLoading();
    this.dialogRef.close();
  }

  setAttrClicked(attr: string, value: any) {
    this.setContactAttr(attr, value);
  }
}
