import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {InputComponent} from '../form-controls/input/input.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {SocketService} from '../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'nxt-artist-key-safe-code',
    templateUrl: './artist-key-safe-code.component.html',
    styleUrls: ['./artist-key-safe-code.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        InputComponent,
        NxtButtonComponent
    ]
})

export class ArtistKeySafeCodeComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  code1 = signal('');
  code2 = signal('');


  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  socketService = inject(SocketService);
  dialogRef = inject(MatDialogRef);


  constructor() {
    super();
  }

  async ngOnInit() {
    const data = await this.socketService.getArtistKeySafeCodes();
    for (const key of data) {
      if (key.safeNumber === 1) {
        this.code1.set(key.code);
      }
      if (key.safeNumber === 2) {
        this.code2.set(key.code);
      }
    }
  }

  nxtOnDestroy() {
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  async saveClicked() {
    const data = await this.socketService.getArtistKeySafeCodes();
    for (const key of data) {
      if (key.safeNumber === 1) {
        key.code = this.code1();
      }
      if (key.safeNumber === 2) {
        key.code = this.code2();
      }
    }
    await this.socketService.setArtistKeySafeCodes(data);
    this.dialogRef.close();
  }
}
