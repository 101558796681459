import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {NxtTypedFormControl} from '../../../nxt-form/nxt.typed-form-control';
import {InputComponent} from '../../form-controls/input/input.component';
import {FormTools} from '../../../services/form.tools';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {NxtDiscountPromotion, NxtDiscountPromotionGiftCard} from '../../../common-interfaces/discount-promotion.interface';
import {WithoutNxtDbFields} from '../../../common-interfaces/nxt.db-fields.interface';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {AutocompleteComponent} from '../../form-controls/autocomplete/autocomplete.component';
import {NxtPromoPromotion, NxtPromoUser} from '../../../common-interfaces/nxt.promo.interface';
import {DisplayWithTools} from '../../../common-browser/helpers/display-with.tools';
import {PermissionDirective} from '../../../directives/permission.directive';
import {Clipboard} from '@angular/cdk/clipboard';
import {toObservable} from '@angular/core/rxjs-interop';

@Component({
  selector: 'nxt-discount-promotion-edit',
  templateUrl: './discount-promotion-edit.component.html',
  styleUrls: ['./discount-promotion-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    SlideToggleComponent,
    NxtButtonComponent,
    MatButtonToggleGroup,
    MatButtonToggle,
    ReactiveFormsModule,
    AutocompleteComponent,
    PermissionDirective,
  ],
})
export class DiscountPromotionEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor() {
    super();
    this.form.valueChanges.subscribe(() => this.cdRef.detectChanges());
    toObservable(this.hasGiftCard).subscribe(() => {
      this.setValidators();
    });
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  originalDiscountPromotion = signal<NxtDiscountPromotion | null>(null);
  promoPromoters = signal<NxtPromoUser[]>([]);
  promoPromotions = signal<NxtPromoPromotion[]>([]);
  hasGiftCard = signal(false);

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  socketService = inject(SocketService);
  dialogRef = inject(MatDialogRef);
  dialogService = inject(DialogService);
  clipboard = inject(Clipboard);

  form = new FormGroup({
    id: new NxtTypedFormControl('', [], 'ID'),
    name: new NxtTypedFormControl('', [ValidatorTools.requiredAndNotNaN], 'Name'),
    active: new NxtTypedFormControl(false, [], 'Aktiv'),
    discountType: new NxtTypedFormControl<'priceLike' | 'priceFix' | 'percentage' | null>(null, [Validators.required], 'Rabatt-Typ'),
    discountPercentage: new NxtTypedFormControl(0, [], 'Rabatt'),
    priceFixValue: new NxtTypedFormControl<number>(null, [], 'Fix-Preis-Betrag'),
    artistGet: new NxtTypedFormControl<number>(null, [], 'Artist bekommt'),
    disableDiscountedGiftCards: new NxtTypedFormControl(false, [], 'Keine rabattierten Gutscheine'),
    additionalPayAfterEnd: new NxtTypedFormControl(false, [], 'Nachkassieren'),
    // maxHours: new NxtTypedFormControl<number>(null, [], 'Max Std.'),
    onlyWithPromoCode: new NxtTypedFormControl(false, [], 'Nur mit Promo-Code'),
    staticPromoCode: new NxtTypedFormControl(false, [], 'Statischer Promo-Code'),
    staticPromoCodePromoterId: new NxtTypedFormControl<NxtPromoUser | null>(null, [], 'Statischer Promo-Code Promoter'),
    staticPromoCodePromoId: new NxtTypedFormControl<NxtPromoPromotion>(null, [], 'Statischer Promo-Code Angebot'),
    multipleEvents: new NxtTypedFormControl(false, [], 'Mehrere Termine'),
    giftCardName: new NxtTypedFormControl<string>('', [], 'Name'),
    giftCardValue: new NxtTypedFormControl<number | null>(null, [], 'Wert'),
    giftCardCustomRestrictionValue: new NxtTypedFormControl<number | null>(null, [], 'Mindestpreis'),
    giftCardOnlyNewAppointments: new NxtTypedFormControl<boolean>(false, [], 'Nur für neue Termine'),
    giftCardValidTillMonths: new NxtTypedFormControl<number | null>(null, [], 'Gültig in Monaten'),
    giftCardDisableDiscountPromo: new NxtTypedFormControl(false, [], 'Nicht auf Specials'),
  });


  protected readonly DisplayWithTools = DisplayWithTools;

  ngOnInit() {
    this.form.valueChanges.subscribe(vale => {
      this.setValidators();
    });
  }

  nxtOnDestroy() {
  }

  private async loadPromoters() {
    this.promoPromoters.set(await this.socketService.getPromoUsers());
    this.promoPromotions.set(await this.socketService.getPromoPromotions());
  }


  async load(discountPromotionId: string) {
    await this.loadPromoters();
    if (discountPromotionId === 'new') {
      this.setDiscountPromotionToForm({
        id: '',
        onlyWithPromoCode: false,
        artistGet: null,
        name: '',
        priceFix: false,
        discountPercentage: null,
        disabled: false,
        priceLike: false,
        staticPromoCode: false,
        staticPromoCodePromoterId: '',
        staticPromoCodePromoId: '',
        additionalPayAfterEnd: false,
        disableDiscountedGiftCards: false,
        priceFixValue: null,
      });
    } else {
      this.originalDiscountPromotion.set(await this.socketService.getDiscountPromotion(discountPromotionId));
      this.setDiscountPromotionToForm(this.originalDiscountPromotion());
    }

    // FormTools.setForm(this.form, this.originalDiscountPromotion);
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  async saveClicked() {
    if (FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      if (this.originalDiscountPromotion()) {
        const toSave = {...this.originalDiscountPromotion(), ...this.getDiscountPromotionFromForm()};
        await this.socketService.upsertDiscountPromotion(toSave);
        this.dialogRef.close(true);
      } else {
        await this.socketService.upsertDiscountPromotion(this.getDiscountPromotionFromForm());
        this.dialogRef.close(true);
      }
    }
  }

  private setDiscountPromotionToForm(discountPromotion: WithoutNxtDbFields<NxtDiscountPromotion>) {
    this.form.controls.name.setValue(discountPromotion.name);
    if (discountPromotion.priceFix) {
      this.form.controls.discountType.setValue('priceFix');
    } else if (discountPromotion.priceLike) {
      this.form.controls.discountType.setValue('priceLike');
    } else if (discountPromotion.discountPercentage !== null) {
      this.form.controls.discountType.setValue('percentage');
    }
    this.form.controls.discountPercentage.setValue(discountPromotion.discountPercentage);
    this.form.controls.priceFixValue.setValue(discountPromotion.priceFixValue);
    this.form.controls.artistGet.setValue(discountPromotion.artistGet);
    this.form.controls.onlyWithPromoCode.setValue(discountPromotion.onlyWithPromoCode);
    this.form.controls.active.setValue(!discountPromotion.disabled);
    this.form.controls.staticPromoCode.setValue(discountPromotion.staticPromoCode);
    this.form.controls.additionalPayAfterEnd.setValue(discountPromotion.additionalPayAfterEnd);
    this.form.controls.disableDiscountedGiftCards.setValue(discountPromotion.disableDiscountedGiftCards);
    this.form.controls.staticPromoCodePromoterId.setValue(this.promoPromoters().find(p => p.promoterId === discountPromotion.staticPromoCodePromoterId));
    this.form.controls.staticPromoCodePromoId.setValue(this.promoPromotions().find(p => p.id === discountPromotion.staticPromoCodePromoId));

    this.form.controls.giftCardName.setValue(discountPromotion.giftCard?.name || '');
    this.form.controls.giftCardValue.setValue(discountPromotion.giftCard?.value || null);
    this.form.controls.giftCardCustomRestrictionValue.setValue(discountPromotion.giftCard?.customRestrictionValue || null);
    this.form.controls.giftCardOnlyNewAppointments.setValue(discountPromotion.giftCard?.onlyNewAppointments || false);
    this.form.controls.giftCardValidTillMonths.setValue(discountPromotion.giftCard?.validTillMonths || null);
    this.form.controls.giftCardDisableDiscountPromo.setValue(discountPromotion.giftCard?.disableDiscountPromo || false);
    this.hasGiftCard.set(!!discountPromotion.giftCard?.name);
  }

  private getDiscountPromotionFromForm(): WithoutNxtDbFields<NxtDiscountPromotion> {
    const priceFix = this.form.value.discountType === 'priceFix';
    const priceLike = this.form.value.discountType === 'priceLike';
    let artistGet = this.form.value.artistGet;
    let discountPercentage = this.form.value.discountPercentage;
    if (priceFix || priceLike) {
      discountPercentage = null;
    } else {
      artistGet = null;
    }
    let giftCard: NxtDiscountPromotionGiftCard | undefined = undefined;
    if (this.hasGiftCard()) {
      giftCard = {
        name: this.form.value.giftCardName,
        value: this.form.value.giftCardValue,
        customRestrictionValue: this.form.value.giftCardCustomRestrictionValue,
        onlyNewAppointments: this.form.value.giftCardOnlyNewAppointments,
        validTillMonths: this.form.value.giftCardValidTillMonths,
        disableDiscountPromo: this.form.value.giftCardDisableDiscountPromo,
      };
    }
    const result: WithoutNxtDbFields<NxtDiscountPromotion> = {
      name: this.form.value.name,
      disabled: !this.form.value.active,
      discountPercentage,
      priceFixValue: this.form.value.priceFixValue,
      artistGet,
      disableDiscountedGiftCards: this.form.value.disableDiscountedGiftCards,
      priceFix,
      priceLike,
      additionalPayAfterEnd: this.form.value.additionalPayAfterEnd,
      onlyWithPromoCode: this.form.value.onlyWithPromoCode,
      staticPromoCode: this.form.value.staticPromoCode,
      staticPromoCodePromoterId: this.form.value.staticPromoCodePromoterId?.id || '',
      staticPromoCodePromoId: this.form.value.staticPromoCodePromoId?.id || '',
      giftCard,
    };
    return result;
  }

  copyIdClicked() {
    this.clipboard.copy(this.originalDiscountPromotion().id);
  }

  private setValidators() {
    FormTools.setValidators(this.form.controls.priceFixValue, this.form.value.discountType !== 'percentage' ? [Validators.required] : []);
    FormTools.setValidators(this.form.controls.artistGet, this.form.value.discountType !== 'percentage' ? [Validators.required] : []);
    FormTools.setValidators(this.form.controls.discountPercentage, this.form.value.discountType === 'percentage' ? [Validators.required] : []);
    FormTools.setValidators(this.form.controls.staticPromoCodePromoterId, this.form.value.staticPromoCode ? [ValidatorTools.hasProperty('name')] : []);
    FormTools.setValidators(this.form.controls.staticPromoCodePromoId, this.form.value.staticPromoCode ? [ValidatorTools.hasProperty('title')] : []);

    FormTools.setValidators(this.form.controls.giftCardName, this.hasGiftCard() ? [Validators.required] : []);
    FormTools.setValidators(this.form.controls.giftCardValue, this.hasGiftCard() ? [Validators.required] : []);
    FormTools.setValidators(this.form.controls.giftCardCustomRestrictionValue, this.hasGiftCard() ? [Validators.required] : []);
    FormTools.setValidators(this.form.controls.giftCardValidTillMonths, this.hasGiftCard() ? [Validators.required] : []);
  }
}
