import {ChangeDetectionStrategy, Component, inject, OnInit, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {SocketService} from '../../../services/socket/socket.service';
import {DateRangePickerComponent} from '../../form-controls/date-range-picker/date-range-picker.component';
import {InputComponent} from '../../form-controls/input/input.component';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {NxtPageComponent} from '../../nxt-page/nxt-page.component';
import {NxtPageContentComponent} from '../../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {NxtPageHeaderComponent} from '../../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageHeaderTitleComponent} from '../../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {FormsModule} from '@angular/forms';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {IconTools} from '../../../common-browser/helpers/icon.tools';
import {DialogService} from '../../../services/dialog.service';
import {NxtBankDocument, NxtBankDocumentStatus, NxtMultiImportRunnerStatus} from '../../../common-interfaces/bank/bank-transaction.interface';
import {firstValueFrom} from 'rxjs';
import {SocketInterface} from '../../../common-interfaces/socket/socket-interface';
import {MatTooltip} from '@angular/material/tooltip';
import {MoneyTools} from '../../../common-browser/helpers/money.tools';
import {LoginService} from '../../../services/login.service';
import {BankDocumentDetailsComponent} from './bank-document-details/bank-document-details.component';
import {BankDocumentService} from './bank-document.service';
import {BankDocumentTools} from '../../../common-browser/helpers/bank-document.tools';
import {SpinnerComponent} from '../../spinner/spinner.component';
import {Router} from '@angular/router';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {DriveTools} from '../../../common-browser-public/helpers/drive.tools';
import {DownloadService} from '../../../services/download.service';
import {LocalStorageService} from '../../../services/local-storage.service';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'nxt-bank-documents',
  templateUrl: './bank-documents.component.html',
  styleUrls: ['./bank-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NxtDatagridComponent, DateRangePickerComponent, InputComponent, NxtButtonIconComponent, NxtPageComponent, NxtPageContentComponent, NxtPageFooterComponent, NxtPageHeaderComponent, NxtPageHeaderTitleComponent, SlideToggleComponent, FormsModule, MatTooltip, SpinnerComponent, MatButtonToggleGroup, MatButtonToggle, MatMenuTrigger, MatMenu, MatIcon, MatMenuItem],
})

export class BankDocumentsComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  @ViewChild(NxtDatagridComponent) datagrid: NxtDatagridComponent;


  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  quickFilterText = signal('');
  multiImporterSate = signal<NxtMultiImportRunnerStatus>({isRunning: false, progress: 0, statusText: '', log: [], errorMessage: ''});
  bankDocuments = signal<NxtBankDocument[]>([]);

  /*** Signals -> Filters ***/
  filterMode = signal<'time' | 'newDocuments'>('time');
  // filterOnlyLastMonth = signal(true);
  filterNotFinished = signal(true);
  filterDateFromTill = signal({
    from: DateTools.getFirstOfMonth(Date.now()).dateFormatDate(),
    till: DateTools.getFirstOfMonth(Date.now()).dateAddMonths(1).dateAddDays(-1).dateFormatDate(),
  });


  /*** Injections ***/
  private socketService = inject(SocketService);
  private router = inject(Router);
  public dialogRef = inject(MatDialogRef, {optional: true});
  private dialogService = inject(DialogService);
  private bankDocumentService = inject(BankDocumentService);
  private loginService = inject(LoginService);
  private downloadService = inject(DownloadService);
  private localStorageService = inject(LocalStorageService);

  columnDefs: NxtColDef<NxtBankDocument>[] = [
    {
      headerName: 'id',
      field: 'id',
      maxWidth: 50,
      minWidth: 50,
      filter: false,
      nxtFieldType: NxtFieldType.Text,
      hide: !this.loginService.isJulian(),
    },
    {
      headerName: '',
      field: 'id',
      cellRenderer: () => IconTools.Material.Edit,
      cellStyle: {textAlign: 'center'},
      maxWidth: 45,
      minWidth: 45,
      filter: false,
      nxtOnCellClicked: params => this.showDocumentDetailClicked(params.data),
    }, {
      headerName: '',
      field: 'id',
      cellRenderer: () => IconTools.Material.Run,
      cellStyle: {textAlign: 'center'},
      maxWidth: 45,
      minWidth: 45,
      filter: false,
      hide: !this.loginService.isJulian(),
      nxtOnCellClicked: params => this.runClicked(params.data.id),
    },
    {
      headerName: '',
      cellRenderer: () => IconTools.Material.Download,
      cellStyle: {textAlign: 'center'},
      maxWidth: 45,
      minWidth: 45,
      filter: false,
      hide: !this.loginService.isJulian(),
      nxtOnCellClicked: params => this.downloadDocumentClicked(params.data),
    },
    {
      headerName: '',
      tooltipValueGetter: () => 'Auf später verschieben',
      cellRenderer: params => {
        if (params.data.status !== NxtBankDocumentStatus._90_ok) {
          return IconTools.Material.Later;
        }
      },
      cellStyle: {textAlign: 'center'},
      maxWidth: 45,
      minWidth: 45,
      filter: false,
      nxtOnCellClicked: params => this.setLaterClicked(params.data),
    },
    {
      headerName: '',
      cellRenderer: () => IconTools.Material.Delete,
      cellStyle: {textAlign: 'center'},
      maxWidth: 45,
      minWidth: 45,
      filter: false,
      hide: !this.loginService.isJulian(),
      nxtOnCellClicked: params => this.deleteClicked(params.data.id),
    },
    {
      headerName: 'Typ',
      valueGetter: params => BankDocumentTools.getTypeText(params?.data?.documentType),
      nxtFieldType: NxtFieldType.Text,
    },
    {
      headerName: 'Status', valueGetter: params => {
        if (params.data.status === NxtBankDocumentStatus._30_transaction && params.data.assignedTransactions.length > 0) {
          return '❗' + params.data.assignValue.toMoneyString() + ' offen';
        }
        if (params.data.status === NxtBankDocumentStatus._05_checkOwnCompany) {
          return '❗' + params.data.ownCompany + ' fehlt';
        }
        if (params.data.statusMessage) {
          if (this.loginService.isJulian()) {
            return '❗' + params.data.statusMessage + ' [' + params.data.status + ']';
          }
          return '❗' + params.data.statusMessage;
        } else {
          if (this.loginService.isJulian()) {
            return params.data.status;
          }
          return BankDocumentTools.getStatusText(params.data.status);
        }
      },
    },
    {
      headerName: 'Datum', field: 'documentDate',
      filterParams: {}, nxtFieldType: NxtFieldType.Date_germanDate,
      valueFormatter: params => {
        if (!params.data.documentDate) {
          return '';
        }
        let days = ((Date.now() - params.data.documentDate) / DurationTools.DURATION_1DAY).abs().round(0);
        if (days === 0) {
          days = 1;
        }
        const daysText = days === 1 ? '1 Tag' : (days + ' Tage');
        return daysText + ' · ' + params.data.documentDate.dateFormat('dd.MM.yyyy');
      },
    },
    {
      headerName: 'Upload', field: 'createdAt',
      filterParams: {}, nxtFieldType: NxtFieldType.Date_germanDateTime,
    },
    {
      headerName: 'Betrag', field: 'nxtAi.invoice.valueGross',
      cellStyle: {textAlign: 'right'},
      valueFormatter: data => {
        const prefix = MoneyTools.currencyShort(data.data.nxtAi?.invoice?.currency || 'EUR');
        return data.data?.nxtAi?.invoice?.valueGross?.toMoneyString(prefix, true) || '';
      },
    },
    {
      headerName: 'Firma', valueGetter: params => {
        return BankDocumentTools.getOtherCompany(params.data).name;
      },
    },
    {
      headerName: 'Transaktionen', nxtFieldType: NxtFieldType.Text,
      valueGetter: data => data.data.assignedTransactions?.map(t => t.id).join(' | ') || '',
    },
    {
      headerName: 'Dokument-Nr', valueGetter: params => {
        return params.data.documentNumber;
      }, nxtFieldType: NxtFieldType.Text,
    },
    {headerName: 'weitere ReNr', field: 'nxtAi.invoice.numbers', valueGetter: data => data.data?.nxtAi?.invoice?.numbers?.join(' | ') || ''},
  ];


  constructor() {
    super();
    this.restoreAllFiltersFromStorage();
  }

  async ngOnInit() {
    this.load();
    // this.multiImporterSate.set(await this.socketService.getBankImportMultiRunnerState());
    this.pushSocketSubscription = this.socketService.subscribeNew('getBankImportMultiRunnerState', state => {
      this.multiImporterSate.set(state);
    }, {emitInitial: true, emitAfterReconnect: true});
  }

  nxtOnDestroy() {
  }

  async load() {
    this.saveAllFiltersToStorage();
    const filter: SocketInterface.GetBankDocuments = {};
    switch (this.filterMode()) {
      case 'time':
        filter.documentDate = {
          fromDateString: this.filterDateFromTill().from,
          tillDateString: this.filterDateFromTill().till,
        };
        break;
      case 'newDocuments':
        filter.createdAt = {
          fromDateString: Date.now().dateAddDays(-14).dateFormatDate(),
          tillDateString: Date.now().dateFormatDate(),
        };
    }
    if (this.filterNotFinished()) {
      filter.notFinished = true;
    }

    const bankDocuments = await this.socketService.getBankDocuments(filter);
    switch (this.filterMode()) {
      case 'time':
        this.bankDocuments.set(bankDocuments.sortNumber('documentDate', false));
        break;
      case 'newDocuments':
        this.bankDocuments.set(bankDocuments.sortNumber('createdAt', true));
    }
  }

  private async showInvoiceClicked(data: NxtBankDocument) {
    // const base64 = await this.socketService.getBankDocumentPdf(data.id);
    // this.dialogService.showPdf(base64, 'rechnung.pdf');
  }

  reloadCLicked() {
    this.load();
  }

  async getMyInvoicesImportClicked() {
    this.dialogService.showLoading('Importiere Rechnungen');
    await this.socketService.startGetMyInvoicesImport();
    this.dialogService.hideLoading();
  }

  async getMyInvoicesFixDocumentsClicked() {
    this.dialogService.showLoading('Firmen werden gefixt');
    await this.socketService.getMyInvoicesFixDocuments();
    this.dialogService.hideLoading();
  }


  async runClicked(id?: string) {
    const ids: string[] = [];
    if (id) {
      ids.push(id);
    } else {
      this.datagrid.api.forEachNodeAfterFilter((node) => {
        ids.push(node.data.id);
      });
    }
    await this.bankDocumentService.run(ids);
    this.load();
  }

  public async deleteTransactions() {
    const ids: string[] = [];
    this.datagrid.api.forEachNodeAfterFilter((node) => {
      ids.push(node.data.id);
    });
    this.dialogService.showLoading('AI wird gestartet');
    await this.socketService.removeTransactionsFromDocuments(ids);
    this.dialogService.hideLoading();
    this.load();
  }

  private async showDocumentDetailClicked(data: NxtBankDocument) {
    const dialog = this.dialogService.showComponentFull(BankDocumentDetailsComponent);
    dialog.componentInstance.load(data.id).then();
    if (await firstValueFrom(dialog.afterClosed())) {
      this.load().then();
    }
  }

  private async deleteClicked(documentId: string) {
    if (await this.dialogService.showYesNo('Dokument wirklich löschen?')) {
      await this.socketService.deleteBankDocument(documentId);
      this.load().then();
    }
  }


  private async setLaterClicked(document: NxtBankDocument) {
    const buttons = [
      {text: '1 Tag', value: 1},
      {text: '3 Tage', value: 3},
      {text: '1 Woche', value: 7},
      {text: '2 Wochen', value: 14},
    ];
    const result = await this.dialogService.showButtons('Auf wann verschieben', {buttons, showCancelButton: true});
    if (result) {
      await this.socketService.setBankDocumentCheckLater(document.id, result.value);
      this.load();
    }
  }

  startMultiImportClicked() {
    return this.socketService.startBankDocumentMultiImport();
  }

  public showBankTransactionsClicked(ev: MouseEvent) {
    if (ev.shiftKey || ev.button === 1) {
      window.open('/bank-transactions', '_blank');
    } else if (ev.button === 0) {
      this.router.navigate(['/bank-transactions']);
    }
  }

  public showMultiImportErrorClicked() {
    this.dialogService.showOk(this.multiImporterSate().errorMessage + '\n\n' + this.multiImporterSate().log.join('\n'));
  }

  private downloadDocumentClicked(document: NxtBankDocument) {
    this.downloadService.downloadUrl(DriveTools.getDriveLink(document.driveFileId), document.documentNumber + '.pdf');
  }


  private restoreAllFiltersFromStorage() {
    const storageState = this.localStorageService.get('BankDocumentFilter', {
      filterMode: this.filterMode(),
      dateStringFrom: this.filterDateFromTill().from,
      dateStringTill: this.filterDateFromTill().till,
      // filterOnlyLastMonth: this.filterOnlyLastMonth(),
      filterNotFinished: this.filterNotFinished(),
    });
    this.filterMode.set(storageState.filterMode);
    this.filterDateFromTill.set({
      from: storageState.dateStringFrom,
      till: storageState.dateStringTill,
    });
    this.filterNotFinished.set(storageState.filterNotFinished);
  }

  private saveAllFiltersToStorage() {
    this.localStorageService.set('BankDocumentFilter', {
      filterMode: this.filterMode(),
      dateStringFrom: this.filterDateFromTill().from,
      dateStringTill: this.filterDateFromTill().till,
      filterNotFinished: this.filterNotFinished(),
    });
  }

  async downloadLinksClicked(type: 'meta' | 'paypal') {
    switch (type) {
      case 'meta':
        const month = await this.dialogService.showButtons('Monat', {
          buttons: [
            {value: Date.now().dateAddMonths(-2).dateFormat('yyyy-MM'), text: Date.now().dateAddMonths(-2).dateFormat('MMMM yyyy')},
            {value: Date.now().dateAddMonths(-1).dateFormat('yyyy-MM'), text: Date.now().dateAddMonths(-1).dateFormat('MMMM yyyy')},
            {value: Date.now().dateFormat('yyyy-MM'), text: Date.now().dateFormat('MMMM yyyy')},
          ],
        });
        const fromDateString = month.value + '-01';
        const tillDateString = DateTools.getLastOfMonth(fromDateString).dateFormatDate();
        const linkAc = await this.socketService.getMetaDownloadLink('ac', fromDateString, tillDateString);
        const linkDu = await this.socketService.getMetaDownloadLink('du', fromDateString, tillDateString);
        const linkDo = await this.socketService.getMetaDownloadLink('do', fromDateString, tillDateString);
        // let html = '<'
        let text = 'Aachen: <a href="' + linkAc + '" target="_blank">➞öffnen</a>';
        text += '\nDüsseldorf: <a href="' + linkDu + '" target="_blank">➞öffnen</a>';
        text += '\nDortmund: <a href="' + linkDo + '" target="_blank">➞öffnen</a>';
        this.dialogService.showOk(text);
        break;
      case 'paypal':
        if (await this.dialogService.showYesNo('https://www.paypal.com/reports/financialSummaries/financialSummary', {yesText: '➞öffnen', noText: 'Abbrechen'})) {
          window.open('https://www.paypal.com/reports/financialSummaries/financialSummary', '_blank');
        }
    }
  }
}
