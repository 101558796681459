import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SocketService} from '../../services/socket/socket.service';
import {ConfigService} from '../../services/config.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NxtInvoiceData} from '../../common-interfaces/nxt.invoice.interface';

@Component({
    selector: 'nxt-invoice-builder',
    templateUrl: './invoice-builder.component.html',
    styleUrls: ['./invoice-builder.component.scss'],
    standalone: true
})
export class InvoiceBuilderComponent implements OnInit {

  form: UntypedFormGroup;


  data: NxtInvoiceData = null; /*{
    invoiceDate: new Date(2020, 7, 1),
    invoiceDateString: '01.07.2020',
    invoiceNumber: 'GS0002',
    invoiceTo: {
      salutation: 'Herrn',
      name: 'Francisco Cortes',
      street: 'Lindenstraße 24',
      postalCode: '52080',
      city: 'Aachen',
      customerNumber: '10005',
      numberRange: 'FCO',
      countryCode: 'DE'
    },
    invoicePositions: [
      {
        posNumber: 1,
        name: 'Tattoo',
        amount: 1,
        unitPrice: 100,
        isInvoice: false
      }, {
        posNumber: 2,
        name: 'Platzmiete',
        amount: 1,
        unitPrice: -50,
        isInvoice: false
      }],
    pageCountCurrent: 3,
    pageCountTotal: 3,
    invoiceValueTotal: 500,
    invoiceValueTotalForTax: 0,
    invoiceTitle: 'Gutschrift'
  };*/

  constructor(
    private route: ActivatedRoute,
    private socketService: SocketService,
    public configService: ConfigService,
    private fb: UntypedFormBuilder
  ) {
// this.form
  }

  async ngOnInit() {
  }

  formGet(name: string) {
    return this.form.get(name) as UntypedFormControl;
  }
}
