import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, OnInit, Optional, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtPageComponent} from '../nxt-page/nxt-page.component';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {NxtEmployee} from '../../common-interfaces/nxt.employee.interface';
import {IconTools} from '../../common-browser/helpers/icon.tools';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {NxtPageHeaderComponent} from '../nxt-page/nxt-page-header/nxt-page-header.component';
import {DialogService} from '../../services/dialog.service';
import {EmployeeEditComponent} from './employee-edit/employee-edit.component';
import {NxtPageContentComponent} from '../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageHeaderTitleComponent} from '../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {SocketService} from '../../services/socket/socket.service';
import {EmployeeTools} from '../../common-browser/helpers/employee.tools';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {TelegramEmployeeStartChatComponent} from '../telegram/telegram-employee-start-chat/telegram-employee-start-chat.component';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {InputComponent} from '../form-controls/input/input.component';
import {ColorTools} from '../../common-browser/helpers/color.tools';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'nxt-employees',
    templateUrl: './employees.component.html',
    styleUrls: ['./employees.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NxtPageComponent,
        NxtPageHeaderComponent,
        NxtPageContentComponent,
        NxtButtonComponent,
        NxtDatagridComponent,
        NxtPageHeaderTitleComponent,
        SlideToggleComponent,
        InputComponent,
    ]
})

export class EmployeesComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  employees = signal<NxtEmployee[]>([]);


  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  dialogService = inject(DialogService);
  socketService = inject(SocketService);


  columnDefs: NxtColDef<NxtEmployee>[] = [
    {headerName: '', nxtFieldType: NxtFieldType.Icon, nxtIcon: IconTools.Material.Edit, nxtOnCellClicked: params => this.editClicked(params.data)},
    {headerName: '', nxtFieldType: NxtFieldType.Icon, nxtIcon: IconTools.Material.History, nxtOnCellClicked: params => this.historyClicked(params.data)},
    {
      headerName: 'Kürzel',
      field: 'abbreviation',
      nxtFieldType: NxtFieldType.Text,
      cellStyle: (params) => ({backgroundColor: params.data.color}),
    },
    {
      headerName: 'Name', field: 'fullName', nxtFieldType: NxtFieldType.Text, cellStyle: (params) => {
        if (params.data.endDateString && params.data.endDateString.dateParse() < Date.now()) {
          return {color: ColorTools.Red};
        }
      },
    },
    {headerName: 'Start', field: 'startDateString', nxtFieldType: NxtFieldType.Date_germanDate},
    {headerName: 'Std / W', valueGetter: params => EmployeeTools.getHoursPerWeek(params.data, DateTools.formatNowDate(), null), nxtFieldType: NxtFieldType.Number},
    {headerName: 'App', valueGetter: (params) => !!params.data.appUserUid, nxtFieldType: NxtFieldType.Boolean},
    {headerName: 'Stempeln', field: 'doTimeTrack', nxtFieldType: NxtFieldType.Boolean},
    {headerName: 'Arbeitsplan in App', field: 'seeWorkTime', nxtFieldType: NxtFieldType.Boolean},
    {headerName: 'Nur im Studio', field: 'timeTrackOnlyFromStudio', nxtFieldType: NxtFieldType.Boolean},
    {
      headerName: 'Frühste Zeit', field: 'earliestStartTime', nxtFieldType: NxtFieldType.Text, valueFormatter: params => {
        const items: string[] = [];
        if (params.data.earliestStartDependOnWorkplace) {
          items.push('30 min vor Arbeit');
        }
        if (params.data.earliestStartTime) {
          items.push(params.data.earliestStartTime);
        }
        return items.join(' oder ');
      },
    },
    {
      headerName: 'Freier Tag(e)', valueFormatter: params => {
        const freeDays = EmployeeTools.getFreeDays(params.data, DateTools.formatNowDate());
        return freeDays.map(d => DateTools.getDayOfWeekTextShort(d)).join(', ');
      },
    },
    {headerName: 'Auto Logout', field: 'timeTrackEndTimeIfMissing', nxtFieldType: NxtFieldType.Text},
    {
      headerName: 'Telegram',
      field: 'telegramChatId',
      nxtFieldType: NxtFieldType.Text,
      valueFormatter: params => params.value ? 'Ja' : 'Nein',
      nxtOnCellClicked: params => this.connectTelegramClicked(params.data),
    },
  ];
  showOldEmployees = signal(false);
  quickFilterText = signal('');

  employeesFiltered = computed(() => {
    return this.employees().filter(e => this.showOldEmployees() || (!e.endDateString || e.endDateString.dateParse() > Date.now()));
  });


  constructor(
    @Optional() public dialogRef: MatDialogRef<EmployeesComponent>,
  ) {
    super();
  }

  ngOnInit() {
    this.pushSocketSubscription = this.socketService.subscribeNew('eventEmployeeChanged', (data) => {
      this.load().then();
    });
    this.load().then();
  }

  nxtOnDestroy() {
  }

  async load() {
    this.employees.set((await this.socketService.getEmployees()).sortString('givenName'));
  }

  newEmployeeClicked() {
    const dialog = this.dialogService.showComponentDialog(EmployeeEditComponent);
    dialog.componentInstance.employees = this.employees();
    dialog.componentInstance.load('new').then();
  }

  private editClicked(data: NxtEmployee) {
    const dialog = this.dialogService.showComponentDialog(EmployeeEditComponent);
    dialog.componentInstance.employees = this.employees();
    dialog.componentInstance.load(data.id).then();
  }

  private historyClicked(data: NxtEmployee) {
    this.dialogService.showHistory('employee', data.id);
  }

  connectTelegramClicked(employee: NxtEmployee) {
    const dialog = this.dialogService.showComponentDialog(TelegramEmployeeStartChatComponent);
    dialog.componentInstance.setEmployee(employee.id);
  }
}
