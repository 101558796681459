import {Component, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {SocketService} from '../../services/socket/socket.service';
import {DialogService} from '../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {ConfigService} from '../../services/config.service';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {UserEditComponent} from './artist-edit/user-edit.component';
import {ActivatedRoute} from '@angular/router';
import {NxtPermissionId, NxtUser, NxtUserPermission} from '../../common-interfaces/nxt.user.interface';
import {IconTools} from '../../common-browser/helpers/icon.tools';
import {SortTools} from '../../common-browser/helpers/sort.tools';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NgIf} from '@angular/common';
import {InputComponent} from '../../components/form-controls/input/input.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'nxt-artists',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  imports: [FlexModule, NxtButtonComponent, InputComponent, NgIf, NxtButtonIconComponent, NxtDatagridComponent],
})
export class UsersComponent implements OnInit, OnDestroy {

  users: NxtUser[];

  @ViewChild(NxtDatagridComponent) dataGrid: NxtDatagridComponent;
  columnDefs: NxtColDef<NxtUser>[] = [


    {
      headerName: 'Bearbeiten',
      maxWidth: 68,
      minWidth: 68,
      nxtFieldType: NxtFieldType.Text,
      cellRenderer: () => IconTools.Material.Edit,
      cellStyle: {textAlign: 'center'},
      nxtOnCellClicked: async (params) => {
        const dialog = this.dialogService.showComponentDialog(UserEditComponent, null, {minHeight: '95vh', minWidth: '95vw'});
        dialog.componentInstance.loadUser(params.data.id);
        await firstValueFrom(dialog.afterClosed());
        this.load();
      },
    },
    {headerName: 'Name', field: 'username', nxtFieldType: NxtFieldType.Text},
    {
      headerName: 'Klonen',
      maxWidth: 68,
      minWidth: 68,
      nxtFieldType: NxtFieldType.Text,
      cellRenderer: () => IconTools.Material.ContentCopy,
      cellStyle: {textAlign: 'center'},
      nxtOnCellClicked: async (params) => {
        const dialog = this.dialogService.showComponentDialog(UserEditComponent, null, {minHeight: '95vh', minWidth: '95vw'});
        dialog.componentInstance.newUserFrom(params.data);
        await dialog.afterClosed().toPromise();
        this.load();
      },
    },

    {headerName: 'Vor- & Nachname', field: 'realName', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Handy privat', field: 'mobilePrivate', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Geburtstag', field: 'birthday', nxtFieldType: NxtFieldType.Date_germanDate},
    // {headerName: 'Arbeitsplätze', field: 'workplaces', cellRenderer: (params: any) => params.value?.map(w => StudioTools.getWorkplaceText(w))?.join(', ')},
    {
      headerName: 'SuperAdmin', nxtFieldType: NxtFieldType.Boolean, valueGetter: params => {
        return params.data.permissions.some(p => p.granted && p.id === NxtPermissionId.SuperAdmin);
      },
    },
    {
      headerName: 'Admin', nxtFieldType: NxtFieldType.Boolean, valueGetter: params => {
        return params.data.permissions.some(p => p.granted && p.id === NxtPermissionId.Admin);
      },
    },
    {headerName: 'startPage', field: 'startPage', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Erstellt Kassenberichte', field: 'cashReportCreator', nxtFieldType: NxtFieldType.Boolean},
    {headerName: 'kein tägliches Login nötig', field: 'disableDailyLogin', nxtFieldType: NxtFieldType.Boolean},
    {
      headerName: 'Kalender', nxtFieldType: NxtFieldType.Boolean, valueGetter: params => {
        return params.data.permissions.find(p => p.id === NxtPermissionId.Calendar)?.granted;
      },
    },
    {
      headerName: 'CashReport', nxtFieldType: NxtFieldType.Boolean, valueGetter: params => {
        return params.data.permissions.find(p => p.id === NxtPermissionId.StudioCashReport)?.granted;
      },
    },
  ];
  quickFilterText = '';

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private configService: ConfigService,
    private activatedRoute: ActivatedRoute,
    @Optional() public dialogRef: MatDialogRef<UsersComponent>,
  ) {
    this.load();

    if (this.activatedRoute.snapshot.paramMap.get('userId')) {

    }

    /*for (const key of Object.keys(NxtPermissionId)) {
      this.columnDefs.push({
        headerName: getPermissionIdText(NxtPermissionId[key]),
        nxtFieldType: NxtFieldType.Boolean,
        valueGetter: (params) => {
          return !!params.data.permissions.find(p => p.id === key)?.granted;
        }
      });
    }*/
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  async rowDoubleClicked(ev: any) {
    const dialog = this.dialogService.showComponentDialog(UserEditComponent, null, {minHeight: '95vh', minWidth: '95vw'});
    dialog.componentInstance.loadUser(ev.data.id);
    await dialog.afterClosed().toPromise();
    this.load();
  }

  private async load() {
    this.users = (await this.socketService.getUsers()).sort(SortTools.sortString('username'));
  }

  async addUser() {
    const dialog = this.dialogService.showComponentDialog(UserEditComponent, null, {minHeight: '95vh', minWidth: '95vw'});
    dialog.componentInstance.newUser();
    await dialog.afterClosed().toPromise();
    this.load();
  }
}
