import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nxtFind',
  standalone: true
})
export class NxtFindPipe implements PipeTransform {

  constructor() {
  }

  transform<T>(obj: T[], key: string, value: any): any {
    return obj.find(i => i[key] === value);
  }
}
