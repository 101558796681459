import {NxtDriveFileSubType, NxtDriveFileType} from '../../common-interfaces/drive-file.interface';

export class DriveTools {

  static driveGetToken = '';

  static getDriveFileTypeByGoogleDriveFilename(filename: string): NxtDriveFileType {
    if (filename.endsWith('.mov') || filename.endsWith('.mp4') || filename.endsWith('.avi')) {
      return 'video';
    }
    if (filename.endsWith('.pdf')) {
      return 'pdf';
    }
    return 'image';
  }

  /*static getDriveLinkByEventFile(eventFile: NxtCalendarEventFile) {
    const fileExtension = MimeTools.getExtension(eventFile.mimetype);
    return 'https://drive.nxt-lvl.ink/get/' + eventFile.id + '.' + fileExtension + '?t=' + DriveTools.driveGetToken;
  }*/


  static getDriveLink(fileId: string, fileExtension?: string) {
    if (fileExtension) {
      return 'https://drive.nxt-lvl.ink/get/' + fileId + '.' + fileExtension + '?t=' + DriveTools.driveGetToken;
    }
    return 'https://drive.nxt-lvl.ink/get/' + fileId + '?t=' + DriveTools.driveGetToken;
  }

  static getDriveThumbLink(fileId: string) {
    return 'https://drive.nxt-lvl.ink/get-thumb/' + fileId + '?t=' + DriveTools.driveGetToken;
  }

  static getDriveFileSubTypeByGoogleDriveFilename(filename: string): NxtDriveFileSubType {
    if (filename.endsWith('.mov') || filename.endsWith('webm') || filename.endsWith('mp4')) {
      if (filename.startsWith('preTattoo')) {
        return 'preTattooVideo';
      }
      if (filename.startsWith('tattoo') || filename.startsWith('202')) {
        return 'tattooVideo';
      }
      if (filename.startsWith('stencil')) {
        return 'stencilVideo';
      }
    } else {
      if (filename.startsWith('tattoo') || filename.startsWith('202')) {
        return 'tattooPhoto';
      }
      if (filename.startsWith('preTattoo')) {
        return 'preTattooPhoto';
      }
      if (filename.startsWith('stencil')) {
        return 'stencilPhoto';
      }
      if (filename.startsWith('template')) {
        return 'templatePhoto';
      }
    }
    if (filename.endsWith('.pdf')) {
      return 'consent';
    }
    throw Error('DriveSubType kann nicht anhand von ' + filename + ' ermittelt werden');
  }


}
