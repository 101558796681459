import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DurationTools} from '../common-browser/helpers/duration.tools';

@Pipe({
  name: 'fromNowText2',
  standalone: true,
})
export class FromNowText2Pipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any): string {
    return DurationTools.format2(value - Date.now());
  }
}
