import {Component, Input, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {AppointmentConfirmationService} from '../../../services/appointment-confirmation.service';
import {AdminComponent} from '../../admin/admin.component';
import {DiscountPromotionsComponent} from '../../discount-promotions/discount-promotions.component';
import {DialogService} from '../../../services/dialog.service';
import {UsersComponent} from '../../../pages/users/users.component';
import {NotAssignedPaymentsComponent} from '../../not-assigend-payments/not-assigned-payments.component';
import {HeaderDoubleBlockComponent} from '../../../pages/design-helper/header-double-block/header-double-block.component';
import {ComponentType} from '@angular/cdk/overlay';
import {GiftCardFormComponent} from '../../giftcard-form/gift-card-form.component';
import {InvoicesComponent} from '../../../pages/invoices/invoices.component';
import {WebFormSubmitsComponent} from '../../web-form-submits/web-form-submits.component';
import {TextTemplatesComponent} from '../../text-templates/text-templates.component';
import {SmsCodeComponent} from '../../sms-code/sms-code.component';
import {WhatsappFastAnswersComponent} from '../../whatsapp/whatsapp-fast-answers/whatsapp-fast-answers.component';
import {CareInstructionsService} from '../../../services/care-instructions.service';
import {AppointmentReminderService} from '../../../services/appointment-reminder.service';
import {ArtistsComponent} from '../../../pages/artists/artists.component';
import {ArtistSpotEditComponent} from '../../artist-spot-edit/artist-spot-edit.component';
import {StudioFeeComponent} from '../../studio-fee/studio-fee.component';
import {DiscountAnalysisComponent} from '../../../pages/analysis/discount-analysis/discount-analysis.component';
import {RemindersComponent} from '../../reminders/reminders.component';
import {BirthdayService} from '../../../services/birthday.service';
import {WhatsAppChatsComponent} from '../../whatsapp/whatsapp-chats/whats-app-chats.component';
import {PromoUsersComponent} from '../../promo/promo-users/promo-users.component';
import {DayFinishLogComponent} from '../../day-finish-log/day-finish-log.component';
import {ContactsSameMobileComponent} from '../../contacts-same-mobile/contacts-same-mobile.component';
import {HandoverService} from '../../../services/handover.service';
import {StudioCashReport2Service} from '../studio-cash-report-2.service';
import {InventoryListComponent} from '../../inventory/inventory-list/inventory-list.component';
import {LoginService} from '../../../services/login.service';
import {NgIf} from '@angular/common';
import {MatIcon} from '@angular/material/icon';
import {PermissionDirective} from '../../../directives/permission.directive';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {DiscountGiftCardsComponent} from '../../discount-gift-cards/discount-gift-cards.component';
import {DocumentService} from '../../../services/document.service';
import {EmployeesComponent} from '../../employee/employees.component';
import {EmployeeCalendarComponent} from '../../employee/employee-calendar/employee-calendar.component';
import {ArtistKeySafeCodeComponent} from '../../artist-key-safe-code/artist-key-safe-code.component';
import {EmployeeWorksComponent} from '../../employee/employee-works/employee-works.component';
import {LanguageTools} from '../../../common-browser/helpers/language.tools';
import {MoneyAccountsComponent} from '../../money-accounts/money-accounts.component';
import {BackofficeComponent} from '../../backoffice/backoffice.component';
import {ArtistRatingsComponent} from '../../artist-ratings/artist-ratings.component';
import {EventsComponent} from '../../events/events.component';
import {ChatsToRecheckComponent} from '../../chats-to-recheck/chats-to-recheck.component';
import {DiscountPromotions2Component} from '../../discount-promotions-2/discount-promotions-2.component';

export enum StudioCashReport2MenuItem {
  Admin,
  DiscountPromotions,
  Users,
  NotAssignedPayments,
  GraphicHeader,
  NewGiftCard,
  Inventory,
  Invoices,
  WebFormSubmits,
  TextTemplates,
  PayPalSms,
  WhatsappFastAnswers,
  Contacts,
  NewContact,
  Artists,
  NewArtistSpot,
  StudioFee,
  Analysis,
  CashRegisterDoCount,
  Handover,
  Reminders,
  EditBirthdayMessage,
  WhatsappChats,
  PromoPromoUsers,
  DayFinishLog,
  CombineContacts,
  AnalysisEvents,
  SetNewPassword,
  DiscountGiftCards,
  DeclarationOfConsentTattoo,
  DeclarationOfConsentPiercing,
  DeclarationOfConsentToothGem,
  AfterCareTattoo,
  AfterCarePiercing,
  AfterCareToothGem,
  Employees,
  EmployeeCalendar,
  ArtistsEditKeySafeCode,
  EmployeeWorks,
  MoneyAccounts,
  AnalysisEventsCreatedOrUpdated,
  ArtistsRatings,
  WhatsAppChatRecheck,
  GoogleSms
}

@Component({
  selector: 'nxt-studio-cash-report-2-menu',
  templateUrl: './studio-cash-report-2-menu.component.html',
  styleUrls: ['./studio-cash-report-2-menu.component.scss'],
  imports: [NxtButtonIconComponent, MatMenuTrigger, MatMenu, PermissionDirective, MatMenuItem, MatIcon, NgIf],
  standalone: true,
})

export class StudioCashReport2MenuComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  MenuItem = StudioCashReport2MenuItem;

  @Input() studioCashReport2Service: StudioCashReport2Service;

  constructor(
    public appointmentConfirmationService: AppointmentConfirmationService,
    public careInstructionsService: CareInstructionsService,
    public appointmentReminderService: AppointmentReminderService,
    private dialogService: DialogService,
    private birthdayService: BirthdayService,
    private handoverService: HandoverService,
    private loginService: LoginService,
    private documentService: DocumentService,
  ) {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {

  }

  async menuItemClicked(menuItem: StudioCashReport2MenuItem) {
    const componentsFull: { menuItem: StudioCashReport2MenuItem, componentType: ComponentType<any>, full: boolean }[] = [
      {menuItem: StudioCashReport2MenuItem.Admin, componentType: AdminComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.DiscountPromotions, componentType: DiscountPromotions2Component, full: true},
      {menuItem: StudioCashReport2MenuItem.Users, componentType: UsersComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.NotAssignedPayments, componentType: NotAssignedPaymentsComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.GraphicHeader, componentType: HeaderDoubleBlockComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.NewGiftCard, componentType: GiftCardFormComponent, full: false},
      {menuItem: StudioCashReport2MenuItem.Inventory, componentType: InventoryListComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.Invoices, componentType: InvoicesComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.WebFormSubmits, componentType: WebFormSubmitsComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.TextTemplates, componentType: TextTemplatesComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.WhatsappFastAnswers, componentType: WhatsappFastAnswersComponent, full: true},
      // {menuItem: StudioCashReport2MenuItem.Contacts, componentType: ContactsComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.Artists, componentType: ArtistsComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.StudioFee, componentType: StudioFeeComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.Reminders, componentType: RemindersComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.WhatsappChats, componentType: WhatsAppChatsComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.PromoPromoUsers, componentType: PromoUsersComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.DayFinishLog, componentType: DayFinishLogComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.CombineContacts, componentType: ContactsSameMobileComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.AnalysisEvents, componentType: EventsComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.AnalysisEventsCreatedOrUpdated, componentType: BackofficeComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.DiscountGiftCards, componentType: DiscountGiftCardsComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.Employees, componentType: EmployeesComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.EmployeeCalendar, componentType: EmployeeCalendarComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.ArtistsEditKeySafeCode, componentType: ArtistKeySafeCodeComponent, full: false},
      {menuItem: StudioCashReport2MenuItem.ArtistsRatings, componentType: ArtistRatingsComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.EmployeeWorks, componentType: EmployeeWorksComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.MoneyAccounts, componentType: MoneyAccountsComponent, full: true},
      {menuItem: StudioCashReport2MenuItem.WhatsAppChatRecheck, componentType: ChatsToRecheckComponent, full: true},
    ];

    const componentFull = componentsFull.find(c => c.menuItem === menuItem);
    if (componentFull) {
      if (componentFull.full) {
        this.dialogService.showComponentFull(componentFull.componentType);
      } else {
        this.dialogService.showComponentDialog(componentFull.componentType);
      }
      return;
    }


    switch (menuItem) {
      case StudioCashReport2MenuItem.PayPalSms:
        this.dialogService.showComponentDialog(SmsCodeComponent, {type: 'PayPal'});
        break;
      case StudioCashReport2MenuItem.GoogleSms:
        this.dialogService.showComponentDialog(SmsCodeComponent, {type: 'Google'});
        break;
      case StudioCashReport2MenuItem.NewContact:
        this.dialogService.showContactForm();
        break;
      case StudioCashReport2MenuItem.NewArtistSpot:
        const dialog = this.dialogService.showComponentDialog(ArtistSpotEditComponent);
        dialog.componentInstance.load('new');
        break;
      case StudioCashReport2MenuItem.Analysis:
        this.dialogService.showComponentDialog(DiscountAnalysisComponent, {}, {
          minWidth: '50vw',
          minHeight: '80vh',
        });
        break;
      case StudioCashReport2MenuItem.EditBirthdayMessage:
        this.birthdayService.showEditBirthdayMessage();
        break;
      case StudioCashReport2MenuItem.Handover:
        this.handoverService.startHandover(this.studioCashReport2Service);
        break;
      case StudioCashReport2MenuItem.SetNewPassword:
        this.loginService.newPassword();
        break;
      case StudioCashReport2MenuItem.DeclarationOfConsentPiercing:
        const tattooLang = await this.dialogService.showSelect('In welcher Sprache?', LanguageTools.languageCodes.map(l => ({text: l.name, value: l.code})), {
          focusInputOnInit: true,
          closeOnOptionSelected: false,
          value: 'de',
          okButtonText: 'Erstellen',
        });

        if (tattooLang) {
          const tattooWithParents = await this.dialogService.showYesNo('Mit Erziehungsberechtigt?');
          const artistEmployed = await this.dialogService.showYesNo('Artist festangestellt?', {yesText: 'Ja', noText: 'Nein, extern'});
          this.documentService.downloadDeclarationOfConsentPiercing(-1, tattooLang, tattooWithParents, artistEmployed).then();
        }
        break;
      case StudioCashReport2MenuItem.DeclarationOfConsentToothGem:
        const toothGemLang = await this.dialogService.showSelect('In welcher Sprache?', LanguageTools.languageCodes.map(l => ({text: l.name, value: l.code})), {
          focusInputOnInit: true,
          closeOnOptionSelected: false,
          value: 'de',
          okButtonText: 'Erstellen',
        });
        if (toothGemLang) {
          const toothGemWithParents = await this.dialogService.showYesNo('Mit Erziehungsberechtigt?');
          const artistEmployed = await this.dialogService.showYesNo('Artist festangestellt?', {yesText: 'Ja', noText: 'Nein, extern'});
          this.documentService.downloadDeclarationOfConsentToothGem(-1, toothGemLang, toothGemWithParents, artistEmployed).then();
        }
        break;
      case StudioCashReport2MenuItem.DeclarationOfConsentTattoo:
        const piercingLang = await this.dialogService.showSelect('In welcher Sprache?', LanguageTools.languageCodes.map(l => ({text: l.name, value: l.code})), {
          focusInputOnInit: true,
          closeOnOptionSelected: false,
          value: 'de',
          okButtonText: 'Erstellen',
        });
        const piercingWithParents = await this.dialogService.showYesNo('Mit Erziehungsberechtigt?');
        if (piercingLang) {
          this.documentService.downloadDeclarationOfConsentTattoo(-1, piercingLang, piercingWithParents).then();
        }
        break;
      case StudioCashReport2MenuItem.AfterCarePiercing:
        this.documentService.downloadAfterCareInstructionPiercing().then();
        break;
      case StudioCashReport2MenuItem.AfterCareTattoo:
        this.documentService.downloadAfterCareInstructionTattoo().then();
        break;
    }
  }
}
