import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NxtFormControl} from '../../nxt-form/nxt.form-control';
import {ValidatorTools} from '../../helpers/validator.tools';
import {NxtTypedFormControl} from '../../nxt-form/nxt.typed-form-control';
import {NxtComponent} from '../nxt.component';
import {ControlEvent} from '@angular/forms';

export interface FormControlWrapper {
  onFormControlSet?(): void;
}

@Component({
    template: '',
    standalone: false
})
export abstract class FormControlWrapper extends NxtComponent {

  @Input() set nxtFormControl(value: NxtFormControl | NxtTypedFormControl | any) {
    if (value) {
      let oldElement;
      if (this._nxtFormControl?.element) {
        oldElement = this._nxtFormControl?.element;
      }
      this._nxtFormControl = value;
      this._nxtFormControl.formControlWrapper = this;
      if (oldElement) {
        this._nxtFormControl.element = oldElement;
      }


      if (!(this.nxtFormControl as any)._markAsTouched) {
        (this.nxtFormControl as any)._markAsTouched = this.nxtFormControl.markAsTouched;
        this.nxtFormControl.markAsTouched = () => {
          this.createErrorList();
          (this.nxtFormControl as any)._markAsTouched();
          // your event handler
        };
      }


      this.nxtFormControl.valueChanges.subscribe(() => {
        this.calcShowInvalidError();
        this.createErrorList();
      });
      this._nxtFormControl.events.subscribe(event => {
        this.calcShowInvalidError();
        this.createErrorList();
        if (this.onEvent) {
          this.onEvent(event);
        }
      });
    }

    if (this.onFormControlSet) {
      this.onFormControlSet();
    }
  }

  public get nxtFormControl(): NxtFormControl | NxtTypedFormControl<any> {
    return this._nxtFormControl;
  }


  constructor() {
    super();
    this.nxtFormControl = new NxtFormControl();
  }

  @Output() enter = new EventEmitter<void>();

  _nxtFormControl: NxtFormControl | NxtTypedFormControl;


  @ViewChild('clickElement') clickControlElemRef: ElementRef;
  @ViewChild('inputElement') inputControlElemRef: ElementRef;


  @ViewChild('controlElement', {static: true}) controlElemRef: ElementRef;

  @Input() width = '100%';
  @Input() requiredError: string;

  showInvalidError = false;
  errorText: string;

  onEvent?(event: ControlEvent): void;

  formControlValueChanged?(value: any): void;

  public createErrorList() {
    if (this._nxtFormControl.errors) {
      const errorList = Object.keys(this._nxtFormControl.errors).map(key => {
        if (typeof this._nxtFormControl.errors[key] === 'string') {
          return this._nxtFormControl.errors[key];
        } else {
          if (ValidatorTools.errorTexts[key]) {
            return ValidatorTools.errorTexts[key](this._nxtFormControl.getError(key));
          }
        }
        return '';
      }).filter(t => !!t);
      this.errorText = errorList.join(', ');

    } else {
      this.errorText = '';
    }
  }

  setInputValue(text: string) {
    if (this.inputControlElemRef?.nativeElement) {
      this.inputControlElemRef.nativeElement.value = text;
    }
  }

  setFocus() {
    this.controlElemRef?.nativeElement?.focus();
  }

  public select() {
    this.doSelect(0);
  }

  private doSelect(retry = 0) {
    if (this.clickControlElemRef?.nativeElement) {
      const timestamp = Date.now();
      (this.clickControlElemRef.nativeElement as HTMLInputElement).addEventListener('focusout', () => {
        const duration = Date.now() - timestamp;
        if (duration < 300) {
          this.doSelect(0);
        }
      });
      this.setFocus();
      this.clickControlElemRef.nativeElement.setSelectionRange(0, this.clickControlElemRef.nativeElement.value?.length);
    } else {
      if (retry < 100) {
        requestAnimationFrame(() => {
          this.doSelect(retry + 1);
        });
      }
    }
  }

  public click() {
    this.clickControlElemRef?.nativeElement?.click();
  }

  private calcShowInvalidError() {
    this.showInvalidError = this.nxtFormControl.invalid && (this.nxtFormControl.dirty || this.nxtFormControl.touched);
  }
}
