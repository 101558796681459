import {NxtArtist, NxtArtistSkills, NxtBoolSkill, NxtSkill} from '../../common-interfaces/nxt.artist.interface';
import {MathTools} from './math.tools';


export class ArtistSkillTools {


  static SkillMustMoreEquals = 6;

  public static get boolSkills() {
    return ArtistSkillTools._boolSkills.sortString('description');
  }

  private static _boolSkills: { description: NxtBoolSkill }[] = [
    {description: 'Farbe'},
    {description: 'Farbe Fineline'},
    {description: 'Lippentattoos'},
    {description: 'Gesichtstattoos'},
    {description: 'Portrait 1zu1'},
    {description: 'Tier-Portrait 1zu1'},
  ];

  public static get skills() {
    return ArtistSkillTools._skills.sortString('description');
  }

  private static _skills: { description: NxtSkill }[] = [
    {description: 'kleine Tattoos'},
    {description: 'Realistic'},
    {description: 'Maori'},
    {description: 'Mandala'},
    {description: 'Coverup'},
    {description: 'Dotwork'},
    {description: 'Portrait'},
    {description: 'Fineline'},
    {description: 'Farbrealistic'},
    {description: 'Blackwork'},
    {description: 'Old / New School'},
    {description: 'Trash Polka'},
    {description: 'Sketch'},
    {description: 'Watercolor'},
    {description: 'Lettering'},
    {description: 'Black & Grey'},
    {description: 'Blumen'},
    {description: 'Tiere'},
    {description: 'Outlines'},
    {description: 'Comic Farbe'},
    {description: 'Comic Black & Grey'},
    {description: 'Mikrorealismus'},
    {description: 'Chicano'},
    {description: 'Japanese'},
    {description: 'Geometric'},

    /***
     so auf 0 setzen;
     if (artist.skills.skillValues.find(f => f.description === 'Geometric')?.value === -1) {
     artist.skills.skillValues.find(f => f.description === 'Geometric')!.value = 0;
     updateArtist = true;
     }
     */
  ];

  public static getArtistSkillResultFromEvent(skill: { skills: { [key: string]: boolean }, boolSkills: { [key: string]: boolean } }, artist: NxtArtist):
    { value: number, stars: number, canUse: boolean, attention: boolean, infoLines: string[] } {
    const result: { value: number, stars: number, canUse: boolean, attention: boolean, infoLines: string[] } = {
      attention: false,
      canUse: false,
      infoLines: [],
      value: -1,
      stars: 0,
    };
    if (!artist) {
      throw Error('calcArtistSkillSumFromEvent fehler\nartist nicht gesetzt');
    }
    try {
      if (!skill || (Object.keys(skill.skills).length === 0 && Object.keys(skill.boolSkills).length === 0)) {
        return result;
      }

      result.canUse = true;

      for (const boolSkill of Object.keys(skill.boolSkills)) {
        if (skill.boolSkills[boolSkill]) {
          const artistBoolSkill = artist.skills.boolSkillValues.find(s => s.description === boolSkill);
          if (!artistBoolSkill) {
            result.infoLines.push(boolSkill + ' Skill nicht hinterlegt');
            result.canUse = false;
          } else if (!artistBoolSkill.value) {
            result.infoLines.push('kann kein ' + boolSkill);
            result.canUse = false;
          }
        }
      }

      for (const s of Object.keys(skill.skills)) {
        if (skill.skills[s]) {
          const artistSkill = artist.skills.skillValues.find(sv => sv.description === s);
          if (!artistSkill) {
            result.infoLines.push(artistSkill + ' Skill nicht hinterlegt');
            result.canUse = false;
          } else if (artistSkill.value < ArtistSkillTools.SkillMustMoreEquals) {
            result.infoLines.push('zu schlecht in ' + s + ' (' + artistSkill.value + '/10)');
            result.canUse = false;
          }
        }
      }
      const skills = Object.keys(skill.skills).filter(key => skill.skills[key]);
      const boolSkills = Object.keys(skill.boolSkills).filter(key => skill.boolSkills[key]);
      result.value = ArtistSkillTools.calcArtistSkillSum(skills, boolSkills, artist).skill;
      result.stars = MathTools.round(result.value / 2, 1);
      if (result.value < ArtistSkillTools.SkillMustMoreEquals) {
        result.attention = true;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
    return result;
  }

  public static calcArtistSkillSum(skillFilter: string[], boolFilter: string[], artist: NxtArtist): { skill: number; speed: number, skillValues: number[] } {
    let sumSkill = 0;
    const skillValues: number[] = [];
    if (skillFilter.length === 0 && boolFilter.length > 0) {
      sumSkill = 10;
    }
    // let sumLike = 0;
    for (const skillF of skillFilter) {
      const artistSkill = artist.skills.skillValues.find(s => s.description === skillF);
      if (artistSkill) {
        sumSkill += artistSkill.value;
        skillValues.push(artistSkill.value);
        // sumLike += artistSkill.like;
      }
    }

    // const skillLengthToDivide = skillFilter.filter(s => !['color'].includes(s)).length;

    if (skillFilter.length > 0) {
      sumSkill = sumSkill / skillFilter.length;
      // sumLike = sumLike / skillFilter.length;
    }

    boolFilter.forEach(bf => {
      if (!artist.skills.boolSkillValues?.find(b => b.description === bf)?.value) {
        sumSkill = 0;
        // sumLike = 0;
      }
    });
    return {skill: sumSkill, speed: artist.skills.speed, skillValues};
  }

  static getDiff(oldObj: NxtArtistSkills, newObj: NxtArtistSkills) {
    const diffLines: { skill: string, oldValue: any, newValue: any }[] = [];
    if (oldObj.speed !== newObj.speed) {
      diffLines.push({skill: 'speed', oldValue: oldObj.speed, newValue: newObj.speed});
    }
    for (const skill of oldObj.skillValues) {
      const newSkill = newObj.skillValues.find(s => s.description === skill.description);
      if (newSkill?.value !== skill.value) {
        diffLines.push({skill: skill.description, oldValue: skill.value, newValue: newSkill?.value});
      }
    }
    for (const skill of oldObj.boolSkillValues) {
      const newSkill = newObj.boolSkillValues.find(s => s.description === skill.description);
      if (newSkill?.value !== skill.value) {
        diffLines.push({skill: skill.description, oldValue: skill.value, newValue: newSkill?.value});
      }
    }

    for (const skill of newObj.skillValues) {
      const oldSkill = oldObj.skillValues.find(s => s.description === skill.description);
      if (!oldSkill) {
        diffLines.push({skill: skill.description, oldValue: 0, newValue: skill.value});
      }
    }

    for (const skill of newObj.boolSkillValues) {
      const oldSkill = oldObj.boolSkillValues.find(s => s.description === skill.description);
      if (!oldSkill) {
        diffLines.push({skill: skill.description, oldValue: 0, newValue: skill.value});
      }
    }
    return diffLines;
  }

  static toEn(skill: string) {
    switch (skill) {
      case 'Farbe':
        return 'color';
      case 'Farbe Fineline':
        return 'color fineline';
      case 'Lippentattoos':
        return 'lip tattoos';
      case 'Gesichtstattoos':
        return 'face tattoos';
      case 'Portrait 1zu1':
        return 'portrait 1to1';
      case 'Tier-Portrait 1zu1':
        return 'animal portrait 1to1';
      case 'kleine Tattoos':
        return 'small tattoos';
      case 'Realistic':
        return 'realistic';
      case 'Maori':
        return 'maori';
      case 'Mandala':
        return 'mandala';
      case 'Coverup':
        return 'coverup';
      case 'Dotwork':
        return 'dotwork';
      case 'Portrait':
        return 'portrait';
      case 'Fineline':
        return 'fineline';
      case 'Farbrealistic':
        return 'color realistic';
      case 'Blackwork':
        return 'blackwork';
      case 'Old / New School':
        return 'old / new school';
      case 'Trash Polka':
        return 'trash polka';
      case 'Sketch':
        return 'sketch';
      case 'Watercolor':
        return 'watercolor';
      case 'Lettering':
        return 'lettering';
      case 'Black & Grey':
        return 'black & grey';
      case 'Blumen':
        return 'flowers';
      case 'Tiere':
        return 'animals';
      case 'Outlines':
        return 'outlines';
      case 'Comic Farbe':
        return 'comic color';
      case 'Comic Black & Grey':
        return 'comic black & grey';
      case 'Mikrorealismus':
        return 'micro realism';
      case 'Chicano':
        return 'chicano';
      case 'Japanese':
        return 'japanese';
      case 'Geometric':
        return 'geometric';
    }
    throw Error('Fehlende Skill-Übersetzung für ' + skill);
  }
}
