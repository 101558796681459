import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DecimalTools} from '../common-browser/helpers/decimal.tools';

@Pipe({
    name: 'money',
    standalone: true
})
export class MoneyPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, withDecimal?: boolean, dashIfZeroOrNull?: boolean, plusPrefix?: boolean): string {
    if (dashIfZeroOrNull && (!value || (value < 0.01 && value > -0.01))) {
      return '-';
    }
    if (typeof withDecimal !== 'boolean') {
      withDecimal = true;
    }
    if (plusPrefix && value > 0) {
      return '+ ' + DecimalTools.toMoneyString(value, '€', withDecimal);
    }
    return DecimalTools.toMoneyString(value, '€', withDecimal);
  }
}
