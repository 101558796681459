import {getDiff, rdiffResult} from 'recursive-diff';
import equal from 'fast-deep-equal';
import _ from 'lodash';

class ObjectTools {

  constructor() {
    ObjectTools.cloneTest();
  }

  public static combineWithDefaultOptions<T, K>(options: T, defaultOptions: K): T & K {
    return Object.assign({}, defaultOptions, options);
  }

  static set(object: any, path: any, value: any) {
    _.set(object, path, value);
  }

  static get(object: any, path: any) {
    return _.get(object, path);
  }

  static delete(object: any, path: any) {
    return _.unset(object, path);
  }

  static keysToCamelCase(obj: any) {
    for (const key of keys(obj)) {
      const camelCaseKey = _.camelCase(key);
      if (camelCaseKey !== key) {
        obj[camelCaseKey] = obj[key];
        delete obj[key];
      }
    }
  }


  static clone<T>(objToClone: T): T {
    return _.cloneDeep(objToClone);
  }

  public static clone_old<T>(objToClone: T): T {
    const cloneObj: any = {};
    for (const attr in objToClone) {
      if (typeof objToClone[attr] === 'object') {
        cloneObj[attr] = this.clone(objToClone[attr]);
      } else {
        cloneObj[attr] = objToClone[attr];
      }
    }
    return cloneObj;
  }

  public static getDiffOld(obj1: any, obj2: any, keepOldValInDiff = false): any {
    return ObjectTools.parseDiffToOld(getDiff(obj1, obj2, keepOldValInDiff));
  }

  /*public static getDiff(obj1: any, obj2: any, keepOldValInDiff = false): rdiffResult[] {
    return getDiff(obj1, obj2, keepOldValInDiff);
  }*/

  public static getDiff(obj1: any, obj2: any, keepOldValInDiff = false, ignoreFields?: string[]): rdiffResult[] {
    let diff = getDiff(obj1, obj2, keepOldValInDiff);
    if (ignoreFields) {
      diff = diff.filter((d: any) => !ignoreFields?.includes(d.path.join('.')));
    }
    return diff;
  }

  /**
   * @deprecated use equal instead
   */
  public static compare(a: any, b: any): boolean {
    if ((equal as any).default) {
      return (equal as any).default(a, b);
    }
    return (equal as any)(a, b);
  }

  public static equal(a: any, b: any): boolean {
    if ((equal as any).default) {
      return (equal as any).default(a, b);
    }
    return (equal as any)(a, b);
  }


  public static sortKeys(obj: any): any {
    if (typeof obj !== 'object' || !obj) {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj.map(this.sortKeys.bind(this));
    }
    return Object.keys(obj).sort().reduce((o, k) => ({...o, [k]: this.sortKeys(obj[k])}), {});
  }

  static removeUndefined(obj: any) {
    Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
  }

  public static waitFor<T>(getFn: () => T, info: string, timeout: number = 5000): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const startTime = Date.now();
      const checkResult = () => {
        const result = getFn();
        if (result) {
          resolve(result);
        } else if (Date.now() - startTime >= timeout) {
          // reject(new Error('Timeout exceeded at ' + info));
        } else {
          setTimeout(checkResult, 100);
        }
      };

      checkResult();
    });
  }

  public static waitForOld<T>(getFn: () => T): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const result = getFn();
      if (result) {
        resolve(result);
      } else {
        setTimeout(() => resolve(ObjectTools.waitFor(getFn, 'waitForOld')), 100);
      }
    });
  }

  static cloneTest() {
    const obj = {
      ebene1: {
        wert1: 'wert1',
        ebene2: {
          wert2: 'wert2',
        },
      },
    };
    const objClone = ObjectTools.clone(obj);
    objClone.ebene1.wert1 = 'neuer Wert 1';
    objClone.ebene1.ebene2.wert2 = 'neuer Wert 2';
  }

  static parseDiffToOld(newDiff: rdiffResult[]): any {
    const oldResultFake: { [path: string]: any } = {};
    for (const d of newDiff) {
      const path = '/' + d.path.join('/');

      oldResultFake['/' + d.path.join('/')] = {
        operation: d.op,
        value: d.val,
      };
      if (d.op === 'delete' || d.op === 'update') {
        oldResultFake['/' + d.path.join('/')].oldValue = d.oldVal;
      }
    }
    return oldResultFake;
  }
}

function keys(obj: any) {
  return Object.keys(obj);
}

function clone<T>(obj: T): T {
  return ObjectTools.clone<T>(obj);
}

export {ObjectTools, keys, clone};
