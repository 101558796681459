import {inject, Injectable} from '@angular/core';
import {SocketService} from '../socket/socket.service';
import {NxtKlarnaOrder} from '../../common-interfaces/nxt.klarna-order.interface';

@Injectable({
  providedIn: 'root'
})
export class TattooTicketConfirmService {
  /*** Injections ***/
  private socketService = inject(SocketService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }


  public async confirm(data: {
    customerName: string,
    eventStartDateString: number,
    paymentMethod: 'klarna' | 'paypal';
    paymentMethodId: string;
    value: number,
    mobile: string,
    shopOrderId: string;
  }): Promise<boolean> {
    // this.socketService.tattooTicket.confirm()
    return false;
  }


  public async confirmKlarna(eventId: string, value: number, paymentMethod: string, paymentKlarnaOrder: NxtKlarnaOrder) {
    return false;
  }
}
