import {Component, ElementRef, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import domtoimage from 'dom-to-image';
import {DownloadService} from '../../../services/download.service';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgIf, NgStyle} from '@angular/common';
import {SelectComponent} from '../../../components/form-controls/select/select.component';
import {InputComponent} from '../../../components/form-controls/input/input.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

interface Block {
  text: string;
  fontSize: number;
  paddingX: number;
  paddingY: number;
  elementRef?: ElementRef;
}

@Component({
    selector: 'nxt-header-double-block',
    templateUrl: './header-double-block.component.html',
    styleUrls: ['./header-double-block.component.scss'],
    imports: [FlexModule, InputComponent, SelectComponent, NgIf, NgStyle, ExtendedModule, NxtButtonComponent]
})
export class HeaderDoubleBlockComponent implements OnInit, OnDestroy {
  resultMaxWidth = 1000;

  @ViewChild('block') blockElement: ElementRef;
  //private multiplier = 0.015;

  multiplier = 0.03;
  color2 = '#3c3b3f';

  @ViewChild('firstBlock') set firstBlock(elemRef: ElementRef) {
    this.blocks[0].elementRef = elemRef;
  }

  @ViewChild('secondBlock') set secondBlock(elemRef: ElementRef) {
    this.blocks[1].elementRef = elemRef;
  }

  // resultMaxHeight = 250;
  get resultMaxHeight() {
    return this.resultMaxWidth / 4;
  }

  blocks: Block[] = [{
    text: 'Capone',
    fontSize: 50,
    paddingX: 0,
    paddingY: 0,
  }, {
    text: 'hat nun Hayat',
    fontSize: 50,
    paddingX: (this.resultMaxWidth * this.multiplier),
    paddingY: 0,
  }];
  fontFamily = 'Montserrat';
  fontWeight = 800;

  resetPadding() {
    return new Promise<void>((resolve, reject) => {
      requestAnimationFrame(() => {
        this.blocks[0].paddingX = 0;
        this.blocks[0].paddingY = 0;
        this.blocks[1].paddingX = (this.resultMaxWidth * this.multiplier);
        this.blocks[1].paddingY = 0;
        resolve();
      });
    });
  }


  constructor(
    private downloadService: DownloadService,
    @Optional() public dialogRef: MatDialogRef<HeaderDoubleBlockComponent>,
  ) {
  }

  async ngOnInit() {

  }

  ngOnDestroy(): void {
  }

  getStyle(index) {
    const style: any = {
      textTransform: 'uppercase',
      lineHeight: 1,
      fontSize: this.blocks[index].fontSize + 'px',
      paddingTop: this.blocks[index].paddingY + 'px',
      paddingBottom: this.blocks[index].paddingY + 'px',
      paddingLeft: this.blocks[index].paddingX + 'px',
      paddingRight: this.blocks[index].paddingX + 'px',
      letterSpace: '10px'
    };
    if (index === 1) {
      style.backgroundColor = this.color2;
    }
    style.marginLeft = (this.resultMaxWidth * this.multiplier) + 'px';
    style.marginRight = (this.resultMaxWidth * this.multiplier) + 'px';
    style.marginBottom = (this.resultMaxWidth * this.multiplier) + 'px';
    style.paddingLeft = (this.resultMaxWidth * this.multiplier) + 'px';
    style.paddingRight = (this.resultMaxWidth * this.multiplier) + 'px';
    // style.marginBottom = this.getMarginBottom();


    if (!this.blocks[index].text) {
      style.display = 'none';
    } else {
      style.display = '';
    }

    if (index === 1 && !this.blocks[0].text) {
      style.marginLeft = '0px';
      style.marginRight = '0px';
      style.marginBottom = '0px';
    }

    if (index === 0 && !this.blocks[1].text) {
      style.marginLeft = '0px';
      style.marginRight = '0px';
      style.marginBottom = '0px';
    }

    if (index === 0 && this.blocks[0].text && this.blocks[1].text) {
      style.marginLeft = '0px';
      style.marginRight = '0px';
      style.marginBottom = '0px';
      style.paddingLeft = '0';
      style.paddingRight = '0';
    }
    style.fontFamily = this.fontFamily;
    style.fontWeight = this.fontWeight;

    return style;
  }

  async download(hayat) {
    if (hayat) {
      await this.autoFormat();
    }
    // this.block.nativeElement.style.transform = 'scale(2)';
    requestAnimationFrame(() => {
      domtoimage.toPng(this.blockElement.nativeElement, {quality: 1})
        .then((dataUrl) => {
          const img = new Image();
          img.src = dataUrl;
          this.downloadService.downloadBase64(this.blocks[0].text + ' - ' + this.blocks[1].text + '.png', dataUrl);
        })
        .catch((error) => {
          console.error('oops, something went wrong!', error);
        });
    });
  }

  async autoFormat() {
    await this.resetPadding();
    this.blocks[0].fontSize = 1;
    this.blocks[1].fontSize = 1;
    let maxWidth = this.resultMaxWidth;
    if (this.blocks[0].text) {
      maxWidth = await this.setFontSize(this.blocks[0], this.resultMaxWidth);
    }
    const temp = this.blocks[0].fontSize;
    // this.blocks[0].fontSize = 1;

    let secondWidth = maxWidth;
    if (this.blocks[1].text) {
      secondWidth = await this.setFontSize(this.blocks[1], maxWidth);
    }

    if (maxWidth > secondWidth) {
      if (this.blocks[0].text) {
        await this.setFontSize(this.blocks[0], secondWidth);
      } else {

      }
    }

    // this.blocks[0].fontSize = temp;
    // await Promise.all([promise1, promise2]);
    if (this.blocks[0].fontSize > this.blocks[1].fontSize) {
      // obere ist größer
      // this.blocks[0].fontSize += 3;
      // this.blocks[0].paddingX = (this.resultWidth * 0.025) + 'px';
    } else {
      // untere ist größer
      this.blocks[1].fontSize += 3;
      //  this.blocks[1].fontSize += 3;
    }
  }


  setFontSize(block: Block, stopAtWidth) {
    return new Promise<number>((resolve, reject) => {
      let action = 0;
      if (block.elementRef.nativeElement.clientWidth > stopAtWidth) {
        action = -1;
      } else if (block.elementRef.nativeElement.clientWidth < stopAtWidth) {
        action = +1;
      }
      block.fontSize += action;
      requestAnimationFrame(() => {
        const diffWidth = Math.abs(block.elementRef.nativeElement.clientWidth - stopAtWidth);
        const diffHeight = Math.abs(block.elementRef.nativeElement.clientHeight - this.resultMaxHeight);
        if (
          diffWidth > 10
          && diffHeight > 10
        ) {
          // console.log('nochmal: diffWidth: ' + diffWidth + ' diffHeight: ' + diffHeight);
          resolve(this.setFontSize(block, stopAtWidth));
        } else {
          resolve(block.elementRef.nativeElement.clientWidth);
        }
      });
    });
  }

  close() {
    this.dialogRef.close();
  }
}
