<div class="h-100 p-relative">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="end" style="right: 0px; top: 0px; margin-top: -10px; margin-right: -10px">
      <nxt-button-icon *ngIf="dialogRef" (click)="dialogRef.close(images)">close</nxt-button-icon>
    </div>
    <div class="h-100" fxLayout="row wrap" fxLayoutAlign="center center">
      <div *ngFor="let image of images">
        <div class="p-1">
          <img [src]="image.base64" style="max-width: 250px;" (click)="imageClicked(image)"/>
        </div>
      </div>
    </div>
    <div fxLayout="row" *ngIf="images && images.length === 0" class="p-4">
      Keine Bilder als Vorlage gespeichert
    </div>
  </div>
</div>
