import {DialogService} from '../../services/dialog.service';
import {SocketService} from '../../services/socket/socket.service';
import {LoginService} from '../../services/login.service';
import {Component, OnDestroy, OnInit, Optional} from '@angular/core';
import {NxtSubscriptionClass} from '../../classes/nxt-subscription-class';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {WindowService} from '../../services/window.service';
import {NxtAvailableArtistDayExtended, NxtAvailableArtistDayExtendedArtist, NxtAvailableArtistDayExtendedArtistFreeTimeWindow,} from '../../common-interfaces/nxt.available-artist-day.interface';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {EventComponent} from '../../event/event.component';
import {ConfigService} from '../../services/config.service';
import {NxtEventFinderData} from '../../common-interfaces/socket/nxt.event-finder.interface';
import {ArtistEditComponent} from '../../pages/artists/artist-edit/artist-edit.component';
import {TimeTools} from '../../common-browser/helpers/time.tools';
import moment from 'moment';
import {Clipboard} from '@angular/cdk/clipboard';
import {ObjectGroupTools} from '../../common-browser/helpers/object-group.tools';
import {clone} from '../../common-browser/helpers/object.tools';
import {DurationPipe} from '../../pipes/duration.pipe';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {RateIconsComponent} from './rate-icons/rate-icons.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {MatTooltip} from '@angular/material/tooltip';
import {InputComponent} from '../form-controls/input/input.component';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {CheckboxComponent} from '../form-controls/checkbox/checkbox.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {FormsModule} from '@angular/forms';
import {MatSlider, MatSliderThumb} from '@angular/material/slider';
import {DatePickerComponent} from '../form-controls/date-picker/date-picker.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {MatIcon} from '@angular/material/icon';
import {NgFor, NgIf, NgStyle} from '@angular/common';
import {NxtArtist} from '../../common-interfaces/nxt.artist.interface';
import {NxtDailyNote} from '../../common-interfaces/daily-note.interface';
import {CacheService} from '../../services/cache/cache.service';

export interface EventFinderDialogResult {
  event: any;
  artistName: string;
  artist?: NxtAvailableArtistDayExtendedArtist;
  artistCalendarId?: string;
  studio: string;
  skill: any;
}

export interface EventFinderFilter {
  startTime: number;
  studioEndTime: number;
  days: boolean[];
}

@Component({
    selector: 'nxt-event-finder',
    templateUrl: './event-finder.component.html',
    styleUrls: ['./event-finder.component.scss'],
    imports: [NgIf, MatIcon, FlexModule, DatePickerComponent, MatSlider, MatSliderThumb, FormsModule, NxtButtonComponent, NgFor, CheckboxComponent, NxtButtonIconComponent, InputComponent, MatTooltip, NgStyle, ExtendedModule, RateIconsComponent, SlideToggleComponent, DurationPipe]
})
export class EventFinderComponent extends NxtSubscriptionClass implements OnInit, OnDestroy {


  constructor(
    private socketService: SocketService,
    public loginService: LoginService,
    public cacheService: CacheService,
    private dialogService: DialogService,
    private windowService: WindowService,
    public configService: ConfigService,
    @Optional() public dialogRef: MatDialogRef<EventComponent>,
    private clipboard: Clipboard,
  ) {
    super();
    this.windowService.setTitle('Terminfinder');
    this.pushSubscription = this.cacheService.artists.subscribe(artists => this.artists = artists);
    let date = Date.now();
    while (DateTools.getDayOfWeek(date) !== 0) {
      date = DateTools.addDays(date, -1);
    }
    this.fromDateString = DateTools.format(date, 'yyyy-MM-dd');

    this.initAuthenticationListener();
    this.normalStudioStart = this.configService.config.value.studioWorkStart / DurationTools.DURATION_1HOUR;
    this.normalStudioEnd = this.configService.config.value.studioWorkEnd / DurationTools.DURATION_1HOUR;
    this.filters.push({startTime: this.normalStudioStart, studioEndTime: this.normalStudioEnd, days: [true, true, true, true, true, true, true]});
  }

  private artists: NxtArtist[] = [];

  normalStudioStart = 0;
  normalStudioEnd = 0;
  // artistTextColors: { [artist: string]: string } = {};
  // artistBackgroundColors: { [artist: string]: string } = {};

  public data: NxtEventFinderData;
  public dataFiltered: NxtEventFinderData;
  public fromDateString = DateTools.format(Date.now(), 'yyyy-MM-dd');
  public duration = 0.5;
  public daysCount = 7;
  showMoneyBar = this.loginService.isBackoffice() && false;
  filters: EventFinderFilter[] = [];
  // currentSkillFilter: string[] = [];
  currentSkillFilterObj: { [skill: string]: boolean } = {};
  currentBoolSkillFilterObj: { [skill: string]: boolean } = {};
  // currentBoolSkillFilter: string[] = [];
  // piercingArtists: any[] = [];
  // artistCalendars: any[] = [];

  /*getCurrentSkillsText() {
    return [...this.currentBoolSkillFilter, ...this.currentSkillFilter].join(' & ');
  }*/
  minTimeFrom = '';
  freeArtistEndTime = '';

  /*public durationLiveChanged(ev: Event) {
    this.duration = parseFloat((ev.target as any).value);
  }*/
  artistFilterText = '';


  /*startTimeLiveChanged(filter: any, change: any) {
    filter.startTime = change.value;
  }*/
  dailyNotes: { [dateString: string]: NxtDailyNote[] } = {};


  async ngOnInit() {
    this.subscribeServerData();
  }

  ngOnDestroy(): void {
  }

  private async subscribeServerData() {
    /*this.socketService.subscribe('getBackofficeViewData, (data: NxtBackofficeViewData) => {
      this.setBackofficeViewData(data);
    });*/
  }

  async loadData() {
    const skillFilter: string[] = [];
    for (const key of Object.keys(this.currentSkillFilterObj)) {
      if (this.currentSkillFilterObj[key]) {
        skillFilter.push(key);
      }
    }
    if (skillFilter.length > 0) {

    }
    const boolSkillFilter: string[] = [];
    for (const key of Object.keys(this.currentBoolSkillFilterObj)) {
      if (this.currentBoolSkillFilterObj[key]) {
        boolSkillFilter.push(key);
      }
    }

    const data = await this.socketService.eventFinder({
      duration: this.duration * 60 * 60 * 1000,
      filters: this.filters,
      fillMissingDays: true,
      fromDateString: this.fromDateString,
      skillFilter,
      boolSkillFilter,
    });
    if (data.days) {
      for (const day of data.days) {
        day.artists = day.artists.filter(a => a.freeTimeWindows.length > 0);
        (day as any).dateStringLong = this.longDate(day.date);
      }
    }
    this.dailyNotes = {};
    for (const dailyNote of data.dailyNotes) {
      if (!this.dailyNotes[dailyNote.dateString]) {
        this.dailyNotes[dailyNote.dateString] = [];
      }
      this.dailyNotes[dailyNote.dateString].push(dailyNote);
    }
    this.data = data;
    this.setFilter();
    const minStart = Math.min(...this.filters.map(f => f.startTime));
    this.minTimeFrom = DurationTools.format(minStart * DurationTools.DURATION_1HOUR, 'HH:mm');
    this.freeArtistEndTime = DurationTools.format((minStart + this.duration) * DurationTools.DURATION_1HOUR, 'HH:mm');
  }


  private initAuthenticationListener() {
    this.pushSubscription = this.socketService.onAuthenticated.subscribe(() => {
      setTimeout(() => this.loadData(), 1);
    });
  }


  longDate(date: number) {
    let text = DateTools.format(date, 'EEE') + ' ' + DateTools.format(date, 'dd.MM');
    if (DateTools.getDayOfWeek(date) === 0) {

      // es ist ein Montag
      const week = moment(date).week();
      const weekNow = moment().week();
      const weekDiff = week - weekNow;
      if (weekDiff < 6) {
        const now = Date.now();
        if (weekDiff === 0) {
          text += '<br/>diese Woche';
        } else if (weekDiff === 1) {
          text += '<br/>nächste Woche';
        } else if (weekDiff > 1) {
          text += '<br/>in&nbsp;' + weekDiff + '&nbsp;Wochen';
        }
      } else {
        text += '<br/>' + DateTools.dateDiffToNowOnlyDaysText(date);
      }
    } else {
      // es ist kein Montag
      const daysCount = Math.abs(date - Date.now()) / DurationTools.DURATION_1DAY;
      if (daysCount < 30) {
        text += '<br/>' + DateTools.dateDiffToNowOnlyDaysText(date);
      }
    }
    /*if (DateTools.format(date, 'yyyy-MM-dd') === DateTools.formatNow('yyyy-MM-dd')) {
      text += '<br/>Heute';
    }*/

    return text;
  }

  endTimeLiveChanged(filter: any, change: any) {
    filter.studioEndTime = change.value;
  }

  dateChanged() {
    this.loadData();
  }

  durationChanged() {
    this.loadData();
  }


  endTimeChanged() {
    this.loadData();
  }

  startTimeChanged() {
    this.loadData();
  }


  selectAllDays(filter: EventFinderFilter, select: boolean) {
    for (let i = 0; i < filter.days.length; i++) {
      filter.days[i] = select;
    }
    this.loadData();
  }

  removeFilter(index: number) {
    this.filters.splice(index, 1);
    this.loadData();
  }

  addFilter() {
    this.filters.push({days: [false, false, false, false, false, false], startTime: this.normalStudioStart, studioEndTime: this.normalStudioEnd});
  }


  freeTimeWindowClicked(day: NxtAvailableArtistDayExtended, artist: NxtAvailableArtistDayExtendedArtist, freeTimeWindow: NxtAvailableArtistDayExtendedArtistFreeTimeWindow) {
    let studio = this.configService.config.value.studioName;
    if (studio === 'Aachen') {
      studio = 'Villa';
    }
    const event = {
      date: day.dateString,
      timeFrom: DurationTools.format(freeTimeWindow.startTime, 'HH:mm'),
      timeTill: DurationTools.format(freeTimeWindow.startTime + (this.duration * DurationTools.DURATION_1HOUR), 'HH:mm'),
      studio,
    };
    if (this.dialogRef) {
      for (const key of Object.keys(this.currentBoolSkillFilterObj)) {
        if (!this.currentBoolSkillFilterObj[key]) {
          delete this.currentBoolSkillFilterObj[key];
        }
      }
      for (const key of Object.keys(this.currentSkillFilterObj)) {
        if (!this.currentSkillFilterObj[key]) {
          delete this.currentSkillFilterObj[key];
        }
      }

      this.dialogRef.close({
        event,
        artist,
        studio: this.configService.config.value.eventStudio,
        skill: {skills: this.currentSkillFilterObj, boolSkills: this.currentBoolSkillFilterObj},
      });
    } else {
      this.dialogService.showOk('termin anlegen hier öffnen...');
    }
  }

  setWeekFromGoogleCalendar(currentParentUrl: string) {
    // "/calendar/u/2/r/week/2021/11/23"
    const splitted = currentParentUrl.split('/');
    const indexWeek = splitted.indexOf('week');
    const indexCustom = splitted.indexOf('custom');
    const indexDay = splitted.indexOf('day');
    let year = '2000';
    let month = '1';
    let day = '1';

    if (indexWeek > 0 && splitted.length > indexWeek + 3) {
      year = splitted[indexWeek + 1];
      month = splitted[indexWeek + 2];
      day = splitted[indexWeek + 3];
    } else if (indexCustom > -1) {
      year = splitted[indexCustom + 3];
      month = splitted[indexCustom + 4];
      day = splitted[indexCustom + 5];
    } else if (indexDay > -1) {
      year = splitted[indexDay + 1];
      month = splitted[indexDay + 2];
      day = splitted[indexDay + 3];
      if (!year) {
        year = DateTools.formatNow('yyyy');
        month = DateTools.formatNow('MM');
        day = DateTools.formatNow('dd');
      }
    } else {
      year = DateTools.format(Date.now(), 'yyyy');
      month = DateTools.format(Date.now(), 'MM');
      day = DateTools.format(Date.now(), 'dd');
    }

    const monthToSet = parseInt(month, 10) - 1;
    const date = new Date(0);
    date.setFullYear(parseInt(year, 10));
    date.setMonth(monthToSet);
    date.setDate(parseInt(day, 10));
    let dateNumber = date.getTime();
    // alert('jo');
    let counter = 0;
    while (DateTools.getDayOfWeek(dateNumber) !== 0) {
      counter++;
      dateNumber = DateTools.addDays(dateNumber, -1);
      if (counter > 100) {
        break;
      }
    }
    this.fromDateString = DateTools.format(dateNumber, 'yyyy-MM-dd');
  }


  editArtist(artistId: string) {
    if (this.loginService.isBackoffice()) {
      const dialog = this.dialogService.showComponentDialog(ArtistEditComponent, null, {minHeight: '95vh', minWidth: '95vw'});
      dialog.componentInstance.setData(artistId);
      dialog.afterClosed().subscribe((changed) => {
        if (changed) {
          this.loadData();
        }
      });
    }
  }

  async selectDuration() {
    await TimeTools.waitForObj(() => this.artists.length > 0);

    return new Promise<number>(async (resolve, reject) => {
      const buttons = [{text: '0:30', value: 0.5}, {text: '1:00', value: 1}, {text: '1:30', value: 1.5}, {text: '2:00', value: 2}, {text: '2:30', value: 2.5}, {
        text: '3:00',
        value: 3,
      }, {
        text: '4:00',
        value: 4,
      }, {text: '5:00', value: 5}, {text: '6:00', value: 6}, {text: '7:00', value: 7}, {text: '10:00', value: 10}];
      /*const piercingArtists = ArrayTools.unique(this.data.days.map(d => d.artists.filter(a => a.workType === 'piercing').map(a => a.name)).flat());
      if (piercingArtists.length > 0) {
        buttons = [{text: 'Piercing', value: -1}, ...buttons];
      }*/


      const result = await this.dialogService.showButtonChooser<number>({
        buttonRows: [buttons],
        title: 'Wie lange dauert es?',
        text: '', minWidth: '80%',
        value: '',
        hideBackButton: true,
      });
      if (typeof result !== 'string' && result?.value) {
        this.duration = result.value;
        this.loadData();
        resolve(result.value);
      } else {
        resolve(undefined);
      }
    });
  }

  async freeArtistClicked(day: NxtAvailableArtistDayExtended) {
    let studio = 'Villa';
    if (this.configService.config.value.studiosWithoutSideCash.length === 1) {
      studio = this.configService.config.value.studiosWithoutSideCash[0].name;
    }
    const event = {
      date: day.dateString,
      timeFrom: this.minTimeFrom,
      timeTill: this.freeArtistEndTime,
      studio,
    };
    if (this.dialogRef) {
      this.dialogRef.close({event, artistName: 'Artist offen', studio});
    } else {
      this.dialogService.showOk('termin anlegen hier öffnen...');
    }
  }

  setDuration(duration: number) {
    this.duration = duration;
    this.loadData();
  }

  public copyText() {
    const artistsTimeWindows: { [artist: string]: NxtAvailableArtistDayExtendedArtistFreeTimeWindow[] } = {};
    for (const day of this.dataFiltered.days) {
      if (day.dateString.dateParse() > Date.now()) {
        for (const artist of day.artists) {
          if (!artistsTimeWindows[artist.name]) {
            artistsTimeWindows[artist.name] = [];
          }
          artist.freeTimeWindows.forEach((t: any) => {
            t.dateString = day.dateString;
          });
          artistsTimeWindows[artist.name].push(...artist.freeTimeWindows);
        }
      }
    }
    const artists = Object.keys(artistsTimeWindows).sort();
    const artistsDays: { [artist: string]: any } = {};
    for (const artist of artists) {
      artistsDays[artist] = ObjectGroupTools.groupObjectArray<any, any>(artistsTimeWindows[artist], 'dateString');
    }

    const lines: string[] = ['Folgende Termine kann ich dir anbieten:'];
    for (const artist of artists) {
      const artistLine: string[] = ['bei ' + artist];
      artistLine.push(encodeURI('https://artists.nxt-lvl.ink/#/artists/' + artist));
      for (const dateString of Object.keys(artistsDays[artist])) {
        if (artistsDays[artist][dateString].length === 1) {
          artistLine.push('    am ' + dateString.dateParse().dateFormat('EEE dd.MM.yyyy') + ' um ' + artistsDays[artist][dateString][0].startTimeString + ' Uhr');
        } else {
          artistLine.push('    am ' + dateString.dateParse().dateFormat('EEE dd.MM.yyyy'));
          for (const timeWindow of artistsDays[artist][dateString]) {
            artistLine.push('       um ' + timeWindow.startTimeString + ' Uhr');
          }
        }
      }
      lines.push(artistLine.join('\n'));
    }
    const text = lines.join('\n\n');
    this.dialogService.showOk(text);
    this.clipboard.copy(text);
  }

  public test() {
    debugger;
  }


  artistFilterTextChanged() {
    this.setFilter();
  }

  setFilter() {
    this.dataFiltered = clone(this.data);
    if (this.dataFiltered?.days) {
      this.dataFiltered.days.forEach(day => {
        day.artists = day.artists.filter(artist => artist.name.toLowerCase().includes(this.artistFilterText.toLowerCase()));
      });
    }
  }

  boolSkillChanged(boolSkill: string) {
    this.loadData();
  }

  async skillChanged(skill: string) {
    if (skill === 'Portrait' && this.currentSkillFilterObj[skill]) {
      // Portrait wurde auf ja gesetzt
      if (!this.currentBoolSkillFilterObj['Portrait 1zu1']) {
        const result = await this.dialogService.showYesNo('Ist es ein 1zu1 Portrait (mit Wiedererkennungswert)?\n\nJa: Berühmte Person, Familienmitglied, etc\n\nNein: Einfach nur eine hübsche Frau, etc.', {});
        if (result) {
          this.currentBoolSkillFilterObj['Portrait 1zu1'] = true;
        }
      }
    }
    if (skill === 'Tiere' && this.currentSkillFilterObj[skill]) {
      // Tiere wurde auf ja gesetzt
      if (!this.currentBoolSkillFilterObj['Tier-Portrait 1zu1']) {
        const result = await this.dialogService.showYesNo('Ist es ein 1zu1 Tier-Portrait (mit Wiedererkennungswert)?\n\nJa: Eigener Hund, eigene Katze, etc\n\nNein: Einfach nur irgendein Hund, etc.', {});
        if (result) {
          this.currentBoolSkillFilterObj['Portrait 1zu1'] = true;
        }
      }
    }
    this.loadData();
  }
}
