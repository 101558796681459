<div fxLayout="column" fxLayoutAlign="space-evenly center" style="height: 100%; background-color: #2A2A2A">

  <nxt-button routerLink="/e/1ispt6ib0asqb11fijq79dj9mp">EVENT-EDIT</nxt-button>

  <nxt-button routerLink="/win">Gewinnspiel</nxt-button>

  <nxt-button routerLink="/set-code">set</nxt-button>

  <nxt-button (click)="restart()">RESTART</nxt-button>

  <nxt-button (click)="dialogService.showLoading(null ,'test ...')">show loading</nxt-button>

  <nxt-button (click)="dialogService.hideLoading(null)">hide loading</nxt-button>


  <!--<nxt-button (click)="showButtonChooser()">button-chooser</nxt-button>-->

  <nxt-button (click)="showBodyPutChooser()">showBodyPutChooser</nxt-button>

  <nxt-button (click)="test()" >test</nxt-button>


</div>

<!--<nxt-loading-fill
  width="200px"
  height="100px"
  loadingType="stroke"

  stroke="#b7860f"
  strokeDir="normal"
  path="m415,-0.02817l-296.5,0l0,36.88464l10.71,0l0,-26.16492l275.35,0l0,26.16492l10.71,0l-0.27,-36.88464z"
></nxt-loading-fill>


<nxt-loading-fill
  width="200px"
  height="100px"
  loadingType="stroke"
  strokeDir="reverse"
  stroke="#b7860f"
  path="m415,172.73l-296.5,0l0,-36.92l10.71,0l0,26.19l275.35,0l0,-26.19l10.71,0l-0.27,36.92z"
></nxt-loading-fill>


<nxt-loading-fill
  width="300px"
  height="100px"
  text="NXT-LVL.INK"
></nxt-loading-fill>-->


<!--  , 'rtl', 'ttb'-->


<!--<nxt-loading-fill width="300px" height="300px" loadingType="fill" img="/assets/logo/logo_white.svg"></nxt-loading-fill>-->
