import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';

@Component({
  selector: 'nxt-page',
  templateUrl: './nxt-page.component.html',
  styleUrls: ['./nxt-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})

export class NxtPageComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }
}
