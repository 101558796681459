import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {NxtTypedFormControl} from '../../../nxt-form/nxt.typed-form-control';
import {SocketService} from '../../../services/socket/socket.service';
import {DialogService} from '../../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtMoneyAccount, NxtMoneyTransaction, NxtMoneyTransactionCategory, NxtMoneyTransactionLabel, NxtMoneyTransactionRegion} from '../../../common-interfaces/money-account.interface';
import {AutocompleteComponent} from '../../form-controls/autocomplete/autocomplete.component';
import {DisplayWithTools} from '../../../common-browser/helpers/display-with.tools';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {InputComponent} from '../../form-controls/input/input.component';
import {FormTools} from '../../../services/form.tools';
import {WithoutNxtDbFields} from '../../../common-interfaces/nxt.db-fields.interface';
import {clone} from '../../../common-browser/helpers/object.tools';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {TextareaComponent} from '../../form-controls/textarea/textarea.component';
import {ValidatorTools} from '../../../helpers/validator.tools';

@Component({
    selector: 'nxt-money-transaction-edit',
    templateUrl: './money-transaction-edit.component.html',
    styleUrls: ['./money-transaction-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        AutocompleteComponent,
        NxtButtonComponent,
        InputComponent,
        MatButtonToggle,
        MatButtonToggleGroup,
        ReactiveFormsModule,
        TextareaComponent
    ]
})

export class MoneyTransactionEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  private originalTransaction: WithoutNxtDbFields<NxtMoneyTransaction>;


  constructor() {
    super();
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  accounts = signal<NxtMoneyAccount[]>([]);
  otherAccounts = computed(() => {
    return this.accounts().filter(a => a.id !== this.form.controls.account.value.id);
  });
  regions = signal<NxtMoneyTransactionRegion[]>([]);
  categories = signal<NxtMoneyTransactionCategory[]>([]);
  labels = signal<NxtMoneyTransactionLabel[]>([]);

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  private dialogRef = inject(MatDialogRef<MoneyTransactionEditComponent>, {optional: true});

  form = new FormGroup({
    account: new NxtTypedFormControl<NxtMoneyAccount>(null, [ValidatorTools.requiredAndNotNaN], 'Konto'),
    fromAccount: new NxtTypedFormControl<NxtMoneyAccount>(null, [], 'Gegenkonto'),
    direction: new NxtTypedFormControl<'in' | 'out' | null>(null, [ValidatorTools.requiredAndNotNaN], 'Richtung'),
    dateString: new NxtTypedFormControl<string>(null, [], 'Datum'),
    info: new NxtTypedFormControl<string>('', [], 'Info'),
    region: new NxtTypedFormControl<NxtMoneyTransactionRegion>(null, [ValidatorTools.requiredAndNotNaN], 'Region'),
    category: new NxtTypedFormControl<NxtMoneyTransactionCategory>(null, [ValidatorTools.requiredAndNotNaN], 'Kategorie'),
    labels: new NxtTypedFormControl<NxtMoneyTransactionLabel[]>([], [], 'Labels'),
    value: new NxtTypedFormControl<number | null>(null, [ValidatorTools.requiredAndNotNaN], 'Betrag'),
  });

  protected readonly DisplayWithTools = DisplayWithTools;

  async ngOnInit() {
    const data = await this.socketService.getMoneyAccounts();
    this.accounts.set(data.accounts);
    this.regions.set(data.regions);
    this.categories.set(data.categories);
    this.labels.set(data.labels);
    if (this.originalTransaction.accountId) {
      this.form.controls.account.setValue(this.accounts().find(a => a.id === this.originalTransaction.accountId));
    }
    this.pushSubscription = this.form.controls.category.valueChanges.subscribe((value) => {
      this.form.controls.fromAccount.clearValidators();
      this.form.controls.fromAccount.setErrors(null);
      this.form.controls.fromAccount.setValidators(value?.isTransfer ? [ValidatorTools.requiredAndNotNaN] : []);
    });
  }

  nxtOnDestroy() {
  }

  new(accountId: string) {
    this.originalTransaction = {
      accountId: '',
      direction: 'in',
      extId: '',
      peerText: '',
      peerName: '',
      peerId: '',
      datetime: 0,
      dateString: '',
      value: 0,
      info: '',
      categoryId: '',
      regionId: '',
      labelIds: [],
      balanceAfter: 0,
      isTransfer: false
    };
    this.originalTransaction.accountId = accountId;
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  async saveClicked() {
    if (FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      const data = this.getFromForm();
      let createPeerTransaction = false;
      if (data.isTransfer && this.form.controls.fromAccount.value.type === 'cash') {
        createPeerTransaction = await this.dialogService.showYesNo('Soll die Gegenbuchung auf "' + this.form.controls.fromAccount.value.name + '" automatisch gebucht werden?');
      }
      await this.socketService.upsertMoneyTransaction(data, createPeerTransaction);
      this.dialogRef.close();
    }
  }

  private getFromForm(): WithoutNxtDbFields<NxtMoneyTransaction> {
    const transaction = clone(this.originalTransaction);
    transaction.value = this.form.controls.value.value;
    transaction.accountId = this.form.controls.account.value.id;
    transaction.regionId = this.form.controls.region.value.id;
    transaction.info = this.form.controls.info.value;
    transaction.direction = this.form.controls.direction.value;
    transaction.categoryId = this.form.controls.category.value.id;
    transaction.isTransfer = this.form.controls.category.value.isTransfer;
    if (this.form.controls.category.value.isTransfer) {
      transaction.peerId = this.form.controls.fromAccount.value.id;
    }
    transaction.labelIds = this.form.controls.labels.value.map(l => l.id);
    return transaction;
  }
}
