import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {EventComponent} from '../../event/event.component';
import {SocketService} from '../../services/socket/socket.service';
import {DialogService, LoadingId} from '../../services/dialog.service';
import {Log} from '../../common-browser/log/log.tools';
import {NxtFormControl} from '../../nxt-form/nxt.form-control';
import {NxtPaymentPossibilityRecord} from '../../common-interfaces/nxt.payment-possibility-record.interface';
import {InputComponent} from '../form-controls/input/input.component';
import {LoginService} from '../../services/login.service';
import {FormTools} from '../../services/form.tools';
import {DecimalTools} from '../../common-browser/helpers/decimal.tools';
import {UuidTools} from '../../common-browser/helpers/uuid.tools';
import {DownloadService} from '../../services/download.service';
import {SortTools} from '../../common-browser/helpers/sort.tools';
import moment from 'moment';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {PermissionService} from '../../services/permission.service';
import {NxtPermissionId} from '../../common-interfaces/nxt.user.interface';
import {ConfigService} from '../../services/config.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {ColComponent} from '../../controls/nxt-grid/col/col.component';
import {RowComponent} from '../../controls/nxt-grid/row/row.component';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {AutocompleteComponent} from '../form-controls/autocomplete/autocomplete.component';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {SelectComponent} from '../form-controls/select/select.component';
import {DatePickerComponent} from '../form-controls/date-picker/date-picker.component';
import {RadioComponent} from '../form-controls/radio/radio.component';
import {NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtPaypalTransaction} from '../../common-interfaces/nxt-paypal-transaction';
import {GiftCardTools} from '../../common-browser/helpers/gift-card.tools';
import {PercentageTools} from '../../common-browser/helpers/percentage.tools';
import {ValidatorTools} from '../../helpers/validator.tools';
import {ThermalPrinterService} from '../../services/thermal-printer.service';
import {DatePicker2Component} from '../form-controls/date-picker-2/date-picker-2.component';

export interface NxtGiftCardDiscountAction {
  percentage: number;
  minPaymentValue?: number;
  name: string;
  validFrom: number;
  disableDiscountPromo: boolean;
}

@Component({
  selector: 'nxt-gift-card-form',
  templateUrl: './gift-card-form.component.html',
  styleUrls: ['./gift-card-form.component.scss'],
  imports: [FormsModule, ReactiveFormsModule, FlexModule, InputComponent, NgIf, RadioComponent, DatePickerComponent, SelectComponent, SlideToggleComponent, AutocompleteComponent, MatRadioGroup, MatRadioButton, RowComponent, ColComponent, NxtButtonComponent, DatePicker2Component],
})
export class GiftCardFormComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EventComponent>,
    @Inject(MAT_DIALOG_DATA) private data: string | NxtPaymentPossibilityRecord,
    private socketService: SocketService,
    private dialogService: DialogService,
    private loginService: LoginService,
    private downloadService: DownloadService,
    private permissionService: PermissionService,
    private configService: ConfigService,
    private thermalPrinterService: ThermalPrinterService,
  ) {
    document.body.style.backgroundColor = 'transparent !important';
    // const createdAt = new NxtFormControl(new Date(), [ValidatorTools.requiredAndNotNaN]);
    // createdAt.disable();

    this.loadNotAssignedPaypalPayments();

    this.form = this.getFormGroup();

    this.form.get('value').valueChanges.subscribe((value) => {
      this.generateGiftCardNumber();
      if (this.discountAction?.percentage) {
        this.calcPaymentValue();
      } else {
        this.discountPercentageChanged();
      }
    });


    this.form.get('paymentValue').valueChanges.subscribe((value) => {

      if (!value || parseFloat(value) === 0) {
        this.enableRestriction = true;
        this.generateGiftCardNumber();
      } else {
        this.enableRestriction = false;
        this.form.get('additionalPrintText').setValue('');
        this.form.get('customRestrictionValue').setValue('');
        // this.showCustomRestriction = false;
      }

      if (this.discountAction?.percentage) {
        this.calcValue();
      } else {
        this.calcDiscountPercentage();
      }
      this.generateGiftCardNumber();
    });

    this.form.get('additionalPrintText').valueChanges.subscribe((value) => {
      if (value === 'custom') {
        // this.showCustomRestriction = true;
      } else {
        // this.showCustomRestriction = false;
        this.form.get('customRestrictionValue').setValue('');
      }
      this.generateGiftCardNumber();

      if (value === 'Google Bewertung' && !this.form.get('discountReason').value) {
        this.form.get('discountReason').setValue(value);
        this.form.get('customRestrictionValue').setValue(100);
      }
    });


    if (this.configService.config.value.studioRegion === 'AC' || this.configService.config.value.studioRegion === 'STAGING' || true) {
      this.discountActions = [
        // {name: 'Aktion ab 1000 € -> 40 %', percentage: 40, minPaymentValue: 600, validFrom: '2024-04-01'.dateParse(), disableDiscountPromo: true},
        // {name: 'XMAS15', percentage: 15, minPaymentValue: 5, validFrom: '2024-01-01'.dateParse(), disableDiscountPromo: true},
        // {name: 'Aktion ab 2000 € -> 50 %', percentage: 50, minPaymentValue: 1000, validFrom: '2024-04-01'.dateParse(), disableDiscountPromo: true},
        // {name: 'Aktion ab 1000 € -> 30 %', percentage: 30, minPaymentValue: 700, validFrom: '2023-06-01'.dateParse(), disableDiscountPromo: true}
      ];
    }

    if (this.configService.config.value.studioRegion === 'STAGING') {
      this.discountActions = [
        // {name: 'Aktion ab 1000 € -> 40 %', percentage: 40, minPaymentValue: 600, validFrom: '2024-04-01'.dateParse(), disableDiscountPromo: true},
        // {name: 'XMAS15', percentage: 15, minPaymentValue: 5, validFrom: '2024-01-01'.dateParse(), disableDiscountPromo: true},
        // {name: 'Aktion ab 2000 € -> 50 %', percentage: 50, minPaymentValue: 1000, validFrom: '2024-04-01'.dateParse(), disableDiscountPromo: true},
        // {name: 'Aktion ab 1000 € -> 30 %', percentage: 30, minPaymentValue: 700, validFrom: '2023-06-01'.dateParse(), disableDiscountPromo: true}
      ];
    }

    // XMAS15
    const thisYearString = DateTools.formatNow('yyyy');
    const nextYearString = (parseInt(thisYearString, 10) + 1).toString();
    const start = (thisYearString + '-11-15').dateParse();
    const end = (thisYearString + '-12-31').dateParse();
    if (Date.now() > start && Date.now() < end) {
      this.discountActions.push({name: 'XMAS15', percentage: 15, minPaymentValue: 5, validFrom: (nextYearString + '-01-01').dateParse(), disableDiscountPromo: true});
    }
  }

  public discountActions: NxtGiftCardDiscountAction [] = [];


  public discountAction: NxtGiftCardDiscountAction;

  discountPercentage = 0;
  notAssignedPaypalPayments = [];

  @ViewChild('valueInput', {static: false}) valueInput: InputComponent;
  buttonOkDisabled = false;
  form: UntypedFormGroup;
  public printerIsAvailable = -1;
  private checkPrinterTimeoutIsRunning = false;
  checkPrinterTimeout: any;
  enableRestriction = false;
  disableRestriction = false;
  giftCardCount = '1';
  // showCustomRestriction = false;
  form_name = new NxtFormControl(this.getRandomId());

  payments = this.fb.array([]);

  /*public xmas15Changed() {
    if (this.xmas15) {
      this.dialogService.showOk('Der Gutschein ist erst ab dem 01.01.2023 einzulösen', {title: 'Info für Kunde'});
      this.discountPercentage = 15;
      this.form.get('discountReason').setValue('XMAS15');
    } else {
      this.form.get('discountReason').setValue(null);
      this.discountPercentage = 0;
    }
  }*/
  disableDiscountPromo = false;

  /*private async generateMultiple() {
    for (let i = 1; i < 36; i++) {
      const number = 'BRAND-' + (i < 10 ? '0' : '') + i + '/35';
      await this.socketService.createPaymentPossibilityRecord({
        value: 20,
        name: number,
        id: new UUID.default(1).format().toString(),
        type: 'gift-card',
        assignments: [],
        assigned: false,
        createdAt: new Date(DateTools.parse('2019-11-04', 'yyyy-MM-dd')),
        additionalInfo: 'Eröffnung Brand'
      });
    }
  }*/

  // public xmas15 = false;

  private getFormGroup() {
    const formGroup = this.fb.group({
      id: new NxtFormControl(UuidTools.generate()),
      name: this.form_name,
      createdAt: new NxtFormControl(new Date(), [ValidatorTools.requiredAndNotNaN]),
      customer: new NxtFormControl(),
      value: new NxtFormControl(null, [ValidatorTools.requiredAndNotNaN], 'Wert'),
      paymentValue: new NxtFormControl(null, [ValidatorTools.requiredAndNotNaN], 'Bezahlt'),
      additionalInfo: new NxtFormControl('', [], 'Info (intern)'),
      additionalPrintText: new NxtFormControl(''),
      customRestrictionValue: new NxtFormControl(''),
      validFrom: new NxtFormControl(Date.now().dateFormatDate(), [], 'Gültig ab'),
      validTill: new NxtFormControl(GiftCardTools.getDateOfExpiry(Date.now().dateFormatDate()), [], 'Gültig bis'),
      paymentMethod: new NxtFormControl('cash'),
      discountReason: new NxtFormControl('', [], 'Rabattgrund'),
      paymentPaypalTransaction: new NxtFormControl(null),
    });
    if (!formGroup.get('name')) {

    }

    return formGroup;
  }

  // showForm = false;

  ngOnInit() {

    // setTimeout(() => this.showForm = true, 5000);
  }


  ngAfterViewInit(): void {
    /*this.checkPrinterTimeoutIsRunning = true;
    this.checkPrinterAvailable();*/


    // setTimeout(() => this.valueInput.setFocus(), 200);
  }

  ngOnDestroy(): void {
  }


  async saveAndClose() {
    if (this.form.get('paymentMethod').value === 'paypal') {
      if (!this.form.get('paymentPaypalTransaction').value.id) {
        this.dialogService.showOk('Du musst eine PayPal-Zahlung auswählen');
        return;
      }
    }
    if (this.discountAction?.minPaymentValue) {
      if (this.form.get('paymentValue').value < this.discountAction?.minPaymentValue) {
        this.dialogService.showOk('Erst ab ' + this.discountAction?.minPaymentValue.toMoneyString());
        return;
      }
    }
    const giftCardCountToSave = parseInt(this.giftCardCount, 10);
    let result = 'kein Gutschein generiert';
    let errorCount = 0;
    for (let i = 0; i < giftCardCountToSave; i++) {
      errorCount = i + 1;
      result = await this.save();
      if (result !== 'OK') {
        break;
      }
      this.generateGiftCardNumber();
      this.form.get('id').setValue(UuidTools.generate());
    }
    if (result === 'OK') {
      this.close(result);
    } else {
      if (typeof result === 'string') {
        if (giftCardCountToSave > 1) {
          this.dialogService.showOk('Fehler beim generieren ' + errorCount + ' / ' + giftCardCountToSave + '\n' + result);
        } else {
          this.dialogService.showOk(result);
        }
      }
    }
  }

  displayPaypalTransactionOptionFn(paypalTransaction: NxtPaypalTransaction | string, highlight: (string) => string): string | undefined {
    if (typeof paypalTransaction === 'string') {
      return paypalTransaction;
    }
    if (paypalTransaction && paypalTransaction.from) {
      const {from, transactionTime, value, originalValue, message} = paypalTransaction;
      let text = '';
      if (paypalTransaction) {
        text = `<div>`;
        text += `<span style="font-size: larger">${highlight(from)}</span>`;
        text += `<br/>${highlight(DateTools.format(transactionTime, 'dd.MM.yyyy HH:mm'))} - ${highlight(moment(transactionTime).fromNow())}`;
        if (originalValue && value !== originalValue) {
          text += `<br/>${DecimalTools.toMoneyString(value)} von ${DecimalTools.toMoneyString(originalValue)}`;
        } else {
          text += `<br/>${DecimalTools.toMoneyString(value)}`;
        }

        text += `<br/>${highlight(message)}`;
        text += '</div>';
      }
      return text;
    }
  }

  displayPaypalTransactionFn(paypalTransaction?: any): string | undefined {
    return paypalTransaction ? DecimalTools.roundToString(paypalTransaction.value, 2) + ' € - ' + paypalTransaction.from : undefined;
  }


  private async check() {

    if (!FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      return false;
    }
    /*if (FormTools.getErrors(this.form).length > 0) {
      await this.dialogService.showOk('🤦🏼‍♂️ Eingabe fehlt 🤦🏼‍♂️');
      return false;
    }*/
    if (this.form.get('additionalPrintText').value === 'custom' && !this.form.get('customRestrictionValue').value) {
      await this.dialogService.showOk('🤦🏼‍♂️ Bruder, wenn du "Mindespreis festlegen" anklickst, musst du auch einen eintragen 🤦🏼‍♂️');
      return false;
    }

    if (this.form.get('paymentPaypalTransaction').value) {
      const paypalTransaction = this.form.get('paymentPaypalTransaction').value as NxtPaypalTransaction;
      const paymentValue = MathTools.roundMoney(this.form.get('paymentValue').value);
      const paypalValue = paypalTransaction.value;
      if (paypalValue < paymentValue) {
        this.dialogService.showOk('Habibi der Kunde hat nur ' + DecimalTools.toMoneyString(paypalValue) + ' bezahlt...\nVersuchs nochmal');
        return false;
      }
    }


    return true;
  }

  private async save() {
    if (await this.check()) {
      let insertGiftCardResult;
      try {
        let print = true;
        if (this.permissionService.hasPermission(NxtPermissionId.GiftCards_Download)) {
          print = await this.dialogService.showYesNo('Drucken oder Herunterladen?', {yesText: 'Drucken', noText: 'Herunterladen'});
        }
        const paymentPosRecord: NxtPaymentPossibilityRecord = {
          ...this.form.getRawValue(),
          type: 'gift-card',
          assignments: [],
          assigned: false,
          createdBy: this.loginService.getUsername(),
          studio: this.loginService.getStudio(),
        };
        paymentPosRecord.validTill = DateTools.parse(paymentPosRecord.validTill);

        if (paymentPosRecord.paymentMethod === 'paypal') {
          paymentPosRecord.paymentMethodId = this.form.get('paymentPaypalTransaction').value.transactionId;
          delete (paymentPosRecord as any).paymentPaypalTransaction;
        }

        paymentPosRecord.createdAt = Date.now();

        const additionalPrintTextLines: string[] = [];

        if (this.form.get('customRestrictionValue').value) {
          additionalPrintTextLines.push(this.getAdditionalPrintTextFromCustomValue(this.form.get('customRestrictionValue').value));
          // paymentPosRecord.additionalPrintText = this.getAdditionalPrintTextFromCustomValue(this.form.get('customRestrictionValue').value);
        }

        if (paymentPosRecord.additionalPrintText === 'daySession') {
          additionalPrintTextLines.push('Einzulösen nur in Verbindung\nmit einer Tagessesion\nAngebote ausgenommen');
          // paymentPosRecord.additionalPrintText = 'Einzulösen nur in Verbindung\nmit einer Tagessesion\nAngebote ausgenommen';
        }
        /*if (paymentPosRecord.additionalPrintText === 'thankYou') {
          paymentPosRecord.additionalPrintText = '';
        }*/
        // paymentPosRecord.validTill = GiftCardTools.getDateOfExpiry(Date.now());
        paymentPosRecord.validFrom = Date.now();

        if (this.discountAction) {
          paymentPosRecord.additionalInfo = this.discountAction.name;
          paymentPosRecord.validFrom = this.discountAction.validFrom;
          paymentPosRecord.disableDiscountPromo = this.discountAction.disableDiscountPromo;
          additionalPrintTextLines.push('Nicht auf Tattoos mit\neinem Aktionspreise einzulösen');
          // paymentPosRecord.additionalPrintText = 'Nicht auf Tattoos mit\neinem Aktionspreise einzulösen';
        } else {
          paymentPosRecord.disableDiscountPromo = this.disableDiscountPromo;
          if (paymentPosRecord.disableDiscountPromo) {
            additionalPrintTextLines.push('Nicht auf Tattoos mit\neinem Aktionspreise einzulösen');
            // paymentPosRecord.additionalPrintText = 'Nicht auf Tattoos mit\neinem Aktionspreise einzulösen';
          }
        }
        paymentPosRecord.additionalPrintText = additionalPrintTextLines.join('\n');
        paymentPosRecord.discountPercentage = PercentageTools.getDiscountPercentage(paymentPosRecord.value, paymentPosRecord.paymentValue);
        paymentPosRecord.originalValue = paymentPosRecord.value;
        paymentPosRecord.originalPaymentValue = paymentPosRecord.paymentValue;
        insertGiftCardResult = await this.socketService.createPaymentPossibilityRecord(paymentPosRecord, print);
        Log.info(insertGiftCardResult);
        if (!print) {
          this.dialogService.showLoading(LoadingId.DownloadVoucher, 'lade Gutschein...');
          const voucher = await this.socketService.getVoucherPdfByCode(paymentPosRecord.name);
          this.downloadService.downloadBase64('Gutschein.pdf', voucher.base64);
          this.dialogService.hideLoading(LoadingId.DownloadVoucher);
          this.dialogService.showOk('ACHTUNG - ACHTUNG - ACHTUNG\n\nDu hast ein Gutschein heruntergeladen, sei dir zu 100% sicher, dass du die richtige Datei verschickst!', {buttonText: 'JA ICH KONTROLLIERE DAS ANHAND DES DATEI-ERSTELL-DATUMS'});
        }
      } catch (err) {
        Log.error(err);
        // alert('save failed! ' + err.message + ' - ' + JSON.stringify(err));
        this.form.get('createdAt').disable();
      }
      return insertGiftCardResult;
    }
    return false;
  }

  async closeClicked() {
    /*const result = await this.dialogService.showYesNo('Möchtest du wirklich abbrechen?', {
      yesText: 'Ja, abbrechen',
      noText: 'Nein'
    });
    if (result) {*/
    this.close(false);
    // }
  }

  async close(result) {
    this.dialogRef.close(result);
  }

  private getRandomId() {
    if (!this.form) {
      return '';
    }
    let code = (UuidTools.generate().substr(0, 30)).toUpperCase().replace(/-/g, '');
    code = code.substr(0, 4) + '-' + code.substr(16, 4) + '-' + code.substr(20, 4);
    const paymentValue = MathTools.roundMoney(this.form.get('paymentValue').value);
    const value = MathTools.roundMoney(this.form.get('value').value);
    if (this.form && this.form.get('additionalPrintText').value === 'thankYou') {
      return 'GD' + '-' + code;
    } else if (this.form && paymentValue === 0) {
      return 'F-' + code;
    } else if (this.form && paymentValue < value) {
      return 'R-' + code;
    } else {
      return 'G-' + code;
    }
  }

  refreshInInputClicked = () => {
    this.generateGiftCardNumber();
  };

  generateGiftCardNumber() {
    const newCode = this.getRandomId();
    this.form.get('name').setValue(newCode);
  }

  private async checkPrinterAvailable() {
    this.printerIsAvailable = (await this.socketService.isPrinterAvailable(this.loginService.getStudio())) ? 1 : 0;
    if (this.checkPrinterTimeoutIsRunning) {
      this.checkPrinterTimeout = setTimeout(async () => this.checkPrinterAvailable(), 3000);
    }
  }


  async buttonOkClicked() {
    // this.toGenerateCounter = parseInt(this.giftCardCount, 10);
    try {
      if (this.buttonOkDisabled === false) {
        this.buttonOkDisabled = true;
        await this.saveAndClose();
        this.buttonOkDisabled = false;
      }
    } catch (err) {
      if (err.message.includes('Belegdrucker reagiert nicht')) {
        this.thermalPrinterService.showChangeIp(err.message).then();
      } else {
        Log.error(err);
      }
    }

  }


  private getAdditionalPrintTextFromCustomValue(value: any) {
    return 'Einzulösen bei einem\nMindestpreis von ' + DecimalTools.toMoneyString(value, '€', false);
  }

  async downloadPdf() {
    if (this.form.get('value').value) {
      const base64Pdf = await this.socketService.getVoucherPdf(this.form.get('value').value, this.form.get('name').value, Date.now());
      this.downloadService.downloadBase64('Gutschein.pdf', base64Pdf);
    }
  }


  discountPercentageChanged() {
    if (this.discountPercentage > 100) {
      this.discountPercentage = 100;
    }
    if (this.discountPercentage <= 0) {
      this.discountPercentage = 0;
    }
    const value = this.form.get('value').value;
    const newPaymentValue = MathTools.roundMoney(value - (value * this.discountPercentage / 100));
    if (this.form.get('paymentValue').value !== newPaymentValue) {
      this.form.get('paymentValue').setValue(newPaymentValue, {onlySelf: true, emitEvent: false});
      // this.form.get('paymentValue').setValue(newPaymentValue);
    }
    if (this.discountPercentage > 0) {
      this.form.get('discountReason').setValidators([ValidatorTools.requiredAndNotNaN]);
    } else {
      this.form.get('discountReason').setValidators([]);
    }
    this.form.get('discountReason').updateValueAndValidity({emitEvent: false});
    this.calcDisableDiscountPromo();
    this.generateGiftCardNumber();
  }

  paymentMethodChange() {
    if (this.form.get('paymentMethod').value === 'paypal') {
      this.form.get('paymentPaypalTransaction').setValidators([ValidatorTools.requiredAndNotNaN]);
    } else {
      this.form.get('paymentPaypalTransaction').setValidators([]);
    }
    this.form.get('paymentPaypalTransaction').updateValueAndValidity({emitEvent: false});
  }

  paypalPaymentsChange() {

  }

  private async loadNotAssignedPaypalPayments() {
    const notAssignedPaypalTransactionsAll = SortTools.sort(await this.socketService.getNotAssignedPaypalTransactions(), 'transactionTime', true);
    this.notAssignedPaypalPayments = notAssignedPaypalTransactionsAll.filter(trans => trans.direction === 'in');
  }

  public discountChanged() {
    if (this.discountAction) {
      this.form.get('discountReason').setValue(this.discountAction.name);
      this.discountPercentage = this.discountAction.percentage;
      this.discountPercentageChanged();
      this.disableDiscountPromo = true;
    }
    this.generateGiftCardNumber();
  }

  private calcValue() {
    const paymentValue = this.form.get('paymentValue').value;
    const newValue = MathTools.roundMoney(paymentValue / ((100 - this.discountPercentage) / 100));

    if (this.form.get('value').value !== newValue) {
      this.form.get('value').setValue(newValue, {onlySelf: true, emitEvent: false});
    }
  }


  private calcDiscountPercentage() {
    const value = this.form.get('value').value;
    const paymentValue = this.form.get('paymentValue').value;
    let newValue = 0;
    if (paymentValue < value) {
      newValue = Math.round((value - paymentValue) / value * 100);
    }
    if (this.discountPercentage !== newValue) {
      this.discountPercentage = newValue;
    }
  }

  private calcPaymentValue() {
    const value = this.form.get('value').value;
    const paymentValue = this.form.get('paymentValue').value;
    const newPaymentValue = value / 100 * (100 - this.discountPercentage);
    if (newPaymentValue !== this.form.get('paymentValue').value) {
      this.form.get('paymentValue').setValue(newPaymentValue, {onlySelf: true, emitEvent: false});
    }
  }

  private calcDisableDiscountPromo() {
    if (this.discountPercentage === 100 || this.discountPercentage === 0) {
      this.disableDiscountPromo = false;
    } else {
      this.disableDiscountPromo = true;
    }
  }

  testClicked() {
    // this.form.get('paymentValue').setValue(newPaymentValue, {onlySelf: true, emitEvent: false});
    this.form.get('paymentValue').setValue(MathTools.random(0, 100000), {onlySelf: true, emitEvent: false});
    // this.form.get('paymentValue').setValue(MathTools.random(0, 100000));
  }
}
