import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CountryTools} from '../../common-browser/helpers/country.tools';
import {NxtFormControl} from '../../nxt-form/nxt.form-control';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {AutocompleteComponent} from '../../components/form-controls/autocomplete/autocomplete.component';

@Component({
    selector: 'nxt-country-select-component',
    templateUrl: './country-select.component.html',
    styleUrls: ['./country-select.component.scss'],
    imports: [AutocompleteComponent]
})

export class CountrySelectComponent implements OnInit {

  constructor() {
  }

  @Input() set nxtFormControl(formControl: NxtFormControl) {
    this._nxtFormControl = formControl;
    this.initFormControlListener();
  }

  @Input() set value(code: string) {
    if (!this._nxtFormControl) {
      this.nxtFormControl = new NxtFormControl(code);
    }
    if (this.autocompleteValue?.code !== code) {
      this._nxtFormControl.setValue(code);
    }
  }

  private _nxtFormControl: NxtFormControl;
  @Output() valueChange = new EventEmitter<string | undefined>();

  countries = CountryTools.list;
  public autocompleteValue: { code: string, name: string } | undefined;
  @Input() placeholder = '';

  displayInOptionWithCountry = (country, highlight: (string) => string) => {
    if (!country) {
      return '';
    }
    return highlight(country.name);
  }

  displayWithCountry = (country) => {
    if (!country) {
      return '';
    }
    return country.name ?? country.name;
  }


  ngOnInit() {

  }

  public optionClicked(event: MatAutocompleteSelectedEvent) {
    const code = event.option.value.code;
    if (code !== this.autocompleteValue?.code) {
      this._nxtFormControl.setValue(code);
    }
  }

  private initFormControlListener() {
    this._nxtFormControl.valueChanges.subscribe((code: string) => {
      if (code !== this.autocompleteValue?.code) {
        this.autocompleteValue = CountryTools.list.find(c => c.code === code);
        this.valueChange.emit(code);
      }
    });
  }
}
