import {Component, OnInit, Optional, signal} from '@angular/core';
import {BodyPutService} from '../../../services/body-put.service';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {DialogService} from '../../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {InputComponent} from '../../form-controls/input/input.component';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {Money2Pipe} from '../../../pipes/money-2.pipe';
import {MathTools} from '../../../common-browser/helpers/math.tools';


export interface NxtPiercingChangeBook {
  workFromUs: boolean;
  jewelryState: 'fromUs' | 'fromUsSterile' | 'own' | undefined;
  // articleOwn: boolean;
  bodyPutPiercing: any;
  articlePrice: number;
  artistPercentage: number;
  eventPrice: number;
  // sterileJewelryFromUs: boolean;
}

@Component({
  selector: 'nxt-piercing-change-book',
  templateUrl: './piercing-change-book.component.html',
  styleUrls: ['./piercing-change-book.component.scss'],
  imports: [FlexModule, SlideToggleComponent, InputComponent, NgIf, NxtButtonIconComponent, NxtButtonComponent, MoneyPipe, MatButtonToggle, MatButtonToggleGroup, Money2Pipe],
})

export class PiercingChangeBookComponent implements OnInit {
  totalIncome = 0;

  constructor(
    @Optional() public dialogRef: MatDialogRef<PiercingChangeBookComponent>,
    private bodyPutService: BodyPutService,
    private dialogService: DialogService,
  ) {
  }

  public piercings = signal<NxtPiercingChangeBook[]>([]);

  piercingArticlePrice = 15;

  required = ValidatorTools.requiredAndNotNaN;


  ngOnInit() {
    this.addPiercing();
    this.calcIncoming();
  }

  addPiercing() {
    this.piercings.set([...this.piercings(), {
      workFromUs: false,
      jewelryState: undefined,
      bodyPutPiercing: '',
      articlePrice: -1,
      artistPercentage: -1,
      eventPrice: -1,
    }]);
    this.calcIncoming();
  }

  bodyPutPiercingCellRenderer = (value: any) => {
    if (value) {
      value = value.filter(p => p !== 'on');
      return this.bodyPutService.getGermanPathBodyPutPiercing(value);
    }
    return '';
  };

  public async bodyPutPiercingClick(index: number) {
    const result = await this.bodyPutService.showBodyPutPiercingChooser();
    if (result) {
      this.piercings.update((currentPiercings) => {
        const updatedPiercings = [...currentPiercings];
        updatedPiercings[index].bodyPutPiercing = result;
        return updatedPiercings;
      });
    }
  }

  public removePiercing() {
    this.piercings.update(piercings => {
      piercings.pop();
      return piercings;
    });
  }

  calcIncoming() {
    this.totalIncome = 0;
    this.piercings().forEach(p => {
      p.articlePrice = 0;
      if (p.jewelryState === 'fromUs') {
        p.articlePrice = 15;
      } else if (p.jewelryState === 'fromUsSterile') {
        p.articlePrice = 5;
      }
      p.artistPercentage = p.workFromUs ? 100 : 50;
      p.eventPrice = p.workFromUs ? 5 : 10;
      this.totalIncome += p.articlePrice;
      this.totalIncome += p.eventPrice;
    }, 0);
    this.totalIncome = MathTools.roundMoney(this.totalIncome);
  }

  public validate() {
    const lines: string[] = [];
    for (const [index, piercing] of this.piercings().entries()) {
      if (!piercing.bodyPutPiercing) {
        lines.push((index + 1) + '. Piercing: Körperstelle fehlt');
      }
      if (!piercing.jewelryState) {
        lines.push((index + 1) + '. Piercing: Schumckherkunft fehlt');
      }
    }
    if (lines.length > 0) {
      this.dialogService.showOk(lines.join('\n'));
      return false;
    }
    return true;
  }

  public save() {
    if (this.validate()) {
      const result = this.getResult();
      this.dialogRef.close(result);
    }
  }

  private getResult() {
    const articlePrice = this.piercings().reduce((sum, p) => sum + p.articlePrice, 0);
    const eventPrice = this.piercings().reduce((sum, p) => sum + p.eventPrice, 0);
    const artistGet = this.piercings().reduce((sum, p) => sum + (p.eventPrice * p.artistPercentage) / 100, 0);
    const artistPercentage = artistGet / eventPrice * 100;
    const textLines: string[] = [];


    /*const workFromUs = this.piercings.filter(p => p.workFromUs);
    const workNotFromUs = this.piercings.filter(p => !p.workFromUs);
    const own = this.piercings.filter(p => p.jewelryState === 'own');
    const fromUs = this.piercings.filter(p => p.jewelryState === 'fromUs');
    const fromUsSterile = this.piercings.filter(p => p.jewelryState === 'fromUsSterile');
    if (workFromUs.length > 0) {
      textLines.push(workFromUs.length + 'x bei uns gestochen');
    }
    if (workNotFromUs.length > 0) {
      textLines.push(workFromUs.length + 'x nicht bei uns gestochen');
    }
    if (workNotFromUs.length > 0) {
      textLines.push(own.length + 'x eigener Schmuck');
    }
    if (fromUs.length > 0) {
      textLines.push(own.length + 'x NXT Schmuck');
    }
    if (fromUsSterile.length > 0) {
      textLines.push(own.length + 'x NXT steriler Schmuck');
    }*/
    for (const piercing of this.piercings()) {
      let text = '';
      if (piercing.workFromUs) {
        text += 'bei uns gestochen';
      } else {
        text += 'nicht bei uns gestochen';
      }
      if (piercing.jewelryState === 'own') {
        text += ' + eigener Schmuck';
      } else if (piercing.jewelryState === 'fromUs') {
        text += ' + NXT Schmuck';
      } else if (piercing.jewelryState === 'fromUsSterile') {
        text += ' + NXT steriler Schmuck';
      }
      textLines.push(text);
    }
    const text = 'Wechsel: ' + textLines.join(' | ');
    return {eventPrice, artistPercentage, bodyPutsPiercing: this.piercings().map(p => p.bodyPutPiercing), title: text, articlePrice};
  }

  JewelryStateChanged() {
    this.calcIncoming();
  }
}
