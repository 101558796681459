import {Component, Input, OnInit, Optional} from '@angular/core';
import {NxtWhatsAppFastAnswer} from '../../../../common-interfaces/nxt-whatsapp-text.interface';
import {UuidTools} from '../../../../common-browser/helpers/uuid.tools';
import {ConfigService} from '../../../../services/config.service';
import {SocketService} from '../../../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../../../services/dialog.service';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {TextareaComponent} from '../../../form-controls/textarea/textarea.component';
import {RadioComponent} from '../../../form-controls/radio/radio.component';
import {InputComponent} from '../../../form-controls/input/input.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-whatsapp-fast-answer-edit',
    templateUrl: './whatsapp-fast-answer-edit.component.html',
    styleUrls: ['./whatsapp-fast-answer-edit.component.scss'],
    imports: [FlexModule, InputComponent, RadioComponent, TextareaComponent, NxtButtonComponent]
})

export class WhatsappFastAnswerEditComponent implements OnInit {

  @Input() whatsappFastAnswer: NxtWhatsAppFastAnswer;
  @Input() isNew: boolean;
  @Input() allWhatsappFastAnswers: NxtWhatsAppFastAnswer[];

  public langs = this.configService.config.value.langs;
  public currentLang = 'de';

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
    private configService: ConfigService,
    private socketService: SocketService,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    if (!this.whatsappFastAnswer) {
      this.whatsappFastAnswer = {
        id: UuidTools.generate(),
        shortcut: '',
        texts: {}
      };
    }
    for (const lang of this.langs) {
      if (!this.whatsappFastAnswer.texts[lang.value]) {
        this.whatsappFastAnswer.texts[lang.value] = '';
      }
    }
  }

  public async save() {
    if (this.validate()) {
      if (this.isNew) {
        await this.socketService.insertWhatsappFastAnswer(this.whatsappFastAnswer);
      } else {
        await this.socketService.updateWhatsappFastAnswer(this.whatsappFastAnswer);
      }

      this.dialogRef.close();
    }
  }

  validate() {
    const lines: string[] = [];
    if (!this.whatsappFastAnswer.shortcut) {
      lines.push('Kürzel fehlt');
    }
    for (const lang of this.langs) {
      if (!this.whatsappFastAnswer.texts[lang.value]) {
        lines.push('Text in ' + lang.text + ' fehlt');
      }
    }
    if (lines.length > 0) {
      this.dialogService.showOk(lines.join('\n'));
      return false;
    }
    return true;
  }
}
