import {Component} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {QRCodeComponent} from 'angularx-qrcode';

@Component({
  template: `
    <div fxFlex fxLayoutAlign="center center" fxLayout="column" class="nxt-page">
      <div class="p-2" *ngIf="qrData">
        <qrcode colorLight="#2A2A2A" colorDark="#FFF" [qrdata]="qrData" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
      </div>
    </div>
  `,
  styles: [`
      :host {
          height: 100%;
          width: 100%;
      }`],
  imports: [FlexModule, NgIf, QRCodeComponent],
  standalone: true,
})
export class QrCodeComponent {


  qrData;

  constructor(
    private socketService: SocketService,
  ) {

  }


}
