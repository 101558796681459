<nxt-form-field-wrapper [nxtFormControl]="nxtFormControl" [width]="width" [class]="bottomNoPadding ? 'bottom-no-padding': ''">
  @if (showJumpMonths) {
    <mat-icon
      class="cursor-pointer"
      style="margin-right: 9px;"
      (click)="addMonth(-1)"
      matTooltip="-1 Monat"
    >arrow_back
    </mat-icon>
  }

  <mat-form-field class="mat-form-field-full-width" [ngStyle]="{width: formFieldWidth}">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-date-range-input [rangePicker]="rangePicker" (close)="closed($event)" (change)="changed($event)">
      <input [readOnly]="true" #clickElement #controlElement matStartDate placeholder="Datum von" [(ngModel)]="valueFrom" (ngModelChange)="emitChange()"
             (click)="open($event)">
      <input [readOnly]="true" matEndDate placeholder="Datum bis" [(ngModel)]="valueTill" (ngModelChange)="emitChange()" (click)="open($event)">
    </mat-date-range-input>
    <div style="position: absolute; right: 0; bottom: 0">
      <mat-icon *ngIf="showClearIcon" aria-hidden="false" aria-label="clear" (click)="clearInput($event)">clear</mat-icon>
    </div>
    <mat-datepicker-toggle *ngIf="false" matSuffix [for]="rangePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #rangePicker>
      <mat-date-range-picker-actions>
        <div fxLayout="column" fxLayoutGap="4px">
          <div fxLayout="row" fxLayoutAlign="space-between">
            <nxt-button (click)="lastWeekClicked()">letzte Woche</nxt-button>
            <nxt-button (click)="thisWeekClicked()">diese Woche</nxt-button>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4px">
            <nxt-button (click)="lastXMonthClicked(5)">{{ monthBack5 }}</nxt-button>
            <nxt-button (click)="lastXMonthClicked(4)">{{ monthBack4 }}</nxt-button>
            <nxt-button (click)="lastXMonthClicked(3)">{{ monthBack3 }}</nxt-button>
            <nxt-button (click)="lastXMonthClicked(2)">{{ monthBack2 }}</nxt-button>
            <nxt-button (click)="lastXMonthClicked(1)">{{ monthBack1 }}</nxt-button>
            <nxt-button (click)="thisMonthClicked()">{{ thisMonthText }}</nxt-button>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between">
            <nxt-button (click)="lastXYearClicked(3)">{{ yearBack3 }}</nxt-button>
            <nxt-button (click)="lastXYearClicked(2)">{{ yearBack2 }}</nxt-button>
            <nxt-button (click)="lastXYearClicked(1)">{{ yearBack1 }}</nxt-button>
            <nxt-button (click)="lastXYearClicked(0)">{{ yearBack0 }}</nxt-button>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between">
            <nxt-button mat-button matDateRangePickerCancel>Abbrechen</nxt-button>
            <nxt-button mat-raised-button color="primary" matDateRangePickerApply>OK</nxt-button>
          </div>
        </div>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
  </mat-form-field>

  @if (showJumpMonths) {
    <mat-icon
      class="cursor-pointer"
      style="margin-right: 9px;"
      (click)="addMonth(1)"
      matTooltip="+1 Monat"
    >arrow_forward
    </mat-icon>
  }
  <!--


    <mat-date-range-input [rangePicker]="rangePicker">
      <input matStartDate placeholder="Start date">
      <input matEndDate placeholder="End date">
    </mat-date-range-input>


    <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
    <mat-date-range-picker  #rangePicker></mat-date-range-picker>
    <mat-date-range-picker-actions>
      <button mat-button matDateRangePickerCancel>Cancel</button>
      <button mat-raised-button color="primary" matDateRangePickerApply>OK</button>
      <button (click)="thisMonthClicked()">dieser Monat</button>
    </mat-date-range-picker-actions>-->

  <!--<mat-datepicker #datePicker></mat-datepicker>
  <mat-error *ngIf="showInvalidError">
    <div *ngIf="nxtFormControl.errors.required">
      bitte eintragen!
    </div>
  </mat-error>
</mat-form-field>-->
</nxt-form-field-wrapper>
