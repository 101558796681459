import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../../services/socket/socket.service';
import {InputComponent} from '../../../../components/form-controls/input/input.component';
import {FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {NxtTypedFormControl} from '../../../../nxt-form/nxt.typed-form-control';
import {TextareaComponent} from '../../../../components/form-controls/textarea/textarea.component';
import {FileUploadComponent} from '../../../../components/file-upload/file-upload.component';
import {DialogService} from '../../../../services/dialog.service';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {WithoutNxtDbFields} from '../../../../common-interfaces/nxt.db-fields.interface';
import {IbPromotion} from '../../../../common-interfaces/ink-back/ib.interface';
import {DriveTools} from '../../../../common-browser-public/helpers/drive.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {DownloadService} from '../../../../services/download.service';
import {MatIcon} from '@angular/material/icon';
import {BoxComponent} from '../../../../components/box/box.component';
import {FlexModule} from 'ngx-flexible-layout';
import {SlideToggleComponent} from '../../../../components/form-controls/slide-toggle/slide-toggle.component';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {FormTools} from '../../../../services/form.tools';

@Component({
  selector: 'nxt-ib-promotion-edit',
  templateUrl: './ib-promotion-edit.component.html',
  styleUrls: ['./ib-promotion-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    TextareaComponent,
    FileUploadComponent,
    NxtButtonComponent,
    MatIcon,
    BoxComponent,
    FlexModule,
    SlideToggleComponent,
    MatButtonToggle,
    MatButtonToggleGroup,
    ReactiveFormsModule,
  ],
  standalone: true,
})

export class IbPromotionEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  imgSrc = signal<string>('');
  imgArrayBuffer = signal<ArrayBuffer | null>(null);
  imgArrayBufferChanged = signal(false);
  originalPromotion = signal<IbPromotion | null>(null);

  /*** Injections ***/
  private dialogService = inject(DialogService);
  private socketService = inject(SocketService);
  private dialogRef = inject(MatDialogRef);
  private downloadService = inject(DownloadService);

  /*** Injections ***/

  /*** Variable ***/
  form = new FormGroup({
    name: new NxtTypedFormControl('', [Validators.required], 'Name'),
    active: new NxtTypedFormControl(false, [Validators.required], 'Aktiv'),
    description: new NxtTypedFormControl('', [Validators.required], 'Beschreibung'),
    code: new FormGroup({
      x: new NxtTypedFormControl<number>(0, [Validators.required], 'Code-X'),
      y: new NxtTypedFormControl<number>(0, [Validators.required], 'Code-Y'),
      fontSize: new NxtTypedFormControl(10, [Validators.required], 'Code-Schrift-Größe'),
      fontColor: new NxtTypedFormControl('#FFFFFF', [Validators.required], 'Code-Schrift-Farbe'),
      textAlign: new NxtTypedFormControl<'start' | 'end' | 'center'>('start', [Validators.required], 'Code-Schrift-Ausrichtung'),
      fontWeight: new NxtTypedFormControl<'normal' | 'bold'>('normal', [Validators.required], 'Code-Schrift-Breite'),
    }),
    footer: new FormGroup({
      x: new NxtTypedFormControl<number>(0, [Validators.required], 'Footer-X'),
      y: new NxtTypedFormControl<number>(0, [Validators.required], 'Footer-Y'),
      fontSize: new NxtTypedFormControl(10, [Validators.required], 'Footer-Schrift-Größe'),
      fontColor: new NxtTypedFormControl('#FFFFFF', [Validators.required], 'Footer-Schrift-Farbe'),
      textAlign: new NxtTypedFormControl<'start' | 'end' | 'center'>('start', [Validators.required], 'Footer-Schrift-Ausrichtung'),
      fontWeight: new NxtTypedFormControl<'normal' | 'bold'>('normal', [Validators.required], 'Footer-Schrift-Breite'),
    }),
    imgDriveFileId: new NxtTypedFormControl('', [], ''),
    discountPromotion: new FormGroup({
      discountType: new NxtTypedFormControl<'priceLike' | 'priceFix' | 'percentage' | null>('priceFix', [Validators.required], 'Rabatt-Typ'),
      priceValue: new NxtTypedFormControl<number>(null, [Validators.required], 'Preis'),
      discountPercentage: new NxtTypedFormControl(0, [], 'Rabatt'),
      artistGet: new NxtTypedFormControl<number>(null, [Validators.required], 'Artist bekommt'),
      disableDiscountedGiftCards: new NxtTypedFormControl(false, [], 'Keine rabattierten Gutscheine'),
      additionalPayAfterEnd: new NxtTypedFormControl(false, [], 'Nachkassieren'),
    }),
    validDays: new NxtTypedFormControl<number>(30, [Validators.required], 'Code gültig in Tagen'),
  });
  private mouseClickSetPos: 'footer' | 'code' | null = null;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  async load(promotionId: string) {
    if (promotionId === 'new') {

    } else {
      this.originalPromotion.set(await this.socketService.ib.getPromotion(promotionId));
      this.setForm(this.originalPromotion());
    }
    this.form.controls.code.valueChanges.subscribe(() => this.showTestCode());
    this.form.controls.footer.valueChanges.subscribe(() => this.showTestCode());
    // this.form.controls.codePosY.valueChanges.subscribe(() => this.showTestCode());
    // this.form.controls.codeFontColor.valueChanges.subscribe(() => this.showTestCode());
    // this.form.controls.codeFontSize.valueChanges.subscribe(() => this.showTestCode());
  }

  async onFileDropped(files: File[]) {
    if (files.length !== 1) {
      this.dialogService.showOk('Nur eine Datei!').then();
      return;
    }
    const file = files[0];
    this.imgSrc.set(URL.createObjectURL(file));
    this.imgArrayBuffer.set(await file.arrayBuffer());
    this.imgArrayBufferChanged.set(true);
  }

  getPromotionFromForm(): WithoutNxtDbFields<IbPromotion> {
    const promotion = this.form.getRawValue();
    if (promotion.discountPromotion.discountType === 'percentage') {
      promotion.discountPromotion.priceValue = null;
      promotion.discountPromotion.artistGet = null;
    } else {
      promotion.discountPromotion.discountPercentage = null;
    }
    return promotion;
  }

  async saveClicked() {
    this.save().then();
  }

  async save() {
    if (FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      this.dialogService.showLoading('Angebot wird gespeichert...');
      const promotion = this.getPromotionFromForm();
      if (this.originalPromotion()) {
        const imgArrayBuffer = this.imgArrayBufferChanged() ? this.imgArrayBuffer() : null;
        await this.socketService.ib.updatePromotion({...this.originalPromotion(), ...promotion}, imgArrayBuffer);
      } else {
        await this.socketService.ib.createPromotion(promotion, this.imgArrayBuffer());
      }
      this.dialogRef.close(true);
      this.dialogService.hideLoading();
    }
  }

  async cancelClicked() {
    this.dialogRef.close();
  }

  private async setForm(promotion: IbPromotion) {
    FormTools.setForm(this.form, promotion);
    this.imgSrc.set(DriveTools.getDriveLink(promotion.imgDriveFileId));
    this.imgArrayBuffer.set(await this.downloadService.fetchImageAsArrayBuffer(this.imgSrc()));
    // await this.showTestCode();
  }

  async showTestCode() {
    const arrayBuffer = await this.socketService.ib.getTestPromotionImage(this.getPromotionFromForm(), this.imgArrayBuffer());
    const blob = new Blob([arrayBuffer], {type: 'image/jpeg'});
    const url = URL.createObjectURL(blob);
    this.imgSrc.set(url);
  }

  imgClicked(event: MouseEvent) {
    if (this.mouseClickSetPos) {
      const img = event.target as HTMLImageElement;
      const imageScale = img.naturalWidth / img.width;
      if (this.mouseClickSetPos === 'code') {
        this.form.controls.code.controls.y.setValue(Math.round(imageScale * event.offsetY));
        this.form.controls.code.controls.x.setValue(Math.round(imageScale * event.offsetX));
      } else if (this.mouseClickSetPos === 'footer') {
        this.form.controls.footer.controls.y.setValue(Math.round(imageScale * event.offsetY));
        this.form.controls.footer.controls.x.setValue(Math.round(imageScale * event.offsetX));
      }
      this.showTestCode().then();
      this.mouseClickSetPos = null;
    }
  }

  setPosX(type: 'footer' | 'code', number: number) {
    if (type === 'code') {
      this.form.controls.code.controls.x.setValue(Math.round(this.form.controls.code.controls.x.value + number));
    } else if (type === 'footer') {
      this.form.controls.footer.controls.x.setValue(Math.round(this.form.controls.footer.controls.x.value + number));
    }
  }

  setPosY(type: 'footer' | 'code', number: number) {
    if (type === 'code') {
      this.form.controls.code.controls.y.setValue(Math.round(this.form.controls.code.controls.y.value + number));
    } else if (type === 'footer') {
      this.form.controls.footer.controls.y.setValue(Math.round(this.form.controls.footer.controls.y.value + number));
    }
  }

  public setPosClicked(type: 'footer' | 'code') {
    this.mouseClickSetPos = type;
    this.dialogService.showOk('Klick ins Bild');
  }

  public clearImageClicked() {
    this.imgSrc.set('');
    this.imgArrayBuffer.set(null);
  }
}

