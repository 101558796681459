import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {FlexModule} from 'ngx-flexible-layout/flex';


@Component({
    selector: 'nxt-star-cell-renderer',
    template: `
    <div class="h-100" fxLayoutAlign="start center" >
      <div fxFlex fxLayout="row" style="position:relative; height:18px">
        <div class="bar" [style]="{width: percentage + '%', backgroundColor: color}">&nbsp;</div>
      </div>
    </div>
  `,
    styles: [`
    .bar {
      border-radius: 3px;
      min-width: 3px;
    }
  `],
    imports: [FlexModule]
})
export class BarCellRenderer implements ICellRendererAngularComp {
  percentage: number;
  color = '#ccc';

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.percentage = params.value;
    // this.color = this.percentage < 30 ? ColorTools.Red : (this.percentage < 80 ? ColorTools.Orange : ColorTools.Green);
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
