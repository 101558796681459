<table class="w-full" *ngIf="config">
  <tr>
    <td>Account-ID</td>
    <td>
      <nxt-input [(value)]="config.accountNumber" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>E-Mail</td>
    <td>
      <nxt-input [(value)]="config.email" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Client ID</td>
    <td>
      <nxt-input [(value)]="config.clientId" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Secret</td>
    <td>
      <div fxLayout="row">
        <nxt-input [(value)]="config.secret" [noPadding]="true"></nxt-input>
        <nxt-button-icon (click)="openPaypalDevSite()">info</nxt-button-icon>
      </div>
    </td>
  </tr>
  <tr>
    <td>Benutzen</td>
    <td>
      <div fxLayout="row">
        <nxt-slide-toggle [(value)]="config.use"></nxt-slide-toggle>
      </div>
    </td>
  </tr>
  <tr>
  <tr>
    <td colspan="2">
      <br>
      <nxt-button style="width: 100%" (click)="testPaypalImport()">Paypal-Import testen</nxt-button>
    </td>
  </tr>
</table>
