import {Component, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtArtist} from '../../common-interfaces/nxt.artist.interface';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {SocketService} from '../../services/socket/socket.service';
import {DialogService, LoadingId} from '../../services/dialog.service';
import {ArtistEditComponent} from './artist-edit/artist-edit.component';
import {SortTools} from '../../common-browser/helpers/sort.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {ColorTools} from '../../common-browser/helpers/color.tools';
import {IconTools} from '../../common-browser/helpers/icon.tools';
import {DownloadService} from '../../services/download.service';
import {ArtistSkillComponent} from './artist-edit/artist-skill/artist-skill.component';
import {NxtFormControl} from '../../nxt-form/nxt.form-control';
import {FormBuilder, Validators} from '@angular/forms';
import {ArtistHistoryComponent} from '../../components/history/artist-history/artist-history.component';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {NgIf} from '@angular/common';
import {SlideToggleComponent} from '../../components/form-controls/slide-toggle/slide-toggle.component';
import {InputComponent} from '../../components/form-controls/input/input.component';
import {PermissionDirective} from '../../directives/permission.directive';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtPageComponent} from '../../components/nxt-page/nxt-page.component';
import {NxtPageHeaderComponent} from '../../components/nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageHeaderTitleComponent} from '../../components/nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageContentComponent} from '../../components/nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../../components/nxt-page/nxt-page-footer/nxt-page-footer.component';
import {ArtistSkillTools} from '../../common-browser/helpers/artist-skill.tools';

@Component({
    selector: 'nxt-artists',
    templateUrl: './artists.component.html',
    styleUrls: ['./artists.component.scss'],
  imports: [FlexModule, NxtButtonComponent, PermissionDirective, InputComponent, SlideToggleComponent, NgIf, NxtDatagridComponent, NxtPageComponent, NxtPageHeaderComponent, NxtPageHeaderTitleComponent, NxtPageContentComponent, NxtPageFooterComponent]
})
export class ArtistsComponent implements OnInit, OnDestroy {

  @ViewChild(NxtDatagridComponent) dataGrid: NxtDatagridComponent;
  showSkills = false;
  showInvoiceData = false;
  showDisabledArtists = false;

  filterArtistSkill: string[] = [];

  artistsAll: NxtArtist[];
  artistsFiltered: NxtArtist[];

  columnDefs: NxtColDef[];
  quickFilterText: string;


  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    @Optional() public dialogRef: MatDialogRef<ArtistsComponent>,
    private downloadService: DownloadService,
    private formBuilder: FormBuilder,
  ) {
    this.load();
    this.columnDefs = this.getStaticColDefs();
  }


  getStars(value: number) {
    let starsHtml = '';
    for (let i = 0; i < 5; i++) {
      if ((value / 2) >= i + 1) {
        starsHtml += '<img src="/assets/images/grid-cell-renderer/star.svg"/>';
      } else if ((value / 2) >= i + 0.5) {
        starsHtml += '<img src="/assets/images/grid-cell-renderer/star_half.svg"/>';
      } else {
        starsHtml += '<img src="/assets/images/grid-cell-renderer/star_border.svg"/>';
      }
    }
    return starsHtml;
  }

  getHearts(like: any) {
    let heartHtml = '';
    for (let i = 0; i < 5; i++) {
      if (like >= i + 1) {
        heartHtml += '<img src="/assets/images/grid-cell-renderer/heart.svg"/>';
      } else {
        heartHtml += '<img src="/assets/images/grid-cell-renderer/heart_border.svg"/>';
      }
    }
    return heartHtml;
  }

  getHtml(description, value, like) {
    return `
        <div style="height:22px" fxLayout="row" fxLayoutAlign="start center">
            ${this.getStars(value)}
        </div>
    `;
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  async load() {
    this.artistsAll = (await this.socketService.getArtists()).sort(SortTools.sortString('calendarNumber'));
    // this.allArtistCalendars = await this.socketService.getArtistCalendars();
    this.filterArtists();
  }

  /*showSkillsChanged() {
    const skillColsIds = this.dataGrid.api.getAllGridColumns().filter(c => c.getColId().startsWith('skill_')).map(c => c.getColId());
    this.dataGrid.api.setColumnsVisible([...skillColsIds, 'skills.speed', ...['Farbe', 'Farbe Fineline', 'Gesichtstattoo', 'Lippentattoos', 'Portrait 1zu1']], this.showSkills);
    this.dataGrid.api.resetRowHeights();
  }*/

  rowDoubleClicked(params: any) {
    this.editArtist(params.data);
  }

  async editArtist(artist?: NxtArtist) {
    const dialog = this.dialogService.showComponentDialog(ArtistEditComponent, null, {minHeight: '95vh', minWidth: '95vw'});

    if (artist) {
      dialog.componentInstance.setData(artist.id);
    } else {
      dialog.componentInstance.setData('new');
    }
    dialog.afterClosed().subscribe((result) => {
      if (result?.syncArtistFromAc) {
        this.syncFromAc();
      }
      this.load();
    });
  }

  getStaticColDefs() {
    const staticColDefs: NxtColDef<NxtArtist>[] = [
      {
        headerName: 'B',
        nxtOnCellClicked: (params) => this.editArtist(params.data),
        cellStyle: {textAlign: 'center'},
        cellRenderer: (params: any) => IconTools.Material.Edit,
        minWidth: 60, maxWidth: 60,
      },
      {
        headerName: 'H',
        nxtOnCellClicked: (params) => this.showHistory(params.data),
        cellStyle: {textAlign: 'center'},
        cellRenderer: (params: any) => IconTools.Material.History,
        minWidth: 60, maxWidth: 60,
      },
      {
        headerName: 'Kalender', field: 'calendarNumber',
        cellStyle: (params) => {
          return {color: params.data.disabled ? ColorTools.Red : '', textAlign: 'center'};
        },
        nxtFieldType: NxtFieldType.Text,
        // cellStyle: {textAlign: 'right'},
        minWidth: 80, maxWidth: 80,
      }, {
        headerName: 'Vertrag',
        nxtOnCellClicked: (params) => this.downloadContract(params.data),
        cellStyle: {textAlign: 'center'},
        cellRenderer: (params: any) => IconTools.Material.Download,
        minWidth: 70, maxWidth: 70,
      }, {
        headerName: 'Fotos',
        field: 'googlePhotosAlbumShareLink',
        cellStyle: {textAlign: 'center'},
        cellRenderer: (params: any) => params.data.googlePhotosAlbumShareLink ? IconTools.Material.Image : '',
        nxtFieldType: NxtFieldType.Icon,
        nxtOnCellClicked: (params) => {
          if (params.data.googlePhotosAlbumShareLink) {
            window.open(params.data.googlePhotosAlbumShareLink, '_blank');
          }
        },
      },
      {headerName: 'Künstlername', field: 'name', nxtFieldType: NxtFieldType.Text},
      {headerName: 'Name', field: 'invoiceData.name', nxtFieldType: NxtFieldType.Text},
      {headerName: 'RE', field: 'invoiceData.numberRange', nxtFieldType: NxtFieldType.Text},
      {headerName: 'Geburtstag', field: 'birthday', nxtFieldType: NxtFieldType.Date_germanDate},
      // {headerName: 'Geimpft / Genesen', field: 'vaccinated', nxtFieldType: NxtFieldType.Boolean},
      {headerName: 'Handy', field: 'mobile', nxtFieldType: NxtFieldType.Text},
      {
        headerName: 'Instagram', field: 'instagram', nxtFieldType: NxtFieldType.Text, nxtOnCellClicked: (params) => {
          window.open('https://instagram.com/' + params.value, '_blank');
        },
      },
      {headerName: 'Vertrag', field: 'contractSigned', nxtFieldType: NxtFieldType.Boolean},
      {headerName: 'Telegram', field: 'telegramChatId', valueGetter: params => !!params.data.telegramChatId, nxtFieldType: NxtFieldType.Boolean},
    ];
    return staticColDefs;
  }

  filterArtists() {
    this.artistsFiltered = this.artistsAll.filter(a => {
      if (!this.showDisabledArtists && a.disabled) {
        return false;
      }
      return true;
    });
  }

  async syncFromAc() {
    this.dialogService.showLoading(LoadingId.SyncArtistAc, 'Syncronisiere Artists...');
    const result = await this.socketService.syncArtistsFromAc();
    if (result.success) {
      let text = 'Sync erfolgreich';
      if (result.resultObj.allErrorLines.length > 0) {
        text = '<div class="red">' + result.resultObj.allErrorLines.join('<br/>') + '</div>';
      }
      if (result.resultObj.syncedLines.length > 0) {
        text += '<div class="green">' + result.resultObj.syncedLines.join('<br/>') + '</div>';
      }
      this.dialogService.hideLoading(LoadingId.SyncArtistAc);
      this.dialogService.showOk(text);
    } else {
      this.dialogService.hideLoading(LoadingId.SyncArtistAc);
      this.dialogService.showOk(result.error);
    }
    this.load();
  }

  private async downloadContract(artist: NxtArtist) {
    const base64 = await this.socketService.getArtistContract(artist.id);
    this.downloadService.downloadBase64('Platzmietvertrag_' + artist.name + '.pdf', base64);
  }

  private getBoolSkillCellValue(data, boolSkill: string) {
    const skill = data.skills?.boolSkillValues?.find(b => b.description === boolSkill);
    if (skill) {
      return skill.value ? 'Ja' : 'Nein';
    } else {
      return 'nicht gesetzt';
    }
  }

  showArtistSkillFilterClicked() {

    const formControl = this.formBuilder.group({
      speed: new NxtFormControl(5, Validators.min(0), 'Geschwindigkeit'),
      boolSkillValues: this.formBuilder.array(ArtistSkillTools.boolSkills.map(v => {
        return this.formBuilder.group({
          description: new NxtFormControl(v.description),
          value: new NxtFormControl(0, [], 'Skill ' + v.description),
        });
      })),
      skillValues: this.formBuilder.array(ArtistSkillTools.skills.map(v => {
        return this.formBuilder.group({
          description: new NxtFormControl(v.description),
          value: new NxtFormControl(0, Validators.min(0), 'Skill ' + v.description),
        });
      })),
    });

    const dialog = this.dialogService.showComponentDialog(ArtistSkillComponent);
    dialog.componentInstance.nxtFormControl = formControl;
  }

  private showHistory(artist: NxtArtist) {
    const dialog = this.dialogService.showComponentFull(ArtistHistoryComponent);
    dialog.componentInstance.setData(artist.id);
  }
}
