<div ngClass="nxt-page nxt-text" fxFlex fxLayout="column" fxLayoutAlign="stretch">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-2">
    <div fxLayout="row" fxLayoutGap="50px" fxLayoutAlign="start center" class="py-2">
      <div class="page-header">WhatsApp Chats</div>
      <mat-radio-group fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" [value]="sinceMinutes$ | async"
                       (change)="sinceMinutes$.next($event.value)" style="padding-left: 15px">
        <mat-radio-button style="padding-right: 15px" class="studio-radio-button" [value]="120">2 Stunden</mat-radio-button>
        <mat-radio-button style="padding-right: 15px" class="studio-radio-button" [value]="720">12 Stunden</mat-radio-button>
        <mat-radio-button style="padding-right: 15px" class="studio-radio-button" [value]="1440">1 Tag</mat-radio-button>
        <mat-radio-button style="padding-right: 15px" class="studio-radio-button" [value]="10080">1 Woche</mat-radio-button>
      </mat-radio-group>
      <nxt-slide-toggle fxHide.lt-sm [(value)]="onlyLastFromCustomer" (valueChange)="onlyUnreadChanged()">vom Kunden zu letzt</nxt-slide-toggle>
      <nxt-slide-toggle fxHide.lt-sm [(value)]="onlyUnread" (valueChange)="onlyUnreadChanged()">ungelesen</nxt-slide-toggle>
      <nxt-slide-toggle fxHide.lt-sm [(value)]="showArtists" (valueChange)="showArtistsChanged()">mit Artists</nxt-slide-toggle>
      <nxt-input style="width: 300px" fxHide.lt-sm [bottomNoPadding]="true" placeholder="Suche" [(value)]="quickFilter"
                 (valueChange)="filterChanged($event)"></nxt-input>
    </div>
    <nxt-button-icon *ngIf="dialogRef" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>

  <div fxFlex class="scrollable">
    <div
      *ngFor="let chat of chatsFiltered"
      (click)="showChat(chat)"
      fxLayoutAlign="start"
      fxLayout="row"
      fxLayout.lt-sm="column"
      class="chat-item text-90"

    >
      <div fxFlex="20%">{{ chat.name }}</div>
      <div fxFlex="20%">{{ chat.timestamp | fromNowText }}</div>
      <div class="flex flex-col justify-center">
        <div *ngIf="chat | nxtWhatsappChatUsername" class="break-word text-90" style="color: gray">{{ chat | nxtWhatsappChatUsername }}:</div>
        <div class="break-word">{{ chat | nxtWhatsappChatLastBody }}</div>
        <!--<mat-icon style="font-size: 16px; width: 16px; height: 16px; position:relative; top: 3px;">arrow_downward_alt</mat-icon>-->
      </div>
    </div>
  </div>
</div>
