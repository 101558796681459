import {Injectable} from '@angular/core';
import {DialogService} from '../../services/dialog.service';
import {WhatsappPriceDialogComponent} from './whatsapp-dialogs/whatsapp-price-dialog/whatsapp-price.dialog.component';
import {NxtWhatsAppChatPinned} from '../../common-interfaces/whats-app/nxt.whats-app-chat-pinned.interface';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {SocketService} from '../../services/socket/socket.service';
import {Browser} from 'leaflet';
import {WithoutNxtDbFields} from '../../common-interfaces/nxt.db-fields.interface';
import {LoginService} from '../../services/login.service';

@Injectable({
  providedIn: 'root',
})
export class WhatsappDialogService {

  constructor(
    private dialogService: DialogService,
    private loginService: LoginService,
    private socketService: SocketService,
  ) {
  }

  showPriceDialog() {
    return this.dialogService.showComponentDialog(WhatsappPriceDialogComponent);
  }

  async setChatPinnedState(data: { mobileWithoutPlus: string, name: string, action: 'update' | 'pin', openPayPalCheckout: boolean }) {
    const id = data.openPayPalCheckout ? (data.mobileWithoutPlus + '_openPayPalCheckout') : data.mobileWithoutPlus;
    let pinnedChat: WithoutNxtDbFields<NxtWhatsAppChatPinned> = await this.socketService.getWhatsAppChatPinned(id);
    if (!pinnedChat) {
      pinnedChat = {
        pinned: true,
        mobile: data.mobileWithoutPlus,
        info: '',
        name: data.name,
        pinnedBy: this.loginService.getUsername(),
      };
    }
    if (data.action === 'pin') {
      const result = await this.dialogService.showInput('Anpinnen', {
        message: data.name,
        okButtonText: 'Anpinnen',
        width: '40vw',
        stringCanEmpty: true,
        prompt: '',
        additionalButtons: [{text: 'Session', value: 'Session'}],
      });
      if (typeof result === 'string') {
        pinnedChat.info = result;
        pinnedChat.pinned = true;
        pinnedChat.remindAgainAt = 0;
        this.socketService.setWhatsAppChatPinnedState(pinnedChat);
      }
    } else if (data.action === 'update') {
      const buttons: { text: string, value: any }[] = [];
      if (!data.openPayPalCheckout) {
        buttons.push({text: 'Info aktualisieren', value: -2});
        buttons.push({text: '3 Tage', value: DurationTools.DURATION_1DAY * 3});
        buttons.push({text: '7 Tage', value: DurationTools.DURATION_1DAY * 7});
        buttons.push({text: '14 Tage', value: DurationTools.DURATION_1DAY * 14});
      }
      buttons.push({text: 'Lösen', value: -1});
      const result = await this.dialogService.showButtons(data.openPayPalCheckout ? 'Paypal-Chat lösen' : 'Chat lösen oder verschieben', {buttons, showCancelButton: true});
      if (result) {
        if (result.value === -2) {
          const result = await this.dialogService.showInput('Info aktualisieren', {
            message: data.name,
            okButtonText: 'Aktualisieren',
            width: '40vw',
            stringCanEmpty: true,
            prompt: pinnedChat.info,
            additionalButtons: [{text: 'Session', value: 'Session'}],
          });
          if (typeof result === 'string') {
            pinnedChat.remindAgainAt = 0;
            pinnedChat.info = result;
            this.socketService.setWhatsAppChatPinnedState(pinnedChat);
          }
        } else if (result.value === -1) {
          pinnedChat.pinned = false;
          pinnedChat.unpinnedAt = Date.now();
          pinnedChat.unpinnedBy = this.loginService.getUsername();
          this.socketService.setWhatsAppChatPinnedState(pinnedChat);
        } else {
          // verschieben
          pinnedChat.pinned = true;
          pinnedChat.remindAgainAt = DateTools.clearTime(Date.now() + result.value);
          // data.info = this.currentWhatsAppChatsPinned.find(c => c.mobile === data.mobile).info;
          pinnedChat.createdAt = Date.now();
          pinnedChat.info = await this.dialogService.showInput('Info für die Zukunft', {prompt: pinnedChat.info, stringCanEmpty: false});
          if (pinnedChat.info) {
            await this.socketService.setWhatsAppChatPinnedState(pinnedChat);
          }
        }
      }
    }

  }
}
