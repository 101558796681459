export class PdfTranslateTools {
  static texts: { [lang: string]: { [key: string]: string } } = {};
  /*public static langs: { name: string, code: string }[] = [
    {name: 'Deutsch', code: 'DE'},
    {name: 'Englisch', code: 'EN'},
    {name: 'Niederländisch', code: 'NL'},
    {name: 'Spanisch', code: 'ES'},
    {name: 'Französisch', code: 'FR'},
  ];

  static texts: { [lang: string]: { [key: string]: string } } = {};*/

  /*public static getLangName(code: string) {
    return PdfTranslateTools.langs.find(l => l.code === code)?.name;
  }

  public static getLangCode(langName: string) {
    return PdfTranslateTools.langs.find(l => l.name === langName)?.code;
  }*/

  public static init() {
    PdfTranslateTools.texts.ES = {};
    PdfTranslateTools.texts.ES.INVOICE_INVOICE = 'Factura';
    PdfTranslateTools.texts.ES.INVOICE_INVOICE_NO = 'Nr Factura';
    PdfTranslateTools.texts.ES.INVOICE_CREDIT = 'Abono';
    PdfTranslateTools.texts.ES.INVOICE_CALC = 'Liquidación';

    PdfTranslateTools.texts.ES.INVOICE_INVOICE_NO = 'Nr Factura';
    PdfTranslateTools.texts.ES.INVOICE_CUSTOMER_NO = 'Nr Cliente';
    PdfTranslateTools.texts.ES.INVOICE_ID_NUMBER = 'DNI';
    PdfTranslateTools.texts.ES.INVOICE_DATE = 'Fecha';
    PdfTranslateTools.texts.ES.INVOICE_PAGE = 'Pagina';
    PdfTranslateTools.texts.ES.INVOICE_POS_DESCRIPTION = 'Descripcion';
    PdfTranslateTools.texts.ES.INVOICE_POS_AMOUNT = 'Cantidad';
    PdfTranslateTools.texts.ES.INVOICE_POS_UNIT_PRICE = 'Unidad';
    PdfTranslateTools.texts.ES.INVOICE_POS_TOTAL = 'Total';
    PdfTranslateTools.texts.ES.INVOICE_TOTAL = 'Total';
    PdfTranslateTools.texts.ES.INVOICE_VAT_INFO = 'En el Total de {{totalGross}} (Netto: {{totalNet}}) esta incluido el IVA {{vatPercentage}} % ({{vatValue}})';
    PdfTranslateTools.texts.ES.INVOICE_GET_IN_CASH = 'recibido en efectivo';
    PdfTranslateTools.texts.ES.INVOICE_VAT_NO = 'CIF';
    PdfTranslateTools.texts.ES.INVOICE_TAX_NO = 'CIF Intracomunitario';
    PdfTranslateTools.texts.ES.INVOICE_POS_SPACE_RENTAL = 'Alquiler de espacio';


    PdfTranslateTools.texts.DE = {};
    PdfTranslateTools.texts.DE.INVOICE_INVOICE = 'Rechnung';
    PdfTranslateTools.texts.DE.INVOICE_ID_NUMBER = 'Ausweisnummer';
    PdfTranslateTools.texts.DE.INVOICE_CREDIT = 'Gutschrift';
    PdfTranslateTools.texts.DE.INVOICE_CALC = 'Abrechnung';
    PdfTranslateTools.texts.DE.INVOICE_INVOICE_NO = 'Rechnung-Nr.';
    PdfTranslateTools.texts.DE.INVOICE_CUSTOMER_NO = 'Kundennr.';
    PdfTranslateTools.texts.DE.INVOICE_DATE = 'Datum';
    PdfTranslateTools.texts.DE.INVOICE_PAGE = 'Seite';
    PdfTranslateTools.texts.DE.INVOICE_POS_DESCRIPTION = 'Bezeichnung';
    PdfTranslateTools.texts.DE.INVOICE_POS_AMOUNT = 'Menge';
    PdfTranslateTools.texts.DE.INVOICE_POS_UNIT_PRICE = 'Einzel';
    PdfTranslateTools.texts.DE.INVOICE_POS_TOTAL = 'Gesamt';
    PdfTranslateTools.texts.DE.INVOICE_POS_SPACE_RENTAL = 'Platzmiete';
    PdfTranslateTools.texts.DE.INVOICE_TOTAL = 'Gesamtbetrag';
    PdfTranslateTools.texts.DE.INVOICE_VAT_INFO = '* Im Gesamtbetrag von {{totalGross}} (Netto: {{totalNet}}) sind USt {{vatPercentage}} % ({{vatValue}}) enthalten.';
    PdfTranslateTools.texts.DE.INVOICE_GET_IN_CASH = 'in bar erhalten';
    PdfTranslateTools.texts.DE.INVOICE_VAT_NO = 'USt-IdNr.';
    PdfTranslateTools.texts.DE.INVOICE_TAX_NO = 'Steuernummer';
  }


  static getText(key: string, lang: string, params?: any) {
    if (!PdfTranslateTools.texts[lang]) {
      PdfTranslateTools.init();
    }
    if (!PdfTranslateTools.texts[lang]) {
      throw Error('getTextLang missing lang "' + lang + '"');
    }
    if (typeof PdfTranslateTools.texts[lang][key] === 'undefined') {
      throw Error('getTextLang missing key "' + key + '" in lang "' + lang + '"');
    }

    let result: string = PdfTranslateTools.texts[lang][key];
    if (params) {
      for (const key of Object.keys(params)) {
        const regex = new RegExp('{{' + key + '}}');
        result = result.replace(regex, params[key]);
      }
    }
    return result;
  }

  static test() {
    PdfTranslateTools.init();
    const text1 = PdfTranslateTools.getText('INVOICE_VAT_INFO', 'DE');
    console.log(text1);
    console.log(text1);
    const text2 = PdfTranslateTools.getText('INVOICE_VAT_INFO', 'ES', {totalGross: '500 €', totalNet: '30 €', vatPercentage: '5', vatValue: '4€'});
    console.log(text2);
    // PdfTranslateTools.getText()
  }
}
