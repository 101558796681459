<div fxLayout="column" class="h-full">
  <div fxLayoutAlign="center center" class="text-140 pb-2">
    {{ title }}
  </div>
  <div fxLayout="column" fxLayoutAlign="start center" class="p-2 scrollable h-100" fxLayoutGap="10px">
    <div fxLayout="column" class="p-2" style="border: 0 solid red" *ngFor="let order of orders">
      <table class="nxt-table">
        <tr>
          <td>Bestellung aufgegeben</td>
          <td>
            <div>{{ order.createdAt | nxtDate: 'EE dd.MM.yyyy HH:mm' }}</div>
            <div>{{ order.createdAt | fromNowText }}</div>
          </td>
        </tr>
        <tr>
          <td>Bestellt von</td>
          <td>
            <div>{{ order.createdBy }}</div>
          </td>
        </tr>
        <tr>
          <td>Bestellt bei</td>
          <td>
            <div>{{ $any(order).sourceOfSupplyText }}</div>
          </td>
        </tr>
        <tr>
          <td>Bestellmenge</td>
          <td>
            <div>{{ order.orderAmount }} x {{ item.amountPerPackage }} {{ item.unitOfMeasure }}
              = {{ order.orderAmount * item.amountPerPackage }} {{ item.unitOfMeasure }}
            </div>
          </td>
        </tr>
        <tr *ngIf="order.arrived">
          <td>Angekommen</td>
          <td>
            <div>{{ order.arrivedAt | nxtDate: 'dd.MM.yyyy HH:mm' }}</div>
          </td>
        </tr>
        <tr *ngIf="order.arrived">
          <td>Angenommen von</td>
          <td>
            <div>{{ order.arrivedBy }}</div>
          </td>
        </tr>
        <tr *ngIf="order.unitPrice">
          <td>Preis pro Packung (netto)</td>
          <td>
            <div>{{ order.unitPrice | money }}</div>
          </td>
        </tr>
        <tr *ngIf="order.unitPrice">
          <td>Gesamtpreis</td>
          <td>
            <div>{{ order.unitPrice * order.orderAmount | money }}</div>
          </td>
        </tr>
        <tr *ngIf="order.shouldArrivedAt && this.onlyNotArrived">
          <td>Sollte Ankommen</td>
          <td>
            <div class="flex flex-row justify-center items-center center">
              <mat-icon class="mouse-pointer" (click)="changeShouldArrivedAt(order, -1)">remove</mat-icon>
              <div class="mx-2">{{ order.shouldArrivedAt | nxtDate: 'dd.MM.yyyy' }} {{ order.shouldArrivedAt | fromNowText: true }}</div>
              <mat-icon class="mouse-pointer" (click)="changeShouldArrivedAt(order,1)">add</mat-icon>
            </div>
          </td>
        </tr>

        <tr *ngIf="showDeleteButton[order.id]">
          <td colspan="2" class="center">
            <nxt-button (click)="deleteOrder(order)">Bestellung Löschen</nxt-button>
            <nxt-button (click)="setNotArrivedOrder(order)">Bestellung doch nicht angekommen</nxt-button>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <nxt-button (click)="dialogRef.close()">Schließen</nxt-button>
  </div>
</div>

