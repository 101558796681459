import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, input, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtEmployee, NxtEmployeeEvent} from '../../../../../common-interfaces/nxt.employee.interface';
import {EmployeeEventTypePipe} from '../../../../../pipes/employee-event-type.pipe';
import {NxtDatePipe} from '../../../../../pipes/nxt-date-pipe';
import {NxtTimePipe} from '../../../../../pipes/nxt-time-pipe';
import {DurationPipe} from '../../../../../pipes/duration.pipe';
import {WorkplaceTools} from '../../../../../common-browser/helpers/workplace.tools';
import {NxtStudioPipe} from '../../../../../pipes/nxt-studio-pipe';

@Component({
    selector: 'nxt-employee-calendar-event-tooltip',
    templateUrl: './employee-calendar-event-tooltip.component.html',
    styleUrls: ['./employee-calendar-event-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    EmployeeEventTypePipe,
    NxtDatePipe,
    NxtTimePipe,
    DurationPipe,
    NxtStudioPipe,
  ],
})

export class EmployeeCalendarEventTooltipComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/
  employeeEvent = input.required<NxtEmployeeEvent>();
  employee = input.required<NxtEmployee>();

  duration = computed(() => {
    if (this.employeeEvent().end) {
      return this.employeeEvent().end - this.employeeEvent().start;
    }
    return Date.now() - this.employeeEvent().start;
  });

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);


  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  protected readonly WorkplaceTools = WorkplaceTools;
}
