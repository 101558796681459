import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {DialogService} from '../../services/dialog.service';
import {IframeMessageManagerInIframe} from '../../services/iframe-message-manager-in-iframe';
import {ContactFormComponent} from '../../components/contact-form/contact-form.component';

import {Log} from '../../common-browser/log/log.tools';
import {TextTools} from '../../common-browser/helpers/text.tools';
import {NxtContact} from '../../common-interfaces/nxt.contact.interface';
import {SocketService} from '../../services/socket/socket.service';
import {MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'nxt-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
  standalone: true
})
export class AddContactComponent implements OnInit {

  private editContactDialogRef: MatDialogRef<ContactFormComponent, MatDialogConfig>;

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private socketService: SocketService
  ) {
  }

  // https://test.nxt-lvl.ink/#/add-contact?givenName=Julian&familyName=Krafft&streetAddress=Lindenstraße 3&postalCode=52146&city=Würselen&email=jkrafft@nxt-lvl.ink&mobile=015120123232

  async ngOnInit() {
    Log.info(this.generateAddContactUrl());


    const queryParams = this.trimAllQueryParams(this.route.snapshot.queryParams);

    /*** queryParams --> formular **/
    const givenName = queryParams.givenName ? queryParams.givenName.trim() : '';
    const familyName = queryParams.familyName ? queryParams.familyName.trim() : '';
    const fullName = givenName + ' ' + familyName;

    const contact: NxtContact = {
      id: '',
      givenName,
      familyName,
      fullName,
      streetAddress: queryParams.streetAddress ? queryParams.streetAddress.trim() : '',
      postalCode: queryParams.postalCode ? queryParams.postalCode.trim() : '',
      city: queryParams.city ? queryParams.city.trim() : '',
      email: queryParams.email ? queryParams.email.toString().toLowerCase() : '',
      mobile: queryParams.mobile ? queryParams.mobile.trim() : '',
      birthday: queryParams.birthday ? queryParams.birthday.trim() : '',
      taglines: [],
      canceledEvents: [],
      events: [],
      closedEvents: [],
      createdAt: Date.now(),
      updatedAt: Date.now(),
      createdBy: '',
      updatedBy: '',
    };

    if (contact.mobile.startsWith('49')) {
      contact.mobile = '+' + contact.mobile;
    }
    if (contact.givenName === '' && contact.familyName === '' && queryParams.name) {
      const names = TextTools.splitNameToGivenAndFamilyName(queryParams.name);
      contact.givenName = names.givenName;
      contact.familyName = names.familyName;
    }

    IframeMessageManagerInIframe.instance.showIframe('add-contact', 'Calendar');
    this.editContactDialogRef = this.dialogService.showContactForm(contact);
    const closed = await this.editContactDialogRef.afterClosed().toPromise();
    IframeMessageManagerInIframe.instance.hideIframe('add-contact');

    // this.startGetNewContactData();
  }

  generateAddContactUrl() {
    let url = 'https://test.nxt-lvl.ink/#/add-contact?';
    url += 'givenName=' + encodeURIComponent('Julian');
    url += '&familyName=' + encodeURIComponent('Krafft');
    url += '&streetAddress=' + encodeURIComponent('Lindenstraße 3');
    url += '&postalCode=' + encodeURIComponent('52146');
    url += '&city=' + encodeURIComponent('Würselen');
    url += '&email=' + encodeURIComponent('mail@krafft.solutions');
    url += '&mobile=' + encodeURIComponent('015120123232');
    return url;
  }

  private trimAllQueryParams(queryParams: Params) {
    const newQueryParams: Params = [];
    for (const key of Object.keys(queryParams)) {
      newQueryParams[key] = queryParams[key].trim();
    }
    return newQueryParams;
  }
}


