<div class="nxt-dialog-content" fxLayout="column">
  <button style="align-self:  flex-end;" mat-icon-button (click)="cancel()" matTooltip="abbrechen">
    <mat-icon aria-hidden="false" aria-label="close">close</mat-icon>
  </button>
  <div fxLayout="100%">
    <img [src]="data.imgSrc" [ngStyle]="{width: imgWidth, height: imgHeight, margin:'auto'}"/>
  </div>

  <div class="nxt-dialog-form">
    <div>
      <nxt-input #descriptionInputElem placeholder="Beschreibung" [readonly]="descriptionReadonly" [nxtFormControl]="form.get('description')"></nxt-input>
    </div>

    <div class="nxt-dialog-button-bar">
      <div style="justify-content: space-around; display:flex;">
        <nxt-button *ngIf="showSaveButton" style="margin: auto;" (click)="save()">SPEICHERN</nxt-button>
        <nxt-button *ngIf="showDeleteButton" style="margin: auto;" (click)="delete()">LÖSCHEN</nxt-button>


      </div>
    </div>
  </div>
</div>
