import {NxtCalendarEvent, NxtWorkType} from '../../common-interfaces/nxt.calendar-event.interface';
import {ArrayTools} from './array.tools';
import {ObjectGroupTools} from './object-group.tools';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {NxtDatagridValueFormatters} from './nxt-fields/nxt-datagrid-value-formatters';

export interface NxtEventCalcItemList {
  name: string;
  items: NxtEventCalcItem<any>[];
  sortValue: any;
}


export interface NxtEventCalcItem<T> {
  id: string;
  name: string;
  value: T;
  valueFormatted?: string;
  type: NxtFieldType;
  hideIfZero?: boolean;
}

export class EventInfoCalc {

  static isNxtEventCalcItemList(obj: any): obj is NxtEventCalcItemList {
    return obj && obj.items && obj.name;
  }

  static isNxtEventCalcItemListArray(obj: any): obj is NxtEventCalcItemList[] {
    return Array.isArray(obj);
  }

  private static formatValues<T = NxtEventCalcItemList | NxtEventCalcItemList[]>(lists: T): T {
    if (EventInfoCalc.isNxtEventCalcItemList(lists)) {
      for (const item of lists.items) {
        item.valueFormatted = NxtDatagridValueFormatters.formatValueByNxtFieldType(item.value, item.type);
      }
    } else if (EventInfoCalc.isNxtEventCalcItemListArray(lists)) {
      for (const list of lists) {
        EventInfoCalc.formatValues(list);
      }
    }
    return lists;
  }

  static calcWorkType(events: NxtCalendarEvent[], workType: NxtWorkType): NxtEventCalcItemList {
    const workTypeEvents = events.filter(e => e.workType === workType);
    const items = EventInfoCalc.getEventsCalc(workTypeEvents);
    return EventInfoCalc.formatValues({name: workType.toUpperCase(), items, sortValue: workType});
  }

  static calcArtists(events: NxtCalendarEvent[]): NxtEventCalcItemList[] {
    const groupedByArtist = ObjectGroupTools.groupObjectArray(events, 'artist');
    const datas = ObjectGroupTools.groupToObjectArray(groupedByArtist, 'artist', 'events');
    let result: NxtEventCalcItemList[] = [];
    for (const artist of datas) {
      const items = EventInfoCalc.getEventsCalc(artist.events);
      result.push({items, name: artist.artist, sortValue: items.find(i => i.id === 'artistsGet')?.value});
    }
    result = result.sortNumber('sortValue', true);
    return EventInfoCalc.formatValues(result);
  }

  private static getEventsCalc(events: NxtCalendarEvent[]) {
    const eventsCanceled = events.filter(e => e.status === 'canceled');
    const eventsClosed = events.filter(e => e.status === 'closed');
    const eventsFuture = events.filter(e => e.status === 'future');

    const bodyPutsPiercingCanceled = ArrayTools.flatten(eventsCanceled.map(e => e.bodyPuts?.piercing || []));
    const bodyPutsPiercingClosed = ArrayTools.flatten(eventsClosed.map(e => e.bodyPuts?.piercing || []));
    const bodyPutsPiercingFuture = ArrayTools.flatten(eventsFuture.map(e => e.bodyPuts?.piercing || []));

    const bodyPutsTattooCanceled = ArrayTools.flatten(eventsCanceled.map(e => e.bodyPuts?.tattoo || []));
    const bodyPutsTattooClosed = ArrayTools.flatten(eventsClosed.map(e => e.bodyPuts?.tattoo || []));
    const bodyPutsTattooFuture = ArrayTools.flatten(eventsFuture.map(e => e.bodyPuts?.tattoo || []));


    const artistValue = events.reduce((sum, e) => sum + e.artistPaymentSum, 0);
    const studioValueCanceled = eventsCanceled.reduce((sum, e) => sum + (e.paymentSum - e.artistPaymentSum), 0);
    const studioValueClosed = eventsClosed.reduce((sum, e) => sum + (e.paymentSum - e.artistPaymentSum), 0);
    const studioValueFuture = eventsFuture.reduce((sum, e) => sum + (e.paymentSum - e.artistPaymentSum), 0);
    const result: NxtEventCalcItem<any>[] = [
      {id: 'customerCountCanceled', name: 'Kundenanzahl abgesagt', value: eventsCanceled.length, type: NxtFieldType.Number, hideIfZero: true},
      {id: 'customerCountClosed', name: 'Kundenanzahl fertig', value: eventsClosed.length, type: NxtFieldType.Number, hideIfZero: true},
      {id: 'customerCountFuture', name: 'Kundenanzahl offen', value: eventsFuture.length, type: NxtFieldType.Number, hideIfZero: true},
      {id: 'piercingCountCanceled', name: 'Piercings abgesagt', value: bodyPutsPiercingCanceled.length, type: NxtFieldType.Number, hideIfZero: true},
      {id: 'piercingCountClosed', name: 'Piercings fertig', value: bodyPutsPiercingClosed.length, type: NxtFieldType.Number, hideIfZero: true},
      {id: 'piercingCountFuture', name: 'Piercings offen', value: bodyPutsPiercingFuture.length, type: NxtFieldType.Number, hideIfZero: true},
      {id: 'tattooCountCanceled', name: 'Tattoos abgesagt', value: bodyPutsTattooCanceled.length, type: NxtFieldType.Number, hideIfZero: true},
      {id: 'tattooCountClosed', name: 'Tattoos fertig', value: bodyPutsTattooClosed.length, type: NxtFieldType.Number, hideIfZero: true},
      {id: 'tattooCountFuture', name: 'Tattoos offen', value: bodyPutsTattooFuture.length, type: NxtFieldType.Number, hideIfZero: true},
      {id: 'artistsGet', name: 'Artist', value: artistValue, type: NxtFieldType.Money},
      {id: 'studioGetCanceled', name: 'Studio abgesagt', value: studioValueCanceled, type: NxtFieldType.Money},
      {id: 'studioGetClosed', name: 'Studio fertig', value: studioValueClosed, type: NxtFieldType.Money},
      {id: 'studioGetFuture', name: 'Studio offen', value: studioValueFuture, type: NxtFieldType.Money},
      {id: 'firstEvent', name: 'Erstes Termin', value: events.length > 0 ? events[0].start : 0, type: NxtFieldType.Date_germanDateTime},
      {id: 'lastEvent', name: 'Letzter Termin', value: events.length > 0 ? events[events.length - 1].start : 0, type: NxtFieldType.Date_germanDateTime},
    ];
    return result;
  }
}
