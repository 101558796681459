import {Component, OnInit, Optional} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService, LoadingId} from '../../services/dialog.service';
import {LoginService} from '../../services/login.service';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {NxtInvoiceDataExternStudio} from '../../common-interfaces/nxt.invoice.interface';
import {NxtInvoiceForm} from '../../common-interfaces/nxt.invoice-form';
import {SafeResourceUrlPipe} from '../../pipes/safe-resource-url.pipe';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {JsonPipe, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-pdf-loader',
    templateUrl: './pdf-loader.component.html',
    styleUrls: ['./pdf-loader.component.scss'],
    imports: [FlexModule, NgIf, NxtButtonComponent, JsonPipe, SafeResourceUrlPipe]
})
export class PdfLoaderComponent implements OnInit {
  iframeSrc: string;
  filename: string;
  pdfBase64: string;


  constructor(
    @Optional() public dialogRef: MatDialogRef<PdfLoaderComponent>,
    private socketService: SocketService,
    private dialogService: DialogService,
    private loginService: LoginService
  ) {
  }

  public async getInvoiceExternStudio(nxtInvoiceDataFfm: NxtInvoiceDataExternStudio) {
    try {
      const data = await this.socketService.getInvoiceExternStudio(nxtInvoiceDataFfm);
      this.iframeSrc = 'data:application/pdf;base64,' + data;
      this.pdfBase64 = data.base64;
      const dateStringSortable = DateTools.parseFormat(nxtInvoiceDataFfm.invoiceDateString, 'dd.MM.yyyy', 'yyyy-MM-dd');
      this.filename = nxtInvoiceDataFfm.invoiceNumber + '.pdf';
    } catch (err) {
      if (err.message && err.message.indexOf('_Es kann keine') === 0) {
        this.dialogService.showOk(err.message, {title: 'Info'});
      } else {
        throw err;
      }
    }
  }

  public async getInvoiceFromInvoiceForm(nxtInvoiceForm: NxtInvoiceForm) {
    try {
      const data = await this.socketService.getInvoiceFromInvoiceForm(nxtInvoiceForm);
      this.iframeSrc = 'data:application/pdf;base64,' + data;
      this.pdfBase64 = data.base64;
      this.filename = nxtInvoiceForm.invoiceNumber + '.pdf';
    } catch (err) {
      if (err.message && err.message.indexOf('_Es kann keine') === 0) {
        this.dialogService.showOk(err.message, {title: 'Info'});
      } else {
        throw err;
      }
    }
  }



  download() {
    const source = this.iframeSrc;
    const downloadLink = document.createElement('a');
    const fileName = this.filename;

    downloadLink.href = source;
    downloadLink.download = fileName;
    downloadLink.click();
  }


  async ngOnInit() {
  }

  async loadData() {
  }

  close() {
    this.dialogRef.close();
  }

  async print() {
    this.dialogService.showLoading(LoadingId.PrintPdf, 'Wird gedruckt...');
    let result = await this.socketService.printPdf({studio: this.loginService.getStudio(), pdfBase64: this.pdfBase64});
    if (result === 'OK') {
      result = 'Erfolgreich zum Drucker gesendet';
    }
    this.dialogService.hideLoading(LoadingId.PrintPdf);
    await this.dialogService.showOk(result);
  }
}
