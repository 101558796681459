<div style="height: 100%" fxFlex fxLayout="column" fxLayoutAlign="stretch">
  <div fxFlex style="" fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" class="w-full">
      <div class="headline text-150" fxLayoutAlign="center center">{{options.title}}</div>
      <div *ngIf="suppressValueChange"
           fxLayoutAlign="center center"
           [ngClass]="{red: currentMoneyStackValue !== startValue}"
           fxFlex
           class="p-4">
        Geldwechseln, du musst mit {{startValue | money}} beenden
      </div>
      <div fxLayout="row">
        <div *ngIf="moneyStackInfoText && options?.payoutData?.values" fxLayout="row" fxLayoutAlign="end start" fxFlex>
          <table class="nxt-table">
            <tr *ngFor="let value of canReduceResult.values" [ngClass]="{'red' : value.missingMoney > 0}">
              <td>{{value.id}}</td>
              <td>{{value.value | money}}</td>
            </tr>
          </table>
        </div>
        <div *ngIf="moneyStackInfoText" fxFlex="20px">&nbsp;</div>
        <div [ngStyle]="{flex: !moneyStackInfoText ? '1 1 0%' : ''}" style="border: 0px solid red" fxLayout="row"
             [fxLayoutAlign]="(moneyStackInfoText) ? 'end' : 'center'">

          <nxt-money-counter-2
            [(moneyStack)]="currentMoneyStack"
            [totalMoneyShouldValue]="options.totalMoneyShouldValue"
            [showShould]="options.showShould"
            (moneyStackChange)="moneyStackChanged($event)"
            [beforeAddMoney]="beforeAddOrRemoveMoney"
            [beforeRemoveMoney]="beforeAddOrRemoveMoney"
          >
          </nxt-money-counter-2>
        </div>
        <div *ngIf="moneyStackInfoText" fxFlex="20px">&nbsp;</div>
        <div fxFlex fxLayout="column" *ngIf="moneyStackInfoText">
          <div class="text-120 pb-2" [innerHTML]="moneyStackInfoText | safeHtml"></div>
          <div>
            <div *ngIf="changeProposals.length === 0">
              Es gibt leider keine Wechselvorschläge
            </div>
            <table class="nxt-table" *ngIf="changeProposals.length > 0">
              <tr>
                <th colspan="4">Wechselvorschläge</th>
              </tr>
              <ng-container *ngFor="let changeProposal of changeProposals; let i = index">
                <tr nxtPermission="IsJulian" *ngIf="false">
                  <td colspan="4">{{changeProposal.sortValue}}</td>
                </tr>
                <tr *ngIf="i > 0">
                  <td colspan="4" class="center">ODER</td>
                </tr>
                <tr>
                  <td>
                    <div *ngFor="let key of keysSortNumber(changeProposal.reduce)">
                      <div *ngIf="key >= 1 && changeProposal.reduce[key] > 1">
                        {{changeProposal.reduce[key]}} x {{key}} €
                      </div>
                      <div *ngIf="key >= 1 && changeProposal.reduce[key] === 1">
                        {{key}} €
                      </div>
                      <div *ngIf="key < 1">
                        {{changeProposal.reduce[key]}} x {{key * 100}} Cent
                      </div>
                    </div>
                  </td>
                  <td>ZU</td>
                  <td>
                    <div *ngFor="let key of keysSortNumber(changeProposal.add)">
                      <div *ngIf="key >= 1">
                        {{changeProposal.add[key]}} x {{key}} €
                      </div>
                      <div *ngIf="key < 1">
                        {{changeProposal.add[key]}} x {{key * 100}} Cent
                      </div>
                    </div>
                  </td>
                  <td>
                    <nxt-button (click)="useProposalClicked(changeProposal)">anwenden</nxt-button>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex fxLayoutAlign="center center">
    <nxt-button (click)="close()">Abbrechen</nxt-button>
    <nxt-button *ngIf="stackBeforeUseProposal" (click)="undoUseProposalClicked()">Wechselvorgang rückgängig machen</nxt-button>
    <nxt-button (click)="save()">speichern</nxt-button>
  </div>
</div>


