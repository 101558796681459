import {Log} from '../log/log.tools';

declare const window: any;

export class BrowserTools {

  static deferredPrompt: any;

  static isBrowser() {
    try {
      return typeof window !== 'undefined' && typeof document !== 'undefined';
    } catch (e) {
      return false;
    }
  }

  static init() {

    window.addEventListener('beforeinstallprompt', (e: any) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt

      // alert('beforeinstallprompt raised!');
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      this.showPrompt();
    });
  }

  static showPrompt() {
    Log.info('deferredPrompt:', this.deferredPrompt);
    try {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          Log.info('User accepted the A2HS prompt');
        } else {
          Log.info('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
    } catch (err) {
      console.warn('showPrompt fehlgeschlagen :(', err);
    }
  }

  /*    static reload(reason) {
          Log.warn('LOCATION RELOAD - ' + reason);
          if (window.location.origin.indexOf('localhost') === -1) {

          }
      }*/

  static closePage(page: any | undefined) {
    if (page) {
      page.close();
    }
  }

  static getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(window as any).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return agent;
    }
  }
}
