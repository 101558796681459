import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtCalendarEvent} from '../../common-interfaces/nxt.calendar-event.interface';
import {SocketService} from '../../services/socket/socket.service';
import {SocketInterface} from '../../common-interfaces/socket/socket-interface';
import {SortTools} from '../../common-browser/helpers/sort.tools';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {TypeTools} from '../../common-browser/helpers/type.tools';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {DialogService} from '../../services/dialog.service';
import {EventTools} from '../../common-browser/helpers/event.tools';
import {EventsInfosComponent} from './events-infos/events-infos.component';
import {LocalStorageService} from '../../services/local-storage.service';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {DateRangePickerComponent} from '../form-controls/date-range-picker/date-range-picker.component';
import {AutocompleteComponent} from '../form-controls/autocomplete/autocomplete.component';
import {SelectComponent} from '../form-controls/select/select.component';
import {NgFor, NgIf} from '@angular/common';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import EventListFilter = SocketInterface.EventListFilter;


export type EventFilter = SocketInterface.EventListFilter & {
  options: { text: string, value: any }[];
  name: string;
  parseValue?: (value: any) => any;
};

@Component({
    selector: 'nxt-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NxtButtonIconComponent, NgFor, SelectComponent, AutocompleteComponent, DateRangePickerComponent, NxtButtonComponent, NgIf, NxtDatagridComponent]
})
export class EventsComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  @ViewChild(NxtDatagridComponent) nxtDatagridComponent: NxtDatagridComponent;
  filters: EventFilter[] = [];
  columnDef: NxtColDef<NxtCalendarEvent>[] = [
    {field: 'id', headerName: '', nxtFieldType: NxtFieldType.ShowCalendarEvent},
    {headerName: 'Termin', field: 'start', cellRenderer: (params: any) => params.data.start.dateFormat('dd.MM.yyyy HH:mm') + ' - ' + params.data.end.dateFormat('HH:mm')},
    {headerName: 'Artist', field: 'artist'},
    {headerName: 'Kunde', field: 'customerObj.fullName'},
    {headerName: 'Preis', field: 'priceEstimatedFrom', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Bezahlt', field: 'paymentSum', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Rückzahlung', field: 'paymentDepositBack', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Artist', field: 'artistPaymentSum', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Stellen', valueGetter: (params: any) => EventTools.getTattooOrPiercingCount(params.data)},
    {headerName: 'Typ', field: 'workType'},
  ];
  events: NxtCalendarEvent[] = [];
  private maxResults = 4000;

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private localStorageService: LocalStorageService,
    private dialogRef: MatDialogRef<any>,
    private cdRef: ChangeDetectorRef) {
    super();

  }


  setFiltersFromLocalStorage() {
    const filters = this.localStorageService.getByString<EventFilter[]>('events-filter');
    if (filters) {
      for (const filter of filters) {
        const filterDef = this.filters.find(f => f.field === filter.field);
        if (filterDef) {
          filterDef.value = filter.value;
        }
      }
    }
  }

  setFiltersToLocalStorage() {
    this.localStorageService.setByString('events-filter', this.filters);
  }

  async ngOnInit() {
    await this.initFilters();
    this.setFiltersFromLocalStorage();
    // this.load();
  }

  nxtOnDestroy() {

  }

  async initFilters() {
    let artists = (await this.socketService.getArtists()).map(a => ({text: a.name, value: a.name})).sort(SortTools.sortString('text'));
    artists = [{text: 'Artist offen', value: 'Artist offen'}, {text: 'Piercing offen', value: 'Piercing offen'}, ...artists];
    this.filters = [
      {
        controlType: 'date-range',
        name: 'Datum',
        field: 'start',
        value: null,
        options: artists,
        parseValue: (value) => {
          return [DateTools.parse(value.from), DateTools.parse(value.till)];
        },
        operator: 'between'
      }, {
        controlType: 'select',
        name: 'Status',
        field: 'status',
        value: '',
        options: [{text: 'Offen', value: 'future'}, {text: 'Geschlossen', value: 'closed'}, {text: 'Abgesagt', value: 'canceled'}],
        operator: '=='
      }, {
        controlType: 'select',
        name: 'Typ',
        field: 'workType',
        value: null,
        options: [{text: 'Piercing', value: 'piercing'}, {text: 'Tattoo', value: 'tattoo'}],
        operator: '=='
      }, {
        controlType: 'search-select',
        name: 'Artist',
        field: 'artist',
        value: '',
        options: artists,
        operator: '=='
      }];
    this.cdRef.detectChanges();
  }

  private getActiveFilters(): EventListFilter[] {
    return this.filters.map(f => ({
      field: f.field,
      value: f.parseValue ? f.parseValue(f.value) : f.value,
      operator: f.operator,
      controlType: f.controlType,
      name: f.name
    })).filter(f => {
      if (TypeTools.isArray(f.value)) {
        if (!f.value[0]) {
          return false;
        }
      }
      return !!f.value;
    });
  }

  async load() {
    const activeFilters = this.getActiveFilters();
    if (activeFilters.length === 0) {
      this.dialogService.showOk('Bitte wähle einen Filter aus');
      return;
    }
    this.dialogService.showLoading('Termine werden geladen');
    this.events = await this.socketService.eventList({filters: this.getActiveFilters(), maxResults: this.maxResults});
    this.setFiltersToLocalStorage();
    if (this.events.length === this.maxResults) {
      const force = await this.dialogService.showYesNo('Dein Filter ist zu schwach, es werden max ' + this.maxResults + ' Termine geladen. Bitte filtere weiter.', {
        noText: 'OK',
        yesText: 'Trotzdem weiter!\nIch mach es auch nicht oft!\nes läd dann etwas länger...'
      });
      if (force) {
        this.events = await this.socketService.eventList({filters: this.getActiveFilters(), maxResults: 20000});
      } else {
        this.events = [];
      }
    }
    if (this.events.length === 20000) {
      this.dialogService.showOk('Es sind leider immer noch zu viele Daten, filtere weiter!');
      this.events = [];
    }
    this.dialogService.hideLoading();
    this.cdRef.detectChanges();
  }

  showInfos() {
    const dialog = this.dialogService.showComponentDialog(EventsInfosComponent);
    const events: NxtCalendarEvent[] = [];
    this.nxtDatagridComponent.api.forEachNodeAfterFilter((node) => {
      events.push(node.data);
    });
    dialog.componentInstance.calc(events, this.getFilterText());
  }

  private getFilterText() {
    const lines: string[] = [];
    const activeFilters = this.getActiveFilters();
    for (const activeFilter of activeFilters) {
      switch (activeFilter.controlType) {
        case 'date-range':
          lines.push(activeFilter.name + ' von ' + activeFilter.value[0].dateFormat('dd.MM.yyyy') + ' bis ' + activeFilter.value[1].dateFormat('dd.MM.yyyy'));
          break;
        default:
          lines.push(activeFilter.name + ' ' + activeFilter.operator + ' ' + activeFilter.value);
          break;
      }
    }
    return lines.join('\n');
  }

  closeClicked() {
    this.dialogRef.close();
  }
}
