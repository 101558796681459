import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {StudioRegionBrowserTools} from '../../../common-browser/helpers/studio-region-browser.tools';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf} from '@angular/common';
import {PermissionService} from '../../../services/permission.service';
import {NxtPermissionId} from '../../../common-interfaces/nxt.user.interface';
import {DialogService} from '../../../services/dialog.service';
import {SocketService} from '../../../services/socket/socket.service';
import {PaypalRefundService} from '../../../services/refund/paypal.refund.service';
import {NxtPaymentPossibilityRecord} from '../../../common-interfaces/nxt.payment-possibility-record.interface';
import {KlarnaRefundService} from '../../../services/refund/klarna.refund.service';

@Component({
  selector: 'nxt-gift-card-record',
  templateUrl: './gift-card-record.component.html',
  styleUrls: ['./gift-card-record.component.scss'],
  imports: [NgIf, FlexModule, NgFor, NxtButtonComponent, NxtDatePipe, MoneyPipe],
  standalone: true,
})

export class GiftCardRecordComponent implements OnInit, OnChanges {

  @Input() showOpenEvent = true;
  @Input() record: NxtPaymentPossibilityRecord;

  studioRegionBrowserTools = StudioRegionBrowserTools;
  private openValue = 0;

  constructor(
    private permissionService: PermissionService,
    private dialogService: DialogService,
    private socketService: SocketService,
    private paypalRefundService: PaypalRefundService,
    private klarnaRefundService: KlarnaRefundService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calc();
  }

  ngOnInit() {

  }

  private calc() {
    this.openValue = this.record.value - this.record.assignments.reduce((sum, r) => sum + r.value, 0);
  }

  openEventClicked(eventId: string) {
    window.open(StudioRegionBrowserTools.getLinkForEvent(eventId), '_blank');
  }

  async refundClicked(record: NxtPaymentPossibilityRecord) {
    if (!['paypal', 'klarna'].includes(record.paymentMethod)) {
      this.dialogService.showOk('Geht hier nur für Klarna oder PayPal');
      return;
    }
    if (!this.permissionService.hasPermissionWithInfo(NxtPermissionId.CanRefund)) {
      return;
    }
    let text = 'Gutschein im Wert von ' + record.value.toMoneyString() + ' erstatten?';
    if (record.paymentValue < record.value) {
      text += '\n\nEs werden nur ' + record.paymentValue.toMoneyString() + ' erstatten, da der Gutschein rabattiert war';
    }
    if (record.paymentMethod === 'paypal') {
      if (record.type === 'gift-card') {
        await this.paypalRefundService.refundShopOrderItem(record.shopOrderLineItemId, record.paymentValue, record.value, text, record.id, 'gift-card');
      } else {
        this.dialogService.showOk('Unerwartet :)');
      }
    } else {
      await this.klarnaRefundService.refundShopOrderItem(record.shopOrderLineItemId, record.paymentValue, record.value, text, record.id, 'gift-card');
    }
  }
}
