import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NxtFormControl} from '../../../nxt-form/nxt.form-control';
import {InputComponent} from '../../../components/form-controls/input/input.component';
import {ShowImageOptions} from '../../../services/dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NxtButtonComponent} from '../../button/nxt-button.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgIf, NgStyle} from '@angular/common';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIconButton} from '@angular/material/button';
import {FlexModule} from 'ngx-flexible-layout/flex';


/**
 * @title Dialog Overview
 */
@Component({
    selector: 'nxt-dialog-image',
    templateUrl: 'dialog-image.component.html',
    styleUrls: ['dialog-image.component.scss'],
    imports: [
        FlexModule,
        MatIconButton,
        MatTooltip,
        MatIcon,
        NgStyle,
        ExtendedModule,
        InputComponent,
        NgIf,
        NxtButtonComponent,
    ]
})
export class DialogImageComponent implements OnInit, AfterViewInit {
  @ViewChild('descriptionInputElem', {static: true}) descriptionInputElem: InputComponent;


  imgWidth = 'auto';
  imgHeight = 'auto';
  form: UntypedFormGroup;
  showSaveButton = true;
  descriptionReadonly = false;
  showDeleteButton = false;

  ngOnInit(): void {

  }

  constructor(
    // private dialogService: DialogService,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DialogImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShowImageOptions
  ) {
    if (data.nxtImage) {
      data.imgSrc = data.nxtImage.data;
      if (!data.imgSrc.startsWith('data:')) {
        data.imgSrc = 'data:image/jpeg;base64,' + data.imgSrc;
      }
      data.description = data.nxtImage.description;
      this.showSaveButton = false;
      this.showDeleteButton = true;
      this.descriptionReadonly = true;
    }
    this.form = this.formBuilder.group({
      description: new NxtFormControl(data.description)
    });
    this.setImageSize();
  }

  close() {
    this.dialogRef.close(this.form.value);
  }

  async setImageSize() {
    const size = await this.getImageSize(this.data.imgSrc);


    const widthPercent = ((size.width / window.innerWidth) * 100);
    const heightPercent = ((size.height / window.innerHeight) * 100);


    if (widthPercent > heightPercent) {
      this.imgWidth = '50vw';
    } else {
      this.imgHeight = '50vh';
    }
  }

  async getImageSize(src): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({width: img.width, height: img.height});
      };
      img.src = src;
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  cancel() {
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.descriptionInputElem?.setFocus(), 200);
  }

  async delete() {
    /*  Log.log('frage ob bild wirklich gelöscht werden soll');
      if (await this.dialogService.showYesNo('Bild wirklich löschen?', {yesText: 'Ja, löschen'})) {
        Log.info('ja bild wirklich löschen');
        this.data.onDelete(this.data.nxtImage);*/
    // this.socketService.deleteEventImage(this.data.nxtImage.id, this.data.nxtImage.eventId);
    // this.dialogRef.close({deleted: true});
    // }
  }
}
