import {EventEmitter, Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {IframeMessageManagerInIframe} from '../iframe-message-manager-in-iframe';
import {DialogService, LoadingId} from '../dialog.service';
import {BehaviorSubject, first, Observable, Subscription} from 'rxjs';
import {Log} from '../../common-browser/log/log.tools';
import {NxtContact, NxtContactForWa, ParsedContactWithMatches} from '../../common-interfaces/nxt.contact.interface';
import {Schema$Event} from '../../common-interfaces/google.calendar.interface';
import {NxtImage} from '../../common-interfaces/nxt.image';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {NxtPaymentPossibilityRecord} from '../../common-interfaces/nxt.payment-possibility-record.interface';
import {PdfPrintData} from '../../common-interfaces/pdf-print-data.interface';
import {NxtNotAssignedPayment, NxtPayment} from '../../common-interfaces/nxt.payment.interface';
import {NxtBackofficeViewData} from '../../common-interfaces/nxt.backoffice-view-data.interface';
import {UuidTools} from '../../common-browser/helpers/uuid.tools';
import {NxtEventFinderData, NxtGetEventFinder} from '../../common-interfaces/socket/nxt.event-finder.interface';
import {NxtArtist} from '../../common-interfaces/nxt.artist.interface';
import {NxtArtistInvoice, NxtInvoiceDataExternStudio} from '../../common-interfaces/nxt.invoice.interface';
import {NxtYearFinishRow} from '../../common-interfaces/nxt.year-finish-row.interface';
import {NxtSale} from '../../common-interfaces/nxt.sale';
import {NxtInvoiceForm} from '../../common-interfaces/nxt.invoice-form';
import {NxtWebFormSubmit} from '../../common-interfaces/nxt.web-form-submit.interface';
import {NxtResult} from '../../common-interfaces/nxt.result.interface';
import {NxtUser} from '../../common-interfaces/nxt.user.interface';
import {JsonTools} from '../../common-browser/helpers/json.tools';
import {FirebaseLoginService} from '../firebase-login.service';
import {IntervalTools} from '../../common-browser/helpers/interval.tools';
import {NxtDynamicDataInsert, NxtDynamicDataType} from '../../common-interfaces/dynamic-data/dynamic-data.interface';
import {LocalStorageService} from '../local-storage.service';
import {
  NxtCalendarEvent,
  NxtCalendarEventFile,
  NxtCalendarEventToPayOnEventDate,
  NxtEventQuery,
  NxtEventRatingLog,
  UpdateCalendarEventProps,
} from '../../common-interfaces/nxt.calendar-event.interface';
import {NxtWhatsAppChatPinned} from '../../common-interfaces/whats-app/nxt.whats-app-chat-pinned.interface';
import {NxtSearchRequestInterface} from '../../common-interfaces/requests/nxt.search-request.interface';
import {NxtPrePayoutResponse} from '../../common-interfaces/nxt.pre-payout.interface';
import {NxtReminder} from '../../common-interfaces/nxt.reminder.interface';
import {SocketInterface, SocketInterfaceResponse} from '../../common-interfaces/socket/socket-interface';
import {NxtArticle} from '../../common-interfaces/nxt.article.interface';
import {NxtInventoryStockItem, NxtInventoryStockItemOrder} from '../../common-interfaces/nxt.inventory-item.interface';
import {NxtWhatsAppFastAnswer} from '../../common-interfaces/nxt-whatsapp-text.interface';
import {NxtConfig} from '../../common-interfaces/nxt.config.interface';
import {NxtPromoPromotion, NxtPromoUser} from '../../common-interfaces/nxt.promo.interface';
import {NxtTextTemplate, NxtTextTemplateType} from '../../common-browser/helpers/text-template.tools';
import {NxtPiercingManagerArtistSession, NxtPiercingManagerCustomer, NxtPiercingManagerSession} from '../../common-interfaces/nxt.piercing-manager-data.interface';
import {ConfigService} from '../config.service';
import {NxtStudioCashReportData, NxtWorkSession, NxtWorkSessionCashRegister, NxtWorkSessionCashRegisterTransfer} from '../../common-interfaces/nxt.work-session';
import {NxtWhatsAppState} from '../../common-interfaces/whats-app/nxt-whats-app-state.interface';
import {NxtPaypalRefundResult} from '../../common-interfaces/paypal/nxt.paypal-refund-result.interface';
import {NxtCashPayment} from '../../common-interfaces/nxt.cash-payment.interface';
import {NxtSocketSubscription} from './socket-subscription';
import {CacheListName} from '../cache-list.service';
import {NxtNgConfigRuntime} from '../../common-interfaces/nxt.ng-config.interface';
import {NxtArtistSpot, NxtAvailableArtistDay, NxtAvailableArtistDayArtist} from '../../common-interfaces/nxt.available-artist-day.interface';
import {NxtRefund} from '../../common-interfaces/refund.interface';
import {NxtKlarnaDispute, NxtKlarnaOrder} from '../../common-interfaces/nxt.klarna-order.interface';
import {NxtShopOrder, NxtShopOrderLineItem, NxtShopOrderWithRaw} from '../../common-interfaces/nxt.shop-order.interface';
import {NxtPaypalTransaction} from '../../common-interfaces/nxt-paypal-transaction';
import {keys} from 'lodash';
import {NxtDayFinishLog} from '../../common-interfaces/nxt-day-finish-log.interface';
import {NxtEventPhotoEvent} from '../../common-interfaces/nxt.event-photo-event.interface';
import {NxtWhatsAppChat, NxtWhatsAppMessage} from '../../common-interfaces/whats-app/nxt-whatsapp-message.interface';
import {TimeTools} from '../../common-browser/helpers/time.tools';
import {NxtDailyNote} from '../../common-interfaces/daily-note.interface';
import {NxtArtistConfirm} from '../../common-interfaces/nxt.artist-confirm.interface';
import {WithoutNxtDbFields} from '../../common-interfaces/nxt.db-fields.interface';
import {SocketAuthRequest, SocketAuthResponse} from '../../common-interfaces/socket/auth.interface';
import {NxtUserContext} from '../../common-interfaces/nxt.user-context.interface';
import {
  NxtEmployee,
  NxtEmployeeEvent,
  NxtEmployeeWeekCheck,
  NxtEmployeeWorkDay,
  NxtEmployeeWorkWeek,
  NxtEmployeeWorkWeekPayout,
  NxtWorkplace,
} from '../../common-interfaces/nxt.employee.interface';
import {
  NxtMoneyAccount,
  NxtMoneyPreTransaction,
  NxtMoneyTransaction,
  NxtMoneyTransactionCategory,
  NxtMoneyTransactionLabel,
  NxtMoneyTransactionRegion,
} from '../../common-interfaces/money-account.interface';
import {NxtTattooTemplate} from '../../common-interfaces/tattoo-template.interface';
import {NxtArtistRating1} from '../../common-interfaces/artist-rating-1.interface';
import {DriveTools} from '../../common-browser-public/helpers/drive.tools';
import {IcloudState} from '../../common-interfaces/icloud.interface';
import {NxtDriveFile, NxtDriveFileSubType} from '../../common-interfaces/drive-file.interface';
import {NxtEventFileClipboard} from '../../common-interfaces/event-file-clipboard.interface';
import {NxtTelegramChatIdGlobal} from '../../common-interfaces/telegram.interface';
import {NxtMonitorEventGlobal} from '../../common-interfaces/monitor.interface';
import {GlobalStudioShortName} from '../../common-interfaces/global-studio.interface';
import {
  NxtBankDocument,
  NxtBankDocumentStatus,
  NxtBankDocumentType,
  NxtBankTransaction,
  NxtBankTransactionStatus,
  NxtBankTransactionType,
  NxtMultiImportRunnerStatus,
} from '../../common-interfaces/bank/bank-transaction.interface';
import {BankSubSocketService} from './sub-socket-services/bank-sub-socket.service';
import {TattooTicketSubSocketService} from './sub-socket-services/tattoo-ticket-sub-socket.service';
import {EmployeeSubSocketService} from './sub-socket-services/employee-sub-socket.service';
import {NxtDiscountPromotion} from '../../common-interfaces/discount-promotion.interface';
import {IbSubSocketService} from './sub-socket-services/ib-sub-socket.service';

export interface NxtDbChangedEventId<T> {
  id: string;
  op: 'update' | 'insert' | 'delete';
  userContext: NxtUserContext;
}

export interface NxtDbChangedEvent<T> {
  record: T;
  oldRecord?: T;
  op: 'update' | 'insert' | 'delete';
  userContext: NxtUserContext;
}

export interface EmployeeData {
  employeeId: string;
  employeeFullName: string;
  workDuration: number;
  moreDuration: number;
  shouldDuration: number;
  vacationDuration: number;
  payoutDuration: number;
  sickDuration: number;
  workDays: number;
}

export interface SocketSubscribeEvents {
  eventPaypalTransactionChanged: NxtPaypalTransaction;
  getCalendarEventsWithContactStudioCashReport: any;
  getNotAssignedBankTransactions: NxtPaymentPossibilityRecord[];
  getNotAssignedPaypalTransactions: NxtPaypalTransaction[];
  getNotAssignedKlarnaOrders: NxtKlarnaOrder[];
  getTaglinesFromUnknowns: any;
  setContactDataFromWhatsApp: any;
  fromWhatsAppSetCustomerRefId: string;
  getBackofficeViewData: any;
  getArtistCalendars: any;
  getOpenWebFormSubmits: NxtWebFormSubmit[];
  getWhatsAppChatsPinned: NxtWhatsAppChatPinned[];
  subscriptionTest: any;
  getClientConnections: any;
  getIcloudState: IcloudState;
  getWhatsAppBroadcastStatus: any;
  // onNewEventFromWhatsApp: any;
  getPaypalSmsCode: any;
  getKlarnaSmsCode: any;
  getGoogleSmsCode: any;
  newEventPhotoAvailable: any;
  onReminderUpdate: any;
  getCurrentArtistPhoto: NxtEventPhotoEvent & { artistPhotoTimeDurationMin: number };
  getClientServerConnections: SocketInterfaceResponse.GetClientServerConnection[];
  eventNewWhatsappMessage: NxtWhatsAppMessage;
  eventPaymentPossibilityRecordUpdated: NxtPaymentPossibilityRecord;
  eventDynamicData_discountPromotion: NxtDiscountPromotion[];
  // eventChanged: { eventId: string, username: string };
  calendarEventChanged: NxtDbChangedEvent<NxtCalendarEvent>;
  eventDailyNoteChanged: NxtDbChangedEvent<NxtDailyNote>;
  eventArtistConfirmChanged: NxtDbChangedEvent<NxtArtistConfirm>;
  calendarEventChangedWithOldRecord: { oldRecord: NxtCalendarEvent, newRecord: NxtCalendarEvent, username: string };
  reLogin: any;
  getPiercingManagerData: any;
  studioCashReport2GetData: NxtStudioCashReportData;
  getWhatsAppState: NxtWhatsAppState;
  getDayFinishLog: NxtDayFinishLog[];
  eventUserUpdated: any;
  eventNgConfigChanged: any;
  eventShopOrderUpdated: NxtShopOrder;
  eventKlarnaOrderRawChanged: any;
  eventKlarnaOrderOutChanged: NxtKlarnaOrder;
  responseStream_sendArtistInvoicesByYear: string;
  eventRefundCreated: NxtRefund;
  getParsedContacts: ParsedContactWithMatches[];
  eventArtistChanged: { record: NxtArtist, updateType: 'remove' | 'update' | 'insert', username: string };
  eventArtistSpotChanged: { record: NxtArtistSpot, updateType: 'remove' | 'update' | 'insert', username: string };
  getArtists: NxtArtist[];
  getEmployees: NxtEmployee[];
  eventEmployeeChanged: NxtDbChangedEvent<NxtEmployee>;
  eventEmployeeEventChanged: NxtDbChangedEvent<NxtEmployeeEvent>;
  eventMoneyTransactionChanged: NxtDbChangedEvent<NxtMoneyTransaction>;
  eventMoneyAccountChanged: NxtDbChangedEvent<NxtMoneyAccount>;
  eventTelegramIdGlobalChanged: NxtDbChangedEvent<void>;
  eventWhatsAppChatChanged: NxtDbChangedEvent<NxtWhatsAppChat>;
  eventWhatsAppChatPinnedChanged: NxtDbChangedEvent<NxtWhatsAppChatPinned>;
  eventMonitorEventGlobalChanged: NxtDbChangedEvent<NxtMonitorEventGlobal>;
  eventContactChanged: NxtDbChangedEvent<NxtContact>;
  eventBankTransactionChanged: NxtDbChangedEvent<NxtBankTransaction>;
  eventBankTransactionChangedId: NxtDbChangedEventId<NxtBankTransaction>;
  eventBankDocumentChanged: NxtDbChangedEvent<NxtBankDocument>;
  eventBankDocumentChangedId: NxtDbChangedEventId<NxtBankDocument>;
  getBankImportMultiRunnerState: NxtMultiImportRunnerStatus;
  eventDiscountPromotionChanged: NxtDbChangedEvent<NxtDiscountPromotion>;
}

export type SubscribeOptions = {
  /**
   * soll es direkt nach dem subscribe ausgeführt werden?
   */
  emitInitial?: boolean,
  emitAfterReconnect?: boolean,
  emitInitialData?: any
};

@Injectable({
  providedIn: 'root',
})
export class SocketService {


  constructor(
    private socket: Socket,
    private dialogService: DialogService,
    private firebaseLoginService: FirebaseLoginService,
    private storageService: LocalStorageService,
    private configService: ConfigService,
  ) {
    SocketService.instance = this;
    this.registerPrivateSubscriptions();
    this.registerSocketConnectedEvent();
    this.registerOnSocketUnAuthentiactedListener();
    this.registerSocketConnectionChangeListener();
    this.registerUserChangedListener();
    setTimeout(() => {
      if (this.missings.length > 0) {

      }
    }, 5000);
  }

  static instance: SocketService;
  public static NODE_SERVER_VERSION: string;
  public static LOGIN_HASH: string;
  serverIsRestarting = false;
  public connectionClientId = new BehaviorSubject('');
  // public storageClientId = new BehaviorSubject(this.readClientId());

  public onAuthenticated = new EventEmitter<void>();
  private nxtSessionId = UuidTools.generateNxt();
  public sessionId: string;
  tryConnectCounter = new BehaviorSubject<number>(0);
  public onSubscribeError = new EventEmitter<string>();
  allSocketSubscriptions: NxtSocketSubscription[] = [];
  public state = new BehaviorSubject<{ authenticated: boolean, connected: boolean }>({authenticated: false, connected: false});
  private lastDisconnect = 0;
  private lastLogin: { username: string, studio: string, studioReal: string, workplace: NxtWorkplace, manualLogin: boolean };
  // private subscriptions: { eventName: string, callback: (data: any) => void }[] = [];
  private subscriptionsNew: { [eventName: string]: EventEmitter<any> } = {};
  private subscriptionsDone = false;
  public suppressSocketConnect = true;
  public onSocketUnauthorized = new EventEmitter<any>();


  // private cache: { contacts: NxtContact[] } = {contacts: []};
  public currentUser$ = new BehaviorSubject<NxtUser>(null);


  // enableConnection = false;
  disableSocket = false;
  private lastErrorType = '';
  private lastErrorTimestamp = 0;
  autoReconnectIntervalMs = 200;
  reconnectInterval: any;


  findContactsWithEventsCounter = 0;

  registerCalendarViewerInterval: any;

  authenticatedCounter = 0;

  openSubscriptions = new BehaviorSubject(0);

  missings: string[] = [];

  public bank = new BankSubSocketService(this);
  public ib = new IbSubSocketService(this);
  public tattooTicket = new TattooTicketSubSocketService(this);
  public employee = new EmployeeSubSocketService(this);

  private registerPrivateSubscriptions() {
    this.tryConnectCounter.subscribe((value) => {
      if (value > 50) {
        this.setAutoReconnectIntervalMs(10000);
      } else if (value > 10) {
        this.setAutoReconnectIntervalMs(3000);
      } else {
        this.setAutoReconnectIntervalMs(1000);
      }
    });
  }

  public async startReconnectInterval() {
    IntervalTools.clear(this.reconnectInterval);
    this.reconnectInterval = setInterval(() => {
      try {
        if (!this.state.getValue().connected && !this.disableSocket) {
          if (this.dialogService.isConnectionLostVisible) {
            let text = '';
            if (this.lastDisconnect === 0) {
              text = 'initiale Verbindung zum Server wird aufgebaut';
            } else {
              text = 'Verbindung wird wiederhergestellt...\n\nletzte Vebindung: ' + DateTools.dateDiffToNowText(this.lastDisconnect);

              if (this.lastErrorType) {
                text += '\n\nFehler: ' + this.lastErrorType;
              }

            }
            text += '\n\nVersuch:' + this.tryConnectCounter.value;
            this.dialogService.setConnectionLostDialogAdditionalMessage(text);
          }
          this.connectSocket();
        }
      } catch (err) {
        Log.error('ReconnectInterval failed', err);
      }
    }, this.autoReconnectIntervalMs);
  }

  private setAutoReconnectIntervalMs(newInterval: number) {
    if (newInterval !== this.autoReconnectIntervalMs) {
      this.autoReconnectIntervalMs = newInterval;
      this.startReconnectInterval();
      Log.debug('new AutoReconnectIntervalMs: ' + this.autoReconnectIntervalMs);
    }
  }


  async askForSocketUrl(): Promise<string> {
    return (await this.dialogService.showInputWhatsApp('socket url', {
      prompt: window.location.origin,
      showColorPicker: false,
    })).toString();
  }


  login(username: string, studio: string, studioReal: string, workplace: NxtWorkplace, manualLogin: boolean) {
    this.lastLogin = {username, studio, studioReal, workplace, manualLogin};
    this.connectSocket();
  }

  private registerOnSocketUnAuthentiactedListener() {
    this.socket.removeListener('unauthorized');
    this.socket.removeListener('unauthorized');
    this.socket.on('unauthorized', (data) => {
      this.setAuthenticated(false);
      this.onSocketUnauthorized.emit(data);
    });
  }

  registerSocketConnectedEvent() {
    this.state.subscribe((params) => {
      if (params.connected) {
        setTimeout(() => {
          this.serverIsRestarting = false;
          this.dialogService.hideLoading(LoadingId.ServerDoRestart);
        }, 5000);
      }
      if (params.connected && !params.authenticated && this.lastLogin && this.firebaseLoginService.isLoggedIn.getValue()) {
        this.startAuthentication();
      }
      if (!params.connected) {
        this.setAuthenticated(false);
      }
      if (params.connected && params.authenticated) {
        this.authenticatedCounter++;
      }
    });
    setTimeout(() => {
      this.subscribeNew('eventNgConfigChanged', () => {
        this.reloadNgConfig();
      });
    }, 2000);
  }

  private async startAuthentication() {
    this.connectionClientId.next(UuidTools.generateNxt());
    const token = await this.firebaseLoginService.getToken();
    const data: SocketAuthRequest = {
      username: this.lastLogin.username,
      token,
      studio: this.lastLogin.studio,
      studioReal: this.lastLogin.studioReal,
      workplace: this.lastLogin.workplace,
      manualLogin: this.lastLogin.manualLogin,
      forceDailyLogin: this.storageService.get('ForceDailyLogin', false),
      nxtSessionId: this.nxtSessionId,
      // storageClientId: this.storageClientId.value,
      connectionClientId: this.connectionClientId.value,
    };
    this.socket.emit('authentication', data);
    this.lastLogin.manualLogin = false;
    this.socket.removeListener('authenticated');
    this.socket.removeListener('authenticated');
    this.socket.removeListener('authenticated');

    this.socket.on('authenticated', (response: SocketAuthResponse) => {
      SocketService.LOGIN_HASH = response.loginHash;
      SocketService.NODE_SERVER_VERSION = response.serverVersion;
      DriveTools.driveGetToken = response.driveToken;
      this.currentUser$.next(response.user);
      this.configService.setConfig(response.ngConfig);
      Log.log('socket.service | authenticated');
      this.setAuthenticated(true);
      setTimeout(() => {
        this.registerSocketEventListeners();
      }, 500);
    });
  }

  private setAuthenticated(value: boolean) {
    if (value !== this.state.getValue().authenticated) {
      this.setState(this.state.value.connected, value);
    }
  }


  private registerSocketEventListeners() {
    if (!this.subscriptionsDone) {
      this.socket.on('showWhatsAppContact', (data) => {
        IframeMessageManagerInIframe.instance.send('eval', 'window.setChat(\'' + data + '\');');
      });

      /*const tempSubscriptions: any[] = ObjectTools.clone(this.subscriptions);
      this.subscriptions.length = 0;
      tempSubscriptions.forEach(sub => this.subscribe(sub.eventName, sub.callback));*/

      if (this.authenticatedCounter > 1) {
        this.allSocketSubscriptions.forEach(s => s.registerNewOnReconnected());
      }

      if (this.authenticatedCounter === 1) {
        this.allSocketSubscriptions.forEach(s => s.registerInitial());
      }
    }
  }


  private setConnected(value: boolean) {
    if (value !== this.state.getValue().connected) {
      this.setState(value, this.state.value.authenticated);
    }
  }

  private setState(connected: boolean, authenticated: boolean) {
    if (connected !== this.state.getValue().connected || authenticated !== this.state.getValue().authenticated) {
      this.state.next({connected, authenticated});
      if (authenticated) {
        setTimeout(() => this.onAuthenticated.emit(), 200);
      }
    }
  }

  public async emit(eventName: string, data?: any): Promise<any> {
    if (!this.state.value.connected || !this.state.value.authenticated) {
      if (eventName === 'subscribe') {
        this.missings.push(data);
      }
      // Log.log('SOCKET EMIT FAILS: ' + eventName);
      return;
    }
    if (eventName === 'subscribe') {
      this.missings = this.missings.filter(m => m !== data);
    }
    return new Promise(async (resolve, reject) => {
      // await this.checkConnection();
      try {
        // Log.log('SOCKET EMIT: ' + eventName);
        const result = await this.socket.emit(eventName, data ?? null, (err, response: any) => {
          if (err) {
            if (err.errorId) {
              reject(err);
            } else if (typeof err === 'string' && err.indexOf('{') === 0) {
              const errorObj = JsonTools.parse(err);
              reject(errorObj);
            } else {
              if (typeof reject !== 'function') {
                Log.onSocketError.emit(err);
              }
              const error = new Error(err);
              reject(error);
            }
          } else {
            resolve(response);
          }
        });
      } catch (err) {
        // Log.error('SOCKET EMIT FEHLER 2!');
        Log.error(err);
        reject(err);
      }
    });
  }


  async getCalendarEventWithCustomer(eventId: string): Promise<NxtCalendarEvent> {
    return this.emit('getCalendarEventWithCustomer', eventId);
  }

  async setNewContactData(data: any): Promise<any> {
    return this.emit('setNewContactData', data);
  }

  async getNewContactData(): Promise<any> {
    return this.emit('getNewContactData');
  }

  async getNotAssignedPaypalTransactions(): Promise<NxtPaypalTransaction[]> {
    return this.emit('getNotAssignedPaypalTransactions');
  }

  /*** CALENDAR **/




  async getYearFinish(data: { year: string, more: boolean }): Promise<NxtYearFinishRow[]> {
    return this.emit('getYearFinish', data);
  }

  async getOverlappedEvents(data: { eventId: string, start: number, end: number, artist: string }): Promise<NxtCalendarEvent[]> {
    return this.emit('getOverlappedEvents', data);
  }

  /*subscribe(eventName: SocketSubscribeEvents, callback) {
    if (this.subscriptions.filter(sub => sub.eventName === eventName).length > 0) {
      this.subscriptions = this.subscriptions.filter(sub => sub.eventName !== eventName);
      // this.onSubscribeError.emit('Event wird neu registriert: ' + eventName);
    }
    this.subscriptions.push({eventName, callback});
    Log.info('REGISTER SOCKET EVENT CALLBACK!!!' + eventName);
    this.socket.removeListener(eventName);
    this.socket.on(eventName, callback);
    this.emit('subscribe', eventName);
  }*/

  getObservable<
    T extends SocketSubscribeEvents[K] = any,
    K extends keyof SocketSubscribeEvents = any
  >(eventName: K): Observable<T> {
    return new Observable<T>((subscriber) => {
      const socketSubscription = this.subscribeNew<T, K>(eventName, (data) => {
        subscriber.next(data);
      }, {emitInitial: true});
      subscriber.add(() => {
        socketSubscription.unsubscribe();
      });
    });
  }

  /**
   * wenn eventName nicht mit "event" Anfängt, wird es direkt einmal ausgelöst!
   */
  subscribeNew<
    T extends SocketSubscribeEvents[K],
    K extends keyof SocketSubscribeEvents
  >(eventName: K, next: (data: T) => void, options?: SubscribeOptions): NxtSocketSubscription {
    this.openSubscriptions.next(this.openSubscriptions.value + 1);
    if (!options) {
      options = {emitInitial: false, emitInitialData: false, emitAfterReconnect: true};
    }
    if (typeof options.emitAfterReconnect !== 'boolean') {
      options.emitAfterReconnect = true;
    }
    if (!this.subscriptionsNew[eventName]) {
      this.subscriptionsNew[eventName] = new EventEmitter(true);
    }
    if (options?.emitInitial) {
      this.state.pipe(first(state => state.authenticated && state.connected)).subscribe(async () => {
        await TimeTools.sleep(100);
        const result = await this.emit(eventName, options.emitInitialData);
        if (result) {
          this.subscriptionsNew[eventName].emit(result);
        }
      });
    }
    let socketSubscription = new NxtSocketSubscription((initiator) => {
      this.socket.removeListener(eventName);
      this.socket.on(eventName, (dataFromServer) => {
        this.subscriptionsNew[eventName].emit(dataFromServer);
      });
      this.emit('subscribe', eventName);
      if (initiator === 'reconnect' && options?.emitAfterReconnect) {
        setTimeout(async () => {
          const result = await this.emit(eventName);
          this.subscriptionsNew[eventName].emit(result);
        }, 200);
      }
      return this.subscriptionsNew[eventName].subscribe(next);
    }, () => {
      if (socketSubscription?.subscription) {
        this.unsubscribeNew(socketSubscription.subscription);
      }
      this.allSocketSubscriptions = this.allSocketSubscriptions.filter((s) => s !== socketSubscription);
      socketSubscription = null;
    });
    this.allSocketSubscriptions.push(socketSubscription);
    return socketSubscription;
  }


  unsubscribeNew(subscription: Subscription) {
    this.openSubscriptions.next(this.openSubscriptions.value - 1);
    if (subscription) {
      let countBeforeUnsubscribe = -1;
      let countAfterUnsubscribe = -1;
      let eventName = '';
      for (const event of keys(this.subscriptionsNew)) {
        const foundSub = this.subscriptionsNew[event]?.observers?.find(o => (o as any) === subscription);
        if (foundSub) {
          countBeforeUnsubscribe = this.subscriptionsNew[event]?.observers.length;
          eventName = event;
          break;
        }
      }
      subscription.unsubscribe();
      if (eventName) {
        countAfterUnsubscribe = this.subscriptionsNew[eventName]?.observers.length;
        if (countAfterUnsubscribe === 0) {
          this.emit('unsubscribe', eventName);
        }
      }
    }
  }


  async unsubscribe(eventName: string): Promise<NxtPayment[]> {
    return this.emit('unsubscribe', eventName);
  }


  async createCalendarEvent(calendarId: string, calendarEvent: Schema$Event, newEventUuid, nxtCreateId: string) {
    calendarEvent.extendedProperties.private.nxtCreateId = nxtCreateId;
    return this.emit('createCalendarEvent', {calendarId, calendarEvent, newEventUuid});
  }


  async translate(text: string, to: 'de' | 'en' | 'sp' | string, from?: string): Promise<{ text: string, detectedSourceLang: string, detectedSourceLangName: string }> {
    return this.emit('translate', {text, to, from});
  }


  async sendTelegramStudioInfo(text: string) {
    return this.emit('sendTelegram', {text});
  }

  /*** END CALENDAR **/

  async getReviews(): Promise<any> {
    return this.emit('getReviews');
  }


  async getWait(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.socket.emit('getWait');
        const subscription = this.socket.fromEvent('wait').subscribe((data: string) => {
          resolve(parseInt(data, 10));
          subscription.unsubscribe();
        });
      } catch (err) {
        Log.info('getWait failed: ', err.message);
        reject('getWait failed: ' + err.message);
      }
    });
  }

  updateContact(contact: NxtContact, force: boolean) {
    return this.emit('updateContact', {contact, force});
  }

  getCode() {
    return this.emit('getCode');
  }

  createContact(contact: NxtContact) {
    return this.emit('createContact', contact);
  }

  registerAsApp(app: 'WhatsApp' | 'Calendar' | 'Paypal') {
    return this.emit('register' + app + 'Viewer');
  }

  printPdf(pdfPrintData: PdfPrintData) {
    return this.emit('createUserAction', {userAction: 'printPdf', userActionData: pdfPrintData});
  }

  public alexaTTS(devices: string[], text: string, volume: string) {
    return this.emit('alexaTTS', {devices, text, volume});
  }

  public createUserAction(data: { userAction: string, userActionData: any }) {
    return this.emit('createUserAction', data);
  }

  getWhatsAppState(): Promise<NxtWhatsAppState> {
    return this.emit('getWhatsAppState');
  }


  registerOn(eventName: string, callback: (data) => void) {
    this.socket.on(eventName, (data) => {
      callback(data);
    });
  }

  setPaypalData(data: any) {
    Log.info('SET-PAYPAL-DATA to socket-server');
    return this.emit('setPaypalData', data);
  }

  showWhatsAppContact(name: string) {
    this.socket.emit('showWhatsAppContact', name);
  }

  getWhatsAppChat(mobileOrWhatsAppId: string, reason: string): Promise<{
    whatsAppId: string,
    messages: NxtWhatsAppMessage[],
    chat: NxtWhatsAppChat,
    pinnedChat: NxtWhatsAppChatPinned
  }> {
    return this.emit('getWhatsAppChat', {mobileOrWhatsAppId, reason});
  }

  getWhatsAppChatFromClient(mobileOrWhatsAppId: string): Promise<any> {
    return this.emit('getWhatsAppChatFromClient', mobileOrWhatsAppId);
  }

  getWhatsAppImage(mediaKey: string): Promise<any> {
    return this.emit('getWhatsAppImage', mediaKey);
  }

  setCode(nextCode: string) {
    return this.emit('setCode', nextCode);
  }

  setCodeInterval(interval: number) {
    return this.emit('setCodeInterval', interval);
  }

  setWhatsAppImage(data: any) {
    return this.emit('setWhatsAppImage', data);
  }

  addEventImage(data: NxtImage) {
    return this.emit('addEventImage', data);
  }

  getEventImages(eventId: string) {
    return this.emit('getEventImages', eventId);
  }

  deleteEventImage(imageId: string, eventId: string) {
    return this.emit('deleteEventImage', {imageId, eventId});
  }

  addTaglineToNxtContact(data: { contactId: string; tagline: string }) {
    return this.emit('addTaglineToNxtContact', data);
  }

  removeTaglineFromNxtContact(data: { contactId: string; tagline: string }) {
    return this.emit('removeTaglineFromNxtContact', data);
  }

  async createPaymentPossibilityRecord(record: NxtPaymentPossibilityRecord, print: boolean) {
    return this.emit('createPaymentPossibilityRecord', {record, print});
  }

  async printFastWalkInNo(fastWalkInNo: string, studio: string, workType: string) {
    return this.emit('printFastWalkInNo', {fastWalkInNo, studio, workType});
  }

  async printSmallText(data: SocketInterface.PrintSmallText): Promise<string> {
    return this.emit('printSmallText', data);
  }

  async printQrCode(studio: string, qrCode: string, text: string, longName: boolean) {
    return this.emit('printQrCode', {qrCode, studio, text, longName});
  }

  isPrinterAvailable(studio: string) {
    return this.emit('isPrinterAvailable', studio);
  }


  /*getInvoicePdfBase64v2(eventIds: string[], invoiceDateString: string) {
    return this.emit('getInvoicePdfBase64v2', {eventIds, invoiceDateString});
  }*/

  getInvoicePdfBase64v3(eventIds: string[], invoiceDateString: string): Promise<SocketInterfaceResponse.GetInvoicePdfBase64v3> {
    return this.emit('getInvoicePdfBase64v3', {eventIds, invoiceDateString});
  }

  private async connectSocket() {
    try {
      this.tryConnectCounter.next(this.tryConnectCounter.value + 1);
      if (!this.firebaseLoginService.isLoggedIn.getValue()) {
        return;
      }
      // Log.log('socket.service | socket.disconnect');
      this.socket.disconnect();
      this.setConnected(false);
      await this.registerSocketConnectionChangeListener();
      this.socket.connect();
      // Log.log('socket.service | socket.connect');
    } catch (err) {
      Log.error(err);
    }
  }


  private async registerSocketConnectionChangeListener() {

    // this.socket = new Socket({url: await this.askForSocketUrl()});
    this.socket.removeListener('connect');
    this.socket.removeListener('disconnect');
    this.socket.removeListener('close');
    this.socket.removeListener('connect_error');
    this.socket.on('connect', () => {
      this.sessionId = this.socket.ioSocket.id;
      this.lastErrorType = '';
      this.lastErrorTimestamp = 0;
      this.tryConnectCounter.next(0);
      Log.log('socket.service | socket.connected');
      this.setConnected(true);
    });
    this.socket.on('disconnect', () => {
      Log.log('socket.service | socket.disconnected');
      this.lastDisconnect = Date.now();
      this.setState(false, false);
    });
    this.socket.on('close', () => {
      Log.log('socket.service | socket.closed');
      this.setConnected(false);
    });
    this.socket.on('connect_error', (info) => {
      this.lastErrorType = info.type;
      this.lastErrorTimestamp = Date.now();
      Log.log('socket.service | socket.connect_error' + info);
      this.setConnected(false);
    });
  }

  disconnectSocket() {
    Log.log('[SOCKET-SERVICE] force disconnect from socket');
    this.socket.disconnect();
  }


  sendBrudiTvAction(param: { data: string; action: string }) {
    return this.emit('brudiTvAction', param);
  }

  getAppointmentConfirmationText(lang: 'de' | 'en') {
    return this.emit('getAppointmentConfirmationText', lang);
  }

  getAppointmentConfirmationTextCanceled(lang: 'de' | 'en') {
    return this.emit('getAppointmentConfirmationTextCanceled', lang);
  }


  getAppointmentConfirmationStudioText(studio: string, lang: 'de' | 'en') {
    return this.emit('getAppointmentConfirmationStudioText', {studio, lang});
  }

  setAppointmentConfirmationText(text: string, lang: 'de' | 'en') {
    return this.emit('setAppointmentConfirmationText', {text, lang});
  }

  setAppointmentConfirmationTextCanceled(text: string, lang: 'de' | 'en') {
    return this.emit('setAppointmentConfirmationTextCanceled', {text, lang});
  }

  setAppointmentConfirmationStudioText(studio: string, text: string, lang: 'de' | 'en') {
    return this.emit('setAppointmentConfirmationStudioText', {studio, text, lang});
  }

  getVoucherPdf(value: string, code: string, createdAt: number) {
    return this.emit('getVoucherPdf', {value, code, createdAt});
  }

  getVoucherPdfByCode(code: string) {
    return this.emit('getVoucherPdfByCode', code);
  }

  setCustomerBirthdayMessageTemplate(message) {
    return this.emit('setCustomerBirthdayMessageTemplate', message);
  }

  getCustomerBirthdayMessageTemplate() {
    return this.emit('getCustomerBirthdayMessageTemplate');
  }

  setCustomerAppointmentReminderMessageTemplate(message) {
    return this.emit('setCustomerAppointmentReminderMessageTemplate', message);
  }

  getCustomerAppointmentReminderMessageTemplate() {
    return this.emit('getCustomerAppointmentReminderMessageTemplate');
  }

  addContactFromWhatsApp(data: any) {
    return this.emit('addContactFromWhatsApp', data);
  }

  processWhatsAppMessage(message: string, type: 'parseContacts') {
    return this.emit('processWhatsAppMessage', {message, type});
  }

  sendTelegramAdmin(text: string) {
    return this.emit('sendTelegramAdmin', {text});
  }

  sendBackofficeHeads(text: string) {
    return this.emit('sendBackofficeHeads', {text});
  }

  sendBackofficeHeadsNoFranchise(text: string) {
    return this.emit('sendBackofficeHeads', {text, noFranchise: true});
  }

  updateUser(user: NxtUser) {
    return this.emit('updateUser', user);
  }

  sendTelegramDayFinish(user: string, studio: string, text: string) {
    text = user + ' ' + studio + '\n' + text;
    return this.emit('sendTelegramDayFinish', {text});
  }

  sendTelegramDayFinishDirect(text: string) {
    return this.emit('sendTelegramDayFinish', {text});
  }

  sendTelegramError(text: string) {
    return this.emit('sendTelegramError', {text});
  }

  async getBackofficeViewData(date: string): Promise<NxtBackofficeViewData> {
    return this.emit('getBackofficeViewData', {date});
  }

  async eventFinder(data: NxtGetEventFinder): Promise<NxtEventFinderData> {
    return this.emit('getEventFinderData', data);
  }

  async getStudioFeeData(data: SocketInterface.GetStudioFeeData) {
    return this.emit('getStudioFeeData', data);
  }

  setStudioFeeMonthData(data: { costsGross: number; costs_: number; monthYear: string }) {
    return this.emit('setStudioFeeMonthData', data);
  }

  getArtists(): Promise<NxtArtist[]> {
    return this.emit('getArtists');
  }

  getBigPayoutArtists(): Promise<string[]> {
    return this.emit('getBigPayoutArtists');
  }

  upsertArtist(artist: NxtArtist) {
    return this.emit('upsertArtist', artist);
  }

  logoutUsernameAll() {
    return this.emit('logoutUsernameAll');
  }

  /*createArtistCalendar(data: { summary: string, backgroundColor?: string, info?: string }) {
    return this.emit('createArtistCalendar', data);
  }*/

  createUser(user: NxtUser, password: string) {
    return this.emit('createUser', {user, password});
  }

  deleteUser(userId: string) {
    return this.emit('deleteUser', userId);
  }

  async getInvoiceExternStudio(nxtInvoiceDataFfm: NxtInvoiceDataExternStudio) {
    return this.emit('getInvoiceExternStudio', nxtInvoiceDataFfm);
  }

  async upsertUser(user: any) {
    return this.emit('upsertUser', user);
  }

  async getTaglinesFromUnknowns() {
    return this.emit('getTaglinesFromUnknowns');
  }

  async isArtistAvailable(artist: string, dateString: string) {
    return this.emit('isArtistAvailable', {artist, dateString});
  }

  async getContactWithEvents(contactId: string): Promise<NxtContact> {
    return this.emit('getContactWithEvents', contactId);
  }


  async addSale(sale: NxtSale) {
    return this.emit('addSale', sale);
  }

  getInvoiceFromInvoiceForm(invoice: NxtInvoiceForm) {
    return this.emit('getInvoiceFromInvoiceForm', invoice);
  }

  async getOpenWebFormSubmits(): Promise<NxtWebFormSubmit[]> {
    return this.emit('getOpenWebFormSubmits', {});
  }

  async updateWebFormSubmit(webFormSubmit: NxtWebFormSubmit) {
    return this.emit('updateWebFormSubmit', webFormSubmit);
  }

  forwardWhatsAppImageToArtist(messageId: string, artistId: string) {
    return this.emit('forwardWhatsAppImageToArtist', {artistId, messageId});
  }

  forwardWhatsAppMessageToArtist(messageId: string, artistId: string, forwardOnlyImage: boolean) {
    return this.emit('forwardWhatsAppMessageToArtist', {artistId, messageId, forwardOnlyImage});
  }

  forwardWhatsAppMessageToAllAvailableArtist(messageId: string, forwardOnlyImage: boolean) {
    return this.emit('forwardWhatsAppMessageToAllAvailableArtist', {messageId, forwardOnlyImage});
  }

  forwardWhatsAppMessageToAllArtists(messageId: string, forwardOnlyImage: boolean) {
    return this.emit('forwardWhatsAppMessageToAllArtists', {messageId, forwardOnlyImage});
  }


  forwardWhatsAppImageToMobile(messageId: string, mobile: string, forwardOnlyImage: boolean) {
    return this.emit('forwardWhatsAppImageToMobile', {mobile, messageId, forwardOnlyImage});
  }

  async syncArtistsFromAc(): Promise<NxtResult> {
    return this.emit('syncArtistsFromAc', {});
  }

  triggerSubscriptionTest() {
    return this.emit('triggerSubscriptionTest', {});
  }

  triggerSubscription(name: string) {
    return this.emit('triggerSubscription', name);
  }


  async sendToJulian() {
    return this.emit('sendToJulian', {});
  }

  async getUsers(): Promise<NxtUser[]> {
    return this.emit('getUsers', {});
  }

  getUser(userId: string) {
    return this.emit('getUser', userId);
  }

  assignSourceDestPayments(source: NxtNotAssignedPayment, dest: NxtNotAssignedPayment, value: number, paymentValue: number) {
    return this.emit('assignSourceDestPayments', {source, dest, value, paymentValue});
  }

  assignOpenPayment(payment: NxtNotAssignedPayment, reasonId: string, reason: string) {
    return this.emit('assignOpenPayment', {payment, reasonId, reason});
  }


  getLatestWhatsAppMessages(sinceMinutes: number, dateTime?: number): Promise<NxtWhatsAppChat[]> {
    return this.emit('getLatestWhatsAppMessages', {sinceMinutes, dateTime});
  }

  reloadWhatsAppImage(id: string) {
    return this.emit('reloadWhatsAppImage', id);
  }

  async getDynamicData<T>(dynamicDataType: NxtDynamicDataType): Promise<T[]> {
    return this.emit('getDynamicData', dynamicDataType);
  }

  async upsertDynamicDataBatch(changedDiscountPromotions: NxtDynamicDataInsert[]) {
    return this.emit('upsertDynamicDataBatch', changedDiscountPromotions);
  }

  deleteDynamicData(dynamicDataId: string) {
    return this.emit('deleteDynamicData', dynamicDataId);
  }

  getClientConnections() {
    return this.emit('getClientConnections', {});
  }

  restartServer() {
    return this.emit('restartServer', {});
  }

  invalidate(cacheObject: string) {
    return this.emit('invalidate', cacheObject);
  }

  sendWhatsAppMessage(mobileOrWhatsAppId: string, message: string) {
    return this.emit('sendWhatsAppMessage', {mobileOrWhatsAppId, message});
  }

  sendWhatsAppDocument(mobileOrWhatsAppId: string, filename: string, base64: string) {
    return this.emit('sendWhatsAppDocument', {mobileOrWhatsAppId, filename, base64});
  }

  /*readShopOrderForce(readShopId: string) {
    return this.emit('readShopOrderForce', readShopId);
  }*/

  updateMissingArtistWhatsAppChat(familyName: string) {
    return this.emit('updateMissingArtistWhatsAppChat', familyName);
  }

  /*getInvoicesCombined(invoiceNumbers: string[]) {
    return this.emit('getInvoicesCombined', invoiceNumbers);
  }*/

  setWhatsAppChatPinnedState(data: WithoutNxtDbFields<NxtWhatsAppChatPinned>) {
    return this.emit('setWhatsAppChatPinnedState', data);
  }

  getGiftCardBySearch(filterValue: string, hasValue: boolean): Promise<NxtPaymentPossibilityRecord[]> {
    return this.emit('getGiftCardBySearch', {filterValue, hasValue});
  }

  async getPaymentPossibilityRecord(id: string): Promise<NxtPaymentPossibilityRecord | undefined> {
    return this.emit('getPaymentPossibilityRecord', id);
  }

  async updateGiftCardPaymentValue(id: string, paymentValue: number) {
    return this.emit('updateGiftCardPaymentValue', {id, paymentValue});
  }

  async getCareInstructionsText(data: { workType: 'tattoo' | 'piercing' | 'tooth-gem'; lang: 'de' | 'en' | 'fr' | 'es' | 'nl' }) {
    return this.emit('getCareInstructionsText', data);
  }

  setCareInstructionsText(text: string, workType: 'tattoo' | 'piercing' | 'tooth-gem', lang: 'de' | 'en' | 'fr' | 'es' | 'nl') {
    return this.emit('setCareInstructionsText', {text, workType, lang});
  }

  search(data: NxtSearchRequestInterface): Promise<SocketInterfaceResponse.SearchByClientResult> {
    return this.emit('search', data);
  }

  findGiftCard(name: string) {
    return this.emit('findGiftCard', {name});
  }

  getTodo(id: string) {
    return this.emit('getTodo', id);
  }

  async findContactsWithEvents(searchText: string) {
    const currentCounter = this.findContactsWithEventsCounter;
    this.findContactsWithEventsCounter++;
    Log.debug('send findContactsWithEvents #' + currentCounter);
    const result = await this.emit('findContactsWithEvents', searchText);
    Log.debug('received findContactsWithEvents #' + currentCounter + ' [length: ' + result.length + ']');
    return result;
  }

  getDiscountAnalysis(data: { startDateString: string, endDateString: string }) {
    return this.emit('getDiscountAnalysis', data);
  }

  /*async deletePaymentPossibilityRecord(id: string) {
    return this.emit('deletePaymentPossibilityRecord', id);
  }*/


  async deletePaypalTransaction(id: string) {
    return this.emit('deletePaypalTransaction', id);
  }

  sendPayoutsToArtists(artistPayouts: { artist: string; payout: number; payout_: number }[]) {
    return this.emit('sendPayoutsToArtists', artistPayouts);
  }

  getPrePayoutData(dateString: string): Promise<NxtPrePayoutResponse> {
    return this.emit('getPrePayoutData', dateString);
  }

  userIsActive(data: { href: string }) {
    return this.emit('userIsActive', data);
  }

  async alexaTTSGetVolume() {
    return this.emit('alexaTTSGetVolume', {});
  }

  canEditArtist(name: string) {
    return this.emit('canEditArtist', name);
  }

  loadWhatsAppBroadcast(broadcastId: string, messageId: string, options: SocketInterface.BroadcastOptions) {
    return this.emit('loadWhatsAppBroadcast', {broadcastId, messageId, options});
  }

  startWhatsAppBroadcast(broadcastId: string, messageId: string, options: SocketInterface.BroadcastOptions) {
    return this.emit('startWhatsAppBroadcast', {broadcastId, messageId, options});
  }

  async getWhatsAppBroadcastStatusStack() {
    return this.emit('getWhatsAppBroadcastStatusStack', {});
  }

  abortWhatsAppBroadcast() {
    return this.emit('abortWhatsAppBroadcast', {});
  }

  errorTest() {
    return this.emit('errorTest', {});
  }

  registerCalendarViewer() {
    IntervalTools.clear(this.registerCalendarViewerInterval);
    this.emit('registerCalendarViewer', {});
    this.registerCalendarViewerInterval = setInterval(() => {
      return this.emit('registerCalendarViewer', {});
    }, 20 * 1000);
  }

  /*emitNewEventFromWhatsApp(dateString: string, contactName: any) {
    return this.emit('emitNewEventFromWhatsApp', {dateString, contactName});
  }*/

  getCanceledEventsByContactId(contactId: any) {
    return this.emit('getCanceledEventsByContactId', contactId);
  }

  async getLastBroadcast(): Promise<SocketInterface.GetLastBroadcast> {
    return this.emit('getLastBroadcast', {});
  }

  getEventPhotos(eventId: string): Promise<SocketInterfaceResponse.EventPhoto[]> {
    const preload = window.location.hostname === 'localhost';
    return this.emit('getEventPhotos', {eventId, preload});
  }

  getGoogleDriveFolder(folderId: string): Promise<any[]> {
    const preload = window.location.hostname === 'localhost';
    return this.emit('getGoogleDriveFolder', {folderId, preload});
  }

  async getNxtCalendarEvent(eventId: string): Promise<NxtCalendarEvent> {
    return this.emit('getNxtCalendarEvent', eventId);
  }

  setEventQr(eventId: string) {
    return this.emit('setEventQr', eventId);
  }


  sendEventPhotosToCustomer(data: { eventId: string, fileIds: string[], contactId: string }) {
    return this.emit('sendEventPhotosToCustomer', data);
  }


  async getContactsByMobile(mobile: any) {
    return this.emit('getContactsByMobile', mobile);
  }

  async getEventsByContactId(contactId: string): Promise<{ contact: NxtContact, events: (NxtCalendarEvent)[] }> {
    return this.emit('getEventsByContactId', contactId);
  }

  async getEventsByArtistAndDate(artist: string, dateString: string) {
    return this.emit('getEventsByArtistAndDate', {artist, dateString});
  }

  updateCalendarEventProperty(eventId: string, properties: UpdateCalendarEventProps): Promise<NxtCalendarEvent> {
    return this.emit('updateCalendarEventProperty', {eventId, properties});
  }

  async getContactById(contactId: any): Promise<NxtContact | undefined> {
    return this.emit('getContactById', contactId);
  }

  async setShopOrderRefunded(data: SocketInterface.SetShopOrderRefunded) {
    return this.emit('setShopOrderRefunded', data);
  }

  createReminder(reminder: NxtReminder) {
    return this.emit('createReminder', reminder);
  }

  getRemindersByEventId(eventId: string) {
    return this.emit('getRemindersByEventId', eventId);
  }

  getRemindersByDate(data: SocketInterface.GetRemindersByDate) {
    return this.emit('getRemindersByDate', data);
  }


  async setReminderDone(reminderId: string, force: boolean) {
    return this.emit('setReminderDone', {reminderId, force});
  }

  async updateReminder(reminder: NxtReminder) {
    return this.emit('updateReminder', reminder);
  }

  public paypalRefund(data: SocketInterface.PaypalRefund): Promise<NxtPaypalRefundResult> {
    return this.emit('paypalRefund', data);
  }

  public paypalRefundShopOrderItem(data: SocketInterface.PaypalRefundShopOrderItem): Promise<NxtPaypalRefundResult> {
    return this.emit('paypalRefundShopOrderItem', data);
  }

  async klarnaRefundShopOrderItem(data: SocketInterface.KlarnaRefundShopOrderItem) {
    return this.emit('klarnaRefundShopOrderItem', data);
  }

  generatePayPalCheckoutLink(data: SocketInterface.GeneratePayPalCheckoutLink) {
    return this.emit('generatePayPalCheckoutLink', data);
  }

  async upsertArtistSpot(artistSpot: NxtArtistSpot) {
    return this.emit('upsertArtistSpot', artistSpot);
  }

  async deleteArtistSpot(artistSpotId: string) {
    return this.emit('deleteArtistSpot', artistSpotId);
  }

  async getArtistSpot(artistSpotId: string) {
    return this.emit('getArtistSpot', artistSpotId);
  }


  /*public calcDayFinishCash(data: SocketInterface.CalcDayFinishCash) {
    return this.emit('calcDayFinishCash', data);
  }*/

  public getArticles(): Promise<NxtArticle[]> {
    return this.emit('getArticles');
  }

  public deleteCalendarEvent(eventId: string, calendarId: string) {
    return this.emit('deleteCalendarEvent', {eventId, calendarId});
  }

  public EventSort_GetEvents(reload: boolean) {
    return this.emit('EventSort_GetEvents', reload);
  }

  public setWhatsAppChatRead(currentWhatAppId: string) {
    return this.emit('setWhatsAppChatRead', currentWhatAppId);
  }

  public setWhatsAppChatUnRead(currentWhatAppId: string) {
    return this.emit('setWhatsAppChatUnRead', currentWhatAppId);
  }

  public setGlobalConfig(key: string, value: any) {
    return this.emit('setGlobalConfig', {key, value});
  }

  public getGlobalConfig(key: string) {
    return this.emit('getGlobalConfig', key);
  }

  public fakeWhatsAppStatus() {
    return this.emit('fakeWhatsAppStatus', {});
  }

  public updatePhotoSyncReimportUnknown(ids: string[], eventId: string, artist: string) {
    return this.emit('updatePhotoSyncReimportUnknown', {ids, eventId, artist});
  }

  public updatePhotoSyncReimportUnknownDelete(ids: string[]) {
    return this.emit('updatePhotoSyncReimportUnknownDelete', {ids});
  }

  public getPhotoSyncUnknownUploadsNotFixed() {
    return this.emit('getPhotoSyncUnknownUploadsNotFixed', {});
  }

  public async setEventTo_FromPayment2(payment: any) {
    return this.emit('setEventTo_FromPayment2', payment);
  }

  public async setEventBackFrom_FromPayment2(payment: any) {
    return this.emit('setEventBackFrom_FromPayment2', payment);
  }

  public sendEventPhotosToClipboard(data: SocketInterface.SendEventPhotosToClipboard) {
    return this.emit('sendEventPhotosToClipboard', data);
  }

  public upsertInventoryOrder(data: NxtInventoryStockItemOrder) {
    return this.emit('upsertInventoryOrder', data);
  }

  public async getWhatsappFastAnswers(): Promise<NxtWhatsAppFastAnswer[]> {
    return this.emit('getWhatsappFastAnswers', {});
  }

  public async insertWhatsappFastAnswer(data: NxtWhatsAppFastAnswer) {
    return this.emit('insertWhatsappFastAnswer', data);
  }

  public async updateWhatsappFastAnswer(data: NxtWhatsAppFastAnswer) {
    return this.emit('updateWhatsappFastAnswer', data);
  }

  public getArtistContract(artistId) {
    return this.emit('getArtistContract', artistId);
  }

  public bankImportTest(bankImportConfig: NxtConfig.BankImport) {
    return this.emit('bankImportTest', bankImportConfig);
  }

  public async paypalImportTest(paypalImport: NxtConfig.PaypalImport) {
    return this.emit('paypalImportTest', paypalImport);
  }

  public async getServerConfig(key: string) {
    return this.emit('getServerConfig', key);
  }

  public async setServerConfig(data: { key: string, value: any }) {
    return this.emit('setServerConfig', data);
  }

  public async deleteContact(contactId: string) {
    return this.emit('deleteContact', contactId);
  }

  public sendPaypalCheckoutLink(id: string) {
    return this.emit('sendPaypalCheckoutLink', id);
  }

  public getTextTemplates() {
    return this.emit('getTextTemplates');
  }

  public async getCalendarPw() {
    return this.emit('getCalendarPw');
  }

  public async getWhatsAppFastAnswersFromAc() {
    return this.emit('getWhatsAppFastAnswersFromAc');
  }

  public postponeReminder(data: SocketInterface.PostponeReminder) {
    return this.emit('postponeReminder', data);
  }

  public getArtistsFromAc(): Promise<NxtArtist[]> {
    return this.emit('getArtistsFromAc');
  }

  /*public getSkills(): Promise<{ skills: NxtArtistSkillValue[]; boolSkills: NxtArtistBoolSkillValue[]; }> {
    return this.emit('getSkills');
  }*/


  async getPromoByOfferId(offerId: string): Promise<SocketInterfaceResponse.GetPromoByOfferId> {
    return this.emit('getPromoByOfferId', offerId);
  }

  public async getCurrentArtistPhoto(deviceName: string) {
    return this.emit('getCurrentArtistPhoto', deviceName);
  }

  public reloadCalendarEvents() {
    return this.emit('reloadCalendarEvents');
  }

  public async addArtistPayouts2(dateString: string) {
    return this.emit('addArtistPayouts2', dateString);
  }

  public async earlyArtistPayout2(dateString: string, artist: string) {
    return this.emit('earlyArtistPayout2', {dateString, artist});
  }

  public async getTime() {
    return this.emit('getTime');
  }

  public async getTime2(): Promise<number> {
    return this.emit('getTime2');
  }

  public getArtistSpotByArtistAndDate(artist: string, dateString: string, getLastIfNotExists = false): Promise<NxtArtistSpot> {
    return this.emit('getArtistSpotByArtistAndDate', {artist, dateString, getLastIfNotExists});
  }


  getAvailableArtistsDay(dateString: string): Promise<NxtAvailableArtistDay> {
    return this.emit('getAvailableArtistsDay', dateString);
  }

  public setWelcomeMessageRead() {
    return this.emit('setWelcomeMessageRead');
  }

  public getCityByPostalCode(data: SocketInterface.GetCityByPostalCode) {
    return this.emit('getCityByPostalCode', data);
  }

  public getPriceBuilderRandomPrePhotos(): Promise<SocketInterfaceResponse.GetPriceBuilderRandomPrePhotos> {
    return this.emit('getPriceBuilderRandomPrePhotos', 'Abc');
  }


  public getGoogleDriveFile(fileId: string): Promise<{ base64: string, mimeType: string }> {
    return this.emit('getGoogleDriveFile', fileId);
  }


  public getArticle(articleId: string): Promise<NxtArticle | null> {
    return this.emit('getArticle', articleId);
  }


  public async getOutgoingsImagesZip(outgoingIds: string[]) {
    return this.emit('getOutgoingsImagesZip', outgoingIds);
  }

  public setNextDocScanWithoutQrCodeData(data: SocketInterface.SetNextDocScanWithoutQrCodeData) {
    return this.emit('setNextDocScanWithoutQrCodeData', data);
  }

  public async getPromoUsers(): Promise<NxtPromoUser[]> {
    return this.emit('getPromoUsers');
  }

  public async getPromoPromotions(): Promise<NxtPromoPromotion[]> {
    return this.emit('getPromoPromotions');
  }

  public generateStaticPromoCode = (discountPromotionStaticCode: NxtDiscountPromotion) => {
    return this.emit('generateStaticPromoCode', discountPromotionStaticCode);
  };

  public getClientServerConnections() {
    return this.emit('getClientServerConnections');
  }

  public clientServerRunNpmI(serverClientId: string) {
    return this.emit('clientServerRunNpmI', serverClientId);
  }

  public clientServerRunUpdate(serverClientId: string) {
    return this.emit('clientServerRunUpdate', serverClientId);
  }

  public clientServerPing(serverClientId: string) {
    return this.emit('clientServerPing', serverClientId);
  }

  public getPaypalTransaction(transactionId: string) {
    return this.emit('getPaypalTransaction', transactionId);
  }

  public getPaypalTransactionFirstParent(transactionId: string): Promise<NxtPaypalTransaction> {
    return this.emit('getPaypalTransactionFirstParent', transactionId);
  }

  public setClientServerDescription(machineId: string, description: string, monitor: boolean) {
    return this.emit('setClientServerDescription', {machineId, description, monitor});
  }

  public clientServerRestart(serverClientId: string) {
    return this.emit('clientServerRestart', serverClientId);
  }

  public clientServerReboot(serverClientId: string) {
    return this.emit('clientServerReboot', serverClientId);
  }


  public async sendArtistStencilRequest(eventId: string) {
    return this.emit('sendArtistStencilRequest', eventId);
  }

  public setUserActive() {
    if (this.state.value.authenticated && this.state.value.connected) {
      return this.emit('setUserActive');
    }
  }

  public setArtistSkillsToAc(artist: NxtArtist) {
    return this.emit('setArtistSkillsToAc', artist);
  }

  public getCustomer(customerId: string) {
    return this.emit('getCustomer', customerId);
  }

  public async sendStencilSeenMessageToCustomer(eventId: string) {
    return this.emit('sendStencilSeenMessageToCustomer', eventId);
  }

  public async getTextTemplatesByType(type: NxtTextTemplateType): Promise<NxtTextTemplate[]> {
    return this.emit('getTextTemplatesByType', type);
  }

  public async updateAllTextTemplateTypes(type: NxtTextTemplateType, textTemplates: NxtTextTemplate[]) {
    return this.emit('updateAllTextTemplateTypes', {type, textTemplates});
  }

  public deleteInventoryOrder(order: NxtInventoryStockItemOrder) {
    return this.emit('deleteInventoryOrder', order);

  }

  public openAiQuestion(content: string) {
    return this.emit('openAiQuestion', content);
  }

  public async getTextTemplatesFromAc(type: NxtTextTemplateType) {
    return this.emit('getTextTemplatesFromAc', type);
  }

  public async sendWhatsAppMessageToAllArtistsInStudio(message: any) {
    return this.emit('sendWhatsAppMessageToAllArtistsInStudio', message);
  }

  public async sendWhatsAppMessageToAllArtists(message: any) {
    return this.emit('sendWhatsAppMessageToAllArtists', message);
  }

  public async piercingManagerStartCustomer(eventIdNext: string): Promise<{ canStart: boolean, text: string }> {
    return this.emit('piercingManagerStartCustomer', eventIdNext);
  }

  public async piercingManagerNotHere(eventId: string, toAdd: number) {
    return this.emit('piercingManagerNotHere', {eventId, toAdd});
  }

  public async piercingManagerEventFinished(eventId: string) {
    return this.emit('piercingManagerEventFinished', eventId);
  }

  public async piercingManagerGetNextCustomer(): Promise<NxtPiercingManagerCustomer> {
    return this.emit('piercingManagerGetNextCustomer');
  }

  public async piercingManagerGetArtistSession(): Promise<NxtPiercingManagerArtistSession> {
    return this.emit('piercingManagerGetArtistSession');
  }

  public async piercingManagerStartArtistSession(artistName: string): Promise<NxtPiercingManagerArtistSession> {
    return this.emit('piercingManagerStartArtistSession', artistName);
  }

  public async piercingManagerGetAvailableArtists(): Promise<string[]> {
    return this.emit('piercingManagerGetAvailableArtists');
  }

  public async piercingManagerGetSession(): Promise<NxtPiercingManagerSession> {
    return this.emit('piercingManagerGetSession');
  }

  public async piercingManagerStartSession(): Promise<boolean> {
    return this.emit('piercingManagerStartSession');
  }

  async piercingManagerClearSession() {
    return this.emit('piercingManagerClearSession');
  }

  async piercingManagerReOpenEvents() {
    return this.emit('piercingManagerReOpenEvents');
  }

  async piercingManagerClearSortValues() {
    return this.emit('piercingManagerClearSortValues');
  }

  async piercingManagerStartPause() {
    return this.emit('piercingManagerStartPause');
  }

  async piercingManagerSetEventsToToday(dateString: string) {
    return this.emit('piercingManagerSetEventsToToday', dateString);
  }

  piercingManagerSendRegisterLink(eventId: string) {
    return this.emit('piercingManagerSendRegisterLink', eventId);
  }

  getRemindersSince(dateString: string, appendRefObjs: boolean): Promise<NxtReminder[]> {
    return this.emit('getRemindersSince', {dateString, appendRefObjs});
  }


  cashReportCashUpsert(cashPayment: WithoutNxtDbFields<NxtCashPayment>) {
    return this.emit('cashReportCashUpsert', cashPayment);
  }

  cashReportCashDeleteById(cashPaymentId: string) {
    return this.emit('cashReportCashDeleteById', cashPaymentId);
  }

  cashReportCashGetById(cashPaymentId: string) {
    return this.emit('cashReportCashGetById', cashPaymentId);
  }

  studioCashReport2GetData(dateString: string, studio: string, noCache: boolean): Promise<NxtStudioCashReportData> {
    return this.emit('studioCashReport2GetData', {dateString, studio, noCache});
  }

  getCalendarEventsForCalendar(dateString: string, daysCount: number): Promise<SocketInterfaceResponse.GetCalendarEventsForCalendar> {
    return this.emit('getCalendarEventsForCalendar', {dateString, daysCount});
  }

  workSessionStart(dateString: string) {
    return this.emit('workSessionStart', dateString);
  }

  workSessionStartCashRegister(dateString: string, startMoney: number) {
    return this.emit('workSessionStartCashRegister', {dateString, startMoney});
  }

  workSessionUpdateCashRegister(cashRegister: NxtWorkSessionCashRegister) {
    return this.emit('workSessionUpdateCashRegister', cashRegister);
  }

  workSessionUpdateWorkSession(workSession: WithoutNxtDbFields<NxtWorkSession>) {
    return this.emit('workSessionUpdateWorkSession', workSession);
  }

  async getDayFinishReport2(dateString: string, studio: string, employeeName: string) {
    return this.emit('getDayFinishReport2', {dateString, studio, employeeName});
  }

  async resetDayFinish2(workSessionDateString: string, withEarlyPayouts: boolean) {
    return this.emit('resetDayFinish2', {workSessionDateString, withEarlyPayouts});
  }

  async bookBankAndSafe(dateString: string, bankValue: any, safeValue: number, shouldValueBeforePayoutsWithoutSafe: number) {
    return this.emit('bookBankAndSafe', {dateString, bankValue, safeValue, shouldValueBeforePayoutsWithoutSafe});
  }

  dayFinishCreateLog(data: SocketInterface.DayFinishCreateLog) {
    return this.emit('dayFinishCreateLog', data);
  }

  dayFinishDeleteLog(dateString: string) {
    return this.emit('dayFinishDeleteLog', dateString);
  }

  getDayFinishLog(dateString: string) {
    return this.emit('getDayFinishLog', dateString);
  }

  async workSessionSetPostponeEvents(dateString: string, eventIds: string[]) {
    return this.emit('workSessionSetPostponeEvents', {dateString, eventIds});
  }

  printInvoices(invoiceNumbers: string[]) {
    return this.emit('printInvoices', invoiceNumbers);
  }

  printDayFinishReports(dateString: string, studio: string, employeeName: string) {
    return this.emit('printDayFinishReports', {dateString, studio, employeeName});
  }


  getMergedInvoices(invoiceNumbers: string[]) {
    return this.emit('getMergedInvoices', invoiceNumbers);
  }

  getMergedDayFinishReports(dateString: string, studio: string, employeeName: string) {
    return this.emit('getMergedDayFinishReports', {dateString, studio, employeeName});
  }


  async openGate() {
    return this.emit('openGate');
  }

  async openDoor() {
    return this.emit('openDoor');
  }

  upsertPromoUser(userToSave: NxtPromoUser) {
    return this.emit('promoService_upsertPromoUser', userToSave);
  }

  async getWhatsAppInstaResponses() {
    return this.emit('getWhatsAppInstaResponses');
  }

  private registerUserChangedListener() {
    this.subscribeNew('eventUserUpdated', (user: NxtUser) => {
      if (user.username === this.currentUser$.value.username) {
        this.currentUser$.next(user);
      }
    });
  }

  emitUserChanged() {
    return this.emit('emitUserChanged');
  }

  combineContacts2(contact: NxtContact, allContactIds: string[]) {
    return this.emit('combineContacts2', {contact, allContactIds});
  }

  bookSideCashRegisterTransfer(dateString: string, cashRegisterStudio: string, transferValue: number, transferSafeValue: number) {
    return this.emit('bookSideCashRegisterTransfer', {dateString, cashRegisterStudio, transferValue, transferSafeValue});
  }

  acceptCashRegisterTransfer(dateString: string, transfer: NxtWorkSessionCashRegisterTransfer, studio: string) {
    return this.emit('acceptCashRegisterTransfer', {dateString, transfer, studio});
  }

  /*sendInvoicesToArtistsByWorkSessionDateString(dateString: string) {
    return this.emit('sendInvoicesToArtistsByWorkSessionDateString', {dateString});
  }*/

  /*sendInvoiceEarlyPaidArtist(artist: string, invoiceNumber: string, totalPayoutValue: number) {
    return this.emit('sendInvoiceEarlyPaidArtist', {artist, invoiceNumber, totalPayoutValue});
  }*/

  /* getArtistsColors() {
    return this.emit('getArtistsColors');
  } */

  getWhatsApp20(): Promise<NxtPaymentPossibilityRecord[]> {
    return this.emit('getWhatsApp20');
  }

  getDeclarationOfConsentTattoo(page: number, lang: string, withParents: boolean) {
    return this.emit('getDeclarationOfConsentTattoo', {page, lang, withParents});
  }

  getDeclarationOfConsentPiercing(page: number, artistEmployed: boolean, lang: string, withParents: boolean) {
    return this.emit('getDeclarationOfConsentPiercing', {page, artistEmployed, lang, withParents});
  }

  getDeclarationOfConsentToothGem(page: number, artistEmployed: boolean, lang: string, withParents: boolean) {
    return this.emit('getDeclarationOfConsentToothGem', {page, artistEmployed, lang, withParents});
  }

  getAfterCareInstructionPiercing(page: number) {
    return this.emit('getAfterCareInstructionPiercing', {page});
  }

  getAfterCareInstructionTattoo(page: number) {
    return this.emit('getAfterCareInstructionTattoo', {page});
  }

  async getEventPhoto(eventId: string, googleDriveFileId: string) {
    return this.emit('getEventPhoto', {eventId, googleDriveFileId});
  }

  async getAdditionalCanNotFinishReasons(workSessionDateString: string) {
    return this.emit('getAdditionalCanNotFinishReasons', workSessionDateString);
  }

  getCacheList(listName: CacheListName) {
    return this.emit('getCacheList', listName);
  }

  /**
   * @deprecated Use canNotBookMessage instead
   */
  canBook(bookDateTime: number, cashRegisterStudio: string): Promise<string> {
    return this.emit('canBook', {bookDateTime, cashRegisterStudio});
  }

  getCanNotBookMessage(bookDateTime: number, cashRegisterStudio: string): Promise<string> {
    return this.emit('canBook', {bookDateTime, cashRegisterStudio});
  }

  sendBadPhotoToArtist(eventId: string, text: any, photoId: string) {
    return this.emit('sendBadPhotoToArtist', {eventId, text, photoId});
  }

  eventList(data: SocketInterface.EventList) {
    return this.emit('eventList', data);
  }

  /*getNotAssignedBankTransfer(): Promise<{ notAssignedOutgoings: NxtCashPayment[], notAssignedAtms: NxtAtmIncomingRecord[] }> {
    return this.emit('getNotAssignedBankTransfer');
  }*/

  async upsertCalendarEvent2(calendarEvent: NxtCalendarEvent, newTattooTemplates: NxtTattooTemplate[], nextNxtUpdateId: string): Promise<NxtCalendarEvent> {
    return this.emit('upsertCalendarEvent2', {calendarEvent, newTattooTemplates, nextNxtUpdateId});
  }

  getPiercingInventoryStockItems(): Promise<NxtInventoryStockItem[]> {
    return this.emit('getPiercingInventoryStockItems');
  }

  deleteCalendarEvent2(eventId: string) {
    return this.emit('deleteCalendarEvent2', eventId);
  }

  async getPayPalIpn(transactionId: string) {
    return this.emit('getPayPalIpn', transactionId);
  }

  async reSendGiftCardsViaWhatsApp(orderId: string, mobile: string) {
    return this.emit('reSendGiftCardsViaWhatsApp', {orderId, mobile});
  }

  async reSendGiftCardsViaEmail(orderId: string) {
    return this.emit('reSendGiftCardsViaEmail', orderId);
  }

  async getKlarnaOrderRaw(klarnaOrderId: string) {
    return this.emit('getKlarnaOrderRaw', klarnaOrderId);
  }

  getGiftCardCodeByByShopOrderLineItemId(orderItemId: string) {
    return this.emit('getGiftCardCodeByByShopOrderLineItemId', orderItemId);
  }

  getKlarnaOrdersByKlarnaOrderId(klarnaOrderId: string): Promise<NxtKlarnaOrder[]> {
    return this.emit('getKlarnaOrdersByKlarnaOrderId', klarnaOrderId);
  }

  async getShopOrder(shopOrderId: string): Promise<NxtShopOrder> {
    return this.emit('getShopOrder', shopOrderId);
  }

  async getShopOrderByLineItemId(shopOrderLineItemId: string): Promise<NxtShopOrder> {
    return this.emit('getShopOrderByLineItemId', shopOrderLineItemId);
  }

  async sendArtistInvoicesByYear(artist: string, year: string) {
    return this.emit('sendArtistInvoicesByYear', {artist, year});
  }

  async getKlarnaShopOrdersBetweenWithRaw(fromDateString: string, tillDateString: string): Promise<NxtShopOrderWithRaw[]> {
    return this.emit('getKlarnaShopOrdersBetweenWithRaw', {fromDateString, tillDateString});
  }

  updateKlarnaOrder(klarnaOrder: NxtKlarnaOrder) {
    return this.emit('updateKlarnaOrder', klarnaOrder);
  }

  private async reloadNgConfig() {
    const newNgConfig = await this.getNgConfig();
    this.configService.setConfig(newNgConfig);
  }

  private getNgConfig(): Promise<NxtNgConfigRuntime> {
    return this.emit('getNgConfig');
  }

  /*getKlarnaOrderByShopOrderLineItemId(shopOrderLineItemId: string): Promise<NxtKlarnaOrder> {
    return this.emit('getKlarnaOrderByShopOrderLineItemId', shopOrderLineItemId);
  }*/

  getKlarnaOrdersSplitted(orderId: string): Promise<NxtKlarnaOrder[]> {
    return this.emit('getKlarnaOrdersSplitted', orderId);
  }

  getRefundsByOrderLineItemId(orderLineItemId: string): Promise<NxtRefund[]> {
    return this.emit('getRefundsByOrderLineItemId', orderLineItemId);
  }

  getKlarnaOrdersOutByShopOrderLineItemId(shopOrderLineItemId: string) {
    return this.emit('getKlarnaOrdersOutByShopOrderLineItemId', shopOrderLineItemId);
  }

  syncShopOrder(shopOrderId: string) {
    return this.emit('syncShopOrder', shopOrderId);
  }

  getPayPalTattooTicket(shopOrderLineItem: string): Promise<SocketInterfaceResponse.TattooTicketView<NxtPaypalTransaction>> {
    return this.emit('getPayPalTattooTicket', shopOrderLineItem);
  }

  sendMessageToClient(socketId: string, message: string) {
    return this.emit('sendMessageToClient', {socketId, message});
  }

  getGiftCardForView(id: string): Promise<SocketInterfaceResponse.GetGiftCardForView> {
    return this.emit('getGiftCardForView', id);
  }

  getKlarnaTattooTicketForView(shopOrderLineItemId: string): Promise<SocketInterfaceResponse.TattooTicketView<NxtKlarnaOrder>> {
    return this.emit('getKlarnaTattooTicketForView', shopOrderLineItemId);
  }

  getShopOrderForView(shopOrderId: string): Promise<SocketInterfaceResponse.GetShopOrderForView> {
    return this.emit('getShopOrderForView', shopOrderId);
  }

  async printCustomerReceipt(eventId: string, paymentUuid: string) {
    return this.emit('printCustomerReceipt', {eventId, paymentUuid});
  }

  async getKlarnaOrdersOriginalFromTill(from: string, till: string): Promise<(NxtKlarnaOrder & { shopOrderLineItem?: NxtShopOrderLineItem })[]> {
    return this.emit('getKlarnaOrdersOriginalFromTill', {from, till});
  }

  setEventToNoWalkIn(eventId: string) {
    return this.emit('setEventToNoWalkIn', eventId);
  }

  setWalkInToday(isWalkInToday: boolean) {
    return this.emit('setIsWalkInToday', isWalkInToday);
  }

  importAdditionalBankTransaction(additionalBankTransactionNoteIncluded: string) {
    return this.emit('importAdditionalBankTransaction', additionalBankTransactionNoteIncluded);
  }

  reduceArtistPayout(eventId: string, toReduce: number) {
    return this.emit('reduceArtistPayout', {eventId, toReduce});
  }

  deleteParsedContact(id: string) {
    return this.emit('deleteParsedContact', id);
  }

  swapParsedContactAttribute(fromId: string, toId: string, attribute: string) {
    return this.emit('swapParsedContactAttribute', {fromId, toId, attribute});
  }

  async getArtist(artistId: string): Promise<NxtArtist | undefined> {
    return this.emit('getArtist', artistId);
  }

  async getArtistByName(artistName: string): Promise<NxtArtist | undefined> {
    return this.emit('getArtistByName', artistName);
  }

  async uploadArtistFile(artist: string, type: 'id-card-front' | 'id-card-back' | string, filename: string, arrayBuffer: ArrayBuffer) {
    return this.emit('uploadArtistFile', {artist, type, filename, arrayBuffer});
  }

  async checkEventsOnContacts(contactIds: string[]) {
    return this.emit('checkEventsOnContacts', contactIds);
  }

  upsertArticle(article: NxtArticle) {
    return this.emit('upsertArticle', article);
  }

  setWhatsAppMessageIdAsBroadcast(messageId: string) {
    return this.emit('setWhatsAppMessageIdAsBroadcast', messageId);
  }

  printShisha(eventId: string) {
    return this.emit('printShisha', eventId);
  }

  /*reCalcPromoPayoutsLastDays(daysCount: number) {
    return this.emit('reCalcPromoPayoutsLastDays', daysCount);
  }*/

  getIp(): Promise<string> {
    return this.emit('getIp');
  }

  getDiscountedOrDaySessionGiftCards(startDateString: string, endDateString: string): Promise<NxtPaymentPossibilityRecord[]> {
    return this.emit('getDiscountedOrDaySessionGiftCards', {startDateString, endDateString});
  }

  getDynamicDataById<T>(dynamicDataId: string): Promise<T> {
    return this.emit('getDynamicDataById', dynamicDataId);
  }

  resetUserPassword(id: string) {
    return this.emit('resetUserPassword', id);
  }

  piercingManagerAddInfo(eventId: string, info: string) {
    return this.emit('piercingManagerAddInfo', {eventId, info});
  }

  getDailyNote(dailyNoteId: string): Promise<NxtDailyNote> {
    return this.emit('getDailyNote', dailyNoteId);
  }

  async upsertDailyNote(dailyNote: NxtDailyNote) {
    return this.emit('upsertDailyNote', dailyNote);
  }

  async deleteDailyNote(dailyNoteId: string) {
    return this.emit('deleteDailyNote', dailyNoteId);
  }

  async getCalendarEvent2ByGoogleId(eventId: string) {
    return this.emit('getCalendarEvent2ByGoogleId', eventId);
  }

  async hasPiercingArtistToday() {
    return this.emit('hasPiercingArtistToday');
  }

  async getPiercingArtistsToday(): Promise<NxtAvailableArtistDayArtist[]> {
    return this.emit('getPiercingArtistsToday');
  }

  async createCashRegisterHandover(data: SocketInterface.CreateCashRegisterHandover) {
    return this.emit('createCashRegisterHandover', data);
  }

  async createArtistConfirm(data: WithoutNxtDbFields<NxtArtistConfirm>): Promise<{ artistConfirm: NxtArtistConfirm, link: string }> {
    return this.emit('createArtistConfirm', data);
  }

  async cancelArtistConfirm(artistConfirmId: string) {
    return this.emit('cancelArtistConfirm', artistConfirmId);
  }

  async upsertEmployee(employee: WithoutNxtDbFields<NxtEmployee>) {
    return this.emit('upsertEmployee', employee);
  }

  async getEmployeeHolidays(yearString: string) {
    return this.emit('getEmployeeHolidays', yearString);
  }

  async getEmployee(employeeId: string) {
    return this.emit('getEmployee', employeeId);
  }

  async getEmployees(): Promise<NxtEmployee[]> {
    return this.emit('getEmployees');
  }

  async getArtistConfirmByArtistAndWorkSessionDateTime(artist: string, workSessionDateString: string) {
    return this.emit('getArtistConfirmByArtistAndWorkSessionDateTime', {artist, workSessionDateString});
  }

  async sendStudioTransferGiftCard(paymentPossibilityRecordId: string, toStudio: string) {
    return this.emit('sendStudioTransferGiftCard', {paymentPossibilityRecordId, toStudio});
  }

  async getEmployeeEvents(fromDateString: string, tillDateString: string): Promise<SocketInterfaceResponse.GetEmployeeEvents> {
    return this.emit('getEmployeeEvents', {fromDateString, tillDateString});
  }


  upsertEmployeeEvent(employeeEvent: WithoutNxtDbFields<NxtEmployeeEvent>) {
    return this.emit('upsertEmployeeEvent', employeeEvent);
  }

  getEmployeeEvent(employeeEventId: string): Promise<NxtEmployeeEvent> {
    return this.emit('getEmployeeEvent', employeeEventId);
  }

  async getAppUsers(): Promise<{ name: string, uid: string, loginContext: string }[]> {
    return this.emit('getAppUsers');
  }

  async sendWhatsAppMessageToArtist(artistId: string, text: string) {
    return this.emit('sendWhatsAppMessageToArtist', {artistId, text});
  }

  async createArtistTelegramChatReminder(artistId: string) {
    return this.emit('createArtistTelegramChatReminder', artistId);
  }

  async checkArtistTelegramChat(artistId: string) {
    return this.emit('checkArtistTelegramChat', artistId);
  }

  async getReminderBySubId(reminderSubId: string): Promise<NxtReminder | null> {
    return this.emit('getReminderBySubId', reminderSubId);
  }

  getEventRatingData(dateString: string): Promise<{ events: NxtCalendarEvent[], additionalEvents: NxtCalendarEvent[], workSession: NxtWorkSession }> {
    return this.emit('getEventRatingData', dateString);
  }

  getEventRatingEvent(eventId: string): Promise<{ events: NxtCalendarEvent[], additionalEvents: NxtCalendarEvent[], workSession: NxtWorkSession }> {
    return this.emit('getEventRatingEvent', eventId);
  }

  getEventRatingArtist(artist: string): Promise<{ events: NxtCalendarEvent[] }> {
    return this.emit('getEventRatingArtist', artist);
  }

  async checkEventPhotoCache(eventId: string) {
    return this.emit('checkEventPhotoCache', eventId);
  }

  deleteEmployeeEvent(employeeEventId: string) {
    return this.emit('deleteEmployeeEvent', employeeEventId);
  }

  async checkEmployeeEvents(dateString: string): Promise<{ checks: NxtEmployeeWeekCheck[], errors: string[] }> {
    return this.emit('checkEmployeeEvents', dateString);
  }

  async getArtistSpotGateOpenText(artistSpotId: string) {
    return this.emit('getArtistSpotGateOpenText', artistSpotId);
  }

  async getArtistKeySafeCodes(): Promise<{ safeNumber: number, code: string }[]> {
    return this.emit('getArtistKeySafeCodes');
  }

  setArtistKeySafeCodes(data: { safeNumber: number; code: string }[]) {
    return this.emit('setArtistKeySafeCodes', data);
  }

  getEmployeeWorks(daysBack: number): Promise<{ workDays: NxtEmployeeWorkDay[], workWeeks: NxtEmployeeWorkWeek[], employees: NxtEmployee[], employeeData: EmployeeData[] }> {
    return this.emit('getEmployeeWorks', daysBack);
  }

  getMoneyAccounts(): Promise<SocketInterfaceResponse.GetMoneyAccounts> {
    return this.emit('getMoneyAccounts');
  }

  async getMoneyAccount(moneyAccountId: string) {
    return this.emit('getMoneyAccount', moneyAccountId);
  }

  async upsertMoneyAccount(moneyAccount: WithoutNxtDbFields<NxtMoneyAccount>) {
    return this.emit('upsertMoneyAccount', moneyAccount);
  }

  async upsertMoneyTransaction(transaction: WithoutNxtDbFields<NxtMoneyTransaction>, createPeerTransaction: boolean) {
    return this.emit('upsertMoneyTransaction', {transaction, createPeerTransaction});
  }

  async upsertMoneyTransactionRegion(region: WithoutNxtDbFields<NxtMoneyTransactionRegion>) {
    return this.emit('upsertMoneyTransactionRegion', region);
  }

  async upsertMoneyTransactionLabel(label: WithoutNxtDbFields<NxtMoneyTransactionLabel>) {
    return this.emit('upsertMoneyTransactionLabel', label);
  }

  async upsertMoneyTransactionCategory(category: WithoutNxtDbFields<NxtMoneyTransactionCategory>) {
    return this.emit('upsertMoneyTransactionCategory', category);
  }

  deleteMoneyTransactionRegion(regionId: string) {
    return this.emit('deleteMoneyTransactionRegion', regionId);
  }

  deleteMoneyTransactionLabel(labelId: string) {
    return this.emit('deleteMoneyTransactionLabel', labelId);
  }

  deleteMoneyTransactionCategory(categoryId: string) {
    return this.emit('deleteMoneyTransactionCategory', categoryId);
  }

  async getMoneyTransactions(moneyAccountId: string): Promise<NxtMoneyTransaction[]> {
    return this.emit('getMoneyTransactions', moneyAccountId);
  }

  async getMoneyTransactionsWithPre(moneyAccountId: string): Promise<{ transactions: NxtMoneyTransaction[], preTransactions: NxtMoneyPreTransaction[] }> {
    return this.emit('getMoneyTransactionsWithPre', moneyAccountId);
  }

  async acceptMoneyPreTransaction(moneyPreTransactionId: string, regionId: string, categoryId: string, info: string) {
    return this.emit('acceptMoneyPreTransaction', {moneyPreTransactionId, regionId, categoryId, info});
  }

  async reCalcAccountBalance(accountId: string, fromDatetime: number) {
    return this.emit('reCalcAccountBalance', {accountId, fromDatetime});
  }

  async deleteMoneyTransaction(moneyTransactionId: string) {
    return this.emit('deleteMoneyTransaction', moneyTransactionId);
  }

  async createPeerMoneyTransaction(moneyTransactionId: string) {
    return this.emit('createPeerMoneyTransaction', moneyTransactionId);
  }

  async getEventsConsentCheckInvalid(): Promise<NxtCalendarEvent[]> {
    return this.emit('getEventsConsentCheckInvalid');
  }

  createEmployeeWorkWeekPayout(data: WithoutNxtDbFields<NxtEmployeeWorkWeekPayout>) {
    return this.emit('createEmployeeWorkWeekPayout', data);
  }

  async getKlarnaDisputes(): Promise<NxtKlarnaDispute[]> {
    return this.emit('getKlarnaDisputes');
  }

  async getArtistInvoice(invoiceNumber: string): Promise<NxtArtistInvoice> {
    return this.emit('getArtistInvoice', invoiceNumber);
  }

  throwErrorToolsInfo() {
    return this.emit('throwErrorToolsInfo');
  }

  throwErrorToolsError() {
    return this.emit('throwErrorToolsError');
  }

  throwError() {
    return this.emit('throwError');
  }

  setEventRating(eventId: string, value: number, comment: string, ratingLog: WithoutNxtDbFields<NxtEventRatingLog>) {
    return this.emit('setEventRating', {eventId, value, comment, ratingLog});
  }

  whatsAppDelete() {
    return this.emit('whatsAppDelete');
  }

  async getWhatsAppScreenshot() {
    return this.emit('getWhatsAppScreenshot');
  }

  deleteEventRating(eventId: string, username: string) {
    return this.emit('deleteEventRating', {eventId, username});
  }

  async readOldChatsShort(mobile) {
    return this.emit('readOldChatsShort', mobile);
  }

  setEventPhotoTypeOld(eventId: string, photoId: string, subType: NxtDriveFileSubType) {
    return this.emit('setEventPhotoTypeOld', {eventId, photoId, subType});
  }

  deleteEventFile(eventId: string, fileId: string) {
    return this.emit('deleteEventFile', {eventId, fileId});
  }

  getEventQrCodeSecret() {
    return this.emit('getEventQrCodeSecret');
  }

  async calcArtistRating(from: string, till: string): Promise<NxtArtistRating1[]> {
    return this.emit('calcArtistRating', {from, till});
  }

  resetEarlyArtistPayout(dateString: string, artist: string) {
    return this.emit('resetEarlyArtistPayout', {dateString, artist});
  }

  setArtistCanEarlyPayout(dateString: string, artist: string, value: boolean) {
    return this.emit('setArtistCanEarlyPayout', {dateString, artist, value});
  }

  tts(text: string): Promise<{ base64: string, mimeType: string }> {
    return this.emit('tts', text);
  }

  whatsAppRestart() {
    return this.emit('whatsAppRestart');
  }

  async getLastPhotoDevice(): Promise<NxtEventPhotoEvent> {
    return this.emit('getLastPhotoDevice');
  }

  async getIcloudState(): Promise<IcloudState> {
    return this.emit('getIcloudState');
  }

  async icloudAction(type: 'code' | 'startScript', data?: string) {
    return this.emit('icloudAction', {type, data});
  }

  async getEventsByFromTill(fromDateString: string, tillDateString: string): Promise<NxtCalendarEvent[]> {
    return this.emit('getEventsByFromTill', {fromDateString, tillDateString});
  }

  async getEventsBestPhoto(): Promise<NxtCalendarEvent[]> {
    return this.emit('getEventsBestPhoto');
  }

  async correctGrammar(text: string): Promise<string> {
    return this.emit('correctGrammar', text);
  }

  updateEventFileSubType(eventId: string, fileId: string, subType: NxtDriveFileSubType) {
    return this.emit('updateEventFileSubType', {eventId, fileId, subType});
  }

  createEventFileAlbum(eventId: string, files: NxtCalendarEventFile[]) {
    return this.emit('createEventFileAlbum', {eventId, files});
  }

  sendEventFileAlbumToContact(contactId: string, url: string) {
    return this.emit('sendEventFileAlbumToContact', {contactId, url});
  }

  sendEventFilesToClipboard(eventId: string, eventFiles: NxtCalendarEventFile[]) {
    return this.emit('sendEventFilesToClipboard', {eventId, eventFiles});
  }

  public async getEventFilesFromClipboard(): Promise<NxtEventFileClipboard[]> {
    return this.emit('getEventFilesFromClipboard');
  }

  reAssignEventFilesFromClipboard(newEventId: string, eventFiles: NxtEventFileClipboard[]) {
    return this.emit('reAssignEventFilesFromClipboard', {newEventId, eventFiles});
  }

  async saveEditPhoto(fileId: string, base64Url: string) {
    return this.emit('saveEditPhoto', {fileId, base64Url});
  }

  async getDriveFile(fileId: string): Promise<NxtDriveFile | undefined> {
    return this.emit('getDriveFile', fileId);
  }

  async getEventRatingLog(eventId: string): Promise<NxtEventRatingLog[]> {
    return this.emit('getEventRatingLog', eventId);
  }

  async eventQuery(eventQuery: NxtEventQuery): Promise<NxtCalendarEvent[]> {
    return this.emit('eventQuery', eventQuery);
  }

  async getFonts() {
    return this.emit('getFonts');
  }

  async deleteGiftCard(paymentPossibilityRecordId: string) {
    return this.emit('deleteGiftCard', paymentPossibilityRecordId);
  }

  startDriveMigration(from: string, till: string) {
    return this.emit('startDriveMigration', {from, till});
  }

  getDriveMigrationLogs(): Promise<string[]> {
    return this.emit('getDriveMigrationLog');
  }

  async getThermalPrinterIp() {
    return this.emit('getThermalPrinterIp');
  }

  async setThermalPrinterIp(printerIp: string) {
    return this.emit('setThermalPrinterIp', printerIp);
  }

  async getStudioSocialMediaTelegramChatId(): Promise<NxtTelegramChatIdGlobal | null> {
    return this.emit('getStudioSocialMediaTelegramChatId');
  }

  async sendImageToSocialMedialMobile(base64: string) {
    return this.emit('sendImageToSocialMedialMobile', {base64});
  }

  async getContactsForMap(): Promise<SocketInterfaceResponse.MapContact[]> {
    return this.emit('getContactsForMap');
  }

  beforeWhatsAppClientMessageSent(message: string) {
    return this.emit('beforeWhatsAppClientMessageSent', message);
  }

  async getChatsToRecheck() {
    return this.emit('getChatsToRecheck');
  }

  setChatRecheck(chatId: string, doCheck: boolean) {
    return this.emit('setChatRecheck', {chatId, doCheck});
  }

  async getWhatsAppChatPinned(mobile: string): Promise<NxtWhatsAppChatPinned> {
    return this.emit('getWhatsAppChatPinned', mobile);
  }

  async deletePaymentPossibilityRecord(deletePaymentPossibilityRecordId: string) {
    return this.emit('deletePaymentPossibilityRecord', deletePaymentPossibilityRecordId);
  }

  async getEmployeeWorkWeekPayout(employeeId: string, dateString: string): Promise<NxtEmployeeWorkWeekPayout[]> {
    return this.emit('getEmployeeWorkWeekPayout', {employeeId, dateString});
  }

  async deleteEmployeePayout(employeeId: string, weekDateString: string) {
    return this.emit('deleteEmployeePayout', {employeeId, weekDateString});
  }

  async importInventoryFromStudio(studio: GlobalStudioShortName) {
    return this.emit('importInventoryFromStudio', studio);
  }


  async getBankTransactionsByIds(transactionIds: string[]): Promise<NxtBankTransaction[]> {
    return this.emit('getBankTransactionsByIds', transactionIds);
  }

  async getBankTransaction(transactionId: string): Promise<NxtBankTransaction> {
    return this.emit('getBankTransaction', transactionId);
  }

  async dayFinishChecklistDone(checkItems: ({ name: string; checked: boolean })[]) {
    return this.emit('dayFinishChecklistDone', checkItems);
  }

  getBankTransactionDetails(transactionId: string): Promise<SocketInterfaceResponse.GetBankTransactionDetails> {
    return this.emit('getBankTransactionDetails', transactionId);
  }

  async rePrintGiftCardReceipt(paymentPossibilityRecordId: string) {
    return this.emit('rePrintGiftCardReceipt', paymentPossibilityRecordId);
  }

  async reCalcEmployeeWeekClicked(employeeId: string, dateString: string) {
    return this.emit('reCalcEmployeeWeekClicked', {employeeId, dateString});
  }

  /*runBankAiById(bankTransactionId: string) {
    return this.emit('runBankAiById', bankTransactionId);
  }*/

  /*runBankAi(options: SocketInterface.RunBankAi) {
    return this.emit('runBankAi', options);
  }*/

  runBankTransactionImportByIds(transactionIds: string[]) {
    return this.emit('runBankTransactionImportByIds', transactionIds);
  }

  setBankTransactionTypeManual(bankTransactionId: string, type: NxtBankTransactionType) {
    return this.emit('setBankTransactionTypeManual', {bankTransactionId, type});
  }


  async getArtistSpotsByArtist(artist: string, future: boolean): Promise<NxtArtistSpot[]> {
    return this.emit('getArtistSpotsByArtist', {artist, future});
  }

  async getBankDocuments(data: SocketInterface.GetBankDocuments): Promise<NxtBankDocument[]> {
    return this.emit('getBankDocuments', data);
  }

  startGetMyInvoicesImport() {
    return this.emit('startGetMyInvoicesImport');
  }

  getMyInvoicesFixDocuments() {
    return this.emit('getMyInvoicesFixDocuments');
  }

  /*async getBankDocumentPdf(bankDocumentId: string) {
    return this.emit('getBankDocumentPdf', bankDocumentId);
  }*/

  public getWaContactCache(): Promise<NxtContactForWa[]> {
    return this.emit('getWaContactCache');
  }

  async runBankTransactionMatchDeleteByIds(ids: string[]) {
    return this.emit('runBankTransactionMatchDeleteByIds', ids);
  }

  public async removeDocumentsFromTransactions(transactionIds: string[]) {
    return this.emit('removeDocumentsFromTransactions', transactionIds);
  }

  public async removeTransactionsFromDocuments(documentIds: string[]) {
    return this.emit('removeTransactionsFromDocuments', documentIds);
  }

  public async removeTransactionsFromDocument(documentId: string, transactionIds: string[]) {
    return this.emit('removeTransactionsFromDocument', {documentId, transactionIds});
  }

  async getCrons() {
    return this.emit('getCrons');
  }

  async runBankDocumentFlow(ids: string[], status: NxtBankDocumentStatus, startFlow: boolean) {
    return this.emit('runBankDocumentFlow', {ids, status, startFlow});
  }

  async runBankTransferFlow(ids: string[], status: NxtBankTransactionStatus, startFlow: boolean) {
    return this.emit('runBankTransferFlow', {ids, status, startFlow});
  }

  public async getBankDocumentFull(documentId: string): Promise<SocketInterfaceResponse.GetBankDocumentFull> {
    return this.emit('getBankDocumentFull', documentId);
  }

  public async updateDocumentNxtAiManual(data: SocketInterface.UpdateDocumentNxtAiManual) {
    return this.emit('updateDocumentNxtAiManual', data);
  }

  public deleteBankDocument(id: string) {
    return this.emit('deleteBankDocument', id);
  }

  async setBankDocumentCheckLater(documentId: string, days: number) {
    return this.emit('setBankDocumentCheckLater', {documentId, days});
  }

  async setBankTransactionNeedInvoiceManual(transactionId: string, needInvoice: boolean) {
    return this.emit('setBankTransactionNeedInvoiceManual', {transactionId, needInvoice});
  }

  async assignDocumentToTransactions(documentId: string, transactionIds: string[]) {
    return this.emit('assignDocumentToTransactions', {documentId, transactionIds});
  }

  async assignTransactionToTransactions(transactionId: string, transactionIds: string[]) {
    return this.emit('assignTransactionToTransactions', {transactionId, transactionIds});
  }

  startBankDocumentMultiImport() {
    return this.emit('startBankDocumentMultiImport');
  }

  public findBankDocuments(filter: SocketInterface.FindBankDocuments): Promise<NxtBankDocument[]> {
    return this.emit('findBankDocuments', filter);
  }

  public async setArtistSkillFromRating(artistId: string, skill: string, newValue: number) {
    return this.emit('setArtistSkillFromRating', {artistId, skill, newValue});
  }

  public async getBankDocumentsByIds(documentIds: string[]): Promise<NxtBankDocument[]> {
    return this.emit('getBankDocumentsByIds', documentIds);
  }

  async bankDocumentDeleteManualChange(documentId: string, createdAt: number) {
    return this.emit('bankDocumentDeleteManualChange', {documentId, createdAt});
  }

  async addBankTransactionNote(transactionId: string, message: string) {
    return this.emit('addBankTransactionNote', {transactionId, message});
  }

  async removeBankTransactionNote(transactionId: string, at: number) {
    return this.emit('removeBankTransactionNote', {transactionId, at});
  }

  public setBankDocumentType(documentId: string, documentType: NxtBankDocumentType) {
    return this.emit('setBankDocumentType', {documentId, documentType});
  }

  async removeTransactionsFromTransaction(transactionId: string, transactionIds: string[]) {
    return this.emit('removeTransactionsFromTransaction', {transactionId, transactionIds});
  }

  async getBankImportMultiRunnerState(): Promise<NxtMultiImportRunnerStatus> {
    return this.emit('getBankImportMultiRunnerState');
  }


  deleteDriveFileFromEvent(eventId: string, fileIds: string[]) {
    return this.emit('deleteDriveFileFromEvent', {eventId, fileIds});
  }

  refundToPayOnEventDatePayed(eventId: string, toPay: NxtCalendarEventToPayOnEventDate) {
    return this.emit('refundToPayOnEventDatePayed', {eventId, toPay});
  }

  setCurrentUrl(url: string) {
    return this.emit('setCurrentUrl', url);
  }

  async updateEventFileConsent(driveFileId: string, type: 'hasAllCustomerSigns' | 'hasAllDates' | 'correctPageCount', value: boolean) {
    return this.emit('updateEventFileConsent', {driveFileId, type, value});
  }

  async getDiscountPromotions(onlyNotDisabled: boolean): Promise<NxtDiscountPromotion[]> {
    return this.emit('getDiscountPromotions', onlyNotDisabled);
  }

  async getDiscountPromotion(discountPromotionId: string): Promise<NxtDiscountPromotion | null> {
    return this.emit('getDiscountPromotion', discountPromotionId);
  }

  async upsertDiscountPromotion(discountPromotion: WithoutNxtDbFields<NxtDiscountPromotion>) {
    return this.emit('upsertDiscountPromotion', discountPromotion);
  }

  deleteCustomerInvoice(eventId: string) {
    return this.emit('deleteCustomerInvoice', eventId);
  }

  async getMetaDownloadLink(studio: GlobalStudioShortName, fromDateString: string, tillDateString: string) {
    return this.emit('getMetaDownloadLink', {studio, fromDateString, tillDateString});
  }

  async fromWhatsAppSetOtherContact(data: { waId?: string; contactId?: string }) {
    return this.emit('fromWhatsAppSetOtherContact', data);
  }

  async deleteCachedAftercare() {
    return this.emit('deleteCachedAftercare');
  }

  async contactStreetRunByEventId(eventId: string) {
    return this.emit('contactStreetRunByEventId', eventId);
  }

  async contactStreetRunByContactId(contactId: string) {
    return this.emit('contactStreetRunByContactId', contactId);
  }
}
