import {Injectable} from '@angular/core';
import {WorkingDayTools} from '../common-browser/helpers/working-day.tools';
import {ConfigService} from './config.service';
import {PublicHolidaysTools} from '../common-browser/helpers/public-holidays.tools';

@Injectable({
  providedIn: 'root'
})
export class WorkingDayService {

  constructor(
    private configService: ConfigService
  ) {
  }

  isWorkingDay(date: any) {
    return WorkingDayTools.isWorkingDay(date, this.configService.config.value.invoiceData.fromRegion);
  }

  addDaysUntilWorkingDay(date: any) {
    return WorkingDayTools.addDaysUntilWorkingDay(date, this.configService.config.value.invoiceData.fromRegion);
  }

  getPublicHoliday(date: any) {
    return PublicHolidaysTools.getPublicHoliday(date, this.configService.config.value.invoiceData.fromRegion);
  }
}
