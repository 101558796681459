<div #artistGetMenuTriggerWrapper class="artistGetMenuTriggerWrapper"
     (contextmenu)="stopPropagation($event)">
  <div #artistGetMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="artistGetMenu"></div>
</div>
<mat-menu #artistGetMenu [hasBackdrop]="false">
  <div
    class="pb-2 artist-get-menu-artist-title"
    fxLayout="row"
    fxLayoutAlign="center center">
    {{ artist?.name | uppercase }}
  </div>
  @if (artist) {
    @if (artist.canEarlyPayout) {
      <button nxtPermission="" [permissionOr]="['IsReception', 'IsJulian']" *ngIf="!artist?.isEarlyPaidOut" mat-menu-item (click)="earlyArtistPayout()">
        <mat-icon>euro</mat-icon>
        <span>Jetzt auszahlen</span>
      </button>
    } @else {
      <button nxtPermission="Artist_SetCanEarlyPayout" mat-menu-item (click)="setCanEarlyPayoutClicked(true)">
        <mat-icon>euro</mat-icon>
        <span>Früh-Auszahlung freigeben</span>
      </button>
    }
    @if (artist.canEarlyPayout) {
      <button nxtPermission="Artist_SetCanEarlyPayout" mat-menu-item (click)="setCanEarlyPayoutClicked(false)">
        <mat-icon>euro</mat-icon>
        <span>Früh-Auszahlung wieder sperren</span>
      </button>
    }
  }

  <button *ngIf="artist?.isEarlyPaidOut" mat-menu-item (click)="invoice()">
    <mat-icon>article</mat-icon>
    <span>Rechnung</span>
  </button>
  <button nxtPermission="IsJulian" mat-menu-item (click)="resetEarlyArtistPayoutClicked()">
    <mat-icon>article</mat-icon>
    <span>Auszahlung zurück setzen (Julian)</span>
  </button>
  <button mat-menu-item (click)="setArtistTimeWindow()">
    <mat-icon>schedule</mat-icon>
    <span>Verfügbarkeit</span>
  </button>
</mat-menu>
