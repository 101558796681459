import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtPageComponent} from '../../components/nxt-page/nxt-page.component';
import {CalendarWeekModule} from 'angular-calendar';
import {NxtPageContentComponent} from '../../components/nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../../components/nxt-page/nxt-page-footer/nxt-page-footer.component';
import {NxtPageHeaderComponent} from '../../components/nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageHeaderTitleComponent} from '../../components/nxt-page/nxt-page-header/nxt-page-header-title.component';
import {MessengerChatListComponent} from './messenger-chat-list/messenger-chat-list.component';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'nxt-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtPageComponent, CalendarWeekModule, NxtPageContentComponent, NxtPageFooterComponent, NxtPageHeaderComponent, NxtPageHeaderTitleComponent, MessengerChatListComponent]
})

export class MessengerComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  dialogRef = inject(MatDialogRef);

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  closeClicked() {

  }
}
