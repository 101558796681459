export class ColorTools {
  public static NxtGold = '#b8860b';
  public static NxtGray = '#3c3b3f';
  public static Red = '#ff3737';
  public static RedDark = '#792929';

  public static Green = '#2b9100';
  public static GreenDark = '#1c5500';
  public static GreenLight = '#4bff4b';

  public static Orange = '#ff8321';
  public static Orange2 = '#c0641b';
  public static OrangeDark = '#694212';


  // public static Yellow = '#9b7c00';
  public static Blue = '#2876ff';
  public static BlueLight = '#5190ff';
  public static GridTextLighter = '#939393';


  static getLightOrDarkFontColorByBackground(hexColor: string, white = '#ffffff', black = '#000000') {
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? black : white;
  }


  public static LightenDarkenColor(col: any, amt: any) {
    let usePound = false;
    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    let g = (num & 0x0000FF) + amt;

    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }


  public static replaceGoogleCalendarColor(color: string) {
    switch (color.toUpperCase()) {
      case '#CCA6AC':
        return '#AD1457';
      case '#FA573C':
        return '#F4511E';
      case '#FBE983':
        return '#E4C441';
      case '#16A765':
        return '#0B8043';
      case '#4986E7':
        return '#3F51B5';
      case '#CD74E6':
        return '#8E24AA';
      case '#F691B2':
        return '#D81B60';
      case '#FF7537':
        return '#EF6C00';
      case '#B3DC6C':
        return '#C0CA33';
      case '#42D692':
        return '#009688';
      case '#9A9CFF':
        return '#7986CB';
      case '#AC725E':
        return '#795548';
      case '#F83A22':
        return '#D50000';
      case '#FFAD46':
        return '#F09300';
      case '#7BD148':
        return '#7CB342';
      case '#9FE1E7':
        return '#039BE5';
      case '#B99AFF':
        return '#B39DDB';
      case '#C2C2C2':
        return '#616161';
      case '#D06B64':
        return '#E67C73';
      case '#FAD165':
        return '#F6BF26';
      case '#92E1C0':
        return '#33B679';
      case '#9FC6E7':
        return '#4285F4';
      case '#A47AE2':
        return '#9E69AF';
      case '#CABDBF':
        return '#A79B8E';
    }
    return color.toUpperCase();
  }
}
