import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'shopOrderPaymentMethod',
    standalone: true
})
export class ShopOrderPaymentMethodPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any): string {
    if (value === 'klarna_payments') {
      return 'Klarna';
    }
    if (value === 'paypal') {
      return 'PayPal';
    }
  }
}
