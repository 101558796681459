@if ((myService.data$ | async).workSession; as workSession) {
  <div class="flex w-full flex-col items-center">
    <div fxLayout="row" fxLayoutAlign="center" class="w-full text-120">
      <div>WorkSession {{ workSession.dateString | date: 'dd.MM.yyyy' }}</div>
    </div>
    <div class="flex flex-row items-center gap-4">
      <div>Start</div>
      <nxt-date-picker [(nxtModel)]="workSession.startAtDateString" (nxtModelChange)="calcFullDays()"></nxt-date-picker>
      <nxt-time-picker [(value)]="startTime" (valueChange)="calcFullDays()"></nxt-time-picker>
    </div>
    <div class="flex flex-row items-center gap-4">
      <div>End</div>
      <nxt-date-picker [(nxtModel)]="workSession.endAtDateString" (nxtModelChange)="calcFullDays()"></nxt-date-picker>
      <nxt-time-picker [(value)]="endTime" (valueChange)="calcFullDays()"></nxt-time-picker>
    </div>

    <div>
      <div>Zusätzliche Termine</div>
      <nxt-button (click)="newAdditionalEventClicked()">neuer Zusätzlicher</nxt-button>
      <div>
        @for (additionalEventId of workSession.additionalEventIds; track additionalEventId) {
          <div class="text-[80%]" (dblclick)="additionalEventIdClicked(additionalEventId)">
            <div>{{ getEventText(additionalEventId) }}</div>
          </div>
        }
      </div>
    </div>


    <div fxLayout="column" fxLayoutAlign="center center">
      <div>Voll Tage</div>
      <div *ngFor="let dateString of workSession.additionalDateStrings">
        <div>{{ dateString | nxtDate: 'dd.MM.yyyy' }}</div>
      </div>
    </div>
    <div class="flex flex-row justify-around pt-2">
      <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
      <nxt-button (click)="saveClicked()">Speichern</nxt-button>
    </div>
  </div>
}
