import {Component} from '@angular/core';
import {NxtYearFinishRow} from '../../common-interfaces/nxt.year-finish-row.interface';
import {SocketService} from '../../services/socket/socket.service';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {YearFinishService} from './year-finish.service';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {MoneyPipe} from '../../pipes/money.pipe';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {FormsModule} from '@angular/forms';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {InputComponent} from '../form-controls/input/input.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-year-finish',
    templateUrl: './year-finish.component.html',
    styleUrls: ['./year-finish.component.scss'],
    imports: [FlexModule, InputComponent, MatRadioGroup, FormsModule, MatRadioButton, NxtDatagridComponent, MoneyPipe]
})
export class YearFinishComponent {
  columnDefs: NxtColDef[] = [
    {headerName: 'Datum', field: 'date', nxtFieldType: NxtFieldType.Date_germanDate},
    {headerName: 'Name', field: 'name', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Betrag', field: 'value', nxtFieldType: NxtFieldType.Money},
    {headerName: 'method', field: 'method', nxtFieldType: NxtFieldType.Text},
    {headerName: 'type', field: 'type', nxtFieldType: NxtFieldType.Text},
    {headerName: 'eventId', field: 'eventId', nxtFieldType: NxtFieldType.ShowCalendarEvent, hide: true},
    {headerName: 'info', field: 'info', nxtFieldType: NxtFieldType.Text},
    {headerName: 'info2', field: 'info2', nxtFieldType: NxtFieldType.Text},
  ];
  data: NxtYearFinishRow[];
  sum = 0;
  quickFilterText = '';
  year = '2020';
  more = false;

  constructor(
    private socketService: SocketService,
    private yearFinishService: YearFinishService
  ) {
    this.load();
  }

  async load() {
    this.data = await this.socketService.getYearFinish({year: this.year, more: this.more});


    this.sum = this.data.reduce((sum, d) => sum + d.value, 0);
  }
  }
