import {Component, OnInit, Optional} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {NxtComponent} from '../nxt.component';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {DialogRef} from '@angular/cdk/dialog';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {ClipboardService} from '../../services/clipboard.service';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgIf, NgStyle} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-sms-code',
    templateUrl: './sms-code.component.html',
    styleUrls: ['./sms-code.component.scss'],
    imports: [FlexModule, NgIf, NgStyle, ExtendedModule, NxtButtonComponent]
})
export class SmsCodeComponent extends NxtComponent implements OnInit {

  constructor(
    private socketService: SocketService,
    private clipboardService: ClipboardService,
    @Optional() public dialogRef: DialogRef<any>
  ) {
    super();
  }

  public data: { code: string, createdAt: number };
  dateText = '';
  dateFromNowText = '';
  code = '';
  toOld = false;
  type: 'PayPal' | 'Klarna' | 'Google';

  nxtOnDestroy(): void {
  }

  ngOnInit(): void {
    if (this.type) {
      this.load(this.type);
    }
  }

  load(type: 'PayPal' | 'Klarna' | 'Google') {
    this.type = type;
    if (type === 'PayPal') {
      this.pushSocketSubscription = this.socketService.subscribeNew('getPaypalSmsCode', (data: { code: string, createdAt: number }) => {
        this.codeReceived(data);
      }, {emitInitial: true});
    } else if (type === 'Klarna') {
      this.pushSocketSubscription = this.socketService.subscribeNew('getKlarnaSmsCode', (data: { code: string, createdAt: number }) => {
        this.codeReceived(data);
      }, {emitInitial: true});
    } else if (type === 'Google') {
      this.pushSocketSubscription = this.socketService.subscribeNew('getGoogleSmsCode', (data: { code: string, createdAt: number }) => {
        this.codeReceived(data);
      }, {emitInitial: true});
    }
  }

  copyAndClose() {
    this.clipboardService.copyToClipboard(this.code);
    this.dialogRef.close();
  }

  private codeReceived(data: { code: string, createdAt: number }) {
    if (data?.createdAt) {
      this.dateText = data.createdAt.dateFormat('dd.MM.yyyy HH:mm:ss');
      this.toOld = Date.now() - (DurationTools.DURATION_1MINUTE * 3) > data.createdAt;
      this.dateFromNowText = DateTools.dateDiffToNowText(data.createdAt, true);
      this.code = data.code;
      this.clipboardService.copyToClipboard(this.code);
    }
  }
}
