import {Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {DialogService} from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class BirthdayService {
  constructor(
    private socketService: SocketService,
    private dialogService: DialogService
  ) {
  }

  defaultText = '';

  public async showEditBirthdayMessage() {
    let prompt = await this.socketService.getCustomerBirthdayMessageTemplate();
    if (!prompt) {
      prompt = this.defaultText;
    }
    const dialog = await this.dialogService.showTextareaOld({
      message: '<strong>KUNDEN GEBURTSTAGS SMS</strong> - {{given-name}}  {{gift-card}}',
      placeholder: '',
      prompt
    });
    const result = await dialog.afterClosed().toPromise();
    if (result) {
      this.socketService.setCustomerBirthdayMessageTemplate(result);
    }
  }
}
