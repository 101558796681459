import {Injectable} from '@angular/core';
import {StringTools} from '../common-browser/helpers/string.tools';
import {DateTools} from '../common-browser/helpers/date.tools';
import {SocketService} from './socket/socket.service';
import {
  NxtCalendarEvent,
  NxtCalendarEventBodyPutBeauty,
  NxtCalendarEventBodyPutPiercing,
  NxtCalendarEventBodyPutTattoo,
  NxtWorkType,
  UpdateCalendarEventProps,
} from '../common-interfaces/nxt.calendar-event.interface';
import {DialogService, LoadingId} from './dialog.service';
import {LoginService} from './login.service';
import {TelegramService} from './telegram.service';
import {ConfigService} from './config.service';
import {NxtPermissionId} from '../common-interfaces/nxt.user.interface';
import {PermissionService} from './permission.service';
import {PaymentTools} from '../common-browser/helpers/payment.tools';
import {DurationTools} from '../common-browser/helpers/duration.tools';
import {DecimalTools} from '../common-browser/helpers/decimal.tools';
import moment from 'moment/moment';
import {NxtContact} from '../common-interfaces/nxt.contact.interface';
import {CalendarEventEdit2Component} from '../pages/calendar-event-edit/calendar-event-edit-2/calendar-event-edit-2.component';

export interface NxtNewEventData {
  customer?: string;
  walkIn?: boolean;
  dateString?: string;
  timeFromString?: string;
  openArtist?: boolean;
  openPiercing?: boolean;
  artist?: string;
  timeTillString?: string;
  contact?: NxtContact;
  workType?: NxtWorkType;
  artistPercentage?: number;
  noAppointmentReminder?: boolean;
  priceFix?: boolean;
  artistFix?: boolean;
  artistFixReason?: string[];
  adjustPrice?: boolean;
  bodyPutsPiercing?: NxtCalendarEventBodyPutPiercing[];
  bodyPutsBeauty?: NxtCalendarEventBodyPutBeauty[];
  bodyPutPiercingCount?: number;
  priceEstimatedFrom?: number;
  priceEstimatedTill?: number;
  discountPromotionId?: string;
  info?: string;
  importantInfo?: string;
  skill?: { skills: { [key: string]: boolean }, boolSkills: { [key: string]: boolean } };
  showEventFinder?: boolean;
  askWorkType?: boolean;
  bodyPutsTattoo?: NxtCalendarEventBodyPutTattoo[];
  // freeImprovement?: boolean;
  improve?: boolean;
  improveOriginalArtist?: string;
  improveArtistShouldGetMoney?: boolean;
  noDepotNecessary?: boolean;
  fastWalkIn?: boolean;
  customerRefId?: string;
  toPayOnEventDate?: { text: string, value: string, payed: boolean, method?: 'cash' | 'paypal', methodId?: string }[];
  priceAuto?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CalendarEventService {

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private loginService: LoginService,
    private telegramService: TelegramService,
    private configService: ConfigService,
    private permissionService: PermissionService,
  ) {
  }

  public async followUpCheck(event: NxtCalendarEvent) {
    if (event.workType === 'tattoo') {
      if (event.end - event.start < DurationTools.DURATION_1HOUR * 4) {
        return;
      }

      if (this.permissionService.hasPermission(NxtPermissionId.CalendarEventEdit_DisableFollowEventInfo)) {
        return;
      }
      const contactWithEvents: NxtContact = await this.socketService.getContactWithEvents(event.customer);
      const followingEvents = contactWithEvents.events?.filter(c => c.start > event.start);
      if (followingEvents.length > 0) {
        let followText = (followingEvents.length > 1 ? 'Der Kunde hat ' + followingEvents.length + ' Folgetermine\n' : 'Der Kunde hat einen Folgetermin\n');
        for (const followingEvent of followingEvents) {
          followText += StringTools.arrowRight + ' ' + DateTools.format(followingEvent.start, 'dd.MM.yyyy') + ' (' + DateTools.dateDiffToNowOnlyDaysText(followingEvent.start) + ')\n';
        }
        await this.dialogService.showOk('Check wie weit der Artist gekommen ist!\n\n' + followText, {buttonText: 'Ja, check ich jetzt DIREKT', title: 'Folgetermin-Info'});
      }
    }
  }

  async artistGetMoneyCheck(event: NxtCalendarEvent) {
    if (event.improveArtistShouldGetMoney) {
      const payoutValue = PaymentTools.getCustomerPayedSumFromPayments(event.payments);
      if (payoutValue === 0) {
        this.dialogService.showOk('Arist bekommt Geld!\nBitte wäge ab, wie viel der Artist bekommen soll und buche ein Gutschein auf den Termin', {title: 'Nachstechtermin!'});
        return false;
      }
    }
    return true;
  }

  async eventCloseCheckAdjustPrice(event: NxtCalendarEvent) {
    if (event.adjustPrice) {
      const result = await this.dialogService.showYesNo('Dieser Termin ist mit "Preis evtl. anpassen" markiert!', {
        yesText: 'Ich habe den Preis gecheckt, es stimmt alles',
        noText: 'Termin öffnen',
        title: 'Termin mit evtl. Preis anpassen',
      });
      if (!result) {
        this.dialogService.showEvent(event.id);
      }
      return result;
    }
    return true;
  }

  async showNewEvent(newEventData: NxtNewEventData) {
    const dialog = this.dialogService.showComponentFull<CalendarEventEdit2Component>(CalendarEventEdit2Component);
    setTimeout(() => {
      dialog.componentInstance.loadEvent({newEventData});
    }, 500);
    return dialog;
  }

  async closeEventDialog(event: NxtCalendarEvent) {

    if (!event.closed && !(await this.artistGetMoneyCheck(event))) {
      return;
    }

    if (!event.closed && !(await this.eventCloseCheckAdjustPrice(event))) {
      return;
    }
    await this.followUpCheck(event);

    const payoutValue = PaymentTools.calcPayout(event.payments, event.artistPercentage, true);
    const moduloValue = event.workType === 'tattoo' ? 2.5 : 2.5;
    if (payoutValue % moduloValue !== 0) {
      const text1 = 'Der Artist bekommt ' + DecimalTools.toMoneyString(payoutValue) + '\nDas willst du nachher nicht zusammen sammeln.\nBitte passe die Artist-Prozente an.';
      this.dialogService.showOk(text1);
      return;
    }

    let text = 'Tätowierer: ' + event.artist;
    text += '\nKunde: ' + event.customerObj.givenName + ' ' + event.customerObj.familyName;
    // text += '\nMotiv: ' + event.tattooMotive;
    text += '\n\nTermin ' + (event.closed ? 'öffnen?' : 'schließen?');

    const buttons: { text: string, value: string }[] = [];
    buttons.push({text: 'Ja, ' + (event.closed ? 'Termin wieder öffnen' : 'Termin ist fertig!'), value: 'yes'});
    if (!event.closed) {
      buttons.push({text: 'Ja, fertig + 🕒 Endezeit anpassen', value: 'setEndTime'});
    }
    buttons.push({text: 'Abbrechen', value: 'no'});
    const result = await this.dialogService.showButtons(text, {buttons});

    if (typeof result !== 'string' && result.value && (result.value === 'yes' || result.value === 'setEndTime')) {
      this.dialogService.showLoading(LoadingId.CloseEvent, event.closed ? 'öffne Termin' : 'schließe Termin');

      const props: UpdateCalendarEventProps = {
        closed: false,
        start: event.start,
        end: event.end,
        endPlanned: event.endPlanned,
      };
      if (event.closed) {
        props.closed = false;
      } else {
        props.closed = true;
        if (result.value === 'setEndTime') {
          props.endPlanned = event.end;
          const oldDuration = event.end - event.start;
          props.end = Date.now();
          if (event.start > props.end) {
            props.start = (props.end - oldDuration);
          }
        }
      }
      await this.socketService.updateCalendarEventProperty(event.id, props);
      this.dialogService.hideLoading(LoadingId.CloseEvent);
      return true;
    }
    return false;
  }

  private sendTelegramSetEventEndTime(startDateTime: string, endDateTime: string, artist: string) {
    const startTimeString = DateTools.parseFormat(startDateTime, 'HH:mm');
    const endBefore = DateTools.parse(endDateTime);
    const endAfter = Date.now();
    const diffHoursAbs = moment.duration(Math.abs(endAfter - endBefore), 'ms').asHours();
    const diffMinutes = Math.floor(moment.duration(endAfter - endBefore, 'ms').asMinutes());
    const diffString = DurationTools.format(Math.abs(Math.floor(endAfter - endBefore)), 'HH:mm');
    if (diffHoursAbs > 4) {
      this.dialogService.showOk('Endezeit kann nicht gesetzt werden, der Zeitunterschied beträgt mehr als 4 Std.');
      return false;
    }
    const before = DateTools.format(endBefore, 'HH:mm');
    const to = DateTools.format(endAfter, 'HH:mm');

    let text = '**NEUE ENDEZEIT - ' + (diffMinutes > 0 ? 'SPÄTER' : 'FRÜHER') + '**';
    text += '\nBenutzer: ' + this.loginService.getUsername();
    text += '\nArtist: ' + artist;
    text += '\nGeplant: ' + startTimeString + ' - ' + before;
    text += '\n' + before + ' Uhr -> ' + to + ' Uhr';
    if (Math.abs(diffMinutes) < 60) {
      text += '\n' + Math.abs(diffMinutes) + ' Minuten ';
    } else {
      text += '\n' + diffString + ' ';
    }
    text += (diffMinutes > 0 ? 'später' : 'früher') + ' als geplant.';

    if (Math.abs(diffMinutes) > 60) {
      this.socketService.sendTelegramStudioInfo(text);
    }
    return true;
  }
}
