import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtPageComponent} from '../nxt-page/nxt-page.component';
import {NxtPageHeaderComponent} from '../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageHeaderTitleComponent} from '../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageContentComponent} from '../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {DialogRef} from '@angular/cdk/dialog';
import {DialogService} from '../../services/dialog.service';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtKlarnaDispute} from '../../common-interfaces/nxt.klarna-order.interface';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {SocketService} from '../../services/socket/socket.service';
import {JsonTools} from '../../common-browser/helpers/json.tools';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {KlarnaTools} from '../../common-browser/helpers/klarna-tools';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'nxt-klarna-disputes',
    templateUrl: './klarna-disputes.component.html',
    styleUrls: ['./klarna-disputes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NxtPageComponent,
        NxtPageHeaderComponent,
        NxtPageHeaderTitleComponent,
        NxtPageContentComponent,
        NxtPageFooterComponent,
        NxtDatagridComponent,
    ]
})

export class KlarnaDisputesComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  klarnaDisputes = signal<NxtKlarnaDispute[]>([]);

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  dialogRef = inject(MatDialogRef, {optional: true});
  private dialogService = inject(DialogService);
  private socketService = inject(SocketService);

  columnDefs: NxtColDef<NxtKlarnaDispute>[] = [
    {headerName: 'Eröffnet', field: 'openedAt', nxtFieldType: NxtFieldType.Date_germanDateTime},
    {headerName: 'Fristablauf', field: 'rawDispute.deadline_expires_at', nxtFieldType: NxtFieldType.Date_germanDateTime},
    {headerName: 'Id', valueGetter: params => params.data.id.split(':')[5], hide: true},
    {headerName: 'Shop', field: 'shopOrderId', nxtOnCellClicked: params => this.showJson(params.data.rawDispute)},
    {headerName: 'Name', field: 'name'},
    {headerName: 'Status', field: 'rawDispute.status', valueFormatter: params => KlarnaTools.getDisputeText('status', params.value)},
    {headerName: 'Info', field: 'statusText'},
    {headerName: 'Beanstandet', field: 'value', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Bekommen', field: 'refundValue', nxtFieldType: NxtFieldType.Money},

    {headerName: 'Untersuchugsstatus', field: 'rawDispute.investigation_status'},
    {headerName: 'Öffnungsgrund', field: 'reason', valueFormatter: params => KlarnaTools.getDisputeText('reason', params.value)},

    // {headerName: 'Closed At', field: 'rawDispute.closed_at'},
    {headerName: 'Ergebnis', field: 'rawDispute.closing_reason', valueFormatter: params => KlarnaTools.getDisputeText('closing_reason', params.value)},
    {
      headerName: 'Schließungsgrund',
      field: 'rawDispute.closing_reason_detailed',
      valueFormatter: params => KlarnaTools.getDisputeText('closing_reason_detailed', params.value),
    },
    {headerName: 'Requests', valueGetter: params => params.data?.rawDispute?.requests.length, nxtOnCellClicked: params => this.showJson(params.data.rawDispute.requests)},
    {headerName: 'Refunds', valueGetter: params => params.data?.refunds?.length, nxtOnCellClicked: params => this.showJson(params.data.refunds)},


    {headerName: 'Chargeback Amount', field: 'rawDispute.chargeback_amount.amount'},
  ];


  constructor() {
    super();
  }

  ngOnInit() {
    this.load();

  }

  nxtOnDestroy() {
  }


  private async load() {
    this.klarnaDisputes.set((await this.socketService.getKlarnaDisputes()).sortNumber('openedAt', true));
  }

  private showJson(data: any) {
    this.dialogService.showOk(JsonTools.stringifyFormat(data, '\t'));
  }

  protected readonly DialogRef = DialogRef;
}
