<div class="nxt-page">
  <div class="flex flex-row">
    <nxt-date-range-picker
      [(value)]="date"
      (valueChange)="find()"
    >
    </nxt-date-range-picker>

    <div class="flex flex-col ml-5 items-end pb-2">
      <table>
        <tr>
          <td>Gesamt</td>
          <td class="right">{{ totalValue() | money }}</td>
        </tr>
        <tr>
          <td class="pr-3">Tattoo-Tickets</td>
          <td class="right">{{ totalValueTattooTickets() | money }}</td>
        </tr>
        <tr>
          <td>Gutscheine</td>
          <td class="right">{{ totalValueGiftCards() | money }}</td>
        </tr>
        <tr>
          <td>DaySessions</td>
          <td class="right">{{ totalValueDaySessions() | money }}</td>
        </tr>
      </table>
    </div>

  </div>
  <nxt-datagrid
    class="h-full"
    [showFooter]="true"
    [rowData]="klarnaOrders()"
    [columnDefs]="columnDefs()"
  >
  </nxt-datagrid>
</div>
