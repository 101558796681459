<div class="flex w-full flex-col gap-3 p-3">

  @if (studioOptions().length > 0) {
    <div class="filter-control">
      <mat-button-toggle-group class="flex !flex-col md:!flex-row" [(ngModel)]="filterStudio" [multiple]="true">
        @for (studioOption of studioOptions(); track studioOption) {
          <mat-button-toggle [value]="studioOption">{{ studioOption | uppercase }}</mat-button-toggle>
        }
      </mat-button-toggle-group>
    </div>
  }

  <div class="filter-control">
    <nxt-slide-toggle [(value)]="filterRating" placeholder="Bewertung"></nxt-slide-toggle>
    @if (filterRating()) {
      <div>Bewertung {{ filterRatingMin() }} bis {{ filterRatingMax() }}</div>
      <div class="flex w-full flex-row">
        <mat-slider class="w-full" min="1" max="10" [showTickMarks]="true" [step]="1">
          <input value="1" [(ngModel)]="filterRatingMin" matSliderStartThumb>
          <input value="10" [(ngModel)]="filterRatingMax" matSliderEndThumb>
        </mat-slider>
      </div>
    }
  </div>

  <div class="filter-control">
    <div class="flex w-full flex-row">
      <nxt-autocomplete
        placeholder="Artist"
        [(value)]="filterArtist"
        [options]="artistOptions()"
        [displayInOptionWith]="DisplayWithTools.displayWithText"
        [filterFields]="['text']"
        [showClearIcon]="true"
      />
    </div>
  </div>

  <div class="filter-control">
    <div (click)="selectSkillsClicked()" nxtClickAnimation class="filter-skill-box center">
      @if (filterSkillsText()) {
        {{ filterSkillsText() }}
      } @else {
        Kein Skill ausgewählt
      }
    </div>
  </div>

  <div class="filter-control">
    <div (click)="selectBodyPutClicked()" nxtClickAnimation class="filter-skill-box center">
      {{ filterBodyPutText() }}
    </div>
  </div>

  <div class="filter-control">
    <nxt-input placeholder="Motiv" [noPadding]="true" [clearIcon]="true" [(value)]="filterMotive"></nxt-input>
  </div>

  <div class="filter-control">
    <mat-button-toggle-group [(ngModel)]="filterDateType">
      <mat-button-toggle value="range">
        <div class="flex h-full justify-center leading-snug">Zeitraum<br/>Auswählen</div>
      </mat-button-toggle>
      <mat-button-toggle value="today">
        <div class="flex h-full justify-center leading-snug">Heute</div>
      </mat-button-toggle>
      <mat-button-toggle value="last7Days">
        <div class="flex h-full justify-center leading-snug">Letzten<br/>7 Tage</div>
      </mat-button-toggle>
      <mat-button-toggle value="last30Days">
        <div class="flex h-full justify-center leading-snug">letzten<br/>30 Tage</div>
      </mat-button-toggle>
    </mat-button-toggle-group>

    @if (filterDateType() === 'range') {
      <div class="mt-3 flex flex-row gap-4">
        <nxt-date-picker-2
          [(value)]="filterDateMin"
          placeholder="Termin von"
        />
        <nxt-date-picker-2
          [(value)]="filterDateMax"
          placeholder="Termin bis"
        />
      </div>
    }
  </div>
  <div class="flex flex-row items-center justify-around">
    <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
    <nxt-button (click)="okClicked()">OK</nxt-button>
  </div>
</div>

