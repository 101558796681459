import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DurationTools} from '../common-browser/helpers/duration.tools';

@Pipe({
    name: 'duration',
    standalone: true
})
export class DurationPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, format ?: string): string {
    return DurationTools.format(value, format);
  }
}
