import {NxtEmployee, NxtEmployeeEvent} from '../../common-interfaces/nxt.employee.interface';
import {WithoutNxtDbFields} from '../../common-interfaces/nxt.db-fields.interface';
import {DurationTools} from './duration.tools';
import {PublicHolidaysTools} from './public-holidays.tools';
import {DateTools} from './date.tools';
import {DayOfWeek} from '../../common-interfaces/date.interface';

export class EmployeeTools {

  static getHoursPerWeek(employee: NxtEmployee, dateString: string, region: 'he' | 'nw' | 'by' | 'sp' | 'ch' | null): number {
    const mondayBefore = DateTools.getMondayBefore(dateString, 0).dateFormatDate();
    const endOfWeek = mondayBefore.dateParse().dateAddDays(5).dateFormatDate();

    if (!employee.hoursPerWeek) {
      return 0;
    }
    const hoursPerWeek: number[] = [];
    for (const dateString of DateTools.getDaysBetween(mondayBefore, endOfWeek)) {
      const item = employee.hoursPerWeek.find(h => {
        const startOk = dateString.dateParse() >= h.startDateString?.dateParse() || 0;
        const endOk = !h.endDateString || dateString.dateParse() <= h.endDateString.dateParse();
        return startOk && endOk;
      });
      let hoursPerWeekOnThisDay = 0;
      if (item) {
        hoursPerWeekOnThisDay = item.value;
      }
      const shouldWorkOnThisDay = hoursPerWeekOnThisDay > 0;
      if (shouldWorkOnThisDay) {
        // wenn es eh dem Mitarbeiter sein freier Tag war, hätte er nicht arbeiten müssen
        const isFreeEmployeeFreeDay = EmployeeTools.isEmployeesFreeDay(employee, dateString);
        if (isFreeEmployeeFreeDay) {
          hoursPerWeekOnThisDay = 0;
        } else {
          // ist es ein Feiertag? Dann muss er auch nicht arbeiten
          if (region) {
            const isPublicHoliday = PublicHolidaysTools.getPublicHoliday(dateString, region);
            if (isPublicHoliday) {
              hoursPerWeekOnThisDay = 0;
            }
          }
        }
        if (hoursPerWeekOnThisDay) {
          hoursPerWeek.push(hoursPerWeekOnThisDay);
        }
        // evtl war aber feiertag, und es war nicht ihr freier Tag, dann müsste sie nicht arbeiten

      }

    }
    const totalHours = hoursPerWeek.reduce((a, b) => a + b, 0);
    const hoursPerWeekOnWorkingDaysAverage = totalHours / hoursPerWeek.length;
    if (hoursPerWeek.length === 0) {
      return 0;
    }
    const daysPerWeek = hoursPerWeekOnWorkingDaysAverage / 8;
    const result = totalHours / daysPerWeek;
    return result;
  }

  /**
   * wenn der mitarbeiter raus ist, kommt hier auch nix zurück
   * @param employee
   * @param dateString
   */
  static getFreeDays(employee: WithoutNxtDbFields<NxtEmployee>, dateString: string): DayOfWeek[] {
    if (employee.givenName === 'Salome' && dateString === '2024-08-12') {
      console.log('getFreeDays', employee, dateString);
    }
    const date = dateString.dateParse();
    if (employee.endDateString && date >= employee.endDateString.dateParse()) {
      return [];
    }
    const item = employee.freeDays?.find(h => {
      const startOk = date >= h.startDateString?.dateParse() || 0;
      const endOk = !h.endDateString || date <= h.endDateString.dateParse();
      return startOk && endOk;
    });
    if (item?.value) {
      return item.value;
    }
    return [];
  }

  /**
   * wenn der mitarbeiter raus ist, kommt hier auch nix zurück
   * @param employee
   * @param dateString
   */
  static isEmployeesFreeDay(employee: NxtEmployee, dateString: string): boolean {
    const date = dateString.dateParse();
    if (employee.endDateString && date >= employee.endDateString.dateParse()) {
      return false;
    }
    const item = employee.freeDays?.find(h => {
      const startOk = date >= h.startDateString?.dateParse() || 0;
      const endOk = !h.endDateString || date <= h.endDateString.dateParse();
      return startOk && endOk;
    });
    if (item) {
      return item.value.includes(DateTools.getDayOfWeek(dateString));
    }
    return false;
  }


  /**
   * gibt die Anzahl der Feiertage zurück, die in der Woche liegen, wenn der Mitarbeiter an dem Tag seinen freien Tag hat, wird er nicht mit gezählt
   */
  static getPublicHolidayCountInWeek(employee: NxtEmployee, dateString: string, region: 'he' | 'nw' | 'by' | 'sp' | 'ch'): number {
    const monday = DateTools.getMondayBefore(dateString, 0);
    const sunday = monday.dateAddDays(6);
    let holidayCount = 0;
    const freeDays = EmployeeTools.getFreeDays(employee, dateString);
    DateTools.forEachDay(monday.dateFormatDate(), sunday.dateFormatDate(), date => {
      if (PublicHolidaysTools.getPublicHoliday(date, region)) {
        // Feiertag ja, wenn es aber der freie Tag des Mitarbeiters ist, dann wird er von der muss arbeitszeit nicht abgezogen
        const dayOfWeek = DateTools.getDayOfWeek(date);
        if (!freeDays.includes(dayOfWeek)) {
          holidayCount++;
        }
      }
    });
    return holidayCount;
  }

  static getEmpty(): WithoutNxtDbFields<NxtEmployee> {
    return {
      givenName: '',
      familyName: '',
      fullName: '',
      abbreviation: '',
      birthday: '',
      mobile: '',
      hoursPerWeek: [],
      shouldPlanWork: false,
      color: '',
      appUserUid: '',
      timeTrackOnlyFromStudio: false,
      doTimeTrack: false,
      seeWorkTime: false,
      freeDays: [],
      telegramChatId: 0,
      startDateString: '',
      endDateString: '',
      holidayCount: [],
      earliestStartDependOnWorkplace: false,
      hasLeft: false,
    };
  }

  static getEmptyEmployeeEvent(): WithoutNxtDbFields<NxtEmployeeEvent> {
    return {
      dateString: '',
      date: 0,
      employeeId: '',
      start: 0,
      end: 0,
      type: 'work',
      employeeName: '',
      workplaces: [],
      studio: '',
      label: '',
      infos: [],
    };
  }

  /*public static getEmptyTimeTrackState(): WithoutNxtDbFields<NxtEmployeeTimeTrackState> {
    return {
      employeeId: '',
      state: 'no-work',
      stateTimestamp: 0
    };
  }*/


  static test() {
    const result1 = DurationTools.format(EmployeeTools.getMinBreakFromDayWorkDuration(DurationTools.parse('01:00')), 'HH:mm');
    const result2 = DurationTools.format(EmployeeTools.getMinBreakFromDayWorkDuration(DurationTools.parse('02:00')), 'HH:mm');
    const result3 = DurationTools.format(EmployeeTools.getMinBreakFromDayWorkDuration(DurationTools.parse('03:00')), 'HH:mm');
    const result4 = DurationTools.format(EmployeeTools.getMinBreakFromDayWorkDuration(DurationTools.parse('04:15')), 'HH:mm');
    const result5 = DurationTools.format(EmployeeTools.getMinBreakFromDayWorkDuration(DurationTools.parse('05:30')), 'HH:mm');
    const result6 = DurationTools.format(EmployeeTools.getMinBreakFromDayWorkDuration(DurationTools.parse('09:00')), 'HH:mm');
    const result7 = DurationTools.format(EmployeeTools.getMinBreakFromDayWorkDuration(DurationTools.parse('09:15')), 'HH:mm');
    const result8 = DurationTools.format(EmployeeTools.getMinBreakFromDayWorkDuration(DurationTools.parse('09:29')), 'HH:mm');
    const result9 = DurationTools.format(EmployeeTools.getMinBreakFromDayWorkDuration(DurationTools.parse('09:31')), 'HH:mm');
    const result10 = DurationTools.format(EmployeeTools.getMinBreakFromDayWorkDuration(DurationTools.parse('09:45')), 'HH:mm');
    const result11 = DurationTools.format(EmployeeTools.getMinBreakFromDayWorkDuration(DurationTools.parse('09:46')), 'HH:mm');
  }

  static getMinBreakFromDayWorkDuration(workDuration: number) {
    if (workDuration > (DurationTools.DURATION_1HOUR * 9 + DurationTools.DURATION_1MINUTE * 30)) {
      return DurationTools.DURATION_1MINUTE * 45;
    }
    if (workDuration > DurationTools.DURATION_1HOUR * 6 + DurationTools.DURATION_1MINUTE * 15) {
      return DurationTools.DURATION_1MINUTE * 30;
    }
    if (workDuration > DurationTools.DURATION_1HOUR * 2) {
      return DurationTools.DURATION_1MINUTE * 15;
    }
    return 0;
  }

  static fixHolidayOrSickDayDuration(employee: NxtEmployee, duration: number) {
    if (duration > DurationTools.DURATION_1HOUR * 7) {
      return DurationTools.DURATION_1HOUR * 8;
    }
    if (duration > DurationTools.DURATION_1HOUR * 4) {
      return DurationTools.DURATION_1HOUR * 4;
    }
    return duration;
  }
}
