import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtArtist} from '../../../common-interfaces/nxt.artist.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {QRCodeComponent} from 'angularx-qrcode';

@Component({
  selector: 'nxt-telegram-artist-start-chat',
  templateUrl: './telegram-artist-start-chat.component.html',
  styleUrls: ['./telegram-artist-start-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtButtonComponent, QRCodeComponent],
  standalone: true,
})

export class TelegramArtistStartChatComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  qrCode = signal<string>('');
  artist = signal<NxtArtist | null>(null);


  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private dialogRef = inject(MatDialogRef);


  constructor() {
    super();
  }

  ngOnInit() {
    this.pushSocketSubscription = this.socketService.subscribeNew('eventArtistChanged', async (data) => {
      if (this.artist().id === data.record.id) {
        if (data.record.telegramChatId) {
          this.dialogRef.close();
        }
      }
    });
  }

  nxtOnDestroy() {
  }

  async setArtist(artistId: string) {
    this.artist.set(await this.socketService.getArtist(artistId));
    if (this.artist()) {
      this.qrCode.set('https://t.me/nxtlvlink_bot?start=' + btoa('Artist:' + this.artist().name));
    }
  }

  cancelClicked() {
    this.dialogRef.close();
  }
}
