<div class="flex flex-col">
  <div>Transaktion für {{ form.controls.account?.value?.name }}</div>
  <div class="flex flex-col">
    <!--<nxt-autocomplete
      [options]="accounts()"
      [nxtFormControl]="form.controls.account"
      [filterFields]="['name']"
      [displayWith]="DisplayWithTools.displayWithName"
      [displayInOptionWith]="DisplayWithTools.displayWithName"
    />-->
    <nxt-autocomplete
      [options]="categories()"
      [nxtFormControl]="form.controls.category"
      [filterFields]="['name']"
      [displayWith]="DisplayWithTools.displayWithName"
      [displayInOptionWith]="DisplayWithTools.displayWithName"
    />
    <nxt-autocomplete
      [options]="regions()"
      [nxtFormControl]="form.controls.region"
      [filterFields]="['name']"
      [displayWith]="DisplayWithTools.displayWithName"
      [displayInOptionWith]="DisplayWithTools.displayWithName"
    />
    @if (form.controls.category?.value?.isTransfer) {
      <nxt-autocomplete
        [options]="otherAccounts()"
        [nxtFormControl]="form.controls.fromAccount"
        [filterFields]="['name']"
        [displayWith]="DisplayWithTools.displayWithName"
        [displayInOptionWith]="DisplayWithTools.displayWithName"
      />
    }
    <div class="flex flex-row justify-between items-center">
      <nxt-input
        class="w-[150px]"
        placeholder="Betrag"
        [isMoney]="true"
        [nxtFormControl]="form.controls.value"
      />
      <mat-button-toggle-group [formControl]="form.controls.direction">
        <mat-button-toggle value="in">REIN</mat-button-toggle>
        <mat-button-toggle value="out">RAUS</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div>
      <nxt-textarea class="w-[350px]" [nxtFormControl]="form.controls.info"></nxt-textarea>
    </div>
  </div>
  <div class="flex flex-row justify-around w-full">
    <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
    <nxt-button (click)="saveClicked()">Speichern</nxt-button>
  </div>
</div>
