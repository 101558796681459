import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtCalendarEvent} from '../../../common-interfaces/nxt.calendar-event.interface';
import {EventInfoCalc, NxtEventCalcItemList} from '../../../common-browser/helpers/event-info-calc';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {NgFor, NgIf, NgTemplateOutlet} from '@angular/common';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';

@Component({
  selector: 'nxt-events-infos',
  templateUrl: './events-infos.component.html',
  styleUrls: ['./events-infos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtButtonIconComponent, NgIf, NgTemplateOutlet, NxtButtonComponent, NgFor, SafeHtmlPipe],
  standalone: true,
})
export class EventsInfosComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  artist: NxtEventCalcItemList[];

  constructor(
    @Optional() private dialogRef: MatDialogRef<any>,
    private cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  piercings: NxtEventCalcItemList;
  tattoo: NxtEventCalcItemList;
  title = '';

  protected readonly NxtFieldType = NxtFieldType;
  currentItemLists: NxtEventCalcItemList[] | null = null;

  /*chartOptions: AgChartOptions = {
    theme: 'ag-default-dark',
    axes: [
      {
        type: 'category',
        position: 'bottom',
      },
      {
        type: 'number',
        position: 'left',
        keys: ['geld1', 'geld2'],
        title: {
          text: 'Euro',
        },
        label: {
          formatter: (params) => {
            return params.value / 1000 + 'M';
          },
        },
      },
      {
        type: 'number',
        position: 'right',
        keys: ['eventCount'],
        title: {
          enabled: true,
          text: 'Anzahl',
        },
        label: {
          formatter: (params) => {
            return params.value / 1000 + 'k';
          },
        },
      },
    ],
    data: [
      {artist: 'Hans', geld1: 1480, geld2: 3843, eventCount: 105311},
      {artist: 'Peter', geld1: 1440, geld2: 3868, eventCount: 112260},
      {artist: 'Willhelm', geld1: 1457, geld2: 3900, eventCount: 100491},
      {artist: '2016', geld1: 1460, geld2: 3895, eventCount: 110428},
      {artist: '2017', geld1: 1456, geld2: 3891, eventCount: 105601},
      {artist: '2018', geld1: 1425, geld2: 3837, eventCount: 110508},
      {artist: '2019', geld1: 1371, geld2: 3775, eventCount: 135198},
    ],
    // Series: Defines which chart type and data to use
    series: [
      {
        type: 'bar',
        xKey: 'artist',
        yKey: 'geld1',
        yName: 'Geld 1',
      },
      {
        type: 'bar',
        xKey: 'artist',
        yKey: 'geld2',
        yName: 'Geld 2',
      },
      {
        type: 'bar',
        xKey: 'artist',
        yKey: 'eventCount',
        yName: 'Anzahl Termine',
      },
    ],
  };*/

  ngOnInit() {

  }

  nxtOnDestroy() {

  }

  calc(events: NxtCalendarEvent[], title: string) {
    events.sortNumber('start');
    this.tattoo = EventInfoCalc.calcWorkType(events, 'tattoo');
    this.piercings = EventInfoCalc.calcWorkType(events, 'piercing');
    this.artist = EventInfoCalc.calcArtists(events);
    this.title = title;
  }

  buildChart(lists: NxtEventCalcItemList[]) {

    const moneyFields = lists[0].items.filter(i => i.type === NxtFieldType.Money).map(i => i.type);


    /*const chartOptions: AgChartOptions = {
      theme: 'ag-default-dark',
      axes: [],
      data: [],
      series: [],
    };*/

  }

  close() {
    this.dialogRef.close();
  }
}
