import {Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'nxt-smooth-width',
    template: `
    <ng-content></ng-content>
  `,
    styles: [`
    :host {
      display: block;
      overflow: hidden;
    }
  `],
    animations: [
        trigger('grow', [
            transition('void <=> *', []),
            transition('* <=> *', [
                style({ width: '{{startWidth}}px', overflow: 'hidden' }),
                animate('{{duration}}s ease'),
            ], { params: { startWidth: 0, endWidth: 0 } })
        ])
    ],
    standalone: true
})
export class SmoothWidthComponent implements OnChanges {
  @Input() trigger: any;
  @Input() hide: any;

  @Input() duration = 0.5;
  @Input() hideOnFalse = false;
  @Output() widthChanged = new EventEmitter<void>();

  startWidth: number;
  startOpacity = 1;

  @HostBinding('@grow') grow: any;

  @HostListener('@grow.done', ['$event'])
  growDoneEvent(ev) {
    this.widthChanged.emit();
  }

  private firstChange = true;

  constructor(private element: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.trigger) {
      if (this.firstChange) {
        this.firstChange = false;
        return;
      }
      this.startWidth = this.element.nativeElement.clientWidth;
      requestAnimationFrame(() => {
        this.grow = {
          value: this.trigger,
          params: {startWidth: this.startWidth, duration: this.duration}
        };
      });
    }
  }
}
