<div *ngIf="showDebug2" style="z-index:99999; position: fixed; top:0; left: 0; background-color: red; font-size: 200%">CHANGES!"</div>
<div [ngClass]="{'nxt-page': showBackgroundColor}" style=" position:relative; width:100%; height:100%; padding: 0">
  <!--<div *ngIf="isFromCalendarView" (click)="testJulian()">
    TEST FÜR JULIAN
  </div>-->
  <div *ngIf="form" [formGroup]="form" style="height:100%" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between" [ngStyle]="{margin: '0 20px'}" (click)="hideAllToasts()">

      <div style="position: fixed; left: 3px; top: 3px" (click)="showChangesClicked()" *ngIf="changes().length > 0">*</div>
      <nxt-button-icon
        tooltip="Speichern"
        (click)="saveAndCloseDialog(false)"
        [onlyOneClick]="true"
      >save
      </nxt-button-icon>

      <nxt-button-icon
        nxtPermission="IsJulian"
        tooltip="Info"
        (click)="showDebugClicked()"
        [onlyOneClick]="true"
      >info
      </nxt-button-icon>

      <nxt-button-icon
        *ngIf="!form.get('closed').value && forceSaveHack"
        tooltip="Speichern und Termin schließen"
        (click)="saveAndCloseDialog(true)"
        [onlyOneClick]="true"
      >check_circle
      </nxt-button-icon>

      <nxt-button-icon *ngIf="this.form.get('fastWalkIn').value && !isNewEvent"
                       (click)="printFastWalkInNoFromEvent()"
                       tooltip="Walk-In-Nummer nochmal drucken"
      >print
      </nxt-button-icon>

      <nxt-button-icon *ngIf="isMallorca && false" matTooltip="Free Shot" (click)="printFreeShot()">local_drink</nxt-button-icon>

      <nxt-button-icon
        *ngIf="!form.get('closed').value"
        tooltip="Speichern und Folgetermin anlegen"
        (click)="saveAndFollowUp()"
      >note_add
      </nxt-button-icon>

      <nxt-button-icon
        tooltip="Speichern & Klonen"
        (click)="safeAndCloneEventClicked()"
      >library_add
      </nxt-button-icon>

      <nxt-button-icon
        tooltip="WhatsApp-Chat"
        (click)="showWhatsAppChat()"
      >chat
      </nxt-button-icon>

      <nxt-button-icon nxtPermission="IsMa"
                       (click)="printShishaClicked()"
                       matTooltip="Shisha"
                       *ngIf="!isNewEvent" [svg]="IconTools.Awesome.Shisha"></nxt-button-icon>
      <!--<nxt-button style="font-size: 14px !important;" >Termin ist geschlossen</nxt-button>-->
      <!--<div  class="leading-none text-80 center" *ngIf="form.get('closed').value">Termin ist<br>geschlossen</div>-->

      <!--<nxt-button style="font-size: 14px !important;" *ngIf="form.get('closed').value && !paymentPayoutExist" (click)="addArtistPayoutPayment()">Artistbuchung
        hinzufügen
      </nxt-button>-->

      <nxt-button-icon
        (click)="showHistoryClicked()"
        tooltip="Verlauf"
      >history
      </nxt-button-icon>

      <nxt-button-icon
        (click)="shareEventClicked()"
        tooltip="Termin teilen"
      >share
      </nxt-button-icon>

      <nxt-button-icon
        (click)="copyEventToClipboardClicked()"
        tooltip="Termin in die Zwischenablage kopieren"
      >content_copy
      </nxt-button-icon>

      <nxt-button-icon
        (click)="showAppointmentConfirmationTextClicked()"
        tooltip="Terminbestätigung"
      >forward
      </nxt-button-icon>

      <nxt-button-icon *ngIf="!isNewEvent" (click)="printQrClicked()" tooltip="QR-Code drucken">qr_code</nxt-button-icon>

      <nxt-button-icon *ngIf="!isNewEvent" (click)="printArtist()" tooltip="Artist drucken">print_add</nxt-button-icon>

      <!--<nxt-button-icon *ngIf="!isNewEvent" (click)="printQrEvent(true)" tooltip="QR-Code anzeigen">qr_code</nxt-button-icon>-->

      <nxt-button-icon *ngIf="(forceSaveHack && isJulian) && !isNewEvent" (click)="deleteEvent()" tooltip="Termin löschen">delete
      </nxt-button-icon>

      @if (dialogRef) {
        <nxt-button-icon
          (click)="closeWithoutSaveClicked()"
          tooltip="Ohne speichern schließen"
        >close
        </nxt-button-icon>
      }
    </div>

    <div *ngIf="!showContent && showReloadEvent" class="w-full text-150 h-100 mouse-pointer" fxLayoutAlign="center center">
      <nxt-button (click)="reload()">Termin erneut laden</nxt-button>
    </div>
    <div fxFlex class="form-scroll-container">
      <div *ngIf="form && form.get('customer') && showContent" [formGroup]="form" class="form-wrapper">

        <div fxLayout="row" fxLayout.lt-sm="column">
          @if (this.originalEvent().ratingValue) {
            <mat-card class="mr-2 flex cursor-pointer items-center justify-center" (click)="showRatingClicked()">
              @if (this.originalEvent().ratingValue < 5) {
                <mat-icon>thumb_down</mat-icon>
              } @else if (this.originalEvent().ratingValue < 7) {
                <mat-icon>star_half</mat-icon>
              } @else {
                <mat-icon>star</mat-icon>
              }
              {{ this.originalEvent().ratingValue }}
            </mat-card>
          }


          <mat-card fxFlex fxFlex.lt-sm="100%">
            <div [nxtMultiClick]="3" [style.color]="forceSaveHack ? 'red' : ''" (nxtOnMultiClick)="toggleForceSaveHack()"
                 class="p-2 text-120">{{ form.get('title').value ? form.get('title').value : '&nbsp;' }}
            </div>
          </mat-card>


        </div>

        <nxt-divider [height]="cardSpace"></nxt-divider>

        <div fxLayout="row" fxLayout.lt-sm="column">
          <mat-card fxFlex="100%" fxFlex.lt-sm="100%" class="pb-2">
            <!--<div class="card-title" (dblclick)="toggleShowDebug()">Info</div>-->
            <div fxLayout="row" [fxLayoutGap]="controlGap">
              <div *ngIf="followUp" class="flex flex-row items-end justify-center">
                <div>Termin</div>
                <nxt-input class="w-[30px]" [noPadding]="true" [isNumber]="true" [value]="followUp.index + 1"
                           (valueChange)="followUp.index = $event -1; followUpChanged()"></nxt-input>
                <div>von</div>
                <nxt-input class="w-[30px]" [noPadding]="true" [isNumber]="true" [(value)]="followUp.total" (valueChange)="followUpChanged()"></nxt-input>
              </div>
              <div fxLayout="row" fxFlex>
                <nxt-input placeholder="Info" [nxtFormControl]="form.get('info')" [noPadding]="true"></nxt-input>
              </div>
              <div fxLayout="row" fxFlex>
                <nxt-input color="red" placeholder="Wichtige Info" [nxtFormControl]="form.get('importantInfo')" [noPadding]="true"></nxt-input>
              </div>
              <div class="flex flex-row justify-center items-center">
                <nxt-input placeholder="Rechnungsnummer" [nxtFormControl]="form.get('invoiceNumber')"
                           [noPadding]="true"></nxt-input>
                @if (form.get('invoiceNumber').value) {
                  <nxt-button-icon (click)="showInvoiceClicked()">description</nxt-button-icon>
                }
                @if (originalEvent().customerInvoice?.invoiceNumber) {
                  <div class="text-[70%] whitespace-nowrap cursor-pointer"
                       (click)="customerInvoiceClicked()">{{ originalEvent().customerInvoice?.invoiceNumber }}
                  </div>
                }
              </div>
            </div>

            <div class="mt-2">
              <nxt-input color="red" placeholder="Probleme am Tag des Termins" [nxtFormControl]="form.get('problems')"></nxt-input>
            </div>

            @if (false) {
              <div fxLayout="row">
                <div fxLayoutAlign="center center" *ngIf="!isNewEvent">
                  <nxt-button-icon (click)="newReminder()" matTooltipPosition="above" matTooltip="neue Aufgabe">notification_add</nxt-button-icon>
                </div>
                <div fxLayout="column" fxLayoutAlign="center start">
                  <div fxLayout="row" *ngFor="let reminder of reminders">
                    <div class="py-1 mouse-pointer">
                      {{ reminder.reminderDateTime | fromNowText : true }} {{ reminder.reminderDateTime | nxtDate: 'HH:mm' }}&nbsp;{{ reminder.createdBy }}
                      :&nbsp;{{ reminder.description }}
                    </div>
                  </div>
                </div>
              </div>
            }
          </mat-card>
        </div>

        <nxt-divider [height]="cardSpace"></nxt-divider>

        <div fxLayout="row">
          <mat-card fxFlex class="o-hidden">
            <!--<div class="card-title"
                 [ngStyle]="{color: !forceSaveHack ? '' : 'Red'}"
                 [nxtMultiClick]="3" (nxtOnMultiClick)="toggleForceSaveHack()"
            >Kunde
            </div>-->
            <div fxLayout="row" fxLayout.lt-sm="column" class="screen-col-container h-100" fxLayoutAlign="start center">
              <div fxFlex="400px" fxFlex.xs="100%" fxLayout="row">
                <nxt-autocomplete
                    #nxtFormControlElemCustomer
                    requiredError="ein Tattoo ohne einen Kunden?"
                    minWidth="300px"
                    placeholder="Vor- und Nachname"
                    [displayWith]="calendarEventEditDisplayFn.displayFn"
                    [displayInOptionWith]="calendarEventEditDisplayFn.displayCustomerOptionFn"
                    [nxtFormControl]="form.get('customer')"
                    [filterFields]="['searchText']"
                    [showClearIcon]="true"
                    [useFuseSearch]="true"
                    [maxOptionsToShow]="50"
                    (noEntryEnter)="customerEmptyEnter($event)"
                    (newItem)="customerEmptyEnter($event)"
                    [customSearchAsyncFn]="customerSearch"
                    [dateControlIfFirstCharDigit]="true"
                ></nxt-autocomplete>
                <nxt-button-icon
                  *ngIf="form.get('customer').value && form.get('customer').value.id"
                  (click)="editContact()"
                  matTooltip="Kunde bearbeiten">edit
                </nxt-button-icon>
              </div>
              <div class="flex h-full flex-row items-center gap-2 pb-3 pl-3 contact-buttons">
                <div *ngIf="showAddCustomer">
                  <nxt-button (click)="addCustomerClicked()">Kunde anlegen</nxt-button>
                </div>
                <div *ngIf="(cacheService.parsedContacts | async).length > 0">
                  <nxt-button-icon (click)="showParsedContactsClicked()" fa="sparkles"></nxt-button-icon>
                </div>
              </div>

              <div fxFlex.xs="100%" style="padding: 0 10px;" fxLayout="column" [innerHTML]="customerText | safeHtml">
              </div>
              @if (form.get('customer').value) {
                <nxt-col fxFlex="20%" fxFlex.xs="100%" align="start">
                  <nxt-divider width="10px"></nxt-divider>
                  @if (formGet('customer').value.taglines) {
                    <div class="tagline-button" (click)="removeTagline(tagline)" *ngFor="let tagline of formGet('customer').value.taglines"
                         [innerHTML]="tagline | safeHtml">
                    </div>
                  }
                  <!--<nxt-button (click)="addCustomerTagline()" style="font-size: 0.7em !important;">neue Kundeninfo</nxt-button>
                  <nxt-button (click)="requestImageFromCustomer()" style="font-size: 0.7em !important;">Bild per WhatsApp anfordern</nxt-button>-->
                  <div fxLayout="row">
                    <nxt-button-icon (click)="addCustomerTagline()" matTooltip="neue Kundeninfo">info</nxt-button-icon>
                    <nxt-button-icon (click)="requestImageFromCustomer()" matTooltip="Bild per WhatsApp anfordern">image</nxt-button-icon>
                    <!--<nxt-slide-toggle [(value)]="showCustomerRef" (valueChange)="showCustomerRefClicked($event)">anderer Chat
                    </nxt-slide-toggle>-->
                  </div>
                </nxt-col>
              }
            </div>
            <!--value: {{ formGet('customerRefId').value }}-->
            @if (customerRefText()) {
              <div class="flex flex-row gap-2 justify-start items-center w-fit mb-2 pl-2" style="border:1px solid #909090; border-radius: 3px">
                <div class="text-[70%]">Anderer Chat</div>

                <div>{{ customerRefText() }}</div>
                <div class="flex flex-col">
                </div>
                <nxt-button-icon
                  tooltip="Anderer WhatsApp-Chat"
                  (click)="showCustomerRefWhatsAppChatClicked()"
                >chat
                </nxt-button-icon>
                <nxt-button-icon
                  tooltip="Anderer Chat löschen"
                  (click)="setCustomerRefId('')"
                >delete
                </nxt-button-icon>
              </div>
            }

            <div fxLayout="row" *ngIf="customerHasMoreEvents">
              <div fxLayout="column" fxLayoutAlign="center center" class="pr-2">
                <nxt-button-icon (click)="showCustomerEvents()" matTooltip="Terminsliste">list</nxt-button-icon>
                <!--<nxt-button (click)="showCustomerEvents()">ÜBERSICHT</nxt-button>-->
              </div>
              <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="4px" class="mb-1 pb-1 o-auto" style="font-size: 85%">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" *ngIf="customerCanceledEvents && customerCanceledEvents.length > 0">
                  <div style="width: 100px;">abgesagt ({{ customerCanceledEvents.length }}):</div>
                  <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let customerEvent of customerCanceledEvents; let last = last">
                    <div (click)="showEvent(customerEvent.eventId)" style=" cursor: pointer;">
                      <div class="canceled" [class.not-this-event]="customerEvent.eventId !== eventId" [class.this-event]="customerEvent.eventId === eventId">
                        {{ customerEvent.start | nxtDate : 'dd.MM.yy' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" *ngIf="customerClosedEvents && customerClosedEvents.length > 0">
                  <div style="width: 100px;">fertig ({{ customerClosedEvents.length }}):</div>
                  <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let customerEvent of customerClosedEvents; let last = last">
                    <div (click)="showEvent(customerEvent.eventId)" style="cursor: pointer">
                      <div class="closed" [class.not-this-event]="customerEvent.eventId !== eventId" [class.this-event]="customerEvent.eventId === eventId">
                        {{ customerEvent.start | nxtDate : 'dd.MM.yy' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" *ngIf="customerEvents && customerEvents.length > 0">
                  <div style="width: 100px;">offen ({{ customerEvents.length }}):</div>
                  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" *ngFor="let customerEvent of customerEvents; let last = last">
                    <div
                      *ngIf="!(customerEvent.eventId === eventId && isNewEvent)"
                      (click)="showEvent(customerEvent.eventId)" [ngStyle]="{cursor: customerEvent.eventId !== eventId ? 'pointer':''}">
                      <div class="future" [class.not-this-event]="customerEvent.eventId !== eventId" [class.this-event]="customerEvent.eventId === eventId">
                        {{ customerEvent.start | nxtDate : 'dd.MM.yy' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
          <mat-card fxLayout="column" class="mat-card-left-margin" fxFlex.lt-sm="100%">
            <div *ngIf="durationText" class="card-title">Termin
              <span *ngIf="durationText" style="float:right">Dauer: {{ durationText }}</span>
            </div>

            <div fxLayoutAlign="center center" fxLayout="column" class="h-100">
              <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="8px">

                <nxt-date-picker-2
                  class="w-[120px]"
                  placeholder="Datum"
                  format="EE dd.MM.yyyy"
                  [readonly]="isCanceled"
                  [nxtFormControl]="form.get('date')"
                  [noPadding]="true"
                >
                </nxt-date-picker-2>

                <!--<nxt-date-picker
                  [noPadding]="true"
                  [readonly]="isCanceled" #nxtFormControlElemDate
                  placeholder="Datum"
                  [nxtFormControl]="form.get('date')"
                ></nxt-date-picker>-->


                <nxt-time-picker
                  [readonly]="isCanceled"
                  title="Startzeit wählen" minTime="10:00"
                  [getText]="getTimeChooserText"
                  placeholder="von"
                  [noPadding]="true"
                  [nxtFormControl]="form.get('timeFrom')"
                  (valueChange)="timeFromChanged($event)">

                </nxt-time-picker>
                <nxt-time-picker
                    #nxtFormControlElemTimeTill
                    [readonly]="isCanceled"
                    title="Endezeit wählen"
                    [minTime]="form.get('timeFrom').value"
                    [getText]="getTimeChooserText"
                    placeholder="bis"
                    [noPadding]="true"
                    [nxtFormControl]="form.get('timeTill')"
                    [showHourCounter]="true"
                >
                </nxt-time-picker>
              </div>
              <div fxLayout="row" fxLayoutAlign="center" class="pt-3">
                <nxt-slide-toggle [nxtFormControl]="formGet('noAppointmentReminder')">
                  Keine Terminerinnerung
                </nxt-slide-toggle>
              </div>
            </div>
          </mat-card>
        </div>
        <nxt-divider [height]="cardSpace"></nxt-divider>


        <nxt-smooth-height [trigger]="artistSmoothHeightTrigger">
          <div class="flex flex-row gap-2">
            <mat-card fxLayout="column" fxFlex="420px" fxFlex.lt-sm="100%">
              @if (forceSaveHack) {
                <div class="absolute card-title" [class]="artistNotAvailableText !== '' ? 'red' : ''" [nxtMultiClick]="3" (nxtOnMultiClick)="setTestData()">
                  Artist {{ artistNotAvailableText }}
                </div>
              }
              <div fxFlex fxLayout="row" fxLayoutAlign="center center">
                <div fxLayout="column" class="w-full">
                  <div fxLayout="row" class="pb-1">
                    <nxt-autocomplete
                      class="mt-3"
                      #nxtFormControlElemArtist
                      requiredError="ein Tattoo ohne einen Artist?"
                      placeholder="Artist"
                      [displayWith]="calendarEventEditDisplayFn.displayArtistCalendar"
                      [displayInOptionWith]="displayArtistCalendarOption"
                      [options]="artists"
                      [nxtFormControl]="form.get('artist')"
                      [filterFields]="['name']"
                      [showClearIcon]="true"
                      [useFuseSearch]="true"
                      nxtMatOptionClass="artist-calendar-option"
                      [maxOptionsToShow]="30"
                      [bottomNoPadding]="true"
                    ></nxt-autocomplete>
                    <div class="flex flex-col justify-center" [ngStyle]="{position:'relative', top: form.get('artist').value ? '9px' : '2px'}">
                      <nxt-button-icon (click)="checkOtherAvailableArtist()" tooltip="Anderer Artist">change_circle</nxt-button-icon>
                      @if (this.form.get('artist').value?.invoiceData?.name) {
                        <nxt-button-icon nxtPermission="Artists_Read" (click)="editArtist()" tooltip="Artist bearbeiten">edit</nxt-button-icon>
                      }
                    </div>
                  </div>

                  <div fxLayout="row" style="padding: 0 10px">
                    <div class="flex flex-row items-center" *ngIf="form.get('artist').value?.artistObj?.skills?.speed as speed">
                      <div class="red text-150 bold" *ngIf="speed < 5">!!&nbsp;</div>
                      <div [class.red]="speed < 5" [class.bold]="speed < 5">Geschwindigkeit: {{ speed }} / 10</div>
                      <div class="red text-150 bold" *ngIf="speed < 5">&nbsp;!!</div>
                    </div>
                  </div>
                  <div *ngIf="isCanceled && formGetValue('canceledAt')" style="padding: 0 10px">
                    <div>Abgesagt am {{ formGetValue('canceledAt') | nxtDate: 'dd.MM.yyyy HH:mm' }}</div>
                    <div
                      [ngClass]="{red: !formGetValue('canceledInTime')}">{{ formGetValue('canceledInTime') ? 'früh genug abgesagt' : 'nicht früh genug abgesagt' }}
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div [ngClass]="{'artist-fix-box': formGet('artistFix').value}">
                    <nxt-slide-toggle [nxtFormControl]="formGet('artistFix')">Fix</nxt-slide-toggle>
                    @if (formGet('artistFix').value) {
                      <mat-button-toggle-group class="flex !flex-col !border-0 mt-2" [formControl]="formGet('artistFixReason')" [multiple]="true">
                        @for (fixArtistReason of fixArtistReasonOptions; track fixArtistReason.value) {
                          <mat-button-toggle class="!text-[80%] !border-0" [value]="fixArtistReason.value">{{ fixArtistReason.text }}</mat-button-toggle>
                        }
                      </mat-button-toggle-group>
                    }
                    <!--<nxt-radio class="pt-1" *ngIf="formGet('artistFix').value" [nxtFormControl]="formGet('artistFixReason')" [options]="fixArtistReasonOptions"
                               valueKey="value" textKey="text">
                    </nxt-radio>-->
                  </div>
                  <nxt-slide-toggle *ngIf="false" [disabled]="!('CalendarEventEdit_ChangeWorkType' | permission)"
                                    [value]="form.get('workType').value === 'piercing'">Piercing
                  </nxt-slide-toggle>
                </div>
              </div>
            </mat-card>

            @if (form.get('workType').value === 'tattoo') {
              <mat-card fxLayout="column" class="mat-card-left-margin" fxFlex.lt-sm="100%">
                <!--<div class="card-title">Stil</div>-->
                <div fxFlex fxLayout="column" fxLayoutAlign="center center">
                  <div *ngIf="skill" (click)="setSkill()" class="mouse-pointer">
                    <div *ngIf="skill.boolSkills">
                      <div *ngFor="let boolSkill of skill.boolSkills | keyvalue">
                        {{ boolSkill.key }}
                      </div>
                    </div>
                    <div *ngIf="skill.skills">
                      <div *ngFor="let skill of skill.skills | keyvalue">
                        {{ skill.key }}
                      </div>
                    </div>
                  </div>
                  <div *ngIf="artistSkill" class="flex flex-col items-center justify-center pt-2">
                    <nxt-rate-icons (click)="setSkill()" class="cursor-pointer" icon="star" [value]="artistSkill.stars"></nxt-rate-icons>
                    <div class="flex pt-2 text-[75%]">
                      <nxt-slide-toggle nxtPermission="SkillCheck_Disable" [placeholder]="'Check\ndeaktivieren'"
                                        [nxtFormControl]="form.get('disableSkillCheck')"></nxt-slide-toggle>
                      @if (form.get('disableSkillCheck').value) {
                        <div nxtPermission permissionNot="SkillCheck_Disable">Check ist deaktiviert</div>
                      }
                    </div>
                  </div>
                  <nxt-button *ngIf="skill && (skill.skills | keyvalue).length === 0 && (skill.boolSkills | keyvalue).length === 0" (click)="setSkill()">
                    Stil
                    wählen
                  </nxt-button>
                </div>
              </mat-card>
              <div fxFlex fxFlex.lt-sm="100%">
                <nxt-smooth-height fxFlex [trigger]="getFormControlGroups('bodyPutsTattoo').length">
                  <mat-card class="flex h-full flex-col justify-center pt-2 mat-card-left-margin" fxFlex.lt-sm="100%">
                    <!--<div class="card-title">Tattoo</div>-->
                    @if (getFormControlGroups('bodyPutsTattoo'); as bodyPutsTattoo) {
                      @for (bodyPut of bodyPutsTattoo; track bodyPut) {
                        <div>
                          <div fxFlex fxLayout="row" fxLayoutAlign="center center" [fxLayoutGap]="controlGap">
                            <div class="flex h-full pt-1">
                              @if (bodyPutsTattoo.length > 1) {
                                <nxt-button-icon (click)="removeBodyPut('bodyPutsTattoo', $index)">remove</nxt-button-icon>
                              }
                              @if ($last) {
                                <nxt-button-icon (click)="addBodyPut('bodyPutsTattoo')">add</nxt-button-icon>
                              }
                            </div>
                            <div class="flex flex-row" fxFlex="181px">
                              <nxt-input
                                  #nxtFormControlElemBodyPut
                                  placeholder="Körperstelle"
                                  (inputClick)="bodyPutClick($index)"
                                  [nxtFormControl]="bodyPut.get('bodyPut')"
                                  [readonly]="true"
                                  [cellRenderer]="bodyPutCellRenderer"
                              ></nxt-input>
                            </div>
                            <div fxFlex>
                              <nxt-autocomplete
                                  #nxtFormControlElemCustomer
                                  minWidth="300px"
                                  placeholder="Motiv"
                                  [maxOptionsToShow]="999"
                                  [nxtFormControl]="bodyPut.get('motive')"
                                  cachedTextId="tattoo-motives"
                                  [filterFields]="[]"
                              >
                              </nxt-autocomplete>
                            </div>
                            <div fxFlex="200px">
                              <nxt-input
                                  #nxtFormControlElemBodyPut
                                  placeholder="Größe"
                                  [nxtFormControl]="bodyPut.get('size')"
                              ></nxt-input>
                            </div>
                            <div>
                              <nxt-slide-toggle [nxtFormControl]="bodyPut.get('round')">Rund</nxt-slide-toggle>
                            </div>
                            <div>
                              <nxt-slide-toggle [nxtFormControl]="bodyPut.get('thinLines')">feine Linien</nxt-slide-toggle>
                            </div>
                          </div>
                        </div>
                      }
                    }
                    <div fxLayout="row" style="position: relative; top: -7px" *ngIf="false">
                      <nxt-button-icon [smallHeight]="true" *ngIf="getFormControlGroups('bodyPutsTattoo').length > 1" (click)="removeBodyPut('bodyPutsTattoo')">
                        remove
                      </nxt-button-icon>
                      <nxt-button-icon [smallHeight]="true" (click)="addBodyPut('bodyPutsTattoo')">add</nxt-button-icon>
                    </div>
                  </mat-card>
                </nxt-smooth-height>
              </div>
            } @else if (form.get('workType').value === 'piercing') {
              <div fxFlex fxFlex.lt-sm="100%">
                <nxt-smooth-height fxFlex [trigger]="getFormControlGroups('bodyPutsPiercing').length">
                  <mat-card fxFlex class="mat-card-left-margin">
                    <div class="card-title">5. Piercing</div>
                    <div *ngFor="let bodyPut of getFormControlGroups('bodyPutsPiercing'); let i = index">
                      <div fxLayout="row">
                        <nxt-input
                            #nxtFormControlElemBodyPut
                            placeholder="Körperstelle"
                            (inputClick)="bodyPutPiercingClick(i)"
                            [nxtFormControl]="bodyPut.get('bodyPut')"
                            [readonly]="true"
                            [cellRenderer]="bodyPutPiercingCellRenderer"
                        ></nxt-input>
                        <div class="flex flex-col justify-center w-[190px]">
                          <nxt-slide-toggle [nxtFormControl]="bodyPut.get('goldBall')">mit Goldkugel</nxt-slide-toggle>
                        </div>
                      </div>
                    </div>
                    <div fxLayout="row" style="position: relative; top: -7px">
                      <nxt-button-icon [smallHeight]="true" *ngIf="getFormControlGroups('bodyPutsPiercing').length > 1"
                                       (click)="removeBodyPut('bodyPutsPiercing')">remove
                      </nxt-button-icon>
                      <nxt-button-icon [smallHeight]="true" (click)="addBodyPut('bodyPutsPiercing')">add</nxt-button-icon>
                    </div>
                  </mat-card>
                </nxt-smooth-height>
              </div>
            } @else if (form.get('workType').value === 'beauty') {
              <div fxFlex fxFlex.lt-sm="100%">
                <nxt-smooth-height fxFlex [trigger]="getFormControlGroups('bodyPutsBeauty').length">
                  <mat-card fxFlex class="mat-card-left-margin">
                    <div class="card-title">5. Behandlung</div>
                    <div *ngFor="let bodyPut of getFormControlGroups('bodyPutsBeauty'); let i = index">
                      <div fxLayout="row">
                        <nxt-input
                            #nxtFormControlElemBodyPut
                            placeholder="Behandlung"
                            (inputClick)="bodyPutBeautyClick(i)"
                            [nxtFormControl]="bodyPut.get('bodyPut')"
                            [readonly]="true"
                            [cellRenderer]="bodyPutBeautyCellRenderer"
                        ></nxt-input>
                      </div>
                    </div>
                    <div fxLayout="row" style="position: relative; top: -7px">
                      <nxt-button-icon [smallHeight]="true" *ngIf="getFormControlGroups('bodyPutsBeauty').length > 1"
                                       (click)="removeBodyPut('bodyPutsBeauty')">remove
                      </nxt-button-icon>
                      <nxt-button-icon [smallHeight]="true" (click)="addBodyPut('bodyPutsBeauty')">add</nxt-button-icon>
                    </div>
                  </mat-card>
                </nxt-smooth-height>
              </div>
            } @else if (form.get('workType').value === 'tooth-gem') {
              <div fxFlex fxFlex.lt-sm="100%">
                <nxt-smooth-height fxFlex [trigger]="getFormControlGroups('bodyPutsToothGem').length">
                  <mat-card fxFlex class="mat-card-left-margin">
                    <div class="card-title">5. Stelle</div>
                    <div *ngFor="let bodyPut of getFormControlGroups('bodyPutsToothGem'); let i = index">
                      <div fxLayout="row">
                        <nxt-input
                            #nxtFormControlElemBodyPut
                            placeholder="Zahnschmuck"
                            (inputClick)="bodyPutToothGemClick(i)"
                            [nxtFormControl]="bodyPut.get('bodyPut')"
                            [readonly]="true"
                            [cellRenderer]="bodyPutToothGemCellRenderer"
                        ></nxt-input>
                      </div>
                    </div>
                    <div fxLayout="row" style="position: relative; top: -7px">
                      <nxt-button-icon [smallHeight]="true" *ngIf="getFormControlGroups('bodyPutsToothGem').length > 1"
                                       (click)="removeBodyPut('bodyPutsToothGem')">remove
                      </nxt-button-icon>
                      <nxt-button-icon [smallHeight]="true" (click)="addBodyPut('bodyPutsToothGem')">add</nxt-button-icon>
                    </div>
                  </mat-card>
                </nxt-smooth-height>
              </div>
            }
          </div>
        </nxt-smooth-height>


        <nxt-divider [height]="cardSpace"></nxt-divider>

        <!--<nxt-tattoo-info></nxt-tattoo-info>-->
        <!--<nxt-smooth-height [trigger]="paymentSmoothHeightTrigger" [fromTop]="getFormPayments().length === 1">-->
        <nxt-payments
          [formPayments]="getFormPayments()"
          [notAssignedPaypalTransactionsIn]="notAssignedPaypalTransactionsIn"
          [notAssignedPaypalTransactionsOut]="notAssignedPaypalTransactionsOut"
          [notAssignedKlarnaOrdersIn]="notAssignedKlarnaOrdersIn"
          [notAssignedKlarnaOrdersOut]="notAssignedKlarnaOrdersOut"
          [notAssignedBankTransactionsIn]="notAssignedBankTransactionsIn"
          [notAssignedBankTransactionsOut]="notAssignedBankTransactionsOut"
          [paymentTypes]="possiblePaymentTypes"
          [paymentMethods]="allPaymentMethods"
          [possiblePaymentMethods]="possiblePaymentMethods"
          [possiblePaymentMethodsDepositBack]="possiblePaymentMethodsDepositBack"
          titleText="Zahlungen"
          [controlGap]="controlGap"
          (valueChange)="paymentsChanged()"
          [priceEstimatedFrom]="form.get('priceEstimatedFrom').value"
          [toPayOnEventDate]="form.get('toPayOnEventDate').value"
          [artistPercentage]="form.get('artistPercentage').value"
          [eventDate]="form.get('date').value"
          [eventIsClosed]="form.get('closed').value"
          [eventCreateAt]="originalEvent().createdAt"
          [customer]="this.form.get('customer').value"
          [isNewEvent]="isNewEvent"
          [enableAddPayment]="!paymentPayoutExist"
          [beforeAddPayment]="beforeAddPayment"
          [discountPromotion]="form.get('discountPromotion').value"
          [eventId]="eventId"
          (printCustomerReceipt)="printCustomerReceipt($event)"
          [forceSaveHack]="forceSaveHack"
          (bookToPayOnEventDateClicked)="bookToPayOnEventDateClicked($event)"
          (refundToPayOnEventDatePayedClicked)="refundToPayOnEventDatePayedClicked($event)"
        ></nxt-payments>
        <!--</nxt-smooth-height>-->

        <!--<nxt-button [hidden]="readOnly" (click)="addGiftCard()">Gutschein hinzufügen</nxt-button>-->

        <nxt-divider [height]="cardSpace"></nxt-divider>

        <mat-card fxLayout="column">
          <div>
            <div class="flex w-full flex-row">
              <div class="flex flex-col" style="border:0px solid red">
                <div fxLayout="row" class="red" *ngIf="form.get('discountPromotion').value && form.get('discountPromotion').value?.id !== 'none'">
                  <div *ngIf="form.get('discountPromotion').value?.id === 'custom'">
                    {{ form.get('discountPromotion').value.name }}: {{ formGet('discountPromotion').value.discountPercentage + '%' }}
                  </div>
                  <div class="flex flex-row items-center gap-6" *ngIf="form.get('discountPromotion').value?.id !== 'custom'">
                    {{ form.get('discountPromotion').value.name }}
                    <div style="font-size: 80%;">Rabattierte
                      Gutscheine: {{ $any(form.get('discountPromotion').value).disableDiscountedGiftCards ? 'Nein' : 'Ja' }}
                    </div>
                    <div style="font-size: 80%;" *ngIf="form.get('promoOfferPromoterName').value">Promoter: {{ form.get('promoOfferPromoterName').value }}</div>
                    <div style="font-size: 80%;" *ngIf="form.get('promoOfferCreatedAt').value">PromoCode
                      erstellt: {{ form.get('promoOfferCreatedAt').value | nxtDate: 'dd.MM.yyyy HH:mm' }}
                    </div>
                  </div>
                </div>
                <!--<div>fix: {{ form.get('priceFix').value }}</div>-->

                <div *ngIf="priceAutoChangedTo > 0" class="green">
                  Preis wird automatisch auf {{ customerPayed.toMoneyString() + ' angehoben' }}
                </div>
                <div (click)="showPriceModal()" class="mouse-pointer">
                  <div [innerHTML]="form.getRawValue() | calendarEventEditPriceText | safeHtml"></div>
                </div>
              </div>
              <div class="flex flex-grow flex-row items-center justify-around" style="border: 0px solid blue">
                <div class="flex h-full flex-col justify-around">
                  <nxt-slide-toggle [nxtFormControl]="formGet('artistPercentageConfirmed')" placeholder="Artist % OK"></nxt-slide-toggle>
                  @if (formGet('workType').value === 'tooth-gem') {
                    <nxt-slide-toggle [nxtFormControl]="formGet('priceAuto')">Automatischer Preis</nxt-slide-toggle>
                  }
                  <nxt-slide-toggle [nxtFormControl]="formGet('priceFix')">Fixpreis</nxt-slide-toggle>
                  <nxt-slide-toggle [nxtFormControl]="form.get('adjustPrice')">Preis evtl. anpassen</nxt-slide-toggle>
                </div>
                <div class="flex h-full flex-col justify-around">
                  <!--@if (disableFreeToggle) {
                    <nxt-slide-toggle [disabled]="disableFreeToggle" [(value)]="isFree" (valueChange)="freeImprovementChanged()">kostenlos</nxt-slide-toggle>
                  }-->
                  <nxt-slide-toggle [nxtFormControl]="formGet('noDepotNecessary')">Kaution nicht nötig</nxt-slide-toggle>
                </div>
                <div class="flex h-full flex-col justify-around gap-1">
                  <nxt-slide-toggle nxtPermission="Admin" [nxtFormControl]="form.get('fastWalkIn')">fastWalkIn</nxt-slide-toggle>

                  <nxt-slide-toggle [nxtFormControl]="formGet('improve')">Nachstechen</nxt-slide-toggle>
                  @if (formGet('improve').value) {
                    <nxt-slide-toggle [nxtFormControl]="formGet('improveArtistShouldGetMoney')">Nachstechen<br/>(Artist bekommt Geld)</nxt-slide-toggle>
                  }
                  @if (formGet('improveOtherArtistCheckState').value === 'do-check' || formGet('improveOtherArtistCheckState').value === 'checked') {
                    <nxt-slide-toggle
                      nxtPermission="CalendarEventEdit_setImproveOtherArtistCheckState"
                      [value]="formGet('improveOtherArtistCheckState').value === 'checked'"
                      (valueChange)="improveOtherArtistCheckStateChanged($event)"
                    >Nachstechen bei<br/>anderem Artist OK
                    </nxt-slide-toggle>
                  }
                </div>
              </div>
              <!--<div fxLayout="column" fxLayoutAlign="start start" class="card-title">Preisabsprache</div>-->
            </div>
          </div>

          <div fxLayout="row" class="pb-3" fxLayoutAlign="start center">
            <nxt-slide-toggle [nxtFormControl]="formGet('shouldDepositBack')">offene Rückzahlung</nxt-slide-toggle>
            <nxt-input fxFlex="285px"
                       *ngIf="formGet('shouldDepositBack').value"
                       [isMoney]="true"
                       placeholder="wie viel soll zurück gezahlt werden?"
                       [nxtFormControl]="formGet('shouldDepositBackValue')">
            </nxt-input>
          </div>
          <div *ngIf="priceChanges && priceChanges.length > 0">
            <table class="price-change-table">
              <tr *ngFor="let priceChange of priceChanges">
                <td>{{ priceChange.t | nxtDate: 'dd.MM.yyyy HH:mm' }}</td>
                <td>{{ priceChange.u }}</td>
                <td>{{ priceChange.from | money }} ➞ {{ priceChange.to | money }}</td>
                <td>{{ priceChange.i }}</td>
              </tr>
            </table>
          </div>
        </mat-card>
        <nxt-divider [height]="cardSpace"></nxt-divider>
        @if (newTattooTemplates().length > 0) {
          <mat-card class="flex flex-row">
            <div class="h-full">Neue Vorlagen</div>
            <div nxtPhotoSwipe class="flex flex-row items-center gap-1">
              @for (tattooTemplate of newTattooTemplates(); track tattooTemplate.messageId) {
                <nxt-context-menu #templateMenu>
                  <button mat-menu-item (click)="deleteTattooTemplateClicked(tattooTemplate)">
                    <span>LÖSCHEN</span>
                  </button>
                </nxt-context-menu>
                <a [href]="tattooTemplate.src" [attr.data-pswp-width]="tattooTemplate.width" [attr.data-pswp-height]="tattooTemplate.height">
                  <img (contextmenu)="templateMenu.show($event)" style="max-width: 180px" [src]="tattooTemplate.src"/>
                </a>
              }
            </div>
          </mat-card>
          <nxt-divider></nxt-divider>
        }
        <mat-card class="flex flex-row" *ngIf="showDurationPriceInfo || form.get('noDepotNecessary').value || showDepositDueDate || isCanceled">
          <div class="card-title">9. Sonstiges</div>
          <div class="flex flex-grow flex-row gap-3">
            @if (showDurationPriceInfo) {
              <div class="flex flex-grow flex-col">
                <nxt-input placeholder="Warum passt der Preis zur Dauer nicht?" [nxtFormControl]="form.get('durationPriceInfo')"></nxt-input>
              </div>
            }
            @if (isCanceled) {
              <div class="flex flex-grow">
                <nxt-slide-toggle *ngIf="!form.get('canceledInTime').value" [nxtFormControl]="form.get('canceledApproved')">Absagenachweis erbracht
                </nxt-slide-toggle>
                <nxt-input placeholder="Warum abgesagt?" [nxtFormControl]="form.get('canceledReason')"></nxt-input>
              </div>
            }
            @if (form.get('noDepotNecessary').value) {
              <nxt-col class="flex flex-grow">
                <nxt-input placeholder="Warum keine Kaution nötig?" [nxtFormControl]="form.get('noDepotNecessaryReason')"></nxt-input>
              </nxt-col>
            }

            @if (showDepositDueDate) {
              <div class="flex flex-col w-[300px]">
                <nxt-date-picker placeholder="Wann kommt die Kaution?" width="300px" [nxtFormControl]="form.get('depotDueDate')"></nxt-date-picker>
                <div style="position: relative; top: -12px" class="flex flex-row justify-around">
                  <nxt-button [smallButton]="true" (click)="setDepotDueDate(0)">Heute</nxt-button>
                  <nxt-button [smallButton]="true" (click)="setDepotDueDate(1)">+1 Tag</nxt-button>
                  <nxt-button [smallButton]="true" (click)="setDepotDueDate(2)">+2 Tage</nxt-button>
                  <nxt-button [smallButton]="true" (click)="setDepotDueDate(3)">+3 Tage</nxt-button>
                </div>
              </div>
            }
            @if (showDepositDueDate) {
              <div class="flex flex-grow">
                <nxt-autocomplete
                    #nxtFormControlElemCustomer
                    [placeholder]="'Warum kommt die Kaution erst am ' +( form.get('depotDueDate').value ? (form.get('depotDueDate').value | nxtDate: 'dd.MM.yyyy') : '')"
                    [maxOptionsToShow]="999"
                    [nxtFormControl]="form.get('depotDueDateReason')"
                    cachedTextId="depot-due-date-reason"
                    [filterFields]="[]"
                >
                </nxt-autocomplete>
              </div>
            }
          </div>
        </mat-card>

        <nxt-divider *ngIf="showDurationPriceInfo || form.get('noDepotNecessary').value || showDepositDueDate || isCanceled" [height]="cardSpace"></nxt-divider>

        @if (!showOldPhotos()) {
          <mat-card class="flex flex-row">
            <div class="flex flex-row">
              <nxt-context-menu #eventFileMenu>
                @if (selectedEventFileText()) {
                  <button mat-menu-item (click)="sendSelectedEventFilesClicked()">
                    <div class="flex flex-row">
                      <mat-icon>share</mat-icon>
                      <span class="flex flex-row whitespace-nowrap"><div [innerHTML]="selectedEventFileText() | safeHtml"></div><div>&nbsp;an {{ formGet('customer').value.givenName }}
                        schicken</div></span>
                    </div>
                  </button>
                  <button mat-menu-item (click)="sendEventFileToUnknownFolderClicked()">
                    <div class="flex flex-row">
                      <mat-icon>error</mat-icon>
                      <span class="flex flex-row whitespace-nowrap"><div [innerHTML]="selectedEventFileText() | safeHtml"></div><div>&nbsp; ➞ nicht von diesem Termin</div></span>
                    </div>
                  </button>
                  @if (!isNewEvent) {
                    <button mat-menu-item (click)="deleteSelectedFilesClicked()">
                      <div class="flex flex-row">
                        <mat-icon>delete</mat-icon>
                        <span class="flex flex-row whitespace-nowrap"><div [innerHTML]="selectedEventFileText() | safeHtml"></div><div>&nbsp; ➞ LÖSCHEN!</div></span>
                      </div>
                    </button>
                  }
                }
                <button mat-menu-item (click)="selectTattooEventFilesClicked()">
                  <mat-icon>check_box</mat-icon>
                  <span>Tattoo Bilder und Videos markieren</span>
                </button>
              </nxt-context-menu>
              <mat-icon class="cursor-pointer" (click)="eventFileMenu.show($event)">menu</mat-icon>
              <div class="flex overflow-auto">
                <div
                  nxtPhotoSwipe
                  [nxtPhotoSwipeShowEditIcon]="true"
                  (nxtPhotoSwipeOnEditClicked)="eventFileEditClicked($event)"
                  class="flex flex-row gap-1">
                  @for (eventFile of eventFiles(); track eventFile.id) {
                    <nxt-context-menu #photoContextMenu2>
                      @if (!eventFile.subType.startsWith('template')) {
                        <button mat-menu-item (click)="updatePhotoType2(eventFile, 'templatePhoto')">
                          <span>➞ VORLAGE</span>
                        </button>
                      }
                      @if (!eventFile.subType.startsWith('stencil')) {
                        <button mat-menu-item (click)="updatePhotoType2(eventFile, 'stencilPhoto')">
                          <span>➞ STENCIL</span>
                        </button>
                      }
                      @if (!eventFile.subType.startsWith('preTattoo')) {
                        <button mat-menu-item (click)="updatePhotoType2(eventFile, 'preTattooPhoto')">
                          <span>➞ VORHER</span>
                        </button>
                      }
                      @if (!eventFile.subType.startsWith('tattoo')) {
                        <button mat-menu-item (click)="updatePhotoType2(eventFile, 'tattooPhoto')">
                          <span>➞ TATTOO</span>
                        </button>
                      }
                      <button nxtPermission="EventPhoto_Delete" mat-menu-item (click)="deletePhotoClicked2(eventFile)">
                        <span>LÖSCHEN</span>
                      </button>
                    </nxt-context-menu>
                    <div (contextmenu)="photoContextMenu2.show($event)" class="relative flex flex-col items-center justify-center">
                      <div class="whitespace-nowrap">
                        <div class="text-sm file-header">{{ eventFile.subType | nxtDriveFileSubType }}</div>
                        <div class="text-xs file-header">{{ (eventFile.fileCreatedAt || eventFile.createdAt) | nxtDate: 'dd.MM.yy HH:mm' }}</div>
                      </div>
                      <div class="flex items-center justify-center" style="min-height: 180px">
                        <div class="relative flex h-fit">
                          <div style="position: absolute; left: 8px; top:8px; padding: 4px; border-radius: 4px; background-color: rgba(0,0,0,0.66)">
                            <nxt-checkbox
                              [value]="!!selectedEventFiles()[eventFile.id]"
                              (valueChange)="selectEventFileClicked(eventFile, $event);"
                            >
                            </nxt-checkbox>
                          </div>
                          @if (eventFile.type === 'image') {
                            <a
                              class="flex h-full items-center justify-center"
                              [attr.data-pswp-width]="eventFile.width"
                              [id]="eventFile.id"
                              [href]="eventFile.id | nxtEventFile"
                              [attr.data-pswp-height]="eventFile.height"
                            >
                              <img style="max-height: 180px; max-width: 135px; height: fit-content"
                                   [src]="eventFile.id | nxtEventFile: true: eventFileThumbReloadIndicator() "/>
                            </a>
                          } @else if (eventFile.type === 'video') {
                            <img
                              (click)="videoClicked(eventFile)"
                              style="max-height: 180px; max-width: 135px; height: fit-content"
                              [src]="eventFile.id | nxtEventFile: true"
                            />
                          }
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              @if (eventConsents().length > 0) {
                <div class="ml-3 flex flex-grow flex-row justify-end">
                  @for (eventFile of eventConsents(); track eventFile.id) {
                    <div class="flex flex-col items-center justify-center">
                      <div
                        class="whitespace-nowrap"
                        [class.red]="!eventFile.consentData?.correctPageCount || !eventFile.consentData?.hasAllCustomerSigns || !eventFile.consentData?.hasAllDates"
                      >
                        <div
                          class="text-sm file-header"
                        >{{ eventFile.subType | nxtDriveFileSubType }}
                        </div>
                        <div class="text-xs file-header">{{ (eventFile.fileCreatedAt || eventFile.createdAt) | nxtDate: 'dd.MM.yy HH:mm' }}</div>
                      </div>
                      <div class="relative flex items-center justify-center" (contextmenu)="consentMenu.show($event)">
                        <nxt-context-menu nxtPermission="IsBackoffice" #consentMenu>
                          @if (!eventFile.consentData?.hasAllCustomerSigns) {
                            <button mat-menu-item (click)="setConsentData(eventFile, 'hasAllCustomerSigns', true)">
                              <mat-icon>check</mat-icon>
                              <span>Unterschrift ist vorhanden</span>
                            </button>
                          } @else {
                            <button mat-menu-item (click)="setConsentData(eventFile, 'hasAllCustomerSigns', false)">
                              <mat-icon>close</mat-icon>
                              <span>Unterschrift fehlt</span>
                            </button>
                          }
                          @if (!eventFile.consentData?.hasAllDates) {
                            <button mat-menu-item (click)="setConsentData(eventFile, 'hasAllDates', true)">
                              <mat-icon>check</mat-icon>
                              <span>Datum ist vorhanden</span>
                            </button>
                          } @else {
                            <button mat-menu-item (click)="setConsentData(eventFile, 'hasAllDates', false)">
                              <mat-icon>close</mat-icon>
                              <span>Datum fehlt</span>
                            </button>
                          }
                          @if (!eventFile.consentData?.correctPageCount) {
                            <button mat-menu-item (click)="setConsentData(eventFile, 'correctPageCount', true)">
                              <mat-icon>check</mat-icon>
                              <span>Seitenzahl ist korrekt</span>
                            </button>
                          } @else {
                            <button mat-menu-item (click)="setConsentData(eventFile, 'correctPageCount', false)">
                              <mat-icon>close</mat-icon>
                              <span>Seitenzahl ist falsch</span>
                            </button>
                          }
                        </nxt-context-menu>
                        <div (click)="consentClicked(eventFile)" class="absolute flex cursor-pointer flex-col items-center justify-end px-2 py-1 text-[70%]"
                             style="background-color: rgb(31 31 31 / 88%); border-radius: 0.5rem">
                          <div [class.green-light]="eventFile.consentData?.hasAllCustomerSigns" [class.red]="!eventFile.consentData?.hasAllCustomerSigns">
                            Unterschrift: {{ eventFile.consentData?.hasAllCustomerSigns ? 'OK' : 'Fehlt' }}
                          </div>
                          <div [class.green-light]="eventFile.consentData?.hasAllDates" [class.red]="!eventFile.consentData?.hasAllDates">
                            Datum: {{ eventFile.consentData?.hasAllDates ? 'OK' : 'Fehlt' }}
                          </div>
                          <div [class.green-light]="eventFile.consentData?.correctPageCount" [class.red]="!eventFile.consentData?.correctPageCount">
                            Seitenzahl: {{ eventFile.consentData?.correctPageCount ? 'OK' : 'Falsch' }}
                          </div>
                        </div>
                        <img
                          class="cursor-pointer"
                          (click)="consentClicked(eventFile)"
                          style="max-height: 180px; max-width: 135px; height: fit-content"
                          [src]="eventFile.id | nxtEventFile: true"
                        />
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
            <div class="p-1" fxLayoutAlign="center center" *ngIf="!isNewEvent">
              <!--<nxt-button (click)="linkArtistPhotos()">Fotos aus unbekannten Fotos verknüpfen</nxt-button>-->
              <nxt-button (click)="linkEventFileFromClipboard()">Fotos einfügen</nxt-button>
            </div>
          </mat-card>
        } @else {
          <mat-card fxLayout="column" style="overflow: auto;">
            <div class="flex flex-row overflow-auto" nxtDragScroll>
              <div *ngIf="photoFolderId && !photosAndVideos">
                <div class="py-4 text-120">Fotos werden geladen...</div>
              </div>
              <div *ngIf="photoFolderId && photosAndVideos && (photosAndVideos.length > 0 || pdfs.length > 0)" fxLayout="row" fxLayoutGap="15px"
                   style="padding: 0 0; border: 0 solid green">
                @if (selectedMediaText() || true) {
                  <div [nxtDynamicSize]="!!selectedMediaText()" nxtDynamicSizeMode="width">
                    <div class="flex h-full flex-col items-center justify-center gap-4 overflow-hidden">
                      <nxt-button [smallButton]="true" (click)="sendSelectedMediaClicked()">
                        <div [innerHTML]="selectedMediaText() | safeHtml"></div>
                        an {{ formGet('customer').value.givenName }}<br/>schicken
                      </nxt-button>
                      <nxt-button [smallButton]="true" (click)="sendPhotosToUnknownFolder()">
                        <div [innerHTML]="selectedMediaText() | safeHtml"></div>
                        sind nicht von<br/>diesem Termin
                      </nxt-button>
                      <nxt-button [smallButton]="true" (click)="resetSelectedMediaClicked()">Markierung<br/>aufheben</nxt-button>
                    </div>
                  </div>
                }
                <div class="flex flex-row gap-1" nxtPhotoSwipe>
                  @for (photo of images; track photo.id) {
                    <nxt-context-menu #photoContextMenu>
                      @if (!photo.filename.startsWith('template')) {
                        <button mat-menu-item (click)="updatePhotoType(photo, 'templatePhoto')">
                          <span>➞ VORLAGE</span>
                        </button>
                      }
                      @if (!photo.filename.startsWith('stencil')) {
                        <button mat-menu-item (click)="updatePhotoType(photo, 'stencilPhoto')">
                          <span>➞ STENCIL</span>
                        </button>
                      }
                      @if (!photo.filename.startsWith('preTattoo')) {
                        <button mat-menu-item (click)="updatePhotoType(photo, 'preTattooPhoto')">
                          <span>➞ VORHER</span>
                        </button>
                      }
                      @if (!photo.filename.startsWith('tattoo')) {
                        <button mat-menu-item (click)="updatePhotoType(photo, 'tattooPhoto')">
                          <span>➞ TATTOO</span>
                        </button>
                      }
                      <button nxtPermission="EventPhoto_Delete" mat-menu-item (click)="deletePhotoClicked(photo)">
                        <span>LÖSCHEN</span>
                      </button>
                    </nxt-context-menu>
                    <div fxLayout="column" (contextmenu)="photoContextMenu.show($event)" fxLayoutAlign="center center">
                      @if (photo.type) {
                        <div class="no-wrap text-[90%]">
                          {{ photo.type | nxtPhotoType | uppercase }}
                        </div>
                      }
                      <div class="pb-2 no-wrap text-[90%]">
                        {{ photo.createdTime | nxtDate: 'dd.MM.yy HH:mm' }}
                      </div>
                      <div class="image-wrapper p-relative" fxLayoutAlign="center center">
                        <nxt-button-icon
                          backgroundColor="#00000075"
                          (click)="eventPhotoClicked(photo)"
                          color="gray"
                          *ngIf="photo.mimeType.startsWith('video')"
                          style="position:absolute;">play_circle_outline
                        </nxt-button-icon>
                        <div style="position: absolute; left: 8px; top:8px; padding: 4px; border-radius: 4px; background-color: rgba(0,0,0,0.66)">
                          <nxt-checkbox
                            [value]="!!selectedMedia()[photo.id]"
                            (valueChange)="selectMedia(photo, $event); selectedPhotosChanged()"
                          >
                          </nxt-checkbox>
                        </div>
                        <a [href]="photo.src" [attr.data-pswp-width]="photo.width" [attr.data-pswp-height]="photo.height">
                          <img draggable="false" style="max-width: 150px; max-height: 180px" [src]="photo.thumbnailLink" alt=""/>
                        </a>
                      </div>
                    </div>
                  }
                </div>
                <div class="flex flex-row gap-1">
                  @for (video of videos; track video.id) {
                    <div fxLayout="column" fxLayoutAlign="center center">
                      <div>VIDEO</div>
                      <div class="pb-2 no-wrap text-[90%]">{{ video.createdTime | nxtDate: 'dd.MM.yy HH:mm' }}</div>
                      <div class="image-wrapper p-relative" fxLayoutAlign="center center">
                        <nxt-button-icon
                          backgroundColor="#00000075"
                          (click)="eventPhotoClicked(video)"
                          color="gray"
                          *ngIf="video.mimeType.startsWith('video')"
                          style="position:absolute;">play_circle_outline
                        </nxt-button-icon>
                        <mat-checkbox
                          [checked]="selectedMedia()[video.id]"
                          (change)="selectMedia(video, $event.checked); selectedPhotosChanged()"
                          style="position: absolute; left: 5px; top:5px"
                        >
                        </mat-checkbox>
                        <img style="cursor: pointer; max-width: 150px; max-height: 180px" (click)="eventPhotoClicked(video)"
                             [src]="video.thumbnailLink | safeUrl"/>
                      </div>
                    </div>
                  }
                </div>
                <div class="pl-3" fxLayout="row" fxLayoutGap="5px" *ngIf="pdfs.length > 0">
                  <div *ngFor="let pdf of pdfs" fxLayout="column" fxLayoutAlign="center center" class="w-full p-relative">
                    @if (consentOk()) {
                      <div style="position: absolute; top: 50%; font-weight: bold;" class="red">✅</div>
                    } @else {
                      <div style="position: absolute; top: 50%; font-weight: bold;" class="red">unvollständig</div>
                    }
                    <div fxLayout="column" class="pb-2">
                      <div>EINWILLIGUNG</div>
                    </div>
                    <img style="cursor: pointer; max-width: 150px; max-height: 180px" (click)="openConsentClicked()" [src]="pdf.thumbnailLink | safeUrl"/>
                  </div>
                </div>
              </div>
            </div>
            <!--<div fxLayoutAlign="center center" *ngIf="!isNewEvent">
              <nxt-button (click)="linkArtistPhotos()">Fotos aus unbekannten Fotos verknüpfen</nxt-button>
              <nxt-button (click)="linkArtistPhotosFromClipboard()">Fotos aus anderem Termin einfügen</nxt-button>
            </div>-->
          </mat-card>
        }
      </div>
    </div>
    <div *ngIf="originalEvent && showDebug"
         style="font-size:14px; z-index: 99999; color: #fff; position:absolute; bottom: 20px; left: 0; right:0; overflow:auto; height: 500px; background-color: #000;"
    >
      <div [innerHTML]="originalEvent | jsonFormatted">
      </div>
    </div>

    <!--<div *ngIf="event && showDebug" style="font-size:14px; color: #fff; position:absolute; bottom: 20px; left: 34%; right:34%; overflow:auto; height: 300px;"
         [innerHTML]="artistCalendarsString | safeHtml"></div>
    <div *ngIf="event && showDebug" style="font-size:14px; color: #fff; position:absolute; bottom: 20px; left: 67%; right:0; overflow:auto; height: 300px;"
         [innerHTML]="contactsString | safeHtml"></div>-->

    <div class="info-div" style="position: fixed; left: 0; bottom: 0; right: 0;">
      <!--<button (click)="reload()">reload</button>-->
      <!--<a *ngIf="href" [href]="href">{{href}}</a>-->
      <!--<br/>-->
      <!--<input *ngIf="href && showDebug" (change)="loadEventManual($event)" style="width:100%; color: #A2A2A2 !important; background-color: #2A2A2A !important; border: none; font-size: 16px; text-align: center"
             onClick="this.select();"/>-->
    </div>
  </div>
</div>
