import {ChangeDetectionStrategy, Component, OnInit, signal} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtEventQrCodePreData} from '../../../common-interfaces/event-qr-code-pre-data.interface';

@Component({
    selector: 'nxt-event-qr-ok',
    templateUrl: './event-qr-ok.component.html',
    styleUrls: ['./event-qr-ok.component.scss'],
    imports: [FlexModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class EventQrOkComponent implements OnInit {
  type = signal<'stencil' | 'tattoo' | 'preTattoo' | null>(null);

  eventQrCodePreData = signal<NxtEventQrCodePreData | null>(null);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.eventQrCodePreData.set(this.router.getCurrentNavigation().extras.state.eventQrCodePreData);
    this.type.set(this.router.getCurrentNavigation().extras.state.type);
    console.log(this.eventQrCodePreData());
    console.log(this.type());
  }

  ngOnInit() {
  }
}
