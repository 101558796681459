import {JsonTools} from './json.tools';
import {WindowTools} from '../window.tools';

declare const window: any;

export interface NxtServerError {
  errorId: NxtErrorId;
  message: string;
  isError: boolean;
  errorMessage?: string;
}

export enum NxtErrorId {
  Info = 'Info',
  PermissionDenied = 'PermissionDenied',
  DailyLogin = 'DailyLogin',
  UserAlreadyExists = 'UserAlreadyExists',
  getCalendarEvent_EventIsFromPrimaryCalendar = 'getCalendarEvent_EventIsFromPrimaryCalendar',
  getCalendarEvent_CalendarNotFound = 'getCalendarEvent_CalendarNotFound',
  OnlyMessage = 'OnlyMessage',
  getCalendarEvent_IsAvailableArtist = 'getCalendarEvent_IsAvailableArtist',
  getCalendarEvent_Deleted = 'getCalendarEvent_Deleted',
}


export class ErrorTools {

  static errorTexts = [
    {
      errorId: NxtErrorId.PermissionDenied,
      message: 'Dafür fehlen dir Berechtigungen',
    }, {
      errorId: NxtErrorId.DailyLogin,
      message: 'Täglicher Login nötig',
    }, {
      errorId: NxtErrorId.UserAlreadyExists,
      message: 'Der Benutzer existiert bereits',
    }, {
      errorId: NxtErrorId.UserAlreadyExists,
      message: 'Das ist der Primary Kalender, hier werden keine Kunden-Termine gespeichert',
    }, {
      errorId: NxtErrorId.getCalendarEvent_CalendarNotFound,
      message: 'Kalender nicht gefunden',
    }, {
      errorId: NxtErrorId.getCalendarEvent_IsAvailableArtist,
      message: 'Es handelt sich um ein Artist',
    }, {
      errorId: NxtErrorId.getCalendarEvent_Deleted,
      message: 'Termin wurde gelöscht',
    },
  ];


  public static throwServerError(errorId: NxtErrorId, message?: string): never;
  public static throwServerError(message: string): never;

  public static throwServerError(errorId: NxtErrorId, message?: string): never {
    if (typeof errorId === 'string') {
      message = errorId;
      errorId = NxtErrorId.OnlyMessage;
    }
    const serverError: NxtServerError = {
      errorId,
      message: message ?? '',
      isError: true,
    };
    const error = Error(JsonTools.stringify(serverError));
    error.message = serverError.message;
    (error as any).isError = true;
    (error as any).isNoError = false;
    (error as any).errorId = errorId;
    throw error;
  }

  /*public static throwServerError(message: string): never {
      const serverError: NxtServerError = {
          errorId: NxtErrorId.OnlyMessage,
          message: message ?? '',
          isError: true
      };
      throw Error(JsonTools.stringify(serverError));
  }*/

  public static getErrorForPromise(errorId: NxtErrorId, message?: string, isError = true) {
    const serverError: NxtServerError = {
      errorId,
      message: message ?? '',
      isError,
      errorMessage: ErrorTools.errorTexts.find(t => t.errorId === errorId)?.message,
    };
    return serverError;
  }


  public static throwServerInfo(message: string): never {
    const serverError: NxtServerError = {
      errorId: NxtErrorId.Info,
      message: message ?? '',
      isError: false,
    };
    const error = Error(JsonTools.stringify(serverError));
    error.message = serverError.message;
    (error as any).isError = false;
    (error as any).isNoError = true;
    (error as any).errorId = NxtErrorId.Info;
    throw error;
  }


  public static getMessage(serverError: NxtServerError): string {
    if (serverError.errorId === NxtErrorId.DailyLogin) {
      WindowTools.reload('DailyLogin');
    }
    let result = ErrorTools.errorTexts.find(t => t.errorId === serverError.errorId)?.message;
    if (serverError.message) {
      if (result) {
        return result + '\n' + serverError.message;
      } else {
        return serverError.message;
      }
    }
    if (!result && serverError.errorId) {
      result = 'Fehlertext fehlt bei ErrorId: ' + serverError.errorId;
    }
    if (serverError?.errorId === NxtErrorId.DailyLogin) {
      return '';
    }
    if (!result) {
      return serverError?.errorMessage || 'Keine Fehlermeldung vorhanden';
    }
    return result;
  }

  public static getTitle(serverError: NxtServerError) {
    return serverError.isError ? 'Fehler' : 'Info';
  }

  static reduceNodeStack(stack: string) {
    const result: string[] = [];
    const stackLines = stack.split('\n');
    for (const stackLine of stackLines) {
      if (stackLine.includes('.ts:')) {
        result.push(stackLine);
      }
    }
    return result.join('\n');
  }
}
