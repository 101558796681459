import {ColorTools} from './color.tools';

export class IconTools {
  static Awesome = {
    Shisha: '<svg style="width: 20px; height: 20px" fill="#bbb" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g><g><path d="M422.788,77.576c-38.499,0-69.818,31.32-69.818,69.818v93.091c0,12.833-10.44,23.273-23.273,23.273h-35.033 c2.583-7.284,4.003-15.115,4.003-23.273c0-17.869-6.755-34.183-17.835-46.545c11.081-12.362,17.835-28.677,17.835-46.545 c0-8.158-1.42-15.988-4.003-23.273h4.003c12.853,0,23.273-10.42,23.273-23.273c0-12.853-10.42-23.273-23.273-23.273h-12.172 l18.263-45.659c2.869-7.17,1.994-15.295-2.337-21.692C298.094,3.831,290.873,0,283.152,0H174.545 c-7.723,0-14.943,3.831-19.271,10.224c-4.33,6.395-5.204,14.521-2.337,21.692l18.264,45.659H159.03 c-12.853,0-23.273,10.42-23.273,23.273c0,12.853,10.42,23.273,23.273,23.273h4.003c-2.583,7.284-4.003,15.115-4.003,23.273 c0,17.869,6.754,34.183,17.835,46.545c-11.081,12.362-17.835,28.677-17.835,46.545c0,8.158,1.42,15.988,4.003,23.273H128 c-12.853,0-23.273,10.42-23.273,23.273c0,12.853,10.42,23.273,23.273,23.273h4.059c-17.081,21.268-27.331,48.24-27.331,77.576 s10.251,56.309,27.331,77.576H42.667c-12.853,0-23.273,10.42-23.273,23.273c0,12.853,10.42,23.273,23.273,23.273h186.182H415.03 c12.853,0,23.273-10.42,23.273-23.273c0-12.853-10.42-23.273-23.273-23.273h-89.392c17.081-21.267,27.331-48.24,27.331-77.576\t\tc0-29.336-10.251-56.308-27.331-77.576h4.059c38.498,0,69.818-31.32,69.818-69.818v-93.091c0-12.833,10.439-23.273,23.273-23.273 c12.833,0,23.273,10.44,23.273,23.273v240.485c0,12.853,10.42,23.273,23.273,23.273c12.853,0,23.273-10.42,23.273-23.273V147.394 C492.606,108.896,461.286,77.576,422.788,77.576z"/></g></g></svg>',
    Magic: '<svg style="fill:#bbb" xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 0 576 512"><path d="M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z"/></svg>',
  };

  private static MaterialIconStyle = 'cursor: pointer; font-size:18px; line-height: 28px;';

  static Material = {
    History: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">history</span>',
    Edit: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">edit</span>',
    Download: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">download</span>',
    EditCalendar: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">edit_calendar</span>',
    Image: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">image</span>',
    Face: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">face</span>',
    Check: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">check</span>',
    CheckNoPointer: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">check</span>',
    Close: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">close</span>',
    Login: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">login</span>',
    Logout: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">logout</span>',
    ArrowRight: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">arrow_right_alt</span>',
    ArrowUp: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">arrow_up_alt</span>',
    Delete: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">delete</span>',
    Chat: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">chat</span>',
    ContentCopy: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">content_copy</span>',
    Reset: '<span style="' + this.MaterialIconStyle + '" class="material-icons icon-image-preview">settings_backup_restore</span>',
    Update: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">deployed_code_update</span>',
    InputCircle: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">input_circle</span>',
    Warning: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">warning</span>',
    DraftOrders: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">draft_orders</span>',
    AddCircle: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">add_circle</span>',
    ShoppingCart: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">shopping_cart</span>',
    Adjust: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">adjust</span>',
    RightClick: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">right_click</span>',
    Print: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">print</span>',
    Eye: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">visibility</span>',
    Euro: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">euro</span>',
    Info: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">info</span>',
    Refresh: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">refresh</span>',
    Settings: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">settings</span>',
    ExclamationRed: '<span style="' + this.MaterialIconStyle + ' color: ' + ColorTools.Red + '" class=\"material-symbols-outlined icon-image-preview\">priority_high</span>',
    CheckGreen: '<span style="' + this.MaterialIconStyle + ' color: ' + ColorTools.Green + '" class="material-icons icon-image-preview">check</span>',
    Document: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">docs</span>',
    Later: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">event_upcoming</span>',
    Run: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">sprint</span>',
    QuestionMark: '<span style="' + this.MaterialIconStyle + '" class="material-symbols-outlined icon-image-preview">question_mark</span>',
  };
  static IconNameMap: { [iconName: string]: string[] } = {
    local_taxi: ['taxi'],
    account_balance: ['bank'],
    handyman: ['handwerk', 'bauhaus', 'obi'],
    all_inbox: ['kasse'],
    restaurant: ['essen'],
    shopping_cart: ['aldi', 'rossmann', 'netto', 'rewe', 'dm', 'markt', 'edeka'],
    local_drink: ['alolez', 'getränk', '3b trading'],
    local_gas_station: ['tankstelle', 'tanken', 'shell', 'aral', 'tank&rast', 'bft'],
  };

  static getIconName(text: string) {
    try {
      return Object.keys(this.IconNameMap).find(k => !!this.IconNameMap[k].find(word => text.toLowerCase().includes(word)));
    } catch (err) {
      console.error(err);
    }
  }
}
