import {Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {DialogService} from './dialog.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CareInstructionsService {

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
  ) {
  }


  public async showEditCareInstructionsTattooText(workType: 'tattoo' | 'piercing' | 'tooth-gem', lang: 'de' | 'en' | 'fr' | 'es' | 'nl') {
    const prompt = await this.socketService.getCareInstructionsText({lang, workType});
    const dialog = await this.dialogService.showTextareaOld({
      message: '<strong>PFLEGEHINWEISTEXT ' + workType + '</strong> - {{kunde_name}} - {{aftercare_link}}',
      placeholder: '',
      prompt
    });
    const result = await firstValueFrom(dialog.afterClosed());
    if (result) {
      this.socketService.setCareInstructionsText(result, workType, lang);
    }
  }
}
