import {NxtContact, NxtContactForWa} from '../../common-interfaces/nxt.contact.interface';
import {DateTools} from './date.tools';
import {keys} from './object.tools';
import {ArrayTools} from './array.tools';

export interface ContactPossibleCombineAttributes {
  givenName: string[];
  familyName: string[];
  birthday: string[];
  mobile: string[];
  email: string[];
  streetAddress: string[];
  postalCode: string[];
  city: string[];
  country: string[];
  lang: string[];
}

export class ContactTools {
  static haveContactsSameNameAndBirthday(c1: NxtContact, c2: NxtContact): boolean {
    if (c1.id === c2.id) {
      return false;
    }
    if (c1.givenName
      && c1.familyName
      && c2.givenName
      && c2.familyName
      && c1.givenName.toLowerCase() === c2.givenName.toLowerCase()
      && c1.familyName.toLowerCase() === c2.familyName.toLowerCase()
    ) {
      // same name -> check birthday
      if (c1.birthday && c2.birthday) {
        // beide haben den Geburtstag eingetragen
        const dateString1 = DateTools.format(DateTools.parse(c1.birthday), 'yyyy-MM-dd');
        const dateString2 = DateTools.format(DateTools.parse(c2.birthday), 'yyyy-MM-dd');
        const sameNameAndBirthday = dateString1 === dateString2;
        return sameNameAndBirthday;
      }
      return true;
    }
    return false;
  }

  /*static hasEvents(contact: NxtContact) {
    return (contact.canceledEvents && contact.canceledEvents.length > 0)
      || (contact.closedEvents && contact.closedEvents.length > 0)
      || (contact.events && contact.events.length > 0);
  }*/

  static hasEvents(contact: NxtContact, typesOr?: ('canceled' | 'future' | 'closed')[]) {
    const hasCanceled = contact.canceledEvents && contact.canceledEvents.length > 0;
    const hasFuture = contact.events && contact.events.length > 0;
    const hasClosed = contact.closedEvents && contact.closedEvents.length > 0;
    if (!typesOr) {
      return hasCanceled || hasFuture || hasClosed;
    }
    if (typesOr.includes('canceled') && hasCanceled) {
      return true;
    }
    if (typesOr.includes('future') && hasFuture) {
      return true;
    }
    if (typesOr.includes('closed') && hasClosed) {
      return true;
    }
    return false;
  }

  static hasClosedOfFutureEvents(contact: NxtContact) {
    return (contact.closedEvents && contact.closedEvents.length > 0)
      || (contact.events && contact.events.length > 0);
  }

  static getAddressLine(contact: NxtContact, multiLine = true): string {
    const addressItems: string[] = [];
    if (contact.streetAddress) {
      addressItems.push(contact.streetAddress);
    }
    if (contact.country) {
      addressItems.push(contact.country);
    }
    if (contact.postalCode) {
      addressItems.push(contact.postalCode);
    }
    if (contact.city) {
      addressItems.push(contact.city);
    }
    if (multiLine && contact.streetAddress) {
      return addressItems[0] + '\n' + addressItems.splice(0, 1).join(' ');
    } else {
      return addressItems.join(' ');
    }
  }

  static getAllEventIds(contactBeforeUpsert: NxtContact | null) {
    if (contactBeforeUpsert) {
      return [
        ...(contactBeforeUpsert.canceledEvents || []).map(e => e.eventId),
        ...(contactBeforeUpsert.closedEvents || []).map(e => e.eventId),
        ...(contactBeforeUpsert.events || []).map(e => e.eventId),
      ];
    }
    return [];
  }


  static isFirstAppointment(customerObj: NxtContact, refDate: number) {
    const dates = [...(customerObj.closedEvents || []).map(e => e.start), ...(customerObj.closedEvents || []).map(e => e.end)];
    const minDate = Math.min(...dates);
    if (refDate <= minDate) {
      return true;
    }
    return false;
  }

  static getPossibleCombineAttributes(contacts: NxtContact[]): ContactPossibleCombineAttributes {
    try {
      const result: ContactPossibleCombineAttributes = {
        givenName: [],
        familyName: [],
        birthday: [],
        mobile: [],
        email: [],
        streetAddress: [],
        postalCode: [],
        city: [],
        country: [],
        lang: [],
      };
      for (const attr of keys(result)) {
        result[attr] = ArrayTools.unique(contacts.map(c => c[attr]).filter(v => !!v));
      }
      return result;
    } catch (err) {
      throw Error('getPossibleCombineAttributes failed ' + err.message);
    }
  }

  static contactToContactForWa(contact: NxtContact): NxtContactForWa {
    const today = DateTools.formatNowDate();
    return {
      mobile: contact.mobileFormatted!,
      hasAppointmentToday: contact.events.some(e => e.start.dateFormatDate() === today),
      waIndex: ContactTools.getWaIndex(contact),
    }
  }

  public static getWaIndex(contact: NxtContact) {
    return (contact.givenName + contact.familyName + contact.suffix).replaceAll(',', '').replaceAll(' ', '')
  }
}
