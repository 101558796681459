import {Observable} from 'rxjs';

function debounceNotFirst(debounceMs: number): (source: Observable<any>) => Observable<any> {
  return (source: Observable<any>) => {
    return new Observable<any>(observer => {
      let isFirst = true;
      let timeout: any;
      return source.subscribe({
        next(x) {
          if (timeout) {
            clearTimeout(timeout);
          }
          if (isFirst) {
            observer.next(x);
            isFirst = false;
            timeout = setTimeout(() => {
              isFirst = true;
            }, debounceMs);
          } else {
            timeout = setTimeout(() => {
              observer.next(x);
              isFirst = true;
            }, debounceMs);
          }
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        }
      });
    });
  };
}

export {debounceNotFirst};
