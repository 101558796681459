import {ComponentRef, createComponent, Directive, ElementRef, EnvironmentInjector, inject, input, OnDestroy, OnInit, output, ViewContainerRef,} from '@angular/core';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import {debounceTime, Subject, Subscription} from 'rxjs';
import Slide from 'photoswipe/dist/types/slide/slide';
import {ObjectTools} from '../../common-browser/helpers/object.tools';
import {PhotoSwipeInfoComponent} from './photo-swipe-info.component';
import {NxtCalendarEvent, NxtCalendarEventFileWithEventId} from '../../common-interfaces/nxt.calendar-event.interface';
import {ScreenService} from '../../services/screen.service';

export type NxtPhotoSwipeItem = {
  id: string;
  [key: string]: any;
};

@Directive({
  selector: '[nxtPhotoSwipe]',
  standalone: true,
})
export class PhotoSwipeDirective implements OnInit, OnDestroy {


  private screenService = inject(ScreenService);
  private showInfoTemplate = !this.screenService.state.value.isMobile;

  constructor(
    private element: ElementRef,
    private viewContainer: ViewContainerRef,
  ) {
    this.subscription = this.changed$.pipe(debounceTime(200)).subscribe(() => {
      this.initPhotoswipe();
    });
  }

  private changed$ = new Subject<void>();
  private subscription: Subscription;
  private lightbox: PhotoSwipeLightbox;
  private observer: MutationObserver;

  /*** Inputs ***/
  showEditIcon = input(false, {alias: 'nxtPhotoSwipeShowEditIcon'});
  showShareIcon = input(false, {alias: 'nxtPhotoSwipeShowShareIcon'});
  eventFiles = input<NxtCalendarEventFileWithEventId[]>([], {alias: 'nxtPhotoSwipeEventFiles'});
  events = input<NxtCalendarEvent[]>([], {alias: 'nxtPhotoSwipeEvents'});


  /*** Outputs ***/
  onEditClicked = output<{ slide: Slide, id: string }>({alias: 'nxtPhotoSwipeOnEditClicked'});
  onShareClicked = output<{ slide: Slide, id: string }>({alias: 'nxtPhotoSwipeOnShareClicked'});
  onImageOpen = output<{ slide: Slide, id: string }>({alias: 'nxtPhotoSwipeOnOpen'});
  onImageClose = output<{ slide: Slide, id: string }>({alias: 'nxtPhotoSwipeOnClose'});

  private lastSlide: Slide;

  private infoTemplateComponentRef: ComponentRef<PhotoSwipeInfoComponent>;

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.observer.disconnect();
    this.removeInfoTemplate();
  }

  async ngOnInit() {
    this.observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          if (mutation.addedNodes?.length > 0) {
            if ((mutation.addedNodes[0] as any).tagName === 'MAT-TOOLTIP-COMPONENT') {
              return;
            }
          }
        }
      }
      this.changed$.next();
    });
    const config = {
      childList: true, // Beobachte direkte Änderungen bei den Kindelementen
      // subtree: true, // Beobachte Änderungen an allen Nachkommen
      characterData: true, // Beobachte Textänderungen innerhalb von Knoten
    };
    this.observer.observe(this.element.nativeElement, config);
  }

  initPhotoswipe() {
    console.log('initPhotoswipe');
    if (this.lightbox) {
      this.lightbox.destroy();
    }
    this.lightbox = new PhotoSwipeLightbox({
      gallery: this.element.nativeElement,
      children: 'a',
      pswpModule: PhotoSwipe,
      wheelToZoom: true,
    });

    this.lightbox.on('closingAnimationStart', (data) => {
      this.removeInfoTemplate();
    });

    this.lightbox.on('close', (data) => {
      this.removeInfoTemplate();
      this.onImageClose.emit({slide: this.lightbox.pswp.currSlide, id: this.lightbox.pswp.currSlide.data.element.id});
      this.lastSlide = null;
    });

    this.lightbox.on('change', (data) => {
      if (this.lastSlide) {
        this.removeInfoTemplate();
        this.onImageClose.emit({slide: this.lastSlide, id: this.lastSlide.data.element.id});
      }
      this.lastSlide = this.lightbox.pswp.currSlide;
      if (this.lastSlide) {
        this.onImageOpen.emit({slide: this.lastSlide, id: this.lastSlide.data.element.id});
        this.updateInfoTemplate(this.lastSlide);
      }
    });

    this.initButtons();

    this.lightbox.init();
  }

  removeInfoTemplate() {
    document.querySelectorAll('.photo-swipe-custom-info-template').forEach(e => e.remove());
    if (this.infoTemplateComponentRef) {
      this.infoTemplateComponentRef.destroy();
      this.infoTemplateComponentRef = null;
    }
  }

  updateInfoTemplate(slide: Slide) {
    this.removeInfoTemplate();
    if (this.lightbox && this.lightbox.pswp) {
      const pswpElement = this.lightbox.pswp.element;
      // Finden Sie das aktuell angezeigte Slide-Element
      const currentItem = pswpElement.querySelector('.pswp__item[aria-hidden="false"]');

      if (currentItem) {
        // Erstellen Sie einen Container für das Info-Template
        document.querySelectorAll('.photo-swipe-custom-info-template').forEach(e => e.remove());
        const infoContainer = document.createElement('div');
        infoContainer.classList.add('photo-swipe-custom-info-template');

        // Fügen Sie den Container in das aktuelle Slide ein, nach `pswp__zoom-wrap`
        currentItem.appendChild(infoContainer);

        // Erstellen Sie die Komponente und fügen Sie sie in den Container ein
        this.infoTemplateComponentRef = createComponent(PhotoSwipeInfoComponent, {
          environmentInjector: this.viewContainer.injector.get(EnvironmentInjector),
        });

        // Setzen Sie das Slide-Input
        this.infoTemplateComponentRef.instance.slide.set(slide);
        const eventFile = this.eventFiles().find(i => i.id === slide.data.element.id);
        if (eventFile) {
          this.infoTemplateComponentRef.instance.eventFile.set(eventFile);
          const event = this.events().find(i => i.id === eventFile.eventId);
          if (event) {
            this.infoTemplateComponentRef.instance.event.set(event);
          }
        }

        this.infoTemplateComponentRef.instance.visible.set(this.showInfoTemplate);


        // Fügen Sie das Host-Element der Komponente in den Info-Container ein
        this.infoTemplateComponentRef.location.nativeElement.id = 'info-template';

        infoContainer.appendChild(this.infoTemplateComponentRef.location.nativeElement);
        this.infoTemplateComponentRef.changeDetectorRef.detectChanges();


        // Manuelle Auslösung der Change Detection
        this.infoTemplateComponentRef.changeDetectorRef.detectChanges();
      }
    }
  }

  toggleInfoTemplate() {
    this.showInfoTemplate = !this.showInfoTemplate;
    if (this.infoTemplateComponentRef) {
      this.infoTemplateComponentRef.instance.visible.set(this.showInfoTemplate);
    }
  }

  private initButtons() {


    this.lightbox.on('uiRegister', (data) => {
      if (this.showEditIcon()) {
        this.lightbox.pswp.ui.registerElement({
          name: 'custom-icon',
          order: 8,  // Position neben der Lupe
          isButton: true,
          html: '<span class="material-symbols-outlined" style="color:#fff">edit</span>',
          onClick: async (event, pswp) => {
            await ObjectTools.waitFor(() => this.lightbox?.pswp?.currSlide, 'currSlide', 2000);
            this.onEditClicked.emit({slide: this.lightbox.pswp.currSlide, id: this.lightbox.pswp.currSlide.data.element.id});
            this.lightbox.pswp.close();
          },
        });
      }
      if (this.showShareIcon()) {
        this.lightbox.pswp.ui.registerElement({
          name: 'custom-icon',
          order: 8,  // Position neben der Lupe
          isButton: true,
          html: '<span class="material-symbols-outlined" style="color:#fff">share</span>',
          onClick: async (event, pswp) => {
            await ObjectTools.waitFor(() => this.lightbox?.pswp?.currSlide, 'currSlide', 2000);
            this.onShareClicked.emit({slide: this.lightbox.pswp.currSlide, id: this.lightbox.pswp.currSlide.data.element.id});
            this.lightbox.pswp.close();
          },
        });
      }



      this.lightbox.pswp.ui.registerElement({
        name: 'custom-icon',
        order: 9,  // Position neben der Lupe
        isButton: true,
        html: '<span class="material-symbols-outlined" style="color:#fff">info</span>',
        onClick: async (event, pswp) => {
          this.toggleInfoTemplate();
        },
      });
    });

  }
}
