export function log(_this: any, message: string, objToJson?: any) {
    let caller = '?';
    if (_this && _this.constructor) {
            caller = _this.constructor.name.replace(/(\S)([A-Z]{1})/g, '$1-$2').toUpperCase();
    }
    // let result = (new Date()).toISOString() + ' [' + caller + '] ' + title;
    let result = '[' + caller + '] ' + message;
    if (objToJson) {
        if (objToJson.message) {
            result += ' - ' + objToJson.message;
        } else {
            result += '\n' + JSON.stringify(objToJson, null, 4);
        }
    }
    return result;
}
