import {Subscription} from 'rxjs';
import {UnsubscribeTools} from '../common-browser/helpers/unsubscribe.tools';
import {NxtSocketSubscription} from '../services/socket/socket-subscription';

export class NxtSubscriptionClass {

  private subscriptions: Subscription[] = [];
  private socketSubscriptions: NxtSocketSubscription[] = [];

  public set pushSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  public set pushSocketSubscription(socketSubscription: NxtSocketSubscription) {
    this.socketSubscriptions.push(socketSubscription);
  }

  protected onDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    for (const socketSubscription of this.socketSubscriptions) {
      socketSubscription.unsubscribe();
    }
  }

  protected unsubscribe(subs: Subscription[]) {
    for (const sub of subs) {
      UnsubscribeTools.unsubscribe(sub);
    }
  }
}
