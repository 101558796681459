import {Component, OnInit} from '@angular/core';
import {JsonTools} from '../../../common-browser/helpers/json.tools';
import {ActivatedRoute} from '@angular/router';
import {VoucherPdfData} from '../../../common-interfaces/nxt.voucher-pdf-data.interface';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {ConfigService} from '../../../services/config.service';
import {NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';

@Component({
  selector: 'nxt-voucher-pdf',
  templateUrl: './voucher-pdf.component.html',
  styleUrls: ['./voucher-pdf.component.scss'],
  imports: [FlexModule, NgIf, NxtDatePipe]
})
export class VoucherPdfComponent implements OnInit {

  valueString = '';
  data: VoucherPdfData;

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigService,
  ) {

    if (this.route.snapshot.queryParams.data) {
      this.data = JsonTools.parse(this.route.snapshot.queryParams.data);
    } else {
      this.data = {
        code: 'X-XXXX-XXXX-XXXX',
        value: 1,
        createdAt: Date.now(),
        validFrom: DateTools.parse('2021-12-24', 'yyyy-MM-dd'),
        validTill: DateTools.parse('2021-12-24', 'yyyy-MM-dd'),
        disableDiscountPromo: false,
        subType: 'day-session'
      };
    }
    this.valueString = parseInt(this.data.value + '', 10) + ' EURO';
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      console.log(data);
    });
  }

}
