import {Injectable} from '@angular/core';
import {LoginService} from './login.service';
import {ConfigService} from './config.service';
import {StringTools} from '../common-browser/helpers/string.tools';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {

  constructor(
    private loginService: LoginService,
    private configService: ConfigService,
  ) {
  }

  getPrivateToCashName() {
    switch (this.configService.config.value.studioRegion) {
      case 'DU':
      case 'DO':
      case 'AC':
      case 'MA':
        return 'Julian Krafft';
      case 'FFM':
        return 'Sven Hennemann';
      case 'AB':
        return 'Oliver Peters';
      case 'NU':
        return 'Oliver Peters';
    }
  }

  getIncomingTransferArticles(currentStudioCash: string, withBank: boolean) {
    const result: any[] = [];
    const studios = this.configService.config.value.studios.filter(s => s.name !== currentStudioCash && !['Brand', 'Alsdorf'].includes(s.realStudio) && !['Brand', 'Alsdorf'].includes(s.name));

    if (withBank) {
      result.push({
        id: 'bank',
        shortDescription: 'Bank',
        description: 'Bank',
        price: -1,
        isArtistDayPrice: false,
        _: false,
      });
    }

    result.push({
      id: 'private_to_cash',
      shortDescription: 'Einlage Tresor',
      description: 'Einlage Tresor',
      price: -1,
      isArtistDayPrice: false,
      _: true,
    });

    result.push({
      id: 'private_to_cash',
      shortDescription: 'Einlage von \n' + this.getPrivateToCashName(),
      description: 'Einlage von \n' + this.getPrivateToCashName(),
      price: -1,
      isArtistDayPrice: false,
      _: false,
    });

    if (this.configService.config.value.studioRegion === 'AC') {
      result.push({
        id: 'credit_card_to_cash_jkr',
        shortDescription: 'Kredit-Karte Krafft',
        description: 'Kredit-Karte Krafft',
        price: -1,
        isArtistDayPrice: false,
        _: false,
      });
    }

    if (this.configService.config.value.studioRegion === 'MA') {
      result.push({
        id: 'safe_house',
        shortDescription: 'Tresor Kasse NXT LVL',
        description: 'Tresor Kasse NXT LVL',
        price: -1,
        isArtistDayPrice: false,
        _: false,
      });
    }

    for (const studio of studios) {
      result.push(this.getStudioArticle(studio.name, false));
      result.push(this.getStudioArticle(studio.name, true));
    }
    return result;
  }

  getOutgoingTransferArticles(currentStudioCash: string, withBank: boolean) {
    const result: any[] = [];
    const studios = this.configService.config.value.studios.filter(s => s.name !== currentStudioCash && !['Brand', 'Alsdorf'].includes(s.realStudio) && !['Brand', 'Alsdorf'].includes(s.name));

    if (withBank) {
      result.push({
        id: 'bank',
        shortDescription: 'Bank',
        description: 'Bank',
        price: -1,
        isArtistDayPrice: false,
        _: false,
      });
    }

    result.push({
      id: 'cash_to_private',
      shortDescription: 'Einlage Rückzahlung an\n' + this.getPrivateToCashName(),
      description: 'Einlage Rückzahlung an\n' + this.getPrivateToCashName(),
      price: -1,
      isArtistDayPrice: false,
      _: false,
    });

    result.push({
      id: 'pre_transfer_bank',
      shortDescription: 'Frühzeitig Bank',
      description: 'Frühzeitig Bank',
      price: -1,
      isArtistDayPrice: false,
      _: false,
    });

    result.push({
      id: 'pre_transfer_safe',
      shortDescription: 'Frühzeitig Tresor',
      description: 'Frühzeitig Tresor',
      price: -1,
      isArtistDayPrice: false,
      _: true,
    });

    if (this.configService.config.value.studioRegion === 'MA') {
      result.push({
        id: 'pre_transfer_safe',
        shortDescription: 'Tresor Kasse NXT LVL',
        description: 'Tresor Kasse NXT LVL',
        price: -1,
        isArtistDayPrice: false,
        _: false,
      });
    }

    for (const studio of studios) {
      result.push(this.getStudioArticle(studio.name, false));
      result.push(this.getStudioArticle(studio.name, true));
    }
    return result;
  }

  getStudioArticle(studio: string, is_: boolean) {
    return {
      id: 'transfer_' + StringTools.toSnakeCase(studio),
      shortDescription: 'Kasse ' + studio + (is_ ? ' _' : ''),
      description: 'Kasse ' + studio + (is_ ? ' _' : ''),
      price: -1,
      isArtistDayPrice: false,
      _: is_,
    };
  }
}
