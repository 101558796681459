import {Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {LoginService} from './login.service';

@Injectable({
  providedIn: 'root'
})
export class WorkSessionService {
  constructor(
    private socketService: SocketService,
    private loginService: LoginService
  ) {
  }

  public async startWorkSession(dateString: string) {
    await this.socketService.workSessionStart(dateString);
  }

  public async isWorkSessionOpen(dateString: string): Promise<boolean> {
    return false;
  }

  async startCashRegister(dateString: string, startMoney: number) {
    await this.socketService.workSessionStartCashRegister(dateString, startMoney);
  }
}
