import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {NxtNgConfigRuntime} from '../../../common-interfaces/nxt.ng-config.interface';
import {NxtPaypalCheckoutLinkForCustomer} from '../../../common-interfaces/paypal.interface';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {SlideToggleComponent} from '../../../components/form-controls/slide-toggle/slide-toggle.component';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgIf, NgStyle} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

declare const paypal;

@Component({
    selector: 'nxt-paypal-checkout',
    templateUrl: './paypal-checkout.component.html',
    styleUrls: ['./paypal-checkout.component.scss'],
    imports: [FlexModule, NgClass, ExtendedModule, NgIf, NxtButtonComponent, SlideToggleComponent, NgStyle, MoneyPipe]
})

export class PaypalCheckoutComponent implements OnInit, AfterViewInit {
  useSandbox = true;
  value: number;
  status: 'selectPaymentMethod' | 'payed' = 'selectPaymentMethod';
  private captureId = '';
  checkoutData: NxtPaypalCheckoutLinkForCustomer;
  private linkId = '';
  public studio: 'ac' | 'ffm' | 'ab' | 'br';
  public agbOk = false;

  // public message = '';
  public footerText = '';

  private ngConfig: NxtNgConfigRuntime = (window as any).ngConfig;
  public logoSrc = '';


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
  ) {
  }

  async ngOnInit() {
    this.studio = this.route.snapshot.params.studio;
    this.logoSrc = '/assets/logo/studio/logo_' + this.studio + '_white.svg';
    this.linkId = this.route.snapshot.params.id;
    this.checkoutData = await this.getCheckoutData();
    this.loadScript(this.checkoutData.paypalClientId);
    if (!this.checkoutData) {
      alert('nicht gefunden');
      // window.location.href = 'https://nxt-lvl.ink';
    }
    this.footerText = this.checkoutData.studioAddress;
  }

  public loadScript(paypalClientId: string) {
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://www.paypal.com/sdk/js?client-id=' + paypalClientId + '&currency=EUR';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  async waitForPaypalScript() {
    return new Promise<void>((resolve, reject) => {
      if ((window as any).paypal) {
        resolve();
      } else {
        setTimeout(() => {
          resolve(this.waitForPaypalScript());
        }, 100);
      }
    });
  }

  public refund() {
    this.router.navigate(['/paypal-refund/' + this.captureId]);
  }

  private async getCheckoutData() {
    if (window.location.origin.includes('localhost') && false) {
      const result = await firstValueFrom(this.httpClient.get('http://localhost:444/get-paypal-checkout-data?id=' + this.linkId));
      return result as NxtPaypalCheckoutLinkForCustomer;
    } else {
      const result = await firstValueFrom(this.httpClient.get('https://' + this.studio + '.nxt-lvl.ink/get-paypal-checkout-data?id=' + this.linkId));
      return result as NxtPaypalCheckoutLinkForCustomer;
    }
  }

  private async sendCheckoutDataToServer(body) {
    if (window.location.origin.includes('localhost')) {
      const result = await firstValueFrom(this.httpClient.post('http://localhost:444/paypal-checkout-done', body));
    } else {
      const result = await firstValueFrom(this.httpClient.post('https://' + this.studio + '.nxt-lvl.ink/paypal-checkout-done', body));
    }
  }

  public async ngAfterViewInit() {
    await this.waitForPaypalScript();

    await paypal.Buttons({
      // Sets up the transaction when a payment button is clicked
      createOrder: (data, actions) => {
        return actions.order.create({

          purchase_units: [{
            reference_id: this.linkId,
            custom_id: this.linkId,
            description: 'Tattoo-Kaution',
            amount: {
              value: this.checkoutData.value // Can also reference a variable or function
            }
          }]
        });
      },
      // Finalize the transaction after payer approval
      onApprove: (data, actions) => {
        return actions.order.capture().then((orderData: any) => {
          // Successful capture! For dev/demo purposes:
          // console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
          this.captureId = orderData.purchase_units[0].payments.captures[0].id;
          this.status = 'payed';
          this.sendCheckoutDataToServer({orderData, linkId: this.linkId});
          // const transaction = orderData.purchase_units[0].payments.captures[0];
          // alert(`Transaction ${transaction.status}: ${transaction.id}\n\nSee console for all available details`);
          // When ready to go live, remove the alert and show a success message within this page. For example:
          // const element = document.getElementById('paypal-button-container');
          // element.innerHTML = '<h3>Thank you for your payment!</h3>';
          // Or go to another URL:  actions.redirect('thank_you.html');
        });
      }
    }).render('#paypal-button-container');
  }
}
