<div class="toggle-wrapper"
     [ngStyle]="{left: (currentWidth - 14) + 'px'}"
     [nxtAnimate]="toggleHighlight && state.value === 'collapsed' ? this.toggleExpandedAnimation : ''"
     [nxtInterval]="10000">
  <div
    [class]="'toggle toggle-' + (state | async) + ' ' +  (toggleHighlight ? 'toggle-highlight' : '')"
    (click)="toggle()"
  >
    <div class="toggle-background" #toggleBackground>
    </div>
    <mat-icon class="toggle-icon">{{(state | async) === 'expanded' ? 'arrow_left' : 'arrow_right'}}</mat-icon>
  </div>
</div>

<nxt-smooth-width [duration]="0.1" [trigger]="state | async" class="h-100" style="border: 0px solid white">
  <div class="h-100"  [ngStyle]="{width: currentWidth + 'px'}"  style="border: 0px solid green">
    <div class="h-100" *ngIf="(state | async) === 'expanded'"   style="border: 0px solid orange">
      <ng-content></ng-content>
    </div>
  </div>
</nxt-smooth-width>
