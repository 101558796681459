import {Component, Input, OnInit} from '@angular/core';
import {NxtNgConfig} from '../../../../common-interfaces/nxt.ng-config.interface';
import {DatePickerComponent} from '../../../form-controls/date-picker/date-picker.component';
import {CountrySelectComponent} from '../../../../controls/select-country/country-select.component';
import {InputComponent} from '../../../form-controls/input/input.component';
import {NgIf} from '@angular/common';

@Component({
    selector: 'nxt-settings-ng-config-invoice-data',
    templateUrl: './settings-ng-config-invoice-data.component.html',
    styleUrls: ['./settings-ng-config-invoice-data.component.scss'],
    imports: [NgIf, InputComponent, CountrySelectComponent, DatePickerComponent],
  standalone: true,
})

export class SettingsNgConfigInvoiceDataComponent implements OnInit {

  @Input() ngConfig: NxtNgConfig;

  constructor() {
  }

  ngOnInit() {

  }
}
