<div class="nxt-page">
  <div *ngIf="firstParent" fxLayout="column" fxLayoutAlign="center center">
    <div *ngIf="firstParent.type === 'gift-card'">
      <table class="nxt-table">
        <tr>
          <td colspan="2">
            <div class="flex flex-col items-center">
              <div class="text-120">Gutschein {{ firstParent.originalValue | money }}</div>
              <div *ngIf="openValue > 0" class="flex flex-row green">noch&nbsp;<div class="green">{{ openValue | money }}</div>&nbsp;offen</div>
              <div *ngIf="openValue == 0">vollständig eingelöst</div>
              <div>{{ firstParent.name }}</div>
            </div>
          </td>
        </tr>
        <tr *ngIf="openValue > 0">
          <td>Nicht zugewiesen</td>
          <td class="green">{{ openValue | money }}</td>
        </tr>
        <tr *ngIf="refundValue > 0">
          <td>Rückerstattet</td>
          <td class="red">{{ refundValue | money }}</td>
        </tr>
        <tr>
          <td>Bezahlt</td>
          <td>{{ firstParent.originalPaymentValue | money }} {{ firstParent.originalValue !== firstParent.originalPaymentValue ? '(' + getDiscountPercentage(firstParent?.originalValue, firstParent?.originalPaymentValue) + ' % Rabatt)' : '' }}</td>
        </tr>
        <tr>
          <td>Gutschein-Nr</td>
          <td>{{ firstParent.internalId }}</td>
        </tr>
        <tr *ngIf="firstParent.validTill">
          <td>Gültig bis</td>
          <td>{{ firstParent.validTill | nxtDate: 'dd.MM.yyyy' }}</td>
        </tr>
        <tr>
          <td>Erstellt</td>
          <td>{{ firstParent.createdAt | nxtDate: 'dd.MM.yyyy HH:ss' }}</td>
        </tr>
        <tr>
          <td>Erstellt von</td>
          <td>{{ firstParent.createdBy }}</td>
        </tr>
        <tr *ngIf="firstParent.additionalInfo">
          <td>Info</td>
          <td>{{ firstParent.additionalInfo }}</td>
        </tr>
        <tr *ngIf="firstParent.additionalInfo2 && firstParent.additionalInfo !== firstParent.additionalInfo2">
          <td>Info</td>
          <td>{{ firstParent.additionalInfo2 }}</td>
        </tr>
        <tr *ngIf="firstParent.discountReason && firstParent.discountReason !== firstParent.additionalInfo2">
          <td>Info</td>
          <td>{{ firstParent.discountReason }}</td>
        </tr>
        <tr *ngIf="firstParent.refunded">
          <td>Rückerstattung</td>
          <td>{{ firstParent.refundInfo }}</td>
        </tr>
        <tr *ngIf="firstParent.paymentMethod">
          <td>Zahlart</td>
          <td>{{ firstParent.paymentMethod | paymentMethod }}<br/>
            <div *ngIf="shopOrder">{{ shopOrder.transactionId }}</div>
          </td>
        </tr>
        <tr *ngIf="!shopOrder && firstParent.shopOrderId">
          <td>Shop-ID</td>
          <td>{{ firstParent.shopOrderId }}</td>
        </tr>
        <tr *ngIf="shopOrder">
          <td>Shop</td>
          <td>
            <div class="flex flex-row items-center">
              <div>{{ shopOrder.id }}</div>
              <div>
                <nxt-button (click)="onShowShopOrder.emit(shopOrder.id)" [smallButton]="true">anzeigen</nxt-button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>Studio-Transfer</td>
          <td>
            <nxt-button (click)="studioTransferClicked()" [smallButton]="true">an ein anderes Studio schicken</nxt-button>
          </td>
        </tr>
        @if (firstParent.paymentMethod === 'cash') {
          <tr>
            <td>Beleg</td>
            <td>
              <div class="flex flex-col">
                @if (firstParent.receiptNumber) {
                  <div>{{ firstParent.receiptNumber }}</div>
                }
                <nxt-button (click)="printReceipt()" [smallButton]="true">Beleg erneut drucken</nxt-button>
              </div>
            </td>
          </tr>
        }
        <tr *ngIf="false">
          <td>Shop-Json</td>
          <td>{{ shopOrder | json }}</td>
        </tr>
      </table>
    </div>
    <div fxLayout="column">
      <div *ngFor="let record of records">
        <nxt-gift-card-record [showOpenEvent]="showOpenEvent" [record]="record"></nxt-gift-card-record>
      </div>
    </div>
  </div>
</div>
