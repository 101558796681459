import {Component, Input, Optional} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {EventComponent} from '../../event/event.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {FormsModule} from '@angular/forms';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {NgFor} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-check-list',
    templateUrl: './check-list.component.html',
    styleUrls: ['./check-list.component.scss'],
    imports: [FlexModule, NgFor, MatSlideToggle, FormsModule, NxtButtonComponent]
})
export class CheckListComponent {
  @Input() title: string;
  @Input() checkItems: { name: string, checked: boolean }[];
  @Input() allCheckedToFinish = false;
  public allItemsChecked = false;


  constructor(
    @Optional() public dialogRef: MatDialogRef<EventComponent>,
  ) {
  }

  checkChanged() {
    this.allItemsChecked = !this.checkItems.some(i => !i.checked);
  }

  close() {
    this.dialogRef.close();
  }
}
