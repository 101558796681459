import {NxtUserInfo} from './nxt.user-info';
import {MathTools} from '../common-browser/helpers/math.tools';
import {DurationTools} from '../common-browser/helpers/duration.tools';
import {DateTools} from '../common-browser/helpers/date.tools';
import {ColorTools} from '../common-browser/helpers/color.tools';
import {NxtUserContext} from './nxt.user-context.interface';

export enum NxtReminderRefType {
  Event = 'event',
  Artist = 'artist',
  None = 'none',
  WorkSessionPayout = 'work-session-payout',
}

export interface NxtReminderPostpone {
  createdAt: number;
  createdBy: string;

  duration: number;
}

export enum NxtReminderType {
  PriceEstimateTillNotReached = 'PriceEstimateTillNotReached',
  Manual = 'Manual',
  AdditionalPay = 'AdditionalPay',
  RestPaymentOverDue = 'RestPaymentOverDue',
  ImproveArtistShouldGetMoney = 'ImproveArtistShouldGetMoney',
  Standalone = 'Standalone',
  MissingDeclarationsOfConsent = 'MissingDeclarationsOfConsent',
  DeclarationsOfConsentWrongSiteCount = 'DeclarationsOfConsentWrongSiteCount',
  WhatsAppConnect = 'WhatsAppConnect',
  Artist_ContractIsMissing = 'Artist_ContractIsMissing',
  Artist_ShowStencil = 'Artist_ShowStencil',
  Piercing_Info = 'Piercing_Info',
  Event_MissingStencil = 'Event_MissingStencil',
}

export type NxtReminderButtonAction =
  'openWhatsAppConnect'
  | 'showArtistContract'
  | 'showEvent'
  | 'setStencilSeen'
  | 'requestStencilFromArtist'
  | 'writeResponse'
  | 'setDone'
  | 'postpone'
  | 'artistConfirm'
  | 'startArtistTelegramChat'
  | 'openIcloudConnect'
  ;

export class NxtReminder {
  id: string;
  subId?: string;
  seqId?: string;
  reminderDate: number;
  reminderDateTime: number;
  reminderDateString: string;
  description: string;
  descriptionLong?: string;
  refType: NxtReminderRefType;
  refId?: string;
  refObj?: any;
  createdBy: string;
  createdByStudio: string;
  createdByWorkplace: string;
  createdAt: number;
  done: boolean;
  doneBy?: string;
  doneAt?: number;
  disableManualSetDone?: boolean;
  disableManualSetDoneText?: string;
  updatedBy: string;
  updatedAt: number;
  toDoByUser?: string;
  toDoByWorkplace?: 'reception' | 'backoffice' | '';
  type: NxtReminderType;
  responseExpected?: boolean;
  responseToReminderId?: boolean;
  // canPostpone?: boolean;
  postpones?: NxtReminderPostpone[];
  // updates: (NxtUserInfo & { createdAt: number, type: 'update' | 'done' })[];
  setDoneText?: string;
  buttons?: { text: string; action: NxtReminderButtonAction }[];

  /***
   * ob der Reminder ausgeblendet wird, wenn weiter als 15 min in der Zukunft liegt
   */
  hideIfNotOverDue?: boolean;
  /***
   * 0 = unwichtig
   * 100 = super wichtig!
   */
  priority: number;
  debugInfo?: string;
  /**
   * ms
   */
  postponeDurations: number[];
  maxPostpones?: number;
  maxPostponeDuration?: number;
  groupName?: string;
  originalReminderDateTime: number;
  maxPostponesReached?: boolean;

  static createEvent(type: NxtReminderType, eventId: string, reminderDateTime: number, description: string, userContext: NxtUserContext): NxtReminder {
    return {
      id: '',
      type,
      refType: NxtReminderRefType.Event,
      description,
      refId: eventId,
      createdBy: userContext.username,
      createdByStudio: userContext.studio || '',
      createdByWorkplace: userContext.workplace || '',
      createdAt: Date.now(),
      done: false,
      reminderDateTime,
      reminderDate: DateTools.clearTime(reminderDateTime),
      reminderDateString: DateTools.format(reminderDateTime, 'yyyy-MM-dd'),
      priority: 0,
      originalReminderDateTime: reminderDateTime,
      postponeDurations: [],
      updatedBy: userContext.username,
      updatedAt: Date.now(),
    };
  }

  static createEventSubId(type: NxtReminderType, eventId: string, subId: string, reminderDateTime: number, description: string, userInfo: NxtUserInfo): NxtReminder {
    return {
      id: '',
      type,
      refType: NxtReminderRefType.Event,
      description,
      refId: eventId,
      createdBy: userInfo.username,
      createdByStudio: userInfo.studio || '',
      createdByWorkplace: userInfo.workplace || '',
      createdAt: Date.now(),
      done: false,
      reminderDateTime,
      reminderDate: DateTools.clearTime(reminderDateTime),
      reminderDateString: DateTools.format(reminderDateTime, 'yyyy-MM-dd'),
      subId,
      priority: 0,
      originalReminderDateTime: reminderDateTime,
      postponeDurations: [],
      updatedBy: userInfo.username,
      updatedAt: Date.now(),
    };
  }

  static createSubId(refType: NxtReminderRefType, type: NxtReminderType, refId: string, subId: string, reminderDateTime: number, description: string, userInfo: NxtUserInfo): NxtReminder {
    return {
      id: '',
      type,
      refType,
      description,
      refId,
      createdBy: userInfo.username,
      createdByStudio: userInfo.studio || '',
      createdByWorkplace: userInfo.workplace || '',
      createdAt: Date.now(),
      done: false,
      reminderDateTime,
      reminderDate: DateTools.clearTime(reminderDateTime),
      reminderDateString: DateTools.format(reminderDateTime, 'yyyy-MM-dd'),
      subId,
      priority: 0,
      originalReminderDateTime: reminderDateTime,
      postponeDurations: [],
      updatedBy: userInfo.username,
      updatedAt: Date.now(),
    };
  }

  static createStandaloneOnlyOnceSubId(subId: string, reminderDateTime: number, description: string, userInfo: NxtUserInfo): NxtReminder {
    return {
      id: '',
      type: NxtReminderType.Standalone,
      refType: NxtReminderRefType.None,
      description,
      createdBy: userInfo.username,
      createdByStudio: userInfo.studio || '',
      createdByWorkplace: userInfo.workplace || '',
      createdAt: Date.now(),
      done: false,
      reminderDateTime,
      reminderDate: DateTools.clearTime(reminderDateTime),
      reminderDateString: DateTools.format(reminderDateTime, 'yyyy-MM-dd'),
      subId,
      priority: 0,
      originalReminderDateTime: reminderDateTime,
      postponeDurations: [],
      updatedBy: userInfo.username,
      updatedAt: Date.now(),
    };
  }

  static createStandalone(reminderDateTime: number, description: string, userInfo: NxtUserInfo): NxtReminder {
    return {
      id: '',
      type: NxtReminderType.Standalone,
      refType: NxtReminderRefType.None,
      description,
      createdBy: userInfo.username,
      createdByStudio: userInfo.studio || '',
      createdByWorkplace: userInfo.workplace || '',
      createdAt: Date.now(),
      done: false,
      reminderDateTime,
      reminderDate: DateTools.clearTime(reminderDateTime),
      reminderDateString: DateTools.format(reminderDateTime, 'yyyy-MM-dd'),
      priority: 0,
      originalReminderDateTime: reminderDateTime,
      postponeDurations: [],
      updatedBy: userInfo.username,
      updatedAt: Date.now(),
    };
  }

  static setNewDateTime(reminder: NxtReminder, newDateTime: number) {
    reminder.reminderDate = DateTools.clearTime(newDateTime);
    reminder.reminderDateString = DateTools.format(newDateTime, 'yyyy-MM-dd');
    reminder.reminderDateTime = newDateTime;
  }

  public static sortReminders(r1: { sortValue: number, reminderDateTime: number }, r2: { sortValue: number, reminderDateTime: number }) {
    if (r1.sortValue < r2.sortValue) {
      return -1;
    } else if (r1.sortValue > r2.sortValue) {
      return 1;
    } else if (r1.reminderDateTime > r2.reminderDateTime) {
      return 1;
    } else if (r1.reminderDateTime < r2.reminderDateTime) {
      return -1;
    }
    return 0;
  }

  public static GetReminderColor(reminder: NxtReminder & { overDueMinutes?: number }): string {
    const overDueMinutes = MathTools.round((Date.now() - reminder.reminderDateTime) / DurationTools.DURATION_1MINUTE);
    if (overDueMinutes >= 0) {
      if (reminder.priority === 100) {
        return ColorTools.RedDark;
      } else if (reminder.priority >= 70) {
        if (overDueMinutes > 20) {
          return ColorTools.RedDark;
        }
        return ColorTools.OrangeDark;
      } else if (reminder.priority >= 50) {
        if (overDueMinutes > 40) {
          return ColorTools.RedDark;
        }
        return ColorTools.OrangeDark;
      } else if (reminder.priority <= 50) {
        if (overDueMinutes > 60 * 4) {
          return ColorTools.RedDark;
        }
        return ColorTools.OrangeDark;
      } else {
        return ColorTools.OrangeDark;
      }
    }
    return '';
  }

  public static GetReminderSortValue(reminder: NxtReminder & { color?: string; sortValue: number }) {
    if (reminder.priority === 100) {
      return 1;
    }
    if (reminder.color === 'red' && reminder.priority >= 50) {
      return 3;
    }
    if (reminder.color === 'orange') {
      return 4;
    }
    return 5;
  }
}
