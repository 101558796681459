<table class="w-full tattoo-ticket-item-table-1">
  <tr>
    <td class="!w-[160px]">
      <div class="flex flex-col items-center">
        @if (item().direction === 'out') {
          <div class="flex flex-row justify-center items-center red">
            <mat-icon style="font-size: 18px; width: 18px; height: 18px">arrow_upward_alt</mat-icon>
            &nbsp;<div>Rückzahlung</div>&nbsp;
            <mat-icon style="font-size: 18px; width: 18px; height: 18px">arrow_upward_alt</mat-icon>
          </div>
          @if (item().assigned) {
            <div>{{ item().assignments[0].value | money }}</div>
            <div>zugewiesen</div>
          } @else {
            <div>{{ item().value | money }}</div>
            <div>nicht zugewiesen</div>
          }
        } @else {
          <div class="flex flex-row justify-center items-center">
            <mat-icon style="font-size: 18px; width: 18px; height: 18px">arrow_downward_alt</mat-icon>
            <div>&nbsp;Zahlung&nbsp;</div>
            <mat-icon style="font-size: 18px; width: 18px; height: 18px">arrow_downward_alt</mat-icon>
          </div>
          @if (item().assigned) {
            <div>{{ item().assignments[0].value | money }}</div>
            <div class="flex flex-row items-center">
              <mat-icon style="font-size: 18px; width: 18px; height: 18px">check</mat-icon>
              <div>&nbsp;zugewiesen&nbsp;</div>
              <mat-icon style="font-size: 18px; width: 18px; height: 18px">check</mat-icon>
            </div>
          } @else {
            <div class="green">{{ item().value | money }}</div>
            <div>nicht zugewiesen</div>
          }
        }
      </div>
    </td>
    <td class="!p-0" *ngIf="item().assignments.length > 0">
      <table class="nxt-table nxt-table-no-outer-border w-full tattoo-ticket-item-table-2">
        <tr *ngFor="let assignment of item().assignments; let i = index">
          <td *ngIf="item().assignments.length > 1">
            <div>{{ i + 1 }}. Zuweisung</div>
          </td>
          <td class="!p-0">
            <table class="nxt-table nxt-table-no-outer-border w-full tattoo-ticket-item-table-3">
              <tr>
                <td class="min-w-[200px]">Zugewiesener Betrag</td>
                <td class="min-w-[200px]">{{ assignment.value | money }}</td>
              </tr>
              <tr>
                <td>Zugewiesen am</td>
                <td>{{ assignment.assignedTime | nxtDate: 'dd.MM.yyyy HH:mm' }}</td>
              </tr>
              <tr>
                <td>Zugewiesen von</td>
                <td>{{ assignment.user || assignment.createdBy }}</td>
              </tr>
              <tr *ngIf="item().refundInfo || item().refundReason">
                <td>Info</td>
                <td>{{ item().refundInfo || item().refundReason }}</td>
              </tr>
              <tr>
                <td>Zugewiesen an</td>
                @if (assignment.eventId) {
                  <td>
                    @if (fromEventId() === assignment.eventId) {
                      <div>Dieser Termin</div>
                    } @else {
                      <nxt-button [smallButton]="true" (click)="showEvent.emit(assignment.eventId)">Termin Anzeigen</nxt-button>
                    }
                  </td>
                } @else {
                  <td *ngIf="assignment.refType">
                    <div>{{ assignment.refType | assignmentRefType: item().direction }}</div>
                    <div *ngIf="assignment.refId">{{ assignment.refId }}</div>
                  </td>
                }
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>




