import {Directive, ElementRef, HostBinding} from '@angular/core';


@Directive({
    selector: '[nxtCard]',
    standalone: true,
})
export class NxtCardDirective {

  @HostBinding('class')
  elementClass = 'nxt-card';

  constructor(el: ElementRef) {

  }
}

