<div class="flex flex-col w-full h-full text-[10px] page-wrapper p-relative">

  <div class="fixed-header text-xl" *ngIf="pageCountCurrent > 0 && pageCountCurrent <= pageCountTotal">
    <div class="flex flex-row">
      <div [nxtTranslate]="lang">SEITE</div>
      <div>&nbsp;{{ pageCountCurrent + ' / ' + pageCountTotal }}</div>
    </div>
  </div>

  <div class="flex justify-center p-3">
    <div class="flex flex-col justify-center items-center">
      <div>
        <img src="../../../../assets/logo/logo_black.svg" style="width: 6cm"/>
      </div>
      <div class="flex flex-row mt-1 logo-text">
        <div>NXT-LVL.INK {{ configService.config.value.studioName }}</div>
        <div class="flex flex-row">
          <div class="gold">・</div>
          {{ configService.config.value.invoiceData.fromStreet }}
        </div>
        <div class="flex flex-row">
          <div class="gold">・</div>
          {{ configService.config.value.invoiceData.fromPostalCode }} {{ configService.config.value.invoiceData.fromCity }}
        </div>
      </div>
    </div>
  </div>

  <div class="flex text-3xl flex-col items-center pb-3 header">
    <div [nxtTranslate]="lang" class="text-center">PFLEGEHINWEISE TATTOO</div>
  </div>

  @if (pageCountCurrent === 1) {
    <div>
      <nxt-declarations-of-consent-section titleText="FOLIE: ANWENDUNG UND HINWEISE" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・️</td>
              <td [nxtTranslate]="lang">Die handelsübliche Frischhaltefolie, mit der dein Tattoo bei uns abgedeckt wurde,
                solltest du nach 2-3 Stunden maximal entfernen. Im Anschluss muss das Tattoo mit
                lauwarmen klarem Wasser sauber gemacht werden. Mit einem Zewa kannst du es
                gerne vorsichtig trocken tupfen. In gar keinem Fall solltest du es mit einem Handtuch
                trocken reiben.
              </td>
            </tr>
            <tr>
              <td>・️</td>
              <td [nxtTranslate]="lang">Hiernach braucht dein Tattoo keine Folie mehr, da es nichts anderes als eine offene
                Schürfwunde ist, die Luft braucht, um zu heilen.
              </td>
            </tr>
            <tr>
              <td>・️</td>
              <td [nxtTranslate]="lang">In den ersten zwei Nächten kannst du dein Tattoo gerne mit einer Wickelunterlage
                umwickeln, zum Schutz vor Schmutz und Kleidungsfasern.
              </td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
      <nxt-declarations-of-consent-section titleText="CREMEN" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・️</td>
              <td [nxtTranslate]="lang">Es ist absolut wichtig, das Tattoo in der Heilphase von vier bis sechs Wochen
                regelmäßig einzucremen.
              </td>
            </tr>
            <tr>
              <td>・️</td>
              <td [nxtTranslate]="lang">Innerhalb der ersten 24-48 Stunden nach dem Stechen, allerdings nicht. Während
                des Tätowierens öffnen sich die Hautporen und es wird eine große Menge Vaseline
                eingearbeitet. Innerhalb der ersten zwei Tage stößt die Haut überschüssige Tinte und
                Wundflüssigkeit ab. Dementsprechend solltest du dein Tattoo bitte innerhalb der
                ersten 48 Stunden nur ca. viermal täglich mit klarem Wasser abwaschen und mit
                Zewa trocken tupfen. Sofern du es doch eincremst, verstopfen die Poren und es
                kommt zu einer Entzündung.
              </td>
            </tr>
            <tr>
              <td>・️</td>
              <td [nxtTranslate]="lang">Nach 48 Stunden fangen die Poren an sich zu schließen und der Heilungsprozess
                beginnt. Ab diesem Zeitpunkt solltest du dein Tattoo NACH DEM SÄUBERN zwei bis
                drei mal täglich hauchdünn eincremen.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Zur Häufigkeit des Cremens gibt es kein Geheimrezept! Jeder Hauttyp ist
                unterschiedlich. So sollten Menschen mit öliger Haut weniger häufig, und Menschen
                mit eher trockener Haut besser regelmäßiger eincremen.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Zu häufiges, sowie zu dickes Auftragen von Creme, kann zum Aufweichen der Haut
                und Farbverlust oder Pustelbildung führen. Cremt man zu wenig, bildet sich eine zu
                dicke Kruste auf dem Tattoo, die einreißen oder aufplatzen kann und ebenfalls zu
                Farbverlust oder sogar Vernarbung führen kann.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Verwende bitte eine geeignete Tattoocreme ohne Panthenol, keine Vaseline oder Bepanthen!</td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
      <nxt-declarations-of-consent-section titleText="WASCHEN/ REINIGEN DES TATTOOS" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Reinige das Tattoo nicht mit einer herkömmlichen Seife oder Duschgel.
                Diese sind wegen enthaltener Parfümstoffe und zu hohem ph-Wert nicht geeignet.
                Du kannst einfach eine handelsübliche Babyseife verwenden. Diese sind in der
                Regel unparfümiert.
              </td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
    </div>
  }

  @if (pageCountCurrent === 2) {
    <div>
      <nxt-declarations-of-consent-section titleText="SCHWEISS/ SCHMUTZ IN DER WUNDE VERMEIDEN" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Schweiß (Bakterien) und Schmutz können zu Entzündungen führen. Deshalb solltest
                du die Wunde möglichst sauber halten und vorerst auf Sport und schweißtreibende
                Aktivitäten verzichten, für ca. 4 Wochen.
              </td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
      <nxt-declarations-of-consent-section titleText="KEINE DIREKTE SONNE ODER SOLARIUM">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Direkte Sonneneinstrahlung ist für ca. 4 Wochen ebenfalls zu vermeiden, da die
                frische Farbe dadurch verblasst und es auf der wunden und dünnen, Haut leicht zu
                einem Sonnenbrand kommen kann, durch den sich die Haut, samt Tattoo, abschälen
                könnte
              </td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
      <nxt-declarations-of-consent-section titleText="BADEN/ LANGES DUSCHEN VERMEIDEN" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Vollbäder oder zu langes Duschen sind während des Heilungsprozesses zu
                vermeiden, da das Tattoo aufweichen kann, was zu Farbverlust führen kann. Vom
                Baden im Meer, Seen oder Frei-/Hallenbädern ist komplett abzuraten, da es zu
                Wundinfektionen führen kann.
              </td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
      <nxt-declarations-of-consent-section titleText="NICHT KRATZEN" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Während des Abheilens ist es ganz normal, dass die Haut anfangen kann zu jucken.
                Kratzen solltest du auf keinen Fall – dies beschädigt dein Tattoo.
              </td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
      <nxt-declarations-of-consent-section titleText="FUSSELIGE ODER ENGE KLEIDUNG" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Zu enge Kleidung scheuert an der Wunde und reizt das Tattoo. Fusseln, die nicht
                entfernt werden, können zu Entzündungen führen. Kleidung aus Baumwolle hat sich
                bewährt da es Wundsekrete gut aufnimmt und leicht zu reinigen ist.
              </td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
      <nxt-declarations-of-consent-section titleText="KLEIDUNG KLEBT AM TATTOO" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Lasse die betroffene Stelle in lauwarmen Wasser einweichen und versuche dann die
                Kleidung langsam und vorsichtig, wieder ablösen. Danach ist das Tattoo zu reinigen
                und einzucremen.
              </td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
      <nxt-declarations-of-consent-section titleText="RICHTIGE ERNÄHRUNG KANN DIE WUNDHEILUNG UNTERSTÜTZEN" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Gerade während der Wundheilung sollte man darauf achten, ausreichend Vitamin C
                zu sich zu nehmen. Zu wenig Vitamin C im Körper beeinträchtigt die Wundheilung.
                Dies ist insbesondere beim Genuss von Alkohol zu beachten, da dieser Vitamine
                abbaut.
              </td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
    </div>
  }
</div>
