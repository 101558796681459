import {AfterContentInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControlWrapper} from '../form-control-wrapper';
import {Subscription} from 'rxjs';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {UuidTools} from '../../../common-browser/helpers/uuid.tools';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgIf, NgStyle} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {FormFieldWrapperComponent} from '../../form-field-wrapper/form-field-wrapper.component';

declare const google: any;

@Component({
  selector: 'nxt-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  imports: [FormFieldWrapperComponent, MatFormField, FlexModule, NgStyle, ExtendedModule, MatLabel, MatInput, FormsModule, ReactiveFormsModule, NgIf, MatError, MatIcon, MatTooltip],
  standalone: true,
})
export class TextareaComponent extends FormControlWrapper implements OnInit, AfterContentInit, OnDestroy, OnChanges {
  public hasFocus = false;
  private valueChangeSubscription: Subscription;

  @Input() icon: { name: string, tooltip: string, click: () => void };

  @Input() color?: string;
  @Input() type = 'text';
  @Input() rows = 4;
  @Input() cols = 150;
  @Input() value: any;
  @Input() readonly: boolean;
  @Input() cellRenderer?: (value: any) => string;

  // @Input() displaySuffix?: string;

  @Input() isPercent = false;
  @Input() isNumber = false;
  @Input() isNumberMin: number;


  @Input() placeholder: string;

  @Output() valueChange = new EventEmitter<any>();
  @Output() enter = new EventEmitter<void>();

  @Input() google = false;
  controlName = UuidTools.generate();

  // lastCharDecimalSeparator = false;
  @Input() spellcheck = true;
  @Input() noPaddingBottom: boolean;

  constructor() {

    super();
  }


  ngOnInit() {
  }

  keyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.enter.observed) {
        this.enter.emit();
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  insertAtCursor(myValue) {
    if (this.controlElemRef.nativeElement.selectionStart || this.controlElemRef.nativeElement.selectionStart === 0) {
      const startPos = this.controlElemRef.nativeElement.selectionStart;
      const endPos = this.controlElemRef.nativeElement.selectionEnd;
      const newValue = this.controlElemRef.nativeElement.value.substring(0, startPos)
        + myValue
        + this.controlElemRef.nativeElement.value.substring(endPos, this.controlElemRef.nativeElement.value.length);
      this.nxtFormControl.setValue(newValue);
    } else {
      debugger;
    }
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.nxtFormControl.element = this.controlElemRef.nativeElement;
      let style = '';
      if (this.readonly) {
        (this.controlElemRef.nativeElement as HTMLInputElement).setAttribute('readonly', 'readonly');
        style += 'cursor: pointer; ';
      } else {
        style += 'cursor: text; ';
      }
      if (this.color) {
        if (this.color.toLowerCase() === 'red') {
          this.color = ColorTools.Red;
        }
        style += 'color:' + this.color + ' !important;';
      }

      if (style) {
        (this.controlElemRef.nativeElement as HTMLInputElement).setAttribute('style', style);
      }
      this.valueChangeSubscription = this.nxtFormControl.valueChanges.subscribe((value) => {
        this.valueChange.emit(value);
      });
    });
  }

  ngOnDestroy(): void {
    this.valueChangeSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.nxtFormControl.setValue(changes.value.currentValue);
    }
    if (!this.placeholder) {
      this.placeholder = this.nxtFormControl.name;
    }
  }
}
