import {inject, Injectable} from '@angular/core';
import {NxtBankTransactionStatus} from '../../../common-interfaces/bank/bank-transaction.interface';
import {DialogService} from '../../../services/dialog.service';
import {SocketService} from '../../../services/socket/socket.service';

@Injectable({
  providedIn: 'root'
})
export class BankTransactionService {
  /*** Injections ***/
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  async run(ids: string[]) {
    const result = await this.dialogService.showButtons('Aktion', {
      showCancelButton: true,
      buttons: [
        {text: NxtBankTransactionStatus._01_nxtAi, value: NxtBankTransactionStatus._01_nxtAi},
        {text: NxtBankTransactionStatus._03_delayed, value: NxtBankTransactionStatus._03_delayed},
        {text: NxtBankTransactionStatus._05_transferMatch, value: NxtBankTransactionStatus._05_transferMatch},
        {text: NxtBankTransactionStatus._07_documentMatch, value: NxtBankTransactionStatus._07_documentMatch},
      ],
    });
    if (!result) {
      return false;
    }
    const startFlow = await this.dialogService.showYesNo('Flow starten?');
    this.dialogService.showLoading(result.value + ' läuft...');
    await this.socketService.runBankTransferFlow(ids, result.value as NxtBankTransactionStatus, startFlow);
    this.dialogService.hideLoading();
    return true;
  }
}
