export interface NxtArticleOld {
  originalCreatedBy?: string;
  id: string;
  description: string;
  shortDescription: string;
  price: number;
  // isArtistDayPrice: boolean;
  _: boolean;
}

export interface NxtArticleGroup {
  id: string;
  description: string;
}


export class ArticleTools {

  public static articleGroups: NxtArticleGroup[] = [
    {id: 'care', description: 'Pflegeprodukt'},
    {id: 'jewelry', description: 'Schmuck'},
  ];


  public static articlesIncoming: NxtArticleOld[] = [
    {
      id: 'after_care_creme',
      shortDescription: 'Tattoo Pflege Creme',
      description: 'Tattoo Pflege Creme',
      price: 10,
      _: false,
    }, {
      id: 'after_care_creme',
      shortDescription: 'Prontolind Spray',
      description: 'Prontolind Spray',
      price: 10,
      _: false,
    }, {
      id: 'after_care_creme',
      shortDescription: 'Tattoo Pflege Creme',
      description: 'Tattoo Pflege Creme',
      price: 5,
      _: false,
    }, {
      id: 'after_care_creme',
      shortDescription: 'Tattoo Pflege Creme',
      description: 'Tattoo Pflege Creme',
      price: 0,
      _: false,
    }, {
      id: 'piercing_jewelry',
      shortDescription: 'Piercingschmuck',
      description: 'Piercingschmuck',
      price: -1,
      _: false,
    }, {
      id: 'piercing_aftercare',
      shortDescription: 'Piercing-Aftercare',
      description: 'Piercing-Aftercare',
      price: 5,
      _: false,
    }, {
      id: 'piercing_aftercare',
      shortDescription: 'Piercing-Aftercare',
      description: 'Piercing-Aftercare',
      price: 0,
      _: false,
    }, /*{
      id: '',
      shortDescription: 'Sonstiges',
      description: 'Sonstiges',
      price: -1,
      _: false,
    },*/ {
      id: '',
      shortDescription: 'Sonstiges Tresor',
      description: 'Sonstiges Tresor',
      price: -1,
      _: true,
    },
  ];

  /*public static getArticlesIncomingTransfer(currentStudio: string) {
      return ArticleTools._articlesIncomingTransfer.filter(a => !a.description.endsWith(currentStudio));
  }

  public static getArticlesOutgoingTransfer(currentStudio: string) {
      return ArticleTools._articlesOutgoingTransfer; // .filter(a => !a.description.endsWith(currentStudio));
  }*/

  private static _articlesOutgoingTransfer: NxtArticleOld[] = [
    {
      id: 'transfer_villa',
      shortDescription: 'Kasse Villa',
      description: 'Kasse Villa',
      price: -1,
      _: false,
    },
    {
      id: 'transfer_villa_',
      shortDescription: 'Kasse Villa _',
      description: 'Kasse Villa _',
      price: -1,
      _: true,
    }
  ];

  public static articlesIncomingTransfer: NxtArticleOld[] = [
    {
      id: 'bank',
      shortDescription: 'Bank',
      description: 'Bank',
      price: -1,
      _: false,
    }/*,
        {
            id: 'transfer_villa',
            shortDescription: 'Kasse Villa',
            description: 'Kasse Villa',
            price: -1,
            isArtistDayPrice: false,
            _: false,
        },
        {
            id: 'transfer_villa_2',
            shortDescription: 'Kasse Villa 2',
            description: 'Kasse Villa 2',
            price: -1,
            isArtistDayPrice: false,
            _: false,
        },
        {
            id: 'transfer_villa_2_',
            shortDescription: 'Kasse Villa 2 _',
            description: 'Kasse Villa 2 _',
            price: -1,
            isArtistDayPrice: false,
            _: true,
        }*/
  ];

  public static articlesIncomingPaypal: NxtArticleOld[] = [
    {
      id: 'piercing_jewelry',
      shortDescription: 'Piercingschmuck',
      description: 'Piercingschmuck',
      price: -1,
      _: false,
    }, {
      id: '',
      shortDescription: 'Sonstiges',
      description: 'Sonstiges',
      price: -1,
      _: false,
    },
  ];


  public static articlesOutgoing: NxtArticleOld[] = [
    {
      id: 'aldi_purchase',
      shortDescription: 'ALDI',
      description: 'ALDI',
      price: -1,
      _: false,
    },
    {
      id: 'killer_ink_purchase',
      shortDescription: 'Killer Ink',
      description: 'Killer Ink',
      price: -1,
      _: false,
    },
    {
      id: 'rossmann_purchase',
      shortDescription: 'Rossmann',
      description: 'Rossmann',
      price: -1,
      _: false,
    },
    {
      id: '',
      shortDescription: 'Sonstiges',
      description: 'Sonstiges',
      price: -1,
      _: false,
    },
    {
      id: 'beverages',
      shortDescription: 'Getränke Tresor',
      description: 'Getränke Tresor',
      price: -1,
      _: true,
    },
    {
      id: '',
      shortDescription: 'Sonstiges Tresor',
      description: 'SonstigesSafe',
      price: -1,
      _: true,
    },
    /*{
        id: 'transfer_cashbox2',
        shortDescription: 'Kasse 2',
        description: 'Kasse 2',
        price: -1,
        isArtistDayPrice: false,
        _: false,
    },
    {
        id: 'skim_off_bank',
        shortDescription: 'Abschöpfen Bank',
        description: 'Abschöpfen Bank',
        price: -1,
        isArtistDayPrice: false,
        _: false,
    },
    {
        id: 'transfer_bank',
        shortDescription: 'Bank',
        description: 'Bank',
        price: -1,
        isArtistDayPrice: false,
        _: false,
    },
    {
        id: 'transfer_brand',
        shortDescription: 'Kasse Brand',
        description: 'Kasse Brand',
        price: -1,
        isArtistDayPrice: false,
        _: false,
    },
    {
        id: 'transfer_villa',
        shortDescription: 'Kasse Villa',
        description: 'Kasse Villa',
        price: -1,
        isArtistDayPrice: false,
        _: false,
    }*/
  ];
}
