import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SocketService} from '../../../services/socket/socket.service';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {ConfigService} from '../../../services/config.service';
import {PdfTranslateTools} from '../../../common-browser/helpers/pdf-translate.tools';
import {TransPipe} from '../../../pipes/trans.pipe';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgFor, NgIf, NgStyle} from '@angular/common';
import {PermissionDirective} from '../../../directives/permission.directive';
import {NxtInvoiceData} from '../../../common-interfaces/nxt.invoice.interface';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';


@Component({
  selector: 'nxt-invoice-2-pdf',
  templateUrl: './invoice-2-pdf.component.html',
  styleUrls: ['./invoice-2-pdf.component.scss'],
  imports: [NgIf, NgStyle, ExtendedModule, FlexModule, NgFor, SafeHtmlPipe, MoneyPipe, TransPipe, PermissionDirective, NxtDatePipe]
})
export class Invoice2PdfComponent implements OnInit {

  @ViewChild('overflowCheck') overflowCheck: ElementRef;

  lang: 'DE' | 'ES' = 'DE';


  errorText = '';
  showSigning = true;
  showTotalTaxInfo = true;

  // fromCompany = 'GBX GmbH';
  // fromStreet = 'Theaterstraße 50-52';
  // fromPostalCode = '52062';

  // vatPercent = 19;

  data?: NxtInvoiceData;
  vatInfoParams: { totalGross: string; vatPercentage: string; vatValue: string; totalNet: string } = {
    totalGross: '? €',
    vatPercentage: '99 %',
    vatValue: '? €',
    totalNet: '? €',
  };

  constructor(
    private route: ActivatedRoute,
    private socketService: SocketService,
    public configService: ConfigService,
  ) {
    this.lang = this.configService.config.value.invoiceData.lang ?? 'DE';
    (window as any).setData = (data: any) => {
      this.setData(data);
    };
  }

  async ngOnInit() {

  }

  getNetto() {
    return this.data.invoiceValueTotalForTax / ((this.configService.config.value.invoiceData.vatPercent / 100) + 1);
  }

  getTax() {
    return this.data.invoiceValueTotalForTax - this.getNetto();
  }

  getFrom() {
    return this.configService.config.value.invoiceData.fromCompany;
  }

  getSalution(salutation: string) {
    if (this.lang === 'ES') {
      if (salutation === 'Herrn') {
        return 'Sr.';
      } else if (salutation === 'Frau') {
        return 'Sra.';
      } else if (salutation === 'Firma') {
        return 'Empresa';
      }
    }
    return salutation;
  }

  private setData(data: any) {
    this.data = data;
    if (this.data.invoiceTitle === PdfTranslateTools.getText('INVOICE_INVOICE', this.lang) || this.data.invoiceTitle === PdfTranslateTools.getText('INVOICE_CREDIT', this.lang)) {
      this.showTotalTaxInfo = true;
      this.showSigning = false;
    } else {
      this.showTotalTaxInfo = false;
      this.showSigning = true;
    }

    if (this.data.invoiceDate < DateTools.addDays(Date.now(), -7)) {
      // this.showSigning = false;
    }

    if (this.showTotalTaxInfo) {
      this.vatInfoParams = {
        totalGross: this.data.invoiceValueTotalForTax.toMoneyString(),
        totalNet: this.getNetto().toMoneyString(),
        vatPercentage: this.configService.config.value.invoiceData.vatPercent.toString(),
        vatValue: this.getTax().toMoneyString(),
      };
    }
  }
}
