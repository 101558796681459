@if (employee(); as employee) {
  <div class="flex flex-col">
    <div class="flex flex-row">
      <div class="flex flex-col">
        <table>
          <tr>
            <td>1.</td>
            <td>Bitte "{{ employee.givenName }}", das er/sie sich Telegram auf dem Handy installiert.</td>
          </tr>
          <tr>
            <td>2.</td>
            <td>Vollständig bei Telegram anmelden.</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>"{{ employee.givenName }}" danach diesen QR-Code scannen lassen.</td>
          </tr>
          <tr>
            <td>4.</td>
            <td>Es öffnet sich Telegram.<br/>Unten ist ein "Start" Button zu sehen, hier muss er/sie drauf klicken.</td>
          </tr>
          <tr>
            <td>5.</td>
            <td>Sobald er Start geklickt hat, schließt sich dieses Fenster hier ;)</td>
          </tr>
        </table>
      </div>
      <div class="flex flex-col">
        <qrcode
          errorCorrectionLevel="M"
          [qrdata]="qrCode()"
          [width]="300" colorDark="#FFF"
          colorLight="#2A2A2A">
        </qrcode>
      </div>
    </div>
    <div class="flex flex-row justify-center">
      <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
    </div>
  </div>
}
