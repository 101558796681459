import {NxtWorkplace} from '../../common-interfaces/nxt.employee.interface';

export class WorkplaceTools {
  public static workPlaces: {
    text: 'Empfang' | 'Backoffice' | 'Handwerk' | 'WhatsApp' | 'WhatsApp Franchise' | 'Piercing' | 'Terminbewertung' | 'Außendienst' | 'Koch' | 'Küchenhilfe' | 'Spüler' | 'Barkeeper' | 'Kellner' | 'Runner' | 'Handwerker' | 'Shisha',
    value: NxtWorkplace
  }[] = [
    {text: 'Empfang', value: 'reception'},
    {text: 'Backoffice', value: 'backoffice'},
    {text: 'Handwerk', value: 'craft'},
    {text: 'WhatsApp', value: 'whatsapp'},
    {text: 'WhatsApp Franchise', value: 'whatsappFranchise'},
    {text: 'Piercing', value: 'piercing'},
    {text: 'Terminbewertung', value: 'eventRating'},
    {text: 'Außendienst', value: 'fieldService'},
    {value: 'cook', text: 'Koch'},
    {value: 'kitchenAssistant', text: 'Küchenhilfe'},
    {value: 'dishwasher', text: 'Spüler'},
    {value: 'bartender', text: 'Barkeeper'},
    {value: 'waiter', text: 'Kellner'},
    {value: 'runner', text: 'Runner'},
    {value: 'handyman', text: 'Handwerker'},
    {value: 'shisha', text: 'Shisha'},
    {value: 'fieldService', text: 'Außendienst'},
  ];

  static getText(value: string | string[]) {
    if (Array.isArray(value)) {
      return value.map(v => WorkplaceTools.getText(v)).join(' &  ');
    }
    return WorkplaceTools.workPlaces.find(w => w.value === value)?.text || '';
  }

  static getValue(value: string): NxtWorkplace | '' {
    return WorkplaceTools.workPlaces.find(w => w.value === value)?.value || '';
  }
}
