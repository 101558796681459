import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtPageComponent} from '../nxt-page/nxt-page.component';
import {NxtPageHeaderComponent} from '../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageHeaderTitleComponent} from '../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageContentComponent} from '../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {MatDialogRef} from '@angular/material/dialog';
import {SocketService} from '../../services/socket/socket.service';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {IconTools} from '../../common-browser/helpers/icon.tools';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtWhatsAppChat} from '../../common-interfaces/whats-app/nxt-whatsapp-message.interface';
import {InputComponent} from '../form-controls/input/input.component';
import {DialogService} from '../../services/dialog.service';
import {WhatsappChatComponent} from '../whatsapp/whatsapp-chat/whatsapp-chat.component';

type ChatWithMessage = NxtWhatsAppChat & { message: string };

@Component({
  selector: 'nxt-chats-to-recheck',
  templateUrl: './chats-to-recheck.component.html',
  styleUrls: ['./chats-to-recheck.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtPageComponent, NxtPageHeaderComponent, NxtPageHeaderTitleComponent, NxtPageContentComponent, NxtPageFooterComponent, NxtDatagridComponent, InputComponent]
})

export class ChatsToRecheckComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  chats = signal<ChatWithMessage[]>([]);
  quickFilterText = signal('');

  chatsView = computed<ChatWithMessage[]>(() => this.chats().map(chat => {
    let message = '';
    if (chat.lastMessageFromMe) {
      message = chat.lastBodyMeUser + ': ' + chat.lastBodyMe;
    } else {
      message = 'Kunde: ' + chat.lastBodyCustomer;
    }
    return {
      ...chat,
      message,
    };
  }));

  columnDefs = signal<NxtColDef<ChatWithMessage>[]>([
    {headerName: '', nxtFieldType: NxtFieldType.Icon, nxtIcon: IconTools.Material.Eye, nxtOnCellClicked: params => this.openChatClicked(params.data)},
    {headerName: 'Name', field: 'name', minWidth: 250, nxtFieldType: NxtFieldType.Text},
    {headerName: '', nxtFieldType: NxtFieldType.Icon, nxtIcon: IconTools.Material.Check, nxtOnCellClicked: params => this.recheckDoneClicked(params.data)},
    {headerName: '', minWidth: 120, field: 'timestamp', nxtFieldType: NxtFieldType.DurationStringToNow},
    {headerName: 'Nachricht', minWidth: 500, maxWidth: 1000, field: 'message', nxtFieldType: NxtFieldType.Text},
  ]);

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  dialogRef = inject(MatDialogRef, {optional: true});

  constructor() {
    super();
  }

  ngOnInit() {
    this.load().then();
    this.socketService.subscribeNew('eventWhatsAppChatChanged', () => {
      this.load().then();
    }, {});
  }

  async load() {
    this.chats.set(await this.socketService.getChatsToRecheck());
  }

  nxtOnDestroy() {
  }

  private openChatClicked(chat: ChatWithMessage) {
    // const mobile = WhatsAppTools.toMobile(chat.id);
    const dialog = this.dialogService.showComponentFull(WhatsappChatComponent);
    dialog.componentInstance.loadChat(chat.id);
    dialog.componentInstance.title = chat.name;
    // window.open('/chat/' + mobile, '_blank');
  }

  private recheckDoneClicked(data: ChatWithMessage) {
    this.socketService.setChatRecheck(data.id, false);
  }

  public rowDoubleClicked(chat: ChatWithMessage) {
    this.openChatClicked(chat);
  }
}
