import {ChangeDetectionStrategy, Component, inject, model, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {FormsModule} from '@angular/forms';
import {InputComponent} from '../../form-controls/input/input.component';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {SocketInterfaceResponse} from '../../../common-interfaces/socket/socket-interface';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {IconTools} from '../../../common-browser/helpers/icon.tools';
import {DialogService} from '../../../services/dialog.service';
import {LoginService} from '../../../services/login.service';
import {PaymentTools} from '../../../common-browser/helpers/payment.tools';
import {MobileTools} from '../../../common-browser/helpers/mobile.tools';

@Component({
  selector: 'nxt-tattoo-ticket-select-for-payment',
  templateUrl: './tattoo-ticket-select-for-payment.component.html',
  styleUrls: ['./tattoo-ticket-select-for-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonToggle,
    MatButtonToggleGroup,
    FormsModule,
    InputComponent,
    NxtButtonComponent,
    NxtDatagridComponent,
  ],
})
export class TattooTicketSelectForPaymentComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  filterMode = signal<'code' | 'name'>('name');
  tattooTickets = signal<SocketInterfaceResponse.NxtTattooTicket[]>([]);

  /*** Models ***/
  tattooTicketCode = model('');
  tattooTicketName = model('Julian Krafft');

  /*** Injections ***/
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  private loginService = inject(LoginService);


  /*** Variable ***/
  columnDefs: NxtColDef<SocketInterfaceResponse.NxtTattooTicket>[] = [
    {headerName: '', nxtFieldType: NxtFieldType.Icon, nxtIcon: IconTools.Material.Check, nxtOnCellClicked: params => this.tattooTicketClicked(params.data)},
    {headerName: 'Name', field: 'fullName'},
    {
      headerName: 'Typ', valueGetter: params => {
        switch (params.data.type) {
          case 'klarna':
            return 'Klarna';
          case 'paypal':
            return 'Paypal';
          case 'payment-possibility-record':
            return 'mit Code';
        }
      },
    },
    {headerName: 'Betrag', field: 'value', nxtFieldType: NxtFieldType.MoneyFull},
    {headerName: 'Gekauft am', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDate},
    {headerName: 'ShopNr', field: 'shopOrderId', nxtFieldType: NxtFieldType.Text},
    {headerName: 'hasParent', field: 'hasParents', nxtFieldType: NxtFieldType.Text, hide: !this.loginService.isJulian()},
  ];


  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  filterModeChanged() {


  }

  find() {

  }

  async findClicked() {
    this.tattooTickets.set(await this.socketService.tattooTicket.findByName(this.tattooTicketName()));
    console.log(this.tattooTickets());
    console.log(this.tattooTickets());
    console.log(this.tattooTickets());
  }

  private async tattooTicketClicked(tattooTicket: SocketInterfaceResponse.NxtTattooTicket) {
    if (!tattooTicket.hasParents && tattooTicket.type !== 'payment-possibility-record') {
      const createCode = await this.dialogService.showYesNo('Das Tattoo-Ticket hat keinen Tattoo-Ticket-Code\nund wurde noch nicht benutzt.\nBitte Code erstellen', {yesText: 'Code erstellen', noText: 'Abbrechen'});
      if (createCode) {
        await this.sendTattooTicketToCustomer(tattooTicket.shopOrderId, tattooTicket.shopOrderLineItemId);
      }
    }
  }

  private async sendTattooTicketToCustomer(shopOrderId: string, shopOrderLineItemId: string) {
    const shopOrder = await this.socketService.getShopOrder(shopOrderId);
    if (shopOrder) {
      const lineItem = shopOrder.lineItems.find(li => li.id === shopOrderLineItemId);
      if (lineItem) {
        const email = shopOrder.billingEmail;
        const mobile = MobileTools.format(shopOrder.billingPhone);
        const title = lineItem.name + '\nTattoo-Ticket an Käufer schicken';
        let text = '';
        text += '\nKäufer: ' + shopOrder.billingFirstName + ' ' + shopOrder.billingLastName;
        text += '\ngekauft am: ' + shopOrder.createdAt.dateFormat('dd.MM.yyyy HH:mm');
        text += '\nbezahlt: ' + lineItem.value.toMoneyString() + ' via ' + PaymentTools.getPaymentMethodText(shopOrder.paymentMethod);
        text += '\n\nHandy: ' + mobile.replace('+49', '0');
        text += '\nE-Mail: ' + email;
        text += '\n\nFrage den Kunden wie er das Tattoo-Ticket empfangen möchte.';
        text += '\n\nGleiche die Daten vorher ab!';
        const result2 = await this.dialogService.showButtons(text, {buttons: [{text: 'WhatsApp', value: 'whatsapp'}, {text: 'E-Mail', value: 'email'}], title, showCancelButton: true});
        if (result2) {
          await this.socketService.tattooTicket.sendToCustomer(shopOrderId, shopOrderLineItemId, result2.value);
          this.dialogService.showOk('Erfolgreich verschickt, suche das Tattoo-Ticket nun anhand des Tattoo-Ticket Code');
        }
      }
    }
  }
}
