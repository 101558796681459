import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtPageHeaderTitleComponent} from '../../components/nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageContentComponent} from '../../components/nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../../components/nxt-page/nxt-page-footer/nxt-page-footer.component';
import {NxtPageComponent} from '../../components/nxt-page/nxt-page.component';
import {NxtPageHeaderComponent} from '../../components/nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtMonitorEventGlobal} from '../../common-interfaces/monitor.interface';

@Component({
    selector: 'nxt-monitor',
    templateUrl: './monitor.component.html',
    styleUrls: ['./monitor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NxtPageComponent, NxtPageHeaderComponent, NxtPageHeaderTitleComponent, NxtPageContentComponent, NxtPageFooterComponent, NxtDatagridComponent]
})

export class MonitorComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);

  columnDefs = signal<NxtColDef<NxtMonitorEventGlobal>[]>([
    {headerName: 'Typ', field: 'type', valueFormatter: (params) => params.value},
  ]);

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }
}
