import {Component, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtDayFinishLog} from '../../common-interfaces/nxt-day-finish-log.interface';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {SocketService} from '../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {DatePickerComponent} from '../form-controls/date-picker/date-picker.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-day-finish-log',
    templateUrl: './day-finish-log.component.html',
    styleUrls: ['./day-finish-log.component.scss'],
    imports: [FlexModule, DatePickerComponent, NxtButtonIconComponent, NxtDatagridComponent]
})

export class DayFinishLogComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  dateString = '';
  logs: NxtDayFinishLog[] = [];
  columnDefs: NxtColDef[] = [
    {headerName: 'Zeit', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDateTime},
    {headerName: 'Benutzer', field: 'createdBy', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Kasse', field: 'cashRegisterView', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Info', field: 'message', nxtFieldType: NxtFieldType.Text},
  ];

  constructor(
    private socketService: SocketService,
    @Optional() private dialogRef: MatDialogRef<DayFinishLogComponent>,
  ) {
    super();
  }

  ngOnInit() {
    this.dateString = DateTools.formatNowDate();
    this.pushSocketSubscription = this.socketService.subscribeNew('getDayFinishLog', (logs) => {
      this.logs = logs;
    }, {emitAfterReconnect: true, emitInitial: true, emitInitialData: this.dateString});
  }

  async load(){
    this.logs = await this.socketService.getDayFinishLog(this.dateString);
  }

  nxtOnDestroy() {

  }

  deleteClicked() {
    this.socketService.dayFinishDeleteLog(this.dateString);
  }

  closeClicked() {
    this.dialogRef.close();
  }
}
