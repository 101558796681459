<div class="flex flex-col w-full h-full text-[10px] page-wrapper p-relative">

  <div class="fixed-header text-xl" *ngIf="pageCountCurrent > 0 && pageCountCurrent <= pageCountTotal">
    <div class="flex flex-row">
      <div [nxtTranslate]="lang">SEITE</div>
      <div>&nbsp;{{ pageCountCurrent + ' / ' + pageCountTotal }}</div>
    </div>
  </div>

  <div class="flex justify-center p-3">
    <div class="flex flex-col justify-center items-center">
      <div>
        <img src="../../../../assets/logo/logo_black.svg" style="width: 6cm"/>
      </div>
      <div class="flex flex-row mt-1 logo-text">
        <div>NXT-LVL.INK {{ configService.config.value.studioName }}</div>
        <div class="flex flex-row">
          <div class="gold">・</div>
          {{ configService.config.value.invoiceData.fromStreet }}
        </div>
        <div class="flex flex-row">
          <div class="gold">・</div>
          {{ configService.config.value.invoiceData.fromPostalCode }} {{ configService.config.value.invoiceData.fromCity }}
        </div>
      </div>
    </div>
  </div>

  <div class="flex text-3xl flex-col items-center pb-3 header">
    <div [nxtTranslate]="lang" class="text-center">PFLEGEHINWEISE PIERCING</div>
  </div>
  <br/>
  <br/>
  @if (pageCountCurrent === 1) {
    <div>
      <nxt-declarations-of-consent-section titleText="NACHBEHANDLUNG KURZ UND KNAPP" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Vermeide unnötiges Berühren und Drehen des neuen Piercings, um die Keimbelastung zu reduzieren.</td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Wasche und trockne deine Hände immer gründlich, bevor du dein neues Piercing anfasst
                oder ziehe beim Reinigen Latex-/Nitrilhandschuhe an.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Zum desinfizieren deines Piercings verwendest du Prontolind. Dies sprühst du zwei mal am Tag auf dein Piercing
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Zur Reinigung von Mundpiercings solltest du eine milde Mundspülung verwenden, z.B. Dontodent bei DM ❗Anti-Bakteriell und ohne Alkohol❗</td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Vermeide es in den ersten 48 Stunden Alkohol zu trinken, da es die Blutung fördert und
                Schwellungen verstärken kann.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Bei Mundpiercings kann Rauchen den Heilungsprozess verzögern. Wenn möglich, meide
                das Rauchen bis zu zwei Wochen
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Vermeide es innerhalb der ersten vier Wochen auf die Sonnenbank, ins Schwimmbad
                und in die Sauna zu gehen. Nutze ebenfalls keine Bräunungscremes und ähnliches.
              </td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
      <br/>
      <br/>
      <nxt-declarations-of-consent-section titleText="EMPFOHLENE HEILZEITEN & TIPPS FÜR SCHNELLERE HEILUNG" [lang]="lang">
        <div class="py-2 px-10">
          <div class="title-2" [nxtTranslate]="lang">Ohrläppchen-Piercings</div>
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Lobes brauchen in der Regel etwa 4-6 Wochen, um zu heilen.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Zum desinfizieren deines Piercings verwendest du Prontolind.
                Dies sprühst du zwei mal am Tag auf dein Piercing.
                Das Piercing solltest du weder bewegen, drehen noch heraus nehmen.
              </td>
            </tr>
          </table>
        </div>

        <div class="py-2 px-10">
          <div class="title-2">
            <div [nxtTranslate]="lang">Ohrknorpel Piercings</div>
            <p [nxtTranslate]="lang">Tragus | Antitragus | Turm | Muschel | Daith | Snug | Industrial |Helix</p></div>
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Bei Knorpelpiercings kann die Heilung 3-6 Monate oder länger dauern.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Zum desinfizieren deines Piercings verwendest du Prontolind. Dies sprühst du zwei mal am Tag auf dein Piercing. Das Piercing solltest
                du weder bewegen, drehen noch heraus nehmen.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Halte potentiell schädliche Gegenstände von deinem Piercing fern, pass beim Haare
                bürsten auf es nicht zu berühren, nutze wenn möglich kein Haarspray in der Nähe des
                Piercings, trage keine Kopfhörer wenn sie dein Piercing berühren könnten, etc.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Schlafe für 3-6 Monate nicht auf der frisch gepiercten Ohrseite!
              </td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
    </div>
  }
  @if (pageCountCurrent === 2) {
    <div>
      <nxt-declarations-of-consent-section titleText="EMPFOHLENE HEILZEITEN & TIPPS FÜR SCHNELLERE HEILUNG" [lang]="lang">
        <div class="py-2 px-10">
          <div class="title-2">
            <div [nxtTranslate]="lang">Surface-Piercings</div>
            <p [nxtTranslate]="lang">Nape (Nacken) | Hüften | Handgelenke | Dekolletee | Dermal Anchor</p></div>
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Oberflächenpiercings sind in der Regel keine permanenten Piercings. Sie halten ca.
                zwischen 9 Monaten - 2 Jahren und brauchen etwa 4-6 Monate, um zu heilen.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Zum desinfizieren deines Piercings verwendest du Prontolind. Dies sprühst du zwei mal am Tag auf dein Piercing. Das Piercing solltest
                du weder bewegen, drehen noch heraus nehmen.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Speziell bei Surface: klebe dein Piercing eine Woche mit einem Pflaster ab, um eine längere Haltbarkeit zu erreichen.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Speziell bei Dermal Anchor: Klebe dein Piercing 3-6 Monate mit einem Pflaster ab, um eine längere Haltbarkeit zu erreichen.
              </td>
            </tr>
          </table>
        </div>
        <div class="py-2 px-10">
          <div class="title-2">
            <div [nxtTranslate]="lang">Mund-Piercings</div>
            <p [nxtTranslate]="lang">Zunge | Zungenbändchen | Labret (Lippe) | Madonna | Medusa | Wange |Smiley</p></div>
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Die meisten Mundpiercings brauchen 2-3 Monate, um vollständig zu heilen und schwellen
                innerhalb der ersten Stunden nach Stechen des Piercings an. Bei Wangenpiercings kann die
                Heilung etwas länger dauern, da dieser Gewebebereich dicker ist diese brauchen ca. 10-12
                Wochen, um vollständig zu heilen.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Bei der Pflege eines Mundpiercings wird empfohlen, eine milde, alkoholfreie
                Mundspülung als Hauptbestandteil der Nachsorge zu verwenden. Mit dieser solltest du
                morgens und Abends, sowie nach jedem Essen oder Trinken den Mund ausspülen.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Die ersten Tage nach dem Stechen solltest du auf Milchprodukte und scharfes Essen
                verzichten. Achte darauf, dass du nur essen zu dir nimmst, welches nicht am Piercing
                hängen bleiben kann (z.B. lieber Penne anstatt Spaghetti). Am besten nimmst du möglichst
                weiche Kost zu dir. Nach dem Essen den Mundraum immer mit einer antibakteriellen Lösung
                säubern.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Es wird empfohlen, nach einem Oralpiercing 10 Tage lang keinen Oralsex auszuüben, da
                der Austausch von Körperflüssigkeiten das Infektionsrisiko für dich und deinen Partner
                erhöhen kann.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Körperflüssigkeiten sollten generell 2 Wochen vermieden werden</td>
            </tr>
          </table>
        </div>

        <div class="py-2 px-10">
          <div class="title-2">
            <div [nxtTranslate]="lang">Gesichts-Piercings</div>
            <p [nxtTranslate]="lang">Augenbraue | Brücke | Nostril | Septum</p></div>
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Die Heilung eines Nostrils (Nasenflügelpiercings) dauert etwa 2-3 Monate. Für
                Nasenflügelpiercings ist es wichtig, die Innenseite des Piercings (d. h. das Nasenloch) sowie
                die Außenseite zu reinigen, da sich Bakterien in deinem Nasenloch ansammeln können. Sei
                so vorsichtig wie möglich, wenn du dir die Nase putzt.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Bei Septum-Piercings dauert die Heilung zwischen 3-6 Monate.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Bei Augenbrauen- und Bridge Piercings dauert die Heilung zwischen 2-3 Monate.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Zum desinfizieren deines Piercings verwendest du Prontolind. Dies sprühst du zwei mal am Tag auf dein Piercing. Das Piercing solltest
                du weder bewegen, drehen noch heraus nehmen.
              </td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
    </div>
  }
  @if (pageCountCurrent === 3) {
    <div>
      <nxt-declarations-of-consent-section titleText="EMPFOHLENE HEILZEITEN & TIPPS FÜR SCHNELLERE HEILUNG" [lang]="lang">
        <div class="py-2 px-10">
          <div class="title-2">
            <div [nxtTranslate]="lang">Torso-Piercings</div>
            <p [nxtTranslate]="lang">Nabel (Bauchnabel) | Nippel</p></div>
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Ein Bauchnabelpiercing braucht etwa 4-6 Monate zum Abheilen. Trage 4 Monate keine enge oder
                Highwaist Hosen, damit kein Druck auf den Bauchnabel ausgeübt wird.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Die Heilung von Brustwarzenpiercings kann je nach Art der Wundheilung etwa 3-6
                Monate dauern. Deine Oberkörperbekleidung sollte möglichst locker sitzen, damit kein Druck
                auf die Piercings ausgeübt wird.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Zum desinfizieren deines Piercings verwendest du Prontolind. Dies sprühst du zwei mal am Tag auf dein Piercing. Das Piercing solltest
                du weder bewegen, drehen noch heraus nehmen.
              </td>
            </tr>
          </table>
        </div>
        <div class="py-2 px-10">
          <div class="title-2">
            <div [nxtTranslate]="lang">Intimpiercings im Schleimhautbereich</div>
            <p [nxtTranslate]="lang">Christina / KVV / KVH</p></div>
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Die Heilung bei einem Intimpiercing im Schleimhautbereich dauert etwa 4-8 Wochen.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Zum desinfizieren benutzt du am besten Eigenurin.</td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Unnötiger Druck, sowie enge Hosen sollten während des Heilungsphase vermieden werden.</td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Fremde Körperflüssigkeiten sollten die ersten 14 Tage vermieden werden</td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Bewege das Piercing während der Heilungsphase nicht unnötig und entferne es nicht.</td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
    </div>
  }
</div>
