@if (!data) {
  <div>KEINE DATEN</div>
}
@if (data) {
  <div *ngIf="errorText" [innerHTML]="errorText | safeHtml" style="margin: 2cm"></div>
  <div id="data" *ngIf="!errorText" fxLayout="row" class="wrapper" style="width:100%; height: 100%; border: 0px solid black !important;">
    <div fxLayout="column" style="border: 0px solid red; position:relative; width:100%; height:100%">
      <div style="width:100%; font-size: 20px;" fxLayout="row" fxLayoutAlign="center">
        KASSENBERICHT - {{ data.studio.toUpperCase() }}
      </div>
      <div style="width:100%" fxLayout="row" fxLayoutAlign="end">
        <table class="header-table">
          <tr style="font-size: 80%">
            <td>Kassenbericht-Nr:</td>
            <td>{{ data.id }}</td>
          </tr>
          <tr style="font-size: 80%">
            <td>Datum:</td>
            <td>{{ data.dateString | nxtDate: 'dd.MM.yyyy' }}</td>
          </tr>
          <tr style="font-size: 80%">
            <td>Mitarbeiter:</td>
            <td>{{ data.employeeName }}</td>
          </tr>
          <tr style="font-size: 80%">
            <td>Seite:</td>
            <td>{{ data.currentPageCount }}/{{ data.totalPageCount }}</td>
          </tr>
        </table>
      </div>
      <div fxLayout="row" style="width:100%;">
        <div fxFlex>
          <table fxFlex class="data-table data-table-left" [ngStyle]="{fontSize: incomingFontSize}">
            <tr>
              <th colspan="3" style="text-align: center">EINNAHMEN</th>
            </tr>
            <tr>
              <th>Kunde</th>
              <th>Bezeichnung</th>
              <th>Betrag</th>
            </tr>
            <tr *ngFor="let incomingPayment of data.cashPaymentsIn">
              <td class="no-wrap">{{ incomingPayment.eventCustomerName }}</td>
              <td class="no-wrap">{{ getPaymentTypeText(incomingPayment) }}</td>
              <td class="no-wrap right">{{ incomingPayment.paymentValue | money }}</td>
            </tr>
            <tr *ngIf="showIncomingSum">
              <td colspan="2">Summe</td>
              <td class="right no-wrap"><strong>{{ data.cashPaymentsInSum | money }}</strong></td>
            </tr>
          </table>
        </div>
        <div fxFlex fxLayout="column" fxLayoutAlign="start" style="border:0px solid red">
          <table *ngIf="showOutgoings" class="data-table data-table-right" [ngStyle]="{fontSize: outgoingFontSize}">
            <tr>
              <th colspan="3" style="text-align: center">AUSGABEN</th>
            </tr>
            <tr>
              <th>Bezeichnung</th>
              <th>Betrag</th>
            </tr>
            <tr *ngFor="let outgoingPayment of data.cashPaymentsOut">
              <td>{{ getPaymentTypeText(outgoingPayment) }}</td>
              <td class="no-wrap" style="text-align: right;">{{ outgoingPayment.paymentValue | money }}</td>
            </tr>
            <tr *ngIf="showOutgoingSum">
              <td>Summe</td>
              <td class="no-wrap" style="text-align: right;"><strong>{{ data.cashPaymentsOutSum | money }}</strong></td>
            </tr>
          </table>
        </div>
      </div>
      <br/>
      <div *ngIf="isLastPage">
        <table class="money-calc-table" *ngIf="data.moneyStackBeforePayouts" style="margin-bottom: 2px">
          <tr>
            <th *ngIf="data.isMainCashRegister" colspan="15" style="text-align: center">ZÄHLPROTOKOLL VOR BANK UND ARTIST-ABRECHNUNG INKL. STARTGELD</th>
            <th *ngIf="!data.isMainCashRegister" colspan="15" style="text-align: center">ZÄHLPROTOKOLL VOR KASSEN-TRANSFER ZUR HAUPTKASSE</th>
          </tr>
          <tr>
            <td>0,01 €</td>
            <td>0,02 €</td>
            <td>0,05 €</td>
            <td>0,10 €</td>
            <td>0,20 €</td>
            <td>0,50 €</td>
            <td>1 €</td>
            <td>2 €</td>
            <td>5 €</td>
            <td>10 €</td>
            <td>20 €</td>
            <td>50 €</td>
            <td>100 €</td>
            <td>200 €</td>
            <td>Summe</td>
          </tr>
          <tr>
            <td *ngFor="let money of moneys">
              {{ data.moneyStackBeforePayouts[money] }}
            </td>
            <td>
              {{ getTotalValueFromMoneyStack(data.moneyStackBeforePayouts) | money }}
            </td>
          </tr>
        </table>
        <table class="money-calc-table" *ngIf="data.moneyStackEnd">
          <tr>
            <th colspan="15" style="text-align: center">ZÄHLPROTOKOLL KASSE ENDE</th>
          </tr>
          <tr>
            <td>0,01 €</td>
            <td>0,02 €</td>
            <td>0,05 €</td>
            <td>0,10 €</td>
            <td>0,20 €</td>
            <td>0,50 €</td>
            <td>1 €</td>
            <td>2 €</td>
            <td>5 €</td>
            <td>10 €</td>
            <td>20 €</td>
            <td>50 €</td>
            <td>100 €</td>
            <td>200 €</td>
            <td>Summe</td>
          </tr>
          <tr>
            <td *ngFor="let money of moneys">
              {{ data.moneyStackEnd[money] }}
            </td>
            <td>
              {{ getTotalValueFromMoneyStack(data.moneyStackEnd) | money }}
            </td>
          </tr>
        </table>
      </div>
      <div *ngIf="isLastPage"><br/><br/><br/>_________________________________________<br/>{{ data.employeeName }}</div>
      <div *ngIf="isLastPage" style="position: absolute; left:0; right:0; bottom:25px; border: 0px solid blue">
        <div style="width: 30%; float:right;">
          <table class="footer-table" style="width: 100%">
            <tr>
              <td class="no-wrap">Start</td>
              <td class="no-wrap right">{{ data.startMoney | money }}</td>
            </tr>
            <tr>
              <td class="no-wrap">Summe Einnahmen</td>
              <td class="no-wrap right">{{ data.cashPaymentsInSum | money }}</td>
            </tr>
            <tr>
              <td class="no-wrap">Summe Ausgaben</td>
              <td class="no-wrap right">{{ data.cashPaymentsOutSum | money }}</td>
            </tr>
            <tr>
              <td class="no-wrap">Kassenfehlbetrag -/+</td>
              <td class="no-wrap right">{{ data.missingMoney | money }}</td>
            </tr>
            <tr>
              <td class="no-wrap">Bestand Ende</td>
              <td class="no-wrap right">{{ data.endMoney | money }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
}
