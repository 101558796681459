import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';
import {JsonTools} from '../common-browser/helpers/json.tools';
import {NxtLogin} from './login.service';
import {SocketInterfaceResponse} from '../common-interfaces/socket/socket-interface';
import {NxtEventQuery} from '../common-interfaces/nxt.calendar-event.interface';
import CalendarFilter = SocketInterfaceResponse.CalendarFilter;

export interface BankTransactionFilterStorage {
  mode: 'time' | 'bank-cash' | 'opos';
  filterNotFinished: boolean;
  filterCompany: string[];
  dateStringFrom: string;
  dateStringTill: string;
  // filterOnlyLastMonth: boolean;
}

export interface BankDocumentFilterStorage {
  filterMode: 'time' | 'newDocuments';
  // filterOnlyLastMonth: boolean;
  filterNotFinished: boolean;
  dateStringFrom: string;
  dateStringTill: string;
}

export enum StorageKeyOld {
  CurrentLogin,
  DisabledWorkplaces,
  CurrentLayoutState,
  FirebaseLoggedIn,
  ForceDailyLogin,
  WhatsAppChatStyle,
  OldStudioCashReport,
  ShowOldNewVersionButton,
  CalendarState,
  StudioLogins,
  WorkplaceLogins,
  FirebaseLastUser,
}

export interface StorageKeys {
  CurrentLogin: NxtLogin;
  DisabledWorkplaces: string[];
  FirebaseLoggedIn: boolean;
  ForceDailyLogin: boolean;
  WhatsAppChatStyle: string;
  CalendarState: CalendarFilter;
  StudioLogins: string[];
  WorkplaceLogins: string[];
  FirebaseLastUser: string;
  ShowOldNewVersionButton: boolean;
  PhotosEventQuery: NxtEventQuery;
  BankTransactionFilter: BankTransactionFilterStorage;
  BankDocumentFilter: BankDocumentFilterStorage;
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  private prefix = '';

  constructor(private configService: ConfigService) {
    this.prefix = configService.config.value.studioRegion;
    this.test();
  }

  get<K extends keyof StorageKeys>(key: K, defaultValue: StorageKeys[K]): StorageKeys[K] {
    const result = JsonTools.parse(localStorage.getItem(this.prefix + '_' + key)) as any;
    if (result === null) {
      return defaultValue;
    }
    return result;
  }

  async test() {
  }

  /*get_old<T>(key: StorageKey, defaultValue?: T) {
    const result = JsonTools.parse(localStorage.getItem(this.prefix + '_' + StorageKey[key])) as T;
    if (result === null) {
      return defaultValue;
    }
    return result;
  }*/

  getByString<T>(key: string, defaultValue?: T) {
    const data = localStorage.getItem(this.prefix + '_' + key);
    try {
      const result = JsonTools.parse(data) as T;
      if (result === null) {
        return defaultValue;
      }
      return result;
    } catch (err) {

    }
    return defaultValue;
  }


  set<T extends StorageKeys[K], K extends keyof StorageKeys>(key: K, value: T) {
    localStorage.setItem(this.prefix + '_' + key, JsonTools.stringify(value));
  }

  /*set_old<T>(key: StorageKey, value: T) {
    return localStorage.setItem(this.prefix + '_' + StorageKey[key], JsonTools.stringify(value));
  }*/

  setByString<T>(key: string, value: T) {
    return localStorage.setItem(this.prefix + '_' + key, JsonTools.stringify(value));
  }
}
