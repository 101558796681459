import {DateTools} from './date.tools';

export class BirthdayTools {
  static getBirthdayInfo(birthday: number): { text: string, daysToBirthday: number, age: number } {
    let result = '';
    const thisYearBirthday = (new Date(birthday)).setFullYear(new Date().getFullYear());
    let days = (thisYearBirthday - Date.now()) / 1000 / 60 / 60 / 24;
    const daysTotal = days;
    if (days < -3) {
      const nextBirthday = new Date(birthday).setFullYear(new Date().getFullYear() + 1);
      days = (nextBirthday - Date.now()) / 1000 / 60 / 60 / 24;
    }
    days = Math.floor(days) + 1;
    if (days < -2) {
      result = 'hatte vor ' + Math.abs(days) + ' Tagen Geburtstag';
    } else if (days === -2) {
      result = 'hatte vorgestern Geburtstag';
    } else if (days === -1) {
      result = 'hatte gestern Geburtstag';
    } else if (days === 0) {
      result = '🥳hat heute Geburtstag🥳';
    } else if (days === 1) {
      result = 'hat morgen Geburtstag';
    } else if (days === 2) {
      result = 'hat übermorgen Geburtstag';
    } else {
      result = 'hat in ' + days + ' Tagen Geburtstag';
    }
    let age = new Date().getFullYear() - new Date(birthday).getFullYear();
    if (daysTotal > 0) {
      age -= 1;
    }
    return {text: result, daysToBirthday: days, age};
  }

  static test() {
    const result = BirthdayTools.getBirthdayInfo(DateTools.parse('2000-01-01'));

  }

  static hasBirthdayToday(birthday: any) {
    if (!birthday) {
      return false;
    }
    return DateTools.parse(birthday).dateFormat('MM-dd') === Date.now().dateFormat('MM-dd');
  }
}
