<div fxLayout="column" style="height:100%;">
  <div >
    <nxt-button-icon style="float:right"
      (click)="closeDialog()"
      tooltip="schließen"
    >close</nxt-button-icon>
  </div>
  <nxt-datagrid
    fxFlex
    [columnDefs]="columnDefs"
    [rowData]="historyData"
    [showSideBar]="false"
  >
  </nxt-datagrid>
</div>
