import {Injectable} from '@angular/core';
import {SocketService} from '../socket/socket.service';
import {DialogService} from '../dialog.service';

@Injectable({
  providedIn: 'root'
})
export class KlarnaRefundService {

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
  ) {
  }

  async refundShopOrderItem(shopOrderLineItemId: string, refundValue: number, value: number, message: string, refId: string, refType: 'gift-card' | 'klarna-order') {
    if (await this.dialogService.showYesNo(message, {yesText: refundValue.toMoneyString() + ' erstatten', noText: 'Abbrechen'})) {
      const reason = await this.dialogService.showInput('Grund für die Erstattung');
      if (reason) {
        const result = await this.socketService.klarnaRefundShopOrderItem({
          shopOrderLineItemId,
          refundValue,
          refId,
          reason,
          refType
        });
        this.dialogService.showOk('Erfolgreich zurückgezahlt');
      }
    }
  }
}
