import {Component, Input, OnInit} from '@angular/core';
import {NxtConfig} from '../../../../common-interfaces/nxt.config.interface';
import {DurationTools} from '../../../../common-browser/helpers/duration.tools';
import {DurationPipe} from '../../../../pipes/duration.pipe';
import {NxtButtonIconComponent} from '../../../../controls/button-icon/nxt-button-icon.component';
import {TextareaComponent} from '../../../form-controls/textarea/textarea.component';
import {InputComponent} from '../../../form-controls/input/input.component';
import {TimePickerComponent} from '../../../form-controls/time-picker/time-picker.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {SlideToggleComponent} from '../../../form-controls/slide-toggle/slide-toggle.component';
import {NgFor, NgIf} from '@angular/common';

@Component({
    selector: 'nxt-settings-whatsapp',
    templateUrl: './settings-whatsapp.component.html',
    styleUrls: ['./settings-whatsapp.component.scss'],
    imports: [NgIf, SlideToggleComponent, FlexModule, TimePickerComponent, NgFor, InputComponent, TextareaComponent, NxtButtonIconComponent, DurationPipe],
  standalone: true,
})

export class SettingsWhatsappComponent implements OnInit {

  @Input() whatsApp: NxtConfig.WhatsApp;

  constructor() {
  }

  ngOnInit() {

  }

  public addMessage() {
    this.whatsApp.autoResponse.messages.push({mobileStartsWith: '', message: ''});
  }

  public removeMessage() {
    if (this.whatsApp.autoResponse.messages.length > 0) {
      this.whatsApp.autoResponse.messages.length -= 1;
    }
  }

  public setLastIncomingMessageThreshold(value: string) {
    if (value) {
      this.whatsApp.autoResponse.lastIncomingMessageThreshold = DurationTools.parse(value);
    }
  }
}
