import {SortTools} from './sort.tools';


export class ObjectGroupTools {

    public static test(value: string) {
        const ob = {
            [value]: 'declarations-of-consent'
        };
        return ob;
    }

    public static reGroupObjectArray<T, W, K extends keyof T>(objectArrayToGroup: T[], groupBy: K): { value: T[K], list: T[] }[] {
        const map = new Map<T[K], T[]>();
        for (const obj of objectArrayToGroup) {
            const value = obj[groupBy];
            if (!map.get(value)) {
                map.set(value, []);
            }
            map.get(value)?.push(obj);
        }
        let result: any[] = [];
        for (const key of map.keys()) {
            const obj: any = {};
            obj.list = map.get(key);
            obj.value = key;
            result.push(obj);
        }
        if (result.length > 0) {
            if (typeof result[0].value === 'string') {
                result = result.sort(SortTools.sortString('value'));
            } else if (typeof result[0].value === 'number') {
                result = result.sortNumber('value');
            }
        }
        return result;
    }


    public static groupToObjectArray<T>(groups: { [key: string]: T[] }, keyPropertyName: string, arrayPropertyName: string): any[] {
        const result: any[] = [];
        for (const key of Object.keys(groups)) {
            const obj: any = {};
            obj[arrayPropertyName] = groups[key];
            obj[keyPropertyName] = key;
            result.push(obj);
        }
        return result;
    }

    public static groupObjectArray<T, K extends keyof T>(objectArrayToGroup: T[], groupBy: K): { [groupBy: string]: T[] } {
        const result: { [groupBy: string]: T[] } = {};
        for (const obj of objectArrayToGroup) {
            const value = obj[groupBy];

            if (!(result as any)[value]) {
                (result as any)[value] = [];
            }
            (result as any)[value].push(obj);
        }
        return result;
    }

    public static groupObjectArrayWithGetterFn<T>(objectArrayToGroup: T[], groupByFn: (item: T) => string): { [groupBy: string]: T[] } {
        const result: { [groupBy: string]: T[] } = {};
        for (const obj of objectArrayToGroup) {
            const value = groupByFn(obj);
            if (!result[value]) {
                result[value] = [];
            }
            result[value].push(obj);
        }
        return result;
    }

    public static groupObjectArrayAndSum<T, K extends keyof T>(objectArrayToGroup: T[], groupBy: K, sumBy: string): {
        groupBy: string,
        elements: any[],
        sum: number
    }[] {
        const groupedObjectArray: { [groupBy: string]: T[] } = ObjectGroupTools.groupObjectArray(objectArrayToGroup, groupBy);
        let result: { groupBy: string, elements: any[], sum: number }[] = [];
        for (const key of Object.keys(groupedObjectArray)) {
            result.push({
                groupBy: key,
                elements: groupedObjectArray[key],
                sum: groupedObjectArray[key].reduce((sum, obj) => sum + parseFloat(obj[sumBy]), 0)
            });
        }
        result = result.sort(SortTools.sortString('groupBy'));
        return result;
    }
}
