<div class="nxt-page w-full h-100" fxLayout="column">
<div fxLayout="row" fxLayoutAlign="space-between center">
  <div>
    <nxt-slide-toggle [(value)]="showDebug" (valueChange)="showDebugChanged()">Julian</nxt-slide-toggle>
  </div>
  <nxt-button-icon (click)="dialogRef.close()">close</nxt-button-icon>
</div>
  <nxt-history
    #historyComponent
    class="w-full h-100"
    table="inventory-items"
    [id]="id"
    [actions]="['update', 'create']"
    [propDefs]="propDefs"
    [prepareRawData]="prepareRawData"
    [filterItem]="filterItem"
    [hiddenProps]="hiddenProps"
  ></nxt-history>
</div>
