import {Component, OnInit, Optional, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {HistoryComponent, NxtHistoryPropDef} from '../history.component';
import {SocketService} from '../../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {NxtCalendarEvent} from '../../../common-interfaces/nxt.calendar-event.interface';
import {BodyPutService} from '../../../services/body-put.service';
import {ConfigService} from '../../../services/config.service';
import {MathTools} from '../../../common-browser/helpers/math.tools';
import {JsonTools} from '../../../common-browser/helpers/json.tools';
import {StringTools} from '../../../common-browser/helpers/string.tools';
import {PaymentTools} from '../../../common-browser/helpers/payment.tools';
import {NxtPayment} from '../../../common-interfaces/nxt.payment.interface';
import {EventTools} from '../../../common-browser/helpers/event.tools';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
  selector: 'nxt-calendar-event-history',
  templateUrl: './calendar-event-history.component.html',
  styleUrls: ['./calendar-event-history.component.scss'],
  imports: [FlexModule, NxtButtonIconComponent, HistoryComponent],
})

export class CalendarEventHistoryComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor(
    private socketService: SocketService,
    @Optional() private dialogRef: MatDialogRef<CalendarEventHistoryComponent>,
    private bodyPutService: BodyPutService,
    private configService: ConfigService,
  ) {
    super();
    this.hiddenProps.push(...this.debugProps);
  }

  @ViewChild(HistoryComponent) historyComponent: HistoryComponent;

  public propDefs: NxtHistoryPropDef<NxtCalendarEvent>[] = [
    {
      field: 'artist',
      name: 'Artist',
      type: NxtFieldType.Text,
    },
    {
      field: 'improve',
      name: 'improve',
      type: NxtFieldType.Boolean,
    },
    {
      field: 'workType',
      name: 'Typ',
      type: NxtFieldType.Text,
      valueGetter: (value, data) => {
        if (value === 'tattoo') {
          return 'Tattoo';
        } else if (value === 'piercing') {
          return 'Piercing';
        }
        return 'nicht gesetzt';
      },
    },
    {
      field: 'invoiceNumber',
      name: 'Rechnungs-Nr',
      type: NxtFieldType.Text,
    },
    {
      field: 'adjustPrice',
      name: 'Preis - Preis evtl. anpassen',
      type: NxtFieldType.Boolean,
      hideOnAdd: (value) => !value,
    }, {
      field: 'noAppointmentReminder',
      name: 'Kunde - Keine Terminerinnerung',
      type: NxtFieldType.Boolean,
      hideOnAdd: (value) => !value,
    }, {
      field: 'noDepotNecessary',
      name: 'Kaution - Keine Kaution nötig',
      type: NxtFieldType.Boolean,
      hideOnAdd: (value) => !value,
    }, {
      field: 'visibility',
      name: 'Tresor',
      valueGetter: (value) => value === 'private',
      type: NxtFieldType.Boolean,
    },
    {
      field: 'workType',
      name: 'Arbeit',
      textGetter: (params) => params.diff.val.replace('piercing', 'Piercing').replace('tattoo', 'Tattoo'),
      type: NxtFieldType.Text,
    }, {
      field: 'pdf1Count',
      name: 'Seitenzahl Einwilligungserklärung',
      type: NxtFieldType.Text,
    }, {
      field: 'invoiceNumber',
      name: 'Rechnungsnummer',
      type: NxtFieldType.Text,
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'nxtUpdateId',
      name: 'nxtUpdateId',
      type: NxtFieldType.Text,
    }, {
      field: 'bodyPuts',
      name: 'Tattoo - Körperstelle',
      valueGetter: (value) => this.bodyPutService.getTextFromEventObj(value).join('\n'),
      type: NxtFieldType.Text,
    }, {
      field: 'info',
      name: 'Termin - Info',
      type: NxtFieldType.Text,
    }, {
      field: 'stencilSeen',
      name: 'Stencil gesehen',
      type: NxtFieldType.Text,
      hideOldValueOnUpdate: true,
      valueGetter: (value) => {
        return value.seenAt.dateFormat('dd.MM.yyyy HH:mm') + ' ' + value.seenBy;
      },
    }, {
      field: 'depotDueDate',
      name: 'Preis - Kaution kommt am',
      type: NxtFieldType.Date_germanDate,
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'depotDueDateReason',
      name: 'Preis - Kaution kommt weil',
      type: NxtFieldType.Text,
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'artistAssignedAt',
      name: 'Artist zugewiesen',
      type: NxtFieldType.Date_germanDate,
    }, {
      field: 'canceledApproved',
      name: 'Kunde - Absage - Nachweis erbracht',
      type: NxtFieldType.Boolean,
      deleteInCompareData: params => !params.data.canceledAt,
    }, {
      name: 'Kunde - Absage - Früh genug abgesagt',
      field: 'canceledInTime',
      type: NxtFieldType.Boolean,
      deleteInCompareData: (params) => {
        return params.data.status !== 'canceled';
      },
    }, {
      field: 'canceledReason',
      name: 'Absage - Grund',
      type: NxtFieldType.Text,
      deleteInCompareData: (params) => {
        return params.data.status !== 'canceled';
      },
    }, {
      field: 'shouldDepositBack',
      name: 'Offene Rückzahlung',
      type: NxtFieldType.Boolean,
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'shouldDepositBackValue',
      name: 'Offene Rückzahlung Betrag',
      type: NxtFieldType.Money,
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'artistFixReason',
      name: 'Artist - Fix Grund',
      type: NxtFieldType.Text,
      valueGetter: (value) => EventTools.getFixArtistReasonText(value),
      deleteInCompareData: (params) => !params.value,
      /*hideOnAdd: (value, diff, data) => {
        return !data.artistFix;
      }*/
    }, {
      field: 'discountPromotion',
      name: 'Preis - Rabatt',
      type: NxtFieldType.Text,
      valueGetter: (value) => {
        if (value?.name) {
          return value.name;
        }
        return '';
      },
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'fastWalkIn',
      name: 'Walk-In',
      type: NxtFieldType.Boolean,
    }, {
      field: 'createdByWorkplace',
      name: 'Ersteller - Arbeitsplatz',
      type: NxtFieldType.Text,
    }, {
      field: 'fastWalkInNo',
      name: 'Walk-In-Nr',
      type: NxtFieldType.Text,
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'importantInfo',
      name: 'Wichtige Info',
      type: NxtFieldType.Text,
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'improveArtistShouldGetMoney',
      name: 'Nachstechen Artist bekommt Geld',
      type: NxtFieldType.Boolean,
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'noDepotNecessaryReason',
      name: 'Kaution - Keine Kaution nötig weil',
      type: NxtFieldType.Text,
      deleteInCompareData: (params) => !params.value,
    },
    {
      field: 'priceEstimatedTill',
      name: 'Preis - Preis bis',
      type: NxtFieldType.Money,
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'priceFix',
      name: 'Preis - Fix-Preis',
      type: NxtFieldType.Boolean,
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'promoOfferId',
      name: 'Promo-Code',
      type: NxtFieldType.Text,
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'shouldDepositBackCreatedAt',
      name: 'offene Rückzahlung erstellt am',
      type: NxtFieldType.Date_germanDateTime,
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'skill',
      name: 'Tattoo - Stil',
      type: NxtFieldType.Text,
      // valueGetter: (params) => JsonTools.parse(params),
      valueGetter: (value) => {

        return (value?.boolSkills ? Object.keys(value.boolSkills).join(', ') : '') + (value?.skills ? Object.keys(value.skills).join(', ') : '');
      },
      // deleteInCompareData: (params) => !params.value
    }, {
      field: 'durationPriceInfo',
      name: 'Preis - Warum passt der Preis zur Dauer nicht?',
      type: NxtFieldType.Text,
      deleteInCompareData: (params) => !params.value,
    }, {
      field: 'start',
      name: 'Termin - Start',
      type: NxtFieldType.Date_germanDateTime,
    }, {
      field: 'end',
      name: 'Termin - Ende',
      type: NxtFieldType.Date_germanDateTime,
    }, {
      field: 'artistFix',
      name: 'Artist - Fix',
      type: NxtFieldType.Boolean,
      // hideOnAdd: (value) => !value
    }, {
      field: 'title',
      name: 'Titel',
      type: NxtFieldType.Text,
      hideOldValueOnUpdate: true,
    }, {
      field: 'artistPercentage',
      name: 'Artist - Prozente',
      type: NxtFieldType.Percentage,
    }, {
      field: 'mediaCount',
      name: 'Datei-Anhänge',
      valueGetter: (value) => (value?.photo || 0) + ' Fotos, ' + (value?.video || 0) + ' Videos, ' + (value?.pdf || 0) + ' PDFs',
      hideOnAdd: (value, nxtDiff) => {
        return !nxtDiff.data.mediaCount || ((nxtDiff.data.mediaCount?.photo || 0) === 0 && (nxtDiff.data.mediaCount?.video || 0) === 0 && (nxtDiff.data.mediaCount?.pdf || 0) === 0);
      },
      type: NxtFieldType.Text,
    }, {
      field: 'valence',
      name: 'Wertigkeit',
      type: NxtFieldType.Number,
      valueGetter: (value) => MathTools.round(value, 2),
    }, {
      field: 'priceEstimatedFrom',
      name: 'Preis von',
      type: NxtFieldType.Money,
      valueGetter: (value) => parseFloat(value),
    }, {
      field: 'priceChanges',
      name: 'Preis - Preisänderungen',
      valueGetter: (value) => value.map(p => p.u + ': ' + p.from.toMoneyString() + ' ' + StringTools.arrowRight + ' ' + p.to.toMoneyString() + ' ' + p.i).join('<br/>'),
      type: NxtFieldType.Text,
      hideOnAdd: (value) => value.length === 0,
    }, {
      field: 'customer',
      name: 'Kunde',
      valueGetter: (value) => this.getCustomer(value),
      type: NxtFieldType.Text,
    }, {
      field: 'customerObj',
      name: 'customerObj',
      valueGetter: (value) => {
        return JsonTools.stringify(value);
      },
      type: NxtFieldType.Text,
    }, {
      field: 'payments',
      name: 'Zahlung',
      type: NxtFieldType.Text,
      valueGetter: (value) => {
        return JsonTools.stringify(value);
      },
      textGetter: (params) => {
        const payments = JsonTools.parse(params.diff.val);
        return this.getPaymentsDiff(params);
      },
      hideOnAddOrUpdate: (value, nxtDiff) => {
        return !nxtDiff.text;
      },
    }, {
      field: 'status',
      name: 'Status',
      valueGetter: (value) => value === 'canceled' ? 'Abgesagt' : (value === 'closed' ? 'Geschlossen' : 'Offen'),
      type: NxtFieldType.Text,
    }, {
      field: 'followUp',
      name: 'FollowUp',
      type: NxtFieldType.Text,
      valueGetter: (value) => JsonTools.stringify(value),
    }, {
      field: 'qrCodeScanStencil',
      name: 'Qr-Code Scan - Stencil',
      type: NxtFieldType.Date_germanDateTime,
    }, {
      field: 'qrCodeScanTattoo',
      name: 'Qr-Code Scan - Tattoo',
      type: NxtFieldType.Date_germanDateTime,
    }, {
      field: 'disableSkillCheck',
      name: 'Skill-Check deaktiviert',
      type: NxtFieldType.Boolean,
    }, {
      field: 'problems',
      name: 'Probleme am Tag des Termins',
      type: NxtFieldType.Text,
    },
  ];
  public hiddenProps = [
    'updated', 'updatedDate', 'artistPaymentSum', 'artistToGet', 'toPayToStudio', 'endPlanned', 'endString',
    'endTime', 'sequence', 'startString', 'updatedAt', 'startTime', 'paymentSum', 'toPay', 'calendarId', 'calendarNumber',
    'createdDate', 'createdDateString', 'createdWeekDay', 'duration', 'endDate', 'followUp', 'id', 'canceledAt', 'created',
    'description', 'fastWalkInNoAdd', 'customerObj', 'lockdownInfo', 'paymentCheaperSum', 'paymentDepositBack', 'closed',
    'paymentSumAdditional', 'photoFolderId', 'startDate', 'startDateString', 'studio', 'updatedBy', 'artistTotalGet',
    'cashEndOfDay', 'createdBy', 'createdByStudio', 'fastWalkInRegisteredAt', 'calendarName', 'mediaCount', 'pdf1Count',
    'promoOfferCreatedAt', 'promoOfferPromoterName', 'updatedByStudio', 'updatedByWorkplace', 'seqId', 'ratings', 'ratingValue',
    'qrScans', 'shouldCheck', 'createdAt', 'artistFixCustomer'
  ];

  // public hiddenProps = [];

  debugProps = ['googleId', 'nextNxtUpdateId', 'nxtUpdateId', 'title', 'followUp', 'mediaCount', 'pdf1Count', 'files'];
  // artistCalendars: any[];
  public showTitle = false;
  public showDebug = false;
  public eventId = '75a2m8cdoc7s5g5g0vif98eoc4';


  filterRawItem(data) {
    return data.user !== 'Media-Count-Fix' && data.user !== 'PDF-Scan';
  }


  setData(eventId: string) {
    this.eventId = eventId;
  }

  ngOnInit() {
    this.propDefs = this.propDefs.sort(SortTools.sortString('name'));
  }


  nxtOnDestroy() {
  }

  close() {
    this.dialogRef.close();
  }

  private async getCustomer(value: any) {
    return (await this.socketService.getContactById(value))?.fullName;
  }

  private getPaymentsDiff(params: { data: any; diff: rdiff.rdiffResult; value: any }) {
    let oldVal: NxtPayment[] = [];
    const newVal: NxtPayment[] = JsonTools.parse(params.diff.val);
    if (params.diff.op === 'update') {
      oldVal = JsonTools.parse(params.diff.oldVal);
    }
    const addedPayments = newVal.filter(n => !oldVal.some(o => o.paymentUuid === n.paymentUuid));
    const removedPayments = oldVal.filter(n => !newVal.some(o => o.paymentUuid === n.paymentUuid));
    const lines: string[] = [];
    for (const newPayment of addedPayments) {
      lines.push('<span>Neue Zahlung: ' + PaymentTools.paymentToTextOneLine(newPayment, false) + '</span>');
    }
    for (const removedPayment of removedPayments) {
      lines.push('<span class="red">Zahlung gelöscht: ' + PaymentTools.paymentToTextOneLine(removedPayment, false) + '</span>');
    }
    if (lines.length === 0) {
      return '';
    }
    if (lines.length === 1) {
      return lines[0];
    }
    return '<br/>&nbsp;&nbsp;&nbsp;' + lines.join('<br/>&nbsp;&nbsp;&nbsp;');
  }
}
