import {Component, Input, OnInit} from '@angular/core';
import {ElectronService} from '../../../../services/electron.service';
import {NxtConfig} from '../../../../common-interfaces/nxt.config.interface';
import {SocketService} from '../../../../services/socket/socket.service';
import {DialogService, LoadingId} from '../../../../services/dialog.service';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {NxtButtonIconComponent} from '../../../../controls/button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {InputComponent} from '../../../form-controls/input/input.component';
import {NgIf} from '@angular/common';

@Component({
  selector: 'nxt-settings-bank-import',
  templateUrl: './settings.bank-import.component.html',
  styleUrls: ['./settings.bank-import.component.scss'],
  imports: [NgIf, InputComponent, FlexModule, NxtButtonIconComponent, NxtButtonComponent],
  standalone: true,
})
export class SettingsBankImportComponent implements OnInit {

  @Input() data: { timer: number, config: NxtConfig.BankImport };

  constructor(
    private electronService: ElectronService,
    private socketService: SocketService,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit() {

  }

  public openFintsSite() {
    this.electronService.openExternal('https://docs.google.com/spreadsheets/d/1ohqm3EbAJY-BqMHN74wFmB6EilzxG7U90SDVZ4wczYg/edit#gid=0');
  }

  public async testBankImport() {
    this.dialogService.showLoading(LoadingId.ImportTest, 'Bank-Import Test läuft...');
    try {
      const result = await this.socketService.bankImportTest(this.data.config);
      if (result) {
        const currentMoney = result.balances.map(b => b.Amt.toMoneyString()).join(', ');
        this.dialogService.hideLoading(LoadingId.ImportTest);
        this.dialogService.showOk('Bank-Import war erfolgreich\n' + currentMoney);
      }
    } catch (err) {
      this.dialogService.hideLoading(LoadingId.ImportTest);
      // this.dialogService.showOk('Bank-Import Test fehlgeschlagen!\n' + err.message);
    }
  }
}
