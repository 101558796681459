import {Injectable} from '@angular/core';
import {IframeMessageManagerInIframe} from './iframe-message-manager-in-iframe';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor() {
  }

  public setTitle(title) {
    if (window.location.origin.includes('https://test.nxt-lvl.ink')) {
      this.setDocumentTitle('TEST - ' + title);
    } else if (window.location.origin.includes('https://ffm.nxt-lvl.ink')) {
      this.setDocumentTitle('FFM - ' + title);
    } else if (window.location.origin.includes('https://ac.nxt-lvl.ink')) {
      this.setDocumentTitle('AC - ' + title);
    } else if (window.location.origin.includes('https://ab.nxt-lvl.ink')) {
      this.setDocumentTitle('AB - ' + title);
    } else {
      this.setDocumentTitle('LOCAL - ' + title);
    }
  }

  private setDocumentTitle(title) {
    window.document.title = title.toUpperCase();
    IframeMessageManagerInIframe.instance.send('eval', 'window.document.title = \'' + title.toUpperCase() + '\'');
  }
}
