import {Component, Optional} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NxtFormControl} from '../../nxt-form/nxt.form-control';
import {SocketService} from '../../services/socket/socket.service';
import {NxtInvoiceForm} from '../../common-interfaces/nxt.invoice-form';
import {PdfLoaderComponent} from '../pdf-loader/pdf-loader.component';
import {DialogService} from '../../services/dialog.service';
import {FormTools} from '../../services/form.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {DatePickerComponent} from '../form-controls/date-picker/date-picker.component';
import {InputComponent} from '../form-controls/input/input.component';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NgFor, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {ValidatorTools} from '../../helpers/validator.tools';

@Component({
    selector: 'nxt-invoice-form',
    templateUrl: './invoice-form.component.html',
    styleUrls: ['./invoice-form.component.scss'],
    imports: [FlexModule, NgIf, NxtButtonIconComponent, InputComponent, DatePickerComponent, MatRadioGroup, FormsModule, ReactiveFormsModule, MatRadioButton, NxtButtonComponent, NgFor]
})
export class InvoiceFormComponent {

  form: UntypedFormGroup;
  positions: UntypedFormGroup[] = [];


  constructor(
    @Optional() public dialogRef: MatDialogRef<InvoiceFormComponent>,
    private formBuilder: UntypedFormBuilder,
    private socketService: SocketService,
    private dialogService: DialogService
  ) {
    this.form = this.formBuilder.group({
      invoiceNumber: new NxtFormControl(null, [ValidatorTools.requiredAndNotNaN], 'Rechnungsnummer'),
      invoiceDate: new NxtFormControl(null, [ValidatorTools.requiredAndNotNaN], 'Rechnungsdatum'),
      recipientSalutation: new NxtFormControl(null, [ValidatorTools.requiredAndNotNaN], 'Anrede'),
      recipientName: new NxtFormControl(null, [ValidatorTools.requiredAndNotNaN], 'Name'),
      recipientName2: new NxtFormControl(null, [], 'Name zweite Zeile'),
      recipientStreet: new NxtFormControl(null, [ValidatorTools.requiredAndNotNaN], 'Straße'),
      recipientPostalCode: new NxtFormControl(null, [ValidatorTools.requiredAndNotNaN], 'PLZ'),
      recipientCity: new NxtFormControl(null, [ValidatorTools.requiredAndNotNaN], 'Ort'),
      customerNumber: new NxtFormControl(null, [ValidatorTools.requiredAndNotNaN], 'Kundennummer'),
    });

    // this.addPos();
  }


  addPos(name = '', amount = 1, unitPrice?: number) {
    this.positions.push(this.formBuilder.group({
      name: new NxtFormControl(name, [ValidatorTools.requiredAndNotNaN], 'Position-Name'),
      amount: new NxtFormControl(amount, [ValidatorTools.requiredAndNotNaN], 'Menge'),
      unitPrice: new NxtFormControl(unitPrice, [ValidatorTools.requiredAndNotNaN], 'Einzelpreis')
    }));
  }

  async generateInvoice() {
    const errors: string [] = [];
    if (this.positions.length === 0) {
      errors.push('Mindestens eine Position');
    }
    this.positions.forEach(p => errors.push(...FormTools.getErrors(p)));
    if (!FormTools.showErrorsTrueIfNoError(this.form, this.dialogService, errors)) {
      return;
    }
    const data = this.form.getRawValue();
    const invoiceForm: NxtInvoiceForm = {
      invoiceTitle: 'Rechnung',
      invoiceDate: data.invoiceDate,
      invoiceNumber: data.invoiceNumber,
      positions: this.positions.map((p, i) => ({...p.getRawValue(), posNumber: i + 1})),
      to: {
        name: data.recipientName,
        name2: data.recipientName2,
        salutation: data.recipientSalutation,
        city: data.recipientCity,
        countryCode: 'DE',
        customerNumber: data.customerNumber,
        street: data.recipientStreet,
        postalCode: data.recipientPostalCode,
      }
    };
    const dialog = this.dialogService.showComponentDialog(PdfLoaderComponent);
    dialog.componentInstance.getInvoiceFromInvoiceForm(invoiceForm).then();
  }

  removePos(index: number) {
    this.positions = this.positions.filter((p, i) => index !== i);

  }

  close() {
    this.dialogRef.close();
  }
}
