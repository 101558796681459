<div class="nxt-page w-full h-100 p-0 wrapper">
  <div fxFlex class="content">
    <div class="logo-wrapper">
      <img style="width: 100%" src="/src/assets/logo/studio/logo_ac_white_old.svg"/>
    </div>
    <div fxLayout="column" style="font-size: 40px; padding-top: 50px;">
      <div fxLayout="row" fxLayoutAlign="center center" style="background-color: #3c3b3f77; border-radius: 5px; padding: 12px 12px">
        <div class="img-wrapper">
          <img style="height: 50px" src="/assets/icons/whatsapp.png">
        </div>
        <div style="color: #fff; padding: 8px"><a href="https://wa.me/4917645663664" target="_blank">AACHEN</a></div>
      </div>
    </div>
    <br/>

    <br/>
  </div>
  <div class="pb-2 footer">{{footerText}}</div>
</div>
