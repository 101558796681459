import {ChangeDetectionStrategy, Component, computed, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtBankConfigAccountLive} from '../../../common-interfaces/bank/bank-transaction.interface';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {InputComponent} from '../../form-controls/input/input.component';
import {FormGroup, Validators} from '@angular/forms';
import {NxtTypedFormControl} from '../../../nxt-form/nxt.typed-form-control';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {DialogService} from '../../../services/dialog.service';
import {SelectComponent} from '../../form-controls/select/select.component';
import {LoginService} from '../../../services/login.service';
import {FormTools} from '../../../services/form.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {MathTools} from '../../../common-browser/helpers/math.tools';
import {BehaviorSubject, first} from 'rxjs';

export interface CreateBankTransferDialogData {
  ownCompany?: string;
  accountId?: string;
  receiverName?: string;
  receiverIban?: string;
  value?: number;
  main?: boolean;
  text?: string;
}

@Component({
  selector: 'nxt-bank-create-transfer',
  templateUrl: './bank-create-transfer.component.html',
  styleUrls: ['./bank-create-transfer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MoneyPipe,
    InputComponent,
    NxtButtonComponent,
    SelectComponent,

  ],
})

export class BankCreateTransferComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  account = computed(() => {
    this.accountId();
    return this.accounts().find(a => a.id === this.accountId());
  });
  accounts = signal<NxtBankConfigAccountLive[]>([]);
  accountId = signal('');
  ownCompany = signal('');
  ownCompanies = signal([
    {text: 'GBX GmbH', value: 'GBX GmbH'},
    {text: 'Krafft & Welsch', value: 'Krafft & Welsch GmbH & Co. KG'},
    {text: 'Krafft & Welsch ES', value: 'Krafft & Welsch GmbH & Co KG Sucursal Española'},
  ]);
  accountOptions = computed(() => {
    this.ownCompany();
    return this.accounts().filter(a => a.company === this.ownCompany()).map(a => {
      return {value: a.id, text: a.name};
    });
  });
  users = signal([
    {value: 'Julian', text: 'Julian'},
    {value: 'Niklas', text: 'Niklas'},
    {value: 'Marcel', text: 'Marcel'},
  ]);
  /*** Computes ***/

  /*** Injections ***/
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  private dialogRef = inject(MatDialogRef);
  private loginService = inject(LoginService);

  /*** Injections ***/


  /*** Variable ***/
  form = new FormGroup({
    name: new NxtTypedFormControl('', [Validators.required], 'Empfänger'),
    iban: new NxtTypedFormControl('', [ValidatorTools.iban], 'IBAN'),
    text: new NxtTypedFormControl('', [Validators.required], 'Verwendungszweck'),
    value: new NxtTypedFormControl<number | null>(null, [Validators.required], 'Betrag'),
    user: new NxtTypedFormControl<string>(this.users().find(u => u.text === this.loginService.getUsername())?.value, [Validators.required], 'Push-Tan-User'),
  });
  loaded = new BehaviorSubject(false);

  constructor() {
    super();

  }

  async ngOnInit() {
    this.accounts.set(await this.socketService.bank.getBankAccount(['spk', 'qonto']));
    console.log(this.accounts());
    this.loaded.next(true);
  }

  nxtOnDestroy() {
  }

  async sendClicked() {
    if (FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      this.dialogService.showLoading('Überweisung wird durchgeführt...\nCheck deine Push-Tan-App!');
      const result = await this.socketService.bank.createTransfer(this.account().id, this.form.value.iban, this.form.value.name, this.form.value.text, this.form.value.value, this.form.value.user);
      this.dialogService.hideLoading();
      await this.dialogService.showOk(result);
      this.dialogRef.close(true);
    }
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  setTestDataClicked() {
    this.form.controls.value.setValue(MathTools.random(50, 150) / 100);
    this.form.controls.name.setValue('GBX GmbH');
    this.form.controls.iban.setValue('DE90390500001077270682');
    this.form.controls.text.setValue('Übertrag');
  }

  async setData(data: CreateBankTransferDialogData) {
    await this.waitLoad();
    if (data.accountId) {
      this.accountId.set(data.accountId);
    }
    if (data.main) {
      this.accountId.set(this.accounts().find(a => a.main)?.id);
    }
    if (data.value) {
      this.form.controls.value.setValue(data.value);
    }
    if (data.ownCompany) {
      this.ownCompany.set(data.ownCompany);
    }
    if (data.receiverName) {
      this.form.controls.name.setValue(data.receiverName);
    }
    if (data.receiverIban) {
      this.form.controls.iban.setValue(data.receiverIban);
    }
    if (data.text) {
      this.form.controls.text.setValue(data.text);
    }
  }

  private async waitLoad() {
    return new Promise<void>((resolve, reject) => {
      if (this.loaded.value) {
        resolve();
      } else {
        this.loaded.pipe(first(loaded => loaded)).subscribe(() => resolve());
      }
    });
  }
}
