import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, input, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';

@Component({
  selector: 'nxt-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})

export class BoxComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  headerText = input('');

  bg = input<'normal' | 'dark'>('normal');

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

}
