<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>Klarna Beanstandungen</nxt-page-header-title>

  </nxt-page-header>
  <nxt-page-content>
    <nxt-datagrid
      [columnDefs]="columnDefs"
      [rowData]="klarnaDisputes()"
    />
  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>
