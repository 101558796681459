<div class="nxt-dialog-content">
  <div>
  <!--  value: {{value}}
    <input *ngIf="value" style="width: 0px;border: none;padding: 0" value="focus">-->
    <mat-icon *ngIf="icon" aria-hidden="false">{{icon}}</mat-icon>
    <div *ngIf="titleText" #header class="titleText">{{titleText}}</div>
  </div>
  <div style="margin-bottom:24px; margin-top:10px;" [innerHTML]="message | safeHtml">
  </div>

  <div>
    <nxt-autocomplete
      [options]="data.items"
      [displayWith]="displayFn"
      [displayInOptionWith]="displayInOptionFn"
      (optionClick)="optionClicked($event)"
      (clearClicked)="value = null"
      [showClearIcon]="true"
      [placeholder]="data.placeholder"
      [maxOptionsToShow]="999"
      [value]="preselectValue"
      (valueChange)="valueChanged($event)"
      [filterFields]="['text']"
      [openSelectOnInit]="true"
      [useFuseSearch]="false"
    >
    </nxt-autocomplete>
  </div>


  <div style="justify-content: space-around; display:flex; padding: 1px;">
    <nxt-button style="margin: auto;" (click)="cancel()">Abbrechen</nxt-button>
    <nxt-button #okButton *ngIf="value && data.okButtonText" style="margin: auto;" (click)="close()">{{data.okButtonText}}</nxt-button>
  </div>
</div>
