import {Component, input, Input, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {NgIf} from '@angular/common';
import {TranslateDirective} from '../../../directives/translate.directive';

@Component({
    selector: 'nxt-declarations-of-consent-checkbox',
    template: `
    <div class="flex flex-row items-start py-1">
      <img *ngIf="checked" src="../../../assets/images/checked.svg" style="width: 16px" class="mr-1"/>
      <div *ngIf="!checked" class="checkbox mr-1 p-relative rounded-full">
      </div>
      <div class="flex flex-col">
        <div [nxtTranslate]="lang()" [innerHTML]="text | safeHtml"></div>
        <div style="color:#4d4d4d; font-size: 90%" *ngIf="textEn" [innerHTML]="textEn | safeHtml"></div>
      </div>
    </div>
  `,
    styles: [`
      .checkbox {
          min-width: 13px;
          min-height: 13px;
          border: 1px solid #000000;
      }
  `
    ],
    imports: [NgIf, SafeHtmlPipe, TranslateDirective]
})

export class DeclarationsOfConsentCheckboxComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  @Input() text: string;
  lang = input.required<string>();

  @Input() textEn: string;

  @Input() checked: boolean;

  constructor() {
    super();
  }

  ngOnInit() {

  }

  nxtOnDestroy() {

  }

}
