import {Component, OnInit, Optional} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {LoginService} from '../../services/login.service';
import {DialogService} from '../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NgIf} from '@angular/common';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {InputComponent} from '../form-controls/input/input.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {IconTools} from '../../common-browser/helpers/icon.tools';
import {DiscountPromotionEditComponent} from './discount-promotion-edit/discount-promotion-edit.component';
import {NxtDiscountPromotion} from '../../common-interfaces/discount-promotion.interface';
import {firstValueFrom} from 'rxjs';
import {NxtComponent, NxtOnDestroy} from '../nxt.component';

@Component({
  selector: 'nxt-discount-promotions-2',
  templateUrl: './discount-promotions-2.component.html',
  imports: [FlexModule, InputComponent, SlideToggleComponent, NgIf, NxtButtonIconComponent, NxtDatagridComponent, NxtButtonComponent],
})
export class DiscountPromotions2Component extends NxtComponent implements OnInit {

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
    private socketService: SocketService,
    private loginService: LoginService,
    private dialogService: DialogService,
  ) {
    super();
  }


  discountPromotions: NxtDiscountPromotion[];

  public form;
  public showDisabled = false;
  private loadTimestamp = 0;

  quickFilterText = '';
  rowData: NxtDiscountPromotion[] = [];
  columnDefs: NxtColDef<NxtDiscountPromotion>[] = [
    {headerName: '', nxtFieldType: NxtFieldType.Icon, nxtIcon: IconTools.Material.Edit, nxtOnCellClicked: (params) => this.editClicked(params.data)},
    {
      headerName: 'Aktiv',
      field: 'disabled',
      valueGetter: params => !params.data.disabled,
      nxtFieldType: NxtFieldType.Boolean,
      cellStyle: {textAlign: 'center'},
      headerClass: 'text-center',
    },
    {
      headerName: 'Name', field: 'name', nxtFieldType: NxtFieldType.Text, valueGetter: params => {
        if (params.data.ibPromotionId) {
          return 'InkBack: ' + params.data.name;
        }
        return params.data.name;
      }
    },
    {headerName: 'Rabatt', field: 'discountPercentage', nxtFieldType: NxtFieldType.Percentage},
    {
      headerName: 'Preis',
      nxtFieldType: NxtFieldType.Text,
      valueGetter: (params) => {
        if (params.data.priceFix) {
          return params.data.priceFixValue;
        } else if (params.data.priceLike) {
          return params.data.priceLike;
        }
      }, cellRenderer: (params: any) => {
        if (params.data.priceFix) {
          try {
            return 'fix ' + params.data.priceFixValue.toMoneyString('€', false);
          } catch (err) {
            return '????';
          }

        } else if (params.data.priceLike) {
          if (params.data.priceLike) {
            return 'ca. ' + params.data.priceFixValue.toMoneyString('€', false);
          }
          return '';
        }
      },
      cellStyle: {textAlign: 'right'},
      headerClass: 'text-center',
    },
    {
      headerName: 'Artist', field: 'artistGet', nxtFieldType: NxtFieldType.Money,
      cellRenderer: (params) => params.value ? (params.value + ' €') : '',
      headerClass: 'text-center',
    },
    {
      headerName: 'Nachkassieren', field: 'additionalPayAfterEnd',
      nxtFieldType: NxtFieldType.Boolean,
      cellStyle: {textAlign: 'center'},
      headerClass: 'text-center',
    },
    {
      headerName: 'Nur Promo-Code',
      field: 'onlyWithPromoCode',
      nxtFieldType: NxtFieldType.Boolean,
      cellStyle: {textAlign: 'center'},
      headerClass: 'text-center',
    },
    {
      headerName: 'Keine Rabatt-Gutscheine',
      field: 'disableDiscountedGiftCards',
      nxtFieldType: NxtFieldType.Boolean,
      cellStyle: {textAlign: 'center'},
      headerClass: 'text-center',
    },
  ];

  public ngOnInit() {
    this.pushSocketSubscription = this.socketService.subscribeNew('eventDiscountPromotionChanged', () => this.load());
    this.load();
  }

  async load() {
    this.discountPromotions = await this.socketService.getDiscountPromotions(false) as any;
    this.loadTimestamp = Date.now();
    if (!this.showDisabled) {
      this.discountPromotions = this.discountPromotions.filter(d => !d.disabled).sortString('name');
    }
  }

  private async editClicked(data: NxtDiscountPromotion) {
    if (data.ibPromotionId) {
      this.dialogService.showOk('InkBack Angebote kannst du hier nicht bearbeiten.').then();
      return;
    }
    const dialog = this.dialogService.showComponentDialog(DiscountPromotionEditComponent);
    dialog.componentInstance.load(data.id).then();
    if (await firstValueFrom(dialog.afterClosed())) {
      this.load().then();
    }
  }

  quickSearchTextChanged() {

  }

  newItem() {

  }

  async addNewClicked() {
    const dialog = this.dialogService.showComponentDialog(DiscountPromotionEditComponent);
    dialog.componentInstance.load('new');
    if (await firstValueFrom(dialog.afterClosed())) {
      this.load().then();
    }
  }
}
