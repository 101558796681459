import {NxtWorkSessionCashRegisterState} from '../../common-interfaces/nxt.work-session';

export class WorkSessionTools {


  static isCashRegisterStateBefore(stateToCheck: NxtWorkSessionCashRegisterState, state: NxtWorkSessionCashRegisterState) {
    const stateToCheckNumber = WorkSessionTools.getNumberFromCashRegisterState(stateToCheck);
    const stateNumber = WorkSessionTools.getNumberFromCashRegisterState(state);
    return stateToCheckNumber < stateNumber;
  }

  static getNumberFromCashRegisterState(state: NxtWorkSessionCashRegisterState) {
    for (const s in NxtWorkSessionCashRegisterState) {
      if (NxtWorkSessionCashRegisterState[s] === state) {
        return parseInt(s.split('_')[1], 10);
      }
    }
    return -1;
  }

  static getCashRegisterStateText(state: NxtWorkSessionCashRegisterState, withoutPrefix = false) {
    let result = WorkSessionTools.getCashRegisterStateTextDebug(state);
    if (withoutPrefix) {
      result = result.split('- ')[1];
    }
    return result;
  }

  static getCashRegisterStateTextDebug(state: NxtWorkSessionCashRegisterState) {
    switch (state) {
      case NxtWorkSessionCashRegisterState._0_Error:
        return '0 - Fehler!';
      case NxtWorkSessionCashRegisterState._1_Open:
        return '1 - Kasse offen';
      case NxtWorkSessionCashRegisterState._3_CalcCashRegister1:
        return '3 - Erste Kassenzählung';
      case NxtWorkSessionCashRegisterState._4_CalcCashRegister2:
        return '4 - Zweite Kassenzählung';
      case NxtWorkSessionCashRegisterState._5_CalcCashRegister3:
        return '5 - Dritte Kassenzählung weil Differenz zu groß';
      case NxtWorkSessionCashRegisterState._6_ChangeMoney:
        return '6 - Geld wechseln';
      case NxtWorkSessionCashRegisterState._7_ArtistPayoutCanBook:
        return '7 - Artist-Auszahlungen können gebucht werden';
      case NxtWorkSessionCashRegisterState._8_ArtistPayoutBooking:
        return '8 - Artist-Auszahlungen werden gebucht';
      case NxtWorkSessionCashRegisterState._9_CreateInvoices:
        return '9 - Umschläge werden gepackt (Rechnungen werden noch erstellt)';
      case NxtWorkSessionCashRegisterState._10_PreparingPayouts:
        return '10 - Umschläge werden gepackt';
      case NxtWorkSessionCashRegisterState._11_SetSafeMoneyDestination:
        return '11 - Tresor-Geld abgeben';
      case NxtWorkSessionCashRegisterState._12_InvoicesPrinting:
        return '12 - Rechnungen drucken';
      case NxtWorkSessionCashRegisterState._13_PayoutsRunning:
        return '13 - Artists bekommen ihre Moneten';
      case NxtWorkSessionCashRegisterState._14_ClosingWorkSession:
        return '14 - Vor dem Feierabend';
      case NxtWorkSessionCashRegisterState._30_SideCashRegisterPrintDayFinishReport:
        return '30 - Kassenbericht drucken';
      case NxtWorkSessionCashRegisterState._29_SideCashRegisterBookTransfers:
        return '29 - Transfer zur Hauptkasse';
      case NxtWorkSessionCashRegisterState._99_Closed:
        return '99 - Kasse geschlossen';
      default:
        debugger;
        return 'X - Status fehlt: ' + state;
    }
  }
}
