import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {SocketService} from './services/socket/socket.service';
import {Log} from './common-browser/log/log.tools';
import {PermissionService} from './services/permission.service';
import {DialogService} from './services/dialog.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard {


  public static firstCalledUrl;

  constructor(
    private router: Router,
    private socketService: SocketService,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private dialogService: DialogService
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // return true;
    console.log(route.routeConfig.path);
    console.log(route.routeConfig.path);
    console.log(route.routeConfig.path);

    if (window.location.hostname === 'paypal.nxt-lvl.ink') {
      window.location.href = 'https://nxt-lvl.ink';
    }

    if (!AuthGuard.firstCalledUrl) {
      AuthGuard.firstCalledUrl = state.url;
    }

    if (this.socketService.state.getValue().authenticated) {
      await this.permissionService.waitForPermissions();
      if (route.data.permission && !this.permissionService.hasPermission(route.data.permission)) {
        debugger;
        this.dialogService.showOk('Du hast keine Berechtigung für diese Seite\n' + route.routeConfig.path);
        return this.router.navigate(['/login']);
      }
      Log.info('AuthGuard -> true');
      return true;
    } else {
      Log.info('AuthGuard -> false | returnUrl = ' + state.url);
      return this.router.navigate(['/login'], {queryParams: this.getQueryParams(state), skipLocationChange: true});
      // return this.router.parseUrl('/');
    }
  }

  getQueryParams(state) {
    const queryParams: any = {returnUrl: state.url};

    const username = this.route.snapshot.queryParams.username;
    const password = this.route.snapshot.queryParams.password;
    const studio = this.route.snapshot.queryParams.studio;
    if (username && password && studio) {
      queryParams.username = username;
      queryParams.password = password;
      queryParams.studio = studio;
    }
    return queryParams;
  }
}
