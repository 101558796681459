<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>WhatsApp Chats prüfen - {{ chatsView().length }}</nxt-page-header-title>
    <nxt-input placeholder="Suche" [(value)]="quickFilterText"></nxt-input>
  </nxt-page-header>
  <nxt-page-content>
    <nxt-datagrid
      [columnDefs]="columnDefs()"
      (rowDoubleClickNew)="rowDoubleClicked($event.data)"
      [rowData]="chatsView()"
      [quickFilterText]="quickFilterText()"
    />
  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>


