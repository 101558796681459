import {Component, Injector, OnDestroy, OnInit, Optional, runInInjectionContext, ViewChild} from '@angular/core';
import {FormsModule, UntypedFormGroup} from '@angular/forms';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtInventoryStockItem} from '../../../common-interfaces/nxt.inventory-item.interface';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {IconTools} from '../../../common-browser/helpers/icon.tools';
import {InventoryItemFormComponent} from '../inventory-item-form/inventory-item-form.component';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {StringTools} from '../../../common-browser/helpers/string.tools';
import {SocketService} from '../../../services/socket/socket.service';
import {DialogService, LoadingId} from '../../../services/dialog.service';
import {InventoryService} from '../../../services/inventory.service';
import {LoginService} from '../../../services/login.service';
import {MatDialogRef} from '@angular/material/dialog';
import {EventComponent} from '../../../event/event.component';
import {ManualDataOrderService} from '../../../services/manual-data-order.service';
import {ElectronService} from '../../../services/electron.service';
import {ConfigService} from '../../../services/config.service';
import {ObjectTools} from '../../../common-browser/helpers/object.tools';
import {TimeTools} from '../../../common-browser/helpers/time.tools';
import {InventoryItemHistoryComponent} from '../../history/inventory-item-history/inventory-item-history.component';
import {JsonTools} from '../../../common-browser/helpers/json.tools';
import {InventoryOrderCreateComponent} from './inventory-order-create/inventory-order-create.component';
import {InventoryOrdersComponent} from './inventory-orders/inventory-orders.component';
import {ArrayTools} from '../../../common-browser/helpers/array.tools';
import {PermissionService} from '../../../services/permission.service';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {InventoryHistoryItemsComponent} from '../inventory-history-items/inventory-history-items.component';
import {Clipboard} from '@angular/cdk/clipboard';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {NxtCardDividerDirective} from '../../../controls/nxt/card-divider/nxt-card-divider.directive';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {InputComponent} from '../../form-controls/input/input.component';
import {NgFor, NgIf} from '@angular/common';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {Go2Item, InventorySpecialButtonService} from '../../../services/inventory-special-button.service';


@Component({
  selector: 'nxt-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss'],
  imports: [FlexModule, MatRadioGroup, FormsModule, NgFor, MatRadioButton, InputComponent, SlideToggleComponent, NgIf, NxtButtonComponent, NxtDatagridComponent, NxtCardDividerDirective, NxtButtonIconComponent]
})
export class InventoryListComponent implements OnInit, OnDestroy {

  @ViewChild(NxtDatagridComponent) dataGrid: NxtDatagridComponent;
  inventoryData: { [studio: string]: NxtInventoryStockItem[] } = {};
  inventoryDataFiltered: { [studio: string]: NxtInventoryStockItem[] } = {};
  inventoryFilter: { [studio: string]: string } = {};
  inventoryFilterItems: { text: string, value: string }[];
  quickFilterText = this.permissionService.isJulian() ? 'Julian' : '';
  inventoryDataSubscriptions: any = {};
  editAmountOld: number;
  studios = this.configService.config.value.studios.filter(s => !s.onlyCash && !['Alsdorf', 'Brand'].includes(s.name)).map(s => s.name);
  studio = this.studios[0];
  showEmptyItems = false;
  showEmptyItemsAc = false;

  form: UntypedFormGroup;
  public inventoryStockColumnDef: NxtColDef<NxtInventoryStockItem>[] = [
    {headerName: 'Id', field: 'id', nxtFieldType: NxtFieldType.Text, hide: true, sortable: false},
    {rowDrag: true, maxWidth: 50, minWidth: 50, cellStyle: {textAlign: 'center'}, cellClass: 'single-drag-row'},
    {
      headerName: '', cellRenderer: () => IconTools.Material.Edit,
      nxtOnCellClicked: (params) => {
        this.dialogService.showComponentDialog(InventoryItemFormComponent).componentInstance.loadFromId(params.data.id);
      },
      maxWidth: 45,
      minWidth: 45,
      cellStyle: {textAlign: 'center'},
    },

    {
      headerName: '',
      nxtOnCellClicked: (event) => this.showHistory(event.data.id, event.data.studio),
      cellRenderer: () => IconTools.Material.History,
      sortable: false,
      cellStyle: {textAlign: 'center'},
      maxWidth: 45,
      minWidth: 45,
      hide: !this.loginService.isBackoffice(),
    }, {
      colId: 'createOrder',
      headerName: '',
      cellRenderer: () => IconTools.Material.ShoppingCart,
      tooltipValueGetter: () => 'Bestellung erstellen',
      cellStyle: {textAlign: 'center'},
      maxWidth: 45,
      minWidth: 45,
      nxtOnCellClicked: (params) => {
        this.createNewOrder(params.data);
      },
      hide: false,
    }, {
      headerName: 'Bezeichnung', field: 'name', cellRenderer: (params: any) => {
        return params.data.name + ' (' + params.data.amountPerPackage + ' ' + params.data.unitOfMeasure + ')';
      },
      nxtFieldType: NxtFieldType.Text,
      sortable: true,
      nxtOnCellDoubleClicked: (params) => this.dialogService.showComponentDialog(InventoryItemFormComponent).componentInstance.loadFromId(params.data.id),
      width: 350,
      suppressAutoSize: true,
    },
    {
      headerName: 'order',
      field: 'orderValue',
      nxtFieldType: NxtFieldType.Text,
      sortable: false,
      hide: !this.loginService.isJulian(),
    },
    {headerName: 'Zuständig', field: 'responsibility', nxtFieldType: NxtFieldType.Text, sortable: false},
    {headerName: 'Vertr.', field: 'responsibility2', nxtFieldType: NxtFieldType.Text, sortable: false, hide: true},
    // {headerName: 'Menge pro Einheit', field: 'amountPerPackage', nxtFieldType: NxtFieldType.Text},
    {
      headerName: 'Menge',
      field: 'amount',
      nxtFieldType: NxtFieldType.Number,
      cellStyle: (params) => ({textAlign: 'right'}),
      editable: true,
      singleClickEdit: true,
      sortable: false,
      maxWidth: 80,
      minWidth: 80,
      cellRenderer: (params: any) => {
        if (params.data.onlyFullOrEmpty) {
          return params.data.amount === 0 ? IconTools.Material.Warning : IconTools.Material.Check;
        } else {
          return params.value;
        }
      },
    },
    {
      headerName: '',
      cellRenderer: (params: any) => '-',
      cellStyle: (params) => ({textAlign: 'center'}),
      nxtFieldType: NxtFieldType.Text,
      nxtOnCellClicked: this.bookOutgoingClicked.bind(this),
      sortable: false,
      maxWidth: 50,
      minWidth: 50,
    },
    {
      headerName: '',
      cellRenderer: (params: any) => '+',
      cellStyle: (params) => ({textAlign: 'center'}),
      nxtFieldType: NxtFieldType.Text,
      nxtOnCellClicked: this.bookIncomingClicked.bind(this),
      sortable: false,
      maxWidth: 50,
      minWidth: 50,
    }, {
      headerName: '',
      nxtOnCellClicked: (event) => {
        const result = this.dialogService.showComponentDialog(InventoryHistoryItemsComponent, {}, {
          minHeight: '50vh',
          minWidth: '30vw',
        });
        result.componentInstance.setDialog(result);
        result.componentInstance.loadData(event.data.id, event.data.studio);
      },
      cellRenderer: () => IconTools.Material.History,
      sortable: false,
      cellStyle: {textAlign: 'center'},
      maxWidth: 50,
      minWidth: 50,
    },
    {
      headerName: 'Min',
      field: 'amountAlarm',
      nxtFieldType: NxtFieldType.Number,
      cellStyle: (params) => ({textAlign: 'right'}),
      editable: false,
      singleClickEdit: false,
      sortable: false,
      maxWidth: 80,
      minWidth: 80,
      hide: true,
    },
    {
      headerName: 'Max',
      field: 'amountFull',
      nxtFieldType: NxtFieldType.Number,
      cellStyle: (params) => ({textAlign: 'right'}),
      editable: false,
      singleClickEdit: false,
      sortable: false,
      maxWidth: 80,
      minWidth: 80,
      hide: true,
    },
    {
      headerName: 'über',
      valueGetter: (params) => params.data.amount - params.data.amountAlarm,
      nxtFieldType: NxtFieldType.Number,
      cellStyle: (params) => ({textAlign: 'right'}),
      editable: false,
      singleClickEdit: false,
      sortable: false,
      maxWidth: 80,
      minWidth: 80,
      hide: true,
    },
    {headerName: 'Bei Bezugsquelle', field: 'nameBySource', nxtFieldType: NxtFieldType.Text, width: 200, suppressAutoSize: true},
    {
      headerName: 'bestellen',
      valueGetter: (params) => {
        const result = params.data.amountFull - params.data.amount;
        if (result > 0 && params.data.amount <= params.data.amountAlarm) {
          return result;
        }
      },
      valueFormatter: (params) => {
        return params.value > 0 ? params.value : '';
      },
      nxtFieldType: NxtFieldType.Number,
      cellStyle: (params) => ({textAlign: 'right'}),
      editable: false,
      singleClickEdit: false,
      sortable: false,
      maxWidth: 80,
      minWidth: 80,
    },
    {
      headerName: 'Bezugsquelle 1',
      field: 'sourceOfSupply',
      colId: 'sourceOfSupply',
      valueFormatter: (params) => this.inventoryService.inventorySourcesOfSupply.find(i => i.value === params.data.sourceOfSupply)?.text,
      sortable: true,
      suppressAutoSize: true,
      width: 150,
      nxtOnCellDoubleClicked: (params) => {
        if (params.data.sourceOfSupplyUrl) {
          this.electronService.openExternal(params.data.sourceOfSupplyUrl);
        }
      },
      cellStyle: (params) => {
        return {color: !params.data.sourceOfSupplyUrl ? ColorTools.Red : ''};
      },
    },
    {
      headerName: 'Bezugsquelle 2',
      field: 'sourceOfSupply2',
      colId: 'sourceOfSupply2',
      suppressAutoSize: true,
      width: 150,
      valueFormatter: (params) => {
        return this.inventoryService.inventorySourcesOfSupply.find(i => i.value === params.data.sourceOfSupply2)?.text
          || params.data.sourceOfSupply2;
      },
      sortable: true,
      nxtOnCellDoubleClicked: (params) => {
        if (params.data.sourceOfSupply2Url) {
          this.electronService.openExternal(params.data.sourceOfSupply2Url);
        }
      },
      cellStyle: (params) => {
        return {color: !params.data.sourceOfSupplyUrl ? ColorTools.Red : ''};
      },
    },
    {
      headerName: 'letzte Aktual.',
      field: 'updatedAt',
      cellRenderer: (params: any) => {
        // const result = DateTools.format(params.data.updatedAt, 'dd.MM.yyyy HH:mm') + ' ' + DateTools.dateDiffToNowText(params.data.updatedAt);
        const result = DateTools.dateDiffToNowText(params.data.updatedAt);
        return result;
      },
      sortable: true,
      minWidth: 150,
      maxWidth: 150,
      cellStyle: (params) => {
        let color = ColorTools.Red;
        const diff = DateTools.dateDiffToNow(params.data.updatedAt);
        if (diff < DurationTools.DURATION_1DAY) {
          color = ColorTools.Green;
        } else if (diff < DurationTools.DURATION_1DAY * 2) {
          color = ColorTools.Orange;
        }
        return {color};
      },
    },
    {
      headerName: 'offene Bestellungen',
      cellRenderer: (params: any) => {
        const lastOrders = params.data.lastOrders?.filter(o => !o.arrived);
        if (lastOrders && lastOrders.length > 0) {
          return '<div>' + lastOrders.map(o => {
            let color = '';
            if (o.shouldArrivedAt) {
              if (o.shouldArrivedAt < Date.now()) {
                color = ColorTools.Red;
              }
            } else {
              if (Date.now() - o.createdAt > DurationTools.DURATION_1DAY * 4) {
                color = ColorTools.Red;
              }
            }
            let style = '';
            if (color) {
              style += 'color: ' + color + '";';
            }
            if (lastOrders.length > 1) {
              style += 'line-height: 1.3;';
            }
            return '<div style="' + style + '">' + o.createdAt.dateFormat('dd.MM') + ':&nbsp;&nbsp;&nbsp;' + o.orderAmount + ' x ' + params.data.amountPerPackage + ' ' + params.data.unitOfMeasure + '</div>';
          }).join('') + '</div>';
        }
      },
      nxtOnCellClicked: (params) => {
        this.showOrders(params.data);
      },
      autoHeight: true,
    },
    {
      headerName: 'letzte angekommene Best.',
      valueGetter: (params) => {
        const lastOrders = params.data.lastOrders?.filter(o => o.arrived).sortNumber('arrivedAt', true);
        if (lastOrders && lastOrders.length > 0) {
          return lastOrders[0].arrivedAt.dateFormat('yyyy-MM-dd');
        }
      },
      cellRenderer: (params: any) => {
        const lastOrders = params.data.lastOrders?.filter(o => o.arrived).sortNumber('arrivedAt', true);
        if (lastOrders && lastOrders.length > 0) {
          return '<div>' + lastOrders[0].createdAt.dateFormat('dd.MM') + ' ' + StringTools.arrowRight + ' ' + lastOrders[0].arrivedAt.dateFormat('dd.MM') + ':&nbsp;&nbsp;&nbsp;' + lastOrders[0].orderAmount + ' x ' + params.data.amountPerPackage + ' ' + params.data.unitOfMeasure + '</div>';
        }
      },
      nxtOnCellClicked: (params) => {
        this.showArrivedOrders(params.data);
      },
      autoHeight: true,
    },
  ];
  public printButtons: string[] = [];
  public specialButtons: string[] = [];
  showOrderTexts: string[] = [];

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    public inventoryService: InventoryService,
    public loginService: LoginService,
    @Optional() public dialogRef: MatDialogRef<EventComponent>,
    private manualDataOrderService: ManualDataOrderService,
    private electronService: ElectronService,
    private configService: ConfigService,
    private permissionService: PermissionService,
    private clipboard: Clipboard,
    private injector: Injector,
    private inventorySpecialButtonService: InventorySpecialButtonService,
  ) {

  }

  private bookOutgoingClicked(params) {
    if (params.data.amount > 0) {
      this.inventoryService.book(params.data.id, params.data.studio, -1);
    }
  }

  private bookIncomingClicked(params) {
    this.inventoryService.book(params.data.id, params.data.studio, +1);
  }


  async ngOnInit() {
    document.body.style.backgroundColor = 'transparent !important';

    setTimeout(() => {
      runInInjectionContext(this.injector, async () => {
        const temp: { [studio: string]: NxtInventoryStockItem[] } = {};
        for (const studio of this.studios) {
          this.inventoryData[studio] = []; // new BehaviorSubject<NxtInventoryStockItem[]>([]);
          if (this.inventoryDataSubscriptions && this.inventoryDataSubscriptions[studio]) {
            this.inventoryDataSubscriptions[studio].unsubscribe();
          }
          if (!this.inventoryDataSubscriptions[studio]) {
            this.inventoryDataSubscriptions[studio] = null;
          }

          this.inventoryDataSubscriptions[studio] = this.inventoryService.inventoryStockItems.subscribe(async (stockItems) => {
            // stockItems = stockItems.filter(i => i.name.includes('ulian'));
            this.inventoryData[studio] = ObjectTools.clone(stockItems.filter(stockItem => stockItem.studio === studio)).sortNumber('orderValue');
            this.inventoryFilterItems = this.inventoryService.inventoryLocations;
            if (this.inventoryFilterItems.length > 0) {
              if (!this.inventoryFilter[studio]) {
                this.inventoryFilter[studio] = this.inventoryFilterItems[0].value;
              }
              this.inventoryFilterChanged();
              await TimeTools.sleep(100);
              this.dataGrid.api.redrawRows();
            }
          });
        }
      });
    }, 200);
  }

  ngOnDestroy(): void {
    Object.keys(this.inventoryDataSubscriptions).forEach(key => {
      this.inventoryDataSubscriptions[key].unsubscribe();
    });
  }

  showItems() {
    // this.dialogService.showComponentDialog(InventoryItemsComponent, {}, {minHeight: '80vh', minWidth: '50vw'});
  }

  async close() {
    this.dialogRef.close();
  }

  private async showHistory(id: any, studio: string) {
    const result = this.dialogService.showComponentFull(InventoryItemHistoryComponent);
    result.componentInstance.id = id;
  }

  cellEditingStopped(event: any) {
    if (event.column.colId === 'amount') {
      const newValue = parseInt(event.value, 10);
      if (Number.isNaN(newValue)) {
        this.dialogService.showOk(event.data.amount + ' ist keine Zahl du Otto');
        event.data.amount = this.editAmountOld;
        event.node.setData(event.data);
      } else {
        const newAmount = newValue - this.editAmountOld;
        if (newAmount !== 0) {
          this.inventoryService.book(event.data.id, event.data.studio, newAmount);
        }
      }
    }
    event.value = 0;
    // console.log(event);
  }


  cellEditingStarted(event: any) {
    if (event.column.colId === 'amount') {
      this.editAmountOld = parseInt(event.data.amount, 10);
    }
  }

  async inventoryFilterChanged() {
    for (const studio of this.studios) {
      if (this.inventoryData[studio]) {
        this.inventoryDataFiltered[studio] = this.inventoryData[studio].filter(item => {
          if (typeof item.location === 'undefined') {
            item.location = 'undefined';
          }
          let result = item.location === this.inventoryFilter[studio];

          if (result) {
            if (this.showEmptyItems) {
              if (item.amountFull === 0 || item.amount - item.amountAlarm > 0) {
                result = false;
              }
              if (item.notOrderBefore && item.notOrderBefore.dateParse() > Date.now()) {
                result = false;
              }
            }
            if (this.showEmptyItemsAc && !item.orderedByAc) {
              return false;
            }
          }
          return result;
        });
        console.log(this.inventoryDataFiltered[studio].length);
      }
    }
    this.calcShowOrderTexts();
  }

  async rowDragEnd(params: any) {
    const item = this.inventoryService.inventoryItems.value.find(i => i.id === params.node.data.id);
    if (item.orderValue === params.newOrderValue) {
      this.dialogService.showLoading(LoadingId.InventorySort, 'Inventar wird strukturiert, bitte warten');
      await this.manualDataOrderService.resetOrderValueInCollection('inventory-items', 'orderValue');
      this.dialogService.hideLoading(LoadingId.InventorySort);
    } else {
      item.orderValue = params.newOrderValue;
      this.inventoryService.upsertInventoryItem(item, this.loginService.getUsername());
    }
  }

  async reorder() {
    const result = await this.dialogService.showYesNoCancel('Dies schmeißt die Sortierung weg! Achtung!', {
      yesText: 'A-Z',
      noText: 'Nur Sortierungszahlen neu vergeben',
    });
    if (typeof result === 'boolean') {
      if (result) {
        if (await this.dialogService.showYesNo('Sicher? das zerstört die manuelle Sortierung!')) {
          await this.manualDataOrderService.resetOrderValueInCollection('inventory-items', 'name');
        }
      } else {
        await this.manualDataOrderService.resetOrderValueInCollection('inventory-items', 'orderValue');
      }
    }
  }


  redraw() {
    this.dataGrid.api.redrawRows();
  }

  addItem() {
    const dialog = this.dialogService.showComponentDialog(InventoryItemFormComponent);
    dialog.componentInstance.newItem();
  }


  showEmptyItemsChanged(checked: any, onlyAc: boolean) {

    if (!onlyAc) {
      this.showEmptyItems = checked;
      this.showEmptyItemsAc = false;
    } else {
      this.showEmptyItems = true;
      this.showEmptyItemsAc = checked;
    }


    this.printButtons = [];
    this.specialButtons = [];
    if (this.showEmptyItems) {
      this.checkUpdatedAt();
    }
    this.inventoryFilterChanged();
    if (this.showEmptyItems) {
      this.generatePrintButtons();
      this.generateSpecialButtons();
    }


    if (this.showEmptyItems) {
      this.dataGrid.api.applyColumnState({
        state: [
          {colId: 'sourceOfSupply', sort: 'asc', sortIndex: 0},
        ],
      });
    } else {
      this.dataGrid.api.resetColumnState();
    }
  }

  private checkUpdatedAt() {
    const toOld = this.inventoryDataFiltered[this.studio]
      .filter(i => {
        const thisLocation = i.location === this.inventoryFilter[this.studio];
        const toOld = DateTools.dateDiffToNow(i.updatedAt) > DurationTools.DURATION_1DAY * 3;
        return thisLocation && toOld;
      });
    if (toOld.length > 0) {
      this.dialogService.showOk('Achtung der Bestand ist zum Teil älter als 3 Tage');
    }
  }

  print(sourceText?: string) {
    const data = this.getToPrint(sourceText);
    window.open('/inventory-to-order-print?data=' + decodeURIComponent(JsonTools.stringify(data)), '_blank');
  }


  private getToPrint(sourceText: string) {
    const source = this.inventoryService.inventorySourcesOfSupply.find(s => s.text === sourceText);
    let nameField = 'name';
    if (source?.printEn) {
      nameField = 'nameEn';
      const missingTranslations = this.inventoryDataFiltered[this.studio].filter(i => !i.nameEn);
      if (missingTranslations.length > 0) {
        this.dialogService.showOk('Übersetzungen fehlen:\n' + missingTranslations.map(m => m.name).join('\n'));
        return;
      }
    }
    const data = {
      studio: this.studio,
      date: Date.now(),
      lines: this.inventoryDataFiltered[this.studio]
        .filter(i => {
          return !source || i.sourceOfSupply === source.value;
        })
        .map(i => {
          return {
            name: i[nameField] + ' (' + i.amountPerPackage + ' ' + i.unitOfMeasure + ')',
            amount: i.amountFull - i.amount,

          };
        }),
    };
    return data;
  }

  public sendWhatsApp(sourceText: string) {
    if (sourceText === 'Sorry Mom Mallorca') {
      const data = this.getToPrint(sourceText);
      const text = data.lines.map(l => l.amount + ' x ' + l.name).join('\n');
      this.socketService.sendWhatsAppMessage('+34676359836', text);
      this.socketService.sendTelegramAdmin('Sorry Mom Mallorca Bestellung\n\n' + text);
    } else {
      const data = this.getToPrint(sourceText);
      const text = data.lines.map(l => l.amount + ' x ' + l.name).join('\n');
      this.socketService.sendWhatsAppMessage('+4917683296085', text);
    }
  }

  private createNewOrder(item: NxtInventoryStockItem) {
    const dialog = this.dialogService.showComponentDialog(InventoryOrderCreateComponent);
    let shouldOrderAmount = 0;
    if ((item.amountFull - item.amount > 0) && item.amount <= item.amountAlarm) {
      shouldOrderAmount = item.amountFull - item.amount;
    }
    dialog.componentInstance.item = item;
    dialog.componentInstance.shouldOrderAmount = shouldOrderAmount;
  }

  private showOrders(data: any) {
    this.dialogService.showComponentDialog(InventoryOrdersComponent, {item: data, onlyNotArrived: true});
  }

  private showArrivedOrders(data: any) {
    this.dialogService.showComponentDialog(InventoryOrdersComponent, {item: data, onlyNotArrived: false});
  }

  generateSpecialButtons() {
    const sources = this.inventoryDataFiltered[this.studio].map(i => i.sourceOfSupply);
    if (sources.includes('GO2')) {
      this.specialButtons.push('GO2');
    }
  }


  private generatePrintButtons() {
    return;
    const sources = this.inventoryDataFiltered[this.studio].map(i => i.sourceOfSupply);
    for (const source of ArrayTools.unique(sources)) {
      if (source) {
        this.printButtons.push(this.inventoryService.getSourceText(source));
      }
    }
  }

  showCreateOrderColumn(show: boolean) {
    this.dataGrid.api?.setColumnsVisible(['createOrder'], show);
  }

  showOrderTextClicked(sourceOfSupply: string) {
    const lines: string[] = [];
    let totalPriceNet = 0;
    for (const item of this.inventoryDataFiltered[this.studio]) {
      const ordersToday = item.lastOrders.filter(o => o.sourceOfSupply === sourceOfSupply && !o.arrived && o.createdAt > Date.now() - DurationTools.DURATION_1DAY);
      for (const orderToday of ordersToday) {
        const item = this.inventoryDataFiltered[this.studio].find(i => i.id === orderToday.itemId);
        lines.push(item.name + ': ' + orderToday.orderAmount + ' x ' + item.amountPerPackage + ' ' + item.unitOfMeasure + ' für ' + orderToday.totalPriceNet.toMoneyString() + ' netto');
        totalPriceNet += orderToday.totalPriceNet;
      }
    }
    const text = 'Bestellung für ' + this.configService.config.value.studioName + '\n' + lines.join('\n') + '\n\nGesamtbetrag: ' + totalPriceNet.toMoneyString();
    this.clipboard.copy(text);
    this.dialogService.showOk(text, {buttonText: 'In die Zwischenablage'});
  }

  calcShowOrderTexts() {
    let orderedSourceOfSupply: string[] = [];
    for (const item of this.inventoryDataFiltered[this.studio]) {
      if (item.lastOrders) {
        const orders = item.lastOrders.filter(o => !o.arrived && o.createdAt > Date.now() - DurationTools.DURATION_1DAY);
        orderedSourceOfSupply.push(...orders.map(o => o.sourceOfSupply));
      }
    }
    orderedSourceOfSupply = ArrayTools.unique(orderedSourceOfSupply);
    orderedSourceOfSupply = orderedSourceOfSupply.filter(s => this.inventoryService.inventorySourcesOfSupply.find(i => i.text === s && i.viewOrderListText));
    this.showOrderTexts = ArrayTools.unique(orderedSourceOfSupply);
  }

  specialButtonClicked(specialButton: string) {
    if (specialButton === 'GO2') {
      const go2Items: Go2Item[] = [];
      for (const item of this.inventoryDataFiltered[this.studio]) {
        if (item.sourceOfSupply === 'GO2') {
          let name = '';
          let size = '';
          if (item.nameBySource.includes('\t')) {
            const parts = item.nameBySource.split('\t');
            name = parts[0];
            size = parts[1];
          } else {
            name = item.nameBySource.substring(0, item.nameBySource.indexOf(' ')).trim();
            size = item.nameBySource.substring(item.nameBySource.indexOf(' ') + 1).trim();
          }
          go2Items.push({id: item.id, name, size, amount: item.amount.toString()});
        }
      }
      const fnCode = 'function ' + this.inventorySpecialButtonService.runGo2Inject.toString();
      const itemsCode = 'const go2Items = JSON.parse(\'' + JSON.stringify(go2Items) + '\');';
      const totalCode = itemsCode + '\n\n' + fnCode + '\n\nrunGo2Inject(go2Items)';
      this.clipboard.copy(totalCode);
    }
  }
}

