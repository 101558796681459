<!--<div style="border: 3px solid red; width: 100%; height: 100%; position: fixed">
  <div style="width: 50%; height: 100%; background: rgba(94,94,94,0.51)"></div>
</div>-->
<div class="flex h-full w-full flex-col text-[10px] page-wrapper p-relative">

  <div class="flex flex-row text-xl fixed-header" *ngIf="pageNumberCurrent > 0 && pageNumberCurrent <= pageNumberTotal">
    <div [nxtTranslate]="lang">SEITE</div>
    <div>&nbsp;{{ pageNumberCurrent }} / {{ pageNumberTotal }}</div>
  </div>

  <div class="flex justify-center p-3" *ngIf="pageNumberCurrent > 1">
    <div class="flex flex-col items-center justify-center">
      <div>
        <img src="../../../../assets/logo/logo_black.svg" style="width: 6cm"/>
      </div>
      <div class="mt-1 flex flex-row logo-text">
        <div>NXT-LVL.INK {{ configService.config.value.invoiceData.fromCity.toUpperCase() }}</div>
        <div class="flex flex-row">
          <div class="gold">・</div>
          {{ configService.config.value.invoiceData.fromCompany }}
        </div>
        <div class="flex flex-row" *ngIf="configService.config.value.invoiceData.fromCompany2">
          <div class="gold">・</div>
          {{ configService.config.value.invoiceData.fromCompany2 }}
        </div>
        <div class="flex flex-row">
          <div class="gold">・</div>
          {{ configService.config.value.invoiceData.fromStreet }}
        </div>
        <div class="flex flex-row">
          <div class="gold">・</div>
          {{ configService.config.value.invoiceData.fromPostalCode }} {{ configService.config.value.invoiceData.fromCity }}
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-row justify-around p-3" *ngIf="pageNumberCurrent === 1">
    <div class="flex w-full grow flex-col items-center justify-center" style="border: 0px solid red">
      <div>
        <img src="../../../../assets/logo/logo_black.svg" style="width: 8cm"/>
      </div>
      <div class="mt-1 flex w-full flex-col items-center logo-text-left text-[20px]">
        <div>NXT-LVL.INK {{ configService.config.value.invoiceData.fromCity.toUpperCase() }}</div>
        <div class="flex flex-row">
          {{ configService.config.value.invoiceData.fromCompany }}
        </div>
        <div class="flex flex-row" *ngIf="configService.config.value.invoiceData.fromCompany2">
          {{ configService.config.value.invoiceData.fromCompany2 }}
        </div>
        <div class="flex flex-row">
          {{ configService.config.value.invoiceData.fromStreet }}
        </div>
        <div class="flex flex-row">
          {{ configService.config.value.invoiceData.fromPostalCode }} {{ configService.config.value.invoiceData.fromCity }}
        </div>
      </div>
    </div>

    <div class="flex justify-end pt-8">
      <div style="border: 1px dotted black; width: 6cm; height: 8.6cm;" class="flex items-center justify-center">
        <div>QR-CODE</div>
      </div>
    </div>

  </div>

  <div class="flex flex-col items-center pb-3 text-3xl header"
       *ngIf="pageNumberCurrent > 0 && pageNumberCurrent <= pageNumberTotal">
    <div [nxtTranslate]="lang">EINWILLIGUNGSERKLÄRUNG PIERCING</div>
  </div>

  <div class="flex flex-col items-center pb-3 text-2xl header"
       *ngIf="pageNumberCurrent === 3">
    <div class="center" [nxtTranslate]="lang">PIERCING<br/>EINWILLIGUNG ERZIEHUNGSBERECHTIGTE</div>
  </div>

  <div *ngIf="pageNumberCurrent === 1">
    <nxt-declarations-of-consent-section titleText="DEINE DATEN" [lang]="lang">
      <div class="flex w-full flex-row gap-3">
        <div class="flex w-full flex-col">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Vor + Nachname</div>
        </div>
        <div class="flex w-full flex-col">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Straße + Hausnummer</div>
        </div>
        <div class="flex w-full flex-col">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">PLZ + Ort</div>
        </div>
      </div>
      <div class="flex w-full flex-row gap-3">
        <div class="flex w-full flex-col">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Geburtsdatum</div>
        </div>
        <div class="flex w-full flex-col">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Handy</div>
        </div>
        <div class="flex w-full flex-col">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Personalausweisnummer</div>
        </div>
      </div>
    </nxt-declarations-of-consent-section>
    <nxt-declarations-of-consent-section [lang]="lang" titleText="ANAMNESE">
      <div class="flex flex-row">
        <div class="flex w-1/2 flex-col">
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich habe in den letzten 24 Std. Alkohol zu mir genommen"
            textEn="I have consumed alcohol in the last 24 hours">
          </nxt-declarations-of-consent-checkbox>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich habe Hautkrankheiten"
            textEn="I have skin diseases">
          </nxt-declarations-of-consent-checkbox>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich habe Allergien oder Überempfindlichkeitsreaktionen?<br/>z.B.: Pflaster, Medikamente, Nickel…"
            textEn="I have allergies or hypersensitivity reactions? e.g.: plasters, medicines, nickel…">
          </nxt-declarations-of-consent-checkbox>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich habe Kreislaufprobleme"
            textEn="I have circulatory problems">
          </nxt-declarations-of-consent-checkbox>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich habe zu hohen oder zu niedrigen Blutdruck"
            textEn="My blood pressure is too high or too low">
          </nxt-declarations-of-consent-checkbox>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich nehme blutverdünnende Medikamente"
            textEn="I take blood thinning medication">
          </nxt-declarations-of-consent-checkbox>
        </div>
        <div class="flex w-1/2 flex-col">
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich bin Bluter oder Diabetiker"
            textEn="Am I a hemophiliac or diabetic">
          </nxt-declarations-of-consent-checkbox>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich bin Epileptiker oder habe andere Anfälle"
            textEn="I am epileptic or have other seizures">
          </nxt-declarations-of-consent-checkbox>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich habe eine Hepatitis A oder B Infektion"
            textEn="I have a hepatitis A or B infection">
          </nxt-declarations-of-consent-checkbox>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich habe eine Hepatitis C, HIV oder EbV Infektion"
            textEn="I have hepatitis C, HIV or EbV infection">
          </nxt-declarations-of-consent-checkbox>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Nur bei Intimpiercing: Ich leide unter einer Geschlechtskrankheit"
            textEn="Only for genital piercing: I suffer from a sexually transmitted disease">
          </nxt-declarations-of-consent-checkbox>
          <nxt-declarations-of-consent-checkbox
            [lang]="lang"
            text="Ich bin schwanger oder stille aktuell"
            textEn="I am pregnant or currently breastfeeding">
          </nxt-declarations-of-consent-checkbox>
        </div>
      </div>
    </nxt-declarations-of-consent-section>
  </div>

  <div *ngIf="pageNumberCurrent === 2">
    <nxt-declarations-of-consent-section titleText="EINWILLIGUNG" [lang]="lang">
      <div class="pb-1" [nxtTranslate]="lang">
        Das Anbringen eines Piercings stellt laut §224 StGB eine Körperverletzung dar.<br/>Deshalb bedarf es einer Einwilligungserklärung, womit durch die
        Unterschrift der zu piercenden Person die Rechtsgrundlage geschaffen wird.<br/>Die zu piercende Person erklärt sich im Sinne des § 224 StGB damit
        einverstanden, dass der / die Piercer(in) bei der Durchführung des Piercings einen Eingriff an Ihrem Körper vornimmt.
      </div>

      <div class="pt-2">
        <div [nxtTranslate]="lang">
          Ich habe den/die Piercer(in) beauftragt, an meinem Körper –an folgender Stelle- Schmuck durch Body-Piercing
          anzubringen:
        </div>
        <div class="mt-6 w-2/3" style="border-top: 1px solid black" [nxtTranslate]="lang">
          Körperstelle(n)
        </div>
      </div>
      <div class="pt-2">
        <nxt-declarations-of-consent-checkbox
          [lang]="lang"
          text="Ich bestätige hiermit, dass ich volljährig bin bzw. ein/e Erziehungsberechtigte/r anwesend ist und vor der Unterzeichnung dieser Erklärung in verständlicher Art und Weise aufgeklärt wurde. Diese Aufklärung bezog sich auf die beim Piercen verbundenen Risiken und möglichen Komplikationen, über die Art und Weise der Durchführung der Anbringung des Schmucks und des Piercingvorgangs sowie über die Tatsache, dass dies mit der Verursachung von Schmerzen verbunden sein kann."
          [checked]="true"></nxt-declarations-of-consent-checkbox>
        <nxt-declarations-of-consent-checkbox
          [lang]="lang"
          text="Alle meine Fragen wurden vollständig beantwortet. Ich hatte ausreichend Zeit und Gelegenheit, meine Entscheidung zu fällen."
          [checked]="true"></nxt-declarations-of-consent-checkbox>
        <nxt-declarations-of-consent-checkbox
          [lang]="lang"
          text=" Es ist mir bewusst, dass es trotz aller hygienischer Umstände zu Komplikationen kommen kann. Über diese Komplikationen wurde ich ausreichend aufgeklärt."
          [checked]="true"></nxt-declarations-of-consent-checkbox>
        <nxt-declarations-of-consent-checkbox
          [lang]="lang"
          text="Eine Pflegeanleitung wurde mir ausgehändigt."
          [checked]="true"></nxt-declarations-of-consent-checkbox>
        <nxt-declarations-of-consent-checkbox
          [lang]="lang"
          text="Ich stehe nicht unter dem Einfluss von Drogen, Alkohol oder Medikamenten und bin im vollen Besitz meiner geistigen und körperlichen Kräfte."
          [checked]="true"></nxt-declarations-of-consent-checkbox>
        <nxt-declarations-of-consent-checkbox
          [lang]="lang"
          text="Der Heilungserfolg des Piercings hängt in erster Linie von der eigenen Nachbehandlung ab."
          [checked]="true"></nxt-declarations-of-consent-checkbox>
        <nxt-declarations-of-consent-checkbox
          [lang]="lang"
          text="Ich bin auf die Aufklärung zur Einverständniserklärung, Datenschutzbestimmungen und AGB hingewiesen worden."
          [checked]="true"></nxt-declarations-of-consent-checkbox>
      </div>
    </nxt-declarations-of-consent-section>
    <nxt-declarations-of-consent-section titleText="MÖGLICHE KOMPLIKATIONEN" class="mt-4" [lang]="lang">
      <div class="pb-1" [nxtTranslate]="lang">Auch bei sachgemäßer Ausführung besteht das Risiko folgender, unerwünschter Nebenerscheinungen:
      </div>
      <div class="flex flex-row">
        <div class="flex w-1/4 flex-col">
          <div [nxtTranslate]="lang">・Nachblutungen</div>
          <div [nxtTranslate]="lang">・Rötungen</div>
          <div [nxtTranslate]="lang">・Kreislaufstörungen</div>
        </div>
        <div class="flex w-1/4 flex-col">
          <div [nxtTranslate]="lang">・Allergien</div>
          <div [nxtTranslate]="lang">・Entzündungen</div>
          <div [nxtTranslate]="lang">・Nekrosen</div>
        </div>
        <div class="flex w-1/4 flex-col">
          <div [nxtTranslate]="lang">・Herauswachsen</div>
          <div [nxtTranslate]="lang">・Zahnschädigungen</div>
          <div [nxtTranslate]="lang">・eitrige Infektionen</div>
        </div>
        <div class="flex w-1/4 flex-col">
          <div [nxtTranslate]="lang">・Blutergüsse</div>
          <div [nxtTranslate]="lang">・Narbenbildung</div>
          <div [nxtTranslate]="lang">・Schmerzen</div>
        </div>
        <!--<div class="flex w-1/2 flex-col">
          <div [nxtTranslate]="lang">・Nachblutungen</div>
          <div [nxtTranslate]="lang">・Rötungen</div>
          <div [nxtTranslate]="lang">・Kreislaufstörungen</div>
          <div [nxtTranslate]="lang">・Allergien</div>
          <div [nxtTranslate]="lang">・Entzündungen</div>
          <div [nxtTranslate]="lang">・Nekrosen</div>
        </div>
        <div class="flex w-1/2 flex-col">
          <div [nxtTranslate]="lang">・Herauswachsen</div>
          <div [nxtTranslate]="lang">・Zahnschädigungen</div>
          <div [nxtTranslate]="lang">・eitrige Infektionen</div>
          <div [nxtTranslate]="lang">・Blutergüsse</div>
          <div [nxtTranslate]="lang">・Narbenbildung</div>
          <div [nxtTranslate]="lang">・Schmerzen</div>
        </div>-->
      </div>
      <div class="pt-2">
        <nxt-declarations-of-consent-checkbox
          [lang]="lang"
          text="Ich wurde darüber aufgeklärt, dass es trotz steriler Arbeitsweise zu Komplikationen kommen kann."
          [checked]="true"></nxt-declarations-of-consent-checkbox>
      </div>
    </nxt-declarations-of-consent-section>
    <nxt-declarations-of-consent-section titleText="VERTRAGSPARTNER" class="mt-4" *ngIf="!artistEmployed">
      <div class="pb-2" [nxtTranslate]="lang">Die Piercer(innen) im Tattoo-Studio NXT-LVL.INK sind selbständig tätig, NXT-LVL.INK stellt lediglich
        Räume und Serviceleistungen zur Verfügung. Wir klären Dich daher hiermit ausdrücklich darüber auf, dass das
        Geschäft (Dienstleistungsvertrag für die Tätowierung) nur zwischen Dir und dem / der Piercer(in) abgeschlossen wird.
        Evtl. Haftungsansprüche sind an diesen zu stellen. Unabhängig davon sind wir, NXT-LVL.INK, um zufriedene Kunden
        bemüht und nehmen eure Reklamationen im Auftrag der Tätowierer gerne entgegen. Evtl. Kompensationen seitens
        NXT-LVL.INK zu Reklamationen erfolgen jedoch auf Basis von Kulanz ohne Anerkennung einer dementsprechenden
        Rechtspflicht.
      </div>
      <div class="flex flex-row w-full justify-center gap-7" style="border: 0px solid red">
        <div class="flex w-1/2 flex-row justify-center items-center">
          <div style="border: 1px dotted black" class="h-fit px-4 py-2 rounded">
            Vertragspartner
          </div>
        </div>
        <div class="flex w-1/2 flex-col">
          <div style="height: 0.8cm"></div>
          <div class="flex flex-col">
            <div style="border-bottom: 1px solid black; width: 7cm">
              <img src="../../../assets/images/sign-here-3.svg" style=" width: 20px;"/>
            </div>
            <div>Signature Artist</div>
          </div>
        </div>
      </div>
    </nxt-declarations-of-consent-section>


  </div>

  <div *ngIf="pageNumberCurrent === 3">
    <div class="p-6 text-[14px]" [nxtTranslate]="lang">
      Bei Minderjährigen Personen muss diese Einwilligungserklärung von einer erziehungsberechtigten Person
      unterzeichnet und somit bestätigt werden.<br/><br/>Mit der Unterschrift wird die ausgeführte Behandlung erlaubt und die
      Richtigkeit der in dieser Erklärung gemachten Daten und Angaben bestätigt.
    </div>
    <nxt-declarations-of-consent-section titleText="ERZIEHUNGSBERECHTIGTE DATEN" [lang]="lang">
      <div class="flex w-full flex-row">
        <div class="flex w-full flex-col">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Vor + Nachname</div>
        </div>
        <div class="flex w-full flex-col pl-5">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Straße + Hausnummer</div>
        </div>
      </div>
      <div class="flex w-full flex-row">
        <div class="flex w-full flex-col">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">PLZ + Ort</div>
        </div>
        <div class="flex w-full flex-col pl-5">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Geburtsdatum</div>
        </div>
        <div class="flex w-full flex-col pl-5">
          <div class="flex w-full text-2xl" style="border-bottom: 1px solid black">&nbsp;</div>
          <div class="flex text-[9px]" [nxtTranslate]="lang">Personalausweisnummer</div>
        </div>
      </div>
    </nxt-declarations-of-consent-section>
  </div>

  <div class="flex flex-col items-center justify-center footer" *ngIf="signatureName">
    <div class="mb-5 flex flex-row items-end w-full gap-7" style="border: 0px solid blue">
      <div class="flex flex-col w-1/2 items-end" style="border:0px solid red">
        <div class="flex w-fit flex-col">
          <div class="flex flex-row">
            <div style="border-bottom: 1px solid black" class="text-sm">
              {{ configService.config.value.invoiceData.fromCity }}, den
            </div>
            <div style="border-bottom: 1px solid black; width: 4cm" class="text-sm">
            </div>
          </div>
          <div [nxtTranslate]="lang">Ort & Datum</div>
        </div>
      </div>
      <div class="flex flex-col w-1/2" style="border:0px solid green">
        <div style="border-bottom: 1px solid black; width: 7cm">
          <img src="../../../assets/images/sign-here-2.svg" style=" width: 20px"/>
        </div>
        <div [nxtTranslate]="lang" [innerHTML]="signatureName"></div>
      </div>
    </div>
  </div>
  <div class="footer-page-counter flex w-full flex-row justify-end">
    <div *ngIf="pageNumberCurrent > 0 && pageNumberCurrent <= pageNumberTotal">Seite {{ pageNumberCurrent }}
      /{{ pageNumberTotal }}
    </div>
  </div>
</div>

```
