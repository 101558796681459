import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, input, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'nxt-page-header',
  template: `
    <div class="flex w-full flex-row justify-between p-2">
      <div class="flex w-full flex-col items-center md:flex-row">
        <ng-content select="nxt-page-header-title"/>
        <div class="mr-3 flex flex-row items-center gap-8">
          <ng-content/>
        </div>
      </div>
      @if (dialogRef()) {
        <nxt-button-icon (click)="close()">close</nxt-button-icon>
      }
    </div>
  `,
  styles: [`
      :host {
          display: flex;
          flex-direction: row;
          width: 100%;
          overflow-x: auto;
      }`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NxtButtonIconComponent,
  ], standalone: true,
})

export class NxtPageHeaderComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/
  dialogRef = input<MatDialogRef<any>>();
  beforeClose = input<() => Promise<boolean>>();

  /*** Signals ***/

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);


  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  async close() {
    if (typeof this.beforeClose() === 'function') {
      const doClose = await this.beforeClose()();
      if (!doClose) {
        return;
      }
    }
    if (this.dialogRef()) {
      this.dialogRef().close();
    }
  }
}
