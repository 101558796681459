import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[nxtDragScroll]',
  standalone: true,
})
export class DragScrollDirective {

  constructor(elem: ElementRef) {
    elem.nativeElement.classList.add('dragScroll');
    requestAnimationFrame(() => {
      if (!this.supportTouch()) {
        (window as any).dragScroll.reset();
      }
    });
  }

  public supportTouch(): boolean {
    const nxtNavigator = window.navigator as any;
    return 'ontouchstart' in window || (navigator.maxTouchPoints && navigator.maxTouchPoints > 0) || (nxtNavigator.msMaxTouchPoints && nxtNavigator.msMaxTouchPoints > 0);
  }


  private async waitForDragscrolls() {
    return new Promise<void>((resolve, reject) => {
      if (document.querySelectorAll('.dragscroll').length > 0) {
        resolve();
      } else {
        setTimeout(() => {
          resolve(this.waitForDragscrolls());
        }, 100);
      }
    });
  }
}
