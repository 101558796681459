import {NxtCalendarEventFile} from '../../common-interfaces/nxt.calendar-event.interface';
import {SortTools} from './sort.tools';


export class EventFileTools {
  static sortFiles(files: NxtCalendarEventFile[]) {
    if (!Array.isArray(files)) {
      return [];
    }
    return SortTools.sortWithGetter(files, EventFileTools.getEventFileSortValue);
  }

  private static getEventFileSortValue(file: NxtCalendarEventFile) {
    switch (file.subType) {
      case 'templatePhoto':
        return '01_' + file.fileCreatedAt;
      case 'stencilPhoto':
        return '02_' + file.fileCreatedAt;
      case 'stencilVideo':
        return '03_' + file.fileCreatedAt;
      case 'preTattooPhoto':
        return '04_' + file.fileCreatedAt;
      case 'preTattooVideo':
        return '05_' + file.fileCreatedAt;
      case 'tattooPhoto':
        return '06_' + file.fileCreatedAt;
      case 'tattooVideo':
        return '07_' + file.fileCreatedAt;
      case 'consent':
        return '10_' + file.fileCreatedAt;
      case 'artistContract':
        return '99_' + file.fileCreatedAt;
    }
    return '';
  }
}
