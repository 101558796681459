import {ChangeDetectionStrategy, Component, OnInit, Optional, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {ImageCroppedEvent, ImageCropperModule} from 'ngx-image-cropper';
import {NgIf} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {MatDialogRef} from '@angular/material/dialog';
import {SafeUrlPipe} from '../../pipes/safe-url.pipe';

@Component({
  selector: 'nxt-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ImageCropperModule,
    NgIf,
    NxtButtonComponent,
    SafeUrlPipe,
  ],
  standalone: true,
})

export class ImageCropComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  src = signal('');
  title = signal('');
  showPreview = signal(true);
  croppedImage = signal('');
  cropEvent = signal<ImageCroppedEvent | null>(null);
  canvasRotation = signal(0);
  buttonText = signal('OK');
  private currentBlob: Blob;


  /*** Injections ***/

  constructor(
    @Optional() private dialogRef: MatDialogRef<ImageCropComponent>,
    private sanitizer: DomSanitizer,
  ) {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  setData(arrayBuffer: ArrayBuffer) {
    this.src.set(URL.createObjectURL(new Blob([arrayBuffer])));
  }

  imageCropped(event: ImageCroppedEvent) {
    const croppedImage = event.objectUrl;
    this.croppedImage.set(croppedImage);
    this.currentBlob = event.blob;
    this.cropEvent.set(event);
  }

  rotateLeftClicked() {
    this.canvasRotation.update(value => value + 1);
  }

  async buttonClicked() {
      this.dialogRef.close({arrayBuffer: await new Response(this.currentBlob).arrayBuffer(), cropEvent: this.cropEvent()});
  }

  cancelClicked() {
    this.dialogRef.close();
  }
}
