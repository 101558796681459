import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, effect, inject, input, model, OnInit, output, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {FlexModule} from 'ngx-flexible-layout';
import {FormsModule} from '@angular/forms';
import {InputComponent} from '../../../form-controls/input/input.component';
import {MatIcon} from '@angular/material/icon';
import {MatSlider, MatSliderThumb} from '@angular/material/slider';
import {MatTooltip} from '@angular/material/tooltip';
import {NxtDatagridComponent} from '../../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtDatePipe} from '../../../../pipes/nxt-date-pipe';
import {SlideToggleComponent} from '../../../form-controls/slide-toggle/slide-toggle.component';
import {SocketInterface} from '../../../../common-interfaces/socket/socket-interface';
import {NxtColDef} from '../../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtFieldType} from '../../../../common-interfaces/nxt-field.interface';
import {DurationTools} from '../../../../common-browser/helpers/duration.tools';
import {
  NxtBankConfigAccount,
  NxtBankConfigAccountType,
  NxtBankDocument,
  NxtBankTransaction,
  NxtBankTransactionStatus,
  NxtBankTransactionType,
} from '../../../../common-interfaces/bank/bank-transaction.interface';
import {LoginService} from '../../../../services/login.service';
import {CellClickedEvent, RowSelectionOptions, SelectionChangedEvent} from 'ag-grid-community';
import {SocketService} from '../../../../services/socket/socket.service';
import {DialogService} from '../../../../services/dialog.service';
import {debounceTime, Subject} from 'rxjs';
import {BankDocumentTools} from '../../../../common-browser/helpers/bank-document.tools';
import {BankTransactionTools} from '../../../../common-browser/helpers/bank-transaction.tools';
import {AssignFinderService} from '../../bank-documents/bank-document-details/assign-finder.service';
import {MultiClickDirective} from '../../../../directives/multi-click.directive';
import {JsonTools} from '../../../../common-browser/helpers/json.tools';
import {ObjectTools} from '../../../../common-browser/helpers/object.tools';

export interface PossibleNxtBankTransaction extends NxtBankTransaction {
  diffToDocument: number;
  selectable: boolean;
}

@Component({
  selector: 'nxt-bank-transaction-finder',
  templateUrl: './bank-transaction-finder.component.html',
  styleUrls: ['./bank-transaction-finder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FlexModule,
    FormsModule,
    InputComponent,
    MatIcon,
    MatSlider,
    MatSliderThumb,
    MatTooltip,
    NxtDatagridComponent,
    NxtDatePipe,
    SlideToggleComponent,
    MultiClickDirective,

  ],
})

export class BankTransactionFinderComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  private _datagrid: NxtDatagridComponent;
  @ViewChild(NxtDatagridComponent) set datagrid(elem: NxtDatagridComponent) {
    this._datagrid = elem;
    this.setColumnsVisible();
  }


  /*** Inputs ***/
  initTransactions = input<NxtBankTransaction[]>();
  initDate = input.required<number>();
  initValue = input.required<number | null>();
  mode = input.required<'view' | 'search'>();
  assignIcon = input('assignment_add');
  assignIconColor = input.required<string>();
  contentHeight = input(false);
  assignText = input.required<string>();
  initTexts = input<string[]>([]);
  notSelectableTransactionIds = input.required<string[]>();
  otherCompanyName = input<string>('');
  bankAccountTypes = input<NxtBankConfigAccountType[]>([]);
  transactionStatus = input<NxtBankTransactionStatus[]>([]);
  onlyNeedDocument = input(false);
  onlyNeedDocumentOrStatusAi = input(false);
  onlyNeedTransaction = input(false);
  transactionTypes = input<NxtBankTransactionType[]>([]);
  accountIds = input<string[]>([]);
  excludeTransactionIds = input<string[]>([]);
  assignedTransactions = input<{ id: string; value: number }[]>([]);
  transactionMethods = input<string[]>([]);
  headerText = input.required();
  assignMode = input.required<'full' | 'rest'>();


  /*** Outputs ***/
  onAssignSelectedPossibleTransactionsClicked = output<void>();

  /*** Models ***/
  selectedPossibleTransactions = model<PossibleNxtBankTransaction[]>([]);

  /*** Signals ***/
  filterDaysBack = signal(-1);
  filterDaysForward = signal(1);
  quickFilterPossibleTransactions = signal('');
  filterPossibleTransactionsAlsoFullyAssigned = signal(false);
  filterPossibleTransactionsSameCompanies = signal(true);
  filterPossibleTransactionsSameValue = signal(true);
  possibleTransactions = signal<PossibleNxtBankTransaction[]>([]);
  possibleTransactionLoading = signal(false);
  private bankAccounts = signal<NxtBankConfigAccount[]>([]);
  showPossibleTransactionsNotText = signal('');
  showPossibleTransactions = signal(false);

  /*** Computed ***/
  filterBookDateFromDateString = computed(() => {
    return this.initDate().dateAddDays(this.filterDaysBack()).dateFormatDate();
  });
  filterBookDateTillDateString = computed(() => {
    return this.initDate().dateAddDays(this.filterDaysForward()).dateFormatDate();
  });
  selectedPossibleTransactionsText = computed(() => {
    if (this.assignMode() === 'full') {
      return this.selectedPossibleTransactions().reduce((sum, t) => sum + t.value, 0).abs().toMoneyString();
    }
    if (this.assignMode() === 'rest') {
      return this.selectedPossibleTransactions().reduce((sum, t) => sum + t.assignValue, 0).abs().toMoneyString();
    }
  });
  possibleTransactionsFiltered = computed(() => {
    return this.possibleTransactions(); // .filter(t => !this.excludeTransactionIds().includes(t.id));
  });
  bankAccountsById = computed(
    () => Object.fromEntries(this.bankAccounts().map(bankAccount => [bankAccount.id, bankAccount])),
  );

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private loginService = inject(LoginService);
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  private assignFinderService = inject(AssignFinderService);


  /*** Variable ***/
  possibleTransactionRowSelection: RowSelectionOptions<PossibleNxtBankTransaction> = {
    mode: 'multiRow',
    headerCheckbox: false,
    isRowSelectable: trans => !trans.data.selectable,
  };
  possibleTransactionsColumnDefs: NxtColDef<PossibleNxtBankTransaction>[] = [
    {headerName: '', field: 'id', hide: !this.loginService.isJulian(), maxWidth: 40},
    {headerName: 'Status', field: 'status', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Datum', field: 'bankCreatedAt', nxtFieldType: NxtFieldType.Date_germanDateTime},
    {
      headerName: 'Abstand', field: 'diffToDocument',
      valueFormatter: params => DurationTools.format2(params.value, {futurePrefix: '', pastPrefix: '', onlyOneUnit: true}),
      cellStyle: {textAlign: 'right'},
    },
    {
      headerName: 'Konto', nxtFieldType: NxtFieldType.Text,
      valueGetter: params => {
        try {
          return this.bankAccountsById()[params.data.accountId].name;
        } catch (err) {
          this.socketService.sendTelegramAdmin('der fehler: ' + JsonTools.stringifyFormat(params.data));
          debugger;
        }
      },
    },
    {headerName: 'Gegenkonto', field: 'nxtAi.datevOther.name', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Gegenüber', field: 'other.name', nxtFieldType: NxtFieldType.Text},
    {
      headerName: 'Betrag',
      field: 'value',
      valueFormatter: params => params.value.toMoneyString(params.data.currency, true),
      cellStyle: {textAlign: 'right'},
    },
    {
      colId: 'assignedValue', headerName: 'Zugewiesen',
      valueFormatter: params => params.value.toMoneyString(params.data.currency, true),
      cellStyle: {textAlign: 'right'},
      valueGetter: params => {
        return this.assignedTransactions().find(t => t.id === params.data.id)?.value;
      }, hide: true,
    },
    {
      headerName: 'Offen', field: 'assignValue',
      valueFormatter: params => params.value.toMoneyString(params.data.currency, true),
      cellStyle: {textAlign: 'right'},
    },
    {headerName: 'Text', field: 'text', nxtFieldType: NxtFieldType.Text},
  ];
  loadPossibleTransactionsDebounced$ = new Subject<void>();
  minMaxValue = signal(50);
  gridMinHeight = computed(() => {
    if (this.possibleTransactionsFiltered().length < 10) {
      return 62 + (this.possibleTransactionsFiltered().length * 30);
    }
  });

  async setColumnsVisible() {
    await ObjectTools.waitFor(() => this._datagrid?.api, 'Bank-Transaction setColumnsVisible');
    this._datagrid?.api?.setColumnsVisible(['assignedValue'], this.assignedTransactions().length > 0);
  }

  constructor() {
    super();

    effect(() => {
      this.assignedTransactions();
      this.setColumnsVisible();
    });

    effect(() => {
      this.filterBookDateFromDateString();
      this.filterBookDateTillDateString();
      this.filterPossibleTransactionsSameCompanies();
      this.filterPossibleTransactionsSameValue();
      this.filterPossibleTransactionsAlsoFullyAssigned();
      this.initTransactions();
      this.loadPossibleTransactionsDebounced$.next();
    });
    this.loadPossibleTransactionsDebounced$.pipe(debounceTime(500)).subscribe(() => {
      this.loadPossibleTransactions().then();
    });
  }

  async ngOnInit() {
    this.bankAccounts.set(await this.socketService.bank.getAccounts());
  }

  nxtOnDestroy() {
  }


  possibleTransactionSelectionChanged(ev: SelectionChangedEvent<NxtBankTransaction> | CellClickedEvent) {
    if (ev.api.getSelectedRows()) {
      this.selectedPossibleTransactions.set(ev.api.getSelectedRows());
    }
  }

  assignSelectedPossibleTransactionsClicked() {
    this.onAssignSelectedPossibleTransactionsClicked.emit();
  }

  async loadPossibleTransactions() {
    if (this.mode() === 'view') {
      if (this.initTransactions()) {
        this.possibleTransactions.set(this.appendDiffAndSortTransactions(this.initTransactions()));
      }
    }
    if (this.mode() === 'search') {
      if (this.initDate() && this.initValue()) {
        this.possibleTransactionLoading.set(true);
        const filter: SocketInterface.TransactionFinder = {
          bookDateFromDateString: this.filterBookDateFromDateString(),
          bookDateTillDateString: this.filterBookDateTillDateString(),
        };
        if (this.filterPossibleTransactionsSameCompanies() && this.otherCompanyName()) {
          filter.otherCompanyNames = [this.otherCompanyName()];
        }
        if (this.filterPossibleTransactionsSameValue()) {
          filter.valueOrAssignValue = this.initValue();
        }
        if (this.transactionTypes().length > 0) {
          filter.transactionTypes = this.transactionTypes();
        }

        if (this.transactionMethods().length > 0) {
          filter.transactionMethods = this.transactionMethods();
        }

        if (this.bankAccountTypes().length > 0) {
          filter.bankAccountTypes = this.bankAccountTypes();
        }

        if (this.accountIds().length > 0) {
          filter.bankAccountIds = this.accountIds();
        }

        if (this.excludeTransactionIds().length > 0) {
          filter.excludeTransactionIds = this.excludeTransactionIds();
        }


        filter.status = this.transactionStatus();
        if (this.filterPossibleTransactionsAlsoFullyAssigned()) {
          filter.status.push(NxtBankTransactionStatus._90_ok);
        }

        if (this.onlyNeedDocument()) {
          filter.needDocument = true;
        }

        if (this.onlyNeedDocumentOrStatusAi()) {
          filter.needDocumentOrStatusAi = true;
        }

        if (this.onlyNeedTransaction()) {
          filter.needTransaction = true;
        }


        filter.projection = {id: 1, bankCreatedAt: 1, accountId: 1, value: 1, text: 1, nxtAi: {datevOther: {name: 1}}, other: 1, assignValue: 1, status: 1, currency: 1};


        const transactions = await this.socketService.bank.transactionFinder(filter);
        if (transactions.length === 1000) {
          this.dialogService.showOk('Es wurden mehr als 1000 Buchungen gefunden, es werden nur 1000 angezeigt');
        }
        this.possibleTransactions.set(this.appendDiffAndSortTransactions(transactions));
        this.showPossibleTransactions.set(true);
        this.possibleTransactionLoading.set(false);
      } else {
        this.showPossibleTransactionsNotText.set('Firma Datum und Betrag müssen für eine Verknüpfung vorhanden sein!');
        this.showPossibleTransactions.set(false);
      }
    }
  }

  appendDiffAndSortTransactions(transactions: NxtBankTransaction[]): PossibleNxtBankTransaction[] {
    for (const transaction of transactions as PossibleNxtBankTransaction[]) {
      transaction.diffToDocument = Math.abs(transaction.bankCreatedAt - this.initDate());
      transaction.selectable = this.notSelectableTransactionIds().includes(transaction.id);
    }
    return (transactions as PossibleNxtBankTransaction[]).sortNumber('diffToDocument');
  }

  public async amazonKiClicked() {
    // document-id: 8a45cae2-6f81-409b-aa4e-614e736aa0b0

    const documents = await this.socketService.findBankDocuments({
      documentNumbersOr: this.initTexts(),
      companyName: this.otherCompanyName(),
    });
    if (documents) {
      const documentsIdValue = documents.map(this.documentToIdValuePair).filter(d => d.value > 0);
      const transactionsIdValue = this.possibleTransactions().map(this.transactionToIdValuePair).filter(d => d.value > 0);
      const transactionsMatches = this.assignFinderService.findAllMatches(transactionsIdValue, documentsIdValue);
      const matches = transactionsMatches.filter(t => t.combinations.length > 0);
      if (matches.length > 1) {
        this.dialogService.showOk('Zu viele Möglichkeiten Variante-1, Julian muss mehr programmieren, bitte bescheid geben');
      } else if (matches.length === 1) {
        const match = matches[0];
        if (match.combinations.length > 1) {
          this.dialogService.showOk('Zu viele Möglichkeiten Variante-2, Julian muss mehr programmieren, bitte bescheid geben');
        } else {
          const docLines: string[] = [];
          const transaction = this.possibleTransactions().find(t => t.id === match.singleId);
          for (const documentId of match.combinations[0]) {
            const document = documents.find(d => d.id === documentId);
            if (document) {
              docLines.push(document.documentNumber + ' ' + document.assignValue.toMoneyString());
            }
          }
          let text = 'Ich konnte sie finden, sie wird dir nun angezeigt, es gibt noch andere Dokumente die dazu passen';
          text += '\n' + docLines.join('\n');
          text += '\n\nErgibt: ' + transaction.assignValue.toMoneyString();
          this.dialogService.showOk(text);
          this.quickFilterPossibleTransactions.set(match.singleId);
          return;
        }
      }
    }
    this.dialogService.showOk('Nix gefunden :(');
  }

  private documentToIdValuePair(document: NxtBankDocument) {
    return {id: document.id, value: document.assignValue * 100};
  }

  private transactionToIdValuePair(transaction: NxtBankTransaction) {
    return {id: transaction.id, value: transaction.assignValue * 100};
  }
}
