import {DateTools} from './date.tools';
import {PublicHolidaysTools} from './public-holidays.tools';
import {DayOfWeek} from '../../common-interfaces/date.interface';

export class WorkingDayTools {

  public static isDateOutsideNext3WorkingDays(date: any) {
    const today = DateTools.clearTime(Date.now());
    const dateWithoutTime = DateTools.clearTime(date);
    if (date <= today) {
      return false;
    }
    return true;
  }

  public static addWorkingDays(date: any, workingDaysToAdd: number, region: 'he' | 'nw' | 'by' | 'sp') {
    if (workingDaysToAdd === 0) {
      return DateTools.parse(date);
    }
    let dateWithoutTime = DateTools.clearTime(date);
    const isNegative = workingDaysToAdd < 0;

    let counter = Math.abs(workingDaysToAdd);
    let daysAdded = 0;
    while (counter > 0) {

      dateWithoutTime = DateTools.addDays(dateWithoutTime, isNegative ? -1 : 1);
      if (WorkingDayTools.isWorkingDay(dateWithoutTime, region)) {
        counter--;
      }
      daysAdded++;
      console.log('addWorkingDays');
    }
    return DateTools.addDays(date, isNegative ? daysAdded * -1 : daysAdded);
  }

  static addDaysUntilWorkingDay(date: any, region: 'nw' | 'he' | 'by' | 'sp' | 'ch') {
    let counter = 0;
    while (true) {
      if (counter > 100) {
        throw new Error('getNextWorkingDay counter > 100');
      }
      if (WorkingDayTools.isWorkingDay(DateTools.addDays(date, counter), region)) {
        return DateTools.addDays(date, counter);
      }
      counter++;
    }
  }

  static isWorkingDay(date: any, region: 'nw' | 'he' | 'by' | 'sp' | 'ch') {
    if (region === 'sp') {
      return true;
    }
    if (DateTools.getDayOfWeek(date) === DayOfWeek.Sunday) {
      return false;
    }
    if (PublicHolidaysTools.getPublicHoliday(date, region)) {
      return false;
    }
    return true;
  }

  public static test() {
    console.log('start WorkingDay-Tools test');
    const sunday = DateTools.getDayOfWeek(DateTools.parse('2022-07-10', 'yyyy-MM-dd'));
    console.log(sunday);

    const result1 = WorkingDayTools.addWorkingDays(DateTools.parse('2022-07-07', 'yyyy-MM-dd'), 3, 'nw');
    const result1String = DateTools.format(result1, 'yyyy-MM-dd');
    if (result1String === '2022-07-11') {
      console.log('test 1 ok');
    } else {
      console.error('WorkingDayTest1 failed! Result = ' + result1String);
    }

    const result2 = WorkingDayTools.addWorkingDays(DateTools.parse('2022-07-07', 'yyyy-MM-dd'), -4, 'nw');
    const result2String = DateTools.format(result2, 'yyyy-MM-dd');
    if (result2String === '2022-07-02') {
      console.log('test 2 ok');
    } else {
      console.error('WorkingDayTest2 failed! Result = ' + result2String);
    }

    const result3 = WorkingDayTools.addWorkingDays(DateTools.parse('2022-04-14', 'yyyy-MM-dd'), 2, 'nw');
    const result3String = DateTools.format(result3, 'yyyy-MM-dd');
    if (result3String === '2022-04-19') {
      console.log('test 2 ok');
    } else {
      console.error('WorkingDayTest3 failed! Result = ' + result3String);
    }

  }
}
