import {AbstractControl, FormArray, FormGroup, UntypedFormArray, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {DialogService} from './dialog.service';
import {keys} from '../common-browser/helpers/object.tools';

export class FormTools {

  public static getErrors(form: UntypedFormGroup): string[] {
    let errors = [];
    form.markAllAsTouched();
    for (const key in form.controls) {
      if (form.controls.hasOwnProperty(key)) {
        const control = form?.get(key);
        if (control instanceof UntypedFormArray) {
          for (const formGroup of (control as UntypedFormArray).controls) {
            errors = errors.concat(this.getErrors(formGroup as UntypedFormGroup));
          }
        } else if (control instanceof UntypedFormGroup) {
          errors = errors.concat(this.getErrors(control as UntypedFormGroup));
        } else {
          if (control?.errors) {
            errors.push((control as any).name ?? key);
          }
        }
      }
    }
    return errors;
  }

  public static showErrorsTrueIfNoError(form: UntypedFormGroup, dialogService: DialogService, additionalErrors: string[] = []) {
    form.markAllAsTouched();
    let errors = FormTools.getErrors(form);
    if (additionalErrors?.length > 0) {
      errors = [...additionalErrors, ...errors];
    }
    if (errors.length > 0) {
      /*if (errorMapping) {
        errors = errors.map(er => errorMapping[er] ?? er);
      }*/
      requestAnimationFrame(() => {
        dialogService.showOk('Folgende Eingaben fehlen:\n- ' + errors.join('\n- '));
      });
      return false;
    }
    return true;
  }

  static setForm(form: FormGroup<any>, data: any, getControlsFns: { [key: string]: (data) => FormGroup } = {}) {
    for (const key of keys(form.controls)) {
      if (form.controls[key] instanceof FormArray) {
        (form.controls[key] as FormArray).controls.length = 0;
        if (!data[key]) {
          data[key] = [];
        }
        for (const item of data[key]) {
          (form.controls[key] as FormArray).controls.push(getControlsFns[key](item));
        }
      } else {
        if (form.controls[key] instanceof FormGroup) {
          FormTools.setForm(form.controls[key], data[key]);
        } else {
          if (data === undefined) {
            form.controls[key].setValue(null);
          } else {
            form.controls[key].setValue(data[key]);
          }
        }
      }
    }
  }

  static setFromFormArray(formArray: FormArray<any>, data: any, getControlsFns: { [key: string]: (data) => FormGroup } = {}) {
    for (const formGroup of formArray.controls) {
      FormTools.setForm(formGroup as FormGroup, data, getControlsFns);
    }
  }

  static setValidators(control: AbstractControl | undefined, validators: ValidatorFn | ValidatorFn[] | null) {
    if (!control) {
      return;
    }
    control.setValidators(validators);
    control.updateValueAndValidity({emitEvent: false});
  }
}
