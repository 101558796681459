import {Component, OnInit, Optional} from '@angular/core';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtWhatsAppFastAnswer} from '../../../common-interfaces/nxt-whatsapp-text.interface';
import {DialogService} from '../../../services/dialog.service';
import {WhatsappFastAnswerEditComponent} from './whatsapp-fast-answer-edit/whatsapp-fast-answer-edit.component';
import {ConfigService} from '../../../services/config.service';
import {KeyCode, ShortcutService} from '../../../services/shortcut.service';
import {NxtComponent} from '../../nxt.component';
import {firstValueFrom} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {LocalStorageService} from '../../../services/local-storage.service';
import {LoginService} from '../../../services/login.service';
import {HighlightPipe} from '../../../pipes/highlight-pipe';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {PermissionDirective} from '../../../directives/permission.directive';
import {RadioComponent} from '../../form-controls/radio/radio.component';
import {InputComponent} from '../../form-controls/input/input.component';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-whatsapp-fast-answers',
    templateUrl: './whatsapp-fast-answers.component.html',
    styleUrls: ['./whatsapp-fast-answers.component.scss'],
    imports: [FlexModule, NgIf, InputComponent, RadioComponent, PermissionDirective, NgFor, NgClass, ExtendedModule, NxtButtonIconComponent, NxtButtonComponent, SafeHtmlPipe, HighlightPipe]
})

export class WhatsappFastAnswersComponent extends NxtComponent implements OnInit {
  public whatsappFastAnswers: (NxtWhatsAppFastAnswer & { filterCounter?: number })[] = [];
  public whatsappFastAnswersFiltered: (NxtWhatsAppFastAnswer & { filterCounter?: number })[] = [];
  public currentLang = 'de';
  public langs = this.configService.config.value.langs;
  public quickFilter = '';

  public contact: any;

  public mobile: string;

  public selectIndex = 0;

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
    private socketService: SocketService,
    private dialogService: DialogService,
    private configService: ConfigService,
    private shortcutService: ShortcutService,
    private storageService: LocalStorageService,
    private loginService: LoginService
  ) {
    super();
    this.load();
    this.pushSubscription = this.shortcutService.onKeyPress.subscribe((key) => {
      if (key === KeyCode.Esc) {
        this.dialogRef.close();
      }
      if (this.contact || this.mobile || true) {
        if (key === KeyCode.Down) {
          this.selectIndex++;
          document.querySelector('#item_' + this.selectIndex)?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
          if (this.selectIndex >= this.whatsappFastAnswersFiltered.length) {
            this.selectIndex = this.whatsappFastAnswersFiltered.length - 1;
          }
        } else if (key === KeyCode.Up) {
          this.selectIndex--;
          if (this.selectIndex < 0) {
            this.selectIndex = 0;
          }
          document.querySelector('#item_' + this.selectIndex)?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
        } else if (key === KeyCode.Enter) {
          this.send();
        }
      }
    });
  }

  setFilter() {
    this.selectIndex = 0;
    this.whatsappFastAnswers.forEach(wfa => {
      if (wfa.shortcut.toLowerCase().startsWith(this.quickFilter.toLowerCase())) {
        wfa.filterCounter = 100;
      } else if (wfa.shortcut.toLowerCase().includes(this.quickFilter.toLowerCase())) {
        wfa.filterCounter = 50;
      } else if (wfa.texts[this.currentLang].toLowerCase().includes(this.quickFilter.toLowerCase())) {
        wfa.filterCounter = 30;
      } else {
        wfa.filterCounter = 0;
      }
    });

    this.whatsappFastAnswersFiltered = this.whatsappFastAnswers.filter(w => w.filterCounter > 0)
      .sort((w1, w2) => {
        if (w1.filterCounter > w2.filterCounter) {
          return -1;
        } else if (w1.filterCounter < w2.filterCounter) {
          return 1;
        } else if (w1.shortcut.length < w2.shortcut.length) {
          return -1;
        } else if (w1.shortcut.length > w2.shortcut.length) {
          return 1;
        }
      });
    if (this.contact) {
      this.selectIndex = 0;
    }
  }

  async load() {
    this.whatsappFastAnswers = await this.socketService.getWhatsappFastAnswers();
    this.setFilter();
    if (!this.contact && !this.mobile) {
      this.selectIndex = -2;
    }
  }

  ngOnInit() {

  }


  async createNew() {
    const dialog = this.dialogService.showComponentDialog(WhatsappFastAnswerEditComponent, {
      whatsappFastAnswer: null,
      allWhatsappFastAnswers: this.whatsappFastAnswers,
      isNew: true
    });
    await firstValueFrom(dialog.afterClosed());
    this.load();
  }

  private async send() {
    if (this.selectIndex < 0) {
      return;
    }
    let mobile = '';
    if (this.contact?.mobileFormatted) {
      mobile = this.contact?.mobileFormatted;
    } else if (this.mobile) {
      mobile = this.mobile;
    }

    if (mobile) {
      if (!this.whatsappFastAnswersFiltered[this.selectIndex]) {
        return; // throw Error('Markierten Text nicht gefunden\nIndex: ' + this.selectIndex);
      }
      let text = this.whatsappFastAnswersFiltered[this.selectIndex].texts[this.currentLang];
      text = this.replacePlaceholders(text);
      this.dialogRef.close({text, whatsappFastAnswers: this.whatsappFastAnswers});
    } else {
      this.dialogService.showOk('Kontakt konnte nicht geladen werden');
    }
  }

  public async edit(whatsappText: NxtWhatsAppFastAnswer) {
    const dialog = this.dialogService.showComponentDialog(WhatsappFastAnswerEditComponent, {
      whatsappFastAnswer: whatsappText,
      allWhatsappFastAnswers: this.whatsappFastAnswers
    });
    await firstValueFrom(dialog.afterClosed());
    this.load();
  }

  private replacePlaceholders(text: string) {
    return text.replaceAll('{{paypal-email}}', this.configService.config.value.paypalEmail);
  }

  nxtOnDestroy(): void {
  }
}
