@if (document(); as document) {
  <div class="flex flex-row justify-end w-full">
    <nxt-button-icon (click)="dialogRef.close()">close</nxt-button-icon>
  </div>
  <div class="flex flex-row text-[70%]">

    <div class="w-fit p-3">
      <table class="nxt-table">
        @for (entity of entities(); track entity) {
          <tr>
            <td>{{ entity.key }}</td>
            <td [innerHTML]="entity.value | safeHtml"></td>
          </tr>
        }
      </table>
    </div>
    @if (document.nxtAi?.manualChanges?.length > 0) {
      <div class="flex text-[85%] flex-col gap-4">
        @for (change of document.nxtAi.manualChanges; track change) {
          <table class="nxt-table">
            <tr>
              <td [colSpan]="2" class="text-center">{{ change.by }} {{ change.at | nxtDate: 'dd.MM.yyyy HH:mm' }}</td>
            </tr>
            @for (itemChange of change.changes; track itemChange.key) {
              <tr>
                <td>{{ itemChange.key }}</td>
                <td>{{ itemChange.oldValue }} ➞ {{ itemChange.newValue }}</td>
              </tr>
            }
          </table>
        }
      </div>
    }
    <div class="w-fit p-3" [innerHTML]="document | jsonFormatted">
    </div>
    <div class="w-fit p-3" [innerHTML]="documentText() | safeHtml">
    </div>
  </div>
}
