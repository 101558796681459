@if (eventQrCodePreData(); as eventQrCodePreData) {
  <div class="w-full nxt-page h-100" fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div class="pb-2 text-250" style="text-align: center">Hello {{ eventQrCodePreData.artist }}!</div>
      @if (type() === 'stencil') {
        <div class="font-bold text-[150%] pb-4">Stencil</div>
        <div class="text-170" style="text-align: center" [innerHTML]="eventQrCodePreData.stencilInstruction"></div>
      }
      @if (type() === 'preTattoo') {
        <div class="font-bold text-[150%] pb-4">Pre-Tattoo</div>
        <div class="text-170" style="text-align: center" [innerHTML]="eventQrCodePreData.preTattooInstruction"></div>
      }
      @if (type() === 'tattoo') {
        <div class="font-bold text-[150%] pb-4">Tattoo</div>
        <div class="text-170" style="text-align: center" [innerHTML]="eventQrCodePreData.tattooInstruction"></div>
        <br/>
        <div class="text-250 red animate__animated animate__tada center" style="text-align: center" [innerHTML]="eventQrCodePreData.minText"></div>
      }
    </div>
  </div>
}
