<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>Mitarbeiter-Kalender</nxt-page-header-title>
    <div class="flex flex-row gap-3 items-center">
      <nxt-date-picker-2
        [jumpWeeks]="true"
        [noPadding]="true"
        [jumpToday]="true"
        [value]="dateString()"
        (valueChange)="datePickerChanged($event)"
      />
      @if (studioOptions().length > 1) {
        <mat-button-toggle-group class="small-toggle-group" [(ngModel)]="studioFilter" [multiple]="true">
          @for (studioOption of studioOptions(); track studioOption.value) {
            <mat-button-toggle [value]="studioOption.value">{{ studioOption.text }}</mat-button-toggle>
          }
        </mat-button-toggle-group>
      }
      <nxt-slide-toggle [(value)]="showTimeTracks" (valueChange)="showTimeTracksChanged()">Stempeln</nxt-slide-toggle>

      @if (!showTimeTracks()) {
        <mat-button-toggle-group class="small-toggle-group" [(ngModel)]="eventTypeFilter" [multiple]="true">
          <mat-button-toggle value="work">Arbeit</mat-button-toggle>
          <mat-button-toggle value="vacation">Urlaub</mat-button-toggle>
          <mat-button-toggle value="sick">Krank</mat-button-toggle>
          <mat-button-toggle value="notHere">Frei</mat-button-toggle>
        </mat-button-toggle-group>
      }
      @if (!showTimeTracks()) {
        <mat-button-toggle-group class="small-toggle-group" [(ngModel)]="workplaceFilter" [multiple]="true">
          @for (workplace of workplaceService.employeeWorkplaceOptions; track workplace.value) {
            <mat-button-toggle [value]="workplace.value">{{ workplace.text }}</mat-button-toggle>
          }
        </mat-button-toggle-group>
      }
    </div>

  </nxt-page-header>
  <nxt-page-content>
    <div class="flex flex-col justify-center items-center w-full pb-2">
      <div>
        <nxt-input placeholder="Suche" [clearIcon]="true" class="w-[300px]" [(value)]="quickFilterText" (valueChange)="quickFilterTextChanged()"></nxt-input>
      </div>
      @if (false && employeeChecks(); as employeeChecks) {
        <table class="nxt-table nxt-table-small-padding w-fit text-[80%]">
          <tr>
            <td>Mitarbeiter</td>
            @for (check of employeeChecks.checks; track check.employeeId) {
              <td>{{ check.employeeName }}</td>
            }
          </tr>
          <tr>
            <td>Geplant</td>
            @for (check of employeeChecks.checks; track check.employeeId) {
              <td>{{ check.plannedH }}</td>
            }
          </tr>
          <tr>
            <td>Soll</td>
            @for (check of employeeChecks.checks; track check.employeeId) {
              <td>{{ check.shouldH }}</td>
            }
          </tr>
          <tr>
            <td>Differenz</td>
            @for (check of employeeChecks.checks; track check.employeeId) {
              <td [class.red]="check.doTimeTrack && check.plannedH - check.shouldH < 0">{{ check.plannedH - check.shouldH }}</td>
            }
          </tr>
        </table>
      }
    </div>


    <mwl-calendar-week-view
      #calendarWeekViewComponent
      class="h-full flex w-full o-hidden"
      [daysInWeek]="7"
      [viewDate]="date()"
      [dayStartHour]="startHour()"
      [dayEndHour]="endHour()"
      [headerTemplate]="headerTemplate"
      [hourSegmentTemplate]="hourSegmentTemplate"
      [eventTemplate]="eventTemplate"
      (hourSegmentClicked)="hourSegmentClicked($event)"
      [events]="eventsFiltered()">
    </mwl-calendar-week-view>

    <ng-template #hourSegmentTemplate let-segment="segment" let-segmentHeight="segmentHeight" let-isTimeLabel="isTimeLabel" let-daysInWeek="daysInWeek">
      <nxt-calendar-hour-segment
        [cssClass]="segment.cssClass"
        [date]="segment.date"
        [isStart]="segment.isStart"
        [segmentHeight]="segmentHeight"
        [isTimeLabel]="isTimeLabel"
        [daysInWeek]="daysInWeek"
        [daysCount]="7"
        [dayStartHour]="startHour()"
        [disableTooltip]="false">
      </nxt-calendar-hour-segment>
    </ng-template>

    <ng-template #headerTemplate let-days="days" let-locale="locale">
      <nxt-employee-calendar-header
        [employeesFreeDays]="employeesFreeDays()"
        [events]="employeeEvents"
        [days]="days"
      />
    </ng-template>

    <ng-template #eventTemplate let-weekEvent="weekEvent">
      <nxt-employee-calendar-event (click)="eventClicked(weekEvent.event)" [event]="weekEvent.event"></nxt-employee-calendar-event>
    </ng-template>
  </nxt-page-content>
  <nxt-page-footer>

  </nxt-page-footer>
</nxt-page>
