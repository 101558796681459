<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>Messenger</nxt-page-header-title>

  </nxt-page-header>
  <nxt-page-content>
    <div class="flex flex-col">
      <div class="flex flex-row">
        <nxt-date-picker-2 placeholder="von" [(value)]="from"></nxt-date-picker-2>
        <nxt-date-picker-2 placeholder="bis" [(value)]="till"></nxt-date-picker-2>
      </div>
      <nxt-button (click)="from.set('2024-06-01');till.set('2024-06-30')">06 2024</nxt-button>
      <nxt-button (click)="from.set('2024-07-01');till.set('2024-07-31')">07 2024</nxt-button>
      <nxt-button (click)="from.set('2024-08-01');till.set('2024-08-31')">08 2024</nxt-button>
      <nxt-button (click)="startClicked()">start</nxt-button>
      <div>
        @for (log of logs(); track log) {
          <div>{{ log }}</div>
        }
      </div>
    </div>

  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>





