import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../services/socket/socket.service';
import {NxtDbFields} from '../../common-interfaces/nxt.db-fields.interface';
import {Binary} from '@angular/compiler';


export type Nxt1001Font = NxtDbFields & {
  fontUrl: string,
  page: number;
  url: string;
  name: string;
  downloadUrl: string;
  file?: Binary,
  fileExtension: string;
  fontFamily: string;
};

@Component({
  selector: 'nxt-fonts',
  templateUrl: './fonts.component.html',
  styleUrls: ['./fonts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})

export class FontsComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);

  fonts = signal<Nxt1001Font[]>([]);

  constructor() {
    super();
  }

  ngOnInit() {
    this.load();

  }

  async load() {
    const fonts = await this.socketService.getFonts();
    this.fonts.set(fonts);
    this.loadFonts();
  }

  nxtOnDestroy() {
  }

  loadFonts() {
    for (const font of this.fonts()) {
      let url = '/nxt-font/' + encodeURIComponent(font.name) + '.' + font.fileExtension;
      if (window.location.hostname === 'localhost') {
        url = 'http://localhost:444/nxt-font/' + encodeURIComponent(font.name) + '.' + font.fileExtension;
      }

      const fontFace = new FontFace(font.name, 'url(' + url + ')');

      fontFace.load().then(loadedFont => {
        (document as any).fonts.add(loadedFont);
      }).catch(function(error) {
        console.error('Schriftart konnte nicht geladen werden:', error);
      });
    }
  }
}
