import {Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {IconTools} from '../../../common-browser/helpers/icon.tools';
import {DialogService} from '../../../services/dialog.service';
import {StudioFeeCostEditComponent} from './studio-fee-cost-edit/studio-fee-cost-edit.component';
import {firstValueFrom} from 'rxjs';
import {NxtStudioFeeCost} from '../../../common-interfaces/nxt.studio-fee.interface';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {MatDialogRef} from '@angular/material/dialog';
import {clone} from '../../../common-browser/helpers/object.tools';
import {NgIf} from '@angular/common';


@Component({
    selector: 'nxt-studio-fee-costs',
    templateUrl: './studio-fee-costs.component.html',
    styleUrls: ['./studio-fee-costs.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NxtDatagridComponent,
    NxtButtonComponent,
    NgIf
  ]
})

export class StudioFeeCostsComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  costs = signal<NxtStudioFeeCost[]>([]);
  canEdit = signal(true);
  title = signal('');
  isSafe = signal(false);

  /*** Injections ***/
  dialogService = inject(DialogService);
  dialogRef = inject(MatDialogRef<StudioFeeCostsComponent>);

  costTypes = [
    'Gehalt', 'Miete', 'Reinigung', 'Nebenkosten'
  ];
  columnDefs: NxtColDef<NxtStudioFeeCost>[] = [
    {headerName: '', nxtFieldType: NxtFieldType.Icon, nxtIcon: IconTools.Material.Edit, nxtOnCellClicked: params => this.editClicked(params.data), hide: !this.canEdit()},
    {headerName: '', nxtFieldType: NxtFieldType.Icon, nxtIcon: IconTools.Material.Delete, nxtOnCellClicked: params => this.deleteClicked(params.data), hide: !this.canEdit()},
    {headerName: 'Beschreibung', field: 'description', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Betrag', field: 'value', nxtFieldType: NxtFieldType.Money, maxWidth: 100, minWidth: 100},
  ];


  constructor() {
    super();
  }

  setData(costs: NxtStudioFeeCost[], canEdit: boolean, title: string, isSafe: boolean) {
    this.costs.set(costs || []);
    this.title.set(title);
    this.canEdit.set(canEdit);
    this.isSafe.set(isSafe);
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  private async editClicked(cost: NxtStudioFeeCost) {
    const dialog = this.dialogService.showComponentDialog(StudioFeeCostEditComponent);
    dialog.componentInstance.setData(cost, this.isSafe());
    const result = await firstValueFrom(dialog.afterClosed());
    if (result) {
      this.costs.update(costs => {
        costs = costs.filter(c => c.id !== cost.id);
        costs.push(result);
        return costs;
      });
    }
  }

  addNewCostClicked() {
    this.editClicked({id: '', value: null, description: ''});
  }

  closeClicked() {
    this.dialogRef.close(this.costs());
  }

  testAdd() {
    this.costs.update(costs => {
      costs.push({id: 'abc', description: 'Test abc hier dies', value: 1299.91});
      return clone(costs);
    });
  }

  private async deleteClicked(cost: NxtStudioFeeCost) {
    const deleteIt = await this.dialogService.showYesNo(cost.value.toMoneyString() + ' ' + cost.description + ' löschen?');
    if (deleteIt) {
      this.costs.update(costs => {
        return costs.filter(c => c.id !== cost.id);
      });
    }
  }

}
