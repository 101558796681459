import {inject, Injectable} from '@angular/core';
import {DialogService} from '../dialog.service';
import {ShopOrderViewComponent} from '../../components/shop-orders/shop-order-view.component';

@Injectable({
  providedIn: 'root'
})
export class ShopOrderService {
  /*** Injections ***/
  dialogService = inject(DialogService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
    this.dialogService.showShopOrder.subscribe(async (shopOrderId) => {
      this.showShopOrder(shopOrderId);
    });
  }

  showShopOrder(shopOrderId: string) {
    const dialog = this.dialogService.showComponentDialog(ShopOrderViewComponent, {shopOrderId});
    dialog.componentInstance.load().then();
  }
}
