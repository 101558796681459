import {Component, OnInit, Optional, ViewChild} from '@angular/core';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NxtFormControl} from '../../../nxt-form/nxt.form-control';
import {FormTools} from '../../../services/form.tools';
import {DialogService} from '../../../services/dialog.service';
import {SocketService} from '../../../services/socket/socket.service';
import {EventComponent} from '../../../event/event.component';
import {getPermissionIdText, getPermissionIdTextDetail, NxtPermissionId, NxtUser} from '../../../common-interfaces/nxt.user.interface';
import {UserTools} from '../../../common-browser/helpers/user.tools';
import {ObjectTools} from '../../../common-browser/helpers/object.tools';
import {LoginService} from '../../../services/login.service';
import {PermissionService} from '../../../services/permission.service';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import moment from 'moment';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {MatTooltip} from '@angular/material/tooltip';
import {PermissionDirective} from '../../../directives/permission.directive';
import {SelectComponent} from '../../../components/form-controls/select/select.component';
import {DatePickerComponent} from '../../../components/form-controls/date-picker/date-picker.component';
import {SlideToggleComponent} from '../../../components/form-controls/slide-toggle/slide-toggle.component';
import {InputComponent} from '../../../components/form-controls/input/input.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf} from '@angular/common';
import {debounceTime, Subject} from 'rxjs';
import {NxtComponent} from '../../../components/nxt.component';
import {MatIcon} from '@angular/material/icon';

interface PermissionModule {
  show?: boolean;
  text: string;
  permissions: {
    show?: boolean;
    id: NxtPermissionId,
    granted?: boolean;
    onlySuperAdminCanChange?: boolean;
  }[];
}

@Component({
  selector: 'nxt-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  imports: [NgIf, FlexModule, InputComponent, SlideToggleComponent, DatePickerComponent, SelectComponent, PermissionDirective, NgFor, MatTooltip, NxtButtonComponent, MatIcon],
  standalone: true,
})
export class UserEditComponent extends NxtComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogService: DialogService,
    private socketService: SocketService,
    private loginService: LoginService,
    private permissionService: PermissionService,
    @Optional() public dialogRef: MatDialogRef<EventComponent>,
  ) {
    super();
    this.permissionModules = [
      {
        text: 'Allgemein', permissions: [
          {id: NxtPermissionId.Admin, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.SuperAdmin, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.ShowDurationPriceCheckColumn},
          {id: NxtPermissionId.DisableGlobal_, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.ExcelExport, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.DefaultDashboard, onlySuperAdminCanChange: true},
        ],
      },
      {
        text: 'Gutscheine', permissions: [
          {id: NxtPermissionId.GiftCard_SeeCodes, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.GiftCard_ForceAllGiftCards},
          {id: NxtPermissionId.GiftCards_Download},
        ],
      }, {
        text: 'Kassen', permissions: [
          {id: NxtPermissionId.MoneyAccount_Admin, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.MoneyAccount_Transactions, onlySuperAdminCanChange: true},
        ],
      }, {
        text: 'Bank', permissions: [
          {id: NxtPermissionId.Bank_Transactions, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.Bank_Documents, onlySuperAdminCanChange: true},
        ],
      }, {
        text: 'Zahlungen', permissions: [
          {id: NxtPermissionId.CanRefund, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.NotAssignedPayments_View, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.NotAssignedPayments_Assign, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.NotAssignedPayments_Delete, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.NotAssignedBankTransfer_Assign, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.PayPalSMSCode},
          {id: NxtPermissionId.GoogleSMSCode},
        ],
      }, {
        text: 'Auswertungen', permissions: [
          {id: NxtPermissionId.Analysis_DiscountPromotions},
          {id: NxtPermissionId.Analysis_StudioFee, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.Analysis_Events, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.Analysis_EventsCreatedOrUpdated},
        ],
      }, {
        text: 'Artists',
        permissions: [
          {id: NxtPermissionId.Artists_Read},
          {id: NxtPermissionId.Artists_NewSpot, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.Artists_CanEditSkillsOutsideFromAc, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.Artists_DeleteNotes},
          {id: NxtPermissionId.Artists_EditKeySafeCode},
          {id: NxtPermissionId.Artist_SetCanEarlyPayout},
        ],
      }, {
        text: 'Mitarbeiter',
        permissions: [
          {id: NxtPermissionId.Employees_ViewEvents, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.Employees_EditEvents, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.Employees_EditTimeTracks, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.Employees_Works},
          {id: NxtPermissionId.Employees_Edit, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.Employees_Payout, onlySuperAdminCanChange: true},
        ],
      }, {
        text: 'Menü',
        permissions: [
          {id: NxtPermissionId.DiscountPromotionsEdit},
          {id: NxtPermissionId.DayFinishLog, onlySuperAdminCanChange: true},
        ],
      }, {
        text: 'Kalender',
        permissions: [
          {id: NxtPermissionId.Calendar},
        ],
      }, {
        text: 'Fotos',
        permissions: [
          {id: NxtPermissionId.Photos_Gallery},
        ],
      }, {
        text: 'Cash-Report', permissions: [
          {id: NxtPermissionId.StudioCashReport},
          {id: NxtPermissionId.StudioCashReport_DatePicker},
          {id: NxtPermissionId.StudioCashReport_ToggleBlockDayFinish, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.StudioCashReport_CanEditIncoming},
          {id: NxtPermissionId.StudioCashReport_CanEditOutgoing},
          {id: NxtPermissionId.StudioCashReport_SwitchStudios},
          {id: NxtPermissionId.StudioCashReport_Switch_},
          {id: NxtPermissionId.StudioCashReport_SeeMoneyEndOfDay},
          {id: NxtPermissionId.StudioCashReport_EditIncomingOutgoing_CanEdit_, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.StudioCashReport_CanEditGiftCard},
          {id: NxtPermissionId.StudioCashReport_ShowAllCashRegisters},
          {id: NxtPermissionId.StudioCashReport_CanEditEndMoney, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.StudioCashReport_PrePayedOutInvoices},
          {id: NxtPermissionId.StudioCashReport_CanSetWalkIn},
        ],
      }, {
        text: 'Termin', permissions: [
          {id: NxtPermissionId.CalendarEventEdit_canMoveEventEarlierThan3Days},
          {id: NxtPermissionId.CalendarEventEdit_canEdit_},
          {id: NxtPermissionId.CalendarEventEdit_ChangeWorkType},
          {id: NxtPermissionId.CalendarEventEdit_DisableFollowEventInfo},
          {id: NxtPermissionId.CalendarEventEdit_SaveWithoutChecks},
          {id: NxtPermissionId.CalendarEventEdit_EditPayments, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.CalendarEventEdit_ShowBankDepositQrCode},
          {id: NxtPermissionId.CalendarEventEdit_canReOpen},
          {id: NxtPermissionId.EventPhoto_Delete},
          {id: NxtPermissionId.SkillCheck_Disable},
          {id: NxtPermissionId.CalendarEventEdit_setImproveOtherArtistCheckState},
        ],
      }, {
        text: 'Studio Abgaben', permissions: [
          {id: NxtPermissionId.StudioFee, onlySuperAdminCanChange: true},
          {id: NxtPermissionId.StudioFee_Admin, onlySuperAdminCanChange: true},
        ],
      }, {
        text: 'WhatsApp', permissions: [
          {id: NxtPermissionId.WhatsAppFastAnswers_Edit},
          {id: NxtPermissionId.WhatsAppBroadcast},
          {id: NxtPermissionId.WhatApp_ChatRecheck},
        ],
      }, {
        text: 'Benutzer', permissions: [
          {id: NxtPermissionId.EditUsers},
        ],
      }, {
        text: 'Inventar', permissions: [
          {id: NxtPermissionId.Inventory_CanDeleteAllOrders, onlySuperAdminCanChange: true},
        ],
      }, {
        text: 'Kunden', permissions: [
          {id: NxtPermissionId.ContactForm_WithoutBirthday},
          {id: NxtPermissionId.ContactsDelete},
        ],
      }, {
        text: 'Promo-App', permissions: [
          {id: NxtPermissionId.Promo_PromotersList, onlySuperAdminCanChange: true},
        ],
      }, {
        text: 'Piercing-Manager', permissions: [
          {id: NxtPermissionId.PiercingManager_AddInfo},
        ],
      }, {
        text: 'Tattoo-Bewertung', permissions: [
          {id: NxtPermissionId.EventRating_Edit},
          {id: NxtPermissionId.EventRating_Delete},
        ],
      },
    ];

    this.permissionModules = this.permissionModules.sort(SortTools.sortString('text'));
    if (!this.permissionService.hasPermission(NxtPermissionId.SuperAdmin) && !this.loginService.isJulian() && !this.loginService.isNiklas() && !this.loginService.isCapone()) {
      for (const permissionModule of this.permissionModules) {
        permissionModule.permissions = permissionModule.permissions.filter(p => p.id !== NxtPermissionId.SuperAdmin);
      }
    }

    this.pushSubscription = this.filterPermissions$.pipe(debounceTime(200)).subscribe(() => {
      this.applyFilter();
    });
    this.applyFilter();
  }

  filterPermissions$ = new Subject<void>();

  public getPermissionIdText = getPermissionIdText;
  public getPermissionIdTextDetail = getPermissionIdTextDetail;

  @ViewChild(NxtDatagridComponent) dataGrid: NxtDatagridComponent;
  user: NxtUser;
  form: UntypedFormGroup;
  color = '#fff';
  permissionModules: PermissionModule[];


  public isEdit = true;

  public currentUserIsSuperAdmin = this.permissionService.hasPermission(NxtPermissionId.SuperAdmin);

  /*colorChanged(value: any) {
    if (this.form.get('backgroundColor').value !== value) {
      this.form.get('backgroundColor').setValue(value);
    }
  }*/
  permissionSearchText = '';

  private applyFilter() {
    this.permissionModules.forEach(module => {
      if (module.text.toLowerCase().includes(this.permissionSearchText.toLowerCase())) {
        module.permissions.forEach(permission => {
          permission.show = true;
        });
        module.show = true;
      } else {
        module.permissions.forEach(permission => {
          permission.show = getPermissionIdText(permission.id).toLowerCase().includes(this.permissionSearchText.toLowerCase());
        });
        module.show = module.permissions.some(p => p.show);
      }
    });
  }

  async ngOnInit() {

  }


  private buildFormFromUser(user: NxtUser) {
    this.form = this.formBuilder.group({
      username: new NxtFormControl(user.username, [ValidatorTools.requiredAndNotNaN], 'Username'),
      disableDailyLogin: new NxtFormControl(!!user.disableDailyLogin, []),
      realName: new NxtFormControl(user.realName, ValidatorTools.requiredAndNotNaN),
      color: new NxtFormControl('#bbb', ValidatorTools.requiredAndNotNaN),
      // backgroundColor: new NxtFormControl(user.backgroundColor, ValidatorTools.requiredAndNotNaN),
      cashReportCreator: new NxtFormControl(user.cashReportCreator, ValidatorTools.requiredAndNotNaN),
      startPage: new NxtFormControl(user.startPage, []),
      photoDeviceName: new NxtFormControl(user.photoDeviceName, []),
      workplaces: new NxtFormControl(user.workplaces, ValidatorTools.requiredAndNotNaN),
      disabled: new NxtFormControl(!!user.disabled, []),
      hidden: new NxtFormControl(!!user.hidden, []),
      birthday: new NxtFormControl(user.birthday ? moment(user.birthday) : null, [ValidatorTools.requiredAndNotNaN]),
      mobilePrivate: new NxtFormControl(user.mobilePrivate, [ValidatorTools.requiredAndNotNaN, ValidatorTools.mobile]),
      mobileBusiness: new NxtFormControl(user.mobileBusiness, [ValidatorTools.mobile]),
      noPerson: new NxtFormControl(!!user.noPerson),
      canSafeMoneyGet: new NxtFormControl(!!user.canSafeMoneyGet),
    });
    this.fillPermissionsToForm();
    this.registerFormChanges();
  }

  async save() {
    if (await this.checkFormErrors(this.user)) {
      const user = ObjectTools.combineWithDefaultOptions(this.form.getRawValue(), this.user);
      this.fillPermissionsToUser(user);
      user.birthday = DateTools.parse(user.birthday).dateFormat('yyyy-MM-dd');
      if (this.isEdit) {
        await this.socketService.upsertUser(user);
      } else {
        await this.socketService.createUser(user, '1234');
        this.dialogService.showOk('Das Passwort lautet: 1234, gib es genau so dem neuen Mitarbeiter, er wird es beim ersten einloggen änder müssen');
      }
      this.dialogRef.close(true);
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  private async checkFormErrors(user: NxtUser) {
    if (this.form.get('disabled').value) {
      return true;
    }
    this.form.markAllAsTouched();
    return FormTools.showErrorsTrueIfNoError(this.form, this.dialogService, await this.getAdditionalErrors(this.user));
  }

  private async getAdditionalErrors(user: NxtUser) {
    const errors: string[] = [];
    return errors;
  }

  private registerFormChanges() {
    this.form.get('noPerson').valueChanges.subscribe(value => {
      this.form.get('realName').setValidators(value ? [] : [ValidatorTools.requiredAndNotNaN]);
      this.form.get('realName').updateValueAndValidity();

      this.form.get('mobilePrivate').setValidators(value ? [] : [ValidatorTools.requiredAndNotNaN]);
      this.form.get('mobilePrivate').updateValueAndValidity();
    });
  }

  test() {
    const value = this.form.get('workplaces').value;
    console.log(value);
  }

  formGet(name: string) {
    return this.form.get(name) as UntypedFormControl;
  }

  async loadUser(userId: string) {
    this.isEdit = true;
    this.user = await this.socketService.getUser(userId);
    this.buildFormFromUser(this.user);
    console.log(this.user);
  }


  newUser() {
    this.isEdit = false;
    this.user = UserTools.getEmptyUser();
    this.buildFormFromUser(this.user);
  }

  private fillPermissionsToForm() {
    let availableIds: string[] = Object.keys(NxtPermissionId);

    for (const module of this.permissionModules) {
      for (const modulePermission of module.permissions) {
        const userPermission = this.user.permissions.find(p => p.id === modulePermission.id);
        if (!userPermission) {
          // throw Error('userPermission "' + modulePermission.id + '" nicht gefunden');
        }
        if (userPermission) {
          modulePermission.granted = userPermission.granted;
        }
        availableIds = availableIds.filter(id => {
          return id !== modulePermission.id && !['Empty', 'IsBackoffice', 'IsReception', 'IsJulian', 'IsNiklas', 'IsCapone', 'SuperAdmin', 'IsAc', 'IsDu', 'IsMa', 'IsNotAc', 'IsFranchise', 'IsNoFranchise'].includes(id);
          // return id !== modulePermission.id && id !== 'IsJulian' && id !== 'SuperAdmin' && id !== 'IsAc' && id !== 'IsNotAc'
        });
      }
    }
    if (availableIds.length > 0) {
      this.dialogService.showOk('Folgende Permissions sind nicht im Formular: ' + availableIds.join('\n'));
    }
  }

  private fillPermissionsToUser(user: NxtUser) {
    for (const module of this.permissionModules) {
      for (const modulePermission of module.permissions) {
        const userPermission = this.user.permissions.find(p => p.id === modulePermission.id);
        if (!userPermission) {
          user.permissions.push({
            id: modulePermission.id,
            granted: !!modulePermission.granted,
          });
        } else {
          userPermission.granted = !!modulePermission.granted;
        }
      }
    }
  }

  async delete() {
    if (await this.dialogService.showYesNo('Benutzer wirklich löschen?')) {
      await this.socketService.deleteUser(this.user.id);
      this.close();
    }
  }

  public newUserFrom(data: any) {
    this.isEdit = false;
    this.user = data;
    this.user.id = null;
    this.user.username = '';
    this.user.realName = '';
    this.user.lastLogin = 0;
    this.user.mobilePrivate = '';
    this.user.mobileBusiness = '';
    this.buildFormFromUser(this.user);
  }

  nxtOnDestroy(): void {
  }

  async resetPassword() {
    const newPw = await this.socketService.resetUserPassword(this.user.id);
    this.dialogService.showOk('Passwort wurde zurückgesetzt\nDas neue Passwort lautet: ' + newPw);
  }
}

