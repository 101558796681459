import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {VirtualScrollerComponent} from '../../../components/virtual-scoller/virtual-scroller';
import {NxtChat} from '../../../common-interfaces/message.interface';
import {MessengerChatListItemComponent} from './messenger-chat-list-item/messenger-chat-list-item.component';

@Component({
  selector: 'nxt-messenger-chat-list',
  templateUrl: './messenger-chat-list.component.html',
  styleUrls: ['./messenger-chat-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    VirtualScrollerComponent,
    MessengerChatListItemComponent,
  ], standalone: true,
})

export class MessengerChatListComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  chats = signal<NxtChat[]>([]);
  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }


}
