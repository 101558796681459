import {DecimalTools} from './decimal.tools';
import {
  NxtCalendarEvent,
  NxtCalendarEventBodyPutTattoo,
  NxtCalendarEventMediaCount,
  NxtCalendarEventSkill,
  NxtCalendarEventSmall,
} from '../../common-interfaces/nxt.calendar-event.interface';
import {DateTools} from './date.tools';
import {DurationTools} from './duration.tools';
import {PaymentTools} from './payment.tools';
import {MathTools} from './math.tools';
import {NanoIdTools} from './nano-id.tools';
import {keys} from './object.tools';
import {GoogleDriveFileInfo} from '../../common-interfaces/google-drive.interface';

export interface NxtImproveStatus {
  status: 'check' | 'free' | 'cost';
  text: string;
  label: string;
  label2: string;
}

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export class EventTools {

  public static AllEventsStart = '2018-01-01';

  static valenceGuideValue = 0;
  // static canceledMoveCalendarId = '';
  static fixArtistReasonOptions = [
    {value: '', text: 'Unbekannt'},
    {value: 'design', text: 'Design'},
    {value: 'follow-up', text: 'Folgetermin'},
    {value: 'customer', text: 'Kunde will'},
    {value: 'partner', text: 'Partner-Tattoo'},
  ];

  static motiveOrBodyPutBlackList = [
    'unklar',
    'unbekannt',
    'unknown',
    'steht noch nicht fest',
    'weiß noch nicht',
    'kommt noch',
    'mehrere motive',
    'noch zu entscheiden',
    'unentschieden',
    'in Klärung',
    'abzuwarten',
    'wird noch geprüft',
    'offen',
    'unbestimmt',
    'noch offen',
    'in Bearbeitung',
    'fraglich',
    'hat das Design bereits',
    'wird mit artist besprochen',
    '2 motive',
    '3 motive',
    '4 motive',
    '5 motive',
  ];

  public static calendarEventsToHtmlTable(events: NxtCalendarEvent[]) {
    let html = '<table style="width:100%; font-family: \'Roboto\',serif; border-collapse: collapse;"><tr>';
    let sum = 0;
    const td = '<td style="border: 1px solid #2a2a2a;padding: 3px 5px">';
    html += '<tr>';
    html += `${td}</td>${td}Angelegt am</td>${td}Termin</td>${td}Type</td>${td}Wert</td>${td}Termin</td>${td}Termininfo</td>`;
    html += '</tr>';

    let counter = 0;
    for (const event of events) {
      counter++;
      const {startDateString, title, createdDateString} = event;
      html += '<tr>';
      html += `${td}${counter}</td>${td}${createdDateString}</td>${td}${startDateString}</td>${td}${title}</td>`;
      html += '</tr>';
      sum += 399;
    }

    html += `<tr>${td}<strong>${DecimalTools.toMoneyString(sum)}</strong></td>`;
    html += '</tr></table>';
    return html;
  }


  public static isPossible_(event: NxtCalendarEvent) {
    return true;
  }

  public static isWalkIn(event: NxtCalendarEvent, date: any) {
    const createdString = DateTools.format(event.createdAt, 'yyyy-MM-dd');
    return createdString === DateTools.format(date, 'yyyy-MM-dd') && event.createdByWorkplace === 'reception';

  }

  static isToday(event: NxtCalendarEvent) {
    return event.startString === DateTools.formatNow('yyyy-MM-dd');
  }

  static isClosedOverDue(event: NxtCalendarEvent) {
    const minDurationH = 3;
    const overDuePercentage = 25;
    const duration = event.end - event.start;
    if (!event.closed) {
      if (duration > DurationTools.DURATION_1HOUR * minDurationH) {
        const toLate = Date.now() - event.end;
        if (toLate > duration / 100 * overDuePercentage) {
          return true;
        }
      }
    }
    return false;
  }

  static getPercentageProcess(event: NxtCalendarEvent) {
    if (event.start > Date.now()) {
      return 0;
    } else if (event.end < Date.now()) {
      return 100;
    } else {
      const duration = event.end - event.start;
      const inProgressDuration = Date.now() - event.start;
      return Math.round(inProgressDuration / duration * 100);
    }
  }

  static hasMissingBodyPutsMotives(event: NxtCalendarEvent) {
    let result = false;
    if (event?.bodyPuts?.tattoo) {
      result = event.bodyPuts.tattoo.some(t => {
        return this.motiveOrBodyPutBlackList.some(b => t.motive.toLowerCase().includes(b.toLowerCase()));
      });
    }
    return result;
  }

  static hasMissingBodyPutsBodyPuts(event: NxtCalendarEvent) {
    let result = false;
    if (event?.bodyPuts?.tattoo) {
      result = event.bodyPuts.tattoo.some(t => {
        return this.motiveOrBodyPutBlackList.some(b => t.bodyPut.toLocaleString().includes(b.toLowerCase()));
      });
    }
    return result;
  }

  static hastMissingBodyPuts(event: NxtCalendarEvent) {
    return EventTools.hasMissingBodyPutsMotives(event) || EventTools.hasMissingBodyPutsBodyPuts(event);
  }

  static getSkillsCombined(event: NxtCalendarEvent): string[] {
    const skills = event.skill?.skills ? Object.keys(event.skill?.skills) : [];
    const boolSkills = event.skill?.boolSkills ? Object.keys(event.skill?.boolSkills) : [];
    return [...skills, ...boolSkills];
  }

  public static getImproveStatus(event: NxtCalendarEvent, otherEventsFromCustomer: NxtCalendarEvent[]): NxtImproveStatus {
    const alreadyImproveEvent = otherEventsFromCustomer.find(e => e.info.includes('Nachstechen vom ' + event.start.dateFormat('dd.MM.yyyy')));
    if (alreadyImproveEvent) {
      if (!alreadyImproveEvent.canceledInTime && alreadyImproveEvent.status === 'canceled') {
        return {
          status: 'cost',
          text: 'Nachstechtermin nicht früh genug abgesagt',
          label: 'kostenpflichtig',
          label2: 'Nachstechtermin am ' + alreadyImproveEvent.start.dateFormat('dd.MM.yyyy') + ' nicht früh genug abgesagt.',
        };
      } else {
        return {
          status: 'cost',
          text: 'Nachstechtermin bereits am ' + alreadyImproveEvent.start.dateFormat('dd.MM.yyyy') + ' gelegt.',
          label: 'kostenpflichtig',
          label2: 'Nachstechtermin bereits am ' + alreadyImproveEvent.start.dateFormat('dd.MM.yyyy') + ' gelegt.',
        };
      }
    }

    if (event.info.includes('Nachstechen')) {
      return {
        status: 'cost',
        text: 'Ist bereits ein Nachstechtermin gewesen',
        label: 'kostenpflichtig',
        label2: 'Ist bereits ein Nachstechtermin gewesen\n' + event.info,
      };
    }

    if (event.workType === 'tattoo') {
      if (event.start.dateAddMonths(6) < Date.now()) {
        return {
          status: 'cost',
          text: 'Das Tattoo ist älter als 6 Monate',
          label: 'kostenpflichtig',
          label2: '> 6 Monate',
        };
      }
      const blackList: string[] = ['hand', 'foot'];
      if (!event.bodyPuts?.tattoo || event.bodyPuts.tattoo.length === 0) {
        return {
          status: 'check',
          label: 'unbekannt',
          text: 'Es ist keine Körperstelle im Termin angegeben',
          label2: 'fehlende Angabe',
        };
      }
      const blackListCount = event.bodyPuts.tattoo.filter(bodyPut => bodyPut.bodyPut.some(b => blackList.includes(b))).length;
      const totalBodyPutsCount = event.bodyPuts.tattoo.length;
      if (blackListCount === totalBodyPutsCount) {
        // alle bodyPuts sind in der Blacklist
        return {
          status: 'cost',
          text: '',
          label: 'kostenpflichtig Körperstelle',
          label2: '',
        };
      } else {
        if (blackListCount === 0) {
          return {
            status: 'free',
            text: '',
            label: 'kostenlos',
            label2: '',
          };
        } else {
          return {
            status: 'check',
            label: 'prüfen',
            label2: '',
            text: 'Prüfe welche Körperstelle nachgestochen werden soll um zu entscheiden ob es kostenlos oder kostenpflichtig ist',
          };
        }
      }
    }
    return {
      status: 'cost',
      text: 'es ist kein Tattoo',
      label: '',
      label2: '',
    };
  }


  static getTattooOrPiercingCount(event: NxtCalendarEvent) {
    if (event.workType === 'tattoo') {
      return event.bodyPuts?.tattoo?.length || 0;
    } else if (event.workType === 'piercing') {
      return event.bodyPuts?.piercing?.length || 0;
    } else if (event.workType === 'beauty') {
      return event.bodyPuts?.beauty?.length || 0;
    }
    return 0;
  }

  /**
   * start ist führend!
   * end ist führend!
   * created ist führend!
   */
  static calcEvent(event: NxtCalendarEvent) {
    if (!EventTools.valenceGuideValue) {
      throw Error('Termin kann nicht berechnet werden, Wertigkeits-Index fehlt');
    }
    if (!event.start) {
      // throw Error('Termin kann nicht berechnet werden ohne Start');
    }
    if (!event.end) {
      // throw Error('Termin kann nicht berechnet werden ohne Ende');
    }
    // event.startDate = event.start.dateClearTime();
    event.startDateString = event.start.dateFormatDate();
    event.startString = event.start.dateFormat('yyyy-MM-dd HH:mm');
    // event.startTime = DurationTools.parse(event.start.dateFormat('HH:mm'));
    // event.endDate = event.end.dateClearTime();
    event.endString = event.end.dateFormat('yyyy-MM-dd HH:mm');
    // event.endTime = DurationTools.parse(event.end.dateFormat('HH:mm'));

    event.createdWeekDay = event.createdAt.dateFormat('dddd');
    event.createdDate = event.createdAt.clearTime();
    event.createdDateString = event.createdAt.dateFormatDate();
    event.duration = event.end - event.start;
    if (event.priceEstimatedFrom) {
      event.valence = event.priceEstimatedFrom / EventTools.valenceGuideValue;
    } else {
      event.valence = 0;
    }
    event.paymentSum = PaymentTools.getCustomerPayedSumFromPayments(event.payments);
    event.paymentSumAdditional = PaymentTools.getPaymentSumByPaymentType(event.payments, ['additional']);
    event.artistPaymentSum = PaymentTools.getPaymentSumByPaymentType(event.payments, ['payout']);
    event.paymentCheaperSum = PaymentTools.getPaymentSumByPaymentType(event.payments, ['cheaper']);
    event.paymentDepositBack = PaymentTools.getPaymentSumByPaymentType(event.payments, ['deposit-back']);
    const priceEstimatedFrom = event.priceEstimatedFrom ?? -1;
    event.toPay = MathTools.roundMoney(priceEstimatedFrom - event.paymentSum - event.paymentCheaperSum);
    if (event.toPay < 0) {
      event.toPay = 0;
    }
    const toCalcWithForPercentage = priceEstimatedFrom > event.paymentSum ? priceEstimatedFrom : (event.paymentSum - event.paymentSumAdditional);
    event.artistToGet = MathTools.roundMoney(event.artistPercentage ? ((toCalcWithForPercentage * event.artistPercentage / 100) - event.artistPaymentSum) : 0);
    event.artistTotalGet = MathTools.roundMoney(event.artistPercentage ? (toCalcWithForPercentage * event.artistPercentage / 100) : 0);
    if (event.paymentSumAdditional > 0.00) {
      event.artistToGet += event.paymentSumAdditional * 0.5;
      event.artistTotalGet += event.paymentSumAdditional * 0.5;
    }
    event.toPayToStudio = event.toPay - event.artistToGet;
    event.cashEndOfDay = PaymentTools.calcCashEndOfDayFromEvents(event);

    for (const payment of event.payments) {
      payment.eventId = event.id;
      if (!payment.createdAtDateString) {
        payment.createdAtDateString = DateTools.format(payment.createdAt, 'yyyy-MM-dd');
      }
      payment.artist = '';
      if (payment.paymentType !== 'deposit-back') {
        payment.artist = event.artist;
        payment.artistCalendarNumber = event.calendarNumber || '';
      }
      payment.eventId = event.id;
      payment.isPrivate = !!(event.visibility && event.visibility === 'private');
      payment.eventDate = event.startDateString || '';
      payment.eventInvoiceNumber = event.invoiceNumber ?? '';
    }
    if (!event.workType) {
      if (event.bodyPuts?.tattoo) {
        event.workType = 'tattoo';
      } else if (event.bodyPuts?.piercing) {
        event.workType = 'piercing';
      } else if (event.bodyPuts?.beauty) {
        event.workType = 'beauty';
      }
    }
    if (!event.workType && event.start < '2022-01-01'.dateParse()) {
      event.workType = 'tattoo';
    }
    if (!event.workType) {
      throw Error('Termin kann nicht gespeichert werden, workType fehlt\n' + event.id);
    }

    if (event.ratings && event.ratings.length > 0) {
      event.ratingValue = MathTools.round(event.ratings.reduce((acc, rating) => acc + rating.value, 0) / event.ratings.length, 1);
    }
    /*event.consentData = undefined;
    if (event.files && event.files.length > 0) {
      const consentFiles = event.files.filter(f => f.subType === 'consent');
      if (consentFiles.length > 0) {
        const allOk = consentFiles.find(c => c.consentData?.hasAllDates && c.consentData.hasAllCustomerSigns && c.consentData.hasArtistSign && c.consentData.correctPageCount)?.consentData;
        if (allOk) {

        }
        event.consentFileId = consentFiles[0].id;
      }
    }*/


    return event;
  }

  static getFixArtistReasonText(value: string | string[]) {
    if (Array.isArray(value)) {
      return value.map(v => EventTools.fixArtistReasonOptions.find(o => o.value === v)?.text || v).join(' & ');
    }
    return EventTools.fixArtistReasonOptions.find(o => o.value === value)?.text || value;
  }


  static getEmptyEvent(start = 0, end = 0) {
    const event: NxtCalendarEvent = {
      id: '',
      start,
      end,
      artist: '',
      title: '',
      description: '',
      updatedAt: Date.now(),
      paymentSum: 0,
      artistPaymentSum: NaN,
      payments: [],
      visibility: '',
      duration: 0,
      priceEstimatedFrom: NaN,
      artistPercentage: 50,
      depotDueDate: '',
      depotDueDateReason: '',
      mediaCount: {photo: 0, video: 0, pdf: 0, tattooVideo: 0, tattooPhoto: 0, stencil: 0, templatePhoto: 0, preTattooPhoto: 0},
      stencilSeen: {seen: false, seenAt: 0, seenBy: '', workplace: '', studio: ''},
      artistAssignedAt: null,
      startDateString: '',
      artistToGet: NaN,
      info: '',
      importantInfo: '',
      fastWalkInRegisteredAt: NaN,
      closed: false,
      toPay: NaN,
      artistTotalGet: NaN,
      nxtUpdateId: NanoIdTools.generateNxt(),
      skill: {skills: {}, boolSkills: {}},
      workType: 'tattoo',
      nextNxtUpdateId: '',
      status: 'future',
      priceFix: false,
      artistFix: false,
      calendarNumber: '',
      valence: 0,
      updatedBy: '',
      updatedByWorkplace: '',
      updatedByStudio: '',
      customer: '',
      createdBy: '',
      createdAt: Date.now(),
      artistFixReason: [],
      ratings: [],
      files: [],
      studio: '',
    };
    EventTools.calcEvent(event);
    return event;
  }

  static toSmall(e: NxtCalendarEvent): NxtCalendarEventSmall {
    return {
      id: e.id,
      start: e.start,
      end: e.end,
      title: e.title,
      artist: e.artist,
      customerObj: e.customerObj,
      status: e.status,
      priceEstimatedFrom: e.priceEstimatedFrom,
      artistFix: e.artistFix,
      priceEstimatedTill: e.priceEstimatedTill,
      priceFix: e.priceFix,
      workType: e.workType,
      bodyPuts: e.bodyPuts,
      duration: e.duration,
      valence: e.valence,
      importantInfo: e.importantInfo,
      fastWalkInNo: e.fastWalkInNo,
      paymentSum: e.paymentSum,
      createdAt: e.createdAt,
      canceledReason: e.canceledReason,
    };
  }

  static isEvent(obj: any): obj is NxtCalendarEvent {
    return obj && obj.id && obj.start && obj.end && obj.customer;
  }

  static getMissingSkills(eventSkill: NxtCalendarEventSkill, eventTattooBodyPuts: NxtCalendarEventBodyPutTattoo[], availableSkills: {
    skills: { description: string }[],
    boolSkills: { description: string }[]
  }) {
    const motiveDescriptions: string[] = [];
    eventTattooBodyPuts.forEach((tattoo: NxtCalendarEventBodyPutTattoo) => {
      motiveDescriptions.push(...tattoo.motive.split(' ').filter(m => !!m));
    });
    const availableSkillsStrings = [...availableSkills.skills.map(s => s.description.toLowerCase()), ...availableSkills.boolSkills.map(s => s.description.toLowerCase())];
    const currentSkills = [...keys(eventSkill.skills).map(s => s.toLowerCase()), ...keys(eventSkill.boolSkills).map(s => s.toLowerCase())];
    const currentSkillsOneString = currentSkills.join(' ');
    const notUsedSkills = availableSkillsStrings.filter(a => !currentSkills.includes(a));
    const missingSkills: string[] = [];
    for (const [index, motiveDescription] of motiveDescriptions.entries()) {
      if (notUsedSkills.includes(motiveDescription.toLowerCase())) {
        let isMissing = true;
        if (index > 0) {
          if (['keine', 'kein', 'ohne'].includes(motiveDescriptions[index - 1])) {
            isMissing = false;
          }
        }
        if (isMissing) {
          if (!currentSkillsOneString.toLowerCase().includes(motiveDescription.toLowerCase())) {
            missingSkills.push(motiveDescription);
          }
        }
      }
    }
    return missingSkills;
  }

  static getPriceText(event: NxtCalendarEvent) {
    if (!event.priceEstimatedFrom) {
      return 'fehlt';
    }
    if (!event.priceEstimatedTill) {
      if (event.priceFix) {
        return 'fix ' + event.priceEstimatedFrom.toMoneyString();
      }
      return event.priceEstimatedFrom.toMoneyString();
    }
    return event.priceEstimatedFrom.toMoneyString() + ' - ' + event.priceEstimatedTill.toMoneyString();
  }

  static getMediaCountFromFiles(files: GoogleDriveFileInfo[], event: NxtCalendarEvent): NxtCalendarEventMediaCount {
    const newMediaCount: NxtCalendarEventMediaCount = {
      video: files.filter(f => f.mimeType?.startsWith('video')).length,
      photo: files.filter(f => f.mimeType?.startsWith('image')).length,
      pdf: files.filter(f => f.mimeType?.startsWith('application/pdf')).length,
      stencil: files.filter(f => f.name.startsWith('stencil')).length,
      tattooVideo: files.filter(f => f.mimeType?.startsWith('video') && f.name.startsWith('tattoo')).length,
      tattooPhoto: files.filter(f => f.mimeType?.startsWith('image') && f.name.startsWith('tattoo')).length,
      templatePhoto: files.filter(f => f.mimeType?.startsWith('image') && f.name.startsWith('template')).length,
      preTattooPhoto: files.filter(f => f.mimeType?.startsWith('image') && f.name.startsWith('preTattoo')).length,
    };
    const oldTattooFotos = files.filter(f => f.mimeType?.startsWith('image') && /^\d{4}/g.test(f.name)).length;
    const oldTattooVideos = files.filter(f => f.mimeType?.startsWith('video') && /^\d{4}/g.test(f.name)).length;
    newMediaCount.tattooPhoto += oldTattooFotos;
    newMediaCount.tattooVideo += oldTattooVideos;
    return newMediaCount;
  }
}
