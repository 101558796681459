<div fxLayout="column">
  <div class="nxt-page-header">Neuer WhatsApp-Text</div>
  <div fxFlex>
    <nxt-input placeholder="Kürzel" [(value)]="whatsappFastAnswer.shortcut" (esc)="dialogRef.close()"></nxt-input>
    <nxt-radio direction="row" [(value)]="currentLang" [options]="langs" valueKey="value"></nxt-radio>
    <nxt-textarea rows="10" [(value)]="whatsappFastAnswer.texts[currentLang]"></nxt-textarea>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
    <nxt-button (click)="dialogRef.close()">Abbrechen</nxt-button>
    <nxt-button (click)="save()">Speichern</nxt-button>
  </div>
</div>
