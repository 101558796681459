import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {FlexModule} from 'ngx-flexible-layout';
import {SafeUrlPipe} from '../../pipes/safe-url.pipe';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';

@Component({
    selector: 'nxt-multi-view-2',
    templateUrl: './multi-view-2.component.html',
    styleUrls: ['./multi-view-2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatTabGroup,
    FlexModule,
    MatTab,
    SafeUrlPipe,
    MatButtonToggle,
    MatButtonToggleGroup
  ]
})

export class MultiView2Component extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  currentPage = '/calendar';

  pages: { name: string, url: string }[] = [
    {name: 'Kalender', url: '/calendar'},
    {name: 'CashReport', url: '/studio-cash-report-2'},
    {name: 'Broadcast', url: '/broadcast'},
    {name: 'Zahlen', url: '/studio-fee'},
  ];

  studios: { name: string, url: string }[] = [
    {name: 'Aachen', url: 'https://ac.nxt-lvl.ink'},
    {name: 'Düsseldorf', url: 'https://du.nxt-lvl.ink'},
    {name: 'Mallorca', url: 'https://ma.nxt-lvl.ink'},
    {name: 'Frankfurt', url: 'https://ffm.nxt-lvl.ink'},
    {name: 'Aschaffenburg', url: 'https://ab.nxt-lvl.ink'},
    {name: 'Nürnberg', url: 'https://nu.nxt-lvl.ink'},
  ];
  studioIndex = 0;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  buttonChanged() {

  }
}
