import {Injectable, signal} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenService {

  isMobile = signal(false);

  state = new BehaviorSubject<{
    isPortrait: boolean;
    isMobile: boolean;
  }>({
    isPortrait: false,
    isMobile: false,
  });

  constructor() {
    this.calc();
  }


  calc() {
    let isPortrait = false;
    let isMobile = false;
    if (window.innerWidth < window.innerHeight) {
      isPortrait = true;
      if (window.innerWidth < 768) {
        isMobile = true;
      }
    } else {
      if (window.innerWidth < 768) {
        isMobile = true;
      }
    }
    if (this.state.value.isMobile !== isMobile || this.state.value.isPortrait !== isPortrait) {
      this.state.next({isPortrait, isMobile});
      this.isMobile.set(isMobile);
    }
  }
}
