import {DefaultUrlSerializer, UrlTree} from '@angular/router';

export class CustomUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    // Wenn ein # in der URL ist, entfernen wir es
    if (url.startsWith('#/')) {
      debugger;
      url = url.substring(2);
    }
    return super.parse(url);
  }
}
