import {Component, OnInit, Optional, signal} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../services/dialog.service';
import {LoginService} from '../../services/login.service';
import {SafeResourceUrlPipe} from '../../pipes/safe-resource-url.pipe';
import {MatIcon} from '@angular/material/icon';
import {AnimateDirective} from '../../directives/animate.directive';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {DownloadService} from '../../services/download.service';

@Component({
  selector: 'nxt-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  imports: [FlexModule, NgIf, NxtButtonComponent, AnimateDirective, MatIcon, SafeResourceUrlPipe]
})
export class PdfViewerComponent implements OnInit {
  filename: string;
  pdfBase64: string;
  options: { showPrintLocalInfo?: boolean, askPrintCount?: boolean };
  pdfObjectUrl = signal('');

  constructor(
    @Optional() public dialogRef: MatDialogRef<PdfViewerComponent>,
    private socketService: SocketService,
    private dialogService: DialogService,
    private loginService: LoginService,
    private downloadService: DownloadService
  ) {
  }

  public async setData(base64: string, filename: string, options?: { showPrintLocalInfo?: boolean, askPrintCount?: boolean }) {
    this.pdfBase64 = base64;
    this.pdfObjectUrl.set(URL.createObjectURL(this.blobPdfFromBase64String(this.pdfBase64)));
    this.filename = filename;
    this.options = options;
  }

  public async setDataSrc(src: string, filename: string, options?: { showPrintLocalInfo?: boolean, askPrintCount?: boolean }) {
    this.pdfObjectUrl.set(src);
    this.filename = filename;
    this.options = options;
  }

  blobPdfFromBase64String(base64String: string) {
    const byteArray = Uint8Array.from(
      atob(base64String)
        .split('')
        .map(char => char.charCodeAt(0))
    );
    return new Blob([byteArray], {type: 'application/pdf'});
  }

  async download(): Promise<void> {
    const source = this.pdfObjectUrl();
    if (source.startsWith('http')) {
      this.downloadService.downloadUrl(source, this.filename);
    } else {
      const downloadLink = document.createElement('a');
      const fileName = this.filename;

      downloadLink.href = source;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }


  downloadOld() {
    const source = this.pdfObjectUrl();
    const downloadLink = document.createElement('a');
    const fileName = this.filename;

    downloadLink.href = source;
    downloadLink.download = fileName;
    downloadLink.click();
  }


  async ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  async print() {
    let printCount = 1;
    if (this.options?.askPrintCount) {
      printCount = await this.dialogService.showInput('Anzahl der Ausdrucke', {isNumber: true, okButtonText: 'Drucken'});
    }
    this.dialogService.showLoading('Wird gedruckt...');
    if (printCount) {
      for (let i = 1; i <= printCount; i++) {
        this.dialogService.updateLoadingText('Wird gedruckt...\n' + i + ' / ' + printCount);
        let result = await this.socketService.printPdf({studio: this.loginService.getStudio(), pdfBase64: this.pdfBase64});
        if (result === 'OK') {
          result = 'Erfolgreich zum Drucker gesendet';
        } else {
          this.dialogService.hideLoading();
          this.dialogService.showOk('Fehler beim Drucken:\n' + result);
          return;
        }
      }
      this.dialogService.hideLoading();
      this.dialogService.showOk('Erfolgreich gedruckt');
    }
  }
}
