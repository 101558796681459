import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../services/socket/socket.service';
import {NxtContact} from '../../common-interfaces/nxt.contact.interface';
import {ObjectGroupTools} from '../../common-browser/helpers/object-group.tools';
import {IconTools} from '../../common-browser/helpers/icon.tools';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {DialogService, LoadingId} from '../../services/dialog.service';
import {debounceTime, firstValueFrom, Subject} from 'rxjs';
import {ContactService} from '../../services/contact.service';
import {MatDialogRef} from '@angular/material/dialog';
import {clone, keys} from '../../common-browser/helpers/object.tools';
import {CalendarEventEdit2Component} from '../../pages/calendar-event-edit/calendar-event-edit-2/calendar-event-edit-2.component';
import {ConfigService} from '../../services/config.service';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {InputComponent} from '../form-controls/input/input.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {SpinnerComponent} from '../spinner/spinner.component';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';

@Component({
    selector: 'nxt-contacts-same-mobile',
    templateUrl: './contacts-same-mobile.component.html',
    styleUrls: ['./contacts-same-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, SpinnerComponent, NxtButtonComponent, InputComponent, NxtButtonIconComponent, NgFor, NgClass, ExtendedModule, SafeHtmlPipe, NxtDatePipe]
})

export class ContactsSameMobileComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    private contactService: ContactService,
    @Optional() private dialogRef: MatDialogRef<any>,
    private configService: ConfigService
  ) {
    super();
    this.setFilter$.pipe(debounceTime(2000)).subscribe(() => this.setFilter());
  }

  private newContactsAvailable = new EventEmitter<void>();

  contacts: NxtContact[];
  mobilesFiltered: { contacts: NxtContact[], mobile: string }[];
  mobiles: { contacts: NxtContact[], mobile: string }[];

  protected readonly IconTools = IconTools;
  // mainContacts: { [contactId: string]: boolean } = {};

  /*async load() {
    this.cdr.detectChanges();
    this.contacts = await this.socketService.getContacts();
    this.groupContacts();
    this.cdr.detectChanges();
    this.dialogService.hideLoading(LoadingId.LoadingContacts);
  }*/
  quickFilterText = '';
  deleteContact = false;
  contactsToDelete: { [contactId: string]: boolean } = {};

  setFilter$ = new Subject<void>();

  async ngOnInit() {
    this.registerOnNewContacts();
  }

  async setContacts(contacts: NxtContact[]) {
    this.cdr.detectChanges();
    this.contacts = contacts;
    this.groupContacts();
    this.cdr.detectChanges();
    this.dialogService.hideLoading(LoadingId.LoadingContacts);
    this.newContactsAvailable.emit();
  }

  nxtOnDestroy() {

  }

  private groupContacts() {
    this.contacts = this.contacts.filter(contact => !!contact.mobile);
    const byMobile = ObjectGroupTools.groupObjectArray(this.contacts, 'mobile');
    this.mobiles = ObjectGroupTools.groupToObjectArray(byMobile, 'mobile', 'contacts');
    for (const mobile of this.mobiles) {
      mobile.contacts = mobile.contacts.sortString('id');
      for (const contact of mobile.contacts) {
        if (contact.events.length === 0 && contact.canceledEvents.length === 0 && contact.closedEvents.length === 0) {
          (contact as any).noEvents = true;
        }
      }
      let hastSameNames = false;
      let hastSameBirthday = false;
      for (let i = 1; i < mobile.contacts.length; i++) {
        if (mobile.contacts[i].fullName === mobile.contacts[0].fullName) {
          hastSameNames = true;
          (mobile.contacts[i] as any).sameName = true;
        }
        if (mobile.contacts[i].birthday === mobile.contacts[0].birthday) {
          hastSameBirthday = true;
          (mobile.contacts[i] as any).sameBirthday = true;
        }
      }
      if (hastSameNames) {
        (mobile.contacts[0] as any).sameName = true;
      }
      if (hastSameBirthday) {
        (mobile.contacts[0] as any).sameBirthday = true;
      }
    }
    this.mobiles = this.mobiles.filter(mobile => mobile.mobile && mobile.contacts.length > 1);


    /*this.mobiles = this.mobiles.filter(mobile => {
      const sameOk = (mobile.contacts[0] as any).sameName && (mobile.contacts[0] as any).sameBirthday;
      const someContactNoEvents = mobile.contacts.some(c => c.events.length === 0 && c.canceledEvents.length === 0 && c.closedEvents.length === 0);
      return sameOk && someContactNoEvents;
    });*/


    this.mobiles = this.mobiles.sortString('mobile');
    this.setFilter();
  }


  showChatClicked(contact: NxtContact) {
    this.contactService.showWhatsAppChat(contact.id);
  }

  showHistoryClicked(contact: NxtContact) {

  }

  async showEventClicked(typeText: string, events?: { eventId: string, start: number, end: number }[]) {
    if (events && events.length > 0) {
      const buttons = events.map(e => ({text: DateTools.format(e.start, 'dd.MM.yyyy'), value: e.eventId}));
      const title = 'Welchen ' + typeText + ' Termin(e) möchtest du dir ansehen?';
      const result = await this.dialogService.showButtonChooser<string>({
        buttonRows: [buttons],
        title,
        text: '',
        minWidth: '80%',
        value: ''
      });
      if (typeof result !== 'string' && result?.value) {
        const dialog = this.dialogService.showComponentFull(CalendarEventEdit2Component);
        setTimeout(() => {
          dialog.componentInstance.loadEvent({eventId: result.value});
        }, 500);
      }
    }
  }

  async editClicked(contact: NxtContact) {
    const dialog = this.dialogService.showContactForm(contact);
    const result = await firstValueFrom(dialog.afterClosed());
    if (result) {
      this.dialogService.showLoading(LoadingId.LoadingContacts, 'Kontakte werden geladen...');
    }
  }

  async combineContactsClicked(contacts: NxtContact[]) {
    await this.dialogService.showCombineContacts(contacts.map(c => c.id));
    /*const mainContact = contacts[0];
    const otherContacts: NxtContact[] = contacts.filter(c => c !== mainContact);
    if (!otherContacts.some(o => o.canceledEvents.length > 0 || o.closedEvents.length > 0 || o.events.length > 0)) {
      // direkt kontakte löschen
      if (this.deleteContact) {
        for (const otherContact of otherContacts) {
          await this.deleteClicked(otherContact, true);
        }
      }
    } else {
      this.dialogService.showLoading(LoadingId.LoadingContacts, 'Termine werden verschoben...');
      await this.socketService.combineContacts(mainContact.id, otherContacts.map(c => c.id));
      if (this.deleteContact) {
        this.newContactsAvailable.pipe(first()).subscribe(async () => {
          for (const otherContact of otherContacts) {
            await this.deleteClicked(this.contacts.find(c => c.id === otherContact.id), true);
          }
        });
      }
    }*/
    this.cdr.detectChanges();
  }

  async deleteClicked(contact: NxtContact, force = false) {
    if ((contact.events && contact.events.length > 0) || (contact.canceledEvents && contact.canceledEvents.length > 0) || (contact.closedEvents && contact.closedEvents.length > 0)) {
      await this.dialogService.showOk('Kontakt ' + contact.fullName + ' kann nicht gelöscht werden, er hat noch Termine');
      return;
    }
    if (force || await this.dialogService.showYesNo(contact.fullName + ' wirklich löschen?')) {
      await this.socketService.deleteContact(contact.id);
      this.dialogService.showLoading(LoadingId.LoadingContacts, '"' + contact.fullName + '" wird gelöscht...');
    }
  }

  registerOnNewContacts() {
  }

  quickFilterTextChanged() {

    this.setFilter$.next();
  }

  private setFilter() {
    if (this.quickFilterText) {
      this.mobilesFiltered = this.mobiles.filter(m => {
        const mobileOk = m.mobile.toLowerCase().includes(this.quickFilterText.toLowerCase());
        const nameOk = m.contacts.some(c => c.fullName.toLowerCase().includes(this.quickFilterText.toLowerCase()));
        return mobileOk || nameOk;
      });
    } else {
      this.mobilesFiltered = clone(this.mobiles);
      if (this.mobilesFiltered.length > 100) {
        this.mobilesFiltered.length = 100;
      }
    }
  }

  closeClicked() {
    this.dialogRef?.close();
  }

  async deleteSelectedContactsClicked() {
    for (const contactId of keys(this.contactsToDelete)) {
      if (this.contactsToDelete[contactId]) {
        const contact = this.contacts.find(c => c.id === contactId);
        if (contact) {
          await this.deleteClicked(contact, true);
        } else {
          debugger;
        }
      }
    }
    this.contactsToDelete = {};
  }
}
