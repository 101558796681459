<div *ngIf="!result" class="nxt-page w-full h-100" fxLayoutAlign="center center">
  <div class="text-150" style="text-align: center;" [innerHTML]="infoText"></div>
</div>
<div *ngIf="result" class="nxt-page w-full h-100 p-0" fxLayout="column" fxLayoutAlign="center center">
  <div fxFlex fxLayout="column" class="scrollable w-full h-100" fxLayoutAlign="center center">
    <div *ngIf="alreadyUsed" class="already-used" fxLayoutAlign="center center"><div>EINGELÖST</div></div>
    <img [ngClass.lt-sm]="'img-mobile'" [src]="result.base64"/>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <nxt-button (click)="download()">Download</nxt-button>
  </div>
</div>

