<div class="cal-day-headers" role="row" [ngStyle]="{width: headerWidth()}">
  <div
    class="cal-header p-relative"
    *ngFor="let day of days()"
    [class.cal-past]="day.isPast"
    [class.cal-today]="day.isToday"
    [class.cal-future]="day.isFuture"
    [class.cal-weekend]="day.isWeekend"
    tabindex="0"
    role="columnheader"
  >
    <div class="flex flex-col leading-tight relative">
      <div class="date-info flex flex-row justify-center items-center cursor-pointer gap-3 whitespace-break-spaces"
           (click)="dateHeaderClicked.emit(day.date.dateFormatDate())">
        @if ((day.date | nxtDate: 'yyyy') === thisYear) {
          <div class="bold text-[25px]">{{ day.date | nxtDate: 'EE dd.MM' }}</div>
        } @else {
          <div class="bold text-[25px]">{{ day.date | nxtDate: 'EE dd.MM.yy' }}</div>
        }
        <div class="text-70">{{ day.date | fromNowText: true }}</div>
      </div>
    </div>
    <div class="artist-info-and-daily-notes">
      <div class="artist-infos flex flex-col">
        <div class="flex flex-row justify-around text-120 leading-tight">
          <div>
            <div class="text-60">Artists</div>
            <div>{{ artistCounts()[day.date | nxtDate: 'yyyy-MM-dd'] }}</div>
          </div>
          <div *ngIf="piercingCounts()[day.date | nxtDate: 'yyyy-MM-dd'] > 0">
            <div class="text-60">Piercing</div>
            <div>{{ piercingCounts()[day.date | nxtDate: 'yyyy-MM-dd'] }}</div>
          </div>
          <div>
            <div class="text-60">Wert</div>
            <div>{{ valence()[day.date | nxtDate: 'yyyy-MM-dd'] | nxtRound: 1 }}</div>
          </div>
        </div>
      </div>
      <!--<ng-template #artistInfoTemplate let-data>
        <div>{{ data.a }} Artists</div>
        <div *ngIf="data.p">{{ data.p }} Piercing</div>
        <div>{{ data.v | nxtRound: 1 }} Wertigkeit</div>
      </ng-template>-->
      @if (publicHolidays()[day.date | nxtDate: 'yyyy-MM-dd']) {
        <div class="public-holiday">{{ publicHolidays()[day.date | nxtDate: 'yyyy-MM-dd'].name }}</div>
      }
      <div class="daily-notes flex flex-col">
        <div class="daily-note cursor-pointer" (click)="dailyNoteClicked.emit(dailyNote)"
             *ngFor="let dailyNote of dailyNotes()[day.date | nxtDate: 'yyyy-MM-dd']">
          {{ dailyNote.title }}
        </div>
      </div>
    </div>
  </div>
</div>
