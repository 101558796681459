import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NgStyle} from '@angular/common';

@Component({
  selector: 'nxt-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  imports: [NgStyle],
  standalone: true,
})

export class SpinnerComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  @Input() size = 80;

  constructor() {
    super();
  }

  @HostBinding('style.width') get setWidth() {
    return this.size + 'px';
  }

  @HostBinding('style.height') get setHeight() {
    return this.size + 'px';
  }

  ngOnInit() {

  }

  nxtOnDestroy() {

  }

}
