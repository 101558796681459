import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MathTools} from '../../../common-browser/helpers/math.tools';
import {DecimalTools} from '../../../common-browser/helpers/decimal.tools';
import {TypeTools} from '../../../common-browser/helpers/type.tools';
import {LoginService} from '../../../services/login.service';

@Pipe({
    name: 'calendarEventEditPriceText',
    standalone: true
})
export class CalendarEventEditPriceTextPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,

    private loginService: LoginService) {
  }

  transform(rawFormValue: any): string {
    try {
      let text = '';
      if (rawFormValue.priceFix && TypeTools.isNumberAndNotNaN(rawFormValue.priceEstimatedFrom)) {
        text = 'fix ' + rawFormValue.priceEstimatedFrom.toMoneyString('€', false);
      } else if (TypeTools.isNumberAndNotNaN(rawFormValue.priceEstimatedFrom) && TypeTools.isNumberAndNotNaN(rawFormValue.priceEstimatedTill)) {
        text = rawFormValue.priceEstimatedFrom.toMoneyString('', false) + ' <div class="text-80">&nbsp;-&nbsp;</div> ' + rawFormValue.priceEstimatedTill.toMoneyString('€', false);
      } else if (TypeTools.isNumberAndNotNaN(rawFormValue.priceEstimatedFrom)) {
        text += 'ca. ' + rawFormValue.priceEstimatedFrom.toMoneyString('€', false);
      } else {
        return '<div class="text-140">Preis festlegen</div>';
      }

      let artistMoney = '';
      if (TypeTools.isNumberAndNotNaN(rawFormValue.priceEstimatedFrom)) {
        artistMoney = DecimalTools.toMoneyString(rawFormValue.priceEstimatedFrom / 100 * rawFormValue.artistPercentage, '€', false);
      }
      let table = '<div class="text-150" style="display:flex; align-items: center; flex-direction: row">' + text + '</div>';
      const artistPercentInfo = this.loginService.isBackoffice() ? (' (' + MathTools.round(rawFormValue.artistPercentage) + '%)') : '';
      if (artistMoney) {
        table += '<div>Artist ' + artistMoney + artistPercentInfo + '</div>';
      }
      return '<div class="price-wrapper">' + table + '</div>';
    } catch (err) {
      return '';
    }
  }
}
