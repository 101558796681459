import {ChangeDetectorRef, Component, HostListener, Inject, LOCALE_ID, OnInit} from '@angular/core';
import moment from 'moment';
import {IframeMessageManagerInIframe, IframeState} from './services/iframe-message-manager-in-iframe';
import {filter, mergeMap, Subject, Subscription} from 'rxjs';
import {SocketService} from './services/socket/socket.service';
import {DialogService, LoadingId} from './services/dialog.service';
import {LoginService} from './services/login.service';
import {ActivatedRoute, ActivationStart, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {Log} from './common-browser/log/log.tools';
import {DomSanitizer, SafeUrl, Title} from '@angular/platform-browser';
import {ClipboardService} from './services/clipboard.service';
import {RouteService} from './services/route.service';
import {FirebaseLoginService} from './services/firebase-login.service';
import {NxtComponent} from './components/nxt.component';
import {KeyCode, ShortcutService} from './services/shortcut.service';
import {FocusService} from './services/focus.service';
import {BodyPutService} from './services/body-put.service';
import {CacheService} from './services/cache/cache.service';
import {LicenseManager} from 'ag-grid-enterprise';
import {DownloadService} from './services/download.service';
import {ManualDataOrderService} from './services/manual-data-order.service';
import {VersionService} from './services/version.service';
import {DomService} from './services/dom.service';
import {ConfigService} from './services/config.service';
import {ErrorTools} from './common-browser/helpers/error.tools';
import {FirebaseApp} from '@angular/fire/compat';
import {WorkingDayTools} from './common-browser/helpers/working-day.tools';
import {DateTools} from './common-browser/helpers/date.tools';
import {DurationTools} from './common-browser/helpers/duration.tools';
import {Cron} from 'croner';
import {StringTools} from './common-browser/helpers/string.tools';
import {MobileTools} from './common-browser/helpers/mobile.tools';
import {MoneyStackTools} from './common-browser/helpers/money-stack.tools';
import {IdleService} from './services/idle.service';
import {ServerService} from './services/server.service';
import {UserActiveTrackService} from './services/user-active-track.service';
import {debounceNotFirst} from './common-browser/helpers/rxjs.tools';
import {ScreenService} from './services/screen.service';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {AppMenuComponent} from './components/app-menu/app-menu.component';
import {AsyncPipe, NgIf} from '@angular/common';
import {NxtButtonIconComponent} from './controls/button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout';
import {ShopOrderService} from './services/shop-order/shop-order.service';
import {JsonTools} from './common-browser/helpers/json.tools';
import {WindowTools} from './common-browser/window.tools';
import {EmployeeTools} from './common-browser/helpers/employee.tools';
import {NanoIdTools} from './common-browser/helpers/nano-id.tools';
import {AssignFinderService} from './components/bank/bank-documents/bank-document-details/assign-finder.service';
import {map} from 'rxjs/operators';
import {FirestoreService} from './services/firestore.service';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {MathTools} from './common-browser/helpers/math.tools';

@Component({
  selector: 'nxt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    RouterOutlet,
    AppMenuComponent,
    NxtButtonIconComponent,
    AsyncPipe,
    NgIf,
    FlexModule,
  ],
})
export class AppComponent extends NxtComponent implements OnInit {

  constructor(
    public firebaseLoginService: FirebaseLoginService,
    public socketService: SocketService,
    private dialogService: DialogService,
    private loginService: LoginService,
    private sanitizer: DomSanitizer,
    private clipboardService: ClipboardService,
    private routeService: RouteService,
    private shortCutService: ShortcutService,
    private focusService: FocusService,
    private bodyPutService: BodyPutService,
    private cacheService: CacheService,
    private downloadService: DownloadService,
    private manualDataOrderService: ManualDataOrderService,
    private versionService: VersionService,
    private route: ActivatedRoute,
    private titleService: Title,
    private router: Router,
    private firestore: FirebaseApp,
    private domService: DomService,
    private configService: ConfigService,
    private idleService: IdleService,
    private serverService: ServerService,
    private userActiveTrackService: UserActiveTrackService,
    private screenService: ScreenService,
    @Inject(LOCALE_ID) public localeId: string,
    @Inject(MAT_DATE_LOCALE) public matDateLocaleId: string,
    private shopOrderService: ShopOrderService,
    private cdRef: ChangeDetectorRef,
    private assignFinderService: AssignFinderService,
    private firestoreService: FirestoreService,
    private angularFirestore: AngularFirestore,
  ) {
    super();
    // firestoreService.angularFirestore = angularFirestore;
    // this.firestoreService.test();
    const abc = NanoIdTools.generateNxt();
    AppComponent.cdRef = this.cdRef;
    const localeDe = moment.localeData('de');
    console.log('----------------------');
    console.log('----------------------');
    console.log('----------------------');
    console.log('----------------------');
    console.log('localeDe', localeDe);
    console.log('----------------------');
    console.log('----------------------');
    console.log('----------------------');
    console.log('----------------------');
    // alert('mat: ' + matDateLocaleId);
    // alert('angular: ' + localeId);

    // this.router.resetConfig(getRoutes());

    this.streamTest();
    WorkingDayTools.test();
    DateTools.test();
    // KlarnaTools.test();
    DurationTools.test();
    StringTools.test();
    MobileTools.test();
    MoneyStackTools.test();
    EmployeeTools.test();
    const cron = new Cron('0 6 * * *', async () => {
      setTimeout(() => {
        if (DateTools.formatNow('HH') === '06') {
          WindowTools.reload('6 Uhr morgens');
        }
      }, 3000);
    });
    this.checkReloadOldPage();
    this.registerTitleSetter();
    this.registerRouteChangedListener();


    this.socketService.registerOn('server-error', async (errMessage) => {
      const prevState = IframeMessageManagerInIframe.instance.showIframe('server-error', 'Error');
      await this.dialogService.showOk(errMessage, {title: 'Fehler'});
      if (prevState === IframeState.Hide) {
        IframeMessageManagerInIframe.instance.hideIframe('server-info');
      }
    });

    this.socketService.registerOn('server-message', async (errMessage) => {
      const prevState = IframeMessageManagerInIframe.instance.showIframe('server-info', 'Error');
      await this.dialogService.showOk(errMessage, {buttonText: 'OK'});
      if (prevState === IframeState.Hide) {
        IframeMessageManagerInIframe.instance.hideIframe('server-info');
      }
    });

    this.socketService.registerOn('welcome-message', async (errMessage) => {
      const prevState = IframeMessageManagerInIframe.instance.showIframe('server-info', 'Error');
      await this.dialogService.showOk(errMessage, {buttonText: 'Let\'s go!'});
      this.socketService.setWelcomeMessageRead();
      if (prevState === IframeState.Hide) {
        IframeMessageManagerInIframe.instance.hideIframe('server-info');
      }
    });

    this.socketService.registerOn('pre-update', async (data) => {
      this.dialogService.updateIsPreparing = true;
      this.dialogService.showLoading(LoadingId.PreUpdate, 'Update läuft...\n\n<div class="text-[60%] text-center">' + this.getFunnyUpdateText() + '</div>');
      setTimeout(() => {
        this.dialogService.hideLoading(LoadingId.PreUpdate);
      }, DurationTools.DURATION_1MINUTE * 5);
      const prevState = IframeMessageManagerInIframe.instance.showIframe('server-info', 'Error');
      if (prevState === IframeState.Hide) {
        IframeMessageManagerInIframe.instance.hideIframe('server-info');
      }
    });

    this.socketService.registerOn('pre-pre-update', async (data) => {
      const prevState = IframeMessageManagerInIframe.instance.showIframe('server-info', 'Error');
      await this.dialogService.showOk('Ankündigung eines Updates in ca. 2 Min.\nHast du was zu speicher? Mach es jetzt!', {timeoutSeconds: 120});
      if (prevState === IframeState.Hide) {
        IframeMessageManagerInIframe.instance.hideIframe('server-info');
      }
    });

    this.socketService.registerOn('server-restart', async (data) => {
      if (this.dialogService.updateIsPreparing) {
        return;
      }
      const prevState = IframeMessageManagerInIframe.instance.showIframe('server-info', 'Error');
      this.dialogService.showLoading(LoadingId.ServerDoRestart, 'kurze Wartezeit...');
      this.socketService.serverIsRestarting = true;
      if (prevState === IframeState.Hide) {
        IframeMessageManagerInIframe.instance.hideIframe('server-info');
      }
    });


    this.bodyPutService.init();
    window.addEventListener('focus', this.windowFocus.bind(this), true);
    window.addEventListener('blur', this.windowBlur.bind(this), true);


    this.shortCutService.init();
    this.firebaseLoginService.init();

    Log.onSocketError.subscribe((message) => {
      this.dialogService.showOk(message, {title: 'Fehler'});
    });

    Log.onError.subscribe(async (data: any) => {
      try {
        this.dialogService.hideLoading(undefined, true);
        let isError = true;
        if (this.checkShowError(data)) {
          if (typeof data?.message === 'string' && data.message.indexOf('$ServerErrorStart$') > -1) {
            const serverError = JsonTools.parse(data.message.substring(data.message.indexOf('$ServerErrorStart$') + 18, data.message.indexOf('$ServerErrorEnd$')));
            this.dialogService.showOk(serverError.message, {title: serverError.title});
          } else {
            if (data.errorId) {
              const title = ErrorTools.getTitle(data);
              const message = ErrorTools.getMessage(data);
              if (message) {
                this.dialogService.showOk(message, {title});
              }
              isError = data.isError;
            } else {
              if (data.message === 'Missing or insufficient permissions.' && !this.firebaseLoginService.isLoggedIn.getValue()) {
              } else if (data.message.includes('The password is invalid or the user does not have a password')) {
                this.dialogService.showOk('Falsches Passwort', {title: 'Login Fehlgeschlagen'});
              } else {
                // this.socketService.sendTelegramError('Fehler bei ' + this.loginService.getUsername() + '\n' + data.message);
                const [message, stack] = data.message.split('[Stack]');
                let messageAndStack = message;
                if (this.loginService.isJulian()) {
                  messageAndStack = message + '<div class="text-80">Julian: ' + stack + '</div>';
                }
                this.dialogService.showOk(messageAndStack, {title: 'Fehler', timeoutSeconds: 20});
                if (this.loginService.getUsername() === 'Artist-foto-info') {
                  WindowTools.reload('Fehler bei Artist-Foto-TV');
                }
              }
            }
          }
          if (isError) {
            if (data.stack) {
              this.sendErrorToServer(data.message + '\n\n' + data.stack);
            } else {
              this.sendErrorToServer(data.message);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    });


    router.events.subscribe((event) => {
      console.log('router.events', event);
      if (event instanceof ActivationStart) {

      }
      if (event instanceof NavigationEnd) {
        if (this.isFirstRoute && !event.url.startsWith('/login?')) {
          this.checkRouteChildData();
          this.isFirstRoute = false;
        }
      }
    });
    IframeMessageManagerInIframe.instance = new IframeMessageManagerInIframe(this.socketService);
    moment.locale('de');
    // DeviceTools.isMobile = deviceService.isMobile();


    /*if (this.socketService.isRouteInWhitelist()) {
      this.registerConnectionListener();
    }*/
    this.imageUrls = [];
    this.lastObjectUrl = '';


    this.shortCutService.onKeyPress.subscribe((key: KeyCode) => {
      if (key === KeyCode.CtrlL) {
        this.showAccountBar = !this.showAccountBar;
      }
    });


    (LicenseManager.prototype as any).validateLicense = function() {
      this.isDisplayWatermark = () => false;
    };
  }

  static cdRef: ChangeDetectorRef;


  static startup = Date.now();
  public static mouseMoveCounter = 0;
  private debounceAllButFirst$ = new Subject<string>();
  private inputStream$ = new Subject<string>();
  title = 'ng-nxtlvlink';
  private connectionChangeListenerSubscription: Subscription;
  private lastObjectUrl: string;
  private imageUrls: SafeUrl[];
  accountInfoText: string;
  showAccountBar = false;
  private lastDisconnect: number;
  version = '';


  public showVersionInfo = true;

  isFirstRoute = true;
  // public authenticated = false;
  // public connected = false;
  private lastMouseMove = 0;

  lastErrorMessageSent = '';

  nxtOnDestroy(): void {
  }

  windowFocus() {
    this.focusService.checkFocusElement(document.activeElement);
  }

  windowBlur() {
    this.focusService.checkFocusElement(document.activeElement);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenService.calc();
  }

  @HostListener('document:mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    this.userActiveTrackService.setIsActive();
  }

  @HostListener('document:keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    this.userActiveTrackService.setIsActive();
  }

  @HostListener('paste', ['$event'])
  pastListener($event) {
    this.clipboardService.handlePaste($event);
  }

  @HostListener('mousemove', ['$event'])
  mousemove($event) {
    this.lastMouseMove = Date.now();
    AppComponent.mouseMoveCounter++;
  }

  async ngOnInit() {
    this.registerFirebaseLoginListener();

    /*** VERSION INFO ANZEIGEN **/

    this.showVersion();
  }


  handleKey($event) {
    Log.info($event);

  }

  private registerConnectionListener() {
    if (this.connectionChangeListenerSubscription) {
      this.connectionChangeListenerSubscription.unsubscribe();
    }
    this.connectionChangeListenerSubscription = this.socketService.state.subscribe((params) => {
      if (typeof params.connected === 'undefined') {
        return;
      }
      if (params.connected) {
        Log.info('CLOSE OFFLINE-DIALOG');
        this.dialogService.hideConnectionListDialog();
      }
      setTimeout(() => {
        if (!this.socketService.state.getValue().connected && this.firebaseLoginService.isLoggedIn.getValue()) {
          this.lastDisconnect = Date.now();
          if (!this.socketService.serverIsRestarting) {
            this.dialogService.showConnectionLostDialog();
          }
        }
      }, 5000);
    });
  }

  private registerFirebaseLoginListener() {
    this.pushSubscription = this.firebaseLoginService.onLoginChanged.subscribe((user) => {
      if (user) {
        this.accountInfoText = user.name + ' (' + user.email + ')';
      } else {
        this.accountInfoText = 'anmelden';
      }
    });
  }

  private async showVersion() {

    // this.version = 'Version: ' + await this.versionService.getVersion() + ' vom ' + await this.versionService.getVersionDate();
  }

  private checkRouteChildData() {
    const data = this.route?.snapshot?.firstChild?.data;
    if (data?.hideVersionInfo) {
      this.showVersionInfo = false;
    } else {
      this.showVersionInfo = true;
    }

    if (data?.disableSocket) {
      this.socketService.disableSocket = true;
      // this.socketService.stopReconnectInterval();
    } else {
      this.registerConnectionListener();
      this.socketService.startReconnectInterval();
    }
  }

  private sendErrorToServer(message: string) {
    try {
      if (this.lastErrorMessageSent === message) {
        setTimeout(() => {
          this.lastErrorMessageSent = '';
        }, 30000);
        return;
      }
      this.lastErrorMessageSent = message;
      let text = LoginService.instance.getUsername().toUpperCase();
      text += '\n' + LoginService.instance.getStudio().toUpperCase();
      text += '\n' + window.location.href;
      text += '\n' + message;
      SocketService.instance.sendTelegramError(text);
    } catch (err) {

    }
  }

  private checkShowError(data: { message: string; stack: string; shrinkStack: string }) {
    if (data?.message === 'Cannot read property \'get\' of null') {
      return false;
    }
    return true;
  }

  disconnect() {
    this.socketService.disconnectSocket();
  }


  streamTest() {
    this.inputStream$ = new Subject<string>();
    const debounceAllButFirst$ = this.inputStream$.pipe(
      debounceNotFirst(800),
    );

    debounceAllButFirst$.subscribe((value) => {
      console.log('subscribe: ' + value);
    });
  }

  private checkReloadOldPage() {
    /*if (window.location.hostname === 'localhost' && window.location.pathname === '/') {
      const lastUrl = localStorage.getItem('localhostLastUrl');
      if (lastUrl) {
        window.location.href = lastUrl;
      }
    }*/
  }

  private registerRouteChangedListener() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.socketService.setCurrentUrl(event.urlAfterRedirects);
      }
    });
  }

  private registerTitleSetter() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.route;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => route.data),
      )
      .subscribe((data) => {
        let title = 'NXT ' + this.configService.config.value.studioName;
        if (data.title) {
          title += ' - ' + data.title;
        }
        this.titleService.setTitle(title);
      });

  }

  private getFunnyUpdateText() {
    return MathTools.getRandomFromArray([
      'Bitte warten… oder drück wild auf die Tastatur, wenn du dich besser fühlst.',
      'Fast fertig… okay, das war gelogen. Aber wir glauben dran!',
      'Jetzt wäre ein guter Zeitpunkt, um deinen Schreibtisch aufzuräumen.',
      'Setz dich hin, entspann dich, das Update hat alles unter Kontrolle.\nWahrscheinlich. 😬',
      'perfekt, Zeit für einen Mittagsschlaf! 😴',
      'Ja, das dauert. Nein, du kannst es nicht beschleunigen. 🙃',
      'Dein PC denkt nach… gib ihm ’nen Moment, der ist nicht mehr der Jüngste. 🧓',
      'Update in Arbeit. Du kannst schon mal anfangen, dein Passwort zu vergessen. 🔐',
      'Chill mal, Bruder. Update läuft, keine Panik auf der Titanic. 🚢💨',
      'Dein PC ackert härter als du an nem Montag. Gönn ihm Respekt. 💪',
      'Installation läuft. Vielleicht installieren wir dir auch gleich neue Nerven. 😂',
      'Geduld ist nicht die Fähigkeit zu warten, sondern die Fähigkeit, ruhig zu bleiben, während man wartet. ☯️',
      'Dein PC rechnet… und erinnert uns daran: Gute Dinge brauchen Zeit. ⏳',
      'Vielleicht ein guter Moment, um mal innezuhalten – nicht alles muss immer schnell gehen. 🍃',
      'Fortschritt passiert nicht immer schnell, aber beständig – genauso wie in unserem Leben. 🏗️',
      'Perfekte Gelegenheit, um zu atmen und den Moment einfach mal so zu nehmen, wie er ist. 🌿',
      'Ladezeiten lehren uns Achtsamkeit – es gibt immer etwas um uns herum, das wir sonst übersehen. 👀',
      'Ja, das Update dauert. Aber auch der Bambus wächst jahrelang unter der Erde, bevor er durchbricht. 🎋',
      'Warten ist nicht Stillstand – es ist Vorbereitung auf das, was kommt. 🌅',
      'Auch wenn’s langsam geht, irgendwann ist es geschafft. Und dann? Weiter geht’s! 🔥',
    ]);
  }
}
