<div class="nxt-page h-100">
  <div class="flex flex-row justify-between">
    <div fxLayout="row" fxLayoutGap="50px">
      <div class="page-header">Promoter</div>
      <nxt-input class="flex w-52" placeholder="Suche" [clearIcon]="true" [(value)]="quickFilterText"></nxt-input>
      <nxt-select class="flex w-52" [(value)]="filterState" (valueChange)="filterStateChanged()"
                  [options]="[{value:'all', text: 'Alle'},{value:'deactivated', text: 'Inaktiv'},{value:'activated', text: 'Aktiv'}]"></nxt-select>
      <nxt-button nxtPermission="IsJulian" (click)="newDummy()">Neuer Promoter (Dummy)</nxt-button>
    </div>
    <nxt-button-icon (click)="closeClicked()">close</nxt-button-icon>
  </div>
  <nxt-datagrid
    class="h-100"
    [quickFilterText]="quickFilterText"
    [autoSizeColumnOnResize]="true"
    [columnDefs]="columnDefs"
    [rowData]="promotersFiltered"
  >
  </nxt-datagrid>
</div>
