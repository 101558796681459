@if (changed()) {
  *
}
<div *ngIf="form && artists.length > 0" fxLayout="column" class="pt-2 w-full">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div *ngIf="!userCanCreateSpots" class="text-120 pb-2">
      {{ form.get('artist').value?.name }}
    </div>
    <div *ngIf="!userCanCreateSpots" class="pb-2">
      {{ form.get('start').value | nxtDate: 'EEE dd.MM.yyyy' }} - {{ form.get('end').value | nxtDate: 'EEE dd.MM.yyyy' }}
    </div>
    <nxt-autocomplete
      *ngIf="userCanCreateSpots"
      placeholder="Artist"
      [nxtFormControl]="$any(form.get('artist'))"
      [options]="artists"
      [displayWith]="displayArtistFn"
      [displayInOptionWith]="displayArtistFn"
      [filterFields]="['name']"
      [showClearIcon]="true"
      [maxOptionsToShow]="999"
    ></nxt-autocomplete>
  </div>

  <div fxLayout="row" fxLayoutAlign="center">
    <nxt-date-picker-2
      *ngIf="userCanCreateSpots"
      placeholder="Start"
      [nxtFormControl]="$any(form.get('start'))"
      (valueChange)="generateDays()"
    >
    </nxt-date-picker-2>
    <nxt-date-picker-2
      *ngIf="userCanCreateSpots"
      placeholder="Ende"
      [nxtFormControl]="$any(form.get('end'))"
      (valueChange)="generateDays()"
    >
    </nxt-date-picker-2>
  </div>

  <div fxLayout="row" fxLayoutAlign="center" class="pb-2">
    <nxt-slide-toggle [nxtFormControl]="form.get('artistIsInApartment')">Artist ist in diesem Zeitraum in einer unserer Wohnungen</nxt-slide-toggle>
  </div>

  <div class="flex flex-col items-center" *ngIf="form.get('artistIsInApartment').value">
    <div class="flex flex-row">
      <nxt-select class="w-[160px]" [nxtFormControl]="form.get('keySafeNumber')" [options]="keySafeNumberOptions" placeholder="Schlüsselbox / Zimmer"></nxt-select>
      <nxt-button (click)="getArtistSpotGateOpenTextClicked()">Schlüssel-Link</nxt-button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" class="pb-2">
      <nxt-slide-toggle [nxtFormControl]="form.get('apartmentCheckInDone')">Wohnungs-Check-In hat stattgefunden</nxt-slide-toggle>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" class="pb-2">
      <nxt-slide-toggle [nxtFormControl]="form.get('apartmentCheckOutDone')">Wohnungs-Check-Out hat stattgefunden</nxt-slide-toggle>
    </div>
  </div>

  <div class="pb-3">
    <!--<div fxLayoutAlign="center" class="p-2">
      <nxt-button (click)="allTo('11:00', '20:00')">Alle 11 - 20 Uhr</nxt-button>
      <nxt-button (click)="allTo('18:00', '01:00')">Alle 18 - 1 Uhr</nxt-button>
    </div>-->
    <table class="nxt-table" style="width: 100%">
      <tr style="margin-bottom: 5px;">
        <th>
          <div fxLayout="row" fxLayoutAlign="center">Mo</div>
        </th>
        <th>
          <div fxLayout="row" fxLayoutAlign="center">Di</div>
        </th>
        <th>
          <div fxLayout="row" fxLayoutAlign="center">Mi</div>
        </th>
        <th>
          <div fxLayout="row" fxLayoutAlign="center">Do</div>
        </th>
        <th>
          <div fxLayout="row" fxLayoutAlign="center">Fr</div>
        </th>
        <th>
          <div fxLayout="row" fxLayoutAlign="center">Sa</div>
        </th>
        <th>
          <div fxLayout="row" fxLayoutAlign="center">So</div>
        </th>
      </tr>
      <tr *ngFor="let days of weeks">
        <td (click)="dayClicked(day)" *ngFor="let day of days" [ngClass]="{today: day.isToday}" class="mouse-pointer">
          <div [id]="'date-' + day.dateString" *ngIf="day.canAvailable" fxLayout="column" fxLayoutAlign="space-between">
            <div fxLayout="row" fxLayoutAlign="center">
              <strong>{{ day.dateString | nxtDate: 'dd.MM' }}</strong>
            </div>
            <div *ngIf="day.from === -1 && day.to === -1" fxLayout="row" fxLayoutAlign="center">
              <div style="font-size: 80%;">komplett da</div>
            </div>
            <div *ngIf="day.from === -2 && day.to === -2" fxLayout="row" fxLayoutAlign="center">
              <div style="font-size: 80%;" class="red">nicht da</div>
            </div>
            <div *ngIf="day.from === -1 && day.to > -1" fxLayout="row" fxLayoutAlign="center">
              Bis {{ day.to | duration: 'HH:mm' }} Uhr
            </div>
            <div *ngIf="day.from > -1 && day.to === -1" fxLayout="row" fxLayoutAlign="center">
              Ab {{ day.from | duration: 'HH:mm' }} Uhr
            </div>
            <div *ngIf="day.from > -1 && day.to > -1" fxLayout="row" fxLayoutAlign="center">
              {{ day.from | duration: 'HH:mm' }} - {{ day.to | duration: 'HH:mm' }}
            </div>
          </div>
        </td>
      </tr>

    </table>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-around">
    <nxt-button *ngIf="artistSpot?.id && userCanCreateSpots" (click)="delete()">löschen</nxt-button>
    <nxt-button (click)="close()">abbrechen</nxt-button>
    <nxt-button (click)="showHistoryClicked()">Verlauf</nxt-button>
    <nxt-button (click)="save()">speichern</nxt-button>
  </div>
</div>
