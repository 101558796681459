<nxt-form-field-wrapper [nxtFormControl]="nxtFormControl" [width]="width">
  <mat-form-field class="mat-form-field-full-width">
    <mat-label>{{ placeholder || nxtFormControl.name }}</mat-label>
    <mat-select
      [panelWidth]="panelWidth"
      [disabled]="disabled"
      *ngIf="!multiple"
      [panelClass]="panelClass"
      [formControl]="nxtFormControl"
      (valueChange)="valueChange.emit($event)"
      #controlElement
    >
      <mat-option *ngFor="let option of options" [value]="option.value" [ngStyle]="{height: optionHeight}">
        {{ option.text ? option.text : option.value }}
      </mat-option>
    </mat-select>


    <mat-select
      *ngIf="multiple"
      [formControl]="nxtFormControl"
      clear
      multiple
      (valueChange)="valueChange.emit($event)"
      #controlElement
    >
      <mat-option *ngFor="let option of options" [value]="option.value" [ngStyle]="{height: optionHeight}">
        {{ option.text ? option.text : option.value }}
      </mat-option>

    </mat-select>
    <div style="position: absolute; right: 0; bottom: 0">
      @if (showClearIcon) {
        <mat-icon aria-hidden="false" aria-label="clear" (click)="clearInput($event)">clear</mat-icon>
      }
    </div>


    <mat-error *ngIf="showInvalidError">
      <div *ngIf="nxtFormControl.errors.required">
        {{ requiredError }}
      </div>
    </mat-error>
  </mat-form-field>
</nxt-form-field-wrapper>
