import {AfterContentInit, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {FormControlWrapper} from '../form-control-wrapper';
import {ObjectTools} from '../../../common-browser/helpers/object.tools';
import {MatSelect} from '@angular/material/select';
import {MatIcon} from '@angular/material/icon';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {MatOption} from '@angular/material/core';
import {NgFor, NgIf, NgStyle} from '@angular/common';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {FormFieldWrapperComponent} from '../../form-field-wrapper/form-field-wrapper.component';

@Component({
    selector: 'nxt-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectComponent),
        }
    ],
    imports: [FormFieldWrapperComponent, MatFormField, MatLabel, NgIf, MatSelect, FormsModule, ReactiveFormsModule, NgFor, MatOption, NgStyle, ExtendedModule, MatIcon, MatError],
  standalone: true,
})
export class SelectComponent extends FormControlWrapper implements OnInit, AfterContentInit, OnChanges {

  @ViewChild(MatSelect) set matSelect(matSelect: MatSelect) {
    if (matSelect) {
      this.nxtFormControl.element = matSelect._elementRef.nativeElement;
    }
  }

  @Output() valueChange: EventEmitter<any>;
  @Input() options: { value: any, text: string }[];
  @Input() placeholder: string;
  @Input() multiple = false;
  @Input() disabled = false;


  @Input() set value(value: any) {
    const oldValue = this.nxtFormControl.value;
    if (!ObjectTools.compare(value, oldValue)) {
      this.nxtFormControl.setValue(value);
      this.valueChange.emit(value);
    }
  }


  @Input() optionHeight = '48px';
  @Input() panelWidth = 'auto';
  @Input() panelClass = '';
  @Input() showClearIcon = false;
  @Input() showListAfterClear = true;

  constructor() {
    super();
    this.valueChange = new EventEmitter<any>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      if (!changes.disabled.currentValue) {
        this.nxtFormControl?.enable();
      } else {
        this.nxtFormControl?.disable();
      }
    }
  }

  ngOnInit() {
  }

  ngAfterContentInit(): void {
    if (this.controlElemRef) {
      if (this.controlElemRef.nativeElement) {
        this.nxtFormControl.element = this.controlElemRef.nativeElement;
      } else if ((this.controlElemRef as any)._elementRef.nativeElement) {
        this.nxtFormControl.element = (this.controlElemRef as any)._elementRef.nativeElement;
      }
    }
  }


  clearInput(ev: MouseEvent) {
    if (!this.showListAfterClear) {
      ev.stopPropagation();
      ev.preventDefault();
    }
    this.value = null;
  }

  testValueChanged(newValue: any) {
    this.value = newValue;
  }
}
