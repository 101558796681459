<div fxLayout="column" class="nxt-page-modal" style="width:70vw; height: 90vh; border-radius: 4px;">
  <div fxFlex fxLayoutAlign="center center" *ngIf="!pdfObjectUrl()">
    <div style="font-size: 24px;">lade PDF...</div>
  </div>
  <object fxFlex *ngIf="pdfObjectUrl()" [data]="pdfObjectUrl() | safeResourceUrl" type="application/pdf">
  </object>
  <!--<iframe style="border: 2px solid red; width: 150px; height: 150px" [src]="iframeSrc | safeResourceUrl">

  </iframe>-->
  <div style="height: 16px;"></div>
  <div class="flex flex-row justify-center items-center gap-2">
    <nxt-button (click)="download()">herunterladen</nxt-button>
    <nxt-button *ngIf="!options?.showPrintLocalInfo" (click)="print()">drucken</nxt-button>
    <nxt-button (click)="close()">schließen</nxt-button>
  </div>
</div>

<div *ngIf="options?.showPrintLocalInfo" nxtAnimate="tada" class="print-info" [nxtInterval]="5000" fxLayout="row">
  HIER DRUCKEN&nbsp;<mat-icon>arrow_upward_alt</mat-icon>
</div>
