import {ChangeDetectionStrategy, Component, computed, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {ObjectGroupTools} from '../../common-browser/helpers/object-group.tools';
import {keys} from '../../common-browser/helpers/object.tools';
import {MoneyPipe} from '../../pipes/money.pipe';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {PdfInvoice3Data} from '../../common-interfaces/pdf/pdf-invoice-3-data.interface';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';


@Component({
  selector: 'nxt-pdf-invoice-3',
  templateUrl: './pdf-invoice-3.component.html',
  styleUrls: ['./pdf-invoice-3.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MoneyPipe,
    SafeHtmlPipe,
    NxtDatePipe,
  ],
})

export class PdfInvoice3Component extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  errorText = signal('');
  data = signal<PdfInvoice3Data | null>(null);

  /*** Computes ***/

  /*** Injections ***/

  /*** Injections ***/
  totalGross = computed(() => {
    return this.data().positions.reduce((sum, p) => sum + p.valueGross, 0);
  });

  vatLines = computed<{ vat: number, valueNet: number, valueGross: number, vatValue: number }[]>(() => {
    if (this.data()) {
      const vatLines: { vat: number, valueNet: number, valueGross: number, vatValue: number }[] = [];
      const posByVat = ObjectGroupTools.groupObjectArray(this.data().positions, 'vat');
      for (const vatString of keys(posByVat)) {
        const vat = parseFloat(vatString);
        const vatPos = posByVat[vat];
        const vatMultiplier = 1 + vat / 100;
        const valueNet = vatPos.reduce((sum, p) => sum + (p.valueGross / vatMultiplier), 0);
        const valueGross = vatPos.reduce((sum, p) => sum + p.valueGross, 0);
        const vatValue = valueGross - valueNet;
        vatLines.push({vat, valueNet, valueGross, vatValue});
      }
      return vatLines;
    }
    return [];
  });

  /*** Variable ***/

  constructor() {
    super();
    (window as any).setData = (data: any) => {
      try {
        this.data.set(data);
      } catch (err) {
        this.errorText.set(err.message);
      }
    };
  }

  ngOnInit() {
    window.document.body.style.backgroundColor = 'transparent';

    if (window.location.hostname === 'localhost' && false) {
      this.data.set({
        footer: 'Hans Huber\nABC DEF\n12345 Musterstadt',
        useVat: true,
        invoiceNumber: 'ABC-DEF',
        invoiceDate: Date.now(),
        sender: {
          oneLine: 'Julian Krafft ・ Arnoldstr. 29 ・ DE 52064 Aachen',
        },
        receiver: {
          text: 'Max Mustermann\nMusterstraße 1\nDE 12345 Musterstadt',
        },
        logo: {
          footer: '',
          url: 'assets/logo/logo_black.svg',
        },
        positions: [
          {description: 'Tattoozahlung', valueGross: 180, vat: 19},
          {description: 'Rückzahlung', valueGross: -50, vat: 19},
          {description: 'Designerstellung', valueGross: 50, vat: 7},
        ],
      });
    }
  }

  nxtOnDestroy() {
  }
}
