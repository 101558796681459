<div fxLayout="column">
  <div class="text-140 p-2 flex flex-col justify-center items-center">
    <div>Neue Bestellung</div>
    <div>{{ item.name }} in {{ item.studio }}</div>
    <div>
      <div class="text-80">Du sollst eine Menge von {{ shouldOrderAmount }} bestellen</div>
    </div>
  </div>
  <div class="flex flex-row">
    <div fxLayout="column" class="w-[400px]">
      <div>
        <nxt-select placeholder="Bezugsquelle" [(value)]="sourceOfSupply" [options]="sourceOfSupplyOptions"></nxt-select>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <nxt-input [isNumber]="true" [(value)]="orderAmount" placeholder="Menge"></nxt-input>
        <div>&nbsp;x&nbsp;</div>
        <div class="no-wrap">{{ item.amountPerPackage }} {{ item.unitOfMeasure }}</div>
      </div>
      <nxt-radio
        *ngIf="orderAmount"
        style="padding: 0 0 0 8px"
        class="flex grow-1"
        textKey="text"
        direction="row"
        [options]="singleOrTotalPriceOptions"
        [(value)]="currentSingleOrTotalPriceOptions"
        (valueChange)="calcNetGross()"
      >
      </nxt-radio>
      @if (currentSingleOrTotalPriceOptions?.value === 'total') {
        <div fxLayout="row" fxLayoutAlign="center center">
          <nxt-input
            [noPadding]="true"
            [(value)]="totalPrice"
            [isMoney]="true"
            (valueChange)="calcNetGross()"
            [placeholder]="'Gesamtpreis ' + (currentNetGrossOption ?'('+ currentNetGrossOption.text +') ' : '')+ (orderAmount ? (orderAmount + ' x ' + item.amountPerPackage + ' ' + item.unitOfMeasure) : '')">
          </nxt-input>
          <nxt-radio
            style="padding: 0 0 0 8px"
            class="flex grow-1"
            textKey="text"
            direction="column"
            [options]="netGrossOptions"
            [(value)]="currentNetGrossOption"
            (valueChange)="calcNetGross()"
          >
          </nxt-radio>
        </div>
      } @else if (currentSingleOrTotalPriceOptions?.value === 'single') {
        <div fxLayout="row" fxLayoutAlign="center center">
          <nxt-input
            [noPadding]="true"
            [(value)]="unitPrice"
            [isMoney]="true"
            (valueChange)="calcNetGross()"
            [placeholder]="'Einzelpreis ' + (currentNetGrossOption ?'('+ currentNetGrossOption.text +') ' : '') + ' 1 x ' + item.amountPerPackage + ' ' + item.unitOfMeasure">
          </nxt-input>
          <nxt-radio
            style="padding: 0 0 0 8px"
            class="flex grow-1"
            textKey="text"
            direction="column"
            [options]="netGrossOptions"
            [(value)]="currentNetGrossOption"
            (valueChange)="calcNetGross()"
          >
          </nxt-radio>
        </div>
      }
      <div *ngIf="currentNetGrossOption && orderAmount && totalPrice">
        <div>
          <div>Netto-Gesamt: {{ totalPriceNet | money }}</div>
          <div>Brutto-Gesamt: {{ totalPriceNet * (vat + 1) | money }}</div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" class="pt-6">
        <div class="flex flex-row justify-center items-center center">
          <nxt-button-icon (click)="changeShouldArrivedAt(-1)">remove</nxt-button-icon>
          <div>
            Sollte ankommen:<br>{{ shouldArrivedAt | nxtDate: 'EEE dd.MM.yyyy' }} {{ shouldArrivedAt | fromNowText: true }}
          </div>
          <nxt-button-icon (click)="changeShouldArrivedAt(1)">add</nxt-button-icon>
        </div>
      </div>
    </div>

    <div *ngIf="item.packages" class="grid grid-cols-1 gap-4 pl-12 h-fit">
      <div class="w-full center text-120">Pakete</div>
      <div
        (click)="packageClicked(itemPackage)"
        *ngFor="let itemPackage of item.packages"
        class="cursor-pointer flex justify-center items-center center flex-col"
        style="border: 1px solid #5b5b5b; border-radius: 4px; padding: 8px; background: #363636"
      >
        <div class="center" [innerHTML]="itemPackage.name | safeHtml">
        </div>
        <div *ngIf="itemPackage.packagePriceNet" style="font-size: 75%">für {{ itemPackage.packagePriceNet | money }} netto</div>
        <div style="font-size: 75%">bei {{ itemPackage.sourceOfSupply }}</div>
      </div>
    </div>
  </div>
  <div class="mt-4" fxLayout="row" fxLayoutAlign="center center">
    <nxt-button (click)="dialogRef.close()">Abbrechen</nxt-button>
    <nxt-button (click)="createOrder()">{{ saveButtonText }}</nxt-button>
  </div>
  <!--<div fxLayout="row">{{item | json}}</div>-->
  <div>

  </div>
</div>
