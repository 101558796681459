<nxt-button-icon
  #menuTrigger
  mat-icon-button
  [matMenuTriggerFor]="menu"
  aria-label="Menü"
  (click)="menuClicked()"
  *ngIf="showMenuButton"
  class="menu-button"
>more_vert
  <mat-icon></mat-icon>
</nxt-button-icon>

<mat-menu #menu="matMenu">
  <div *ngIf="buttons">
    <button *ngFor="let button of buttons" mat-menu-item>
      <mat-icon>{{button.icon}}</mat-icon>
      <span (click)="button.click()">{{button.text}}</span>
    </button>
  </div>
</mat-menu>

