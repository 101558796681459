<nxt-form-field-wrapper [nxtFormControl]="nxtFormControl" [width]="width"
                        [ngClass]="{'bottom-no-padding': noPadding || bottomNoPadding, 'no-padding': noPadding}">

  <mat-form-field (click)="inputWrapperClicked()" fxFlex *ngIf="nxtFormControl" class="mat-form-field-full-width">
    <mat-label>{{ placeholder }}</mat-label>
    <div fxLayout="row">

      <input
        [tabIndex]="tabIndex"
        fxFlex
        (focusout)="focusout($event)"
        (focusin)="focusin($event)"
        (click)="inputClicked($event)"
        type="text"
        [attr.aria-label]="placeholder"
        matInput
        [formControl]="nxtFormControl"
        [matAutocomplete]="autoComplete"
        #controlElement
        #inputElement
        matAutocompletePosition="auto"
      >

      <div fxLayout="column" fxLayoutAlign="center" style="height: 17px; position: relative; top: -3px; left: 5px;">
        <nxt-button-icon *ngIf="showAddCacheTextButton" (click)="addCacheText()">add</nxt-button-icon>
        <nxt-button-icon *ngIf="showRemoveCacheTextButton" (click)="removeCacheText()">remove</nxt-button-icon>
      </div>
    </div>
    <mat-error *ngIf="nxtFormControl.invalid && (nxtFormControl.dirty || nxtFormControl.touched)">
      <div *ngIf="$any(nxtFormControl.errors).required">
        {{ requiredError }}
      </div>
      <div *ngIf="$any(nxtFormControl.errors).validCurrency">
        ungültiger Wert
      </div>
    </mat-error>
    <mat-autocomplete
      [autoActiveFirstOption]="true"
      #autoComplete="matAutocomplete"
      [displayWith]="displayWith"
      (closed)="closed($event)"
      (opened)="opened($event)"
      (optionSelected)="optionSelected($event)"
      [panelWidth]="panelWidth"
    >
      <div [class]="nxtMatOptionContainerClass">
        @if (optionTrackBy()) {
          @for (option of filteredOptions(); track option[optionTrackBy()]) {
            <mat-option
              class="mat-option {{nxtMatOptionClass}}"
              [value]="option"
              [innerHTML]="displayOptionWith(option) | safeHtml">
            </mat-option>
          }
        } @else {
          @for (option of filteredOptions(); track option) {
            <mat-option
              class="mat-option {{nxtMatOptionClass}}"
              [value]="option"
              [innerHTML]="displayOptionWith(option) | safeHtml">
            </mat-option>
          }
        }

      </div>
    </mat-autocomplete>
    @if (showClearIcon && nxtFormControl.value) {
      <div class="absolute cursor-pointer right-0 bottom-0">
        <mat-icon *ngIf="showClearIcon" aria-hidden="false" aria-label="clear" (click)="clearInput($event)">clear</mat-icon>
      </div>
    }
  </mat-form-field>
</nxt-form-field-wrapper>


