<div *ngIf="shopOrders" class="h-100 w-full" fxLayout="column">
  <nxt-datagrid
    class="h-100"
    [columnDefs]="columnDefs"
    [rowData]="shopOrders"
    excelExportName="Studio-Abgaben Klarna-Zahlungen"
    [showFooter]="true"
  >
  </nxt-datagrid>
  <div fxLayout="row" fxLayoutAlign="center center" class="pt-2">
    <nxt-button (click)="dialogRef.close()">schließen</nxt-button>
    <nxt-button (click)="createInvoiceClicked()">Rechnung erstellen</nxt-button>
  </div>
</div>
