import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {PercentageTools} from '../../common-browser/helpers/percentage.tools';
import {DialogService} from '../../services/dialog.service';
import {NxtShopOrder} from '../../common-interfaces/nxt.shop-order.interface';
import {MoneyPipe} from '../../pipes/money.pipe';
import {PaymentMethodPipe} from '../../pipes/payment-method.pipe';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {JsonPipe, NgFor, NgIf} from '@angular/common';
import {NxtPermissionId} from '../../common-interfaces/nxt.user.interface';
import {PermissionService} from '../../services/permission.service';
import {LoginService} from '../../services/login.service';
import {NxtPaymentPossibilityRecord} from '../../common-interfaces/nxt.payment-possibility-record.interface';
import {GiftCardRecordComponent} from './gift-card-view-items/gift-card-record.component';
import {NxtComponent} from '../nxt.component';
import {ConfigService} from '../../services/config.service';
import {Log} from '../../common-browser/log/log.tools';
import {ThermalPrinterService} from '../../services/thermal-printer.service';

@Component({
  selector: 'nxt-gift-card-view',
  templateUrl: './gift-card-view.component.html',
  styleUrls: ['./gift-card-view.component.scss'],
  imports: [NgIf, FlexModule, NxtButtonComponent, NgFor, JsonPipe, NxtDatePipe, PaymentMethodPipe, MoneyPipe, GiftCardRecordComponent, GiftCardRecordComponent],
  standalone: true,
})

export class GiftCardViewComponent extends NxtComponent implements OnInit, OnChanges {

  /**
   * irgend eine recordId
   */
  @Input() id: string;

  @Input() reload: EventEmitter<void>;
  @Output() onShowShopOrder = new EventEmitter<string>();
  public openValue = 0;
  public refundValue = 0;
  @Input() showOpenEvent = true;

  getDiscountPercentage = PercentageTools.getDiscountPercentage;

  shopOrder: NxtShopOrder;
  records: NxtPaymentPossibilityRecord[] = [];

  firstParent?: NxtPaymentPossibilityRecord;

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private permissionService: PermissionService,
    private loginService: LoginService,
    private configService: ConfigService,
    private thermalPrinterService: ThermalPrinterService,
  ) {
    super();
    this.pushSocketSubscription = this.socketService.subscribeNew('eventShopOrderUpdated', (shopOrder: NxtShopOrder) => {
      if (this.records.length > 0 && this.records[0].shopOrderId === shopOrder.id) {
        this.shopOrder = shopOrder;
      }
    });
    this.pushSocketSubscription = this.socketService.subscribeNew('eventPaymentPossibilityRecordUpdated', (record: NxtPaymentPossibilityRecord) => {
      if (this.records.some(r => r.id === record.id)) {
        this.load();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.id) {
      this.load();
    }
  }

  async ngOnInit() {
  }

  nxtOnDestroy(): void {
  }

  public delete() {
    alert('gibts noch nicht');
  }


  async refundPayPalClicked() {
    if (!this.permissionService.hasPermissionWithInfo(NxtPermissionId.CanRefund)) {
      return;
    }
    const firstParent = this.records[0];
    if (this.openValue !== firstParent.originalValue) {
      this.dialogService.showOk('Es können nur Gutscheine zurückgezahlt werden, die noch nicht benutzt wurden');
      return;
    }
    const payPalIpn = await this.socketService.getPayPalIpn(firstParent.paymentMethodId);
    if (!payPalIpn) {
      this.dialogService.showOk('PayPal-IPN nicht gefunden');
      return;
    }
    let refundInfo = await this.dialogService.showInput('Kurze info zur Rückzahlung');
    if (refundInfo) {
      refundInfo = this.loginService.getUsername() + ': ' + refundInfo;
      this.dialogService.showLoading('Wird zurückgezahlt...');
      /*const refundResult = await this.socketService.paypalRefundShopOrderItem({
        captureId: this.firstParent.paymentMethodId,
        value: this.firstParent.originalPaymentValue,
        receiverEmail: payPalIpn.receiver_email,
        reason: refundInfo,
        shopOrderId: this.firstParent.shopOrderId,
        refType: 'gift-card',
        refId: this.firstParent.id,
        shopOrderLineItemId: this.firstParent.shopOrderLineItemId
      });
      this.dialogService.hideLoading();
      if (refundResult.success) {
        this.dialogService.showOk('Erfolgreich zurückgezahlt');
      } else {
        this.dialogService.showOk(refundResult.status + '\n' + refundResult.message, {title: 'Fehler beim zurückzahlen'});
      }*/
      this.dialogService.hideLoading();
    }
  }


  private async load() {
    const data = await this.socketService.getGiftCardForView(this.id);
    if (data) {
      this.records = [data.mainRecord, ...data.splittedRecords];
      this.firstParent = data.mainRecord;
      this.shopOrder = data.shopOrder;
      this.openValue = this.records.filter(o => !o.assigned).reduce((sum, o) => sum + o.value, 0);
      this.refundValue = this.records.filter(o => o.refunded).reduce((sum, o) => sum + o.value, 0);
    } else {
      this.dialogService.showOk('Keine Daten gefunden');
    }
  }

  async studioTransferClicked() {
    if (this.records.length > 1 || this.records[0].assigned) {
      this.dialogService.showOk('Nur Gutscheine die noch nicht benutzt wurden können übertragen werden');
      return;
    }
    const result = await this.dialogService.showButtons('An welches Studio übertragen?', {buttons: this.getPossibleStudiosToTransfer(), showCancelButton: true});
    if (result) {
      let text = 'Soll der Gutschein wirklich übertragen werden?';
      text += '\n\n' + this.openValue.toMoneyString();
      text += '\n\n' + this.firstParent.name;
      if (await this.dialogService.showYesNo(text)) {
        this.dialogService.showLoading('Gutschein wird übertragen...');
        const transferResult = await this.socketService.sendStudioTransferGiftCard(this.records[0].id, result.value);
        this.dialogService.hideLoading();
        if (transferResult === 'OK') {
          await this.dialogService.showOk('Gutschein erfolgreich übertragen');
        } else {

        }
      }
    }
  }

  getPossibleStudiosToTransfer() {
    if (this.configService.config.value.studioRegion === 'AC') {
      return [{value: 'du', text: 'Düsseldorf'}, {value: 'ma', text: 'Mallorca'}, {value: 'do', text: 'Dortmund'}];
    }
    if (this.configService.config.value.studioRegion === 'DU') {
      return [{value: 'ac', text: 'Aachen'}, {value: 'ma', text: 'Mallorca'}, {value: 'do', text: 'Dortmund'}];
    }
    if (this.configService.config.value.studioRegion === 'DO') {
      return [{value: 'ac', text: 'Aachen'}, {value: 'ma', text: 'Mallorca'}, {value: 'du', text: 'Düsseldorf'}];
    }
    if (this.configService.config.value.studioRegion === 'FFM') {
      return [{value: 'ab', text: 'Aschaffenburg'}, {value: 'nu', text: 'Nürnberg'}];
    }
    if (this.configService.config.value.studioRegion === 'NU') {
      return [{value: 'ab', text: 'Aschaffenburg'}, {value: 'ffm', text: 'Frankfurt'}];
    }
    if (this.configService.config.value.studioRegion === 'AB') {
      return [{value: 'nu', text: 'Nürnberg'}, {value: 'ffm', text: 'Frankfurt'}];
    }
  }

  async printReceipt() {
    try {
      this.dialogService.showLoading('Gutschein-Beleg wird gedruckt');
      await this.socketService.rePrintGiftCardReceipt(this.firstParent.id);
      this.dialogService.hideLoading();
    } catch (err) {
      this.dialogService.hideLoading();
      if (err.message.includes('Belegdrucker reagiert nicht')) {
        this.thermalPrinterService.showChangeIp(err.message).then();
      } else {
        Log.error(err);
      }
    }
  }
}


