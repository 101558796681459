<div fxLayout="column" class="nxt-page nxt-text">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-2">
    <div fxLayout="row" fxLayoutGap="50px" fxLayoutAlign="start center">
      <div class="page-header">Offene Zahlungen</div>
      <div fxLayout="column" fxLayoutAlign="start center">
        <div>Richtung</div>
        <mat-radio-group [disabled]="!!(sourcePayment$ | async)" [value]="directionFilter$ | async" (change)="directionFilter$.next($event.value)" style="display: flex;flex-direction: row;">
          <mat-radio-button style="padding-right: 15px" class="studio-radio-button" value="">Alle</mat-radio-button>
          <mat-radio-button style="padding-right: 15px" class="studio-radio-button" value="in">In</mat-radio-button>
          <mat-radio-button class="studio-radio-button" value="out">Out</mat-radio-button>
        </mat-radio-group>
      </div>
      <div  fxLayout="column" fxLayoutAlign="start center">
        <div>Zahlungstyp</div>
        <mat-radio-group [value]="paymentTypeFilter$ | async" (change)="paymentTypeFilter$.next($event.value)" style="display: flex;flex-direction: row;">
          <mat-radio-button style="padding-right: 15px" class="studio-radio-button" value="">Alle</mat-radio-button>
          <mat-radio-button style="padding-right: 15px" class="studio-radio-button" value="PayPal">PayPal</mat-radio-button>
          <mat-radio-button style="padding-right: 15px" class="studio-radio-button" value="Bank">Bank</mat-radio-button>
          <mat-radio-button style="padding-right: 15px" class="studio-radio-button" value="Klarna">Klarna</mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxFlex="250px">
        <nxt-input [bottomNoPadding]="true" placeholder="Suche" [(value)]="quickFilterText"></nxt-input>
      </div>
      <div *ngIf="sourcePayment$ | async">
        <nxt-button (click)="cancelAssignment()">Zuweisung Abbrechen</nxt-button>
      </div>
    </div>
    <nxt-button-icon *ngIf="dialogRef" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>
  <nxt-datagrid
    class="h-100 w-full"
    [quickFilterText]="quickFilterText"
    [columnDefs]="columnDefs"
    [rowData]="notAssignedPaymentsFiltered$ | async"
    [showSideBar]="false"
  >
  </nxt-datagrid>
</div>
