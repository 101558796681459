import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {EventComponent} from '../../../event/event.component';
import {SocketService} from '../../../services/socket/socket.service';
import {DialogService} from '../../../services/dialog.service';
import {Log} from '../../../common-browser/log/log.tools';
import {NxtFormControl} from '../../../nxt-form/nxt.form-control';
import {NxtInventoryItem} from '../../../common-interfaces/nxt.inventory-item.interface';
import {InventoryService} from '../../../services/inventory.service';
import {FormTools} from '../../../services/form.tools';
import {LoginService} from '../../../services/login.service';
import {ConfigService} from '../../../services/config.service';
import {Subscription} from 'rxjs';
import {DisplayWithTools} from '../../../common-browser/helpers/display-with.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {MathTools} from '../../../common-browser/helpers/math.tools';
import {ObjectTools} from '../../../common-browser/helpers/object.tools';
import {InventoryPackagesComponent} from '../inventory-packages/inventory-packages.component';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {AutocompleteComponent} from '../../form-controls/autocomplete/autocomplete.component';
import {SelectComponent} from '../../form-controls/select/select.component';
import {InputComponent} from '../../form-controls/input/input.component';
import {PermissionDirective} from '../../../directives/permission.directive';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf} from '@angular/common';
import {DatePickerComponent} from '../../form-controls/date-picker/date-picker.component';
import moment from 'moment';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {ValidatorTools} from '../../../helpers/validator.tools';


@Component({
    selector: 'nxt-inventory-item-form',
    templateUrl: './inventory-item-form.component.html',
    styleUrls: ['./inventory-item-form.component.scss'],
    imports: [NgIf, FlexModule, FormsModule, ReactiveFormsModule, PermissionDirective, InputComponent, NgFor, SelectComponent, AutocompleteComponent, SlideToggleComponent, NxtButtonComponent, DatePickerComponent]
})
export class InventoryItemFormComponent implements OnInit, OnDestroy {


  form: UntypedFormGroup;
  private sourceOfSupplyValueChangesSubscription: Subscription;
  public amountFullText = '';
  public users = this.configService.config.value.users.map(u => u.username).sort();
  public displayWithUsername = DisplayWithTools.displayWith('text');
  data: NxtInventoryItem;
  piercingAttributes: { name: string, shortName: string }[] = [];
  piercingData = {
    name: '',
    attributes: []
  };

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EventComponent>,
    private socketService: SocketService,
    private dialogService: DialogService,
    public inventoryService: InventoryService,
    public loginService: LoginService,
    private configService: ConfigService
  ) {
  }

  loadFromId(id: string) {
    const data = this.inventoryService.inventoryItems.getValue().find(i => i.id === id);
    this.loadFormFromObject(data);
  }

  async ngOnInit() {
    document.body.style.backgroundColor = 'transparent !important';
    this.sourceOfSupplyValueChangesSubscription = this.form.get('sourceOfSupply').valueChanges.subscribe(() => {
      this.calcNameEnRequired();
    });
  }

  ngOnDestroy(): void {
    this.sourceOfSupplyValueChangesSubscription.unsubscribe();
  }

  calcNameEnRequired() {
    let validators = [];

    if (this.form.get('sourceOfSupply').value) {
      if (this.inventoryService.inventorySourcesOfSupply.find(s => s.value === this.form.get('sourceOfSupply').value)?.printEn) {
        validators = [ValidatorTools.requiredAndNotNaN];
      }
    }
    this.form.get('nameEn').setValidators(validators);
    this.form.get('nameEn').updateValueAndValidity({emitEvent: false});
  }

  private loadFormFromObject(data: NxtInventoryItem) {
    this.data = data;
    const nameEnRequired = false;

    if (!data.unitOfMeasure) {
      data.unitOfMeasure = 'Stk';
    }
    this.form = this.fb.group({
      id: new NxtFormControl(data.id ? data.id : ''),
      name: new NxtFormControl(data.name ? data.name : '', [ValidatorTools.requiredAndNotNaN]),
      location: new NxtFormControl(data.location ? data.location : '', [ValidatorTools.requiredAndNotNaN]),
      amountPerPackage: new NxtFormControl(data.amountPerPackage ? data.amountPerPackage : 1, [ValidatorTools.requiredAndNotNaN]),
      orderValue: new NxtFormControl(data.orderValue),
      deleted: new NxtFormControl(data.deleted),
      unitOfMeasure: new NxtFormControl(data.unitOfMeasure, [ValidatorTools.requiredAndNotNaN]),
      amountAlarm: new NxtFormControl(data.amountAlarm),
      sourceOfSupply: new NxtFormControl(data.sourceOfSupply),
      sourceOfSupply2: new NxtFormControl(data.sourceOfSupply2),
      sourceOfSupplyUrl: new NxtFormControl(data.sourceOfSupplyUrl),
      sourceOfSupply2Url: new NxtFormControl(data.sourceOfSupply2Url),
      amountFull: new NxtFormControl(data.amountFull),
      nfcId: new NxtFormControl(data.nfcId),
      // studiosAmounts: this.fb.array(studiosAmounts),
      nameEn: new NxtFormControl(data.nameEn ? data.nameEn : '', nameEnRequired ? [ValidatorTools.requiredAndNotNaN] : []),
      nameBySource: new NxtFormControl(data.nameBySource ?? '', [ValidatorTools.requiredAndNotNaN]),
      responsibility: new NxtFormControl(data.responsibility ?? '', [ValidatorTools.requiredAndNotNaN]),
      responsibility2: new NxtFormControl(data.responsibility2 ?? '', [ValidatorTools.requiredAndNotNaN]),
      orderedByAc: new NxtFormControl(!!data.orderedByAc, [ValidatorTools.requiredAndNotNaN]),
      onlyFullOrEmptyInfo: new NxtFormControl(data.onlyFullOrEmptyInfo ?? '', []),
      onlyFullOrEmpty: new NxtFormControl(!!data.onlyFullOrEmpty, []),
      notOrderBefore: new NxtFormControl(data.notOrderBefore ? moment(data.notOrderBefore) : null, []),
    });
    this.calcNameEnRequired();
    this.form.get('location').valueChanges.subscribe((location) => {
      this.calcPiercingAttributes();
    });
    this.calcPiercingAttributes();
  }


  async saveAndClose() {
    const result = await this.save();
    if (result) {
      this.close(result);
    }
  }

  private async save() {
    const errors = FormTools.getErrors(this.form);
    if (errors.length > 0) {
      const errorText = 'Na, hast du nicht was vergessen einzutragen?\n\n' + this.getErrorText(errors);
      await this.dialogService.showOk(errorText, {title: 'Fehlende Eingaben!'});
      return false;
    } else {
      try {
        const item = {...this.data, ...this.form.getRawValue()};
        if (item.notOrderBefore) {
          item.notOrderBefore = DateTools.parse(item.notOrderBefore).dateFormatDate();
        }
        if (item.onlyFullOrEmpty) {
          item.amountAlarm = 0;
          item.amountFull = 1;
        }
        if (!item.orderValue) {
          item.orderValue = MathTools.random(0, 5000);
        }
        await this.inventoryService.upsertInventoryItem(item, this.loginService.getUsername());
        return true;
      } catch (err) {
        Log.error(err);
        return false;
      }
    }
  }

  private getErrorText(errors: string[]): string {
    const texts = {
      name: 'Bezeichnung',
      location: 'Ort',
      amountPerPackage: 'Menge pro Packung',
      responsibility: 'Zuständig',
      responsibility2: 'Zuständig Vertretung',
    };
    return '- ' + errors.map(error => texts[error] ? texts[error] : error).join('\n- ');
  }

  async closeClicked() {
    // const result = await this.dialogService.showYesNo('Möchtest du wirklich abbrechen und nicht speichern?', {yesText: 'Ja, abbrechen'});
    // if (result) {
    this.close(false);
    // }
  }

  async close(result) {
    this.dialogRef.close(result);
  }


  async delete() {
    const result = await this.dialogService.showYesNo('Wirklich löschen?', {yesText: 'Ja, löschen'});
    if (result) {
      await this.inventoryService.upsertInventoryItem({...this.form.value, deleted: true}, this.loginService.getUsername());
      this.close(true);
    }
  }

  getStudiosAmounts() {
    return (this.form.get('studiosAmounts') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  public newItem() {
    this.loadFormFromObject({} as NxtInventoryItem);
  }

  private calcPiercingAttributes() {
    if (this.form.get('location').value === 'piercing') {
      if (this.form.get('name').value && this.form.get('name').value.toString().includesAll(['dermal', 'anchor'])) {
        this.setPiercingAttributes([
          {name: 'Länge (mm)', shortName: 'Länge'},
          {name: 'Breite (mm)', shortName: 'Breite'},
          {name: 'Kugelgröße (mm)', shortName: 'Kugel'}
        ]);
      } else {
        this.setPiercingAttributes([
          {name: 'Stabdurchmesser (mm)', shortName: 'Durch'},
          {name: 'Stablänge (mm)', shortName: 'Länge'},
          {name: 'Kugelgröße (mm)', shortName: 'Kugel'}
        ]);
      }
      if (this.piercingData.attributes.length === 0 && this.form.get('name').value.includes(' | ')) {
        const parts = this.form.get('name').value.split(' | ');
        this.piercingData.name = parts[0];
        for (const [index, part] of parts.slice(1).entries()) {
          const attributeParts = part.split(':');
          const attribute = this.piercingAttributes[index];
          if (attribute) {
            this.piercingData.attributes.push(attributeParts[1]);
          }
        }
      } else {
        this.piercingData.name = this.form.get('name').value.split(' | ')[0];
      }
    } else {
      this.piercingAttributes = [];
    }
  }

  piercingDataChanged() {
    requestAnimationFrame(() => {
      let text = this.piercingData.name;
      for (const [index, attribute] of this.piercingAttributes.entries()) {
        if (this.piercingData.attributes[index]) {
          text += ' | ' + attribute.shortName + ':' + this.piercingData.attributes[index];
        }
      }
      this.form.get('name').setValue(text);
      this.calcPiercingAttributes();
    });
  }

  private setPiercingAttributes(piercingAttributes: { name: string; shortName: string }[]) {
    if (!ObjectTools.equal(this.piercingAttributes, piercingAttributes)) {
      this.piercingAttributes = piercingAttributes;
    }
  }

  showPackagesClicked() {
    const dialog = this.dialogService.showComponentDialog(InventoryPackagesComponent);
    dialog.componentInstance.load(this.data.id);
  }
}
