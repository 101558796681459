import {Component, OnInit, Optional, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {HistoryComponent, NxtHistoryPropDef} from '../history.component';
import {SocketService} from '../../../services/socket/socket.service';
import {JsonTools} from '../../../common-browser/helpers/json.tools';
import {BodyPutService} from '../../../services/body-put.service';
import {ConfigService} from '../../../services/config.service';
import {MatDialogRef} from '@angular/material/dialog';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {ArtistSkillTools} from '../../../common-browser/helpers/artist-skill.tools';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {LanguageTools} from '../../../common-browser/helpers/language.tools';

@Component({
    selector: 'nxt-artist-history',
    templateUrl: './artist-history.component.html',
    styleUrls: ['./artist-history.component.scss'],
    imports: [FlexModule, NxtButtonIconComponent, HistoryComponent]
})
export class ArtistHistoryComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor(
    private socketService: SocketService,
    private bodyPutService: BodyPutService,
    private configService: ConfigService,
    @Optional() private dialogRef: MatDialogRef<ArtistHistoryComponent>,
  ) {
    super();
    this.hiddenProps.push(...this.debugProps);
  }

  @ViewChild(HistoryComponent) historyComponent: HistoryComponent;

  public propDefs: NxtHistoryPropDef[] = [
    {
      field: 'birthday',
      name: 'Sprache',
      type: NxtFieldType.Date_germanDate,
    },
    {
      field: 'lang',
      name: 'Sprache',
      type: NxtFieldType.Text,
      valueGetter: data => LanguageTools.getTextByCode(data),
    },
    {
      field: 'telegramChatId',
      name: 'Telegram',
      type: NxtFieldType.Text,
      valueGetter: data => data ? 'verbunden' : ('nicht verbunden (' + data + ')'),
    },
    {
      field: 'idNumber',
      name: 'Ausweisnummer',
      type: NxtFieldType.Text,
    },
    {
      field: 'skills',
      name: 'Fähigkeiten',
      type: NxtFieldType.Text,
      valueGetter: (data) => {
        return JsonTools.stringify(data);
      },
      textGetter: (params) => {
        console.log(params);
        if (params.diff.op === 'update') {
          if (params.diff.val && params.diff.oldVal) {
            const oldObj = JsonTools.parse(params.diff.oldVal);
            const newObj = JsonTools.parse(params.diff.val);
            const diffLines = ArtistSkillTools.getDiff(oldObj, newObj);
            if (diffLines.length === 0) {
              return 'keine Änderungen';
            }
            return (diffLines.length > 1 ? '<br />' : '') + diffLines.map(d => {
              if (typeof d.newValue === 'boolean') {
                return d.skill + ' ' + (d.oldValue ? 'Ja' : 'Nein') + ' -> ' + (d.newValue ? 'Ja' : 'Nein');
              }
              return d.skill + ' ' + d.oldValue + ' -> ' + d.newValue;
            }).join('<br/>');
          }
        }
        if (params.diff.op === 'add') {
          const data = JsonTools.parse(params.value);
          const lines: string[] = [];
          lines.push('Speed: ' + data.speed);
          for (const skill of data.skillValues) {
            lines.push(skill.description + ': ' + skill.value);
          }
          for (const skill of data.boolSkillValues) {
            lines.push(skill.description + ': ' + (skill.value ? 'Ja' : 'Nein'));
          }
          return '<br />' + lines.sort().join('<br/>');
        }
      },
    },
  ];
  public hiddenProps = [
    'eventId',
    'seqId',
    'updatedBy',
    'updatedAt',
    'createdAt',
    'createdBy',
  ];

  debugProps = [];
  public showTitle = false;
  public showDebug = false;
  public artistId = '7d4f03bd-6b90-4bfd-b969-c11e8cf50c0a'; // '74trk3lmp6jm6nuddh6dsq232o';


  setData(artistId: string) {
    this.artistId = artistId;
  }

  ngOnInit() {
    this.propDefs = this.propDefs.sort(SortTools.sortString('name'));
  }

  nxtOnDestroy() {
  }

  public prepareRawData(data) {
    return data;
  }

  close() {
    this.dialogRef.close();
  }
}
