import {Component, OnInit, Optional, signal, ViewChild} from '@angular/core';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NxtFormControl} from '../../../nxt-form/nxt.form-control';
import {StringTools} from '../../../common-browser/helpers/string.tools';
import {CountryTools} from '../../../common-browser/helpers/country.tools';
import {FormTools} from '../../../services/form.tools';
import {DialogService} from '../../../services/dialog.service';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtArtist} from '../../../common-interfaces/nxt.artist.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {EventComponent} from '../../../event/event.component';
import {ClipboardService} from '../../../services/clipboard.service';
import {JsonTools} from '../../../common-browser/helpers/json.tools';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {ConfigService} from '../../../services/config.service';
import {BodyPutService} from '../../../services/body-put.service';
import {NxtComponent} from '../../../components/nxt.component';
import {PermissionService} from '../../../services/permission.service';
import {NxtPermissionId} from '../../../common-interfaces/nxt.user.interface';
import {TimeTools} from '../../../common-browser/helpers/time.tools';
import {MobileTools} from '../../../common-browser/helpers/mobile.tools';
import {ArtistSkillComponent} from './artist-skill/artist-skill.component';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {AutocompleteComponent} from '../../../components/form-controls/autocomplete/autocomplete.component';
import {SelectComponent} from '../../../components/form-controls/select/select.component';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {SlideToggleComponent} from '../../../components/form-controls/slide-toggle/slide-toggle.component';
import {DatePickerComponent} from '../../../components/form-controls/date-picker/date-picker.component';
import {InputComponent} from '../../../components/form-controls/input/input.component';
import {MultiClickDirective} from '../../../directives/multi-click.directive';
import {PermissionDirective} from '../../../directives/permission.directive';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf} from '@angular/common';
import {FileUploadComponent} from '../../../components/file-upload/file-upload.component';
import {JsonFormattedPipe} from '../../../pipes/json-formatted-pipe';
import {GoogleDriveFileInfo} from '../../../common-interfaces/google-drive.interface';
import {ArtistNotesComponent} from './artist-notes/artist-notes.component';
import {ArtistsTools} from '../../../common-browser/helpers/artists.tools';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {LoginService} from '../../../services/login.service';
import {LanguageTools} from '../../../common-browser/helpers/language.tools';
import {TelegramArtistStartChatComponent} from '../../../components/telegram/telegram-artist-start-chat/telegram-artist-start-chat.component';
import {CacheService} from '../../../services/cache/cache.service';
import {ArtistSkillTools} from '../../../common-browser/helpers/artist-skill.tools';


@Component({
  selector: 'nxt-artist-edit',
  templateUrl: './artist-edit.component.html',
  styleUrls: ['./artist-edit.component.scss'],
  imports: [NgIf, FlexModule, NxtButtonIconComponent, PermissionDirective, MultiClickDirective, InputComponent, DatePickerComponent, SlideToggleComponent, MatTabGroup, MatTab, SelectComponent, AutocompleteComponent, NxtButtonComponent, ArtistSkillComponent, NgFor, FileUploadComponent, JsonFormattedPipe, ArtistNotesComponent, MatButtonToggle, MatButtonToggleGroup, ReactiveFormsModule],
})
export class ArtistEditComponent extends NxtComponent implements OnInit {

  static TabIndex_IdAndContract = 0;
  static TabIndex_Invoice = 1;
  static TabIndex_Notes = 2;
  static TabIndex_Skills = 3;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogService: DialogService,
    private socketService: SocketService,
    private clipboardService: ClipboardService,
    @Optional() public dialogRef: MatDialogRef<EventComponent>,
    public configService: ConfigService,
    private bodyPutService: BodyPutService,
    private permissionService: PermissionService,
    private loginService: LoginService,
    private cacheService: CacheService,
  ) {
    super();
  }

  saveRunning = false;

  artistFiles = signal<GoogleDriveFileInfo[]>([]);

  private imported = false;
  private saveSkillsToAc = false;

  canEdit = true;

  isAc = this.permissionService.isAc();

  @ViewChild(NxtDatagridComponent) dataGrid: NxtDatagridComponent;
  artist: NxtArtist | undefined; //  = this.getDummyArtist();
  form: UntypedFormGroup;
  countries = CountryTools.list;
  // allArtists: NxtArtist[];
  isMainStudio = this.configService.config.value.studios.some(s => s.name === 'Villa');
  motiveTattooBlacklist = {
    round: false,
    thinLines: false,
  };
  public allStudios = this.configService.config.value.allStudios;
  public disabledStudios: { [studioShort: string]: boolean } = {};
  showSkills = true;
  showBlackList = true;
  languages = LanguageTools.languageCodes.map(l => ({text: l.name, value: l.code})).sort(SortTools.sortString('text'));
  selectedIndex = signal(0);


  nxtOnDestroy(): void {

  }

  // bodyPuts: { value: string[], text: string }[] = [];

  displayInOptionWithCountry = (country, highlight: (string) => string) => {
    if (!country) {
      return '';
    }
    return highlight(country.name);
  };

  displayWithCountry = (country) => {
    if (!country) {
      return '';
    }
    return country.name ?? country.name;
  };

  async ngOnInit() {
    this.pushSocketSubscription = this.socketService.subscribeNew('eventArtistChanged', async (data) => {
      if (!this.saveRunning && data.record.id === this.artist.id) {
        await this.dialogService.showOk('Artist wurde eben von ' + data.record.updatedBy + ' geändert!\n\nDeine Änderungen werden nicht gespeichert.', {buttonText: 'Artist neu laden'});
        this.setData(this.artist.id);
      }
    });
  }


  async setData(artistOrArtistId: string | NxtArtist, checkExistsInAc = true) {
    console.log('warte waitForArtists');
    await this.cacheService.waitForArtists();
    console.log('fertig waitForArtists');
    if (typeof artistOrArtistId === 'string') {
      if (artistOrArtistId === 'new') {
        this.artist = ArtistsTools.getEmptyArtist();
      } else {
        console.log('warte getArtist');
        this.artist = await this.socketService.getArtist(artistOrArtistId);
        console.log('fertig getArtist');
      }
    } else {
      this.artist = artistOrArtistId;
    }

    if (this.artist) {
      await this.enrichArtistSkills(this.artist);
      this.buildFormFromArtist(this.artist);

      if (artistOrArtistId === 'new') {
        let nextCalendarNr = 0;
        if (this.cacheService.artists.value.length > 0) {
          nextCalendarNr = Math.max(...this.cacheService.artists.value.map(a => parseInt(a.calendarNumber, 10)).filter(n => n < 990));
        }
        const nextCalendarNrString = StringTools.fill((nextCalendarNr + 1).toString(), 3, '0', false);
        this.formGet('calendarNumber').setValue(nextCalendarNrString);
        this.generateInvoiceDataCustomerNumber();
        this.formGet('name').enable();
        this.formGet('calendarNumber').disable();
      } else {
        this.formGet('name').disable();
      }

      if (checkExistsInAc) {
        this.canEdit = await this.socketService.canEditArtist(this.artist.name);
        if (!this.canEdit) {
          if (this.permissionService.hasPermission(NxtPermissionId.Artists_CanEditSkillsOutsideFromAc)) {
            this.dialogService.showOk('Es werden lediglich die Fähigkeiten die du änderst gespeichert!');
            this.saveSkillsToAc = true;
            this.canEdit = true;
          } else {
            this.dialogService.showOk(this.artist.name + ' gibts auch in Aachen, bitte dort ändern und Synchronisieren');
          }
          return;
        }
      } else {
        this.canEdit = true;
      }
      this.loadArtistFiles();
    }
  }

  private async enrichArtistSkills(artist: NxtArtist) {
    ArtistSkillTools.skills.forEach(artistSkill => {
      if (!artist.skills.skillValues.find(s => s.description === artistSkill.description)) {
        artist.skills.skillValues.push({description: artistSkill.description, value: 0});
      }
    });
    ArtistSkillTools.boolSkills.forEach(artistSkill => {
      if (!artist.skills.boolSkillValues) {
        artist.skills.boolSkillValues = [];
      }
      if (!artist.skills.boolSkillValues.find(s => s.description === artistSkill.description)) {
        artist.skills.boolSkillValues.push({description: artistSkill.description, value: false});
      }
    });
  }


  private buildFormFromArtist(artist: NxtArtist) {
    let artistLang = artist.lang;
    if (!artistLang && artist?.invoiceData?.country) {
      artistLang = LanguageTools.getLanguageFromCountry(artist.invoiceData.country);
    }
    const lang = this.languages.find(l => l.value === artistLang);
    this.form = this.formBuilder.group({
      name: new NxtFormControl(artist.name, [ValidatorTools.requiredAndNotNaN, ValidatorTools.maxWords(2), ValidatorTools.trim, ValidatorTools.custom(this.checkArtistName.bind(this)), ValidatorTools.artistName], 'Künstlername'),
      calendarNumber: new NxtFormControl(artist.calendarNumber, [ValidatorTools.requiredAndNotNaN, ValidatorTools.exactCharLength(3), ValidatorTools.onlyNumbers], 'Kalendernummer'),
      birthday: new NxtFormControl(artist.birthday, [ValidatorTools.requiredAndNotNaN], 'Geburtstag'),
      lang: new NxtFormControl(lang, [ValidatorTools.requiredAndNotNaN], 'Sprache'),
      idNumber: new NxtFormControl(artist.idNumber, [ValidatorTools.requiredAndNotNaN], 'Ausweis-Nummer'),
      contractSigned: new NxtFormControl(artist.contractSigned, ValidatorTools.requiredAndNotNaN),
      payoutBig: new NxtFormControl(artist.payoutBig),
      employed: new NxtFormControl(!!artist.employed),
      createCustomerInvoices: new NxtFormControl(!!artist.createCustomerInvoices),
      telegramChatId: new NxtFormControl(artist.telegramChatId || 0),
      only_: new NxtFormControl(artist.only_, [], 'Nur _'),
      instagram: new NxtFormControl(artist.instagram, [ValidatorTools.requiredAndNotNaN], 'Instagram'),
      mobile: new NxtFormControl(artist.mobile, [ValidatorTools.requiredAndNotNaN, ValidatorTools.mobile], 'Handy'),
      workType: new NxtFormControl(artist.workType ?? 'tattoo', [], 'Arbeit'),
      toothGem: new NxtFormControl(!!artist.toothGem),
      disabled: new NxtFormControl(artist.disabled),
      invoiceData: this.formBuilder.group({
        salutation: new NxtFormControl(artist.invoiceData.salutation, ValidatorTools.requiredAndNotNaN, 'Rechnungs-Anrede'),
        name: new NxtFormControl(artist.invoiceData.name, ValidatorTools.requiredAndNotNaN, 'Rechnungs-Name'),
        // email: new NxtFormControl(artist.invoiceData.email, [Validators.email/*, ValidatorTools.requiredAndNotNaN*/], 'Rechnungs-E-Mail'),
        email: new NxtFormControl(artist.invoiceData.email, [], 'Rechnungs-E-Mail'),
        street: new NxtFormControl(artist.invoiceData.street, ValidatorTools.requiredAndNotNaN, 'Rechnungs-Straße'),
        city: new NxtFormControl(artist.invoiceData.city, ValidatorTools.requiredAndNotNaN, 'Rechnungs-Ort'),
        country: new NxtFormControl(artist.invoiceData.country, ValidatorTools.hasProperty('code'), 'Rechnungs-Land'),
        postalCode: new NxtFormControl(artist.invoiceData.postalCode, ValidatorTools.requiredAndNotNaN, 'Rechnungs-PLZ'),
        vatId: new NxtFormControl(artist.invoiceData.vatId, [], 'USt-IdNr (nur bei Deutschen)'),
        customerNumber: new NxtFormControl(artist.invoiceData.customerNumber, ValidatorTools.requiredAndNotNaN),
        numberRange: new NxtFormControl(artist.invoiceData.numberRange, [ValidatorTools.requiredAndNotNaN, ValidatorTools.exactCharLength(3), ValidatorTools.onlyAZ, ValidatorTools.uppercase, ValidatorTools.custom(this.checkNumberRangeFree.bind(this))], 'Rechnungskreis'),
      }),
      skills: this.formBuilder.group({
        speed: new NxtFormControl(artist.skills.speed, Validators.min(0), 'Geschwindigkeit'),
        // color: new NxtFormControl(artist.skills.color, ValidatorTools.requiredAndNotNaN, 'Farbe'),
        boolSkillValues: this.formBuilder.array(artist.skills.boolSkillValues.map(v => {
          return this.formBuilder.group({
            description: new NxtFormControl(v.description),
            value: new NxtFormControl(v.value, [], 'Skill ' + v.description),
          });
        })),
        skillValues: this.formBuilder.array(artist.skills.skillValues.map(v => {
          return this.formBuilder.group({
            description: new NxtFormControl(v.description),
            value: new NxtFormControl(v.value, Validators.min(0), 'Skill ' + v.description),
            // like: new NxtFormControl(v.like, Validators.min(0), 'Gerne ' + v.description),
          });
        })),
      }),
    });
    this.motiveTattooBlacklist.round = !!artist.motiveTattooBlacklist?.includes('round');
    this.motiveTattooBlacklist.thinLines = !!artist.motiveTattooBlacklist?.includes('thinLines');

    if (artist.invoiceData.country) {
      this.formGet('invoiceData').get('country').setValue(this.countries.find(c => c.code === artist.invoiceData.country));
    }
    this.formGet('invoiceData').get('customerNumber').disable();
    this.formGet('calendarNumber').disable();

    this.registerFormChanges();
    this.formGet('calendarNumber').setValue(this.formGet('calendarNumber').value);

    if (!artist.disabledStudios) {
      artist.disabledStudios = [];
    }
    this.disabledStudios = {};
    for (const disabledStudio of artist.disabledStudios) {
      this.disabledStudios[disabledStudio] = true;
    }
  }


  checkNumberRangeFree(value: string) {
    const otherArtist = this.cacheService.artists.value.filter(a => a.id !== this.artist!.id).find(a => a.invoiceData.numberRange === value);
    if (otherArtist) {
      return 'hat bereits ' + otherArtist.name;
    }
    return '';
  }

  checkArtistName(value: string) {
    const otherArtist = this.cacheService.artists.value.filter(a => a.id !== this.artist!.id).find(a => a.name.toLowerCase() === value.toLowerCase());
    if (otherArtist) {
      return 'ist bereits vergeben';
    }
    return '';
  }

  async save() {
    const artist: NxtArtist = {...this.artist, ...this.form.getRawValue() as NxtArtist};
    if (this.saveSkillsToAc) {
      await this.socketService.setArtistSkillsToAc(artist);
      await TimeTools.sleep(1000);
      this.dialogRef.close({syncArtistFromAc: true});
      return;
    }

    if (this.configService.config.value.studioRegion !== 'STAGING' && this.configService.config.value.studioRegion !== 'BR' && this.configService.config.value.studioRegion !== 'ZONA') {
      if (this.configService.config.value.studioRegion !== 'AC' && !this.imported && !this.artist!.id) {
        this.dialogService.showOk('Du kannst hier keine neuen Artists anlegen, bitte importiere einen Artist aus Aachen!');
        return;
      }
    }

    artist.motiveTattooBlacklist = [];
    artist.disabledStudios = [];
    for (const key of Object.keys(this.disabledStudios)) {
      if (this.disabledStudios[key]) {
        artist.disabledStudios.push(key);
      }
    }
    if (this.motiveTattooBlacklist.round) {
      artist.motiveTattooBlacklist.push('round');
    }
    if (this.motiveTattooBlacklist.thinLines) {
      artist.motiveTattooBlacklist.push('thinLines');
    }
    if (await this.checkFormErrors(artist)) {
      artist.invoiceData.country = (artist.invoiceData.country as any)?.code;
      artist.name = artist.name.trim();
      artist.invoiceData.name = artist.invoiceData.name.trim();
      artist.lang = (artist.lang as any).value;
      this.saveRunning = true;
      this.dialogService.showLoading('Artist wird gespeichert...');
      await this.socketService.upsertArtist(artist);
      this.dialogService.hideLoading();
      this.dialogRef.close(true);
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  private async checkFormErrors(artistToSave: NxtArtist) {
    if (this.formGet('disabled').value) {
      return true;
    }
    this.form.markAllAsTouched();
    return FormTools.showErrorsTrueIfNoError(this.form, this.dialogService, await this.getAdditionalErrors(artistToSave));
  }

  enableName() {
    this.formGet('name').enable();
  }

  private async getAdditionalErrors(artistToSave: NxtArtist) {
    const allOtherArtists = this.cacheService.artists.value.filter(a => a.id !== artistToSave.id);
    const errors: string[] = [];
    if (allOtherArtists.find(a => a.calendarNumber === artistToSave.calendarNumber)) {
      errors.push('Kalendernummer ist bereits vergeben');
    }

    if (allOtherArtists.find(a => a.name === artistToSave.name)) {
      errors.push('Name ist bereits  vergeben');
    }

    if (allOtherArtists.find(a => a.invoiceData.numberRange === artistToSave.invoiceData.numberRange)) {
      errors.push('Rechnungskreis ist bereits vergeben');
    }
    return errors;
  }

  copyArtist() {
    const artistData: NxtArtist = this.form.getRawValue();
    this.clipboardService.copyToClipboard(JSON.stringify(artistData));
  }

  async pastArtist() {
    const dialog = await this.dialogService.showInputOld({message: 'Artist einfügen:'});
    const result: string = await dialog.afterClosed().toPromise();
    if (result?.startsWith('{"name"')) {
      const artistToSet = JsonTools.parse(result);
      artistToSet.calendarNumber = this.formGet('calendarNumber').value;
      artistToSet.contractSigned = this.formGet('contractSigned').value;
      artistToSet.invoiceData.customerNumber = this.formGet('invoiceData.customerNumber').value;
      this.form.setValue(artistToSet);
    }
  }

  /*async load(artistId: string) {
    this.allArtists = (await this.socketService.getArtists()).sort(SortTools.sortString('calendarNumber'));
    this.setArtist(this.allArtists.find(a => a.id === artistId));
  }*/

  private registerFormChanges() {

    this.formGet('calendarNumber').valueChanges.subscribe((calendarNumber) => {
      this.generateInvoiceDataCustomerNumber();
    });

    this.formGet('contractSigned').valueChanges.subscribe((value) => {
      if (value) {
        this.canEdit = true;
      }
    });

    this.formGet('invoiceData').get('country').valueChanges.subscribe((value) => {
      if (value && value.code && !value.eu) {
        this.dialogService.showOk('Das Land ist nicht in der EU\nsomit ist eine Zusammenarbeit nicht möglich');
        requestAnimationFrame(() => {
          this.formGet('invoiceData').get('country').setValue(null);
        });
      }
    });

    this.formGet('mobile').valueChanges.subscribe((value) => {
      const newMobile = MobileTools.format(value);
      if (newMobile && value !== newMobile) {
        this.formGet('mobile').setValue(MobileTools.format(value));
      }
    });

    this.formGet('workType').valueChanges.subscribe((value) => {
      this.showSkills = value === 'tattoo';
      this.showBlackList = value === 'tattoo' || value === 'piercing';
      if (value !== 'tattoo') {
        this.setSkillsToZero();
      }
    });
  }

  formGet(name: string) {
    return this.form.get(name) as UntypedFormControl;
  }

  setSkillsToZero() {
    this.formGet('skills').get('speed').setValue(0);
    const skillsValues = this.formGet('skills').get('skillValues').value;
    skillsValues.forEach(sv => {
      if (sv.value === -1) {
        sv.value = 0;
      }
    });
    this.formGet('skills').get('skillValues').setValue(skillsValues);
  }

  async bodyPutClick(index: number) {
    const result = await this.bodyPutService.showBodyPutChooser();
    this.artist!.bodyPutTattooBlacklist![index] = result.filter(p => p !== 'on').join(',');
  }

  newBodyPut() {
    if (this.artist) {
      if (!this.artist.bodyPutTattooBlacklist) {
        this.artist.bodyPutTattooBlacklist = [];
      }
      this.artist.bodyPutTattooBlacklist.push(null);
    }
  }

  bodyPutCellRenderer = (value: string) => {
    if (value) {
      const valueArray = value.split(',').filter(p => p !== 'on');
      return this.bodyPutService.getGermanPath(valueArray);
    }
    return '';
  };

  removeBodyPut(index: number) {
    this.artist.bodyPutTattooBlacklist.splice(index, 1);
  }

  setAddressFromGoogle(data: { street: string; streetNumber: string; postalCode: string; city: string; country: string; countryCode: string }) {
    const country = this.countries.find(c => c.code === data.countryCode);
    if (data.street && data.streetNumber) {
      this.formGet('invoiceData').get('street').setValue(data.street + ' ' + data.streetNumber);
    } else if (data.street) {
      this.formGet('invoiceData').get('street').setValue(data.street);
    } else {
      this.formGet('invoiceData').get('street').setValue('');
    }
    this.formGet('invoiceData').get('postalCode').setValue(data.postalCode);
    this.formGet('invoiceData').get('city').setValue(data.city);
    this.formGet('invoiceData').get('country').setValue(country);
  }

  showInMaps() {
    const text = this.formGet('invoiceData').get('street').value + ' ' +
      this.formGet('invoiceData').get('postalCode').value + ' ' +
      this.formGet('invoiceData').get('city').value + ' ' +
      this.formGet('invoiceData').get('country').value?.code;
    window.open('https://maps.google.com/maps?q=' + encodeURI(text), '_blank', 'popup=true');
  }

  private generateInvoiceDataCustomerNumber() {
    const calendarNumber = this.formGet('calendarNumber').value;
    this.formGet('invoiceData').get('customerNumber').setValue('A' + StringTools.fill(calendarNumber, 5, '0', false));
  }

  public async getArtistFromAc() {
    this.dialogService.showLoading('Lade Artists aus Aachen...');
    const artistsFromAc = await this.socketService.getArtistsFromAc();
    this.dialogService.hideLoading();
    const options = artistsFromAc.map(a => ({text: a.name, value: a})).sort(SortTools.sortString('text'));
    options.sortString('text');
    const artistToSet: NxtArtist = await this.dialogService.showSelect('Welchen Artist möchtest du aus Aachen importieren?', options, {placeholder: 'Artist auswählen'});
    if (artistToSet) {
      artistToSet.calendarNumber = this.formGet('calendarNumber').value;
      artistToSet.contractSigned = this.formGet('contractSigned').value;
      artistToSet.invoiceData.customerNumber = this.formGet('invoiceData.customerNumber').value;
      this.setData(artistToSet, false);
      this.imported = true;
    }
  }

  public openInsta() {
    window.open('https://instagram.com/' + this.formGet('instagram').value, '_blank');
  }

  async sendInvoices() {
    const result = await this.dialogService.showSelect('Alle Rechnungen aus eine Jahr an ' + this.artist.name + ' per WhatsApp schicken?',
      [
        {value: '2024', text: '2024'},
        {value: '2023', text: '2023'},
      ], {placeholder: 'Jahr auswählen', okButtonText: 'Senden'});
    if (result) {
      this.dialogService.showLoading('Rechnungen werden gesendet...');
      const lines: string[] = [];
      this.pushSocketSubscription = this.socketService.subscribeNew('responseStream_sendArtistInvoicesByYear', (message) => {
        lines.push(message.replaceAll('\n', ': '));
        this.dialogService.updateLoadingText(message);
      });
      await this.socketService.sendArtistInvoicesByYear(this.artist.name, result);
      await TimeTools.sleep(200);
      this.dialogService.showOk('Rechnungen wurden gesendet:\n' + lines.join('\n'));
      this.dialogService.hideLoading();
    }
  }

  async onFileDropped(files: File[]) {
    if (files.length === 0) {
      return;
    }
    if (files.length > 1) {
      this.dialogService.showOk('Bitte immer nur eine Datei');
      return;
    }
    const file = files[0];

    const buttons = [{text: 'Ausweis vorne', value: 'id-card-front'}, {text: 'Ausweis hinten', value: 'id-card-back'}];
    const src = URL.createObjectURL(file);
    const img = '<img src="' + src + '" style="width: 250px; height: fit-content" />';
    const html = '<div class="flex flex-col items-center justify-center">' + img + '<br/><div>Worum handelt es sich?</div></div>';
    const result = await this.dialogService.showButtons(html, {buttons, showCancelButton: true, textAlign: 'center'});
    if (result) {
      this.dialogService.showLoading('Ausweis wird hochgeladen...');
      const arrayBuffer = await file.arrayBuffer();
      const cropResult = await this.dialogService.showImageCrop('Zieh ein Viereck um den Ausweis', arrayBuffer, {buttonText: 'Hochladen'});
      if (cropResult) {
        await this.socketService.uploadArtistFile(this.formGet('name').value, result.value, file.name, cropResult.arrayBuffer);
      }
    }
    this.loadArtistFiles();
  }

  async loadArtistFiles() {
    if (this.artist.googleDriveFolderId) {
      this.artistFiles.set(await this.socketService.getGoogleDriveFolder(this.artist.googleDriveFolderId));
      this.dialogService.hideLoading();
    }
  }

  async telegramClicked() {
    const deleteIt = await this.dialogService.showYesNo('Chat-ID für ' + this.artist.name + '\n' + this.formGet('telegramChatId').value, {
      yesText: 'Chat löschen',
      noText: 'Zurück',
    });
    if (deleteIt) {
      this.formGet('telegramChatId').setValue(0);
    }
  }

  async fakeTelegram() {
    this.formGet('telegramChatId').setValue(Date.now());
  }


  async connectTelegramClicked() {
    const dialog = this.dialogService.showComponentDialog(TelegramArtistStartChatComponent);
    dialog.componentInstance.setArtist(this.artist.id);
  }

  openGoogleDriveClicked() {
    window.open('https://drive.google.com/drive/folders/' + this.artist.googleDriveFolderId, '_blank');
  }

  public async artistFileClicked(file: GoogleDriveFileInfo) {
    if (file.name.toLowerCase().endsWith('.pdf')) {
      this.dialogService.showLoading('PDF wird geladen...');
      const driveFile = await this.socketService.getGoogleDriveFile(file.id);
      if (driveFile.base64) {
        this.dialogService.showPdf(driveFile.base64, file.name);
      }
      this.dialogService.hideLoading();
    }
  }
}

