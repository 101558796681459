<div fxLayout="column" fxLayoutAlign="center center" *ngIf="result">
  <div fxLayout="column" fxLayoutAlign="center center" *ngIf="isInTime">
    <div class="artist-name">{{result.artist}}</div>
    <!--<div class="scan-time">{{result.createdAt | datePipe: 'HH:mm'}}</div>-->
    <div *ngIf="!showTime" class="time-left-small">{{restTime | duration: 'mm:ss'}}</div>
    <div *ngIf="showTime" class="time-left">{{restTime | duration: 'mm:ss'}}</div>
  </div>
  <div fxLayout="column" *ngIf="!isInTime">
    <div class="no-photo">NO PHOTOS!</div>
  </div>
</div>
