<div class="nxt-page w-full h-100 p-0 wrapper" [ngClass]="{brudis: studio === 'br'}" fxLayout="column" fxLayoutAlign="center center">
  <div fxFlex class="o-auto w-full" fxLayout="column" fxLayoutAlign="start center" style="scrollbar-width: 10px">
    <div class="logo-wrapper" fxLayoutAlign="center" fxLayout="row">
      <img style="width: 100%" *ngIf="logoSrc" [src]="logoSrc"/>
    </div>
    <div style="font-size: 120%" *ngIf="checkoutData">
      <br/>
      <div fxLayout="column" fxLayoutGap="0.8em" fxLayoutAlign="center center" *ngIf="status === 'selectPaymentMethod'">
        <div>Hallo {{checkoutData.name}}!</div>
        <div style="text-align: center" *ngIf="checkoutData.text">{{checkoutData.text}}</div>
        <div>Betrag: {{checkoutData.value | money}}</div>
        <div style="font-size: 80%">
          <nxt-slide-toggle [(value)]="agbOk" (valueChange)="agbOkChanged()">Hiermit bestätige ich<br/>die {{studio === 'br' ? 'Brudis' : 'NXT-LVL.INK'}} <a
            target="_blank" [href]="'https://' + studio + '.nxt-lvl.ink/agb'"><strong>AGB</strong></a></nxt-slide-toggle>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="center center" *ngIf="status === 'payed'">
        <div>Vielen Dank für deine Zahlung!</div>
        <br/>
        <div>Du kannst diese Seite nun schließen</div>
      </div>
      <!--<nxt-button *ngIf="status === 'payed'" (click)="refund()">Rückerstatten</nxt-button>-->
    </div>
    <br/>

    <br/>
    <div fxLayout="row" fxLayoutAlign="center" [ngClass]="{hidden: !agbOk || status !== 'selectPaymentMethod'}"
         style="padding: 15px; border-radius: 4px; background-color: #fff;">
      <div id="klarnaPaymentsContainer"></div>
    </div>
    <nxt-button *ngIf="agbOk && status === 'selectPaymentMethod'" (click)="authorize()">weiter</nxt-button>
  </div>
  <div class="pb-2 footer center">{{footerText}}</div>
</div>
