<table class="w-full" *ngIf="studio">
  <tr>
    <td>Name</td>
    <td>
      <nxt-input [(value)]="studio.name" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Seitenkasse</td>
    <td style="padding-top: 7px; padding-bottom: 7px">
      <nxt-slide-toggle [(value)]="studio.onlyCash"></nxt-slide-toggle>
    </td>
  </tr>
  <tr *ngIf="studio.onlyCash">
    <td>Hauptkassenname</td>
    <td>
      <nxt-input [(value)]="studio.realStudio" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr *ngIf="!studio.onlyCash">
    <td>Adresse</td>
    <td>
      <nxt-input [(value)]="studio.addressOneLine" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr *ngIf="!studio.onlyCash">
    <td>Google Maps Link</td>
    <td>
      <nxt-input [(value)]="studio.googleMapsLink" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Kurzname</td>
    <td>
      <nxt-input [(value)]="studio.shortName" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr *ngIf="!studio.onlyCash">
    <td>Link Einver. Tattoo</td>
    <td>
      <nxt-input [(value)]="studio.acceptanceLinkTattoo" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr *ngIf="!studio.onlyCash">
    <td>Link Einver. Piercing</td>
    <td>
      <nxt-input [(value)]="studio.acceptanceLinkPiercing" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Debug (für Julian)</td>
    <td>
      <nxt-input [(value)]="studio.debug" [noPadding]="true"></nxt-input>
    </td>
  </tr>
</table>
