<nxt-form-field-wrapper class="flex" [nxtFormControl]="nxtFormControl" [width]="width" [class.no-padding-bottom]="noPaddingBottom">
  <mat-form-field fxLayout="column" class="mat-form-field-full-width" [ngStyle]="{width: icon ? 'calc('+ width + ' - 28px)' : width}">
    <mat-label>{{placeholder}}</mat-label>
    <div fxLayout="row" class="textarea-wrapper">
      <textarea
          #controlElement
          matInput
          [formControl]="nxtFormControl"
          [rows]="rows"
          [cols]="cols"
          (keyup)="keyup($event)"
          [spellcheck]="spellcheck"
      ></textarea>
    </div>
    <mat-error *ngIf="nxtFormControl.invalid && (nxtFormControl.dirty || nxtFormControl.touched)">
      <div *ngIf="nxtFormControl.errors.required">
        {{requiredError}}
      </div>
      <div *ngIf="$any(nxtFormControl.errors).validCurrency">
        ungültiger Wert
      </div>
    </mat-error>
  </mat-form-field>

  <mat-icon (click)="icon.click()" style="color:#a2a2a2; position:relative; font-size:18px; top:6px; cursor: pointer;"
            *ngIf="icon" [matTooltip]="icon.tooltip">{{icon.name}}</mat-icon>

</nxt-form-field-wrapper>
