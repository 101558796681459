import {Component, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {ActivatedRoute} from '@angular/router';
import {ConfigService} from '../../../services/config.service';
import {JsonTools} from '../../../common-browser/helpers/json.tools';
import {DeclarationsOfConsentSectionComponent} from '../../declarations-of-consent/declarations-of-consent-section/declarations-of-consent-section.component';
import {NgIf} from '@angular/common';
import {TranslateDirective} from '../../../directives/translate.directive';

@Component({
  selector: 'nxt-after-care-instruction-tooth-gem',
  templateUrl: './after-care-instruction-tooth-gem.component.html',
  styleUrls: ['./after-care-instruction-tooth-gem.component.scss'],
  imports: [NgIf, DeclarationsOfConsentSectionComponent, TranslateDirective]
})
export class AfterCareInstructionToothGemComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  pageCountCurrent = 1;
  pageCountTotal = 1;
  lang = 'de';

  constructor(
    public configService: ConfigService,
    private route: ActivatedRoute,
  ) {
    super();
    if (this.route.snapshot.queryParams.page) {
      this.pageCountCurrent = parseInt(this.route.snapshot.queryParams.page, 10);
      this.lang = this.route.snapshot.queryParams.lang || 'de';
    }
    if (this.route.snapshot.queryParams.data) {
      const data = JsonTools.parse(this.route.snapshot.queryParams.data);
      this.pageCountCurrent = data.page;
      this.lang = data.lang || 'de';
    }
  }

  ngOnInit() {
    window.document.body.style.backgroundColor = 'transparent';
  }

  nxtOnDestroy() {

  }
}
