import {NxtInventoryItem, NxtInventoryStockItem} from '../../common-interfaces/nxt.inventory-item.interface';
import {UuidTools} from '../helpers/uuid.tools';
import {BehaviorSubject, Subscription} from 'rxjs';

export class NxtInventoryService {
  public inventoryLocations: { text: string, value: string }[] = [
    {text: 'Studio', value: 'studio'},
    {text: 'Piercing', value: 'piercing'},
    {text: 'Küche', value: 'kitchen'},
    {text: 'Reinigung', value: 'cleaning'},
  ];

  public get inventorySourcesOfSupply() {
    return this._inventorySourcesOfSupply.sortString('text');
  }

  private _inventorySourcesOfSupply: { text: string, value: string, newValue?: string, printEn?: boolean, viewOrderListText?: boolean, specialButton?: string }[] = [
    {text: 'ALDI', value: 'ALDI'},
    {text: 'Amazon', value: 'Amazon'},
    {text: 'DM / Rossmann', value: 'DM / Rossmann'},
    {text: 'Hygi', value: 'Hygi'},
    {text: 'Killer Ink', value: 'Killer Ink'},
    {text: 'Office Discount', value: 'Office Discount'},
    {text: 'Magic Moon', value: 'Magic Moon'},
    {text: 'Rezwan Haque', value: 'Rezwan Haque'},
    {text: 'Sorry Mom Mallorca', value: 'Sorry Mom Mallorca', printEn: true},
    {text: 'Osman', value: 'Osman'},
    {text: 'Flyeralarm', value: 'Flyeralarm'},
    {text: 'WIRmachenDRUCK', value: 'WIRmachenDRUCK'},
    {text: 'Onlineprinters', value: 'Onlineprinters'},
    {text: 'Rezwan', value: 'Rezwan'},
    {text: 'Mercadona', value: 'Mercadona'},
    {text: 'Flaschenpost', value: 'Flaschenpost'},
    {text: 'Sonstige', value: 'Sonstige'},
    {text: 'Rex-Rotary', value: 'Rex-Rotary'},
    {text: 'Franz Mensch', value: 'Franz Mensch'},
    {text: 'TattooKing', value: 'TattooKing'},
    {text: 'Restless', value: 'Restless'},
    {text: 'ARNOWA', value: 'ARNOWA'},
    {text: 'Suministros Clinicos Baleares S.L', value: 'Suministros Clinicos Baleares S.L'},
    {text: 'Sharon', value: 'Sharon', viewOrderListText: true},
    {text: 'GO2', value: 'GO2', specialButton: '-'},
  ];

  constructor(
    private collection: <T>(path: string, queryFn?: any) => any,
    private getUsername: () => Promise<string> | string,
    private studio: string,
  ) {
    if (this.studio === 'Aachen') {
      this.studio = 'Villa';
    }
    if (studio === 'Brudis') {
      this.inventoryLocations = [
        {text: 'Bar', value: 'bar'},
        {text: 'Barlager', value: 'bar-stock'},
        {text: 'Küche', value: 'kitchen'},
        {text: 'Küchenlager', value: 'kitchen-stock'},
      ];

      this._inventorySourcesOfSupply = [
        {text: 'Distribuidora Rotger', value: 'Distribuidora Rotger'},
        {text: 'Lavazza', value: 'Lavazza'},
        {text: 'Giovanni L.', value: 'Giovanni L.'},
        {text: 'Disbal Voldis', value: 'Disbal Voldis'},
        {text: 'Distribuidora Tunel', value: 'Distribuidora Tunel'},
        {text: 'Mercadona', value: 'Mercadona'},
        {text: 'Knapp', value: 'Knapp'},
        {text: 'Garcimar', value: 'Garcimar'},
        {text: 'Palmafruit', value: 'Palmafruit'},
        {text: 'Makro', value: 'Makro'},
        {text: 'A.A.Dunn', value: 'A.A.Dunn'},
        {text: 'Ille', value: 'Ille'},
      ];
    }
  }

  public get inventoryStockItems(): BehaviorSubject<NxtInventoryStockItem[]> {
    if (!this._inventoryStockItems) {
      this._inventoryStockItems = new BehaviorSubject<NxtInventoryStockItem[]>([]);
      this.inventoryStockSubscription?.unsubscribe();
      this.inventoryStockSubscription = this.inventoryStockValueChanges().subscribe((inventoryStock) => {
        // inventoryStock = inventoryStock.filter(d => d.id === '907d6610-3198-11ec-8658-97e619e35e75');
        this._inventoryStockRawItems.next(inventoryStock as NxtInventoryStockItem[]);
        this.inventoryStockRawItemsLoaded = true;
        const stock = this.mergeInventoryItemsAndStock(this.inventoryItems.value, inventoryStock as NxtInventoryStockItem[]);
        this._inventoryStockItems.next(stock as NxtInventoryStockItem[]);
      });
      this.inventoryItemsInternSubscription?.unsubscribe();
      this.inventoryItemsInternSubscription = this.inventoryItems.subscribe((items) => {
        // this.clearStudioFromItems(items);
        const stock = this.mergeInventoryItemsAndStock(items, this._inventoryStockRawItems.value);
        if (this.inventoryStockRawItemsLoaded) {
          this._inventoryStockItems.next(stock as NxtInventoryStockItem[]);
        }
      });
    }
    return this._inventoryStockItems;
  }


  public get inventoryItems(): BehaviorSubject<NxtInventoryItem[]> {
    if (!this._inventoryItems) {
      this._inventoryItems = new BehaviorSubject<NxtInventoryItem[]>([]);
      this.inventoryItemsSubscription = this.inventoryItemsValueChanges().subscribe((data: any[]) => {
        data.forEach(i => {
          if (!i.packages) {
            i.packages = [];
          }
          if (typeof i.amountAlarm !== 'number' || Number.isNaN(i.amountAlarm)) {
            i.amountAlarm = 0;
          }
          if (typeof i.amountFull !== 'number' || Number.isNaN(i.amountFull)) {
            i.amountFull = 0;
          }
        });
        this._inventoryItems.next(data as NxtInventoryItem[]);
      });
    }
    return this._inventoryItems;
  }

  // public studios: string[] = ['Villa', 'Brand'];
  private inventoryStockRawItemsLoaded = false;

  private _inventoryStockRawItems: BehaviorSubject<NxtInventoryStockItem[]> = new BehaviorSubject<NxtInventoryStockItem[]>([]);
  private _inventoryStockItems: BehaviorSubject<NxtInventoryStockItem[]>;
  private _inventoryItems: BehaviorSubject<NxtInventoryItem[]>;


  inventoryStockSubscription: Subscription;
  inventoryItemsSubscription: Subscription;
  inventoryItemsInternSubscription: Subscription;

  clearIsRunning = false;

  public mergeStockItemWithItem(item: NxtInventoryItem, stockItem?: NxtInventoryStockItem) {
    if (!stockItem) {
      stockItem = {amount: 0, studio: this.studio} as NxtInventoryStockItem;
    }
    const result = {...stockItem};
    result.id = item.id;
    result.name = item.name;
    result.nameEn = item.nameEn;
    result.nameBySource = item.nameBySource;
    result.amountPerPackage = item.amountPerPackage;
    result.location = item.location;
    result.deleted = item.deleted;
    result.orderValue = item.orderValue;
    result.deleted = !!item.deleted;
    result.unitOfMeasure = item.unitOfMeasure;

    result.nfcId = item.nfcId;
    result.sourceOfSupply = item.sourceOfSupply;
    result.sourceOfSupplyUrl = item.sourceOfSupplyUrl;
    result.sourceOfSupply2 = item.sourceOfSupply2;
    result.sourceOfSupply2Url = item.sourceOfSupply2Url;
    result.amountFull = item.amountFull;
    result.amountAlarm = item.amountAlarm;
    result.responsibility = item.responsibility;
    result.responsibility2 = item.responsibility2;
    result.orderedByAc = item.orderedByAc;
    result.onlyFullOrEmptyInfo = item.onlyFullOrEmptyInfo;
    result.onlyFullOrEmpty = item.onlyFullOrEmpty;
    result.packages = item.packages;
    result.notOrderBefore = item.notOrderBefore;
    return result;
  }

  getSourceText(value) {
    return this.inventorySourcesOfSupply.find(s => s.value === value)?.text || value;
  }

  getSourceValue(text) {
    return this.inventorySourcesOfSupply.find(s => s.text === text)?.value || text;
  }

  destroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    this.inventoryStockSubscription?.unsubscribe();
    this.inventoryItemsInternSubscription?.unsubscribe();
    this.inventoryItemsSubscription?.unsubscribe();
  }

  protected mergeInventoryItemsAndStock(nxtInventoryItems: NxtInventoryItem[], nxtInventoryStockItems: NxtInventoryStockItem[]) {
    const newInventoryStockItems: NxtInventoryStockItem[] = [];
    if (nxtInventoryItems.length === 0) {
      return [];
    }
    /*const toAddItems: NxtInventoryStockItem[] = [];
    const stockIds: string[] = nxtInventoryStockItems.filter(stockItem => stockItem.studio === this.studio).map(stockItem => stockItem.id);
    const toAddItemsStudio = ObjectTools.clone(nxtInventoryItems.filter(item => stockIds.indexOf(item.id) === -1)) as NxtInventoryStockItem[];
    toAddItemsStudio.forEach(stockItem => {
      stockItem.studio = this.studio;
      stockItem.amount = 0;
      // stockItem.amountAlarm =  stockItem.studiosAmounts?.find(s => s.name === stockItem.studio)?.amountAlarm ?? stockItem.amountAlarm;
      // stockItem.amountFull = stockItem.studiosAmounts?.find(s => s.name === stockItem.studio)?.amountFull ?? stockItem.amountFull;
    });
    toAddItems.push(...toAddItemsStudio);*/

    for (const nxtInventoryItem of nxtInventoryItems) {
      const stockItem = nxtInventoryStockItems.find(s => s.id === nxtInventoryItem.id && s.studio === this.studio);
      newInventoryStockItems.push(this.mergeStockItemWithItem(nxtInventoryItem, stockItem));
    }
    /*nxtInventoryStockItems = nxtInventoryStockItems.map(stockItem => {
      const item = nxtInventoryItems.find(i => i.id === stockItem.id);
      if (item) {
        stockItem = NxtInventoryService.mergeStockItemWithItem(stockItem, item);
      } else {
      }
      if (!stockItem.unitOfMeasure) {
        stockItem.unitOfMeasure = 'Stk';
      }
      return stockItem;
    });*/

    const result = newInventoryStockItems.filter(i => !i.deleted);
    return result;
  }

  /**
   * wird in NODE-JS überschrieben wegen history!
   * wird im frontend benutzt
   */
  async upsertInventoryItem(nxtInventoryItem: NxtInventoryItem, username: string) {
    if ((nxtInventoryItem as any).lastOrders) {
      delete (nxtInventoryItem as any).lastOrders;
      debugger;
    }
    if ((nxtInventoryItem as any).studio) {
      delete (nxtInventoryItem as any).studio;
      debugger;
    }
    if ((nxtInventoryItem as any).amount) {
      delete (nxtInventoryItem as any).amount;
      debugger;
    }
    if (!nxtInventoryItem.onlyFullOrEmpty) {
      nxtInventoryItem.onlyFullOrEmpty = false;
    }
    if (!nxtInventoryItem.onlyFullOrEmptyInfo) {
      nxtInventoryItem.onlyFullOrEmptyInfo = '';
    }
    let action = '';
    let result;
    if (!nxtInventoryItem.id) {
      nxtInventoryItem.id = UuidTools.generate();
      action = 'create';
      result = await this.collection('inventory-items').doc(nxtInventoryItem.id).set(nxtInventoryItem);
    } else {
      action = 'update';
      result = await this.collection('inventory-items').doc(nxtInventoryItem.id).set(nxtInventoryItem);
    }
    this.collection('history/inventory-items/' + nxtInventoryItem.id).add({
      data: JSON.stringify(nxtInventoryItem),
      created: new Date(),
      user: username,
      action,
    });
    return result;
  }

  // abstract createOrder(nxtInventoryItem: NxtInventoryItem): void;

  async book(id: any, studio: string, amountOffset: number) {
    try {
      const inventoryItem = this.inventoryStockItems.value.find(i => i.id === id);
      if (!inventoryItem) {
        throw Error('inventoryItem ' + id + ' not found');
      }
      if (inventoryItem && inventoryItem.onlyFullOrEmpty) {
        if (inventoryItem.amount === 1 && amountOffset > 0) {
          amountOffset = 0;
        }
        if (inventoryItem.amount === 0 && amountOffset < 0) {
          amountOffset = 0;
        }
      }
      const stockItemToUpdate = this._inventoryStockRawItems.value.find(stockItem2 => stockItem2.id === id && stockItem2.studio === studio);
      let stockItemToInsert: any;
      let inventoryStockId = '';
      let oldAmount = 0;
      let newAmount = 0;
      let result;
      if (typeof amountOffset === 'string') {
        amountOffset = parseInt(amountOffset, 10);
      }
      if (stockItemToUpdate) {
        if (typeof stockItemToUpdate.amount === 'string') {
          stockItemToUpdate.amount = parseInt(stockItemToUpdate.amount, 10);
        }
        oldAmount = stockItemToUpdate.amount;
        newAmount = stockItemToUpdate.amount + amountOffset;

        if (inventoryItem.onlyFullOrEmpty) {
          if (newAmount > 0) {
            newAmount = 1;
          } else if (newAmount < 1) {
            newAmount = 0;
          }
        }
        inventoryStockId = stockItemToUpdate.id + '_' + studio;
        const updateData = {
          amount: newAmount,
          updatedAt: new Date(),
          updatedBy: await this.getUsername(),
        };
        result = await this.collection('inventory-stock').doc(stockItemToUpdate.id + '_' + studio).update(updateData);
      } else {
        stockItemToInsert = this.inventoryItems.value.find(item => item.id === id) as NxtInventoryStockItem;
        stockItemToInsert.amount = amountOffset;
        newAmount = stockItemToInsert.amount;
        stockItemToInsert.studio = studio;
        inventoryStockId = stockItemToInsert.id + '_' + studio;
        stockItemToInsert.updatedAt = new Date();
        stockItemToInsert.updatedFrom = await this.getUsername();
        result = await this.setDoc('inventory-stock', stockItemToInsert.id + '_' + studio, stockItemToInsert);
        // result = await this.collection('inventory-stock').doc(stockItemToInsert.id + '_' + studio).set(stockItemToInsert);
      }
      const historyItem = {
        createdBy: await this.getUsername(),
        id: inventoryStockId,
        stockItemId: id,
        studio,
        createdAt: new Date(),
        oldAmount,
        newAmount,
      };
      this.setDoc('history/inventory-stock/' + inventoryStockId, UuidTools.generate(), historyItem);
      // const historyResult = await this.collection('history/inventory-stock/' + inventoryStockId).doc(UuidTools.generate()).set(historyItem);
      const stockItem = stockItemToUpdate ? stockItemToUpdate : stockItemToInsert;
      // this.dialogService.showToast('Inventar in ' + stockItem.studio + '\n' + stockItem.name + ': ' + oldAmount + ' Stk.  --> ' + newAmount + ' Stk.', 5000);
      return result;
    } catch (err) {
      throw new Error('inventory-list.service.book failed\n' + err.message);
    }
  }

  public inventoryItemsValueChanges() {
    if (this.collection) {
      return this.collection('inventory-items').valueChanges();
    } else {
    }
  }

  public inventoryStockValueChanges() {
    if (this.collection) {
      return this.collection('inventory-stock').valueChanges();
    }
  }

  public setDoc(collection: string, doc: string, data: any) {
    if (this.collection) {
      return this.collection(collection).doc(doc).set(data);
    }
  }
}
