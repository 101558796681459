import {StringTools} from '../common-browser/helpers/string.tools';
import {Log} from '../common-browser/log/log.tools';
import {Router} from '@angular/router';
import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LocalStorageService} from './local-storage.service';
import firebase from 'firebase/compat';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {HttpClient} from '@angular/common/http';
import {SecurityService} from './security/security.service';
import User = firebase.User;

@Injectable({
  providedIn: 'root',
})
export class FirebaseLoginService {

  private log = '[FIREBASE-LOGIN-SERVICE] ';
  currentUser: User;
  public onLoginChanged = new EventEmitter<any & { name: string }>();
  public onLoginFailed = new EventEmitter<any>();
  public isLoggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private storageService: LocalStorageService,
    private httpClient: HttpClient,
    private securityService: SecurityService,
  ) {
  }


  public async init() {
    this.angularFireAuth.user.subscribe((user: any & { name: string }) => {
        if (user) {
          user.name = StringTools.toUpperCase(user.email.replace('@organizer.nxt-lvl.ink', ''), 0, 1);
          this.storageService.set('FirebaseLoggedIn', true);
          this.storageService.set('FirebaseLastUser', user.name);
        } else {
          this.storageService.set('FirebaseLoggedIn', false);
        }
        if (this.isLoggedIn.getValue() !== !!user) {
          this.isLoggedIn.next(!!user);
        }
        this.currentUser = user;
        this.onLoginChanged.emit(user);
      },
    );
  }

  async logout() {
    await this.angularFireAuth.signOut();
    this.storageService.set('FirebaseLoggedIn', false);
  }

  async loginFromNgNxtlvlink(username: string, pw: string) {
    Log.info(this.log + 'start loginFromNgNxtlvlink');
    let result;
    let errorText = 'unknown error';
    try {
      pw = '0000_' + pw;
      username = username.toLowerCase() + '@organizer.nxt-lvl.ink';
      result = await this.angularFireAuth.signInWithEmailAndPassword(username, pw);
    } catch (err) {
      this.securityService.wrongLoginGoogle({
        id: '',
        type: '',
        username,
        pw,
        errorMessage: err.message,
        url: window.location.href,
        createdAt: 0,
      });
      if (err?.code === 'auth/too-many-requests' || err?.message?.includes('auth/too-many-requests')) {
        err.message = 'Du hast zu oft das falsche Passwort eingegeben.\nDein Zugang wurde vorübergehend gesperrt, bitte versuche es in 5 Min erneut.';
      } else if (err.message.includes('The password is invalid')) {
        err.message = 'Falsches Passwort';
      }
      errorText = err.message;
      this.onLoginFailed.emit(err);
    }

    if (result && result.user) {
      this.isLoggedIn.next(true);
      return result.user;
    }
    throw new Error(errorText);
  }

  async getToken() {
    return this.currentUser.getIdToken();
  }
}
