import {Pipe, PipeTransform} from '@angular/core';
import {DateTools} from '../common-browser/helpers/date.tools';

@Pipe({
  name: 'nxtDate',
  standalone: true
})
export class NxtDatePipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, format: string): any {
    try {
      return DateTools.format(value, format);
    } catch (err) {
      return '?';
    }
  }
}
