@if (document(); as document) {
  <div class="flex flex-col">
    <div class="flex flex-row justify-end">
      <nxt-button-icon (click)="closeClicked()">close</nxt-button-icon>
    </div>
    <div>
      <table class="nxt-table-new">
        @for (change of document.nxtAi.manualChanges; track change.at) {
          <tr>
            <td>{{ change.at | nxtDate: 'dd.MM.yyyy HH:mm' }}</td>
            <td>{{ change.by }}</td>
            <td>
              <div class="flex flex-col">
                @for (itemChange of change.changes; track itemChange.key) {
                  <div>{{ itemChange.keyText }}: {{ itemChange.oldValue || 'leer' }} ➞ {{ itemChange.newValue }}</div>
                }
              </div>
            </td>
            <td nxtPermission="IsJulian">
              <nxt-button (click)="deleteChange(change)">Eintrag löschen</nxt-button>
            </td>
          </tr>
        }
      </table>
    </div>
  </div>
}
