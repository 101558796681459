<div class="flex flex-col nxt-page">

  <div class="flex flex-col">
    <div class="center text-xl w-full my-4">
      Kontakte zusammenführen
    </div>

    <div>Wähle die Korrekten Daten!</div>
    @if (possibleAttributes()) {
      <table class="nxt-table" style="border: none;">
        <tr class="header-tr">
          <th *ngIf="possibleAttributes().givenName.length > 0">Vorname</th>
          <th *ngIf="possibleAttributes().familyName.length > 0">Nachname</th>
          <th *ngIf="possibleAttributes().birthday.length > 0">Geburtstag</th>
          <th *ngIf="possibleAttributes().mobile.length > 0">Handy</th>
          <th *ngIf="possibleAttributes().email.length > 0">E-Mail</th>
          <th *ngIf="possibleAttributes().streetAddress.length > 0">Straße</th>
          <th *ngIf="possibleAttributes().postalCode.length > 0">PLZ</th>
          <th *ngIf="possibleAttributes().city.length > 0">Ort</th>
          <th *ngIf="possibleAttributes().country.length > 0">Land</th>
          <th *ngIf="possibleAttributes().lang.length > 0">Sprache</th>
        </tr>
        <tr>
          @for (attr of attributes(); track attr) {
            @if (possibleAttributes()[attr].length === 1) {
              <td>
                @if (attr === 'birthday') {
                  <div>{{ possibleAttributes()[attr][0]| nxtDate: 'dd.MM.yyyy' }}</div>
                } @else {
                  <div>{{ possibleAttributes()[attr][0] }}</div>
                }
              </td>
            }
            @if (possibleAttributes()[attr].length > 1) {
              <td [class.background-dark-red]="newContact()[attr] === null" [class.background-green]="newContact()[attr] !== null">
                <div class="flex flex-col items-center gap-3">
                  @for (value of possibleAttributes()[attr]; track value) {
                    @if (attr === 'birthday') {
                      <nxt-button (click)="setAttrClicked(attr, value)">{{ value | nxtDate: 'dd.MM.yyyy' }}</nxt-button>
                    } @else {
                      <nxt-button (click)="setAttrClicked(attr, value)">{{ value }}</nxt-button>
                    }
                  }
                </div>
              </td>
            }
          }
        </tr>

        <tr *ngIf="newContact">
          <td colspan="8" style="border:none;" class="center">
            <div class="text-xl pt-3">Ergebnis</div>
          </td>
        </tr>
        <tr *ngIf="newContact()" style="font-weight: bold">
          @for (attr of attributes(); track attr) {
            @if (possibleAttributes()[attr].length > 0) {
              <td [class.background-dark-red]="newContact()[attr] === null">
                <div class="flex flex-row items-center">
                  @if (attr === 'birthday') {
                    {{ newContact()[attr] | nxtDate: 'dd.MM.yyyy' }}
                  } @else {
                    {{ newContact()[attr] }}
                  }
                </div>
              </td>
            }
          }
        </tr>
      </table>
    }
    <div class="flex flex-row mt-4 justify-around">
      <nxt-button (click)="close()">Abbrechen</nxt-button>
      <nxt-button *ngIf="canCombine()" (click)="combine()">Zusammenführen</nxt-button>
    </div>
  </div>
</div>
