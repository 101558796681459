import {Component, Inject} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {NxtFormControl} from '../../../nxt-form/nxt.form-control';
import {NxtCalendarEventBodyPutTattoo} from '../../../common-interfaces/nxt.calendar-event.interface';
import {BodyPutService} from '../../../services/body-put.service';
import {SocketService} from '../../../services/socket/socket.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BodyPutTextPipe} from '../../../pipes/body-put-text.pipe';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {NxtButtonComponent} from '../../button/nxt-button.component';
import {TextareaComponent} from '../../../components/form-controls/textarea/textarea.component';
import {NgFor, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {ValidatorTools} from '../../../helpers/validator.tools';

export interface DialogTextareaOptions {
  message: string;
  prompt?: string;
  buttonText?: string;

  placeholder?: string;
  cols?: number;
  charCounterFn?: (text) => string;
}

/**
 * @title Dialog Overview
 */
@Component({
    selector: 'nxt-dialog-send-message-to-artist',
    templateUrl: './dialog-send-message-to-artist.component.html',
    styleUrls: ['./dialog-send-message-to-artist.component.scss'],
    imports: [
        FlexModule,
        NgIf,
        TextareaComponent,
        NgFor,
        NxtButtonComponent,
        SafeHtmlPipe,
        BodyPutTextPipe,
    ]
})
export class DialogSendMessageToArtistComponent {

  placeholder: string;
  message: string;
  buttonText: string;
  cols = 50;
  inputFormControl: UntypedFormControl;
  charCounterText = '';
  bodyPutsTattoo: NxtCalendarEventBodyPutTattoo[];
  skillsText = '';
  private artistLang = '';

  constructor(
    public dialogRef: MatDialogRef<DialogSendMessageToArtistComponent>,
    private bodyPutService: BodyPutService,
    private socketService: SocketService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  setOptions(options: DialogTextareaOptions & { bodyPutsTattoo?: NxtCalendarEventBodyPutTattoo[], skills: string[], artistLang: string }) {
    this.placeholder = options.placeholder;
    this.message = options.message;
    this.artistLang = options.artistLang || 'de';
    this.buttonText = options.buttonText;
    this.bodyPutsTattoo = options.bodyPutsTattoo;
    this.skillsText = options.skills?.join(' & ');
    if (options.cols) {
      this.cols = options.cols;
    }

    this.inputFormControl = new NxtFormControl(options.prompt ? options.prompt : '', [ValidatorTools.requiredAndNotNaN]);

    this.inputFormControl.valueChanges.subscribe((value) => {
      if (options.charCounterFn) {
        this.charCounterText = options.charCounterFn(value);
      }
    });
    if (options.charCounterFn) {
      this.charCounterText = options.charCounterFn(options.prompt);
    }
  }

  close() {
    if (!this.inputFormControl.invalid) {
      this.dialogRef.close(this.inputFormControl.value);
    }
  }

  okClicked() {
    if (!this.inputFormControl.invalid) {
      this.dialogRef.close(this.inputFormControl.value);
    }
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  public enterClicked() {
    this.inputFormControl.setValue(this.inputFormControl.value + '\n');
  }

  public async addBodyPutText(bodyPutTattoo: NxtCalendarEventBodyPutTattoo) {
    // this.artistLang = 'de';
    this.socketService.getArtists();
    const motiveDe = bodyPutTattoo.motive;
    const motiveEn = (await this.socketService.translate(bodyPutTattoo.motive, 'en')).text;
    let textEn = 'Motive: ' + motiveEn + '\nBody part: ' + this.bodyPutService.getEnPath(bodyPutTattoo.bodyPut);
    if (bodyPutTattoo.size) {
      textEn += '\nSize: ' + bodyPutTattoo.size;
    }
    let textDe = 'Motiv: ' + motiveDe + '\nKörperstelle: ' + this.bodyPutService.getGermanPath(bodyPutTattoo.bodyPut);
    if (bodyPutTattoo.size) {
      textDe += '\nGröße: ' + bodyPutTattoo.size;
    }
    let textToAdd = textEn;
    if (this.artistLang === 'de') {
      textToAdd = textDe;
    }
    /*if (this.artistLang !== 'en') {
      textToAdd += '\nDE: ' + textDe;
    }*/
    this.inputFormControl.setValue(this.inputFormControl.value.trimEnd('\n') + '\n\n' + textToAdd);
  }

  public addSkill() {
    this.inputFormControl.setValue(this.inputFormControl.value + '\n\n' + 'Style: ' + this.skillsText);
  }
}
