import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, OnInit, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import {NxtCalendarEventFile} from '../../common-interfaces/nxt.calendar-event.interface';
import {DriveTools} from '../../common-browser-public/helpers/drive.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {KeyCode, ShortcutService} from '../../services/shortcut.service';
import {MatIcon} from '@angular/material/icon';
import {DownloadService} from '../../services/download.service';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
    selector: 'nxt-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIcon,
    MatTooltip,
  ]
})

export class VideoComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  src = signal('');
  filename = signal('');
  mimeType = signal('');

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private downloadService = inject(DownloadService);
  private dialogRef = inject(MatDialogRef);
  private shortcutService = inject(ShortcutService);

  get transparentBackground() {
    return true;
  }

  @ViewChild('videoPlayer') set videoElement(elem: ElementRef) {
    if (elem) {
      this.player = videojs(elem.nativeElement, {
        disablePictureInPicture: true,
        controls: true,
        autoplay: true,
        preload: 'auto',
        fluid: true,
        fill: true,
        bigPlayButton: true,
      });
      this.player.on('touchstart', (event) => {
        const touchY = event.touches[0].clientY;
        // alert('touchy: ' + touchY);

        // Ermittelt den unteren Rand des Players
        const videoElement = this.player.el() as HTMLElement;
        const playerRect = videoElement.getBoundingClientRect();
        const playerBottom = playerRect.bottom;

        // Berechnet den Abstand vom Touch-Punkt zum unteren Rand des Players
        const distanceFromBottom = playerBottom - touchY;

        // Nur abspielen/pausieren, wenn der Touch-Punkt mehr als 75px vom unteren Rand entfernt ist
        // alert(distanceFromBottom);
        if (distanceFromBottom > 60) {
          if (this.player.paused()) {
            this.player.play();
          } else {
            this.player.pause();
          }
        }
      });


      this.player.on('click', (event) => {
        const videoElement = this.player.el() as HTMLElement;
        const rect = videoElement.getBoundingClientRect();

        // Position des Klicks
        const clickX = event.clientX - rect.left;
        const clickY = event.clientY - rect.top;

        // Dimensionen des tatsächlichen Video-Contents
        const videoWidth = this.player.videoWidth();
        const videoHeight = this.player.videoHeight();

        // Aktuelle Dimensionen des HTML-Video-Elements
        const elementWidth = videoElement.clientWidth;
        const elementHeight = videoElement.clientHeight;

        // Berechnung der sichtbaren Video-Dimensionen (unter Berücksichtigung von Letterboxing)
        const aspectRatio = videoWidth / videoHeight;
        let visibleWidth = elementWidth;
        let visibleHeight = elementWidth / aspectRatio;

        if (visibleHeight > elementHeight) {
          visibleHeight = elementHeight;
          visibleWidth = elementHeight * aspectRatio;
        }

        const paddingX = (elementWidth - visibleWidth) / 2;
        const paddingY = (elementHeight - visibleHeight) / 2;

        // Überprüfen, ob der Klick im unteren Rand von 75px erfolgt
        const bottomPadding = 75;
        const clickedInBottomArea = clickY >= elementHeight - bottomPadding;

        // Überprüfen, ob der Klick im sichtbaren Bereich des Videos war
        const clickedInsideVideo =
          clickX >= paddingX &&
          clickX <= paddingX + visibleWidth &&
          clickY >= paddingY &&
          clickY <= paddingY + visibleHeight;

        if (clickedInBottomArea) {
          console.log('Klick im unteren Randbereich von 75px');
        } else if (clickedInsideVideo) {
          console.log('Klick auf das Video');
        } else {
          this.dialogRef.close();
        }
      });
    }
  }

  player!: Player;

  constructor() {
    super();
  }

  ngOnInit() {
    this.pushSubscription = this.shortcutService.onKeyPressExclusive.subscribe([KeyCode.Esc], () => this.closeClicked());
  }

  nxtOnDestroy() {
  }

  loadDriveVideo(eventFile: NxtCalendarEventFile) {
    this.src.set(DriveTools.getDriveLink(eventFile.id));
    this.mimeType.set(eventFile.mimetype);
    this.filename.set(eventFile.name);
  }

  closeClicked() {
    this.dialogRef.close();
  }


  downloadClicked() {
    this.downloadService.downloadUrl(this.src(), this.filename());
  }

  shareClicked() {

  }
}
