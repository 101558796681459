<nxt-page>
  <nxt-page-header>
    <nxt-page-header-title>Messenger</nxt-page-header-title>

  </nxt-page-header>
  <nxt-page-content>
    <nxt-datagrid
      [columnDefs]="columnDefs()"


    />

  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>

