<div *ngIf="form && artist" class="nxt-page" fxFlex fxLayout="row" fxLayoutAlign="center">
  <div fxFlex fxFlex.gt-sm="80" fxLayout="column">
    <div class="title" fxLayout="row" fxLayoutAlign="center center">
      Artist {{ form.get('name').value }}
    </div>
    <div fxLayout="row" fxLayoutAlign="end" *ngIf="false">
      <nxt-button-icon nxtPermission="IsAc" tooltip="Artist kopieren" (click)="copyArtist()">content_copy</nxt-button-icon>
      <nxt-button-icon nxtPermission="IsNotAc" tooltip="Artist einfügen" (click)="pastArtist()">content_paste</nxt-button-icon>
    </div>
    <div nxtPermission="IsNotAc" fxLayoutAlign="end">
      <nxt-button-icon tooltip="Artist aus Aachen einfügen" (click)="getArtistFromAc()">content_paste</nxt-button-icon>
    </div>

    <div fxFlex fxLayout="column" style="border: 0 solid red">
      <div class="section-header" [nxtMultiClick]="3" (nxtOnMultiClick)="enableName()">Name & Kalender</div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px">
        <nxt-input fxFlex.gt-sm="50" placeholder="Künstlername" [nxtFormControl]="form.get('name')"></nxt-input>
        <nxt-date-picker
          displayDateFormat="dd.MM.yyyy"
          placeholder="Geburtstag"
          [nxtFormControl]="form.get('birthday')"
          [disableOpenOnClickInput]="true">
        </nxt-date-picker>
        <nxt-input placeholder="Ausweis-Nummer" [nxtFormControl]="form.get('idNumber')"></nxt-input>
        <nxt-input class="w-[150px]" placeholder="KalenderNr" [nxtFormControl]="form.get('calendarNumber')"></nxt-input>

        <div class="flex no-wrap text-[80%] justify-center">
          @if (form.get('telegramChatId').value) {
            <div class="flex flex-row justify-center">
              <div (click)="telegramClicked()" class="center cursor-pointer">Telegram<br/>verbunden</div>
            </div>
          } @else {
            <div class="center cursor-pointer" (click)="connectTelegramClicked()">Telegram<br/>nicht verbunden</div>
          }
          <!--<div nxtPermission="IsJulian" (click)="fakeTelegram()">fake</div>-->
        </div>
      </div>
      <div class="flex flex-row justify-around items-center gap-3">
        <nxt-autocomplete
          class="flex w-[150px]"
          [nxtFormControl]="form.get('lang')"
          [options]="languages"
          [filterFields]="['text']"
          [showClearIcon]="true"
        >

        </nxt-autocomplete>
        <div class="flex grow">
          <nxt-input class="flex w-full" placeholder="Handy" [nxtFormControl]="form.get('mobile')"></nxt-input>
        </div>
        <div class="flex grow">
          <nxt-button-icon (click)="openInsta()">open_in_new</nxt-button-icon>
          <nxt-input placeholder="Instagram" [nxtFormControl]="form.get('instagram')"></nxt-input>
        </div>
        <mat-button-toggle-group [formControl]="$any(form.get('workType'))">
          <mat-button-toggle value="tattoo">Tattoo</mat-button-toggle>
          <mat-button-toggle value="piercing">Piercing</mat-button-toggle>
          <mat-button-toggle value="beauty">Beauty</mat-button-toggle>
        </mat-button-toggle-group>
        @if ($any(form.get('workType')).value === 'piercing') {
          <nxt-slide-toggle [nxtFormControl]="$any(form.get('toothGem'))">Zahnschmuck</nxt-slide-toggle>
        }

        <!--<nxt-slide-toggle [value]="form.get('workType').value === 'piercing'"
                          (valueChange)="form.get('workType').setValue($event ? 'piercing' : 'tattoo'); workTypeChanged()">Piercing
        </nxt-slide-toggle>-->
      </div>
      <div fxFlex fxLayout="column">
        <mat-tab-group style="height:200px" fxFlex [selectedIndex]="selectedIndex()" [disablePagination]="true">
          <mat-tab label="Ausweis & Vertrag">
            @if (!artist.id) {
              <div class="py-20">Der Artist muss erst angelegt sein, dann kannst du den Ausweis hochladen 😉</div>
            } @else {
              <div class="flex flex-row gap-5 pt-10">
                <nxt-file-upload [width]="300" [height]="220" message="Ausweis-Bild hier her ziehen" (onFileDropped)="onFileDropped($event)"></nxt-file-upload>
                <div *ngFor="let file of artistFiles()">
                  <div class="flex flex-col items-center">
                    <img (click)="artistFileClicked(file)" [src]="file.thumbnailLink" style="height: 220px; width: fit-content" class="cursor-pointer"/>
                    <div>{{ file.name }}</div>
                    <div *ngIf="false" [innerHTML]="file | jsonFormatted"></div>
                  </div>
                </div>
              </div>
            }
            <nxt-slide-toggle [nxtFormControl]="formGet('contractSigned')">Vertrag unterschrieben</nxt-slide-toggle>
            <nxt-button nxtPermission="IsJulian" (click)="openGoogleDriveClicked()">Google-Drive</nxt-button>
          </mat-tab>
          <mat-tab label="Rechnung">
            <div fxFlex fxLayout="column" class="tab-content">
              <div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px">
                <nxt-select
                  fxFlex.gt-sm="15"
                  placeholder="Anrede"
                  [options]="[{value: 'Frau', text: 'Frau'}, {value: 'Herrn', text: 'Herrn'},{value: 'Firma', text: 'Firma'}]"
                  [nxtFormControl]="form.get('invoiceData').get('salutation')"
                >
                </nxt-select>
                <nxt-input fxFlex.gt-sm="50" placeholder="Name" [nxtFormControl]="form.get('invoiceData').get('name')"></nxt-input>
                <nxt-input fxFlex.gt-sm="15" placeholder="Kundennummer" [nxtFormControl]="form.get('invoiceData').get('customerNumber')"></nxt-input>
                <nxt-input fxFlex.gt-sm="20" placeholder="Rechnungskreis" [nxtFormControl]="form.get('invoiceData').get('numberRange')"></nxt-input>
              </div>
              <div fxFlex fxLayout="row" fxLayout.lt-md="column">
                <nxt-input
                  [google]="true"
                  [clearGoogleAfterClick]="true"
                  (onGoogleClick)="setAddressFromGoogle($event)"
                  placeholder="Adress-Suche"></nxt-input>
              </div>
              <div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px">
                <nxt-autocomplete
                  fxFlex.gt-sm="25"
                  placeholder="Land"
                  [nxtFormControl]="form.get('invoiceData').get('country')"
                  [options]="countries"
                  [displayWith]="displayWithCountry"
                  [displayInOptionWith]="displayInOptionWithCountry"
                  [maxOptionsToShow]="-1"
                  [filterFields]="['name']"
                  panelWidth="300px"
                >
                </nxt-autocomplete>
                <nxt-input fxFlex.gt-sm="30" placeholder="Straße" [nxtFormControl]="form.get('invoiceData').get('street')"></nxt-input>
                <nxt-input fxFlex.gt-sm="10" placeholder="PLZ" [nxtFormControl]="form.get('invoiceData').get('postalCode')"></nxt-input>
                <nxt-input fxFlex.gt-sm="35" placeholder="Ort" [nxtFormControl]="form.get('invoiceData').get('city')"></nxt-input>
                <nxt-button-icon (click)="showInMaps()">maps</nxt-button-icon>
              </div>


              <div class="gap-3" fxFlex fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-md="column">
                <nxt-input placeholder="E-Mail" [nxtFormControl]="form.get('invoiceData').get('email')"></nxt-input>

                <nxt-slide-toggle [nxtFormControl]="formGet('employed')">Angestellt</nxt-slide-toggle>
                <nxt-slide-toggle [nxtFormControl]="formGet('payoutBig')">große Scheine</nxt-slide-toggle>
                <nxt-slide-toggle [nxtFormControl]="formGet('disabled')">Artist deaktiviert</nxt-slide-toggle>
                <nxt-slide-toggle [disabled]="formGet('createCustomerInvoices').value" [nxtFormControl]="formGet('createCustomerInvoices')">Kunden-Rechnungen
                  erstellen
                </nxt-slide-toggle>
                @if (formGet('createCustomerInvoices').value) {
                  <nxt-input class="w-[350px]" [nxtFormControl]="form.get('invoiceData').get('vatId')"></nxt-input>
                }
              </div>
            </div>
            <div>
              <nxt-button (click)="sendInvoices()">Rechnungen schicken</nxt-button>
            </div>
          </mat-tab>
          @if (isAc) {
            <mat-tab label="Notizen">
              <div class="w-full flex flex-row justify-center pt-4">
                <nxt-artist-notes class="flex w-fit" [(artist)]="artist"></nxt-artist-notes>
              </div>
            </mat-tab>
          }
          <mat-tab fxFlex label="Fähigkeiten" *ngIf="showSkills">
            <div fxFlex fxLayout="column" class="tab-content">
              <div fxFlex fxLayout="column">
                <div class="artist-skills">
                  <nxt-artist-skill [nxtFormControl]="form.get('skills')"></nxt-artist-skill>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Blacklist" *ngIf="showBlackList">
            <div fxFlex fxLayout="column" fxLayoutAlign="center center" style="padding-top: 2em">

              <div fxLayout="row" fxLayoutGap="2em" class="pb-4">
                <nxt-slide-toggle [(value)]="motiveTattooBlacklist.round">Rund / Kreis</nxt-slide-toggle>
                <nxt-slide-toggle [(value)]="motiveTattooBlacklist.thinLines">feine gerade Linien</nxt-slide-toggle>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let bodyPut of artist.bodyPutTattooBlacklist, let index = index">
                <nxt-input
                  fxFlex="350px"
                  #nxtFormControlElemBodyPut
                  placeholder="Körperstelle"
                  (click)="bodyPutClick(index)"
                  [value]="bodyPut"
                  [readonly]="true"
                  [cellRenderer]="bodyPutCellRenderer"
                ></nxt-input>
                <nxt-button-icon (click)="removeBodyPut(index)">remove</nxt-button-icon>
              </div>
              <nxt-button-icon (click)="newBodyPut()">add</nxt-button-icon>
            </div>
            <br/>
          </mat-tab>
          <mat-tab label="gesperrt Für" *ngIf="isMainStudio">
            <div fxFlex fxLayout="column" fxLayoutAlign="center center" style="padding-top: 2em">
              <div>
                <nxt-slide-toggle *ngFor="let studio of allStudios" [(value)]="disabledStudios[studio.shortName]">{{ studio.name }}</nxt-slide-toggle>
              </div>
            </div>
            <br/>
          </mat-tab>
          <!--<mat-tab label="Rechnungsnummer">
            <div fxFlex fxLayout="column" fxLayoutAlign="center center" style="padding-top: 2em">
              <div>
                <nxt-input [(value)]="current"></nxt-input>
                <nxt-button>speichern</nxt-button>
              </div>
            </div>
            <br/>
          </mat-tab>-->
        </mat-tab-group>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="2em" style="padding-top: 1em">
      <nxt-button (click)="close()">ABBRECHEN</nxt-button>
      <nxt-button (click)="save()" [disabled]="!canEdit || saveRunning">SPEICHERN</nxt-button>
    </div>
  </div>
</div>
