import {DateTools} from '../../common-browser/helpers/date.tools';
import {DialogService} from '../../services/dialog.service';
import {Component, OnInit, Optional, signal} from '@angular/core';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {FormTools} from '../../services/form.tools';
import {NxtFormControl} from '../../nxt-form/nxt.form-control';
import {SocketService} from '../../services/socket/socket.service';
import {ConfigService} from '../../services/config.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NxtArtist} from '../../common-interfaces/nxt.artist.interface';
import {ValidatorTools} from '../../helpers/validator.tools';
import {DialogRef} from '@angular/cdk/dialog';
import {NxtArtistSpot, NxtAvailableArtistWorkTimeWindow} from '../../common-interfaces/nxt.available-artist-day.interface';
import {PermissionService} from '../../services/permission.service';
import {NxtPermissionId} from '../../common-interfaces/nxt.user.interface';
import {DurationPipe} from '../../pipes/duration.pipe';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {AutocompleteComponent} from '../form-controls/autocomplete/autocomplete.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {Clipboard} from '@angular/cdk/clipboard';
import {SelectComponent} from '../form-controls/select/select.component';
import {DatePicker2Component} from '../form-controls/date-picker-2/date-picker-2.component';
import {CacheService} from '../../services/cache/cache.service';
import {NxtComponent} from '../nxt.component';
import {ObjectTools} from '../../common-browser/helpers/object.tools';
import {DayOfWeek} from '../../common-interfaces/date.interface';

export interface AvailableDay {
  canAvailable: boolean;
  dateString: string;
  from: number;
  to: number;
  isToday: boolean;
}

@Component({
  selector: 'nxt-artist-spot-edit',
  templateUrl: './artist-spot-edit.component.html',
  styleUrls: ['./artist-spot-edit.component.scss'],
  imports: [NgIf, FlexModule, AutocompleteComponent, SlideToggleComponent, NgFor, NgClass, ExtendedModule, NxtButtonComponent, DurationPipe, NxtDatePipe, SelectComponent, DatePicker2Component],
})
export class ArtistSpotEditComponent extends NxtComponent implements OnInit {
  changed = signal(false);

  constructor(
    private fb: UntypedFormBuilder,
    private socketService: SocketService,
    @Optional() private dialogRef: DialogRef<any>,
    private dialogService: DialogService,
    private configService: ConfigService,
    private permissionService: PermissionService,
    private clipboard: Clipboard,
    private cacheService: CacheService,
  ) {
    super();
    if (!dialogRef) {
    }
  }


  artistSpot: NxtArtistSpot;
  form: UntypedFormGroup;
  artists: NxtArtist[] = [];

  public weeks: AvailableDay[][] = [];
  public availableDays: AvailableDay[] = [];

  public userCanCreateSpots = this.permissionService.hasPermission(NxtPermissionId.Artists_NewSpot);
  keySafeNumberOptions: { value: any; text: string }[] = [{text: 'Links - Zimmer 1', value: 1}, {text: 'Rechts - Zimmer 2', value: 2}];

  async ngOnInit() {
    this.pushSubscription = this.cacheService.artists.subscribe((artists) => {
      this.artists = artists.filter(a => !a.disabled).sortString('name');
    });
  }


  displayArtistFn(artist?: NxtArtist): string | undefined {
    return artist?.name;
  }

  async load(artistSpotId: string, startDateString?: string, endDateString?: string) {
    await ObjectTools.waitFor(() => this.artists.length > 0, 'load ArtistSpotEdit');
    if (artistSpotId === 'new') {
      this.artistSpot = {
        artist: null,
        start: startDateString ? startDateString.dateParse() : Date.now().clearTime(),
        end: endDateString ? endDateString.dateParse() : Date.now().dateAddDays(7).clearTime(),
        id: '',
        updatedAt: 0,
        updatedBy: '',
        createdAt: 0,
        createdBy: '',
        artistIsInApartment: false,
        apartmentCheckOutDone: false,
        apartmentCheckInDone: false,
        keySafeNumber: null,
      };
    } else {
      this.artistSpot = await this.socketService.getArtistSpot(artistSpotId);
      if (!this.artistSpot) {
        this.dialogService.showOk('Artist konnte nicht geladen werden\nartistSpotId: ' + artistSpotId);
      }
    }
    if (this.artistSpot) {
      this.buildForm();
    }
  }

  async buildForm() {
    const artist = this.artists.find(a => a.name === this.artistSpot.artist);
    this.form = this.fb.group({
      artist: new NxtFormControl(artist, [ValidatorTools.hasProperty('id')]),
      start: new NxtFormControl(this.artistSpot.start, ValidatorTools.requiredAndNotNaN),
      end: new NxtFormControl(this.artistSpot.end, ValidatorTools.requiredAndNotNaN),
      artistIsInApartment: new NxtFormControl(this.artistSpot.artistIsInApartment),
      keySafeNumber: new NxtFormControl(this.artistSpot.keySafeNumber),
      apartmentCheckInDone: new NxtFormControl(this.artistSpot.apartmentCheckInDone),
      apartmentCheckOutDone: new NxtFormControl(this.artistSpot.apartmentCheckOutDone),
    });

    this.form.get('start').valueChanges.subscribe(() => {
      const start = DateTools.parse(this.form.get('start').value);
      const end = DateTools.parse(this.form.get('end').value);
      if (end < start) {
        this.form.get('end').setValue(start.dateAddDays(7).dateFormatDate());
      }
    });

    setTimeout(() => {
      this.form.valueChanges.subscribe((data) => {
        this.changed.set(true);
      });
    }, 1000);
    this.generateDays();
  }

  async save() {
    const raw = this.form.getRawValue();
    if (this.configService.config.value.studioRegion === 'MA') {
      if (!raw?.artist?.idNumber) {
        this.dialogService.showOk('Bei ' + raw?.artist?.name + ' fehlt die Ausweis-Nummer\nBitte trage sie nach und lege dann erst den Spott an.');
        return;
      }
    }

    if (!FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      return;
    }

    if (this.missingSkills(raw.artist)) {
      return;
    }
    // raw.start = raw.start
    this.artistSpot = {...this.artistSpot, ...raw};
    this.artistSpot.artist = raw.artist.name;
    this.artistSpot.start = DateTools.parse(raw.start);
    this.artistSpot.end = DateTools.parse(raw.end);
    if (this.artistSpot.end < this.artistSpot.start) {
      return;
    }
    if (!this.artistSpot.id && DateTools.monthDiff(this.artistSpot.end, this.artistSpot.start) > 3) {
      this.dialogService.showOk('Bitte splitte die Spots auf maximal 3 Monate je Spot');
      return;
    }
    this.artistSpot.workTimeWindows = this.generateWorkTimeWindows(this.artistSpot.workTimeWindows);
    await this.socketService.upsertArtistSpot(this.artistSpot);
    this.dialogRef.close();
  }

  public async close() {
    if (this.changed()) {
      const closeWithoutSave = await this.dialogService.showYesNo('Deine Änderungen gehen verloren\n\nOhne Speichern schließen?', {
        noText: 'Zurück',
        yesText: 'Ja, ohne speichern schließen',
      });
      if (!closeWithoutSave) {
        return;
      }
    }
    this.dialogRef.close();
  }

  public async delete() {
    if (await this.dialogService.showYesNo('Spot wirklich löschen?', {yesText: 'Ja, löschen'})) {
      await this.socketService.deleteArtistSpot(this.artistSpot.id);
      this.close();
    }
  }

  public generateDays() {
    if (this.form.get('start').value && this.form.get('end').value) {
      const startArtist = DateTools.parse(this.form.get('start').value);
      const endArtist = DateTools.parse(this.form.get('end').value);
      const start = DateTools.getDateFromDayOfWeekBeforeOrSame(startArtist, DayOfWeek.Monday);
      const end = DateTools.getDateFromDayOfWeekAfterOrSame(endArtist, DayOfWeek.Sunday);
      const days = DateTools.dayDiff(end, start);
      console.log(days);
      const newAvailableDays: AvailableDay[] = [];
      for (let daysToAdd = 0; daysToAdd <= days; daysToAdd++) {
        const date = start.dateAddDays(daysToAdd);
        const dateString = date.dateFormat('yyyy-MM-dd');
        let d = this.availableDays.find(a => a.dateString === dateString);
        const canAvailable = date >= startArtist && date <= endArtist;
        const isToday = dateString === DateTools.formatNowDate();
        if (!d) {
          if (canAvailable) {
            const wtw = this.artistSpot.workTimeWindows?.find(w => w.dateString === dateString);
            d = {dateString, from: wtw?.start ?? -1, to: wtw?.end ?? -1, canAvailable, isToday};
          } else {
            d = {dateString, from: -1, to: -1, canAvailable, isToday};
          }
        } else {
          d.canAvailable = canAvailable;
        }
        newAvailableDays.push(d);
      }
      this.availableDays = newAvailableDays;
      this.generateWeeks();
    }
  }

  generateWeeks() {
    this.weeks = [];
    let index = -1;
    for (const day of this.availableDays) {
      if (DateTools.getDayOfWeek(day.dateString) === DayOfWeek.Monday) {
        index++;
        this.weeks.push([]);
      }
      this.weeks[index].push(day);
    }
  }

  public async dayClicked(day: AvailableDay) {
    if (day.dateString.dateParse() < Date.now().dateAddDays(-1)) {
      this.dialogService.showOk('Nix Vergangenheit amk');
      return;
    }
    if (day.canAvailable) {
      const resultFrom = await this.dialogService.showTimeChooser('00:00', day.dateString.dateParse().dateFormat('dd.MM.yyyy'), 'Wann fängt "' + this.artistSpot.artist + '" an', '11:00', '23:00', true,
        [
          {
            text: 'Nicht da',
            value: '-2',
            position: 'start',
          },
          {
            text: 'Studio-Start',
            value: '-1',
            position: 'start',
          }]);
      if (resultFrom) {
        if (resultFrom === '-2') {
          day.from = -2;
          day.to = -2;
          return;
        }
        let minEnd = DurationTools.format(this.configService.config.value.studioWorkStart, 'HH:mm');
        if (resultFrom !== '-1') {
          minEnd = resultFrom;
        }
        const resultTill = await this.dialogService.showTimeChooser('00:00', day.dateString.dateParse().dateFormat('dd.MM.yyyy') + ' ' + resultFrom + ' - ?', 'Wann hört "' + this.artistSpot.artist + '" auf', minEnd, '23:00', true, [{
          text: 'Studio-Ende',
          value: '-1',
          position: 'end',
        }]);
        if (resultTill) {
          if (resultFrom === '-1') {
            day.from = -1;
          } else {
            day.from = DurationTools.parse(resultFrom);
          }
          if (resultTill === '-1') {
            day.to = -1;
          } else {
            day.to = DurationTools.parse(resultTill);
          }
        }
      }
    }
  }

  private missingSkills(artist: NxtArtist) {
    const missingSkills = artist.skills.skillValues.filter(s => s.value === -1);
    if (missingSkills.length > 0) {
      this.dialogService.showOk('Dem Artist "' + artist.name + '" fehlen noch folgende Skills:\n\n' + missingSkills.map(s => s.description).join('\n'));
      requestAnimationFrame(() => this.form.get('artist').setValue(null));
      return true;
    }
    return false;
  }

  private generateWorkTimeWindows(oldTimeWindows: NxtAvailableArtistWorkTimeWindow[]): NxtAvailableArtistWorkTimeWindow[] {
    const result: NxtAvailableArtistWorkTimeWindow[] = [];
    this.availableDays.filter(a => a.canAvailable).forEach(a => {
      result.push({
        dateString: a.dateString,
        earlyPaidOut: !!oldTimeWindows?.find(t => t.dateString === a.dateString)?.earlyPaidOut,
        start: a.from,
        end: a.to,
      });
    });
    return result;
  }

  async getArtistSpotGateOpenTextClicked() {
    const text = await this.socketService.getArtistSpotGateOpenText(this.artistSpot.id);
    await this.dialogService.showOk(text, {buttonText: 'Text in die Zwischenablage'});
    this.clipboard.copy(text);
  }

  showHistoryClicked() {
    this.dialogService.showHistory('artistSpot', this.artistSpot.id);
  }
}
