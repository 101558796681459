<nxt-button-icon [matMenuTriggerFor]="menu">menu</nxt-button-icon>
<mat-menu #menu>
  <button nxtPermission mat-menu-item (click)="menuItemClicked(MenuItem.SetNewPassword)">
    <mat-icon fontSet="material-symbols-outlined">password</mat-icon>
    <span>Passwort neu setzen</span>
  </button>
  <button nxtPermission [permissionOr]="['Analysis_DiscountPromotions', 'Analysis_StudioFee' , 'Analysis_EventsCreatedOrUpdated']" mat-menu-item
          [matMenuTriggerFor]="analysis">
    <mat-icon fontSet="material-symbols-outlined">analytics</mat-icon>
    <span>Auswertungen</span>
  </button>

  <button nxtPermission [permissionOr]="['MoneyAccount_Transactions', 'MoneyAccount_Admin']" mat-menu-item (click)="menuItemClicked(MenuItem.MoneyAccounts)">
    <mat-icon fontSet="material-symbols-outlined">euro_symbol</mat-icon>
    <span>Kassen</span>
  </button>

  <button nxtPermission="WhatApp_ChatRecheck" mat-menu-item (click)="menuItemClicked(MenuItem.WhatsAppChatRecheck)">
    <mat-icon fontSet="material-symbols-outlined">task_alt</mat-icon>
    <span>Chat-Kontrolle</span>
  </button>

  <button nxtPermission="Admin" mat-menu-item (click)="menuItemClicked(MenuItem.Admin)">
    <mat-icon fontSet="material-symbols-outlined">admin_panel_settings</mat-icon>
    <span>Super Admin</span>
  </button>

  <button nxtPermission [permissionOr]="['Artists_Read', 'Artists_NewSpot']" mat-menu-item
          [matMenuTriggerFor]="artists">
    <mat-icon fontSet="material-symbols-outlined">person_apron</mat-icon>
    <span>Artists</span>
  </button>

  <button nxtPermission [permissionOr]="['Employees_Edit', 'Employees_EditEvents']" mat-menu-item
          [matMenuTriggerFor]="employees">
    <mat-icon fontSet="material-symbols-outlined">badge</mat-icon>
    <span>Mitarbeiter</span>
  </button>

  <button mat-menu-item [matMenuTriggerFor]="documents">
    <mat-icon fontSet="material-symbols-outlined">text_snippet</mat-icon>
    <span>Dokumente</span>
  </button>

  <button nxtPermission="DiscountPromotionsEdit" mat-menu-item (click)="menuItemClicked(MenuItem.DiscountPromotions)">
    <mat-icon fontSet="material-symbols-outlined">percent</mat-icon>
    <span>Rabatte</span>
  </button>

  <button nxtPermission="Promo_PromotersList" mat-menu-item [matMenuTriggerFor]="promo">
    <mat-icon fontSet="material-symbols-outlined">celebration</mat-icon>
    <span>Promo-App</span>
  </button>

  <button nxtPermission [permissionOr]="['EditUsers']" mat-menu-item (click)="menuItemClicked(MenuItem.Users)">
    <mat-icon fontSet="material-symbols-outlined">people_outline</mat-icon>
    <span>Benutzer</span>
  </button>

  <button nxtPermission="NotAssignedPayments_View" mat-menu-item (click)="menuItemClicked(MenuItem.NotAssignedPayments)">
    <mat-icon fontSet="material-symbols-outlined">payments</mat-icon>
    <span>Offene Zahlungen</span>
  </button>

  <button mat-menu-item (click)="menuItemClicked(MenuItem.GraphicHeader)">
    <mat-icon fontSet="material-symbols-outlined">vertical_distribute</mat-icon>
    <span>Grafiktitel</span>
  </button>

  <button mat-menu-item (click)="menuItemClicked(MenuItem.NewGiftCard)">
    <mat-icon fontSet="material-symbols-outlined">receipt_long</mat-icon>
    <span>Gutschein erfassen</span>
  </button>

  <button mat-menu-item (click)="menuItemClicked(MenuItem.Inventory)">
    <mat-icon fontSet="material-symbols-outlined">assignment</mat-icon>
    <span>Inventar</span>
  </button>

  <button nxtPermission="Admin" mat-menu-item (click)="menuItemClicked(MenuItem.Invoices)">
    <mat-icon fontSet="material-symbols-outlined">receipt</mat-icon>
    <span>Rechnungen</span>
  </button>

  <button mat-menu-item [matMenuTriggerFor]="customer">
    <mat-icon fontSet="material-symbols-outlined">people</mat-icon>
    <span>Kunden</span>
  </button>
  <!--<button mat-menu-item [matMenuTriggerFor]="cash">
    <mat-icon fontSet="material-symbols-outlined">euro</mat-icon>
    <span>Kasse</span>
  </button>-->
  <button mat-menu-item (click)="menuItemClicked(MenuItem.Handover)" nxtPermission="IsReception">
    <mat-icon fontSet="material-symbols-outlined">logout</mat-icon>
    <span>Kassenübergabe</span>
  </button>

  <button mat-menu-item (click)="menuItemClicked(MenuItem.WebFormSubmits)">
    <mat-icon fontSet="material-symbols-outlined">contact_mail</mat-icon>
    <span>Terminanfragen</span>
  </button>

  <button mat-menu-item (click)="menuItemClicked(MenuItem.TextTemplates)">
    <mat-icon fontSet="material-symbols-outlined">edit_note</mat-icon>
    <span>Textvorlagen</span>
  </button>

  <button nxtPermission [permissionOr]="['PayPalSMSCode', 'GoogleSMSCode']" mat-menu-item
          [matMenuTriggerFor]="smsCodes">
    <mat-icon fontSet="material-symbols-outlined">sms</mat-icon>
    <span>SMS-Codes</span>
  </button>


  <button mat-menu-item [matMenuTriggerFor]="whatsappMenu">
    <mat-icon fontSet="material-symbols-outlined">euro</mat-icon>
    <span>WhatsApp</span>
  </button>

  <button mat-menu-item (click)="menuItemClicked(MenuItem.Reminders)">
    <mat-icon fontSet="material-symbols-outlined">notifications</mat-icon>
    <span>Aufgabenliste</span>
  </button>

  <button nxtPermission="DayFinishLog" mat-menu-item (click)="menuItemClicked(MenuItem.DayFinishLog)">
    <mat-icon fontSet="material-symbols-outlined">manage_search</mat-icon>
    <span>Abrechnungs-Log</span>
  </button>
</mat-menu>


<!-- WHATSAPP-MENU -->
<mat-menu #whatsappMenu>
  <button mat-menu-item (click)="menuItemClicked(MenuItem.WhatsappChats)">
    <mat-icon fontSet="material-symbols-outlined">chat</mat-icon>
    <span>WhatsApp-Chats</span>
  </button>
  <button nxtPermission="WhatsAppFastAnswers_Edit" mat-menu-item (click)="menuItemClicked(MenuItem.WhatsappFastAnswers)">
    <mat-icon fontSet="material-symbols-outlined">format_list_bulleted</mat-icon>
    <span>WhatsApp-Texte</span>
  </button>
</mat-menu>
<mat-menu #cash>
  <button mat-menu-item (click)="menuItemClicked(MenuItem.Handover)">
    <mat-icon fontSet="material-symbols-outlined">logout</mat-icon>
    <span>Kassenübergabe</span>
  </button>

  <button *ngIf="false" mat-menu-item (click)="menuItemClicked(MenuItem.CashRegisterDoCount)">
    <mat-icon fontSet="material-symbols-outlined">calculate</mat-icon>
    <span>Kasse zählen</span>
  </button>
</mat-menu>
<mat-menu #analysis>
  <button mat-menu-item nxtPermission="Analysis_EventsCreatedOrUpdated" (click)="menuItemClicked(MenuItem.AnalysisEvents)">
    <mat-icon fontSet="material-symbols-outlined">calendar_view_day</mat-icon>
    <span>Termine</span>
  </button>
  <button mat-menu-item nxtPermission="Analysis_EventsCreatedOrUpdated" (click)="menuItemClicked(MenuItem.AnalysisEventsCreatedOrUpdated)">
    <mat-icon fontSet="material-symbols-outlined">calendar_view_day</mat-icon>
    <span>Termine erstellt oder aktualisiert</span>
  </button>

  <button mat-menu-item nxtPermission="Analysis_DiscountPromotions" (click)="menuItemClicked(MenuItem.Analysis)">
    <mat-icon fontSet="material-symbols-outlined">percent</mat-icon>
    <span>Rabatte</span>
  </button>
  <button mat-menu-item nxtPermission="Analysis_StudioFee" (click)="menuItemClicked(MenuItem.StudioFee)">
    <mat-icon fontSet="material-symbols-outlined">savings</mat-icon>
    <span>Studio-Abgaben</span>
  </button>
  <button mat-menu-item nxtPermission="Admin" (click)="menuItemClicked(MenuItem.DiscountGiftCards)">
    <mat-icon fontSet="material-symbols-outlined">receipt_long</mat-icon>
    <span>Rabattierte Gutscheine</span>
  </button>
</mat-menu>
<mat-menu #artists>
  <button nxtPermission="Artists_NewSpot" mat-menu-item (click)="menuItemClicked(MenuItem.NewArtistSpot)">
    <mat-icon fontSet="material-symbols-outlined">add</mat-icon>
    <span>Neuer Guest-Spot</span>
  </button>
  <button nxtPermission="Artists_Read" mat-menu-item (click)="menuItemClicked(MenuItem.Artists)">
    <mat-icon fontSet="material-symbols-outlined">person_apron</mat-icon>
    <span>Artistliste</span>
  </button>
  <button nxtPermission="Artists_EditKeySafeCode" mat-menu-item (click)="menuItemClicked(MenuItem.ArtistsEditKeySafeCode)">
    <mat-icon fontSet="material-symbols-outlined">person_apron</mat-icon>
    <span>Schlüssel-Codes</span>
  </button>
  <button nxtPermission="Artists_Read" mat-menu-item (click)="menuItemClicked(MenuItem.ArtistsRatings)">
    <mat-icon fontSet="material-symbols-outlined">person_apron</mat-icon>
    <span>Artist-Rating-Skills</span>
  </button>
</mat-menu>
<mat-menu #employees>
  <button nxtPermission="Employees_Edit" mat-menu-item (click)="menuItemClicked(MenuItem.Employees)">
    <mat-icon fontSet="material-symbols-outlined">badge</mat-icon>
    <span>Mitarbeiter</span>
  </button>
  <button nxtPermission="Employees_ViewEvents" mat-menu-item (click)="menuItemClicked(MenuItem.EmployeeCalendar)">
    <mat-icon fontSet="material-symbols-outlined">calendar_month</mat-icon>
    <span>Mitarbeiter Kalender</span>
  </button>
  <button nxtPermission="Employees_Works" mat-menu-item (click)="menuItemClicked(MenuItem.EmployeeWorks)">
    <mat-icon fontSet="material-symbols-outlined">timer</mat-icon>
    <span>Mitarbeiter-Zeiten</span>
  </button>
</mat-menu>

<mat-menu #customer>
  <button mat-menu-item (click)="menuItemClicked(MenuItem.NewContact)">
    <mat-icon fontSet="material-symbols-outlined">person_add</mat-icon>
    <span>neuer Kunde</span>
  </button>

  <button mat-menu-item (click)="menuItemClicked(MenuItem.CombineContacts)">
    <mat-icon fontSet="material-symbols-outlined">merge</mat-icon>
    <span>Zusammenführen</span>
  </button>

  <button mat-menu-item nxtPermission="IsJulian" (click)="menuItemClicked(MenuItem.Contacts)">
    <mat-icon fontSet="material-symbols-outlined">people</mat-icon>
    <span>Kundenliste</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item
          (click)="appointmentReminderService.showEditAppointmentReminderText()">
    <mat-icon fontSet="material-symbols-outlined">notifications</mat-icon>
    <span>Terminerinner. WhatsApp</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item [matMenuTriggerFor]="customer_careInstructions">
    <mat-icon fontSet="material-symbols-outlined">spa</mat-icon>
    <span>Pflegehinweis WhatsApp</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item [matMenuTriggerFor]="customer_appointmentConfirmation">
    <mat-icon fontSet="material-symbols-outlined">people</mat-icon>
    <span>Terminbestätigung</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item
          [matMenuTriggerFor]="customer_appointmentConfirmationCanceled">
    <mat-icon fontSet="material-symbols-outlined">people</mat-icon>
    <span>Absage-Bestätigung</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item (click)="menuItemClicked(MenuItem.EditBirthdayMessage)">
    <mat-icon fontSet="material-symbols-outlined">people</mat-icon>
    <span>Geburtstagsnachricht</span>
  </button>
</mat-menu>
<mat-menu #customer_careInstructions>
  <button nxtPermission="Admin" mat-menu-item (click)="careInstructionsService.showEditCareInstructionsTattooText('tattoo', 'de')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>Tattoo DE</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item (click)="careInstructionsService.showEditCareInstructionsTattooText('piercing', 'de')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>Piercing DE</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item (click)="careInstructionsService.showEditCareInstructionsTattooText('tattoo', 'en')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>Tattoo EN</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item (click)="careInstructionsService.showEditCareInstructionsTattooText('piercing', 'en')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>Piercing EN</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item (click)="careInstructionsService.showEditCareInstructionsTattooText('tattoo', 'nl')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>Tattoo NL</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item (click)="careInstructionsService.showEditCareInstructionsTattooText('tattoo', 'es')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>Tattoo ES</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item (click)="careInstructionsService.showEditCareInstructionsTattooText('tattoo', 'fr')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>Tattoo FR</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item (click)="careInstructionsService.showEditCareInstructionsTattooText('tooth-gem', 'de')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>Zahnschmuck DE</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item (click)="careInstructionsService.showEditCareInstructionsTattooText('tooth-gem', 'en')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>Zahnschmuck EN</span>
  </button>
</mat-menu>
<mat-menu #customer_appointmentConfirmation>
  <button nxtPermission="Admin" mat-menu-item
          (click)="appointmentConfirmationService.showEditAppointmentConfirmationText('de')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>Text DE</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item
          (click)="appointmentConfirmationService.showEditAppointmentConfirmationText('en')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>Text EN</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item
          (click)="appointmentConfirmationService.showEditAppointmentConfirmationStudioText('de')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>StudioText DE</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item
          (click)="appointmentConfirmationService.showEditAppointmentConfirmationStudioText('en')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>StudioText EN</span>
  </button>
</mat-menu>
<mat-menu #customer_appointmentConfirmationCanceled>
  <button nxtPermission="Admin" mat-menu-item
          (click)="appointmentConfirmationService.showEditAppointmentConfirmationTextCanceled('de')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>Text DE</span>
  </button>
  <button nxtPermission="Admin" mat-menu-item
          (click)="appointmentConfirmationService.showEditAppointmentConfirmationTextCanceled('en')">
    <mat-icon fontSet="material-symbols-outlined">fact_check</mat-icon>
    <span>Text EN</span>
  </button>
</mat-menu>

<mat-menu #promo>
  <button mat-menu-item (click)="menuItemClicked(MenuItem.PromoPromoUsers)">
    <mat-icon fontSet="material-symbols-outlined">people_outline</mat-icon>
    <span>Promoter</span>
  </button>
</mat-menu>

<mat-menu #documents>
  <button mat-menu-item (click)="menuItemClicked(MenuItem.DeclarationOfConsentTattoo)">
    <mat-icon fontSet="material-symbols-outlined">text_snippet</mat-icon>
    <span>Einwilligungen Tattoo</span>
  </button>
  <button mat-menu-item (click)="menuItemClicked(MenuItem.DeclarationOfConsentPiercing)">
    <mat-icon fontSet="material-symbols-outlined">text_snippet</mat-icon>
    <span>Einwilligungen Piercing</span>
  </button>
  <button mat-menu-item (click)="menuItemClicked(MenuItem.DeclarationOfConsentToothGem)">
    <mat-icon fontSet="material-symbols-outlined">text_snippet</mat-icon>
    <span>Einwilligungen Zahnschmuck</span>
  </button>
  <button mat-menu-item (click)="menuItemClicked(MenuItem.AfterCareTattoo)">
    <mat-icon fontSet="material-symbols-outlined">text_snippet</mat-icon>
    <span>Pflege Tattoo</span>
  </button>
  <button mat-menu-item (click)="menuItemClicked(MenuItem.AfterCarePiercing)">
    <mat-icon fontSet="material-symbols-outlined">text_snippet</mat-icon>
    <span>Pflege Piercing</span>
  </button>
  <button mat-menu-item (click)="menuItemClicked(MenuItem.AfterCareToothGem)">
    <mat-icon fontSet="material-symbols-outlined">text_snippet</mat-icon>
    <span>Pflege Zahnschmuck</span>
  </button>
</mat-menu>

<mat-menu #smsCodes>
  <button nxtPermission="PayPalSMSCode" mat-menu-item (click)="menuItemClicked(MenuItem.PayPalSms)">
    <mat-icon fontSet="material-symbols-outlined">sms</mat-icon>
    <span>PayPal SMS-Code</span>
  </button>
  <button nxtPermission="GoogleSMSCode" mat-menu-item (click)="menuItemClicked(MenuItem.GoogleSms)">
    <mat-icon fontSet="material-symbols-outlined">sms</mat-icon>
    <span>Google SMS-Code</span>
  </button>
</mat-menu>





