<div [class.has-placeholder]="placeholder() || nxtFormControl.name" class="flex w-full flex-row items-center justify-center wrapper"
     [class.show-invalid]="showInvalidError" [class.no-padding]="noPadding()">

  @if (jumpWeeks() || jumpDays()) {
    <div class="flex flex-row items-center justify-center gap-4 px-3 buttons-left">
      @if (jumpWeeks()) {
        <mat-icon class="mouse-pointer" matTooltip="-1 Woche" (click)="jumpWeekClicked(-1)">arrow_back</mat-icon>
      }
      @if (jumpDays()) {
        <mat-icon class="mouse-pointer" matTooltip="-1 Tag" (click)="jumpDayClicked(-1)">arrow_back</mat-icon>
      }
    </div>
  }
  <div class="w-full flex-col">
    <div (click)="showDatePickerDialog()" class="relative flex cursor-pointer flex-col justify-center items-center w-full abcdef">
      <div [class.red]="showInvalidError" class="absolute placeholder" [class.top]="!!nxtFormControl.value">{{ placeholder() || nxtFormControl.name }}
        @if (nxtFormControl.hasValidator(ValidatorTools.requiredAndNotNaN)) {
          <ng-container>*</ng-container>
        }
      </div>
      <div #element class="text-box w-full" [innerHTML]="displayValue() || '&nbsp;'"></div>
    </div>

    <div class="text-[12px] red" [class.error-text-height]="!noPadding()">
      @if (showInvalidError) {
        {{ errorText }}
      }
    </div>

  </div>
  @if (jumpWeeks() || jumpDays() || jumpToday()) {
    <div class="flex flex-row items-center justify-center gap-4 px-3 buttons-right">
      @if (jumpWeeks()) {
        <mat-icon class="mouse-pointer" matTooltip="Heute" (click)="jumpTodayClicked()">system_update_alt</mat-icon>
      }
      @if (jumpWeeks()) {
        <mat-icon class="mouse-pointer" matTooltip="+1 Woche" (click)="jumpWeekClicked(1)">arrow_forward</mat-icon>
      }
      @if (jumpDays()) {
        <mat-icon class="mouse-pointer" matTooltip="+1 Tag" (click)="jumpDayClicked(1)">arrow_forward</mat-icon>
      }
    </div>
  }
</div>

