<div class="flex flex-col justify-center items-center">
  <nxt-slide-toggle [nxtFormControl]="form.controls.active"></nxt-slide-toggle>
  <div class="flex flex-row w-full items-start justify-center">


  </div>
  <nxt-input [nxtFormControl]="form.controls.name"></nxt-input>
  <div class="flex flex-row w-full pb-2 justify-center">
    <mat-button-toggle-group [formControl]="form.controls.discountType">
      <mat-button-toggle value="priceFix">Fix Preis</mat-button-toggle>
      <mat-button-toggle value="priceLike">Ca. Preis</mat-button-toggle>
      <mat-button-toggle value="percentage">Prozent</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  @if (form.controls.discountType.value === 'priceFix' || form.controls.discountType.value === 'priceLike') {
    <nxt-input [nxtFormControl]="form.controls.priceFixValue" [isMoney]="true"
               [placeholder]="form.controls.discountType.value === 'priceFix' ? 'Fixpreis' : 'Ca. Preis'"></nxt-input>
    <nxt-input [nxtFormControl]="form.controls.artistGet" [isMoney]="true"></nxt-input>
  }
  @if (form.controls.discountType.value === 'percentage') {
    <nxt-input [nxtFormControl]="form.controls.discountPercentage" [isPercent]="true"></nxt-input>
  }
  @if (form.controls.discountType.value) {
    <nxt-slide-toggle class="py-1" [nxtFormControl]="form.controls.disableDiscountedGiftCards"></nxt-slide-toggle>
    <nxt-slide-toggle class="py-1" [nxtFormControl]="form.controls.additionalPayAfterEnd"></nxt-slide-toggle>
    <nxt-slide-toggle class="py-1" [nxtFormControl]="form.controls.onlyWithPromoCode"></nxt-slide-toggle>
    @if (form.controls.onlyWithPromoCode.value) {
      <nxt-slide-toggle [nxtFormControl]="form.controls.staticPromoCode"></nxt-slide-toggle>
      @if (form.controls.staticPromoCode.value) {
        <nxt-autocomplete
          placeholder="Promoter"
          [options]="promoPromoters()"
          [displayInOptionWith]="DisplayWithTools.displayWithName"
          [displayWith]="DisplayWithTools.displayWithName"
          [filterFields]="['name']"
          [nxtFormControl]="form.controls.staticPromoCodePromoterId"
          valueField="promoterId"
        >
        </nxt-autocomplete>
        <nxt-autocomplete
          placeholder="Promo-Angebot"
          [options]="promoPromotions()"
          [displayInOptionWith]="DisplayWithTools.displayWithTitle"
          [displayWith]="DisplayWithTools.displayWithTitle"
          [filterFields]="['title']"
          [nxtFormControl]="form.controls.staticPromoCodePromoId"
          valueField="id"
        >
        </nxt-autocomplete>
      }
    }
  }
  @if (form.controls.discountType.value === 'priceFix' || form.controls.discountType.value === 'priceLike') {
    <nxt-slide-toggle [(value)]="hasGiftCard">Gutschein verschicken</nxt-slide-toggle>
    @if (hasGiftCard()) {
      <div class="flex flex-col justify-center items-center rounded-md p-3 mt-3 w-[500px]" style="border: 1px solid #909090">
        <div class="flex w-full justify-center text-[120%]">GUTSCHEIN</div>
        <nxt-input [nxtFormControl]="form.controls.giftCardName"></nxt-input>
        <nxt-input [nxtFormControl]="form.controls.giftCardValue" [isMoney]="true"></nxt-input>
        <nxt-input [nxtFormControl]="form.controls.giftCardCustomRestrictionValue" [isMoney]="true"></nxt-input>
        <nxt-input [nxtFormControl]="form.controls.giftCardValidTillMonths" [isNumber]="true"></nxt-input>
        <nxt-slide-toggle class="py-1" [nxtFormControl]="form.controls.giftCardOnlyNewAppointments"></nxt-slide-toggle>
        <nxt-slide-toggle class="py-1" [nxtFormControl]="form.controls.giftCardDisableDiscountPromo"></nxt-slide-toggle>
      </div>
    }
  }
  <div class="flex flex-row justify-around w-full pt-3 items-center">
    <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
    @if (originalDiscountPromotion()) {
      <nxt-button [smallButton]="true" nxtPermission="IsBackoffice" (click)="copyIdClicked()">ID Kopieren</nxt-button>
    }
    <nxt-button (click)="saveClicked()">Speichern</nxt-button>
  </div>
</div>
