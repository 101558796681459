import {Component, OnInit, Optional, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {HistoryComponent, NxtHistoryEntryNew, NxtHistoryPropDef} from '../history.component';
import {SocketService} from '../../../services/socket/socket.service';
import {BodyPutService} from '../../../services/body-put.service';
import {ConfigService} from '../../../services/config.service';
import {InventoryService} from '../../../services/inventory.service';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-inventory-item-history',
    templateUrl: './inventory-item-history.component.html',
    styleUrls: ['./inventory-item-history.component.scss'],
    imports: [FlexModule, SlideToggleComponent, NxtButtonIconComponent, HistoryComponent]
})

export class InventoryItemHistoryComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  @ViewChild(HistoryComponent) historyComponent: HistoryComponent;


  public propDefs: NxtHistoryPropDef[] = [
    {field: 'name', name: 'Name', type: NxtFieldType.Text},
    {field: 'amountAlarm', name: 'Alarm-Bestand', type: NxtFieldType.Text},
    {field: 'amountFull', name: 'Soll-Bestand', type: NxtFieldType.Text},
    {field: 'amountPerPackage', name: 'Menge pro Packung', type: NxtFieldType.Text},
    {field: 'unitOfMeasure', name: 'Mengeneinheit', type: NxtFieldType.Text},
    {field: 'deleted', name: 'Gelöscht', type: NxtFieldType.Text, deleteInCompareData: (params) => !params.value},
    {field: 'nameBySource', name: 'Bezeichnung bei Bezugsquelle', type: NxtFieldType.Text},
    {field: 'nameEn', name: 'Bezeichnung engl.', type: NxtFieldType.Text},
    {field: 'orderedByAc', name: 'Aachen bestellt', type: NxtFieldType.Boolean},
    {field: 'location', name: 'Ort', type: NxtFieldType.Text},
    {field: 'responsibility', name: 'Zuständig', type: NxtFieldType.Text},
    {field: 'responsibility2', name: 'Zuständig Vertretung', type: NxtFieldType.Text},
    {field: 'sourceOfSupply', name: '1. Bezugsquelle', type: NxtFieldType.Text, deleteInCompareData: (params) => !params.value, valueGetter: (value) => this.inventoryService.getSourceText(value)},
    {field: 'sourceOfSupplyUrl', name: '1. Bezugsquelle Link', type: NxtFieldType.Text, deleteInCompareData: (params) => !params.value},
    {field: 'sourceOfSupply2', name: '2. Bezugsquelle', type: NxtFieldType.Text, deleteInCompareData: (params) => !params.value, valueGetter: (value) => this.inventoryService.getSourceText(value)},
    {field: 'sourceOfSupply2Url', name: '2. Bezugsquelle Link', type: NxtFieldType.Text, deleteInCompareData: (params) => !params.value},

    {field: 'xxx', name: 'xxx', type: NxtFieldType.Text},
    {field: 'xxx', name: 'xxx', type: NxtFieldType.Text},
    {field: 'xxx', name: 'xxx', type: NxtFieldType.Text},

  ];

  public hiddenProps = [
    'studioValues',
    'studiosAmounts'
  ];

  debugProps = [
    'id',
    'orderValue',
    'nfcId'
  ];
  artistCalendars: any[];
  public showTitle = false;
  public showDebug = false;
  public id = ''; // = 'f8f76ff0-b17f-11ea-8fdb-03d8a5e374d4'; // '74trk3lmp6jm6nuddh6dsq232o';


  constructor(
    private socketService: SocketService,
    private bodyPutService: BodyPutService,
    private configService: ConfigService,
    private inventoryService: InventoryService,
    @Optional() public dialogRef: MatDialogRef<any>
  ) {
    super();
    this.hiddenProps.push(...this.debugProps);
  }

  ngOnInit() {
    // this.propDefs = this.propDefs.filter(p => p.name === 'Körperstelle');

  }

  nxtOnDestroy() {
  }

  public filterItem(data: NxtHistoryEntryNew) {
    if (data.user === 'Media-Count-Fix') {
      return false;
    }
    return true;
  }

  public prepareRawData(data) {
    return data;
  }


  public showDebugChanged() {

    this.hiddenProps = this.hiddenProps.filter(p => !this.debugProps.includes(p));
    if (!this.showDebug) {
      this.hiddenProps.push(...this.debugProps);
    }
    this.historyComponent.load();
  }

  private async getCustomer(value: any) {
    return (await this.socketService.getContactById(value))?.fullName;
  }
}
