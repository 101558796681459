import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, effect, HostBinding, input, Input, OnInit, ViewChild,} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {AsyncPipe} from '@angular/common';
import {MatIcon} from '@angular/material/icon';
import {NxtComponent} from '../../components/nxt.component';
import {toObservable} from '@angular/core/rxjs-interop';
import {ScreenService} from '../../services/screen.service';

@Component({
  selector: 'nxt-button',
  templateUrl: './nxt-button.component.html',
  styleUrls: ['./nxt-button.component.scss'],
  imports: [MatButton, ExtendedModule, MatIcon, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NxtButtonComponent extends NxtComponent implements OnInit, AfterViewInit {


  constructor(
    private cdRef: ChangeDetectorRef,
    public screenService: ScreenService,
  ) {
    super();
    this.type = 'NormalButton';

    effect(() => {
      this.showSmallButton();
      this.checkSmallButton();
    });

    /*this.pushSubscription = this.screenService.state.subscribe(() => {
      // debugger;
      console.log('jo');
      this.checkSmallButton();
    });

    this.pushSubscription = toObservable(this.smallButton).subscribe(() => {
      this.checkSmallButton();
    });*/
    this.pushSubscription = toObservable(this.bigButton).subscribe(() => {
      if (this.bigButton()) {
        this.fontSize = '1.3rem';
        this.padding = '8px 12px';
        this.borderRadius = '10px';
        this.cdRef.detectChanges();
      }
    });
    this.pushSubscription = toObservable(this.disabled).subscribe(() => {
      this.pointerEvents = this.disabled() ? 'none' : 'auto';
      this.cdRef.detectChanges();
    });
  }

  @ViewChild(MatButton, {static: true}) button: MatButton;
  @ViewChild('button') buttonElement: HTMLButtonElement;

  smallButton = input(false);
  bigButton = input(false);
  disabled = input(false);

  showSmallButton = computed(() => {
    return this.smallButton() || this.screenService.isMobile();
  });

  @Input() useMinWidth = false;
  @Input() noBorder = false;
  @Input() nxtHTML = '';
  @Input() iconLeft = '';
  @Input() iconRight = '';
  fontSize = '14px';
  @Input() height = 'fit-content';
  @Input() padding = '4px 10px';
  @Input() borderRadius = '4px';
  @Input() noWrap = true;
  @Input() color = '#A2A2A2';


  type: 'TextButton' | 'NormalButton';
  opacity = 1;


  @HostBinding('style.pointer-events') pointerEvents = 'auto';

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    if (this.button) {
      (this.button._elementRef.nativeElement as HTMLButtonElement).onfocus = (ev) => {
        (ev.target as HTMLElement).blur();
      };
    }
  }

  clicked(ev: MouseEvent) {
    if (this.disabled()) {
      ev.stopPropagation();
      ev.preventDefault();
    }
  }

  focus() {
    this.buttonElement.focus();
  }

  private checkSmallButton() {
    if (this.showSmallButton()) {
      this.fontSize = '12px';
      this.padding = '4px 8px';
      this.borderRadius = '4px';
    } else {
      this.fontSize = '14px';
      this.height = 'fit-content';
      this.padding = '4px 10px';
      this.borderRadius = '4px';
    }
  }
}
