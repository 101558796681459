<div
  class="nxt-calendar-hour"
  [class.mark-on-hover]="!isTimeLabel() || daysCount() === 1"
  [mtxTooltip]="(!isTimeLabel() || daysCount() === 1) ? tooltipTemplate : ''"
  mtxTooltipPosition="after"
  mtxTooltipClass="mat-tooltip-offset-right"
  [mtxTooltipDisabled]="disableTooltip()"
  [mtxTooltipPositionAtOrigin]="daysCount() === 1"
  [style.height.px]="segmentHeight()"
  [class.mouse-pointer]="!isTimeLabel() || daysCount() === 1"
  [ngClass]="cssClass()"
  [class.today]="!isTimeLabel() && isToday()"
>
  @if (isTimeLabel() && isFullHour()) {
    <div class="w-[70px] flex flex-col justify-start items-center !p-0 text-color">
      <div [class.offset]="!isFirstTimeLabel()" [class.first-time-label]="isFirstTimeLabel()" class="time-label">{{ date() | nxtDate: 'HH' }}</div>
    </div>
  }
</div>


<ng-template #tooltipTemplate>
  <div class="flex flex-col justify-center items-center text whitespace-nowrap">
    <div class="flex flex-col">
      <div class="text-70">{{ date() | nxtDate: 'EEEE' }}</div>
      @if (showYear()) {
        <div class="font-bold text-130">{{ date() | nxtDate: 'dd.MM.yyyy' }}</div>
      } @else {
        <div class="font-bold text-130">{{ date() | nxtDate: 'dd.MM' }}</div>
      }
      <div *ngIf="showFromNowText()" class="text-70">{{ date() | fromNowText: true }}</div>
    </div>
    <div class="text-160 font-bold">{{ date() | nxtDate: 'HH:mm' }}</div>
  </div>
</ng-template>
