import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {PaymentTools} from '../common-browser/helpers/payment.tools';

@Pipe({
    name: 'paymentMethod',
    standalone: true
})
export class PaymentMethodPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, format ?: string): string {
    return PaymentTools.getPaymentMethodText(value);
  }
}
