import {ChangeDetectionStrategy, Component, Inject, OnInit, signal} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {NxtButtonComponent} from '../../button/nxt-button.component';
import {MatIcon} from '@angular/material/icon';
import {NgIf} from '@angular/common';

export interface DialogData {
  message: string;
}

/**
 * @title Dialog Overview
 */
@Component({
    selector: 'nxt-dialog-ok',
    templateUrl: './dialog-ok.component.html',
    styleUrls: ['./dialog-ok.component.scss'],
    imports: [
        NgIf,
        MatIcon,
        NxtButtonComponent,
        SafeHtmlPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogOkComponent implements OnInit {

  message = signal('');
  buttonText = signal('');
  icon: string;
  titleText: string;
  // private showOkButtonTimeoutSeconds = -1;
  okButtonDisabled = false;

  constructor(
    public dialogRef: MatDialogRef<DialogOkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    setTimeout(() => this.load());
  }

  ngOnInit(): void {
  }

  load() {
    // this.message.set(typeof this.data?.message?.replace === 'function' ? this.data.message.replace(/\n/g, '<br/>') : '');
    this.titleText = this.data.titleText;

    if (this.data.buttonText) {
      this.buttonText.set(this.data.buttonText.replace(/\n/g, '<br/>'));
    }
    if (this.data.icon) {
      this.icon = this.data.icon;
    }

    if (this.data.showOkButtonTimeoutSeconds > -1) {
      this.okButtonDisabled = true;
      setTimeout(() => this.okButtonDisabled = false, this.data.showOkButtonTimeoutSeconds * 1000);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
