<div fxLayout="column" class="flex"
     style="border: 0px solid blue; overflow: hidden; width: 100%; height: 100%; position: relative">
  <div style="height: 50px; z-index: 999">
    <nxt-button-icon class="dialog-close-button" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>

  <div *ngIf="!currentSrc || isLoading" class="flex w-full h-full justify-center items-center absolute" style="z-index: 2000">
    <nxt-spinner></nxt-spinner>
  </div>


  <div class="flex flex-col h-full w-full" *ngIf="currentSrc">

    <div class="flex flex-row h-full">
      <div class="flex h-full justify-center items-center pl-10">
        <mat-icon class="prev-next-button" *ngIf="!isFirst" (click)="prevImage()">chevron_left</mat-icon>
        <div class="prev-next-button" *ngIf="isFirst"></div>
      </div>
      <div class="flex w-full h-full grow flex-col">
        <div class="flex w-full justify-center p-6">
          <div class="text-[24px] text-center" [innerHTML]="title | safeHtml"></div>
        </div>
        <div class="flex o-hidden flex-row justify-center">
          <ngx-image-viewer
            class="flex"
            [src]="[currentSrc]"
            [index]="0"
            [config]="imageViewerConfig"></ngx-image-viewer>
        </div>
        <div class="mt-3 w-full flex flex-row justify-center" *ngIf="buttons">
          <div *ngFor="let button of buttons">
            <nxt-button (click)="buttonClicked.emit(button)">{{ button.text }}</nxt-button>
          </div>
        </div>
      </div>
      <div class="flex h-full justify-center items-center pr-10">
        <mat-icon class="prev-next-button" *ngIf="!isLast" (click)="nextImage()">chevron_right</mat-icon>
        <div class="prev-next-button" *ngIf="isLast"></div>
      </div>
    </div>
  </div>
</div>

