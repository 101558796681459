import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControlWrapper} from '../form-control-wrapper';
import {Subscription} from 'rxjs';
import {UuidTools} from '../../../common-browser/helpers/uuid.tools';
import {MatRadioButton, MatRadioChange, MatRadioGroup} from '@angular/material/radio';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {FormFieldWrapperComponent} from '../../form-field-wrapper/form-field-wrapper.component';
import {NgClass, NgFor, NgIf, NgStyle} from '@angular/common';


@Component({
    selector: 'nxt-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    imports: [NgIf, FormFieldWrapperComponent, FlexModule, MatRadioGroup, NgStyle, ExtendedModule, NgFor, MatRadioButton, NgClass, SafeHtmlPipe],
  standalone: true,
})
export class RadioComponent extends FormControlWrapper implements OnInit, OnDestroy {
  private valueChangeSubscription: Subscription;

  @Output() valueChange = new EventEmitter<any>();

  @Input() options: any[];

  private _value: any;


  @Input() set value(value) {
    this.nxtFormControl.setValue(value, {emitEvent: false, onlySelf: true});
  }

  @Input() readonly: boolean;
  @Input() cellRenderer?: (value: any) => string;
  controlName = UuidTools.generate();
  @Input() direction = 'column';
  @Input() valueKey = '';
  @Input() textKey = 'text';
  @Input() disabledKey = '';
  @Input() disabled = false;

  constructor() {
    super();
  }


  ngOnInit() {
  }


  ngOnDestroy(): void {
    this.valueChangeSubscription?.unsubscribe();
  }

  valueChanged(ev: MatRadioChange) {
    this.nxtFormControl.setValue(ev.value);
    this.valueChange.emit(this.nxtFormControl.value);
  }

  getOptionText(option: any) {
    if (typeof option === 'string') {
      return option;
    }
    return option[this.textKey];
  }


  public setValue(value: any) {
    this.nxtFormControl.setValue(value);

  }
}
