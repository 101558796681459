import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {DatePicker2Component} from '../../components/form-controls/date-picker-2/date-picker-2.component';
import {NxtFormControl} from '../../nxt-form/nxt.form-control';
import {InputComponent} from '../../components/form-controls/input/input.component';
import {ArtistConfirmService} from '../../services/artist-confirm.service';
import {ValidatorTools} from '../../helpers/validator.tools';

@Component({
    selector: 'nxt-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DatePicker2Component,
        InputComponent
    ]
})

export class TestComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  private artistConfirmService = inject(ArtistConfirmService);


  date2FormControl = new NxtFormControl('2021-01-01', [], 'Datum');
  pwFormControl = new NxtFormControl('', [ValidatorTools.requiredAndNotNaN], 'Datum');
  value2 = '2028-01-02';

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  test() {
    this.date2FormControl.setValue('2021-01-02');

    this.value2 = '2028-01-31';
  }
}
