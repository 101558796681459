import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SocketService} from '../../services/socket/socket.service';
import {JsonTools} from '../../common-browser/helpers/json.tools';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {NxtReceipt} from '../../common-interfaces/nxtReceipt';
import {MoneyPipe} from '../../pipes/money.pipe';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf} from '@angular/common';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';


@Component({
  selector: 'nxt-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
  imports: [NgIf, FlexModule, NgFor, SafeHtmlPipe, MoneyPipe, NxtDatePipe]
})
export class ReceiptComponent implements OnInit {

  /*** A6: im Chrome auf 200% Zoom, 800 x 1100 ***/


  errorText = '';
  gbxStreet = 'Wallstraße 62';
  gbxPostalCode = '52064';

  data: NxtReceipt = {
    // valueWithoutTax: 100,
    appointment: {
      startDateTime: DateTools.parse('2020-07-09 11:00', 'yyyy-MM-dd HH:mm'),
      studio: 'Villa',
      studioAddress: 'Aachen Innenstadt, Wallstraße 62'
    },
    receiptValueTotal: 100,
    receiptDateString: '2020-07-02',
    receiptNo: '000001',
    customer: {
      customerName: 'Niklas Mollski',
      customerStreet: 'Cityscooter Straße',
      customerHouseNumber: '99z',
      customerCity: 'Netflixhausen',
      customerPostalCode: '99999'
    },
    receiptPositions: [
      {
        name: 'Kaution',
        amount: 1,
        unitPrice: 100
      }
    ]
  };


  receiptDate: any;

  constructor(
    private route: ActivatedRoute,
    private socketService: SocketService
  ) {
    if (this.route.snapshot.queryParams.data) {
      this.data = JsonTools.parse(this.route.snapshot.queryParams.data);
    } else {
      // this.errorText = 'Date fehlen!';
    }
    this.receiptDate = DateTools.parseFormat(this.data.receiptDateString, 'yyyy-MM-dd', 'dd.MM.yyyy');
  }

  vatPercent = 16;


  async ngOnInit() {
  }
}
