import {Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[nxtCardDivider]',
    standalone: true,
})
export class NxtCardDividerDirective {


  constructor(el: ElementRef) {
    el.nativeElement.innerHTML = '&nbsp;';
    el.nativeElement.style.width = '10px';
    el.nativeElement.style.height = '10px';
  }

}
