<nxt-page>
  <nxt-page-content>
    <div class="absolute top-2 right-2 z-10 flex flex-row gap-2 buttons">
      @if (!isCropping()) {
        @if (currentZoom() !== 1) {
          <nxt-button-icon class="cursor-pointer" (click)="resetZoom()">zoom_out_map</nxt-button-icon>
        }
        <nxt-button-icon class="cursor-pointer" (click)="zoomOutClicked()">zoom_out</nxt-button-icon>
        <nxt-button-icon class="cursor-pointer" (click)="zoomInClicked()">zoom_in</nxt-button-icon>
      }
      @if (dialogRef) {
        <nxt-button-icon class="cursor-pointer" (click)="dialogRef.close()">close</nxt-button-icon>
      }
    </div>
    @if (contentOpacity() === 0) {
      <div class="absolute flex h-full w-full items-center justify-center">
        <nxt-spinner></nxt-spinner>
      </div>
    }
    @if (show()) {
      <div [style.opacity]="contentOpacity()" class="flex h-full w-full flex-col items-center justify-center photo-editor">
        <div #canvasWrapper class="flex h-full w-full items-center justify-center overflow-hidden canvas-wrapper" style="border: 0px solid blue">
          <canvas #canvasElement [width]="canvasWidth()" [height]="canvasHeight()"></canvas>
        </div>

        <div class="flex flex-row justify-center gap-4 p-2">
          @if (!isCropping()) {
            <button nxtClickAnimation (click)="switchLogoClicked()" matTooltip="Logo">
              <mat-icon class="mat-icon-lg" fontSet="material-symbols-outlined">wallpaper</mat-icon>
            </button>
            <button nxtClickAnimation (click)="rotateClicked(90)" matTooltip="rechts drehen">
              <mat-icon class="mat-icon-lg" fontSet="material-symbols-outlined">rotate_right</mat-icon>
            </button>

            <button nxtClickAnimation (click)="downloadClicked()" matTooltip="Herunterladen">
              <mat-icon class="mat-icon-lg" fontSet="material-symbols-outlined">download</mat-icon>
            </button>

            <mat-menu #shareMenu>
              <button mat-menu-item (click)="shareToStudioSocialMediaMobileClicked()">
                <span>Social Media Handy</span>
              </button>
              @for (sendTo of sendToOptions(); track sendTo.name) {
                <button mat-menu-item (click)="shareToClicked(sendTo.mobile)">
                  <span>{{ sendTo.name }}</span>
                </button>
              }
            </mat-menu>
            <button nxtClickAnimation [matMenuTriggerFor]="shareMenu" matTooltip="Teilen">
              <mat-icon class="mat-icon-lg" fontSet="material-symbols-outlined">share</mat-icon>
            </button>

            <button nxtClickAnimation (click)="saveClicked()" matTooltip="Speichern">
              <mat-icon class="mat-icon-lg" fontSet="material-symbols-outlined">save</mat-icon>
            </button>

            <button nxtClickAnimation matTooltip="Zuschneiden" (click)="startCropping()">
              <mat-icon class="mat-icon-lg" fontSet="material-symbols-outlined">crop</mat-icon>
            </button>
          }
          @if (isCropping()) {
            <button nxtClickAnimation (click)="cancelCropping()" matTooltip="Zuschneiden abbrechen">
              <mat-icon class="mat-icon-lg" fontSet="material-symbols-outlined">close</mat-icon>
            </button>
            <button nxtClickAnimation matTooltip="Zuschneiden anwenden" (click)="applyCrop()">
              <mat-icon class="mat-icon-lg" fontSet="material-symbols-outlined">done</mat-icon>
            </button>
          }
        </div>
        <div class="grid w-full select-none grid-cols-2 gap-x-2 gap-y-0 px-1 controls md:w-9/12 md:grid-cols-4 md:gap-2" style="border:0px solid red">
          <!-- Helligkeit -->
          <div class="flex flex-row items-center gap-3 md:hidden">
            <nxt-button class="!w-full !h-full" (click)="settingsDown('brightness')">&nbsp;-&nbsp;</nxt-button>
            <label class="center">Helligkeit<br/>{{ settings.brightness * 100 | nxtRound: 0 }}</label>
            <nxt-button class="!w-full !h-full" (click)="settingsUp('brightness')">&nbsp;+&nbsp;</nxt-button>
          </div>
          <div class="hidden md:flex">
            <label class="no-wrap">Helligkeit {{ settings.brightness * 100 | nxtRound: 0 }}</label>
            <mat-slider min="-0.4" max="0.4" step="0.01" class="!w-full">
              <input matSliderThumb [(ngModel)]="settings.brightness" (input)="applyAllSettings('input')">
            </mat-slider>
          </div>


          <!-- Kontrast -->
          <div class="flex flex-row items-center gap-3 md:hidden">
            <nxt-button class="!w-full !h-full" (click)="settingsDown('contrast')">&nbsp;-&nbsp;</nxt-button>
            <label class="center">Kontrast<br/>{{ settings.contrast * 100 | nxtRound: 0 }}</label>
            <nxt-button class="!w-full !h-full" (click)="settingsUp('contrast')">&nbsp;+&nbsp;</nxt-button>
          </div>
          <div class="hidden md:flex">
            <label class="no-wrap">Kontrast {{ settings.contrast * 100 | nxtRound: 0 }}</label>
            <mat-slider min="-0.4" max="0.4" step="0.01" class="!w-full">
              <input matSliderThumb [(ngModel)]="settings.contrast" (input)="applyAllSettings('input')">
            </mat-slider>
          </div>


          <!-- Sättigung -->
          <div class="flex flex-row items-center gap-3 md:hidden">
            <nxt-button class="!w-full !h-full" (click)="settingsDown('saturation')">&nbsp;-&nbsp;</nxt-button>
            <label class="center">Sättigung<br/>{{ settings.saturation * 100 | nxtRound: 0 }}</label>
            <nxt-button class="!w-full !h-full" (click)="settingsUp('saturation')">&nbsp;+&nbsp;</nxt-button>
          </div>
          <div class="hidden md:flex">
            <label class="no-wrap">Sättigung {{ settings.saturation * 100 | nxtRound: 0 }}</label>
            <mat-slider min="-0.4" max="0.4" step="0.01" class="!w-full">
              <input matSliderThumb [(ngModel)]="settings.saturation" (input)="applyAllSettings('input')">
            </mat-slider>
          </div>

          <!-- Schwarzwert -->
          <div class="flex flex-row items-center gap-3 md:hidden">
            <nxt-button class="!w-full !h-full" (click)="settingsDown('blackPoint')">&nbsp;-&nbsp;</nxt-button>
            <label class="center">Schwarz<br/>{{ settings.blackPoint * 100 | nxtRound: 0 }}</label>
            <nxt-button class="!w-full !h-full" (click)="settingsUp('blackPoint')">&nbsp;+&nbsp;</nxt-button>
          </div>
          <div class="hidden md:flex">
            <label class="no-wrap">Schwarz {{ settings.blackPoint * 100 | nxtRound: 0 }}</label>
            <mat-slider min="-0.4" max="0.4" step="0.01" class="!w-full">
              <input matSliderThumb [(ngModel)]="settings.blackPoint" (input)="applyAllSettings('input')">
            </mat-slider>
          </div>
        </div>
      </div>
    }
  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>






