import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {LoginService} from '../../services/login.service';
import {Log} from '../../common-browser/log/log.tools';

@Component({
    selector: 'nxt-draw',
    templateUrl: './draw.component.html',
    styleUrls: ['./draw.component.scss'],
    standalone: true
})
export class DrawComponent implements OnInit, OnDestroy {


  @ViewChild('codeSpan', {static: true}) codeSpan: ElementRef;
  private isRunning = false;



  constructor(private socketService: SocketService, private loginService: LoginService) {
  }

  async ngOnInit() {
    this.isRunning = true;
    this.run();
    this.setFullScreen();
  }

  setFullScreen(elem = document.body) {
    try {
      const methodToBeInvoked = elem.requestFullscreen || (elem as any).webkitRequestFullScreen || (elem as any).mozRequestFullscreen || (elem as any).msRequestFullscreen;
      if (methodToBeInvoked) {
        methodToBeInvoked.call(elem);
        // this.storageService.setLastFullscreenState(true);
      }
    } catch (err) {
      Log.error('ScreenService.setFullScreen failed', err);
    }
  }


  private async run() {
    let intervalToUse = 2000;
    try {
      Log.info('start getCode');
      const {code, interval} = await this.socketService.getCode();
      intervalToUse = interval;
      Log.info('code: ' + code + ' interval: ' + interval);
      this.codeSpan.nativeElement.innerText = code.toUpperCase();
    } catch (err) {

    }
    if (this.isRunning) {
      setTimeout(() => this.run(), intervalToUse);
    }
  }

  ngOnDestroy(): void {
    this.isRunning = false;
  }
}
