import {EventEmitter, Injectable} from '@angular/core';
import {IframeMessageManagerInIframe, IframeParentCommand} from './iframe-message-manager-in-iframe';
import {Observable} from 'rxjs';


export enum KeyCode {
  Add = 'Add',
  A = 'a',
  P = 'p',
  H = 'h',
  O = 'o',
  _1 = '1',
  _3 = '3',
  _6 = '6',
  _7 = '7',
  Alt1 = 'Alt+1',
  Alt2 = 'Alt+2',
  Alt3 = 'Alt+3',
  Alt4 = 'Alt+4',
  Alt5 = 'Alt+5',
  CtrlAUml = 'Ctrl+Ä',
  CtrlT = 'CtrlT',
  CtrlM = 'CtrlM',
  CtrlK = 'CtrlK',
  CtrlL = 'CtrlL',
  CtrlS = 'CtrlS',
  Enter = 'Enter',
  Ctrl1 = 'Ctrl1',
  Ctrl2 = 'Ctrl2',
  Ctrl3 = 'Ctrl3',
  Ctrl4 = 'Ctrl4',
  Ctrl5 = 'Ctrl5',
  Esc = 'Esc',
  AltB = 'AltB',
  AltV = 'AltV',
  AltD = 'AltD',
  AltK = 'AltK',
  AltP = 'AltP',
  AltR = 'AltR',
  CtrlShiftR = 'CtrlShiftR',
  CtrlShiftB = 'CtrlShiftB',
  Shift7 = 'shift+7',
  CtrlShiftL = 'CtrlShiftL',
  CtrlShiftD = 'CtrlShiftD',
  Up = 'up',
  Down = 'down',
  Right = 'right',
  Left = 'left',
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  // F5 = 'F5',
  F6 = 'F6',
  F7 = 'F7',
  F8 = 'F8',
  F9 = 'F9',
  F10 = 'F10',
  F11 = 'F11',
  // F12 = 'F12',
  C = 'C',
}


@Injectable({
  providedIn: 'root'
})
export class ShortcutService {

  constructor() {
    this.shortcut.add('up', () => this.emit(KeyCode.Up), {propagate: true});
    this.shortcut.add('down', () => this.emit(KeyCode.Down), {propagate: true});
    this.shortcut.add(KeyCode.Left, () => this.emit(KeyCode.Left), {propagate: true});
    this.shortcut.add(KeyCode.Right, () => this.emit(KeyCode.Right), {propagate: true});
    this.shortcut.add(KeyCode.A, () => this.emit(KeyCode.A), {propagate: true});
    this.shortcut.add(KeyCode.H, () => this.emit(KeyCode.H), {propagate: true});
    this.shortcut.add(KeyCode.P, () => this.emit(KeyCode.P), {propagate: true});
    this.shortcut.add(KeyCode.O, () => this.emit(KeyCode.O), {propagate: true});
    this.shortcut.add(KeyCode._1, () => this.emit(KeyCode._1), {propagate: true});
    this.shortcut.add(KeyCode._3, () => this.emit(KeyCode._3), {propagate: true});
    this.shortcut.add(KeyCode._6, () => this.emit(KeyCode._6), {propagate: true});
    this.shortcut.add(KeyCode._7, () => this.emit(KeyCode._7), {propagate: true});
    this.shortcut.add('Alt+1', () => this.emit(KeyCode.Alt1));
    this.shortcut.add('Alt+2', () => this.emit(KeyCode.Alt2));
    this.shortcut.add('Alt+3', () => this.emit(KeyCode.Alt3));
    this.shortcut.add('Alt+4', () => this.emit(KeyCode.Alt4));
    this.shortcut.add('Alt+5', () => this.emit(KeyCode.Alt5));
    this.shortcut.add('Alt+B', () => this.emit(KeyCode.AltB));
    this.shortcut.add('Alt+D', () => this.emit(KeyCode.AltD));
    this.shortcut.add('Alt+K', () => this.emit(KeyCode.AltK));
    this.shortcut.add('Alt+V', () => this.emit(KeyCode.AltV));
    this.shortcut.add('Alt+P', () => this.emit(KeyCode.AltP));
    this.shortcut.add('Alt+R', () => this.emit(KeyCode.AltR));
    this.shortcut.add('Ctrl+1', () => this.emit(KeyCode.Ctrl1));
    this.shortcut.add('Ctrl+2', () => this.emit(KeyCode.Ctrl2));
    this.shortcut.add('Ctrl+3', () => this.emit(KeyCode.Ctrl3));
    this.shortcut.add('Ctrl+4', () => this.emit(KeyCode.Ctrl4));
    this.shortcut.add('Ctrl+5', () => this.emit(KeyCode.Ctrl5));
    this.shortcut.add('Ctrl+K', () => this.emit(KeyCode.CtrlK));
    this.shortcut.add('Ctrl+Ä', () => this.emit(KeyCode.CtrlAUml));
    this.shortcut.add('Add', () => this.emit(KeyCode.Add), {disable_in_input: true});
    this.shortcut.add('Ctrl+T', () => this.emit(KeyCode.CtrlT));
    this.shortcut.add('Ctrl+M', () => this.emit(KeyCode.CtrlM));
    this.shortcut.add('Ctrl+L', () => this.emit(KeyCode.CtrlL));
    this.shortcut.add('F1', () => this.emit(KeyCode.F1));
    this.shortcut.add('F2', () => this.emit(KeyCode.F2));
    this.shortcut.add('F3', () => this.emit(KeyCode.F3));
    this.shortcut.add('F4', () => this.emit(KeyCode.F4));
    // this.shortcut.add('F5', () => this.onKeyPress.emit(KeyCode.F5));
    this.shortcut.add('F6', () => this.emit(KeyCode.F6));
    this.shortcut.add('F7', () => this.emit(KeyCode.F7));
    this.shortcut.add('F8', () => this.emit(KeyCode.F8));
    this.shortcut.add(KeyCode.F9, () => this.emit(KeyCode.F9));
    this.shortcut.add('F10', () => this.emit(KeyCode.F10));
    this.shortcut.add('F11', () => this.emit(KeyCode.F11));
    // this.shortcut.add('F12', () => this.onKeyPress.emit(KeyCode.F12));

    this.shortcut.add('Ctrl+S', () => {
      this.emit(KeyCode.CtrlS);
    });
    this.shortcut.add('esc', () => this.emit(KeyCode.Esc), {disable_in_input: true});
    this.shortcut.add('enter', () => this.emit(KeyCode.Enter), {propagate: true});
    this.shortcut.add('c', () => this.emit(KeyCode.C), {propagate: true});
    this.shortcut.add('ctrl+shift+b', () => this.emit(KeyCode.CtrlShiftB));
    this.shortcut.add('ctrl+shift+l', () => this.emit(KeyCode.CtrlShiftL));
    this.shortcut.add('ctrl+shift+d', () => this.emit(KeyCode.CtrlShiftD));
    this.shortcut.add('ctrl+shift+r', () => this.emit(KeyCode.CtrlShiftR));

    this.shortcut.add('ctrl+shift+s', () => {
      if (this.iframeShowHide) {
        alert('show iframe');
        IframeMessageManagerInIframe.instance.sendEval(IframeParentCommand.showIframe);
      } else {
        alert('hide iframe');
        IframeMessageManagerInIframe.instance.sendEval(IframeParentCommand.hideIframe);
      }
      this.iframeShowHide = !this.iframeShowHide;
    });


    this.shortcut.add('ctrl+shift+9', async () => {
      this.switchTestCounter++;
      setTimeout(() => this.switchTestCounter = 0, 500);

      if (this.switchTestCounter === 3) {
        IframeMessageManagerInIframe.instance.send('eval', IframeParentCommand.showIframe);
        const newUrl = window.location.href;
        if (newUrl.indexOf('organizer') > -1) {
          // newUrl = window.location.href.replace('organizer.nxt-lvl.ink/', 'test.nxt-lvl.ink:99/');
        }

        // await this.dialogService.showOk('switch\n\n' + window.location.href + '\n->\n' + newUrl);
        IframeMessageManagerInIframe.instance.send('eval', 'window[\'createIframe\'](\'' + newUrl + '\');');
      }
    });
  }

  private iframeShowHide = true;

  onKeyPress = new EventEmitter<KeyCode>();

  private _onKeyPress = new EventEmitter<KeyCode>();

  exclusiveBlockedKeyCodes: { keyCode: KeyCode, subscriptionIndex: number }[] = [];

  subscriptionIndexCounter = 0;

  onKeyPressExclusive = {
    subscribe: (keyCodes: KeyCode[], callback: (keyCode: KeyCode) => void) => {
      const subscriptionIndex = this.subscriptionIndexCounter++;
      return new Observable((subscriber) => {
        this.exclusiveBlockedKeyCodes.push(...keyCodes.map(keyCode => ({keyCode, subscriptionIndex})));
        const subscription = this._onKeyPress.subscribe((keyCode) => {
          if (keyCodes.includes(keyCode)) {
            if (subscriptionIndex === this.getLastRegisteredExclusiveSubscriptionIndex(keyCode)) {
              subscriber.next(keyCode);
            }
          }
        });
        return () => {
          subscription.unsubscribe();
          this.exclusiveBlockedKeyCodes = this.exclusiveBlockedKeyCodes.filter(e => {
            return e.subscriptionIndex !== subscriptionIndex;
          });
        };
      }).subscribe(callback);
    }
  };

  switchTestCounter = 0;

  shortcut = {
    all_shortcuts: {}, // All the shortcuts are stored in this array
    add(shortcutCombination, callback, opt?) {
      // Provide a set of default options
      const defaultOptions = {
        type: 'keydown',
        propagate: false,
        disable_in_input: false,
        target: document,
        keycode: false
      };
      if (!opt) {
        opt = defaultOptions;
      } else {
        for (const dfo in defaultOptions) {
          if (typeof opt[dfo] === 'undefined') {
            opt[dfo] = defaultOptions[dfo];
          }
        }
      }

      let ele = opt.target;
      if (typeof opt.target === 'string') {
        ele = document.getElementById(opt.target);
      }
      const ths = this;
      shortcutCombination = shortcutCombination.toLowerCase();

      // The function to be called at keypress
      const func = (e) => {
        let code;
        e = e || window.event;

        if (opt.disable_in_input) { // Don't enable shortcut keys in Input, Textarea fields
          let element;
          if (e.target) {
            element = e.target;
          } else if (e.srcElement) {
            element = e.srcElement;
          }
          if (element.nodeType === 3) {
            element = element.parentNode;
          }

          if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA' || element.tagName === 'MAT-SELECT') {
            return;
          }
        }

        // Find Which key is pressed
        if (e.keyCode) {
          code = e.keyCode;
        } else if (e.which) {
          code = e.which;
        }
        let character = String.fromCharCode(code).toLowerCase();

        if (code === 188) {
          character = ',';
        } // If the user presses , when the type is onkeydown
        if (code === 190) {
          character = '.';
        } // If the user presses , when the type is onkeydown

        const keys = shortcutCombination.split('+');
        // Key Pressed - counts the number of valid keypresses - if it is same as the number of keys, the shortcut function is invoked
        let kp = 0;

        // Work around for stupid Shift key bug created by using lowercase - as a result the shift+num combination was broken
        const shiftNums = {
          '`': '~',
          1: '!',
          2: '@',
          3: '#',
          4: '$',
          5: '%',
          6: '^',
          7: '&',
          8: '*',
          9: '(',
          0: ')',
          '-': '_',
          '=': '+',
          ';': ':',
          '\'': '"',
          ',': '<',
          '.': '>',
          '/': '?',
          '\\': '|'
        };
        // Special Keys - and their codes
        const specialKeys = {
          esc: 27,
          escape: 27,
          tab: 9,
          space: 32,
          return: 13,
          enter: 13,
          backspace: 8,

          scrolllock: 145,
          scroll_lock: 145,
          scroll: 145,
          capslock: 20,
          caps_lock: 20,
          caps: 20,
          numlock: 144,
          num_lock: 144,
          num: 144,

          pause: 19,
          break: 19,

          insert: 45,
          home: 36,
          delete: 46,
          end: 35,

          pageup: 33,
          page_up: 33,
          pu: 33,

          pagedown: 34,
          page_down: 34,
          pd: 34,

          left: 37,
          up: 38,
          right: 39,
          down: 40,

          multiply: 106,
          add: 107,
          subtract: 109,

          f1: 112,
          f2: 113,
          f3: 114,
          f4: 115,
          f5: 116,
          f6: 117,
          f7: 118,
          f8: 119,
          f9: 120,
          f10: 121,
          f11: 122,
          f12: 123
        };

        const modifiers = {
          shift: {wanted: false, pressed: false},
          ctrl: {wanted: false, pressed: false},
          alt: {wanted: false, pressed: false},
          meta: {wanted: false, pressed: false}	// Meta is Mac specific
        };

        if (e.ctrlKey) {
          modifiers.ctrl.pressed = true;
        }
        if (e.shiftKey) {
          modifiers.shift.pressed = true;
        }
        if (e.altKey) {
          modifiers.alt.pressed = true;
        }
        if (e.metaKey) {
          modifiers.meta.pressed = true;
        }
        let k;
        for (let i = 0; k = keys[i], i < keys.length; i++) {
          // Modifiers
          if (k === 'ctrl' || k === 'control') {
            kp++;
            modifiers.ctrl.wanted = true;

          } else if (k === 'shift') {
            kp++;
            modifiers.shift.wanted = true;

          } else if (k === 'alt') {
            kp++;
            modifiers.alt.wanted = true;
          } else if (k === 'meta') {
            kp++;
            modifiers.meta.wanted = true;
          } else if (k.length > 1) { // If it is a special key
            if (specialKeys[k] === code) {
              kp++;
            }

          } else if (opt.keycode) {
            if (opt.keycode === code) {
              kp++;
            }

          } else { // The special keys did not match
            if (character === k) {
              kp++;
            } else {
              if (shiftNums[character] && e.shiftKey) { // Stupid Shift key bug created by using lowercase
                character = shiftNums[character];
                if (character === k) {
                  kp++;
                }
              }
            }
          }
        }

        if (kp === keys.length &&
          modifiers.ctrl.pressed === modifiers.ctrl.wanted &&
          modifiers.shift.pressed === modifiers.shift.wanted &&
          modifiers.alt.pressed === modifiers.alt.wanted &&
          modifiers.meta.pressed === modifiers.meta.wanted) {
          callback(e);

          if (!opt.propagate) { // Stop the event
            // e.cancelBubble is supported by IE - this will kill the bubbling process.
            e.cancelBubble = true;
            e.returnValue = false;

            // e.stopPropagation works in Firefox.
            if (e.stopPropagation) {
              e.stopPropagation();
              e.preventDefault();
            }
            return false;
          }
        }
      };
      this.all_shortcuts[shortcutCombination] = {
        callback: func,
        target: ele,
        event: opt.type
      };
      // Attach the function with the event
      if (ele.addEventListener) {
        ele.addEventListener(opt.type, func, false);
      } else if (ele.attachEvent) {
        ele.attachEvent('on' + opt.type, func);
      } else {
        ele['on' + opt.type] = func;
      }
    },

    // Remove the shortcut - just specify the shortcut and I will remove the binding
    remove(shortcutCombination) {
      shortcutCombination = shortcutCombination.toLowerCase();
      const binding = this.all_shortcuts[shortcutCombination];
      delete (this.all_shortcuts[shortcutCombination]);
      if (!binding) {
        return;
      }
      const type = binding.event;
      const ele = binding.target;
      const callback = binding.callback;

      if (ele.detachEvent) {
        ele.detachEvent('on' + type, callback);
      } else if (ele.removeEventListener) {
        ele.removeEventListener(type, callback, false);
      } else {
        ele['on' + type] = false;
      }
    }
  };

  private getLastRegisteredExclusiveSubscriptionIndex(keyCode: KeyCode) {
    const blockedKeyCode = this.exclusiveBlockedKeyCodes.filter(e => e.keyCode === keyCode).sortNumber('subscriptionIndex', true);
    if (blockedKeyCode.length > 0) {
      return blockedKeyCode[0].subscriptionIndex;
    }
    throw Error('getLastRegisteredExclusiveSubscriptionIndex fehler');
  }

  registerKey(keyCode: KeyCode, options?: any) {
    this.shortcut.add(keyCode, () => this.emit(keyCode), options);
    return this;
  }

  init() {

  }

  private emit(keyCode: KeyCode) {
    if (!this.exclusiveBlockedKeyCodes.some(e => e.keyCode === keyCode)) {
      this.onKeyPress.emit(keyCode);
    }
    this._onKeyPress.emit(keyCode);
  }
}
