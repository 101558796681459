import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BodyPutService} from '../services/body-put.service';
import {NxtCalendarEventSmall} from '../common-interfaces/nxt.calendar-event.interface';

@Pipe({
  name: 'calendarBodyPuts',
  standalone: true
})
export class CalendarBodyPutPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer,
    private bodyPutService: BodyPutService) {
  }

  transform(event: NxtCalendarEventSmall, prefix = ''): string {
    return prefix + this.bodyPutService.getTextFromEvent(event).join('<br/>' + prefix);
  }
}
