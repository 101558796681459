import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {NxtEmployee} from '../../../common-interfaces/nxt.employee.interface';
import {QRCodeComponent} from 'angularx-qrcode';

@Component({
  selector: 'nxt-telegram-artist-start-chat',
  templateUrl: './telegram-employee-start-chat.component.html',
  styleUrls: ['./telegram-employee-start-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtButtonComponent, QRCodeComponent],
  standalone: true,
})

export class TelegramEmployeeStartChatComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  qrCode = signal<string>('');
  employee = signal<NxtEmployee | null>(null);


  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private dialogRef = inject(MatDialogRef);


  constructor() {
    super();
  }

  ngOnInit() {
    this.pushSocketSubscription = this.socketService.subscribeNew('eventEmployeeChanged', async (data) => {
      if (this.employee().id === data.record.id) {
        if (data.record.telegramChatId) {
          this.dialogRef.close();
        }
      }
    });
  }

  nxtOnDestroy() {
  }

  async setEmployee(employeeId: string) {
    this.employee.set(await this.socketService.getEmployee(employeeId));
    if (this.employee()) {
      this.qrCode.set('https://t.me/nxtlvlink_bot?start=' + btoa('Employee:' + this.employee().id));
    }
  }

  cancelClicked() {
    this.dialogRef.close();
  }
}
