import {Component, OnInit, Optional} from '@angular/core';
import {SocketService} from '../../../services/socket/socket.service';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {NxtEventFinderData, NxtGetEventFinder} from '../../../common-interfaces/socket/nxt.event-finder.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtAvailableArtistDayExtendedArtist} from '../../../common-interfaces/nxt.available-artist-day.interface';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {JsonTools} from '../../../common-browser/helpers/json.tools';
import {LoginService} from '../../../services/login.service';
import {TimeTools} from '../../../common-browser/helpers/time.tools';
import {ConfigService} from '../../../services/config.service';
import {DurationPipe} from '../../../pipes/duration.pipe';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {PermissionDirective} from '../../../directives/permission.directive';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {RateIconsComponent} from '../../../components/event-finder/rate-icons/rate-icons.component';
import {NgFor, NgIf, NgStyle} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-other-available-artists',
    templateUrl: './other-available-artist.component.html',
    styleUrls: ['./other-available-artist.component.scss'],
    imports: [FlexModule, NgIf, NgFor, RateIconsComponent, NgStyle, ExtendedModule, NxtButtonIconComponent, PermissionDirective, NxtButtonComponent, DurationPipe]
})
export class OtherAvailableArtistComponent implements OnInit {
  private eventStart: number;
  private eventEnd: number;
  public loading = false;
  private eventFinderData: NxtGetEventFinder;
  private eventId: string;

  constructor(
    private socketService: SocketService,
    @Optional() public dialogRef: MatDialogRef<OtherAvailableArtistComponent>,
    private loginService: LoginService,
    private configService: ConfigService
  ) {

  }

  result: NxtEventFinderData;
  availableArtists: NxtAvailableArtistDayExtendedArtist[];
  title = '';
  startTime = '';

  timeWindowColors: { [timeWindowId: string]: string } = {};
  public showTest = true;

  // public till = '20:00';
  public showArtistSkill = false;

  ngOnInit(): void {

  }

  async load(data: { dateString: string, from: string, till: string, eventId: string, boolSkillFilter: string[], skillFilter: string[] }) {
    this.showArtistSkill = data.boolSkillFilter.length > 0 || data.skillFilter.length > 0;
    this.loading = true;
    this.startTime = data.from;
    this.eventStart = DateTools.parse(data.dateString + ' ' + data.from, 'yyyy-MM-dd HH:mm');
    this.eventEnd = DateTools.parse(data.dateString + ' ' + data.till, 'yyyy-MM-dd HH:mm');
    this.title = 'Termin ist von ' + this.eventStart.dateFormat('HH:mm') + ' bis ' + this.eventEnd.dateFormat('HH:mm');
    this.eventId = data.eventId;
    const studioEndTime = DateTools.timeStringToDuration(data.till) / DurationTools.DURATION_1HOUR;
    const studioStart = this.configService.config.value.studioWorkStart / DurationTools.DURATION_1HOUR;
    this.eventFinderData = {
      duration: this.eventEnd - this.eventStart,
      fromDateString: data.dateString,
      tillDateString: data.dateString,
      boolSkillFilter: data.boolSkillFilter,
      filters: [{startTime: studioStart, studioEndTime, days: [true, true, true, true, true, true, true]}],
      skillFilter: data.skillFilter,
      fillMissingDays: false,
      forceLoadAllArtists: true
    };
    this.result = await this.socketService.eventFinder(this.eventFinderData);
    if (this.result.days.length > 0) {
      setTimeout(() => {
        this.availableArtists = this.result.days[0].artists.filter(a => a.freeTimeWindows.length > 0);
        this.calcTimeWindows();
        this.loading = false;
      }, 100);
    } else {
      this.availableArtists = [];
      this.loading = false;
    }
  }

  useArtist(artist: NxtAvailableArtistDayExtendedArtist) {
    this.dialogRef.close(artist.name);
  }

  private calcTimeWindows() {
    const start = DurationTools.parse(this.eventStart.dateFormat('HH:mm'));
    const end = DurationTools.parse(this.eventEnd.dateFormat('HH:mm'));
    for (const artist of this.availableArtists) {
      for (const freeTimeWindow of artist.freeTimeWindows) {
        if (freeTimeWindow.startTime <= start && freeTimeWindow.endTime >= end) {
          if (freeTimeWindow.startTime === start) {
            this.timeWindowColors[freeTimeWindow.id] = ColorTools.Green;
          }
        } else {
          this.timeWindowColors[freeTimeWindow.id] = ColorTools.Red;
        }
      }
    }
  }

  public async infoToJulian() {
    await this.socketService.sendTelegramAdmin(this.loginService.getUsername() + ' ist der Meinung, "Anderer Artist" funktioniert nicht');
    await TimeTools.sleep(200);
    await this.socketService.sendTelegramAdmin('Anfrage:\nEventId: ' + this.eventId + '\n' + JsonTools.stringifyFormat(this.eventFinderData));
    await TimeTools.sleep(200);
    await this.socketService.sendTelegramAdmin('Antwort 1 result:\n' + JsonTools.stringifyFormat(this.result));
    // await TimeTools.sleep(200);
    // await this.socketService.sendTelegramAdmin('Antwort2 availableArtists:\n' + JsonTools.stringifyFormat(this.availableArtists));
  }
}

