<div class="nxt-page !p-0">
  <div class="flex flex-row justify-stretch items-center">
    <mat-button-toggle-group class="flex" [(value)]="currentPage" (valueChange)="buttonChanged()">
      @for (page of pages; track page) {
        <mat-button-toggle [value]="page.url">{{ page.name }}</mat-button-toggle>
      }
    </mat-button-toggle-group>


    <!--<nxt-slide-toggle leftText="CashReport" [noColorChange]="true" [(value)]="showUrl1">Kalender</nxt-slide-toggle>-->
    <div class="flex flex-grow">
      <mat-tab-group class="h-fit w-full" [(selectedIndex)]="studioIndex">
        @for (studio of studios; track studio.name) {
          <mat-tab class="w-full h-fit" [label]="studio.name" fxLayout="column"></mat-tab>
        }
      </mat-tab-group>
    </div>
  </div>
  <div class="flex flex-column w-full h-full">
    @for (studio of studios; track studio.name; let sIndex = $index) {
      @for (page of pages; track page.url) {
        <div [class.visible]="sIndex === studioIndex && currentPage === page.url" class="iframe-wrapper">
          <div>{{ studio.url + currentPage }}</div>
          <iframe class="w-full h-full" [src]="studio.url + currentPage | safeUrl: true"></iframe>

          <!--@defer (on viewport) {
            <iframe class="w-full h-full" [src]="studio.url + currentPage | safeUrl: true"></iframe>
          } @placeholder {
            <div>Loading...</div>
          }-->
        </div>
      }
    }
  </div>
</div>
