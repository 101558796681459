import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, Optional, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtReminder, NxtReminderButtonAction, NxtReminderRefType} from '../../../common-interfaces/nxt.reminder.interface';
import {ReminderTools} from '../../../common-browser/helpers/reminder.tools';
import {SocketService} from '../../../services/socket/socket.service';
import {ReminderService} from '../../../services/reminder.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {StringTools} from '../../../common-browser/helpers/string.tools';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {MultiClickDirective} from '../../../directives/multi-click.directive';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';

type ActionButton = { text: string, action: NxtReminderButtonAction | 'back' };

@Component({
    selector: 'nxt-reminder-view',
    templateUrl: './reminder-view.component.html',
    styleUrls: ['./reminder-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NxtButtonComponent, MultiClickDirective, SafeHtmlPipe]
})
export class ReminderViewComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/
  title = signal('');
  reminder = signal<NxtReminder>(null);
  buttons = signal<ActionButton[]>([]);
  /*** Signals ***/

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  socketService = inject(SocketService);
  reminderService = inject(ReminderService);
  @Optional() dialogRef = inject(MatDialogRef<ReminderViewComponent>);


  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }


  setData(reminder: NxtReminder) {
    this.reminder.set(reminder);
    if (reminder.createdBy === 'System') {
      this.title.set('Aufgabe für ' + ReminderTools.getTodoBy(reminder));
    } else {
      this.title.set('Aufgabe von ' + reminder.createdBy + ' für ' + ReminderTools.getTodoBy(reminder));
    }
    this.drawButtons();
  }

  private async drawButtons() {
    const backButton: ActionButton = {text: 'Zurück', action: 'back'};

    const setDoneButton: ActionButton = {text: 'Erledigt', action: 'setDone'};

    const writeResponseButton: ActionButton = {text: 'Antworten', action: 'writeResponse'};

    let postponeButtonText = 'Später';
    if (this.reminder().postponeDurations.length === 1) {
      postponeButtonText = StringTools.arrowRight + ' ' + DurationTools.format(this.reminder().postponeDurations[0], '', true);
    }
    const postponeButton: ActionButton = {text: postponeButtonText, action: 'postpone'};

    const buttons: ActionButton[] = [backButton];
    if (this.reminder().postponeDurations.length > 0) {
      buttons.push(postponeButton);
    }

    if (!this.reminder().disableManualSetDone) {
      buttons.push(setDoneButton);
    }


    if (this.reminder().refType === NxtReminderRefType.Event && (!this.reminder().buttons || !this.reminder().buttons.some(b => b.action === 'showEvent'))) {
      buttons.push({text: 'Termin öffnen', action: 'showEvent'});
    }


    if (this.reminder().buttons) {
      for (const button of this.reminder().buttons) {
        buttons.push({text: button.text, action: button.action});
      }
    }
    this.buttons.set(buttons);
  }

  async buttonClicked(button: ActionButton) {
    if (button.action === 'back') {
      this.dialogRef.close();
      return;
    }
    await this.reminderService.startReminderAction(this.reminder(), button.action).then();
    this.dialogRef.close();
  }

  showHack() {
    this.reminder.update(reminder => {
      if (!reminder.postponeDurations.includes(DurationTools.DURATION_1HOUR * 6)) {
        reminder.postponeDurations.push(DurationTools.DURATION_1HOUR * 6);
      }
      if (reminder.disableManualSetDone) {
        reminder.disableManualSetDone = false;
      }
      return reminder;
    });
    this.drawButtons().then();
  }
}
