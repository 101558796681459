<div class="flex flex-col h-full" style="border: 0px solid red">
  @if (title()) {
    <div class="flex w-full justify-center text-[120%] py-3">{{ title() }}</div>
  }
  <div style="border: 0px solid red; max-width: 70vw; max-height: 70vh" class="flex flex-col items-center justify-center">
    <image-cropper
      style="border: 0px solid green"
      [imageURL]="src()"
      [maintainAspectRatio]="false"
      (imageCropped)="imageCropped($event)"
      [canvasRotation]="canvasRotation()"
      [imageQuality]="100"></image-cropper>
  </div>
  <img [src]="croppedImage() | safeUrl" style="height: 25vh; width: fit-content" *ngIf="src() && showPreview()">
  <div class="flex flex-row justify-around pt-3">
    <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
    <nxt-button (click)="rotateLeftClicked()">Bild drehen</nxt-button>
    <nxt-button (click)="buttonClicked()">{{ buttonText() }}</nxt-button>
  </div>
</div>

