<div class="wrapper" [ngClass]="{'more-button-padding': data.fontSize !== '100%'}">
  <div *ngIf="data.title" style="font-size: 22px">
    <div class="title-text" [ngStyle]="{textAlign: data.textAlign, fontSize: data.fontSize}">{{data.title}}</div>
  </div>
  <div style="margin-bottom:24px; margin-top:10px;" [ngStyle]="{textAlign: data.textAlign, fontSize: data.fontSize}" [innerHTML]="data.message | safeHtml">
  </div>
  <div class="flex flex-row justify-around gap-3" [ngStyle]="{fontSize: data.fontSize}">
    <nxt-button [useMinWidth]="true" (click)="no()">
      <div [innerHTML]="(data.noText ? data.noText.toUpperCase() : 'NEIN') | safeHtml"></div>
    </nxt-button>
    <nxt-button (click)="yes()">
      <div [innerHTML]="(data.yesText ? data.yesText.toUpperCase() : 'JA') | safeHtml"></div>
    </nxt-button>
  </div>
</div>
