import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ArrayTools} from '../../common-browser/helpers/array.tools';

@Injectable({
  providedIn: 'root'
})
export class YearFinishService {


  constructor(
    private httpClient: HttpClient
  ) {
    this.getDiffs();
  }


  async getDiffs() {
    const data: any = await this.httpClient.get('assets/2021.json').toPromise();
    console.log(data);

    let texts = data.filter(d => d?.Buchungstext).map(d => d.Buchungstext);
    texts = ArrayTools.unique(texts);


    let result: any[] = [];

    texts.forEach(text => {
      const lines = data.filter(d => d && d.Buchungstext === text);

      let soll = 0;
      let haben = 0;

      lines.forEach(line => {
        if (line['Umsatz Soll']) {
          soll += line['Umsatz Soll'];
        }

        if (line['Umsatz Haben']) {
          haben += line['Umsatz Haben'];
        }
      });
      if (soll !== haben) {
        result.push({text, soll, haben, diff: haben - soll});
      }


    });
    result = result.sortNumber('diff', true);
    return result;
    // console.log(result);
  }


}
