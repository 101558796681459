import {Component, Input, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NxtTypedFormControl} from '../../../../nxt-form/nxt.typed-form-control';
import {NxtPromoStudioShort, NxtPromoUser, NxtPromoUserPercentage} from '../../../../common-interfaces/nxt.promo.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {FormTools} from '../../../../services/form.tools';
import {LoginService} from '../../../../services/login.service';
import {SocketService} from '../../../../services/socket/socket.service';
import {DateTools} from '../../../../common-browser/helpers/date.tools';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {NxtButtonIconComponent} from '../../../../controls/button-icon/nxt-button-icon.component';
import {DatePickerComponent} from '../../../form-controls/date-picker/date-picker.component';
import {NgFor} from '@angular/common';
import {TextareaComponent} from '../../../form-controls/textarea/textarea.component';
import {SlideToggleComponent} from '../../../form-controls/slide-toggle/slide-toggle.component';
import {SelectComponent} from '../../../form-controls/select/select.component';
import {MultiClickDirective} from '../../../../directives/multi-click.directive';
import {InputComponent} from '../../../form-controls/input/input.component';
import {DialogService} from '../../../../services/dialog.service';
import {ValidatorTools} from '../../../../helpers/validator.tools';


export type PromoterPercentagesFormGroup = FormGroup<{
  startDateString: NxtTypedFormControl<string>,
  endDateString: NxtTypedFormControl<string>,
  percentage: NxtTypedFormControl<number>,
}>;

@Component({
    selector: 'nxt-promo-user-edit',
    templateUrl: './promo-user-edit.component.html',
    styleUrls: ['./promo-user-edit.component.scss'],
    imports: [InputComponent, MultiClickDirective, SelectComponent, SlideToggleComponent, TextareaComponent, NgFor, DatePickerComponent, NxtButtonIconComponent, NxtButtonComponent]
})

export class PromoUserEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {


  form = new FormGroup({
    id: new NxtTypedFormControl<string>('', [], 'ID'),
    promoterId: new NxtTypedFormControl<string>('', [], 'Promoter-ID'),
    infoInternal: new NxtTypedFormControl<string>('', [], 'Interne Info'),
    name: new NxtTypedFormControl('', [ValidatorTools.requiredAndNotNaN], 'Name'),
    studio: new NxtTypedFormControl<NxtPromoStudioShort>(null, [ValidatorTools.requiredAndNotNaN], 'Studio'),
    email: new NxtTypedFormControl('', [], 'E-Mail'),
    isNxtPromoter: new NxtTypedFormControl(false, [], 'NXT-Promoter'),
    promoterPercentages: this.fb.array<PromoterPercentagesFormGroup>([]),
    disabled: new NxtTypedFormControl(false, [], 'Deaktiviert'),
  });

  constructor(
    private fb: FormBuilder,
    @Optional() private dialogRef: MatDialogRef<PromoUserEditComponent>,
    private loginService: LoginService,
    private socketService: SocketService,
    private dialogService: DialogService
  ) {
    super();
    this.form.valueChanges.subscribe(() => this.formChanged());
  }

  @Input() user: NxtPromoUser;
  minFrom = new Date(Date.now().dateAddDays(-3));

  ngOnInit() {
    this.user.promoterPercentages?.sortString('startDateString');
    FormTools.setForm(this.form, this.user, {promoterPercentages: this.getPromoterPercentagesControl.bind(this)});
    this.form.controls.promoterPercentages.controls.forEach(c => c.disable());
    this.form.controls.email.disable();
    this.form.controls.promoterId.disable();
  }

  getPromoterPercentagesControl(percentage: NxtPromoUserPercentage) {
    const percentageFormGroup: PromoterPercentagesFormGroup = new FormGroup({
      startDateString: new NxtTypedFormControl<string>(percentage.startDateString, [ValidatorTools.requiredAndNotNaN], 'Von'),
      endDateString: new NxtTypedFormControl<string>(percentage.endDateString, [], 'Bis'),
      percentage: new NxtTypedFormControl<number | null>(percentage.percentage, [ValidatorTools.requiredAndNotNaN], 'Provision'),
    });
    this.registerFormGroupValueChanges(percentageFormGroup);
    return percentageFormGroup;
  }

  addPercentage() {
    console.log(this.form.controls.promoterPercentages.controls);
    // console.log(percentage.controls.endDateString);
    // console.log(percentage.controls.endDateString);


    const formGroup = this.getPromoterPercentagesControl({
      startDateString: '',
      endDateString: '',
      percentage: null,
    });
    formGroup.controls.endDateString.disable();
    this.form.controls.promoterPercentages.push(formGroup);
  }

  nxtOnDestroy() {
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  async saveClicked() {
    this.getPromoterFromForm();
    if (this.form.getRawValue().promoterPercentages.some(p => p.percentage > 15)) {
      this.dialogService.showOk('Maximal 15%!');
      return;
    }
    const userToSave: NxtPromoUser = {...this.user, ...this.form.getRawValue()};
    await this.socketService.upsertPromoUser(userToSave);
    this.dialogRef.close(true);
  }

  private registerFormGroupValueChanges(percentageFormGroup: PromoterPercentagesFormGroup) {

  }

  enablePromoterId() {
    if (this.loginService.isJulian()) {
      this.form.controls.isNxtPromoter.enable();
    }
  }

  private formChanged() {
    for (const [index, formGroup] of this.form.controls.promoterPercentages.controls.entries()) {
      const isLast = index === this.form.controls.promoterPercentages.controls.length - 1;

      if (!isLast) {
        let toSet = this.form.controls.promoterPercentages.controls[index + 1].value.startDateString;
        if (toSet) {
          toSet = DateTools.format(DateTools.parse(toSet).dateAddDays(-1), 'yyyy-MM-dd');
          if (formGroup.controls.endDateString.value !== toSet) {
            this.form.controls.promoterPercentages.controls[index].patchValue({endDateString: toSet});
          }
        }
      }
    }
  }

  private getPromoterFromForm() {
    this.user = {...this.user, ...this.form.getRawValue()};
    for (const promoterPercentage of this.user.promoterPercentages) {
      promoterPercentage.startDateString = DateTools.parse(promoterPercentage.startDateString).dateFormatDate();
      if (promoterPercentage.endDateString) {
        promoterPercentage.endDateString = DateTools.parse(promoterPercentage.endDateString).dateFormatDate();
      }
    }
  }
}
