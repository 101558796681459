<div class="nxt-page nxt-text">


  <div>data1: {{ data1 }}</div>
  <div>data2: {{ data2 }}</div>
  <div *ngIf="showData3">data3: {{ $data3 | async }}</div>
  <br/>
  <div>observersLength: {{ observersLength }}</div>
  <br/>
  <br/>
  <nxt-button (click)="showData3 = !showData3">switch data3</nxt-button>
  <br/>
  <br/>
  <nxt-button (click)="trigger()">trigger</nxt-button>
  <br>
  <br>
  <nxt-button (click)="subscribe1()">subscribe 1</nxt-button>
  <nxt-button (click)="subscribe2()">subscribe 2</nxt-button>
  <br>
  <br>
  <nxt-button (click)="unsubscribe1()">unsubscribe 1</nxt-button>
  <nxt-button (click)="unsubscribe2()">unsubscribe 2</nxt-button>
  <br/>

</div>
