import {Component, computed, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {MultiClickDirective} from '../../directives/multi-click.directive';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {LoadingCubeComponent} from './loading-cube/loading-cube.component';
import {LoadingPacmanComponent} from './loading-pacman/loading-pacman.component';
import {LoadingLinesComponent} from './loading-lines/loading-lines.component';
import {LoadingNetComponent} from './loading-net/loading-net.component';

@Component({
  selector: 'nxt-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss', './loading-2.component.scss'],
  imports: [MultiClickDirective, SafeHtmlPipe, LoadingCubeComponent, LoadingPacmanComponent, LoadingLinesComponent, LoadingNetComponent]
})
export class LoadingComponent implements OnInit {
  showLoading = true;
  allowCancelClick = false;
  random = computed(() => {
    return MathTools.random(1, 6);
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    @Optional() public dialogRef: MatDialogRef<LoadingComponent>,
  ) {
  }

  ngOnInit() {
    // setTimeout(() => this.showLoading = true, 500);

  }

  allowCancel() {
    this.allowCancelClick = true;
  }

  multiClicked() {
    if (this.allowCancelClick) {
      this.dialogRef.close();
    }
  }

  public closeClicked() {
    this.dialogRef.close();
  }
}
