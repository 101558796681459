<div fxLayout="column" class="w-full" *ngIf="data">
  <div fxLayout="column" *ngIf="!checkoutLinkData">
    <nxt-input placeholder="Name" [(value)]="data.name"></nxt-input>
    <nxt-input placeholder="Handy" [(value)]="data.mobile"></nxt-input>
    <!--<label>Text für Telegram</label>
    <nxt-textarea [(value)]="data.infoInternal"></nxt-textarea>-->
    <nxt-textarea placeholder="Text beim Zahlungsvorgang (sieht der Kunde)" [(value)]="data.infoForCustomer"></nxt-textarea>
    <nxt-textarea placeholder="WhatsApp Nachricht" [noPaddingBottom]="true" [(value)]="message"></nxt-textarea>
    <div class="flex flex-row justify-between mt-2">
      <nxt-button [smallButton]="true" (click)="translateTo('de')">DE</nxt-button>
      <nxt-button [smallButton]="true" (click)="translateTo('en')">EN</nxt-button>
      <nxt-button [smallButton]="true" (click)="translateTo('es')">ES</nxt-button>
      <nxt-button [smallButton]="true" (click)="translateTo('nl')">NL</nxt-button>
      <nxt-button [smallButton]="true" (click)="translateTo('fr')">FR</nxt-button>
    </div>
    <div class="pt-2">
      <nxt-input [isMoney]="true" [selectOnInit]="true" placeholder="Betrag" [(value)]="data.value" (valueChange)="valueChanged()"></nxt-input>
    </div>
  </div>
  <div fxLayout="column" *ngIf="checkoutLinkData">
    <nxt-input placeholder="Link" [value]="checkoutLinkData.link" [readonly]="true"></nxt-input>
  </div>
  <div fxLayout="row" class="w-full" fxLayoutAlign="space-around">
    <nxt-button *ngIf="!checkoutLinkData" (click)="dialogRef.close()">Schließen</nxt-button>
    <nxt-button *ngIf="!checkoutLinkData && buttonText" [disabled]="data.value <= 0 || !this.message || !data.infoForCustomer"
                (click)="sendMessage()">{{ buttonText }}
    </nxt-button>
    <!--<nxt-button *ngIf="checkoutLinkData" (click)="copyLink()">Link kopieren</nxt-button>-->
    <!--<nxt-button *ngIf="checkoutLinkData && data.mobile" (click)="sendLink()">Link per WhatsApp schicken</nxt-button>-->
  </div>
</div>
