@if (transaction(); as transaction) {
  <div class="flex flex-col w-full h-full nxt-page !p-0" style="border: 0 solid red">
    <div class="flex flex-row justify-between px-2">
      <nxt-button-icon (click)="showDebugClicked()">info</nxt-button-icon>
      @if (!dialogRef) {
        <nxt-button-icon matTooltip="Transaktionen anzeigen" routerLink="/bank-transactions">checklist</nxt-button-icon>
      }
      <nxt-button-icon matTooltip="Transaktionslink kopieren" (mouseup)="copyUrlClicked($event)">content_copy</nxt-button-icon>
      <nxt-button-icon matTooltip="RUN" (click)="runClicked()">sprint</nxt-button-icon>
      <nxt-button-icon matTooltip="Datev-Export" (click)="runDateExportClicked()">share_windows</nxt-button-icon>
      @if (dialogRef) {
        <nxt-button-icon (click)="closeClicked()">close</nxt-button-icon>
      }
    </div>
    <div class="flex flex-col flex-grow" style="border: 0 solid blue">
      <div class="flex flex-col items-center justify-start flex-grow" style="border: 0 solid white">
        <div class="flex flex-col items-center flex-grow" style="border: 0 solid green">
          <table class="nxt-table-new">
            <tr>
              <td class="text-center">Buchung</td>
              <td class="text-center">Erstellt</td>
              <td class="text-center">Betrag</td>
              <td class="text-center">Konto</td>
              <td class="text-center"></td>
              <td [nxtMultiClick]="3" (nxtOnMultiClick)="showSetArtistCustomerButton()" class="text-center">Gegenkonto</td>
              <td class="text-center">Gegenüber</td>
              <td class="text-center">Text</td>
              <td class="text-center">Zahlungsinfo</td>
            </tr>
            <tr>
              <!-- BUCHUNG -->
              <td>{{ transaction.bookDate | nxtDate: 'dd.MM.yyyy' }}</td>
              <!-- ERSTELLT -->
              <td>{{ transaction.bankCreatedAt | nxtDate: 'dd.MM.yyyy HH:mm' }}</td>
              <!-- BETRAG -->
              <td>
                {{ transaction.value > 0 ? '+' : '' }}{{ transaction.value | money2: transaction.currency }}
              </td>
              <!-- KONTO -->
              <td class="text-center">
                <div class="flex flex-col">
                  <div> {{ this.account().name }}</div>
                  @if (this.account().iban !== this.account().datevNo) {
                    <div class="text-[70%]">{{ this.account().iban }}</div>
                  }
                  <div class="text-[70%]">{{ this.account().datevNo }}</div>
                </div>
              </td>
              <!-- PFEIL -->
              <td>
                @if (transaction.direction === 'in') {
                  <mat-icon class="m-4 mat-icon-lg" fontSet="material-symbols-outlined">arrow_left_alt</mat-icon>
                } @else {
                  <mat-icon class="m-4 mat-icon-lg" fontSet="material-symbols-outlined">arrow_right_alt</mat-icon>
                }
              </td>
              <!-- GEGENKONTO -->
              <td [nxtMultiClick]="3" (nxtOnMultiClick)="setOtherCompanyClicked()">
                @if (otherAccount()) {
                  <div class="flex flex-col text-center">
                    <div>{{ otherAccount().name }}</div>
                    <div>{{ otherAccount().name2 }}</div>
                    <div class="text-[70%]">{{ otherAccount().datevNo }}</div>
                  </div>
                } @else {
                  <nxt-button (click)="setOtherCompanyClicked()">auswählen</nxt-button>
                }
              </td>
              <!-- GEGENÜBER -->
              <td>
                <div class="flex flex-col justify-center items-center text-center">
                  <div>{{ transaction.other.name }}</div>
                  @if (transaction.other.name2) {
                    <div>{{ transaction.other.name2 }}</div>
                  }
                  @if (transaction.other.iban) {
                    <div class="text-[70%]">{{ transaction.other.iban }}</div>
                  }
                </div>
              </td>
              <!-- TEXT -->
              <td>
                <div class="text-[80%]">{{ transaction.text }}</div>
              </td>
              <!-- Zahlungsinfo -->
              <td>
                <div class="flex flex-col justify-center text-center">
                  <div class="text-[70%]">{{ transaction.transactionText }}</div>
                  @if (transaction.transactionText !== transaction.method) {
                    <div class="text-[70%]">{{ transaction.method }}</div>
                  }
                </div>
              </td>
            </tr>
          </table>

          <div class="flex flex-row justify-between mt-2 gap-2">
            <nxt-button
              [class.background-green-dark]="transaction.status === NxtBankTransactionStatus._90_ok"
              [class.background-orange-dark]="transaction.status !== NxtBankTransactionStatus._90_ok"
              [smallButton]="true">Status: {{ transaction.status | nxtBankTransactionStatus }}
            </nxt-button>

            @if (transaction.type === NxtBankTransactionType.Unknown || forceShowSetArtistCustomerButton() || true) {
              <nxt-button (click)="setTransactionToCustomerDeposit()" [smallButton]="true">zu einer Kundenzahlung wandeln</nxt-button>
            }

            @if (showNeedInvoiceButton()) {
              <nxt-button [smallButton]="true" (click)="needInvoiceClicked()">{{ needInvoiceText() }}</nxt-button>
            }


          </div>

          @if (this.transaction.nxtManual?.missingInvoice) {
            <div class="flex flex-row items-center justify-center my-3">
              <div class="orange-div">✓ Rechnung nicht auffindbar 😕</div>
            </div>
          }

          @if (transaction.assignValue === 0) {
            <div class="flex flex-row items-center justify-center my-3">
              <div class="full-assigned">✓ Vollständig zugewiesen ✓</div>
            </div>
          }

          <div class="flex flex-col mt-2 justify-center items-center">
            @if (transaction.nxtNotes?.length > 0) {
              <table class="nxt-table-new my-2">
                @if (transaction.nxtNotes) {
                  <tr>
                    <td colspan="3" class="text-center">Notizen</td>
                  </tr>
                  @for (note of transaction.nxtNotes; track note.at) {
                    <tr (click)="noteClicked(note)">
                      <td>{{ note.at | nxtDate: 'dd.MM.yyyy HH:mm' }}</td>
                      <td>{{ note.by }}</td>
                      <td>{{ note.text }}</td>
                    </tr>
                  }
                }
              </table>
            }
            <nxt-button (click)="newNoteClicked()" [smallButton]="true">neue Notiz</nxt-button>


            <div></div>

          </div>



          @if (transaction.assignedDocuments.length > 0) {
            <div class="flex flex-row mt-8 gap-8">
              @for (document of documentsWithAssignValue(); track document.id) {
                <div class="flex flex-col items-center cursor-pointer" (click)="documentClicked(document)">
                  <img style="border-radius: 4px" [src]="document.driveFileId | nxtEventFile : true" alt=""/>
                  <div class="flex flex-col items-center curs">
                    <div class="text-[120%]">{{ document.documentDate | nxtDate: 'dd.MM.yyyy' }}</div>
                    @if (document.nxtAi.invoice.valueGross !== document.assignValue) {
                      <div class="flex flex-row items-baseline">
                        <div class="text-[150%] leading-tight">{{ document.assignValue | money2: document.nxtAi.invoice.currency }}</div>
                        <div class="leading-tight pl-2">von {{ document.nxtAi.invoice.valueGross | money2: document.nxtAi.invoice.currency }}</div>
                      </div>
                    } @else {
                      <div class="text-[150%] leading-tight">{{ document.nxtAi.invoice.valueGross | money2: document.nxtAi.invoice.currency }}</div>
                    }
                    <div class="text-[90%]">{{ document.documentNumber }}</div>
                  </div>
                </div>
              }
            </div>
          }


          @if (transaction.nxtAi?.needTransaction) {
            <div class="w-full h-[220px] mt-4">
              <nxt-bank-transaction-finder
                [initTransactions]="assignedTransactions()"
                [initDate]="transaction.bankCreatedAt"
                [initValue]="transaction.value * -1"
                mode="view"
                assignText="Zuweisung aufheben"
                assignMode="full"
                [notSelectableTransactionIds]="[]"
                [(selectedPossibleTransactions)]="selectedAssignedTransactions"
                (onAssignSelectedPossibleTransactionsClicked)="removeAssignedTransactionsClicked()"
                headerText="Zugewiesene Transaktionen"
                assignIcon="delete"
                [assignIconColor]="ColorTools.Red"
              />
            </div>
            @if (transaction.assignValue > 0) {
              <div class="h-full mt-4">
                <nxt-bank-transaction-finder
                  [initDate]="transaction.bankCreatedAt"
                  [initValue]="transaction.value * -1"
                  mode="search"
                  assignText="Zuweisen"
                  assignMode="rest"
                  [excludeTransactionIds]="[transaction.id]"
                  [notSelectableTransactionIds]="[]"
                  [(selectedPossibleTransactions)]="selectedPossibleTransactions"
                  (onAssignSelectedPossibleTransactionsClicked)="assignSelectedTransactions()"
                  [bankAccountTypes]="transferFinderBankAccountTypes()"
                  [accountIds]="transferFinderAccountIds()"
                  [transactionMethods]="transferFinderTransactionMethods()"
                  [transactionStatus]="[NxtBankTransactionStatus._05_transferMatch, NxtBankTransactionStatus._03_delayed]"
                  headerText="Mögliche Transaktionen"
                  [assignIconColor]="ColorTools.Green"
                  [otherCompanyName]="otherCompanyName()"
                  [onlyNeedTransaction]="true"
                />
              </div>
            }
          }
        </div>
      </div>
    </div>
  </div>
}
