import {NxtAvailableArtistDayExtendedArtistFreeTimeWindow} from '../../common-interfaces/nxt.available-artist-day.interface';

export class TimeWindowTools {
   static timeWindowToDateTimeToday(timeWindow: NxtAvailableArtistDayExtendedArtistFreeTimeWindow) {
      return {start: Date.now().clearTime() + timeWindow.startTime, end: Date.now().clearTime() + timeWindow.endTime};
   }

   static timeWindowToDateTimeYesterday(timeWindow: NxtAvailableArtistDayExtendedArtistFreeTimeWindow) {
      return {start: Date.now().dateAddDays(-1).clearTime() + timeWindow.startTime, end: Date.now().dateAddDays(-1).clearTime() + timeWindow.endTime};
   }

   static timeWindowToDateTime(dateString: string, timeWindow: NxtAvailableArtistDayExtendedArtistFreeTimeWindow) {
      return {start: dateString.dateParse().clearTime() + timeWindow.startTime, end: dateString.dateParse().clearTime() + timeWindow.endTime};
   }
}
