import {Component, OnInit, Optional} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {NxtCalendarEvent} from '../../common-interfaces/nxt.calendar-event.interface';
import {BodyPutService} from '../../services/body-put.service';
import {DialogService} from '../../services/dialog.service';
import {AppointmentConfirmationService} from '../../services/appointment-confirmation.service';
import {MobileTools} from '../../common-browser/helpers/mobile.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {LoginService} from '../../services/login.service';
import {NxtComponent} from '../nxt.component';
import {NxtNewEventData} from '../../services/calendar-event.service';
import {ObjectTools} from '../../common-browser/helpers/object.tools';
import {EventTools, NxtImproveStatus} from '../../common-browser/helpers/event.tools';
import {NxtContact} from '../../common-interfaces/nxt.contact.interface';
import {FromNowPipe} from '../../pipes/from-now.pipe';
import {MoneyPipe} from '../../pipes/money.pipe';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {MatTooltip} from '@angular/material/tooltip';
import {MatCell, MatColumnDef, MatHeaderCell, MatHeaderRow, MatRow, MatTable} from '@angular/material/table';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';

interface NxtCalendarEventEventsView extends NxtCalendarEvent {
  improveStatus: NxtImproveStatus;
}

@Component({
  selector: 'nxt-events-view',
  templateUrl: './events-view.component.html',
  styleUrls: ['./events-view.component.scss'],
  imports: [NgIf, FlexModule, NxtButtonComponent, NxtButtonIconComponent, MatTable, MatColumnDef, MatHeaderCell, MatCell, MatHeaderRow, MatRow, NgFor, MatTooltip, NgClass, ExtendedModule, SlideToggleComponent, NxtDatePipe, MoneyPipe, FromNowPipe, SafeHtmlPipe],
  standalone: true,
})
export class EventsViewComponent extends NxtComponent implements OnInit {

  constructor(
    @Optional() public dialogRef: MatDialogRef<EventsViewComponent>,
    private socketService: SocketService,
    private bodyPutService: BodyPutService,
    private dialogService: DialogService,
    private appointmentConfirmationService: AppointmentConfirmationService,
    private loginService: LoginService,
  ) {
    super();
    this.pushSocketSubscription = this.socketService.subscribeNew('calendarEventChanged', async (data) => {
      if (this.data.events.map(e => e.id).includes(data.record.id)) {
        this.load(this.contactId).then();
      }
    });
  }

  data: { contact: NxtContact, events: NxtCalendarEventEventsView[] };
  motiveTexts: { [eventId: string]: string[] } = {};
  totalPayed = 0;
  displayedColumns: string[] = ['event', 'status'];
  isFromCalendarEventEditComponent = false;
  contactId = '';
  appointmentConfirmations: { [eventId: string]: boolean } = {};
  sendAppointmentConfirmationsCount = 0;
  isBackoffice = this.loginService.isBackoffice();

  nxtOnDestroy(): void {
  }


  ngOnInit() {
    // this.load();
    setTimeout(() => {
      if (!this.contactId) {
        this.load('people/c3605986183168815524');
      }
    }, 1000);
  }

  async load(contactId: string) {
    this.contactId = contactId;
    this.data = await this.socketService.getEventsByContactId(this.contactId) as any;
    if (this.data.events) {
      this.data.events = this.data.events.sortNumber('start', true);
    }
    for (const event of this.data.events) {
      event.improveStatus = EventTools.getImproveStatus(event, this.data.events);
    }
    this.totalPayed = this.data.events.reduce((sum, e) => sum + (e.status === 'closed' ? e.paymentSum : 0), 0);
    this.data.events.forEach(event => {
      this.motiveTexts[event.id] = this.bodyPutService.getTextFromEvent(event);
    });
  }

  openEvent(event: NxtCalendarEvent) {
    if (this.isFromCalendarEventEditComponent) {
      this.dialogRef.close({eventId: event.id});
    } else {
      this.dialogService.showEvent(event.id, 'ArtistEventsViewComponent');
    }
  }

  calcShowAppointmentConfirmations() {
    this.sendAppointmentConfirmationsCount = 0;
    for (const key of Object.keys(this.appointmentConfirmations)) {
      if (this.appointmentConfirmations[key]) {
        this.sendAppointmentConfirmationsCount++;
      }
    }
  }

  clearShowAppointmentConfirmations() {
    this.sendAppointmentConfirmationsCount = 0;
    this.appointmentConfirmations = {};
  }


  async sendAppointmentConfirmations() {
    if (!MobileTools.isValid(this.data.contact.mobileFormatted)) {
      await this.dialogService.showOk(this.data.contact.fullName + ' hat keine gültige Handynummer: ' + this.data.contact.mobileFormatted);
      this.clearShowAppointmentConfirmations();
      return;
    }
    const button = await this.dialogService.showButtons<'en' | 'de'>('Welche Sprache', {
      buttons: [
        {text: 'Abbrechen', value: null}, {text: 'Deutsch senden', value: 'de'}, {text: 'Englisch senden', value: 'en'},
      ],
    });
    for (const key of Object.keys(this.appointmentConfirmations)) {
      if (this.appointmentConfirmations[key]) {
        const event = this.data.events.find(e => e.id === key);
        if (button.value) {
          const text = await this.appointmentConfirmationService.getConfirmationTextFromEvent(event, button.value, event.status === 'canceled');
          await this.socketService.sendWhatsAppMessage(this.data.contact.mobileFormatted, text);
        }
      }
    }
    this.clearShowAppointmentConfirmations();
  }

  sendEventInfoToCustomer() {
    this.dialogService.showOk('Was genau soll hier geschickt werden?');
  }

  setArtistFix(event: NxtCalendarEvent) {
    this.socketService.updateCalendarEventProperty(event.id, {artistFix: true});
    event.artistFix = true;
  }

  public async createImproveEvent(event: NxtCalendarEventEventsView) {
    if (event.status === 'canceled') {
      return;
    }
    if (event.workType === 'tattoo') {
      let info = 'Nachstechen vom ' + event.start.dateFormat('dd.MM.yyyy') + ' bei ' + event.artist;

      let isFree = event.improveStatus.status === 'free';
      if (event.improveStatus.status === 'check') {
        const bodyPutText = this.bodyPutService.getTextFromEvent(event).join('\noder\n');
        isFree = await this.dialogService.showYesNo(event.improveStatus.text + '\n\n' + bodyPutText, {yesText: 'Es ist kostenlos', noText: 'es ist kostenpflichtig'});
      }
      if (isFree) {
        info += ' (Kostenlos)';
      } else {
        info += ' (Kostenpflichtig)';
      }
      const bodyPutsTattoo = ObjectTools.clone(event.bodyPuts.tattoo);
      bodyPutsTattoo.forEach(b => b.motive = b.motive.replaceAll(' (nachstechen)', '') + ' (nachstechen)');

      const spots = await this.socketService.getArtistSpotsByArtist(event.artist, true);
      const spotsText: string[] = [];

      for (const spot of spots) {
        spotsText.push(spot.start.dateFormat('dd.MM.yyyy') + ' - ' + spot.end.dateFormat('dd.MM.yyyy'));
      }
      let additionalText = event.artist + ' hat kein Spot in der Zukunft';
      if (spotsText.length > 0) {
        additionalText = event.artist + ' hat folgende Spots:\n· ' + spotsText.join('\n· ');
      }
      const improveOriginalArtist = event.artist;
      await this.dialogService.showOk('Versuche ein Termin bei ' + event.artist + ' zu finden\n\n' + additionalText);
      let dateString = DateTools.formatNowDate();
      if (spots.length > 0) {
        dateString = spots[0].start.dateFormat('yyyy-MM-dd');
      }

      const newEventData: NxtNewEventData = {
        dateString,
        timeFromString: '11:00',
        showEventFinder: true,
        skill: event.skill,
        info,
        improveOriginalArtist,
        bodyPutsTattoo,
        priceEstimatedFrom: isFree ? 0 : NaN,
        customer: event.customer,
        improve: true,
      };
      if (this.isFromCalendarEventEditComponent) {
        this.dialogRef.close({newEventData});
      } else {
        this.dialogService.newEvent(newEventData);
      }
    }
  }
}
