<div fxLayout="column" *ngIf="record">
  <div class="flex flex-row justify-center items-center pt-3" fxLayout="row">
    <div fxLayout="column" *ngIf="record.type === 'gift-card'">
      <ng-container *ngIf="!record.assigned">
        <table class="nxt-table">
          <tr>
            <td colspan="2">
              <div class="flex flex-col items-center">
                <div class="flex flex-ro">
                  <div class="green">{{ record.value | money }} nicht zugewiesen</div>
                </div>
                <nxt-button [smallButton]="true" (click)="refundClicked(record)">Erstatten</nxt-button>
              </div>
            </td>
          </tr>
          <tr>
            <td>ID</td>
            <td>{{ record.id }}</td>
          </tr>
          <tr>
            <td>Wert</td>
            <td>{{ record.value | money }}</td>
          </tr>
          <tr>
            <td>Bezahlt</td>
            <td>{{ record.paymentValue | money }}</td>
          </tr>
        </table>
      </ng-container>
      <div fxLayout="column" *ngIf="record.assignments.length > 0">
        <div fxLayout="column" fxLayoutGap="10px">
          <div *ngFor="let assignment of record.assignments">
            <!--<div *ngIf="showOpenEvent && assignment.eventId" style="padding-bottom: 4px" fxLayout="row" fxLayoutAlign="center"
                 [innerHTML]="studioRegionBrowserTools.getLinkForEvent(assignment.eventId, {asLink: true, text: 'Termin öffnen'}) | safeHtml"></div>-->
            <table class="nxt-table">
              <tr>
                <td colspan="2">
                  <div class="flex flex-col items-center">
                    @if (record.refunded) {
                      <div class="red" *ngIf="record.refunded">{{ record.assignments[0].value | money }} Rückerstattet</div>
                    } @else {
                      <div>{{ record.assignments[0].value | money }} Zugewiesen</div>
                    }
                  </div>
                </td>
              </tr>
              <tr *ngIf="showOpenEvent && assignment.eventId">
                <td>Termin</td>
                <td>
                  <nxt-button [smallButton]="true" (click)="openEventClicked(assignment.eventId)">Termin öffnen
                  </nxt-button>
                </td>
              </tr>
              <tr *ngIf="assignment.event">
                <td>Kunde</td>
                <td>{{ assignment.event.customerObj.fullName }}</td>
              </tr>
              <tr *ngIf="!record.refunded">
                <td>Zugewiesen von</td>
                <td>{{ assignment.createdBy }}</td>
              </tr>
              <tr>
                <td *ngIf="record.refunded">Zurückerstattet am</td>
                <td *ngIf="!record.refunded">Zugewiesen am</td>
                <td>{{ assignment.assignedTime | nxtDate: 'dd.MM.yyyy HH:mm' }}</td>
              </tr>
              <tr *ngIf="false">
                <td *ngIf="record.refunded">Zurückerstattet von</td>
                <td *ngIf="!record.refunded">Zugewiesen von</td>
                <td>{{ assignment.createdBy }}</td>
              </tr>
              <tr>
                <td>Wert</td>
                <td>{{ assignment.value | money }}</td>
              </tr>
              <tr *ngIf="assignment.paymentValue">
                <td>Bezahl-Wert</td>
                <td>{{ assignment.paymentValue | money }}</td>
              </tr>
              <tr *ngIf="assignment.info">
                <td>Info</td>
                <td>{{ assignment.info }}</td>
              </tr>
              <tr *ngIf="record.refundInfo">
                <td>Rückerstattungsinfo</td>
                <td>{{ record.refundInfo }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
