import {Component, OnInit} from '@angular/core';
import {ContactFormComponent} from '../../contact-form/contact-form.component';
import {NxtContact} from 'src/app/common-interfaces/nxt.contact.interface';
import {NxtWhatsAppChatPinned} from '../../../common-interfaces/whats-app/nxt.whats-app-chat-pinned.interface';
import {NxtWebFormSubmit} from '../../../common-interfaces/nxt.web-form-submit.interface';
import {ClipboardService} from '../../../services/clipboard.service';
import {DialogService} from '../../../services/dialog.service';
import {SocketService} from '../../../services/socket/socket.service';
import {WebFormSubmitService} from '../../../services/web-form-submit.service';
import {LoginService} from '../../../services/login.service';
import {ContactService} from 'src/app/services/contact.service';
import {IframeMessageManagerInIframe, IframeParentCommand} from '../../../services/iframe-message-manager-in-iframe';
import {DateTools} from 'src/app/common-browser/helpers/date.tools';
import {WebFormSubmitStatus} from '../../web-form-submits/web-form-submits.component';
import {WhatsappChatComponent} from '../whatsapp-chat/whatsapp-chat.component';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {AddTaglineUnknownComponent} from './add-tagline-unknown/add-tagline-unknown.component';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {async, firstValueFrom} from 'rxjs';
import {TelegramService} from '../../../services/telegram.service';
import {KeyCode, ShortcutService} from '../../../services/shortcut.service';
import {WhatsappFastAnswersComponent} from '../whatsapp-fast-answers/whatsapp-fast-answers.component';
import {PaypalCheckoutService} from '../../../services/paypal-checkout.service';
import {NxtWhatsAppFastAnswer} from '../../../common-interfaces/nxt-whatsapp-text.interface';
import {LanguageTools} from '../../../common-browser/helpers/language.tools';
import {WhatsappImageSendComponent} from '../../whatsapp-image-send/whatsapp-image-send.component';
import {NxtWhatsAppImageSend} from '../../../common-interfaces/dynamic-data/dynamic-data.interface';
import {MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {WhatsappDialogService} from '../whatsapp-dialog.service';
import {CalcComponent} from '../../calc/calc.component';
import {NxtComponent} from '../../nxt.component';
import {TopBarService} from '../../../services/top-bar.service';
import {ReminderEditComponent} from '../../reminder/reminder-edit/reminder-edit.component';
import {WaContactCacheService} from '../../../services/contact/wa-contact-cache.service';


@Component({
  selector: 'nxt-whatsapp-extension',
  templateUrl: './whatsapp-extension.component.html',
  styleUrls: ['./whatsapp-extension.component.scss'],
  imports: [],
})
export class WhatsappExtensionComponent extends NxtComponent implements OnInit {

  constructor(
    private clipboardService: ClipboardService,
    private dialogService: DialogService,
    private socketService: SocketService,
    private topBarService: TopBarService,
    private webFormSubmitService: WebFormSubmitService,
    public loginService: LoginService,
    private contactService: ContactService,
    private telegramService: TelegramService,
    private shortcutService: ShortcutService,
    private paypalCheckoutService: PaypalCheckoutService,
    private whatsappDialogService: WhatsappDialogService,
    private waContactCacheService: WaContactCacheService,
  ) {
    super();
    setTimeout(() => {
      // this.showAddTaglineUnknownDialog('+49 151 20123232');
    }, 1000);
    this.shortcutService.onKeyPress.subscribe((key) => {
      this.onKeyPress(key);
    });
    this.waContactCacheService.start().then();
  }

  private whatsappFastAnswersCache: NxtWhatsAppFastAnswer[];
  private whatsappImageSendCache: NxtWhatsAppImageSend[];
  private priceDialogIsOpen = false;

  private editContactDialogRef: MatDialogRef<ContactFormComponent, MatDialogConfig>;
  // contactsWithEventsIndexed: any;
  currentWhatsAppChatsPinned: NxtWhatsAppChatPinned[];
  currentWebFormSubmitters: (NxtWebFormSubmit & {
    firstText: string;
    firstTextEmailSubject: string;
    dateTimeString: string;
  })[] = [];
  private whatsappFastAnswersComponentIsVisible = false;
  private currentWhatsAppContact: any;
  private currentWhatsAppMobile: any;

  nxtOnDestroy(): void {

  }

  async ngOnInit() {
    window.document.body.style.backgroundColor = 'transparent';
    this.registerWebFormSubmits();
    this.registerWhatsAppChatsPinned();
    this.whatsappFastAnswersCache = await this.socketService.getWhatsappFastAnswers();
    // this.whatsappImageSendCache = await this.socketService.getDynamicData<NxtWhatsAppImageSend>(NxtDynamicDataType.WhatsAppImageSend);
    IframeMessageManagerInIframe.instance.messageFromElectron.subscribe((data) => {
      if (data.type === 'setWhatsAppImage') {
        this.socketService.setWhatsAppImage(data.data);
      }
    });

    IframeMessageManagerInIframe.instance.addRequestListener('showAddContact', (data) => {
      this.socketService.addContactFromWhatsApp(data);
    });

    IframeMessageManagerInIframe.instance.addRequestListener('processWhatsAppMessage', (message) => {
      this.socketService.processWhatsAppMessage(message, 'parseContacts');
    });


    IframeMessageManagerInIframe.instance.addRequestListener('onKey', async (data: { key, contact, mobile }) => {
      this.onKeyPress(data.key);
    });

    IframeMessageManagerInIframe.instance.addRequestListener('setCurrentWhatsAppContact', async (currentWhatsAppContact) => {
      this.currentWhatsAppContact = currentWhatsAppContact;
    });

    IframeMessageManagerInIframe.instance.addRequestListener('beforeWhatsAppClientMessageSent', async (message) => {
      this.socketService.beforeWhatsAppClientMessageSent(message);
    });

    IframeMessageManagerInIframe.instance.addRequestListener('setCurrentWhatsAppMobile', async (currentWhatsAppMobile) => {
      this.currentWhatsAppMobile = currentWhatsAppMobile;
    });

    IframeMessageManagerInIframe.instance.addRequestListener('getCanceledEventsByContactId', async (contactId) => {
      const result = await this.socketService.getCanceledEventsByContactId(contactId);

      return result.map(e => {
        return {
          start: e.start,
          date: DateTools.format(e.start, 'dd.MM.yyyy'),
          eventId: e.id,
        };
      });
    });


    /*IframeMessageManagerInIframe.instance.addRequestListener('emitNewEventFromWhatsApp', async (data) => {
      const dateString = DateTools.format(DateTools.parseGermanWild(data.date), 'yyyy-MM-dd');
      this.socketService.emitNewEventFromWhatsApp(dateString, data.contactName);
    });*/

    IframeMessageManagerInIframe.instance.addRequestListener('usernameClicked', (url) => {
      IframeMessageManagerInIframe.instance.showIframe('usernameClicked', 'WhatsApp');
      this.loginService.reLogin(true);
    });

    IframeMessageManagerInIframe.instance.addRequestListener('calendarContextClicked', (url) => {
      this.telegramService.sendAdmin(this.loginService.getUsername() + ' hat im Kalender Rechts-Klick-Trick gestartet');
    });

    IframeMessageManagerInIframe.instance.addRequestListener('showWhatsAppSidebar', () => {
      this.showWhatsAppSidebar();
    });

    IframeMessageManagerInIframe.instance.addRequestListener('translate', async (data: { text: string, to: 'de' | 'en', from: string }) => {
      if (!data.to) {
        IframeMessageManagerInIframe.instance.showIframe('whats-app translateToClipboard', 'WhatsApp');
        data.to = await this.dialogService.showSelect('In welche Sprache?',
          LanguageTools.languageCodes.map(l => ({text: l.name, value: l.code})),
          {focusInputOnInit: true, closeOnOptionSelected: true, okButtonText: ''});
        IframeMessageManagerInIframe.instance.hideIframe('whats-app translateToClipboard');
      }
      if (data.to) {
        return await this.socketService.translate(data.text, data.to);
      }
    });

    IframeMessageManagerInIframe.instance.addRequestListener('translateToClipboard', async (data: { text: string, to: 'de' | 'en' }) => {
      const result = await this.socketService.translate(data.text, data.to);
      // IframeMessageManagerInIframe.instance.writeInWhatsAppChat(result.text);
      IframeMessageManagerInIframe.instance.showIframe('whats-app translateToClipboard', 'WhatsApp');
      await this.dialogService.showOk(result.text);
      this.clipboardService.copyToClipboard(result.text);
      IframeMessageManagerInIframe.instance.hideIframe('whats-app translateToClipboard');


      return result;
    });

    IframeMessageManagerInIframe.instance.addRequestListener('showEvents', async (contactId) => {
      IframeMessageManagerInIframe.instance.showIframe('whats-app showEvents', 'WhatsApp');
      const dialog = this.dialogService.showEventsByContact(contactId);
      await firstValueFrom(dialog.afterClosed());
      IframeMessageManagerInIframe.instance.hideIframe('whats-app showEvents');
    });

    IframeMessageManagerInIframe.instance.addRequestListener('webFormSubmitterClicked', async (customer) => {
      let text = customer.firstText;
      if (customer.isArtistMissingChat) {
        text = 'Hi ' + customer.familyName + '!\n' +
          'When you will work in our shop, you will get the tattoo pictures from this number.\n' +
          'See you soon :)\n' +
          'Dear NXT-LVL.INK Team';
      }
      // const textWithBr = text.replace(/\n/g, '<br/>');
      IframeMessageManagerInIframe.instance.writeInWhatsAppChat(text);
      IframeMessageManagerInIframe.instance.showIframe('whats-app webFormSubmitterClicked', 'WhatsApp');
      await this.dialogService.showOk(text);
      this.clipboardService.copyToClipboard(text);
      IframeMessageManagerInIframe.instance.hideIframe('whats-app webFormSubmitterClicked');
    });

    IframeMessageManagerInIframe.instance.addRequestListener('sendToWebFormSubmitter', async (customer) => {
      this.socketService.sendWhatsAppMessage(customer.mobile, customer.firstText);
    });

    IframeMessageManagerInIframe.instance.addRequestListener('topBarItemClicked', async (itemId: string) => {
      IframeMessageManagerInIframe.instance.showIframe('whats-app topBarItemClicked', 'WhatsApp');
      await this.topBarService.itemClicked(itemId);
      IframeMessageManagerInIframe.instance.hideIframe('whats-app topBarItemClicked');
    });

    IframeMessageManagerInIframe.instance.addRequestListener('topBarAddButtonClicked', async (itemId: string) => {
      IframeMessageManagerInIframe.instance.showIframe('whats-app topBarAddButtonClicked', 'WhatsApp');
      const dialog = this.dialogService.showComponentDialog(ReminderEditComponent);
      dialog.componentInstance.newReminder();
      await firstValueFrom(dialog.afterClosed());
      IframeMessageManagerInIframe.instance.hideIframe('whats-app topBarAddButtonClicked');
    });

    IframeMessageManagerInIframe.instance.addRequestListener('webFormSubmitterDoneClicked', async (webFormSubmitter: any) => {
      IframeMessageManagerInIframe.instance.showIframe('whats-app webFormSubmitterDoneClicked', 'WhatsApp');
      if (webFormSubmitter.isArtistMissingChat) {
        this.socketService.updateMissingArtistWhatsAppChat(webFormSubmitter.familyName);
      } else {
        if (await this.dialogService.showYesNo('Du hast ' + webFormSubmitter.givenName + ' angeschrieben?', {yesText: 'Ja, ich habe angeschreiben', noText: 'zurück'})) {
          this.socketService.updateWebFormSubmit({...webFormSubmitter, statusText: WebFormSubmitStatus.writtenTo});
        }
      }
      IframeMessageManagerInIframe.instance.hideIframe('whats-app webFormSubmitterDoneClicked');
    });


    IframeMessageManagerInIframe.instance.addRequestListener('getUsername', async () => {
      return {
        username: this.loginService.getUsername(),
        studio: this.loginService.getStudio(),
        color: '#FFF',
      };
    });


    IframeMessageManagerInIframe.instance.addRequestListener('getTaglinesFromUnknowns', async () => {
      return await this.socketService.getTaglinesFromUnknowns();
    });

    IframeMessageManagerInIframe.instance.addRequestListener('getOpenWebFormSubmits', async () => {
      return await this.socketService.getOpenWebFormSubmits();
    });

    IframeMessageManagerInIframe.instance.addRequestListener('showAddTaglineDialog', async (data) => {
      return await this.showAddTaglineDialog(data);
    });

    IframeMessageManagerInIframe.instance.addRequestListener('showAddTaglineUnknownDialog', async (data) => {
      return await this.showAddTaglineUnknownDialog(data);
    });

    IframeMessageManagerInIframe.instance.addRequestListener('showRemoveTaglineDialog', async (data) => {
      return await this.showRemoveTaglineDialog(data);
    });

    IframeMessageManagerInIframe.instance.addRequestListener('requestPaypalCheckout', async (data) => {
      IframeMessageManagerInIframe.instance.showIframe('whats-app showCreatePaypalPayment', 'WhatsApp');
      const text = await this.paypalCheckoutService.showCreatePaypalPayment({
        value: null,
        name: data.name,
        mobile: data.mobile,
        contactId: data.contact?.id,
        infoInternal: data.name,
      });
      /*if (text) {
        IframeMessageManagerInIframe.instance.writeInWhatsAppChat(text);
      }*/
      IframeMessageManagerInIframe.instance.hideIframe('whats-app showCreatePaypalPayment');
    });

    IframeMessageManagerInIframe.instance.addRequestListener('showWhatsAppChat', async (whatsAppId) => {
      IframeMessageManagerInIframe.instance.showIframe('whats-app showWhatsAppChat', 'WhatsApp');
      const dialog = this.dialogService.showComponentFull(WhatsappChatComponent);
      dialog.componentInstance.loadChat(whatsAppId);
      dialog.afterClosed().subscribe(() => {
        IframeMessageManagerInIframe.instance.hideIframe('whats-app showWhatsAppChat');
      });
    });

    IframeMessageManagerInIframe.instance.addRequestListener('setOtherChat', async (data: { waId?: string, contactId?: string }) => {
      this.socketService.fromWhatsAppSetOtherContact(data);
    });


    IframeMessageManagerInIframe.instance.addRequestListener('showWhatsAppChatContact', async (contactId) => {
      IframeMessageManagerInIframe.instance.showIframe('whats-app showWhatsAppChat', 'WhatsApp');
      const dialog = await this.contactService.showWhatsAppChat(contactId);
      dialog.afterClosed().subscribe(() => {
        IframeMessageManagerInIframe.instance.hideIframe('whats-app showWhatsAppChat');
      });
    });

    IframeMessageManagerInIframe.instance.addRequestListener('getContactsByMobile', async (mobile) => {
      return await this.socketService.getContactsByMobile(mobile);
    });

    IframeMessageManagerInIframe.instance.addRequestListener('editContact', async (customerName) => {
      IframeMessageManagerInIframe.instance.showIframe('whats-app editContact', 'WhatsApp');
      const contact = await this.getContactByWhatsAppCustomerName(customerName);
      if (contact) {
        const dialog = this.dialogService.showComponentDialog(ContactFormComponent);
        dialog.afterClosed().subscribe(() => {
          IframeMessageManagerInIframe.instance.hideIframe('whats-app editContact');
        });
        dialog.componentInstance.loadFormFromObject(contact);
      } else {
        await this.dialogService.showOk('Kunde nicht gefunden');
        IframeMessageManagerInIframe.instance.hideIframe('whats-app editContact');
      }
    });

    IframeMessageManagerInIframe.instance.addRequestListener('editContactId', async (contactId) => {
      IframeMessageManagerInIframe.instance.showIframe('whats-app editContactId', 'WhatsApp');
      const contact = await this.socketService.getContactById(contactId);
      if (contact) {
        const dialog = this.dialogService.showComponentDialog(ContactFormComponent);
        dialog.afterClosed().subscribe(() => {
          IframeMessageManagerInIframe.instance.hideIframe('whats-app editContact');
        });
        dialog.componentInstance.loadFormFromObject(contact);
      } else {
        await this.dialogService.showOk('Kunde nicht gefunden');
        IframeMessageManagerInIframe.instance.hideIframe('whats-app editContactId');
      }
    });

    IframeMessageManagerInIframe.instance.addRequestListener('setChatPinnedState', async (data: {
      mobileWithoutPlus: string,
      name: string,
      action: 'update' | 'pin',
      openPayPalCheckout: boolean
    }) => {
      const existing = this.currentWhatsAppChatsPinned.find(c => c.mobile === data.mobileWithoutPlus);
      IframeMessageManagerInIframe.instance.showIframe('whats-app setChatPinnedState', 'WhatsApp');
      await this.whatsappDialogService.setChatPinnedState(data);
      IframeMessageManagerInIframe.instance.hideIframe('whats-app showWhatsAppChat');
      /*if (data.mustWrite && data.pinned) {

        let result = await this.dialogService.showInput({
          message: 'Wann anschreiben?',
          prompt: '19:00',
          isTime: true,
          isTimeParams: {
            minTime: (Date.now() + DurationTools.DURATION_1DAY).dateFormat('HH') + ':00',
            maxTime: '19:00',
          },
          additionalButtons: [{text: 'in 4 Stunden', value: '{{4h}}'}],
        });
        if (typeof result === 'string') {
          if (result === '{{4h}}') {
            result = DateTools.roundToNextMinute((Date.now() + (DurationTools.DURATION_1HOUR * 4)), 15).dateFormat('yyyy-MM-dd HH:mm:ss');
          } else {
            result = DateTools.formatNow('yyyy-MM-dd') + ' ' + result + ':00';
          }
          data.mustWrite = DateTools.parse(result, 'yyyy-MM-dd HH:mm:ss');
          this.socketService.setWhatsAppChatPinnedState(data);
        }
        IframeMessageManagerInIframe.instance.hideIframe('whats-app setChatPinnedState');
      } else {
        IframeMessageManagerInIframe.instance.showIframe('whats-app setChatPinnedState', 'WhatsApp');
        if (data.pinned) {
          const result = await this.dialogService.showInput({
            title: data.info ? 'Info aktualisieren' : 'Anpinnen',
            message: data.name,
            okButtonText: data.info ? 'Aktualisieren' : 'Anpinnen',
            width: '40vw',
            stringCanEmpty: true,
            prompt: data.info,
            additionalButtons: [{text: 'Session', value: 'Session'}],
          });
          if (typeof result === 'string') {
            data.info = result;
            this.socketService.setWhatsAppChatPinnedState(data);
          }
        } else {
          const buttons: { text: string, value: any }[] = [];
          // buttons.push({text: '2 Minuten', value: DurationTools.DURATION_1MINUTE * 3});
          buttons.push({text: '3 Tage', value: DurationTools.DURATION_1DAY * 3});
          buttons.push({text: '7 Tage', value: DurationTools.DURATION_1DAY * 7});
          buttons.push({text: '14 Tage', value: DurationTools.DURATION_1DAY * 14});
          buttons.push({text: 'Lösen', value: -1});
          const result = await this.dialogService.showButtons('Chat lösen oder verschieben', {buttons, showCancelButton: true});
          if (result) {
            if (result.value === -1) {
              this.socketService.setWhatsAppChatPinnedState(data);
            } else {
              data.pinned = true;
              data.remindAgainAt = DateTools.clearTime(Date.now() + result.value);
              debugger;
              data.info = this.currentWhatsAppChatsPinned.find(c => c.mobile === data.mobile).info;
              data.createdAt = Date.now();
              data.info = await this.dialogService.showInput({message: 'Info für die Zukunft', prompt: data.info, stringCanEmpty: false});
              if (data.info) {
                this.socketService.setWhatsAppChatPinnedState(data);
              }
            }
          }
        }

      }*/
    });


    /*IframeMessageManagerInIframe.instance.addRequestListener('addTaglineToNxtContact', (data: { contactId: string, tagline: string }) => {
      this.socketService.addTaglineToNxtContact(data);
    });*/

    /*IframeMessageManagerInIframe.instance.addRequestListener('removeTaglineFromNxtContact', (data: { contactId: string, tagline: string }) => {
      this.socketService.removeTaglineFromNxtContact(data);
    });*/


    IframeMessageManagerInIframe.instance.addRequestListener('getContactInfoTemplateFn', () => {
      const fn = (oldEvents, newEvents, contact) => {
        let birthdayText = '';
        if (contact.birthday) {
          let nextBirthday = (new Date(contact.birthday)).setFullYear(new Date().getFullYear());
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          if (nextBirthday < today.getTime() - 1000 * 60 * 60 * 24 * 10) {
            nextBirthday = new Date(contact.birthday).setFullYear(new Date().getFullYear() + 1);
          }
          const days = (Math.floor((nextBirthday - Date.now()) / 1000 / 60 / 60 / 24) + 1);
          if (days < 0 && days < -2) {
            birthdayText = ' 🎂 vor ' + Math.abs(days) + ' Tagen';
          } else if (days === -2) {
            birthdayText = ' 🎂 VORGESTERN 🎂';
          } else if (days === -1) {
            birthdayText = ' 🎂 GESTERN 🎂';
          } else if (days === 0) {
            birthdayText = ' 🎉🎂 HEUTE 🎂🎉';
          } else if (days === 1) {
            birthdayText = ' 🎂 MORGEN 🎂';
          } else if (days <= 7) {
            birthdayText = ' 🎂 in ' + days + ' Tagen';
          } else {
            // birthdayText = days.toString();
          }
        }

        newEvents = newEvents.filter(e => e.start >= Date.now());
        const result: string[] = [];
        if (oldEvents.length > 2) {
          result.push('<div style="color:' + ColorTools.Red + '; display: inline-block" title="' + oldEvents.map(e => new Date(e.start).toLocaleDateString('de')).join(' · ') + '">&nbsp;&nbsp;' + oldEvents.length + '&nbsp;&nbsp;</div>');
        } else {
          result.push(oldEvents.map(e => '<div style="color:' + ColorTools.Red + '; display: inline-block">' + new Date(e.start).toLocaleDateString('de') + '</div>').join(' · '));
        }
        if (newEvents.length > 0) {
          result.push(newEvents.map(e => '<div style="color:green; display: inline-block">' + new Date(e.start).toLocaleDateString('de') + '</div>').join(' · '));
        }
        return '<div id="event-info" style="margin-left: 8px;font-size: 12px;">' + result.join(' · ') + ' ' + birthdayText + '</div>';
      };
      return fn.toString();
    });

    IframeMessageManagerInIframe.instance.addRequestListener('getContactMobileByWaIndex', (waIndex) => {
      return this.waContactCacheService.getByWaIndex(waIndex)?.mobile;
    });

    IframeMessageManagerInIframe.instance.addRequestListener('getContactListInfo', (name) => {
      const indexOrMobile = name.replaceAll(',', '').replaceAll(' ', '').replaceAll('+', '');
      const contact = this.waContactCacheService.getByWaIndex(indexOrMobile);
      const infoText: string[] = [];
      if (contact?.hasAppointmentToday) {
        infoText.push('⭐');
      }
      if (this.currentWhatsAppChatsPinned) {
        const contactIsPinned = this.currentWhatsAppChatsPinned.some(p => {
          const pinnedContactMobile = p.mobile.replaceAll(',', '').replaceAll(' ', '').replaceAll('+', '');
          const pinnedContactName = p.name.replaceAll(',', '').replaceAll(' ', '').replaceAll('+', '');
          return indexOrMobile === pinnedContactName || indexOrMobile === pinnedContactMobile;
        });
        if (contactIsPinned) {
          infoText.push('📌');
        }
      }
      return infoText.join('');
    });


    IframeMessageManagerInIframe.instance.addRequestListener('correctGrammar', async (text) => {
      const result = await this.socketService.correctGrammar(text);
      return result;
    });


    this.socketService.registerAsApp('WhatsApp');

    this.pushSubscription = this.waContactCacheService.contactsChanged.subscribe(() => {
      IframeMessageManagerInIframe.instance.send('contactsUpdated');
    });

    setTimeout(() => {
      IframeMessageManagerInIframe.instance.send('ng-nxtlvlink-loggedIn', true);
    }, 2000);


    IframeMessageManagerInIframe.instance.send('setUsername', {
      username: this.loginService.getUsername(),
      studio: this.loginService.getStudio(),
      color: '#FFF',
    });
  }

  async loadDialog(data: string) {
    if (data !== '0') {
      IframeMessageManagerInIframe.instance.showIframe('whats-app loadDialog', 'WhatsApp');
      this.editContactDialogRef = this.dialogService.showContactForm(data);
      const closed = await this.editContactDialogRef.afterClosed().toPromise();
      IframeMessageManagerInIframe.instance.hideIframe('whats-app loadDialog');
    }
  }

  async showAddTaglineDialog(contactId: string): Promise<string | undefined> {
    IframeMessageManagerInIframe.instance.showIframe('whats-app showAddTaglineDialog', 'WhatsApp');
    const result = await this.dialogService.showInputWhatsApp('Info hinzufügen', {showColorPicker: true, prompt: 'info zum Kunden erfassen'});
    IframeMessageManagerInIframe.instance.hideIframe('whats-app showAddTaglineDialog');

    let tagline = '';

    if (typeof result === 'string') {
      tagline = result;
    } else {
      const style = `background-color:${result?.color.backgroundColor};color:${result?.color.fontColor};padding: 2px 4px; border: none; border-radius: 3px; display: inline;`;
      if (result?.text) {
        tagline = `<button style="${style}">${result?.text}</button>`;
      }
    }
    if (tagline) {
      this.socketService.addTaglineToNxtContact({contactId, tagline});
    }
    return tagline;
  }

  async showAddTaglineUnknownDialog(mobileNumber: string): Promise<string | undefined> {
    IframeMessageManagerInIframe.instance.showIframe('whats-app showAddTaglineUnknownDialog', 'WhatsApp');
    const result = await this.dialogService.showComponentDialog(AddTaglineUnknownComponent, {mobileNumber}, {
      minWidth: '50vw',
    });
    await result.afterClosed().toPromise();
    IframeMessageManagerInIframe.instance.hideIframe('whats-app showAddTaglineUnknownDialog');
    return 'hat geklappt';
  }

  async showRemoveTaglineDialog(data: { contactId: string, tagline: string }): Promise<boolean> {
    IframeMessageManagerInIframe.instance.showIframe('whats-app showRemoveTaglineDialog', 'WhatsApp');
    const result = await this.dialogService.showYesNo(data.tagline + ' entfernen?');
    if (result) {
      this.socketService.removeTaglineFromNxtContact(data);
    }
    IframeMessageManagerInIframe.instance.hideIframe('whats-app showRemoveTaglineDialog');
    return result;
  }

  private showWhatsAppSidebar() {
  }

  private registerWhatsAppChatsPinned() {
    IframeMessageManagerInIframe.instance.addRequestListener('getWhatsAppChatsPinned', async () => {
      this.appendDateTimeStringToWhatsAppChatsPinned();
      return this.currentWhatsAppChatsPinned;
    });


    this.pushSocketSubscription = this.socketService.subscribeNew('getWhatsAppChatsPinned', (data) => {
      this.currentWhatsAppChatsPinned = data;
      this.appendDateTimeStringToWhatsAppChatsPinned();
      this.currentWhatsAppChatsPinned = data.sort(this.sortPinnedChats.bind(this));
      IframeMessageManagerInIframe.instance.send('setPinnedChats', this.currentWhatsAppChatsPinned);
    }, {emitInitial: true});

    this.topBarService.topBarItems.subscribe(topBarItems => {
      IframeMessageManagerInIframe.instance.send('getTopBarItems', topBarItems);
    });
  }

  appendDateTimeStringToWhatsAppChatsPinned() {
    this.currentWhatsAppChatsPinned?.forEach(d => {
      if (DateTools.format(d.createdAt, 'yyyy-MM-dd') === DateTools.formatNow('yyyy-MM-dd')) {
        (d as any).dateTimeString = DateTools.format(d.createdAt, 'HH:mm') + ' (' + DateTools.dateDiffToNowText(d.createdAt, true) + ')';
        (d as any).dateTimeStringColorClass = 'text-color';
      } else {
        (d as any).dateTimeString = DateTools.dateDiffToNowOnlyDaysText(d.createdAt) + ' um ' + DateTools.format(d.createdAt, 'HH:mm');
        (d as any).dateTimeStringColorClass = 'text-color-orange';
      }

      if (d.mustWrite) {
        if (d.mustWrite > Date.now()) {
          (d as any).mustWriteStringColorClass = 'text-color-green';
          if (d.mustWrite.dateFormat('yyyy-MM-dd') === DateTools.formatNow('yyyy-MM-dd')) {
            // heute zukunft
            (d as any).mustWriteString = 'Heute ab ' + d.mustWrite.dateFormat('HH:mm') + ' anschreiben';
          } else {
            // zukunft nach heute
            (d as any).mustWriteString = DateTools.dateDiffToNowText(d.mustWrite) + ' anschreiben';
          }
        } else {
          (d as any).mustWriteString = DateTools.dateDiffToNowText(d.mustWrite) + ' anschreiben';
          (d as any).mustWriteStringColorClass = 'text-color-red';
        }
      }

      if (d.remindAgainAt > Date.now()) {
        // (d as any).additionalInfo = 'In ' + DateTools.dateDiffToNowOnlyDaysText(d.remindAgainAt) + ' (' + d.remindAgainAt.dateFormat('dd.MM.yyyy HH:mm:ss') + ')';
        (d as any).additionalInfo = 'Anschreiben in<br/>' + DateTools.dateDiffToNowOnlyDaysText(d.remindAgainAt);
      }
    });
  }

  private registerWebFormSubmits() {
    IframeMessageManagerInIframe.instance.addRequestListener('getWebFormSubmitters', async () => {
      return this.currentWebFormSubmitters;
    });

    this.pushSocketSubscription = this.socketService.subscribeNew('getOpenWebFormSubmits', (data) => {
      this.setOpenWebFormSubmits(data);
    }, {emitInitial: true});

    setInterval(async () => {
      const data = await this.socketService.getOpenWebFormSubmits();
      if (data) {
        this.setOpenWebFormSubmits(data);
      }
    }, 1000 * 60 * 3);
  }

  public getCurrentWhatsAppChatTitle() {
    const currentChatTitle = IframeMessageManagerInIframe.instance.sendEval(IframeParentCommand.whatsApp_getCurrentChatTitle);
    return currentChatTitle;
  }

  private async getContactByWhatsAppCustomerName(customerName: any): Promise<NxtContact | undefined> {
    const nxtContacts: NxtContact[] = await this.socketService.findContactsWithEvents(customerName);
    const contact = nxtContacts.find(c => (c.fullName.trim() + ' ' + c.suffix.trim()).trim() === customerName.trim());
    return contact;
  }

  private async showWhatsappFastAnswers() {
    if (this.whatsappFastAnswersComponentIsVisible) {
      return;
    }
    this.whatsappFastAnswersComponentIsVisible = true;
    IframeMessageManagerInIframe.instance.showIframe('onKey', 'WhatsApp');
    const dialog = await this.dialogService.showComponentDialog(WhatsappFastAnswersComponent, {contact: this.currentWhatsAppContact, mobile: this.currentWhatsAppMobile});
    dialog.componentInstance.whatsappFastAnswers = this.whatsappFastAnswersCache;
    const result = await firstValueFrom(dialog.afterClosed());
    if (result?.whatsappFastAnswers) {
      this.whatsappFastAnswersCache = result.whatsappFastAnswers;
    }
    if (result?.text) {
      IframeMessageManagerInIframe.instance.writeInWhatsAppChat(result.text);
    }
    IframeMessageManagerInIframe.instance.hideIframe('onKey');
    this.whatsappFastAnswersComponentIsVisible = false;
    return 'ok';
  }

  private async showWhatsAppImageSend() {
    if (this.whatsappFastAnswersComponentIsVisible) {
      return;
    }
    this.whatsappFastAnswersComponentIsVisible = true;
    IframeMessageManagerInIframe.instance.showIframe('onKey showWhatsAppImageSend', 'WhatsApp');
    const dialog = await this.dialogService.showComponentDialog(WhatsappImageSendComponent, {contact: this.currentWhatsAppContact, mobile: this.currentWhatsAppMobile});
    dialog.componentInstance.images = this.whatsappImageSendCache;
    const result = await firstValueFrom(dialog.afterClosed());
    if (result?.images) {
      this.whatsappImageSendCache = result.images;
    }
    IframeMessageManagerInIframe.instance.hideIframe('onKey showWhatsAppImageSend');
    this.whatsappFastAnswersComponentIsVisible = false;
    return 'ok';
  }

  private sortPinnedChats(p1: NxtWhatsAppChatPinned, p2: NxtWhatsAppChatPinned) {
    const sortValue1 = p1.receivedPaypalCheckout ? p1.createdAt + DurationTools.DURATION_1YEAR : p1.createdAt;
    const sortValue2 = p2.receivedPaypalCheckout ? p2.createdAt + DurationTools.DURATION_1YEAR : p2.createdAt;
    return sortValue2 - sortValue1;
  }

  private setOpenWebFormSubmits(data: NxtWebFormSubmit[]) {
    (data as any).forEach(d => {
      d.dateTimeString = DateTools.format(d.createdAt, 'HH:mm');
      if (DateTools.format(d.createdAt, 'yyyy-MM-dd') !== DateTools.formatNow('yyyy-MM-dd')) {
        d.dateTimeString = '<div style="color:' + ColorTools.Red + ';">' + DateTools.format(d.createdAt, 'dd.MM HH:mm') + '</div>';
      }
      if (d.isArtistMissingChat) {
        d.dateTimeString = '<div style="color:' + ColorTools.Red + ';">ARTIST</div>';
      }
      d.firstText = this.webFormSubmitService.getFirstText(d);
      d.firstTextEmailSubject = this.webFormSubmitService.getFirstTextEmailSubject(d);
      if (d.contactVia === 'Telefon') {
        d.printUrl = window.location.origin + '/web-form-submit-print/' + d.id;
      }
    });
    this.currentWebFormSubmitters = (data as any).sort(SortTools.sortDate('createdAt'));
    IframeMessageManagerInIframe.instance.send('setWebFormSubmitters', this.currentWebFormSubmitters);
  }


  private async showPriceDialog() {
    if (this.priceDialogIsOpen) {
      return;
    }
    this.priceDialogIsOpen = true;
    IframeMessageManagerInIframe.instance.showIframe('showPriceDialog', 'WhatsApp');
    const dialog = this.whatsappDialogService.showPriceDialog();
    const result = await firstValueFrom(dialog.afterClosed());
    if (result) {
      IframeMessageManagerInIframe.instance.writeInWhatsAppChat(result);
    }
    IframeMessageManagerInIframe.instance.hideIframe('showPriceDialog');
    this.priceDialogIsOpen = false;
  }

  private async onKeyPress(key: KeyCode) {
    if (key === KeyCode.F8) {
      return this.showPriceDialog();
    } else if (key === KeyCode.F9) {
      this.showWhatsappFastAnswers();
    } else if (key === KeyCode.CtrlShiftB) {
      this.showWhatsappFastAnswers();
    } else if (key === KeyCode.F10) {
      this.showWhatsAppImageSend();
    } else if (key === KeyCode.AltP) {
      this.showCalc();
    }
  }

  async showCalc() {
    IframeMessageManagerInIframe.instance.showIframe('onKey showWhatsAppImageSend', 'WhatsApp');
    const dialog = this.dialogService.showComponentDialog(CalcComponent, {});
    await firstValueFrom(dialog.afterClosed());
    IframeMessageManagerInIframe.instance.hideIframe('onKey showWhatsAppImageSend');
  }
}
