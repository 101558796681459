import {TypeTools} from '../helpers/type.tools';
import {EventEmitter} from '../../common-browser-public/classes/event-emitter';


declare const window: any;

export class Log {
  public static onError = new EventEmitter<{ message: string, stack: string, shrinkStack: string, optionalParams: any; }>();
  public static onSocketError = new EventEmitter<string>();
  private static callerBlacklist = ['NXT.FORM-CONTROL'];
  static lastLogTimestamp = 0;
  private static colors: any = {
    unknown: 'background: #2a2a2a; color: #a2a2a2',
    'socket.service.ts': 'background: #222; color: #bada55',

    'app.component.ts': 'background-color: #2a2a2a; color: orange',
    'login.service.ts': 'background-color: #2a2a2a; color: white',
    'login.component.ts': 'background-color: #2a2a2a; color: yellow',
    'nxt.form-control.ts': 'background-color: #2a2a2a; color: gold',

    'auth-guard.ts': 'background:blue; color: orange',
    'iframe-message-manager-in-iframe.ts': 'background:green; color: black',

    'calendar-event-edit.component.ts': 'background:yellow; color: black',
    'payments.component.ts': 'background:yellow; color: blue',
    'input.component.ts': 'background:yellow; color: brown',
  };
  private static infoFn: any;
  private static debugFn: any;
  private static errorFn: any;


  private static async getStackTrace(stack: string, method: any): Promise<string[]> {
    return new Promise((resolve, reject) => {
      resolve(stack.split('\n'));
      if (method !== 'error') {
      }
      if ((window as any) && (window as any).sourceMappedStackTrace) {
        (window as any).sourceMappedStackTrace.mapStackTrace(stack, (mappedStack: any) => {
          resolve(mappedStack);
        }, {
          cacheGlobally: true, filter: (line: any) => {
            return line.indexOf('main.js') > -1;
          },
        });
      } else {
        resolve([stack]);
      }
    });
  }

  public static shrinkStack(stack: string[], maxLines: number, addBrackets = true): string[] {
    const newStack: string[] = [];
    stack.forEach((line: string) => {
      line = Log.modifyLine(line);
      if (line.length > 0) {
        if (addBrackets) {
          newStack.push('[' + line + ']');
        } else {
          newStack.push(line);
        }
      }
    });
    if (newStack.length > maxLines) {
      newStack.length = maxLines;
    }
    return newStack;
  }

  /*private getDateString(){
      const date = new Date();
      return
  }*/

  private static write(method: 'error' | 'info' | 'warn' | 'debug', message: any, optionalParams: any[]) {
    if (!optionalParams) {
      optionalParams = [];
    }
    let error: TypeError | any;
    if (method === 'error') {
      if (TypeTools.isArray(optionalParams) && optionalParams.length > 0 && optionalParams[0].name === 'FirebaseError' && optionalParams[0].message === 'Missing or insufficient permissions.') {
        return;
      }
      if (this.isError(message)) {
        error = message;
        console.error(error, optionalParams);
        Log.onError.emit({message: error.message, stack: '', shrinkStack: '', optionalParams});
      } else if (TypeTools.isArray(optionalParams)) {
        error = optionalParams.find(o => this.isError(o));
        optionalParams = optionalParams.filter(o => !this.isError(o));
        if (error) {
          console.error(message + '\n\n', error, ...optionalParams);
          if (error.isError || error.isNoError || error.isError === undefined) {
            Log.onError.emit(error);
          }
        } else {
          console.error(message + '\n\n', ...optionalParams);
          Log.onError.emit({message, stack: '', shrinkStack: '', optionalParams});
        }
      }
    } else {
      if (TypeTools.isArray(optionalParams)) {
        console[method].apply(console, [message, ...optionalParams]);
      } else {
        console[method].apply(console, message);
      }

    }
  }

  public static log(message: any, ...optionalParams: any) {
    return Log.write('info', message, optionalParams);
  }

  public static warn(message: any, ...optionalParams: any) {
    return Log.write('warn', message, optionalParams);
  }

  public static info(message: any, ...optionalParams: any) {
    return Log.write('info', message, optionalParams);
  }

  public static error(message: any, ...optionalParams: any[]) {
    return Log.write('error', message, optionalParams);
  }

  public static debug(message: any, ...optionalParams: any[]) {
    return Log.write('debug', message, optionalParams);
  }

  private static modifyLine(line: string) {
    line = line.replace('at Function.<anonymous>', '');
    line = line.replace('at Socket.<anonymous>', '');
    line = line.replace(')', '');
    line = line.replace('(', '');
    line = line.trim();
    line = line.replace('at Function.<anonymous>', '');
    // line = line.replace('webpack:///', 'webpack:///./');

    const match = line.match(/webpack:\/\/\/\.\/[^\]]+/);
    if (match) {
      line = match[0];
    }
    if (
      line.indexOf('.ts') === -1
      || line.indexOf('log.tools.ts') > -1
    ) {
      return '';
    }
    return line;
  }

  private static getSourceFileFromCaller(caller: any) {
    if (caller) {
      const match = caller.match(/[^\.\/]+\.([^\.]+\.)?ts/g);
      if (match) {
        return match[0];
      }
    }
    return '';
  }

  private static getColorFromCaller(caller: any) {
    let file: any = this.getSourceFileFromCaller(caller);
    if (!file || file.length === 0) {
      file = 'unknown';
    }
    if (this.colors[file]) {
      return this.colors[file];
    }

    console.error('no console-color for ' + file);
    return '';
  }


  private static isCallerInBlacklist(caller: string) {
    if (this.callerBlacklist.indexOf(caller) > -1) {
      return true;
    }
    return false;
  }

  static setLogFunctions(debug: any, info: any, error: any) {
    this.debugFn = debug;
    this.infoFn = info;
    this.errorFn = error;
  }

  private static isError(maybeError: any) {
    if (maybeError?.stack) {
      return true;
    }
    return maybeError?.constructor?.name?.includes('Error') || maybeError?.name?.includes('Error');
  }
}
