import {inject, Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {ConfigService} from './config.service';
import {NxtWorkplace} from '../common-interfaces/nxt.employee.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkplaceService {
  /*** Injections ***/
  private socketService = inject(SocketService);
  private configService = inject(ConfigService);

  get employeeWorkplaceOptions(): { value: NxtWorkplace, text: string }[] {
    if (this.configService.config.value.studioRegion === 'BR') {
      return [
        {value: 'cook', text: 'Koch'},
        {value: 'kitchenAssistant', text: 'Küchenhilfe'},
        {value: 'dishwasher', text: 'Spüler'},
        {value: 'bartender', text: 'Barkeeper'},
        {value: 'waiter', text: 'Kellner'},
        {value: 'runner', text: 'Runner'},
        {value: 'handyman', text: 'Handwerker'},
        {value: 'shisha', text: 'Shisha'},
        {value: 'fieldService', text: 'Außendienst'},
      ];
    }
    return [
      {value: 'backoffice', text: 'Backoffice'},
      {value: 'whatsapp', text: 'WhatsApp'},
      {value: 'whatsappFranchise', text: 'WhatsApp Franchise'},
      {value: 'eventRating', text: 'Terminbewertung'},
      {value: 'reception', text: 'Empfang'},
      {value: 'craft', text: 'Handwerk'},
      {value: 'piercing', text: 'Piercing'},
      {value: 'fieldService', text: 'Außendienst'},
    ];
  }


  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }


}
