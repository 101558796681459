<nxt-autocomplete
  [placeholder]="placeholder"
  [value]="autocompleteValue"
  (optionClick)="optionClicked($event)"
  [options]="countries"
  [displayWith]="displayWithCountry"
  [displayInOptionWith]="displayInOptionWithCountry"
  [maxOptionsToShow]="-1"
  [filterFields]="['name']"
  panelWidth="300px"
>
</nxt-autocomplete>
