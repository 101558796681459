<div class="nxt-page w-full">
  <div class="flex flex-row justify-between mb-2">
    <div *ngIf="!fromEvent" class="flex flex-col md:flex-row justify-center items-center gap-4">
      <nxt-radio
        direction="row"
        [(value)]="searchType"
        textKey="text"
        (valueChange)="searchTypeChanged($event)"
        [options]="searchTypes">
      </nxt-radio>
      <nxt-input (enter)="search()" class="md:min-w-[250px]" [clearIcon]="true" placeholder="Suche" [(value)]="searchText" (valueChange)="searchTextChanged()"
                 [noPadding]="true">
      </nxt-input>
      <nxt-button (click)="search()">Suchen</nxt-button>
      <nxt-button *ngIf="showCombineContacts()" (click)="combineContactsClicked()">Kontakte zusammenführen</nxt-button>

      <div nxtPermission="IsJulian" class="flex gap-2">
        <nxt-slide-toggle *ngIf="searchType.value === 'shop-order'" [value]="showDebug()" (valueChange)="showDebug.set($event)">Julian</nxt-slide-toggle>
        <nxt-button [smallButton]="true" (click)="testGiftCard('R-D495-85C8-7EEB')">R-D495-85C8-7EEB</nxt-button>
        <nxt-button [smallButton]="true" (click)="testShopOrder('70080')">70080</nxt-button>
        <!--<nxt-button (click)="testGiftCard('174c77d0-a46d-11eb-88ea-9782b1617453')">G1</nxt-button>-->
        <!--<nxt-button (click)="testGiftCard('174c77d0-a46d-11eb-88ea-9782b1617453')">G1</nxt-button>-->
        <!--<nxt-button [smallButton]="true" (click)="showContact('Sarah Honnef')">Sarah Honnef</nxt-button>-->
        <!--<nxt-button [smallButton]="true" (click)="testShopOrder('11678')">11678</nxt-button>

        <nxt-button [smallButton]="true" (click)="testShopOrder('11588')">2xTT PayPal</nxt-button>
        <nxt-button [smallButton]="true" (click)="showTattooTicketKlarna('7979')">TT Klarna</nxt-button>
        <nxt-button [smallButton]="true" (click)="testGiftCard('G-0100-97A1-50BF')">FFM G-0100-97A1-50BF</nxt-button>
        <nxt-button [smallButton]="true" (click)="testShopOrder('10266')">10266</nxt-button>
        <nxt-button [smallButton]="true" (click)="testShopOrder('30125')">30125</nxt-button>
        <nxt-button [smallButton]="true" (click)="testShopOrder('11559')">2xG PP</nxt-button>
        <nxt-button [smallButton]="true" (click)="testGiftCard('R-6DB9-9FB7-FF36')">R-6DB9-9FB7-FF36</nxt-button>-->
      </div>


      <div *ngIf="searchType.value === 'artists'" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2em">
        <nxt-slide-toggle [(value)]="artistInPast">Vergangenheit</nxt-slide-toggle>
        <div fxLayoutAlign="center center">
          <div class="mouse-pointer" (click)="selectDuration()">Dauer</div>&nbsp;&nbsp;
          <mat-slider
            fxFlex="250px"
            min="0.5"
            max="10"
            step="0.5"
          >
            <input matSliderThumb [(ngModel)]="duration" (valueChange)="durationChanged()">
          </mat-slider>
          &nbsp;&nbsp;
          <div>{{ duration * 3600000 | duration: 'HH:mm' }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="fromEvent"></div>
    <nxt-button-icon fxFlexAlign="start" *ngIf="dialogRef" (click)="dialogRef.close()">close</nxt-button-icon>
  </div>
  <div class="o-auto h-full text-90">
    <!-- SHOP-ORDER -->
    <div *ngIf="resultShopOrders()" class="flex flex-row justify-center pt-3 w-full">
      <div *ngFor="let shopOrder of resultShopOrders()">
        <nxt-shop-order-view
          [shopOrderId]="shopOrder.id"
          [showDebug]="showDebug()"
          (showEvent)="showEvent($event)"
          (showGiftCard)="showGiftCard($event)"
          (showTattooTicketKlarna)="showTattooTicketKlarna($event)"
          (showTattooTicketPaypal)="showTattooTicketPayPal($event)"
        ></nxt-shop-order-view>
      </div>
    </div>
    <!-- GIFT-CARD -->
    <div fxFlex *ngIf="resultGiftCard() && resultGiftCard().length > 0">
      <nxt-gift-card-view
        [showOpenEvent]="!fromEvent"
        [id]="resultGiftCard()[0].id"
        (onShowShopOrder)="showShopOrder($event)"
        [reload]="reload"></nxt-gift-card-view>
    </div>

    <div *ngIf="resultTattooTicketKlarna()" class="flex flex-row w-full justify-center pt-3">
      <nxt-tattoo-ticket-klarna
        (showEvent)="showEvent($event)"
        (showShopOrder)="showShopOrder($event)"
        [shopOrderLineItemId]="resultTattooTicketKlarna().shopOrderLineItemId"
      ></nxt-tattoo-ticket-klarna>
    </div>
    <div *ngIf="resultTattooTicketPaypal()" class="flex flex-row w-full justify-center pt-3">
      <nxt-tattoo-ticket-paypal
        (showEvent)="showEvent($event)"
        (showShopOrder)="showShopOrder($event)"
        [shopOrderLineItemId]="resultTattooTicketPaypal().shopOrderLineItemId"
      ></nxt-tattoo-ticket-paypal>
    </div>
    <!--<div class="flex flex-row justify-center" *ngIf="resultKlarnaOrder()">
      <nxt-klarna-order-raw
        [showShopOrder]="true"
        [showDebug]="showDebug()"
        [klarnaOrderId]="resultKlarnaOrder().klarnaOrderId"
        (onShowShopOrder)="showShopOrder($event)"
        (onShowGiftCard)="showGiftCard($event)"
      >
      </nxt-klarna-order-raw>
    </div>-->

    <!-- CUSTOMERS -->
    <div class="flex h-full" *ngIf="resultCustomers()">
      <nxt-datagrid
        class="h-full"
        [rowData]="resultCustomers()"
        [columnDefs]="customersColumnDefs"
        (selectionChanged)="contactSelectionChanged($event)"
        rowSelection="multiple"
      >
      </nxt-datagrid>
    </div>

    <!-- ARTISTS -->
    <div *ngIf="resultArtists()" fxLayout="column" class="results pt-4 h-100" style="overflow: auto; border: 0px solid red">
      <div *ngIf="resultArtists().length === 0" class="text-120" fxLayoutAlign="center center">
        Kein Artist gefunden :(
      </div>
      <div *ngIf="resultArtists().length > 0" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <div *ngFor="let artist of resultArtists()" class="w-full" fxLayout="column" fxLayoutAlign="center center">
          <table class="artists-table">
            <tr>
              <th colspan="7" style="text-transform: uppercase">
                {{ artist.artist }}
              </th>
            </tr>
            <tr>
              <th>Artist</th>
              <th>Von</th>
              <th>Bis</th>
              <th *ngIf="loginService.isBackoffice()" colspan="2">Text für Kunden</th>
              <th nxtPermission="Artists_NewSpot"></th>
              <th>Instagram</th>
            </tr>
            <tr *ngFor="let spot of artist.spots; let index = index">
              <td>{{ artist.artist }}</td>
              <td class="mouse-pointer"
                  (click)="scrollToId(spot.artist + '_' + spot.start.dateFormat('yyyy-MM-dd'))">{{ spot.start | nxtDate: 'EE dd.MM.yyyy' }}
              </td>
              <td class="mouse-pointer" (click)="scrollToId(spot.artist + '_' + spot.end.dateFormat('yyyy-MM-dd'))">{{ spot.end | nxtDate: 'EE dd.MM.yyyy' }}
              </td>
              <td *ngIf="loginService.isBackoffice()" style="text-align: center;">
                <div fxLayout="row" fxLayoutAlign="center">
                  <nxt-button-icon (click)="copyCustomerText(artist.artist, [spot])">content_copy</nxt-button-icon>
                </div>
              </td>
              <td *ngIf="loginService.isBackoffice() && index === 0 && artist.spots.length > 1" [rowSpan]="artist.spots.length" style="text-align: center;">
                <div fxLayout="row" fxLayoutAlign="center">
                  <nxt-button-icon (click)="copyCustomerText(artist.artist, artist.spots)">content_copy</nxt-button-icon>
                </div>
              </td>
              <td *ngIf="loginService.isBackoffice() && index === 0 && artist.spots.length === 1">
              </td>
              <td nxtPermission="Artists_NewSpot" class="center">
                <nxt-button-icon (click)="editArtistSpot(spot)">edit</nxt-button-icon>
              </td>
              <td class="center">
                <div class="flex flex-row justify-center items-center">
                  {{ artist.instagram }}&nbsp;&nbsp;
                  <nxt-button-icon (click)="copyInstaClicked(artist)">content_copy</nxt-button-icon>
                </div>
              </td>
            </tr>
          </table>
          <div fxLayout="column" fxLayoutAlign="center" style="overflow: hidden; width: 98%; border: 0px solid blue">
            <div fxLayoutAlign="center" fxLayout="row" class="py-2" *ngFor="let filter of filters; let last = last; let index = index">
              <!--<div *ngIf="filters.length > 1" style="padding-right: 1em" class="mouse-pointer" (click)="removeFilter(index)">
                <mat-icon>delete</mat-icon>
              </div>-->

              <mat-icon matTooltip="Kein Tag" class="mouse-pointer" *ngIf="filter.days.indexOf(false) === -1" (click)="selectAllDays(filter, false)">deselect
              </mat-icon>
              <mat-icon matTooltip="Alle Tag" class="mouse-pointer" *ngIf="filter.days.indexOf(false) > -1" (click)="selectAllDays(filter, true)">select_all
              </mat-icon>

              <div class="flex flex-row gap-4">
                <nxt-checkbox label="Mo." [(value)]="filter.days[0]" (valueChange)="showArtistsResult()"></nxt-checkbox>
                <nxt-checkbox label="Di." [(value)]="filter.days[1]" (valueChange)="showArtistsResult()"></nxt-checkbox>
                <nxt-checkbox label="Mi." [(value)]="filter.days[2]" (valueChange)="showArtistsResult()"></nxt-checkbox>
                <nxt-checkbox label="Do." [(value)]="filter.days[3]" (valueChange)="showArtistsResult()"></nxt-checkbox>
                <nxt-checkbox label="Fr." [(value)]="filter.days[4]" (valueChange)="showArtistsResult()"></nxt-checkbox>
                <nxt-checkbox label="Sa." [(value)]="filter.days[5]" (valueChange)="showArtistsResult()"></nxt-checkbox>
                <nxt-checkbox label="So." [(value)]="filter.days[6]" (valueChange)="showArtistsResult()"></nxt-checkbox>
              </div>
            </div>
            <div fxLayout="row" id="scroller" style="overflow: auto; margin: auto; max-width: 100%">
              <div *ngIf="artistEvents[artist.artist]?.days.length === 0" fxLayout="row" fxLayoutAlign="center">
                <div>{{ artist.artist }} ist nicht verfügbar</div>
              </div>
              <div fxLayout="row">
                <table class="artists-table" *ngIf="artistEvents[artist.artist]">
                  <tr>
                    <th *ngFor="let artistDay of artistEvents[artist.artist].days" [id]="artist.artist + '_' + artistDay.dateString">
                      {{ artistDay.date | nxtDate: 'EE dd.MM.yyyy' }}
                    </th>
                  </tr>
                  <tr>
                    <td *ngFor="let artistDay of artistEvents[artist.artist].days">
                      <div *ngFor="let freeTimeWindow of artistDay.artists[0].freeTimeWindows" class="no-wrap p-1 mouse-pointer" fxLayout="column"
                           fxLayoutAlign="center center">
                        <div (click)="freeTimeWindowClicked(artistDay, artist.artist, freeTimeWindow)">
                          {{ freeTimeWindow.startTime | duration: 'HH:mm' }} - {{ freeTimeWindow.endTime | duration: 'HH:mm' }}
                        </div>
                        <div class="pt-3">
                          <nxt-checkbox [value]="checkedTimeWindows[freeTimeWindow.id]"
                                        (valueChange)="checkTimeWindow(artist.artist, artistDay.date, freeTimeWindow, $event)"></nxt-checkbox>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="center" *ngIf="artistEvents[artist.artist]?.days.length > 0">
            <nxt-button-icon matTooltip="Alle Tage markieren" (click)="selectAllArtistDays(artist.artist)">select_all</nxt-button-icon>
            <nxt-button-icon *ngIf="this.checkedTimeWindowObj[artist.artist]?.length > 0" (click)="copyCustomerTimeWindowText(artist.artist)">content_copy
            </nxt-button-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
