import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal, ViewChild, ViewChildren} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {MatNestedTreeNode, MatTree, MatTreeNodeDef, MatTreeNodeOutlet, MatTreeNodeToggle} from '@angular/material/tree';
import {MatIcon} from '@angular/material/icon';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {DialogService} from '../../../services/dialog.service';
import {UuidTools} from '../../../common-browser/helpers/uuid.tools';
import {JsonFormattedPipe} from '../../../pipes/json-formatted-pipe';
import {TimeTools} from '../../../common-browser/helpers/time.tools';
import {BankTransactionLabel} from '../../../common-interfaces/bank/bank-transaction.interface';


@Component({
  selector: 'nxt-bank-transaction-labels',
  templateUrl: './bank-transaction-labels.component.html',
  styleUrls: ['./bank-transaction-labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatNestedTreeNode,
    MatTree,
    MatIcon,
    MatTreeNodeDef,
    MatTreeNodeToggle,
    MatTreeNodeOutlet,
    NxtButtonComponent,
    JsonFormattedPipe
  ],
  standalone: true
})

export class BankTransactionLabelsComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  @ViewChild(MatTree) matTreeComponent: MatTree<BankTransactionLabel, any>;


  constructor() {
    super();
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  private socketService = inject(SocketService);
  private cdRef = inject(ChangeDetectorRef);
  private dialogService = inject(DialogService);
  dataSource = signal([]);

  /*** Injections ***/

  /*** Variable ***/
  childrenAccessor = (node: BankTransactionLabel) => node.children ?? [];
  hasChild = (_: number, node: BankTransactionLabel) => !!node.children && node.children.length > 0;

  trackByFn(index: number, item: BankTransactionLabel) {
    function generateCompositeId(node: BankTransactionLabel): string {
      if (!node.children || node.children.length === 0) {
        return node.id;
      }
      return node.id + '-' + node.children.map(child => generateCompositeId(child)).join('-');
    }

    return generateCompositeId(item);
  }


  ngOnInit() {
    this.load().then();
  }

  async load() {
    this.dataSource.set(await this.socketService.bank.getTransactionLabels());
  }

  nxtOnDestroy() {
  }

  public async addChildClicked(event: MouseEvent, node?: BankTransactionLabel, treeNode?: MatNestedTreeNode<BankTransactionLabel>) {
    event.stopPropagation();
    event.preventDefault();
    console.log(treeNode);
    console.log(treeNode);
    const name = await this.dialogService.showInput('Name');
    if (name) {
      if (node) {
        const dataSourceNode = this.getNodeFromDataSource(node.id);
        if (!dataSourceNode.children) {
          dataSourceNode.children = [];
        }
        dataSourceNode.children.push({name, id: UuidTools.generate(), children: []});
        dataSourceNode.children = [...dataSourceNode.children];
        this.updateDataSource();
      } else {
        this.dataSource.update(dataSource => {
          dataSource.push({name, id: UuidTools.generate(), children: []});
          return [...dataSource];
        });
      }
      if (treeNode) {
        treeNode.expand();
      }
    }
    this.save();
  }

  getNodeFromDataSource(id: string): BankTransactionLabel {
    for (const node of this.dataSource()) {
      const result = this.getNodeById(id, node);
      if (result) {
        return result;
      }
    }
    return null;
  }

  private getNodeById(id: string, node: BankTransactionLabel): BankTransactionLabel {
    if (node.id === id) {
      return node;
    }
    if (node.children) {
      for (const child of node.children) {
        const result = this.getNodeById(id, child);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  public async test123(dataSource: BankTransactionLabel[]) {
    return;
    this.matTreeComponent.insertNode({name: 'TEST', id: UuidTools.generate(), children: [{name: 'ddd', id: UuidTools.generate(), children: []}]}, 1);
    return;
    console.log(dataSource);
    this.dataSource.set([]);
    await TimeTools.sleep(500);
    this.dataSource.update(dataSource => {
      dataSource.push({name: 'TEST', id: UuidTools.generate(), children: [{name: 'ddd', id: UuidTools.generate(), children: []}]});
      return [...dataSource];
    });
    this.cdRef.detectChanges();
    // this.matTreeComponent.
  }

  private save() {
    return this.socketService.bank.upsertTransactionLabels(this.dataSource());
  }

  public deleteAllClicked() {
    this.dataSource.set([]);
    this.save();
  }

  public editChildClicked($event: MouseEvent, node: BankTransactionLabel) {
    $event.stopPropagation();
    $event.preventDefault();
    this.dialogService.showInput('Name', {prompt: node.name}).then(name => {
      if (name) {
        node.name = name;
        this.updateDataSource();
        this.save();
      }
    });
  }

  private updateDataSource() {
    this.dataSource.update(dataSource => {
      return [...dataSource];
    });
  }
}
