import {MathTools} from './math.tools';
import {TypeTools} from './type.tools';
import * as  _ from 'lodash';

export class ArrayTools {

  public static removeFromArray(arr: any [], toRemove: any) {
    return arr.filter(item => item !== toRemove);
  }

  static unique<T>(array: T[]): T[] {
    return array.filter((item, index, arr) => arr.indexOf(item) === index);
  }

  static uniqueFn<T>(array: T[], fn: (item: T) => any): T[] {
    return _.unionBy(array, fn);
  }

  static checkSameString(stringArray1: string[], stringArray2: string[]) {
    if (stringArray1.length !== stringArray2.length) {
      return false;
    }
    for (const str of stringArray1) {
      if (stringArray2.indexOf(str) === -1) {
        return false;
      }
    }
    for (const str of stringArray2) {
      if (stringArray1.indexOf(str) === -1) {
        return false;
      }
    }
    return true;
  }

  /*static uniqueFn<T>(array: T[], getFn: (item: T) => any): T[] {
    const map: any[] = [];
    const result: T[] = [];
    for (const item of array) {
      const value = getFn(item);
      if (!map.includes(value)) {
        result.push(item);
        map.push(value);
      }
    }
    return result;
  }*/


  static checkSame(stringArray1: any[], stringArray2: any[]) {
    if (stringArray1.length !== stringArray2.length) {
      return false;
    }

    for (const item of stringArray1) {
      if (stringArray2.indexOf(item) === -1) {
        return false;
      }
    }
    return true;
  }

  static flatten<T>(arr: T[][]): T[] {
    return ([] as T[]).concat(...arr);
  }

  static sortRandomByIndicator(items: any[], indicatorField: string, indicator: string) {
    const from = Math.max(...items.map(i => TypeTools.isNumber(i._sortValue) ? i._sortValue : 0)) + 1;
    for (const [index, item] of items.entries()) {
      if (!TypeTools.isNumber(item._sortValue)) {
        item._sortValue = MathTools.randomFromBasedOn(item[indicatorField].toString() + '_' + indicator.toString(), from, 100);
      }
    }
    const result = items.sortNumber('_sortValue');
    // result.forEach(i => delete i._sortValue);
    return result;
  }

  static test() {
    const result = [1, 1, 2].unique();
  }

  static splitByMaxLength<T>(array: T[], maxLength: number): T[][] {
    const chunks: any[][] = [];
    for (let i = 0; i < array.length; i += maxLength) {
      const chunk = array.slice(i, i + maxLength);
      chunks.push(chunk);
    }
    return chunks;
  }

  static sum<T>(param: T[], key?: keyof T) {
    if (!key) {
      return param.reduce((sum, i) => sum + (i as number), 0);
    }
    return param.reduce((sum, i) => sum + (i as any)[key], 0);
  }
}


declare global {
  interface Array<T> {
    unique(getFn?: (item: T) => any): Array<T>;

    sum(key?: keyof T): number;
  }
}

/*Array.prototype.unique = function(this) {
    return ArrayTools.unique(this);
};*/

Object.defineProperty(Array.prototype, 'unique', {
  configurable: true,
  writable: true,
  value(this: any[], getFn: any) {
    if (getFn) {
      return ArrayTools.uniqueFn(this, getFn);
    }
    return ArrayTools.unique(this);
  },
});

Object.defineProperty(Array.prototype, 'sum', {
  configurable: true,
  writable: true,
  value(this: any[], key?: string) {
    return ArrayTools.sum(this, key);
  },
});


/*Object.defineProperty(Array.prototype, 'uniqueFn', {
  configurable: true,
  writable: true,
  value:  (this, getFn: any) => {
    return ArrayTools.uniqueFn(this, getFn);
  }
});*/


setTimeout(() => {
  const enumerableProperties: any = [];
  for (const property in []) {
    enumerableProperties.push(property);
  }
  if (enumerableProperties.length) {
    throw new Error('The `Array.prototype` contains unexpected enumerable properties: ' + enumerableProperties.join(', ') + '; thus breaking e.g. `for...in` iteration of `Array`s.');
  }
}, 2000);
