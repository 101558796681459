import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {PermissionService} from '../services/permission.service';
import {NxtPermissionId, PermissionId} from '../common-interfaces/nxt.user.interface';
import {Subscription} from 'rxjs';
import {Log} from '../common-browser/log/log.tools';


@Directive({
    selector: '[nxtPermission]',
    standalone: true
})
export class PermissionDirective implements OnInit, OnDestroy {
  @Input('nxtPermission') permission: PermissionId;
  @Input() permissionOr: PermissionId[];
  @Input() permissionNot: PermissionId;
  permissionUpdatedSubscription: Subscription;

  constructor(
    private element: ElementRef,
    private permissionService: PermissionService
  ) {
    this.permissionUpdatedSubscription = this.permissionService.onPermissionUpdated.subscribe(() => {
      this.setPermission();
    });
  }

  ngOnInit(): void {
    this.setPermission();
  }

  ngOnDestroy(): void {
    this.permissionUpdatedSubscription?.unsubscribe();
  }


  private setPermission() {
    if (this.permission || this.permissionOr || this.permissionNot) {
      if (!this.element.nativeElement.classList) {
        Log.error('Recht kann nicht gesetzt werden\n' + this.permission + '\n' + this.permissionOr + '\n' + this.permissionNot);
      } else {
        if (this.hasPermission()) {
          this.element.nativeElement.classList.remove('permission-display-none');
        } else {
          this.element.nativeElement.classList.add('permission-display-none');
        }
      }
    }
  }

  private hasPermission() {
    if (this.permissionOr?.length > 0) {
      for (const p of this.permissionOr) {
        if (this.permissionService.hasPermission(p as NxtPermissionId)) {
          return true;
        }
      }
      return false;
    }
    if (this.permissionNot) {
      return !this.permissionService.hasPermission(this.permissionNot as NxtPermissionId);
    }
    return this.permissionService.hasPermission(this.permission as NxtPermissionId);
  }


}
