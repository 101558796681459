<div class="w-full p-3 pt-6 wrapper">
  <div class="header-text">{{ headerText() }}</div>
  @for (formGroup of nxtFormControl().controls; track formGroup) {
    <div class="flex flex-row gap-3">
      <nxt-date-picker-2 [readonly]="added() ? !$last : true" (valueChange)="_formChanged()" [nxtFormControl]="formGroup.controls.startDateString"
                         [placeholder]="$last ? 'Ab' : 'Von'"></nxt-date-picker-2>
      @if (!$last) {
        <nxt-date-picker-2 [readonly]="true" (valueChange)="_formChanged()" [nxtFormControl]="formGroup.controls.endDateString"></nxt-date-picker-2>
      }
      @if (options()) {
        <nxt-select [disabled]="added() ? !$last : true" [multiple]="multiSelect()" [nxtFormControl]="formGroup.controls.value"
                    [options]="options()" [placeholder]="placeholder()"></nxt-select>
      } @else {
        <nxt-input [readonly]="added() ? !$last : true" [isNumber]="isNumber()" (valueChange)="_formChanged()" [nxtFormControl]="formGroup.controls.value"
                   [placeholder]="placeholder()"></nxt-input>
      }
      <nxt-button-icon nxtPermission="IsJulian" (click)="removeClicked($index)">remove</nxt-button-icon>
    </div>
  }
  <div class="flex flex-row w-full justify-center">
    <nxt-button-icon (click)="addClicked()">add</nxt-button-icon>
  </div>

</div>
