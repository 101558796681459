import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nxtSubstr',
  standalone: true
})
export class NxtSubstrPipe implements PipeTransform {

  constructor() {
  }

  transform(value: string, start: number, end?: number): any {
    try {
      if (value.length < end) {
        return value;
      }
      return value.substring(start, end);
    } catch (err) {
    }
  }
}
