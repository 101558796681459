<div clasS="nxt-page">
  <nxt-date-picker-2
    [nxtFormControl]="date2FormControl">
  </nxt-date-picker-2>





  value: {{ date2FormControl.value }}



  <nxt-date-picker-2 [(value)]="value2"></nxt-date-picker-2>
  value2: {{ value2 }}

  <button (click)="test()">createArtistConfirm</button>

  <nxt-input type="password" [nxtFormControl]="pwFormControl"></nxt-input>
</div>

