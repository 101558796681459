<div fxLayout="row" *ngIf="fullImage">
  <div fxLayout="column" class="h-100">

    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="column">
        <div style="text-align: center">
          <div>Breite: {{width ? width : '?'}}cm</div>
        </div>
        <div fxLayout="row">
          <div class="mouse-pointer" *ngFor="let step of widthSteps" (click)="setWidth(step)">&nbsp;{{step}}&nbsp;</div>
        </div>
      </div>

      <div fxLayout="column">
        <div style="text-align: center">
          <div>Höhe: {{height ? height : '?'}}cm</div>
        </div>
        <div fxLayout="row">
          <div class="mouse-pointer" *ngFor="let step of heightSteps" (click)="setHeight(step)">&nbsp;{{step}}&nbsp;</div>
        </div>
      </div>
    </div>
    <div fxLayout="row">
      <nxt-button>Benutzen</nxt-button>
    </div>
  </div>
  <div>
    <img id="img" class="img" [src]="'data:'+ fullImage.mimeType + ';base64,'+ fullImage.base64 | safeResourceUrl">
  </div>

</div>
