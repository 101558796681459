import {Component, OnInit, Optional} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtInventoryStockItemOrder} from '../../../../common-interfaces/nxt.inventory-item.interface';
import {InventoryService} from '../../../../services/inventory.service';
import {SocketService} from '../../../../services/socket/socket.service';
import {DialogService} from '../../../../services/dialog.service';
import {PermissionService} from '../../../../services/permission.service';
import {LoginService} from '../../../../services/login.service';
import {DurationTools} from '../../../../common-browser/helpers/duration.tools';
import {NxtPermissionId} from '../../../../common-interfaces/nxt.user.interface';
import {FromNowPipe} from '../../../../pipes/from-now.pipe';
import {MoneyPipe} from '../../../../pipes/money.pipe';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {MatIcon} from '@angular/material/icon';
import {NgFor, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtDatePipe} from '../../../../pipes/nxt-date-pipe';


@Component({
    selector: 'nxt-inventory-list-orders',
    templateUrl: './inventory-orders.component.html',
    styleUrls: ['./inventory-orders.component.scss'],
  imports: [FlexModule, NgFor, NgIf, MatIcon, NxtButtonComponent, MoneyPipe, FromNowPipe, NxtDatePipe]
})

export class InventoryOrdersComponent implements OnInit {

  item: any;
  onlyNotArrived: boolean;

  orders: NxtInventoryStockItemOrder[] = [];
  public title = '';
  showDeleteButton: { [orderId: string]: boolean } = {};

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
    private inventoryService: InventoryService,
    private socketService: SocketService,
    private dialogService: DialogService,
    private permissionService: PermissionService,
    private loginService: LoginService,
  ) {
  }

  ngOnInit() {
    if (this.onlyNotArrived) {
      this.orders = this.item.lastOrders.filter(o => !o.arrived);
      this.orders.map(o => {
        (o as any).sourceOfSupplyText = this.inventoryService.getSourceText(o.sourceOfSupply);
      });
      this.title = 'Offene Bestellungen ' + this.item.name;
    } else {
      this.orders = this.item.lastOrders.filter(o => o.arrived).sortNumber('arrivedAt', true);
      this.orders.map(o => {
        (o as any).sourceOfSupplyText = this.inventoryService.getSourceText(o.sourceOfSupply);
      });
      this.title = 'Angekommene Bestellungen ' + this.item.name;
    }
    this.orders.forEach(o => {
      const dateOk = (o.createdAt + DurationTools.DURATION_1DAY > Date.now());
      const userOk = o.createdBy === this.loginService.getUsername();
      const canDeleteAllOrders = this.permissionService.hasPermission(NxtPermissionId.Inventory_CanDeleteAllOrders);
      this.showDeleteButton[o.id] = (dateOk && userOk) || canDeleteAllOrders;
    });
    this.orders.filter(o => !o.shouldArrivedAt).forEach(o => {
      o.shouldArrivedAt = Date.now().dateAddDays(4);
    });
  }

  public async deleteOrder(order: any) {
    const text = 'Möchtest du die Bestellung' +
      '\n' + order.orderAmount + ' x ' + this.item.amountPerPackage + ' ' + this.item.unitOfMeasure + ' ' + this.item.name +
      '\nlöschen?';
    const result = await this.dialogService.showYesNo(text, {title: 'Bestellung löschen'});
    if (result) {
      await this.socketService.deleteInventoryOrder(order);
      this.dialogRef.close();
    }
  }

  changeShouldArrivedAt(order: NxtInventoryStockItemOrder, daysToAdd: number) {
    order.shouldArrivedAt = order.shouldArrivedAt.dateAddDays(daysToAdd);
    this.socketService.upsertInventoryOrder(order);
  }

  setNotArrivedOrder(order: NxtInventoryStockItemOrder) {
    order.arrived = false;
    order.arrivedAt = 0;
    this.socketService.upsertInventoryOrder(order);
    this.dialogRef.close();
  }
}
