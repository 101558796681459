import {AfterViewInit, Component, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DialogService} from '../../services/dialog.service';
import {faPaypal, IconDefinition} from '@fortawesome/free-brands-svg-icons';
import {faMoneyBillTransfer, faWandSparkles} from '@fortawesome/free-solid-svg-icons';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {MatIcon} from '@angular/material/icon';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgIf, NgStyle} from '@angular/common';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIconButton} from '@angular/material/button';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';


@Component({
  selector: 'nxt-button-icon',
  templateUrl: './nxt-button-icon.component.html',
  styleUrls: ['./nxt-button-icon.component.scss'],
  standalone: true,
  imports: [MatIconButton, MatTooltip, NgStyle, ExtendedModule, NgClass, NgIf, MatIcon, FaIconComponent, SafeHtmlPipe],
})
export class NxtButtonIconComponent implements OnInit, AfterViewInit, OnChanges {

  constructor(
    private dialogService: DialogService,
  ) {
  }

  buttonSize: number;

  @Input() backgroundColor: string;
  @Input() size = 28;
  @Input() color: string;
  // @Input() icon: string;
  @Input() tooltip: string;
  @Input() smallHeight = false;
  @Input() fa: 'paypal' | 'transfer' | 'sparkles' | '' = '';

  lastMouseDown = 0;
  @Input() onlyOneClick = false;
  faIcon: IconDefinition;
  @Input() svg: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size) {
      this.calcButtonSize();
    }
  }

  @HostListener('mousedown', ['$event'])
  public onMouseDown(event: MouseEvent): void {
    if (this.onlyOneClick) {
      const diff = Date.now() - this.lastMouseDown;
      if (diff < 500) {
        this.dialogService.showOk('Bei Icons brauchst du immer nur 1x klicken!', {buttonText: 'Ok, ich klick in Zukunft nur einmal'});
      }
      this.lastMouseDown = Date.now();
    }
  }

  ngOnInit() {
    if (this.fa === 'paypal') {
      this.faIcon = faPaypal;
    } else if (this.fa === 'transfer') {
      this.faIcon = faMoneyBillTransfer;
    } else if (this.fa === 'sparkles') {
      this.faIcon = faWandSparkles;
    }
    this.calcButtonSize();
  }

  ngAfterViewInit(): void {
  }

  private calcButtonSize() {
    this.buttonSize = this.size + 14;
  }
}
