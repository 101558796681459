import {Component, Optional} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {InputComponent} from '../form-controls/input/input.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-calc',
    templateUrl: './calc.component.html',
    styleUrls: ['./calc.component.scss'],
    imports: [FlexModule, InputComponent, SlideToggleComponent, NxtButtonComponent]
})
export class CalcComponent {

  priceStart: number = undefined;
  discountPercentage = 20;
  priceEnd = 0;
  fixPriceStart = false;
  fixPriceEnd = false;
  fixDiscountPercentage = true;


  constructor(
    @Optional() public dialogRef: MatDialogRef<CalcComponent>,
  ) {
  }


  fixPriceEndChanged() {
    if (this.fixPriceEnd) {
      this.fixPriceStart = false;
      this.fixDiscountPercentage = false;
    } else {
      requestAnimationFrame(() => this.fixPriceEnd = true);
    }
  }

  fixDiscountPercentageChanged() {
    if (this.fixDiscountPercentage) {
      this.fixPriceEnd = false;
      this.fixPriceStart = false;
    } else {
      requestAnimationFrame(() => this.fixDiscountPercentage = true);
    }
  }

  fixPriceStartChanged() {
    if (this.fixPriceStart) {
      this.fixPriceEnd = false;
      this.fixDiscountPercentage = false;
    } else {
      requestAnimationFrame(() => this.fixPriceStart = true);
    }
  }

  priceEndChanged() {
    requestAnimationFrame(() => {
      if (this.fixDiscountPercentage) {
        this.calcPriceStart();
      } else {
        this.calcDiscountPercentage();
      }
    });
  }

  priceStartChanged() {
    requestAnimationFrame(() => {
      if (this.fixDiscountPercentage) {
        this.calcPriceEnd();
      } else {
        this.calcDiscountPercentage();
      }
    });
  }

  discountPercentageChanged() {
    requestAnimationFrame(() => {
      if (this.fixPriceStart) {
        this.calcPriceEnd();
      } else {
        this.calcPriceStart();
      }
    });
  }

  calcPriceStart() {
    this.priceStart = this.priceEnd / ((100 - this.discountPercentage) / 100);
  }

  calcPriceEnd() {
    this.priceEnd = this.priceStart - (this.priceStart * this.discountPercentage / 100);
  }

  calcDiscountPercentage() {
    this.discountPercentage = Math.round(100 - (this.priceEnd / this.priceStart * 100));
    if (this.discountPercentage < 0) {
      this.discountPercentage = 0;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
