import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtInventoryItemPackage} from '../../../../common-interfaces/nxt.inventory-item.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {UuidTools} from '../../../../common-browser/helpers/uuid.tools';
import {NgIf} from '@angular/common';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {InputComponent} from '../../../form-controls/input/input.component';
import {TextareaComponent} from '../../../form-controls/textarea/textarea.component';
import {SelectComponent} from '../../../form-controls/select/select.component';

@Component({
    selector: 'nxt-inventory-package-edit',
    templateUrl: './inventory-package-edit.component.html',
    styleUrls: ['./inventory-package-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SelectComponent, TextareaComponent, InputComponent, NxtButtonComponent, NgIf]
})

export class InventoryPackageEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  package: NxtInventoryItemPackage;
  sourceOfSupplies: { text: string, value: string }[];

  constructor(
    private dialogRef: MatDialogRef<InventoryPackageEditComponent>,
    private cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {

  }

  nxtOnDestroy() {

  }

  load(itemPackage: NxtInventoryItemPackage, sourceOfSupplies: string[]) {
    this.package = itemPackage;
    this.sourceOfSupplies = sourceOfSupplies.map(s => ({text: s, value: s}));
  }

  saveClicked() {
    if (!this.package.name) {
      return;
    }
    if (!this.package.sourceOfSupply) {
      return;
    }
    if (!this.package.amount) {
      return;
    }
    if (!this.package.id) {
      this.package.id = UuidTools.generate();
    }
    this.dialogRef.close(this.package);
  }

  closeClicked() {
    this.dialogRef.close();
  }

  deleteClicked() {
    this.dialogRef.close({id: this.package.id, delete: true});
  }
}
