import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, signal, SimpleChanges} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtPaypalTransaction} from '../../../common-interfaces/nxt-paypal-transaction';
import {NxtShopOrder, NxtShopOrderLineItem} from '../../../common-interfaces/nxt.shop-order.interface';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {NxtPermissionId} from '../../../common-interfaces/nxt.user.interface';
import {TypeTools} from '../../../common-browser/helpers/type.tools';
import {PermissionService} from '../../../services/permission.service';
import {DialogService} from '../../../services/dialog.service';
import {PaypalRefundService} from '../../../services/refund/paypal.refund.service';
import {NgIf} from '@angular/common';
import {TattooTicketComponent} from '../tattoo-ticket.component';
import {MatDialogRef} from '@angular/material/dialog';
import {TattooTicketView} from '../abstract-tattoo-ticket/abstract-tattoo-ticket.component';
import {first} from 'rxjs';
import {toObservable} from '@angular/core/rxjs-interop';

@Component({
    selector: 'nxt-tattoo-ticket-paypal',
    templateUrl: './tattoo-ticket-paypal.component.html',
    styleUrls: ['./tattoo-ticket-paypal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NxtButtonComponent,
    NgIf,
    TattooTicketComponent
  ]
})

export class TattooTicketPaypalComponent extends NxtComponent implements OnInit, NxtOnDestroy, OnChanges, TattooTicketView {

  /*** Outputs ***/
  @Output() showShopOrder = new EventEmitter<string>();
  @Output() showEvent = new EventEmitter<string>();
  /*** Inputs ***/
  @Input({required: true}) shopOrderLineItemId = '';

  /*** Signals ***/
  shopOrder = signal<NxtShopOrder>(null);
  shopOrderLineItem = signal<NxtShopOrderLineItem>(null);
  firstParent = signal<NxtPaypalTransaction>(null);
  outTransactions = signal<NxtPaypalTransaction[]>([]);
  refundValue = signal(0);
  refundValue$ = toObservable(this.refundValue);
  mainWithChildrenTransactionsAndOuts = signal<NxtPaypalTransaction[]>([]);
  openValue = signal(0);
  hastOutTransactions = signal(false);
  allOrdersAssigned = signal(false);
  isDialog = false;
  fromEventId = '';

  constructor(
    @Optional() public dialogRef: MatDialogRef<TattooTicketPaypalComponent>,
    private socketService: SocketService,
    private permissionService: PermissionService,
    private dialogService: DialogService,
    private paypalRefundService: PaypalRefundService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shopOrderLineItemId) {
      this.load().then();
    }
  }

  ngOnInit() {
    this.isDialog = this.dialogRef?.componentInstance === this;
    this.pushSocketSubscription = this.socketService.subscribeNew('eventPaypalTransactionChanged', (paypalTransaction) => {
      if (paypalTransaction.shopOrderId === this.shopOrder().id) {
        this.load().then();
      }
    });
    this.pushSocketSubscription = this.socketService.subscribeNew('eventShopOrderUpdated', (shopOrder) => {
      if (this.firstParent().shopOrderId === shopOrder.id) {
        this.load().then();
      }
    });
  }

  nxtOnDestroy() {

  }

  async load() {
    const data = await this.socketService.getPayPalTattooTicket(this.shopOrderLineItemId);
    this.shopOrder.set(data.shopOrder);
    this.shopOrderLineItem.set(data.shopOrder.lineItems.find(l => l.id === this.shopOrderLineItemId));
    this.firstParent.set(data.main);
    const lastOutCount = this.outTransactions().length;
    this.outTransactions.set(data.out);
    this.refundValue.set(data.refundValue);
    this.mainWithChildrenTransactionsAndOuts.set([data.main, ...data.splitted, ...data.out]);
    this.openValue.set([data.main, ...data.splitted].filter(t => !t.assigned).reduce((sum, p) => sum + p.value, 0));
    this.hastOutTransactions.set(false);
    this.allOrdersAssigned.set(!this.mainWithChildrenTransactionsAndOuts().some(t => !t.assigned));
    if (this.outTransactions().length > lastOutCount) {
      this.dialogService.hideLoading();
    }
  }


  async refundClicked() {
    if (!this.permissionService.hasPermissionWithInfo(NxtPermissionId.CanRefund)) {
      return;
    }
    const maxRefund = this.firstParent().originalValue - this.refundValue();
    let message = '<table class="nxt-table w-full"><tr></tr>';
    message += '<tr><td>Bereits erstattet</td><td>' + this.refundValue().toMoneyString() + '</td>';
    message += '<tr><td>Maximaler Erstattungsbetrag</td><td>' + maxRefund.toMoneyString() + '</td>';
    message += '</table>';
    const title = 'Tattoo-Ticket erstatten';
    if (maxRefund === 0) {
      await this.dialogService.showOk(message, {title});
    } else {
      const valueToRefund = await this.dialogService.showInput(message, {
        title,
        isMoney: true,
        prompt: maxRefund,
        placeholder: 'Wie viel soll erstattet werden?',
        selectPrompt: true
      });
      if (TypeTools.isNumber(valueToRefund)) {
        if (valueToRefund > maxRefund) {
          await this.dialogService.showOk('Du kannst nur maximal ' + maxRefund.toMoneyString() + ' zurück zurückerstatten');
          return;
        }
        this.dialogService.showLoading('Erstattung wird durchgeführt');
        this.hideLoadingOnRefundReceived();
        await this.paypalRefundService.refundShopOrderItem(this.shopOrderLineItemId, valueToRefund, valueToRefund, 'Tattoo-Ticket erstatten', this.firstParent().id, 'paypal');
      }
    }
  }

  hideLoadingOnRefundReceived() {
    const currentRefundValue = this.refundValue();
    this.refundValue$.pipe(first((value) => value > currentRefundValue)).subscribe(() => {
      this.dialogService.hideLoading();
    });
  }
}
