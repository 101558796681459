import {Injectable} from '@angular/core';
import {SocketInterface} from '../common-interfaces/socket/socket-interface';
import {SocketService} from './socket/socket.service';

@Injectable({
    providedIn: 'root'
})
export class DayFinishService {

    constructor(
        private socketService: SocketService
    ) {
    }

    public async log(data: SocketInterface.DayFinishCreateLog) {
        return this.socketService.dayFinishCreateLog(data);
    }
}
