import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogService} from '../../services/dialog.service';
import {Router} from '@angular/router';
import {MenuButtons} from './app-menu-buttons';
import {KeyCode, ShortcutService} from '../../services/shortcut.service';
import {VersionService} from '../../services/version.service';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {MatIcon} from '@angular/material/icon';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NgFor, NgIf} from '@angular/common';

export interface MenuButton {
  text: string;
  // routerLink?: string;
  icon?: string;
  click?: () => void;
}

@Component({
    selector: 'nxt-app-menu',
    templateUrl: './app-menu.component.html',
    styleUrls: ['./app-menu.component.scss'],
    imports: [NgIf, NxtButtonIconComponent, MatMenuTrigger, MatIcon, MatMenu, NgFor, MatMenuItem]
})
export class AppMenuComponent implements OnInit {

  @ViewChild('menuTrigger', {static: true, read: MatMenuTrigger})
  menuTrigger: MatMenuTrigger;
  menuButtons: MenuButtons;

  buttons: MenuButton[];

  showLoading = false;
  private normalButtons = [];
  private debugButtons = [];
  private menuClickedCounter = 0;
  showMenuButton = false;
  version = '';


  constructor(
    private dialogService: DialogService,
    private router: Router,
    private shortcutService: ShortcutService,
    public versionService: VersionService
  ) {
    this.shortcutService.onKeyPress.subscribe((key: KeyCode) => {
      if (key === KeyCode.CtrlM) {
        this.showMenuButton = !this.showMenuButton;
      }
    });

    this.menuButtons = new MenuButtons(router, dialogService);

    this.initButtons();


    this.buttons = this.normalButtons;
  }

  ngOnInit(): void {
  }


  menuClicked() {
    this.menuClickedCounter++;
    setTimeout(() => this.menuClickedCounter = 0, 800);
    /*if (this.menuClickedCounter === 1) {
      this.removeDebugButtons();
    }*/
    if (this.menuClickedCounter === 3) {
      this.removeDebugButtons();
      this.addDebugButtons();
    }
    /*if (this.menuTrigger.menuOpen) {
      IframeMessageManagerInIframe.instance.sendEval();
    } else {
      IframeMessageManagerInIframe.instance.sendEval(IframeParentCommand.hideIframe);
    }*/
  }

  private addDebugButtons() {
    this.buttons = [...this.normalButtons, ...this.debugButtons];
  }

  private removeDebugButtons() {
    this.buttons = this.normalButtons;
  }

  private initButtons() {
    this.normalButtons = [
      this.menuButtons.bodyPuts,
      this.menuButtons.bodyPutsTranslate,
      this.menuButtons.markDublicateContacts,
      this.menuButtons.showDoubleContactsPhone,
      this.menuButtons.jsonData
    ];

    this.debugButtons = [
      this.menuButtons.cashToday,
      this.menuButtons.showDebugPage,
      // this.menuButtons.switchToTest,
      this.menuButtons.testEvent,
      this.menuButtons.toggleLoading,
      this.menuButtons.winningGame,
      this.menuButtons.newEvent
    ];
  }
}
