import {Component, OnInit} from '@angular/core';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {SocketService} from '../../../services/socket/socket.service';
import {DialogService} from '../../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {FirestoreService} from '../../../services/firestore.service';

export interface NxtInventoryHistoryEntry {
  oldAmount: number;
  newAmount: number;
  createdAt: Date;
  createdBy: string;
  id: string;
  studio: string;
}

@Component({
    selector: 'nxt-history-items',
    templateUrl: './inventory-history-items.component.html',
    styleUrls: ['./inventory-history-items.component.scss'],
    imports: [FlexModule, NxtButtonIconComponent, NxtDatagridComponent]
})
export class InventoryHistoryItemsComponent implements OnInit {

  // @Input() historyEntryType: NxtHistoryEntryType;
  // @Input() historyEntryId: string;


  columnDefs: NxtColDef[] = [];
  historyData: NxtInventoryHistoryEntry[];
  private dialog: MatDialogRef<InventoryHistoryItemsComponent, any>;

  private lastRowData: any;
  private dialogEventEdit;


  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private firestoreService: FirestoreService
  ) {
    this.columnDefs = [
      {headerName: 'Datum', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDateTimeWithDayOfWeek},
      {headerName: 'Benutzer', field: 'createdBy', nxtFieldType: NxtFieldType.Text},
      {headerName: 'Menge', field: '', cellRenderer: (params: any) => params.data.oldAmount + ' -> ' + params.data.newAmount},
    ];
  }
  artistCalendars: any[];

  async ngOnInit() {
  }

  public async loadData(inventoryId: string, studio: string) {
    const snapshot = await this.firestoreService.collection<NxtInventoryHistoryEntry>('/history/inventory-stock/' + inventoryId + '_' + studio, ref => ref.limit(20).orderBy('createdAt', 'desc')).get().toPromise();
    let result: NxtInventoryHistoryEntry[] = [];
    for (const doc of snapshot.docs) {
      result.push(doc.data() as NxtInventoryHistoryEntry);
    }
    result = result.sortNumber('createdAt', true);
    this.historyData = result;
  }

  setDialog(dialog: MatDialogRef<InventoryHistoryItemsComponent, any>) {
    this.dialog = dialog;
  }

  closeDialog() {
    if (this.dialogEventEdit) {
      this.dialogEventEdit.close();
    }
    this.dialog.close();
  }
}
