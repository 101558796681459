<div style="margin-bottom:24px; margin-top:10px;" [innerHTML]="data.message | safeHtml">
</div>
<div>
  <nxt-input [placeholder]="placeholder" [nxtFormControl]="inputFormControl"></nxt-input>
</div>
<div *ngIf="showColorPicker" style="justify-content: space-around; display:flex;">
  <button *ngFor="let color of colors" [ngStyle]="{backgroundColor: color.backgroundColor, color: color.fontColor}" style="margin: 10px;" mat-raised-button color="primary"
          (click)="close(color)">{{buttonText}}</button>
  <nxt-button (click)="cancel()">Abbrechen</nxt-button>
</div>


