import {ChangeDetectionStrategy, Component, computed, EventEmitter, inject, input, OnInit, Optional, Output} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtShopOrder} from '../../common-interfaces/nxt.shop-order.interface';
import {TattooTicketItemComponent} from './tattoo-ticket-item/tattoo-ticket-item.component';
import {MatIcon} from '@angular/material/icon';
import {MoneyPipe} from '../../pipes/money.pipe';
import {NgIf} from '@angular/common';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {FromNowPipe} from '../../pipes/from-now.pipe';
import {DialogService} from '../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';

export interface TattooTicketItem {
  assigned: boolean;
  assignments: {
    value: number;
    assignedTime: number;
    user?: string;
    createdBy?: string;
    eventId?: string;
    refType: string;
    refId?: string;
  }[];
  refundInfo?: string;
  refundReason?: string;
  direction: 'in' | 'out';
  value: number;
  originalValue: number;
  shopOrderLineItemId?: string;
}

@Component({
    selector: 'nxt-tattoo-ticket',
    templateUrl: './tattoo-ticket.component.html',
    styleUrls: ['./tattoo-ticket.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TattooTicketItemComponent,
    MatIcon,
    MoneyPipe,
    NgIf,
    NxtButtonComponent,
    NxtDatePipe,
    FromNowPipe
  ]
})

export class TattooTicketComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/
  tattooTicketItems = input.required<TattooTicketItem[]>();
  shopOrder = input.required<NxtShopOrder>();
  fromEventId = input<string>();
  isDialog = input.required<boolean>();

  /*** Outputs ***/
  @Output() doRefund = new EventEmitter<string>();
  @Output() showShopOrder = new EventEmitter<string>();
  @Output() showEvent = new EventEmitter<string>();

  /*** Signals ***/
  refundValue = computed(() => this.tattooTicketItems().filter(t => t.direction === 'out').reduce((acc, item) => acc + item.value, 0));
  openValue = computed(() => {
    let value = this.tattooTicketItems().filter(t => !t.assigned && t.direction === 'in').reduce((acc, item) => acc + item.value, 0)
      - this.tattooTicketItems().filter(t => !t.assigned && t.direction === 'out').reduce((acc, item) => acc + item.value, 0);
    if (value < 0) {
      value = 0;
    }
    return value;
  });
  originalValue = computed(() => this.tattooTicketItems().length > 0 ? this.tattooTicketItems()[0].originalValue : 0);
  allAssigned = computed(() => this.tattooTicketItems().every(item => item.assigned));
  buyerName = computed(() => this.shopOrder() ? (this.shopOrder().billingFirstName + ' ' + this.shopOrder().billingLastName) : '');


  /*** Injections ***/
  dialogService = inject(DialogService);

  constructor(
    @Optional() private dialogRef: MatDialogRef<any>
  ) {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  showShopOrderClicked(eventId: string) {
    if (this.isDialog()) {
      this.dialogService.showShopOrder.emit(eventId);
      this.dialogRef?.close();
    } else {
      this.showShopOrder.emit(eventId);
    }
  }

  showEventClicked(eventId: string) {
    if (this.isDialog()) {
      window.open('e/' + eventId, '_blank');
      this.dialogRef?.close();
    } else {
      this.showEvent.emit(eventId);
    }
  }
}
