import {Component, OnDestroy, OnInit} from '@angular/core';
import {NxtMoneyStack} from '../../common-interfaces/nxt.money-stack.interface';
import {ColorTools} from '../../common-browser/helpers/color.tools';
import {SocketService} from '../../services/socket/socket.service';
import {DialogService} from '../../services/dialog.service';
import {LoginService} from '../../services/login.service';
import {MatDialogRef} from '@angular/material/dialog';
import {CashMoneyCounterComponent} from '../day-finish-view/cash-money-counter/cash-money-counter.component';
import {MoneyStackTools} from '../../common-browser/helpers/money-stack.tools';
import {SortTools} from '../../common-browser/helpers/sort.tools';
import {clone, keys, ObjectTools} from '../../common-browser/helpers/object.tools';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {JsonTools} from '../../common-browser/helpers/json.tools';
import {MoneyPipe} from '../../pipes/money.pipe';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {PermissionDirective} from '../../directives/permission.directive';
import {MoneyCounter2Component} from '../../components/money-counter-2/money-counter-2.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgFor, NgIf, NgStyle} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

export interface CashMoneyCounter2Options {
  suppressValueChange?: boolean;
  title: string;
  moneyStack: NxtMoneyStack;
  totalMoneyShouldValue?: number;
  showShould?: boolean;
  canNotExitWithWrongShouldValue?: boolean;
  payoutData?: { values: { id: string; value: number }[]; prioIds: string[] };
  canOnlyUseChangeProposals?: boolean;
}

@Component({
    selector: 'nxt-money-counter-wrapper2',
    templateUrl: './money-counter-wrapper2.component.html',
    styleUrls: ['./money-counter-wrapper2.component.scss'],
    imports: [FlexModule, NgIf, NgClass, ExtendedModule, NgFor, NgStyle, MoneyCounter2Component, PermissionDirective, NxtButtonComponent, SafeHtmlPipe, MoneyPipe]
})
export class MoneyCounterWrapper2Component implements OnInit, OnDestroy {


  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    public loginService: LoginService,
    private dialogRef: MatDialogRef<CashMoneyCounterComponent>,
  ) {
  }

  options: CashMoneyCounter2Options;

  currentMoneyStack: NxtMoneyStack = {};

  colorTools = ColorTools;
  // public showShould = true;
  public mustCorrect = false;
  currentMoneyStackValue = 0;
  public suppressValueChange = false;
  public suppressValueChangeErrorMessage = 'Der IST-Wert darf sich nicht ändern';
  startValue = 0;
  // public reduceData: { values: { id: string; value: number }[]; otherMoneyStack: MoneyStack };
  moneyStackInfoText = '';

  keys = keys;

  // forceForce = false;
  changeProposals: { reduce: NxtMoneyStack; add: NxtMoneyStack, sortValue: number }[];
  stackBeforeUseProposal: NxtMoneyStack;
  canReduceResult: { values: { id: string; value: number; moneyStack?: NxtMoneyStack; missingMoney?: number }[]; possible: boolean; missingMoneySum: number };

  public keysSortNumber(obj: any) {
    return Object.keys(obj).map(k => parseFloat(k)).sort(SortTools.arrayNumbers(true));
  }

  async ngOnInit() {
  }

  ngOnDestroy(): void {

  }

  getKeys(moneyStack: NxtMoneyStack) {
    return Object.keys(moneyStack).map(key => parseFloat(key)).sort(SortTools.arrayNumbers());
  }

  setData(options: CashMoneyCounter2Options) {
    this.options = ObjectTools.combineWithDefaultOptions(options, {});
    if (options.moneyStack) {
      this.currentMoneyStack = ObjectTools.clone(options.moneyStack);
      this.startValue = MoneyStackTools.getTotalValueFromMoneyStack(this.currentMoneyStack);
    } else {
      this.currentMoneyStack = MoneyStackTools.getEmptyMoneyStack();
    }
    this.currentMoneyStackValue = MoneyStackTools.getTotalValueFromMoneyStack(this.currentMoneyStack);
    if (this.startValue > 0) {
      this.calcReduceData();
    }

    this.suppressValueChange = !!options.suppressValueChange;
  }

  async save() {
    if (this.options.payoutData) {
      this.calcReduceData();
      if (this.moneyStackInfoText) {
        return;
      }
    }
    if (this.suppressValueChange && this.startValue !== MoneyStackTools.getTotalValueFromMoneyStack(this.currentMoneyStack)) {
      await this.dialogService.showOk(this.suppressValueChangeErrorMessage + '\n' + this.startValue.toMoneyString());
      return;
    }

    if (this.options.canNotExitWithWrongShouldValue && this.options.totalMoneyShouldValue !== MoneyStackTools.getTotalValueFromMoneyStack(this.currentMoneyStack)) {
      await this.dialogService.showOk('Du kannst nur genau mit ' + this.options.totalMoneyShouldValue.toMoneyString() + ' beenden');
      return;
    }


    this.dialogRef.close(this.currentMoneyStack);
  }

  close() {
    this.dialogRef.close();
  }

  public moneyStackChanged(moneyStack: NxtMoneyStack, clearStackBeforeUseProposal = true) {
    this.currentMoneyStackValue = MoneyStackTools.getTotalValueFromMoneyStack(moneyStack);
    if (this.moneyStackInfoText) {
      this.calcReduceData();
    }
    if (clearStackBeforeUseProposal) {
      this.stackBeforeUseProposal = null;
    }
  }

  private calcReduceData() {
    const countedCash = MoneyStackTools.getTotalValueFromMoneyStack(this.currentMoneyStack);
    if (this.suppressValueChange) {
      // es mit startValue beendet werden
      if (this.startValue !== countedCash) {
        return;
      }
    }
    this.moneyStackInfoText = '';
    this.changeProposals = [];
    if (this.options.payoutData) {

      const mustHaveValue = MathTools.roundMoney(this.options.payoutData.values.reduce((sum, v) => sum + v.value, 0));
      if (mustHaveValue > countedCash) {
        this.moneyStackInfoText = 'Das reicht nicht zum Auszahlen,\nes müssen ' + (mustHaveValue - countedCash).toMoneyString() + ' eingelegt werden';
      } else {
        this.canReduceResult = MoneyStackTools.canReduceMultiFromMoneyStack(this.currentMoneyStack, this.options.payoutData.values, this.options.payoutData.prioIds);
        const infoLinesTrs: string[] = [];
        for (const value of this.canReduceResult.values) {
          if (value.missingMoney && value.missingMoney > 0) {
            infoLinesTrs.push('<tr><td class="p-1">' + value.id + '</td><td class="p-1">' + value.missingMoney.toMoneyString() + '</td></tr>');
          }
        }
        if (infoLinesTrs.length > 0) {
          const stackForMultiProposals = ObjectTools.clone(this.currentMoneyStack);
          this.changeProposals = MoneyStackTools.getProposalChangesMulti(stackForMultiProposals, this.options.payoutData.values.map(v => v.value));
          if (this.changeProposals.length > 5) {
            this.changeProposals.length = 5;
          }
          this.moneyStackInfoText = 'Leider kannst du so nicht auszahlen.❗';
          if (this.changeProposals.length === 0) {
            this.moneyStackInfoText += '<br/>Es fehlen dir:<br/><br/><table>' + infoLinesTrs.join('') + '</table>';
            this.socketService.sendTelegramAdmin('Keine Wechselvorschlag gefunden:\n\n' + JsonTools.stringify(stackForMultiProposals) + '\n\nsuche nach\n0x344' + '56455\n\n' + JsonTools.stringify(this.options.payoutData.values.map(v => v.value)));
          }
        }
      }
    }
  }

  useProposalClicked(changeProposal: { reduce: NxtMoneyStack; add: NxtMoneyStack }) {
    this.stackBeforeUseProposal = clone(this.currentMoneyStack);
    this.currentMoneyStack = MoneyStackTools.combineMoneyStacks([this.currentMoneyStack, changeProposal.add]);
    MoneyStackTools.reduceMoneyStackFromMoneyStack(this.currentMoneyStack, changeProposal.reduce);
    this.moneyStackChanged(this.currentMoneyStack, false);
  }

  undoUseProposalClicked() {
    this.currentMoneyStack = clone(this.stackBeforeUseProposal);
    this.moneyStackChanged(this.stackBeforeUseProposal);
    this.stackBeforeUseProposal = null;
    this.calcReduceData();
  }

  beforeAddOrRemoveMoney = async (coin: number) => {
    if (this.options.canOnlyUseChangeProposals && this.changeProposals.length > 0) {
      if (this.startValue === this.options.totalMoneyShouldValue) {
        this.dialogService.showOk('Du kannst nur Wechselvorschläge verwenden');
        return false;
      }
    }
    return true;
  }
}
