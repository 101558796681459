import {ChangeDetectorRef, Component, EventEmitter, Optional, signal, WritableSignal} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {DialogService, LoadingId} from '../../services/dialog.service';
import {ClipboardService} from '../../services/clipboard.service';
import {NxtEventFinderData} from '../../common-interfaces/socket/nxt.event-finder.interface';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {NxtArtistSpot, NxtAvailableArtistDayExtended, NxtAvailableArtistDayExtendedArtistFreeTimeWindow} from '../../common-interfaces/nxt.available-artist-day.interface';
import {CalendarEventService} from '../../services/calendar-event.service';
import {firstValueFrom} from 'rxjs';
import {LoginService} from '../../services/login.service';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {ConfigService} from '../../services/config.service';
import {EventFinderFilter} from '../../components/event-finder/event-finder.component';
import {IconTools} from '../../common-browser/helpers/icon.tools';
import {NxtPaymentPossibilityRecord} from '../../common-interfaces/nxt.payment-possibility-record.interface';
import {PermissionService} from '../../services/permission.service';
import {NxtPermissionId} from '../../common-interfaces/nxt.user.interface';
import {TimeTools} from '../../common-browser/helpers/time.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtContact} from '../../common-interfaces/nxt.contact.interface';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {SearchTools} from '../../common-browser/helpers/search.tools';
import {NxtComponent} from '../../components/nxt.component';
import {NxtKlarnaOrder} from '../../common-interfaces/nxt.klarna-order.interface';
import {NxtShopOrder} from '../../common-interfaces/nxt.shop-order.interface';
import {ArtistSpotEditComponent} from '../../components/artist-spot-edit/artist-spot-edit.component';
import {DurationPipe} from '../../pipes/duration.pipe';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {CheckboxComponent} from '../../components/form-controls/checkbox/checkbox.component';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon} from '@angular/material/icon';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {ShopOrderViewComponent} from '../../components/shop-orders/shop-order-view.component';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {FormsModule} from '@angular/forms';
import {MatSlider, MatSliderThumb} from '@angular/material/slider';
import {SlideToggleComponent} from '../../components/form-controls/slide-toggle/slide-toggle.component';
import {PermissionDirective} from '../../directives/permission.directive';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {InputComponent} from '../../components/form-controls/input/input.component';
import {RadioComponent} from '../../components/form-controls/radio/radio.component';
import {NgFor, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {GiftCardViewComponent} from '../../components/gift-card-view/gift-card-view.component';
import {TattooTicketKlarnaComponent} from '../../components/tattoo-ticket/tattoo-ticket-klarna/tattoo-ticket-klarna.component';
import {NxtPaypalTransaction} from '../../common-interfaces/nxt-paypal-transaction';
import {TattooTicketPaypalComponent} from '../../components/tattoo-ticket/tattoo-ticket-paypal/tattoo-ticket-paypal.component';
import {ActivatedRoute} from '@angular/router';
import {CellClickedEvent, SelectionChangedEvent} from 'ag-grid-community';
import {Clipboard} from '@angular/cdk/clipboard';


@Component({
  selector: 'nxt-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  imports: [FlexModule, NgIf, RadioComponent, InputComponent, NxtButtonComponent, PermissionDirective, SlideToggleComponent, MatSlider, MatSliderThumb, FormsModule, NxtButtonIconComponent, NgFor, ShopOrderViewComponent, NxtDatagridComponent, MatIcon, MatTooltip, CheckboxComponent, NxtDatePipe, DurationPipe, GiftCardViewComponent, TattooTicketKlarnaComponent, TattooTicketPaypalComponent]
})
export class SearchComponent extends NxtComponent {
  showCombineContacts = signal(false);
  private selectedRows: NxtContact[];

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private clipboardService: ClipboardService,
    private calendarEventService: CalendarEventService,
    @Optional() public dialogRef: MatDialogRef<SearchComponent>,
    private configService: ConfigService,
    public loginService: LoginService,
    private permissionService: PermissionService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private clipboard: Clipboard,
  ) {
    super();
    this.searchText = '';
    if (this.permissionService.isJulian()) {

    }

    this.route.queryParams.subscribe(params => {
      if (params.search && params.searchType) {
        this.searchType = this.searchTypes.find(s => s.value === params.searchType);
        this.searchText = params.search;
        this.search();
      }
    });


    this.reload.subscribe(() => {
      this.search();
    });
    setTimeout(() => {

    }, 400);
  }

  public fromEvent = false;

  artistInPast = false;

  public reload = new EventEmitter<void>();

  filters: EventFinderFilter[] = [
    {startTime: 11, studioEndTime: 20, days: [true, true, true, true, true, true, true]},
  ];
  searchTypes = [
    {text: 'Kunden', value: 'customers'},
    {text: 'Artists', value: 'artists'},
    {text: 'Gutschein', value: 'gift-card'},
    {text: 'Shop', value: 'shop-order'},
    {text: 'Klarna TT', value: 'tattoo-ticket-klarna'},
    {text: 'PayPal TT', value: 'tattoo-ticket-paypal'},
  ];
  searchText = '';
  searchType = this.searchTypes[0];
  resultArtists: WritableSignal<{ artist: string, spots: NxtArtistSpot[], instagram: string }[] | null> = signal(null);
  resultCustomers?: WritableSignal<NxtContact[] | null> = signal(null);
  // resultKlarnaOrder?: WritableSignal<NxtKlarnaOrder | null> = signal(null);
  resultGiftCard?: WritableSignal<NxtPaymentPossibilityRecord[]> = signal([]);
  resultTattooTicketKlarna?: WritableSignal<NxtKlarnaOrder | null> = signal(null);
  resultTattooTicketPaypal?: WritableSignal<NxtPaypalTransaction | null> = signal(null);
  resultShopOrders?: WritableSignal<(NxtShopOrder | null)[]> = signal(null);
  duration = 4;
  artistEvents: { [artist: string]: NxtEventFinderData } = {};
  checkedTimeWindows: { [timeWindowId: string]: boolean } = {};
  checkedTimeWindowObj: { [artist: string]: (NxtAvailableArtistDayExtendedArtistFreeTimeWindow & { date: number })[] } = {};
  customersColumnDefs: NxtColDef[] = [
    {checkboxSelection: true, maxWidth: 40, cellStyle: {textAlign: 'center'}},
    {
      nxtOnCellClicked: async (params) => {
        const dialog = this.dialogService.showContactForm(params.data);
        const result = await firstValueFrom(dialog.afterClosed());
        if (result) {
          setTimeout(() => this.search(), 3000);
        }
      },
      cellRenderer: (params: any) => IconTools.Material.Edit,
      cellStyle: {textAlign: 'center'},
      getQuickFilterText: () => '',
    }, {
      nxtOnCellClicked: async (params) => {
        if (await this.dialogService.showYesNo('Kunde wirklich löschen?')) {
          await this.deleteContact(params.data.id);
        }
      },
      hide: !this.permissionService.hasPermission(NxtPermissionId.ContactsDelete),
      cellRenderer: (params: any) => IconTools.Material.Delete,
      cellStyle: {textAlign: 'center'},
      getQuickFilterText: () => '',
    },
    {
      nxtFieldType: NxtFieldType.ShowContactHistory,
      cellRenderer: (params: any) => IconTools.Material.History,
      cellStyle: {textAlign: 'center'},
      getQuickFilterText: () => '',
    },
    {
      headerName: 'Name', field: 'fullName', nxtFieldType: NxtFieldType.Text, cellRenderer: (params: any) => {
        return SearchTools.highlightHtml(params.value, this.searchText);
      },
    },
    {
      headerName: 'Geburtstag', field: 'birthday', nxtFieldType: NxtFieldType.Date_germanDate, cellRenderer: (params: any) => {
        return SearchTools.highlightHtml(params.valueFormatted, this.searchText);
      },
    },
    {headerName: 'Sprache', field: 'lang', nxtFieldType: NxtFieldType.Text},
    {
      headerName: 'Handy', field: 'mobileFormatted', nxtFieldType: NxtFieldType.Text, cellRenderer: (params: any) => {
        return SearchTools.highlightHtml(params.value, this.searchText);
      },
    },
    {
      headerName: 'alte Handy', field: 'oldMobilesFormatted', valueFormatter: (params) => {
        return params.value?.map(m => m.m).join(', ');
      },
    },
    {
      headerName: 'Chat', field: 'mobileFormatted', nxtFieldType: NxtFieldType.WhatsAppChat,
      valueGetter: (params) => params.data,
      nxtFieldTypeParams: (params) => params.data.fullName + ' (' + params.data.mobileFormatted + ')',
      cellStyle: {textAlign: 'center'},
    },
    {
      headerName: 'abgesagte Termine', field: 'canceledEvents',
      nxtFieldType: NxtFieldType.CalendarEvents,
      minWidth: 60,
      getQuickFilterText: (params) => '',
    },
    {
      headerName: 'fertige Termine', field: 'closedEvents',
      nxtFieldType: NxtFieldType.CalendarEvents,
      minWidth: 60,
      getQuickFilterText: (params) => '',
    }, {
      headerName: 'offene Termine', field: 'events',
      nxtFieldType: NxtFieldType.CalendarEvents, minWidth: 60,
      getQuickFilterText: (params) => '',
    },
  ];
  showDebug = signal(false);

  nxtOnDestroy(): void {
  }

  searchTypeChanged(ev: any) {
    this.clearResults();
  }

  searchTextChanged() {
  }

  async search() {
    this.searchText = this.searchText.trim();
    this.clearResults();
    if (this.searchType.value === 'gift-card') {
      if (this.searchText.length < 8) {
        this.dialogService.showOk('Mindestens 8 Zeichen');
        return;
      }
    }

    if (this.searchText.length === 0) {
      this.dialogService.showOk('Bitte geben ein Suchbegriff ein');
      return;
    }
    const results = await this.socketService.search({
      searchType: this.searchType.value,
      searchText: this.searchText,
      maxResults: 35,
      options: {
        artistInPast: this.artistInPast,
      },
    });
    this.resultArtists.set(results.artists);
    this.resultCustomers.set(results.customers);
    this.resultTattooTicketKlarna.set(results.klarnaTattooTicket);
    this.resultTattooTicketPaypal.set(results.paypalTattooTicket);
    // this.resultKlarnaOrder.set(results.klarnaOrder);
    this.resultGiftCard.set(results.giftCard);
    this.resultShopOrders.set(results.shopOrders);
    this.showArtistsResult();
    this.calcResults();
    this.cdRef.detectChanges();
  }

  durationChanged() {
    this.search();
  }

  selectDuration() {
  }

  copyCustomerText(artist: string, spots: NxtArtistSpot[]) {
    const lines: string[] = [];
    for (const spot of spots) {
      lines.push('vom ' + spot.start.dateFormat('EE dd.MM.yyyy') + ' bis zum ' + spot.end.dateFormat('EE dd.MM.yyyy'));
    }
    const text = artist + ' ist wie folgt bei uns:\n' + lines.join('\nund\n');
    this.dialogService.showOk(text);
    this.clipboardService.copyToClipboard(text);
  }

  async freeTimeWindowClicked(day: NxtAvailableArtistDayExtended, artist: string, freeTimeWindow: NxtAvailableArtistDayExtendedArtistFreeTimeWindow) {
    const event = {
      dateString: day.dateString,
      timeFromString: DurationTools.format(freeTimeWindow.startTime, 'HH:mm'),
      timeTillString: DurationTools.format(freeTimeWindow.startTime + (this.duration * DurationTools.DURATION_1HOUR), 'HH:mm'),
      studio: day.artists[0].studio, // artist.studio
      artist,
    };
    const dialog = await this.calendarEventService.showNewEvent(event);
    await firstValueFrom(dialog.afterClosed());
    this.dialogRef.close();
  }


  scrollToId(id: string) {
    document.querySelectorAll('#' + id).forEach(e => {
      e.scrollIntoView();
      e.classList.add('animate__animated');
      e.classList.add('animate__flash');
      setTimeout(() => {
        e.classList.remove('animate__animated');
        e.classList.remove('animate__flash');
      }, 1000);
      requestAnimationFrame(() => {
        const scroller = this.getScroller(e);
        const bounding = e.getBoundingClientRect();
        const clientMiddleX = (scroller.getBoundingClientRect().width / 2 + scroller.getBoundingClientRect().x) - (bounding.width / 2);
        const toScrollAgain = bounding.x - clientMiddleX;
        scroller.scrollBy(toScrollAgain, 0);
      });
    });
  }

  private getScroller(elem: any): HTMLDivElement {
    if (elem.parentNode) {
      if ((elem.parentNode as any)?.id === 'scroller') {
        return elem.parentNode;
      }
      return this.getScroller(elem.parentNode);
    }
  }

  checkTimeWindow(artist: string, date: number, freeTimeWindow: NxtAvailableArtistDayExtendedArtistFreeTimeWindow, checked: boolean) {
    if (!this.checkedTimeWindowObj[artist]) {
      this.checkedTimeWindowObj[artist] = [];
    }
    if (checked) {
      this.checkedTimeWindowObj[artist].push({...freeTimeWindow, date});
    } else {
      this.checkedTimeWindowObj[artist] = this.checkedTimeWindowObj[artist].filter(t => t.id !== freeTimeWindow.id);
    }
  }

  copyCustomerTimeWindowText(artist: string) {
    const lines: string[] = [];
    for (const freeTimeWindow of this.checkedTimeWindowObj[artist]) {
      this.checkedTimeWindows[freeTimeWindow.id] = false;
      lines.push('am ' + freeTimeWindow.date.dateFormat('EE dd.MM.yyyy') + ' um ' + DurationTools.format(freeTimeWindow.startTime, 'HH:mm') + ' Uhr');
    }
    let text = 'Folgende Termine kann ich dir bei ' + artist + ' anbieten:\n' + lines.join('\noder\n');
    if (lines.length === 1) {
      text = 'Folgenden Termin kann ich dir bei ' + artist + ' anbieten:\n' + lines.join('\noder\n');
    }

    this.dialogService.showOk(text);
    this.clipboardService.copyToClipboard(text);

    this.checkedTimeWindowObj[artist] = [];
  }

  async showArtistsResult() {
    this.resetAllDayCheckboxes();
    if (!this.resultArtists()) {
      return;
    }
    for (const artist of this.resultArtists()) {
      const artistDays = await this.socketService.eventFinder({
        artist: artist.artist,
        duration: this.duration * 60 * 60 * 1000,
        fromDateString: DateTools.formatNow('yyyy-MM-dd'),
        tillDateString: Date.now().dateAddYears(3).dateFormat('yyyy-MM-dd'),
        boolSkillFilter: [],
        filters: this.filters,
        skillFilter: [],
        fillMissingDays: false,
      });
      artistDays.days = artistDays.days.filter(d => d.artists[0].freeTimeWindows.length > 0);
      this.artistEvents[artist.artist] = artistDays;
    }
  }


  selectAllDays(filter: EventFinderFilter, select: boolean) {
    for (let i = 0; i < filter.days.length; i++) {
      filter.days[i] = select;
    }
    this.showArtistsResult();
  }

  async deleteContact(contactId: string) {
    await this.socketService.deleteContact(contactId);
    this.dialogService.showLoading(LoadingId.DeleteContact, 'Kunde wird gelöscht...');
    await TimeTools.sleep(5000);
    this.dialogService.hideLoading(LoadingId.DeleteContact);
    this.search();
  }

  public selectAllArtistDays(artist: string) {
    let allWasChecked = true;
    for (const day of this.artistEvents[artist].days) {
      for (const timeWindow of day.artists[0].freeTimeWindows) {
        if (!this.checkedTimeWindows[timeWindow.id]) {
          allWasChecked = false;
        }
      }
    }

    for (const day of this.artistEvents[artist].days) {
      for (const timeWindow of day.artists[0].freeTimeWindows) {
        this.checkTimeWindow(artist, day.date, timeWindow, !allWasChecked);
        this.checkedTimeWindows[timeWindow.id] = !allWasChecked;
      }
    }
  }

  private resetAllDayCheckboxes() {
    for (const key of Object.keys(this.checkedTimeWindows)) {
      this.checkedTimeWindows[key] = false;
    }

    for (const key of Object.keys(this.checkedTimeWindowObj)) {
      delete this.checkedTimeWindowObj[key];
    }
  }

  private async calcResults() {

  }

  clearResults() {
    this.resultArtists.set(null);
    this.resultCustomers.set(null);
    this.resultTattooTicketKlarna.set(null);
    this.resultGiftCard.set(null);
    this.resultShopOrders.set(null);
  }

  testShopOrder(searchText: string) {
    this.searchText = searchText;
    this.searchType = this.searchTypes[3];
    this.search();
  }


  testGiftCard(searchText: string) {
    this.searchText = searchText;
    this.searchType = this.searchTypes[2];
    this.search();
  }

  showShopOrder(shopOrderId: string) {
    this.searchText = shopOrderId.toString();
    this.searchType = this.searchTypes.find(s => s.value === 'shop-order');
    this.search();
  }

  showEvent(eventId: string) {
    this.dialogService.showEvent(eventId);
  }

  showGiftCard(paymentPossibilityRecordId: string) {
    this.searchText = paymentPossibilityRecordId;
    this.searchType = this.searchTypes.find(s => s.value === 'gift-card');
    this.search();
  }

  showArtist(artist: string) {
    this.searchText = artist;
    this.searchType = this.searchTypes.find(s => s.value === 'artists');
    this.search();
  }

  async editArtistSpot(artistSpot: NxtArtistSpot) {
    const dialog = this.dialogService.showComponentDialog(ArtistSpotEditComponent);
    dialog.componentInstance.load(artistSpot.id);
    await firstValueFrom(dialog.afterClosed());
    this.search();
  }

  /*showTattooTicket(id: string) {
    this.searchText = id;
    this.searchType = this.searchTypes.find(s => s.value === 'klarna-order');
    this.search();
  }*/

  showTattooTicketKlarna(shopOrderLineItemId: string) {
    this.searchText = shopOrderLineItemId;
    this.searchType = this.searchTypes.find(s => s.value === 'tattoo-ticket-klarna');
    this.search();
  }

  showTattooTicketPayPal(shopOrderLineItemId: string) {
    this.searchText = shopOrderLineItemId;
    this.searchType = this.searchTypes.find(s => s.value === 'tattoo-ticket-paypal');
    this.search();
  }

  showContact(searchText: string) {
    this.searchText = searchText;
    this.searchType = this.searchTypes.find(s => s.value === 'customers');
    this.search();
  }


  contactSelectionChanged(event: SelectionChangedEvent<NxtContact> | CellClickedEvent) {
    this.selectedRows = event.api.getSelectedRows();
    if (this.selectedRows.length > 1) {
      this.showCombineContacts.set(true);
    }
  }

  async combineContactsClicked() {
    const result = await this.dialogService.showCombineContacts(this.selectedRows.map(s => s.id));
    this.search();
  }

  copyInstaClicked(artist: { artist: string; spots: NxtArtistSpot[]; instagram: string }) {
    this.clipboard.copy('https://instagram.com/' + artist.instagram);
  }
}
