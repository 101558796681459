export class TimeTools {
  public static sleep(millis: number): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, millis);
    });
  }

  public static waitAnimationFrame(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      requestAnimationFrame(() => {
        resolve();
      });
    });
  }

  static async waitForObj(getFn: () => any, interval = 100) {
    return new Promise<void>((resolve, reject) => {
      if (getFn()) {
        resolve();
      } else {
        setTimeout(() => {
          resolve(this.waitForObj(getFn, interval));
        }, interval);
      }
    });
  }

  static sleepWithAbort(millis: number, signal: AbortSignal): Promise<void> {
    return new Promise((resolve, reject) => {
      if (signal.aborted) {
        resolve();
      }

      const timeout = setTimeout(() => {
        signal.removeEventListener('abort', onAbort);
        resolve();
      }, millis);

      const onAbort = () => {
        clearTimeout(timeout);
        resolve();
      };
      signal.addEventListener('abort', onAbort);
    });
  }
}
