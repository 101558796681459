import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControlWrapper} from '../form-control-wrapper';
import {MatCheckbox} from '@angular/material/checkbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormFieldWrapperComponent} from '../../form-field-wrapper/form-field-wrapper.component';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';

@Component({
  selector: 'nxt-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  imports: [FormFieldWrapperComponent, MatCheckbox, FormsModule, ReactiveFormsModule, SafeHtmlPipe],
  standalone: true,
})
export class CheckboxComponent extends FormControlWrapper implements OnInit, OnChanges {
  @Input() label: string;

  @Input() set value(checked: boolean) {
    if (this.nxtFormControl.value !== checked) {
      this.nxtFormControl.setValue(checked, {emitEvent: false});
    }
  }

  @Output() valueChange = new EventEmitter<boolean>();
  modelName: any;
  @Input() disabled = false;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      if (this.disabled) {
        this.nxtFormControl.disable();
      } else {
        this.nxtFormControl.enable();
      }
    }
  }

  ngOnInit() {
    this.nxtFormControl.valueChanges.subscribe((value) => {
      this.valueChange.emit(value);
    });
  }
}
