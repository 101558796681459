import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, OnInit, Optional, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {CalendarCommonModule, CalendarDayModule, CalendarDayViewComponent, CalendarEvent, CalendarMonthModule, CalendarWeekModule, CalendarWeekViewComponent} from 'angular-calendar';
import {SocketService} from '../../services/socket/socket.service';
import {ConfigService} from '../../services/config.service';
import {NxtCalendarEvent, NxtCalendarEventSmall} from '../../common-interfaces/nxt.calendar-event.interface';
import {NxtArtistSpot, NxtAvailableArtistWorkTimeWindow} from '../../common-interfaces/nxt.available-artist-day.interface';
import {DialogService} from '../../services/dialog.service';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {firstValueFrom, Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgIf} from '@angular/common';
import {debounceNotFirst} from '../../common-browser/helpers/rxjs.tools';
import {DateSelectComponent} from './date-select/date-select.component';
import {LoginService} from '../../services/login.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {SocketInterfaceResponse} from 'src/app/common-interfaces/socket/socket-interface';
import {BodyPutService} from '../../services/body-put.service';
import {CalendarHourSegmentComponent} from './calendar-hour-segment/calendar-hour-segment.component';
import {CalendarHeaderComponent} from './calendar-header/calendar-header.component';
import {CalendarToolbarTopComponent} from './calendar-toolbar-top/calendar-toolbar-top.component';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {toObservable} from '@angular/core/rxjs-interop';
import {FromNowPipe} from '../../pipes/from-now.pipe';
import {clone, ObjectTools} from '../../common-browser/helpers/object.tools';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {NxtSubstrPipe} from '../../pipes/nxt-subsstring-pipe';
import {MatDialogRef} from '@angular/material/dialog';
import {ColorTools} from '../../common-browser/helpers/color.tools';
import {CalendarEventTemplateComponent} from './calendar-event-template/calendar-event-template.component';
import {NxtDailyNote} from '../../common-interfaces/daily-note.interface';
import {ArtistSpotEditComponent} from '../artist-spot-edit/artist-spot-edit.component';
import {DailyNoteEditComponent} from '../daily-note-edit/daily-note-edit.component';
import {CalendarArtistViewComponent} from './calendar-artist-view/calendar-artist-view.component';
import {CalendarEventEdit2Component} from '../../pages/calendar-event-edit/calendar-event-edit-2/calendar-event-edit-2.component';
import {CalendarArtistSpotComponent} from './calendar-artist-spot/calendar-artist-spot.component';
import {WalkInService} from '../../services/walk-in.service';
import CalendarFilter = SocketInterfaceResponse.CalendarFilter;


export type EventMeta = {
  event?: NxtCalendarEventSmall;
  artistSpot?: NxtArtistSpot;
};

@Component({
    selector: 'nxt-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, CalendarWeekModule, CalendarDayModule, ExtendedModule, CalendarCommonModule, NxtDatePipe, CalendarHourSegmentComponent, CalendarHeaderComponent, CalendarToolbarTopComponent, FromNowPipe, NxtDatePipe, NxtDatePipe, NxtDatePipe, NxtSubstrPipe, CalendarEventTemplateComponent, CalendarMonthModule, CalendarArtistSpotComponent]
})
export class CalendarComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor(
    @Optional() dialogRef: MatDialogRef<CalendarComponent>,
    private cdRef: ChangeDetectorRef,
    private socketService: SocketService,
    private configService: ConfigService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    public loginService: LoginService,
    private localStorageService: LocalStorageService,
    private bodyPutService: BodyPutService,
    private walkInService: WalkInService,
    // private shortcutService: ShortcutService
  ) {
    super();

    // this.registerShortcuts();  das triggert auch wenn dialoge drüber liegen

    this.pushSubscription = toObservable(this.currentFilter).subscribe(() => {
      this.filterChanged();
    });
    this.registerEventListener();
    this.route.queryParams.subscribe(params => {
      if (params.days) {
        this.currentFilter.update(f => ({...f, daysCount: parseInt(params.days, 10)}));
      }
    });

    setInterval(() => {
      this.refreshView.next(true);
    }, 30000);
  }


  /*** Signals **/
  artistCounts: { [dateString: string]: number } = {};
  piercingCounts: { [dateString: string]: number } = {};
  dailyNotes: { [dateString: string]: NxtDailyNote[] } = {};
  valence: { [dateString: string]: number } = {};

  viewMode = computed<'day' | 'week' | 'month'>(() => {
    if (this.currentFilter().daysCount === 1) {
      return 'day';
    } else if (this.currentFilter().daysCount <= 7) {
      return 'week';
    }
    return 'month';
  });

  currentFilter = signal<CalendarFilter>({
    dateString: Date.now().dateFormatDate(),
    daysCount: 7,
    showCanceledEvents: false,
    showEventsWithPiercing: true,
    showOnlyEventsFuture: false,
    onlyArtistSpots: false,
    showOnlyCanceledEvents: false,
  });

  @ViewChild(CalendarWeekViewComponent) calendarWeekViewComponent: CalendarWeekViewComponent;
  @ViewChild(CalendarDayViewComponent) calendarDayViewComponent: CalendarDayViewComponent;


  events = signal<CalendarEvent<EventMeta>[] | null>(null);
  headerWidth = 'calc(100% - 9px)';
  private load$ = new Subject<void>();
  data: SocketInterfaceResponse.GetCalendarEventsForCalendar;
  dayStartHour = (this.configService.config.value.studioWorkStart / DurationTools.DURATION_1HOUR);
  viewParams = signal({dateToShow: new Date(), daysCountToShow: 1});
  private forceReloadOnSameFilters = false;

  /*private registerShortcuts() {
    this.shortcutService.onKeyPressExclusive.subscribe([KeyCode.A, KeyCode.P, KeyCode.H, KeyCode.O, KeyCode._1, KeyCode._3, KeyCode._6, KeyCode._7, KeyCode.Right, KeyCode.Left], (code) => {
      switch (code) {
        case KeyCode.A:
          this.currentFilter.update(f => ({...f, showCanceledEvents: !f.showCanceledEvents, showOnlyEventsFuture: !f.showCanceledEvents ? false : f.showOnlyEventsFuture}));
          break;
        case KeyCode.O:
          this.currentFilter.update(f => ({...f, showOnlyEventsFuture: !f.showOnlyEventsFuture, showCanceledEvents: !f.showOnlyEventsFuture ? false : f.showCanceledEvents}));
          break;
        case KeyCode.P:
          this.currentFilter.update(f => ({...f, showEventsWithPiercing: !f.showEventsWithPiercing}));
          break;
        case KeyCode.H:
          this.setToday();
          break;
        case KeyCode._1:
          this.currentFilter.update(f => ({...f, daysCount: 1}));
          break;
        case KeyCode._3:
          this.currentFilter.update(f => ({...f, daysCount: 3}));
          break;
        case KeyCode._6:
          this.currentFilter.update(f => ({...f, daysCount: 6}));
          break;
        case KeyCode._7:
          this.currentFilter.update(f => ({...f, daysCount: 7}));
          break;
        case KeyCode.Left:
          this.jumpDateBackClicked();
          break;
        case KeyCode.Right:
          this.jumpDateForwardClicked();
          break;
      }
    });
  }*/
  refreshView = new Subject();

  test() {
    console.log(this.calendarWeekViewComponent.view);
    console.log(this.calendarWeekViewComponent.view);
    console.log(this.calendarWeekViewComponent.view);
  }

  async ngOnInit() {
    this.loadCalendarStateStorage();
    this.load$.pipe(debounceNotFirst(500)).subscribe(() => this.loadFromServer());
    this.load$.next();
  }

  nxtOnDestroy() {
  }

  calendarEventClicked(event: NxtCalendarEvent) {
    this.dialogService.showEvent(event.id);
  }

  artistSpotClicked(artistSpot: NxtArtistSpot) {
    if (this.currentFilter().onlyArtistSpots) {
      const dialog = this.dialogService.showComponentDialog(ArtistSpotEditComponent);
      dialog.componentInstance.load(artistSpot.id);
    } else {
      const dialog = this.dialogService.showComponentDialog(CalendarArtistViewComponent);
      const artistColors: { [color: string]: string } = {};
      this.data.artists.forEach(a => artistColors[a.color] = a.name);
      dialog.componentInstance.load(artistSpot, artistColors).then();
    }
  }

  private registerEventListener() {
    this.pushSocketSubscription = this.socketService
      .subscribeNew('calendarEventChanged', async (data) => {
        const start = this.currentFilter().dateString.dateParse();
        const end = start.dateAddDays(this.currentFilter().daysCount);
        if (DateTools.betweenDays(data.record.start, start, end)) {
          this.forceReloadOnSameFilters = true;
          this.load$.next();
        } else {
          if (data.op === 'update') {
            if (DateTools.betweenDays(data.oldRecord.start, start, end)) {
              this.forceReloadOnSameFilters = true;
              this.load$.next();
            }
          }
        }
      });

    this.pushSocketSubscription = this.socketService
      .subscribeNew('eventDailyNoteChanged', async (data) => {
        this.forceReloadOnSameFilters = true;
        this.load$.next();
      });

    this.pushSocketSubscription = this.socketService
      .subscribeNew('eventArtistChanged', async (data) => {
        this.forceReloadOnSameFilters = true;
        this.load$.next();
      });

    this.pushSocketSubscription = this.socketService
      .subscribeNew('eventArtistSpotChanged', async (data) => {
        this.forceReloadOnSameFilters = true;
        this.load$.next();
      });

    this.socketService.onAuthenticated.subscribe(() => {
      this.forceReloadOnSameFilters = true;
      this.load$.next();
    });
  }

  wrap() {
    const timeEvents = document.querySelector('.cal-time-events');

    timeEvents.outerHTML = '<div class="wrapper">' + timeEvents.outerHTML + '</div>';

  }

  createEventClicked(data: { date: Date; sourceEvent: MouseEvent }) {
    this.dialogService.showEventNew({
      dateString: data.date.dateFormat('yyyy-MM-dd'),
      timeFromString: data.date.dateFormat('HH:mm'),
      showEventFinder: true,
      askWorkType: true,
    });
  }

  calcDateData() {
    this.artistCounts = {};
    this.piercingCounts = {};
    this.dailyNotes = {};
    const start = this.currentFilter().dateString.dateParse();
    for (let daysAdd = 0; daysAdd < this.currentFilter().daysCount; daysAdd++) {
      const date = start.dateAddDays(daysAdd);
      const dateString = date.dateFormat('yyyy-MM-dd');

      let spots = this.data.artistSpots.filter(spot => !spot.artist.toLowerCase().includes('piercing') && DateTools.betweenDays(date, spot.start, spot.end));

      spots = spots.filter(s => {
        return !s.workTimeWindows || s.workTimeWindows.some(w => w.dateString === dateString && w.start !== -2 && w.end !== -2);
      });
      this.artistCounts[dateString] = spots.length;
      this.piercingCounts[dateString] = this.data.artistSpots.filter(spot => spot.artist.toLowerCase().includes('piercing') && DateTools.betweenDays(date, spot.start, spot.end)).length;
      this.valence[dateString] = this.getValence(date.dateFormatDate());
      this.dailyNotes[dateString] = this.data.dailyNotes.filter(dn => dn.dateString === dateString);
    }
  }

  getCalendarEventTitle(event: NxtCalendarEventSmall) {
    let title = event.customerObj?.fullName || '?';
    if (this.currentFilter().daysCount > 2) {
      return title || '?';
    } else {

      if (event.fastWalkInNo) {
        if (event.workType === 'piercing') {
          title = 'P-' + event.fastWalkInNo + ' ' + title;
        }
        if (event.workType === 'tattoo') {
          title = 'T-' + event.fastWalkInNo + ' ' + title;
        }
      }
      let text = '<div class="leading-tight"><strong>' + title + '</strong>';
      if (event.priceFix) {
        text += '<br/>fix ' + event.priceEstimatedFrom.toMoneyString();
      } else if (event.priceEstimatedTill) {
        text += '<br/>' + event.priceEstimatedFrom.toMoneyString() + ' - ' + event.priceEstimatedTill.toMoneyString();
      } else if (event.priceEstimatedFrom) {
        text += '<br/>ca. ' + event.priceEstimatedFrom.toMoneyString();
      } else {
        // debugger;
      }
      text += '<br/>' + this.bodyPutService.getTextFromEvent(event, {size: false}).join('<br/>');
      text += '</div>';
      return text;
    }
  }

  addCurrentDate(number: number) {
    const newDateString = this.currentFilter().dateString.dateParse().dateAddDays(number).dateFormatDate();
    this.currentFilter.update(f => ({...f, dateString: newDateString}));
    this.load$.next();
  }

  setToday() {
    this.currentFilter.update(f => ({...f, dateString: Date.now().dateFormatDate()}));
    this.load$.next();
  }

  private async loadFromServer() {
    this.log('start loadFromServer');
    if (this.currentFilter().daysCount === 7 || this.currentFilter().daysCount === 6) {
      const monday = DateTools.getMondayBefore(this.currentFilter().dateString, 0).dateFormatDate();
      if (this.currentFilter().dateString !== monday) {
        this.log('datum ist nicht Montag, wird auf montag gesetzt -> return');
        this.currentFilter.update(f => ({...f, dateString: monday}));
        return;
      }
    }
    if (this.forceReloadOnSameFilters || !this.data || this.shouldLoadNew()) {
      this.forceReloadOnSameFilters = false;
      this.log('frage Server nach daten für ' + this.currentFilter().dateString + ' | Tage: ' + this.currentFilter().daysCount);
      this.data = await this.socketService.getCalendarEventsForCalendar(this.currentFilter().dateString, this.currentFilter().daysCount);
      this.data.filter = clone(this.currentFilter());
    } else {
      this.log('keine neuladen notwendig');
    }

    const allDayEvents = this.getEventsFromArtistSpots(this.data.artistSpots);

    const nxtEvents = this.data.events.filter(e => {
      if (this.currentFilter().showOnlyEventsFuture && e.status !== 'future') {
        return false;
      }
      if (!this.currentFilter().showCanceledEvents && e.status === 'canceled') {
        return false;
      }
      if (!this.currentFilter().showEventsWithPiercing && e.workType === 'piercing') {
        return false;
      }
      if (this.currentFilter().showOnlyCanceledEvents && e.status !== 'canceled') {
        return false;
      }
      return true;
    });

    const events: CalendarEvent<EventMeta>[] = [];

    if (!this.currentFilter().onlyArtistSpots) {
      for (const event of nxtEvents) {
        const toAdd = 0; // = MathTools.random(0, DurationTools.DURATION_1HOUR);
        let color = this.data.artists.find(a => a.name === event.artist)?.color;
        if (!color) {
          if (event.status === 'canceled') {
            color = 'red';
          }
          if (!color) {
            color = '#555';
          }
        }
        events.push({
          start: new Date(event.start + toAdd),
          end: new Date(event.end + toAdd),
          id: event.id,
          title: this.getCalendarEventTitle(event),
          color: {
            primary: color,
            secondary: ColorTools.getLightOrDarkFontColorByBackground(color),
          },
          meta: {event},
        });
      }
    }
    this.calcDateData();

    events.sort((e1, e2) => {
      if (e1.start < e2.start) {
        return -1;
      } else if (e1.start > e2.start) {
        return 1;
      } else {
        if (e1.end > e2.end) {
          return -1;
        } else if (e1.end < e2.end) {
          return 1;
        }
        return e1.meta.event.artist.localeCompare(e2.meta.event.artist);
      }
    });
    this.events.set([...allDayEvents, ...events]);
    this.cdRef.detectChanges();
    this.viewParams.set({dateToShow: new Date(this.currentFilter().dateString), daysCountToShow: this.currentFilter().daysCount});
    setTimeout(() => {
      this.cdRef.detectChanges();
    }, 200);
  }

  async showDateSelectClicked() {
    const dialog = this.dialogService.showComponentDialog(DateSelectComponent);
    dialog.componentInstance.selectedMonthString = this.currentFilter().dateString.dateParse().dateFormat('yyyy-MM');
    dialog.componentInstance.markDateRange(this.currentFilter().dateString, this.currentFilter().dateString.dateParse().dateAddDays(this.currentFilter().daysCount).dateFormatDate());
    const newDateString = await firstValueFrom(dialog.afterClosed());
    if (newDateString) {
      this.currentFilter.update(cf => {
        cf.dateString = newDateString;
        return cf;
      });
      this.load$.next();
    }
  }

  filterChanged() {
    this.load$.next();
    this.setCalendarStateStorage();
  }

  setCalendarStateStorage() {
    this.localStorageService.set('CalendarState', this.currentFilter());
  }

  private loadCalendarStateStorage() {
    this.currentFilter.set({...this.currentFilter(), ...this.localStorageService.get('CalendarState', this.currentFilter())});
  }

  jumpDateBackClicked() {
    const toJump = -1 * (this.currentFilter().daysCount === 6 ? 7 : this.currentFilter().daysCount);
    this.addCurrentDate(toJump);
  }

  jumpDateForwardClicked() {
    const toJump = this.currentFilter().daysCount === 6 ? 7 : this.currentFilter().daysCount;
    this.addCurrentDate(toJump);
  }

  private shouldLoadNew() {
    let diff = ObjectTools.getDiff(this.data.filter, this.currentFilter());
    const whileList: string[] = [
      'showOnlyEventsFuture',
      'showCanceledEvents',
      'showEventsWithPiercing',
    ];
    diff = diff.filter(d => !whileList.includes(d.path.join()));
    this.log('shouldLoadNewDiff: ' + JSON.stringify(diff));
    const result = diff.length > 0;
    if (!result) {
      this.log('alteDate', this.data.filter);
      this.log('neueDate', this.currentFilter());
    }
    return result;
  }

  private getValence(dateString: string) {
    const events = this.data.events.filter(e => e.status !== 'canceled' && e.start.dateFormatDate() === dateString);
    return events.map(e => e.valence).reduce((sum, valence) => sum + valence, 0);
  }

  private getEventsFromArtistSpots(artistSpots: NxtArtistSpot[]): CalendarEvent<EventMeta>[] {
    const events: CalendarEvent[] = [];
    for (const artistSpot of artistSpots) {
      let parts: { start: number, end: number }[] = [];
      let startNewPart = true;
      let lastWorkTimeWindow: NxtAvailableArtistWorkTimeWindow;
      if (artistSpot.workTimeWindows) {
        for (const workTimeWindow of artistSpot.workTimeWindows) {
          if (workTimeWindow.start === -2 && workTimeWindow.end === -2) {
            startNewPart = true;
            if (lastWorkTimeWindow) {
              parts[parts.length - 1].end = lastWorkTimeWindow.dateString.dateParse();
            }
            continue;
          }
          if (startNewPart) {
            parts.push({start: workTimeWindow.dateString.dateParse(), end: 0});
            startNewPart = false;
          }
          lastWorkTimeWindow = workTimeWindow;
        }
      }
      if (parts.length > 0 && !parts[parts.length - 1].end && lastWorkTimeWindow) {
        parts[parts.length - 1].end = lastWorkTimeWindow.dateString.dateParse();
      }
      parts = parts.filter(p => p.start <= this.currentFilter().dateString.dateParse().dateAddDays(this.currentFilter().daysCount));
      const title = artistSpot.artist;
      let color = this.data.artists.find(a => a.name === artistSpot.artist).color; // this.artistCalendars.find(a => a.artist === artistSpot.artist)?.backgroundColor;
      if (this.currentFilter().onlyArtistSpots) {
        color = '#888888';
      }
      if (!color) {
        color = '#fff';
      }
      for (const [index, part] of parts.entries()) {
        events.push({
          start: new Date(part.start),
          end: new Date(part.end),
          id: artistSpot.id + '_' + index,
          title,
          allDay: true,
          color: {
            primary: color,
            secondary: color,
          },
          meta: {artistSpot, view: {textColor: ColorTools.getLightOrDarkFontColorByBackground(color)}},
        });
      }
    }
    return events;
  }

  newArtistSpotClicked() {
    const dialog = this.dialogService.showComponentDialog(ArtistSpotEditComponent);
    const start = this.currentFilter().dateString;
    const end = start.dateParse().dateAddDays(6).dateFormatDate();
    dialog.componentInstance.load('new', start, end);
  }

  dateHeaderClicked(dateString: string) {
    const dialog = this.dialogService.showComponentDialog(DailyNoteEditComponent);
    dialog.componentInstance.new(dateString);
  }

  dailyNoteClicked(dailyNote: NxtDailyNote) {
    const dialog = this.dialogService.showComponentDialog(DailyNoteEditComponent);
    dialog.componentInstance.load(dailyNote.id);
  }

  eventFinderClicked() {
    const dialog = this.dialogService.showComponentFull(CalendarEventEdit2Component);
    dialog.componentInstance.loadEvent({newEventData: {}});
    dialog.componentInstance.showEventFinder({
      fromDateString: this.currentFilter().dateString,
    });
  }

  reloadClicked() {
    this.forceReloadOnSameFilters = true;
    this.load$.next();
  }

  walkInClicked() {
    this.walkInService.startWalkIn().then();
  }

  private log(message: string, ...args: any[]) {
    console.log('CALENDAR | ' + message, ...args);
  }
}
