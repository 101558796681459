import {ObjectTools} from './object.tools';

export class JsonTools {
  public static stringify(obj: any, sortKeys = false) {
    if (sortKeys) {
      return JSON.stringify(ObjectTools.sortKeys(obj));
    }
    return JSON.stringify(obj);
  }

  public static stringifyFormat(obj: any, spacer = ' ') {
    return JSON.stringify(obj, undefined, spacer);
  }

  static parse(data: any) {
    try {
      if (data === null) {
        return null;
      }
      if (typeof data !== 'string') {
        return JSON.parse(data.toString());
      }
      return JSON.parse(data);
    } catch (err: any) {
      let jsonString = '';
      if (data?.toString().length > 100) {
        jsonString = data.toString().substr(0, 100);
      } else {
        jsonString = data;
      }
      throw Error('Fehler beim JSON parsen\n' + err.message + '\n' + jsonString);
    }
  }


  static toTable(objArray: any[]) {
    if (objArray.length === 0) {
      return 'leer';
    }
    const html: string[] = ['<table class="nxt-table">'];

    html.push('<tr>');
    for (const key of Object.keys(objArray[0])) {
      html.push(`<td>${key}</td>`);
    }
    html.push('</tr>');
    for (const item of objArray) {
      html.push('<tr>');
      for (const key of Object.keys(item)) {
        html.push(`<td>${item[key]}</td>`);
      }
      html.push('</tr>');
    }
    html.push('</table>');
    return html.join('');
  }
}
