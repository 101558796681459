import {DialogService} from '../../services/dialog.service';
import {FirestoreService} from '../../services/firestore.service';
import {SocketService} from '../../services/socket/socket.service';
import {LoginService} from '../../services/login.service';
import {Component, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {NxtSubscriptionClass} from '../../classes/nxt-subscription-class';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {WindowService} from '../../services/window.service';
import {BackofficeColumnDefs} from './backoffice.column-defs';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtBackofficeViewData} from '../../common-interfaces/nxt.backoffice-view-data.interface';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {PermissionService} from '../../services/permission.service';
import {MoneyPipe} from '../../pipes/money.pipe';
import {DatePickerComponent} from '../form-controls/date-picker/date-picker.component';
import {NgIf} from '@angular/common';
import {RadioComponent} from '../form-controls/radio/radio.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtPageComponent} from '../nxt-page/nxt-page.component';
import {NxtPageHeaderComponent} from '../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageHeaderTitleComponent} from '../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageContentComponent} from '../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
    selector: 'nxt-backoffice',
    templateUrl: './backoffice.component.html',
    styleUrls: ['./backoffice.component.scss'],
    imports: [FlexModule, RadioComponent, NgIf, NxtDatagridComponent, DatePickerComponent, MoneyPipe, NxtPageComponent, NxtPageHeaderComponent, NxtPageHeaderTitleComponent, NxtPageContentComponent, NxtPageFooterComponent]
})
export class BackofficeComponent extends NxtSubscriptionClass implements OnInit, OnDestroy {

  constructor(
    private firestoreService: FirestoreService,
    private socketService: SocketService,
    public loginService: LoginService,
    private dialogService: DialogService,
    private windowService: WindowService,
    public permissionService: PermissionService,
    @Optional() public dialogRef: MatDialogRef<BackofficeComponent>,
  ) {
    super();
    this.windowService.setTitle('Backoffice');

    this.initAuthenticationListener();
    this.loadData();
  }

  @ViewChild('newEventsDataGrid') nxtDataGrid: NxtDatagridComponent;

  public dateString = DateTools.format(Date.now(), 'yyyy-MM-dd');
  columnDefs: BackofficeColumnDefs = new BackofficeColumnDefs(this);
  backofficeViewData: NxtBackofficeViewData;
  quickFilterText = '';
  totalBank = 0;
  total_ = 0;
  newEventCalcData = {
    totalPrice: 0,
    totalValence: 0,
    reception: 0,
    percentageBackoffice: 0,
    percentageReception: 0,
    valenceBackoffice: 0,
    valencePercentageBackoffice: 0,
    valencePercentageReception: 0,
    valenceReception: 0,
    eventCountBackoffice: 0,
    eventCountReception: 0,
    eventCountTattoo: 0,
    eventCountTattooBackoffice: 0,
    eventCountTattooReception: 0,
    eventCountTattooReceptionPercentage: 0,
    eventCountTattooBackofficePercentage: 0,
    eventCountPiercing: 0,
    eventCountPiercingBackoffice: 0,
    eventCountPiercingReception: 0,
    eventCountPiercingReceptionPercentage: 0,
    eventCountPiercingBackofficePercentage: 0,
  };
  public currentView = 'created';


  async ngOnInit() {
    this.subscribeServerData();
  }

  ngOnDestroy(): void {
  }

  private async subscribeServerData() {
    this.pushSocketSubscription = this.socketService.subscribeNew('getBackofficeViewData', (data: NxtBackofficeViewData) => {
      this.setBackofficeViewData(data);
    });
  }

  private loadData = async () => {
    if (this.loginService.isBackoffice()) {
      const data = (await this.socketService.getBackofficeViewData(this.dateString));
      this.setBackofficeViewData(data);
    } else {
      this.dialogService.showOk('Keine Berechtigungen (Nur Backoffice)');
      this.loginService.reLogin(false);
    }
  };


  private initAuthenticationListener() {
    this.socketService.state.subscribe(async (params) => {
      if (params.authenticated) {
        setTimeout(() => {
          this.loadData();
        }, 1);
      }
    });
  }

  dateChanged() {
    this.loadData();
  }

  private calcNewEventsData() {
    this.newEventCalcData.totalPrice = this.backofficeViewData.calendarEventsCreated.reduce((sum, obj) => sum + obj.priceEstimatedFrom, 0);
    this.newEventCalcData.totalValence = MathTools.round(this.backofficeViewData.calendarEventsCreated.reduce((sum, obj) => sum + obj.valence, 0), 1);

    this.newEventCalcData.valenceBackoffice = MathTools.round(this.backofficeViewData.calendarEventsCreated.filter(e => e.createdByWorkplace === 'backoffice').reduce((sum, obj) => sum + obj.valence, 0), 1);
    this.newEventCalcData.valenceReception = MathTools.round(this.newEventCalcData.totalValence - this.newEventCalcData.valenceBackoffice, 1);

    this.newEventCalcData.valencePercentageBackoffice = Math.round(this.newEventCalcData.valenceBackoffice / this.newEventCalcData.totalValence * 100);
    this.newEventCalcData.valencePercentageReception = 100 - this.newEventCalcData.valencePercentageBackoffice;

    // Percentage
    this.newEventCalcData.eventCountBackoffice = this.backofficeViewData.calendarEventsCreated.filter(e => e.createdByWorkplace === 'backoffice').length;
    const allEventsCount = this.backofficeViewData.calendarEventsCreated.length;
    this.newEventCalcData.eventCountReception = allEventsCount - this.newEventCalcData.eventCountBackoffice;

    this.newEventCalcData.percentageBackoffice = Math.round(this.newEventCalcData.eventCountBackoffice / allEventsCount * 100);
    this.newEventCalcData.percentageReception = 100 - this.newEventCalcData.percentageBackoffice;

    this.newEventCalcData.eventCountTattoo = this.backofficeViewData.calendarEventsCreated.filter(e => e.workType === 'tattoo').length;
    this.newEventCalcData.eventCountTattooBackoffice = this.backofficeViewData.calendarEventsCreated.filter(e => e.workType === 'tattoo' && e.createdByWorkplace === 'backoffice').length;
    this.newEventCalcData.eventCountTattooReception = this.newEventCalcData.eventCountTattoo - this.newEventCalcData.eventCountTattooBackoffice;

    this.newEventCalcData.eventCountTattooReceptionPercentage = Math.round(this.newEventCalcData.eventCountTattooReception / this.newEventCalcData.eventCountTattoo * 100);
    this.newEventCalcData.eventCountTattooBackofficePercentage = 100 - this.newEventCalcData.eventCountTattooReceptionPercentage;

    this.newEventCalcData.eventCountPiercing = this.backofficeViewData.calendarEventsCreated.length - this.newEventCalcData.eventCountTattoo;
    this.newEventCalcData.eventCountPiercingBackoffice = this.backofficeViewData.calendarEventsCreated.filter(e => e.workType === 'piercing' && e.createdByWorkplace === 'backoffice').length;
    this.newEventCalcData.eventCountPiercingReception = this.newEventCalcData.eventCountPiercing - this.newEventCalcData.eventCountPiercingBackoffice;

    this.newEventCalcData.eventCountPiercingReceptionPercentage = Math.round(this.newEventCalcData.eventCountPiercingReception / this.newEventCalcData.eventCountPiercing * 100);
    this.newEventCalcData.eventCountPiercingBackofficePercentage = 100 - this.newEventCalcData.eventCountPiercingReceptionPercentage;
  }

  private setBackofficeViewData(data: NxtBackofficeViewData) {
    data.calendarEventsCreated = data.calendarEventsCreated.sortNumber('createdAt', true);
    data.calendarEventsChanged = data.calendarEventsChanged.sortNumber('updatedAt', true);
    this.backofficeViewData = data;
    this.calcNewEventsData();
  }
}
