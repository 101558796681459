<div fxLayout="column" fxFlex style="height: 100vh">
<table>
  <tr>
    <td style="font-family: 'Montserrat ExtraBold2';">name</td>
    <td><input style="font-size: 20px; color: #000000 !important;" type="text" [(ngModel)]="text" (keyup)="textChanged()"/></td>
  </tr>
  <tr>
    <td>font-size</td>
    <td><input style="font-size: 20px; color: #000000 !important;" type="number" [(ngModel)]="fontSize" (ngModelChange)="textChanged()"/></td>
  </tr>
  <tr>
    <td>letter-space</td>
    <td><input style="font-size: 20px; color: #000000 !important;" type="number" [(ngModel)]="letterSpace" (ngModelChange)="textChanged()"/></td>
  </tr>
  <tr>
    <td>logo-letter-color</td>
    <td>
      <select [(ngModel)]="letterColor" (ngModelChange)="textChanged()" style="font-size: 20px; color: #000000 !important;">
        <option value="#303030">dark</option>
        <option value="#FFFFFF">white</option>
      </select>
    </td>
  </tr>
  <tr>
    <td>OTF</td>
    <td><a href="assets/fonts/Montserrat-ExtraBold.otf">Monsterrat-ExtraBold OTF</a></td>
  </tr>
  <tr>
    <td>TTF</td>
    <td><a href="assets/fonts/Montserrat-ExtraBold.ttf">Monsterrat-ExtraBold TTF</a></td>
  </tr>
  <tr>
    <td>SVG</td>
    <td>
      <button (click)="download('both')">LOGO DOWNLOAD</button>&nbsp;
      <!--<button (click)="download('letters')">LOGO LETTERS</button>&nbsp;
      <button (click)="download('forms')">LOGO FORMS</button>&nbsp;-->
    </td>
  </tr>
</table>

<div fxLayout="row" fxFlex style="background-color:#c4ffed;" fxLayoutAlign="center center">
  <img style="width: 30vw;" [src]="imgSrcBoth | safeResourceUrl"/>
  <!--<img style="width: 30vw;" [src]="imgSrcLetters | safeResourceUrl"/>
  <img style="width: 30vw;" [src]="imgSrcForms | safeResourceUrl"/>-->
</div>

</div>
