<div *ngIf="form" [formGroup]="form">
  <div nxtPermission="IsJulian">{{ form.get('id').value }}</div>
  @if (loadingParseContacts()) {
    <div class="flex justify-center flex-row items-center w-full gap-2">
      <nxt-spinner [size]="24"></nxt-spinner>
      <fa-icon size="lg" [icon]="faWandSparkles"></fa-icon>
      <nxt-spinner [size]="24"></nxt-spinner>
    </div>
  }
  <nxt-input nxtPermission="IsBackoffice" placeholder="Aus Zwischenablage (STRG-V)" [(value)]="clipboardText" (valueChange)="fromClipBoard()"></nxt-input>
  <nxt-row>
    <nxt-col fxFlex>
      <nxt-input placeholder="Vorname" [nxtFormControl]="form.get('givenName')" (change)="checkUpperCase('givenName')"></nxt-input>
    </nxt-col>
    <nxt-button-icon (click)="switchNames()">switch_left</nxt-button-icon>
    <nxt-col fxFlex>
      <nxt-input placeholder="Nachname" [nxtFormControl]="form.get('familyName')" (change)="checkUpperCase('familyName')"></nxt-input>
    </nxt-col>
  </nxt-row>

  <nxt-row>
    <div fxLayout="row" fxFlex fxLayoutAlign="baseline baseline">
      <nxt-date-picker
        width="100%"
        placeholder="Geburtstag"
        displayDateFormat="dd.MM.yyyy"
        [nxtFormControl]="form.get('birthday')"
        (nxtModelChange)="birthdayChanged()"
        [disableOpenOnClickInput]="true"
      >
      </nxt-date-picker>
      <nxt-radio
        class="lang-radio"
        direction="row"
        valueKey="code"
        textKey="name"
        [nxtFormControl]="form.get('lang')"
        [options]="langs">
      </nxt-radio>
    </div>
  </nxt-row>
  <nxt-row>
    <nxt-col fxFlex>
      <nxt-input placeholder="Handy" [nxtFormControl]="form.get('mobile')"></nxt-input>
      <nxt-slide-toggle nxtPermission="IsBackoffice" [(value)]="unknownMobile" (valueChange)="unknownMobileChanged()">Handynummer unbekannt</nxt-slide-toggle>
    </nxt-col>
  </nxt-row>


  <nxt-row>
    <nxt-col fxFlex>
      <nxt-input placeholder="E-Mail" [nxtFormControl]="form.get('email')"></nxt-input>
    </nxt-col>
  </nxt-row>

  <nxt-row>
    <div class="flex flex-row items-center w-full gap-2">
      <nxt-input placeholder="Straße & Hausnummer" [nxtFormControl]="form.get('streetAddress')"></nxt-input>
      @if (!form.get('streetAddress').value && closedEventsCount() > 0) {
        <nxt-button-icon [size]="18" (click)="autoStreetClicked()" matTooltip="Aus Einwilligung auslesen" fa="sparkles"></nxt-button-icon>
      }
      <nxt-slide-toggle [(value)]="unknownStreet" (valueChange)="unknownStreetChanged()">Straße unbekannt</nxt-slide-toggle>
    </div>
  </nxt-row>

  <div fxLayout="column">
    <div fxLayout="row">
      <nxt-button (click)="setCountry('DE')">DE</nxt-button>
      <nxt-button (click)="setCountry('ES')">ES</nxt-button>
      <nxt-button (click)="setCountry('BE')">BE</nxt-button>
    </div>
    <div fxLayout="row">
      <nxt-autocomplete
        fxFlex.gt-sm="25"
        placeholder="Land"
        [nxtFormControl]="form.get('country')"
        [options]="countries"
        [displayWith]="displayWithCountry"
        [displayInOptionWith]="displayInOptionWithCountry"
        [maxOptionsToShow]="-1"
        [filterFields]="['name']"
        panelWidth="300px"
      >
      </nxt-autocomplete>
      <nxt-col fxFlex="30">
        <nxt-input placeholder="PLZ" [nxtFormControl]="form.get('postalCode')"></nxt-input>
      </nxt-col>
      <nxt-col fxFlex="70">
        <nxt-input placeholder="Ort" [nxtFormControl]="form.get('city')"></nxt-input>
      </nxt-col>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between" class="py-3">
    <nxt-slide-toggle [nxtFormControl]="form.get('disableBroadcast')">Kein Broadcast</nxt-slide-toggle>
    <nxt-slide-toggle [nxtFormControl]="form.get('weeklyBroadcast')">Weekly Broadcast</nxt-slide-toggle>
    <nxt-slide-toggle [nxtFormControl]="form.get('disableBirthdayGiftCard')">Kein Geburstags-Gutschein</nxt-slide-toggle>
    <nxt-slide-toggle nxtPermission="WhatsAppBroadcast" [nxtFormControl]="form.get('testBroadcast')">Test-Broadcast</nxt-slide-toggle>
  </div>


  <div fxLayout="column" fxLayoutAlign="center center" *ngIf="false">
    <div>Benachrichtigen wenn Artist wieder kommt</div>
    <div *ngFor="let artistSpotNotification of artistSpotNotifications; let i = index">
      <nxt-autocomplete
        [options]="artists"
        [displayWith]="artistDisplayWith"
        placeholder="Artist"
        [maxOptionsToShow]="999"
        valueField="name"
        [(value)]="artistSpotNotification.artist"
      >
      </nxt-autocomplete>
      <nxt-button-icon (click)="removeArtistSpotNotification(i)">remove</nxt-button-icon>
    </div>
    <nxt-button-icon (click)="addArtistSpotNotification()">add</nxt-button-icon>
  </div>

  <br/>

  <div fxLayout="row" fxLayoutAlign="space-around">
    <div>
      <nxt-button (click)="closeClicked()">ABBRECHEN</nxt-button>
    </div>
    <!--<div *ngIf="!getNewContactDataRunning">
      <nxt-button (click)="readFromServer()">von Tablet</nxt-button>
    </div>-->
    <div>
      <nxt-button *ngIf="showSaveButton" (click)="saveAndClose()">SPEICHERN</nxt-button>
    </div>
  </div>
</div>
