import {MoneyTools} from './money.tools';
import {DateTools} from './date.tools';

export class DecimalTools {

  static toMoneyString(number: number | string, suffix = '€', withDecimal = true) {
    if (typeof number === 'string') {
      number = parseFloat(number.replace(',', '.'));
    }
    if (!withDecimal) {
      if (number % 1 !== 0) {
        withDecimal = true;
      }
    }
    // suffix = MoneyTools.currencyShort(suffix);
    return this.roundToString(number, withDecimal ? 2 : 0) + (suffix.length > 0 ? ' ' : '') + suffix;
  }

  static round(value: number, decimalCount: number): number {
    const multiplier = Math.pow(10, decimalCount);
    return Math.round(value * multiplier) / multiplier;
  }

  static roundToString(number: number, decimalCount: number): string {
    if (typeof number === 'undefined') {
      return '?';
    }
    try {
      return number.toLocaleString('de-DE', {minimumFractionDigits: decimalCount, maximumFractionDigits: decimalCount});
    } catch (err) {
      console.error('roundToString fehlgeschlagen', err);
    }
    return '?';
    // const multiplier = Math.pow(10, decimalCount);
    // return Math.round(number * multiplier) / multiplier;
  }

  static test() {
    const value = 200;
    const text = value.toMoneyString('EUR', true);
    console.log(text);
    const result1 = DecimalTools.toMoneyString(80000.00);
    const result2 = DecimalTools.toMoneyString(80000.10);
    const result3 = DecimalTools.toMoneyString(10000001.00);
    const result4 = DecimalTools.toMoneyString(10000001.99);
    const result5 = DecimalTools.roundToString(10000001.99876545, 2);
    const result6 = DecimalTools.roundToString(10000001.1, 2);

  }

  static toNumberString(number: number): string {
    const parts = number.toString().split('.');
    let integerPart = parts[0];
    let decimalPart = parts[1] ?? '';

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return integerPart + (decimalPart ? ',' + decimalPart : '');
  }
}

declare global {

  interface String {
    toMoneyString(): string;
  }

  interface Number {
    abs(): number;

    toMoneyString(suffix?: string, withDecimal?: boolean): string;

    roundToString(decimalCount: number): string;

    toNumberString(): number;
  }
}

Number.prototype.roundToString = function (this: number, decimalCount: number) {
  return DecimalTools.roundToString(this, decimalCount);
};

Number.prototype.abs = function (this: number) {
  return Math.abs(this);
};

Number.prototype.toMoneyString = function (this: number, suffix: string = '€', withDecimal: boolean = false) {
  return DecimalTools.toMoneyString(this, suffix, withDecimal);
};

String.prototype.toMoneyString = function (this: string, suffix = '€', withDecimal = true) {
  return DecimalTools.toMoneyString(this, suffix, withDecimal);
};

Object.defineProperty(Number.prototype, 'toNumberString', {
  configurable: true,
  writable: true,
  value(this: number) {
    return DecimalTools.toNumberString(this);
  },
});

