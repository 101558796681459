import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SocketService} from '../../../services/socket/socket.service';
import {JsonTools} from '../../../common-browser/helpers/json.tools';
import {NxtInvoiceData, NxtInvoiceDataExternStudio, NxtInvoiceTo} from '../../../common-interfaces/nxt.invoice.interface';
import {ConfigService} from '../../../services/config.service';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf} from '@angular/common';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';


@Component({
    selector: 'nxt-invoice-extern-studio-pdf',
    templateUrl: './invoice-extern-studio-pdf.component.html',
    styleUrls: ['./invoice-extern-studio-pdf.component.scss'],
    imports: [NgIf, FlexModule, NgFor, SafeHtmlPipe, MoneyPipe, NxtDatePipe]
})
export class InvoiceExternStudioPdfComponent implements OnInit {

  errorText = '';
  showSigning = true;
  showTotalTaxInfo = true;
  data: NxtInvoiceData;
  vatPercent = 19;
  termOfPayment = '';

  constructor(
    private route: ActivatedRoute,
    private socketService: SocketService,
    public configService: ConfigService,
  ) {
    try {
      if (this.route.snapshot.queryParams.data) {
        this.data = this.getInvoiceData(JsonTools.parse(this.route.snapshot.queryParams.data));
        this.calc();
      } else {
        this.errorText = 'Date fehlen!';
        return;
      }
    } catch (err) {
      this.errorText = err.message;
    }
  }

  async ngOnInit() {
  }

  getNet() {
    return this.data.invoiceValueTotalForTax / ((this.vatPercent / 100) + 1);
  }

  getTax() {
    return this.data.invoiceValueTotalForTax - this.getNet();
  }

  getFrom() {
    let text = this.configService.config.value.invoiceData.fromCompany;
    text += ', ' + this.configService.config.value.invoiceData.fromStreet;
    text += ', DE-' + this.configService.config.value.invoiceData.fromPostalCode + ' ' + this.configService.config.value.invoiceData.fromCity;
    return text;
  }

  private calc() {
    this.data.invoiceValueTotal = this.data.invoicePositions.reduce((sum, p) => sum + p.amount * p.unitPrice, 0);
    this.data.invoiceValueTotalForTax = this.data.invoiceValueTotal;
    this.termOfPayment = DateTools.parse(this.data.invoiceDate, 'yyyy.MM.dd').dateAddDays(14).dateFormat('dd.MM.yyyy');
  }


  private getInvoiceData(invoiceDataExternStudio: NxtInvoiceDataExternStudio): NxtInvoiceData {
    const invoiceTo = this.getInvoiceTo(invoiceDataExternStudio.studio);
    if (!invoiceTo) {
      throw Error('missing franchise invoice to');
    }
    const data: NxtInvoiceData = {
      invoiceDate: invoiceDataExternStudio.invoiceDateString.dateParse('dd.MM.yyyy'),
      invoiceNumber: invoiceDataExternStudio.invoiceNumber,
      invoiceTo,
      invoicePositions: [
        {
          posNumber: 1,
          name: 'NXT-LVL.INK Lizenzgebühr ' + invoiceDataExternStudio.monthYearString,
          amount: 1,
          unitPrice: 999,
          isInvoice: true,
          type: 'license-fee',
        },
        {
          posNumber: 2,
          name: 'NXT-LVL.INK Softwarenutzung ' + invoiceDataExternStudio.monthYearString,
          amount: Math.round((invoiceDataExternStudio.shouldBeValue - 999) / 0.19),
          unitPrice: 0.19,
          isInvoice: true,
          type: 'software-usage',
        }],
      pageCountCurrent: 1,
      pageCountTotal: 1,
      invoiceValueTotal: 0,
      invoiceValueTotalForTax: 0,
      invoiceTitle: 'Rechnung',
    };
    if (invoiceDataExternStudio.discountGross > 0) {
      data.invoicePositions.push({
        posNumber: 3,
        name: 'Rabatt',
        amount: 1,
        unitPrice: invoiceDataExternStudio.discountGross * -1,
        isInvoice: true,
        type: 'discount',
      });
    }
    return data;
  }

  private getInvoiceTo(studio: 'AC' | 'FFM' | 'AB' | string): NxtInvoiceTo {
    switch (studio) {
      case 'FFM':
        return {
          salutation: 'Herrn',
          name: 'Sven Hennemann',
          name2: 'NXT-LVL.INK Frankfurt',
          street: 'Berger Str. 288',
          postalCode: '60385',
          city: 'Frankfurt',
          customerNumber: 'S00001',
          numberRange: 'FFM',
          countryCode: 'DE',
          artistName: '',
          idNumber: '',
        };
      case 'AB':
        return {
          salutation: 'Herrn',
          name: 'Oliver Peters',
          name2: 'NXT-LVL.INK Aschaffenburg',
          street: 'Seestraße 3',
          postalCode: '63741',
          city: 'Aschaffenburg',
          customerNumber: 'S00002',
          numberRange: 'AB',
          countryCode: 'DE',
          artistName: '',
          idNumber: '',
        };
      case 'NU':
        return {
          salutation: 'Herrn',
          name: 'Oliver Peters',
          name2: 'NXT-LVL.INK Nürnberg',
          street: 'Freystädter Str. 137',
          postalCode: '90475',
          city: 'Nürnberg',
          customerNumber: 'S00003',
          numberRange: 'NU',
          countryCode: 'DE',
          artistName: '',
          idNumber: '',
        };
    }

  }
}
