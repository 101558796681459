import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {SocketService} from '../../services/socket/socket.service';
import {ObjectGroupTools} from '../../common-browser/helpers/object-group.tools';
import {NxtFieldType} from '../../common-interfaces/nxt-field.interface';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {DateRangePickerComponent} from '../form-controls/date-range-picker/date-range-picker.component';
import {toObservable} from '@angular/core/rxjs-interop';
import {DialogService} from '../../services/dialog.service';
import {MoneyPipe} from '../../pipes/money.pipe';

export type DiscountGiftCardItem = {
  name: string;
  openValue: number;
  totalValue: number;
  paymentValue: number;
  discountPercentage: number;
  info: string;
  createdAt: number;
  shopOrderId: string;
  type: string;
};

@Component({
    selector: 'nxt-discount-gift-cards',
    templateUrl: './discount-gift-cards.component.html',
    styleUrls: ['./discount-gift-cards.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NxtDatagridComponent,
        SlideToggleComponent,
        DateRangePickerComponent,
        MoneyPipe
    ]
})

export class DiscountGiftCardsComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  totalValue = signal(0);
  openValue = signal(0);
  paymentValue = signal(0);
  discountGiftCardItems = signal<DiscountGiftCardItem[]>([]);
  fromTill = signal({from: '', till: ''});
  show20Percentage = signal(true);
  show40Percentage = signal(true);
  show50Percentage = signal(true);
  showDaySessionCards = signal(true);

  /*** Injections ***/
  socketService = inject(SocketService);
  dialogService = inject(DialogService);


  columnDefs: NxtColDef<DiscountGiftCardItem>[] = [
    {headerName: 'Gesamtwert', field: 'totalValue', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Bezahlt', field: 'paymentValue', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Typ', field: 'type', nxtFieldType: NxtFieldType.Text},
    {headerName: 'Rabatt', field: 'discountPercentage', nxtFieldType: NxtFieldType.Percentage},
    {headerName: 'nicht zugewiesen', field: 'openValue', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Erstellt', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDate},
    {headerName: 'Info', field: 'info'},
    {headerName: 'Shop-kauf', field: 'shopOrderId', nxtOnCellClicked: (params) => this.showShopOrder(params.data.shopOrderId)},
  ];


  constructor() {
    super();
    toObservable(this.show20Percentage).subscribe(() => this.load());
    toObservable(this.show40Percentage).subscribe(() => this.load());
    toObservable(this.show50Percentage).subscribe(() => this.load());
    toObservable(this.showDaySessionCards).subscribe(() => this.load());
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  async load() {
    if (!this.fromTill().from || !this.fromTill().till) {
      return;
    }
    const result: DiscountGiftCardItem[] = [];
    const giftCards = await this.socketService.getDiscountedOrDaySessionGiftCards(this.fromTill().from, this.fromTill().till);
    // const groupedGiftCards = ObjectGroupTools.groupObjectArray(giftCards, 'name');
    const groupedGiftCards = ObjectGroupTools.reGroupObjectArray(giftCards, 'name');
    for (const groupedGiftCard of groupedGiftCards) {
      const name = groupedGiftCard.value;
      const parent = groupedGiftCard.list.find(g => !g.originalId);
      const openValue = groupedGiftCard.list.filter(o => !o.assigned).reduce((sum, o) => sum + o.value, 0);
      const totalValue = parent.originalValue;
      const paymentValue = parent.originalPaymentValue;
      const discountPercentage = parent.discountPercentage;
      const paymentMethod = parent.paymentMethod;
      const shopOrderId = parent.shopOrderId;
      const info = parent.additionalInfo;
      let type = discountPercentage + '%';
      /*if (!info) {
        info = parent.createSource + ': ' + parent.discountReason;
      }*/
      if (discountPercentage === 0) {
        if (totalValue === 550) {
          type = 'DaySessionCard';
        }
        if (totalValue === 1000) {
          type = 'DaySessionCard';
        }
      }
      const createdAt = parent.createdAt;

      const filterOk = (discountPercentage === 20 && this.show20Percentage()) || (discountPercentage === 40 && this.show40Percentage()) || (discountPercentage === 50 && this.show50Percentage()) || (parent.giftCardSubType === 'day-session' && this.showDaySessionCards());

      if (filterOk) {
        result.push({name, openValue, totalValue, info, paymentValue, discountPercentage, createdAt, shopOrderId, type});
      }
    }
    this.discountGiftCardItems.set(result.sortNumber('createdAt'));
    this.totalValue.set(result.reduce((sum, o) => sum + o.totalValue, 0));
    this.openValue.set(result.reduce((sum, o) => sum + o.openValue, 0));
    this.paymentValue.set(result.reduce((sum, o) => sum + o.paymentValue, 0));
  }

  fromTillChanged(fromTill: { from: string; till: string }) {
    this.fromTill.set(fromTill);
    this.load();
  }

  private showShopOrder(shopOrderId: string) {
    this.dialogService.showShopOrder.emit(shopOrderId);
  }
}
