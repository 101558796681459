import {Injectable} from '@angular/core';
import {WrongLoginGoogle} from '../../common-interfaces/security/wrong-login-google.interface';
import {HttpClient} from '@angular/common/http';
import Bowser from 'bowser';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  wrongLoginGoogle(data: WrongLoginGoogle) {
    setTimeout(async () => {
      try {
        data.browser = Bowser.getParser(window.navigator.userAgent);
        let url = 'https://ac.nxt-lvl.ink/wrong-login';
        if (window.location.hostname === 'localhost') {
          url = 'http://localhost:444/wrong-login';
        }
        await firstValueFrom(this.httpClient.post(url, data));
      } catch (err) {
      }
    });
  }
}
