<div class="flex flex-col">
  <div class="text-120 p-2">{{title()}}</div>
  <div class="w-[500px] h-[400px]">
    <nxt-datagrid
      [rowData]="costs()"
      [columnDefs]="columnDefs"
      [sizeColumnsToFit]="true"

    >
    </nxt-datagrid>
  </div>
  <div class="flex flex-row justify-around pt-3">
    <nxt-button (click)="closeClicked()">Schließen</nxt-button>
    <nxt-button *ngIf="canEdit()" (click)="addNewCostClicked()">Neue Ausgabe hinzufügen</nxt-button>
  </div>
</div>
