<div class="flex flex-col justify-center items-center p-4 gap-3">

  <div class="flex">
    <mat-button-toggle-group [(ngModel)]="filterMode" (ngModelChange)="filterModeChanged()">
      <mat-button-toggle value="code">Tattoo-Ticket Code</mat-button-toggle>
      <mat-button-toggle value="name">Kundenname</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="flex">
    @if (filterMode() === 'code') {
      <nxt-input class="w-[200px]" placeholder="Tattoo-Ticket Code" [(value)]="tattooTicketCode"></nxt-input>
    }
    @if (filterMode() === 'name') {
      <nxt-input class="w-[200px]" placeholder="Kundenname" [(value)]="tattooTicketName"></nxt-input>
    }
  </div>
  <div>
    <nxt-button (click)="findClicked()">Suchen</nxt-button>
  </div>
  @if (tattooTickets().length > 0) {
    <div class="flex h-[350px] w-full">
      <nxt-datagrid
        [columnDefs]="columnDefs"
        [rowData]="tattooTickets()"
      />
    </div>
  }
</div>
