@if (data) {
  <div class="error-modal" *ngIf="errorText">{{ errorText }}</div>
  <div id="data" class="flex flex-col fontsize5 wrapper borders" style="width:100%; height: 100%; border: 3px solid black">
    <div class="flex w-full flex-row" style="border: 3px solid yellow" [ngStyle]="{marginBottom: data.pageCountCurrent === 1 ? '2cm': '0.5cm'}">
      <div class="flex grow flex-col">
        <div class="flex grow">
          <img src="assets/logo/logo_black.svg" style="width: 6cm; height: fit-content" alt=""/>
        </div>
        <div *ngIf="data.pageCountCurrent === 1" fxFlex class="borders" style="border: 3px solid red;">
          <div>
            <div class="address-from">{{ getFrom() }}</div>
            <div class="address-to fontsize4">
              {{ getSalution(data.invoiceTo.salutation) }} {{ data.invoiceTo.name }}<br/>
              {{ data.invoiceTo.street }}<br/>
              {{ data.invoiceTo.countryCode }}-{{ data.invoiceTo.postalCode }} {{ data.invoiceTo.city }}<br/>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col">
        <div fxFlex class="borders" style="border: 3px solid orange; width:100%" fxLayoutAlign="end end" fxLayout="column">
          <div class="fontsize2">{{ data.invoiceTitle }}</div>
          <br/>
          <table class="header-data-table">
            <tr *ngIf="data.invoiceNumber">
              <!--<td>{{data.invoiceTitle === 'Rechnung' ? 'RE-Nr.:' : 'RE-Korrekturnr.:'}}</td>-->
              <td>{{ 'INVOICE_INVOICE_NO' | trans: lang }}&nbsp;&nbsp;&nbsp;</td>
              <td>{{ data.invoiceNumber }}</td>
            </tr>
            <tr>
              <td>{{ 'INVOICE_CUSTOMER_NO' | trans: lang }}</td>
              <td>{{ data.invoiceTo.customerNumber }}</td>
            </tr>
            <tr nxtPermission="IsMa" *ngIf="data.invoiceTo.idNumber">
              <td>{{ 'INVOICE_ID_NUMBER' | trans: lang }}</td>
              <td>{{ data.invoiceTo.idNumber }}</td>
            </tr>
            <tr>
              <td>{{ 'INVOICE_DATE' | trans: lang }}</td>
              <td>{{ data.invoiceDate | nxtDate: (lang === 'DE' ? 'dd.MM.yyyy' : 'dd/MM/yyyy') }}</td>
            </tr>
            <tr>
              <td>{{ 'INVOICE_PAGE' | trans: lang }}</td>
              <td>{{ data.pageCountCurrent }}/{{ data.pageCountTotal }}</td>
            </tr>
          </table>
        </div>
        <div *ngIf="data.pageCountCurrent === 1" class="flex" style="border: 3px solid brown; margin-top: 0.5cm">
          <table class="header-company-address-table">
            <tr>
              <td class="no-wrap">{{ configService.config.value.invoiceData.fromCompany }}</td>
            </tr>
            <tr *ngIf="configService.config.value.invoiceData.fromCompany2">
              <td>{{ configService.config.value.invoiceData.fromCompany2 }}</td>
            </tr>
            <tr>
              <td>{{ configService.config.value.invoiceData.fromStreet }}</td>
            </tr>
            <tr>
              <td>{{ configService.config.value.invoiceData.fromCountryCode }}
                -{{ configService.config.value.invoiceData.fromPostalCode }} {{ configService.config.value.invoiceData.fromCity }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div #overflowCheck class="grow o-hidden" style="border: 3px solid #2876ff">
      <table class="pos-table">
        <tr>
          <th>Pos.</th>
          <th>{{ 'INVOICE_POS_DESCRIPTION' | trans: lang }}</th>
          <th>{{ 'INVOICE_POS_AMOUNT' | trans: lang }}</th>
          <th>{{ 'INVOICE_POS_UNIT_PRICE' | trans: lang }} €</th>
          <th>{{ 'INVOICE_POS_TOTAL' | trans: lang }} €</th>
        </tr>
        <tr *ngFor="let pos of data.invoicePositions">
          <td class="cell-align-right" style="width: 7%">{{ pos.posNumber }}</td>
          <td style="width: 59%" [innerHTML]="pos.name | safeHtml"></td>
          <td class="cell-align-right" style="width: 10%">{{ pos.amount }}</td>
          <td class="cell-align-right" style="width: 12%">{{ pos.unitPrice | money }}</td>
          <td class="cell-align-right" style="width: 12%">{{ pos.amount * pos.unitPrice | money }}</td>
        </tr>
        <tr *ngIf="data.pageCountCurrent === data.pageCountTotal">
          <td colspan="4">
            <div>{{ 'INVOICE_TOTAL' | trans: lang }}
              <ng-container *ngIf="showTotalTaxInfo">*</ng-container>
            </div>
          </td>
          <td class="cell-align-right">{{ data.invoiceValueTotal | money }}</td>
        </tr>
      </table>
      <div class="mt-4 flex w-full flex-col items-center" style="border:4px solid #ff5555" *ngIf="data.pageCountCurrent === data.pageCountTotal">
        <div *ngIf="showTotalTaxInfo">
          {{ 'INVOICE_VAT_INFO' | trans: lang: vatInfoParams }}
        </div>

        <div class="w-fit pt-3 signing-field" *ngIf="showSigning" style="border: 3px solid red; margin-top: 2cm">
          <table>
            <tr>
              <td class="no-wrap" style="padding-right:0.7cm">{{ data.invoiceValueTotal | money }} {{ 'INVOICE_GET_IN_CASH' | trans: lang }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td style="border-top: 1px solid black; width: 7cm">{{ data.invoiceTo.name }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-around" style="font-size: 85%; color:#3f3f3f">
      <div>
        {{ configService.config.value.invoiceData.fromCompany }}
        <br/>
        {{ configService.config.value.invoiceData.fromStreet }}
        <br/>
        {{ configService.config.value.invoiceData.fromCountryCode }}
        -{{ configService.config.value.invoiceData.fromPostalCode }} {{ configService.config.value.invoiceData.fromCity }}
      </div>
      <div class="flex flex-col justify-end">
        @if (configService.config.value.invoiceData.fromVATNumber) {
          {{ 'INVOICE_VAT_NO' | trans: lang }}: {{ configService.config.value.invoiceData.fromVATNumber }}
        }
        <br/>
        @if (configService.config.value.invoiceData.fromTaxId) {
          {{ 'INVOICE_TAX_NO' | trans: lang }}: {{ configService.config.value.invoiceData.fromTaxId }}
        }
      </div>
    </div>
  </div>
}
