@if (originalEmployeeEvent) {
  <div>
    <div class="flex w-full flex-row justify-center">{{ originalEmployeeEvent.dateString | nxtDate: 'EEEE dd.MM.yyyy' }}</div>
    <div nxtPermission="IsJulian" class="flex w-full flex-row justify-center">{{ originalEmployeeEvent.id }}</div>
    <div class="flex flex-row">
      <nxt-autocomplete
        [nxtFormControl]="form.controls.employee"
        [filterFields]="['fullName', 'id']"
        [displayInOptionWith]="displayWithEmployee"
        [displayWith]="displayWithEmployee"
        [options]="employees | async"
      >
      </nxt-autocomplete>
    </div>
    <div class="mt-2 mb-5 flex flex-col items-center justify-center gap-3">
      <div>
        <mat-button-toggle-group [formControl]="form.controls.type">
          <mat-button-toggle value="work">Arbeit</mat-button-toggle>
          <mat-button-toggle value="vacation">Urlaub</mat-button-toggle>
          <mat-button-toggle value="notHere" class="leading-none">Frei</mat-button-toggle>
          <mat-button-toggle value="sick">Krank</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      @if (form.controls.type.value === 'work') {
        <div>
          @if (studioOptions().length > 1) {
            <mat-button-toggle-group [formControl]="form.controls.studio">
              @for (studioOption of studioOptions(); track studioOption.value) {
                <mat-button-toggle [value]="studioOption.value">{{ studioOption.text }}</mat-button-toggle>
              }
            </mat-button-toggle-group>
          }
        </div>
        <div class="pr-1">
          <mat-button-toggle-group [multiple]="true" [formControl]="form.controls.workplaces">
            @for (workplace of workplaceService.employeeWorkplaceOptions; track workplace.value) {
              <mat-button-toggle [value]="workplace.value">{{ workplace.text }}</mat-button-toggle>
            }
          </mat-button-toggle-group>
          <!--<mat-button-toggle-group [multiple]="true" [formControl]="form.controls.workplaces">
            <mat-button-toggle value="backoffice">Backoffice</mat-button-toggle>
            <mat-button-toggle value="whatsapp">WhatsApp</mat-button-toggle>
            <mat-button-toggle value="whatsappFranchise">WhatsApp Franchise</mat-button-toggle>
            <mat-button-toggle value="reception">Empfang</mat-button-toggle>
            <mat-button-toggle value="craft">Handwerk</mat-button-toggle>
            <mat-button-toggle value="piercing">Piercing</mat-button-toggle>
            <mat-button-toggle value="eventRating">Terminbewertung</mat-button-toggle>
            <mat-button-toggle value="fieldService">Außendienst</mat-button-toggle>
          </mat-button-toggle-group>-->
        </div>
      }
    </div>
    <div>
      <nxt-input placeholder="Info" [nxtFormControl]="form.controls.info"></nxt-input>
    </div>
    <div class="flex w-full flex-col justify-around">
      <div class="flex flex-row justify-around">
        <nxt-time-picker
          [nxtFormControl]="form.controls.startTime"
        ></nxt-time-picker>
        @for (timeButton of timeButtons(); track timeButton) {
          <nxt-button [smallButton]="true" (click)="setTime(timeButton.fromTime, timeButton.tillTime)">{{ timeButton.text }}</nxt-button>
        }
        <nxt-time-picker
          [nxtFormControl]="form.controls.endTime"
        ></nxt-time-picker>
      </div>
      @if (originalEmployeeEvent && !originalEmployeeEvent.id) {
        <div class="flex w-full flex-row justify-center gap-4 pt-3">
          <nxt-checkbox label="Mo" [(value)]="selectedDates[0]"></nxt-checkbox>
          <nxt-checkbox label="Di" [(value)]="selectedDates[1]"></nxt-checkbox>
          <nxt-checkbox label="Mi" [(value)]="selectedDates[2]"></nxt-checkbox>
          <nxt-checkbox label="Do" [(value)]="selectedDates[3]"></nxt-checkbox>
          <nxt-checkbox label="Fr" [(value)]="selectedDates[4]"></nxt-checkbox>
          <nxt-checkbox label="Sa" [(value)]="selectedDates[5]"></nxt-checkbox>
          <nxt-checkbox label="So" [(value)]="selectedDates[6]"></nxt-checkbox>
        </div>
      }
    </div>
    <div class="mt-5 flex flex-row justify-between gap-3">
      <nxt-button (click)="closeClicked()">Abbrechen</nxt-button>
      <nxt-button (click)="deleteClicked()">Löschen</nxt-button>
      <nxt-button (click)="saveClicked()">Speichern</nxt-button>
    </div>
  </div>
}
