import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, signal, SimpleChanges} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {NgIf} from '@angular/common';
import {DialogService} from '../../../services/dialog.service';
import {TypeTools} from '../../../common-browser/helpers/type.tools';
import {NxtKlarnaOrder} from '../../../common-interfaces/nxt.klarna-order.interface';
import {PermissionService} from '../../../services/permission.service';
import {NxtPermissionId} from '../../../common-interfaces/nxt.user.interface';
import {NxtShopOrder} from '../../../common-interfaces/nxt.shop-order.interface';
import {TattooTicketComponent} from '../tattoo-ticket.component';
import {MatDialogRef} from '@angular/material/dialog';
import {toObservable} from '@angular/core/rxjs-interop';
import {first} from 'rxjs';

@Component({
  selector: 'nxt-tattoo-ticket-klarna',
  templateUrl: './tattoo-ticket-klarna.component.html',
  styleUrls: ['./tattoo-ticket-klarna.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtButtonComponent, NgIf, TattooTicketComponent]
})

export class TattooTicketKlarnaComponent extends NxtComponent implements OnInit, NxtOnDestroy, OnChanges {
  /*** Inputs ***/
  @Input({required: true}) shopOrderLineItemId = '';

  /*** Outputs ***/
  @Output() showEvent = new EventEmitter<string>();
  @Output() showShopOrder = new EventEmitter<string>();

  /*** Signals ***/
  shopOrder = signal<NxtShopOrder | null>(null);
  refundValue = signal(0);
  refundValue$ = toObservable(this.refundValue);
  firstParent = signal<NxtKlarnaOrder>(null);
  ordersInAndOut = signal<NxtKlarnaOrder[]>([]);
  isDialog = false;
  fromEventId = '';

  constructor(
    @Optional() public dialogRef: MatDialogRef<TattooTicketKlarnaComponent>,
    private socketService: SocketService,
    private dialogService: DialogService,
    private permissionService: PermissionService
  ) {
    super();
    this.pushSocketSubscription = this.socketService.subscribeNew('eventRefundCreated', () => {
      this.load().then();
    });
  }

  ngOnInit() {
    this.isDialog = this.dialogRef?.componentInstance === this;
    this.pushSocketSubscription = this.socketService.subscribeNew('eventKlarnaOrderOutChanged', (klarnaOrder) => {
      if (this.firstParent().klarnaOrderId === klarnaOrder.klarnaOrderId) {
        this.load().then();
      }
    });
    this.pushSocketSubscription = this.socketService.subscribeNew('eventShopOrderUpdated', (shopOrder) => {
      if (this.firstParent().shopOrderId === shopOrder.id) {
        this.load().then();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shopOrderLineItemId) {
      this.load().then();
    }
  }

  nxtOnDestroy() {
  }


  async load() {
    const data = await this.socketService.getKlarnaTattooTicketForView(this.shopOrderLineItemId);
    if (data?.main) {
      this.ordersInAndOut.set([data.main, ...data.splitted, ...data.out]);
      this.firstParent.set(data.main);
      this.refundValue.set(data.refundValue);
      this.shopOrder.set(data.shopOrder);
    }
  }

  async refundClicked() {
    if (!this.permissionService.hasPermissionWithInfo(NxtPermissionId.CanRefund)) {
      return;
    }
    const maxRefund = this.firstParent().originalPaymentValue - this.refundValue();
    let message = '<table class="nxt-table w-full"><tr></tr>';
    message += '<tr><td>Bereits erstattet</td><td>' + this.refundValue().toMoneyString() + '</td>';
    message += '<tr><td>Maximaler Erstattungsbetrag</td><td>' + maxRefund.toMoneyString() + '</td>';
    message += '</table>';
    const title = 'Tattoo-Ticket erstatten';
    if (maxRefund === 0) {
      await this.dialogService.showOk(message, {title});
    } else {
      const valueToRefund = await this.dialogService.showInput(message, {
        title,
        isMoney: true,
        prompt: maxRefund,
        placeholder: 'Wie viel soll erstattet werden?',
        selectPrompt: true
      });
      if (TypeTools.isNumber(valueToRefund)) {
        if (valueToRefund > maxRefund) {
          await this.dialogService.showOk('Du kannst nur maximal ' + maxRefund.toMoneyString() + ' zurück zurückerstatten');
          return;
        }
        const message = 'Kurzer Grund zur Rückerstattung von ' + valueToRefund.toMoneyString();
        const refundReason = await this.dialogService.showInput(message);
        if (refundReason) {
          this.dialogService.showLoading('Erstattung wird durchgeführt');
          this.socketService.klarnaRefundShopOrderItem({
            refundValue: valueToRefund,
            reason: refundReason,
            refType: 'klarna-order',
            refId: this.firstParent().orderId,
            shopOrderLineItemId: this.firstParent().shopOrderLineItemId
          }).then();
          this.hideLoadingOnRefundReceived();
        }
      }
    }
  }

  private hideLoadingOnRefundReceived() {
    const currentRefundValue = this.refundValue();
    this.refundValue$.pipe(first((value) => value > currentRefundValue)).subscribe(() => {
      this.dialogService.hideLoading();
    });
  }
}
