<div class="nxt-page" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="center center" class="gap-4">
    <nxt-input [disabled]="status?.isRunning" fxFlex="300px" placeholder="Broadcast-Name" [(value)]="broadcastId"></nxt-input>
    <nxt-input [disabled]="status?.isRunning" fxFlex="300px" placeholder="Message-Id" [(value)]="messageId"></nxt-input>
    <nxt-button [disabled]="!broadcastId || !messageId || status?.isRunning" (click)="loadBroadcast(false)">Lade Broadcast</nxt-button>
    <nxt-button [disabled]="!broadcastId || !messageId || status?.isRunning" (click)="loadBroadcast(true)">Lade Broadcast mit Kontakten</nxt-button>
    <nxt-button *ngIf="message" [disabled]="status && (!broadcastId || !messageId || status.isRunning)" (click)="start()">START</nxt-button>
    <nxt-button *ngIf="status?.isRunning" (click)="abort()">Abbrechen</nxt-button>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" class="pb-3 gap-4">
    <nxt-slide-toggle [disabled]="status?.isRunning" leftText="TEST" [(value)]="live">LIVE</nxt-slide-toggle>
    <nxt-slide-toggle *ngIf="!unknownChats" [disabled]="status?.isRunning" [(value)]="customFilter.use">CUSTOM!</nxt-slide-toggle>
    <nxt-slide-toggle *ngIf="!unknownChats" [disabled]="status?.isRunning" [(value)]="weekly">WEEKLY</nxt-slide-toggle>
    <nxt-slide-toggle [disabled]="status?.isRunning" [(value)]="unknownChats" (valueChange)="unknownChatsChanged()">Unbekannte Chats</nxt-slide-toggle>
    <nxt-slide-toggle *ngIf="!unknownChats" [disabled]="status?.isRunning" [(value)]="sortMostEvents">Kunden mit den meist geschlossenen zu erst
    </nxt-slide-toggle>
    <nxt-input [bottomNoPadding]="true" fxFlex="200px" [isNumber]="true" placeholder="Wartezeit" [(value)]="sleepBetweenMessages"
               (valueChange)="sleepBetweenMessagesChanged()"></nxt-input>
    <nxt-input [clearIcon]="true" [bottomNoPadding]="true" fxFlex="100px" [isTimePicker]="true" placeholder="Stop" [(value)]="stopTime"></nxt-input>
  </div>

  <div *ngIf="customFilter.use" fxLayout="column" class="p-2 gap-4" fxLayoutAlign="center center">
    <div class="flex flex-row gap-4">
      <nxt-slide-toggle [(value)]="customFilter.hadEvents">Hat Termin(e)</nxt-slide-toggle>
      <!--<div class="no-wrap">Hat Termine</div>-->
      <ng-container *ngIf="customFilter.hadEvents">
        <nxt-date-picker fxFlex="200px" class="date-picker--bottom-no-padding" placeholder="start" [(nxtModel)]="customFilter.eventFrom"></nxt-date-picker>
        <nxt-date-picker fxFlex="260px" class="date-picker--bottom-no-padding" [showJumpDayButtons]="true" placeholder="end"
                         [(nxtModel)]="customFilter.eventTill"></nxt-date-picker>
        <nxt-input fxFlex="150px" [bottomNoPadding]="true" placeholder="Min. Preis" [isMoney]="true" [(value)]="customFilter.minPriceEstimatedFrom"></nxt-input>
        <nxt-input fxFlex="150px" [bottomNoPadding]="true" placeholder="Max. Preis" [isMoney]="true" [(value)]="customFilter.maxPriceEstimatedFrom"></nxt-input>
        <nxt-input style="width: 120px" [(value)]="customFilter.artistCalendarNumber" placeholder="Artist-Kalender-Nummer"></nxt-input>
      </ng-container>
    </div>
    <div class="flex flex-row gap-3">
      <nxt-slide-toggle [(value)]="customFilter.noFixArtist">keine Fix-Artists</nxt-slide-toggle>

      <nxt-slide-toggle [(value)]="customFilter.invertResult">Filter invertieren</nxt-slide-toggle>
      <nxt-slide-toggle [(value)]="customFilter.noEventsInFuture">Keine zukünftigen Termine</nxt-slide-toggle>
      <nxt-slide-toggle [(value)]="customFilter.malleImport">Malleimport</nxt-slide-toggle>
      <nxt-autocomplete fxFlex="150px" placeholder="Kundensprache" valueField="code" [options]="languages" [displayWith]="DisplayWithTools.displayWithName"
                        [displayInOptionWith]="DisplayWithTools.displayWithName" [(value)]="customFilter.customerLanguage"></nxt-autocomplete>
    </div>
  </div>

  <div fxLayout="column" *ngIf="alreadySentCount > -1">
    <table class="nxt-table mb-1">
      <tr>
        <th>Status</th>
        <th>Gesamt</th>
        <th>Verschickt</th>
        <th>Offen</th>
        <th>Fortschritt</th>
        <th>Restdauer</th>
      </tr>
      <tr>
        <td>{{ status?.isRunning ? 'läuft' : 'läuft nicht' }}</td>
        <td>{{ alreadySentCount + toSendCount }}</td>
        <td>{{ alreadySentCount }}</td>
        <td>{{ toSendCount }}</td>
        <td>{{ sentPercentage }} %</td>
        <td>{{ restDuration | duration: 'HH [Std.] mm [Min.]' }}</td>
      </tr>
    </table>
  </div>

  <div fxFlex fxLayout="row" class="o-hidden">

    <div fxFlex="30%">
      <nxt-datagrid
        class="w-full h-100"
        [columnDefs]="columnDefsContacts"
        [rowData]="openContacts"
      >
      </nxt-datagrid>
    </div>
    <div fxFlex="70%">
      <nxt-datagrid
          #statusGrid
          class="w-full h-100"
          [columnDefs]="columnDefs"
          [rowData]="statusStack"
          uniqueRowDataKey="mobile"
      >
      </nxt-datagrid>
    </div>
    <div class="o-auto" fxFlex="30%" *ngIf="message" fxLayout="column" fxLayoutAlign="start center">
      <img *ngIf="imageSrc" style="width: 50%; padding-bottom: 8px" [src]="imageSrc"/>
      <div [innerHTML]="message.body | safeHtml"></div>
    </div>
  </div>

</div>
