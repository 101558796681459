import {Component, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {SocketInterfaceResponse} from '../../../common-interfaces/socket/socket-interface';
import {BodyPutService} from '../../../services/body-put.service';
import {DialogService} from '../../../services/dialog.service';
import {PriceBuilderPhotoViewerComponent} from './price-builder-photo-viewer/price-builder-photo-viewer.component';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf} from '@angular/common';

@Component({
    selector: 'nxt-price-builder-pre-image',
    templateUrl: './price-builder-pre-image.component.html',
    styleUrls: ['./price-builder-pre-image.component.scss'],
    imports: [NgIf, FlexModule, NgFor, NxtButtonIconComponent, SafeHtmlPipe]
})

export class PriceBuilderPreImageComponent extends NxtComponent implements OnInit, NxtOnDestroy {


  constructor(
    private socketService: SocketService,
    private bodyPutService: BodyPutService,
    private dialogService: DialogService
  ) {
    super();
  }

  public imgSrc = '';
  data: SocketInterfaceResponse.GetPriceBuilderRandomPrePhotos;
  private photosStack: SocketInterfaceResponse.GetPriceBuilderRandomPrePhotos[] = [];


  currentIndex = -1;

  nextIsRunning = false;
  public title = 'hallo';

  ngOnInit() {
    this.next();
  }

  async loadNewPhotos() {
    const newPhotos = await this.socketService.getPriceBuilderRandomPrePhotos();
    // newPhotos.photos = [...newPhotos.photos, ...newPhotos.photos, ...newPhotos.photos, ...newPhotos.photos, ...newPhotos.photos];
    this.photosStack.push(newPhotos);
    this.shrinkStack();
  }

  nxtOnDestroy() {
  }


  prev() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.setCurrentIndex();
    }
  }

  async next() {
    if (!this.nextIsRunning) {
      this.nextIsRunning = true;
      this.currentIndex++;
      if (this.currentIndex > this.photosStack.length - 1) {
        await this.loadNewPhotos();
      }
      this.setCurrentIndex();
      this.nextIsRunning = false;
    }
  }

  public shrinkStack() {
    if (this.photosStack.length > 10) {
      const delCount = this.photosStack.length - 10;
      this.photosStack.splice(0, delCount);
      this.currentIndex -= delCount;
      this.setCurrentIndex();
    }
  }

  setCurrentIndex() {
    this.data = this.photosStack[this.currentIndex];
    this.setInfo();
  }

  private setInfo() {
    const bodyPuts: string[] = [];
    this.title = '';
    for (const bodyPut of this.data.event.bodyPuts.tattoo) {
      bodyPuts.push(bodyPut.motive + ' auf ' + this.bodyPutService.getGermanPath(bodyPut.bodyPut));
    }
    this.title = bodyPuts.join('\n') + '\n' + this.data.event.paymentSum.toMoneyString();
  }

  public async photoClicked(photo: SocketInterfaceResponse.EventPhoto) {
    const dialog = this.dialogService.showComponentDialog(PriceBuilderPhotoViewerComponent);
    dialog.componentInstance.data = this.data;
    dialog.componentInstance.photo = photo;
  }
}
