import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DurationTools} from '../common-browser/helpers/duration.tools';

@Pipe({
  name: 'durationFromNowOnlyPast',
  standalone: true,
})
export class DurationFromNowOnlyPastPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, withoutSeconds = true): string {
    return DurationTools.format2((value - Date.now()).abs() * -1, {withoutSeconds});
  }
}
