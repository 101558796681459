<div>
  <div>Preistext</div>
  <div class="flex flex-row">
    <nxt-input #originalPreisInput
               [isMoney]="true"
               [selectOnFocus]="true"
               [(value)]="originalPrice"
               (up)="roundUpClicked()"
               (down)="roundDownClicked()"
               (enter)="originalPriceOnEnter()"
               (valueChange)="originalPriceChanged()" placeholder="Original-Preis">
    </nxt-input>
    <div class="flex flex-column">
      <nxt-button-icon (click)="roundUpClicked()">arrow_upward</nxt-button-icon>
      <nxt-button-icon (click)="roundDownClicked()">arrow_downward</nxt-button-icon>
    </div>
  </div>

  <nxt-input [isMoney]="true" [selectOnFocus]="true" [selectOnInit]="true" #priceInput [(value)]="price" (enter)="priceOnEnter()" (valueChange)="priceChanged()"
             placeholder="Preis">
  </nxt-input>


  <div class="text-center" *ngIf="realPercentage === -1">Nachlass: ? (F8)</div>
  <div class="text-center" *ngIf="realPercentage > -1">Nachlass: {{realPercentage}}% (F8)</div>

  <div class="flex flex-col items-center">
    <nxt-slide-toggle class="pb-2 d-flex" [(value)]="aboutPrice" (valueChange)="aboutPriceChanged()">Ca. Preis (F7)</nxt-slide-toggle>

    <nxt-radio [options]="percentageOptions" valueKey="value" [(value)]="percentage" (valueChange)="percentageChanged()"></nxt-radio>

    <nxt-textarea [rows]="6" class="flex w-96" [(value)]="text"></nxt-textarea>
  </div>

  <div class="flex row justify-around">
    <nxt-button (click)="okClicked()">OK</nxt-button>
    <nxt-button (click)="closeClicked()">Abbrechen</nxt-button>
  </div>
</div>
