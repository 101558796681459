<div class="flex flex-col h-full w-full" style="border:0px solid red;">
  <div class="flex flex-row" style="border:0px solid green">
    <ng-content select="nxt-page-header"/>
  </div>
  <div class="nxt-page-content-wrapper flex flex-col flex-grow h-full overflow-auto" style="border:0px solid blue">
    <ng-content select="nxt-page-content"/>
  </div>
  <div class="flex flex-col w-full" style="border:0px solid yellow">
    <ng-content select="nxt-page-footer"/>
  </div>
</div>
