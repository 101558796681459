<div class="w-full h-100 p-3" *ngIf="data">
  <div class="p-3" style="font-size: 150%; text-align: center; border: 1px solid black">{{data.studio | uppercase}} <small>{{data.date | nxtDate: 'EEEE dd.MM.yyyy'}}</small></div>
  <table class="w-full">
    <tr>
      <th>Bezeichnung</th>
      <th>Menge</th>
    </tr>
    <tr *ngFor="let line of data.lines">
      <td>{{line.name}}</td>
      <td style="text-align: center;">{{line.amount}}</td>
    </tr>
  </table>
</div>
