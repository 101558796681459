<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>Artist-Skills anpassen</nxt-page-header-title>
    <div class="flex flex-col">
      <div class="background-green" style="color:white;">Grüner Hintergrund = Artist Skill soll hoch</div>
      <div class="green">Grüne Schrift = Artist Skill evtl. hoch</div>
      <div class="red">Rote Schrift = Artist Skill evtl. runter</div>
      <div class="background-red" style="color:white;">Roter Hintergrund = Artist Skill soll runter</div>
    </div>
    <nxt-slide-toggle [(value)]="showOnlyTodos" placeholder="Nur soll Anpassungen"></nxt-slide-toggle>
  </nxt-page-header>
  <nxt-page-content>
    @if (this.rowData().length === 0) {
      <div class="flex h-full w-full items-center justify-center">
        <nxt-spinner></nxt-spinner>
      </div>
    } @else {
      <nxt-datagrid
        uniqueRowDataKey="skill"
        [columnDefs]="pivotColumnDefs()"
        [rowData]="pivotData()"
      >
      </nxt-datagrid>
    }
  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>
