<div class="nxt-page w-full h-100" fxLayout="column" fxLayoutAlign="center center" *ngIf="data">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxLayoutAlign="center center" class="text-150 py-4" style="text-align: center" [innerHTML]="title | safeHtml">
    </div>
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="center center" class="w-full  o-hidden">
    <div fxLayout="column" fxLayoutAlign="start center" class="scrollable max-h-100">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayout="row wrap">
        <div *ngFor="let photo of data.photos">
          <div class="img-wrapper mouse-pointer" (click)="photoClicked(photo)" >
            <img class="preview-image" [src]="'data:image/jpeg;base64,' + photo.base64"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" class="p-4">
    <nxt-button-icon [size]="40" (click)="prev()">arrow_back_ios_new</nxt-button-icon>
    <div style="width: 200px;">&nbsp;</div>
    <nxt-button-icon [size]="40" (click)="next()">arrow_forward_ios</nxt-button-icon>
  </div>
</div>
