/* tslint:disable:no-non-null-assertion */
import {AfterViewInit, ChangeDetectorRef, Component, computed, ElementRef, EventEmitter, OnDestroy, OnInit, Optional, signal, ViewChild} from '@angular/core';
import {NxtComponent} from '../../../components/nxt.component';
import {NxtArtist} from '../../../common-interfaces/nxt.artist.interface';
import {MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {LoginService} from '../../../services/login.service';
import {PermissionService} from '../../../services/permission.service';
import {SocketService} from '../../../services/socket/socket.service';
import {TelegramService} from '../../../services/telegram.service';
import {DialogService, LoadingId, OpenerComponent} from '../../../services/dialog.service';
import {KeyCode, ShortcutService} from '../../../services/shortcut.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ClipboardService} from '../../../services/clipboard.service';
import {RouteService} from '../../../services/route.service';
import {BodyPutService} from '../../../services/body-put.service';
import {AppointmentConfirmationService} from '../../../services/appointment-confirmation.service';
import {ContactService} from '../../../services/contact.service';
import {CacheService} from '../../../services/cache/cache.service';
import {ConfigService} from '../../../services/config.service';
import {WindowService} from '../../../services/window.service';
import {LabelPrintService} from '../../../services/label-print.service';
import {NxtCalendarEvent, NxtCalendarEventFile, NxtCalendarEventPriceChange, NxtCalendarEventToPayOnEventDate} from '../../../common-interfaces/nxt.calendar-event.interface';
import {NxtReminder} from '../../../common-interfaces/nxt.reminder.interface';
import {SocketInterfaceResponse} from '../../../common-interfaces/socket/socket-interface';
import {NxtNewEventData} from '../../../services/calendar-event.service';
import {BehaviorSubject, filter, first, firstValueFrom, Subscription} from 'rxjs';
import {InputComponent} from '../../../components/form-controls/input/input.component';
import {AutocompleteComponent} from '../../../components/form-controls/autocomplete/autocomplete.component';
import {DatePickerComponent} from '../../../components/form-controls/date-picker/date-picker.component';
import {TimePickerComponent} from '../../../components/form-controls/time-picker/time-picker.component';
import {PaymentsComponent} from '../payments/payments.component';
import {CalendarEventEditDisplayFn} from '../calendar-event-edit.display-fn';
import {NxtImage} from '../../../common-interfaces/nxt.image';
import {NxtKlarnaOrder} from '../../../common-interfaces/nxt.klarna-order.interface';
import {NxtPaymentPossibilityRecord} from '../../../common-interfaces/nxt.payment-possibility-record.interface';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {NxtContact, NxtContactPartial} from '../../../common-interfaces/nxt.contact.interface';
import {ContactFormComponent} from '../../../components/contact-form/contact-form.component';
import {IframeMessageManagerInIframe, IframeParentCommand} from '../../../services/iframe-message-manager-in-iframe';
import {SearchComponent} from '../../search/search.component';
import {Log} from '../../../common-browser/log/log.tools';
import {NxtPayment, PaymentMethod} from '../../../common-interfaces/nxt.payment.interface';
import {Payment} from '../../../classes/payment';
import {NxtFormControl} from 'src/app/nxt-form/nxt.form-control';
import {StudioRegionBrowserTools} from 'src/app/common-browser/helpers/studio-region-browser.tools';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {NxtPermissionId} from '../../../common-interfaces/nxt.user.interface';
import {DurationLogger} from '../../../common-browser/helpers/duration-logger';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {UuidTools} from '../../../common-browser/helpers/uuid.tools';
import {TimeoutTools} from '../../../common-browser/helpers/timeout.tools';
import {JsonTools} from '../../../common-browser/helpers/json.tools';
import {ErrorTools, NxtErrorId} from '../../../common-browser/helpers/error.tools';
import {MathTools} from '../../../common-browser/helpers/math.tools';
import {StringTools} from '../../../common-browser/helpers/string.tools';
import {clone, keys, ObjectTools} from '../../../common-browser/helpers/object.tools';
import {FormTools} from '../../../services/form.tools';
import {PublicHolidaysTools} from '../../../common-browser/helpers/public-holidays.tools';
import {PaymentTools, PaymentTypes} from '../../../common-browser/helpers/payment.tools';
import {DecimalTools} from '../../../common-browser/helpers/decimal.tools';
import {TypeTools} from '../../../common-browser/helpers/type.tools';
import {ArtistsTools} from '../../../common-browser/helpers/artists.tools';
import {BirthdayTools} from '../../../common-browser/helpers/birthday.tools';
import {WorkingDayTools} from '../../../common-browser/helpers/working-day.tools';
import {EventCalcTools} from '../../../common-browser/helpers/event-calc.tools';
import {EventFinderComponent, EventFinderDialogResult} from '../../../components/event-finder/event-finder.component';
import {PriceInputComponent} from '../price-input/price-input.component';
import * as QRCode from 'qrcode';
import moment from 'moment';
import {OtherAvailableArtistComponent} from '../other-available-artist/other-available-artist.component';
import {ArtistPhotoUnknownUploadsComponent} from '../../../components/artist-photo-unknown-uploads/artist-photo-unknown-uploads.component';
import {SkillSelectComponent} from '../skill-select/skill-select.component';
import {ArtistSkillTools} from '../../../common-browser/helpers/artist-skill.tools';
import {EventTools} from '../../../common-browser/helpers/event.tools';
import {MobileTools} from '../../../common-browser/helpers/mobile.tools';
import {Clipboard} from '@angular/cdk/clipboard';
import {CalendarEventHistoryComponent} from '../../../components/history/calendar-event-history/calendar-event-history.component';
import {CalendarEventEditPriceTextPipe} from '../pipes/calendar-event-edit-price-text.pipe';
import {PermissionPipe} from '../../../pipes/permission.pipe';
import {FromNowPipe} from '../../../pipes/from-now.pipe';
import {JsonFormattedPipe} from '../../../pipes/json-formatted-pipe';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {SafeUrlPipe} from '../../../pipes/safe-url.pipe';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {CheckboxComponent} from '../../../components/form-controls/checkbox/checkbox.component';
import {RateIconsComponent} from '../../../components/event-finder/rate-icons/rate-icons.component';
import {SmoothHeightComponent} from '../../../components/smooth-height.component';
import {SlideToggleComponent} from '../../../components/form-controls/slide-toggle/slide-toggle.component';
import {MultiClickDirective} from '../../../directives/multi-click.directive';
import {DividerComponent} from '../../../controls/divider/divider.component';
import {ColComponent} from '../../../controls/nxt-grid/col/col.component';
import {MatCard} from '@angular/material/card';
import {MatTooltip} from '@angular/material/tooltip';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {AsyncPipe, KeyValuePipe, NgClass, NgFor, NgIf, NgStyle, UpperCasePipe} from '@angular/common';
import {PermissionDirective} from '../../../directives/permission.directive';
import {NxtLog} from '../../../common-browser/log/nxt-log';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {NxtPaypalTransaction} from '../../../common-interfaces/nxt-paypal-transaction';
import {ContactParserComponent, ContactParserComponentDialogClose} from '../../../components/contact-parser/contact-parser.component';
import {IconTools} from '../../../common-browser/helpers/icon.tools';
import {ContactTools} from '../../../common-browser/helpers/contact.tools';
import {WalkInService} from '../../../services/walk-in.service';
import {ReminderService} from '../../../services/reminder.service';
import {WorkingDayService} from '../../../services/working-day.service';
import {DatePicker2Component} from '../../../components/form-controls/date-picker-2/date-picker-2.component';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {ArtistEditComponent} from '../../artists/artist-edit/artist-edit.component';
import {MatCheckbox} from '@angular/material/checkbox';
import {PhotoSwipeDirective} from '../../../directives/photo-swipe/photo-swipe.directive';
import {MatIcon} from '@angular/material/icon';
import {TattooTemplateService} from '../../../services/tattoo-template.service';
import {NxtTattooTemplate} from '../../../common-interfaces/tattoo-template.interface';
import {DynamicSizeDirective} from '../../../directives/dynamic-size.directive';
import {map} from 'rxjs/operators';
import {ContextMenuComponent} from '../../../components/context-menu/context-menu.component';
import {MatMenuItem} from '@angular/material/menu';
import {DragScrollDirective} from '../../../directives/drag-scroll.directive';
import {EventFileTools} from '../../../common-browser/helpers/event-file.tools';
import {NxtDriveFileSubTypePipe} from '../../../pipes/nxt-drive-file-sub-type-pipe';
import {NxtPhotoTypePipe} from '../../../pipes/nxt-photo-type-pipe';
import {EventConsentTools} from '../../../common-browser/helpers/event-consent.tools';
import {DriveTools} from '../../../common-browser-public/helpers/drive.tools';
import {NxtEventFilePipe} from '../../../pipes/nxt-event-file.pipe';
import {VideoComponent} from '../../../components/video/video.component';
import {NxtDriveFileSubType} from '../../../common-interfaces/drive-file.interface';
import {EventFilesClipboardComponent} from '../../../components/event-files-clipboard/event-files-clipboard.component';
import {ThermalPrinterService} from '../../../services/thermal-printer.service';
import Slide from 'photoswipe/dist/types/slide/slide';
import {PhotoEditorComponent} from '../../../components/photos/photo-editor/photo-editor.component';
import {TimeTools} from '../../../common-browser/helpers/time.tools';
import {NxtDiscountPromotion} from '../../../common-interfaces/discount-promotion.interface';
import {WhatsappChatComponent} from '../../../components/whatsapp/whatsapp-chat/whatsapp-chat.component';
import {BodyPutTools} from '../../../common-browser/helpers/body-put.tools';


interface LoadEventParams {
  eventId?: string;
  newEventData?: NxtNewEventData;
  calendarEvent?: NxtCalendarEvent;
}

@Component({
  selector: 'nxt-calendar-event-edit-2',
  templateUrl: './calendar-event-edit-2.component.html',
  styleUrls: ['./calendar-event-edit-2.component.scss'],
  imports: [PermissionDirective, NgIf, NxtButtonComponent, NgClass, ExtendedModule, FlexModule, FormsModule, ReactiveFormsModule, NgStyle, NxtButtonIconComponent, MatTooltip, MatCard, ColComponent, NgFor, DividerComponent, InputComponent, MultiClickDirective, AutocompleteComponent, DatePickerComponent, TimePickerComponent, SlideToggleComponent, SmoothHeightComponent, RateIconsComponent, PaymentsComponent, CheckboxComponent, NxtDatePipe, KeyValuePipe, SafeHtmlPipe, SafeUrlPipe, MoneyPipe, JsonFormattedPipe, FromNowPipe, PermissionPipe, CalendarEventEditPriceTextPipe, NxtDatePipe, AsyncPipe, DatePicker2Component, MatButtonToggle, MatButtonToggleGroup, MatCheckbox, UpperCasePipe, PhotoSwipeDirective, MatIcon, DynamicSizeDirective, ContextMenuComponent, MatMenuItem, DragScrollDirective, NxtDriveFileSubTypePipe, NxtPhotoTypePipe, NxtEventFilePipe],
  standalone: true,
})
export class CalendarEventEdit2Component extends NxtComponent implements OnInit, AfterViewInit, OnDestroy {


  constructor(
    @Optional() public dialogRef: MatDialogRef<CalendarEventEdit2Component>,
    private fb: UntypedFormBuilder,
    public loginService: LoginService,
    public permissionService: PermissionService,
    private socketService: SocketService,
    private telegramService: TelegramService,
    private cdRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private thermalPrinterService: ThermalPrinterService,
    private shortcutService: ShortcutService,
    private activatedRoute: ActivatedRoute,
    private clipboardService: ClipboardService,
    private routeService: RouteService,
    private bodyPutService: BodyPutService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private appointmentConfirmationService: AppointmentConfirmationService,
    private contactService: ContactService,
    public cacheService: CacheService,
    private configService: ConfigService,
    private windowService: WindowService,
    private labelPrintService: LabelPrintService,
    private clipboard: Clipboard,
    private walkInService: WalkInService,
    private reminderService: ReminderService,
    private workingDayService: WorkingDayService,
    private tattooTemplateService: TattooTemplateService,
  ) {
    super();
    this.pushSubscription = this.cacheService.artists.subscribe(artists => {
      if (artists?.length > 0) {
        this.artists = [ArtistsTools.getCancelArtist(), ArtistsTools.getArtistOpenTattoo(), ArtistsTools.getArtistOpenPiercing(), ...(artists.sortString('calendarNumber'))];
      }
    });
    if (this.activatedRoute.snapshot.url.length > 0 && this.activatedRoute.snapshot.url[0].path === 'eventedit') {
      this.socketService.registerAsApp('Calendar');
      setInterval(() => this.windowService.setTitle('Kalender'), 1000);
    }

    if (window.location.href.includes('eventedit-from-calendar')) {
      this.registerCalendarViewer();
    }
  }

  @ViewChild(PaymentsComponent) paymentsComponent: PaymentsComponent;
  private discountPromotions: NxtDiscountPromotion[] = [];

  originalFormRawValue: any = {};

  // showCustomerRef = signal(false);


  consentOk = computed(() => {
    return EventConsentTools.hastCorrectPageCount2(this.originalEvent());
  });

  showOldPhotos = computed(() => {
    if (this.originalEvent().id) {
      if (this.originalEvent().start < '2024-09-01'.dateParse()) {
        if (!this.originalEvent().files || this.originalEvent().files.length === 0) {
          return true;
        }
      }
      if (!this.originalEvent().files || this.originalEvent().files.length === 0) {
        if (this.originalEvent().mediaCount) {
          return this.originalEvent().mediaCount.photo > 0
            || this.originalEvent().mediaCount.video > 0
            || this.originalEvent().mediaCount.pdf > 0;
        }
      }
    }
  });

  private availableArtistThisDate: { [artist: string]: boolean } = {};
  private tattooTemplateSubscription: Subscription;

  priceAutoChangedTo: number = null;
  private loadEventLoadingTimeout: number;
  private isSafe = false;
  nextNxtUpdateId = '';

  private availableSkills = {skills: ArtistSkillTools.skills, boolSkills: ArtistSkillTools.boolSkills};
  // private hasChanges = false;
  showDebug2 = false;

  fixArtistReasonOptions = [
    {value: '', text: 'Unbekannt'},
    {value: 'design', text: 'Design'},
    {value: 'follow-up', text: 'Folgetermin'},
    {value: 'customer', text: 'Kunde will'},
    {value: 'partner', text: 'Partner-Tattoo'},
  ];
  originalEvent = signal<NxtCalendarEvent | null>(null);
  private _customerRefId = '';
  eventFiles = computed<NxtCalendarEventFile[]>(() => {
    if (!this.originalEvent() || !this.originalEvent().files) {
      return [];
    }
    return EventFileTools.sortFiles(this.originalEvent().files.filter(f => f.type !== 'pdf'));
  });

  eventConsents = computed<NxtCalendarEventFile[]>(() => {
    if (!this.originalEvent() || !this.originalEvent().files) {
      return [];
    }
    return EventFileTools.sortFiles(this.originalEvent().files.filter(f => f.type === 'pdf'));
  });

  customerHasMoreEvents = false;
  artistAssignedAt: number;
  private restore = false;
  isFromCalendarView = false;
  private pdf1Count = 0;
  public artistSkill: { value: number, stars: number, canUse: boolean, attention: boolean, infoLines: string[] };
  public customerPayedMoreThanPriceEstimatedTill = 0;
  public customerPayedMoreThanPriceEstimatedFrom = 0;
  public customerPayed = 0;
  public shouldHaveSkill = false;
  public afterSaved = new EventEmitter<NxtCalendarEvent>();
  public afterClosed = new EventEmitter<{ op: 'created' | 'updated' | 'canceled' | 'deleted', newCalendarEvent?: NxtCalendarEvent }>();
  reOpened = false;
  // private originalArtistCalendar: NxtCalendarEvent;
  reminders: NxtReminder[];
  priceChanges: NxtCalendarEventPriceChange[] = [];
  openerComponent: OpenerComponent;
  tattooPhotoCount = 0;
  videoCount = 0;
  followUp: { total: number, index?: number, eventIds?: string[], id: string };
  photoFolderId: string | undefined = '';
  // selectedMedias = signal<{}[]>([]);
  photosAndVideos: (SocketInterfaceResponse.EventPhoto)[] = [];
  images: (SocketInterfaceResponse.EventPhoto & { sortValue?: string })[] = [];
  videos: SocketInterfaceResponse.EventPhoto[] = [];
  pdfs: (SocketInterfaceResponse.EventPhoto & { src?: string, complete?: boolean })[] = [];
  newEventData: NxtNewEventData;
  artists: NxtArtist[] = [];
  private eventIdIsInUrl = false;
  @ViewChild('customerSuggestionButton', {static: true}) customerSugBtn: ElementRef;
  @ViewChild('myTitleInput', {static: false}) myTitleInputRefEl: InputComponent;
  @ViewChild('nxtFormControlElemCustomer', {static: false}) nxtFormControlElemCustomer: AutocompleteComponent;
  @ViewChild('nxtFormControlElemArtist', {static: false}) nxtFormControlElemArtist: AutocompleteComponent;
  @ViewChild('nxtFormControlElemBodyPut', {static: false}) nxtFormControlElemBodyPut: InputComponent;
  @ViewChild('nxtFormControlEleLmDate', {static: false}) nxtFormControlElemDate: DatePickerComponent;
  @ViewChild('nxtFormControlElemTimeTill') nxtFormControlElemTimeTill: TimePickerComponent;
  @ViewChild('nxtFormControlElemDepotDueDateReason', {static: false}) nxtFormControlElemDepotDueDateReason: InputComponent;


  motiveBlacklist = ['fertig', 'machen', 'chat', 'session'];

  private _nxtPaymentsComponent: PaymentsComponent;
  showDepositDueDate = false;
  isCanceled = false;
  showBackgroundColor = false;
  forceSaveHack = false;
  isJulian = this.loginService.isJulian();
  onInitTimestamp = 0;
  saveIsRunning = false;
  changes = signal<string[]>([]);
  eventFileThumbReloadIndicator = signal('');
  // workType: NxtWorkType = 'tattoo';
  skill: { skills: { [key: string]: boolean }, boolSkills: { [key: string]: boolean } };
  showDurationPriceInfo = false;
  customerText = '';
  customerEvents: any[] = [];
  customerClosedEvents: any[] = [];
  customerCanceledEvents: any[] = [];
  createdToday = false;
  public calendarEventEditDisplayFn = CalendarEventEditDisplayFn;
  /*public studiosWithoutCash = this.configService.config.value.studiosWithoutSideCash.filter(s => !['Alsdorf', 'Brand'].includes(s.name)).map(s => ({
    value: s.name,
    text: s.name,
    hotkey: '',
  }));*/
  public paymentPayoutExist = false;
  // isFree = false;
  unsavedImages: NxtImage[];
  displayLogArray: string [] = [];
  private onNewContacts = new EventEmitter<any>();
  lastSaved = 0;
  form: UntypedFormGroup;
  notAssignedPaypalTransactionsIn: NxtPaypalTransaction[];
  notAssignedPaypalTransactionsOut: NxtPaypalTransaction[];
  notAssignedKlarnaOrdersIn: NxtKlarnaOrder[];
  notAssignedKlarnaOrdersOut: NxtKlarnaOrder[];
  showAddCustomer = false;
  notAssignedBankTransactionsIn: NxtPaymentPossibilityRecord[];
  notAssignedBankTransactionsOut: NxtPaymentPossibilityRecord[];
  isEditContact = false;
  isButtonChooserOpen = false;
  isNewEvent = false;
  newEventUuid = '';
  disableFreeToggle = false;
  durationText = '';
  showDebug = false;
  eventString = '';
  eventUrl = '';
  colorTools = ColorTools;
  eventId: string;
  possiblePaymentTypes: any[];
  allPaymentMethods: any[];
  paymentSmoothHeightTrigger = 0;
  artistSmoothHeightTrigger = 0;
  possiblePaymentMethods: any[];
  possiblePaymentMethodsDepositBack: any[];
  priceFix = false;
  showContent = false;
  customerSuggestion: NxtContact | null = null;
  private shortcutSubscription: Subscription;
  private isNoArtistCalendar = false;
  private editContactDialogRef: MatDialogRef<ContactFormComponent, MatDialogConfig>;
  cardSpace = '10px';
  private formValueChangeSubscription: Subscription;
  formValueChangesSubscriptions: (Subscription | undefined)[] = [];
  // piercingArtists: any[] = [];
  artistNotAvailableText = '';
  searchTimeout: any;
  showReloadEvent = false;
  selectedMedia = signal<{ [photoId: string]: SocketInterfaceResponse.EventPhoto }>({});
  selectedEventFiles = signal<{ [eventFileId: string]: NxtCalendarEventFile }>({});
  public isMallorca = this.loginService.getStudio() === 'Mallorca';
  public controlGap = '15px';
  private saveAndCloseDialogRunning = false;
  actionAfterSaved: 'print-qr' | 'appointment-reminder' | '' = '';
  protected readonly MobileTools = MobileTools;
  loadLog = new NxtLog('loadEvent');
  protected readonly IconTools = IconTools;
  isFirstCustomerEvent = false;

  newTattooTemplates = signal<NxtTattooTemplate[]>([]);

  selectedMediaText = computed(() => {
    const lines: string[] = [];
    let tattooPhotoCount = 0;
    let stencilPhotoCount = 0;
    let templatePhotoCount = 0;

    for (const key of keys(this.selectedMedia())) {
      const media = this.selectedMedia()[key];
      if (media.type === 'tattoo') {
        tattooPhotoCount++;
      }
      if (media.type === 'stencil') {
        stencilPhotoCount++;
      }
      if (media.type === 'template') {
        templatePhotoCount++;
      }
    }
    if (tattooPhotoCount === 1) {
      lines.push('1 Tattoo-Bild');
    } else if (tattooPhotoCount > 1) {
      lines.push(tattooPhotoCount + ' Tattoo-Bilder');
    }
    if (stencilPhotoCount === 1) {
      lines.push('1 Stencil');
    } else if (stencilPhotoCount > 1) {
      lines.push(stencilPhotoCount + ' Stencil');
    }
    if (templatePhotoCount === 1) {
      lines.push('1 Vorlage');
    } else if (templatePhotoCount > 1) {
      lines.push(templatePhotoCount + ' Vorlagen');
    }
    return lines.join('\n& ');
  });

  selectedEventFileText = computed(() => {
    const lines: string[] = [];
    const files = keys(this.selectedEventFiles()).map(fileId => this.selectedEventFiles()[fileId]);
    const videoCount = files.filter(f => f.type === 'video').length;
    const imageCount = files.filter(f => f.type === 'image').length;
    if (imageCount === 1) {
      lines.push('1 Bild');
    } else if (imageCount > 1) {
      lines.push(imageCount + ' Bilder');
    }
    if (videoCount === 1) {
      lines.push('1 Video');
    } else if (videoCount > 1) {
      lines.push(videoCount + ' Videos');
    }
    return lines.join(' & ');
  });

  loadEventDone = new BehaviorSubject(false);

  customerRefText = signal('');

  async ngOnInit() {
    this.onInitTimestamp = Date.now();
    this.socketService.getDiscountPromotions(true).then(d => this.discountPromotions = d);
    let errorCode = '';
    try {
      errorCode = '0x001';
      this.subscribeSocketEvents();
      errorCode = '0x002';
      IframeMessageManagerInIframe.instance.send('setUsername', {
        username: this.loginService.getUsername(),
        studio: this.loginService.getStudio(),
        color: '#FFF',
      });
      // this.setArtistColors();

      errorCode = '0x004';
      this.registerShortcutEvents();
      document.body.style.backgroundColor = 'transparent !important';
      errorCode = '0x005';
      IframeMessageManagerInIframe.instance.addRequestListener('startWalkIn', async () => {
        IframeMessageManagerInIframe.instance.showIframe('startWalkIn', 'Calendar');
        if (!(await this.walkInService.startWalkIn(this))) {
          IframeMessageManagerInIframe.instance.hideIframe('startWalkIn');
        }
      });

      IframeMessageManagerInIframe.instance.addRequestListener('showSearch', async () => {
        IframeMessageManagerInIframe.instance.showIframe('showSearch', 'Calendar');
        const dialog = this.dialogService.showComponentFull(SearchComponent);
        await firstValueFrom(dialog.afterClosed());
        if (!this.dialogRef) {
          IframeMessageManagerInIframe.instance.hideIframe('showSearch');
        }
      });

      IframeMessageManagerInIframe.instance.addRequestListener('getCalendarLogin', async () => {
        return await this.socketService.getCalendarPw();
      });

      IframeMessageManagerInIframe.instance.addRequestListener('usernameClicked', () => {
        IframeMessageManagerInIframe.instance.showIframe('usernameClicked', 'Calendar');
        this.loginService.reLogin(true);
      });
      errorCode = '0x006';
      this.loadEventFromUrl();
      // this.discountPromotions$ = this.cacheService.discountPromotions;
      errorCode = '0x007';
      this.routeService.setCurrentComponent(this);
    } catch (err) {
      Log.error('calendar-event.edit ngOnInit failed\nErrorCode: ' + errorCode + '\n' + err.message);
    }
  }

  bodyPutCellRenderer = (value: any) => {
    value = value.filter(p => p !== 'on');
    return this.bodyPutService.getGermanPath(value);
  };

  bodyPutPiercingCellRenderer = (value: any) => {
    value = value.filter(p => p !== 'on');
    return this.bodyPutService.getGermanPathBodyPutPiercing(value);
  };

  bodyPutBeautyCellRenderer = (value: any) => {
    return this.bodyPutService.getBodyPutBeautyText(value);
  };

  bodyPutToothGemCellRenderer = (value: any) => {
    return this.bodyPutService.getBodyPutToothGemText(value);
  };

  private registerShortcutEvents() {
    if (this.shortcutSubscription) {
      this.shortcutSubscription.unsubscribe();
    }
    this.shortcutSubscription = this.shortcutService.onKeyPress.subscribe(async (key: KeyCode) => {
      // ACHTUNG DOPPELT VERHINDERN
      // aber wie?
      if (key === KeyCode.CtrlS) {
        if (this.isEditContact) {
          this.saveAndCloseEditContact();
        } else {
          this.saveAndCloseDialog(false).then();
        }
      } else if (key === KeyCode.Esc) {
        if (!this.dialogRef) {
          if (this.isEditContact) {
            this.closeEditContact();
          }
          if (this.isButtonChooserOpen) {
            this.dialogService.closeButtonChooser();
          } else {
            // this.close();
          }
        }
      } else if (key === KeyCode.Ctrl1 || key === KeyCode.CtrlT || key === KeyCode.Alt1) {
        // this.myTitleInputRefEl?.setFocus();
      } else if (key === KeyCode.Ctrl2 || key === KeyCode.CtrlK || key === KeyCode.Alt2) {
        // this.nxtFormControlElemCustomer?.setFocus();
      } else if (key === KeyCode.Ctrl3 || key === KeyCode.CtrlAUml || key === KeyCode.Alt3) {
        // this.nxtFormControlElemArtist?.setFocus();
      } else if (key === KeyCode.Ctrl5 || key === KeyCode.Alt5) {
        // this.nxtFormControlElemDate.setFocus();
      } else if (key === KeyCode.Add) {
        // this.nxtPaymentsComponent?.addPayment();
      } else if (key === KeyCode.AltB) {
        // this.form?.get('studio').setValue('Brand');
      } else if (key === KeyCode.AltV) {
        // this.form?.get('studio').setValue('Villa');
      }
    });
  }


  ngAfterViewInit(): void {
  }

  /* loadForm formLoad */
  private async parseEventToForm() {
    await this.waitForArtistCalendars();
    const event = this.originalEvent()!;

    this.isNoArtistCalendar = false;
    this.setIsCanceled(event.status === 'canceled');
    const customerId = event.customer;
    this.setCustomerRefId(event.customerRefId);

    /*** HOTFIX bei bereits gespeicherten Kunden **/
    let customer: NxtContact | undefined;
    // let customerRef: NxtContact | undefined;
    if (customerId) {
      customer = await this.socketService.getContactWithEvents(customerId.toString());
    }
    /*if (customerRefId) {
      customerRef = await this.socketService.getContactWithEvents(customerRefId.toString());
    }*/
    // this.showCustomerRef.set(!!customerRefId);
    /*** ENDE HOTFIX **/

    const bodyPutsTattooGroups: UntypedFormGroup[] = [];
    const bodyPutsBeautyGroups: UntypedFormGroup[] = [];
    const bodyPutsToothGemGroups: UntypedFormGroup[] = [];
    const bodyPutsPiercingGroups: UntypedFormGroup[] = [];


    event!.bodyPuts?.tattoo?.forEach(t => {
      bodyPutsTattooGroups.push(
        this.fb.group({
            bodyPut: new NxtFormControl(t.bodyPut, [ValidatorTools.requiredAndNotNaN]),
            motive: new NxtFormControl(t.motive, [ValidatorTools.requiredAndNotNaN]),
            round: new NxtFormControl(t.round),
            thinLines: new NxtFormControl(t.thinLines),
            size: new NxtFormControl(t.size, this.isNewEvent ? [ValidatorTools.requiredAndNotNaN] : []),
          },
        ));
    });
    event!.bodyPuts?.piercing?.forEach(t => {
      bodyPutsPiercingGroups.push(
        this.fb.group({
            bodyPut: new NxtFormControl(t.bodyPut, [ValidatorTools.requiredAndNotNaN]),
            motive: new NxtFormControl(t.motive, [ValidatorTools.requiredAndNotNaN]),
            goldBall: new NxtFormControl(!!t.goldBall),
          },
        ));
    });
    event!.bodyPuts?.beauty?.forEach(t => {
      bodyPutsBeautyGroups.push(
        this.fb.group({
            bodyPut: new NxtFormControl(t.bodyPut, [ValidatorTools.requiredAndNotNaN]),
            motive: new NxtFormControl('', [ValidatorTools.requiredAndNotNaN]),
          },
        ));
    });

    event!.bodyPuts?.toothGem?.forEach(t => {
      bodyPutsToothGemGroups.push(
        this.fb.group({
            bodyPut: new NxtFormControl(t.bodyPut, [ValidatorTools.requiredAndNotNaN]),
            motive: new NxtFormControl('', [ValidatorTools.requiredAndNotNaN]),
          },
        ));
    });

    let payments: NxtPayment[] = event!.payments;
    if (!payments) {
      payments = [];
    }

    const paymentGroups: UntypedFormGroup[] = [];
    for (const payment of payments) {
      const paymentFormControls: UntypedFormGroup = new Payment(payment).getFormGroup(this.fb);
      paymentFormControls.get('paymentValue')?.disable();
      paymentFormControls.get('paymentMethod')?.disable();
      paymentFormControls.get('paymentType')?.disable();
      paymentFormControls.get('paymentDate')?.disable();
      paymentFormControls.get('paymentComment')?.disable();
      paymentFormControls.get('paymentPaypalTransaction')?.disable();
      paymentFormControls.get('paymentKlarnaOrder')?.disable();
      paymentFormControls.get('paymentGiftCard')?.disable();
      paymentFormControls.get('paymentBankTransaction')?.disable();
      paymentFormControls.get('studio')?.disable();
      paymentFormControls.get('studioReal')?.disable();
      paymentFormControls.get('workplace')?.disable();
      paymentFormControls.get('createdBy')?.disable();
      paymentGroups.push(paymentFormControls);
    }
    this.isSafe = event.visibility && event?.visibility === 'private';
    this.photoFolderId = event.photoFolderId;
    this.followUp = event.followUp;
    this.priceChanges = clone(event.priceChanges) || [];
    let artist = this.artists.find(a => a.name === event.artist);
    if (event.status === 'canceled') {
      artist = this.artists.find(a => a.name === ArtistsTools.getCancelArtist().name);
    }
    if (!Array.isArray(event.artistFixReason)) {
      if (event.artistFixReason && (event.artistFixReason as any).length > 0 && typeof event.artistFixReason === 'string') {
        event.artistFixReason = [event.artistFixReason];
      } else {
        event.artistFixReason = [];
      }
    }
    const formToSet = this.fb.group({
      title: new NxtFormControl(event.title),
      info: new NxtFormControl(event.info, [], 'Info'),
      importantInfo: new NxtFormControl(event.importantInfo, [], 'Wichtige Info'),
      invoiceNumber: new NxtFormControl(event.invoiceNumber, [], 'Rechnungsnummer'),
      timeFrom: new NxtFormControl(event.start.dateFormat('HH:mm'), [ValidatorTools.requiredAndNotNaN], 'Von'),
      timeTill: new NxtFormControl(event.end.dateFormat('HH:mm'), [ValidatorTools.requiredAndNotNaN], 'Bis'),
      date: new NxtFormControl(event.start.dateFormat('yyyy-MM-dd'), [ValidatorTools.requiredAndNotNaN], 'Datum'),
      customer: new NxtFormControl(customer, [ValidatorTools.validCustomer, ValidatorTools.requiredAndNotNaN], 'Kunde'),
      customerRefId: new NxtFormControl(event.customerRefId, [], 'Anderer Chat'),
      artist: new NxtFormControl(artist, [ValidatorTools.requiredAndNotNaN, ValidatorTools.hasProperty('name')], 'Artist'),
      bodyPutsTattoo: this.fb.array(bodyPutsTattooGroups),
      bodyPutsBeauty: this.fb.array(bodyPutsBeautyGroups),
      bodyPutsToothGem: this.fb.array(bodyPutsToothGemGroups),
      bodyPutsPiercing: this.fb.array(bodyPutsPiercingGroups),
      priceEstimatedFrom: new NxtFormControl(event.priceEstimatedFrom, [ValidatorTools.requiredAndNotNaN], 'Preis'),
      priceEstimatedTill: new NxtFormControl(event.priceEstimatedTill, [], 'Preis'),
      // studio: new NxtFormControl(event.studio, [ValidatorTools.requiredAndNotNaN]),
      payments: this.fb.array(paymentGroups),
      closed: new NxtFormControl(event.closed),
      artistPercentage: new NxtFormControl(event.artistPercentage),
      priceFix: new NxtFormControl(event.priceFix, [], 'Fix Preis'),
      priceAuto: new NxtFormControl(!!event.priceAuto, [], 'Automatischer Preis'),
      artistFix: new NxtFormControl(event.artistFix, [], 'Fix Artist'),
      artistFixReason: new NxtFormControl(event.artistFixReason, [], 'Grund fix Artist'),
      durationPriceInfo: new NxtFormControl(event.durationPriceInfo, [], 'Dauer Preis Grund'),
      noDepotNecessary: new NxtFormControl(event.noDepotNecessary, [], 'Keine Kaution nötig'),
      noDepotNecessaryReason: new NxtFormControl(event.noDepotNecessaryReason, [], 'Keine Kaution Grund'),
      depotDueDate: new NxtFormControl(event.depotDueDate ? moment(event.depotDueDate) : null, []),
      depotDueDateReason: new NxtFormControl(event.depotDueDateReason),
      discountPromotion: new NxtFormControl(event.discountPromotion),
      noAppointmentReminder: new NxtFormControl(event.noAppointmentReminder),
      improveArtistShouldGetMoney: new NxtFormControl(event.improveArtistShouldGetMoney),
      improve: new NxtFormControl(!!event.improve, [], 'Nachstechen'),
      improveOtherArtistCheckState: new NxtFormControl(event.improveOtherArtistCheckState, [], 'Nachstechen anderer Artist Status'),
      improveOriginalArtist: new NxtFormControl(event.improveOriginalArtist, [], 'Nachstechen Original Artist'),
      canceledApproved: new NxtFormControl(event.canceledApproved),
      canceledReason: new NxtFormControl(event.canceledReason, [], 'Absagegrund'),
      canceledAt: new NxtFormControl(event.canceledAt),
      canceledInTime: new NxtFormControl(event.canceledInTime),
      adjustPrice: new NxtFormControl(event.adjustPrice, [], 'Preis evtl. anpassen'),
      fastWalkIn: new NxtFormControl(event.fastWalkIn),
      fastWalkInNo: new NxtFormControl(event.fastWalkInNo),
      shouldDepositBack: new NxtFormControl(event.shouldDepositBack),
      shouldDepositBackValue: new NxtFormControl(event.shouldDepositBackValue),
      shouldDepositBackCreatedAt: new NxtFormControl(event.shouldDepositBackCreatedAt),
      promoOfferId: new NxtFormControl(event.promoOfferId),
      promoOfferPromoterName: new NxtFormControl(event.promoOfferPromoterName),
      promoOfferCreatedAt: new NxtFormControl(event.promoOfferCreatedAt),
      workType: new NxtFormControl(event.workType),
      problems: new NxtFormControl(event.problems || ''),
      disableSkillCheck: new NxtFormControl(!!event.disableSkillCheck),
      artistPercentageConfirmed: new NxtFormControl(!!event.artistPercentageConfirmed),
      toPayOnEventDate: new NxtFormControl(event.toPayOnEventDate || []),
    });

    /*if (event.studio && !this.studiosWithoutCash.find(s => s.value === event.studio)) {
      this.studiosWithoutCash = this.configService.config.value.studiosWithoutSideCash.map(s => ({
        value: s.name,
        text: s.name,
        hotkey: '',
      }));
    }*/

    this.pdf1Count = event.pdf1Count;
    if (event.workType === 'tattoo') {
      this.skill = event.skill;
      if (!this.skill) {
        this.skill = {boolSkills: {}, skills: {}};
      }
    } else {
      this.skill = undefined;
    }

    this.artistAssignedAt = event.artistAssignedAt;
    this.shouldHaveSkill = DateTools.parse(event.createdAt) > '2023-03-03'.dateParse();
    if (!this.form) {
      this.form = formToSet;
    } else {
      for (const key of Object.keys(formToSet.controls)) {
        if (key === 'bodyPutsPiercing') {
          (this.formGet(key) as UntypedFormArray).controls.length = 0;
          for (const control of this.fb.array(bodyPutsPiercingGroups).controls) {
            (this.formGet(key) as UntypedFormArray).controls.push(control);
          }
        } else if (key === 'bodyPutsTattoo') {
          (this.formGet(key) as UntypedFormArray).controls.length = 0;
          for (const control of this.fb.array(bodyPutsTattooGroups).controls) {
            (this.formGet(key) as UntypedFormArray).controls.push(control);
          }
        } else if (key === 'bodyPutsToothGem') {
          (this.formGet(key) as UntypedFormArray).controls.length = 0;
          for (const control of this.fb.array(bodyPutsToothGemGroups).controls) {
            (this.formGet(key) as UntypedFormArray).controls.push(control);
          }
        } else if (key === 'payments') {
          (this.formGet(key) as UntypedFormArray).controls.length = 0;
          for (const control of this.fb.array(paymentGroups).controls) {
            (this.formGet(key) as UntypedFormArray).controls.push(control);
          }
        } else {
          try {
            this.formGet(key).setValue(formToSet.controls[key].value);
          } catch (err) {
            Log.error('reset controls failed', err);
          }
        }
      }
    }


    // this.priceFix = extProps.getPriceFix();

    this.form?.get('title').disable();
    /*if (!this.permissionService.isJulian()) {
      this.form?.get('invoiceNumber').disable();
    }*/
    this.form?.get('invoiceNumber').disable();

    // this.form?.get('titleOld').disable();


    this.registerFormValueChanged();
    this.getAvailableArtistThisDay();
    this.loadReminder();
    // suffixes anfügen
    setTimeout(() => {
      this.registerFormControlChangeListener();
      this.formGet('title').setValue(this.formGet('title').value);
      this.formGet('info').setValue(this.formGet('info').value);
      this.formGet('priceEstimatedFrom').setValue(event.priceEstimatedFrom);
      this.formGet('artistPercentage').setValue(event.artistPercentage);
      this.formGet('priceFix').setValue(event.priceFix);
      this.formGet('artistFix').setValue(event.artistFix);
      this.formGet('artistFixReason').setValue(event.artistFixReason);
      this.formGet('durationPriceInfo').setValue(event.durationPriceInfo);
      this.formGet('priceEstimatedTill').setValue(event.priceEstimatedTill);
      this.formGet('customer').setValue(this.formGet('customer').value);
      this.formGet('artist').setValue(this.formGet('artist').value);
      this.formGet('timeFrom').setValue(this.formGet('timeFrom').value);
      this.formGet('timeTill').setValue(this.formGet('timeTill').value);
      this.formGet('discountPromotion').setValue(this.formGet('discountPromotion').value);
      this.formGet('noDepotNecessary').setValue(event.noDepotNecessary);
      this.formGet('fastWalkIn').setValue(event.fastWalkIn);
      // this.fastWalkIn = event.fastWalkIn;
      this.formGet('fastWalkInNo').setValue(event.fastWalkInNo);

      for (const paymentGroup of bodyPutsTattooGroups) {
        paymentGroup?.get('bodyPut').setValue(paymentGroup.get('bodyPut').value);
        paymentGroup?.get('motive').setValue(paymentGroup.get('motive').value);
      }
      for (const paymentGroup of bodyPutsPiercingGroups) {
        paymentGroup?.get('bodyPut').setValue(paymentGroup.get('bodyPut').value);
        paymentGroup?.get('motive').setValue(paymentGroup.get('motive').value);
      }

      for (const paymentGroup of paymentGroups) {
        // warum das hier?
        paymentGroup?.get('paymentValue').setValue(paymentGroup.get('paymentValue').value);
        paymentGroup?.get('paymentMethod').setValue(paymentGroup.get('paymentMethod').value);
        paymentGroup?.get('paymentPaypalTransaction').setValue(paymentGroup.get('paymentPaypalTransaction').value);
        paymentGroup?.get('paymentKlarnaOrder').setValue(paymentGroup.get('paymentKlarnaOrder').value);
        paymentGroup?.get('paymentGiftCard').setValue(paymentGroup.get('paymentGiftCard').value);
      }
      if (this.form && paymentGroups.length === 0) {
        (this.formGet('payments') as UntypedFormArray).setValue([]);
      }
      this.checkPublicHoliday();
      // studio setzen, wenn es nur ein studio gibt
      this.workTypeChanged();
      this.setPossiblePaymentTypesAndMethods();
      this.setIsCanceled(this.isCanceled);
      this.clearChanges();

      setTimeout(async () => {
          await this.checkLoadNewEventData();
          await this.refreshCustomerForm();
          this.calcHasArtistPayment();
          this.calcDepotDueDate();
          this.checkNoDepotNecessaryReason();
          this.calcCustomerText();
          await this.checkArtistAvailable();
          setTimeout(() => {
            this.calcArtistSkill();
            this.generateTitle();
            IframeMessageManagerInIframe.instance.sendEval(IframeParentCommand.closeGoogleEvent);
            this.clearChanges();
            this.loadEventDone.next(true);
          }, 200);
        }
        , 100);

    }, 100);
    this.reOpened = false;
  }

  enableDurationPriceInfo(enable: boolean) {
    if (this.isCanceled) {
      enable = false;
    }
    this.formGet('durationPriceInfo').clearValidators();
    if (enable) {
      this.formGet('durationPriceInfo').setValidators([ValidatorTools.requiredAndNotNaN]);
    }
    this.formGet('durationPriceInfo').updateValueAndValidity({emitEvent: false});
    this.showDurationPriceInfo = enable;
  }


  /*** -------END PARSE --------- **/
  /*** --------------------------------- **/

  /*** -------EXTENDED PROPERTIES --------- **/
  /*** -------END EXTENDED PROPERTIES --------- **/


  private checkShowAddCustomer() {
    this.showAddCustomer = typeof this.form?.get('customer').value === 'string' && this.form?.get('customer').value.length > 3;
  }

  private loadEventFromUrl() {
    try {
      const eventId = this.activatedRoute.snapshot.paramMap?.get('eventId');
      if (eventId) {
        this.loadEvent({eventId});
      }
    } catch (err) {
      throw Error('calendar-event-edit loadEventFromUrl failed' + err.message);
    }
  }

  async loadEvent(params: LoadEventParams) {
    this.loadEventDone.next(false);
    this.loadLog = new NxtLog('loadEvent');
    this.loadLog.logAfter('START');
    this.newEventData = null;
    this.resetCalendarEvent();
    this.loadLog.logAfter('resetCalendarEvent');
    this.showReloadEvent = false;
    this.photosAndVideos = null;
    this.videos = null;
    this.images = null;
    this.calcCustomerText();
    this.loadLog.logAfter('calcCustomerText');
    this.showBackgroundColor = true;
    try {
      this.unRegisterFormControlChangeListener();
      this.loadLog.logAfter('unRegisterFormControlChangeListener');
      this.forceSaveHack = false;
      IframeMessageManagerInIframe.instance.send('setUsername', {
        username: this.loginService.getUsername(),
        studio: this.loginService.getStudio(),
        color: '#FFF',
      });

      const durationLogger = DurationLogger.new('LOAD-EVENT');
      this.unsavedImages = [];
      IframeMessageManagerInIframe.instance.showIframe('calendar-event-edit loadEvent() start', 'Calendar');
      if (params.eventId) {
        this.loadEventLoadingTimeout = window.setTimeout(() => {
          this.dialogService.showLoading(LoadingId.CalendarEventEditLoading, 'lade Termin...');
        }, 500);
      } else {
        this.dialogService.showLoading(LoadingId.CalendarEventEditLoading, 'lade leeren Termin...');
      }
      this.loadLog.logAfter('showLoading');
      setTimeout(async () => {
        this.dialogService.updateLoadingText('lade Kunden...');
        this.customerSuggestion = null;
        this.eventId = params.eventId;
        this.isNewEvent = false;
        this.newEventUuid = '';
        try {
          if (params.eventId === 'new') {
            this.isNewEvent = true;
            this.originalEvent.set(EventTools.getEmptyEvent(DateTools.todayAt('22:00')));
          } else if (params.calendarEvent) {
            this.originalEvent.set(params.calendarEvent);
            if (!this.originalEvent().workType) {
              this.originalEvent.update(originalEvent => ({...originalEvent, workType: 'tattoo'}));
            }
          } else if (params.eventId) {
            this.dialogService.updateLoadingText('lade Termin');
            this.loadLog.logAfter('start getCalendarEvent2');
            this.originalEvent.set(await this.socketService.getCalendarEventWithCustomer(this.eventId));
            this.loadLog.logAfter('end getCalendarEvent2');
          } else {
            // leerer Termin
            this.dialogService.updateLoadingText('empty event');
            const start = DateTools.roundToNextMinute(Date.now, 15);
            const end = start + DurationTools.DURATION_1HOUR;
            this.originalEvent.set(EventTools.getEmptyEvent(start, end));
            this.isNewEvent = true;
            this.newEventUuid = UuidTools.generate();
            if (params.newEventData) {
              this.eventId = 'new';
              this.newEventData = params.newEventData;
            } else {
              this.newEventData = null;
            }
          }

          if (this.isNewEvent) {
            this.fixArtistReasonOptions = [
              {value: 'design', text: 'Design'},
              {value: 'follow-up', text: 'Folgetermin'},
              {value: 'customer', text: 'Kunde will'},
              {value: 'partner', text: 'Partner-Tattoo'},
            ];
          } else {
            this.fixArtistReasonOptions = [
              {value: '', text: 'Unbekannt'},
              {value: 'design', text: 'Design'},
              {value: 'follow-up', text: 'Folgetermin'},
              {value: 'customer', text: 'Kunde will'},
              {value: 'partner', text: 'Partner-Tattoo'},
            ];
          }


          this.createdToday = DateTools.isToday(this.originalEvent()?.createdAt);

          /*** ARTIST-CALENDAR ***/

          this.eventUrl = window.location.origin + '/e/' + this.originalEvent().id;
          this.eventString = JsonTools.stringifyFormat(this.originalEvent(), '&nbsp;&nbsp;&nbsp;');
          this.loadLog.logAfter('start parseEventToForm');
          await this.parseEventToForm();
          this.loadLog.logAfter('end parseEventToForm');
          this.showContent = true;
          this.loadLog.writeToLog();
          this.registerTattooTemplates();
          document.body.focus();
          if (!this.isNoArtistCalendar) {
            IframeMessageManagerInIframe.instance.showIframe('calendar-event-edit loadEvent() end', 'Calendar');
          }
        } catch (err) {
          this.log('Laden des Termins nicht erfolgreich\n' + JsonTools.stringify(err));
          if (err?.errorId === NxtErrorId.getCalendarEvent_IsAvailableArtist) {
            // this.showArtistAvailable();
          } else if (err?.errorId === NxtErrorId.getCalendarEvent_EventIsFromPrimaryCalendar) {
            if (!this.dialogRef) {
              IframeMessageManagerInIframe.instance.hideIframe('calender-event is from primaray calenar');
            }
          } else {
            if (err?.errorId) {
              this.dialogService.showOk(ErrorTools.getMessage(err));
            } else {
              Log.error('CALENDAR-EVENT-EDIT\ninner loadEvent fehlgeschlagen\nevent-id: ' + this.eventId, err);
            }
          }
          this.hideLoadingEventDialog();
          return;
        }
        setTimeout(() => {
          this.hideLoadingEventDialog();
          this.cdr.detectChanges();
          durationLogger.log('');
          // hier vorher
          this.loadPhotos('load event');
          this.clearChanges();
          setTimeout(() => {
            this.clearChanges();
          }, 200);
        }, 200);
      }, 10);

    } catch (err) {
      throw Error('calendar-event-edit loadEvent failed' + err.message);
    }
  }


  /*** ----------SAVE------------ **/
  async save(checkClosed: boolean, recursiveCounter = 0): Promise<NxtCalendarEvent | undefined> {
    if (!this.forceSaveHack && this.hasMotiveDescriptionWithoutSkill()) {
      return;
    }
    this.log('start save');
    if (!this.isCanceled) {
      this.form?.get('canceledAt').setValue(null);
      this.form?.get('canceledInTime').setValue(false);
    }
    const formValuesRaw = this.form.getRawValue();
    const payments: NxtPayment[] = this.getFormPayments().getRawValue();
    const newPayments: NxtPayment[] = payments.filter(p => p.isNewPayment);
    const deletedPayments: NxtPayment[] = this.originalEvent().payments.filter(pOrig => !payments.some(p => p.paymentUuid === pOrig.paymentUuid));
    this.fixPaymentsBeforeSaveAndSetToCalendarEvent(payments);

    this.log('new ExtendedCalendarEventProperties');
    const date = this.formGet('date').value;
    const timeFrom = this.formGet('timeFrom').value;
    const timeTill = this.formGet('timeTill').value;

    let customerPayedMoreReason = '';


    const newData = {
      start: DateTools.parse(DateTools.format(date, 'yyyy-MM-dd') + ' ' + timeFrom, 'yyyy-MM-dd HH:mm'),
      end: DateTools.parse(DateTools.format(date, 'yyyy-MM-dd') + ' ' + timeTill, 'yyyy-MM-dd HH:mm'),
      artistPercentage: formValuesRaw.artistPercentage,
      customer: formValuesRaw.customer as NxtContact,
      customerName: formValuesRaw.customer?.givenName + ' ' + formValuesRaw.customer?.familyName,
      artistName: formValuesRaw.artist?.name,
      artistCalendarNumber: parseInt(formValuesRaw.artist?.calendarNumber, 10),
      fastWalkIn: !!this.formGet('fastWalkIn').value,
      studio: formValuesRaw.studio,
      priceEstimatedFrom: formValuesRaw.priceEstimatedFrom,
      customerPayed: this.paymentsComponent.paymentValueSum().roundMoney(),
      shouldDepositBackValue: MathTools.roundMoney(formValuesRaw.shouldDepositBackValue),
    };
    if (newData.end < newData.start) {
      newData.end = DateTools.addDays(newData.end, 1);
    }


    let startChanged = false;
    let endChanged = false;
    // let studioChanged = false;

    if (!this.originalEvent().start || this.originalEvent().start !== newData.start) {
      startChanged = true;
    }
    if (!this.originalEvent().end || this.originalEvent().end !== newData.end) {
      endChanged = true;
    }
    /*if (this.originalEvent().studio !== newData.studio) {
      studioChanged = true;
    }*/

    if (this.saveIsRunning) {
      this.telegramService.sendAdmin('Doppel speichern wird verhindert');
      return;
    }
    this.log('start ok checks');


    /*if (!this.isNewEvent && ['closed', 'canceled'].includes(this.originalEvent().status)) {
      let isToOld = false;
      if (DateTools.getDayOfMonth(Date.now()) > 15) {
        // es ist die zweite Hälfte des Monats, also muss es dieser Monat sein
        if (this.originalEvent().start < DateTools.getFirstOfMonth(Date.now())) {
          isToOld = true;
        }
      } else {
        // letzter Monat ist noch ok
        if (this.originalEvent().start < DateTools.getFirstOfMonth(Date.now().dateAddMonths(-1))) {
          isToOld = true;
        }
      }
      if (isToOld) {
        if (this.loginService.isJulian()) {
          const cancel = await this.dialogService.showYesNo('Ist zu Alt!', {yesText: 'OK', noText: 'FORCE'});
          if (cancel) {
            return;
          }
        } else {
          this.dialogService.showOk('Der Termin ist zu alt um zu speichern.');
          return;
        }
      }
    }*/

    if (await this.checkEnterCustomerBirthday(newPayments.length > 0)) {
      return;
    }

    if (newPayments.some(p => p.paymentValue === 0 && p.paymentType !== 'payout')) {
      await this.dialogService.showOk('❌Zahlung mit 0 Euro?❌');
      return;
    }

    if (deletedPayments.some(p => p.paymentMethod === 'cash' && p.paymentType !== 'payout')) {
      if (this.loginService.isJulian()) {
        const cancel = await this.dialogService.showYesNo('Bar-Zahlungen können nicht gelöscht werden!', {yesText: 'OK', noText: 'FORCE'});
        if (cancel) {
          return;
        }
      } else {
        this.dialogService.showOk('Bar-Zahlungen können nicht gelöscht werden!');
        return;
      }
    }


    if (!this.permissionService.hasPermission(NxtPermissionId.CalendarEventEdit_canMoveEventEarlierThan3Days) && this.dateIsChangedToShort()) {
      await this.dialogService.showOk('❌Termin kann nicht verschoben werden, 3 Tage-Regel. Termin kann nur auf Abgesagt gesetzen werden (Kaution wird einbehalten)!❌');
      return;
    }

    if (newData.customerPayed < 0) {
      await this.dialogService.showOk('❌Du kannst nicht mehr zurück zahlen, als der Kunde überhaupt bezahlt hat❌');
      return;
    }

    if (newData.customerPayed < newData.shouldDepositBackValue) {
      const newDepositBackValue = newPayments.filter(p => p.paymentType === 'deposit-back').reduce((sum, p) => sum + p.paymentValue, 0);
      if (newData.customerPayed < newData.shouldDepositBackValue - newDepositBackValue) {
        await this.dialogService.showOk('❌Mehr zurück zahlen als der Kunde bezahlt hat geht nicht❌');
        return;
      }
    }

    if (checkClosed && this.formGet('closed').value && !this.forceSaveHack && !this.reOpened) {
      if (payments.some(p => p.paymentType === 'payout')) {
        await this.dialogService.showOk('❌Termin kann nicht gespeichert werden!❌\nEr ist bereits eine Arist-Auszahlung');
        return;
      }
    }

    if (this.formGet('invoiceNumber').value && !this.forceSaveHack) {
      await this.dialogService.showOk('❌Termin kann nicht gespeichert werden!❌\nEs existiert bereits eine Rechnung / Gutschrift');
      return;
    }

    if (this.hasNewPayments('cash') && !!await this.getCanNotBookMessage() && !this.forceSaveHack) {
      await this.dialogService.showOk('Deine Kasse ist bereits geschlossen');
      return;
    }

    if (newData.start.dateFormatDate() === DateTools.formatNowDate() && this.loginService.isReception() && newPayments.length > 0) {
      if (newData.customer.birthday && BirthdayTools.getBirthdayInfo(newData.customer.birthday.dateParse()).age < 18) {
        this.dialogService.showOk('Achtung ' + newData.customer.givenName + ' ist noch nicht 18 Jahre alt!');
      }
    }

    if (newData.start.dateFormatDate() !== DateTools.formatNowDate() && this.loginService.isReception() && newPayments.some(p => ['rest', 'total'].includes(p.paymentType))) {
      const cancelIt = await this.dialogService.showYesNo('Der Termin ist nicht heute, bist du dir Sicher das du diesen Termin meinst?', {
        noText: 'Zurück',
        yesText: 'Ja, Speichern!',
      });
      if (!cancelIt) {
        return;
      }
    }

    if (this.artistNotAvailableText) {
      if (formValuesRaw?.artist?.id !== 'canceled') {
        if (!await this.dialogService.showYesNo('Artist ist nicht verfügbar, trotzdem speichern?')) {
          return;
        }
      }
    }

    if (this.formGet('workType').value === 'tattoo') {
      for (const t of formValuesRaw.bodyPutsTattoo) {
        if (t.motive.toLowerCase().split(' ').find(m => this.motiveBlacklist.includes(m))) {
          if (!await this.dialogService.showYesNo('Bist du dir sicher, dass "' + t.motive + '" eine genaue Motivbeschreibung ist?',
            {yesText: 'Ja, das ist korrekt', noText: 'Oh, das pass ich lieber nochmal an'})) {
            return;
          }
        }
      }
    }

    if (!this.checkArtistBodyPutBlackList()) {
      return;
    }
    this.log('checkArtistBodyPutBlackList');
    if (!this.isNewEvent && this.originalEvent().artistFix && newData.artistName !== this.originalEvent().artist) {
      if (!newData.artistName.includes('ABGESAGT')) {
        if (!await this.dialogService.showYesNo('Du möchtest den Fix-Artist ' + this.originalEvent().artist + ' auf ' + newData.artistName + ' ändern.\nBitte bestätigen', {
          yesText: 'Ja, ist korrekt', noText: 'Abbrechen',
        })) {
          return;
        }
      }
    }

    if (!this.isNewEvent && newData.fastWalkIn && this.originalEvent().startDateString !== newData.start.dateFormat('yyyy-MM-dd')) {
      if (!this.forceSaveHack) {
        await this.dialogService.showOk('Datumsänderung an einem Walk-In nicht möglich');
        return;
      }
    }

    if (!endChanged && this.customerPayedMoreThanPriceEstimatedTill > 0 && newPayments.length > 0) {
      if (await this.dialogService.showYesNo('Du hast nachkassiert, musst du dann nicht auch das Ende anpassen?', {
        yesText: 'Oh ja, ich passe es jetzt an',
        noText: 'Nein ' + timeTill + ' Uhr ist ok',
      })) {
        return;
      }
    }

    if (this.customerPayedMoreThanPriceEstimatedFrom > 0 && newPayments.length > 0) {
      customerPayedMoreReason = await this.dialogService.showInput('Warum hast du nachkassiert?');
      if (!customerPayedMoreReason) {
        return;
      }
    }


    if (!await this.checkPriceChangeBeforeSave(this.originalEvent().priceEstimatedFrom, newData.priceEstimatedFrom)) {
      return;
    }

    if (newPayments.some(p => p.paymentType === 'deposit-back' && p.paymentValue > 50) && !this.loginService.isBackoffice()) {
      if (await this.dialogService.showYesNo('Eine Rückzahlung größer 50 € muss mit dem Shop-Manager abgesprochen sein',
        {yesText: 'Nicht speichern und Zurück', noText: 'Ist mit dem Shop-Manager abgesprochen'})) {
        return;
      }
    }
    this.log('CalendarEventEdit_DisableFollowEventInfo');
    const futureEvents = this.customerEvents.filter(c => c.eventId !== this.eventId && c.start > newData.start);
    if (futureEvents.length > 0 && this.formGet('workType').value === 'tattoo' && !this.isNewEvent) {
      if (!this.permissionService.hasPermission(NxtPermissionId.CalendarEventEdit_DisableFollowEventInfo)) {
        await this.dialogService.showOk('Der Termin hat mindestens einen Folgetermin!\n\nBehalte im Auge wie weit der Artist heute kommt!', {
          buttonText: 'Ja, habe ich im Blick',
          title: 'Folgetermin-Info',
        });
      }
    }
    if (newData.artistCalendarNumber < 990 && !await this.checkOverlap({
      eventId: this.eventId,
      start: newData.start,
      end: newData.end,
      artist: newData.artistName,
      customerName: newData.customerName,
    })) {
      return;
    }

    if (newData.artistCalendarNumber < 990 && !await this.checkNotAvailable({
      eventId: this.eventId,
      start: newData.start,
      end: newData.end,
      artist: newData.artistName,
      customerName: newData.customerName,
    })) {
      return;
    }

    /*** Startzeit und Endzeit anpassen wenn von "Artist offen" zu "nicht Artist offen" & Empfang & heute **/
    if (!this.isNewEvent && this.loginService.isReception() && parseInt(this.originalEvent().calendarNumber, 10) > 990 && newData.artistCalendarNumber < 990 && newData.start.dateFormat('yyyy-MM-dd') === DateTools.formatNowDate()) {
      const oldStartTime = newData.start.dateFormat('HH:mm');
      const oldEndTime = newData.end.dateFormat('HH:mm');
      const duration = newData.end - newData.start;

      const newStartTimeAfterArtistChange = DateTools.formatNow('HH:mm');
      const newEndTimeAfterArtistChange = (Date.now() + duration).dateFormat('HH:mm');
      if (await this.dialogService.showYesNo('Du hast eben ein Artist zugewiesen, soll die Startzeit auf jetzt gesetzt werden?', {
        yesText: 'Ja, auf ' + newStartTimeAfterArtistChange + ' - ' + newEndTimeAfterArtistChange + ' Uhr setzen',
        noText: 'Nein, bei ' + oldStartTime + ' - ' + oldEndTime + ' Uhr bleiben',
      })) {
        newData.start = DateTools.parse(DateTools.formatNowDate() + ' ' + newStartTimeAfterArtistChange + ':00');
        newData.end = DateTools.parse(DateTools.formatNowDate() + ' ' + newEndTimeAfterArtistChange + ':00');
        if (newData.end < newData.start) {
          newData.end = DateTools.addDays(newData.end, 1);
        }
        if (duration < 0) {
          let logText = 'Artist offen -> ' + newData.artistName;
          logText += '\noldStartTime: ' + oldStartTime;
          logText += '\noldEndTime: ' + oldEndTime;
          logText += '\nnewStartTimeAfterArtistChange: ' + newStartTimeAfterArtistChange;
          logText += '\nnewEndTimeAfterArtistChange: ' + newEndTimeAfterArtistChange;
          logText += '\nduration: ' + duration;
          logText += '\ndurationMin: ' + (duration / DurationTools.DURATION_1MINUTE);
          logText += '\n' + StudioRegionBrowserTools.getLinkForEvent(this.eventId);
          this.socketService.sendTelegramAdmin(logText);
        }
      }
    }

    if (!await this.checkArtistSkillBeforeSave()) {
      return;
    }

    if (this.isNewEvent && (newData.start + DurationTools.DURATION_1HOUR) < Date.now() && !this.forceSaveHack) {
      this.dialogService.showOk('Du kannst keinen Termin in die Vergangenheit anlegen,\nbitte kontrolliere das Datum!');
      return;
    }
    if (!this.beforeSaveHook({
      newPayments,
      newCustomer: newData.customer,
      newDateString: this.formGet('date').value,
    })) {
      return;
    }


    /*** AB HIER KEIN RETURN MEHR **/


    if (!this.isNewEvent) {
      if (!this.configService.config.value.isFranchise && Math.abs(this.originalEvent().artistPercentage - newData.artistPercentage) > 1 && this.originalEvent().workType === 'piercing') {
        this.telegramService.sendBackofficeHeads(this.loginService.getUsername() + ' ändert die Artist-Prozente\n' + this.originalEvent().artistPercentage + '% ' + StringTools.arrowRight + ' ' + newData.artistPercentage + '%\n' + StudioRegionBrowserTools.getLinkForEvent(this.eventId));
      }
    }


    await this.checkShouldDeposit();
    this.log('checkShouldDeposit');
    this.saveIsRunning = true;
    // wenn kasse schon geschlossen && neue zahlung cash rein kommt, speichern nicht möglich
    let loadingText = 'Termin wird ' + (this.isNewEvent ? 'angelegt' : 'gespeichert') + '...';
    if (recursiveCounter > 0) {
      loadingText += '\n\nVersuch: ' + recursiveCounter;
    }
    this.dialogService.showLoading(LoadingId.CalendarEventEditSave, loadingText);

    // let success = false;
    let savedEvent: NxtCalendarEvent | undefined;
    try {
      const eventToSave = clone(this.originalEvent())!;
      /*** TITEL */
      eventToSave.title = this.formGet('title')!.value;
      eventToSave.start = newData.start;
      eventToSave.end = newData.end;
      let depotDueDate = '';
      if (this.formGet('depotDueDate')!.value) {
        depotDueDate = DateTools.format(this.formGet('depotDueDate')!.value, 'yyyy-MM-dd');
      }
      const info = this.prependFollowUpText(this.formGet('info')!.value, this.followUp);

      if (!this.artistAssignedAt) {
        this.artistAssignedAt = 0;
      }
      if (this.artistAssignedAt === 0 && newData.artistCalendarNumber < 990) {
        this.artistAssignedAt = Date.now();
      }
      eventToSave.customer = this.formGet('customer')?.value?.id;
      eventToSave.customerRefId = this.formGet('customerRefId')?.value;
      eventToSave.priceEstimatedFrom = this.formGet('priceEstimatedFrom').value;
      eventToSave.priceEstimatedTill = this.formGet('priceEstimatedTill').value;
      eventToSave.closed = this.formGet('closed').value;
      eventToSave.artistPercentage = this.formGet('artistPercentage')!.value;
      eventToSave.info = info;
      eventToSave.importantInfo = this.formGet('importantInfo').value;
      eventToSave.invoiceNumber = this.formGet('invoiceNumber').value;
      eventToSave.priceFix = this.formGet('priceFix').value;
      eventToSave.priceAuto = this.formGet('priceAuto').value;
      eventToSave.artistFix = this.formGet('artistFix').value;
      eventToSave.artistFixReason = this.formGet('artistFixReason').value;
      eventToSave.durationPriceInfo = this.formGet('durationPriceInfo').value;
      eventToSave.noDepotNecessary = this.formGet('noDepotNecessary').value;
      eventToSave.noDepotNecessaryReason = this.formGet('noDepotNecessaryReason').value;
      eventToSave.depotDueDate = depotDueDate;
      eventToSave.depotDueDateReason = this.formGet('depotDueDateReason').value;
      eventToSave.workType = this.formGet('workType').value;
      eventToSave.discountPromotion = this.formGet('discountPromotion').value;
      eventToSave.noAppointmentReminder = this.formGet('noAppointmentReminder').value;
      eventToSave.improveArtistShouldGetMoney = this.formGet('improveArtistShouldGetMoney').value;
      eventToSave.canceledApproved = this.formGet('canceledApproved').value;
      eventToSave.canceledReason = this.formGet('canceledReason').value;
      eventToSave.canceledAt = this.formGet('canceledAt').value;
      eventToSave.canceledInTime = this.formGet('canceledInTime').value;
      eventToSave.adjustPrice = this.formGet('adjustPrice').value;
      eventToSave.fastWalkIn = this.formGet('fastWalkIn').value;
      eventToSave.fastWalkInNo = this.formGet('fastWalkInNo').value;
      eventToSave.shouldDepositBack = this.formGet('shouldDepositBack').value;
      eventToSave.shouldDepositBackValue = this.formGet('shouldDepositBackValue').value;
      eventToSave.shouldDepositBackCreatedAt = this.formGet('shouldDepositBackCreatedAt').value;
      eventToSave.promoOfferPromoterName = this.formGet('promoOfferPromoterName').value;
      eventToSave.promoOfferCreatedAt = this.formGet('promoOfferCreatedAt').value || 0;
      eventToSave.improve = !!this.formGet('improve').value;
      eventToSave.followUp = this.followUp;
      eventToSave.priceChanges = this.priceChanges;
      eventToSave.skill = this.skill;
      eventToSave.artistAssignedAt = this.artistAssignedAt;
      eventToSave.promoOfferId = this.formGet('promoOfferId').value;
      eventToSave.problems = this.formGet('problems').value;
      eventToSave.disableSkillCheck = !!this.formGet('disableSkillCheck').value;
      eventToSave.artistPercentageConfirmed = !!this.formGet('artistPercentageConfirmed').value;
      eventToSave.improveOtherArtistCheckState = this.formGet('improveOtherArtistCheckState').value;
      eventToSave.bodyPuts = {tattoo: formValuesRaw.bodyPutsTattoo, piercing: formValuesRaw.bodyPutsPiercing, toothGem: formValuesRaw.bodyPutsToothGem};
      eventToSave.toPayOnEventDate = this.formGet('toPayOnEventDate').value || [];
      eventToSave.payments = [];
      for (const payment of payments) {
        const originalPayment = this.originalEvent().payments.find(p => p.paymentUuid === payment.paymentUuid);
        if (originalPayment) {
          eventToSave.payments.push({...originalPayment, ...payment});
        } else {
          eventToSave.payments.push(payment);
        }
      }
      // checken ob es boolean sind
      if (payments.length > 0 || eventToSave.noDepotNecessary) {
        eventToSave.depotDueDate = '';
        eventToSave.depotDueDateReason = '';
      }
      /*** END PAYMENTS */
      this.lastSaved = Date.now();
      if (this.customerPayedMoreThanPriceEstimatedFrom > 0 && newPayments.length > 0) {
        eventToSave.priceEstimatedFrom = this.customerPayed;
        if (!customerPayedMoreReason) {
          customerPayedMoreReason = 'automatisch:  "Preis bis" ausgereizt';
        }
        this.priceChanges.push({
          from: formValuesRaw.priceEstimatedFrom,
          to: this.customerPayed,
          t: Date.now(),
          u: this.loginService.getUsername(),
          i: customerPayedMoreReason,
        });
        eventToSave.priceChanges = this.priceChanges;
        if (formValuesRaw.priceEstimatedTill && formValuesRaw.priceEstimatedTill < this.customerPayed) {
          eventToSave.priceEstimatedTill = null;
          formValuesRaw.priceEstimatedTill = null;
        }
        if (formValuesRaw.discountPromotion && formValuesRaw.discountPromotion.priceFixValue) {
          const artistShouldGet = MathTools.roundMoney(formValuesRaw.artistPercentage * formValuesRaw.priceEstimatedFrom / 100);
          eventToSave.artistPercentage = MathTools.round(artistShouldGet / this.customerPayed * 100, 5);
          formValuesRaw.artistPercentage = newData.artistPercentage;
        }
        formValuesRaw.priceEstimatedFrom = this.customerPayed;
        if (formValuesRaw.priceEstimatedFrom === formValuesRaw.priceEstimatedTill) {
          eventToSave.priceEstimatedTill = null;
          formValuesRaw.priceEstimatedTill = null;
        }
      }
      if (eventToSave.priceEstimatedFrom === eventToSave.priceEstimatedTill) {
        eventToSave.priceEstimatedTill = null;
      }
      eventToSave.artist = this.formGet('artist').value.name;
      eventToSave.nextNxtUpdateId = this.nextNxtUpdateId;
      if (this.isNewEvent) {
        /*** NEUER TERMIN **/
        const createdEvent = await this.socketService.upsertCalendarEvent2(eventToSave, this.newTattooTemplates(), this.nextNxtUpdateId);
        this.tattooTemplateService.clear(this.eventId);
        this.clearChanges();
        this.eventId = createdEvent.id;
        if (createdEvent.fastWalkIn) {
          this.formGet('fastWalkInNo').setValue(createdEvent.fastWalkInNo);
        }
        this.afterSaved.emit(createdEvent);
        this.afterClosed.emit({op: 'created', newCalendarEvent: createdEvent});
        if (createdEvent.fastWalkIn) {
          if (this.loginService.getStudio() !== 'Staging') {
            this.dialogService.updateLoadingText('Walk-In-Nummer wird gedruckt');
            await this.printFastWalkInNo(createdEvent.fastWalkInNo, createdEvent.workType);
            if (this.isArtistOpen() && this.configService.config.value.printSecondWalkInNoIfArtistOpen) {
              await this.printFastWalkInNo(createdEvent.fastWalkInNo, createdEvent.workType);
            }
          }
        }
        savedEvent = createdEvent;
      } else {
        /*** TERMIN WURDE BEARBEITET **/
        const updatedEvent = await this.socketService.upsertCalendarEvent2(eventToSave, this.newTattooTemplates(), this.nextNxtUpdateId);
        this.tattooTemplateService.clear(this.eventId);
        savedEvent = updatedEvent;
        this.clearChanges();
        this.afterSaved.emit(updatedEvent);
        this.afterClosed.emit({op: 'updated', newCalendarEvent: updatedEvent});
      }
      if (this.isNewEvent || startChanged || newPayments.length > 0 || this.isCanceled) {
        if (this.loginService.isBackoffice()) {
          await this.showAppointmentConfirmationText();
        }
      }
      this.dialogService.hideLoading(LoadingId.CalendarEventEditSave);
      await this.checkToPayOnEventDate(savedEvent);
      if (this.isNewEvent && this.loginService.isReception() && this.formGet('fastWalkIn').value) {
        const artist: NxtArtist = this.formGet('artist')?.value;
        if (artist && !ArtistsTools.isArtistOpen(artist.name)) {
          await this.printQrEvent(false);
        }
      }

      if (this.actionAfterSaved) {
        switch (this.actionAfterSaved) {
          case 'print-qr':
            await this.printQrEvent(false);
            break;
          case  'appointment-reminder':
            await this.showAppointmentConfirmationText();
            break;
        }
      }
    } catch (err) {

      this.saveIsRunning = false;
      if (err.message.includes('Belegdrucker reagiert nicht')) {
        this.thermalPrinterService.showChangeIp(err.message).then();
      } else {
        Log.error(err);
      }
    }
    this.dialogService.hideLoading(LoadingId.CalendarEventEditSave);
    this.saveIsRunning = false;
    if (this.eventId) {
      this.showReloadEvent = true;
    }
    return savedEvent;
  }

  async closeWithoutSaveClicked() {
    if (this.changes().length > 0) {
      const changedText = '- ' + this.changes().join('\n- ');
      if (await this.dialogService.showYesNo('Änderungen gehen verloren, wirklich ohne Speichern schließen?\n\nDeine Änderungen:\n' + changedText, {
        yesText: 'Ohne Speichern schließen',
        noText: 'Zurück',
      })) {
        this.close(true);
      }
    } else {
      this.close(true);
    }
  }

  async close(emitNotSavedEvent: boolean) {
    this.closeEditContact();
    if (!this.dialogRef) {
      IframeMessageManagerInIframe.instance.hideIframe('calendar-event-edit close');
    }

    this.showContent = false;
    IframeMessageManagerInIframe.instance.sendEval(IframeParentCommand.closeGoogleEvent);
    this.dialogRef?.close();
    if (emitNotSavedEvent) {
      this.afterClosed.emit({op: 'canceled'});
    }

    try {
      if (this.eventIdIsInUrl) {
        window.close();
      }
    } catch (err) {
    }
  }

  hasMotiveDescriptionWithoutSkill() {
    const workType = this.formGet('workType').value;
    if (workType !== 'tattoo') {
      return;
    }
    const missingSkills = EventTools.getMissingSkills(this.skill, this.formGet('bodyPutsTattoo').getRawValue(), this.availableSkills);
    if (missingSkills.length > 0) {
      // this.socketService.sendTelegramAdmin('Motive-Beschreibung ohne Skill\nFehlende Skills: ' + missingSkills.join(', ') + '\n\n' + JsonTools.stringifyFormat(this.formGet('bodyPutsTattoo').getRawValue()));
      if (missingSkills.length === 1) {
        this.dialogService.showOk('Es steht "' + missingSkills[0] + '" in der Motiv-Beschreibung, der Stil ist aber nicht ausgewählt!');
      } else {
        this.dialogService.showOk('Es steht "' + missingSkills.join(' & ') + '" in der Motiv-Beschreibung, die Stile sind aber aber nicht ausgewählt!');
      }
      return true;
    }
  }

  async saveAndCloseDialog(closeCalendarEvent: boolean, hideDialog = true) {
    if (this.saveAndCloseDialogRunning) {
      return;
    }
    this.saveAndCloseDialogRunning = true;
    let doIt = false;
    let errors = FormTools.getErrors(this.form);
    errors.push(...this.getAdditionalErrors());
    if (this.forceSaveHack) {
      errors = errors.filter(e => e !== 'skill');
    }
    if (errors.length > 0) {
      const errorText = 'Na, hast du nicht was vergessen einzutragen?\n\n' + this.getErrorText(errors);
      await this.dialogService.showOk(errorText, {title: 'Fehlende Eingaben!'});
      this.saveAndCloseDialogRunning = false;
      return;
    } else {
      if (closeCalendarEvent) {
        if (await this.checkForCloseEvent()) {
          const result = await this.dialogService.showYesNo('Termin abschließen?', {yesText: 'Ja, Termin abschließen'});
          if (result) {
            if (this.checkBeforeClose()) {
              this.formGet('closed').setValue(true);
              doIt = true;
            }
          }
        }
      } else {
        doIt = true;
      }
    }
    if (doIt) {
      const savedEvent = await this.save(!closeCalendarEvent);
      if (savedEvent) {
        if (!this.dialogRef) {
          IframeMessageManagerInIframe.instance.hideIframe('calendar-event-edit saveAndClose');
        }
        this.showContent = false;
        if (hideDialog) {
          IframeMessageManagerInIframe.instance.sendEval(IframeParentCommand.closeGoogleEvent);
          this.dialogRef?.close();
        }
        this.saveAndCloseDialogRunning = false;
        return savedEvent;
      } else {
        this.saveAndCloseDialogRunning = false;
        return;
      }
    }
    this.saveAndCloseDialogRunning = false;
    if (!doIt) {
      // kann eig nicht sein
      debugger;
    }
  }

  async editContact() {
    this.showContactFormById(this.formGet('customer').value.id);
  }

  private closeEditContact() {
    if (this.editContactDialogRef) {
      this.editContactDialogRef.close();
    }
    this.isEditContact = false;
  }

  private saveAndCloseEditContact() {
    this.editContactDialogRef.componentInstance.saveAndClose();
    this.isEditContact = false;
  }


  async toggleShowDebug() {
    this.showDebug = !this.showDebug;
    if (this.showDebug) {
      (window as any).debug = true;
    } else {
      delete (window as any).debug;
    }
  }

  public getFormPayments(): UntypedFormArray {
    return (this.form?.get('payments') as UntypedFormArray);
  }

  private unRegisterFormControlChangeListener() {
    this.formValueChangesSubscriptions.forEach(sub => sub?.unsubscribe());
  }

  private async registerFormControlChangeListener() {
    await this.waitForForm();
    this.unRegisterFormControlChangeListener();
    this.formValueChangesSubscriptions.length = 0;

    this.formValueChangesSubscriptions.push(
      this.formGet('priceFix')?.valueChanges.subscribe(() => {
        this.priceFix = this.formGet('priceFix')?.value;
        const discountPromotion = this.formGetValue<NxtDiscountPromotion>('discountPromotion');
        if (discountPromotion) {
          discountPromotion.priceFix = this.priceFix;
        }
      }));

    this.formValueChangesSubscriptions.push(
      this.formGet('timeFrom')?.valueChanges.subscribe(() => {
        this.calcDurationText();
        this.dateOrTimeOrPriceOrPaymentsChanged();
      }));

    this.formValueChangesSubscriptions.push(
      this.formGet('timeTill')?.valueChanges.subscribe(() => {
        this.calcDurationText();
        this.dateOrTimeOrPriceOrPaymentsChanged();
      }));


    this.formValueChangesSubscriptions.push(
      this.formGet('customer')?.valueChanges.subscribe(() => {
        this.calcCustomerText();
        this.checkShowAddCustomer();
        this.calcIsFirstCustomerEvent();
        if (this.isNewEvent) {
          // this.showCustomerChangeInfo();
        }
      }));

    this.formValueChangesSubscriptions.push(
      this.formGet('title')?.valueChanges.subscribe(() => {
        this.checkShowAddCustomer();
      }));


    this.formValueChangesSubscriptions.push(
      this.formGet('date')?.valueChanges.subscribe(() => {
        this.getAvailableArtistThisDay();
        this.checkPublicHoliday();
        this.dateOrTimeOrPriceOrPaymentsChanged();
        this.calcDepotDueDate();
        this.checkArtistAvailable();
      }));


    this.formValueChangesSubscriptions.push(
      this.formGet('priceEstimatedFrom').valueChanges.subscribe((value) => {
        this.dateOrTimeOrPriceOrPaymentsChanged();
        this.priceEstimatedFromChanged(value);
      }));

    this.formValueChangesSubscriptions.push(
      this.formGet('artist').valueChanges.subscribe(async (artist: NxtArtist) => {
        if (typeof artist === 'string') {
          return;
        }
        const isCanceled = artist?.id === 'canceled';
        if (artist?.workType !== this.formGet('workType').value && !isCanceled) {
          if (artist.toothGem && this.formGet('workType').value === 'tooth-gem') {
            // lassen
          } else {
            this.formGet('workType').setValue(artist?.workType);
          }
        }
        this.artistSmoothHeightTrigger = Math.random();
        this.checkArtistAvailable();
        this.setIsCanceled(artist?.id === 'canceled');
        /*const oldArtist = (this.formGet('artist') as NxtFormControl).getOldValue();
        if (oldArtist !== value) {
          this.artistHasChangedNotInit(oldArtist, value);
        }*/
        this.checkArtistBodyPutBlackList();
        this.calcArtistSkill();
        this.checkArtistEmployed();

        if (!this.isNewEvent && this.loadEventDone.value && this.originalEvent().status === 'canceled' && !isCanceled) {
          await TimeTools.waitAnimationFrame();
          const canceledDuration = Date.now() - this.originalEvent().canceledAt;
          if (canceledDuration > DurationTools.DURATION_1HOUR) {
            if (this.loginService.isJulian() && this.forceSaveHack) {
              this.dialogService.showOk('Das geht eig nicht, Achtung evtl. schon Export zu Datev');
            } else {
              this.dialogService.showOk('Abgesagt ist abgesagt\nLege einen neuen Termin an');
              const artist = this.artists.find(a => a.id === 'canceled');
              if (artist) {
                this.formGet('artist').setValue(artist);
              }
            }
          }
        }
      }));


    this.formValueChangesSubscriptions.push(
      this.formGet('noDepotNecessary').valueChanges.subscribe(() => {
        this.checkNoDepotNecessaryReason();
      }));

    this.formValueChangesSubscriptions.push(
      this.formGet('depotDueDate')?.valueChanges.subscribe(() => {
      }));

    this.formValueChangesSubscriptions.push(
      this.formGet('fastWalkIn')?.valueChanges.subscribe((fastWalkIn) => {

        const fastWalkInAndNewEvent = fastWalkIn && this.isNewEvent;
        FormTools.setValidators(this.formGet('customer'), fastWalkInAndNewEvent ? [] : [ValidatorTools.validCustomer, ValidatorTools.requiredAndNotNaN]);

        // this.formGet('priceEstimatedFrom').setValidators(fastWalkInAndNewEvent ? [] : [ValidatorTools.money]);
        if (fastWalkInAndNewEvent && this.formGet('workType').value === 'piercing') {
          this.formGet('priceEstimatedFrom').setValidators([ValidatorTools.money]);
        }
        this.formGet('priceEstimatedFrom').updateValueAndValidity({emitEvent: false});
        (this.formGet('bodyPutsPiercing') as FormArray).controls.forEach(fg => {
          FormTools.setValidators(fg.get('bodyPut'), fastWalkInAndNewEvent ? [] : [ValidatorTools.requiredAndNotNaN]);
        });
      }));

    this.formValueChangesSubscriptions.push(
      this.formGet('shouldDepositBack').valueChanges.subscribe((value) => {
        this.shouldDepositBackChanged(value);
      }),
    );

    this.formValueChangesSubscriptions.push(
      this.formGet('shouldDepositBackValue').valueChanges.subscribe(async (value) => {
        if (!this.isNewEvent) {
          if (this.originalEvent().shouldDepositBackValue !== value) {
            this.formGet('shouldDepositBackCreatedAt').setValue(Date.now());
          }
        } else {
          if (value) {
            this.formGet('shouldDepositBackCreatedAt').setValue(Date.now());
          }
        }
      }),
    );
    this.formValueChangesSubscriptions.push(
      this.formGet('workType').valueChanges.subscribe(async () => {
        this.workTypeChanged();
      }),
    );

    this.formValueChangesSubscriptions.push(
      this.formGet('bodyPutsBeauty').valueChanges.subscribe(async () => {
        debugger;
      }),
    );

    this.formValueChangesSubscriptions.push(
      this.formGet('priceAuto').valueChanges.subscribe(async () => {
        this.calcAutoPrice();
      }),
    );


    this.formValueChangesSubscriptions.push(
      this.formGet('improve').valueChanges.subscribe(async (value) => {
        if (this.isNewEvent) {
          if (value && this.loadEventDone.value) {
            if (!await this.dialogService.showYesNo('Ist der Ursprüngliche Termin aus einem anderen Studio?', {yesText: 'Ja anderes Studio'})) {
              this.dialogService.showOk('So legt man keine Nachstechtermine an, informiere dich wie es geht!');
              setTimeout(() => {
                this.formGet('improve').setValue(false);
              }, 200);
            }
          }
        }
        if (!this.isNewEvent && value) {
          if (!this.originalEvent().improve) {
            this.dialogService.showOk('Aus einem normalen Termin kannst du keinen Nachstechtermin machen.\n➞ Termin neu anlegen');
            setTimeout(() => {
              this.formGet('improve').setValue(false);
            }, 200);
            return;
          }
        }
        if (!value) {
          this.formGet('improveArtistShouldGetMoney').setValue(false);
        }
      }),
    );
  }

  shouldDepositBackChanged(value: boolean) {
    if (this.form) {
      if (value) {
        if (!this.originalEvent().shouldDepositBack) {
          // wird neu gesetzt, nicht zulassen bei Bank-Zahlungen
          if (false && this.getCurrentPayments().some(p => p.paymentMethod === 'bank')) {
            this.dialogService.showOk(('NEU: offene Bank-Rückzahlungen bitte direkt an der Zahlung vornehmen'));
            setTimeout(() => {
              this.formGet('shouldDepositBack').setValue(false);
            }, 200);
          } else {
            this.formGet('shouldDepositBackValue').setValidators([ValidatorTools.requiredAndNotNaN]);
          }
        }
      } else {
        this.formGet('shouldDepositBackValue').setValidators([]);
        this.formGet('shouldDepositBackValue').setValue(null);
      }
      this.formGet('shouldDepositBackValue').updateValueAndValidity({emitEvent: false});
    }
  }


  private checkPublicHoliday() {
    const value = this.form?.get('date').value;
    if (!value) {
      return;
    }
    const publicHoliday = PublicHolidaysTools.getPublicHoliday(value, this.configService.config.value.invoiceData.fromRegion);
    if (publicHoliday) {
      this.dialogService.showOk('Feiertag!\n' + DateTools.format(value, 'dd.MM.yyyy') + ' ist ' + publicHoliday.name);
    }
  }


  private displayLog(text) {
    this.displayLogArray.push('[' + DateTools.now.format('HH:mm:ss') + '] ' + text);
    if (this.displayLogArray.length > 10) {
      this.displayLogArray.shift();
    }
  }


  getTimeChooserText = () => {
    return DateTools.format(this.formGet('date').value, 'EEE dd.MM.yyyy') + '<br/>' + this.formGet('timeFrom').value + ' - ' + this.formGet('timeTill').value;
  };

  async bodyPutClick(index: number) {
    this.isButtonChooserOpen = true;
    const result = await this.bodyPutService.showBodyPutChooser();
    if (result) {
      this.getFormControlGroups('bodyPutsTattoo')[index].get('bodyPut').setValue(result);
    }
    this.isButtonChooserOpen = false;
  }

  async bodyPutPiercingClick(index) {
    this.isButtonChooserOpen = true;
    const result = await this.bodyPutService.showBodyPutPiercingChooser();
    if (result) {
      this.getFormControlGroups('bodyPutsPiercing')[index].get('bodyPut').setValue(result);
    }
    this.isButtonChooserOpen = false;
  }

  async bodyPutBeautyClick(index: number) {
    this.isButtonChooserOpen = true;
    const result = await this.bodyPutService.showBodyPutBeautyChooser();
    if (result) {
      this.getFormControlGroups('bodyPutsBeauty')[index].get('bodyPut').setValue(result);
    }
    this.isButtonChooserOpen = false;
  }

  async bodyPutToothGemClick(index: number) {
    this.isButtonChooserOpen = true;
    const result = await this.bodyPutService.showBodyPutToothGemChooser();
    if (result) {
      this.getFormControlGroups('bodyPutsToothGem')[index].get('bodyPut').setValue(result);
    }
    this.isButtonChooserOpen = false;
  }


  private getErrorText(errors: string[]): string {
    const texts = {
      title: 'Titel',
      customer: 'Kunde',
      artist: 'Artist',
      bodyPut: 'Körperstelle',
      priceEstimatedFrom: 'Preisabsprache ca. von',
      paymentComment: 'Zahlungsinfo',
      noDepotNecessaryReason: 'Warum ist keine Kaution nötig',
      date: 'Datum',
      paymentPaypalTransaction: 'wähle eine Paypal-Buchung aus',
      paymentValue: 'Betrag fehlt',
      paymentKlarnaOrder: 'wähle eine Klarna-Buchung aus',
      paymentGiftCard: 'wähle einen Gutschein aus',
      paymentBankTransaction: 'wähle eine Bank-Zahlung aus',
      tattooMotive: 'Motiv',
      durationPriceInfo: 'Warum passt der Preis zur Dauer nicht?',
      depotDueDate: 'Wann kommt die Kaution',
      depotDueDateReason: 'Warum kommt die Kation verspätet',
      canceledReason: 'Warum wurde der Termin abgesagt?',
      shouldDepositBackValue: 'Wie viel soll zurück gezahlt werden?',
      skill: 'Stil',
      motive: 'Motiv',
      size: 'Größe',
    };
    return '- ' + errors.map(error => texts[error] ? texts[error] : error).join('\n- ');
  }

  /*freeImprovementChanged() {
    // requestAnimationFrame(() => {
    const freeExists = this.getFormPayments().controls.filter(control => control.get('paymentType').value === 'free').length > 0;
    if (this.isFree) {
      if (!this.formGet('priceFix').value) {
        this.formGet('priceFix').setValue(true);
      }
      if (!this.formGet('noDepotNecessary').value) {
        this.formGet('noDepotNecessary').setValue(true);
      }
      if (!this.formGet('noDepotNecessaryReason').value) {
        this.formGet('noDepotNecessaryReason').setValue('kostenlos');
      }
      if (this.formGet('priceEstimatedFrom').value !== 0) {
        this.formGet('priceEstimatedFrom').setValue(0);
      }
    } else if (freeExists) {
      this.getFormPayments().controls = this.getFormPayments().controls.filter(control => control.get('paymentType').value !== 'free');
      this.formGet('noDepotNecessary').setValue(false);
    }
    this.generateTitle();
    this.paymentsChanged();
    // });
  }*/

  /*private async checkIsFreeImprovement() {
    await this.waitForForm();
    Log.debug('KOSTENLOS: start checkDisablePayment');
    // const freeFormGroup = (this.getFormPayments().controls as UntypedFormGroup[]).find((formGroup) => formGroup.getRawValue().paymentType === 'free');
    const notFreeFormGroup = (this.getFormPayments().controls as UntypedFormGroup[]).find((formGroup) => formGroup.getRawValue().paymentValue >= 0.01);
    // Log.debug('KOSTENLOS: ' + (freeFormGroup ? freeFormGroup.getRawValue() : ' nicht gefunden'));
    if (this.formGet('priceEstimatedFrom').value === 0 && !this.isFree) {
      Log.debug('KOSTENLOS: set isFreeImprovement = true and disable form-controls');
      this.isFree = true;
      // freeFormGroup.get('paymentValue').disable();
      // freeFormGroup.get('paymentType').disable();
      // freeFormGroup.get('paymentMethod').disable();
      // freeFormGroup.get('paymentDate').disable();
    } else if (this.formGet('priceEstimatedFrom').value > 0 && this.isFree) {
      Log.debug('KOSTENLOS: set isFreeImprovement = false');
      this.isFree = false;
    }
    this.disableFreeToggle = !!notFreeFormGroup;
  }*/


  triggerPaymentHeight() {
    this.paymentSmoothHeightTrigger = Math.random();
  }

  paymentsChanged() {
    this.setChanged('neue Zahlung');
    this.calcHasArtistPayment();
    this.calcDepotDueDate();
    this.triggerPaymentHeight();
    this.checkNoDepotNecessaryReason();
    this.dateOrTimeOrPriceOrPaymentsChanged();

    if (this.getFormPayments().length > 0) {
      this.formGet('priceEstimatedFrom').setValidators([ValidatorTools.money]);
      this.formGet('priceEstimatedFrom').updateValueAndValidity({emitEvent: false});
    }
  }

  private async subscribeSocketEvents() {
    this.pushSocketSubscription = this.socketService.subscribeNew('calendarEventChanged', async (eventChangeData) => {
      if (this.showContent && !this.isNewEvent && eventChangeData.record.id === this.originalEvent().id) {
        if (this.nextNxtUpdateId !== eventChangeData.record.nxtUpdateId) {
          if (eventChangeData.userContext.context === 'onlyToPayOnEventDate') {
            this.originalEvent.update(originalEvent => {
              originalEvent.toPayOnEventDate = eventChangeData.record.toPayOnEventDate;
              originalEvent.seqId = eventChangeData.record.seqId;
              originalEvent.nxtUpdateId = eventChangeData.record.nxtUpdateId;
              return {...originalEvent};
            });
            this.formGet('toPayOnEventDate').setValue(eventChangeData.record.toPayOnEventDate);
          } else if (eventChangeData.record.updatedBy === this.loginService.getUsername()) {
            this.dialogService.hideLoading();
            this.reload();
          } else {
            if (eventChangeData.record.updatedBy === 'NewEventFile') {
              this.originalEvent.update(originalEvent => {
                originalEvent.files = eventChangeData.record.files;
                originalEvent.seqId = eventChangeData.record.seqId;
                originalEvent.nxtUpdateId = eventChangeData.record.nxtUpdateId;
                return {...originalEvent};
              });
              this.loadPhotos('NewEventFile').then();
              this.eventFileThumbReloadIndicator.set(Date.now().toString());
            } else {
              await this.dialogService.showOk('Der Termin wurde zwischenzeitlich von ' + eventChangeData.record.updatedBy + ' geändert\nDeine Änderungen gehen leider verloren', {buttonText: 'Termin neu laden'});
              this.reload();
            }
          }
        }
      }
    });

    this.pushSocketSubscription = this.socketService.subscribeNew('newEventPhotoAvailable', async (eventId) => {
      if (this.showContent && eventId === this.eventId) {
        this.loadPhotos('newEventPhotoAvailable');
      }
    });


    this.pushSocketSubscription = this.socketService.subscribeNew('getNotAssignedBankTransactions', (notAssignedBankTransactions) => {
      this.notAssignedBankTransactionsIn = notAssignedBankTransactions.filter(trans => trans.direction === 'in').sort(SortTools.sortDate('createdAt', true));
      this.notAssignedBankTransactionsOut = notAssignedBankTransactions.filter(trans => trans.direction === 'out').sort(SortTools.sortDate('createdAt', true));
    }, {emitInitial: true});


    this.pushSocketSubscription = this.socketService.subscribeNew('getNotAssignedPaypalTransactions', (notAssignedPaypalTransactionsAll2) => {
      notAssignedPaypalTransactionsAll2 = SortTools.sort(notAssignedPaypalTransactionsAll2, 'transactionTime', true);
      this.notAssignedPaypalTransactionsIn = notAssignedPaypalTransactionsAll2.filter(trans => trans.direction === 'in');
      this.notAssignedPaypalTransactionsOut = notAssignedPaypalTransactionsAll2.filter(trans => trans.direction === 'out');
    }, {emitInitial: true});

    this.pushSocketSubscription = this.socketService.subscribeNew('getNotAssignedKlarnaOrders', (notAssignedKlarnaOrders) => {
      notAssignedKlarnaOrders = SortTools.sort(notAssignedKlarnaOrders, 'createdAt', true);
      this.notAssignedKlarnaOrdersIn = notAssignedKlarnaOrders.filter(trans => trans.direction === 'in' || !trans.direction);
      this.notAssignedKlarnaOrdersOut = notAssignedKlarnaOrders.filter(trans => trans.direction === 'out');
    }, {emitInitial: true});

    this.pushSocketSubscription = this.socketService.subscribeNew('setContactDataFromWhatsApp', (data) => {
      this.addContactFromWhatsApp(data);
    }, {emitInitial: true});

    this.pushSocketSubscription = this.socketService.subscribeNew('fromWhatsAppSetCustomerRefId', (customerRefId) => {
      this.setCustomerRefId(customerRefId);
    }, {emitInitial: true});

    this.pushSocketSubscription = this.socketService.subscribeNew('eventArtistChanged', (data) => {
      const currentArtistId = this.formGet('artist')?.value?.id;
      if (currentArtistId === data.record.id) {
        this.formGet('artist').setValue(data.record);
        this.calcArtistSkill();
      }
      this.addContactFromWhatsApp(data);
    });
  }

  private checkBeforeClose() {
    if (this.formGet) {
      return true;
    }
  }

  private generateTitle() {
    if (!this.form) {
      return;
    }
    const separator = ' | ';
    let newTitle = '';

    if (this.formGet('fastWalkInNo').value) {
      if (this.formGet('workType').value === 'piercing') {
        newTitle += separator + '#P' + this.formGet('fastWalkInNo').value;
      } else {
        newTitle += separator + '#' + this.formGet('fastWalkInNo').value;
      }
    }


    const customer = this.formGet('customer').value;
    if (customer?.givenName) {
      newTitle += separator + customer.givenName + ' ' + customer.familyName;
    }

    const depositsSumValue = PaymentTools.getPaymentSumByPaymentTypeControl(this.getFormPayments().controls, [PaymentTypes.Deposit]);
    let customerPayedValue = PaymentTools.getPaymentSumByPaymentTypeControl(this.getFormPayments().controls, PaymentTools.customerPayedPaymentTypes);
    customerPayedValue -= PaymentTools.getPaymentSumByPaymentTypeControl(this.getFormPayments().controls, [PaymentTypes.DepositBack]);
    const deposits = this.getFormPayments().controls.filter(control => control.get('paymentType').value === 'deposit');
    const completes = this.getFormPayments().controls.filter(control => control.get('paymentType').value === PaymentTypes.Complete);

    if (this.formGet('noDepotNecessary').value) {
      // newTitle += seperator + ' KAUTION FEHLT';
    } else {
      if (completes.length === 0 && deposits.length === 0 && this.formGet('priceEstimatedFrom').value > 0) {
        newTitle += separator + ' KAUTION FEHLT';
      }
    }


    // let depositsSum = 0;

    if (depositsSumValue > 0) {
      for (const control of deposits) {
        newTitle += separator + ' Kaution: ' + DecimalTools.toMoneyString(control.get('paymentValue').value, '€', false) + ' via ' + PaymentTools.getPaymentMethodText(control.get('paymentMethod').value);
        // depositsSum += parseFloat(control.get('paymentValue').value);
      }
    } else if (deposits.length > 1) {
      // Kaution mit 0 Euro
      newTitle += separator + 'keine Kaution nötig';
    }

    const hasFrom = !!this.formGet('priceEstimatedFrom').value;
    const hasTill = !!this.formGet('priceEstimatedTill').value;

    let restFrom = 0;
    if (this.formGet('priceEstimatedFrom').value) {
      restFrom = this.formGet('priceEstimatedFrom').value - customerPayedValue;
      if (restFrom < 0) {
        restFrom = 0;
      }
      newTitle += separator + DecimalTools.toMoneyString(restFrom, hasTill ? '' : '€', false);
    }

    if (this.formGet('priceEstimatedTill').value) {
      const restTill = this.formGet('priceEstimatedTill').value - customerPayedValue;
      if (restFrom > 0 && restTill > 0) {
        newTitle += '-' + DecimalTools.toMoneyString(restTill, '€', false);
      } else {
        newTitle += ' €';
      }
    }

    if (hasFrom) {
      newTitle += ' offen';
    }

    newTitle = newTitle.replace('| 0 € offen', '| voll bezahlt');

    if (this.formGet('priceEstimatedFrom').value === 0) {
      newTitle += separator + 'kostenlos';
    } else {
      if (this.priceFix) {
        newTitle += separator + 'Fixpreis';
      }
    }

    const discountPromotion = this.formGetValue<any>('discountPromotion');
    if (discountPromotion && discountPromotion.id !== 'none') {
      if (discountPromotion.id === 'custom') {
        newTitle += separator + discountPromotion.discountPercentage + '% Rabatt';
      } else {
        newTitle += separator + discountPromotion.name;
      }
    }


    if (this.formGet('artistFix').value) {
      newTitle += separator + 'fix Artist!';
    }


    if (this.formGet('workType').value === 'tattoo') {
      if (this.getFormControlGroups('bodyPutsTattoo').length === 1) {
        let tattooTextSet = false;
        const motive = this.getFormControlGroups('bodyPutsTattoo')[0].get('motive').value;
        const bodyPut = this.getFormControlGroups('bodyPutsTattoo')[0].get('bodyPut').value;
        if (bodyPut.length > 0) {
          const bodyPutGerman = this.bodyPutService.getGermanPath(bodyPut);
          if (TypeTools.isString(bodyPutGerman) && bodyPutGerman.toLowerCase() !== 'unbekannt') {
            tattooTextSet = true;
            newTitle += separator + motive + ' auf ' + bodyPutGerman;
          }
        }
        if (!tattooTextSet && motive) {
          newTitle += separator + motive;
        }
      } else if (this.getFormControlGroups('bodyPutsTattoo').length > 1) {
        newTitle += separator + 'mehrere Motive';
      }
    }

    if (this.formGet('workType').value === 'piercing') {
      if (this.getFormControlGroups('bodyPutsPiercing').length === 1) {
        const bodyPut = this.getFormControlGroups('bodyPutsPiercing')[0].get('bodyPut').value;
        if (bodyPut.length > 0) {
          const bodyPutPiercingGerman = this.bodyPutService.getGermanPathBodyPutPiercing(bodyPut);
          newTitle += separator + 'Piercing ' + bodyPutPiercingGerman;
        }
      } else if (this.getFormControlGroups('bodyPutsPiercing').length > 1) {
        newTitle += separator + ' ' + this.getFormControlGroups('bodyPutsPiercing').length + ' Piercings';
      }
    }

    if (this.formGet('workType').value === 'tooth-gem') {
      if (this.getFormControlGroups('bodyPutsToothGem').length === 1) {
        const bodyPut = this.getFormControlGroups('bodyPutsToothGem')[0].get('bodyPut').value;
        if (bodyPut.length > 0) {
          const bodyPutPiercingGerman = BodyPutTools.getGermanPathBodyPutToothGem(bodyPut);
          newTitle += separator + ' ' + bodyPutPiercingGerman;
        }
      } else if (this.getFormControlGroups('bodyPutsToothGem').length > 1) {
        newTitle += separator + ' ' + this.getFormControlGroups('bodyPutsToothGem').length + ' Zahnsteine';
      }
    }

    if (this.formGet('importantInfo').value) {
      newTitle += separator + '‼️ ' + this.formGet('importantInfo').value + ' ‼️';
    }

    if (this.formGet('adjustPrice').value) {
      newTitle += separator + '‼️ Preis evtl. anpassen ‼️';
    }

    if (this.formGet('info').value) {
      newTitle += separator + this.formGet('info').value;
    }

    if (newTitle.startsWith(separator)) {
      newTitle = newTitle.substring(3);
    }
    this.formGet('title').setValue(newTitle, {emitEvent: false});
  }

  private async checkForCloseEvent() {
    return true;
  }

  private getEndFromForm(): number {
    const date = this.formGet('date').value;
    const timeTill = this.formGet('timeTill').value;
    if (date) {
      return DateTools.parse(DateTools.format(date, 'yyyy-MM-dd') + ' ' + timeTill, 'yyyy-MM-dd HH:mm');
    }
    return 0;
  }

  private getStartFromForm(): number {
    const date = this.formGet('date').value;
    const timeFrom = this.formGet('timeFrom').value;
    if (date) {
      return DateTools.parse(DateTools.format(date, 'yyyy-MM-dd') + ' ' + timeFrom, 'yyyy-MM-dd HH:mm');
    }
    return 0;
  }

  private calcHasArtistPayment() {
    this.paymentPayoutExist = this.getFormPayments()?.controls.filter(control => control.get('paymentType').value === 'payout').length > 0;
  }

  async showAppointmentConfirmationText() {
    let text = await this.getAppointmentConfirmationText('de', this.isCanceled);
    if (text) {
      const customer = this.formGet('customer').value;
      const mobileFormatted = customer?.mobileFormatted;
      let buttons = [
        {text: 'Deutsch', value: 'german'},
        {text: 'Deutsch senden', value: 'send-german'},
        {text: 'Englisch', value: 'english'},
        {text: 'Englisch senden', value: 'send-english'},
      ];
      if (!mobileFormatted) {
        buttons = buttons.filter(b => !b.value.includes('send'));
      }
      const result = await this.dialogService.showButtons(text, {
        buttons,
      });

      if (result!.value.includes('english')) {
        text = await this.getAppointmentConfirmationText('en', this.isCanceled);
      }

      if (result!.value.includes('send')) {
        try {
          await this.socketService.sendWhatsAppMessage(mobileFormatted, text);
        } catch (err) {
          if (err.errorId) {
            await this.dialogService.showOk(err.message + '\nHandynummer: ' + mobileFormatted);
          } else {
            await this.dialogService.showOk('Fehler beim Senden an Handynummer: ' + mobileFormatted);
          }
          this.showAppointmentConfirmationText();
        }
      } else {
        this.clipboardService.copyToClipboard(text);
      }
    }
  }

  private async getAppointmentConfirmationText(lang: 'de' | 'en', canceled: boolean) {
    const start = DateTools.parse(DateTools.format(this.formGet('date')?.value, 'yyyy-MM-dd') + ' ' + this.formGet('timeFrom')?.value, 'yyyy-MM-dd HH:mm');
    const customer = this.formGet('customer')?.value;
    const depositBack = this.getCurrentPayments().find(p => p.paymentType === 'deposit-back');
    const depositPayed = this.getCurrentPayments().filter(p => p.paymentType === 'deposit' && p.paymentMethod !== 'klarna').reduce((sum, p) => sum + p.paymentValue, 0);
    if (customer?.givenName) {
      return await this.appointmentConfirmationService.getConfirmationText({
        customerName: customer.fullName ?? '',
        start,
        appointmentType: this.formGet('workType')?.value,
        priceEstimatedFrom: this.formGet('priceEstimatedFrom')?.value,
        priceEstimatedTill: this.formGet('priceEstimatedTill')?.value,
        priceFix: this.formGet('priceFix').value,
        lang,
        canceled,
        depositBackValue: depositBack?.paymentValue.toMoneyString(),
        depositBackMethod: PaymentTools.getPaymentMethodText(depositBack?.paymentMethod),
        depositPayed,
        discountPromotion: this.formGet('discountPromotion')!.value,
      });
    }
  }

  typeof(value: any) {
    return typeof value;
  }

  async showHistoryClicked() {
    const dialog = this.dialogService.showComponentFull(CalendarEventHistoryComponent);
    dialog.componentInstance.setData(this.eventId);
  }


  timeFromChanged(value: string) {
    if (this.formGet('workType').value === 'piercing') {
      this.setDurationPiercing();
    } else {
      setTimeout(() => {
        this.nxtFormControlElemTimeTill.inputClick();
      }, 100);
    }
  }


  public hideAllToasts() {
  }

  async addCustomerTagline() {
    const customerId = this.formGet('customer').value.id;
    await this.contactService.showAddTaglineDialog(customerId);
  }

  async removeTagline(tagline: string) {
    const result = await this.dialogService.showYesNo(tagline + ' entfernen?');

    if (result) {
      const customerId = this.formGet('customer').value.id;
      const newContact = await this.socketService.removeTaglineFromNxtContact({contactId: customerId, tagline});
    }
  }

  private async refreshCustomerForm() {
    if (this.form?.get('customer')?.value?.id) {
      const customer = await this.socketService.getContactWithEvents(this.form?.get('customer')?.value?.id); // .contactsWithEvents.getValue().find(c => c.id === this.formGet('customer').value.id);
      if (customer) {
        this.formGet('customer').setValue(customer);
      }
    }
  }

  async customerEmptyEnter(name: any) {
    this.addCustomerClicked();
  }

  async addCustomerClicked() {
    const contact: NxtContact = {taglines: [], id: ''} as NxtContact;
    let customerStrings = [];
    const customerValue = this.formGet('customer').value;
    if (typeof customerValue !== 'string') {
      return;
    }
    if (customerValue) {
      customerStrings = customerValue.trim().split(' ');
    }
    if (customerStrings.length === 1) {
      contact.givenName = StringTools.firstCharUppercase(customerStrings[0]);
    } else if (customerStrings.length === 2) {
      contact.givenName = StringTools.firstCharUppercase(customerStrings[0]);
      contact.familyName = StringTools.firstCharUppercase(customerStrings[1]);
    } else if (customerStrings.length === 3) {
      if (customerStrings[1].toLowerCase().match(/van|von/g)) {
        contact.givenName = StringTools.firstCharUppercase(customerStrings[0]);
        contact.familyName = customerStrings[1] + ' ' + StringTools.firstCharUppercase(customerStrings[2]);
      } else {
        contact.givenName = StringTools.firstCharUppercase(customerStrings[0]) + ' ' + StringTools.firstCharUppercase(customerStrings[1]);
        contact.familyName = StringTools.firstCharUppercase(customerStrings[2]);
      }
    } else if (customerStrings.length === 4) {
      if (customerStrings[1].toLowerCase().match(/van|von/g)) {
        if (customerStrings[2].toLowerCase().match(/der/g)) {
          contact.givenName = StringTools.firstCharUppercase(customerStrings[0]);
          contact.familyName = customerStrings[1] + ' ' + customerStrings[2] + ' ' + StringTools.firstCharUppercase(customerStrings[3]);
        } else {
          contact.givenName = StringTools.firstCharUppercase(customerStrings[0]);
          contact.familyName = customerStrings[1] + ' ' + StringTools.firstCharUppercase(customerStrings[2]);
        }
      } else {
        contact.givenName = StringTools.firstCharUppercase(customerStrings[0]) + ' ' + StringTools.firstCharUppercase(customerStrings[1]);
        contact.familyName = StringTools.firstCharUppercase(customerStrings[2]);
      }
    }

    this.showContactForm(contact);
  }

  customerSearch = async (options: any[], searchText: string): Promise<any[]> => {
    return new Promise(async (resolve) => {
      TimeoutTools.clear(this.searchTimeout);
      this.searchTimeout = setTimeout(async () => {
        if (searchText?.length >= 3) {
          resolve(await this.socketService.findContactsWithEvents(searchText));
        } else {
          resolve(['mind. 3 Zeichen']);
        }
      }, 500);
    });
  };


  private setPossiblePaymentTypesAndMethods() {
    this.allPaymentMethods = PaymentTools.paymentMethods.sort(SortTools.sortString('text'));
    this.possiblePaymentTypes = PaymentTools.paymentTypes.filter(pt => pt.eventBookable).sort(SortTools.sortString('text'));
    if (this.isSafe) {
      this.possiblePaymentMethods = PaymentTools.paymentMethods.filter(pt => !['none', 'paypal', 'klarna', 'bank', 'transfer', 'gift-card'].includes(pt.value)).sort(SortTools.sortString('text'));
      this.possiblePaymentMethodsDepositBack = PaymentTools.paymentMethods.filter(pt => ['none', 'paypal', 'klarna', 'bank', 'transfer', 'gift-card'].indexOf(pt.value) === -1).sort(SortTools.sortString('text'));
    } else {
      this.possiblePaymentMethods = PaymentTools.paymentMethods.filter(pt => ['none', 'transfer'].indexOf(pt.value) === -1).sort(SortTools.sortString('text'));
      this.possiblePaymentMethodsDepositBack = PaymentTools.paymentMethods.filter(pt => !['none', 'transfer', 'gift-card'].includes(pt.value)).sort(SortTools.sortString('text'));
    }
  }

  getCurrentCustomerPaymentSum(): number {
    return PaymentTools.getCustomerPayedSumFromPayments(this.getCurrentPayments());
  }

  getCurrentPayments(): NxtPayment[] {
    return (this.getFormPayments().controls as UntypedFormGroup[]).map(p => p.getRawValue()) as NxtPayment[];
  }

  async saveAndFollowUp() {
    if (!this.formGet('artistFix').value) {
      const doFix = await this.dialogService.showYesNo('Folgetermin ohne Artist-Fix?', {
        noText: 'Ohne Artist-Fix weiter',
        yesText: 'Oh vergessen, ja Artist-Fix',
      });
      if (doFix) {
        this.formGet('artistFix')!.setValue(true);
        if (!this.formGet('artistFixReason')!.value.includes('follow-up')) {
          this.formGet('artistFixReason')!.setValue([...this.formGet('artistFixReason')!.value, 'follow-up']);
        }
      }
    }

    if (!this.followUp?.id) {
      const total = await this.dialogService.showInput(
        'Wie viele Termine sind es insgesamt?\n\nACHTUNG NEU\nDu brauchst keine Infos mehr über Folgetermine in die Info eintragen, das passiert nun automatisch 🎉\nHast du es bereits beim ersten Termin hier gemacht, klick auf Abbrechen und lösche es raus.',
        {isNumber: true});
      if (total) {
        this.followUp = {
          total,
          id: UuidTools.generate(),
          index: 0,
          eventIds: [],
        };
      } else {
        return;
      }
    }
    const savedEvent = await this.saveAndCloseDialog(false, false);
    if (savedEvent) {
      const followUpEvent = EventTools.getEmptyEvent();
      const eventClone = clone(savedEvent);
      followUpEvent.end = eventClone.end;
      followUpEvent.start = eventClone.start;
      followUpEvent.calendarNumber = eventClone.calendarNumber;
      followUpEvent.priceEstimatedFrom = eventClone.priceEstimatedFrom;
      followUpEvent.priceEstimatedTill = eventClone.priceEstimatedTill;
      followUpEvent.customer = eventClone.customer;
      followUpEvent.customerObj = eventClone.customerObj;
      followUpEvent.bodyPuts = eventClone.bodyPuts;
      followUpEvent.importantInfo = eventClone.importantInfo;
      followUpEvent.durationPriceInfo = eventClone.durationPriceInfo;
      followUpEvent.artistPercentage = eventClone.artistPercentage;
      followUpEvent.visibility = eventClone.visibility;
      followUpEvent.workType = eventClone.workType;
      followUpEvent.noAppointmentReminder = eventClone.noAppointmentReminder;
      followUpEvent.skill = eventClone.skill;
      followUpEvent.artistFix = eventClone.artistFix;
      followUpEvent.artistFixReason = eventClone.artistFixReason;
      followUpEvent.depotDueDate = eventClone.depotDueDate;
      followUpEvent.noDepotNecessaryReason = eventClone.noDepotNecessaryReason;
      followUpEvent.noDepotNecessary = eventClone.noDepotNecessary;
      followUpEvent.depotDueDateReason = eventClone.depotDueDateReason;
      followUpEvent.artist = eventClone.artist;
      followUpEvent.priceFix = eventClone.priceFix;
      const newFollowUp = {
        ...this.followUp,
        index: this.followUp.index + 1,
        eventIds: [...this.followUp.eventIds, this.eventId],
      };
      followUpEvent.info = this.prependFollowUpText(followUpEvent.info, newFollowUp);
      followUpEvent.followUp = newFollowUp;
      await this.loadEvent({calendarEvent: followUpEvent});
      const element = await ObjectTools.waitFor(() => {
        const e = (this.formGet('date') as NxtFormControl).element;
        return e;
      }, 'saveAndFollowUp');
      this.formGet('date').setValue(null);
      element.click();
    }
  }

  private calcDurationText() {
    if (this.form) {
      const from = DurationTools.parse(this.formGet('timeFrom')!.value);
      let till = DurationTools.parse(this.formGet('timeTill')!.value);
      if (from > till) {
        till += DurationTools.DURATION_1DAY;
      }
      this.durationText = DurationTools.format(till - from, 'HH:mm');
    }
  }

  private async checkEnterCustomerBirthday(hasNewPayments: boolean) {
    if (this.loginService.getWorkplace() === 'reception') {
      // am Empfang
      const customer: NxtContact = this.formGet('customer')!.value;
      if (customer) {
        const missing: string[] = [];
        // Geburtstag fehlt
        if (hasNewPayments) {
          // es wurde gerade eine Zahlung hinzugefügt

          if (!customer.birthday) {
            missing.push('Geburtstag');
          }
          if (this.configService.config.value.mustContactStreet) {
            if (!customer.streetAddress) {
              missing.push('Straße + Nr');
            }
          }
          if (!customer.mobile) {
            missing.push('Handy');
          }
          let editContact = false;
          if (missing.length > 0) {
            editContact = await this.dialogService.showYesNo('Bitte trage ' + missing.join(' & ') + ' des Kunden ein.', {
              yesText: 'eintragen',
              noText: 'ist gerade nicht möglich',
            });
          }
          if (editContact) {
            this.editContact();
            return true;
          }

          if (missing.length < 0) {
            let result = 'ask-again';
            while (result === 'ask-again') {
              const buttonResult = await this.dialogService.showButtons('Warum ist es nicht möglich?\nWenn der Kunde gerade bezahlt hat, dann frag ihn JETZT', {
                buttons: [
                  {text: 'Ich bin zu faul ihn zu fragen', value: 'ask-again'},
                  {text: 'Kunde ist nicht (mehr) im Haus', value: 'out'},
                  {text: 'Oh ich kann ihn doch jetzt fragen', value: 'do-it'},
                ],
              });
              result = buttonResult!.value;
            }
            if (result === 'do-it') {
              this.editContact();
              return true;
            } else {
              // this.socketService.sendTelegramStudioInfo(this.loginService.getUsername() + ': Geburtstag von ' + customer?.givenName + ' ' + customer.familyName + ' eintragen nicht möglich\nKunde ist nicht (mehr) im Haus');
            }
          }
        }
      }
    }
    return false;
  }


  calcCustomerText() {
    const customer = this.form?.get('customer').value;
    this.customerText = '';
    this.customerClosedEvents = [];
    this.customerCanceledEvents = [];
    this.customerEvents = [];
    this.customerHasMoreEvents = false;
    if (customer) {
      this.customerText = customer.mobileFormatted;
      if (customer.birthday) {
        const birthday = DateTools.parse(customer.birthday);
        const birthdayInfo = BirthdayTools.getBirthdayInfo(birthday);
        this.customerText += '<br/>' + birthdayInfo.age + ' Jahre  ' + DateTools.format(birthday, 'dd.MM.yyyy');
        if (birthdayInfo.daysToBirthday > -3 && birthdayInfo.daysToBirthday <= 3) {
          this.customerText += ' ' + birthdayInfo.text;
        }
      }
      this.customerClosedEvents = customer.closedEvents;
      this.customerCanceledEvents = customer.canceledEvents;
      // this.customerEvents = customer.events ? customer.events.filter(e => e.start > DateTools.clearTime(Date.now())) : [];
      this.customerEvents = customer.events ? customer.events : [];
      this.customerEvents = this.customerEvents.sortNumber('start');
      this.customerHasMoreEvents = (this.customerClosedEvents && this.customerClosedEvents.length > 0)
        || (this.customerCanceledEvents && this.customerCanceledEvents.length > 0)
        || (this.customerEvents && this.customerEvents.length > 0);
    }
  }

  showEvent(eventId: any) {
    if (this.eventId !== eventId) {
      this.loadEvent({eventId});
      if (this.eventIdIsInUrl) {
        this.router.navigateByUrl('/e/' + eventId);
      }
    }
  }

  async addContactFromTextString() {
    const result = await this.dialogService.showInput('Hier Name & Geburtstag aus WhatsApp einfügen');
    if (result) {
      this.addContactFromWhatsApp({mobile: '', clipboard: result});
    }
  }

  async addContactFromWhatsApp(params) {
    if (this.form) {
      if (!this.formGetValue('customer')) {
        const contact: NxtContact = {taglines: [], mobile: params.mobile, id: ''} as NxtContact;

        if (params.clipboard) {
          const c = StringTools.getNameAttributesByText(params.clipboard);
          if (c.birthday) {
            (contact as any).birthday = c.birthday.dateParse().dateFormat('dd.MM.yyyy');
          }
          if (c.postalCode) {
            contact.postalCode = c.postalCode;
          }
          if (c.givenName) {
            contact.givenName = c.givenName;
          }
          if (c.familyName) {
            contact.familyName = c.familyName;
          }
        } else {
          if (this.cacheService.parsedContacts.value.length === 1) {
            const contactToCopy = this.cacheService.parsedContacts.value[0];
            contact.givenName = contactToCopy.parsedContact.givenName;
            contact.familyName = contactToCopy.parsedContact.familyName;
            if (!contact.mobile && contactToCopy.parsedContact.mobile) {
              contact.mobile = contactToCopy.parsedContact.mobile;
            }
            contact.birthday = contactToCopy.parsedContact.birthday;
            contact.postalCode = contactToCopy.parsedContact.postalCode;
          }
        }
        if (contact.mobile) {
          contact.mobile = contact.mobile.replaceAll(' ', '');
        }
        this.showContactForm(contact);
      }
    }
  }

  async showContactFormById(contactId: string) {
    const contact = await this.socketService.getContactById(contactId);
    this.showContactForm(contact);
  }

  async showContactForm(contact: NxtContactPartial) {
    this.isEditContact = true;
    this.editContactDialogRef = this.dialogService.showContactForm(contact);
    const savedContact = (await firstValueFrom(this.editContactDialogRef.afterClosed())) as NxtContact;
    this.isEditContact = false;
    if (savedContact) {
      // this.cacheService.addContactWithEvents(savedContact);
      this.formGet('customer').setValue(savedContact);
      const subscription = this.onNewContacts.subscribe(async () => {
        subscription.unsubscribe();
        const contactToSet = await this.socketService.getContactWithEvents(savedContact.id); // .cacheService.contactsWithEvents.getValue().find(c => c.id === savedContact.id);
        this.form?.get('customer').setValue(contactToSet);
      });
      return savedContact;
    }
  }

  private dateIsChangedToShort() {
    if (this.isNewEvent) {
      return false;
    }
    const date = this.form?.get('date').value;
    const timeFrom = this.form?.get('timeFrom').value;
    const newStart = DateTools.clearTime(DateTools.parse(DateTools.format(date, 'yyyy-MM-dd') + ' ' + timeFrom, 'yyyy-MM-dd HH:mm'));
    const oldStart = DateTools.clearTime(DateTools.parse(this.originalEvent().start));
    if (newStart !== oldStart) {
      let refDate = DateTools.clearTime(Date.now());
      let workingDaysAdd = 0;
      while (workingDaysAdd < 2) {
        refDate = DateTools.addDays(refDate, 1);
        if (WorkingDayTools.isWorkingDay(refDate, this.configService.config.value.invoiceData.fromRegion)) {
          workingDaysAdd++;
        }
      }
      if (oldStart <= refDate) {
        return true;
      }
    }
  }

  private calcCustomerPayedMore() {
    this.customerPayedMoreThanPriceEstimatedTill = 0;
    this.customerPayedMoreThanPriceEstimatedFrom = 0;
    this.customerPayed = 0;
    if (this.form) {
      const priceEstimatedFrom = this.formGet('priceEstimatedFrom').value;
      const priceEstimatedTill = this.formGet('priceEstimatedTill').value;
      this.customerPayed = this.getCurrentCustomerPaymentSum();
      this.priceAutoChangedTo = null;
      if (priceEstimatedTill) {
        if (this.customerPayed > priceEstimatedTill) {
          this.customerPayedMoreThanPriceEstimatedTill = this.customerPayed - priceEstimatedTill;
          this.priceAutoChangedTo = this.customerPayed;
        }
      }
      if (this.customerPayed > priceEstimatedFrom) {
        this.customerPayedMoreThanPriceEstimatedFrom = this.customerPayed - priceEstimatedFrom;
        if (!priceEstimatedTill) {
          this.priceAutoChangedTo = this.customerPayed;
        }
      }
    }
  }

  private dateOrTimeOrPriceOrPaymentsChanged() {
    this.calcCustomerPayedMore();
    if (this.form) {
      const workType = this.formGet('workType').value;
      const priceEstimatedFrom = this.formGet('priceEstimatedFrom').value;
      const priceEstimatedTill = this.formGet('priceEstimatedTill').value;
      let toCalcWith = priceEstimatedFrom + this.customerPayedMoreThanPriceEstimatedFrom;
      if (priceEstimatedTill) {
        toCalcWith = priceEstimatedTill + this.customerPayedMoreThanPriceEstimatedTill;
      }
      let eventDuration = this.getEndFromForm() - this.getStartFromForm();
      if (eventDuration < 0) {
        eventDuration = this.getEndFromForm() + DurationTools.DURATION_1DAY - this.getStartFromForm();
      }
      const timeCheck = EventCalcTools.timeCheck(eventDuration, toCalcWith);
      let hasDiscountPromotion = false;
      if (this.formGet('discountPromotion').value && this.formGet('discountPromotion').value.id !== 'none') {
        hasDiscountPromotion = true;
      }
      let thresholdPrice = hasDiscountPromotion ? 130 : 100;
      if (eventDuration / DurationTools.DURATION_1HOUR >= 3) {
        thresholdPrice = hasDiscountPromotion ? 200 : 150;
      }
      if (Math.abs(timeCheck.calculatedPriceDiff) > thresholdPrice && workType === 'tattoo') {
        this.enableDurationPriceInfo(true);
      } else {
        this.enableDurationPriceInfo(false);
      }
    }
  }

  reload() {
    this.loadEvent({eventId: this.eventId});
  }

  formGet(name: string): UntypedFormControl | FormArray | any {
    return this.form.get(name) as any;
  }

  formGetValue<T>(name: string): T {
    if (!this.form) {
      throw Error('formGetValue zu früh aufgerufen! [' + name + ']');
    }
    return this.formGet(name).value as T;
  }

  toggleForceSaveHack() {
    if (this.permissionService.hasPermission(NxtPermissionId.CalendarEventEdit_SaveWithoutChecks)) {
      if (this.formGet('closed').value && !this.permissionService.hasPermission(NxtPermissionId.Admin)) {
        this.dialogService.showOk('Der Termin ist bereits geschlossen\nSomit würdest du die Buchhaltung verändern, das geht nicht');
      } else {
        if (!this.permissionService.hasPermission(NxtPermissionId.SuperAdmin)) {
          this.socketService.sendTelegramAdmin(this.loginService.getUsername() + ' benutzt den speicher-hack ohne SuperAdmin zu sein\n' + StudioRegionBrowserTools.getLinkForEvent(this.eventId));
        }
        this.forceSaveHack = !this.forceSaveHack;
      }
    }
  }

  private calcDepotDueDate() {
    if (this.form) {
      const dateString = DateTools.format(this.formGet('date').value, 'yyyy-MM-dd');
      this.showDepositDueDate = this.getCurrentPayments().length === 0
        && this.formGet('priceEstimatedFrom').value > 0
        && !this.formGet('noDepotNecessary').value
        && (dateString !== DateTools.todayDateString || !this.createdToday)
        && !this.isCanceled;
      FormTools.setValidators(this.formGet('depotDueDate'), this.showDepositDueDate ? [ValidatorTools.requiredAndNotNaN] : []);
      FormTools.setValidators(this.formGet('depotDueDateReason'), this.showDepositDueDate ? [ValidatorTools.requiredAndNotNaN] : []);
    }
  }

  private priceEstimatedFromChanged(value: number | null) {
    requestAnimationFrame(() => {
      this.calcDepotDueDate();
    });
    if (TypeTools.isNumber(value)) {
      if (value === 0) {
        this.formGet('noDepotNecessary').setValue(true);
        this.formGet('noDepotNecessaryReason').setValue('kostenlos');
      } else {
        if (this.formGet('workType').value === 'tattoo') {
          this.formGet('noDepotNecessary').setValue(false);
        }
        if (this.formGet('noDepotNecessaryReason').value === 'kostenlos') {
          this.formGet('noDepotNecessaryReason').setValue('');
        }
      }
    }
  }

  public async showEventFinder(params: {
    pathName?: string,
    fromDateString?: string,
    fromTime?: number,
    hideIframeOnClose?: boolean,
    selectDurationOnStart?: boolean,
    selectSkillsOnStart?: boolean,
    skills?: { [skill: string]: boolean },
    boolSkills?: { [boolSkill: string]: boolean }
  }) {
    const dialogEventFinder = this.dialogService.showComponentFull(EventFinderComponent);
    if (params.pathName) {
      dialogEventFinder.componentInstance.setWeekFromGoogleCalendar(params.pathName);
    }
    if (params.fromDateString) {
      dialogEventFinder.componentInstance.fromDateString = params.fromDateString;
    }

    if (params.fromTime) {
      dialogEventFinder.componentInstance.filters[0].startTime = params.fromTime;
    }
    if (params.skills) {
      dialogEventFinder.componentInstance.currentSkillFilterObj = params.skills;
    }
    if (params.boolSkills) {
      dialogEventFinder.componentInstance.currentBoolSkillFilterObj = params.boolSkills;
    }
    await dialogEventFinder.componentInstance.loadData();
    if (params.selectDurationOnStart) {
      const duration = await dialogEventFinder.componentInstance.selectDuration();
      if (!duration) {
        // abbruch, alles schließen, auch den Termin
        dialogEventFinder.close();
        this.close(true);
        return;
      }
    }

    const eventFinderDialogResult: EventFinderDialogResult = await firstValueFrom(dialogEventFinder.afterClosed());
    if (eventFinderDialogResult) {
      this.formGet('date').setValue(eventFinderDialogResult.event.date);
      this.formGet('timeFrom').setValue(eventFinderDialogResult.event.timeFrom);
      this.formGet('timeTill').setValue(eventFinderDialogResult.event.timeTill);
      if (eventFinderDialogResult.skill) {
        this.skill = eventFinderDialogResult.skill;
      }
      // artist finden
      if (eventFinderDialogResult.artist?.name) {
        const artist = this.artists.find(a => a.name === eventFinderDialogResult.artist.name);
        if (artist) {
          this.formGet('artist').setValue(artist);
        }
      }
      requestAnimationFrame(() => {
        this.calcArtistSkill();
      });
    } else {
      this.close(true);
    }
  }

  private setDurationPiercing() {
    const from = this.formGet('timeFrom').value;
    const till = DurationTools.format(DurationTools.parse(from) + (DurationTools.DURATION_1MINUTE * 15), 'HH:mm');
    // const till = DateTools.format(DateTools.parse(from, 'HH:mm') + (DurationTools.DURATION_1MINUTE * 15), 'HH:mm');
    this.formGet('timeTill').setValue(till);

  }

  public beforeAddPayment = () => {
    if (this.loginService.isReception()) {
      const isEventToday = DateTools.isToday(this.formGet('date').value);
      if (isEventToday) {
        let hastUnknownBodyPutTattoo = false;
        let hastUnknownMotive = false;
        (this.formGet('bodyPutsTattoo') as FormArray).controls.forEach(fg => {
          if (Array.isArray(fg.get('bodyPut').value)) {
            if (fg.get('bodyPut').value.includes('unknown')) {
              hastUnknownBodyPutTattoo = true;
            }
            if (EventTools.motiveOrBodyPutBlackList.some((motiveOrBodyPutBlackList) => fg.get('motive').value.toString().toLowerCase().includes(motiveOrBodyPutBlackList.toLowerCase()))) {
              hastUnknownMotive = true;
            }
          }
        });
        if (hastUnknownBodyPutTattoo) {
          this.dialogService.showOk('Trage erst die Körperstelle ein!');
          return false;
        }
        if (hastUnknownMotive) {
          this.dialogService.showOk('Trage erst das Motiv ein!');
          return false;
        }
      }
    }
    return true;
  };

  private async checkArtistAvailable() {
    if (!this.formGet('date').value) {
      return;
    }
    this.artistNotAvailableText = '';
    const artist: NxtArtist = this.formGet('artist').value;
    if (artist?.name) {
      if (parseInt(artist.calendarNumber, 10) < 990) {
        const date = this.formGet('date').value;
        const artistAvailable = await this.socketService.isArtistAvailable(artist.name, DateTools.parseFormat(date, 'yyyy-MM-dd'));
        if (artist.name.toLowerCase().includes('piercing')) {

        } else if (artistAvailable === 'NOT_AVAILABLE') {
          if (!this.isCanceled) {
            this.artistNotAvailableText = ' (NICHT VERFÜGBAR)';
          }
        } else if (artistAvailable === 'WALK_IN') {
          this.artistNotAvailableText = ' (WALK IN)';
        }
      }
    }
  }

  async showCustomerRefWhatsAppChatClicked() {
    const customerRefId = this.form.get('customerRefId').value.toString();
    if (customerRefId) {
      const bodyPutsTattoo = this.formGet('bodyPutsTattoo').getRawValue();
      const skills = [...keys(this.skill.skills).filter(s => this.skill.skills[s]), ...keys(this.skill.boolSkills).filter(s => this.skill.boolSkills[s])];
      this.contactService.showWhatsAppChat(this.form.get('customerRefId').value, this.formGet('artist').value, {
        eventId: this.originalEvent()?.id || 'new',
        bodyPutsTattoo,
        skills,
      });
    }
  }

  async showWhatsAppChat() {
    const customer: NxtContact = this.formGet('customer').value;
    if (!customer) {
      await this.dialogService.showOk('Du hast noch keinen Kunden ausgewählt');
    } else {
      const bodyPutsTattoo = this.formGet('bodyPutsTattoo').getRawValue();
      const skills = [...keys(this.skill.skills).filter(s => this.skill.skills[s]), ...keys(this.skill.boolSkills).filter(s => this.skill.boolSkills[s])];
      this.contactService.showWhatsAppChat(customer, this.formGet('artist').value, {eventId: this.originalEvent()?.id || 'new', bodyPutsTattoo, skills});
    }
  }


  private setBodyPutRequired(name: 'bodyPutsTattoo' | 'bodyPutsPiercing' | 'bodyPutsBeauty' | 'bodyPutsToothGem', required: boolean) {
    if (required && this.getFormControlGroups(name).length === 0) {
      this.addBodyPut(name);
    }

    this.getFormControlGroups(name).forEach(g => {
      requestAnimationFrame(() => {
        g.get('bodyPut').setValidators(required ? [ValidatorTools.requiredAndNotNaN] : []);
        g.get('bodyPut').updateValueAndValidity({emitEvent: false});
        g.get('motive').setValidators(required && name === 'bodyPutsTattoo' ? [ValidatorTools.requiredAndNotNaN] : []);
        g.get('motive').updateValueAndValidity({emitEvent: false});
        if (name === 'bodyPutsTattoo') {
          g.get('size').setValidators((required && name === 'bodyPutsTattoo' && this.isNewEvent) ? [ValidatorTools.requiredAndNotNaN, ValidatorTools.custom(this.checkBodyPutSize.bind(this))] : []);
          g.get('size').updateValueAndValidity({emitEvent: false});
        }
      });
    });
  }

  private checkBodyPutSize(value: string) {
    if (value.toLowerCase() === 'x') {
      return 'X ist keine gültige Größe';
    }
    return '';
  }

  private clearBodyPuts(name: 'bodyPutsTattoo' | 'bodyPutsPiercing' | 'bodyPutsBeauty' | 'bodyPutsToothGem') {
    (this.formGet(name) as UntypedFormArray).controls = [];
    // this.getFormControlGroups(name).length = 0;
  }

  getFormControlGroups(name: 'bodyPutsTattoo' | 'bodyPutsPiercing' | 'bodyPutsBeauty' | 'bodyPutsToothGem') {
    return (this.formGet(name) as UntypedFormArray).controls as UntypedFormGroup[];
  }

  addBodyPut(name: 'bodyPutsTattoo' | 'bodyPutsPiercing' | 'bodyPutsBeauty' | 'bodyPutsToothGem', data: {
    bodyPut?: string[],
    motive?: string,
    round?: boolean,
    thinLines?: boolean,
    size?: string,
    goldBall?: boolean
  } = {}) {
    const formGroup = this.fb.group({
      bodyPut: new NxtFormControl(data.bodyPut ?? [], [ValidatorTools.requiredAndNotNaN]),
      motive: new NxtFormControl(data.motive ?? '', name === 'bodyPutsTattoo' ? [ValidatorTools.requiredAndNotNaN] : []),
      round: new NxtFormControl(!!data.round),
      thinLines: new NxtFormControl(!!data.thinLines),
      size: new NxtFormControl(data.size ?? '', name === 'bodyPutsTattoo' ? [ValidatorTools.requiredAndNotNaN] : []),
      goldBall: new NxtFormControl(!!data.goldBall),
    });
    formGroup.valueChanges.subscribe(() => this.setChanged('Motive oder Körperstelle geändert'));
    formGroup.valueChanges.subscribe(() => this.bodyPutChanged());
    this.getFormControlGroups(name).push(formGroup);
    this.registerFormValueChanged();
    this.setChanged('neue Körperstelle');
  }

  removeBodyPut(name: 'bodyPutsTattoo' | 'bodyPutsPiercing' | 'bodyPutsBeauty' | 'bodyPutsToothGem', index = -1) {
    if (index === -1) {
      this.getFormControlGroups(name).pop();
    } else {
      this.getFormControlGroups(name).splice(index, 1);
    }
    this.setChanged('Körperstelle entfernt');
    this.bodyPutChanged();
  }

  private registerFormValueChanged() {
    requestAnimationFrame(() => {
      this.formValueChangeSubscription?.unsubscribe();
      this.formValueChangeSubscription = this.form.valueChanges.subscribe((preData) => {
        const data = this.form.getRawValue();
        for (const key in data) {
          if (['payments', 'title'].includes(key)) {
            continue;
          }
          if (!ObjectTools.compare(data[key], this.originalFormRawValue[key])) {
            this.setChanged(key);
          }
        }
      });
    });
  }

  /*changeWorkType() {
    if (!this.formGet('workType').value) {
      this.formGet('workType').setValue('tattoo');
    }
    this.workTypeChanged();
  }*/

  private workTypeChanged() {
    if (!this.formGet('workType').value) {
      this.formGet('workType').setValue('tattoo');
    }
    if (this.formGet('workType').value === 'piercing' || this.formGet('workType').value === 'tooth-gem') {
      this.skill = {skills: {}, boolSkills: {}};
    }
    this.setBodyPutRequired('bodyPutsTattoo', false);
    this.setBodyPutRequired('bodyPutsPiercing', false);
    if (this.formGet('workType').value === 'tattoo') {
      this.setBodyPutRequired('bodyPutsTattoo', true);
      this.clearBodyPuts('bodyPutsPiercing');
      this.clearBodyPuts('bodyPutsBeauty');
      this.clearBodyPuts('bodyPutsToothGem');
      if (this.formGet('noDepotNecessaryReason').value === 'Piercing') {
        this.formGet('noDepotNecessary').setValue(false);
        this.formGet('noDepotNecessaryReason').setValue('');
      }
    } else if (this.formGet('workType').value === 'piercing') {
      this.setBodyPutRequired('bodyPutsPiercing', true);
      this.clearBodyPuts('bodyPutsTattoo');
      this.clearBodyPuts('bodyPutsToothGem');
      this.clearBodyPuts('bodyPutsBeauty');
      this.formGet('noDepotNecessary').setValue(true);
      this.formGet('noDepotNecessaryReason').setValue('Piercing');
      this.setDurationPiercing();
    } else if (this.formGet('workType').value === 'beauty') {
      this.setBodyPutRequired('bodyPutsBeauty', true);
      this.clearBodyPuts('bodyPutsTattoo');
      this.clearBodyPuts('bodyPutsToothGem');
      this.clearBodyPuts('bodyPutsPiercing');
      this.formGet('noDepotNecessary').setValue(true);
      this.formGet('noDepotNecessaryReason').setValue('Beauty');
    }
    if (this.formGet('workType').value === 'tooth-gem') {
      this.setBodyPutRequired('bodyPutsToothGem', true);
      this.clearBodyPuts('bodyPutsTattoo');
      this.clearBodyPuts('bodyPutsPiercing');
      this.clearBodyPuts('bodyPutsBeauty');
      this.formGet('noDepotNecessary').setValue(true);
      this.formGet('noDepotNecessaryReason').setValue('Zahnschmuck');
    }
  }

  async showPriceModal() {
    if (this.form.get('priceAuto').value) {
      return;
    }

    if (this.formGet('workType').value === 'beauty') {
      const auto = await this.dialogService.showYesNo('Preis festlegen', {yesText: 'Automatisch', noText: 'Manuell'});
      if (auto) {
        this.autoPriceByBeauty();
        return;
      }
    }
    if (this.getCurrentPayments().some(p => p.paymentType === 'payout') && !this.forceSaveHack) {
      await this.dialogService.showOk('Es gibt bereits eine Artist-Auszahlung!');
      return;
    }
    const dialog = this.dialogService.showComponentDialog(PriceInputComponent, {}, {minWidth: '400px'});
    dialog.componentInstance.discountPromotion = this.formGetValue('discountPromotion');
    dialog.componentInstance.price.set(this.formGetValue('priceEstimatedFrom') || null);
    dialog.componentInstance.artistPercentage = this.formGetValue('artistPercentage');
    dialog.componentInstance.priceTill = this.formGetValue('priceEstimatedTill');
    dialog.componentInstance.priceFixWithoutDiscountPromotion = this.formGetValue('priceFix');
    dialog.componentInstance.currentPriceEstimatedFrom = this.formGetValue('priceEstimatedFrom');
    dialog.componentInstance.isNewEvent = this.isNewEvent;
    dialog.componentInstance.eventCreatedAt = this.originalEvent().createdAt;
    dialog.componentInstance.eventId = this.eventId;
    dialog.componentInstance.artist = this.getArtistFromForm();
    dialog.componentInstance.promoOfferId = this.formGetValue('promoOfferId');
    dialog.componentInstance.promoOfferCreatedAt = this.formGetValue('promoOfferCreatedAt');
    dialog.componentInstance.promoOfferPromoterName = this.formGetValue('promoOfferPromoterName');
    dialog.componentInstance.discountPromotions = ObjectTools.clone(this.discountPromotions);
    const result = await firstValueFrom(dialog.afterClosed());
    if (result) {
      if (!result.discountPromotion.priceLike) {
        this.formGet('priceFix').setValue(result.discountPromotion.priceFix);
      }

      this.formGet('priceEstimatedTill').setValue(result.priceTill);
      if (result.discountPromotion.priceFix) {
        this.formGet('priceEstimatedTill').setValue(null);
      }
      this.formGet('discountPromotion').setValue(result.discountPromotion);
      this.formGet('priceEstimatedFrom').setValue(result.price);

      if (result.artistPercentage > -1) {
        if (this.loginService.isBackoffice()) {
          if (this.originalEvent().closed && this.formGetValue('artistPercentage') !== result.artistPercentage) {
            this.formGet('artistPercentageConfirmed').setValue(true);
          }
        }
        this.formGet('artistPercentage').setValue(result.artistPercentage);
      }


      const additionalPayAfterEndText = 'Termin bis max. 18 Uhr, danach neuer Termin!';
      if (this.formGet('importantInfo').value && this.formGet('importantInfo').value.includes(additionalPayAfterEndText)) {
        this.formGet('importantInfo').setValue(this.formGet('importantInfo').value.replaceAll(additionalPayAfterEndText, ''));
      }
      if (result?.discountPromotion?.additionalPayAfterEnd) {
        this.formGet('importantInfo').setValue(this.formGet('importantInfo').value + ' ' + additionalPayAfterEndText);
      }
      this.formGet('promoOfferId').setValue(result.promoOfferId);
      this.formGet('promoOfferCreatedAt').setValue(result.promoOfferCreatedAt);
      this.formGet('promoOfferPromoterName').setValue(result.promoOfferPromoterName);


      /*requestAnimationFrame(() => {
        if (this.showDurationPriceInfo && result?.discountPromotion?.id !== 'none') {
          this.formGet('durationPriceInfo').setValue(result.discountPromotion.name);
        }
      });*/
    }
  }


  private async checkOverlap(params: {
    eventId: string;
    artist: string;
    start: number;
    end: number,
    customerName: string
  }) {
    if (this.formGet('workType').value === 'piercing') {
      return true;
    }
    const showLoadingTimeout = setTimeout(() => {
      this.dialogService.showLoading(LoadingId.CalendarEventEditCheckOverlap, 'Es wird geprüft, ob der Artist verfügbar ist...');
    }, 500);
    const overlappedEvents = await this.socketService.getOverlappedEvents(params);
    const thisStart = DateTools.format(params.start, 'dd.MM.yyyy HH:mm');
    const thisEnd = DateTools.format(params.end, 'HH:mm');
    const lines = [];
    for (const overlappedEvent of overlappedEvents) {
      const start = DateTools.format(overlappedEvent.start, 'HH:mm');
      const end = DateTools.format(overlappedEvent.end, 'HH:mm');
      const overlapH = MathTools.round((overlappedEvent as any).overlapDuration / DurationTools.DURATION_1HOUR, 1);
      let color = '';
      if (overlapH <= 0.5) {
        color = ColorTools.Orange;
      } else {
        color = ColorTools.Red;
      }
      const showLink = '<a href="/#/e/' + overlappedEvent.id + '" target="_blank">anzeigen</a>';
      const overlapText = '<div style="display: inline-block; color:' + color + '">Fehler:&nbsp;' + overlapH + '&nbsp;Std.</div>';
      lines.push(overlapText + ' | ' + start + ' - ' + end + ' | ' + overlappedEvent?.customerObj?.givenName + ' ' + overlappedEvent?.customerObj?.familyName + ' ' + showLink);
    }
    if (lines.length > 0) {
      const thisEventText = 'Du willst anlegen:\n' + thisStart + ' - ' + thisEnd + ' | ' + params.customerName;
      const titleText = params.artist + ' ist bereits belegt!';
      const text = thisEventText + '\n\nBereits angelegt:\n' + lines.join('\n');
      this.dialogService.hideLoading(LoadingId.CalendarEventEditCheckOverlap);
      TimeoutTools.clear(showLoadingTimeout);
      const result = await this.dialogService.showYesNo(text, {
        yesText: 'Trotzdem speichern',
        noText: 'Zurück',
        title: titleText,
      });
      return result;
    }
    this.dialogService.hideLoading(LoadingId.CalendarEventEditCheckOverlap);
    TimeoutTools.clear(showLoadingTimeout);
    return true;
  }

  private async checkNotAvailable(params: {
    eventId: string;
    artist: string;
    start: number;
    end: number,
    customerName: string
  }) {
    if (params.start.clearDate() > params.end.clearDate()) {
      // Tagesübergreifend, nicht checken
    }
    const dateString = params.start.dateFormatDate();
    const artistSpot = await this.socketService.getArtistSpotByArtistAndDate(params.artist, dateString, false);
    if (!artistSpot) {
      // this.telegramService.sendAdmin('checkNotAvailable - ArtistSpot nicht gefunden: ' + params.artist + ' ' + dateString);
      return true;
    }
    const lines: string[] = [];
    if (artistSpot.workTimeWindows) {
      const workTimeWindow = artistSpot.workTimeWindows.find(w => w.dateString === dateString);
      if (workTimeWindow.start > params.start.clearDate()) {
        lines.push(params.artist + ' ist erst ab ' + DurationTools.format(workTimeWindow.start, 'HH:mm') + ' verfügbar!');
      }
      if (workTimeWindow.end > 0 && workTimeWindow.end < params.end.clearDate()) {
        lines.push(params.artist + ' ist nur bis ' + DurationTools.format(workTimeWindow.end, 'HH:mm') + ' verfügbar!');
      }
    }
    if (lines.length === 0) {
      return true;
    }
    return this.dialogService.showYesNo(lines.join('\n'), {
      yesText: 'Trotzdem speichern',
      noText: 'Zurück',
      title: '❗Artist nicht verfügbar❗',
    });
  }

  private checkCancelReasonVisible() {
    if (this.form) {
      const isOk = WorkingDayTools.addWorkingDays(DateTools.clearTime(Date.now()), 3, this.configService.config.value.invoiceData.fromRegion);
      const startDateWithoutTime = DateTools.clearTime(this.getStartFromForm());
      this.formGet('canceledReason').setValidators(this.isCanceled ? [ValidatorTools.requiredAndNotNaN] : []);
      this.formGet('canceledReason').updateValueAndValidity({emitEvent: false});
    }
  }

  private setIsCanceled(value: boolean) {
    if (!value) {
      const fastWalkInAndNewEvent = this.form?.get('fastWalkIn')?.value && this.isNewEvent;
      this.form?.get('customer').setValidators(fastWalkInAndNewEvent ? [] : [ValidatorTools.validCustomer, ValidatorTools.requiredAndNotNaN]);
      this.form?.get('customer').updateValueAndValidity({emitEvent: false});
    } else {
      this.form?.get('customer').setValidators([]);
      this.form?.get('customer').updateValueAndValidity({emitEvent: false});

      if (this.form?.get('fastWalkIn')?.value) {
        this.formGet('priceEstimatedFrom').setValidators([]);
        this.formGet('priceEstimatedFrom').updateValueAndValidity({emitEvent: false});

        (this.formGet('bodyPutsTattoo') as FormArray).controls.forEach(fg => {
          FormTools.setValidators(fg.get('bodyPut'), []);
          FormTools.setValidators(fg.get('motive'), []);
          FormTools.setValidators(fg.get('size'), []);
        });

        (this.formGet('bodyPutsPiercing') as FormArray).controls.forEach(fg => {
          fg.get('bodyPut').setValidators([]);
          fg.get('bodyPut').updateValueAndValidity({emitEvent: false});
        });
      }


    }
    if (value && this.originalEvent().status !== 'canceled') {
      this.form?.get('canceledAt').setValue(Date.now());
      const isOkDate = WorkingDayTools.addWorkingDays(DateTools.clearTime(Date.now()), 3, this.configService.config.value.invoiceData.fromRegion);
      const startDateWithoutTime = DateTools.clearTime(this.getStartFromForm());
      this.form?.get('canceledInTime').setValue(startDateWithoutTime >= isOkDate);
    }


    this.isCanceled = value;
    this.dateOrTimeOrPriceOrPaymentsChanged();
    this.calcDepotDueDate();
    this.checkCancelReasonVisible();
  }


  private checkNoDepotNecessaryReason() {
    if (this.form) {
      const noDepotNecessary: boolean = this.formGet('noDepotNecessary').value;
      this.calcDepotDueDate();
      if (!noDepotNecessary) {
        this.formGet('noDepotNecessaryReason').setValue('');
      }
      this.formGet('noDepotNecessaryReason').setValidators(noDepotNecessary ? [ValidatorTools.requiredAndNotNaN] : []);
      this.formGet('noDepotNecessaryReason').updateValueAndValidity({emitEvent: false});
    }
  }

  public async printQrEvent(onlyShow = false) {
    const eventDateTime = DateTools.parse(this.formGet('date').value + ' ' + this.formGet('timeFrom').value + ':00');
    if (DateTools.formatNowDate() !== eventDateTime.dateFormatDate()) {
      if (!this.forceSaveHack && eventDateTime > Date.now() && !this.loginService.isJulian()) {
        this.dialogService.showOk('Der Termin ist nicht von heute, drucken nicht möglich!');
        return;
      }
      if (!await this.dialogService.showYesNo('Der Termin ist nicht von heute!\nQR-Code trotzdem drucken?', {yesText: 'Ja drucken', noText: 'Ups, falscher Termin 🙈'})) {
        return;
      }
    }

    const code = window.location.origin + '/event-qr-2/' + this.eventId;
    if (onlyShow) {
      const src = await QRCode.toDataURL(code, {width: 500, color: {dark: '#ffffff', light: '#2A2A2A'}});
      this.dialogService.showOk('<div style="width: 100%; text-align: center;"><br/><img src="' + src + '" /></div>', {title: 'QR-Code den Artist scannen lassen!'});
      return;
    }
    this.dialogService.showLoading(LoadingId.PrintWalkInNo, 'QR-Code wird gedruckt...');
    const date = DateTools.parseFormat(this.formGet('date').value, 'dd.MM.yyyy');
    const time = this.formGet('timeFrom').value;
    const dateTime = date + ' ' + time;
    const customerName = (this.formGet('customer').value)?.fullName;
    const longName = customerName.length > 25;
    let text = dateTime + '\n' + customerName;
    if (this.formGet('artist')?.value?.name) {
      text += '\nbei ' + this.formGet('artist')?.value?.name;
    }
    if (this.formGet('fastWalkIn').value) {
      text += '\nWalkIn-Nr:  ' + this.formGet('fastWalkInNo').value;
      if (!this.formGet('fastWalkInNo').value) {
        this.socketService.sendTelegramAdmin('WalkIn-Nr:  ' + this.formGet('fastWalkInNo').value + ' hat keine Nummer!\n' + StudioRegionBrowserTools.getLinkForEvent(this.eventId));
      }
    }
    let result;
    if (this.configService.config.value.studioRegion === 'STAGING') {
      await this.dialogService.showOk('Bei Staging wird der QR-Code nur angezeigt\nQR-Code:\n\n' + text);
      result = '';
    } else {
      if (this.configService.config.value.studioRegion === 'AC' && this.loginService.isBackoffice()) {
        result = await this.socketService.printQrCode('Backoffice', code, text, longName);
      } else {
        result = await this.socketService.printQrCode(this.loginService.getStudio(), code, text, longName);
      }
    }


    if (result === '') {
      this.dialogService.hideLoading(LoadingId.PrintWalkInNo);
    } else {
      if (await this.dialogService.showYesNo('Fehler beim drucken\n' + result, {
        yesText: 'Nochmal drucken',
        noText: 'Abbrechen',
      })) {
        await this.printQrEvent();
      }
    }

    this.dialogService.hideLoading(LoadingId.PrintWalkInNo);

    await this.printArtist();
  }

  public async printFastWalkInNo(fastWalkInNo: number, workType: string, showLoading = false) {
    if (showLoading) {
      this.dialogService.showLoading(LoadingId.PrintWalkInNo, 'Walk-In-Nummer wird gedruckt...');
    }
    const NoToPrint = (workType === 'piercing' ? 'P' : '') + fastWalkInNo;
    const result = await this.socketService.printFastWalkInNo(NoToPrint, this.loginService.getStudio(), workType);
    if (result === '') {
      this.dialogService.hideLoading(LoadingId.PrintWalkInNo);
      /*const resultMessagebox = await this.dialogService.showYesNo('Walk-In: ' + NoToPrint, {yesText: 'OK', noText: 'Nochmal drucken', title: 'Erfolgreich gedruckt'});
      if (!resultMessagebox) {
        await this.printFastWalkInNo(fastWalkInNo, workType);
      }*/
    } else {
      const result2 = await this.dialogService.showYesNo('Fehler beim drucken\n' + result, {
        yesText: 'Nochmal',
        noText: 'Abbrechen',
      });
      if (result2) {
        await this.printFastWalkInNo(fastWalkInNo, workType, showLoading);
      }
    }
    if (showLoading) {
      this.dialogService.hideLoading(LoadingId.PrintWalkInNo);
    }
  }

  async printFastWalkInNoFromEvent() {
    const fastWalkInNo = this.formGet('fastWalkInNo').value;
    await this.printFastWalkInNo(fastWalkInNo, this.formGet('workType').value);
  }


  async checkOtherAvailableArtist() {
    const dialog = await this.dialogService.showComponentDialog(OtherAvailableArtistComponent, {}, {});
    dialog.componentInstance.load({
      dateString: DateTools.parse(this.formGet('date').value).dateFormat('yyyy-MM-dd'),
      from: this.formGet('timeFrom').value,
      till: this.formGet('timeTill').value,
      eventId: this.eventId,
      skillFilter: Object.keys(this.skill.skills),
      boolSkillFilter: Object.keys(this.skill.boolSkills),
    });
    const newArtist = await firstValueFrom(dialog.afterClosed());
    if (newArtist) {
      const artist = this.artists.find(a => a.name === newArtist);
      if (artist) {
        this.formGet('artist').setValue(artist);
      }
    }
  }

  private checkArtistBodyPutBlackList() {
    const rawValues = this.form.getRawValue();
    if (rawValues.bodyPutsTattoo && rawValues?.artist?.bodyPutBlacklist) {
      const artistBodyPutTattooBlacklist = rawValues?.artist?.bodyPutTattooBlacklist;
      const artistName = rawValues?.artist?.name;
      for (const bodyPutTattoo of rawValues.bodyPutsTattoo) {
        if (artistBodyPutTattooBlacklist.includes(bodyPutTattoo.bodyPut.join(','))) {
          const bodyPutText = this.bodyPutService.getGermanPath(bodyPutTattoo.bodyPut);
          this.dialogService.showOk(artistName + ' tätowiert nicht auf ' + bodyPutText);
          return false;
        }
      }
    }

    if (rawValues.bodyPutsTattoo && rawValues?.artist?.motiveTattooBlacklist) {
      const artistMotiveBlackList = rawValues?.artist?.motiveTattooBlacklist;
      const artistName = rawValues?.artist?.name;
      for (const bodyPutTattoo of rawValues.bodyPutsTattoo) {
        if (bodyPutTattoo.round && artistMotiveBlackList.includes('round')) {
          this.dialogService.showOk(artistName + ' kann keine runden Kreise');
          return false;
        }
        if (bodyPutTattoo.thinLines && artistMotiveBlackList.includes('thinLines')) {
          this.dialogService.showOk(artistName + ' kann keine feine geraden Linien');
          return false;
        }
      }
    }
    return true;
  }

  private async waitForForm() {
    return new Promise<void>((resolve, reject) => {
      if (this.form) {
        resolve();
      } else {
        setTimeout(() => resolve(this.waitForForm()), 50);
      }
    });
  }

  private async checkShouldDeposit() {
    const shouldDepositBack = this.formGet('shouldDepositBack').value;
    if (shouldDepositBack) {
      const shouldDepositBackValue = this.formGet('shouldDepositBackValue').value;
      const shouldDepositBackCreatedAt = this.formGet('shouldDepositBackCreatedAt').value;
      const newDepositBackPayments = this.getCurrentPayments().filter(p => p.paymentType === 'deposit-back' && p.createdAt > shouldDepositBackCreatedAt);
      const newDepositBackValue = newDepositBackPayments.reduce((sum, p) => sum + (p?.paymentValue ?? 0), 0);

      if (newDepositBackValue > 0) {
        if (newDepositBackValue < shouldDepositBackValue) {
          const newShouldDepositBackValue = shouldDepositBackValue - newDepositBackValue;
          await this.dialogService.showOk('Es wurde nicht der komplett festgelegte Betrag zurück gezahlt\nOffene Rückzahlung wird auf ' + DecimalTools.toMoneyString(newShouldDepositBackValue) + ' gesetzt');
          this.formGet('shouldDepositBackValue')?.setValue(newShouldDepositBackValue);
          this.formGet('shouldDepositBackCreatedAt')?.setValue(Date.now());
        } else {
          await this.dialogService.showOk('Offene Rückzahlung erledigt');
          this.formGet('shouldDepositBackValue')?.setValue(null);
          this.formGet('shouldDepositBack')?.setValue(false);
        }
      }
    }
  }

  private registerCalendarViewer() {
    this.isFromCalendarView = true;
    this.socketService.registerCalendarViewer();
  }

  private async loadPhotos(reason: string) {
    Log.debug('loadPhotos -' + reason);
    this.photosAndVideos = await this.socketService.getEventPhotos(this.eventId);
    this.videos = this.photosAndVideos.filter(p => p.mimeType.startsWith('video'));
    this.images = this.photosAndVideos.filter(p => p.mimeType.startsWith('image'));

    for (const [index, photo] of this.images.entries()) {
      if (photo.type === 'template') {
        photo.sortValue = 1 + photo.filename;
      }
      if (photo.type === 'preTattoo') {
        photo.sortValue = 2 + photo.filename;
      }
      if (photo.type === 'stencil') {
        photo.sortValue = 3 + photo.filename;
      }
      if (photo.type === 'tattoo') {
        photo.sortValue = 4 + photo.filename;
      }
    }
    this.images = this.images.sortString('sortValue');

    for (const [index, photo] of this.images.entries()) {
      photo.title = (index + 1) + ' / ' + this.images.length + '&nbsp;&nbsp;&nbsp;' + this.formGet('artist').value?.name;
    }

    this.pdfs = this.photosAndVideos.filter(p => p.filename.endsWith('.pdf'));
    this.pdfs.forEach(p => {
      p.complete = !p.filename.includes('unvollständig');
    });
    if (this.pdfs.some(p => p.complete)) {
      this.pdfs = this.pdfs.filter(p => p.complete);
    }
    this.photosAndVideos = this.photosAndVideos.filter(p => !p.filename.endsWith('.pdf'));
    this.tattooPhotoCount = this.photosAndVideos.filter(p => p.filename.startsWith('tattoo') && p.mimeType.startsWith('image')).length;
    this.videoCount = this.photosAndVideos.filter(p => p.mimeType.startsWith('video')).length;
    this.autoSelectMedia();
  }

  autoSelectMedia() {
    this.selectedMedia.set({});
    const photosSelected = {};
    for (const photo of this.images) {
      if (photo.filename.startsWith('tattoo')) {
        photosSelected[photo.id] = photo;
      }
    }
    this.selectedMedia.set(photosSelected);
  }

  openGoogleDriveFolder() {
    window.open('https://drive.google.com/drive/folders/' + this.photoFolderId, '_blank');
  }

  private hasNewPayments(paymentMethod?: PaymentMethod, ignoreDepositBack = false) {
    return !!this.getCurrentPayments().find(p => {
      const correctPaymentType = ignoreDepositBack ? p.paymentType !== 'deposit-back' : true;
      const correctPaymentMethod = !paymentMethod || p.paymentMethod === paymentMethod;
      return p.isNewPayment && correctPaymentType && correctPaymentMethod;
    });
  }


  async setTestData() {
    if (['AC', 'STAGING'].includes(this.configService.config.value.studioRegion)) {
      this.formGet('info').setValue((this.formGet('info').value ?? '') + ' TEST');
      const customers = await this.socketService.findContactsWithEvents('Test Kunde');
      this.formGet('priceEstimatedFrom').setValue(10);
      this.formGet('customer').setValue(MathTools.getRandomFromArray(customers));
      this.formGet('noDepotNecessary').setValue(true);
      this.formGet('timeFrom').setValue('22:00');
      this.formGet('timeTill').setValue('23:00');
      this.formGet('importantInfo').setValue('Wichtig bla bla');
      // this.formGet('improveArtistShouldGetMoney').setValue(true);
      this.formGet('priceFix').setValue(true);
      this.formGet('artistFix').setValue(true);
      // this.formGet('adjustPrice').setValue(true);
      this.formGet('artistFixReason').setValue(['design']);

      this.skill = {
        boolSkills: {},
        skills: {Coverup: true},
      };
      this.formGet('noDepotNecessaryReason').setValue('test');
      const artist = this.artists.find(a => a.name === 'Test-Artist 2');
      this.formGet('artist').setValue(artist);
      (this.formGet('bodyPutsTattoo') as FormArray).controls[0].setValue(
        {
          bodyPut: ['unknown'],
          motive: 'test',
          round: true,
          thinLines: true,
          size: '10cm',
          goldBall: false,
        },
      );
    } else if (this.configService.config.value.studioRegion === 'MA') {
      this.formGet('info').setValue((this.formGet('info').value ?? '') + ' TEST Julian');
      const customers = await this.socketService.findContactsWithEvents('Yvonne Hennes');
      this.formGet('priceEstimatedFrom').setValue(50);
      this.formGet('customer').setValue(customers[0]);
      this.formGet('noDepotNecessary').setValue(true);
      this.formGet('noDepotNecessaryReason').setValue('test');
      const artist = this.artists.find(a => a.name === 'Test Julian');
      this.formGet('artist').setValue(artist);
      (this.formGet('bodyPutsTattoo') as FormArray).controls[0].setValue(
        {
          bodyPut: ['unknown'],
          motive: 'test',
          round: false,
          thinLines: false,
          size: '10cm',
          goldBall: false,
        },
      );
    }
  }

  prependFollowUpText(info: string, followUp: any) {
    if (!followUp) {
      return info;
    }
    const text = '[Folgetermin ' + (followUp.index + 1) + ' von ' + followUp.total + ']';
    const match = (new RegExp(/\[Folgetermin[^\]]+]/g)).exec(info);
    if (match && match.length > 0) {
      return info.replace(match[0], text);
    } else {
      return text + ' ' + info;
    }
  }

  async showCustomerEvents() {
    if (this.openerComponent === 'ArtistEventsViewComponent') {
      this.close(true);
    } else if (this.formGet('customer').value?.id) {
      const dialog = this.dialogService.showEventsByContact(this.formGet('customer').value.id, true);
      const result = await firstValueFrom(dialog.afterClosed());
      if (result?.eventId) {
        this.loadEvent(result);
      }
      if (result?.newEventData) {
        this.loadEvent({newEventData: result.newEventData});
      }
    }
  }

  private async checkPriceChangeBeforeSave(oldPrice: number, newPrice: number) {
    if (oldPrice && oldPrice !== newPrice && this.loginService.isReception()) {
      const result = await this.dialogService.showInput(
        'Warum hat sich der Preis geändert?\n\nVorher: ' + oldPrice.toMoneyString() + '\nJetzt: ' + newPrice.toMoneyString(),
        {
          width: '600px',
          title: 'Preisänderung',
        });
      if (result) {
        this.priceChanges.push({
          from: oldPrice,
          to: newPrice,
          t: Date.now(),
          u: this.loginService.getUsername(),
          i: result,
        });
        this.priceChanges = this.priceChanges.sortNumber('t', true);
      }
      if (!result) {
        return false;
      }
    }
    return true;
  }


  isArtistOpen() {
    return this.formGet('artist').value?.summary?.includes('offen');
  }

  async deleteEvent() {
    if (await this.dialogService.showYesNo('Willst du wirklich den Termin löschen?')) {
      this.dialogService.showLoading('Termin wird gelöscht...');
      const result = await this.socketService.deleteCalendarEvent2(this.eventId);
      this.dialogService.hideLoading();
      this.close(false);
      this.afterClosed.emit({op: 'deleted'});
    }
    // TODO: Event Löschen
  }

  selectedPhotosChanged() {
    for (const fileId of Object.keys(this.selectedMedia())) {
      if (this.selectedMedia()[fileId]) {
        const media = this.photosAndVideos.find(pv => pv.id === fileId);
        if (media.size > 1024 * 1024 * 40) {
          requestAnimationFrame(() => {
            this.selectedMedia.update(mediaSelected => {
              delete mediaSelected[fileId];
              return {...mediaSelected};
            });
          });
          this.dialogService.showOk('Video ist zu groß!');
        }
      } else {
        this.selectedMedia.update(mediaSelected => {
          delete mediaSelected[fileId];
          return {...mediaSelected};
        });
      }
    }
  }

  async newReminder() {
    await this.reminderService.showCreateReminder({eventId: this.eventId});
    this.loadReminder();
    /*if (!this.eventId) {
      this.dialogService.showOk('Speicher erst den Termin, dann kannst du danach Erinnerungen erstellen');
    } else {
      const dialogRef = this.dialogService.showComponentDialog(ReminderEditComponent, {}, {
        minWidth: '500px'
      });
      dialogRef.componentInstance.newReminder(this.eventId);
      await firstValueFrom(dialogRef.afterClosed());
      this.loadReminder();
    }*/
  }

  private async loadReminder() {
    if (this.eventId) {
      this.reminders = await this.socketService.getRemindersByEventId(this.eventId);
    } else {
      this.reminders = [];
    }
  }

  private log(message: string) {
    Log.info('CALENDAR-EVENT-EDIT | ' + message);
  }

  public closeAndSave() {
    this.formGet('closed').setValue(true);
    this.saveAndCloseDialog(false);
  }

  public linkArtistPhotos() {
    const artist = this.formGet('artist').value.artist;
    this.dialogService.showComponentDialog(ArtistPhotoUnknownUploadsComponent, {eventId: this.eventId, artist});
  }


  getSelectedPhotoIds(): string[] {
    const ids: string[] = [];
    for (const id of Object.keys(this.selectedMedia())) {
      if (this.selectedMedia()[id]) {
        ids.push(this.selectedMedia()[id].id);
      }
    }
    return ids;
  }

  getSelectedEventFileIds(): string[] {
    const ids: string[] = [];
    for (const id of Object.keys(this.selectedEventFiles())) {
      if (this.selectedEventFiles()[id]) {
        ids.push(this.selectedEventFiles()[id].id);
      }
    }
    return ids;
  }

  public async sendPhotosToUnknownFolder() {
    /*this.dialogService.showOk('Diese Funktion ist noch in fertig');
    return;*/
    const fileIds = this.getSelectedPhotoIds();
    const photosToClipboard = this.photosAndVideos.filter(photo => fileIds.includes(photo.id));
    const text = 'Die ' + photosToClipboard.length + ' markierten Fotos gehören nicht zu diesem Termin?';
    if (await this.dialogService.showYesNo(text)) {
      this.socketService.sendEventPhotosToClipboard({
        eventId: this.eventId,
        photos: photosToClipboard.map(p => {
          return {
            name: p.filename,
            fileId: p.id,
            thumbnailLink: p.thumbnailLink,
            createdAt: p.createdTime,
            mimeType: p.mimeType,
          };
        }),
      });
      // this.dialogService.showOk('Bilder werden im Hintergrund verschickt');
      this.selectedMedia.set({});
      this.dialogService.showOk('Gehe nun in den Termin zu dem die Fotos gehören und füge sie dort über "Fotos aus anderem Termin einfügen" ein');
      // this.photos = this.photos.filter(p => !fileIds.includes(p.id));
    }
  }

  public async printArtist() {
    if (this.loginService.getStudio() === 'Staging') {
      return;
    }

    this.dialogService.showLoading('Artist wird gedruckt');
    try {
      const artist: NxtArtist = this.formGet('artist').value;
      if (artist.name === 'Tobi' || artist.employed) {
        this.dialogService.hideLoading();
        return;
      }
      if (artist) {
        const lines: string[] = [];
        lines.push('Vertragspartner:');
        lines.push(artist.invoiceData.name);
        const result = await this.socketService.printSmallText({
          text: lines.join('\n'),
          studio: this.loginService.getStudio(),
          orientation: 'standard',
          margin_top: 20,
          margin_bottom: 20,
          font_size: 50,
        });
        this.dialogService.hideLoading();
        if (result) {
          const text = 'Fehler beim Artist drucken, versuche es bitte erneut\n' + result;
          this.dialogService.showOk(text);
        }
      }
    } catch (err) {
      this.dialogService.hideLoading();
      this.dialogService.showOk(err.message);
    }
  }

  private fixPaymentsBeforeSaveAndSetToCalendarEvent(payments: NxtPayment[]) {
    let counter = 0;
    for (const payment of payments) {
      if (payment.paymentMethod === 'gift-card' && typeof payment.paymentGiftCard === 'string') {
        throw Error('defekter Gutschein');
      }

      if (typeof payment.paymentValue === 'string') {
        payment.paymentValue = parseFloat((payment.paymentValue as string).replace(',', '.'));
      }

      if (payment.paymentDate.toString().length > 10) {
        payment.paymentDate = DateTools.format(DateTools.parse(payment.paymentDate), 'yyyy-MM-dd');
      }
      if (typeof payment.createdAt === 'string') {
        payment.createdAt = DateTools.parse(payment.createdAt);
      }
      counter++;
    }
  }

  public async setSkill() {
    const dialog = this.dialogService.showComponentDialog(SkillSelectComponent);
    dialog.componentInstance.currentSkills = clone(this.skill.skills);
    dialog.componentInstance.currentBoolSkills = clone(this.skill.boolSkills);
    const result = await firstValueFrom(dialog.afterClosed());
    if (result) {
      const changed = !ObjectTools.equal(this.skill, result);
      if (changed) {
        this.skill = result;
        this.calcArtistSkill();
        if (this.skill.skills.Mandala) {
          this.dialogService.showOk('Ein Mandala ist aufwendiger als ein "normales" Tattoo, beachte dies auch im Preis 💸', {
            title: 'Mandala',
            buttonText: 'Ja beachte ich!',
          });
        }
        if (this.skill.skills.Maori) {
          this.dialogService.showOk('Ein Maori ist aufwendiger als ein "normales" Tattoo, beachte dies auch im Preis 💸', {
            title: 'Maori',
            buttonText: 'Ja beachte ich!',
          });
        }
        this.formGet('disableSkillCheck').setValue(false);
      }
    }
    this.setChanged('Skill gesetzt');
  }

  private getAdditionalErrors() {
    const additionalErrors: string[] = [];
    if (this.formGet('workType').value === 'tattoo') {
      if (Object.keys(this.skill.skills).length === 0 && Object.keys(this.skill.boolSkills).length === 0) {
        additionalErrors.push('skill');
      }
    }
    return additionalErrors;
  }

  private calcArtistSkill() {
    if (this.formGet('workType').value === 'tattoo') {
      this.artistSkill = undefined;
      const artist: NxtArtist = this.formGet('artist').value;
      if (artist) {
        if (artist.id === 'canceled') {
          return;
        }
        if (ArtistsTools.isArtistOpen(artist.name)) {
          return;
        }
        if (this.skill && artist && (Object.keys(this.skill.skills).length > 0 || Object.keys(this.skill.boolSkills).length > 0)) {
          this.artistSkill = ArtistSkillTools.getArtistSkillResultFromEvent(this.skill, artist);
        }
      }
    }
  }

  private async checkArtistSkillBeforeSave() {
    if (this.forceSaveHack) {
      return true;
    }
    if (!this.artistSkill) {
      return true;
    }
    if (this.formGet('disableSkillCheck').value) {
      return true;
    }
    if (!this.artistSkill.canUse) {
      const artist = this.formGet('artist').value;
      if (artist.name) {
        let text = artist.name + ' ist\n' + this.artistSkill.infoLines.join('\nund ');
        text += '\n\nWenn du der Meinung bist, ' + artist.name + ' kann das,\nspricht bitte das Backoffice an,\ndamit die Artist-Fähigkeiten angepasst werden';
        await this.dialogService.showOk(text, {title: 'Speichern nicht möglich'});
      }
      return false;
    } else if (this.artistSkill.attention) {
      const result = await this.dialogService.showYesNo('Der Artist hat nur ' + this.artistSkill.stars.roundToString(1) + ' Sterne\nbist du dir sicher?', {
        yesText: 'Ja trotz ' + this.artistSkill.stars.roundToString(1) + ' Sterne speichern',
        noText: 'Abbrechen',
      });
      if (!result) {
        return false;
      }
    }
    return true;
  }

  public async requestImageFromCustomer() {
    if (this.configService.config.value.studioRegion === 'MA') {
      this.dialogService.showOk('Bitte mit dem QR-Code arbeiten');
      return;
    }
    const customer = this.formGet('customer').value as NxtContact;
    if (customer.id && customer.mobileFormatted) {

      const text = `Hey ${customer.givenName},
wir würden dich bitten in diesen Chat hier kurz deine Vorlagen bzw. Beispielbilder zu senden, damit wir sofort mit der Designerstellung starten können!
Sofern du gerne ein Datum, eine Uhrzeit oder einen Schriftzug in deinem Design hättest, schick uns dieses/diesen doch bitte zusätzlich einfach als Nachricht hier in den Chat.
Dankeschön :)`;
      const dialog = await this.dialogService.showTextareaOld({message: 'Text an Kunden schicken', prompt: text});
      const result = await firstValueFrom(dialog.afterClosed());
      if (result) {
        this.socketService.sendWhatsAppMessage(customer.mobileFormatted, text);
      }
    }
  }

  private transactionMoreShorten(payment: NxtPayment, toShort: number) {
    if (payment.paymentBankTransaction) {
      if (payment.paymentBankTransaction.name.length > toShort) {
        payment.paymentBankTransaction.name = payment.paymentBankTransaction.name.substring(0, payment.paymentBankTransaction.name.length - toShort);
      }
    }
    if (payment.paymentPaypalTransaction) {
      if (payment.paymentPaypalTransaction.message.length > toShort) {
        payment.paymentPaypalTransaction.message = payment.paymentPaypalTransaction.message.substring(0, payment.paymentPaypalTransaction.message.length - toShort);
      }
    }
  }

  resetCalendarEvent() {
    this.nextNxtUpdateId = UuidTools.generate();
    this.isSafe = false;
    this.restore = false;
    this.calcDurationText();
    this.dateOrTimeOrPriceOrPaymentsChanged();
    this.shouldDepositBackChanged(false);
    this.form?.markAsUntouched();
    this.photosAndVideos = [];
    this.pdfs = [];
    this.originalEvent.set(EventTools.getEmptyEvent());
    this.originalFormRawValue = {};
    setTimeout(() => {
      this.clearChanges();
    }, 1000);
  }

  public printFreeShot() {
    if (this.formGet('customer').value) {
      this.labelPrintService.printFreeShot(this.formGet('customer').value.givenName);
    }
  }


  nxtOnDestroy(): void {
    this.formValueChangeSubscription?.unsubscribe();
    this.shortcutSubscription.unsubscribe();
    this.shortcutSubscription = null;
    this.tattooTemplateSubscription?.unsubscribe();
  }

  private beforeSaveHook(data: {
    newPayments: NxtPayment[],
    newCustomer: NxtContact,
    newDateString: string
  }) {
    const customerAvailable = !!data.newCustomer;
    const eventIsToday = data.newDateString === DateTools.formatNowDate();
    const hasNewPaymentsFromReception = data.newPayments.some(p => p.workplace === 'reception');
    if (customerAvailable && eventIsToday && hasNewPaymentsFromReception) {
      const missingCustomerFields: string[] = [];
      if (!data.newCustomer.mobile) {
        missingCustomerFields.push('Handynummer');
      }
      if (!data.newCustomer.postalCode) {
        missingCustomerFields.push('PLZ');
      }
      if (missingCustomerFields.length > 0) {
        this.dialogService.showOk('Für den Kunden ' + data.newCustomer.givenName + ' fehlt ' + missingCustomerFields.join(' & '), {
          title: 'Speichern noch nicht möglich',
        });
        return false;
      }
    }
    return true;
  }

  async safeAndCloneEventClicked() {
    const saveResult = await this.saveAndCloseDialog(false, false);
    if (saveResult) {
      const newEventData: NxtNewEventData = {
        dateString: saveResult.startDateString,
        timeFromString: saveResult.start.dateFormat('HH:mm'),
        timeTillString: saveResult.end.dateFormat('HH:mm'),
        // studio: saveResult.studio,
        artist: saveResult.artist,
        workType: saveResult.workType,
        info: saveResult.info,
        importantInfo: saveResult.importantInfo,
        skill: saveResult.skill,
        priceEstimatedFrom: saveResult.priceEstimatedFrom,
        priceEstimatedTill: saveResult.priceEstimatedTill,
        discountPromotionId: !saveResult.promoOfferId ? saveResult.discountPromotion?.id : null,
        bodyPutsTattoo: saveResult.bodyPuts.tattoo,
        bodyPutsPiercing: saveResult.bodyPuts.piercing,
        bodyPutsBeauty: saveResult.bodyPuts.beauty,
        artistPercentage: saveResult.artistPercentage,
        noAppointmentReminder: saveResult.noAppointmentReminder,
        priceFix: saveResult.priceFix,
        adjustPrice: saveResult.adjustPrice,
        artistFix: saveResult.artistFix,
        artistFixReason: saveResult.artistFixReason,
        customer: saveResult.customer,
        improveArtistShouldGetMoney: saveResult.improveArtistShouldGetMoney,
        fastWalkIn: saveResult.fastWalkIn,
        customerRefId: saveResult.customerRefId,
        // noDepotNecessary: saveResult.noDepotNecessary, // das nicht übernehmen
      };
      this.loadEvent({newEventData}).then();
    }
  }

  private async checkLoadNewEventData() {
    await TimeTools.waitAnimationFrame();
    if (this.newEventData) {
      if (this.newEventData.askWorkType && !this.newEventData.workType) {
        const availableArtists = await this.socketService.getAvailableArtistsDay(this.newEventData.dateString);
        const piercingArtists = availableArtists.artists.filter(a => a.workType === 'piercing');
        const toothGemArtists = piercingArtists.filter(a => a.toothGem);
        if (piercingArtists.length > 0) {
          let title = 'Piercing oder Tattoo?';
          const buttons = [
            {value: 'piercing', text: 'Piercing'},
            {value: 'tattoo', text: 'Tattoo'},
          ];
          if (toothGemArtists.length > 0) {
            buttons.push({value: 'tooth-gem', text: 'Zahnschmuck'});
            title = 'Piercing, Tattoo oder Zahnschmuck?';
          }
          const result = await this.dialogService.showButtonChooserNew({buttonRows: [buttons], title, hideCancelButton: true, hideBackButton: true});
          if (result) {
            if (result.value === 'piercing') {
              const dateTime = (this.newEventData.dateString + ' ' + this.newEventData.timeFromString).dateParse();
              this.newEventData = await this.walkInService.getPiercingWalkInNewEventData(dateTime, false);
              await this.checkLoadNewEventData();
              return;
            }
            if (result.value === 'tooth-gem') {
              const dateTime = (this.newEventData.dateString + ' ' + this.newEventData.timeFromString).dateParse();
              this.newEventData = await this.walkInService.getToothGemWalkInNewEventData(dateTime, false);
              await this.checkLoadNewEventData();
              return;
            }
            this.formGet('workType').setValue(result.value);
          }
        } else {
          this.formGet('workType').setValue('tattoo');
        }
      }
      if (this.newEventData.timeFromString) {
        this.formGet('timeFrom').setValue(this.newEventData.timeFromString);
      } else {
        this.formGet('timeFrom').setValue('11:00');
      }
      if (this.newEventData.timeTillString) {
        this.formGet('timeTill').setValue(this.newEventData.timeTillString);
      } else {
        this.formGet('timeTill').setValue('18:00');
      }
      if (this.newEventData.dateString && !this.newEventData.showEventFinder) {
        // nicht bei showEventFinder, da das Datum von da dann kommt
        this.formGet('date').setValue(this.newEventData.dateString);
      }
      if (this.newEventData.artist) {
        const artist = this.artists.find(a => a.name === this.newEventData.artist);
        if (artist) {
          this.formGet('artist').setValue(artist);
        }
      }
      if (this.newEventData.contact) {
        this.formGet('customer').setValue(this.newEventData.contact);
      }

      this.formGet('fastWalkIn').setValue(!!this.newEventData.walkIn);

      if (this.newEventData.workType) {
        if (this.formGet('workType').value !== this.newEventData.workType) {
          this.formGet('workType').setValue(this.newEventData.workType);
        }
      }

      if (this.newEventData.artistPercentage) {
        this.formGet('artistPercentage').setValue(this.newEventData.artistPercentage);
      }

      if (this.newEventData.priceEstimatedFrom) {
        this.formGet('priceEstimatedFrom').setValue(this.newEventData.priceEstimatedFrom);
      }

      if (this.newEventData.priceEstimatedTill) {
        this.formGet('priceEstimatedTill').setValue(this.newEventData.priceEstimatedTill);
      }

      if (this.newEventData.info) {
        this.formGet('info').setValue(this.newEventData.info);
      }

      if (this.newEventData.importantInfo) {
        this.formGet('importantInfo').setValue(this.newEventData.importantInfo);
      }

      if (this.newEventData.bodyPutsPiercing) {
        this.removeBodyPut('bodyPutsPiercing');
        for (const bodyPutPiercing of this.newEventData.bodyPutsPiercing) {
          this.addBodyPut('bodyPutsPiercing', bodyPutPiercing);
        }
      }

      if (this.newEventData.bodyPutsTattoo) {
        this.removeBodyPut('bodyPutsTattoo');
        for (const bodyPutTattoo of this.newEventData.bodyPutsTattoo) {
          this.addBodyPut('bodyPutsTattoo', bodyPutTattoo);
        }
      }


      if (this.newEventData.bodyPutPiercingCount) {
        for (let i = 1; i < this.newEventData.bodyPutPiercingCount; i++) {
          this.addBodyPut('bodyPutsPiercing');
        }
      }
      if (this.newEventData.openArtist) {
        const artist = this.artists.find(a => a.name === ArtistsTools.getArtistOpenTattoo().name);
        if (artist) {
          this.formGet('artist').setValue(artist);
        }
      }

      if (this.newEventData.openPiercing) {
        const artist = this.artists.find(a => a.name === ArtistsTools.getArtistOpenPiercing().name);
        if (artist) {
          this.formGet('artist').setValue(artist);
        }
      }
      if (this.newEventData.skill) {
        this.skill = this.newEventData.skill;
      }
      if (typeof this.newEventData.improveArtistShouldGetMoney === 'boolean') {
        this.formGet('improveArtistShouldGetMoney').setValue(this.newEventData.improveArtistShouldGetMoney);
      }

      if (typeof this.newEventData.artistFix === 'boolean') {
        this.formGet('artistFix').setValue(this.newEventData.artistFix);
      }

      if (this.newEventData.artistFixReason) {
        this.formGet('artistFixReason').setValue(this.newEventData.artistFixReason);
      }

      if (typeof this.newEventData.noDepotNecessary === 'boolean') {
        debugger;
        this.formGet('noDepotNecessary').setValue(this.newEventData.noDepotNecessary);
      }
      if (this.newEventData.customer) {
        const customer = await this.socketService.getContactById(this.newEventData.customer);
        if (customer) {
          this.formGet('customer').setValue(customer);
        }
      }
      if (this.newEventData.discountPromotionId) {
        const d = await this.socketService.getDiscountPromotion(this.newEventData.discountPromotionId);
        if (d) {
          this.formGet('discountPromotion').setValue(d);
        }
      }
      if (typeof this.newEventData.noAppointmentReminder === 'boolean') {
        this.formGet('noAppointmentReminder').setValue(this.newEventData.noAppointmentReminder);
      }
      if (typeof this.newEventData.priceFix === 'boolean') {
        this.formGet('priceFix').setValue(this.newEventData.priceFix);
      }
      if (typeof this.newEventData.adjustPrice === 'boolean') {
        this.formGet('adjustPrice').setValue(this.newEventData.adjustPrice);
      }

      if (this.newEventData.toPayOnEventDate) {
        this.formGet('toPayOnEventDate').setValue(this.newEventData.toPayOnEventDate);
      }

      if (this.newEventData.showEventFinder && this.formGet('workType').value === 'tattoo') {
        let fromTime = this.configService.config.value.studioWorkStart;
        if (this.newEventData.timeFromString) {
          fromTime = DurationTools.parse(this.newEventData.timeFromString) / DurationTools.DURATION_1HOUR;
        }
        this.showEventFinder({
          fromDateString: this.newEventData.dateString,
          fromTime,
          selectDurationOnStart: true,
          selectSkillsOnStart: false,
          skills: this.newEventData.skill?.skills,
          boolSkills: this.newEventData.skill?.boolSkills,
        });
      }
      if (typeof this.newEventData.improve === 'boolean') {
        this.formGet('improve').setValue(this.newEventData.improve);
      }
      if (typeof this.newEventData.improveOriginalArtist) {
        this.formGet('improveOriginalArtist').setValue(this.newEventData.improveOriginalArtist);
      }

      if (this.newEventData.customerRefId) {
        await this.setCustomerRefId(this.newEventData.customerRefId);
      }

      if (typeof this.newEventData.priceAuto === 'boolean') {
        this.formGet('priceAuto').setValue(this.newEventData.priceAuto);
      }
    }
  }

  async eventPhotoClicked(photo: SocketInterfaceResponse.EventPhoto & { src?: string }) {
    if (photo.mimeType.startsWith('video')) {
      this.openGoogleDriveFolder();
      return;
    }
    const images = this.photosAndVideos.filter(p => !p.mimeType.startsWith('video'));
    const loadEventPhoto = async (index: number) => {
      const eventPhoto = await this.socketService.getEventPhoto(this.eventId, images[index].id);
      const src = 'data:' + eventPhoto.mimeType + ';base64,' + eventPhoto.base64;
      return {src, isFirst: index === 0, isLast: index === images.length - 1, title: 'Foto ' + (index + 1) + '/' + images.length + '\n' + images[index].filename};
    };
    const dialog = this.dialogService.showImagesAsync(images.length, images.indexOf(photo), loadEventPhoto);
    dialog.componentInstance.buttonClicked.subscribe(async () => {
      const artist = this.getArtistFromForm();
      if (artist) {
        const buttons = ArtistsTools.badPhotoTexts.map(text => ({value: text.replaceAll('{{artist}}', artist.name), text: text.replaceAll('{{artist}}', artist.name)}));
        const result = await this.dialogService.showButtons('Die Nachricht wird in die Sprache des Artists übersetzt', {
          title: 'Schicke eine Nachricht an den Artist',
          buttons,
          showCancelButton: true,
          buttonDirection: 'column',
          textAlign: 'center',
        });
        if (result) {
          const photoId = images[dialog.componentInstance.currentIndex].id;
          await this.socketService.sendBadPhotoToArtist(this.eventId, result.value, photoId);
          this.dialogService.showOk('Nachricht wurde an ' + artist.name + ' geschickt');
        }
      }
    });
  }

  checkArtistEmployed() {
    const artist = this.getArtistFromForm();
    if (artist) {
      if (artist.employed) {
        this.formGet('artistPercentage').setValue(0);
      }
    }
  }

  private getArtistFromForm(): NxtArtist | undefined {
    const artist = this.formGet('artist').value;
    if (artist?.name) {
      return artist;
    }
  }

  /*private async isCashRegisterTodayClosed() {
    const reason = await this.socketService.canBook(Date.now(), this.loginService.getStudio());
    return !!reason;
    if (reason && showError) {
      const force = await this.dialogService.showOkWithForceIfJulian(reason);
      return !force;
    }
    return false;
  }*/

  async showAppointmentConfirmationTextClicked() {
    if (this.isNewEvent || this.changes().length > 0) {
      if (await this.dialogService.showYesNo('Du musst erst deine Änderungen speichern', {yesText: 'Speichern dann Terminerinnerung', noText: 'Abbrechen'})) {
        this.actionAfterSaved = 'appointment-reminder';
        const result = await this.saveAndCloseDialog(false);
        this.actionAfterSaved = '';
      }
      return;
    } else {
      this.showAppointmentConfirmationText();
    }
  }

  async printQrClicked() {
    // await this.checkToPayOnEventDate(savedEvent);
    const toPay: number = this.formGet('toPayOnEventDate').value?.filter(toPay => !toPay.payed).reduce((sum, toPay) => sum + toPay.value, 0);
    if (this.changes().length > 0 || toPay > 0) {
      if (await this.dialogService.showYesNo('Du musst erst deine Änderungen speichern', {yesText: 'Speichern dann drucken', noText: 'Abbrechen'})) {
        this.actionAfterSaved = 'print-qr';
        const result = await this.saveAndCloseDialog(false);
        this.actionAfterSaved = '';
      }
      return;
    }
    this.printQrEvent(false).then();
  }

  private async waitForArtistCalendars() {
    return new Promise<void>((resolve) => {
      if (this.artists.length > 0) {
        resolve();
      } else {
        this.cacheService.artists.pipe(filter(a => a.length > 0)).pipe(first()).subscribe(() => resolve());
      }
    });
  }

  private hideLoadingEventDialog() {
    TimeoutTools.clear(this.loadEventLoadingTimeout);
    this.dialogService.hideLoading(LoadingId.CalendarEventEditLoading);
  }

  async printCustomerReceipt(paymentUuid: string) {
    try {
      if (this.changes().length > 0) {
        await this.dialogService.showOk('Termin erst speichern');
        return;
      }
      this.dialogService.showLoading('Drucke Beleg');
      const error = await this.socketService.printCustomerReceipt(this.eventId, paymentUuid);
      this.dialogService.hideLoading();
      if (error) {
        await this.dialogService.showOk(error);
      }
    } catch (err) {
      Log.error(err);
      await this.dialogService.showOk(err.message);
      this.thermalPrinterService.showChangeIp().then();
      this.dialogService.hideLoading();
    }
  }

  async showParsedContactsClicked() {
    const dialog = this.dialogService.showComponentDialog<ContactParserComponentDialogClose>(ContactParserComponent);
    const result = await firstValueFrom(dialog.afterClosed());
    if (result) {
      if (result.action === 'createContact') {
        const savedContact = await this.showContactForm(result.parsedContact);
        if (savedContact) {
          this.socketService.deleteParsedContact(result.id).then();
        }
      } else if (result.action === 'useContact') {
        this.formGet('customer').setValue(result.contact);
        this.socketService.deleteParsedContact(result.id).then();
      }
    }
  }

  followUpChanged() {
    this.formGet('info').setValue(this.prependFollowUpText(this.formGet('info').value, this.followUp));
  }

  private calcIsFirstCustomerEvent() {
    this.isFirstCustomerEvent = false;
    const customer = this.formGet('customer').value;
    const date = this.formGet('date').value;
    if (customer && date) {
      this.isFirstCustomerEvent = ContactTools.isFirstAppointment(customer, DateTools.parse(date));
    }
  }

  async printShishaClicked() {
    this.dialogService.showLoading('Wird gedruckt..');
    const result = await this.socketService.printShisha(this.originalEvent().id);
    this.dialogService.hideLoading();
    if (result) {
      this.dialogService.showOk(result).then();
    }
  }

  private clearChanges() {
    this.changes.set([]);
    this.originalFormRawValue = {};
    if (this.form) {
      this.originalFormRawValue = this.form.getRawValue();
    }
  }

  getFormName(controlName: string) {
    const name = (this.formGet(controlName) as NxtFormControl)?.name;
    if (name) {
      return name;
    }
    switch (controlName) {
      case 'bodyPutsTattoo':
        return 'Tattoo-Körperstelle';
    }
    return controlName;
  }

  private setChanged(name: string) {
    name = this.getFormName(name);
    if (!this.changes().includes(name)) {
      this.changes.update(changes => {
        changes.push(name);
        return [...changes];
      });
      this.generateTitle();
      this.originalFormRawValue = this.form.getRawValue();
    }
  }

  openAdmin() {
    this.router.navigateByUrl('/admin');
    this.close(true);
  }

  /*async showSizeInputClicked(bodyPut: FormGroup<any>) {
    const buttonRows: { text: string }[][] = [[]];
    buttonRows.push([{text: '10cm'}, {text: '15cm'}, {text: '20cm'}, {text: '25cm'}, {text: '30cm'}, {text: '35cm'}, {text: '40cm'}, {text: '45cm'}, {text: '50cm'}]);
    const result = await this.dialogService.showButtonChooserNew({title: 'Größe', text: 'abnc', buttonRows});
    bodyPut.get('size').setValue(result.text);
  }*/
  displayArtistCalendarOption = (artist: NxtArtist, highlight: (text) => string): string | undefined => {
    let result = '?';
    if (artist) {
      result = artist.calendarNumber + ' ' + artist.name;
    }
    if (this.availableArtistThisDate[artist.name]) {
      result = '⭐️' + result + '⭐️';
    }
    return highlight(result);
  };


  reSortArtists() {
    this.artists = this.artists.sort((a, b) => {
      const aIsHere = this.availableArtistThisDate[a.name];
      const bIsHere = this.availableArtistThisDate[b.name];
      if (aIsHere && !bIsHere) {
        return -1;
      }
      if (!aIsHere && bIsHere) {
        return 1;
      }
      return a.calendarNumber.localeCompare(b.calendarNumber);
    });
  }

  setDepotDueDate(daysAdd: number) {
    const nextWorkingDay = this.workingDayService.addDaysUntilWorkingDay(Date.now().clearTime().dateAddDays(daysAdd));
    this.formGet('depotDueDate').setValue(moment(nextWorkingDay));
  }

  private async getAvailableArtistThisDay() {
    const availableArtistsDay = await this.socketService.getAvailableArtistsDay(this.formGet('date').value);
    this.availableArtistThisDate = {};
    if (availableArtistsDay?.artists) {
      availableArtistsDay?.artists.forEach(a => this.availableArtistThisDate[a.name] = true);
    }
    this.reSortArtists();
  }

  editArtist() {
    const dialog = this.dialogService.showComponentFull(ArtistEditComponent);
    dialog.componentInstance.setData(this.formGet('artist').value.id);
  }

  private bodyPutChanged() {
    this.calcAutoPrice();
  }

  private autoPriceByBeauty() {
    const bodyPutsBeauty: any[] = this.formGet('bodyPutsBeauty').getRawValue();
    if (bodyPutsBeauty) {
      let value = 0;
      for (const bodyPutBeauty of bodyPutsBeauty) {
        const data = this.bodyPutService.getBodyPutBeauty(bodyPutBeauty.bodyPut);
        if (data.price) {
          value += data.price;
        }
      }
      if (value) {
        this.formGet('priceEstimatedFrom').setValue(value);
      }
    }
  }

  showRatingClicked() {
    if (this.openerComponent === 'EventsRatingComponent') {
      this.closeWithoutSaveClicked();
    } else {
      this.dialogService.showEventRating(this.originalEvent().id, 'CalendarEventEditComponent');
    }
  }

  registerTattooTemplates() {
    this.tattooTemplateSubscription?.unsubscribe();
    this.tattooTemplateSubscription = this.tattooTemplateService.currentTattooTemplates
      .pipe(map(tattooTemplates => {
        return tattooTemplates.filter(t => t.eventId === this.eventId);
      }))
      .subscribe(tattooTemplates => {
        this.newTattooTemplates.set(tattooTemplates);
        if (tattooTemplates.length > 0) {
          this.setChanged('Tattoo-Vorlage');
        }
      });
  }

  selectMedia(media: SocketInterfaceResponse.EventPhoto, checked: boolean) {
    this.selectedMedia.update(mediaSelected => {
      if (checked) {
        mediaSelected[media.id] = media;
      } else {
        delete mediaSelected[media.id];
      }
      return {...mediaSelected};
    });
  }

  resetSelectedMediaClicked() {
    this.selectedMedia.set({});
  }

  async sendSelectedMediaClicked() {
    const fileIds = keys(this.selectedMedia());
    const customer = this.formGet('customer').value;
    const text = 'Möchtest du ' + this.selectedMediaText() + ' an ' + customer.fullName + ' schicken?';
    if (await this.dialogService.showYesNo(text)) {
      this.socketService.sendEventPhotosToCustomer({
        eventId: this.eventId,
        fileIds,
        contactId: customer.id,
      });
      this.dialogService.showOk('Bilder werden im Hintergrund verschickt');
      this.selectedMedia.set({});
    }
  }

  updatePhotoType(photo: SocketInterfaceResponse.EventPhoto, subType: NxtDriveFileSubType) {
    if (this.changes().length > 0) {
      this.dialogService.showOk('Foto-Typ ändern geht nur ohne Änderungen am Termin, speicher erst den Termin');
      return;
    }
    return this.socketService.setEventPhotoTypeOld(this.eventId, photo.id, subType);
  }

  showChangesClicked() {
    this.dialogService.showOk(this.changes().join('\n'));
  }

  deleteTattooTemplateClicked(tattooTemplate: NxtTattooTemplate) {
    this.tattooTemplateService.deleteTattooTemplate(tattooTemplate);
  }

  async deletePhotoClicked(photo: SocketInterfaceResponse.EventPhoto & { sortValue?: string }) {
    if (await this.dialogService.showYesNo('Foto löschen?')) {
      this.socketService.deleteEventFile(this.eventId, photo.id).then();
    }
  }

  async shareEventClicked() {
    if (!this.eventId) {
      this.dialogService.showOk('Speicher erst den Termin, dann kannst du in senden');
      return;
    }
    const buttons: { value: string, text: string }[] = [
      {value: MobileTools.Numbers.Julian, text: 'Julian'},
      {value: MobileTools.Numbers.NiklasNXT, text: 'Niklas'},
      {value: MobileTools.Numbers.CaponeNXT, text: 'Capone'},
      {value: MobileTools.Numbers.Lili, text: 'Lili'},
    ];
    const shareTo = await this.dialogService.showButtons('Mit wem möchtest du den Termin teilen?', {buttons, showCancelButton: true});
    if (shareTo) {
      const text = await this.dialogService.showInput('Info dazu');
      this.socketService.sendWhatsAppMessage(shareTo.value, 'von ' + this.loginService.getUsername() + '\n\n' + text + '\n\n' + window.location.origin + '/e/' + this.eventId);
    }
  }

  copyEventToClipboardClicked() {
    this.clipboard.copy(this.eventUrl);
  }

  openConsentClicked() {
    const consentFile = EventConsentTools.getBestConsent(this.originalEvent());
    if (consentFile) {
      const url = DriveTools.getDriveLink(consentFile.id);
      this.dialogService.showPdfSrc(url, 'Einverständniserklärung.pdf');
    } else {
      this.openGoogleDriveFolder();
    }
  }

  videoClicked(eventFile: NxtCalendarEventFile) {
    const dialog = this.dialogService.showComponentFull(VideoComponent);
    dialog.componentInstance.loadDriveVideo(eventFile);
  }

  consentClicked(eventFile: NxtCalendarEventFile) {
    const url = DriveTools.getDriveLink(eventFile.id);
    this.dialogService.showPdfSrc(url, this.originalEvent().startDateString + '_' + this.originalEvent().id + '_Einverständniserklärung.pdf');
  }

  updatePhotoType2(eventFile: NxtCalendarEventFile, subType: NxtDriveFileSubType) {
    if (eventFile.type === 'video') {
      if (subType === 'tattooPhoto') {
        subType = 'tattooVideo';
      } else if (subType === 'preTattooVideo') {
        subType = 'preTattooVideo';
      } else if (subType === 'stencilPhoto') {
        subType = 'stencilVideo';
      }
    }
    this.socketService.updateEventFileSubType(this.eventId, eventFile.id, subType);
  }

  deletePhotoClicked2(eventFile: NxtCalendarEventFile) {

  }

  selectEventFileClicked(eventFile: NxtCalendarEventFile, checked: boolean) {
    this.selectedEventFiles.update(selectedEventFiles => {
      if (checked) {
        selectedEventFiles[eventFile.id] = eventFile;
      } else {
        delete selectedEventFiles[eventFile.id];
      }
      return {...selectedEventFiles};
    });
  }

  resetSelectedEventFilesClicked() {
    this.selectedEventFiles.set({});
  }

  async sendEventFileToUnknownFolderClicked() {
    const eventFiles = keys(this.selectedEventFiles()).map(fileId => this.selectedEventFiles()[fileId]);
    const text = this.selectedEventFileText() + ' gehören nicht zu diesem Termin?';
    if (await this.dialogService.showYesNo(text)) {
      this.socketService.sendEventFilesToClipboard(this.eventId, eventFiles);
      // this.dialogService.showOk('Bilder werden im Hintergrund verschickt');
      this.selectedEventFiles.set({});
      this.dialogService.showOk('Gehe nun in den Termin zu dem die Fotos gehören und füge sie dort über "Fotos aus anderem Termin einfügen" ein');
    }
  }

  async sendSelectedEventFilesClicked() {
    const files = keys(this.selectedEventFiles()).map(fileId => this.selectedEventFiles()[fileId]);
    const url = await this.socketService.createEventFileAlbum(this.eventId, files);
    const sendViaWhatsapp = await this.dialogService.showYesNo('Link kopieren oder direkt senden?', {yesText: 'Per WhatsApp senden', noText: 'Link kopieren'});
    if (sendViaWhatsapp) {
      const customer = this.formGet('customer').value;
      this.socketService.sendEventFileAlbumToContact(customer.id, url);
    } else {
      this.clipboard.copy(url);
    }
  }

  linkEventFileFromClipboard() {
    const dialog = this.dialogService.showComponentDialog(EventFilesClipboardComponent);
    dialog.componentInstance.eventId = this.eventId;

  }

  selectTattooEventFilesClicked() {
    this.selectedEventFiles.set({});
    const files = this.eventFiles().filter(f => f.subType.startsWith('tattoo'));
    files.forEach(f => this.selectEventFileClicked(f, true));
  }

  eventFileEditClicked(data: { slide: Slide; id: string }) {
    requestAnimationFrame(() => {
      const dialog = this.dialogService.showComponentFull(PhotoEditorComponent);
      dialog.componentInstance.fileId = data.id;
    });
  }

  test() {
    this.eventFileThumbReloadIndicator.set(UuidTools.generate());
  }

  showCustomerRefClicked(value: boolean) {
    if (!value) {
      this.formGet('customerRef').setValue(null);
    }
  }

  improveOtherArtistCheckStateChanged(value: boolean) {
    const newState = value ? 'checked' : 'do-check';
    if (this.formGet('improveOtherArtistCheckState').value !== newState) {
      this.formGet('improveOtherArtistCheckState').setValue(newState);
    }
  }

  async deleteSelectedFilesClicked() {
    if (await this.dialogService.showYesNo('Bilder wirklich löschen?')) {
      const files = keys(this.selectedEventFiles()).map(fileId => this.selectedEventFiles()[fileId]);
      await this.socketService.deleteDriveFileFromEvent(this.originalEvent().id, files.map(f => f.id));
    }
  }

  public showDebugClicked() {
    this.dialogService.showOk(JsonTools.stringifyFormat(this.originalEvent()));
  }

  public async bookToPayOnEventDateClicked(toBook: { text: string; value: number }) {
    // const toPayOnEventDate: { text: string; value: number }[] = this.formGet('toPayOnEventDate').value;
    // await this.walkInService.createCashIncomingAfterEventCreated(toBook.text, toBook.value);
  }


  private async checkToPayOnEventDate(event: NxtCalendarEvent) {

    const toPayOnEventDate = event.toPayOnEventDate.filter(toPay => !toPay.payed);
    if (toPayOnEventDate.length > 0 && !this.isCanceled && this.loginService.isReception() && !await this.getCanNotBookMessage()) {
      if (event.start.clearTime() < Date.now()) {
        for (const toPay of toPayOnEventDate) {
          const buttons = [{text: 'Jetzt buchen', value: true}];
          if (this.paymentsComponent.customerToPay() > 0) {
            buttons.push({text: 'Später', value: false});
          }
          const contact = await this.socketService.getContactById(event.customer);
          const text = 'Du musst noch ' + toPay.value.toMoneyString() + ' ' + toPay.text + '\nvon "' + contact.fullName + '" buchen';
          const doIt = await this.dialogService.showButtons(text, {buttons});
          if (doIt.value) {
            await this.walkInService.createCashIncomingAfterEventCreated(toPay.text, toPay.value, event.id);
          }
        }
      }
    }
  }

  async getCanNotBookMessage() {
    return this.socketService.getCanNotBookMessage(Date.now(), this.loginService.getStudio());
  }

  async refundToPayOnEventDatePayedClicked(toPay: NxtCalendarEventToPayOnEventDate) {
    const reason = await this.getCanNotBookMessage();
    if (reason) {
      this.dialogService.showOk(reason);
      return;
    }
    if (await this.dialogService.showYesNo(toPay.text + ' wirklich erstatten?')) {
      return this.socketService.refundToPayOnEventDatePayed(this.originalEvent().id, toPay);
    }
  }

  async showInvoiceClicked() {
    const invoiceNumber = this.form.get('invoiceNumber').value;
    if (invoiceNumber) {
      const invoice = await this.socketService.getArtistInvoice(invoiceNumber);
      this.dialogService.showPdf(invoice.invoiceSignedBase64 || invoice.invoiceBase64, 'Rechnungen_' + invoiceNumber + '.pdf');
    }
  }

  setConsentData(eventFile: NxtCalendarEventFile, type: 'hasAllCustomerSigns' | 'hasAllDates' | 'correctPageCount', value: boolean) {
    this.socketService.updateEventFileConsent(eventFile.id, type, value).then();
  }

  async customerInvoiceClicked() {
    if (this.loginService.isJulian()) {
      const deleteIt = await this.dialogService.showYesNo(this.originalEvent().customerInvoice.invoiceNumber, {noText: 'Anzeigen', yesText: 'Löschen'});
      if (deleteIt) {
        this.socketService.deleteCustomerInvoice(this.originalEvent().id);
      } else {
        const url = DriveTools.getDriveLink(this.originalEvent().customerInvoice.driveFileId);
        this.dialogService.showPdfSrc(url, this.originalEvent().customerInvoice.invoiceNumber + '.pdf');
      }
    }
  }

  async setCustomerRefId(customerRefId: string) {
    this.form?.get('customerRefId').setValue(customerRefId);
    this.customerRefText.set('');
    if (customerRefId) {
      if (customerRefId.startsWith('+')) {
        this.customerRefText.set(customerRefId);
      } else {
        const contact = await this.socketService.getContactWithEvents(customerRefId.toString());
        this.customerRefText.set(contact.fullName);
      }
    }
  }

  calcAutoPrice() {
    if (this.form.get('priceAuto').value) {
      if (this.form.get('workType').value === 'tooth-gem') {
        const bodyPuts = this.form.get('bodyPutsToothGem').getRawValue();
        const ids = bodyPuts.map(b => b.bodyPut);
        const price = BodyPutTools.calcToothGemBodyPutPrice(ids);
        this.form.get('priceFix').setValue(true);
        if (price) {
          this.form.get('priceEstimatedFrom').setValue(price);
          this.form.get('priceEstimatedTill').setValue(null);
          this.form.get('discountPromotion').setValue(null);
        }
      }
    }
  }
}

