import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {WorkSessionTools} from '../common-browser/helpers/work-session.tools';
import {NxtWorkSessionCashRegisterState} from '../common-interfaces/nxt.work-session';

@Pipe({
    name: 'cashRegisterState',
    standalone: true
})
export class CashRegisterStatePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: NxtWorkSessionCashRegisterState): string {
    return WorkSessionTools.getCashRegisterStateText(value);
  }
}
