import {WhatsAppTools} from './whats-app.tools';

declare const window: any;
export class StudioRegionBrowserTools {

    public static getLinkForEvent(eventId: string, options?: { asLink?: boolean, text?: string }) {
        let link = `${window.origin}/#/e/${eventId}`;
        if (options?.asLink || options?.text) {
            const text = options?.text ?? link;
            link = '<a href="' + link + '" target="_blank">' + text + '</a>';
        }
        return link;
    }

    public static getLinkForChat(mobile: string) {
        return `${window.origin}/#/chat/${WhatsAppTools.parseWhatsAppId(mobile, false)}`;
    }
}
