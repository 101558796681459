import {Pipe, PipeTransform} from '@angular/core';
import {MathTools} from '../common-browser/helpers/math.tools';

@Pipe({
  name: 'nxtRound',
  standalone: true
})
export class NxtRoundPipe implements PipeTransform {

  constructor() {
  }

  transform(value: number, decimals: number): any {
    return MathTools.round(value, decimals);
  }
}
