import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {InputComponent} from '../../form-controls/input/input.component';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {SocketService} from '../../../services/socket/socket.service';
import {DialogService} from '../../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtMoneyTransactionRegion} from '../../../common-interfaces/money-account.interface';
import {WithoutNxtDbFields} from '../../../common-interfaces/nxt.db-fields.interface';
import {FormGroup} from '@angular/forms';
import {NxtTypedFormControl} from '../../../nxt-form/nxt.typed-form-control';
import {FormTools} from '../../../services/form.tools';
import {clone} from '../../../common-browser/helpers/object.tools';
import {ValidatorTools} from '../../../helpers/validator.tools';

@Component({
    selector: 'nxt-money-transaction-category-edit',
    templateUrl: './money-transaction-region-edit.component.html',
    styleUrls: ['./money-transaction-region-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    NxtButtonComponent
  ]
})

export class MoneyTransactionRegionEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  private dialogRef = inject(MatDialogRef<MoneyTransactionRegionEditComponent>, {optional: true});


  originalTransactionRegion: WithoutNxtDbFields<NxtMoneyTransactionRegion>;

  form = new FormGroup({
    name: new NxtTypedFormControl('', [ValidatorTools.requiredAndNotNaN], 'Name'),
    description: new NxtTypedFormControl('', [], 'Beschreibung'),
  });

  constructor() {
    super();
  }


  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  async new() {
    this.originalTransactionRegion = {name: '', description: ''};
    this.setForm(this.originalTransactionRegion);
  }

  async load(moneyTransactionRegion: NxtMoneyTransactionRegion) {
    this.originalTransactionRegion = moneyTransactionRegion;
    this.setForm(this.originalTransactionRegion);
  }

  private setForm(moneyAccount: WithoutNxtDbFields<NxtMoneyTransactionRegion>) {
    FormTools.setForm(this.form, moneyAccount);
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  async saveClicked() {
    if (FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      const data = this.getFromForm();
      await this.socketService.upsertMoneyTransactionRegion(data);
      this.dialogRef.close();
    }
  }

  private getFromForm() {
    const data: WithoutNxtDbFields<NxtMoneyTransactionRegion> = {...clone(this.originalTransactionRegion), ...this.form.getRawValue() as any};
    return data;
  }

  deleteClicked() {
    this.socketService.deleteMoneyTransactionRegion(this.originalTransactionRegion.id);
    this.dialogRef.close();
  }
}
