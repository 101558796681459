<div style="width:100%; height: 100%; display: flex; flex-direction: column">
  <div style="display: flex; flex:10%; padding: 5px; flex-direction: column; justify-content: center; align-items: center">
    <div>
      <div>KLEBEBAND</div>
    </div>
  </div>
  <div style="flex:90%; border: 1px solid red; display: flex; flex-direction: row; align-items: center; justify-content: center">
    <button style="flex: 1; height: 90%">-</button>

    <div style="flex: 1; height: 90%; font-size: x-large">500</div>
    <button style="flex: 1; height: 90%">+</button>
  </div>
</div>

