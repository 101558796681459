import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {NxtTypedFormControl} from '../../../nxt-form/nxt.typed-form-control';
import {NxtEmployee, NxtEmployeeEvent, NxtEmployeeEventType} from '../../../common-interfaces/nxt.employee.interface';
import {CacheService} from '../../../services/cache/cache.service';
import {AutocompleteComponent} from '../../form-controls/autocomplete/autocomplete.component';
import {AsyncPipe} from '@angular/common';
import {TimePickerComponent} from '../../form-controls/time-picker/time-picker.component';
import {FormTools} from '../../../services/form.tools';
import {EmployeeTools} from '../../../common-browser/helpers/employee.tools';
import {WithoutNxtDbFields} from '../../../common-interfaces/nxt.db-fields.interface';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {SocketService} from '../../../services/socket/socket.service';
import {DialogRef} from '@angular/cdk/dialog';
import {DialogService} from '../../../services/dialog.service';
import {LoginService} from '../../../services/login.service';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {PermissionDirective} from '../../../directives/permission.directive';

@Component({
  selector: 'nxt-employee-event-timetrack-edit',
  templateUrl: './employee-event-timetrack-edit.component.html',
  styleUrls: ['./employee-event-timetrack-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AutocompleteComponent,
    AsyncPipe,
    TimePickerComponent,
    NxtButtonComponent,
    ReactiveFormsModule,
    PermissionDirective,

  ],
})

export class EmployeeEventTimetrackEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor(
    @Optional() public dialogRef: DialogRef<EmployeeEventTimetrackEditComponent>,
  ) {
    super();
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  cacheService = inject(CacheService);
  socketService = inject(SocketService);
  dialogService = inject(DialogService);
  loginService = inject(LoginService);


  form = new FormGroup({
    id: new NxtTypedFormControl('', [], 'ID'),
    employee: new NxtTypedFormControl<NxtEmployee>(null, [ValidatorTools.requiredAndNotNaN], 'Mitarbeiter'),
    dateString: new NxtTypedFormControl<string>(null, [ValidatorTools.requiredAndNotNaN], 'Datum'),
    startTime: new NxtTypedFormControl<string>(null, [ValidatorTools.requiredAndNotNaN], 'Start'),
    endTime: new NxtTypedFormControl<string>(null, [], 'Ende'),
    type: new NxtTypedFormControl<NxtEmployeeEventType>('timeTrack', [ValidatorTools.requiredAndNotNaN], 'Typ'),
  });
  originalEmployeeEvent: WithoutNxtDbFields<NxtEmployeeEvent>;


  displayWithEmployee = (data: NxtEmployee) => {
    if (!data) {
      return '';
    }
    return data.abbreviation + ' - ' + data.fullName;
  };

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  new(dateString: string, startTimeHour: string) {
    this.originalEmployeeEvent = EmployeeTools.getEmptyEmployeeEvent();
    this.originalEmployeeEvent.dateString = dateString;
    this.originalEmployeeEvent.start = (dateString + ' ' + startTimeHour).dateParse();
    // this.form.controls.endTime.setErrors(null);
    // this.form.controls.endTime.setValidators([ValidatorTools.requiredAndNotNaN]);
    this.setForm();
  }

  async load(employeeEventId: string) {
    this.originalEmployeeEvent = await this.socketService.getEmployeeEvent(employeeEventId);
    if (this.originalEmployeeEvent) {
      // this.form.controls.endTime.setErrors(null);
      // this.form.controls.endTime.setValidators([]);
      this.setForm();
    }
  }

  private async setForm() {
    await this.cacheService.waitForEmployees();
    FormTools.setForm(this.form, {
      employee: this.cacheService.employees.value.find(e => e.id === this.originalEmployeeEvent.employeeId),
      dateString: this.originalEmployeeEvent.dateString,
      startTime: this.originalEmployeeEvent.start > 0 ? this.originalEmployeeEvent.start.dateFormat('HH:mm') : null,
      endTime: this.originalEmployeeEvent.end > 0 ? this.originalEmployeeEvent.end.dateFormat('HH:mm') : null,
      type: this.originalEmployeeEvent.type,
      studio: this.originalEmployeeEvent.studio,
      workplaces: this.originalEmployeeEvent.workplaces,
    });
  }

  private getFromForm(): WithoutNxtDbFields<NxtEmployeeEvent> {
    let end = null;
    if (this.form.controls.endTime.value) {
      end = DateTools.parse(this.originalEmployeeEvent.dateString + ' ' + this.form.controls.endTime.value + ':00');
    }
    return {
      ...this.originalEmployeeEvent,
      employeeId: this.form.controls.employee.value.id,
      start: DateTools.parse(this.originalEmployeeEvent.dateString + ' ' + this.form.controls.startTime.value + ':00'),
      end,
      dateString: this.form.controls.dateString.value,
      manualTimeTrack: !this.originalEmployeeEvent.id,
      type: 'timeTrack',
    };
  }

  async saveClicked() {
    if (FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      const employeeEvent = this.getFromForm();
      const info = await this.dialogService.showInput('Info zu deiner Änderung', {stringCanEmpty: this.loginService.isJulian()});
      if (info || this.loginService.isJulian()) {
        if (!employeeEvent.infos) {
          employeeEvent.infos = [];
        }
        if (info) {
          employeeEvent.infos.push(Date.now().dateFormat('dd.MM.yyyy HH:mm:ss') + ' ' + this.loginService.getUsername() + ': ' + info);
        }
        await this.socketService.upsertEmployeeEvent(employeeEvent);
        this.dialogRef.close();
      }
    }
  }

  setTime(start: string, end: string) {
    this.form.controls.startTime.setValue(start);
    this.form.controls.endTime.setValue(end);
  }

  closeClicked() {
    this.dialogRef.close();
  }

  async deleteClicked() {
    if (await this.dialogService.showYesNo('Löschen?')) {
      this.socketService.deleteEmployeeEvent(this.originalEmployeeEvent.id);
      this.dialogRef.close();
    }
  }
}
