<div #page fxLayout="column" class="nxt-page"
     style="font-size: 1.2em; position:fixed; height: auto; width: auto; bottom:0; left: 0; top: 0; right: 0; border: 0px solid red;">
  <div fxFlex="35%" nxtCard fxLayout="column" style="padding-top:0.5em;">
    <nxt-input
      fxFlex
      fxLayout="column"
      fxLayoutAlign="center"
      placeholder="Vorname"
      requiredError="verrate uns bitte deinen Vornamen"
      [nxtFormControl]="form.get('givenName')"
    >
    </nxt-input>
    <nxt-input
      fxFlex
      fxLayout="column"
      fxLayoutAlign="center"
      placeholder="Nachname"
      requiredError="bitte gebe deinen Nachname ein"
      [nxtFormControl]="form.get('familyName')"
    >
    </nxt-input>
  </div>

  <div nxtCardDivider></div>

  <div fxFlex="20%" nxtCard fxLayout="column" style="padding-top:0.5em;">
    <!-- HANDY -->
    <nxt-input
      fxFlex
      fxLayout="column"
      fxLayoutAlign="center"
      placeholder="Handy"
      requiredError="benötigen wir für eventuelle Rückfragen"
      [nxtFormControl]="form.get('mobile')"></nxt-input>
  </div>
  <div nxtCardDivider></div>
  <div fxFlex="35%" nxtCard fxLayout="column" style="padding-top:0.5em;">


    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="center center">
      <nxt-input
        fxFlex="30%"
        [ngStyle]="{display: showPostalCode ? '' : 'none'}"
        [nxtFormControl]="form.get('postalCode')"
        placeholder="PLZ"
      ></nxt-input>

      <!-- STADT -->
      <nxt-autocomplete fxFlex
                        fxLayout="column"
                        fxLayoutAlign="center"
                        [options]="cities"
                        placeholder="Stadt"
                        [nxtFormControl]="form.get('city')"
                        nxtMatOptionContainerClass="new-contact-option-container"
                        [maxOptionsToShow]="5"
                        (optionClick)="clickInput('streetAddress')"
                        [minLength]="1"
                        requiredError="Bitte gebe die Stadt in der du wohnst ein"
      ></nxt-autocomplete>
    </div>

    <!-- STRASSE & HAUSNUMMER -->


    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="center center">
      <nxt-autocomplete
          #street
          fxFlex="70%"
          [filterService]="filterService"
          placeholder="Strasse"
          [nxtFormControl]="form.get('streetAddress')"
          [displayInOptionWith]="streetDisplayInOptionWith"
          [displayWith]="streetDisplayWith"
          (optionClick)="clickInput('houseNumber')"
          [minLength]="0"
      ></nxt-autocomplete>

      <nxt-input
          #houseNumber
          fxFlex="30%"
          placeholder="HausNr."
          [nxtFormControl]="form.get('houseNumber')">
      </nxt-input>
    </div>
  </div>
  <div fxFlex="10%" fxLayout="column" style="padding-top:0.5em;">
    <nxt-button (click)="finished()" style="width:100%; height:100%">fertig</nxt-button>
  </div>
</div>


