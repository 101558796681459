<div class="flex flex-col">
  <div>Artist-Galerie Link verschicken</div>
  <nxt-autocomplete
    [options]="artists"
    placeholder="Artist auswählen"
    [filterFields]="['name']"
    [(value)]="currentArtist"
    [displayWith]="DisplayWithTools.displayWithName"
    [displayInOptionWith]="DisplayWithTools.displayWithName"
    optionTrackBy="name"
  >
  </nxt-autocomplete>
  <div>
    <nxt-button (click)="closeClicked()">Abbrechen</nxt-button>
    <nxt-button *ngIf="currentArtist?.name" (click)="sendClicked()">Senden</nxt-button>
  </div>
  <!--<div class="artist-item" *ngFor="let artist of artists" (click)="artistClicked(artist)">{{artist.name}}</div>-->
</div>

