import {inject, Injectable, Injector, runInInjectionContext} from '@angular/core';
import {UuidTools} from '../common-browser/helpers/uuid.tools';
import {FirestoreService} from './firestore.service';
import {JsonTools} from '../common-browser/helpers/json.tools';
import {SocketService} from './socket/socket.service';
import {AngularFirestore} from '@angular/fire/compat/firestore';

export type CacheListName = 'tattoo-motives' | 'depot-due-date-reason';

@Injectable({
  providedIn: 'root'
})
export class CacheListService {
  constructor(
    private firestoreService: FirestoreService,
    private injector: Injector,
    private socketService: SocketService) {
  }

  private cacheLists: { [listName: string]: { id: string, text: string, used: number, lastUsed: number }[] } = {};

  async addCacheListItem(listName: CacheListName, text: string) {
    const id = UuidTools.generate();
    this.cacheLists[listName].push({id, text, used: 1, lastUsed: Date.now()});
    this.saveCacheList(listName);
  }

  async removeCacheListItem(listName: CacheListName, text: string) {
    const index = this.cacheLists[listName].findIndex(i => i.text === text);
    this.cacheLists[listName].splice(index, 1);
    this.saveCacheList(listName);
  }

  async incrementCacheList(listName: CacheListName, item: { id: string, text: string, used: number, lastUsed: number }) {
    item.used++;
    item.lastUsed = Date.now();
    this.cacheLists[listName] = this.cacheLists[listName].filter(i => i.id !== item.id);
    this.cacheLists[listName].push(item);
    this.saveCacheList(listName);
  }


  async getCacheList(listName: CacheListName): Promise<{ id: string, text: string, used: number, lastUsed: number }[]> {
    const docData: any = await this.socketService.getCacheList(listName);
    if (docData) {
      this.cacheLists[listName] = docData;
    }
    if (!this.cacheLists[listName]) {
      this.cacheLists[listName] = [];
    }
    return this.cacheLists[listName];
  }


  private async saveCacheList(listName: CacheListName) {
    if (!this.cacheLists[listName]) {
      this.cacheLists[listName] = [];
    }
    await runInInjectionContext(this.injector, async () => {
      try {
        await this.firestoreService.doc('cache-lists/' + listName).update({data: JsonTools.stringify(this.cacheLists[listName])});
      } catch (err) {
        await this.firestoreService.doc('cache-lists/' + listName).set({data: JsonTools.stringify(this.cacheLists[listName])});
      }
    });
  }

  displayCacheListItemFn(motive: any): string {
    const result = motive?.text ? motive.text : motive;
    return result;
  }

  displayCacheListItemInOptionFn(motive: any, highlight: (string) => string): string {
    const result = motive?.text ? motive.text : motive;
    return highlight(result);
  }
}
