import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {NxtButtonComponent} from '../../button/nxt-button.component';
import {AutocompleteComponent} from '../../../components/form-controls/autocomplete/autocomplete.component';
import {MatDialogRef} from '@angular/material/dialog';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {MatIcon} from '@angular/material/icon';
import {NgIf} from '@angular/common';

export interface DialogData {
  message: string;
}

/**
 * @title Dialog Overview
 */
@Component({
    selector: 'nxt-dialog-select',
    templateUrl: './dialog-select.component.html',
    styleUrls: ['./dialog-select.component.scss'],
    imports: [
        NgIf,
        MatIcon,
        AutocompleteComponent,
        NxtButtonComponent,
        SafeHtmlPipe,
    ]
})
export class DialogSelectComponent implements AfterViewInit {

  @ViewChild('header') set headerDiv(value: ElementRef) {
    if (value) {
      requestAnimationFrame(() => value.nativeElement.focus());
    }
  }

  @ViewChild('okButton') set okButton(value: NxtButtonComponent) {
    // requestAnimationFrame(() => value.;
  }

  @ViewChild(AutocompleteComponent) autocompleteComponent: AutocompleteComponent;

  message: string;
  icon: string;
  titleText: string;
  value: any;
  preselectValue: any;

  constructor(
    public dialogRef: MatDialogRef<DialogSelectComponent>,
  ) {
  }

  data: any;

  setData(data: any) {
    this.data = data;
    this.message = typeof data?.message?.replace === 'function' ? data.message.replace(/\n/g, '<br/>') : '';
    this.titleText = data.title;
    data.okButtonText = data.okButtonText?.replace(/\n/g, '<br/>');
    this.preselectValue = data.items.find(o => o.value === data.value);
    this.value = this.preselectValue?.value;
    if (data.icon) {
      this.icon = data.icon;
    }
  }

  ngAfterViewInit(): void {
    if (this.data.focusInputOnInit) {
      if (this.autocompleteComponent.inputControlElemRef) {
        setTimeout(() => this.autocompleteComponent.inputControlElemRef.nativeElement.click(), 200);
      }
    }
    setTimeout(() => {
      if (this.value) {
        // (this.headerDiv.nativeElement as HTMLDivElement).click();
      }
    }, 1000);
  }

  displayInOptionFn(item: any, highlight: (string) => string): string | undefined {
    return highlight(item?.text);
  }

  displayFn(item: any): string | undefined {
    return item?.text;
  }

  close() {
    this.dialogRef.close(this.value);
  }

  optionClicked(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value.value;
    if (this.data.closeOnOptionSelected) {
      this.close();
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  valueChanged(value: any) {
    if (this.data.allowCustomString) {
      this.value = value;
    }
  }
}
