import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {DatePicker2Component} from '../form-controls/date-picker-2/date-picker-2.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {SocketService} from '../../services/socket/socket.service';
import {IntervalTools} from '../../common-browser/helpers/interval.tools';
import {NxtPageComponent} from '../nxt-page/nxt-page.component';
import {NxtPageHeaderComponent} from '../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageContentComponent} from '../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtPageHeaderTitleComponent} from '../nxt-page/nxt-page-header/nxt-page-header-title.component';

@Component({
  selector: 'nxt-drive-migration',
  templateUrl: './drive-migration.component.html',
  styleUrls: ['./drive-migration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePicker2Component, NxtButtonComponent, NxtPageComponent, NxtPageHeaderComponent, NxtPageHeaderTitleComponent, NxtPageContentComponent, NxtPageFooterComponent],
  standalone: true,
})
export class DriveMigrationComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  logs = signal<string[]>([]);
  private interval: number;

  constructor() {
    super();
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  dialogRef = inject(MatDialogRef, {optional: true});
  private socketService = inject(SocketService);

  from = signal<string>('');
  till = signal<string>('');

  ngOnInit() {
    this.interval = window.setInterval(async () => {
      this.logs.set(await this.socketService.getDriveMigrationLogs());
    }, 1000);
  }

  nxtOnDestroy() {
    IntervalTools.clear(this.interval);
  }

  startClicked() {
    this.socketService.startDriveMigration(this.from(), this.till());
  }
}
