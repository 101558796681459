import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, model, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {ColorTools} from '../../common-browser/helpers/color.tools';
import {MaterialColors} from '../../common-browser/helpers/material-colors';
import {keys} from 'lodash';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'nxt-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: []
})

export class ColorPickerComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor() {
    super();
  }

  /*** Inputs ***/
  colorInfos = model<{ [color: string]: string }>({}); // = {};

  /*** Outputs ***/

  /*** Signals ***/
  color = signal('#ab47bc');
  title = signal('');

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  dialogRef = inject(MatDialogRef<ColorPickerComponent>);

  colors = keys(MaterialColors.colors);
  colorSteps = ['200', '300', '400', '500', '600', '700', '800', '900'];


  currentIndex = computed(() => {
    for (const color of keys(MaterialColors.colors)) {
      for (const colorStep of keys(MaterialColors.colors[color])) {
        if (MaterialColors.colors[color][colorStep] === this.color()) {
          return color + colorStep;
        }
      }
    }
    return '';
  });

  protected readonly ColorTools = ColorTools;
  protected readonly MaterialColors = MaterialColors;



  setCurrentColor(color: string, colorStep: string) {
    this.dialogRef.close(MaterialColors.colors[color][colorStep]);
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }
}
