import {Injectable} from '@angular/core';
import {LoginService} from './login.service';
import {CheckListComponent} from '../components/check-list/check-list.component';
import {DialogService} from './dialog.service';
import {TelegramService} from './telegram.service';
import {ConfigService} from './config.service';
import {firstValueFrom} from 'rxjs';
import {SocketService} from './socket/socket.service';


export enum CheckListEvent {
  AfterDayFinishReportPdfPrinted,
  ArtistPayoutConfirmed
}

@Injectable({
  providedIn: 'root',
})
export class CheckListService {


  constructor(
    private loginService: LoginService,
    private dialogService: DialogService,
    private telegramService: TelegramService,
    private socketService: SocketService,
    private configService: ConfigService,
  ) {
  }


  async startReception() {
    if (this.configService.config.value.studioRegion === 'AC' || this.configService.config.value.studioRegion === 'STAGING') {
      const checkItems = [
        {name: 'FOTO-HANDY Galerie öffnen ->SYNC!', checked: false},
        {name: 'TV im Hauptraum aus', checked: false},
        {name: 'Markise eingefahren', checked: false},
        {name: 'Terrassentür abgeschlossen', checked: false},
        {name: 'Einwilligungserklärungen alle gescannt', checked: false},
        {name: 'Alle Tattoo-Lichter aus', checked: false},
        {name: 'Handy ans Ladegerät', checked: false},
        {name: 'Terrassentüre-Backoffice zu', checked: false},
        {name: 'Abrechnung abgeheftet', checked: false},
        {name: 'Ich bringe nun direkt das Geld zur Bank', checked: false},
      ];
      const dialog = this.dialogService.showComponentDialog(CheckListComponent, {
          title: 'Villa Theke Feierabend',
          allCheckedToFinish: true,
          checkItems,
        }, {
          minWidth: '40vw',
        },
      );
      await dialog.afterClosed().toPromise();
      this.socketService.dayFinishChecklistDone(checkItems);
      this.telegramService.sendTelegramDayFinish('hat die Feierabend-Checkliste bestätigt');
    } else if (this.configService.config.value.isFranchise) {
      const checkItems = [
        {name: 'Post', checked: false},
        {name: 'Kautionen checken', checked: false},
        {name: 'Infos weitergeben', checked: false},
      ];
      const dialog = this.dialogService.showComponentDialog(CheckListComponent, {
          title: 'FFM Theke Feierabend',
          allCheckedToFinish: true,
          checkItems,
        }, {
          minWidth: '40vw',
        },
      );
      await firstValueFrom(dialog.afterClosed());
      this.socketService.dayFinishChecklistDone(checkItems);
      this.telegramService.sendTelegramDayFinish('hat die Feierabend-Checkliste bestätigt');
    } else if (this.configService.config.value.studioRegion === 'MA') {
      const checkItems = [
        {name: 'alle Ringlichter aus', checked: false},
        {name: 'alle Fenster geschlossen', checked: false},
        {name: 'alle Fensterläden geschlossen', checked: false},
        {name: 'alle Balkontüren geschlossen', checked: false},
        {name: 'Eingangstüre verankert', checked: false},
        {name: 'private Balkontüre verankert', checked: false},
        {name: 'Stencil Drucker', checked: false},
        {name: 'Markise eingefahren', checked: false},
        {name: 'Hauptschalter (Alumbrado SALA 1) unten', checked: false},
      ];
      const dialog = this.dialogService.showComponentDialog(CheckListComponent, {
          title: 'Malle Theke Feierabend',
          allCheckedToFinish: true,
          checkItems,
        }, {
          minWidth: '40vw',
        },
      );
      await firstValueFrom(dialog.afterClosed());
      this.socketService.dayFinishChecklistDone(checkItems);
      this.telegramService.sendTelegramDayFinish('hat die Feierabend-Checkliste bestätigt');
    } else if (this.configService.config.value.studioRegion === 'DU') {
      const checkItems = [
        {name: 'Alle Dokumente sind gescannt', checked: false},
        {name: 'Alle Lichter aus', checked: false},
        {name: 'Stencildrucker aus', checked: false},
        {name: 'Geld im Tresor', checked: false},
        {name: 'Kassenbericht unterschrieben und abgeheftet', checked: false},
        {name: 'Kundenstopper, Bänke & Aschenbecher drinnen', checked: false},
        {name: 'Stuff Schublade aufgefüllt', checked: false},
        {name: 'Handys am Ladekabel', checked: false},
        {name: 'Flur Tür abgeschlossen', checked: false},
      ];
      const dialog = this.dialogService.showComponentDialog(CheckListComponent, {
          title: 'Düsseldorf Theke Feierabend',
          allCheckedToFinish: true,
          checkItems,
        }, {
          minWidth: '40vw',
        },
      );
      await firstValueFrom(dialog.afterClosed());
      this.socketService.dayFinishChecklistDone(checkItems);
      this.telegramService.sendTelegramDayFinish('hat die Feierabend-Checkliste bestätigt');
    } else if (this.configService.config.value.studioRegion === 'DO') {
      const checkItems = [
        {name: 'Fernseher aus', checked: false},
        {name: 'Türe vorne abschließen', checked: false},
        {name: 'Riegel vor Türe hinten', checked: false},
        {name: 'Piercingraum Tür kontrollieren', checked: false},
        {name: 'Piercings ins Lager', checked: false},
        {name: 'Sicherungen raus', checked: false},
        {name: 'Kassenbericht unterschreiben und abheften', checked: false},
        {name: 'Aschenbecher rein stellen', checked: false},
        {name: 'Handys und Piercing Tablet ans Ladekabel in Tresor', checked: false},
      ];
      const dialog = this.dialogService.showComponentDialog(CheckListComponent, {
          title: 'Düsseldorf Theke Feierabend',
          allCheckedToFinish: true,
          checkItems,
        }, {
          minWidth: '40vw',
        },
      );
      await firstValueFrom(dialog.afterClosed());
      this.socketService.dayFinishChecklistDone(checkItems);
      this.telegramService.sendTelegramDayFinish('hat die Feierabend-Checkliste bestätigt');
    }
  }
}
