import {DateTools} from './date.tools';
import {DecimalTools} from './decimal.tools';
import {NxtPaymentPossibilityRecord} from '../../common-interfaces/nxt.payment-possibility-record.interface';
import {JsonTools} from './json.tools';
import {NxtBankTransaction, NxtBankTransactionStatus, NxtBankTransactionType} from '../../common-interfaces/bank/bank-transaction.interface';
import {MathTools} from './math.tools';
import {keys} from './object.tools';
import {IconTools} from './icon.tools';


export class BankTransactionTools {


  private static status: NxtBankTransactionStatus[] = [];
  private static _types: NxtBankTransactionType[] = [];
  private static statusLoaded = false;
  private static typesLoaded = false;


  static get types(): string[] {
    if (!BankTransactionTools.typesLoaded) {
      BankTransactionTools.loadTypes();
    }
    return BankTransactionTools._types;
  }

  public static getTypeText(aiType: NxtBankTransactionType) {
    switch (aiType) {
      case 'internal-transfer':
        return 'Transfer';
      case 'loan':
        return 'Darlehen';
      case 'salary':
        return 'Gehalt';
      case 'tax':
        return 'Steuer';
      case 'unknown':
        return 'Unbekannt';
      case 'artist-customer':
        return 'Artist-Kunde';
      case 'customer-ar':
        return 'Debitor';
      case 'rent':
        return 'Miete';
      case 'supplier-ap':
        return 'Kreditor';
      case 'health-insurance':
        return 'Krankenkasse';
      case 'paypal':
        return 'PayPal';
      case 'paypal-credit':
        return 'PayPal-Kredit';
      case 'qonto-credit':
        return 'Qonto-Kredit';
      case 'private':
        return 'Privat';
      case 'revenue':
        return 'Umsatz';
    }
    return aiType;
  }


  static getNextStatus(status: NxtBankTransactionStatus): NxtBankTransactionStatus {
    if (status === NxtBankTransactionStatus._90_ok) {
      return NxtBankTransactionStatus._90_ok;
    }
    if (!BankTransactionTools.statusLoaded) {
      BankTransactionTools.loadStatus();
    }
    const index = BankTransactionTools.status.indexOf(status);
    return BankTransactionTools.status[index + 1];
  }

  private static loadStatus() {
    for (const status of keys(NxtBankTransactionStatus)) {
      BankTransactionTools.status.push(NxtBankTransactionStatus[status]);
    }
    BankTransactionTools.statusLoaded = true;
  }


  static getNewTransactionText(bankTransaction: NxtPaymentPossibilityRecord): string {
    let text = 'BANK';
    text += '\n' + DateTools.format(bankTransaction.createdAt, 'dd.MM.yyyy HH:mm');
    text += '\n' + DecimalTools.toMoneyString(bankTransaction.value);
    text += '\n' + bankTransaction.name;
    return text;
  }

  static getSenderIbanFromRaw(rawObject: any) {
    let iban = rawObject?.NtryDtls?.TxDtls?.RltdPties?.DbtrAcct?.Id?.IBAN;
    if (iban) {
      return iban;
    }
    iban = rawObject?.income?.counterparty_account_number;
    if (iban) {
      return iban;
    }
    throw Error('getSenderIbanFromRaw fehlgeschlagen, Absender-IBAN nicht gefunden\n' + JsonTools.stringify(rawObject));
  }

  static getBookDay(rawObject: any) {
    let date = rawObject?.BookgDt?.Dt;
    if (date) {
      return DateTools.parse(date, 'yyyy-MM-dd');
    }
    date = rawObject.settled_at?.dateParse()?.dateFormatDate();
    if (date) {
      return date;
    }
    debugger;
    throw Error('getBookDay fehlgeschlagen, BookgDt nicht gefunden\n' + JsonTools.stringify(rawObject));
  }

  static getSenderText(rawObject: any) {
    const text = rawObject?.NtryDtls?.TxDtls?.RmtInf?.Ustrd;
    if (text) {
      return text;
    }
    throw Error('getSenderText fehlgeschlagen, Absender-Text nicht gefunden\n' + JsonTools.stringify(rawObject));
  }

  static getSenderName(rawObject: any) {
    let name = rawObject?.NtryDtls?.TxDtls?.RltdPties?.Dbtr?.Nm;
    if (name) {
      return name;
    }
    name = rawObject?.label;
    if (name) {
      return name;
    }
    throw Error('getSenderName fehlgeschlagen, Absender-Name nicht gefunden\n' + JsonTools.stringify(rawObject));
  }

  public static calcAssignValue(transaction: NxtBankTransaction) {
    let assignedValue = 0;
    assignedValue += (transaction.assignedTransactions.reduce((sum, t) => sum + Math.abs(t.value), 0)).abs();
    assignedValue += transaction.assignedDocuments.reduce((sum, d) => sum + d.value, 0).abs();
    transaction.assignValue = MathTools.roundMoney(transaction.value.abs() - assignedValue);
    if (transaction.assignValue < 0.03) {
      transaction.assignValue = 0;
    }
  }

  public static getFirstStatus() {
    return NxtBankTransactionStatus._01_nxtAi;
  }

  private static loadTypes() {
    for (const type of keys(NxtBankTransactionType)) {
      BankTransactionTools._types.push(NxtBankTransactionType[type]);
    }
    BankTransactionTools.typesLoaded = true;
  }

  public static getStatusText(bankTransactionStatus: NxtBankTransactionStatus) {
    switch (bankTransactionStatus) {
      case NxtBankTransactionStatus._01_nxtAi:
        return 'NXT AI';
      case NxtBankTransactionStatus._03_delayed:
        return 'Später';
      case NxtBankTransactionStatus._07_documentMatch:
        return 'Dokument-Zuordnung';
      case NxtBankTransactionStatus._05_transferMatch:
        return 'Transaktion-Zuordnung';
      case NxtBankTransactionStatus._99_error:
        return 'Fehler';
      case NxtBankTransactionStatus._90_ok:
        return 'OK';
    }
  }

  static getAssignText(transaction: NxtBankTransaction) {
    if (transaction.nxtAi) {
      if (!transaction.nxtAi.needInvoice && !transaction.nxtAi?.needTransaction) {
        if (transaction.nxtManual?.missingInvoice) {
          return 'nicht auffindbar';
        }
        return 'nicht nötig';
      }
      if (transaction.nxtAi?.needTransaction) {
        if (transaction.assignValue === 0) {
          return 'OK';
        } else {
          return transaction.assignValue.toMoneyString() + ' offen';
        }
      }
      if (transaction.nxtAi?.needInvoice) {
        let text = '';
        if (transaction.assignedDocuments.length > 0) {
          text += transaction.assignedDocuments.length + ' × ' + IconTools.Material.Document;
        }
        if (transaction.assignValue > 0) {
          text += ' ' + transaction.assignValue.toMoneyString() + ' offen';
        }
        return text;
      }
      return 'Text fehlt';
    }
    return '';
  }
}
