import {Component, inject, OnInit, Optional} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtArticle} from '../../common-interfaces/nxt.article.interface';
import {DialogService} from '../../services/dialog.service';
import {ArticleEditComponent} from './article-edit/article-edit.component';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtPageHeaderComponent} from '../../components/nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageContentComponent} from '../../components/nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../../components/nxt-page/nxt-page-footer/nxt-page-footer.component';
import {NxtPageComponent} from '../../components/nxt-page/nxt-page.component';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'nxt-articles',
    templateUrl: './articles.component.html',
    styleUrls: ['./articles.component.scss'],
  imports: [FlexModule, NxtDatagridComponent, NxtButtonComponent, NxtPageHeaderComponent, NxtPageContentComponent, NxtPageFooterComponent, NxtPageComponent]
})

export class ArticlesComponent implements OnInit {

  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);

  articles: NxtArticle[];

  columnDefs: NxtColDef[] = [
    {headerName: 'id', field: 'id'},
    {headerName: 'articleGroup', field: 'articleGroup'},
    {headerName: 'articleId', field: 'articleId'},
    {headerName: 'vat', field: 'vat'},
    {headerName: 'description', field: 'description'},
    {headerName: 'is_', field: 'is_'},
    {headerName: 'postingAccountNo', field: 'postingAccountNo'},
    {headerName: 'disabled', field: 'disabled'},
    {headerName: 'proposalValue', field: 'proposalValue'},
    {headerName: 'direction', field: 'direction'},
    {headerName: 'invoiceRequired', field: 'invoiceRequired'},
  ];

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
  ) {
    this.load();
  }

  ngOnInit() {

  }

  async load() {
    this.articles = await this.socketService.getArticles();
    console.log(this.articles);
  }

  public newArticle() {
    const dialogRef = this.dialogService.showComponentDialog(ArticleEditComponent);
    dialogRef.componentInstance.newArticle();
  }
}
