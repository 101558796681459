<table class="w-full" *ngIf="data">
  <tr>
    <td>IBAN</td>
    <td>
      <nxt-input [(value)]="data.config.iban" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Kennung</td>
    <td>
      <nxt-input [(value)]="data.config.identifier" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Geheimschlüssel</td>
    <td>
      <nxt-input [(value)]="data.config.secret" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Import-Timer</td>
    <td>
      <nxt-input [isNumber]="true" [(value)]="data.timer" [noPadding]="true"></nxt-input>
    </td>
  </tr>
</table>
