import {Component, OnInit} from '@angular/core';
import {DialogOptionsButtons} from '../../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {NxtButtonComponent} from '../../button/nxt-button.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgIf, NgStyle} from '@angular/common';

@Component({
  selector: 'nxt-dialog-buttons',
  templateUrl: './dialog-buttons.component.html',
  styleUrls: ['./dialog-buttons.component.scss'],
  imports: [NgIf, NgStyle, ExtendedModule, FlexModule, NgClass, NxtButtonComponent, SafeHtmlPipe]
})
export class DialogButtonsComponent implements OnInit {

  message: string;
  buttonDirection = 'row';

  data: DialogOptionsButtons<any> & { message: string, returnValue: boolean };

  constructor(
    public dialogRef: MatDialogRef<DialogButtonsComponent>,
  ) {
  }

  setData(data: DialogOptionsButtons<any> & { message: string, returnValue: boolean }) {
    this.data = data;
    if (!data.fontSize) {
      data.fontSize = '100%';
    }
    if (data.buttonDirection) {
      this.buttonDirection = data.buttonDirection;
    }
  }

  buttonClick(button) {
    if (this.data.returnValue) {
      this.dialogRef.close(button.value);
    } else {
      this.dialogRef.close(button);
    }

  }

  ngOnInit(): void {

  }

  cancel() {
    this.dialogRef.close();
  }
}
