import {Component, OnInit, Optional, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {HistoryComponent, NxtHistoryPropDef} from '../history.component';
import {MatDialogRef} from '@angular/material/dialog';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-employee-history',
    templateUrl: './employee-history.component.html',
    styleUrls: ['./employee-history.component.scss'],
    imports: [FlexModule, NxtButtonIconComponent, HistoryComponent]
})

export class EmployeeHistoryComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  employeeId = signal('');

  constructor(
    @Optional() private dialogRef: MatDialogRef<EmployeeHistoryComponent>,
  ) {
    super();
    this.hiddenProps.push(...this.debugProps);
  }

  @ViewChild(HistoryComponent) historyComponent: HistoryComponent;

  public propDefs: NxtHistoryPropDef[] = [
    {
      field: 'weeklyBroadcast',
      name: 'Weekly Broadcast',
      type: NxtFieldType.Boolean,
    }, {
      field: 'taglines',
      name: 'Interne Infos',
      type: NxtFieldType.Text,
    }, {
      field: 'oldMobilesFormatted',
      name: 'Alte Nummern',
      valueGetter: (data) => {
        return data.map(d => d.m).join(', ');
      },
      type: NxtFieldType.Text,
    }, {
      field: 'birthday',
      name: 'Geburtstag',
      type: NxtFieldType.Date_germanDate,
    },

  ];
  public hiddenProps = [
    'testBroadcast', 'suffix'
  ];

  debugProps = [];
  public showTitle = false;
  public showDebug = false;


  setData(employeeId: string) {
    this.employeeId.set(employeeId);
  }

  ngOnInit() {
    this.propDefs = this.propDefs.sort(SortTools.sortString('name'));
  }

  nxtOnDestroy() {
  }

  public prepareRawData(data) {
    return data;
  }

  close() {
    this.dialogRef.close();
  }
}
