import {NxtCalendarEvent} from './nxt.calendar-event.interface';
import {NxtStudioCashReportPayment} from './nxt.payment.interface';
import {NxtAvailableArtistDayExtended, NxtAvailableArtistDayExtendedArtistFreeTimeWindow, NxtAvailableArtistWorkTimeWindow} from './nxt.available-artist-day.interface';
import {NxtMoneyStack} from './nxt.money-stack.interface';
import {NxtInvoiceData} from './nxt.invoice.interface';
import {NxtCashPayment} from './nxt.cash-payment.interface';
import {NxtArtistConfirm} from './nxt.artist-confirm.interface';
import {NxtDbFields, WithoutNxtDbFields} from './nxt.db-fields.interface';

export enum NxtWorkSessionCashRegisterState {
  _0_Error = 'error',
  /***
   * 1
   * Die Kasse ist geöffnet und kann benutzt werden.
   Nur in diesem Zustand können Ein- und Ausgaben getätigt werden.
   */
  _1_Open = 'open',

  /***
   * 2
   */
  // _2_CloseAllEvents = 'close-all-events',

  /***
   * 3
   */
  _3_CalcCashRegister1 = 'calc-cash-register-1',

  /***
   * 4
   * Die Kasse kann zum zweiten Mal gezählt werden.
   */
  _4_CalcCashRegister2 = 'calc-cash-register-2',

  /***
   * 4
   * Die Kasse kann zum zweiten Mal gezählt werden.
   */
  _5_CalcCashRegister3 = 'calc-cash-register-3',

  /***
   * 5
   * Es muss Geld gewechselt werden
   */
  _6_ChangeMoney = 'change-money',

  /***
   * 6
   * Es muss Geld gewechselt werden
   */
  _7_ArtistPayoutCanBook = 'artist-payout-can-book',

  /***
   * 7
   */
  _8_ArtistPayoutBooking = 'artist-payout-booking',

  /***
   * 8
   */
  _9_CreateInvoices = 'create-invoices',


  /***
   * 9 Die Rechnungen sind gedruckt, die Umschläge werden noch gepackt
   */
  _10_PreparingPayouts = 'preparing-payouts',

  _11_SetSafeMoneyDestination = 'set-safe-money-destination',

  _12_InvoicesPrinting = 'invoices-printing',

  _13_PayoutsRunning = 'payouts-running',

  _14_ClosingWorkSession = 'closing-work-session',

  _29_SideCashRegisterBookTransfers = 'side-cash-register-book-transfers',

  _30_SideCashRegisterPrintDayFinishReport = 'side-cash-register-print-day-finish-report',

  /***
   * Abrechnung ist abgeschlossen, somit ist die Kasse geschlossen
   */
  _99_Closed = 'closed',
  // _50_Loading = 'loading',


}

export interface NxtWorkSessionCashRegisterTransfer {
  transferValue: number;
  transferSafeValue: number;
  createdAt: number;
  accepted: boolean;
  transferCashPaymentId: string;
  transferSafeCashPaymentId: string;
  transferCashPayment?: NxtCashPayment;
  transferSafeCashPayment?: NxtCashPayment;
}

export interface NXtWorkSessionCashRegisterHandover {
  fromUsername: string;
  toUsername: string;
  date: number;
  moneyStack: NxtMoneyStack;
  value: number;
  shouldValue: number;
  missingValue: number;
}

export interface NxtWorkSessionCashRegister {
  openCashRegisterTransfersToReceived?: NxtWorkSessionCashRegisterTransfer[];
  transferToMainRegister?: NxtWorkSessionCashRegisterTransfer;
  checkListDone: boolean;
  missingValue?: number;
  bankCashPaymentId?: string;
  bankCashPayment?: NxtCashPayment;
  safeCashPaymentId?: string;
  safeCashPayment?: NxtCashPayment;
  errorMessage?: string;
  dateString: string;
  studio: string;
  startMoney: number | null;
  endMoney: number | null;
  endMoneyDateTime?: number | null;
  state: NxtWorkSessionCashRegisterState;
  isMainCashRegister: boolean;
  startBy: string;
  currentUser: string;
  startAt: number;
  closedBy: string;
  safeMoneyGet?: string;
  moneyStackBeforePayouts?: NxtMoneyStack;
  moneyStackEnd?: NxtMoneyStack;
  handovers: NXtWorkSessionCashRegisterHandover[];
  /**
   * Der Gesamte MoneyStack vor den Artistauszahlung, Tresor und Kasse
   */
  moneyStackInternal?: NxtMoneyStack;
  /**
   * Der Wert aus dem die Kassenzählung generiert wird ;)
   */
  shouldValueBeforePayoutsWithoutSafe?: number;
  payouts?: NxtWorkSessionPayout[];

  moneyPreTransferId: string;
}

export enum NxtWorkSessionState {
  Open = 'open',
  Closed = 'closed',
  Closing = 'closing',
  Dummy = 'dummy',
  CanStart = 'can-start'
}

export interface NxtWorkSession extends NxtDbFields {
  postponedEventIds: string[];
  additionalEventIds: string[];
  /**
   * nicht frühausbezahle Artists
   */
  invoices?: NxtInvoiceData[];
  dateString: string;
  startAt: number;
  startAtDateString: string;
  endAt: number;
  endAtDateString: string;
  state: NxtWorkSessionState;
  startBy: string;
  endBy: string;
  cashRegisters: NxtWorkSessionCashRegister[];
  additionalDateStrings: string[];
  invoiceDateString?: string;
  artistsCanEarlyPayout: string[];
}

export interface NxtStudioCashReportDataCalculatedDataArtistData {
  artistName: string;
  moneyEndOfDay: number;
  freeTimeWindows: NxtAvailableArtistDayExtendedArtistFreeTimeWindow[];
  workTimeWindow: NxtAvailableArtistWorkTimeWindow;
  workStart: number;
  workEnd: number;
}

export interface CanNotFinishReason {
  type: 'event-has-artist-open' | 'event-missing-payment' | 'event-missing-artist-should-get-money' | 'missing-cash-payment-out-scan' | 'artist-missing-spot' | 'open-reminder' | 'missing-event-rating' | 'event-missing-artist-percentage-confirm' | 'wrong-artist-percentage' | 'artist-already-payout';
  refType?: 'event' | 'cash-payment-out' | 'artist' | 'reminder' | '';
  text: string;
  refId?: string;
}


export interface NxtStudioCashReportDataCalculatedCashRegister {
  studio: string;
  isMainCashRegister: boolean;
  incomingPaymentsSum: number;
  incomingPaymentsSum_: number;
  outgoingPaymentsSum: number;
  outgoingPaymentsSum_: number;
  show?: boolean;
  canNotFinishReasons: CanNotFinishReason[];
}

export interface NxtStudioCashReportDataCalculatedData {
  endOfDayCash: {
    value: number;
    toGetFromBank: number;
    value_: number;
  };
  payments: {
    reception: {
      bank: number;
      klarna: number;
      giftCard: number;
      paypal: number,
      bankPostponed: number;
      klarnaPostponed: number;
      giftCardPostponed: number;
      paypalPostponed: number
    }
  };
  cashRegisters: NxtStudioCashReportDataCalculatedCashRegister[];
  missingImproveArtistShouldGetMoney: boolean;
}

export interface NxtStudioCashReportData {
  workSession: NxtWorkSession | WithoutNxtDbFields<NxtWorkSession> | null;
  events: NxtCalendarEvent[];
  artistConfirms: NxtArtistConfirm[];
  postponedEvents: NxtCalendarEvent[];
  cashPayments: NxtStudioCashReportPayment[];
  calculatedData: NxtStudioCashReportDataCalculatedData;
  availableArtists: NxtAvailableArtistDayExtended;
  otherOpenWorkSession?: {
    openWorkSessionDateString: string,
    askUserIfManualDateChange: boolean
  };
  foundByAdditionalDateString: boolean;
  calcId: string;
  calcDuration: number;
}

export interface NxtWorkSessionPayout {
  id: string;
  value: number;
  moneyStack?: NxtMoneyStack;
  invoiceNumber?: string;
  artistGotMoney?: boolean;
  confirmState?: 'pending' | 'confirmed' | 'rejected' | 'none' | 'canceled';
}
