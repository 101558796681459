<!--<mat-spinner></mat-spinner>-->

<div class="flex h-full w-full flex-col items-center justify-center loading-container" [nxtMultiClick]="4" (nxtOnMultiClick)="multiClicked()">

  @if (random() === 1) {
    <img src="assets/logo-loading.svg"/>
  } @else if (random() === 2) {
    <div class="loader">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  } @else if (random() === 3) {
    <nxt-loading-cube/>
  } @else if (random() === 4) {
    <nxt-loading-pacman/>
  } @else if (random() === 5) {
    <nxt-loading-lines class="m-4"/>
  } @else if (random() === 6) {
    <nxt-loading-net/>
  }

  <div class="text-2xl message font-montserrat center" [nxtMultiClick]="3" (nxtOnMultiClick)="closeClicked()" [innerHTML]="data | safeHtml"></div>
</div>
