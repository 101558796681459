<div class="background fix-fullscreen"></div>


<div style="width:100%; height: 100%" fxLayout="row" fxLayoutAlign="center center">
<video
  #videoElement
  *ngIf="show"
  [ngStyle]="{width: videoWidth, height: videoHeight, visibility: visibility, left: videoLeft, top: videoTop}"
  class="video"
  preload="auto"
  [src]="videoSrc"
>
</video>
</div>
