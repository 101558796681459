<div *ngIf="errorText">{{errorText}}</div>


<div class="receipt-container" style="border: 0px solid black; height: 100%; width:100%">

  <div fxFlex fxLayout="column" fxLayoutAlign="center">
    <div fxFlex="20" fxLayout="column" style="border: 0px solid red" fxLayoutAlign="start center">
      <div fxFlex="70" style="border: 0px solid yellowgreen; height: 80px; margin: 15px; margin-top: 30px">
        <img src="/assets/logo/logo_black.svg" style="height: 80px;" alt=""/>
      </div>
      <div fxFlex="30" style="border: 0px solid green; font-size:12px;">
        GBX GmbH · {{gbxStreet}} · {{gbxPostalCode}} Aachen
      </div>
    </div>

    <div fxFlex="80" fxLayout="column" style="border: 0px solid blue;" fxLayoutAlign="center">
      <div fxLayout="column" fxLayoutAlign="center center">
        <br/><br/>
        <h1>Quittung</h1>
        <div>Nr {{data.receiptNo}}</div>
        <div>{{receiptDate}}</div>
      </div>
      <br/>
      <div fxLayout="column" fxFlex fxLayoutAlign="start center">
        <div style="font-size:12px; width: 80vw">
          {{data.customer.customerName}}
          <br/>
          {{data.customer.customerStreet}} {{data.customer.customerHouseNumber}}
          <br/>
          {{data.customer.customerPostalCode}} {{data.customer.customerCity}}
        </div>
        <br/>
        <table class="pos-table">
          <tr>
            <th>Bezeichnung</th>
            <th>Menge</th>
            <th>Einzel €</th>
            <th>Gesamt €</th>
          </tr>
          <tr *ngFor="let pos of data.receiptPositions">
            <td style="width: 40%" [innerHTML]="pos.name | safeHtml"></td>
            <td class="cell-align-right">{{pos.amount}}</td>
            <td class="cell-align-right">{{pos.unitPrice | money}}</td>
            <td class="cell-align-right">{{pos.amount * pos.unitPrice | money}}</td>
          </tr>
          <tr>
            <td colspan="3">
              Gesamtbetrag*
            </td>
            <td class="cell-align-right"><strong>{{data.receiptValueTotal | money}}</strong></td>
          </tr>
        </table>
        <div style="font-size:8px;margin-top: 3px">
          * Im Gesamtbetrag von {{data.receiptValueTotal | money}} (Netto: {{data.receiptValueTotal / ((vatPercent / 100) + 1) | money}}) sind USt {{vatPercent}} %
          ({{(data.receiptValueTotal - data.receiptValueTotal / ((vatPercent / 100) + 1)) | money}}) enthalten.
        </div>
        <div fxLayout="column" *ngIf="data.appointment" fxLayoutAlign="center center">
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <div>📆 DEIN TERMIN 📆</div>
          <div>⌚ {{data.appointment.startDateTime | nxtDate: 'dd.MM.yyyy HH:mm'}} ⌚</div>
          <div>📍 {{data.appointment.studioAddress}} 📍</div>
        </div>
      </div>
    </div>
  </div>
</div>
