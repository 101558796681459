import {Pipe, PipeTransform} from '@angular/core';
import {WorkplaceTools} from '../common-browser/helpers/workplace.tools';

@Pipe({
    name: 'workplace',
    standalone: true
})
export class WorkplacePipe implements PipeTransform {

  constructor() {
  }

  transform(value: any): any {
    return WorkplaceTools.getText(value);
  }
}
