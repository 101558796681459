import {MathTools} from './math.tools';

export class PercentageTools {
    static getDiscountPercentage(value: number, paymentValue: number, decimals = 4) {
        return MathTools.round((1 - (paymentValue / value)) * 100, decimals);
    }

    static test() {
        if (PercentageTools.getDiscountPercentage(100, 80) !== 20) {
            debugger;
        }
    }
}
