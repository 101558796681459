import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, Optional, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {NxtTypedFormControl} from '../../../nxt-form/nxt.typed-form-control';
import {NxtEmployee, NxtEmployeeEvent, NxtEmployeeEventType, NxtWorkplace} from '../../../common-interfaces/nxt.employee.interface';
import {CacheService} from '../../../services/cache/cache.service';
import {AutocompleteComponent} from '../../form-controls/autocomplete/autocomplete.component';
import {AsyncPipe} from '@angular/common';
import {TimePickerComponent} from '../../form-controls/time-picker/time-picker.component';
import {FormTools} from '../../../services/form.tools';
import {EmployeeTools} from '../../../common-browser/helpers/employee.tools';
import {WithoutNxtDbFields} from '../../../common-interfaces/nxt.db-fields.interface';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {SocketService} from '../../../services/socket/socket.service';
import {DialogRef} from '@angular/cdk/dialog';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {DialogService} from '../../../services/dialog.service';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {ConfigService} from '../../../services/config.service';
import {CheckboxComponent} from '../../form-controls/checkbox/checkbox.component';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PermissionDirective} from '../../../directives/permission.directive';
import {DayOfWeek} from '../../../common-interfaces/date.interface';
import {InputComponent} from '../../form-controls/input/input.component';
import {WorkplaceService} from '../../../services/workplace.service';
import {EmployeeEventService} from '../employee-event.service';

@Component({
  selector: 'nxt-employee-event-edit',
  templateUrl: './employee-event-edit.component.html',
  styleUrls: ['./employee-event-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AutocompleteComponent,
    AsyncPipe,
    TimePickerComponent,
    NxtButtonComponent,
    MatButtonToggleGroup,
    MatButtonToggle,
    ReactiveFormsModule,
    CheckboxComponent,
    NxtDatePipe,
    PermissionDirective,
    InputComponent,
  ],
})

export class EmployeeEventEditComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  selectedDates: { [dayOfWeek: number]: boolean } = {};
  employees: Observable<NxtEmployee[]>;


  constructor(
    @Optional() public dialogRef: DialogRef<EmployeeEventEditComponent>,
  ) {
    super();
    this.employees = this.cacheService.employees.pipe(map(e => e.filter(e => !e.hasLeft)));
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  studioOptions = signal<{ value: string, text: string }[]>([]);
  timeButtons = signal<{ text: string, fromTime: string, tillTime: string }[]>([]);

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);
  cacheService = inject(CacheService);
  socketService = inject(SocketService);
  dialogService = inject(DialogService);
  configService = inject(ConfigService);
  employeeEventService = inject(EmployeeEventService);
  workplaceService = inject(WorkplaceService);


  form = new FormGroup({
    id: new NxtTypedFormControl('', [], 'ID'),
    employee: new NxtTypedFormControl<NxtEmployee>(null, [ValidatorTools.hasProperty('id')], 'Mitarbeiter'),
    dateString: new NxtTypedFormControl<string>(null, [ValidatorTools.requiredAndNotNaN], 'Datum'),
    startTime: new NxtTypedFormControl<string>(null, [ValidatorTools.requiredAndNotNaN], 'Start'),
    endTime: new NxtTypedFormControl<string>(null, [ValidatorTools.requiredAndNotNaN], 'Ende'),
    type: new NxtTypedFormControl<NxtEmployeeEventType>(null, [ValidatorTools.requiredAndNotNaN], 'Typ'),
    studio: new NxtTypedFormControl(null, [ValidatorTools.requiredAndNotNaN], 'Studio'),
    workplaces: new NxtTypedFormControl<NxtWorkplace[]>([], [ValidatorTools.requiredAndNotNaN], 'Arbeitsplatz'),
    info: new NxtTypedFormControl('', [], 'Info'),
  });
  originalEmployeeEvent: WithoutNxtDbFields<NxtEmployeeEvent>;


  displayWithEmployee = (data: NxtEmployee) => {
    if (!data) {
      return '';
    }
    return data.abbreviation + ' - ' + data.fullName;
  };

  ngOnInit() {
    this.timeButtons.set(this.employeeEventService.getWorkTimeButtons());
    if (this.configService.config.value.studioRegion === 'AC') {
      this.studioOptions.set([{text: 'Aachen', value: 'ac'}, {text: 'Düsseldorf', value: 'du'}, {text: 'Dortmund', value: 'do'}, {text: 'Mallorca', value: 'ma'}]);
    } else if (this.configService.config.value.studioRegion === 'MA') {
      this.studioOptions.set([{text: 'Mallorca', value: 'ma'}]);
    } else if (this.configService.config.value.studioRegion === 'BR') {
      this.studioOptions.set([{text: 'Brudis', value: 'br'}]);
    }
    this.registerChangeListener();
    this.initDone();
  }

  nxtOnDestroy() {
  }

  new(dateString: string, startTimeHour: string) {
    this.originalEmployeeEvent = EmployeeTools.getEmptyEmployeeEvent();
    this.originalEmployeeEvent.dateString = dateString;
    this.originalEmployeeEvent.start = (dateString + ' ' + startTimeHour).dateParse();
    this.setForm();
  }

  async load(employeeEventId: string) {
    this.originalEmployeeEvent = await this.socketService.getEmployeeEvent(employeeEventId);
    if (this.originalEmployeeEvent) {
      this.setForm();
    }
  }


  private async setForm() {
    await this.waitForInit();
    await this.cacheService.waitForEmployees();
    let studio = this.originalEmployeeEvent.studio;
    if (this.studioOptions().length === 1) {
      studio = this.studioOptions()[0].value;
    }
    FormTools.setForm(this.form, {
      employee: this.cacheService.employees.value.find(e => e.id === this.originalEmployeeEvent.employeeId),
      dateString: this.originalEmployeeEvent.dateString,
      startTime: this.originalEmployeeEvent.start > 0 ? this.originalEmployeeEvent.start.dateFormat('HH:mm') : null,
      endTime: this.originalEmployeeEvent.end > 0 ? this.originalEmployeeEvent.end.dateFormat('HH:mm') : null,
      type: this.originalEmployeeEvent.type,
      studio,
      workplaces: this.originalEmployeeEvent.workplaces,
      info: this.originalEmployeeEvent.info || '',
    });
    if (!this.originalEmployeeEvent.id) {
      this.selectedDates[DateTools.getDayOfWeek(this.originalEmployeeEvent.dateString)] = true;
    }
    this.cdRef.detectChanges();
  }

  private getFromForm(): WithoutNxtDbFields<NxtEmployeeEvent> {
    const employeeEvent = {
      ...this.originalEmployeeEvent,
      employeeId: this.form.controls.employee.value.id,
      start: DateTools.parse(this.originalEmployeeEvent.dateString + ' ' + this.form.controls.startTime.value + ':00'),
      end: DateTools.parse(this.originalEmployeeEvent.dateString + ' ' + this.form.controls.endTime.value + ':00'),
      dateString: this.form.controls.dateString.value,
      type: this.form.controls.type.value,
      studio: this.form.controls.studio.value,
      workplaces: this.form.controls.workplaces.value,
      info: this.form.controls.info.value || '',
    };
    if (employeeEvent.end < employeeEvent.start) {
      employeeEvent.end = employeeEvent.end.dateAddDays(1);
    }
    return employeeEvent;
  }

  async saveClicked() {
    if (FormTools.showErrorsTrueIfNoError(this.form, this.dialogService)) {
      const employeeEvent = this.getFromForm();
      if (this.originalEmployeeEvent.id) {
        await this.socketService.upsertEmployeeEvent(employeeEvent);
      } else {
        const currentDate = employeeEvent.dateString;
        const currentDayOfWeek = DateTools.getDayOfWeek(employeeEvent.dateString);
        for (let i = DayOfWeek.Monday; i <= DayOfWeek.Sunday; i++) {
          if (this.selectedDates[i]) {
            employeeEvent.dateString = currentDate.dateAddDays(i - currentDayOfWeek).dateFormatDate();
            employeeEvent.start = DateTools.parse(employeeEvent.dateString + ' ' + this.form.controls.startTime.value + ':00');
            employeeEvent.end = DateTools.parse(employeeEvent.dateString + ' ' + this.form.controls.endTime.value + ':00');
            await this.socketService.upsertEmployeeEvent(employeeEvent);
          }
        }
      }
      this.dialogRef.close();
    }
  }

  setTime(start: string, end: string) {
    this.form.controls.startTime.setValue(start);
    this.form.controls.endTime.setValue(end);
  }

  closeClicked() {
    this.dialogRef.close();
  }

  async deleteClicked() {
    if (await this.dialogService.showYesNo('Löschen?')) {
      this.socketService.deleteEmployeeEvent(this.originalEmployeeEvent.id);
      this.dialogRef.close();
    }
  }

  private registerChangeListener() {
    this.form.controls.type.valueChanges.subscribe((value) => {
      this.form.controls.studio.setErrors(null);
      this.form.controls.workplaces.setErrors(null);
      this.form.controls.studio.setValidators(this.form.controls.type.value === 'work' ? [ValidatorTools.requiredAndNotNaN] : []);
      this.form.controls.workplaces.setValidators(this.form.controls.type.value === 'work' ? [ValidatorTools.requiredAndNotNaN] : []);
      if (value !== 'work') {
        this.form.controls.studio.setValue(null);
        this.form.controls.workplaces.setValue([]);
      }
    });
  }
}
