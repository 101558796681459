import {Component, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from '../nxt.component';
import {SocketService} from '../../services/socket/socket.service';
import {NxtTextTemplateDef, TextTemplateTools} from '../../common-browser/helpers/text-template.tools';
import {DialogService} from '../../services/dialog.service';
import {TextTemplateEditComponent} from './text-template-edit/text-template-edit.component';
import {MatDialogRef} from '@angular/material/dialog';
import {PermissionDirective} from '../../directives/permission.directive';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NgFor, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';


@Component({
    selector: 'nxt-text-templates',
    templateUrl: './text-templates.component.html',
    styleUrls: ['./text-templates.component.scss'],
    imports: [FlexModule, NgIf, NxtButtonIconComponent, PermissionDirective, NgFor]
})

export class TextTemplatesComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  public textTemplateDefs = TextTemplateTools.TextTemplateDefs;

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
    private socketService: SocketService,
    private dialogService: DialogService
  ) {
    super();
    this.load();
  }

  ngOnInit() {

  }

  nxtOnDestroy() {

  }

  private async load() {
    // this.textTemplates = await this.socketService.getTextTemplates();
  }

  public async edit(textTemplateDef: NxtTextTemplateDef) {
    const dialog = this.dialogService.showComponentDialog(TextTemplateEditComponent);
    dialog.componentInstance.load(textTemplateDef.type);
  }
}
