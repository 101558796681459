<div fxLayout="row" nxtPermission="IsJulian">{{ id }}</div>
<div fxLayout="column" *ngIf="form" [formGroup]="form">
  <nxt-row>
    <nxt-col fxFlex>
      <nxt-select
        placeholder="Typ"
        [options]="articles"
        placeholder="articleId"
        [nxtFormControl]="form.get('articleId')"
        (valueChange)="articleChanged($event)"
      ></nxt-select>
    </nxt-col>
  </nxt-row>

  <nxt-row>
    <nxt-col fxFlex>
      <nxt-input
        label="Bezeichnung"
        [nxtFormControl]="form.get('description')"
      ></nxt-input>
    </nxt-col>
  </nxt-row>

  <nxt-row>
    <nxt-col fxFlex>
      <nxt-input
        placeholder="Betrag"
        [nxtFormControl]="form.get('value')"
        [isMoney]="true"
      ></nxt-input>
      <div fxLayout="column" fxLayoutAlign="center" nxtPermission="StudioCashReport_EditIncomingOutgoing_CanEdit_">
        <nxt-slide-toggle [nxtFormControl]="form.get('_')">
          Tresor
        </nxt-slide-toggle>
        <!--<nxt-checkbox
          label="_"
          [nxtFormControl]="form.get('_')"
        ></nxt-checkbox>-->
      </div>
    </nxt-col>
  </nxt-row>

  <nxt-row>
    <nxt-col fxFlex>
      <nxt-date-picker placeholder="Datum" [nxtFormControl]="form.get('date')"></nxt-date-picker>
    </nxt-col>
    <nxt-col fxFlex>
      <nxt-input placeholder="Uhrzeit" [nxtFormControl]="form.get('time')"></nxt-input>
    </nxt-col>
  </nxt-row>

  <nxt-row>
    <nxt-col fxFlex>
      <nxt-select
        placeholder="Studio"
        [nxtFormControl]="form.get('studio')"
        [options]="studios"
      >
      </nxt-select>
    </nxt-col>
  </nxt-row>

  <!--<nxt-row>
    <nxt-col fxFlex>
      <nxt-select
        placeholder="Studio-Real"
        [nxtFormControl]="form.get('studioReal')"
        [options]="studios"
      >
      </nxt-select>
    </nxt-col>
  </nxt-row>-->

  <nxt-row>
    <nxt-col fxFlex>
      <nxt-input placeholder="Beleg von" [nxtFormControl]="form.get('originalCreatedBy')"></nxt-input>
    </nxt-col>
    <nxt-col fxFlex>
      <nxt-input placeholder="erstellt von" [nxtFormControl]="form.get('createdBy')"></nxt-input>
    </nxt-col>
  </nxt-row>

  <nxt-row>
    <nxt-col fxFlex>
      <nxt-input placeholder="geändert am" [nxtFormControl]="form.get('editedAt')"></nxt-input>
    </nxt-col>
    <nxt-col fxFlex>
      <nxt-input placeholder="geändert von" [nxtFormControl]="form.get('editedBy')"></nxt-input>
    </nxt-col>
  </nxt-row>


  <div class="pb-2" *ngIf="form.get('articleId').value === 'transfer_bank' && cashType === 'outgoing'">
    @if (form.get('bankConfirmed').value) {
      <div class="green">Einzahlung von Bank bestätigt</div>
      <div class="text-80" *ngIf="form.get('bankConfirmedManualReason').value">{{ form.get('bankConfirmedManualReason').value }}</div>
    } @else {
      <div class="red">Einzahlung von Bank NICHT bestätigt</div>
    }
  </div>


  <div *ngIf="googleDriveFolderId === 'not-needed'">Benötigt kein Beleg</div>

  <div *ngIf="googleDriveFolderId" fxLayout="column">
    <div fxLayout="row">
      <div *ngFor="let file of files" (click)="openGoogleDrive()">
        <img *ngIf="file.base64" [src]="'data:'+ file.mimeType + ';base64,'+ file.base64 | safeResourceUrl">
        <img *ngIf="!file.base64" [src]="file.thumbnailLink | safeResourceUrl">
      </div>
    </div>
  </div>

  <div class="flex flex-row justify-center gap-2 px-1">
    <div>
      <nxt-button (click)="closeClicked()">ABBRECHEN</nxt-button>
    </div>
    <div *ngIf="canReupload">
      <nxt-button (click)="reUpload()">BELEG<br/>HOCHLADEN</nxt-button>
    </div>
    <div nxtPermission [permissionOr]="['Admin']" *ngIf="!googleDriveFolderId">
      <nxt-button (click)="noUploadNeededClicked()">BENÖTIGT<br/>KEIN BELEG</nxt-button>
    </div>
    <div *ngIf="!form.get('bankConfirmed').value" nxtPermission [permissionOr]="['IsJulian', 'IsNiklas', 'IsCapone']">
      <nxt-button (click)="setBankConfirmedClicked()">BANK<br/>BESTÄTIGEN</nxt-button>
    </div>
    <div nxtPermission [permissionOr]="['IsJulian', 'IsNiklas', 'IsCapone']">
      <nxt-button (click)="deleteClicked()">LÖSCHEN</nxt-button>
    </div>
    <div nxtPermission [permissionOr]="['IsJulian', 'IsNiklas', 'IsCapone']">
      <nxt-button (click)="saveAndClose()">SPEICHERN</nxt-button>
    </div>
  </div>
</div>
