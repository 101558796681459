import {Component, OnInit, Optional, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {MatDialogRef} from '@angular/material/dialog';
import {StudioCashReport2Service} from '../../studio-cash-report-2.service';
import {DialogService, LoadingId} from '../../../../services/dialog.service';
import {MoneyCounterWrapper2Component} from '../../../../pages/cash-money-counter-2/money-counter-wrapper2.component';
import {BehaviorSubject, first, firstValueFrom} from 'rxjs';
import {NxtWorkSessionCashRegisterState, NxtWorkSessionPayout} from '../../../../common-interfaces/nxt.work-session';
import {MoneyStackTools} from '../../../../common-browser/helpers/money-stack.tools';
import {SocketService} from '../../../../services/socket/socket.service';
import {MathTools} from '../../../../common-browser/helpers/math.tools';
import {Log} from '../../../../common-browser/log/log.tools';
import {NxtMoneyStack} from '../../../../common-interfaces/nxt.money-stack.interface';
import {SortTools} from '../../../../common-browser/helpers/sort.tools';
import {KeyCode, ShortcutService} from '../../../../services/shortcut.service';
import {FirestoreService} from '../../../../services/firestore.service';
import {PaymentTools} from '../../../../common-browser/helpers/payment.tools';
import {CashPaymentService} from '../../../../services/cash-payment.service';
import {LoginService} from '../../../../services/login.service';
import {ConfigService} from '../../../../services/config.service';
import {PermissionService} from '../../../../services/permission.service';
import {WorkSessionTools} from '../../../../common-browser/helpers/work-session.tools';
import {StringTools} from '../../../../common-browser/helpers/string.tools';
import {CheckListService} from '../../../../services/check-list.service';
import {MoneyStackPipe} from '../../../../pipes/money-stack.pipe';
import {MobileTools} from '../../../../common-browser/helpers/mobile.tools';
import {CashRegisterStatePipe} from '../../../../pipes/cash-register-state.pipe';
import {MoneyPipe} from '../../../../pipes/money.pipe';
import {SafeHtmlPipe} from '../../../../pipes/safe-html.pipe';
import {NxtDatePipe} from '../../../../pipes/nxt-date-pipe';
import {SlideToggleComponent} from '../../../form-controls/slide-toggle/slide-toggle.component';
import {SelectComponent} from '../../../form-controls/select/select.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {SpinnerComponent} from '../../../spinner/spinner.component';
import {NxtButtonIconComponent} from '../../../../controls/button-icon/nxt-button-icon.component';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {PermissionDirective} from '../../../../directives/permission.directive';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {JsonTools} from '../../../../common-browser/helpers/json.tools';
import {HttpClient} from '@angular/common/http';
import {IntervalTools} from '../../../../common-browser/helpers/interval.tools';
import {TimeoutTools} from '../../../../common-browser/helpers/timeout.tools';

@Component({
  selector: 'nxt-day-finish-view-2',
  templateUrl: './day-finish-view-2.component.html',
  styleUrls: ['./day-finish-view-2.component.scss'],
  imports: [FlexModule, PermissionDirective, NgIf, NgFor, NxtButtonIconComponent, SpinnerComponent, NxtButtonComponent, NgClass, ExtendedModule, SelectComponent, SlideToggleComponent, NxtDatePipe, SafeHtmlPipe, MoneyPipe, CashRegisterStatePipe, MoneyStackPipe],
  standalone: true,
})

export class DayFinishView2Component extends NxtComponent implements OnInit, NxtOnDestroy {

  private maxMissingValue = 100;

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
    private socketService: SocketService,
    private dialogService: DialogService,
    private shortcutService: ShortcutService,
    private firestoreService: FirestoreService,
    private cashPaymentService: CashPaymentService,
    private loginService: LoginService,
    private configService: ConfigService,
    private permissionService: PermissionService,
    private checkListService: CheckListService,
    private httpClient: HttpClient,
  ) {
    super();
    this.preload();
    if (this.permissionService.isAc()) {
      this.users = [{value: 'pre-safe', text: 'Safe bei Niklas'}, ...this.users];
    }

    this.pushSubscription = this.shortcutService.onKeyPress.subscribe((key) => {
      if (key === KeyCode.Down) {
        this.nextArtist();
      } else if (key === KeyCode.Up) {
        this.prevArtist();
      }
    });
  }

  private camInterval: number;
  camImgSrc: string;
  private showCamTimeout: number;

  payouts: NxtWorkSessionPayout[];
  moneyStackTotalValue = 0;
  // payoutsTotalSum = 0;
  payoutsTotalSumSafeIncluded = 0;
  shouldBeforeDayFinishTotalValue = 0;
  shouldBeforeDayFinishValueWithoutSafe = 0;
  public myService: StudioCashReport2Service;
  showLoadingUntilNextChange = false;
  private safeText = this.permissionService.isAc() ? 'Safe hinten' : 'Safe';
  public users = [{value: 'Safe', text: this.safeText}, ...this.configService.config.value.users
    .filter(u => u.canSafeMoneyGet)
    .map(u => ({text: u.username, value: u.username})).sort()];
  private bigPayoutArtists = new BehaviorSubject<string[] | null>(null);

  NxtWorkSessionCashRegisterState = NxtWorkSessionCashRegisterState;

  allPayoutsAreBooked = false;
  allEventsAreClosed = false;
  currentLoadingMessage = '';
  currentPayoutRow = 0;

  // safeConfirmedOrNotNeeded = false;
  loadingText = 'Artist werden ausgezahlt';
  // artistGotMoneySlideToggle: { [artist: string]: boolean } = {};
  camTimestamp = 0;
  showCam = false;

  useArtistConfirm = signal(true); // signal(true['AC', 'STAGING', 'DU'].includes(this.configService.config.value.studioRegion));

  showRestartCreateInvoicesButton = signal(false);

  private async preload() {
    this.bigPayoutArtists.next(await this.socketService.getBigPayoutArtists());
  }

  ngOnInit() {
    this.pushSubscription = this.myService.data$.subscribe((data) => {
      this.showLoadingUntilNextChange = false;
      this.loadingText = '';
      if (this.myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._8_ArtistPayoutBooking) {
        this.showLoadingUntilNextChange = true;
        this.loadingText = 'Artist-Auszahlungen werden gebucht';
      } else if (this.myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._9_CreateInvoices) {
      }
      this.calc();
    });

    this.startCamInterval();
  }

  private async calc() {
    await this.waitForBigPayoutArtists();
    if (WorkSessionTools.isCashRegisterStateBefore(this.myService.cashRegisterView.state, NxtWorkSessionCashRegisterState._10_PreparingPayouts)) {
      if (this.myService.cashRegisterView.safeCashPaymentId || this.myService.cashRegisterView.bankCashPaymentId) {
        this.dialogService.showOk('Zu diesem Zeitpunkt darf es noch keine Bank- oder Tresor-Buchung geben\nAbrechnung wird abgebrochen');
        this.dialogRef.close();
      }
    }
    this.shouldBeforeDayFinishTotalValue = this.getTotalShouldValue();
    this.shouldBeforeDayFinishValueWithoutSafe = this.getTotalShouldValueWithoutSafe();
    this.payoutsTotalSumSafeIncluded = this.getTotalPayoutValueTresorIncludedFromEvents(true);
    // this.safeConfirmedOrNotNeeded = !!this.myService.cashRegisterView.safeCashPaymentId || this.myService.data.calculatedData.endOfDayCash.value_ === 0;
    this.moneyStackTotalValue = MoneyStackTools.getTotalValueFromMoneyStack(this.myService.cashRegisterView.moneyStackInternal);
    this.allEventsAreClosed = !this.myService.data.events.some(e => !e.closed);
    this.allEventsAreClosed = true;
    this.allPayoutsAreBooked = !this.myService.data.events.some(e => e.artistToGet > 0);


    /*if (this.allEventsAreClosed && this.myService.myLoginCashRegister.state === NxtWorkSessionCashRegisterState._2_CloseAllEvents) {
      setTimeout(() => {
        this.myService.setViewedCashRegisterState(NxtWorkSessionCashRegisterState._3_CalcCashRegister1);
      }, 100);
    }*/
    if (this.allPayoutsAreBooked && this.myService.myLoginCashRegister.state === NxtWorkSessionCashRegisterState._7_ArtistPayoutCanBook) {
      /***
       * _7_ArtistPayoutCanBook
       */
      this.showLoadingUntilNextChange = true;
      this.bookArtistPayouts();
    }

    if (this.myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._9_CreateInvoices
      || this.myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._10_PreparingPayouts
      || this.myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._12_InvoicesPrinting
      || this.myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._13_PayoutsRunning
    ) {
      /***
       * _9_CreateInvoices
       * _10_PreparingPayouts
       * _12_InvoicesPrinting
       * _13_PayoutsRunning
       */
      const prios: string[] = [];
      const values: { id: string, value: number }[] = this.myService.artistData.filter(a => !a.isEarlyPaidOut).map(a => ({id: a.name, value: a.payoutValue}));

      const saveValue = this.myService.getSaveValueForMoneyCashCalc();
      if (saveValue) {
        prios.push(saveValue.id);
        values.push(saveValue);
      }
      for (const value of values) {
        if (this.bigPayoutArtists.value.includes(value.id)) {
          if (!prios.includes(value.id)) {
            // this.socketService.sendTelegramAdmin(value.id + ' bekommt große Scheine');
            prios.push(value.id);
          }
        }
      }
      // hier wird von dem gesamten MoneyStack

      const moneyStackSplitResult = MoneyStackTools.canReduceMultiFromMoneyStack(this.myService.cashRegisterView.moneyStackInternal, values, prios);
      if (!moneyStackSplitResult.possible) {
        // d.h. die nor
        // die mal bitte genauer schreiben, ob das passieren darf oder nicht
        // debugger;
        const stackSum = MoneyStackTools.getSum(this.myService.cashRegisterView.moneyStackInternal);
        const valueSum = values.reduce((sum, v) => sum + v.value, 0);
        Log.error('Money-Stack ist nicht mehr aufteilbar\nstackSum: ' + stackSum.toMoneyString() + '\nvalueSum: ' + valueSum.toMoneyString() + '\nMoneyStackInternal: ' + JsonTools.stringifyFormat(this.myService.cashRegisterView.moneyStackInternal) + '\n\n' + 'Values: ' + JsonTools.stringifyFormat(values));
      } else {
        this.payouts = moneyStackSplitResult.values.filter(v => v.value > 0).sort(SortTools.sortString('id', false, {first: 'Tresor'}));
        for (const payout of this.payouts) {
          payout.confirmState = 'none';
          const artistConfirm = this.myService.data.artistConfirms.find(ac => ac.artist === payout.id);
          if (artistConfirm) {
            payout.confirmState = artistConfirm.state;
          }
        }
        const pendingArtistConfirm = this.myService.data.artistConfirms.find(ac => ac.state === 'pending');
        this.payoutsTotalSumSafeIncluded = MathTools.roundMoney(this.payouts.reduce((sum, p) => sum + p.value, 0));
        const totalPayoutValueTresorIncludedFromEvents = this.getTotalPayoutValueTresorIncludedFromEvents(true);
        if (totalPayoutValueTresorIncludedFromEvents !== this.payoutsTotalSumSafeIncluded) {
          const payoutFromEvents = this.getTotalPayoutValueTresorIncludedFromEvents(false);
          // die Payouts stimmen nicht mit den berechneten Auszahlungen aus den Terminen überein
          // gibt es evtl. einen Termin mit einem Artist der nicht als Verfügbar markiert ist?
          // const text = 'gibt es evtl. einen Termin mit einem Artist der nicht als Verfügbar markiert ist?';
          const payoutsByArtistsFromEvents = this.getPayoutsByArtistFromEvents();

          let calculatedSafe = 0;
          if (this.myService.cashRegisterView.safeCashPayment) {
            calculatedSafe = this.myService.cashRegisterView.safeCashPayment.value;
          } else {
            calculatedSafe = this.myService.data.calculatedData.endOfDayCash.value_;
          }

          let text = 'RECHENFEHLER_2!!!';
          text += '\nerwartet: ' + totalPayoutValueTresorIncludedFromEvents + ' == ' + this.payoutsTotalSumSafeIncluded;
          text += '\ntotalPayoutValueTresorIncludedFromEvents == payoutsTotalSumSafeIncluded';
          text += '\nDiffernz: ' + Math.abs(totalPayoutValueTresorIncludedFromEvents - this.payoutsTotalSumSafeIncluded);

          text += '\nAuszahlungen aus den Terminen + Tresor ' + ' = ' + totalPayoutValueTresorIncludedFromEvents;
          text += '\nPayouts = ' + this.payoutsTotalSumSafeIncluded;
          text += '\npayoutFromEvents = ' + payoutFromEvents;
          text += '\nSollte Tresor: ' + calculatedSafe;
          text += '\n\nAuszahlungen aus den Terminen\n' + JsonTools.stringifyFormat(payoutsByArtistsFromEvents);
          await this.dialogService.showOk(text);
          this.dialogRef.close();
          debugger;
        }
      }
      if (this.myService.data.workSession.invoices) {
        for (const payout of this.payouts) {
          const invoice = this.myService.data.workSession.invoices.find(a => a.invoiceTo.artistName === payout.id);
          if (invoice) {
            payout.invoiceNumber = invoice.invoiceNumber;
          }
        }
      }
      if (this.myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._13_PayoutsRunning) {
        this.currentPayoutRow = -1;
        this.payouts = this.payouts.filter(p => p.id !== 'Tresor');
      }
    }


    this.showRestartCreateInvoicesButton.set(this.myService?.cashRegisterView?.errorMessage?.includes('Fehler beim erstellen einer Rechnung'));
  }

  nxtOnDestroy() {
    IntervalTools.clear(this.camInterval);
  }


  async countCashRegisterBeforeStart(countIndex: number) {
    this.myService.log(countIndex + '. Kassenzählung gestartet');
    const dialog = await this.dialogService.showComponentFull(MoneyCounterWrapper2Component);
    dialog.componentInstance.setData({
      showShould: false,
      // moneyStack: JsonTools.parse(this.test3), // this.myService.cashRegisterView.moneyStackPreCalcCashRegister,
      moneyStack: MoneyStackTools.getEmptyMoneyStack(), // this.myService.cashRegisterView.moneyStackPreCalcCashRegister,
      title: 'Gesamte Kasse zählen',
    });
    const preCalcCashRegisterMoneyStack = await firstValueFrom(dialog.afterClosed());
    const moneyStackValue = MoneyStackTools.getTotalValueFromMoneyStack(preCalcCashRegisterMoneyStack);
    const missing = MathTools.roundMoney(this.shouldBeforeDayFinishTotalValue - moneyStackValue);
    const missingPrefix = missing > 0 ? 'Fehlbetrag' : 'Überschuss';
    if (preCalcCashRegisterMoneyStack) {
      this.myService.log(countIndex + '. Kassenzählung fertig ' + StringTools.arrowRight + ' ' + moneyStackValue.toMoneyString() + ' (' + missingPrefix + ' ' + Math.abs(missing).toMoneyString() + ')');
      if (this.myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._3_CalcCashRegister1) {
        /*** Es war die ERSTE Zählung */
        if (Math.abs(missing) >= this.maxMissingValue) {
          this.myService.log('Die Kassendifferenz beträgt mehr als 100 €, so gehts nicht weiter');
          const text = 'Ein ' + missingPrefix + ' von ' + Math.abs(missing).toMoneyString() + ' ist nicht zulässig, bitte nochmal zählen!';
          this.myService.logDialog(text);
          await this.dialogService.showOk(text);
          return;
        }
        this.myService.cashRegisterView.moneyStackInternal = preCalcCashRegisterMoneyStack;
        this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._4_CalcCashRegister2;
        this.myService.logCashRegisterState();
        this.myService.updateWorkSessionCashRegister(this.myService.cashRegisterView);
      } else if (this.myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._4_CalcCashRegister2) {
        /*** Es war die ZWEITE Zählung */
        if (!MoneyStackTools.isSame(this.myService.cashRegisterView.moneyStackInternal, preCalcCashRegisterMoneyStack)) {
          /*** Zählungen stimmen nicht über ein, also nochmal zählen */
          this.myService.cashRegisterView.moneyStackInternal = MoneyStackTools.getEmptyMoneyStack();
          this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._3_CalcCashRegister1;
          this.myService.logCashRegisterState();
          const text = 'Die Erste und Zweite Kassenzählung stimmt nicht überein, bitte wiederholen.';
          this.myService.logDialog(text);
          this.dialogService.showOk(text);
        } else {
          /*** Zählungen stimmen überein */
          if (!this.myService.cashRegisterView.moneyStackInternal) {
            throw Error('moneyStackPreCalcCashRegister muss hier gesetzt sein');
          }

          if (Math.abs(missing) > 0) {
            await this.showMissingDialog(missing);
          } else {
            await this.countingProcessCashRegisterFinished(missing);
          }
        }
      } else if (this.myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._5_CalcCashRegister3) {
        if (!MoneyStackTools.isSame(this.myService.cashRegisterView.moneyStackInternal, preCalcCashRegisterMoneyStack)) {
          /*** Zählungen stimmen nicht über ein, also nochmal zählen */
          this.myService.cashRegisterView.moneyStackInternal = MoneyStackTools.getEmptyMoneyStack();
          this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._3_CalcCashRegister1;
          this.myService.logCashRegisterState();
          await this.dialogService.showOk('Die Erste und Zweite Zählung stimmen nicht mit der Dritten überein, bitte wiederholen.');
        } else {
          /*** Alle 3 Zählungen stimmen überein */
          let text = '';
          let noText = '';
          if (missing > 0) {
            text = 'Du hast leider zu wenig Geld, es fehlen dir ' + missing.toMoneyString();
            noText = 'Mit dem Fehlbetrag weiter machen';
          }
          if (missing < 0) {
            text = 'Du hast ' + Math.abs(missing).toMoneyString() + ' zu viel Geld gezählt.';
            noText = 'Mit dem Überschuss weiter machen';
          }
          if (text) {
            const result = await this.dialogService.showYesNo(text, {yesText: 'Ich zähle das Geld nochmal', noText});
            if (result) {
              this.myService.setViewedCashRegisterState(NxtWorkSessionCashRegisterState._3_CalcCashRegister1);
              return;
            }
          }
          await this.countingProcessCashRegisterFinished(missing);
        }
      }
      this.myService.updateWorkSessionCashRegister(this.myService.cashRegisterView);
    }
  }

  /**
   * Wie viel Geld sollte da sein bevor man die Abrechnung beginnt.
   * Alle Einnahmen - alle Ausgaben (ohne Artist-Auszahlungen die bei der Abrechnung gebucht werden).
   * @private
   */
  private getTotalShouldValue() {
    const cashRegisterCalc = this.myService.data.calculatedData.cashRegisters.find(c => c.studio === this.myService.cashRegisterView.studio);
    let totalMoneyShouldValue = cashRegisterCalc.incomingPaymentsSum + cashRegisterCalc.incomingPaymentsSum_;
    totalMoneyShouldValue -= cashRegisterCalc.outgoingPaymentsSum + cashRegisterCalc.outgoingPaymentsSum_;
    totalMoneyShouldValue += this.myService.cashRegisterView.startMoney;
    // Artists-Payouts dazu rechnen (passiert nur, wenn man die Abrechnung wiederholt)
    if (this.myService.cashRegisterView.isMainCashRegister) {
      const alreadyPayoutsValue = this.myService.data.events.filter(e => !e.payments.some(p => p.earlyPayout)).reduce((sum, e) => sum + PaymentTools.getPaymentSumByPaymentType(e.payments, 'payout'), 0);
      // Bereits Artist-Auszahlungen dazu rechnen
      totalMoneyShouldValue += alreadyPayoutsValue;
      // Bereits getätigte Bank-Buchung dazu rechnen
      if (this.myService.cashRegisterView.bankCashPayment) {
        totalMoneyShouldValue += this.myService.cashRegisterView.bankCashPayment.value;
      }
      // Bereits getätigte Tresor-Buchung dazu rechen
      if (this.myService.cashRegisterView.safeCashPaymentId) {
        totalMoneyShouldValue += this.myService.cashRegisterView.safeCashPayment.value;
      }
    } else {
      const mainCashRegisterStudio = this.myService.data.workSession.cashRegisters.find(c => c.isMainCashRegister).studio;
      const payments = this.myService.data.cashPayments
        .filter(p => p.direction === 'out' && p.studio === this.myService.cashRegisterView.studio && p.articleId?.startsWith('transfer_' + mainCashRegisterStudio.toLowerCase()));
      const alreadyTransferToMainCashRegisterValue = payments.reduce((sum, p) => sum + p.paymentValue, 0);
      totalMoneyShouldValue += alreadyTransferToMainCashRegisterValue;
    }
    return totalMoneyShouldValue.roundMoney();
  }

  private getTotalShouldValueWithoutSafe() {
    const cashRegisterCalc = this.myService.data.calculatedData.cashRegisters.find(c => c.studio === this.myService.cashRegisterView.studio);
    let totalMoneyShouldValue = cashRegisterCalc.incomingPaymentsSum;
    totalMoneyShouldValue -= cashRegisterCalc.outgoingPaymentsSum;
    totalMoneyShouldValue += this.myService.cashRegisterView.startMoney;
    // Artists-Payouts dazu rechnen (passiert nur, wenn man die Abrechnung wiederholt)
    if (this.myService.cashRegisterView.isMainCashRegister) {
      const eventsPayout = this.myService.data.events.filter(e => e.visibility !== 'private' && !e.payments.some(p => p.earlyPayout));
      const alreadyPayoutsValue = this.myService.data.events.filter(e => e.visibility !== 'private' && !e.payments.some(p => p.earlyPayout)).reduce((sum, e) => sum + PaymentTools.getPaymentSumByPaymentType(e.payments, 'payout'), 0);
      totalMoneyShouldValue += alreadyPayoutsValue;
      // Bereits getätigte Bank-Buchung dazu rechnen
      if (this.myService.cashRegisterView.bankCashPayment) {
        totalMoneyShouldValue += this.myService.cashRegisterView.bankCashPayment.value;
      }
      // Bereits getätigte Tresor-Buchung dazu rechen
      if (this.myService.cashRegisterView.safeCashPaymentId) {
        // totalMoneyShouldValue += this.myService.cashRegisterView.safeCashPayment.value;
      }
    } else {
// Transfers zur Hauptkasse abziehen
      const mainCashRegisterStudio = this.myService.data.workSession.cashRegisters.find(c => c.isMainCashRegister).studio;
      const payments = this.myService.data.cashPayments
        .filter(p => p.direction === 'out' && !p.isPrivate && p.studio === this.myService.cashRegisterView.studio && p.articleId?.startsWith('transfer_' + mainCashRegisterStudio.toLowerCase()));
      const alreadyTransferToMainCashRegisterValue = payments.reduce((sum, p) => sum + p.paymentValue, 0);
      totalMoneyShouldValue += alreadyTransferToMainCashRegisterValue;
    }
    return totalMoneyShouldValue.roundMoney();
  }

  private getPayoutsByArtistFromEvents() {
    const payouts: { [artist: string]: number } = {};
    const events = this.myService.data.events.filter(e => !e.payments.some(p => p.earlyPayout));
    for (const event of events) {
      if (event.artistTotalGet > 0) {
        if (!payouts[event.artist]) {
          payouts[event.artist] = 0;
        }
        payouts[event.artist] += event.artistTotalGet;
      }
    }
    return payouts;
  }

  /***
   * Alle Auszahlungen + Tresor-Buchung
   ***/
  private getTotalPayoutValueTresorIncludedFromEvents(withSafe: boolean) {
    const payouts = this.myService.data.events.filter(e => !e.payments.some(p => p.earlyPayout)).reduce((sum, e) => sum + e.artistTotalGet, 0);
    let result = 0;
    if (!withSafe) {
      result = payouts;
    } else {
      if (this.myService.cashRegisterView.safeCashPayment) {
        result = MathTools.roundMoney(payouts + this.myService.cashRegisterView.safeCashPayment.value);
      } else {
        result = MathTools.roundMoney(payouts + this.myService.data.calculatedData.endOfDayCash.value_);
      }
    }
    return result;
  }

  async countCashRegisterChange() {
    this.myService.log('Geldwechseln gestartet');
    const dialog = this.dialogService.showComponentFull(MoneyCounterWrapper2Component);
    const artistPayouts = this.myService.getArtistPayouts(true);
    const payoutData: { values: { id: string; value: number }[]; prioIds: string[] } = {
      prioIds: [],
      values: artistPayouts.artists.map(p => ({id: p.artistName, value: p.payoutValueTotal})),
    };

    if (this.myService.data.calculatedData.endOfDayCash.value_ > 0) {
      payoutData.values.push({id: 'Tresor', value: this.myService.data.calculatedData.endOfDayCash.value_});
      payoutData.prioIds = ['Tresor', ...payoutData.prioIds];
    }

    for (const artist of artistPayouts.artists) {
      if (this.bigPayoutArtists.value.includes(artist.artistName)) {
        if (!payoutData.prioIds.includes(artist.artistName)) {
          // this.socketService.sendTelegramAdmin(artist.artistName + ' bekommt große Scheine');
          payoutData.prioIds.push(artist.artistName);
        }
      }
    }


    dialog.componentInstance.setData({
      showShould: true,
      moneyStack: this.myService.cashRegisterView.moneyStackInternal,
      title: 'Geld wechseln',
      // totalMoneyShouldValue: this.shouldBeforeDayFinishValue,
      totalMoneyShouldValue: MoneyStackTools.getTotalValueFromMoneyStack(this.myService.cashRegisterView.moneyStackInternal),
      payoutData,
      suppressValueChange: true,
      canOnlyUseChangeProposals: true,
    });

    const result = await firstValueFrom(dialog.afterClosed());
    if (result) {
      this.myService.log('Geldwechseln erfolgreich beendet');
      this.myService.cashRegisterView.moneyStackInternal = result;
      this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._7_ArtistPayoutCanBook;
      this.myService.logCashRegisterState();
      this.myService.updateWorkSessionCashRegister(this.myService.cashRegisterView);
    } else {
      this.myService.log('Geldwechseln abgebrochen');
    }
  }

  bookArtistPayouts() {
    this.showLoadingUntilNextChange = true;
    this.myService.log('Artist-Auszahlungen werden gebucht');
    this.socketService.addArtistPayouts2(this.myService.dateString);
  }


  nextArtist() {
    if (this.myService.myLoginCashRegister.state === NxtWorkSessionCashRegisterState._9_CreateInvoices || this.myService.myLoginCashRegister.state === NxtWorkSessionCashRegisterState._10_PreparingPayouts) {
      if (this.payouts.length > this.currentPayoutRow + (this.myService.myLoginCashRegister.state === NxtWorkSessionCashRegisterState._10_PreparingPayouts ? 0 : 1)) {
        this.currentPayoutRow++;
        if (this.payouts[this.currentPayoutRow]) {
          this.myService.log('Umschlag von "' + this.payouts[this.currentPayoutRow].id + '" (' + this.payouts[this.currentPayoutRow].value.toMoneyString() + ') wird gepackt');
        }
      }
    }
  }

  prevArtist() {
    if (this.myService.myLoginCashRegister.state === NxtWorkSessionCashRegisterState._9_CreateInvoices || this.myService.myLoginCashRegister.state === NxtWorkSessionCashRegisterState._10_PreparingPayouts) {
      if (this.currentPayoutRow > 0) {
        this.currentPayoutRow--;
        this.myService.log('Umschlag von "' + this.payouts[this.currentPayoutRow].id + '" (' + this.payouts[this.currentPayoutRow].value.toMoneyString() + ') wird gepackt');
      }
    }
  }

  async showInvoice(payout: { id: string; value: number; moneyStack?: NxtMoneyStack; missingMoney?: number; invoiceNumber?: string }) {
    const invoice = await this.socketService.getArtistInvoice(payout.invoiceNumber);
    this.dialogService.showPdf(invoice.invoiceBase64, invoice.invoiceNumber + '.pdf');
  }

  bookSideCashRegisterTransfer() {
    const transferValue = this.shouldBeforeDayFinishValueWithoutSafe - this.myService.cashRegisterView.missingValue;
    const transferSafeValue = this.shouldBeforeDayFinishTotalValue - this.shouldBeforeDayFinishValueWithoutSafe;
    this.socketService.bookSideCashRegisterTransfer(this.myService.dateString, this.myService.cashRegisterView.studio, transferValue, transferSafeValue);
    this.showLoadingUntilNextChange = true;
  }

  private async countingProcessCashRegisterFinished(missing: number) {
    if (!this.myService.cashRegisterView.isMainCashRegister) {
      // hier noch den Transfer buchen
      const mainCashRegister = this.myService.data.workSession.cashRegisters.find(c => c.isMainCashRegister);
      this.myService.cashRegisterView.moneyStackEnd = MoneyStackTools.getEmptyMoneyStack();
      this.myService.cashRegisterView.endMoney = 0;
      this.myService.cashRegisterView.endMoneyDateTime = Date.now();
      this.myService.cashRegisterView.shouldValueBeforePayoutsWithoutSafe = this.shouldBeforeDayFinishValueWithoutSafe;
      if (this.myService.cashRegisterView.shouldValueBeforePayoutsWithoutSafe < 0) {
        this.myService.log('shouldValueBeforePayoutsWithoutSafe ist kleiner 0, das darf nicht sein -> Fehler');
        throw Error('Es fehlt zu viel Geld um fortzufahren');
      }
      this.myService.log('Fehlbetrag (missingValue) wird auf ' + missing + ' gesetzt');
      this.myService.cashRegisterView.missingValue = missing;
      this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._29_SideCashRegisterBookTransfers;
      this.myService.logCashRegisterState();
      this.myService.updateWorkSessionCashRegister(this.myService.cashRegisterView);
    } else {
      if (this.payoutsTotalSumSafeIncluded > this.moneyStackTotalValue) {
        const getFromBank = MathTools.roundTo(this.payoutsTotalSumSafeIncluded - this.moneyStackTotalValue, 10);
        const text = 'Da du ein Fehlbetrag hast, ist eine Auszahlung aller Artists nicht mehr möglich.\n' +
          '\n\nEs müssen <strong>' + getFromBank.toMoneyString() + '</strong> von der Bank geholt werden' +
          '\n\nDie Abrechnung wird abgebrochen';
        await this.dialogService.showOk(text, {title: 'Abrechnung nicht möglich'});
        this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._1_Open;
        this.myService.logCashRegisterState();
        const telegramText = 'Abrechnung wird abgebrochen!\nDer Fehlbetrag von ' + missing.toMoneyString() + ' hat eine Bank-Einlage zur Folge.' +
          '\n\nEs müssen ' + getFromBank.toMoneyString() + ' von der Bank geholt werden.';
        this.socketService.sendBackofficeHeads(telegramText);
        setTimeout(() => this.dialogRef.close(), 1000);
      } else {
        if (!this.myService.canPayoutPreCalcCashRegister()) {
          this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._6_ChangeMoney;
          this.myService.logCashRegisterState();
        } else {
          this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._7_ArtistPayoutCanBook;
          this.myService.logCashRegisterState();
        }
        this.myService.logCashRegisterState();
        this.myService.log('Fehlbetrag (missingValue) wird auf ' + missing + ' gesetzt');
        this.myService.cashRegisterView.missingValue = missing;
      }
    }
  }

  async test4() {
    if (this.myService.cashRegisterView.safeCashPaymentId) {
      await this.cashPaymentService.deleteById(this.myService.cashRegisterView.safeCashPaymentId);
    }
    if (this.myService.cashRegisterView.bankCashPaymentId) {
      await this.cashPaymentService.deleteById(this.myService.cashRegisterView.bankCashPaymentId);
    }
    // this.myService.bookBankAndSafe(100, 12);
  }

  async bookBankClicked() {
    if (this.myService.cashRegisterView.state === NxtWorkSessionCashRegisterState._9_CreateInvoices) {
      this.dialogService.showOk('Rechnungen werden noch erstellt, bitte warten bis die Rechnungen erstellt wurden.');
      return;
    }
    const bankMaxValue = MathTools.roundMoney(this.moneyStackTotalValue - this.payoutsTotalSumSafeIncluded);
    const safeValue = this.payouts.find(p => p.id === 'Tresor')?.value || 0;
    this.showLoadingUntilNextChange = true;
    if (!await this.myService.bookBankAndSafe(bankMaxValue, safeValue, this.shouldBeforeDayFinishValueWithoutSafe)) {
      this.showLoadingUntilNextChange = false;
    }
  }

  async bookEndMoney() {
    this.myService.log('starte die Zählung des Kassenbestandes');
    const totalMoneyShouldValue = MathTools.roundMoney(this.moneyStackTotalValue - this.payoutsTotalSumSafeIncluded - this.myService.cashRegisterView.bankCashPayment.value);
    const dialog = this.dialogService.showComponentFull(MoneyCounterWrapper2Component);
    dialog.componentInstance.setData({
      showShould: true,
      moneyStack: MoneyStackTools.getEmptyMoneyStack(),
      title: 'Kassen-Endbestand zählen',
      totalMoneyShouldValue,
      canNotExitWithWrongShouldValue: true,
    });
    const preCalcCashRegisterMoneyStack = await firstValueFrom(dialog.afterClosed());
    if (preCalcCashRegisterMoneyStack) {
      const endMoney = MoneyStackTools.getTotalValueFromMoneyStack(preCalcCashRegisterMoneyStack);
      this.myService.log('Kassenbestandes von ' + endMoney.toMoneyString() + ' wird gebucht');
      this.myService.cashRegisterView.moneyStackEnd = preCalcCashRegisterMoneyStack;
      this.myService.cashRegisterView.endMoney = endMoney;
      this.myService.cashRegisterView.endMoneyDateTime = Date.now();
      const safeValue = MathTools.roundMoney(this.shouldBeforeDayFinishTotalValue - this.shouldBeforeDayFinishValueWithoutSafe);
      this.myService.log('shouldBeforeDayFinishValue: ' + this.shouldBeforeDayFinishTotalValue);
      this.myService.log('shouldBeforeDayFinishValueWithoutSafe: ' + this.shouldBeforeDayFinishValueWithoutSafe);
      if (safeValue > 0) {
        this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._11_SetSafeMoneyDestination;
        this.myService.logCashRegisterState();
      } else {
        this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._12_InvoicesPrinting;
        this.myService.cashRegisterView.payouts = this.payouts;
        this.myService.logCashRegisterState();
      }
      this.myService.updateWorkSessionCashRegister(this.myService.cashRegisterView);
    } else {
      this.myService.log('Zählung des Kassenbestandes abgebrochen');
    }
  }

  setSafeMoneyDestination() {
    let safeGetMoneyText = this.myService.cashRegisterView.safeMoneyGet;
    if (this.configService.config.value.studioRegion === 'AC') {
      safeGetMoneyText = safeGetMoneyText.replace('pre-safe', 'Safe bei Niklas');
    }
    const text = 'Tresor-Umschlag ' + this.myService.cashRegisterView.safeCashPayment.value.toMoneyString() + ' wird an "' + safeGetMoneyText + '" übergeben';
    if (!this.configService.config.value.isFranchise && this.myService.cashRegisterView.safeCashPayment.value > 0) {
      // this.socketService.sendWhatsAppMessage(MobileTools.Numbers.Julian, text);
      // this.socketService.sendWhatsAppMessage(MobileTools.Numbers.CaponePrivat, text);
    }
    this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._12_InvoicesPrinting;
    this.myService.cashRegisterView.payouts = this.payouts;
    this.myService.logCashRegisterState();
    this.myService.updateWorkSessionCashRegister(this.myService.cashRegisterView);
    this.showLoadingUntilNextChange = true;
    this.myService.log(text);
  }

  async printInvoicesClicked() {
    try {
      this.dialogService.showLoading(LoadingId.PrintPdf, 'Rechnungen werden gedruckt');
      this.myService.log('Alle Rechnungen drucken');
      await this.myService.workSessionPrintAllInvoices();
      this.myService.log('Alle Rechnungen wurden gedruckt');
      this.dialogService.hideLoading(LoadingId.PrintPdf);
    } catch (err) {
      this.myService.log('Fehler beim drucken der Rechnungen\n' + err.message);
      this.dialogService.hideLoading(LoadingId.PrintPdf);
      if (await this.dialogService.showYesNo('Fehler beim drucken der Rechnungen', {yesText: 'Alle Rechnungen lokal drucken', noText: 'Abbrechen'})) {
        this.myService.showMergedInvoices(true);
      }
    }
  }

  async showInvoicesClicked(showPrintLocalInfo: boolean) {
    this.myService.showMergedInvoices(showPrintLocalInfo);
  }

  async printDayFinishReportClicked() {
    const employee = await this.myService.askDayFinishReportEmployee();
    try {
      if (employee) {
        this.dialogService.showLoading(LoadingId.PrintPdf, 'Kassenbericht wird erstellt');
        this.myService.log('Kassenbericht(e) wird erstellt');
        await this.myService.workSessionPrintDayFinishReports(employee);
        this.myService.log('Kassenbericht(e) wurden gedruckt');
        this.dialogService.hideLoading(LoadingId.PrintPdf);
      }
    } catch (err) {
      this.myService.log('Fehler beim drucken des Kassenberichts\n' + err.message);
      this.dialogService.hideLoading(LoadingId.PrintPdf);
      if (await this.dialogService.showYesNo('Fehler beim drucken des Kassenberichts\n\n<small>' + err.message + '</small>', {
        yesText: 'Kassenbericht lokal drucken',
        noText: 'Abbrechen',
        title: '😒 Fehler 😒',
      })) {
        this.myService.showMergedDayFinishReports(employee, true);
      }
    }
  }

  async showDayFinishReportClicked(showPrintLocalInfo: boolean) {
    const employee = await this.myService.askDayFinishReportEmployee();
    if (employee) {
      this.myService.showMergedDayFinishReports(employee, showPrintLocalInfo);
    }
  }

  printingFinishedClicked() {
    this.showLoadingUntilNextChange = true;
    if (this.myService.cashRegisterView.isMainCashRegister) {
      this.myService.setViewedCashRegisterState(NxtWorkSessionCashRegisterState._13_PayoutsRunning);
    } else {
      this.myService.cashRegisterView.closedBy = this.loginService.getUsername();
      this.myService.setViewedCashRegisterState(NxtWorkSessionCashRegisterState._99_Closed);
    }
  }

  async payoutsFinishedClicked() {
    if (this.myService.data.artistConfirms.some(ac => ac.state === 'pending')) {
      await this.dialogService.showOk('Es gibt noch Artisten die ihre Umschläge nicht bestätigt haben, bitte warte bis alle Artisten ihre Umschläge bestätigt haben.');
      return;
    }

    if (!this.configService.config.value.isFranchise) {
      const confirmed = await this.dialogService.showYesNo('Ja ich habe verstanden, dass ich jeden Arbeitsplatz kontrollieren soll, das mach ich auch bei jedem Artist bevor ich ihm seinen Umschlag gebe.', {
        yesText: 'Ja, ich habe verstanden',
        noText: 'Nein, ich habe nicht verstanden',
      });
      if (!confirmed) {
        return;
      }
      const confirmed2 = await this.dialogService.showYesNo('Und wenn ich es vergesse', {
        yesText: 'Dann zahle ich pro Arbeitsplatz 50 €',
        noText: 'Dann passiert nix',
      });
      if (!confirmed2) {
        return;
      }
    }


    for (const payout of this.payouts) {
      payout.artistGotMoney = payout.confirmState === 'confirmed';
    }
    this.showLoadingUntilNextChange = true;
    this.myService.cashRegisterView.payouts = this.payouts;
    this.myService.cashRegisterView.checkListDone = false;
    this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._14_ClosingWorkSession;
    this.myService.logCashRegisterState();
    this.myService.updateWorkSessionCashRegister(this.myService.cashRegisterView);
  }

  async showCheckListClicked() {
    await this.checkListService.startReception();
    this.myService.cashRegisterView.checkListDone = true;
    this.myService.updateWorkSessionCashRegister(this.myService.cashRegisterView);
    this.showLoadingUntilNextChange = true;
  }

  closeDayFinishClicked() {
    this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._99_Closed;
    this.myService.cashRegisterView.closedBy = this.loginService.getUsername();
    this.myService.logCashRegisterState();
    this.myService.updateWorkSessionCashRegister(this.myService.cashRegisterView);
    this.dialogRef.close();
  }

  closeClicked() {
    this.dialogRef.close();
  }

  private async showMissingDialog(missing: number) {
    let text = '';
    let typeText = 'Fehlbetrag';
    if (missing > 0) {
      text = 'Es fehlen ' + missing.toMoneyString() + ' in der Kasse!';
    } else {
      text = 'Es sind ' + Math.abs(missing).toMoneyString() + ' zu viel in der Kasse!';
      typeText = 'Überschuss';
    }
    const showContinueButton = missing > (this.maxMissingValue * -1) && missing < this.maxMissingValue;

    if (showContinueButton) {
      if (await this.dialogService.showYesNo(text, {yesText: 'Ich zähle nochmal von vorne', noText: 'Mit dem ' + typeText + ' weiter machen'})) {
        this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._3_CalcCashRegister1;
        this.myService.logCashRegisterState();
        this.myService.log(text + ' -> Ich zähle nochmal von vorne das Geld nochmal');
      } else {
        this.myService.log(text + ' -> Mit dem Fehlbetrag weiter machen');
        await this.countingProcessCashRegisterFinished(missing);
      }
    } else {
      await this.dialogService.showOk(text, {buttonText: 'Ich zähle das Geld nochmal'});
      this.myService.log(text + ' -> Muss von vorne zählen');
      this.myService.cashRegisterView.state = NxtWorkSessionCashRegisterState._3_CalcCashRegister1;
      this.myService.logCashRegisterState();
    }
  }


  private async startCamInterval() {
    const ip = await this.socketService.getIp();
    if (ip === '1' || ip === '37.24.101.11') {
      this.camInterval = window.setInterval(async () => {
        this.loadCamImage();
      }, 3000);
      this.loadCamImage();
    }
  }

  private async loadCamImage() {
    return;
    const response = await firstValueFrom(this.httpClient.get('https://test.nxt-lvl.ink/empfang-bild-9ksfwjv9buz4dlwqg628nc', {responseType: 'arraybuffer'}));
    const arrayBufferView = new Uint8Array(response);
    const blob = new Blob([arrayBufferView], {type: 'image/jpeg'});
    const urlCreator = window.URL || window.webkitURL;
    this.camImgSrc = urlCreator.createObjectURL(blob);
    this.camTimestamp = Date.now();
  }

  hideCamClicked() {
    TimeoutTools.clear(this.showCamTimeout);
    this.showCam = false;
    this.showCamTimeout = window.setTimeout(() => {
      this.showCam = true;
    }, 15000);
  }

  private async waitForBigPayoutArtists() {
    return new Promise<void>((resolve, reject) => {
      if (Array.isArray(this.bigPayoutArtists)) {
        resolve();
      } else {
        this.bigPayoutArtists.pipe(first(a => Array.isArray(a))).subscribe(() => resolve());
      }
    });
  }

  async startArtistConfirm(payout: NxtWorkSessionPayout) {
    const existingOtherArtistConfirm = this.myService.data.artistConfirms.find(a => a.state === 'pending' && a.artist !== payout.id);
    if (existingOtherArtistConfirm) {
      await this.dialogService.showOk('Warte bis Der Artist ' + existingOtherArtistConfirm.artist + ' bestätigt hat');
      return;
    }
    this.myService.log('Betrag-Bestätigungs-Link wird an ' + payout.id + ' geschickt');
    await this.socketService.createArtistConfirm({
      artist: payout.id,
      value: payout.value,
      workSessionDateString: this.myService.dateString,
      state: 'pending',
      earlyPayout: false,
    });
    // this.dialogService.showOk(payout.id + ' wurde eine Telegram-Nachricht geschickt, in dieser ins ein Link zum bestätigen des Umschlags');
  }

  async cancelArtistConfirm(payout: NxtWorkSessionPayout) {
    const toDelete = this.myService.data.artistConfirms.find(ac => ac.artist === payout.id);
    await this.socketService.cancelArtistConfirm(toDelete.id);
  }

  restartCreateInvoicesClicked() {
    const cashRegister = this.myService.data.workSession.cashRegisters.find(c => c.isMainCashRegister);
    cashRegister.state = NxtWorkSessionCashRegisterState._7_ArtistPayoutCanBook;
    this.myService.updateWorkSessionCashRegister(cashRegister);
  }
}
