import {ChangeDetectionStrategy, Component, computed, input, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NgClass, NgIf} from '@angular/common';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {MtxTooltip} from '@ng-matero/extensions/tooltip';
import {FromNowPipe} from '../../../pipes/from-now.pipe';
import {DateTools} from '../../../common-browser/helpers/date.tools';

@Component({
    selector: 'nxt-calendar-hour-segment',
    templateUrl: './calendar-hour-segment.component.html',
    styleUrls: ['./calendar-hour-segment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NxtDatePipe, NgIf, MtxTooltip, FromNowPipe]
})

export class CalendarHourSegmentComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/
  isTimeLabel = input.required<boolean>();
  dayStartHour = input.required<number>();
  daysInWeek = input.required<boolean>();
  daysCount = input.required<number>();
  date = input.required<Date>();
  segmentHeight = input.required<number>();
  isStart = input.required<boolean>();
  cssClass = input.required<string>();
  disableTooltip = input(false);

  showFromNowText = computed(() => {
    return DateTools.parse(this.date()) < Date.now().dateAddDays(30);
  });

  isFirstTimeLabel = computed(() => {
    return this.dayStartHour() === parseInt(this.date().dateFormat('HH'), 10);
  });

  isFullHour = computed(() => {
    return this.date().dateFormat('mm') === '00';
  });

  showYear = computed(() => {
    return DateTools.parse(this.date()).dateFormat('yyyy') !== Date.now().dateFormat('yyyy');
  });
  /*** Outputs ***/

  /*** Signals ***/
  /*tooltip = computed(() => {
    if (!this.isTimeLabel() || this.daysCount() === 1) {
      return '<div class="text-70">' + this.date().dateFormat('EEE dd.MM.yyyy') + '</div>' +
        '<br/>' + this.date().dateFormat('HH:mm');
    }
  });*/
  isToday = signal(false);

  /*** Injections ***/

  constructor() {
    super();
  }

  ngOnInit() {
    this.isToday.set(DateTools.isToday(this.date()));
  }

  nxtOnDestroy() {
  }
}
