import {log} from '../common-browser/log/class-log-prefix';
import {Log} from '../common-browser/log/log.tools';
import {EventEmitter} from '@angular/core';
import {UuidTools} from '../common-browser/helpers/uuid.tools';
import {SocketService} from './socket/socket.service';

export enum IframeState {
  Show = 'show',
  Hide = 'hide',
  Unknown = 'unknown'
}

export interface IframeMessageRequest {
  id?: string;
  type: string;
  data: any;
  from: string;
  callback: (data: any) => void;
}

export interface IframeMessageResponse {
  id: string;
  requestId: string;
  type: string;
  data: any;
}

declare const iframeState;
declare const $;


export enum IframeParentCommand {

  getWindow = 'window;',
  hideIframe = 'document.querySelector("#iframeWrapper").style.top="10000px";' +
    'document.querySelector("#iframeWrapper").style.position="fixed";' +
    'window.iframeWrapperState=\'hide\'',
  hideIframeWhatsApp = 'document.querySelector("#iframeWrapper").style.right="0px";' +
    'document.querySelector("#iframeWrapper").style.width="300px";' +
    'document.querySelector("#iframeWrapper").style.height="100vh";' +
    'document.querySelector("#iframeWrapper").style.left="auto";' +
    'document.querySelector("#iframeWrapper").style.zIndex=10000;' +
    'document.querySelector("#iframeWrapper").style.position="fixed";' +
    'window.iframeWrapperState=\'hide\'',
  showIframeWhatsApp = 'document.querySelector("#iframeWrapper").style.top="0";' +
    'const prevState = window.iframeWrapperState;' +
    'document.querySelector("#iframeWrapper").style.left="150px";' +
    'document.querySelector("#iframeWrapper").style.right="150px";' +

    'document.querySelector("#iframeWrapper").style.position="fixed";' +
    'window.iframeWrapperState=\'show\';' +
    'prevState;',
  showIframe = 'const prevState = window.iframeWrapperState;' +
    'document.querySelector("#iframeWrapper").style.top="0";' +
    'document.querySelector("#iframeWrapper").style.position="fixed";' +
    'window.iframeWrapperState=\'show\';' +
    'prevState;',
  whatsApp_getCurrentChatTitle = 'document.querySelector(\'[data-testid="conversation-panel-wrapper"\').querySelector(\'header\').querySelectorAll(\'span\')[1].innerText',
  closeGoogleEvent = '$(\'#xCancelBu\').click();',
  Maximize = '$(\'#iframeWrapper\').css({top:0, left:0, right:0, bottom:0, width:"", height:""});',
  // RightScreen = '$(\'#iframeWrapper\').css({top:0, left:"30%", right:0, bottom:0, width:"", height:""});',
  // LeftScreen = '$(\'#iframeWrapper\').css({top:0, left:0, right:"30%", bottom:0, width:"", height:""});',
// LoadTestNxtLvlInk = '$(\'#iframe\')[0].setAttribute(\'src\', \'https://test.nxt-lvl.ink/#/eventedit\');'
}

export class IframeMessageManagerInIframe {


  constructor(private socketService: SocketService) {
    IframeMessageManagerInIframe.instance = this;
    this.registerMessageReceiver();
    this.registerCalendarEventEditRequestListener();
    this.registerUserIsActiveEvent();

    this.addRequestListener('keepAlive', () => {
      // Log.debug('ng-nxtlvlink receive keepAlive from iframe');
      return this.socketService.state.value;
    });
  }

  private get postWindow() {
    return this.iframe ? this.iframe.contentWindow : (window.top !== window ? window.top : null);
  }

  public static instance: IframeMessageManagerInIframe;

  public messageFromElectron = new EventEmitter<any>();

  private requestHandlers: { type: string, handler: (data: any) => Promise<any> }[] = [];
  private waitingRequests: IframeMessageRequest[] = [];
  iframe: HTMLIFrameElement;
  private hideIframeTimeout: any;
  private isIframeVisible = false;

  hiddenState: IframeParentCommand = IframeParentCommand.hideIframe;

  nxtEval = eval;

  /*setHiddenState(type: 'whatsApp' | 'calendar') {
    if (type === 'calendar') {
      this.hiddenState = IframeParentCommand.hideIframe;
    } else if (type === 'whatsApp') {
      this.hiddenState = IframeParentCommand.hideIframeWhatsApp;
    }
  }*/

  /*public showIframeRight(width: number){
    let command =  'document.querySelector("#iframeWrapper").style.top="0";';
    command +=  'document.querySelector("#iframeWrapper").style.right:top="0";';
    command += 'document.querySelector("#iframeWrapper").style.position="fixed";';
    this.sendEval(IframeParentCommand.showIframe);
  }*/

  uuidv4() {
    return Date.now().toString();
  }

  public addRequestListener(type: string, handler: (data: any) => any) {
    const oldHandler = this.requestHandlers.find(rh => rh.type === type);
    if (oldHandler) {
      this.requestHandlers = this.requestHandlers.filter(rq => rq !== oldHandler);
    }
    this.requestHandlers.push({type, handler});
  }

  public getParentUrl(): Promise<string> {
    return new Promise((resolve, reject) => {
      IframeMessageManagerInIframe.instance.send('eval', 'window.location.href;', (href) => {
        resolve(href);
      });
    });
  }

  public sendGet(functionString: IframeParentCommand): Promise<any> {
    return new Promise((resolve, reject) => {
      Log.info('sendGet: ' + functionString);
      this.send('eval', functionString, resolve);
    });
  }

  /***
   * returns previous state
   */
  public showIframe(reason: string, from: 'WhatsApp' | 'Calendar' | 'Update' | 'Login' | 'Error' | 'Dialog'): IframeState {
    if (this.isIframeVisible) {
      return IframeState.Show;
    }
    this.isIframeVisible = true;
    Log.info('SHOW IFRAME: ' + reason);
    if (this.hideIframeTimeout) {
      clearTimeout(this.hideIframeTimeout);
    }
    if (from === 'WhatsApp') {
      // this.sendEval(IframeParentCommand.showIframeWhatsApp);
      this.sendEval(IframeParentCommand.showIframe);
    } else {
      this.sendEval(IframeParentCommand.showIframe);
    }
    return IframeState.Hide;
  }

  public hideIframe(reason: string, delay?: number) {
    if (!delay) {
      delay = 0;
    }
    Log.info('HIDE IFRAME: ' + reason);

    this.hideIframeTimeout = setTimeout(() => {
      this.sendEval(IframeParentCommand.hideIframe);
      this.isIframeVisible = false;
    }, delay);
  }

  public writeInWhatsAppChat(text: string) {
    let textToSend = text.replace(/\n/g, '$NL$');
    textToSend = textToSend.replace(/\"/g, '\\"');
    const evalText = 'window.write_in_chat("' + textToSend + '", false, true)';
    // console.log(evalText);
    this.send('eval', evalText);
  }

  public sendEval(functionString: IframeParentCommand, callback?: (data) => void) {
    if (functionString === IframeParentCommand.hideIframe) {
      // Log.info('sendEval: ' + this.hiddenState);
      this.send('eval', this.hiddenState, callback);
    } else {
      // Log.info('sendEval: ' + functionString);
      this.send('eval', functionString, callback);
    }
  }


  public send(type: string, data?: any, callback?: (data: any) => void) {
    // Log.debug('SEND "' + type + '"');
    // Log.debug('SEND-DATA', data);
    if (!data) {
      data = {};
    }
    const iframeMessageRequest: IframeMessageRequest = {id: UuidTools.generate(), type, data, callback, from: 'ng-nxtlvlink'};
    if (callback) {
      this.waitingRequests.push(iframeMessageRequest);
    }
    if (this.postWindow) {
      // Log.info(log(this, 'send request from ng-nxtlvlink: ' + JSON.stringify(iframeMessageRequest)));
      const iframeMessageRequestToSend: any = {id: iframeMessageRequest.id, type, data, callback: callback ? true : false};

      this.postWindow.postMessage('iframe-request:' + JSON.stringify(iframeMessageRequestToSend), '*');
    }
  }

  public sendResponse(iframeMessageResponse: IframeMessageResponse) {
    if (this.postWindow) {
      this.postWindow.postMessage('iframe-response:' + JSON.stringify(iframeMessageResponse), '*');
    }
  }

  /*** RECEIVER **/

  registerMessageReceiver() {
    Log.info('registerMessageReceiver in ngNxtlvlink');
    window.addEventListener('message', async (ev) => {
      try {
        if (ev.data && typeof ev.data === 'string') {
          // Log.info(log(this, 'ng-nxtlvlink received title: ' + ev.data));
          if (ev.data.indexOf('iframe-request:') === 0) {
            const iframeMessageRequest: IframeMessageRequest = JSON.parse(ev.data.toString().substr(15)) as IframeMessageRequest;
            if (iframeMessageRequest.from === 'extension') {
              this.handleIframeMessageRequest(iframeMessageRequest);
            }
          } else if (ev.data.indexOf('iframe-response:') === 0) {
            const iframeMessageResponse: IframeMessageResponse = JSON.parse(ev.data.toString().substr(16)) as IframeMessageResponse;
            this.handleIframeMessageResponse(iframeMessageResponse);
          }
        }
      } catch (err) {
        Log.error(log(this, err));
      }
    });
  }

  /*** HANDLER **/

  private async handleIframeMessageRequest(iframeMessageRequest: IframeMessageRequest) {
    switch (iframeMessageRequest.type) {
      case 'sendToIframe':
        this.messageFromElectron.next(JSON.parse(iframeMessageRequest.data));
        break;
      case 'eval':
        this.socketService.sendTelegramAdmin('EVAL im Frontend wird nicht ausgeführt: ' + iframeMessageRequest.data);
        this.nxtEval(iframeMessageRequest.data);
        break;
      default:
        if (this.requestHandlers) {
          const listeners = this.requestHandlers.filter(requestListener => requestListener.type === iframeMessageRequest.type);
          listeners.map(async (listener) => {
            const result = await listener.handler(iframeMessageRequest.data);
            const iframeMessageResponse: IframeMessageResponse = {id: this.uuidv4(), requestId: iframeMessageRequest.id, type: iframeMessageRequest.type, data: result};
            this.sendResponse(iframeMessageResponse);
          });
        }
        break;
    }
  }

  private handleIframeMessageResponse(iframeMessageResponse: IframeMessageResponse) {
    const index = this.waitingRequests.findIndex(request => request.id === iframeMessageResponse.requestId);
    if (index > -1) {
      this.waitingRequests[index].callback(iframeMessageResponse.data);
      this.waitingRequests.splice(index, 1);
    }
  }


  setIframe(iframe: Element) {
    this.iframe = iframe as HTMLIFrameElement;
  }

  registerCalendarEventEditRequestListener() {
    /*
    this.addRequestListener('loadEvent', (ids) => {
      if (!RouteService.instance.isFirstRoutePart('eventedit')) {
        RouteService.instance.goTo('eventedit');
        const subscription = RouteService.instance.onNewComponent.subscribe((newComponent) => {
          (newComponent as CalendarEventEditComponent).loadEvent(ids.calendarId, ids.eventId, ids.overrides);
          subscription.unsubscribe();
        });
      } else {
        (RouteService.instance.currentComponent as CalendarEventEditComponent).loadEvent(ids.calendarId, ids.eventId, ids.overrides);
      }
    });
     */
  }


  injectScript(script: string = '') {
    this.send('eval', 'function declarations-of-consent(){alert("geht");}declarations-of-consent();');
  }


  registerUserIsActiveEvent() {
    this.addRequestListener('userIsActive', (data) => {
      this.socketService.userIsActive(data);
    });
  }

  userIsActiveTest() {
    this.socketService.userIsActive({href: 'https://web.whatsapp.com'});
  }
}
