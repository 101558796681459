import {Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {LoginService} from './login.service';
import {DateTools} from '../common-browser/helpers/date.tools';
import {DurationTools} from '../common-browser/helpers/duration.tools';
import {DialogService} from './dialog.service';
import {ServerDate} from '../server-date';

@Injectable({
  providedIn: 'root',
})
export class ServerService {
  constructor(
    private socketService: SocketService,
    private loginService: LoginService,
    private dialogService: DialogService,
  ) {
    setTimeout(() => this.init());
  }


  wrongTimeCounter = 0;
  timeCheckIsRunning = false;

  private init() {
    this.checkTime();
  }

  private async checkTime() {
    let wait = 60000;
    if (this.socketService.state.value.authenticated && !this.timeCheckIsRunning) {
      this.timeCheckIsRunning = true;
      try {
        const before = Date.now();
        const serverTime = await this.socketService.getTime2();
        if (Date.now() - before > 1000) {
          // die Serveranfrage hat zu lange gedauert, weg werfen
          return;
        }
        ServerDate.serverDiff = serverTime - before;
        const timeDiff = Math.abs(serverTime - Date.now());
        if (timeDiff > DurationTools.DURATION_1MINUTE * 2) {
          this.wrongTimeCounter++;
        } else {
          this.wrongTimeCounter = 0;
        }
        if (this.wrongTimeCounter > 3) {
          const text = 'Die Uhrzeit an deinem PC ist falsch eingestellt, bitte richtig einstellen' +
            '\n\nDein PC: ' + DateTools.formatNow('dd.MM.yyyy HH:mm') +
            '\n\nRichtig:' + serverTime.dateFormat('dd.MM.yyyy HH:mm');
          this.socketService.sendTelegramAdmin('Falsche Uhrzeit bei ' + this.loginService.getUsername() + '\n' + DateTools.formatNow('dd.MM.yyyy HH:mm'));
          await this.dialogService.showOk(text);
          this.wrongTimeCounter = 0;
        }
      } catch (er) {
      }
      this.timeCheckIsRunning = false;
    } else {
      wait = 10000;
    }
    setTimeout(() => this.checkTime(), wait);
  }
}
