import {Component, Input, OnInit} from '@angular/core';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgStyle} from '@angular/common';

@Component({
    selector: 'nxt-divider',
    templateUrl: './divider.component.html',
    styleUrls: ['./divider.component.scss'],
    imports: [NgStyle, ExtendedModule]
})
export class DividerComponent implements OnInit {

  @Input() width: string;
  @Input() height: string;

  constructor() { }

  ngOnInit() {
  }

}
