import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, effect, inject, OnInit, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtPageComponent} from '../nxt-page/nxt-page.component';
import {NxtPageHeaderComponent} from '../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageHeaderTitleComponent} from '../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtPageContentComponent} from '../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {MatDialogRef} from '@angular/material/dialog';
import {SocketService} from '../../services/socket/socket.service';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtArtistRating1} from '../../common-interfaces/artist-rating-1.interface';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {ColorTools} from '../../common-browser/helpers/color.tools';
import {DialogService} from '../../services/dialog.service';
import {ArtistEditComponent} from '../../pages/artists/artist-edit/artist-edit.component';
import {CacheService} from '../../services/cache/cache.service';
import {SpinnerComponent} from '../spinner/spinner.component';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {keys} from '../../common-browser/helpers/object.tools';
import {firstValueFrom} from 'rxjs';
import {ArtistSkillTools} from '../../common-browser/helpers/artist-skill.tools';

@Component({
  selector: 'nxt-artist-ratings',
  templateUrl: './artist-ratings.component.html',
  styleUrls: ['./artist-ratings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtPageComponent, NxtPageHeaderComponent, NxtPageHeaderTitleComponent, NxtPageContentComponent, NxtPageFooterComponent, NxtDatagridComponent, SpinnerComponent, SlideToggleComponent]
})

export class ArtistRatingsComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  @ViewChild(NxtDatagridComponent) datagrid: NxtDatagridComponent;


  constructor() {
    super();
    effect(() => {
      const showOnlyTodos = this.showOnlyTodos();
      setTimeout(() => {
        if (!this.datagrid?.api) {
          return;
        }
        for (const columnDef of this.pivotColumnDefs().filter(c => c.field !== 'skill')) {
          const artist = columnDef.colId;
          console.log(artist);
          const data = this.rowData().find(r => r.artist === artist);
          let hasTodo = false;
          if (data) {
            for (const skill of keys(data.eventRatings)) {
              if (data.eventRatings[skill].todo.startsWith('must')) {
                hasTodo = true;
              }
            }
          }
          this.datagrid.api.setColumnsVisible([artist], hasTodo || !showOnlyTodos);
        }
      });
    });
  }


  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  rowData = signal<NxtArtistRating1[]>([]);

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  dialogService = inject(DialogService);
  cacheService = inject(CacheService);
  dialogRef = inject(MatDialogRef, {optional: true});


  columnDefs: NxtColDef<any>[] = [
    {headerName: 'Artist', field: 'artist', pivot: true},
  ];


  pivotColumnDefs = computed(() => {
    const columns: NxtColDef<any>[] = [];
    columns.push({headerName: 'Skill', field: 'skill', pinned: true});
    for (const row of this.rowData()) {
      columns.push({
        nxtCellStyle: {textAlign: 'center'},
        colId: row.artist,
        headerName: row.artist, valueGetter: params => {
          if (params.data[row.artist]) {
            return params.data[row.artist];
          }
        },
        valueFormatter: params => {
          if (params.value?.skillDiff) {
            return MathTools.round(params.value?.skillDiff, 1).toString();
          }
        },
        tooltipValueGetter: params => {
          if (params.value) {
            const artist = params.colDef.headerName;
            let text = 'Skill: ' + params.value.skill;
            text += '\nArtist: ' + params.value.artistSkill;
            text += '\nBewertung: ' + params.value.rating;
            text += '\nGrundlage: ' + params.value.events.length + ' Termine';
            const multiplier = params.value.skillDiff > 0 ? -1 : 1;
            let changeValue = 0;
            if (params.value.todo.startsWith('must')) {
              if (Math.abs(params.value.skillDiff) > 1) {
                changeValue = 2;
              } else {
                changeValue = 1;
              }
              if (changeValue) {
                text += '\n\nVORSCHLAG:\n' + artist + '\n' + params.value.skill + '\n' + params.value.artistSkill + ' ➞ ' + (params.value.artistSkill - (changeValue * multiplier));
              }
            }
            return text;
          }
        },
        cellStyle: params => {
          switch (params.value?.todo) {
            case 'mustDown':
              return {backgroundColor: ColorTools.Red, color: '#fff'};
            case 'canDown':
              return {color: ColorTools.Red};
            case 'mustUp':
              return {backgroundColor: ColorTools.Green, color: '#fff'};
            case 'canUp':
              return {color: ColorTools.Green};
          }
        },
        nxtOnCellDoubleClicked: params => {
          this.artistClicked(params.data.skill, params.colDef.headerName, params.data[params.colDef.headerName]);
        },
      });
    }
    return columns;
  });

  pivotData = computed(() => {
    const pivotData: any[] = [];

    for (const col of this.columnDefs.filter(c => c.field !== 'artist')) {
      const data = {
        skill: col.field,
      };
      for (const row of this.rowData()) {
        if (row.eventRatings[col.field]) {
          data[row.artist] = {...row.eventRatings[col.field], skill: col.field};
        }
      }
      pivotData.push(data);
    }
    return pivotData;
  });
  showOnlyTodos = signal(false);


  ngOnInit() {
    const temp = this.cacheService.artists.value;
    this.columnDefs.push(...this.getSkillColDefs());
    this.load();
  }

  nxtOnDestroy() {
  }

  async load() {
    const data = await this.socketService.calcArtistRating('2024-08-01', Date.now().dateAddDays(1).dateFormatDate());
    this.rowData.set(data);
    console.log(data);
    console.log(data);
    console.log(data);
  }

  getSkillColDefs() {
    const columns: NxtColDef<NxtArtistRating1>[] = [];
    for (const skill of ArtistSkillTools.skills) {
      columns.push({
        headerName: skill.description,
        field: skill.description as any,
        valueGetter: params => {
          if (params.data.eventRatings[skill.description]) {
            const skillDiff = params.data.eventRatings[skill.description].skillDiff;
            return MathTools.round(skillDiff, 1);
          }
        },
        tooltipValueGetter: params => {
          if (params.data.eventRatings[skill.description]) {
            const rating = params.data.eventRatings[skill.description].rating;
            const skillDiff = params.data.eventRatings[skill.description].skillDiff;
            const artistSkill = params.data.eventRatings[skill.description].artistSkill;
            const ratingCount = params.data.eventRatings[skill.description].ratings.length;
            return 'Artist: ' + artistSkill + '\n' + 'Bewertung: ' + rating + '\n' + 'Grundlage : ' + ratingCount + ' Termine';
          }
        },
      });
    }
    return columns;
  }

  private async artistClicked(skill: string, artist: string, data: any) {
    const artistId = this.cacheService.artists.value.find(a => a.name === artist)?.id;
    if (data.todo === 'mustDown' || true) {
      const prompt = Math.round(data.rating);
      const result = await this.dialogService.showInput(artist + '\n' + skill + '\nAktuell: ' + data.artistSkill + '\nBewertung: ' + data.rating, {placeholder: 'Neuer Wert', prompt});
      if (result) {
        await this.socketService.setArtistSkillFromRating(artistId, skill, result);
        this.load().then();
      }
    } else {
      const dialog = this.dialogService.showComponentDialog(ArtistEditComponent);
      dialog.componentInstance.setData(artistId).then();
      dialog.componentInstance.selectedIndex.set(ArtistEditComponent.TabIndex_Skills);
      await firstValueFrom(dialog.afterClosed());
      this.load().then();
    }

  }
}
