import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
  standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(text: string): any {
    if (text) {
      text = text.replace(/\n/g, '<br/>').replace(/\t/g, '&nbsp;&nbsp;');
      return this.sanitizer.bypassSecurityTrustHtml(text);
    }
    return '';
  }
}
