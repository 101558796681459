import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ElectronService {
  constructor() {
  }

  public flash() {
    const eCmdElem = document.querySelector(('#hiddenElectronCommand'));
    if (eCmdElem) {
      (eCmdElem as HTMLInputElement).value = '_flash';
    }
  }

  public show() {
    const eCmdElem = document.querySelector(('#hiddenElectronCommand'));
    if (eCmdElem) {
      (eCmdElem as HTMLInputElement).value = '_show';
    }
  }

  public openExternal(url: string) {
    if (!this.send('openExternal', [url])) {
      window.open(url, '_blank');
    }
  }

  public send(channel: string, args: any[]) {
    const eCmdElem = document.querySelector(('#hiddenElectronCommand'));
    if (eCmdElem) {
      if ((eCmdElem as HTMLInputElement).value) {
        return false;
      }
      (eCmdElem as HTMLInputElement).value = JSON.stringify({channel, args});
      return true;
    }
    return false;
  }

  public getApp() {
    const hiddenFieldApp = document.querySelector(('#nxtApp'));
    if (hiddenFieldApp) {
      return (hiddenFieldApp as HTMLInputElement).value;
    }
  }
}
