<div fxLayout="column" *ngIf="textTemplateDef">
  <div fxLayout="row" class="text-140 pb-4">{{ textTemplateDef.description }}</div>

  <div fxLayout="row" class="pb-3">
    <table *ngIf="textTemplateDef.availablePlaceholders || textTemplateDef.availableBlockConfigs">
      <tr *ngIf=" textTemplateDef.availablePlaceholders">
        <td>Mögliche Platzhalter:</td>
        <td>
          <div class="pl-4 consolas" fxLayout="column">
            <div class="flex flex-row items-center" *ngFor="let placeholder of textTemplateDef.availablePlaceholders">
              <mat-icon class="text-[18px] !w-[18px] !h-[18px] cursor-pointer" (click)="toClipboard('{{' + placeholder.placeholder + '}}')">content_copy</mat-icon>
              &nbsp;
              <div>{{ '&#123;&#123;' + placeholder.placeholder + '&#125;&#125;' }}</div>
            </div>
          </div>
        </td>
      </tr>
      <tr style="height: 15px;" *ngIf="textTemplateDef.availablePlaceholders && textTemplateDef.availableBlockConfigs">
      </tr>
      <tr *ngIf=" textTemplateDef.availableBlockConfigs">
        <td>Mögliche Blöcke:</td>
        <td>
          <div class="pl-4 consolas" fxLayout="column">
            <div *ngFor="let config of textTemplateDef.availableBlockConfigs">
              {{ '{{' + config.placeholder + '-start:Wert1,Wert2 }}'}} ... {{ '{{' + config.placeholder + '-end }}'}}
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf=" textTemplateDef.availableVariables">
        <td>Mögliche Variable:</td>
        <td>
          <div class="pl-4 consolas" fxLayout="column">
            <div *ngFor="let config of textTemplateDef.availableVariables">
              {{ '[' + config.name + ':Wert1,Wert2]' }}
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div fxLayout="column" style="overflow: hidden">
    <div fxLayout="column" style="overflow: auto">
      <div *ngFor="let textTemplate of textTemplates; let i = index" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxLayout="column" fxLayoutAlign="center center">
            <nxt-autocomplete
              style="width: 182px;"
              [options]="langs"
              [displayWith]="displayWith"
              [displayInOptionWith]="displayWith"
              placeholder="Sprache"
              [maxOptionsToShow]="999"
              [(value)]="textTemplate.lang"
              valueField="value"
              [filterFields]="['text']"
              [bottomNoPadding]="true"
            >
            </nxt-autocomplete>
            <nxt-slide-toggle [(value)]="textTemplate.disabled">Deaktiviert</nxt-slide-toggle>
            <div class="pt-3">
              <nxt-button *ngIf="i > 0" (click)="delete(i)">Löschen</nxt-button>
            </div>
          </div>
          <nxt-textarea style="width: 50vw" [(value)]="textTemplate.text" [rows]="10" [spellcheck]="false"></nxt-textarea>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="textTemplates && textTemplates.length === 0">Noch kein Text hinterlegt</div>
  <div fxLayout="row" class="w-full pt-4" fxLayoutAlign="space-around">
    <nxt-button (click)="close()">Abbrechen</nxt-button>
    <nxt-button (click)="addTextTemplate()">neuer Text</nxt-button>
    <nxt-button nxtPermission="IsNotAc" (click)="importFromAc()">Aus Aachen importieren</nxt-button>
    <nxt-button (click)="save()">Speichern</nxt-button>
  </div>
</div>
