<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>Bank</nxt-page-header-title>
    <nxt-input class="w-[12rem]" [(value)]="quickFilterText" placeholder="Suche" [clearIcon]="true"></nxt-input>
  </nxt-page-header>
  <nxt-page-content>
    <nxt-datagrid
      [columnDefs]="columnDefs"
      [rowData]="bookingAccounts()"
      [quickFilterText]="quickFilterText()"
      [columnFilters]="true"
      [showFooter]="true"
      [loadingSpinner]="isLoading()"
    >
    </nxt-datagrid>
  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>



