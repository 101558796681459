export class CountryTools {

    /*** Alpha-2-code **/
    static list: { code: string, name: string, eu?: boolean }[] = [
        {code: 'AE', name: 'Vereinigte Arabische Emirate'},
        {code: 'AL', name: 'Albanien'},
        {code: 'AM', name: 'Armenien'},
        {code: 'AR', name: 'Argentinien'},
        {code: 'AT', name: 'Österreich', eu: true},
        {code: 'AU', name: 'Australien'},
        {code: 'AZ', name: 'Aserbaidschan'},
        {code: 'BA', name: 'Bosnien und Herzegowina'},
        {code: 'BD', name: 'Bangladesch'},
        {code: 'BE', name: 'Belgien', eu: true},
        {code: 'BG', name: 'Bulgarien', eu: true},
        {code: 'BH', name: 'Bahrain'},
        {code: 'BO', name: 'Bolivien'},
        {code: 'BR', name: 'Brasilien'},
        {code: 'BS', name: 'Bahamas'},
        {code: 'BY', name: 'Belarus'},
        {code: 'BZ', name: 'Belize'},
        {code: 'CA', name: 'Kanada'},
        {code: 'CH', name: 'Schweiz'},
        {code: 'CL', name: 'Chile'},
        {code: 'CO', name: 'Kolumbien'},
        {code: 'CR', name: 'Costa Rica'},
        {code: 'CU', name: 'Kuba'},
        {code: 'CY', name: 'Zypern', eu: true},
        {code: 'CZ', name: 'Tschechische Republik', eu: true},
        {code: 'DE', name: 'Deutschland', eu: true},
        {code: 'DK', name: 'Dänemark', eu: true},
        {code: 'DO', name: 'Dominikanische Republik'},
        {code: 'EC', name: 'Ecuador'},
        {code: 'EE', name: 'Estland', eu: true},
        {code: 'EG', name: 'Ägypten'},
        {code: 'ES', name: 'Spanien', eu: true},
        {code: 'ET', name: 'Äthiopien'},
        {code: 'FI', name: 'Finnland', eu: true},
        {code: 'FR', name: 'Frankreich', eu: true},
        {code: 'GB', name: 'Vereinigtes Königreich', eu: true},
        {code: 'GE', name: 'Georgien'},
        {code: 'GH', name: 'Ghana'},
        {code: 'GR', name: 'Griechenland', eu: true},
        {code: 'GT', name: 'Guatemala'},
        {code: 'GY', name: 'Guyana'},
        {code: 'HK', name: 'Hongkong'},
        {code: 'HN', name: 'Honduras'},
        {code: 'HR', name: 'Kroatien', eu: true},
        {code: 'HT', name: 'Haiti'},
        {code: 'HU', name: 'Ungarn', eu: true},
        {code: 'ID', name: 'Indonesien'},
        {code: 'IE', name: 'Irland', eu: true},
        {code: 'IL', name: 'Israel'},
        {code: 'IN', name: 'Indien'},
        {code: 'IQ', name: 'Irak'},
        {code: 'IR', name: 'Iran'},
        {code: 'IS', name: 'Island'},
        {code: 'IT', name: 'Italien', eu: true},
        {code: 'JM', name: 'Jamaika'},
        {code: 'JO', name: 'Jordanien'},
        {code: 'JP', name: 'Japan'},
        {code: 'KE', name: 'Kenia'},
        {code: 'KH', name: 'Kambodscha'},
        {code: 'KR', name: 'Südkorea'},
        {code: 'KW', name: 'Kuwait'},
        {code: 'KY', name: 'Kaimaninseln'},
        {code: 'KZ', name: 'Kasachstan'},
        {code: 'LA', name: 'Laos'},
        {code: 'LB', name: 'Libanon'},
        {code: 'LK', name: 'Sri Lanka'},
        {code: 'LT', name: 'Litauen', eu: true},
        {code: 'LU', name: 'Luxemburg', eu: true},
        {code: 'LV', name: 'Lettland', eu: true},
        {code: 'MA', name: 'Marokko'},
        {code: 'MN', name: 'Mongolei'},
        {code: 'MT', name: 'Malta', eu: true},
        {code: 'MV', name: 'Malediven'},
        {code: 'MX', name: 'Mexiko'},
        {code: 'MY', name: 'Malaysia'},
        {code: 'NG', name: 'Nigeria'},
        {code: 'NI', name: 'Nicaragua'},
        {code: 'NL', name: 'Niederlande', eu: true},
        {code: 'NO', name: 'Norwegen'},
        {code: 'NZ', name: 'Neuseeland'},
        {code: 'OM', name: 'Oman'},
        {code: 'PA', name: 'Panama'},
        {code: 'PE', name: 'Peru'},
        {code: 'PH', name: 'Philippinen'},
        {code: 'PK', name: 'Pakistan'},
        {code: 'PL', name: 'Polen', eu: true},
        {code: 'PT', name: 'Portugal', eu: true},
        {code: 'PY', name: 'Paraguay'},
        {code: 'QA', name: 'Katar'},
        {code: 'RO', name: 'Rumänien', eu: true},
        {code: 'RS', name: 'Serbien'},
        {code: 'RU', name: 'Russland'},
        {code: 'SA', name: 'Saudi-Arabien'},
        {code: 'SE', name: 'Schweden', eu: true},
        {code: 'SG', name: 'Singapur'},
        {code: 'SK', name: 'Slowenien', eu: true},
        {code: 'SI', name: 'Slowakei', eu: true},
        {code: 'SR', name: 'Suriname'},
        {code: 'SV', name: 'El Salvador'},
        {code: 'TH', name: 'Thailand'},
        {code: 'TN', name: 'Tunesien'},
        {code: 'TR', name: 'Türkei'},
        {code: 'TT', name: 'Trinidad und Tobago'},
        {code: 'TW', name: 'Taiwan'},
        {code: 'TZ', name: 'Tansania'},
        {code: 'UA', name: 'Ukraine'},
        {code: 'UG', name: 'Uganda'},
        {code: 'US', name: 'Vereinigte Staaten'},
        {code: 'UY', name: 'Uruguay'},
        {code: 'UZ', name: 'Usbekistan'},
        {code: 'VE', name: 'Venezuela'},
        {code: 'VN', name: 'Vietnam'},
        {code: 'ZA', name: 'Südafrika'},
        {code: 'ZM', name: 'Sambia'},
        {code: 'ZW', name: 'Simbabwe'}
    ];

    private static _codes: string[];
    private static _name: string[];

    static get codes() {
        if (!CountryTools._codes) {
            CountryTools._codes = CountryTools.list.map(c => c.code);
        }
        return CountryTools._codes;
    }

    static get names() {
        if (!CountryTools._name) {
            CountryTools._name = CountryTools.list.map(c => c.name);
        }
        return CountryTools._name;
    }

    static isEu(code: string) {
        return !!CountryTools.list.find(c => c.code === code)?.eu;
    }

    static getNameByCode(code: string) {
        return CountryTools.names[CountryTools.codes.indexOf(code)];
    }

    static getCodeByName(code: string) {
        return CountryTools.codes[CountryTools.names.indexOf(code)];
    }
}
