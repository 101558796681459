import {ChangeDetectionStrategy, Component, computed, effect, input, OnInit, output, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtDatePipe} from '../../../../pipes/nxt-date-pipe';
import {NgForOf, NgStyle} from '@angular/common';
import {FromNowPipe} from '../../../../pipes/from-now.pipe';
import {PublicHolidaysTools} from '../../../../common-browser/helpers/public-holidays.tools';
import {NxtDailyNote} from '../../../../common-interfaces/daily-note.interface';
import {DateTools} from '../../../../common-browser/helpers/date.tools';
import {ConfigService} from '../../../../services/config.service';
import {NxtEmployeeEvent} from '../../../../common-interfaces/nxt.employee.interface';
import {SafeHtmlPipe} from '../../../../pipes/safe-html.pipe';
import {DayOfWeek} from '../../../../common-interfaces/date.interface';

export interface CalendarHeaderDay {
  isPast: boolean;
  isToday: boolean;
  isFuture: boolean;
  isWeekend: boolean;
  cssClass: string;
  date: Date;
}

@Component({
  selector: 'nxt-employee-calendar-header',
  templateUrl: './employee-calendar-header.component.html',
  styleUrls: ['./employee-calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtDatePipe, NgStyle, NgForOf, FromNowPipe, SafeHtmlPipe],
})
export class EmployeeCalenderHeaderComponent extends NxtComponent implements OnInit, NxtOnDestroy {


  /*** Injections ***/

  constructor(
    private configService: ConfigService,
  ) {
    super();
    effect(() => {

      if (this.events().length === 0) {
        return {};
      }
      const dayCheckRed = {};
      const dayCheckOrange = {};
      for (const day of this.days()) {
        if (DateTools.getDayOfWeek(day.date) === DayOfWeek.Sunday && !this.configService.config.value.workOnSunday) {
          continue;
        }
        const events = this.events().filter(e => e.dateString === day.date.dateFormatDate());
        if (events.length === 0) {
          return {};
        }
        if (this.configService.config.value.studioRegion === 'AC') {
          const redLines: string[] = [];
          const orangeLines: string[] = [];
          const receptionAc = events.filter(e => e.studio === 'ac' && e.workplaces.includes('reception'));
          const receptionDu = events.filter(e => e.studio === 'du' && e.workplaces.includes('reception'));
          const receptionDo = events.filter(e => e.studio === 'do' && e.workplaces.includes('reception'));
          const whatsApp = events.filter(e => e.workplaces.includes('whatsapp'));
          const whatsAppFranchise = events.filter(e => e.workplaces.includes('whatsappFranchise'));
          const eventRating = events.filter(e => e.workplaces.includes('eventRating'));
          if (receptionAc.length === 0) {
            redLines.push('Empfang AC fehlt');
          }
          if (receptionAc.length > 2) {
            orangeLines.push('Empfang AC evtl. zu viel?');
          }
          if (receptionDu.length === 0) {
            redLines.push('Empfang DU fehlt');
          }
          if (receptionDo.length === 0) {
            redLines.push('Empfang DO fehlt');
          }
          if (whatsApp.length === 0) {
            redLines.push('WhatsApp fehlt');
          }
          if (whatsAppFranchise.length === 0) {
            if (events.some(e => e.employeeName.startsWith('Mandy') && e.type === 'vacation')) {
              redLines.push('WhatsApp Franchise fehlt');
            }
          }
          if (eventRating.length < 2 && day.date.getTime() > '2024-09-17'.dateParse()) {
            redLines.push('Terminbewertung zu wenig');
          }
          dayCheckRed[day.date.dateFormatDate()] = redLines.join('\n');
          dayCheckOrange[day.date.dateFormatDate()] = orangeLines.join('\n');
        }
      }
      this.dayCheckRed.set(dayCheckRed);
      this.dayCheckOrange.set(dayCheckOrange);
    }, {allowSignalWrites: true});
  }

  /*** Inputs ***/
  days = input.required<CalendarHeaderDay[]>();
  events = input.required<NxtEmployeeEvent[]>();
  employeesFreeDays = input.required<{ employeeId: string, giveName: string, familyName: string, freeDays: DayOfWeek[] }[]>();

  /*** Outputs ***/
  dateHeaderClicked = output<string>();
  dailyNoteClicked = output<NxtDailyNote>();

  /*** Signals ***/
  employeesFreeDaysByDateString = computed(() => {
    const byDateString: { [dateString: string]: string[] } = {};
    for (const day of this.days()) {
      for (const e of this.employeesFreeDays()) {
        if (e.freeDays.includes(DateTools.getDayOfWeek(day.date))) {
          if (!byDateString[day.date.dateFormatDate()]) {
            byDateString[day.date.dateFormatDate()] = [];
          }
          byDateString[day.date.dateFormatDate()].push(e.giveName);
        }
      }
    }
    return byDateString;
  });

  publicHolidays = computed<{ [dateString: string]: string }>(() => {
    const publicHolidays = {};
    for (const day of this.days()) {
      const text = PublicHolidaysTools.getPublicHoliday(day.date.dateFormatDate(), this.configService.config.value.invoiceData.fromRegion);
      if (text) {
        publicHolidays[day.date.dateFormat('yyyy-MM-dd')] = text;
      }
    }
    return publicHolidays;
  });

  thisYear = DateTools.formatNow('yyyy');

  dayCheckRed = signal<{ [dayString: string]: string }>({});
  dayCheckOrange = signal<{ [dayString: string]: string }>({});


  ngOnInit() {
  }

  nxtOnDestroy() {
  }
}
