<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title [nxtMultiClick]="3" (nxtOnMultiClick)="forceShowRatingAndArtist.set(!forceShowRatingAndArtist())">Tattoo-Bewertung
    </nxt-page-header-title>
    @if (showHeaderButtons()) {
      <div class="flex flex-col items-center justify-center gap-4 md:flex-row">
        <nxt-date-picker-2 [jumpDays]="true" [noPadding]="true" [(value)]="dateString"></nxt-date-picker-2>
        <div class="flex flex-col gap-2 md:flex-row md:gap-4">
          <nxt-slide-toggle [(value)]="showEventsOnlyWithPhotosOrVideos">Nur mit Tattoo-Fotos</nxt-slide-toggle>
          <nxt-slide-toggle [(value)]="showEventsOnlyToFewRatings" (valueChange)="showEventsOnlyToFewRatingsChanged()">zu wenig Bewertungen</nxt-slide-toggle>
          <nxt-slide-toggle [(value)]="showEventsOnlyWithoutMyCheck" (valueChange)="showEventsOnlyWithoutMyCheckChanged()">Ohne Bewertung von mir
          </nxt-slide-toggle>
        </div>
      </div>
    }
  </nxt-page-header>
  <nxt-page-content>
    <div class="flex flex-col nxt-page">
      @if (artist()) {
        <div class="flex w-full flex-row justify-center text-2xl">{{ artist() }}</div>
      }
      @if (!singleEventId()) {
        <div class="flex w-full flex-row justify-center">
          @if (eventsFiltered().length === 1) {
            <div>noch 1 Termin</div>
          } @else {
            <div>{{ (currentIndex() + 1) }} / {{ eventsFiltered().length }} Termine</div>
          }
        </div>
      }
      @if (eventsFiltered().length === 0) {
        <div class="flex h-full w-full flex-row items-center justify-center text-center text-3xl">
          @if (showEventsOnlyWithoutMyCheck()) {
            Keine Termine, die du noch nicht bewertet hast.
          } @else if (showEventsOnlyToFewRatings()) {
            Keine Termine ohne Bewertungen.
          } @else {
            Keine Termine zum Bewerten.
          }
        </div>
      }
      <div>
        @if (event(); as event) {
          <div class="flex w-full flex-col items-center">
            <div>
              <nxt-button-icon (click)="copyLinkClicked()">content_copy</nxt-button-icon>
            </div>
            @if (!singleEventId()) {
              <div class="mt-4 mb-4 flex flex-row items-center justify-center gap-8">
                <nxt-button-icon (click)="prevClicked()">arrow_back_ios_new</nxt-button-icon>
                <nxt-button-icon (click)="nextClicked()">arrow_forward_ios</nxt-button-icon>
              </div>
            }
            <div class="flex flex-col gap-2 md:flex-row">
              <table class="nxt-table">
                <tr>
                  <td>Wann</td>
                  <td>
                    {{ event.start | nxtDate: 'dd.MM.yyyy HH:mm' }} - {{ event.end | nxtDate: 'HH:mm' }}
                    @if (openerComponent !== 'CalendarEventEditComponent') {
                      <nxt-button class="mr-3" [smallButton]="true" (click)="showEventClicked()">Termin öffnen</nxt-button>
                    }
                  </td>
                </tr>
                <tr>
                  <td>Dauer</td>
                  <td>
                    <div class="flex flex-col">
                      <div>{{ event.end - event.start | duration2 }}</div>
                      @if (!event.closed) {
                        <div>läuft noch</div>
                      }
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Artist</td>
                  @if (showRatingAndArtist()) {
                    <td>{{ event.artist }}
                      @if (!artist()) {
                        <nxt-button class="mr-3" [smallButton]="true" (click)="showArtistClicked()">mehr vom Artist</nxt-button>
                      }
                    </td>
                  } @else {
                    <td>*</td>
                  }
                </tr>
                <tr>
                  <td>Artist bekommt</td>
                  <td>{{ event.artistTotalGet | money2 }}</td>
                </tr>
                <tr>
                  <td>Artist-Prozente</td>
                  @if (event.percentageAfterClosed && event.percentageAfterClosed.length > 0) {
                    <td>
                      @for (change of event.percentageAfterClosed; track change.t) {
                        <div>{{ change.t | nxtDate: 'HH:mm' }}: {{ change.u }} -> {{ change.v | money2 }}&nbsp;&nbsp;&nbsp;{{ change.p }}%</div>
                      }
                    </td>
                  } @else {
                    <td>noch nicht angepasst</td>
                  }
                </tr>
                <tr>
                  <td>Preis</td>
                  <td>{{ EventTools.getPriceText(event) }}</td>
                </tr>
                <tr>
                  <td>Stil</td>
                  <td>{{ EventTools.getSkillsCombined(event).join(' & ') }}</td>
                </tr>
                @for (text of bodyPutService.getTextFromEvent(event); track text) {
                  <tr>
                    <td colspan="2">{{ text }}</td>
                  </tr>
                }
              </table>
              @if (event.ratingValue && showRatingAndArtist()) {
                <div class="flex flex-row justify-center">
                  <table class="nxt-table">
                    <tr>
                      <td class="text-center" colspan="4" [nxtMultiClick]="3" (nxtOnMultiClick)="showRatingLogClicked()">Aktuelle Bewertung
                        @if (showRatingAndArtist()) {
                          <div class="text-xl font-bold">{{ event.ratingValue | nxtRound: 1 }}</div>
                        } @else {
                          <div class="text-xl font-bold">*</div>
                        }
                      </td>
                    </tr>
                    @if (allRatings().length > 0) {
                      <tr>
                        <td>von</td>
                        <td>Bewertung</td>
                        <td>Info</td>
                        <td nxtPermission="EventRating_Delete"></td>
                      </tr>
                      @for (rating of allRatings(); track rating.username) {
                        <tr>
                          <td>{{ rating.username }}</td>
                          @if (showRatingAndArtist()) {
                            <td class="text-center">{{ rating.value }}</td>
                          } @else {
                            <td class="text-center">*</td>
                          }
                          <td>{{ rating.comment }}</td>
                          <td nxtPermission="EventRating_Delete">
                            <mat-icon class="cursor-pointer" (click)="deleteRatingClicked($index)">delete</mat-icon>
                          </td>
                          @if (ratingsLog[rating.username]) {
                            <td>
                              @for (log of ratingsLog[rating.username]; track log) {
                                <nxt-button class="m-2" [smallButton]="true" (click)="showRatingLogDetails(log)">{{ log.end - log.start | duration2: false }}
                                </nxt-button>
                              }
                            </td>
                          }
                        </tr>
                      }
                    }
                  </table>
                </div>
              }
            </div>
          </div>
          @if (loading()) {
            <div class="flex w-full flex-row items-center justify-center h-[220px]">loading...</div>
          } @else {
            <div class="outer-photo-container">

              <div class="gap-5 photo-container"
                   nxtPhotoSwipe
                   (nxtPhotoSwipeOnOpen)="openImage($event)"
                   (nxtPhotoSwipeOnClose)="closeImage($event)"
                   [nxtPhotoSwipeShowEditIcon]="true"
                   (nxtPhotoSwipeOnEditClicked)="eventFileEditClicked($event)"
              >
                @for (eventFile of imagesAndVideos(); track eventFile.id) {
                  <div class="flex flex-col items-center justify-center">
                    <div class="whitespace-nowrap">
                      <div class="text-sm file-header">{{ eventFile.subType | nxtDriveFileSubType }}</div>
                      <!--<div class="text-xs file-header">{{ (eventFile.fileCreatedAt || eventFile.createdAt) | nxtDate: 'dd.MM.yy HH:mm' }}</div>-->
                    </div>
                    @if (eventFile.type === 'image') {
                      <a
                        class="flex h-full items-center justify-center"
                        [attr.data-pswp-width]="eventFile.width"
                        [href]="eventFile.id | nxtEventFile"
                        [id]="eventFile.id"
                        [attr.data-pswp-height]="eventFile.height"
                      >
                        <img style="max-height: 180px; max-width: 135px; height: fit-content"
                             [src]="eventFile.id | nxtEventFile: true: eventFileThumbReloadIndicator()"/>
                      </a>
                    } @else if (eventFile.type === 'video') {
                      <img

                        (click)="videoClicked(eventFile)"
                        style="max-height: 180px; max-width: 135px; height: fit-content"
                        [src]="eventFile.id | nxtEventFile: true"
                      />
                    }
                    @if (additionImageText[eventFile.id]) {
                      <div [innerHTML]="additionImageText[eventFile.id] | safeHtml"></div>
                    }
                  </div>
                }
              </div>
            </div>
          }

          @if (!hasRatingFromMe()) {
            <div class="mt-4 flex w-full flex-col items-center justify-center p-1">
              <mat-button-toggle-group [(value)]="myRatingValue" (valueChange)="changed = true">
                <mat-button-toggle [value]="1">1</mat-button-toggle>
                <mat-button-toggle [value]="2">2</mat-button-toggle>
                <mat-button-toggle [value]="3">3</mat-button-toggle>
                <mat-button-toggle [value]="4">4</mat-button-toggle>
                <mat-button-toggle [value]="5">5</mat-button-toggle>
                <mat-button-toggle [value]="6">6</mat-button-toggle>
                <mat-button-toggle [value]="7">7</mat-button-toggle>
                <mat-button-toggle [value]="8">8</mat-button-toggle>
                <mat-button-toggle [value]="9">9</mat-button-toggle>
                <mat-button-toggle [value]="10">10</mat-button-toggle>
              </mat-button-toggle-group>
              <div class="flex flex-col items-center justify-center">
                <div>1&nbsp;&nbsp;Augenkrebs</div>
                <div>2&nbsp;&nbsp;Schrott schrott</div>
                <div>3&nbsp;&nbsp;Verkackt</div>
                <div>4&nbsp;&nbsp;Kann man nicht machen</div>
                <div>5&nbsp;&nbsp;Entspricht nicht unseren Ansprüchen</div>
                <div>6&nbsp;&nbsp;Entspricht gerade so unseren Ansprüchen</div>
                <div>7&nbsp;&nbsp;Gut gestochen mehr davon</div>
                <div>8&nbsp;&nbsp;Echt Gut!</div>
                <div>9&nbsp;&nbsp;Mega!</div>
                <div>10&nbsp;&nbsp;Endstufe</div>
              </div>
            </div>
            <div class="flex flex-row justify-center">
              <nxt-textarea placeholder="Info" [(value)]="myRatingComment" (valueChange)="changed = true" class="flex w-full md:w-1/2">
              </nxt-textarea>
            </div>
          }
          @if (!hasRatingFromMe()) {
            <div class="mt-4 mb-4 flex flex-row items-center justify-center gap-5">
              <nxt-button (click)="saveClicked(false)">Speichern</nxt-button>
              @if (!singleEventId()) {
                <nxt-button (click)="saveClicked(true)">Speichern + Nächstes</nxt-button>
              }
            </div>
          }
        }
      </div>
    </div>
  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>
