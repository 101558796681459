import {inject, Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {BehaviorSubject} from 'rxjs';
import {DateTools} from '../common-browser/helpers/date.tools';
import {NxtReminder} from '../common-interfaces/nxt.reminder.interface';
import {LoginService} from './login.service';
import {DurationTools} from '../common-browser/helpers/duration.tools';
import {WorkplaceTools} from '../common-browser/helpers/workplace.tools';
import {DialogService} from './dialog.service';
import {ReminderService} from './reminder.service';
import {ColorTools} from '../common-browser/helpers/color.tools';

interface NxtReminderTopBar extends NxtReminder {
  // color?: string;
  sortValue: number;
}


export interface TopBarItem {
  id: string;
  htmlContent: string;
}

@Injectable({
  providedIn: 'root',
})
export class TopBarService {

  private initDone = false;


  dialogService = inject(DialogService);
  reminderService = inject(ReminderService);

  get topBarItems() {
    if (!this.initDone) {
      this.init();
    }
    return this._topBarItems.asObservable();
  }

  private _topBarItems = new BehaviorSubject<TopBarItem[]>([]);
  socketService = inject(SocketService);
  loginService = inject(LoginService);

  itemCss = {
    minWidth: '150px',
    color: '#bbb',
    width: 'fit-content',
    padding: '0 5px 5px 5px',
    height: '100%',
    boxSizing: 'border-box',
    maxWidth: '300px',
    borderRadius: '4px',

    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // justifyContent: 'center',
    cursor: 'pointer',
  };

  textBoxCss = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  };

  textCss = {
    '-webkit-line-clamp': '3',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    lineHeight: '14px',
    fontSize: '12px',
    maxHeight: '48px',
  };

  headerCss = {
    padding: '2px',
    display: 'flex',
    fontSize: '12px',
  };
  private reminders: NxtReminderTopBar[] = [];
  private remindersFiltered: NxtReminderTopBar[] = [];


  constructor() {
  }

  init() {
    this.initDone = true;
    this.socketService.onAuthenticated.subscribe(() => this.loadFromServer());
    this.socketService.subscribeNew('onReminderUpdate', async () => {
      this.loadFromServer().then();
    }, {emitInitial: true});
    this.loadFromServer().then();
    setInterval(() => this.refreshView(), DurationTools.DURATION_1MINUTE);
    setInterval(() => this.loadFromServer().then(), DurationTools.DURATION_1MINUTE * 10);
    this.socketService.state.subscribe(() => this.refreshView());
  }

  async loadFromServer() {
    this.reminders = await this.socketService.getRemindersByDate({dateString: DateTools.formatNowDate(), appendRef: true, withOldOpen: true});
    if (this.reminders) {
      this.reminders.sort(NxtReminder.sortReminders);
      this.refreshView();
    }
  }

  startTest() {
    for (const reminder of this.remindersFiltered) {
      if (NxtReminder.GetReminderColor(reminder) === ColorTools.RedDark) {
        this.highlightReminder(reminder);
      }
    }
  }

  highlightReminder(reminder: NxtReminderTopBar) {
    return new Promise<void>((resolve, reject) => {
      const elem = document.querySelector('#reminder_' + reminder.id) as HTMLDivElement;
      elem.style.transform = 'scale(3) translate(33%, 33%)';
      elem.style.transition = 'transform 400ms ease-in-out';
      setTimeout(() => {
        elem.style.transform = '';
        resolve();
      }, 400);
    });
  }

  getHtmlContent(reminder: NxtReminder) {
    const item = $('<div id="reminder_' + reminder.id + '"></div>');
    item.css(this.itemCss);
    let backgroundColor = NxtReminder.GetReminderColor(reminder);
    if (!backgroundColor) {
      backgroundColor = '#424242';
    } else if (backgroundColor === ColorTools.RedDark) {
      item.addClass('reminder-overdue');
    }
    item.css('backgroundColor', backgroundColor);
    const time = reminder.reminderDateTime.dateFormat('HH:mm');
    let todoBy = reminder.toDoByUser;
    if (reminder.toDoByWorkplace) {
      todoBy = WorkplaceTools.getText(reminder.toDoByWorkplace);
    }
    const header = $('<div><div>' + todoBy + '</div><div>' + time + '</div></div>');
    header.css({
      padding: '0 3px',
      fontSize: '10px',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    });
    item.css(this.headerCss);
    const textBox = $('<div></div>');
    textBox.css(this.textBoxCss);
    const text = $('<div>' + reminder.description.replaceAll('\n', '<br/>') + '</div>');
    text.css(this.textCss);
    textBox.append(text);
    item.append(header);
    item.append(textBox);
    return item.prop('outerHTML');
  }

  private refreshView() {
    this.filterReminders();
    const items: TopBarItem[] = [];
    for (const reminder of this.remindersFiltered) {
      items.push({
        id: reminder.id,
        htmlContent: this.getHtmlContent(reminder),
      });
    }
    if (this.socketService.state.getValue().authenticated) {
      this._topBarItems.next(items);
    } else {
      this._topBarItems.next([]);
    }
  }

  private filterReminders() {
    const username = this.loginService.getUsername();
    const workPlace = this.loginService.getWorkplace();
    if (this.reminders) {
      this.remindersFiltered = this.reminders.filter(reminder => {
        const todoByOk = reminder.toDoByUser === username || reminder.toDoByWorkplace === workPlace;
        const hideIfNotOverDueOk = !reminder.hideIfNotOverDue || (reminder.reminderDateTime - DurationTools.DURATION_1MINUTE * 15) < Date.now();
        return todoByOk && hideIfNotOverDueOk;
      });
    }
  }

  async itemClicked(id: string) {
    const reminder = this.reminders.find(r => r.id === id);
    return this.reminderService.reminderClicked(reminder);
  }
}
