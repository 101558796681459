export interface NxtBodyPut {
  id: string;
  children?: NxtBodyPut[];
  name: string;
  fullName?: string;
  price?: number;
  nameEn?: string;
  discountPrice?: number;
  durationFrom?: number;
  durationTill?: number;
}

export class BodyPutTools {

  static beautyBodyPuts: NxtBodyPut[] = [
    {id: 'g8Massage', name: 'G8-Massage', fullName: 'G8-Massage', price: 190, durationFrom: 45},
    {id: 'afterLipo', name: 'After Lipo', fullName: 'After Lipo', price: 180, durationFrom: 60},
    {id: 'massage', name: 'Massage', fullName: 'Massage', price: 140, durationFrom: 60},
    {id: 'kobido', name: 'Kobido', fullName: 'Kobido', price: 150, durationFrom: 60},
    {id: 'headSpa', name: 'Head Spa', fullName: 'Head Spa', price: 200, durationFrom: 90},
    {id: 'PregnancyMassage', name: 'Schwangerschaftsmassage', fullName: 'Schwangerschaftsmassage', price: 150, durationFrom: 60},
    {id: 'maderotherapie', name: 'Maderotherapie', fullName: 'Maderotherapie', price: 150, durationFrom: 45, durationTill: 60},
    {
      id: 'brasilLymph',
      name: 'Brasilianische Lymphdrainage',
      children: [
        {id: 'brasilLymph_fullBody', name: 'Full Body', fullName: 'Brasilianische Lymphdrainage - Full Body', price: 250, durationFrom: 45, durationTill: 60},
        {id: 'brasilLymph_face', name: 'Face', fullName: 'Brasilianische Lymphdrainage - Face', price: 130, durationFrom: 30},
        {id: 'brasilLymph_body_face', name: 'Body & Face', fullName: 'Brasilianische Lymphdrainage - Body & Face', price: 350, durationFrom: 75, durationTill: 90},
      ],
    },
    {
      id: 'microneedling',
      name: 'Microneedling',
      children: [
        {id: 'microNeedling_body', name: 'Body', fullName: 'Microneedling - Body', price: 300, durationFrom: 60},
        {id: 'microNeedling_scars', name: 'Narben', fullName: 'Microneedling - Narben', price: 150, durationFrom: 30},
      ],
    },
    {
      id: 'lipoLaster',
      name: 'Lipo Laser',
      children: [
        {id: 'lipoLaster_lowerBody', name: 'Unterkörper', fullName: 'Lipo Laser - Unterkörper', price: 130, durationFrom: 45},
        {id: 'lipoLaster_upperBody', name: 'Oberkörper', fullName: 'Lipo Laser - Oberkörper', price: 130, durationFrom: 45},
      ],
    },
  ];

  static toothGemBodyPuts: NxtBodyPut[] = [
    {id: 'gem1', name: '1 Stein', fullName: '1 Stein', price: 40, nameEn: '1 Gem'},
    {id: 'gem2', name: '2 Steine', fullName: '2 Steine', price: 80, nameEn: '2 Gems'},
    {id: 'gem3', name: '3 Steine', fullName: '3 Steine', price: 110, nameEn: '3 Gems'},
    {id: 'gem4', name: '4 Steine', fullName: '4 Steine', price: 150, nameEn: '4 Gems'},
    {id: 'butterfly1', name: 'Schmetterling', fullName: 'Schmetterling', nameEn: 'Butterfly', price: 80},
    {id: 'heart1', name: 'Herz', fullName: 'Herz', price: 40, nameEn: 'Heart'},
    {id: 'otherForm1', name: 'Formstein', fullName: 'Formstein', nameEn: 'Other Form', price: 50},
  ];

  static calcToothGemBodyPutPrice(bodyPutIds: string[]): number {
    return bodyPutIds.reduce((acc, id) => {
      const bodyPut = this.toothGemBodyPuts.find(bp => bp.id === id);
      return acc + (bodyPut?.price || 0);
    }, 0);
  }

  static getGermanPathBodyPutToothGem(id: string) {
    const item = BodyPutTools.toothGemBodyPuts.find(t => t.id === id);
    return item?.name || '';
  }

  static getEnPathBodyPutToothGem(id: string) {
    const item = BodyPutTools.toothGemBodyPuts.find(t => t.id === id);
    return item?.nameEn || '';
  }
}
