import {DateTools} from '../helpers/date.tools';
import {DecimalTools} from '../helpers/decimal.tools';
import {EventEmitter} from '../../common-browser-public/classes/event-emitter';

export class NxtLog {

  constructor(private name: string, private options?: { nxtConnection?: any, sendTelegram?: boolean, disabled?: boolean }) {
    if (this.options?.disabled) {
      return;
    }
    if (!this.blackListNames.includes(name)) {
      setTimeout(() => {
        NxtLog.Log.info('start NXT-LOG ' + name);
      }, 10);
    }
    this.start = Date.now();
    this.restartTimeout();
  }

  static Log = {
    info: console.info,
    error: console.error,
  };
  // public directTelegramLog = false;
  static onSendTelegram = new EventEmitter<string>();

  private timeoutWithoutLog = 30000;

  private start: number = 0;

  private logMessages: { timestamp: number, message: string }[] = [];
  private timeoutTimer: any;

  public onWriteToLog = new EventEmitter<string>();
  public directLog = true;

  blackListNames = [
    'client call "getTime"',
  ];

  hasError = false;

  private tab = '\t';

  public setTimeout(value: number) {
    if (this.options?.disabled) {
      return;
    }
    this.timeoutWithoutLog = value;
    this.restartTimeout();
  }

  public setLogTimeout(timeoutMillis: number) {
    if (this.options?.disabled) {
      return;
    }
    this.timeoutWithoutLog = timeoutMillis;
  }

  // onLogAfter = new EventEmitter<string>();

  public logAfter(message: string) {
    if (this.options?.disabled) {
      return;
    }
    // NxtLog.Log.info('NXT-LOG | ' + this.name + ' | ' + DateTools.formatNow('HH:mm:ss') + ' | ' + message);
    // this.onLogAfter.emit(message);
    if (this.directLog) {
      NxtLog.Log.info('NXT-DIRECT-LOG | ' + this.name + ' | ' + DateTools.formatNow('HH:mm:ss') + ' | ' + message);
    }
    this.logMessages.push({timestamp: Date.now(), message});
    // this.restartTimeout();
  }

  public error(message: string, error?: Error) {
    if (this.options?.disabled) {
      return;
    }
    this.hasError = true;
    NxtLog.Log.error(`nxtLog: [${this.name}] fehlgeschlagen\n${message}`, error);
    if (error) {
      this.logMessages.push({timestamp: Date.now(), message: `ERROR ERROR: ${message}\n${error}`});
    } else {
      this.logMessages.push({timestamp: Date.now(), message: `ERROR ERROR: ${message}`});
    }
    this.restartTimeout();
  }


  private getLogLines(): string[] {
    if (this.options?.disabled) {
      return [];
    }
    const logLines: string[] = [];
    let lastMessageStart = -1;
    let logLine = '';
    for (const message of this.logMessages) {
      logLine = this.tab + '[' + DateTools.format(message.timestamp, 'HH:mm:ss.SSS') + '] ';
      if (lastMessageStart > -1) {
        const duration = message.timestamp - lastMessageStart;
        logLine += this.tab + '[' + DecimalTools.roundToString(duration / 1000, 4) + ' s] ';
      }
      logLine += message.message;
      lastMessageStart = message.timestamp;
      logLines.push(logLine);
    }
    return logLines;
  }

  public writeToLog() {
    if (this.options?.disabled) {
      return;
    }
    if (this.blackListNames.includes(this.name)) {
      return;
    }
    if (this.logMessages.length > 0 || this.hasError) {
      const duration = Date.now() - this.start;
      if (duration > 100) {
        if (this.logMessages.length === 1 && (this.logMessages[0].message === 'NXT-LOG timedout!' || this.logMessages[0].message === 'wait for reloading finished')) {

        } else {
          const logMessage = this.getCompleteLogMessage();
          NxtLog.Log.info(logMessage);
          if (this.options?.sendTelegram) {
            NxtLog.onSendTelegram.emit(logMessage);
          }
          this.clearTimeoutTimeout();
          this.onWriteToLog.emit(logMessage);
        }
      }
    }
    this.logMessages.length = 0;

  }

  private totalSecondsString(): string {
    return DecimalTools.roundToString(DateTools.dateDiffToNow(this.start) / 1000, 4);
  }

  public getCompleteLogMessage() {
    const completeLogMessageLines: string[] = [];
    const totalSecondsString = this.totalSecondsString();

    completeLogMessageLines.push(`\nSTART: ${this.name} | TOTAL ${totalSecondsString} s`);
    if (this.options?.nxtConnection) {
      completeLogMessageLines.push(`nxtConnection: ${this.options?.nxtConnection.name}`);
    }
    return [...completeLogMessageLines, ...this.getLogLines(), `END NXT-LOG`].join('\n  ');
  }


  private timedOut() {
    // this.logAfter('NXT-LOG timedout!');
    this.writeToLog();
  }

  private restartTimeout() {
    this.clearTimeoutTimeout();
    this.timeoutTimer = setTimeout(() => this.timedOut(), this.timeoutWithoutLog);
  }

  private clearTimeoutTimeout() {
    if (this.timeoutTimer) {
      clearTimeout(this.timeoutTimer);
    }
  }
}
