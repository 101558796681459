import {inject, Injectable} from '@angular/core';
import {CalendarEventEdit2Component} from '../pages/calendar-event-edit/calendar-event-edit-2/calendar-event-edit-2.component';
import {DateTools} from '../common-browser/helpers/date.tools';
import {PiercingChangeBookComponent} from '../components/studio-cash-report/piercing-change-book/piercing-change-book.component';
import {firstValueFrom} from 'rxjs';
import {NxtCashPayment} from '../common-interfaces/nxt.cash-payment.interface';
import {UuidTools} from '../common-browser/helpers/uuid.tools';
import {LoginService} from './login.service';
import {DialogService} from './dialog.service';
import {CashPaymentService} from './cash-payment.service';
import {NxtNewEventData} from './calendar-event.service';
import {ConfigService} from './config.service';
import {SocketService} from './socket/socket.service';
import {SaleService} from './sale.service';
import {NxtSale} from '../common-interfaces/nxt.sale';
import {WithoutNxtDbFields} from '../common-interfaces/nxt.db-fields.interface';

@Injectable({
  providedIn: 'root',
})
export class WalkInService {

  loginService = inject(LoginService);
  dialogService = inject(DialogService);
  saleService = inject(SaleService);
  cashPaymentService = inject(CashPaymentService);
  configService = inject(ConfigService);
  socketService = inject(SocketService);

  constructor() {
  }

  async startWalkIn(calendarEventEdit2Component?: CalendarEventEdit2Component) {
    const newEventData = await this.getWalkInNewEventData();
    if (!newEventData) {
      return false;
    }
    if (!calendarEventEdit2Component) {
      calendarEventEdit2Component = this.dialogService.showComponentFull(CalendarEventEdit2Component).componentInstance;
    }
    calendarEventEdit2Component.loadEvent({newEventData}).then();
    /*if (data.articlePrice > 0) {
      calendarEventEdit2Component.afterClosed.pipe(first()).subscribe(async (eventClosedData) => {
        if (eventClosedData.op === 'created') {
          this.createCashIncomingAfterEventCreated('Piercingschmuck', data.articlePrice).then();
        }
      });
    }*/
  }

  async createCashIncomingAfterEventCreated(articleText: string, articlePrice: number, eventId: string) {
    const isPaypal = await this.dialogService.showYesNo(articleText + ' für ' + articlePrice.toMoneyString(), {yesText: 'PayPal', noText: 'Bar'});
    const articleId = articleText === 'Piercingschmuck' ? 'piercing_jewelry' : articleText;
    if (isPaypal) {
      const payPalTransaction = await this.saleService.selectPayPalTransaction();
      if (!payPalTransaction) {
        // erneut Fragen
        return this.createCashIncomingAfterEventCreated(articleText, articlePrice, eventId);
      }
      const sale: NxtSale = {
        id: UuidTools.generate(),
        value: articlePrice,
        createdAt: new Date(),
        createdAtDateString: DateTools.todayDateString,
        paypalTransaction: payPalTransaction,
        discountPercentage: 0,
        paymentMethod: 'paypal',
        description: articleText,
        eventId,
      };
      await this.socketService.addSale(sale);
    } else {
      const cashIncoming: WithoutNxtDbFields<NxtCashPayment> = {
        direction: 'in',
        articleId,
        createdAtDateString: DateTools.format(Date.now(), 'yyyy-MM-dd'),
        createdAt: Date.now(),
        createdBy: this.loginService.getUsername(),
        studio: this.loginService.getStudio(),
        // studioReal: this.loginService.getStudioReal(),
        description: articleText,
        _: false,
        value: articlePrice,
        vatPercentage: 19,
        eventId,
      };
      await this.cashPaymentService.upsert(cashIncoming);
    }
    this.dialogService.showOk('Buchung erstellt').then();
  }

  async getWalkInNewEventData() {
    // const hasPiercingArtistToday = await this.socketService.hasPiercingArtistToday();
    const piercingArtistsToday = await this.socketService.getPiercingArtistsToday();
    let result = {value: 'tattoo'};
    if (piercingArtistsToday.length > 0) {
      const buttons = [{value: 'piercing', text: 'Piercing'}, {value: 'tattoo', text: 'Tattoo'}];
      const hasToothGemArtistToday = piercingArtistsToday.some(a => a.toothGem);
      let title = 'Piercing oder Tattoo?';
      if (hasToothGemArtistToday) {
        title = 'Piercing, Tattoo oder Zahnschmuck?';
        buttons.push({value: 'tooth-gem', text: 'Zahnschmuck'});
      }
      result = await this.dialogService.showButtonChooserNew({
        buttonRows: [buttons],
        title,
        hideBackButton: true,
      });
    }
    if (result?.value) {
      if (result.value === 'piercing') {
        return this.getPiercingWalkInNewEventData(Date.now(), true);
      } else if (result.value === 'tooth-gem') {
        return this.getToothGemWalkInNewEventData(Date.now(), true);
      } else {
        return this.getTattooWalkInNewEventData();
      }
    }
  }

  private async getTattooWalkInNewEventData(): Promise<NxtNewEventData> {
    const start = DateTools.roundToNextMinute(Date.now(), 15);
    const end = start + (30 * 60 * 1000);
    const timeFromString = DateTools.format(start, 'HH:mm');
    const timeTillString = DateTools.format(end, 'HH:mm');
    return {
      dateString: DateTools.formatNowDate(),
      timeFromString,
      timeTillString,
      walkIn: true,
      workType: 'tattoo',
      openArtist: true,
    };
  }

  async getPiercingWalkInNewEventData(dateTime: number, walkIn: boolean): Promise<NxtNewEventData | undefined> {
    const dateString = dateTime.dateFormatDate();
    const isNew = (await this.dialogService.showButtonChooserNew({
      buttonRows: [[{value: true, text: 'Neu'}, {value: false, text: 'Wechsel'}]],
      title: 'Neu oder Wechsel?',
      hideBackButton: true,
    }))?.value;
    const day = await this.socketService.getAvailableArtistsDay(dateString);
    const piercingArtistsToday = day?.artists.filter(a => a.workType === 'piercing');
    if (isNew) {
      const start = DateTools.roundToNextMinute(dateTime, 15);
      const end = start + (15 * 60 * 1000);
      const timeFromString = DateTools.format(start, 'HH:mm');
      const timeTillString = DateTools.format(end, 'HH:mm');
      return {
        dateString,
        timeFromString,
        timeTillString,
        walkIn,
        workType: 'piercing',
        openPiercing: piercingArtistsToday.length !== 1,
        artist: piercingArtistsToday.length === 1 ? piercingArtistsToday[0].name : '',
      };
    } else {
      const dialog = this.dialogService.showComponentDialog(PiercingChangeBookComponent, {});
      const result = await firstValueFrom(dialog.afterClosed());
      if (result) {
        const start = DateTools.roundToNextMinute(dateTime, 15);
        const end = start + (15 * 60 * 1000);
        const timeFromString = DateTools.format(start, 'HH:mm');
        const timeTillString = DateTools.format(end, 'HH:mm');
        let artistPercentage = result.artistPercentage;
        if (piercingArtistsToday.length === 1) {
          const artist = await this.socketService.getArtistByName(piercingArtistsToday[0].name);
          if (artist.employed) {
            artistPercentage = 0;
          }
        }
        return {
          dateString,
          timeFromString,
          timeTillString,
          walkIn,
          workType: 'piercing',
          artistPercentage,
          bodyPutsPiercing: result.bodyPutsPiercing.map(bodyPutsStringArray => ({bodyPut: bodyPutsStringArray})),
          priceEstimatedFrom: result.eventPrice,
          info: result.title,
          openPiercing: piercingArtistsToday.length !== 1,
          artist: piercingArtistsToday.length === 1 ? piercingArtistsToday[0].name : '',
          toPayOnEventDate: [{text: 'Piercingschmuck', value: result.articlePrice, payed: false}],
        };
      }
    }
  }

  async getToothGemWalkInNewEventData(dateTime: number, walkIn: boolean): Promise<NxtNewEventData | undefined> {
    const dateString = dateTime.dateFormatDate();
    const day = await this.socketService.getAvailableArtistsDay(dateString);
    const toothGemArtistsToday = day?.artists.filter(a => a.workType === 'piercing' && a.toothGem);
    const start = DateTools.roundToNextMinute(dateTime, 15);
    const end = start + (15 * 60 * 1000);
    const timeFromString = DateTools.format(start, 'HH:mm');
    const timeTillString = DateTools.format(end, 'HH:mm');
    return {
      dateString,
      timeFromString,
      timeTillString,
      walkIn,
      workType: 'tooth-gem',
      openPiercing: toothGemArtistsToday.length !== 1,
      artist: toothGemArtistsToday.length === 1 ? toothGemArtistsToday[0].name : '',
      priceAuto: true,
    };
  }
}
