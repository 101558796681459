import {DateTools} from './date.tools';
import {MathTools} from './math.tools';

export enum Emoji {
  noPriceEstimatedFrom = '💰',
  noBodyPut = '🤷‍♂️',
  specialAction399 = '3️⃣9️⃣9️⃣',
  canceledMove = '🚫',
  noCustomer = '📵',
  restMissing = '📥',
  missingPaypal = '❓',
  depositMissing = '🔴',
  eventOK = '✔️',
  middleDotBlack = '▪',
  payoutMissing = '📤'
}


export class EmojiTools {

  private static niceEmojis = ['✅', '💪', '👌', '💖', '🔅', '😍', '🤩', '🤗', '🥳', '🤡', '🫶', '🎉', '🎊'];

  private static funnyEmojis = ['👻', '👽', '🎃', '🐹', '🐶', '🐣', '🌞', '🙂', '🤡'];

  private static helloEmojis = ['👋🏽', '🙋🏼‍♂️', '🫡', '🤝🏻', '👋🏽', '🤘🏼', '✌', '🙋🏼‍♀️', '🙋🏼‍♂️'];

  private static angryEmojis = ['😤', '😡', '🤬', '😳', '😨', '❌', '🚫', '🤦🏽‍♀️', '🤷🏽‍♂️'];
  private static times: { [time: number]: string };

  private static initTimes() {
    this.times = {};
    this.times[0] = '🕛';
    this.times[0.5] = '🕧';
    this.times[1] = '🕐';
    this.times[1.5] = '🕜';
    this.times[2] = '🕑';
    this.times[2.5] = '🕝';
    this.times[3] = '🕒';
    this.times[3.5] = '🕞';
    this.times[4] = '🕓';
    this.times[4.5] = '🕟';
    this.times[5] = '🕔';
    this.times[5.5] = '🕠';
    this.times[6] = '🕕';
    this.times[6.5] = '🕡';
    this.times[7] = '🕖';
    this.times[7.5] = '🕢';
    this.times[8] = '🕗';
    this.times[8.5] = '🕣';
    this.times[9] = '🕘';
    this.times[9.5] = '🕤';
    this.times[10] = '🕙';
    this.times[10.5] = '🕥';
    this.times[11] = '🕚';
    this.times[11.5] = '🕦';
    this.times[12] = '🕛';
  }

  public static getClock(date: number) {
    if (!this.times) {
      this.initTimes();
    }
    let hours = parseInt(DateTools.format(date, 'HH'), 10);
    const minutes = parseInt(DateTools.format(date, 'mm'), 10);

    if (hours >= 12) {
      hours -= 12;
    }

    if (minutes >= 30) {
      hours += 0.5;
    }
    return this.times[hours];
  }

  public static getNice(number = 1) {
    let result = '';
    for (let i = 0; i < number; i++) {
      result += MathTools.getRandomFromArray(EmojiTools.niceEmojis);
    }
    return result;
  }

  public static getAngry(number = 1) {
    let result = '';
    for (let i = 0; i < number; i++) {
      result += MathTools.getRandomFromArray(EmojiTools.angryEmojis);
    }
    return result;
  }

  public static getFunny(number = 1) {
    let result = '';
    for (let i = 0; i < number; i++) {
      result += MathTools.getRandomFromArray(EmojiTools.funnyEmojis);
    }
    return result;
  }

  public static getHello(number = 1) {
    let result = '';
    for (let i = 0; i < number; i++) {
      result += MathTools.getRandomFromArray(EmojiTools.helloEmojis);
    }
    return result;
  }


}
