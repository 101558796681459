import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {InventoryService} from './inventory.service';
import {LoginService} from './login.service';

@Injectable({
  providedIn: 'root'
})
export class ManualDataOrderService {

  constructor(
    private firestoreService: FirestoreService,
    private inventoryService: InventoryService,
    private loginService: LoginService
  ) {

  }

  async resetOrderValueInCollection(path: string, orderFieldName: string) {

    const docs = (await this.firestoreService.collection(path, (ref) => ref.orderBy(orderFieldName)).get().toPromise()).docs as any[];
    for (const [index, doc] of docs.entries()) {
      const data = doc.data();
      const newOrderValue = (index + 1) * 100000;
      if (data.orderValue !== newOrderValue) {
        data.orderValue = newOrderValue;
        await this.inventoryService.upsertInventoryItem(data, this.loginService.getUsername());
      }
    }
  }
}
