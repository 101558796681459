<div class="flex h-full flex-col border-1-red">
  <div>
    title
  </div>

  <div *ngIf="data" class="flex flex-row h-full">
    <nxt-datagrid
      [columnDefs]="outgoingsColDefs"
      [rowData]="data.notAssignedOutgoings"
      [autoSizeColumnOnResize]="true"
      rowSelection="single"
    >
    </nxt-datagrid>

    <nxt-datagrid>
    </nxt-datagrid>

  </div>


</div>
