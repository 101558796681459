import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NxtTypedFormControl} from '../../../nxt-form/nxt.typed-form-control';
import {NxtArticle} from '../../../common-interfaces/nxt.article.interface';
import {UuidTools} from '../../../common-browser/helpers/uuid.tools';
import {SocketService} from '../../../services/socket/socket.service';
import {ValidatorTools} from '../../../helpers/validator.tools';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {SlideToggleComponent} from '../../../components/form-controls/slide-toggle/slide-toggle.component';
import {NgIf} from '@angular/common';
import {InputComponent} from '../../../components/form-controls/input/input.component';
import {RadioComponent} from '../../../components/form-controls/radio/radio.component';
import {SmoothHeightComponent} from '../../../components/smooth-height.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {AutocompleteComponent} from '../../../components/form-controls/autocomplete/autocomplete.component';
import {ArticleTools} from '../../../common-browser/helpers/article.tools';
import {DisplayWithTools} from '../../../common-browser/helpers/display-with.tools';
import {PermissionDirective} from '../../../directives/permission.directive';

@Component({
  selector: 'nxt-article-edit',
  templateUrl: './article-edit.component.html',
  styleUrls: ['./article-edit.component.scss'],
  imports: [FlexModule, SmoothHeightComponent, RadioComponent, InputComponent, NgIf, SlideToggleComponent, NxtButtonComponent, AutocompleteComponent, PermissionDirective],
  standalone: true,

})

export class ArticleEditComponent implements OnInit {

  originalArticle: NxtArticle;

  constructor(
    private formBuilder: FormBuilder,
    private socketService: SocketService,
  ) {
  }

  form = new FormGroup({
    id: new NxtTypedFormControl('', [], 'ID'),
    name: new NxtTypedFormControl('', [ValidatorTools.requiredAndNotNaN], 'Name'),
    articleGroup: new NxtTypedFormControl('', [ValidatorTools.hasProperty('id')], 'Artikelgruppe'),
    vat: new NxtTypedFormControl<number | null>(null, [], 'MwSt.'),
    is_: new NxtTypedFormControl(false, [], 'Tresor'),
    postingAccountNo: new NxtTypedFormControl<string | null>(null, [], 'Buchungskonto'),
    disabled: new NxtTypedFormControl(false, [], 'Deaktiviert'),
    proposalValue: new NxtTypedFormControl<number | null>(null, [], 'Vorgeschlagener Betrag'),
    direction: new NxtTypedFormControl<'in' | 'out'>('in', [], 'Ein oder Ausgabe'),
    invoiceRequired: new NxtTypedFormControl(false, [], 'Rechnungs-Scan'),
  });
  articleGroupOptions = ArticleTools.articleGroups;

  protected readonly DisplayWithTools = DisplayWithTools;

  ngOnInit() {
  }

  fillForm(article: NxtArticle) {
    this.form.setValue(article);
  }

  async loadById(articleId: string) {
    this.originalArticle = await this.socketService.getArticle(articleId);
    if (this.originalArticle) {
      this.fillForm(this.originalArticle);
    } else {
      throw Error('article ' + articleId + ' not found');
    }
  }

  private loadEmptyArticle() {
    const article: NxtArticle = {
      id: UuidTools.generate(),
      proposalValue: null,
      is_: false,
      vat: 19,
      disabled: false,
      postingAccountNo: '',
      articleGroup: '',
      name: '',
      direction: 'in',
      invoiceRequired: false,
      createdAt: Date.now(),
      createdBy: '',
      updatedAt: Date.now(),
      updatedBy: '',
    };
    this.fillForm(article);
  }

  public newArticle() {
    this.loadEmptyArticle();
  }

  public save() {
    this.socketService.upsertArticle({...this.originalArticle, ...this.form.getRawValue()});
    console.log(this.form.value);
    console.log(this.form.value);
    console.log(this.form.value);
  }
}
