<div class="flex flex-col items-center justify-center tooltip">
  <div class="flex w-full flex-row justify-between time-artist" [ngStyle]="{backgroundColor: artistColor, color: color}">
    <div class="time">{{ event.start | nxtDate: 'HH:mm' }} - {{ event.end | nxtDate: 'HH:mm' }}</div>
    <div class="artist">{{ event.artist }}{{ event.artistFix ? ' (fix)' : '' }}</div>
  </div>
  <div class="flex w-full flex-col items-center p-2 content" [ngStyle]="{borderColor: artistColor}">

    @if (event.status === 'canceled') {
      <div class="text-xl no-wrap">erstellt: {{ event.createdAt | fromNowText2 }}</div>
    }

    <!--KUNDE-->
    <div class="text-xl no-wrap">{{ event?.customerObj?.fullName || '?' }}</div>

    <div class="text-xl no-wrap">bereits bezahlt: {{ event.paymentSum | money2 }}</div>

    <!--PREIS-->
    <div class="text-l">
      @if (event.priceFix) {
        <div>fix {{ event.priceEstimatedFrom | money }}</div>
      } @else {
        @if (event.priceEstimatedTill) {
          <div>{{ event.priceEstimatedFrom | money }} bis {{ event.priceEstimatedTill | money }}</div>
        } @else {
          <div>ca. {{ event.priceEstimatedFrom | money }}</div>
        }
      }
    </div>

    <div class="mt-1 flex w-full flex-col gap-1">
      @if (event.importantInfo) {
        <div class="flex w-full red-light">
          <div class="text-sm no-wrap">{{ event.importantInfo }}</div>
        </div>
      }
      @if (event.canceledReason) {
        <div class="text-sm no-wrap red">Absagegrund: {{ event.canceledReason }}</div>
      }
      <div></div>


      <div class="flex w-full">
        <div class="text-sm no-wrap" [innerHTML]="event | calendarBodyPuts: '- '"></div>
      </div>
    </div>
  </div>
</div>
