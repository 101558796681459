import {Component, Input, OnInit} from '@angular/core';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgStyle} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
  selector: 'nxt-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
  imports: [FlexModule, NgStyle, ExtendedModule],
  standalone: true,
})
export class RowComponent implements OnInit {

  @Input() height: string;

  @Input() width = '100%';

  constructor() {
  }

  ngOnInit() {
  }

}
