<div>
  <div class="pb-1">Stempelzeit</div>
  @if (originalEmployeeEvent) {
    <div nxtPermission="IsJulian" class="text-[60%]">{{ originalEmployeeEvent.id }}</div>
  }

  <div class="flex flex-row">
    <nxt-autocomplete
      [nxtFormControl]="form.controls.employee"
      [filterFields]="['fullName', 'id']"
      [displayInOptionWith]="displayWithEmployee"
      [displayWith]="displayWithEmployee"
      [options]="cacheService.employees | async"
    >
    </nxt-autocomplete>
  </div>
  <div class="flex flex-col w-full justify-around">
    <div class="flex flex-row justify-around">
      <nxt-time-picker
        [nxtFormControl]="form.controls.startTime"
      ></nxt-time-picker>
      <nxt-button class="self-center" [smallButton]="true" (click)="setTime('11:30', '20:00')">11:30 - 20:00</nxt-button>
      <nxt-time-picker
        [nxtFormControl]="form.controls.endTime"
      ></nxt-time-picker>
    </div>
    <div class="flex flex-row justify-around">
      <nxt-button [smallButton]="true" (click)="setTime('11:00', '15:15')">11:00 - 15:15</nxt-button>
      <nxt-button [smallButton]="true" (click)="setTime('15:45', '20:00')">15:45 - 20:00</nxt-button>
    </div>
  </div>
  <div class="flex flex-row mt-5 justify-between gap-3">
    <nxt-button (click)="closeClicked()">Abbrechen</nxt-button>
    <nxt-button (click)="deleteClicked()">Löschen</nxt-button>
    <nxt-button (click)="saveClicked()">Speichern</nxt-button>
  </div>
</div>
