<div [fxLayout]="direction" fxLayoutAlign="center center" class="nxt-slide-toggle" [ngClass]="{'no-color-change': noColorChange}">
  <div class="none-selection mouse-pointer no-wrap pr-2" *ngIf="leftText" (click)="textClicked()">{{ leftText }}</div>

  <mat-slide-toggle color="primary" [formControl]="nxtFormControl" (change)="toggleChanged($event.checked)">
    <ng-container *ngIf="!placeholder">
      <div class="pl-1">
        <ng-content></ng-content>
      </div>
    </ng-container>
    <div *ngIf="placeholder" class="pl-1" [innerHTML]="placeholder | safeHtml">
    </div>
  </mat-slide-toggle>
</div>

