import {Component, ElementRef, Inject, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {TypeTools} from '../../common-browser/helpers/type.tools';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {TimeButtonComponent} from './time-button/time-button.component';
import {NgFor} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtButtonIconComponent} from '../button-icon/nxt-button-icon.component';

@Component({
  selector: 'nxt-time-chooser',
  templateUrl: './time-chooser.component.html',
  styleUrls: ['./time-chooser.component.scss'],
  imports: [FlexModule, NgFor, TimeButtonComponent, SafeHtmlPipe, NxtButtonIconComponent]
})
export class TimeChooserComponent implements OnInit, OnChanges {

  @ViewChild('titleDiv', {static: false}) titleDivElem: ElementRef;
  title: string;
  textField: string;
  buttonRows: ({ text: string, value?: string }[] | string[])[];
  currentValue: string;
  showHourCounter = false;
  text: string;
  startHour = 0;
  hourCounter: any = {};

  constructor(
    public dialogRef: MatDialogRef<TimeChooserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.buttonRows = data.buttonRows;
    this.title = data.title;
    this.text = data.text;
    this.textField = data.textField;
    this.currentValue = data.value;
    this.showHourCounter = data.showHourCounter;
    this.startHour = parseInt(this.buttonRows[0][0].toString(), 10);
  }

  ngOnInit() {
    this.hourCounter = {};
    for (const buttonRow of this.buttonRows) {
      for (const button of buttonRow) {
        if (typeof button === 'string') {
          this.hourCounter[button] = this.getHourCounters(button);
        } else {
          this.hourCounter[button.text] = this.getHourCounters(button);
        }
      }
    }
  }


  buttonClicked(button: any) {
    this.dialogRef.close(button);
  }

  cancel() {
    this.dialogRef.close();
  }

  getHourCounters(button: { text: string; value?: string } | string) {
    let currentHour = parseInt(button.toString(), 10);
    if (currentHour === 0) {
      currentHour = 24;
    }
    if (this.showHourCounter) {
      if (TypeTools.isNumberAndNotNaN(currentHour - this.data.hourCounterStart)) {
        const full = (currentHour - this.data.hourCounterStart);
        const result = {
          full: full * DurationTools.DURATION_1HOUR,
          hourCounter15: (full + 0.25) * DurationTools.DURATION_1HOUR,
          hourCounter30: (full + 0.5) * DurationTools.DURATION_1HOUR,
          hourCounter45: (full + 0.75) * DurationTools.DURATION_1HOUR
        };
        if (result.full < 0) {
          result.full += DurationTools.DURATION_1DAY;
        }
        if (result.hourCounter15 < 0) {
          result.hourCounter15 += DurationTools.DURATION_1DAY;
        }
        if (result.hourCounter30 < 0) {
          result.hourCounter30 += DurationTools.DURATION_1DAY;
        }
        if (result.hourCounter45 < 0) {
          result.hourCounter45 += DurationTools.DURATION_1DAY;
        }
        return result;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    debugger;

  }
}
