@if (data(); as data) {
  <div class="flex flex-col font-montserrat-900 h-full tt-border" style="padding: 1cm 1cm 1cm 1cm; background-color: #ffffff">

    <div class="flex flex-row justify-center w-full">
      <img src="../../../../assets/images/tattoo-ticket/tattoo-ticket.svg" style="width: 70%;"/>
    </div>

    <div class="flex flex-col flex-grow justify-center items-center">
      <div class="name text-[300%]">{{ data.name }}</div>
      <div class="name text-[300%] text-[#b7860f]">{{ data.value | money2 }}</div>
    </div>


    <div class="flex flex-col justify-center items-center">
      <div style="font-size: 14px; letter-spacing: 2px; font-weight: 700; color: #3c3b3f; text-align: center">Ticket-Code: {{ data.code }}</div>
      <div class="text-[60%] text-center">
        <div>Bitte beachten: Dieser Code ist einzigartig und berechtigt zum Einlösen deines Tattoo-Tickets.</div>
        <div>Halte ihn sicher und gib ihn nicht an Dritte weiter, da das Ticket mit diesem Code eingelöst werden kann.</div>
      </div>
    </div>
    <div fxLayout="column" class="p-relative flex flex-column items-center"
         style="border-radius: 0.3cm; border: 2px solid #b7860f; background-color: white; padding: 8px">
      <div fxLayout="row" fxLayoutAlign="center" style="padding: 0.5cm;">
        <!--<img src="../../../../assets/logo/logo_black.svg" style="width: 6cm"/>-->
        <img [src]="'../../../../assets/logo/studio/logo_' + configService.config.value.studioRegion.toLowerCase() + '_dark.svg'" style="width: 6cm"/>

      </div>
      <div fxLayout="row" fxLayoutAlign="center" class="no-wrap"
           style="font-size: 14px; letter-spacing: 2px; font-weight: 900; color: #3c3b3f; text-align: center"
           [innerHTML]="configService.config.value.voucher.address"></div>
    </div>
  </div>
}
