<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>{{ currentView === 'created' ? 'Erstellte Termine' : 'Aktualisierte Termine' }}</nxt-page-header-title>
    <nxt-radio
      valueKey="value"
      direction="row"
      [(value)]="currentView"
      textKey="text"
      [options]="[{text: 'Angelegt', value: 'created'}, {text: 'Aktualisiert', value: 'changed'}]"
    ></nxt-radio>
  </nxt-page-header>
  <nxt-page-content>
    <div fxFlex fxLayout="row" style="border: 0px solid green">
      <nxt-datagrid
          #newEventsDataGrid
          *ngIf="backofficeViewData && backofficeViewData.calendarEventsCreated"
          fxFlex
          name="Backoffice"
          [columnDefs]="currentView === 'created' ? columnDefs.newEventsColumnDefs : columnDefs.createdEventsColumnDefs"
          [disableAllSort]="true"
          [quickFilterText]="quickFilterText"
          [rowData]="currentView === 'created' ? backofficeViewData.calendarEventsCreated : backofficeViewData.calendarEventsChanged"
          [showFooter]="true"
          [showSideBar]="false"
          [autoSizeColumnOnResize]="true"
      >
      </nxt-datagrid>
    </div>

    <div *ngIf="backofficeViewData" fxLayout="row" fxLayoutAlign="center center" style="border: 0px solid yellow">
      <div fxLayout="column" fxFlex="50" fxLayoutAlign="center">
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <div>
            <div class="nxt-text-80">{{ backofficeViewData?.calendarEventsCreated.length }} Termine davon</div>
            <div class="nxt-text-80">{{ newEventCalcData.eventCountTattoo }} Tattoo | {{ newEventCalcData.eventCountPiercing }} Piercing</div>
          </div>
          <div *ngIf="backofficeViewData?.calendarEventsCreated.length > 0">
            <table class="workplace-data-table nxt-text-80">
              <tr>
                <td>Backoffice</td>
                <td>|</td>
                <td>{{ newEventCalcData.eventCountBackoffice }} Termine / {{ newEventCalcData.percentageBackoffice }} %</td>
                <td>|</td>
                <td>{{ newEventCalcData.valenceBackoffice }} Wert / {{ newEventCalcData.valencePercentageBackoffice }} %</td>
              </tr>
              <tr>
                <td>Empfang</td>
                <td>|</td>
                <td>{{ newEventCalcData.eventCountReception }} Termine / {{ newEventCalcData.percentageReception }} %</td>
                <td>|</td>
                <td>{{ newEventCalcData.valenceReception }} Wert / {{ newEventCalcData.valencePercentageReception }} %</td>
              </tr>
            </table>
            <!--<div class="nxt-text">Backoffice: {{newEventCalcData.eventCountBackoffice}} Termine / {{newEventCalcData.percentageBackoffice}} % |
              {{newEventCalcData.valenceBackoffice}} Wert / {{newEventCalcData.valencePercentageBackoffice}} %</div>
            <div class="nxt-text">Empfang: {{newEventCalcData.eventCountReception}} Termine / {{newEventCalcData.percentageReception}} % |
              {{newEventCalcData.valenceReception}} Wert / {{newEventCalcData.valencePercentageReception}} %</div>-->
          </div>
        </div>
      </div>
      <div fxFlex="300px" fxLayout="column">
        <nxt-date-picker [showJumpDayButtons]="true" style="width:305px;" [(nxtModel)]="dateString" (nxtModelChange)="dateChanged()"
                         [showTodayButton]="true"></nxt-date-picker>
      </div>
      <div fxLayout="column" fxFlex="50" fxLayoutAlign="center">
        <div fxLayout="row" fxLayoutAlign="space-around">
          <div>
            <div class="nxt-text">Gesamt: {{ newEventCalcData.totalPrice | money }}</div>
            <div class="nxt-text">Wertigkeit: {{ newEventCalcData.totalValence }}</div>
          </div>
        </div>
      </div>
    </div>
  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>
