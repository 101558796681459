import type {customRandom as CustomRandomType, nanoid as NanoidType, random as RandomType, urlAlphabet as UrlAlphabetType} from 'nanoid';
import {BrowserTools} from './browser.tools';

export class NanoIdTools {
  static nanoid: typeof NanoidType;
  static urlAlphabet: typeof UrlAlphabetType;
  static customRandom: typeof CustomRandomType;
  static random: typeof RandomType;

  static customChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  static onlyUpperCaseWithNumbers = 'ABCDEFGHJKLMNPQRSTUVWXYZ0123456789';
  static onlyLowerCase = 'abcdefghijklmnopqrstuvwxyz';
  static onlyLowerCaseWithNumbers = 'abcdefghijklmnopqrstuvwxyz0123456789';
  static receiptChars = 'NXT1234567890';

  static async init() {
    if (BrowserTools.isBrowser()) {
      const {nanoid, urlAlphabet, customRandom, random} = await import('nanoid');
      NanoIdTools.nanoid = nanoid;
      NanoIdTools.urlAlphabet = urlAlphabet;
      NanoIdTools.customRandom = customRandom;
      NanoIdTools.random = random;
    } else {
      const dynamicImport = new Function('specifier', 'return import(specifier)');
      const {nanoid, urlAlphabet, customRandom, random} = await dynamicImport('nanoid');
      NanoIdTools.nanoid = nanoid;
      NanoIdTools.urlAlphabet = urlAlphabet;
      NanoIdTools.customRandom = customRandom;
      NanoIdTools.random = random;
    }
  }

  public static generate(): string {
    return NanoIdTools.nanoid();
  }

  public static generateForUrl(length: number): string {
    return NanoIdTools.customRandom(NanoIdTools.onlyLowerCaseWithNumbers, length, NanoIdTools.random)();
  }

  public static generateNxt(): string {
    return NanoIdTools.customRandom(NanoIdTools.customChars, 10, NanoIdTools.random)();
  }

  public static generateEvent(): string {
    return NanoIdTools.customRandom(NanoIdTools.onlyLowerCaseWithNumbers, 14, NanoIdTools.random)();
  }

  public static generateBankTransactionOrDocument(): string {
    return NanoIdTools.customRandom(NanoIdTools.onlyLowerCaseWithNumbers, 16, NanoIdTools.random)();
  }

  public static generateEventFileAlbum(): string {
    return NanoIdTools.customRandom(NanoIdTools.onlyLowerCaseWithNumbers, 8, NanoIdTools.random)();
  }

  public static generateCheckoutId(): string {
    return NanoIdTools.customRandom(NanoIdTools.onlyLowerCase, 7, NanoIdTools.random)();
  }

  public static generateCustom(length: number, onlyUppercase: boolean): string {
    return NanoIdTools.customRandom(
      onlyUppercase ? NanoIdTools.onlyUpperCaseWithNumbers : NanoIdTools.customChars,
      length,
      NanoIdTools.random,
    )();
  }

  public static generatePromoterId(): string {
    return NanoIdTools.customRandom(NanoIdTools.customChars, 10, NanoIdTools.random)();
  }

  public static generateNxtPromo(): string {
    return NanoIdTools.customRandom(NanoIdTools.onlyUpperCaseWithNumbers, 5, NanoIdTools.random)();
  }

  public static generateIbOfferCode(): string {
    return NanoIdTools.customRandom(NanoIdTools.onlyUpperCaseWithNumbers, 6, NanoIdTools.random)();
  }

  static generateTattooTicket() {
    const code = NanoIdTools.customRandom(NanoIdTools.onlyUpperCaseWithNumbers, 12, NanoIdTools.random)();
    return code.substring(0, 4) + '-' + code.substring(4, 8) + '-' + code.substring(8);
  }

  static generateReceiptNumber() {
    return '0' + NanoIdTools.customRandom(NanoIdTools.receiptChars, 5, NanoIdTools.random)();
  }

  static generateUppercase(number: number) {
    return NanoIdTools.customRandom(NanoIdTools.onlyUpperCaseWithNumbers, number, NanoIdTools.random)();
  }

  static generateArtistGateOpenLink() {
    // Methode bleibt leer, wie im Originalcode
  }
}
