import {SocketService} from '../../services/socket/socket.service';
import {IframeMessageManagerInIframe} from '../../services/iframe-message-manager-in-iframe';
import {KeyCode, ShortcutService} from '../../services/shortcut.service';
import {DialogService, LoadingId} from '../../services/dialog.service';
import {LoginService} from '../../services/login.service';
import {Log} from '../../common-browser/log/log.tools';
import {SortTools} from '../../common-browser/helpers/sort.tools';
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ConfigService} from '../../services/config.service';
import {NxtNgConfigUser} from '../../common-interfaces/nxt.ng-config.interface';
import {FirebaseLoginService} from '../../services/firebase-login.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {ErrorTools} from '../../common-browser/helpers/error.tools';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {WorkplaceTools} from '../../common-browser/helpers/workplace.tools';
import {InputComponent} from '../../components/form-controls/input/input.component';
import {PasswordTools} from '../../common-browser/helpers/password.tools';
import {IntervalTools} from '../../common-browser/helpers/interval.tools';
import {TimeTools} from 'src/app/common-browser/helpers/time.tools';
import {ValidatorTools} from '../../helpers/validator.tools';
import {StringTools} from '../../common-browser/helpers/string.tools';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {ColComponent} from '../../controls/nxt-grid/col/col.component';
import {RowComponent} from '../../controls/nxt-grid/row/row.component';
import {LongPressDirective} from '../../directives/long-press.directive';
import {NgIf} from '@angular/common';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {WindowTools} from '../../common-browser/window.tools';
import {SlideToggleComponent} from '../../components/form-controls/slide-toggle/slide-toggle.component';


@Component({
  selector: 'nxt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [FlexModule, ExtendedModule, LongPressDirective, InputComponent, RowComponent, ColComponent, NxtButtonComponent, SafeHtmlPipe, NgIf, SlideToggleComponent],
  standalone: true,
})
export class LoginComponent implements OnInit, OnDestroy {

  @ViewChild('loginBtn', {static: false})
  loginButton: HTMLButtonElement;
  @ViewChild('inputPassword', {static: false}) inputPassword: InputComponent;
  authSubscription: Subscription;
  authFailedSubscription: Subscription;
  firebaseLoginFailedSubscription: Subscription;
  private startDelayedLoginTimeout: any;
  private shortcutSubscription: Subscription;
  lastLoginTimestamp = 0;
  errorMessage = '';
  loginButtonText = 'Anmelden';
  studio = '';
  workplace = '';
  username = '';
  password = '';
  private connectionChangeListenerSubscription: Subscription;
  usernames: NxtNgConfigUser[];
  workPlaceTools = WorkplaceTools;
  logoSrc = '';
  private passwordStrengthToLow = false;
  private showIframeInterval: any;
  public setNewPassword = false;
  public passwordValidator = ValidatorTools.password;
  public newPassword1 = '';
  public newPassword2 = '';
  public disableLoginButton = false;
  testValue = '';
  showPassword = false;
  loginRunning: boolean;


  constructor(
    private router: Router,
    private socketService: SocketService,
    private route: ActivatedRoute,
    private shortcutService: ShortcutService,
    private dialogService: DialogService,
    public loginService: LoginService,
    private storageService: LocalStorageService,
    private configService: ConfigService,
    private firebaseLoginService: FirebaseLoginService,
    private cdRef: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
  ) {
    if (window.location.hostname === 'paypal.nxt-lvl.ink') {
      window.location.href = 'https://nxt-lvl.ink';
    }
    this.usernames = this.configService.config.value.users.filter(u => !u.hidden).sort(SortTools.sortString('username'));
    // this.registerConnectionListener();

    this.showIframeInterval = setInterval(() => {
      IframeMessageManagerInIframe.instance.showIframe('login', 'Login');
    }, 30000);
  }

  test() {
  }

  private async reLogin() {
    this.loginService.showLoginDialog = true;
    await TimeTools.sleep(500);
    await this.firebaseLoginService.logout();
    this.socketService.disconnectSocket();
  }

  async ngOnInit() {
    this.loadTest();
    this.loginService.showLoginDialog = !this.storageService.get('FirebaseLoggedIn', false);
    this.logoSrc = '/assets/logo/studio/logo_' + this.configService.config.value.studioRegion?.toLowerCase() + '_white.svg';
    if (this.route.snapshot.queryParams.setNewPassword) {
      this.setNewPassword = true;
      this.loginButtonText = 'neues Passwort setzen';
      this.disableLoginButton = true;
    }

    if (this.route.snapshot.queryParams.reLogin) {
      await this.reLogin();
    }
    this.registerServiceEvents();
    this.setLastLoginFromStorage();
    this.shortcutSubscription = this.shortcutService.onKeyPress.subscribe((key: KeyCode) => {
      if (key === KeyCode.Enter) {
        if (!this.disableLoginButton && !this.loginRunning) {
          this.login(true);
        }
      }
    });
    IframeMessageManagerInIframe.instance.showIframe('login-init', 'Calendar');
    setTimeout(() => {
      if (!this.storageService.get('FirebaseLoggedIn', false)) {
        this.loginService.showLoginDialog = true;
      }
    }, 2000);
    setTimeout(() => {
      this.loginService.showLoginDialog = true;
    }, 2500);

    if (this.router.url === '/logout') {
      this.firebaseLoginService.logout();
      this.loginService.clearSavedLogin(true, 'logout');
    }
  }


  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
    this.authFailedSubscription?.unsubscribe();
    this.shortcutSubscription?.unsubscribe();
    this.connectionChangeListenerSubscription?.unsubscribe();
    this.firebaseLoginFailedSubscription?.unsubscribe();
    IntervalTools.clear(this.showIframeInterval);
  }


  registerServiceEvents() {

    this.firebaseLoginFailedSubscription = this.firebaseLoginService.onLoginFailed.subscribe((err) => {
      setTimeout(() => {
        this.dialogService.hideLoading(LoadingId.Login);
        this.loginService.showLoginDialog = true;
        this.errorMessage = err.message;
      }, 500);
    });

    this.authSubscription = this.socketService.state.subscribe(async (params) => {
      if (params.authenticated) {
        if (this.passwordStrengthToLow || this.setNewPassword) {
          if (!this.setNewPassword) {
            this.dialogService.hideLoading(LoadingId.Login);
            await this.dialogService.showOk('Dein Passwort ist zu schwach, bitte lege ein neues Passwort fest');
            if (this.socketService.state.getValue().authenticated) {
              await this.firebaseLoginService.logout();
              this.socketService.disconnectSocket();
              this.loginService.clearSavedLogin(false, 'neu Einloggen');
              this.setNewPassword = true;
              // this.password = '';
              this.cdRef.detectChanges();
              this.loginService.showLoginDialog = true;
              // setTimeout(() => {

              // }, 1000);
              // this.loginService.navigateToLogin({reLogin: true, setNewPassword: true});
            }
          } else {
            if (this.newPassword1 === this.newPassword2) {
              await this.loginService.setNewPassword(this.newPassword1);
              await this.reLogin();
              this.setNewPassword = false;
              this.password = '';
              this.loginButtonText = 'Anmelden';
            }
          }
        } else {
          const returnUrl = this.getReturnUrl();
          if (returnUrl && returnUrl.length > 1) {
            Log.info('LOGIN navigate after login to' + returnUrl);
            IframeMessageManagerInIframe.instance.hideIframe('calendar-event-edit login', 1000);
            setTimeout(() => {
              const newUrl = returnUrl + this.getUrlLoginQuery();
              this.router.navigateByUrl(newUrl, {skipLocationChange: false});
            }, 100);
          } else {
            this.router.navigateByUrl('/');
          }
        }
        this.dialogService.hideLoading(LoadingId.Login);
      }
    });

    this.authFailedSubscription = this.socketService.onSocketUnauthorized.subscribe((err) => {
      this.firebaseLoginService.logout();
      this.dialogService.hideLoading(LoadingId.Login);
      const message = ErrorTools.getMessage(err);
      if (message.includes('Firebase ID token has incorrect')) {
        WindowTools.reload('Firebase ID token has incorrect');
      }
      this.dialogService.showOk(message);
      this.loginService.showLoginDialog = true;
    });

    this.authFailedSubscription = this.firebaseLoginService.onLoginChanged.subscribe(async (user) => {
      /*if (user && this.storageService.get(StorageKey.WeakPassword, false)) {
        this.firebaseLoginService.logout();
        await this.dialogService.showOk('Willst du das System ausdribbeln?\nBitte ändere dein Passwort!', {title: '🤬🤬🤬'});
        this.storageService.set(StorageKey.WeakPassword, false);
        return;
      }*/
      if (user && !this.socketService.state.getValue().authenticated) {
        if (this.username && this.workplace && this.studio) {
          this.dialogService.closeButtonChooser();
          const diff = DateTools.dateDiffToNow(this.lastLoginTimestamp) > 1500;
          if (diff) {
            this.login(false);
          }
        } else {
          this.firebaseLoginService.logout();
        }
      }
    });
  }


  async login(manualLogin: boolean) {
    try {
      if (!this.studio) {
        return;
      }
      if (!this.workplace) {
        return;
      }
      this.loginRunning = true;
      this.username = StringTools.firstCharUppercase(this.username);
      if (manualLogin) {
        this.passwordStrengthToLow = !PasswordTools.getPasswordStrength(this.password).ok;
      }
      if (!this.username || !this.studio || !this.workplace) {
        this.askNext();
      }

      if (manualLogin && !this.password) {
        this.inputPassword?.setFocus();
        this.loginRunning = false;
        return;
      }

      this.lastLoginTimestamp = Date.now();
      if (this.startDelayedLoginTimeout) {
        clearTimeout(this.startDelayedLoginTimeout);
      }
      this.loginService.showLoginDialog = false;
      // this.loginService.loginStart();


      const loginTimeout = 10000;
      this.dialogService.showLoading(LoadingId.Login, 'login...');

      const realStudio = this.configService.config.value.studios.find(s => s.name === this.studio).realStudio;

      await this.loginService.login(this.username, this.studio, realStudio ?? this.studio, WorkplaceTools.getValue(this.workplace), this.password, manualLogin);
      this.password = '';
    } catch (err) {
    }
    this.loginRunning = false;
  }

  private getReturnUrl() {
    return this.route.snapshot.queryParams.returnUrl || '/';
  }

  public async askUsername() {
    const buttonRows = [this.usernames.map(login => ({text: login.username, color: login.color, backgroundColor: login.backgroundColor}))];
    const result = await this.dialogService.showButtonChooser({buttonRows, title: 'Wer bist du?', text: '', minWidth: '80%', value: '', hideBackButton: true});
    if (!result) {
      return;
    }
    if (typeof result !== 'string' && result.text) {
      if (this.username !== result.text) {
        this.username = result.text;
        this.studio = '';
        this.workplace = '';
        this.password = '';
      }
      this.askNext();
    }
  }

  public async askStudio() {
    const user = this.usernames.find(u => u.username === this.username);
    if (!user) {
      return;
    }
    let studio = '';
    let autoStudio = '';
    if (this.loginService.ip === '37.24.101.11') {
      autoStudio = 'Villa';
    } else if (this.loginService.ip === '37.24.177.51') {
      autoStudio = 'Brand';
    }
    let studioButtonRows = this.configService.config.value.studios.filter(s => !['Alsdorf', 'Alsdorf 2', 'Brand'].includes(s.name)).map(s => ({text: s.name}));
    const studioLogins = this.localStorageService.get('StudioLogins', []);
    if (studioLogins.length > 0) {
      studioButtonRows = studioButtonRows.filter(s => studioLogins.includes(s.text));
    }

    let result: any;
    if (studioButtonRows.length === 1) {
      result = studioButtonRows[0];
    } else {
      result = await this.dialogService.showButtonChooser({buttonRows: [studioButtonRows], title: 'Welche Kasse?', text: '', minWidth: '80%', value: '', hideBackButton: true});
    }
    if (!result) {
      return;
    } else {
      studio = result.text;
      if (autoStudio && !studio.includes(autoStudio) && this.configService.config.value.studios.find(s => s.name === autoStudio)) {
        if (!(await this.dialogService.showYesNo('Bist du dir sicher, dich in ' + (studio === 'Villa' ? 'der ' : '') + '<strong>' + studio + '</strong> anzumelden, obwohl du dich in ' + (autoStudio === 'Villa' ? 'der ' : '') + '<strong>' + autoStudio + '</strong> befindest?'))) {
          studio = '';
        }
      }
    }
    this.studio = studio;
    if (studio) {
      this.askNext();
    }
  }

  public async askWorkplace() {
    const user = this.usernames.find(u => u.username === this.username);
    if (!user) {
      return;
    }
    const disabledWorkplaces = this.storageService.get('DisabledWorkplaces', []);
    let buttonRowsWorkplace: { text: string, value: string }[] = [];

    const currentUser = this.configService.config.value.users.find(u => u.username === this.username);
    if (!currentUser) {
      return;
    }
    if (!currentUser.workplaces || currentUser.workplaces.includes('reception')) {
      buttonRowsWorkplace.push({text: 'Empfang', value: 'reception'});
    }
    if (currentUser.workplaces?.includes('backoffice') && !disabledWorkplaces.includes('backoffice')) {
      buttonRowsWorkplace.push({text: 'Backoffice', value: 'backoffice'});
    }


    const workplaceLogins = this.localStorageService.get('WorkplaceLogins', []);
    if (workplaceLogins.length > 0) {
      buttonRowsWorkplace = buttonRowsWorkplace.filter(w => workplaceLogins.includes(w.value));
    }


    let result: any;
    if (buttonRowsWorkplace.length === 1) {
      result = buttonRowsWorkplace[0];
    } else {
      result = await this.dialogService.showButtonChooser({
        buttonRows: [buttonRowsWorkplace],
        title: 'An welchem Arbeitsplatz bist du?',
        text: '',
        minWidth: '80%',
        value: '',
        hideBackButton: true,
      });
    }

    if (!result) {
      return;
    } else {
      if (typeof result !== 'string' && result.value) {
        this.workplace = result.value.toString();
        this.askNext();
      }
    }
  }

  private getUrlLoginQuery() {
    const username = this.route.snapshot.queryParams.username;
    const password = this.route.snapshot.queryParams.password;
    const studio = this.route.snapshot.queryParams.studio;
    if (username && password && studio) {
      return '?username=' + username + '&password=' + password + '&studio=' + studio;
    }
    return '';
  }


  private askNext() {
    requestAnimationFrame(() => {
      if (!this.studio) {
        this.askStudio();
      } else if (!this.workplace) {
        this.askWorkplace();
      } else {
        this.inputPassword?.click();
      }
    });
  }

  private setLastLoginFromStorage() {
    const login = this.storageService.get('CurrentLogin', null);
    if (login) {
      if (this.route.snapshot.queryParams.username) {
        this.username = this.route.snapshot.queryParams.username;
      } else {
        this.username = login.username;
      }
      this.studio = login.studio;
      this.workplace = login.workplace;
    }
    if (this.username && this.studio && this.workplace) {
      // this.focusPassword();
      this.focusPassword();
    }
  }

  private focusPassword() {
    setTimeout(() => {
      this.inputPassword?.click();
    }, 200);
  }

  async logoLongPressed() {
    const result = await this.dialogService.showYesNo('Welches Handy', {yesText: 'Foto 12er', noText: 'Foto 13er'});
    if (result) {
      this.username = 'Foto12er';
    } else {
      this.username = 'Foto13er';
    }
  }

  public checkCanChangePassword() {
    this.loginButtonText = 'neues Passwort setzen';
    this.disableLoginButton = false;
    if (!this.password) {
      this.disableLoginButton = true;
    } else if (this.newPassword1 !== this.newPassword2) {
      this.loginButtonText = 'Passwörter stimmen nicht überein';
      this.disableLoginButton = true;
    } else {
      if (this.password === this.newPassword1) {
        this.loginButtonText = 'Das neue Passwort darf nicht dein altes Passwort sein';
        this.disableLoginButton = true;
      } else {
        this.disableLoginButton = !PasswordTools.getPasswordStrength(this.newPassword1).ok;
      }
    }
  }

  public usernameChanged() {
    if (this.username !== StringTools.firstCharUppercase(this.username)) {
      this.username = StringTools.firstCharUppercase(this.username);
    }
    const user = this.usernames.find(u => u.username === this.username);
    if (!user) {
      this.workplace = '';
      this.studio = '';
    }
  }

  public onFocusPassword() {
    setTimeout(() => {
      if (!this.studio) {
        this.askStudio();
      }
    }, 200);
  }

  testChanged() {
    this.storageService.setByString('test', this.testValue);
  }

  loadTest() {
    this.testValue = this.storageService.getByString('test', '');
  }
}
