import {SocketService} from '../../services/socket/socket.service';
import {Component, OnInit} from '@angular/core';
import {NxtSocketSubscription} from '../../services/socket/socket-subscription';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
    selector: 'nxt-subscription-test',
    templateUrl: './subscription-test.component.html',
    styleUrls: ['./subscription-test.component.scss'],
    imports: [NgIf, NxtButtonComponent, AsyncPipe]
})
export class SubscriptionTestComponent implements OnInit {

  public data1 = '';
  private subscription1: NxtSocketSubscription;
  public data2 = '';
  private subscription2: NxtSocketSubscription;

  $data3 = this.socketService.getObservable('subscriptionTest');
  showData3 = true;
  observersLength = 0;
  constructor(
    private socketService: SocketService
  ) {

  }

  ngOnInit(): void {
    setInterval(() => {
      // this.observersLength = this.socketService.subscriptionsNew.subscriptionTest?.observers?.length ?? 0;
    }, 100);
  }


  subscribe1() {
    this.subscription1?.unsubscribe();
    this.subscription1 = this.socketService.subscribeNew('subscriptionTest', (data) => {
      this.data1 = data;
    }, {emitInitial: true});
  }

  subscribe2() {
    this.subscription2?.unsubscribe();
    this.subscription2 = this.socketService.subscribeNew('subscriptionTest', (data) => {
      this.data2 = data;
    }, {emitInitial: true});
  }

  public trigger() {
    this.socketService.triggerSubscriptionTest();
  }


  public unsubscribe1() {
    this.subscription1?.unsubscribe();
  }

  public unsubscribe2() {
    this.subscription2?.unsubscribe();
  }


}
