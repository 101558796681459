import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControlWrapper} from '../form-control-wrapper';
import {DialogService} from '../../../services/dialog.service';
import {MatIcon} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgIf, NgStyle} from '@angular/common';
import {FormFieldWrapperComponent} from '../../form-field-wrapper/form-field-wrapper.component';

@Component({
    selector: 'nxt-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss'],
    imports: [FormFieldWrapperComponent, NgClass, ExtendedModule, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, NgStyle, NgIf, MatIcon],
  standalone: true,
})
export class TimePickerComponent extends FormControlWrapper implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() getText: () => string;
  @Input() readonly = false;
  @Input() minTime?: string;
  @Input() maxTime?: string;
  @Input() showHourCounter: boolean;
  @Input() width = '50px';
  @Input() placeholder: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() showClearButton = false;
  @Input() bottomNoPadding = false;
  @Input() noPadding = false;


  @Input() set value(value: string) {
    this.nxtFormControl.setValue(value, {emitEvent: false, onlySelf: true});
  }

  constructor(private dialogService: DialogService) {
    super();
  }

  ngOnInit() {
  }


  async inputClick() {
    if (this.readonly) {
      return;
    }
    let text = this.text;
    if (this.getText) {
      text = this.getText();
    }
    if (!text && this.nxtFormControl.value) {
      text = 'Aktuell: ' + this.nxtFormControl.value + ' Uhr';
    }
    const result = await this.dialogService.showTimeChooser(this.nxtFormControl.value, this.title || this.placeholder || this.nxtFormControl.name, text, this.minTime, this.maxTime, this.showHourCounter);
    if (result) {
      this.nxtFormControl.setValue(result);
      this.valueChange.emit(result);
    }
  }

  /*click() {
    this.inputClick();
  }*/

  public clear() {
    this.nxtFormControl.setValue('');
    this.valueChange.emit('');
  }
}
