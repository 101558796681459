@if (loadingSpinner()) {
  <div class="loading-spinner">
    <nxt-spinner size="40"></nxt-spinner>
  </div>
}
<ag-grid-angular
  [ngStyle]="{fontSize: fontSize, opacity: opacity, width: '100%', height: '100%'}"
  [columnDefs]="_columnDefs"
  [gridOptions]="gridOptions"
  [theme]="myTheme"
  (gridReady)="onGridReady($event)"
  [animateRows]="true"
  [cacheQuickFilter]="true"
  [suppressRowTransform]="suppressRowTransform"
  [components]="frameworkComponents"
  [enableRangeSelection]="enableRangeSelection"
  [localeText]="localeText"
  [pagination]="pagination"
  [pinnedBottomRowData]="pinnedBottomRowData"
  [quickFilterText]="quickFilterText"
  [rowDragManaged]="true"
  [suppressColumnVirtualisation]="true"
  [suppressRowHoverHighlight]="suppressRowHoverHighlight"
  [suppressScrollOnNewData]="true"
  [rowSelection]="rowSelection"
  (cellClicked)="onCellClicked($event)"
  (cellDoubleClicked)="onCellDoubleClicked($event)"
  (cellEditingStarted)="cellEditingStarted.emit($event)"
  (cellEditingStopped)="cellEditingStopped.emit($event)"
  (resized)="gridResized($event)"
  (rowClicked)="rowClick.emit($event)"
  (rowDoubleClicked)="rowDoubleClick.emit($event)"
  (rowDragEnd)="onRowDragEnd($event)"
  (selectionChanged)="onSelectionChanged($event)"


></ag-grid-angular>

