import {Component, OnInit, Optional, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {HistoryComponent, NxtHistoryPropDef} from '../history.component';
import {MatDialogRef} from '@angular/material/dialog';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
  selector: 'nxt-contact-history',
  templateUrl: './contact-history.component.html',
  styleUrls: ['./contact-history.component.scss'],
  imports: [FlexModule, NxtButtonIconComponent, HistoryComponent],
})

export class ContactHistoryComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  contactId = signal('');

  constructor(
    @Optional() private dialogRef: MatDialogRef<ContactHistoryComponent>,
  ) {
    super();
    this.hiddenProps.push(...this.debugProps);
  }

  @ViewChild(HistoryComponent) historyComponent: HistoryComponent;

  public propDefs: NxtHistoryPropDef[] = [
    {
      field: 'weeklyBroadcast',
      name: 'Weekly Broadcast',
      type: NxtFieldType.Boolean,
    }, {
      field: 'taglines',
      name: 'Interne Infos',
      type: NxtFieldType.Text,
      valueGetter: data => data.join('\n'),
    }, {
      field: 'city',
      name: 'Stadt',
      type: NxtFieldType.Text,
    }, {
      field: 'country',
      name: 'Land',
      type: NxtFieldType.Text,
    }, {
      field: 'createdBy',
      name: 'Erstellt von',
      type: NxtFieldType.Text,
    }, {
      field: 'updatedBy',
      name: 'Aktualsiert von',
      type: NxtFieldType.Text,
    }, {
      field: 'mobileFormatted',
      name: 'Handy',
      type: NxtFieldType.Text,
    }, {
      field: 'streetAdresse',
      name: 'Straße',
      type: NxtFieldType.Text,
    }, {
      field: 'disableBirthdayGiftCard',
      name: 'keine Geburstagsgutscheine',
      type: NxtFieldType.Text,
    }, {
      field: 'email',
      name: 'E-Mail',
      type: NxtFieldType.Text,
      valueGetter: data => data || '',
    }, {
      field: 'givenName',
      name: 'Vorname',
      type: NxtFieldType.Text,
    }, {
      field: 'fullName',
      name: 'voller Name',
      type: NxtFieldType.Text,
    }, {
      field: 'familyName',
      name: 'Nachname',
      type: NxtFieldType.Text,
    }, {
      field: 'postalCode',
      name: 'PLZ',
      type: NxtFieldType.Text,
    }, {
      field: 'disableBroadcast',
      name: 'kein Broadcast',
      type: NxtFieldType.Text,
    }, {
      field: 'oldMobilesFormatted',
      name: 'Alte Nummern',
      valueGetter: (data) => {
        return data.map(d => d.m).join(', ');
      },
      type: NxtFieldType.Text,
    }, {
      field: 'birthday',
      name: 'Geburtstag',
      type: NxtFieldType.Date_germanDate,
    },
  ];
  public hiddenProps = [
    'updatedAt', 'testBroadcast', 'suffix', 'seqId', /^closedEvents/g, /^events/g, /^canceledEvents/g, 'syncToGoogle', 'createdAt', 'mobile', 'id',
  ];

  debugProps = [];
  public showTitle = false;
  public showDebug = false;


  setData(contactId: string) {
    this.contactId.set(contactId.replace(/\//g, '_'));
  }

  ngOnInit() {
    this.propDefs = this.propDefs.sort(SortTools.sortString('name'));
  }

  nxtOnDestroy() {
  }

  public prepareRawData(data) {
    return data;
  }

  close() {
    this.dialogRef.close();
  }
}
