import {Injectable} from '@angular/core';
import {DateTools} from '../common-browser/helpers/date.tools';
import {NxtWebFormSubmit} from '../common-interfaces/nxt.web-form-submit.interface';

@Injectable({
  providedIn: 'root'
})
export class WebFormSubmitService {


  constructor() {
  }


  getFirstText(webFormSubmit: NxtWebFormSubmit) {
    let text = 'Hallo ' + webFormSubmit.givenName + ',';
    text += '\nvielen Dank für deine Anfrage.';
    text += '\n\nDeine Anfrage:';
    text += '\n' + webFormSubmit.text;
    text += '\nStudio: ' + webFormSubmit.studio;
    if (webFormSubmit.imageUrl) {
      text += '\nDein Bild: ' + webFormSubmit.imageUrl.replace(/\s/g, '%20');
    }
    return text;
  }

  getFirstTextEmailSubject(webFormSubmit: NxtWebFormSubmit) {
    return 'Deine Tattoo-Preisanfrage vom ' + DateTools.format(webFormSubmit.createdAt, 'dd.MM.yyyy');
  }
}
