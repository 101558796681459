<div class="flex h-full w-full flex-col items-center justify-center nxt-background-image">
  @if (view() === 'loading') {
    <nxt-spinner></nxt-spinner>
  } @else if (view() === 'initDone') {
    <div class="text-2xl">Einrichtung abgeschlossen</div>
    <div class="mt-2 px-5 text-xl center">Das "{{ model() }}" ist bereit.</div>
  } @else if (view() === 'error') {
    <div class="text-2xl red center" [innerHTML]="errorText() | safeHtml"></div>
  } @else if (view() === 'canceled') {
    <div class="text-2xl red center">Appointment is canceled</div>
  } @else if (view() === 'showButtons') {
    <div class="flex w-full flex-col items-center justify-center gap-6">
      @if (eventQrCodePreData(); as eventQrCodePreData) {
        <div class="px-6 text-2xl center" [innerHTML]="eventQrCodePreData.headerText | safeHtml"></div>
        @if (eventQrCodePreData.tattooBeforeButtonText) {
          <div>
            <nxt-button [bigButton]="true" (click)="typeClicked('preTattoo')">
              <div [innerHTML]="eventQrCodePreData.tattooBeforeButtonText | safeHtml"></div>
            </nxt-button>
          </div>
        }
        @if (eventQrCodePreData.stencilButtonText) {
          <div>
            <nxt-button [bigButton]="true" (click)="typeClicked('stencil')">
              <div [innerHTML]="eventQrCodePreData.stencilButtonText | safeHtml"></div>
            </nxt-button>
          </div>
        }
        @if (eventQrCodePreData.tattooButtonText) {
          <div>
            <nxt-button [bigButton]="true" (click)="typeClicked('tattoo')">
              <div [innerHTML]="eventQrCodePreData.tattooButtonText | safeHtml"></div>
            </nxt-button>
          </div>
        }
      }
    </div>
  } @else if (view() === 'finished') {
    @if (eventQrCodePreData(); as eventQrCodePreData) {
      <div class="flex h-full w-full flex-col items-center justify-center px-4">

        <div class="pb-2 text-250" style="text-align: center">Hello {{ eventQrCodePreData.artist }}!</div>
        @if (type() === 'stencil') {
          <div class="pb-4 font-bold text-[150%]">Stencil</div>
          <div class="text-170" style="text-align: center" [innerHTML]="eventQrCodePreData.stencilInstruction"></div>
        }
        @if (type() === 'preTattoo') {
          <div class="pb-4 font-bold text-[150%]">Pre-Tattoo</div>
          <div class="text-170" style="text-align: center" [innerHTML]="eventQrCodePreData.preTattooInstruction"></div>
        }
        @if (type() === 'tattoo') {
          <div class="pb-4 font-bold text-[150%]">Tattoo</div>
          <div class="text-170" style="text-align: center" [innerHTML]="eventQrCodePreData.tattooInstruction"></div>
          <br/>
          <div class="text-250 red animate__animated animate__tada center" style="text-align: center" [innerHTML]="eventQrCodePreData.minText"></div>
        }
      </div>
    }
  }
</div>
