import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {JsonTools} from '../../../../common-browser/helpers/json.tools';
import {NxtDatePipe} from '../../../../pipes/nxt-date-pipe';
import {NgFor, NgIf, UpperCasePipe} from '@angular/common';

@Component({
    selector: 'nxt-inventory-list-print',
    templateUrl: './inventory-print.component.html',
    styleUrls: ['./inventory-print.component.scss'],
    imports: [NgIf, NgFor, UpperCasePipe, NxtDatePipe]
})
export class InventoryPrintComponent implements AfterViewInit {

  public data: {
    lines: { name: string, amount: number }[],
    studio: string;
    date: number;
  };

  constructor(private route: ActivatedRoute) {

    if (this.route.snapshot.queryParams.data) {
      this.data = JsonTools.parse(this.route.snapshot.queryParams.data);
    }
  }

  ngAfterViewInit(): void {
    window.print();
  }
}
