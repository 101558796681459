import {Component, OnInit, Optional} from '@angular/core';
import {SocketService} from '../../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {NxtComponent} from '../../nxt.component';
import {WhatsappChatComponent} from '../whatsapp-chat/whatsapp-chat.component';
import {BehaviorSubject} from 'rxjs';
import {FromNowPipe} from '../../../pipes/from-now.pipe';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {InputComponent} from '../../form-controls/input/input.component';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {AsyncPipe, NgFor, NgIf} from '@angular/common';
import {NxtWhatsAppChat} from 'src/app/common-interfaces/whats-app/nxt-whatsapp-message.interface';
import {WhatsappChatUsernamePipe} from './whatsapp-chat-username-pipe';
import {WhatsappChatLastBodyPipe} from './whatsapp-chat-last-body-pipe';


@Component({
  selector: 'nxt-whatsapp-chats',
  templateUrl: './whats-app-chats.component.html',
  styleUrls: ['./whats-app-chats.component.scss'],
  imports: [ExtendedModule, FlexModule, MatRadioGroup, MatRadioButton, SlideToggleComponent, InputComponent, NgIf, NxtButtonIconComponent, NgFor, AsyncPipe, FromNowPipe, WhatsappChatUsernamePipe, WhatsappChatLastBodyPipe]
})
export class WhatsAppChatsComponent extends NxtComponent implements OnInit {
  chats: NxtWhatsAppChat[] = [];
  chatsFiltered: NxtWhatsAppChat[] = [];

  sinceMinutes$ = new BehaviorSubject(120);
  quickFilter = '';
  public onlyUnread = false;
  public showArtists = false;
  onlyLastFromCustomer = false;

  constructor(
    @Optional() public dialogRef: MatDialogRef<WhatsAppChatsComponent>,
    private socketService: SocketService,
    private dialogService: DialogService
  ) {
    super();
  }


  async ngOnInit() {
    this.load();
    this.pushSocketSubscription = this.socketService.subscribeNew('eventWhatsAppChatChanged', () => {
      this.load();
    }, {emitInitial: true});

    this.sinceMinutes$.subscribe((value) => {
      this.load();
    });
  }

  private async load() {
    if (this.sinceMinutes$.value === -1) {
      this.chats = await this.socketService.getLatestWhatsAppMessages(undefined, '2023-01-30 20:00:00'.dateParse());
    } else {
      this.chats = await this.socketService.getLatestWhatsAppMessages(this.sinceMinutes$.value);
    }
    this.filterChanged(this.quickFilter);
  }

  showChat(chat: NxtWhatsAppChat) {
    const dialog = this.dialogService.showComponentFull(WhatsappChatComponent);
    dialog.componentInstance.loadChat(chat.id);
    dialog.componentInstance.title = chat.name;
    dialog.componentInstance.currentArtist = null;
  }

  filterChanged(value: any) {
    this.chatsFiltered = this.chats.filter(c => {
      const nameMatch = !value || c.name.replace(/\s/g, '').toLowerCase().indexOf(value.toLowerCase().replace(/\s/g, '')) > -1;
      const unreadMatch = !this.onlyUnread || c.unreadCount > 0;
      const showArtistsMatch = this.showArtists || !c.name.includes('Artist_');
      const lastFromCustomer = !this.onlyLastFromCustomer || !c.lastMessageFromMe;
      return nameMatch && unreadMatch && showArtistsMatch && lastFromCustomer;
    });
  }

  public onlyUnreadChanged() {
    this.filterChanged(this.quickFilter);
  }

  public showArtistsChanged() {
    this.filterChanged(this.quickFilter);
  }

  nxtOnDestroy(): void {
  }
}
