import {CellClassParams, CellStyle} from 'ag-grid-community';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {NxtColDef} from './nxt-col-def';

export class NxtDatagridCellStyler {
  static getCellStyler() {
    return (params: CellClassParams): CellStyle => {
      const colDef: NxtColDef = params.column.getColDef();
      if (!colDef.nxtCellStyle) {
        colDef.nxtCellStyle = {};
      }
      switch (colDef.nxtFieldType) {
        case NxtFieldType.Date_germanDateShort:
        case NxtFieldType.Date_germanDateTime:
        case NxtFieldType.Date_germanDateShortWithDayOfWeek:
        case NxtFieldType.Date_germanDateTimeFull:
        case NxtFieldType.Date_germanDateTimeWithSeconds:
        case NxtFieldType.Date_germanDate:
        case NxtFieldType.Money:
        case NxtFieldType.MoneyOnlyPositiv:
        case NxtFieldType.MoneyOnlyPositivShort:
        case NxtFieldType.MoneyFull:
        case NxtFieldType.Duration:
        case NxtFieldType.Percentage:
        case NxtFieldType.Number:
          return {textAlign: 'right', ...colDef.nxtCellStyle};
        case NxtFieldType.Icon:
          return {textAlign: 'center', ...colDef.nxtCellStyle};
      }
      if ([NxtFieldType.ShowCalendarEvent, NxtFieldType.ShowEventHistory, NxtFieldType.ShowContactHistory].includes(colDef.nxtFieldType)) {
        return {textAlign: 'center', ...colDef.nxtCellStyle};
      }
      return colDef.nxtCellStyle;
    };
  }
}
