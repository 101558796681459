<div class="flex h-full w-full flex-col p-0 md:p-4">
  <div class="flex flex-row justify-between">
    <nxt-button-icon style="position: relative; top: -8px" matTooltip="Heute anzeigen" (click)="jumpToThisMonth()">system_update_alt</nxt-button-icon>
    <nxt-button-icon style="position: relative; top: -8px" matTooltip="Abbrechen" (click)="closeClicked()">close</nxt-button-icon>
  </div>
  <div class="flex h-full w-full flex-col gap-2">
    <div class="flex flex-row items-center years">
      <nxt-button-icon (click)="jumpYears(-5)">arrow_left</nxt-button-icon>
      <table class="w-full nxt-table year-table">
        <tr>
          <td *ngFor="let year of years"
              class="year-item-id text-[80%] md:text[100%]"
              (click)="yearClicked(year.yearString)"
              [class.selected]="year.selected">
            {{ year.yearString }}
          </td>
        </tr>
      </table>
      <nxt-button-icon (click)="jumpYears(5)">arrow_right</nxt-button-icon>
    </div>
    <div class="flex flex-row overflow-x-auto months">
      <table class="w-full nxt-table month-table">
        <tr>
          <td
            *ngFor="let month of months"
            (click)="monthClicked(month.monthOfYear)"
            class="month-item-td"
            [class.selected]="month.selected">
            <div class="flex flex-col">
              <div class="text-[80%] md:text[100%]">{{ '2020-' + month.monthOfYear + '-01' | nxtDate: 'MM' }}</div>
              <div class="text-[80%] md:text-[120%]">{{ '2020-' + month.monthOfYear + '-01' | nxtDate: 'MMM' }}</div>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="flex flex-grow dates">
      <table class="h-full w-full nxt-table day-table">
        <tr style="height: 20px">
          <td>KW</td>
          <td>Mo</td>
          <td>Di</td>
          <td>Mi</td>
          <td>Do</td>
          <td>Fr</td>
          <td>Sa</td>
          <td>So</td>
        </tr>
        <tr *ngFor="let week of weeks">
          <td class="text-sm">{{ getWeek(week) }}</td>
          <td
            *ngFor="let day of week.days"
            class="day-item-td"
            [class]="day.outline"
            [class.selected]="day.selected"
            [class.today]="day.dateString === todayDateString"
            (click)="dateClicked(day.dateString)">
            <div class="relative flex flex-col justify-center">
              <div class="leading-tight text-[110%]">{{ day.text }}</div>
              <div class="hidden leading-tight text-[11px] md:block" *ngIf="day.text2">{{ day.text2 }}</div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
