import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NxtFormControl} from '../../../../nxt-form/nxt.form-control';
import {FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {MatSlider, MatSliderThumb} from '@angular/material/slider';
import {SlideToggleComponent} from '../../../../components/form-controls/slide-toggle/slide-toggle.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgFor, NgIf, NgStyle} from '@angular/common';


@Component({
    selector: 'nxt-artist-skill',
    templateUrl: './artist-skill.component.html',
    styleUrls: ['./artist-skill.component.scss'],
    imports: [NgIf, FlexModule, NgFor, SlideToggleComponent, MatSlider, MatSliderThumb, FormsModule, ReactiveFormsModule, NgStyle, ExtendedModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArtistSkillComponent implements OnInit, OnDestroy {

  @Input() nxtFormControl: NxtFormControl | any;

  constructor() {

  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  get skillValues() {
    const result = (this.nxtFormControl.get('skillValues') as UntypedFormArray).controls as UntypedFormGroup[];
    return result.sort((a, b) => {
      return a.value.description.toString().localeCompare(b.value.description.toString());
    });
  }

  get boolSkillValues() {
    const result = (this.nxtFormControl.get('boolSkillValues') as UntypedFormArray).controls as UntypedFormGroup[];
    return result.sort((a, b) => {
      return a.value.description.toString().localeCompare(b.value.description.toString());
    });
  }
}

