import {ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtInventoryStockItem} from '../../../common-interfaces/nxt.inventory-item.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {NgFor} from '@angular/common';

@Component({
    selector: 'nxt-piercing-manager-inventory-check',
    templateUrl: './piercing-manager-inventory-check.component.html',
    styleUrls: ['./piercing-manager-inventory-check.component.scss'],
    imports: [NgFor, NxtButtonComponent]
})

export class PiercingManagerInventoryCheckComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  inventoryItems: NxtInventoryStockItem[] = [];

  constructor(
    @Optional() private dialogRef: MatDialogRef<PiercingManagerInventoryCheckComponent>,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {

  }

  backClicked() {
    this.dialogRef.close();
  }
}
