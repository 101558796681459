import {Component, OnInit} from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {DialogService} from '../../services/dialog.service';
import {BodyPutService} from '../../services/body-put.service';
import {IframeMessageManagerInIframe, IframeParentCommand} from '../../services/iframe-message-manager-in-iframe';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-debug',
    templateUrl: './debug.component.html',
    styleUrls: ['./debug.component.scss'],
    imports: [FlexModule, NxtButtonComponent, RouterLink]
})
export class DebugComponent implements OnInit {

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    public dialogService: DialogService,
    private bodyPutService: BodyPutService
  ) {
    // this.dialogService.showLoading(LoadingId.Test, 'declarations-of-consent');
  }

  ngOnInit() {

  }

  restart() {
    const restartUrl = window.location.origin.split(':')[0] + ':' + window.location.origin.split(':')[1];
    this.httpClient.get(restartUrl + ':82/api/restart').toPromise();

  }

  /*async showButtonChooser() {
    const buttonRows = [['11', '12', '13', '14'], ['15', '16', '17', '18'], ['19', '20', '21', '22']  ];
    const result = await this.dialogService.showButtonChooser({buttonRows, title: 'Startzeit des Termins', text: 'Di. 14.08.2019 11:00 - 18:00', minWidth: '80%', value: '11:15'});
  }*/

  async showBodyPutChooser() {
    const result = await this.bodyPutService.showBodyPutChooser();
    alert(JSON.stringify(result));
  }


  async test() {
    const parentWindow: Window = await IframeMessageManagerInIframe.instance.sendGet(IframeParentCommand.getWindow);
    (parentWindow.document.querySelector('#iframeWrapper') as HTMLIFrameElement).style.border = '5px solid green;';
  }
}
