import {Pipe, PipeTransform} from '@angular/core';
import {NxtMoneyStack} from '../common-interfaces/nxt.money-stack.interface';
import {keys} from '../common-browser/helpers/object.tools';
import {TypeTools} from '../common-browser/helpers/type.tools';
import {SortTools} from '../common-browser/helpers/sort.tools';
import {StringTools} from '../common-browser/helpers/string.tools';

@Pipe({
    name: 'moneyStack',
    standalone: true
})
export class MoneyStackPipe implements PipeTransform {

  constructor() {
  }

  transform(moneyStack: NxtMoneyStack): any {
    const result: string[] = [];
    try {
      for (const key of keys(moneyStack).map(k => parseFloat(k)).sort(SortTools.arrayNumbers(true))) {
        if (TypeTools.isNumber(moneyStack[key]) && moneyStack[key] > 0) {
          if (key >= 1) {
            result.push(moneyStack[key] + StringTools.multiply + key.toString() + ' €');
          } else {
            result.push(moneyStack[key] + StringTools.multiply + (key * 100).toString() + ' Cent');
          }
          moneyStack[key] = 0;
        }
      }
    } catch (err) {
      result.push('?');
    }
    return result.join('&nbsp;&nbsp;&nbsp;');
  }

  transformAlt(moneyStack: NxtMoneyStack): any {
    const result: string[] = [];
    try {
      for (const key of keys(moneyStack).sort(SortTools.arrayParseNumbers(true))) {
        if (TypeTools.isNumber(moneyStack[key]) && moneyStack[key] > 0) {
          result.push(moneyStack[key] + StringTools.multiply + key);
          moneyStack[key] = 0;
        }
      }
    } catch (err) {
      result.push('?');
    }
    return result.join('&nbsp;&nbsp;');
  }
}
