import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SocketService} from '../socket/socket.service';
import {DialogService} from '../dialog.service';
import {ParsedContactWithMatches} from '../../common-interfaces/nxt.contact.interface';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {NxtArtist} from '../../common-interfaces/nxt.artist.interface';
import {NxtEmployee} from '../../common-interfaces/nxt.employee.interface';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  public static instance: CacheService;
  private contactsLoaded = false;
  // private _discountPromotions: BehaviorSubject<NxtDiscountPromotion[]>;
  private _artists: BehaviorSubject<NxtArtist[]>;
  private _parsedContacts: BehaviorSubject<ParsedContactWithMatches[]>;
  private _employees: BehaviorSubject<NxtEmployee[]>;


  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
  ) {
    CacheService.instance = this;
  }

  public waitForCalendarEvents() {
    return this.waitFor('calendarEventsWithContacts');
  }

  public waitForEmployees() {
    return this.waitFor('employees');
  }

  public waitForArtists() {
    return this.waitFor('artists');
  }

  public waitForContacts() {
    if (this.contactsLoaded) {
      return;
    }
    return this.waitFor('contacts');
  }

  public waitForCalendarEventsCanceledWithContact() {
    return this.waitFor('calendarEventsCanceledWithContacts');
  }

  private async waitFor(obj: string) {
    return new Promise<void>((resolve, reject) => {
      if (this[obj].getValue().length === 0) {
        const subscription = this[obj].subscribe((data) => {
          if (data.length > 0) {
            resolve();
            setTimeout(() => {
              subscription.unsubscribe();
            }, 100);
          }
        });
      } else {
        resolve();
      }
    });
  }

  /*public get discountPromotions() {
    if (!this._discountPromotions) {
      this._discountPromotions = new BehaviorSubject<NxtDiscountPromotionOld[]>([]);
      this.socketService.subscribeNew('getDiscountPromotions', (data) => {
        this._discountPromotions.next(data);
      });
      this.socketService.getDiscountPromotions().then((data) => {
        this._discountPromotions.next(data);
      });
    }
    return this._discountPromotions;
  }*/

  public get artists() {
    if (!this._artists) {
      this._artists = new BehaviorSubject<NxtArtist[]>([]);
      this.socketService.subscribeNew('getArtists', (data) => {
        this._artists.next(data as NxtArtist[]);
      }, {emitInitial: true, emitAfterReconnect: true});
    }
    return this._artists;
  }

  public get employees() {
    if (!this._employees) {
      this._employees = new BehaviorSubject<NxtEmployee[]>([]);
      this.socketService.subscribeNew('getEmployees', (data) => {
        this._employees.next(data as NxtEmployee[]);
      }, {emitInitial: true, emitAfterReconnect: true});
    }
    return this._employees;
  }


  public get parsedContacts() {
    if (!this._parsedContacts) {
      this._parsedContacts = new BehaviorSubject<ParsedContactWithMatches[]>([]);
      this.socketService.subscribeNew('getParsedContacts', (data) => {
        this._parsedContacts.next(this.filterParsedContacts(data));
      }, {emitInitial: true, emitAfterReconnect: true});
      setInterval(() => {
        const newParseContacts = this.filterParsedContacts(this._parsedContacts.value);
        if (newParseContacts.length !== this._parsedContacts.value.length) {
          this._parsedContacts.next(newParseContacts);
        }
      }, 10000);
    }
    return this._parsedContacts;
  }

  private filterParsedContacts(parsedContacts: ParsedContactWithMatches[]): ParsedContactWithMatches[] {
    return parsedContacts.filter(p => p.createdAt > Date.now() - DurationTools.DURATION_1MINUTE * 5);
  }
}
