@if (event(); as event) {
  <div
    [mtxTooltip]="tooltipTemplate"
    mtxTooltipClass="no-style mat-tooltip-width-content"
    [mtxTooltipPosition]="event.allDay ? 'after' : 'above'"
    [ngStyle]="{color: textColor(), backgroundColor: event.meta.employee.color}"
    [class.p-3]="duration() > DurationTools.DURATION_1HOUR"
    [class.all-center]="duration() < DurationTools.DURATION_1HOUR"
    class="flex h-full w-full flex-col items-center event"
  >
    @if (duration() < DurationTools.DURATION_1HOUR) {
      <div class="flex text-[70%]">
        <div>{{ event.meta.employee.abbreviation | nxtSubstr: 0:2 }} ⌚</div>
      </div>
    } @else if (!event.allDay) {
      <div class="flex flex-col items-center text-[75%]">
        <div>{{ event.meta.employee.abbreviation | nxtSubstr: 0:2 }}</div>
        <div class="flex flex-col text-xs center">
          <div>{{ event.meta.employeeEvent.start  | nxtTime: true }}</div>
          <div>{{ event.meta.employeeEvent.end | nxtTime: true }}</div>
        </div>
        <div class="mt-2 text-xs h-[40px]">
          <div class="absolute left-0 w-full center" style="width: calc(100% - 3px);overflow: hidden; word-break: break-all">{{ event.meta.employee.givenName }}</div>
        </div>


        @if (event.meta.employeeEvent.type === 'work') {
          @if (event.meta.employeeEvent?.workplaces?.includes('reception')) {
            <div class="state-icon">🛎️</div>
          }
          @if (event.meta.employeeEvent?.workplaces?.includes('backoffice')) {
            <div class="state-icon">🖥️</div>
          }
          @if (event.meta.employeeEvent?.workplaces?.includes('piercing')) {
            <div class="state-icon">🪡</div>
          }
          @if (event.meta.employeeEvent?.workplaces?.includes('whatsapp')) {
            <div class="state-icon">📈</div>
          }
          @if (event.meta.employeeEvent?.workplaces?.includes('whatsappFranchise')) {
            <div class="state-icon">📉</div>
          }
          @if (event.meta.employeeEvent?.workplaces?.includes('fieldService')) {
            <div class="state-icon">🚗</div>
          }


          @if (event.meta.employeeEvent?.workplaces?.includes('craft')) {
            <div class="state-icon">🔨</div>
          }
          @if (event.meta.employeeEvent?.workplaces?.includes('eventRating')) {
            <div class="state-icon">🔎</div>
          }
          <div class="mt-3 text-xs">{{ event.meta.employeeEvent.studio | uppercase }}</div>
        }
        @if (event.meta.employeeEvent.type === 'sick') {
          <div class="state-icon">😷</div>
        }
        @if (event.meta.employeeEvent.type === 'vacation') {
          <div class="state-icon">✈️</div>
        }
        @if (event.meta.employeeEvent.type === 'timeTrack') {
          <div class="state-icon">⌚</div>
        }
        @if (event.meta.employeeEvent.type === 'notHere') {
          <div class="state-icon">🆓</div>
        }
      </div>
    }
  </div>

  <ng-template #tooltipTemplate>
    <nxt-employee-calendar-event-tooltip
      [employeeEvent]="event.meta.employeeEvent"
      [employee]="event.meta.employee"
    />
  </ng-template>
}
