import {inject, Injectable} from '@angular/core';
import {DialogService} from '../dialog.service';
import {TattooTicketPaypalComponent} from '../../components/tattoo-ticket/tattoo-ticket-paypal/tattoo-ticket-paypal.component';
import {TattooTicketKlarnaComponent} from '../../components/tattoo-ticket/tattoo-ticket-klarna/tattoo-ticket-klarna.component';
import {MatDialogRef} from '@angular/material/dialog';
import {SocketService} from '../socket/socket.service';

@Injectable({
  providedIn: 'root'
})
export class TattooTicketService {
  /*** Injections ***/
  dialogService = inject(DialogService);
  socketService = inject(SocketService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
    this.dialogService.showTattooTicket.subscribe(async (shopOrderLineItemId) => {
      const shopOrder = await this.socketService.getShopOrderByLineItemId(shopOrderLineItemId);
      this.showTattooTicket(shopOrderLineItemId, shopOrder.paymentMethod);
    });
  }

  showTattooTicket(shopOrderLineItemId: string, type: 'paypal' | 'klarna_payments', fromEventId?: string) {
    let dialog: MatDialogRef<TattooTicketPaypalComponent | TattooTicketKlarnaComponent>;
    if (type === 'paypal') {
      dialog = this.dialogService.showComponentDialog(TattooTicketPaypalComponent, {shopOrderLineItemId, fromEventId});
    }
    if (type === 'klarna_payments') {
      dialog = this.dialogService.showComponentDialog(TattooTicketKlarnaComponent, {shopOrderLineItemId, fromEventId});
    }
    dialog.componentInstance.load().then();
  }
}
