<div class="nxt-page !p-0">
  <div class="flex flex-row items-center">
    <nxt-slide-toggle leftText="CashReport" [noColorChange]="true" [(value)]="showUrl1">Kalender</nxt-slide-toggle>
    <mat-tab-group class="w-full h-fit" [(selectedIndex)]="selectedIndex">
      @for (studio of studios; track studio.name) {
        <mat-tab class="w-full h-fit" [label]="studio.name" fxLayout="column"></mat-tab>
      }
    </mat-tab-group>
  </div>
  <div class="flex flex-column w-full h-full">
    @for (studio of studios; track studio.name) {
      <div [class.visible]="selectedIndex === $index && showUrl1" class="iframe-wrapper">
        <iframe class="w-full h-full" [src]="studio.url1 | safeUrl: true"></iframe>
      </div>
      <div [class.visible]="selectedIndex === $index && !showUrl1" class="iframe-wrapper">
        <iframe class="w-full h-full" [src]="studio.url2 | safeUrl: true"></iframe>
      </div>
    }
  </div>
</div>
