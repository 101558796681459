<table class="w-full" *ngIf="data">
  <tr>
    <td>Bank</td>
    <td>
      <nxt-input [(value)]="data.config.bankName" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Kontonummer</td>
    <td>
      <nxt-input [(value)]="data.config.account" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>BLZ</td>
    <td>
      <nxt-input [(value)]="data.config.BLZ" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>BIC</td>
    <td>
      <nxt-input [(value)]="data.config.bic" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>IBAN</td>
    <td>
      <nxt-input [(value)]="data.config.iban" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Login-Name</td>
    <td>
      <nxt-input [(value)]="data.config.loginName" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>PIN</td>
    <td>
      <nxt-input [(value)]="data.config.pin" type="password" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Bank URL</td>
    <td>
      <div fxLayout="row">
        <nxt-input [(value)]="data.config.bankFintsURL" [noPadding]="true"></nxt-input>
        <nxt-button-icon (click)="openFintsSite()">info</nxt-button-icon>
      </div>
    </td>
  </tr>
  <tr>
    <td>Import-Timer</td>
    <td>
      <nxt-input [isNumber]="true" [(value)]="data.timer" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <br>
      <nxt-button style="width: 100%" (click)="testBankImport()">Bank-Import testen</nxt-button>
    </td>
  </tr>
</table>
