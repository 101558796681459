import {Component, ElementRef, EventEmitter, OnInit, Optional, signal, ViewChild} from '@angular/core';
import {SocketService} from '../../../services/socket/socket.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {DialogService, LoadingId} from '../../../services/dialog.service';
import {WhatsappChatService} from './whatsapp-chat.service';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {NxtArtist} from '../../../common-interfaces/nxt.artist.interface';
import {HtmlTools} from '../../../common-browser/helpers/html.tools';
import {NxtComponent} from '../../nxt.component';
import {ArrayTools} from '../../../common-browser/helpers/array.tools';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {ClipboardService} from '../../../services/clipboard.service';
import {ActivatedRoute} from '@angular/router';
import {TextareaAutoSizeDirective} from '../../../directives/textarea-auto-size.directive';
import {PermissionService} from '../../../services/permission.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {LocalStorageService} from '../../../services/local-storage.service';
import {StudioRegionBrowserTools} from '../../../common-browser/helpers/studio-region-browser.tools';
import {LoginService} from '../../../services/login.service';
import {Log} from '../../../common-browser/log/log.tools';
import {firstValueFrom} from 'rxjs';
import {NxtDynamicDataType, NxtWhatsAppImageSendInsert} from '../../../common-interfaces/dynamic-data/dynamic-data.interface';
import {WhatsAppTools} from '../../../common-browser/helpers/whats-app.tools';
import {PaypalCheckoutService} from '../../../services/paypal-checkout.service';
import {NxtCalendarEventBodyPutTattoo} from '../../../common-interfaces/nxt.calendar-event.interface';
import {DialogSendMessageToArtistComponent} from '../../../controls/dialog/dialog-send-message-to-artist/dialog-send-message-to-artist.component';
import {ConfigService} from '../../../services/config.service';
import {NxtContact} from '../../../common-interfaces/nxt.contact.interface';
import {MobileTools} from '../../../common-browser/helpers/mobile.tools';
import {ArtistsGalleryLinksComponent} from '../../artists-gallery-links/artists-gallery-links.component';
import {BodyPutTextPipe} from '../../../pipes/body-put-text.pipe';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {FormsModule} from '@angular/forms';
import {ObserveVisibilityDirective} from '../../../directives/observe-visibility.directive';
import {CheckboxComponent} from '../../form-controls/checkbox/checkbox.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {InputComponent} from '../../form-controls/input/input.component';
import {MatTooltip} from '@angular/material/tooltip';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {JsonPipe, NgClass, NgFor, NgIf, NgStyle} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {NxtWhatsAppChat, NxtWhatsAppMessage} from 'src/app/common-interfaces/whats-app/nxt-whatsapp-message.interface';
import {NxtWhatsAppImage, NxtWhatsAppMessageUiChat} from '../../../common-interfaces/whats-app/nxt-whatsapp-message.interface';
import {ContextMenuComponent} from '../../context-menu/context-menu.component';
import {MatIcon} from '@angular/material/icon';
import {MatMenuItem} from '@angular/material/menu';
import {ReminderService} from '../../../services/reminder.service';
import {TimeTools} from '../../../common-browser/helpers/time.tools';
import {TattooTemplateService} from '../../../services/tattoo-template.service';
import {WhatsappDialogService} from '../whatsapp-dialog.service';
import {NxtWhatsAppChatPinned} from '../../../common-interfaces/whats-app/nxt.whats-app-chat-pinned.interface';
import {FromNowPipe} from '../../../pipes/from-now.pipe';

@Component({
  selector: 'nxt-whatsapp-chat',
  templateUrl: './whatsapp-chat.component.html',
  styleUrls: ['./whatsapp-chat.component.scss', './whatsapp-chat-wa-style.component.scss'],
  imports: [FlexModule, NgClass, ExtendedModule, SlideToggleComponent, NxtButtonIconComponent, MatTooltip, InputComponent, NgIf, NgFor, NxtButtonComponent, NgStyle, InfiniteScrollModule, CheckboxComponent, ObserveVisibilityDirective, FormsModule, TextareaAutoSizeDirective, JsonPipe, SafeHtmlPipe, BodyPutTextPipe, NxtDatePipe, ContextMenuComponent, MatIcon, MatMenuItem, FromNowPipe],
})
export class WhatsappChatComponent extends NxtComponent implements OnInit {

  @ViewChild('textareaTextInput') textarea: ElementRef;
  public chat: NxtWhatsAppChat;
  public bodyPutsTattoo: NxtCalendarEventBodyPutTattoo[];
  public skills: any[];
  public eventId = '';
  contact?: NxtContact;
  contactMobiles: { m: string, t: number }[] = [];
  isUnread = signal(false);
  onAddTemplateImage = new EventEmitter<any>();
  pinnedChat: NxtWhatsAppChatPinned;

  constructor(
    @Optional() public dialogRef: MatDialogRef<WhatsappChatComponent>,
    private socketService: SocketService,
    private dialogService: DialogService,
    private whatsAppChatService: WhatsappChatService,
    private whatsappDialogService: WhatsappDialogService,
    private clipboardService: ClipboardService,
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionService,
    private clipboard: Clipboard,
    private storageService: LocalStorageService,
    private loginService: LoginService,
    private paypalCheckoutService: PaypalCheckoutService,
    private configService: ConfigService,
    private reminderService: ReminderService,
    private tattooTemplateService: TattooTemplateService,
  ) {
    super();
    const temp = this.storageService.get('WhatsAppChatStyle', '');
    this.newStyle = temp === 'WhatsApp';
    this.loadArtists();
  }

  @ViewChild('textareaAutoSize') textareaAutoSize: TextareaAutoSizeDirective;
  imagesSrc: { [messageId: string]: NxtWhatsAppImage } = {};
  public messages: NxtWhatsAppMessageUiChat[] = [];
  public messagesFiltered: NxtWhatsAppMessageUiChat[] = [];
  public messagesVisible: NxtWhatsAppMessageUiChat[] = [];
  public title = '';
  // currentData: any;
  newStyle = false;
  currentArtist: NxtArtist;
  currentWhatAppId = '';
  selectedMessages: { [message: string]: boolean } = {};
  reloadRunning: { [messageId: string]: boolean } = {};
  textToSend = '';
  private artists: NxtArtist[];
  message = '';
  scrollDownForce = false;
  interval: any;
  visibility = 'hidden';
  array = [];
  sum = 100;
  throttle = 300;
  direction = '';
  currentMessageIndex = -1;
  toAddCount = 20;
  imageReloadCounter: { [messageId: string]: number } = {};
  public filterText = '';
  onlyFromCustomer = false;

  ngOnInit(): void {
    // this.startReload();
    this.subscribeOnNewEvent();


    if (this.activatedRoute.snapshot.paramMap.get('id')) {
      this.loadChat(this.activatedRoute.snapshot.paramMap.get('id'));
    }
  }

  nxtOnDestroy(): void {
    clearInterval(this.interval);
    this.whatsAppChatService.clearCache();
  }

  public async loadChat(mobileOrWhatsAppId: string, contactId?: string) {
    const mobile = mobileOrWhatsAppId = '+' + WhatsAppTools.parseWhatsAppId(mobileOrWhatsAppId, false);
    this.currentWhatAppId = WhatsAppTools.parseWhatsAppId(mobileOrWhatsAppId, true);
    if (!mobileOrWhatsAppId) {
      this.dialogService.showOk('Es ist keine Handynummer hinterlegt');
      this.dialogRef.close();
    }
    // this.currentData = data;

    this.message = 'Chat wird geladen';
    const reason = 'WhatsApp Chat';
    this.checkUnread();
    const result = await this.socketService.getWhatsAppChat(mobile, reason);
    if (!contactId) {
      const contacts = await this.socketService.getContactsByMobile(mobile);
      if (contacts.length === 1) {
        contactId = contacts[0].id;
      } else {
        if (contacts.length > 1 && mobileOrWhatsAppId.includes('15120123232')) {
          contactId = contacts[0].id;
        }
      }
    }
    if (contactId) {
      this.contactMobiles = [];
      this.contact = await this.socketService.getContactById(contactId);
      if (!this.contact) {
        Log.error('WhatsAppChat kann nicht geladen werden, Kontakt nicht gefunden\ncontactId: ' + contactId);
        this.dialogRef.close();
      }
      if (mobileOrWhatsAppId) {
        this.title = this.contact.fullName + ' (' + MobileTools.formatHuman('+' + WhatsAppTools.parseWhatsAppId(mobileOrWhatsAppId, false)) + ')';
      }
      if (this.contact.oldMobilesFormatted) {
        for (const mobile of this.contact.oldMobilesFormatted) {
          this.contactMobiles.push({m: WhatsAppTools.parseWhatsAppId(mobile.m, true), t: mobile.t});
        }
      }
      this.contactMobiles.push({m: WhatsAppTools.parseWhatsAppId(this.contact.mobileFormatted, true), t: -1});
    } else {
      this.title = result.chat.name;
    }
    this.chat = result.chat;
    this.pinnedChat = result.pinnedChat;
    this.message = result.messages.length > 0 ? '' : 'Es gibt kein Chat für ' + this.title;
    if (result.messages.length !== this.messages.length) {
      this.messages = result.messages as any;
      for (const [index, message] of this.messages.entries()) {
        if (index > 0) {
          this.prepareMessage(message, this.messages[index - 1].timestamp);
        } else {
          this.prepareMessage(message, 0);
        }
      }
      this.setMessagesFiltered();
      this.prependMessages();
      this.scrollToEnd('auto');
    } else if (this.messages.length > 0) {
      this.visibility = '';
    }
  }

  close() {
    this.dialogRef?.close();
    this.prependMessages();
  }

  async showImage(message: NxtWhatsAppMessage) {
    const image = await this.whatsAppChatService.getImage(message.mediaKey);
    this.dialogService.showImageViewer(['data:' + image.mimetype + ';base64,' + image.data]);
  }

  async preloadImage(message: NxtWhatsAppMessage) {
    if (!this.imagesSrc[message.id] || this.imagesSrc[message.id].notInDb) {
      const image = await this.whatsAppChatService.getImage(message.mediaKey);
      if (!this.imageReloadCounter[message.id]) {
        this.imageReloadCounter[message.id] = 0;
      }
      if (image) {
        image.src = this.getImageSrc(image);
        this.imagesSrc[message.id] = image;
      } else if (this.imageReloadCounter[message.id] < 10 && DateTools.dateDiffToNow(message.timestamp) < DurationTools.DURATION_1MINUTE * 3) {
        setTimeout(() => {
          this.imageReloadCounter[message.id]++;
          this.preloadImage(message);
        }, 500);
      } else {
        this.imagesSrc[message.id] = {id: message.id, width: 0, height: 0, notInDb: true};
      }
    }
  }

  getImageSrc(whatsAppImage: NxtWhatsAppImage) {
    if (whatsAppImage.data) {
      return 'data:' + whatsAppImage.mimetype + ';base64,' + whatsAppImage.data;
    }
    return '';
  }


  async reloadImage(message: NxtWhatsAppMessage) {
    try {
      this.reloadRunning[message.id] = true;
      await this.socketService.reloadWhatsAppImage(message.id);
      this.preloadImage(message);
    } catch (err) {
      this.dialogService.showOk('Fehler beim Laden des Bildes\n' + err.message);
    } finally {
      this.reloadRunning[message.id] = false;
    }
  }

  async forwardToArtist(message: NxtWhatsAppMessage) {
    let artistToSend: NxtArtist;

    let sendAdditionalInfoMessage = false;

    let messageIds: string[] = [message.id];

    if (Object.keys(this.selectedMessages).length > 0) {
      messageIds.push(...Object.keys(this.selectedMessages).map(k => k));
      messageIds = ArrayTools.unique(messageIds);
    }

    const selectedMessages = this.messages.filter(m => messageIds.includes(m.id));
    const selectedMessagesWithImage: (NxtWhatsAppMessage & { dateHtml: string })[] = [];

    let textLines: string[] = [];
    for (const selectedMessage of selectedMessages) {
      if (selectedMessage.hasMedia && selectedMessage.type === 'image') {
        selectedMessagesWithImage.push(selectedMessage);
        if (selectedMessage.body) {
          textLines.push(selectedMessage.originalBody);
        }
      } else if (!selectedMessage.hasMedia) {
        if (selectedMessage.body) {
          textLines.push(selectedMessage.originalBody);
        }
      }
    }

    let options: { text: string, value: any }[] = this.artists.map(a => ({text: a.name, value: a})).sort(SortTools.sortString('text'));
    // const text = 'An einen Artist weiterleiten';
    const value = this.currentArtist ? options.find(o => o.text === this.currentArtist?.name)?.value : null;
    const artistsInStudio = {text: 'Alle Artist aktuell im Studio', value: {name: 'Alle Artist aktuell im Studio', id: 'all-available', mobile: 'all-available'}};
    const allArtists = {text: 'Alle Alle Artist', value: {name: 'Alle Alle Artist', id: 'all-artists', mobile: 'all-artists'}};

    options = [artistsInStudio, ...options];
    if (this.permissionService.isNiklas() || this.permissionService.isJulian()) {
      options = [allArtists, ...options];
    }
    const result = await this.dialogService.showSelect('', options, {placeholder: 'Artist auswählen', value, title: 'An einen Artist weiterleiten'});
    if (result) {
      artistToSend = result;
    }

    if (!artistToSend) {
      return;
    }

    let descriptionInputMessage = 'Beschreibe das Design\n\nWas soll anders als das Bild\n\nWie groß soll es werden?\n\nSonstige Infos!';

    if (this.skills && this.skills.includes('Lettering')) {
      descriptionInputMessage += '<br/><br/><div class="uppercase bold red">ACHTUNG!\nEs handelt sich um ein Schriftzug,<br/>leite dem Artist 1 zu 1 den geschriebenen Text vom Kunden weiter!\n\nFordere den Text vom Kunden an, wenn er nicht hier im Chat ist!</div>';
    }

    const city = this.configService.config.value.invoiceData.fromCity;

    if (artistToSend.id === 'all-available') {
      textLines = ['This message goes to all artists who are currently at NXT-LVL.INK ' + city + '.\n\n', ...textLines];
    } else if (artistToSend.id === 'all-artists') {

    } else {
      const errorText = await this.socketService.checkArtistTelegramChat(artistToSend.id);
      if (errorText) {
        await this.dialogService.showOk(errorText);
        this.dialogRef?.close();
        this.reminderService.showReminderBySubId('ArtistTelegramChat_' + artistToSend.name);
        return;
      }
    }

    const dialog = this.dialogService.showComponentDialog(DialogSendMessageToArtistComponent);
    const artistLang = artistToSend?.lang || 'en';
    dialog.componentInstance.setOptions({message: descriptionInputMessage, prompt: textLines.join('\n'), bodyPutsTattoo: this.bodyPutsTattoo, skills: this.skills, artistLang});

    const messageToArtist = await firstValueFrom(dialog.afterClosed());
    if (typeof messageToArtist === 'undefined') {
      return;
    }
    if (artistToSend.id === 'all-artists' && !await this.dialogService.showYesNo('Sicher an Alle Artists die aktiv sind?')) {
      return;
    }
    this.dialogService.showLoading(LoadingId.WhatsAppImageForwardToArtist, 'Nachricht(en) werden an ' + artistToSend.name + ' weitergeleitet');
    try {
      for (const [index, selectedMessageWithImage] of selectedMessagesWithImage.entries()) {
        this.dialogService.updateLoadingText('Bild ' + (index + 1) + ' von ' + selectedMessagesWithImage.length + ' wird an ' + artistToSend.name + ' gesendet');
        let forwardResult;
        if (artistToSend.id === 'all-available') {
          forwardResult = await this.socketService.forwardWhatsAppMessageToAllAvailableArtist(selectedMessageWithImage.id, true);
        } else if (artistToSend.id === 'all-artists') {
          forwardResult = await this.socketService.forwardWhatsAppMessageToAllArtists(selectedMessageWithImage.id, true);
        } else {
          forwardResult = await this.socketService.forwardWhatsAppMessageToArtist(selectedMessageWithImage.id, artistToSend.id, true);
        }
        if (!forwardResult.success) {
          await this.dialogService.showOk(forwardResult.error);
          this.dialogService.hideLoading(LoadingId.WhatsAppImageForwardToArtist);
          return;
        }
      }
      if (messageToArtist) {
        this.dialogService.updateLoadingText('Text wird  an ' + artistToSend.name + ' gesendet');
        if (artistToSend.id === 'all-available') {
          await this.socketService.sendWhatsAppMessageToAllArtistsInStudio(messageToArtist);
        } else if (artistToSend.id === 'all-artists') {
          await this.socketService.sendWhatsAppMessageToAllArtists(messageToArtist);
        } else {
          // await this.socketService.sendWhatsAppMessage(artistToSend.mobile, messageToArtist);
          await this.socketService.sendWhatsAppMessageToArtist(artistToSend.id, messageToArtist);
        }
      }
    } catch (err) {
      await this.dialogService.showOk('Fehler beim Senden der Nachricht an den Artist\n' + err.message);
      this.dialogService.hideLoading(LoadingId.WhatsAppImageForwardToArtist);
      return;
    }
    this.selectedMessages = {};
    this.dialogService.hideLoading(LoadingId.WhatsAppImageForwardToArtist);
  }

  private subscribeOnNewEvent() {
    this.pushSocketSubscription = this.socketService.subscribeNew('eventWhatsAppChatChanged', data => {
      if (this.chat?.id === data?.record?.id) {
        this.chat = data.record;
        this.isUnread.set(this.chat.unreadCount !== 0);
        console.log(this.chat.unreadCount);
      }
    });

    this.pushSocketSubscription = this.socketService.subscribeNew('eventWhatsAppChatPinnedChanged', data => {
      if (this.currentWhatAppId === WhatsAppTools.toWhatsAppId(data.record.id)) {
        this.pinnedChat = data.record;
      }
    });


    this.pushSocketSubscription = this.socketService.subscribeNew('eventNewWhatsappMessage', (message) => {
      if (message.from === this.currentWhatAppId || message.to === this.currentWhatAppId) {
        if (this.messages.length > 0) {
          this.prepareMessage(message as any, this.messages[this.messages.length - 1].timestamp);
        } else {
          this.prepareMessage(message as any, 0);
        }
        this.messages.push(message as any);
        this.setMessagesFiltered();
        this.messagesVisible.push(message as any);
        this.scrollToEndIfOnEnd();
      }
    }, {emitInitial: true});
  }

  private async loadArtists() {
    this.artists = await this.socketService.getArtists();
  }

  copyToClipboard(id: string) {
    this.clipboardService.copyToClipboard(id);
  }

  send() {
    if (!this.textToSend.trim()) {
      return;
    }
    if (this.currentWhatAppId) {
      this.socketService.sendWhatsAppMessage(this.currentWhatAppId, this.textToSend.trimChar('\n'));
    } else {
      this.socketService.sendWhatsAppMessage(this.activatedRoute.snapshot.paramMap.get('id'), this.textToSend);
    }

    this.textToSend = '';
    requestAnimationFrame(() => this.textareaAutoSize.resize());
    this.scrollDownForce = true;
    setTimeout(() => {
      this.scrollDownForce = false;
    }, 1000);
  }

  textAreaKeyUp(ev: KeyboardEvent) {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.stopPropagation();
      ev.preventDefault();
      this.send();
    }
  }

  textAreaKeyDown(ev: KeyboardEvent) {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.stopPropagation();
      ev.preventDefault();
    }
  }

  private prepareMessage(m: NxtWhatsAppMessage & { dateHtml: string, originalBody: string, hoursToLastMessage: number }, lastMessageTimestamp: number) {
    m.originalBody = m.body;
    m.body = m.body.replace(/\*(.*)\*/g, '<strong>$1</strong>');
    m.body = m.body.replace(/\n/g, '<br/>');
    m.body = HtmlTools.linkify(m.body, '_blank');

    if (DateTools.formatNowDate() === m.timestamp.dateFormat('yyyy-MM-dd')) {
      m.dateHtml = 'Heute vor ' + DurationTools.format2(Date.now() - m.timestamp, {
        withoutSeconds: true,
        futurePrefix: '',
        pastPrefix: '',
      }) + ' um ' + DateTools.format(m.timestamp, 'HH:mm');
    } else {
      const dayDiffToNow = DateTools.dayDiff(Date.now(), m.timestamp);
      if (dayDiffToNow === 1) {
        m.dateHtml = ' Gestern ' + DateTools.format(m.timestamp, 'EEE dd.MM HH:mm');
      } else {
        if (m.timestamp.dateFormat('yyyy') !== Date.now().dateFormat('yyyy')) {
          m.dateHtml = ' vor ' + dayDiffToNow + ' Tagen ' + DateTools.format(m.timestamp, 'EEE dd.MM.yyyy HH:mm');
        } else {
          m.dateHtml = ' vor ' + dayDiffToNow + ' Tagen ' + DateTools.format(m.timestamp, 'EEE dd.MM HH:mm');
        }
      }
    }
    if (m.fromMe && m.nxtUsername) {
      m.dateHtml += ' - ' + m.nxtUsername;
    }
    if (lastMessageTimestamp === 0) {
      m.hoursToLastMessage = 0;
    } else {
      m.hoursToLastMessage = DateTools.dayDiff(m.timestamp, lastMessageTimestamp);
    }
    /*if (this.permissionService.hasPermission(NxtPermissionId.WhatsAppBroadcast)) {
      m.dateHtml += '<br/>' + m.id;
    }*/
  }

  private scrollToEndIfOnEnd(counter = 0) {
    const elem = document.querySelector('.scrollDiv');
    if (elem) {
      if (this.scrollDownForce || Math.abs(elem.scrollHeight - (elem.scrollTop + elem.clientHeight)) < 3) {
        this.scrollToEnd('smooth');
      }
    } else {
      if (counter < 20) {
        requestAnimationFrame(() => {
          this.scrollToEndIfOnEnd(counter + 1);
        });
      }
    }
  }

  scrollToEnd(behavior) {
    requestAnimationFrame(() => {
      document.querySelector('.scrollDiv')?.scrollTo({top: document.querySelector('.scrollDiv').scrollHeight, behavior});
      requestAnimationFrame(() => {
        document.querySelector('.scrollDiv')?.scrollTo({top: document.querySelector('.scrollDiv').scrollHeight, behavior});
        requestAnimationFrame(() => {
          document.querySelector('.scrollDiv')?.scrollTo({top: document.querySelector('.scrollDiv').scrollHeight, behavior});
          this.visibility = '';
        });
      });
    });
  }

  scrolledUp() {
    this.prependMessages();
    setTimeout(() => {
      const elem = document.querySelector('.scrollDiv');
      if (elem && elem.scrollTop === 0) {
        // this.prependMessages();
        // elem.scrollTo({top: 100});
      }
    }, 400);
  }

  private loadTillMessageId(messageId: string) {
    if (this.messagesVisible.find(m => m.id === messageId)) {
      return;
    } else {
      const lengthBefore = this.messagesVisible.length;
      Log.info('loadTillMessageId prependMessages because not found');
      this.prependMessages();
      const lengthAfter = this.messagesVisible.length;
      if (lengthAfter > lengthBefore) {
        Log.info('loadTillMessageId lengthBefore: ' + lengthBefore + '  lengthAfter' + lengthAfter);
        return this.loadTillMessageId(messageId);
      }
    }
  }

  private prependMessages() {
    if (this.currentMessageIndex === -1) {
      this.currentMessageIndex = this.messagesFiltered.length;
    }
    let from = this.currentMessageIndex - this.toAddCount;
    if (from < 0) {
      from = 0;
    }
    const to = this.currentMessageIndex;
    if (to > 0) {
      const elem = document.querySelector('.scrollDiv');
      if (elem.scrollTop === 0) {
        elem.scrollTo({top: 1});
      }
      const toAdd = this.messagesFiltered.slice(from, to);
      Log.debug('to: ' + to + '   | add ' + toAdd.length);
      this.messagesVisible.splice(0, 0, ...toAdd);
      this.currentMessageIndex -= this.toAddCount;
      if (this.currentMessageIndex < 0) {
        this.currentMessageIndex = 0;
      }
    }
  }

  async showMessageId(message: NxtWhatsAppMessage) {
    this.socketService.setWhatsAppMessageIdAsBroadcast(message.id);
    await this.dialogService.showOk(message.id, {buttonText: 'Ab in die Zwischenablage'});
    this.clipboard.copy(message.id);
  }

  safeStyle() {
    this.storageService.set('WhatsAppChatStyle', this.newStyle ? 'WhatsApp' : 'NXT');
  }

  async sendToNiklas() {
    // this.socketService.sendWhatsAppMessage('+4917642725407', StudioRegionBrowserTools.getLinkForChat(this.currentWhatAppId));
    const result = await this.dialogService.showInput('Schreib eine Info dazu', {title: 'Info dazu'});
    if (result) {
      this.socketService.sendWhatsAppMessage('+4915120123232', 'Von: ' + this.loginService.getUsername() + '\n' + result + '\n' + StudioRegionBrowserTools.getLinkForChat(this.currentWhatAppId));
    }
  }

  public filterTextChanged() {
    this.messagesVisible = [];
    if (this.messages.length === 0) {
      return;
    }
    this.setMessagesFiltered();
    if (this.filterText && this.filterText.length > 0 || this.onlyFromCustomer) {
      this.messagesVisible = [];
      this.currentMessageIndex = -1;
      this.prependMessages();
    } else {
      this.currentMessageIndex = -1;
      this.prependMessages();
    }
  }

  private setMessagesFiltered() {
    if (this.filterText) {
      const words = this.filterText.split(' ');
      this.messagesFiltered = this.messages.filter(m => m.body.toLowerCase().includes(this.filterText.toLowerCase()));
    } else {
      this.messagesFiltered = this.messages;
    }
    if (this.onlyFromCustomer) {
      this.messagesFiltered = this.messagesFiltered.filter(m => !m.fromMe);
    }
  }

  public async addToSendImages(message: NxtWhatsAppMessage & { dateHtml: string }) {
    if (await this.dialogService.showYesNo('Bild als Whats-App-Vorlage speichern?')) {
      const data: NxtWhatsAppImageSendInsert = {
        type: NxtDynamicDataType.WhatsAppImageSend,
        disabled: false,
        name: 'Vorlage',
        base64: this.imagesSrc[message.id]?.src,
        whatsAppMediaKey: message.mediaKey,
        whatsAppMessageId: message.id,
      };
      this.socketService.upsertDynamicDataBatch([data]);
    }
  }

  public copyUrl() {
    this.clipboard.copy(window.origin + '/chat/' + WhatsAppTools.parseWhatsAppId(this.currentWhatAppId));
  }

  public async paypal() {
    await this.paypalCheckoutService.showCreatePaypalPayment({
      value: null,
      name: this.chat?.name ? this.chat.name.split(',')[0] : '',
      mobile: WhatsAppTools.toMobile(this.chat.id),
      contactId: null,
      infoInternal: this.chat?.name,
    });
  }

  public jumpToMessage(messageId: string) {
    const message = this.messages.find(m => m.id === messageId);
    if (message) {
      let messageElem = document.querySelector('#message-' + messageId);
      if (messageElem) {
        messageElem.scrollIntoView({behavior: 'auto', block: 'center', inline: 'center'});
      } else {
        this.loadTillMessageId(messageId);
        requestAnimationFrame(() => {
          messageElem = document.querySelector('#message-' + messageId);
          messageElem.scrollIntoView({behavior: 'auto', block: 'center', inline: 'center'});
        });
      }

      setTimeout(() => this.highlightMessage(messageId), 500);
    } else {
      this.dialogService.showOk('Nachricht nicht gefunden :(');
    }
  }

  private highlightMessage(messageId: string) {
    const messageElem = document.querySelector('#message-' + messageId) as HTMLDivElement;
    if (messageElem) {
      messageElem.classList.add('animate__animated');
      messageElem.classList.add('animate__tada');
      setTimeout(() => {
        messageElem.classList.remove('animate__animated');
        messageElem.classList.remove('animate__tada');
      }, 2500);
    }
  }

  public async copyConversationAfter(message: NxtWhatsAppMessageUiChat) {
    const oldValue = message.hoursToLastMessage;
    message.hoursToLastMessage = -1;
    const lines: string[] = [];
    let pushIt = false;
    this.messages.forEach(m => {
      if (m.id === message.id) {
        pushIt = true;
      }
      if (pushIt) {
        if (m.body) {
          if (m.fromMe) {
            lines.push('Verkäufer: ' + m.body);
          } else {
            lines.push('Kunde: ' + m.body);
          }
        }
      }
    });
    const toCopy = lines.join('\n\n');
    this.clipboard.copy(toCopy);
    const prompt = 'Fasse die folgende Konversation stichwortartig in 60 wörtern zusammen: (setze Zeilenumbrüche)' +
      '\n\n###\n\n' + toCopy + '\n###\n';
    const result = await this.socketService.openAiQuestion(prompt);
    this.dialogService.showOk(result);
    message.hoursToLastMessage = oldValue;
  }

  onlyFromCustomerChanged() {
    this.filterTextChanged();
  }

  loadOtherMobile(mobile: string) {
    this.messages = [];
    this.messagesFiltered = [];
    this.messagesVisible = [];
    this.currentWhatAppId = '';
    this.message = '';
    this.currentMessageIndex = -1;
    this.toAddCount = 20;
    this.title = '';
    this.loadChat(mobile, this.contact.id);
  }

  async sendArtistGallery() {
    const dialog = this.dialogService.showComponentDialog(ArtistsGalleryLinksComponent);
    const result = await firstValueFrom(dialog.afterClosed());
    if (result) {
      this.textToSend = result + ': https://artists.nxt-lvl.ink/#/artists/' + encodeURIComponent(result);
      this.textareaAutoSize.elementRef.nativeElement.focus();
      (this.textareaAutoSize.elementRef.nativeElement as HTMLTextAreaElement).setSelectionRange(this.textToSend.length, this.textToSend.length);
      this.send();
    }
  }

  bankClicked() {
    this.textToSend = 'Unsere Bankverbindung:';
    this.textToSend += '\nName: NXT-LVL.INK';
    this.textToSend += '\nBank: ' + this.configService.config.value.depositBankName;
    this.textToSend += '\nIBAN: ' + this.configService.config.value.depositBankIban.replaceAll(' ', '');
    requestAnimationFrame(() => {
      this.textareaAutoSize.resize();
    });
  }

  sendLocation() {
    this.textToSend = 'location';
    this.send();
  }

  async translateMessage(message: NxtWhatsAppMessageUiChat) {
    const result = await this.socketService.translate(message.body, 'de');
    message.body = result.text;
    message.detectedSourceLangName = result.detectedSourceLangName;
  }

  async setReadClicked() {
    await this.socketService.setWhatsAppChatRead(this.currentWhatAppId);
    this.isUnread.set(false);
    await TimeTools.sleep(500);
    this.checkUnread();
  }

  async setUnReadClicked() {
    await this.socketService.setWhatsAppChatUnRead(this.currentWhatAppId);
    this.isUnread.set(true);
    await TimeTools.sleep(500);
    this.checkUnread();
  }


  private checkUnread() {
    this.socketService.getWhatsAppChatFromClient(this.currentWhatAppId).then((chat) => {
        if (chat) {
          this.isUnread.set(chat.unreadCount !== 0);
        }
      },
    );
  }

  setImageAsTemplateClicked(message: NxtWhatsAppMessageUiChat, ev: MouseEvent) {
    this.tattooTemplateService.addTemplate({
      mobile: WhatsAppTools.toMobile(this.currentWhatAppId),
      src: this.imagesSrc[message.id]?.src,
      datetime: message.timestamp,
      eventId: this.eventId,
      messageId: message.id,
      width: this.imagesSrc[message.id]?.width,
      height: this.imagesSrc[message.id]?.height,
      createdAt: Date.now(),
    });
    (ev.target as any).style.display = 'none';
  }

  async reloadChatClicked() {
    const result = await this.socketService.readOldChatsShort(WhatsAppTools.toMobile(this.currentWhatAppId));
    if (result > 0) {
      this.dialogService.showOk('Es wurden ' + result + ' neue Nachrichten eingelesen');
      this.loadChat(this.currentWhatAppId);
    } else {
      this.dialogService.showOk('Keine neuen Nachrichten gefunden');
    }
  }

  setCheckClicked(doCheck: boolean) {
    this.chat.doRecheck = doCheck;
    this.socketService.setChatRecheck(this.chat.id, doCheck);
  }

  setPinnedClicked() {
    const mobileWithoutPlus = WhatsAppTools.toMobile(this.currentWhatAppId, false);
    this.whatsappDialogService.setChatPinnedState({
      action: this.pinnedChat?.pinned ? 'update' : 'pin',
      name: this.title,
      mobileWithoutPlus,
      openPayPalCheckout: false,
    });
  }
}
