<div class="cal-day-headers" role="row" [ngStyle]="{width: 'calc(100% - 9px)'}">
  <div
    class="cal-header p-relative"
    *ngFor="let day of days()"
    [class.cal-past]="day.isPast"
    [class.cal-today]="day.isToday"
    [class.cal-future]="day.isFuture"
    [class.cal-weekend]="day.isWeekend"
    tabindex="0"
    role="columnheader"
  >
    <div class="flex flex-col leading-tight relative">
      <div class="date-info flex flex-row justify-center items-center gap-3 whitespace-break-spaces"
           (click)="dateHeaderClicked.emit(day.date.dateFormatDate())">
        @if ((day.date | nxtDate: 'yyyy') === thisYear) {
          <div class="bold text-[25px]">{{ day.date | nxtDate: 'EE dd.MM' }}</div>
        } @else {
          <div class="bold text-[25px]">{{ day.date | nxtDate: 'EE dd.MM.yyyy' }}</div>
        }
        <div class="text-80">{{ day.date | fromNowText: true }}</div>
      </div>
      <div class="flex flex-col">
        @if (publicHolidays()[day.date | nxtDate: 'yyyy-MM-dd']) {
          <div class="public-holiday p-2">{{ publicHolidays()[day.date | nxtDate: 'yyyy-MM-dd'].name }}</div>
        }
        @if (employeesFreeDaysByDateString()[day.date.dateFormatDate()]) {
          <div class="text-[80%] pt-1 pb-2">F: {{ employeesFreeDaysByDateString()[day.date.dateFormatDate()].join(', ') }}</div>
        }
        @if (dayCheckRed()[day.date.dateFormatDate()]) {
          <div class="text-[80%] pt-1 pb-2 background-red m-3 rounded" [innerHTML]="dayCheckRed()[day.date.dateFormatDate()] | safeHtml"></div>
        }
        @if (dayCheckOrange()[day.date.dateFormatDate()]) {
          <div class="text-[80%] pt-1 pb-2 background-orange m-3 rounded" style="color: #000;"
               [innerHTML]="dayCheckOrange()[day.date.dateFormatDate()] | safeHtml"></div>
        }
      </div>
    </div>
  </div>
</div>
