import {DateTools} from './date.tools';
import {StringTools} from './string.tools';
import moment from 'moment';
import 'moment-duration-format';
import momentDurationFormatSetup from 'moment-duration-format';
import {ObjectTools} from './object.tools';

momentDurationFormatSetup(moment as any);

export class DurationTools {
  static DURATION_1MINUTE = 1000 * 60;
  static DURATION_1HOUR = DurationTools.DURATION_1MINUTE * 60;
  static DURATION_1DAY = DurationTools.DURATION_1HOUR * 24;
  static DURATION_1WEEK = DurationTools.DURATION_1DAY * 7;
  static DURATION_1YEAR = DurationTools.DURATION_1DAY * 365;

  private static units = {
    year: {short: 'Jahr', longSingle: 'Jahr', longPlural: 'Jahre'},
    month: {short: 'Mon', longSingle: 'Monat', longPlural: 'Monate'},
    week: {short: 'Wo', longSingle: 'Woche', longPlural: 'Wochen'},
    day: {short: 'Tag', longSingle: 'Tag', longPlural: 'Tage'},
    hour: {short: 'Std', longSingle: 'Stunde', longPlural: 'Stunden'},
    minute: {short: 'Min', longSingle: 'Minute', longPlural: 'Minuten'},
    second: {short: 'Sek', longSingle: 'Sekunde', longPlural: 'Sekunden'},
  };


  static format2(duration: number, options?: { withoutSeconds?: boolean, pastPrefix?: 'seit' | 'vor' | '', futurePrefix?: 'in' | 'noch' | '', onlyOneUnit?: boolean }) {
    options = ObjectTools.combineWithDefaultOptions(options, {withoutSeconds: false, pastPrefix: 'vor', futurePrefix: 'in'});
    let prefix = '';
    if (duration < 0) {
      if (options.pastPrefix) {
        prefix = options.pastPrefix + ' ';
      }
    } else {
      if (options.futurePrefix) {
        prefix = options.futurePrefix + ' ';
      }
    }
    if (duration < 0) {
      duration = duration * -1;
    }
    return prefix + DurationTools.format(duration, undefined, true, options);
  }

  static format(duration: number, format?: string, short = true, options?: { withoutSeconds?: boolean, onlyOneUnit?: boolean }) {
    if (!format) {
      const result = DurationTools.durationNew(duration);
      const text: string[] = [];
      if (result.year > 0) {
        if (result.year === 1) {
          text.push('1 ' + (short ? DurationTools.units.year.short : DurationTools.units.year.longSingle));
        } else {
          text.push(result.year + ' ' + (short ? DurationTools.units.year.short : DurationTools.units.year.longPlural));
        }
      }
      if (result.month > 0) {
        if (result.month === 1) {
          text.push('1 ' + (short ? DurationTools.units.month.short : DurationTools.units.month.longSingle));
        } else {
          text.push(result.month + ' Monaten');
        }
      }
      if (result.week > 0) {
        if (result.week === 1) {
          text.push('1 ' + (short ? DurationTools.units.week.short : DurationTools.units.week.longSingle));
        } else {
          text.push(result.week + ' ' + (short ? DurationTools.units.week.short : DurationTools.units.week.longPlural));
        }
      }
      if (result.day > 0) {
        if (result.day === 1) {
          text.push('1 ' + (short ? DurationTools.units.day.short : DurationTools.units.day.longSingle));
        } else {
          text.push(result.day + ' ' + (short ? DurationTools.units.day.short : DurationTools.units.day.longPlural));
        }
      }
      if (result.hour === 1) {
        text.push('1 ' + (short ? DurationTools.units.hour.short : DurationTools.units.hour.longSingle));
      } else {
        if (result.hour !== 0 || !short) {
          text.push(result.hour + ' ' + (short ? DurationTools.units.hour.short : DurationTools.units.hour.longPlural));
        }
      }

      if (result.minute === 1) {
        text.push('1 ' + (short ? DurationTools.units.minute.short : DurationTools.units.minute.longSingle));
      } else {
        if (result.minute !== 0 || !short) {
          text.push(result.minute + ' ' + (short ? DurationTools.units.minute.short : DurationTools.units.minute.longPlural));
        }
      }

      if (!options?.withoutSeconds) {
        if (result.second === 1) {
          text.push('1 ' + (short ? DurationTools.units.second.short : DurationTools.units.second.longSingle));
        } else {
          if (result.second !== 0 || !short) {
            text.push(result.second + ' ' + (short ? DurationTools.units.second.short : DurationTools.units.second.longPlural));
          }
        }
      }
      if (text.length === 0) {
        text.push('0 ' + DurationTools.units.second.short);
      }
      const firstNumber = parseInt(text[0].split(' ')[0], 10);
      if (options?.onlyOneUnit) {
        return text[0];
      }
      if (firstNumber > 5) {
        return text[0];
      } else if (text.length > 1) {
        return text[0] + ' ' + text[1];
      }

      return text.join(' ');
    } else {
      format = format.replace('yyyy', 'YYYY').replace('dd', 'DD');
      const momentDuration = moment.duration(duration, 'ms');
      return (momentDuration as any).format(format, {trim: false});
      /*let result = moment.utc(moment.duration(duration, 'ms').asMilliseconds()).format(format);
      if (short && result.endsWith(':00')) {
        result = result.substring(0, result.length - 3);
      }
      return result;*/
    }
  }

  static durationNew(duration: number): any {
    let d = duration / 1000;
    const r: any = {};                                                                // result
    const s: any = {                                                                  // structure
      year: 31536000,
      month: 2592000,
      week: 604800, // uncomment row to ignore
      day: 86400,   // feel free to add your own row
      hour: 3600,
      minute: 60,
      second: 1,
    };

    Object.keys(s).forEach((key) => {
      r[key] = Math.floor((d) / s[key]);
      d -= r[key] * s[key];
    });

    return r;
  }

  static getAge(birthday: any) {
    return Math.floor(moment.duration(Date.now() - DateTools.parse(birthday), 'ms').asYears());
  }

  static getTotalHoursAndMinutes(duration: number) {
    const d = moment.duration(duration, 'ms');
    return Math.floor(d.asHours()) + ':' + StringTools.fill(d.get('minutes').toString(), 2, '0', false);
  }

  static parse(duration: string): number {
    const d = moment.duration(duration.toUpperCase());
    return d.asMilliseconds();
  }

  static test() {
    const duration = DurationTools.format(DurationTools.DURATION_1MINUTE * 5, 'HH [Std.] mm [Minuten]');
    const formatted = DurationTools.format(DurationTools.DURATION_1MINUTE * 52, 'hh:mm');
    const formatted1 = DurationTools.format(DurationTools.DURATION_1MINUTE * 8, 'hh:mm');
    const formatted2 = DurationTools.format(DurationTools.DURATION_1DAY + DurationTools.DURATION_1HOUR, 'HH:mm');
    const formatted3 = DurationTools.format(DurationTools.DURATION_1DAY + DurationTools.DURATION_1HOUR, 'HH:mm');
    const formatted4 = DurationTools.format(DurationTools.DURATION_1DAY + DurationTools.DURATION_1HOUR, 'HH:mm');


    const result1 = DurationTools.parse('1:30');
    if (result1 !== 90 * DurationTools.DURATION_1MINUTE) {
      debugger;
    }

    const result2 = DurationTools.parse('10:30');
    if (result2 !== 10.5 * DurationTools.DURATION_1HOUR) {
      debugger;
    }

  }

  public static toText(duration: number) {
    return moment.duration(duration / 1000, 'seconds').humanize();
  }
}

declare global {
  interface Number {
    durationFormat(format: string): string;
  }
}

Number.prototype.durationFormat = function(this: number, format: string) {
  return DurationTools.format(this, format);
};

