export class RomanTools {
  static parseIntToRoman(number: any) {
    if (typeof number !== 'number') {
      number = parseInt(number, 10);
    }
    return new Roman().parseIntToRoman(number).toUpperCase();
  }

  static parseRomanToInt(roman: string) {
    return new Roman().parseRomanToInt(roman, true);
  }
}

class Roman {
  counter: any = {};
  romans: any = {I: 1, V: 5, X: 10, K: 50, C: 100, D: 500, M: 1000};
  subs: any = {I: true, X: true, C: true, M: true};
  getChar = ['I', 'V', 'X', 'L', 'C', 'D', 'M'];

  /***********************************
   * This first function is a pretty  *
   * simple and generic function used *
   * to define and throw errors       *
   ***********************************/

  private createError(errorName: string, errorMessage: string) {
    const theError = new Error();
    theError.name = errorName;
    theError.message = errorMessage;
    throw theError;
  }

  /***********************************
   * These next four functions are    *
   * used to test for errors in the   *
   * input and convert roman numerals *
   * into integers.                   *
   ***********************************/

  private checkRom(rcur: any, rNext: any, lSub: any, n: any, l: any) {
    if ((this.romans[rcur] === undefined) || ((this.romans[rNext] === undefined) && ((n + 1) < l))) {
      this.createError('InputError', 'Keine römische Zahl');
    } else if (this.romans[rcur] >= lSub) {
      this.createError('InputError', 'Not a Properly Formed Numeral');
    }
  }

  private testSub(cR: any, nR: any, pR: any) {
    if (this.romans[cR] < this.romans[nR]) {
      if ((this.romans[pR] === this.romans[nR]) && (this.subs[nR] !== true)) {
        this.createError('InputError', 'Not a Properly Formed Numeral');
      } else if ((this.subs[cR] === true) && (10 * this.romans[cR] >= this.romans[nR])) {
        return true;
      } else {
        this.createError('InputError', 'Not a Properly Formed Numeral');
      }
    }
    return false;
  }

  private testRom(rome: any) {
    if (this.counter[rome] < 3) {
      return true;
    } else {
      this.createError('InputError', 'Not a Properly Formed Numeral');
    }
    return false;
  }

  parseRomanToInt(rNumb: any, strict: any) {
    this.counter.I = 0;
    this.counter.V = 2;
    this.counter.X = 0;
    this.counter.L = 2;
    this.counter.C = 0;
    this.counter.D = 2;
    this.counter.M = Number.NEGATIVE_INFINITY;
    let intNumb: any = 0;
    let lastNumb = Number.POSITIVE_INFINITY;
    let thisNumb = 0;
    let lastSub = Number.POSITIVE_INFINITY;
    rNumb = rNumb.toString().toUpperCase();
    for (let i = 0; i < rNumb.length; i++) {
      const currentR = rNumb.charAt(i);
      const nextR = rNumb.charAt(i + 1);
      const prevR = rNumb.charAt(i - 1);
      try {
        if (strict !== false) {
          this.checkRom(currentR, nextR, lastSub, i, rNumb.length);
          if (this.testSub(currentR, nextR, prevR) === true) {
            thisNumb = (this.romans[nextR] - this.romans[currentR]);
            i++;
            lastSub = this.romans[currentR];
          } else if (this.testRom(currentR) === true) {
            thisNumb = this.romans[currentR];
            this.counter[currentR]++;
          }
          if (thisNumb > lastNumb) {
            this.createError('InputError', 'Not a Properly Formed Numeral');
          } else {
            intNumb += thisNumb;
            lastNumb = thisNumb;
          }
        } else {
          if ((this.romans[currentR] === undefined) || ((this.romans[nextR] === undefined) && ((i + 1) < rNumb.length))) {
            this.createError('InputError', 'Keine römische Zahl');
          } else if (this.romans[currentR] < this.romans[nextR]) {
            thisNumb = (this.romans[nextR] - this.romans[currentR]);
            i++;
          } else {
            thisNumb = this.romans[currentR];
          }
          intNumb += thisNumb;
        }
      } catch (e) {
        intNumb = -1;
        // intNumb = e.message;
        break;
      }
    }
    return intNumb;
  }

  /***********************************
   * The next two functions are used  *
   * for converting an integer into a *
   * roman Numeral.                   *
   ***********************************/

  ints(pos: number, iValue: any) {
    let charValue = '';
    const s = 2 * pos;
    if (pos > 2) {
      for (let i = 0; i < iValue * Math.pow(10, (pos - 3)); i++) {
        charValue += 'M';
      }
    } else if (iValue < 4) {
      for (let i = 0; i < iValue; i++) {
        charValue += this.getChar[s];
      }
    } else if (iValue === 4) {
      charValue = this.getChar[s] + this.getChar[s + 1];
    } else if (iValue < 9) {
      charValue = this.getChar[s + 1];
      for (let i = 0; i < iValue - 5; i++) {
        charValue += this.getChar[s];
      }
    } else if (iValue === 9) {
      charValue = this.getChar[s] + this.getChar[s + 2];
    }
    return charValue;
  }

  parseIntToRoman(intNumb: any, whichError = 1) {
    let romNumb;
    let romNumbFinal = '';
    if ((parseInt(intNumb, 10) !== intNumb) || (parseInt(intNumb, 10) < 0)) {
      try {
        if (whichError === 0) {
          this.createError('InputError', 'Cannot create a valid Roman Numeral');
        } else {
          this.createError('InputError', 'Not a positive Integer');
        }
      } catch (err: any) {
        romNumbFinal = err?.name.toString() + ': ' + err?.message.toString();
      }
    } else {
      intNumb = parseInt(intNumb, 10).toString();
      for (let k = 0; k < intNumb.length; k++) {
        const currentI = parseInt(intNumb.charAt(intNumb.length - (k + 1)), 10);
        romNumb = romNumbFinal;
        romNumbFinal = this.ints(k, currentI) + romNumb;
      }
    }
    return romNumbFinal;
  }
}

