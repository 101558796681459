<div fxLayout="column" class="nxt-page" style="overflow:auto;">
  <div *ngIf="dialogRef" fxLayoutAlign="end" fxLayout="row">
    <nxt-button-icon (click)="close()">close</nxt-button-icon>
  </div>
  <div fxLayout="row" fxLayoutGap="15px">
    <nxt-input placeholder="Rechnungsnummer" [nxtFormControl]="form.get('invoiceNumber')"></nxt-input>
    <nxt-input placeholder="Kundennummer" [nxtFormControl]="form.get('customerNumber')"></nxt-input>
    <nxt-date-picker width="350px" placeholder="Rechnungsdatum" [nxtFormControl]="form.get('invoiceDate')"></nxt-date-picker>
  </div>
  <mat-radio-group [formControl]="$any(form.get('recipientSalutation'))">
    <mat-radio-button class="mx-3" value="Frau">Frau</mat-radio-button>
    <mat-radio-button class="mx-3" value="Herrn">Herr</mat-radio-button>
  </mat-radio-group>
  <div fxLayout="row" fxLayoutGap="15px">
    <nxt-input placeholder="Name" [nxtFormControl]="form.get('recipientName')"></nxt-input>
    <nxt-input placeholder="Name Zweite Zeile" [nxtFormControl]="form.get('recipientName2')"></nxt-input>
  </div>
  <nxt-input placeholder="Straße + Nummer" [nxtFormControl]="form.get('recipientStreet')"></nxt-input>
  <div fxLayout="row" fxLayoutGap="15px">
    <nxt-input placeholder="PLZ" [nxtFormControl]="form.get('recipientPostalCode')"></nxt-input>
    <nxt-input placeholder="Ort" [nxtFormControl]="form.get('recipientCity')"></nxt-input>
  </div>


  <div fxLayout="row" fxLayoutAlign="center start">
    <nxt-button (click)="addPos()">Neue Position</nxt-button>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" *ngFor="let pos of positions; let i = index">
    <div class="nxt-text pos-number" fxLayout="row" fxLayoutAlign="center center">{{(i + 1)}}
      <nxt-button-icon (click)="removePos(i)">delete</nxt-button-icon>
    </div>
    <nxt-input placeholder="Bezeichnung" [nxtFormControl]="pos.get('name')"></nxt-input>
    <nxt-input placeholder="Menge" [isNumber]="true" [nxtFormControl]="pos.get('amount')"></nxt-input>
    <nxt-input placeholder="Einzelpreis" [isMoney]="true" [nxtFormControl]="pos.get('unitPrice')"></nxt-input>
  </div>


  <div fxFlex fxLayout="row" fxLayoutAlign="center start">
    <nxt-button (click)="generateInvoice()">Rechnung erstellen</nxt-button>
  </div>

</div>
