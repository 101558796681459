import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DateTools} from '../common-browser/helpers/date.tools';
import {NxtFormControl} from '../nxt-form/nxt.form-control';
import {NxtPayment, PaymentMethod, PaymentType} from '../common-interfaces/nxt.payment.interface';
import {UuidTools} from '../common-browser/helpers/uuid.tools';
import {ValidatorTools} from '../helpers/validator.tools';
import {ServerDate} from '../server-date';
import {NxtWorkplace} from '../common-interfaces/nxt.employee.interface';


export class Payment {

  /*static uuid() {
    return new UUID.default(1).format();
  }*/

  constructor(public data: NxtPayment) {
  }

  static createEmptyPayment(username: string, studio: string, studioReal: string, workplace: NxtWorkplace, paymentType?: PaymentType, paymentMethod?: PaymentMethod): Payment {
    const payment = new Payment({
      paymentDate: DateTools.format(Date.now(), 'yyyy-MM-dd'),
      createdAt: ServerDate.now(),
      paymentUuid: UuidTools.generate(),
      isNewPayment: true,
      studio,
      studioReal,
      createdBy: username,
      paymentMethod,
      paymentType,
      workplace,
      earlyPayout: false,
      paymentValue: 0,
      createdAtDateString: DateTools.format(Date.now(), 'yyyy-MM-dd')
    });
    return payment;
  }

  parseArrayToPaymentFormGroup(formBuilder: UntypedFormBuilder, payment: Payment): UntypedFormGroup {
    return formBuilder.group({
      paymentComment: new NxtFormControl(payment.data.paymentComment),
      paymentDate: new NxtFormControl(payment.data.paymentDate, [ValidatorTools.requiredAndNotNaN]),
      paymentMethod: new NxtFormControl(payment.data.paymentMethod, [ValidatorTools.requiredAndNotNaN]),
      paymentType: new NxtFormControl(payment.data.paymentType, [ValidatorTools.requiredAndNotNaN]),
      paymentValue: new NxtFormControl(payment.data.paymentValue, [ValidatorTools.requiredAndNotNaN, Validators.max(2000)]),
      paymentPaypalTransaction: new NxtFormControl(payment.data.paymentPaypalTransaction),
      paymentKlarnaOrder: new NxtFormControl(payment.data.paymentKlarnaOrder),
      paymentGiftCard: new NxtFormControl(payment.data.paymentGiftCard),
      paymentBankTransaction: new NxtFormControl(payment.data.paymentBankTransaction),
      paymentUuid: new NxtFormControl(payment.data.paymentUuid ? payment.data.paymentUuid : UuidTools.generate()),
      createdAt: new NxtFormControl(payment.data.createdAt),
      studio: new NxtFormControl(payment.data.studio),
      createdBy: new NxtFormControl(payment.data.createdBy),
      isNewPayment: new NxtFormControl(payment.data.isNewPayment),
      workplace: new NxtFormControl(payment.data.workplace),
      studioReal: new NxtFormControl(payment.data.studioReal),
      earlyPayout: new NxtFormControl(!!payment.data.earlyPayout),
    });
  }

  getFormGroup(fb: UntypedFormBuilder): UntypedFormGroup {
    return this.parseArrayToPaymentFormGroup(fb, this);
  }
}
