<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>InkBack Angebote</nxt-page-header-title>
    <nxt-button (click)="newOfferClicked()">NEUES ANGEBOT</nxt-button>
  </nxt-page-header>
  <nxt-page-content>
    <nxt-datagrid
      [rowData]="promotions()"
      [columnDefs]="columnDefs()"
    />


  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>
