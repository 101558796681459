<div class="nxt-page w-full h-100" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div class="text-130">Terminverlauf</div>
    <nxt-button-icon (click)="close()">close</nxt-button-icon>
  </div>
  <nxt-history
    #historyComponent
    class="w-full h-100"
    table="calendar-event"
    [id]="eventId"
    [actions]="['update', 'create']"
    [propDefs]="propDefs"
    [hiddenProps]="hiddenProps"
    [debugProps]="debugProps"
    [filterRawItem]="filterRawItem"
  ></nxt-history>
</div>
