import {NxtColDef} from './nxt-col-def';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {ProcessCellForExportParams} from 'ag-grid-community';


export class NxtDatagridExcelCellRenderers {


  static FORMAT_DATE_SHORT = {de: 'dd.MM', en: 'MM-dd'};
  static FORMAT_DATE = {de: 'dd.MM.yyyy', en: 'yyyy-MM-dd'};
  static FORMAT_DATETIME = {de: 'dd.MM.yyyy HH:mm:ss', en: 'yyyy-MM-ddTHH:mm:ss'};
  static FORMAT_DATETIMEFULL = {de: 'dd.MM.yyyy HH:mm:ss', en: 'yyyy-MM-ddTHH:mm:ss'};
  static FORMAT_TIME = {de: 'HH:mm:ss', en: 'HH:mm:ss'};


  public static getExcelCellRenderer(params: ProcessCellForExportParams, lang: string) {
    let nxtFieldType: NxtFieldType;
    if (params.node && params.node.group && !(params.column.getColDef() as any).nxtFieldType) {
      nxtFieldType = (params.node.rowGroupColumn.getColDef() as any).nxtFieldType;
    } else {
      nxtFieldType = (params.column.getColDef() as NxtColDef).nxtFieldType;
    }
    const value = params.value;
    let result: any;
    switch (nxtFieldType) {
      case NxtFieldType.Date_germanDateWithToNowText:
        if (value === 0) {
          result = '';
        } else {
          result = DateTools.format(value, NxtDatagridExcelCellRenderers.FORMAT_DATETIME[lang]);
        }
        break;
      case NxtFieldType.Date_germanDate:
        result = DateTools.format(value, NxtDatagridExcelCellRenderers.FORMAT_DATE[lang]);
        break;
      case NxtFieldType.Date_germanDateShort:
        result = DateTools.format(value, NxtDatagridExcelCellRenderers.FORMAT_DATE_SHORT[lang]);
        break;
      case NxtFieldType.Date_germanDateTimeWithSeconds:
        result = DateTools.format(value, NxtDatagridExcelCellRenderers.FORMAT_DATETIME[lang]);
        break;
      case NxtFieldType.Date_germanDateTime:
        result = DateTools.format(value, NxtDatagridExcelCellRenderers.FORMAT_DATETIME[lang]);
        break;
      case NxtFieldType.Date_germanDateTimeFull:
        result = DateTools.format(value, NxtDatagridExcelCellRenderers.FORMAT_DATETIMEFULL[lang]);
        break;
      case NxtFieldType.Date_germanTime:
        result = DateTools.format(value, NxtDatagridExcelCellRenderers.FORMAT_TIME[lang]);
        break;
      case NxtFieldType.Date_germanDateWithDayOfWeek:
        debugger;
        result = DateTools.format(value, NxtDatagridExcelCellRenderers.FORMAT_DATE[lang]);
        break;
      default:
        result = value;
        break;
    }
    return result;
  }
}
