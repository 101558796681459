@if (accounts(); as accounts) {
  <div class="flex flex-col justify-center items-center">
    <nxt-select placeholder="Firma" [options]="ownCompanies()" [(value)]="ownCompany"/>
    <nxt-select placeholder="Konto" [options]="accountOptions()" [(value)]="accountId"/>

    <nxt-input [nxtFormControl]="form.controls.name"></nxt-input>
    <nxt-input [nxtFormControl]="form.controls.iban" placeholder="IBAN"></nxt-input>
    @if (account(); as account) {
      <nxt-input [nxtFormControl]="form.controls.value" [isMoney]="true" [placeholder]="'Betrag max. ' + (account.saldo - account.limit | money)"></nxt-input>
    }
    <nxt-input [nxtFormControl]="form.controls.text"></nxt-input>
    <nxt-select [options]="users()" [nxtFormControl]="form.controls.user"></nxt-select>
    <div class="flex flex-row justify-around w-full">
      <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
      <nxt-button (click)="sendClicked()">Senden</nxt-button>
    </div>
  </div>
}
