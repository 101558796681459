@if (artist) {
  <div class="flex flex-col">
    @if (artist.internalNotes.length > 0) {
      <table class="nxt-table w-fit">
        <tr>
          <td>Datum</td>
          <td>Von</td>
          <td>Text</td>
          <td nxtPermission="Artists_DeleteNotes">Löschen</td>
        </tr>
        @for (internalNote of artist.internalNotes; track internalNote.id) {
          <tr>
            <td>
              {{ internalNote.createdAt | nxtDate: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td>
              {{ internalNote.createdBy }}
            </td>
            <td [innerHTML]="internalNote.text | safeHtml"></td>
            <td nxtPermission="Artists_DeleteNotes">
              <div class="flex flex-row justify-center w-full">
                <mat-icon (click)="deleteNoteClicked(internalNote)" class="mouse-pointer">delete</mat-icon>
              </div>
            </td>
          </tr>
        }
      </table>
    }
    <div class="flex flex-row mt-4 justify-center">
      <nxt-button (click)="addNewNoteClicked()">Neue Notiz</nxt-button>
    </div>
  </div>
}
