<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>Mitarbeiter-Zeiten</nxt-page-header-title>
    <nxt-input placeholder="Suche" class="w-[250px]" [clearIcon]="true" [(value)]="quickFilterText"></nxt-input>
    <nxt-select placeholder="Auszahlmonat" class="w-[200px]" [showClearIcon]="true" [options]="monthOptions()" [(value)]="selectedMonth">
    </nxt-select>
  </nxt-page-header>
  <nxt-page-content>
    <div class="flex h-full w-full flex-col">
      <div class="flex h-full flex-row">
        @if (true) {
          <div class="flex h-full w-[400px] flex-col items-center">
            <div>Tage</div>
            <nxt-datagrid
              [quickFilterText]="quickFilterText()"
              class="h-full w-full"
              [columnDefs]="daysColumnDefs"
              [rowData]="workDays()"
            />
          </div>
        }
        <div class="flex h-full w-2/3 flex-col items-center">
          <div>Wochen</div>
          <nxt-datagrid
            class="h-full w-full"
            [quickFilterText]="quickFilterText()"

            [columnDefs]="weeksColumnDefs"
            [rowData]="workWeeksFiltered()"
            [showFooter]="true"
          />
        </div>
        <div class="flex h-full w-1/3 flex-col items-center">
          <div>Gesamt</div>
          <nxt-datagrid
            class="h-full w-full"
            [quickFilterText]="quickFilterText()"
            uniqueRowDataKey="employeeId"
            [columnDefs]="employeeDataColumnDefs"
            [rowData]="employeeData()"
          />
        </div>
      </div>
    </div>
  </nxt-page-content>
  <nxt-page-footer>

  </nxt-page-footer>
</nxt-page>
