import {inject, Injectable} from '@angular/core';
import {DialogService} from './dialog.service';
import {NxtReminder, NxtReminderButtonAction} from '../common-interfaces/nxt.reminder.interface';
import {SocketService} from './socket/socket.service';
import {DurationTools} from '../common-browser/helpers/duration.tools';
import {ReminderViewComponent} from '../components/reminder/reminder-view/reminder-view.component';
import {ReminderEditComponent} from '../components/reminder/reminder-edit/reminder-edit.component';
import {firstValueFrom} from 'rxjs';
import {StencilService} from './stencil.service';
import {ArtistConfirmService} from './artist-confirm.service';
import {TelegramArtistService} from './telegram/telegram-artist.service';
import {IcloudComponent} from '../components/icloud/icloud.component';

@Injectable({
  providedIn: 'root',
})
export class ReminderService {
  /*** Injections ***/
  private dialogService = inject(DialogService);
  private socketService = inject(SocketService);
  private stencilService = inject(StencilService);
  private artistConfirmService = inject(ArtistConfirmService);
  private telegramArtistService = inject(TelegramArtistService);

  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  async reminderClicked(reminder: NxtReminder) {
    const dialog = this.dialogService.showComponentDialog(ReminderViewComponent);
    dialog.componentInstance.setData(reminder);
    await firstValueFrom(dialog.afterClosed());
  }

  async postponeReminder(reminder: NxtReminder) {
    if (reminder.maxPostponesReached) {
      await this.dialogService.showOk('Du hast die Aufgabe bereits zu oft verschoben.', {title: '❗JETZT ERLEDIGEN❗'});
      return;
    }
    if (reminder.postponeDurations.length === 1) {
      this.socketService.postponeReminder({reminderId: reminder.id, postponeDuration: reminder.postponeDurations[0]});
    } else {
      const backButton = {text: 'Abbrechen', value: -1};
      const buttons: { text: string, value: number }[] = [backButton];
      for (const postponeDuration of reminder.postponeDurations) {
        buttons.push({text: DurationTools.format(postponeDuration, '', true), value: postponeDuration});
      }
      const action = await this.dialogService.showButtons('Um wie viel verschieben?', {buttons});
      if (action.value !== -1) {
        this.socketService.postponeReminder({reminderId: reminder.id, postponeDuration: action.value});
      }
    }
  }

  async printArtistContract(artistId: string) {
    this.dialogService.showLoading('Artist-Vertrag wird erstellt...');
    const base64 = await this.socketService.getArtistContract(artistId);
    const artist = await this.socketService.getArtist(artistId);
    this.dialogService.hideLoading();
    const dialog = this.dialogService.showPdf(base64, 'Artist-Vertrag ' + artist.name + '.pdf');
    await firstValueFrom(dialog.afterClosed());
  }

  setReminderDone(reminderId: string, force: boolean) {
    return this.socketService.setReminderDone(reminderId, force);
  }

  async startReminderAction(reminder: NxtReminder, action: NxtReminderButtonAction) {
    switch (action) {
      case 'setDone':
        this.setReminderDone(reminder.id, false);
        break;
      case 'postpone':
        await this.postponeReminder(reminder);
        break;
      case 'openWhatsAppConnect':
        await this.dialogService.showConnectWhatsApp();
        break;
      case 'openIcloudConnect':
        const icloudDialog = this.dialogService.showComponentDialog(IcloudComponent);
        await firstValueFrom(icloudDialog.afterClosed());
        break;
      case 'showArtistContract':
        await this.printArtistContract(reminder.refId);
        break;
      case 'showEvent':
        const dialog = this.dialogService.showEvent(reminder.refId);
        await firstValueFrom(dialog.afterClosed());
        break;
      case 'setStencilSeen':
        await this.stencilService.showStencilSeenDialog(reminder.refId);
        break;
      case 'requestStencilFromArtist':
        await this.stencilService.showStencilRequestDialog(reminder.refId);
        break;
      case 'artistConfirm':
        await this.artistConfirmService.startArtistConfirmFromReminder(reminder);
        break;
      case 'startArtistTelegramChat':
        await this.telegramArtistService.startArtistChat(reminder.refId);
        break;
    }
  }

  showCreateReminder(options?: { eventId?: string }) {
    const dialog = this.dialogService.showComponentDialog(ReminderEditComponent);
    dialog.componentInstance.newReminder(options);
    return firstValueFrom(dialog.afterClosed());
  }

  async showReminderBySubId(reminderSubId: string) {
    const reminder = await this.socketService.getReminderBySubId(reminderSubId);
    if (reminder) {
      this.reminderClicked(reminder);
    }
  }
}
