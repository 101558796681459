<div class="flex flex-col w-full">
  <div class="flex flex-row justify-center mb-2">{{ headerText() }}</div>
  @if (transaction()) {
    <div class="flex flex-col gap-10 mb-3">
      <div class="flex flex-col items-center">
        <div class="text-[70%]">aus Transaktion:</div>
        <div class="text-[120%] text-center">{{ transaction().other.name }}</div>
        @if (transaction().other.name2) {
          <div class="text-[100%] text-center">{{ transaction().other.name2 }}</div>
        }
        @if (['nxt-bank', 'qonto'].includes(transaction().rawType)) {
          <div>{{ transaction().other.iban }}</div>
        }
        <div class="text-[80%] text-center">{{ transaction().text }}</div>
      </div>
      @if (transaction().nxtAi?.datevOtherSuggestion) {
        <div class="flex flex-col items-center">
          <div class="text-[70%]">Könnte sein:</div>
          <div class="text-[120%] text-center">{{ transaction().nxtAi?.datevOtherSuggestion.company }}</div>
          <div class="text-[100%] text-center">{{ transaction().nxtAi?.datevOtherSuggestion.category }}</div>
          <div class="text-[80%] text-center">{{ transaction().nxtAi?.datevOtherSuggestion.description }}</div>
        </div>
        <!--<table class="nxt-table-new">
          <tr>
            <td>Firma</td>
            <td>Kategorie</td>
            <td>Beschreibung</td>
          </tr>
          <tr>
            <td>{{ transaction().nxtAi?.datevOtherSuggestion.company }}</td>
            <td>{{ transaction().nxtAi?.datevOtherSuggestion.category }}</td>
            <td>{{ transaction().nxtAi?.datevOtherSuggestion.description }}</td>
          </tr>
        </table>-->
      }
    </div>
  }



  <nxt-autocomplete
    [displayInOptionWith]="displayType"
    [displayWith]="displayType"
    [options]="types()"
    [filterFields]="['text']"
    [nxtFormControl]="form.controls.type"
    [maxOptionsToShow]="300"
    [showClearIcon]="true"
  />
  @if (form.value.type?.text) {
    <nxt-autocomplete
      [displayInOptionWith]="displayCompany"
      [displayWith]="displayCompany"
      [options]="companiesFiltered()"
      [filterFields]="['name', 'datevNo']"
      [nxtFormControl]="form.controls.company"
      [maxOptionsToShow]="300"
      [showClearIcon]="true"
    />
  }

  @if (form.value.company?.name) {
    <div class="flex flex-row justify-center mt-4 mb-4">
      <mat-button-toggle-group [formControl]="form.controls.assignType">
        <mat-button-toggle value="invoice">Rechnung</mat-button-toggle>
        <mat-button-toggle value="transaction">Transaktion</mat-button-toggle>
        <mat-button-toggle value="none">Keine Verknüpfung</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  }

  <div class="flex flex-row justify-around mt-4">
    <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
    <nxt-button (click)="saveClicked()">Auswählen</nxt-button>
  </div>

</div>
