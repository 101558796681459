<div class="flex flex-col items-center">
  <div class="flex flex-row justify-between w-full">
    <div [innerHTML]="title | safeHtml"></div>
    <nxt-button-icon (click)="close()">close</nxt-button-icon>
  </div>
  <ng-container *ngIf="!currentItemLists && piercings">
    <div class="grid grid-cols-2 gap-4">
      <ng-container *ngTemplateOutlet="itemListTemplate; context: {list: piercings}"/>
      <ng-container *ngTemplateOutlet="itemListTemplate; context: {list: tattoo}"/>
    </div>
    <div class="pt-1">
      <nxt-button (click)="currentItemLists = artist">Artists</nxt-button>
    </div>
  </ng-container>

  <div *ngIf="currentItemLists">
    <nxt-button (click)="currentItemLists = null">zurück</nxt-button>
    <div class="grid grid-cols-6 gap-4">
      <ng-container *ngFor="let itemList of currentItemLists">
        <ng-container></ng-container>
        <ng-container *ngTemplateOutlet="itemListTemplate; context: {list: itemList}"/>
      </ng-container>
    </div>
  </div>
  <!--<div *ngIf="false">
    <ag-charts-angular
      style="height: 100%"
      [options]="chartOptions">
    </ag-charts-angular>
  </div>-->
  <ng-template #itemListTemplate let-list='list'>
    <table class="nxt-table">
      <tr>
        <th colspan="2">{{ list.name }}</th>
      </tr>
      <ng-container *ngFor="let item of list.items">
        @if (!item.hideIfZero || item.value !== 0) {
          <tr>
            <td>{{ item.name }}</td>
            <td>{{ item.valueFormatted }}</td>
          </tr>
        }
      </ng-container>
    </table>
  </ng-template>
</div>
