import {Pipe, PipeTransform} from '@angular/core';
import {NxtWhatsAppChat} from '../../../common-interfaces/whats-app/nxt-whatsapp-message.interface';

@Pipe({
  name: 'nxtWhatsappChatUsername',
  standalone: true
})
export class WhatsappChatUsernamePipe implements PipeTransform {

  constructor() {
  }

  transform(chat: NxtWhatsAppChat): any {
    if (chat.lastBodyCustomerTimestamp > chat.lastBodyMeTimestamp) {
      if (chat.name.startsWith('+')) {
        return chat.name;
      }
      return chat.name.split(' ')[0];
    } else {
      return chat.lastBodyMeUser;
    }
  }
}
