<div fxFlex fxLayout="column">


  <div fxFlex fxLayout="row" fxLayoutAlign="center center">
    <nxt-input placeholder="Vorher" [(value)]="priceStart" [isMoney]="true" (keyup)="priceStartChanged()"></nxt-input>
    <nxt-slide-toggle [(value)]="fixPriceStart" (valueChange)="fixPriceStartChanged()"></nxt-slide-toggle>
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="center center">
    <nxt-input placeholder="Rabatt" [(value)]="discountPercentage" [isPercent]="true" (keyup)="discountPercentageChanged()"></nxt-input>
    <nxt-slide-toggle [(value)]="fixDiscountPercentage" (valueChange)="fixDiscountPercentageChanged()"></nxt-slide-toggle>
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="center center">
    <nxt-input placeholder="Endpreis" [(value)]="priceEnd" (keyup)="priceEndChanged()" [isMoney]="true"></nxt-input>
    <nxt-slide-toggle [(value)]="fixPriceEnd" (valueChange)="fixPriceEndChanged()"></nxt-slide-toggle>
  </div>

  <div fxFlex fxLayout="row" fxLayoutAlign="center center">
    <nxt-button (click)="close()">OK</nxt-button>
  </div>

</div>
