import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DialogService} from './dialog.service';
import {ObjectTools} from '../common-browser/helpers/object.tools';
import {SortTools} from '../common-browser/helpers/sort.tools';
import {ClipboardService} from './clipboard.service';
import {NxtCalendarEventBodyPuts, NxtCalendarEventSmall} from '../common-interfaces/nxt.calendar-event.interface';
import {SocketService} from './socket/socket.service';
import {BodyPutTools, NxtBodyPut} from '../common-browser/helpers/body-put.tools';

@Injectable({
  providedIn: 'root',
})
export class BodyPutService {
  private bodyPuts: any;
  private bodyPutsDe: any;
  private bodyPutsPiercingDe: any;
  private bodyPutsPiercingEn: any;
  private piercingTypesDe: any;
  private piercingTypes: any;
  private bodyPutsPiercing: any;
  private bodyPutsEn: any;

  constructor(
    private dialogService: DialogService,
    private httpClient: HttpClient,
    private clipboardService: ClipboardService,
    private socketService: SocketService,
  ) {
    setTimeout(() => {
      if (!this.socketService.disableSocket) {
        this.getNotTranslated();
      }
    }, 4000);
  }

  public async init() {
    this.bodyPuts = await this.httpClient.get('/assets/body-puts/body-puts.json?temp=' + Date.now()).toPromise();
    this.bodyPutsDe = await this.httpClient.get('/assets/body-puts/body-puts-de.json?temp=' + Date.now()).toPromise();
    this.bodyPutsEn = await this.httpClient.get('/assets/body-puts/body-puts-en.json?temp=' + Date.now()).toPromise();
    this.bodyPutsPiercing = await this.httpClient.get('/assets/body-puts/body-puts-piercing.json?temp=' + Date.now()).toPromise();
    this.bodyPutsPiercingDe = await this.httpClient.get('/assets/body-puts/body-puts-piercing-de.json?temp=' + Date.now()).toPromise();
    this.bodyPutsPiercingEn = await this.httpClient.get('/assets/body-puts/body-puts-piercing-en.json?temp=' + Date.now()).toPromise();
    this.piercingTypesDe = await this.httpClient.get('/assets/body-puts/piercing-types-de.json?temp=' + Date.now()).toPromise();
    this.piercingTypes = await this.httpClient.get('/assets/body-puts/piercing-types.json?temp=' + Date.now()).toPromise();
  }

  /*public getBodyPuts() {
    return this.bodyPuts;
  }*/

  /*public getBodyPutDe() {
    return this.bodyPutsDe;
  }*/

  private async waitForBodyPutsRead() {
    if (this.bodyPuts && this.bodyPutsDe && this.bodyPutsPiercing && this.bodyPutsPiercingDe) {
      return;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => resolve(this.waitForBodyPutsRead()), 100);
      });
    }
  }

  private getButtonsFromPath(data: any, path: string[], bodyPutDe): { buttons: { text: string, value: string }[], lastPath: boolean } {
    let dataToUse = ObjectTools.clone(data);
    path.forEach(p => dataToUse = dataToUse[p]);

    if (Array.isArray(dataToUse)) {
      return {buttons: this.getButtonsFromArray(dataToUse, bodyPutDe), lastPath: true};
    }
    const buttons = Object.keys(dataToUse).map(k => {
      if (bodyPutDe[k]) {
        return {text: bodyPutDe[k], value: k};
      } else {
        return {text: k + ' xxx(nicht übersetzt)', value: k};
      }
    });

    return {buttons, lastPath: false};
  }

  private getButtonsFromArray(arr: string[], bodyPutDe) {
    return arr.map(k => {
      if (typeof k === 'number') {
        return {text: k, value: k};
      }
      if (bodyPutDe[k]) {
        return {text: bodyPutDe[k], value: k};
      } else {
        return {text: k + ' <span style="font-size:12pt">(xxxnicht übersetzt)</span>', value: k};
      }
    });
  }

  async showBodyPutChooser(canChooseGroup = false): Promise<string[]> {
    const result = await this.showButtons(this.bodyPuts, this.bodyPutsDe, [], canChooseGroup);
    return result;
  }

  async showBodyPutPiercingChooser(): Promise<string> {
    let result: any;
    result = await this.showButtons(this.bodyPutsPiercing, this.bodyPutsPiercingDe);
    return result;
  }

  async showPiercingTypesChooser(): Promise<string> {
    let result: any;
    result = await this.showButtons(this.piercingTypes, this.piercingTypesDe);
    return result;
  }


  public getGermanPathPiercingType(path: string[]) {
    return this.getFromPath(path, this.piercingTypesDe);
  }

  public getGermanPathBodyPutPiercing(path: string[]) {
    return this.getFromPath(path, this.bodyPutsPiercingDe);
  }

  public getGermanPath(path: string[]) {
    return this.getFromPath(path, this.bodyPutsDe);
  }

  public getEnPath(path: string[]) {
    return this.getFromPath(path, this.bodyPutsEn);
  }

  /*public getTextFromEventBodyPuts(bodyPuts: NxtCalendarEventBodyPuts): string[] {
    if (bodyPuts.tattoo) {
      return this.getTextFromEvent{};
    }
  }*/

  public getTextFromEvent(event: NxtCalendarEventSmall, options?: { size?: boolean }): string[] {
    if (!event) {
      return [];
    }
    const defaultOptions = {size: true};
    options = Object.assign(defaultOptions, options);
    const lines: string[] = [];
    if (event.workType === 'tattoo' && event?.bodyPuts?.tattoo) {
      for (const tattoo of event.bodyPuts.tattoo) {
        if (tattoo.bodyPut.length > 0) {
          const bodyPutGerman = this.getGermanPath(tattoo.bodyPut);
          if (tattoo.size && options.size) {
            lines.push(tattoo.motive + ' (' + tattoo.size + ') auf ' + bodyPutGerman);
          } else {
            lines.push(tattoo.motive + ' auf ' + bodyPutGerman);
          }
        }
      }
    }
    if (event.workType === 'piercing' && event.bodyPuts?.piercing) {
      for (const piercing of event.bodyPuts.piercing) {
        lines.push(this.getGermanPathBodyPutPiercing(piercing.bodyPut));
      }
    }
    if (event.workType === 'tooth-gem' && event.bodyPuts?.toothGem) {
      for (const toothGem of event.bodyPuts.toothGem) {
        lines.push(BodyPutTools.getGermanPathBodyPutToothGem(toothGem.bodyPut));
      }
    }
    return lines;
  }

  public getTextFromEventObj(bodyPuts: NxtCalendarEventBodyPuts): string[] {
    const lines: string[] = [];
    if (bodyPuts?.tattoo) {
      for (const tattoo of bodyPuts.tattoo) {
        if (tattoo.bodyPut.length > 0) {
          const bodyPutGerman = this.getGermanPath(tattoo.bodyPut);
          if (tattoo.size) {
            lines.push(tattoo.motive + ' (' + tattoo.size + ') auf ' + bodyPutGerman);
          } else {
            lines.push(tattoo.motive + ' auf ' + bodyPutGerman);
          }
        }
      }
    }
    if (bodyPuts?.piercing) {
      for (const piercing of bodyPuts.piercing) {
        lines.push(this.getGermanPathBodyPutPiercing(piercing.bodyPut));
      }
    }
    return lines;
  }


  private getFromPath(path: string[] | string, de: any[]) {
    if (typeof path === 'string') {
      path = path.split(',');
    }
    if (path[path.length - 1] === 'on') {
      path.splice(path.length - 1, 1);
    }
    let result = '';
    // const germanArray = path.map(p => de[p]);
    if (de[path.join(',')]) {
      result = de[path.join(',')];
    } else {
      result = path.map(p => de[p]).join('%');
    }
    return result;
  }

  private async showButtons(data: any, bodyPutDe, path: string[] = [], canChooseGroup = false): Promise<any> {


    const getButtonsResult = this.getButtonsFromPath(data, path, bodyPutDe);

    const buttons = getButtonsResult.buttons.sort(SortTools.sortString('text'));


    let result: { text: string, value: string } | string = buttons[0];

    if (buttons.length > 1) {
      const pathDe = path.map(p => bodyPutDe[p]).join(' > ');
      if (path.length > 0) {
        buttons.push({text: 'Alle', value: 'nxt-all'});
      }
      result = await this.dialogService.showButtonChooser({buttonRows: [buttons], title: 'Körperstelle', text: pathDe, minWidth: '80%', value: ''});
    }
    if (!result) {
      return undefined;
    }

    if (typeof result === 'string' && result === 'back') {
      path.pop();
      return await this.showButtons(data, bodyPutDe, path, canChooseGroup);
    }

    if (result && typeof result !== 'string') {
      path.push(result.value);
    }

    const showButtonsAgain = !getButtonsResult.lastPath && typeof result !== 'string' && result.value !== 'nxt-all';

    if (showButtonsAgain) {
      if (typeof result !== 'string') {
        return await this.showButtons(data, bodyPutDe, path, canChooseGroup);
      }
    } else {
      return path.filter(p => p !== 'nxt-all');
    }
  }


  async getNotTranslated() {
    await this.waitForBodyPutsRead();
    await this.checkTranslations(this.bodyPuts, [], this.bodyPutsDe, this.bodyPutsEn, 'Tattoo');
    await this.checkTranslations(this.bodyPutsPiercing, [], this.bodyPutsPiercingDe, this.bodyPutsPiercingEn, 'Piercing');
  }

  async checkTranslations(obj, parentsKey: string[], putsDe, putsEn, type) {
    if (Array.isArray(obj)) {
      for (const bp of obj) {
        await this.checkTranslation(bp, parentsKey, putsDe, 'DE-' + type);
        await this.checkTranslation(bp, parentsKey, putsEn, 'EN' + type);
      }
    } else {
      for (const key of Object.keys(obj)) {
        parentsKey.push(key);
        await this.checkTranslations(obj[key], parentsKey, putsDe, putsEn, type);
        await this.checkTranslations(obj[key], parentsKey, putsEn, putsEn, type);
        parentsKey.pop();
      }
    }
  }


  private async checkTranslation(bp: any, parentsKey, de, langText: string) {
    let translateText = '';
    if (bp !== 'on') {
      translateText = [...parentsKey, bp].join(',');
    } else {
      translateText = [...parentsKey].join(',');
    }
    try {
      if (!de[translateText]) {
        this.clipboardService.copyToClipboard('"' + translateText + '": "",');
        await this.dialogService.showOk('Übersetzung fehlt (' + langText + '):\n"' + translateText + '": "",');
      }
    } catch (err) {
      console.error('Übersetzung fehlt ', err);
      debugger;
    }
  }

  getBodyPutBeauty(id: string) {
    return this._getBodyPutBeauty(id, BodyPutTools.beautyBodyPuts);
  }

  _getBodyPutBeauty(id: string, bodyPuts: NxtBodyPut[]): NxtBodyPut | undefined {
    const bodyPut = bodyPuts.find(bp => bp.id === id);
    if (bodyPut) {
      return bodyPut;
    }
    for (const bodyPut of bodyPuts) {
      if (bodyPut.children) {
        const result = this._getBodyPutBeauty(id, bodyPut.children);
        if (result) {
          return result;
        }
      }
    }
  }

  _getBodyPutToothGem(id: string, bodyPuts: NxtBodyPut[]): NxtBodyPut | undefined {
    const bodyPut = bodyPuts.find(bp => bp.id === id);
    if (bodyPut) {
      return bodyPut;
    }
    for (const bodyPut of bodyPuts) {
      if (bodyPut.children) {
        const result = this._getBodyPutToothGem(id, bodyPut.children);
        if (result) {
          return result;
        }
      }
    }
  }

  getBodyPutBeautyText(id: string) {
    const bodyPut = this._getBodyPutBeauty(id, BodyPutTools.beautyBodyPuts);
    if (bodyPut) {
      const name = bodyPut.fullName || bodyPut.name;
      if (bodyPut.durationFrom) {
        if (bodyPut.durationTill) {
          return name + ' - ' + bodyPut.durationFrom + '-' + bodyPut.durationTill + ' Min - ' + bodyPut.price.toMoneyString();
        } else {
          return name + ' - ' + bodyPut.durationFrom + ' Min - ' + bodyPut.price.toMoneyString();
        }
      } else {
        return name;
      }
    }
    return '';
  }

  getBodyPutToothGemText(id: string) {
    const bodyPut = this._getBodyPutToothGem(id, BodyPutTools.toothGemBodyPuts);
    if (bodyPut) {
      const name = bodyPut.fullName || bodyPut.name;
      if (bodyPut.durationFrom) {
        if (bodyPut.durationTill) {
          return name + ' - ' + bodyPut.durationFrom + '-' + bodyPut.durationTill + ' Min - ' + bodyPut.price.toMoneyString();
        } else {
          return name + ' - ' + bodyPut.durationFrom + ' Min - ' + bodyPut.price.toMoneyString();
        }
      } else {
        if (bodyPut.price) {
          return name + ' - ' + bodyPut.price.toMoneyString();
        }
        return name;
      }
    }
    return '';
  }

  getBodyPutBeautyTextButton(id: string) {
    const bodyPut = this._getBodyPutBeauty(id, BodyPutTools.beautyBodyPuts);
    if (bodyPut) {
      if (bodyPut.durationFrom) {
        if (bodyPut.durationTill) {
          return bodyPut.name + '\n<div class="text-80">' + bodyPut.durationFrom + '-' + bodyPut.durationTill + ' Min - ' + bodyPut.price.toMoneyString() + '</div>';
        } else {
          return bodyPut.name + '\n<div class="text-80">' + bodyPut.durationFrom + ' Min - ' + bodyPut.price.toMoneyString() + '</div>';
        }
      } else {
        return bodyPut.name;
      }
    }
    return '';
  }

  getBodyPutToothGemTextButton(id: string) {
    const bodyPut = this._getBodyPutToothGem(id, BodyPutTools.toothGemBodyPuts);
    if (bodyPut) {
      if (bodyPut.durationFrom) {
        if (bodyPut.durationTill) {
          return bodyPut.name + '\n<div class="text-80">' + bodyPut.durationFrom + '-' + bodyPut.durationTill + ' Min - ' + bodyPut.price.toMoneyString() + '</div>';
        } else {
          return bodyPut.name + '\n<div class="text-80">' + bodyPut.durationFrom + ' Min - ' + bodyPut.price.toMoneyString() + '</div>';
        }
      } else {
        if (bodyPut.price) {
          return bodyPut.name + '\n<div class="text-80">' + bodyPut.price.toMoneyString() + '</div>';
        }
        return bodyPut.name;
      }
    }
    return '';
  }

  async showBodyPutBeautyChooser(title?: string, bodyPuts?: NxtBodyPut[]) {
    if (!title) {
      title = 'Behandlung wählen';
    }
    if (!bodyPuts) {
      bodyPuts = BodyPutTools.beautyBodyPuts.sortString('name');
    }
    const buttons: { text: string; value: NxtBodyPut }[] = [];

    for (const bodyPut of bodyPuts) {
      buttons.push({text: this.getBodyPutBeautyTextButton(bodyPut.id), value: bodyPut});
    }
    const bodyPut = await this.dialogService.showButtonChooserNew({title, buttonRows: [buttons]});
    // const bodyPut = await this.dialogService.showButtons('Behandlung wählen', {buttons});
    if (bodyPut) {
      if (bodyPut.value.children) {
        title += '\n' + bodyPut.value.name;
        return await this.showBodyPutBeautyChooser(title, bodyPut.value.children);
      }
      return bodyPut.value.id;
    }
  }

  async showBodyPutToothGemChooser(title?: string, bodyPuts?: NxtBodyPut[]) {
    if (!title) {
      title = 'Stelle wählen';
    }
    if (!bodyPuts) {
      bodyPuts = BodyPutTools.toothGemBodyPuts.sortString('name');
    }
    const buttons: { text: string; value: NxtBodyPut }[] = [];

    for (const bodyPut of bodyPuts) {
      buttons.push({text: this.getBodyPutToothGemTextButton(bodyPut.id), value: bodyPut});
    }
    const bodyPut = await this.dialogService.showButtonChooserNew({title, buttonRows: [buttons]});
    // const bodyPut = await this.dialogService.showButtons('Behandlung wählen', {buttons});
    if (bodyPut) {
      if (bodyPut.value.children) {
        title += '\n' + bodyPut.value.name;
        return await this.showBodyPutToothGemChooser(title, bodyPut.value.children);
      }
      return bodyPut.value.id;
    }
  }
}
