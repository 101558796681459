import {ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, OnInit, Optional, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtArtistReduceProposal, StudioCashReport2Service} from '../studio-cash-report-2.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {NxtCalendarEvent} from '../../../common-interfaces/nxt.calendar-event.interface';
import {clone} from '../../../common-browser/helpers/object.tools';
import {CanNotFinishReason} from '../../../common-interfaces/nxt.work-session';
import {SocketService} from '../../../services/socket/socket.service';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {MathTools} from '../../../common-browser/helpers/math.tools';
import {MoneyPipe} from '../../../pipes/money.pipe';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {SlideToggleComponent} from '../../form-controls/slide-toggle/slide-toggle.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {MatIcon} from '@angular/material/icon';
import {ArrayTools} from '../../../common-browser/helpers/array.tools';
import {WorkingDayService} from '../../../services/working-day.service';
import {NxtAvailableArtistDay} from '../../../common-interfaces/nxt.available-artist-day.interface';
import {MultiClickDirective} from '../../../directives/multi-click.directive';
import {ConfigService} from '../../../services/config.service';
import {EventTools} from '../../../common-browser/helpers/event.tools';
import {Money2Pipe} from '../../../pipes/money-2.pipe';
import {IconTools} from '../../../common-browser/helpers/icon.tools';
import {NxtRoundPipe} from '../../../pipes/nxt-round-pipe';
import {TypeTools} from '../../../common-browser/helpers/type.tools';
import {LoginService} from '../../../services/login.service';
import {PermissionDirective} from '../../../directives/permission.directive';
import {determineBaseTypes} from '@angular/cdk/schematics';
import {result} from 'lodash';

type EventPostpone = NxtCalendarEvent & {
  postpone: boolean,
  alreadyPostponed: boolean,
  canPostpone: boolean,
  artistLastDay: boolean;
  isAdditionalEvent: boolean;
  cssClass: string,
  postponeInfo: string,
  artistAway: boolean,
  tattooCount: number,
  stencilCount: number;
  preTattooCount: number;
};


@Component({
  selector: 'nxt-scr-pre-start-day-finish',
  templateUrl: './scr-pre-start-day-finish.component.html',
  styleUrls: ['./scr-pre-start-day-finish.component.scss'],
  imports: [FlexModule, NgClass, ExtendedModule, NgIf, SlideToggleComponent, NgFor, NxtButtonComponent, NxtDatePipe, SafeHtmlPipe, MoneyPipe, MatIcon, MultiClickDirective, Money2Pipe, NxtRoundPipe, PermissionDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ScrPreStartDayFinishComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor(
    @Optional() private dialogRef: MatDialogRef<ScrPreStartDayFinishComponent>,
    private cdkRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private socketService: SocketService,
    private workingDayService: WorkingDayService,
    private configService: ConfigService,
    private loginService: LoginService,
  ) {
    super();
    effect(() => {
      this.showAlreadyPayoutArtists();
      this.load().then();
    });
  }

  private forceShowAllArtistsAndCanPostponeAll = false;

  private additionalCanNotFinishReasons: CanNotFinishReason[] = [];
  private additionalCanNotFinishReasonsLocal: CanNotFinishReason[] = [];

  public myService: StudioCashReport2Service;
  canUnblock = signal(false);
  artistReduces: NxtArtistReduceProposal[];
  eventsToPostpone = signal<EventPostpone[]>([]);
  showAlreadyPayoutArtists = signal(false);
  endOfDayValue = signal(0);
  endOfDayValue_ = signal(0);
  canNotFinishReasons = signal<CanNotFinishReason[]>([]);
  canNotFinishReasonsLocal = signal<CanNotFinishReason[]>([]);
  showAllEventsToPostpone = true;
  private availableArtistNextWorkingDay: NxtAvailableArtistDay;

  protected readonly EventTools = EventTools;

  protected readonly IconTools = IconTools;

  ngOnInit() {
    this.pushSubscription = this.myService.data$.subscribe(async () => {
      this.load().then();
    });
  }

  private async load() {
    await this.getArtistAvailableNextWorkingDay();
    this.calcEventsCanPostpone();
    this.calcEndOfDayCash();
    this.calcCanUnblock();
    this.calcArtistReduces();
  }

  calcArtistReduces() {
    this.artistReduces = this.myService.getArtistReduceProposals(this.eventsToPostpone().filter(e => e.postpone).map(e => e.id));
  }

  nxtOnDestroy() {

  }

  cancelClicked() {
    this.dialogRef.close(false);
  }

  unblockClicked() {
    const postponeEventIds = this.eventsToPostpone().filter(e => e.postpone).map(e => e.id);
    this.dialogRef.close({doUnblock: true, postponeEventIds});
  }

  openEventClicked(eventId: string) {
    this.dialogService.showEvent(eventId, 'StudioCashReport');
  }

  showEventClicked(eventId: string) {
    this.dialogService.showEvent(eventId, 'StudioCashReport');
  }

  private async calcCanUnblock(onlyLocal = false) {
    if (!onlyLocal) {
      this.additionalCanNotFinishReasons = await this.socketService.getAdditionalCanNotFinishReasons(this.myService.dateString);
    }
    this.additionalCanNotFinishReasonsLocal = [...this.getLocalAdditionalCanNotFinishReasons1(), ...this.getLocalAdditionalCanNotFinishReasons2()];
    // this.additionalCanNotFinishReasonsLocal = this.getLocalAdditionalCanNotFinishReasons();
    this.canNotFinishReasons.set([...this.additionalCanNotFinishReasons, ...this.myService.cashRegisterViewCalc.canNotFinishReasons, ...this.additionalCanNotFinishReasonsLocal]);
    const noCanNotFinishReasons = this.canNotFinishReasons().length === 0;
    const noTransferNeeded = this.endOfDayValue() >= 0 && this.endOfDayValue_() >= 0;
    this.canUnblock.set(noCanNotFinishReasons && noTransferNeeded);
  }

  getLocalAdditionalCanNotFinishReasons1(): CanNotFinishReason[] {
    const result: CanNotFinishReason[] = [];
    if (this.myService?.data?.workSession?.artistsCanEarlyPayout) {
      for (const artistName of this.myService.data.workSession.artistsCanEarlyPayout) {
        const artistNotPayedOutEvents = this.myService.data.events.filter(e => {
          return e.paymentSum > 0 && !e.payments?.some(p => p.paymentType === 'payout') && e.artist === artistName;
        });
        for (const event of artistNotPayedOutEvents) {
          if (!this.myService.data.workSession.postponedEventIds.includes(event.id)) {
            result.push({
              text: artistName + ' wurde bereits ausgezahlt,\nTerminauszahlung muss verschoben werden!',
              type: 'artist-already-payout',
              refType: 'event',
              refId: event.id
            });
          }
        }
      }
    }
    return result;
  }

  getLocalAdditionalCanNotFinishReasons2(): CanNotFinishReason[] {
    const result: CanNotFinishReason[] = [];
    if (this.configService.config.value.mustEventRating) {
      const eventsNotPostpone = this.eventsToPostpone().filter(e => {
        const isTattoo = e.workType === 'tattoo';
        const isNotPostponed = !e.postpone;
        const toFewRatings = !TypeTools.isNumber(e.ratingValue) || e.ratings.length < this.configService.config.value.mustEventRatingMin;
        return isTattoo && isNotPostponed && toFewRatings;
      });
      for (const event of eventsNotPostpone) {
        result.push({text: 'Zu wenige Tattoo-Bewertungen', type: 'missing-event-rating', refType: 'event', refId: event.id});
      }
    }
    return result;
  }


  calcEventsCanPostpone() {
    const postponeEventIds: string[] = [];

    let earlyPayoutArtists = ArrayTools.unique(this.myService.data.events.filter(e => e.payments.some(p => p.paymentType === 'payout' && p.earlyPayout)).map(e => e.artist));

    if (this.forceShowAllArtistsAndCanPostponeAll) {
      earlyPayoutArtists = [];
    }

    if (this.eventsToPostpone()) {
      postponeEventIds.push(...this.eventsToPostpone().filter(e => e.postpone).map(e => e.id));
    }
    this.eventsToPostpone.set(clone(this.myService.data.events.filter(e => {
      return !earlyPayoutArtists.includes(e.artist) || this.showAlreadyPayoutArtists();
    })) as any);

    for (const postponedEvent of this.myService.data.postponedEvents) {

      if (!this.eventsToPostpone().find(e => e.id === postponedEvent.id)) {
        this.eventsToPostpone.update(eventsToPostpone => {
          const eventToPostpone: EventPostpone = {
            ...postponedEvent,
            postpone: true,
            alreadyPostponed: true,
            canPostpone: true,
            postponeInfo: '',
            cssClass: '',
            artistAway: false,
            artistLastDay: false,
            isAdditionalEvent: false,
            stencilCount: 0,
            tattooCount: 0,
            preTattooCount: 0,
          };
          return [...eventsToPostpone, eventToPostpone];
        });
      }
    }
    this.eventsToPostpone().filter(e => postponeEventIds.includes(e.id)).forEach(e => e.postpone = true);
    for (const openEvent of this.eventsToPostpone()) {
      const isEventPostponed = this.myService.data.postponedEvents.some(e => e.id === openEvent.id);
      const artistIsAvailableNextWorkingDay = this.availableArtistNextWorkingDay?.artists.some(a => a.name === openEvent.artist);
      const isAdditionalEvent = this.myService.data.workSession.additionalEventIds.includes(openEvent.id);
      if (this.configService.config.value.studioRegion === 'STAGING') {
        openEvent.canPostpone = artistIsAvailableNextWorkingDay;
      } else {
        openEvent.canPostpone = artistIsAvailableNextWorkingDay && !isAdditionalEvent;
        if (this.loginService.isJulian()) {
          if (!openEvent.canPostpone) {
            if (artistIsAvailableNextWorkingDay) {
              openEvent.canPostpone = true;
            }
          }
        }
      }
      if (!openEvent.canPostpone) {
        // openEvent.postpone = false;
      }
      openEvent.artistLastDay = !artistIsAvailableNextWorkingDay;
      openEvent.isAdditionalEvent = isAdditionalEvent;
      if (this.forceShowAllArtistsAndCanPostponeAll) {
        openEvent.canPostpone = true;
      }
    }
    this.eventsToPostpone.update((eventsToPostpone) => {
      return [...eventsToPostpone].sort(SortTools.sortString('artist'));
    });
    let rowClass = 'normal';
    let currentArtist = '';

    // this.eventsToPostpone = this.eventsToPostpone.filter(e => e.artist === 'Stefan Alt');

    for (const event of this.eventsToPostpone()) {
      if (event.artist !== currentArtist) {
        rowClass = rowClass === 'row-dark' ? '' : 'row-dark';
      }
      event.cssClass = rowClass;
      currentArtist = event.artist;
      const postponeInfos: string[] = [];
      if (event.canPostpone) {
        const artist = this.myService.data.availableArtists.artists.find(a => a.name === event.artist);
        if (artist) {
          if (artist.workTimeWindow.end > 0) {
            postponeInfos.push('Artist weg');
            event.artistAway = true;
          }
        }
      }
      if (event.isAdditionalEvent) {
        postponeInfos.push('nicht von heute');
      }
      if (!event.closed) {
        postponeInfos.push('offen');
      }
      if (event.artistLastDay) {
        postponeInfos.push('letzter Tag');
      }
      event.postponeInfo = postponeInfos.join('\n');

      event.stencilCount = event.files?.filter(f => f.subType === 'stencilPhoto' || f.subType === 'stencilVideo').length || 0;
      event.tattooCount = event.files?.filter(f => f.subType === 'tattooPhoto' || f.subType === 'tattooVideo').length || 0;
      event.preTattooCount = event.files?.filter(f => f.subType === 'preTattooPhoto' || f.subType === 'preTattooVideo').length || 0;
    }
    this.calcCanUnblock(true);
  }

  private calcEndOfDayCash() {
    const toAddPostponedEventsValue = this.eventsToPostpone().filter(e => e.postpone && e.visibility !== 'private' && !e.alreadyPostponed).reduce((sum, e) => sum + e.artistTotalGet, 0);
    const toAddPostponedEventsValue_ = this.eventsToPostpone().filter(e => e.postpone && e.visibility === 'private' && !e.alreadyPostponed).reduce((sum, e) => sum + e.artistTotalGet, 0);
    const toRemovePostponedEventsValue = this.eventsToPostpone().filter(e => !e.postpone && e.visibility !== 'private' && e.alreadyPostponed).reduce((sum, e) => sum + e.artistTotalGet, 0);
    const toRemovePostponedEventsValue_ = this.eventsToPostpone().filter(e => !e.postpone && e.visibility === 'private' && e.alreadyPostponed).reduce((sum, e) => sum + e.artistTotalGet, 0);
    this.endOfDayValue.set(MathTools.roundMoney(this.myService.data.calculatedData.endOfDayCash.value + toAddPostponedEventsValue - toRemovePostponedEventsValue));
    this.endOfDayValue_.set(MathTools.roundMoney(this.myService.data.calculatedData.endOfDayCash.value_ + toAddPostponedEventsValue_ - toRemovePostponedEventsValue_));
  }

  eventPostponeChanged() {
    this.calcEndOfDayCash();
    this.calcCanUnblock();
    this.calcArtistReduces();
  }

  autoPostponeClicked() {
    if (!this.showAllEventsToPostpone) {
      this.showAllEventsToPostpone = !this.showAllEventsToPostpone;
      this.calcEventsCanPostpone();
      this.calcArtistReduces();
      requestAnimationFrame(() => this.autoPostpone());
    } else {
      this.autoPostpone();
    }
  }

  private autoPostpone() {
    for (const event of this.eventsToPostpone()) {
      event.postpone = false;
      if (event.canPostpone) {
        event.postpone = event.artistAway || !event.closed;
      }
    }
    this.calcEndOfDayCash();
    this.calcCanUnblock().then();
    this.calcArtistReduces();
  }

  async autoReduceArtistClicked(artistReduce: NxtArtistReduceProposal) {
    const doIt = await this.dialogService.showYesNo(artistReduce.text);
    if (doIt) {
      const event = artistReduce.events.find(e => e.artistGet > 5);
      if (event) {
        this.socketService.reduceArtistPayout(event.eventId, artistReduce.toReduce).then();
      }
    }
  }

  private async getArtistAvailableNextWorkingDay() {
    const nextWorkingDay = this.workingDayService.addDaysUntilWorkingDay(this.myService.data$.value.workSession.endAtDateString);
    this.availableArtistNextWorkingDay = await this.socketService.getAvailableArtistsDay(nextWorkingDay.dateFormatDate());
  }

  onlyPostPone() {
    const postponeEventIds = this.eventsToPostpone().filter(e => e.postpone).map(e => e.id);
    this.dialogRef.close({doUnblock: false, postponeEventIds});
  }

  enablePostPoneHack(event: NxtCalendarEvent & {
    postpone: boolean;
    alreadyPostponed: boolean;
    canPostpone: boolean;
    artistLastDay: boolean;
    isAdditionalEvent: boolean;
    cssClass: string;
    postponeInfo: string;
    artistAway: boolean
  }) {
    if (!event.isAdditionalEvent) {
      event.canPostpone = true;
    }
  }

  eventRatingClicked(refId: string) {
    this.dialogService.showEventRating(refId, 'StudioCashReport');
  }
}
