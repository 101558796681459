import {Component, OnInit, Optional, signal, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {HistoryComponent, NxtHistoryPropDef} from '../history.component';
import {MatDialogRef} from '@angular/material/dialog';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-artist-spot-history',
    templateUrl: './artist-spot-history.component.html',
    styleUrls: ['./artist-spot-history.component.scss'],
    imports: [FlexModule, NxtButtonIconComponent, HistoryComponent]
})

export class ArtistSpotHistoryComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  artistSpotId = signal('');

  constructor(
    @Optional() private dialogRef: MatDialogRef<ArtistSpotHistoryComponent>,
  ) {
    super();
    this.hiddenProps.push(...this.debugProps);
  }

  @ViewChild(HistoryComponent) historyComponent: HistoryComponent;

  public propDefs: NxtHistoryPropDef[] = [


  ];
  public hiddenProps = [

  ];

  debugProps = [];
  public showTitle = false;
  public showDebug = false;


  setData(employeeId: string) {
    this.artistSpotId.set(employeeId);
  }

  ngOnInit() {
    this.propDefs = this.propDefs.sort(SortTools.sortString('name'));
  }

  nxtOnDestroy() {
  }

  public prepareRawData(data) {
    return data;
  }

  close() {
    this.dialogRef.close();
  }
}
