<div fxLayout="column" class="pb-2 px-2 w-full h-100" style="border: 0 solid yellow">
  <div fxLayout="column" fxLayoutAlign="start center" class="header w-full h-100" style="border: 0px solid red">
    <div>
      <nxt-slide-toggle class="no-wrap pb-2" [value]="filterOnlyMe()" (valueChange)="filterOnlyMe.set($event)">nur für Mich</nxt-slide-toggle>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="w-full" *ngIf="reminders && reminders.length === 0">
      <div fxFlex>Alles erledigt</div>
      <div fxFlex>{{ goodEmojis }}</div>
    </div>

    <div class="text-[32px] cursor-pointer" (click)="reminderService.showCreateReminder()">+</div>
    <div fxLayout="column" fxLayoutAlign="center center" class="w-full pb-2" *ngIf="reminders && reminders.length > 0">
      <div *ngIf="reminders.length === 1" fxFlex>Eine Aufgabe</div>
      <div (click)="showImportantRemindersDialog()" *ngIf="reminders.length > 1" fxFlex>{{ reminders.length }} Aufgaben</div>
    </div>
    <div class="w-full pb-2" *ngIf="reminders && reminders.length > 0" fxLayoutGap="3px" fxLayout="column" fxLayoutAlign="center">

      <div
        (click)="reminderGroupClicked(reminderGrouped)"
        (contextmenu)="reminderGroupContextClicked(reminderGrouped, $event)"
        *ngFor="let reminderGrouped of remindersGrouped"
        [ngClass]="{'current-reminder-group': currentReinderGroup === reminderGrouped.text}"
        [style.background-color]="reminderGrouped.color"
        class="p-1 mouse-pointer w-full reminder-group text-center"
        fxLayout="column"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="text-80 px-1">{{ reminderGrouped.count }}</div>
          <div class="text-60 no-wrap o-dots">{{ reminderGrouped.text }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="reminders && reminders.length > 2" class="py-1 w-full" style="border-top:1px solid #ffff"></div>
    <div fxLayout="column" class="w-full" *ngIf="reminders" fxLayoutGap="5px" style="border: 0px solid blue; overflow: auto">
      <div fxLayout="column" class="reminder w-full" *ngFor="let reminder of remindersFiltered; let i = index"
           [style.background-color]="reminder.color">

        <div class="reminder-header pt-1 flex flex-col items-center" (click)="reminderClicked(reminder)">
          <div fxLayout="row" class="w-full" fxLayoutAlign="space-between">
            <div class="no-wrap" fxLayout="row">
              <div>{{ (i + 1) }}.</div>
              @if (reminder.toDoByWorkplace) {
                <div>{{ reminder.createdBy !== 'System' ? (reminder.createdBy + ' ➞ ') : '' }}{{ reminder.toDoByWorkplace | workplace }}</div>
              } @else if (reminder.toDoByUser) {
                <div>{{ reminder.createdBy !== 'System' ? (reminder.createdBy + ' ➞ ') : '' }}{{ reminder.toDoByUser }}</div>
              } @else {
                <div>Alle</div>
              }
            </div>
            <div fxLayout="column" fxLayoutAlign="start end" [class]="reminder | reminderDateTimeClass">
              <div style="text-align: right">{{ reminder.reminderDateTime | nxtDate: 'HH:mm' }}</div>
            </div>
          </div>
          <div class="flex pt-1">{{ reminder.reminderDateTime | fromNowText }}</div>
        </div>

        <div class="reminder-type px-1 py-1" fxLayout="column" (click)="reminderClicked(reminder)">
          <div style="font-size: 60%" fxLayout="column" *ngIf="reminder.refType === NxtReminderRefType.Event">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div fxLayout="row" class="w-full" fxLayoutAlign="space-between">
                <div>
                  <div
                    *ngIf="reminder?.refObj?.startDateString && reminder.refObj.startDateString !== todayDateString">{{ reminder?.refObj?.start | nxtDate: 'dd.MM' }}
                  </div>
                  <div>{{ reminder?.refObj?.start | nxtTime: true : 'HH:mm' }}-{{ reminder?.refObj?.end | nxtTime: true: 'HH:mm' }}</div>
                </div>
                <div>{{ reminder?.refObj?.artist }}</div>
              </div>
              <div fxLayout="row">
                <div *ngIf="reminder?.refObj?.fastWalkInNo">#{{ reminder?.refObj?.fastWalkInNo }}&nbsp;</div>
                <div>{{ reminder?.refObj?.customerObj?.fullName }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="reminder-body px-1 py-1 max-w-full overflow-hidden" fxLayout="column" fxLayoutAlign="center center" (click)="reminderClicked(reminder)">
          <div class="max-w-full overflow-hidden" fxLayoutAlign="center center" style="text-align: center; overflow: hidden">
            <div class="reminder-body-html" [innerHTML]="(reminder.description | safeHtml)"></div>
          </div>
        </div>
        <div class="reminder-footer" fxLayout="column" *ngIf="false">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>{{ reminder.createdBy }}</div>
            <div>{{ reminder.createdAt | fromNowText: true }} {{ reminder.createdAt | nxtDate: 'HH:mm' }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
