import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'shopOrderStatus',
  standalone: true
})
export class ShopOrderStatusPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any): string {
    switch (value) {
      case 'completed':
        return 'Abgeschlossen';
      case 'processing':
        return 'In Bearbeitung';
      case 'on-hold':
        return 'In Wartestellung';
      case 'pending':
        return 'Ausstehende Zahlung';
      case 'refunded':
        return 'Rückerstattet';
      default:
        return value;
    }
  }
}
