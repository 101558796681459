import {NxtContact} from '../../common-interfaces/nxt.contact.interface';
import {format, isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber, PhoneNumber, validatePhoneNumberLength} from 'libphonenumber-js';

export class MobileTools {
  static Numbers = {
    NiklasNXT: '+4917642725407',
    Niklas: '+491785815558',
    NiklasES: '+34604110000',
    Julian: '+4915120123232',
    // Mona: '+4915736186256',
    Sadri: '+32484101121',
    Bobby: '+4915752870433',
    CaponePrivat: '+4917683296085',
    CaponeNXT: '+4917687793584',
    Thorsten: '+34603289774',
    Sven: '+4916091661816',
    Achim: '+4915117601869',
    Born: '+491722129839',
    Lili: '+491786311193',
    Alija: '+491779372839',
    // Marcel: '+4915781715198',
    Deniz: '+491793952618',
    Jeremy: '+491737139154',
    Mandy: '+4917682647279',
    ArtistManagementFranchise: '+4917677870071',
    ArtistManagement: '+491795668878',
    ACSocialMedia: '+491785801593',

  };

  static isValid(mobile: any) {
    if (typeof mobile !== 'string') {
      return false;
    }
    return !!mobile.match(/^\+\d{10,13}$/);
    // return isValidPhoneNumber(mobile);
  }

  static parse(mobile: string): PhoneNumber {
    return parsePhoneNumber(mobile, 'DE');
  }

  static validatePhoneNumberLength(mobile: string) {
    return validatePhoneNumberLength(mobile);
  }

  static isValidPhoneNumber(mobile: string) {
    return isValidPhoneNumber(mobile);
  }

  static isValidPhoneNumberNxt(mobile: string) {
    return mobile && mobile?.startsWith('+') && mobile?.length > 10 && /^\+\d{9,17}$/g.exec(mobile);
  }

  static isPossiblePhoneNumber(mobile: string) {
    return isPossiblePhoneNumber(mobile);
  }

  static formatHuman(mobile: string) {
    const result = format(mobile, 'INTERNATIONAL');
    return result || mobile;
  }

  static formatNxt(mobile: string) {
    if (!mobile) {
      return '';
    }
    mobile = mobile.replace(/\D/g, '');
    if (mobile.startsWith('0')) {
      mobile = '49' + mobile.substring(1);
    }
    return '+' + mobile;
  }

  static format(mobile?: string) {
    try {
      if (typeof mobile === 'string' && mobile.length > 7) {
        mobile = mobile.replace(/[^\d+]/g, '');
        mobile = mobile.replaceAll('+49+49', '+49');
        const phoneNumber = parsePhoneNumber(mobile, 'DE');
        if (phoneNumber) {
          let number = phoneNumber.number.toString();
          const isValid = phoneNumber.isValid();
          const isPossible = phoneNumber.isPossible();
          if (!isValid) {
            number = MobileTools.tryGetValidNumber(number);
          }
          return number;
        }
      }
    } catch (err) {
      if (err.message === 'INVALID_COUNTRY') {
        if (mobile?.startsWith('+')) {
          return MobileTools.format(mobile.substring(1));
        }
        return '';
      }
      if (err.message === 'NOT_A_NUMBER') {
        return '';
      }

      if (err.message === 'TOO_LONG') {
        return '';
      }
      debugger;
    }
    return '';
  }

  private static tryGetValidNumber(number: string) {
    while (validatePhoneNumberLength(number) === 'TOO_LONG') {
      number = number.substring(0, number.length - 1);
    }
    for (let i = 0; i < number.length; i++) {
      if (isValidPhoneNumber(number)) {
        return number;
      }
      number = number.substring(0, number.length - 1);
    }
    return '';
  }

  private static shorten(mobile: string) {

  }


  static contactsHaveSameMobile(c1: NxtContact, c2: NxtContact): boolean {
    let result: boolean = !!c1.mobile && !!c2.mobile && MobileTools.format(c1.mobile) === MobileTools.format(c2.mobile);
    if (!result) {
      result = !!c1.mobileFormatted && !!c2.mobileFormatted && MobileTools.format(c1.mobileFormatted) === MobileTools.format(c2.mobileFormatted);
    }
    return result;
  }

  static test() {

    MobileTools.format('+49152314089449');

    MobileTools.format('+49‪+491729236615‬');


    // MobileTools.format('+49171564444501739433090');


    const a = MobileTools.format('+32 9 385 72 540');


    if (!MobileTools.isValid(MobileTools.Numbers.Julian)) {
      debugger;
    }

    if (MobileTools.isValid('+4915 120123232')) {
      debugger;
    }

    if (MobileTools.isValid('+4915a1201232')) {
      debugger;
    }

    if (MobileTools.format('+491512 012 32') !== '+49151201232') {
      debugger;
    }

    if (MobileTools.format('01512 012 32') !== '+49151201232') {
      debugger;
    }
  }

  static isSame(mobile1: string, mobile2: string) {
    if (typeof mobile1 !== 'string' || typeof mobile2 !== 'string') {
      debugger;
    }
    if (mobile1 === mobile2) {
      return true;
    }
    return mobile1?.mobileFormat() === mobile2?.mobileFormat();
  }
}


declare global {
  interface String {
    mobileIsSame(mobile: string): boolean;
  }
}

String.prototype.mobileIsSame = function(this: string, mobile: string) {
  return MobileTools.isSame(this, mobile);
};

declare global {
  interface String {
    mobileFormat(): string;
  }
}

String.prototype.mobileFormat = function(this: string) {
  return MobileTools.format(this);
};
