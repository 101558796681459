import {Component, Input, OnInit, Optional} from '@angular/core';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {DialogRef} from '@angular/cdk/dialog';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {InvoiceFormComponent} from '../../invoice-form/invoice-form.component';
import {DateTools} from '../../../common-browser/helpers/date.tools';
import {DialogService} from '../../../services/dialog.service';
import {NxtShopOrderWithRaw} from '../../../common-interfaces/nxt.shop-order.interface';
import {ConfigService} from '../../../services/config.service';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgIf} from '@angular/common';

@Component({
    selector: 'nxt-studio-fee-klarna-orders-component',
    templateUrl: './studio-fee-klarna-orders.component.html',
    styleUrls: ['./studio-fee-klarna-orders.component.scss'],
    imports: [NgIf, FlexModule, NxtDatagridComponent, NxtButtonComponent]
})

export class StudioFeeKlarnaOrdersComponent implements OnInit {

  @Input() shopOrders: NxtShopOrderWithRaw[];
  @Input() dateRange: { from: string, till: string };

  columnDefs: NxtColDef<NxtShopOrderWithRaw>[] = [
    {headerName: 'Name', valueGetter: params => params.data.billingFirstName + ' ' + params.data.billingLastName},
    {headerName: 'Datum', field: 'createdAt', nxtFieldType: NxtFieldType.Date_germanDate},
    {
      headerName: 'Wert', valueGetter: (params) => {
        return params.data.lineItems.reduce((sum, lineItem) => sum + lineItem.value, 0);
      }, nxtFieldType: NxtFieldType.Money
    },
    {
      headerName: 'Beschreibung', valueGetter: (params) => {
        return params.data.lineItems.map(l => l.name);
      }, nxtFieldType: NxtFieldType.Text
    },
    {headerName: 'Rabatt', field: 'discountTotal', nxtFieldType: NxtFieldType.Money},
    {
      headerName: 'Rabatt %', valueGetter: (params) => {
        const value = params.data.lineItems.reduce((sum, lineItem) => sum + lineItem.value, 0);
        return 100 - (((value) / (value + params.data.discountTotal)) * 100);
      }, nxtFieldType: NxtFieldType.Percentage
    },
    {headerName: 'Bezahlt', field: 'paymentValue', nxtFieldType: NxtFieldType.Money},
    {
      headerName: 'Rückerstattung', valueGetter: (params) => {
        if (params.data.klarnaRawOrder?.refunded_amount) {
          return params.data.klarnaRawOrder.refunded_amount / 100;
        }
        return 0;
      }, nxtFieldType: NxtFieldType.Money, cellStyle: {textAlign: 'right'}
    }, {
      headerName: 'Rückerstattung am', valueGetter: (params) => {
        if (params.data.klarnaRawOrder?.refunded_amount) {
          return params.data.klarnaRawOrder.refunds[0].refunded_at.dateFormat('dd.MM.yyyy');
        }
        return '';
      }, nxtFieldType: NxtFieldType.Text, cellStyle: {textAlign: 'right'}
    }, {
      headerName: 'Shop-ID', field: 'id', nxtFieldType: NxtFieldType.Text, nxtOnCellDoubleClicked: (params) => {
        const url = this.configService.config.value.shopUrl + '/wp-admin/post.php?post=' + params.data.id + '&action=edit';
        window.open(url, '_blank');
      }
    },
  ];

  constructor(
    @Optional() public dialogRef: DialogRef<any>,
    private dialogService: DialogService,
    private configService: ConfigService
  ) {
  }

  ngOnInit() {

    debugger;
    console.log(this.shopOrders);
    console.log(this.dateRange);
  }

  createInvoiceClicked() {
    const dialog = this.dialogService.showComponentFull(InvoiceFormComponent);
    dialog.componentInstance.form.get('recipientName').setValue('Oliver Peters');
    dialog.componentInstance.form.get('recipientName2').setValue('NXT-LVL.INK Aschaffenburg');
    dialog.componentInstance.form.get('recipientStreet').setValue('Seestraße 3');
    dialog.componentInstance.form.get('recipientPostalCode').setValue('63741');
    dialog.componentInstance.form.get('recipientCity').setValue('Aschaffenburg');
    dialog.componentInstance.form.get('recipientSalutation').setValue('Herr');
    dialog.componentInstance.form.get('invoiceDate').setValue(DateTools.formatNowDate());
    const sum = this.shopOrders.reduce((sum, shopOrder) => shopOrder.paymentValue - (shopOrder.klarnaRawOrder.refunded_amount / 100) + sum, 0);
    dialog.componentInstance.addPos('Klarna Umsatz ' + this.dateRange.from.dateFormat('dd.MM.yyyy') + ' - ' + this.dateRange.till.dateFormat('dd.MM.yyyy'), 1, sum);
  }
}
