<table class="w-full" *ngIf="ngConfig">
  <tr>
    <td>Bankname</td>
    <td style="width: 100%">
      <nxt-input [(value)]="ngConfig.invoiceData.fromBank" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>IBAN</td>
    <td>
      <nxt-input [(value)]="ngConfig.invoiceData.fromBankIBAN" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Stadt</td>
    <td>
      <nxt-input [(value)]="ngConfig.invoiceData.fromCity" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Firma</td>
    <td>
      <nxt-input [(value)]="ngConfig.invoiceData.fromCompany" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Firma Zusatz</td>
    <td>
      <nxt-input [(value)]="ngConfig.invoiceData.fromCompany2" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>PLZ</td>
    <td>
      <nxt-input [(value)]="ngConfig.invoiceData.fromPostalCode" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Land</td>
    <td>
      <nxt-country-select-component [(value)]="ngConfig.invoiceData.fromCountryCode"></nxt-country-select-component>
    </td>
  </tr>
  <tr>
    <td>Bundesland (nw, by, ...)</td>
    <td>
      <nxt-input [(value)]="ngConfig.invoiceData.fromRegion" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Straße</td>
    <td>
      <nxt-input [(value)]="ngConfig.invoiceData.fromStreet" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>Steuernummer</td>
    <td>
      <nxt-input [(value)]="ngConfig.invoiceData.fromTaxId" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>MwSt.-Nummer</td>
    <td>
      <nxt-input [(value)]="ngConfig.invoiceData.fromVATNumber" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>MwSt. Steuersatz</td>
    <td>
      <nxt-input [(value)]="ngConfig.invoiceData.vatPercent" [noPadding]="true"></nxt-input>
    </td>
  </tr>
  <tr>
    <td>AGB Stand</td>
    <td>
      <nxt-date-picker [(nxtModel)]="ngConfig.invoiceData.dateOfCondition"></nxt-date-picker>
    </td>
  </tr>
  <tr>
    <td>RE Sprache (DE, ES)</td>
    <td>
      <nxt-input [(value)]="ngConfig.invoiceData.lang" [noPadding]="true"></nxt-input>
    </td>
  </tr>
</table>
