import {Component, computed, OnInit, signal} from '@angular/core';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtWhatsAppState} from '../../../common-interfaces/whats-app/nxt-whats-app-state.interface';
import {NxtComponent} from '../../nxt.component';
import {SpinnerComponent} from '../../spinner/spinner.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgIf} from '@angular/common';
import {NxtDatePipe} from '../../../pipes/nxt-date-pipe';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {IntervalTools} from '../../../common-browser/helpers/interval.tools';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {ServerDate} from '../../../server-date';
import {JsonFormattedPipe} from '../../../pipes/json-formatted-pipe';
import {QRCodeComponent} from 'angularx-qrcode';

@Component({
  templateUrl: './whats-app-qr.component.html',
  styleUrls: ['./whats-app-qr.component.scss'],
  imports: [NgIf, FlexModule, ExtendedModule, SpinnerComponent, NxtDatePipe, NxtButtonComponent, JsonFormattedPipe, QRCodeComponent],
  standalone: true,
})
export class WhatsAppQrComponent extends NxtComponent implements OnInit {

  constructor(
    private socketService: SocketService,
  ) {
    super();
    this.load();
  }

  view = computed<'connected' | 'qrCodeInvalide' | 'qrCode' | 'loading' | 'unknown'>(() => {
    if (this.state().connected) {
      return 'connected';
    } else if (this.state().qrCodeInvalide) {
      return 'qrCodeInvalide';
    } else if (this.state().loadingMessage) {
      return 'loading';
    } else if (this.state().qrCode) {
      return 'qrCode';
    } else {
      return 'unknown';
    }
  });

  state = signal<NxtWhatsAppState>({
    connected: false,
    lastConnectedTimestamp: 0,
    qrCode: '',
    qrCodeTimestamp: 0,
    qrCodeInvalide: false,
    qrCodeCounter: 0,
    connectedId: '',
    waState: null,
    loadingMessage: '',
    connectedSince: 0,
    initTimestamp: 0,
    initCounter: 0,
    qrCodeIsLoading: false,
    qrCodeLoadingTimestamp: 0,
  });
  serverDate = ServerDate.now();
  qrCodeTimestamp = signal('');
  initTimestamp = signal('');
  lastConnectedTimestamp = signal('');
  newQrCodeIn = signal('');
  showQrCode = signal(true);
  private viewInterval: number;

  protected readonly ServerDate = ServerDate;

  ngOnInit(): void {
    this.viewInterval = window.setInterval(() => {
      this.initTimestamp.set(DurationTools.format2(ServerDate.now() - this.state().initTimestamp) + ' (' + this.state().initTimestamp.dateFormat('dd.MM.yy HH:mm:ss') + ')');

      if (this.state().lastConnectedTimestamp) {
        this.lastConnectedTimestamp.set(DurationTools.format2(ServerDate.now() - this.state().lastConnectedTimestamp) + ' (' + this.state().lastConnectedTimestamp.dateFormat('dd.MM.yy HH:mm:ss') + ')');
      }


      if (this.state().qrCodeTimestamp) {
        this.showQrCode.set(true);
        this.qrCodeTimestamp.set(DurationTools.format2(ServerDate.now() - this.state().qrCodeTimestamp));
        let newQrCodeInTimestamp = 0;
        if (this.state().qrCodeCounter === 1) {
          newQrCodeInTimestamp = this.state().qrCodeTimestamp + DurationTools.DURATION_1MINUTE;
        } else {
          newQrCodeInTimestamp = this.state().qrCodeTimestamp + 20000;
        }
        const diffTotal = newQrCodeInTimestamp - ServerDate.now();
        if (this.state().qrCodeCounter === 6) {
          this.newQrCodeIn.set('QR-Code noch gültig: ' + DurationTools.format2(diffTotal));
        } else {
          if (diffTotal < 0) {
            this.showQrCode.set(false);
            this.newQrCodeIn.set('QR-Code abgelaufen');
          } else {
            if (diffTotal > DurationTools.DURATION_1MINUTE * 2) {
              this.newQrCodeIn.set('Fehler, bitte WhatsApp neustarten');
              debugger;
            } else {
              this.newQrCodeIn.set('Neuer QR-Code in ' + DurationTools.format2(diffTotal));
            }
          }
        }
      }
    }, 1000);
  }

  nxtOnDestroy(): void {
    IntervalTools.clear(this.viewInterval);
  }

  async load() {
    this.pushSocketSubscription = this.socketService.subscribeNew('getWhatsAppState', (state) => {
      this.state.set(state);
    }, {emitInitial: true, emitAfterReconnect: true});
    const result = await this.socketService.getWhatsAppState();
  }

  reloadQrCodeClicked() {
    this.socketService.whatsAppRestart();
  }
}
