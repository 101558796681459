import {Component, OnDestroy, OnInit} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {LoginService} from '../../services/login.service';
import {SafeResourceUrlPipe} from '../../pipes/safe-resource-url.pipe';
import {FormsModule} from '@angular/forms';
import {FlexModule} from 'ngx-flexible-layout/flex';


@Component({
    selector: 'nxt-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    imports: [FlexModule, FormsModule, SafeResourceUrlPipe]
})
export class LogoComponent implements OnInit, OnDestroy {

  imgSrcBoth = '';
  imgSrcLetters = '';
  imgSrcForms = '';
  text = 'JULIAN';
  letterColor = '#303030';
  letterSpace = 100;
  fontSize = 155;
  svgCodeBoth = '';
  svgCodeLetters = '';
  svgCodeForms = '';

  constructor(
    private socketService: SocketService,
    private loginService: LoginService
  ) {
  }

  async ngOnInit() {
    this.textChanged();
  }

  ngOnDestroy(): void {
  }


  getSvgCode(text: string, letterColor: string, letterSpacing = 100, layers: 'letters' | 'forms' | 'both', fontSize = 150) {
    const svgCode = `<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t viewBox="0 0 1581 764.3" style="enable-background:new 0 0 1581 764.3;" xml:space="preserve">
<style type="text/css">
\t.st0{fill:${letterColor};}
\t.st1{fill:#B7860F;}
\t.white{fill:#FFFFFF;}
</style>
<g id="Ebene_2_1_">
\t<g id="logo">
\t\t<path class="st0" d="M125.6,187.2v137h-26L31.3,241v83.2H0v-137h26.2l68.1,83.2v-83.2H125.6z"/>
\t\t<path class="st0" d="M272.9,324.2l-33-47.5L207.3,324h-36.4l50.7-69.4l-48.1-67.5h36l31.5,44.4l30.9-44.4h34.2l-47.7,66.1
\t\t\tl51.1,70.9h-36.6V324.2z"/>
\t\t<path class="st0" d="M398.6,213h-43.8v-25.8h119.4V213h-43.9v111.2h-31.7V213z"/>
\t\t<path class="st0" d="M620.8,187.2h31.7v111.2h68.7v25.8H620.8V187.2z"/>
\t\t<path class="st0" d="M880.7,187.2l-59.2,137h-31.4l-59.2-137h34.2l41.7,97.8l42.3-97.8H880.7z"/>
\t\t<path class="st0" d="M920.6,187.2h31.7v111.2h68.7v25.8H920.6V187.2L920.6,187.2z"/>
\t\t<path class="st0" d="M1149.6,187.2h31.7v137h-31.7V187.2z"/>
\t\t<path class="st0" d="M1378.6,187.2v137h-26l-68.3-83.2v83.2h-31.4v-137h26.3l68.1,83.2v-83.2H1378.6z"/>
\t\t<path class="st0" d="M1500.1,270.4l-18.4,19.2v34.6h-31.5v-137h31.5v64l60.7-64h35.2l-56.8,61.1l60.1,75.9h-37L1500.1,270.4z"/>
\t\t<rect x="1069.4" y="292.6" class="st1" width="31.7" height="31.7"/>
\t\t<rect x="509.1" y="250.1" class="st1" width="64.1" height="26.3"/>
\t\t<path class="st1" d="M1230,511.6H351V402.2h31.7v77.6h815.6v-77.6h31.7V511.6z"/>
\t\t<polygon class="st1" points="1230,109.3 1198.3,109.3 1198.3,31.7 382.7,31.7 382.7,109.3 351,109.3 351,0 1230,0 \t\t"/>
\t\t<rect y="584.3" class="st1" width="1581" height="180"/>
\t</g>
</g>
<text font-size="${fontSize}" class="white" id="svg_15" y="730" x="790" text-anchor="middle"
font-weight="normal" font-style="normal" letter-spacing="${letterSpacing}" font-family="'Montserrat ExtraBold', 'Montserrat-ExtraBold'">${text}</text>
</svg>`;
    return svgCode;
  }

  getSvgCode_old(text: string, letterColor: string, letterSpacing = 100, layers: 'letters' | 'forms' | 'both') {
    let svgCode = '<svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
      '\t viewBox="0 0 2014 1172" style="enable-background:new 0 0 2014 1172;" xml:space="preserve">\n' +
      '<style type="text/css">\n' +
      '\t.letters{fill:' + letterColor + ';}\n' +
      '\t.gold{fill:#b7860f;}\n' +
      '\t.name{font-size:154px;font-family:\'Montserrat ExtraBold\';fill:#FFFFFF; letter-spacing:' + letterSpacing + 'px}\n' +
      '</style>\n' +
      '<title>Name</title>\n';
    if (layers === 'letters' || layers === 'both') {
      svgCode += '<g>\n' +
        '\t<path class="letters" d="M367.3,444.7v137h-26L273,498.5v83.2h-31.3v-137h26.2l68.1,83.2v-83.2H367.3z"/>\n' +
        '\t<path class="letters" d="M514.6,581.7l-33-47.5L449,581.5h-36.4l50.7-69.4l-48.1-67.5h36l31.5,44.4l30.9-44.4h34.2l-47.7,66.1l51.1,70.9H514.6z"/>\n' +
        '\t<path class="letters" d="M640.3,470.5h-43.8v-25.8h119.4v25.8H672v111.2h-31.7V470.5z"/>\n' +
        '\t<path class="letters" d="M862.5,444.7h31.7v111.2h68.7v25.8H862.5V444.7z"/>\n' +
        '\t<path class="letters" d="M1122.4,444.7l-59.2,137h-31.4l-59.2-137h34.2l41.7,97.8l42.3-97.8L1122.4,444.7z"/>\n' +
        '\t<path class="letters" d="M1162.3,444.7h31.7v111.2h68.7v25.8h-100.4V444.7z"/>\n' +
        '\t<path class="letters" d="M1391.3,444.7h31.7v137h-31.7L1391.3,444.7z"/>\n' +
        '\t<path class="letters" d="M1620.3,444.7v137h-26l-68.3-83.2v83.2h-31.4v-137h26.3l68.1,83.2v-83.2H1620.3z"/>\n' +
        '\t<path class="letters" d="M1741.8,527.9l-18.4,19.2v34.6h-31.5v-137h31.5v64l60.7-64h35.2l-56.8,61.1l60.1,75.9h-37L1741.8,527.9z"/>\n' +
        '</g>\n';
    }
    if (layers === 'forms' || layers === 'both') {
      svgCode += '<rect x="1311.1" y="550.1" class="gold" width="31.7" height="31.7"/>\n' +
        '<rect x="750.8" y="507.6" class="gold" width="64.1" height="26.3"/>\n' +
        '<path class="gold" d="M1471.7,769.1h-879V659.7h31.7v77.6H1440v-77.6h31.7L1471.7,769.1z"/>\n' +
        '<polygon class="gold" points="1471.7,366.8 1440,366.8 1440,289.2 624.4,289.2 624.4,366.8 592.7,366.8 592.7,257.5 1471.7,257.5 "/>\n' +
        '<rect x="241.7" y="841.8" class="gold" width="1581" height="180"/>\n';
    }
    if (layers === 'letters' || layers === 'both') {
      svgCode += '<text text-anchor="middle">\n' +
        '  <tspan  x="1038" y="986" class="name">' + text + '</tspan>\n' +
        '</text>\n';
    }
    svgCode += '</svg>';
    return svgCode;
  }

  textChanged() {
    this.svgCodeBoth = this.getSvgCode(this.text.toUpperCase(), this.letterColor, this.letterSpace, 'both', this.fontSize);
    // this.svgCodeLetters = this.getSvgCode(this.text.toUpperCase(), this.letterColor, this.letterSpace, 'letters');
    // this.svgCodeForms = this.getSvgCode(this.text.toUpperCase(), this.letterColor, this.letterSpace, 'forms');
    this.imgSrcBoth = 'data:image/svg+xml;base64,' + btoa(this.svgCodeBoth);
    this.imgSrcLetters = 'data:image/svg+xml;base64,' + btoa(this.svgCodeLetters);
    this.imgSrcForms = 'data:image/svg+xml;base64,' + btoa(this.svgCodeForms);
  }


  download(layers: 'letters' | 'forms' | 'both') {
    const element = document.createElement('a');
    let blob;


    if (layers === 'letters') {
      blob = new Blob([this.svgCodeLetters]);
    } else if (layers === 'forms') {
      blob = new Blob([this.svgCodeForms]);
    } else if (layers === 'both') {
      blob = new Blob([this.svgCodeBoth]);
    }

    element.download = this.text.toLowerCase() + '_' + layers + '.svg';
    element.href = window.URL.createObjectURL(blob);
    element.click();
    element.remove();
  }
}
