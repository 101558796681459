<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>Bank</nxt-page-header-title>

    <!--<nxt-button nxtPermission="IsJulian" (click)="reImportAllClicked()">import</nxt-button>-->


    <div class="flex flex-col items-center">
      <mat-button-toggle-group [(ngModel)]="filterMode" (ngModelChange)="filterModeChanged()">
        <mat-button-toggle value="time">Zeitraum</mat-button-toggle>
        <mat-button-toggle value="bank-cash">Kasse - Bank</mat-button-toggle>
        <mat-button-toggle value="opos">OPOS</mat-button-toggle>
      </mat-button-toggle-group>

      @switch (filterMode()) {
        @case ('time') {
          <nxt-date-range-picker [showJumpMonths]="true" [bottomNoPadding]="true" [(value)]="dateFromTill" (valueChange)="load()" placeholder="Von - Bis"/>
        }
      }
      <nxt-slide-toggle class="whitespace-nowrap" [(value)]="filterNotFinished" (valueChange)="load()" placeholder="nur Offene"></nxt-slide-toggle>
    </div>
    @if (filterMode() === 'opos') {
      <nxt-button nxtPermission="IsJulian" (click)="oposDocumentExportClicked()">OPOS Dokument-Export</nxt-button>
    }

    <nxt-input class="w-[12rem]" [(value)]="quickFilterText" placeholder="Suche" [clearIcon]="true"></nxt-input>
    <nxt-input class="w-[6rem]" [(value)]="filterValue" placeholder="Betrag" [clearIcon]="true"></nxt-input>


    <nxt-button-icon (click)="reloadCLicked()">refresh</nxt-button-icon>
    <nxt-button-icon matTooltip="RUN" (click)="runClicked()">sprint</nxt-button-icon>
    <nxt-button-icon matTooltip="Datev-Export" (click)="runDateExportClicked()">share_windows</nxt-button-icon>
    <nxt-button-icon matTooltip="Dokumente anzeigen" (mouseup)="showBankDocumentsClicked($event)">docs</nxt-button-icon>
    <nxt-button-icon matTooltip="Banken & PayPal einlesen" (mouseup)="runBankAndPayPalImportClicked()">download</nxt-button-icon>
    <!--<div class="flex flex-row gap-4" nxtPermission="IsJulian">
      <div class="flex flex-col gap-3">
        <nxt-button (click)="reImportAllClicked()">IM</nxt-button>
        <nxt-button (click)="runActionClicked('delete-transfer-match')">TRANSFER-MATCH-DELETE</nxt-button>
        <nxt-button (click)="runActionClicked('delete-document-match')">DOC-MATCH-DELETE</nxt-button>
      </div>

      <div>Unbekannt: {{ unknownCount() }} / {{ transactions().length }}</div>
      <div class="flex flex-col gap-3">
        <nxt-slide-toggle [(value)]="filterUnknown">Unbekannt</nxt-slide-toggle>
        <nxt-slide-toggle [(value)]="filterSupplier">Supplier</nxt-slide-toggle>
        <nxt-slide-toggle [(value)]="missingDatevNo">Fehlende Konten</nxt-slide-toggle>
      </div>
      <div class="flex flex-col gap-3">
        <nxt-slide-toggle [(value)]="filterCashBankTransfers">Bank - Cash</nxt-slide-toggle>
        <nxt-slide-toggle [(value)]="filterMissingTransferMatch">Transfer-Match fehlt</nxt-slide-toggle>
        <nxt-slide-toggle [(value)]="filterTransfer">Transfer</nxt-slide-toggle>
      </div>
    </div>-->
  </nxt-page-header>
  <nxt-page-content>
    <div class="mb-2 flex flex-row items-center px-1 studio-buttons overflow-x-auto">
      <div class="flex flex-col">
        <mat-icon matTooltip="Kein Konto" class="mx-2 mouse-pointer" (click)="selectAllAccounts(false)">deselect</mat-icon>
        <mat-icon matTooltip="Alle Konten" class="mx-2 mouse-pointer" (click)="selectAllAccounts(true)">select_all</mat-icon>
      </div>

      <div class="flex flex-col gap-1">
        <mat-button-toggle-group [multiple]="true" [(ngModel)]="filterCompany" (ngModelChange)="filterCompanyChanged()">
          <mat-button-toggle value="GBX GmbH">GBX</mat-button-toggle>
          <mat-button-toggle value="Krafft & Welsch GmbH & Co. KG">Krafft & Welsch</mat-button-toggle>
          <mat-button-toggle value="Krafft & Welsch GmbH & Co KG Sucursal en España">Krafft & Welsch ES</mat-button-toggle>
        </mat-button-toggle-group>


        @if (klarnaAccountsFiltered().length > 0) {
          <mat-button-toggle-group [multiple]="true" [(ngModel)]="filterKlarnaAccount">
            @for (klarnaAccount of klarnaAccountsFiltered(); track klarnaAccount.id) {
              <mat-button-toggle [value]="klarnaAccount.id">{{ klarnaAccount.name }}</mat-button-toggle>
            }
          </mat-button-toggle-group>
        }

        @if (paypalAccountsFiltered().length > 0) {
          <mat-button-toggle-group [multiple]="true" [(ngModel)]="filterPaypalAccount">
            @for (paypalAccount of paypalAccountsFiltered(); track paypalAccount.id) {
              <mat-button-toggle [value]="paypalAccount.id">{{ paypalAccount.name }}</mat-button-toggle>
            }
          </mat-button-toggle-group>
        }

        @if (qontoAccountsFiltered().length > 0) {
          <mat-button-toggle-group [multiple]="true" [(ngModel)]="filterQontoAccount">
            @for (qontoAccount of qontoAccountsFiltered(); track qontoAccount.id) {
              <mat-button-toggle [value]="qontoAccount.id">{{ qontoAccount.name }}</mat-button-toggle>
            }
          </mat-button-toggle-group>
        }

        @if (spkAccountsFiltered().length > 0) {
          <mat-button-toggle-group [multiple]="true" [(ngModel)]="filterSpkAccount">
            @for (spkAccount of spkAccountsFiltered(); track spkAccount.id) {
              <mat-button-toggle [value]="spkAccount.id">{{ spkAccount.name }}</mat-button-toggle>
            }
          </mat-button-toggle-group>
        }

        @if (creditCardAccountsFiltered().length > 0) {
          <mat-button-toggle-group [multiple]="true" [(ngModel)]="filterCreditCardAccount">
            @for (creditCardAccount of creditCardAccountsFiltered(); track creditCardAccount.id) {
              <mat-button-toggle [value]="creditCardAccount.id">{{ creditCardAccount.name }}</mat-button-toggle>
            }
          </mat-button-toggle-group>
        }
        @if (cashRegisterAccountsFiltered().length > 0) {
          <mat-button-toggle-group [multiple]="true" [(ngModel)]="filterCashRegisterAccount">
            @for (cashRegisterAccount of cashRegisterAccountsFiltered(); track cashRegisterAccount.id) {
              <mat-button-toggle [value]="cashRegisterAccount.id">{{ cashRegisterAccount.name }}</mat-button-toggle>
            }
          </mat-button-toggle-group>
        }
        @if (depositAccountsFiltered().length > 0) {
          <mat-button-toggle-group [multiple]="true" [(ngModel)]="filterDepositAccount">
            @for (depositAccount of depositAccountsFiltered(); track depositAccount.id) {
              <mat-button-toggle [value]="depositAccount.id">{{ depositAccount.name }}</mat-button-toggle>
            }
          </mat-button-toggle-group>
        }
      </div>

      <div class="ml-3 flex flex-row gap-3 balance-tables text-[60%] whitespace-nowrap">
        <table class="nxt-table">
          <tr>
            <td colspan="3" class="text-center text-[120%]">PayPal&nbsp;&nbsp;{{ availablePaypal() | money }}</td>
          </tr>
          @for (account of paypalAccounts(); track account.id) {
            <tr>
              <td>{{ account.name }}</td>
              <td class="text-right">{{ account.saldo |  money }}</td>
              <td>{{ account.lastSyncedAt | durationFromNowOnlyPast }}</td>
            </tr>
          }
        </table>
        <div class="flex flex-col">
          <table class="nxt-table">
            <tr>
              <td colspan="3" class="text-center text-[120%]">Qonto&nbsp;&nbsp;{{ availableQonto() | money }}</td>
            </tr>
            @for (account of qontoAccounts(); track account.id) {
              <tr>
                <td>{{ account.name }}</td>
                <td class="text-right">{{ account.saldo |  money }}</td>
                <td>{{ account.lastSyncedAt |  durationFromNowOnlyPast }}</td>
              </tr>
            }
            <tr>
              <td colspan="3">
                <div class="flex flex-row justify-center">
                  <nxt-button [smallButton]="true" (click)="allToMainClicked('qonto', 'GBX GmbH')">Alles aufs Hauptkonto</nxt-button>
                </div>
              </td>
            </tr>
          </table>
          <div class="flex justify-center items-end flex-grow">
            <div class="font-bold pt-2 text-[150%]">TOTAL: {{ (availablePaypal() + availableQonto() + availableSpk()) | money }}</div>
          </div>
        </div>
        <table class="nxt-table">
          <tr>
            <td colspan="10" class="text-center text-[120%]">Sparkasse&nbsp;&nbsp;{{ availableSpk() | money }}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>Saldo</td>
            <td>Stand</td>
            <td class="text-center">+0</td>
            <td class="text-center">+1</td>
            <td class="text-center">+2</td>
            <td class="text-center">+3</td>
            <td class="text-center">+4</td>
            <td></td>
          </tr>
          @for (account of spkAccounts(); track account.id) {
            <tr>
              <td>{{ account.name }}</td>
              <td class="text-right">{{ account.saldo |  money }}</td>
              <td>{{ account.lastSyncedAt |  durationFromNowOnlyPast }}</td>
              @for (unbooked of account.unbooked; track unbooked.dateString) {
                <td class="text-right" [class.red]="unbooked.value < 0 && !unbooked.ok">
                  {{ unbooked.value | money2 }}
                </td>
              }

              <td>
                <div class="flex justify-center items-center">
                  <mat-icon
                    fontSet="material-symbols-outlined"
                    class="mouse-pointer icon-sm"
                    [matTooltip]="'Überweisung von ' + account.name"
                    (click)="createFinTsTransferClicked(account)">
                    send
                  </mat-icon>
                </div>
              </td>
            </tr>
          }
          <td colspan="10">
            <div class="flex flex-row justify-center">
              <nxt-button [smallButton]="true" (click)="allToMainClicked('spk', 'GBX GmbH')">Alles aufs Hauptkonto</nxt-button>
            </div>
          </td>
        </table>
      </div>
    </div>
    <div class="flex flex-grow">
      <nxt-datagrid

        [columnDefs]="columnDefs"
        [rowData]="transactionsFiltered()"
        [quickFilterText]="quickFilterText()"
        [columnFilters]="true"
        [showFooter]="true"
        [loadingSpinner]="isLoading()"
      >
      </nxt-datagrid>
    </div>
  </nxt-page-content>
  <nxt-page-footer>
  </nxt-page-footer>
</nxt-page>



