import {Pipe, PipeTransform} from '@angular/core';
import {GlobalStudioShortName} from '../common-interfaces/global-studio.interface';

@Pipe({
  name: 'nxtStudio',
  standalone: true,
})
export class NxtStudioPipe implements PipeTransform {

  constructor() {
  }

  transform(value: GlobalStudioShortName | string, short = false): any {
    switch (value) {
      case 'ac':
        return 'Aachen';
      case 'ffm':
        return 'Frankfurt';
      case 'ab':
        return 'Aschaffenburg';
      case 'nu':
        return 'Nürnberg';
      case 'ma':
        return 'Mallorca';
      case 'du':
        return 'Düsseldorf';
      case 'do':
        return 'Dortmund';
      case 'mg':
        return 'Mönchengladbach';
    }
  }
}
