import {
  NxtStudioCashReportData,
  NxtStudioCashReportDataCalculatedCashRegister,
  NxtWorkSessionCashRegister,
  NxtWorkSessionCashRegisterState,
} from '../../common-interfaces/nxt.work-session';
import {ArtistsTools, CalcArtistData} from '../../common-browser/helpers/artists.tools';
import {NxtStudioCashReportPayment} from '../../common-interfaces/nxt.payment.interface';
import {NxtCalendarEvent} from '../../common-interfaces/nxt.calendar-event.interface';
import {IntervalTools} from '../../common-browser/helpers/interval.tools';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {SocketService} from '../../services/socket/socket.service';
import {LoginService} from '../../services/login.service';
import {DialogService, LoadingId} from '../../services/dialog.service';
import {ConfigService} from '../../services/config.service';
import {WorkSessionService} from '../../services/work-session.service';
import {TypeTools} from '../../common-browser/helpers/type.tools';
import {WorkSessionTools} from '../../common-browser/helpers/work-session.tools';
import {SortTools} from '../../common-browser/helpers/sort.tools';
import {NxtMoneyStack} from '../../common-interfaces/nxt.money-stack.interface';
import {MoneyStackTools} from '../../common-browser/helpers/money-stack.tools';
import {PermissionService} from '../../services/permission.service';
import {NxtPermissionId} from '../../common-interfaces/nxt.user.interface';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceNotFirst} from '../../common-browser/helpers/rxjs.tools';
import {NxtSocketSubscription} from '../../services/socket/socket-subscription';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {ArrayTools} from '../../common-browser/helpers/array.tools';
import {StringTools} from '../../common-browser/helpers/string.tools';
import {Log} from '../../common-browser/log/log.tools';
import {DayFinishService} from '../../services/day-finish.service';
import {CashPaymentService} from '../../services/cash-payment.service';
import {WalkInService} from '../../services/walk-in.service';
import {signal} from '@angular/core';
import {ArtistConfirmService} from '../../services/artist-confirm.service';

export interface NxtArtistReduceProposal {
  artist: string;
  text: string;
  toReduce: number;
  payoutSum: number;
  eventCount: number;
  events: {
    eventId: string;
    artistGet: number;
    sortValue: number;
    isPrivate: boolean;
    text: string;
    customerName: string;
  }[];
}

export class StudioCashReport2Service {
  showPostponedEvents = false;


  constructor(
    private socketService: SocketService,
    private loginService: LoginService,
    private dialogService: DialogService,
    private configService: ConfigService,
    private workSessionService: WorkSessionService,
    private permissionService: PermissionService,
    private dayFinishService: DayFinishService,
    private cashPaymentService: CashPaymentService,
    private walkInService: WalkInService,
    private artistConfirmService: ArtistConfirmService,
  ) {
    this.debouncedReload.pipe(debounceNotFirst(500)).subscribe(() => {
      this.reloadData();
    });
  }

  public cashRegisterRadioButtons: { text: string, value: string }[] = [];
  private minuteInterval: any;
  dateString = DateTools.format(new Date(), 'yyyy-MM-dd');
  data$ = new BehaviorSubject<NxtStudioCashReportData | null>(null);
  data: NxtStudioCashReportData;
  artistData: CalcArtistData[];
  showAllCashRegisters = false;
  totalIncomingView: number;
  totalOutgoingView: number;
  studioView: string;
  private newDataSubscription: NxtSocketSubscription;
  incomingDataView: NxtStudioCashReportPayment[];
  outgoingDataView: NxtStudioCashReportPayment[];
  calendarEventsView: NxtCalendarEvent[];
  myLoginCashRegister?: NxtWorkSessionCashRegister;
  cashRegisterView: NxtWorkSessionCashRegister;
  cashRegisterViewCalc: NxtStudioCashReportDataCalculatedCashRegister;
  loginIsMainCashRegister = false;
  showDateInGrid = new BehaviorSubject(false);
  debouncedReload = new Subject<void>();
  public viewFilter: { artist: string, cashState: 'all' | 't' | 'n', hideClosedEvents: boolean } = {
    artist: '',
    cashState: 'all',
    hideClosedEvents: false,
  };
  showNotLoggedInView = signal(false);

  public onOpenStudioCashReport() {
    this.startMinuteInterval();
    this.newDataSubscription = this.socketService.subscribeNew('studioCashReport2GetData', (data) => {
      this.setData(data);
    }, {emitInitial: true, emitAfterReconnect: true, emitInitialData: {dateString: this.dateString, studio: this.studioView}});

    this.loginIsMainCashRegister = !this.configService.config.value.studios.find(s => s.name === this.loginService.getStudio()).onlyCash;
  }

  public onCloseStudioCashReport() {
    IntervalTools.clear(this.minuteInterval);
    this.newDataSubscription.unsubscribe();
  }

  private setData(data?: NxtStudioCashReportData) {
    if (data && data.otherOpenWorkSession) {
      if (!this.permissionService.hasPermission(NxtPermissionId.StudioCashReport_DatePicker) || !data.otherOpenWorkSession.askUserIfManualDateChange) {
        this.dateString = data.otherOpenWorkSession.openWorkSessionDateString;
        this.reloadData();
        return;
      }
    }
    if (data) {
      this.data = data;
    }
    if (this.data.foundByAdditionalDateString) {
      this.dateString = this.data.workSession.dateString;
      // debugger;
    }
    this.incomingDataView = this.data.cashPayments.filter(p => p.direction === 'in');
    this.outgoingDataView = this.data.cashPayments.filter(p => p.direction === 'out');
    this.data.calculatedData.cashRegisters.map(r => r.show = false);
    this.cashRegisterView = null;

    if (this.showAllCashRegisters) {
      this.totalIncomingView = this.data.calculatedData.cashRegisters.map(r => r.incomingPaymentsSum + r.incomingPaymentsSum_).reduce((sum, i) => sum + i, 0);
      this.totalOutgoingView = this.data.calculatedData.cashRegisters.map(r => r.outgoingPaymentsSum + r.outgoingPaymentsSum_).reduce((sum, i) => sum + i, 0);
      this.data.calculatedData.cashRegisters.map(r => r.show = true);
    } else {
      this.incomingDataView = this.incomingDataView.filter(p => p.studio === this.studioView);
      this.outgoingDataView = this.outgoingDataView.filter(p => p.studio === this.studioView);
      this.totalIncomingView = this.data.calculatedData.cashRegisters.filter(c => c.studio === this.studioView).map(r => r.incomingPaymentsSum + r.incomingPaymentsSum_).reduce((sum, i) => sum + i, 0);
      this.totalOutgoingView = this.data.calculatedData.cashRegisters.filter(c => c.studio === this.studioView).map(r => r.outgoingPaymentsSum + r.outgoingPaymentsSum_).reduce((sum, i) => sum + i, 0);
      this.data.calculatedData.cashRegisters.map(r => r.show = r.studio === this.studioView);
      this.cashRegisterView = this.data.workSession.cashRegisters.find(c => c.studio === this.studioView);
      this.cashRegisterViewCalc = this.data.calculatedData.cashRegisters.find(c => c.studio === this.studioView);
    }
    if (this.showPostponedEvents) {
      this.calendarEventsView = this.data.postponedEvents;
    } else {
      this.calendarEventsView = this.data.events;
    }

    this.myLoginCashRegister = this.data.workSession.cashRegisters.find(c => c.studio === this.loginService.getStudio());
    this.calcArtistData();
    this.applyViewFilters();
    this.setCashRegisterRadioButtons();
    this.calcShowColumnsInGrid();
    this.calcShowNotLoggedInView();
    this.checkArtistConfirms();
    this.data$.next(this.data);
  }

  calcShowColumnsInGrid() {
    const eventsDateString = ArrayTools.unique(this.data.events.map(e => e.startDateString));
    const paymentsDateString = ArrayTools.unique(this.data.cashPayments.map(e => e.createdAtDateString));
    const dateStrings = ArrayTools.unique([...eventsDateString, ...paymentsDateString]);
    const showDate = dateStrings.length > 1;
    if (showDate !== this.showDateInGrid.value) {
      this.showDateInGrid.next(showDate);
    }
  }


  /***
   * Die Artist-Daten werden nach einem Reload und im Intervall neu berechnet
   * @private
   */
  calcArtistData() {
    // this.data.availableArtists.artists = this.data.availableArtists.artists.filter(a => a.name === 'MrNippel (Piercing)');
    // this.data.availableArtists.artists = this.data.availableArtists.artists.filter(a => a.name === 'Mr Realistic');
    this.artistData = ArtistsTools.calcArtists(this.data.workSession, this.data.events, this.data.postponedEvents, this.data.availableArtists);
  }

  private startMinuteInterval() {
    this.minuteInterval = setInterval(() => {
      this.runMinuteInterval();
    }, 1000 * 60);
  }


  private runMinuteInterval() {
    this.calcArtistData();
  }


  public async reloadData(noCache = false) {
    this.setData(await this.socketService.studioCashReport2GetData(this.dateString, this.studioView, noCache));
  }

  async isWorkSessionOpenMyLoginCashRegister(showDialogIfNot: boolean) {
    if (!this.myLoginCashRegister || this.myLoginCashRegister.state !== NxtWorkSessionCashRegisterState._1_Open) {
      if (showDialogIfNot) {
        if (this.permissionService.isJulian()) {
          const result = await this.dialogService.showYesNo('Deine Kasse ist nicht offen', {yesText: 'Force (Julian)', noText: 'OK'});
          if (result) {
            return true;
          }
        } else {
          this.dialogService.showOk('Deine Kasse ist nicht offen');
        }
      }
      return false;
    }
    return true;
  }

  startWorkSession() {
    return this.workSessionService.startWorkSession(this.dateString);
  }

  async startCashRegister() {
    const result = await this.dialogService.showInput('Zähle das Geld in deiner Kasse', {isMoney: true, placeholder: 'Startgeld'});
    if (TypeTools.isNumber(result)) {
      await this.workSessionService.startCashRegister(this.dateString, result);
    }
  }

  refreshView() {
    this.setData();
  }

  setViewedCashRegisterState(newState: NxtWorkSessionCashRegisterState) {
    if (this.cashRegisterView.state !== newState) {
      this.log('Kassen-Status wird auf ' + WorkSessionTools.getCashRegisterStateTextDebug(newState) + ' gesetzt');
      this.cashRegisterView.state = newState;
      this.logCashRegisterState();
      this.updateWorkSessionCashRegister(this.cashRegisterView);
    }
  }

  public updateWorkSessionCashRegister(cashRegister: NxtWorkSessionCashRegister) {
    this.socketService.workSessionUpdateCashRegister(cashRegister);
  }

  public setArtistFilter(artistName: string) {
    if (this.viewFilter.artist === artistName) {
      this.viewFilter.artist = '';
    } else {
      this.viewFilter.artist = artistName;
    }
    this.setData();
  }

  private applyViewFilters() {
    if (this.viewFilter.artist) {
      this.calendarEventsView = this.calendarEventsView.filter(c => c.artist === this.viewFilter.artist);
      this.incomingDataView = this.incomingDataView.filter(p => p.artist === this.viewFilter.artist);
      this.outgoingDataView = this.outgoingDataView.filter(p => p.artist === this.viewFilter.artist);
    }
    if (this.viewFilter.cashState === 't') {
      this.calendarEventsView = this.calendarEventsView.filter(c => c.visibility === 'private');
      this.incomingDataView = this.incomingDataView.filter(p => p.isPrivate);
      this.outgoingDataView = this.outgoingDataView.filter(p => p.isPrivate);
      this.totalIncomingView = this.data.calculatedData.cashRegisters.filter(c => c.show).map(r => r.incomingPaymentsSum_).reduce((sum, i) => sum + i, 0);
      this.totalOutgoingView = this.data.calculatedData.cashRegisters.filter(c => c.show).map(r => r.outgoingPaymentsSum_).reduce((sum, i) => sum + i, 0);
    }
    if (this.viewFilter.cashState === 'n') {
      this.calendarEventsView = this.calendarEventsView.filter(c => c.visibility !== 'private');
      this.incomingDataView = this.incomingDataView.filter(p => !p.isPrivate);
      this.outgoingDataView = this.outgoingDataView.filter(p => !p.isPrivate);
      this.totalIncomingView = this.data.calculatedData.cashRegisters.filter(c => c.show).map(r => r.incomingPaymentsSum).reduce((sum, i) => sum + i, 0);
      this.totalOutgoingView = this.data.calculatedData.cashRegisters.filter(c => c.show).map(r => r.outgoingPaymentsSum).reduce((sum, i) => sum + i, 0);
    }

    if (this.viewFilter.hideClosedEvents) {
      this.calendarEventsView = this.calendarEventsView.filter(c => !c.closed);
    }
  }

  getArtistPayouts(beforeArtistPayout: boolean) {
    return ArtistsTools.getArtistPayouts(this.data, {calcType: 'pre-calc', withEarlyPayout: false});
  }

  private setCashRegisterRadioButtons() {
    this.cashRegisterRadioButtons = this.data.workSession.cashRegisters.map(c => {
      let text = '<div class="flex flex-col">';
      text += '<div>' + c.studio + '<div>';
      text += '<div class="text-60" style="line-height: 1.5">';
      if (c.currentUser) {
        text += '<div>' + c.currentUser + '・' + WorkSessionTools.getCashRegisterStateText(c.state, true) + '</div>';
      } else {
        text += '<div>' + WorkSessionTools.getCashRegisterStateText(c.state, true) + '</div>';
      }
      if (c.missingValue) {
        if (c.missingValue > 0) {
          text += '<div class="red-light">' + c.missingValue.toMoneyString() + ' zu wenig</div>';
        } else {
          text += '<div class="red-light">' + Math.abs(c.missingValue).toMoneyString() + ' zu viel</div>';
        }
      }
      text += '</div>';
      return {
        text,
        value: c.studio,
      };
    }).sort(SortTools.sortString('value'));
  }

  async setPreCalcCashRegisterMoneyStackxXX(preCalcCashRegisterMoneyStack: NxtMoneyStack) {


    // const clone = ObjectTools.clone(result);
    // const cashRegisterCalcData = this.data.calculatedData.cashRegisters.find(c => c.studio === this.studioView);
    /*const value = cashRegisterCalcData.incomingPaymentsSum_ - cashRegisterCalcData.outgoingPaymentsSum_;
    const moneyStackReduceResult = MoneyStackTools.reduceFromMoneyStack(clone, value);
    if (!moneyStackReduceResult.possible) {
      debugger;
      throw Error('Tresorgeld kann nicht vorab abgezogen werden :(');
    }*/
    // const newMoneyStackValue = MoneyStackTools.getTotalValueFromMoneyStack(clone);
    // const shouldValue = this.cashRegisterView.startMoney + cashRegisterCalcData.incomingPaymentsSum - cashRegisterCalcData.outgoingPaymentsSum;
    /*if (newMoneyStackValue !== shouldValue) {
      this.dialogService.showOk('Rechenfehler!\n' + newMoneyStackValue + '\n' + shouldValue);
      return;
    }*/
  }

  canPayoutPreCalcCashRegister() {
    const artistPayouts = this.getArtistPayouts(true);
    const payoutData: { values: { id: string; value: number }[]; prioIds: string[] } = {
      prioIds: [],
      values: artistPayouts.artists.map(p => ({id: p.artistName, value: p.payoutValueTotal})),
    };
    const safeValue = this.getSaveValueForMoneyCashCalc();
    if (safeValue) {
      payoutData.values.push(safeValue);
      payoutData.prioIds.push(safeValue.id);
    }
    const result = MoneyStackTools.canReduceMultiFromMoneyStack(this.cashRegisterView.moneyStackInternal, payoutData.values, payoutData.prioIds);
    return result.possible;
  }

  async bookBankAndSafe(maxBankValue: number, safeValue: number, shouldValueBeforePayoutsWithoutSafe: number) {
    const maxValueBank = MathTools.roundTo(maxBankValue, 5, false);
    let bankValue: number = await this.dialogService.showInput('Wie viel Geld geht zur Bank?', {isMoney: true});
    if (!TypeTools.isNumber(bankValue)) {
      return false;
    }
    bankValue = MathTools.roundMoney(bankValue);
    if (bankValue > maxBankValue) {
      const text = 'So viel Geld kannst du nicht zur Bank bringen.' +
        '\n\n Maximal auf dem Tisch: ' + maxBankValue.toMoneyString() +
        '\n\n Maximal zur Bank: ' + maxValueBank.toMoneyString() +
        '\n\nWenn du mehr Geld auf dem Tisch liegen hast, fehlt Geld in einem Umschlag!';
      this.logDialog(text);
      await this.dialogService.showOk(text);
      return false;
    }
    if (bankValue % 5 > 0) {
      const text = 'Wie willst du ' + bankValue.toMoneyString() + ' bei der Bank einzahlen?\nDu kannst nur Scheine zur Bank bringen.';
      this.logDialog(text);
      await this.dialogService.showOk(text);
      return false;
    }
    await this.socketService.bookBankAndSafe(this.dateString, bankValue, safeValue, shouldValueBeforePayoutsWithoutSafe);
    this.log('Bank (' + bankValue.toMoneyString() + ') und Tresor (' + safeValue.toMoneyString() + ') wird gebucht [shouldValueBeforePayoutsWithoutSafe: ' + shouldValueBeforePayoutsWithoutSafe.toMoneyString() + ']');
    return true;
  }

  /**
   * da events verschoben werden, muss das hier live berechnet werden
   */
  getArtistReduceProposals(postponedEventIds: string[]) {
    const allEvents = [...this.data.events, ...this.data.postponedEvents];
    const postponedEvents = allEvents.filter(e => postponedEventIds.includes(e.id));
    const events = allEvents.filter(e => !postponedEventIds.includes(e.id));
    const artistData = ArtistsTools.calcArtists(this.data.workSession, events, postponedEvents, this.data.availableArtists);

    const artistReduceProposals: NxtArtistReduceProposal[] = [];
    for (const artistPayout of artistData) {
      let toReduce = 0;
      let diff = artistPayout.payoutValue % 10;
      let text = '';
      if (artistPayout.payoutValue % 10 !== 0) {
        let percentage = MathTools.round(diff / artistPayout.payoutValue * 100, 1);

        if (percentage <= 5) {
          text = `${diff.toMoneyString()} abziehen, das sind ${percentage}% von ${artistPayout.payoutValue.toMoneyString()} ist OK`;
          toReduce = MathTools.roundMoney(diff);
        } else {
          diff = artistPayout.payoutValue % 5;
          if (diff !== 0) {
            percentage = MathTools.round(diff / artistPayout.payoutValue * 100, 1);
            if (percentage <= 5) {
              text = `Zieh ${diff.toMoneyString()} abziehen, das sind ${percentage}% von ${artistPayout.payoutValue.toMoneyString()} ist OK`;
              toReduce = MathTools.roundMoney(diff);
            }
          }
        }
      }
      if (!text) {
        text = '✅ Nichts abziehen'; // , ${artistPayout.payoutValue.toMoneyString()} sind schon echt nicht viel';
      }
      const eventsData = this.getBestEventsToArtistReduce(artistPayout.name, events);
      artistReduceProposals.push({
        artist: artistPayout.name,
        payoutSum: artistPayout.payoutValue,
        text,
        toReduce,
        events: eventsData.events,
        eventCount: eventsData.totalEventCount,
      });
    }
    return artistReduceProposals
      .filter(a => a.eventCount > 0 && a.payoutSum > 0)
      .sortString('artist');
  }

  private getBestEventsToArtistReduce(artistName: string, events: NxtCalendarEvent[]) {
    const result = events
      .filter(e => e.artist === artistName && !e.payments.some(p => p.earlyPayout) && e.priceEstimatedFrom > 0)
      .map(e => {
        return {
          eventId: e.id,
          artistGet: e.artistTotalGet,
          sortValue: e.visibility === 'private' ? e.artistTotalGet * 1000 : e.artistTotalGet,
          isPrivate: e.visibility === 'private',
          text: e.paymentSum.toMoneyString() + ' ' + StringTools.arrowRight + ' ' + e.artistTotalGet.toMoneyString() + ' (' + Math.round(e.artistPercentage) + ' %)',
          customerName: e.customerObj.fullName,
        };
      }).sortNumber('sortValue', true);
    const totalEventCount = result.length;
    if (result.length > 3) {
      result.length = 3;
    }
    return {totalEventCount, events: result};
  }

  log(message: string) {
    this.dayFinishService.log({
      message,
      cashRegisterView: this.cashRegisterView.studio,
      workSessionDateString: this.dateString,
    });
  }

  logDialog(message: string) {
    this.log('Info wird gezeigt: ' + message);
  }

  getSaveValueForMoneyCashCalc() {
    if (this.data.calculatedData.endOfDayCash.value_ > 0) {
      return {id: 'Tresor', value: this.data.calculatedData.endOfDayCash.value_};
    } else if (this.cashRegisterView.safeCashPayment) {
      // Tresor-Zahlung ist bereits gebucht, also von da
      return {id: 'Tresor', value: this.cashRegisterView.safeCashPayment.value};
    }
  }

  async workSessionPrintAllInvoices() {
    return this.socketService.printInvoices(this.data.workSession.invoices.map(i => i.invoiceNumber));
  }

  async workSessionPrintDayFinishReports(employeeName: string) {
    return this.socketService.printDayFinishReports(this.dateString, this.cashRegisterView.studio, employeeName);
  }

  async showMergedInvoices(showPrintLocalInfo: boolean) {
    try {
      this.dialogService.showLoading(LoadingId.LoadPdfs, 'PDF wird erstellt...');
      const base64 = await this.socketService.getMergedInvoices(this.data.workSession.invoices.map(i => i.invoiceNumber));
      this.dialogService.hideLoading(LoadingId.LoadPdfs);
      this.dialogService.showPdf(base64, 'Rechnungen_' + this.dateString + '.pdf', {showPrintLocalInfo});
    } catch (err) {
      Log.error(err);
    }
  }

  async showMergedDayFinishReports(employeeName: string, showPrintLocalInfo: boolean) {
    try {
      this.dialogService.showLoading(LoadingId.LoadPdfs, 'PDF wird erstellt...');
      const base64 = await this.socketService.getMergedDayFinishReports(this.dateString, this.cashRegisterView.studio, employeeName);
      this.dialogService.hideLoading(LoadingId.LoadPdfs);
      this.dialogService.showPdf(base64, 'Kassenberichte_' + this.dateString + '.pdf', {showPrintLocalInfo});
    } catch (err) {
      Log.error(err);
    }
  }


  async askDayFinishReportEmployee(): Promise<string> {
    const users = this.configService.config.value.users.filter(u => u.cashReportCreator).map(login => login.realName);
    const employeeName = await this.dialogService.showButtonChooser({
      buttonRows: [users],
      title: 'Wer soll auf dem Kassenbericht stehen?',
      text: '',
      minWidth: '80%',
      value: '',
    });
    if (typeof employeeName === 'string') {
      return employeeName;
    }
    return '';
  }

  async walkInClicked(studioReal: string) {
    this.walkInService.startWalkIn();
  }

  logCashRegisterState() {
    this.log('Kassen-Status wird auf ' + WorkSessionTools.getCashRegisterStateTextDebug(this.cashRegisterView.state) + ' gesetzt');
  }

  createHandover(toUsername: string, shouldValue: number, value: number, moneyStack: NxtMoneyStack) {
    return this.socketService.createCashRegisterHandover({dateString: this.dateString, studio: this.cashRegisterView.studio, toUsername, shouldValue, value, moneyStack});
  }

  private calcShowNotLoggedInView() {
    let showIt = false;
    if (this.cashRegisterView && this.cashRegisterView.state === NxtWorkSessionCashRegisterState._1_Open) {
      if (this.loginService.isReception()) {
        if (this.cashRegisterView.currentUser && this.loginService.getUsername() !== this.cashRegisterView.currentUser) {
          showIt = true;
        }
      }
    }
    this.showNotLoggedInView.set(showIt);
  }

  private checkArtistConfirms() {
    const artistConfirm = this.data.artistConfirms.find(a => a.state === 'pending');
    if (artistConfirm) {
      this.artistConfirmService.waitForArtistConfirm(artistConfirm);
    }
  }
}
