import {Pipe, PipeTransform} from '@angular/core';
import {NxtDriveFileSubType} from '../common-interfaces/drive-file.interface';

@Pipe({
  name: 'nxtDriveFileSubType',
  standalone: true,
})
export class NxtDriveFileSubTypePipe implements PipeTransform {

  constructor() {
  }


  transform(subType: NxtDriveFileSubType): any {
    switch (subType) {
      case 'templatePhoto':
        return 'Vorlage Foto';
      case 'tattooPhoto':
        return 'Tattoo Foto';
      case 'tattooVideo':
        return 'Tattoo Video';
      case 'stencilPhoto':
        return 'Stencil Foto';
      case 'stencilVideo':
        return 'Stencil Video';
      case 'preTattooVideo':
        return 'Vorher Video';
      case 'preTattooPhoto':
        return 'Vorher Foto';
      case 'consent':
        return 'Einwilligung';
    }
    return '';
  }
}
