<div>
  <nxt-input [nxtFormControl]="form.controls.email"></nxt-input>
  <nxt-input [nxtFormControl]="form.controls.name"></nxt-input>
  <nxt-input [nxtMultiClick]="3" (nxtOnMultiClick)="enablePromoterId()" [nxtFormControl]="form.controls.promoterId"></nxt-input>
  <nxt-select [options]="[{value: 'MA', text: 'Mallorca'}, {value: 'AC', text: 'Aachen'}]" [nxtFormControl]="form.controls.studio"></nxt-select>
  <div class="flex pb-3">
    <nxt-slide-toggle [nxtFormControl]="form.controls.isNxtPromoter"></nxt-slide-toggle>
    <nxt-slide-toggle [nxtFormControl]="form.controls.disabled"></nxt-slide-toggle>
  </div>
  <nxt-textarea class="flex w-full" [nxtFormControl]="form.controls.infoInternal"></nxt-textarea>



  <div class="mt-5 mb-3">Provision</div>
  <div *ngFor="let percentage of form.controls.promoterPercentages.controls" class="flex flex-row gap-3">
    <nxt-date-picker [minDate]="minFrom" [nxtFormControl]="percentage.controls.startDateString"></nxt-date-picker>
    <nxt-date-picker [nxtFormControl]="percentage.controls.endDateString"></nxt-date-picker>
    <nxt-input [isPercent]="true" [nxtFormControl]="percentage.controls.percentage"></nxt-input>
  </div>
  <div class="flex flex-row justify-center">
    <nxt-button-icon (click)="addPercentage()">add</nxt-button-icon>
  </div>
</div>
<div class="flex flex-row justify-around">
  <nxt-button (click)="cancelClicked()">Abbrechen</nxt-button>
  <nxt-button (click)="saveClicked()">Speichern</nxt-button>
</div>

