import {Component, Inject} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {NxtFormControl} from '../../../nxt-form/nxt.form-control';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {NxtButtonComponent} from '../../button/nxt-button.component';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {MatButton} from '@angular/material/button';
import {NgFor, NgIf, NgStyle} from '@angular/common';
import {InputComponent} from '../../../components/form-controls/input/input.component';


export interface DialogData {
  message: string;
}

export interface ColorFontAndBack {
  fontColor: string;
  backgroundColor: string;
}

/**
 * @title Dialog Overview
 */
@Component({
    selector: 'nxt-dialog-input-whats-app',
    templateUrl: './dialog-input-whats-app.component.html',
    styleUrls: ['./dialog-input-whats-app.component.scss'],
    imports: [
        InputComponent,
        NgIf,
        NgFor,
        MatButton,
        NgStyle,
        ExtendedModule,
        NxtButtonComponent,
        SafeHtmlPipe,
    ]
})
export class DialogInputWhatsAppComponent {

  placeholder: string;
  message: string;
  buttonText: string;
  inputFormControl: UntypedFormControl;
  showColorPicker: boolean;
  currentColor: ColorFontAndBack;

  colors: ColorFontAndBack[] = [
    {backgroundColor: 'rgb(216, 0, 255)', fontColor: 'white'},
    {backgroundColor: 'red', fontColor: 'white'},
    {backgroundColor: 'green', fontColor: 'white'},
    {backgroundColor: 'blue', fontColor: 'white'}
    ];

  constructor(
    public dialogRef: MatDialogRef<DialogInputWhatsAppComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentColor = this.colors[0];
    this.placeholder = data.placeholder;
    this.message = data.message;
    this.buttonText = data.buttonText;
    this.showColorPicker = data.showColorPicker;
    this.inputFormControl = new NxtFormControl(data.prompt);
  }

  close(color: ColorFontAndBack) {
    if (!this.inputFormControl.invalid) {
      if (this.showColorPicker) {
        this.dialogRef.close({color, text: this.inputFormControl.value});
      } else {
        this.dialogRef.close(this.inputFormControl.value);
      }
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
