import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';

@Component({
  selector: 'nxt-page-content',
  template: `
    <ng-content/>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
      :host {
          display: flex;
          flex-direction: column;
          height: 100%;
      }`],
  standalone: true,
})

export class NxtPageContentComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  cdRef = inject(ChangeDetectorRef);

  constructor() {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }
}
