import {Component, Optional} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {DateTools} from 'src/app/common-browser/helpers/date.tools';
import {EventComponent} from '../../../../event/event.component';
import {DialogService} from '../../../../services/dialog.service';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {DatePickerComponent} from '../../../form-controls/date-picker/date-picker.component';
import {InputComponent} from '../../../form-controls/input/input.component';
import {FlexModule} from 'ngx-flexible-layout/flex';


@Component({
    selector: 'nxt-add-tagline-unknown',
    template: `
    <div fxLayout="column">
      <div class="pl-2 pt-2">Wiedervorlage für {{mobileNumber}}</div>
      <br/>
      <nxt-input placeholder="Info" [(value)]="text"></nxt-input>
      <div class="pl-2" fxLayout="row" fxLayoutAlign="start center">
        <div>Wann soll daran erinnert werden:</div>
        <div fxFlex="300px">
          <nxt-date-picker
            [(nxtModel)]="date"
            [minDate]="minDate"
          ></nxt-date-picker>
        </div>
        <div>
          <nxt-button (click)="setDate(1)">Morgen</nxt-button>
          <nxt-button (click)="setDate(2)">Übermorgen</nxt-button>
          <nxt-button (click)="setDate(3)">in 3 Tagen</nxt-button>
          <nxt-button (click)="setDate(7)">1 Woche</nxt-button>
          <nxt-button (click)="setDate(14)">2 Woche</nxt-button>
        </div>
      </div>
      <div class="pt-3" fxLayout="row" fxLayoutAlign="space-around">
        <nxt-button (click)="close();">Abbrechen</nxt-button>
        <nxt-button (click)="save();" [disabled]="!text ||!date">Speichern</nxt-button>
      </div>
    </div>
  `,
    imports: [FlexModule, InputComponent, DatePickerComponent, NxtButtonComponent]
})
export class AddTaglineUnknownComponent {

  mobileNumber: string;
  minDate = DateTools.todayDateString;
  date = '';
  text = '';

  constructor(
    @Optional() public dialogRef: MatDialogRef<EventComponent>,
    private dialogService: DialogService
  ) {

  }

  close() {
    this.dialogRef?.close();
  }

  setDate(daysToAdd: number) {
    this.date = DateTools.format(DateTools.addDays(Date.now(), daysToAdd), 'yyyy-MM-dd');

  }

  async save() {
    await this.dialogService.showOk('Sorry das geht nocht nicht, kommt die Tage!');
    this.close();
  }
}
