@if (qrCode()) {
  <div class="flex flex-col">
    <div class="flex flex-col md:flex-row">
      <div class="flex flex-col">
        <qrcode
          errorCorrectionLevel="M"
          [qrdata]="qrCode()"
          [width]="300" colorDark="#FFF"
          colorLight="#2A2A2A">
        </qrcode>
      </div>
      <div class="flex flex-col justify-center pt-3">
        <table>
          <tr>
            <td>1.</td>
            <td>Scanne mit dem Foto-iPhone diesen QR-Code.</td>
          </tr>
          <tr>
            <td>2.</td>
            <td>Fertig</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="flex flex-row justify-center pt-4">
      <nxt-button (click)="closeClicked()">Schließen</nxt-button>
    </div>
  </div>
}
