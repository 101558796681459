<div fxFlex class="h-100 p-relative">
  <div class="flex flex-row justify-between absolute w-full">
    <div>
      <nxt-button-icon
        *ngIf="!hideBackButton"
        (click)="back()" matTooltip="zurück"
      >chevron_left
      </nxt-button-icon>
    </div>
    <div>
      <nxt-button-icon
        *ngIf="!hideCancelButton"
        (click)="cancel()" matTooltip="abbrechen"
      >close
      </nxt-button-icon>
    </div>
  </div>
  <!--<button *ngIf="!hideCancelButton" #buttonClose style="position:absolute;right:0;" class="dialog-toolbar-button close-button" mat-icon-button
          (click)="cancel()" matTooltip="abbrechen">
    <mat-icon aria-hidden="false" aria-label="close">close</mat-icon>
  </button>-->

  <div fxLayout="column" class="outer-container">
    <div>
      <div class="title">
        <div #titleDiv [innerHTML]="title | safeHtml"></div>
      </div>
      <div class="text">
        <div [innerHTML]="text | safeHtml"></div>
      </div>
    </div>

    <div fxFlex>
      <div *ngFor="let buttons of buttonRows" fxLayout="row wrap" fxLayoutAlign="space-around">
        <div
          [ngStyle]="{marginBottom: '0.7em', backgroundColor: button.backgroundColor, color: button.color, padding: '7px'}"
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex.sm="48%" fxFlex.md="30%" fxFlex.lg="30%" fxFlex.xl="30%"
          *ngFor="let button of buttons"
          [ngClass]="{item: true, 'item-smaller-text': button.smallerText}"
          (click)="buttonClicked(button)"
        >
          <div style="margin:auto;"
               [innerHTML]="(button.text ? button.text : button) + (button.currentTimeoutText ? ' ('+button.currentTimeoutText+')' : '') | safeHtml"></div>
        </div>
      </div>
    </div>
  </div>
</div>
