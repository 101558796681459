<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <nxt-page-header-title>{{ accountName() }}&nbsp;&nbsp;&nbsp;{{ accountBalance() | money }}</nxt-page-header-title>
    <nxt-button (click)="newTransactionClicked()">Neue Transaktion</nxt-button>
    <nxt-input placeholder="Suche" [(value)]="quickFilterText" [clearIcon]="true"></nxt-input>
  </nxt-page-header>
  <nxt-page-content>
    <nxt-datagrid
      [columnDefs]="columnDefs"
      [quickFilterText]="quickFilterText"
      [rowData]="moneyTransactions()"
    />
  </nxt-page-content>
  <nxt-page-content>
    <div class="text-center text-2xl mt-4 mb-2">Offene Buchungen</div>
    <nxt-datagrid
      [columnDefs]="preTransactionColumnDefs"
      [rowData]="moneyPreTransactions()"
    />
  </nxt-page-content>
  <nxt-page-footer>

  </nxt-page-footer>
</nxt-page>
