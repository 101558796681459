import {NxtSubscriptionClass} from '../classes/nxt-subscription-class';
import {Component, OnDestroy} from '@angular/core';

import {ColorTools} from '../common-browser/helpers/color.tools';
import {BehaviorSubject, first} from 'rxjs';

export declare interface NxtOnDestroy {
  nxtOnDestroy(): void;
}

export interface NxtComponent {
  nxtOnDestroy?(): void;
}

@Component({
  template: '',
  standalone: false,
})
export abstract class NxtComponent extends NxtSubscriptionClass implements OnDestroy {
  colorTools = ColorTools;

  private _initDone = new BehaviorSubject(false);

  /**
   * somit muss man in der Component direkt kein ngOnDestroy implementieren
   * wenn man es in der Component jedoch implementiert, muss super.onDestroy aufgerufen werden!
   */
  ngOnDestroy(): void {
    super.onDestroy();
    if (this.nxtOnDestroy) {
      this.nxtOnDestroy();
    }
  }

  initDone() {
    this._initDone.next(true);
  }

  waitForInit() {
    return new Promise<void>((resolve, reject) => {
      if (this._initDone.value) {
        resolve();
      } else {
        this._initDone.pipe(first(v => v)).subscribe(() => resolve());
      }
    });
  }
}
