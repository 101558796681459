import {Component, Optional, ViewChild} from '@angular/core';
import {SocketService} from '../../services/socket/socket.service';
import {NxtPrePayoutResponse} from '../../common-interfaces/nxt.pre-payout.interface';
import {NxtColDef} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {ColorTools} from '../../common-browser/helpers/color.tools';
import {NxtDatagridComponent} from '../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {NxtCalendarEvent} from '../../common-interfaces/nxt.calendar-event.interface';
import {DecimalTools} from '../../common-browser/helpers/decimal.tools';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtFieldType} from 'src/app/common-interfaces/nxt-field.interface';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-pre-payout',
    templateUrl: './pre-payout.component.html',
    styleUrls: ['./pre-payout.component.scss'],
    imports: [FlexModule, NgIf, NxtButtonIconComponent, NxtDatagridComponent]
})
export class PrePayoutComponent {
  data: NxtPrePayoutResponse;
  dateString: any;
  @ViewChild('prePayoutGrid', {static: false}) prePayoutGrid: NxtDatagridComponent;
  columnDefs: NxtColDef[] = [
    {headerName: 'Artist', field: 'name', nxtFieldType: NxtFieldType.Text},

    {
      headerName: 'NxtFactor', nxtFieldType: NxtFieldType.Number, valueGetter: (params) => {
        let result = params.data.nxtFactor.pointsForPayout;
        result += params.data.nxtFactor.pointsForPayoutPerTimePlanned;
        result += params.data.nxtFactor.pointsForPayoutPerTimeReal;
        return Math.round(result);
      }, cellStyle: (params) => {
        if (params.value < 24) {
          return {color: ColorTools.Red};
        }
        if (params.value < 26) {
          return {color: ColorTools.Orange};
        }
        return {color: ColorTools.Green};
      }, sort: 'asc'
    },
    {headerName: 'Payout', field: 'totalPayout', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Payout geplant', field: 'totalPayoutPlanned', nxtFieldType: NxtFieldType.Money},

    {headerName: 'Prozente', field: 'totalPercentage', nxtFieldType: NxtFieldType.Percentage},

    {
      headerName: 'Termine', nxtFieldType: NxtFieldType.CalendarEvents, valueGetter: (params) => {
        return params.data.events;
      }, nxtFieldTypeParams: {
        getButtonText: (event: NxtCalendarEvent) => {
          let text = DateTools.format(event.start, 'HH:mm') + ' - ' + DateTools.format(event.end, 'HH:mm');
          if (event.customerObj) {
            text += '\n' + event.customerObj.givenName + ' ' + event.customerObj.familyName;
          }
          if (event.priceFix) {
            text += '\nFix: ' + DecimalTools.toMoneyString(event.priceEstimatedFrom);
          } else {
            text += '\nca. ' + DecimalTools.toMoneyString(event.priceEstimatedFrom);
            if (event.priceEstimatedTill) {
              text += ' - ' + DecimalTools.toMoneyString(event.priceEstimatedTill);
            }
          }
          if (event.paymentSum) {
            text += '\nbezahlt: ' + DecimalTools.toMoneyString(event.paymentSum);
          }
          return text;
        }
      }
    },
    {headerName: '€/Std geplant', field: 'moneyPerHourPlanned', nxtFieldType: NxtFieldType.Money},
    {headerName: '€/Std ist', field: 'moneyPerHourReal', nxtFieldType: NxtFieldType.Money},
    {headerName: 'Dauer geplant', field: 'totalDurationPlanned', nxtFieldType: NxtFieldType.Duration},
    {headerName: 'Dauer ist', field: 'totalDurationReal', nxtFieldType: NxtFieldType.Duration},


    // {headerName: 'pointsForPayout', field: 'nxtFactor.pointsForPayout', nxtFieldType: NxtFieldType.Number},
    // {headerName: 'pointsForPayoutPerTimePlanned', field: 'nxtFactor.pointsForPayoutPerTimePlanned', nxtFieldType: NxtFieldType.Number},
    // {headerName: 'pointsForPayoutPerTimeReal', field: 'nxtFactor.pointsForPayoutPerTimeReal', nxtFieldType: NxtFieldType.Number},


  ];


  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
    private socketService: SocketService
  ) {
  }

  async load(dateString: string) {
    this.dateString = DateTools.format(dateString, 'dd.MM.yyyy');
    this.data = await this.socketService.getPrePayoutData(dateString);
    console.log(this.data);
  }
}
