<nxt-page>
  <nxt-page-header [dialogRef]="dialogRef">
    <div>Artikel</div>
    <nxt-button (click)="newArticle()">Neuer Artikel</nxt-button>
  </nxt-page-header>

  <nxt-page-content>
    <nxt-datagrid
      class="h-full w-full"
      [rowData]="articles"
      [columnDefs]="columnDefs"
    >
    </nxt-datagrid>
  </nxt-page-content>
  <nxt-page-footer>ich bin der footer</nxt-page-footer>
</nxt-page>
