import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {EventComponent} from '../../event/event.component';
import {SocketService} from '../../services/socket/socket.service';
import {DialogService} from '../../services/dialog.service';
import {Log} from '../../common-browser/log/log.tools';
import {NxtFormControl} from '../../nxt-form/nxt.form-control';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {FormTools} from '../../services/form.tools';
import {LoginService} from '../../services/login.service';
import {ArticleTools, NxtArticleOld} from '../../common-browser/helpers/article.tools';
import {DecimalTools} from '../../common-browser/helpers/decimal.tools';
import {ConfigService} from '../../services/config.service';
import * as QRCode from 'qrcode';
import {MatDialogRef} from '@angular/material/dialog';
import {CashPaymentService} from '../../services/cash-payment.service';
import {ObjectTools} from '../../common-browser/helpers/object.tools';
import {NxtCashPayment} from '../../common-interfaces/nxt.cash-payment.interface';
import {SafeResourceUrlPipe} from '../../pipes/safe-resource-url.pipe';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {DatePickerComponent} from '../form-controls/date-picker/date-picker.component';
import {InputComponent} from '../form-controls/input/input.component';
import {SelectComponent} from '../form-controls/select/select.component';
import {ColComponent} from '../../controls/nxt-grid/col/col.component';
import {RowComponent} from '../../controls/nxt-grid/row/row.component';
import {NgFor, NgIf} from '@angular/common';
import {PermissionDirective} from '../../directives/permission.directive';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {GoogleDriveFileInfo} from '../../common-interfaces/google-drive.interface';
import {SlideToggleComponent} from '../form-controls/slide-toggle/slide-toggle.component';
import {ValidatorTools} from '../../helpers/validator.tools';

@Component({
  selector: 'nxt-cash-report-incoming-outgoing-form',
  templateUrl: './cash-report-incoming-outgoing-form.component.html',
  styleUrls: ['./cash-report-incoming-outgoing-form.component.scss'],
  imports: [FlexModule, PermissionDirective, NgIf, FormsModule, ReactiveFormsModule, RowComponent, ColComponent, SelectComponent, InputComponent, DatePickerComponent, NgFor, NxtButtonComponent, SafeResourceUrlPipe, SlideToggleComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class CashReportIncomingOutgoingFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  cashType: 'incoming' | 'outgoing';
  articles: any;
  studios = this.configService.config.value.studios.map(studio => ({value: studio.name, text: studio.name}));
  private allArticles: NxtArticleOld[];
  showDescription = true;
  id: string;
  googleDriveFolderId = '';
  files: (GoogleDriveFileInfo & { base64?: string })[];
  public canReupload = false;
  private originalData: NxtCashPayment;
  private noUploadNeeded = false;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EventComponent>,
    private socketService: SocketService,
    private dialogService: DialogService,
    public loginService: LoginService,
    private configService: ConfigService,
    private cashReportCashService: CashPaymentService,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  public async loadFormId(cashType: 'incoming' | 'outgoing', id: string) {
    this.id = id;
    this.cashType = cashType;
    if (this.cashType === 'incoming') {
      this.allArticles = ArticleTools.articlesIncoming;
      this.articles = ArticleTools.articlesIncoming.filter(article => article.id !== '').map(article => ({
        text: article.id, // article.description + (article.price > -1 ? (' - ' + DecimalTools.toMoneyString(article.price)) : ''),
        value: article.id,
      }));
    } else {
      this.allArticles = ArticleTools.articlesOutgoing;
      this.articles = ArticleTools.articlesOutgoing.filter(article => article.id !== '').map(article => ({
        text: article.description + (article.price > -1 ? (' - ' + DecimalTools.toMoneyString(article.price)) : ''),
        value: article.id,
      }));
    }
    const data = await this.cashReportCashService.getById(id);
    this.originalData = ObjectTools.clone(data);
    if (!data) {
      throw Error('no cash-report-cash found for id ' + id + ' and type ' + this.cashType + '');
    }


    // * wenn sonstiges --> artikel hinzufügen */

    if (!this.articles.find(article => article.value === data.articleId)) {
      this.showDescription = true;
      this.articles.push({text: data.articleId, value: data.articleId});
    }

    this.googleDriveFolderId = data.googleDriveFolderId;


    // this.googleDriveFolderId = '1pG5qDHTUYdTfWZGk6kV5xxRKgBgLMJAs';

    if (this.googleDriveFolderId && this.googleDriveFolderId !== 'not-needed') {
      setTimeout(async () => {
        const preload = window.location.origin.includes('localhost');
        this.files = await this.socketService.getGoogleDriveFolder(this.googleDriveFolderId);
        this.cdRef.detectChanges();
      });
    }

    this.form = this.fb.group({
      id: new NxtFormControl(data.id, [ValidatorTools.requiredAndNotNaN]),
      articleId: new NxtFormControl(data.articleId),
      date: new NxtFormControl(new Date(DateTools.parse(data.createdAt)), [ValidatorTools.requiredAndNotNaN]),
      time: new NxtFormControl(DateTools.format(data.createdAt, 'HH:mm'), [ValidatorTools.requiredAndNotNaN]),
      studio: new NxtFormControl(data.studio),
      // studioReal: new NxtFormControl(data.studioReal),
      createdAtDateString: new NxtFormControl(data.createdAtDateString, [ValidatorTools.requiredAndNotNaN]),
      createdBy: new NxtFormControl(data.createdBy),
      value: new NxtFormControl(data.value),
      description: new NxtFormControl(data.description),
      editedAt: new NxtFormControl(DateTools.format(data.editedAt, 'dd.MM.yyyy HH:mm:ss')),
      editedBy: new NxtFormControl(data.editedBy),
      originalCreatedBy: new NxtFormControl(data.originalCreatedBy),
      bankConfirmed: new NxtFormControl(data.bankConfirmed),
      bankConfirmedManualReason: new NxtFormControl(data.bankConfirmedManualReason),
      _: new NxtFormControl(data._),
    });
    if (!this.loginService.isJulian()) {
      this.form.get('createdBy').disable();
    }
    this.form.get('editedAt').disable();
    this.form.get('editedBy').disable();
    if (this.cashType === 'outgoing' && !data._ && !data.googleDriveFolderId) {
      this.canReupload = true;
    }
    this.cdRef.detectChanges();
  }

  async ngOnInit() {
    document.body.style.backgroundColor = 'transparent !important';
  }

  ngOnDestroy(): void {
  }

  async saveAndClose() {
    if (!await this.canBook()) {
      return;
    }
    const result = await this.save();
    if (result) {
      this.close(result);
    }
  }

  private async save() {
    const checkErrors = await this.checkFormErrors();
    if (checkErrors) {
      try {
        const newObj: NxtCashPayment = this.form.getRawValue();
        newObj.createdAtDateString = DateTools.format(this.form.get('date').value, 'yyyy-MM-dd');
        if (!newObj.createdAt) {
          newObj.createdAt = DateTools.parse(newObj.createdAtDateString + ' ' + this.form.get('time').value, 'yyyy-MM-dd HH:mm');
        }
        newObj.editedBy = this.loginService.getUsername();
        newObj.value = parseFloat(newObj.value.toString().replace(',', '.'));
        newObj.editedAt = Date.now();
        if (this.noUploadNeeded) {
          newObj.googleDriveFolderId = 'not-needed';
        }
        const toSave = {...this.originalData, ...newObj} as NxtCashPayment;
        toSave.direction = this.cashType === 'incoming' ? 'in' : 'out';
        await this.cashReportCashService.upsert(toSave);
        return true;
      } catch (err) {
        Log.error(err);
        // alert('save failed! ' + err.message + ' - ' + JSON.stringify(err));
      }
    }
    return false;
  }

  async closeClicked() {
    this.close(false);
  }

  async close(result) {
    this.dialogRef.close(result);
  }

  private async checkFormErrors() {
    this.form.markAllAsTouched();
    if (FormTools.getErrors(this.form).length > 0) {
      await this.dialogService.showOk('Eingabe fehlt');
      return false;
    }
    return true;
  }

  async deleteClicked() {
    if (!await this.canBook()) {
      return;
    }
    const result = await this.dialogService.showYesNo('Eintrag löschen?', {yesText: 'Ja, löschen'});
    if (result) {
      await this.cashReportCashService.deleteById(this.form.get('id').value);
      this.close('deleted');
    }
  }

  articleChanged(articleId) {
    const article = this.allArticles.find(a => a.id === articleId);
    if (article) {
      this.form.get('description').setValue(article.description);
    }
  }

  public openGoogleDrive() {
    window.open('https://drive.google.com/drive/u/0/folders/' + this.googleDriveFolderId, '_blank');
  }

  public async reUpload() {
    const url = window.location.origin + '/event-qr/outgoing-scan' + this.form.get('id').value;
    const src = await QRCode.toDataURL(url, {width: 500, color: {dark: '#ffffff', light: '#2A2A2A'}});
    let useNew = true;
    if (this.configService.config.value.studioRegion === 'MA' && false) {
      useNew = await this.dialogService.showYesNo('Scannen oder Foto?', {yesText: 'Scannen', noText: 'Foto'});
    }
    if (useNew) {
      this.socketService.setNextDocScanWithoutQrCodeData({
        refId: this.form.get('id').value,
        refType: 'outgoing-scan',
      });
      const text = 'Benutze bitte den Scanner um den Beleg zu scannen, du hast 5 Minuten Zeit dafür.\n\nAuch quer, so wie die Einwilligungserklärungen\n\nBei kleinen Rechnungen benutze das Flachbett.';
      this.dialogService.showOk(text, {title: 'ACHTUNG NEU!'});
    } else {
      this.dialogService.showOk('<div style="width: 100%; text-align: center;"><br/><img src="' + src + '" /></div>', {title: 'Rechnung für Ausgabe fotografieren!'});
    }
    this.dialogRef.close();
  }


  async canBook() {
    let reason = '';
    if (this.originalData?.createdAt) {
      reason = await this.socketService.canBook(DateTools.parse(this.originalData.createdAt), this.originalData.studio);
    }
    if (!reason) {
      const createdAtDateString = DateTools.format(this.form.get('date').value, 'yyyy-MM-dd');
      const createdAtNew = DateTools.parse(createdAtDateString + ' ' + this.form.get('time').value, 'yyyy-MM-dd HH:mm');
      reason = await this.socketService.canBook(createdAtNew, this.originalData.studio);
    }
    if (reason) {
      if (await this.dialogService.showOkWithForceIfJulian(reason)) {
        return true;
      }
      return false;
    }
    return true;
  }

  noUploadNeededClicked() {
    this.noUploadNeeded = true;
    this.saveAndClose();
  }

  async setBankConfirmedClicked() {
    const manualReason = await this.dialogService.showInput('Bitte den Grund für die Bestätigung eingeben', {title: 'Bestätigung'});
    this.form.get('bankConfirmed').setValue(true);
    this.form.get('bankConfirmedManualReason').setValue(this.loginService.getUsername() + ': ' + manualReason);
    const result = await this.save();
    if (result) {
      this.close(result);
    }
  }
}
