import {AbstractControl} from '@angular/forms';

export class DateControl {

  private lastValue = '';
  private pointsRemoved = false;
  public enable = true;

  constructor(formControl: AbstractControl, onlyFirstCharIsDigit = false) {
    formControl.valueChanges.subscribe((value) => {
      if (!this.enable) {
        this.lastValue = value;
        return;
      }
      if (onlyFirstCharIsDigit && !/^\d+/g.test(value)) {
        this.lastValue = value;
        return;
      }
      let newValue = value;
      if (this.lastValue && value && value.length < this.lastValue.length && value.length + 1 === this.lastValue.length) {
        // zeichen wurde gelöscht;
        if (this.pointsRemoved) {
          this.pointsRemoved = false;
          return;
        }
        if (this.lastValue.endsWith('.')) {
          // was macht das hier?
          this.lastValue = value;
          setTimeout(() => formControl.setValue(value.substring(0, value.length - 1)));
        } else {
          this.lastValue = value;
        }
        return;
      }
      if (value.includes('..')) {
        newValue = value.replaceAll('..', '.');
        this.pointsRemoved = true;
      } else {
        newValue = this.completeDate(value);
      }
      if (newValue !== value) {
        setTimeout(() => formControl.setValue(newValue));
      }
      this.lastValue = value;
    });
  }

  public completeDate(data: string): string {
    if (data.length === 1) {
      const firstNumber = parseInt(data[0], 10);
      if (firstNumber > 3) {
        return '0' + data + '.';
      }
    } else if (data.length === 2) {
      if (!data.includes('.')) {
        return data + '.';
      } else {
        // bsp: 7.
      }
    } else if (data.length === 3) {
      if (data.includes('.')) {
        const firstNumber = parseInt(data.substring(0, 2), 10);
        if (firstNumber > 31) {
          return '31.';
        }
      } else {
        return data.substring(0, 2) + '.' + data.substring(2, 3);
      }
    } else if (data.length === 4) {
      if (data.includes('.')) {
        const secondNumber = parseInt(data.split('.')[1], 10);
        if (secondNumber > 1) {
          return data.substring(0, 3) + '0' + data.substring(3, 4) + '.';
        }
      } else {
        // return date.substring(0, 2) + '.' + date.substring(2, 3);
      }
    } else if (data.length === 5) {
      if (!data.endsWith('.')) {
        return data + '.';
      }
    } else if (data.length === 8) {
      const lastNumber = parseInt(data.split('.')[2], 10);
      if (lastNumber !== 19 && lastNumber !== 20) {
        if (lastNumber > 50) {
          data = data.substring(0, 6) + '19' + data.substring(6, 8);
        } else {
          data = data.substring(0, 6) + '20' + data.substring(6, 8);
        }
        return data;
      }
    } else if (data.length > 10) {
      return data.substring(0, 10);
    }
    return data;
  }
}
