import {inject, Injectable} from '@angular/core';
import {SocketService} from './socket/socket.service';
import {NxtReminder} from '../common-interfaces/nxt.reminder.interface';
import {DialogService} from './dialog.service';
import {NxtArtistConfirm} from '../common-interfaces/nxt.artist-confirm.interface';
import {LoginService} from './login.service';
import {ArtistConfirmWaitingComponent} from '../components/artist-confirm-waiting/artist-confirm-waiting.component';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArtistConfirmService {
  /*** Injections ***/
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  private loginService = inject(LoginService);
  private waitingIsRunning = false;


  constructor() {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  /*openArtistConfirmIfPending() {
    const artistConfirm = this.socketService.getPendingArtistConfirm();
    if (artistConfirm) {
      this.waitForArtistConfirm(artistConfirm);
    }
  }*/

  async startArtistConfirmFromReminder(reminder: NxtReminder) {
    const artistConfirm = await this.socketService.createArtistConfirm({
      artist: reminder.refObj.artist,
      value: reminder.refObj.value,
      workSessionDateString: reminder.refObj.workSessionDateString,
      state: 'pending',
      earlyPayout: false,
    });
    this.waitForArtistConfirm(artistConfirm.artistConfirm);
  }

  async start(artist: string, value: number, workSessionDateString: string, earlyPayout: boolean) {
    const artistConfirm = await this.socketService.createArtistConfirm({
      artist,
      value,
      workSessionDateString,
      state: 'pending',
      earlyPayout,
    });
    this.waitForArtistConfirm(artistConfirm.artistConfirm);
  }

  async waitForArtistConfirm(artistConfirm: NxtArtistConfirm) {
    if (this.waitingIsRunning) {
      return;
    }
    if (this.loginService.isBackoffice()) {
      return;
    }
    this.waitingIsRunning = true;
    try {
      const dialog = this.dialogService.showComponentDialog(ArtistConfirmWaitingComponent);
      dialog.componentInstance.start(artistConfirm);
      const result = await firstValueFrom(dialog.afterClosed());
      if (!result) {
        await this.socketService.cancelArtistConfirm(artistConfirm.id);
      }
    } catch (err) {

    }
    this.waitingIsRunning = false;
  }
}
