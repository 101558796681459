import {ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import Slide from 'photoswipe/dist/types/slide/slide';
import {NxtCalendarEvent, NxtCalendarEventFileWithEventId} from '../../common-interfaces/nxt.calendar-event.interface';
import {SocketService} from '../../services/socket/socket.service';
import {NxtDatePipe} from '../../pipes/nxt-date-pipe';
import {Money2Pipe} from '../../pipes/money-2.pipe';
import {NxtStudioPipe} from '../../pipes/nxt-studio-pipe';


@Component({
  selector: 'nxt-photo-swipe-info',
  templateUrl: './photo-swipe-info.component.html',
  styleUrls: ['./photo-swipe-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NxtDatePipe, Money2Pipe, NxtStudioPipe]
})

export class PhotoSwipeInfoComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/
  slide = signal<Slide>(null);
  eventFile = signal<NxtCalendarEventFileWithEventId>(null);
  visible = signal<boolean>(false);
  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);

  event = signal<NxtCalendarEvent | null>(null);


  constructor() {
    super();
    effect(async () => {
      this.visible();
      this.cdRef.detectChanges();
    });
  }

  async ngOnInit() {

  }

  nxtOnDestroy() {
    this.visible.set(false);
    this.cdRef.detectChanges();
  }

  showEventClicked(event: NxtCalendarEvent) {
    window.open('https://' + event.studio + '.nxt-lvl.ink/e/' + event.id, '_blank');
  }
}
