import {Injectable} from '@angular/core';
import {SocketService} from '../socket.service';
import {GlobalStudioShortName} from '../../../common-interfaces/global-studio.interface';
import {NxtBankBookingAccount, NxtBankCompany, NxtBankConfigAccount, NxtBankDatevExportLine, NxtBankTransaction} from '../../../common-interfaces/bank/bank-transaction.interface';
import {SocketInterface, SocketInterfaceResponse} from '../../../common-interfaces/socket/socket-interface';

@Injectable({
  providedIn: 'root',
})
export class EmployeeSubSocketService {
  /*** Injections ***/

  constructor(private socketService: SocketService) {
    setTimeout(() => this.init());
  }

  private async init() {
  }

  private async emit(eventName: string, data?: any): Promise<any> {
    return this.socketService.emit('employee.' + eventName, data);
  }

  async isWorkingByName(employeeName: string, dateString: string) {
    return this.emit('isWorkingByName', {employeeName, dateString});
  }
}
