<div fxLayout="column" *ngIf="paymentPossibilityRecord" style="width: 350px;">
  <div fxLayout="row" fxLayoutAlign="center" class="text-130" style="padding-top:0.5em">Gutschein bearbeiten</div>
  <div fxLayout="row" fxLayoutAlign="space-around" style="padding-top:1em" class="text-110">
    <div>{{paymentPossibilityRecord.name}}</div>
    <div>{{paymentPossibilityRecord.value | money}}</div>
  </div>
  <div *ngIf="paymentPossibilityRecord.discountReason" fxLayout="row" fxLayoutAlign="space-around" style="padding-top:1em">
    {{paymentPossibilityRecord.discountReason}}
  </div>

  <div *ngIf="paymentPossibilityRecord.assigned" fxLayout="row" fxLayoutAlign="space-around" style="padding-top:1em">

    <div *ngFor="let assignment of paymentPossibilityRecord.assignments"
         fxLayout="column"
         fxLayoutAlign="center"
         fxLayoutGap="0.5em"
         class="w-full"
         style="border: 1px solid #787878; border-radius: 3px; padding: 0.5em; margin: 0 1em">
      <div class="mouse-pointer" (click)="showEvent(assignment.eventId)">Termin anzeigen</div>
      <div>Zugewiesen: {{assignment.value | money}}</div>
    </div>

  </div>


  <div fxLayout="row" style="padding-top:1em">
    <nxt-input [selectOnInit]="true" [isMoney]="true" placeholder="Bezahlt" width="100%" [(value)]="paymentPossibilityRecord.paymentValue"></nxt-input>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-around">
    <nxt-button (click)="close()">abbrechen</nxt-button>
    <nxt-button (click)="deleteClicked()">löschen</nxt-button>
    <nxt-button (click)="save()">speichern</nxt-button>
  </div>
</div>
