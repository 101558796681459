import {Component, Input, OnInit} from '@angular/core';
import {ElectronService} from '../../../../services/electron.service';
import {NxtConfig} from '../../../../common-interfaces/nxt.config.interface';
import {SocketService} from '../../../../services/socket/socket.service';
import {DialogService, LoadingId} from '../../../../services/dialog.service';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {SlideToggleComponent} from '../../../form-controls/slide-toggle/slide-toggle.component';
import {NxtButtonIconComponent} from '../../../../controls/button-icon/nxt-button-icon.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {InputComponent} from '../../../form-controls/input/input.component';
import {NgIf} from '@angular/common';

@Component({
  selector: 'nxt-settings-paypal-import',
  templateUrl: './settings.paypal-import.component.html',
  styleUrls: ['./settings.paypal-import.component.scss'],
  imports: [NgIf, InputComponent, FlexModule, NxtButtonIconComponent, SlideToggleComponent, NxtButtonComponent],
  standalone: true,
})

export class SettingsPaypalImportComponent implements OnInit {

  @Input() config: NxtConfig.PaypalImport;

  /*@Input() paypalImport: NxtConfig.PaypalImport;
  @Input() timer: number;
  @Output() timerChange = new EventEmitter<number>();*/

  constructor(
    private electronService: ElectronService,
    private socketService: SocketService,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit() {

  }

  public openPaypalDevSite() {
    this.electronService.openExternal('https://developer.paypal.com/dashboard/applications/edit');
  }

  public async testPaypalImport() {
    this.dialogService.showLoading(LoadingId.ImportTest, 'Paypal-Import Test läuft...');
    try {
      const result = await this.socketService.paypalImportTest(this.config);
      if (typeof result === 'number') {
        this.dialogService.hideLoading(LoadingId.ImportTest);
        this.dialogService.showOk('Paypal-Import war erfolgreich\nAktuelles Guthaben: ' + result.toMoneyString());
      }
    } catch (err) {
      this.dialogService.hideLoading(LoadingId.ImportTest);
      // this.dialogService.showOk('Bank-Import Test fehlgeschlagen!\n' + err.message);
    }
  }
}
