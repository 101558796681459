import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtPageComponent} from '../../nxt-page/nxt-page.component';
import {CalendarWeekModule} from 'angular-calendar';
import {NxtPageContentComponent} from '../../nxt-page/nxt-page-content/nxt-page-content.component';
import {NxtPageFooterComponent} from '../../nxt-page/nxt-page-footer/nxt-page-footer.component';
import {NxtPageHeaderComponent} from '../../nxt-page/nxt-page-header/nxt-page-header.component';
import {NxtPageHeaderTitleComponent} from '../../nxt-page/nxt-page-header/nxt-page-header-title.component';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtEmployee, NxtEmployeeWorkDay, NxtEmployeeWorkWeek} from '../../../common-interfaces/nxt.employee.interface';
import {EmployeeData, SocketService} from '../../../services/socket/socket.service';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {ColorTools} from '../../../common-browser/helpers/color.tools';
import {IconTools} from '../../../common-browser/helpers/icon.tools';
import {DialogService} from '../../../services/dialog.service';
import {DurationTools} from '../../../common-browser/helpers/duration.tools';
import {MathTools} from '../../../common-browser/helpers/math.tools';
import {PermissionService} from '../../../services/permission.service';
import {NxtPermissionId} from '../../../common-interfaces/nxt.user.interface';
import {InputComponent} from '../../form-controls/input/input.component';
import {SelectComponent} from '../../form-controls/select/select.component';
import {TimeTools} from '../../../common-browser/helpers/time.tools';
import {LoginService} from '../../../services/login.service';
import {TypeTools} from '../../../common-browser/helpers/type.tools';

interface NxtEmployeeWorkWeekWithPayoutMonth extends NxtEmployeeWorkWeek {
  payoutMonth: string;
}


@Component({
  selector: 'nxt-employee-works',
  templateUrl: './employee-works.component.html',
  styleUrls: ['./employee-works.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NxtPageComponent,
    CalendarWeekModule,
    NxtPageContentComponent,
    NxtPageFooterComponent,
    NxtPageHeaderComponent,
    NxtPageHeaderTitleComponent,
    NxtDatagridComponent,
    InputComponent,
    SelectComponent,
  ],
})

export class EmployeeWorksComponent extends NxtComponent implements OnInit, NxtOnDestroy {

  constructor() {
    super();
  }

  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private socketService = inject(SocketService);
  private dialogService = inject(DialogService);
  private loginService = inject(LoginService);
  private permissionService = inject(PermissionService);
  dialogRef = inject(MatDialogRef<any>, {optional: true});


  daysColumnDefs: NxtColDef<NxtEmployeeWorkDay>[] = [
    {headerName: 'Datum', field: 'dateString', nxtFieldType: NxtFieldType.Date_germanDate},
    {headerName: 'Mitarbeiter', valueGetter: (params) => this.employees[params.data.employeeId].fullName},
    {headerName: 'Arbeit', field: 'workDuration', nxtFieldType: NxtFieldType.Duration},
    {headerName: 'Pause', field: 'breakDuration', nxtFieldType: NxtFieldType.Duration},
    // {headerName: 'Effektiv', field: '', nxtFieldType: NxtFieldType.Duration},
    {headerName: 'Krank', field: 'sickDuration', nxtFieldType: NxtFieldType.Duration},
    {headerName: 'Urlaub', field: 'vacationDuration', nxtFieldType: NxtFieldType.Duration},
    {
      headerName: 'Info', valueGetter: (params) => {
        return params.data.events.filter(e => e.infos && e.infos.length > 0).map(e => e.infos).flat().join(' - ');
      }, nxtFieldType: NxtFieldType.Text,
    },
  ];

  private employees: { [employeeId: string]: NxtEmployee } = {};
  workDays = signal<NxtEmployeeWorkDay[]>([]);
  workWeeksFiltered = computed(() => {
    if (this.selectedMonth()) {
      return this.workWeeks().filter(week => week.payoutMonth === this.selectedMonth()).sortNumber('start', true);
    }
    return this.workWeeks().sortNumber('start', true);
  });
  workWeeks = signal<NxtEmployeeWorkWeekWithPayoutMonth[]>([]);
  employeeData = signal<EmployeeData[]>([]);

  employeeDataColumnDefs: NxtColDef<EmployeeData>[] = [
    {headerName: 'Mitarbeiter', field: 'employeeFullName', nxtFieldType: NxtFieldType.Text},
    // {headerName: 'Arbeit', field: 'workDuration', nxtFieldType: NxtFieldType.Duration},
    {
      headerName: 'Arbeit',
      field: 'workDays',
      valueFormatter: params => {
        if (params.value > 0) {
          if (params.value === 1) {
            return params.value + ' Tag';
          }
          return params.value + ' Tage';
        }
      },
      nxtFieldType: NxtFieldType.Number,
    },
    // {headerName: 'Soll', field: 'shouldDuration', nxtFieldType: NxtFieldType.Duration},
    {
      headerName: 'Krank',
      valueGetter: params => params.data.sickDuration / (DurationTools.DURATION_1HOUR * 8),
      valueFormatter: params => {
        if (params.value > 0) {
          return params.value.toNumberString() + ' Tage';
        }
      },
      nxtFieldType: NxtFieldType.Text,
    },
    {
      headerName: 'Urlaub',
      valueGetter: params => params.data.vacationDuration / (DurationTools.DURATION_1HOUR * 8),
      valueFormatter: params => {
        if (params.value === 1) {
          return params.value.toNumberString() + ' Tag';
        }
        return params.value.toNumberString() + ' Tage';
      },
      nxtFieldType: NxtFieldType.Text,
      minWidth: 90, maxWidth: 90,
    },
    {
      headerName: 'Überstd.',
      field: 'moreDuration',
      nxtFieldType: NxtFieldType.Duration,
      cellRenderer: (params) => {
        if (params.value > 0) {
          return '+' + params.valueFormatted;
        }
        return params.valueFormatted;
      },
      cellStyle: (params) => ({textAlign: 'right', color: params.data.moreDuration < 0 ? ColorTools.Red : ColorTools.Green}),
    },
  ];


  weeksColumnDefs: NxtColDef<NxtEmployeeWorkWeekWithPayoutMonth>[] = [
    {
      headerName: '', nxtFieldType: NxtFieldType.Icon,
      cellRenderer: () => IconTools.Material.Refresh,
      nxtOnCellClicked: (params) => this.reCalcEmployeeWeekClicked(params.data),
    },
    {headerName: 'Woche', field: 'start', nxtFieldType: NxtFieldType.Date_germanDate},
    {headerName: 'Mitarbeiter', valueGetter: (params) => this.employees[params.data.employeeId].fullName},
    {headerName: 'Arbeitstage', field: 'workDays', nxtFieldType: NxtFieldType.Number},
    {headerName: 'Arbeit', field: 'workDuration', nxtFieldType: NxtFieldType.Duration},
    {headerName: 'Soll', field: 'shouldDuration', nxtFieldType: NxtFieldType.Duration},
    {
      headerName: 'Krank',
      valueGetter: params => params.data.sickDuration / (DurationTools.DURATION_1HOUR * 8),
      valueFormatter: params => {
        if (params.value > 0) {
          if (params.value === 1) {
            return params.value + ' Tag';
          }
          return params.value + ' Tage';
        }
      },
      nxtFieldType: NxtFieldType.Text,
    },
    {
      headerName: 'Urlaub',
      valueGetter: params => params.data.vacationDuration / (DurationTools.DURATION_1HOUR * 8),
      valueFormatter: params => {
        if (params.value > 0) {
          if (params.value === 1) {
            return params.value.toNumberString() + ' Tag';
          }
          return params.value.toNumberString() + ' Tage';
        }
      },
    },
    {headerName: 'Bezahlt', field: 'payoutDuration', nxtFieldType: NxtFieldType.Duration},
    {
      headerName: 'Überstd.',
      field: 'moreDuration',
      nxtFieldType: NxtFieldType.Duration,
      cellRenderer: (params) => {
        if (params.value > 0) {
          return '+' + params.valueFormatted;
        }
        return params.valueFormatted;
      },
      cellStyle: (params) => ({textAlign: 'right', color: params.data.moreDuration < 0 ? ColorTools.Red : ColorTools.Green}),
    },
    {
      headerName: '',
      nxtFieldType: NxtFieldType.Icon,
      valueGetter: (params) => {
        if (params.data.payoutValue) {
          return params.data.payoutValue;
        }
        return 0;
      },
      cellRenderer: (params) => {
        if (params.data.payoutValue) {
          return params.data.payoutValue.toMoneyString();
        }
        if (params.data.moreDuration > 0) {
          return IconTools.Material.Euro;
        }
      }, nxtOnCellClicked: (params) => this.payoutClicked(params.data),
      hide: !this.permissionService.hasPermission(NxtPermissionId.Employees_Payout),
    },
    {
      headerName: 'Auszahlmonat', valueFormatter: (params) => {
        return params.data.payoutMonth.dateParse('yyyy-MM').dateFormat('MMMM yy');
      },
    },
  ];
  quickFilterText = signal('');

  selectedMonth = signal('');
  monthOptions = signal(this.generateMonthOptions());


  generateMonthOptions() {
    let date = Date.now();
    const options = [];
    for (let i = 0; i < 6; i++) {
      date = date.dateAddMonths(-1 * i);
      options.push({text: date.dateFormat('MMM yyyy'), value: date.dateFormat('yyyy-MM')});
    }
    return options;
  }

  ngOnInit() {
    this.generateMonthOptions();
    this.load();
  }

  nxtOnDestroy() {
  }

  async load() {
    const data = await this.socketService.getEmployeeWorks(30);
    for (const employee of data.employees) {
      this.employees[employee.id] = employee;
      if (!employee.doTimeTrack) {
        data.workDays = data.workDays.filter(day => day.employeeId !== employee.id);
        data.workWeeks = data.workWeeks.filter(week => week.employeeId !== employee.id);
      }
    }
    this.workDays.set(data.workDays.sortString('dateString'));
    this.employeeData.set(data.employeeData);
    const workWeeksWithPayoutMonth = data.workWeeks.sortString('dateString').map(week => {
      (week as NxtEmployeeWorkWeekWithPayoutMonth).payoutMonth = week.start.dateAddDays(6).dateFormat('yyyy-MM');
      return week as NxtEmployeeWorkWeekWithPayoutMonth;
    });
    this.workWeeks.set(workWeeksWithPayoutMonth);
    for (const workWeek of data.workWeeks) {
      if (!TypeTools.isNumberAndNotNaN(workWeek.moreDuration)) {
        const employee = data.employeeData.find(e => e.employeeId === workWeek.employeeId);
        this.dialogService.showOk('Eine Wochenberechnung hat ein Fehler\nBitte Woche neu berechnen:\n' + workWeek.dateString.dateFormat('dd.MM.yyyy') + '\n' + employee.employeeFullName);
      }
    }
  }

  private async payoutClicked(week: NxtEmployeeWorkWeek) {
    if ((Date.now() - week.dateString.dateParse()) / DurationTools.DURATION_1DAY < 7) {
      this.dialogService.showOk('Die Woche ist noch nicht vorbei').then();
      return;
    }
    const payouts = await this.socketService.getEmployeeWorkWeekPayout(week.employeeId, week.dateString);
    if (payouts.length > 0) {
      if (payouts.length > 1) {
        this.dialogService.showOk('Achtung mehrere Auszahlungen, Julian muss prüfen');
      }
      if (payouts.length === 1) {
        const text = 'Auszahlung\nAuszahler: ' + payouts[0].createdBy + '\nBetrag: ' + payouts[0].payoutValue.toMoneyString() + '\nAusgezahlt am: ' + payouts[0].createdAt.dateFormat('dd.MM.yyyy HH:mm');
        const buttons = [{text: 'OK', value: 'ok'}];
        if (this.loginService.isJulian()) {
          buttons.push({text: 'Löschen', value: 'delete'});
        }
        const result = await this.dialogService.showButtons(text, {buttons});
        if (result.value === 'delete') {
          if (await this.dialogService.showYesNo('Wirklich löschen?')) {
            this.dialogService.showLoading('Auszahlung wird gelöscht');
            await this.socketService.deleteEmployeePayout(week.employeeId, week.dateString);
            await TimeTools.sleep(2000);
            this.dialogService.hideLoading();
            this.load().then();
          }
        }
        return;
      }
      return;
    }
    if (week.moreDuration <= DurationTools.DURATION_1MINUTE) {
      this.dialogService.showOk('Es gibts nix zum Auszahlen').then();
      return;
    }


    const text = this.employees[week.employeeId].fullName + '\n\n' + DurationTools.format(week.moreDuration, 'HH:mm') + ' Std ausbezahlen\n\n€uro pro Std?';
    const euroPerH = await this.dialogService.showInput(text, {isMoney: true});
    if (euroPerH) {
      this.dialogService.showLoading('Auszahlung wird gebucht');
      await this.socketService.createEmployeeWorkWeekPayout({
        employeeId: week.employeeId,
        dateString: week.dateString,
        payoutDuration: week.moreDuration,
        payoutValue: MathTools.roundMoney((week.moreDuration / DurationTools.DURATION_1HOUR) * euroPerH),
      });
      await TimeTools.sleep(2000);
      this.dialogService.hideLoading();
      this.load().then();
    }
  }

  private async reCalcEmployeeWeekClicked(data: NxtEmployeeWorkWeek) {
    this.dialogService.showLoading('Berechnung wird gestartet');
    await this.socketService.reCalcEmployeeWeekClicked(data.employeeId, data.dateString);
    this.dialogService.hideLoading();
    this.load();
  }
}
