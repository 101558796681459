import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DurationPipe} from '../../../pipes/duration.pipe';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgClass, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-time-button',
    templateUrl: './time-button.component.html',
    styleUrls: ['./time-button.component.scss'],
    imports: [FlexModule, NgClass, ExtendedModule, NgIf, DurationPipe]
})
export class TimeButtonComponent implements OnInit {

  @Input() value: string;
  @Input() hour: string | any;
  @Input() hourCounter: { full: string, hourCounter15: string, hourCounter30: string, hourCounter45: string };

  @Output()
  buttonClick = new EventEmitter<string>();


  constructor() {
  }

  ngOnInit() {
  }

  hourClick(value: string) {
    this.buttonClick.emit(value);
  }

  getButtonText(value) {

    return value;
  }

  getButtonClass(value: string) {
    if (value === this.value) {
      return 'currentValue';
    }
    return '';
  }

  public buttonClicked(hour: any) {
    this.buttonClick.emit(hour.value);
  }
}
