<table class="w-full" *ngIf="whatsApp">
  <tr>
    <td>Auto-Antwort</td>
    <td style="width: 100%">
      <nxt-slide-toggle [(value)]="whatsApp.autoResponse.enable"></nxt-slide-toggle>
    </td>
  </tr>
  <tr *ngIf="whatsApp.autoResponse.enable">
    <td>Auto-Antwort nach</td>
    <td>
      <div fxLayout="row" fxLayoutAlign="start center">
        <nxt-time-picker
          style="width: 100px; display:block"
          [value]="whatsApp.autoResponse.lastIncomingMessageThreshold | duration: 'HH:mm'"
          (valueChange)="setLastIncomingMessageThreshold($event)"
        ></nxt-time-picker>
        <div>Stunden wiederholen</div>
      </div>
    </td>
  </tr>
  <tr *ngIf="whatsApp.autoResponse.enable">
    <td>Auto-Antwort Texte</td>
    <td>
      <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let message of whatsApp.autoResponse.messages">
        <nxt-input placeholder="Handy fängt an mit" [(value)]="message.mobileStartsWith" [noPadding]="true"></nxt-input>
        <nxt-textarea [(value)]="message.message">
        </nxt-textarea>
      </div>
      <div fxLayout="row" class="w-full" fxLayoutAlign="center">
        <nxt-button-icon (click)="addMessage()">add</nxt-button-icon>
        <nxt-button-icon (click)="removeMessage()">remove</nxt-button-icon>
      </div>
    </td>
  </tr>
</table>

