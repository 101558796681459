<div class="h-100 nxt-page" fxFlex fxLayout="column">
  <div>
    <div>Summe: {{sum | money}}</div>
    <div fxFlex fxLayout="row">
      <nxt-input [(value)]="quickFilterText"></nxt-input>
      <mat-radio-group style="display: flex;flex-direction: column;margin: 0 10px;" [(ngModel)]="year" (ngModelChange)="load()">
        <mat-radio-button style="margin: 10px 0" class="studio-radio-button" value="2020">2020</mat-radio-button>
        <mat-radio-button style="margin: 10px 0" class="studio-radio-button" value="2021">2021</mat-radio-button>
      </mat-radio-group>

      <mat-radio-group style="display: flex;flex-direction: column;margin: 0 10px;" [(ngModel)]="more" (ngModelChange)="load()">
        <mat-radio-button style="margin: 10px 0" class="studio-radio-button" [value]="true">mehr</mat-radio-button>
        <mat-radio-button style="margin: 10px 0" class="studio-radio-button" [value]="false">weniger</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <nxt-datagrid
    class="h-100"
    [columnDefs]="columnDefs"
    [rowData]="data"
    [showFooter]="true"
    [quickFilterText]="quickFilterText"
  >


  </nxt-datagrid>
</div>
