<div class="text-120 p-3" fxLayout="row" fxLayoutAlign="center center">
  <div>
    {{type}} SMS-Code
  </div>
</div>
<div fxLayout="column" fxLayoutAlign="center center">
  <div *ngIf="dateText" class="pb-3" fxLayout="column" fxLayoutAlign="center center">
    <div class="pb-2">{{dateText}}</div>
    <div [ngStyle]="{color: toOld ? colorTools.Red : ''}">{{dateFromNowText}}</div>
    <div class="text-150 p-3" style="font-weight: bold;">{{code}}</div>
  </div>
  <div *ngIf="!dateText" class="pb-3">
    Kein SMS-Code vorhanden
  </div>
</div>

<div fxLayout="row" fxLayoutAlign="center center">
  <nxt-button *ngIf="dateText" (click)="copyAndClose()">In die Zwischenablage</nxt-button>
  <nxt-button (click)="dialogRef.close()" *ngIf="!dateText">OK</nxt-button>
</div>
