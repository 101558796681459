<div class="flex flex-col w-full h-full text-[10px] page-wrapper p-relative">

  <div class="fixed-header text-xl" *ngIf="pageCountCurrent > 0 && pageCountCurrent <= pageCountTotal">
    <div class="flex flex-row">
      <div [nxtTranslate]="lang">SEITE</div>
      <div>&nbsp;{{ pageCountCurrent + ' / ' + pageCountTotal }}</div>
    </div>
  </div>

  <div class="flex justify-center p-3">
    <div class="flex flex-col justify-center items-center">
      <div>
        <img src="../../../../assets/logo/logo_black.svg" style="width: 6cm"/>
      </div>
      <div class="flex flex-row mt-1 logo-text">
        <div>NXT-LVL.INK {{ configService.config.value.studioName }}</div>
        <div class="flex flex-row">
          <div class="gold">・</div>
          {{ configService.config.value.invoiceData.fromStreet }}
        </div>
        <div class="flex flex-row">
          <div class="gold">・</div>
          {{ configService.config.value.invoiceData.fromPostalCode }} {{ configService.config.value.invoiceData.fromCity }}
        </div>
      </div>
    </div>
  </div>

  <div class="flex text-3xl flex-col items-center pb-3 header">
    <div [nxtTranslate]="lang" class="text-center">PFLEGEHINWEISE ZAHNSCHMUCK</div>
  </div>

  @if (pageCountCurrent === 1) {
    <div>
      <nxt-declarations-of-consent-section titleText="Erste Stunden nach der Behandlung" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・️</td>
              <td [nxtTranslate]="lang">Nicht essen oder trinken für mindestens 1 Stunde, damit der Kleber vollständig aushärten kann.</td>
            </tr>
            <tr>
              <td>・️</td>
              <td [nxtTranslate]="lang">Vermeide harte, klebrige oder färbende Lebensmittel (z. B. Kaugummi, Kaffee, Tee, Rotwein) für die nächsten 24 Stunden.</td>
            </tr>
            <tr>
              <td>・️</td>
              <td [nxtTranslate]="lang">Nicht mit der Zunge oder den Fingern daran spielen, um ein frühzeitiges Ablösen zu verhindern.</td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
      <nxt-declarations-of-consent-section titleText="Tägliche Zahnpflege" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・️</td>
              <td [nxtTranslate]="lang">Regelmäßiges Zähneputzen (mind. 2x täglich) mit einer weichen Zahnbürste.</td>
            </tr>
            <tr>
              <td>・️</td>
              <td [nxtTranslate]="lang">Kein Whitening-Zahnpasta verwenden, da sie den Kleber angreifen kann.</td>
            </tr>
            <tr>
              <td>・️</td>
              <td [nxtTranslate]="lang">Zahnseide & Mundspülung zur gründlichen Reinigung nutzen – besonders um den Schmuck herum.</td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
      <nxt-declarations-of-consent-section titleText="Wichtige Hinweise zur Haltbarkeit" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Der Zahnschmuck kann mehrere Monate bis Jahre halten – das hängt von der Pflege und individuellen Zahnbeschaffenheit ab.</td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Sollte sich der Schmuck lockern oder abfallen, keinesfalls selbst versuchen, Klebereste zu entfernen – dies kann den Zahnschmelz beschädigen. Konsultiere in diesem Fall einen
                Zahnarzt oder einen erfahrenen Zahnschmuck-Experten.
              </td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Bei Zahnarztbesuchen (z. B. Zahnreinigung) vorher darauf hinweisen, dass du Zahnschmuck trägst.</td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
      <nxt-declarations-of-consent-section titleText="Wann solltest du uns oder einen Zahnarzt aufsuchen?" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Falls du Schmerzen, Reizungen oder eine allergische Reaktion bemerkst.</td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Falls der Zahnschmuck sich löst oder störend wird.</td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
      <div class="flex flex-row justify-center text-center text-[150%] pt-10" [nxtTranslate]="lang">Falls du noch Fragen hast, melde dich gerne bei uns! 😊<br/>Viel Freude mit deinem neuen Zahnschmuck! ✨</div>
    </div>
  }

  @if (pageCountCurrent === 2) {
    <div>
      <nxt-declarations-of-consent-section titleText="Wann solltest du uns oder einen Zahnarzt aufsuchen?" [lang]="lang">
        <div class="py-3 px-10">
          <table class="w-full info-table">
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Falls du Schmerzen, Reizungen oder eine allergische Reaktion bemerkst.</td>
            </tr>
            <tr>
              <td>・</td>
              <td [nxtTranslate]="lang">Falls der Zahnschmuck sich löst oder störend wird.</td>
            </tr>
          </table>
        </div>
      </nxt-declarations-of-consent-section>
    </div>
  }
</div>
