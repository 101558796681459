<div *ngIf="form && user" class="nxt-page" fxFlex fxLayout="row" fxLayoutAlign="center">

  <div fxFlex="70" class="pt-4" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
      <nxt-input [readonly]="isEdit" fxFlex [nxtFormControl]="form.get('username')" placeholder="Username"></nxt-input>
      <nxt-input fxFlex [nxtFormControl]="form.get('realName')" placeholder="Vollständiger Name"></nxt-input>
      <nxt-slide-toggle [nxtFormControl]="form.get('noPerson')">Keine Person</nxt-slide-toggle>
      <nxt-date-picker [nxtFormControl]="form.get('birthday')" placeholder="Geburtstag"></nxt-date-picker>
    </div>


    <div fxLayout="row" class="w-full" fxLayoutGap="15px">
      <div fxFlex>
        <nxt-input fxFlex [nxtFormControl]="form.get('mobilePrivate')" placeholder="Handy privat"></nxt-input>
      </div>
      <div fxFlex>
        <nxt-input fxFlex [nxtFormControl]="form.get('mobileBusiness')" placeholder="Handy geschäftlich"></nxt-input>
      </div>
      <div fxFlex>
        <nxt-select
          placeholder="Arbeitsplätze"
          [multiple]="true"
          [nxtFormControl]="form.get('workplaces')"
          [options]="[{text: 'Backoffice', value: 'backoffice'}, {text: 'Empfang', value:'reception'}]"
        >
        </nxt-select>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="15px">
      <nxt-slide-toggle class="py-3" [nxtFormControl]="form.get('disabled')">Deaktiviert</nxt-slide-toggle>
      <nxt-slide-toggle class="py-3" [nxtFormControl]="form.get('hidden')">Versteckt</nxt-slide-toggle>
      <nxt-slide-toggle class="py-3" [nxtFormControl]="form.get('disableDailyLogin')">kein tägliches Login nötig
      </nxt-slide-toggle>
      <nxt-slide-toggle class="py-3" [nxtFormControl]="form.get('cashReportCreator')">Erstellt Kassenberichte
      </nxt-slide-toggle>
      <nxt-slide-toggle class="py-3" [nxtFormControl]="form.get('canSafeMoneyGet')">Tresor-Geld empfangen
      </nxt-slide-toggle>

      <nxt-input [nxtFormControl]="form.get('startPage')" placeholder="startPage"></nxt-input>
      <nxt-input nxtPermission="IsJulian" [nxtFormControl]="form.get('photoDeviceName')"
                 placeholder="PhoneDevice"></nxt-input>
    </div>

    <div fxFlex fxLayout="column" class="pl-2">
      <!--<div class="pb-3 text-120">Rechte</div>-->
      <nxt-input
        class="w-1/3"
        [clearIcon]="true"
        placeholder="Suche"
        [(value)]="permissionSearchText"
        (valueChange)="filterPermissions$.next()">
      </nxt-input>

      <div fxFlex style="overflow: auto">
        <ng-container *ngFor="let permissionModule of permissionModules">
          <!--<nxt-smooth-height [trigger]="permissionSearchText">-->
          <div class="pb-3" *ngIf="permissionModule.show">

            <div>{{ permissionModule.text }}</div>
            <ng-container *ngFor="let permission of permissionModule.permissions">
              <div
                *ngIf="permission.show"
                class="p-1"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <nxt-slide-toggle
                  [matTooltip]="(permission.onlySuperAdminCanChange && !currentUserIsSuperAdmin) ? 'Können nur Super-Admins ändern': ''"
                  [disabled]="permission.onlySuperAdminCanChange && !currentUserIsSuperAdmin"
                  [(value)]="permission.granted">
                  <div class="flex flex-row items-center">
                    @if (permission.onlySuperAdminCanChange) {
                      <mat-icon fontSet="material-symbols-outlined">verified_user</mat-icon>&nbsp;
                    }
                    {{ getPermissionIdText(permission.id) }}
                  </div>
                </nxt-slide-toggle>
                <div class="nxt-text-70"
                     *ngIf="getPermissionIdTextDetail(permission.id).length > 0">&nbsp;&nbsp;{{ getPermissionIdTextDetail(permission.id).join(' | ') }}
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="2em" style="padding-top: 1em">
      <nxt-button (click)="close()">ABBRECHEN</nxt-button>
      <nxt-button *ngIf="isEdit" (click)="delete()">BENUTZER LÖSCHEN</nxt-button>
      <nxt-button *ngIf="isEdit" (click)="resetPassword()">Passwort zurücksetzen</nxt-button>
      <nxt-button (click)="save()">SPEICHERN</nxt-button>
    </div>
  </div>
</div>
