<div class="h-100">
  <nxt-slide-toggle nxtPermission="IsJulian"
                    *ngIf="debugProps.length > 0"
                    [(value)]="showDebugProps"
                    (valueChange)="showDebugPropsChanged()">
    Julian
  </nxt-slide-toggle>
  <nxt-datagrid
    class="h-100"
    fxFlex
    uniqueRowDataKey="created"
    [suppressRowTransform]="true"
    [columnDefs]="columnDefs"
    [rowData]="historyData"
    [showSideBar]="false"
    [disableAllSort]="true"
  >
  </nxt-datagrid>
</div>
