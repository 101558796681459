@if (state(); as state) {
  <div class="flex flex-col">
    <div class="flex flex-col items-center justify-center">
      <div class="mb-4 text-xl">Apple iCloud Foto Sync</div>
      <table class="nxt-table-new">
        <tr>
          <td class="text-right">Apple ID</td>
          <td>{{ state.appleId }}</td>
        </tr>
        <tr>
          <td class="text-right">Status</td>
          <td>
            @if (state.state === 'connected') {
              <div class="green">Verbunden</div>
            } @else if (state.state === 'getCode') {
              <div class="red">Code eingeben</div>
            } @else if (state.state === 'getCodeTimeout') {
              <div class="red">Code muss neu angefordert werden</div>
              <nxt-button class="mt-1" [smallButton]="true" (click)="getNewCodeClicked()">Neuen Code jetzt anfordern</nxt-button>
            } @else if (state.state === 'codeChecking') {
              Code wird überprüft
            } @else if (state.state === 'codeRequesting') {
              Neuer Code wird angefordert
            } @else {
              {{ state.state }}
            }
          </td>
        </tr>
        @if (state.loginAt) {
          <tr>
            <td class="text-right">Letzter Check</td>
            <td>{{ state.loginAt | fromNowText2 }}</td>
          </tr>
        }
        @if (state.lastDownloadAt) {
          <tr>
            <td class="text-right">Letzter Download</td>
            <td>{{ state.lastDownloadAt  | fromNowText2 }}</td>
          </tr>
        }
      </table>

      <div class="mt-4 flex flex-col items-center justify-center gap-4">
        @if (state.state === 'getCode') {
          <div class="flex flex-col justify-center text-center">
            Auf dem Foto-Handy erscheint nun ein Dialog,
            <br>
            diesen bitte bestätigen und den angezeigten Code hier eingeben.
          </div>
          <nxt-input placeholder="Code" [(value)]="code"></nxt-input>
          <nxt-button (click)="setCodeClicked()">{{code()}} Bestätigen</nxt-button>
        }
        <nxt-button (click)="closeClicked()">Schließen</nxt-button>
      </div>
    </div>
  </div>
}
