import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtCalendarEventSmall} from '../../../../common-interfaces/nxt.calendar-event.interface';
import {CalendarBodyPutPipe} from '../../../../pipes/calendar-body-put.pipe';
import {MoneyPipe} from '../../../../pipes/money.pipe';
import {NxtDatePipe} from '../../../../pipes/nxt-date-pipe';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {NgStyle} from '@angular/common';
import {ColorTools} from '../../../../common-browser/helpers/color.tools';
import {FromNowText2Pipe} from '../../../../pipes/from-now-2.pipe';
import {Money2Pipe} from '../../../../pipes/money-2.pipe';

@Component({
    selector: 'nxt-tooltip-calendar-event',
    templateUrl: './tooltip-calendar-event.component.html',
    styleUrls: ['./tooltip-calendar-event.component.scss'],
    imports: [NgStyle, ExtendedModule, NxtDatePipe, MoneyPipe, CalendarBodyPutPipe, FromNowText2Pipe, Money2Pipe]
})
export class TooltipCalendarEventComponent extends NxtComponent implements OnInit, NxtOnDestroy, OnChanges {

  @Input() event: NxtCalendarEventSmall;
  @Input() artistColor = '';
  color: string;

  constructor(
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.artistColor) {
      this.color = ColorTools.getLightOrDarkFontColorByBackground(changes.artistColor.currentValue, '#eee', '#222');
    }
  }
}
