import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ShowButtonChooserOptions} from '../../services/dialog.service';
import {KeyCode, ShortcutService} from '../../services/shortcut.service';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {ExtendedModule} from 'ngx-flexible-layout/extended';
import {MatTooltip} from '@angular/material/tooltip';
import {NxtButtonIconComponent} from '../button-icon/nxt-button-icon.component';
import {NgClass, NgFor, NgIf, NgStyle} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-button-chooser',
    templateUrl: './button-chooser.component.html',
    styleUrls: ['./button-chooser.component.scss'],
    imports: [FlexModule, NgIf, NxtButtonIconComponent, MatTooltip, NgFor, NgStyle, ExtendedModule, NgClass, SafeHtmlPipe]
})
export class ButtonChooserComponent implements OnInit, OnDestroy {

  @ViewChild('titleDiv', {static: false}) titleDivElem: ElementRef;
  title: string;
  textField: string;
  buttonRows: ({ text: string, value?: string, color?: string, backgroundColor?: string }[] | string[])[];
  private currentValue: string;
  text: string;
  hideBackButton = false;
  hideCancelButton = false;

  constructor(
    public dialogRef: MatDialogRef<ButtonChooserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShowButtonChooserOptions,
    public shortcutService: ShortcutService
  ) {
    this.buttonRows = data.buttonRows;
    this.title = data.title;
    this.text = data.text;
    this.currentValue = data.value;
    this.hideBackButton = data.hideBackButton;
    this.hideCancelButton = data.hideCancelButton;
  }


  shortcutSubscription: Subscription;

  ngOnInit() {
    this.shortcutSubscription = this.shortcutService.onKeyPress.subscribe((key: KeyCode) => {
      if (key === KeyCode.Esc) {
        // this.cancel();
      }
    });

    this.buttonRows.forEach(row => {
      row.forEach(button => {
        if (button.timeout) {
          (button as any).currentTimeout = button.timeout;
          this.setButtonTimeout(button);
        }
      });
    });

  }

  buttonClicked(button: any) {
    this.dialogRef.close(button);
  }

  cancel() {
    this.dialogRef.close();
  }

  back() {
    this.dialogRef.close('back');
  }

  ngOnDestroy(): void {
    this.shortcutSubscription.unsubscribe();
  }

  private setButtonTimeout(button: any) {
    if (button.currentTimeout > 0) {
      button.currentTimeoutText = (button.currentTimeout / 1000).toString();
      setTimeout(() => {
        button.currentTimeout -= 1000;
        this.setButtonTimeout(button);
      }, 1000);
    } else {
      // raise
      this.buttonClicked(button);
    }
  }
}
