import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {PermissionService} from '../services/permission.service';

@Pipe({
    name: 'permission',
    standalone: true
})
export class PermissionPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer, private permissionService: PermissionService) {
  }

  transform(key: any): boolean {
    return this.permissionService.hasPermission(key);
  }
}

