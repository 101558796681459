import {Injectable} from '@angular/core';
import {SocketService} from '../socket/socket.service';
import {DialogService} from '../dialog.service';
import {LoginService} from '../login.service';

@Injectable({
  providedIn: 'root'
})
export class PaypalRefundService {

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    private loginService: LoginService
  ) {
  }

  async refundShopOrderItem(shopOrderLineItemId: string, refundValue: number, value: number, message: string, refId: string, refType: 'gift-card' | 'paypal'): Promise<boolean> {
    if (await this.dialogService.showYesNo(message, {yesText: refundValue.toMoneyString() + ' erstatten', noText: 'Abbrechen'})) {
      const reason = await this.dialogService.showInput('Grund für die Erstattung');
      if (reason) {
        const result = await this.socketService.paypalRefundShopOrderItem({
          shopOrderLineItemId,
          refundValue,
          refType,
          refId,
          value,
          reason: this.loginService.getUsername() + ':  ' + reason
        });
        if (result.success) {
          this.dialogService.showOk('Erfolgreich zurückgezahlt');
          return true;
        } else {
          this.dialogService.showOk(result.message, {title: 'Fehler beim zurückzahlen'});
          return false;
        }
      }
    }
    return false;
  }
}
