import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {NxtArtist, NxtArtistInternalNote} from '../../../../common-interfaces/nxt.artist.interface';
import {NxtDatePipe} from '../../../../pipes/nxt-date-pipe';
import {SafeHtmlPipe} from '../../../../pipes/safe-html.pipe';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {DialogService} from '../../../../services/dialog.service';
import {UuidTools} from '../../../../common-browser/helpers/uuid.tools';
import {LoginService} from '../../../../services/login.service';
import {IconTools} from '../../../../common-browser/helpers/icon.tools';
import {MatIcon} from '@angular/material/icon';
import {PermissionDirective} from '../../../../directives/permission.directive';

@Component({
    selector: 'nxt-artist-notes',
    templateUrl: './artist-notes.component.html',
    styleUrls: ['./artist-notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NxtDatePipe,
        SafeHtmlPipe,
        NxtButtonComponent,
        MatIcon,
        PermissionDirective
    ]
})

export class ArtistNotesComponent extends NxtComponent implements OnInit, NxtOnDestroy, OnChanges {

  constructor() {
    super();
  }

  /*** Inputs ***/
  @Input({required: true}) artist: NxtArtist;

  /*** Outputs ***/
  @Output() artistChange = new EventEmitter<NxtArtist>();

  /*** Signals ***/

  /*** Injections ***/
  dialogService = inject(DialogService);
  loginService = inject(LoginService);
  cdRef = inject(ChangeDetectorRef);

  protected readonly IconTools = IconTools;

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.artistId) {
      this.load().then();
    }
  }

  private async load() {
  }

  async addNewNoteClicked() {
    const newNote = await this.dialogService.showTextarea({buttonText: 'Notiz anlegen', message: 'Neue Notiz für ' + this.artist.name});
    if (newNote) {
      this.artist.internalNotes.push({
        id: UuidTools.generate(),
        text: newNote,
        createdBy: this.loginService.getUsername(),
        createdAt: Date.now()
      });
      this.artistChange.emit(this.artist);
    }
    this.cdRef.detectChanges();
  }

  async deleteNoteClicked(internalNote: NxtArtistInternalNote) {
    if (await this.dialogService.showYesNo(internalNote.text, {yesText: 'Löschen', noText: 'Zurück'})) {
      this.artist.internalNotes = this.artist.internalNotes.filter(n => n.id !== internalNote.id);
      this.artistChange.emit(this.artist);
      this.cdRef.detectChanges();
    }
  }
}
