<div
  fxLayout="column"
  class="hour-container h-100"
  style="position:relative;" fxLayoutAlign="center"
  [ngClass]="{'hour-counter-visible' : !!hourCounter}"
>

  <div id="textWrapper" fxLayout="column" (click)="buttonClicked(hour)" class="h-100 mouse-pointer" *ngIf="hour.text" fxLayoutAlign="center center">
    <div id="text" fxFlexAlign="center" class="text">{{hour.text}}</div>
  </div>
  <ng-container *ngIf="!hour.text">

    <div *ngIf="!hourCounter || hourCounter.full > 0" fxFlex="100" fxFlexAlign="center">
      <div class="p-relative" fxLayoutAlign="center" [class]="getButtonClass(hour + ':00') + ' hour'" (click)="hourClick(hour + ':00')">
        {{hour + ':00'}}
        <div *ngIf="hourCounter" fxLayout="row" fxLayoutAlign="end" class="w-full">
          <div id="hourCounter" class="hour-counter">{{hourCounter.full | duration: 'H:mm'}}</div>
        </div>
      </div>
    </div>
    <div fxFlex="100%" class="hour-parts" fxLayoutAlign="center center">
      <div *ngIf="!hourCounter || hourCounter.hourCounter15 > 0" class="p-relative hour-part"
           fxLayout="row" fxLayoutAlign="center" fxFlex="33.3%" [class]="getButtonClass(hour + ':15')"
           (click)="hourClick(hour + ':15')"
      >
        <div class="p-relative no-hover">
          {{getButtonText(hour + ':15')}}
          <div *ngIf="hourCounter" class="hour-counter-mini no-hover">{{hourCounter.hourCounter15 | duration: 'H:mm'}}</div>
        </div>
      </div>
      <div *ngIf="!hourCounter ||  hourCounter.hourCounter30 > 0" class="p-relative hour-part" fxLayout="row" fxLayoutAlign="center" fxFlex="33.3%"
           [class]="getButtonClass(hour + ':30')"
           (click)="hourClick(hour + ':30')"
      >
        <div class="p-relative no-hover">
          {{getButtonText(hour + ':30')}}
          <div *ngIf="hourCounter" class="hour-counter-mini no-hover">{{hourCounter.hourCounter30 | duration: 'H:mm'}}</div>
        </div>
      </div>
      <div *ngIf="!hourCounter || hourCounter.hourCounter45 > 0" class="p-relative hour-part" fxLayout="row" fxLayoutAlign="center" fxFlex="33.3%"
           [class]="getButtonClass(hour + ':45')"
           (click)="hourClick(hour + ':45')"
      >
        <div class="p-relative no-hover">
          {{getButtonText(hour + ':45')}}
          <div *ngIf="hourCounter" class="hour-counter-mini no-hover">{{hourCounter.hourCounter45 | duration: 'H:mm'}}</div>
        </div>
      </div>
    </div>
  </ng-container>
</div>


