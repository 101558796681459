import {Component, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../services/socket/socket.service';
import {NxtDynamicDataType, NxtWhatsAppImageSend} from '../../common-interfaces/dynamic-data/dynamic-data.interface';
import {DialogService} from '../../services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';
import {NgFor, NgIf} from '@angular/common';
import {FlexModule} from 'ngx-flexible-layout/flex';

@Component({
    selector: 'nxt-whatsapp-image-send',
    templateUrl: './whatsapp-image-send.component.html',
    styleUrls: ['./whatsapp-image-send.component.scss'],
    imports: [FlexModule, NgIf, NxtButtonIconComponent, NgFor]
})

export class WhatsappImageSendComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  public images: NxtWhatsAppImageSend[];
  public contact: any;
  public mobile: string;


  constructor(
    @Optional() public dialogRef: MatDialogRef<WhatsappImageSendComponent>,
    private socketService: SocketService,
    private dialogService: DialogService
  ) {
    super();
  }

  async ngOnInit() {
    this.load();
  }

  private async load() {
    this.images = await this.socketService.getDynamicData<NxtWhatsAppImageSend>(NxtDynamicDataType.WhatsAppImageSend);
  }

  nxtOnDestroy() {

  }

  public async imageClicked(image: NxtWhatsAppImageSend) {
    const result = await this.dialogService.showYesNoCancel('<div style="width:100%; text-align: center;"><img src="' + image.base64 + '" style="max-height: 50vh"></div>', {
      yesText: 'Bild senden',
      noText: 'Bild löschen',
      cancelText: 'Abbrechen'
    });
    if (typeof result === 'boolean') {
      if (result) {
        await this.send(image);
        this.dialogRef?.close(this.images);
      } else {
        await this.socketService.deleteDynamicData(image.id);
        this.load();
      }
    }
  }

  private async send(image: NxtWhatsAppImageSend) {
    let mobile = '';
    if (this.contact?.mobileFormatted) {
      mobile = this.contact?.mobileFormatted;
    } else if (this.mobile) {
      mobile = this.mobile;
    }
    if (mobile) {
      this.socketService.forwardWhatsAppImageToMobile(image.whatsAppMessageId, mobile, true);
      return true;
    } else {
      await this.dialogService.showOk('Handynummer nicht gefunden');
    }
  }
}
