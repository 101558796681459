import {UntypedFormControl, ValidationErrors} from '@angular/forms';
import {Log} from '../common-browser/log/log.tools';
import {PasswordTools} from '../common-browser/helpers/password.tools';
import {MobileTools} from '../common-browser/helpers/mobile.tools';
import {isValidIBAN} from 'ibantools';

export class ValidatorTools {
  public static errorTexts = {
    validCurrency: () => 'ungültiger Wert',
    maxWordCount: (params) => {
      return 'Maximal ' + params.maxWordLength + ' Wörter';
    },
    trim: () => 'Am Anfang und ende keine Leerzeichen',
    required: () => 'wird benötigt',
    alreadyExists: () => 'existiert bereits',
    mobile: () => 'keine gültige Telefonnummer',
    mobileFullCheck: (text) => {
      return text;
    },
    password: (params) => params.text,
    exactCharLength: (params) => {
      if (params.charLength === 1) {
        return 'Genau Ein Buchstab';
      }
      return 'Genau ' + params.charLength + ' Zeichen';
    },
    onlyNumbers: () => 'Nur Zahlen',
    onlyAZ: () => 'Nur Buchstaben von A-Z',
    uppercase: () => 'Nur Großbuchstaben',
    custom: (params) => params.text,
    germanChars: () => 'nur deutsche Zeichen',
    artistName: () => 'keine komischen Zeichen bitte',
    money: () => 'Ungültiger Betrag',
    iban: () => 'ungültige IBAN',
  };


  public static validCurrency(fc: UntypedFormControl): ValidationErrors | null {
    try {
      if (!fc.value || fc.value.toString().match(/^[0-9^]*$/g)) {
        return null;
      } else {
        return ({validCurrency: true});
      }
    } catch (er) {
      Log.error('validCurrency fehlgeschlagen', fc.value);
      return ({validCurrency: true});
    }
  }

  public static requiredAndNotNaN(control: UntypedFormControl): ValidationErrors | null {
    const value = control.value;
    if (Array.isArray(value)) {
      return value.length > 0 ? null : {required: true};
    }
    if (value === null || value === undefined || value === '' || (typeof value === 'number' && isNaN(value))) {
      return {required: true};
    }
    return null;
  }

  public static mobile(fc: UntypedFormControl): ValidationErrors | null {
    try {
      if (fc.value === '' || fc.value === null || MobileTools.isValidPhoneNumberNxt(fc.value)) {
        return null;
      } else {
        return ({mobile: true});
      }
    } catch (er) {
      return ({mobile: true});
    }
  }

  public static mobileFullCheck(fc: UntypedFormControl): ValidationErrors | null {
    try {
      if (fc.value === '' || fc.value === null) {
        return null;
      }
      const phoneNumber = MobileTools.parse(fc.value);
      const validatePhoneNumberLength = MobileTools.validatePhoneNumberLength(fc.value);
      const isValidPhoneNumber = MobileTools.isValidPhoneNumber(fc.value);
      const isPossiblePhoneNumber = MobileTools.isPossiblePhoneNumber(fc.value);
      if (!validatePhoneNumberLength && isValidPhoneNumber && isPossiblePhoneNumber) {
        return null;
      }
      const lengthResult = MobileTools.validatePhoneNumberLength(fc.value);
      if (lengthResult) {
        if (lengthResult === 'TOO_LONG') {
          return ({mobileFullCheck: 'Zu lang'});
        } else if (lengthResult === 'TOO_SHORT') {
          return ({mobileFullCheck: 'zu kurz'});
        } else if (lengthResult === 'INVALID_COUNTRY') {
          return ({mobileFullCheck: 'Ungültige Ländervorwahl'});
        } else {
          return ({mobileFullCheck: lengthResult});
        }
      } else {
        debugger;
      }
    } catch (err) {
      return ({text: 'Keine Telefonnummer :( (' + err.message + ')'});
    }
  }

  public static password(fc: UntypedFormControl): ValidationErrors | null {
    try {
      const passwordStrength = PasswordTools.getPasswordStrength(fc.value);
      if (passwordStrength.ok) {
        return null;
      } else {
        return {password: {text: passwordStrength.oneLine}};
      }
    } catch (er) {
      Log.error('validCurrency fehlgeschlagen', fc.value);
      return ({validCurrency: true});
    }
  }

  public static validCustomer(fc: UntypedFormControl): ValidationErrors | null {
    if (fc.value && fc.value.id) {
      return null;
    } else {
      return ({noCustomer: true});
    }
  }

  public static onlyNumbers(fc: UntypedFormControl): ValidationErrors | null {
    if (fc.value && /^\d+$/.test(fc.value)) {
      return null;
    } else {
      return ({onlyNumbers: true});
    }
  }

  public static onlyAZ(fc: UntypedFormControl): ValidationErrors | null {
    if (fc.value && /^[A-Z,a-z]+$/.test(fc.value)) {
      return null;
    } else {
      return ({onlyAZ: true});
    }
  }

  public static iban(fc: UntypedFormControl): ValidationErrors | null {
    if (fc.value && isValidIBAN(fc.value)) {
      return null;
    } else {
      return ({iban: true});
    }
  }

  public static uppercase(fc: UntypedFormControl): ValidationErrors | null {
    if (fc.value && /^[A-Z0-9ÄÜÖ]+$/.test(fc.value)) {
      return null;
    } else {
      return ({uppercase: true});
    }
  }

  public static StudioCashReportArtist(fc: UntypedFormControl): ValidationErrors | null {
    if (fc.value && fc.value.id) {
      return null;
    } else {
      return ({noArtist: true});
    }
  }

  public static hasProperty(prop: string) {
    return (fc: UntypedFormControl): ValidationErrors | null => {
      if (fc.value && fc.value[prop]) {
        return null;
      } else {
        const result: any = {};
        result['missingProperty_' + prop] = true;
        return result;
      }
    };
  }

  public static maxWords(maxWordLength: number) {
    return (fc: UntypedFormControl): ValidationErrors | null => {
      if (typeof fc.value === 'string' && fc.value.split(' ').length <= maxWordLength) {
        return null;
      } else {
        const result: any = {};
        result.maxWordCount = {maxWordLength};
        return result;
      }
    };
  }

  public static exactCharLength(charLength: number) {
    return (fc: UntypedFormControl): ValidationErrors | null => {
      if (typeof fc.value === 'string' && fc.value.length === charLength) {
        return null;
      } else {
        const result: any = {};
        result.exactCharLength = {charLength};
        return result;
      }
    };
  }

  public static trim(fc: UntypedFormControl): ValidationErrors | null {
    if (fc.value && fc.value.length > 0 && (fc.value.startsWith(' ') || fc.value.endsWith(' '))) {
      return ({trim: true});
    } else {
      return null;
    }
  }

  public static custom(checkFn: (value: any) => string) {
    return (fc: UntypedFormControl): ValidationErrors | null => {
      const text = checkFn(fc.value);
      if (text) {
        return {custom: {text}};
      }
      return null;
    };
  }

  public static germanChars(fc: UntypedFormControl) {
    if (fc.value && /^[a-zäüöA-Z0-9ÄÜÖ\s\-\(\)]+$/.test(fc.value)) {
      return null;
    } else {
      return ({germanChars: true});
    }
  }

  public static artistName(fc: UntypedFormControl) {
    if (fc.value && /^[a-zäüöA-Z0-9ÄÜÖ\s\-\(\)]+$/.test(fc.value)) {
      return null;
    } else {
      return ({artistName: true});
    }
  }

  public static money(fc: UntypedFormControl) {
    if (!Number.isNaN(fc.value)) {
      return null;
    } else {
      return ({money: true});
    }
  }
}
