import {Directive, ElementRef, input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subscription} from 'rxjs';
import {toObservable} from '@angular/core/rxjs-interop';


@Directive({
  selector: '[nxtDynamicSize]',
  standalone: true,
})
export class DynamicSizeDirective implements OnInit, OnDestroy {

  nxtDynamicSize = input<boolean>();
  nxtDynamicSizeMode = input<'width' | 'height'>('height');
  private subscription: Subscription;

  private style = '';

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
  ) {
    this.subscription = toObservable(this.nxtDynamicSize).subscribe((value) => {
      this.renderer.setStyle(this.element.nativeElement, this.style, value ? '1fr' : '0fr');
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async ngOnInit() {
    this.renderer.setStyle(this.element.nativeElement, 'display', 'grid');
    if (this.nxtDynamicSizeMode() === 'width') {
      this.style = 'grid-template-columns';
      this.renderer.setStyle(this.element.nativeElement, 'min-width', 'fit-content');
    } else if (this.nxtDynamicSizeMode() === 'height') {
      this.style = 'grid-template-rows';
      this.renderer.setStyle(this.element.nativeElement, 'min-height', 'fit-content');
    }
    this.renderer.setStyle(this.element.nativeElement, this.style, this.nxtDynamicSize() ? '1fr' : '0fr');
    this.renderer.setStyle(this.element.nativeElement, 'transition', this.style + ' 0.1s ease-in');
  }
}
