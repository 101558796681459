import {Component, OnInit, Optional} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {SocketService} from '../../../services/socket/socket.service';
import {NxtColDef} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-col-def';
import {NxtPromoUser} from '../../../common-interfaces/nxt.promo.interface';
import {IconTools} from '../../../common-browser/helpers/icon.tools';
import {SortTools} from '../../../common-browser/helpers/sort.tools';
import {DialogService} from '../../../services/dialog.service';
import {PromoUserEditComponent} from './promo-user-edit/promo-user-edit.component';
import {firstValueFrom} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {ConfigService} from '../../../services/config.service';
import {NxtFieldType} from '../../../common-interfaces/nxt-field.interface';
import {NxtDatagridComponent} from '../../../controls/nxt-datagrid/nxt-datagrid/nxt-datagrid.component';
import {NxtButtonIconComponent} from '../../../controls/button-icon/nxt-button-icon.component';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {SelectComponent} from '../../form-controls/select/select.component';
import {InputComponent} from '../../form-controls/input/input.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {PermissionDirective} from '../../../directives/permission.directive';

@Component({
    selector: 'nxt-promo-users',
    templateUrl: './promo-users.component.html',
    styleUrls: ['./promo-users.component.scss'],
    imports: [FlexModule, InputComponent, SelectComponent, NxtButtonComponent, NxtButtonIconComponent, NxtDatagridComponent, PermissionDirective]
})

export class PromoUsersComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  columnDefs: NxtColDef[] = [
    {
      headerName: '',
      field: '',
      cellRenderer: () => IconTools.Material.Edit,
      cellStyle: {textAlign: 'center'},
      nxtOnCellClicked: (params) => this.editUser(params.data)
    },
    {headerName: '', field: '', cellRenderer: () => IconTools.Material.History, cellStyle: {textAlign: 'center'}},
    {headerName: 'Name', field: 'name', nxtFieldType: NxtFieldType.Text},
    {headerName: 'NXT-Promoter', field: 'isNxtPromoter', nxtFieldType: NxtFieldType.Boolean},
    {
      headerName: 'Dabei seit',
      field: 'createdAt',
      nxtFieldType: NxtFieldType.Date_germanDate,
      cellStyle: {textAlign: 'right'},
      headerClass: 'text-right'
    },
    {
      headerName: 'Deaktiviert',
      field: 'disabled',
      nxtFieldType: NxtFieldType.Boolean
    },


    {headerName: 'Id', field: 'promoterId', nxtFieldType: NxtFieldType.Text},
    {headerName: 'E-Mail', field: 'email', nxtFieldType: NxtFieldType.Text},
    {
      headerClass: 'text-right',
      headerName: '%', field: 'promoterPercentage', valueGetter: (params) => {
        if (params.data.promoterPercentages && params.data.promoterPercentages.length > 0) {
          return params.data.promoterPercentages.sort(SortTools.sortString('startDateString', true))[0].percentage + ' %';
        }
        return params.data.promoterPercentage + ' %';
      },
      nxtFieldType: NxtFieldType.Text,
      cellStyle: {textAlign: 'right'}
    },

  ];
  promoters: NxtPromoUser[] = [];
  promotersFiltered: NxtPromoUser[] = [];
  quickFilterText = '';
  filterState: 'all' | 'deactivated' | 'activated' = 'all';

  constructor(
    private socketService: SocketService,
    private dialogService: DialogService,
    @Optional() private dialogRef: MatDialogRef<PromoUsersComponent>,
    private configService: ConfigService
  ) {
    super();
  }

  async ngOnInit() {
    this.load();
  }

  nxtOnDestroy() {

  }

  private async editUser(user: NxtPromoUser) {
    const dialog = this.dialogService.showComponentDialog(PromoUserEditComponent, {user});
    if (await firstValueFrom(dialog.afterClosed())) {
      this.load();
    }
  }

  private async load() {
    this.promoters = await this.socketService.getPromoUsers();
    this.setFilters();
  }

  closeClicked() {
    this.dialogRef.close();
  }

  filterStateChanged() {
    this.setFilters();
  }

  private setFilters() {
    this.promotersFiltered = this.promoters.filter(p => {
      if (this.filterState === 'activated') {
        if (p.disabled) {
          return false;
        }
      }
      if (this.filterState === 'deactivated') {
        if (!p.disabled) {
          return false;
        }
      }
      return true;
    });
  }

  async newDummy() {
    const user: NxtPromoUser = {
      id: '',
      promoterId: '',
      disabled: false,
      createdAt: Date.now(),
      email: '',
      studio: this.configService.config.value.studioRegion,
      isNxtPromoter: false,
      name: '',
      photoURL: '',
      promoterPercentage: 0,
      team: '',
      domain: '',
      mobile: '',
      permissions: [],
      infoInternal: '',
      promoterPercentages: [],
      isDummy: true
    };
    const dialog = this.dialogService.showComponentDialog(PromoUserEditComponent, {user});
    if (await firstValueFrom(dialog.afterClosed())) {
      this.load();
    }
  }
}
