import {Directive, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IntervalTools} from '../common-browser/helpers/interval.tools';

@Directive({
    selector: '[nxtAnimate]',
    standalone: true
})
export class AnimateDirective implements OnInit, OnChanges {

  @Input() nxtAnimate: string;
  @Input() nxtInterval: number;
  @Input() nxtAnimateOnClick: boolean;
  private currentAnimation = '';
  private interval: any;

  constructor(private element: ElementRef) {
  }

  ngOnInit(): void {
    if (!this.nxtAnimateOnClick) {
      this.check();
    }
  }

  @HostListener('mouseup', ['$event'])
  public onMouseUp(event: MouseEvent): void {
    if (this.nxtAnimateOnClick) {
      this.check();
    }
  }

  check() {
    if (this.nxtAnimate) {
      this.element.nativeElement.classList.remove('animate__animated');
      this.element.nativeElement.classList.remove('animate__' + this.currentAnimation);
      requestAnimationFrame(() => {
        this.currentAnimation = this.nxtAnimate;
        this.element.nativeElement.classList.add('animate__animated');
        this.element.nativeElement.classList.add('animate__' + this.currentAnimation);
        IntervalTools.clear(this.interval);
        if (this.nxtInterval) {
          this.interval = setInterval(async () => {
            this.element.nativeElement.classList.remove('animate__animated');
            this.element.nativeElement.classList.remove('animate__' + this.currentAnimation);
            requestAnimationFrame(() => {
              this.element.nativeElement.classList.add('animate__animated');
              this.element.nativeElement.classList.add('animate__' + this.currentAnimation);
            });
          }, this.nxtInterval);
        }
      });
    } else {
      IntervalTools.clear(this.interval);
      this.element.nativeElement.classList.remove('animate__animated');
      this.element.nativeElement.classList.remove('animate__' + this.currentAnimation);
    }
  }


  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.nxtAnimate) {
      if (!this.nxtAnimateOnClick) {
        this.check();
      }
    }
  }
}
