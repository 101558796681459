import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {FlexModule} from 'ngx-flexible-layout/flex';


@Component({
    selector: 'nxt-context-menu',
    templateUrl: './context-menu.component.html',
    styleUrls: ['./context-menu.component.scss'],
  imports: [MatMenuTrigger, MatMenu, FlexModule]
})

export class ContextMenuComponent extends NxtComponent implements OnInit, NxtOnDestroy {
  @ViewChild('menuTriggerWrapper') artistGetMenuTriggerWrapper: ElementRef;
  @ViewChild('menuTrigger') artistGetMenuTrigger: MatMenuTrigger;

  constructor() {
    super();
  }

  @HostListener('document:click')
  clickedOut() {
    this.artistGetMenuTrigger.closeMenu();
  }

  ngOnInit() {
  }

  nxtOnDestroy() {
  }

  public show(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.artistGetMenuTriggerWrapper.nativeElement.style.left = event.x + 'px';
    this.artistGetMenuTriggerWrapper.nativeElement.style.top = event.y + 'px';
    this.artistGetMenuTrigger.closeMenu();
    setTimeout(() => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            this.artistGetMenuTrigger.openMenu();
            requestAnimationFrame(() => {
              this.artistGetMenuTriggerWrapper.nativeElement.style.left = '0px';
              this.artistGetMenuTriggerWrapper.nativeElement.style.top = '0px';
            });
          });
        });
      });
    }, 100);
  }

  stopPropagation(ev: MouseEvent) {
    ev.stopPropagation();
    ev.preventDefault();
  }
}
