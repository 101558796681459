import {AfterViewInit, Component, OnInit, Optional, ViewChild} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {InputComponent} from '../../../form-controls/input/input.component';
import {MatDialogRef} from '@angular/material/dialog';
import {MathTools} from '../../../../common-browser/helpers/math.tools';
import {KeyCode, ShortcutService} from '../../../../services/shortcut.service';
import {NxtButtonComponent} from '../../../../controls/button/nxt-button.component';
import {TextareaComponent} from '../../../form-controls/textarea/textarea.component';
import {RadioComponent} from '../../../form-controls/radio/radio.component';
import {SlideToggleComponent} from '../../../form-controls/slide-toggle/slide-toggle.component';
import {NgIf} from '@angular/common';
import {NxtButtonIconComponent} from '../../../../controls/button-icon/nxt-button-icon.component';

@Component({
    selector: 'nxt-whatsapp-price-dialog',
    templateUrl: './whatsapp-price-dialog.component.html',
    styleUrls: ['./whatsapp-price-dialog.component.scss'],
    imports: [InputComponent, NxtButtonIconComponent, NgIf, SlideToggleComponent, RadioComponent, TextareaComponent, NxtButtonComponent]
})

export class WhatsappPriceDialogComponent extends NxtComponent implements OnInit, NxtOnDestroy, AfterViewInit {
  aboutPrice = false;

  @ViewChild('priceInput') set priceInput(value: InputComponent) {
    if (value) {
      requestAnimationFrame(() => {
        value.click();
      });
    }
  }

  constructor(
    @Optional() private dialogRef: MatDialogRef<any>,
    private shortcutService: ShortcutService
  ) {
    super();

    this.pushSubscription = this.shortcutService.onKeyPressExclusive.subscribe([KeyCode.F7, KeyCode.F8], (key) => {
      if (key === KeyCode.F8) {
        const currentIndex = this.percentageOptions.findIndex(p => p.value === this.percentage);
        if (this.percentageOptions.length > currentIndex + 1) {
          this.percentage = this.percentageOptions[currentIndex + 1].value;
        } else {
          this.percentage = this.percentageOptions[0].value;
        }
        this.percentageChanged();
      }
      if (key === KeyCode.F7) {
        this.aboutPrice = !this.aboutPrice;
        this.generateText();
      }
    });
  }

  @ViewChild('originalPreisInput') originalPreisInput: InputComponent;

  price = 0;
  originalPrice: number;
  text = '';

  texte: string[] = [
    'Da würden wir bei einem Normalpreis von {{originalPrice}} landen. Ich würde dir heute aber einen Angebots-Preis von {{price}} 🏷️ anbieten.\n' +
    '\n' +
    'Passt das? 😊',

    'In der Regel kostet das 💰{{originalPrice}}, aber ich kann es dir heute für 🎉{{price}} als Sonderangebot überlassen.\n' +
    '\n' +
    'Ist das in Ordnung für dich? 🙂',

    'Der Standardpreis liegt normalerweise bei {{originalPrice}}, aber ich kann dir ein spezielles Angebot von {{price}} unterbreiten.\n' +
    '\n' +
    'Wie klingt das für dich? 😊',

    'Normalerweise kostet es {{originalPrice}}, aber für dich habe ich heute ein Sonderangebot: nur {{price}}.\n\nIst das für dich in Ordnung? 😊',

    'Unser üblicher Verkaufspreis wäre {{originalPrice}}. Heute möchte ich dir jedoch einen Vorzugspreis von 🔥{{price}} anbieten.\n' +
    '\n' +
    'Ist das cool?',

    'Die reguläre Preisgestaltung sieht {{originalPrice}} vor, aber ich bin bereit, dir heute einen exklusiven Preisnachlass zu gewähren 😊, und dir nur 💰 {{price}} berechnen.\n' +
    '\n' +
    'Passt das?',

    'Der reguläre Preis beträgt {{originalPrice}}. Allerdings kann ich dir heute ein Sonderangebot von {{price}}🎉 anbieten.\n' +
    '\n' +
    'Wie klingt das für dich?'
  ];
  percentageOptions = [
    {text: 'auto', value: 'auto'},
    {text: '28%', value: 25},
    {text: '20%', value: 20},
    {text: '15%', value: 15},
    {text: '10%', value: 10},
  ];
  percentage: string | number = 'auto';

  realPercentage = 0;

  ngAfterViewInit(): void {

  }

  ngOnInit() {

  }

  nxtOnDestroy() {

  }

  priceChanged() {
    this.calcOriginalPrice();
  }

  originalPriceChanged() {
    this.calcRealPercentage();
    this.generateText();
  }

  closeClicked() {
    this.dialogRef.close();
  }

  okClicked() {
    this.dialogRef.close(this.text);
  }

  private calcOriginalPrice() {
    let originalPriceToSet = this.originalPrice;
    if (this.price) {
      if (this.percentage === 'auto') {
        originalPriceToSet = this.getOriginalPriceDiff(this.price) + this.price;
        originalPriceToSet -= originalPriceToSet % 10;
      } else if (typeof this.percentage === 'number') {
        originalPriceToSet = this.price / (1 - (this.percentage / 100));
      }
    }
    if (originalPriceToSet !== this.originalPrice) {
      this.originalPrice = originalPriceToSet;
    }
    this.calcRealPercentage();
    this.generateText();
  }

  private getOriginalPriceDiff(price: number) {
    if (price < 20) {
      return 5;
    } else if (price < 30) {
      return 10;
    } else if (price < 60) {
      return 20;
    } else if (price < 100) {
      return 30;
    } else if (price < 200) {
      return 40;
    } else if (price < 400) {
      return 50;
    } else if (price < 600) {
      return 90;
    } else if (price < 700) {
      return 100;
    } else if (price < 800) {
      return 130;
    } else if (price < 1000) {
      return 180;
    } else if (price < 1500) {
      return 250;
    } else {
      return price * 0.2;
    }
  }

  priceOnEnter() {
    this.generateText();
    this.originalPreisInput.click();
  }

  originalPriceOnEnter() {
    this.generateText();
    this.okClicked();
  }

  generateText() {
    if (this.originalPrice && this.price) {
      let originalPriceText = this.originalPrice.toMoneyString('€', false);
      let priceText = this.price.toMoneyString('€', false);
      if (this.aboutPrice) {
        originalPriceText = 'ca. ' + originalPriceText;
        priceText = 'ca. ' + priceText;
      }
      this.text = MathTools.getRandomFromArray(this.texte)
        .replaceAll('{{originalPrice}}', originalPriceText)
        .replaceAll('{{price}}', priceText);
    }
  }

  percentageChanged() {
    this.calcOriginalPrice();
    requestAnimationFrame(() => {
      this.originalPreisInput.click();
      this.originalPreisInput.select();
    });
  }

  roundUpClicked() {
    this.originalPrice = this.originalPrice + (10 - this.originalPrice % 10);
    this.calcRealPercentage();
    requestAnimationFrame(() => {
      this.originalPreisInput.select();
    });
  }

  roundDownClicked() {
    if (this.originalPrice % 10 === 0) {
      this.originalPrice -= 10;
    } else {
      this.originalPrice = this.originalPrice - (this.originalPrice % 10);
    }
    if (this.originalPrice < 0) {
      this.originalPrice = 0;
    }
    this.calcRealPercentage();
    requestAnimationFrame(() => {
      this.originalPreisInput.select();
    });
  }

  private calcRealPercentage() {
    if (this.price > 0) {
      this.realPercentage = Math.round(100 - (this.price / this.originalPrice * 100));
    } else {
      this.realPercentage = -1;
    }
  }

  aboutPriceChanged() {
    this.generateText();
  }
}
