import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {NxtComponent, NxtOnDestroy} from 'src/app/components/nxt.component';
import {ActivatedRoute} from '@angular/router';
import {SpinnerComponent} from '../../spinner/spinner.component';
import {NxtEventQrCodePreData} from '../../../common-interfaces/event-qr-code-pre-data.interface';
import {firstValueFrom} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {FlexModule} from 'ngx-flexible-layout';
import {NxtButtonComponent} from '../../../controls/button/nxt-button.component';
import {SafeHtmlPipe} from '../../../pipes/safe-html.pipe';
import {DialogService} from '../../../services/dialog.service';


@Component({
    selector: 'nxt-event-qr-2',
    templateUrl: './event-qr-2.component.html',
    styleUrls: ['./event-qr-2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SpinnerComponent,
    FlexModule,
    NxtButtonComponent,
    SafeHtmlPipe,
  ]
})

export class EventQr2Component extends NxtComponent implements OnInit, NxtOnDestroy {
  /*** Inputs ***/

  /*** Outputs ***/

  /*** Signals ***/
  view = signal<'loading' | 'initDone' | 'showButtons' | 'error' | 'finished' | 'canceled'>('loading');
  errorText = signal('');
  eventQrCodePreData = signal<NxtEventQrCodePreData | null>(null);
  type = signal<'tattoo' | 'stencil' | 'preTattoo' | ''>('');

  /*** Injections ***/
  private cdRef = inject(ChangeDetectorRef);
  private activatedRoute = inject(ActivatedRoute);
  private httpClient = inject(HttpClient);
  private dialogService = inject(DialogService);


  model = signal('');
  private secret = signal('');
  private eventId = '';

  constructor() {
    super();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      try {
        if (params.eventId) {
          this.startEvent(params.eventId).then();
        } else if (params.model && params.secret) {
          this.init(params.model, params.secret);
        }
      } catch (err) {
        this.dialogService.showOk(err.message);
      }
    });
  }

  nxtOnDestroy() {
  }

  private async startEvent(eventId: any) {
    try {
      this.eventId = eventId;
      this.model.set(localStorage.getItem('eventQrModel'));
      this.secret.set(localStorage.getItem('eventQrSecret'));
      if (!this.model() || !this.secret()) {
        window.location.href = 'https://p.nxtlvl.ink/w/' + this.getStudioFromHost() + '/' + eventId;
      } else {
        this.eventQrCodePreData.set(await this.getEventQrCodePreData());
        if (this.eventQrCodePreData().canceled) {
          this.view.set('canceled');
        } else {
          this.view.set('showButtons');
        }
      }
    } catch (err) {
      if (err.status === 401) {
        this.errorText.set('Login ungültig\nBitte in Aachen bei Niklas melden');
      } else {
        this.errorText.set(err.message);
      }
      this.view.set('error');
    }
  }

  private init(model: any, secret: any) {
    this.model.set(model);
    this.secret.set(secret);
    localStorage.setItem('eventQrModel', model);
    localStorage.setItem('eventQrSecret', secret);
    this.view.set('initDone');
  }


  private async getEventQrCodePreData(): Promise<NxtEventQrCodePreData> {
    let url = window.location.origin + '/getEventQr';
    if (window.location.origin.includes('localhost')) {
      url = 'http://localhost:444/getEventQr';
    }
    return firstValueFrom(this.httpClient.post<NxtEventQrCodePreData>(url, {eventId: this.eventId, model: this.model(), secret: this.secret()}));
  }

  private async setEventQrCodePreData(): Promise<NxtEventQrCodePreData> {
    let url = window.location.origin + '/setEventQr2';
    if (window.location.origin.includes('localhost')) {
      url = 'http://localhost:444/setEventQr2';
    }
    return firstValueFrom(this.httpClient.post<NxtEventQrCodePreData>(url,
      {
        eventId: this.eventId,
        timestamp: Date.now(),
        type: this.type(),
        model: this.model(),
        secret: this.secret(),
      }));
  }

  private getStudioFromHost() {
    let host = window.location.host;
    if (host.includes('localhost')) {
      host = 'ac.nxt-lvl.ink';
    }
    return host.split('.')[0];
  }

  async typeClicked(type: 'tattoo' | 'stencil' | 'preTattoo') {
    this.type.set(type);
    await this.setEventQrCodePreData();
    this.view.set('finished');
  }
}
