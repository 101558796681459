// import {Subscription} from 'rxjs';

export class UnsubscribeTools {


    public static unsubscribe(subscription: any | undefined) {
        if (subscription && typeof subscription.unsubscribe === 'function') {
            subscription.unsubscribe();
        }
    }
}
