import {AppComponent} from './app/app.component';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {CalendarModule, DateAdapter as CalendarDateAdapter} from 'angular-calendar';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {MatStepperModule} from '@angular/material/stepper';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FlexLayoutModule} from 'ngx-flexible-layout';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {provideAnimations} from '@angular/platform-browser/animations';
import {environment} from './environments/environment';
import {SocketIoModule} from 'ngx-socket-io';
import {getRoutes} from './app/app-routing.module';
import {provideRouter, UrlSerializer} from '@angular/router';

import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {NxtDateFormatter} from './app/nxt-date-formatter';
import {GlobalErrorHandler} from './app/global-error-handler';
import {ErrorHandler, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {AngularFireModule, FIREBASE_OPTIONS} from '@angular/fire/compat';
import {ConfigService} from './app/services/config.service';
import $ from 'jquery';
import {registerLocaleData} from '@angular/common';
import moment from 'moment';
import {PdfTranslateTools} from './app/common-browser/helpers/pdf-translate.tools';
import localeDe from '@angular/common/locales/de';
import 'moment/locale/de';
import localeEn from '@angular/common/locales/en';
import {WindowTools} from './app/common-browser/window.tools';
import {CustomUrlSerializer} from './app/custom-url-serializer';
import {NanoIdTools} from './app/common-browser/helpers/nano-id.tools';
import {ModuleRegistry} from 'ag-grid-community';
import {AllEnterpriseModule, IntegratedChartsModule} from 'ag-grid-enterprise';

(window as any).$ = $;
registerLocaleData(localeDe);
registerLocaleData(localeEn);
moment.locale('de');
let devSocketUrl = '';
PdfTranslateTools.init();
switch (environment.backend.toLowerCase()) {
  case 'aschaffenburg':
    devSocketUrl = 'https://ab.nxt-lvl.ink';
    break;
  case 'frankfurt':
    devSocketUrl = 'https://ffm.nxt-lvl.ink';
    break;
  case 'aachen':
    devSocketUrl = 'https://ac.nxt-lvl.ink';
    break;
  case 'nuernberg':
    devSocketUrl = 'https://nu.nxt-lvl.ink';
    break;
  case 'mallorca':
    devSocketUrl = 'https://ma.nxt-lvl.ink';
    break;
  case 'duesseldorf':
    devSocketUrl = 'https://du.nxt-lvl.ink';
    break;
  case 'brudis':
    devSocketUrl = 'https://stock.brudis.es';
    break;
  case 'local':
    devSocketUrl = 'http://localhost:83';
    break;
  case 'staging':
    devSocketUrl = 'https://staging.nxt-lvl.ink';
    break;
  case 'dortmund':
    devSocketUrl = 'https://do.nxt-lvl.ink';
    break;
}
const socketIoConfig = {url: environment.production ? window.location.origin : devSocketUrl, options: {}};

function initializeApp(configService: ConfigService) {
  return configService?.config?.value?.fireConfig;
}

const MY_FORMATS = {
  parse: {
    dateInput: 'ddd DD.MM.YYYY',
  },
  display: {
    dateInput: 'ddd DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

function getNgConfigUrl() {
  let ngConfigUrl = window.location.origin + '/ng-config-new';

  if (environment.backend === 'local') {
    ngConfigUrl = 'http://localhost:82/api/ng-config-new';
  } else if (environment.backend === 'frankfurt') {
    ngConfigUrl = 'https://ffm.nxt-lvl.ink/ng-config-new';
  } else if (environment.backend === 'aachen') {
    ngConfigUrl = 'https://ac.nxt-lvl.ink/ng-config-new';
  } else if (environment.backend === 'aschaffenburg') {
    ngConfigUrl = 'https://ab.nxt-lvl.ink/ng-config-new';
  } else if (environment.backend === 'nuernberg') {
    ngConfigUrl = 'https://nu.nxt-lvl.ink/ng-config-new';
  } else if (environment.backend === 'mallorca') {
    ngConfigUrl = 'https://ma.nxt-lvl.ink/ng-config-new';
  } else if (environment.backend === 'duesseldorf') {
    ngConfigUrl = 'https://du.nxt-lvl.ink/ng-config-new';
  } else if (environment.backend === 'brudis') {
    ngConfigUrl = 'https://stock.brudis.es/ng-config-new';
  } else if (environment.backend === 'staging') {
    ngConfigUrl = 'https://staging.nxt-lvl.ink/ng-config-new';
  } else if (environment.backend === 'dortmund') {
    ngConfigUrl = 'https://do.nxt-lvl.ink/ng-config-new';
  }
  return ngConfigUrl;
}

fetch(getNgConfigUrl()).then(resp => resp.json()).then(async (ngConfig) => {
  (window as any).ngConfig = ngConfig;
  await NanoIdTools.init();
  await bootstrap();
}).catch(err => {
  const div = document.createElement('div');
  div.style.width = '100%';
  div.style.height = '100%';
  div.style.display = 'flex';
  div.style.justifyContent = 'center';
  div.style.alignItems = 'center';
  div.style.color = '#ccc';
  div.innerHTML = ('ngConfig konnte nicht geladen werden\n' + (window as any).ngConfigUrl + '<br/><br/>' + err.message);
  window.document.body.append(div);
  setTimeout(() => {
    WindowTools.reload('ngConfig konnte nicht geladen werden');
  }, 3000);
});


async function bootstrap() {
  ModuleRegistry.registerModules([AllEnterpriseModule, IntegratedChartsModule]);
  return bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        BrowserModule,
        SocketIoModule.forRoot(socketIoConfig),
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule, MatDatepickerModule,
        MatMenuModule, MatButtonModule, MatIconModule, MatCardModule, MatTabsModule, MatSidenavModule,
        MatListModule, MatToolbarModule, MatInputModule, MatCheckboxModule, MatTableModule, MatPaginatorModule,
        MatSortModule, MatProgressSpinnerModule, MatDialogModule, MatMomentDateModule, MatChipsModule,
        MatButtonToggleModule, MatAutocompleteModule, MatGridListModule, FlexLayoutModule, MatTooltipModule,
        MatSnackBarModule, AngularFireAuthModule, AngularFirestoreModule, AngularFireModule,
        MatRadioModule, MatSlideToggleModule, MatNativeDateModule, MatSliderModule, ScrollingModule, MatStepperModule,
        CdkConnectedOverlay, CdkOverlayOrigin, FontAwesomeModule,
        CalendarModule.forRoot({
          provide: CalendarDateAdapter,
          useFactory: adapterFactory,
        })),
      ConfigService,
      {
        provide: FIREBASE_OPTIONS,
        deps: [ConfigService],
        useFactory: initializeApp,
      },
      {
        provide: MAT_DATE_FORMATS,
        useValue: MY_FORMATS,
      },
      {
        provide: ErrorHandler,
        useClass: GlobalErrorHandler,
      },
      {provide: UrlSerializer, useClass: CustomUrlSerializer},
      {provide: LOCALE_ID, useValue: 'de'},
      {provide: MAT_DATE_LOCALE, useValue: 'de'},
      // {provide: DateAdapter, useValue: new NxtDateFormatter('de')},
      {provide: DateAdapter, useClass: NxtDateFormatter},
      // {provide: HTTP_INTERCEPTORS, useClass: DirectInterceptor, multi: true},
      // provideRouter(getRoutes(), withHashLocation()),
      provideRouter(getRoutes()),
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
    ],
  });
}
