<div *ngIf="parsedContacts()" class="flex flex-col">
  <div class="flex flex-row gap-4 justify-center p-1">
    @for (parsedContact of parsedContacts(); track parsedContact) {
      @if (parsedContact.id.startsWith('loading')) {
        <div class="contact-card nxt-card-border px-6 pt-2 pb-4 relative flex flex-col justify-between">
          <div class="w-full flex flex-row justify-center">
            <nxt-spinner [size]="30"></nxt-spinner>
          </div>
          <div [innerHTML]="parsedContact.content | safeHtml"></div>
        </div>
      } @else {
        <div
          [id]="parsedContact.id"
          [class.highlight]="highlightBoxId === parsedContact.id"
          class="contact-card nxt-card-border px-6 pt-2 pb-4 relative flex flex-col justify-between">
          <mat-icon class="absolute right-[2px] top-[2px] cursor-pointer" (click)="deleteParsedContactClicked(parsedContact)">close</mat-icon>
          <div>
            <div class="flex flex-col items-center text-70">
              <div [class]="(now() - parsedContact.createdAt > 1000 * 60 * 4) ? 'red' : 'green'">
                {{ parsedContact.createdAt | fromNowText }}
              </div>
            </div>
            <div class="flex flex-col items-center text-120 pt-1">
              <div
                class="cursor-pointer"
                cdkDrag
                (cdkDragEnded)="dragEnded(parsedContact, $event, 'name')"
                *ngIf="parsedContact.parsedContact.givenName || parsedContact.parsedContact.familyName">
                {{ parsedContact.parsedContact.givenName }} {{ parsedContact.parsedContact.familyName }}
              </div>
              <div
                class="cursor-pointer"
                cdkDrag
                (cdkDragEnded)="dragEnded(parsedContact, $event, 'birthday')"
                *ngIf="parsedContact.parsedContact.birthday">
                {{ parsedContact.parsedContact.birthday | nxtDate: 'dd.MM.yyyy' }}
              </div>
              <div
                class="cursor-pointer text-80"
                cdkDrag
                (cdkDragEnded)="dragEnded(parsedContact, $event, 'mobile')"
                (cdkDragMoved)="dragMoved(parsedContact, $event)"
                *ngIf="parsedContact.parsedContact.mobile">
                {{ parsedContact.parsedContact.mobile }}
              </div>
              <div
                class="cursor-pointer text-80"
                cdkDrag
                (cdkDragEnded)="dragEnded(parsedContact, $event, 'streetAddress')"
                *ngIf="parsedContact.parsedContact.streetAddress">
                {{ parsedContact.parsedContact.streetAddress }}
              </div>
              <div
                class="cursor-pointer text-80"
                cdkDrag
                (cdkDragEnded)="dragEnded(parsedContact, $event, 'postalCode')"
                *ngIf="parsedContact.parsedContact.postalCode">
                {{ parsedContact.parsedContact.postalCode }}
              </div>
              <div
                class="cursor-pointer text-80"
                cdkDrag
                (cdkDragEnded)="dragEnded(parsedContact, $event, 'city')"
                *ngIf="parsedContact.parsedContact.city">
                {{ parsedContact.parsedContact.city }}
              </div>
            </div>
            <div>
              <br/>
            </div>
          </div>
          <div class="flex flex-row justify-center">
            @if (parsedContact.matchingContacts.length === 0) {
              <div>
                <nxt-button (click)="createContactClicked(parsedContact)">
                  <div>ANLEGEN</div>
                </nxt-button>
              </div>
            } @else {
              <div>
                @for (matchingContact of parsedContact.matchingContacts; track matchingContact.id) {
                  <nxt-button (click)="useMatchedContact(matchingContact, parsedContact.id)">
                    <div>{{ matchingContact.givenName }} {{ matchingContact.familyName }}</div>
                    <div>{{ matchingContact.birthday | nxtDate: 'dd.MM.yyyy' }}</div>
                  </nxt-button>
                }
              </div>
            }
          </div>
        </div>
      }
    }
  </div>
  <div class="flex flex-row justify-center w-full pt-3">
    <nxt-button [smallButton]="true" (click)="cancelClicked()">Abbrechen</nxt-button>
  </div>
</div>
