import {Component, model, OnInit, signal} from '@angular/core';
import {NxtFormControl} from '../../nxt-form/nxt.form-control';
import {LoginService} from '../../services/login.service';
import {DialogService, LoadingId} from '../../services/dialog.service';
import {Router, RouterLink} from '@angular/router';
import {DateTools} from '../../common-browser/helpers/date.tools';
import {SocketService} from '../../services/socket/socket.service';
import {ConfigService} from '../../services/config.service';
import {CheckListComponent} from '../../components/check-list/check-list.component';
import {ClipboardService} from '../../services/clipboard.service';
import {MathTools} from '../../common-browser/helpers/math.tools';
import {WhatsappChatComponent} from '../../components/whatsapp/whatsapp-chat/whatsapp-chat.component';
import {SearchComponent} from '../search/search.component';
import {firstValueFrom} from 'rxjs';
import {IframeMessageManagerInIframe} from '../../services/iframe-message-manager-in-iframe';
import {DurationTools} from '../../common-browser/helpers/duration.tools';
import {SmsCodeComponent} from '../../components/sms-code/sms-code.component';
import {PaypalCheckoutService} from '../../services/paypal-checkout.service';
import {KeyCode, ShortcutService} from '../../services/shortcut.service';
import * as QRCode from 'qrcode';
import {HttpClient} from '@angular/common/http';
import {ContactService} from '../../services/contact.service';
import {WhatsappPriceDialogComponent} from '../../components/whatsapp/whatsapp-dialogs/whatsapp-price-dialog/whatsapp-price.dialog.component';
import {WhatsappFastAnswersComponent} from '../../components/whatsapp/whatsapp-fast-answers/whatsapp-fast-answers.component';
import {JsonTools} from '../../common-browser/helpers/json.tools';
import {MoneyCounterWrapper2Component} from '../cash-money-counter-2/money-counter-wrapper2.component';
import {CalendarEventEdit2Component} from '../calendar-event-edit/calendar-event-edit-2/calendar-event-edit-2.component';
import {TimePickerComponent} from '../../components/form-controls/time-picker/time-picker.component';
import {PermissionDirective} from '../../directives/permission.directive';
import {NxtButtonComponent} from '../../controls/button/nxt-button.component';
import {FlexModule} from 'ngx-flexible-layout/flex';
import {NgIf} from '@angular/common';
import {TopBarItem, TopBarService} from '../../services/top-bar.service';
import {ReminderEditComponent} from '../../components/reminder/reminder-edit/reminder-edit.component';
import {PermissionService} from '../../services/permission.service';
import {NxtPermissionId} from '../../common-interfaces/nxt.user.interface';
import {AudioService} from '../../services/audio-service';
import {VideoComponent} from '../../components/video/video.component';
import {FormsModule, UntypedFormBuilder} from '@angular/forms';
import {
  ColDef,
  colorSchemeDark,
  colorSchemeDarkBlue,
  colorSchemeDarkWarm,
  colorSchemeLight,
  colorSchemeLightCold,
  colorSchemeLightWarm,
  colorSchemeVariable,
  iconSetAlpine,
  iconSetMaterial,
  iconSetQuartzBold,
  iconSetQuartzLight,
  iconSetQuartzRegular,
  themeAlpine,
  themeBalham,
  themeQuartz,
} from 'ag-grid-community';
import {InputComponent} from '../../components/form-controls/input/input.component';
import {NxtButtonIconComponent} from '../../controls/button-icon/nxt-button-icon.component';


@Component({
  selector: 'nxt-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  imports: [NgIf, FlexModule, NxtButtonComponent, PermissionDirective, RouterLink, TimePickerComponent, FormsModule, InputComponent, NxtButtonIconComponent],
  standalone: true,
})
export class DashboardComponent implements OnInit {

  constructor(
    public loginService: LoginService,
    private dialogService: DialogService,
    private router: Router,
    public socketService: SocketService,
    public configService: ConfigService,
    private clipboardService: ClipboardService,
    private paypalCheckoutService: PaypalCheckoutService,
    private shortcutService: ShortcutService,
    private httpClient: HttpClient,
    private contactService: ContactService,
    public topBarService: TopBarService,
    private permissionService: PermissionService,
    private audioService: AudioService,
    private fb: UntypedFormBuilder,
  ) {
    this.dynamicGridName = new NxtFormControl();
    this.shortcutService.registerKey(KeyCode.Shift7, {propagate: true});
  }


  dynamicGridName: NxtFormControl;
  showCreateNewGrid = false;
  username: string;

  /*test2() {
    this.appointmentConfirmationService.showEditAppointmentConfirmationText();
  }*/
  showAll = false;
  version = SocketService.NODE_SERVER_VERSION;

  /*public setWeakPassword() {
    this.storageService.set(StorageKey.WeakPassword, true);
  }*/
  public testValue = 100;
  public testFormControl = new NxtFormControl(100);
  public otherStudios = this.configService.config.value.allStudios;
  nxtFormControl = new NxtFormControl();
  multilineText = 'Hallo\ndu\nSchlumpf\nwie\ngehts\ndir\so?';
  testValue1 = null;
  testOptions = [{text: 'abc', value: 'abc'}, {text: 'def', value: 'def'}];
  selectDisabled = true;
  // topBarItemsContent = '';
  topBarItems: TopBarItem[] = [];

  text = '';

  isDisabled = true;
  showImg = false;
  localLastUrls = signal<string[]>([]);

  form = this.fb.group({
    testValue1: new NxtFormControl(500),
    testValue2: new NxtFormControl(500),
  });


  columnDefs: ColDef[] = [
    {field: 'make'},
    {field: 'model'},
    {field: 'price'},
  ];

  defaultColDef: ColDef = {
    editable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
  };

  baseThemes = [
    {id: 'themeQuartz', value: themeQuartz},
    {id: 'themeBalham', value: themeBalham},
    {id: 'themeAlpine', value: themeAlpine},
  ];
  baseTheme = themeQuartz;

  colorSchemes = [
    {id: '(unchanged)', value: null},
    {id: 'colorSchemeLight', value: colorSchemeLight},
    {id: 'colorSchemeLightCold', value: colorSchemeLightCold},
    {id: 'colorSchemeLightWarm', value: colorSchemeLightWarm},
    {id: 'colorSchemeDark', value: colorSchemeDark},
    {id: 'colorSchemeDarkWarm', value: colorSchemeDarkWarm},
    {id: 'colorSchemeDarkBlue', value: colorSchemeDarkBlue},
    {id: 'colorSchemeVariable', value: colorSchemeVariable},
  ];
  colorScheme = null;

  iconSets = [
    {id: '(unchanged)', value: null},
    {id: 'iconSetQuartzLight', value: iconSetQuartzLight},
    {id: 'iconSetQuartzRegular', value: iconSetQuartzRegular},
    {id: 'iconSetQuartzBold', value: iconSetQuartzBold},
    {id: 'iconSetAlpine', value: iconSetAlpine},
    {id: 'iconSetMaterial', value: iconSetMaterial},
  ];
  iconSet = null;

  get theme() {
    let theme = this.baseTheme;
    if (this.iconSet) {
      theme = theme.withPart(this.iconSet);
    }
    if (this.colorScheme) {
      theme = theme.withPart(this.colorScheme);
    }
    return theme;
  }

  rowData: any[] = (() => {
    const rowData: any[] = [];
    for (let i = 0; i < 10; i++) {
      rowData.push({
        make: 'Toyota',
        model: 'Celica',
        price: 35000 + i * 1000,
      });
      rowData.push({make: 'Ford', model: 'Mondeo', price: 32000 + i * 1000});
      rowData.push({
        make: 'Porsche',
        model: 'Boxster',
        price: 72000 + i * 1000,
      });
    }
    return rowData;
  })();
  bankDocumentId = model('');
  bankTransactionId = model('');


  ngOnInit() {

    this.localLastUrls.set(JsonTools.parse(localStorage.getItem('localLastUrls')) || []);

    this.showAll = this.permissionService.hasPermission(NxtPermissionId.DefaultDashboard);
    if (this.socketService.currentUser$.value.photoDeviceName) {
      this.showAll = false;
    }

    const currentUser = this.configService.config.value.users.find(u => u.username === this.loginService.getUsername());
    if (currentUser.startPage) {
      this.router.navigateByUrl(currentUser.startPage);
    }

    this.topBarService.topBarItems.subscribe((items) => {
      this.topBarItems = items;
    });

    // this.test333();

    // this.test();

    /*for(const i = )
      console.log(i);
      await TimeTools.sleep(1000);
    })*/


    // this.loginService.setUserIsActive();
    this.username = this.loginService.getUsername();
  }

  async test() {
    alert('joo');
  }


  newReportClicked() {
  }

  showStudioCashReport() {
    this.router.navigateByUrl('studio-cash-report');
  }

  showStudioCashReport2() {
    this.router.navigateByUrl('studio-cash-report-2');
  }

  showStudioCashReportNewWindow(studio: string) {
    window.open('/studio-cash-report/' + studio, '_blank');
  }


  newEvent(noEventFinder?: boolean) {
    const dateStart = DateTools.format(Date.now(), 'yyyyMMddT100000');
    const dateEnd = DateTools.format(Date.now(), 'yyyyMMddT110000');
    // const dateStart = '20210501T100000';
    // const dateEnd = '20210501T110000';
    this.router.navigateByUrl('/e/new');
  }

  idChanged($event: Event) {
    let eventId = ($event.target as HTMLInputElement).value;
    if (eventId.includes('/')) {
      eventId = eventId.split('/').pop();
    }
    window.location.href = 'e/' + eventId;
  }

  idChanged2($event: Event) {
    window.location.href = 'e2/' + ($event.target as HTMLInputElement).value;
  }

  mobileChanged($event: Event) {
    window.location.href = 'chat/' + ($event.target as HTMLInputElement).value;
  }

  showInventory() {
    this.router.navigateByUrl('/inventory');
  }

  syncTest() {
    this.router.navigateByUrl('/sync-test');
  }

  logout() {
    this.loginService.reLogin(true);
  }

  invoices() {
    this.router.navigateByUrl('/invoices');
  }

  showBrudiTvDashboard() {
    this.router.navigateByUrl('/brudi-tv-dashboard');
  }

  showArtists() {
    this.router.navigateByUrl('/artists');
  }

  headerDoubleBlockClicked() {
    this.router.navigateByUrl('/header-double-block');
  }

  backoffice() {
    this.router.navigateByUrl('/backoffice');
  }

  eventFinder() {
    this.router.navigateByUrl('/event-finder');
  }

  showStudioFee() {
    this.router.navigateByUrl('/studio-fee');
  }

  checkListTest() {
    this.dialogService.showComponentDialog(CheckListComponent, {
        title: 'TestCheckbox',
        allCheckedToFinish: true,
        checkItems: [
          {name: 'TV im Hauptraum aus', checked: false},
          {name: 'Terrassentür abgeschlossen', checked: false},
          {name: 'Alle Tattoolichter aus / oder Artist die Info geben wenn noch gearbeitet wird', checked: false},
          {name: 'Handy ans Ladegerät', checked: false},
          {name: 'Abrechnung abgeheftet', checked: false},
          {name: 'Ich bringe nun direkt das Geld zur Bank', checked: false},
        ],
      }, {
        minWidth: '40vw',
      },
    );
  }

  clipboardTest() {
    const artists = [
      'https://artists.nxt-lvl.ink/#/artists/Alberto%202',
      'https://artists.nxt-lvl.ink/#/artists/Alberto',
      'https://artists.nxt-lvl.ink/#/artists/Anestis',
      'https://artists.nxt-lvl.ink/#/artists/Jorge%20Maori',
      'https://artists.nxt-lvl.ink/#/artists/Sebastian',
    ];
    this.clipboardService.copyToClipboard(MathTools.getRandomFromArray(artists));
  }

  async chat() {
    this.router.navigateByUrl('/chat/+4915120123232');
    return;


    const dialog = await this.dialogService.showComponentFull(WhatsappChatComponent);
    dialog.componentInstance.loadChat('+4915120123232');
    dialog.componentInstance.title = 'Julian';
    /*dialog.componentInstance.currentArtist = {
      name: 'Test',
      mobile: '',
      id: 'Julian'
    } as NxtArtist;*/
  }

  sendToJulian() {
    this.socketService.sendToJulian();
  }

  eventFinderFromGoogleUrl() {
    const dialog = this.dialogService.showComponentFull(CalendarEventEdit2Component);
    const test1 = '/calendar/u/1/r/week/2022/6/22';
    const test2 = '/calendar/u/1/r/custom/3/d/2022/6/22';
    const test3 = '/calendar/u/1/r/day/2022/6/22';

    dialog.componentInstance.showEventFinder({pathName: test3});
  }

  showInputDialog() {
    this.dialogService.showInput('hier rein', {
      width: '40vw',
      okButtonText: 'Anpinnen',
      stringCanEmpty: false,
    });
  }

  async showYesNo() {
    const result = await this.dialogService.showYesNo('TEST');
    alert(result);
  }

  showSelect() {
    this.dialogService.showSelect('Selct mal was', [{text: 'abc', value: 'abc'}, {text: 'def', value: 'def'}], {title: 'hansi'});
  }

  showOk() {
    this.dialogService.showOk('TEST', {buttonText: 'button text anderer', timeoutSeconds: 1000, minWidth: '600px'});
  }


  async showSearch() {
    const dialog = this.dialogService.showComponentFull(SearchComponent);
    await firstValueFrom(dialog.afterClosed());
  }

  newEventInNewWindow() {
    window.open('e/2j60582vije28dcj3vmionejqc', '_blank');

  }

  showLoading() {
    this.dialogService.showLoading(LoadingId.Default, 'App wird aktualisiert');
    this.dialogService.updateLoadingAllowCancel();
  }

  newPassword() {
    this.loginService.clearSavedLogin(false, 'neu Einloggen');
    this.loginService.navigateToLogin({reLogin: true, setNewPassword: true});
  }

  userIsActive() {
    IframeMessageManagerInIframe.instance.userIsActiveTest();
  }

  clicked(ev: any) {
    alert(ev);
  }

  showInputTime() {
    this.dialogService.showInput('Wann?', {
      prompt: '19:00',
      isTime: true,
      isTimeParams: {
        minTime: (Date.now() + DurationTools.DURATION_1DAY).dateFormat('HH') + ':00',
        maxTime: '19:00',
      },
    });
  }

  showContactEvents() {
    // this.dialogService.showEventsByContact('people/c2690143351460615359');
    // this.dialogService.showEventsByContact('people/c5654191451673313675');

    // this.dialogService.showEventsByContact('people/c1578786534629321036'); // marlon

    this.dialogService.showEventsByContact('people/c6266708952696678077'); // unglaublich viele termine


  }

  async showInputTest() {
    const result = await this.dialogService.showInput('Geld eingeben', {isMoney: true});
    this.dialogService.showOk('type: ' + typeof result + '\n\n' + result);
  }


  showSmsCode(type: 'Klarna' | 'PayPal' | 'Google') {
    this.dialogService.showComponentDialog(SmsCodeComponent, {type});
  }

  public async createPaypalLink() {
    const text = await this.paypalCheckoutService.showCreatePaypalPayment({
      value: null,
      name: 'Julian',
      mobile: '+4915120123232',
      infoInternal: 'Telegram info dies das',
    });
  }

  async translate() {

  }

  public photoSyncReimportUnknown() {
    // this.socketService.photoSyncReimportUnknown();
  }

  public showTextArea() {
    this.dialogService.showTextareaOld({message: 'test'});
  }


  public showBroadcast() {
    this.router.navigateByUrl('/broadcast');
  }

  public showBackoffice() {
    this.router.navigateByUrl('/backoffice');
  }

  public async qr() {
    const url = window.location.origin + '/event-qr/outgoing-scan' + 'xxxxxxxxxxxxx';
    const src = await QRCode.toDataURL(url, {width: 500, color: {dark: '#ffffff', light: '#2A2A2A'}});
    this.dialogService.showOk('<div style="width: 100%; text-align: center;"><br/><img src="' + src + '" /></div>', {title: 'Rechnung für Ausgabe fotografieren!'});
  }

  public preUpdate(shortName: 'ac' | 'ffm' | 'ab' | 'nu' | 'ma' | 'du' | 'br') {
    this.httpClient.get('https://' + shortName + '.nxt-lvl.ink/pre-pre-update').subscribe();
  }

  newContact() {
    this.dialogService.showContactForm();
  }

  emitUserChanged() {
    this.socketService.emitUserChanged();
  }

  test123() {
    alert('2024-01-01'.dateParse().dateFormat('MMM yy'));
  }

  showChat() {
    this.contactService.showWhatsAppChat('people/c2904602201128383241');
  }

  wtest() {
    return this.dialogService.showComponentDialog(WhatsappPriceDialogComponent);
  }

  fastAnswer() {
    this.dialogService.showComponentDialog(WhatsappFastAnswersComponent);
  }

  async moneyStackTest() {
    const moneyStack = JsonTools.parse('{"1":1,"2":2,"5":0,"10":0,"20":3,"50":7,"100":0,"200":0,"0.01":7,"0.02":20,"0.05":26,"0.1":6,"0.2":1,"0.5":0}');
    const values = JsonTools.parse('[1850.62]');

    const dialog = await this.dialogService.showComponentFull(MoneyCounterWrapper2Component);
    dialog.componentInstance.setData({
      showShould: true,
      // moneyStack: JsonTools.parse(this.test3), // this.myService.cashRegisterView.moneyStackPreCalcCashRegister,
      moneyStack, // this.myService.cashRegisterView.moneyStackPreCalcCashRegister,
      title: 'Testzählung',
    });
  }

  async showCombineContacts() {
    const result = await this.dialogService.showCombineContacts(['people/c6768937824501444099', 'people/c4285440395132638452']);
    // debugger;
  }

  errorTest() {
    this.socketService.assignSourceDestPayments(null, null, null, null);

  }

  clickTest() {
    setTimeout(() => {
      this.testValue1 = 5.25;
    }, 2000);
  }

  showEvents() {
    this.router.navigateByUrl('/events');
  }

  testReminder() {
    const dialog = this.dialogService.showComponentDialog(ReminderEditComponent);
    dialog.componentInstance.newReminder();
    // dialog.componentInstance
  }

  highlightReminders() {
    this.topBarService.startTest();
  }

  combineContacts() {
    this.dialogService.showCombineContacts(['people/c1389072887783766958', 'people/c4427429052640335265']);
  }

  throwErrorToolsInfo() {
    this.socketService.throwErrorToolsInfo();
  }

  throwErrorToolsError() {
    this.socketService.throwErrorToolsError();
  }

  throwError() {
    this.socketService.throwError();
  }

  async soundTest() {
    this.audioService.playReminderNotification();
  }

  showWhatsAppQr() {
    this.dialogService.showConnectWhatsApp();
  }

  async showVideo() {

    const event = await this.socketService.getCalendarEventWithCustomer('rp2ftwe5fxvy1b');
    const video = event.files.find(e => e.type === 'video');
    if (video) {
      const dialog = this.dialogService.showComponentFull(VideoComponent);
      dialog.componentInstance.loadDriveVideo(video);
    }
  }

  async grammarTest() {
    const text = await this.dialogService.showInput('text');
    const start = Date.now();
    const result = await this.socketService.correctGrammar(text);
    const end = Date.now();
    this.dialogService.showOk('Dauer: ' + (end - start) + 'ms\n\n' + result);
  }

  historyChanged($event: Event) {
    this.dialogService.showHistory('artistSpot', ($event.target as HTMLInputElement).value);
  }

  setTestValue() {
    this.form.get('testValue2').setValue(MathTools.random(0, 100000), {onlySelf: true, emitEvent: false});

  }

  public bankTransactionChanged($event: Event) {
    const value = ($event.target as HTMLInputElement).value;
    const parts = value.split('/');
    this.router.navigateByUrl('/bt/' + parts[parts.length - 1]).then();
  }

  public bankDocumentChanged($event: Event) {
    const value = ($event.target as HTMLInputElement).value;
    const parts = value.split('/');
    this.router.navigateByUrl('/bd/' + parts[parts.length - 1]).then();
  }

  openBankDocumentClicked() {
    if (this.bankDocumentId()) {
      const parts = this.bankDocumentId().split('/');
      this.router.navigateByUrl('/bd/' + parts[parts.length - 1]).then();
    }
  }

  openBankTransactionClicked() {
    if (this.bankDocumentId()) {
      const parts = this.bankTransactionId().split('/');
      this.router.navigateByUrl('/bd/' + parts[parts.length - 1]).then();
    }
  }
}
