<div fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="5px" class="h-100" *ngIf="availableSkills">
  <div fxLayout="row" fxLayoutAlign="center">
    <div class="text-120">Stil</div>
  </div>
  <div fxLayout="column" fxLayoutGap="2px">
    <div *ngFor="let skill of availableSkills.boolSkills">
      <nxt-slide-toggle [(value)]="currentBoolSkills[skill.description]" (valueChange)="valueChanged()">{{ skill.description }}</nxt-slide-toggle>
    </div>
    <div>&nbsp;</div>
    @for (skill of availableSkills.skills; track skill.description) {
      <div>
        <nxt-slide-toggle [(value)]="currentSkills[skill.description]" (valueChange)="valueChanged()">{{ skill.description }}</nxt-slide-toggle>
      </div>
    }
  </div>
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <nxt-button (click)="cancel()">Abbrechen</nxt-button>
    <nxt-button (click)="save()">OK</nxt-button>
  </div>
</div>
